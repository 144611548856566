import { IconName } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React, { HTMLAttributes } from 'react';
import theme from '../../../theme';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';

interface IButtonComponent extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  label?: string;
  buttonType: string;
  buttonText?: string;
  testId?: string;
  buttonStyle?: string;
  buttonCss?: React.CSSProperties;
  isDisabled?: boolean;
  click?: any;
  iconName?: string;
  href?: string;
  textSize?: string;
  typeAttribute?: 'button' | 'submit' | 'reset' | undefined;
}

const StyledButton = styled(Button)(({ color }) => ({
  '&.MuiButton-root': {
    backgroundColor: color === 'primary' ? theme.palette.rnOrange.darkOrange : theme.palette.rnMisc.white,
    border: color === 'primary' ? `2px solid ${theme.palette.rnOrange.darkOrange}` : `2px solid ${theme.palette.rnSteel.steel700}`,
    borderRadius: theme.borders.cardRadius,
    color: color === 'primary' ? theme.palette.rnMisc.white : theme.palette.rnSteel.steel700,
    margin: '0px 5px',
    padding: '10px 16px',
    textTransform: 'none',
  },
  '&.MuiButton-root.Mui-disabled': {
    color: color === 'primary' ? theme.palette.rnMisc.white : theme.palette.rnGrey.grey400,
    background: color === 'primary' ? theme.palette.rnGrey.grey200 : theme.palette.rnGrey.grey100,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    border: color === 'primary' ? '2px solid transparent' : `2px solid ${theme.palette.rnGrey.grey100}`,
    cursor: 'default',
  },
  '&.MuiButton-root:active': {
    backgroundColor: color === 'primary' ? theme.palette.rnOrange.darkOrangeLight : theme.palette.rnSteel.steel50,
    border: `2px solid ${theme.palette.rnOrange.darkOrange}`,
    color: theme.palette.rnOrange.darkOrange,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
  },
  '&.MuiButton-root:hover': {
    backgroundColor: color === 'primary' ? `${theme.palette.rnSteel.steel700}` : theme.palette.rnMisc.white,
    boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.05)`,
    border: color === 'primary' ? `2px solid ${theme.palette.rnSteel.steel700}` : `2px solid ${theme.palette.rnOrange.darkOrange}`,
  },
  '&.MuiButton-root:focus': {
    boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px ${theme.palette.rnOrange.darkOrange}`,
    backgroundColor: color === 'primary' ? theme.palette.rnOrange.darkOrange : theme.palette.rnMisc.white,
    border: `2px solid ${theme.palette.rnOrange.darkOrange}`,
    color: color === 'primary' ? theme.palette.rnMisc.white : theme.palette.rnSteel.steel700
  },
}));

const ButtonTypography = styled(Typography)({
  '&.MuiTypography-root': {
    marginLeft: '10px',
  },
});

// UI Wrapper for button components- onClick or onSubmit logic should be placed in parent, button type can be set using typeAttribute prop
export const ButtonComponent = ({
  id,
  label,
  buttonType,
  buttonText,
  testId,
  buttonStyle,
  buttonCss,
  isDisabled,
  click,
  iconName,
  typeAttribute,
  href,
  textSize,
}: IButtonComponent) => {
  return (
    <div className="rnbuttoncomponent">
      <StyledButton
        disabled={isDisabled}
        disableRipple
        disableFocusRipple
        color={buttonType == 'primary' ? 'primary' : 'secondary'}
        className={'btn ' + (buttonStyle != undefined ? buttonStyle : ' ')}
        style={buttonCss}
        data-testid={testId}
        aria-label={label}
        id={id}
        type={typeAttribute}
        onClick={click}
        href={href}
      >
        {iconName != undefined ? (
          <>
            {iconName === 'calculate' ?
              <FontAwesomeIcon
                icon={faCalculator}
                size={'2x'}
                data-testid="calculatorIcon"
                color={theme.palette.rnMisc.white}
              />
              :
              <span className="material-symbols-outlined button-icon">{iconName}</span>
            }
            <ButtonTypography fontSize={textSize ? textSize : '1rem'} fontWeight={600} fontFamily={'Inter'}>
              {buttonText}
            </ButtonTypography>
          </>
        ) : (
          <Typography fontSize={textSize ? textSize : '1rem'} fontWeight={600} fontFamily={'Inter'}>
            {buttonText}
          </Typography>
        )}
      </StyledButton>
    </div>
  );
};
