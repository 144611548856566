import { useMsal } from '@azure/msal-react';
import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Order_By } from '../../models/GQLGeneratedModels';
import { getShareLinkId } from '../../services/SaveLoadSearchService';
import { ApplicationContext } from '../ApplicationProvider';
import { ConfigitContext } from '../ConfigitProvider';
import { ButtonComponent } from '../shared/ButtonComponent/ButtonComponent';
import { TextBoxComponent } from '../shared/TextBoxComponent';

const ShareSearch: React.FC = () => {
  const { instance: azureADPCA } = useMsal();
  const { itemOffset, itemsPerPage, filters } = useContext(ApplicationContext);
  const { layers, variableAssignments, manufacturerProduct, excludedLayerIds } = useContext(ConfigitContext);
  const [url, setUrl] = useState('');
  const [copied, setCopied] = useState(false);
  const [linkCreated, setLinkCreated] = useState(false);
  const [createdLinkGuid, setCreatedLinkGuid] = useState('');

  useEffect(() => {
    const createShareLink = async () => {
      function sleep(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }
      // Get user Guid
      const userGuid = azureADPCA.getActiveAccount()?.localAccountId as string;

      // Add a share link into database
      const shareLinkGuid = await getShareLinkId(
        layers,
        variableAssignments,
        excludedLayerIds,
        manufacturerProduct,
        itemsPerPage,
        itemOffset,
        {
          AssemblyId: Order_By.Asc,
          CoverSubassemblyId: Order_By.Asc,
          DeckSubassemblyId: Order_By.Asc,
        },
        userGuid
      );

      // waits for 2000ms
      await sleep(2000);

      //return shareLinkGuid;
      setCreatedLinkGuid(shareLinkGuid);
      setLinkCreated(true);
      setUrl(`${window.location.protocol}//${window.location.host}/loadShareLinkSearchCriteria/${shareLinkGuid}`);
    };

    if (!linkCreated) {
      createShareLink();
    }
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  return (
    <Box gap={2} display="flex" flexDirection="column">
      <Typography className={'py-2'} fontFamily={'Inter'} fontSize={'16px'} lineHeight={'20px'}>
        Click below to copy your shareable link.
      </Typography>
      <TextBoxComponent id="url" value={url} />
      {!copied && <ButtonComponent buttonStyle="ml-0" buttonType="primary" buttonText="Copy Link" click={handleCopy} />}
      {copied && (
        <ButtonComponent
          buttonStyle="ml-0"
          buttonType="primary"
          buttonText="Link Copied"
          click={() => { }}
          iconName="check"
        />
      )}
    </Box>
  );
};

export default ShareSearch;
