import { Checkbox, FormControlLabel } from "@mui/material";
import React, { HTMLAttributes } from "react";
import { ConfigurationValue, SingletonValue } from "../../../types/configurator";

interface IConfigitCheckboxProps extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  label?: string;
  options: ConfigurationValue[];
  isDisabled?: boolean;
  select: (variableId: string, value: string) => void;
  unSelect: (variableId: string) => void;
}

export const ConfigitCheckbox: React.FC<IConfigitCheckboxProps> = ({
  id,
  label,
  options,
  isDisabled,
  select,
  unSelect,
  className,
  ...other
}: IConfigitCheckboxProps) => {
  const preSelected = options.find(option => option.assigned);
  const preSelectedValue = Boolean(preSelected && (preSelected as SingletonValue).value?.toString().toLowerCase() === 'true');
  const incompatible = options.find(option => option.incompatible);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (id && (!preSelected || preSelectedValue !== checked)) {
      select(id, checked ? 'True' : 'False')
    }
  };
  return (
    <>
      <FormControlLabel control={
        <Checkbox
          checked={preSelectedValue}
          disabled={isDisabled || !!incompatible}
          onChange={handleChange}
        />
      } label={label} />
    </>
  );
};