import React from 'react';
import { useGetWeldTorchedUsageDetailQuery } from '../../models/GQLGeneratedModels';
import './UsageDetails.scss';

export const WeldTorchedUsageDetail = (props: any) => {
  const { data, loading, error } = useGetWeldTorchedUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];

  return (
    <div className={'usage-detail p-3'}>
      <h4>Weld (Torched)</h4>
      <table>
        <tr>
          <td>Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
