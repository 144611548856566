import { NativeSelect, styled, Typography } from '@mui/material';
import React, { HTMLAttributes } from 'react';
import theme from '../../../theme';
import { ISelectValue } from './SelectBox';
import './SelectBox.scss';

interface ISelectComponentProps extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  isError?: boolean;
  errorMsg?: string;
  selectDisable?: boolean;
  options: ISelectValue[];
  value?: string;
  setValue: (value: string) => void;
}

// NativeSelect is needed for accessibility- will need to use some custom css in lieu of styled attribute for options
// Also results in need for option tag instead of MUI's MenuItem
const StyledNativeSelect = styled(NativeSelect)(({ className, error }) => ({
  '&.MuiNativeSelect-root': {
    border: `1px solid ${error ? theme.palette.rnMisc.red : theme.palette.rnGrey.grey600}`,
    outline: error ? `1px solid ${theme.palette.rnMisc.red}` : 'none',
    color: error ? theme.palette.rnMisc.red : theme.palette.rnText.primaryText,
    width: '100%',
    height: '40px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
  },

  '&.MuiNativeSelect-root:focus-within': {
    border: `1px solid ${theme.palette.rnSteel.steel700}`,
    outline: `1px solid ${theme.palette.rnSteel.steel700}`,
    color: theme.palette.rnText.primaryText,
  },
  '&.MuiNativeSelect-root.Mui-disabled': {
    border: `1px solid ${theme.palette.rnGrey.grey300}`,
    backgroundColor: theme.palette.rnGrey.grey100,
    color: theme.palette.rnGrey.grey400,
    opacity: 1,
  },
  '.MuiNativeSelect-select:disabled': {
    color: theme.palette.rnGrey.grey300,
  },
  '.MuiNativeSelect-select.MuiInputBase-input.MuiInput-input:focus': {
    backgroundColor: theme.palette.rnMisc.white,
  },
  ' .MuiNativeSelect-select.MuiInputBase-input.MuiInput-input': {
    marginLeft: '5px',
    display: 'block',
    textOverflow: 'ellipsis',
  },
}));

const StyledTypography = styled(Typography)({
  '&.MuiTypography-root': {
    width: '0px',
    height: '0px',
    whiteSpace: 'nowrap',
    marginBottom: '1.5rem',
  },
});

export const SelectComponent: React.FC<ISelectComponentProps> = (props: ISelectComponentProps) => {
  const {
    id,
    label,
    disabled = false,
    required = false,
    isError = false,
    errorMsg,
    selectDisable = false,
    options,
    value,
    setValue,
    className,
    ...other
  } = props;

  return (
    <div className={'rn-select'}>
      {label ? (
        <StyledTypography className={''} fontFamily={'Inter'} fontSize={14} fontWeight={500}>
          {label}
          {required ? <span className={'rn-error'}> *</span> : ''}
        </StyledTypography>
      ) : null}
      <StyledNativeSelect
        error={isError}
        disabled={disabled}
        value={value}
        disableUnderline
        onChange={(e) => setValue(e.target.value)}
        className={`select-box ${className?.includes('isChild') ? 'w-100 ' : ''}${className}`}
      >
        <option
          disabled={selectDisable}
          className={`rn-select-default`}
          aria-selected="true"
          value={''}
          role="option"
          tabIndex={-1}
        >
          Select
        </option>
        {options.map((option: ISelectValue, index) => {
          const { name, incompatible } = option;
          return (
            <option
              disabled={incompatible}
              className={`rn-list-item ${option.value === value ? 'rn-item-selected' : ''}`}
              aria-selected={option.value === value}
              value={option.value}
              role="option"
              tabIndex={-1}
              key={index}
            >
              {name}
            </option>
          );
        })}
      </StyledNativeSelect>
      {isError ? <Typography
        fontWeight={500}
        color={theme.palette.rnMisc.red}
        className={'mt-1'}
      >{errorMsg}</Typography> : ''}

    </div>
  );
};
