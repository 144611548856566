import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import theme from '../../../theme';
import { StyledTableCell } from '../../shared/TableComponent/TableComponent';
import { ApprovedUse } from '../../constants/ENUMS';
import { useNavigate } from 'react-router';
interface IUsageDetailProps {
  title: string;
  comments: string;
  approvdUsage: any;
  componentId: number;
  countMatchedAssemblies: number;
}

export const CoatingUsageDetail = (props: IUsageDetailProps) => {
  const { title, comments, approvdUsage, componentId, countMatchedAssemblies } = props;
  const usage = approvdUsage;
  const navigate = useNavigate();
  const DetailsAccordion = styled(Accordion)({
    '&.MuiAccordion-root': {
      backgroundColor: theme.palette.rnSteel.steel500,
      border: 'none',
      boxShadow: 'none !important',
    },
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
    '&.MuiAccordion-root:after': {
      height: '1px',
      backgroundColor: theme.palette.rnGrey.grey600,
    },
    '& .MuiCollapse-root': {
      width: '100%',
    },
  });

  const DetailsAccordionSummary = styled(AccordionSummary)({
    '&.MuiAccordionSummary-root': {
      pointerEvents: 'none',
    },
    '& .MuiAccordionSummary-content': {
      margin: '3px 0',
    },
  });
  const ProductDetailTableRow = styled(TableRow)({
    '&.MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: theme.palette.rnGrey.grey50,
    },
    '&.MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: theme.palette.rnMisc.white,
    },
  });
  return (
    <div className="card border-0 h-100">
      <div className="card-body p-0">
        <div className="bg-white w-100 mb-1">
          <DetailsAccordion className={`row m-0 pe-none`} disableGutters expanded={true}>
            <DetailsAccordionSummary className="row m-0 justify-content-between w-100">
              <Typography
                className={'pe-none col-9'}
                color={theme.palette.rnMisc.white}
                fontWeight={600}
                fontFamily={'Inter'}
                fontSize={'20px'}
                lineHeight={'28px'}
              >
                {title}
              </Typography>
            </DetailsAccordionSummary>
          </DetailsAccordion>
          <br></br>
          <TableContainer>
            <Table
              sx={{
                minWidth: 300,
                '& .MuiTableRow-root:hover': {
                  backgroundColor: 'none',
                },
              }}
              aria-label="customized table"
            >
              <TableBody>
                <ProductDetailTableRow>
                  <StyledTableCell className={'summary-bold-text'}>Material:</StyledTableCell>
                  <StyledTableCell className={'modal-info-bold-text'}>
                    {usage?.C_CoatingMaterial?.CoatingMaterialCode}
                  </StyledTableCell>
                </ProductDetailTableRow>
                <ProductDetailTableRow>
                  <StyledTableCell className={'summary-bold-text'}>Assemblies:</StyledTableCell>
                  <StyledTableCell className={'modal-info-bold-text'}>
                    {countMatchedAssemblies > 0 ? (
                      <Button
                        className={'modal-info-bold-text-link'}
                        style={{ textTransform: 'none', marginLeft: '-12px' }}
                        onClick={() => {
                          navigate(
                            `/assemblyListProductApprovedUse?componentid=${componentId}&approveduseid=${ApprovedUse.Coating}&count=${countMatchedAssemblies}`
                          );
                        }}
                      >
                        {countMatchedAssemblies}
                      </Button>
                    ) : (
                      '0'
                    )}
                  </StyledTableCell>
                </ProductDetailTableRow>
                <ProductDetailTableRow>
                  <StyledTableCell className={'summary-bold-text'}>Comments:</StyledTableCell>
                  <StyledTableCell className={'modal-info-bold-text'}>{comments ? comments : 'none'}</StyledTableCell>
                </ProductDetailTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};
