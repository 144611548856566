import React from 'react';
import { useGetSprayedFoamInsulationUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { Density } from '../../utils/Measurement/Density';
import { Length } from '../../utils/Measurement/Length';
import './UsageDetails.scss';

export const SprayedFoamInsulationUsageDetail = (props: any) => {
  const { data, loading, error } = useGetSprayedFoamInsulationUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];
  const isMetric = usage?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Insulation (Spray Foam)</h4>
      <table>
        <tr>
          <td>Material:</td>
          <td>
            {usage?.Component?.ManufacturedProduct?.SprayedFoamInsulation?.C_InsulationFoamType.InsulationFoamTypeCode}
          </td>
        </tr>
        <tr>
          <td>Min Thickness:</td>
          <td>{Length.display(usage?.SprayFoamInsulationUsage?.ThicknessMin, isMetric)}</td>
        </tr>
        <tr>
          <td>Min Density:</td>
          <td>{Density.display(usage?.Component?.ManufacturedProduct?.SprayedFoamInsulation?.DensityMin, isMetric)}</td>
        </tr>
        <tr>
          <td>Max Thickness:</td>
          <td>{Length.display(usage?.SprayFoamInsulationUsage?.ThicknessMax, isMetric)}</td>
        </tr>
        <tr>
          <td>Max Density:</td>
          <td>{Density.display(usage?.Component?.ManufacturedProduct?.SprayedFoamInsulation?.DensityMax, isMetric)}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
