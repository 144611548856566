import { TextareaAutosize, Typography } from '@mui/material';
import React, { HTMLAttributes } from 'react';
import './TextAreaComponent.scss';
import theme from '../../../theme';


interface ITextAreaComponentProps extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  label?: string;
  required?: boolean;
  value: string;
  isError?: boolean;
  errorMsg?: string;
  setValue: (value: string) => void;
}

const TextAreaComponent: React.FC<ITextAreaComponentProps> = ({
  id,
  label,
  required,
  value,
  isError = false,
  errorMsg,
  setValue,
}: ITextAreaComponentProps) => {
  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <div id={id} className="rn-text-area">
      {label && (
        <Typography fontSize={14} fontWeight={500} fontFamily={'Inter'}>
          {label}
          {required ? <span className={'rn-error'}> *</span> : ''}
        </Typography>
      )}
      <TextareaAutosize
        minRows={4}
        style={{ width: '100%', borderRadius: '4px' }}
        className={isError ? 'rn-error-textfield' : ''}
        value={value}
        onChange={handleChange}
      />
      {isError && <Typography
        fontWeight={500}
        color={theme.palette.rnMisc.red}>{errorMsg}</Typography>}
    </div>
  );
};

export default TextAreaComponent;
