import { useContext, useEffect, useState } from 'react';
import { apolloClient } from '../ApolloClient';
import { ILayer } from '../components/ConfigitProvider';
import {
  AssemblyApiRoofTypeSearchDocument,
  AssemblyApiRoofTypeSearchQuery,
  AssemblyApiRoofTypeSearchQueryVariables,
  GetRoofTypeCountByFiltersDocument,
  GetRoofTypeCountByFiltersQuery,
  GetRoofTypeCountByFiltersQueryVariables,
  NavAssembly_Bool_Exp,
  NavAssembly_Order_By,
  SearchNavAssembliesDocument,
  SearchNavAssembliesQuery,
  SearchNavAssembliesQueryVariables,
  VNavAssembly_RoofTypeCount_Bool_Exp,
  VNavAssembly_RoofTypeCount_Order_By,
  useAssemblySearchRoofTypeCountQuery,
  useGetRoofTypeCountByFiltersQuery
} from '../models/GQLGeneratedModels';
import { VariableAssignment } from '../types/configurator';
import { getAPISearchCriteriaBasedOnFilters } from './AssemblySearchAPIService';
import { ApplicationContext } from '../components/ApplicationProvider';
import { useLogger } from 'fmg-telemetry-react';

export const useGetRoofTypeCount = (filters: NavAssembly_Bool_Exp) => {
  return {
    data: 50,
    loading: false,
    error: null,
  };
};

export const useGetRoofTypesWithCount = (
  filters: VNavAssembly_RoofTypeCount_Bool_Exp,
  limit: number,
  offset: number,
  orderBy: VNavAssembly_RoofTypeCount_Order_By
) => {
  return useAssemblySearchRoofTypeCountQuery({
    variables: {
      filters: filters,
      limit: limit,
      offset: offset,
      order_by: orderBy,
    },
  });
};

export const useGetRoofTypesCountByAPIQuery = (
  layers: ILayer[],
  excludedLayerTypeIds: string[],
  manufacturerProduct: VariableAssignment[],
  limit: number,
  offset: number,
  orderBy: VNavAssembly_RoofTypeCount_Order_By,
  filters?: NavAssembly_Bool_Exp,
) => {
  const [data, setData] = useState<any>();
  //const [loading, setLoading] = useState(true);
  const logger = useLogger();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const { autoRefreshSearchResult, setAutoRefreshSearchResult } = useContext(ApplicationContext);
  const { triggerRoofTypeList, setTriggerRoofTypeList } = useContext(ApplicationContext);
  const { numShowResult, setNumShowResult } = useContext(ApplicationContext);
  useEffect(() => {
    if (filters && (autoRefreshSearchResult || triggerRoofTypeList)) {
      setLoading(true);
      if (triggerRoofTypeList) setTriggerRoofTypeList(false);
      const assemblySearchCriteria = getAPISearchCriteriaBasedOnFilters(
        layers,
        filters,
        excludedLayerTypeIds,
        manufacturerProduct,
        limit,
        offset,
        orderBy
      );
      apolloClient.query<AssemblyApiRoofTypeSearchQuery, AssemblyApiRoofTypeSearchQueryVariables>({
        query: AssemblyApiRoofTypeSearchDocument,
        variables: {
          assemblySearchCriteria: assemblySearchCriteria,
        },
      }).then(result => {
        setData(result.data);
        setLoading(result.loading);
        setError(result.error);
      })
        .catch((error) => {
          setError(error);
          setLoading(false);
          logger.error('Error in useGetRoofTypesCountByAPIQuery', { searchCriteriaUsed: assemblySearchCriteria, error: error, msg: error.message })

        });
    }
  }, [
    limit,
    offset,
    JSON.stringify(orderBy),
    JSON.stringify(filters),
    numShowResult
  ]);
  return { data, loading, error };
};

export const useGetAssembliesExtraLayerConfigIdFilterQuery = (
  limit: number,
  offset: number,
  orderBy: NavAssembly_Order_By,
  layerConfigId: number,
  filters?: NavAssembly_Bool_Exp,
) => {
  const logger = useLogger();
  const [data, setData] = useState<any>();
  //const [loading, setLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  useEffect(() => {
    if (filters) {
      setLoading(true);
      const newFilters = { ...filters };
      newFilters['LayerConfigId'] = {
        _eq: layerConfigId,
      };
      apolloClient.query<SearchNavAssembliesQuery, SearchNavAssembliesQueryVariables>({
        query: SearchNavAssembliesDocument,
        variables: {
          filters: newFilters,
          limit: limit,
          offset: offset,
          order_by: orderBy,
        },
      }).then(result => {
        setData(result.data);
        setLoading(result.loading);
        setError(result.error);
      })
        .catch((error) => {
          setError(error);
          setLoading(false);
          logger.error('Error in useGetAssembliesExtraLayerConfigIdFilterQuery', {
            searchCriteriaUsed: {
              filters: newFilters,
              limit: limit,
              offset: offset,
              order_by: orderBy,
            }, error: error, msg: error.message
          })
        });
    }
  }, [
    limit,
    offset,
    JSON.stringify(orderBy),
    JSON.stringify(filters),
  ]);
  return { data, loading, error };
};

export const getRoofTypeCountByFilters = async (filters: VNavAssembly_RoofTypeCount_Bool_Exp) => {
  const result = await apolloClient.query<GetRoofTypeCountByFiltersQuery, GetRoofTypeCountByFiltersQueryVariables>({
    query: GetRoofTypeCountByFiltersDocument,
    variables: {
      filters: filters,
    },
  });
  return result.data.vNavAssembly_RoofTypeCount_aggregate.aggregate?.count;
};

export const useGetRoofTypeCountByFilters = (filters: VNavAssembly_RoofTypeCount_Bool_Exp) => {
  const { data, loading, error } = useGetRoofTypeCountByFiltersQuery({
    variables: {
      filters: filters,
    },
  });
  return {
    data: data?.vNavAssembly_RoofTypeCount_aggregate.aggregate?.count,
    loading: loading,
    error: error,
  };
};
