import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled, Table, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import React from 'react';
import ReactPaginate from 'react-paginate';
import theme from '../../../theme';
import './TableComponent.scss'
import { Loading } from '../Loading/Loading';


interface IColHead {
  name: string;
  width: number;
  className: string;
}

interface ITableProps {
  loading: boolean;
  count?: number;
  offset: number;
  limit: number;
  handlePageSizeChange: (event: any) => void;
  handlePageClick: (event: any) => void;
  handleSort: (field: string) => void;
  colHeads: IColHead[];
  rows?: string[][];
  navigateToDetail: (rowId: number) => void;
}

const StyledTable = styled(Table)({
  overflowX: 'scroll'

});
export const StyledTableCell = styled(TableCell)({

  whiteSpace: 'normal',
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.rnSteel.steel500,
    color: theme.palette.rnMisc.white,
    borderRight: '1px solid grey !important',
    verticalAlign: 'middle !important'
  },
  '&.MuiTableCell-head:last-of-type': {
    borderRight: '0px !important'
  },

});
export const StyledTableRow = styled(TableRow)({
  '&.MuiTableRow-root:nth-of-type(odd)': {
    backgroundColor: theme.palette.rnMisc.white
  },
  '&.MuiTableRow-root:nth-of-type(even)': {
    backgroundColor: theme.palette.rnStone.stone100
  },
  '&.MuiTableRow-root:hover, &.MuiRableRow-root:focus': {
    backgroundColor: theme.palette.rnSteel.steel500 + ' !important',
    color: theme.palette.rnMisc.white
  }

});

export const TableComponent = (props: ITableProps) => {
  const {
    loading,
    count,
    offset,
    limit,
    handlePageSizeChange,
    handlePageClick,
    handleSort,
    colHeads,
    rows,
    navigateToDetail,
  } = props;
  return (
    <div className="bootstrap-table bootstrap4">
      <div className="fixed-table-toolbar"></div>
      <div className="fixed-table-container" style={{ paddingBottom: '0px' }}>
        <div className="fixed-table-header" style={{ display: 'none' }}>
          <table></table>
        </div>
        <div className="fixed-table-body">
          <div
            className="fixed-table-loading table table-bordered table-hover"
            style={{ top: '29.625px', display: loading ? 'block' : 'none' }}
          >
            <span className="loading-wrap">
              <div className="loading-spinner d-flex justify-content-center align-items-center">
                <Loading />
              </div>
            </span>
          </div>
          {!loading &&
            <StyledTable
              id="table"
              className="mdc-data-table__table table table-bordered table-hover"
              data-toggle="table"
              data-sort-name="assemblynum"
              data-sort-order="asc"
              data-page-size="25"
              data-pagination="true"
            >
              <TableHead>
                <StyledTableRow className="">
                  {colHeads.map((colHead, index) => {
                    return (
                      <StyledTableCell variant={'head'}
                        key={index}
                        className={
                          colHeads[index].className === 'notvisible'
                            ? 'd-none'
                            : ''
                        }
                        style={{ minWidth: `${colHead.width}px`, width: `${colHead.width}px` }}
                        data-field="assemblynum"
                      >
                        <div className={colHead.className + ' px-0'} onClick={() => handleSort(colHead.name)}>
                          <div className={'d-flex flex-row justify-content-between align-items-center'}>
                            <Typography fontFamily={'Inter'} fontWeight={700} fontSize={14} display={'block'}>{colHead.name}</Typography>
                            <FontAwesomeIcon id={`sortButton${index}`} icon={['fas', (colHead.className.includes('desc') ? 'caret-down' : 'caret-up')]} className={'fa-table-icon px-1'} size={"2x"}></FontAwesomeIcon>

                          </div>

                        </div>


                        <div className="fht-cell"></div>

                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              </TableHead>
              <tbody className="mdc-data-table__content">
                {!loading &&
                  rows &&
                  rows.map((row, index) => (
                    <StyledTableRow
                      key={index}
                      className="mdc-data-table__row"
                      data-index={index}
                      onClick={() => navigateToDetail(parseInt(row[0]))}
                    >
                      {row.map((data, index) => (
                        <StyledTableCell
                          key={index}
                          className={colHeads[index].className === 'notvisible' ? 'd-none' : ''}
                          style={{ width: `${colHeads[index].width}px` }}
                        >
                          <Typography fontFamily={'Inter'} fontWeight={500} fontSize={12.8} >{data}</Typography>

                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
              </tbody>
            </StyledTable>
          }
        </div>
        <div className="fixed-table-footer">
          <table>
            <thead>
              <tr></tr>
            </thead>
          </table>
        </div>
      </div>
      {count && (
        <div className="fixed-table-pagination">
          <div className="float-left pagination-detail">
            <span className="pagination-info">
              Showing {offset + 1} to {offset + limit < count ? offset + limit : count} of {count} rows
            </span>
            <span className="page-list">
              <span className="btn-group dropdown dropup">
                <select value={limit} onChange={handlePageSizeChange}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </span>{' '}
              rows per page
            </span>
          </div>
          <div className="float-right pagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(count / limit)}
              forcePage={Math.round(offset / limit)}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      )}
    </div>
  );
};
