import { Section, VariableAssignment } from "../types/configurator";

export interface IConfigitResponse {
  data?: Section | Section[];
  loading: boolean;
  error?: any;
  targets: EventTarget[];
}

export class ConfigitCacheManager {
  static configitCache: Record<string, Record<string, IConfigitResponse>> = {};
  // sort variableAssignments to avoid duplicate api calls of same input in different order
  static getVariableAssignmentsStr = (variableAssignments: VariableAssignment[]) => {
    return JSON.stringify(
      variableAssignments.sort(
        (a, b) => (a.variableId && b.variableId) ? (
          (a.variableId > b.variableId) ? 1 : ((a.variableId < b.variableId) ? -1 : 0)
        ) : 0
      )
    );
  };
  // directly use data from IConfigitResponse if it is not undefined
  static getApiResponse = (model: string, variableAssignmentsStr: string): IConfigitResponse | undefined => {
    if (!this.configitCache[model]) this.configitCache[model] = {};
    return this.configitCache[model][variableAssignmentsStr];
  };
  // start new api call if both data and loading from IConfigitResponse is undefined
  static startApiCall = (model: string, variableAssignmentsStr: string) => {
    this.configitCache[model][variableAssignmentsStr] = { loading: true, targets: [] };
  };
  // use promise to get new IConfigitResponse if loading from IConfigitResponse is true
  static getApiResponsePromise = (model: string, variableAssignmentsStr: string) => {
    // target for event listener
    const target = new EventTarget();
    // Promise callback that triggers resolve or reject method
    const resolution = (resolve: (value: Section | Section[]) => void, reject: (reason?: any) => void) => {
      // event listener to trigger when api call is finished
      target.addEventListener('customEvent', () => {
        const temp = this.configitCache[model][variableAssignmentsStr];
        if (temp.data) resolve(temp.data);
        if (temp.error) reject(temp.error);
      });
    };
    const promise = new Promise<Section | Section[]>(resolution);
    // push targets to trigger event when api call is finished
    this.configitCache[model][variableAssignmentsStr].targets.push(target);
    return promise;
  };
  // finish api call by sending data and error from response of configit api call
  static finishApiCall = (
    model: string,
    variableAssignmentsStr: string,
    data?: Section | Section[],
    error?: any
  ) => {
    const temp = this.configitCache[model][variableAssignmentsStr];
    temp.data = data;
    temp.loading = false;
    temp.error = error;
    // trigger events in each target so they can resolve their promise
    temp.targets.forEach(target => target.dispatchEvent(new Event('customEvent')));
    temp.targets = [];
  };
}