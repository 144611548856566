import { MeasureType } from './ENUMS';

interface IUnit {
  imperialUnit: string;
  metricUnit: string;
}

interface IMeasurementAttribute {
  columnName: string;
  measureType: MeasureType;
}

interface ILayerMeasurementAttributes {
  layerTypeId: string;
  layerTypeName: string;
  measurementAttributes: IMeasurementAttribute[];
}
interface IApprovedUseMeasurementAttributes {
  approvedUseId: string;
  approvedUseName: string;
  measurementAttributes: IMeasurementAttribute[];
}

export const MEASURE_UNITS: Record<MeasureType, IUnit> = {
  [MeasureType.SolidApplicationRate]: { imperialUnit: 'lb/square', metricUnit: 'kg/m2' },
  [MeasureType.Area]: { imperialUnit: 'ft2', metricUnit: 'm2' },
  [MeasureType.Coverage]: { imperialUnit: '', metricUnit: 'm2/L' },
  [MeasureType.Density]: { imperialUnit: 'lb/ft3', metricUnit: 'kg/m3' },
  [MeasureType.Force]: { imperialUnit: 'lbf', metricUnit: 'N' },
  [MeasureType.Length]: { imperialUnit: 'in', metricUnit: 'mm' },
  [MeasureType.Mass]: { imperialUnit: '', metricUnit: 'kg' },
  [MeasureType.MassPerUnitArea]: { imperialUnit: 'oz/ft2', metricUnit: 'kg/m2' },
  [MeasureType.Pressure]: { imperialUnit: 'ksi', metricUnit: 'mPa' },
  [MeasureType.Volume]: { imperialUnit: '', metricUnit: 'L' },
  [MeasureType.VolumePerUnitArea]: { imperialUnit: 'gal/square', metricUnit: 'l/m2' },
  [MeasureType.Strength]: { imperialUnit: 'ksi', metricUnit: 'Mpa' },
};

export const LAYER_MEASUREMENT_ATTRIBUTES: ILayerMeasurementAttributes[] = [
  {
    layerTypeId: '01',
    layerTypeName: 'Cover (Composite Panel)',
    measurementAttributes: [
      { columnName: 'WidthMax', measureType: MeasureType.Length },
      { columnName: 'ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'WidthMin', measureType: MeasureType.Length },
      { columnName: 'FacerThicknessTop', measureType: MeasureType.Length },
      { columnName: 'FacerThicknessBottom', measureType: MeasureType.Length },
    ],
  },
  {
    layerTypeId: '06',
    layerTypeName: 'Base Coat',
    measurementAttributes: [
      { columnName: 'ApplicationRateMinWet', measureType: MeasureType.VolumePerUnitArea },
      { columnName: 'ApplicationRateMinDry', measureType: MeasureType.SolidApplicationRate },
    ],
  },
  {
    layerTypeId: '07',
    layerTypeName: 'Coat',
    measurementAttributes: [
      { columnName: 'MinAppRatePerCoatWet', measureType: MeasureType.VolumePerUnitArea },
      { columnName: 'MinAppRatePerCoatDry', measureType: MeasureType.SolidApplicationRate },
    ],
  },
  {
    layerTypeId: '08',
    layerTypeName: 'Top Coat',
    measurementAttributes: [
      { columnName: 'ApplicationRateMinWet', measureType: MeasureType.VolumePerUnitArea },
      { columnName: 'ApplicationRateMinDry', measureType: MeasureType.SolidApplicationRate },
    ],
  },
  {
    layerTypeId: '09',
    layerTypeName: 'Base Ply',
    measurementAttributes: [{ columnName: 'Width', measureType: MeasureType.Length }],
  },
  {
    layerTypeId: '10',
    layerTypeName: 'Cap Ply',
    measurementAttributes: [{ columnName: 'Width', measureType: MeasureType.Length }],
  },
  {
    layerTypeId: '11',
    layerTypeName: 'Ply',
    measurementAttributes: [{ columnName: 'Width', measureType: MeasureType.Length }],
  },
  {
    layerTypeId: '04',
    layerTypeName: 'Cover (Single-ply)',
    measurementAttributes: [
      { columnName: 'ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'WidthMax', measureType: MeasureType.Length },
    ],
  },
  {
    layerTypeId: '05',
    layerTypeName: 'Cover (Standing/Lap Seam)',
    measurementAttributes: [
      { columnName: 'WidthMin', measureType: MeasureType.Length },
      { columnName: 'WidthMax', measureType: MeasureType.Length },
      { columnName: 'ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'NominalWeight', measureType: MeasureType.MassPerUnitArea },
      { columnName: 'WeightPerUnitAreaNominal', measureType: MeasureType.MassPerUnitArea },
    ],
  },
  {
    layerTypeId: '24',
    layerTypeName: 'Cover Board',
    measurementAttributes: [
      { columnName: 'ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'DensityMin', measureType: MeasureType.Density },
    ],
  },
  {
    layerTypeId: '27',
    layerTypeName: 'Insulation (Batt/Blanket)',
    measurementAttributes: [
      { columnName: 'ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'ThicknessMax', measureType: MeasureType.Length },
    ],
  },
  {
    layerTypeId: '28',
    layerTypeName: 'Insulation (Board Stock)',
    measurementAttributes: [
      { columnName: 'ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'DensityMin', measureType: MeasureType.Density },
    ],
  },
  {
    layerTypeId: '30',
    layerTypeName: 'Lightweight Insulating Concrete',
    measurementAttributes: [
      { columnName: 'ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'CompressiveStrengthMin', measureType: MeasureType.Strength },
      { columnName: 'MinCompressiveStrength', measureType: MeasureType.Strength },
      { columnName: 'DryDensityMin', measureType: MeasureType.Density },
      { columnName: 'CastDensityMin', measureType: MeasureType.Density },
    ],
  },
  {
    layerTypeId: '29',
    layerTypeName: 'Insulation (Spray Foam)',
    measurementAttributes: [
      { columnName: 'ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'DensityMin', measureType: MeasureType.Density },
      { columnName: 'DensityMax', measureType: MeasureType.Density },
    ],
  },
  {
    layerTypeId: '36',
    layerTypeName: 'Thermal Barrier',
    measurementAttributes: [
      { columnName: 'ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'DensityMin', measureType: MeasureType.Density },
    ],
  },
  {
    layerTypeId: '22',
    layerTypeName: 'Base Sheet',
    measurementAttributes: [
      { columnName: 'Width', measureType: MeasureType.Length },
      { columnName: 'WidthMax', measureType: MeasureType.Length },
      { columnName: 'LapWidth', measureType: MeasureType.Length },
    ],
  },
  {
    layerTypeId: '37',
    layerTypeName: 'Vapor Retarder',
    measurementAttributes: [
      { columnName: 'ApplicationRateWet', measureType: MeasureType.VolumePerUnitArea },
      { columnName: 'ApplicationRateDry', measureType: MeasureType.SolidApplicationRate },
    ],
  },
  {
    layerTypeId: '12',
    layerTypeName: '(Deck) Cementitious Panel',
    measurementAttributes: [
      { columnName: 'ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'SpanMax', measureType: MeasureType.Length },
    ],
  },
  {
    layerTypeId: '17',
    layerTypeName: '(Deck) Steel',
    measurementAttributes: [
      { columnName: 'DepthMin', measureType: MeasureType.Length },
      { columnName: 'DepthMax', measureType: MeasureType.Length },
      { columnName: 'WidthMin', measureType: MeasureType.Length },
      { columnName: 'WidthMax', measureType: MeasureType.Length },
      { columnName: 'DesignThickness', measureType: MeasureType.Length },
      { columnName: 'SpanMax', measureType: MeasureType.Length },
      { columnName: 'ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'GradeMin', measureType: MeasureType.Strength },
    ],
  },
  {
    layerTypeId: '25',
    layerTypeName: 'Existing Roof',
    measurementAttributes: [
      { columnName: 'SteelStrength', measureType: MeasureType.Strength },
      { columnName: 'SteelThicknessMin', measureType: MeasureType.Length },
      { columnName: 'SteelThicknessMax', measureType: MeasureType.Length },
      { columnName: 'InsulationThicknessMin', measureType: MeasureType.Length },
      { columnName: 'InsulationThicknessMax', measureType: MeasureType.Length },
    ],
  },
  {
    layerTypeId: '26',
    layerTypeName: 'Form Deck',
    measurementAttributes: [
      { columnName: 'ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'Depth', measureType: MeasureType.Length },
      { columnName: 'SpanMax', measureType: MeasureType.Length },
    ],
  },
  {
    layerTypeId: '33',
    layerTypeName: 'Substrate Primer',
    measurementAttributes: [
      { columnName: 'ApplicationRateWet', measureType: MeasureType.VolumePerUnitArea },
      { columnName: 'ApplicationRateDry', measureType: MeasureType.SolidApplicationRate },
    ],
  },
  {
    layerTypeId: '35',
    layerTypeName: 'Structure',
    measurementAttributes: [
      { columnName: 'SpacingMax', measureType: MeasureType.Length },
      { columnName: 'Thickness', measureType: MeasureType.Length },
    ],
  },
  {
    layerTypeId: '45',
    layerTypeName: 'Securement (Cover)',
    measurementAttributes: [
      // Adhesive (Full Application)
      { columnName: 'SubstratePrimerRateWet', measureType: MeasureType.VolumePerUnitArea },
      { columnName: 'OverSubstrateRateWet', measureType: MeasureType.VolumePerUnitArea },
      { columnName: 'UndersideRateWet', measureType: MeasureType.VolumePerUnitArea },
      { columnName: 'SubstratePrimerRateDry', measureType: MeasureType.SolidApplicationRate },
      { columnName: 'OverSubstrateRateDry', measureType: MeasureType.SolidApplicationRate },
      { columnName: 'UndersideRateDry', measureType: MeasureType.SolidApplicationRate },
      // Adhesive (Ribbons)
      { columnName: 'TapeWidth', measureType: MeasureType.Length },
      { columnName: 'RibbonWidth', measureType: MeasureType.Length },
      { columnName: 'RowSpacing', measureType: MeasureType.Length },
      // Adhesive (Spots)
      { columnName: 'RowSpacing', measureType: MeasureType.Length },
      { columnName: 'OnCenter', measureType: MeasureType.Length },
      { columnName: 'SpotDiameter', measureType: MeasureType.Length },
      // Fastening (Fastener)
      { columnName: 'LengthMin', measureType: MeasureType.Length },
      { columnName: 'LengthMax', measureType: MeasureType.Length },
      { columnName: 'Diameter', measureType: MeasureType.Length },
      { columnName: 'RowSpacing', measureType: MeasureType.Length },
      { columnName: 'OnCenter', measureType: MeasureType.Length },
      { columnName: 'FieldRowsOnCenter', measureType: MeasureType.Length },
      { columnName: 'Embedment', measureType: MeasureType.Length },
      { columnName: 'ContributoryArea', measureType: MeasureType.Area },
      // Fastening (Pre-assembled Fastening)
      { columnName: 'LengthMin', measureType: MeasureType.Length },
      { columnName: 'LengthMax', measureType: MeasureType.Length },
      { columnName: 'FastenerDiameter', measureType: MeasureType.Length },
      { columnName: 'RowSpacing', measureType: MeasureType.Length },
      { columnName: 'OnCenter', measureType: MeasureType.Length },
      { columnName: 'FieldRowsOnCenter', measureType: MeasureType.Length },
      { columnName: 'Embedment', measureType: MeasureType.Length },
      { columnName: 'ContributoryArea', measureType: MeasureType.Area },
      // Fastening System(Batten Bar)
      { columnName: 'RowSpacing', measureType: MeasureType.Length },
      { columnName: 'OnCenter', measureType: MeasureType.Length },
      { columnName: 'FieldRowsOnCenter', measureType: MeasureType.Length },
      // Fastening System(Clip)
      { columnName: 'RowSpacing', measureType: MeasureType.Length },
      { columnName: 'OnCenter', measureType: MeasureType.Length },
      // Fastening System(Stress Plate)
      { columnName: 'RowSpacing', measureType: MeasureType.Length },
      { columnName: 'OnCenter', measureType: MeasureType.Length },
      { columnName: 'FieldRowsOnCenter', measureType: MeasureType.Length },
      { columnName: 'ContributoryArea', measureType: MeasureType.Area },
      { columnName: 'Embedment', measureType: MeasureType.Length },
      // Weld (Arc)
      { columnName: 'RowSpacing', measureType: MeasureType.Length },
      { columnName: 'OnCenter', measureType: MeasureType.Length },
      { columnName: 'Diameter', measureType: MeasureType.Length },
      // Weld (Heat)
      { columnName: 'OutsideWidth', measureType: MeasureType.Length },
      { columnName: 'InsideWidth', measureType: MeasureType.Length },
      // Weld (Solvent)
      { columnName: 'OutsideWidth', measureType: MeasureType.Length },
      { columnName: 'InsideWidth', measureType: MeasureType.Length },
    ],
  },
];
// neither name nor id have 1:1 matches with apu data, columnName matxhes name property for some apus
//not others, so matching for column name here is based on variable id
export const APU_MEASUREMENT_ATTRIBUTES: IApprovedUseMeasurementAttributes[] = [
  {
    approvedUseId: '2',
    approvedUseName: 'Adhesive (Ribbons)',
    measurementAttributes: [
      { columnName: 'AdhesiveRibbons_TapeWidth', measureType: MeasureType.Length },
    ],
  },
  {
    approvedUseId: '8',
    approvedUseName: 'Cover (Composite Panel)',
    measurementAttributes: [
      { columnName: 'CompositePanelCover_WidthMax', measureType: MeasureType.Length },
      { columnName: 'CompositePanelCover_ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'CompositePanelCover_ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'CompositePanelCover_WidthMin', measureType: MeasureType.Length },
      { columnName: 'CompositePanelCover_FacerThicknessTop', measureType: MeasureType.Length },
      { columnName: 'CompositePanelCover_FacerThicknessBottom', measureType: MeasureType.Length },
    ],
  },
  {
    approvedUseId: '16',
    approvedUseName: 'Cover, Multi-ply (Base Ply)',
    measurementAttributes: [{ columnName: 'BasePly_Width', measureType: MeasureType.Length }],
  },
  {
    approvedUseId: '17',
    approvedUseName: 'Cover, Multi-ply (Cap Ply)',
    measurementAttributes: [{ columnName: 'CapPly_Width', measureType: MeasureType.Length }],
  },
  {
    approvedUseId: '18',
    approvedUseName: 'Cover, Multi-ply (Ply)',
    measurementAttributes: [{ columnName: 'OtherPly_Width', measureType: MeasureType.Length }],
  },
  {
    approvedUseId: '82',
    approvedUseName: 'Cover-Shingles',
    measurementAttributes: [
      { columnName: 'Shingles_Length', measureType: MeasureType.Length },
      { columnName: 'Shingles_Width', measureType: MeasureType.Length },
      { columnName: 'Shingles_NominalWeight', measureType: MeasureType.SolidApplicationRate }
    ],
  },
  {
    approvedUseId: '9',
    approvedUseName: 'Cover (Single-ply)',
    measurementAttributes: [
      { columnName: 'SinglePlyCover_ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'SinglePlyCover_ThicknessMax', measureType: MeasureType.Length },
    ],
  },
  {
    approvedUseId: '10',
    approvedUseName: 'Cover (Standing/Lap Seam)',
    measurementAttributes: [
      { columnName: 'StandingLapSeamCover_WidthMin', measureType: MeasureType.Length },
      { columnName: 'StandingLapSeamCover_WidthMax', measureType: MeasureType.Length },
      { columnName: 'StandingLapSeamCover_ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'StandingLapSeamCover_ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'StandingLapSeamCover_NominalWeight', measureType: MeasureType.MassPerUnitArea },
    ],
  },
  {
    approvedUseId: '11',
    approvedUseName: 'Cover Board',
    measurementAttributes: [
      { columnName: 'CoverBoard_ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'CoverBoard_ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'CoverBoard_DensityMin', measureType: MeasureType.Density },
    ],
  },
  {
    approvedUseId: '35',
    approvedUseName: 'Insulation (Batt/Blanket)',
    measurementAttributes: [
      { columnName: 'BattBlanketInsulation_ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'BattBlanketInsulation_ThicknessMax', measureType: MeasureType.Length },
    ],
  },
  {
    approvedUseId: '36',
    approvedUseName: 'Insulation (Board Stock)',
    measurementAttributes: [
      { columnName: 'BoardStock_ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'BoardStock_ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'BoardStock_DensityMin', measureType: MeasureType.Density },
    ],
  },
  {
    approvedUseId: '22',
    approvedUseName: 'Lightweight Insulating Concrete',
    measurementAttributes: [
      { columnName: 'LightweightInsulatingConcrete_ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'LightweightInsulatingConcrete_CompressiveStrengthMin', measureType: MeasureType.Strength },
      { columnName: 'LightweightInsulatingConcrete_MinCompressiveStrength', measureType: MeasureType.Strength },
      { columnName: 'LightweightInsulatingConcrete_DryDensityMin', measureType: MeasureType.Density },
      { columnName: 'LightweightInsulatingConcrete_CastDensityMin', measureType: MeasureType.Density },
    ],
  },
  {
    approvedUseId: '38',
    approvedUseName: 'Insulation (Spray Foam)',
    measurementAttributes: [
      { columnName: 'SprayedFoamInsulation_ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'SprayedFoamInsulation_ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'SprayedFoamInsulation_DensityMin', measureType: MeasureType.Density },
      { columnName: 'SprayedFoamInsulation_DensityMax', measureType: MeasureType.Density },
    ],
  },
  {
    approvedUseId: '48',
    approvedUseName: 'Thermal Barrier',
    measurementAttributes: [
      { columnName: 'ThermalBarrier_ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'ThermalBarrier_ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'ThermalBarrier_DensityMin', measureType: MeasureType.Density },
    ],
  },
  {
    approvedUseId: '5',
    approvedUseName: 'Base Sheet',
    measurementAttributes: [
      { columnName: 'BaseSheet_Width', measureType: MeasureType.Length },
    ],
  },
  {
    approvedUseId: '19',
    approvedUseName: 'Deck (Cementitious Panel)',
    measurementAttributes: [
      { columnName: 'CementitiousWoodFiberDeck_ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'CementitiousWoodFiberDeck_ThicknessMax', measureType: MeasureType.Length },
      { columnName: 'CementitiousWoodFiberDeck_SpanMax', measureType: MeasureType.Length },
    ],
  },
  {
    approvedUseId: '23',
    approvedUseName: 'Deck (Steel)',
    measurementAttributes: [
      { columnName: 'SteelDeck_DepthMin', measureType: MeasureType.Length },
      { columnName: 'SteelDeck_DepthMax', measureType: MeasureType.Length },
      { columnName: 'SteelDeck_WidthMin', measureType: MeasureType.Length },
      { columnName: 'SteelDeck_WidthMax', measureType: MeasureType.Length },
      { columnName: 'SteelDeck_DesignThickness', measureType: MeasureType.Length },
      { columnName: 'SteelDeck_SpanMax', measureType: MeasureType.Length },
      { columnName: 'SteelDeck_ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'SteelDeck_GradeMin', measureType: MeasureType.Strength },
    ],
  },
  {
    approvedUseId: '33',
    approvedUseName: 'Form Deck',
    measurementAttributes: [
      { columnName: 'FormDeck_ThicknessMin', measureType: MeasureType.Length },
      { columnName: 'FormDeck_Depth', measureType: MeasureType.Length },
      { columnName: 'FormDeck_SpanMax', measureType: MeasureType.Length },
    ],
  },
  {
    approvedUseId: '46',
    approvedUseName: 'Substrate Primer',
    // TODO: await fixes
    measurementAttributes: [
      { columnName: 'ApplicationRateWet', measureType: MeasureType.VolumePerUnitArea },
      { columnName: 'ApplicationRateDry', measureType: MeasureType.SolidApplicationRate },
    ],
  },
  {
    approvedUseId: '29',
    approvedUseName: 'Fastening (Fastener)',
    measurementAttributes: [
      { columnName: 'Fastener_LengthMax', measureType: MeasureType.Length },
      { columnName: 'Fastener_LengthMin', measureType: MeasureType.Length },
      { columnName: 'Fastener_Diameter', measureType: MeasureType.Length },
    ],
  },
  {
    approvedUseId: '30',
    approvedUseName: 'Fastening (Pre-assembled Fastening)',
    measurementAttributes: [
      { columnName: 'PreassembledFastening_LengthMax', measureType: MeasureType.Length },
      { columnName: 'PreassembledFastening_LengthMin', measureType: MeasureType.Length },
      { columnName: 'PreassembledFastening_FastenerDiameter', measureType: MeasureType.Length },
    ],
  },
  {
    approvedUseId: '83',
    approvedUseName: 'Anchors, Roof Mounted Equipment',
    measurementAttributes: [
      { columnName: 'Anchor_VerticalLoadMax', measureType: MeasureType.Force },
      { columnName: 'Anchor_ShearLoadMax', measureType: MeasureType.Force },
    ],
  },
]