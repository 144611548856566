import React from 'react';
import { useGetBaseCoatUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { SolidApplicationRate } from '../../utils/Measurement/SolidApplicationRate';
import { VolumePerUnitArea } from '../../utils/Measurement/VolumePerUnitArea';
import './UsageDetails.scss';

export const BaseCoatUsageDetail = (props: any) => {
  const { data, loading, error } = useGetBaseCoatUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];
  const isMetric = usage?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Cover, Liquid Applied (Base Coat)</h4>
      <table>
        <tr>
          <td>Number of Coats:</td>
          <td>{usage?.BaseCoatUsage?.NumCoats}</td>
        </tr>
        <tr>
          <td>Material:</td>
          <td>{usage?.Component?.ManufacturedProduct?.BaseCoat?.C_LiquidAppliedMaterial.LiquidAppliedMaterialCode}</td>
        </tr>
        <tr>
          <td>Application Rate Wet:</td>
          <td>{VolumePerUnitArea.display(usage?.BaseCoatUsage?.ApplicationRateMinWet, isMetric)}</td>
        </tr>
        <tr>
          <td>Application Rate Dry:</td>
          <td>{SolidApplicationRate.display(usage?.BaseCoatUsage?.ApplicationRateMinDry, isMetric)}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
