import React from 'react';
import { useGetSeamTapeSystemUsageDetailQuery } from '../../models/GQLGeneratedModels';
import './UsageDetails.scss'

export const SeamTapeSystemUsageDetail = (props: any) => {
  const { data, loading, error } = useGetSeamTapeSystemUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];

  return (
    <div className={'usage-detail p-3'}>
      <h4>Seam Tape System</h4>
      <table>
        <tr>
          <td valign="top">
            System Comments:
          </td>
          <td>{usage?.Component.Comments}</td>
        </tr>
        <tr>
          <td valign="top">Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
