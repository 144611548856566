import React from 'react';
import { useGetCompositePanelCoverUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { Length } from '../../utils/Measurement/Length';
import './UsageDetails.scss';

export const CompositePanelCoverUsageDetail = (props: any) => {
  const { data, loading, error } = useGetCompositePanelCoverUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];

  return (
    <div className={'usage-detail p-3'}>
      <h4>Cover (Composite Panel)</h4>
      <table>
        <tr>
          <td>Material:</td>
          <td>{usage?.Component?.ManufacturedProduct?.CompositePanelCover?.C_InsulationCore.InsulationCoreCode}</td>
        </tr>
        <tr>
          <td>Max Width:</td>
          <td>{Length.display(usage?.CompositePanelUsage?.WidthMax, usage?.EnteredAsMetric)}</td>
        </tr>
        <tr>
          <td>Min Width:</td>
          <td>
            {Length.display(
              usage?.Component?.ManufacturedProduct?.CompositePanelCover?.WidthMin,
              usage?.EnteredAsMetric
            )}
          </td>
        </tr>
        <tr>
          <td>Min Thickness:</td>
          <td>{Length.display(usage?.CompositePanelUsage?.ThicknessMin, usage?.EnteredAsMetric)}</td>
        </tr>
        <tr>
          <td>Max Thickness:</td>
          <td>
            {Length.display(
              usage?.Component?.ManufacturedProduct?.CompositePanelCover?.ThicknessMax,
              usage?.EnteredAsMetric
            )}
          </td>
        </tr>
        <tr>
          <td>Top Facer:</td>
          <td>{usage?.Component?.ManufacturedProduct?.CompositePanelCover?.C_FacerMaterial.FacerMaterialCode}</td>
        </tr>
        <tr>
          <td>Top Facer Thickness:</td>
          <td>
            {Length.display(
              usage?.Component?.ManufacturedProduct?.CompositePanelCover?.FacerThicknessTop,
              usage?.EnteredAsMetric
            )}
          </td>
        </tr>
        <tr>
          <td>Bottom Facer:</td>
          <td>
            {
              usage?.Component?.ManufacturedProduct?.CompositePanelCover?.cFacermaterialByBottomfacermaterialid
                .FacerMaterialCode
            }
          </td>
        </tr>
        <tr>
          <td>Bottom Facer Thickness:</td>
          <td>
            {Length.display(
              usage?.Component?.ManufacturedProduct?.CompositePanelCover?.FacerThicknessBottom,
              usage?.EnteredAsMetric
            )}
          </td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
