import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { COMPONENT_MAP } from '../constants/COMPONENT_MAP';
import { NavAssembly_Order_By, Order_By } from '../../models/GQLGeneratedModels';
import { useGetAssembliesByComponentApprovedUse } from '../../services/AssemblyService';
import { ApplicationContext } from '../ApplicationProvider';
import { ErrorModalComponent } from '../shared/ErrorModalComponent/ErrorModalComponent';
import { Dialog } from '../shared/DialogComponent/Dialog';
import { TableComponent } from '../shared/TableComponent/TableComponent';
import { Typography } from '@mui/material';
import theme from '../../theme';
import { TextOnlyComponent } from '../shared/TextOnlyComponent/TextOnlyComponent';

export const AssemblyListProductApprovedUse: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const componentId = searchParams.get('componentid') ? Number(searchParams.get('componentid')) : 0;
  const approveduseId = searchParams.get('approveduseid') ? Number(searchParams.get('approveduseid')) : 0;
  const count: number = searchParams.get('count') ? Number(searchParams.get('count')) : 25;
  const { itemOffset, itemsPerPage, setItemOffset, setItemsPerPage, setDialog, showDialog, setShowDialog } =
    useContext(ApplicationContext);

  const [columnHeaders, setColumnHeaders] = useState(COMPONENT_MAP.AssemblyView.colHeads);
  const [fieldSort, setFieldSort] = useState('Assembly #');
  const [sortDir, setSortDir] = useState('asc');
  const [orderbyInfo, setOrderbyInfo] = useState<NavAssembly_Order_By>({
    AssemblyId: Order_By.Asc,
    CoverSubassemblyId: Order_By.Asc,
    DeckSubassemblyId: Order_By.Asc,
  });

  const { data, loading, error } = useGetAssembliesByComponentApprovedUse(
    componentId,
    approveduseId,
    itemsPerPage,
    itemOffset,
    orderbyInfo
  );
  useEffect(() => {
    if (error) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [error]);

  const navigateToDetail = (navAssemblyId: number) => {
    navigate(`/assemblyDetail/${navAssemblyId}?linkedProductSearch=1`);
  };

  // Invoke when user click to change different page size
  const handlePageSizeChange = (event: any) => {
    const newPageSize = parseInt(event.target.value);
    setItemsPerPage(newPageSize);
    setItemOffset(0); // Reset to be the first page
  };

  // Invoke when user click to request another page.
  const handlePageClick = async (event: any) => {
    if (!count) return;
    const newOffset = (event.selected * itemsPerPage) % count;
    setItemOffset(newOffset);
  };

  const handleSort = (field: string) => {
    const headers = [...COMPONENT_MAP.AssemblyView.colHeads];
    let newSortDir: string;
    if (fieldSort === field) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
      for (let i = 0; i < headers.length; i++) {
        if (headers[i].className !== 'notvisible') {
          if (headers[i].name === field) {
            headers[i].className =
              newSortDir === 'asc' ? 'th-inner sortable both sorted asc' : 'th-inner sortable both sorted desc';
          } else {
            headers[i].className = 'th-inner sortable both unsorted';
          }
        }
      }
    } else {
      newSortDir = 'asc';
      for (let i = 0; i < headers.length; i++) {
        if (headers[i].className !== 'notvisible') {
          if (headers[i].name === field) {
            headers[i].className = 'th-inner sortable both sorted asc';
          } else {
            headers[i].className = 'th-inner sortable both unsorted';
          }
        }
      }
    }

    // Get order_by variable
    let orderBy: NavAssembly_Order_By;
    const orderByType: Order_By = newSortDir === 'asc' ? Order_By.Asc : Order_By.Desc;
    switch (field) {
      case 'Assembly #':
        //orderBy = { NavAssemblyName: orderByType };
        orderBy = { AssemblyId: orderByType, CoverSubassemblyId: orderByType, DeckSubassemblyId: orderByType };
        break;
      case 'Cover':
        //orderBy = { AssemblyTypeId: orderByType };
        //orderBy = { RoofAssembly: { C_AssemblyType: { AssemblyTypeCode: orderByType } } };
        orderBy = { AssemblyTypeCode: orderByType };
        break;
      case 'Application':
        //orderBy = { AssemblyApplicationTypeId: orderByType };
        //orderBy = { RoofAssembly: { C_AssemblyApplicationType: { AssemblyApplicationTypeCode: orderByType } } };
        orderBy = { AssemblyApplicationTypeCode: orderByType };
        break;
      case 'Securement':
        //orderBy = { CoverSecurementMethodId: orderByType };
        //orderBy = { RoofAssembly: { C_CoverSecurementMethod: { CoverSecurementMethodCode: orderByType } } };
        orderBy = { CoverSecurementMethodCode: orderByType };
        break;
      case 'Deck':
        //orderBy = { DeckTypeId: orderByType };
        //orderBy = { RoofAssembly: { C_DeckType: { DeckTypeCode: orderByType } } };
        orderBy = { DeckTypeCode: orderByType };
        break;
      case 'Wind Uplift':
        //orderBy = { WindUplift: orderByType };
        orderBy = { WindUpliftValue: orderByType };
        break;
      case 'I/Fire':
        //orderBy = { IntFireRatingId: orderByType };
        //orderBy = { RoofAssembly: { C_IntFireRating: { IntFireRatingCode: orderByType } } };
        orderBy = { IntFireRatingCode: orderByType };
        break;
      case 'E/Fire':
        //orderBy = { ExtFireRatingId: orderByType };
        //orderBy = { RoofAssembly: { C_ExtFireRating: { ExtFireRatingCode: orderByType } } };
        orderBy = { ExtFireRatingCode: orderByType };
        break;
      case 'Slope':
        //orderBy = { Slope: orderByType };
        orderBy = { SlopeValue: orderByType };
        break;
      case 'Hail':
        //orderBy = { HailRatingId: orderByType };
        //orderBy = { RoofAssembly: { C_HailRating: { HailRatingCode: orderByType } } };
        orderBy = { HailRatingCode: orderByType };
        break;
      default:
        //orderBy = { NavAssemblyName: Order_By.Asc };
        orderBy = { AssemblyId: Order_By.Asc, CoverSubassemblyId: Order_By.Asc, DeckSubassemblyId: Order_By.Asc };
    }

    setOrderbyInfo(orderBy);

    setFieldSort(field);
    setSortDir(newSortDir);
    setColumnHeaders(headers);
  };

  const handleBackClick = () => {
    history.back();
  };

  return (
    <section className="bg-white h-100 overflow-hidden">
      <div style={{ marginTop: '20px' }}></div>
      <div className="row align-items-stretch">
        <div className="col">
          <div className="d-flex align-items-center p-3">
            <Typography color={theme.palette.rnOrange.darkOrange} className={'headline-1'}>
              {count} Matching Assemblies
            </Typography>
          </div>
        </div>
        <div className="col px-4 my-1 text-right align-text-bottom" style={{ marginRight: '50px' }}>
          <Link onClick={handleBackClick} to={'#'}>
            <TextOnlyComponent buttonText={'Back'} iconName={'arrow_left'} />
          </Link>
        </div>
      </div>
      <div id="roofnav_results" className="p-3">
        <TableComponent
          loading={loading}
          count={count}
          offset={itemOffset}
          limit={itemsPerPage}
          handlePageSizeChange={handlePageSizeChange}
          handlePageClick={handlePageClick}
          handleSort={handleSort}
          colHeads={columnHeaders}
          navigateToDetail={navigateToDetail}
          rows={
            data &&
            data.product_api_matchedassemblylist?.map((navAssembly: any) => {
              const row: string[] = [];
              row.push(navAssembly?.NavAssemblyId.toString() as string);
              row.push(navAssembly?.NavAssemblyName as string);
              row.push(navAssembly?.AssemblyTypeCode as string);
              row.push(navAssembly?.AssemblyApplicationTypeCode as string);
              row.push(navAssembly?.CoverSecurementMethodCode as string);
              row.push(navAssembly?.DeckTypeCode as string);
              row.push(navAssembly?.WindUplift.toString() as string);
              row.push((navAssembly?.IntFireRatingCode as string).trim());
              row.push(navAssembly?.ExtFireRatingCode as string);
              row.push(navAssembly?.Slope.toString() as string);
              row.push(navAssembly?.HailRatingCode as string);
              return row;
            })
          }
        />
        <div className="clearfix"></div>
      </div>
    </section>
  );
};
