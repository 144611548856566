import React, { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import "./TextLinkComponent.scss";


interface ITextLinkComponentProps extends HTMLAttributes<HTMLDivElement> {
    linkText: string;
    linkUrl: string;
    linkStyle?: string;
    testId?: string;
    isExternal?: boolean
}

export const TextLinkComponent = ({ linkText, linkUrl, linkStyle, testId, isExternal }: ITextLinkComponentProps) => {
    return (
        <span className="rntextlink">
            {isExternal ?
                <a
                    href={linkUrl}
                    target={'_blank'}
                    rel="noreferrer"
                    className={"text-link " + (linkStyle ? linkStyle : "")}
                    tabIndex={0}
                    data-testid={testId}
                >
                    {linkText}
                </a>
                :
                <Link
                    to={linkUrl}
                    className={"text-link " + (linkStyle ? linkStyle : "")}
                    tabIndex={0}
                    data-testid={testId}
                >
                    {linkText}
                </Link>
            }
        </span>
    )
}