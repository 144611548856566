import React, { useContext } from 'react';
import { ApplicationContext } from './ApplicationProvider';

export const LoadingComp = () => {
  const { loading } = useContext(ApplicationContext);
  return (
    <>
      <div id="overlay" className={loading ? 'loading' : ''}></div>
    </>
  );
};
