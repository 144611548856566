export const ENCLOSURE_CLASSIFICATION = [
    { value: 0, name: 'Enclosed' },
    { value: 1, name: 'Partially Enclosed' },
    { value: 2, name: 'Open' }
];
export const SURFACE_ROUGHNESS = [
    { value: 0, name: 'B' },
    { value: 1, name: 'C' },
    { value: 2, name: 'D' }
];
export const INTERNAL_FIRE = [
    { value: 1, name: 'NC' },
    { value: 2, name: '1' }
];
export const EXTERNAL_FIRE = [
    { value: 1, name: 'A' },
    { value: 2, name: 'B' },
    { value: 3, name: 'C' }
];
export const HAIL = [
    { value: 1, name: 'SH' },
    { value: 2, name: 'MH' },
    { value: 3, name: 'VSH' }
];
export const SLOPE_RISE = [
    { value: '0.125', name: '0.125' },
    { value: '0.25', name: '0.25' },
    { value: '0.375', name: '0.375' },
    { value: '0.5', name: '0.5' },
    { value: '0.625', name: '0.625' },
    { value: '0.75', name: '0.75' },
    { value: '0.875', name: '0.875' },
    { value: '1', name: '1' },
    { value: '1.125', name: '1.125' },
    { value: '1.25', name: '1.25' },
    { value: '1.375', name: '1.375' },
    { value: '1.5', name: '1.5' },
    { value: '1.625', name: '1.625' },
    { value: '1.75', name: '1.75' },
    { value: '1.875', name: '1.875' },
    { value: '2', name: '2' },
    { value: '2.125', name: '2.125' },
    { value: '2.25', name: '2.25' },
    { value: '2.375', name: '2.375' },
    { value: '2.5', name: '2.5' },
    { value: '2.625', name: '2.625' },
    { value: '2.75', name: '2.75' },
    { value: '2.875', name: '2.875' },
    { value: '3', name: '3' },
    { value: '3.125', name: '3.125' },
    { value: '3.25', name: '3.25' },
    { value: '3.375', name: '3.375' },
    { value: '3.5', name: '3.5' },
    { value: '3.625', name: '3.625' },
    { value: '3.75', name: '3.75' },
    { value: '3.875', name: '3.875' },
    { value: '4', name: '4' },
    { value: '4.125', name: '4.125' },
    { value: '4.25', name: '4.25' },
    { value: '4.375', name: '4.375' },
    { value: '4.5', name: '4.5' },
    { value: '4.625', name: '4.625' },
    { value: '4.75', name: '4.75' },
    { value: '4.875', name: '4.875' },
    { value: '5', name: '5' }
];
export const SLOPE_DEGREE = [
    { value: '0.6', name: '0.6\u00B0' },
    { value: '1.2', name: '1.2\u00B0' },
    { value: '1.8', name: '1.8\u00B0' },
    { value: '2.4', name: '2.4\u00B0' },
    { value: '3', name: '3\u00B0' },
    { value: '3.6', name: '3.6\u00B0' },
    { value: '4.2', name: '4.2\u00B0' },
    { value: '4.8', name: '4.8\u00B0' },
    { value: '5.4', name: '5.4\u00B0' },
    { value: '6', name: '6\u00B0' },
    { value: '6.5', name: '6.5\u00B0' },
    { value: '7.1', name: '7.1\u00B0' },
    { value: '7.7', name: '7.7\u00B0' },
    { value: '8.3', name: '8.3\u00B0' },
    { value: '8.9', name: '8.9\u00B0' },
    { value: '9.5', name: '9.5\u00B0' },
    { value: '10', name: '10\u00B0' },
    { value: '10.6', name: '10.6\u00B0' },
    { value: '11.2', name: '11.2\u00B0' },
    { value: '11.8', name: '11.8\u00B0' },
    { value: '12.3', name: '12.3\u00B0' },
    { value: '12.9', name: '12.9\u00B0' },
    { value: '13.5', name: '13.5\u00B0' },
    { value: '14', name: '14\u00B0' },
    { value: '14.6', name: '14.6\u00B0' },
    { value: '15.2', name: '15.2\u00B0' },
    { value: '15.7', name: '15.7\u00B0' },
    { value: '16.3', name: '16.3\u00B0' },
    { value: '16.8', name: '16.8\u00B0' },
    { value: '17.4', name: '17.4\u00B0' },
    { value: '17.9', name: '17.9\u00B0' },
    { value: '18.4', name: '18.4\u00B0' },
    { value: '19', name: '19\u00B0' },
    { value: '19.5', name: '19.5\u00B0' },
    { value: '20', name: '20\u00B0' },
    { value: '20.6', name: '20.6\u00B0' },
    { value: '21', name: '21\u00B0' },
    { value: '21.6', name: '21.6\u00B0' },
    { value: '22.1', name: '22.1\u00B0' },
    { value: '22.6', name: '22.6\u00B0' },
];
export const SUMMARY_COLUMNS = ['Zone', 'Wind Pressures (psf)', 'Roof', 'Fascia', 'Coping', 'Gutter'];
export const MOCK_RATING_DATA = [
    {
        "pressureZone": 1,
        "pressure": 399.71975340680285,
        "rating": 990,
        "copingRating": null,
        "fasciaRating": null,
        "strips": []
    },
    {
        "pressureZone": 2,
        "pressure": 627.4082205372603,
        "rating": 990,
        "copingRating": 750,
        "fasciaRating": 570,
        "strips": [
            {
                "dimensionType": 1,
                "measurmentValue": 10,
                "buildingStateType": 0
            },
            {
                "dimensionType": 1,
                "measurmentValue": 10,
                "buildingStateType": 1
            }
        ]
    },
    {
        "pressureZone": 3,
        "pressure": 627.4082205372603,
        "rating": 990,
        "copingRating": 840,
        "fasciaRating": 840,
        "strips": [
            {
                "dimensionType": 1,
                "measurmentValue": 10,
                "buildingStateType": 0
            },
            {
                "dimensionType": 0,
                "measurmentValue": 20,
                "buildingStateType": 1
            },
            {
                "dimensionType": 1,
                "measurmentValue": 20,
                "buildingStateType": 1
            },
            {
                "dimensionType": 2,
                "measurmentValue": 10,
                "buildingStateType": 1
            }
        ]
    }
]

export const ROOF_PRESSURE_ZONE = [
    { value: 4, name: 'Zone 1 Prime' },
    { value: 1, name: 'Zone 1' },
    { value: 2, name: 'Zone 2' },
    { value: 5, name: 'Zone 2E' },
    { value: 7, name: 'Zone 2N' },
    { value: 8, name: 'Zone 2R' },
    { value: 3, name: 'Zone 3' },
    { value: 6, name: 'Zone 3E' },
    { value: 9, name: 'Zone 3R' }
];
export const PSF_KSF_CONVERSION_CONSTANT = 0.047880256944;
export const EFFECTIVE_WIND_AREA = 10;
export const DIRECTIONALITY_FACTOR = 0.85;
export const ELEVATION_MEAN = 1.0;
export const TOPOGRAPHICAL_FACTOR = 1;
export const IMPORTANCE_FACTOR = 1.15;
export const SAFETY_FACTOR = 2;

// hardcoded table of wind uplift values to eliminate need for call to ConfigitProvider in the calculator
// format is compatible with ISelectValue type used in select component
export const WIND_UPLIFT =
    [
        {
            value: "65",
            name: "65"
        },
        {
            value: "70",
            name: "70"
        },
        {
            value: "75",
            name: "75"
        },
        {
            value: "80",
            name: "80"
        },
        {
            value: "85",
            name: "85"
        },
        {
            value: "90",
            name: "90"
        },
        {
            value: "95",
            name: "95"
        },
        {
            value: "100",
            name: "100"
        },
        {
            value: "105",
            name: "105"
        },
        {
            value: "110",
            name: "110"
        },
        {
            value: "115",
            name: "115"
        },
        {
            value: "120",
            name: "120"
        },
        {
            value: "125",
            name: "125"
        },
        {
            value: "130",
            name: "130"
        },
        {
            value: "135",
            name: "135"
        },
        {
            value: "140",
            name: "140"
        },
        {
            value: "145",
            name: "145"
        },
        {
            value: "150",
            name: "150"
        },
        {
            value: "155",
            name: "155"
        },
        {
            value: "160",
            name: "160"
        },
        {
            value: "165",
            name: "165"
        },
        {
            value: "170",
            name: "170"
        },
        {
            value: "175",
            name: "175"
        },
        {
            value: "180",
            name: "180"
        },
        {
            value: "185",
            name: "185"
        },
        {
            value: "190",
            name: "190"
        },
        {
            value: "195",
            name: "195"
        },
        {
            value: "200",
            name: "200"
        },
        {
            value: "205",
            name: "205"
        },
        {
            value: "210",
            name: "210"
        },
        {
            value: "215",
            name: "215"
        },
        {
            value: "220",
            name: "220"
        },
        {
            value: "225",
            name: "225"
        },
        {
            value: "230",
            name: "230"
        },
        {
            value: "235",
            name: "235"
        },
        {
            value: "240",
            name: "240"
        },
        {
            value: "245",
            name: "245"
        },
        {
            value: "250",
            name: "250"
        },
        {
            value: "255",
            name: "255"
        },
        {
            value: "260",
            name: "260"
        },
        {
            value: "265",
            name: "265"
        },
        {
            value: "270",
            name: "270"
        },
        {
            value: "275",
            name: "275"
        },
        {
            value: "280",
            name: "280"
        },
        {
            value: "285",
            name: "285"
        },
        {
            value: "290",
            name: "290"
        },
        {
            value: "295",
            name: "295"
        },
        {
            value: "300",
            name: "300"
        },
        {
            value: "305",
            name: "305"
        },
        {
            value: "310",
            name: "310"
        },
        {
            value: "315",
            name: "315"
        },
        {
            value: "320",
            name: "320"
        },
        {
            value: "325",
            name: "325"
        },
        {
            value: "330",
            name: "330"
        },
        {
            value: "335",
            name: "335"
        },
        {
            value: "340",
            name: "340"
        },
        {
            value: "345",
            name: "345"
        },
        {
            value: "350",
            name: "350"
        },
        {
            value: "355",
            name: "355"
        },
        {
            value: "360",
            name: "360"
        },
        {
            value: "365",
            name: "365"
        },
        {
            value: "370",
            name: "370"
        },
        {
            value: "375",
            name: "375"
        },
        {
            value: "380",
            name: "380"
        },
        {
            value: "385",
            name: "385"
        },
        {
            value: "390",
            name: "390"
        },
        {
            value: "395",
            name: "395"
        },
        {
            value: "400",
            name: "400"
        },
        {
            value: "405",
            name: "405"
        },
        {
            value: "410",
            name: "410"
        },
        {
            value: "415",
            name: "415"
        },
        {
            value: "420",
            name: "420"
        },
        {
            value: "425",
            name: "425"
        },
        {
            value: "430",
            name: "430"
        },
        {
            value: "435",
            name: "435"
        },
        {
            value: "440",
            name: "440"
        },
        {
            value: "445",
            name: "445"
        },
        {
            value: "450",
            name: "450"
        },
        {
            value: "455",
            name: "455"
        },
        {
            value: "460",
            name: "460"
        },
        {
            value: "465",
            name: "465"
        },
        {
            value: "470",
            name: "470"
        },
        {
            value: "475",
            name: "475"
        },
        {
            value: "480",
            name: "480"
        },
        {
            value: "485",
            name: "485"
        },
        {
            value: "490",
            name: "490"
        },
        {
            value: "495",
            name: "495"
        },
        {
            value: "500",
            name: "500"
        },
        {
            value: "505",
            name: "505"
        },
        {
            value: "510",
            name: "510"
        },
        {
            value: "515",
            name: "515"
        },
        {
            value: "520",
            name: "520"
        },
        {
            value: "525",
            name: "525"
        },
        {
            value: "530",
            name: "530"
        },
        {
            value: "535",
            name: "535"
        },
        {
            value: "540",
            name: "540"
        },
        {
            value: "545",
            name: "545"
        },
        {
            value: "550",
            name: "550"
        },
        {
            value: "555",
            name: "555"
        },
        {
            value: "560",
            name: "560"
        },
        {
            value: "565",
            name: "565"
        },
        {
            value: "570",
            name: "570"
        },
        {
            value: "575",
            name: "575"
        },
        {
            value: "580",
            name: "580"
        },
        {
            value: "585",
            name: "585"
        },
        {
            value: "590",
            name: "590"
        },
        {
            value: "595",
            name: "595"
        },
        {
            value: "600",
            name: "600"
        },
        {
            value: "605",
            name: "605"
        },
        {
            value: "610",
            name: "610"
        },
        {
            value: "615",
            name: "615"
        },
        {
            value: "620",
            name: "620"
        },
        {
            value: "625",
            name: "625"
        },
        {
            value: "630",
            name: "630"
        },
        {
            value: "635",
            name: "635"
        },
        {
            value: "640",
            name: "640"
        },
        {
            value: "645",
            name: "645"
        },
        {
            value: "650",
            name: "650"
        },
        {
            value: "655",
            name: "655"
        },
        {
            value: "660",
            name: "660"
        },
        {
            value: "665",
            name: "665"
        },
        {
            value: "670",
            name: "670"
        },
        {
            value: "675",
            name: "675"
        },
        {
            value: "680",
            name: "680"
        },
        {
            value: "685",
            name: "685"
        },
        {
            value: "690",
            name: "690"
        },
        {
            value: "695",
            name: "695"
        },
        {
            value: "700",
            name: "700"
        },
        {
            value: "705",
            name: "705"
        },
        {
            value: "710",
            name: "710"
        },
        {
            value: "715",
            name: "715"
        },
        {
            value: "720",
            name: "720"
        },
        {
            value: "725",
            name: "725"
        },
        {
            value: "730",
            name: "730"
        },
        {
            value: "735",
            name: "735"
        },
        {
            value: "740",
            name: "740"
        },
        {
            value: "745",
            name: "745"
        },
        {
            value: "750",
            name: "750"
        },
        {
            value: "755",
            name: "755"
        },
        {
            value: "760",
            name: "760"
        },
        {
            value: "765",
            name: "765"
        },
        {
            value: "770",
            name: "770"
        },
        {
            value: "775",
            name: "775"
        },
        {
            value: "780",
            name: "780"
        },
        {
            value: "785",
            name: "785"
        },
        {
            value: "790",
            name: "790"
        },
        {
            value: "795",
            name: "795"
        },
        {
            value: "800",
            name: "800"
        },
        {
            value: "805",
            name: "805"
        },
        {
            value: "810",
            name: "810"
        },
        {
            value: "815",
            name: "815"
        },
        {
            value: "820",
            name: "820"
        },
        {
            value: "825",
            name: "825"
        },
        {
            value: "830",
            name: "830"
        },
        {
            value: "835",
            name: "835"
        },
        {
            value: "840",
            name: "840"
        },
        {
            value: "845",
            name: "845"
        },
        {
            value: "850",
            name: "850"
        },
        {
            value: "855",
            name: "855"
        },
        {
            value: "860",
            name: "860"
        },
        {
            value: "865",
            name: "865"
        },
        {
            value: "870",
            name: "870"
        },
        {
            value: "875",
            name: "875"
        },
        {
            value: "880",
            name: "880"
        },
        {
            value: "885",
            name: "885"
        },
        {
            value: "890",
            name: "890"
        },
        {
            value: "895",
            name: "895"
        },
        {
            value: "900",
            name: "900"
        },
        {
            value: "905",
            name: "905"
        },
        {
            value: "910",
            name: "910"
        },
        {
            value: "915",
            name: "915"
        },
        {
            value: "920",
            name: "920"
        },
        {
            value: "925",
            name: "925"
        },
        {
            value: "930",
            name: "930"
        },
        {
            value: "935",
            name: "935"
        },
        {
            value: "940",
            name: "940"
        },
        {
            value: "945",
            name: "945"
        },
        {
            value: "950",
            name: "950"
        },
        {
            value: "955",
            name: "955"
        },
        {
            value: "960",
            name: "960"
        },
        {
            value: "965",
            name: "965"
        },
        {
            value: "970",
            name: "970"
        },
        {
            value: "975",
            name: "975"
        },
        {
            value: "980",
            name: "980"
        },
        {
            value: "985",
            name: "985"
        },
        {
            value: "990",
            name: "990"
        }
    ];

