import React from 'react';
import { useGetVaporRetarderUsageDetailQuery } from '../../models/GQLGeneratedModels';
import './UsageDetails.scss';

export const VaporRetarderUsageDetail = (props: any) => {
  const { data, loading, error } = useGetVaporRetarderUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];

  return (
    <div className={'usage-detail p-3'}>
      <h4>Vapor Retarder</h4>
      <table>
        <tr>
          <td>Material:</td>
          <td>{usage?.Component?.ManufacturedProduct?.VaporRetarder?.C_RetarderMaterial.RetarderMaterialCode}</td>
        </tr>
        <tr>
          <td>Number of Plies:</td>
          <td>{usage?.VaporRetarderUsage?.NumPlies}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
