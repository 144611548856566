import { apolloClient } from '../ApolloClient';
import {
  GetListScenarioDetailByEmailDocument,
  GetListScenarioDetailByEmailQuery,
  GetListScenarioDetailByEmailQueryVariables,
  GetScenarioDetailDocument,
  GetScenarioDetailQuery,
  GetScenarioDetailQueryVariables,
  GetScenarioFullInfoDocument,
  GetScenarioFullInfoQuery,
  GetScenarioFullInfoQueryVariables,
  GetSinglePlyAdhesiveRelatedUsagesDocument,
  GetSinglePlyAdhesiveRelatedUsagesQuery,
  GetSinglePlyAdhesiveRelatedUsagesQueryVariables,
  SaveScenarioDocument,
  SaveScenarioQuery,
  SaveScenarioQueryVariables,
  ScenarioInfo,
} from '../models/GQLGeneratedModels';
//import { IScenarioForAPI } from '../pages/AssemblyDetail/SaveModal';

export const saveAssemblyScenario = async (scenarioToSave: ScenarioInfo, operationType: number) => {
  const scenarioInfoAndOperator = { Scenario: scenarioToSave, SaveOperationType: operationType }; // TODO need to populate data for Scenario
  // One sample scenarioInfoAndOperator is as follows
  // {"Scenario":{"ScenarioStatusId":1,"Name":"TestScenario123","NavAssemblyId":1873424,"NavAssemblyName":"239-0-0","ScenarioTypeId":3,"Comments":"Test comments here","AzureB2CUserEmail":"heng.chen@fmglobal.com","AzureB2CUserGuid":"acbd6821-a26d-41d1-bc07-c764463f4ef8","ScenarioLayers":[{"NavLayerId":260969,"LayerNum":1,"LayerText":"1. Cover (Single-ply)","LayerTypeID":4,"IsPermeable":false,"IsOptional":false,"ScenarioUsages":[{"UsageId":4704574,"LayerNum":1},{"UsageId":5135029,"LayerNum":1,"SelfSecurementText":"Securement (Sheet Lap)","SelfSecurementLayerTypeId":40}]},{"NavLayerId":4702055,"LayerNum":2,"LayerText":"2. Securement (Cover) from 1. Cover (Single-ply) to 5. (Deck) Steel","LayerTypeID":39,"IsPermeable":false,"IsOptional":false,"ScenarioUsages":[{"UsageId":31120015,"LayerNum":2}]},{"NavLayerId":228901,"LayerNum":3,"LayerText":"3. Insulation (Board Stock)","LayerTypeID":28,"IsPermeable":false,"IsOptional":false,"ScenarioUsages":[{"UsageId":2990562,"LayerNum":3}]},{"NavLayerId":4677173,"LayerNum":4,"LayerText":"4. Securement (Board Stock) from 3. Insulation (Board Stock) to 5. (Deck) Steel","LayerTypeID":42,"IsPermeable":false,"IsOptional":false,"ScenarioUsages":[{"UsageId":5138064,"LayerNum":4}]},{"NavLayerId":137205,"LayerNum":5,"LayerText":"5. (Deck) Steel","LayerTypeID":17,"IsPermeable":false,"IsOptional":false,"ScenarioUsages":[{"UsageId":3405341,"LayerNum":5},{"UsageId":24636632,"LayerNum":5,"SelfSecurementText":"Securement (Deck Lap)","SelfSecurementLayerTypeId":43}]},{"NavLayerId":4604883,"LayerNum":6,"LayerText":"6. Securement (Deck) from 5. (Deck) Steel to 7. Structure","LayerTypeID":44,"IsPermeable":false,"IsOptional":false,"ScenarioUsages":[{"UsageId":24511809,"LayerNum":6}]},{"NavLayerId":290405,"LayerNum":7,"LayerText":"7. Structure","LayerTypeID":35,"IsPermeable":false,"IsOptional":false,"ScenarioUsages":[]}]},"SaveOperationType":1}

  const result = await apolloClient.query<SaveScenarioQuery, SaveScenarioQueryVariables>({
    query: SaveScenarioDocument,
    variables: {
      scenarioInfoSave: scenarioInfoAndOperator,
    },
  });
  // Sample response is as follows
  /* 
  {
    "data": {
      "scenario_api_savescenario": [
        {
          "ScenarioId": 175343
        }
      ]
    }
  }
  */

  let resultScenarioId = 0;
  if (result.data?.scenario_api_savescenario)
    resultScenarioId = result.data?.scenario_api_savescenario[0]?.ScenarioId as number;

  return resultScenarioId;
};
export const deleteAssemblyScenario = async (scenarioToSave: ScenarioInfo) => {
  const scenarioInfoAndOperator = { Scenario: scenarioToSave, SaveOperationType: 3 }; // TODO need to populate data for Scenario

  const result = await apolloClient.query<SaveScenarioQuery, SaveScenarioQueryVariables>({
    query: SaveScenarioDocument,
    variables: {
      scenarioInfoSave: scenarioInfoAndOperator,
    },
  });
  let resultScenarioId = 0;
  if (result.data?.scenario_api_savescenario)
    resultScenarioId = result.data?.scenario_api_savescenario[0]?.ScenarioId as number;

  return resultScenarioId;
};
export const getSavedScenarioByEmail = async (userEmail: string, randomId: string) => {
  const result = await apolloClient.query<
    GetListScenarioDetailByEmailQuery,
    GetListScenarioDetailByEmailQueryVariables
  >({
    query: GetListScenarioDetailByEmailDocument,
    variables: {
      UserEmail: userEmail,
      RandomId: randomId
    },
  });
  return result.data.Scenario;
};

export const getScenarioDetailById = async (scenarioId: number, userEmail: string, randomId: string) => {
  const result = await apolloClient.query<GetScenarioDetailQuery, GetScenarioDetailQueryVariables>({
    query: GetScenarioDetailDocument,
    variables: {
      ScenarioId: scenarioId,
      AzureB2CUserEmail: userEmail,
      RandomId: randomId
    },
  });
  return result.data.Scenario;
};

export const getScenarioFullInfoById = async (scenarioId: number, userEmail: string, randomId: string) => {
  const result = await apolloClient.query<GetScenarioFullInfoQuery, GetScenarioFullInfoQueryVariables>({
    query: GetScenarioFullInfoDocument,
    variables: {
      ScenarioId: scenarioId,
      AzureB2CUserEmail: userEmail,
      RandomId: randomId
    },
  });
  return result.data.Scenario;
};

export const getSinglePlyAdhesiveRelatedUsagesByNavAssemblyId = async (navAssemblyId: number) => {
  const result = await apolloClient.query<
    GetSinglePlyAdhesiveRelatedUsagesQuery,
    GetSinglePlyAdhesiveRelatedUsagesQueryVariables
  >({
    query: GetSinglePlyAdhesiveRelatedUsagesDocument,
    variables: {
      NavAssemblyId: navAssemblyId,
    },
  });
  return result.data.NavAssembly;
};
export const getScenarioDetailByEmail = async (userEmail: string) => {
  const result = await apolloClient.query<
    GetListScenarioDetailByEmailQuery,
    GetListScenarioDetailByEmailQueryVariables
  >({
    query: GetListScenarioDetailByEmailDocument,
    variables: {
      UserEmail: userEmail,
    },
  });
  return result.data.Scenario;
};
export const genrateUuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const uuid = (Math.random() * 16) | 0,
      v = c == 'x' ? uuid : (uuid & 0x3) | 0x8;
    return uuid.toString(16);
  });
}
