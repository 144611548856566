export class VolumePerUnitArea {
  static GALLONSSQUARE_TO_LITERSSQUAREMETER = 0.407458;
  static IMPERIALUNIT = 'gal/square';
  static METRICUNIT = 'l/m2';

  static convertImperialToMetric(valueImperial: number) {
    const valueMetric = valueImperial * VolumePerUnitArea.GALLONSSQUARE_TO_LITERSSQUAREMETER;
    return valueMetric.toFixed(4) + ' ' + VolumePerUnitArea.METRICUNIT;
  }

  static convertMetricToImperial(valueMetric: number) {
    const valueImperial = valueMetric / VolumePerUnitArea.GALLONSSQUARE_TO_LITERSSQUAREMETER;
    return valueImperial.toFixed(4) + ' ' + VolumePerUnitArea.IMPERIALUNIT;
  }

  static displayMetric(valueMetric: number) {
    return valueMetric.toFixed(4) + ' ' + VolumePerUnitArea.METRICUNIT;
  }

  static display(valueMetric: number, showAsMetric: boolean) {
    if (showAsMetric) return VolumePerUnitArea.displayMetric(valueMetric);
    else return VolumePerUnitArea.convertMetricToImperial(valueMetric);
  }
}
