import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCompatibleExtendedLayerConfigs } from '../../services/ConfigitService';
import * as LayerApi from '../../utils/LayerApi';
import { getLayerDataById } from '../../utils/LayerTypeCheckUtilities';
import { ApplicationContext } from '../ApplicationProvider';
import { ConfigitContext, ISecurementLayer } from '../ConfigitProvider';
import { LayerBuilder } from '../LayerBuilder';
import { LayerConfigurator } from '../LayerConfigurator';
import { AccordionComponent } from '../shared/AccordionComponent/AccordionComponent';
import { ButtonComponent } from '../shared/ButtonComponent/ButtonComponent';
import { Dialog } from '../shared/DialogComponent/Dialog';
import { MultiSelectBox } from '../shared/MultiSelectBox';
import { LayerConfigSummary } from './LayerConfigSummary';
import './RoofLayers.scss';

export const RoofLayers: React.FC = () => {
  const { setDialog, setShowDialog, showRoofLayers } = useContext(ApplicationContext);
  const { sections, layers, setLayers, excludedLayerIds, setExcludedLayerIds, variableAssignments, layerConfigMasterData } =
    useContext(ConfigitContext);
  const layerIds = layers.map((layer) => layer.id);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let collapsed = params.get('openRoofLayers') == null;

  useEffect(() => {
    if (showRoofLayers) {
      collapsed = true;
      toggleCollapsed()
    }
  }, [showRoofLayers]);
  const toggleCollapsed = () => {
    collapsed ? params.set('openRoofLayers', 'true') : params.delete('openRoofLayers');
    navigate('?' + params.toString());
  };
  const handleAddLayer = (index: number) => {
    setDialog(
      <Dialog label="Layers">
        <LayerBuilder
          popup={true}
          addLayerAt={index}
          layers={layers}
          excludedLayerIds={excludedLayerIds}
          sections={sections}
          handleSelect={(id: string, value: string, isSecurementLayer?: boolean) => {
            const newLayers = [
              ...layers.slice(0, index).map((data) => {
                data.autoSelected = false;
                return data;
              }),
              { id: value, autoSelected: false, isSecurementLayer },
              ...layers.slice(index).map((data) => {
                data.autoSelected = false;
                return data;
              }),
            ];
            let relSeqNum = 1;
            for (let i = 0; i < index; i++) {
              if (layers[i].id === value) relSeqNum++;
            }
            for (let i = 0; i < newLayers.length; i++) {
              if (newLayers[i].isSecurementLayer) {
                const securmentLayer = { ...newLayers[i] } as ISecurementLayer;
                if (securmentLayer.fromLayerTypeId === Number(value) && securmentLayer.fromLayerNum && securmentLayer.fromLayerNum >= relSeqNum) {
                  securmentLayer.fromLayerNum++;
                  newLayers[i] = securmentLayer;
                }
                if (securmentLayer.toLayerTypeId === Number(value) && securmentLayer.toLayerNum && securmentLayer.toLayerNum >= relSeqNum) {
                  securmentLayer.toLayerNum++;
                  newLayers[i] = securmentLayer;
                }
              }
            }
            setLayers(newLayers);
            setShowDialog(false);
          }}
        />
      </Dialog>
    );
    setShowDialog(true);
  };
  const handleEditLayer = (layerId: string, layerIndex: number) => {
    setDialog(
      <Dialog label="Layer Configuration">
        <LayerConfigurator layerId={layerId} layerIndex={layerIndex} />
      </Dialog>
    );
    setShowDialog(true);
  };
  const handleRemoveLayer = (index: number) => {
    //setLayers(layers.filter((layer, i) => index !== i));
    const newLayers = layers.filter((layer, i) => index !== i);
    // If the deleted layer is not securement layer, we may update from/to info of securement layers
    if (!layers[index].isSecurementLayer) {
      const value = layers[index].id;
      let relSeqNum = 1;
      for (let i = 0; i < index; i++) {
        if (layers[i].id === value) relSeqNum++;
      }
      for (let i = 0; i < newLayers.length; i++) {
        if (newLayers[i].isSecurementLayer) {
          const securmentLayer = { ...newLayers[i] } as ISecurementLayer;
          if (securmentLayer.fromLayerTypeId === Number(value) && securmentLayer.fromLayerNum && securmentLayer.fromLayerNum >= relSeqNum) {
            if (securmentLayer.fromLayerNum > 1) securmentLayer.fromLayerNum--;
            else {
              // TODO Consider remove this securement layer since this securement layer may become not applicable at all.
              // When editing such n/a securement, from/to options could be empty
              securmentLayer.fromLayerTypeId = undefined;
              securmentLayer.fromLayerNum = undefined;
              securmentLayer.id = '45';
            }
            newLayers[i] = securmentLayer;
          }
          if (securmentLayer.toLayerTypeId === Number(value) && securmentLayer.toLayerNum && securmentLayer.toLayerNum >= relSeqNum) {
            if (securmentLayer.toLayerNum > 1) securmentLayer.toLayerNum--;
            else {
              securmentLayer.toLayerTypeId = undefined;
              securmentLayer.toLayerNum = undefined;
            }
            newLayers[i] = securmentLayer;
          }
        }
      }
    }
    setLayers(newLayers);
  };
  const allExtendedLayerConfigs = getCompatibleExtendedLayerConfigs(sections, layerConfigMasterData ?? []);
  const allowedLayerConfigs = variableAssignments
    .find((data) => data.variableId === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId')
    ?.allowedValues?.map((data) => String(data.value));
  const insertionPoints = LayerApi.getLayerInsertPoints(
    layers,
    excludedLayerIds,
    allowedLayerConfigs?.length
      ? allExtendedLayerConfigs.filter((data) => allowedLayerConfigs.includes(String(data.ExtendedLayerConfigId)))
      : allExtendedLayerConfigs
  );
  const matchFound = insertionPoints.includes(0);
  const matchingLayerConfigs = LayerApi.getMatchingLayerConfigs(
    layers,
    excludedLayerIds,
    allExtendedLayerConfigs
  );
  // TODO: this generates elements w/same key- need to take closer look
  const LayerList = () => {
    const elems = layers.map((layer, index) => {
      const layerData = getLayerDataById(layer.id);
      const matchFound = insertionPoints.includes(index + 1);
      return (
        <React.Fragment key={index}>
          <div className="mdc-card px-2 mb-2 shadow-none rounded-0" style={{ backgroundColor: '#F8F7F4 ' }}>
            <div className={'test'}></div>
            <div className="d-flex align-items-center  p-1 pl-4">
              <div className={'layer-display-name pl-2'}>
                <Typography fontFamily={'Inter'} fontWeight={500} fontSize={12}>{layerData?.name}</Typography>
              </div>
              <div className="pl-2 ">
                {layer.id !== '18' &&
                  <a className="px-1" onClick={() => handleEditLayer(layer.id, index)}>
                    <span className="material-symbols-outlined pencil-icon">edit</span>
                  </a>}
                {layer.id !== '35' &&
                  <a className="px-1" onClick={() => (layer.autoSelected ? undefined : handleRemoveLayer(index))}>
                    <span className={`material-symbols-outlined close-icon ${layer.autoSelected ? ' disabled' : ''}`} aria-hidden="true">close</span>
                  </a>}
              </div>
            </div>
            <LayerConfigSummary layer={layer} index={index} />
          </div>
          <div className="form-group mb-2 pl-4">
            <ButtonComponent
              iconName=''
              buttonType='primary'
              click={() => {
                if (matchFound) handleAddLayer(index + 1);
              }}
              buttonText={'Add Layer'}
              buttonStyle={`py-1 px-2 ${matchFound ? '' : ' d-none'}`}
            />
          </div>
        </React.Fragment>
      );
    });
    return <>{elems}</>;
  };
  const sectionMessageA = 'Add Roof Layers by clicking the ';
  const sectionMessageB = ' where you would like the new layer to be added. Search results will be refined.';

  return (
    <AccordionComponent
      className="mb-1 rn-rooflayer"
      id="accordionRoofLayer"
      title="Roof Layers"
      collapsed={collapsed}
      toggleCollapsed={toggleCollapsed}
    >
      <Typography fontFamily={'Inter'} fontWeight={400} fontSize={14} className={'mb-5'}>
        {sectionMessageA} <FontAwesomeIcon icon={['fas', 'plus']} />
        {sectionMessageB}
      </Typography>
      <div className="form-group mb-2 pl-4">
        <ButtonComponent
          iconName='add'
          buttonType='primary'
          click={() => {
            if (matchFound) handleAddLayer(0);
          }}
          buttonText={'Add Layer'}
          buttonStyle={`py-1 px-2 ${matchFound ? '' : ' d-none'}`}
        />
      </div>
      <LayerList />
      <div className='roof-separator'></div>
      <MultiSelectBox
        id="ExcludeLayerId"
        label="Exclude Layer(s)"
        className="ac-slope-operator col px-2 mt-4 select-container"
        options={LayerApi.getExcludedLayerDomain(matchingLayerConfigs, excludedLayerIds)}
        select={(variableId: string, value: string) => setExcludedLayerIds([...excludedLayerIds, value])}
        unSelect={(variableId: string, value?: string) => {
          if (value) setExcludedLayerIds(excludedLayerIds.filter((layerId) => layerId !== value));
          else setExcludedLayerIds([]);
        }}
      />
    </AccordionComponent>
  );
};
