import { Divider, Typography, styled } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import theme from "../../theme";

export const SplashHeader = () => {
    const MidDivider = styled(Divider)({
        '&.MuiDivider-root': {
            background: theme.palette.rnMisc.white,
            width: '1px',
            height: '50px',
            marginLeft: '1.5rem',
            marginRight: '1.5rem'
        },
    });
    useEffect(() => {
        const itemKey = 'msal.interaction.status';
        if (sessionStorage.getItem(itemKey)) {
            sessionStorage.removeItem(itemKey);
        }
    }, []);
    return (
        <div>
            <header className="">
                <div className="rnheader container-fluid">
                    <div className="d-flex flex-nowrap justify-content-between align-items-center">
                        <div className="pt-1">
                            <nav className="navbar navbar-dark p-0">
                                <div className="navbar-brand mx-2 d-flex flex-nowrap row align-items-center">
                                    <Link to={`/`}>
                                        <img src="/assets/FM_Approvals_Logo_Main.svg" width='300' alt="RoofNav logo" />
                                    </Link>
                                    <MidDivider variant={'middle'} />
                                    <Typography fontFamily={'FM Review Web'} className='FMReview-Regular-Web title-font'
                                        color={theme.palette.rnMisc.white} fontSize={'36px'}
                                        fontWeight={500}
                                    >
                                        RoofNav
                                    </Typography>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            <div className='border-bar'></div>
        </div>
    );
}