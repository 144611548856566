import { LayerType } from '../components/constants/ENUMS';

export enum StandardSecurementLayerType {
  SecurementCover = 39,
  SecurementBaseSheet = 41,
  SecurementBoardStock = 42,
  SecurementDeck = 44,
  Securement = 45,
}

export enum SelfSecurementLayerType {
  SecurementSheetLap = 40,
  SecurementDeckLap = 43,
  SelfSecurement = 51,
}

export const StandardSecurementLayerTypeIds = ['39', '41', '42', '44', '45'];
export const SelfSecurementLayerTypeIds = ['40', '43', '51'];

export const DetermineSelfSecurementLayerType = (componentLayerTypeId: number) => {
  switch (componentLayerTypeId) {
    case LayerType._Deck__Steel_17:
    case LayerType._Deck__Fiber_Reinforced_Plastic_14:
    case LayerType._Deck__Treated_Wood_19:
      return LayerType.Securement__Deck_Lap__43;

    case LayerType.Cover__Composite_Panel__1:
    case LayerType.Cover__Standing_Lap_Seam__5:
    case LayerType.Cover__Single_ply__4:
    case LayerType.Cap_Ply_10:
    case LayerType.Ply_11:
    case LayerType.Base_Ply_9:
    case LayerType.Base_Sheet_22:
    case LayerType.Vapor_Retarder_37:
    case LayerType.Separator_Sheet_34:
    case LayerType.Air_Retarder_21:
      return LayerType.Securement__Sheet_Lap__40;
  }
  return LayerType.Self_Securement_51;
};

export const DetermineStandardSecurementLayerType = (fromLayerTypeId: number, toLayerTypeId: number) => {
  if (fromLayerTypeId === 0) {
    return LayerType.Securement_45;
  }

  if (DetermineIsCoverMultiPlyOrLiquidAppliedSALayer(fromLayerTypeId)) {
    if (!toLayerTypeId) {
      // to layer not selected
      // treat it as a generic securement
      return LayerType.Securement_45;
    }
    if (toLayerTypeId > 0 && DetermineIsCoverMultiPlyOrLiquidAppliedSALayer(toLayerTypeId)) {
      // this is a securement b/w layers in a subassembly
      // treat it as a generic securement
      return LayerType.Securement_45;
    }
    // it's a securement from a CoverSA layer to something outside the SA,
    // so we treat it as a Cover Securement
    return LayerType.Securement__Cover__39;
  }

  switch (fromLayerTypeId) {
    case LayerType.Cover__Composite_Panel__1:
    case LayerType.Cover__Standing_Lap_Seam__5:
    case LayerType.Cover__Single_ply__4:
    case LayerType.Cover__Multi_ply__3:
      return LayerType.Securement__Cover__39;

    case LayerType.Base_Sheet_22:
      return LayerType.Securement__Base_Sheet__41;

    case LayerType.Cover_Board_24:
    case LayerType.Insulation__Board_Stock__28:
    case LayerType.Thermal_Barrier_36:
      return LayerType.Securement__Board_Stock__42;

    case LayerType._Deck__Steel_17:
    case LayerType._Deck__Cementitious_Wood_Fiber_12:
    case LayerType._Deck__Fiber_Reinforced_Plastic_14:
    case LayerType.Form_Deck_26:
    case LayerType._Deck__Structural_Concrete_18:
    case LayerType._Deck__Treated_Wood_19:
    case LayerType.Existing_Roof_25:
      return LayerType.Securement__Deck__44;
  }

  return LayerType.Securement_45;
};

const DetermineIsCoverMultiPlyOrLiquidAppliedSALayer = (layerType: LayerType) => {
  switch (layerType) {
    case LayerType.Base_Ply_9:
    case LayerType.Cap_Ply_10:
    case LayerType.Ply_11:
    case LayerType.Base_Coat_6:
    case LayerType.Coat_7:
    case LayerType.Top_Coat_8:
      return true;
  }

  return false;
};
