import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField, Typography, InputAdornment, styled } from "@mui/material";
import React, { FocusEvent, useState } from "react";
import theme from "../../theme";
import './SearchBox.css'

interface ISearchBox {
    label?: string;
    value: string;
    setValue: (value: string) => void;
    search: (value: string) => void;
    restrictKeyPress?: RegExp;
    errMsg?: string;
}

const SearchBoxTypography = styled(Typography)({
    '&.MuiTypography-root': {
        paddingTop: '.75rem',
        textOverflow: 'ellipsis'
    }
})
const SearchBoxTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        background: theme.palette.rnMisc.white,
        paddingRight: 0
    },
    '& .MuiInputBase-input': {
        padding: '8px'
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline ': {
        borderColor: `${theme.palette.rnSteel.steel700} !important`,
    },
    '& .MuiInputBase-root .MuiInputAdornment-root': {
        cursor: 'pointer !important'
    },

})
const StyledInputAdornment = styled(InputAdornment)({
    '& .MuiInputAdornment-root': {
        cursor: 'pointer !important'
    },
    '& .MuiInputAdornment-root:hover': {
        cursor: 'pointer !important'
    }
})

export const SearchBox = ({ label, value, setValue, search, restrictKeyPress, errMsg = '' }: ISearchBox) => {
    const [focused, setFocused] = useState(false);

    const handleFocus = (event: FocusEvent) => {
        setFocused(true);
    };

    const handleBlur = (event: FocusEvent) => {
        setFocused(false);
    };

    const handleChange = (event: any) => {
        if (event.target.value === '' || !restrictKeyPress || restrictKeyPress.test(event.target.value)) {
            setValue(event.target.value);
        }
    };

    const searchHandler = async () => {
        await search(value);
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            searchHandler();
        }
    };

    return (
        <div id="QuickSearch">
            <SearchBoxTextField
                placeholder="Enter Assembly #"
                className="w-100"
                type="text"
                aria-labelledby="SearchAssembly"
                value={value}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                helperText={errMsg}
                InputProps={{
                    endAdornment: (
                        <StyledInputAdornment
                            position="start"
                            onClick={() => searchHandler()}
                        >
                            <FontAwesomeIcon icon={"search"} color={theme.palette.rnOrange.darkOrange}
                            />
                        </StyledInputAdornment>
                    ),
                }}
            />
        </div>
    );

};