import { useContext, useEffect, useState } from 'react';
import { apolloClient } from '../ApolloClient';
import { ConfigitContext, ILayer } from '../components/ConfigitProvider';
import {
  AssemblyApiSearchDocument,
  AssemblyApiSearchQuery,
  AssemblyApiSearchQueryVariables,
  AssemblyApiSearchResultCountDocument,
  AssemblyApiSearchResultCountQuery,
  AssemblyApiSearchResultCountQueryVariables,
  GetAssembliesByFiltersDocument,
  GetAssembliesByFiltersQuery,
  GetAssembliesByFiltersQueryVariables,
  GetAssemblyCountByFiltersDocument,
  GetAssemblyCountByFiltersQuery,
  GetAssemblyCountByFiltersQueryVariables,
  GetAssemblyIdsByFiltersDocument,
  GetAssemblyIdsByFiltersQuery,
  GetAssemblyIdsByFiltersQueryVariables,
  GetAssemblyNumberInfoDocument,
  GetAssemblyNumberInfoQuery,
  GetAssemblyNumberInfoQueryVariables,
  GetNavAssemblyDetailByIdDocument,
  GetNavAssemblyDetailByIdQuery,
  GetNavAssemblyDetailByIdQueryVariables,
  GetNavAssemblyIdByFiltersDocument,
  GetNavAssemblyIdByFiltersQuery,
  GetNavAssemblyIdByFiltersQueryVariables,
  NavAssembly_Bool_Exp,
  NavAssembly_Order_By,
  Order_By,
  ProductApiMatchedAssemblyListDocument,
  ProductApiMatchedAssemblyListQuery,
  ProductApiMatchedAssemblyListQueryVariables,
  ProductMatchAssemblyListCriteria,
  useGetAssembliesByIdsQuery,
  useGetAssemblyCountByFiltersQuery,
  useGetAssemblyIdsByFiltersQuery,
  useSearchNavAssembliesQuery,
} from '../models/GQLGeneratedModels';
import { VariableAssignment } from '../types/configurator';
import { getAPISearchCriteriaBasedOnFilters } from './AssemblySearchAPIService';
import { ApplicationContext } from '../components/ApplicationProvider';
import { Dialog } from '@mui/material';
import { useLogger } from 'fmg-telemetry-react';

export const getAssembliesByAssemblyNumber = async (assemblyNumber: string) => {
  const result = await apolloClient.query<GetAssemblyNumberInfoQuery, GetAssemblyNumberInfoQueryVariables>({
    query: GetAssemblyNumberInfoDocument,
    variables: {
      navAssemblyName: `${assemblyNumber}%`,
    },
  });
  return result.data.NavAssembly;
};

export const getAssemblyDetailByAssemblyId = async (assemblyNumber: number) => {
  const result = await apolloClient.query<GetNavAssemblyDetailByIdQuery, GetNavAssemblyDetailByIdQueryVariables>({
    query: GetNavAssemblyDetailByIdDocument,
    variables: {
      NavAssemblyId: assemblyNumber,
    },
  });
  return result.data.NavAssembly[0];
};

export const getAssemblyCountByFilters = async (filters: NavAssembly_Bool_Exp) => {
  const result = await apolloClient.query<GetAssemblyCountByFiltersQuery, GetAssemblyCountByFiltersQueryVariables>({
    query: GetAssemblyCountByFiltersDocument,
    variables: {
      filters: filters,
    },
  });
  return result.data.NavAssembly_aggregate.aggregate?.count;
};

export const getNavAssemblyIdByFiltersResults = async (filters: NavAssembly_Bool_Exp) => {
  const result = await apolloClient.query<GetNavAssemblyIdByFiltersQuery, GetNavAssemblyIdByFiltersQueryVariables>({
    query: GetNavAssemblyIdByFiltersDocument,
    variables: {
      filters: filters,
    },
  });
  return result.data.NavAssembly;
};

export const getAssemblyFilterSearchResults = async (
  filters: NavAssembly_Bool_Exp,
  limit: number,
  offset: number,
  orderBy: NavAssembly_Order_By
) => {
  const result = await apolloClient.query<GetAssembliesByFiltersQuery, GetAssembliesByFiltersQueryVariables>({
    query: GetAssembliesByFiltersDocument,
    variables: {
      filters: filters,
      limit: limit,
      offset: offset,
      order_by: orderBy,
    },
  });
  return result.data.NavAssembly;
};

export const getAssemblyIdsByFilters = async (
  filters: NavAssembly_Bool_Exp,
  limit: number,
  offset: number,
  orderBy: NavAssembly_Order_By
): Promise<number[]> => {
  const result = await apolloClient.query<GetAssemblyIdsByFiltersQuery, GetAssemblyIdsByFiltersQueryVariables>({
    query: GetAssemblyIdsByFiltersDocument,
    variables: {
      filters: filters,
      limit: limit,
      offset: offset,
      order_by: orderBy,
    },
  });
  return result.data.NavAssembly.map((navAssembly) => navAssembly.NavAssemblyId);
};

export const useGetAssemblyCountByFiltersQueryWrapper = (filters: NavAssembly_Bool_Exp) => {
  const { data, loading, error } = useGetAssemblyCountByFiltersQuery({
    variables: {
      filters: filters,
    },
  });
  return {
    count: data?.NavAssembly_aggregate.aggregate?.count,
    countLoading: loading,
    countError: error,
  };
};

export const useGetAssembliesByFiltersDoubleQuery = (
  filters: NavAssembly_Bool_Exp,
  limit: number,
  offset: number,
  orderBy: NavAssembly_Order_By
) => {
  const { data, loading, error } = useGetAssemblyIdsByFiltersQuery({
    variables: {
      filters: filters,
      limit: limit,
      offset: offset,
      order_by: orderBy,
    },
  });
  let ids = data?.NavAssembly.map((navAssembly) => navAssembly.NavAssemblyId);
  if (loading) {
    ids = [-1];
    // return { data: undefined, loading, error };
  }
  return useGetAssembliesByIdsQuery({
    variables: {
      ids: {
        NavAssemblyId: {
          _in: ids,
        },
      },
      order_by: orderBy,
    },
  });
};

export const useGetAssembliesByVariousQuery = (
  filters: NavAssembly_Bool_Exp,
  limit: number,
  offset: number,
  orderBy: NavAssembly_Order_By
) => {
  // if (
  //   filters.NavLayers &&
  //   filters.NavLayers?.NavUsages?.Usage?.Component?.ManufacturedProduct?.ManufacturerId &&
  //   Object.keys(filters).length === 2
  // ) {
  //   const mId = filters.NavLayers?.NavUsages?.Usage?.Component?.ManufacturedProduct?.ManufacturerId['_eq'];
  //   const idManufacturer = parseInt(String(mId));
  //   if (LARGEMANUFACTURERS.includes(idManufacturer)) {
  //     // Currently this branch is only used for large manufacturer as single search criteria except StatusId filter
  //     // To use SearchNavAssembliesByManufacturer, filters and order_by variables need to be in different format
  //     const filtersManufacturer: any = {}; // vNavAssembly_ManufacturerOnlySearch contains StatusId check
  //     filtersManufacturer['ManufacturerId'] = {
  //       _eq: idManufacturer,
  //     };

  //     const orderByManufacturer: any = {};
  //     orderByManufacturer['NavAssembly_ForManufacturerSearch'] = { ...orderBy };

  //     const { data, loading, error } = useSearchNavAssembliesByManufacturerQuery({
  //       variables: {
  //         filters: filtersManufacturer,
  //         limit: limit,
  //         offset: offset,
  //         order_by: orderByManufacturer,
  //       },
  //     });

  //     let ids = data?.vNavAssembly_ManufacturerOnlySearch.map((navAssembly) => navAssembly.NavAssemblyId);
  //     if (loading) {
  //       ids = [-1];
  //     }

  //     return useGetAssembliesByIdsQuery({
  //       variables: {
  //         ids: {
  //           NavAssemblyId: {
  //             _in: ids,
  //           },
  //         },
  //         order_by: orderBy,
  //       },
  //     });
  //   }
  // }

  return useSearchNavAssembliesQuery({
    variables: {
      filters: filters,
      limit: limit,
      offset: offset,
      order_by: orderBy,
    },
  });
};

export const useGetAssembliesByAPIQuery = (
  layers: ILayer[],
  excludedLayerTypeIds: string[],
  manufacturerProduct: VariableAssignment[],
  limit: number,
  offset: number,
  orderBy: NavAssembly_Order_By,
  filters?: NavAssembly_Bool_Exp
) => {
  const logger = useLogger();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  const { autoRefreshSearchResult } = useContext(ApplicationContext);
  const { triggerAssemblyList, setTriggerAssemblyList } = useContext(ApplicationContext);
  const { numShowResult, setNumShowResult } = useContext(ApplicationContext);
  useEffect(() => {
    if (filters && (autoRefreshSearchResult || triggerAssemblyList)) {
      setLoading(true);
      if (triggerAssemblyList) setTriggerAssemblyList(false);
      const assemblySearchCriteria = getAPISearchCriteriaBasedOnFilters(
        layers,
        filters,
        excludedLayerTypeIds,
        manufacturerProduct,
        limit,
        offset,
        orderBy
      );
      apolloClient
        .query<AssemblyApiSearchQuery, AssemblyApiSearchQueryVariables>({
          query: AssemblyApiSearchDocument,
          variables: {
            assemblySearchCriteria: assemblySearchCriteria,
          },
        })
        .then((result) => {
          setData(result.data);
          setLoading(result.loading);
          setError(result.error);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
          logger.error('Error in useGetAssembliesByAPIQuery', { searchCriteriaUsed: assemblySearchCriteria, error: error, msg: error.message })

        });
    }
  }, [limit, offset, JSON.stringify(orderBy), JSON.stringify(filters), numShowResult]);
  return { data, loading, error };
};

export const useGetAssemblyCountByAPIQuery = (
  layers: ILayer[],
  excludedLayerTypeIds: string[],
  manufacturerProduct: VariableAssignment[],
  filters?: NavAssembly_Bool_Exp
) => {
  const logger = useLogger();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  const { autoRefreshSearchResult, setAutoRefreshSearchResult } = useContext(ApplicationContext);
  const { triggerSearchResultCount, setTriggerSearchResultCount } = useContext(ApplicationContext);
  const { numShowResult, setNumShowResult } = useContext(ApplicationContext);
  useEffect(() => {
    if (filters && (autoRefreshSearchResult || triggerSearchResultCount)) {
      setLoading(true);
      if (triggerSearchResultCount) setTriggerSearchResultCount(false);
      const assemblySearchCriteria = getAPISearchCriteriaBasedOnFilters(
        layers,
        filters,
        excludedLayerTypeIds,
        manufacturerProduct,
        25,
        0,
        {
          LayerConfigId: Order_By.Asc,
        }
      );
      apolloClient
        .query<AssemblyApiSearchResultCountQuery, AssemblyApiSearchResultCountQueryVariables>({
          query: AssemblyApiSearchResultCountDocument,
          variables: {
            assemblySearchCriteria: assemblySearchCriteria,
          },
        })
        .then((result) => {
          setData(result.data);
          setLoading(result.loading);
          setError(result.error);
        }).catch((error) => {
          setError(error);
          setLoading(false);
          logger.error('Error in useGetAssemblyCountByAPIQuery', { searchCriteriaUsed: assemblySearchCriteria, error: error, msg: error.message })
        });
    }
  }, [JSON.stringify(filters), numShowResult]);
  return {
    count: data?.navassembly_api_countsearchresult?.[0]?.AssemblySearchResultCount,
    countLoading: loading,
    countError: error,
  };
};

export const useGetAssembliesExtraRoofTypeIdByAPIQuery = (
  layerConfigId: number,
  layers: ILayer[],
  excludedLayerTypeIds: string[],
  manufacturerProduct: VariableAssignment[],
  limit: number,
  offset: number,
  orderBy: NavAssembly_Order_By,
  filters?: NavAssembly_Bool_Exp
) => {
  const newFilters = { ...filters };
  newFilters['LayerConfigId'] = {
    _eq: layerConfigId,
  };

  return useGetAssembliesByAPIQuery(
    layers,
    excludedLayerTypeIds,
    manufacturerProduct,
    limit,
    offset,
    orderBy,
    newFilters
  );
};


export const useGetAssembliesByComponentApprovedUse = (
  componentId: number,
  approvedUseId: number,
  limit: number,
  offset: number,
  orderBy: NavAssembly_Order_By
) => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();

  const assemblySearchCriteria: ProductMatchAssemblyListCriteria = {
    componentId: componentId,
    approvedUseId: approvedUseId,
    rowsPerPage: 25,
    pageNumber: 1,
    sortColumn: 'NavAssemblyName',
    sortDirection: 0,
  };

  // Paging and Sorting properties
  assemblySearchCriteria.rowsPerPage = limit;
  assemblySearchCriteria.pageNumber = offset / limit + 1;
  if (orderBy.NavAssemblyName) {
    assemblySearchCriteria.sortColumn = 'NavAssemblyName';
    assemblySearchCriteria.sortDirection = orderBy.NavAssemblyName === Order_By.Asc ? 0 : 1;
  } else if (orderBy.AssemblyTypeCode) {
    assemblySearchCriteria.sortColumn = 'AssemblyTypeCode';
    assemblySearchCriteria.sortDirection = orderBy.AssemblyTypeCode === Order_By.Asc ? 0 : 1;
  } else if (orderBy.AssemblyApplicationTypeCode) {
    assemblySearchCriteria.sortColumn = 'AssemblyApplicationTypeCode';
    assemblySearchCriteria.sortDirection = orderBy.AssemblyApplicationTypeCode === Order_By.Asc ? 0 : 1;
  } else if (orderBy.CoverSecurementMethodCode) {
    assemblySearchCriteria.sortColumn = 'CoverSecurementMethodCode';
    assemblySearchCriteria.sortDirection = orderBy.CoverSecurementMethodCode === Order_By.Asc ? 0 : 1;
  } else if (orderBy.DeckTypeCode) {
    assemblySearchCriteria.sortColumn = 'DeckTypeCode';
    assemblySearchCriteria.sortDirection = orderBy.DeckTypeCode === Order_By.Asc ? 0 : 1;
  } else if (orderBy.WindUplift || orderBy.WindUpliftValue) {
    assemblySearchCriteria.sortColumn = 'WindUplift';
    if (orderBy.WindUplift) assemblySearchCriteria.sortDirection = orderBy.WindUplift === Order_By.Asc ? 0 : 1;
    else assemblySearchCriteria.sortDirection = orderBy.WindUpliftValue === Order_By.Asc ? 0 : 1;
  } else if (orderBy.IntFireRatingCode) {
    assemblySearchCriteria.sortColumn = 'IntFireRatingCode';
    assemblySearchCriteria.sortDirection = orderBy.IntFireRatingCode === Order_By.Asc ? 0 : 1;
  } else if (orderBy.ExtFireRatingCode) {
    assemblySearchCriteria.sortColumn = 'ExtFireRatingCode';
    assemblySearchCriteria.sortDirection = orderBy.ExtFireRatingCode === Order_By.Asc ? 0 : 1;
  } else if (orderBy.Slope || orderBy.SlopeValue) {
    assemblySearchCriteria.sortColumn = 'Slope';
    if (orderBy.Slope) assemblySearchCriteria.sortDirection = orderBy.Slope === Order_By.Asc ? 0 : 1;
    else assemblySearchCriteria.sortDirection = orderBy.SlopeValue === Order_By.Asc ? 0 : 1;
  } else if (orderBy.HailRatingCode) {
    assemblySearchCriteria.sortColumn = 'HailRatingCode';
    assemblySearchCriteria.sortDirection = orderBy.HailRatingCode === Order_By.Asc ? 0 : 1;
  } else {
    assemblySearchCriteria.sortColumn = 'AssemblyNum';
    if (orderBy.AssemblyId) assemblySearchCriteria.sortDirection = orderBy.AssemblyId === Order_By.Asc ? 0 : 1;
    else assemblySearchCriteria.sortDirection = 0;
  }

  useEffect(() => {
    const logger = useLogger();
    setLoading(true);
    apolloClient
      .query<ProductApiMatchedAssemblyListQuery, ProductApiMatchedAssemblyListQueryVariables>({
        query: ProductApiMatchedAssemblyListDocument,
        variables: {
          productMatchAssemblyListCriteria: assemblySearchCriteria
        },
      })
      .then((result) => {
        setData(result.data);
        setLoading(result.loading);
        setError(result.error);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        logger.error('Error in useGetAssembliesByComponentApprovedUse', { searchCriteriaUsed: assemblySearchCriteria, error: error.error, msg: error.message })

      });
  }, [limit, offset, JSON.stringify(orderBy), componentId, approvedUseId]);
  return { data, loading, error };
};
