import React, { HTMLAttributes, useEffect, useState } from 'react';
import { ConfigurationValue, SingletonValue } from '../../../types/configurator';
import { UnitConversion } from '../../../utils/Measurement/UnitConversion';
import './SelectBox.scss';
import { SelectComponent } from './SelectComponent';

interface ISelectBoxProps extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  isError?: boolean;
  options: ConfigurationValue[];
  measurementAttr?: boolean;
  measurementTypeId?: number;
  imperialUnit?: boolean;
  hasAllowedValues?: boolean;
  select: (variableId: string, value: string) => void;
  unSelect?: (variableId: string) => void;
}
export interface ISelectValue {
  value: string | number | undefined;
  name: string | undefined;
  incompatible?: boolean | undefined;
}

export const SelectBox: React.FC<ISelectBoxProps> = (props: ISelectBoxProps) => {
  const {
    id,
    label,
    disabled = false,
    required = false,
    isError = false,
    options,
    measurementAttr,
    imperialUnit,
    measurementTypeId,
    hasAllowedValues,
    select,
    unSelect,
    className,
    ...other
  } = props;

  const [displayValue, setDisplayValue] = useState('');
  const [selectDisable, setSelectDisable] = useState(false);

  const getDisplayValue = (value: string): string => {
    if (measurementAttr) {
      if (imperialUnit && measurementTypeId && measurementTypeId > 0) {
        return UnitConversion.convertMetricToImperial(parseFloat(value), measurementTypeId);
      } else return parseFloat(value).toFixed(4);
    }
    return value;
  };

  const optionArray: ISelectValue[] = [];
  // mapping to send only relevant info to SelectComponent
  const mapOptions = (options: ConfigurationValue[]) => {
    options.forEach((optionValue: SingletonValue) => {
      optionArray.push({
        value: optionValue.value,
        name: getDisplayValue(optionValue.name as string),
        incompatible: optionValue.incompatible
      })
    });

    return optionArray
  }

  // helper function to pass callback function from select
  const setCallback = (value: string) => {
    if (id) {
      if (value != '') select(id, value)
      else unSelect && unSelect(id);
    }
  }
  let defaultValue: SingletonValue | undefined;
  useEffect(() => {
    defaultValue = options.find((option) => option.assigned);
    setDisplayValue(defaultValue && defaultValue.value ? defaultValue.value.toString() : 'Select');
    if (defaultValue?.assigned) {
      setSelectDisable(defaultValue.value != undefined && defaultValue.assigned === 'byRule')
    }
    else setSelectDisable(false)
  });

  return (
    <SelectComponent
      className={className}
      id={id}
      label={label}
      options={mapOptions(options)}
      value={displayValue}
      setValue={setCallback}
      required={required}
      selectDisable={selectDisable}
      disabled={disabled}
      isError={isError} />
  )
};
