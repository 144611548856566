import React from 'react';
import { useGetExistingRoofDetailQuery } from '../../models/GQLGeneratedModels';
import { Length } from '../../utils/Measurement/Length';
import { Strength } from '../../utils/Measurement/Strength';

export const ExistingRoofDetail = (props: any) => {
  const { data, loading, error } = useGetExistingRoofDetailQuery({
    variables: {
      LayerId: parseInt(props.layerId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const layer = data?.ExistingRoofLayer[0];
  const isMetric = layer?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Existing Roof</h4>
      <table>
        <tr>
          <td>Insulation Material:</td>
          <td>{layer?.C_ExistingRoofInsulationMaterial.ExistingRoofInsulationMaterialCode}</td>
        </tr>
        <tr>
          <td>Single Ply Material:</td>
          <td>{layer?.C_ExistingRoofSinglePlyMaterial.ExistingRoofSinglePlyMaterialCode}</td>
        </tr>
        <tr>
          <td>Roof Deck:</td>
          <td>{layer?.C_ExistingRoofDeck.ExistingRoofDeckCode}</td>
        </tr>
        <tr>
          <td>Cover:</td>
          <td>{layer?.C_ExistingRoofCover.ExistingRoofCoverCode}</td>
        </tr>
        <tr>
          <td>Surface:</td>
          <td>{layer?.C_ExistingRoofSurface.ExistingRoofSurfaceCode}</td>
        </tr>
        <tr>
          <td>Steel Strength:</td>
          <td>{Strength.display(layer?.SteelThicknessMin, isMetric)}</td>
        </tr>
        <tr>
          <td>Min Steel Thickness:</td>
          <td>{Length.display(layer?.SteelThicknessMin, isMetric)}</td>
        </tr>
        <tr>
          <td>Max Steel Thickness:</td>
          <td>{Length.display(layer?.SteelThicknessMax, isMetric)}</td>
        </tr>
        <tr>
          <td>Min Insulation Thickness:</td>
          <td>{Length.display(layer?.InsulationThicknessMin, isMetric)}</td>
        </tr>
        <tr>
          <td>Max Insulation Thickness:</td>
          <td>{Length.display(layer?.InsulationThicknessMax, isMetric)}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{layer?.Layer.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
