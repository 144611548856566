import { Configuration, ProtocolMode, PublicClientApplication } from '@azure/msal-browser';

// fallbacks in case .env is not set
const authorityToUse =
  process.env.REACT_APP_AAD_AUTHORITY ||
  'https://devfmgidp.b2clogin.com/devfmgidp.onmicrosoft.com/B2C_1A_signup_signin';
const appIdToUse = process.env.REACT_APP_AAD_APPLICATION_ID || 'b98f9bf2-05dc-420b-98f2-b5c6980bddf8';

export const scopesToUse = process.env.REACT_APP_AAD_SCOPE
  ? [process.env.REACT_APP_AAD_SCOPE]
  : appIdToUse
  ? [appIdToUse]
  : [];

const config = {
  auth: {
    clientId: appIdToUse,
    authority: authorityToUse,
    knownAuthorities: [authorityToUse ? authorityToUse : 'https://login.microsoftonline.com/common'],
    ...(Boolean(process.env.REACT_APP_AAD_REDIRECT_URL) && { redirectUri: process.env.REACT_APP_AAD_REDIRECT_URL }),
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: process.env.REACT_APP_AAD_LOGOUT_URL || '/home',
    protocolMode: process.env.REACT_APP_AAD_PROTOCOL_MODE || ProtocolMode.AAD,
  },
  cache: {
    cacheLocation: process.env.REACT_APP_AAD_CACHE_LOCATION || 'localStorage',
    storeAuthStateInCookie: process.env.REACT_APP_AAD_USE_COOKIES === 'true',
    secureCookies: process.env.REACT_APP_AAD_USE_SECURE_COOKIES === 'true',
  },
  system: {
    /*
    tokenRenewalOffsetSeconds - Sets the window of offset needed to renew the token before expiry
    loggerOptions - Used to initialize the Logger object (See ClientConfiguration.ts)
    networkClient - Network interface implementation
    windowHashTimeout - Sets the timeout for waiting for a response hash in a popup. Will take precedence over loadFrameTimeout if both are set.
    iframeHashTimeout - Sets the timeout for waiting for a response hash in an iframe. Will take precedence over loadFrameTimeout if both are set.
    loadFrameTimeout - Sets the timeout for waiting for a response hash in an iframe or popup
    navigateFrameWait - Maximum time the library should wait for a frame to load
    redirectNavigationTimeout - Time to wait for redirection to occur before resolving promise
    asyncPopups - Sets whether popups are opened asynchronously. By default, this flag is set to false. When set to false, blank popups are opened before anything else happens. When set to true, popups are opened when making the network request.
    allowRedirectInIframe - Flag to enable redirect operations when the app is rendered in an iframe (to support scenarios such as embedded B2C login). 
    */
    // loggerOptions: {
    //   loggerCallback: (level, message, containsPii) => {
    //     if (containsPii) {
    //       return;
    //     }
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error(message);
    //         return;
    //       case LogLevel.Info:
    //         console.info(message);
    //         return;
    //       case LogLevel.Verbose:
    //         console.debug(message);
    //         return;
    //       case LogLevel.Warning:
    //         console.warn(message);
    //         return;
    //     }
    //   },
    // },
  },
} as Configuration;
export const azureAADPublicClientApplication = new PublicClientApplication(config);
