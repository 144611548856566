interface ISelfSecurementType {
  id: string;
  model: string;
  baseLayerTypeId: string;
}

export const SELFSECUREMENT_DATA: ISelfSecurementType[] = [
  {
    id: 'SS21',
    model: 'SelfSecurementOnAirRetarder',
    baseLayerTypeId: '21',
  },
  {
    id: 'SS09',
    model: 'SelfSecurementOnBasePly',
    baseLayerTypeId: '09',
  },
  {
    id: 'SS22',
    model: 'SelfSecurementOnBaseSheet',
    baseLayerTypeId: '22',
  },
  {
    id: 'SS10',
    model: 'SelfSecurementOnCapPly',
    baseLayerTypeId: '10',
  },
  {
    id: 'SS24',
    model: 'SelfSecurementOnCoverBoard',
    baseLayerTypeId: '24',
  },
  {
    id: 'SS01',
    model: 'SelfSecurementOnCoverCompositePanel',
    baseLayerTypeId: '01',
  },
  {
    id: 'SS04',
    model: 'SelfSecurementOnCoverSingleply',
    baseLayerTypeId: '04',
  },
  {
    id: 'SS05',
    model: 'SelfSecurementOnCoverStandingLapSeam',
    baseLayerTypeId: '05',
  },
  {
    id: 'SS17',
    model: 'SelfSecurementOnDeckSteel',
    baseLayerTypeId: '17',
  },
  {
    id: 'SS26',
    model: 'SelfSecurementOnFormDeck',
    baseLayerTypeId: '26',
  },
  {
    id: 'SS28',
    model: 'SelfSecurementOnInsulationBoardStock',
    baseLayerTypeId: '28',
  },
  {
    id: 'SS31',
    model: 'SelfSecurementOnLinerPanel',
    baseLayerTypeId: '31',
  },
  {
    id: 'SS11',
    model: 'SelfSecurementOnPly',
    baseLayerTypeId: '11',
  },
  {
    id: 'SS36',
    model: 'SelfSecurementOnThermalBarrier',
    baseLayerTypeId: '36',
  },
  {
    id: 'SS37',
    model: 'SelfSecurementOnVaporRetarder',
    baseLayerTypeId: '37',
  },
];

export const getSelfSecurementPropsBybaseLayerTypeId = (baseLayerTypeId: string) => {
  const propSelfSecurement = SELFSECUREMENT_DATA.find((e) => e.baseLayerTypeId === baseLayerTypeId);
  if (propSelfSecurement) return propSelfSecurement;
  else return { id: '', model: '', baseLayerTypeId: '' };
};
