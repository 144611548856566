import { ToggleButton, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import React, { HTMLAttributes } from "react";
import theme from "../../../theme";
import { ConfigurationValue, SingletonValue } from "../../../types/configurator";
import './ConfigitButtonGroup.scss';

interface IConfigitButtonGroupProps extends HTMLAttributes<HTMLDivElement> {
    id?: string;
    label?: string;
    options: ConfigurationValue[];
    hasAllowedValues?: boolean;
    isDisabled?: boolean;
    select: (variableId: string, value: string) => void;
    unSelect: (variableId: string) => void;
}

export const StyledToggleButton = styled(ToggleButton)(
    {
        '&.MuiToggleButton-root': {
            color: theme.palette.rnSteel.steel700,
            padding: '10px 16px',
            backgroundColor: theme.palette.rnMisc.white,
            borderColor: theme.palette.rnGrey.grey600,
            borderWidth: '1px',
            maxHeight: '48px',
            height: '40px',
            borderRadius: '0px',
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        },
        '&.MuiToggleButton-root:not(:first-of-type)': {
            borderColor: `${theme.palette.rnGrey.grey600} !important`,
            borderWidth: '1px'
        },

        '&:hover': {
            backgroundColor: theme.palette.rnGrey.grey50
        },
        '&.MuiToggleButton-root.Mui-disabled': {
            backgroundColor: theme.palette.rnGrey.grey200,
            borderColor: theme.palette.rnGrey.grey600,
            color: theme.palette.rnGrey.grey300
        },
        '&.MuiToggleButton-root.Mui-selected': {
            backgroundColor: theme.palette.rnOrange.darkOrange,
            borderColor: theme.palette.rnOrange.darkOrange,
            borderWidth: '1px',
            color: theme.palette.rnMisc.white,
            outline: 'none !important'
        },
        '&.MuiToggleButton-root:focus': {
            outline: `2px solid ${theme.palette.rnOrange.darkOrange}`,
            borderColor: theme.palette.rnOrange.darkOrange,
            zIndex: '2'
        },
        // ToggleButton used with ToggleButtonGroup has border radius, this removes it
        '&.MuiToggleButton-root:first-of-type': {
            borderRadius: theme.borders.firstBorderRadius,
        },
        '&.MuiToggleButton-root:last-of-type': {
            borderRadius: theme.borders.lastBorderRadius,
        },
    })

export const ConfigitButtonGroupComponent = ({
    id,
    label,
    options,
    hasAllowedValues,
    isDisabled,
    select,
    unSelect,
    className,
    ...other
}: IConfigitButtonGroupProps) => {
    return (<>
        <span className="bg2 mdc-floating-label mdc-floating-label--float-above">{label}</span>
        {options.map((option: SingletonValue, index) => {
            const { assigned, name, value, incompatible, isAllowed } = option;
            const disabled = isDisabled || incompatible || (hasAllowedValues && !isAllowed)
            return (<React.Fragment key={name}>
                {disabled ?
                    <StyledToggleButton
                        id={(id ? id : 'undefinedId') + index}
                        data-testid={(id ? id : 'undefinedId') + index}
                        disabled
                        disableRipple
                        key={index}
                        value={name ? name : ''}
                        className={className + " col " + `${assigned ? 'Mui-selected' : ''}`}
                    >
                        <Typography fontSize={12} fontFamily={"Inter"} fontWeight={500} >{name}</Typography>
                    </StyledToggleButton>
                    :
                    <StyledToggleButton
                        id={(id ? id : 'undefinedId') + index}
                        data-testid={(id ? id : 'undefinedId') + index}
                        disableRipple
                        key={index}
                        value={name ? name : ''}
                        onClick={() => {
                            if (id && value) {
                                if (assigned) {
                                    if (assigned === 'byUser') unSelect(id);
                                }
                                else select(id, value.toString());
                            }
                        }}
                        className={className + " col " + `${assigned ? 'Mui-selected' : ''}`}
                    >
                        <Typography className={'scaled-font'} fontSize={12} fontFamily={"Inter"} fontWeight={500}>{name}</Typography>
                    </StyledToggleButton>
                }
            </React.Fragment>
            );
        })}
    </>
    );
};