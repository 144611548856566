import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserShareLinkSearchCriteriaQuery } from '../../models/GQLGeneratedModels';
import { ApplicationContext } from '../ApplicationProvider';
import { ConfigitContext, IConfigitCriteria } from '../ConfigitProvider';
import { Loading } from '../shared/Loading/Loading';

const LoadShareSearch: React.FC = (props: any) => {
  const { setItemOffset, setItemsPerPage } = useContext(ApplicationContext);
  const { setManufacturerProduct, updateConfigitCriteria, layerConfigMasterData, sections } = useContext(ConfigitContext);
  const params = useParams();
  if (!params.ShareLinkGuid) {
    throw Error('Empty ShareLinkGuid');
  }
  const { data, error, loading } = useGetUserShareLinkSearchCriteriaQuery({
    variables: { ShareLinkGuid: params.ShareLinkGuid },
  });
  useEffect(() => {
    // if search criteria, extended layer configs and sections are populated
    if (data && layerConfigMasterData?.length && sections.length) {
      const configitCriteriaString = atob(data.UserShareLink[0].SearchCriteriaData);
      const configitCriteria: IConfigitCriteria = JSON.parse(configitCriteriaString);
      if (configitCriteria.manufacturerProduct.length) setManufacturerProduct(configitCriteria.manufacturerProduct);
      else {
        updateConfigitCriteria(
          configitCriteria.variableAssignments,
          configitCriteria.layers,
          configitCriteria.excludedLayerIds
        );
      }
    }
  }, [data, layerConfigMasterData, !sections.length]);
  // !sections.length should only trigger this effect when sections is undefined and populated not everytime it changes
  return (<div className="d-flex justify-content-center align-items-center loading-spinner-full">
    <Loading />
  </div>);
};

export default LoadShareSearch;
