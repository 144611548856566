import { Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ApplicationContext } from '../../components/ApplicationProvider';
import { TextOnlyComponent } from '../../components/shared/TextOnlyComponent/TextOnlyComponent';
import { useGetNavAssemblyCompleteDetailQuery } from '../../models/GQLGeneratedModels';
import theme from '../../theme';
import { Detail } from './Detail';
import { Summary } from './Summary';
import { genrateUuidv4, getScenarioDetailById } from '../../services/ScenarioService';
import { useMsal } from '@azure/msal-react';
import { Dialog } from '../../components/shared/DialogComponent/Dialog';
import { ErrorModalComponent } from '../../components/shared/ErrorModalComponent/ErrorModalComponent';

export const AssemblyDetail: React.FC = (props: any) => {
  const location = useLocation();
  const { instance: azureADPCA } = useMsal();
  const urlParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const params = useParams();
  if (!params.assemblyid) {
    throw Error('Empty Assembly id');
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const fromProductSearch = searchParams.get('linkedProductSearch') && searchParams.get('linkedProductSearch') === '1' ? true : false;
  //console.log('fromProductSearch is ', fromProductSearch);

  const { setDialog, setShowDialog } = useContext(ApplicationContext)
  const { data, loading, error } = useGetNavAssemblyCompleteDetailQuery({
    variables: {
      NavAssemblyId: parseInt(params.assemblyid),
    },
  });
  useEffect(() => {
    if (error) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [error]);

  const { numShowResult, setNumShowResult } = useContext(ApplicationContext);
  const { setTriggerRoofTypeList } = useContext(ApplicationContext);
  const { setTriggerAssemblyList } = useContext(ApplicationContext);
  const { setTriggerSearchResultCount } = useContext(ApplicationContext);
  const [savedData, setSavedData] = useState<any>(undefined)
  //let savedData: any | undefined;
  const properties = data?.NavAssembly[0];
  const layers = data?.vNavAssembly_Layers;
  const usages = data?.vNavAssembly_Usages;
  let savedScenarioId;
  const navigateBack = () => {
    if (fromProductSearch) { history.back(); }
    else {
      setTriggerSearchResultCount(true);
      setTriggerRoofTypeList(true);
      setTriggerAssemblyList(true);
      setNumShowResult(numShowResult + 1);

      navigate(`/home/assembliesListing?${urlParams.toString()}`);
    }
  }
  useEffect(() => {
    // reset state on initial render- TODO: add in check for navassembly id
    const getSinglePlyData = async () => {
      // TODO: need to account for slower systems, etc
      let userEmail = azureADPCA.getActiveAccount()?.username || 'test@test.com';
      let tokenClaims;
      if (azureADPCA.getActiveAccount()?.idTokenClaims) {
        tokenClaims = azureADPCA.getActiveAccount()?.idTokenClaims;
        if (tokenClaims && tokenClaims.hasOwnProperty('email')) {
          userEmail = (tokenClaims as any)['email'];
        }
      }
      savedScenarioId = location.state.savedScenarioId;
      const savedScenario = await getScenarioDetailById(savedScenarioId, userEmail, genrateUuidv4());
      setSavedData(savedScenario[0])
    };
    if (location.state && location.state.savedScenarioId) {
      getSinglePlyData();
    }
  }, []);
  return (
    <main className="main-content container-fluid flex-shrink-0">
      <div>
        <div className="row justify-content-center">
          <div className="col-12 pt-5">
            <section className="bg-white h-100 overflow-hidden">
              {!loading && (
                <>
                  <div className="row align-items-stretch">
                    <div className="col-9">
                      <div className="d-flex align-items-center h-100">
                        <Typography
                          className="mx-3"
                          fontFamily={'Inter'}
                          fontSize={'36px'}
                          fontWeight={700}
                          lineHeight={'44px'}
                          color={theme.palette.rnSteel.steel700}
                        >
                          Assembly Detail
                        </Typography>
                      </div>
                    </div>
                    <div className="col px-4 my-1 text-right">
                      {!savedData && (
                        <a onClick={navigateBack} >
                          <TextOnlyComponent buttonText={'Back'} iconName={'arrow_left'} />
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="card border-0 h-100">
                    <div className="card-body p-0">
                      <Summary properties={properties} usages={usages} layers={layers} />
                      <Detail
                        layers={layers}
                        usages={usages}
                        properties={properties}
                        newSavedData={savedData}
                        savedScenarioId={savedScenarioId}
                      />
                    </div>
                  </div>
                </>
              )}
            </section>
          </div>
        </div>
      </div>
    </main>
  );
};
