import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ISelectValue } from '../components/shared/SelectComponent/SelectBox';

// note: some type conversion (or just type changes on redux side) may be needed when integrating calculator API
// Current types subject to change
const externalFireRating: ISelectValue = { value: undefined, name: 'None Selected' };
const internalFireRating: ISelectValue = { value: undefined, name: 'None Selected' };
const hailRating: ISelectValue = { value: '', name: 'None Selected' };
const enclosureClassification: ISelectValue = { value: -1, name: 'None Selected' };
const surfaceRoughness: ISelectValue = { value: -1, name: '' };

const fireState = {
    internalFireRating: internalFireRating,
    externalFireRating: externalFireRating,
}
const hailState = { hailRating: hailRating }
const windState = {
    cycloneProneLocation: false,
    enclosureClassification: enclosureClassification,
    hasParapet: false,
    roofAreaHeight: '',
    roofAreaLength: '',
    roofAreaSlope: '',
    roofAreaWidth: '',
    surfaceRoughness: surfaceRoughness,
    windSpeed: '',
    isRiseRun: true,
}

const initialState = {
    calculatorIndex: 0,
    showCalc: false,
    fireRatingState: fireState,
    hailRatingState: hailState,
    windRatingState: windState,
    isWindComplete: false

};

export const calculatorSlice = createSlice({
    name: 'calculator',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        jumpToIndex: (state, action: PayloadAction<number>) => {
            state.calculatorIndex = action.payload;
        },
        nextSection: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            // technically check isn't needed, but including for now
            if (state.calculatorIndex < 3) {
                state.calculatorIndex += 1;
            }
        },
        previousSection: (state) => {
            state.calculatorIndex -= 1;
        },
        resetState: (state) => {
            state = initialState;
        },
        setCycloneProne: (state, action: PayloadAction<boolean>) => {
            state.windRatingState.cycloneProneLocation = action.payload
        },
        setEnclosureClassification: (state, action: PayloadAction<ISelectValue>) => {
            state.windRatingState.enclosureClassification = action.payload
        },
        setExternalFire: (state, action: PayloadAction<ISelectValue>) => {
            state.fireRatingState.externalFireRating = action.payload;
        },
        setHail: (state, action: PayloadAction<ISelectValue>) => {
            state.hailRatingState.hailRating = action.payload
        },
        setHasParapet: (state, action: PayloadAction<boolean>) => {
            state.windRatingState.hasParapet = action.payload
        },
        setInternalFire: (state, action: PayloadAction<ISelectValue>) => {
            state.fireRatingState.internalFireRating = action.payload;
        },
        setIsRiseRun: (state, action: PayloadAction<boolean>) => {
            state.windRatingState.isRiseRun = action.payload
        },
        setIsWindComplete: (state, action: PayloadAction<boolean>) => {
            state.isWindComplete = action.payload;
        },
        setRoofAreaHeight: (state, action: PayloadAction<string>) => {
            state.windRatingState.roofAreaHeight = action.payload
        },
        setRoofAreaLength: (state, action: PayloadAction<string>) => {
            state.windRatingState.roofAreaLength = action.payload
        },
        setRoofAreaSlope: (state, action: PayloadAction<string>) => {
            state.windRatingState.roofAreaSlope = action.payload
        },
        setRoofAreaWidth: (state, action: PayloadAction<string>) => {
            state.windRatingState.roofAreaWidth = action.payload
        },
        setSurfaceRoughness: (state, action: PayloadAction<ISelectValue>) => {
            state.windRatingState.surfaceRoughness = action.payload
        },
        // currently this value is a string- once data is plugged in, may change
        setWindSpeed: (state, action: PayloadAction<string>) => {
            state.windRatingState.windSpeed = action.payload
        },
        toggleCalc: (state, action: PayloadAction<boolean>) => {
            state.showCalc = action.payload
        }

    },

});


export const {
    jumpToIndex,
    nextSection,
    previousSection,
    resetState,
    setCycloneProne,
    setEnclosureClassification,
    setExternalFire,
    setHail,
    setHasParapet,
    setInternalFire,
    setIsRiseRun,
    setIsWindComplete,
    setRoofAreaHeight,
    setRoofAreaLength,
    setRoofAreaSlope,
    setRoofAreaWidth,
    setSurfaceRoughness,
    setWindSpeed,
    toggleCalc
} = calculatorSlice.actions
export default calculatorSlice.reducer;
