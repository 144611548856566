import { Box, TextField, Typography } from '@mui/material';
import { ButtonComponent } from '../../components/shared/ButtonComponent/ButtonComponent';
import TextAreaComponent from '../../components/shared/TextAreaComponent';
import React, { useContext, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { IScenarioUsage } from '../../types/interfaces';
import { ConfigitContext } from '../../components/ConfigitProvider';
import { useLocation, useNavigate, useParams } from 'react-router';
import { genrateUuidv4, saveAssemblyScenario } from '../../services/ScenarioService';
import { ScenarioInfo, ScenarioLayer } from '../../models/GQLGeneratedModels';
import { ApplicationContext } from '../../components/ApplicationProvider';
import theme from '../../theme';

export interface IScenarioLayerForAPI {
  ScenarioLayerId?: number;
  ScenarioId?: number;
  NavLayerId: number;
  LayerNum: number;
  LayerText: string;
  LayerTypeID: number;
  IsPermeable: boolean;
  IsOptional: boolean;
  ScenarioUsages?: IScenarioUsage[];
}

export const SaveModal = (props: any) => {
  const { properties, layers } = props;
  const { instance: azureADPCA } = useMsal();
  const { curScenarioState } = useContext(ConfigitContext);
  const { setShowDialog } = useContext(ApplicationContext);
  const [notes, setNotes] = useState(curScenarioState.Comments ? curScenarioState.Comments : '');
  const [title, setTitle] = useState(curScenarioState.Name ? curScenarioState.Name : '');
  const [titleError, setTitleError] = useState(false);
  const params = useParams();
  const location = useLocation();
  const invalidLayerTypeIds = [35, 50, 25];
  const navigate = useNavigate();
  const saveData = () => {
    if (title === '') {
      setTitleError(true);
    }
    if (titleError && title.length > 0) {
      setTitleError(false);
    }

    if (title !== '') {
      let userEmail = azureADPCA.getActiveAccount()?.username || 'test@test.com'; // works for initial Azure B2C before customization
      // For customized Azure B2C, user email needs to be retrieved as follows:
      let tokenClaims;
      let userGUID;
      if (azureADPCA.getActiveAccount()?.idTokenClaims) {
        tokenClaims = azureADPCA.getActiveAccount()?.idTokenClaims;
        if (tokenClaims && tokenClaims.hasOwnProperty('email')) {
          userEmail = (tokenClaims as any)['email'];
        }
        //  if (tokenClaims && tokenClaims.hasOwnProperty('aud')) {
        userGUID = azureADPCA.getActiveAccount()?.localAccountId as string;
        //  }
      }
      const dataToSave: ScenarioInfo = {
        ScenarioStatusId: curScenarioState.ScenarioStatusId != -1 ? curScenarioState.ScenarioStatusId : 1,
        ScenarioTypeId: curScenarioState.ScenarioTypeId != -1 ? curScenarioState.ScenarioTypeId : 1,
        ScenarioId: curScenarioState.ScenarioId,
        Name: 'New Scenario',
        NavAssemblyId: Number(params.assemblyid),
        Comments: '',
        ScenarioLayers: [],
      };
      dataToSave.AzureB2CUserEmail = userEmail;
      dataToSave.AzureB2CUserGuid = userGUID;
      dataToSave.Comments = notes;
      dataToSave.Name = title;
      dataToSave.NavAssemblyName = properties.NavAssemblyName;
      curScenarioState.ScenarioLayers?.forEach((curLayer) => {
        const layerToAdd: IScenarioLayerForAPI = {
          ScenarioLayerId: curLayer.ScenarioLayerId,
          ScenarioId: curLayer.ScenarioId,
          NavLayerId: curLayer.NavLayerId,
          LayerNum: curLayer.LayerNum,
          LayerText: curLayer.LayerText,
          LayerTypeID: curLayer.LayerTypeID,
          IsPermeable: curLayer.IsPermeable,
          IsOptional: curLayer.IsOptional,
          ScenarioUsages: [],
        };
        if (curLayer.ScenarioUsages.UsageId > 0) {
          layerToAdd.ScenarioUsages?.push({
            UsageId: curLayer.ScenarioUsages.UsageId,
            LayerNum: curLayer.ScenarioUsages.LayerNum,
          });
        }
        if (curLayer.ScenarioUsages.SelfSecurementUsageId) {
          layerToAdd.ScenarioUsages?.push({
            UsageId: curLayer.ScenarioUsages.SelfSecurementUsageId,
            LayerNum: curLayer.ScenarioUsages.LayerNum,
            SelfSecurementText: curLayer.ScenarioUsages.SelfSecurementText,
            SelfSecurementLayerTypeId: curLayer.ScenarioUsages.SelfSecurementLayerTypeId,
          });
        }
        dataToSave.ScenarioLayers?.push(layerToAdd);
      });
      layers.forEach((layer: any) => {
        if (!dataToSave.ScenarioLayers?.find((curLayer: any) => curLayer.LayerNum === layer.NavLayerNum)) {
          const tempLayer: IScenarioLayerForAPI = {
            NavLayerId: layer.NavLayerId,
            LayerNum: layer.NavLayerNum,
            LayerText: getLayerTitle(
              layer.NavLayerNum,
              layer.LayerTypeCode,
              layer.FromNavLayerNum,
              layer.ToNavLayerNum
            ),
            LayerTypeID: layer.LayerTypeId,
            IsPermeable: layer.IsPermeable ? layer.IsPermeable : false,
            IsOptional: layer.IsOptional ? layer.IsOptional : false,
            ScenarioUsages: [],
          };
          dataToSave.ScenarioLayers?.push(tempLayer);
        }
      });
      if (location.state && location.state.savedScenarioId) {
        saveAssemblyScenario(dataToSave, 2);

      } else {
        saveAssemblyScenario(dataToSave, 1);

      }
      const randguid = genrateUuidv4();
      navigate(`/savedItems/${randguid}`);
      //navigate('/savedItems');
      setShowDialog(false);
    }
  };
  const getLayerTitle = (
    layerNum: number,
    layerTypeCode: string,
    fromNavLayerNum: number | null | undefined,
    toNavLayerNum: number | null | undefined
  ) => {
    if (fromNavLayerNum && toNavLayerNum && fromNavLayerNum !== toNavLayerNum) {
      const fromLayer = layers.find((l: any) => l.NavLayerNum === fromNavLayerNum);
      const toLayer = layers.find((l: any) => l.NavLayerNum === toNavLayerNum);
      if (fromLayer && toLayer)
        return `${layerNum}. ${layerTypeCode} from ${fromNavLayerNum}. ${fromLayer.LayerTypeCode} to ${toNavLayerNum}. ${toLayer.LayerTypeCode}`;
      else return `${layerNum}. ${layerTypeCode}`;
    } else return `${layerNum}. ${layerTypeCode}`;
  };
  return (
    <Box display="flex" flexDirection="column">
      <label htmlFor="SavedItemTitle" className={'small-para-semibold ml-2'}>
        Title:
        <span style={{ color: theme.palette.rnMisc.red }}> *</span>
      </label>
      <TextField
        id={'SavedItemTitle'}
        required
        value={title}
        error={titleError}
        onChange={(e) => setTitle(e.target.value)}
        helperText={titleError ? 'This field is required' : ''}
      />
      <br></br>
      <TextAreaComponent
        label="Comments:"
        value={notes}
        setValue={setNotes}
        errorMsg={'Please enter a short description of the error'}
      />
      <br></br>
      <div className="row m-0">
        <ButtonComponent buttonType="" buttonText="Save" buttonCss={{ margin: 0 }} click={() => saveData()} />
        &nbsp; &nbsp;
        <ButtonComponent
          buttonType="primary"
          buttonText="Close"
          buttonCss={{ margin: 0 }}
          click={() => setShowDialog(false)}
        />
      </div>
    </Box>
  );
};
