import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetApprovalsDocByIdQuery } from '../../models/GQLGeneratedModels';
import { Box, Typography } from '@mui/material';
import theme from '../../theme';
import { Loading } from '../shared/Loading/Loading';
import { ApplicationContext } from '../ApplicationProvider';
import { Dialog } from '../shared/DialogComponent/Dialog';
import { ErrorModalComponent } from '../shared/ErrorModalComponent/ErrorModalComponent';

const LoadDocument: React.FC = (props: any) => {
  const params = useParams();
  if (!params.documentid) {
    throw Error('Empty documentid');
  }

  const { data, loading, error } = useGetApprovalsDocByIdQuery({
    variables: { ApprovalsDocId: parseInt(params.documentid) },
  });
  const { setDialog, setShowDialog } = useContext(ApplicationContext);

  useEffect(() => {
    if (error) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [error])
  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Loading />
      </div>
    );
  }

  const doc = data?.ApprovalsDoc[0];

  return (
    <main className="container-fluid ">
      <Box className={'d-flex justify-content-center h-100'}>
        <div className={'col-12 px-3'}>
          <Typography
            color={theme.palette.rnSteel.steel700}
            className={'py-2 headline-1'}
          >
            {doc?.DocName}
          </Typography>

          <object
            className={'overflow-auto'}
            width="100%"
            height="1150px"
            type="application/pdf"
            data={'data:application/pdf;base64,' + doc?.DocData}
          >
            <embed src={'data:application/pdf;base64,' + doc?.DocData} type="application/pdf" />
          </object>
        </div>
      </Box>

    </main>
  );
};

export default LoadDocument;
