import React, { useContext } from 'react';
import { mapWithDatabase } from '../../services/ConfigitService';
import { ConfigitContext } from '../ConfigitProvider';
import { ConfigitForm } from '../Filters/ConfigitForm';

export const ManufacturerProductSearch: React.FC = () => {
    const { manufacturerProduct, setManufacturerProduct, section } = useContext(ConfigitContext);
    let data;
    if (section) {
        data = mapWithDatabase([section])[0];
    }
    return (
        <>
            {data &&
                <ConfigitForm
                    variableAssignments={manufacturerProduct}
                    setVariableAssignments={setManufacturerProduct}
                    section={data}
                />
            }
        </>
    );
};
