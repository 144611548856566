import React from 'react';
import { useGetBaseSheetUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { Length } from '../../utils/Measurement/Length';
import './UsageDetails.scss';

export const BaseSheetUsageDetail = (props: any) => {
  const { data, loading, error } = useGetBaseSheetUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];
  const isMetric = usage?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Base Sheet</h4>
      <table>
        <tr>
          <td>Permeable:</td>
          <td>{usage?.Component?.ManufacturedProduct?.BaseSheet?.IsPermeable ? 'Yes' : 'No'}</td>
        </tr>
        <tr>
          <td>Max Width:</td>
          <td>{Length.display(usage?.BaseSheetUsage?.WidthMax, isMetric)}</td>
        </tr>
        <tr>
          <td>Backing:</td>
          <td>{usage?.Component?.ManufacturedProduct?.BaseSheet?.C_BackingMaterial.BackingMaterialCode}</td>
        </tr>
        <tr>
          <td>Lap Width:</td>
          <td>{Length.display(usage?.BaseSheetUsage?.LapWidth, isMetric)}</td>
        </tr>
        <tr>
          <td>Width:</td>
          <td>{Length.display(usage?.Component?.ManufacturedProduct?.BaseSheet?.Width, isMetric)}</td>
        </tr>
        <tr>
          <td>Material:</td>
          <td>{usage?.Component?.ManufacturedProduct?.BaseSheet?.C_MultiplyMaterial.MultiplyMaterialCode}</td>
        </tr>
        <tr>
          <td>Reinforcement:</td>
          <td>{usage?.Component?.ManufacturedProduct?.BaseSheet?.C_Reinforcement.ReinforcementCode}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
