import { useContext, useEffect, useState } from 'react';
import { apolloClient } from '../ApolloClient';
import {
  AttributeSearchCriteria,
  Maybe,
  ProductApiSearchDocument,
  ProductApiSearchQuery,
  ProductApiSearchQueryVariables,
  ProductApiListingQuery,
  ProductApiListingQueryVariables,
  ProductApiListingDocument,
  ProductSearchCriteria,
  Order_By,
  GetProductDetailAllInfoQuery,
  GetProductDetailAllInfoQueryVariables,
  GetProductDetailAllInfoDocument,
  GetComponentDocByIdQuery,
  GetComponentDocByIdQueryVariables,
  GetComponentDocByIdDocument,
  useGetApprovalsDocByCategoryIdQuery,
  ProductApiMatchedAssemblyCountQuery,
  ProductApiMatchedAssemblyCountQueryVariables,
  ProductApiMatchedAssemblyCountDocument,
} from '../models/GQLGeneratedModels';
import { AttributeType, ComparisonOperator } from './AssemblySearchAPIService';
import { ConfigitProductContext } from '../components/ConfigitProductProvider';
import { useLogger } from 'fmg-telemetry-react';

export interface attributeSearchCriteria {
  attributeName: string;
  comparisonOperator: ComparisonOperator;
  attributeValue: string;
  attributeType: AttributeType;
}

export const useProductApiSearchQuery = (
  manufacturerId?: number,
  componentId?: number,
  categoryId?: number,
  subCategoryId?: number,
  approvedUseId?: number,
  attributes?: attributeSearchCriteria[],
  countryId?: number
) => {
  const logger = useLogger();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  const { variableAssignments } = useContext(ConfigitProductContext)

  useEffect(() => {
    const productSearchCriteria = {
      manufacturerId: manufacturerId,
      componentId: componentId,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
      approvedUseId: approvedUseId,
      attributes: attributes,
      countryId: countryId,
    };
    apolloClient
      .query<ProductApiSearchQuery, ProductApiSearchQueryVariables>({
        query: ProductApiSearchDocument,
        variables: {
          productSearchCriteria: productSearchCriteria,
        },
      })
      .then((result) => {
        setData(result.data);
        setLoading(result.loading);
        setError(result.error);
      }).catch((error) => {
        setLoading(false);
        setError(error);
        logger.error('Error in useProductApiSearchQuery', { searchCriteriaUsed: productSearchCriteria, error: error, msg: error.message })
      });
  }, [JSON.stringify(variableAssignments)]);
  return { data, loading, error };
};
export const useGetProductListByAPIQuery = (
  iniSearchCriteria: ProductSearchCriteria,
  pageNumber: number,
  rowsPerPage: number,
  sortColumn: string,
  sortDirection: number,
  targetApprovedUseId?: number,
  targetCategoryId?: number,
  targetSubCategoryId?: number
) => {
  const logger = useLogger();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  useEffect(() => {
    if (iniSearchCriteria) {
      setLoading(true);
      const productSearchCriteriaWithTarget = {
        iniSearchCriteria: iniSearchCriteria as Maybe<Maybe<ProductSearchCriteria>>,
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        targetApprovedUseId: targetApprovedUseId,
        targetCategoryId: targetCategoryId,
        targetSubCategoryId: targetSubCategoryId,
      };
      productSearchCriteriaWithTarget.rowsPerPage = rowsPerPage;
      productSearchCriteriaWithTarget.pageNumber = pageNumber / rowsPerPage + 1;
      apolloClient
        .query<ProductApiListingQuery, ProductApiListingQueryVariables>({
          query: ProductApiListingDocument,
          variables: {
            productSearchCriteriaWithTarget: productSearchCriteriaWithTarget,
          },
        })
        .then((result) => {
          setData(result.data);
          setLoading(result.loading);
          setError(result.error);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
          logger.error('Error in useGetProductListByAPIQuery', { searchCriteriaUsed: productSearchCriteriaWithTarget, error: error, msg: error.message })

        });
    }
  }, [rowsPerPage, pageNumber, sortDirection, JSON.stringify(sortColumn), JSON.stringify(iniSearchCriteria)]);
  return { data, loading, error };
};
export const getProductDetailByComponentId = async (componentId: number) => {
  const result = await apolloClient.query<GetProductDetailAllInfoQuery, GetProductDetailAllInfoQueryVariables>({
    query: GetProductDetailAllInfoDocument,
    variables: {
      ComponentId: componentId,
    },
  });
  return result.data;
};
export const getProductDocumentByDocumentId = async (ComponentDocId: number) => {
  const result = await apolloClient.query<GetComponentDocByIdQuery, GetComponentDocByIdQueryVariables>({
    query: GetComponentDocByIdDocument,
    variables: {
      ComponentDocId: ComponentDocId,
    },
  });
  return result.data.ComponentDoc;
};

export const getProductMatchedAssemblyCount = async (componentId: number) => {
  const result = await apolloClient.query<
    ProductApiMatchedAssemblyCountQuery,
    ProductApiMatchedAssemblyCountQueryVariables
  >({
    query: ProductApiMatchedAssemblyCountDocument,
    variables: {
      producMatchAssemblyCountCriteria: { componentId: componentId },
    },
  });
  return result.data;
};
