import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ManufacturerProductSearch } from '../ManufacturerProductSearch';
import { AccordionComponent } from '../shared/AccordionComponent/AccordionComponent';
import { ApplicationContext } from '../ApplicationProvider';
import { ButtonComponent } from '../shared/ButtonComponent/ButtonComponent';

export const ProductsUsed = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let collapsed = params.get('openProductsUsed') == null;
  const { showProductsUsed } = useContext(ApplicationContext);
  const { setTriggerSearchResultCount } = useContext(ApplicationContext);
  const { setTriggerRoofTypeList } = useContext(ApplicationContext);
  const { setTriggerAssemblyList } = useContext(ApplicationContext);
  const { numShowResult, setNumShowResult } = useContext(ApplicationContext);

  const handleShowSearchResult = () => {
    setTriggerSearchResultCount(true);
    setTriggerRoofTypeList(true);
    setTriggerAssemblyList(true);
    setNumShowResult(numShowResult + 1);
  };

  useEffect(() => {
    if (showProductsUsed) {
      collapsed = true;
      toggleCollapsed();
    }
  }, [showProductsUsed]);
  const toggleCollapsed = () => {
    collapsed ? params.set('openProductsUsed', 'true') : params.delete('openProductsUsed');
    navigate('?' + params.toString());
  };
  return (
    <AccordionComponent
      id="accordionProdUsed"
      title="Product Used"
      collapsed={collapsed}
      toggleCollapsed={toggleCollapsed}
    >
      <ManufacturerProductSearch />
    </AccordionComponent>
  );
};