import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import React from 'react';
import theme from '../../../theme';
import './SavedAssembly.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UsageDetails } from '../../UsageDetails';

export const Detail: React.FC<any> = (props: any) => {
  const { layers } = props;

  const DetailsAccordion = styled(Accordion)({
    '&.MuiAccordion-root': {
      backgroundColor: theme.palette.rnSteel.steel500,
      border: 'none',
      boxShadow: 'none !important',
    },
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
    '&.MuiAccordion-root:after': {
      height: '1px',
      backgroundColor: theme.palette.rnGrey.grey600,
    },
    '& .MuiCollapse-root': {
      width: '100%',
    },
  });
  const DetailAccordionDetails = styled(AccordionDetails)({
    '&.MuiAccordionDetails-root': {
      backgroundColor: theme.palette.rnSteel.steel500,
      border: 'none',
    },
  });
  const DetailsAccordionSummary = styled(AccordionSummary)({
    '&.MuiAccordionSummary-root': {
      pointerEvents: 'none',
    },
    '& .MuiAccordionSummary-content': {
      margin: '3px 0',
    },
  });

  const DetailsTypography = styled(Typography)({
    '&.MuiTypography-root': {
      backgroundColor: theme.palette.rnMisc.white,
      padding: '1px',
      borderRadius: '6px',
    },
  });
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: '500px',
      padding: '1px',
      border: '1px solid #EFF0F1',
      boxShadow: '5px 5px 8px -1px rgba(0, 0, 0, 0.18)',
    },
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        backgroundColor: '#C93102',
      },
    },
  }));

  const getSystemUsages = (StructuredSystemProducts: any, usageId: number, approvedUsedId: number) => {

    return StructuredSystemProducts.map((systemProduct: any) => (
      <>
        <div key={systemProduct.ComponentId} className={'detail-row d-flex'}>
          <div className={'col-5 d-flex align-items-center'}>
            <Typography fontFamily={'Inter'} fontWeight={500} style={{ padding: 3 }}>
              {systemProduct.ManufacturedProduct.Manufacturer.Name}
            </Typography>
          </div>
          <div className={'col-6 d-flex align-items-center'}>
            <Typography fontFamily={'Inter'} fontWeight={500}>
              {systemProduct.ManufacturedProduct.TradeName}
            </Typography>
          </div>
          <div className="d-flex align-items-center justify-content-end col">
            {usageId > 0 && (
              <HtmlTooltip
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={<UsageDetails usageId={usageId} approvedUseId={approvedUsedId} />}
                arrow
                placement={'left'}
              >
                <Typography fontFamily={'Inter'} color={theme.palette.rnOrange.darkOrange} fontWeight={600}>
                  View
                </Typography>
              </HtmlTooltip>
            )}
          </div>
        </div>
      </>
    ));
  };
  const checkIfLayerContainSelfSecurement = (layerTypeid: null) => {
    return layerTypeid !== null ? true : false;
  };
  const checkUsageType = (isSystemUsage: number) => {
    if (!isSystemUsage) return false;
    return isSystemUsage > 0 ? true : false;
  };
  const getRegularUsage = (ManufacturedProduct: any, usageId: number, approvedUsedId: number) => {
    return (
      <>
        <div className={'detail-row d-flex'}>
          <div className={'col-5 d-flex align-items-center'}>
            <Typography fontFamily={'Inter'} fontWeight={500} style={{ padding: 3 }}>
              {ManufacturedProduct.Manufacturer.Name}
            </Typography>
          </div>
          <div className={'col-6 d-flex align-items-center'}>
            <Typography fontFamily={'Inter'} fontWeight={500}>
              {ManufacturedProduct.TradeName}
            </Typography>
          </div>
          <div className="d-flex align-items-center justify-content-end col">
            {usageId > 0 && (
              <HtmlTooltip
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={<UsageDetails usageId={usageId} approvedUseId={approvedUsedId} />}
                arrow
                placement={'left'}
              >
                <Typography fontFamily={'Inter'} color={theme.palette.rnOrange.darkOrange} fontWeight={600}>
                  View
                </Typography>
              </HtmlTooltip>
            )}
          </div>
        </div>
      </>
    );
  };
  const getSystemName = (systemName: any, isOptional: boolean) => {
    return (
      <>
        <div className={'detail-row d-flex'}>
          <div className={'col-10 d-flex align-items-center'}>
            <Typography fontFamily={'Inter'} fontWeight={500} style={{ padding: 3 }}>
              {systemName}
            </Typography>
          </div>
          <div className="d-flex align-items-center justify-content-end col">
            {isOptional && (
              <Typography fontWeight={500} color={theme.palette.rnMisc.white}>
                Optional&nbsp;
              </Typography>
            )}
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="bg-white p-3">
      <Typography
        className={'py-3'}
        color={theme.palette.rnSteel.steel700}
        fontFamily={'Inter'}
        fontSize={'28px'}
        lineHeight={'40px'}
        fontWeight={600}
      >
        Assembly Details
      </Typography>
      {layers.length === 0 &&
        <div>
          <Typography color={theme.palette.rnMisc.red}>
            There are no valid layers in this saved assembly.
          </Typography>
        </div>}
      {layers.map((layer: any) => {
        return (
          <div key={layer.NavLayerId} className="bg-white w-100 mb-0 pb-1">
            <DetailsAccordion className={`row p-1 m-0 pe-none`} disableGutters expanded={true}>
              <DetailsAccordionSummary className="row p-0 m-0 justify-content-between w-100">
                <Typography
                  className={'pe-none col-9'}
                  color={theme.palette.rnMisc.white}
                  fontWeight={600}
                  fontFamily={'Inter'}
                  fontSize={'20px'}
                  lineHeight={'28px'}
                >
                  {layer.LayerText}
                </Typography>
                <div className={`row m-0 col-3 d-flex justify-content-end align-content-center`}>
                  {layer.IsOptional && (
                    <>
                      <Typography fontWeight={500} color={theme.palette.rnMisc.white}>
                        Optional&nbsp;
                      </Typography>
                      {/* <div className="col d-flex align-items-center justify-content-end  h-0">
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                      </div> */}
                    </>
                  )}
                </div>
              </DetailsAccordionSummary>
              {layer.NavLayer?.Layer?.Comments && (
                <DetailAccordionDetails className={'mx-4'}>
                  <DetailsTypography fontFamily={'Inter'} fontSize={'12.5px'} fontWeight={500}>
                    {layer.NavLayer.Layer?.Comments}
                  </DetailsTypography>
                </DetailAccordionDetails>
              )}
            </DetailsAccordion>

            {layer.ScenarioUsages.map((usage: any) => {
              if (!usage.Usage) {
                return (
                  <div className='col' key={usage.ScenarioUsageId}>
                    <DetailsTypography color={theme.palette.rnMisc.red}>
                      (No Data)
                    </DetailsTypography>
                  </div>
                )
              }
              return (
                <div key={usage.ScenarioUsageId} className="detail-section">
                  {checkIfLayerContainSelfSecurement(usage.SelfSecurementLayerTypeId) ? (
                    <div className={'row m-0 p-1'}>
                      <div className="w-100">
                        <div className={'details-securement'}>
                          <Typography
                            color={theme.palette.rnSteel.steel700}
                            fontWeight={600}
                            fontFamily={'Inter'}
                            fontSize={'20px'}
                            lineHeight={'20px'}
                          >
                            {usage.SelfSecurementText}
                          </Typography>
                        </div>
                        <div>
                          {checkUsageType(usage.Usage.Component?.StructuredSystem?.StructuredSystemProducts?.length)
                            ? [
                              getSystemName(usage.Usage.Component?.StructuredSystem.SystemName, layer.IsOptional),
                              getSystemUsages(
                                usage.Usage.Component?.StructuredSystem?.StructuredSystemProducts,
                                usage.UsageId,
                                usage.Usage.ApprovedUseId
                              ),
                            ]
                            : getRegularUsage(
                              usage.Usage.Component?.ManufacturedProduct,
                              usage.UsageId,
                              usage.Usage.ApprovedUseId
                            )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {checkUsageType(usage?.Usage?.Component?.StructuredSystem?.StructuredSystemProducts?.length)
                        ? [
                          getSystemName(usage?.Usage?.Component?.StructuredSystem.SystemName, layer.IsOptional),
                          getSystemUsages(
                            usage.Usage?.Component?.StructuredSystem?.StructuredSystemProducts,
                            usage.UsageId,
                            usage.Usage.ApprovedUseId
                          ),
                        ]
                        : getRegularUsage(
                          usage.Usage.Component?.ManufacturedProduct,
                          usage.UsageId,
                          usage.Usage.ApprovedUseId
                        )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
