import React, { useState } from 'react';
import { NavAssembly_Order_By } from '../models/GQLGeneratedModels';
import { Section, VariableAssignment } from '../types/configurator';
import { IScenario } from '../types/interfaces';
export interface IManufacturerProduct {
  manufacturerId: string;
  manufacturerLabel?: string;
  productId: string;
  productLabel?: string;
  uiselectmode?: string;
}
export interface IVariableAssigmentLabels {
  variableId: string;
  variableLabel: string;
  valueLabel: string;
  type?: string;
}

export interface ILayer {
  id: string;
  autoSelected: boolean;
  model?: string;
  isSecurementLayer?: boolean;
  hasSelfSecurement?: boolean;
  SelfSecurementId?: string;
  variableAssignments?: VariableAssignment[];
  variableAssignmentLabels?: IVariableAssigmentLabels[];
  productDetails?: IManufacturerProduct[];
  section?: Section;
  selfSecurement?: ISelfSecurement;
}

export interface ISecurementLayer extends ILayer {
  fromLayerNum?: number;
  fromLayerTypeId?: number;
  toLayerNum?: number;
  toLayerTypeId?: number;
}

export interface ISelfSecurement {
  idLayerType: string; // Possible values are '40', '43', '51'
  autoSelected: boolean;
  baseLayerTypeId: string;
  model?: string;
  variableAssignments?: VariableAssignment[];
  variableAssignmentLabels?: IVariableAssigmentLabels[];
  productDetails?: IManufacturerProduct[];
  section?: Section;
}

export interface ILayerConfigSymbol {
  Expression: string;
  BaseLocationNum: number;
  RelativePosition: number;
}

export interface IExtendedLayerConfig {
  ExtendedLayerConfigId: number;
  FullConfigString: string;
  GenericSecurementDuplicateString: string;
}

export interface IConfigitContext {
  variableAssignments: VariableAssignment[];
  setVariableAssignments: (variableAssignments: VariableAssignment[]) => void;
  manufacturerProduct: VariableAssignment[];
  setManufacturerProduct: (variableAssignments: VariableAssignment[]) => void;
  section?: Section;
  setSection: (section: Section) => void;
  sections: Section[];
  setSections: (sections: Section[]) => void;
  layers: ILayer[];
  setLayers: (layers: ILayer[]) => void;
  excludedLayerIds: string[];
  setExcludedLayerIds: (excludedLayerIds: string[]) => void;
  configureLayer: (layer: ILayer, layerIndex: number) => void;
  clearSearch: () => void;
  updateConfigitCriteria: (
    variableAssignments: VariableAssignment[],
    layers: ILayer[],
    excludedLayerIds: string[]
  ) => void;
  layerConfigMasterData?: IExtendedLayerConfig[];
  setLayerConfigMasterData: (layerConfigMasterData?: IExtendedLayerConfig[]) => void;
  curScenarioState: IScenario;
  setCurScenarioState: (curScenarioState: IScenario) => void;
}

export interface IConfigitCriteria {
  variableAssignments: VariableAssignment[];
  layers: ILayer[];
  excludedLayerIds: string[];
  manufacturerProduct: VariableAssignment[];
  limit: number;
  offset: number;
  orderBy: NavAssembly_Order_By;
}

export const ConfigitContext: React.Context<IConfigitContext> = React.createContext<IConfigitContext>({
  variableAssignments: [],
  setVariableAssignments: () => { },
  manufacturerProduct: [],
  setManufacturerProduct: () => { },
  setSection: () => { },
  sections: [],
  setSections: () => { },
  layers: [],
  setLayers: () => { },
  excludedLayerIds: [],
  setExcludedLayerIds: () => { },
  configureLayer: () => { },
  clearSearch: () => { },
  updateConfigitCriteria: () => { },
  setLayerConfigMasterData: () => { },
  curScenarioState: {
    ScenarioId: -1,
    ScenarioStatusId: -1,
    Name: 'New Scenario',
    NavAssemblyId: -1,
    DateCreated: new Date(),
    ScenarioTypeId: -1,
    Comments: '',
    ScenarioLayers: []
  },
  setCurScenarioState: (curScenarioState: IScenario) => { },
});

export const ConfigitProvider: React.FC = (props: any) => {
  const [variableAssignments, setVariableAssignments] = useState<VariableAssignment[]>([]);
  const [manufacturerProduct, setManufacturerProduct] = useState<VariableAssignment[]>([]);
  const [section, setSection] = useState<Section>();
  const [sections, setSections] = useState<Section[]>([]);
  const [layers, setLayers] = useState<ILayer[]>([]);
  const [excludedLayerIds, setExcludedLayerIds] = useState<string[]>([]);
  const [layerConfigMasterData, setLayerConfigMasterData] = useState<IExtendedLayerConfig[]>();
  const [curScenarioState, setCurScenarioState] = useState<IScenario>({
    ScenarioId: -1,
    ScenarioStatusId: -1,
    Name: 'New Scenario',
    NavAssemblyId: -1,
    DateCreated: new Date(),
    ScenarioTypeId: -1,
    Comments: '',
    ScenarioLayers: []
  });

  const configureLayer = (layer: ILayer, layerIndex: number) => {
    // update layer object at given index in layers state
    const temp = layers.map((data, index) => {
      if (index === layerIndex) return layer;
      data.autoSelected = false;
      return data;
    });
    setLayers(temp);
  };

  const clearSearch = () => {
    setLayers([]);
    setExcludedLayerIds([]);
    setVariableAssignments([]);
  };

  const updateConfigitCriteria = (
    variableAssignments: VariableAssignment[],
    layers: ILayer[],
    excludedLayerIds: string[]
  ) => {
    setLayers(layers);
    setExcludedLayerIds(excludedLayerIds);
    setVariableAssignments(variableAssignments);
  };
  return (
    <ConfigitContext.Provider
      value={{
        variableAssignments,
        setVariableAssignments,
        manufacturerProduct,
        setManufacturerProduct,
        section,
        setSection,
        sections,
        setSections,
        layers,
        setLayers,
        excludedLayerIds,
        setExcludedLayerIds,
        configureLayer,
        clearSearch,
        updateConfigitCriteria,
        layerConfigMasterData,
        setLayerConfigMasterData,
        curScenarioState,
        setCurScenarioState,
      }}
    >
      {props.children}
    </ConfigitContext.Provider>
  );
};
