import { Accordion, AccordionDetails, AccordionSummary, styled, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import theme from '../../../theme';
import './Accordion.css';

interface AccodianType {
  className?: string;
  id: string;
  title: ReactNode | string;
  whiteTitle?: boolean;
  disabled?: boolean;
  collapsed?: boolean;
  toggleCollapsed?: () => void;
  children?: ReactNode;
}

const StyledAccordion = styled(Accordion)({
  '&.MuiAccordion-root': {
    backgroundColor: theme.palette.rnGrey.grey50,
    border: 'none',
    boxShadow: 'none !important',

  },
  '&.MuiAccordion-root:before': {
    display: 'none',
  },
  '&.MuiAccordion-root:after': {
    display: 'block',
    height: '1px',
    backgroundColor: theme.palette.rnGrey.grey600,
    width: '85%',
  },
});
const StyledAccordionSummary = styled(AccordionSummary)({
  '&.MuiAccordionSummary-root': {
    backgroundColor: theme.palette.rnGrey.grey50,
    marginTop: '15px',
    marginBottom: ' 15px',
    border: 'none',
  },
  '&.Mui-expanded': {
    margin: '10px 0',
  },
});

export const AccordionComponent = ({
  className,
  id,
  title,
  whiteTitle,
  disabled,
  collapsed = true,
  toggleCollapsed,
  children,
}: AccodianType) => {
  return (
    <div>
      <StyledAccordion
        disableGutters
        className={`accordion ${className} rnAccordion`}
        id={id}
        data-testid={id}
        expanded={!collapsed}
      >
        <StyledAccordionSummary
          id="headingAssemProp"
          disabled={disabled ? disabled : false}
          className={`card-header my-0 d-flex  ${collapsed ? 'collapsed' : ''} ${whiteTitle ? 'bg-white' : ''} ${disabled ? 'disabled' : ''
            }`}
          data-toggle="collapse"
          data-target="#collapseAssemProp"
          aria-expanded={!collapsed}
          aria-controls="collapseAssemProp"
          onClick={toggleCollapsed}
        >
          <span className={'d-flex align-items-center col-12 px-0 justify-content-left'}>
            <i className={`fa fa-2x ${collapsed ? 'fa-angle-down' : 'fa-angle-up'}`} style={{ color: theme.palette.rnSteel.steel700 }}></i>
            <Typography fontFamily={'Inter'} fontSize={20} fontWeight={500} sx={{ ml: 1, color: theme.palette.rnSteel.steel700 }}>
              {title}
            </Typography>
          </span>
        </StyledAccordionSummary>
        <AccordionDetails
          id="collapseAssemProp"
          className={`p-0 collapse ${collapsed ? '' : 'show'} ${whiteTitle ? 'bg-white' : ''}`}
          aria-labelledby="headingAssemProp"
          data-parent="#accordionAssemProp"
        >
          <div className="card-body" style={{ marginTop: '1px' }}>
            {children}
          </div>
        </AccordionDetails>
      </StyledAccordion>
      <div className={'accordion-bar'}></div>
    </div>
  );
};
