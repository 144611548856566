import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAssemblyCountByFilters, getNavAssemblyIdByFiltersResults } from '../../services/AssemblyService';
import { ApplicationContext } from '../ApplicationProvider';
import { SearchBox } from '../shared/SearchBox';
import { ErrorModalComponent } from '../shared/ErrorModalComponent/ErrorModalComponent';
import { Dialog } from '../shared/DialogComponent/Dialog';

export const AssemblyNumberSearch: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { setDialog, setShowDialog, setLoading, setSearchAssemblyNum, setItemOffset, setItemsPerPage, setFilters } =
    useContext(ApplicationContext);
  const [value, setValue] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const { triggerSearchResultCount, setTriggerSearchResultCount } = useContext(ApplicationContext);
  const { triggerRoofTypeList, setTriggerRoofTypeList } = useContext(ApplicationContext);
  const { triggerAssemblyList, setTriggerAssemblyList } = useContext(ApplicationContext);
  const { numShowResult, setNumShowResult } = useContext(ApplicationContext);
  const { autoRefreshSearchResult, setAutoRefreshSearchResult } = useContext(ApplicationContext);

  const search = async (value: string) => {
    if (value === '') {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent message='Please enter an assembly number for search.' />
        </Dialog>
      );
      setShowDialog(true);
    }
    else {
      setLoading(true);
      const filters = {
        StatusId: { _eq: 1 },
        NavAssemblyName: {
          _like: `${value}%`,
        },
      };
      const count = await getAssemblyCountByFilters(filters);
      setLoading(false);
      if (count && count > 0) {
        //const data = await getAssembliesByAssemblyNumber(value);
        setSearchAssemblyNum(value);
        setItemOffset(0);
        setItemsPerPage(25);
        setErrMsg('');
        setValue('');
        if (count > 1) {
          setFilters(filters);
          if (!autoRefreshSearchResult) {
            setTriggerSearchResultCount(true);
            setTriggerRoofTypeList(true);
            setTriggerAssemblyList(true);
            setNumShowResult(numShowResult + 1);
          }
          navigate(`assembliesListing?${params.toString()}`);
        } else {
          const data = await getNavAssemblyIdByFiltersResults(filters);
          navigate(`/assemblyDetail/${data[0].NavAssemblyId}?${params.toString()}`);
        }
      } else {
        setDialog(
          <Dialog label="An Error Occurred">
            <ErrorModalComponent message='Assembly does not exist.' />
          </Dialog>
        );
        setShowDialog(true);
        // setErrMsg('No Results for Assembly Number');
      }
    }
  };

  return (
    <SearchBox
      label="Enter Assembly #"
      value={value}
      setValue={setValue}
      search={search}
      restrictKeyPress={/^[0-9-\b]+$/}
      errMsg={errMsg}
    />
  );
};

export const AssemblyNumber = () => {
  return (
    <AssemblyNumberSearch />
  );
};
