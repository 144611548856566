import { createTheme } from '@mui/material/styles';

// A custom theme for RoofNavNext
const theme = {
  palette: {
    primary: {
      main: '#722750',
    },
    rnBlue: {
      blue100: '#DBEAFE',
      blue500: '#3B82F6',
      blue800: '#1E40AF',
    },
    rnCobalt: {
      cobalt50: '#DDEEFD',
      cobalt100: '#8CC5F9',
      cobalt200: '#198AF2',
      cobalt300: '#0057CA',
      cobalt400: '#003D8D'
    },
    rnConcrete: {
      concrete50: '#F6F6F6',
      concrete100: '#E5E5E5',
      concrete200: '#CCCCCC',
      concrete300: '#B2B2B2',
      concrete400: '#999999',
      concrete500: '#808080',
      concrete600: '#666666',
      concrete700: '#4D4D4D',
      concrete800: '#2D2D2D',
      concrete900: '#141414'
    },
    rnGreen: {
      green100: '#D1FAE5',
      green800: '#065F46',
    },
    rnGrey: {
      grey900: '#111827',
      grey800: '#2e3338',
      grey700: '#40474E',
      grey600: '#5B6670',
      grey500: '#7C858D',
      grey400: '#9DA3A9',
      grey300: '#BDC2C6',
      grey200: '#DEE0E2',
      grey100: '#EFF0F1',
      grey50: '#F8F7F4 ',
    },
    rnMisc: {
      dialogBg: '#EDEBEA',
      red: '#DC2626',
      white: '#ffffff',
    },
    rnOrange: {
      darkOrange: '#C93102',
      darkOrangeLight: '#C9310238',
      primaryOrange: '#FF5100'
    },
    rnSteel: {
      steel50: '#F2F2F5',
      steel100: '#E0E0E5',
      steel200: '#C1C1C8',
      steel300: '#A1A3B1',
      steel400: '#828497',
      steel500: '#63657D',
      steel600: '#383B554',
      steel700: '#1C1F3C',
      steel800: '#0D102B',
      steel900: '#080A1A'
    },
    rnStone: {
      stone100: '#F8F7F4',
      stone200: '#F2F0E9',
      stone300: '#E9E6DB',
      stone400: '#D1CDC1',
      stone500: '#B5B1A4',
      stone600: '#97958C',
      stone700: '#817F76',
      stone800: '#727069',
      stone900: '#676560'
    },
    rnText: {
      primaryHeading: '#080A1A',
      primaryText: '#111827',
      secondaryText: '#444444',
      whiteText: '#F8F7F4 ',
    },
    rnYellow: {
      yellow100: '#FEF3C7',
      yellow800: '#92400E',
    },
    rnRed: {
      red100: '#FAA0A0',
      red800: '#913831',
    },
  },
  borders: {
    cardRadius: '6px',
    // border radius for first element in group
    firstBorderRadius: '4px 0px 0px 4px',
    // border radius for last element in group
    lastBorderRadius: '0px 4px 4px 0px',
    tabBorderRadius: '6px 6px 0px 0px',
  },
} as const;

type CustomTheme = {
  [Key in keyof typeof theme]: (typeof theme)[Key];
};

// module augmentation for type creation
declare module '@mui/material/styles' {
  interface Theme extends CustomTheme {
    // this overwrites palette
    palette: {
      primary: {
        main: string;
      };
      rnBlue: {
        blue100: string;
        blue500: string;
        blue800: string;
      };
      rnConcrete: {
        concrete50: string;
        concrete100: string;
        concrete200: string;
        concrete300: string;
        concrete400: string;
        concrete500: string;
        concrete600: string;
        concrete700: string;
        concrete800: string;
        concrete900: string;
      };
      rnGreen: {
        green100: string;
        green800: string;
      };
      rnGrey: {
        grey900: string;
        grey800: string;
        grey700: string;
        grey600: string;
        grey500: string;
        grey400: string;
        grey300: string;
        grey200: string;
        grey100: string;
        grey50: string;
      };
      rnMisc: {
        approvalsTeal: string;
        actionBlue: string;
        approvalsGreen: string;
        dialogBg: string;
        red: string;
        white: string;
      };
      rnOrange: {
        darkOrange: string;
        darkOrangeLight: string;
        primaryOrange: string;
      };
      rnSteel: {
        steel50: string;
        steel100: string;
        steel200: string;
        steel300: string;
        steel400: string;
        steel500: string;
        steel600: string;
        steel700: string;
        steel800: string;
        steel900: string;
      };
      rnStone: {
        stone100: string;
        stone200: string;
        stone300: string;
        stone400: string;
        stone500: string;
        stone600: string;
        stone700: string;
        stone800: string;
        stone900: string;
      };
      rnText: {
        primaryHeading: string;
        primaryText: string;
        secondaryText: string;
        whiteText: string;
      };
      rnYellow: {
        yellow100: string;
        yellow800: string;
      };
      rnRed: {
        red100: string;
        red800: string;
      };
    };
    borders: {
      cardRadius: string;
      lastBorderRadius: string;
      firstBorderRadius: string;
      tabBorderRadius: string;
    };
  }
  // left in case changes are needed, commented as eslint doesnt like empty interfaces
  // interface ThemeOptions extends CustomTheme {

  // }
}

export default createTheme(theme);
