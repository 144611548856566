import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApplicationContext } from '../ApplicationProvider';
import { ConfigitContext } from '../ConfigitProvider';
import { AccordionComponent } from '../shared/AccordionComponent/AccordionComponent';
import { ConfigitForm } from './ConfigitForm';
import { Loading } from '../shared/Loading/Loading';

export const AssemblyProps = () => {
    const { variableAssignments, setVariableAssignments, sections } = useContext(ConfigitContext);
    const { showFilters } = useContext(ApplicationContext);
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    let collapsed = params.get('openAssemblyProps') == null;

    useEffect(() => {
        if (showFilters) {
            collapsed = true;
            toggleCollapsed()
        }
    }, [showFilters]);
    const toggleCollapsed = () => {
        collapsed ? params.set('openAssemblyProps', 'true') : params.delete('openAssemblyProps');
        navigate('?' + params.toString());
    };
    return (
        <AccordionComponent className="assembly-props" id="accordionAssemProp" title="Assembly Properties"
            collapsed={collapsed} toggleCollapsed={toggleCollapsed}>
            {sections.length === 0 && <div className='d-flex justify-content-center'><Loading /></div>}
            {sections.length > 0 &&
                <div><ConfigitForm
                    variableAssignments={variableAssignments}
                    setVariableAssignments={setVariableAssignments}
                    section={sections[0]}
                />
                </div>

            }
        </AccordionComponent>
    );
};