/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMsal } from '@azure/msal-react';
import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { ApplicationProvider } from '../../components/ApplicationProvider';
import { ConfigitProvider } from '../../components/ConfigitProvider';
import { DialogComp } from '../../components/DialogComp';
import { LoadingComp } from '../../components/LoadingComp';
import Footer from '../../Footer';
import Header from '../../Header';
import { store } from '../../store';
import { Provider } from 'react-redux';
import { ConfigitProductProvider } from '../../components/ConfigitProductProvider';
import { FMGTelemetry } from 'fmg-telemetry-react';
import FMGTelemetrySetup from '../../utils/FMGTelemetry/FMGTelemetryUtil';

export const Default = (props: PropsWithChildren<unknown>) => {
  return (
    <div>
      <FMGTelemetry telemetryOptions={FMGTelemetrySetup}>
        <Provider store={store}>
          <ApplicationProvider>
            <LoadingComp />
            <ConfigitProvider>
              <ConfigitProductProvider>
                <Header />
                <DialogComp />
                <Outlet />
                <Footer />
              </ConfigitProductProvider>
            </ConfigitProvider>
          </ApplicationProvider>
        </Provider>
      </FMGTelemetry>
    </div>
  );
};
