import React, { useContext, useEffect, useState } from 'react';
import { Order_By } from '../../models/GQLGeneratedModels';
import { useGetRoofTypesCountByAPIQuery } from '../../services/RoofTypeService';
import { ApplicationContext } from '../ApplicationProvider';
import { ConfigitContext } from '../ConfigitProvider';
import { LAYERTYPE_MAP } from '../constants/LAYERTYPE_MAP';
import { RoofTypeAssemblyView } from './RoofTypeAssemblyView';
import { Drawer } from '@mui/material';
import { Loading } from '../shared/Loading/Loading';
import { Dialog } from '../shared/DialogComponent/Dialog';
import { ErrorModalComponent } from '../shared/ErrorModalComponent/ErrorModalComponent';

interface IRoofTypeViewProps {
  roofTypeCount: number;
  setRoofTypeCount: (roofTypeCount: number) => void;
}

export const RoofTypeView = ({ roofTypeCount, setRoofTypeCount }: IRoofTypeViewProps) => {
  const { filters } = useContext(ApplicationContext);
  const { layers, manufacturerProduct, excludedLayerIds } = useContext(ConfigitContext);
  const { setDialog, setShowDialog } = useContext(ApplicationContext);
  const [showIsOpenDrawer, setIsOpenDrawer] = useState(false);
  const { triggerAssemblyList, setTriggerAssemblyList } = useContext(ApplicationContext);
  const { numShowResult, setNumShowResult } = useContext(ApplicationContext);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    //console.log(event);
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsOpenDrawer(open);
    setShowDialog(open);
  };
  useEffect(() => {
    setIsOpenDrawer(true);
  }, [setIsOpenDrawer]);
  const { data, loading, error } = useGetRoofTypesCountByAPIQuery(
    layers,
    excludedLayerIds,
    manufacturerProduct,
    100,
    0,
    {
      NumOfAssembly: Order_By.Desc,
    },
    filters
  );
  useEffect(() => {
    if (error) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [error]);
  const count = data?.navassembly_api_rooftypesearch?.length;
  useEffect(() => {
    if (count && count !== roofTypeCount) setRoofTypeCount(count);
  }, [setRoofTypeCount]);

  const displayItems = data?.navassembly_api_rooftypesearch?.slice(
    0,
    Math.min(50, data?.navassembly_api_rooftypesearch.length)
  );
  return (
    <div id="roofnav_results" className={`card-body px-0 py-3 ${loading ? 'loading-spinner' : ''}`}>
      {loading ?
        <div className="d-flex justify-content-center align-items-center h-100">
          <Loading />
        </div>
        :
        displayItems &&
        displayItems.map((roofType: any, index: any) => {
          const layers = roofType?.ConfigString?.split(',');
          return (
            <div
              key={roofType?.LayerConfigId}
              className="mdc-card mdc-card--outlined border-0 my-2 mdc-ripple-upgraded"
              style={
                {
                  '--mdc-ripple-fg-size': '601px',
                  '--mdc-ripple-fg-scale': 1.68585,
                  '--mdc-ripple-fg-translate-start': '-136.761px, -282.614px',
                  '--mdc-ripple-fg-translate-end': '200.347px, -273.045px',
                } as React.CSSProperties
              }
            >
              <a
                onClick={() => {
                  setDialog(
                    <div>
                      <React.Fragment key={roofType?.LayerConfigId}>
                        <Drawer
                          anchor={'right'}
                          BackdropProps={{ invisible: true }}
                          disableScrollLock={true}
                          open={showIsOpenDrawer}
                          PaperProps={{
                            sx: { width: '75%' },
                          }}
                          onClose={toggleDrawer(false)}
                          ModalProps={{ style: { position: 'absolute', overflow: 'hidden' } }}
                        >
                          <div>
                            <RoofTypeAssemblyView
                              layerConfigId={roofType?.LayerConfigId}
                              count={roofType?.NumOfAssembly}
                              label={`Roof Type Summary (${roofType?.NumOfAssembly} assemblies)`}
                            />
                          </div>
                        </Drawer>
                      </React.Fragment>
                    </div>
                  );
                  setShowDialog(true);
                  setIsOpenDrawer(true);
                  setTriggerAssemblyList(true);
                  setNumShowResult(numShowResult + 1);
                }}
              >
                <div className="mdc-card__primary-action" tabIndex={0}>
                  <div className="d-flex">
                    <div className="d-inline-block badge">{roofType?.NumOfAssembly}</div>
                    <div className="col rounded-0 p-1">
                      {layers?.map((layer: any, i: any) => (
                        <React.Fragment key={i}>
                          <span className="badge-pill bg-light">{LAYERTYPE_MAP[layer]}</span>
                          {layers?.length !== i + 1 && '   >'}
                        </React.Fragment>
                      ))}
                    </div>{' '}
                  </div>
                </div>
              </a>
            </div>
          );
        })}
    </div>
  );
};
