import React from 'react';
import { useGetLightweightInsulatingConcreteUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { Density } from '../../utils/Measurement/Density';
import { Length } from '../../utils/Measurement/Length';
import { Pressure } from '../../utils/Measurement/Pressure';
import './UsageDetails.scss';

export const LightweightInsulatingConcreteUsageDetail = (props: any) => {
  const { data, loading, error } = useGetLightweightInsulatingConcreteUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];
  const isMetric = usage?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Lightweight Insulating Concrete</h4>
      <table>
        <tr>
          <td>Aggregate:</td>
          <td>{usage?.Component?.ManufacturedProduct?.LightweightInsulatingConcrete?.C_Aggregate.AggregateCode}</td>
        </tr>
        <tr>
          <td>Min Thickness:</td>
          <td>{Length.display(usage?.LightweightInsulatingConcreteUsage?.ThicknessMin, isMetric)}</td>
        </tr>
        <tr>
          <td>Cement Aggregate Ratio:</td>
          <td>
            {
              usage?.Component?.ManufacturedProduct?.LightweightInsulatingConcrete?.C_CementAggregateRatio
                .CementAggregateRatioCode
            }
          </td>
        </tr>
        <tr>
          <td>Min Compressive Strength:</td>
          <td>{Pressure.display(usage?.LightweightInsulatingConcreteUsage?.MinCompressiveStrength, isMetric)}</td>
        </tr>
        <tr>
          <td>Min Dry Density:</td>
          <td>{Density.display(usage?.LightweightInsulatingConcreteUsage?.DryDensityMin, isMetric)}</td>
        </tr>
        <tr>
          <td>Min Cast Density:</td>
          <td>{Density.display(usage?.LightweightInsulatingConcreteUsage?.CastDensityMin, isMetric)}</td>
        </tr>
        <tr>
          <td valign="top">Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
