import { useState } from 'react';
import { apolloClient } from '../ApolloClient';
import {
  GetCountryListDocument,
  GetCountryListQuery,
  GetCountryListQueryVariables,
  GetProjectsWithChildRoofAreaScenarioByEmailDocument,
  GetProjectsWithChildRoofAreaScenarioByEmailQuery,
  GetProjectsWithChildRoofAreaScenarioByEmailQueryVariables,
  GetRoofProjectBaseInfoByIdDocument,
  GetRoofProjectBaseInfoByIdQuery,
  GetRoofProjectBaseInfoByIdQueryVariables,
  GetRoofProjectCompleteInfoByIdDocument,
  GetRoofProjectsByEmailDocument,
  GetRoofProjectsByEmailQuery,
  GetRoofProjectsByEmailQueryVariables,
  GetStateProvinceListByCountryDocument,
  GetStateProvinceListByCountryQuery,
  GetStateProvinceListByCountryQueryVariables,
  RoofProjectBaseInfoSave,
  SaveUserProjectDocument,
  SaveUserProjectQuery,
  SaveUserProjectQueryVariables,
} from '../models/GQLGeneratedModels';
import { useLogger } from 'fmg-telemetry-react';

export const getCountries = async () => {
  const result = await apolloClient.query<GetCountryListQuery, GetCountryListQueryVariables>({
    query: GetCountryListDocument,
  });
  return result.data.C_Country;
};

export const getStatesProvincesByCountry = async (countryId: number) => {
  const result = await apolloClient.query<
    GetStateProvinceListByCountryQuery,
    GetStateProvinceListByCountryQueryVariables
  >({
    query: GetStateProvinceListByCountryDocument,
    variables: {
      CountryId: countryId,
    },
  });
  return result.data.R_Country_StateProvince;
};
// need to pass data as project, operation type
export const saveUserProject = async (projectToSave: RoofProjectBaseInfoSave) => {
  const logger = useLogger();

  const result = await apolloClient
    .query<SaveUserProjectQuery, SaveUserProjectQueryVariables>({
      query: SaveUserProjectDocument,
      variables: {
        saveProjectBaseInfo: projectToSave,
      },
    })
    .then((result) => {
      let resultProjectId = 0;
      if (result.data?.roofproject_api_savebaseinfo)
        resultProjectId = result.data?.roofproject_api_savebaseinfo[0]?.ProjectId as number;
      return resultProjectId;
    })
    .catch((error) => {
      logger.error('Error in saveUserProject', { searchCriteriaUsed: projectToSave, error: error, msg: error.message })

    });
  return result;
};
export const getProjectsByEmail = async (userEmail: string, randomId: string) => {
  const result = await apolloClient.query<GetRoofProjectsByEmailQuery, GetRoofProjectsByEmailQueryVariables>({
    query: GetRoofProjectsByEmailDocument,
    variables: {
      UserEmail: userEmail,
      RandomId: randomId,
    },
  });
  return result.data.RoofProject;
};
export const getProjectByProjectId = async (projectId: number, randomId: string) => {
  const result = await apolloClient.query<GetRoofProjectBaseInfoByIdQuery, GetRoofProjectBaseInfoByIdQueryVariables>({
    query: GetRoofProjectBaseInfoByIdDocument,
    variables: {
      ProjectId: projectId,
      RandomId: randomId
    },
  });
  return result.data.RoofProject;
};
export const getProjectsWithChildRoofAreaScenarioByEmail = async (userEmail: string, randomId: string) => {
  const result = await apolloClient.query<
    GetProjectsWithChildRoofAreaScenarioByEmailQuery,
    GetProjectsWithChildRoofAreaScenarioByEmailQueryVariables
  >({
    query: GetProjectsWithChildRoofAreaScenarioByEmailDocument,
    variables: {
      UserEmail: userEmail,
      RandomId: randomId,
    },
  });
  return result.data.RoofProject;
};
