export const COMPONENT_MAP = {
  AssemblyView: {
    colHeads: [
      { name: 'Id', width: 0, className: 'notvisible' },
      { name: 'Assembly #', width: 120, className: 'th-inner sortable both sorted asc' },
      { name: 'Cover', width: 140, className: 'th-inner sortable both unsorted' },
      { name: 'Application', width: 120, className: 'th-inner sortable both unsorted' },
      { name: 'Securement', width: 120, className: 'th-inner sortable both unsorted' },
      { name: 'Deck', width: 110, className: 'th-inner sortable both unsorted' },
      { name: 'Wind Uplift', width: 75, className: 'th-inner sortable both unsorted' },
      { name: 'I/Fire', width: 75, className: 'th-inner sortable both unsorted' },
      { name: 'E/Fire', width: 75, className: 'th-inner sortable both unsorted' },
      { name: 'Slope', width: 75, className: 'th-inner sortable both unsorted' },
      { name: 'Hail', width: 65, className: 'th-inner sortable both unsorted' },
    ],
  },
  RoofTypeAssemblyView: {
    colHeads: [
      { name: 'Id', width: 0, className: 'notvisible' },
      { name: 'Assembly #', width: 120, className: 'th-inner sortable both sorted asc' },
      { name: 'Cover', width: 140, className: 'th-inner sortable both unsorted' },
      { name: 'Application', width: 120, className: 'th-inner sortable both unsorted' },
      { name: 'Securement', width: 120, className: 'th-inner sortable both unsorted' },
      { name: 'Deck', width: 110, className: 'th-inner sortable both unsorted' },
      { name: 'Wind Uplift', width: 90, className: 'th-inner sortable both unsorted' },
      { name: 'I/Fire', width: 70, className: 'th-inner sortable both unsorted' },
      { name: 'E/Fire', width: 70, className: 'th-inner sortable both unsorted' },
      { name: 'Slope', width: 70, className: 'th-inner sortable both unsorted' },
      { name: 'Hail', width: 70, className: 'th-inner sortable both unsorted' },
     
    ],
  },
  ProductList: {
    colHeads: [
      { name: 'Id', width: 0, className: 'notvisible' },
      {
        name: 'Product Trade Name',
        width: 120,
        className: 'th-inner sortable both sorted asc',
      },
      {
        name: 'Company Name',
        width: 140,
        className: 'th-inner sortable both unsorted',
      },
      {
        name: 'Approved Use',
        width: 120,
        className: 'th-inner sortable both unsorted',
      },
      {
        name: 'Country',
        width: 120,
        className: 'th-inner sortable both unsorted',
      },
      
    ],
  },
};
