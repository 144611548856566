import React from 'react';
import { useGetBasePlyUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { Length } from '../../utils/Measurement/Length';
import './UsageDetails.scss'

export const BasePlyUsageDetail = (props: any) => {
  const { data, loading, error } = useGetBasePlyUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];

  return (
    <div className={'usage-detail p-3'}>
      <h4>Cover, Multi-ply (Base Ply)</h4>
      <table>
        <tr>
          <td>Backing:</td>
          <td>{usage?.Component?.ManufacturedProduct?.BasePly?.C_BackingMaterial.BackingMaterialCode}</td>
        </tr>
        <tr>
          <td>Width:</td>
          <td>{Length.display(usage?.Component?.ManufacturedProduct?.BasePly?.Width, usage?.EnteredAsMetric)}</td>
        </tr>
        <tr>
          <td>Material:</td>
          <td>{usage?.Component?.ManufacturedProduct?.BasePly?.C_MultiplyMaterial.MultiplyMaterialCode}</td>
        </tr>
        <tr>
          <td>Reinforcement:</td>
          <td>{usage?.Component?.ManufacturedProduct?.BasePly?.C_Reinforcement.ReinforcementCode}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
