import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApplicationContext } from '../ApplicationProvider';
import {
  ConfigitContext,
  ILayer,
  IManufacturerProduct,
  ISelfSecurement,
  IVariableAssigmentLabels,
} from '../ConfigitProvider';
import { getSelfSecurementPropsBybaseLayerTypeId } from '../constants/SELFSECUREMENT_DATA';
import {
  configureLayer as configureLayerAPI,
  useConfigureLayer,
  useGetInitialLayerState,
  useGetInitialSelfSecurementState,
} from '../../services/ConfigitService';
import { AllowedValues, ConfigurationVariable, SingletonValue } from '../../types/configurator';
import { DetermineSelfSecurementLayerType, SelfSecurementLayerTypeIds } from '../../utils/SecurementUtilities';
import { EXCHANGE_VARIABLES } from '../constants/EXCHANGE_VARIABLES';
import { ManufacturerProductForm } from './ManufacturerProductForm';
import { ISecurementDetails, SecurementForm } from './SecurementForm';
import { ProductAttributesForm } from './ProductAttributesForm';
import { ButtonComponent } from '../shared/ButtonComponent/ButtonComponent';
import { TextOnlyComponent } from '../shared/TextOnlyComponent/TextOnlyComponent';
import { getLayerDataById } from '../../utils/LayerTypeCheckUtilities';
import { getLayerProps } from '../constants/LAYER_DATA';
import { Dialog } from '../shared/DialogComponent/Dialog';
import { ErrorModalComponent } from '../shared/ErrorModalComponent/ErrorModalComponent';

interface ISecurementConfiguratorProps {
  baseLayerTypeId: string;
  baseLayerIndex: number;
}

export const SelfSecurementConfigurator = ({ baseLayerTypeId, baseLayerIndex }: ISecurementConfiguratorProps) => {
  const [mode, setMode] = useState('Basic');
  const { setShowDialog, setDialog } = useContext(ApplicationContext);
  const { sections, variableAssignments, layerConfigMasterData, layers, excludedLayerIds, configureLayer } =
    useContext(ConfigitContext);
  const baseLayer = layers[baseLayerIndex] as ILayer;
  const baseLayerData = getLayerDataById(baseLayerTypeId);
  const baseLayerProps = getLayerProps(baseLayerData);
  const modelBaseLayer = baseLayerProps.model ? (baseLayerProps.model as string) : '--NA--';

  const selfSecurement = layers[baseLayerIndex].selfSecurement as ISelfSecurement;
  const selfSecurementProps = getSelfSecurementPropsBybaseLayerTypeId(baseLayerTypeId);
  const modelSelfSecurement = selfSecurementProps.model ? (selfSecurementProps.model as string) : '--NA--';
  //TODO may need new methods useGetInitialSelfSecurementState and getInitialSelfSecurementState in ConfigitService
  const initialState = useGetInitialSelfSecurementState(
    variableAssignments,
    layerConfigMasterData ?? [],
    excludedLayerIds,
    layers,
    baseLayerIndex,
    modelSelfSecurement,
    modelBaseLayer
  );
  const [selfSecurementDetails, setSelfSecurementDetails] = useState(initialState.data);
  const settingSelfSecurementDetailsRef = useRef(false);
  const [productDetails, setProductDetails] = useState<IManufacturerProduct[]>(
    selfSecurement?.productDetails
      ? selfSecurement?.productDetails
      : [
        {
          productId: '',
          manufacturerId: '',
        },
      ]
  );
  // const [resetProductDetails, setResetProductDetails] = useState<IManufacturerProduct[]>(
  //   selfSecurement?.productDetails
  //     ? selfSecurement?.productDetails
  //     : [
  //         {
  //           productId: '',
  //           manufacturerId: '',
  //         },
  //       ]
  // );
  const [securementDetails, setSecurementDetails] = useState<ISecurementDetails>({
    fromLayerTypeId: Number(baseLayerTypeId),
    fromLayerNum: baseLayerIndex + 1,
    toLayerTypeId: Number(baseLayerTypeId),
    toLayerNum: baseLayerIndex + 1,
  });
  const [variableAssignmentLabels, setVariableAssignmentLabels] = useState<IVariableAssigmentLabels[]>(
    selfSecurement.variableAssignmentLabels ?? []
  );
  if (initialState.data && !selfSecurementDetails) setSelfSecurementDetails(initialState.data);

  //TODO may need new methods useConfigureSelfSecurement and selfSecurementLayer in ConfigitService
  // It looks like we can still use useConfigureLayer and configureLayer. Only model matters.
  const { data, loading, error } = useConfigureLayer(selfSecurementDetails, modelSelfSecurement);
  useEffect(() => {
    if (error) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [error]);
  useEffect(() => {
    if (settingSelfSecurementDetailsRef.current) settingSelfSecurementDetailsRef.current = false;
  }, [JSON.stringify(selfSecurementDetails)]);

  const handleFormSelect = (variableId: string, value: string | string[]) => {
    const temp = selfSecurementDetails?.filter((data) => data.variableId !== variableId);
    const temp2 = variableAssignmentLabels.filter((data) => data.variableId !== variableId);
    const variable = data?.variables?.find((data) => data.id === variableId);
    const type = variable?.properties && variable?.properties[0] && variable.properties[0]?.value;
    const valueObj = variable?.values?.find((data: SingletonValue) => data.value === value) as SingletonValue;
    if (typeof value === 'string') {
      temp?.push({ variableId, value });
      if (variable?.name && valueObj.name) {
        temp2.push({ variableId, variableLabel: variable.name, valueLabel: valueObj.name, type });
        setVariableAssignmentLabels(temp2);
      }
    } else {
      temp?.push({
        variableId,
        type: 'AllowedValues',
        allowedValues: value.map((val) => {
          return { type: 'SingletonValue', value: String(val) };
        }),
      });
    }
    setSelfSecurementDetails(temp);
  };

  const handleFormUnSelect = (variableId: string) => {
    setSelfSecurementDetails(selfSecurementDetails?.filter((data) => data.variableId !== variableId));
    setVariableAssignmentLabels(variableAssignmentLabels.filter((data) => data.variableId !== variableId));
  };

  const handleMeasureAttrOperatorChange = (event: React.ChangeEvent<HTMLSelectElement>) => { };

  const handleUpdateSearchFilter = async () => {
    if (selfSecurement) {
      const tempSS = { ...selfSecurement };
      tempSS.idLayerType = Number(DetermineSelfSecurementLayerType(Number(baseLayerTypeId))).toString();
      tempSS.autoSelected = false;
      tempSS.baseLayerTypeId = baseLayerTypeId;
      tempSS.model = modelSelfSecurement;
      tempSS.variableAssignments = selfSecurementDetails;
      tempSS.section = data;
      tempSS.productDetails = productDetails;
      tempSS.variableAssignmentLabels = variableAssignmentLabels;

      const tempTargetLayer = { ...layers[baseLayerIndex] };
      tempTargetLayer.selfSecurement = tempSS;
      configureLayer(tempTargetLayer, baseLayerIndex);
      setShowDialog(false);
    }
  };

  const handleCancel = () => setShowDialog(false);
  const handleResetSelfSecurementDetails = () => {
    setProductDetails([
      {
        productId: '',
        manufacturerId: '',
      },
    ]);
    setSelfSecurementDetails(
      selfSecurementDetails?.filter((data) => EXCHANGE_VARIABLES.includes(String(data.variableId)))
    );
  };

  const handleAddProductDetail = () => {
    const emptyRow = productDetails.find((data) => data.manufacturerId === '' && data.productId === '');
    if (!emptyRow) {
      const data = [...productDetails];
      data.push({
        productId: '',
        manufacturerId: '',
      });
      setProductDetails(data);
    }
  };
  const handleUpdateProductDetail = async (index: number, productDetail: IManufacturerProduct) => {
    if (productDetail.manufacturerId === '' && productDetail.productId === '' && productDetails.length > 1) {
      handleRemoveProductDetail(index, productDetail.productId, productDetail.manufacturerId);
      return;
    }
    const data = productDetails.map((obj: IManufacturerProduct, pos: number) => {
      if (index === pos) return productDetail;
      return obj;
    });
    setProductDetails(data);
    setSelfSecurementDetails(await getUpdatedSelfSecurementDetails(data));
  };
  const handleRemoveProductDetail = async (index: number, deletedproductid: string, deletedmanufacturerid: string) => {
    const data = [...productDetails];
    data.splice(index, 1);
    setProductDetails(data);
    setSelfSecurementDetails(await getUpdatedSelfSecurementDetails(data));

  };

  const getUpdatedSelfSecurementDetails = async (productDetails: IManufacturerProduct[], newModel?: string) => {
    const data = selfSecurementDetails?.filter(
      (obj) => obj.variableId !== 'ManufacturerId' && obj.variableId !== 'ComponentId_view'
    );
    let products: AllowedValues[] = [];
    for (let i = 0; i < productDetails.length; i++) {
      const productDetail = productDetails[i];
      if (productDetail.productId !== '') products.push({ type: 'SingletonValue', value: productDetail.productId });
      else if (productDetail.manufacturerId !== '') {
        const section = await configureLayerAPI(
          [
            ...(selfSecurementDetails?.filter(
              (data) =>
                data.variableId !== 'ManufacturerId' &&
                data.variableId !== 'ComponentId_view' &&
                !(newModel && data.variableId == 'ComponentId')
            ) ?? []),
            {
              variableId: 'ManufacturerId',
              value: productDetail.manufacturerId,
            },
          ],
          newModel ?? modelSelfSecurement
        );
        const allowedProducts = section.variables
          ?.find((data) => data.id === 'ComponentId_view')
          ?.values?.filter((data) => !data.incompatible)
          ?.map((data: SingletonValue) => {
            return { type: 'SingletonValue', value: data.value } as AllowedValues;
          });
        if (allowedProducts) products = products.concat(allowedProducts);
      }
    }
    if (products.length) {
      data?.push({
        variableId: 'ComponentId_view',
        type: 'AllowedValues',
        allowedValues: products,
      });
    }
    return data;
  };

  const securementVariables = data?.variables
    ?.filter(
      (obj) =>
        obj.id === 'CApprovedUse_ApprovedUseId' ||
        obj.id === 'ManufacturedProduct_CategoryId' ||
        obj.id === 'ManufacturedProduct_SubcategoryId'
    )
    .sort(compare);

  function compare(a: ConfigurationVariable, b: ConfigurationVariable) {
    let sequenceId1: number;
    let sequenceId2: number;

    switch (a.id) {
      case 'CApprovedUse_ApprovedUseId':
        sequenceId1 = 3;
        break;
      case 'ManufacturedProduct_CategoryId':
        sequenceId1 = 1;
        break;
      case 'ManufacturedProduct_SubcategoryId':
        sequenceId1 = 2;
        break;
      default:
        sequenceId1 = 100;
    }

    switch (b.id) {
      case 'CApprovedUse_ApprovedUseId':
        sequenceId2 = 3;
        break;
      case 'ManufacturedProduct_CategoryId':
        sequenceId2 = 1;
        break;
      case 'ManufacturedProduct_SubcategoryId':
        sequenceId2 = 2;
        break;
      default:
        sequenceId2 = 100;
    }

    return sequenceId1 - sequenceId2;
  }

  const unAssigned = !!securementVariables?.find((variable) => !variable.values?.find((value) => !!value.assigned));

  return (
    <>
      <h4 className="mt-3 mb-2">Self-Securement Details</h4>
      {(initialState.loading || loading) && 'Loading...'}
      {selfSecurementDetails && initialState.data && !loading && data && data.variables && (
        <>
          <ManufacturerProductForm
            layerDetails={selfSecurementDetails}
            model={modelSelfSecurement}
            productDetails={productDetails}
            handleAddProductDetail={handleAddProductDetail}
            handleUpdateProductDetail={handleUpdateProductDetail}
            handleRemoveProductDetail={handleRemoveProductDetail}
          />
          {securementVariables && (
            <SecurementForm
              layerId={Number(DetermineSelfSecurementLayerType(Number(baseLayerTypeId))).toString()}
              layerIndex={baseLayerIndex}
              layers={layers}
              variables={data.variables}
              securementVariables={securementVariables}
              handleFormSelect={handleFormSelect}
              handleFormUnSelect={handleFormUnSelect}
              securementDetails={securementDetails}
              setSecurementDetails={setSecurementDetails}
              showFromTo={false}
            />
          )}
          {!unAssigned && (
            <ProductAttributesForm
              mode={mode}
              setMode={setMode}
              layerTypeId={Number(DetermineSelfSecurementLayerType(Number(baseLayerTypeId))).toString()}
              name={'Self-Securement'}
              layerProps={{ model: '--NA--', basicAttributes: [] }}
              variables={data.variables}
              handleFormSelect={handleFormSelect}
              handleFormUnSelect={handleFormUnSelect}
              handleMeasureAttrOperatorChange={handleMeasureAttrOperatorChange}
            />
          )}
        </>
      )}
      <div style={{ height: '97px' }}></div>
      <div className="form-group px-3 mb-3 row mx-0">
        <div>
          <ButtonComponent
            click={handleUpdateSearchFilter}
            data-mdc-dialog-action="close"
            buttonStyle="py-2 px-3"
            buttonType={'primary'}
            buttonText={'Ok'}
          />
        </div>
        <div className={'px-2'}>
          <ButtonComponent
            click={handleCancel}
            data-mdc-dialog-action="close"
            buttonStyle="py-2 px-3 ml-2"
            buttonType={'secondary'}
            buttonText={'Cancel'}
          />
        </div>
        <div className={'d-flex align-middle'}>
          <TextOnlyComponent
            click={handleResetSelfSecurementDetails}
            data-mdc-dialog-action="close"
            buttonStyle="ml-2"
            buttonText={'Reset Self-Securement Details'}
          />
        </div>
      </div>
    </>
  );
};
