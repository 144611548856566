import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableRow, Typography, styled, Button } from '@mui/material';
import theme from '../../theme';
import { StyledTableCell } from '../shared/TableComponent/TableComponent';
import { getProductDetailByComponentId, getProductDocumentByDocumentId, getProductMatchedAssemblyCount } from '../../services/ProductSearchService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ApprovedUsageDetail } from './ApprovedUsageDetail';
import { TextOnlyComponent } from '../shared/TextOnlyComponent/TextOnlyComponent';

// interface IProductDetailViewProps {
//   componentId: number;
//   handleBackClick: () => void;
// }
export const ProductDetail: React.FC = () => {
  const { componentid } = useParams();

  const [data, setData] = useState<any>();
  const [countdata, setCountdata] = useState<any>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let isSubscribed = false;
    const getProductDetailData = async () => {
      setLoading(true);
      const manufacturedata = await getProductDetailByComponentId(Number(componentid));
      const assemblyCountData = await getProductMatchedAssemblyCount(Number(componentid));
      if (!isSubscribed) {
        setData(manufacturedata);
        setCountdata(assemblyCountData);
        setLoading(false);
      }
    };
    getProductDetailData();
    return () => {
      isSubscribed = true;
    };
  }, [Number(componentid)]);

  let manufactureproductdata;
  let approvedUses;
  let componentdocs: string | any;
  let country: string;

  let componentcomments;
  if (data && countdata) {
    manufactureproductdata = data?.ManufacturedProduct[0];
    approvedUses = data?.ManufacturedProduct[0].Component.ComponentApprovedUses;
    componentdocs = data?.ManufacturedProduct[0].Component.ComponentDocs;
    componentcomments = data?.ManufacturedProduct[0].Component.Comments;

    if (componentdocs.length === 0) {
      componentdocs = 'none';
    } else {
      componentdocs = data?.ManufacturedProduct[0].Component.ComponentDocs[0].ComponentDocId;
    }
    if (componentcomments === '') {
      componentcomments = 'none';
    }
  }
  const handleBackClick = () => {
    history.back();
  };
  const ProductDetailTableRow = styled(TableRow)({
    '&.MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: theme.palette.rnGrey.grey50,
    },
    '&.MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: theme.palette.rnMisc.white,
    },
  });

  const handleDocument = (docid: string) => {
    loadDocument(parseInt(docid));
  };

  const loadDocument = async (documentId: number) => {
    const ProdDocument = await getProductDocumentByDocumentId(documentId);
    const bytes = atob(ProdDocument[0].DocData);
    let length = bytes.length;
    const out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
    const blob = new Blob([out], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  };

  const getNumOfAssemblyCount = () => {
    if (
      countdata === undefined ||
      countdata.product_api_matchedassemblycount === undefined ||
      countdata.product_api_matchedassemblycount.length === 0
    )
      return 0;

    //return Number(countdata.product_api_matchedassemblycount[0].NumMatchedAssembly);
    let countResult = 0;
    for (let i = 0; i < countdata.product_api_matchedassemblycount.length; i++) {
      if (countdata.product_api_matchedassemblycount[i].ApprovedUseId == 0) {
        countResult = countdata.product_api_matchedassemblycount[i].NumMatchedAssembly;
        break;
      }
    }
    return countResult;
  };

  return (
    <main className="main-content container-fluid flex-shrink-0">
      <div>
        <div className="row justify-content-center">
          <div className="col-11 pt-5">
            <section className="bg-white h-100 overflow-hidden">
              {!loading && (
                <>
                  <br></br>
                  <div className="row align-items-stretch">
                    <div className="col-9">
                      <div className="d-flex align-items-center h-100">
                        <Typography
                          className="mx-3"
                          fontFamily={'Inter'}
                          fontSize={'36px'}
                          fontWeight={700}
                          lineHeight={'44px'}
                          color={theme.palette.rnSteel.steel700}
                        >
                          Product Detail Report
                        </Typography>
                      </div>
                    </div>
                    <div className="col px-4 my-1 text-right">
                      <Link onClick={handleBackClick} to={'#'}>
                        <TextOnlyComponent buttonText={'Back'} iconName={'arrow_left'} />
                      </Link>
                    </div>
                  </div>
                  <br></br>
                  {manufactureproductdata && (
                    <>
                      <Table style={{ width: '50%' }}>
                        <TableBody>
                          <ProductDetailTableRow>
                            <StyledTableCell>
                              <Typography fontWeight={500} fontFamily={'Inter'}>Company:</Typography>
                            </StyledTableCell>
                            <StyledTableCell className={'modal-info-bold-text'}>
                              {manufactureproductdata?.Manufacturer.Name}
                            </StyledTableCell>
                          </ProductDetailTableRow>
                          <ProductDetailTableRow>
                            <StyledTableCell>
                              <Typography fontWeight={500} fontFamily={'Inter'}>Trade Name:</Typography>
                            </StyledTableCell>
                            <StyledTableCell className={'modal-info-bold-text'}>
                              {manufactureproductdata?.TradeName}
                            </StyledTableCell>
                          </ProductDetailTableRow>
                          <ProductDetailTableRow>
                            <StyledTableCell>
                              <Typography fontWeight={500} fontFamily={'Inter'}>
                                Country:
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell className={'modal-info-bold-text'}>
                              {manufactureproductdata?.Manufacturer?.Address.C_Country.CountryCode}
                            </StyledTableCell>
                          </ProductDetailTableRow>
                          <ProductDetailTableRow>
                            <StyledTableCell>
                              <Typography fontWeight={500} fontFamily={'Inter'}>
                                Document Link:
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell className={'modal-info-bold-text'}>
                              {componentdocs != 'none' ? (
                                <Button
                                  className={'modal-info-bold-text-link'}
                                  style={{ textTransform: 'none' }}
                                  onClick={() => handleDocument(componentdocs)}
                                >
                                  Please click here for more details.
                                </Button>
                              ) : (
                                '(none)'
                              )}
                            </StyledTableCell>
                          </ProductDetailTableRow>
                          <ProductDetailTableRow>
                            <StyledTableCell>
                              <Typography fontWeight={500} fontFamily={'Inter'}>
                                Assemblies:
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell className={'modal-info-bold-text'}>
                              {getNumOfAssemblyCount() > 0 ? (
                                <Button
                                  className={'modal-info-bold-text-link'}
                                  style={{ textTransform: 'none', marginLeft: '-8px' }}
                                  onClick={() => {
                                    navigate(`/assemblyListProductApprovedUse?componentid=${componentid}&count=${getNumOfAssemblyCount()}`);
                                  }}
                                >
                                  {getNumOfAssemblyCount()}
                                </Button>
                              ) : (
                                '0'
                              )}
                            </StyledTableCell>
                          </ProductDetailTableRow>
                          <ProductDetailTableRow>
                            <StyledTableCell>
                              <Typography fontWeight={500} fontFamily={'Inter'}>Comments:</Typography>
                            </StyledTableCell>
                            <StyledTableCell className={'modal-info-bold-text'}>{componentcomments}</StyledTableCell>
                          </ProductDetailTableRow>
                        </TableBody>
                      </Table>
                      <br></br>
                      <div className="row align-items-stretch">
                        <div className="col-9">
                          <div className="d-flex align-items-center h-100">
                            <Typography
                              className="mx-3"
                              fontFamily={'Inter'}
                              fontSize={'25px'}
                              fontWeight={600}
                              lineHeight={'44px'}
                              color={theme.palette.rnSteel.steel700}
                            >
                              Approved Uses
                            </Typography>
                          </div>
                        </div>
                      </div>
                      {approvedUses?.map((approveduse: any, i: React.Key | null | undefined) => (
                        <React.Fragment key={i}>
                          <ApprovedUsageDetail
                            approvedUseId={approveduse.C_ApprovedUse?.ApprovedUseId}
                            approvedUseCode={approveduse.C_ApprovedUse?.ApprovedUseCode}
                            approvedUseCommnets={approveduse.Comments}
                            manufacturedProduct={data?.ManufacturedProduct}
                            componentId={Number(componentid)}
                            countData={countdata.product_api_matchedassemblycount}
                          />
                          <br></br>
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </>
              )}
              {loading && <div>Loading....</div>}
            </section>
          </div>
        </div>
      </div>
    </main>
  );
};
