import { Box, Table, TableHead, TableCell, Typography, styled, TableRow } from '@mui/material';
import React, { useContext } from 'react';
import theme from '../../../theme';

export const SurfaceRoughnessComponent = () => {
  const StyledTableHead = styled(TableHead)({
    '&.MuiTableHead-root': {
      backgroundColor: theme.palette.rnSteel.steel500,
    },
    '&.MuiTableHead-root tr th:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.rnStone.stone200}`,
    },
  });
  const StyledTableRow = styled(TableRow)({
    '&.MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: theme.palette.rnMisc.white,
      borderBottom: 'none',
    },
    '&.MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: theme.palette.rnStone.stone100,
      borderBottom: 'none',
    },
    '&.MuiTableRow-root:focus': {
      backgroundColor: theme.palette.rnSteel.steel500 + ' !important',
      color: theme.palette.rnMisc.white,
    },
  });
  const StyledTableCell = styled(TableCell)({
    '&.MuiTableCell-root': {
      verticalAlign: 'top',
      borderBottom: 'none',
    },
  });

  return (
    <Box>
      <Typography className={'standard-para pb-3'}>
        The following is a brief summary of each category for a location surface roughness exposure. For more
        information, see DS 1-28.
      </Typography>
      <Typography className={'standard-para pb-3'}>
        Note: When more than one category applies, select the category with the greatest wind exposure. For example, if
        the location is between B and C categories, use C.
      </Typography>
      <Table>
        <StyledTableHead>
          <tr>
            <StyledTableCell className={'col-3 modal-info-heading'}>Category</StyledTableCell>
            <StyledTableCell className={'col-9 modal-info-heading'}>Description</StyledTableCell>
          </tr>
        </StyledTableHead>
        <StyledTableRow>
          <StyledTableCell className={'modal-info-bold-text'}>B</StyledTableCell>
          <StyledTableCell>
            <Typography className={'modal-info-text pb-2'}>
              Urban and suburban areas, wooded areas, or other terrain with numerous closely spaced obstructions having
              the size of single-family dwellings or larger.
            </Typography>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell className={'modal-info-bold-text'}>C</StyledTableCell>
          <StyledTableCell>
            <Typography className={'modal-info-text pb-2'}>
              Open terrain with scattered obstructions having heights generally less than 30 ft (9.1 m). Examples
              include flat, open country and grasslands, and water surfaces in hurricane-prone regions where the basic
              wind speed is greater than or equal to 120 mph (54 m/s).
            </Typography>
            <Typography className={'modal-info-text pb-2'}>
              Also use category C for B ground roughness exposures having one or more clearings greater than 600 ft (183
              m) by 164 ft (50 m), and in all cases where exposure B or D do not apply.
            </Typography>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell className={'modal-info-bold-text'}>D</StyledTableCell>
          <StyledTableCell>
            <Typography className={'modal-info-text pb-2'}>
              Flat, unobstructed areas and water surfaces including smooth mud flats, salt flats, and unbroken ice.
            </Typography>
          </StyledTableCell>
        </StyledTableRow>
      </Table>
    </Box>
  );
};
