import React from 'react';
import { SplashHeader } from '../../pages/Login/SplashHeader';
import QuickStartGuide from './QuickStartGuide';
import SplashFooter from '../../pages/Login/SplashFooter';

export const UnAuthedQuickStartGuide = () => {
    return (
        <div>
            <SplashHeader />
            <QuickStartGuide />
            <SplashFooter />
        </div>

    )
}