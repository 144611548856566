export class SolidApplicationRate {
  static POUNDSQUARE_TO_KILOSSQUAREMETER = 0.04882428;
  static IMPERIALUNIT = 'lb/square';
  static METRICUNIT = 'kg/m2';

  static convertImperialToMetric(valueImperial: number) {
    const valueMetric = valueImperial * SolidApplicationRate.POUNDSQUARE_TO_KILOSSQUAREMETER;
    return valueMetric.toFixed(4) + ' ' + SolidApplicationRate.METRICUNIT;
  }

  static convertMetricToImperial(valueMetric: number) {
    const valueImperial = valueMetric / SolidApplicationRate.POUNDSQUARE_TO_KILOSSQUAREMETER;
    return valueImperial.toFixed(4) + ' ' + SolidApplicationRate.IMPERIALUNIT;
  }

  static displayMetric(valueMetric: number) {
    return valueMetric.toFixed(4) + ' ' + SolidApplicationRate.METRICUNIT;
  }

  static display(valueMetric: number, showAsMetric: boolean) {
    if (showAsMetric) return SolidApplicationRate.displayMetric(valueMetric);
    else return SolidApplicationRate.convertMetricToImperial(valueMetric);
  }
}
