import { CardHeader, Card, styled, Typography, CardContent } from '@mui/material';
import React, { HTMLAttributes } from 'react';
import theme from '../../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';


interface IIconCardComponentProps extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  title: string;
  iconName: string;
  body: string;
  click?: any;
}

export const IconCard = ({ id, title, iconName, body, click }: IIconCardComponentProps) => {

  const StyledCardHeader = styled(CardHeader)({

    '&.MuiCardHeader-root': {
      backgroundColor: theme.palette.rnSteel.steel500,
      marginBottom: '3rem'
    },
  });
  const StyledCard = styled(Card)({
    '&.MuiCard-root': {
      borderRadius: theme.borders.cardRadius,
      backgroundColor: theme.palette.rnGrey.grey50,
      minHeight: '370px',
      cursor: click ? 'pointer' : 'default'
    }
  })


  return (
    <StyledCard id={id} onClick={click}>
      <StyledCardHeader
        disableTypography
        title={
          <Typography
            color={theme.palette.rnMisc.white}
            fontWeight={600}
            fontSize={'26px'}
            lineHeight={''}
            textAlign={'center'}
          >
            {title}
          </Typography>
        }
      />
      <div className={'d-flex justify-content-center py-2'}>
        {iconName === 'calculate' ?
          <FontAwesomeIcon
            icon={faCalculator}
            size={'4x'}
            style={{ paddingTop: '10px' }}
            color={theme.palette.rnOrange.darkOrange}
          />
          :
          <span className={'material-symbols-outlined fill-icon'}>{iconName}</span>
        }
      </div>
      <CardContent>
        <Typography
          textAlign={'center'}
          className={'large-para mt-3'}
        >
          {body}
        </Typography>
      </CardContent>
    </StyledCard>

  );
};
