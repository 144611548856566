import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavAssembly_Order_By, Order_By } from '../../models/GQLGeneratedModels';
import { useGetAssembliesExtraRoofTypeIdByAPIQuery } from '../../services/AssemblyService';
import { ApplicationContext } from '../ApplicationProvider';
import { ConfigitContext } from '../ConfigitProvider';
import { COMPONENT_MAP } from '../constants/COMPONENT_MAP';
import { TableComponent } from '../shared/TableComponent/TableComponent';
import theme from '../../theme';
import { styled, Typography } from '@mui/material';
import '../shared/DialogComponent/Dialog.scss';
import { AssemblyDetail } from './AssemblyDetail';
import { Link } from '@mui/material';
import { TextOnlyComponent } from '../shared/TextOnlyComponent/TextOnlyComponent';
import { ErrorModalComponent } from '../shared/ErrorModalComponent/ErrorModalComponent';
import { Dialog } from '../shared/DialogComponent/Dialog';

interface IAssemblyViewProps {
  layerConfigId: number;
  count: number;
  label: React.ReactNode;
  children?: React.ReactNode;
}
const HeaderTypography = styled(Typography)({
  '&.MuiTypography-root': {
    color: theme.palette.rnMisc.white,
  },
});

export const RoofTypeAssemblyView: React.FC<IAssemblyViewProps> = ({
  layerConfigId,
  count,
  label,
  children,
}: IAssemblyViewProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const { itemOffset, itemsPerPage, setItemOffset, setItemsPerPage, filters, setDialog } = useContext(ApplicationContext);
  const { layers, manufacturerProduct, excludedLayerIds } = useContext(ConfigitContext);
  const [showAssemblyDetail, setShowAssemblyDetail] = useState(false);
  const [navAssemblyId] = useState(0);

  const [columnHeaders, setColumnHeaders] = useState(COMPONENT_MAP.RoofTypeAssemblyView.colHeads);
  const [fieldSort, setFieldSort] = useState('Assembly #');
  const [sortDir, setSortDir] = useState('asc');
  const [orderbyInfo, setOrderbyInfo] = useState<NavAssembly_Order_By>({
    AssemblyId: Order_By.Asc,
    CoverSubassemblyId: Order_By.Asc,
    DeckSubassemblyId: Order_By.Asc,
  });
  const { setTriggerAssemblyList } = useContext(ApplicationContext);
  const { numShowResult, setNumShowResult } = useContext(ApplicationContext);


  const { data, loading, error } = useGetAssembliesExtraRoofTypeIdByAPIQuery(
    layerConfigId,
    layers,
    excludedLayerIds,
    manufacturerProduct,
    itemsPerPage,
    itemOffset,
    orderbyInfo,
    filters
  );
  const navigateToDetail = (navAssemblyId: number) => {
    navigate(`/assemblyDetail/${navAssemblyId}?${urlParams.toString()}`);
    setShowDialog(false);

  };
  useEffect(() => {
    if (error) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [error]);
  const handleBackClick = () => {
    setShowAssemblyDetail(false);
  };

  // Invoke when user click to change different page size
  const handlePageSizeChange = (event: any) => {
    const newPageSize = parseInt(event.target.value);
    setItemsPerPage(newPageSize);
    setItemOffset(0); // Reset to be the first page
    setTriggerAssemblyList(true);
    setNumShowResult(numShowResult + 1);
  };

  // Invoke when user click to request another page.
  const handlePageClick = async (event: any) => {
    if (!count) return;
    const newOffset = (event.selected * itemsPerPage) % count;
    //console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
    setTriggerAssemblyList(true);
    setNumShowResult(numShowResult + 1);
  };

  const handleSort = (field: string) => {
    const headers = [...COMPONENT_MAP.RoofTypeAssemblyView.colHeads];
    let newSortDir: string;
    if (fieldSort === field) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
      for (let i = 0; i < headers.length; i++) {
        if (headers[i].className !== 'notvisible') {
          if (headers[i].name === field) {
            headers[i].className =
              newSortDir === 'asc' ? 'th-inner sortable both sorted asc' : 'th-inner sortable both sorted desc';
          } else {
            headers[i].className = 'th-inner sortable both unsorted';
          }
        }
      }
    } else {
      newSortDir = 'asc';
      for (let i = 0; i < headers.length; i++) {
        if (headers[i].className !== 'notvisible') {
          if (headers[i].name === field) {
            headers[i].className = 'th-inner sortable both sorted asc';
          } else {
            headers[i].className = 'th-inner sortable both unsorted';
          }
        }
      }
    }

    // Get order_by variable
    let orderBy: NavAssembly_Order_By;
    const orderByType: Order_By = newSortDir === 'asc' ? Order_By.Asc : Order_By.Desc;
    switch (field) {
      case 'Assembly #':
        orderBy = { AssemblyId: orderByType, CoverSubassemblyId: orderByType, DeckSubassemblyId: orderByType };
        break;
      case 'Cover':
        orderBy = { AssemblyTypeCode: orderByType };
        break;
      case 'Application':
        orderBy = { AssemblyApplicationTypeCode: orderByType };
        break;
      case 'Securement':
        orderBy = { CoverSecurementMethodCode: orderByType };
        break;
      case 'Deck':
        orderBy = { DeckTypeCode: orderByType };
        break;
      case 'Wind Uplift':
        orderBy = { WindUplift: orderByType };
        break;
      case 'I/Fire':
        orderBy = { IntFireRatingCode: orderByType };
        break;
      case 'E/Fire':
        orderBy = { ExtFireRatingCode: orderByType };
        break;
      case 'Slope':
        orderBy = { Slope: orderByType };
        break;
      case 'Hail':
        orderBy = { HailRatingCode: orderByType };
        break;

      default:
        orderBy = { AssemblyId: Order_By.Asc, CoverSubassemblyId: Order_By.Asc, DeckSubassemblyId: Order_By.Asc };
    }

    setOrderbyInfo(orderBy);

    //console.log(headers);
    setFieldSort(field);
    setSortDir(newSortDir);
    setColumnHeaders(headers);
    setTriggerAssemblyList(true);
    setNumShowResult(numShowResult + 1);
  };
  const { setShowDialog } = useContext(ApplicationContext);
  useEffect(function setupListener() {
    function handleBackButton() {
      setShowDialog(false);
    }
    window.addEventListener('popstate', handleBackButton);
    return function cleanupListener() {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);
  return (
    <div id="roofnav_results" className="p-3">
      <div className="p-3">
        <div className="modal-header bg-dialog-steel align-items-center p-0">
          <HeaderTypography className={'ml-3 py-2'} fontFamily={'Inter'} fontSize={24} fontWeight={600}>
            {label}
          </HeaderTypography>
        </div>
      </div>
      {!showAssemblyDetail ? (
        <>
          <div className="col px-4 my-1 text-right">
            <Link onClick={() => setShowDialog(false)}>
              <TextOnlyComponent buttonText={'Back'} iconName={'arrow_left'} />
            </Link>
          </div>
          <TableComponent
            loading={loading}
            count={count}
            offset={itemOffset}
            limit={itemsPerPage}
            handlePageSizeChange={handlePageSizeChange}
            handlePageClick={handlePageClick}
            handleSort={handleSort}
            colHeads={columnHeaders}
            navigateToDetail={navigateToDetail}
            rows={
              data &&
              data.navassembly_api_search?.map((navAssembly: any) => {
                const row: string[] = [],
                  key = { navAssemblyId };
                row.push(navAssembly?.NavAssemblyId.toString() as string);
                row.push(navAssembly?.NavAssemblyName as string);
                row.push(navAssembly?.AssemblyTypeCode as string);
                row.push(navAssembly?.AssemblyApplicationTypeCode as string);
                row.push(navAssembly?.CoverSecurementMethodCode as string);
                row.push(navAssembly?.DeckTypeCode as string);
                row.push(navAssembly?.WindUplift as string);
                row.push(navAssembly?.IntFireRatingCode as string);
                row.push(navAssembly?.ExtFireRatingCode as string);
                row.push(navAssembly?.Slope as string);
                row.push(navAssembly?.HailRatingCode as string);

                return row;
              })
            }
          />
        </>
      ) : (
        <AssemblyDetail navAssemblyId={navAssemblyId} handleBackClick={handleBackClick} />
      )}

      <div className="clearfix"></div>
    </div>
  );
};
