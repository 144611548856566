import React from 'react';
import { FireRatingComponent } from './FireRatingComponent';
import { HailRatingComponent } from './HailRatingComponent';
import { WindRatingComponent } from './WindRatingComponent';
import { ProgressBar } from './ProgressBarComponent';
import { useSelector } from 'react-redux';
import { RootState } from './../../store';
import { SummaryComponent } from './CalculatorSummary/SummaryComponent';

interface IComponentWrapperProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function ComponentWrapper(props: IComponentWrapperProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      hidden={value !== index}
      id={`ratingscalc-section-${index}`}
      aria-labelledby={`ratingscalc-section-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export const CalculatorComponent = () => {
  const selectCalculatorIndex = (state: RootState) => state.calculator.calculatorIndex;
  const calculatorIndex = useSelector(selectCalculatorIndex);

  return (
    <div>
      <div className={'d-flex justify-content-center'}>
        <ProgressBar />
      </div>
      <ComponentWrapper index={0} value={calculatorIndex}>
        <FireRatingComponent />
      </ComponentWrapper>
      <ComponentWrapper index={1} value={calculatorIndex}>
        <HailRatingComponent />
      </ComponentWrapper>
      <ComponentWrapper index={2} value={calculatorIndex}>
        <WindRatingComponent />
      </ComponentWrapper>
      <ComponentWrapper index={3} value={calculatorIndex}>
        <SummaryComponent />
      </ComponentWrapper>
    </div>
  );
};
