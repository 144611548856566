export const LAYERTYPE_MAP: Record<string, string> = {
  '01': 'Cover (Composite Panel)',
  '02': 'Cover (Liquid Applied)',
  '03': 'Cover (Multi-ply)',
  '04': 'Cover (Single-ply)',
  '05': 'Cover (Standing/Lap Seam)',
  '06': 'Base Coat',
  '07': 'Coat',
  '08': 'Top Coat',
  '09': 'Base Ply',
  '10': 'Cap Ply',
  '11': 'Ply',
  '12': '(Deck) Cementitious Panel',
  '13': '(Deck) Fiber Reinforced Cement',
  '14': '(Deck) Fiber Reinforced Plastic',
  '16': 'Lightweight Insulating Concrete Subassembly',
  '17': '(Deck) Steel',
  '18': '(Deck) Structural Concrete',
  '19': '(Deck) Treated Wood',
  '20': '** DO NOT USE **(LA)',
  '21': 'Air Retarder',
  '22': 'Base Sheet',
  '23': 'Coating/Surfacing',
  '24': 'Cover Board',
  '25': 'Existing Roof',
  '26': 'Form Deck',
  '27': 'Insulation (Batt/Blanket)',
  '28': 'Insulation (Board Stock)',
  '29': 'Insulation (Spray Foam)',
  '30': 'Lightweight Insulating Concrete',
  '31': 'Liner Panel',
  '33': 'Substrate Primer',
  '34': 'Separator Sheet',
  '35': 'Structure',
  '36': 'Thermal Barrier',
  '37': 'Vapor Retarder',
  '38': 'Cover Strip/Disk',
  '39': 'Securement (Cover)',
  '40': 'Securement (Sheet Lap)',
  '41': 'Securement (Base Sheet)',
  '42': 'Securement (Board Stock)',
  '43': 'Securement (Deck Lap)',
  '44': 'Securement (Deck)',
  '45': 'Securement',
  '50': 'Steel Deck Specification',
  '51': 'Self-Securement',
};
