import { ApolloProvider } from '@apollo/client';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { AuthError } from 'msal';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { apolloClient } from './ApolloClient';
import './assets/css/index.css';
import { azureAADPublicClientApplication } from './Auth';
import Routes from './Routes';
import * as serviceWorker from './serviceWorker';
import UnAuthedRoutes from './UnAuthedRoutes';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import theme from './theme'
import { ThemeProvider } from 'react-bootstrap';

// This exports the whole icon packs for Brand and Solid. Required if testing icons- see TextOnlyComponent.test.tsx 
library.add(fab, fas)

interface ErrorComponentProps {
  error: AuthError | null;
}

const rnTheme = theme

export const ErrorComponent = ({ error }: ErrorComponentProps) => {
  console.error(error);
  return (
    <div>
      An Error Occurred during login
      <br />
      <button className="mdc-button mdc-button--raised mdc-button--touch btn-assembly-rating my-0 mdc-ripple-upgraded"
        onClick={() => {
          azureAADPublicClientApplication.loginRedirect().catch((error) => {
            console.error(error);
          });
        }}
      >
        <span className="mdc-button__label">Login Again</span>
      </button>
    </div>
  );
};
ReactDOM.render(
  // //  <RdsAppLayout>
  //   {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}

  <MsalProvider instance={azureAADPublicClientApplication}>
    <BrowserRouter basename={process.env.REACT_APP_BASE_URL || '/'}>
      <ApolloProvider client={apolloClient}>
        <UnauthenticatedTemplate>
          <UnAuthedRoutes />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <ThemeProvider theme={rnTheme}>
            <Routes />
          </ThemeProvider>
        </AuthenticatedTemplate>
        {/* <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={{
            scopes: scopesToUse,
            state: btoa(location.pathname + location.search),
            ...(!Boolean(process.env.REACT_APP_AAD_REDIRECT_URL) && { redirectUri: location.origin }),
          }}
          errorComponent={ErrorComponent}
        >
          <ApolloProvider client={apolloClient}>
              <Routes />
              </ApolloProvider>
            </MsalAuthenticationTemplate> */}
      </ApolloProvider>
    </BrowserRouter>
  </MsalProvider>,

  document.querySelector('#root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
