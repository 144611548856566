import { Accordion, AccordionSummary, Box, Link, Typography, styled } from '@mui/material';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import theme from '../../theme';
import { SavedChipComponent } from '../shared/ChipComponent/SavedChipComponent';
import { TextOnlyComponent } from '../shared/TextOnlyComponent/TextOnlyComponent';
import { useMsal } from '@azure/msal-react';
import { useLocation, useNavigate } from 'react-router';
import { Loading } from '../shared/Loading/Loading';
import { ApplicationContext } from '../ApplicationProvider';
import { Dialog } from '../../components/shared/DialogComponent/Dialog';
import { DeleteModal } from './DeleteModal';
import { genrateUuidv4 } from '../../services/ScenarioService';

const SavedAccordionSummary = styled(AccordionSummary)({
  '&.MuiAccordionSummary-root': {
    borderLeft: `5px solid ${theme.palette.rnOrange.darkOrange}`,
    padding: '0px 10px',
    borderBottom: `1px solid #BDC2C6`,
    pointerEvents: 'none',
  },
  '& .MuiAccordionSummary-content': {
    margin: '3px 0',
  },
});
interface ISavedScenariosProps {
  scenario: any;
}

export const SavedAssemblies: React.FC<ISavedScenariosProps> = ({ scenario }: ISavedScenariosProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  
  const { setDialog, setShowDialog } = useContext(ApplicationContext);

  const handleDeletion = async (isdeleted: boolean) => {
    if (isdeleted) {
      const randguid = genrateUuidv4();
      navigate(`/savedItems/${randguid}?${urlParams.toString()}`);
    }
  };

  const setDeleteDialog = (scenarioid: string) => {
    setDialog(
      scenarioid !== null ? (
        <Dialog label="Delete Scenario">
          <>
            <DeleteModal scenarioid={scenarioid} handleDeletion={handleDeletion} />
          </>
        </Dialog>
      ) : null
    );
    setShowDialog(true);
  };
  const navigateToDetail = (scenarioid: any) => {
    navigate(`/savedAssemblyDetail/${scenarioid}?${urlParams.toString()}`);
  };

  return (
    <>
      <Box className="py-3" key={scenario.ScenarioId}>
        <Accordion disableGutters>
          <SavedAccordionSummary>
            <div className="col-xl-6 col-md-6">
              <Typography className={'headline-5'} color={theme.palette.rnSteel.steel700}>
                {scenario.Name}
              </Typography>
            </div>
            <div className="col-xl-6 col-md-6 d-flex justify-content-between">
              {scenario.ScenarioStatusId === 1 ? (
                <SavedChipComponent label={'Active'} />
              ) : (
                <SavedChipComponent isArchived label={'Invalid'} />
              )}
              <Link onClick={() => navigateToDetail(scenario.ScenarioId)} className="d-flex float-right">
                <Typography
                  color={theme.palette.rnSteel.steel700}
                  className={'small-para-bold px-2'}
                  style={{ pointerEvents: 'auto', cursor: 'pointer' }}
                >
                  View
                </Typography>
              </Link>
              <Link
                onClick={() => setDeleteDialog(scenario.ScenarioId)}
                style={{ pointerEvents: 'auto', cursor: 'pointer' }}
              >
                <TextOnlyComponent iconName="delete" iconColor={theme.palette.rnOrange.darkOrange} />
              </Link>
              <span> </span>
            </div>
          </SavedAccordionSummary>
        </Accordion>
      </Box>
    </>
  );
};
