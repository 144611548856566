import { Box } from '@mui/material';
import React from 'react';
import theme from '../../theme';
import { CalcLaunchComponent } from './CalcLaunchComponent';
import { CalculatorComponent } from './CalculatorComponent';
import { useSelector } from 'react-redux';
import { RootState } from './../../store';


const selectCalc = (state: RootState) => state.calculator.showCalc;

export const Calculator = () => {
  const showCalc = useSelector(selectCalc);
  return <Box>{!showCalc ?
    <CalcLaunchComponent />
    :
    <CalculatorComponent />
  }</Box>;
};
