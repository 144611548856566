import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import calculatorReducer from './reducers/calculatorReducer';
import projectReducer from './reducers/projectReducer';

export const store = configureStore({
    reducer: {
        calculator: calculatorReducer,
        projects: projectReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch