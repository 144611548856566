export const EXCHANGE_VARIABLES = [
    'C_AssemblyType_AssemblyTypeId_view',
    'C_IntFireRating_IntFireRatingId_view',
    'C_ExtFireRating_ExtFireRatingId_view',
    'C_AssemblyApplicationType_AssemblyApplicationTypeId_view',
    'C_HailRating_HailRatingId_view',
    'C_CoverSecurementMethod_CoverSecurementMethodId_view',
    'C_DeckType_DeckTypeId_view',
    'NavAssembly_Slope_view',
    'NavAssembly_WindUplift_view',
    'ExtendedLayerConfigSplit_ExtendedLayerConfigId'
  ];