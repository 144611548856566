export const LAYERTYPES = [
  { LayerTypeId: 1, LayerTypeCode: 'Cover (Composite Panel)' },
  { LayerTypeId: 2, LayerTypeCode: 'Cover (Liquid Applied)' },
  { LayerTypeId: 3, LayerTypeCode: 'Cover (Multi-ply)' },
  { LayerTypeId: 4, LayerTypeCode: 'Cover (Single-ply)' },
  { LayerTypeId: 5, LayerTypeCode: 'Cover (Standing/Lap Seam)' },
  { LayerTypeId: 6, LayerTypeCode: 'Base Coat' },
  { LayerTypeId: 7, LayerTypeCode: 'Coat' },
  { LayerTypeId: 8, LayerTypeCode: 'Top Coat' },
  { LayerTypeId: 9, LayerTypeCode: 'Base Ply' },
  { LayerTypeId: 10, LayerTypeCode: 'Cap Ply' },
  { LayerTypeId: 11, LayerTypeCode: 'Ply' },
  { LayerTypeId: 12, LayerTypeCode: '(Deck) Cementitious Panel' },
  { LayerTypeId: 13, LayerTypeCode: '(Deck) Fiber Reinforced Cement' },
  { LayerTypeId: 14, LayerTypeCode: '(Deck) Fiber Reinforced Plastic' },
  { LayerTypeId: 16, LayerTypeCode: 'Lightweight Insulating Concrete Subassembly' },
  { LayerTypeId: 17, LayerTypeCode: '(Deck) Steel' },
  { LayerTypeId: 18, LayerTypeCode: '(Deck) Structural Concrete' },
  { LayerTypeId: 19, LayerTypeCode: '(Deck) Treated Wood' },
  { LayerTypeId: 20, LayerTypeCode: '** DO NOT USE **(LA)' },
  { LayerTypeId: 21, LayerTypeCode: 'Air Retarder' },
  { LayerTypeId: 22, LayerTypeCode: 'Base Sheet' },
  { LayerTypeId: 23, LayerTypeCode: 'Coating/Surfacing' },
  { LayerTypeId: 24, LayerTypeCode: 'Cover Board' },
  { LayerTypeId: 25, LayerTypeCode: 'Existing Roof' },
  { LayerTypeId: 26, LayerTypeCode: 'Form Deck' },
  { LayerTypeId: 27, LayerTypeCode: 'Insulation (Batt/Blanket)' },
  { LayerTypeId: 28, LayerTypeCode: 'Insulation (Board Stock)' },
  { LayerTypeId: 29, LayerTypeCode: 'Insulation (Spray Foam)' },
  { LayerTypeId: 30, LayerTypeCode: 'Lightweight Insulating Concrete' },
  { LayerTypeId: 31, LayerTypeCode: 'Liner Panel' },
  { LayerTypeId: 33, LayerTypeCode: 'Substrate Primer' },
  { LayerTypeId: 34, LayerTypeCode: 'Separator Sheet' },
  { LayerTypeId: 35, LayerTypeCode: 'Structure' },
  { LayerTypeId: 36, LayerTypeCode: 'Thermal Barrier' },
  { LayerTypeId: 37, LayerTypeCode: 'Vapor Retarder' },
  { LayerTypeId: 38, LayerTypeCode: 'Cover Strip/Disk' },
  { LayerTypeId: 39, LayerTypeCode: 'Securement (Cover)' },
  { LayerTypeId: 40, LayerTypeCode: 'Securement (Sheet Lap)' },
  { LayerTypeId: 41, LayerTypeCode: 'Securement (Base Sheet)' },
  { LayerTypeId: 42, LayerTypeCode: 'Securement (Board Stock)' },
  { LayerTypeId: 43, LayerTypeCode: 'Securement (Deck Lap)' },
  { LayerTypeId: 44, LayerTypeCode: 'Securement (Deck)' },
  { LayerTypeId: 45, LayerTypeCode: 'Securement' },
  { LayerTypeId: 50, LayerTypeCode: 'Steel Deck Specification' },
  { LayerTypeId: 51, LayerTypeCode: 'Self-Securement' },
];
