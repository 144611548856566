import { useContext, useEffect, useState } from 'react';

import {
  GetApprovalsDocByIdDocument,
  GetApprovalsDocByIdQuery,
  GetApprovalsDocByIdQueryVariables,
  useGetApprovalsDocByCategoryIdQuery,
  useGetApprovalsDocByIdQuery,
} from '../models/GQLGeneratedModels';
import { apolloClient } from '../ApolloClient';

// export const getApprovalDocByDocumentId = (ComponentDocId: number) => {
//   const { data, loading, error } = useGetApprovalsDocByIdQuery({
//     variables: { ApprovalsDocId: ComponentDocId },
//   });
//   return data?.ApprovalsDoc;
// };
export const getDataSheetsByCategoryId = (catid: number) => {
  const { data, loading, error } = useGetApprovalsDocByCategoryIdQuery({
    variables: { ApprovalsDocCategoryId: catid },
  });
  return data?.ApprovalsDoc;
};
export const getApprovalDocByDocumentId = async (ComponentDocId: number) => {
  const result = await apolloClient.query<GetApprovalsDocByIdQuery, GetApprovalsDocByIdQueryVariables>({
    query: GetApprovalsDocByIdDocument,
    variables: {
      ApprovalsDocId: ComponentDocId,
    },
  });
  return result.data.ApprovalsDoc;
};
