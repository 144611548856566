import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClickAwayListener, styled, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import theme from "../../../theme";
import { ApplicationContext } from '../../ApplicationProvider';
import './Dialog.scss';

interface IDialogProps {
	label: React.ReactNode;
	children: React.ReactNode;
}

const HeaderTypography = styled(Typography)({
	'&.MuiTypography-root': {
		color: theme.palette.rnSteel.steel700
	}

});

// TODO: as with select components, this should be shifted from MDC dialog to an MUI dialog post-UAT
export const Dialog = ({ label, children }: IDialogProps) => {
	const { setShowDialog } = useContext(ApplicationContext);
	useEffect(function setupListener() {
		function handleBackButton() {
			setShowDialog(false);
		}
		window.addEventListener('popstate', handleBackButton)
		return function cleanupListener() {
			window.removeEventListener('popstate', handleBackButton)
		}
	}, [])
	return (
		<div id="DialogLayers" className="mdc-dialog mdc-dialog--open rn-dialog">
			{/* <ClickAwayListener onClickAway={e => { setShowDialog(false) }}> */}
			<div className="mdc-dialog__container min-vw-100">

				<div tabIndex={0} aria-hidden="true" className="mdc-dom-focus-sentinel"></div><div className="mdc-dialog__surface w-100 dialog-container" role="alertdialog" aria-modal="true" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-content">
					<div className="modal-header bg-dialog align-items-center p-0">
						<HeaderTypography className={'ml-3 py-2'} fontFamily={'Inter'} fontSize={24} fontWeight={600}>{label}</HeaderTypography>
						<button type="button" className="dialog-close m-0 mr-4 p-2" data-mdc-dialog-action="close"
							onClick={() => setShowDialog(false)}>
							<FontAwesomeIcon size={'2x'} icon={["fas", "xmark"]} className={'px-1'} />
						</button>
					</div>
					<div className="modal-body px-4 overflow-auto">
						{children}
					</div>
				</div>

				<div tabIndex={0} aria-hidden="true" className="mdc-dom-focus-sentinel"></div></div>
			{/* </ClickAwayListener> */}
			<div className="mdc-dialog__scrim"></div>
		</div>

	);
};