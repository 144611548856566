import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonComponent } from '../../components/shared/ButtonComponent/ButtonComponent';
import "./PageNotFound.scss";

export const PageNotFound: React.FC = () => {
  const message = "Please check the URL in address bar and try again."
  const navigate = useNavigate()

  return (
    <div className="rnpagenotfound">
      <div className="section-wrapper">
        <div className="row mx-1 px-2">
          <div className="left-text">404</div>
          <div>
            <hr className="divider my-2" />
          </div>
        </div>
        <div>

          <div className="mb-4">
            <div className="right-header mb-2">
              Page not found
            </div>
            <div className="right-message my-2">
              {message}
            </div>
          </div>
          <div className="row ml-0 my-2">
            <Link to="/" tabIndex={-1}>

              <ButtonComponent buttonType={'primary'}
                buttonText={"Go Back Home"}
                testId={"error-home-link"}
                tabIndex={0}
                href={'/'}
              // click={() => navigate('/')}
              />
            </Link>
            <a href="mailto:ITsupportroofnav@fmapprovals.com" tabIndex={-1} >
              <ButtonComponent buttonType={'secondary'}
                buttonText={"Contact Support"}
                buttonStyle={"mx-3"}
                testId={"error-support-link"}
                label={"Contact Support"}
                tabIndex={0} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
