import React from 'react';
import { useGetTopCoatUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { SolidApplicationRate } from '../../utils/Measurement/SolidApplicationRate';
import { VolumePerUnitArea } from '../../utils/Measurement/VolumePerUnitArea';
import './UsageDetails.scss';

export const TopCoatUsageDetail = (props: any) => {
  const { data, loading, error } = useGetTopCoatUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];
  const isMetric = usage?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Cover, Liquid Applied (Top Coat)</h4>
      <table>
        <tr>
          <td>Number of Coats:</td>
          <td>{usage?.TopCoatUsage?.NumCoats}</td>
        </tr>
        <tr>
          <td>Material:</td>
          <td>{usage?.Component?.ManufacturedProduct?.TopCoat?.C_LiquidAppliedMaterial.LiquidAppliedMaterialCode}</td>
        </tr>
        <tr>
          <td>Min App Rate per Coat Wet:</td>
          <td>{VolumePerUnitArea.display(usage?.TopCoatUsage?.ApplicationRateMinWet, isMetric)}</td>
        </tr>
        <tr>
          <td>Min App Rate per Coat Dry:</td>
          <td>{SolidApplicationRate.display(usage?.TopCoatUsage?.ApplicationRateMinDry, isMetric)}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
