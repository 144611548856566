import { useMsal } from '@azure/msal-react';
import { Box, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Order_By } from '../../models/GQLGeneratedModels';
import { getBugReportId } from '../../services/SaveLoadSearchService';
import { ApplicationContext } from '../ApplicationProvider';
import { ConfigitContext } from '../ConfigitProvider';
import { ButtonComponent } from '../shared/ButtonComponent/ButtonComponent';
import { Dialog } from '../shared/DialogComponent/Dialog';
import { SelectComponent } from '../shared/SelectComponent/SelectComponent';
import TextAreaComponent from '../shared/TextAreaComponent';

const ReportBug: React.FC = () => {
  const { instance: azureADPCA } = useMsal();
  const { itemOffset, itemsPerPage, filters } = useContext(ApplicationContext);
  const { layers, variableAssignments, manufacturerProduct, excludedLayerIds } = useContext(ConfigitContext);
  const { setDialog, setShowDialog } = useContext(ApplicationContext);
  const [reason, setReason] = useState('');
  const [notes, setNotes] = useState('');
  const [selectError, setSelectError] = useState(false);
  const [textError, setTextError] = useState(false);
  const reasonOptions = [
    { name: 'Zero results', value: 1 },
    { name: 'Inconsistent result', value: 2 },
    { name: 'Other', value: 3 },
  ];

  const getReasonOptionTextBasedOnValue = (value: string) => {
    if (value === '') return '';
    else {
      for (let i = 0; i < reasonOptions.length; i++) {
        if (reasonOptions[i].value.toString() === value) return reasonOptions[i].name;
      }
      return '';
    }
  };

  const handleSendClick = () => {
    // super rough validation. TODO: set up react validation library in future PBI
    setSelectError(false);
    setTextError(false);
    if (reason === '') {
      setSelectError(true)
    }
    if (notes === '') {
      setTextError(true)
    }
    if (notes !== '' && reason !== '') {
      // Temporarily comments out the functionality of posting report bug into DB
      // Get user email
      let userEmail = azureADPCA.getActiveAccount()?.username || 'test@test.com'; // works for initial Azure B2C before customization
      // For customized Azure B2C, user email needs to be retrieved as follows:
      let tokenClaims;
      if (azureADPCA.getActiveAccount()?.idTokenClaims) {
        tokenClaims = azureADPCA.getActiveAccount()?.idTokenClaims;
        if (tokenClaims && tokenClaims.hasOwnProperty('email')) {
          userEmail = (tokenClaims as any)['email'];
        }
      }

      //const userName = azureADPCA.getActiveAccount()?.name || 'test user';

      const reasonText = getReasonOptionTextBasedOnValue(reason);
      const urlPath = window.location.href;
      

      // Add a bug report into database
      getBugReportId(
        layers,
        //filters,
        variableAssignments,
        excludedLayerIds,
        manufacturerProduct,
        itemsPerPage,
        itemOffset,
        {
          AssemblyId: Order_By.Asc,
          CoverSubassemblyId: Order_By.Asc,
          DeckSubassemblyId: Order_By.Asc,
        },
        reasonText,
        notes,
        userEmail,
        urlPath
      );

      // console.log('Reason is ', reason);
      // console.log('reasonText is ', reasonText);
      // console.log('Notes is ', notes);

      setDialog(
        <Dialog label="Thank You">
          <Box gap={2} display="flex" flexDirection="column">
            <Typography className={'py-2'} fontFamily={'Inter'} fontSize={'16px'} lineHeight={'20px'}>
              Thank you, your message has been sent to our team for review.
            </Typography>
            <ButtonComponent
              buttonType="primary"
              buttonText="Close"
              buttonCss={{ margin: 0 }}
              click={() => setShowDialog(false)}
            />
          </Box>
        </Dialog>
      );
      setShowDialog(true);
    }
  };
  return (
    <Box gap={2} display="flex" flexDirection="column">
      <Typography className={'py-2'} fontFamily={'Inter'} fontSize={'16px'} lineHeight={'20px'}>
        Your search criteria will automatically be sent, please add additional details below.
      </Typography>
      <SelectComponent
        label="Reason"
        options={reasonOptions}
        required
        className={'as-2'}
        value={reason}
        setValue={setReason}
        isError={selectError}
        errorMsg={"Please select an option"}
      />
      <TextAreaComponent
        label="Describe the error you encountered:"
        required
        value={notes}
        setValue={setNotes}
        isError={textError}
        errorMsg={"Please enter a short description of the error"} />
      <ButtonComponent buttonType="primary" buttonText="Send" click={handleSendClick} />
    </Box>
  );
};

export default ReportBug;
