import React from 'react';
import { useGetPreassembledFasteningUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { Area } from '../../utils/Measurement/Area';
import { Length } from '../../utils/Measurement/Length';
import './UsageDetails.scss';

export const PreassembledFasteningUsageDetail = (props: any) => {
  const { data, loading, error } = useGetPreassembledFasteningUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];
  const isMetric = usage?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <div className={'row'}>
        
        <h4>Fastening (Pre-assembled Fastening)</h4>
      </div>
      <div className={'row ml-0'}>
        <table>
          <tr>
            <td>Fastener Material:</td>
            <td>
              {
                usage?.Component?.ManufacturedProduct?.PreassembledFastening?.cSecurementmaterialByFastenermaterialid
                  .SecurementMaterialCode
              }
            </td>
          </tr>
          <tr>
            <td>Row Spacings:</td>
            <td>{Length.display(usage?.PreassembledFasteningUsage?.RowSpacing, isMetric)}</td>
          </tr>
          <tr>
            <td>On Center:</td>
            <td>{Length.display(usage?.PreassembledFasteningUsage?.OnCenter, isMetric)}</td>
          </tr>
          <tr>
            <td>Field Rows:</td>
            <td>{usage?.PreassembledFasteningUsage?.IsFieldRows ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <td>Field Row On Center:</td>
            <td>{Length.display(usage?.PreassembledFasteningUsage?.FieldRowsOnCenter, isMetric)}</td>
          </tr>
          <tr>
            <td>Fastener Diameter:</td>
            <td>
              {Length.display(usage?.Component?.ManufacturedProduct?.PreassembledFastening?.FastenerDiameter, isMetric)}
            </td>
          </tr>
          <tr>
            <td>Number of Field Rows:</td>
            <td>{usage?.PreassembledFasteningUsage?.NumFieldRows}</td>
          </tr>
          <tr>
            <td>Min Length:</td>
            <td>{Length.display(usage?.Component?.ManufacturedProduct?.PreassembledFastening?.LengthMin, isMetric)}</td>
          </tr>
          <tr>
            <td>Application Method:</td>
            <td>{usage?.PreassembledFasteningUsage?.C_FasteningApplicationMethod.FasteningApplicationMethodCode}</td>
          </tr>
          <tr>
            <td>Max Length:</td>
            <td>{Length.display(usage?.Component?.ManufacturedProduct?.PreassembledFastening?.LengthMax, isMetric)}</td>
          </tr>
          <tr>
            <td>Embedment:</td>
            <td>{Length.display(usage?.PreassembledFasteningUsage?.Embedment, isMetric)}</td>
          </tr>
          <tr>
            <td>Stress Distributor:</td>
            <td>{usage?.PreassembledFasteningUsage?.C_StressDistributorType.StressDistributorTypeCode}</td>
          </tr>
          <tr>
            <td>Steel Deck Fastener:</td>
            <td>{usage?.Component?.ManufacturedProduct?.PreassembledFastening?.IsSteelDeckFastener ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <td>Fasteners per Plate/Clip:</td>
            <td>{usage?.PreassembledFasteningUsage?.FastenersPerPlateOrClip}</td>
          </tr>
          <tr>
            <td>Contributory Area:</td>
            <td>{Area.display(usage?.PreassembledFasteningUsage?.ContributoryArea, isMetric)}</td>
          </tr>
          <tr>
            <td>Stress Distributor Material:</td>
            <td>
              {
                usage?.Component?.ManufacturedProduct?.PreassembledFastening?.C_SecurementMaterial
                  .SecurementMaterialCode
              }
            </td>
          </tr>
          <tr>
            <td>Comments:</td>
            <td>{usage?.Comments}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};
