import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Minimal from './layouts/Minimal';
import { Login } from './pages/Login';
import { UnAuthedQuickStartGuide } from './components/Document/UnAuthedQuickStartGuide';


const UnAuthedRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route element={<Minimal />} path="*">
          <Route element={<Login />} path="*" />
          <Route element={<UnAuthedQuickStartGuide />} path="quickstart" />
        </Route>
      </Routes>
    </>
  );
};

export default UnAuthedRoutes;
