import { Box, Link, Modal, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetAssemblyCountByAPIQuery } from '../../services/AssemblyService';
import theme from '../../theme';
import { ApplicationContext } from '../ApplicationProvider';
import { ConfigitContext } from '../ConfigitProvider';
import ShareSearch from '../ShareSearch';
import { ButtonToggleComponent } from '../shared/ButtonToggleComponent/ButtonToggleComponent';
import { Dialog } from '../shared/DialogComponent/Dialog';
import { TextOnlyComponent } from '../shared/TextOnlyComponent/TextOnlyComponent';
import { AssemblyView } from './AssemblyView';
import { RoofTypeView } from './RoofTypeView';
import { ErrorModalComponent } from '../shared/ErrorModalComponent/ErrorModalComponent';

export const MatchingAssemblies: React.FC = () => {
  const { view, setView } = useContext(ApplicationContext);
  const location = useLocation();
  const { filters } = useContext(ApplicationContext);
  const { setDialog, setShowDialog, showDialog } = useContext(ApplicationContext);
  const { layers, manufacturerProduct, excludedLayerIds } = useContext(ConfigitContext);
  const { setTriggerRoofTypeList } = useContext(ApplicationContext);
  const { setTriggerAssemblyList } = useContext(ApplicationContext);
  const { numShowResult, setNumShowResult } = useContext(ApplicationContext);
  const { autoRefreshSearchResult } = useContext(ApplicationContext);
  const { count, countLoading, countError } = useGetAssemblyCountByAPIQuery(
    layers,
    excludedLayerIds,
    manufacturerProduct,
    filters
  );
  const [roofTypeCount, setRoofTypeCount] = useState(-1);
  useEffect(() => {
    if (countError) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [countError])


  const processViewToggle = (viewOption: string) => {
    setView(viewOption);
    if (viewOption === 'Roof Type') {
      if (!autoRefreshSearchResult) {
        setTriggerRoofTypeList(true);
        setNumShowResult(numShowResult + 1);
      }
    }
    if (viewOption === 'Assembly') {
      if (!autoRefreshSearchResult) {
        setTriggerAssemblyList(true);
        setNumShowResult(numShowResult + 1);
      }
    }
  }

  const getHeading = () => {
    let heading = '';
    if (view === 'Roof Type' || view === 'Assembly') {
      heading += (countLoading ? 'Loading' : count) + ' Matching Assemblies';
    } else {
      heading += (countLoading ? 'Loading' : count) + ' Results';
    }
    if (!autoRefreshSearchResult && !filters?.NavAssemblyName && !count) {
      heading = ""
    }
    return heading;
  };

  const handleShareClick = () => {
    setDialog(
      <Dialog label="Share">
        <ShareSearch />
      </Dialog>
    );
    setShowDialog(true);
  };

  return (
    <section className="bg-white h-100 overflow-hidden">
      <div className="row align-items-stretch">
        <div className="col">
          <div className="d-flex align-items-center p-3">
            <Typography color={theme.palette.rnOrange.darkOrange} className={'headline-1'}>
              {getHeading()}
            </Typography>
          </div>
          {(autoRefreshSearchResult || (!autoRefreshSearchResult && count)) ?
            <div className="d-flex align-items-end p-3">
              <Typography
                fontWeight={600}
                fontFamily={'Inter'}
                fontSize={'18px'}
                lineHeight={'17px'}
                className={'pb-1 pr-4'}
              >
                View Results by
              </Typography>
              <ButtonToggleComponent
                options={['Roof Type', 'Assembly']}
                selectedOption={view}
                handleSelect={processViewToggle}
                className={' mt-0 rooftype-select'}
              />

              <div className="col px-4 my-1 text-right">
                <Link onClick={handleShareClick}>
                  <TextOnlyComponent buttonText={'Share'} iconName={'share'} />
                </Link>
              </div>
            </div>
            :
            <>
            </>
          }
        </div>
      </div>
      {(autoRefreshSearchResult || (!autoRefreshSearchResult && count)) ?
        <div className="card bg-transparent">
          {view === 'Assembly' && count && <AssemblyView count={count} />}
          {view === 'Roof Type' && <RoofTypeView roofTypeCount={roofTypeCount} setRoofTypeCount={setRoofTypeCount} />}
        </div>
        :
        <Box className=' w-100 h-50 pt-5'>
          <br></br>
          <div >
            {count === 0 ?
              <div className='d-flex justify-content-center'>
                <Typography className='large-para-bold'
                >
                  No Search Results were returned. Please adjust your criteria and try again.
                </Typography>
              </div>
              :
              <div className='d-flex justify-content-center'>
                <Typography className='large-para-bold'
                >
                  Searching is set to 'Manual'. Press 'Search' after specifying your criteria to show results.
                </Typography>
              </div>
            }
          </div>
        </Box>
      }
    </section>
  );
};
