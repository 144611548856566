import React, { InputHTMLAttributes, useRef, useState } from 'react';
import { orderAllowedValuesFirst } from '../../../services/ConfigitService';
import { ConfigurationValue, SingletonValue } from '../../../types/configurator';
import { AutocompleteComponent, IAutocompleteValue } from './../AutocompleteComponent/AutocompleteComponent';
import { ISelectValue } from '../TextFieldComponent';

export interface IConfigitAutocompleteProps {
  id?: string;
  label?: string;
  options: ConfigurationValue[];
  hasAllowedValues?: boolean;
  handleSelect: (value: string) => void;
  handleUnSelect: (variableId: string) => void;
  disabled?: boolean;
  preSelectedOption?: ISelectValue;
  className?: string;
}

export const ConfigitAutocomplete = ({
  id,
  label,
  options,
  hasAllowedValues,
  handleSelect,
  handleUnSelect,
  disabled,
  className,
  preSelectedOption,
}: IConfigitAutocompleteProps) => {
  const optionArray: IAutocompleteValue[] = [];
  const [selectedOption, setSelectedOption] = useState<ISelectValue | undefined>(preSelectedOption);
  if (preSelectedOption?.name !== selectedOption?.name) setSelectedOption(preSelectedOption);
  const mapOptions = (options: ConfigurationValue[]) => {
    const sortedOptions = orderAllowedValuesFirst(options);
    sortedOptions.forEach((optionValue: SingletonValue) => {
      optionArray.push({
        value: optionValue.value,
        name: checkName(optionValue.name),
        incompatible: optionValue.incompatible,
      });
    });
    return optionArray;
  };

  const checkName = (value: string | undefined) => {
    return value === undefined ? '' : value;
  };

  const updatedAutocompleteValue = (option: IAutocompleteValue | null) => {
    if (id) {
      if (id) {
        if (selectedOption && option === null) handleUnSelect(id);
        if (option && option.value)
          if (!selectedOption || selectedOption.value !== option.value) {
            handleSelect(String(option.value));
          }
      }
      setSelectedOption(option ? option : {});
    }
  };

  return (
    <AutocompleteComponent
      id={id}
      className={className ? className : 'w-100'}
      options={mapOptions(options)}
      setCallback={updatedAutocompleteValue}
      defaultValue={checkName(preSelectedOption?.name)}
      label={label}
    />
  );
};
