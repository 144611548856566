import React, { useContext, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Filters } from '../../components/Filters';
import './Home.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavAssembly_Order_By, useGetExtendedLayerConfigQuery } from '../../models/GQLGeneratedModels';
import { AllowedValues, Section, VariableAssignment } from '../../types/configurator';
import {
  configure,
  configureLayer as configureLayerAPI,
  getCompatibleExtendedLayerConfigs,
  mapInferredLayerWithConfigurations,
  updateFilters,
} from '../../services/ConfigitService';
import { ApplicationContext } from '../../components/ApplicationProvider';
import {
  getLayerDataById,
  updateExchangeVariableAssignments,
  updateLayerFilter,
  updateSelfSecurementFilter,
} from '../../utils/LayerTypeCheckUtilities';
import * as LayerApi from '../../utils/LayerApi';
import { unstable_batchedUpdates } from 'react-dom';
import { LAYERMODELHASURA_MAP } from '../../components/constants/LAYERMODELHASURA_MAP';
import { getLayerProps } from '../../components/constants/LAYER_DATA';
import { ConfigitContext, IExtendedLayerConfig } from '../../components/ConfigitProvider';

export const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { loading, setLoading, filters, setFilters, setItemOffset, defaultTab } = useContext(ApplicationContext);
  const settingVariableAssignmentsRef = useRef(false);
  const settingLayersRef = useRef(false);
  const { data } = useGetExtendedLayerConfigQuery();
  const {
    variableAssignments,
    setVariableAssignments,
    manufacturerProduct,
    setManufacturerProduct,
    section,
    setSection,
    sections,
    setSections,
    layers,
    setLayers,
    excludedLayerIds,
    setExcludedLayerIds,
    configureLayer,
    clearSearch,
    updateConfigitCriteria,
    layerConfigMasterData,
    setLayerConfigMasterData,
  } = useContext(ConfigitContext);
  useEffect(() => {
    let allExtendedLayerConfigs = data?.ExtendedLayerConfigSplit.map((data) => {
      return {
        ExtendedLayerConfigId: data.ExtendedLayerConfigId,
        FullConfigString: data.CompleteConfigString,
        GenericSecurementDuplicateString: data.ConfigStringGenericSecurementDuplicateId,
      } as IExtendedLayerConfig;
    });
    // Two extended layer config strings have abnormal securement with reversed from/to info
    // And it looks like 'L01&40,L39{35:01},L35' in DEV becomes ‘L01&40,L35,L39{35:01}’ in UAT and Prod
    // Temporarily avoid considering those special/abnormal cases.
    allExtendedLayerConfigs = allExtendedLayerConfigs?.filter(
      (item) =>
        item.FullConfigString !== 'L01&40,L39{35:01},L35' &&
        item.FullConfigString !== 'L01&40,L39{01:35},L35,L45{35:01}' &&
        item.FullConfigString !== 'L01&40,L35,L39{35:01}'
    );
    setLayerConfigMasterData(allExtendedLayerConfigs);
  }, [data]);
  const configitProcess = async () => {
    let newVariableAssignments = [...variableAssignments];
    let newSections = sections;
    // clear manufacturerProduct state if it is not empty and a filter (assembly props or layer cake) is being applied
    // back button will trigger both configitProcess and manufacturerProductEffect
    // add defaultTab === 0 check here to deal with back button case 2 - Product Used search
    // Otherwise filter is initialized and previous search criteria of product used search will be lost
    if (defaultTab === 0 && (newVariableAssignments.length || layers.length || excludedLayerIds.length) && manufacturerProduct.length) {
      setManufacturerProduct([]);
    }
    if (!loading) setLoading(true);
    // make a configit api call with empty input if no previous response is stored in sections state like loading a bug report
    if ((newVariableAssignments.length || layers.length || excludedLayerIds.length) && !sections.length) {
      newSections = await configure([]);
    }
    // if at least one layer is configured
    if (layers.find((data) => data.variableAssignments?.length))
      newVariableAssignments = await updateExchangeVariableAssignments(
        layers,
        excludedLayerIds,
        newVariableAssignments,
        newSections,
        layerConfigMasterData ?? []
      );
    else {
      // no layer is configured case
      // Compute matching layer configs
      newVariableAssignments = newVariableAssignments.filter((data) => data.value);
      const compatibleExtendedLayerConfigs = getCompatibleExtendedLayerConfigs(
        newSections,
        layerConfigMasterData ?? []
      );
      const matchingExtendedLayerConfigs = LayerApi.getMatchingLayerConfigs(
        layers.filter((data) => !data.autoSelected),
        excludedLayerIds,
        compatibleExtendedLayerConfigs
      );
      // when a layer is added or removed
      // update ExtendedLayerConfig variable assignments with matching layer configs
      if (
        matchingExtendedLayerConfigs.length &&
        matchingExtendedLayerConfigs.length < compatibleExtendedLayerConfigs.length
      ) {
        newVariableAssignments.push({
          variableId: 'ExtendedLayerConfigSplit_ExtendedLayerConfigId',
          type: 'AllowedValues',
          allowedValues: matchingExtendedLayerConfigs.map((data) => {
            return { type: 'SingletonValue', value: String(data.ExtendedLayerConfigId) } as AllowedValues;
          }),
        } as VariableAssignment);
      }
    }
    // make configit api call to get new sections
    newSections = await configure(newVariableAssignments);
    // unstable_batchedUpdates to avoid multiple re-renders for multiple state updates
    unstable_batchedUpdates(() => {
      let newFilters: any = { StatusId: { _eq: 1 } }; // newFilters is used in hasura query variables against database. It is initialized for active assemblies only.
      let filterApplied = false;
      // Compute inferred layers
      const compatibleExtendedLayerConfigs = getCompatibleExtendedLayerConfigs(
        newSections,
        layerConfigMasterData ?? []
      );
      let matchingExtendedLayerConfigs = LayerApi.getMatchingLayerConfigs(
        layers.filter((data) => !data.autoSelected),
        excludedLayerIds,
        compatibleExtendedLayerConfigs,
        true
      );
      if (!matchingExtendedLayerConfigs.length) matchingExtendedLayerConfigs = compatibleExtendedLayerConfigs;
      const allowedLayerConfigs = newVariableAssignments
        .find((data) => data.variableId === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId')
        ?.allowedValues?.map((data) => String(data.value));
      const result = LayerApi.getInferredLayers(
        layers.filter((data) => !data.autoSelected),
        allowedLayerConfigs?.length
          ? matchingExtendedLayerConfigs.filter((data) =>
            allowedLayerConfigs.includes(String(data.ExtendedLayerConfigId))
          )
          : matchingExtendedLayerConfigs
      );
      const resultLayers = mapInferredLayerWithConfigurations(layers, result);
      // when there is some new inferred layers or removal of previously inferred layers
      // update layers state without triggering configit process
      if (JSON.stringify(layers) !== JSON.stringify(resultLayers)) {
        setLayers(resultLayers);
        settingLayersRef.current = true;
      }
      // when there is change in ExtendedLayerConfig variable or some sub model communication variable
      // update variableAssignments state without triggering configit process
      if (JSON.stringify(variableAssignments) !== JSON.stringify(newVariableAssignments)) {
        setVariableAssignments(newVariableAssignments);
        settingVariableAssignmentsRef.current = true;
      }
      // Compute new filters
      const layerConfigIds = newVariableAssignments?.find(
        (variableAssignment) => variableAssignment.variableId === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId'
      );
      if (layerConfigIds?.allowedValues?.length) {
        filterApplied = true;
        newFilters['SplitLayerConfigId'] = {
          _in: layerConfigIds?.allowedValues?.map((config) => parseInt(String(config.value))),
        };
      }
      newSections.forEach((section) => {
        if (section && updateFilters(section, newFilters)) filterApplied = true;
      });
      newFilters = updateLayerFilter(resultLayers, newFilters);

      // Any layer has self-securement: may need to update newFilters with self-securement filter criteria
      if (resultLayers.find((layer) => layer.hasSelfSecurement))
        newFilters = updateSelfSecurementFilter(resultLayers, newFilters);

      if (newFilters._and.length === 0) delete newFilters._and;
      else filterApplied = true;
      setLoading(false);
      // update filters state if manufacturerProduct is empty
      if (defaultTab === 0 && !manufacturerProduct.length && JSON.stringify(newFilters) !== JSON.stringify(filters))
        setFilters(newFilters);
      setSections(newSections);
      // update page number to 1
      setItemOffset(0);
      // redirect to assembliesListing page if filter is applied in home page
      if (filterApplied && location.pathname.indexOf('assembliesListing') < 0)
        navigate(`assembliesListing?${params.toString()}`);
    });
  };
  useEffect(() => {
    // trigger configit process only if extended layers configs have been loaded from database
    // dont trigger configit process if settingVariableAssignmentsRef.current or settingLayersRef.current is true
    if (layerConfigMasterData?.length) {
      if (!settingVariableAssignmentsRef.current && !settingLayersRef.current) configitProcess();
      if (settingVariableAssignmentsRef.current) settingVariableAssignmentsRef.current = false;
      if (settingLayersRef.current) settingLayersRef.current = false;
    }
  }, [
    JSON.stringify(variableAssignments),
    JSON.stringify(layers),
    JSON.stringify(excludedLayerIds),
    layerConfigMasterData,
  ]);
  const manufacturerProductEffect = async (newManufacturerProduct: VariableAssignment[]) => {
    if (!loading) setLoading(true);
    // make a configit api call to ProductModel
    const section = await configureLayerAPI(newManufacturerProduct, 'ProductModel');
    const newFilters: any = { StatusId: { _eq: 1 } }; // newFilters is used in hasura query variables against database. It is initialized for active assemblies only.
    const layerTypeId = newManufacturerProduct.find((data) => data.variableId === 'C_LayerType_LayerTypeId')?.value;
    // Compute new filters
    if (layerTypeId) {
      // layer selected case
      const productId = newManufacturerProduct.find(
        (data) => data.variableId === 'ManufacturedProduct_ComponentId'
      )?.value;
      const manufacturerId = newManufacturerProduct.find(
        (data) => data.variableId === 'Manufacturer_ManufacturerId'
      )?.value;
      if (productId || manufacturerId) {
        const layerData = getLayerDataById(LayerApi.numToString(layerTypeId as number));
        const layerProps = getLayerProps(layerData);
        const model = layerProps.model ? (layerProps.model as string) : '--NA--';
        const view = LAYERMODELHASURA_MAP[model];
        newFilters[view] = {};
        if (productId) newFilters[view]['ComponentId'] = { _eq: parseInt(String(productId)) };
        if (manufacturerId) newFilters[view]['ManufacturerId'] = { _eq: parseInt(String(manufacturerId)) };
      } else {
        newFilters.NavLayers = { LayerTypeId: { _eq: parseInt(String(layerTypeId)) } };
      }
    } else updateFilters(section, newFilters);
    // unstable_batchedUpdates to avoid multiple re-renders for multiple state updates
    unstable_batchedUpdates(() => {
      setSection(section);
      // if there is a change in filters
      // back button will trigger both configitProcess and manufacturerProductEffect
      // add defaultTab === 1 check here to deal with back button case 1 - normal assembly search through assembly properties and layers 
      // Otherwise filter is initialized and previous search criteria of normal assembly search will be lost
      if (defaultTab === 1 && ((filters && JSON.stringify(newFilters) !== JSON.stringify(filters)) || newManufacturerProduct.length)) {
        setFilters(newFilters);
        // redirect to assembliesListing page if filter is applied in home page
        if (location.pathname.indexOf('assembliesListing') < 0) navigate(`assembliesListing?${params.toString()}`);
        // if manufacturerProduct filter is applied
        if (manufacturerProduct.length) {
          // clear variableAssignments if it is not empty
          if (variableAssignments.length) setVariableAssignments([]);
          // clear layers if it is not empty
          if (layers.find((layer) => !layer.autoSelected)) setLayers([]);
          // clear excludedLayerIds if it is not empty
          if (excludedLayerIds.length) setExcludedLayerIds([]);
        }
      }
      setLoading(false);
    });
  };
  useEffect(() => {
    manufacturerProductEffect(manufacturerProduct);
  }, [JSON.stringify(manufacturerProduct)]);

  return (
    <main className="main-content container-fluid flex-shrink-0">
      <div className="row h-100">
        <div className="col-lg-3 col-md-4 filters">
          <Filters />
        </div>
        <div className="col-lg-9 col-md-8 h-100">
          <Outlet />
        </div>
      </div>
    </main>
  );
};
