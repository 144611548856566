import { Box, Table, TableHead, TableCell, Typography, styled, TableRow } from '@mui/material';
import React, { useContext } from 'react';
import theme from '../../../theme';

export const InternalFireComponent = () => {
  const DescriptionA =
    'Roof will not propagate an interior building fire. The combination of materials in the assembly limits the amount of fuel contributed to an interior fire to within acceptable limits.';
  const ExampleA =
    'Steel, fire retardant-treated wood, structural cement fiber, and some gypsum and lightweight concrete.';
  const DescriptionB =
    'Deck will not burn or contribute fuel to a fire originating in the building. Deck prevents heat from passing through the deck to the combustibles above the deck for a minimum of 30 minutes.';
  const MainText =
    'Each FM Approved roof construction is categorized by its internal fire resistance. FM Approvals does not approve Class 2 decks, which will propagate fire on the deck underside.';
  const StyledTableHead = styled(TableHead)({
    '&.MuiTableHead-root': {
      backgroundColor: theme.palette.rnSteel.steel500,
    },
    '&.MuiTableHead-root tr th:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.rnStone.stone200}`,
    },
  });
  const StyledTableRow = styled(TableRow)({
    '&.MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: theme.palette.rnMisc.white,
      borderBottom: 'none',
    },
    '&.MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: theme.palette.rnStone.stone100,
      borderBottom: 'none',
    },
    '&.MuiTableRow-root:focus': {
      backgroundColor: theme.palette.rnSteel.steel500 + ' !important',
      color: theme.palette.rnMisc.white,
    },
  });
  const StyledTableCell = styled(TableCell)({
    '&.MuiTableCell-root': {
      verticalAlign: 'top',
      borderBottom: 'none',
    },
  });

  return (
    <Box>
      <Typography className={'small-para pb-4'}>{MainText}</Typography>
      <Table>
        <StyledTableHead>
          <tr>
            <StyledTableCell className={'col-3 modal-info-heading'}>Rating</StyledTableCell>
            <StyledTableCell className={'col-4 modal-info-heading'}>Description</StyledTableCell>
            <StyledTableCell className={'col-5 modal-info-heading'}>Examples</StyledTableCell>
          </tr>
        </StyledTableHead>
        <StyledTableRow>
          <StyledTableCell className={'modal-info-bold-text'}>Class 1</StyledTableCell>
          <StyledTableCell>
            <Typography className={'modal-info-text'}>{DescriptionA}</Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Typography className={'modal-info-text'}>{ExampleA}</Typography>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell className={'modal-info-bold-text'}>Non-combustible</StyledTableCell>
          <StyledTableCell className={'modal-info-text'}>{DescriptionB}</StyledTableCell>
          <StyledTableCell>
            <Typography className={'modal-info-text pb-2'}>
              Cementitious decks (e.g., concrete, fiber reinforced cement, and gypsum) that have <b>both</b> of the
              following:
            </Typography>
            <ul className={'pl-4 mb-2'}>
              <li className={'modal-info-text'}>Minimum thickness of 2 inches (51 mm)</li>
              <li className={'modal-info-text'}>Good fire resistive properties</li>
            </ul>
            <Typography className={'modal-info-text pb-2'}>
              Certain lightweight insulating concrete assemblies that also meet one of the following conditions:
            </Typography>
            <ul className={'pl-4 mb-2'}>
              <li className={'modal-info-text'}>
                Minimum thickness of 2 inches (51 mm), directly above the top of corrugations of the steel deck
              </li>
              <li className={'modal-info-text'}>
                Minimum thickness of 1/8 inch (3mm) above a structural concrete deck
              </li>
            </ul>
          </StyledTableCell>
        </StyledTableRow>
      </Table>
    </Box>
  );
};
