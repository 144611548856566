import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import theme from '../../../theme';
import './../calculator.scss';
import { SLOPE_DEGREE, SLOPE_RISE } from '../../constants/CALCULATOR_VALUES';


export const SummaryTables = () => {
    // alternative to these statements is to have one selector for calculator slice, but this is more readable
    // selectors
    const selectSurfaceRoughness = (state: RootState) => state.calculator.windRatingState.surfaceRoughness;
    const selectWindSpeed = (state: RootState) => state.calculator.windRatingState.windSpeed;
    const selectEnclosureClassification = (state: RootState) => state.calculator.windRatingState.enclosureClassification;
    const selectCycloneProne = (state: RootState) => state.calculator.windRatingState.cycloneProneLocation;
    const selectRoofAreaHeight = (state: RootState) => state.calculator.windRatingState.roofAreaHeight;
    const selectRoofAreaLength = (state: RootState) => state.calculator.windRatingState.roofAreaLength;
    const selectRoofAreaSlope = (state: RootState) => state.calculator.windRatingState.roofAreaSlope;
    const selectRoofAreaWidth = (state: RootState) => state.calculator.windRatingState.roofAreaWidth;
    const selectHailRating = (state: RootState) => state.calculator.hailRatingState.hailRating
    const selectInternalFire = (state: RootState) => state.calculator.fireRatingState.internalFireRating;
    const selectExternalFire = (state: RootState) => state.calculator.fireRatingState.externalFireRating;
    const selectHasParapet = (state: RootState) => state.calculator.windRatingState.hasParapet;
    const selectIsRiseRun = (state: RootState) => state.calculator.windRatingState.isRiseRun;



    // values from selectors
    const surfaceRoughness = useSelector(selectSurfaceRoughness);
    const windSpeed = useSelector(selectWindSpeed);
    const enclosureClassification = useSelector(selectEnclosureClassification);
    const cycloneProne = useSelector(selectCycloneProne);
    const roofAreaHeight = useSelector(selectRoofAreaHeight);
    const roofAreaLength = useSelector(selectRoofAreaLength);
    const roofAreaSlope = useSelector(selectRoofAreaSlope);
    const roofAreaWidth = useSelector(selectRoofAreaWidth);
    const hailRating = useSelector(selectHailRating);
    const internalFire = useSelector(selectInternalFire);
    const externalFire = useSelector(selectExternalFire);
    const hasParapet = useSelector(selectHasParapet);
    const isRiseRun = useSelector(selectIsRiseRun);


    const feetToMeters = (feet: string) => {
        const meters = (Number(feet) * 0.3048)
        return meters.toFixed(2);
    };
    const getSlopeEquivalent = () => {
        if (isRiseRun) {
            for (let i = 0; i < SLOPE_RISE.length; i++) {
                if (roofAreaSlope == SLOPE_RISE[i].value) {
                    return `${SLOPE_RISE[i].name} in 12 (${SLOPE_DEGREE[i].name})`
                }
            }
        } else {
            for (let i = 0; i < SLOPE_DEGREE.length; i++) {
                if (roofAreaSlope == SLOPE_DEGREE[i].value) {
                    return `${SLOPE_RISE[i].name} in 12 (${SLOPE_DEGREE[i].name})`
                }
            }
        }
    }

    return (
        <Box className={'row m-0 py-4 px-0'}>
            <div className={'col-sm-6 col-xs-12 table-wrapper pl-0'}>
                <Typography className={'headline-3 py-3'} color={theme.palette.rnSteel.steel700}>
                    Roof Area Properties
                </Typography>
                <div className={"summary-section col-sm-12 m-0 p-0"}>
                    <div className='row m-0 summary-row'>
                        <div className={'col-6 pl-0 text-right'}>
                            <Typography className={'summary-bold-text'}>Dimensions:</Typography>
                        </div>
                        <div>
                            <Typography className={'modal-info-bold-text'}>
                                {roofAreaLength} x {roofAreaWidth} ft ({feetToMeters(roofAreaLength)} x {feetToMeters(roofAreaWidth)} m)
                            </Typography>
                        </div>
                    </div>
                    <div className='row m-0 summary-row'>
                        <div className={'col-6 pl-0 text-right'}>
                            <Typography
                                className={'summary-bold-text'}>
                                Height:</Typography>
                        </div>
                        <div>
                            <Typography className={'modal-info-bold-text'}>
                                {roofAreaHeight} ft ({feetToMeters(roofAreaHeight)} m)
                            </Typography>
                        </div>
                    </div>
                    <div className='row m-0 summary-row'>
                        <div className={'col-6 pl-0 text-right'}>
                            <Typography className={'summary-bold-text'}>
                                Slope:</Typography>
                        </div>
                        <div>
                            <Typography className={'modal-info-bold-text'}>
                                {getSlopeEquivalent()}
                            </Typography>
                        </div>
                    </div>
                    <div className='row m-0 summary-row'>
                        <div className={'col-6 pl-0 text-right'}>
                            <Typography className={'summary-bold-text'}>
                                Internal Fire Rating:
                            </Typography>
                        </div>
                        <div>
                            <Typography className={'modal-info-bold-text'}>
                                {internalFire.name}
                            </Typography>
                        </div>
                    </div>
                    <div className='row m-0 summary-row'>
                        <div className={'col-6 pl-0 text-right'}>
                            <Typography className={'summary-bold-text'}>
                                Exterior Fire Rating:
                            </Typography>
                        </div>
                        <div>
                            <Typography className={'modal-info-bold-text'}>{externalFire.name}</Typography>
                        </div>
                    </div>
                    <div className='row m-0 summary-row'>
                        <div className={'col-6 pl-0 text-right'}>
                            <Typography className={'summary-bold-text'}>
                                Min 3ft (1m) Continuous Parapet:
                            </Typography>
                        </div>
                        <div>
                            <Typography className={'modal-info-bold-text'}>{hasParapet ? 'Y' : 'N'}</Typography>
                        </div>
                    </div>

                </div>
            </div>
            <div className={'col-sm-6 col-xs-12 table-wrapper pr-0'}>
                <Typography
                    className={'headline-3 py-3'}
                    color={theme.palette.rnSteel.steel700}>
                    Site Properties
                </Typography>
                <div className={"summary-section col-12 m-0 p-0"}>
                    <div className='row m-0 summary-row'>
                        <div className={'col-6 pl-0 text-right'}>
                            <Typography className={'summary-bold-text'}>Surface Roughness:</Typography>
                        </div>
                        <div>
                            <Typography className={'modal-info-bold-text'}>{surfaceRoughness.name}</Typography>
                        </div>
                    </div>
                    <div className='row m-0 summary-row'>
                        <div className={'col-6 pl-0 text-right'}>
                            <Typography className={'summary-bold-text'}>Wind Speed:</Typography>
                        </div>
                        <div>
                            <Typography className={'modal-info-bold-text'}>{windSpeed}</Typography>
                        </div>
                    </div>
                    <div className='row m-0 summary-row'>
                        <div className={'col-6 pl-0 text-right'}>
                            <Typography className={'summary-bold-text'}>In a Tropical Cyclone Prone Region:</Typography>
                        </div>
                        <div>
                            <Typography className={'modal-info-bold-text'}>{cycloneProne ? 'Y' : 'N'}</Typography>
                        </div>
                    </div>
                    <div className='row m-0 summary-row'>
                        <div className={'col-6 pl-0 text-right'}>
                            <Typography className={'summary-bold-text'}>Enclosure Classification:</Typography>
                        </div>
                        <div>
                            <Typography className={'modal-info-bold-text'}>{enclosureClassification.name}</Typography>
                        </div>
                    </div>
                    <div className='row m-0 summary-row'>
                        <div className={'col-6 pl-0 text-right'}>
                            <Typography className={'summary-bold-text'}>Hail Zone:</Typography>
                        </div>
                        <div>
                            <Typography className={'modal-info-bold-text'}>{hailRating.name}</Typography>
                        </div>
                    </div>

                </div>
            </div>
        </Box>
    )
}