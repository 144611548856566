import { Box, Table, TableHead, TableCell, Typography, styled, TableRow } from '@mui/material';
import React, { useContext } from 'react';
import theme from '../../../theme';

export const ExternalFireComponent = () => {
  const MainText =
    'Each FM Approved roof construction is placed in one of three categories for exterior fire resistance. These categories correspond to ASTM E108 (04) fire ratings.';

  const StyledTableHead = styled(TableHead)({
    '&.MuiTableHead-root': {
      backgroundColor: theme.palette.rnSteel.steel500,
    },
    '&.MuiTableHead-root tr th:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.rnStone.stone200}`,
    },
  });
  const StyledTableRow = styled(TableRow)({
    '&.MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: theme.palette.rnMisc.white,
      borderBottom: 'none',
    },
    '&.MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: theme.palette.rnStone.stone100,
      borderBottom: 'none',
    },
    '&.MuiTableRow-root:focus': {
      backgroundColor: theme.palette.rnSteel.steel500 + ' !important',
      color: theme.palette.rnMisc.white,
    },
  });
  const StyledTableCell = styled(TableCell)({
    '&.MuiTableCell-root': {
      verticalAlign: 'top',
      borderBottom: 'none',
    },
  });

  return (
    <Box>
      <Typography className={'small-para pb-3'}>{MainText}</Typography>
      <Table>
        <StyledTableHead>
          <tr>
            <StyledTableCell className={'col-3 modal-info-heading'}>Rating</StyledTableCell>
            <StyledTableCell className={'col-9 modal-info-heading'}>When Used</StyledTableCell>
          </tr>
        </StyledTableHead>
        <StyledTableRow>
          <StyledTableCell className={'modal-info-bold-text'}>Class A</StyledTableCell>
          <StyledTableCell>
            <Typography className={'modal-info-text pb-2'}>
              Preferred in all cases, and required under <b>any</b> of the following conditions:
            </Typography>
            <ul className={'pl-4 mb-2'}>
              <li className={'modal-info-text'}>The exterior fire exposure to the roof is severe.</li>
              <li className={'modal-info-text'}>
                The occupancy is particularly susceptible to smoke and/or water damage.
              </li>
              <li className={'modal-info-text'}>The building is subdivided by an MFL firewall.</li>
            </ul>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell className={'modal-info-bold-text'}>Class B</StyledTableCell>
          <StyledTableCell>
            <Typography className={'modal-info-text pb-2'}>
              Acceptable when <b>all</b> of the following conditions are true:
            </Typography>
            <ul className={'pl-4 mb-2'}>
              <li className={'modal-info-text'}>The exterior fire exposure to the roof is not severe.</li>
              <li className={'modal-info-text'}>
                The occupancy is not particularly susceptible to smoke and/or water damage.
              </li>
            </ul>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell className={'modal-info-bold-text'}>Class C</StyledTableCell>
          <StyledTableCell>
            <Typography className={'modal-info-text pb-2'}>
              Acceptable when <b>all</b> of the following conditions are true:
            </Typography>
            <ul className={'pl-4 mb-2'}>
              <li className={'modal-info-text'}>The exterior fire exposure to the roof is low.</li>
              <li className={'modal-info-text'}>
                The occupancy is not particularly susceptible to smoke and/or water damage.
              </li>
              <li className={'modal-info-text'}>The roof is less than 10,000 sq ft (930 sq m) in area.</li>
            </ul>
          </StyledTableCell>
        </StyledTableRow>
      </Table>
    </Box>
  );
};
