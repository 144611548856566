import { Typography } from '@mui/material';
import React, { useContext } from 'react';
import theme from '../../theme';
import { ApplicationContext } from '../ApplicationProvider';
import { ButtonComponent } from '../shared/ButtonComponent/ButtonComponent';
import { ButtonGroupComponent } from '../shared/ButtonGroupComponent/ButtonGroupComponent';
import { ToolTipComponent } from '../shared/TooltipComponent/ToolTipComponent';
import { InternalFireComponent } from './CalculatorInfo/InternalFireComponent';
import { Dialog } from '../shared/DialogComponent/Dialog';
import { ExternalFireComponent } from './CalculatorInfo/ExternalFireComponent';
import { useDispatch } from 'react-redux';
import { toggleCalc, nextSection, setExternalFire, setInternalFire } from '../../reducers/calculatorReducer';
import { ToggleButtonComponent } from '../shared/ToggleButtonComponent/ToggleButtonComponent';
import { useSelector } from 'react-redux';
import { RootState } from './../../store';
import { EXTERNAL_FIRE, INTERNAL_FIRE } from '../constants/CALCULATOR_VALUES';


export const FireRatingComponent = () => {
  const { setDialog, setShowDialog } = useContext(ApplicationContext);
  const dispatch = useDispatch();
  const selectInternalFire = (state: RootState) => state.calculator.fireRatingState.internalFireRating;
  const selectExternalFire = (state: RootState) => state.calculator.fireRatingState.externalFireRating;
  const internalFire = useSelector(selectInternalFire);
  const externalFire = useSelector(selectExternalFire);
  const showModal = (selectedIndex: number) => {
    // console.log({ selectedIndex });
    setDialog(
      <Dialog label={selectedIndex === 1 ? 'Internal Fire' : 'Exterior Fire'}>
        {selectedIndex === 1 ? <InternalFireComponent /> : <ExternalFireComponent />}
      </Dialog>
    );
    setShowDialog(true);
  };

  return (
    <div className={'px-4'}>
      <Typography className={'headline-1 py-5'} color={theme.palette.rnSteel.steel700}>
        Fire Ratings
      </Typography>
      <Typography className={'large-para pb-4'}>
        Roofing assemblies are rated for interior and exterior fire resistance.
      </Typography>
      <Typography className={'large-para pb-4'}>
        <b>Internal fire rating</b> indicates the level of fire resistance a roofing assembly has when exposed to fire
        on its underside, from within the structure. FM Approved assemblies are rated Class 1 or Non-combustible. Class
        2 assemblies are not FM Approved because they propagate fire on the deck underside.
      </Typography>
      <Typography className={'large-para pb-5'}>
        <b>Exterior fire rating</b> indicates the level of fire resistance a roofing assembly has when exposed to fire
        on its top or sides, external to the structure. FM Approved assembles are rated Class A, Class B, or Class C.
        Class A is preferred in all cases, but Class B and Class C can be acceptable depending on other conditions.
      </Typography>
      <div className={'pt-5'}>

        <div className={'pb-4'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            Internal Fire
          </Typography>
          <div className={'row m-0 d-flex align-items-center'}>
            <ButtonGroupComponent className={'pb-2'} >
              <ToggleButtonComponent
                options={INTERNAL_FIRE}
                handleSelect={(value) => dispatch(setInternalFire(value))}
                selectedOption={internalFire}
              ></ToggleButtonComponent>
            </ButtonGroupComponent>
            <ToolTipComponent testId={'internalFireInfo'} content={'Click for more information'} click={() => showModal(1)} />
          </div>
        </div>
        <div className={'pb-4'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            External Fire
          </Typography>
          <div className={'row m-0 d-flex align-items-center'}>
            <ButtonGroupComponent className={'pb-2'} >
              <ToggleButtonComponent
                options={EXTERNAL_FIRE}
                handleSelect={(value) => dispatch(setExternalFire(value))}
                selectedOption={externalFire}
              ></ToggleButtonComponent>
            </ButtonGroupComponent>
            <ToolTipComponent testId={'externalFireInfo'} content={'Click for more information'} click={() => showModal(2)} />
          </div>
        </div>

      </div>
      <div className={'row m-0 py-3'}>
        <ButtonComponent
          id='previousSection'
          buttonType={'secondary'}
          buttonText={'Previous'}
          click={() => dispatch(toggleCalc(false))}
          buttonStyle={'ml-0'}
        />
        <div className={'mx-1'}>&nbsp;</div>
        <ButtonComponent
          id='nextSection'
          buttonType={'primary'}
          buttonText={'Next'}
          click={() => dispatch(nextSection())}
          buttonStyle={''}
        />
      </div>
    </div>
  );
};
