import React, { useContext } from 'react';
import { AllowedValues, Section, VariableAssignment } from '../../types/configurator';
import { ApplicationContext } from '../ApplicationProvider';
import { ButtonGroupComponent } from '../shared/ButtonGroupComponent/ButtonGroupComponent';
import { ConfigitButtonGroupComponent } from '../shared/ConfigitButtonGroup/ConfigitButtonGroupComponent';
import { ConfigitCheckbox } from '../shared/ConfigitCheckbox';
import { SelectBox } from '../shared/SelectComponent/SelectBox';
import { ConfigitTextField } from '../shared/TextFieldComponent/ConfigitTextField';
import { ToolTipComponent } from '../shared/TooltipComponent/ToolTipComponent';

export interface IConfigitForm {
  variableAssignments: VariableAssignment[];
  setVariableAssignments: (variableAssignments: VariableAssignment[]) => void;
  section: Section;
  limit?: number;
  getVariableAt?: number;
}

export const formSelect = (
  variableId: string,
  value: string | string[] | AllowedValues[],
  variableAssignments: VariableAssignment[],
  setVariableAssignments: (variableAssignments: VariableAssignment[]) => void,
  setLoading: (loading: boolean) => void
) => {
  setLoading(true);
  const newVariableAssignments = variableAssignments.filter(
    variableAssignment => variableAssignment.variableId !== variableId
  );
  if (typeof value === 'string')
    newVariableAssignments.push({ variableId, value });
  else if (typeof value[0] === 'string')
    newVariableAssignments.push({
      variableId,
      type: 'AllowedValues',
      allowedValues: value.map(val => { return { type: 'SingletonValue', value: String(val) } })
    });
  else
    newVariableAssignments.push({
      variableId,
      type: 'AllowedValues',
      allowedValues: value.map(data => data as AllowedValues)
    });
  setVariableAssignments(newVariableAssignments);
};

export const formUnSelect = (
  variableId: string,
  variableAssignments: VariableAssignment[],
  setVariableAssignments: (variableAssignments: VariableAssignment[]) => void,
  setLoading: (loading: boolean) => void
) => {
  setLoading(true);
  const newVariableAssignments = variableAssignments.filter(
    (variableAssignment) => variableAssignment.variableId != variableId
  );
  setVariableAssignments(newVariableAssignments);
};

export const ConfigitForm: React.FC<IConfigitForm> = ({
  variableAssignments,
  setVariableAssignments,
  section,
  limit,
  getVariableAt
}: IConfigitForm) => {
  const { setLoading } = useContext(ApplicationContext);
  const select = (variableId: string, value: string | AllowedValues[]) => formSelect(variableId, value, variableAssignments, setVariableAssignments, setLoading);
  const unSelect = (variableId: string) => formUnSelect(variableId, variableAssignments, setVariableAssignments, setLoading);
  const checkPV = (value: string) => {
    const isPVActive = variableAssignments.find((data) => data.variableId === value)
    return isPVActive?.value
  }

  return (
    <>
      {section.variables?.map((variable, index) => {
        if (limit && index === limit) return;
        if (getVariableAt && index !== getVariableAt) return;
        const { id, name, properties, values } = variable;
        const last = section.variables && index === section.variables.length - 1;
        const isPVIndex: number[] = [12, 13, 14, 15]
        if (values && properties && properties[0] && properties[0].values && properties[0].values[0]) {
          const { disabled, type, className, tip, rangeValues } = properties[0].values[0];
          let formGroup;
          if (type === 'Boolean') {
            formGroup = (
              <ConfigitCheckbox
                id={id}
                label={name}
                options={values}
                select={select}
                unSelect={unSelect}
              />
            );
          }
          else if (type === 'Range') {
            formGroup = (
              <>
                <ButtonGroupComponent id={id} label={name} >
                  {/* add in case for select */}
                  <ConfigitButtonGroupComponent
                    id={id}
                    label={name}
                    options={values}
                    select={select}
                    unSelect={unSelect}
                    className={'buttonSelect'}
                  />
                </ButtonGroupComponent>
                <SelectBox
                  id={`NavAssembly_${id?.replace('Operator', '')}_view`}
                  className="ac-slope-operator col px-0 assembly-select as-1"
                  options={rangeValues}
                  select={select}
                  unSelect={unSelect}
                />
              </>
            );
          } else if (values.length > 100) {
            formGroup = <ConfigitTextField
              id={id}
              label={name}
              options={values}
              select={select}
              unSelect={unSelect}
            />;
          } else if (values.length > 3) {
            formGroup = (
              <SelectBox
                id={id}
                label={name}
                disabled={disabled}
                options={values}
                select={select}
                unSelect={unSelect}
                className={'assembly-select as-2'}
              />
            );
          }
          else {
            formGroup = (<ButtonGroupComponent id={id} label={name} buttonGroupStyle={className ? className : 'w-100'}>
              <ConfigitButtonGroupComponent
                id={id}
                label={name}
                options={values}
                select={select}
                unSelect={unSelect}

              />
            </ButtonGroupComponent>
            );
          }
          return (
            <div key={index} className={`form-group ${!last && index === 0 ? 'mt-2' : ''} ${last ? 'mb-0' : ''} ${!checkPV("IsPVEnabled") && isPVIndex.includes(index) ? 'd-none' : ''}`}>
              <div className="d-flex align-items-center">
                {formGroup}
                <div className="align-items-center">{tip && <ToolTipComponent content={tip} position={"right"} />}</div>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};
