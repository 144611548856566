import { Property } from '../../types/configurator';

interface IRecordType {
  label: string;
  tableName?: string;
  colName?: string;
  properties?: Property[];
  addFilters?: any;
}

export const CONFIGIT_MAP: Record<string, IRecordType> = {
  C_AssemblyType_AssemblyTypeId: {
    label: 'Roof System',
    tableName: 'AssemblyType',
    properties: [
      {
        id: 'extraDetails',
        values: [
          {
            tip: "A roof system a system of interacting roof components (not including the roof deck) designed to weatherproof and, normally, to insulate a building's top surface.",
          },
        ],
      },
    ],
  },
  C_AssemblyApplicationType_AssemblyApplicationTypeId: {
    label: 'Application',
    tableName: 'AssemblyApplicationType',
    properties: [
      {
        id: 'extraDetails',
        values: [
          {
            tip: `<ul className='pl-2 mb-0'>
                            <li>New Roof: A roof, including its deck, is newly built as part of a new building. Note: Reroof construction may include New Roof options</li> 
                            <li>Reroof: Re-roof roof construction requires stripping the existing roof system, consisting of all membranes, insulations (see exceptions for lightweight insulating concrete [LWIC] and steel deck), fasteners, etc., down to the bare substrate (i.e. steel deck, structural concrete or wood deck). In all cases the existing deck must be sound.  </li>
                            <li>Recover: Recover roof constructions over existing FM Approved Roof: The new components are installed over the existing roof cover. If more than one existing roof is present, the existing components should be removed. (see Re-Roof) </li>
                        </ul>`,
          },
        ],
      },
    ],
  },
  C_CoverSecurementMethod_CoverSecurementMethodId: {
    label: 'Cover Securement',
    tableName: 'CoverSecurementMethod',
    properties: [
      {
        id: 'extraDetails',
        values: [
          {
            tip: 'Cover securement is the manner in which a roof cover (multi-ply or single ply) or base sheet is secured to the substrate(s) below.  There are two main types of securement: adhered and fastened ',
          },
        ],
      },
    ],
  },
  C_DeckType_DeckTypeId: {
    label: 'Deck Type',
    tableName: 'DeckType',
    properties: [
      {
        id: 'extraDetails',
        values: [
          {
            tip: 'The deck is the structural component of the roof assembly to which the roof system is secured.',
          },
        ],
      },
    ],
  },
  SlopeOperator: {
    label: 'Slope Filter',
    colName: 'Slope',
    properties: [
      {
        id: 'extraDetails',
        values: [
          {
            type: 'Range',
            tip: 'Roof slope is the angle a roof surface makes with the horizontal, expressed as a ratio of the units of vertical rise to 12 units of horizontal length (sometimes referred to as run). 	When choosing a slope for an Assembly Search, select the number of units for the vertical rise. The slope associated with each assembly is the maximum slope for that assembly. ',
          },
        ],
      },
    ],
  },
  WindUpliftOperator: {
    label: 'Wind Uplift',
    colName: 'WindUplift',
    properties: [
      {
        id: 'extraDetails',
        values: [
          {
            type: 'Range',
            tip: 'Wind uplift is the force generated by wind on a roof system or components in a roof system resulting from wind-induced pressure. The Wind Uplift rating determined by the Ratings Calculator is the result of the calculated velocity pressure multiplied by 2.0 and rounded up to the nearest multiple of 15 psf. This is the Windstorm Classification for the field of the roof only. An FM Approved roof assembly with the same or higher Windstorm Classification is required.',
          },
        ],
      },
    ],
  },
  C_IntFireRating_IntFireRatingId: {
    label: 'Internal Fire',
    tableName: 'IntFireRating',
    properties: [
      {
        id: 'extraDetails',
        values: [
          {
            className: 'col-5 p-0',
            tip: 'Internal fire rating indicates the level of fire resistance a roofing assembly has when exposed to fire on its underside, from within the structure. FM Approved assemblies are rated Class 1 or Non-combustible. Class 2 assemblies are not FM Approved because they propagate fire on the deck underside.	 ',
          },
        ],
      },
    ],
  },
  C_ExtFireRating_ExtFireRatingId: {
    label: 'External Fire',
    tableName: 'ExtFireRating',
    properties: [
      {
        id: 'extraDetails',
        values: [
          {
            className: 'col-7 p-0',
            tip: 'Exterior fire rating indicates the level of fire resistance a roofing assembly has when exposed to fire on its top or sides, external to the structure. FM Approved assembles are rated Class A, Class B, or Class C. Class A is preferred in all cases, but Class B and Class C can be acceptable depending on other conditions. These categories correspond to ASTM E108 (04) fire ratings.',
          },
        ],
      },
    ],
  },
  C_HailRating_HailRatingId: {
    label: 'Hail',
    tableName: 'HailRating',
    properties: [
      {
        id: 'extraDetails',
        values: [
          {
            className: 'col-7 p-0',
            tip: 'Each FM Approved roof construction is rated for resistance to hail impact. All FM Approved roofs meet one of the following levels of resistance: Moderate Hail (MH), Severe Hail (SH), or Very Severe Hail (VSH).',
          },
        ],
      },
    ],
  },
  Manufacturer_ManufacturerId: {
    label: 'Manufacturer',
    addFilters: (filters: any, manufacturerId: number) => {
      // if(filters.NavLayers) filters.NavLayers.NavUsages.Usage.Component.ManufacturedProduct.ManufacturerId = {_eq: manufacturerId};
      // else filters.NavLayers = {NavUsages: {Usage: {Component: {ManufacturedProduct: {ManufacturerId: {_eq: manufacturerId}}}}}};
      if (filters.SearchNavAssembly_ManufacturerProducts)
        filters.SearchNavAssembly_ManufacturerProducts.ManufacturerId = { _eq: manufacturerId };
      else filters.SearchNavAssembly_ManufacturerProducts = { ManufacturerId: { _eq: manufacturerId } };
      return filters;
    },
    properties: [
      {
        id: 'extraDetails',
        values: [{}],
      },
    ],
  },
  ManufacturedProduct_ComponentId: {
    label: 'Product',
    addFilters: (filters: any, productId: number) => {
      // if(filters.NavLayers) filters.NavLayers.NavUsages.Usage.Component.ManufacturedProduct.ComponentId = {_eq: productId};
      // else filters.NavLayers = {NavUsages: {Usage: {Component: {ManufacturedProduct: {ComponentId: {_eq: productId}}}}}};
      if (filters.SearchNavAssembly_ManufacturerProducts)
        filters.SearchNavAssembly_ManufacturerProducts.ComponentId = { _eq: productId };
      else filters.SearchNavAssembly_ManufacturerProducts = { ComponentId: { _eq: productId } };
      return filters;
    },
    properties: [
      {
        id: 'extraDetails',
        values: [{}],
      },
    ],
  },
  NavLayer_ExcludeLayerTypeId: {
    label: 'Exclude these layers',
    // tableName: 'LayerType',
    properties: [
      {
        id: 'extraDetails',
        values: [{}],
      },
    ],
  },
  C_LayerType_LayerTypeId: {
    label: 'Layer Type',
    // tableName: 'LayerType',
    properties: [
      {
        id: 'extraDetails',
        values: [{}],
      },
    ],
  },
  C_PVApplicationMethod_PVApplicationMethodId: {
    label: 'PV Application Method',
    tableName: 'PVApplicationMethod',
    properties: [
      {
        id: 'extraDetails',
        values: [
          {
            tip: `<ul className='pl-2 mb-0'>
                            <li>New Roof: A roof, including its deck, is newly built as part of a new building. Note: Reroof construction may include New Roof options</li> 
                            <li>Reroof: Re-roof roof construction requires stripping the existing roof system, consisting of all membranes, insulations (see exceptions for lightweight insulating concrete [LWIC] and steel deck), fasteners, etc., down to the bare substrate (i.e. steel deck, structural concrete or wood deck). In all cases the existing deck must be sound.  </li>
                            <li>Recover: Recover roof constructions over existing FM Approved Roof: The new components are installed over the existing roof cover. If more than one existing roof is present, the existing components should be removed. (see Re-Roof) </li>
                        </ul>`,
          },
        ],
      },
    ],
  },
  C_PVHailRating_PVHailRatingId: {
    label: 'PV Hail Rating',
    tableName: 'PVHailRating',
    properties: [
      {
        id: 'extraDetails',
        values: [
          {
            tip: 'Each FM Approved roof construction is rated for resistance to hail impact. All FM Approved roofs meet one of the following levels of resistance: Moderate Hail (MH), Severe Hail (SH), or Very Severe Hail (VSH).',
          },
        ],
      },
    ],
  },
  PVWindUpliftOperator: {
    label: 'PV Wind Uplift',
    colName: 'PVWindUplift',
    properties: [
      {
        id: 'extraDetails',
        values: [
          {
            type: 'Range',
            tip: 'Wind uplift is the force generated by wind on a roof system or components in a roof system resulting from wind-induced pressure. The Wind Uplift rating determined by the Ratings Calculator is the result of the calculated velocity pressure multiplied by 2.0 and rounded up to the nearest multiple of 15 psf. This is the Windstorm Classification for the field of the roof only. An FM Approved roof assembly with the same or higher Windstorm Classification is required.',
          },
        ],
      },
    ],
  },
  IsPVEnabled: {
    label: 'Is PV Enabled',
    colName: 'IncludesPV',
    properties: [
      {
        id: 'extraDetails',
        values: [{
          type: 'Boolean',
          tip: 'Select this option to limit your search to assemblies containing Photovoltaic (PV) Approvals and to filter on PV specific ratings',
        }],
      },
    ],
  },
};
