import React, { useContext } from 'react';
import theme from '../../../theme';
import { Box, Typography } from '@mui/material';

export const EnclosureClassificationComponent = () => {
  const sectionA =
    'If the roof area has a monolithic deck such as cast-in-place concrete or gypsum, select “Enclosed” for classification. Otherwise determine the classification by considering if the building is exposed to windborne debris, and if the building is adequately protected against windborne debris.';
  const sectionB =
    'The total area of openings in a wall that receives positive external pressure exceeds 4 sq ft (0.37 sq m) or 1% of the area of that wall, whichever is smaller, and the percentage of openings in the balance of the building envelope does not exceed 20%.';
  const sectionC =
    'Buildings in a tropical storm-prone region that either meet criteria 1 and 2 above, or have wall cladding, or wall opening protectives that are inadequate for design wind pressures or windborne debris exposure (if applicable), are also considered partially enclosed.';
  return (
    <Box>
      <Typography className={'standard-para pb-3'}>{sectionA}</Typography>
      <Typography className={'standard-para pb-3'}>
        A building not within a tropical-cyclone pro ne area that complies with <b>both</b> of the following conditions
        is considered a “Partially Enclosed” building:
      </Typography>
      <ol className="pl-4">
        <li className={'standard-para pb-3 pl-0'}>
          The total area of openings in a wall that receives positive external pressure exceeds the sum of the area of
          openings in the balance of the building envelope (walls and roof) by more than 10%,
        </li>
        <Typography className={'standard-para pb-3'}>AND</Typography>
        <li className={'standard-para pb-3 pl-0'}>{sectionB}</li>
      </ol>
      <Typography className={'standard-para pb-3'}>{sectionC}</Typography>
      <Typography className={'standard-para pb-3'}>
        To be considered Enclosed, the walls, windows and doors need to be adequate for the expected wind pressures and
        openings need to be “evenly” distributed (i.e. not meeting the criteria above.)
      </Typography>
      <Typography className={'standard-para pb-3'}>
        For further details or ratings for open buildings, refer to DS 1-28.
      </Typography>
    </Box>
  );
};
