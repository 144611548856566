import { apolloClient } from '../ApolloClient';
import { ILayer } from '../components/ConfigitProvider';
import {
  AddUserReportBugDocument,
  AddUserReportBugMutation,
  AddUserReportBugMutationVariables,
  AddUserShareLinkDocument,
  AddUserShareLinkMutation,
  AddUserShareLinkMutationVariables,
  NavAssembly_Order_By,
  useAddUserReportBugMutation,
  UserReportBug_Insert_Input,
  UserShareLink_Insert_Input,
} from '../models/GQLGeneratedModels';
import { VariableAssignment } from '../types/configurator';
import { getConfigitCriteria } from './AssemblySearchAPIService';

export const getNewReportId = async (reason: string, extraNotes: string, userEmail: string, urlPath: string) => {
  //const result = await addUserReportBug('test reason', 'test notes', 'test123@test.com');

  const userReportBug: UserReportBug_Insert_Input = {
    SearchCriteriaData: 'test search data',
    Reason: reason,
    ExtraNotes: extraNotes,
    UserEmail: userEmail,
    UrlPath: urlPath
  };
  const result = await insertUserReportBug(userReportBug);

  console.log(result);
};

export const getBugReportId = async (
  layers: ILayer[],
  variableAssignments: VariableAssignment[],
  excludedLayerTypeIds: string[],
  manufacturerProduct: VariableAssignment[],
  limit: number,
  offset: number,
  orderBy: NavAssembly_Order_By,
  reason: string,
  extraNotes: string,
  userEmail: string,
  urlPath: string
) => {
  const configitCriteria = getConfigitCriteria(
    layers,
    variableAssignments,
    excludedLayerTypeIds,
    manufacturerProduct,
    limit,
    offset,
    orderBy
  );

  const configitCriteriaString = JSON.stringify(configitCriteria);
  // const assemblySearchCriteria = getAPISearchCriteriaBasedOnFilters(
  //   layers,
  //   filters,
  //   excludedLayerTypeIds,
  //   manufacturerProduct,
  //   limit,
  //   offset,
  //   orderBy
  // );

  // const assemblySearchCriteriaString = JSON.stringify(assemblySearchCriteria);
  //console.log('assemblySearchCriteriaString is ', assemblySearchCriteriaString);

  const userReportBug: UserReportBug_Insert_Input = {
    SearchCriteriaData: btoa(configitCriteriaString),
    Reason: reason,
    ExtraNotes: extraNotes,
    UserEmail: userEmail,
    UrlPath: urlPath
  };

  const result = await insertUserReportBug(userReportBug);

  console.log(result);
};

export const addUserReportBug = async (reason: string, extraNotes: string, userEmail: string, UrlPath: string) => {
  const userReportBug: UserReportBug_Insert_Input = {
    SearchCriteriaData: 'test search data',
    Reason: reason,
    ExtraNotes: extraNotes,
    UserEmail: userEmail,
    
  };

  return useAddUserReportBugMutation({
    variables: {
      UserReportBug: userReportBug,
    },
  });
};

export const insertUserReportBug = async (userReportBug: UserReportBug_Insert_Input) => {
  const result = await apolloClient.mutate<AddUserReportBugMutation, AddUserReportBugMutationVariables>({
    mutation: AddUserReportBugDocument,
    variables: {
      UserReportBug: userReportBug,
    },
  });

  //return result;
  //return result.data?.insert_UserReportBug?.returning[0].ReportBugId;
  return result.data?.insert_UserReportBug?.returning[0].ReportBugGuid;
};

export const getShareLinkId = async (
  layers: ILayer[],
  variableAssignments: VariableAssignment[],
  excludedLayerTypeIds: string[],
  manufacturerProduct: VariableAssignment[],
  limit: number,
  offset: number,
  orderBy: NavAssembly_Order_By,
  userGuid: string
) => {
  const configitCriteria = getConfigitCriteria(
    layers,
    variableAssignments,
    excludedLayerTypeIds,
    manufacturerProduct,
    limit,
    offset,
    orderBy
  );

  const configitCriteriaString = JSON.stringify(configitCriteria);

  const userShareLink: UserShareLink_Insert_Input = {
    SearchCriteriaData: btoa(configitCriteriaString),
    UserGuid: userGuid,
  };

  const shareLinkGuid = await insertUserShareLink(userShareLink);
  return shareLinkGuid as string;
};

export const insertUserShareLink = async (userShareLink: UserShareLink_Insert_Input) => {
  const result = await apolloClient.mutate<AddUserShareLinkMutation, AddUserShareLinkMutationVariables>({
    mutation: AddUserShareLinkDocument,
    variables: {
      UserShareLink: userShareLink,
    },
  });

  return result.data?.insert_UserShareLink?.returning[0].ShareLinkGuid;
};
