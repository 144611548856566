import React from 'react';
import { useGetFastenerUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { Area } from '../../utils/Measurement/Area';
import { Length } from '../../utils/Measurement/Length';
import './UsageDetails.scss';

export const FastenerUsageDetail = (props: any) => {
  const { data, loading, error } = useGetFastenerUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];

  return (
    <div className={'usage-detail p-3'}>
      <h4>Fastening (Fastener)</h4>
      <table>
        <tr>
          <td>Material:</td>
          <td>{usage?.Component?.ManufacturedProduct?.Fastener?.C_SecurementMaterial.SecurementMaterialCode}</td>
        </tr>
        <tr>
          <td>Row Spacings:</td>
          <td>{Length.display(usage?.FastenerUsage?.RowSpacing, usage?.EnteredAsMetric)}</td>
        </tr>
        <tr>
          <td>On Center:</td>
          <td>{Length.display(usage?.FastenerUsage?.OnCenter, usage?.EnteredAsMetric)}</td>
        </tr>
        <tr>
          <td>Field Rows:</td>
          <td>{usage?.FastenerUsage?.IsFieldRows ? 'Yes' : 'No'}</td>
        </tr>
        <tr>
          <td>Field Row On Center:</td>
          <td>{Length.display(usage?.FastenerUsage?.FieldRowsOnCenter, usage?.EnteredAsMetric)}</td>
        </tr>
        <tr>
          <td>Fastener Diameter:</td>
          <td>{Length.display(usage?.Component?.ManufacturedProduct?.Fastener?.Diameter, usage?.EnteredAsMetric)}</td>
        </tr>
        <tr>
          <td>Number of Field Rows:</td>
          <td>{usage?.FastenerUsage?.NumFieldRows}</td>
        </tr>
        <tr>
          <td>Min Length:</td>
          <td>{Length.display(usage?.Component?.ManufacturedProduct?.Fastener?.LengthMin, usage?.EnteredAsMetric)}</td>
        </tr>
        <tr>
          <td>Application Method:</td>
          <td>{usage?.FastenerUsage?.C_FasteningApplicationMethod.FasteningApplicationMethodCode}</td>
        </tr>
        <tr>
          <td>Max Length:</td>
          <td>{Length.display(usage?.Component?.ManufacturedProduct?.Fastener?.LengthMax, usage?.EnteredAsMetric)}</td>
        </tr>
        <tr>
          <td>Embedment:</td>
          <td>{Length.display(usage?.FastenerUsage?.Embedment, usage?.EnteredAsMetric)}</td>
        </tr>

        <tr>
          <td>Contributory Area:</td>
          <td>{Area.display(usage?.FastenerUsage?.ContributoryArea, usage?.EnteredAsMetric)}</td>
        </tr>
        <tr>
          <td>Steel Deck Fastener:</td>
          <td>{usage?.Component?.ManufacturedProduct?.Fastener?.IsSteelDeckFastener ? 'Yes' : 'No'}</td>
        </tr>

        <tr>
          <td>Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
