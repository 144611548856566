import React from "react";
import './Loading.scss';
export const Loading = () => {
    // empty divs are for css animation stages- see https://loading.io/css/
    return (
        <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}