import { Table, TableBody, TableHead, TableRow, Typography, styled } from '@mui/material';
import React, { HTMLAttributes } from 'react';
import theme from '../../../theme';
import { PSF_KSF_CONVERSION_CONSTANT, SUMMARY_COLUMNS } from '../../constants/CALCULATOR_VALUES';
import { StyledTableCell } from '../../shared/TableComponent/TableComponent';
import { IRoofZoneResult, getZoneNameByValue } from './SummaryComponent';

interface ISummaryRatingsProps extends HTMLAttributes<HTMLDivElement> {
    calcResult: IRoofZoneResult[];
}
const CalculatorTableRow = styled(TableRow)({
    '&.MuiTableRow-root:nth-of-type(odd)': {
        backgroundColor: theme.palette.rnMisc.white
    },
    '&.MuiTableRow-root:nth-of-type(even)': {
        backgroundColor: theme.palette.rnGrey.grey50
    },
});
export const SummaryRatings: React.FC<ISummaryRatingsProps> = ({ calcResult }: ISummaryRatingsProps) => {

    const returnGutter = (zone: number, copingRating: number) => {
        // allowed zones are Zone 2, Zone 3, Zone 2E, Zone 3E, Zone 2N, Zone 3R
        const allowZones = [2, 3, 5, 6, 7, 9];
        const result = allowZones.find((objZone) => {
            return objZone === zone;
        });
        if (result && copingRating !== null || undefined) return `1-${copingRating}`;
        else return '';
    };
    const psfToKPA = (value: number) => {
        return value * PSF_KSF_CONVERSION_CONSTANT;
    }

    return (
        <div className={'ratings-table py-4'}>
            <Table>
                <TableHead>
                    <CalculatorTableRow>
                        {SUMMARY_COLUMNS.map((column, index) => {
                            return (
                                <StyledTableCell variant={'head'}
                                    key={index}

                                    data-field="assemblynum"
                                >
                                    <Typography className={'modal-info-heading'}>
                                        {column}
                                    </Typography>
                                </StyledTableCell>
                            )
                        })}
                    </CalculatorTableRow>
                </TableHead>
                <TableBody>
                    {calcResult.map((zone, index) => {
                        return (
                            <CalculatorTableRow key={index}>
                                <StyledTableCell>
                                    <Typography className={'modal-info-bold-text'}>
                                        {getZoneNameByValue(zone.pressureZone)}
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Typography className={'modal-info-text'}>
                                        {Math.floor(zone.pressure)} psf ({psfToKPA(zone.pressure).toString().substring(0, 3)} kPa)
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Typography className={'modal-info-text'}>
                                        {zone.rating ? `1-${zone.rating}` : ''}
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Typography className={'modal-info-text'}>
                                        {zone.fasciaRating ? `1-${zone.fasciaRating}` : ''}
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Typography className={'modal-info-text'}>
                                        {zone.copingRating ? `1-${zone.copingRating}` : ''}
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Typography className={'modal-info-text'}>
                                        {returnGutter(zone.pressureZone, zone.copingRating)}
                                    </Typography>
                                </StyledTableCell>
                            </CalculatorTableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
}