import { SingletonValue } from '../../types/configurator';

interface ILayerType {
  id: string;
  name: string;
  layers: SingletonValue[];
}

export const LAYER_DATA: ILayerType[] = [
    {
        id: '11111',
        name: 'Covers',
        layers: [
            {
                value: '01',
                name: 'Cover - Composite Panel',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'CoverCompositePanel',
                                hasDuplicates: true,
                                basicAttributes: ['InsulationCoreId', 'WidthMax']
                            }
                        ]
                    }
                ]
            },
            {
                value: '06',
                name: 'Cover - Liquid Applied (Base Coat)',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'BaseCoat',
                                basicAttributes: ['LiquidAppliedMaterialId']
                            }
                        ]
                    }
                ]
            },
            {
                value: '07',
                name: 'Cover - Liquid Applied (Coat)',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'Coat',
                                hasDuplicates: true,
                                basicAttributes: ['LiquidAppliedMaterialId']
                            }
                        ]
                    }
                ]
            },
            {
                value: '08',
                name: 'Cover - Liquid Applied (Top Coat)',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'TopCoat',
                                basicAttributes: ['LiquidAppliedMaterialId']
                            }
                        ]
                    }
                ]
            },
            {
                value: '10',
                name: 'Cover - Multi-ply (Cap Ply)',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'CapPly',
                                basicAttributes: ['MultiplyMaterialId', 'BackingMaterialId', 'ReinforcementId', 'SurfaceTypeId']
                            }
                        ]
                    }
                ]
            },
            {
                value: '11',
                name: 'Cover - Multi-ply (Ply)',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'Ply',
                                hasDuplicates: true,
                                basicAttributes: ['MultiplyMaterialId', 'ReinforcementId', 'NumPlies']
                            }
                        ]
                    }
                ]
            },
            {
                value: '09',
                name: 'Cover - Multi-ply (Base Ply)',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'BasePly',
                                basicAttributes: ['MultiplyMaterialId', 'BackingMaterialId', 'ReinforcementId']
                            }
                        ]
                    }
                ]
            },
            {
                value: '04',
                name: 'Cover - Single-ply',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'CoverSingleply',
                                basicAttributes: ['SinglePlyMaterialId', 'BackingMaterialId']
                            }
                        ]
                    }
                ]
            },
            {
                value: '05',
                name: 'Cover - Standing/Lap Seam',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'CoverStandingLapSeam',
                                basicAttributes: ['StandingLapSeamMaterialId', 'WidthMax']
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: '11113',
        name: 'Insulation',
        layers: [
            {
                value: '24',
                name: 'Cover Board',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'CoverBoard',
                                basicAttributes: ['CoverBoardMaterialId']
                            }
                        ]
                    }
                ]
            },
            {
                value: '27',
                name: 'Insulation - Batt/Blanket',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'InsulationBattBlanket',
                                basicAttributes: ['BattBlanketFacerMaterialId']
                            }
                        ]
                    }
                ]
            },
            {
                value: '28',
                name: 'Insulation - Board Stock',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'InsulationBoardStock',
                                hasDuplicates: true,
                                basicAttributes: ['BoardStockMaterialId']
                            }
                        ]
                    }
                ]
            },
            {
                value: '30',
                name: 'Insulation - Lightweight Insulating Concrete',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'LightweightInsulatingConcrete',
                                hasDuplicates: true,
                                basicAttributes: ['MinCompressiveStrength', 'CastDensityMin']
                            }
                        ]
                    }
                ]
            },
            {
                value: '29',
                name: 'Insulation - Spray Foam',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'InsulationSprayFoam',
                                basicAttributes: ['InsulationFoamTypeId', 'ThicknessMax', 'DensityMax']
                            }
                        ]
                    }
                ]
            },
            {
                value: '36',
                name: 'Thermal Barrier',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'ThermalBarrier',
                                hasDuplicates: true,
                                basicAttributes: ['ThermalBarrierMaterialId']
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: '11115',
        name: 'Sheets',
        layers: [
            {
                value: '21',
                name: 'Air Retarder',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'AirRetarder',
                                basicAttributes: ['RetarderMaterialId']
                            }
                        ]
                    }
                ]
            },
            {
                value: '22',
                name: 'Base Sheet',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'BaseSheet',
                                hasDuplicates: true,
                                basicAttributes: ['MultiplyMaterialId', 'BackingMaterialId', 'ReinforcementId']
                            }
                        ]
                    }
                ]
            },
            {
                value: '34',
                name: 'Separator',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'SeparatorSheet',
                                basicAttributes: ['RetarderMaterialId']
                            }
                        ]
                    }
                ]
            },
            {
                value: '37',
                name: 'Vapor Retarder',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'VaporRetarder',
                                hasDuplicates: true,
                                basicAttributes: ['RetarderMaterialId']
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: '11112',
        name: 'Decking',
        layers: [
            {
                value: '12',
                name: 'Deck - Cementitious Panel',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'DeckCementitiousPanel',
                                basicAttributes: ['SpanMax']
                            }
                        ]
                    }
                ]
            },
            {
                value: '14',
                name: 'Deck - Fiber Reinforced Plastic',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: '--NA--',
                                basicAttributes: []
                            }
                        ]
                    }
                ]
            },
            {
                value: '17',
                name: 'Deck - Steel',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'DeckSteel',
                                basicAttributes: ['IsAcoustical', 'SteelDeckRibTypeId', 'ThicknessMin', 'SpanMax', 'GradeMin']
                            }
                        ]
                    }
                ]
            },
            {
                value: '18',
                name: 'Deck - Structural Concrete',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: '--NA--',
                                basicAttributes: []
                            }
                        ]
                    }
                ]
            },
            {
                value: '19',
                name: 'Deck - Treated Wood',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: '--NA--',
                                basicAttributes: []
                            }
                        ]
                    }
                ]
            },
            {
                value: '25',
                name: 'Existing Roof',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'ExistingRoof',
                                basicAttributes: ['CExistingRoofCover_ExistingRoofCoverId', 'CExistingRoofDeck_ExistingRoofDeckId']
                            }
                        ]
                    }
                ]
            },
            {
                value: '26',
                name: 'Form Deck',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'FormDeck',
                                basicAttributes: ['SpanMax']
                            }
                        ]
                    }
                ]
            },
            {
                value: '31',
                name: 'Liner Panel',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'LinerPanel',
                                basicAttributes: ['LinerPanelMaterialId']
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: '11114',
        name: 'Surface Treatments',
        layers: [
            {
                value: '23',
                name: 'Coating/Surfacing',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'CoatingSurfacing',
                                hasDuplicates: true,
                                basicAttributes: []
                            }
                        ]
                    }
                ]
            },
            {
                value: '33',
                name: 'Substrate Primer',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'SubstratePrimer',
                                hasDuplicates: true,
                                basicAttributes: []
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: '11118',
        name: 'Securement',
        layers: [
            {
                value: '41',
                name: 'Securement (Base Sheet)',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'SecurementBaseSheet',
                                hasDuplicates: true,
                                basicAttributes: []
                            }
                        ]
                    }
                ]
            },
            {
                value: '42',
                name: 'Securement (Board Stock)',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'SecurementBoardStock',
                                hasDuplicates: true,
                                basicAttributes: []
                            }
                        ]
                    }
                ]
            },
            {
                value: '39',
                name: 'Securement (Cover)',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'SecurementCover',
                                hasDuplicates: true,
                                basicAttributes: []
                            }
                        ]
                    }
                ]
            },
            {
                value: '43',
                name: 'Securement (Deck Lap)',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: '--NA--',
                                basicAttributes: []
                            }
                        ]
                    }
                ]
            },
            {
                value: '44',
                name: 'Securement (Deck)',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'SecurementDeck',
                                hasDuplicates: true,
                                basicAttributes: []
                            }
                        ]
                    }
                ]
            },
            {
                value: '40',
                name: 'Securement (Sheet Lap)',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'SecurementSheet',
                                basicAttributes: []
                            }
                        ]
                    }
                ]
            },
            {
                value: '45',
                name: 'Securement',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'Securement',
                                hasDuplicates: true,
                                basicAttributes: []
                            }
                        ]
                    }
                ]
            },
            {
                value: '51',
                name: 'Self-Securement',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: '--NA--',
                                basicAttributes: []
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: '11117',
        name: 'Miscellaneous',
        layers: [
            {
                value: '38',
                name: 'Cover - Strip/Disk',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'CoverStripDisk',
                                hasDuplicates: true,
                                basicAttributes: ['SinglePlyMaterialId']
                            }
                        ]
                    }
                ]
            },
            {
                value: '35',
                name: 'Structure',
                properties: [
                    {
                        id: 'extraDetails',
                        values: [
                            {
                                model: 'Structure',
                                basicAttributes: ['CStructureType_StructureTypeId']
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

export const getLayerData = (): ILayerType[] => {
  return LAYER_DATA.map((layerType) => {
    return {
      id: layerType.id,
      name: layerType.name,
      layers: layerType.layers.map((layer) => {
        return { ...layer };
      }),
    };
  });
};

export const getFlatLayerData = (): SingletonValue[] => {
  return LAYER_DATA.flatMap((layerType) => {
    return layerType.layers.map((layer) => {
      return { ...layer };
    });
  });
};

export const getLayerProps = (layerData?: SingletonValue) => {
  if (layerData?.properties && layerData?.properties[0].values && layerData?.properties[0].values[0].model)
    return layerData?.properties[0].values[0];
  return {};
};
