import { styled, ToggleButton, Typography } from '@mui/material';
import React from 'react';
import theme from '../../../theme';
import { ButtonGroupComponent } from '../ButtonGroupComponent/ButtonGroupComponent';
import './ButtonToggle.scss';

interface IButtonToggleProps {
  options: string[];
  selectedOption: string;
  className?: string;
  handleSelect: (option: string) => void;
}

const StyledToggle = styled(ToggleButton)(({ className }) => ({
  '&.MuiToggleButton-root:not(:first-of-type)': {
    borderColor: theme.palette.rnOrange.darkOrange,
    borderWidth: '1px'
  },
  '&:focus': {
    boxShadow: 'none !important',
    borderColor: theme.palette.rnOrange.darkOrange,
    outline: `2px solid ${theme.palette.rnOrange.darkOrange}`
  },
  '&.MuiToggleButton-root:first-of-type': {
    width: className?.includes('rooftype-select') ? '50%' : className?.includes('attribute-select') ? '40%' : '60%'
  },
  '&.MuiToggleButton-root:last-of-type': {
    width: className?.includes('rooftype-select') ? '50%' : className?.includes('attribute-select') ? '60%' : '40%'
  },
  '&.MuiToggleButton-root.Mui-selected:active': {
    backgroundColor: theme.palette.rnOrange.darkOrange,
    borderColor: theme.palette.rnOrange.darkOrange,
  },
  '&.MuiToggleButton-root.deselected': {
    backgroundColor: theme.palette.rnMisc.white,
    borderColor: theme.palette.rnOrange.darkOrange,
    color: theme.palette.rnGrey.grey600
  },
  '&.MuiToggleButton-root.deselected:active': {
    backgroundColor: theme.palette.rnMisc.white,
    borderColor: theme.palette.rnOrange.darkOrange,
    color: theme.palette.rnGrey.grey600
  },
  '&.MuiToggleButton-root.deselected:hover': {
    color: theme.palette.rnGrey.grey600
  },
  '&.MuiToggleButton-root.Mui-selected': {
    backgroundColor: theme.palette.rnOrange.darkOrange,
    borderColor: theme.palette.rnOrange.darkOrange,
    borderWidth: '1px',
    color: theme.palette.rnMisc.white,
    outline: 'none !important'
  },
}));

export const ButtonToggleComponent = ({ options, selectedOption, className, handleSelect }: IButtonToggleProps) => {
  return (
    <div className="ButtonToggle btn-group btn-group-toggle" data-toggle="buttons" role="group" aria-label="">
      {options.map((option, index) => (
        <StyledToggle
          key={index}
          disableRipple
          id={option}
          data-testid={option}
          value={option}
          className={` btn btn-primary col ${selectedOption === option ? 'Mui-selected' : 'deselected'
            } ${className}`}
          onClick={() => (option !== selectedOption ? handleSelect(option) : undefined)}
          aria-pressed="true"
        >
          <Typography fontSize={12} fontFamily={"Inter"} fontWeight={500} className={'text-nowrap'} >{option}</Typography>
        </StyledToggle>
      ))}
    </div>
  );
};
