import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import theme from '../../theme';
import { ApplicationContext } from '../ApplicationProvider';
import { IconCard } from '../shared/IconCard/IconCardComponent';
import { useNavigate } from 'react-router';
import './Landing.scss';

export const LandingComponent: React.FC = () => {
  const navigate = useNavigate();
  const upperBodyTextA =
    'FM Approvals welcomes you to RoofNav, your source for the latest information on FM Approved roofing assemblies and products.';
  const upperBodyTextB =
    ' provides an overview of all RoofNav search methods along with recommendations and guidance to maximize results.';
  const lowerBodyTextA =
    'To get started immediately, begin making your selections in the Filters area. You will notice your search results being parsed down as you select more attributes. If you have any questions or would like assistance completing successful searches, click on the ';
  const lowerBodyTextB = ' icon at the top of this page.';
  const filterText =
    'Find FM Approved Roof Assemblies that meet your requirements using Assembly Property and/or Roof Layer filtering.';
  const calculatorText = 'Use the Ratings Calculator to specify fire and hail ratings and calculate wind uplift.';
  const searchText = 'Search for products based on manufacturer, product name, category, and approved use.';
  const { setDefaultTab, showProductsUsed, setShowProductsUsed, setShowFilters, setShowRoofLayers, setShowCalc } =
    useContext(ApplicationContext);
  const params = new URLSearchParams(location.search);
  const checkRoute = (target: string) => {
    if (target === 'home' && window.location.href.includes('products')) {
      navigate('/home')
    }
    if (target === 'product' && !window.location.href.includes('products')) {
      navigate('/products')
    }
    if (target === 'calc') {
      navigate('/ratingsCalculator')
    }
  }

  return (
    <Box className={'h-100 px-4 landing'}>
      <div className={''}>
        <Typography color={theme.palette.rnText.primaryHeading} className={'headline-1 py-4'}>
          RoofNav Just Got Easier
        </Typography>
        <Typography className={'large-para'}>{upperBodyTextA}</Typography>
        <Typography className={'large-para py-4'}>
          {lowerBodyTextA}
          <span
            className={'d-inline-block icon-text'}
          >
            ?
          </span>
          {lowerBodyTextB}
        </Typography>
      </div>

      <div>
        <Typography color={theme.palette.rnText.primaryHeading} className={'headline-2 py-3'}>
          Quick Tips for Getting Started
        </Typography>
        <div className={'row mx-0'}>
          <div className={'py-4 pl-0 col-lg-4 col-md-12 col-sm-6 col-xs-12 iconcard'}>
            <IconCard
              id={'rncard-filter'}
              title={'Assembly Search'}
              iconName={'filter_alt'}
              body={filterText}
              click={() => {
                checkRoute('home');
                // opens filters tab and child accordions
                // setTimeout/2 separate calls are used here since modifying the URL params for each accordion at the same results in inconsistent accordion toggle
                setDefaultTab(0);
                setShowFilters(true);
                setTimeout(() => {
                  setShowRoofLayers(true);
                }, 600);
                setTimeout(() => {
                  setShowFilters(false);
                  setShowRoofLayers(false);
                }, 3000);
              }}
            />
          </div>
          <div className={'pl-0 py-4 col-lg-4 col-md-12 col-sm-6 col-xs-12 iconcard'}>
            <IconCard
              id={'rncard-search'}
              title={'Ratings Calculator'}
              iconName={'calculate'}
              body={calculatorText}
              click={() => {
                checkRoute('calc');
                // opens tools tab and child accordions
                setDefaultTab(1);
                setShowCalc(true);
                // setTimeout(() => {
                //   setShowProductsUsed(false);
                // }, 1000);
              }}
            />
          </div>
          <div className={'pl-0 py-4 col-lg-4 col-md-12 col-sm-6 col-xs-12 iconcard'}>
            <IconCard
              id={'rncard-search'}
              title={'Product Search'}
              iconName={'build'}
              body={searchText}
              click={() => {
                checkRoute('product');

              }}
            />
          </div>
        </div>
      </div>
    </Box>
  );
};
