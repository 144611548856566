import { RadioGroup, styled, Typography } from "@mui/material";
import React, { HTMLAttributes } from "react";
import { SingletonValue } from "../../../types/configurator";
import './../ConfigitButtonGroup/ConfigitButtonGroup.scss';
import { RadioComponent } from "../RadioComponent/RadioComponent";
import './RadioGroup.scss';

interface IRadioGroup extends HTMLAttributes<HTMLDivElement> {
    id?: string;
    options: SingletonValue[];
    showBorders?: boolean;
    select: (variableId: string, value: string) => void;
}

export const ConfigitRadioGroup = ({ id, options, showBorders, select, className, ...other }: IRadioGroup) => {

    const radioSelect = (option: SingletonValue) => {
        if (!option.incompatible) {
            select('', String(option.value));
        }
    }

    return (
        <RadioGroup className={"px-4"}>
            {options.map((option, index) => {
                return (
                    <RadioComponent
                        key={index}
                        click={() => radioSelect(option)}
                        name={"radioCovers"}
                        checked={option.assigned ? true : false}
                        value={option.value}
                        disabled={option?.incompatible}
                        label={option.name} />
                );
            })}
        </RadioGroup>
    );
};