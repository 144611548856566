import { Link, Table, TableBody, TableHead, TableRow, Typography, styled } from '@mui/material';
import React from 'react';
import theme from '../../theme';
import './RefMaterial.scss';

import { StyledTableCell } from '../../components/shared/TableComponent/TableComponent';

interface IDataSheetTable {
  approvalList: any;
  handleDocument: (docid: number) => void;
  isStandard: boolean;
}

export const DataSheetTable: React.FC<IDataSheetTable> = ({
  approvalList,
  handleDocument,
  isStandard,
}: IDataSheetTable) => {
  const getDate = (objectDate: Date) => {
    const today = new Date(objectDate);
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    return `${month}/${year}`;
  };
  // TODO: this should be using a heading tag, NOT a separate table row
  const GuideLineTableRow = styled(TableRow)({
    '&.MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: theme.palette.rnMisc.white,
    },
    '&.MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: theme.palette.rnStone.stone100,
    },
  });
  const TopTableRow = styled(TableRow)({
    '&.MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: theme.palette.rnSteel.steel500,
      color: theme.palette.rnMisc.white
    },
  });
  const GetTableHeading = () => {
    {
      if (isStandard) {
        return (
          <>
            <StyledTableCell className={'summary-bold-text-w'}>Approval Standard</StyledTableCell>
            <StyledTableCell className={'summary-bold-text-w'}>Title</StyledTableCell>
          </>
        );
      } else {
        return (
          <>
            <StyledTableCell className={'summary-bold-text-w'}>Data Sheet</StyledTableCell>
            <StyledTableCell className={'summary-bold-text-w'}>Purpose</StyledTableCell>
          </>
        );
      }
    }
  };
  return (
    <>
      <Table style={{ width: '100%', border: 1 }}>
        <TableBody>
          <TopTableRow>
            {GetTableHeading()}
            <StyledTableCell className={'summary-bold-text-w'}>Month Issued</StyledTableCell>
          </TopTableRow>
          {approvalList?.map((item: any) => (
            <GuideLineTableRow key={item.ApprovalsDocId}>
              <StyledTableCell className={'modal-info-bold-text'}>
                <Link className="doc-link" onClick={() => handleDocument(item.ApprovalsDocId)}>
                  {item.DocName}
                </Link>
              </StyledTableCell>
              <StyledTableCell className={'modal-info-bold-text'}>{item.Dsc}</StyledTableCell>
              <StyledTableCell className={'modal-info-bold-text'}>{getDate(item.DateIssued)}</StyledTableCell>
            </GuideLineTableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

