import React, { useContext } from 'react';
import { ConfigurationVariable } from '../../types/configurator';
import { ApplicationContext } from '../ApplicationProvider';
import { MeasureType } from '../constants/ENUMS';
import { EXCHANGE_VARIABLES } from '../constants/EXCHANGE_VARIABLES';
import { LAYER_MEASUREMENT_ATTRIBUTES, MEASURE_UNITS } from '../constants/MEASURE_UNITS';
import { ButtonToggleComponent } from '../shared/ButtonToggleComponent/ButtonToggleComponent';
import { SelectBox } from '../shared/SelectComponent/SelectBox';
import './LayerConfigurator.scss';
import { sortMeasurementHelper } from '../../pages/ProductSearch/ProductAttributeForm';

interface IProductAttributesFormProps {
  mode: string;
  setMode: (mode: string) => void;

  layerTypeId?: string | number;
  name?: string;
  layerProps: {
    [key: string]: any;
  };
  variables: ConfigurationVariable[];
  handleFormSelect: (variableId: string, value: string) => void;
  handleFormUnSelect: (variableId: string) => void;
  handleMeasureAttrOperatorChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const ProductAttributesForm = ({
  mode,
  setMode,
  layerTypeId,
  name,
  layerProps,
  variables,
  handleFormSelect,
  handleFormUnSelect,
  handleMeasureAttrOperatorChange,
}: IProductAttributesFormProps) => {
  const { unitSessionMode } = useContext(ApplicationContext);
  const StandardSecurementLayerTypeIds = ['39', '41', '42', '44', '45'];
  const isMeasurementAttribute = (layerTypeId: string, columnName: string) => {
    let result = false;
    // If current layer type is one of securement types, get measurement attributes information based on id 45
    // Otherwise retrieve measurement attributes information based on normal layer type id
    const layer = StandardSecurementLayerTypeIds.includes(layerTypeId)
      ? LAYER_MEASUREMENT_ATTRIBUTES.find((data) => data.layerTypeId === '45')
      : LAYER_MEASUREMENT_ATTRIBUTES.find((data) => data.layerTypeId === layerTypeId);
    if (layer) {
      result = layer.measurementAttributes.filter((a) => a.columnName === columnName).length > 0 ? true : false;
    } else {
      result = false;
    }
    return result;
  };

  /*  
    The following function getMeasurementType is for a measurement attribute which is identified by the above function isMeasurementAttribute
    No need to check the result of array find method
  */
  const getMeasurementType = (layerTypeId: string, columnName: string) => {
    // tempLayerTypeId is used for base layer type id for measurement attributes information retrieval
    // Determine tempLayerTypeId value based on current layer type is one of securement types or not
    const tempLayerTypeId = StandardSecurementLayerTypeIds.includes(layerTypeId) ? '45' : layerTypeId;
    return LAYER_MEASUREMENT_ATTRIBUTES.find(
      (data) => data.layerTypeId === tempLayerTypeId
    )?.measurementAttributes.find((a) => a.columnName === columnName)?.measureType;
  };

  /* 
    The following function is for select options of imperial/metric units. The sample usage is as below:
    <select id={id + 'Unit'} onChange={handleMeasureAttrUnitChange} className="custom-select">
      {getMeasureUnitOptions(measureAttrType)}
    </select>
  */
  const getMeasureUnitOptions = (measureTypeId: MeasureType) => {
    const iUnit = MEASURE_UNITS[measureTypeId].imperialUnit;
    const mUnit = MEASURE_UNITS[measureTypeId].metricUnit;
    return (
      <>
        <option value={iUnit}>{iUnit}</option>
        <option value={mUnit}>{mUnit}</option>
      </>
    );
  };

  const displayMeasureUnit = (measureTypeId: MeasureType) => {
    if (unitSessionMode === 'Metric') {
      return <span>&nbsp;&nbsp;{MEASURE_UNITS[measureTypeId].metricUnit}</span>;
    } else {
      return <span>&nbsp;&nbsp;{MEASURE_UNITS[measureTypeId].imperialUnit}</span>;
    }
  };

  const getMeasureUnit = (measureTypeId: MeasureType) => {
    if (unitSessionMode === 'Metric') {
      return MEASURE_UNITS[measureTypeId].metricUnit;
    } else {
      return MEASURE_UNITS[measureTypeId].imperialUnit;
    }
  };

  const showAttribute = (layerTypeId: string, attrId: string) => {
    return layerTypeId === '35' && attrId === 'StrengthMin' ? false : true;
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-end mb-5">
        <h4 className="mb-0">{name} Attributes</h4>
        <ButtonToggleComponent options={['Basic', 'Advanced']} selectedOption={mode} handleSelect={setMode} className='attribute-select' />
      </div>
      <div className="row px-3 attribute-form">
        {layerProps.basicAttributes.map((attr: string, index: number) => {
          const variable = variables.find((data) => data.id === attr);
          if (variable) {
            const { id, name, values } = variable;
            if (isMeasurementAttribute(layerTypeId as string, id as string)) {
              const measureAttrType = getMeasurementType(layerTypeId as string, id as string) as MeasureType;
              const operatorVariable = variables.find((data) => data.id === `${id}Operator`) as ConfigurationVariable;
              return (
                <div key={index} className="form-group col-md-6">
                  {values && operatorVariable.values && (
                    <>
                      <div className={'basic-selector row p-0 m-0'}>
                        <span>
                          <SelectBox
                            id={operatorVariable.id}
                            label={name}
                            options={operatorVariable.values}
                            select={handleFormSelect}
                            className={'prod-operator'}
                          />
                        </span>
                        <span className={'prod-value'}>
                          <div className={'section-wrapper'}>
                            <span className={'pb-1'}>&nbsp;</span>
                            <SelectBox
                              id={id}
                              options={sortMeasurementHelper(values)}
                              measurementAttr={true}
                              imperialUnit={unitSessionMode === 'Metric' ? false : true}
                              measurementTypeId={measureAttrType}
                              select={handleFormSelect}
                              unSelect={handleFormUnSelect}
                            />
                          </div>
                        </span>
                        <div className={'section-wrapper'}>
                          <span className={'pb-1'}>&nbsp;</span>
                          <span className={'prod-measure'}>{displayMeasureUnit(measureAttrType)}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            } else {
              return (
                <div key={index} className="form-group col-md-6">
                  {values && (
                    <SelectBox
                      id={id}
                      label={name}
                      options={values}
                      select={handleFormSelect}
                      unSelect={handleFormUnSelect}
                    />
                  )}
                </div>
              );
            }
          }
        })}
        {mode === 'Advanced' &&
          variables.map((variable, index) => {
            const { id, name, values, properties } = variable;
            const show =
              layerTypeId === '25' || layerTypeId === '35'
                ? true
                : properties?.find((prop) => prop.id === 'Show')?.value;
            if (
              id &&
              (!show ||
                [
                  'ManufacturerId',
                  'ComponentId',
                  'ExtendedLayerConfigSplit_ExtendedLayerConfigId',
                  'CApprovedUse_ApprovedUseId',
                  'ManufacturedProduct_CategoryId',
                  'ManufacturedProduct_SubcategoryId',
                ]
                  .concat(layerProps.basicAttributes)
                  .concat(EXCHANGE_VARIABLES.map((data) => data.replace('_view', '')))
                  .includes(id) ||
                id.endsWith('_view') ||
                id.endsWith('Operator'))
            )
              return <React.Fragment key={index}></React.Fragment>;
            if (isMeasurementAttribute(layerTypeId as string, id as string)) {
              const measureAttrType = getMeasurementType(layerTypeId as string, id as string) as MeasureType;
              const operatorVariable = variables.find((data) => data.id === `${id}Operator`) as ConfigurationVariable;
              return (
                <div key={index} className="form-group col-md-6">
                  {values && operatorVariable.values && (
                    <>
                      <div className={'advanced-selector row p-0 m-0'}>
                        <span>
                          <SelectBox
                            id={operatorVariable.id}
                            label={name}
                            options={operatorVariable.values}
                            select={handleFormSelect}
                            className={'prod-operator'}
                          />
                        </span>
                        <span className={'prod-value'}>
                          <div className={'section-wrapper'}>
                            <span className={'pb-1'}>&nbsp;</span>
                            <SelectBox
                              id={id}
                              options={sortMeasurementHelper(values)}
                              measurementAttr={true}
                              imperialUnit={unitSessionMode === 'Metric' ? false : true}
                              measurementTypeId={measureAttrType}
                              select={handleFormSelect}
                              unSelect={handleFormUnSelect}
                            />
                          </div>
                        </span>
                        <div className={'section-wrapper'}>
                          <span className={'pb-1'}>&nbsp;</span>
                          <div className={'prod-measure'}>{displayMeasureUnit(measureAttrType)}</div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            } else {
              return (
                <div key={index} className="form-group col-md-6">
                  {showAttribute(layerTypeId as string, id as string) && values && (
                    <SelectBox
                      id={id}
                      label={name}
                      options={values}
                      select={handleFormSelect}
                      unSelect={handleFormUnSelect}
                    />
                  )}
                </div>
              );
            }
          })}
      </div>
    </>
  );
};
