import { faCircle, faStopCircle, faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { faStop, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, styled } from '@mui/material';
import React, { HTMLAttributes, useContext } from 'react';
import theme from '../../../theme';

interface IChipComponentProps extends HTMLAttributes<HTMLDivElement> {
    id?: string;
    label?: string;
    className?: string;
    isDisabled?: boolean;
    click?: any;
    deleteChip?: any;
}

const StyledChip = styled(Chip)({
    '&.MuiChip-root ': {
        fontWeight: 500,
        fontSize: '12px',
        fontFamily: 'Inter',
        height: 'fit-content',
        minHeight: '23px',
        color: theme.palette.rnSteel.steel700,
        backgroundColor: theme.palette.rnSteel.steel100
    },
    // MuiChip-label changes are not registering, styling is in ChipComponent.scss
    ' .MuiChip-label': {
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
        textOverflow: 'clip'
    },
    '&.MuiChip-root:hover': {
        color: theme.palette.rnMisc.white,
        backgroundColor: theme.palette.rnOrange.darkOrange,
        ' .layered-icon-circle': {
            background: theme.palette.rnGrey.grey200,
            borderRadius: '100%',
        },

    },
    '&.MuiChip-root:focus': {
        color: theme.palette.rnSteel.steel700,
        backgroundColor: theme.palette.rnSteel.steel100,
        outline: '1px solid ' + theme.palette.rnOrange.darkOrange + ' !important',
    },
    '&.MuiChip-root.Mui-disabled': {
        color: `${theme.palette.rnGrey.grey400} !important`,
        backgroundColor: theme.palette.rnMisc.white,
        outline: `1px solid ${theme.palette.rnGrey.grey400}`,
        opacity: 1,
        ' .layered-icon-xmark': {
            color: theme.palette.rnGrey.grey400,
        }
    },
    ' .fa-layers': {
        marginTop: '1px !important'
    },
    ' .layered-icon-circle': {
        color: 'transparent',
        fontSize: '1.2rem'
    },
    ' .layered-icon-xmark': {
        fontSize: '1rem',
        color: theme.palette.rnGrey.grey600,
    }
});



export const ChipComponent = ({
    id,
    label,
    className,
    isDisabled,
    click,
    deleteChip,
    ...other
}: IChipComponentProps) => {
    // custom layered icon to replace default delete icon on chip
    const layeredIcon =
        <span className={`fa-layers fa-fw ${isDisabled ? 'disabled' : ''}`} data-testid={`${id}DeleteIcon`}>
            <FontAwesomeIcon icon={faCircle} className="layered-icon-circle" aria-hidden="true" />
            <FontAwesomeIcon icon={faXmark} className="layered-icon-xmark" aria-hidden="true" />
        </span>;
    return (
        <StyledChip
            id={id}
            disabled={isDisabled}
            className={`${className ? className : ''}`}
            label={label}
            onClick={click}
            onDelete={deleteChip}
            deleteIcon={layeredIcon}
            tabIndex={deleteChip ? 0 : -1}
        />
    )
}