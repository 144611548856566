import { Box } from '@mui/material';
import { ButtonComponent } from '../../components/shared/ButtonComponent/ButtonComponent';
import React, { useContext, useState } from 'react';
import { deleteAssemblyScenario } from '../../services/ScenarioService';
import { ScenarioInfo } from '../../models/GQLGeneratedModels';
import { ApplicationContext } from '../../components/ApplicationProvider';
import theme from '../../theme';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router';

interface IDeleteScnarioProps {
  scenarioid: string;
    handleDeletion: (isDeleted: boolean) => void;
}
export const DeleteModal = ({ scenarioid,handleDeletion }: IDeleteScnarioProps) => {
  const { setShowDialog } = useContext(ApplicationContext);
  const [deleted, setDeleted] = useState(false);
  const { instance: azureADPCA } = useMsal();
  const navigate = useNavigate();
  const deleteData = () => {
    let userEmail = azureADPCA.getActiveAccount()?.username || 'test@test.com'; // works for initial Azure B2C before customization
    // For customized Azure B2C, user email needs to be retrieved as follows:
    let tokenClaims;
    let userGUID;
    if (azureADPCA.getActiveAccount()?.idTokenClaims) {
      tokenClaims = azureADPCA.getActiveAccount()?.idTokenClaims;
      if (tokenClaims && tokenClaims.hasOwnProperty('email')) {
        userEmail = (tokenClaims as any)['email'];
      }
      //  if (tokenClaims && tokenClaims.hasOwnProperty('aud')) {
      userGUID = azureADPCA.getActiveAccount()?.localAccountId as string;
      //  }
    }

    const dataToDelete: ScenarioInfo = {
      AzureB2CUserEmail: userEmail,
      ScenarioId: parseInt(scenarioid),
    };

    deleteAssemblyScenario(dataToDelete);
    handleDeletion(true);
    setShowDialog(false);
  };

  return (
    <Box display="flex" flexDirection="column">
      <label htmlFor="SavedItemTitle" className={'small-para-semibold ml-2'}>
        Are you sure you want to Delete Scenario ?
      </label>
      <br></br>
      <div className="row m-0">
        <ButtonComponent
          buttonType={'secondary'}
          buttonText={'Delete'}
          buttonCss={{ margin: 0 }}
          click={() => deleteData()}
        />
        &nbsp; &nbsp;
        <ButtonComponent
          buttonType={'primary'}
          buttonText={'Cancel'}
          buttonCss={{ margin: 0 }}
          click={() => setShowDialog(false)}
        />
      </div>
    </Box>
  );
};
