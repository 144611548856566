import { Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { ButtonComponent } from "../ButtonComponent/ButtonComponent";
import { ApplicationContext } from "../../ApplicationProvider";

interface IErrorModalProps {
    message?: string;
}
export const ErrorModalComponent = ({ message }: IErrorModalProps) => {
    const { setShowDialog } = useContext(ApplicationContext)
    return (
        <div>
            <Typography className="standard-para py-2">
                {message ? message : `Error encountered, please click on "report bug".`}
            </Typography>
            <ButtonComponent
                id='error-modal-close'
                buttonType={'primary'}
                click={() => setShowDialog(false)}
                buttonText={'Ok'}
                buttonStyle={'mx-0 py-2'} />
        </div>
    )

}