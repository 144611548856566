import React from 'react';
import { useGetCementitiousWoodFiberDeckUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { Length } from '../../utils/Measurement/Length';
import './UsageDetails.scss';

export const CementitiousWoodFiberDeckUsageDetail = (props: any) => {
  const { data, loading, error } = useGetCementitiousWoodFiberDeckUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];
  const isMetric = usage?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Deck (Cementitious Panel)</h4>
      <table>
        <tr>
          <td>Min Thickness:</td>
          <td>{Length.display(usage?.CementitiousWoodFiberDeckUsage?.ThicknessMin, isMetric)}</td>
        </tr>
        <tr>
          <td>Max Thickness:</td>
          <td>
            {Length.display(usage?.Component?.ManufacturedProduct?.CementitiousWoodFiberDeck?.ThicknessMax, isMetric)}
          </td>
        </tr>
        <tr>
          <td>Max Span:</td>
          <td>{Length.display(usage?.CementitiousWoodFiberDeckUsage?.SpanMax, isMetric)}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
