export class Length {
  static INCH_TO_MM = 25.4;
  static IMPERIALUNIT = 'in';
  static METRICUNIT = 'mm';
  static WINDUNIT = 'psf';

  static convertImperialToMetric(valueImperial: number) {
    const valueMetric = valueImperial * Length.INCH_TO_MM;
    return valueMetric.toFixed(4) + ' ' + Length.METRICUNIT;
  }

  static convertMetricToImperial(valueMetric: number) {
    const valueImperial = valueMetric / Length.INCH_TO_MM;
    return valueImperial.toFixed(4) + ' ' + Length.IMPERIALUNIT;
  }

  static displayMetric(valueMetric: number) {
    return valueMetric.toFixed(4) + ' ' + Length.METRICUNIT;
  }
  static displayImperial(valueImperial: number) {
    return valueImperial.toFixed(4) + ' ' + Length.IMPERIALUNIT;
  }
  static displayWind(valueWind: number) {
    return valueWind.toFixed(4) + ' ' + Length.WINDUNIT;
  }

  static display(valueMetric: number, showAsMetric: boolean) {
    if (showAsMetric) return Length.displayMetric(valueMetric);
    else return Length.convertMetricToImperial(valueMetric);
  }
}
