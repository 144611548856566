import { Divider, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import theme from '../../../theme';
import './SavedAssembly.scss';
import { ButtonComponent } from '../../shared/ButtonComponent/ButtonComponent';
import { useNavigate } from 'react-router';
import { genrateUuidv4, getScenarioDetailById } from '../../../services/ScenarioService';
import { useMsal } from '@azure/msal-react';

export const Summary: React.FC<any> = (props: any) => {
  const { properties, scenarioid } = props;
  const [data, setData] = useState<any>();
  const navigate = useNavigate();
  const { instance: azureADPCA } = useMsal();
  const urlParams = new URLSearchParams(location.search);
  const resetSearch = () => {
    window.location.reload();
  };
  const loadContractorPackage = (scenarioid: any) => {
    window.open(`/ContractorPackageReport/${scenarioid}`, '_blank');
  };

  const editScenario = async (scenarioid: any) => {
    let userEmail = azureADPCA.getActiveAccount()?.username || 'test@test.com';
    let tokenClaims;
    if (azureADPCA.getActiveAccount()?.idTokenClaims) {
      tokenClaims = azureADPCA.getActiveAccount()?.idTokenClaims;
      if (tokenClaims && tokenClaims.hasOwnProperty('email')) {
        userEmail = (tokenClaims as any)['email'];
      }
    }
    const response = await getScenarioDetailById(Number(scenarioid), userEmail, genrateUuidv4());

    navigate(`/assemblyDetail/${response[0].NavAssemblyId}?${urlParams.toString()}`, { state: { savedScenarioId: response[0].ScenarioId } });
  };
  const linkStyle = {
    color: theme.palette.rnOrange.darkOrange,
    fontweight: 600,
  };
  return (
    <div className="row p-3 assembly-summary">
      <div className="table-wrapper col-lg-12">
        <div className="row m-0">
          <div className="py-2">
            <ButtonComponent
              isDisabled={(properties[0].ScenarioStatusId === 3 || properties[0].ScenarioStatusId === 3)}
              buttonType={'secondary'} buttonText={'Edit'} click={() => editScenario(scenarioid)} />
          </div>
          <div className="py-2">
            <ButtonComponent
              isDisabled={properties[0].ScenarioStatusId != 1}
              iconName={'download'}
              buttonType={'secondary'}
              buttonText={'Contractor Package'}
              click={() => loadContractorPackage(scenarioid)}
            />
          </div>
          {/* <div className="py-2">
            <ButtonComponent buttonType={'secondary'} buttonText={'Delete'} click={() => resetSearch()} />
          </div> */}
        </div>
      </div>
      <div className="table-wrapper col-lg-12">
        <div className="row m-0">
          <div className="py-2">
            <Typography className="mx-1" fontFamily={'Inter'} fontSize={'26px'} fontWeight={600} lineHeight={'34px'}>
              Assembly Name
            </Typography>
          </div>
        </div>
      </div>
      <div className="table-wrapper col-lg-12">
        <div className={'row m-0 '}>
          <div className={'col-lg-6 col-sm-5 pl-0'}>
            <Typography fontWeight={700} fontFamily={'Inter'}>
              Assembly Name
            </Typography>
          </div>
          <div className={'col-lg-6 col-sm-7'}>
            <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
              Notes
            </Typography>
          </div>
        </div>
        <div className={'row m-0 '}>
          <div className={'col-lg-6 col-sm-5 pl-0'}>
            <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
              {properties[0].Name}
            </Typography>
          </div>
          <div className={'col-lg-6 col-sm-7'}>
            <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
              {properties[0].Comments}
            </Typography>
          </div>
        </div>
        <br></br>
        <br></br>
        <Divider></Divider>
      </div>
      <div className="table-wrapper col-lg-12">
        <br></br>
        <div className="d-flex bg-white">
          <div className={'col-12 p-0 summray-section'}>
            <div className={'row m-0 detail-row'}>
              <div className={'col-8 row m-0 '}>
                <div className={'col-lg-4 col-sm-5 pl-0 text-right'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnOrange.darkOrange}>
                    Assembly #
                  </Typography>
                </div>
                <div className={'col-lg-8 col-sm-7'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties[0].NavAssemblyName}
                  </Typography>
                </div>
              </div>
              <div className={'col-4 row m-0 '}>
                <div className={'col-lg-6 col-sm-7 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    Wind Uplift
                  </Typography>
                </div>
                <div className={'col-lg-6 col-sm-5'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties[0].NavAssembly.WindUplift}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={'row m-0 detail-row'}>
              <div className={'col-8 row m-0 '}>
                <div className={'col-lg-4 col-sm-5 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    Roof System
                  </Typography>
                </div>
                <div className={'col-lg-8 col-sm-7'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties[0].NavAssembly.AssemblyTypeCode}
                  </Typography>
                </div>
              </div>
              <div className={'col-4 row m-0 '}>
                <div className={'col-lg-6 col-sm-7 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    Internal Fire
                  </Typography>
                </div>
                <div className={'col-lg-6 col-sm-5'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties[0].NavAssembly.IntFireRatingCode.trim()}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={'row m-0 detail-row'}>
              <div className={'col-8 row m-0 '}>
                <div className={'col-lg-4 col-sm-5 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    Application
                  </Typography>
                </div>
                <div className={'col-lg-8 col-sm-7'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties[0].NavAssembly.AssemblyApplicationTypeCode}
                  </Typography>
                </div>
              </div>
              <div className={'col-4 row m-0 '}>
                <div className={'col-lg-6 col-sm-7 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    External Fire
                  </Typography>
                </div>
                <div className={'col-lg-6 col-sm-5'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties[0].NavAssembly.ExtFireRatingCode}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={'row m-0 detail-row'}>
              <div className={'col-8 row m-0'}>
                <div className={'col-lg-4 col-sm-5 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    Cover Securement
                  </Typography>
                </div>
                <div className={'col-lg-8 col-sm-7'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties[0].NavAssembly.CoverSecurementMethodCode}
                  </Typography>
                </div>
              </div>
              <div className={'col-4 row m-0 '}>
                <div className={'col-lg-6 col-sm-7 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    Hail
                  </Typography>
                </div>
                <div className={'col-lg-6 col-sm-5'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties[0].NavAssembly.HailRatingCode}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={'row m-0 detail-row'}>
              <div className={'col-8 row m-0 '}>
                <div className={'col-lg-4 col-sm-5 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    Deck Type
                  </Typography>
                </div>
                <div className={'col-lg-8 col-sm-7'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties[0].NavAssembly.DeckTypeCode}
                  </Typography>
                </div>
              </div>
              <div className={'col-4 row m-0 '}>
                <div className={'col-lg-6 col-sm-7 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'}>
                    Slope
                  </Typography>
                </div>
                <div className={'col-lg-6 col-sm-5'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties[0].NavAssembly.Slope}
                  </Typography>
                </div>
              </div>
            </div>
            {/* <PV Properties Start here> */}
            {properties[0].NavAssembly.IncludesPV && (
              <>
                <div className={'row m-0 detail-row'}>
                  <div className={'col-8 row m-0'}>
                    <div className={'col-lg-4 col-sm-5 pl-0 text-right'}>
                      <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                        PV Application Method
                      </Typography>
                    </div>
                    <div className={'col-lg-8 col-sm-7'}>
                      <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                        {properties[0].NavAssembly.PVApplicationMethodCode}
                      </Typography>
                    </div>
                  </div>
                  <div className={'col-4 row m-0 '}>
                    <div className={'col-lg-6 col-sm-7 pl-0 text-right'}>
                      <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                        PV Wind Uplift
                      </Typography>
                    </div>
                    <div className={'col-lg-6 col-sm-5'}>
                      <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                        {properties[0].NavAssembly.PVWindUplift}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={'row m-0 detail-row'}>
                  <div className={'col-8 row m-0 '}>
                    <div className={'col-lg-4 col-sm-5 pl-0 text-right'}>
                      <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                        PV Hail
                      </Typography>
                    </div>
                    <div className={'col-lg-8 col-sm-7'}>
                      <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                        {properties[0].NavAssembly.PVHailRatingCode}
                      </Typography>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* <PV Properties Ends here>   */}
          </div>
        </div>
        <br></br>
        <br></br>
      </div>

    </div>
  );
};
