import React, { useContext } from 'react';
import { numToString } from '../../utils/LayerApi';
import { getLayerDataById } from '../../utils/LayerTypeCheckUtilities';
import { UnitConversion } from '../../utils/Measurement/UnitConversion';
import { ApplicationContext } from '../ApplicationProvider';
import { ILayer, ISecurementLayer, IVariableAssigmentLabels } from '../ConfigitProvider';
import { MeasureType } from '../constants/ENUMS';
import { EXCHANGE_VARIABLES } from '../constants/EXCHANGE_VARIABLES';
import { LAYER_MEASUREMENT_ATTRIBUTES, MEASURE_UNITS } from '../constants/MEASURE_UNITS';
import { ChipComponent } from '../shared/ChipComponent/ChipComponent';
import './RoofLayers.scss';
import { SelfSecurementConfigSummary } from './SelfSecurementConfigSummary';
import { Dialog } from '../shared/DialogComponent/Dialog';
import { SelfSecurementConfigurator } from '../LayerConfigurator/SelfSecurementConfigurator';
import { Typography } from '@mui/material';

interface ILayerConfigSummaryProps {
  layer: ILayer;
  index: number;
}

export const LayerConfigSummary: React.FC<ILayerConfigSummaryProps> = ({ layer, index }: ILayerConfigSummaryProps) => {
  const { unitSessionMode, setDialog, setShowDialog } = useContext(ApplicationContext);
  let fromLayer: string | undefined, toLayer: string | undefined;
  if (layer.isSecurementLayer) {
    const securementLayer = layer as ISecurementLayer;
    if (securementLayer.fromLayerTypeId) {
      fromLayer = 'From: ';
      fromLayer += getLayerDataById(numToString(securementLayer.fromLayerTypeId))?.name;
      if (securementLayer.fromLayerNum) fromLayer += ' - ' + securementLayer.fromLayerNum;
    }
    if (securementLayer.toLayerTypeId) {
      toLayer = 'To: ';
      toLayer += getLayerDataById(numToString(securementLayer.toLayerTypeId))?.name;
      if (securementLayer.toLayerNum) toLayer += ' - ' + securementLayer.toLayerNum;
    }
  }
  const productList: string[] = [];
  const products = layer.productDetails
    ?.filter((data) => data.productId !== '' || data.manufacturerId !== '')
    ?.map((data) => {
      if (data.manufacturerLabel && data.productLabel) return productList.push(`Manufacturer: ${data.manufacturerLabel} and Product: ${data.productLabel}`);
      if (data.manufacturerLabel) return productList.push(`Manufacturer: ${data.manufacturerLabel}`);
      if (data.productLabel) return productList.push(`Product: ${data.productLabel}`);
    });

  const getAttributeDisplayText = (variableAssignmentLabels: IVariableAssigmentLabels) => {
    const StandardSecurementLayerTypeIds = ['39', '41', '42', '44', '45'];
    // layerTypeIdForMeasurementAttr is used for base layer type id for measurement attributes information retrieval
    // If current layer type is one of securement types, layerTypeIdForMeasurementAttr is 45; otherwise it's value is normal layer type id
    const layerTypeIdForMeasurementAttr = StandardSecurementLayerTypeIds.includes(layer.id) ? '45' : layer.id;
    const targetLayer = LAYER_MEASUREMENT_ATTRIBUTES.find((data) => data.layerTypeId === layerTypeIdForMeasurementAttr);
    let isMeasureAttribute = false;
    if (targetLayer) {
      isMeasureAttribute =
        targetLayer.measurementAttributes.filter((lyr) => lyr.columnName === variableAssignmentLabels.variableId).length > 0 ? true : false;
    } else {
      isMeasureAttribute = false;
    }
    if (isMeasureAttribute) {
      const measureType = LAYER_MEASUREMENT_ATTRIBUTES.find(
        (data) => data.layerTypeId === layerTypeIdForMeasurementAttr
      )?.measurementAttributes.find((a) => a.columnName === variableAssignmentLabels.variableId)?.measureType;
      const iUnit = MEASURE_UNITS[measureType as MeasureType].imperialUnit;
      const mUnit = MEASURE_UNITS[measureType as MeasureType].metricUnit;
      const mValue = parseFloat(variableAssignmentLabels.valueLabel as string).toFixed(4);
      const iValue = UnitConversion.convertMetricToImperial(
        parseFloat(variableAssignmentLabels.valueLabel as string),
        measureType as MeasureType
      );
      let operator = layer.variableAssignmentLabels?.find(data => data.variableId === `${variableAssignmentLabels.variableId}Operator`)?.valueLabel;
      if (operator === undefined) {
        operator = '=';
      }
      return unitSessionMode === 'Metric'
        ? `${variableAssignmentLabels.variableLabel} ${operator} ${mValue} ${mUnit}`
        : `${variableAssignmentLabels.variableLabel} ${operator} ${iValue} ${iUnit}`;
    } else {
      return `${variableAssignmentLabels.variableLabel}: ${variableAssignmentLabels.valueLabel}`;
    }
  };

  const attributes = layer.variableAssignmentLabels?.map((data, index) => {
    if (!(
      data.variableId === 'ComponentId' ||
      data.variableId === 'ComponentId_view' ||
      data.variableId === 'ManufacturerId' ||
      data.variableId === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId' ||
      EXCHANGE_VARIABLES.includes(`${data.variableId}_view`) ||
      data.variableId?.endsWith('Operator')
    ))
      return <ChipComponent key={'layerAttributes' + index} className={'m-1'} label={getAttributeDisplayText(data)} />;
  });

  const handleEditSelfSecurement = (layerId: string, layerIndex: number) => {
    setDialog(
      <Dialog label="Self-Securement Configuration">
        <SelfSecurementConfigurator baseLayerTypeId={layerId} baseLayerIndex={layerIndex} />
      </Dialog>
    );
    setShowDialog(true);
  };
  const handleRemoveSelfSecurement = (index: number) => {
    const temp = { ...layer };
    temp.hasSelfSecurement = false;
    temp.SelfSecurementId = '';
    temp.selfSecurement = undefined;
    //TODO Need to set layers
    //configureLayer(temp, index);
    //setLayers(layers.filter((layer, i) => index !== i));
  };
  const getSelfSecurementTypeName = (selfSecurementTypeId: string) => {
    let nameDisplay = '';
    switch (selfSecurementTypeId) {
      case '40':
        nameDisplay = 'Securement (Sheet Lap)';
        break;
      case '43':
        nameDisplay = 'Securement (Deck Lap)';
        break;
      default:
        nameDisplay = 'Self Securement';
    }
    return nameDisplay;
  };

  if (!(products || attributes || fromLayer || toLayer)) return <></>;
  return (
    <div className="layer-bg p-1 pl-3 mx-1">
      {fromLayer && <ChipComponent className={'m-1'} label={`${fromLayer}`} />}
      {toLayer && <ChipComponent className={'m-1'} label={`${toLayer}`} />}
      {products ? productList?.map((item, index) => {
        return <ChipComponent key={index + item} className={'m-1'} label={`${item}`} />
      })
        : null}
      {attributes}
      {layer.hasSelfSecurement && layer.selfSecurement && (
        <div className="mdc-card px-2 mb-2 shadow-none rounded-0" style={{ backgroundColor: '#F8F7F4 ' }}>
          <div className={'test'}></div>
          <div className="d-flex align-items-center  p-1 pl-4">
            <div className={'layer-display-name pl-2'}>
              <Typography fontFamily={'Inter'} fontWeight={500} fontSize={12}>{getSelfSecurementTypeName(layer.selfSecurement.idLayerType)}</Typography>
            </div>
            <div className="pl-2 ">
              <a className="px-1" onClick={() => handleEditSelfSecurement(layer.id, index)}>
                <span className="material-symbols-outlined pencil-icon" aria-hidden="true">edit</span>
              </a>
              <a className="px-1" onClick={() => (layer.selfSecurement?.autoSelected ? undefined : handleRemoveSelfSecurement(index))}>
                <span className={`material-symbols-outlined close-icon ${layer.selfSecurement.autoSelected ? ' disabled' : ''}`} aria-hidden="true">close</span>
              </a>
            </div>
          </div>
          <SelfSecurementConfigSummary selfSecurement={layer.selfSecurement} baseLayerIndex={index} />
        </div>
      )}
    </div>
  );
};
