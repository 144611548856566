import { Box, Tab, Tabs, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import theme from '../../theme';
import { ButtonComponent } from '../shared/ButtonComponent/ButtonComponent';
import { SnackbarComponent } from '../shared/SnackbarComponent';
import { Project } from './Project';

import { AddNewProject } from './Addnewproject';
import './SavedItems.scss';
import { UnassignedAssembly } from './UnassignedAssembly';

import { SavedAssemblies } from './SavedAssemblies';
import { useMsal } from '@azure/msal-react';
import { genrateUuidv4, getSavedScenarioByEmail } from '../../services/ScenarioService';
import { Loading } from '../shared/Loading/Loading';
import { useLocation, useParams } from 'react-router';
import { getProjectsByEmail } from '../../services/ProjectService';
import { ProjectDetail } from './ProjectDetail';
import { AddNewRoofArea } from './AddNewRoofArea';
import { Contacts } from './Contacts/ContactsView';

// TODO: as this is now used in more than one area (filters, here, calc- though calc may be redone), make this into shared component
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={'pt-4'}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
const StyledTab = styled(Tab)({
  '&.MuiTab-root.Mui-selected': {
    backgroundColor: theme.palette.rnOrange.darkOrange,
    color: theme.palette.rnMisc.white,
    borderRadius: theme.borders.tabBorderRadius,
  },
  '&.MuiTab-root.Mui-selected:focus': {
    outline: 'none',
  },
  '&.MuiTab-root': {
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Inter',
    lineHeight: '24px',
    padding: '12px 8px',
    textTransform: 'none',
    backgroundColor: theme.palette.rnSteel.steel700,
    color: theme.palette.rnMisc.white,
    borderRadius: theme.borders.tabBorderRadius,
  },
});
const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    display: 'none !important',
  },
  '& .MuiTabs-flexContainer': {
    borderBottom: `1px solid ${theme.palette.rnOrange.darkOrange}`,
  },
});

export const SavedItemsComponent: React.FC = () => {
  const [savedItemTab, setSavedItemTab] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [showAddProject, setShowAddProject] = useState(false);
  const [showProjectView, setShowProjectView] = useState(false);
  const [projectTabTitle, setProjectTabTitle] = useState('Add New Project');
  const [projectid, setProjectId] = useState(0);
  const [showAddRoofArea, setShowAddRoofArea] = useState(false);
  const [scenarios, setScenarios] = useState<any>();
  const [projects, setProjects] = useState<any>();
  const [currentlocation, setCurrentLocation] = useState('default');
  const [loading, setLoading] = useState(false);
  const [reload, setReLoad] = useState(false);
  const { instance: azureADPCA } = useMsal();
  const location = useLocation();
  const { guid } = useParams();
  const params = useParams();
  const closeIcon = (value: number) => {
    if (value === 0) {
      return (
        <span className="material-symbols-outlined" onClick={() => closeProjectView(0)}>
          close
        </span>
      );
    }
    if (value === 1) {
      return (
        <span className="material-symbols-outlined" onClick={() => closeAddNewProject(0)}>
          close
        </span>
      );
    }
    if (value === 2) {
      return (
        <span className="material-symbols-outlined" onClick={() => closeAddRoofArea(0)}>
          close
        </span>
      );
    }
    return <></>;
  };
  if (!guid) {
    throw Error('Empty Scenario id');
  }
  const handleClick = () => {
    //setOpen(false);
    setShowAddProject(true);
    selectedTab(4);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
  };
  const handleview = (projectid: number) => {
    setProjectTabTitle('Project Detail');
    setSavedItemTab(4);
    setShowProjectView(true);
    setProjectId(projectid);
  };

  const handleAddProject = () => {
    console.log('Add Project Clicked');
    setSavedItemTab(5);
    setShowAddProject(true);
    setShowProjectView(false);
    setProjectId(0);
    setProjectTabTitle('Add New Project');
  };
  const handleedit = (projectid: number) => {
    setProjectTabTitle('Edit Project');
    setSavedItemTab(5);
    setShowProjectView(false);
    setShowAddProject(true);
    setProjectId(projectid);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // disable for now
    return;
    // if (newValue !== 4) {
    //   setShowAddProject(false);
    // }
    // if (newValue != 5) setShowAddProject(false);
    // if (newValue != 4) setShowProjectView(false);
    // if (newValue != 6) setShowAddRoofArea(false);
    if (savedItemTab != newValue) setSavedItemTab(newValue);
  };
  const selectedTab = (newValue: number) => {
    if (savedItemTab != newValue) setSavedItemTab(newValue);
  };
  const closeAddNewProject = (newValue: number) => {
    setShowAddProject(false);
    if (savedItemTab != newValue) setSavedItemTab(newValue);
  };
  const closeAddRoofArea = (newValue: number) => {
    setShowAddRoofArea(false);
    if (savedItemTab != newValue) setSavedItemTab(newValue);
  };
  const closeProjectView = (newValue: number) => {
    setShowProjectView(false);
    if (savedItemTab != newValue) setSavedItemTab(newValue);
  };
  const openAddRoofArea = () => {
    setShowAddRoofArea(true);
    setSavedItemTab(6);
  }
  const textBoxStyle = {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  };
  const iconStyle = {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  };

  useEffect(() => {
    let userEmail = azureADPCA.getActiveAccount()?.username || 'test@test.com';
    let tokenClaims;
    if (azureADPCA.getActiveAccount()?.idTokenClaims) {
      tokenClaims = azureADPCA.getActiveAccount()?.idTokenClaims;
      if (tokenClaims && tokenClaims.hasOwnProperty('email')) {
        userEmail = (tokenClaims as any)['email'];
      }
    }
    const fetchSavedItems = async () => {
      setLoading(true);
      const assemblies = await getSavedScenarioByEmail(userEmail, genrateUuidv4());
      if (assemblies) {
        setScenarios(assemblies);
      }

      setLoading(false);
    };

    const timer = setTimeout(() => {
      fetchSavedItems();
    }, 3000);
    return () => clearTimeout(timer);
  }, [guid]);
  useEffect(() => { }, [savedItemTab]);

  let savedScenarios;
  if (scenarios) {
    savedScenarios = scenarios;
  }

  return (
    <>
      <main className="main-content container-fluid flex-shrink-0">
        <div className={'row justify-content-center'}>
          <Box className={'col-10 rn-saved-items'}>
            <div className="d-flex justify-content-between align-items-center">
              <Typography className={'headline-1 py-4'}>My Saved Items</Typography>
            </div>
            <Box>
              <StyledTabs value={savedItemTab} onChange={handleChange}>
                {/* <StyledTab label="Projects" value={0} disableRipple /> */}
                <StyledTab label="Saved Assemblies" value={1} disableRipple />
                {/* <StyledTab label="Saved Searches" value={2} disableRipple />
                 */}
                {/* <StyledTab label="Contacts" value={3} disableRipple /> */}
                {showProjectView && (
                  <StyledTab
                    label={
                      <span className="d-flex">
                        {projectTabTitle}&nbsp;&nbsp;{savedItemTab === 4 && closeIcon(0)}
                      </span>
                    }
                    value={4}
                    disableRipple
                  />
                )}
                {showAddProject && (
                  <StyledTab
                    label={
                      <span className="d-flex">
                        {projectTabTitle}&nbsp;&nbsp;{savedItemTab === 5 && closeIcon(1)}
                      </span>
                    }
                    value={5}
                    disableRipple
                  />
                )}
                {showAddRoofArea && (
                  <StyledTab
                    label={
                      <span className="d-flex">Add Roof Area&nbsp;&nbsp;{savedItemTab === 6 && closeIcon(2)}</span>
                    }
                    value={6}
                    disableRipple
                  />
                )}
              </StyledTabs>
              {/* <TabPanel index={0} value={savedItemTab}>
                <div className={'row ml-0 mt-3'}>
                  <div className={'col-12'}>
                    <Box>
                      <Project
                        handleview={handleview}
                        handleAddProject={handleAddProject}
                        openAddRoofArea={openAddRoofArea}
                      />

                      <Box className="py-3">
                         {unassignedAssemblies.map((unassignedAssembly, index) => (
                              <UnassignedAssembly key={index} {...unassignedAssembly} />
                            ))}
                      </Box>
                    </Box>
                  </div>
                </div>
              </TabPanel> */}
              <div className={'row'}>
                <div className={'col-12'}>
                  <TabPanel index={1} value={savedItemTab}>
                    {loading ? <div className='d-flex justify-content-center'><Loading /></div> : savedScenarios?.map((scenario: any) => (
                      <SavedAssemblies key={scenario.ScenarioId} scenario={scenario} />
                    ))
                    }
                  </TabPanel>
                </div>
              </div>

              {/* <TabPanel index={3} value={savedItemTab}>
                <Contacts />
              </TabPanel> */}

              <TabPanel index={4} value={savedItemTab}>
                <div className={'row'}>
                  <div className={'col-12 p-0'}>
                    <Box className={'col-12'}>
                      {/* Place holder for Project Detail */}
                      {/* <ProjectDetail projectid={projectid} handleedit={handleedit} /> */}
                    </Box>
                  </div>
                </div>
              </TabPanel>
              <TabPanel index={5} value={savedItemTab}>
                {/* Place holder for Add New Project */}
                <AddNewProject
                  projectid={projectid}
                  onCloseAddNewTab={() => closeAddNewProject(0)}
                  openAddRoofArea={openAddRoofArea}
                />
              </TabPanel>
              <TabPanel index={6} value={savedItemTab}>
                <AddNewRoofArea projectId={projectid} onCloseAddNewTab={() => closeAddRoofArea(0)} />
              </TabPanel>
            </Box>
          </Box>
        </div>
      </main>
      <SnackbarComponent open={open} handleClose={handleClose} message={'Project successfully saved'} />
    </>
  );
};
