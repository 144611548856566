import { FormControlLabel, Radio, styled, Typography } from '@mui/material';
import React, { HTMLAttributes } from 'react';
import theme from '../../../theme';

interface IRadioComponentProps extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  name?: string;
  checked?: boolean;
  value?: string | number;
  disabled?: boolean;
  label?: string;
  click?: any;
  error?: boolean;
  defaultChecked?: boolean;
}

const RoofNavRadio = styled(Radio)(({ color }) => ({
  '&.MuiRadio-root:hover': {
    outline: `2px ${theme.palette.rnGrey.grey300}`,
    outlineOffset: 2,
    backgroundColor: 'transparent',
  },
  '&.MuiRadio-root .Mui-focusVisible': {
    outline: color !== 'error' ? `2px auto ${theme.palette.rnOrange.darkOrange}` : `2px auto ${theme.palette.rnMisc.red}`,
    outlineOffset: 2,
  }

}));

// modified icons based off of customized icon here: https://mui.com/material-ui/react-radio-button/#customization
const RNIcon = styled('span')(({ color }) => ({
  borderRadius: '50%',
  width: 18,
  height: 18,
  backgroundColor: '#f5f8fa',
  backgroundImage: `linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))`,
  boxShadow:
    color !== 'error'
      ? `inset 0 0 0 1px ${theme.palette.rnGrey.grey300}, inset 0 -1px 0 ${theme.palette.rnGrey.grey300}`
      : `inset 0 0 0 1px ${theme.palette.rnMisc.red}, inset 0 -1px 0 ${theme.palette.rnMisc.red}`,
  '&:before': {
    display: 'block',
    width: 18,
    height: 18,
    content: '""',
  },
  'input:focus ~ &': {
    outline: color != 'error' ? `2px auto ${theme.palette.rnOrange.darkOrange}` : `2px auto ${theme.palette.rnMisc.red}`,
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    background: 'rgba(206,217,224,.5)',
  },
  '.MuiRadio-root:hover &': {
    outline: `2px auto ${theme.palette.rnGrey.grey300}`,
    outlineOffset: 2,
  },
}));

const RNCheckedIcon = styled(RNIcon)({
  backgroundColor: theme.palette.rnOrange.darkOrange,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  boxShadow: 'none',
  '&:before': {
    display: 'block',
    width: 18,
    height: 18,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
});

export const RadioComponent = ({
  name,
  checked,
  value,
  disabled,
  label,
  click,
  error,
  defaultChecked,
}: IRadioComponentProps) => {
  return (
    <FormControlLabel
      tabIndex={0}
      onClick={click}
      value={value}
      control={
        <RoofNavRadio
          disableFocusRipple
          disableRipple
          name={name}
          checked={checked}
          disabled={disabled}
          icon={<RNIcon color={error ? 'error' : 'primary'} />}
          checkedIcon={<RNCheckedIcon />}
          defaultChecked={defaultChecked}
          color={error ? 'error' : 'primary'}

        />
      }
      label={
        <Typography
          fontFamily={'Inter'}
          fontWeight={500}
          color={disabled ? theme.palette.rnGrey.grey300 : theme.palette.rnGrey.grey700}
        >
          {label}
        </Typography>
      }
    />
  );
};
