import React from 'react';
import { useGetWeldSolventUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { Length } from '../../utils/Measurement/Length';
import './UsageDetails.scss';

export const WeldSolventUsageDetail = (props: any) => {
  const { data, loading, error } = useGetWeldSolventUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];
  const isMetric = usage?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Weld (Solvent)</h4>
      <table>
        <tr>
          <td>Configuration:</td>
          <td>{usage?.WeldSolventUsage?.C_WeldConfiguration.WeldConfigurationCode}</td>
        </tr>
        <tr>
          <td>Outside/Full With:</td>
          <td>{Length.display(usage?.WeldSolventUsage?.OutsideWidth, isMetric)}</td>
        </tr>
        <tr>
          <td>Inside Width:</td>
          <td>{Length.display(usage?.WeldSolventUsage?.InsideWidth, isMetric)}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
