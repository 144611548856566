import React, { useContext, useState } from 'react';
import { getCompatibleExtendedLayerConfigs } from '../../services/ConfigitService';
import { Section } from '../../types/configurator';
import * as LayerApi from '../../utils/LayerApi';
import { ConfigitContext, ILayer } from '../ConfigitProvider';
import { getLayerData } from '../constants/LAYER_DATA';
import { AccordionComponent } from '../shared/AccordionComponent/AccordionComponent';
import { ConfigitRadioGroup } from '../shared/ConfigitRadioGroup/ConfigitRadioGroup';

interface ILayerBuilderProps {
  popup?: boolean;
  addLayerAt?: number;
  layers?: ILayer[];
  excludedLayerIds?: string[];
  sections?: Section[];
  handleSelect?: (id: string, value: string, isSecurementLayer?: boolean) => void;
}

export const LayerBuilder: React.FC<ILayerBuilderProps> = ({
  popup,
  addLayerAt = 0,
  layers = [],
  excludedLayerIds = [],
  sections,
  handleSelect,
}: ILayerBuilderProps) => {
  const { setLayers, variableAssignments, layerConfigMasterData } = useContext(ConfigitContext);
  const [openIndex, setOpenIndex] = useState(-1);
  let layerInsertPointDomain: string[];
  if (sections) {
    const allExtendedLayerConfigs = getCompatibleExtendedLayerConfigs(sections, layerConfigMasterData ?? []);
    const allowedLayerConfigs = variableAssignments
      .find((data) => data.variableId === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId')
      ?.allowedValues?.map((data) => String(data.value));
    layerInsertPointDomain = LayerApi.getLayerInsertPointDomain(
      layers,
      excludedLayerIds,
      allowedLayerConfigs?.length
        ? allExtendedLayerConfigs.filter((data) => allowedLayerConfigs.includes(String(data.ExtendedLayerConfigId)))
        : allExtendedLayerConfigs,
      addLayerAt
    );
  }
  return (
    <div id="QSRoofLayers" className={'layer-builder'}>
      {getLayerData()?.map((layerType, index) => {
        const isSecurementLayer = layerType.name === 'Securement';
        let incompatible = true;
        if (popup && sections) {
          for (let i = 0; i < layerType.layers.length; i++) {
            const layer = layerType.layers[i];
            layer.incompatible = !layerInsertPointDomain.includes(String(layer.value));
            if (!layer.incompatible) incompatible = false;
          }
        } else incompatible = false;
        return (
          <AccordionComponent
            key={index}
            id={layerType.id}
            title={layerType.name}
            className={popup ? 'bg-white' : 'bg-white mb-2'}
            whiteTitle={popup}
            disabled={incompatible}
            collapsed={openIndex !== index}
            toggleCollapsed={() => {
              if (!incompatible) {
                if (openIndex === index) setOpenIndex(-1);
                else setOpenIndex(index);
              }
            }}
          >
            <ConfigitRadioGroup
              options={layerType.layers.filter((data) => !isSecurementLayer || data.value === '45')}
              showBorders={popup}
              select={(id: string, value: string) => {
                if (handleSelect) handleSelect(id, value, isSecurementLayer);
                else setLayers([{ id: value, autoSelected: false, isSecurementLayer }]);
              }}
            />
          </AccordionComponent>
        );
      })}
    </div>
  );
};
