import React, { useContext } from 'react';
import { ApplicationContext } from '../../components/ApplicationProvider';
import { APU_MEASUREMENT_ATTRIBUTES, MEASURE_UNITS } from '../../components/constants/MEASURE_UNITS';
import { MeasureType } from '../../components/constants/ENUMS';
import { SelectBox } from '../../components/shared/SelectComponent/SelectBox';
import { ConfigurationValue, ConfigurationVariable, SingletonValue } from '../../types/configurator';
import { EXCHANGE_VARIABLES } from '../../components/constants/EXCHANGE_VARIABLES';
import './ProductSearch.scss';

interface IProductAttributeProps {
  attributeId: string;
  approvedUseCode: string;
  variables?: ConfigurationVariable[];
  handleFormSelect: (variableId: string, value: string) => void;
  handleFormUnSelect: (variableId: string) => void;
}
export const sortMeasurementHelper = (values: ConfigurationValue[]) => {
  const tempSort = (values as SingletonValue[]).sort((a, b) => {
    // cast to number to prevent type error- no values here should be undefined
    const valueA = Number(a.value);
    const valueB = Number(b.value);
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  });
  const compatibilitySort = tempSort.filter((value) => !value.incompatible).concat(values.filter((value) => value.incompatible));
  return compatibilitySort as ConfigurationValue[];
};
export const ProductAttributeForm = ({
  attributeId,
  approvedUseCode,
  variables,
  handleFormSelect,
  handleFormUnSelect,
}: IProductAttributeProps) => {
  const { unitSessionMode } = useContext(ApplicationContext);
  const isMeasurementAttribute = (approvedUseCode: string, columnName: string) => {
    let result = false;
    const layer = APU_MEASUREMENT_ATTRIBUTES.find((data) => data.approvedUseName === approvedUseCode);
    if (layer) {
      result = layer.measurementAttributes.filter((a) => a.columnName === columnName).length > 0 ? true : false;
    } else {
      result = false;
    }
    return result;
  };
  // TODO: handling for attribute data mapping with form select/unselect
  /*  
      The following function getMeasurementType is for a measurement attribute which is identified by the above function isMeasurementAttribute
      No need to check the result of array find method
    */
  const getMeasurementType = (attributeId: string, columnName: string) => {
    return APU_MEASUREMENT_ATTRIBUTES.find((data) => data.approvedUseId === attributeId)?.measurementAttributes.find(
      (a) => a.columnName === columnName
    )?.measureType;
  };

  const displayMeasureUnit = (measureTypeId: MeasureType) => {
    if (measureTypeId === undefined) return '';
    if (unitSessionMode === 'Metric') {
      return <span>&nbsp;&nbsp;{MEASURE_UNITS[measureTypeId].metricUnit}</span>;
    } else {
      return <span>&nbsp;&nbsp;{MEASURE_UNITS[measureTypeId].imperialUnit}</span>;
    }
  };
  // sorts measurement values- since values are of subtype SingletonValue,
  // array is cast to that type then cast back to ConfigurationValue
  const checkValues = (options: ConfigurationValue[]) => {
    const defaultValue: SingletonValue | undefined = options.find((option) => option.assigned);
    return defaultValue?.assigned === 'byRule';
  };
  const checkOperatorValues = (values: ConfigurationValue[], operatorValues: ConfigurationValue[]) => {
    const validValues = values.filter((value) => !value.incompatible);
    // rough implementation will refine- currently happy path
    if (validValues[0].assigned) {
      operatorValues[1].incompatible = true;
    } else {
      operatorValues[1].incompatible = false;
    }
    if (operatorValues[4]) {
      if (validValues[validValues.length - 1].assigned) {
        operatorValues[4].incompatible = true;
      } else {
        operatorValues[4].incompatible = false;
      }
    }

    return operatorValues;
  };
  return (
    <div>
      <div className="row px-3 attribute-form">
        {variables?.map((variable, index) => {
          const { id, name, values, properties } = variable;
          const show =
            approvedUseCode === '25' || approvedUseCode === '35'
              ? true
              : properties?.find((prop) => prop.id === 'VariableType')?.value;

          if (
            id &&
            (!show ||
              [
                'Manufacturer_ManufacturerId',
                'ManufacturedProduct_ComponentId',
                'ExtendedLayerConfigSplit_ExtendedLayerConfigId',
                'CApprovedUse_ApprovedUseId',
                'ManufacturedProduct_CategoryId',
                'ManufacturedProduct_SubcategoryId',
                'ManufacturedProduct_CountryId'
              ]
                .concat(EXCHANGE_VARIABLES.map((data) => data.replace('_view', '')))
                .includes(id) ||
              id.endsWith('_view') ||
              id.endsWith('Operator'))
          )
            return <React.Fragment key={index}></React.Fragment>;
          if (isMeasurementAttribute(approvedUseCode as string, id as string)) {
            const measureAttrType = getMeasurementType(attributeId as string, id as string) as MeasureType;
            const operatorVariable = variables.find((data) => data.id === `${id}Operator`) as ConfigurationVariable;
            // TODO: set incompatible if >, < is an option for least/greatest options, respectively
            //  could this be handled on model end?
            // if not, helper function for operatorVariable.values? get items where incompatible = false and if assigned
            // is true, set as incompatible < if first and > if last?
            // this seems like something that should be done at configit end
            return (
              <div key={index} className="form-group col-md-12 pl-0" style={{ width: '263px' }}>
                {values && operatorVariable.values && (
                  <>
                    <div className={'advanced-selector row p-0 m-0'}>
                      <span>
                        <SelectBox
                          id={operatorVariable.id}
                          label={name}
                          options={checkOperatorValues(sortMeasurementHelper(values), operatorVariable.values)}
                          select={handleFormSelect}
                          className={'prod-operator'}
                          unSelect={handleFormUnSelect}
                          disabled={checkValues(values)}
                        />
                      </span>
                      <span className={'prod-select-value'}>
                        <div className={'section-wrapper'}>
                          <p className={''}>&nbsp;</p>
                          <SelectBox
                            id={id}
                            options={sortMeasurementHelper(values)}
                            measurementAttr={true}
                            imperialUnit={unitSessionMode === 'Metric' ? false : true}
                            measurementTypeId={measureAttrType}
                            select={handleFormSelect}
                            unSelect={handleFormUnSelect}
                          />
                        </div>
                      </span>
                      <div className={'section-wrapper'}>
                        <p className={''}>&nbsp;</p>
                        <div className={'prod-measure'}>{displayMeasureUnit(measureAttrType)}</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          } else if ((id as string).includes('WindUplift') || (id as string).includes('MaxWindRating')) {
            const measureAttrType = getMeasurementType(attributeId as string, id as string) as MeasureType;
            const operatorVariable = variables.find((data) => data.id === `${id}Operator`) as ConfigurationVariable;
            return (
              <div key={index} className="form-group col-md-12 pl-0" style={{ width: '263px' }}>
                {values && operatorVariable.values && (
                  <>
                    <div className={'advanced-selector row p-0 m-0'}>
                      <span>
                        <SelectBox
                          id={operatorVariable.id}
                          label={name}
                          options={operatorVariable.values}
                          select={handleFormSelect}
                          className={'prod-operator'}
                        />
                      </span>
                      <span className={''}>
                        <div className={'section-wrapper'}>
                          <p className={''}>&nbsp;</p>
                          <SelectBox
                            id={id}
                            options={values}
                            measurementAttr={false}
                            imperialUnit={unitSessionMode === 'Metric' ? false : true}
                            measurementTypeId={measureAttrType}
                            select={handleFormSelect}
                            unSelect={handleFormUnSelect}
                            className="product-wind-select"
                          />
                        </div>
                      </span>
                    </div>
                  </>
                )}
              </div>
            );
          } else {
            return (
              <div key={index} className="form-group col-md-12 pl-0">
                {values && (
                  <SelectBox
                    id={id}
                    label={name}
                    options={values}
                    select={handleFormSelect}
                    unSelect={handleFormUnSelect}
                    className="product-select"
                  />
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
