import React, { useState } from 'react';
import { ProductSearchResults } from './ProductSearchResults';

export const ProductSeachComponent = () => {
  return (
    <>
      <ProductSearchResults></ProductSearchResults>
    </>
  );
};
