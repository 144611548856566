import React, { useContext, useState } from 'react';
import { AccordionComponent } from '../../components/shared/AccordionComponent/AccordionComponent';
import { TextOnlyComponent } from '../../components/shared/TextOnlyComponent/TextOnlyComponent';
import { TabPanel } from '../../components/Filters';
import { styled, Tab, Tabs } from '@mui/material';
import theme from '../../theme';
import { ButtonToggleComponent } from '../../components/shared/ButtonToggleComponent/ButtonToggleComponent';
import { ApplicationContext } from '../../components/ApplicationProvider';
import './ProductSearch.scss';
import { ConfigitProductContext } from '../../components/ConfigitProductProvider';
import { ConfigitProductForm } from './ConfigitProductForm';
import { ButtonComponent } from '../../components/shared/ButtonComponent/ButtonComponent';
export const ProductFilters = () => {
  const { unitSessionMode, setUnitSessionMode } = useContext(ApplicationContext);
  const [unitCollapsed, setUnitCollapsed] = useState(false);
  const [productCollapsed, setProductCollapsed] = useState(false);
  const { variableAssignments, setVariableAssignments, section } = useContext(ConfigitProductContext);

  const resetSearch = () => {
    window.location.reload();
  };
  const toggleCollapsed = (target?: string) => {
    if (target === 'productSearch') {
      setProductCollapsed(!productCollapsed);
    } else {
      setUnitCollapsed(!unitCollapsed);
    }
  };
  const StyledTab = styled(Tab)({
    '&.MuiTab-root.Mui-selected': {
      backgroundColor: theme.palette.rnOrange.darkOrange,
      color: theme.palette.rnMisc.white,
      borderRadius: theme.borders.tabBorderRadius,
    },
    '&.MuiTab-root.Mui-selected:focus': {
      outline: 'none',
    },
    '&.MuiTab-root': {
      fontSize: '16px',
      fontWeight: 700,
      fontFamily: 'Inter',
      lineHeight: '24px',
      padding: '12px 8px',
      textTransform: 'none',
    },
  });
  const StyledTabs = styled(Tabs)({
    '&.MuiTabs-indicator': {
      backgroundColor: theme.palette.rnOrange.darkOrange,
      color: theme.palette.rnOrange.darkOrange,
    },
  });

  return (
    <div id="Filters" className={'product-filter'}>
      <div style={{ marginBottom: '3px' } as React.CSSProperties}>
        <div className={'row px-2 d-flex align-items-center justify-content-between mx-3 filter-top'}>
          <StyledTabs value={0} onChange={() => { }}>
            <StyledTab label="Products" disableRipple />
          </StyledTabs>
        </div>
      </div>
      <TabPanel index={0} value={0}>
        <div className='d-flex row px-4 py-2 justify-content-end align-items-baseline'>
          <TextOnlyComponent buttonStyle={'text-align-right'} click={resetSearch} buttonText={'Reset'} />
        </div>
        {/* <AccordionComponent
          className={''}
          id={'measureToggleAccordion'}
          title={'Metric/US Standard'}
          collapsed={unitCollapsed}
          toggleCollapsed={toggleCollapsed}
        >
          <ButtonToggleComponent
            options={['US Standard', 'Metric']}
            selectedOption={unitSessionMode}
            handleSelect={setUnitSessionMode}
            className={'mt-0'}
          />
        </AccordionComponent> */}
        <AccordionComponent
          className={''}
          id={'productSearchAccordion'}
          title={'Product Search'}
          collapsed={productCollapsed}
          toggleCollapsed={() => toggleCollapsed('productSearch')}
        >
          {section && (
            <div>
              <ConfigitProductForm
                variableAssignments={variableAssignments}
                setVariableAssignments={setVariableAssignments}
                section={section}
              />
            </div>
          )}
        </AccordionComponent>
      </TabPanel>
    </div>
  );
};
