import { Box, Button, Link, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import theme from '../../theme';
import './AssemblyDetails.scss';
import { ButtonComponent } from '../../components/shared/ButtonComponent/ButtonComponent';
import { ApplicationContext } from '../../components/ApplicationProvider';
import { Dialog } from '../../components/shared/DialogComponent/Dialog';
import TextAreaComponent from '../../components/shared/TextAreaComponent';
import { SaveModal } from './SaveModal';
import { IScenario } from '../../types/interfaces';
import { useMsal } from '@azure/msal-react';
import { ConfigitContext } from '../../components/ConfigitProvider';

export const Summary: React.FC<any> = (props: any) => {
  const { properties, usages, layers } = props;
  const { setDialog, setShowDialog } = useContext(ApplicationContext);
  const { curScenarioState, setCurScenarioState } = useContext(ConfigitContext);
  const scenarioError = useRef(false)
  useEffect(() => {
    errorCheck()
  }, [scenarioError, JSON.stringify(curScenarioState)])

  const checkIfLayerContainSelfSecurement = (layerNum: number) => {
    return usages.filter((u: any) => u.NavLayerNum === layerNum && u.SelfSecurementLayerTypeId).length > 0
      ? true
      : false;
  };
  const errorCheck = () => {
    if (scenarioError.current) {
      scenarioError.current = false
    }

    const excludedLayers: any = layers.filter((layer: any) => !invalidLayerTypeIds.includes(layer.LayerTypeId) && !layer.IsOptional);
    if (excludedLayers.length > 0 && curScenarioState.ScenarioLayers?.length === 0) {
      scenarioError.current = true
    }
    if (curScenarioState.ScenarioLayers?.length && (excludedLayers.length > curScenarioState.ScenarioLayers?.length)) {
      scenarioError.current = true
    }
    curScenarioState.ScenarioLayers?.forEach((curLayer) => {
      if (curLayer.ScenarioUsages.UsageId === -1 && !invalidLayerTypeIds.includes(curLayer.LayerTypeID)) {
        scenarioError.current = true
      }
      if (checkIfLayerContainSelfSecurement(curLayer.ScenarioUsages.LayerNum) && !curLayer.ScenarioUsages.SelfSecurementUsageId) {
        scenarioError.current = true
      }
    })

  }
  const invalidLayerTypeIds = [35, 50, 25, 18]
  const setSaveDialog = () => {
    errorCheck()
    setDialog(!scenarioError.current ?
      <Dialog label="Save Assembly">
        <>
          <SaveModal properties={properties} layers={layers} />
        </>
      </Dialog>
      :
      <Dialog label="Error">
        <>
          Please pick a component for each layer in the assembly.
        </>
      </Dialog>
    )
    setShowDialog(true);
  }
  const resetSearch = () => {
    window.location.href = window.location.href;
  };
  return (
    <div className="row p-3 assembly-summary">
      <div className="table-wrapper col-lg-6">
        <div className="d-flex bg-white">
          <div className={'col-12 p-0 summray-section'}>
            <div className={'row m-0 detail-row'}>
              <div className={'col-8 row m-0 '}>
                <div className={'col-lg-4 col-sm-5 pl-0 text-right'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnOrange.darkOrange}>
                    Assembly #
                  </Typography>
                </div>
                <div className={'col-lg-8 col-sm-7'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties.NavAssemblyName ? properties.NavAssemblyName : ''}
                  </Typography>
                </div>
              </div>
              <div className={'col-4 row m-0 '}>
                <div className={'col-lg-6 col-sm-7 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    Wind Uplift
                  </Typography>
                </div>
                <div className={'col-lg-6 col-sm-5'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties.RoofAssembly.WindUplift}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={'row m-0 detail-row'}>
              <div className={'col-8 row m-0 '}>
                <div className={'col-lg-4 col-sm-5 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    Roof System
                  </Typography>
                </div>
                <div className={'col-lg-8 col-sm-7'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties.RoofAssembly.C_AssemblyType.AssemblyTypeCode}
                  </Typography>
                </div>
              </div>
              <div className={'col-4 row m-0 '}>
                <div className={'col-lg-6 col-sm-7 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    Internal Fire
                  </Typography>
                </div>
                <div className={'col-lg-6 col-sm-5'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties.RoofAssembly.C_IntFireRating.IntFireRatingCode.trim()}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={'row m-0 detail-row'}>
              <div className={'col-8 row m-0 '}>
                <div className={'col-lg-4 col-sm-5 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    Application
                  </Typography>
                </div>
                <div className={'col-lg-8 col-sm-7'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties.RoofAssembly.C_AssemblyApplicationType.AssemblyApplicationTypeCode}
                  </Typography>
                </div>
              </div>
              <div className={'col-4 row m-0 '}>
                <div className={'col-lg-6 col-sm-7 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    External Fire
                  </Typography>
                </div>
                <div className={'col-lg-6 col-sm-5'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties.RoofAssembly.C_ExtFireRating.ExtFireRatingCode}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={'row m-0 detail-row'}>
              <div className={'col-8 row m-0'}>
                <div className={'col-lg-4 col-sm-5 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    Cover Securement
                  </Typography>
                </div>
                <div className={'col-lg-8 col-sm-7'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties.RoofAssembly.C_CoverSecurementMethod.CoverSecurementMethodCode}
                  </Typography>
                </div>
              </div>
              <div className={'col-4 row m-0 '}>
                <div className={'col-lg-6 col-sm-7 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    Hail
                  </Typography>
                </div>
                <div className={'col-lg-6 col-sm-5'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties.RoofAssembly.C_HailRating.HailRatingCode}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={'row m-0 detail-row'}>
              <div className={'col-8 row m-0 '}>
                <div className={'col-lg-4 col-sm-5 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    Deck Type
                  </Typography>
                </div>
                <div className={'col-lg-8 col-sm-7'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties.RoofAssembly.C_DeckType.DeckTypeCode}
                  </Typography>
                </div>
              </div>
              <div className={'col-4 row m-0 '}>
                <div className={'col-lg-6 col-sm-7 pl-0 text-right'}>
                  <Typography fontWeight={500} fontFamily={'Inter'}>
                    Slope
                  </Typography>
                </div>
                <div className={'col-lg-6 col-sm-5'}>
                  <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                    {properties.RoofAssembly.Slope}
                  </Typography>
                </div>
              </div>
            </div>
            {/* <PV Properties Start here> */}
            {properties.RoofAssembly.IncludesPV && (
              <>
                <div className={'row m-0 detail-row'}>
                  <div className={'col-8 row m-0'}>
                    <div className={'col-lg-4 col-sm-5 pl-0 text-right'}>
                      <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                        PV Application Method
                      </Typography>
                    </div>
                    <div className={'col-lg-8 col-sm-7'}>
                      <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                        {properties.RoofAssembly.C_PVApplicationMethod.PVApplicationMethodCode}
                      </Typography>
                    </div>
                  </div>
                  <div className={'col-4 row m-0 '}>
                    <div className={'col-lg-6 col-sm-7 pl-0 text-right'}>
                      <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                        PV Wind Uplift
                      </Typography>
                    </div>
                    <div className={'col-lg-6 col-sm-5'}>
                      <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                        {properties.RoofAssembly.PVWindUplift}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={'row m-0 detail-row'}>
                  <div className={'col-8 row m-0 '}>
                    <div className={'col-lg-4 col-sm-5 pl-0 text-right'}>
                      <Typography fontWeight={500} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                        PV Hail
                      </Typography>
                    </div>
                    <div className={'col-lg-8 col-sm-7'}>
                      <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                        {properties.RoofAssembly.C_PVHailRating.PVHailRatingCode}
                      </Typography>
                    </div>
                  </div>
                  <div className={'col-4 row m-0 '}>
                    <div className={'col-lg-6 col-sm-7 pl-0 text-right'}>
                      {/* <Typography fontWeight={500} fontFamily={'Inter'}>
                        PV Hail
                      </Typography> */}
                    </div>
                    <div className={'col-lg-6 col-sm-5'}>
                      {/* <Typography fontWeight={700} fontFamily={'Inter'} color={theme.palette.rnGrey.grey700}>
                        {properties.RoofAssembly.Slope}
                      </Typography> */}
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* <PV Properties Ends here>   */}
          </div>
        </div>
      </div>
      <div className="col-md-6 content-wrapper">
        <div className='row m-0'>
          <div className='py-2'><ButtonComponent buttonType={'primary'} buttonText={'Save to My Projects'} click={() => setSaveDialog()} /></div>
          <div className='py-2'><ButtonComponent buttonType={'secondary'} buttonText={'Reset Selections'} click={() => resetSearch()} /></div>

        </div>
        <Typography>
          * FM Approved roofs must also have corner (Zone 3) and perimeter (Zone 2) enhancements and FM Approved
          perimeter flashing. For details, see DS 1-29 and 1-49. For Standing/Lap Seam roofs, see DS 1 - 31.
        </Typography>
        <Typography>
          Adobe Acrobat Reader version 6.0 or later is required to open Property Loss Prevention Data Sheets. Click here
          to download this software for free.
        </Typography>
      </div>
    </div>
  );
};