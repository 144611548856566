import React, { useContext, useEffect, useState } from 'react';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ButtonComponent } from '../shared/ButtonComponent/ButtonComponent';
import { SelectComponent } from '../shared/SelectComponent/SelectComponent';
import { SnackbarComponent } from '../shared/SnackbarComponent';
import './SavedItems.scss';
import styled from '@emotion/styled';
import {
  getCountries,
  getProjectByProjectId,
  getStatesProvincesByCountry,
  saveUserProject,
} from '../../services/ProjectService';
import { ISelectValue } from '../shared/SelectComponent/SelectBox';
import { AddressInfo, RoofProjectBaseInfo, RoofProjectBaseInfoSave } from '../../models/GQLGeneratedModels';
import { useMsal } from '@azure/msal-react';
import { Dialog } from '../shared/DialogComponent/Dialog';
import { ErrorModalComponent } from '../shared/ErrorModalComponent/ErrorModalComponent';
import { ApplicationContext } from '../ApplicationProvider';
import { genrateUuidv4 } from '../../services/ScenarioService';
import { Loading } from '../shared/Loading/Loading';

interface IAddNewProject {
  projectid: number;
  onCloseAddNewTab: () => void;
  openAddRoofArea: () => void;
}
const StyledTextField = styled(TextField)({
  '& .MuiInputBase-multiline': {
    padding: '0px',
  },
});
export const AddNewProject = ({ projectid, onCloseAddNewTab, openAddRoofArea }: IAddNewProject) => {
  const { setDialog, setShowDialog } = useContext(ApplicationContext);
  const { instance: azureADPCA } = useMsal();
  const [errorCheck, setErrorCheck] = useState(false);
  const [apiErrorCheck, setApiErrorCheck] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [projectName, setProjectName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [indexRec, setIndexRec] = useState('');
  const [city, setCity] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [noteContents, setNoteContents] = useState('');
  const [countryOptions, setCountryOptions] = useState<ISelectValue[]>();
  const [stateProvinceOptions, setStateProvinceOptions] = useState<ISelectValue[]>();
  const [saveoperationtype, setSaveOperationType] = useState(1);
  const [addressId, setAddressId] = useState(0);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const getCountryOptions = async () => {
      const callCountryResult = await getCountries();
      setCountryOptions(mapCountryData(callCountryResult));
    };
    getCountryOptions();
  }, []);
  useEffect(() => {
    const getStateProvinceOptions = async () => {
      const callStateProvinceResult = await getStatesProvincesByCountry(Number(selectedCountry));
      setStateProvinceOptions(mapStateProvinceData(callStateProvinceResult));
    };
    getStateProvinceOptions();
  }, [selectedCountry]);
  useEffect(() => {
    if (apiErrorCheck) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent message="Please check data and resubmit" />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [apiErrorCheck]);


  // need to get typename here
  const mapCountryData = (data: any) => {
    const countryArray: ISelectValue[] = [];
    for (let index = 0; index < data.length; index++) {
      countryArray.push({ name: data[index].CountryCode, value: data[index].CountryId });
    }
    return countryArray;
  };
  const mapStateProvinceData = (data: any) => {
    const stateProvinceArray: ISelectValue[] = [];
    for (let index = 0; index < data.length; index++) {
      stateProvinceArray.push({
        name: data[index].C_StateProvince.StateProvinceCode,
        value: data[index].StateProvinceId,
      });
    }
    return stateProvinceArray;
  };

  const onProjectNameSelected = (e: any) => {
    if (e.target.value) {
      setProjectName(e.target.value);
      setErrorCheck(true);
    } else {
      setProjectName('');
      setErrorCheck(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    const getProjectById = async () => {
      if (projectid > 0) {
        const randguid = genrateUuidv4();
        const projectData = await getProjectByProjectId(projectid, randguid);
        mapProjectDetail(projectData);
      }
      setLoading(false);
    };
    getProjectById();
  }, []);
  const mapProjectDetail = (projectdata: any) => {
    setProjectName(projectdata[0]?.Name);
    setAddressId(projectdata[0]?.Address.AddressId);
    setAddress1(projectdata[0]?.Address?.Line1);
    setAddress2(projectdata[0]?.Address?.Line2);
    setCity(projectdata[0]?.Address?.City);
    setSelectedCountry(projectdata[0]?.Address?.CountryId);
    setSelectedState(projectdata[0]?.Address?.StateId);
    setZipCode(projectdata[0]?.Address?.PostalCode);
    setIndexRec(projectdata[0]?.IndexNumber);
    setProjectDescription(projectdata[0]?.Description);
    setNoteContents(projectdata[0]?.Notes);
    setErrorCheck(true);
    setSaveOperationType(2);
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    setApiErrorCheck(false);
    // N.B.- intended to put this in utility files in /utils folder, but gives hook related error. TODO: create util finction with
    // MSAL instance as argument- should be done w/dynatrace changes
    let userEmail = azureADPCA.getActiveAccount()?.username || 'test@test.com'; // works for initial Azure B2C before customization
    // For customized Azure B2C, user email needs to be retrieved as follows:
    let tokenClaims;
    let userGUID;
    if (azureADPCA.getActiveAccount()?.idTokenClaims) {
      tokenClaims = azureADPCA.getActiveAccount()?.idTokenClaims;
      if (tokenClaims && tokenClaims.hasOwnProperty('email')) {
        userEmail = (tokenClaims as any)['email'];
      }
      //  if (tokenClaims && tokenClaims.hasOwnProperty('aud')) {
      userGUID = azureADPCA.getActiveAccount()?.localAccountId as string;
    }
    const userAddress: AddressInfo = {
      City: city,
      CountryId: Number(selectedCountry),
      Line1: address1,
      Line2: address2,
      Line3: '',
      PostalCode: zipCode,
      StateId: Number(selectedState),
    };
    if (projectid > 0) userAddress.AddressId = addressId;
    const newProject: RoofProjectBaseInfo = {
      Address: userAddress,
      AzureB2CUserEmail: userEmail,
      AzureB2CUserGuid: userGUID,
      Description: projectDescription,
      IndexNumber: indexRec,
      Name: projectName,
      Notes: noteContents,
      ProjectId: projectid,
    };

    const newProjectInfo: RoofProjectBaseInfoSave = {
      Project: newProject,
      SaveOperationType: saveoperationtype,
    };
    const result = await saveUserProject(newProjectInfo);
    if (newProject.Name && result) {
      setApiErrorCheck(false);
      setErrorCheck(false);
      if (result) {
        setOpen(true);
      }
    } else {
      if (!result) {
        setApiErrorCheck(true);
      }
      setErrorCheck(true);
    }
  };
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (event) {
      setOpen(false);
      return;
    }
  };

  return (
    <>
      <Box className={'d-flex rn-new-project justify-content-between'}>
        {loading ?
          <div className="d-flex justify-content-center align-items-center col-12 mt-5">
            <Loading />
          </div>
          :
          <>
            <Box component="form" className="col-xxl-9 col-xl-8" noValidate>
              <br />
              <div className={'row'}>
                <div className={'col-lg-6'}>
                  <div className="my-3">
                    <Typography className={'small-para-semibold '}>
                      Project Name: <span className="roofnav-error">*</span>
                    </Typography>
                    <TextField
                      value={projectName}
                      onChange={onProjectNameSelected}
                      id="projectNameInput"
                      placeholder="Project Name"
                      size="small"
                      error={errorCheck && projectName.length == 0}
                    />
                    {errorCheck && projectName.length == 0 && (
                      <Typography className={'small-para roofnav-error pt-1'}>
                        Please submit a value for project name
                      </Typography>
                    )}
                  </div>
                  <div className="my-3">
                    <Typography className={'small-para-semibold '}>Address 1:</Typography>
                    <TextField
                      value={address1}
                      onChange={(e) => setAddress1(e.target.value)}
                      id="address1Input"
                      placeholder="Address Line 1"
                      size="small"
                    />
                  </div>
                  <div className="my-3">
                    <Typography className={'small-para-semibold '}>Address 2:</Typography>
                    <TextField
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                      id="address2Input"
                      placeholder="Address Line 2"
                      size="small"
                    />
                  </div>
                  <div className="my-3">
                    <Typography className={'small-para-semibold '}>City:</Typography>
                    <TextField
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      id="cityInput"
                      placeholder="City"
                      size="small"
                    />
                  </div>
                  <div className="my-3">
                    <Typography className={'small-para-semibold '}>Country:</Typography>
                    <SelectComponent
                      value={selectedCountry}
                      id={'countrySelection'}
                      className="as-2 project-select mr-2"
                      options={countryOptions ?? [{ name: 'Select', value: 0 }]}
                      setValue={(value) => {
                        setSelectedCountry(value);
                        setSelectedState('');
                      }}
                    />
                  </div>
                  <div className="my-3">
                    <Typography className={'small-para-semibold '}>State/Province:</Typography>
                    <SelectComponent
                      id={'stateSelection'}
                      className="as-2 project-select mr-2"
                      options={stateProvinceOptions ?? [{ name: 'Select', value: 0 }]}
                      setValue={(value) => setSelectedState(value)}
                      value={selectedState}
                    />
                  </div>
                  <div className="my-3">
                    <Typography className={'small-para-semibold '}>Zip/Postal Code:</Typography>
                    <TextField
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                      id="zipInput"
                      placeholder=""
                      size="small"
                      margin="none"
                    />
                  </div>
                  <div className="my-3">
                    <Typography className={'small-para-semibold '}>Index/Rec #:</Typography>
                    <TextField
                      value={indexRec}
                      onChange={(e) => setIndexRec(e.target.value)}
                      id="indexInput"
                      placeholder=""
                      size="small"
                    />
                  </div>
                </div>
                <div className={'col-lg-6'}>
                  <div className="my-3">
                    <Typography className={'small-para-semibold '}>Project Description:</Typography>
                    <StyledTextField
                      value={projectDescription}
                      onChange={(e) => setProjectDescription(e.target.value)}
                      id="projectDescriptionInput"
                      placeholder="Enter description about the project"
                      multiline
                      rows={2}
                    />
                  </div>
                  <div className="my-3">
                    <Typography className={'small-para-semibold '}>Project Notes:</Typography>
                    <StyledTextField
                      value={noteContents}
                      onChange={(e) => setNoteContents(e.target.value)}
                      id="projectNotesnInput"
                      placeholder="Notes about this project are optional"
                      multiline
                      rows={2}
                    />
                  </div>
                </div>
              </div>
            </Box>
            <div className={'right-section'}>
              <ButtonComponent isDisabled={!errorCheck} buttonType="primary" buttonText="Save" click={onSubmit} />
              <ButtonComponent
                isDisabled={projectid === 0}
                buttonType="secondary"
                buttonText="Add Roof Area"
                click={openAddRoofArea}
              />
              <div>
                <ButtonComponent buttonType="secondary" buttonText="Cancel" click={onCloseAddNewTab} />
              </div>
            </div>
          </>
        }

      </Box>
      <SnackbarComponent open={open} handleClose={handleClose} message={'Project successfully saved'} />
    </>
  );
};
