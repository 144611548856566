import { styled, Tab, Tabs } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import theme from '../../theme';
import { ApplicationContext } from '../ApplicationProvider';
import { AssemblyNumber } from '../FindYourRoof/AssemblyNumber';
import { AccordionComponent } from '../shared/AccordionComponent/AccordionComponent';
import { ButtonToggleComponent } from '../shared/ButtonToggleComponent/ButtonToggleComponent';
import { TextOnlyComponent } from '../shared/TextOnlyComponent/TextOnlyComponent';
import { AssemblyProps } from './AssemblyProps';
import './Filters.scss';
import { ProductsUsed } from './ProductsUsed';
import { RoofLayers } from './RoofLayers';
import { ButtonComponent } from '../shared/ButtonComponent/ButtonComponent';
import { useNavigate } from 'react-router';
import { ConfigitContext } from '../ConfigitProvider';
import { ToolTipComponent } from '../shared/TooltipComponent/ToolTipComponent';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export const Filters = () => {
  const { unitSessionMode, setUnitSessionMode } = useContext(ApplicationContext);
  const { defaultTab, setDefaultTab } = useContext(ApplicationContext);
  const { clearSearch, updateConfigitCriteria } = useContext(ConfigitContext);
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const { triggerSearchResultCount, setTriggerSearchResultCount } = useContext(ApplicationContext);
  const { triggerRoofTypeList, setTriggerRoofTypeList } = useContext(ApplicationContext);
  const { triggerAssemblyList, setTriggerAssemblyList } = useContext(ApplicationContext);
  const { numShowResult, setNumShowResult } = useContext(ApplicationContext);


  const resetSearch = () => {
    // window.location.reload();

    // setTriggerSearchResultCount(true);
    // setTriggerRoofTypeList(true);
    // setTriggerAssemblyList(true);
    // setNumShowResult(numShowResult + 1);
    // clearSearch();
    // updateConfigitCriteria([], [], [])
    //  window.location.reload();
    navigate(`/home?${params.toString()}`);
    window.location.reload();

  };
  const [unitCollapsed, setUnitCollapsed] = useState(false);
  const [calcCollapsed, setCalcCollapsed] = useState(false);
  const [assemblyCollapsed, setAssemblyCollapsed] = useState(true);
  const { showProductsUsed } = useContext(ApplicationContext);
  const { autoRefreshSearchResult, setAutoRefreshSearchResult } = useContext(ApplicationContext);
  const [autoValue, setAutoValue] = useState(localStorage.getItem('userPreferenceSearch') === 'Auto-Refresh' ? 'Auto-Refresh' : 'Manual');
  const UOMCopy = 'Choose the preferred unit of measure in your search criteria and results.';
  const searchToggleCopy = 'Choose “Auto” for immediate updates on criteria changes, or “Manual” to update only when you click the “Search” button.';

  useEffect(() => {
    if (showProductsUsed) {
      setAssemblyCollapsed(false);
    }
  }, [showProductsUsed]);
  const toggleAutoValue = (autoValueOption: string) => {
    localStorage.setItem('userPreferenceSearch', autoValueOption)
    setAutoValue(autoValueOption);
    setAutoRefreshSearchResult(autoValueOption === 'Auto-Refresh');
    setNumShowResult(numShowResult + 1);
  };
  const toggleUOM = (UOMOption: string) => {
    localStorage.setItem('userPreferenceUOM', UOMOption)
    setUnitSessionMode(UOMOption)
  };
  const toggleCollapsed = (target?: string) => {
    //Once calculator is added back, will set up logic for toggling individual accordion that is not in a subcomponent, or move accordion to new subcomponent

    if (target === 'calculator') {
      setCalcCollapsed(!calcCollapsed);
    } else if (target === 'assemblySearch') {
      setAssemblyCollapsed(!assemblyCollapsed);
    } else {
      setUnitCollapsed(!unitCollapsed);
    }
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // TODO: there's some range error w/recursive calls that seems to involve tab switching. Was resolved but reappeared. Unable to replicate at the moment- TM
    if (defaultTab != newValue) setDefaultTab(newValue);
  };
  const StyledTab = styled(Tab)({
    '&.MuiTab-root.Mui-selected': {
      backgroundColor: theme.palette.rnOrange.darkOrange,
      color: theme.palette.rnMisc.white,
      borderRadius: theme.borders.tabBorderRadius,
    },
    '&.MuiTab-root.Mui-selected:focus': {
      outline: 'none',
    },
    '&.MuiTab-root': {
      fontSize: '16px',
      fontWeight: 700,
      fontFamily: 'Inter',
      lineHeight: '24px',
      padding: '12px 8px',
      textTransform: 'none',
    },
  });
  const StyledTabs = styled(Tabs)({
    '&.MuiTabs-indicator': {
      backgroundColor: theme.palette.rnOrange.darkOrange,
      color: theme.palette.rnOrange.darkOrange,
    },
  });
  const handleShowSearchResult = () => {
    setTriggerSearchResultCount(true);
    setTriggerRoofTypeList(true);
    setTriggerAssemblyList(true);
    setNumShowResult(numShowResult + 1);
    // TBD- are there any cases where we don't want this redirect?
    if (location.pathname.indexOf('assembliesListing') < 0) {
      navigate(`assembliesListing?${params.toString()}`);
    }

  };

  return (
    <div id="Filters" className={'rnfilter'}>
      <div style={{ marginBottom: '3px' } as React.CSSProperties}>
        <div className={'row px-2 d-flex align-items-center justify-content-between mx-3 filter-top'}>
          <StyledTabs value={defaultTab} onChange={handleChange}>
            <StyledTab label="Assemblies" disableRipple />
            <StyledTab label="Tools" disableRipple />
          </StyledTabs>
        </div>
      </div>

      <TabPanel index={0} value={defaultTab}>
        <div className='d-flex row px-4 py-2 justify-content-end align-items-baseline'>
          <TextOnlyComponent buttonStyle={'text-align-right pb-2 clearsearch'} click={resetSearch} buttonText={'Reset'} />

          {!autoRefreshSearchResult &&
            <div> <ButtonComponent
              buttonType='primary'
              buttonText='Search'
              click={handleShowSearchResult}
              textSize={'14px'}
              buttonStyle='py-1'
            />
              &nbsp;
              &nbsp;
            </div>
          }
        </div>
        <AssemblyProps />
        <RoofLayers />
        <AccordionComponent
          className={''}
          id={'measureToggleAccordion'}
          title={'Preferences'}
          collapsed={unitCollapsed}
          toggleCollapsed={toggleCollapsed}
        >
          <div className='d-flex align-items-center'>
            <ButtonToggleComponent
              options={['US Standard', 'Metric']}
              selectedOption={unitSessionMode}
              handleSelect={toggleUOM}
              className={'mt-0'}
            />
            <ToolTipComponent content={UOMCopy} position={"right"} />
          </div>
          <br></br>
          <div className='d-flex align-items-center'>
            <ButtonToggleComponent
              options={['Manual', 'Auto-Refresh']}
              selectedOption={autoValue}
              handleSelect={toggleAutoValue}
              className={'mt-0 attribute-select'}
            />
            <ToolTipComponent content={searchToggleCopy} position={"right"} />

          </div>
        </AccordionComponent>
      </TabPanel>
      <TabPanel index={1} value={defaultTab}>
        <div className='d-flex row px-4 py-2 justify-content-end align-items-baseline'>
          <TextOnlyComponent buttonStyle={'text-align-right pb-2 clearsearch'} click={resetSearch} buttonText={'Reset'} />
          {!autoRefreshSearchResult &&
            <> <ButtonComponent
              buttonType='primary'
              buttonText='Search'
              click={handleShowSearchResult}
              textSize={'14px'}
              buttonStyle='py-1'
            />
              &nbsp;
              &nbsp;
            </>
          }
        </div>
        <AccordionComponent className={''} id={'ratingsCalculatorAccordion'} title={'Ratings Calculator'} collapsed={calcCollapsed} toggleCollapsed={() => toggleCollapsed('calculator')} >
          <ButtonComponent click={() => navigate("/ratingsCalculator")} iconName="calculate" buttonType={'primary'} buttonText={'Launch Ratings Calculator'} />
        </AccordionComponent>
        <ProductsUsed />
        <AccordionComponent
          className={''}
          id={'assemblySearchAccordion'}
          title={'Assembly Search'}
          collapsed={assemblyCollapsed}
          toggleCollapsed={() => toggleCollapsed('assemblySearch')}
        >
          <AssemblyNumber />
        </AccordionComponent>
      </TabPanel>
    </div>
  );
};
