import React, { useContext } from 'react';
import { ISelfSecurement, IVariableAssigmentLabels } from '../ConfigitProvider';
import { ApplicationContext } from '../ApplicationProvider';
import { LAYER_MEASUREMENT_ATTRIBUTES, MEASURE_UNITS } from '../constants/MEASURE_UNITS';
import { MeasureType } from '../constants/ENUMS';
import { UnitConversion } from '../../utils/Measurement/UnitConversion';
import { EXCHANGE_VARIABLES } from '../constants/EXCHANGE_VARIABLES';
import { ChipComponent } from '../shared/ChipComponent/ChipComponent';

interface ISelfSecurementConfigSummaryProps {
  selfSecurement: ISelfSecurement;
  baseLayerIndex: number;
}

export const SelfSecurementConfigSummary: React.FC<ISelfSecurementConfigSummaryProps> = ({
  selfSecurement,
  baseLayerIndex,
}: ISelfSecurementConfigSummaryProps) => {
  const { unitSessionMode } = useContext(ApplicationContext);

  const productList: string[] = [];
  const products = selfSecurement.productDetails
    ?.filter((data) => data.productId !== '' || data.manufacturerId !== '')
    ?.map((data) => {
      if (data.manufacturerLabel && data.productLabel)
        return productList.push(`Manufacturer: ${data.manufacturerLabel} and Product: ${data.productLabel}`);
      if (data.manufacturerLabel) return productList.push(`Manufacturer: ${data.manufacturerLabel}`);
      if (data.productLabel) return productList.push(`Product: ${data.productLabel}`);
    });

  const getAttributeDisplayText = (variableAssignmentLabels: IVariableAssigmentLabels) => {
    const targetLayer = LAYER_MEASUREMENT_ATTRIBUTES.find((data) => data.layerTypeId === selfSecurement.idLayerType);
    let isMeasureAttribute = false;
    if (targetLayer) {
      isMeasureAttribute =
        targetLayer.measurementAttributes.filter((lyr) => lyr.columnName === variableAssignmentLabels.variableId)
          .length > 0
          ? true
          : false;
    } else {
      isMeasureAttribute = false;
    }
    if (isMeasureAttribute) {
      const measureType = LAYER_MEASUREMENT_ATTRIBUTES.find(
        (data) => data.layerTypeId === selfSecurement.idLayerType
      )?.measurementAttributes.find((a) => a.columnName === variableAssignmentLabels.variableId)?.measureType;
      const iUnit = MEASURE_UNITS[measureType as MeasureType].imperialUnit;
      const mUnit = MEASURE_UNITS[measureType as MeasureType].metricUnit;
      const mValue = parseFloat(variableAssignmentLabels.valueLabel as string).toFixed(4);
      const iValue = UnitConversion.convertMetricToImperial(
        parseFloat(variableAssignmentLabels.valueLabel as string),
        measureType as MeasureType
      );
      const operator = selfSecurement.variableAssignmentLabels?.find(
        (data) => data.variableId === `${variableAssignmentLabels.variableId}Operator`
      )?.valueLabel;
      return unitSessionMode === 'Metric'
        ? `${variableAssignmentLabels.variableLabel} ${operator} ${mValue} ${mUnit}`
        : `${variableAssignmentLabels.variableLabel} ${operator} ${iValue} ${iUnit}`;
    } else {
      return `${variableAssignmentLabels.variableLabel}: ${variableAssignmentLabels.valueLabel}`;
    }
  };

  const attributes = selfSecurement.variableAssignmentLabels?.map((data, index) => {
    if (
      !(
        data.variableId === 'ComponentId' ||
        data.variableId === 'ComponentId_view' ||
        data.variableId === 'ManufacturerId' ||
        data.variableId === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId' ||
        EXCHANGE_VARIABLES.includes(`${data.variableId}_view`) ||
        data.variableId?.endsWith('Operator')
      )
    )
      return (
        <ChipComponent
          key={'selfSecurementAttributes' + index}
          className={'m-1'}
          label={getAttributeDisplayText(data)}
        />
      );
  });

  if (!(products || attributes)) return <></>;
  return (
    <div className="layer-bg p-1 pl-3 mx-1">
      {products
        ? productList?.map((item, index) => {
            return <ChipComponent key={index + item} className={'m-1'} label={`${item}`} />;
          })
        : null}
      {attributes}
    </div>
  );
};
