import { useMsal } from '@azure/msal-react';
import { Box, Card, Divider, styled, Typography } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';
import { azureAADPublicClientApplication, scopesToUse } from '../../Auth';
import { ButtonComponent } from '../../components/shared/ButtonComponent/ButtonComponent';
import { TextLinkComponent } from '../../components/shared/TextLinkComponent/TextLinkComponent';
import theme from '../../theme';
import SplashFooter from './SplashFooter';
import { SplashHeader } from './SplashHeader';

export const Login: React.FC = (props: PropsWithChildren<unknown>) => {
  const { children } = props;
  const { inProgress } = useMsal();
  console.log(inProgress);
  const [loginError, setLoginError] = useState<Error>();
  const SplashCard = styled(Card)({
    '&.MuiCard-root': {
      backgroundImage: `url(/assets/rn-splash.jpg)`,
      height: '80vh',
      minHeight: '650px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  });

  const LoginCard = styled(Card)({
    '&.MuiCard-root': {
      borderRadius: '6px',
      background: 'rgba(248, 247, 244, 0.78)',
      width: '678px',
      // minHeight: '479px',
      height: 'fit-content',
      marginRight: '10%',
      marginTop: '4%',
      marginLeft: '10px',
    },
  });
  const MainBox = styled(Box)({
    '&.MuiBox-root': {
      background: theme.palette.rnSteel.steel800,
    },
  });
  const NewsBox = styled(Box)({
    '&.MuiBox-root': {
      padding: '1rem',
      background: theme.palette.rnMisc.white,
    },
  });
  const MidDivider = styled(Divider)({
    '&.MuiDivider-root': {
      background: theme.palette.rnMisc.white,
      width: '1px',
    },
  });
  const NewsTypography = styled(Typography)({
    '&.MuiTypography-root': {
      fontWeight: 400,
      fontSize: '16px',
      fontFamily: 'Inter',
      lineHeight: '24px',
    },
  });

  const login = () => {
    azureAADPublicClientApplication
      .loginRedirect({
        scopes: scopesToUse,
        state: btoa(location.pathname + location.search),
        ...(!Boolean(process.env.REACT_APP_AAD_REDIRECT_URL) && { redirectUri: location.origin }),
      })
      .catch((error) => {
        console.log(error);
        setLoginError(error);
      });
  };

  return (
    <div>
      <SplashHeader />
      <div>
        {/* {inProgress !== InteractionStatus.None && <div>Loading</div>} */}
        {loginError && <div>Error with login: {loginError.toString()}</div>}
        <SplashCard className={'d-flex justify-content-end'}>
          <LoginCard>
            <div className={'m-4'}>
              <div className="row m-2">
                <Typography className={'splash-para pb-2'}>
                  FM Approved roof assemblies and products. Access to roofing related Approval Standards and FM Property Loss Prevention Data Sheets are also available.
                </Typography>
                <Typography className={'splash-para pb-2'}>
                  <TextLinkComponent
                    linkStyle="font-weight-bold"
                    isExternal
                    linkText="Click here"
                    linkUrl={`/quickstart`}
                  />
                  &nbsp;to read about the new capabilities of RoofNav including how to login and register.
                </Typography>
                <Typography className={'splash-para pb-2'}>
                  To register for a new account, click on the "Login" button.
                </Typography>
              </div>
            </div>
            <div className="mx-5 mt-2 mb-5 float-right row">
              <ButtonComponent buttonText={'Login'} click={login} buttonType={'primary'} buttonStyle={'ml-2 mb-1'} />
            </div>
          </LoginCard>
        </SplashCard>
        <MainBox className={'row m-0 py-5 justify-content-center'}>
          <div className={'row w-100'}>
            <div className={'col-lg-6 col-md-12 row justify-content-center align-items-center mb-3'}>
              <div className="col-3 d-md-none"></div>
              <div className="col-4 d-flex justify-content-end">
                <img src="/assets/fma-diamond.svg" width={'177px'} height={'117px'} />
              </div>
              <MidDivider className="h-100" variant={'middle'} />
              <div className={'text-center col-4'}>
                <Typography lineHeight={'24px'} fontSize={'22px'} fontWeight={600} color={theme.palette.rnMisc.white}>
                  What's New
                </Typography>
                <Typography className={'standard-para'} color={theme.palette.rnMisc.white}>
                  We continue to update RoofNav with new features to enhance your experience.
                </Typography>
              </div>
            </div>
            <div className={'col-lg-6 col-md-12 row m-0 mb-3'}>
              <div className={'d-flex justify-content-center'}>
                <NewsBox className={'col-lg-9 col-md-12 rounded'}>
                  <NewsTypography className={'splash-para pb-2'}>
                    6/28 - Update - Disabled auto-add securements to fix inconsistent assembly search results and added "Country" as a search criteria in "Product Search"
                  </NewsTypography>
                </NewsBox>
              </div>
            </div>
          </div>
        </MainBox>
      </div>
      <SplashFooter />
    </div>
  );
};
