import { Table, TableBody, TableCell, TableRow, styled } from '@mui/material';
import React from 'react';
import theme from '../../theme';
import './RefMaterial.scss';

import { StyledTableCell } from '../../components/shared/TableComponent/TableComponent';

export const UnitConversions = () => {
  const rowborder = {
    border: '1px solid',
    borderColor: theme.palette.rnGrey.grey50,
  };
  const colborder = {
    border: '2px solid',
    borderColor: theme.palette.rnGrey.grey50,
  };
  const rowStyle = {
    backgroundColor: theme.palette.rnGrey.grey50,
  };
  const GuideLineTableRow = styled(TableRow)({
    '&.MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: theme.palette.rnMisc.white,
    },
    '&.MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: theme.palette.rnMisc.white,
    },
  });
  return (
    <main className="main-content container-fluid flex-shrink-0 refmaterial-ui">
      <div className={'px-4'}>
        <Table style={{ width: '100%', border: 1 }}>
          <TableBody>
            <GuideLineTableRow style={rowStyle}>
              <StyledTableCell style={colborder} className={'summary-bold-text'}>
                Property
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'summary-bold-text'}>
                To Convert From
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'summary-bold-text'}>
                To
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'summary-bold-text'}>
                Multiply By
              </StyledTableCell>
            </GuideLineTableRow>
            {/* Apllication */}
            <GuideLineTableRow style={rowborder}>
              <TableCell rowSpan={2} style={colborder} className={'modal-info-bold-text'}>
                Application Rate:
              </TableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                Gal/sq (100 ft<sup>2</sup>)
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                L/m<sup>2</sup>
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                0.4075
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                gal/sq
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                liter/sq meter
              </StyledTableCell>
            </GuideLineTableRow>
            {/* Area  */}
            <GuideLineTableRow style={rowborder}>
              <TableCell rowSpan={4} style={colborder} className={'modal-info-bold-text'}>
                Area:
              </TableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                in<sup>2</sup>
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                mm<sup>2</sup>
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                6.4516 x 10<sup>2</sup>
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                sq in
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                sq millimeters
              </StyledTableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                sq ft
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                m<sup>2</sup>
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                0.0929
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                sq ft
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                sq meters
              </StyledTableCell>
            </GuideLineTableRow>
            {/* Coverage */}
            <GuideLineTableRow style={rowborder}>
              <TableCell rowSpan={2} style={colborder} className={'modal-info-bold-text'}>
                Coverage:
              </TableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                ft<sup>2</sup>/gal
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                m<sup>2</sup>/L
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                0.02454
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                sq ft/gal
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                sq meter/liter
              </StyledTableCell>
            </GuideLineTableRow>
            {/* Density: */}
            <GuideLineTableRow style={rowborder}>
              <TableCell rowSpan={2} style={colborder} className={'modal-info-bold-text'}>
                Density:
              </TableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                lb/ft<sup>3</sup>/gal
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                kg/m<sup>3</sup>
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                16.02
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                b/cubic ft
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                kilogram/cubic meter
              </StyledTableCell>
            </GuideLineTableRow>
            {/* Force: */}
            <GuideLineTableRow style={rowborder}>
              <TableCell rowSpan={2} style={colborder} className={'modal-info-bold-text'}>
                Force:
              </TableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                lbf
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                N
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                4.448
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                lb force
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                Newton
              </StyledTableCell>
            </GuideLineTableRow>
            {/* Length */}
            <GuideLineTableRow style={rowborder}>
              <TableCell rowSpan={4} style={colborder} className={'modal-info-bold-text'}>
                Length:
              </TableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                in
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                mm
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                25.4
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                in
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                millimeter
              </StyledTableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                ft
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                m
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                0.3048
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                ft
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                meter
              </StyledTableCell>
            </GuideLineTableRow>
            {/* Mass */}
            <GuideLineTableRow style={rowborder}>
              <TableCell rowSpan={2} style={colborder} className={'modal-info-bold-text'}>
                Force:
              </TableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                lbf
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                N
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                4.448
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                lb force
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                Newton
              </StyledTableCell>
            </GuideLineTableRow>
            {/* Mass */}
            <GuideLineTableRow style={rowborder}>
              <TableCell rowSpan={4} style={colborder} className={'modal-info-bold-text'}>
                Mass:
              </TableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                lb
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                kg
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                0.4536
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                lb
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                kilogram
              </StyledTableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                oz
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                g
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                28.35
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                ounce
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                gram
              </StyledTableCell>
            </GuideLineTableRow>
            {/* Mass/Unit Area: */}
            <GuideLineTableRow style={rowborder}>
              <TableCell rowSpan={4} style={colborder} className={'modal-info-bold-text'}>
                Mass/Unit Area:
              </TableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                lb/ft<sup>2</sup>
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                kg/m<sup>2</sup>
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                4.882
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                lb/sq ft
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                kilogram/sq meter
              </StyledTableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                oz/yd<sup>2</sup>
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                g/m<sup>2</sup>
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                33.91
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                ounce/sq yard
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                gram/meter
              </StyledTableCell>
            </GuideLineTableRow>
            {/* Pressure */}
            <GuideLineTableRow style={rowborder}>
              <TableCell rowSpan={4} style={colborder} className={'modal-info-bold-text'}>
                Pressure:
              </TableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                psi
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                kPa
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                6.895
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                lb/sq in
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                kilopascals
              </StyledTableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                psf
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                kPa
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                0.04788
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                lb/sq ft
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                kilopascals
              </StyledTableCell>
            </GuideLineTableRow>
            {/* Temperature: */}
            <GuideLineTableRow style={rowborder}>
              <TableCell style={colborder} className={'modal-info-bold-text'}>
                Temperature:
              </TableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                degrees Fahrenheit
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                degrees Celsius
              </StyledTableCell>
              <TableCell className={'modal-info-bold-text'}>(Fahrenheit temp - 32) * 5/9</TableCell>
            </GuideLineTableRow>
            {/* Volume */}
            <GuideLineTableRow style={rowborder}>
              <TableCell rowSpan={4} style={colborder} className={'modal-info-bold-text'}>
                Volumes:
              </TableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                gal
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                L
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                3.785
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                gal
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                liter
              </StyledTableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                ft<sup>3</sup>
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                m<sup>3</sup>
              </StyledTableCell>
              <TableCell rowSpan={2} className={'modal-info-bold-text'}>
                0.02832
              </TableCell>
            </GuideLineTableRow>
            <GuideLineTableRow style={rowborder}>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                cubic ft
              </StyledTableCell>
              <StyledTableCell style={colborder} className={'modal-info-bold-text'}>
                cubic meter
              </StyledTableCell>
            </GuideLineTableRow>
          </TableBody>
        </Table>
      </div>
    </main>
  );
};
