import { FMGTelemetryOptions } from 'fmg-telemetry-react';
// TODO: check which of these variables are needed, which need to be modified, etc
const appNodeEnv = process.env.REACT_APP_NODE_ENV;
const applicationName = process.env.REACT_APP_TELEMETRY_APP_NAME ?? 'FMG.Approvals.RoofNavNext.UI.EnvVarsNotLoaded';

const exportToConsole = process.env.REACT_APP_FMG_TELEMETRY_EXPORT_TO_CONSOLE !== 'false'; // Sends all telemetry to console. Very noisy.
const localOnlyMode = process.env.REACT_APP_FMG_TELEMETRY_LOCAL_ONLY_MODE !== 'false'; // Does not contact Dynatrace, Sends Log statements to console. Use this flag during development of new logging.
const logToConsole = process.env.REACT_APP_FMG_TELEMETRY_LOG_TO_CONSOLE !== 'false'; // Sends Log statements to console.

const allowedTraceUrls: RegExp[] = [];

const logLevel = process.env.REACT_APP_LOG_LEVEL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const dtConfig = process.env.REACT_APP_DYNATRACE_CONFIG;

if (appNodeEnv !== 'production') {
    allowedTraceUrls.push(/http:\/\/localhost:8080/);
    allowedTraceUrls.push(/http:\/\/localhost:3000/);
    allowedTraceUrls.push(/^http:\/\/localhost:5100(?:\/[\w-]+)*(?:\?[\w=&-]+)?/);
}

// Basic config needed for FMG Telemetry context
const FMGTelemetrySetup: FMGTelemetryOptions = {
    servicename: applicationName,
    allowedTraceUrls,
    exportToConsole,
    localOnlyMode,
    logToConsole,
    logLevel,
    environment,
    dtConfig,
};

export default FMGTelemetrySetup;
