import { ApolloClient, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { azureAADPublicClientApplication } from './Auth';
import { fetchFreshToken } from './AuthHelper';
import { apolloCache } from './models/CacheAndSettings';

export const fallbackURL = 'http://localhost:8080/v1/graphql';
if (!process.env.REACT_APP_GRAPHQL_API_URL) {
  console.log(`No env variable found for REACT_APP_GRAPHQL_API_URL. Using fallback ${fallbackURL}`);
}
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL || fallbackURL,
  credentials: 'same-origin',
});

const authLink = setContext(async (request, { headers }) => {
  const token = (await fetchFreshToken()) || '';

  // roofnav-user will be the default role for this app, change if needed
  // but you can specify a different role in the context of the query if needed
  const requestedRole = request?.context?.preferredRole || 'roofnav-user';
  let roleToUse = 'roofnav-user';
  const activeUser = azureAADPublicClientApplication.getActiveAccount();
  if (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((activeUser?.idTokenClaims as any)?.['dce-roles'] as string[])?.some(
      (currentUserRole) => currentUserRole === requestedRole
    )
  ) {
    roleToUse = requestedRole;
  }
  return {
    headers: {
      ...headers,
      'hasura-client-name': process.env.REACT_APP_X_HASURA_CLIENT_NAME,
      ...((process.env.REACT_APP_X_HASURA_ADMIN_SECRET && {
        'x-hasura-admin-secret': process.env.REACT_APP_X_HASURA_ADMIN_SECRET,
      }) || {
        Authorization: token ? `Bearer ${token}` : '',
        // it is necessary to set a preferred role with queries, in the event a user has multiple roles
        // otherwise Hasura will just choose the first role in the list of a user's (many) roles
        // this could lead to unexpected permissions issues
        ...(roleToUse && { 'x-hasura-role': roleToUse }),
      }),
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: apolloCache,
});
