import { Button, Link, Typography } from '@mui/material';
import React from 'react';
import theme from '../../theme';
import './RefMaterial.scss';
import { TextLinkComponent } from '../../components/shared/TextLinkComponent/TextLinkComponent';
import { TextOnlyComponent } from '../../components/shared/TextOnlyComponent/TextOnlyComponent';

export const Help = () => {
  const handleBackClick = () => {
    history.back();
  };
  return (
    <main className="main-content container-fluid flex-shrink-0 refmaterial-ui">
      <div className={'px-4'}>
        <div className="d-flex justify-content-between align-items-center">
          <Typography className={'headline-1 py-5'} color={theme.palette.rnSteel.steel700}>
            RoofNav Help
          </Typography>
          <Link onClick={handleBackClick} className="d-flex float-right">
            <TextOnlyComponent buttonText={'Back'} iconName={'arrow_left'} />
          </Link>
        </div>
        <Typography className={'large-para pb-4'}>
          Roof Navigator (RoofNav) from FM Approvals is the definitive source of up-to-date information on FM Approved
          roofing assemblies and products. Our goal is to assist you in identifying and configuring FM Approved roofing
          assemblies by making all the information you need for your roofing needs readily accessible.
        </Typography>
        <Typography className={'large-para pb-4'}>
          The following information is intended to assist you in getting the most out of your use of the RoofNav
          website. We hope you will find RoofNav relatively easy to use. If you review this information and the other
          online Help facilities of RoofNav, and still have questions regarding the use of the website, please contact
          RoofNav support via the "Support" link at the top of any RoofNav page.
        </Typography>
        <Typography className={'large-para pb-5'}>
          RoofNav is a comprehensive application designed to offer various methods of accessing the information
          contained within. We hope these tools provide you with the means to effectively address your roofing
          questions, and the flexiblity to manage the information in a manner suited to your situation.
        </Typography>

        <div className={'pb-2'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            Getting Started
          </Typography>
        </div>
        <div className={'large-para pb-2'}>
          <Typography className={'large-para pb-2'}>
            Facility available to help you get started with RoofNav:
            <span className={'pb-2'}>
              <ul className={'pb-2'} style={{ marginLeft: '5%' }}>
                <li>
                  Review the&nbsp;
                  <b>
                    <TextLinkComponent linkText={'RoofNav v3.0 Quick Start Guide'} linkUrl={'/quickstart'} isExternal />
                  </b>
                  &nbsp;for an overview of the RoofNav v3.0 application.
                </li>
                {/* <li>
                  Click "Training" at the top of any RoofNav page and view our 60 minute, pre-recorded, online training
                  session.
                </li> */}
              </ul>
            </span>
          </Typography>
        </div>
        <div className={'pb-2'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            RoofNav Tools / Sectional Help
          </Typography>
        </div>
        <Typography className={'large-para pb-5'}>
          The various tools available in RoofNav are divided into sections and presented on the main navigation bar.
          Below is a brief description of each section, and links to additional help for the use of the tools in these
          sections. If you are unable to find the help you need from the information below, please click the "Support"
          link at the top of any RoofNav page for information on how to contact FM Approvals for additional assistance.
        </Typography>
        {/* <div className={'pb-2'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            My Projects
          </Typography>
        </div>
        <Typography className={'large-para pb-5'}>
          The My Projects section provides you with tools to organize all of your roofing projects, associate and save
          FM Approved roofing assembly "scenarios" with your projects, and generate Contractor Package Reports. For more
          information on how to use these tools, please click the link below:
          <br></br>
          <Button className={'button-link'} style={{ textTransform: 'none' }}>
            How to use the My Projects tools
          </Button>
        </Typography>
        */}
        <div className={'pb-2'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            Product Search
          </Typography>
        </div>
        <Typography className={'large-para pb-5'}>
          The Product Search section contains two search tools; Product Search, and Product Systems Search. Product
          Search allows you to specify detailed criteria to find products contained in FM Approved roofing assemblies.
          Product System Search is used to find combinations of products such as Stress Distributors and Fasteners. For
          more information on how to use these tools, please click the link below:
          <br></br>
          <b>
            <TextLinkComponent linkText={'How to use Product Search'} linkUrl={'/quickstart'} isExternal />
          </b>
        </Typography>
        <div className={'pb-2'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            Assembly Search
          </Typography>
        </div>
        <Typography className={'large-para pb-5'}>
          Assembly Search is the core of RoofNav, as it provides you with the capability to perform detailed searches of
          our massive database to find FM Approved roof assemblies that meet your design requirements. For more
          information on how to use these tools, please click the link below:
          <br></br>
          <b>
            <TextLinkComponent linkText={'How to use the Assembly Search tools'} linkUrl={'/quickstart'} isExternal />
          </b>
        </Typography>
        <div className={'pb-2'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            Ratings Calculator
          </Typography>
        </div>
        <Typography className={'large-para pb-5'}>
          The Ratings Calculator tool is specifically designed to walk you step-by-step through the process of
          determining the appropriate roof classification ratings for fire, hail and wind uplift based on the attributes
          a structure and its location.
          <br></br>
        </Typography>
        <div className={'pb-2'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            Reference Materials
          </Typography>
        </div>
        <Typography className={'large-para pb-5'}>
          This section of RoofNav provides you with easy access to a number of materials including background and
          guidelines for FM Approved roofing products and assemblies, a glossary of roofing terms, roofing related FM loss prevention data sheets, and more.
          <br></br>
        </Typography>
      </div>
    </main>
  );
};
