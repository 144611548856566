import { Typography } from "@mui/material"
import React from "react"
import './RefMaterial.scss';
import theme from "../../theme";

export const BoardStockRules = () => {
    return (
        <main className="main-content container-fluid flex-shrink-0 glossary">
            <div className="px-4 py-2">
                <Typography className="headline-1 py-2" color={theme.palette.rnSteel.steel700}>Board Stock Rules</Typography>
                <div className="mx-2">
                    <p>Board stock products may be used in three different types of layers
                        within&nbsp;RoofNav assemblies:&nbsp;Cover Board layers,&nbsp;Board
                        Stock&nbsp;Insulation layers, and Thermal Barrier layers.&nbsp; The layer
                        designation is based on the&nbsp;number&nbsp;of&nbsp;board stock layers in the
                        assembly, the position of the layer, and the core material of the
                        product.&nbsp;&nbsp;When you include these layers in
                        an&nbsp;Assembly&nbsp;Search, it is important to use the appropriate layer
                        names in order to find all the matching assemblies.</p>
                    <ul>
                        <li>
                            An assembly can have, at most, one Cover Board layer.&nbsp; It must be the top
                            board stock layer.&nbsp; It must be made of an appropriate material.&nbsp; (See
                            material table below.)
                            <p></p>
                        </li>
                        <li>
                            An assembly can have, at most, one Thermal Barrier layer.&nbsp; It must be the
                            bottom board stock layer.&nbsp; It must be made of an appropriate
                            material.&nbsp; (See material table below.)
                            <p></p>
                        </li>
                        <li>
                            As assembly can have many Board Stock Insulation layers.</li></ul>
                    <p className="MsoNormal"><b >One Board Stock Layer
                    </b></p>
                    <ul>
                        <li className="MsoNormal" >
                            If there is only one board stock layer in an assembly, and there is no Spray
                            Foam Insulation layer in the assembly, then the board stock layer is
                            a&nbsp;Board Stock&nbsp;Insulation layer.
                        </li>
                        <p></p>
                        <li className="MsoNormal" >
                            If there is only one board stock layer in an assembly and&nbsp;there IS a Spray
                            Foam Insulation layer,&nbsp;the board stock layer must be below the Spray Foam
                            Insulation layer&nbsp;and the board stock layer is either a Board
                            Stock&nbsp;Insulation layer or a Thermal Barrier layer, depending on the
                            material.&nbsp; (See material table below.&nbsp; For gypsum, perlite, and
                            mineral wool use Thermal Barrier.)</li></ul>
                    <p className="MsoNormal" >
                        <div className="d-flex row m-0">
                            <div className="px-2">
                                Board Stock Insulation
                            </div>
                            <div className="px-2">
                                OR
                            </div>
                            <div className="px-2">
                                <Typography>
                                    Spray Foam
                                </Typography>
                                <Typography>
                                    Board Stock Insulation
                                </Typography>
                            </div>
                            <div className="px-2">
                                OR
                            </div>
                            <div className="px-2">
                                <Typography>
                                    Spray Foam
                                </Typography>
                                <Typography>
                                    Thermal Barrier
                                </Typography>
                            </div>
                        </div>
                    </p>
                    <p></p>
                    <p className="MsoNormal">&nbsp;</p>
                    <p className="MsoNormal"><b ></b>&nbsp;</p>
                    <p className="MsoNormal"><b >Two Board Stock Layers
                    </b></p>
                    <ul>
                        <li>
                            <div className="MsoNormal" >If an
                                assembly has two board stock layers, the valid
                                combinations&nbsp;are:&nbsp;insulation over insulation; cover board over
                                insulation; or insulation over thermal barrier, all as determined by
                                material.&nbsp; (See material table below.)</div>
                        </li>
                    </ul>
                    <p className="MsoNormal" >
                        <div className="d-flex row m-0">
                            <div className="px-2">
                                <Typography>
                                    Board Stock Insulation
                                </Typography>
                                <Typography>
                                    Board Stock Insulation
                                </Typography>
                            </div>
                            <div className="px-2">
                                OR
                            </div>
                            <div className="px-2">
                                <Typography>
                                    Cover Board
                                </Typography>
                                <Typography>
                                    Board Stock Insulation
                                </Typography>
                            </div>
                            <div className="px-2">
                                OR
                            </div>
                            <div className="px-2">
                                <Typography>
                                    Board Stock Insulation
                                </Typography>
                                <Typography>
                                    Thermal Barrier
                                </Typography>
                            </div>
                        </div>
                    </p>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong></strong>&nbsp;</p>
                    <p><strong>Three or more Board Stock Layers</strong></p>
                    <ul  >
                        <li className="MsoNormal" >
                            If an assembly has&nbsp;three or more&nbsp;board stock layers, the valid
                            combinations are : all are insulation&nbsp;layers; a cover board over one or
                            more insulation layers over a thermal barrier;&nbsp;a cover board
                            over&nbsp;multiple insulation layers; or multiple insulation layers over a
                            thermal barrier, all&nbsp;as determined by material.&nbsp; (See material table
                            below.)&nbsp;&nbsp;</li></ul>
                    <p className="MsoNormal" >
                        <div className="d-flex row m-0">
                            <div className="px-2">
                                <Typography>
                                    Board Stock Insulation
                                </Typography>
                                <Typography>
                                    Board Stock Insulation(s)
                                </Typography>
                                <Typography>
                                    Board Stock Insulation
                                </Typography>
                            </div>
                            <div className="px-2">
                                OR
                            </div>
                            <div className="px-2">
                                <Typography>
                                    Cover Board
                                </Typography>
                                <Typography>
                                    Board Stock Insulation(s)
                                </Typography>
                                <Typography>
                                    Board Stock Insulation
                                </Typography>
                            </div>
                            <div className="px-2">
                                OR
                            </div>
                            <div className="px-2">
                                <Typography>
                                    Cover Board
                                </Typography>
                                <Typography>
                                    Board Stock Insulation(s)
                                </Typography>
                                <Typography>
                                    Thermal Barrier
                                </Typography>
                            </div>
                            <div className="px-2">
                                OR
                            </div>
                            <div className="px-2">
                                <Typography>
                                    Board Stock Insulation
                                </Typography>
                                <Typography>
                                    Board Stock Insulation(s)
                                </Typography>
                                <Typography>
                                    Thermal Barrier
                                </Typography>
                            </div>
                        </div>
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>The following table&nbsp;lists the appropriate materials for each
                        layer.</strong></p>
                    <ul>
                        <li>
                            <strong>Cover Board</strong>
                            - Core Material or Top Facer Material&nbsp;
                        </li>
                        <li>
                            <strong>Insulation</strong>
                            - Core Material
                        </li>
                        <li>
                            <strong>Thermal Barrier</strong> - Core Material or Bottom Facer Material</li></ul>
                    <p>
                        <table id="Table6" cellSpacing="1" cellPadding="1" width="500" >
                            <tr>
                                <td width="124"><strong>Cover Board</strong></td>
                                <td width="188"><strong>Insulation</strong></td>
                                <td><strong>Thermal Barrier</strong></td>
                            </tr>
                            <tr>
                                <td width="124">APA-rated OSB</td>
                                <td width="188">APA-rated OSB</td>
                                <td>gypsum</td>
                            </tr>
                            <tr>
                                <td width="124">asphalt/glass</td>
                                <td width="188">asphalt/glass</td>
                                <td>mineral wool</td>
                            </tr>
                            <tr>
                                <td width="124">glass-based</td>
                                <td width="188">glass-based</td>
                                <td>perlite</td>
                            </tr>
                            <tr>
                                <td width="124">gypsum</td>
                                <td width="188">gypsum
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="124">mineral wool</td>
                                <td width="188">mineral wool</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="124">perlite</td>
                                <td width="188">perlite</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="124">polyisocyanurate/<br></br>polyurethane</td>
                                <td width="188">polyisocyanurate/<br></br>polyurethane</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="124">wood fiber</td>
                                <td width="188">polystyrene foam, EPS</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="124">&nbsp;</td>
                                <td width="188">polystyrene foam, XPS</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="124">&nbsp;</td>
                                <td width="188">wood fiber</td>
                                <td>&nbsp;</td>
                            </tr>
                        </table>
                    </p>

                </div>
            </div>
        </main>
    )
}