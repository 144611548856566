import React from 'react';
import { useGetSinglePlyCoverUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { Length } from '../../utils/Measurement/Length';
import './UsageDetails.scss';

export const SinglePlyCoverUsageDetail = (props: any) => {
  const { data, loading, error } = useGetSinglePlyCoverUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];
  const isMetric = usage?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Cover (Single-ply)</h4>
      <table>
        <tr>
          <td>Max Width:</td>
          <td>{Length.display(usage?.SinglePlyUsage?.WidthMax, isMetric)}</td>
        </tr>
        <tr>
          <td>Material:</td>
          <td>{usage?.Component?.ManufacturedProduct?.SinglePlyCover?.C_SinglePlyMaterial.SinglePlyMaterialCode}</td>
        </tr>
        <tr>
          <td>Min Thickness:</td>
          <td>{Length.display(usage?.SinglePlyUsage?.ThicknessMin, isMetric)}</td>
        </tr>
        <tr>
          <td>Backing:</td>
          <td>{usage?.Component?.ManufacturedProduct?.SinglePlyCover?.C_BackingMaterial.BackingMaterialCode}</td>
        </tr>
        <tr>
          <td>Max Thickness:</td>
          <td>{Length.display(usage?.SinglePlyUsage?.ThicknessMax, isMetric)}</td>
        </tr>
        <tr>
          <td>Reinforcement:</td>
          <td>{usage?.Component?.ManufacturedProduct?.SinglePlyCover?.C_Reinforcement.ReinforcementCode}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
