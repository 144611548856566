import { OutlinedInput, TextField, styled } from "@mui/material";
import React from "react";
import theme from "../../../theme";

interface ITextBoxComponentProps {
  id: string;
  value?: string;
  placeholder?: string;
}

const StyledTextField = styled(TextField)({
  '&.MuiTextField-root .Mui-focused fieldset': {
    // maxWidth: '500px',
    // width: '500px'
    borderColor: theme.palette.rnSteel.steel700
  },

})

export const TextBoxComponent: React.FC<ITextBoxComponentProps> = ({
  id,
  value,
  placeholder
}: ITextBoxComponentProps) => {
  return <StyledTextField id={id} value={value} placeholder={placeholder} />;
};