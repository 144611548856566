import { Box, styled, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SelectComponent } from '../shared/SelectComponent/SelectComponent';
import { ButtonComponent } from '../shared/ButtonComponent/ButtonComponent';
import './SavedItems.scss';
import { SnackbarComponent } from '../shared/SnackbarComponent';
import { RoofAreaBaseInfo, SaveRoofAreaBaseInfo } from '../../models/GQLGeneratedModels';
import { saveBaseRoofArea } from '../../services/RoofAreaService';
import { getProjectByProjectId } from '../../services/ProjectService';
import { genrateUuidv4 } from '../../services/ScenarioService';

interface IAddNewRoofArea {
  onCloseAddNewTab: () => void;
  projectId: number;
}
const StyledTextField = styled(TextField)({
  '& .MuiInputBase-multiline': {
    padding: '0px',
  },
});
export const AddNewRoofArea = ({ onCloseAddNewTab, projectId }: IAddNewRoofArea) => {
  const [errorCheck, setErrorCheck] = useState(false);
  const [roofAreaName, setRoofAreaName] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [roofAreaDescription, setRoofAreaDescription] = useState('');
  const [apiErrorCheck, setApiErrorCheck] = useState(false);
  const [client, setClient] = useState('');
  const [observer, setObserver] = useState('');
  const [contractor, setContractor] = useState('');
  const [open, setOpen] = React.useState(false);
  const [projectTitle, setProjectTitle] = useState('');
  useEffect(() => {
    const getProjectById = async () => {
      if (projectId > 0) {
        const randguid = genrateUuidv4();
        const projectData = await getProjectByProjectId(projectId, randguid);
        setProjectTitle(projectData[0]?.Name);
      }
    };
    getProjectById();
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setApiErrorCheck(false);
    // TODO: we will need to make changes to this section when we get to editing roof area,
    // and when we include roof areas with ratings. Will need to add mapping for editing, and additional fields
    // for RA w/ratings, add flag for edit, etc
    const roofAreaToSave: RoofAreaBaseInfo = {
      BuildingName: buildingName,
      Description: roofAreaDescription,
      ProjectId: projectId,
      Name: roofAreaName,
    };
    const newRoofAreaInfo: SaveRoofAreaBaseInfo = {
      RoofArea: roofAreaToSave,
      SaveOperationType: 1,
    };
    const result = await saveBaseRoofArea(newRoofAreaInfo);
    if (roofAreaToSave.Name && result) {
      setApiErrorCheck(false);
      setErrorCheck(false);
      if (result) {
        setOpen(true);
      }
    } else {
      if (!result) {
        setApiErrorCheck(true);
      }
      setErrorCheck(true);
    }
  };
  const deleteRoofArea = async (e: any) => {
    console.log('Clickity click this is a placeholder for deleting roof area');
  };

  const onroofAreaNameSelected = (e: any) => {
    if (e.target.value) {
      setRoofAreaName(e.target.value);
      setErrorCheck(true);
    } else {
      setRoofAreaName('');
      setErrorCheck(false);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (event) {
      setOpen(false);
      return;
    }
  };

  return (
    <>
      <Box className={'d-flex rn-new-project justify-content-between'}>
        <Box component="form" className="col-xxl-9 col-xl-8" noValidate>
          <div className="row mx-0">
            <div className={'justify-content-between row'}>
              <div className={'ml-2 mx-4'}>
                <Typography className={'headline-5'}>{projectTitle}</Typography>
                <div className="my-3">
                  <Typography className={'small-para-semibold '}>
                    Roof Area Name: <span className="roofnav-error">*</span>
                  </Typography>
                  <TextField
                    value={roofAreaName}
                    onChange={onroofAreaNameSelected}
                    id="roofAreaNameInput"
                    placeholder="Roof Area Name"
                    size="small"
                    error={errorCheck && roofAreaName.length == 0}
                  />
                  {errorCheck && roofAreaName.length == 0 && (
                    <Typography className={'small-para roofnav-error pt-1'}>
                      Please submit a value for roof area name
                    </Typography>
                  )}
                </div>
                <div className="my-3">
                  <Typography className={'small-para-semibold '}>Building Name/Number:</Typography>
                  <TextField
                    value={buildingName}
                    onChange={(e) => setBuildingName(e.target.value)}
                    id="buildingNameInput"
                    placeholder="Bldg 1"
                    size="small"
                  />
                </div>
                <div className="my-3">
                  <Typography className={'small-para-semibold '}>Roof Area Description:</Typography>
                  <StyledTextField
                    value={roofAreaDescription}
                    onChange={(e) => setRoofAreaDescription(e.target.value)}
                    id="projectDescriptionInput"
                    placeholder="Enter description about the project"
                    multiline
                    rows={2}
                  />
                </div>
              </div>
              <div className={'ml-2 mx-4'}>
                <Typography className={'headline-5'}>Roof Area Contacts</Typography>
                <div className="my-3">
                  <Typography className={'small-para-semibold '}>Client/Property Owner</Typography>
                  <SelectComponent
                    id={'clientSelection'}
                    className="as-2 project-select mr-2"
                    options={[]}
                    setValue={(value) => setClient(value)}
                    value={client}
                  />
                </div>
                <div className="my-3">
                  <Typography className={'small-para-semibold '}>Roof Designer/Observer</Typography>
                  <SelectComponent
                    id={'observerSelection'}
                    className="as-2 project-select mr-2"
                    options={[]}
                    setValue={(value) => setObserver(value)}
                    value={observer}
                  />
                </div>
                <div className="my-3">
                  <Typography className={'small-para-semibold '}>Roof Contractor</Typography>
                  <SelectComponent
                    id={'contractorSelection'}
                    className="as-2 project-select mr-2"
                    options={[]}
                    setValue={(value) => setContractor(value)}
                    value={contractor}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
        <div className={'right-section mb-4'}>
          <ButtonComponent isDisabled={!errorCheck} buttonType="primary" buttonText="Save" click={onSubmit} />
          <ButtonComponent isDisabled buttonType="secondary" buttonText="Add Ratings" />
          <div className={''}>
            <ButtonComponent buttonType="secondary" buttonText="Delete Roof Area" click={deleteRoofArea} />
          </div>
        </div>
      </Box>
      <SnackbarComponent open={open} handleClose={handleClose} message={'Project successfully saved'} />
    </>
  );
};
