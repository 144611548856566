import { useLogger } from "fmg-telemetry-react";
import { GetRoofAreaInfoByIdDocument, GetRoofAreaInfoByIdQuery, GetRoofAreaInfoByIdQueryVariables, GetRoofAreasByProjectIdDocument, GetRoofAreasByProjectIdQuery, GetRoofAreasByProjectIdQueryVariables, SaveRoofAreaBaseInfo, SaveRoofAreaBaseOnlyDocument, SaveRoofAreaBaseOnlyQuery, SaveRoofAreaBaseOnlyQueryVariables, SaveRoofAreaIncludeRatingDocument, SaveRoofAreaIncludeRatingQuery, SaveRoofAreaIncludeRatingQueryVariables, SaveRoofAreaWithRating } from "../models/GQLGeneratedModels";
import { apolloClient } from "../ApolloClient";

export const saveBaseRoofArea = async (roofAreaToSave: SaveRoofAreaBaseInfo) => {
    const logger = useLogger();
    const result = await apolloClient
        .query<SaveRoofAreaBaseOnlyQuery, SaveRoofAreaBaseOnlyQueryVariables>({
            query: SaveRoofAreaBaseOnlyDocument,
            variables: {
                saveRoofAreaBaseInfo: roofAreaToSave,
            },
        }).then((result) => {
            let resultRoofAreaId = 0;
            if (result.data?.roofarea_api_saveroofareabase)
                resultRoofAreaId = result.data?.roofarea_api_saveroofareabase[0]?.RoofAreaId as number;
            return resultRoofAreaId;
        }).catch((error) => {
            logger.error("Error in saveBaseRoofArea", { saveCriteria: roofAreaToSave, error: error });
        });
    return result;
};

export const saveBaseRoofAreaIncludingRating = async (roofAreaToSave: SaveRoofAreaWithRating) => {
    const logger = useLogger();
    const result = await apolloClient
        .query<SaveRoofAreaIncludeRatingQuery, SaveRoofAreaIncludeRatingQueryVariables>({
            query: SaveRoofAreaIncludeRatingDocument,
            variables: {
                saveRoofAreaWithRating: roofAreaToSave,
            },
        }).then((result) => {
            let resultRoofAreaId = 0;
            if (result.data?.roofarea_api_saveroofareawithrating)
                resultRoofAreaId = result.data?.roofarea_api_saveroofareawithrating[0]?.RoofAreaId as number;
            return resultRoofAreaId;
        }).catch((error) => {
            logger.error("Error in saveBaseRoofAreaIncludingRating", { saveCriteria: roofAreaToSave, error: error });
        });
    return result;
};

export const getRoofAreasByProjectId = async (projectId: number, randomId: string) => {
    const logger = useLogger();
    const result = await apolloClient
        .query<GetRoofAreasByProjectIdQuery, GetRoofAreasByProjectIdQueryVariables>({
            query: GetRoofAreasByProjectIdDocument,
            variables: {
                ProjectId: projectId,
                RandomId: randomId
            },
        });
    return result.data.RoofArea;
}

export const getRoofAreaInfoById = async (roofAreaId: number) => {
    const logger = useLogger();
    const result = await apolloClient
        .query<GetRoofAreaInfoByIdQuery, GetRoofAreaInfoByIdQueryVariables>({
            query: GetRoofAreaInfoByIdDocument,
            variables: {
                RoofAreaId: roofAreaId
            }
        });
    return result.data.RoofArea
}