export class Force {
    static N_TO_LBF = 4.448;
    static IMPERIALUNIT = 'lbf';
    static METRICUNIT = 'N';

    static convertMetricToImperial(valueMetric: number) {
        const valueImperial = valueMetric / Force.N_TO_LBF;
        return valueImperial.toFixed(4) + ' ' + Force.IMPERIALUNIT;
    }
}
