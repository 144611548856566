import React, { useContext, useEffect, useState } from 'react';
import './ContractorPackageReport.scss';
import { Link, useParams } from 'react-router-dom';
import { useGetScenarioFullInfoQuery } from '../../models/GQLGeneratedModels';
import { Loading } from '../shared/Loading/Loading';
import { UsageDetails } from '../UsageDetails';
import { SpecLayerDetails } from '../SpecLayerDetails';
import { TextOnlyComponent } from '../shared/TextOnlyComponent/TextOnlyComponent';
import { Typography } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { ApplicationContext } from '../ApplicationProvider';
import { Dialog } from '../shared/DialogComponent/Dialog';
import { ErrorModalComponent } from '../shared/ErrorModalComponent/ErrorModalComponent';

export const ContractorPackageReport: React.FC = () => {
  const { instance: azureADPCA } = useMsal();
  const { setDialog, setShowDialog } = useContext(ApplicationContext)
  const params = useParams();
  if (!params.scenarioid) {
    throw Error('Empty scenarioid');
  }

  //console.log('scenarioid is ', parseInt(params.scenarioid));
  let userEmail = azureADPCA.getActiveAccount()?.username || 'test@test.com';
  let tokenClaims;
  if (azureADPCA.getActiveAccount()?.idTokenClaims) {
    tokenClaims = azureADPCA.getActiveAccount()?.idTokenClaims;
    if (tokenClaims && tokenClaims.hasOwnProperty('email')) {
      userEmail = (tokenClaims as any)['email'];
    }
  }

  const { data, loading, error } = useGetScenarioFullInfoQuery({
    variables: { ScenarioId: parseInt(params.scenarioid), AzureB2CUserEmail: userEmail, RandomId: 'abcdef' },
  });

  useEffect(() => {
    if (error) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [error])

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Loading />
      </div>
    );
  }
  const handlePrint = () => {
    window.print();
  };
  const scenario = data?.Scenario[0];

  return scenario ? (
    <>
      <div className={'rn-contractor-package d-flex justify-content-center mt-2'}>
        <table>
          <thead>
            <div className={'row'} id="header">
              <div className={'col-3 d-flex align-content-center'}>
                <img src="../../assets/FM_Approvals_Logo_Horiz_Dark.svg" width="140" alt="FMApprovals logo" />
              </div>

              <div className={'col-7 header-text'}>ROOFING SYSTEM CONTRACTOR PACKAGE</div>
              <div className="col px-4 my-1 text-right printbutton">
                <Link to={``} onClick={handlePrint}>
                  <TextOnlyComponent buttonText={'Print'} iconName={'print'} />
                </Link>
              </div>
            </div>
          </thead>

          <tbody>
            <div id="pagedisplay">
              <div className={'section-space'}></div>

              <div className={'section-title'}>Scenario Information</div>
              <div>
                <span className={'label-text'}>Scenario Name:</span> {scenario?.Name}
              </div>
              <div>
                <span className={'label-text'}>Scenario Comments:</span> {scenario?.Comments}
              </div>

              <div className={'section-space'}></div>

              <div className={'section-title'}>Assembly Properties</div>
              <div>
                <span className={'label-text'}>Assembly #:</span> {scenario?.NavAssemblyName}
              </div>
              <div>
                <span className={'label-text'}>Roof System:</span> {scenario?.NavAssembly?.AssemblyTypeCode}
              </div>
              <div>
                <span className={'label-text'}>Application:</span> {scenario?.NavAssembly?.AssemblyApplicationTypeCode}
              </div>
              <div>
                <span className={'label-text'}>Cover Securement:</span>{' '}
                {scenario?.NavAssembly?.CoverSecurementMethodCode}
              </div>
              <div>
                <span className={'label-text'}>Deck Type:</span> {scenario?.NavAssembly?.DeckTypeCode}
              </div>
              <div>
                <span className={'label-text'}>Slope:</span> {scenario?.NavAssembly?.Slope}
              </div>
              <div>
                <span className={'label-text'}>Wind Uplift:</span> {scenario?.NavAssembly?.WindUplift}
              </div>
              <div>
                <span className={'label-text'}>Internal Fire:</span> {scenario?.NavAssembly?.IntFireRatingCode}
              </div>
              <div>
                <span className={'label-text'}>External Fire:</span> {scenario?.NavAssembly?.ExtFireRatingCode}
              </div>
              <div>
                <span className={'label-text'}>Hail:</span> {scenario?.NavAssembly?.HailRatingCode}
              </div>
              {scenario?.NavAssembly?.RoofAssembly.IncludesPV && (
                <>
                  <div>
                    <span className={'label-text'}>PV Application Method:</span>{' '}
                    {scenario?.NavAssembly?.RoofAssembly.C_PVApplicationMethod?.PVApplicationMethodCode}
                  </div>
                  <div>
                    <span className={'label-text'}>PV Wind Uplift:</span> {scenario?.NavAssembly?.Slope}
                  </div>
                  <div>
                    <span className={'label-text'}>PV Hail:</span>{' '}
                    {scenario?.NavAssembly?.RoofAssembly.C_PVHailRating?.PVHailRatingCode}
                  </div>
                </>
              )}
              <div>
                * FM Approved roofs must also have corner (Zone 3) and perimeter (Zone 2) enhancements and FM Approved
                perimeter flashing. For details, see DS 1-29 and 1-49. For Standing/Lap Seam roofs, see DS 1 - 31.
              </div>

              <div className={'section-space'}></div>

              <div className={'section-title'}>Layers and Usages Details</div>
              <div>
                {scenario?.ScenarioLayers?.map((slr) => (
                  <>
                    <div className={'layer-title'}>{slr.LayerText}</div>
                    <div className={'layer-Comment-line'}>
                      <span className={'label-text'}>Comments:</span> {slr.NavLayer?.Layer?.Comments}
                    </div>

                    <div>
                      {(slr.LayerTypeID === 25 || slr.LayerTypeID === 35 || slr.LayerTypeID === 18) && (
                        <>
                          <SpecLayerDetails layerId={slr.NavLayer?.LayerId as number} layerTypeId={slr.LayerTypeID} />
                        </>
                      )}

                      {slr?.ScenarioUsages?.map((susg, index) => (
                        <>
                          <div>
                            {index === 1 && <div className={'self-securement-title'}>{susg.SelfSecurementText}</div>}
                            {susg.Usage?.Component.ManufacturedProduct && (
                              <>
                                <div>
                                  <span>Company:</span>{' '}
                                  <span className={'trade-name-text'}>{susg.Usage?.Component.ManufacturedProduct.Manufacturer?.Name}</span>
                                </div>
                                <div>
                                  <span>Trade Name:</span>{' '}
                                  <span className={'trade-name-text'}>{susg.Usage?.Component.ManufacturedProduct.TradeName}</span>
                                </div>
                              </>
                            )}
                            {susg.Usage?.Component.StructuredSystem && (
                              <>
                                <div>
                                  <span className={'label-text'}>System Name:</span>{' '}
                                  {susg.Usage?.Component.StructuredSystem.SystemName}
                                </div>
                                <div className={'row'}>
                                  <div className={'col-4'}>Company</div>
                                  <div className={'col-4'}>Trade Name</div>
                                </div>
                                {susg.Usage?.Component?.StructuredSystem.StructuredSystemProducts?.map(
                                  (subcomponent) => (
                                    <div key={susg.Usage?.Component.StructuredSystem?.ComponentId} className={'row'}>
                                      <div className={'col-4'}>
                                        <span className={'trade-name-text'}>{subcomponent.ManufacturedProduct.Manufacturer.Name}</span>
                                      </div>
                                      <div className={'col-4'}><span className={'trade-name-text'}>{subcomponent.ManufacturedProduct.TradeName}</span></div>
                                    </div>
                                  )
                                )}
                                <div>
                                  <span className={'label-text'}>System Comments:</span>
                                  <Typography sx={{ wordBreak: 'break-word' }}>
                                    {susg.Usage?.Component.Comments}
                                  </Typography>
                                </div>
                              </>
                            )}
                          </div>
                          <UsageDetails usageId={susg.UsageId} approvedUseId={susg.Usage?.ApprovedUseId as number} />
                        </>
                      ))}
                      <div className={'block-space'}></div>
                    </div>
                  </>
                ))}
              </div>

              <div className={'section-space'}></div>

              <div>
                <div className={'sub-section-title'}>Fastener Placement</div>
                <div>
                  Now that the configuration is complete, you must select the required insulation fastener placement
                  Fastener Placement Figures FM Property Loss Prevention Data Sheet (DS) 1-29.
                </div>
                <div className={'block-space'}></div>
                <div className={'sub-section-title'}>Zone 2 and 3 Assemblies.</div>
                <div>
                  If this scenario is for Roof Zone 1, you must select roof assemblies that meet the wind uplift
                  requirements for Zones 2 and 3 as well. If performance-based design is desired, select a roof assembly
                  with the needed wind uplift rating for each roof zone.
                </div>
                <div>
                  For Roof Zone 1', select a roof with the associated rating, or use the roof assembly selected for Roof
                  Zone 1.
                </div>
                <div className={'block-space'}></div>
                <div className={'sub-section-title'}>Perimeter Flashing</div>
                <div>
                  You must select an FM Approved perimeter flashing that meets the appropriate ratings for your roof.
                </div>
                <div>
                  You can find FM Approved perimeter flashings in RoofNav. To do so, click Products. On the Product
                  Search page, select Category: Flashing. Specify any other criteria as needed and then click Search.{' '}
                  <br></br>For more information, see DS 1-49.
                </div>
              </div>
            </div>
          </tbody>
          <div className={'row'} id="footer">
            <div className="col px-4 my-1 text-right copywrite">2006-2024 FM Approvals LLC. All rights reserved.</div>
          </div>
        </table>
      </div>
    </>
  ) : (
    <div className={'rn-contractor-package d-flex justify-content-center mt-2'}>
      <div className={'invalid_message'}>
        Invalid scenario
      </div>
    </div>
  )
};
