import React, { useContext, useEffect, useState } from 'react';
import { COMPONENT_MAP } from '../constants/COMPONENT_MAP';
import { TableComponent } from '../shared/TableComponent/TableComponent';
import { useGetProductListByAPIQuery } from '../../services/ProductSearchService';
import { ApplicationContext } from '../ApplicationProvider';
import { useLocation, useNavigate } from 'react-router';
import { ConfigitProductContext } from '../ConfigitProductProvider';
import { Link } from 'react-router-dom';
import { TextOnlyComponent } from '../shared/TextOnlyComponent/TextOnlyComponent';
import { Typography } from '@mui/material';
import theme from '../../theme';
import { Dialog } from '../shared/DialogComponent/Dialog';
import { ErrorModalComponent } from '../shared/ErrorModalComponent/ErrorModalComponent';
interface IProductListProps {
  count?: number;
}

export const ProductList: React.FC<IProductListProps> = ({ count }: IProductListProps) => {
  const { initialSearchCriteria, apuCount } = useContext(ConfigitProductContext);
  let iniSearchCriteria = initialSearchCriteria;
  count = apuCount;
  useEffect(() => {
    iniSearchCriteria = initialSearchCriteria;
    setItemOffset(0);
  }, [initialSearchCriteria]);

  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [columnHeaders, setColumnHeaders] = useState(COMPONENT_MAP.ProductList.colHeads);
  const { itemOffset, itemsPerPage, setItemOffset, setItemsPerPage, setDialog, setShowDialog } =
    useContext(ApplicationContext);
  const [fieldSort, setFieldSort] = useState('TradeName');
  const [sortingField, setSortingField] = useState('Product Trade Name');
  const [sortDirection, setSortDirection] = useState(0);

  const { data, loading, error } = useGetProductListByAPIQuery(
    iniSearchCriteria,
    itemOffset,
    itemsPerPage,
    fieldSort,
    sortDirection,
    Number(iniSearchCriteria.approvedUseId),
    Number(iniSearchCriteria.categoryId),
    undefined
  );
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    if (error) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [error]);
  const navigateToDetail = (componentId: any) => {
    navigate(`/productDetail/${componentId}?${urlParams.toString()}`);
  };
  const handlePageSizeChange = (event: any) => {
    const newPageSize = parseInt(event.target.value);
    setItemsPerPage(newPageSize);
    setItemOffset(itemOffset); // Reset to be the first page
  };

  // Invoke when user click to request another page.
  const handlePageClick = async (event: any) => {
    if (!count) return;
    const newOffset = (event.selected * itemsPerPage) % count;

    setItemOffset(newOffset);
  };
  const handleSort = (field: string) => {
    const headers = [...COMPONENT_MAP.ProductList.colHeads];
    let newSortDir: number;

    if (sortingField === field) {
      newSortDir = sortDirection === 0 ? 1 : 0;
      for (let i = 0; i < headers.length; i++) {
        if (headers[i].className !== 'notvisible') {
          if (headers[i].name === field) {
            headers[i].className =
              newSortDir === 0 ? 'th-inner sortable both sorted asc' : 'th-inner sortable both sorted desc';
          } else {
            headers[i].className = 'th-inner sortable both unsorted';
            setSortDirection(1);
          }
        }
      }
    } else {
      newSortDir = 0;
      for (let i = 0; i < headers.length; i++) {
        if (headers[i].className !== 'notvisible') {
          if (headers[i].name === field) {
            headers[i].className = 'th-inner sortable both sorted asc';
          } else {
            headers[i].className = 'th-inner sortable both unsorted';
          }
        }
      }
    }

    let sortColumn: string;
    switch (field) {
      case 'Product Trade Name':
        sortColumn = 'TradeName';

        break;
      case 'Company Name':
        sortColumn = 'ManufacturerName';
        break;
      case 'Approved Use':
        sortColumn = 'ApprovedUseCode';
        break;
      case 'CountryCode':
        sortColumn = 'CountryCode';
        break;
      default:
        sortColumn = 'TradeName';
    }
    setSortDirection(newSortDir);
    setSortingField(field);
    setFieldSort(sortColumn);
    setColumnHeaders(headers);
  };
  const handleBackClick = () => {
    history.back();
  };
  return (
    <div id="roofnav_results" className="p-3">
      <div className="p-5">
        <div className="col px-4 my-1 text-right">
          <Link onClick={handleBackClick} to={'#'}>
            <TextOnlyComponent buttonText={'Back'} iconName={'arrow_left'} />
          </Link>
        </div>
        {Object.keys(initialSearchCriteria).length > 0 ? (
          <TableComponent
            loading={loading}
            count={count}
            offset={itemOffset}
            limit={itemsPerPage}
            handlePageSizeChange={handlePageSizeChange}
            handlePageClick={handlePageClick}
            handleSort={handleSort}
            colHeads={columnHeaders}
            navigateToDetail={navigateToDetail}
            rows={
              data &&
              data.product_api_listing?.map((productlist: any) => {
                const row: string[] = [];
                row.push(productlist?.ComponentId.toString() as string);
                row.push(productlist?.TradeName as string);
                row.push(productlist?.ManufacturerName as string);
                row.push(productlist?.ApprovedUseCode as string);
                row.push(productlist?.CountryCode as string);
                return row;
              })
            }
          />
        ) : (
          <Typography className="headline-1 justify-content-center d-flex" color={theme.palette.rnSteel.steel700}>
            No seach criteria received, please return and search again
          </Typography>
        )}
      </div>
      <div className="clearfix"></div>
    </div>
  );
};
