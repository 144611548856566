import React, { useState } from 'react';
import { NavAssembly_Bool_Exp } from '../models/GQLGeneratedModels';
import { IScenario } from '../types/interfaces';

interface IApplicationContext {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  searchAssemblyNum: string;
  setSearchAssemblyNum: (searchAssemblyNum: string) => void;
  itemOffset: number;
  setItemOffset: (itemOffset: number) => void;
  itemsPerPage: number;
  setItemsPerPage: (itemsPerPage: number) => void;
  unitSessionMode: string;
  setUnitSessionMode: (unitSessionMode: string) => void;
  dialog: React.ReactNode;
  setDialog: (dialog: React.ReactNode) => void;
  showDialog: boolean;
  setShowDialog: (loading: boolean) => void;
  filters?: NavAssembly_Bool_Exp;
  setFilters: (filters: NavAssembly_Bool_Exp) => void;
  defaultTab: number;
  setDefaultTab: (defaultTab: number) => void;
  showCalc: boolean;
  setShowCalc: (showCalc: boolean) => void;
  showProductsUsed: boolean;
  setShowProductsUsed: (showProductsUsed: boolean) => void;
  showFilters: boolean;
  setShowFilters: (showFilters: boolean) => void;
  showRoofLayers: boolean;
  setShowRoofLayers: (showRoofLayers: boolean) => void;
  calculatorIndex: number;
  setCalculatorIndex: (calculatorIndex: number) => void;
  scenarioError: boolean;
  setScenarioError: (scenarioError: boolean) => void;
  view: string;
  setView: (view: string) => void;
  autoRefreshSearchResult: boolean;
  setAutoRefreshSearchResult: (autoRefreshSearchResult: boolean) => void;
  triggerSearchResultCount: boolean;
  setTriggerSearchResultCount: (triggerSearchResultCount: boolean) => void;
  triggerRoofTypeList: boolean;
  setTriggerRoofTypeList: (triggerRoofTypeList: boolean) => void;
  triggerAssemblyList: boolean;
  setTriggerAssemblyList: (triggerAssemblyList: boolean) => void;
  numShowResult: number;
  setNumShowResult: (numShowResult: number) => void;
}

export const ApplicationContext = React.createContext<IApplicationContext>({
  loading: false,
  setLoading: (loading: boolean) => { },
  searchAssemblyNum: '',
  setSearchAssemblyNum: (searchAssemblyNum: string) => { },
  itemOffset: 0,
  setItemOffset: (itemOffset: number) => { },
  itemsPerPage: 25,
  setItemsPerPage: (itemsPerPage: number) => { },
  unitSessionMode: localStorage.getItem('userPreferenceUOM') === 'Metric' ? 'Metric' : 'US Standard',
  setUnitSessionMode: (unitSessionMode: string) => { },
  dialog: <></>,
  setDialog: (dialog: React.ReactNode) => { },
  showDialog: false,
  setShowDialog: (loading: boolean) => { },
  setFilters: (filters: NavAssembly_Bool_Exp) => { },
  defaultTab: 0,
  setDefaultTab: (defaultTab: number) => { },
  showCalc: false,
  setShowCalc: (showCalc: boolean) => { },
  showProductsUsed: false,
  setShowProductsUsed: (showProductsUsed: boolean) => { },
  showFilters: false,
  setShowFilters: (showFilters: boolean) => { },
  showRoofLayers: false,
  setShowRoofLayers: (showRoofLayers: boolean) => { },
  calculatorIndex: 0,
  setCalculatorIndex: (calculatorIndex: number) => { },
  scenarioError: false,
  setScenarioError: (scenarioError: boolean) => { },
  view: 'Roof Type',
  setView: (view: string) => { },
  autoRefreshSearchResult: localStorage.getItem('userPreferenceSearch') === 'Auto-Refresh' ? true : false,
  setAutoRefreshSearchResult: (autoRefreshSearchResult: boolean) => { },
  triggerSearchResultCount: false,
  setTriggerSearchResultCount: (triggerSearchResultCount: boolean) => { },
  triggerRoofTypeList: false,
  setTriggerRoofTypeList: (triggerRoofTypeList: boolean) => { },
  triggerAssemblyList: false,
  setTriggerAssemblyList: (triggerAssemblyList: boolean) => { },
  numShowResult: 0,
  setNumShowResult: (numShowResult: number) => { }

});

export const ApplicationProvider = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [searchAssemblyNum, setSearchAssemblyNum] = useState('');
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [unitSessionMode, setUnitSessionMode] = useState(localStorage.getItem('userPreferenceUOM') === 'Metric' ? 'Metric' : 'US Standard',);
  const [dialog, setDialog] = useState<React.ReactNode>(<></>);
  const [showDialog, setShowDialog] = useState(false);
  const [filters, setFilters] = useState<NavAssembly_Bool_Exp>();
  const [defaultTab, setDefaultTab] = useState(0);
  const [showCalc, setShowCalc] = useState(false);
  const [showProductsUsed, setShowProductsUsed] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showRoofLayers, setShowRoofLayers] = useState(false);
  const [calculatorIndex, setCalculatorIndex] = useState(0);

  const [scenarioError, setScenarioError] = useState(false);
  const [view, setView] = useState('Roof Type');
  const [autoRefreshSearchResult, setAutoRefreshSearchResult] = useState(localStorage.getItem('userPreferenceSearch') === 'Auto-Refresh' ? true : false);
  const [triggerSearchResultCount, setTriggerSearchResultCount] = useState(false);
  const [triggerRoofTypeList, setTriggerRoofTypeList] = useState(false);
  const [triggerAssemblyList, setTriggerAssemblyList] = useState(false);
  const [numShowResult, setNumShowResult] = useState(0);

  return (
    <ApplicationContext.Provider
      value={{
        loading,
        setLoading,
        searchAssemblyNum,
        setSearchAssemblyNum,
        itemOffset,
        setItemOffset,
        itemsPerPage,
        setItemsPerPage,
        unitSessionMode,
        setUnitSessionMode,
        dialog,
        setDialog,
        showDialog,
        setShowDialog,
        filters,
        setFilters,
        defaultTab,
        setDefaultTab,
        showCalc,
        setShowCalc,
        showProductsUsed,
        setShowProductsUsed,
        showFilters,
        setShowFilters,
        showRoofLayers,
        setShowRoofLayers,
        calculatorIndex,
        setCalculatorIndex,
        scenarioError,
        setScenarioError,
        view,
        setView,
        autoRefreshSearchResult,
        setAutoRefreshSearchResult,
        triggerSearchResultCount,
        setTriggerSearchResultCount,
        triggerRoofTypeList,
        setTriggerRoofTypeList,
        triggerAssemblyList,
        setTriggerAssemblyList,
        numShowResult,
        setNumShowResult
      }}
    >
      {props.children}
    </ApplicationContext.Provider>
  );
};
