import React from 'react';
import { ApprovedUse } from '../../constants/ENUMS';
import { BaseSheetUsageDetail } from './BaseSheetUsageDetail';
import { SinglePlyCoverUsageDetail } from './SinglePlyCoverUsageDetail';
import { BasePlyUsageDetail } from './BasePlyUsageDetail';
import { CoverBoardUsageDetail } from './CoverBoardUsageDetail';
import { BoardStockUsageDetail } from './BoardStockUsageDetail';
import { ThermalBarrierUsageDetail } from './ThermalBarrierUsageDetail';
import { CapPlyUsageDetail } from './CapPlyUsageDetail';
import { OtherPlyUsageDetail } from './OtherPlyUsageDetail';
import { FastenerUsageDetail } from './FastenerUsageDetail';
import { PreassembledFasteningUsageDetail } from './PreassembledFasteningUsageDetail';
import { FasteningClipUsageDetail } from './FasteningClipUsageDetail';
import { SteelDeckUsageDetail } from './SteelDeckUsageDetail';
import { LinerPanelUsageDetail } from './LinerPanelUsageDetail';
import { StandingLapSeamCoverUsageDetail } from './StandingLapSeamCoverUsageDetail';
import { FasciumUsageDetail } from './FasciumUsageDetail';
import { AdhesiveFullApplicationUsageDetail } from './AdhesiveFullApplicationUsageDetail';
import { AdhesiveRibbonsUsageDetail } from './AdhesiveRibbonsUsageDetail';
import { AdhesiveSpotsUsageDetail } from './AdhesiveSpotsUsageDetail';
import { AirRetarderUsageDetail } from './AirRetarderUsageDetail';
import { SeparatorSheetUsageDetail } from './SeparatorSheetUsageDetail';
import { VaporRetarderUsageDetail } from './VaporRetarderUsageDetail';
import { CoatingUsageDetail } from './CoatingUsageDetail';
import { CopingUsageDetail } from './CopingUsageDetail';
import { CompositePanelCoverUsageDetail } from './CompositePanelCoverUsageDetail';
import { CoverStripDiskUsageDetail } from './CoverStripDiskUsageDetail';
import { TopCoatUsageDetail } from './TopCoatUsageDetail';
import { BaseCoatUsageDetail } from './BaseCoatUsageDetail';
import { CoatUsageDetail } from './CoatUsageDetail';
import { CoverShinglesUsageDetail } from './CoverShinglesUsageDetail';
import { CementitiousWoodFiberDeckUsageDetail } from './CementitiousWoodFiberDeckUsageDetail';
import { FasteningBattenBarUsageDetail } from './FasteningBattenBarUsageDetail';
import { FasteningStressPlateUsageDetail } from './FasteningStressPlateUsageDetail';
import { FormDeckUsageDetail } from './FormDeckUsageDetail';
import { GutterUsageDetail } from './GutterUsageDetail';
import { BattBlanketInsulationUsageDetail } from './BattBlanketInsulationUsageDetail';
import { SprayedFoamInsulationUsageDetail } from './SprayedFoamInsulationUsageDetail';
import { LapSealantUsageDetail } from './LapSealantUsageDetail';
import { LightweightInsulatingConcreteUsageDetail } from './LightweightInsulatingConcreteUsageDetail';
import { MaintenanceCoatingUsageDetail } from './MaintenanceCoatingUsageDetail';
import { SeamPrimerUsageDetail } from './SeamPrimerUsageDetail';
import { SubstratePrimerUsageDetail } from './SubstratePrimerUsageDetail';
import { SurfacingUsageDetail } from './SurfacingUsageDetail';
import { WeldArcUsageDetail } from './WeldArcUsageDetail';
import { WeldHeatUsageDetail } from './WeldHeatUsageDetail';
import { WeldTorchedUsageDetail } from './WeldTorchedUsageDetail';
import { WeldSolventUsageDetail } from './WeldSolventUsageDetail';
import { AnchorUsageDetail } from './AnchorUsageDetail';

interface IUsageDetailProps {
  approvedUseId: number;
  approvedUseCode: string;
  approvedUseCommnets: any;
  manufacturedProduct: any;
  componentId: number;
  countData: any;
}

export const ApprovedUsageDetail = (props: IUsageDetailProps) => {
  const { approvedUseId, approvedUseCode, approvedUseCommnets, manufacturedProduct, componentId, countData } = props;
  const isMetric = manufacturedProduct[0].EnteredAsMetric;

  const getCountByApprovedUse = (approvedUseId: number) => {
    if (countData === undefined || countData.length === 0) return 0;
    let countResult = 0;
    for (let i = 0; i < countData.length; i++) {
      if (countData[i].ApprovedUseId == approvedUseId) {
        countResult = countData[i].NumMatchedAssembly;
        break;
      }
    }
    return countResult;
  }
  
  const getApprovedUses = () => {
    let approvedUses;
    if (approvedUseId === ApprovedUse.AdhesiveFullApplication) {
      approvedUses = manufacturedProduct[0].AdhesiveFullApplication;
    }
    if (approvedUseId === ApprovedUse.AdhesiveRibbons) {
      approvedUses = manufacturedProduct[0].AdhesiveRibbon;
    }
    if (approvedUseId === ApprovedUse.AdhesiveSpots) {
      approvedUses = manufacturedProduct[0].AdhesiveSpot;
    }
    if (approvedUseId === ApprovedUse.AirRetarder) {
      approvedUses = manufacturedProduct[0].AirRetarder;
    }
    if (approvedUseId === ApprovedUse.BaseSheet) {
      approvedUses = manufacturedProduct[0].BaseSheet;
    }
    if (approvedUseId === ApprovedUse.Coating) {
      approvedUses = manufacturedProduct[0].Coating;
    }
    if (approvedUseId === ApprovedUse.CoverSingleply) {
      approvedUses = manufacturedProduct[0].SinglePlyCover;
    }
    if (approvedUseId === ApprovedUse.CoverMultiplyBasePly) {
      approvedUses = manufacturedProduct[0].BasePly;
    }
    if (approvedUseId === ApprovedUse.CoverBoard) {
      approvedUses = manufacturedProduct[0].CoverBoard;
    }
    if (approvedUseId === ApprovedUse.Coping) {
      approvedUses = manufacturedProduct[0].Coping;
    }
    if (approvedUseId === ApprovedUse.CoverCompositePanel) {
      approvedUses = manufacturedProduct[0].CompositePanelCover;
    }
    if (approvedUseId === ApprovedUse.CoverLiquidAppliedTopCoat) {
      approvedUses = manufacturedProduct[0].TopCoat;
    }
    if (approvedUseId === ApprovedUse.CoverStripDisk) {
      approvedUses = manufacturedProduct[0].CoverStripDisk;
    }
    if (approvedUseId === ApprovedUse.InsulationBoardStock) {
      approvedUses = manufacturedProduct[0].BoardStock;
    }
    if (approvedUseId === ApprovedUse.ThermalBarrier) {
      approvedUses = manufacturedProduct[0].ThermalBarrier;
    }
    if (approvedUseId === ApprovedUse.CoverMultiplyCapPly) {
      approvedUses = manufacturedProduct[0].CapPly;
    }
    if (approvedUseId === ApprovedUse.CoverMultiplyPly) {
      approvedUses = manufacturedProduct[0].OtherPly;
    }
    if (approvedUseId === ApprovedUse.CoverLiquidAppliedCoat) {
      approvedUses = manufacturedProduct[0].Coat;
    }
    if (approvedUseId === ApprovedUse.CoverLiquidAppliedBaseCoat) {
      approvedUses = manufacturedProduct[0].BaseCoat;
    }
    if (approvedUseId === ApprovedUse.FasteningFastener) {
      approvedUses = manufacturedProduct[0].Fastener;
    }
    if (approvedUseId === ApprovedUse.FasteningPreassembledFastening) {
      approvedUses = manufacturedProduct[0].PreassembledFastening;
    }
    if (approvedUseId === ApprovedUse.FasteningClip) {
      approvedUses = manufacturedProduct[0].Clip;
    }
    if (approvedUseId === ApprovedUse.DeckSteel) {
      approvedUses = manufacturedProduct[0].SteelDeck;
    }
    if (approvedUseId === ApprovedUse.LinerPanel) {
      approvedUses = manufacturedProduct[0].LinerPanel;
    }
    if (approvedUseId === ApprovedUse.CoverStandingLapSeam) {
      approvedUses = manufacturedProduct[0].StandingLapSeamCover;
    }
    if (approvedUseId === ApprovedUse.Fascia) {
      approvedUses = manufacturedProduct[0].Fascium;
    }
    if (approvedUseId === ApprovedUse.SeparatorSheet) {
      approvedUses = manufacturedProduct[0].SeparatorSheet;
    }
    if (approvedUseId === ApprovedUse.VaporRetarder) {
      approvedUses = manufacturedProduct[0].VaporRetarder;
    }
    if (approvedUseId === ApprovedUse.CoverShingles) {
      approvedUses = manufacturedProduct[0].Shingle;
    }
    if (approvedUseId === ApprovedUse.DeckCementitiousPanel) {
      approvedUses = manufacturedProduct[0].CementitiousWoodFiberDeck;
    }
    if (approvedUseId === ApprovedUse.FasteningBattenBar) {
      approvedUses = manufacturedProduct[0].BattenBar;
    }
    if (approvedUseId === ApprovedUse.FasteningStressPlate) {
      approvedUses = manufacturedProduct[0].StressPlate;
    }
    if (approvedUseId === ApprovedUse.FormDeck) {
      approvedUses = manufacturedProduct[0].FormDeck;
    }
    if (approvedUseId === ApprovedUse.Gutter) {
      approvedUses = manufacturedProduct[0].Gutter;
    }
    if (approvedUseId === ApprovedUse.InsulationBattBlanket) {
      approvedUses = manufacturedProduct[0].BattBlanketInsulation;
    }
    if (approvedUseId === ApprovedUse.InsulationSprayFoam) {
      approvedUses = manufacturedProduct[0].SprayedFoamInsulation;
    }
    if (approvedUseId === ApprovedUse.LapSealant) {
      approvedUses = 'none';
    }
    if (approvedUseId === ApprovedUse.LightweightInsulatingConcrete) {
      approvedUses = manufacturedProduct[0].LightweightInsulatingConcrete;
    }
    if (approvedUseId === ApprovedUse.MaintenanceCoating) {
      approvedUses = manufacturedProduct[0].MaintenanceCoating;
    }
    if (approvedUseId === ApprovedUse.SeamPrimer) {
      approvedUses = 'none';
    }
    if (approvedUseId === ApprovedUse.Surfacing) {
      approvedUses = manufacturedProduct[0].Surfacing;
    }
    if (approvedUseId === ApprovedUse.SubstratePrimer) {
      approvedUses = manufacturedProduct[0].SubstratePrimer;
    }
    if (approvedUseId === ApprovedUse.WeldArc) {
      approvedUses = 'none';
    }
    if (approvedUseId === ApprovedUse.WeldHeat) {
      approvedUses = 'none';
    }
    if (approvedUseId === ApprovedUse.WeldTorched) {
      approvedUses = 'none';
    }
    if (approvedUseId === ApprovedUse.WeldSolvent) {
      approvedUses = 'none';
    }
    if (approvedUseId === ApprovedUse.Anchor) {
      approvedUses = manufacturedProduct[0].Anchor;
    }
    return approvedUses;
  
  };

  return (
    <div>
      <div className="notvisible">{approvedUseId}</div>
      {approvedUseId === ApprovedUse.AdhesiveFullApplication && (
        <AdhesiveFullApplicationUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.AdhesiveFullApplication)}
        />
      )}
      {approvedUseId === ApprovedUse.AdhesiveRibbons && (
        <AdhesiveRibbonsUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.AdhesiveRibbons)}
        />
      )}
      {approvedUseId === ApprovedUse.AdhesiveSpots && (
        <AdhesiveSpotsUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.AdhesiveSpots)}
        />
      )}
      {approvedUseId === ApprovedUse.AirRetarder && (
        <AirRetarderUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.AirRetarder)}
        />
      )}
      {approvedUseId === ApprovedUse.BaseSheet && (
        <BaseSheetUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.BaseSheet)}
        />
      )}
      {approvedUseId === ApprovedUse.Coating && (
        <CoatingUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.Coating)}
        />
      )}
      {approvedUseId === ApprovedUse.Coping && (
        <CopingUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.Coping)}
        />
      )}
      {approvedUseId === ApprovedUse.CoverSingleply && (
        <SinglePlyCoverUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.CoverSingleply)}
        />
      )}
      {approvedUseId === ApprovedUse.CoverMultiplyBasePly && (
        <BasePlyUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.CoverMultiplyBasePly)}
        />
      )}
      {approvedUseId === ApprovedUse.CoverBoard && (
        <CoverBoardUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.CoverBoard)}
        />
      )}
      {approvedUseId === ApprovedUse.InsulationBoardStock && (
        <BoardStockUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.InsulationBoardStock)}
        />
      )}
      {approvedUseId === ApprovedUse.ThermalBarrier && (
        <ThermalBarrierUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.ThermalBarrier)}
        />
      )}
      {approvedUseId === ApprovedUse.CoverMultiplyCapPly && (
        <CapPlyUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.CoverMultiplyCapPly)}
        />
      )}
      {approvedUseId === ApprovedUse.CoverStripDisk && (
        <CoverStripDiskUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.CoverStripDisk)}
        />
      )}
      {approvedUseId === ApprovedUse.CoverMultiplyPly && (
        <OtherPlyUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.CoverMultiplyPly)}
        />
      )}
      {approvedUseId === ApprovedUse.FasteningFastener && (
        <FastenerUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.FasteningFastener)}
        />
      )}
      {approvedUseId === ApprovedUse.FasteningPreassembledFastening && (
        <PreassembledFasteningUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.FasteningPreassembledFastening)}
        />
      )}
      {approvedUseId === ApprovedUse.FasteningClip && (
        <FasteningClipUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.FasteningClip)}
        />
      )}
      {approvedUseId === ApprovedUse.DeckSteel && (
        <SteelDeckUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.DeckSteel)}
        />
      )}
      {approvedUseId === ApprovedUse.LinerPanel && (
        <LinerPanelUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.LinerPanel)}
        />
      )}
      {approvedUseId === ApprovedUse.CoverStandingLapSeam && (
        <StandingLapSeamCoverUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.CoverStandingLapSeam)}
        />
      )}
      {approvedUseId === ApprovedUse.Fascia && (
        <FasciumUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.Fascia)}
        />
      )}
      {approvedUseId === ApprovedUse.SeparatorSheet && (
        <SeparatorSheetUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.SeparatorSheet)}
        />
      )}
      {approvedUseId === ApprovedUse.VaporRetarder && (
        <VaporRetarderUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.VaporRetarder)}
        />
      )}
      {approvedUseId === ApprovedUse.CoverCompositePanel && (
        <CompositePanelCoverUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.CoverCompositePanel)}
        />
      )}
      {approvedUseId === ApprovedUse.CoverLiquidAppliedTopCoat && (
        <TopCoatUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.CoverLiquidAppliedTopCoat)}
        />
      )}
      {approvedUseId === ApprovedUse.CoverLiquidAppliedBaseCoat && (
        <BaseCoatUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.CoverLiquidAppliedBaseCoat)}
        />
      )}
      {approvedUseId === ApprovedUse.CoverLiquidAppliedCoat && (
        <CoatUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.CoverLiquidAppliedCoat)}
        />
      )}
      {approvedUseId === ApprovedUse.CoverShingles && (
        <CoverShinglesUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.CoverShingles)}
        />
      )}
      {approvedUseId === ApprovedUse.DeckCementitiousPanel && (
        <CementitiousWoodFiberDeckUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.DeckCementitiousPanel)}
        />
      )}
      {approvedUseId === ApprovedUse.FasteningBattenBar && (
        <FasteningBattenBarUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.FasteningBattenBar)}
        />
      )}
      {approvedUseId === ApprovedUse.FasteningStressPlate && (
        <FasteningStressPlateUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.FasteningStressPlate)}
        />
      )}
      {approvedUseId === ApprovedUse.FormDeck && (
        <FormDeckUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.FormDeck)}
        />
      )}
      {approvedUseId === ApprovedUse.Gutter && (
        <GutterUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.Gutter)}
        />
      )}
      {approvedUseId === ApprovedUse.InsulationBattBlanket && (
        <BattBlanketInsulationUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.InsulationBattBlanket)}
        />
      )}
      {approvedUseId === ApprovedUse.InsulationSprayFoam && (
        <SprayedFoamInsulationUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.InsulationSprayFoam)}
        />
      )}
      {approvedUseId === ApprovedUse.LapSealant && (
        <LapSealantUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.LapSealant)}
        />
      )}
      {approvedUseId === ApprovedUse.LightweightInsulatingConcrete && (
        <LightweightInsulatingConcreteUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          isMetric={isMetric}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.LightweightInsulatingConcrete)}
        />
      )}
      {approvedUseId === ApprovedUse.MaintenanceCoating && (
        <MaintenanceCoatingUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.MaintenanceCoating)}
        />
      )}
      {approvedUseId === ApprovedUse.SeamPrimer && (
        <SeamPrimerUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.SeamPrimer)}
        />
      )}
      {approvedUseId === ApprovedUse.SubstratePrimer && (
        <SubstratePrimerUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.SubstratePrimer)}
        />
      )}
      {approvedUseId === ApprovedUse.Surfacing && (
        <SurfacingUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.Surfacing)}
        />
      )}
      {approvedUseId === ApprovedUse.WeldArc && (
        <WeldArcUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.WeldArc)}
        />
      )}
      {approvedUseId === ApprovedUse.WeldHeat && (
        <WeldHeatUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.WeldHeat)}
        />
      )}
      {approvedUseId === ApprovedUse.WeldTorched && (
        <WeldTorchedUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.WeldTorched)}
        />
      )}
      {approvedUseId === ApprovedUse.WeldSolvent && (
        <WeldSolventUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.WeldSolvent)}
        />
      )}
      {approvedUseId === ApprovedUse.Anchor && (
        <AnchorUsageDetail
          title={approvedUseCode}
          comments={approvedUseCommnets}
          approvdUsage={getApprovedUses()}
          componentId={componentId}
          countMatchedAssemblies={getCountByApprovedUse(ApprovedUse.Anchor)}
        />
      )}
    </div>
  );
};
