export class MassPerUnitArea {
  static OzPerSquareFt_TO_KILOSSQUAREMETER = 0.305152;
  static IMPERIALUNIT = 'oz/ft2';
  static METRICUNIT = 'kg/m2';

  static convertImperialToMetric(valueImperial: number) {
    const valueMetric = valueImperial * MassPerUnitArea.OzPerSquareFt_TO_KILOSSQUAREMETER;
    return valueMetric.toFixed(4) + ' ' + MassPerUnitArea.METRICUNIT;
  }

  static convertMetricToImperial(valueMetric: number) {
    const valueImperial = valueMetric / MassPerUnitArea.OzPerSquareFt_TO_KILOSSQUAREMETER;
    return valueImperial.toFixed(4) + ' ' + MassPerUnitArea.IMPERIALUNIT;
  }

  static displayMetric(valueMetric: number) {
    return valueMetric.toFixed(4) + ' ' + MassPerUnitArea.METRICUNIT;
  }

  static display(valueMetric: number, showAsMetric: boolean) {
    if (showAsMetric) return MassPerUnitArea.displayMetric(valueMetric);
    else return MassPerUnitArea.convertMetricToImperial(valueMetric);
  }
}
