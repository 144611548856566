import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { configureLayer } from '../../services/ConfigitService';
import { Section, SingletonValue, VariableAssignment } from '../../types/configurator';
import { IManufacturerProduct } from '../ConfigitProvider';
import { ConfigitTextField } from '../shared/TextFieldComponent/ConfigitTextField';
import './LayerConfigurator.scss';
import { ConfigitTextFieldProduct } from '../shared/TextFieldComponent/ConfigitTextFieldProduct';

interface IFormRowProps {
  index: number;
  layerDetails: VariableAssignment[];
  model: string;
  productDetail: IManufacturerProduct;
  productDetails: IManufacturerProduct[];
  handleUpdateProductDetail: (index: number, productDetail: IManufacturerProduct) => void;
  handleRemoveProductDetail: (index: number, deletedproductid: string, deletedManufacturerid: string) => void;

}

const FormRow = ({
  index,
  layerDetails,
  model,
  productDetail,
  productDetails,
  handleUpdateProductDetail,
  handleRemoveProductDetail,

}: IFormRowProps) => {
  const variableAssignments = layerDetails.filter(
    (data) => data.variableId !== 'ManufacturerId' && data.variableId !== 'ComponentId_view'
  );

  if (productDetail.manufacturerId !== '') {
    variableAssignments.push({
      variableId: 'ManufacturerId',
      value: productDetail.manufacturerId,
    });
  }
  if (productDetail.productId !== '') {
    variableAssignments.push({
      variableId: 'ComponentId_view',
      value: productDetail.productId,
    });
  }
  const [data, setData] = useState<Section>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isSubscribed = false;
    const getLayerData = async () => {
      setLoading(true);
      const section = await configureLayer(variableAssignments, model);
      if (!isSubscribed) {
        setData(section);
        setLoading(false);
      }
    };
    getLayerData();
    return () => {
      isSubscribed = true;
    };
  }, [model, JSON.stringify(variableAssignments)]);
  let manufacturer;
  let product: any;
  let deletedProductId: string | null | undefined;
  let deletedManufacturerId: string | null | undefined;

  let conflictRequestAssigned = false;  // this flag is used to indicate whether this line is valid or not
  if (data) {
    if (localStorage.getItem('deletedManufacturer'))
      deletedManufacturerId = localStorage.getItem('deletedManufacturer');
    if (localStorage.getItem('deletedProduct')) deletedProductId = localStorage.getItem('deletedProduct');
    manufacturer = data.variables?.find((data) => data.id === 'ManufacturerId');
    product = data.variables?.find((data) => data.id === 'ComponentId_view');
    // Manufacturer Selected By User and Product not Selcetd
    if (manufacturer && product && productDetail.manufacturerId !== '' && productDetail.productId === '') {
      const manufactureValues = manufacturer?.values?.map((prodVal: SingletonValue) => {
        let finalValue: SingletonValue = prodVal;

        if (prodVal.value === productDetail.manufacturerId) {
          prodVal.incompatible = true;
          finalValue = prodVal;
        }
        return finalValue;
      });
      manufacturer.values = manufactureValues;
      // if (resetProductDetails.length > 0) {
      //   resetProductDetails?.map((deletedProds: IManufacturerProduct) => {
      //     // Reset Layer Product
      //     if (deletedProds.manufacturerId === productDetail.manufacturerId) {
      //       const productValues = product?.values?.map((prodVal: SingletonValue) => {
      //         let finalProdValue: SingletonValue = prodVal;

      //         if (prodVal.value === deletedProds.productId) {
      //           prodVal.incompatible = false;
      //           finalProdValue = prodVal;
      //         }

      //         return finalProdValue;
      //       });
      //       if (product) product.values = productValues;
      //     }
      //   });
      // }
    }

    // Explicit manufacturer/product selection needs to match assigned items in Configit response
    // Otherwise set the conflictRequestAssigned to be true to indicate this line become invalid
    if (productDetail.manufacturerId !== '') {
      const assignedManufacturer = manufacturer?.values?.find((manuValue: SingletonValue) => manuValue.assigned);
      if (assignedManufacturer) {
        if ((assignedManufacturer as SingletonValue).value !== productDetail.manufacturerId) {
          conflictRequestAssigned = true;
        }
      }
    }
    if (conflictRequestAssigned === false && productDetail.productId !== '') {
      const assignedProduct = product?.values?.find((prodValue: SingletonValue) => prodValue.assigned);
      if (assignedProduct) {
        if ((assignedProduct as SingletonValue).value !== productDetail.productId) {
          conflictRequestAssigned = true;
        }
      }
    }

    // Manufacturer is Selected By User and Product id Selcetd by Rule
    if (manufacturer && product && productDetail.manufacturerId !== '' && productDetail.productId === '') {
      const isRowExist = productDetails.find((ids) => ids.manufacturerId === productDetail.manufacturerId);
      const productValues = product?.values?.map((prodVal: SingletonValue) => {
        let finalValue: SingletonValue = prodVal;

        if (prodVal.value === productDetail.productId && prodVal.assigned !== 'byRule') {
          if (isRowExist) {
            prodVal.incompatible = true;
            finalValue = prodVal;
          }
        }
        return finalValue;
      });
      product.values = productValues;
      // Update Manufacturer dropdown
      const newmanufactureValues = manufacturer?.values?.map((prodVal: SingletonValue) => {
        let finalValue: SingletonValue = prodVal;

        if (prodVal.value === productDetail.manufacturerId) {
          prodVal.incompatible = false;
          finalValue = prodVal;
        }
        return finalValue;
      });
      manufacturer.values = newmanufactureValues;
    }
    // Manufacturer is selected By user and Product is selected by user
    if (manufacturer && product && productDetail.manufacturerId !== '' && productDetail.productId !== '') {
      const isRowExist = productDetails.find(
        (ids) => ids.manufacturerId === productDetail.manufacturerId && ids.productId === productDetail.productId
      );
      const productValues = product?.values?.map((prodVal: SingletonValue) => {
        let finalValue: SingletonValue = prodVal;

        if (prodVal.value === productDetail.productId && prodVal.assigned !== 'byUser') {
          if (isRowExist) {
            prodVal.incompatible = true;
            finalValue = prodVal;
          }
        }
        return finalValue;
      });
      product.values = productValues;
      // Update Manufacturer dropdown
      const newmanufactureValues = manufacturer?.values?.map((prodVal: SingletonValue) => {
        let finalValue: SingletonValue = prodVal;

        if (prodVal.value === productDetail.manufacturerId) {
          prodVal.incompatible = false;
          finalValue = prodVal;
        }
        return finalValue;
      });
      manufacturer.values = newmanufactureValues;
    }
    // Manufacturer id is empty and Product id is empty
    if (manufacturer && productDetail.manufacturerId === '' && productDetail.productId === '') {
      const previousRow = productDetails.find((ids) => ids.manufacturerId !== '' && ids.productId !== '');
      const newRowMnaufactureValues = manufacturer?.values?.map((prodVal: SingletonValue) => {
        let finalValue: SingletonValue = prodVal;
        if (prodVal.value === previousRow?.manufacturerId) {
          prodVal.incompatible = false;
          finalValue = prodVal;
        }
        return finalValue;
      });

      manufacturer.values = newRowMnaufactureValues;
      // Reset Layer Product
      const productValues = product?.values?.map((prodVal: SingletonValue) => {
        let finalProdValue: SingletonValue = prodVal;

        if (prodVal.assigned === 'byUser') {
          prodVal.incompatible = false;
          finalProdValue = prodVal;
        }

        return finalProdValue;
      });
      if (product) product.values = productValues;
    }
    // Manufacturer id is empty and Product id is selected by user
    if (manufacturer && productDetail.manufacturerId === '' && productDetail.productId !== '') {
      const isRowExist = productDetails.find((ids) => ids.productId === productDetail.productId);
      const productValues = product?.values?.map((prodVal: SingletonValue) => {
        let finalValue: SingletonValue = prodVal;

        if (prodVal.value === productDetail.productId && prodVal.assigned !== 'byUser') {
          if (isRowExist) {
            prodVal.incompatible = true;
            finalValue = prodVal;
          }
        }
        return finalValue;
      });
      if (product) product.values = productValues;
      // Update Manufacturer dropdown
      const newmanufactureValues = manufacturer?.values?.map((manufacturerVal: SingletonValue) => {
        let manufinalValue: SingletonValue = manufacturerVal;

        if (manufacturerVal.assigned === 'byRule') {
          manufacturerVal.incompatible = false;
          manufinalValue = manufacturerVal;
        }
        return manufinalValue;
      });
      manufacturer.values = newmanufactureValues;
    }
    // Reset Delete Manufacturer
    if (deletedManufacturerId) {
      const manufactureValues = manufacturer?.values?.map((prodVal: SingletonValue) => {
        let finalValue: SingletonValue = prodVal;

        if (prodVal.incompatible === true && prodVal.value === deletedManufacturerId) {
          prodVal.incompatible = false;
          finalValue = prodVal;
          localStorage.removeItem('deletedManufacturer');
        }
        return finalValue;
      });

      if (manufacturer) manufacturer.values = manufactureValues;
    }
    // Reset Product
    if (deletedProductId) {
      // const isRowExist = productDetails.find(
      //   (ids) => ids.manufacturerId === productDetail.manufacturerId && ids.productId === deletedProductId
      // );
      const productValues = product?.values?.map((prodVal: SingletonValue) => {
        let finalValue: SingletonValue = prodVal;

        if (prodVal.value === deletedProductId && prodVal.incompatible === true) {
          //if (!isRowExist) {
          prodVal.incompatible = false;
          finalValue = prodVal;
          localStorage.removeItem('deletedProduct');
          //}
        }
        return finalValue;
      });

      if (product) product.values = productValues;
    }
  }

  // Remove this product detail info from state if conflictRequestAssigned flag is true
  // Also UI is rendered for this manufacturer/product line only when conflictRequestAssigned flag has false value
  if (conflictRequestAssigned) handleRemoveProductDetail(index, productDetail.productId, productDetail.manufacturerId);

  return (
    <tr>
      {loading && <td className={'w-100'}>Loading...</td>}
      {!loading && data && !conflictRequestAssigned && (
        <>
          <td className=" px-1 pl-3">
            {manufacturer?.values && (
              <ConfigitTextField
                id={manufacturer?.id}
                options={manufacturer?.values}
                select={(variableId: string, value: string) => {
                  const manufacturerValue = data.variables
                    ?.find((data) => data.id === 'ManufacturerId')
                    ?.values?.find((data: SingletonValue) => data.value === value) as SingletonValue;
                  handleUpdateProductDetail(index, {
                    manufacturerId: value,
                    manufacturerLabel: manufacturerValue.name,
                    productId: productDetail.productId,
                    productLabel: productDetail.productLabel,
                  });
                }}
                unSelect={() => {
                  handleUpdateProductDetail(index, {
                    manufacturerId: '',
                    manufacturerLabel: undefined,
                    productId: productDetail.productId,
                    productLabel: productDetail.productLabel,
                  });
                }}
              />
            )}
          </td>
          <td className=" px-1 pl-4">
            {product?.values && (
              <ConfigitTextFieldProduct
                id={product?.id}
                options={product?.values}
                select={(variableId: string, value: string) => {
                  const productValue = data.variables
                    ?.find((data) => data.id === 'ComponentId_view')
                    ?.values?.find((data: SingletonValue) => data.value === value) as SingletonValue;
                  handleUpdateProductDetail(index, {
                    manufacturerId: productDetail.manufacturerId,
                    manufacturerLabel: productDetail.manufacturerLabel,
                    productId: value,
                    productLabel: productValue.name,
                  });
                }}
                unSelect={() => {
                  handleUpdateProductDetail(index, {
                    manufacturerId: productDetail.manufacturerId,
                    manufacturerLabel: productDetail.manufacturerLabel,
                    productId: '',
                    productLabel: undefined,
                  });
                }}
              />
            )}
          </td>
          <td className="d-flex justify-content-center align-middle xmark">
            <a
              onClick={() => handleRemoveProductDetail(index, productDetail.productId, productDetail.manufacturerId)}
              className="table-remove btn btn-light bg-transparent border-0 p-0 pt-1"
            >
              <FontAwesomeIcon icon={faTimes} />
            </a>
          </td>
        </>
      )}
    </tr>
  );
};

interface IManufacturerProductFormProps {
  layerDetails: VariableAssignment[];
  model: string;
  productDetails: IManufacturerProduct[];
  handleAddProductDetail: () => void;
  handleUpdateProductDetail: (index: number, productDetail: IManufacturerProduct) => void;
  handleRemoveProductDetail: (index: number, deletedProduct: string, deletedManufacturer: string) => void;

}

export const ManufacturerProductForm: React.FC<IManufacturerProductFormProps> = ({
  productDetails,
  handleAddProductDetail,
  handleUpdateProductDetail,
  handleRemoveProductDetail,

  layerDetails,
  model,
}: IManufacturerProductFormProps) => {
  return (
    <div className="form-group manufacture-form">
      <div id="tableCover" className="table-editable">
        <table className="table w-100">
          <thead>
            <tr>
              <th className="pb-2 px-3">Manufacturer</th>
              <th className="pb-2 pl-4 pr-3">Product</th>
            </tr>
          </thead>
          <tbody>
            {productDetails.map((productDetail, index) => {
              return (
                <FormRow
                  key={index}
                  index={index}
                  layerDetails={layerDetails}
                  model={model}
                  productDetail={productDetail}
                  productDetails={productDetails}
                  handleUpdateProductDetail={handleUpdateProductDetail}
                  handleRemoveProductDetail={handleRemoveProductDetail}

                />
              );
            })}
            <tr className={'p-1 mt-3'}>
              <td>
                {/* TODO: set up some state in parent to handle whether this is disabled */}
                <span
                  id="btnTableAdd"
                  onClick={handleAddProductDetail}
                  className={`btn btn-light bg-transparent border-0 px-3 py-0 `}
                >
                  <FontAwesomeIcon aria-hidden="true" icon={faPlus} /> Add Manufacturer or Product
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
