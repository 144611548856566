import React from 'react';
import { useGetFormDeckUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { Length } from '../../utils/Measurement/Length';
import './UsageDetails.scss';

export const FormDeckUsageDetail = (props: any) => {
  const { data, loading, error } = useGetFormDeckUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];
  const isMetric = usage?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Form Deck</h4>
      <table>
        <tr>
          <td>Min Thickness:</td>
          <td>{Length.display(usage?.Component?.ManufacturedProduct?.FormDeck?.ThicknessMin, isMetric)}</td>
        </tr>
        <tr>
          <td>Max Span:</td>
          <td>{Length.display(usage?.FormDeckUsage?.SpanMax, isMetric)}</td>
        </tr>
        <tr>
          <td>Depth:</td>
          <td>{Length.display(usage?.Component?.ManufacturedProduct?.FormDeck?.Depth, isMetric)}</td>
        </tr>
        <tr>
          <td>Vented:</td>
          <td>{usage?.Component?.ManufacturedProduct?.FormDeck?.IsVented ? 'Yes' : 'No'}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
