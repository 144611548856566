import { Link, Typography } from '@mui/material';
import React from 'react';
import theme from '../../theme';
import { ButtonComponent } from '../shared/ButtonComponent/ButtonComponent';
import { ButtonGroupComponent } from '../shared/ButtonGroupComponent/ButtonGroupComponent';
import { useDispatch } from 'react-redux';
import { previousSection, nextSection, setHail } from '../../reducers/calculatorReducer';
import { ToggleButtonComponent } from '../shared/ToggleButtonComponent/ToggleButtonComponent';
import { useSelector } from 'react-redux';
import { RootState } from './../../store';
import { HAIL } from '../constants/CALCULATOR_VALUES';
import { TextLinkComponent } from '../shared/TextLinkComponent/TextLinkComponent';



const bottomHailText =
  'For locations outside of the U.S., MH-rated systems can be used unless local weather records indicate the need for greater hail resistance. Generally, SH-rated systems are recommended for locations where the hail size exceeds 1.75 in. (44 mm) but does not exceed 2 in. (51 mm) for the 15 year mean recurrence interval. Refer to Loss Prevention Data Sheet 1-34 – Hail Damage for additional information.';
const topHailText =
  'Each FM Approved roof construction is rated for resistance to hail impact. All FM Approved roofs meet one of the following levels of resistance: Moderate Hail (MH), Severe Hail (SH), or Very Severe Hail (VSH).';
const middleHailText =
  'to determine whether or not the construction is in a Moderate, Severe or Very Severe Hail area. (Adobe Acrobat Reader version 6.0 or later is required to open this map. Click here to download this software for free.) You can use the magnifying glass button in Acrobat Reader to enlarge a specific section of the map.';

export const HailRatingComponent = () => {
  const selectHailRating = (state: RootState) => state.calculator.hailRatingState.hailRating
  const hailRating = useSelector(selectHailRating);
  const dispatch = useDispatch();

  return (
    <div className={'px-4 rn-hail-calc'}>
      <Typography className={'headline-1 py-5'} color={theme.palette.rnSteel.steel700}>
        Hail Ratings
      </Typography>
      <Typography className={'large-para pb-4'}>{topHailText}</Typography>
      <Typography className={'large-para pb-4'}>
        For U.S. locations, review the&nbsp;
        <TextLinkComponent
          linkStyle="font-weight-bold"
          isExternal
          linkText="Hail Damage Map"
          linkUrl={`/loaddocument/25`}
        />

        &nbsp;{middleHailText}
      </Typography>
      <Typography className={'large-para pb-5'}>{bottomHailText}</Typography>

      <div className={'py-5'}>
        <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
          Hail
        </Typography>
        <ButtonGroupComponent className={'pb-2'} >
          <ToggleButtonComponent
            options={HAIL}
            handleSelect={(value) => dispatch(setHail(value))}
            selectedOption={hailRating}
          ></ToggleButtonComponent>
        </ButtonGroupComponent>

      </div>
      <div className={'row m-0 py-3'}>
        <ButtonComponent
          id='previousSection'
          buttonType={'secondary'}
          buttonText={'Previous'}
          click={() => dispatch(previousSection())}
          buttonStyle={'ml-0'}
        />
        <div className={'mx-1'}>&nbsp;</div>
        <ButtonComponent
          id='nextSection'
          buttonType={'primary'}
          buttonText={'Next'}
          click={() => dispatch(nextSection())}
          buttonStyle={''}
        />
      </div>
    </div>
  );
};
