import React from 'react';
import { useGetAdhesiveFullApplicationUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { SolidApplicationRate } from '../../utils/Measurement/SolidApplicationRate';
import { VolumePerUnitArea } from '../../utils/Measurement/VolumePerUnitArea';
import './UsageDetails.scss';

export const AdhesiveFullApplicationUsageDetail = (props: any) => {
  const { data, loading, error } = useGetAdhesiveFullApplicationUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];
  const isMetric = usage?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Adhesive (Full Application)</h4>
      <table>
        <tr>
          <td>Material:</td>
          <td>
            {usage?.Component?.ManufacturedProduct?.AdhesiveFullApplication?.C_AdhesiveMaterial.AdhesiveMaterialCode}
          </td>
        </tr>
        <tr>
          <td>Substrate Primer Rate Wet:</td>
          <td>{VolumePerUnitArea.display(usage?.AdhesiveFullApplicationUsage?.SubstratePrimerRateWet, isMetric)}</td>
        </tr>
        <tr>
          <td>Type:</td>
          <td>{usage?.Component?.ManufacturedProduct?.AdhesiveFullApplication?.C_AdhesiveType.AdhesiveTypeCode}</td>
        </tr>
        <tr>
          <td>Over Substrate Rate Wet:</td>
          <td>{VolumePerUnitArea.display(usage?.AdhesiveFullApplicationUsage?.OverSubstrateRateWet, isMetric)}</td>
        </tr>
        <tr>
          <td>Underside Rate Wet:</td>
          <td>{VolumePerUnitArea.display(usage?.AdhesiveFullApplicationUsage?.UndersideRateWet, isMetric)}</td>
        </tr>
        <tr>
          <td>Substrate Primer Rate Dry:</td>
          <td>{SolidApplicationRate.display(usage?.AdhesiveFullApplicationUsage?.SubstratePrimerRateDry, isMetric)}</td>
        </tr>
        <tr>
          <td>Over Substrate Rate Dry:</td>
          <td>{SolidApplicationRate.display(usage?.AdhesiveFullApplicationUsage?.OverSubstrateRateDry, isMetric)}</td>
        </tr>
        <tr>
          <td>Underside Rate Dry:</td>
          <td>{SolidApplicationRate.display(usage?.AdhesiveFullApplicationUsage?.UndersideRateDry, isMetric)}</td>
        </tr>
        <tr>
          <td valign="top">Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
