import { apolloClient } from '../ApolloClient';
import {
  IConfigitCriteria,
  ILayer,
  IManufacturerProduct,
  ISecurementLayer,
  ISelfSecurement,
} from '../components/ConfigitProvider';
import { LayerType, SpecialApprovedUse, SpecialSubCategory } from '../components/constants/ENUMS';
import { EXCHANGE_VARIABLES } from '../components/constants/EXCHANGE_VARIABLES';
import {
  COMPONENTLAYERTYPEAPPROVEDUSE_MAP,
  COMPONENTLAYERTYPESUBCATEGORY_MAP,
} from '../components/constants/LAYERTYPEAPPROVEDUSE_MAP';
import {
  AssemblyApiRoofTypeSearchDocument,
  AssemblyApiRoofTypeSearchQuery,
  AssemblyApiRoofTypeSearchQueryVariables,
  AssemblyApiSearchDocument,
  AssemblyApiSearchQuery,
  AssemblyApiSearchQueryVariables,
  AssemblySearchCriteria,
  AttributeSearchCriteria,
  LayerSearchCriteriaBase,
  NavAssembly_Bool_Exp,
  NavAssembly_Order_By,
  Order_By,
  SelfSecurementSearchCriteria,
} from '../models/GQLGeneratedModels';
import { Section, SingletonValue, VariableAssignment } from '../types/configurator';

export enum ComparisonOperator {
  EqualTo = 0,
  NotEqualTo = 1,
  GreaterThan = 2,
  GreaterThanOrEqual = 3,
  LessThan = 4,
  LessThanOrEqual = 5,
}

export enum AttributeType {
  Boolean = 1,
  CodeTable = 2,
  Measurement = 3,
  Text = 4,
  Multivalue = 5,
}

export enum SortOperator {
  Ascending = 0,
  Descending = 1,
}

function getSubCategoryId(arrayVariableAssignments: VariableAssignment[], section: Section | undefined): number {
  let subCategoryId = 0;
  // Get subCategoryId from variable ManufacturedProduct_SubcategoryId
  const dataSubCategoryId = arrayVariableAssignments.find(
    (data) => data.variableId === 'ManufacturedProduct_SubcategoryId'
  );
  if (dataSubCategoryId === undefined) {
    subCategoryId = 0;
  } else {
    if (dataSubCategoryId.value) {
      subCategoryId = parseInt(dataSubCategoryId.value.toString());
      subCategoryId = isNaN(subCategoryId) ? 0 : subCategoryId;
    }
  }

  if (subCategoryId === 0 && section) {
    const itemSubCategory = section.variables?.find((item) => item.id === 'ManufacturedProduct_SubcategoryId');
    if (itemSubCategory && itemSubCategory.values)
      for (let i = 0; i < itemSubCategory.values.length; i++) {
        if (
          itemSubCategory.values[i].hasOwnProperty('assigned') &&
          itemSubCategory.values[i]['assigned'] === 'byRule'
        ) {
          const temp = (itemSubCategory.values[i] as SingletonValue).value?.toString();
          if (temp) {
            subCategoryId = parseInt(temp);
            subCategoryId = isNaN(subCategoryId) ? 0 : subCategoryId;
          }
          break;
        }
      }
  }

  return subCategoryId;
}

function getApprovedUseId(arrayVariableAssignments: VariableAssignment[], section: Section | undefined): number {
  let approvedUseId = 0;
  // Get approvedUseId from variable CApprovedUse_ApprovedUseId
  const dataApprovedUseId = arrayVariableAssignments.find((data) => data.variableId === 'CApprovedUse_ApprovedUseId');
  if (dataApprovedUseId === undefined) {
    approvedUseId = 0;
  } else {
    if (dataApprovedUseId.value) {
      approvedUseId = parseInt(dataApprovedUseId.value.toString());
      approvedUseId = isNaN(approvedUseId) ? 0 : approvedUseId;
    }
  }

  if (approvedUseId === 0 && section) {
    const itemApprovedUse = section.variables?.find((item) => item.id === 'CApprovedUse_ApprovedUseId');
    if (itemApprovedUse && itemApprovedUse.values)
      for (let i = 0; i < itemApprovedUse.values.length; i++) {
        if (
          itemApprovedUse.values[i].hasOwnProperty('assigned') &&
          itemApprovedUse.values[i]['assigned'] === 'byRule'
        ) {
          const temp = (itemApprovedUse.values[i] as SingletonValue).value?.toString();
          if (temp) {
            approvedUseId = parseInt(temp);
            approvedUseId = isNaN(approvedUseId) ? 0 : approvedUseId;
          }
          break;
        }
      }
  }

  return approvedUseId;
}

export const getAssembliesByActionAPI = async (assemblySearchCriteria: AssemblySearchCriteria) => {
  const result = await apolloClient.query<AssemblyApiSearchQuery, AssemblyApiSearchQueryVariables>({
    query: AssemblyApiSearchDocument,
    variables: {
      assemblySearchCriteria: assemblySearchCriteria,
    },
  });
  //return result.data.navassembly_api_search;
  return result;
};

export const getAssembliesRoofTypeByActionAPI = async (assemblySearchCriteria: AssemblySearchCriteria) => {
  const result = await apolloClient.query<AssemblyApiRoofTypeSearchQuery, AssemblyApiRoofTypeSearchQueryVariables>({
    query: AssemblyApiRoofTypeSearchDocument,
    variables: {
      assemblySearchCriteria: assemblySearchCriteria,
    },
  });
  //return result.data.navassembly_api_rooftypesearch;
  return result;
};

export const testSearchAssembliesAPI = async () => {
  // // Test Case 1:
  // const assemblySearchCriteria: AssemblySearchCriteria = {
  //   assemblyCriteria: {
  //     AssemblyTypeId: 3,
  //   },
  //   LayerCriteria: [
  //     {
  //       layerTypeId: 22,
  //       approvedUseId: 5,
  //       layerNum: 1,
  //       fromlayerNum: 0,
  //       tolayerNum: 0,
  //       subCategoryId: 21,
  //       manufacturerIds: [532],
  //       componentIds: [],
  //       attributes: [
  //         {
  //           attributeName: 'ReinforcementId',
  //           comparisonOperator: 0,
  //           attributeValue: '6',
  //           attributeType: 2,
  //         },
  //         {
  //           attributeName: 'Width',
  //           comparisonOperator: 0,
  //           attributeValue: '914.4',
  //           attributeType: 3,
  //         },
  //       ],
  //       selfSecurement: null,
  //     },
  //   ],
  //   rowsPerPage: 25,
  //   pageNumber: 1,
  //   sortColumn: 'AssemblyApplicationTypeCode',
  //   sortDirection: 0,
  // };

  // Test Case 2: JSON object was created/populated from getAPISearchCriteriaBasedOnFilters function
  const assemblySearchCriteria: AssemblySearchCriteria = {
    assemblyCriteria: {
      AssemblyTypeId: 3,
      DeckTypeId: 7,
      HailRatingId: 1,
      IntFireRatingId: 2,
      ExtFireRatingId: 1,
    },
    LayerCriteria: [
      {
        layerTypeId: 39,
        approvedUseId: 0,
        subCategoryId: 0,
        attributes: [],
        manufacturerIds: [],
        componentIds: [],
        layerNum: 1,
        fromlayerNum: 0,
        tolayerNum: 0,
        selfSecurement: null,
      },
      {
        layerTypeId: 22,
        approvedUseId: 5,
        subCategoryId: 21,
        attributes: [
          {
            attributeName: 'ReinforcementId',
            attributeType: 2,
            attributeValue: '6',
            comparisonOperator: 0,
          },
          {
            attributeName: 'Width',
            attributeType: 3,
            attributeValue: '914.4',
            comparisonOperator: 0,
          },
        ],
        manufacturerIds: [532],
        componentIds: [],
        layerNum: 2,
        fromlayerNum: 0,
        tolayerNum: 0,
        selfSecurement: null,
      },
      {
        layerTypeId: 41,
        approvedUseId: 29,
        subCategoryId: 0,
        attributes: [
          {
            attributeName: 'SecurementMaterialId',
            attributeType: 2,
            attributeValue: '1',
            comparisonOperator: 0,
          },
        ],
        manufacturerIds: [],
        componentIds: [],
        layerNum: 3,
        fromlayerNum: 2,
        tolayerNum: 0,
        selfSecurement: null,
      },
      {
        layerTypeId: 25,
        approvedUseId: 0,
        subCategoryId: 0,
        attributes: [],
        manufacturerIds: [],
        componentIds: [],
        layerNum: 4,
        fromlayerNum: 0,
        tolayerNum: 0,
        selfSecurement: null,
      },
      {
        layerTypeId: 35,
        approvedUseId: 0,
        subCategoryId: 0,
        attributes: [],
        manufacturerIds: [],
        componentIds: [],
        layerNum: 5,
        fromlayerNum: 0,
        tolayerNum: 0,
        selfSecurement: null,
      },
    ],
    rowsPerPage: 25,
    pageNumber: 1,
    sortColumn: 'NavAssemblyName',
    sortDirection: 0,
  };

  const result = await getAssembliesByActionAPI(assemblySearchCriteria);
  //console.log(result.data.navassembly_api_search);
  result.data.navassembly_api_search?.map((navAssembly) => {
    console.log(navAssembly?.NavAssemblyName);
  });
};

export const testSearchAssembliesRoofTypeAPI = async () => {
  const assemblySearchCriteria: AssemblySearchCriteria = {
    assemblyCriteria: {
      AssemblyTypeId: 3,
    },
    LayerCriteria: [
      {
        layerTypeId: 22,
        approvedUseId: 5,
        layerNum: 1,
        fromlayerNum: 0,
        tolayerNum: 0,
        subCategoryId: 21,
        manufacturerIds: [532],
        componentIds: [],
        attributes: [
          {
            attributeName: 'ReinforcementId',
            comparisonOperator: 0,
            attributeValue: '6',
            attributeType: 2,
          },
          {
            attributeName: 'Width',
            comparisonOperator: 0,
            attributeValue: '914.4',
            attributeType: 3,
          },
        ],
        selfSecurement: null,
      },
    ],
    rowsPerPage: 25,
    pageNumber: 1,
    sortColumn: 'NumOfAssembly',
    sortDirection: 0,
  };

  const result = await getAssembliesRoofTypeByActionAPI(assemblySearchCriteria);
  console.log(result.data.navassembly_api_rooftypesearch);
};

export const checkIfAPISearchNecessary = (layers: ILayer[], filters: NavAssembly_Bool_Exp) => {
  const needAPISearch = false;

  //TODO Determine needAPISearch value based on filters
  //Duplicate layers with different attributes
  //Mapped Securement layers (exclude unmapped securement layers?) with manufacturers/products?, subcategory, approved use, and attributes?
  //Self Securement

  const securementLayers = layers.filter((layer) => layer.isSecurementLayer === true);
  if (securementLayers.length > 0) {
    for (let index = 0; index < securementLayers.length; index++) {
      // For each securement layer we only need to check SubCategoryId and ApprovedUseId
      // Since valid securement attribute filters have to be based on a specific approved use
      let layerSubCategoryId = 0;
      let layerApprovedUseId = 0;
      const securementLayer = securementLayers[index] as ISecurementLayer;
      if (securementLayer.variableAssignments) {
        const arrayVariableAssignments = securementLayer.variableAssignments as VariableAssignment[];
        // Get subCategoryId from variable ManufacturedProduct_SubcategoryId
        const dataSubCategoryId = arrayVariableAssignments.find(
          (data) => data.variableId === 'ManufacturedProduct_SubcategoryId'
        );
        if (dataSubCategoryId === undefined) {
          layerSubCategoryId = 0;
        } else {
          if (dataSubCategoryId.value) {
            const subCategoryId = parseInt(dataSubCategoryId.value.toString());
            layerSubCategoryId = isNaN(subCategoryId) ? 0 : subCategoryId;
          }
        }
        // Get approvedUseId from variable CApprovedUse_ApprovedUseId
        const dataApprovedUseId = arrayVariableAssignments.find(
          (data) => data.variableId === 'CApprovedUse_ApprovedUseId'
        );
        if (dataApprovedUseId === undefined) {
          layerApprovedUseId = 0;
        } else {
          if (dataApprovedUseId.value) {
            const approvedUseId = parseInt(dataApprovedUseId.value.toString());
            layerApprovedUseId = isNaN(approvedUseId) ? 0 : approvedUseId;
          }
        }
      }

      if (layerSubCategoryId > 0 || layerApprovedUseId > 0) {
        return true;
      }
    }
  }

  const componentLayers = layers.filter((layer) => layer.isSecurementLayer === false);
  if (componentLayers.find((layer) => layer.hasSelfSecurement)) return true;
  else {
    const arrayLayerTypeIds = componentLayers.map((layer) => layer.id);
    const hasDuplicateLayers = arrayLayerTypeIds.some((layerTypeId, idx) => {
      return arrayLayerTypeIds.indexOf(layerTypeId) != idx;
    });
    if (hasDuplicateLayers) return true;
  }

  return needAPISearch;
};

export const getConfigitCriteria = (
  layers: ILayer[],
  variableAssignments: VariableAssignment[],
  excludedLayerIds: string[],
  manufacturerProduct: VariableAssignment[],
  limit: number,
  offset: number,
  orderBy: NavAssembly_Order_By
) => {
  return {
    layers: layers
      .filter((data) => !data.autoSelected)
      .map((data) => {
        return { ...data, section: undefined };
      }),
    variableAssignments: variableAssignments.filter((data) => data.value),
    excludedLayerIds,
    manufacturerProduct,
    limit,
    offset,
    orderBy,
  } as IConfigitCriteria;
};

export const getAPISearchCriteriaBasedOnFilters = (
  layers: ILayer[],
  filters: NavAssembly_Bool_Exp,
  excludedLayerTypeIds: string[],
  manufacturerProduct: VariableAssignment[],
  limit: number,
  offset: number,
  orderBy: NavAssembly_Order_By
) => {
  // console.log('layers in API Search is ', layers);
  // console.log('filters in API Search is ', filters);
  // Set initialize assemblySearchCriteria
  const assemblySearchCriteria: AssemblySearchCriteria = {
    assemblyCriteria: {},
    LayerCriteria: [],
    rowsPerPage: 25,
    pageNumber: 1,
    sortColumn: 'NavAssemblyName',
    sortDirection: 0,
  };

  // For Assembly Number Search
  if (filters.NavAssemblyName && filters.NavAssemblyName._like) {
    const searchString = filters.NavAssemblyName._like.toString();
    if (searchString.length > 0 && searchString.slice(-1) === '%') {
      assemblySearchCriteria.assemblyCriteria.NavAssemblyName = searchString.substring(0, searchString.length - 1);
    }
  }

  // Roof Type
  if (filters.LayerConfigId && filters.LayerConfigId._eq) {
    assemblySearchCriteria.assemblyCriteria.LayerConfigId = parseInt(filters.LayerConfigId._eq.toString());
  }

  // Assembly Property Search Criteria
  // The following Assembly Property Search Criteria only involve of comparison operator _eq
  // Such criteria in filters has the format like AssemblyApplicationTypeId: {_eq: 1}
  if (filters.AssemblyApplicationTypeId && filters.AssemblyApplicationTypeId._eq) {
    assemblySearchCriteria.assemblyCriteria.AssemblyApplicationTypeId = parseInt(
      filters.AssemblyApplicationTypeId._eq.toString()
    );
  }

  if (filters.AssemblyTypeId && filters.AssemblyTypeId._eq) {
    assemblySearchCriteria.assemblyCriteria.AssemblyTypeId = parseInt(filters.AssemblyTypeId._eq.toString());
  }

  if (filters.CoverSecurementMethodId && filters.CoverSecurementMethodId._eq) {
    assemblySearchCriteria.assemblyCriteria.CoverSecurementMethodId = parseInt(
      filters.CoverSecurementMethodId._eq.toString()
    );
  }

  if (filters.DeckTypeId && filters.DeckTypeId._eq) {
    assemblySearchCriteria.assemblyCriteria.DeckTypeId = parseInt(filters.DeckTypeId._eq.toString());
  }

  if (filters.HailRatingId && filters.HailRatingId._eq) {
    assemblySearchCriteria.assemblyCriteria.HailRatingId = parseInt(filters.HailRatingId._eq.toString());
  }

  if (filters.IntFireRatingId && filters.IntFireRatingId._eq) {
    assemblySearchCriteria.assemblyCriteria.IntFireRatingId = parseInt(filters.IntFireRatingId._eq.toString());
  }

  if (filters.ExtFireRatingId && filters.ExtFireRatingId._eq) {
    assemblySearchCriteria.assemblyCriteria.ExtFireRatingId = parseInt(filters.ExtFireRatingId._eq.toString());
  }

  // Wind Uplift and Slope: involve of comparison operators _eq, _gte, and _lte
  // Such criteria in filters has the format like WindUplift: {_eq: "90"} or WindUplift: {_gte: "90"}
  if (filters.WindUplift && filters.WindUplift._eq) {
    assemblySearchCriteria.assemblyCriteria.WindUplift = parseFloat(filters.WindUplift._eq.toString());
    assemblySearchCriteria.assemblyCriteria.WindUpliftOperator = ComparisonOperator.EqualTo;
  } else if (filters.WindUplift && filters.WindUplift._gte) {
    assemblySearchCriteria.assemblyCriteria.WindUplift = parseFloat(filters.WindUplift._gte.toString());
    assemblySearchCriteria.assemblyCriteria.WindUpliftOperator = ComparisonOperator.GreaterThanOrEqual;
  } else if (filters.WindUplift && filters.WindUplift._lte) {
    assemblySearchCriteria.assemblyCriteria.WindUplift = parseFloat(filters.WindUplift._lte.toString());
    assemblySearchCriteria.assemblyCriteria.WindUpliftOperator = ComparisonOperator.LessThanOrEqual;
  }

  if (filters.Slope && filters.Slope._eq) {
    assemblySearchCriteria.assemblyCriteria.Slope = parseFloat(filters.Slope._eq.toString());
    assemblySearchCriteria.assemblyCriteria.SlopeOperator = ComparisonOperator.EqualTo;
  } else if (filters.Slope && filters.Slope._gte) {
    assemblySearchCriteria.assemblyCriteria.Slope = parseFloat(filters.Slope._gte.toString());
    assemblySearchCriteria.assemblyCriteria.SlopeOperator = ComparisonOperator.GreaterThanOrEqual;
  } else if (filters.Slope && filters.Slope._lte) {
    assemblySearchCriteria.assemblyCriteria.Slope = parseFloat(filters.Slope._lte.toString());
    assemblySearchCriteria.assemblyCriteria.SlopeOperator = ComparisonOperator.LessThanOrEqual;
  }

  if (filters.PVApplicationMethodId && filters.PVApplicationMethodId._eq) {
    assemblySearchCriteria.assemblyCriteria.PVApplicationMethodId = parseInt(
      filters.PVApplicationMethodId._eq.toString()
    );
  }

  if (filters.PVHailRatingId && filters.PVHailRatingId._eq) {
    assemblySearchCriteria.assemblyCriteria.PVHailRatingId = parseInt(filters.PVHailRatingId._eq.toString());
  }

  if (filters.IncludesPV && filters.IncludesPV._eq) {
    assemblySearchCriteria.assemblyCriteria.IncludesPV = filters.IncludesPV._eq.toString().toLowerCase() === 'true';
  }

  if (filters.PVWindUplift && filters.PVWindUplift._eq) {
    assemblySearchCriteria.assemblyCriteria.PVWindUplift = parseFloat(filters.PVWindUplift._eq.toString());
    assemblySearchCriteria.assemblyCriteria.PVWindUpliftOperator = ComparisonOperator.EqualTo;
  } else if (filters.PVWindUplift && filters.PVWindUplift._gte) {
    assemblySearchCriteria.assemblyCriteria.PVWindUplift = parseFloat(filters.PVWindUplift._gte.toString());
    assemblySearchCriteria.assemblyCriteria.PVWindUpliftOperator = ComparisonOperator.GreaterThanOrEqual;
  } else if (filters.PVWindUplift && filters.PVWindUplift._lte) {
    assemblySearchCriteria.assemblyCriteria.PVWindUplift = parseFloat(filters.PVWindUplift._lte.toString());
    assemblySearchCriteria.assemblyCriteria.PVWindUpliftOperator = ComparisonOperator.LessThanOrEqual;
  }

  // LayerConfigString LIKE '%%' is generated by API code based on layer type ids of layers criteria

  // Populate global search ManufacturerId, ComponentId and LayerTypeId
  // TOCONFIRM: Global products used search should be exclusive to other searches?
  // Current UI implementation: products used search is exclusive to explicitly selected assembly properties? but it can combine with explicitly added roof layers search criteria?
  if (manufacturerProduct && manufacturerProduct.length > 0) {
    for (let i = 0; i < manufacturerProduct.length; i++) {
      if (manufacturerProduct[i].variableId === 'Manufacturer_ManufacturerId')
        assemblySearchCriteria.assemblyCriteria.ManufacturerId = parseInt(manufacturerProduct[i].value as string);
      else if (manufacturerProduct[i].variableId === 'ManufacturedProduct_ComponentId')
        assemblySearchCriteria.assemblyCriteria.ComponentId = parseInt(manufacturerProduct[i].value as string);
      else if (manufacturerProduct[i].variableId === 'C_LayerType_LayerTypeId')
        assemblySearchCriteria.assemblyCriteria.LayerTypeId = parseInt(manufacturerProduct[i].value as string);
    }
  }

  // Layers Search Criteria
  // TOCHECK: Only consider layers[i].autoSelected === false. It is related to layers join for layer cake search and layerconfig like '%%' in header query
  // Auto polulated manufacturer(s)/product(s) are NOT in productDetails of the layer
  // Auto selected attributes(s) are NOT in variableAssignments of the layer
  // Auto selection(s) on assembly properties are in filters. They are in assemblySearchCriteria.assemblyCriteria based on above Assembly Property Search Criteria assignment.
  for (let i = 0; i < layers.length; i++) {
    const layerSearchCriteria: LayerSearchCriteriaBase = {
      layerTypeId: 0,
      approvedUseId: 0,
      subCategoryId: 0,
      attributes: [],
      manufacturerIds: [],
      componentIds: [],
      layerNum: 0,
      fromlayerNum: 0,
      tolayerNum: 0,
      selfSecurement: null,
    };
    layerSearchCriteria.layerTypeId = parseInt(layers[i].id);
    layerSearchCriteria.layerNum = i + 1;

    //Get approvedUseId and subCategoryId (theoretically if there is specific ApprovedUseId, subCategoryId is not necessary for search)
    if (layers[i].isSecurementLayer) {
      // securement layer
      if (layers[i].variableAssignments) {
        const arrayVariableAssignments = layers[i].variableAssignments as VariableAssignment[];
        layerSearchCriteria.subCategoryId = getSubCategoryId(arrayVariableAssignments, layers[i].section);
        layerSearchCriteria.approvedUseId = getApprovedUseId(arrayVariableAssignments, layers[i].section);
      }
    } else {
      // component layer
      // LayerType 23 - Coating/Surfacing and LayerType 37 - Vapor Retarder may have multiple approved uses.
      if (
        layerSearchCriteria.layerTypeId === LayerType.Coating_Surfacing_23 ||
        layerSearchCriteria.layerTypeId === LayerType.Vapor_Retarder_37
      ) {
        if (layers[i].variableAssignments) {
          const arrayVariableAssignments = layers[i].variableAssignments as VariableAssignment[];
          layerSearchCriteria.subCategoryId = getSubCategoryId(arrayVariableAssignments, layers[i].section);
          layerSearchCriteria.approvedUseId =
            layerSearchCriteria.subCategoryId === SpecialSubCategory.Surfacing
              ? SpecialApprovedUse.Surfacing
              : getApprovedUseId(arrayVariableAssignments, layers[i].section);
        }
      } else {
        // Get approvedUseId from layer type id and approved use id 1-1 mapping
        // Get subCategoryId from layer type id and subcategory id 1-1 mapping
        const mappedApprovedUseId: number | undefined =
          COMPONENTLAYERTYPEAPPROVEDUSE_MAP[layerSearchCriteria.layerTypeId];
        layerSearchCriteria.approvedUseId = typeof mappedApprovedUseId !== 'undefined' ? mappedApprovedUseId : 0;
        const mappedSubCategoryId: number | undefined =
          COMPONENTLAYERTYPESUBCATEGORY_MAP[layerSearchCriteria.layerTypeId];
        layerSearchCriteria.subCategoryId = typeof mappedSubCategoryId !== 'undefined' ? mappedSubCategoryId : 0;
      }
    }

    //for manufacturer(s) and product(s)
    const manufacturerIds: number[] = [];
    const componentIds: number[] = [];
    if (layers[i].productDetails) {
      const arrayManufacturerProduct = layers[i].productDetails as IManufacturerProduct[];
      for (let index = 0; index < arrayManufacturerProduct.length; index++) {
        if (arrayManufacturerProduct[index].productId !== '') {
          // Product
          componentIds.push(parseInt(arrayManufacturerProduct[index].productId));
        } else if (arrayManufacturerProduct[index].manufacturerId !== '') {
          // Manufacturer
          manufacturerIds.push(parseInt(arrayManufacturerProduct[index].manufacturerId));
        }
      }
    }
    layerSearchCriteria.manufacturerIds = manufacturerIds;
    layerSearchCriteria.componentIds = componentIds;

    // //for attributes filters, reference updateLayerFilter method in src\utils\LayerTypeCheckUtilities.ts
    // //layers[i].variableAssignments and layers[i].section?.variables
    layerSearchCriteria.attributes = getLayerAttributes(layers[i]);

    // Currently for securementLayer: fromlayerNum and tolayerNum are identifiers of duplicate layers, not layer numbers
    // So, layerSearchCriteria fromLayerNum and toLayerNum have different meaning from securementLayer fromlayerNum and tolayerNum
    // See buildLayerCake function in src\utils\LayerApi.ts   ex. 23,11&40,39{11:22},22,41{22:30-1},30-1,28,30-2,26&51,44{26:35},35
    // const tempSecuremnt = tempFull[1].split(':'); const tempFrom = tempSecuremnt[0].split('-');
    // const fromLayerTypeId = parseInt(tempFrom[0]); const fromLayerNum = parseInt(tempFrom[1]) ?? 1;
    // fromLayerTypeId and toLayerTypeId are the expected values
    if (layers[i].isSecurementLayer) {
      const securementLayer = layers[i] as ISecurementLayer;
      //if (securementLayer.fromLayerNum) layerSearchCriteria.fromlayerNum = securementLayer.fromLayerNum;
      //if (securementLayer.toLayerNum) layerSearchCriteria.tolayerNum = securementLayer.toLayerNum;

      // get layerSearchCriteria.fromlayerNum
      if (securementLayer.fromLayerNum) {
        if (securementLayer.fromLayerNum === 1) {
          layerSearchCriteria.fromlayerNum =
            layers.findIndex((data) => parseInt(data.id) === securementLayer.fromLayerTypeId) + 1;
        } else if (securementLayer.fromLayerNum > 1) {
          let matched = 0;
          for (let j = 0; j < layers.length; j++) {
            if (parseInt(layers[j].id) === securementLayer.fromLayerTypeId) {
              matched++;
              if (matched === securementLayer.fromLayerNum) {
                layerSearchCriteria.fromlayerNum = j + 1;
                break;
              }
            }
          }
        }
      }

      // get layerSearchCriteria.tolayerNum
      if (securementLayer.toLayerNum) {
        if (securementLayer.toLayerNum === 1) {
          layerSearchCriteria.tolayerNum =
            layers.findIndex((data) => parseInt(data.id) === securementLayer.toLayerTypeId) + 1;
        } else if (securementLayer.toLayerNum > 1) {
          let matched = 0;
          for (let j = 0; j < layers.length; j++) {
            if (parseInt(layers[j].id) === securementLayer.toLayerTypeId) {
              matched++;
              if (matched === securementLayer.toLayerNum) {
                layerSearchCriteria.tolayerNum = j + 1;
                break;
              }
            }
          }
        }
      }
    }

    // Self-Securement
    if (layers[i].hasSelfSecurement) {
      // layerSearchCriteria.selfSecurement = {
      //   selfSecurementLayerTypeId: 0,
      //   approvedUseId: 0,
      //   subCategoryId: 0,
      //   attributes: [],
      //   manufacturerIds: [],
      //   componentIds: [],
      //   layerNum: 0,
      // };
      // layerSearchCriteria.selfSecurement.layerNum = i + 1;
      // layerSearchCriteria.selfSecurement.selfSecurementLayerTypeId = parseInt(layers[i].SelfSecurementId as string);
      // //TODO
      // // 1. Consider adding interface ISelfSecurement and SelfSecurement property (object that implements ISelfSecurement) into ILayer.
      // // We need more info on SelfSecurement instead of hasSelfSecurement and SelfSecurementId only.
      // // 2. How to get self-securement Approved Use Id and attributes. Check getLayerAttributes. Can get info from variableAssignments similarly??
      // // 3. Manufacturer and Product on self-securement. Layer itself has productDetails property.

      layerSearchCriteria.selfSecurement = getSelfSecurementSearchCriteria(layers[i], i);
    }

    assemblySearchCriteria.LayerCriteria.push(layerSearchCriteria);
  }

  // excluded layer types
  if (excludedLayerTypeIds && excludedLayerTypeIds.length > 0) {
    assemblySearchCriteria.excludedLayerTypeIds = excludedLayerTypeIds;
  }

  // Paging and Sorting properties
  assemblySearchCriteria.rowsPerPage = limit;
  assemblySearchCriteria.pageNumber = offset / limit + 1;
  if (orderBy.NavAssemblyName) {
    assemblySearchCriteria.sortColumn = 'NavAssemblyName';
    assemblySearchCriteria.sortDirection = orderBy.NavAssemblyName === Order_By.Asc ? 0 : 1;
  } else if (orderBy.AssemblyTypeCode) {
    assemblySearchCriteria.sortColumn = 'AssemblyTypeCode';
    assemblySearchCriteria.sortDirection = orderBy.AssemblyTypeCode === Order_By.Asc ? 0 : 1;
  } else if (orderBy.AssemblyApplicationTypeCode) {
    assemblySearchCriteria.sortColumn = 'AssemblyApplicationTypeCode';
    assemblySearchCriteria.sortDirection = orderBy.AssemblyApplicationTypeCode === Order_By.Asc ? 0 : 1;
  } else if (orderBy.CoverSecurementMethodCode) {
    assemblySearchCriteria.sortColumn = 'CoverSecurementMethodCode';
    assemblySearchCriteria.sortDirection = orderBy.CoverSecurementMethodCode === Order_By.Asc ? 0 : 1;
  } else if (orderBy.DeckTypeCode) {
    assemblySearchCriteria.sortColumn = 'DeckTypeCode';
    assemblySearchCriteria.sortDirection = orderBy.DeckTypeCode === Order_By.Asc ? 0 : 1;
  } else if (orderBy.WindUplift || orderBy.WindUpliftValue) {
    assemblySearchCriteria.sortColumn = 'WindUplift';
    if (orderBy.WindUplift) assemblySearchCriteria.sortDirection = orderBy.WindUplift === Order_By.Asc ? 0 : 1;
    else assemblySearchCriteria.sortDirection = orderBy.WindUpliftValue === Order_By.Asc ? 0 : 1;
  } else if (orderBy.IntFireRatingCode) {
    assemblySearchCriteria.sortColumn = 'IntFireRatingCode';
    assemblySearchCriteria.sortDirection = orderBy.IntFireRatingCode === Order_By.Asc ? 0 : 1;
  } else if (orderBy.ExtFireRatingCode) {
    assemblySearchCriteria.sortColumn = 'ExtFireRatingCode';
    assemblySearchCriteria.sortDirection = orderBy.ExtFireRatingCode === Order_By.Asc ? 0 : 1;
  } else if (orderBy.Slope || orderBy.SlopeValue) {
    assemblySearchCriteria.sortColumn = 'Slope';
    if (orderBy.Slope) assemblySearchCriteria.sortDirection = orderBy.Slope === Order_By.Asc ? 0 : 1;
    else assemblySearchCriteria.sortDirection = orderBy.SlopeValue === Order_By.Asc ? 0 : 1;
  } else if (orderBy.HailRatingCode) {
    assemblySearchCriteria.sortColumn = 'HailRatingCode';
    assemblySearchCriteria.sortDirection = orderBy.HailRatingCode === Order_By.Asc ? 0 : 1;
  } else {
    assemblySearchCriteria.sortColumn = 'AssemblyNum';
    if (orderBy.AssemblyId) assemblySearchCriteria.sortDirection = orderBy.AssemblyId === Order_By.Asc ? 0 : 1;
    else assemblySearchCriteria.sortDirection = 0;
  }
  //console.log("assemblySearchCriteria is ", assemblySearchCriteria);
  return assemblySearchCriteria;
};

const getLayerAttributes = (layer: ILayer) => {
  const attributes: AttributeSearchCriteria[] = [];

  if (layer.variableAssignments) {
    const arrayVariableAssignments = layer.variableAssignments as VariableAssignment[];
    for (let index = 0; index < arrayVariableAssignments.length; index++) {
      const data = arrayVariableAssignments[index];
      const variableAssignmentLabel = layer.variableAssignmentLabels?.find((obj) => data.variableId === obj.variableId);
      if (variableAssignmentLabel) {
        const { variableId, type } = variableAssignmentLabel;
        if (
          variableId === 'ManufacturerId' ||
          variableId === 'ComponentId' ||
          variableId === 'CApprovedUse_ApprovedUseId' ||
          variableId === 'ManufacturedProduct_CategoryId' ||
          variableId === 'ManufacturedProduct_SubcategoryId' ||
          variableId === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId' ||
          variableId === 'ComponentId_view' ||
          EXCHANGE_VARIABLES.includes(`${variableId}_view`) ||
          variableId.endsWith('Operator')
        )
          continue;

        // valid attribute search criteria
        const attribute: AttributeSearchCriteria = {
          attributeName: variableId,
          attributeType: AttributeType.CodeTable,
          attributeValue: data.value?.toString(),
          comparisonOperator: ComparisonOperator.EqualTo,
        };

        if (type === 'codetable') {
          attribute.attributeType = AttributeType.CodeTable;
        }
        if (type === 'measurement') {
          attribute.attributeType = AttributeType.Measurement;
          const operator = layer.variableAssignments?.find((data) => data.variableId === `${variableId}Operator`)
            ?.value;
          switch (String(operator)) {
            case 'Equal':
              attribute.comparisonOperator = ComparisonOperator.EqualTo;
              break;
            case 'BiggerOrEqual':
              attribute.comparisonOperator = ComparisonOperator.GreaterThanOrEqual;
              break;
            case 'LessOrEqual':
              attribute.comparisonOperator = ComparisonOperator.LessThanOrEqual;
              break;
            case 'Bigger':
              attribute.comparisonOperator = ComparisonOperator.GreaterThan;
              break;
            case 'Less':
              attribute.comparisonOperator = ComparisonOperator.LessThan;
              break;
            case 'Different':
              attribute.comparisonOperator = ComparisonOperator.NotEqualTo;
              break;
            default:
              attribute.comparisonOperator = ComparisonOperator.EqualTo;
          }
        } else if (type === 'boolean') {
          attribute.attributeValue = Boolean(String(data.value)) ? '1' : '0';
          attribute.attributeType = AttributeType.Boolean;
        } else {
          //TODO
        }

        attributes.push(attribute);
      }
    }
  }

  return attributes;
};

const getSelfSecurementSearchCriteria = (layer: ILayer, layerIndex: number) => {
  const selfSecurementSearchCriteria: SelfSecurementSearchCriteria = {
    selfSecurementLayerTypeId: layer.SelfSecurementId ? Number(layer.SelfSecurementId) : 0,
    approvedUseId: 0,
    subCategoryId: 0,
    attributes: [],
    manufacturerIds: [],
    componentIds: [],
    layerNum: layerIndex + 1,
  };
  //Get approvedUseId and subCategoryId (theoretically if there is specific ApprovedUseId, subCategoryId is not necessary for search)
  if (layer.selfSecurement?.variableAssignments) {
    const arrayVariableAssignments = layer.selfSecurement?.variableAssignments as VariableAssignment[];
    selfSecurementSearchCriteria.subCategoryId = getSubCategoryId(
      arrayVariableAssignments,
      layer.selfSecurement?.section
    );
    selfSecurementSearchCriteria.approvedUseId = getApprovedUseId(
      arrayVariableAssignments,
      layer.selfSecurement?.section
    );
  }

  // for manufacturer(s) and product(s)
  const manufacturerIds: number[] = [];
  const componentIds: number[] = [];
  if (layer.selfSecurement?.productDetails) {
    const arrayManufacturerProduct = layer.selfSecurement?.productDetails as IManufacturerProduct[];
    for (let index = 0; index < arrayManufacturerProduct.length; index++) {
      if (arrayManufacturerProduct[index].productId !== '') {
        // Product
        componentIds.push(parseInt(arrayManufacturerProduct[index].productId));
      } else if (arrayManufacturerProduct[index].manufacturerId !== '') {
        // Manufacturer
        manufacturerIds.push(parseInt(arrayManufacturerProduct[index].manufacturerId));
      }
    }
  }
  selfSecurementSearchCriteria.manufacturerIds = manufacturerIds;
  selfSecurementSearchCriteria.componentIds = componentIds;

  // attributes
  selfSecurementSearchCriteria.attributes = getSelfSecurementAttributes(layer.selfSecurement as ISelfSecurement);

  return selfSecurementSearchCriteria;
};

const getSelfSecurementAttributes = (ssInfo: ISelfSecurement) => {
  const attributes: AttributeSearchCriteria[] = [];

  if (ssInfo.variableAssignments) {
    const arrayVariableAssignments = ssInfo.variableAssignments as VariableAssignment[];
    for (let index = 0; index < arrayVariableAssignments.length; index++) {
      const data = arrayVariableAssignments[index];
      const variableAssignmentLabel = ssInfo.variableAssignmentLabels?.find(
        (obj) => data.variableId === obj.variableId
      );
      if (variableAssignmentLabel) {
        const { variableId, type } = variableAssignmentLabel;
        if (
          variableId === 'ManufacturerId' ||
          variableId === 'ComponentId' ||
          variableId === 'CApprovedUse_ApprovedUseId' ||
          variableId === 'ManufacturedProduct_CategoryId' ||
          variableId === 'ManufacturedProduct_SubcategoryId' ||
          variableId === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId' ||
          variableId === 'ComponentId_view' ||
          EXCHANGE_VARIABLES.includes(`${variableId}_view`) ||
          variableId.endsWith('Operator')
        )
          continue;

        // valid attribute search criteria
        const attribute: AttributeSearchCriteria = {
          attributeName: variableId,
          attributeType: AttributeType.CodeTable,
          attributeValue: data.value?.toString(),
          comparisonOperator: ComparisonOperator.EqualTo,
        };

        if (type === 'codetable') {
          attribute.attributeType = AttributeType.CodeTable;
        }
        if (type === 'measurement') {
          attribute.attributeType = AttributeType.Measurement;
          const operator = ssInfo.variableAssignments?.find((data) => data.variableId === `${variableId}Operator`)
            ?.value;
          switch (String(operator)) {
            case 'Equal':
              attribute.comparisonOperator = ComparisonOperator.EqualTo;
              break;
            case 'BiggerOrEqual':
              attribute.comparisonOperator = ComparisonOperator.GreaterThanOrEqual;
              break;
            case 'LessOrEqual':
              attribute.comparisonOperator = ComparisonOperator.LessThanOrEqual;
              break;
            case 'Bigger':
              attribute.comparisonOperator = ComparisonOperator.GreaterThan;
              break;
            case 'Less':
              attribute.comparisonOperator = ComparisonOperator.LessThan;
              break;
            case 'Different':
              attribute.comparisonOperator = ComparisonOperator.NotEqualTo;
              break;
            default:
              attribute.comparisonOperator = ComparisonOperator.EqualTo;
          }
        } else if (type === 'boolean') {
          attribute.attributeValue = Boolean(String(data.value)) ? '1' : '0';
          attribute.attributeType = AttributeType.Boolean;
        } else {
          //TODO
        }

        attributes.push(attribute);
      }
    }
  }

  return attributes;
};
