import React, { useContext, useEffect, useState } from 'react';
import './ProductSearchResults.scss';
import { Typography } from '@mui/material';
import { useProductApiSearchQuery } from '../../services/ProductSearchService';
import { ConfigitProductContext } from '../ConfigitProductProvider';
import theme from '../../theme';
import { useNavigate } from 'react-router';
import { ApplicationContext } from '../ApplicationProvider';
import { ErrorModalComponent } from '../shared/ErrorModalComponent/ErrorModalComponent';
import { Dialog } from '../shared/DialogComponent/Dialog';

export interface IProductSearchResults {
  ApprovedUseId: number;
  ApprovedUseCode: string;
  SubcategoryId: number;
  SubcategoryName: string;
  CategoryId: number;
  CategoryName: string;
  NumOfProducts: number;
  _typename?: string;
}
export interface IProductSearchTopLevel {
  CategoryId: number;
  CategoryName: string;
  children: IProductSearchResults[];
  count: number;
}
export interface IProductSearchSubLevel {
  SubcategoryId: number;
  SubcategoryName: string;
  children: IProductSearchResults[];
  count: number;
}

export const ProductSearchResults = () => {
  const searchData: IProductSearchTopLevel[] = [];
  const [mappedData, setMappedData] = useState(searchData);
  const { variableAssignments, productCount, setProductCount } = useContext(ConfigitProductContext);
  const { setDialog, setShowDialog } = useContext(ApplicationContext);
  const { attributes, setApuCount, setInitialSearchCriteria } =
    useContext(ConfigitProductContext);
  const navigate = useNavigate();

  const getSearchData = (id: string) => {
    // checks to ensure we are passing correct type or undefined- data from VariableAssignments is sent as a string
    // but a number is needed for our hasura call
    if (id === 'manufacturer') {
      const manufacturerId = Number(
        variableAssignments.find((data) => data.variableId === 'Manufacturer_ManufacturerId')?.value
      );
      return isNaN(manufacturerId) ? undefined : manufacturerId;
    }
    if (id === 'category') {
      const categoryId = Number(
        variableAssignments.find((data) => data.variableId === 'ManufacturedProduct_CategoryId')?.value
      );
      return isNaN(categoryId) ? undefined : categoryId;
    }
    if (id === 'product') {
      const productId = Number(
        variableAssignments.find((data) => data.variableId === 'ManufacturedProduct_ComponentId')?.value
      );
      return isNaN(productId) ? undefined : productId;
    }
    if (id === 'approvedUse') {
      const approvedUseId = Number(
        variableAssignments.find((data) => data.variableId === 'CApprovedUse_ApprovedUseId')?.value
      );
      return isNaN(approvedUseId) ? undefined : approvedUseId;
    }
    if (id === 'countryCode') {
      const countryId = Number(
        variableAssignments.find((data) => data.variableId === 'ManufacturedProduct_CountryId')?.value
      );
      return isNaN(countryId) ? undefined : countryId;
    }

    return undefined;
  };
  const getAttributes = () => {

    return attributes.length > 0 ? attributes : undefined;
  };
  const { data, loading, error } = useProductApiSearchQuery(
    getSearchData('manufacturer'),
    getSearchData('product'),
    getSearchData('category'),
    undefined,
    getSearchData('approvedUse'),
    getAttributes(),
    getSearchData('countryCode')
  );
  useEffect(() => {
    if (error) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [error]);
  useEffect(() => {
    const mapProductData = () => {
      const dataToMap: IProductSearchResults[] = data ? data.product_api_search : [];
      // group data at top level and preserve children
      if (dataToMap) {
        let productCountSum = 0;
        dataToMap.forEach((datum: IProductSearchResults) => {
          if (searchData.find((data) => data.CategoryId === datum.CategoryId)) {
            const dataToUpdate = searchData.filter((data) => data.CategoryId === datum.CategoryId);
            dataToUpdate[0].count = dataToUpdate[0].count + datum.NumOfProducts;
            dataToUpdate[0].children.push(datum);
            productCountSum = productCountSum + datum.NumOfProducts;
          } else {
            searchData.push({
              CategoryId: datum.CategoryId,
              CategoryName: datum.CategoryName,
              children: [datum],
              count: datum.NumOfProducts,
            });
            productCountSum = productCountSum + datum.NumOfProducts;
          }
        });
        setProductCount(productCountSum);
        setMappedData(searchData);
      }
    };
    mapProductData();
  }, [JSON.stringify(data), JSON.stringify(attributes), JSON.stringify(variableAssignments)]);

  // currently we are not mapping subcategories on their own rows, this function will be useful when/if that
  // functionality is implemented
  const mapSubcategoryData = (selectedChildren: IProductSearchResults[]) => {
    const tempArray: IProductSearchSubLevel[] = [];
    selectedChildren.forEach((selectedChild: IProductSearchResults) => {
      if (tempArray.find((data) => data.SubcategoryId === selectedChild.SubcategoryId)) {
        const dataToUpdate = tempArray.filter((data) => data.SubcategoryId === selectedChild.SubcategoryId);
        dataToUpdate[0].children.push(selectedChild);
        dataToUpdate[0].count = dataToUpdate[0].count + selectedChild.NumOfProducts;
      } else {
        tempArray.push({
          SubcategoryId: selectedChild.SubcategoryId,
          SubcategoryName: selectedChild.SubcategoryName,
          children: [selectedChild],
          count: selectedChild.NumOfProducts,
        });
      }
    });
    return tempArray;
  };
  const setListData = (
    selectedCount: number,
    selectedCategoryId: number,
    selectedSubCategoryId?: number,
    approvedUsedId?: number
  ) => {

    if (approvedUsedId) {
      approvedUsedId = approvedUsedId;
    } else {
      approvedUsedId = getSearchData('approvedUse');
    }
    setApuCount(selectedCount);
    setInitialSearchCriteria({
      manufacturerId: getSearchData('manufacturer'),
      componentId: getSearchData('product'),
      categoryId: selectedCategoryId,
      subCategoryId: selectedSubCategoryId,
      approvedUseId: approvedUsedId,
      attributes: attributes,
      countryId: getSearchData('countryCode'),
    });
    navigate('/productList');
  };
  return (
    <div className="product-results px-4 py-2" id="roofnav_results">
      {!loading && mappedData ? (
        <div>
          {!error ? (
            <div>
              <div className="py-3">
                <Typography color={theme.palette.rnOrange.darkOrange} className={'headline-1'}>
                  {productCount} Matching Products
                </Typography>
              </div>
              <div>
                {mappedData.map((result: IProductSearchTopLevel, index: number) => {
                  // for each, map results- then, map children. Pills are using anchor tags for now
                  return (
                    <div key={'category' + index} className="my-2">
                      <div className="row">
                        <Typography className="small-para-bold product-count">{result.count}&nbsp;</Typography>
                        <a className="product-pill" onClick={() => setListData(result.count, result.CategoryId)}>
                          <Typography className="small-para">{result.CategoryName}&nbsp;</Typography>
                        </a>
                        &nbsp;
                        <b className="chevron">{`>`}</b>
                      </div>
                      <div className="sub-row p-1">
                        {result.children.map((child: IProductSearchResults, childIndex: number) => {
                          return (
                            <span key={'categoryChild' + childIndex} className="py-2">
                              {/* <a className="product-pill my-1" href={'#'}> */}
                              <a
                                className="product-pill"
                                onClick={() =>
                                  setListData(
                                    child.NumOfProducts,
                                    child.CategoryId,
                                    child.SubcategoryId,
                                    child.ApprovedUseId
                                  )
                                }
                              >
                                <div className="row m-0 p-0">
                                  <Typography className="small-para">{child.ApprovedUseCode}&nbsp;</Typography>

                                  <Typography className="small-para-bold">({child.NumOfProducts})</Typography>
                                </div>
                              </a>
                              {/* </a> */}
                              {childIndex + 1 !== result.children.length && <b className="px-1">&nbsp;/&nbsp;</b>}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <>
              <div>An error occured or data is unavailable, please try again</div>
            </>
          )}
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};
