import theme from '../../../theme';
import './TextOnlyComponent.scss';
import React, { HTMLAttributes } from 'react';

interface ITextOnlyComponentProps extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  label?: string;
  iconStyle?: string;
  iconName?: string;
  buttonText?: string;
  testId?: string;
  buttonStyle?: string;
  isDisabled?: boolean;
  click?: any;
  iconColor?: string;
  typeAttribute?: 'button' | 'submit' | 'reset' | undefined;
}
// Note: this is a significant amount of props- discussed w/team, decision was to still use this approach as making options a generic type requires
// setting type definition per button, as opposed to passing props per button- easier to do 2nd dynamically
export const TextOnlyComponent = ({
  id,
  label,
  iconStyle,
  iconName,
  buttonText,
  testId,
  buttonStyle,
  isDisabled,
  click,
  iconColor,
  typeAttribute,
}: ITextOnlyComponentProps) => {
  return (
    <span className="rntextonlycomponent my-1">
      <button
        className={'btn textonly-btn d-inline-flex ' + (buttonStyle ? buttonStyle : '')}
        data-testid={testId}
        type={typeAttribute}
        aria-label={label}
        id={id}
        onClick={click}
        disabled={isDisabled}
      >
        <div className='d-inline-flex icon-wrap'>
          {iconName != undefined ? (
            <span
              className="material-symbols-outlined textonly-icon mr-1"
              style={{ color: iconColor ? iconColor : '#2a6bc6' }}>{iconName}</span>
          ) : null}
          <div className='text'>
            {buttonText}
          </div>
        </div>
      </button>
    </span>
  );
};
