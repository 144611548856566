import { FormControlLabel, styled, Switch, Typography } from '@mui/material';
import React, { HTMLAttributes } from 'react';
import theme from '../../../theme';


interface ISwitchComponentProps extends HTMLAttributes<HTMLDivElement> {
    id: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
    label?: string;
}

const StyledSwitch = styled(Switch)({
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: theme.palette.rnOrange.darkOrange,
            '& + .MuiSwitch-track': {
                opacity: 0.75,
                backgroundColor: theme.palette.rnOrange.darkOrange,
            },
        }
    }
})

export const SwitchComponent = ({ id, handleChange, checked, label }: ISwitchComponentProps) => {

    return (
        <FormControlLabel
            control={
                <StyledSwitch
                    disableRipple
                    disableFocusRipple
                    id={id}
                    checked={checked}
                    onChange={handleChange}
                />
            }
            label={<Typography className={'small-para'}>{label}</Typography>}
        />
    )

}