import React from 'react';
import { useGetStructureDetailQuery } from '../../models/GQLGeneratedModels';
import { Strength } from '../../utils/Measurement/Strength';
import { Length } from '../../utils/Measurement/Length';

export const StructureDetail = (props: any) => {
  const { data, loading, error } = useGetStructureDetailQuery({
    variables: {
      LayerId: parseInt(props.layerId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const layer = data?.StructureLayer[0];
  const isMetric = layer?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Structure</h4>
      <table>
        <tr>
          <td>Structure Type:</td>
          <td>{layer?.C_StructureType.StructureTypeCode}</td>
        </tr>
        <tr>
          <td>Max Spacing:</td>
          <td>{Length.display(layer?.SpacingMax, isMetric)}</td>
        </tr>
        <tr>
          <td>Thickness:</td>
          <td>{Length.display(layer?.Thickness, isMetric)}</td>
        </tr>
        <tr>
          <td>Min Strength:</td>
          <td>{Strength.display(layer?.StrengthMin, isMetric)}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{layer?.Layer.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
