import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  configureLayer as configureLayerAPI,
  getCompatibleExtendedLayerConfigs,
  getVariable,
  useConfigureLayer,
  useGetInitialLayerState,
} from '../../services/ConfigitService';
import { AllowedValues, ConfigurationVariable, SingletonValue } from '../../types/configurator';
import * as LayerApi from '../../utils/LayerApi';
import { getLayerDataById } from '../../utils/LayerTypeCheckUtilities';
import { ApplicationContext } from '../ApplicationProvider';
import {
  ConfigitContext,
  IManufacturerProduct,
  ISecurementLayer,
  ISelfSecurement,
  IVariableAssigmentLabels,
} from '../ConfigitProvider';
import { LAYERMODELHASURA_MAP } from '../constants/LAYERMODELHASURA_MAP';
import { getLayerProps } from '../constants/LAYER_DATA';
import { ButtonComponent } from '../shared/ButtonComponent/ButtonComponent';
import { TextOnlyComponent } from '../shared/TextOnlyComponent/TextOnlyComponent';
import { ManufacturerProductForm } from './ManufacturerProductForm';
import { ProductAttributesForm } from './ProductAttributesForm';
import { ISecurementDetails, SecurementForm } from './SecurementForm';
import { Loading } from '../shared/Loading/Loading';
import { Checkbox, FormControlLabel } from '@mui/material';
import { DetermineSelfSecurementLayerType, StandardSecurementLayerTypeIds } from '../../utils/SecurementUtilities';
import { SELFSECUREMENT_DATA } from '../constants/SELFSECUREMENT_DATA';
import { ErrorModalComponent } from '../shared/ErrorModalComponent/ErrorModalComponent';
import { Dialog } from '../shared/DialogComponent/Dialog';

const numToString = (num?: number) => String(num).padStart(2, '0');

interface ILayerConfiguratorProps {
  layerId: string;
  layerIndex: number;
}

export const LayerConfigurator = ({ layerId, layerIndex }: ILayerConfiguratorProps) => {
  const [mode, setMode] = useState('Basic');
  // layerTypeId can change based on securement from / to selection
  const [layerTypeId, setLayerTypeId] = useState(layerId);
  const { setShowDialog, setDialog } = useContext(ApplicationContext);
  const { sections, variableAssignments, layerConfigMasterData, layers, excludedLayerIds, configureLayer } =
    useContext(ConfigitContext);
  const layer = layers[layerIndex] as ISecurementLayer;
  const layerData = getLayerDataById(layerTypeId);
  const layerProps = getLayerProps(layerData);
  let model = layerProps.model ? (layerProps.model as string) : '--NA--';
  // when layerTypeId is 45, model is Securement
  // but it should be GenericSecurement if from or to is not selected yet
  if (layerTypeId === '45' && !(layer.fromLayerTypeId && layer.toLayerTypeId)) model = 'GenericSecurement';
  const view = LAYERMODELHASURA_MAP[model];
  const iniSelfSecurementSet =
    layer.selfSecurement && layer.selfSecurement.idLayerType && layer.selfSecurement.baseLayerTypeId ? true : false;
  const [isSelfSecurementSet, setIsSelfSecurementSet] = useState(iniSelfSecurementSet);

  // Get previously allowed and compatible extended layer configs from sections, which come from the response of last configit roofnav model call
  // Extended layer configs have both "isAllowed": true and "incompatible": false
  const prevAllowedExtendedLayerConfigs = getVariable(
    sections,
    'ExtendedLayerConfigSplit_ExtendedLayerConfigId'
  )?.values?.filter((data) => data.isAllowed && !data.incompatible);

  const initialState = useGetInitialLayerState(
    variableAssignments,
    layerConfigMasterData ?? [],
    excludedLayerIds,
    layers,
    layerIndex,
    model,
    prevAllowedExtendedLayerConfigs
  );
  const [layerDetails, setLayerDetails] = useState(initialState.data);
  const [iniLayerDetails, setIniLayerDetails] = useState(initialState.data);
  const settingLayerDetailsRef = useRef(false);
  const [productDetails, setProductDetails] = useState<IManufacturerProduct[]>(
    layer?.productDetails
      ? layer?.productDetails
      : [
        {
          productId: '',
          manufacturerId: '',
          uiselectmode: '',
        },
      ]
  );
  // const [resetProductDetails, setResetProductDetails] = useState<IManufacturerProduct[]>(
  //   layer?.productDetails
  //     ? layer?.productDetails
  //     : [
  //         {
  //           productId: '',
  //           manufacturerId: '',
  //           uiselectmode: '',
  //         },
  //       ]
  // );
  const [securementDetails, setSecurementDetails] = useState<ISecurementDetails>({
    fromLayerTypeId: layer.fromLayerTypeId,
    fromLayerNum: layer.fromLayerNum,
    toLayerTypeId: layer.toLayerTypeId,
    toLayerNum: layer.toLayerNum,
  });
  const settingSecurementDetailsRef = useRef(false);
  const [variableAssignmentLabels, setVariableAssignmentLabels] = useState<IVariableAssigmentLabels[]>(
    layer.variableAssignmentLabels ?? []
  );
  const [iniVariableAssignmentLabels, setIniVariableAssignmentLabels] = useState<IVariableAssigmentLabels[]>(
    layer.variableAssignmentLabels ?? []
  );
  if (initialState.data && !layerDetails) {
    setLayerDetails(initialState.data);
    setIniLayerDetails(initialState.data);
  }

  const { data, loading, error } = useConfigureLayer(layerDetails, model);
  useEffect(() => {
    if (error) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [error])
  const securementTypeSwitch = () => {
    if (data && layer.isSecurementLayer) {
      const allExtendedLayerConfigs = getCompatibleExtendedLayerConfigs(sections, layerConfigMasterData ?? []);
      let matchingExtendedLayerConfigs = LayerApi.getMatchingLayerConfigs(
        layers.map((data, index) =>
          index === layerIndex
            ? ({
              ...data,
              id: layerTypeId,
              fromLayerNum: securementDetails.fromLayerNum,
              fromLayerTypeId: securementDetails.fromLayerTypeId,
              toLayerNum: securementDetails.toLayerNum,
              toLayerTypeId: securementDetails.toLayerTypeId,
            } as ISecurementLayer)
            : data
        ),
        excludedLayerIds,
        allExtendedLayerConfigs,
        true,
        false
      );
      const allowedLayerConfigIds = data?.variables
        ?.find((data) => data.id === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId')
        ?.values?.filter((data) => !data.incompatible)
        .map((data: SingletonValue) => String(data.value));
      matchingExtendedLayerConfigs = matchingExtendedLayerConfigs.filter(
        (data) => allowedLayerConfigIds && allowedLayerConfigIds.includes(String(data.ExtendedLayerConfigId))
      );
      const id = LayerApi.getSpecificSecurementType(
        layers.map((data, index) =>
          index === layerIndex
            ? ({
              ...data,
              id: layerTypeId,
              fromLayerNum: securementDetails.fromLayerNum,
              fromLayerTypeId: securementDetails.fromLayerTypeId,
              toLayerNum: securementDetails.toLayerNum,
              toLayerTypeId: securementDetails.toLayerTypeId,
            } as ISecurementLayer)
            : data
        ),
        matchingExtendedLayerConfigs,
        layerIndex
      );
      if (id !== layerTypeId) setLayerTypeId(id);
      if (settingSecurementDetailsRef.current) {
        settingSecurementDetailsRef.current = false;
        const oldAllowedIds = layerDetails
          ?.find((data) => data.variableId === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId')
          ?.allowedValues?.map((data) => String(data.value));
        const newAllowedIds = matchingExtendedLayerConfigs.map((data) => String(data.ExtendedLayerConfigId));
        if (JSON.stringify(oldAllowedIds) !== JSON.stringify(newAllowedIds)) {
          settingLayerDetailsRef.current = true;
          handleFormSelect('ExtendedLayerConfigSplit_ExtendedLayerConfigId', newAllowedIds);
        }
      }
    }
  };
  useEffect(() => {
    settingSecurementDetailsRef.current = true;
    // switch securement type when from / to is changed
    securementTypeSwitch();
  }, [JSON.stringify(securementDetails)]);
  useEffect(() => {
    if (settingLayerDetailsRef.current) settingLayerDetailsRef.current = false;
    else securementTypeSwitch();
  }, [JSON.stringify(layerDetails)]);
  const handleFormSelect = (variableId: string, value: string | string[]) => {
    const temp = layerDetails?.filter((data) => data.variableId !== variableId);
    const temp2 = variableAssignmentLabels.filter((data) => data.variableId !== variableId);
    const variable = data?.variables?.find((data) => data.id === variableId);
    const type = variable?.properties && variable?.properties[0] && variable.properties[0]?.value;
    const valueObj = variable?.values?.find((data: SingletonValue) => data.value === value) as SingletonValue;
    if (typeof value === 'string') {
      temp?.push({ variableId, value });
      if (variable?.name && valueObj.name) {
        temp2.push({ variableId, variableLabel: variable.name, valueLabel: valueObj.name, type });
        setVariableAssignmentLabels(temp2);
      }
    } else {
      temp?.push({
        variableId,
        type: 'AllowedValues',
        allowedValues: value.map((val) => {
          return { type: 'SingletonValue', value: String(val) };
        }),
      });
    }
    setLayerDetails(temp);
  };
  const handleFormUnSelect = (variableId: string) => {
    setLayerDetails(layerDetails?.filter((data) => data.variableId !== variableId));
    setVariableAssignmentLabels(variableAssignmentLabels.filter((data) => data.variableId !== variableId));
  };
  const handleMeasureAttrOperatorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //console.log(event.target.value); // ex. ">="
    //console.log(event.target.id); // ex. "WidthOperator"
    //TODO
  };

  // const handleMeasureAttrUnitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   //console.log(event.target.value); // ex. "mm"
  //   //console.log(event.target.id); // ex. "WidthUnit"
  //   //TODO: May not need this any more. Since we may not need unit select for each measurement attribute, Instead, layer level or session level unit toggler will be used.
  // };
  const handleUpdateSearchFilter = async () => {
    if (layer) {
      const temp = { ...layer };
      temp.id = layerTypeId;
      temp.autoSelected = false;
      temp.model = model;
      temp.variableAssignments = layerDetails;
      temp.section = data;
      temp.productDetails = productDetails;
      temp.fromLayerTypeId = securementDetails.fromLayerTypeId;
      temp.fromLayerNum = securementDetails.fromLayerNum;
      temp.toLayerTypeId = securementDetails.toLayerTypeId;
      temp.toLayerNum = securementDetails.toLayerNum;
      temp.variableAssignmentLabels = variableAssignmentLabels;
      // self-securement
      temp.hasSelfSecurement = isSelfSecurementSet;
      temp.SelfSecurementId = isSelfSecurementSet
        ? DetermineSelfSecurementLayerType(Number(layerTypeId)).toString()
        : '';
      if (isSelfSecurementSet) {
        if (layer.selfSecurement && Object.keys(layer.selfSecurement).length > 0) {
          const originalSSInfo = { ...layer.selfSecurement } as ISelfSecurement;
          temp.selfSecurement = originalSSInfo;
        } else {
          const selfSecurementInfo: ISelfSecurement = {
            idLayerType: DetermineSelfSecurementLayerType(Number(layerTypeId)).toString(),
            autoSelected: false,
            baseLayerTypeId: layerTypeId,
            model: SELFSECUREMENT_DATA.find((item) => item.baseLayerTypeId === layerTypeId)?.model,
          };
          temp.selfSecurement = selfSecurementInfo;
        }
      } else {
        temp.selfSecurement = undefined;
      }

      configureLayer(temp, layerIndex);
      setShowDialog(false);
    }
  };
  const handleCancel = () => setShowDialog(false);
  const handleResetLayerDetails = () => {
    // setResetProductDetails(productDetails);
    // setProductDetails([
    //   {
    //     productId: '',
    //     manufacturerId: '',
    //     uiselectmode: '',
    //   },
    // ]);
    //setLayerDetails([]);
    setLayerDetails(iniLayerDetails);
    setVariableAssignmentLabels(iniVariableAssignmentLabels);

    //resetSearchResutls();
  };
  const handleAddProductDetail = () => {
    const emptyRow = productDetails.find((data) => data.manufacturerId === '' && data.productId === '');
    if (!emptyRow) {
      const data = [...productDetails];
      data.push({
        productId: '',
        manufacturerId: '',
        uiselectmode: '',
      });
      setProductDetails(data);
    }
  };
  // const resetSearchResutls = async () => {
  //   if (layer) {
  //     const temp = { ...layer };
  //     temp.id = layerTypeId;
  //     temp.autoSelected = false;
  //     temp.model = model;
  //     temp.variableAssignments = layerDetails;
  //     temp.section = data;
  //     temp.productDetails = [];
  //     temp.fromLayerTypeId = securementDetails.fromLayerTypeId;
  //     temp.fromLayerNum = securementDetails.fromLayerNum;
  //     temp.toLayerTypeId = securementDetails.toLayerTypeId;
  //     temp.toLayerNum = securementDetails.toLayerNum;
  //     temp.variableAssignmentLabels = variableAssignmentLabels;
  //     // self-securement
  //     temp.hasSelfSecurement = isSelfSecurementSet;
  //     temp.SelfSecurementId = isSelfSecurementSet
  //       ? DetermineSelfSecurementLayerType(Number(layerTypeId)).toString()
  //       : '';
  //     if (isSelfSecurementSet) {
  //       if (layer.selfSecurement && Object.keys(layer.selfSecurement).length > 0) {
  //         const originalSSInfo = { ...layer.selfSecurement } as ISelfSecurement;
  //         temp.selfSecurement = originalSSInfo;
  //       } else {
  //         const selfSecurementInfo: ISelfSecurement = {
  //           idLayerType: DetermineSelfSecurementLayerType(Number(layerTypeId)).toString(),
  //           autoSelected: false,
  //           baseLayerTypeId: layerTypeId,
  //           model: SELFSECUREMENT_DATA.find((item) => item.baseLayerTypeId === layerTypeId)?.model,
  //         };
  //         temp.selfSecurement = selfSecurementInfo;
  //       }
  //     } else {
  //       temp.selfSecurement = undefined;
  //     }

  //     configureLayer(temp, layerIndex);
  //     setShowDialog(true);
  //   }
  // };
  const handleUpdateProductDetail = async (index: number, productDetail: IManufacturerProduct) => {
    if (productDetail.manufacturerId === '' && productDetail.productId === '' && productDetails.length > 1) {
      handleRemoveProductDetail(index, productDetail.productId, productDetail.manufacturerId);
      return;
    }

    const data = productDetails.map((obj: IManufacturerProduct, pos: number) => {
      if (index === pos) return productDetail;
      return obj;
    });
    setProductDetails(data);
    setLayerDetails(await getUpdatedLayerDetails(data));
  };
  const handleRemoveProductDetail = async (index: number, deletedProductId: string, deletedManufacturerId: string) => {
    const data = [...productDetails];
    data.splice(index, 1);
    setProductDetails(data);
    setLayerDetails(await getUpdatedLayerDetails(data));

    if (deletedManufacturerId !== '') {
      localStorage.setItem('deletedProduct', deletedProductId);
    }

    localStorage.setItem('deletedManufacturer', deletedManufacturerId);
  };
  const getUpdatedLayerDetails = async (productDetails: IManufacturerProduct[], newModel?: string) => {
    const data = layerDetails?.filter(
      (obj) => obj.variableId !== 'ManufacturerId' && obj.variableId !== 'ComponentId_view'
    );
    let products: AllowedValues[] = [];
    for (let i = 0; i < productDetails.length; i++) {
      const productDetail = productDetails[i];
      if (productDetail.productId !== '') products.push({ type: 'SingletonValue', value: productDetail.productId });
      else if (productDetail.manufacturerId !== '') {
        const section = await configureLayerAPI(
          [
            ...(layerDetails?.filter(
              (data) =>
                data.variableId !== 'ManufacturerId' &&
                data.variableId !== 'ComponentId_view' &&
                !(newModel && data.variableId == 'ComponentId')
            ) ?? []),
            {
              variableId: 'ManufacturerId',
              value: productDetail.manufacturerId,
            },
          ],
          newModel ?? model
        );
        const allowedProducts = section.variables
          ?.find((data) => data.id === 'ComponentId_view')
          ?.values?.filter((data) => !data.incompatible)
          ?.map((data: SingletonValue) => {
            return { type: 'SingletonValue', value: data.value } as AllowedValues;
          });
        if (allowedProducts) products = products.concat(allowedProducts);
      }
    }
    if (products.length) {
      data?.push({
        variableId: 'ComponentId_view',
        type: 'AllowedValues',
        allowedValues: products,
      });
    }
    return data;
  };
  if (!layer?.isSecurementLayer && (model === '--NA--' || !view)) {
    return (
      <>
        <h4 className="mt-3 mb-2">{layerData?.name} Details</h4>
        Not implemented yet
      </>
    );
  }
  const securementVariables = data?.variables
    ?.filter(
      (obj) =>
        obj.id === 'CApprovedUse_ApprovedUseId' ||
        obj.id === 'ManufacturedProduct_CategoryId' ||
        obj.id === 'ManufacturedProduct_SubcategoryId'
    )
    .sort(compare);

  function compare(a: ConfigurationVariable, b: ConfigurationVariable) {
    let sequenceId1: number;
    let sequenceId2: number;

    switch (a.id) {
      case 'CApprovedUse_ApprovedUseId':
        sequenceId1 = 3;
        break;
      case 'ManufacturedProduct_CategoryId':
        sequenceId1 = 1;
        break;
      case 'ManufacturedProduct_SubcategoryId':
        sequenceId1 = 2;
        break;
      default:
        sequenceId1 = 100;
    }

    switch (b.id) {
      case 'CApprovedUse_ApprovedUseId':
        sequenceId2 = 3;
        break;
      case 'ManufacturedProduct_CategoryId':
        sequenceId2 = 1;
        break;
      case 'ManufacturedProduct_SubcategoryId':
        sequenceId2 = 2;
        break;
      default:
        sequenceId2 = 100;
    }

    return sequenceId1 - sequenceId2;
  }

  function checkIfLayerHasMultipleApprovedUses(arrayCategoryApprovedUse: ConfigurationVariable[] | undefined) {
    if (arrayCategoryApprovedUse == undefined) return false;
    const approvedUse = arrayCategoryApprovedUse.filter((obj) => obj.id === 'CApprovedUse_ApprovedUseId');
    if (approvedUse.length > 0) {
      return (approvedUse[0].values as ConfigurationVariable[]).length > 1 ? true : false;
    }
    return false;
  }

  function checkIfSelfSecurementAvailable() {
    return false; // return false to hide self-securement checkbox

    // // Regular securement layer can not have self-securement
    // if (StandardSecurementLayerTypeIds.includes(layerTypeId)) return false;

    // if (layer.selfSecurement && layer.selfSecurement.idLayerType && layer.selfSecurement.baseLayerTypeId) {
    //   // self-securement has been set for the current layer.
    //   return true;
    // }

    // //TODO May need to consider visible/invisible and enabled/disabled status of Self-Securement checkbox
    // //TODO call checkIfLayerCanContainSelfSecurement for enabled/disabled status
    // //TODO need to consider interaction between Self-Securement checkbox status and layer details (manufacturers/products, approved use, attributes) change
    // //If self-securement has been set before, need to consider restrictions of other layer details
    // //TODO Need to add self-securement related code into "OK" button handler handleUpdateSearchFilter
    // return true;
  }

  function checkIfSpecificationLayer(layerTypeId: string): boolean {
    return layerTypeId === '25' || layerTypeId === '35' ? true : false;
  }

  const handleSelfSecurementChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsSelfSecurementSet(checked);
    //setIsSelfSecurementSet(event.target.checked);
  };

  const unAssigned = !!securementVariables?.find((variable) => !variable.values?.find((value) => !!value.assigned));
  const loadingState = (
    <div className="d-flex justify-content-center align-items-center">
      <Loading />
    </div>
  );

  return (
    <>
      {checkIfSelfSecurementAvailable() && (
        <FormControlLabel
          control={<Checkbox checked={isSelfSecurementSet} onChange={handleSelfSecurementChange} />}
          label={'Self-Securement'}
        />
      )}
      <h4 className="mt-3 mb-2">{layerData?.name} Details</h4>
      {(initialState.loading || loading) && loadingState}
      {layerDetails && initialState.data && !loading && data && data.variables && (
        <>
          {!checkIfSpecificationLayer(layer.id) && (
            <ManufacturerProductForm
              layerDetails={layerDetails}
              model={model}
              productDetails={productDetails}
              handleAddProductDetail={handleAddProductDetail}
              handleUpdateProductDetail={handleUpdateProductDetail}
              handleRemoveProductDetail={handleRemoveProductDetail}
            />
          )}
          {(layer?.isSecurementLayer || checkIfLayerHasMultipleApprovedUses(securementVariables)) &&
            securementVariables && (
              <SecurementForm
                layerId={layerTypeId}
                layerIndex={layerIndex}
                layers={layers}
                variables={data.variables}
                securementVariables={securementVariables}
                handleFormSelect={handleFormSelect}
                handleFormUnSelect={handleFormUnSelect}
                securementDetails={securementDetails}
                setSecurementDetails={setSecurementDetails}
                showFromTo={!!layer?.isSecurementLayer}
              />
            )}
          {((!layer?.isSecurementLayer && !checkIfLayerHasMultipleApprovedUses(securementVariables)) ||
            !unAssigned) && (
              <ProductAttributesForm
                mode={mode}
                setMode={setMode}
                layerTypeId={layerData?.value}
                name={layerData?.name}
                layerProps={layerProps}
                variables={data.variables}
                handleFormSelect={handleFormSelect}
                handleFormUnSelect={handleFormUnSelect}
                handleMeasureAttrOperatorChange={handleMeasureAttrOperatorChange}
              />
            )}
          <div style={{ height: '97px' }}></div>
        </>
      )}

      <div className="form-group px-3 mb-3 row mx-0">
        <div>
          <ButtonComponent
            click={handleUpdateSearchFilter}
            data-mdc-dialog-action="close"
            buttonStyle="py-2 px-3"
            buttonType={'primary'}
            buttonText={'Ok'}
          />
        </div>
        <div className={'px-2'}>
          <ButtonComponent
            click={handleCancel}
            data-mdc-dialog-action="close"
            buttonStyle="py-2 px-3 ml-2"
            buttonType={'secondary'}
            buttonText={'Cancel'}
          />
        </div>
      </div>
    </>
  );
};
