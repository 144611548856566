import React from 'react';
import { ApprovedUse } from '../constants/ENUMS';
import { AdhesiveFullApplicationUsageDetail } from './AdhesiveFullApplicationUsageDetail';
import { AdhesiveRibbonsUsageDetail } from './AdhesiveRibbonsUsageDetail';
import { AdhesiveSpotsUsageDetail } from './AdhesiveSpotsUsageDetail';
import { AirRetarderUsageDetail } from './AirRetarderUsageDetail';
import { BaseCoatUsageDetail } from './BaseCoatUsageDetail';
import { BasePlyUsageDetail } from './BasePlyUsageDetail';
import { BaseSheetUsageDetail } from './BaseSheetUsageDetail';
import { BattBlanketInsulationUsageDetail } from './BattBlanketInsulationUsageDetail';
import { BattenBarSystemUsageDetail } from './BattenBarSystemUsageDetail';
import { BoardStockUsageDetail } from './BoardStockUsageDetail';
import { CapPlyUsageDetail } from './CapPlyUsageDetail';
import { CementitiousWoodFiberDeckUsageDetail } from './CementitiousWoodFiberDeckUsageDetail';
import { ClipSystemUsageDetail } from './ClipSystemUsageDetail';
import { CoatingSystemUsageDetail } from './CoatingSystemUsageDetail';
import { CoatingUsageDetail } from './CoatingUsageDetail';
import { CoatUsageDetail } from './CoatUsageDetail';
import { CompositePanelCoverUsageDetail } from './CompositePanelCoverUsageDetail';
import { CoverBoardUsageDetail } from './CoverBoardUsageDetail';
import { CoverStripDiskUsageDetail } from './CoverStripDiskUsageDetail';
import { FastenerUsageDetail } from './FastenerUsageDetail';
import { FasteningSystemStressPlateUsageDetail } from './FasteningSystemStressPlateUsageDetail';
import { FormDeckUsageDetail } from './FormDeckUsageDetail';
import { LightweightInsulatingConcreteUsageDetail } from './LightweightInsulatingConcreteUsageDetail';
import { LinerPanelUsageDetail } from './LinerPanelUsageDetail';
import { OtherPlyUsageDetail } from './OtherPlyUsageDetail';
import { PreassembledFasteningUsageDetail } from './PreassembledFasteningUsageDetail';
import { SeamTapeSystemUsageDetail } from './SeamTapeSystemUsageDetail';
import { SeparatorSheetUsageDetail } from './SeparatorSheetUsageDetail';
import { SinglePlyCoverUsageDetail } from './SinglePlyCoverUsageDetail';
import { SprayedFoamInsulationUsageDetail } from './SprayedFoamInsulationUsageDetail';
import { StandingLapSeamCoverUsageDetail } from './StandingLapSeamCoverUsageDetail';
import { SteelDeckUsageDetail } from './SteelDeckUsageDetail';
import { SubstratePrimerUsageDetail } from './SubstratePrimerUsageDetail';
import { ThermalBarrierUsageDetail } from './ThermalBarrierUsageDetail';
import { TopCoatUsageDetail } from './TopCoatUsageDetail';
import { VaporRetarderSystemUsageDetail } from './VaporRetarderSystemUsageDetail';
import { VaporRetarderUsageDetail } from './VaporRetarderUsageDetail';
import { WeldArcUsageDetail } from './WeldArcUsageDetail';
import { WeldHeatUsageDetail } from './WeldHeatUsageDetail';
import { WeldSolventUsageDetail } from './WeldSolventUsageDetail';
import { WeldTorchedUsageDetail } from './WeldTorchedUsageDetail';
import './UsageDetails.scss'
import { SurfacingUsageDetail } from './SurfacingUsageDetail';
import { AdhesiveSystemUsageDetail } from './AdhesiveSystemUsageDetail';


interface IUsageDetailProps {
  usageId: number;
  approvedUseId: number;
}

export const UsageDetails = (props: IUsageDetailProps) => {
  const { usageId, approvedUseId } = props;
  return (
    <div>
      {/* <div>ApprovedUse.tit</div> */}
      <div className="notvisible">{usageId}</div>
      <div className="notvisible">{approvedUseId}</div>
      {approvedUseId === ApprovedUse.AdhesiveFullApplication && (
        <AdhesiveFullApplicationUsageDetail usageId={usageId} />
      )}

      {approvedUseId === ApprovedUse.BaseSheet && <BaseSheetUsageDetail usageId={usageId} />}

      {approvedUseId === ApprovedUse.Coating && <CoatingUsageDetail usageId={usageId} />}

      {approvedUseId === ApprovedUse.Surfacing && <SurfacingUsageDetail usageId={usageId} />}

      {approvedUseId === ApprovedUse.CoverMultiplyPly && <OtherPlyUsageDetail usageId={usageId} />}

      {approvedUseId === ApprovedUse.FasteningPreassembledFastening && (
        <PreassembledFasteningUsageDetail usageId={usageId} />
      )}

      {approvedUseId === ApprovedUse.FasteningSystemStressPlate && (
        <FasteningSystemStressPlateUsageDetail usageId={usageId} />
      )}

      {approvedUseId === ApprovedUse.CoverSingleply && <SinglePlyCoverUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.InsulationBoardStock && <BoardStockUsageDetail usageId={usageId} />}

      {approvedUseId === ApprovedUse.LightweightInsulatingConcrete && (
        <LightweightInsulatingConcreteUsageDetail usageId={usageId} />
      )}
      {approvedUseId === ApprovedUse.DeckSteel && <SteelDeckUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.VaporRetarder && <VaporRetarderUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.VaporRetarderSystem && <VaporRetarderSystemUsageDetail usageId={usageId} />}

      {approvedUseId === ApprovedUse.CoverBoard && <CoverBoardUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.CoverMultiplyCapPly && <CapPlyUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.WeldTorched && <WeldTorchedUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.ThermalBarrier && <ThermalBarrierUsageDetail usageId={usageId} />}

      {approvedUseId === ApprovedUse.CoverMultiplyBasePly && <BasePlyUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.SubstratePrimer && <SubstratePrimerUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.CoverCompositePanel && <CompositePanelCoverUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.FasteningFastener && <FastenerUsageDetail usageId={usageId} />}

      {approvedUseId === ApprovedUse.CoverLiquidAppliedCoat && <CoatUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.CoverLiquidAppliedTopCoat && <TopCoatUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.CoverLiquidAppliedBaseCoat && <BaseCoatUsageDetail usageId={usageId} />}

      {approvedUseId === ApprovedUse.InsulationSprayFoam && <SprayedFoamInsulationUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.InsulationBattBlanket && <BattBlanketInsulationUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.CoverStandingLapSeam && <StandingLapSeamCoverUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.AirRetarder && <AirRetarderUsageDetail usageId={usageId} />}

      {approvedUseId === ApprovedUse.SeamTapeSystem && <SeamTapeSystemUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.AdhesiveRibbons && <AdhesiveRibbonsUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.DeckCementitiousPanel && (
        <CementitiousWoodFiberDeckUsageDetail usageId={usageId} />
      )}
      {approvedUseId === ApprovedUse.WeldHeat && <WeldHeatUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.SeparatorSheet && <SeparatorSheetUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.CoverStripDisk && <CoverStripDiskUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.LinerPanel && <LinerPanelUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.FasteningSystemClip && <ClipSystemUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.CoatingSystem && <CoatingSystemUsageDetail usageId={usageId} />}

      {approvedUseId === ApprovedUse.FasteningSystemBattenBar && <BattenBarSystemUsageDetail usageId={usageId} />}

      {approvedUseId === ApprovedUse.WeldSolvent && <WeldSolventUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.FormDeck && <FormDeckUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.AdhesiveSpots && <AdhesiveSpotsUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.WeldArc && <WeldArcUsageDetail usageId={usageId} />}
      {approvedUseId === ApprovedUse.AdhesiveSystem && <AdhesiveSystemUsageDetail usageId={usageId} />}

      {approvedUseId !== ApprovedUse.Coating &&
        approvedUseId !== ApprovedUse.Surfacing &&
        approvedUseId !== ApprovedUse.BaseSheet &&
        approvedUseId !== ApprovedUse.FasteningPreassembledFastening &&
        approvedUseId !== ApprovedUse.AdhesiveFullApplication &&
        approvedUseId !== ApprovedUse.CoverMultiplyPly &&
        approvedUseId !== ApprovedUse.CoverSingleply &&
        approvedUseId !== ApprovedUse.InsulationBoardStock &&
        approvedUseId !== ApprovedUse.LightweightInsulatingConcrete &&
        approvedUseId !== ApprovedUse.DeckSteel &&
        approvedUseId !== ApprovedUse.VaporRetarder &&
        approvedUseId !== ApprovedUse.VaporRetarderSystem &&
        approvedUseId !== ApprovedUse.CoverBoard &&
        approvedUseId !== ApprovedUse.CoverMultiplyCapPly &&
        approvedUseId !== ApprovedUse.WeldTorched &&
        approvedUseId !== ApprovedUse.ThermalBarrier &&
        approvedUseId !== ApprovedUse.CoverMultiplyBasePly &&
        approvedUseId !== ApprovedUse.SubstratePrimer &&
        approvedUseId !== ApprovedUse.CoverCompositePanel &&
        approvedUseId !== ApprovedUse.FasteningFastener &&
        approvedUseId !== ApprovedUse.CoverLiquidAppliedCoat &&
        approvedUseId !== ApprovedUse.CoverLiquidAppliedTopCoat &&
        approvedUseId !== ApprovedUse.CoverLiquidAppliedBaseCoat &&
        approvedUseId !== ApprovedUse.InsulationSprayFoam &&
        approvedUseId !== ApprovedUse.InsulationBattBlanket &&
        approvedUseId !== ApprovedUse.CoverStandingLapSeam &&
        approvedUseId !== ApprovedUse.AirRetarder &&
        approvedUseId !== ApprovedUse.SeamTapeSystem &&
        approvedUseId !== ApprovedUse.AdhesiveRibbons &&
        approvedUseId !== ApprovedUse.DeckCementitiousPanel &&
        approvedUseId !== ApprovedUse.WeldHeat &&
        approvedUseId !== ApprovedUse.SeparatorSheet &&
        approvedUseId !== ApprovedUse.CoverStripDisk &&
        approvedUseId !== ApprovedUse.LinerPanel &&
        approvedUseId !== ApprovedUse.FasteningSystemClip &&
        approvedUseId !== ApprovedUse.CoatingSystem &&
        approvedUseId !== ApprovedUse.FasteningSystemBattenBar &&
        approvedUseId !== ApprovedUse.WeldSolvent &&
        approvedUseId !== ApprovedUse.FormDeck &&
        approvedUseId !== ApprovedUse.AdhesiveSpots &&
        approvedUseId !== ApprovedUse.WeldArc &&
        approvedUseId !== ApprovedUse.AdhesiveSystem &&
        approvedUseId !== ApprovedUse.FasteningSystemStressPlate && (
          <div>
            <h4>Coming Soon!</h4>
          </div>
        )}
    </div>
  );
};
