export const LAYERMODELHASURA_MAP: Record<string, string> = {
  AirRetarder: 'View_Assembly_Search_AirRetarder',
  BaseCoat: 'View_Assembly_Search_BaseCoat',
  BasePly: 'View_Assembly_Search_BasePly',
  BaseSheet: 'View_Assembly_Search_BaseSheet',
  CapPly: 'View_Assembly_Search_CapPly',
  Coat: 'View_Assembly_Search_Coat',
  Coating: 'View_Assembly_Search_Coating',
  CoverBoard: 'View_Assembly_Search_CoverBoard',
  CoverCompositePanel: 'View_Assembly_Search_CoverCompositePanel',
  CoverSingleply: 'View_Assembly_Search_CoverSingleply',
  CoverStandingLapSeam: 'View_Assembly_Search_CoverStandingLapSeam',
  CoverStripDisk: 'View_Assembly_Search_CoverStripDisk',
  DeckCementitiousPanel: 'View_Assembly_Search_DeckCementitiousPanel',
  DeckSteel: 'View_Assembly_Search_DeckSteel',
  FormDeck: 'View_Assembly_Search_FormDeck',
  InsulationBattBlanket: 'View_Assembly_Search_InsulationBattBlanket',
  InsulationBoardStock: 'View_Assembly_Search_BoardStock',
  InsulationSprayFoam: 'View_Assembly_Search_InsulationSprayFoam',
  LightweightInsulatingConcrete: 'View_Assembly_Search_LightweightInsulatingConcrete',
  LinerPanel: 'View_Assembly_Search_LinerPanel',
  Ply: 'View_Assembly_Search_Ply',
  SeparatorSheet: 'View_Assembly_Search_SeparatorSheet',
  SubstratePrimer: 'View_Assembly_Search_SubstratePrimer',
  Surfacing: 'View_Assembly_Search_Surfacing',
  ThermalBarrier: 'View_Assembly_Search_ThermalBarrier',
  TopCoat: 'View_Assembly_Search_TopCoat',
  VaporRetarder: 'View_Assembly_Search_VaporRetarder',
  CoatingSurfacing: 'View_Assembly_Search_CoatingSurfacing',
  ExistingRoof: 'View_Assembly_Search_ExistingRoof',
  Structure: 'View_Assembly_Search_Structure'
};
