import React from 'react';
import { useGetCoatingSystemUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { SolidApplicationRate } from '../../utils/Measurement/SolidApplicationRate';
import { VolumePerUnitArea } from '../../utils/Measurement/VolumePerUnitArea';
import './UsageDetails.scss';

export const CoatingSystemUsageDetail = (props: any) => {
  const { data, loading, error } = useGetCoatingSystemUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];
  const isMetric = usage?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Coating System</h4>
      <table>
        <tr>
          <td>Application Rate Wet:</td>
          <td>{VolumePerUnitArea.display(usage?.CoatingSystemUsage?.ApplicationRateWet, isMetric)}</td>
        </tr>
        <tr>
          <td>Application Rate Dry:</td>
          <td>{SolidApplicationRate.display(usage?.CoatingSystemUsage?.ApplicationRateDry, isMetric)}</td>
        </tr>
        <tr>
          <td valign="top">System Comments:</td>
          <td>{usage?.Component.Comments}</td>
        </tr>
        <tr>
          <td valign="top">Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
