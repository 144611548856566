export class Density {
  static LbPerCubicFt_TO_KilogramPerCubicMeter = 16.01846;
  static IMPERIALUNIT = 'lb/ft3';
  static METRICUNIT = 'kg/m3';

  static convertImperialToMetric(valueImperial: number) {
    const valueMetric = valueImperial * Density.LbPerCubicFt_TO_KilogramPerCubicMeter;
    return valueMetric.toFixed(4) + ' ' + Density.METRICUNIT;
  }

  static convertMetricToImperial(valueMetric: number) {
    const valueImperial = valueMetric / Density.LbPerCubicFt_TO_KilogramPerCubicMeter;
    return valueImperial.toFixed(4) + ' ' + Density.IMPERIALUNIT;
  }

  static displayMetric(valueMetric: number) {
    return valueMetric.toFixed(4) + ' ' + Density.METRICUNIT;
  }

  static display(valueMetric: number, showAsMetric: boolean) {
    if (showAsMetric) return Density.displayMetric(valueMetric);
    else return Density.convertMetricToImperial(valueMetric);
  }
}
export class NominalWeight {
  static LbPerCubicFt_TO_KilogramPerCubicMeter = 16.01846;
  static LbPerSqureFt_TO_KilogramPerSqure = 20.48161;
  static IMPERIALUNIT = 'lb/ft2';
  static METRICUNIT = 'kg/m2';
  static SQUREUNIT = 'lb/square';

  static convertImperialToMetric(valueImperial: number) {
    const valueMetric = valueImperial * NominalWeight.LbPerCubicFt_TO_KilogramPerCubicMeter;
    return valueMetric.toFixed(4) + ' ' + NominalWeight.METRICUNIT;
  }

  static convertMetricToImperial(valueMetric: number) {
    const valueImperial = valueMetric / NominalWeight.LbPerCubicFt_TO_KilogramPerCubicMeter;
    return valueImperial.toFixed(4) + ' ' + NominalWeight.IMPERIALUNIT;
  }
  static convertToSqFt(valueWeight: number) {
    const valueLb = valueWeight * NominalWeight.LbPerSqureFt_TO_KilogramPerSqure;
    return valueLb.toFixed(4) + ' ' + NominalWeight.SQUREUNIT;
  }

  static displayMetric(valueMetric: number) {
    return valueMetric.toFixed(4) + ' ' + NominalWeight.METRICUNIT;
  }
}
