import { Link, Typography } from '@mui/material';
import React from 'react';
import theme from '../../theme';
import './RefMaterial.scss';
import { getApprovalDocByDocumentId, getDataSheetsByCategoryId } from '../../services/RefferenceMaterialService';
import { TextOnlyComponent } from '../../components/shared/TextOnlyComponent/TextOnlyComponent';
import { DataSheetTable } from './datasheettable';

export const DataSheetsView = () => {
  const commList = getDataSheetsByCategoryId(1);
  const otherList = getDataSheetsByCategoryId(2);

  const handleDocument = (docid: number) => {
    loadDocument(docid);
  };
  const loadDocument = async (documentId: number) => {
    const ApprovalDocument = await getApprovalDocByDocumentId(documentId);
    const bytes = atob(ApprovalDocument[0].DocData);
    let length = bytes.length;
    const out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
    const blob = new Blob([out], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  };
  const handleBackClick = () => {
    history.back();
  };
  const linkStyle = {
    color: theme.palette.rnOrange.darkOrange,
    fontweight: 600,
  };
  return (
    <main className="main-content container-fluid flex-shrink-0 refmaterial-ui">
      <div className={'px-4'}>
        <div className="d-flex justify-content-between align-items-center">
          <Typography className={'headline-1 py-5'} color={theme.palette.rnSteel.steel700}>
            Data Sheets
          </Typography>

          <Link onClick={handleBackClick} className="d-flex float-right">
            <TextOnlyComponent buttonText={'Back'} iconName={'arrow_left'} />
          </Link>
        </div>
        <Typography className={'large-para pb-4'}>
          M Global Property Loss Prevention Data Sheets are engineering guidelines written to help reduce the chance of
          property loss due to fire, weather conditions and failure of electrical and mechanical equipment. They
          incorporate loss experience, input from consensus standards committees, equipment manufacturers and others,
          and represent 170 years of FM research into the area of property protection. The data sheets included
          in RoofNav are specific to roofing installations and the roofing industry.
        </Typography>
        <div className={'pb-2'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            Most Commonly Used Data Sheets
          </Typography>
          <Typography className={'large-para pb-2'}>
            The following table shows the Data Sheets that provide the most useful information for the majority of
            roofing professionals.
          </Typography>
          <DataSheetTable approvalList={commList} handleDocument={handleDocument} isStandard={false}></DataSheetTable>
        </div>
        <div className={'pb-2'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            Other Roofing-Related Data Sheets
          </Typography>
          <Typography className={'large-para pb-2'}>
            The following table shows other Data Sheets that are useful for roofing designers, plan reviewers, and
            others who are interested in knowing detailed information about how roofs are designed.
          </Typography>
          <DataSheetTable approvalList={otherList} handleDocument={handleDocument} isStandard={false}></DataSheetTable>
        </div>
      </div>
    </main>
  );
};
