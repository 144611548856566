export class Pressure {
  static KSI_TO_Megapascal = 6.89476;
  static IMPERIALUNIT = 'ksi';
  static METRICUNIT = 'mPa';

  static convertImperialToMetric(valueImperial: number) {
    const valueMetric = valueImperial * Pressure.KSI_TO_Megapascal;
    return valueMetric.toFixed(4) + ' ' + Pressure.METRICUNIT;
  }

  static convertMetricToImperial(valueMetric: number) {
    const valueImperial = valueMetric / Pressure.KSI_TO_Megapascal;
    return valueImperial.toFixed(4) + ' ' + Pressure.IMPERIALUNIT;
  }

  static displayMetric(valueMetric: number) {
    return valueMetric.toFixed(4) + ' ' + Pressure.METRICUNIT;
  }

  static display(valueMetric: number, showAsMetric: boolean) {
    if (showAsMetric) return Pressure.displayMetric(valueMetric);
    else return Pressure.convertMetricToImperial(valueMetric);
  }
}
