import { Link, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ApplicationContext } from '../ApplicationProvider';
import { BannerComponent } from '../shared/BannerComponent/BannerComponent';
import { TextOnlyComponent } from '../shared/TextOnlyComponent/TextOnlyComponent';
import { useGetNavAssemblyCompleteDetailQuery } from '../../models/GQLGeneratedModels';
import theme from '../../theme';
import { Detail } from '../../pages/AssemblyDetail/Detail';
import { Summary } from '../../pages/AssemblyDetail/Summary';
import { Dialog } from '../shared/DialogComponent/Dialog';
import { ErrorModalComponent } from '../shared/ErrorModalComponent/ErrorModalComponent';

interface IAssemblyViewProps {
  navAssemblyId: number;
  handleBackClick: () => void;
  children?: React.ReactNode;
}

export const AssemblyDetail: React.FC<IAssemblyViewProps> = ({
  navAssemblyId,
  handleBackClick,
  children,
}: IAssemblyViewProps) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const params = useParams();
  const { data, loading, error } = useGetNavAssemblyCompleteDetailQuery({
    variables: {
      NavAssemblyId: navAssemblyId,
    },
  });

  const { setDialog, setShowDialog } = useContext(ApplicationContext);
  useEffect(() => {
    if (error) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [error]);
  const properties = data?.NavAssembly[0];
  const layers = data?.vNavAssembly_Layers;
  const usages = data?.vNavAssembly_Usages;
  return (
    <main className="main-content container-fluid flex-shrink-0">
      <div>
        <div className="row justify-content-center">
          {/* <BannerComponent title={bannerTitle} message={bannerMessage} className={'col-11'} /> */}
          <div className="col-12 pt-5">
            <section className="bg-white h-100 overflow-hidden">
              {!loading && (
                <>
                  <div className="row align-items-stretch">
                    <div className="col-9">
                      <div className="d-flex align-items-center h-100">
                        <Typography
                          className="mx-3"
                          fontFamily={'Inter'}
                          fontSize={'36px'}
                          fontWeight={700}
                          lineHeight={'44px'}
                          color={theme.palette.rnSteel.steel700}
                        >
                          Assembly Detail
                        </Typography>
                      </div>
                    </div>
                    <div className="col px-4 my-1 text-right">
                      <Link onClick={handleBackClick}>
                        <TextOnlyComponent buttonText={'Back'} iconName={'arrow_left'} />
                      </Link>
                    </div>
                  </div>
                  <div className="card border-0 h-100">
                    <div className="card-body p-0">
                      <Summary properties={properties} />
                      <Detail layers={layers} usages={usages} />
                    </div>
                  </div>
                </>
              )}
            </section>
          </div>
        </div>
      </div>
    </main>
  );
};
