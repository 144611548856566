import { ToggleButtonGroup } from "@mui/material";
import React, { HTMLAttributes } from "react";
import './ButtonGroupComponent.scss';

interface IButtonGroupComponentProps extends HTMLAttributes<HTMLDivElement> {
    id?: string;
    label?: string;
    children: React.ReactNode,
    // buttonGroupStyle passed from ConfigitForm originates in ASSEMBLY_PROPS.ts
    buttonGroupStyle?: string
}


export const ButtonGroupComponent = ({
    id,
    label,
    children,
    buttonGroupStyle,
    ...other
}: IButtonGroupComponentProps) => {
    return (
        <ToggleButtonGroup
            id={(id ? id : 'undefinedId') + 'Wrapper'}
            data-testid={(id ? id : 'undefinedId') + 'Wrapper'}
            className={`btn-group btn-group-toggle ${buttonGroupStyle}`}
            aria-label={label}
            exclusive
        >
            {children}
        </ToggleButtonGroup>
    );
};