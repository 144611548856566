import React from 'react';
import { useEffect, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import { useSelector } from 'react-redux';
import { RootState } from './../../store';
import { useDispatch } from 'react-redux';
import { jumpToIndex } from '../../reducers/calculatorReducer';
import theme from '../../theme';


const steps = ['Fire', 'Hail', 'Wind', 'Summary'];

export const ProgressBar = () => {
  const selectCalculatorIndex = (state: RootState) => state.calculator.calculatorIndex;
  const calculatorIndex = useSelector(selectCalculatorIndex);
  const selectIsWindComplete = (state: RootState) => state.calculator.isWindComplete;
  const isWindComplete = useSelector(selectIsWindComplete);
  const dispatch = useDispatch();


  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    setActiveStep(calculatorIndex);
  }, [calculatorIndex]);

  const navigateStepper = (label: string, index: number) => {
    if (isWindComplete) dispatch(jumpToIndex(index));
    if (!isWindComplete && label != 'Summary') dispatch(jumpToIndex(index));
  }

  return (
    <Box pt={5} sx={{ width: '90%' }}>
      <Stepper nonLinear activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            id={`step${index}`}
            key={label}
            sx={{
              '& .MuiSvgIcon-root.MuiStepIcon-root': {
                color: `${theme.palette.rnSteel.steel700}`,
                transform: 'scale(1.5)',
                cursor: (label == 'Summary' && !isWindComplete) ? 'default' : 'pointer'
              },
              '& .MuiStepLabel-root .MuiStepLabel-alternativeLabel': {
                color: `${theme.palette.rnSteel.steel700}`, // circle color (ACTIVE)
                fontSize: '20px',
                fontFamily: 'Inter',
                fontStyle: 'bold',
                fontWeight: 500
              },
              '& .MuiStepLabel-root .Mui-active': {
                color: `${theme.palette.rnOrange.darkOrange}`, // circle color (ACTIVE)
                fontSize: '20px',
                fontFamily: 'Inter',
              },
              '& .MuiStepLabel-root .MuiStepIcon-text': {
                fontFamily: 'Inter',
                fontWeight: 500,
                cursor: (label == 'Summary' && !isWindComplete) ? 'default' : 'pointer'
              },
              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text ': {
                fontFamily: 'Inter',
              },
            }}
            onClick={() => { navigateStepper(label, index) }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
