import React, { useContext, useEffect, useState } from 'react';
import { IConfigitForm } from '../../components/Filters/ConfigitForm';
import { ApplicationContext } from '../../components/ApplicationProvider';
import { AllowedValues, Section, SingletonValue, VariableAssignment } from '../../types/configurator';
import { ConfigitTextField } from '../../components/shared/TextFieldComponent/ConfigitTextField';
import { SelectBox } from '../../components/shared/SelectComponent/SelectBox';
import { configureLayer as configureLayerAPI } from '../../services/ConfigitService';
import { ProductAttributeForm } from './ProductAttributeForm';
import { Loading } from '../../components/shared/Loading/Loading';
import { Typography } from '@mui/material';
import { attributeSearchCriteria } from '../../services/ProductSearchService';
import { ConfigitProductContext } from '../../components/ConfigitProductProvider';
import { AttributeType, ComparisonOperator } from '../../services/AssemblySearchAPIService';
import { APU_MEASUREMENT_ATTRIBUTES } from '../../components/constants/MEASURE_UNITS';
import theme from '../../theme';
export const ConfigitProductForm: React.FC<IConfigitForm> = ({
  variableAssignments,
  setVariableAssignments,
  section,
  limit,
  getVariableAt,
}: IConfigitForm) => {
  const [selectedAPUName, setSelectedAPUName] = useState<string | undefined>('');
  const { setLoading } = useContext(ApplicationContext);
  const {
    attributes,
    setAttributes,
    isAttributeSelected,
    setIsAttributeSelected,
    selectedApprovedUse,
    setSelectedApprovedUse,
    selectedAttributeId,
    setSelectedAttributeId,
  } = useContext(ConfigitProductContext);
  let attributeLoading = false;
  const nonAttributeIds = [
    'Manufacturer_ManufacturerId',
    'ManufacturedProduct_ComponentId',
    'ManufacturedProduct_CategoryId',
    'CApprovedUse_ApprovedUseId',
    'ManufacturedProduct_CountryId',
  ];
  //    causes selection issues
  useEffect(() => {
    // if (selectedApprovedUse) {
    //     const defaultOperatorVariableAssignments = selectedApprovedUse?.variables
    //         ?.filter(
    //             (data) => data.id?.endsWith('Operator'))
    //         .map((data) => {
    //             return { variableId: data.id, value: 'Equal' } as VariableAssignment;
    //         });
    //     if (defaultOperatorVariableAssignments) {
    //         const newVariableAssignments: VariableAssignment[] = [...variableAssignments, ...defaultOperatorVariableAssignments];
    //         setVariableAssignments(newVariableAssignments);
    //     }
    // }
  }, [selectedAPUName, selectedApprovedUse]);
  // Selection
  const formSelect = (
    variableId: string,
    value: string | string[] | AllowedValues[],
    variableAssignments: VariableAssignment[],
    setVariableAssignments: (variableAssignments: VariableAssignment[]) => void,
    setLoading: (loading: boolean) => void
  ) => {
    setLoading(true);
    let newVariableAssignments = variableAssignments.filter(
      (variableAssignment) => variableAssignment.variableId !== variableId
    );
    if (typeof value === 'string') newVariableAssignments.push({ variableId, value });
    else if (typeof value[0] === 'string')
      newVariableAssignments.push({
        variableId,
        type: 'AllowedValues',
        allowedValues: value.map((val) => {
          return { type: 'SingletonValue', value: String(val) };
        }),
      });
    else
      newVariableAssignments.push({
        variableId,
        type: 'AllowedValues',
        allowedValues: value.map((data) => data as AllowedValues),
      });
    // logic for attribute changes below
    if (!nonAttributeIds.includes(variableId) && typeof value === 'string') {
      // set flag here if man/product not selected
      const isOperator = variableId.includes('Operator');
      const attributeToPush = selectedApprovedUse?.variables?.find((attribute) => attribute.id === variableId);
      const operatorValue: string = isOperator ? value : getAssignedValue(variableId + 'Operator');
      // assigned by rule logic here

      if (attributeToPush && attributeToPush.properties) {
        const truncateAttributeName = variableId.substring(variableId.indexOf('_') + 1);

        const tempAttribute: attributeSearchCriteria = {
          attributeName: truncateAttributeName,
          attributeType: getAttributeType(attributeToPush.properties[0]?.value),
          attributeValue: isOperator ? '' : value,
          comparisonOperator: isOperator ? getComparisonOperator(value) : getComparisonOperator(operatorValue),
        };
        // check if attribute is already include and modify instead of pushing duplicate data
        let attributeFlag = false;
        attributes.forEach((obj) => {
          if (isOperator && obj.attributeName + 'Operator' === truncateAttributeName) {
            obj.comparisonOperator = getComparisonOperator(operatorValue);
            attributeFlag = true;
            return;
          }
          if (obj.attributeName === truncateAttributeName) {
            obj.attributeValue = value;
            obj.comparisonOperator = getComparisonOperator(operatorValue);
            attributeFlag = true;
            return;
          }
        });
        // check to see if measurement assigned by rule
        if (!attributeFlag && isOperator) {
          const assignedAttribute = selectedApprovedUse?.variables?.find(
            (attribute) => attribute.id + 'Operator' === variableId
          );
          const assignedValue = (assignedAttribute?.values as SingletonValue[]).find(
            (value) => value.assigned === 'byRule'
          )?.value;
          const parentName = truncateAttributeName.replace('Operator', '');
          if (assignedValue && assignedAttribute && assignedAttribute.properties) {
            const tempAttributeByRule: attributeSearchCriteria = {
              attributeName: parentName,
              attributeType: getAttributeType(assignedAttribute.properties[0]?.value),
              attributeValue: String(assignedValue),
              comparisonOperator: getComparisonOperator(operatorValue),
            };
            console.log({ tempAttributeByRule });
            attributes.push(tempAttributeByRule);
          }
        }
        if (!attributeFlag && !isOperator) attributes.push(tempAttribute);

        // move this code block higher up, include check to see if attribute exists
        if (selectedApprovedUse?.variables) {
          selectedApprovedUse.variables.forEach((attr) => {
            let assignedVal: string | undefined = undefined;
            if (!attr.id?.includes('view')) {
              attr.values?.forEach((value) => {
                if (value.assigned === 'byRule') {
                  assignedVal = String((value as SingletonValue).value);
                }
              });
            }
            if (
              assignedVal &&
              attr.id &&
              attr.properties &&
              !attr.id.includes('Operator') &&
              !nonAttributeIds.includes(attr.id)
            ) {
              const truncatedAssignedName = attr.id.substring(attr.id.indexOf('_') + 1);
              const assignedOperatorValue: string = getAssignedValue(variableId + 'Operator');
              //   const isAssignedOperator
              // const assignedOperatorValue
              const assignedAttributeByRule: attributeSearchCriteria = {
                attributeName: truncatedAssignedName,
                attributeType: getAttributeType(attr.properties[0]?.value),
                attributeValue: assignedVal,
                comparisonOperator: isOperator
                  ? getComparisonOperator(assignedVal)
                  : getComparisonOperator(assignedOperatorValue),
              };
              if (
                !attributes.find(
                  (attribute) => attribute.attributeName === attr.id?.substring(attr.id.indexOf('_') + 1)
                )
              ) {
                attributes.push(assignedAttributeByRule);
              }
            }
          });
        }
        setIsAttributeSelected(true);
      }
    }
    // remove attributes and variable assignments associated with them if new APU selected
    if (variableId == 'CApprovedUse_ApprovedUseId') {
      newVariableAssignments = newVariableAssignments.filter((variableAssignment) =>
        nonAttributeIds.includes(variableAssignment.variableId ? variableAssignment.variableId : '')
      );
      setAttributes([]);
      setIsAttributeSelected(true);
    }
    // add in checks- this is happy path and will be error prone
    // add in check for assigned by rule
    if (selectedAttributeId) updateAttributeData(selectedAttributeId, newVariableAssignments);
    setVariableAssignments(newVariableAssignments);
  };
  const getSelectedData = (dataId: string, selectedApprovedUse: Section) => {
    const selectedData = selectedApprovedUse.variables?.find((attribute) => attribute.id === dataId)?.values
    return selectedData;
  };
  const updateAttributeData = async (attributeValue: string, updatedVariableAssignments: VariableAssignment[]) => {
    const getSelectedAttribute = await configureLayerAPI(updatedVariableAssignments, `apu_${attributeValue}`).then(
      (value) => {
        setSelectedApprovedUse(value);
        return value;
      }
    );
    // setSelectedApprovedUse(getSelectedAttribute)
  };
  const getAttributeType = (value: string | undefined) => {
    let comparisonValue = -1;
    // lower case to account for difference between data from model and type in table
    switch (value?.toLowerCase()) {
      case 'boolean':
        comparisonValue = AttributeType.Boolean;
        break;
      case 'codetable':
        comparisonValue = AttributeType.CodeTable;
        break;
      case 'measurement':
        comparisonValue = AttributeType.Measurement;
        break;
      case 'text':
        comparisonValue = AttributeType.Text;
        break;
      case 'multivalue':
        comparisonValue = AttributeType.Multivalue;
        break;
      default:
        break;
    }
    return comparisonValue;
  };
  const getComparisonOperator = (value: string) => {
    let comparisonValue = 0;
    switch (value) {
      case 'Equal':
        comparisonValue = ComparisonOperator.EqualTo;
        break;
      case 'Different':
        comparisonValue = ComparisonOperator.NotEqualTo;
        break;
      case 'Bigger':
        comparisonValue = ComparisonOperator.GreaterThan;
        break;
      case 'BiggerOrEqual':
        comparisonValue = ComparisonOperator.GreaterThanOrEqual;
        break;
      case 'Less':
        comparisonValue = ComparisonOperator.LessThan;
        break;
      case 'LessOrEqual':
        comparisonValue = ComparisonOperator.LessThanOrEqual;
        break;
    }
    return comparisonValue;
  };
  const getAssignedValue = (variableId: string) => {
    const selectedAPU = selectedApprovedUse?.variables?.find((attribute) => attribute.id === variableId)
      ?.values as SingletonValue[];
    let result = 'Equal';
    if (selectedAPU) {
      selectedAPU.forEach((obj) => {
        if (obj.value && obj.assigned === 'byUser') {
          result = String(obj.value);
        }
      });
    }
    return result;
  };
  const updateUnselectAttributes = async (variableId: string, isOperator: boolean) => {
    const newVariableAssignments = variableAssignments.filter(
      (variableAssignment) => variableAssignment.variableId != variableId
    );
    // update here?
    const testSelected = selectedApprovedUse;
    const updateData = await updateAttributeData(selectedAttributeId, newVariableAssignments);

    if (selectedApprovedUse?.variables) {
      // setAttributes([])
      const tempAttributes: attributeSearchCriteria[] = [];

      selectedApprovedUse.variables.forEach((attr) => {
        let assignedVal: string | undefined = undefined;
        if (!attr.id?.includes('view')) {
          attr.values?.forEach((value) => {
            if (value.assigned) {
              assignedVal = String((value as SingletonValue).value);
            }
          });
        }
        if (
          assignedVal &&
          attr.id &&
          attr.properties &&
          !attr.id.includes('Operator') &&
          !nonAttributeIds.includes(attr.id)
        ) {
          console.log({ assignedVal });
          const truncatedAssignedName = attr.id.substring(attr.id.indexOf('_') + 1);
          const assignedOperatorValue: string = getAssignedValue(variableId + 'Operator');
          const assignedAttributeByRule: attributeSearchCriteria = {
            attributeName: truncatedAssignedName,
            attributeType: getAttributeType(attr.properties[0]?.value),
            attributeValue: assignedVal,
            comparisonOperator: isOperator
              ? getComparisonOperator(assignedVal)
              : getComparisonOperator(assignedOperatorValue),
          };
          console.log(attr.id?.substring(attr.id.indexOf('_') + 1));
          if (
            !attributes.find((attribute) => attribute.attributeName === attr.id?.substring(attr.id.indexOf('_') + 1))
          ) {
            tempAttributes.push(assignedAttributeByRule);
          }
        }
      });
      setAttributes(tempAttributes);
    }
    if (attributes.length === 0) setIsAttributeSelected(false);

    setVariableAssignments(newVariableAssignments);
  };
  // removes previously selected value and triggers configit process
  const formUnSelect = (
    variableId: string,
    variableAssignments: VariableAssignment[],
    setVariableAssignments: (variableAssignments: VariableAssignment[]) => void,
    setLoading: (loading: boolean) => void
  ) => {
    setLoading(true);
    const isOperator = variableId.includes('Operator');
    let newVariableAssignments = variableAssignments.filter(
      (variableAssignment) => variableAssignment.variableId != variableId
    );
    if (variableId == 'ManufacturedProduct_CategoryId') {
      newVariableAssignments = newVariableAssignments.filter(
        (variableAssignment) => variableAssignment.variableId != 'CApprovedUse_ApprovedUseId'
      );
      newVariableAssignments = newVariableAssignments.filter((variableAssignment) =>
        nonAttributeIds.includes(variableAssignment.variableId ? variableAssignment.variableId : '')
      );
      // setAttributes([])
      //if (selectedAttributeId) updateAttributeData(selectedAttributeId, newVariableAssignments);
      // debugger
      //setVariableAssignments(newVariableAssignments);
    } else if (variableId == 'CApprovedUse_ApprovedUseId') {
      newVariableAssignments = newVariableAssignments.filter((variableAssignment) =>
        nonAttributeIds.includes(variableAssignment.variableId ? variableAssignment.variableId : '')
      );
      setAttributes([]);
      setIsAttributeSelected(false);
      //if (selectedAttributeId) updateAttributeData(selectedAttributeId, newVariableAssignments);
      //setVariableAssignments(newVariableAssignments);
    } else {
      // filter out attributes, requires a truncated variableId field
      // possible fix- clear all, and if assinged by rule/user add to attributes1
      if (!nonAttributeIds.includes(variableId)) {
        const truncateAttributeName = variableId.substring(variableId.indexOf('_') + 1);

        updateUnselectAttributes(variableId, isOperator);
      }
    }
    setVariableAssignments(newVariableAssignments);

  };


  const select = (variableId: string, value: string | AllowedValues[]) =>
    formSelect(variableId, value, variableAssignments, setVariableAssignments, setLoading);
  const unSelect = (variableId: string) =>
    formUnSelect(variableId, variableAssignments, setVariableAssignments, setLoading);
  const getAttributeData = async (attributeValue: string, name: string | undefined) => {
    attributeLoading = true;
    // eqaul to before call
    const selectedMeasurements = APU_MEASUREMENT_ATTRIBUTES.find((obj) => obj.approvedUseId === attributeValue)
      ?.measurementAttributes;
    let tempAssignments: VariableAssignment[] = variableAssignments;
    if (selectedMeasurements) {
      const tempArray: VariableAssignment[] = [];

      selectedMeasurements.forEach((measurement) => {
        const operatorColumnName = measurement.columnName + 'Operator';
        if (!tempArray.find((obj) => obj.variableId === operatorColumnName)) {
          tempArray.push({ variableId: operatorColumnName, value: 'Equal' });
        }
      });
      tempAssignments = [...tempAssignments, ...tempArray];
    }
    const getSelectedAttribute = await configureLayerAPI(tempAssignments, `apu_${attributeValue}`);
    setSelectedApprovedUse(getSelectedAttribute);
    setSelectedAPUName(name);
    setSelectedAttributeId(attributeValue);
    return selectedApprovedUse;
  };
  let assignedVals = false;
  return (
    <>
      {section.variables?.map((variable, index) => {
        if (limit && index === limit) return;
        if (getVariableAt && index !== getVariableAt) return;
        const { id, name, properties, values, valueType } = variable;

        const last = section.variables && index === section.variables.length - 1;
        const isAuto: string[] = ['Manufacturer', 'Product', 'Country'];
        if (values && name !== 'Product subcategory') {
          let formGroup;
          const productName = 'Trade Name';

          // pass ProductModel section values if attribute properties are not selected. otherwise, pass in values
          // from attribute model
          if (name && isAuto.includes(name) && !isAttributeSelected) {
            formGroup = (
              <ConfigitTextField
                id={id}
                label={name.includes('Product') ? productName : name}
                options={values}
                select={select}
                unSelect={unSelect}
              />
            );
          } else if (
            name &&
            isAuto.includes(name) &&
            isAttributeSelected &&
            selectedApprovedUse &&
            selectedApprovedUse.variables
          ) {
            const countryValues = getSelectedData('ManufacturedProduct_CountryId', selectedApprovedUse);
            const manufacturerValues = getSelectedData('Manufacturer_ManufacturerId', selectedApprovedUse);
            const propertyValues = getSelectedData('ManufacturedProduct_ComponentId', selectedApprovedUse);
            if (name.includes('Manufacturer') && manufacturerValues) {
              formGroup = (
                <ConfigitTextField
                  id={id}
                  label={name}
                  options={manufacturerValues}
                  select={select}
                  unSelect={unSelect}
                />
              );
            }
            if (name.includes('Product') && propertyValues) {
              formGroup = (
                <ConfigitTextField
                  id={id}
                  label={productName}
                  options={propertyValues}
                  select={select}
                  unSelect={unSelect}
                />
              );
            }
            if (name.includes('Country') && countryValues) {
              formGroup = (
                <SelectBox
                  id={id}
                  label={name}
                  options={countryValues}
                  select={select}
                  unSelect={unSelect}
                  className={'assembly-select as-2'}
                />
              );
            }
          } else if (name !== 'Product subcategory') {
            formGroup = (
              <SelectBox
                id={id}
                label={name}
                options={values}
                select={select}
                unSelect={unSelect}
                className={'assembly-select as-2'}
              />
            );
          }
          if (id == 'CApprovedUse_ApprovedUseId') {
            assignedVals = false;
            values.forEach((datum) => {
              if (datum.assigned) {
                const chosenAttributeValue = (datum as SingletonValue).value;
                if (chosenAttributeValue) {
                  getAttributeData(String(chosenAttributeValue), (datum as SingletonValue).name);
                  assignedVals = true;
                  attributeLoading = false;
                }
              }
            });
          }

          return (
            <div key={index} className={`form-group ${!last && index === 0 ? 'mt-2' : ''} ${last ? 'mb-0' : ''}`}>
              <div className="d-flex align-items-center">{formGroup}</div>
              {assignedVals && selectedApprovedUse && (
                <div className="d-flex align-items-center pt-3">
                  {!attributeLoading ? (
                    <div>
                      {/* TODO: check rendering here */}
                      <Typography
                        fontFamily={'Inter'}
                        fontSize={20}
                        fontWeight={500}
                        color={theme.palette.rnSteel.steel700}
                        className="pb-2 pt-1"
                      >
                        {selectedAPUName} Attributes
                      </Typography>
                      <ProductAttributeForm
                        attributeId={selectedAttributeId}
                        approvedUseCode={selectedAPUName ? selectedAPUName : ''}
                        variables={selectedApprovedUse.variables}
                        handleFormSelect={select}
                        handleFormUnSelect={unSelect}
                      />
                    </div>
                  ) : (
                    <Loading />
                  )}
                </div>
              )}
            </div>
          );
        }
      })}
    </>
  );
};
