import { MeasureType } from '../../components/constants/ENUMS';
import { Area } from './Area';
import { Density } from './Density';
import { Force } from './Force';
import { Length } from './Length';
import { MassPerUnitArea } from './MassPerUnitArea';
import { Pressure } from './Pressure';
import { SolidApplicationRate } from './SolidApplicationRate';
import { Strength } from './Strength';
import { VolumePerUnitArea } from './VolumePerUnitArea';

export class UnitConversion {
  static convertImperialToMetric(valueImperial: number, measureTypeId: number) {
    let valueMetric = 0;
    switch (measureTypeId) {
      case MeasureType.Length:
        valueMetric = valueImperial * Length.INCH_TO_MM;
        break;
      case MeasureType.Area:
        valueMetric = valueImperial * Area.SQUAREFEET_TO_SQUAREMETERS;
        break;
      case MeasureType.Density:
        valueMetric = valueImperial * Density.LbPerCubicFt_TO_KilogramPerCubicMeter;
        break;
      case MeasureType.MassPerUnitArea:
        valueMetric = valueImperial * MassPerUnitArea.OzPerSquareFt_TO_KILOSSQUAREMETER;
        break;
      case MeasureType.Pressure:
        valueMetric = valueImperial * Pressure.KSI_TO_Megapascal;
        break;
      case MeasureType.SolidApplicationRate:
        valueMetric = valueImperial * SolidApplicationRate.POUNDSQUARE_TO_KILOSSQUAREMETER;
        break;
      case MeasureType.VolumePerUnitArea:
        valueMetric = valueImperial * VolumePerUnitArea.GALLONSSQUARE_TO_LITERSSQUAREMETER;
        break;
      case MeasureType.Strength:
        valueMetric = valueImperial * Strength.KSI_TO_Megapascal;
        break;
    }
    return valueMetric.toFixed(4);
  }

  static convertMetricToImperial(valueMetric: number, measureTypeId: number) {
    let valueImperial = 0;
    switch (measureTypeId) {
      case MeasureType.Length:
        valueImperial = valueMetric / Length.INCH_TO_MM;
        break;
      case MeasureType.Area:
        valueImperial = valueMetric / Area.SQUAREFEET_TO_SQUAREMETERS;
        break;
      case MeasureType.Density:
        valueImperial = valueMetric / Density.LbPerCubicFt_TO_KilogramPerCubicMeter;
        break;
      case MeasureType.MassPerUnitArea:
        valueImperial = valueMetric / MassPerUnitArea.OzPerSquareFt_TO_KILOSSQUAREMETER;
        break;
      case MeasureType.Pressure:
        valueImperial = valueMetric / Pressure.KSI_TO_Megapascal;
        break;
      case MeasureType.SolidApplicationRate:
        valueImperial = valueMetric / SolidApplicationRate.POUNDSQUARE_TO_KILOSSQUAREMETER;
        break;
      case MeasureType.VolumePerUnitArea:
        valueImperial = valueMetric / VolumePerUnitArea.GALLONSSQUARE_TO_LITERSSQUAREMETER;
        break;
      case MeasureType.Strength:
        valueImperial = valueMetric / Pressure.KSI_TO_Megapascal;
        break;
      case MeasureType.Force:
        valueImperial = valueMetric / Force.N_TO_LBF;
        break;
    }
    return valueImperial.toFixed(4);
  }
}
