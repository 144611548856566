import { Outlet, useNavigate } from 'react-router';
import React, { useContext, useEffect } from 'react';
import { ProductFilters } from './ProductFilters';
import { ConfigitProductContext } from '../../components/ConfigitProductProvider';
import { VariableAssignment } from '../../types/configurator';
import { ApplicationContext } from '../../components/ApplicationProvider';
import { configureLayer as configureLayerAPI } from '../../services/ConfigitService';

export const ProductSearchHome = () => {
  const navigate = useNavigate();
  const { setSection, section, variableAssignments, selectedAttributeId, isAttributeSelected } =
    useContext(ConfigitProductContext);
  const { loading, setLoading } = useContext(ApplicationContext);
  // flag, trigger base model or apu
  const manufacturerProductEffect = async (newManufacturerProduct: VariableAssignment[]) => {
    if (!loading) setLoading(true);
    let productFilterApplied = false;

    // const sectionVar = isAttributeSelected ? `apu_${selectedAttributeId}` : 'ProductSearchModel'
    const newSection = await configureLayerAPI(newManufacturerProduct, 'ProductSearchModel');
    // equality check
    if (section !== newSection) setSection(newSection);
    const productId = newManufacturerProduct.find((data) => data.variableId === 'ManufacturedProduct_ComponentId')
      ?.value;
    const manufacturerId = newManufacturerProduct.find((data) => data.variableId === 'Manufacturer_ManufacturerId')
      ?.value;
    const categoryId = newManufacturerProduct.find((data) => data.variableId === 'ManufacturedProduct_CategoryId')
      ?.value;
    const approvedUseId = newManufacturerProduct.find((data) => data.variableId === 'CApprovedUse_ApprovedUseId')
      ?.value;
    const countryId = newManufacturerProduct.find((data) => data.variableId === 'ManufacturedProduct_CountryId')?.value;

    if (productId || manufacturerId || categoryId || approvedUseId || countryId) {
      productFilterApplied = true;
    }
    if (productFilterApplied) {
      navigate('./productSearchResults');
    }
    setLoading(false);
  };
  useEffect(() => {
    manufacturerProductEffect(variableAssignments);
  }, [JSON.stringify(variableAssignments)]);

  useEffect(() => {
    const getSection = async () => {
      const newSection = await configureLayerAPI(variableAssignments, 'ProductSearchModel');
      if (section != newSection) setSection(newSection);
    };
    getSection();
  }, []);

  return (
    <main className="main-content container-fluid flex-shrink-0 product-ui">
      <div className="row h-100">
        <div className="col-xl-3 col-lg-3 col-md-4 filters product-filters">
          <ProductFilters />
        </div>
        <div className="col-xl-9 col-lg-9 col-md-8 h-100 product-outlet">
          <Outlet />
        </div>
      </div>
    </main>
  );
};
