import { List, ListItem, styled, Typography } from '@mui/material';
import React from 'react';
import theme from '../../theme';
import { ButtonComponent } from '../shared/ButtonComponent/ButtonComponent';
import { useDispatch } from 'react-redux';
import { toggleCalc } from '../../reducers/calculatorReducer';

const topText1 =
  'The Ratings Calculator allows you to specify fire and hail ratings, and calculate the wind uplift rating, for a roof area or for use in an assembly search.';
const topText2 =
  '. This way you only have to complete the fields in the Ratings Calculator once for a given roof area.';

const bottomText =
  'You can complete as few or many of the fields in the Fire and Hail sections as desired. However, to calculate the wind uplift ratings, all fields in the Wind section must be completed. When you are finished you can perform an assembly search with the specified ratings and slope.';
// TODO: some issues re: styles not applying to LitItem. Revisit later
const StyledList = styled(List)({
  '&.MuiList-root:': {
    fontWeight: '400',
    fontSize: '18px',
    display: 'list-item',
    listStylePosition: 'inside',
    listStyle: 'disc !important',
  },
  '&.MuiList-root .MuiListItem-root:': {
    listStyleType: 'disc',
    display: 'list-item',
    listStylePosition: 'inside',
    fontWeight: '400',
    fontSize: '18px',
    fontFamily: 'Inter',
  },
});

export const CalcLaunchComponent: React.FC = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(toggleCalc(true))
  };

  return (
    <div className={'p-4'}>
      <Typography className={'headline-1 py-4 d-inline-block'} color={theme.palette.rnSteel.steel700}>
        Ratings Calculator
      </Typography>
      <div className={'row p-0 m-0'}>
        <Typography className={'large-para pb-3'}>
          {topText1}
        </Typography>
      </div>
      <Typography className={'large-para pb-3'}>{bottomText}</Typography>
      <div className={'py-2'}>
        <ButtonComponent
          id='nextSection'
          buttonType={'primary'}
          click={handleClick}
          buttonText={'Next'}
          buttonStyle={'mx-2 py-2'} />
      </div>
    </div>
  );
};
