import React, { useContext } from "react";
import { ApplicationContext } from './ApplicationProvider';

export const DialogComp = () => {
    const { dialog, showDialog } = useContext(ApplicationContext);
    return (
        <>
            { showDialog && dialog }
        </>
    );
};