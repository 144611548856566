import { Checkbox, FormControlLabel, FormGroup, List, ListItem, styled, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import theme from '../../theme';
import { ApplicationContext } from '../ApplicationProvider';
import { ButtonComponent } from '../shared/ButtonComponent/ButtonComponent';
import { ButtonGroupComponent } from '../shared/ButtonGroupComponent/ButtonGroupComponent';
import { ToolTipComponent } from '../shared/TooltipComponent/ToolTipComponent';
import { ISelectValue } from '../shared/SelectComponent/SelectBox';
import { SurfaceRoughnessComponent } from './CalculatorInfo/SurfaceRoughnessComponent';
import { Dialog } from '../shared/DialogComponent/Dialog';
import { EnclosureClassificationComponent } from './CalculatorInfo/EnclosureClassificationComponent';
import { useDispatch } from 'react-redux';
import { previousSection, nextSection, setWindSpeed, setSurfaceRoughness, setEnclosureClassification, setCycloneProne, setRoofAreaHeight, setRoofAreaWidth, setRoofAreaLength, setRoofAreaSlope, setIsWindComplete, setIsRiseRun, setHasParapet } from '../../reducers/calculatorReducer';
import { ToggleButtonComponent } from '../shared/ToggleButtonComponent/ToggleButtonComponent';
import { useSelector } from 'react-redux';
import { RootState } from './../../store';
import { SelectComponent } from '../shared/SelectComponent/SelectComponent';
import { ENCLOSURE_CLASSIFICATION, SLOPE_DEGREE, SLOPE_RISE, SURFACE_ROUGHNESS, WIND_UPLIFT } from '../constants/CALCULATOR_VALUES';
import './calculator.scss';
import { useGetApprovalsDocByCategoryIdQuery } from '../../models/GQLGeneratedModels';
import { SwitchComponent } from '../shared/SwitchComponent/SwitchComponent';
import { Loading } from '../shared/Loading/Loading';
import { ErrorModalComponent } from '../shared/ErrorModalComponent/ErrorModalComponent';

const firstWindText =
  'A Wind Uplift Rating indicates how much wind uplift pressure a particular roof assembly can resist. Wind Uplift ratings are measured in pounds per square foot (psf), and span from 60 to 990 in 15 psf increments. The Ratings Calculator assumes a directionality factor (KD) = 0.85, which is appropriate for most building shapes and an elevation factor (Ke) = 1.0. Refer to ';
const secondWindText =
  'The Ratings Calculator outputs Wind Uplift Ratings for all roof zones applicable to data input. These may include Zone 1’, 1, 2, 3, 2e, 2n, 2r, 3e and 3r. In order to obtain these values, you must complete every field in this section with a valid data entry.';
const thirdWindText =
  'The ratings are used to determine what FM Approved assemblies can be used in a given roof area. In many cases there are two options for enhancing Zone 1 roof systems for Zone 2 and 3:';

const middleHailText =
  'to determine whether or not the construction is in a Moderate, Severe or Very Severe Hail area. (Adobe Acrobat Reader version 6.0 or later is required to open this map. Click here to download this software for free.) You can use the magnifying glass button in Acrobat Reader to enlarge a specific section of the map.';

export const WindRatingComponent = () => {
  const dispatch = useDispatch();
  const { setDialog, setShowDialog } = useContext(ApplicationContext);
  const windSpeedData: ISelectValue[] = WIND_UPLIFT;
  const [errorCheck, setErrorCheck] = useState(false);
  const [selectedMap, setSelectedMap] = useState('');

  // selectors
  const selectSurfaceRoughness = (state: RootState) => state.calculator.windRatingState.surfaceRoughness;
  const selectWindSpeed = (state: RootState) => state.calculator.windRatingState.windSpeed;
  const selectEnclosureClassification = (state: RootState) => state.calculator.windRatingState.enclosureClassification;
  const selectCycloneProne = (state: RootState) => state.calculator.windRatingState.cycloneProneLocation;
  const selectRoofAreaHeight = (state: RootState) => state.calculator.windRatingState.roofAreaHeight;
  const selectRoofAreaLength = (state: RootState) => state.calculator.windRatingState.roofAreaLength;
  const selectRoofAreaSlope = (state: RootState) => state.calculator.windRatingState.roofAreaSlope;
  const selectRoofAreaWidth = (state: RootState) => state.calculator.windRatingState.roofAreaWidth;
  const selectIsWindComplete = (state: RootState) => state.calculator.isWindComplete;
  const selectIsRiseRun = (state: RootState) => state.calculator.windRatingState.isRiseRun;
  const selectHasParapet = (state: RootState) => state.calculator.windRatingState.hasParapet;

  // values from selectors
  const surfaceRoughness = useSelector(selectSurfaceRoughness);
  const windSpeed = useSelector(selectWindSpeed);
  const enclosureClassification = useSelector(selectEnclosureClassification);
  const cycloneProne = useSelector(selectCycloneProne);
  const roofAreaHeight = useSelector(selectRoofAreaHeight);
  const roofAreaLength = useSelector(selectRoofAreaLength);
  const roofAreaSlope = useSelector(selectRoofAreaSlope);
  const roofAreaWidth = useSelector(selectRoofAreaWidth);
  const isWindComplete = useSelector(selectIsWindComplete);
  const isRiseRun = useSelector(selectIsRiseRun);
  const hasParapet = useSelector(selectHasParapet);


  useEffect(() => {
    if (roofAreaHeight && roofAreaLength && roofAreaSlope && roofAreaWidth && enclosureClassification.value != -1 && windSpeed && surfaceRoughness.value != -1) {
      dispatch(setIsWindComplete(true))
    } else {
      if (isWindComplete) dispatch(setIsWindComplete(false))
    }
  }, [roofAreaHeight, roofAreaLength, roofAreaSlope, roofAreaWidth, enclosureClassification, windSpeed, surfaceRoughness]);

  const { data, loading, error } = useGetApprovalsDocByCategoryIdQuery({
    variables: { ApprovalsDocCategoryId: 4 },
  });
  useEffect(() => {
    if (error) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [error])

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Loading />
      </div>
    );
  }

  const mapList = data?.ApprovalsDoc;

  // Options format needs to be compatible with ISelectValue type used in select component
  const regionMapOptions = [];
  if (mapList) {
    for (let i = 0; i < mapList.length; i++) {
      regionMapOptions.push({
        value: mapList[i].ApprovalsDocId,
        name: mapList[i].DocName,
        incompatible: false,
      });
    }
  }

  const showModal = (selectedIndex: number, dataToMap?: any) => {
    if (selectedIndex === 0) {
      setDialog(
        <Dialog label={'Surface Roughness'}>
          <SurfaceRoughnessComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
    if (selectedIndex === 9999) {
      setDialog(
        <Dialog label={'Enclosure Classification'}>
          <EnclosureClassificationComponent />
        </Dialog>
      );
      setShowDialog(true);
    }
  };
  const validateStep = () => {
    if (isWindComplete) {
      setErrorCheck(false);
      dispatch(nextSection());
    } else {
      setErrorCheck(true);

    }
  };

  const WindCheckbox = styled(Checkbox)({
    '&.MuiCheckbox-root': {
      color: theme.palette.rnSteel.steel700,
    }
  });
  const handleSlopeChange = () => {
    dispatch(setIsRiseRun(!isRiseRun));
    dispatch(setRoofAreaSlope(''));
  }

  return (
    <div className={'rn-wind-calc px-4'}>
      <Typography
        className={'headline-1 pt-5 pb-3'}

        color={theme.palette.rnSteel.steel700}
      >
        Wind Ratings
      </Typography>
      <Typography className={'large-para pb-4'} >
        {firstWindText}
        <Typography
          className={'d-inline-block'}
          fontFamily={'Inter'}
          fontWeight={700}
          fontSize={18}
          color={theme.palette.rnSteel.steel700}
        >
          DS 1-28
        </Typography>
        &nbsp;for further guidance and how to calculate ratings if using other values.
      </Typography>
      <Typography className={'large-para pb-3'} >
        {secondWindText}
      </Typography>
      <Typography className={'large-para pb-3'}>
        {thirdWindText}
      </Typography>
      <List className={'pb-3'}>
        <ListItem
          sx={{
            listStyleType: 'disc',
            display: 'list-item',
            listStylePosition: 'inside',
            fontWeight: '400',
            fontSize: '18px',
            fontFamily: 'Inter',
          }}
        >
          Determine the rating for each Zone and select an assembly meeting the criteria for each roof area.
        </ListItem>
        <ListItem
          sx={{
            listStyleType: 'disc',
            display: 'list-item',
            listStylePosition: 'inside',
            fontWeight: '400',
            fontSize: '18px',
            fontFamily: 'Inter',
          }}
        >
          Determine the Zone 1 rating and select an assembly meeting that criteria, followed by prescriptive
          enhancements for other zones in accordance with the applicable DS (1-28, 1-29, and/or 1-31).
        </ListItem>
      </List>
      <Typography className={'headline-5 pb-3'}>
        All fields below except Wind Map Region are required. <span className="roofnav-error">*</span>
      </Typography>


      <div className={''}>
        <div className={'pb-4'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            Surface Roughness Exposure
          </Typography>
          <div className={'row m-0 d-flex align-items-center'}>
            <div className={'d-flex align-items-start'}>
              <ButtonGroupComponent className={'pb-2'}>
                <ToggleButtonComponent
                  id={'surfaceRoughnessToggle'}
                  options={SURFACE_ROUGHNESS}
                  handleSelect={(value) => dispatch(setSurfaceRoughness(value))}
                  selectedOption={surfaceRoughness}
                ></ToggleButtonComponent>
              </ButtonGroupComponent>
              <span className="pl-1 roofnav-error">*</span>
            </div>
            <ToolTipComponent
              testId={'surfaceRoughnessInfo'}
              position='right'
              content={'Click for more information'}
              click={() => showModal(0)}
            />
          </div>
          {(errorCheck && surfaceRoughness.value === -1) &&
            <Typography className={'small-para roofnav-error pt-1'}>
              Please submit a value for Surface Roughness Exposure
            </Typography>}

        </div>
        <div className={'pb-4'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            Wind Speed
          </Typography>
          <Typography className={'standard-para pb-1'}>
            Select the appropriate map below to assist you in determining the basic wind speed for your location. Wind maps
            provide the minimum acceptable wind design criteria.
          </Typography>
          <Typography className={'standard-para pb-1'}>
            For more information and a table of wind speeds for enhanced and optimum designs based on storm category, see
            section 2.1 of DS 1-28.
          </Typography>
          <Typography className={'headline-6'}>
            Wind Map Region:
          </Typography>
          <div className={' pb-3 pr-2 m-0 row align-items-end'}>
            <SelectComponent
              id={`windMapSelection`}
              className="as-2 wind-select mr-2"
              options={regionMapOptions}
              setValue={(value) => setSelectedMap(value)}
              value={selectedMap}
            />

            <ButtonComponent
              id={'mapSelectionButton'}
              buttonType="primary"
              buttonText="Go"
              click={() => {
                if (selectedMap.length > 0) window.open(`/loaddocument/${selectedMap}`, 'windmap');
              }}
            />
          </div>
          <div className={'pb-3'}>
            <Typography className={'headline-6'}>
              Wind Speed:
            </Typography>
            <div className={'row p-0 m-0 d-flex align-items-center col-4'}>
              <div className={'d-flex align-items-start'}>
                <SelectComponent
                  id={`windSpeedSelection`}
                  className="as-2 wind-select"
                  options={windSpeedData}
                  setValue={(value) => dispatch(setWindSpeed(value))}
                  value={windSpeed}
                />
                <span className="pl-1 roofnav-error">*</span>
              </div>
            </div>
            {(errorCheck && !windSpeed) &&
              <Typography className={'small-para roofnav-error pt-1'}>
                Please submit a value for Wind Speed
              </Typography>}
          </div>
          <Typography className={'standard-para'}>
            For Puerto Rico, use 175 mph (78 m/s); and for Guam, use 170 mph (76 m/s).
          </Typography>
        </div>

        <div className={'pb-4'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            Enclosure Classification
          </Typography>
          <div className={'row m-0 d-flex align-items-center'}>
            <div className={'d-flex align-items-start'}>
              <ButtonGroupComponent className={'pb-2'}>
                <ToggleButtonComponent
                  options={ENCLOSURE_CLASSIFICATION}
                  handleSelect={(value) => dispatch(setEnclosureClassification(value))}
                  selectedOption={enclosureClassification}
                ></ToggleButtonComponent>
              </ButtonGroupComponent>
              <span className="pl-1 roofnav-error">*</span>
            </div>
            <ToolTipComponent testId={"enclosureClassificationInfo"} position='right' content={'Click for more information'} click={() => showModal(9999)} />
          </div>
          {(errorCheck && enclosureClassification.value === -1) &&
            <Typography className={'small-para roofnav-error pt-1'}>
              Please submit a value for Enclosure Classification
            </Typography>}

        </div>
        <div>
          <Typography
            color={theme.palette.rnSteel.steel700}
            className={'headline-3 pb-2'}
          >
            Property Location
          </Typography>
          <div>
            <div className={'col-6 col-sm-12 m-0 p-0'}>
              <FormGroup >
                <FormControlLabel
                  control={
                    <WindCheckbox
                      disableFocusRipple
                      disableRipple
                      id={'cycloneProneCheckbox'}
                      checked={cycloneProne}
                      onChange={(event) => dispatch(setCycloneProne(event.target.checked))}
                    />}
                  label="Is Location in a Tropical Cyclone Prone Region?"
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className={'pb-2'}>
          <Typography
            color={theme.palette.rnSteel.steel700}
            className={'headline-3 pb-2'}
          >
            Roof Area Dimensions
          </Typography>
          <div>
            <Typography className={'headline-6 py-2'}>
              Width (ft): <span className="roofnav-error">*</span>
            </Typography>
            <TextField
              id={'roofAreaWidthInput'}
              value={roofAreaWidth}
              error={(errorCheck && roofAreaHeight.length == 0)}
              onChange={(event) => dispatch(setRoofAreaWidth(event.target.value.replace(/\D/g, "")))} />
            {(errorCheck && roofAreaWidth.length == 0) &&
              <Typography className={'small-para roofnav-error pt-1'}>
                Please submit a value for Roof Area Width
              </Typography>}

          </div>
          <div>
            <Typography className={'headline-6 py-2'}>
              Length (ft): <span className="roofnav-error">*</span>
            </Typography>
            <TextField
              id={'roofAreaLengthInput'}
              value={roofAreaLength}
              error={(errorCheck && roofAreaLength.length == 0)}
              onChange={(event) => dispatch(setRoofAreaLength(event.target.value.replace(/\D/g, "")))}
            />
            {(errorCheck && roofAreaLength.length == 0) &&
              <Typography className={'small-para roofnav-error pt-1'}>
                Please submit a value for Roof Area Length
              </Typography>}
          </div>
          <div>
            <Typography className={'headline-6 py-2'}>
              Height (ft): <span className="roofnav-error">*</span>
            </Typography>
            <TextField
              id={'roofAreaHeightInput'}
              value={roofAreaHeight}
              error={(errorCheck && roofAreaHeight.length == 0)}
              onChange={(event) => dispatch(setRoofAreaHeight(event.target.value.replace(/\D/g, "")))}
            />
            {(errorCheck && roofAreaHeight.length == 0) &&
              <Typography className={'small-para roofnav-error pt-1'}>
                Please submit a value for Roof Area Height
              </Typography>}

          </div>
          <div>

            <Typography className={'headline-6 py-2'}>
              Slope: <span className="roofnav-error">*</span>
            </Typography>
            <div className={'row m-0 d-flex align-items-center'}>
              <SelectComponent
                id={'roofAreaSlopeInput'}
                className="as-2 "
                options={isRiseRun ? SLOPE_RISE : SLOPE_DEGREE}
                setValue={(value) => dispatch(setRoofAreaSlope(value))}
                value={roofAreaSlope}
              />
              <ToolTipComponent testId={'slopeInfo'} position='right' content={'Select the rise for the slope (rise over 12 units of run).'} />
            </div>
            {(errorCheck && roofAreaSlope.length == 0) &&
              <Typography className={'small-para roofnav-error pt-1'}>
                Please select a value for Roof Area Slope
              </Typography>}
            <SwitchComponent checked={isRiseRun} id={'windSlopeToggle'} handleChange={handleSlopeChange} label={isRiseRun ? 'Rise over run (in)' : 'Degree'} />
          </div>
        </div>
        <div className={'col-6 col-sm-12 m-0 p-0'}>
          <FormGroup >
            <FormControlLabel
              control={
                <WindCheckbox
                  disableFocusRipple
                  disableRipple
                  id={'parapetCheckbox'}
                  checked={hasParapet}
                  onChange={(event) => dispatch(setHasParapet(event.target.checked))}
                />}
              label="Has minimum 3 ft (1 m) continuous parapet?"
            />
          </FormGroup>
        </div>
      </div>
      <div className={'row m-0 py-3'}>
        <ButtonComponent
          id='previousSection'
          buttonType={'secondary'}
          buttonText={'Previous'}
          click={() => dispatch(previousSection())}
          buttonStyle={'ml-0'}
        />
        <div className={'mx-1'}>&nbsp;</div>
        <ButtonComponent
          id='nextSection'
          buttonType={'primary'}
          buttonText={'Next'}
          click={validateStep}
          buttonStyle={''}
        />
      </div>
    </div>
  );
};
