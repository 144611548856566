import { Autocomplete, AutocompleteRenderInputParams, styled, TextField, Typography } from '@mui/material';
import React, { HTMLAttributes } from 'react';
import theme from '../../../theme';

interface IAutocompleteComponentProps extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  options: IAutocompleteValue[];
  label?: string;
  defaultValue: string;
  setCallback: (option: IAutocompleteValue | null) => void;
}
export interface IAutocompleteValue {
  value?: string | number;
  name: string | undefined;
  incompatible?: boolean;
}
const StyledAutocomplete = styled(Autocomplete)({
  '&.Mui-focused .MuiOutlinedInput-notchedOutline  ': {
    borderColor: `${theme.palette.rnSteel.steel700} !important`,
  },
});

const AutoTextField = styled(TextField)({
  '&.MuiTextField-root': {
    borderRadius: '6px',
    background: 'white',
    width: '100%',
    fontWeight: '700',
  },
});

export const AutocompleteComponent = ({
  id,
  options,
  label,
  defaultValue,
  setCallback,
}: IAutocompleteComponentProps) => {
  const updateSelectedOption = (option: IAutocompleteValue) => {
    defaultValue = option !== null || undefined ? String(option.name) : '';
    setValue(option !== null || undefined ? String(option.name) : '');
    setCallback(option);
  };
  const [value, setValue] = React.useState(defaultValue);
  const [inputValue, setInputValue] = React.useState(defaultValue ? defaultValue : 'input value');
  // div instead of fragment- bypasses issue with flexbox
  return (
    <div className="w-100">
      {label && (
        <div>
          <label htmlFor={id}>
            <Typography fontSize={'14px'} fontWeight={400} fontFamily={'Inter'}>
              {label}
            </Typography>
          </label>
        </div>
      )}
      <StyledAutocomplete
        id={id}
        //value={value}
        className={'w-100'}
        options={options}
        getOptionDisabled={(option) => !!(option as IAutocompleteValue).incompatible}
        getOptionLabel={(option) => {
          return (option as IAutocompleteValue).name ? String((option as IAutocompleteValue).name) : '';
        }}
        value={options?.find((data) => data.name === value)}
        onChange={(event: any, value) => {
          updateSelectedOption(value as IAutocompleteValue);
        }}
        inputValue={inputValue ? inputValue : ''}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <span>
            <AutoTextField
              {...params}
              size={'small'}
              value={value ? value : ''}
              placeholder={defaultValue ? defaultValue : ''}
              variant="outlined"
            />
          </span>
        )}
        renderOption={(props, option) => (
          <li {...props} key={(option as IAutocompleteValue).value}>
            {(option as IAutocompleteValue).name}
          </li>
        )}
      />
    </div>
  );
};
