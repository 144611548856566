import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ISelectValue } from '../components/shared/SelectComponent/SelectBox';
import { IRoofZoneResult } from '../components/Calculator/CalculatorSummary/SummaryComponent';
import { INote, IR_RoofAreaNote, IR_RoofAreaScenario, IRoofArea, IRoofAreaContact, IRoofProject, IScenario, IScenarioStatus, IScenarioType } from '../types/interfaces';

const note: INote[] = [];
const roofAreaData: IRoofArea[] = [];
const roofAreaContact: IRoofAreaContact[] = [];
const roofAreaNote: IR_RoofAreaNote[] = [];
const project: IRoofProject[] = [];
const scenarios: IScenario[] = [];
const scenarioStatus: IScenarioStatus[] = [];
const scenarioType: IScenarioType[] = [];


const initialState = {
    note: note,
    project: project,
    roofArea: roofAreaData,
    roofAreaContact: roofAreaContact,
    roofAreaNote: roofAreaNote,
    scenarios: scenarios,
    scenarioStatus: scenarioStatus,
    scenarioType: scenarioType,
};

export const projectSlice = createSlice({
    name: 'projects',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // removing/adding project are accounted for here- this will update state after CRUD operations take place
        setProject: (state, action: PayloadAction<IRoofProject[]>) => {
            state.project = action.payload
        },
        setNote: (state, action: PayloadAction<INote[]>) => {
            state.note = action.payload
        },
        setRoofArea: (state, action: PayloadAction<IRoofArea[]>) => {
            state.roofArea = action.payload
        },
        setRoofAreaContact: (state, action: PayloadAction<IRoofAreaContact[]>) => {
            state.roofAreaContact = action.payload
        },
        setScenarios: (state, action: PayloadAction<IScenario[]>) => {
            state.scenarios = action.payload
        },
        setScenarioStatus: (state, action: PayloadAction<IScenarioStatus[]>) => {
            state.scenarioStatus = action.payload
        },
        setScenarioType: (state, action: PayloadAction<IScenarioType[]>) => {
            state.scenarioType = action.payload
        },
    },

});


export const {
    setProject, setNote, setRoofArea, setRoofAreaContact, setScenarios, setScenarioStatus, setScenarioType
} = projectSlice.actions
export default projectSlice.reducer;
