import React, { HTMLAttributes, useEffect, useRef } from "react";
import { MDCSelect } from "@material/select";
import { ConfigurationValue, SingletonValue } from "../../types/configurator";
import { ChipComponent } from "./ChipComponent/ChipComponent";
import './MultiSelectBox.scss';
import { Typography } from "@mui/material";
import theme from "../../theme";

interface IMultiSelectBox extends HTMLAttributes<HTMLDivElement> {
    id?: string;
    label?: string;
    disabled?: boolean;
    options: ConfigurationValue[];
    select: (variableId: string, value: string) => void;
    unSelect: (variableId: string, value?: string) => void;
}

export const MultiSelectBox = (props: IMultiSelectBox) => {
    const { id, label, disabled = false, options, select, unSelect, className, ...other } = props;
    const ref = useRef<HTMLDivElement>(null);
    let mdcSelect: MDCSelect;

    useEffect(() => {
        if (ref.current) {
            if (!mdcSelect) {
                mdcSelect = new MDCSelect(ref.current);
                mdcSelect.setValue('Select');
            }
        }
    });
    const selectedOptions: SingletonValue[] = options.filter(option => option.assigned);

    return (
        <>
            <div className={`multi-select-box form-group ${selectedOptions.length ? 'mb-2' : 'mb-0'}`}>
                <div className="d-flex align-items-center">
                    <div data-id={id} ref={ref} className={`mdc-select mdc-select--outlined ${disabled ? 'mdc-select--disabled' : ''} ${className}`} {...other}>
                        <div className="mdc-select__anchor select-box" aria-labelledby="outlined-select-label" tabIndex={0} aria-disabled={disabled}>
                            <span className="mdc-notched-outline mdc-notched-outline--upgraded mdc-notched-outline--notched">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                    {label && <span id="outlined-select-label" className="mdc-floating-label mdc-floating-label--float-above">
                                        <Typography
                                            color={theme.palette.rnSteel.steel700}
                                            fontSize={'14px'}
                                            lineHeight={'20px'}
                                            fontWeight={600}>
                                            {label}
                                        </Typography>
                                    </span>}
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                            </span>
                            <span className="mdc-select__selected-text-container">
                                <span id="demo-selected-text" className="mdc-select__selected-text">Select</span>
                            </span>
                            <span className="mdc-select__dropdown-icon">
                                <svg className="mdc-select__dropdown-icon-graphic" viewBox="7 10 10 5" focusable="false">
                                    <polygon className="mdc-select__dropdown-icon-inactive" stroke="none" fillRule="evenodd" points="7 10 12 15 17 10">
                                    </polygon>
                                    <polygon className="mdc-select__dropdown-icon-active" stroke="none" fillRule="evenodd" points="7 15 12 10 17 15">
                                    </polygon>
                                </svg>
                            </span>
                        </div>
                        <div className={`mdc-select__menu mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth`}>
                            <ul className="mdc-list" role="listbox" aria-label="Food picker listbox">
                                <li className="mdc-list-item mdc-list-item--selected" aria-selected="true"
                                    data-value="Select" role="option" tabIndex={-1}
                                    onClick={() => {
                                        const id = ref.current?.getAttribute('data-id');
                                        if (id && selectedOptions.length) unSelect(id);
                                    }}>
                                    <span className="mdc-list-item__ripple"></span>
                                    <span className="mdc-list-item__text">Select</span>
                                </li>
                                {options.map((option: SingletonValue, index) => {
                                    const { assigned, name, value, incompatible } = option;
                                    return (
                                        <li className={`mdc-list-item ${assigned ? 'mdc-list-item--selected' : ''} ${incompatible ? 'mdc-list-item--incompatible' : ''}`}
                                            aria-selected={assigned ? true : false} data-value={value} role="option"
                                            tabIndex={-1} key={index}
                                            onClick={() => {
                                                const id = ref.current?.getAttribute('data-id');
                                                if (id && value) {
                                                    if (!selectedOptions.length || !selectedOptions.find(opt => opt.value === value))
                                                        select(id, String(value));
                                                }
                                            }}>
                                            <span className="mdc-list-item__ripple"></span>
                                            <span className="mdc-list-item__text">{name}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="text-center"></div>
                </div>
            </div>
            {selectedOptions.map(selectedOption => <div key={selectedOption.value} className="pl-4 mx-1">
                <div className="p-1">
                    <ChipComponent
                        label={selectedOption?.name}
                        deleteChip={() => {
                            const id = ref.current?.getAttribute('data-id');
                            if (id && selectedOptions.length) unSelect(id, String(selectedOption.value));
                        }} />
                </div>
            </div>)}
        </>
    );
};