export enum ApprovedUse {
  AdhesiveFullApplication = 1,
  AdhesiveRibbons = 2,
  AdhesiveSpots = 3,
  AdhesiveSystem = 56,
  AirRetarder = 4,
  Anchor = 83,
  BaseSheet = 5,
  Coating = 6,
  CoatingSystem = 63,
  Coping = 7,
  CoverCompositePanel = 8,
  CoverLiquidAppliedSubassembly = 68,
  CoverMultiplySubassembly = 69,
  CoverSingleply = 9,
  CoverStandingLapSeam = 10,
  CoverBoard = 11,
  CoverStripDisk = 12,
  CoverLiquidAppliedBaseCoat = 59,
  CoverLiquidAppliedCoat = 57,
  CoverLiquidAppliedTopCoat = 58,
  CoverMultiplyBasePly = 16,
  CoverMultiplyCapPly = 17,
  CoverMultiplyPly = 18,
  CoverShingles = 82,
  DeckCementitiousPanel = 19,
  DeckFiberReinforcedPlastic = 20,
  DeckSteel = 23,
  DeckTreatedWood = 24,
  ExpansionJoint = 25,
  Fascia = 26,
  FasteningBattenBar = 27,
  FasteningClip = 28,
  FasteningFastener = 29,
  FasteningPreassembledFastening = 30,
  FasteningStressPlate = 32,
  FasteningSystemBattenBar = 65,
  FasteningSystemClip = 66,
  FasteningSystemStressPlate = 67,
  FormDeck = 33,
  Gutter = 34,
  InsulationBattBlanket = 35,
  InsulationBoardStock = 36,
  InsulationSprayFoam = 38,
  LapSealant = 60,
  LightweightInsulatingConcrete = 22,
  LightweightInsulatingConcreteSubassembly = 81,
  LinerPanel = 40,
  MaintenanceCoating = 41,
  Reglet = 42,
  SeamPrimer = 43,
  SeamTapeSystem = 80,
  SeamWash = 61,
  SeparatorSheet = 45,
  SubstratePrimer = 46,
  Surfacing = 47,
  ThermalBarrier = 48,
  VaporRetarder = 49,
  VaporRetarderSystem = 62,
  WeldArc = 51,
  WeldHeat = 52,
  WeldSolvent = 53,
  WeldTorched = 54,
  WeldSystem = 64,
}

export enum MeasureType {
  SolidApplicationRate = 1,
  Area = 2,
  Coverage = 3,
  Density = 4,
  Force = 5,
  Length = 6,
  Mass = 7,
  MassPerUnitArea = 8,
  Pressure = 9,
  Volume = 10,
  VolumePerUnitArea = 11,
  Strength = 12,
}

export enum LayerType {
  Cover__Composite_Panel__1 = 1,
  Cover__Liquid_Applied__2 = 2,
  Cover__Multi_ply__3 = 3,
  Cover__Single_ply__4 = 4,
  Cover__Standing_Lap_Seam__5 = 5,
  Base_Coat_6 = 6,
  Coat_7 = 7,
  Top_Coat_8 = 8,
  Base_Ply_9 = 9,
  Cap_Ply_10 = 10,
  Ply_11 = 11,
  _Deck__Cementitious_Wood_Fiber_12 = 12,
  _Deck__Fiber_Reinforced_Cement_13 = 13,
  _Deck__Fiber_Reinforced_Plastic_14 = 14,
  Lightweight_Insulating_Concrete_Subassembly_16 = 16,
  _Deck__Steel_17 = 17,
  _Deck__Structural_Concrete_18 = 18,
  _Deck__Treated_Wood_19 = 19,
  ___DO_NOT_USE____LA__20 = 20,
  Air_Retarder_21 = 21,
  Base_Sheet_22 = 22,
  Coating_Surfacing_23 = 23,
  Cover_Board_24 = 24,
  Existing_Roof_25 = 25,
  Form_Deck_26 = 26,
  Insulation__Batt_Blanket__27 = 27,
  Insulation__Board_Stock__28 = 28,
  Insulation__Spray_Foam__29 = 29,
  Lightweight_Insulating_Concrete_30 = 30,
  Liner_Panel_31 = 31,
  Substrate_Primer_33 = 33,
  Separator_Sheet_34 = 34,
  Structure_35 = 35,
  Thermal_Barrier_36 = 36,
  Vapor_Retarder_37 = 37,
  Cover_Strip_Disk_38 = 38,
  Securement__Cover__39 = 39,
  Securement__Sheet_Lap__40 = 40,
  Securement__Base_Sheet__41 = 41,
  Securement__Board_Stock__42 = 42,
  Securement__Deck_Lap__43 = 43,
  Securement__Deck__44 = 44,
  Securement_45 = 45,
  Steel_Deck_Specification_50 = 50,
  Self_Securement_51 = 51,
}

export enum SpecialSubCategory {
  Surfacing = 2,
}

export enum SpecialApprovedUse {
  Surfacing = 47,
}
export enum RoofPressureZone {
  Zone1Prime = 4,
  Zone1 = 1,
  Zone2 = 2,
  Zone2E = 5,
  Zone2N = 7,
  Zone2R = 8,
  Zone3 = 3,
  Zone3E = 6,
  Zone3R = 9
}

export enum ScenarioStatus {
  Valid = 1,
  Invalid = 2,
  Orphan = 3,         // Backing Assembly has been deleted
  Delisted = 4,       // Backing Assembly has been delisted
  Reconfigured = 5    // Backing Assembly has been reconfigured
}
