import React from 'react';
import { SolidApplicationRate } from '../../utils/Measurement/SolidApplicationRate';
import { VolumePerUnitArea } from '../../utils/Measurement/VolumePerUnitArea';
import './UsageDetails.scss';
import { useGetAdhesiveSystemUsageDetailQuery } from '../../models/GQLGeneratedModels';

export const AdhesiveSystemUsageDetail = (props: any) => {
  const { data, loading, error } = useGetAdhesiveSystemUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];
  const isMetric = usage?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Adhesive System</h4>
      <table>
        <tr>
          <td>Substrate Primer Rate Wet:</td>
          <td>{VolumePerUnitArea.display(usage?.AdhesiveSystemUsage?.SubstratePrimerRateWet, isMetric)}</td>
        </tr>
        <tr>
          <td>Over Substrate Rate Wet:</td>
          <td>{VolumePerUnitArea.display(usage?.AdhesiveSystemUsage?.OverSubstrateRateWet, isMetric)}</td>
        </tr>
        <tr>
          <td>Underside Rate Wet:</td>
          <td>{VolumePerUnitArea.display(usage?.AdhesiveSystemUsage?.UndersideRateWet, isMetric)}</td>
        </tr>
        <tr>
          <td>Substrate Primer Rate Dry:</td>
          <td>{SolidApplicationRate.display(usage?.AdhesiveSystemUsage?.SubstratePrimerRateDry, isMetric)}</td>
        </tr>
        <tr>
          <td>Over Substrate Rate Dry:</td>
          <td>{SolidApplicationRate.display(usage?.AdhesiveSystemUsage?.OverSubstrateRateDry, isMetric)}</td>
        </tr>
        <tr>
          <td>Underside Rate Dry:</td>
          <td>{SolidApplicationRate.display(usage?.AdhesiveSystemUsage?.UndersideRateDry, isMetric)}</td>
        </tr>
        <tr>
          <td valign="top">System Comments:</td>
          <td>{usage?.Component.Comments}</td>
        </tr>
        <tr>
          <td valign="top">Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
