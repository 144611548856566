import React from 'react';
import { BoardStockRules } from '../../pages/RefMaterial/BoardStockRules';
interface IGlossaryEntry {
    id: string;
    name: string;
    definition: JSX.IntrinsicElements["div"];
}
// todo: ask about board stock rules
// TODO: add in definitions under 'def'
// is exact search needed?
// FlexPhotoVolt- Ps
// perimeter flashing- table
// RFIN- Board Stock Insulation
export const GLOSSARY_ENTRIES: IGlossaryEntry[] = [
    {
        id: 'A1',
        name: 'Adhesives',
        definition:
            <div>
                <p>
                    Adhesives are used in roof construction to adhere roof coverings to roof
                    coverings as in lap construction. They are also used to bond roof coverings to
                    the substrate below or to adhere insulation to the substrate. Depending on the
                    use, the adhesive could be in either a liquid form, semi liquid form of a solid
                    form as in a seam tape or as in hot asphalt which is solid until heated.
                </p>
                <p>FM Approved roofing adhesives for securing roof cover to roof cover, roof cover
                    to insulation, insulation to insulation, or insulation to deck (not steel deck)
                    have met minimum 1-60 Windstorm requirements and consist of the following:</p>
                <p><b>– Hot asphalt, coal tar</b>
                </p>
                <blockquote dir="ltr" >
                    <p>Solid, bitumen adhesives which become liquid when heated and return to a solid
                        state when cooled. Extreme care must be used when working with asphalt or coal
                        tar due to the temperatures needed to maintain them in a liquid state. Only FM
                        Approved materials&nbsp;for use with hot asphalt or coal tar may be used in FM
                        Approved constructions. The use of hot asphalt or coal tar when the air
                        temperature is below 40°F (4°C) requires extreme care to establish a proper
                        bond.
                    </p>
                </blockquote>
                <p><strong>– Solvent-based</strong>
                </p>
                <blockquote dir="ltr">
                    <p>Solvent-based. These adhesives consist of rubber or asphalt-based solids and
                        usually a proprietary solvent which dissipates, leaving the solids to make the
                        bond. The use of these adhesives when the air temperature is below 40°F (4°C)
                        requires extreme care to provide a proper bond. The adhesive must not be
                        diluted and must be mixed thoroughly before use.</p>
                </blockquote>
                <p><strong>– Asphalt blend</strong>
                </p>
                <blockquote >
                    <p>These adhesives consist of asphalt which has been blended with other components
                        and usually do not need mixing or any additional additives prior to use.
                    </p>
                </blockquote>
                <p><strong>– Moisture curing urethane foam</strong>&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <blockquote dir="ltr" >
                    <p>These adhesives are applied from small pressurized cylinders or large truck
                        mounted equipment, using hoses and wands supplied by the manufacturer.</p>
                </blockquote>
                <p><strong>– Water-based</strong>
                </p>
                <blockquote dir="ltr" >
                    <p>These adhesives consist of rubber or asphalt-based solids generally suspended in
                        water which dissipates, leaving the solids to make the bond. The use of these
                        adhesives when the air temperature is below 32°F (0°C) requires extreme care to
                        provide a proper bond. The adhesive must not be diluted and must be mixed
                        thoroughly before use.</p>
                </blockquote>

            </div>
    },
    {
        id: 'A2',
        name: 'Aggregate',
        definition:
            <div>
                <p>
                    Aggregate is an inert granular material consisting of sand, gravel,
                    vermiculite, perlit, or slag which when bound together into a conglomerate mass
                    by a matrix forms concrete.
                </p>
            </div>
    },
    {
        id: 'A3',
        name: 'Air Retarder',
        definition:
            <div>
                <p>
                    An air retarder is a layer or layers of material or a laminate used to reduce
                    the flow of air into a roof assembly.</p>
                <p>See Retarders - Air and Vapor.</p>
            </div>
    },
    {
        id: 'A4',
        name: 'Anchors for Roof Mounted Equipment',
        definition:
            <div>
                <p>
                    Devices intended to secure roof mounted equipment, except Photovoltaic (PV) panels, to a substrate unless separately Approved for PV securement.
                    Devices for securing PV panels are included within assemblies which utilize PV panels.
                </p>
            </div>
    },
    {
        id: 'A5',
        name: 'Application Rate',
        definition:
            <div>
                <p>
                    The application rate is the average quantity (mass, volume, or thickness) of
                    material applied per unit area.
                </p>
            </div>
    },
    {
        id: 'A6',
        name: 'Asphalt',
        definition:
            <div>
                <p>
                    Asphalt is a dark brown or black substance found in a natural state or, more
                    commonly, left as a residue after evaporationg or otherwise processing crude
                    oil or petroleum.&nbsp; Asphalt may be further refined to conform to various
                    roofing grade specifications:&nbsp; Dead-Level Asphalt: a roofing asphalt
                    conforming to the requirements of ASTM Specification D 312 Type I.&nbsp; Flat
                    Asphalt: a roofing asphalt conforming to the requirements of ASTM Specification
                    D 312, Type II.&nbsp; Steep Asphalt: a roofing asphalt conforming to the
                    requirements of ASTM Specifications D312, Type III.&nbsp; Special Steep
                    Asphalt: a roofing asphalt conforming to the requirements of ASTM Specification
                    D 312, Type IV.</p>
            </div>
    },
    {
        id: 'A7',
        name: 'Assembly',
        definition:
            <div>
                <p>
                    An assembly is a set of roofing products that, when installed together, meet specific FM Approvals performance criteria.
                    The assembly contains all of the product substitutions that may be used in each layer. Fully adhered single ply assemblies may contain multiple covers and adhesive which are not Approved for use together.
                    After selecting a cover, only Approved adhesives are available for selection. For further information and guidance refer to the training video link on fully adhered assemblies.
                </p>
            </div>
    },
    {
        id: 'A8',
        name: 'Assembly Number',
        definition:
            <div>
                <p>
                    Assembly Number is a unique number assigned to each RoofNav assembly. &nbsp;A
                    valid assembly number consists of three numbers separated by hyphens. &nbsp;The
                    number of digits in each section may vary as in the following examples:
                    1-234-5; 123-45678-9; 123456-1-12345; etc.
                </p>
            </div>
    },

    {
        id: 'B1',
        name: 'Base Coat',
        definition:
            <div>
                <p>
                    The base coat is the bottom or initial application of liquid applied to the
                    substrate or primer when building liquid applied roof covers.</p>
            </div>
    },
    {
        id: 'B2',
        name: 'Base Ply',
        definition:
            <div>
                <p>
                    A base ply&nbsp;is the bottom or first ply in a built-up roof membrane when
                    additional plies are to be subsequently installed.&nbsp;&nbsp;It differs from a
                    base sheet in that a base ply is only used within a multi-ply
                    cover.&nbsp;&nbsp;A base&nbsp;ply&nbsp;can not be mechanically attached.</p>
            </div>
    },
    {
        id: 'B3',
        name: 'Base Sheet',
        definition:
            <div>
                <p>
                    A base sheet is an asphalt-impregnated or coated felt&nbsp;that is
                    often&nbsp;used&nbsp;below a built-up or modified bitumen&nbsp;roof
                    membrane&nbsp;to&nbsp;mechanically fasten or spot attach the cover to the roof
                    deck or substrate.&nbsp; It can also be used as a base layer to which other
                    roofing materials are adhered, e.g. rigid board stock insulation.</p>
            </div>
    },
    {
        id: 'B4',
        name: 'Batt & Blanket',
        definition:
            <div>
                <p>
                    Batt and Blanket insulation is glass fiber or other
                    compressible fibrous insulation, generally available in roll form.
                </p>
            </div>
    },
    {
        id: 'B5',
        name: 'Batten Bar',
        definition:
            <div>
                <p>
                    A batten bar is a metal bar or plastic strip which is used in conjunction with
                    fasteners to secure roof covers in place.
                </p>
            </div>
    },
    {
        id: 'B6',
        name: 'Bitumen',
        definition:
            <div>
                <p>
                    Bitumen is a class of amorphous black or dark colored (solid, semi-solid, or
                    viscous) cementitious substances, natural or manufactured, composed principally
                    of high molecular weight hydrocarbons, soluble in carbon disulfide, and found
                    in petroleum asphalts, coal tars and pitches, wood tars and asphalts.
                </p>
            </div>
    },
    {
        id: 'B7',
        name: 'Board Stock Insulation',
        definition:
            <div>
                <p>A board stock insulation has a core of one of the following materials:&nbsp;
                    APA-rated OSB; asphalt/glass; glass-based; gypsum; mineral wool; perlite;
                    polyisocyanurate/polyurethane; expanded polystyrene foam (EPS); extruded
                    polystyrene foam (XPS); or wood fiber.&nbsp; The material, the number of board
                    stock layers,&nbsp;and the position of the product within the assembly
                    determine whether&nbsp;it will be classified as an insulation, a cover board,
                    or a thermal barrier.&nbsp;
                </p>
                <p>For&nbsp;more information about how insulation layers within assemblies are
                    classified in RoofNav, click <a className='glossary-link' id="SD" href="boardstockrules" target='_blank'>
                        here</a></p>
            </div>
    },
    {
        id: 'B8',
        name: 'Built-Up Roof',
        definition:
            <div>
                <p>
                    A built-up roof (BUR) is a continuous semi-flexible roof membrane consisting of
                    multiple plies of saturated felts, coated felts, fabrics, or mats assembled in
                    place with alternate layers of bitumen.&nbsp; An FM Approved BUR shall
                    be&nbsp;surfaced with mineral aggregate, bituminous materials, a liquid-applied
                    coating, or a granule-surfaced cap sheet.
                </p>
            </div>
    },
    {
        id: 'C1',
        name: 'Cap Ply',
        definition:
            <div>
                <p>
                    A cap ply layer in a multi-ply assembly cover contains cap sheet products.
                </p>
            </div>
    },
    {
        id: 'C2',
        name: 'Cap Sheet',
        definition:
            <div>
                <p>
                    A cap sheet is an asphalt-impregnated or coated felt, often granule-surfaced,
                    used as the top ply of some built-up or modified bitumen roofs.
                </p>
            </div>
    },
    {
        id: 'C3',
        name: 'Cleat',
        definition:
            <div>
                <p>
                    A cleat is a continuous metal strip used to secure two or more metal roof
                    components together.
                </p>
            </div>
    },
    {
        id: 'C4',
        name: 'Clip',
        definition:
            <div>
                <p>
                    A clip is a non-continuous metal component which is mechanically fastened to the
                    deck or structure and used to secure two or more metal roof components
                    together.&nbsp; These are used primarily to anchor the seams of standing seam
                    roof panels to the deck or structure below.
                </p>
            </div>
    },
    {
        id: 'C5',
        name: 'Coal Tar Bitumen',
        definition:
            <div>
                <p>
                    Coal tar bitumen is a proprietary trade name for Type III coal tar used
                    as the dampproofing or waterproofing agent in dead-level or low-slope
                    built-up roof membranes and membrane waterproofing systems, conforming
                    to ASTM D 450, Type III.
                </p>
            </div>
    },
    {
        id: 'C6',
        name: 'Coating',
        definition:
            <div>
                <p>
                    A coating is a layer of liquid material applied to a surface for protection or
                    appearance.
                </p>
            </div>
    },
    {
        id: 'C7',
        name: 'Compressive Strength',
        definition:
            <div>
                <p>
                    Compressive strength is the property of a material that relates to its
                    ability to resist compression loads.
                </p>
            </div>
    },
    {
        id: 'C8',
        name: 'Configuration (Multi-Ply Roof Cover)',
        definition:
            <div>
                <p>
                    Multi-Ply Roof Cover configurations are described in RoofNav using the
                    following abbreviations:
                </p>
                <blockquote dir="ltr">
                    <p>B#-P#-C#
                    </p>
                    <p>B# is the number of <a id="B1" className='glossary-link' href="#B2">
                        base plies</a> (either zero or 1)</p>
                    <p>P# is the number of <a id="P1" className='glossary-link' href="#P5">
                        ply sheets</a>&nbsp;required in the ply layer.&nbsp; If more than one
                        number is displayed, more than one ply layer&nbsp;exists in the assembly, each
                        one requiring a separate product selection.&nbsp; For example, an assembly
                        described as B1-P1-P1-C1 contains four plies and four layers.&nbsp; Two
                        different ply sheet products are used.&nbsp; An assembly described as B1-P2-C1
                        also contains four plies, but only one&nbsp;product&nbsp;is used&nbsp;for the
                        ply layer.&nbsp;</p>
                    <p>C# is the number of <a id="C1" className='glossary-link' href="#C2">
                        cap sheets</a> (either zero or 1)</p>
                </blockquote>
            </div>
    },
    {
        id: 'C9',
        name: 'Contractor Package',
        definition:
            <div>
                <p>
                    The Contractor Package is a detailed description of the building
                    configuration, location, parties involved, and the FM Approved
                    <a id="CP1" className='glossary-link' href="#A6"> assembly </a>
                    as it is to be installed on that particular project.
                </p>
            </div>
    },
    {
        id: 'C10',
        name: 'Cover Board',
        definition:
            <div>
                <p>
                    A cover board is a board&nbsp;stock&nbsp;product used over insulation.&nbsp; A
                    cover board is only applied as the <b>top</b> layer of a multilayer
                    insulation assembly.&nbsp;&nbsp;A cover board must be made of an appropriate
                    material as follows:&nbsp; a core or top facer material of APA-rated OSB;
                    asphalt/glass; glass-based; gypsum; mineral wool; perlite; polyisocyanurate/polyurethane, or wood fiber.&nbsp;</p>
                <p>
                    For&nbsp;more information about how insulation layers within assemblies are
                    classified in RoofNav, click <a id="SD" className='glossary-link' href="boardstockrules" target='_blank'>
                        here</a></p>
            </div>
    },
    {
        id: 'C11',
        name: 'Composite Panel Roof Cover',
        definition:
            <div>
                <p>FM Approved composite panel roof coverings are Class 1 if the arrangement of materials
                    is such that they produce a product which will satisfy fire, minimum Class 1-60
                    wind uplift, minimum Class 1-MH Hail, and Class C ASTM E 108 criteria.  The structural integrity and
                    fire hazard characteristics are produced by the complete system; therefore, no
                    installation modifications or material substitutions can be made.</p>
                <p>Unless otherwise noted in the assembly, composite panels are
                    installed directly to the supporting members (purlins), and no other
                    deck material is provided.</p>
            </div>
    },
    {
        id: 'C12',
        name: 'Cementitious Panel Roof Deck',
        definition:
            <div>
                <p>
                    Cementitious Panel Roof Deck is a factory fabricated roof deck consisting of fibers (typically wood or fiberglass) and cementitious binder.
                    Panels are supplied in various thickness with tongue and groove or butt side and end joints.
                </p>

            </div>
    },
    {
        id: 'D1',
        name: 'Deck',
        definition:
            <div>
                <p>
                    The deck is the structural component of the roof assembly to which the roof
                    system is secured.
                </p>
            </div>
    },
    {
        id: 'D2',
        name: 'Double Weld',
        definition:
            <div>
                <p>
                    Double Weld describes membrane laps which incorporate 2 welds, with the
                    membrane lap fasteners installed between the welds.
                </p>
            </div>
    },
    {
        id: 'D3',
        name: 'Dust Sheet',
        definition:
            <div>
                <p>
                    A dust sheet is a membrane barrier that is used in a protected membrane roof
                    system to prevent damaging particulates from migrating through the upper
                    insulation layer of the system to the roof cover below the insulation.
                </p>
                <p>
                    A protected membrane roof system is an insulated and ballasted roofing system in
                    which the insulation and ballast are placed on top of the roof cover.&nbsp;
                    This is also referred to on a proprietary basis as an Inverted Roof Membrane
                    Assembly (IRMA).
                </p>
            </div>
    },
    {
        id: 'E1',
        name: 'Embedment',
        definition:
            <div>
                <p>
                    Embedment is (1) the process of pressing/positioning a felt,
                    aggregate, fabric, mat, or panel into hot bitumen or adhesive
                    to ensure intimate contact at all points; (2) the process of
                    pressing/positioning granules into coating in the manufacture
                    of factory-prepared roofing, such as shingles.
                </p>
            </div>
    },
    {
        id: 'E2',
        name: 'EPDM',
        definition:
            <div>
                <p>
                    EPDM&nbsp;stands for ethylene propylene diene monomer.
                </p>
            </div>
    },
    {
        id: 'E3',
        name: 'Exact Search',
        definition:
            <div>
                <p>
                    When performing an Assembly Search, an&nbsp;exact search will locate only
                    assemblies that contain the&nbsp;exact configuration&nbsp;of layers and
                    products you specify with no additional layers between them.&nbsp; However,
                    there may be additional layers above or below the layers specified.&nbsp; In a
                    Cross Section Search, there may be additional layers between the Roof Deck
                    the&nbsp;bottom most&nbsp;layer you specified, between the Roof Cover and the
                    upper most layer you specified, and above the Roof Cover (ex. coatings).
                </p>
            </div>
    },
    {
        id: 'E4',
        name: 'Existing Roof',
        definition:
            <div>
                <p>
                    Existing&nbsp;roof is used to describe recover roof assemblies where all components are fully adhered to or mechanically fastened through the existing roof.
                    Because no fasteners are used, further description of deck type is not required.
                </p>
            </div>
    },
    {
        id: 'F1',
        name: 'Fasteners',
        definition:
            <div>
                <p>A fastener is a mechanical securement device used alone or in combination with a
                    stress distributor to secure various components of a roof assembly.&nbsp;
                    Fasteners are FM Approved for various applications within a completed
                    roof.&nbsp; See below for additional detail.
                </p>
                <p><strong>Fasteners used to Secure Roof Covers and Insulations:</strong>
                </p>
                <blockquote dir="ltr" >
                    <p>Fasteners are FM Approved for use with the decks, plates and/or batten bars
                        indicated. They may be designed with a shank which lodges in the deck or a
                        toggle which opens and lodges against the underside of the deck. Fasteners may
                        be punched (clip type), driven (nail type) or screwed (predrilled or
                        self-drilling type) into the deck and must provide a strong resistance to
                        withdrawal or backout. Special proprietary tools are available to drive some of
                        the fasteners.&nbsp;
                    </p>
                    <p>They utilize a distribution plate, disk or batten bar under the fastener head to
                        distribute the wind uplift force transferred from the roof cover or insulation.
                        The disk, plate or batten bar must prevent the roof cover or insulation from
                        being pulled over the fastener head.&nbsp; A minimum number of fasteners are
                        required for each specific use to resist failure of the completed roof
                        assembly.&nbsp; Care must be used when installing fasteners to insure that they
                        are not overdriven.
                    </p>
                    <p>In assemblies where only two or three fasteners per insulation board
                        are&nbsp;needed to meet uplift requirements, additional consideration (e.g.,
                        additional fastening, taping of joints) may be&nbsp;required to prevent the
                        insulation board from curling under varying field conditions.
                    </p>
                    <p>For Structural Concrete Decks, fasteners are&nbsp;FM Approved&nbsp;when used
                        with 2500 psi (176 kg/cm 2 ) minimum compressive strength concrete. Minimum
                        fastener embedment shall be 1 in. (25 mm) unless otherwise noted.
                    </p>
                    <p>
                        For Steel Deck, fasteners and their listed installation requirements are FM
                        Approved only when used with FM Approved steel deck.&nbsp; FM
                        Approved&nbsp;steel deck fasteners shall be installed in accordance with all FM
                        Approval requirements and manufacturer's specifications.&nbsp;
                    </p>
                    <p>
                        For Cementitious Panel Roof Deck, fasteners are FM Approved only when used
                        with FM Approved Cementitious Panel Roof Decks. Minimum fastener embedment
                        shall be 2 in. (50 mm) for Class 1-75 and Class 1-90 windstorms and 1.5 in. (38
                        mm) for Class 1-60 windstorms. Fasteners shall not be driven into the deck
                        joints for Class 1-75 or Class 1-90 construction unless otherwise noted. Refer
                        to specific listings for wind uplift rated assemblies above Class 1-90.
                    </p>
                </blockquote>
                <p><strong>Fasteners Used to Secure Steel Roof Decks: </strong>
                </p>
                <blockquote dir="ltr" >
                    <p>
                        The deck is normally supported by steel beams or open web joists and secured to
                        these supporting members by means of FM Approved fasteners spaced maximum 12
                        in. (305 mm) on center or as otherwise listed. FM Approved&nbsp;fasteners shall
                        be provided at all side laps. Spacing between fasteners and supporting members
                        shall not exceed 36 in. (914 mm). The listed deck fasteners are FM Approved for
                        use in securing Class 1 steel roof decks.
                    </p>
                </blockquote>
                <p><strong>Fasteners Used to Secure Composite Panel and Standing/Lap Seam Roof
                    Coverings:</strong>
                </p>
                <blockquote dir="ltr" >
                    <p>The fasteners are for use in securing Composite Panel and Standing/Lap Seam Roof
                        Coverings. The fasteners can be self-drilling screws or they may be screws that
                        require a pilot hole whereby the threads engage the supporting steel or they
                        may be bolts with nuts that require a predrilled hole.
                    </p>
                </blockquote>
            </div>
    },
    {
        id: 'F2',
        name: 'Fiber Reinforced Cement Roof Deck',
        definition:
            <div>
                <p >
                    FM Approved&nbsp;precast cementitious decks are a&nbsp;of minimum 4 in.
                    (100 mm) thick, are lightweight but have a high strength-to-weight
                    ratio and are considered noncombustible. The individual panels are
                    extremely rigid, maintain strong side-lap joints, assure a smooth
                    top surface for contact with insulation or roof covering, and allow
                    a choice of ribbed or smooth ceiling.
                </p>
            </div>
    },
    {
        id: 'F3',
        name: 'Fiber Reinforced Plastic Roof Deck',
        definition:
            <div>
                <p>
                    Insulated or uninsulated fiber reinforced plastic roof
                    deck constructions are used in a manner similar to insulated steel roof deck
                    constructions (see Steel Roof Decks).
                </p>
            </div>
    },
    {
        id: 'F4',
        name: 'Field of the Roof',
        definition:
            <div>
                <p>
                    The field of a roof is the central portion of a roof, excluding the perimeter
                    and flashing.
                </p>
            </div>
    },
    {
        id: 'F5',
        name: 'Flashing',
        definition:
            <div>
                <p>
                    Flashing is a component used to weatherproof or seal the roof system edges at
                    perimeters, penetrations, walls, expansion joints, valleys, drains, and other
                    places where the roof covering is interrupted or terminated.
                </p>
            </div>
    },
    {
        id: 'F6',
        name: 'Form Deck',
        definition:
            <div>
                <p>
                    The form deck is the substrate over which lightweight insulating concrete is
                    cast to create a lightweight insulating concrete deck.&nbsp; The form deck is
                    typically corrugated galvanized steel, structural concrete or an existing
                    built-up roof.
                </p>
            </div>
    },
    {
        id: 'F7',
        name: 'Fully Adhered',
        definition:
            <div>
                <p>
                    Fully adhered describes roof coverings that have been bonded to the
                    substrate&nbsp;using a compatible adhesive throughout the entire surface of the
                    roof.
                </p>
            </div>
    },
    {
        id: 'G1',
        name: 'Glass Felt',
        definition:
            <div>
                <p>
                    Glass felt is glass fibers bonded into a sheet with resin.&nbsp; It is
                    used&nbsp;in the&nbsp;preparation of bituminous waterproofing roofing
                    membranes.
                </p>
            </div>
    },
    {
        id: 'G2',
        name: 'Glass Fiber Insulation',
        definition:
            <div>
                <p>
                    Glass fiber insulation is blanket or rigid board insulation, composed
                    of glass fibers bound together with a binder, faced or unfaced, used
                    to insulate roofs and walls.
                </p>
            </div>
    },
    {
        id: 'G3',
        name: 'Granule',
        definition:
            <div>
                <p>
                    Granule (also referred to as mineral or ceramic granule) is an
                    opaque, natural, or synthetically colored aggregate commonly used
                    to surface cap sheets, shingles, and other granule-surfaced roof
                    coverings.
                </p>
            </div>
    },
    {
        id: 'G4',
        name: 'Gypsum Board',
        definition:
            <div>
                <p>
                    Gypsum board is cementitious board stock with a noncombustible core
                    primarily comprised of gypsum that is commonly used as a thermal
                    barrier or cover board in a roof assembly.
                </p>
            </div>
    },
    {
        id: 'G5',
        name: 'Gypsum Roof Deck',
        definition:
            <div>
                <p>
                    FM Approved gypsum roof decks consist of a slurry of gypsum cement
                    placed&nbsp;over steel reinforcing mesh&nbsp;and permanent formboards.&nbsp;
                    The form boards must be low-hazard Class 1 material supported by steel
                    subpurlins secured to the roof structural steel or concrete framing members and
                    must remain in place as a finished ceiling or underside to the roof deck. If a
                    suspended ceiling is desired, it shall be supported from the roof structural
                    members or hung from the subpurlins designed to carry this additional weight.
                </p>
                <p>
                    The load carrying capacity of the system is
                    governed by the steel subpurlins. Where superimposed concentrated
                    loads occur due to mechanical equipment, additional steel framing is
                    required.
                </p>
                <p>
                    Currently, there are no FM Approved
                    manufacturers&nbsp;supplying new gypsum roof decks.
                    Sound&nbsp;existing gypsum roof decks that were constructed using FM
                    Approved gypsum products may be recovered&nbsp;with an FM Approved
                    Recover assembly.
                </p>
            </div>
    },
    {
        id: 'H1',
        name: 'Heat Weld',
        definition:
            <div>
                <p>
                    Heat welding is a method of melting and fusing together the overlapping edges
                    of separate sheets or sections of polymer modified bitumen, thermoplastics, or
                    some uncured thermoset roofing membranes by the application of heat and
                    pressure.
                </p>
            </div>
    },
    {
        id: 'I1',
        name: 'Inside Width',
        definition:
            <div>
                <p>
                    Inside Width is the width of a membrane weld which starts at or near the edge
                    of the lower membrane in a membrane lap.
                </p>
            </div>
    },
    {
        id: 'I2',
        name: 'Insulation',
        definition:
            <div>
                <p>
                    Insulation is any of a variety of materials designed to reduce the
                    flow of heat, either from or into a building. &nbsp;(see also Thermal Insulation.)
                </p>
            </div>
    },
    {
        id: 'J1',
        name: 'Joist',
        definition:
            <div>
                <p>
                    A joist is any of the small timbers, metal or wood beams arranged
                    parallel to each other and spanning from wall to wall to support
                    a floor, ceiling, or roof of a building.
                </p>
            </div>
    },
    {
        id: 'L1',
        name: 'Lap Configuration',
        definition:
            <div>
                <p>
                    Lap Configuration describes the configuration of welded membrane laps; either
                    single weld or double weld.
                </p>
            </div>
    },
    {
        id: 'L2',
        name: 'Lap Seam',
        definition:
            <div>
                <p>
                    A lap seam occurs where overlapping materials are seamed, sealed, or otherwise
                    bonded.
                </p>
            </div>
    },
    {
        id: 'L3',
        name: 'Lightweight Insulating Concrete Roof Deck',
        definition:
            <div>
                <p>
                    Lightweight insulating concrete decks are poured in place as a slurry over
                    FM Approved corrugated steel-form or structural concrete deck. The
                    corrugated steel-form deck is fastened as indicated in the&nbsp;RoofNav assembly.
                </p>
                <p>
                    FM Approved lightweight concrete is a mixture of Portland cement and water with
                    various aggregate and/or preformed foam and an air-entraining agent. The
                    lightweight concrete shall be installed by a licensed applicator. Reinforcing
                    mesh of galvanized steel-welded wire mesh is recommended in seismic areas. Where
                    reference is made to compressive strength, the strength is determined per ASTM C495.
                </p>
                <p>
                    <b>Noncombustible Constructions:</b>
                    Require 1) a minimum 2 in. (50 mm) thickness of lightweight concrete directly
                    above the top of the corrugations of the steel-forming, unless otherwise noted,
                    or 2) a minimum 1/8 in. (3 mm) thickness of lightweight concrete above a
                    structural concrete deck or a structural concrete deck with existing BUR cover
                    in place, unless otherwise noted.
                </p>
                <p>
                    Insulation boards may be placed in the wet concrete, followed by additional
                    concrete which must be placed before the bottom layer sets up. Unless otherwise
                    noted, a minimum 2 in. (50 mm) thickness of lightweight concrete is required
                    above any rigid insulation board.
                </p>
                <p>
                    <b>Class 1 Fire Rated Constructions:</b>
                    Requires a minimum 1/8 in. (3 mm) thickness of lightweight concrete applied
                    above the top of the corrugations of the steel form deck, unless otherwise
                    noted. Insulation boards may then be placed in the wet concrete, followed by
                    additional concrete which must be placed before the bottom layer sets up. A
                    minimum 2 in. (50 mm) thickness of lightweight concrete is required above any
                    rigid insulation boards, unless otherwise noted.
                </p>
            </div>
    },
    {
        id: 'L4',
        name: 'Liner Panel',
        definition:
            <div>
                <p>
                    A liner panel is a steel sheet placed either between the structure and a
                    standing seam / lap seam roof panel to support insulation or over Fiber
                    Reinforced Plastic roof decks.</p>
            </div>
    },
    {
        id: 'L5',
        name: 'Liquid Applied Roof Cover',
        definition:
            <div>
                <p>
                    Liquid applied roof coverings arrive on the roof in a liquid state and are
                    spray, brush or roller applied using proprietary procedures to
                    the&nbsp;substrate which may consist of a&nbsp;spray applied&nbsp;foam, rigid
                    insulation, an existing panel roof or an asphaltic built-up roof. These
                    coverings are generally applied in a minimum two coat application to ensure
                    proper coverage.
                </p>
            </div>
    },
    {
        id: 'M1',
        name: 'Maintenance Coating',
        definition:
            <div>
                <p>
                    A liquid applied product with or without reinforcements that is applied over a functioning roof system.
                    Maintenance coatings do not increase the fire, wind, or hail resistance performance of the existing roof.
                    Maintenance coatings are evaluated for combustibility from above the roof deck only.
                </p>
            </div>
    },
    {
        id: 'M2',
        name: 'Mechanically Fastened ',
        definition:
            <div>
                <p>
                    Mechanically fastened describes roof coverings or base sheets that have been
                    attached to the substrate at defined intervals using fasteners with or without
                    stress distributors.&nbsp; Multi-ply covers adhered to
                    mechanically&nbsp;fastened base sheets are classified as mechanically fastened.
                </p>
            </div>
    },
    {
        id: 'M3',
        name: 'Membrane',
        definition:
            <div>
                <p>
                    A membrane is a flexible&nbsp;roof covering&nbsp;layer whose primary function
                    is the exclusion of water.
                </p>
            </div>
    },
    {
        id: 'M4',
        name: 'Modified Bitumen',
        definition:
            <div>
                <p>
                    Modified bitumen is&nbsp;an asphalt or coal tar with the inclusion of&nbsp;one
                    or more polymers.
                </p>
            </div>
    },
    {
        id: 'M5',
        name: 'Multi-Ply Roof Cover',
        definition:
            <div>
                <p>
                    Multi-ply roof coverings consist of several layers of base, ply, and/or
                    cap&nbsp;sheets that are adhered to each other with an adhesive and together
                    form a thick multi-layer weatherproof roof covering. The single sheets consist
                    of a reinforcement&nbsp;which&nbsp;is saturated and/or coated with an asphaltic
                    or coal tar material.
                </p>
                <p>
                    Multi-ply roof construction generally consists of a 2&nbsp;to 5 ply built up
                    roof (BUR), modified bitumen roofs with a modified bitumen base and cap sheet
                    or they may consist of a combination of BUR and modified bitumen products.
                </p>
                <p>
                    When hot asphalt is used as the adhesive, unless otherwise noted in the specific
                    listing, it is applied at a nominal rate of 25 lb/sq (1.2 kg/m 2 ).
                </p>
            </div>
    },
    {
        id: 'O1',
        name: 'Optional Layer',
        definition:
            <div>
                <p>
                    An optional layer is a layer that does not affect the performance criteria of a
                    roof assembly.
                </p>
            </div>
    },
    {
        id: 'O2',
        name: 'Organic Felt',
        definition:
            <div>
                <p>
                    Organic felt is one type of base, ply, or cap sheet consisting of asphalt
                    saturated cellulose fibers.
                </p>
            </div>
    },
    {
        id: 'O3',
        name: 'Other Coat',
        definition:
            <div>
                <p>
                    Other coat refers to
                    the liquid material applied between the base coat and the top coat when
                    building a&nbsp;liquid applied roof cover.
                </p>
            </div>
    },
    {
        id: 'O4',
        name: 'Outside Width',
        definition:
            <div>
                <p>
                    Outside Width is the width of a membrane weld which runs along the edge of the
                    top membrane in a membrane lap.
                </p>
            </div>
    },
    {
        id: 'P1',
        name: 'Perforated',
        definition:
            <div>
                <p>
                    A layer&nbsp;which is perforated, typically a base sheet,&nbsp;has holes in it
                    that allow adhesive to pass through it to the substrate below. &nbsp;See Permeable.
                </p>
            </div>
    },
    {
        id: 'P2',
        name: 'Perimeter Flashing',
        definition:
            <div>
                <p>
                    Perimeter flashing is a component or system of components used to
                    weatherproof&nbsp;and seal the&nbsp;roof system at the building edge.
                </p>
                <p>
                    Perimeter flashing (coping and roof edging systems) must be well anchored and of
                    sufficient gauge or thickness to resist anticipated rain, temperature and wind
                    effects. Most roof-covering blowoffs originate at the perimeter when the wind
                    works its way under the edge, frequently deforming the edge flashing and then
                    rolling back the covering.
                </p>
                <p>
                    These perimeter flashing systems are FM Approved for use with the wind uplift
                    classifications shown. The perimeter flashing is designed to contain
                    installation features capable of resisting anticipated wind forces.
                </p>
                <p>
                    A properly installed roof assembly is dependent upon strong roof edge flashing,
                    properly anchored to resist anticipated edge wind forces. Only FM Approved edge
                    flashing components shall be used.
                </p>
                <p>The roof assembly must be installed using a perimeter flashing system capable of
                    withstanding the anticipated loads. When an FM Approved system is unavailable,
                    the flashing must be designed to withstand the following minimum loads:
                </p>

                <p className={'my-0 ml-5'}>&nbsp;&nbsp;
                    <span className=''>Classification &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Uplift Load
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        Outward Load
                    </span></p>
                <p className={'my-0 ml-5'}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    psf
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    psf</p>
                <p className={'my-0 ml-5'}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <p className={'my-0'}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Class 1-60
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    90
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    45</p>
                <p className={'my-0'}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Class 1-75
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    113
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    56</p>
                <p className={'my-0'}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Class 1-90
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    135
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    70</p>
                <p className={'my-0'}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Class 1-105
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    158
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    79</p>
                <p className={'my-0'}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Class 1-120
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    203
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    101</p>
                <p className={'my-0'}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Class 1-150
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    225
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    115</p>
                <p className={'my-0'}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Class 1-165
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    248
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    124</p>
                <p className={'my-0'}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Class 1-180
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    270
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    135</p>
            </div >
    },
    {
        id: 'P3',
        name: 'Photovoltaic Module, Flexible',
        definition:
            <div>
                <p>
                    A flexible device that converts solar energy into electricity using the photovoltaic effect.
                    Flexible photovoltaic modules are submitted in either sheet or roll form. They can be installed using either mechanical fasteners and plates or adhesives. Modules that are mechanically fastened are installed on site during or after the installation of the roof system. Modules that are installed using adhesives can be adhered to the roof cover during the manufacturing process or installed on site during or after the installation of the roof system.
                </p>
                <p>
                    These products and Approval information can be found by performing a Product Search, with the following specified:
                </p>
                <p>
                    Category = Coating/Surfacing<br />
                    Subcategory = Surfacing<br />
                    Approved use = Surfacing
                </p>
            </div>
    },
    {
        id: 'P4',
        name: 'Photovoltaic Module, Rigid',
        definition:
            <div>
                <p>
                    A rigid device that converts solar energy into electricity using the photovoltaic effect.  Rigid photovoltaic modules are submitted as panels in varying sizes. They can come with glass facers on both the top and bottom or can be supplied with plastic back sheets. Rigid Photovoltaic Modules are installed on site during or after the installation of the roof system and can be mechanically attached using mounting systems, or brackets to the roof cover or through the roof to the deck.  They can also be ballasted on the FM Approved roof assembly.  Consult RoofNav for installation requirement and details of all assemblies.
                </p>
                <p><b>NOTE: The wind uplift and fire ratings of the PV system may differ from the <span>underlying</span> roofing assembly</b>
                </p>
                <p>
                    These products and Approval information can be found by performing a Product Search, with the following specified:
                </p>
                <p>
                    Category = Coating/Surfacing<br />
                    Subcategory = Surfacing<br />
                    Approved use = Surfacing
                </p>
            </div>
    },
    {
        id: 'P5',
        name: 'Ply',
        definition:
            <div>
                <p>
                    A ply layer in a multi-ply assembly cover contains Ply Sheet products.&nbsp; A ply is one of
                    a number of felts or sheets bonded together to form a multi-ply roof cover.
                </p>
            </div>
    },
    {
        id: 'P6',
        name: 'Preassembled System',
        definition:
            <div>
                <p>
                    A preassembled system is a fastener and stress plate supplied as an individual
                    unit.
                </p>
            </div>
    },
    {
        id: 'P7',
        name: 'Preliminary Insulation Fastening',
        definition:
            <div>
                <p>
                    Refer to Data Sheet 1-29, Table 6 for presecurement fastening requirements.
                </p>
            </div>
    },
    {
        id: 'P8',
        name: 'Presecurement',
        definition:
            <div>
                <p>
                    See Preliminary Insulation Fastening.
                </p>
            </div>
    },
    {
        id: 'P9',
        name: 'Primer',
        definition:
            <div>
                <p>
                    A primer is a thin bodied liquid material applied to a surface to prepare the
                    surface and / or to improve the adhesion of the subsequent adhesive.
                </p>
            </div>
    },
    {
        id: 'P10',
        name: 'Purlin',
        definition:
            <div>
                <p>
                    A purlin is a light gauge structural member typically used to support standing
                    or lap seam roofs.&nbsp; Purlins&nbsp;transfer loads&nbsp;to the primary
                    structural framing of the building.
                </p>
            </div>
    },
    {
        id: 'P11',
        name: 'PVC',
        definition:
            <div>
                <p>
                    Polyvinyl chlorided (PVC) is a synthetic thermoplastic polymer prepared from
                    vinylchloride.&nbsp; PVC can be compounded into flexible and rigid forms
                    through the use of plasticizers, stabilizers, fillers, and other
                    modifiers.&nbsp; Rigid forms are used in pipes; flexible forms are used in the
                    manufacture of sheeting and roof membrane materials.
                </p>
            </div>
    },

    {
        id: 'R1',
        name: 'Recover',
        definition:
            <div>
                <p>
                    Recover roof constructions over existing FM Approved Roof: The new components are installed over the existing roof cover. If more than one existing roof is present, the existing components should be removed. (see Re-Roof)
                </p>
                <p>
                    Remove and replace all wet insulation materials prior to application of the new roof system, remove existing gravel and cut out blisters.
                </p>
                <p>
                    In all cases, the wind uplift resistance of the completed roof is the lesser of the rating of the recover RoofNav assembly or that of the substrate to which the recover assembly is secured.
                    Particular care is needed when a mechanically fastened roof cover is secured through a Standing Seam Roof to existing purlins. If the new assembly is not secured to every existing purlin, structural analysis should be conducted to confirm the purlins are not over stressed under wind uplift conditions.
                    Refer to Data Sheet 1-31 for additional details.
                </p>
                <p>The addition of a new roof assembly in recover construction will not upgrade an existing Class 2 roof assembly to Class 1. The fire rating of the existing steel deck roof is assumed to be Class 2 requiring automatic sprinkler protection under the roof if the original roof assembly is not FM Approved.</p>

            </div>
    },
    {
        id: 'R2',
        name: 'Reglet',
        definition:
            <div>
                <p>
                    A reglet&nbsp;is a metal counterflashing installed into the wall at the
                    roof/wall transition.&nbsp; It is installed adjacent to a roof surface, over
                    the roof flashing.&nbsp; Reglets can be secured to the wall with fasteners,
                    installed into a metal strip and secured with clips, or embedded behind
                    cladding.
                </p>
            </div>
    },
    {
        id: 'R3',
        name: 'Re-Roof ',
        definition:
            <div>
                <p>Re-roof roof construction requires stripping the existing roof system, consisting of all membranes, insulations (see exceptions for lightweight insulating concrete [LWIC] and steel deck), fasteners, etc., down to the bare substrate (i.e. steel deck, structural concrete or wood deck). In all cases the existing deck must be sound. The roof system is then installed according to the RoofNav assembly and the applicable Data Sheet. In all cases the wind uplift resistance of the re-roofed assembly is the lesser of the rating of the re-roof assembly or that of the substrate to which the re-roof assembly is secured. When re-roofing over gypsum, cementitious panel or LWIC, verify fastener pull-out performance with field tests per FM Property Loss Prevention Data Sheet (LPDS) 1-29. Refer to LPDS 1-29 for pull-out tests on other substrates.</p>
                <p>To search for re-roof assemblies: </p>
                <p>On the Assembly Search Classifications Tab, select "Re-Roof" in the Application drop down. NOTE: It is not necessary to select anything on the Deck Type drop down. If you do, only “Existing Deck” is selected regardless of the actual material of the existing roof deck. If you want to specify an existing deck material, on the Specifications Tab select "Existing Roof" under Decking. You can specify the existing deck material in the details of Existing Roof layer.</p>
                <p>For each specific existing deck, the following applies: </p>
                <ol>
                    <li>For steel deck, unless otherwise noted in the assembly, no asphalt or adhesives remain on the deck.  For assemblies in which asphalt can remain on the deck it is noted on the existing deck layer of the assembly.  If any asphalt remains, it cannot exceed 15 lb/sq (0.72 kg/m2) and an FM Approved assembly for re-roof construction must be used. If there is no asphalt or other adhesive on the steel deck, an FM Approved assembly for re-roof over steel deck -OR- new construction on steel deck is used. The existing deck must show no signs of damage, corrosion, rot or rust through. It is necessary to know the integrity and grade of the steel deck. In the event that the grade of the steel deck cannot be determined by mill certificates from the deck manufacturer or the integrity cannot be determined by observation, then fastener pullout tests as described in LPDS 1-29 or ANSI Standard ANSI/SPRI FX-1-1996 will satisfy this requirement.</li>
                    <li>For re-roofing of existing assemblies where existing LWIC will remain and all other components are removed (base sheet, cover, etc.) and the new roof assembly is applied over the LWIC, the following is done: The reroof assembly is secured either to the existing LWIC or through the LWIC to the underlying deck, depending on the details of the Approved assembly being installed. The existing assembly must have a sound LWIC layer.  To find assemblies Approved for this use, select "Existing Roof" on the Specifications Tab under Decking. Under Details of Existing Roof, for Insulation Material select Lightweight Insulating Concrete from the drop down. If you know the underlying deck (steel, structural concrete) that can be specified under Roof Deck on the same details page. </li>
                    <li>
                        For structural concrete deck the following options can be used:
                        <ol>
                            <li>An assembly Approved for re-roof over concrete within the limitations of the assembly.</li>
                            <li>An assembly Approved for new construction on structural concrete which uses mechanical fastening into the deck can be used.</li>
                            <li>If the existing deck is free of adhesives, etc. an assembly Approved for new construction on structural concrete which uses adhesives for securement to the deck can be used.</li>
                        </ol>
                    </li>
                    <li>For fire retardant Treated wood deck, all above deck components are removed. Unlike steel deck, there is no allowance for residual asphalt.</li>
                    <li>For gypsum deck, all above deck components are removed and the reroof assembly is then secured to the deck per the details of the approved assembly being installed.</li>
                    <li>For Cementitious Panel deck, any existing asphalt can remain on the deck. An FM Approved assembly for new construction may be used.</li>
                    <li>For fiber reinforced Plastic deck, there should be no existing asphalt on the deck. An FM Approved assembly for new construction may be used.</li>
                </ol>
            </div>
    },
    {
        id: 'R4',
        name: 'Retarders - Air and Vapor',
        definition:
            <div>
                <p>Vapor/air retarders provide protection to the above deck components from air
                    infiltration and condensation due to high internal moisture loads. Moisture
                    sealed into the roof system during construction may expand during hot weather,
                    causing blisters to form in the roof covering and eventually leading to failure
                    of the roof system. Vapor retarders prevent moisture from infiltrating to the
                    above deck components. Air retarders prevent air from infiltrating to the above
                    deck components. A vapor/air retarder for steel, wood or concrete deck may be
                    provided in the form of a membrane sheet applied over the deck surface. The
                    membrane sheet or film type of vapor/air barrier may be supplied as
                    asphalt-saturated felt, vinyl plastic, Kraft paper, etc., or a laminated combination.</p>
                <p>FM Approved vapor/air retarder sheets shall be placed directly on
                    the deck or over a minimum FM Approved thickness of insulation or separator
                    board. They shall be be installed in accordance with the Approval requirements.
                    Then, FM Approved insulation is placed and fasteners driven through the
                    components into the deck.
                </p>
                <p>When an air retarder or vapor retarder is listed as a component with a
                    mechanically fastened roof cover, it is imperative that the air/vapor retarders
                    restrict air infiltration to the roof assembly between the top of the air
                    barrier and the underside or the roof covering. To accomplish this, the
                    air/vapor retarder must encapsulate these roof components. An accepted means of
                    encapsulating these components is to fold the air/vapor retarder up along the
                    edges of the components and seal the air/vapor retarder to the underside or the
                    roof covering using a compatible adhesive. The encapsulating must be done at
                    the entire roof perimeter and at all roof penetrations. Additionally, if the
                    integrity of the air/vapor barrier is breached; e.g., equipment is added on the
                    roof, the air/vapor retarder must be sealed to the underside of the roof cover
                    using a compatible adhesive.</p>
                <p>FM Approved vapor/air retarders have been evaluated for fire hazard and/or wind resistance
                    only, not for permeability.</p>
            </div>
    },
    {
        id: 'R5',
        name: 'Roof Assembly',
        definition:
            <div>
                <p>
                    A roof assembly is a system of interacting roof components (including the roof
                    deck) designed to weatherproof and, normally, to insulate a building's top
                    surface.
                </p>
            </div>
    },
    {
        id: 'R6',
        name: 'Roof Coating',
        definition:
            <div>
                <p>Roof coatings are applied to the top surface of a roof covering to provide for a
                    reflective top surface, for energy savings, for aesthetics or to improve the
                    weather and/or fire performance of the roof.
                </p>
                <p>When the coatings improve the ASTM E 108 exterior fire performance of a roof
                    covering, the manufacturer shall supply the building owner with written
                    maintenance recommendations for maintaining the proper coverage thickness as
                    the surface coating weathers.
                </p>
            </div>
    },
    {
        id: 'R7',
        name: 'Roof Cover',
        definition:
            <div>
                <p>
                    A roof cover is the exterior surface of a roof assembly.
                </p>
            </div>
    },
    {
        id: 'R8',
        name: 'Roof Cover - Composite Panel',
        definition:
            <div>
                <p>FM Approved composite panel roof coverings are Class 1 if the arrangement of materials
                    is such that they produce a product which will satisfy fire, minimum Class 1-60
                    wind uplift, minimum Class 1-MH Hail, and Class C ASTM E 108 criteria.  The structural integrity and
                    fire hazard characteristics are produced by the complete system; therefore, no
                    installation modifications or material substitutions can be made.</p>
                <p>Unless otherwise noted in the assembly, composite panels are
                    installed directly to the supporting members (purlins), and no other
                    deck material is provided.</p>
            </div>
    },
    {
        id: 'R9',
        name: 'Roof Cover - Liquid Applied',
        definition:
            <div>
                <p>Liquid applied roof coverings arrive on the roof in a liquid state and are
                    spray, brush or roller applied using proprietary procedures to
                    the&nbsp;substrate which may consist of a&nbsp;spray applied&nbsp;foam, rigid
                    insulation, an existing panel roof or an asphaltic built-up roof. These
                    coverings are generally applied in a minimum two coat application to ensure
                    proper coverage.</p>
            </div>
    },
    {
        id: 'R10',
        name: 'Roof Cover - Multi-Ply',
        definition:
            <div>
                <p>Multi-ply roof coverings consist of several layers of base, ply, and/or
                    cap&nbsp;sheets that are adhered to each other with an adhesive and together
                    form a thick multi-layer weatherproof roof covering. The single sheets consist
                    of a reinforcement&nbsp;which&nbsp;is saturated and/or coated with an asphaltic
                    or coal tar material.</p>
                <p>Multi-ply roof construction generally consists of a 2&nbsp;to 5 ply built up
                    roof (BUR), modified bitumen roofs with a modified bitumen base and cap sheet
                    or they may consist of a combination of BUR and modified bitumen products.</p>
                <p>When hot asphalt is used as the adhesive, unless otherwise noted in the specific
                    listing, it is applied at a nominal rate of 25 lb/sq (1.2 kg/m 2 ).</p>
            </div>
    },
    {
        id: 'R11',
        name: 'Roof Cover - Standing / Lap Seam',
        definition:
            <div>
                <p>The standing seam roof cover generally consists of&nbsp;metal sheets or panels,
                    field seamed to adjacent sheets by a special roll-forming machine to create an
                    upstanding seam (rib) of folded metal along the sheet sidelaps. The panels are
                    secured to the building framing with clips.&nbsp; The&nbsp;clip, which contains
                    metal tabs, is roll-formed into the panel seam.&nbsp;</p>
                <p>Lap seam covers consist of metal or plastic spurs which are through fastened to
                    structural members.</p>
                <p>Standing seam and lap seam roof covers are installed directly to
                    the supporting members (no deck), through a liner panel or FM
                    Approved steel deck into the supporting members (steel deck), or,
                    only where detailed in the RoofNav assembly, into the FM Approved
                    steel deck.</p>
                <p>For existing roofs there are two assemblies with these types of
                    roofs. Standing/Lap Seam Roof (Insulated), these are existing roofs
                    which contain some type of existing insulation, including, for example,
                    glass fiber batts on the underside. Standing/Lap Seam Roof Uninsulated)
                    are existing roofs which do not contain any existing insulation.</p>
            </div>
    },
    {
        id: 'R12',
        name: 'Roof Cover - Single Ply',
        definition:
            <div>
                <p>
                    Factory produced single ply roof covers&nbsp;consist of sheets of EPDM, PVC,
                    TPO, CSPE or several variations of these.&nbsp; Modified bitumen covers applied
                    in a single layer (no base or ply sheets) are considered single ply roof
                    covers.</p>
            </div>
    },
    {
        id: 'R13',
        name: 'Roof Deck - Cementitious Panel',
        definition:
            <div>
                <p>
                    Cementitious Panel Roof Deck is a factory fabricated roof deck consisting of fibers (typically wood or fiberglass) and cementitious binder.
                    Panels are supplied in various thickness with tongue and groove or butt side and end joints.
                </p>
            </div>
    },
    {
        id: 'R14',
        name: 'Roof Deck - Existing Roof',
        definition:
            <div>
                <p>
                    Existing&nbsp;roof is used to describe recover roof assemblies where all components are fully adhered to or mechanically fastened through the existing roof.
                    Because no fasteners are used, further description of deck type is not required.
                </p>
            </div>
    },
    {
        id: 'R15',
        name: 'Roof Deck - Fiber Reinforced Cement',
        definition:
            <div>
                <p>
                    FM Approved&nbsp;precast cementitious decks are a&nbsp;of minimum 4 in.
                    (100 mm) thick, are lightweight but have a high strength-to-weight
                    ratio and are considered noncombustible. The individual panels are
                    extremely rigid, maintain strong side-lap joints, assure a smooth
                    top surface for contact with insulation or roof covering, and allow
                    a choice of ribbed or smooth ceiling.
                </p>
            </div>
    },
    {
        id: 'R16',
        name: 'Roof Deck - Fiber Reinforced Plastic',
        definition:
            <div>
                <p>
                    Insulated or uninsulated fiber reinforced plastic roof
                    deck constructions are used in a manner similar to insulated steel roof deck
                    constructions (see Steel Roof Decks).
                </p>
            </div>
    },
    {
        id: 'R17',
        name: 'Roof Deck - Gypsum',
        definition:
            <div>
                <p>FM Approved gypsum roof decks consist of a slurry of gypsum cement
                    placed&nbsp;over steel reinforcing mesh&nbsp;and permanent formboards.&nbsp;
                    The form boards must be low-hazard Class 1 material supported by steel
                    subpurlins secured to the roof structural steel or concrete framing members and
                    must remain in place as a finished ceiling or underside to the roof deck. If a
                    suspended ceiling is desired, it shall be supported from the roof structural
                    members or hung from the subpurlins designed to carry this additional weight.
                </p>
                <p>The load carrying capacity of the system is
                    governed by the steel subpurlins. Where superimposed concentrated
                    loads occur due to mechanical equipment, additional steel framing is
                    required.</p>
                <p>
                    Currently, there are no FM Approved
                    manufacturers&nbsp;supplying new gypsum roof decks.
                    Sound&nbsp;existing gypsum roof decks that were constructed using FM
                    Approved gypsum products may be recovered&nbsp;with an FM Approved
                    Recover assembly.
                </p>
            </div>
    },
    {
        id: 'R18',
        name: 'Roof Deck - Lightweight Insulating Concrete',
        definition:
            <div>
                <p> Lightweight insulating concrete decks are poured in place as a slurry over
                    FM Approved corrugated steel-form or structural concrete deck. The
                    corrugated steel-form deck is fastened as indicated in the&nbsp;RoofNav assembly.</p>
                <p>FM Approved lightweight concrete is a mixture of Portland cement and water with
                    various aggregate and/or preformed foam and an air-entraining agent. The
                    lightweight concrete shall be installed by a licensed applicator. Reinforcing
                    mesh of galvanized steel-welded wire mesh is recommended in seismic areas. Where
                    reference is made to compressive strength, the strength is determined per ASTM C495.</p>
                <p><b>Noncombustible Constructions:</b>
                    Require 1) a minimum 2 in. (50 mm) thickness of lightweight concrete directly
                    above the top of the corrugations of the steel-forming, unless otherwise noted,
                    or 2) a minimum 1/8 in. (3 mm) thickness of lightweight concrete above a
                    structural concrete deck or a structural concrete deck with existing BUR cover
                    in place, unless otherwise noted.</p>
                <p>Insulation boards may be placed in the wet concrete, followed by additional
                    concrete which must be placed before the bottom layer sets up. Unless otherwise
                    noted, a minimum 2 in. (50 mm) thickness of lightweight concrete is required
                    above any rigid insulation board.</p>
                <p><b>Class 1 Fire Rated Constructions:</b>
                    Requires a minimum 1/8 in. (3 mm) thickness of lightweight concrete applied
                    above the top of the corrugations of the steel form deck, unless otherwise
                    noted. Insulation boards may then be placed in the wet concrete, followed by
                    additional concrete which must be placed before the bottom layer sets up. A
                    minimum 2 in. (50 mm) thickness of lightweight concrete is required above any
                    rigid insulation boards, unless otherwise noted.</p>
            </div>
    },
    {
        id: 'R19',
        name: 'Roof Deck - Steel',
        definition:
            <div>
                <p>Insulated steel roof decks may contain combustible insulations, sometimes
                    adhesives or a vapor/air retarder and always a roof cover, applied over a steel
                    deck.&nbsp; If an assembly which is not FM Approved is used, a self-propagating
                    fire may result on the underside of the roof deck due to the evolution of
                    combustible vapors. FM Approved assemblies&nbsp;produce heat release rates
                    within acceptable limits. The FM Approved steel deck assemblies are Class 1
                    fire rated.</p>
                <p>
                    Steel deck strongly influences the ability of the roof construction to resist
                    wind uplift forces by its structural integrity, general physical configuration
                    and method of installation.</p>
                <p>
                    Steel deck is normally classified by&nbsp;depth and&nbsp;rib opening as
                    follows: narrow rib deck has a minimum rib opening of 1 in. &gt;(25 mm),
                    intermediate rib 1 3/4 in. (45 mm) and wide rib 2 1/2 in. (64 mm), measured
                    along the top surface. The top flange surface of the deck must be flat.</p>
                <p>Roof assemblies are continuously subjected to concentrated loads from personnel
                    and equipment during and sometimes after construction. Deflection from this
                    type of loading is greater than with a uniformly distributed design load. The
                    minimum acceptable steel thickness must conform to Approval requirements. Side
                    laps securement and attachment to structural supports must be according to FM
                    Approved means.</p>
            </div>
    },
    {
        id: 'R20',
        name: 'Roof Deck - Structural Concrete',
        definition:
            <div>
                <p>Structural concrete decks are generally monolithic, poured in place decks or
                    precast concrete plank and are considered noncombustible.
                </p>
                <p>These decks have a minimum compressive strength of 2500 psi (17.2 MPa) and a
                    density of approximately 150 lb/ft 3 (2400 kg/m 3 ). Structural concrete deck
                    assemblies&nbsp;are considered noncombustible when the deck is a&nbsp;minimum
                    of 2 in. (50 mm) thick.</p>
                <p>When structural concrete roof decks are used the building owner or his agent
                    should seek advice from a competent engineer, roof consultant or roof designer
                    to determine that the structural substrate and, when used, a structural
                    concrete deck, has the ability to withstand the anticipated uplift forces for
                    each classification.
                </p>
                <p>Screw type fasteners used with structural concrete decks to secure insulations
                    or roof covers usually require different thread design than those used with
                    other decks. Unless otherwise noted, listings with concrete deck pertain to
                    structural concrete deck only with a minimum ultimate compressive strength of
                    2500 psi (17.2 MPa).
                </p>
                <p>
                    Due to the non-combustible nature of Structural Concrete, FM&nbsp;Approvals
                    does not recognize the need to list manufacturers of the decks having a
                    compressive strength of 2500 psi (17.2 MPa) and a density of approximately 150
                    lb/ft 3 (2400 kg/m 3 ).</p>
            </div>
    },
    {
        id: 'R21',
        name: 'Roof Deck - Treated Wood',
        definition:
            <div>
                <p>Impregnated fire-retardant treated lumber or plywood is of low combustibility
                    and FM Approved for roof deck construction without automatic sprinkler
                    protection.</p>
                <p>The wood is treated by pressure impregnation with chemicals.</p>
                <p>The roof must be supported by members of no greater combustibility than the deck
                    itself. The building occupancy should not possess a high-humidity exposure to
                    the deck.</p>
                <p>The deck thickness must be a minimum nominal 2 in. (50 mm) for lumber and 3/4
                    in. (19 mm) for plywood. Lumber shall be tongue-and-groove or splined together
                    at side joints. Plywood must be exterior glue grade and contain
                    tongue-and-groove or shiplap longitudinal edges. It is recommended that the
                    material be precut as much as possible and protected from weather while stored
                    at the job site.</p>
                <p>Fasteners used to secure insulation or roof covers to lumber deck must penetrate
                    a minimum of 1 in. (25 mm). Fasteners used to secure insulation or roof covers
                    to plywood deck must protrude through the deck a minimum of 1/4 in. (6 mm).</p>
                <p>Refer to FM Property Loss Prevention Data Sheet 1-29 for securement
                    procedures of the deck to the structural supports.</p>
            </div>
    },
    {
        id: 'R22',
        name: 'Roof Slope',
        definition:
            <div>
                <p>
                    Roof slope is the angle a roof surface makes with the horizontal, expressed as
                    a ratio of the units of vertical rise to 12 units&nbsp;of horizontal length
                    (sometimes referred to as run).</p>
                <p>
                    When choosing a slope for an Assembly Search, select the number of units for
                    the vertical rise. The slope associated with each assembly is the maximum slope for that assembly.
                </p>
            </div>
    },
    {
        id: 'R23',
        name: 'Roof System',
        definition:
            <div>
                <p>
                    A roof system a system of interacting roof components (not including the
                    roof deck) designed to weatherproof and, normally, to insulate a building's top
                    surface.</p>
            </div>
    },
    {
        id: 'R24',
        name: 'Root Barrier',
        definition:
            <div>
                <p>
                    A membrane used to provide protection from penetration of plant roots into the waterproofing membrane in a vegetative roof system.
                    An FM Approved thermoplastic single ply roof cover may be used as a root barrier if the laps are heat welded.</p>
            </div>
    },
    {
        id: 'S1',
        name: 'Saturated Felt',
        definition:
            <div>
                <p>
                    Saturated felt is a felt that has been immersed in hot bitumen; the felt
                    absorbs as much bitumen as it can under the processing conditions, but
                    remains porous and contains voids.</p>
            </div>
    },
    {
        id: 'S2',
        name: 'Scenario',
        definition:
            <div>
                <p>A scenario is a single
                    roof&nbsp;configuration consisting&nbsp;one product selected from
                    each layer in an assembly.&nbsp; The scenario identifies all
                    components of the roof configuration selected for the project.
                </p>
            </div>
    },
    {
        id: 'S3',
        name: 'Seam',
        definition:
            <div>
                <p>
                    A seam is a joint formed by mating two separate sections of material.
                </p>
            </div>
    },
    {
        id: 'S4',
        name: 'Seam Primer',
        definition:
            <div>
                <p>
                    A seam primer a thin bodied liquid material applied to the mating surfaces
                    of membranes to prepare the surface and / or improve the adhesion of the
                    subsequent adhesive application.</p>
            </div>
    },
    {
        id: 'S5',
        name: 'Self-Drilling Screw',
        definition:
            <div>
                <p>
                    A self-drilling screw is a fastener that taps and drills its own hole
                    during application.</p>
            </div>
    },
    {
        id: 'S6',
        name: 'Separator Sheet',
        definition:
            <div>
                <p>
                    A separator sheet is a sheeting material, typically loose laid, placed between roofing components to
                    separate them for protection or to prevent material damage due to component
                    incompatibility.
                </p>
            </div>
    },
    {
        id: 'S7',
        name: 'Single Ply Roof Cover',
        definition:
            <div>
                <p>
                    Factory produced single ply roof covers&nbsp;consist of sheets of EPDM, PVC,
                    TPO, CSPE or several variations of these.&nbsp; Modified bitumen covers applied
                    in a single layer (no base or ply sheets) are considered single ply roof
                    covers.</p>
            </div>
    },
    {
        id: 'S8',
        name: 'Single Weld',
        definition:
            <div>
                <p>
                    Single Weld describes membrane laps which are sealed with one weld, typically
                    1-2 in. (25-50 mm) wide along the outside edge of the top membrane.</p>
            </div>
    },
    {
        id: 'S9',
        name: 'Splice-Tape',
        definition:
            <div>
                <p>
                    Splice-tape is cured or uncured synthetic rubber tape used for splicing membrane
                    materials.</p>
            </div>
    },
    {
        id: 'S10',
        name: 'Spray Foam Insulation',
        definition:
            <div>
                <p>The field applied spray foam insulation systems consist of components delivered individually
                    to the job site where they are sprayed in place prior to applying the
                    roof cover. These systems are FM Approved when all components are installed as
                    described, and there are no substitutions.</p>
            </div>
    },
    {
        id: 'S11',
        name: 'Standing / Lap Seam Roof Cover',
        definition:
            <div>
                <p>The standing seam roof cover generally consists of&nbsp;metal sheets or panels,
                    field seamed to adjacent sheets by a special roll-forming machine to create an
                    upstanding seam (rib) of folded metal along the sheet sidelaps. The panels are
                    secured to the building framing with clips.&nbsp; The&nbsp;clip, which contains
                    metal tabs, is roll-formed into the panel seam.&nbsp;</p>
                <p>Lap seam covers consist of metal or plastic spurs which are through fastened to
                    structural members.</p>
                <p>Standing seam and lap seam roof covers are installed directly to
                    the supporting members (no deck), through a liner panel or FM
                    Approved steel deck into the supporting members (steel deck), or,
                    only where detailed in the RoofNav assembly, into the FM Approved
                    steel deck.</p>
                <p>For existing roofs there are two assemblies with these types of
                    roofs. Standing/Lap Seam Roof (Insulated), these are existing roofs
                    which contain some type of existing insulation, including, for example,
                    glass fiber batts on the underside. Standing/Lap Seam Roof Uninsulated)
                    are existing roofs which do not contain any existing insulation.</p>
            </div>
    },
    {
        id: 'S12',
        name: 'Steel Roof Deck',
        definition:
            <div>
                <p>Insulated steel roof decks may contain combustible insulations, sometimes
                    adhesives or a vapor/air retarder and always a roof cover, applied over a steel
                    deck.&nbsp; If an assembly which is not FM Approved is used, a self-propagating
                    fire may result on the underside of the roof deck due to the evolution of
                    combustible vapors. FM Approved assemblies&nbsp;produce heat release rates
                    within acceptable limits. The FM Approved steel deck assemblies are Class 1
                    fire rated.</p>
                <p>
                    Steel deck strongly influences the ability of the roof construction to resist
                    wind uplift forces by its structural integrity, general physical configuration
                    and method of installation.</p>
                <p>
                    Steel deck is normally classified by&nbsp;depth and&nbsp;rib opening as
                    follows: narrow rib deck has a minimum rib opening of 1 in. &gt;(25 mm),
                    intermediate rib 1 3/4 in. (45 mm) and wide rib 2 1/2 in. (64 mm), measured
                    along the top surface. The top flange surface of the deck must be flat.</p>
                <p>Roof assemblies are continuously subjected to concentrated loads from personnel
                    and equipment during and sometimes after construction. Deflection from this
                    type of loading is greater than with a uniformly distributed design load. The
                    minimum acceptable steel thickness must conform to Approval requirements. Side
                    laps securement and attachment to structural supports must be according to FM
                    Approved means.</p>
            </div>
    },
    {
        id: 'S13',
        name: 'Structural Concrete Roof Deck',
        definition:
            <div>
                <p>Structural concrete decks are generally monolithic, poured in place decks or
                    precast concrete plank and are considered noncombustible.
                </p>
                <p>These decks have a minimum compressive strength of 2500 psi (17.2 MPa) and a
                    density of approximately 150 lb/ft 3 (2400 kg/m 3 ). Structural concrete deck
                    assemblies&nbsp;are considered noncombustible when the deck is a&nbsp;minimum
                    of 2 in. (50 mm) thick.</p>
                <p>When structural concrete roof decks are used the building owner or his agent
                    should seek advice from a competent engineer, roof consultant or roof designer
                    to determine that the structural substrate and, when used, a structural
                    concrete deck, has the ability to withstand the anticipated uplift forces for
                    each classification.
                </p>
                <p>Screw type fasteners used with structural concrete decks to secure insulations
                    or roof covers usually require different thread design than those used with
                    other decks. Unless otherwise noted, listings with concrete deck pertain to
                    structural concrete deck only with a minimum ultimate compressive strength of
                    2500 psi (17.2 MPa).
                </p>
                <p>
                    Due to the non-combustible nature of Structural Concrete, FM&nbsp;Approvals
                    does not recognize the need to list manufacturers of the decks having a
                    compressive strength of 2500 psi (17.2 MPa) and a density of approximately 150
                    lb/ft 3 (2400 kg/m 3 ).</p>
            </div>
    },
    {
        id: 'S14',
        name: 'Stress Distributor',
        definition:
            <div>
                <p>
                    A stress distributor is metal or plastic disk or bar designed to distribute a
                    concentrated load over a larger surface area.</p>
            </div>
    },
    {
        id: 'S15',
        name: 'Stress Plate',
        definition:
            <div>
                <p>
                    A stress plate is a metal or plastic disk which is used in conjunction with a
                    fastener to secure roof components.</p>
            </div>
    },
    {
        id: 'S16',
        name: 'Structure',
        definition:
            <div>
                <p>
                    The structure is the building framework to which the roof assembly is fastened.
                </p>
            </div>
    },
    {
        id: 'S17',
        name: 'Substrate',
        definition:
            <div>
                <p>
                    The substrate if the surface upon which a component is applied (e.g.,
                    in roofing, the structural deck or insulation).</p>
            </div>
    },
    {
        id: 'S18',
        name: 'Surfacing',
        definition:
            <div>
                <p>
                    Surfacing is the top layer or layers of a roof covering. It may be specified or designed to protect the underlying roofing from direct exposure to the weather.</p>
            </div>
    },
    {
        id: 'T1',
        name: 'Thermal Barrier',
        definition:
            <div>
                <p>
                    A thermal barrier is a rigid&nbsp;board stock material&nbsp;placed on the roof
                    deck to slow the temperature rise to the roof system during an interior
                    building fire and to delay involvement of the roof system in the fire.&nbsp; A
                    thermal barrier layer must be the <b>bottom</b> &nbsp;board stock
                    layer.&nbsp; It must be made of an appropriate material as follows:&nbsp; a
                    core or bottom facer material of gypsum; mineral wool; or perlite.&nbsp; For
                    assemblies with spray applied insulation over a board stock insulation, the
                    board stock insulation will either be&nbsp;designated a thermal barrier layer
                    or an insulation layer&nbsp;as determined by&nbsp;the materials.&nbsp;&nbsp;</p>
                <p>For&nbsp;more information about how insulation layers within assemblies are
                    classified in RoofNav, click <a id="SD" className='glossary-link' href="boardstockrules" target='_blank'>
                        here</a></p>
            </div>
    },
    {
        id: 'T2',
        name: 'Thermal Insulation',
        definition:
            <div>
                <p>
                    Thermal&nbsp;insulation is a material applied to reduce the flow of heat.</p>
            </div>
    },
    {
        id: 'T3',
        name: 'Top Coat',
        definition:
            <div>
                <p>
                    The top coat is the final application of liquid applied material when building
                    a liquid applied roof cover.</p>
            </div>
    },
    {
        id: 'T4',
        name: 'Torch-Applied',
        definition:
            <div>
                <p>
                    Torch Applied describes a method used in the installation of polymer modified
                    bitumen membranes characterized by using open flame propane torch equipment.</p>
            </div>
    },
    {
        id: 'T5',
        name: 'Treated Wood Roof Deck',
        definition:
            <div>
                <p>Impregnated fire-retardant treated lumber or plywood is of low combustibility
                    and FM Approved for roof deck construction without automatic sprinkler
                    protection.</p>
                <p>The wood is treated by pressure impregnation with chemicals.</p>
                <p>The roof must be supported by members of no greater combustibility than the deck
                    itself. The building occupancy should not possess a high-humidity exposure to
                    the deck.</p>
                <p>The deck thickness must be a minimum nominal 2 in. (50 mm) for lumber and 3/4
                    in. (19 mm) for plywood. Lumber shall be tongue-and-groove or splined together
                    at side joints. Plywood must be exterior glue grade and contain
                    tongue-and-groove or shiplap longitudinal edges. It is recommended that the
                    material be precut as much as possible and protected from weather while stored
                    at the job site.</p>
                <p>Fasteners used to secure insulation or roof covers to lumber deck must penetrate
                    a minimum of 1 in. (25 mm). Fasteners used to secure insulation or roof covers
                    to plywood deck must protrude through the deck a minimum of 1/4 in. (6 mm).</p>
                <p>Refer to FM Property Loss Prevention Data Sheet 1-29 for securement
                    procedures of the deck to the structural supports.</p>
            </div>
    },
    {
        id: 'U1',
        name: 'Underside Securement',
        definition:
            <div>
                <p>
                    Underside Securement&nbsp;is a&nbsp;securement element, typically strips or
                    plates, secured to the deck through the insulation prior to the roof cover
                    installation.&nbsp; The roof cover is then installed and adhered to the element
                    without penetrating the cover.</p>
            </div>
    },
    {
        id: 'V1',
        name: 'Vapor Retarder',
        definition:
            <div>
                <p>
                    A vapor retarder is a layer or layers of material, or a laminate, used to
                    appreciably reduce the flow of water vapor into a roof assembly.</p>
            </div>
    },
    {
        id: 'V2',
        name: 'Vented/Venting',
        definition:
            <div>
                <p>
                    A vent is an opening designed to convey air, heat, water vapor or gas from
                    inside a building or building component to the atmosphere.&nbsp; For example, a
                    vented base sheet is installed with an air space between it and the substrate
                    below it, providing a passageway for venting of the roof assembly.
                </p>
            </div>
    },
    {
        id: 'V3',
        name: 'Vermiculite',
        definition:
            <div>
                <p>
                    Vermiculite is an aggregate used in some lightweight insulation concretes,
                    formed by the heating and consequent expansion of a micaceous mineral.</p>
            </div>
    },
    {
        id: 'W1',
        name: 'Weld',
        definition:
            <div>
                <p>A weld is a type of securement whereby metal or plastic products are joined
                    together through heat fusion.</p>
            </div>
    },
    {
        id: 'W2',
        name: 'Weld, Double',
        definition:
            <div>
                <p>
                    Double Weld describes membrane laps which incorporate 2 welds, with the
                    membrane lap fasteners installed between the welds.</p>
            </div>
    },
    {
        id: 'W3',
        name: 'Weld, Single',
        definition:
            <div>
                <p>
                    Single Weld describes membrane laps which are sealed with one weld, typically
                    1-2 in. (25-50 mm) wide along the outside edge of the top membrane.</p>
            </div>
    },
    {
        id: 'W4',
        name: 'Width, Inside',
        definition:
            <div>
                <p>
                    Inside Width is the width of a membrane weld which starts at or near the edge
                    of the lower membrane in a membrane lap.</p>
            </div>
    },
    {
        id: 'W5',
        name: 'Width, Outside',
        definition:
            <div>
                <p>
                    Outside Width is the width of a membrane weld which runs along the edge of the
                    top membrane in a membrane lap.</p>
            </div>
    },
    {
        id: 'W6',
        name: 'Wind Uplift',
        definition:
            <div>
                <p>
                    Wind uplift is the force generated by wind on a roof system or components in a
                    roof system resulting from wind-induced pressure.</p>
                <p>
                    The Wind Uplift rating determined by the Ratings Calculator is the result of
                    the calculated velocity pressure multiplied by 2.0 and rounded up to the
                    nearest multiple of 15 psf. &nbsp;This is the Windstorm Classification for the
                    field of the roof only.&nbsp; An FM Approved roof assembly with the same or
                    higher Windstorm Classification is required.
                </p>
            </div>
    },
]