import { IExtendedLayerConfig, ILayer } from '../components/ConfigitProvider';
import { EXCHANGE_VARIABLES } from '../components/constants/EXCHANGE_VARIABLES';
import { LAYERMODELHASURA_MAP } from '../components/constants/LAYERMODELHASURA_MAP';
import { LAYER_DATA, getLayerData, getLayerProps } from '../components/constants/LAYER_DATA';
import { OPERATOR_FILTER_MAP } from '../components/constants/OPERATOR_FILTER_MAP';
import { configureLayer, getCompatibleExtendedLayerConfigs } from '../services/ConfigitService';
import { AllowedValues, Section, SingletonValue, VariableAssignment } from '../types/configurator';
import * as LayerApi from '../utils/LayerApi';

export const AllowMoreLayersAboveTop = (arraySelectedLayers: string[], arrayConfigStrings: string[]) => {
  if (arrayConfigStrings.length === 0) return false;
  if (arraySelectedLayers.length === 0) return true;

  const firstLayer: string = arraySelectedLayers[0];
  for (let i = 0; i < arrayConfigStrings.length; i++) {
    if (!arrayConfigStrings[i].startsWith(firstLayer)) return true;
  }
  return false;
};

export const AllowMoreLayersBelowBottom = (arraySelectedLayers: string[], arrayConfigStrings: string[]) => {
  if (arrayConfigStrings.length === 0) return false;
  if (arraySelectedLayers.length === 0) return true;

  const lastLayer: string = arraySelectedLayers[arraySelectedLayers.length - 1];
  for (let i = 0; i < arrayConfigStrings.length; i++) {
    if (!arrayConfigStrings[i].endsWith(lastLayer)) return true;
  }
  return false;
};

export const AllowMoreLayersBetweenLayers = (
  arraySelectedLayers: string[],
  arrayConfigStrings: string[],
  upLayerIndex: number,
  downLayerIndex: number
) => {
  if (arrayConfigStrings.length === 0) return false;
  if (arraySelectedLayers.length === 0) return true;

  const upLayer: string = arraySelectedLayers[upLayerIndex];
  const downLayer: string = arraySelectedLayers[downLayerIndex];
  const twoLayersTogether = upLayer + ',' + downLayer;
  for (let i = 0; i < arrayConfigStrings.length; i++) {
    if (arrayConfigStrings[i].indexOf(twoLayersTogether) === -1) return true;
  }
  return false;
};

export const LayerTypesAllowedAboveTop = (arraySelectedLayers: string[], arrayConfigStrings: string[]) => {
  if (arrayConfigStrings.length === 0) return [];
  if (arraySelectedLayers.length === 0) return GetAllLayersNoDuplicates(arrayConfigStrings);

  let allowedLayers: string[] = [];
  const firstLayer: string = arraySelectedLayers[0];
  for (let i = 0; i < arrayConfigStrings.length; i++) {
    const indexFirstLayer = arrayConfigStrings[i].indexOf(firstLayer);
    if (indexFirstLayer > 0) {
      const layers = arrayConfigStrings[i].substring(0, indexFirstLayer - 1).split(',');
      allowedLayers = [...allowedLayers, ...layers];
    }
  }

  const allowedLayersNoDuplicates = Array.from(new Set(allowedLayers));
  return allowedLayersNoDuplicates;
};

export const LayerTypesAllowedBelowBottom = (arraySelectedLayers: string[], arrayConfigStrings: string[]) => {
  if (arrayConfigStrings.length === 0) return [];
  if (arraySelectedLayers.length === 0) return GetAllLayersNoDuplicates(arrayConfigStrings);

  let allowedLayers: string[] = [];
  const lastLayer: string = arraySelectedLayers[arraySelectedLayers.length - 1];
  for (let i = 0; i < arrayConfigStrings.length; i++) {
    const indexLastLayer = arrayConfigStrings[i].indexOf(lastLayer);
    if (indexLastLayer < arrayConfigStrings[i].length - 2) {
      const layers = arrayConfigStrings[i].substring(indexLastLayer + 3).split(',');
      allowedLayers = [...allowedLayers, ...layers];
    }
  }

  const allowedLayersNoDuplicates = Array.from(new Set(allowedLayers));
  return allowedLayersNoDuplicates;
};

export const LayerTypesAllowedBetweenLayers = (
  arraySelectedLayers: string[],
  arrayConfigStrings: string[],
  upLayerIndex: number,
  downLayerIndex: number
) => {
  if (arrayConfigStrings.length === 0) return [];
  if (arraySelectedLayers.length === 0) return GetAllLayersNoDuplicates(arrayConfigStrings);

  let allowedLayers: string[] = [];
  const upLayer: string = arraySelectedLayers[upLayerIndex];
  const downLayer: string = arraySelectedLayers[downLayerIndex];
  for (let i = 0; i < arrayConfigStrings.length; i++) {
    const indexUpLayer = arrayConfigStrings[i].indexOf(upLayer);
    const indexDownLayer = arrayConfigStrings[i].indexOf(downLayer, indexUpLayer + 1);
    if (indexUpLayer > 0 && indexDownLayer > indexUpLayer) {
      const layers = arrayConfigStrings[i].substring(indexUpLayer + 3, indexDownLayer - 1).split(',');
      allowedLayers = [...allowedLayers, ...layers];
    }
  }

  const allowedLayersNoDuplicates = Array.from(new Set(allowedLayers));
  return allowedLayersNoDuplicates;
};

export const GetAllLayersNoDuplicates = (arrayConfigStrings: string[]) => {
  let allowedLayers: string[] = [];
  for (let i = 0; i < arrayConfigStrings.length; i++) {
    const layers = arrayConfigStrings[i].split(',');
    allowedLayers = [...allowedLayers, ...layers];
  }
  const allowedLayersNoDuplicates = Array.from(new Set(allowedLayers));
  return allowedLayersNoDuplicates;
};

export const insertLayer = (layers: string[], layer: string, addLayerAt: number) => {
  return layers?.length
    ? [...layers.slice(0, addLayerAt), String(layer), ...layers.slice(addLayerAt)]
    : [String(layer)];
};

const matchingLayerConfigUtil = (
  layers: string[],
  excludedLayers: string[],
  layerConfigs: SingletonValue[],
  singleMatch?: boolean,
  configObjs?: boolean
) => {
  const matchingConfigIds: string[] = [];
  const matchingConfigs: SingletonValue[] = [];
  const layerRegex = new RegExp(`^.*${layers.join(',.*')}.*$`);
  const excludedLayerRegex = new RegExp(`^.*(${excludedLayers.join('|')}).*$`);
  for (let i = 0; i < layerConfigs.length; i++) {
    const allowedValue = layerConfigs[i];
    const hasLayers = layers.length ? layerRegex.test(String(allowedValue.name)) : true;
    const hasExcludedLayers = excludedLayers.length ? excludedLayerRegex.test(String(allowedValue.name)) : false;
    if (hasLayers && !hasExcludedLayers) {
      if (configObjs) matchingConfigs.push(layerConfigs[i]);
      else matchingConfigIds.push(String(allowedValue.value));
      if (singleMatch) break;
    }
  }
  if (configObjs) return matchingConfigs;
  return matchingConfigIds;
};

export const getMatchingLayerConfigIds = (
  layers: string[],
  excludedLayers: string[],
  layerConfigs: SingletonValue[]
): string[] => {
  const matchingConfigIds: any[] = matchingLayerConfigUtil(layers, excludedLayers, layerConfigs);
  return matchingConfigIds;
};

export const getMatchingLayerConfigs = (
  layers: string[],
  excludedLayers: string[],
  layerConfigs: SingletonValue[]
): SingletonValue[] => {
  const matchingConfigIds: any[] = matchingLayerConfigUtil(layers, excludedLayers, layerConfigs, false, true);
  return matchingConfigIds;
};

export const isLayeConfigMatching = (layers: string[], excludedLayers: string[], layerConfigs: SingletonValue[]) => {
  const matchingConfigIds: any[] = matchingLayerConfigUtil(layers, excludedLayers, layerConfigs, true);
  return matchingConfigIds.length ? true : false;
};

const isAllLayerConfigsMatching = (pattern: RegExp, layerConfigs: string[]) => {
  for (let i = 0; i < layerConfigs.length; i++) {
    if (!pattern.test(String(layerConfigs[i]))) return false;
  }
  return true;
};

const getAutoSelectedLayersBFS = (sampleLayers: string[], layerConfigs: string[], layerRegex?: RegExp): string[] => {
  let level = sampleLayers.length;
  const queue: string[][] = [];
  queue.push(sampleLayers);
  let autoSelectedLayers: any = {};
  while (queue.length) {
    const curSampleLayers = queue.shift();
    if (curSampleLayers?.length !== undefined) {
      if (curSampleLayers.length < level) {
        const autoSelectedLayerKeys = Object.keys(autoSelectedLayers);
        if (autoSelectedLayerKeys.length === 1) return autoSelectedLayers[autoSelectedLayerKeys[0]] as string[];
        if (autoSelectedLayerKeys.length > 1) {
          return getAutoSelectedLayersBFS(
            autoSelectedLayers[autoSelectedLayerKeys[0]],
            autoSelectedLayerKeys.map((data) => autoSelectedLayers[data].join(',')),
            layerRegex
          );
        }
        autoSelectedLayers = {};
        level = curSampleLayers.length;
        if (level === 0) return [];
      }
      const sampleLayersRegexStr = `^.*${curSampleLayers.join(',.*')}.*$`;
      const sampleLayersRegex = new RegExp(sampleLayersRegexStr);
      let match = true;
      if (layerRegex && !isAllLayerConfigsMatching(layerRegex, [curSampleLayers.join(',')])) match = false;
      if (!isAllLayerConfigsMatching(sampleLayersRegex, layerConfigs)) match = false;
      if (match) autoSelectedLayers[sampleLayersRegexStr] = curSampleLayers;
      for (let i = 0; i < curSampleLayers.length; i++) {
        const nextSampleLayers = curSampleLayers.filter((layer, index) => index !== i);
        const alreadyAdded = queue.find((data) => data.join(',') === nextSampleLayers.join(','));
        if (!alreadyAdded) queue.push(nextSampleLayers);
      }
    }
  }
  return [];
};

const getSmallestLayerConfigs = (layerConfigs: string[]) => {
  let size = layerConfigs[0].length;
  for (let i = 0; i < layerConfigs.length; i++) {
    const temp = layerConfigs[i];
    if (size && temp?.length && temp?.length < size) size = temp?.length;
  }
  const result: string[] = [];
  for (let i = 0; i < layerConfigs.length; i++) {
    const temp = layerConfigs[i];
    if (size && temp?.length === size) result.push(temp);
  }
  return result;
};

export const getAutoSelectedLayers = (layerConfigs: string[], userSelectedLayers: string[]) => {
  if (!layerConfigs.length) return [];
  const smallestLayerConfigs = getSmallestLayerConfigs(layerConfigs);
  const sampleLayers = smallestLayerConfigs[0].split(',');
  return getAutoSelectedLayersBFS(
    sampleLayers,
    layerConfigs,
    userSelectedLayers?.length ? new RegExp(`^.*${userSelectedLayers.join(',.*')}.*$`) : undefined
  );
};

export const getLayerDataById = (id: string) => {
  let layerData: SingletonValue | undefined;
  for (let i = 0; i < LAYER_DATA.length; i++) {
    layerData = LAYER_DATA[i].layers.find((data) => data.value === id);
    if (layerData) break;
  }
  return layerData;
};

export const getLayersToExclude = (selectedLayers: string[], excludedLayers: string[]) => {
  return getLayerData()
    .map((layerType) =>
      layerType.layers.map((layer) => {
        layer.incompatible = selectedLayers.indexOf(String(layer.value)) > -1;
        layer.assigned = excludedLayers.indexOf(String(layer.value)) > -1 ? 'byUser' : undefined;
        return layer;
      })
    )
    .flat(1);
};

export const updateLayerFilter = (layers: ILayer[], filters: any) => {
  filters._and = [];
  layers.forEach((layer) => {
    const layerData = getLayerDataById(layer.id);
    const layerProps = getLayerProps(layerData);
    const model = layerProps.model ? (layerProps.model as string) : '--NA--';
    const view = LAYERMODELHASURA_MAP[model];
    if (!view) return;
    const filter: any = {};
    filter[view] = {};
    let filterApplied = false;
    layer.variableAssignments?.forEach((data) => {
      const variable = layer.section?.variables?.find((variable) => variable.id === data.variableId);
      if (variable && variable.id) {
        const type = variable.properties && variable.properties[0] && variable.properties[0].value;
        if (
          variable.id === 'ManufacturerId' ||
          variable.id === 'ComponentId' ||
          variable.id === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId' ||
          EXCHANGE_VARIABLES.includes(`${variable.id}_view`) ||
          variable.id.endsWith('Operator')
        )
          return;
        if (variable.id === 'ComponentId_view')
          filter[view]['ComponentId'] = { _in: data.allowedValues?.map((obj) => parseInt(String(obj.value))) };
        else if (type === 'enum' || type === 'codetable')
          filter[view][variable.id] = { _eq: parseInt(String(data.value)) };
        else if (type === 'decimal') filter[view][variable.id] = { _eq: parseFloat(String(data.value)) };
        else if (type === 'measurement') {
          const operator = layer.variableAssignments?.find(
            (data) => data.variableId === `${variable.id}Operator`
          )?.value;
          if (!operator) return;
          filter[view][variable.id] = {};
          const PRECISIONTOLERANCE = 0.0001;
          switch (String(operator)) {
            case 'Equal':
              filter[view][variable.id]['_gte'] = parseFloat(String(data.value)) - PRECISIONTOLERANCE;
              filter[view][variable.id]['_lte'] = parseFloat(String(data.value)) + PRECISIONTOLERANCE;
              break;
            case 'BiggerOrEqual':
              filter[view][variable.id][OPERATOR_FILTER_MAP[String(operator)]] =
                parseFloat(String(data.value)) - PRECISIONTOLERANCE;
              break;
            case 'LessOrEqual':
              filter[view][variable.id][OPERATOR_FILTER_MAP[String(operator)]] =
                parseFloat(String(data.value)) + PRECISIONTOLERANCE;
              break;
            default:
              filter[view][variable.id][OPERATOR_FILTER_MAP[String(operator)]] = parseFloat(String(data.value));
          }
        } else if (type === 'boolean') filter[view][variable.id] = { _eq: Boolean(String(data.value)) };
        else filter[view][variable.id] = { _eq: data.value };
        filterApplied = true;
      }
    });
    if (filterApplied) filters._and.push(filter);
  });
  return filters;
};

export const updateExchangeVariableAssignments = async (
  layers: ILayer[],
  excludedLayerIds: string[],
  variableAssignments: VariableAssignment[],
  sections: Section[],
  allExtendedLayerConfigs: IExtendedLayerConfig[]
) => {
  const compatibleExtendedLayerConfigs = getCompatibleExtendedLayerConfigs(sections, allExtendedLayerConfigs);
  const matchingExtendedLayerConfigs = LayerApi.getMatchingLayerConfigs(
    layers.filter((data) => !data.autoSelected),
    excludedLayerIds,
    compatibleExtendedLayerConfigs
  );
  const newVariableAssignments = [
    ...variableAssignments.filter(data => data.value),
    {
      variableId: 'ExtendedLayerConfigSplit_ExtendedLayerConfigId',
      type: 'AllowedValues',
      allowedValues: matchingExtendedLayerConfigs.map((data) => {
        return { type: 'SingletonValue', value: String(data.ExtendedLayerConfigId) } as AllowedValues;
      }),
    } as VariableAssignment,
  ];
  for (let i = 0; i < layers.length; i++) {
    const layer = layers[i];
    if (layer.variableAssignments && layer.model) {
      if (!layer.section) {
        layer.section = await configureLayer(
          layer.variableAssignments.filter(data =>
            data.variableId && data.variableId !== 'ComponentId' &&
            data.variableId !== 'ExtendedLayerConfigSplit_ExtendedLayerConfigId'
          ),
          layer.model
        );
      }
      const values = layer.section?.variables
        ?.find(data => data.id === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId')?.values
        ?.map((data: SingletonValue) => String(data.value));
      const variableAssignment: VariableAssignment = {
        ...newVariableAssignments.find(
          data => data.variableId === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId'
        )
      };
      if (variableAssignment?.allowedValues) {
        variableAssignment.allowedValues = variableAssignment?.allowedValues?.filter(
          data => values?.includes(String(data.value))
        );
      }
      layer.section = await configureLayer(
        layer.variableAssignments.filter(data =>
          data.variableId && data.variableId !== 'ComponentId' &&
          data.variableId !== 'ExtendedLayerConfigSplit_ExtendedLayerConfigId'
        ).concat(variableAssignment),
        layer.model
      );
    }
  }
  let relativeSeqNum = 0;
  const layerTypeIdVA: VariableAssignment[] = [];
  const exchangeVariableAssignments = layers.map((layer, index) => {
    if (layer.isSecurementLayer) relativeSeqNum++;
    if (layer.variableAssignments && layer.model) {
      const allowedLayerConfigs = layer.section?.variables
        ?.find(data => data.id === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId')?.values;
      const extendedLayerConfigIdVA = newVariableAssignments
        .find(data => data.variableId === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId');
      if (extendedLayerConfigIdVA?.allowedValues) {
        extendedLayerConfigIdVA.allowedValues = extendedLayerConfigIdVA.allowedValues.filter(data =>
          !allowedLayerConfigs?.find((obj: SingletonValue) => obj.value === data.value)?.incompatible
        );
      }
      let allowedValues = layer.variableAssignments
        .find(data => data.variableId === 'ComponentId_view')?.allowedValues;
      if (!allowedValues) {
        // The response of Configit call to specification layer has no "ComponentId_view" variable
        const componentVariableId = (layer.id === '25' || layer.id === '35') ? 'ComponentId' : 'ComponentId_view';
        allowedValues = layer.section?.variables
          ?.find(data => data.id === componentVariableId)?.values
          ?.filter(data => !data.incompatible)
          ?.map((data: SingletonValue) => {
            return { type: data.type, value: data.value } as AllowedValues;
          });
      }
      const layerData = getLayerDataById(layer.id);
      const layerProps = getLayerProps(layerData);
      let variableId = `ComponentId_${layer.model}`;
      if (layer.isSecurementLayer) {
        variableId = `ComponentId_GenericSecurement${relativeSeqNum}`;
        layerTypeIdVA.push({
          variableId: `LayerTypeId_GenericSecurement${relativeSeqNum}`,
          value: layer.id
        });
      }
      if (layerProps.hasDuplicates) {
        let layerRelativeSeqNum = 0;
        for (let i = 0; i < layers.length; i++) {
          if (layers[i].id === layer.id) layerRelativeSeqNum++;
          if (i === index) break;
        }
        variableId = `ComponentId_${layer.model}${layerRelativeSeqNum}`;
      }
      return {
        variableId,
        type: 'AllowedValues',
        allowedValues
      } as VariableAssignment;
    }
    return {} as VariableAssignment;
  }).filter(data => data.variableId);
  return newVariableAssignments.concat(exchangeVariableAssignments);
};

export const updateSelfSecurementFilter = (layers: ILayer[], filters: any) => {
  // Since this method may be called after updateLayerFilter
  // We should not reset _and property to empty array, otherwise it will erase layer search criteria
  //filters._and = [];
  layers.forEach((layer) => {
    // Only when this layer has self-securement and this self-securement has variableAssignments, filter criteria may be available
    if (!layer.hasSelfSecurement || !layer.selfSecurement || !layer.selfSecurement.variableAssignments) return;

    const layerData = getLayerDataById(layer.id);
    const layerProps = getLayerProps(layerData);
    const model = layerProps.model ? (layerProps.model as string) : '--NA--';
    let view = LAYERMODELHASURA_MAP[model];
    if (!view) return;
    view = view + '_SelfSecurement'; // make the view name is different from component layer view
    const filter: any = {};
    filter[view] = {};
    let filterApplied = false;

    layer.selfSecurement.variableAssignments?.forEach((data) => {
      const variable = layer.selfSecurement?.section?.variables?.find((variable) => variable.id === data.variableId);
      if (variable && variable.id) {
        const type = variable.properties && variable.properties[0] && variable.properties[0].value;
        if (
          variable.id === 'ManufacturerId' ||
          variable.id === 'ComponentId' ||
          variable.id === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId' ||
          EXCHANGE_VARIABLES.includes(`${variable.id}_view`) ||
          variable.id.endsWith('Operator')
        )
          return;
        if (variable.id === 'ComponentId_view')
          filter[view]['ComponentId'] = { _in: data.allowedValues?.map((obj) => parseInt(String(obj.value))) };
        else if (type === 'enum' || type === 'codetable')
          filter[view][variable.id] = { _eq: parseInt(String(data.value)) };
        else if (type === 'decimal') filter[view][variable.id] = { _eq: parseFloat(String(data.value)) };
        else if (type === 'measurement') {
          const operator = layer.selfSecurement?.variableAssignments?.find(
            (data) => data.variableId === `${variable.id}Operator`
          )?.value;
          if (!operator) return;
          filter[view][variable.id] = {};
          const PRECISIONTOLERANCE = 0.0001;
          switch (String(operator)) {
            case 'Equal':
              filter[view][variable.id]['_gte'] = parseFloat(String(data.value)) - PRECISIONTOLERANCE;
              filter[view][variable.id]['_lte'] = parseFloat(String(data.value)) + PRECISIONTOLERANCE;
              break;
            case 'BiggerOrEqual':
              filter[view][variable.id][OPERATOR_FILTER_MAP[String(operator)]] =
                parseFloat(String(data.value)) - PRECISIONTOLERANCE;
              break;
            case 'LessOrEqual':
              filter[view][variable.id][OPERATOR_FILTER_MAP[String(operator)]] =
                parseFloat(String(data.value)) + PRECISIONTOLERANCE;
              break;
            default:
              filter[view][variable.id][OPERATOR_FILTER_MAP[String(operator)]] = parseFloat(String(data.value));
          }
        } else if (type === 'boolean') filter[view][variable.id] = { _eq: Boolean(String(data.value)) };
        else filter[view][variable.id] = { _eq: data.value };
        filterApplied = true;
      }
    });
    if (filterApplied) filters._and.push(filter);
  });
  return filters;
};