import { ToggleButton, Typography } from "@mui/material";
import React, { HTMLAttributes } from "react";
import theme from "../../../theme";
//import './ConfigitButtonGroup.scss';
import { StyledToggleButton } from "../ConfigitButtonGroup/ConfigitButtonGroupComponent";
import { ISelectValue } from "../SelectComponent/SelectBox";

interface IToggleButtonGroupProps extends HTMLAttributes<HTMLDivElement> {
    id?: string;
    label?: string;
    options: ISelectValue[];
    selectedOption?: ISelectValue;
    className?: string;
    handleSelect: (option: ISelectValue) => void;
}


export const ToggleButtonComponent = ({
    id,
    label,
    options,
    selectedOption,
    className,
    handleSelect,
    ...other
}: IToggleButtonGroupProps) => {
    return (<>
        {options.map((option: ISelectValue, index) => {

            return (<React.Fragment key={index}>

                <StyledToggleButton
                    id={(id ? id : 'undefinedId') + index}
                    data-testid={(id ? id : 'undefinedId') + index}
                    disableRipple
                    key={index}
                    value={option.value ? option.value : ''}
                    className={`col ${selectedOption === option ? 'Mui-selected' : 'deselected'
                        } ${className}`}
                    onClick={() => (option !== selectedOption ? handleSelect(option) : handleSelect({ value: -1, name: 'None Selected' }))}
                    aria-pressed="true"
                >
                    <Typography className={'scaled-font'} fontSize={12} fontFamily={"Inter"} fontWeight={500}>{option.name}</Typography>
                </StyledToggleButton>

            </React.Fragment>
            );
        })}
    </>
    );
};