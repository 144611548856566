import { NavigationClient } from '@azure/msal-browser';
import React from 'react';
import { NavigateFunction, Routes as ReactRoutes, Route, useNavigate } from 'react-router-dom';
import { azureAADPublicClientApplication } from './Auth';
import { Calculator } from './components/Calculator';
import LoadDocument from './components/Document/LoadDocument';
import { LandingComponent } from './components/Landing';
import { MatchingAssemblies } from './components/MatchingAssemblies';
import LoadReportBug from './components/ReportBug/LoadReportBug';
import { SavedItemsComponent } from './components/SavedItems';
import ScrollToTop from './components/ScrollToTop';
import LoadShareSearch from './components/ShareSearch/LoadShareSearch';
import { Default } from './layouts/Default';
import { AssemblyDetail } from './pages/AssemblyDetail';
import { Home } from './pages/Home';
import { PageNotFound } from './pages/PageNotFound';
import { ProductSeachComponent } from './components/ProductSearch';
import { ProductList } from './components/ProductSearch/ProductList';
import { ProductDetail } from './components/ProductSearch/ProductDetail';
import { ProductSearchResults } from './components/ProductSearch/ProductSearchResults';
import { ProductSearchHome } from './pages/ProductSearch';
import QuickStartGuide from './components/Document/QuickStartGuide';
import { Help } from './pages/RefMaterial';
import { GuideLines } from './pages/RefMaterial/guidelines';
import { DataSheetsView } from './pages/RefMaterial/datasheetsview';
import { ApprovalStandard } from './pages/RefMaterial/approvalstandard';
import { Glossary } from './pages/RefMaterial/Glossary';
import { BoardStockRules } from './pages/RefMaterial/BoardStockRules';
import { ContractorPackageReport } from './components/ContractorPackageReport';
import { SavedAssemblyDetail } from './components/SavedItems/SavedAssembly';
import { AssemblyListProductApprovedUse } from './components/ProductSearch/AssemblyListProductApprovedUse';

// helps with routing performance
// per https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/performance.md
class CustomNavigationClient extends NavigationClient {
  navigate: NavigateFunction;
  constructor(navigate: NavigateFunction) {
    super();
    this.navigate = navigate; // Passed in from useNavigate hook provided by react-router-dom;
  }

  // This function will be called anytime msal needs to navigate from one page in your application to another
  async navigateInternal(url: string, options: { [name: string]: unknown }) {
    // url will be absolute, you will need to parse out the relative path to provide to the history API
    const relativePath = url.replace(window.location.origin, '');
    if (options.noHistory) {
      this.navigate(relativePath, { replace: true });
    } else {
      this.navigate(relativePath);
    }

    return false;
  }
}

const Routes: React.FC = () => {
  //helps with routing performance, see above
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  azureAADPublicClientApplication.setNavigationClient(navigationClient);

  return (
    <>
      <ScrollToTop />
      <ReactRoutes>
        <Route element={<Default />} path="/">
          <Route element={<Home />} path="/">
            <Route index element={<LandingComponent />} />
            <Route element={<ProductSearchResults />} path="productSearchResults" />
            <Route element={<LoadReportBug />} path="loadReportBugSearchCriteria/:ReportBugGuid" />
            <Route element={<LoadShareSearch />} path="loadShareLinkSearchCriteria/:ShareLinkGuid" />
            <Route element={<MatchingAssemblies />} path="assembliesListing">
              <Route element={<AssemblyDetail />} path="assemblyDetail/:assemblyid" />
            </Route>
            <Route element={<Calculator />} path="ratingsCalculator" />
          </Route>
          <Route element={<ProductSearchHome />} path="/products">
            <Route index element={<LandingComponent />} />
            <Route element={<ProductSearchResults />} path="productSearchResults" />
          </Route>
          <Route element={<Home />} path="/home">
            <Route index element={<LandingComponent />} />
            <Route element={<ProductSearchResults />} path="productSearchResults" />
            <Route element={<MatchingAssemblies />} path="assembliesListing">
              <Route element={<AssemblyDetail />} path="assemblyDetail/:assemblyid" />
            </Route>
            <Route element={<Calculator />} path="ratingsCalculator" />
          </Route>
          <Route element={<QuickStartGuide />} path="quickstart" />
          <Route element={<SavedItemsComponent />} path="/savedItems/:guid" />

          <Route element={<SavedAssemblyDetail />} path="savedAssemblyDetail/:scenarioid" />
          <Route element={<AssemblyDetail />} path="assemblyDetail/:assemblyid" />
          <Route element={<LoadDocument />} path="loaddocument/:documentid" />
          <Route element={<ProductSeachComponent />} path="/productSearch" />
          <Route element={<ProductList />} path="/productList" />
          <Route element={<ProductDetail />} path="/productDetail/:componentid" />
          <Route element={<AssemblyListProductApprovedUse />} path="/assemblyListProductApprovedUse" />
          <Route element={<Help />} path="/help" />
          <Route element={<GuideLines />} path="/guidelines" />
          <Route element={<DataSheetsView />} path="/datasheetsview" />
          <Route element={<ApprovalStandard />} path="/standardsview" />
          <Route element={<BoardStockRules />} path="/boardstockrules" />
          <Route element={<Glossary />} path="/glossary" />
          <Route element={<ContractorPackageReport />} path="/contractorpackagereport/:scenarioid" />
          <Route element={<PageNotFound />} path="*" />
        </Route>
      </ReactRoutes>
    </>
  );
};

export default Routes;
