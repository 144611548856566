import React from 'react';
import { useGetFasteningSystemStressPlateUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { Area } from '../../utils/Measurement/Area';
import { Length } from '../../utils/Measurement/Length';
import './UsageDetails.scss';

export const FasteningSystemStressPlateUsageDetail = (props: any) => {
  const { data, loading, error } = useGetFasteningSystemStressPlateUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];
  const isMetric = usage?.EnteredAsMetric;

  return (
    <div className={'usage-detail p-3'}>
      <h4>Fastening System (Stress Plate)</h4>
      <table>
        <tr>
          <td>Row Spacing:</td>
          <td>{Length.display(usage?.FasteningSystemStressPlateUsage?.RowSpacing, isMetric)}</td>
        </tr>
        <tr>
          <td>On Center:</td>
          <td>{Length.display(usage?.FasteningSystemStressPlateUsage?.OnCenter, isMetric)}</td>
        </tr>
        <tr>
          <td>Fasteners per Plate/Clip:</td>
          <td>{usage?.FasteningSystemStressPlateUsage?.FastenersPerPlateOrClip}</td>
        </tr>
        <tr>
          <td>Field Rows:</td>
          <td>{usage?.FasteningSystemStressPlateUsage?.IsFieldRows ? 'Yes' : 'No'}</td>
        </tr>
        <tr>
          <td>Field Row on Center:</td>
          <td>{Length.display(usage?.FasteningSystemStressPlateUsage?.FieldRowsOnCenter, isMetric)}</td>
        </tr>
        <tr>
          <td>Number of Field Rows:</td>
          <td>{usage?.FasteningSystemStressPlateUsage?.NumFieldRows}</td>
        </tr>
        <tr>
          <td>Application Method:</td>
          <td>{usage?.FasteningSystemStressPlateUsage?.C_FasteningApplicationMethod.FasteningApplicationMethodCode}</td>
        </tr>
        <tr>
          <td>Contributory Area:</td>
          <td>{Area.display(usage?.FasteningSystemStressPlateUsage?.ContributoryArea, isMetric)}</td>
        </tr>
        <tr>
          <td>Embedment:</td>
          <td>{Length.display(usage?.FasteningSystemStressPlateUsage?.Embedment, isMetric)}</td>
        </tr>
        <tr>
          <td valign="top">System Comments:</td>
          <td>{usage?.Component.Comments}</td>
        </tr>
        <tr>
          <td valign="top">Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
