import React from 'react';
import { useGetDeckStructuralConcreteDetailQuery } from '../../models/GQLGeneratedModels';

export const DeckStructuralConcreteDetail = (props: any) => {
  const { data, loading, error } = useGetDeckStructuralConcreteDetailQuery({
    variables: {
      LayerId: parseInt(props.layerId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const layer = data?.StructuralConcreteLayer[0];

  return (
    <div className={'usage-detail p-3'}>
      <h4>(Deck) Structural Concrete</h4>
      <table>
        <tr>
          <td>Note:</td>
          <td>
            Structural Concrete deck must have a minimum compressive strength of 2500 psi (17.24 Mpa) and a minimum
            thickness of 2 inches (50 mm).
          </td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{layer?.Layer.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
