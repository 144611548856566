import React, { useContext, useEffect, useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import theme from '../../theme';
import { TextOnlyComponent } from '../shared/TextOnlyComponent/TextOnlyComponent';

const QuickStartGuide: React.FC = (props: any) => {
  const handleBackClick = () => {
    history.back();
  };
  return (
    <main className="container-fluid ">
      <Box className={'h-100'}>
        <div className="d-flex justify-content-center align-items-center mx-3">
          <Typography color={theme.palette.rnSteel.steel700} className={'py-2 headline-1 d-flex justify-content-center'}>
            Quick Start Guide
          </Typography>

        </div>
        <div className={' d-flex justify-content-center col-12 px-3'}>
          <object
            className={'overflow-auto'}
            width="100%"
            height="1150px"
            type="application/pdf"
            data={'/assets/RoofNav Quick Guide v1.pdf'}
          >
            <embed src={'/assets/RoofNav Quick Guide v1.pdf'} type="application/pdf" />
          </object>
        </div>
      </Box>
    </main>
  );
};

export default QuickStartGuide;
