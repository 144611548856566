import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import theme from '../../../theme';
import "./ToolTipComponent.scss";

interface IToolTip {
  content?: string;
  testId?: string;
  className?: string;
  click?: any;
  color?: string;
  position?: "top" | "bottom" | "right" | "left" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | "right-start" | "right-end" | "left-start" | "left-end" | undefined;
}

export const ToolTipComponent: React.FC<IToolTip> = ({ content, testId, className, click, position, color }: IToolTip) => {
  return (
    <Tooltip
      id={testId}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      onClick={click}
      tabIndex={0}
      componentsProps={{
        tooltip: {
          sx: {
            color: "#fff",
            bgcolor: 'common.black',
            '& .MuiTooltip-arrow': {
              color: 'common.black',
            },
            '& .MuiTooltip-tooltipPlacementRight': {
              margin: '0px 4px !important',
            }
          },
        },
      }}
      title={<Typography fontSize={14}>{(content || '').replace(/<[^>]*>?/gm, '\r\n').replace(/\n\s*\n/g, '\n')}</Typography>}
      className={className ? `${className} rn-tooltip` : "m-2 rn-tooltip"}
      arrow
      placement={position}
      data-testid={testId}>
      <span data-testid="calculatorIcon" className="material-symbols-outlined tooltip-icon" style={{ color: color ? color : theme.palette.rnGrey.grey500 }}>info</span>
    </Tooltip>
  );
};
