import React, { useContext } from 'react';
import { getCompatibleExtendedLayerConfigs } from '../../services/ConfigitService';
import { ConfigurationVariable, SingletonValue } from '../../types/configurator';
import * as LayerApi from '../../utils/LayerApi';
import { getLayerDataById } from '../../utils/LayerTypeCheckUtilities';
import { ConfigitContext, ILayer, IManufacturerProduct, ISecurementLayer } from '../ConfigitProvider';
import { SelectBox } from '../shared/SelectComponent/SelectBox';

export interface ISecurementDetails {
  fromLayerTypeId?: number;
  fromLayerNum?: number;
  toLayerTypeId?: number;
  toLayerNum?: number;
}

interface ISecurementFormProps {
  layerId: string;
  layerIndex: number;
  layers: ILayer[];
  variables: ConfigurationVariable[];
  securementVariables: ConfigurationVariable[];
  handleFormSelect: (variableId: string, value: string | string[]) => void;
  handleFormUnSelect: (variableId: string) => void;
  securementDetails: ISecurementDetails;
  setSecurementDetails: (securementDetails: ISecurementDetails) => void;
  showFromTo: boolean;
}

export const SecurementForm: React.FC<ISecurementFormProps> = ({
  layerId,
  layerIndex,
  layers,
  variables,
  securementVariables,
  handleFormSelect,
  handleFormUnSelect,
  securementDetails,
  setSecurementDetails,
 
  showFromTo,
}: ISecurementFormProps) => {
  const { sections, layerConfigMasterData, excludedLayerIds } = useContext(ConfigitContext);
  const allExtendedLayerConfigs = getCompatibleExtendedLayerConfigs(sections, layerConfigMasterData ?? []);
  let matchingExtendedLayerConfigs = LayerApi.getMatchingLayerConfigs(
    layers.map((data, index) =>
      index === layerIndex
        ? ({
            ...data,
            fromLayerNum: securementDetails.fromLayerNum,
            fromLayerTypeId: securementDetails.fromLayerTypeId,
            toLayerNum: securementDetails.toLayerNum,
            toLayerTypeId: securementDetails.toLayerTypeId,
          } as ISecurementLayer)
        : data
    ),
    excludedLayerIds,
    allExtendedLayerConfigs,
    true,
    false
  );
  const allowedLayerConfigIds = variables
    .find((data) => data.id === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId')
    ?.values?.filter((data) => !data.incompatible)
    .map((data: SingletonValue) => String(data.value));
  matchingExtendedLayerConfigs = matchingExtendedLayerConfigs.filter(
    (data) => allowedLayerConfigIds && allowedLayerConfigIds.includes(String(data.ExtendedLayerConfigId))
  );
  const fromLayers = LayerApi.getSecurementDomain(
    layers.map((data, index) =>
      index === layerIndex
        ? ({
            ...data,
            id: layerId,
            toLayerNum: securementDetails.toLayerNum,
            toLayerTypeId: securementDetails.toLayerTypeId,
          } as ISecurementLayer)
        : data
    ),
    matchingExtendedLayerConfigs,
    layerIndex,
    'from'
  );
  const fromLayersOptions = fromLayers.map((data) => {
    const temp = data.split('-');
    const layerTypeId = temp[0];
    const layerNum = temp[1];
    const name = `${getLayerDataById(layerTypeId)?.name} - ${layerNum ?? 1}`;
    let assigned: 'byUser' | undefined;
    if (Number(layerTypeId) === securementDetails.fromLayerTypeId) {
      if (!layerNum || !securementDetails.fromLayerNum || Number(layerNum) === securementDetails.fromLayerNum)
        assigned = 'byUser';
    }
    return { name, value: data, assigned } as SingletonValue;
  });
  const toLayers = LayerApi.getSecurementDomain(
    layers.map((data, index) =>
      index === layerIndex
        ? ({
            ...data,
            id: layerId,
            fromLayerNum: securementDetails.fromLayerNum,
            fromLayerTypeId: securementDetails.fromLayerTypeId,
          } as ISecurementLayer)
        : data
    ),
    matchingExtendedLayerConfigs,
    layerIndex,
    'to'
  );
  const toLayersOptions = toLayers.map((data) => {
    const temp = data.split('-');
    const layerTypeId = temp[0];
    const layerNum = temp[1];
    const name = `${getLayerDataById(layerTypeId)?.name} - ${layerNum ?? 1}`;
    let assigned: 'byUser' | undefined;
    if (Number(layerTypeId) === securementDetails.toLayerTypeId) {
      if (!layerNum || !securementDetails.toLayerNum || Number(layerNum) === securementDetails.toLayerNum)
        assigned = 'byUser';
    }
    return { name, value: data, assigned } as SingletonValue;
  });

  return (
    <>
      <div className="row px-3 py-1 securement-form">
        {showFromTo && (
          <div className="form-group col-md-6">
            <SelectBox
              id="fromLayer"
              label="From"
              options={fromLayersOptions}
              select={(variableId: string, value: string) => {
                const temp = value.split('-');
                const fromLayerTypeId = Number(temp[0]);
                const fromLayerNum = temp[1] ? Number(temp[1]) : undefined;
                setSecurementDetails({
                  ...securementDetails,
                  fromLayerTypeId,
                  fromLayerNum,
                });
              }}
              unSelect={(variableId: string) => {
                setSecurementDetails({
                  ...securementDetails,
                  fromLayerTypeId: undefined,
                  fromLayerNum: undefined,
                });
              }}
            />
          </div>
        )}
        {showFromTo && (
          <div className="form-group col-md-6">
            <SelectBox
              id="toLayer"
              label="To"
              options={toLayersOptions}
              select={(variableId: string, value: string) => {
                const temp = value.split('-');
                const toLayerTypeId = Number(temp[0]);
                const toLayerNum = temp[1] ? Number(temp[1]) : undefined;
                setSecurementDetails({
                  ...securementDetails,
                  toLayerTypeId,
                  toLayerNum,
                });
              }}
              unSelect={(variableId: string) => {
                setSecurementDetails({
                  ...securementDetails,
                  toLayerTypeId: undefined,
                  toLayerNum: undefined,
                });
              }}
            />
          </div>
        )}
        {securementVariables.map(({ id, name, values }, index) => (
          <div key={index} className="form-group col-md-6">
            {values && (
              <SelectBox
                id={id}
                label={name}
                options={values}
                select={handleFormSelect}
                unSelect={handleFormUnSelect}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};
