export class Area {
  static SQUAREFEET_TO_SQUAREMETERS = 0.0929;
  static IMPERIALUNIT = 'ft2';
  static METRICUNIT = 'm2';

  static convertImperialToMetric(valueImperial: number) {
    const valueMetric = valueImperial * Area.SQUAREFEET_TO_SQUAREMETERS;
    return valueMetric.toFixed(4) + ' ' + Area.METRICUNIT;
  }

  static convertMetricToImperial(valueMetric: number) {
    const valueImperial = valueMetric / Area.SQUAREFEET_TO_SQUAREMETERS;
    return valueImperial.toFixed(4) + ' ' + Area.IMPERIALUNIT;
  }

  static displayMetric(valueMetric: number) {
    return valueMetric.toFixed(4) + ' ' + Area.METRICUNIT;
  }

  static display(valueMetric: number, showAsMetric: boolean) {
    if (showAsMetric) return Area.displayMetric(valueMetric);
    else return Area.convertMetricToImperial(valueMetric);
  }
}
