import { faCircle, faStopCircle, faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { faStop, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, styled } from '@mui/material';
import React, { HTMLAttributes, useContext } from 'react';
import theme from '../../../theme';

interface ISavedChipComponentProps extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  label?: string;
  className?: string;
  isArchived?: boolean;
  isDisabled?: boolean;
  click?: any;
}

const ActiveChip = styled(Chip)({
  '&.MuiChip-root': {
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Inter',
    height: 'fit-content',
    lineHeight: '20px',
    color: theme.palette.rnGreen.green800,
    backgroundColor: theme.palette.rnGreen.green100,
    padding: '2px 8px',
    borderRadius: '12px',
  },
  '&.MuiChip-root:hover': {
    color: theme.palette.rnGreen.green800,
    backgroundColor: theme.palette.rnGreen.green100,
  },
  '& .MuiChip-label': {
    padding: '0px',
  },
});

const ArchivedChip = styled(Chip)({
  '&.MuiChip-root': {
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Inter',
    height: 'fit-content',
    lineHeight: '20px',
    color: theme.palette.rnYellow.yellow800,
    backgroundColor: theme.palette.rnYellow.yellow100,
    padding: '2px 8px',
    borderRadius: '12px',
  },
  '&.MuiChip-root:hover': {
    color: theme.palette.rnYellow.yellow800,
    backgroundColor: theme.palette.rnYellow.yellow100,
  },
  '& .MuiChip-label': {
    padding: '0px',
  },
});
const InvalidChip = styled(Chip)({
    '&.MuiChip-root': {
      fontWeight: 500,
      fontSize: '14px',
      fontFamily: 'Inter',
      height: 'fit-content',
      lineHeight: '20px',
      color: theme.palette.rnRed.red800,
      backgroundColor: theme.palette.rnRed.red100,
      padding: '2px 8px',
      borderRadius: '12px',
    },
    '&.MuiChip-root:hover': {
      color: theme.palette.rnRed.red800,
      backgroundColor: theme.palette.rnRed.red100,
    },
    '& .MuiChip-label': {
      padding: '0px',
    },
  });

export const SavedChipComponent = ({
  id,
  label,
  className,
  isDisabled,
  isArchived,
  click,
  ...other
}: ISavedChipComponentProps) => {
  return (
    <>
      {' '}
      {isArchived ? (
        <InvalidChip
          id={id}
          disabled={isDisabled}
          className={`${className ? className : ''}`}
          label={label}
          onClick={click}
        />
      ) : (
        <ActiveChip
          id={id}
          disabled={isDisabled}
          className={`${className ? className : ''}`}
          label={label}
          onClick={click}
        />
      )}
    </>
  );
};
