export class DisplayText {
  static BoardSize(width: number, length: number, isMetric: boolean) {
    if (width  && length) {
      if (isMetric) {
        return `${width.toFixed(2)} x ${length.toFixed(2)} mm`;
      }
      return `${width.toFixed(2)} x ${length.toFixed(2)} in`;
    } else {
      return;
    }
  
  }
}
