import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { scopesToUse } from './Auth';
import { fetchAndSetActiveAccount } from './AuthHelper';
import './assets/scss/Header.scss';
import { AssemblyNumberSearch } from './components/FindYourRoof/AssemblyNumber';
import { Divider, Typography, styled } from '@mui/material';
import { genrateUuidv4 } from './services/ScenarioService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import theme from './theme';
import { useLogger } from 'fmg-telemetry-react';

const Header: React.FC = () => {
  const logger = useLogger();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { instance: azureADPCA } = useMsal();
  const [nameState, setName] = useState('');
  const navigate = useNavigate();
  const randguid = genrateUuidv4();

  const MidDivider = styled(Divider)({
    '&.MuiDivider-root': {
      background: theme.palette.rnMisc.white,
      width: '1px',
      height: '50px',
      marginLeft: '1.5rem',
      marginRight: '1.5rem'
    },
  });

  useEffect(() => {
    fetchAndSetActiveAccount();
    const name = azureADPCA.getActiveAccount()?.name
      ? azureADPCA.getActiveAccount()?.name
      : azureADPCA.getActiveAccount()?.username;
    setName(String(name));
    azureADPCA
      .acquireTokenSilent({
        scopes: scopesToUse,
      })
      .then((response) => {
        if (!response.accessToken && !response.idToken) {
          console.log(new Error('No access token nor ID token found in the returned data from the token request call'));
        } else {
          // console.log(
          //   response.accessToken
          //     ? response.accessToken
          //     : 'NO ACCESS TOKEN FOUND IN RETURNED DATA FROM TOKEN REQUEST CALL!'
          // );
          // console.log(
          //   response.idToken ? response.idToken : 'NO ID TOKEN FOUND IN RETURNED DATA FROM TOKEN REQUEST CALL!'
          // );
        }
      })
      .catch((error) => {
        azureADPCA.logoutRedirect();
      });
  });
  const helpmenu = () => {
    return (
      <>
        <Dropdown.Item
          className="help-button-dropdown"
          href="#/action-1"
          onClick={(e) => {
            e.preventDefault();
            navigate('/help');
          }}
          data-testid="helpDropdown"
        >
          <span className="dropdown-text">Help</span>
        </Dropdown.Item>
        <Dropdown.Item
          className="help-button-dropdown"
          href="#/action-2"
          onClick={(e) => {
            e.preventDefault();
            window.open('/quickstart')
          }}
          data-testid="helpDropdown"
        >
          <span className="dropdown-text">Quick Start Guide</span>
        </Dropdown.Item>
        <Dropdown.Item
          className="help-button-dropdown"
          href="/assets/2688.doc"
          data-testid="helpDropdown"
        >
          <span className="dropdown-text">Form 2688</span>
        </Dropdown.Item>
        <Dropdown.Item
          className="help-button-dropdown"
          href="/glossary"
          onClick={(e) => {
            e.preventDefault();
            navigate('/glossary');
          }}
          data-testid="helpDropdown"
        >
          <span className="dropdown-text">Glossary</span>
        </Dropdown.Item>
        <Divider></Divider>
        <Dropdown.Item
          className="help-button-dropdown"
          href="#/action-4"
          onClick={(e) => {
            e.preventDefault();
            navigate('/datasheetsview');
          }}
          data-testid="helpDropdown"
        >
          <span className="dropdown-text">Data Sheets</span>
        </Dropdown.Item>
        <Dropdown.Item
          className="help-button-dropdown"
          href="#/action-5"
          onClick={(e) => {
            e.preventDefault();
            navigate('/standardsview');
          }}
          data-testid="helpDropdown"
        >
          <span className="dropdown-text">Approvals Standards</span>
        </Dropdown.Item>
        <Divider></Divider>
        <Dropdown.Item
          className="help-button-dropdown"
          href="#/action-7"
          onClick={(e) => {
            e.preventDefault();
            navigate('/guidelines');
          }}
          data-testid="helpDropdown"
        >
          <span className="dropdown-text">Approval Testing Services</span>
        </Dropdown.Item>
        <Dropdown.Item
          className="help-button-dropdown"
          href="#/action-8"
          onClick={(e) => {
            e.preventDefault();
            navigate('/guidelines#Intro');
          }}
          data-testid="helpDropdown"
        >
          <span className="dropdown-text">Intro to FM Approved Roof Assemblies</span>
        </Dropdown.Item>
        <Dropdown.Item
          className="help-button-dropdown"
          href="#/action-9"
          onClick={(e) => {
            e.preventDefault();
            navigate('/guidelines#GenReqs');
          }}
          data-testid="helpDropdown"
        >
          <span className="dropdown-text">Req. of Approved Roof Constructions</span>
        </Dropdown.Item>
        <Dropdown.Item
          className="help-button-dropdown"
          href="#/action-10"
          onClick={(e) => {
            e.preventDefault();
            navigate('/guidelines#UnitConv');
          }}
          data-testid="helpDropdown"
        >
          <span className="dropdown-text">SI of Units Conversions</span>
        </Dropdown.Item>
        <Dropdown.Item
          className="help-button-dropdown"
          href="#/action-11"
          onClick={(e) => {
            e.preventDefault();
            navigate('/guidelines#RoofNavSupport');
          }}
          data-testid="helpDropdown"
        >
          <span className="dropdown-text">Support</span>
        </Dropdown.Item>
      </>
    );
  };
  return (
    <>
      <header className="screenheader">
        <div className="rnheader container-fluid">
          <div className="d-flex flex-nowrap justify-content-between align-items-center">
            <div className="pt-1">
              <nav className="navbar navbar-dark p-0">
                <div className="navbar-brand mx-2 d-flex flex-nowrap row align-items-center">
                  <Link className='stacked-logo' to={`/home?${params.toString()}`}>
                    <img src="/assets/FM_Approvals_Logo_Stack_Primary_rev_rgb.svg" width='140' alt="RoofNav logo" />
                  </Link>
                  <Link className='reg-logo' to={`/home?${params.toString()}`}>
                    <img src="/assets/FM_Approvals_Logo_Main.svg" width='300' alt="RoofNav logo" />
                  </Link>
                  <MidDivider variant={'middle'} />
                  <Typography fontFamily={'FM Review Web'} className='FMReview-Regular-Web title-font'
                    color={theme.palette.rnMisc.white} fontSize={'36px'}
                    fontWeight={500}
                  >
                    RoofNav
                  </Typography>
                </div>
              </nav>
            </div>
            <div className="d-flex align-items-center">
              <div className='header-regular'>
                <ul className="list-group list-group-horizontal align-items-center">
                  <div className={'list-group-item'}>
                    <AssemblyNumberSearch />
                  </div>
                  <li className="list-group-item border-0">
                    <a
                      className="header-link"
                      title="Assembly Search"
                      href="/home?openAssemblyProps=true&openRoofLayers=true"
                    >
                      <span data-testid="filterIcon" className="material-symbols-outlined header-icon">filter_alt</span>
                      <span className="sr-only">Assembly Search</span>
                    </a>
                  </li>
                  <li className="list-group-item border-0">
                    <a className="header-link" title="Ratings Calculator" href="/ratingsCalculator">
                      {/* <span data-testid="calculatorIcon" className="material-symbols-outlined header-icon">calculate</span> */}
                      <FontAwesomeIcon
                        icon={faCalculator}
                        size={'3x'}
                        data-testid="calculatorIcon"
                        className="header-icon"
                        style={{ padding: '6px' }}
                      />
                      <span className="sr-only">Calculator</span>
                    </a>
                  </li>
                  <li className="list-group-item border-0">
                    <a className="header-link" title="Product Search" href="/products">
                      <span data-testid="buildIcon" className="material-symbols-outlined header-icon">build</span>
                      <span className="sr-only">Product Search</span>
                    </a>
                  </li>
                  <li className="list-group-item border-0">
                    <a className="header-link" title="Home" href="/">
                      <span className="material-symbols-outlined header-icon" data-testid="homeIcon">home</span>
                      <span className="sr-only">Home</span>
                    </a>
                  </li>
                  <li className="list-group-item border-0">
                    <Dropdown>
                      <Dropdown.Toggle className="help-button header-icon d-flex" variant="none" id="dropdown-basic">
                        <span className="material-symbols-outlined header-icon" data-testid="helpIcon">help</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {helpmenu()}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li className="list-group-item border-0">
                    <Dropdown>
                      <Dropdown.Toggle className="d-flex account-button header-icon" variant="none" id="dropdown-basic">
                        <span className="material-symbols-outlined header-icon" data-testid="userIcon">account_circle</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="account-button-dropdown"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/savedItems/${randguid}`);
                          }}
                          data-testid="myProjectsDropdown"
                        >
                          <span className="dropdown-text">My Saved Items</span>
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Item
                          className="account-button-dropdown"
                          href="#/action-1"
                          onClick={(e) => {
                            e.preventDefault();

                            azureADPCA.logoutRedirect();
                          }}
                          data-testid="logoutDropdown"
                        >
                          <span className="dropdown-text">Logout</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div>
              <div className='header-hamburger d-none'>
                <Dropdown>
                  <Dropdown.Toggle className="help-button header-icon d-flex" variant="none" id="dropdown-basic">
                    <span className="material-symbols-outlined header-icon" data-testid="userIcon">menu</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>

                    {helpmenu()}
                    <Dropdown.Item
                      className="account-button-dropdown"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/savedItems/${randguid}`);
                      }}
                      data-testid="myProjectsDropdown"
                    >
                      <span className="dropdown-text">My Saved Items</span>
                    </Dropdown.Item>

                    <Dropdown.Divider />

                    <Dropdown.Item
                      className="account-button-dropdown"
                      href="#/action-1"
                      onClick={(e) => {
                        e.preventDefault();

                        azureADPCA.logoutRedirect();
                      }}
                      data-testid="logoutDropdown"
                    >
                      <span className="dropdown-text">Logout</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

          </div>
        </div>
      </header>
      <div className='border-bar'></div>
    </>
  );
};

export default Header;
