import { Box, Divider, styled, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  calculateRoofPressures,
  calculateRoofPressuresWithFlashingRating,
} from '../../../services/RatingsCalculatorService';
import { RootState } from '../../../store';
import theme from '../../../theme';
import { VariableAssignment } from '../../../types/configurator';
import { ApplicationContext } from '../../ApplicationProvider';
import { ConfigitContext } from '../../ConfigitProvider';
import { DIRECTIONALITY_FACTOR, EFFECTIVE_WIND_AREA, ELEVATION_MEAN, IMPORTANCE_FACTOR, ROOF_PRESSURE_ZONE, SAFETY_FACTOR, SLOPE_DEGREE, SLOPE_RISE, TOPOGRAPHICAL_FACTOR } from '../../constants/CALCULATOR_VALUES';
import { RoofPressureZone } from '../../constants/ENUMS';
import { ButtonComponent } from '../../shared/ButtonComponent/ButtonComponent';
import { ISelectValue } from '../../shared/SelectComponent/SelectBox';
import { SelectComponent } from '../../shared/SelectComponent/SelectComponent';
import { SummaryRatings } from './SummaryRatings';
import { SummaryTables } from './SummaryTables';
import { useMsal } from '@azure/msal-react';
import { getProjectsByEmail, saveUserProject } from '../../../services/ProjectService';
import { genrateUuidv4 } from '../../../services/ScenarioService';
import { IRoofAreaRatingInput, IRoofAreaWindPressure, RoofArea, RoofAreaBaseInfo, RoofProject, RoofProjectBaseInfo, SaveRoofAreaWithRating } from '../../../models/GQLGeneratedModels';
import { getRoofAreaInfoById, getRoofAreasByProjectId, saveBaseRoofArea, saveBaseRoofAreaIncludingRating } from '../../../services/RoofAreaService';
import { IRoofAreaBaseInfo } from '../../../types/interfaces';
import { ErrorModalComponent } from '../../shared/ErrorModalComponent/ErrorModalComponent';
import { Dialog } from '../../shared/DialogComponent/Dialog';
import { SnackbarComponent } from '../../shared/SnackbarComponent';
import { Length } from '../../../utils/Measurement/Length';

export interface IRatingsCalculatorInfo {
  surfaceRoughness: any;
  windSpeed: any;
  enclosureClassification: any;
  width: number;
  roofHeight: number;
  wallComponentHeight: number;
  highestOpeningHeight: number;
  roofSlope: number;
  isContinuousParapet: boolean;
  directionalityFactor?: number;
  elevationMean?: number;
  topographicalFactor: number;
  effectiveWindArea: number;
  importanceFactor: number;
  safetyFactor: number;
  isTropicalCycloneRegion: boolean;
}

export interface IRoofZoneResult {
  pressureZone: RoofPressureZone;
  pressure: number;
  rating: number;
  copingRating: number;
  fasciaRating: number;
  strips: any;
}

const SummaryTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    width: '273px'
  },


})

export const getZoneNameByValue = (resultZoneValue: number) => {
  const result = ROOF_PRESSURE_ZONE.find((objZone) => {
    return objZone.value === resultZoneValue;
  });
  if (result) return result.name;
  else return 'Unknown';
};

export const SummaryComponent: React.FC = () => {
  const navigate = useNavigate();
  const { setDefaultTab, setDialog, setShowDialog } = useContext(ApplicationContext);
  const { updateConfigitCriteria } = useContext(ConfigitContext);
  const [selectedZone, setSelectedZone] = useState('');
  const [open, setOpen] = React.useState(false);

  const ratingData: IRoofZoneResult[] = [];

  const selectSurfaceRoughness = (state: RootState) => state.calculator.windRatingState.surfaceRoughness;
  const selectWindSpeed = (state: RootState) => state.calculator.windRatingState.windSpeed;
  const selectEnclosureClassification = (state: RootState) => state.calculator.windRatingState.enclosureClassification;
  const selectCycloneProne = (state: RootState) => state.calculator.windRatingState.cycloneProneLocation;
  const selectRoofAreaHeight = (state: RootState) => state.calculator.windRatingState.roofAreaHeight;
  const selectRoofAreaSlope = (state: RootState) => state.calculator.windRatingState.roofAreaSlope;
  const selectRoofAreaWidth = (state: RootState) => state.calculator.windRatingState.roofAreaWidth;
  const selectRoofAreaLength = (state: RootState) => state.calculator.windRatingState.roofAreaLength;
  const selectHasParapet = (state: RootState) => state.calculator.windRatingState.hasParapet;
  const selectHailRating = (state: RootState) => state.calculator.hailRatingState.hailRating;
  const selectInternalFire = (state: RootState) => state.calculator.fireRatingState.internalFireRating;
  const selectExternalFire = (state: RootState) => state.calculator.fireRatingState.externalFireRating;
  const selectIsRiseRun = (state: RootState) => state.calculator.windRatingState.isRiseRun;

  const surfaceRoughness = useSelector(selectSurfaceRoughness);
  const windSpeed = useSelector(selectWindSpeed);
  const enclosureClassification = useSelector(selectEnclosureClassification);
  const cycloneProne = useSelector(selectCycloneProne);
  const roofAreaHeight = useSelector(selectRoofAreaHeight);
  const roofAreaLength = useSelector(selectRoofAreaLength);
  const roofAreaSlope = useSelector(selectRoofAreaSlope);
  const roofAreaWidth = useSelector(selectRoofAreaWidth);
  const hasParapet = useSelector(selectHasParapet);
  const hailRating = useSelector(selectHailRating);
  const internalFire = useSelector(selectInternalFire);
  const externalFire = useSelector(selectExternalFire);
  const isRiseRun = useSelector(selectIsRiseRun);
  const [calcResult, setCalcResult] = useState(ratingData);
  const [isTallRoof, setIsTallRoof] = useState(false);
  const [userProjects, setUserProjects] = useState<ISelectValue[]>();
  const [userRoofAreas, setUserRoofAreas] = useState<ISelectValue[]>();
  const [apiErrorCheck, setApiErrorCheck] = useState(false);

  const selectedProject = useRef<string | number>('');
  const selectedRoofArea = useRef<string | number>('');

  const [newProjectName, setNewProjectName] = useState('');
  const [newRoofAreaName, setNewRoofAreaName] = useState('');
  const forceUpdate = useReducer((x) => x + 1, 0)[1];

  const [errorCheck, setErrorCheck] = useState(false);

  const { instance: azureADPCA } = useMsal();

  // Slope values sent to configit are needed in rise/run
  const checkSlopeValues = () => {
    if (isRiseRun) {
      return roofAreaSlope;
    } else {
      for (let i = 0; i < SLOPE_DEGREE.length; i++) {
        if (roofAreaSlope == SLOPE_DEGREE[i].value) {
          return SLOPE_RISE[i].value;
        }
      }
    }
  };
  // slope values sent to caluclator API are needed in degrees
  const getSlopeDegree = () => {
    if (!isRiseRun) {
      return roofAreaSlope;
    } else {
      for (let i = 0; i < SLOPE_RISE.length; i++) {
        if (roofAreaSlope == SLOPE_RISE[i].value) {
          return SLOPE_DEGREE[i].value;
        }
      }
    }
  };
  const calcData: IRatingsCalculatorInfo = {
    surfaceRoughness: surfaceRoughness.value,
    windSpeed: Number(windSpeed),
    enclosureClassification: enclosureClassification.value,
    width: Math.min(Number(roofAreaWidth), Number(roofAreaLength)),
    roofHeight: Number(roofAreaHeight),
    wallComponentHeight: Number(roofAreaHeight),
    highestOpeningHeight: Number(roofAreaHeight),
    roofSlope: Number(getSlopeDegree()),
    isContinuousParapet: hasParapet,
    effectiveWindArea: EFFECTIVE_WIND_AREA,
    isTropicalCycloneRegion: cycloneProne,
    directionalityFactor: DIRECTIONALITY_FACTOR,
    elevationMean: ELEVATION_MEAN,
    topographicalFactor: TOPOGRAPHICAL_FACTOR,
    importanceFactor: IMPORTANCE_FACTOR,
    safetyFactor: SAFETY_FACTOR,
  };

  const checkTallRoof = () => {
    //RoofHeight >= 90 || (RoofHeight > 60 && (RoofHeight > RoofWidth));
    const minDimension = Math.min(Number(roofAreaWidth), Number(roofAreaLength));
    const roofResult = (Number(roofAreaHeight) >= 90) || (Number(roofAreaHeight) > 60 && (Number(roofAreaHeight) > minDimension));
    return roofResult;
  }
  const sortCalc = (a: IRoofZoneResult, b: IRoofZoneResult) => {
    // sends Zone 1 Prime to the top
    if (ROOF_PRESSURE_ZONE[a.pressureZone] < ROOF_PRESSURE_ZONE[b.pressureZone] || a.pressureZone == 4) return -1;
    if (ROOF_PRESSURE_ZONE[a.pressureZone] > ROOF_PRESSURE_ZONE[b.pressureZone]) return 1;
    return 0;
  };
  const getUserEmail = () => {
    let userEmail = azureADPCA.getActiveAccount()?.username || 'test@test.com'; // works for initial Azure B2C before customization
    // For customized Azure B2C, user email needs to be retrieved as follows:
    let tokenClaims;
    let userGUID;
    if (azureADPCA.getActiveAccount()?.idTokenClaims) {
      tokenClaims = azureADPCA.getActiveAccount()?.idTokenClaims;
      if (tokenClaims && tokenClaims.hasOwnProperty('email')) {
        userEmail = (tokenClaims as any)['email'];
      }
      //  if (tokenClaims && tokenClaims.hasOwnProperty('aud')) {
      userGUID = azureADPCA.getActiveAccount()?.localAccountId as string;
    }
    return userEmail;
  }
  useEffect(() => {
    const getCalcData = async () => {
      if (enclosureClassification.value === 2) {
        const calcCallResult = await calculateRoofPressures(calcData);
        calcCallResult.sort((a: IRoofZoneResult, b: IRoofZoneResult) => {
          return sortCalc(a, b)
        })
        setCalcResult(calcCallResult);

      } else {
        const calcCallResultWithFlashing = await calculateRoofPressuresWithFlashingRating(calcData);
        calcCallResultWithFlashing.sort((a: IRoofZoneResult, b: IRoofZoneResult) => {
          return sortCalc(a, b)

        })
        setCalcResult(calcCallResultWithFlashing);
      }
      setIsTallRoof(checkTallRoof());
    };
    const getRoofProjects = async () => {
      const email = getUserEmail();
      const projectData = await getProjectsByEmail(email, genrateUuidv4());
      mapProjects(projectData as RoofProject[]);

    }
    getCalcData();
    getRoofProjects();
  }, []);

  useEffect(() => {
    // TODO: make some refinements based on which crud operation failed.
    // completion planned once work begins on loading and editing saved roof area calculations
    if (apiErrorCheck) {
      setDialog(
        <Dialog label="An Error Occurred">
          <ErrorModalComponent message="Please check data and resubmit" />
        </Dialog>
      );
      setShowDialog(true);
    }
  }, [apiErrorCheck]);

  const mapProjects = (projects: RoofProject[]) => {
    const projectMapping: ISelectValue[] = [{ name: 'Create New Project', value: 0 }];
    projects.forEach((project: RoofProject) => {
      projectMapping.push({ value: project.ProjectId, name: project.Name })
    });
    setUserProjects(projectMapping);
  }
  const mapRoofAreas = (roofAreas: RoofArea[]) => {
    const roofAreaMapping: ISelectValue[] = [{ name: 'Create New Roof Area', value: 0 }];
    roofAreas.forEach((roofArea: RoofArea) => {
      roofAreaMapping.push({ value: roofArea.RoofAreaId, name: roofArea.Name })
    });
    setUserRoofAreas(roofAreaMapping);
  }
  const mapZoneData = (data: IRoofZoneResult[]) => {
    const zoneArray: ISelectValue[] = [];
    for (let index = 0; index < data.length; index++) {
      zoneArray.push({ name: getZoneNameByValue(data[index].pressureZone), value: data[index].pressureZone });
    }
    return zoneArray;
  };
  const onProjectNameSelected = (e: any) => {
    if (e.target.value) {
      setNewProjectName(e.target.value);
      setErrorCheck(false);
    } else {
      setNewProjectName('');
      setErrorCheck(false);
    }
  };
  const onRoofAreaNameSelected = (e: any) => {
    if (e.target.value) {
      setNewRoofAreaName(e.target.value);
      setErrorCheck(false);
    } else {
      setNewRoofAreaName('');
      setErrorCheck(false);
    }
  };

  const getWindUpliftByZone = (selectedZoneValue: number) => {
    const result = isZone(selectedZoneValue);
    if (result) return result.rating.toString();
    else return '105';
  };
  // helper function to determine if a zone is present
  const isZone = (selectedZoneValue: number) => {
    const result = calcResult.find((objZone) => {
      return objZone.pressureZone == selectedZoneValue;
    });
    return result;
  }

  const handleSearchClick = () => {

    if (selectedZone === '') return false;

    const assemblyProp: VariableAssignment[] = [];

    // Hail Rating
    const variableHailRating: VariableAssignment = {};
    variableHailRating.variableId = 'C_HailRating_HailRatingId';
    variableHailRating.value = hailRating.value?.toString();
    assemblyProp.push(variableHailRating);

    // Internal Fire
    const variableInternalFire: VariableAssignment = {};
    variableInternalFire.variableId = 'C_IntFireRating_IntFireRatingId';
    variableInternalFire.value = internalFire.value?.toString();
    assemblyProp.push(variableInternalFire);

    // External Fire
    const variableExternalFire: VariableAssignment = {};
    variableExternalFire.variableId = 'C_ExtFireRating_ExtFireRatingId';
    variableExternalFire.value = externalFire.value?.toString();
    assemblyProp.push(variableExternalFire);

    // Slope
    const variableSlopeValue: VariableAssignment = {};
    variableSlopeValue.variableId = 'NavAssembly_Slope_view';
    variableSlopeValue.value = checkSlopeValues();
    assemblyProp.push(variableSlopeValue);

    const variableSlopeOperator: VariableAssignment = {};
    variableSlopeOperator.variableId = 'SlopeOperator';
    variableSlopeOperator.value = 'Equal';
    assemblyProp.push(variableSlopeOperator);

    // Wind Uplift
    const variableWindUpliftValue: VariableAssignment = {};
    variableWindUpliftValue.variableId = 'NavAssembly_WindUplift_view';
    variableWindUpliftValue.value = getWindUpliftByZone(Number(selectedZone));
    assemblyProp.push(variableWindUpliftValue);

    const variableWindUpliftOperator: VariableAssignment = {};
    variableWindUpliftOperator.variableId = 'WindUpliftOperator';
    variableWindUpliftOperator.value = 'BiggerOrEqual';
    assemblyProp.push(variableWindUpliftOperator);

    updateConfigitCriteria(assemblyProp, [], []);
    setDefaultTab(0);
    navigate('?openRoofLayers=true&openAssemblyProps=true');
  };
  const getZoneImage = () => {
    let result = undefined;
    // 3-Zones: Zone 2 non-null and is tall roof
    // 4-Zones: Zone 1 Prime is non-null and is not tall roof
    // 6-Zones: Zone 2e is not null or slope is greater than 7 degrees 
    if (isZone(4) || isZone(2) && !isTallRoof) result = '4-zones';
    if (isZone(2) && isTallRoof) result = '3-Zones';
    if (isZone(5) || Number(checkSlopeValues()) >= 1.5) result = '6-zones';
    return result;
  };
  const roofMapResult = getZoneImage();
  const mapWindBorneData = (value: number) => {
    if (value === 0) return 2;
    if (value === 1) return 1;
    return 3;
  };
  const mapRatingInput = () => {
    const ratingInput: IRoofAreaRatingInput = {
      BuildingEnclosureId: 1,
      // CalculatorVersion?: Maybe<Scalars['String']>;
      ContinuousParapet: hasParapet,
      // EnteredAsMetric: !isRiseRun,
      ExtFireRatingId: Number(externalFire.value),
      GroundRoughnessRatingId: Number(surfaceRoughness.value) + 1,
      HailRatingId: Number(hailRating.value),
      Height: Number(roofAreaHeight) * 12 * Length.INCH_TO_MM,
      IntFireRatingId: Number(internalFire.value),
      Length: Number(roofAreaLength) * 12 * Length.INCH_TO_MM,
      Slope: Number(roofAreaSlope),
      TropicalCycloneRegion: cycloneProne,
      Width: Number(roofAreaWidth) * 12 * Length.INCH_TO_MM,
      WindBorneDebrisId: mapWindBorneData(Number(enclosureClassification.value)),
      WindSpeed: Number(windSpeed)
    };
    return ratingInput;
  };
  const mapRoofAreaRatingResult = () => {
    // TODO: add logic to account for roofareaid when editing exisiting roof area
    const roofAreaRatingResult: IRoofAreaWindPressure[] = []
    calcResult.forEach((result: IRoofZoneResult) => {
      const tempResult = {
        CopingRating: result.copingRating,
        FasciaRating: result.fasciaRating,
        PressureZoneId: result.pressureZone,
        WindUplift: result.rating
      };
      roofAreaRatingResult.push(tempResult);
    });
    return roofAreaRatingResult;
  };

  const saveCalcData = async () => {
    setApiErrorCheck(false);
    let newProjectId = undefined;
    // if no project, create new project and proceed if successful
    if (selectedProject.current === 0) {
      let userEmail = azureADPCA.getActiveAccount()?.username || 'test@test.com'; // works for initial Azure B2C before customization
      // For customized Azure B2C, user email needs to be retrieved as follows:
      let tokenClaims;
      let userGUID;
      if (azureADPCA.getActiveAccount()?.idTokenClaims) {
        tokenClaims = azureADPCA.getActiveAccount()?.idTokenClaims;
        if (tokenClaims && tokenClaims.hasOwnProperty('email')) {
          userEmail = (tokenClaims as any)['email'];
        }
        //  if (tokenClaims && tokenClaims.hasOwnProperty('aud')) {
        userGUID = azureADPCA.getActiveAccount()?.localAccountId as string;
      }
      const newProject: RoofProjectBaseInfo = {
        Name: newProjectName,
        AzureB2CUserEmail: userEmail,
        AzureB2CUserGuid: userGUID
      }

      newProjectId = await saveUserProject({ Project: newProject, SaveOperationType: 1 });
      if (!newProjectId) {
        setApiErrorCheck(true);
        return;
      }
    }

    // TODO: for future stories, move to separate functions as there will be extra logic for getting the data
    // NB: surface roughness and enclosure need some data manipulation between api db and hasura db
    const roofAreaRatingInput = mapRatingInput();
    const roofAreaRatingResult = mapRoofAreaRatingResult();
    let roofAreaBaseInfo: RoofAreaBaseInfo = {
    }
    if (Number(selectedRoofArea.current) > 0) {
      const roofAreaToSave = await getRoofAreaInfoById(Number(selectedRoofArea.current))
      roofAreaBaseInfo = {
        RoofAreaId: roofAreaToSave[0].RoofAreaId,
        ProjectId: roofAreaToSave[0].RoofProject.ProjectId,
        Name: roofAreaToSave[0].Name,
        Description: roofAreaToSave[0].Description,
        BuildingName: roofAreaToSave[0].BuildingName
      }
    } else {
      roofAreaBaseInfo = {
        Name: newRoofAreaName,
        ProjectId: newProjectId ? newProjectId : Number(selectedProject.current)
      }
    }
    const roofAreaFullData: SaveRoofAreaWithRating = {
      RoofAreaBase: roofAreaBaseInfo,
      RoofAreaRatingInput: roofAreaRatingInput,
      RoofAreaRatingResult: roofAreaRatingResult,
      SaveOperationType: Number(selectedRoofArea.current) > 0 ? 2 : 1
    }
    const saveData = await saveBaseRoofAreaIncludingRating(roofAreaFullData);
    if (saveData) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false)
      }, 3000);
      if (roofAreaFullData.SaveOperationType === 1 && roofAreaBaseInfo.ProjectId && roofAreaBaseInfo.RoofAreaId) {
        const randguid = genrateUuidv4();
        const roofAreas = await getRoofAreasByProjectId(roofAreaBaseInfo.ProjectId, randguid);
        mapRoofAreas(roofAreas as RoofArea[]);
        selectedRoofArea.current = roofAreaBaseInfo.RoofAreaId;
        changeSelectedProject(String(selectedProject.current))
        forceUpdate();
      }
    } else {
      setNewProjectName('');
      setNewRoofAreaName('');
      setApiErrorCheck(true);
      return;
    }
    setNewProjectName('');
    setNewRoofAreaName('');
  }
  const changeSelectedProject = async (value: string) => {
    let roofAreas;
    if (value.length === 0) {
      selectedProject.current = '';
      selectedRoofArea.current = '';
      forceUpdate()
      return
    } else {
      selectedProject.current = Number(value);
    }
    if (selectedProject.current === 0) {
      selectedRoofArea.current = 0;
      forceUpdate()
    }
    if (selectedProject.current > 0) {
      selectedRoofArea.current = -1;
      const randguid = genrateUuidv4();

      roofAreas = await getRoofAreasByProjectId(selectedProject.current, randguid);
      mapRoofAreas(roofAreas as RoofArea[]);

      forceUpdate();
    }

  }
  const changeSelectedRoofArea = async (value: string) => {
    if (value.length === 0) {
      selectedRoofArea.current = -1;

    } else {

      selectedRoofArea.current = Number(value);
    }
    forceUpdate()

  }
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (event) {
      setOpen(false);
      return;
    }
  };
  const preflightCheck = () => {
    if (selectedProject.current === '' || selectedRoofArea.current === '') return true;
    if (selectedProject.current === 0 && newProjectName.length === 0) return true;
    if (selectedRoofArea.current === 0 && newRoofAreaName.length === 0) return true;
    if (selectedRoofArea.current === -1) return true;
    return false;
  }

  return (
    <Box className={'px-4 rn-summary'}>
      <Typography className={'headline-1 py-5'} color={theme.palette.rnSteel.steel700}>
        Summary
      </Typography>
      <Typography className="large-para pb-4">
        Below is a summary of your personalized calculations. You can <b>save</b> these calculations to a Roof Area and{' '}
        <b>search</b> for assemblies with your calculations pre-populated. For further guidance, refer to the FM
        Data Sheets.
      </Typography>
      <Typography className="headline-3">
        Save Ratings
      </Typography>
      <Typography className="large-para pb-4">
        <b>Saving ratings is currently disabled.</b>
      </Typography>
      {/* <div className='row m-0'>
        <div className='mr-4 mb-2'>
          <div className='row m-0'>
            <div className="mr-4 mb-2">
              <Typography className="summary-select modal-info-bold-text pb-1"
              >
                Project
              </Typography>
              <SelectComponent
                options={userProjects ?? [{ name: 'Create New Project', value: 0 }]}
                setValue={(value) => changeSelectedProject(value)}
                value={String(selectedProject.current)}
                className="as-2 summary-select"
              />
            </div>
            <div className="as-2 mb-2">
              <Typography className="summary-select modal-info-bold-text pb-1">
                Roof Area
              </Typography>
              <SelectComponent
                disabled={selectedProject.current === 0}
                options={userRoofAreas ?? [{ name: 'Create New Roof Area', value: 0 }]}
                setValue={(value) => changeSelectedRoofArea(value)}
                value={String(selectedRoofArea.current)}
                className="as-2 summary-select"
              />
            </div>
          </div>
          <div className='row m-0'>
            {selectedProject.current === 0 ?
              <div className='mr-4 mb-2'
              >
                <Typography className="summary-select modal-info-bold-text pb-1"
                >
                  Project Name: <span className="roofnav-error">*</span>
                </Typography>
                <SummaryTextField
                  value={newProjectName}
                  onChange={onProjectNameSelected}
                  id="projectNameInput"
                  placeholder="Project Name"
                  className='as-2'
                  size='small'
                  error={errorCheck && newProjectName.length == 0}
                />
              </div>
              :
              <div className='mr-4 empty-slot mb-2'> &nbsp;
              </div>
            }
            {selectedRoofArea.current === 0 ?
              <div className='mb-2'
              >
                <Typography className="summary-select modal-info-bold-text pb-1"
                >
                  Roof Area Name: <span className="roofnav-error">*</span>
                </Typography>
                <SummaryTextField
                  value={newRoofAreaName}
                  onChange={onRoofAreaNameSelected}
                  id="roofAreaNameInput"
                  placeholder="Roof Area Name"
                  className='as-2'
                  size='small'
                  error={errorCheck && newRoofAreaName.length == 0}
                />
              </div>
              :
              <div className='as-2'>
              </div>
            }

          </div>
        </div>
        <ButtonComponent
          buttonStyle='mt-3 mb-2 mx-0'
          isDisabled={preflightCheck()}
          buttonText='Save Ratings' buttonType={'primary'} click={() => saveCalcData()} />
      </div> */}

      <Typography className={'headline-3'} color={theme.palette.rnSteel.steel700}>
        Assembly Search
      </Typography>
      <Typography className={'modal-info-bold-text'} >Area of Roof</Typography>
      <div className={'row m-0 px-0 pb-5 d-flex align-items-center'}>
        <SelectComponent
          id='searchAssembliesSelect'
          options={mapZoneData(calcResult)}
          setValue={(value) => setSelectedZone(value)}
          value={selectedZone}
          className="as-2 summary-select"
        />
        <ButtonComponent
          isDisabled={selectedZone.length === 0}
          id={'searchAssembliesButton'}
          buttonType={'primary'}
          buttonText={'Search Assemblies'}
          iconName="search"
          buttonStyle={'ml-4'}
          click={handleSearchClick}
        />
      </div>

      <Divider></Divider>
      <SummaryTables />
      <SummaryRatings calcResult={calcResult} />
      {roofMapResult ? <img src={`/assets/${roofMapResult}.png`} alt={`${roofMapResult}`} aria-label={`${roofMapResult}`} /> : <></>}
      <SnackbarComponent open={open} handleClose={handleClose} message={'Roof Area successfully saved'} />

    </Box>
  );
};
