import React from 'react';
import { ExistingRoofDetail } from './ExistingRoofDetail';
import { StructureDetail } from './StructureDetail';
import { DeckStructuralConcreteDetail } from './DeckStructuralConcreteDetail';

interface ISpecLayerDetailProps {
  layerId: number;
  layerTypeId: number;
}

export const SpecLayerDetails = (props: ISpecLayerDetailProps) => {
  const { layerId, layerTypeId } = props;
  return (
    <div>
      <div className="notvisible">{layerId}</div>
      <div className="notvisible">{layerTypeId}</div>

      {layerTypeId === 18 && <DeckStructuralConcreteDetail layerId={layerId} />}
      {layerTypeId === 25 && <ExistingRoofDetail layerId={layerId} />}
      {layerTypeId === 35 && <StructureDetail layerId={layerId} />}
    </div>
  );
};
