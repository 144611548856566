import React, { useEffect } from 'react';
import { GLOSSARY_ENTRIES } from '../../components/constants/REFERENCE_GLOSSARY';
import './RefMaterial.scss';
import { Accordion, AccordionDetails, AccordionSummary, Link, Typography, styled } from '@mui/material';
import theme from '../../theme';
import { TextOnlyComponent } from '../../components/shared/TextOnlyComponent/TextOnlyComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Glossary = () => {
    console.log({ GLOSSARY_ENTRIES });
    useEffect(() => {

    }, [history])
    const indices = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
        'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const GlossaryAccordion = styled(Accordion)({
        '&.MuiAccordion-root': {
            border: 'none',
            boxShadow: 'none !important',
        },
    });
    const handleBackClick = () => {
        history.back();
    };
    return (
        <main className="main-content container-fluid flex-shrink-0 glossary">
            <div className='px-4'>
                <div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Typography className='headline-1 pt-2' color={theme.palette.rnSteel.steel700}>Glossary of Terms</Typography>

                        <Link onClick={handleBackClick} className='d-flex float-right'>
                            <TextOnlyComponent buttonText={'Back'} iconName={'arrow_left'} />
                        </Link>
                    </div>
                    <br></br>
                    <div className='d-flex row m-0'>
                        <p className='contents'>
                            <a href="#A">A</a>&nbsp;|&nbsp; <a href="#B">B</a>&nbsp;|&nbsp; <a href="#C">C</a>&nbsp;|&nbsp;
                            <a href="#D">D</a>&nbsp;|&nbsp; <a href="#E">E</a>&nbsp;|&nbsp; <a href="#F">F</a>&nbsp;|&nbsp;
                            <a href="#G">G</a>&nbsp;|&nbsp; <a href="#H">H</a>&nbsp;|&nbsp; <a href="#I">I</a>&nbsp;|&nbsp;
                            <a href="#J">J</a>&nbsp;|&nbsp; <a href="#K">K</a>&nbsp;|&nbsp; <a href="#L">L</a>&nbsp;|&nbsp;
                            <a href="#M">M</a>&nbsp;|&nbsp; <a href="#N">N</a>&nbsp;|&nbsp; <a href="#O">O</a>&nbsp;|&nbsp;
                            <a href="#P">P</a>&nbsp;|&nbsp; <a href="#Q">Q</a>&nbsp;|&nbsp; <a href="#R">R</a>&nbsp;|&nbsp;
                            <a href="#S">S</a>&nbsp;|&nbsp; <a href="#T">T</a>&nbsp;|&nbsp; <a href="#U">U</a>&nbsp;|&nbsp;
                            <a href="#V">V</a>&nbsp;|&nbsp; <a href="#W">W</a>&nbsp;|&nbsp; <a href="#X">X</a>&nbsp;|&nbsp;
                            <a href="#Y">Y</a>&nbsp;|&nbsp; <a href="#Z">Z</a>
                        </p>
                    </div>

                </div>

                <div className='my-4'>
                    {indices.map((letter, index) => {
                        return (
                            <div key={index}>
                                <Typography className='headline-2' id={letter} color={theme.palette.rnOrange.darkOrange}>{letter}</Typography>
                                <div>
                                    {GLOSSARY_ENTRIES.map((entry, glossaryIndex) => {
                                        if (entry.id.slice(0, 1) === letter) {
                                            return (
                                                <div key={glossaryIndex} className='py-1'>
                                                    <GlossaryAccordion id={entry.id} >
                                                        <AccordionSummary sx={{ pointerEvents: 'none' }}>
                                                            <div >
                                                                <Typography color={theme.palette.rnSteel.steel700} style={{ pointerEvents: 'auto', }} > {entry.name} </Typography>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails className='mx-4' id={`${entry.id}Details`}>
                                                            {entry.definition}
                                                        </AccordionDetails>
                                                    </GlossaryAccordion>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <span className="mx-4 d-flex float-right px-2 top-navigate" onClick={handleClick}>
                                    <div>
                                        Back To Top&nbsp;
                                        <FontAwesomeIcon className='px-1' icon={['fas', 'chevron-up']} />
                                    </div>
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </main>
    )
}