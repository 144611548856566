import { IRatingsCalculatorInfo } from "../components/Calculator/CalculatorSummary/SummaryComponent";
import { fetchFreshToken } from '../AuthHelper';
const calcUrl = process.env.REACT_APP_CALCULATOR_API_URL;
export const calculateRoofPressuresWithFlashingRating = async (data: IRatingsCalculatorInfo) => {
    const token = (await fetchFreshToken()) || '';
    const response = await fetch(`${calcUrl ? calcUrl : "https://dev2-01.apim-gateway.fmglobal.com/approvals/wind-pressure-calculator/v1"}/WindPressureRatings/CalculateRoofPressuresWithFlashingRating`, {
        method: 'post',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
            'clientId': '10b7cd2df0004f8088de2b591effd818',
        },
        body: JSON.stringify(data),
    });
    return await response.json();
};
export const calculateRoofPressures = async (data: IRatingsCalculatorInfo) => {
    const token = (await fetchFreshToken()) || '';
    const response = await fetch(`${calcUrl ? calcUrl : "https://dev2-01.apim-gateway.fmglobal.com/approvals/wind-pressure-calculator/v1"}/WindPressureRatings/CalculateRoofPressures`, {
        method: 'post',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
            'clientId': '10b7cd2df0004f8088de2b591effd818',
        },
        body: JSON.stringify(data),
    });
    return await response.json();
};
