import React, { useState } from 'react';
import { NavAssembly_Order_By, ProductSearchCriteria } from '../models/GQLGeneratedModels';
import { Section, VariableAssignment } from '../types/configurator';
import { attributeSearchCriteria } from '../services/ProductSearchService';
export interface IManufacturerProduct {
  manufacturerId: string;
  manufacturerLabel?: string;
  productId: string;
  productLabel?: string;
}
export interface ICategory {
  categoryId: string;
  categoryLabel?: string;
}
// changes to this interface needed- discuss
export interface IApprovedUse {
  approvedUseId: string;
  approvedUseLabel?: string;
}

export interface IConfigitProductContext {
  variableAssignments: VariableAssignment[];
  setVariableAssignments: (variableAssignments: VariableAssignment[]) => void;
  manufacturerProduct: VariableAssignment[];
  setManufacturerProduct: (variableAssignments: VariableAssignment[]) => void;
  categories: ICategory[];
  setCategories: (categories: ICategory[]) => void;
  approvedUse: IApprovedUse[];
  setApprovedUse: (approvedUse: IApprovedUse[]) => void;
  section?: Section;
  setSection: (section: Section) => void;
  attributes: attributeSearchCriteria[];
  setAttributes: (attributes: attributeSearchCriteria[]) => void;
  clearSearch: () => void;
  productCount: number;
  setProductCount: (productCount: number) => void;
  apuCount: number;
  setApuCount: (apuCount: number) => void;
  initialSearchCriteria: ProductSearchCriteria;
  setInitialSearchCriteria: (initialSearchCriteria: ProductSearchCriteria) => void;
  isAttributeSelected: boolean;
  setIsAttributeSelected: (isAttributeSelected: boolean) => void;
  selectedApprovedUse?: Section;
  setSelectedApprovedUse: (selectedApprovedUse: Section) => void;
  selectedAttributeId: string;
  setSelectedAttributeId: (selectedAttributeId: string) => void;
}

export interface IConfigitProductCriteria {
  variableAssignments: VariableAssignment[];
  manufacturerProduct: VariableAssignment[];
  categories: ICategory[];
  approvedUse: IApprovedUse[];
  limit: number;
  offset: number;
  orderBy: NavAssembly_Order_By;
}

export const ConfigitProductContext: React.Context<IConfigitProductContext> =
  React.createContext<IConfigitProductContext>({
    variableAssignments: [],
    setVariableAssignments: () => { },
    manufacturerProduct: [],
    setManufacturerProduct: () => { },
    categories: [],
    setCategories: () => { },
    approvedUse: [],
    setApprovedUse: () => { },
    attributes: [],
    setAttributes: () => { },
    clearSearch: () => { },
    setSection: () => { },
    productCount: 0,
    setProductCount: () => { },
    apuCount: 0,
    setApuCount: () => { },
    setInitialSearchCriteria: () => { },
    initialSearchCriteria: {},
    isAttributeSelected: false,
    setIsAttributeSelected: () => { },
    setSelectedApprovedUse: () => { },
    selectedAttributeId: '',
    setSelectedAttributeId: () => { }
  });

export const ConfigitProductProvider: React.FC = (props: any) => {
  const [variableAssignments, setVariableAssignments] = useState<VariableAssignment[]>([]);
  const [manufacturerProduct, setManufacturerProduct] = useState<VariableAssignment[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [approvedUse, setApprovedUse] = useState<IApprovedUse[]>([]);
  const [attributes, setAttributes] = useState<attributeSearchCriteria[]>([]);
  const [section, setSection] = useState<Section>();
  const [productCount, setProductCount] = useState(0);
  const [apuCount, setApuCount] = useState(0);
  const [initialSearchCriteria, setInitialSearchCriteria] = useState<ProductSearchCriteria>({});
  const [isAttributeSelected, setIsAttributeSelected] = useState(false);
  const [selectedApprovedUse, setSelectedApprovedUse] = useState<Section>();
  const [selectedAttributeId, setSelectedAttributeId] = useState('')


  const clearSearch = () => {
    //setLayers([]);
    //setVariableAssignments([]);
  };
  return (
    <ConfigitProductContext.Provider
      value={{
        variableAssignments,
        setVariableAssignments,
        manufacturerProduct,
        setManufacturerProduct,
        categories,
        setCategories,
        approvedUse,
        setApprovedUse,
        attributes,
        setAttributes,
        clearSearch,
        section,
        setSection,
        productCount,
        setProductCount,
        apuCount,
        setApuCount,
        initialSearchCriteria,
        setInitialSearchCriteria,
        isAttributeSelected,
        setIsAttributeSelected,
        selectedApprovedUse,
        setSelectedApprovedUse,
        selectedAttributeId,
        setSelectedAttributeId
      }}
    >
      {props.children}
    </ConfigitProductContext.Provider>
  );
};
