import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  RadioGroup,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { LAYERTYPES } from '../../components/constants/CODETABLES';
import { RadioComponent } from '../../components/shared/RadioComponent/RadioComponent';
import { UsageDetails } from '../../components/UsageDetails';
import theme from '../../theme';
import './AssemblyDetails.scss';
import { SpecLayerDetails } from '../../components/SpecLayerDetails';
import { IScenario, IScenarioLayer, IScenarioUsage } from '../../types/interfaces';
import { ApplicationContext } from '../../components/ApplicationProvider';
import { ConfigitContext } from '../../components/ConfigitProvider';
import {
  genrateUuidv4,
  getScenarioDetailById,
  getSinglePlyAdhesiveRelatedUsagesByNavAssemblyId,
} from '../../services/ScenarioService';
import { useLocation, useParams } from 'react-router-dom';
import { Scenario } from '../../models/GQLGeneratedModels';
import { IScenarioLayerForAPI } from './SaveModal';
import { useMsal } from '@azure/msal-react';
// TODO: create interface for this instead of props
export const Detail: React.FC<any> = (props: any) => {
  const { layers, usages, properties, newSavedData, savedScenarioId } = props;
  const { scenarioError, setDialog, setShowDialog } = useContext(ApplicationContext);
  const { curScenarioState, setCurScenarioState } = useContext(ConfigitContext);
  // this is crap, need to replace later
  const [singlePlyData, setSinglePlyData] = useState<any>(undefined);
  const selectedSinglePly = useRef<number | undefined>(undefined);
  const params = useParams();
  const { instance: azureADPCA } = useMsal();
  const location = useLocation();
  let savedData: any | undefined;
  const isSinglePly = properties?.RoofAssembly.C_AssemblyType.AssemblyTypeCode === 'Single-Ply System';
  const isAdhered = properties?.RoofAssembly.C_CoverSecurementMethod.CoverSecurementMethodCode === 'Adhered';

  useEffect(() => {
    // reset state on initial render- TODO: add in check for navassembly id
    const initialSaveState: IScenario = {
      ScenarioId: -1,
      ScenarioStatusId: -1,
      Name: 'New Scenario',
      NavAssemblyId: -1,
      DateCreated: new Date(),
      ScenarioTypeId: -1,
      Comments: '',
      ScenarioLayers: [],
    };
    const mapSavedData = (data: Scenario) => {
      const singlePlyLayerNum = layers.find((layer: any) => layer.LayerTypeId === 4)

      const savedState: IScenario = {
        ScenarioId: data.ScenarioId,
        ScenarioStatusId: data.ScenarioStatusId,
        Name: data.Name,
        NavAssemblyId: data.NavAssemblyId,
        DateCreated: data.DateCreated,
        ScenarioTypeId: data.ScenarioTypeId,
        Comments: data.Comments,
        ScenarioLayers: [],
      };
      data.ScenarioLayers.forEach((savedLayer) => {
        const regUsageId = savedLayer.ScenarioUsages.filter((usage: any) => !usage.SelfSecurementLayerTypeId)[0];
        const tempLayer: IScenarioLayer = {
          NavLayerId: savedLayer.NavLayerId,
          LayerNum: savedLayer.LayerNum,
          LayerText: savedLayer.LayerText ? String(savedLayer.LayerText) : '',
          LayerTypeID: savedLayer.LayerTypeID,
          IsPermeable: savedLayer.IsPermeable,
          IsOptional: savedLayer.IsOptional,
          ScenarioUsages: {
            UsageId: regUsageId != undefined ? regUsageId.UsageId : 0,
            LayerNum: savedLayer.LayerNum,
          },
        };
        savedLayer.ScenarioUsages.forEach((savedUsage) => {
          if (savedLayer.LayerNum === singlePlyLayerNum?.NavLayerNum && isAdhered && isSinglePly && !savedUsage.SelfSecurementLayerTypeId) {
            selectedSinglePly.current = savedUsage.UsageId;
          }
          if (savedUsage.SelfSecurementLayerTypeId) {
            (tempLayer.ScenarioUsages.SelfSecurementUsageId = savedUsage.UsageId),
              (tempLayer.ScenarioUsages.SelfSecurementText = String(savedUsage.SelfSecurementText)),
              (tempLayer.ScenarioUsages.SelfSecurementLayerTypeId = Number(savedUsage.SelfSecurementLayerTypeId));
          } else {
            tempLayer.ScenarioUsages.UsageId = savedUsage.UsageId;
          }
        });
        savedState.ScenarioLayers?.push(tempLayer);
      });
      savedData = newSavedData;
      setCurScenarioState(savedState);
      forceUpdate();
    };
    if (location.state && location.state.savedScenarioId) {
      if (savedData === undefined) {
        let userEmail = azureADPCA.getActiveAccount()?.username || 'test@test.com';
        let tokenClaims;
        if (azureADPCA.getActiveAccount()?.idTokenClaims) {
          tokenClaims = azureADPCA.getActiveAccount()?.idTokenClaims;
          if (tokenClaims && tokenClaims.hasOwnProperty('email')) {
            userEmail = (tokenClaims as any)['email'];
          }
        }
        const fetchData = async () => {
          const savedScenario = await getScenarioDetailById(location.state.savedScenarioId, userEmail, genrateUuidv4());
          savedData = savedScenario[0];

          mapSavedData(savedData);
        };
        fetchData();
      }
    } else {
      setCurScenarioState(initialSaveState);
    }



    const getSinglePlyData = async () => {
      // TODO: need to account for slower systems, etc
      const assemblyId = Number(params.assemblyid);
      const result = await getSinglePlyAdhesiveRelatedUsagesByNavAssemblyId(assemblyId);
      setSinglePlyData(result[0]);
    };
    // check from assembly properties- move call to index
    if (isSinglePly && isAdhered && properties) {
      getSinglePlyData();
    }
  }, []);
  useEffect(() => {
    // add logic here
  }, [JSON.stringify(curScenarioState), selectedSinglePly]);
  const forceUpdate = useReducer((x) => x + 1, 0)[1];

  const DetailsAccordion = styled(Accordion)({
    '&.MuiAccordion-root': {
      backgroundColor: theme.palette.rnSteel.steel500,
      border: 'none',
      boxShadow: 'none !important',
    },
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
    '&.MuiAccordion-root:after': {
      height: '1px',
      backgroundColor: theme.palette.rnGrey.grey600,
    },
    '& .MuiCollapse-root': {
      width: '100%',
    },
  });
  const DetailAccordionDetails = styled(AccordionDetails)({
    '&.MuiAccordionDetails-root': {
      backgroundColor: theme.palette.rnSteel.steel500,
      border: 'none',
    },
  });
  const DetailsAccordionSummary = styled(AccordionSummary)({
    '&.MuiAccordionSummary-root': {
      pointerEvents: 'none',
    },
    '& .MuiAccordionSummary-content': {
      margin: '3px 0',
    },
  });

  const DetailsTypography = styled(Typography)({
    '&.MuiTypography-root': {
      backgroundColor: theme.palette.rnMisc.white,
      padding: '1px',
      borderRadius: '6px',
    },
  });

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: '500px',
      padding: '1px',
      border: '1px solid #EFF0F1',
      boxShadow: '5px 5px 8px -1px rgba(0, 0, 0, 0.18)',
    },
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        backgroundColor: '#C93102',
      },
    },
  }));

  const createOptionalLayerNoneUsage = (navLayerId: number) => {
    return {
      ApprovedUseId: 0,
      NavLayerId: navLayerId,
      NavLayerNum: 0,
      ProductComponentId: 0,
      ProductManufacturer: 'None',
      ProductTradeName: '',
      SelfSecurementComments: '',
      SelfSecurementLayerTypeId: null,
      SystemComponentId: null,
      SystemName: null,
      SystemProductComponentId: null,
      SystemProductManufacturer: null,
      SystemProductOrder: null,
      SystemProductTradeName: null,
      UsageId: 0,
      __typename: 'vNavAssembly_Usages',
    };
  };

  const getRegularUsagesNonSystemOnLayer = (navLayerId: number, navLayerOptional: boolean) => {
    const usagesOnLayer = usages.filter(
      (usage: any) => usage.NavLayerId === navLayerId && !usage.SelfSecurementLayerTypeId && !usage.SystemComponentId
    );
    if (navLayerOptional) usagesOnLayer.push(createOptionalLayerNoneUsage(navLayerId));
    return usagesOnLayer;
  };
  const getSelfSecurementUsagesNonSystemOnLayer = (navLayerId: number, navLayerOptional: boolean) => {
    const usagesOnLayer = usages.filter(
      (usage: any) => usage.NavLayerId === navLayerId && usage.SelfSecurementLayerTypeId && !usage.SystemComponentId
    );
    //if (navLayerOptional) usagesOnLayer.push(createOptionalLayerNoneUsage(navLayerId));
    return usagesOnLayer;
  };

  const setRegularUsage = (usage: any, layer: any) => {
    const singlePlyLayerNum = layers.find((layer: any) => layer.LayerTypeId === 4)

    if (!curScenarioState.ScenarioLayers?.find((curLayer) => curLayer.NavLayerId === layer.NavLayerId)) {
      if (isSinglePly && isAdhered && layer.NavLayerNum === singlePlyLayerNum.NavLayerNum) {
        selectedSinglePly.current = usage.UsageId;
        curScenarioState.ScenarioLayers?.forEach((curLayer) => {
          if (curLayer.NavLayerId === layer.NavLayerId) {
            curLayer.ScenarioUsages.SelfSecurementUsageId = undefined;
          }
          if (curLayer.LayerNum === singlePlyLayerNum.NavLayerNum + 1) {
            curLayer.ScenarioUsages.UsageId = -1;
          }
        });
      }
      const tempScenario = curScenarioState;
      const tempLayer: IScenarioLayer = {
        NavLayerId: layer.NavLayerId,
        LayerNum: layer.NavLayerNum,
        LayerText: getLayerTitle(layer.NavLayerNum, layer.LayerTypeCode, layer.FromNavLayerNum, layer.ToNavLayerNum),
        LayerTypeID: layer.LayerTypeId,
        IsPermeable: layer.IsPermeable ? layer.IsPermeable : false,
        IsOptional: layer.IsOptional ? layer.IsOptional : false,
        ScenarioUsages: {
          UsageId: usage.UsageId,
          LayerNum: usage.NavLayerNum,
        },
      };
      tempScenario.ScenarioLayers?.push(tempLayer);
      if (isSinglePly && isAdhered && layer.NavLayerNum === singlePlyLayerNum.NavLayerNum) {
        tempScenario.ScenarioLayers?.forEach((curLayer) => {
          if (curLayer.LayerNum === singlePlyLayerNum.NavLayerNum) {
            curLayer.ScenarioUsages = {
              UsageId: usage.UsageId,
              LayerNum: usage.NavLayerNum,
            };
          }
          if (curLayer.LayerNum === singlePlyLayerNum.NavLayerNum + 1) {
            curLayer.ScenarioUsages.UsageId = -1;
          }
        });
      }
      setCurScenarioState(tempScenario);
      forceUpdate();
    } else {
      const tempScenario = curScenarioState;

      tempScenario.ScenarioLayers?.forEach((tempLayer) => {
        if (isSinglePly && isAdhered && layer.NavLayerNum == singlePlyLayerNum.NavLayerNum) {
          selectedSinglePly.current = usage.UsageId;
          if (tempLayer.LayerNum === singlePlyLayerNum.NavLayerNum) {
            tempLayer.ScenarioUsages = {
              UsageId: usage.UsageId,
              LayerNum: usage.NavLayerNum,
            };
          }
          if (tempLayer.LayerNum === singlePlyLayerNum.NavLayerNum + 1) {
            tempLayer.ScenarioUsages.UsageId = -1;
          }
        } else {
          if (tempLayer.NavLayerId === layer.NavLayerId) {
            tempLayer.ScenarioUsages.UsageId = usage.UsageId;
          }
        }
      });
      setCurScenarioState(tempScenario);
      forceUpdate();
    }
  };

  const setSelfSecurementUsage = (usage: any, navLayerId: any, layerNum: number, layer: any) => {
    if (!curScenarioState.ScenarioLayers?.find((curLayer) => curLayer.NavLayerId === layer?.NavLayerId) && layer) {
      const tempScenario = curScenarioState;
      const tempLayer: IScenarioLayer = {
        NavLayerId: navLayerId,
        LayerNum: layerNum,
        LayerText: getLayerTitle(layer.NavLayerNum, layer.LayerTypeCode, layer.FromNavLayerNum, layer.ToNavLayerNum),
        LayerTypeID: layer.LayerTypeId,
        IsPermeable: layer.IsPermeable ? layer.IsPermeable : false,
        IsOptional: layer.IsOptional ? layer.IsOptional : false,
        ScenarioUsages: {
          UsageId: -1,
          LayerNum: usage.NavLayerNum,
          SelfSecurementUsageId: usage.UsageId,
          SelfSecurementText: getSelfSecurementType(layer.NavLayerNum),
          SelfSecurementLayerTypeId: usage.SelfSecurementLayerTypeId,
        },
      };

      tempScenario.ScenarioLayers?.push(tempLayer);
      setCurScenarioState(tempScenario);
      forceUpdate();
    } else {
      const tempScenario = curScenarioState;
      tempScenario.ScenarioLayers?.forEach((tempLayer) => {
        if (tempLayer.NavLayerId === layer?.NavLayerId) {
          tempLayer.ScenarioUsages.SelfSecurementUsageId = usage.UsageId;
          tempLayer.ScenarioUsages.SelfSecurementText = getSelfSecurementType(layer.NavLayerNum);
          tempLayer.ScenarioUsages.SelfSecurementLayerTypeId = usage.SelfSecurementLayerTypeId;
        }
      });
      setCurScenarioState(tempScenario);
      forceUpdate();
    }
  };
  const setSystemUsage = (usage: any, navLayerId: any, layerNum: number, layer: any) => {
    if (!curScenarioState.ScenarioLayers?.find((curLayer) => curLayer.NavLayerId === layer?.NavLayerId)) {
      const tempScenario = curScenarioState;
      const tempLayer: IScenarioLayer = {
        NavLayerId: layer.NavLayerId,
        LayerNum: layerNum,
        LayerText: getLayerTitle(layer.NavLayerNum, layer.LayerTypeCode, layer.FromNavLayerNum, layer.ToNavLayerNum),
        LayerTypeID: layer.LayerTypeId,
        IsPermeable: layer.IsPermeable ? layer.IsPermeable : false,
        IsOptional: layer.IsOptional ? layer.IsOptional : false,
        ScenarioUsages: {
          UsageId: usage.UsageId,
          LayerNum: usage.NavLayerNum,
        },
      };
      const tempSelfSecurementLayer: IScenarioLayer = {
        NavLayerId: navLayerId,
        LayerNum: layerNum,
        LayerText: getLayerTitle(layer.NavLayerNum, layer.LayerTypeCode, layer.FromNavLayerNum, layer.ToNavLayerNum),
        LayerTypeID: layer.LayerTypeId,
        IsPermeable: layer.IsPermeable ? layer.IsPermeable : false,
        IsOptional: layer.IsOptional ? layer.IsOptional : false,
        ScenarioUsages: {
          UsageId: -1,
          LayerNum: usage.NavLayerNum,
          SelfSecurementUsageId: usage.UsageId,
          SelfSecurementText: getSelfSecurementType(layer.NavLayerNum),
          SelfSecurementLayerTypeId: usage.SelfSecurementLayerTypeId,
        },
      };
      tempScenario.ScenarioLayers?.push(usage.SelfSecurementLayerTypeId ? tempSelfSecurementLayer : tempLayer);
      forceUpdate();
    } else {
      const tempScenario = curScenarioState;
      tempScenario.ScenarioLayers?.forEach((tempLayer) => {
        if (tempLayer.NavLayerId === layer?.NavLayerId) {
          if (usage.SelfSecurementLayerTypeId) {
            tempLayer.ScenarioUsages.SelfSecurementUsageId = usage.UsageId;
            tempLayer.ScenarioUsages.SelfSecurementText = getSelfSecurementType(layer.NavLayerNum);
            tempLayer.ScenarioUsages.SelfSecurementLayerTypeId = usage.SelfSecurementLayerTypeId;
          } else {
            tempLayer.ScenarioUsages.UsageId = usage.UsageId;
          }
        }
      });
      setCurScenarioState(tempScenario);
      forceUpdate();
    }
  };

  const getLayerTitle = (
    layerNum: number,
    layerTypeCode: string,
    fromNavLayerNum: number | null | undefined,
    toNavLayerNum: number | null | undefined
  ) => {
    if (fromNavLayerNum && toNavLayerNum && fromNavLayerNum !== toNavLayerNum) {
      const fromLayer = layers.find((l: any) => l.NavLayerNum === fromNavLayerNum);
      const toLayer = layers.find((l: any) => l.NavLayerNum === toNavLayerNum);
      if (fromLayer && toLayer)
        return `${layerNum}. ${layerTypeCode} from ${fromNavLayerNum}. ${fromLayer.LayerTypeCode} to ${toNavLayerNum}. ${toLayer.LayerTypeCode}`;
      else return `${layerNum}. ${layerTypeCode}`;
    } else return `${layerNum}. ${layerTypeCode}`;
  };

  const checkIfLayerContainSelfSecurement = (layerNum: number) => {
    return usages.filter((u: any) => u.NavLayerNum === layerNum && u.SelfSecurementLayerTypeId).length > 0
      ? true
      : false;
  };
  const checkSinglePlyValues = (layer: any, usage: any) => {
    // adjust here
    const singlePlyLayerNum = layers.find((layer: any) => layer.LayerTypeId === 4)
    if (!singlePlyData) {
      return false;
    }
    if (layer.NavLayerNum > singlePlyLayerNum.NavLayerNum + 1) {
      return false;
    }
    if (layer.NavLayerNum === singlePlyLayerNum.NavLayerNum && !usage.SelfSecurementLayerTypeId) {
      return false;
    }
    if (layer.NavLayerNum === singlePlyLayerNum.NavLayerNum && usage.SelfSecurementLayerTypeId && curScenarioState.ScenarioLayers?.length === 0) {
      return true;
    }
    if (layer.NavLayerNum === singlePlyLayerNum.NavLayerNum + 1 && curScenarioState.ScenarioLayers?.length === 0) {
      return true;
    }
    if (layer.NavLayerNum === singlePlyLayerNum.NavLayerNum && usage.SelfSecurementLayerTypeId && singlePlyData) {
      const adhesiveData = singlePlyData.Nav_SinglePlyLapAdhesives.filter(
        (datum: any) => datum.AdhesiveUsageId === usage.UsageId
      );
      const filteredData = adhesiveData.filter((datum: any) => datum.SinglePlyUsageId === selectedSinglePly.current);
      const isValid = filteredData.find((datum: any) => datum.AdhesiveUsageId === usage.UsageId);
      return isValid ? false : true;
    }
    if (layer.NavLayerNum === singlePlyLayerNum.NavLayerNum + 1) {
      // check match from adhesive data componentid?
      const bondingAdhesiveData = singlePlyData.Nav_SinglePlyBondingAdhesives.filter(
        (datum: any) => datum.AdhesiveUsageId === usage.UsageId
      );
      const filteredData = bondingAdhesiveData.filter(
        (datum: any) => datum.SinglePlyUsageId === selectedSinglePly.current
      );
      const isValid = filteredData.find((datum: any) => datum.AdhesiveUsageId === usage.UsageId);

      return isValid ? false : true;
    }
    return false;
  };

  const findLayerValues = (layer: any, usage: any) => {
    let result = false;
    curScenarioState.ScenarioLayers?.forEach((curLayer: any) => {
      if (curLayer.LayerNum === layer.NavLayerNum) {
        if (usage.UsageId === curLayer.ScenarioUsages.UsageId) {
          result = true;
        }
        if (usage.SelfSecurementLayerTypeId) {
          if (usage.UsageId === curLayer.ScenarioUsages.SelfSecurementUsageId) {
            result = true;
          }
        }
      }
    });
    return result;
  };
  const getSelfSecurementType = (layerNum: number) => {
    let selfSecurementTypeName = 'Self Secument';
    const selfSecurementUsages = usages.filter((u: any) => u.NavLayerNum === layerNum && u.SelfSecurementLayerTypeId);
    if (selfSecurementUsages.length > 0) {
      const typeId = selfSecurementUsages[0].SelfSecurementLayerTypeId;
      if (LAYERTYPES.filter((t) => t.LayerTypeId == typeId).length > 0) {
        selfSecurementTypeName = LAYERTYPES.filter((t) => t.LayerTypeId == typeId)[0].LayerTypeCode;
      }
    }
    return selfSecurementTypeName;
  };
  // ###SELF SECUREMENT###

  const checkIfLayerContainRegularSystemUsage = (layerNum: number) => {
    return usages.filter((u: any) => u.NavLayerNum === layerNum && u.SystemComponentId && !u.SelfSecurementLayerTypeId)
      .length > 0
      ? true
      : false;
  };
  const checkIfLayerContainSelfSecurementSystemUsage = (layerNum: number) => {
    return usages.filter((u: any) => u.NavLayerNum === layerNum && u.SystemComponentId && u.SelfSecurementLayerTypeId)
      .length > 0
      ? true
      : false;
  };
  // ###SYSTEM USAGES- PART OF INITIAL RADIO GROUP###
  const getSystemUsages = (layerNum: number, navLayerId: number, layer: any, isSelfSecurementUsage: boolean) => {
    const systemUsages = usages.filter((u: any) => u.NavLayerNum === layerNum && u.SystemComponentId);
    const uniqueSystemName = Array.from(new Set(systemUsages.map((item: any) => item.SystemName)));

    const usagesRegularProduct = isSelfSecurementUsage
      ? usages.filter((u: any) => u.NavLayerNum === layerNum && u.SelfSecurementLayerTypeId && !u.SystemComponentId)
      : usages.filter((u: any) => u.NavLayerNum === layerNum && !u.SelfSecurementLayerTypeId && !u.SystemComponentId);
    return uniqueSystemName.map((system: any) => {
      const targetUsage = usages.filter((u: any) => u.NavLayerNum === layerNum && u.SystemName === system)[0];
      // add in system logic here- need to fix selection bug first
      return (
        <div key={system} className={'system-row px-4'}>
          <div className={'detail-row m-0 px-3 align-items-center'}>
            {/* <div> */}
            <div className={'col-11 d-flex align-items-center '}>
              <RadioComponent
                disabled={checkSinglePlyValues(layer, targetUsage)}
                id={'radio-' + navLayerId + '-' + targetUsage.UsageId}
                value={navLayerId + '-' + targetUsage.UsageId}
                label={system}
                click={() => {
                  setSystemUsage(targetUsage, navLayerId, layerNum, layer);
                }}
                checked={!checkSinglePlyValues(layer, targetUsage) && findLayerValues(layer, targetUsage)}
              />
            </div>
            <div className="d-flex align-items-center justify-content-end col">
              <HtmlTooltip
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={<UsageDetails usageId={targetUsage.UsageId} approvedUseId={targetUsage.ApprovedUseId} />}
                arrow
                placement={'left'}
              >
                <Typography fontFamily={'Inter'} color={theme.palette.rnOrange.darkOrange} fontWeight={600}>
                  View
                </Typography>
              </HtmlTooltip>
            </div>
          </div>
          <div className="px-5">{getProductsWithinSystem(layerNum, system)}</div>
        </div>
      );
    });
  };
  // ###SYSTEM ROW###
  const getProductsWithinSystem = (layerNum: number, system: string) => {
    const productUsages = usages.filter((u: any) => u.NavLayerNum === layerNum && u.SystemName === system);
    // add in system portion now

    return productUsages.map((usage: any, index: number) => (
      <>
        <div key={usage.SystemProductComponentId} className={'row detail-row m-0 px-2'}>
          <div className="col-5">
            <Typography fontWeight={500} fontFamily={'Inter'}>
              {usage.SystemProductManufacturer}
            </Typography>
          </div>
          <div className="col-5">
            <Typography fontWeight={500} fontFamily={'Inter'}>
              {usage.SystemProductTradeName}
            </Typography>
          </div>
        </div>
        {index + 1 !== productUsages.length && <Divider className="system-divider mx-4" />}
      </>
    ));
  };
  // ##PRESELECT#
  // TODO: refactor this, can be one function
  const getPreselectedNonSelfSecurementUsageId = (
    usagesNonSelfSecurement: any,
    layerNum: number,
    optionalLayer: boolean
  ) => {
    if (savedData) {
      let result = undefined;
      const savedValue = savedData.ScenarioLayers.forEach((layer: IScenarioLayerForAPI) => {
        const savedUsage = layer.ScenarioUsages?.find((usage: any) => usage.LayerNum === layerNum);
        if (savedUsage) {
          result = savedUsage.UsageId;
        }
      });
      return result;
    }
    if (usagesNonSelfSecurement.length === 1) {
      return optionalLayer ? 0 : usagesNonSelfSecurement[0].UsageId;
    }
    // systems have many usages but share a common usage id
    const systemCheck = usagesNonSelfSecurement.filter(
      (usage: any) => usage.UsageId === usagesNonSelfSecurement[0].UsageId
    );
    if (systemCheck.length != 0 && systemCheck.length === usagesNonSelfSecurement.length) {
      return usagesNonSelfSecurement[0].UsageId;
    }
    return 0;
  };
  const getPreselectedSelfSecurementUsageId = (selfSecurementUsages: any, layerNum: number, optionalLayer: boolean) => {
    if (savedData) {
      const savedValue = savedData.ScenarioLayers.forEach((layer: IScenarioLayerForAPI) => {
        const savedUsage = layer.ScenarioUsages?.find((usage: IScenarioUsage) => usage.LayerNum === layerNum);
        if (savedUsage) {
          return savedUsage.UsageId;
        }
      });
      return savedValue;
    }
    if (selfSecurementUsages.length === 1) {
      return optionalLayer ? 0 : selfSecurementUsages[0].UsageId;
    }
    // systems have many usages but share a common usage id
    const systemCheck = selfSecurementUsages.filter((usage: any) => usage.UsageId === selfSecurementUsages[0].UsageId);
    if (systemCheck.length != 0 && systemCheck.length === selfSecurementUsages.length) {
      return selfSecurementUsages[0].UsageId;
    }
    return 0;
  };
  // ## END PRESELECT#
  // # SET INITIAL STATE
  const setInitialNonSelfSecurementState = (preSelectedUsageId: number, layer: any, usagesNonSelfSecurement: any) => {
    const singlePlyLayerNum = layers.find((layer: any) => layer.LayerTypeId === 4)
    if (preSelectedUsageId != 0 && savedData) {
      return;
    }

    if (
      preSelectedUsageId != 0 &&
      curScenarioState &&
      curScenarioState.ScenarioLayers &&
      usagesNonSelfSecurement.length != 0
    ) {
      // add in logic for layer id, search through current object
      if (curScenarioState.ScenarioLayers.find((currLayer) => currLayer.NavLayerId === layer.NavLayerId)) {
        const currentLayer = curScenarioState.ScenarioLayers.find(
          (currLayer) => currLayer.NavLayerId === usagesNonSelfSecurement[0].NavLayerId
        );

        if (currentLayer) {
          if (isSinglePly && isAdhered && usagesNonSelfSecurement[0].NavLayerNum == singlePlyLayerNum.NavLayerNum) {
            selectedSinglePly.current = usagesNonSelfSecurement[0].UsageId;
          }
          curScenarioState.ScenarioLayers?.forEach((lyr) => {
            if (lyr.NavLayerId === currentLayer.NavLayerId) {
              // add in check to prevent duplicates here
              (lyr.ScenarioUsages.UsageId = usagesNonSelfSecurement[0].UsageId),
                (lyr.ScenarioUsages.LayerNum = usagesNonSelfSecurement[0].NavLayerNum);
            }
          });
        }
      } else {
        // logic for adding in layer
        const tempLayer: IScenarioLayer = {
          // ScenarioLayerId: 0,
          // ScenarioId: 0,
          NavLayerId: layer.NavLayerId,
          LayerNum: layer.NavLayerNum,
          LayerText: getLayerTitle(layer.NavLayerNum, layer.LayerTypeCode, layer.FromNavLayerNum, layer.ToNavLayerNum),
          LayerTypeID: layer.LayerTypeId,
          IsPermeable: layer.IsPermeable ? layer.IsPermeable : false,
          IsOptional: layer.IsOptional ? layer.IsOptional : false,
          ScenarioUsages: {
            UsageId: usagesNonSelfSecurement[0].UsageId,
            LayerNum: usagesNonSelfSecurement[0].NavLayerNum,
          },
        };
        if (isSinglePly && isAdhered && usagesNonSelfSecurement[0].NavLayerNum == singlePlyLayerNum.NavLayerNum) {
          selectedSinglePly.current = usagesNonSelfSecurement[0].UsageId;
        }

        // check to prevent adding duplicate layers to reducer state
        if (!curScenarioState?.ScenarioLayers?.find((curLayer) => curLayer.NavLayerId === layer.NavLayerId)) {
          curScenarioState?.ScenarioLayers?.push(tempLayer);
        }
      }
    }
  };
  const setInitialSelfSecurementState = (
    preSelectedSelfSecurementUsageId: number,
    layer: any,
    selfSecurementUsages: any
  ) => {
    if (preSelectedSelfSecurementUsageId != 0 && savedData) {
      return;
    }
    if (
      preSelectedSelfSecurementUsageId != 0 &&
      curScenarioState &&
      curScenarioState.ScenarioLayers &&
      selfSecurementUsages.length != 0
    ) {
      const currentLayer = curScenarioState.ScenarioLayers.find(
        (currLayer) => currLayer.NavLayerId === selfSecurementUsages[0].NavLayerId
      );
      if (currentLayer) {
        curScenarioState.ScenarioLayers?.forEach((lyr) => {
          if (lyr.NavLayerId === currentLayer.NavLayerId) {
            lyr.ScenarioUsages.SelfSecurementUsageId = selfSecurementUsages[0].UsageId;
            lyr.ScenarioUsages.SelfSecurementText = getSelfSecurementType(layer.NavLayerNum);
            lyr.ScenarioUsages.SelfSecurementLayerTypeId = selfSecurementUsages[0].SelfSecurementLayerTypeId;
          }
        });
      } else {
        const tempLayer: IScenarioLayer = {
          NavLayerId: layer.NavLayerId,
          LayerNum: selfSecurementUsages[0].NavLayerNum,
          LayerText: getLayerTitle(layer.NavLayerNum, layer.LayerTypeCode, layer.FromNavLayerNum, layer.ToNavLayerNum),
          LayerTypeID: layer.LayerTypeId,
          IsPermeable: layer.IsPermeable ? layer.IsPermeable : false,
          IsOptional: layer.IsOptional ? layer.IsOptional : false,
          ScenarioUsages: {
            UsageId: -1,
            SelfSecurementUsageId: selfSecurementUsages[0].UsageId,
            LayerNum: selfSecurementUsages[0].NavLayerNum,
            SelfSecurementText: getSelfSecurementType(layer.NavLayerNum),
            SelfSecurementLayerTypeId: selfSecurementUsages[0].SelfSecurementLayerTypeId,
          },
        };

        // check to prevent adding duplicate layers to reducer state
        if (
          !curScenarioState?.ScenarioLayers?.find(
            (curLayer) => curLayer.NavLayerId === selfSecurementUsages[0].NavLayerId
          )
        ) {
          curScenarioState?.ScenarioLayers?.push(tempLayer);
        }
      }
    }
  };
  // ### END SET INITIAL STATE ###

  return (
    <div className="bg-white p-3">
      <Typography
        className={'py-3'}
        color={theme.palette.rnSteel.steel700}
        fontFamily={'Inter'}
        fontSize={'28px'}
        lineHeight={'40px'}
        fontWeight={600}
      >
        Assembly Details
      </Typography>
      {isSinglePly && isAdhered && (
        <div className="pb-2">
          <Typography className="large-para" color={theme.palette.rnMisc.red}>
            IMPORTANT: You must select a cover in order to select the cover securements. Note that all securements may
            not be approved with all covers.
          </Typography>
        </div>
      )}
      {layers.map((layer: any) => {
        const usagesNonSelfSecurement = usages.filter(
          (u: any) => u.NavLayerNum === layer.NavLayerNum && !u.SelfSecurementLayerTypeId
        );
        const selfSecurementUsages = usages.filter(
          (u: any) => u.NavLayerNum === layer.NavLayerNum && u.SelfSecurementLayerTypeId
        );

        const preSelectedUsageId = getPreselectedNonSelfSecurementUsageId(
          usagesNonSelfSecurement,
          layer.NavLayerNum,
          layer.IsOptional
        );

        const preSelectedSelfSecurementUsageId = getPreselectedSelfSecurementUsageId(
          selfSecurementUsages,
          layer.NavLayerNum,
          layer.IsOptional
        );
        // logic for non self securement- duplicate below for self securement
        setInitialNonSelfSecurementState(preSelectedUsageId, layer, usagesNonSelfSecurement);
        if (selfSecurementUsages.length > 0) {
          setInitialSelfSecurementState(preSelectedSelfSecurementUsageId, layer, selfSecurementUsages);
        }

        return (
          <div key={layer.NavLayerId} className="bg-white w-100 mb-0">
            <DetailsAccordion className={`row p-1 m-0 pe-none`} disableGutters expanded={true}>
              <DetailsAccordionSummary className="row p-0 m-0 justify-content-between w-100">
                <Typography
                  className={'pe-none col-9'}
                  color={theme.palette.rnMisc.white}
                  fontWeight={600}
                  fontFamily={'Inter'}
                  fontSize={'20px'}
                  lineHeight={'28px'}
                >
                  {getLayerTitle(layer.NavLayerNum, layer.LayerTypeCode, layer.FromNavLayerNum, layer.ToNavLayerNum)}
                </Typography>
                <div className={`row m-0 col-3 d-flex justify-content-end align-content-center`}>
                  {layer.IsPermeable && (
                    <Typography fontWeight={500} color={theme.palette.rnMisc.white}>
                      {layer.IsOptional || layer.Comments ? 'Permeable,' : 'Permeable'}&nbsp;
                    </Typography>
                  )}
                  {layer.IsOptional && (
                    <Typography fontWeight={500} color={theme.palette.rnMisc.white}>
                      Optional&nbsp;
                    </Typography>
                  )}
                  {(layer.LayerTypeId === 25 || layer.LayerTypeId === 35 || layer.LayerTypeId === 18) && (
                    <div className="col d-flex align-items-center justify-content-end  h-0">
                      <HtmlTooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                        title={<SpecLayerDetails layerId={layer.LayerId} layerTypeId={layer.LayerTypeId} />}
                        arrow
                        placement={'left'}
                        style={{ pointerEvents: 'auto', cursor: 'text' }}
                      >
                        <Typography fontFamily={'Inter'} color={theme.palette.rnMisc.white} fontWeight={600}>
                          View
                        </Typography>
                      </HtmlTooltip>
                    </div>
                  )}
                </div>
              </DetailsAccordionSummary>
              {layer.Comments && (
                <DetailAccordionDetails className={'mx-4'}>
                  <DetailsTypography fontFamily={'Inter'} fontSize={'12.5px'} fontWeight={500}>
                    {layer.Comments}
                  </DetailsTypography>
                </DetailAccordionDetails>
              )}
            </DetailsAccordion>
            <div key={layer.NavLayerId} className="detail-section">
              <RadioGroup
                id={'Layer' + layer.NavLayerNum + 'Usage'}
                style={{ padding: 3 }}
                name={'Layer' + layer.NavLayerNum + 'Usage'}
                defaultValue={layer.NavLayerId + '-' + preSelectedUsageId}
                className={'w-100 px-4'}
              >
                {getRegularUsagesNonSystemOnLayer(layer.NavLayerId, layer.IsOptional).map((usage: any) => (
                  <div key={usage.UsageId} className={'detail-row d-flex'}>
                    <div className={'col-5 d-flex align-items-center'}>
                      <RadioComponent
                        disabled={checkSinglePlyValues(layer, usage)}
                        id={'radio-' + usage.NavLayerId + '-' + usage.UsageId}
                        label={usage.SystemComponentId ? usage.SystemProductManufacturer : usage.ProductManufacturer}
                        value={usage.NavLayerId + '-' + usage.UsageId}
                        style={{ width: 'auto' }}
                        click={() => {
                          setRegularUsage(usage, layer);
                        }}
                        checked={!checkSinglePlyValues(layer, usage) && findLayerValues(layer, usage)}
                      />
                    </div>
                    <div className="col-6 d-flex align-items-center">
                      <Typography fontFamily={'Inter'} fontWeight={500}>
                        {usage.SystemComponentId
                          ? usage.SystemName + ' - ' + usage.SystemProductTradeName
                          : usage.ProductTradeName}
                      </Typography>
                    </div>
                    <div className="col d-flex align-items-center justify-content-end  h-0">
                      {usage.UsageId > 0 && (
                        <HtmlTooltip
                          enterTouchDelay={0}
                          leaveTouchDelay={5000}
                          title={<UsageDetails usageId={usage.UsageId} approvedUseId={usage.ApprovedUseId} />}
                          arrow
                          placement={'left'}
                        >
                          <Typography fontFamily={'Inter'} color={theme.palette.rnOrange.darkOrange} fontWeight={600}>
                            View
                          </Typography>
                        </HtmlTooltip>
                      )}
                    </div>
                  </div>
                ))}
                {checkIfLayerContainRegularSystemUsage(layer.NavLayerNum)
                  ? getSystemUsages(layer.NavLayerNum, layer.NavLayerId, layer, false)
                  : null}
              </RadioGroup>
              {checkIfLayerContainSelfSecurement(layer.NavLayerNum) ? (
                <div className={'row m-0 p-1'}>
                  <div className="w-100">
                    <div className={'details-securement'}>
                      <Typography
                        color={theme.palette.rnSteel.steel700}
                        fontWeight={600}
                        fontFamily={'Inter'}
                        fontSize={'20px'}
                        lineHeight={'20px'}
                      >
                        {getSelfSecurementType(layer.NavLayerNum)}
                      </Typography>
                    </div>
                    <RadioGroup
                      id={'Layer' + layer.NavLayerNum + 'Usage'}
                      style={{ padding: 3 }}
                      name={'Layer' + layer.NavLayerNum + 'Usage'}
                      defaultValue={layer.NavLayerId + '-' + preSelectedSelfSecurementUsageId}
                      className={'w-100 px-4'}
                    >
                      {getSelfSecurementUsagesNonSystemOnLayer(layer.NavLayerId, layer.IsOptional).map((usage: any) => (
                        <div key={usage.UsageId} className={'detail-row d-flex'}>
                          <div className={'col-5 d-flex align-items-center'}>
                            <RadioComponent
                              disabled={checkSinglePlyValues(layer, usage)}
                              id={'radio-' + usage.NavLayerId + '-' + usage.UsageId}
                              label={
                                usage.SystemComponentId ? usage.SystemProductManufacturer : usage.ProductManufacturer
                              }
                              value={usage.NavLayerId + '-' + usage.UsageId}
                              style={{ width: 'auto' }}
                              click={() => {
                                setSelfSecurementUsage(
                                  usage,
                                  selfSecurementUsages[0].NavLayerId,
                                  layer.NavLayerNum,
                                  layer
                                );
                              }}
                              checked={!checkSinglePlyValues(layer, usage) && findLayerValues(layer, usage)}
                            />
                          </div>
                          <div className="col-6 d-flex align-items-center">
                            <Typography fontFamily={'Inter'} fontWeight={500}>
                              {usage.SystemComponentId
                                ? usage.SystemName + ' - ' + usage.SystemProductTradeName
                                : usage.ProductTradeName}
                            </Typography>
                          </div>
                          <div className="col d-flex align-items-center justify-content-end  h-0">
                            {usage.UsageId > 0 && (
                              <HtmlTooltip
                                enterTouchDelay={0}
                                leaveTouchDelay={5000}
                                title={<UsageDetails usageId={usage.UsageId} approvedUseId={usage.ApprovedUseId} />}
                                arrow
                                placement={'left'}
                              >
                                <Typography fontFamily={'Inter'} color={theme.palette.rnOrange.darkOrange} fontWeight={600}>
                                  View
                                </Typography>
                              </HtmlTooltip>
                            )}
                          </div>
                        </div>
                      ))}
                      {checkIfLayerContainSelfSecurementSystemUsage(layer.NavLayerNum)
                        ? getSystemUsages(layer.NavLayerNum, layer.NavLayerId, layer, true)
                        : null}
                    </RadioGroup>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};
