import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { TextOnlyComponent } from '../../shared/TextOnlyComponent/TextOnlyComponent';
import theme from '../../../theme';
import { Detail } from './SavedDetail';
import { Summary } from './SavedSummary';
import { genrateUuidv4, getScenarioFullInfoById } from '../../../services/ScenarioService';
import { Loading } from '../../shared/Loading/Loading';
import { useMsal } from '@azure/msal-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SavedAssemblyDetail: React.FC = (props: any) => {
  const { instance: azureADPCA } = useMsal();
  const location = useLocation();
  const [scanario, setScanario] = useState<any>();
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(location.search);
  const { scenarioid } = useParams();
  const params = useParams();
  const randguid = genrateUuidv4();
  if (!params.scenarioid) {
    throw Error('Empty Scenario id');
  }
  const handleClick = () => {
    window.print();
  };
  useEffect(() => {
    let isSubscribed = false;
    const getSavedScenarioDetailData = async () => {
      setLoading(true);
      let userEmail = azureADPCA.getActiveAccount()?.username || 'test@test.com';
      let tokenClaims;
      if (azureADPCA.getActiveAccount()?.idTokenClaims) {
        tokenClaims = azureADPCA.getActiveAccount()?.idTokenClaims;
        if (tokenClaims && tokenClaims.hasOwnProperty('email')) {
          userEmail = (tokenClaims as any)['email'];
        }
      }
      const saveScanario = await getScenarioFullInfoById(Number(scenarioid), userEmail, genrateUuidv4());
      if (!isSubscribed) {
        setScanario(saveScanario);
        setLoading(false);
      }
    };
    getSavedScenarioDetailData();
    return () => {
      isSubscribed = true;
    };
  }, [scenarioid]);
  let properties;
  if (scanario) {
    properties = scanario;
  }
  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Loading />
      </div>
    );
  }
  return (
    <main className="main-content container-fluid flex-shrink-0">
      <div>
        <div className="row justify-content-center">
          <div className="col-12 pt-5">
            <section className="bg-white h-100 overflow-hidden">
              {!loading && properties && (
                <>
                  {properties[0].ScenarioStatusId != 1 && (
                    <div className="col-9 row align-items-baseline">
                      <FontAwesomeIcon color={theme.palette.rnMisc.red} icon={['fas', 'triangle-exclamation']} />
                      <div>
                        {properties[0].ScenarioStatusId === 2 || properties[0].ScenarioStatusId === 5 ? (
                          <Typography className="large-para-bold pl-1" color={theme.palette.rnMisc.red}>
                            This Roof Assembly Scenario is no longer valid. Please ensure that you have selected an
                            approved product in each assembly layer.
                          </Typography>
                        ) : (
                          <Typography className="large-para-bold" color={theme.palette.rnMisc.red}>
                            This Roof Assembly Scenario is no longer valid and cannot be edited.
                          </Typography>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="row align-items-stretch">
                    <div className="col-9">
                      <div className="d-flex align-items-center h-100">
                        <Typography
                          className="mx-3"
                          fontFamily={'Inter'}
                          fontSize={'26px'}
                          fontWeight={700}
                          lineHeight={'34px'}
                          color={theme.palette.rnSteel.steel700}
                        >
                          Assembly Properties
                        </Typography>
                      </div>
                    </div>
                    <div className="col px-4 my-1 text-right">
                      <Link to={`/savedItems/${randguid}`}>
                        <TextOnlyComponent buttonText={'Back'} iconName={'arrow_left'} />
                      </Link>
                    </div>
                  </div>
                  <div className="card border-0 h-100">
                    <div className="card-body p-0">
                      <Summary properties={properties} scenarioid={scenarioid} />
                      <Detail layers={properties[0]?.ScenarioLayers} />
                    </div>
                  </div>
                </>
              )}
            </section>
          </div>
        </div>
      </div>
    </main>
  );
};
