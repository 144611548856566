import { BrowserAuthError, EndSessionRequest } from '@azure/msal-browser';
import { InteractionRequiredAuthError } from 'msal';
import { useNavigate } from 'react-router-dom';
// import { logErrorToAppInsights } from './AppInsights';
import { azureAADPublicClientApplication, scopesToUse } from './Auth';

/**
 * Handles delaying a token.  Helps manage local time sync issue.
 *
 * **Should only be used in local dev**
 */
const delayToken = () => {
  if (process.env.NODE_ENV === 'development') {
    return new Promise((resolve) => setTimeout(resolve, 1000));
  }
};

/**
 * Catches any unexpected error.
 * Logs to App Insights and redirects to Error Page.
 */
const handleTokenError = (error: unknown) => {
  // logErrorToAppInsights(error as Error);

  if (process.env.NODE_ENV !== 'production') {
    console.error(error);
    return null;
  }

  const navigate = useNavigate();
  navigate({
    pathname: '/ErrorPage',
  });
};

/**
 * Causes a log out and prompts the user to log back in.
 * Redirect url is set to return them to the page they were on.
 */
const logoutRedirect = async () => {
  const logoutRequest: EndSessionRequest = {
    postLogoutRedirectUri: window.location.href,
  };

  await azureAADPublicClientApplication.logoutRedirect(logoutRequest).catch(handleTokenError);
};

/**
 * Handles fetching an access token for Apollo or Rest calls.
 *
 * Verifies the user is authenticated and has an active account.
 * Then grabs either an access token from cache or uses the refresh token to
 * obtain a new access token.
 *
 * Handles errors by either logging user out to be re-authenticated or by logging an
 * error to App Insights.
 */
export const fetchFreshToken = async () => {
  fetchAndSetActiveAccount();

  try {
    const tokenResponse = await azureAADPublicClientApplication.acquireTokenSilent({
      scopes: scopesToUse,
    });

    if (process.env.NODE_ENV === 'development') {
      await delayToken();
    }

    return tokenResponse.accessToken ? tokenResponse.accessToken : tokenResponse.idToken;
  } catch (error) {
    // if the error is a type that can be resolved with re-logging in
    // such as a token expired, try the redirect
    if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
      logoutRedirect();
    } else {
      handleTokenError(error);
    }
  }
};

/**
 * Handles fetching an setting an Active account for a user.
 *
 * If no account can be found for the user, log them out to be
 * re-authenticated.
 *
 * Handles errors by either logging user out to be re-authenticated or by logging an
 * error to App Insights.
 */
export const fetchAndSetActiveAccount = () => {
  try {
    if (!!azureAADPublicClientApplication.getActiveAccount()) {
    } else {
      if (azureAADPublicClientApplication.getAllAccounts()?.length > 0) {
        azureAADPublicClientApplication.setActiveAccount(azureAADPublicClientApplication.getAllAccounts()[0]);
      } else {
        // If no account is found, log them out.
        logoutRedirect();
      }
    }
  } catch (error) {
    handleTokenError(error);
  }
};
