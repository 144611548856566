/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Slide, SlideProps, Snackbar } from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";

const StyledAlert = styled(Alert)((severity) => ({
  '&.MuiAlert-root': {
    backgroundColor: '#D1FAE5',
    color: '#065F46',
    fontSize: '14px',
  },
  '&.MuiAlert-action': {
    fontSize: '24px',
    padding: '4px !important',
    color: '#059669'
  }
}));
const StyledSnackbar = styled(Snackbar)({
  '&.MuiSnackbar-root': {
    top: '10%'
  }
})

const TransitionRight = (props: SlideProps) => {
  return <Slide {...props} direction="right" />;
}

interface ISnackbarComponentProps {
  open?: boolean;
  handleClose?: (event?: React.SyntheticEvent | Event, reason?: string) => void;
  message: string;
}

export const SnackbarComponent: React.FC<ISnackbarComponentProps> = ({ open, handleClose, message }) => {
  return (
    <StyledSnackbar
      TransitionComponent={TransitionRight}
      open={open}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
    >
      {/* {children} */}
      <StyledAlert
        onClose={handleClose}
        severity="success"
        sx={{ width: '100%' }}
        icon={<FontAwesomeIcon icon={['fas', 'circle-check']} color="#34D399" fontSize={'24px'} />}
      >
        {message}
      </StyledAlert>
    </StyledSnackbar>
  );
};