import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CodeTableDescription: any;
  CodeTableValue: any;
  ItemComment: any;
  MeasurementValue: any;
  MetricEntryFlag: any;
  datetime: any;
  varbinary: any;
};

/** columns and relationships of "Address" */
export type Address = {
  __typename?: 'Address';
  AddressId: Scalars['Int'];
  /** An object relationship */
  C_Country?: Maybe<C_Country>;
  /** An object relationship */
  C_StateProvince?: Maybe<C_StateProvince>;
  City: Scalars['String'];
  CountryId?: Maybe<Scalars['Int']>;
  Line1: Scalars['String'];
  Line2: Scalars['String'];
  Line3: Scalars['String'];
  /** An array relationship */
  Manufacturers: Array<Manufacturer>;
  /** An aggregate relationship */
  Manufacturers_aggregate: Manufacturer_Aggregate;
  PostalCode: Scalars['String'];
  /** An array relationship */
  RoofProjects: Array<RoofProject>;
  /** An aggregate relationship */
  RoofProjects_aggregate: RoofProject_Aggregate;
  StateId?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "Address" */
export type AddressManufacturersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manufacturer_Order_By>>;
  where?: Maybe<Manufacturer_Bool_Exp>;
};


/** columns and relationships of "Address" */
export type AddressManufacturers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manufacturer_Order_By>>;
  where?: Maybe<Manufacturer_Bool_Exp>;
};


/** columns and relationships of "Address" */
export type AddressRoofProjectsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofProject_Order_By>>;
  where?: Maybe<RoofProject_Bool_Exp>;
};


/** columns and relationships of "Address" */
export type AddressRoofProjects_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofProject_Order_By>>;
  where?: Maybe<RoofProject_Bool_Exp>;
};

export type AddressInfo = {
  AddressId?: Maybe<Scalars['Int']>;
  City?: Maybe<Scalars['String']>;
  CountryId?: Maybe<Scalars['Int']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  Line3?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  StateId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "Address" */
export type Address_Aggregate = {
  __typename?: 'Address_aggregate';
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
};

/** aggregate fields of "Address" */
export type Address_Aggregate_Fields = {
  __typename?: 'Address_aggregate_fields';
  avg?: Maybe<Address_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
  stddev?: Maybe<Address_Stddev_Fields>;
  stddev_pop?: Maybe<Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Address_Stddev_Samp_Fields>;
  sum?: Maybe<Address_Sum_Fields>;
  var_pop?: Maybe<Address_Var_Pop_Fields>;
  var_samp?: Maybe<Address_Var_Samp_Fields>;
  variance?: Maybe<Address_Variance_Fields>;
};


/** aggregate fields of "Address" */
export type Address_Aggregate_FieldsCountArgs = {
  column?: Maybe<Address_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Address" */
export type Address_Aggregate_Order_By = {
  avg?: Maybe<Address_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Address_Max_Order_By>;
  min?: Maybe<Address_Min_Order_By>;
  stddev?: Maybe<Address_Stddev_Order_By>;
  stddev_pop?: Maybe<Address_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Address_Stddev_Samp_Order_By>;
  sum?: Maybe<Address_Sum_Order_By>;
  var_pop?: Maybe<Address_Var_Pop_Order_By>;
  var_samp?: Maybe<Address_Var_Samp_Order_By>;
  variance?: Maybe<Address_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Address_Avg_Fields = {
  __typename?: 'Address_avg_fields';
  AddressId?: Maybe<Scalars['Float']>;
  CountryId?: Maybe<Scalars['Float']>;
  StateId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Address" */
export type Address_Avg_Order_By = {
  AddressId?: Maybe<Order_By>;
  CountryId?: Maybe<Order_By>;
  StateId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  AddressId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_Country?: Maybe<C_Country_Bool_Exp>;
  C_StateProvince?: Maybe<C_StateProvince_Bool_Exp>;
  City?: Maybe<String_Mssql_Comparison_Exp>;
  CountryId?: Maybe<Int_Mssql_Comparison_Exp>;
  Line1?: Maybe<String_Mssql_Comparison_Exp>;
  Line2?: Maybe<String_Mssql_Comparison_Exp>;
  Line3?: Maybe<String_Mssql_Comparison_Exp>;
  Manufacturers?: Maybe<Manufacturer_Bool_Exp>;
  PostalCode?: Maybe<String_Mssql_Comparison_Exp>;
  RoofProjects?: Maybe<RoofProject_Bool_Exp>;
  StateId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Address_Bool_Exp>>;
  _not?: Maybe<Address_Bool_Exp>;
  _or?: Maybe<Array<Address_Bool_Exp>>;
};

/** upsert condition type for table "Address" */
export type Address_If_Matched = {
  match_columns?: Array<Address_Insert_Match_Column>;
  update_columns?: Array<Address_Update_Column>;
  where?: Maybe<Address_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Address" */
export type Address_Inc_Input = {
  CountryId?: Maybe<Scalars['Int']>;
  StateId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Address" */
export type Address_Insert_Input = {
  City?: Maybe<Scalars['String']>;
  CountryId?: Maybe<Scalars['Int']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  Line3?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  StateId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "Address" */
export enum Address_Insert_Match_Column {
  /** column name */
  AddressId = 'AddressId',
  /** column name */
  City = 'City',
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  Line1 = 'Line1',
  /** column name */
  Line2 = 'Line2',
  /** column name */
  Line3 = 'Line3',
  /** column name */
  PostalCode = 'PostalCode',
  /** column name */
  StateId = 'StateId'
}

/** aggregate max on columns */
export type Address_Max_Fields = {
  __typename?: 'Address_max_fields';
  AddressId?: Maybe<Scalars['Int']>;
  City?: Maybe<Scalars['String']>;
  CountryId?: Maybe<Scalars['Int']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  Line3?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  StateId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Address" */
export type Address_Max_Order_By = {
  AddressId?: Maybe<Order_By>;
  City?: Maybe<Order_By>;
  CountryId?: Maybe<Order_By>;
  Line1?: Maybe<Order_By>;
  Line2?: Maybe<Order_By>;
  Line3?: Maybe<Order_By>;
  PostalCode?: Maybe<Order_By>;
  StateId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Address_Min_Fields = {
  __typename?: 'Address_min_fields';
  AddressId?: Maybe<Scalars['Int']>;
  City?: Maybe<Scalars['String']>;
  CountryId?: Maybe<Scalars['Int']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  Line3?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  StateId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Address" */
export type Address_Min_Order_By = {
  AddressId?: Maybe<Order_By>;
  City?: Maybe<Order_By>;
  CountryId?: Maybe<Order_By>;
  Line1?: Maybe<Order_By>;
  Line2?: Maybe<Order_By>;
  Line3?: Maybe<Order_By>;
  PostalCode?: Maybe<Order_By>;
  StateId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Address" */
export type Address_Mutation_Response = {
  __typename?: 'Address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** Ordering options when selecting data from "Address". */
export type Address_Order_By = {
  AddressId?: Maybe<Order_By>;
  C_Country?: Maybe<C_Country_Order_By>;
  C_StateProvince?: Maybe<C_StateProvince_Order_By>;
  City?: Maybe<Order_By>;
  CountryId?: Maybe<Order_By>;
  Line1?: Maybe<Order_By>;
  Line2?: Maybe<Order_By>;
  Line3?: Maybe<Order_By>;
  Manufacturers_aggregate?: Maybe<Manufacturer_Aggregate_Order_By>;
  PostalCode?: Maybe<Order_By>;
  RoofProjects_aggregate?: Maybe<RoofProject_Aggregate_Order_By>;
  StateId?: Maybe<Order_By>;
};

/** primary key columns input for table: Address */
export type Address_Pk_Columns_Input = {
  AddressId: Scalars['Int'];
};

/** select columns of table "Address" */
export enum Address_Select_Column {
  /** column name */
  AddressId = 'AddressId',
  /** column name */
  City = 'City',
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  Line1 = 'Line1',
  /** column name */
  Line2 = 'Line2',
  /** column name */
  Line3 = 'Line3',
  /** column name */
  PostalCode = 'PostalCode',
  /** column name */
  StateId = 'StateId'
}

/** input type for updating data in table "Address" */
export type Address_Set_Input = {
  City?: Maybe<Scalars['String']>;
  CountryId?: Maybe<Scalars['Int']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  Line3?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  StateId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Address_Stddev_Fields = {
  __typename?: 'Address_stddev_fields';
  AddressId?: Maybe<Scalars['Float']>;
  CountryId?: Maybe<Scalars['Float']>;
  StateId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Address" */
export type Address_Stddev_Order_By = {
  AddressId?: Maybe<Order_By>;
  CountryId?: Maybe<Order_By>;
  StateId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Address_Stddev_Pop_Fields = {
  __typename?: 'Address_stddev_pop_fields';
  AddressId?: Maybe<Scalars['Float']>;
  CountryId?: Maybe<Scalars['Float']>;
  StateId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Address" */
export type Address_Stddev_Pop_Order_By = {
  AddressId?: Maybe<Order_By>;
  CountryId?: Maybe<Order_By>;
  StateId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Address_Stddev_Samp_Fields = {
  __typename?: 'Address_stddev_samp_fields';
  AddressId?: Maybe<Scalars['Float']>;
  CountryId?: Maybe<Scalars['Float']>;
  StateId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Address" */
export type Address_Stddev_Samp_Order_By = {
  AddressId?: Maybe<Order_By>;
  CountryId?: Maybe<Order_By>;
  StateId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Address_Sum_Fields = {
  __typename?: 'Address_sum_fields';
  AddressId?: Maybe<Scalars['Int']>;
  CountryId?: Maybe<Scalars['Int']>;
  StateId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Address" */
export type Address_Sum_Order_By = {
  AddressId?: Maybe<Order_By>;
  CountryId?: Maybe<Order_By>;
  StateId?: Maybe<Order_By>;
};

/** update columns of table "Address" */
export enum Address_Update_Column {
  /** column name */
  City = 'City',
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  Line1 = 'Line1',
  /** column name */
  Line2 = 'Line2',
  /** column name */
  Line3 = 'Line3',
  /** column name */
  PostalCode = 'PostalCode',
  /** column name */
  StateId = 'StateId'
}

/** aggregate var_pop on columns */
export type Address_Var_Pop_Fields = {
  __typename?: 'Address_var_pop_fields';
  AddressId?: Maybe<Scalars['Float']>;
  CountryId?: Maybe<Scalars['Float']>;
  StateId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Address" */
export type Address_Var_Pop_Order_By = {
  AddressId?: Maybe<Order_By>;
  CountryId?: Maybe<Order_By>;
  StateId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Address_Var_Samp_Fields = {
  __typename?: 'Address_var_samp_fields';
  AddressId?: Maybe<Scalars['Float']>;
  CountryId?: Maybe<Scalars['Float']>;
  StateId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Address" */
export type Address_Var_Samp_Order_By = {
  AddressId?: Maybe<Order_By>;
  CountryId?: Maybe<Order_By>;
  StateId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Address_Variance_Fields = {
  __typename?: 'Address_variance_fields';
  AddressId?: Maybe<Scalars['Float']>;
  CountryId?: Maybe<Scalars['Float']>;
  StateId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Address" */
export type Address_Variance_Order_By = {
  AddressId?: Maybe<Order_By>;
  CountryId?: Maybe<Order_By>;
  StateId?: Maybe<Order_By>;
};

/** columns and relationships of "AdheredSinglePlyOrphanReport" */
export type AdheredSinglePlyOrphanReport = {
  __typename?: 'AdheredSinglePlyOrphanReport';
  AdheredSinglePlyOrphansId: Scalars['Int'];
  ApprovedUseId: Scalars['Int'];
  AssemblyId: Scalars['Int'];
  ComponentId: Scalars['Int'];
  LayerId: Scalars['Int'];
  LayerTypeId: Scalars['Int'];
  OrphanReportDate: Scalars['datetime'];
  ReportId: Scalars['Int'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "AdheredSinglePlyOrphanReport" */
export type AdheredSinglePlyOrphanReport_Aggregate = {
  __typename?: 'AdheredSinglePlyOrphanReport_aggregate';
  aggregate?: Maybe<AdheredSinglePlyOrphanReport_Aggregate_Fields>;
  nodes: Array<AdheredSinglePlyOrphanReport>;
};

/** aggregate fields of "AdheredSinglePlyOrphanReport" */
export type AdheredSinglePlyOrphanReport_Aggregate_Fields = {
  __typename?: 'AdheredSinglePlyOrphanReport_aggregate_fields';
  avg?: Maybe<AdheredSinglePlyOrphanReport_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AdheredSinglePlyOrphanReport_Max_Fields>;
  min?: Maybe<AdheredSinglePlyOrphanReport_Min_Fields>;
  stddev?: Maybe<AdheredSinglePlyOrphanReport_Stddev_Fields>;
  stddev_pop?: Maybe<AdheredSinglePlyOrphanReport_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AdheredSinglePlyOrphanReport_Stddev_Samp_Fields>;
  sum?: Maybe<AdheredSinglePlyOrphanReport_Sum_Fields>;
  var_pop?: Maybe<AdheredSinglePlyOrphanReport_Var_Pop_Fields>;
  var_samp?: Maybe<AdheredSinglePlyOrphanReport_Var_Samp_Fields>;
  variance?: Maybe<AdheredSinglePlyOrphanReport_Variance_Fields>;
};


/** aggregate fields of "AdheredSinglePlyOrphanReport" */
export type AdheredSinglePlyOrphanReport_Aggregate_FieldsCountArgs = {
  column?: Maybe<AdheredSinglePlyOrphanReport_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AdheredSinglePlyOrphanReport_Avg_Fields = {
  __typename?: 'AdheredSinglePlyOrphanReport_avg_fields';
  AdheredSinglePlyOrphansId?: Maybe<Scalars['Float']>;
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  ReportId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "AdheredSinglePlyOrphanReport". All fields are combined with a logical 'AND'. */
export type AdheredSinglePlyOrphanReport_Bool_Exp = {
  AdheredSinglePlyOrphansId?: Maybe<Int_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  AssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  LayerTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  OrphanReportDate?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ReportId?: Maybe<Int_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<AdheredSinglePlyOrphanReport_Bool_Exp>>;
  _not?: Maybe<AdheredSinglePlyOrphanReport_Bool_Exp>;
  _or?: Maybe<Array<AdheredSinglePlyOrphanReport_Bool_Exp>>;
};

/** upsert condition type for table "AdheredSinglePlyOrphanReport" */
export type AdheredSinglePlyOrphanReport_If_Matched = {
  match_columns?: Array<AdheredSinglePlyOrphanReport_Insert_Match_Column>;
  update_columns?: Array<AdheredSinglePlyOrphanReport_Update_Column>;
  where?: Maybe<AdheredSinglePlyOrphanReport_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "AdheredSinglePlyOrphanReport" */
export type AdheredSinglePlyOrphanReport_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  ReportId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "AdheredSinglePlyOrphanReport" */
export type AdheredSinglePlyOrphanReport_Insert_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  OrphanReportDate?: Maybe<Scalars['datetime']>;
  ReportId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "AdheredSinglePlyOrphanReport" */
export enum AdheredSinglePlyOrphanReport_Insert_Match_Column {
  /** column name */
  AdheredSinglePlyOrphansId = 'AdheredSinglePlyOrphansId',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerTypeId = 'LayerTypeId',
  /** column name */
  OrphanReportDate = 'OrphanReportDate',
  /** column name */
  ReportId = 'ReportId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type AdheredSinglePlyOrphanReport_Max_Fields = {
  __typename?: 'AdheredSinglePlyOrphanReport_max_fields';
  AdheredSinglePlyOrphansId?: Maybe<Scalars['Int']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  OrphanReportDate?: Maybe<Scalars['datetime']>;
  ReportId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type AdheredSinglePlyOrphanReport_Min_Fields = {
  __typename?: 'AdheredSinglePlyOrphanReport_min_fields';
  AdheredSinglePlyOrphansId?: Maybe<Scalars['Int']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  OrphanReportDate?: Maybe<Scalars['datetime']>;
  ReportId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "AdheredSinglePlyOrphanReport" */
export type AdheredSinglePlyOrphanReport_Mutation_Response = {
  __typename?: 'AdheredSinglePlyOrphanReport_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AdheredSinglePlyOrphanReport>;
};

/** Ordering options when selecting data from "AdheredSinglePlyOrphanReport". */
export type AdheredSinglePlyOrphanReport_Order_By = {
  AdheredSinglePlyOrphansId?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  OrphanReportDate?: Maybe<Order_By>;
  ReportId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: AdheredSinglePlyOrphanReport */
export type AdheredSinglePlyOrphanReport_Pk_Columns_Input = {
  AdheredSinglePlyOrphansId: Scalars['Int'];
};

/** select columns of table "AdheredSinglePlyOrphanReport" */
export enum AdheredSinglePlyOrphanReport_Select_Column {
  /** column name */
  AdheredSinglePlyOrphansId = 'AdheredSinglePlyOrphansId',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerTypeId = 'LayerTypeId',
  /** column name */
  OrphanReportDate = 'OrphanReportDate',
  /** column name */
  ReportId = 'ReportId',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "AdheredSinglePlyOrphanReport" */
export type AdheredSinglePlyOrphanReport_Set_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  OrphanReportDate?: Maybe<Scalars['datetime']>;
  ReportId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AdheredSinglePlyOrphanReport_Stddev_Fields = {
  __typename?: 'AdheredSinglePlyOrphanReport_stddev_fields';
  AdheredSinglePlyOrphansId?: Maybe<Scalars['Float']>;
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  ReportId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AdheredSinglePlyOrphanReport_Stddev_Pop_Fields = {
  __typename?: 'AdheredSinglePlyOrphanReport_stddev_pop_fields';
  AdheredSinglePlyOrphansId?: Maybe<Scalars['Float']>;
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  ReportId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AdheredSinglePlyOrphanReport_Stddev_Samp_Fields = {
  __typename?: 'AdheredSinglePlyOrphanReport_stddev_samp_fields';
  AdheredSinglePlyOrphansId?: Maybe<Scalars['Float']>;
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  ReportId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AdheredSinglePlyOrphanReport_Sum_Fields = {
  __typename?: 'AdheredSinglePlyOrphanReport_sum_fields';
  AdheredSinglePlyOrphansId?: Maybe<Scalars['Int']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  ReportId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "AdheredSinglePlyOrphanReport" */
export enum AdheredSinglePlyOrphanReport_Update_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerTypeId = 'LayerTypeId',
  /** column name */
  OrphanReportDate = 'OrphanReportDate',
  /** column name */
  ReportId = 'ReportId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type AdheredSinglePlyOrphanReport_Var_Pop_Fields = {
  __typename?: 'AdheredSinglePlyOrphanReport_var_pop_fields';
  AdheredSinglePlyOrphansId?: Maybe<Scalars['Float']>;
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  ReportId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AdheredSinglePlyOrphanReport_Var_Samp_Fields = {
  __typename?: 'AdheredSinglePlyOrphanReport_var_samp_fields';
  AdheredSinglePlyOrphansId?: Maybe<Scalars['Float']>;
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  ReportId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AdheredSinglePlyOrphanReport_Variance_Fields = {
  __typename?: 'AdheredSinglePlyOrphanReport_variance_fields';
  AdheredSinglePlyOrphansId?: Maybe<Scalars['Float']>;
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  ReportId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "AdhesiveFullApplication" */
export type AdhesiveFullApplication = {
  __typename?: 'AdhesiveFullApplication';
  AdhesiveMaterialId: Scalars['Int'];
  AdhesiveTypeId: Scalars['Int'];
  /** An object relationship */
  C_AdhesiveMaterial: C_AdhesiveMaterial;
  /** An object relationship */
  C_AdhesiveType: C_AdhesiveType;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
};

/** columns and relationships of "AdhesiveFullApplicationUsage" */
export type AdhesiveFullApplicationUsage = {
  __typename?: 'AdhesiveFullApplicationUsage';
  OverSubstrateRateDry: Scalars['MeasurementValue'];
  OverSubstrateRateWet: Scalars['MeasurementValue'];
  SubstratePrimerRateDry: Scalars['MeasurementValue'];
  SubstratePrimerRateWet: Scalars['MeasurementValue'];
  UndersideRateDry: Scalars['MeasurementValue'];
  UndersideRateWet: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "AdhesiveFullApplicationUsage" */
export type AdhesiveFullApplicationUsage_Aggregate = {
  __typename?: 'AdhesiveFullApplicationUsage_aggregate';
  aggregate?: Maybe<AdhesiveFullApplicationUsage_Aggregate_Fields>;
  nodes: Array<AdhesiveFullApplicationUsage>;
};

/** aggregate fields of "AdhesiveFullApplicationUsage" */
export type AdhesiveFullApplicationUsage_Aggregate_Fields = {
  __typename?: 'AdhesiveFullApplicationUsage_aggregate_fields';
  avg?: Maybe<AdhesiveFullApplicationUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AdhesiveFullApplicationUsage_Max_Fields>;
  min?: Maybe<AdhesiveFullApplicationUsage_Min_Fields>;
  stddev?: Maybe<AdhesiveFullApplicationUsage_Stddev_Fields>;
  stddev_pop?: Maybe<AdhesiveFullApplicationUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AdhesiveFullApplicationUsage_Stddev_Samp_Fields>;
  sum?: Maybe<AdhesiveFullApplicationUsage_Sum_Fields>;
  var_pop?: Maybe<AdhesiveFullApplicationUsage_Var_Pop_Fields>;
  var_samp?: Maybe<AdhesiveFullApplicationUsage_Var_Samp_Fields>;
  variance?: Maybe<AdhesiveFullApplicationUsage_Variance_Fields>;
};


/** aggregate fields of "AdhesiveFullApplicationUsage" */
export type AdhesiveFullApplicationUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<AdhesiveFullApplicationUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AdhesiveFullApplicationUsage_Avg_Fields = {
  __typename?: 'AdhesiveFullApplicationUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "AdhesiveFullApplicationUsage". All fields are combined with a logical 'AND'. */
export type AdhesiveFullApplicationUsage_Bool_Exp = {
  OverSubstrateRateDry?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  OverSubstrateRateWet?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  SubstratePrimerRateDry?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  SubstratePrimerRateWet?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  UndersideRateDry?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  UndersideRateWet?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<AdhesiveFullApplicationUsage_Bool_Exp>>;
  _not?: Maybe<AdhesiveFullApplicationUsage_Bool_Exp>;
  _or?: Maybe<Array<AdhesiveFullApplicationUsage_Bool_Exp>>;
};

/** upsert condition type for table "AdhesiveFullApplicationUsage" */
export type AdhesiveFullApplicationUsage_If_Matched = {
  match_columns?: Array<AdhesiveFullApplicationUsage_Insert_Match_Column>;
  update_columns?: Array<AdhesiveFullApplicationUsage_Update_Column>;
  where?: Maybe<AdhesiveFullApplicationUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "AdhesiveFullApplicationUsage" */
export type AdhesiveFullApplicationUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "AdhesiveFullApplicationUsage" */
export type AdhesiveFullApplicationUsage_Insert_Input = {
  OverSubstrateRateDry?: Maybe<Scalars['MeasurementValue']>;
  OverSubstrateRateWet?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateDry?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateWet?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateDry?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateWet?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "AdhesiveFullApplicationUsage" */
export enum AdhesiveFullApplicationUsage_Insert_Match_Column {
  /** column name */
  OverSubstrateRateDry = 'OverSubstrateRateDry',
  /** column name */
  OverSubstrateRateWet = 'OverSubstrateRateWet',
  /** column name */
  SubstratePrimerRateDry = 'SubstratePrimerRateDry',
  /** column name */
  SubstratePrimerRateWet = 'SubstratePrimerRateWet',
  /** column name */
  UndersideRateDry = 'UndersideRateDry',
  /** column name */
  UndersideRateWet = 'UndersideRateWet',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type AdhesiveFullApplicationUsage_Max_Fields = {
  __typename?: 'AdhesiveFullApplicationUsage_max_fields';
  OverSubstrateRateDry?: Maybe<Scalars['MeasurementValue']>;
  OverSubstrateRateWet?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateDry?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateWet?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateDry?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateWet?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type AdhesiveFullApplicationUsage_Min_Fields = {
  __typename?: 'AdhesiveFullApplicationUsage_min_fields';
  OverSubstrateRateDry?: Maybe<Scalars['MeasurementValue']>;
  OverSubstrateRateWet?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateDry?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateWet?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateDry?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateWet?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "AdhesiveFullApplicationUsage" */
export type AdhesiveFullApplicationUsage_Mutation_Response = {
  __typename?: 'AdhesiveFullApplicationUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AdhesiveFullApplicationUsage>;
};

/** Ordering options when selecting data from "AdhesiveFullApplicationUsage". */
export type AdhesiveFullApplicationUsage_Order_By = {
  OverSubstrateRateDry?: Maybe<Order_By>;
  OverSubstrateRateWet?: Maybe<Order_By>;
  SubstratePrimerRateDry?: Maybe<Order_By>;
  SubstratePrimerRateWet?: Maybe<Order_By>;
  UndersideRateDry?: Maybe<Order_By>;
  UndersideRateWet?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: AdhesiveFullApplicationUsage */
export type AdhesiveFullApplicationUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "AdhesiveFullApplicationUsage" */
export enum AdhesiveFullApplicationUsage_Select_Column {
  /** column name */
  OverSubstrateRateDry = 'OverSubstrateRateDry',
  /** column name */
  OverSubstrateRateWet = 'OverSubstrateRateWet',
  /** column name */
  SubstratePrimerRateDry = 'SubstratePrimerRateDry',
  /** column name */
  SubstratePrimerRateWet = 'SubstratePrimerRateWet',
  /** column name */
  UndersideRateDry = 'UndersideRateDry',
  /** column name */
  UndersideRateWet = 'UndersideRateWet',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "AdhesiveFullApplicationUsage" */
export type AdhesiveFullApplicationUsage_Set_Input = {
  OverSubstrateRateDry?: Maybe<Scalars['MeasurementValue']>;
  OverSubstrateRateWet?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateDry?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateWet?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateDry?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateWet?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AdhesiveFullApplicationUsage_Stddev_Fields = {
  __typename?: 'AdhesiveFullApplicationUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AdhesiveFullApplicationUsage_Stddev_Pop_Fields = {
  __typename?: 'AdhesiveFullApplicationUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AdhesiveFullApplicationUsage_Stddev_Samp_Fields = {
  __typename?: 'AdhesiveFullApplicationUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AdhesiveFullApplicationUsage_Sum_Fields = {
  __typename?: 'AdhesiveFullApplicationUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "AdhesiveFullApplicationUsage" */
export enum AdhesiveFullApplicationUsage_Update_Column {
  /** column name */
  OverSubstrateRateDry = 'OverSubstrateRateDry',
  /** column name */
  OverSubstrateRateWet = 'OverSubstrateRateWet',
  /** column name */
  SubstratePrimerRateDry = 'SubstratePrimerRateDry',
  /** column name */
  SubstratePrimerRateWet = 'SubstratePrimerRateWet',
  /** column name */
  UndersideRateDry = 'UndersideRateDry',
  /** column name */
  UndersideRateWet = 'UndersideRateWet',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type AdhesiveFullApplicationUsage_Var_Pop_Fields = {
  __typename?: 'AdhesiveFullApplicationUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AdhesiveFullApplicationUsage_Var_Samp_Fields = {
  __typename?: 'AdhesiveFullApplicationUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AdhesiveFullApplicationUsage_Variance_Fields = {
  __typename?: 'AdhesiveFullApplicationUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Aggregate = {
  __typename?: 'AdhesiveFullApplication_aggregate';
  aggregate?: Maybe<AdhesiveFullApplication_Aggregate_Fields>;
  nodes: Array<AdhesiveFullApplication>;
};

/** aggregate fields of "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Aggregate_Fields = {
  __typename?: 'AdhesiveFullApplication_aggregate_fields';
  avg?: Maybe<AdhesiveFullApplication_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AdhesiveFullApplication_Max_Fields>;
  min?: Maybe<AdhesiveFullApplication_Min_Fields>;
  stddev?: Maybe<AdhesiveFullApplication_Stddev_Fields>;
  stddev_pop?: Maybe<AdhesiveFullApplication_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AdhesiveFullApplication_Stddev_Samp_Fields>;
  sum?: Maybe<AdhesiveFullApplication_Sum_Fields>;
  var_pop?: Maybe<AdhesiveFullApplication_Var_Pop_Fields>;
  var_samp?: Maybe<AdhesiveFullApplication_Var_Samp_Fields>;
  variance?: Maybe<AdhesiveFullApplication_Variance_Fields>;
};


/** aggregate fields of "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Aggregate_FieldsCountArgs = {
  column?: Maybe<AdhesiveFullApplication_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Aggregate_Order_By = {
  avg?: Maybe<AdhesiveFullApplication_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<AdhesiveFullApplication_Max_Order_By>;
  min?: Maybe<AdhesiveFullApplication_Min_Order_By>;
  stddev?: Maybe<AdhesiveFullApplication_Stddev_Order_By>;
  stddev_pop?: Maybe<AdhesiveFullApplication_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<AdhesiveFullApplication_Stddev_Samp_Order_By>;
  sum?: Maybe<AdhesiveFullApplication_Sum_Order_By>;
  var_pop?: Maybe<AdhesiveFullApplication_Var_Pop_Order_By>;
  var_samp?: Maybe<AdhesiveFullApplication_Var_Samp_Order_By>;
  variance?: Maybe<AdhesiveFullApplication_Variance_Order_By>;
};

/** aggregate avg on columns */
export type AdhesiveFullApplication_Avg_Fields = {
  __typename?: 'AdhesiveFullApplication_avg_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Avg_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "AdhesiveFullApplication". All fields are combined with a logical 'AND'. */
export type AdhesiveFullApplication_Bool_Exp = {
  AdhesiveMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  AdhesiveTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_AdhesiveMaterial?: Maybe<C_AdhesiveMaterial_Bool_Exp>;
  C_AdhesiveType?: Maybe<C_AdhesiveType_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  _and?: Maybe<Array<AdhesiveFullApplication_Bool_Exp>>;
  _not?: Maybe<AdhesiveFullApplication_Bool_Exp>;
  _or?: Maybe<Array<AdhesiveFullApplication_Bool_Exp>>;
};

/** upsert condition type for table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_If_Matched = {
  match_columns?: Array<AdhesiveFullApplication_Insert_Match_Column>;
  update_columns?: Array<AdhesiveFullApplication_Update_Column>;
  where?: Maybe<AdhesiveFullApplication_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Inc_Input = {
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Insert_Input = {
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "AdhesiveFullApplication" */
export enum AdhesiveFullApplication_Insert_Match_Column {
  /** column name */
  AdhesiveMaterialId = 'AdhesiveMaterialId',
  /** column name */
  AdhesiveTypeId = 'AdhesiveTypeId',
  /** column name */
  ComponentId = 'ComponentId'
}

/** aggregate max on columns */
export type AdhesiveFullApplication_Max_Fields = {
  __typename?: 'AdhesiveFullApplication_max_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Max_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type AdhesiveFullApplication_Min_Fields = {
  __typename?: 'AdhesiveFullApplication_min_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Min_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Mutation_Response = {
  __typename?: 'AdhesiveFullApplication_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AdhesiveFullApplication>;
};

/** Ordering options when selecting data from "AdhesiveFullApplication". */
export type AdhesiveFullApplication_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  C_AdhesiveMaterial?: Maybe<C_AdhesiveMaterial_Order_By>;
  C_AdhesiveType?: Maybe<C_AdhesiveType_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
};

/** primary key columns input for table: AdhesiveFullApplication */
export type AdhesiveFullApplication_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "AdhesiveFullApplication" */
export enum AdhesiveFullApplication_Select_Column {
  /** column name */
  AdhesiveMaterialId = 'AdhesiveMaterialId',
  /** column name */
  AdhesiveTypeId = 'AdhesiveTypeId',
  /** column name */
  ComponentId = 'ComponentId'
}

/** input type for updating data in table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Set_Input = {
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AdhesiveFullApplication_Stddev_Fields = {
  __typename?: 'AdhesiveFullApplication_stddev_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Stddev_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type AdhesiveFullApplication_Stddev_Pop_Fields = {
  __typename?: 'AdhesiveFullApplication_stddev_pop_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Stddev_Pop_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type AdhesiveFullApplication_Stddev_Samp_Fields = {
  __typename?: 'AdhesiveFullApplication_stddev_samp_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Stddev_Samp_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type AdhesiveFullApplication_Sum_Fields = {
  __typename?: 'AdhesiveFullApplication_sum_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Sum_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** update columns of table "AdhesiveFullApplication" */
export enum AdhesiveFullApplication_Update_Column {
  /** column name */
  AdhesiveMaterialId = 'AdhesiveMaterialId',
  /** column name */
  AdhesiveTypeId = 'AdhesiveTypeId',
  /** column name */
  ComponentId = 'ComponentId'
}

/** aggregate var_pop on columns */
export type AdhesiveFullApplication_Var_Pop_Fields = {
  __typename?: 'AdhesiveFullApplication_var_pop_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Var_Pop_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type AdhesiveFullApplication_Var_Samp_Fields = {
  __typename?: 'AdhesiveFullApplication_var_samp_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Var_Samp_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type AdhesiveFullApplication_Variance_Fields = {
  __typename?: 'AdhesiveFullApplication_variance_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "AdhesiveFullApplication" */
export type AdhesiveFullApplication_Variance_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage = {
  __typename?: 'AdhesiveRibbonUsage';
  AdhesiveApplicationMethodId: Scalars['Int'];
  /** An object relationship */
  C_AdhesiveApplicationMethod: C_AdhesiveApplicationMethod;
  RibbonWidth: Scalars['MeasurementValue'];
  RowSpacing: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Aggregate = {
  __typename?: 'AdhesiveRibbonUsage_aggregate';
  aggregate?: Maybe<AdhesiveRibbonUsage_Aggregate_Fields>;
  nodes: Array<AdhesiveRibbonUsage>;
};

/** aggregate fields of "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Aggregate_Fields = {
  __typename?: 'AdhesiveRibbonUsage_aggregate_fields';
  avg?: Maybe<AdhesiveRibbonUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AdhesiveRibbonUsage_Max_Fields>;
  min?: Maybe<AdhesiveRibbonUsage_Min_Fields>;
  stddev?: Maybe<AdhesiveRibbonUsage_Stddev_Fields>;
  stddev_pop?: Maybe<AdhesiveRibbonUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AdhesiveRibbonUsage_Stddev_Samp_Fields>;
  sum?: Maybe<AdhesiveRibbonUsage_Sum_Fields>;
  var_pop?: Maybe<AdhesiveRibbonUsage_Var_Pop_Fields>;
  var_samp?: Maybe<AdhesiveRibbonUsage_Var_Samp_Fields>;
  variance?: Maybe<AdhesiveRibbonUsage_Variance_Fields>;
};


/** aggregate fields of "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<AdhesiveRibbonUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Aggregate_Order_By = {
  avg?: Maybe<AdhesiveRibbonUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<AdhesiveRibbonUsage_Max_Order_By>;
  min?: Maybe<AdhesiveRibbonUsage_Min_Order_By>;
  stddev?: Maybe<AdhesiveRibbonUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<AdhesiveRibbonUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<AdhesiveRibbonUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<AdhesiveRibbonUsage_Sum_Order_By>;
  var_pop?: Maybe<AdhesiveRibbonUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<AdhesiveRibbonUsage_Var_Samp_Order_By>;
  variance?: Maybe<AdhesiveRibbonUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type AdhesiveRibbonUsage_Avg_Fields = {
  __typename?: 'AdhesiveRibbonUsage_avg_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Avg_Order_By = {
  AdhesiveApplicationMethodId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "AdhesiveRibbonUsage". All fields are combined with a logical 'AND'. */
export type AdhesiveRibbonUsage_Bool_Exp = {
  AdhesiveApplicationMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_AdhesiveApplicationMethod?: Maybe<C_AdhesiveApplicationMethod_Bool_Exp>;
  RibbonWidth?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  RowSpacing?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<AdhesiveRibbonUsage_Bool_Exp>>;
  _not?: Maybe<AdhesiveRibbonUsage_Bool_Exp>;
  _or?: Maybe<Array<AdhesiveRibbonUsage_Bool_Exp>>;
};

/** upsert condition type for table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_If_Matched = {
  match_columns?: Array<AdhesiveRibbonUsage_Insert_Match_Column>;
  update_columns?: Array<AdhesiveRibbonUsage_Update_Column>;
  where?: Maybe<AdhesiveRibbonUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Inc_Input = {
  AdhesiveApplicationMethodId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Insert_Input = {
  AdhesiveApplicationMethodId?: Maybe<Scalars['Int']>;
  RibbonWidth?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "AdhesiveRibbonUsage" */
export enum AdhesiveRibbonUsage_Insert_Match_Column {
  /** column name */
  AdhesiveApplicationMethodId = 'AdhesiveApplicationMethodId',
  /** column name */
  RibbonWidth = 'RibbonWidth',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type AdhesiveRibbonUsage_Max_Fields = {
  __typename?: 'AdhesiveRibbonUsage_max_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Int']>;
  RibbonWidth?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Max_Order_By = {
  AdhesiveApplicationMethodId?: Maybe<Order_By>;
  RibbonWidth?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type AdhesiveRibbonUsage_Min_Fields = {
  __typename?: 'AdhesiveRibbonUsage_min_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Int']>;
  RibbonWidth?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Min_Order_By = {
  AdhesiveApplicationMethodId?: Maybe<Order_By>;
  RibbonWidth?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Mutation_Response = {
  __typename?: 'AdhesiveRibbonUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AdhesiveRibbonUsage>;
};

/** Ordering options when selecting data from "AdhesiveRibbonUsage". */
export type AdhesiveRibbonUsage_Order_By = {
  AdhesiveApplicationMethodId?: Maybe<Order_By>;
  C_AdhesiveApplicationMethod?: Maybe<C_AdhesiveApplicationMethod_Order_By>;
  RibbonWidth?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: AdhesiveRibbonUsage */
export type AdhesiveRibbonUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "AdhesiveRibbonUsage" */
export enum AdhesiveRibbonUsage_Select_Column {
  /** column name */
  AdhesiveApplicationMethodId = 'AdhesiveApplicationMethodId',
  /** column name */
  RibbonWidth = 'RibbonWidth',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Set_Input = {
  AdhesiveApplicationMethodId?: Maybe<Scalars['Int']>;
  RibbonWidth?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AdhesiveRibbonUsage_Stddev_Fields = {
  __typename?: 'AdhesiveRibbonUsage_stddev_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Stddev_Order_By = {
  AdhesiveApplicationMethodId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type AdhesiveRibbonUsage_Stddev_Pop_Fields = {
  __typename?: 'AdhesiveRibbonUsage_stddev_pop_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Stddev_Pop_Order_By = {
  AdhesiveApplicationMethodId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type AdhesiveRibbonUsage_Stddev_Samp_Fields = {
  __typename?: 'AdhesiveRibbonUsage_stddev_samp_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Stddev_Samp_Order_By = {
  AdhesiveApplicationMethodId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type AdhesiveRibbonUsage_Sum_Fields = {
  __typename?: 'AdhesiveRibbonUsage_sum_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Sum_Order_By = {
  AdhesiveApplicationMethodId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "AdhesiveRibbonUsage" */
export enum AdhesiveRibbonUsage_Update_Column {
  /** column name */
  AdhesiveApplicationMethodId = 'AdhesiveApplicationMethodId',
  /** column name */
  RibbonWidth = 'RibbonWidth',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type AdhesiveRibbonUsage_Var_Pop_Fields = {
  __typename?: 'AdhesiveRibbonUsage_var_pop_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Var_Pop_Order_By = {
  AdhesiveApplicationMethodId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type AdhesiveRibbonUsage_Var_Samp_Fields = {
  __typename?: 'AdhesiveRibbonUsage_var_samp_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Var_Samp_Order_By = {
  AdhesiveApplicationMethodId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type AdhesiveRibbonUsage_Variance_Fields = {
  __typename?: 'AdhesiveRibbonUsage_variance_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "AdhesiveRibbonUsage" */
export type AdhesiveRibbonUsage_Variance_Order_By = {
  AdhesiveApplicationMethodId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "AdhesiveRibbons" */
export type AdhesiveRibbons = {
  __typename?: 'AdhesiveRibbons';
  AdhesiveMaterialId: Scalars['Int'];
  AdhesiveTypeId: Scalars['Int'];
  /** An object relationship */
  C_AdhesiveMaterial: C_AdhesiveMaterial;
  /** An object relationship */
  C_AdhesiveType: C_AdhesiveType;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  TapeWidth: Scalars['MeasurementValue'];
};

/** aggregated selection of "AdhesiveRibbons" */
export type AdhesiveRibbons_Aggregate = {
  __typename?: 'AdhesiveRibbons_aggregate';
  aggregate?: Maybe<AdhesiveRibbons_Aggregate_Fields>;
  nodes: Array<AdhesiveRibbons>;
};

/** aggregate fields of "AdhesiveRibbons" */
export type AdhesiveRibbons_Aggregate_Fields = {
  __typename?: 'AdhesiveRibbons_aggregate_fields';
  avg?: Maybe<AdhesiveRibbons_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AdhesiveRibbons_Max_Fields>;
  min?: Maybe<AdhesiveRibbons_Min_Fields>;
  stddev?: Maybe<AdhesiveRibbons_Stddev_Fields>;
  stddev_pop?: Maybe<AdhesiveRibbons_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AdhesiveRibbons_Stddev_Samp_Fields>;
  sum?: Maybe<AdhesiveRibbons_Sum_Fields>;
  var_pop?: Maybe<AdhesiveRibbons_Var_Pop_Fields>;
  var_samp?: Maybe<AdhesiveRibbons_Var_Samp_Fields>;
  variance?: Maybe<AdhesiveRibbons_Variance_Fields>;
};


/** aggregate fields of "AdhesiveRibbons" */
export type AdhesiveRibbons_Aggregate_FieldsCountArgs = {
  column?: Maybe<AdhesiveRibbons_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "AdhesiveRibbons" */
export type AdhesiveRibbons_Aggregate_Order_By = {
  avg?: Maybe<AdhesiveRibbons_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<AdhesiveRibbons_Max_Order_By>;
  min?: Maybe<AdhesiveRibbons_Min_Order_By>;
  stddev?: Maybe<AdhesiveRibbons_Stddev_Order_By>;
  stddev_pop?: Maybe<AdhesiveRibbons_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<AdhesiveRibbons_Stddev_Samp_Order_By>;
  sum?: Maybe<AdhesiveRibbons_Sum_Order_By>;
  var_pop?: Maybe<AdhesiveRibbons_Var_Pop_Order_By>;
  var_samp?: Maybe<AdhesiveRibbons_Var_Samp_Order_By>;
  variance?: Maybe<AdhesiveRibbons_Variance_Order_By>;
};

/** aggregate avg on columns */
export type AdhesiveRibbons_Avg_Fields = {
  __typename?: 'AdhesiveRibbons_avg_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "AdhesiveRibbons" */
export type AdhesiveRibbons_Avg_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "AdhesiveRibbons". All fields are combined with a logical 'AND'. */
export type AdhesiveRibbons_Bool_Exp = {
  AdhesiveMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  AdhesiveTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_AdhesiveMaterial?: Maybe<C_AdhesiveMaterial_Bool_Exp>;
  C_AdhesiveType?: Maybe<C_AdhesiveType_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  TapeWidth?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<AdhesiveRibbons_Bool_Exp>>;
  _not?: Maybe<AdhesiveRibbons_Bool_Exp>;
  _or?: Maybe<Array<AdhesiveRibbons_Bool_Exp>>;
};

/** upsert condition type for table "AdhesiveRibbons" */
export type AdhesiveRibbons_If_Matched = {
  match_columns?: Array<AdhesiveRibbons_Insert_Match_Column>;
  update_columns?: Array<AdhesiveRibbons_Update_Column>;
  where?: Maybe<AdhesiveRibbons_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "AdhesiveRibbons" */
export type AdhesiveRibbons_Inc_Input = {
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "AdhesiveRibbons" */
export type AdhesiveRibbons_Insert_Input = {
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  TapeWidth?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "AdhesiveRibbons" */
export enum AdhesiveRibbons_Insert_Match_Column {
  /** column name */
  AdhesiveMaterialId = 'AdhesiveMaterialId',
  /** column name */
  AdhesiveTypeId = 'AdhesiveTypeId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  TapeWidth = 'TapeWidth'
}

/** aggregate max on columns */
export type AdhesiveRibbons_Max_Fields = {
  __typename?: 'AdhesiveRibbons_max_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  TapeWidth?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "AdhesiveRibbons" */
export type AdhesiveRibbons_Max_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  TapeWidth?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type AdhesiveRibbons_Min_Fields = {
  __typename?: 'AdhesiveRibbons_min_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  TapeWidth?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "AdhesiveRibbons" */
export type AdhesiveRibbons_Min_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  TapeWidth?: Maybe<Order_By>;
};

/** response of any mutation on the table "AdhesiveRibbons" */
export type AdhesiveRibbons_Mutation_Response = {
  __typename?: 'AdhesiveRibbons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AdhesiveRibbons>;
};

/** Ordering options when selecting data from "AdhesiveRibbons". */
export type AdhesiveRibbons_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  C_AdhesiveMaterial?: Maybe<C_AdhesiveMaterial_Order_By>;
  C_AdhesiveType?: Maybe<C_AdhesiveType_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  TapeWidth?: Maybe<Order_By>;
};

/** primary key columns input for table: AdhesiveRibbons */
export type AdhesiveRibbons_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "AdhesiveRibbons" */
export enum AdhesiveRibbons_Select_Column {
  /** column name */
  AdhesiveMaterialId = 'AdhesiveMaterialId',
  /** column name */
  AdhesiveTypeId = 'AdhesiveTypeId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  TapeWidth = 'TapeWidth'
}

/** input type for updating data in table "AdhesiveRibbons" */
export type AdhesiveRibbons_Set_Input = {
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  TapeWidth?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type AdhesiveRibbons_Stddev_Fields = {
  __typename?: 'AdhesiveRibbons_stddev_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "AdhesiveRibbons" */
export type AdhesiveRibbons_Stddev_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type AdhesiveRibbons_Stddev_Pop_Fields = {
  __typename?: 'AdhesiveRibbons_stddev_pop_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "AdhesiveRibbons" */
export type AdhesiveRibbons_Stddev_Pop_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type AdhesiveRibbons_Stddev_Samp_Fields = {
  __typename?: 'AdhesiveRibbons_stddev_samp_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "AdhesiveRibbons" */
export type AdhesiveRibbons_Stddev_Samp_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type AdhesiveRibbons_Sum_Fields = {
  __typename?: 'AdhesiveRibbons_sum_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "AdhesiveRibbons" */
export type AdhesiveRibbons_Sum_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** update columns of table "AdhesiveRibbons" */
export enum AdhesiveRibbons_Update_Column {
  /** column name */
  AdhesiveMaterialId = 'AdhesiveMaterialId',
  /** column name */
  AdhesiveTypeId = 'AdhesiveTypeId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  TapeWidth = 'TapeWidth'
}

/** aggregate var_pop on columns */
export type AdhesiveRibbons_Var_Pop_Fields = {
  __typename?: 'AdhesiveRibbons_var_pop_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "AdhesiveRibbons" */
export type AdhesiveRibbons_Var_Pop_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type AdhesiveRibbons_Var_Samp_Fields = {
  __typename?: 'AdhesiveRibbons_var_samp_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "AdhesiveRibbons" */
export type AdhesiveRibbons_Var_Samp_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type AdhesiveRibbons_Variance_Fields = {
  __typename?: 'AdhesiveRibbons_variance_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "AdhesiveRibbons" */
export type AdhesiveRibbons_Variance_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "AdhesiveSpots" */
export type AdhesiveSpots = {
  __typename?: 'AdhesiveSpots';
  AdhesiveMaterialId: Scalars['Int'];
  AdhesiveTypeId: Scalars['Int'];
  /** An object relationship */
  C_AdhesiveMaterial: C_AdhesiveMaterial;
  /** An object relationship */
  C_AdhesiveType: C_AdhesiveType;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
};

/** columns and relationships of "AdhesiveSpotsUsage" */
export type AdhesiveSpotsUsage = {
  __typename?: 'AdhesiveSpotsUsage';
  OnCenter: Scalars['MeasurementValue'];
  RowSpacing: Scalars['MeasurementValue'];
  SpotDiameter: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "AdhesiveSpotsUsage" */
export type AdhesiveSpotsUsage_Aggregate = {
  __typename?: 'AdhesiveSpotsUsage_aggregate';
  aggregate?: Maybe<AdhesiveSpotsUsage_Aggregate_Fields>;
  nodes: Array<AdhesiveSpotsUsage>;
};

/** aggregate fields of "AdhesiveSpotsUsage" */
export type AdhesiveSpotsUsage_Aggregate_Fields = {
  __typename?: 'AdhesiveSpotsUsage_aggregate_fields';
  avg?: Maybe<AdhesiveSpotsUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AdhesiveSpotsUsage_Max_Fields>;
  min?: Maybe<AdhesiveSpotsUsage_Min_Fields>;
  stddev?: Maybe<AdhesiveSpotsUsage_Stddev_Fields>;
  stddev_pop?: Maybe<AdhesiveSpotsUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AdhesiveSpotsUsage_Stddev_Samp_Fields>;
  sum?: Maybe<AdhesiveSpotsUsage_Sum_Fields>;
  var_pop?: Maybe<AdhesiveSpotsUsage_Var_Pop_Fields>;
  var_samp?: Maybe<AdhesiveSpotsUsage_Var_Samp_Fields>;
  variance?: Maybe<AdhesiveSpotsUsage_Variance_Fields>;
};


/** aggregate fields of "AdhesiveSpotsUsage" */
export type AdhesiveSpotsUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<AdhesiveSpotsUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AdhesiveSpotsUsage_Avg_Fields = {
  __typename?: 'AdhesiveSpotsUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "AdhesiveSpotsUsage". All fields are combined with a logical 'AND'. */
export type AdhesiveSpotsUsage_Bool_Exp = {
  OnCenter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  RowSpacing?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  SpotDiameter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<AdhesiveSpotsUsage_Bool_Exp>>;
  _not?: Maybe<AdhesiveSpotsUsage_Bool_Exp>;
  _or?: Maybe<Array<AdhesiveSpotsUsage_Bool_Exp>>;
};

/** upsert condition type for table "AdhesiveSpotsUsage" */
export type AdhesiveSpotsUsage_If_Matched = {
  match_columns?: Array<AdhesiveSpotsUsage_Insert_Match_Column>;
  update_columns?: Array<AdhesiveSpotsUsage_Update_Column>;
  where?: Maybe<AdhesiveSpotsUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "AdhesiveSpotsUsage" */
export type AdhesiveSpotsUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "AdhesiveSpotsUsage" */
export type AdhesiveSpotsUsage_Insert_Input = {
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  SpotDiameter?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "AdhesiveSpotsUsage" */
export enum AdhesiveSpotsUsage_Insert_Match_Column {
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  SpotDiameter = 'SpotDiameter',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type AdhesiveSpotsUsage_Max_Fields = {
  __typename?: 'AdhesiveSpotsUsage_max_fields';
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  SpotDiameter?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type AdhesiveSpotsUsage_Min_Fields = {
  __typename?: 'AdhesiveSpotsUsage_min_fields';
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  SpotDiameter?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "AdhesiveSpotsUsage" */
export type AdhesiveSpotsUsage_Mutation_Response = {
  __typename?: 'AdhesiveSpotsUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AdhesiveSpotsUsage>;
};

/** Ordering options when selecting data from "AdhesiveSpotsUsage". */
export type AdhesiveSpotsUsage_Order_By = {
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  SpotDiameter?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: AdhesiveSpotsUsage */
export type AdhesiveSpotsUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "AdhesiveSpotsUsage" */
export enum AdhesiveSpotsUsage_Select_Column {
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  SpotDiameter = 'SpotDiameter',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "AdhesiveSpotsUsage" */
export type AdhesiveSpotsUsage_Set_Input = {
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  SpotDiameter?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AdhesiveSpotsUsage_Stddev_Fields = {
  __typename?: 'AdhesiveSpotsUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AdhesiveSpotsUsage_Stddev_Pop_Fields = {
  __typename?: 'AdhesiveSpotsUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AdhesiveSpotsUsage_Stddev_Samp_Fields = {
  __typename?: 'AdhesiveSpotsUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AdhesiveSpotsUsage_Sum_Fields = {
  __typename?: 'AdhesiveSpotsUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "AdhesiveSpotsUsage" */
export enum AdhesiveSpotsUsage_Update_Column {
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  SpotDiameter = 'SpotDiameter',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type AdhesiveSpotsUsage_Var_Pop_Fields = {
  __typename?: 'AdhesiveSpotsUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AdhesiveSpotsUsage_Var_Samp_Fields = {
  __typename?: 'AdhesiveSpotsUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AdhesiveSpotsUsage_Variance_Fields = {
  __typename?: 'AdhesiveSpotsUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "AdhesiveSpots" */
export type AdhesiveSpots_Aggregate = {
  __typename?: 'AdhesiveSpots_aggregate';
  aggregate?: Maybe<AdhesiveSpots_Aggregate_Fields>;
  nodes: Array<AdhesiveSpots>;
};

/** aggregate fields of "AdhesiveSpots" */
export type AdhesiveSpots_Aggregate_Fields = {
  __typename?: 'AdhesiveSpots_aggregate_fields';
  avg?: Maybe<AdhesiveSpots_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AdhesiveSpots_Max_Fields>;
  min?: Maybe<AdhesiveSpots_Min_Fields>;
  stddev?: Maybe<AdhesiveSpots_Stddev_Fields>;
  stddev_pop?: Maybe<AdhesiveSpots_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AdhesiveSpots_Stddev_Samp_Fields>;
  sum?: Maybe<AdhesiveSpots_Sum_Fields>;
  var_pop?: Maybe<AdhesiveSpots_Var_Pop_Fields>;
  var_samp?: Maybe<AdhesiveSpots_Var_Samp_Fields>;
  variance?: Maybe<AdhesiveSpots_Variance_Fields>;
};


/** aggregate fields of "AdhesiveSpots" */
export type AdhesiveSpots_Aggregate_FieldsCountArgs = {
  column?: Maybe<AdhesiveSpots_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "AdhesiveSpots" */
export type AdhesiveSpots_Aggregate_Order_By = {
  avg?: Maybe<AdhesiveSpots_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<AdhesiveSpots_Max_Order_By>;
  min?: Maybe<AdhesiveSpots_Min_Order_By>;
  stddev?: Maybe<AdhesiveSpots_Stddev_Order_By>;
  stddev_pop?: Maybe<AdhesiveSpots_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<AdhesiveSpots_Stddev_Samp_Order_By>;
  sum?: Maybe<AdhesiveSpots_Sum_Order_By>;
  var_pop?: Maybe<AdhesiveSpots_Var_Pop_Order_By>;
  var_samp?: Maybe<AdhesiveSpots_Var_Samp_Order_By>;
  variance?: Maybe<AdhesiveSpots_Variance_Order_By>;
};

/** aggregate avg on columns */
export type AdhesiveSpots_Avg_Fields = {
  __typename?: 'AdhesiveSpots_avg_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "AdhesiveSpots" */
export type AdhesiveSpots_Avg_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "AdhesiveSpots". All fields are combined with a logical 'AND'. */
export type AdhesiveSpots_Bool_Exp = {
  AdhesiveMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  AdhesiveTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_AdhesiveMaterial?: Maybe<C_AdhesiveMaterial_Bool_Exp>;
  C_AdhesiveType?: Maybe<C_AdhesiveType_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  _and?: Maybe<Array<AdhesiveSpots_Bool_Exp>>;
  _not?: Maybe<AdhesiveSpots_Bool_Exp>;
  _or?: Maybe<Array<AdhesiveSpots_Bool_Exp>>;
};

/** upsert condition type for table "AdhesiveSpots" */
export type AdhesiveSpots_If_Matched = {
  match_columns?: Array<AdhesiveSpots_Insert_Match_Column>;
  update_columns?: Array<AdhesiveSpots_Update_Column>;
  where?: Maybe<AdhesiveSpots_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "AdhesiveSpots" */
export type AdhesiveSpots_Inc_Input = {
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "AdhesiveSpots" */
export type AdhesiveSpots_Insert_Input = {
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "AdhesiveSpots" */
export enum AdhesiveSpots_Insert_Match_Column {
  /** column name */
  AdhesiveMaterialId = 'AdhesiveMaterialId',
  /** column name */
  AdhesiveTypeId = 'AdhesiveTypeId',
  /** column name */
  ComponentId = 'ComponentId'
}

/** aggregate max on columns */
export type AdhesiveSpots_Max_Fields = {
  __typename?: 'AdhesiveSpots_max_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "AdhesiveSpots" */
export type AdhesiveSpots_Max_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type AdhesiveSpots_Min_Fields = {
  __typename?: 'AdhesiveSpots_min_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "AdhesiveSpots" */
export type AdhesiveSpots_Min_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "AdhesiveSpots" */
export type AdhesiveSpots_Mutation_Response = {
  __typename?: 'AdhesiveSpots_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AdhesiveSpots>;
};

/** Ordering options when selecting data from "AdhesiveSpots". */
export type AdhesiveSpots_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  C_AdhesiveMaterial?: Maybe<C_AdhesiveMaterial_Order_By>;
  C_AdhesiveType?: Maybe<C_AdhesiveType_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
};

/** primary key columns input for table: AdhesiveSpots */
export type AdhesiveSpots_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "AdhesiveSpots" */
export enum AdhesiveSpots_Select_Column {
  /** column name */
  AdhesiveMaterialId = 'AdhesiveMaterialId',
  /** column name */
  AdhesiveTypeId = 'AdhesiveTypeId',
  /** column name */
  ComponentId = 'ComponentId'
}

/** input type for updating data in table "AdhesiveSpots" */
export type AdhesiveSpots_Set_Input = {
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AdhesiveSpots_Stddev_Fields = {
  __typename?: 'AdhesiveSpots_stddev_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "AdhesiveSpots" */
export type AdhesiveSpots_Stddev_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type AdhesiveSpots_Stddev_Pop_Fields = {
  __typename?: 'AdhesiveSpots_stddev_pop_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "AdhesiveSpots" */
export type AdhesiveSpots_Stddev_Pop_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type AdhesiveSpots_Stddev_Samp_Fields = {
  __typename?: 'AdhesiveSpots_stddev_samp_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "AdhesiveSpots" */
export type AdhesiveSpots_Stddev_Samp_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type AdhesiveSpots_Sum_Fields = {
  __typename?: 'AdhesiveSpots_sum_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "AdhesiveSpots" */
export type AdhesiveSpots_Sum_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** update columns of table "AdhesiveSpots" */
export enum AdhesiveSpots_Update_Column {
  /** column name */
  AdhesiveMaterialId = 'AdhesiveMaterialId',
  /** column name */
  AdhesiveTypeId = 'AdhesiveTypeId',
  /** column name */
  ComponentId = 'ComponentId'
}

/** aggregate var_pop on columns */
export type AdhesiveSpots_Var_Pop_Fields = {
  __typename?: 'AdhesiveSpots_var_pop_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "AdhesiveSpots" */
export type AdhesiveSpots_Var_Pop_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type AdhesiveSpots_Var_Samp_Fields = {
  __typename?: 'AdhesiveSpots_var_samp_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "AdhesiveSpots" */
export type AdhesiveSpots_Var_Samp_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type AdhesiveSpots_Variance_Fields = {
  __typename?: 'AdhesiveSpots_variance_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "AdhesiveSpots" */
export type AdhesiveSpots_Variance_Order_By = {
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "AdhesiveSystemUsage" */
export type AdhesiveSystemUsage = {
  __typename?: 'AdhesiveSystemUsage';
  OverSubstrateRateDry: Scalars['MeasurementValue'];
  OverSubstrateRateWet: Scalars['MeasurementValue'];
  SubstratePrimerRateDry: Scalars['MeasurementValue'];
  SubstratePrimerRateWet: Scalars['MeasurementValue'];
  UndersideRateDry: Scalars['MeasurementValue'];
  UndersideRateWet: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "AdhesiveSystemUsage" */
export type AdhesiveSystemUsage_Aggregate = {
  __typename?: 'AdhesiveSystemUsage_aggregate';
  aggregate?: Maybe<AdhesiveSystemUsage_Aggregate_Fields>;
  nodes: Array<AdhesiveSystemUsage>;
};

/** aggregate fields of "AdhesiveSystemUsage" */
export type AdhesiveSystemUsage_Aggregate_Fields = {
  __typename?: 'AdhesiveSystemUsage_aggregate_fields';
  avg?: Maybe<AdhesiveSystemUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AdhesiveSystemUsage_Max_Fields>;
  min?: Maybe<AdhesiveSystemUsage_Min_Fields>;
  stddev?: Maybe<AdhesiveSystemUsage_Stddev_Fields>;
  stddev_pop?: Maybe<AdhesiveSystemUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AdhesiveSystemUsage_Stddev_Samp_Fields>;
  sum?: Maybe<AdhesiveSystemUsage_Sum_Fields>;
  var_pop?: Maybe<AdhesiveSystemUsage_Var_Pop_Fields>;
  var_samp?: Maybe<AdhesiveSystemUsage_Var_Samp_Fields>;
  variance?: Maybe<AdhesiveSystemUsage_Variance_Fields>;
};


/** aggregate fields of "AdhesiveSystemUsage" */
export type AdhesiveSystemUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<AdhesiveSystemUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AdhesiveSystemUsage_Avg_Fields = {
  __typename?: 'AdhesiveSystemUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "AdhesiveSystemUsage". All fields are combined with a logical 'AND'. */
export type AdhesiveSystemUsage_Bool_Exp = {
  OverSubstrateRateDry?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  OverSubstrateRateWet?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  SubstratePrimerRateDry?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  SubstratePrimerRateWet?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  UndersideRateDry?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  UndersideRateWet?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<AdhesiveSystemUsage_Bool_Exp>>;
  _not?: Maybe<AdhesiveSystemUsage_Bool_Exp>;
  _or?: Maybe<Array<AdhesiveSystemUsage_Bool_Exp>>;
};

/** upsert condition type for table "AdhesiveSystemUsage" */
export type AdhesiveSystemUsage_If_Matched = {
  match_columns?: Array<AdhesiveSystemUsage_Insert_Match_Column>;
  update_columns?: Array<AdhesiveSystemUsage_Update_Column>;
  where?: Maybe<AdhesiveSystemUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "AdhesiveSystemUsage" */
export type AdhesiveSystemUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "AdhesiveSystemUsage" */
export type AdhesiveSystemUsage_Insert_Input = {
  OverSubstrateRateDry?: Maybe<Scalars['MeasurementValue']>;
  OverSubstrateRateWet?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateDry?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateWet?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateDry?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateWet?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "AdhesiveSystemUsage" */
export enum AdhesiveSystemUsage_Insert_Match_Column {
  /** column name */
  OverSubstrateRateDry = 'OverSubstrateRateDry',
  /** column name */
  OverSubstrateRateWet = 'OverSubstrateRateWet',
  /** column name */
  SubstratePrimerRateDry = 'SubstratePrimerRateDry',
  /** column name */
  SubstratePrimerRateWet = 'SubstratePrimerRateWet',
  /** column name */
  UndersideRateDry = 'UndersideRateDry',
  /** column name */
  UndersideRateWet = 'UndersideRateWet',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type AdhesiveSystemUsage_Max_Fields = {
  __typename?: 'AdhesiveSystemUsage_max_fields';
  OverSubstrateRateDry?: Maybe<Scalars['MeasurementValue']>;
  OverSubstrateRateWet?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateDry?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateWet?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateDry?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateWet?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type AdhesiveSystemUsage_Min_Fields = {
  __typename?: 'AdhesiveSystemUsage_min_fields';
  OverSubstrateRateDry?: Maybe<Scalars['MeasurementValue']>;
  OverSubstrateRateWet?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateDry?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateWet?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateDry?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateWet?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "AdhesiveSystemUsage" */
export type AdhesiveSystemUsage_Mutation_Response = {
  __typename?: 'AdhesiveSystemUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AdhesiveSystemUsage>;
};

/** Ordering options when selecting data from "AdhesiveSystemUsage". */
export type AdhesiveSystemUsage_Order_By = {
  OverSubstrateRateDry?: Maybe<Order_By>;
  OverSubstrateRateWet?: Maybe<Order_By>;
  SubstratePrimerRateDry?: Maybe<Order_By>;
  SubstratePrimerRateWet?: Maybe<Order_By>;
  UndersideRateDry?: Maybe<Order_By>;
  UndersideRateWet?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: AdhesiveSystemUsage */
export type AdhesiveSystemUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "AdhesiveSystemUsage" */
export enum AdhesiveSystemUsage_Select_Column {
  /** column name */
  OverSubstrateRateDry = 'OverSubstrateRateDry',
  /** column name */
  OverSubstrateRateWet = 'OverSubstrateRateWet',
  /** column name */
  SubstratePrimerRateDry = 'SubstratePrimerRateDry',
  /** column name */
  SubstratePrimerRateWet = 'SubstratePrimerRateWet',
  /** column name */
  UndersideRateDry = 'UndersideRateDry',
  /** column name */
  UndersideRateWet = 'UndersideRateWet',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "AdhesiveSystemUsage" */
export type AdhesiveSystemUsage_Set_Input = {
  OverSubstrateRateDry?: Maybe<Scalars['MeasurementValue']>;
  OverSubstrateRateWet?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateDry?: Maybe<Scalars['MeasurementValue']>;
  SubstratePrimerRateWet?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateDry?: Maybe<Scalars['MeasurementValue']>;
  UndersideRateWet?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AdhesiveSystemUsage_Stddev_Fields = {
  __typename?: 'AdhesiveSystemUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AdhesiveSystemUsage_Stddev_Pop_Fields = {
  __typename?: 'AdhesiveSystemUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AdhesiveSystemUsage_Stddev_Samp_Fields = {
  __typename?: 'AdhesiveSystemUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AdhesiveSystemUsage_Sum_Fields = {
  __typename?: 'AdhesiveSystemUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "AdhesiveSystemUsage" */
export enum AdhesiveSystemUsage_Update_Column {
  /** column name */
  OverSubstrateRateDry = 'OverSubstrateRateDry',
  /** column name */
  OverSubstrateRateWet = 'OverSubstrateRateWet',
  /** column name */
  SubstratePrimerRateDry = 'SubstratePrimerRateDry',
  /** column name */
  SubstratePrimerRateWet = 'SubstratePrimerRateWet',
  /** column name */
  UndersideRateDry = 'UndersideRateDry',
  /** column name */
  UndersideRateWet = 'UndersideRateWet',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type AdhesiveSystemUsage_Var_Pop_Fields = {
  __typename?: 'AdhesiveSystemUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AdhesiveSystemUsage_Var_Samp_Fields = {
  __typename?: 'AdhesiveSystemUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AdhesiveSystemUsage_Variance_Fields = {
  __typename?: 'AdhesiveSystemUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "AirRetarder" */
export type AirRetarder = {
  __typename?: 'AirRetarder';
  /** An object relationship */
  C_RetarderMaterial: C_RetarderMaterial;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  RetarderMaterialId: Scalars['Int'];
};

/** columns and relationships of "AirRetarderUsage" */
export type AirRetarderUsage = {
  __typename?: 'AirRetarderUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "AirRetarderUsage" */
export type AirRetarderUsage_Aggregate = {
  __typename?: 'AirRetarderUsage_aggregate';
  aggregate?: Maybe<AirRetarderUsage_Aggregate_Fields>;
  nodes: Array<AirRetarderUsage>;
};

/** aggregate fields of "AirRetarderUsage" */
export type AirRetarderUsage_Aggregate_Fields = {
  __typename?: 'AirRetarderUsage_aggregate_fields';
  avg?: Maybe<AirRetarderUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AirRetarderUsage_Max_Fields>;
  min?: Maybe<AirRetarderUsage_Min_Fields>;
  stddev?: Maybe<AirRetarderUsage_Stddev_Fields>;
  stddev_pop?: Maybe<AirRetarderUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AirRetarderUsage_Stddev_Samp_Fields>;
  sum?: Maybe<AirRetarderUsage_Sum_Fields>;
  var_pop?: Maybe<AirRetarderUsage_Var_Pop_Fields>;
  var_samp?: Maybe<AirRetarderUsage_Var_Samp_Fields>;
  variance?: Maybe<AirRetarderUsage_Variance_Fields>;
};


/** aggregate fields of "AirRetarderUsage" */
export type AirRetarderUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<AirRetarderUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AirRetarderUsage_Avg_Fields = {
  __typename?: 'AirRetarderUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "AirRetarderUsage". All fields are combined with a logical 'AND'. */
export type AirRetarderUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<AirRetarderUsage_Bool_Exp>>;
  _not?: Maybe<AirRetarderUsage_Bool_Exp>;
  _or?: Maybe<Array<AirRetarderUsage_Bool_Exp>>;
};

/** upsert condition type for table "AirRetarderUsage" */
export type AirRetarderUsage_If_Matched = {
  match_columns?: Array<AirRetarderUsage_Insert_Match_Column>;
  update_columns?: Array<AirRetarderUsage_Update_Column>;
  where?: Maybe<AirRetarderUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "AirRetarderUsage" */
export type AirRetarderUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "AirRetarderUsage" */
export type AirRetarderUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "AirRetarderUsage" */
export enum AirRetarderUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type AirRetarderUsage_Max_Fields = {
  __typename?: 'AirRetarderUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type AirRetarderUsage_Min_Fields = {
  __typename?: 'AirRetarderUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "AirRetarderUsage" */
export type AirRetarderUsage_Mutation_Response = {
  __typename?: 'AirRetarderUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AirRetarderUsage>;
};

/** Ordering options when selecting data from "AirRetarderUsage". */
export type AirRetarderUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: AirRetarderUsage */
export type AirRetarderUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "AirRetarderUsage" */
export enum AirRetarderUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "AirRetarderUsage" */
export type AirRetarderUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AirRetarderUsage_Stddev_Fields = {
  __typename?: 'AirRetarderUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AirRetarderUsage_Stddev_Pop_Fields = {
  __typename?: 'AirRetarderUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AirRetarderUsage_Stddev_Samp_Fields = {
  __typename?: 'AirRetarderUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AirRetarderUsage_Sum_Fields = {
  __typename?: 'AirRetarderUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "AirRetarderUsage" */
export enum AirRetarderUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type AirRetarderUsage_Var_Pop_Fields = {
  __typename?: 'AirRetarderUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AirRetarderUsage_Var_Samp_Fields = {
  __typename?: 'AirRetarderUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AirRetarderUsage_Variance_Fields = {
  __typename?: 'AirRetarderUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "AirRetarder" */
export type AirRetarder_Aggregate = {
  __typename?: 'AirRetarder_aggregate';
  aggregate?: Maybe<AirRetarder_Aggregate_Fields>;
  nodes: Array<AirRetarder>;
};

/** aggregate fields of "AirRetarder" */
export type AirRetarder_Aggregate_Fields = {
  __typename?: 'AirRetarder_aggregate_fields';
  avg?: Maybe<AirRetarder_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AirRetarder_Max_Fields>;
  min?: Maybe<AirRetarder_Min_Fields>;
  stddev?: Maybe<AirRetarder_Stddev_Fields>;
  stddev_pop?: Maybe<AirRetarder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AirRetarder_Stddev_Samp_Fields>;
  sum?: Maybe<AirRetarder_Sum_Fields>;
  var_pop?: Maybe<AirRetarder_Var_Pop_Fields>;
  var_samp?: Maybe<AirRetarder_Var_Samp_Fields>;
  variance?: Maybe<AirRetarder_Variance_Fields>;
};


/** aggregate fields of "AirRetarder" */
export type AirRetarder_Aggregate_FieldsCountArgs = {
  column?: Maybe<AirRetarder_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "AirRetarder" */
export type AirRetarder_Aggregate_Order_By = {
  avg?: Maybe<AirRetarder_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<AirRetarder_Max_Order_By>;
  min?: Maybe<AirRetarder_Min_Order_By>;
  stddev?: Maybe<AirRetarder_Stddev_Order_By>;
  stddev_pop?: Maybe<AirRetarder_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<AirRetarder_Stddev_Samp_Order_By>;
  sum?: Maybe<AirRetarder_Sum_Order_By>;
  var_pop?: Maybe<AirRetarder_Var_Pop_Order_By>;
  var_samp?: Maybe<AirRetarder_Var_Samp_Order_By>;
  variance?: Maybe<AirRetarder_Variance_Order_By>;
};

/** aggregate avg on columns */
export type AirRetarder_Avg_Fields = {
  __typename?: 'AirRetarder_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "AirRetarder" */
export type AirRetarder_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "AirRetarder". All fields are combined with a logical 'AND'. */
export type AirRetarder_Bool_Exp = {
  C_RetarderMaterial?: Maybe<C_RetarderMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  RetarderMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<AirRetarder_Bool_Exp>>;
  _not?: Maybe<AirRetarder_Bool_Exp>;
  _or?: Maybe<Array<AirRetarder_Bool_Exp>>;
};

/** upsert condition type for table "AirRetarder" */
export type AirRetarder_If_Matched = {
  match_columns?: Array<AirRetarder_Insert_Match_Column>;
  update_columns?: Array<AirRetarder_Update_Column>;
  where?: Maybe<AirRetarder_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "AirRetarder" */
export type AirRetarder_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "AirRetarder" */
export type AirRetarder_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "AirRetarder" */
export enum AirRetarder_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId'
}

/** aggregate max on columns */
export type AirRetarder_Max_Fields = {
  __typename?: 'AirRetarder_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "AirRetarder" */
export type AirRetarder_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type AirRetarder_Min_Fields = {
  __typename?: 'AirRetarder_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "AirRetarder" */
export type AirRetarder_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** response of any mutation on the table "AirRetarder" */
export type AirRetarder_Mutation_Response = {
  __typename?: 'AirRetarder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AirRetarder>;
};

/** Ordering options when selecting data from "AirRetarder". */
export type AirRetarder_Order_By = {
  C_RetarderMaterial?: Maybe<C_RetarderMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** primary key columns input for table: AirRetarder */
export type AirRetarder_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "AirRetarder" */
export enum AirRetarder_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId'
}

/** input type for updating data in table "AirRetarder" */
export type AirRetarder_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AirRetarder_Stddev_Fields = {
  __typename?: 'AirRetarder_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "AirRetarder" */
export type AirRetarder_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type AirRetarder_Stddev_Pop_Fields = {
  __typename?: 'AirRetarder_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "AirRetarder" */
export type AirRetarder_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type AirRetarder_Stddev_Samp_Fields = {
  __typename?: 'AirRetarder_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "AirRetarder" */
export type AirRetarder_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type AirRetarder_Sum_Fields = {
  __typename?: 'AirRetarder_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "AirRetarder" */
export type AirRetarder_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** update columns of table "AirRetarder" */
export enum AirRetarder_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId'
}

/** aggregate var_pop on columns */
export type AirRetarder_Var_Pop_Fields = {
  __typename?: 'AirRetarder_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "AirRetarder" */
export type AirRetarder_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type AirRetarder_Var_Samp_Fields = {
  __typename?: 'AirRetarder_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "AirRetarder" */
export type AirRetarder_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type AirRetarder_Variance_Fields = {
  __typename?: 'AirRetarder_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "AirRetarder" */
export type AirRetarder_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "AllManufacturedProducts" */
export type AllManufacturedProducts = {
  __typename?: 'AllManufacturedProducts';
  ApprovedUseCode: Scalars['CodeTableValue'];
  ApprovedUseId: Scalars['Int'];
  ApprovedUseTableName: Scalars['String'];
  ComponentId: Scalars['Int'];
  ComponentStatusId: Scalars['Int'];
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified: Scalars['datetime'];
  LockStatusId: Scalars['Int'];
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  ManufacturerName: Scalars['String'];
  ModifiedByUserAccountId: Scalars['Int'];
  StatusId: Scalars['Int'];
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "AllManufacturedProducts" */
export type AllManufacturedProducts_Aggregate = {
  __typename?: 'AllManufacturedProducts_aggregate';
  aggregate?: Maybe<AllManufacturedProducts_Aggregate_Fields>;
  nodes: Array<AllManufacturedProducts>;
};

/** aggregate fields of "AllManufacturedProducts" */
export type AllManufacturedProducts_Aggregate_Fields = {
  __typename?: 'AllManufacturedProducts_aggregate_fields';
  avg?: Maybe<AllManufacturedProducts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AllManufacturedProducts_Max_Fields>;
  min?: Maybe<AllManufacturedProducts_Min_Fields>;
  stddev?: Maybe<AllManufacturedProducts_Stddev_Fields>;
  stddev_pop?: Maybe<AllManufacturedProducts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AllManufacturedProducts_Stddev_Samp_Fields>;
  sum?: Maybe<AllManufacturedProducts_Sum_Fields>;
  var_pop?: Maybe<AllManufacturedProducts_Var_Pop_Fields>;
  var_samp?: Maybe<AllManufacturedProducts_Var_Samp_Fields>;
  variance?: Maybe<AllManufacturedProducts_Variance_Fields>;
};


/** aggregate fields of "AllManufacturedProducts" */
export type AllManufacturedProducts_Aggregate_FieldsCountArgs = {
  column?: Maybe<AllManufacturedProducts_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AllManufacturedProducts_Avg_Fields = {
  __typename?: 'AllManufacturedProducts_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "AllManufacturedProducts". All fields are combined with a logical 'AND'. */
export type AllManufacturedProducts_Bool_Exp = {
  ApprovedUseCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  ApprovedUseTableName?: Maybe<String_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateLocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerName?: Maybe<String_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<AllManufacturedProducts_Bool_Exp>>;
  _not?: Maybe<AllManufacturedProducts_Bool_Exp>;
  _or?: Maybe<Array<AllManufacturedProducts_Bool_Exp>>;
};

/** upsert condition type for table "AllManufacturedProducts" */
export type AllManufacturedProducts_If_Matched = {
  match_columns?: Array<AllManufacturedProducts_Insert_Match_Column>;
  update_columns?: Array<AllManufacturedProducts_Update_Column>;
  where?: Maybe<AllManufacturedProducts_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "AllManufacturedProducts" */
export type AllManufacturedProducts_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "AllManufacturedProducts" */
export type AllManufacturedProducts_Insert_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "AllManufacturedProducts" */
export enum AllManufacturedProducts_Insert_Match_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate max on columns */
export type AllManufacturedProducts_Max_Fields = {
  __typename?: 'AllManufacturedProducts_max_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AllManufacturedProducts_Min_Fields = {
  __typename?: 'AllManufacturedProducts_min_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "AllManufacturedProducts" */
export type AllManufacturedProducts_Mutation_Response = {
  __typename?: 'AllManufacturedProducts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AllManufacturedProducts>;
};

/** Ordering options when selecting data from "AllManufacturedProducts". */
export type AllManufacturedProducts_Order_By = {
  ApprovedUseCode?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  ApprovedUseTableName?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ComponentStatusId?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ManufacturerName?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
};

/** select columns of table "AllManufacturedProducts" */
export enum AllManufacturedProducts_Select_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  TradeName = 'TradeName'
}

/** input type for updating data in table "AllManufacturedProducts" */
export type AllManufacturedProducts_Set_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type AllManufacturedProducts_Stddev_Fields = {
  __typename?: 'AllManufacturedProducts_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AllManufacturedProducts_Stddev_Pop_Fields = {
  __typename?: 'AllManufacturedProducts_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AllManufacturedProducts_Stddev_Samp_Fields = {
  __typename?: 'AllManufacturedProducts_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AllManufacturedProducts_Sum_Fields = {
  __typename?: 'AllManufacturedProducts_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "AllManufacturedProducts" */
export enum AllManufacturedProducts_Update_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate var_pop on columns */
export type AllManufacturedProducts_Var_Pop_Fields = {
  __typename?: 'AllManufacturedProducts_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AllManufacturedProducts_Var_Samp_Fields = {
  __typename?: 'AllManufacturedProducts_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AllManufacturedProducts_Variance_Fields = {
  __typename?: 'AllManufacturedProducts_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Anchor" */
export type Anchor = {
  __typename?: 'Anchor';
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  ShearLoadMax: Scalars['MeasurementValue'];
  VerticalLoadMax: Scalars['MeasurementValue'];
};

/** aggregated selection of "Anchor" */
export type Anchor_Aggregate = {
  __typename?: 'Anchor_aggregate';
  aggregate?: Maybe<Anchor_Aggregate_Fields>;
  nodes: Array<Anchor>;
};

/** aggregate fields of "Anchor" */
export type Anchor_Aggregate_Fields = {
  __typename?: 'Anchor_aggregate_fields';
  avg?: Maybe<Anchor_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Anchor_Max_Fields>;
  min?: Maybe<Anchor_Min_Fields>;
  stddev?: Maybe<Anchor_Stddev_Fields>;
  stddev_pop?: Maybe<Anchor_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Anchor_Stddev_Samp_Fields>;
  sum?: Maybe<Anchor_Sum_Fields>;
  var_pop?: Maybe<Anchor_Var_Pop_Fields>;
  var_samp?: Maybe<Anchor_Var_Samp_Fields>;
  variance?: Maybe<Anchor_Variance_Fields>;
};


/** aggregate fields of "Anchor" */
export type Anchor_Aggregate_FieldsCountArgs = {
  column?: Maybe<Anchor_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Anchor_Avg_Fields = {
  __typename?: 'Anchor_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Anchor". All fields are combined with a logical 'AND'. */
export type Anchor_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  ShearLoadMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  VerticalLoadMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Anchor_Bool_Exp>>;
  _not?: Maybe<Anchor_Bool_Exp>;
  _or?: Maybe<Array<Anchor_Bool_Exp>>;
};

/** upsert condition type for table "Anchor" */
export type Anchor_If_Matched = {
  match_columns?: Array<Anchor_Insert_Match_Column>;
  update_columns?: Array<Anchor_Update_Column>;
  where?: Maybe<Anchor_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Anchor" */
export type Anchor_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Anchor" */
export type Anchor_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ShearLoadMax?: Maybe<Scalars['MeasurementValue']>;
  VerticalLoadMax?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "Anchor" */
export enum Anchor_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ShearLoadMax = 'ShearLoadMax',
  /** column name */
  VerticalLoadMax = 'VerticalLoadMax'
}

/** aggregate max on columns */
export type Anchor_Max_Fields = {
  __typename?: 'Anchor_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ShearLoadMax?: Maybe<Scalars['MeasurementValue']>;
  VerticalLoadMax?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate min on columns */
export type Anchor_Min_Fields = {
  __typename?: 'Anchor_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ShearLoadMax?: Maybe<Scalars['MeasurementValue']>;
  VerticalLoadMax?: Maybe<Scalars['MeasurementValue']>;
};

/** response of any mutation on the table "Anchor" */
export type Anchor_Mutation_Response = {
  __typename?: 'Anchor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Anchor>;
};

/** Ordering options when selecting data from "Anchor". */
export type Anchor_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  ShearLoadMax?: Maybe<Order_By>;
  VerticalLoadMax?: Maybe<Order_By>;
};

/** primary key columns input for table: Anchor */
export type Anchor_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "Anchor" */
export enum Anchor_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ShearLoadMax = 'ShearLoadMax',
  /** column name */
  VerticalLoadMax = 'VerticalLoadMax'
}

/** input type for updating data in table "Anchor" */
export type Anchor_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ShearLoadMax?: Maybe<Scalars['MeasurementValue']>;
  VerticalLoadMax?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type Anchor_Stddev_Fields = {
  __typename?: 'Anchor_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Anchor_Stddev_Pop_Fields = {
  __typename?: 'Anchor_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Anchor_Stddev_Samp_Fields = {
  __typename?: 'Anchor_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Anchor_Sum_Fields = {
  __typename?: 'Anchor_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
};

/** update columns of table "Anchor" */
export enum Anchor_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ShearLoadMax = 'ShearLoadMax',
  /** column name */
  VerticalLoadMax = 'VerticalLoadMax'
}

/** aggregate var_pop on columns */
export type Anchor_Var_Pop_Fields = {
  __typename?: 'Anchor_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Anchor_Var_Samp_Fields = {
  __typename?: 'Anchor_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Anchor_Variance_Fields = {
  __typename?: 'Anchor_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ApprovalsDoc" */
export type ApprovalsDoc = {
  __typename?: 'ApprovalsDoc';
  ApprovalsDocCategoryId: Scalars['Int'];
  ApprovalsDocId: Scalars['Int'];
  /** An object relationship */
  C_ApprovalsDocCategory: C_ApprovalsDocCategory;
  DateAdded: Scalars['datetime'];
  DateIssued?: Maybe<Scalars['datetime']>;
  DateModified: Scalars['datetime'];
  DocData?: Maybe<Scalars['varbinary']>;
  DocName: Scalars['String'];
  Dsc: Scalars['CodeTableDescription'];
};

/** aggregated selection of "ApprovalsDoc" */
export type ApprovalsDoc_Aggregate = {
  __typename?: 'ApprovalsDoc_aggregate';
  aggregate?: Maybe<ApprovalsDoc_Aggregate_Fields>;
  nodes: Array<ApprovalsDoc>;
};

/** aggregate fields of "ApprovalsDoc" */
export type ApprovalsDoc_Aggregate_Fields = {
  __typename?: 'ApprovalsDoc_aggregate_fields';
  avg?: Maybe<ApprovalsDoc_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ApprovalsDoc_Max_Fields>;
  min?: Maybe<ApprovalsDoc_Min_Fields>;
  stddev?: Maybe<ApprovalsDoc_Stddev_Fields>;
  stddev_pop?: Maybe<ApprovalsDoc_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ApprovalsDoc_Stddev_Samp_Fields>;
  sum?: Maybe<ApprovalsDoc_Sum_Fields>;
  var_pop?: Maybe<ApprovalsDoc_Var_Pop_Fields>;
  var_samp?: Maybe<ApprovalsDoc_Var_Samp_Fields>;
  variance?: Maybe<ApprovalsDoc_Variance_Fields>;
};


/** aggregate fields of "ApprovalsDoc" */
export type ApprovalsDoc_Aggregate_FieldsCountArgs = {
  column?: Maybe<ApprovalsDoc_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ApprovalsDoc" */
export type ApprovalsDoc_Aggregate_Order_By = {
  avg?: Maybe<ApprovalsDoc_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ApprovalsDoc_Max_Order_By>;
  min?: Maybe<ApprovalsDoc_Min_Order_By>;
  stddev?: Maybe<ApprovalsDoc_Stddev_Order_By>;
  stddev_pop?: Maybe<ApprovalsDoc_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ApprovalsDoc_Stddev_Samp_Order_By>;
  sum?: Maybe<ApprovalsDoc_Sum_Order_By>;
  var_pop?: Maybe<ApprovalsDoc_Var_Pop_Order_By>;
  var_samp?: Maybe<ApprovalsDoc_Var_Samp_Order_By>;
  variance?: Maybe<ApprovalsDoc_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ApprovalsDoc_Avg_Fields = {
  __typename?: 'ApprovalsDoc_avg_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Float']>;
  ApprovalsDocId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ApprovalsDoc" */
export type ApprovalsDoc_Avg_Order_By = {
  ApprovalsDocCategoryId?: Maybe<Order_By>;
  ApprovalsDocId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ApprovalsDoc". All fields are combined with a logical 'AND'. */
export type ApprovalsDoc_Bool_Exp = {
  ApprovalsDocCategoryId?: Maybe<Int_Mssql_Comparison_Exp>;
  ApprovalsDocId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_ApprovalsDocCategory?: Maybe<C_ApprovalsDocCategory_Bool_Exp>;
  DateAdded?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateIssued?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DocData?: Maybe<Varbinary_Mssql_Comparison_Exp>;
  DocName?: Maybe<String_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ApprovalsDoc_Bool_Exp>>;
  _not?: Maybe<ApprovalsDoc_Bool_Exp>;
  _or?: Maybe<Array<ApprovalsDoc_Bool_Exp>>;
};

/** upsert condition type for table "ApprovalsDoc" */
export type ApprovalsDoc_If_Matched = {
  match_columns?: Array<ApprovalsDoc_Insert_Match_Column>;
  update_columns?: Array<ApprovalsDoc_Update_Column>;
  where?: Maybe<ApprovalsDoc_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ApprovalsDoc" */
export type ApprovalsDoc_Inc_Input = {
  ApprovalsDocCategoryId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ApprovalsDoc" */
export type ApprovalsDoc_Insert_Input = {
  ApprovalsDocCategoryId?: Maybe<Scalars['Int']>;
  DateAdded?: Maybe<Scalars['datetime']>;
  DateIssued?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DocData?: Maybe<Scalars['varbinary']>;
  DocName?: Maybe<Scalars['String']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "ApprovalsDoc" */
export enum ApprovalsDoc_Insert_Match_Column {
  /** column name */
  ApprovalsDocCategoryId = 'ApprovalsDocCategoryId',
  /** column name */
  ApprovalsDocId = 'ApprovalsDocId',
  /** column name */
  DateAdded = 'DateAdded',
  /** column name */
  DateIssued = 'DateIssued',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  DocData = 'DocData',
  /** column name */
  DocName = 'DocName',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type ApprovalsDoc_Max_Fields = {
  __typename?: 'ApprovalsDoc_max_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Int']>;
  ApprovalsDocId?: Maybe<Scalars['Int']>;
  DateAdded?: Maybe<Scalars['datetime']>;
  DateIssued?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DocName?: Maybe<Scalars['String']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** order by max() on columns of table "ApprovalsDoc" */
export type ApprovalsDoc_Max_Order_By = {
  ApprovalsDocCategoryId?: Maybe<Order_By>;
  ApprovalsDocId?: Maybe<Order_By>;
  DateAdded?: Maybe<Order_By>;
  DateIssued?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  DocName?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ApprovalsDoc_Min_Fields = {
  __typename?: 'ApprovalsDoc_min_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Int']>;
  ApprovalsDocId?: Maybe<Scalars['Int']>;
  DateAdded?: Maybe<Scalars['datetime']>;
  DateIssued?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DocName?: Maybe<Scalars['String']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** order by min() on columns of table "ApprovalsDoc" */
export type ApprovalsDoc_Min_Order_By = {
  ApprovalsDocCategoryId?: Maybe<Order_By>;
  ApprovalsDocId?: Maybe<Order_By>;
  DateAdded?: Maybe<Order_By>;
  DateIssued?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  DocName?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** response of any mutation on the table "ApprovalsDoc" */
export type ApprovalsDoc_Mutation_Response = {
  __typename?: 'ApprovalsDoc_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ApprovalsDoc>;
};

/** Ordering options when selecting data from "ApprovalsDoc". */
export type ApprovalsDoc_Order_By = {
  ApprovalsDocCategoryId?: Maybe<Order_By>;
  ApprovalsDocId?: Maybe<Order_By>;
  C_ApprovalsDocCategory?: Maybe<C_ApprovalsDocCategory_Order_By>;
  DateAdded?: Maybe<Order_By>;
  DateIssued?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  DocData?: Maybe<Order_By>;
  DocName?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** primary key columns input for table: ApprovalsDoc */
export type ApprovalsDoc_Pk_Columns_Input = {
  ApprovalsDocId: Scalars['Int'];
};

/** select columns of table "ApprovalsDoc" */
export enum ApprovalsDoc_Select_Column {
  /** column name */
  ApprovalsDocCategoryId = 'ApprovalsDocCategoryId',
  /** column name */
  ApprovalsDocId = 'ApprovalsDocId',
  /** column name */
  DateAdded = 'DateAdded',
  /** column name */
  DateIssued = 'DateIssued',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  DocData = 'DocData',
  /** column name */
  DocName = 'DocName',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "ApprovalsDoc" */
export type ApprovalsDoc_Set_Input = {
  ApprovalsDocCategoryId?: Maybe<Scalars['Int']>;
  DateAdded?: Maybe<Scalars['datetime']>;
  DateIssued?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DocData?: Maybe<Scalars['varbinary']>;
  DocName?: Maybe<Scalars['String']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type ApprovalsDoc_Stddev_Fields = {
  __typename?: 'ApprovalsDoc_stddev_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Float']>;
  ApprovalsDocId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ApprovalsDoc" */
export type ApprovalsDoc_Stddev_Order_By = {
  ApprovalsDocCategoryId?: Maybe<Order_By>;
  ApprovalsDocId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ApprovalsDoc_Stddev_Pop_Fields = {
  __typename?: 'ApprovalsDoc_stddev_pop_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Float']>;
  ApprovalsDocId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ApprovalsDoc" */
export type ApprovalsDoc_Stddev_Pop_Order_By = {
  ApprovalsDocCategoryId?: Maybe<Order_By>;
  ApprovalsDocId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ApprovalsDoc_Stddev_Samp_Fields = {
  __typename?: 'ApprovalsDoc_stddev_samp_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Float']>;
  ApprovalsDocId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ApprovalsDoc" */
export type ApprovalsDoc_Stddev_Samp_Order_By = {
  ApprovalsDocCategoryId?: Maybe<Order_By>;
  ApprovalsDocId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ApprovalsDoc_Sum_Fields = {
  __typename?: 'ApprovalsDoc_sum_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Int']>;
  ApprovalsDocId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ApprovalsDoc" */
export type ApprovalsDoc_Sum_Order_By = {
  ApprovalsDocCategoryId?: Maybe<Order_By>;
  ApprovalsDocId?: Maybe<Order_By>;
};

/** update columns of table "ApprovalsDoc" */
export enum ApprovalsDoc_Update_Column {
  /** column name */
  ApprovalsDocCategoryId = 'ApprovalsDocCategoryId',
  /** column name */
  DateAdded = 'DateAdded',
  /** column name */
  DateIssued = 'DateIssued',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  DocData = 'DocData',
  /** column name */
  DocName = 'DocName',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type ApprovalsDoc_Var_Pop_Fields = {
  __typename?: 'ApprovalsDoc_var_pop_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Float']>;
  ApprovalsDocId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ApprovalsDoc" */
export type ApprovalsDoc_Var_Pop_Order_By = {
  ApprovalsDocCategoryId?: Maybe<Order_By>;
  ApprovalsDocId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ApprovalsDoc_Var_Samp_Fields = {
  __typename?: 'ApprovalsDoc_var_samp_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Float']>;
  ApprovalsDocId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ApprovalsDoc" */
export type ApprovalsDoc_Var_Samp_Order_By = {
  ApprovalsDocCategoryId?: Maybe<Order_By>;
  ApprovalsDocId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ApprovalsDoc_Variance_Fields = {
  __typename?: 'ApprovalsDoc_variance_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Float']>;
  ApprovalsDocId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ApprovalsDoc" */
export type ApprovalsDoc_Variance_Order_By = {
  ApprovalsDocCategoryId?: Maybe<Order_By>;
  ApprovalsDocId?: Maybe<Order_By>;
};

/** columns and relationships of "ApprovedLayerUse" */
export type ApprovedLayerUse = {
  __typename?: 'ApprovedLayerUse';
  ApprovedLayerUseId: Scalars['Int'];
  ApprovedUseId: Scalars['Int'];
  /** An object relationship */
  C_ApprovedUse: C_ApprovedUse;
  /** An object relationship */
  C_LayerType: C_LayerType;
  IsSecurement: Scalars['Boolean'];
  LayerTypeId: Scalars['Int'];
};

/** aggregated selection of "ApprovedLayerUse" */
export type ApprovedLayerUse_Aggregate = {
  __typename?: 'ApprovedLayerUse_aggregate';
  aggregate?: Maybe<ApprovedLayerUse_Aggregate_Fields>;
  nodes: Array<ApprovedLayerUse>;
};

/** aggregate fields of "ApprovedLayerUse" */
export type ApprovedLayerUse_Aggregate_Fields = {
  __typename?: 'ApprovedLayerUse_aggregate_fields';
  avg?: Maybe<ApprovedLayerUse_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ApprovedLayerUse_Max_Fields>;
  min?: Maybe<ApprovedLayerUse_Min_Fields>;
  stddev?: Maybe<ApprovedLayerUse_Stddev_Fields>;
  stddev_pop?: Maybe<ApprovedLayerUse_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ApprovedLayerUse_Stddev_Samp_Fields>;
  sum?: Maybe<ApprovedLayerUse_Sum_Fields>;
  var_pop?: Maybe<ApprovedLayerUse_Var_Pop_Fields>;
  var_samp?: Maybe<ApprovedLayerUse_Var_Samp_Fields>;
  variance?: Maybe<ApprovedLayerUse_Variance_Fields>;
};


/** aggregate fields of "ApprovedLayerUse" */
export type ApprovedLayerUse_Aggregate_FieldsCountArgs = {
  column?: Maybe<ApprovedLayerUse_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ApprovedLayerUse" */
export type ApprovedLayerUse_Aggregate_Order_By = {
  avg?: Maybe<ApprovedLayerUse_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ApprovedLayerUse_Max_Order_By>;
  min?: Maybe<ApprovedLayerUse_Min_Order_By>;
  stddev?: Maybe<ApprovedLayerUse_Stddev_Order_By>;
  stddev_pop?: Maybe<ApprovedLayerUse_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ApprovedLayerUse_Stddev_Samp_Order_By>;
  sum?: Maybe<ApprovedLayerUse_Sum_Order_By>;
  var_pop?: Maybe<ApprovedLayerUse_Var_Pop_Order_By>;
  var_samp?: Maybe<ApprovedLayerUse_Var_Samp_Order_By>;
  variance?: Maybe<ApprovedLayerUse_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ApprovedLayerUse_Avg_Fields = {
  __typename?: 'ApprovedLayerUse_avg_fields';
  ApprovedLayerUseId?: Maybe<Scalars['Float']>;
  ApprovedUseId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ApprovedLayerUse" */
export type ApprovedLayerUse_Avg_Order_By = {
  ApprovedLayerUseId?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ApprovedLayerUse". All fields are combined with a logical 'AND'. */
export type ApprovedLayerUse_Bool_Exp = {
  ApprovedLayerUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_ApprovedUse?: Maybe<C_ApprovedUse_Bool_Exp>;
  C_LayerType?: Maybe<C_LayerType_Bool_Exp>;
  IsSecurement?: Maybe<Boolean_Mssql_Comparison_Exp>;
  LayerTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ApprovedLayerUse_Bool_Exp>>;
  _not?: Maybe<ApprovedLayerUse_Bool_Exp>;
  _or?: Maybe<Array<ApprovedLayerUse_Bool_Exp>>;
};

/** upsert condition type for table "ApprovedLayerUse" */
export type ApprovedLayerUse_If_Matched = {
  match_columns?: Array<ApprovedLayerUse_Insert_Match_Column>;
  update_columns?: Array<ApprovedLayerUse_Update_Column>;
  where?: Maybe<ApprovedLayerUse_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ApprovedLayerUse" */
export type ApprovedLayerUse_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ApprovedLayerUse" */
export type ApprovedLayerUse_Insert_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  IsSecurement?: Maybe<Scalars['Boolean']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ApprovedLayerUse" */
export enum ApprovedLayerUse_Insert_Match_Column {
  /** column name */
  ApprovedLayerUseId = 'ApprovedLayerUseId',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  IsSecurement = 'IsSecurement',
  /** column name */
  LayerTypeId = 'LayerTypeId'
}

/** aggregate max on columns */
export type ApprovedLayerUse_Max_Fields = {
  __typename?: 'ApprovedLayerUse_max_fields';
  ApprovedLayerUseId?: Maybe<Scalars['Int']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ApprovedLayerUse" */
export type ApprovedLayerUse_Max_Order_By = {
  ApprovedLayerUseId?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ApprovedLayerUse_Min_Fields = {
  __typename?: 'ApprovedLayerUse_min_fields';
  ApprovedLayerUseId?: Maybe<Scalars['Int']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ApprovedLayerUse" */
export type ApprovedLayerUse_Min_Order_By = {
  ApprovedLayerUseId?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ApprovedLayerUse" */
export type ApprovedLayerUse_Mutation_Response = {
  __typename?: 'ApprovedLayerUse_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ApprovedLayerUse>;
};

/** Ordering options when selecting data from "ApprovedLayerUse". */
export type ApprovedLayerUse_Order_By = {
  ApprovedLayerUseId?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  C_ApprovedUse?: Maybe<C_ApprovedUse_Order_By>;
  C_LayerType?: Maybe<C_LayerType_Order_By>;
  IsSecurement?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: ApprovedLayerUse */
export type ApprovedLayerUse_Pk_Columns_Input = {
  ApprovedLayerUseId: Scalars['Int'];
};

/** select columns of table "ApprovedLayerUse" */
export enum ApprovedLayerUse_Select_Column {
  /** column name */
  ApprovedLayerUseId = 'ApprovedLayerUseId',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  IsSecurement = 'IsSecurement',
  /** column name */
  LayerTypeId = 'LayerTypeId'
}

/** input type for updating data in table "ApprovedLayerUse" */
export type ApprovedLayerUse_Set_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  IsSecurement?: Maybe<Scalars['Boolean']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ApprovedLayerUse_Stddev_Fields = {
  __typename?: 'ApprovedLayerUse_stddev_fields';
  ApprovedLayerUseId?: Maybe<Scalars['Float']>;
  ApprovedUseId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ApprovedLayerUse" */
export type ApprovedLayerUse_Stddev_Order_By = {
  ApprovedLayerUseId?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ApprovedLayerUse_Stddev_Pop_Fields = {
  __typename?: 'ApprovedLayerUse_stddev_pop_fields';
  ApprovedLayerUseId?: Maybe<Scalars['Float']>;
  ApprovedUseId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ApprovedLayerUse" */
export type ApprovedLayerUse_Stddev_Pop_Order_By = {
  ApprovedLayerUseId?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ApprovedLayerUse_Stddev_Samp_Fields = {
  __typename?: 'ApprovedLayerUse_stddev_samp_fields';
  ApprovedLayerUseId?: Maybe<Scalars['Float']>;
  ApprovedUseId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ApprovedLayerUse" */
export type ApprovedLayerUse_Stddev_Samp_Order_By = {
  ApprovedLayerUseId?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ApprovedLayerUse_Sum_Fields = {
  __typename?: 'ApprovedLayerUse_sum_fields';
  ApprovedLayerUseId?: Maybe<Scalars['Int']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ApprovedLayerUse" */
export type ApprovedLayerUse_Sum_Order_By = {
  ApprovedLayerUseId?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
};

/** update columns of table "ApprovedLayerUse" */
export enum ApprovedLayerUse_Update_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  IsSecurement = 'IsSecurement',
  /** column name */
  LayerTypeId = 'LayerTypeId'
}

/** aggregate var_pop on columns */
export type ApprovedLayerUse_Var_Pop_Fields = {
  __typename?: 'ApprovedLayerUse_var_pop_fields';
  ApprovedLayerUseId?: Maybe<Scalars['Float']>;
  ApprovedUseId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ApprovedLayerUse" */
export type ApprovedLayerUse_Var_Pop_Order_By = {
  ApprovedLayerUseId?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ApprovedLayerUse_Var_Samp_Fields = {
  __typename?: 'ApprovedLayerUse_var_samp_fields';
  ApprovedLayerUseId?: Maybe<Scalars['Float']>;
  ApprovedUseId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ApprovedLayerUse" */
export type ApprovedLayerUse_Var_Samp_Order_By = {
  ApprovedLayerUseId?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ApprovedLayerUse_Variance_Fields = {
  __typename?: 'ApprovedLayerUse_variance_fields';
  ApprovedLayerUseId?: Maybe<Scalars['Float']>;
  ApprovedUseId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ApprovedLayerUse" */
export type ApprovedLayerUse_Variance_Order_By = {
  ApprovedLayerUseId?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
};

export type AssemblyPropertySearchCriteria = {
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IncludesPV?: Maybe<Scalars['Boolean']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  LayerConfig?: Maybe<Scalars['String']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyName?: Maybe<Scalars['String']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  PVWindUplift?: Maybe<Scalars['Float']>;
  PVWindUpliftOperator?: Maybe<Scalars['Int']>;
  Slope?: Maybe<Scalars['Float']>;
  SlopeOperator?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['Float']>;
  WindUpliftOperator?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "AssemblyReconfigQueue" */
export type AssemblyReconfigQueue = {
  __typename?: 'AssemblyReconfigQueue';
  AssemblyId: Scalars['Int'];
  AssemblyReconfigQueueId: Scalars['Int'];
  DateCompleted?: Maybe<Scalars['datetime']>;
  DateReconfigured: Scalars['datetime'];
  ErrorMsg?: Maybe<Scalars['String']>;
  IsCompleted: Scalars['Boolean'];
};

/** aggregated selection of "AssemblyReconfigQueue" */
export type AssemblyReconfigQueue_Aggregate = {
  __typename?: 'AssemblyReconfigQueue_aggregate';
  aggregate?: Maybe<AssemblyReconfigQueue_Aggregate_Fields>;
  nodes: Array<AssemblyReconfigQueue>;
};

/** aggregate fields of "AssemblyReconfigQueue" */
export type AssemblyReconfigQueue_Aggregate_Fields = {
  __typename?: 'AssemblyReconfigQueue_aggregate_fields';
  avg?: Maybe<AssemblyReconfigQueue_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<AssemblyReconfigQueue_Max_Fields>;
  min?: Maybe<AssemblyReconfigQueue_Min_Fields>;
  stddev?: Maybe<AssemblyReconfigQueue_Stddev_Fields>;
  stddev_pop?: Maybe<AssemblyReconfigQueue_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AssemblyReconfigQueue_Stddev_Samp_Fields>;
  sum?: Maybe<AssemblyReconfigQueue_Sum_Fields>;
  var_pop?: Maybe<AssemblyReconfigQueue_Var_Pop_Fields>;
  var_samp?: Maybe<AssemblyReconfigQueue_Var_Samp_Fields>;
  variance?: Maybe<AssemblyReconfigQueue_Variance_Fields>;
};


/** aggregate fields of "AssemblyReconfigQueue" */
export type AssemblyReconfigQueue_Aggregate_FieldsCountArgs = {
  column?: Maybe<AssemblyReconfigQueue_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AssemblyReconfigQueue_Avg_Fields = {
  __typename?: 'AssemblyReconfigQueue_avg_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyReconfigQueueId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "AssemblyReconfigQueue". All fields are combined with a logical 'AND'. */
export type AssemblyReconfigQueue_Bool_Exp = {
  AssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  AssemblyReconfigQueueId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateCompleted?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateReconfigured?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ErrorMsg?: Maybe<String_Mssql_Comparison_Exp>;
  IsCompleted?: Maybe<Boolean_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<AssemblyReconfigQueue_Bool_Exp>>;
  _not?: Maybe<AssemblyReconfigQueue_Bool_Exp>;
  _or?: Maybe<Array<AssemblyReconfigQueue_Bool_Exp>>;
};

/** upsert condition type for table "AssemblyReconfigQueue" */
export type AssemblyReconfigQueue_If_Matched = {
  match_columns?: Array<AssemblyReconfigQueue_Insert_Match_Column>;
  update_columns?: Array<AssemblyReconfigQueue_Update_Column>;
  where?: Maybe<AssemblyReconfigQueue_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "AssemblyReconfigQueue" */
export type AssemblyReconfigQueue_Inc_Input = {
  AssemblyId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "AssemblyReconfigQueue" */
export type AssemblyReconfigQueue_Insert_Input = {
  AssemblyId?: Maybe<Scalars['Int']>;
  DateCompleted?: Maybe<Scalars['datetime']>;
  DateReconfigured?: Maybe<Scalars['datetime']>;
  ErrorMsg?: Maybe<Scalars['String']>;
  IsCompleted?: Maybe<Scalars['Boolean']>;
};

/** select match_columns of table "AssemblyReconfigQueue" */
export enum AssemblyReconfigQueue_Insert_Match_Column {
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  AssemblyReconfigQueueId = 'AssemblyReconfigQueueId',
  /** column name */
  DateCompleted = 'DateCompleted',
  /** column name */
  DateReconfigured = 'DateReconfigured',
  /** column name */
  ErrorMsg = 'ErrorMsg',
  /** column name */
  IsCompleted = 'IsCompleted'
}

/** aggregate max on columns */
export type AssemblyReconfigQueue_Max_Fields = {
  __typename?: 'AssemblyReconfigQueue_max_fields';
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyReconfigQueueId?: Maybe<Scalars['Int']>;
  DateCompleted?: Maybe<Scalars['datetime']>;
  DateReconfigured?: Maybe<Scalars['datetime']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AssemblyReconfigQueue_Min_Fields = {
  __typename?: 'AssemblyReconfigQueue_min_fields';
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyReconfigQueueId?: Maybe<Scalars['Int']>;
  DateCompleted?: Maybe<Scalars['datetime']>;
  DateReconfigured?: Maybe<Scalars['datetime']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "AssemblyReconfigQueue" */
export type AssemblyReconfigQueue_Mutation_Response = {
  __typename?: 'AssemblyReconfigQueue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AssemblyReconfigQueue>;
};

/** Ordering options when selecting data from "AssemblyReconfigQueue". */
export type AssemblyReconfigQueue_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  AssemblyReconfigQueueId?: Maybe<Order_By>;
  DateCompleted?: Maybe<Order_By>;
  DateReconfigured?: Maybe<Order_By>;
  ErrorMsg?: Maybe<Order_By>;
  IsCompleted?: Maybe<Order_By>;
};

/** primary key columns input for table: AssemblyReconfigQueue */
export type AssemblyReconfigQueue_Pk_Columns_Input = {
  AssemblyReconfigQueueId: Scalars['Int'];
};

/** select columns of table "AssemblyReconfigQueue" */
export enum AssemblyReconfigQueue_Select_Column {
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  AssemblyReconfigQueueId = 'AssemblyReconfigQueueId',
  /** column name */
  DateCompleted = 'DateCompleted',
  /** column name */
  DateReconfigured = 'DateReconfigured',
  /** column name */
  ErrorMsg = 'ErrorMsg',
  /** column name */
  IsCompleted = 'IsCompleted'
}

/** input type for updating data in table "AssemblyReconfigQueue" */
export type AssemblyReconfigQueue_Set_Input = {
  AssemblyId?: Maybe<Scalars['Int']>;
  DateCompleted?: Maybe<Scalars['datetime']>;
  DateReconfigured?: Maybe<Scalars['datetime']>;
  ErrorMsg?: Maybe<Scalars['String']>;
  IsCompleted?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type AssemblyReconfigQueue_Stddev_Fields = {
  __typename?: 'AssemblyReconfigQueue_stddev_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyReconfigQueueId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AssemblyReconfigQueue_Stddev_Pop_Fields = {
  __typename?: 'AssemblyReconfigQueue_stddev_pop_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyReconfigQueueId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AssemblyReconfigQueue_Stddev_Samp_Fields = {
  __typename?: 'AssemblyReconfigQueue_stddev_samp_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyReconfigQueueId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AssemblyReconfigQueue_Sum_Fields = {
  __typename?: 'AssemblyReconfigQueue_sum_fields';
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyReconfigQueueId?: Maybe<Scalars['Int']>;
};

/** update columns of table "AssemblyReconfigQueue" */
export enum AssemblyReconfigQueue_Update_Column {
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  DateCompleted = 'DateCompleted',
  /** column name */
  DateReconfigured = 'DateReconfigured',
  /** column name */
  ErrorMsg = 'ErrorMsg',
  /** column name */
  IsCompleted = 'IsCompleted'
}

/** aggregate var_pop on columns */
export type AssemblyReconfigQueue_Var_Pop_Fields = {
  __typename?: 'AssemblyReconfigQueue_var_pop_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyReconfigQueueId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AssemblyReconfigQueue_Var_Samp_Fields = {
  __typename?: 'AssemblyReconfigQueue_var_samp_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyReconfigQueueId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AssemblyReconfigQueue_Variance_Fields = {
  __typename?: 'AssemblyReconfigQueue_variance_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyReconfigQueueId?: Maybe<Scalars['Float']>;
};

export type AssemblySearchCountItem = {
  __typename?: 'AssemblySearchCountItem';
  AssemblySearchResultCount: Scalars['Int'];
};

export type AssemblySearchCriteria = {
  LayerCriteria: Array<Maybe<LayerSearchCriteriaBase>>;
  assemblyCriteria: AssemblyPropertySearchCriteria;
  excludedLayerTypeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  pageNumber: Scalars['Int'];
  rowsPerPage: Scalars['Int'];
  sortColumn: Scalars['String'];
  sortDirection: Scalars['Int'];
};

export type AssemblySearchResultItem = {
  __typename?: 'AssemblySearchResultItem';
  AssemblyApplicationTypeCode: Scalars['String'];
  AssemblyTypeCode: Scalars['String'];
  CoverSecurementMethodCode: Scalars['String'];
  DeckTypeCode: Scalars['String'];
  ExtFireRatingCode: Scalars['String'];
  HailRatingCode: Scalars['String'];
  IntFireRatingCode: Scalars['String'];
  NavAssemblyId: Scalars['Int'];
  NavAssemblyName: Scalars['String'];
  RecordCount?: Maybe<Scalars['Int']>;
  Slope: Scalars['Float'];
  WindUplift: Scalars['Float'];
};

export type AssemblySearchRoofTypeItem = {
  __typename?: 'AssemblySearchRoofTypeItem';
  AssemblyCount?: Maybe<Scalars['Int']>;
  ConfigString: Scalars['String'];
  LayerConfigId: Scalars['Int'];
  NumOfAssembly: Scalars['Int'];
};

/** columns and relationships of "BaseCoat" */
export type BaseCoat = {
  __typename?: 'BaseCoat';
  /** An object relationship */
  C_CoverColor: C_CoverColor;
  /** An object relationship */
  C_LiquidApplication: C_LiquidApplication;
  /** An object relationship */
  C_LiquidAppliedMaterial: C_LiquidAppliedMaterial;
  ComponentId: Scalars['Int'];
  CoverColorId: Scalars['Int'];
  LiquidApplicationId: Scalars['Int'];
  LiquidAppliedMaterialId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
};

/** columns and relationships of "BaseCoatUsage" */
export type BaseCoatUsage = {
  __typename?: 'BaseCoatUsage';
  ApplicationRateMinDry: Scalars['MeasurementValue'];
  ApplicationRateMinWet: Scalars['MeasurementValue'];
  NumCoats: Scalars['Int'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "BaseCoatUsage" */
export type BaseCoatUsage_Aggregate = {
  __typename?: 'BaseCoatUsage_aggregate';
  aggregate?: Maybe<BaseCoatUsage_Aggregate_Fields>;
  nodes: Array<BaseCoatUsage>;
};

/** aggregate fields of "BaseCoatUsage" */
export type BaseCoatUsage_Aggregate_Fields = {
  __typename?: 'BaseCoatUsage_aggregate_fields';
  avg?: Maybe<BaseCoatUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BaseCoatUsage_Max_Fields>;
  min?: Maybe<BaseCoatUsage_Min_Fields>;
  stddev?: Maybe<BaseCoatUsage_Stddev_Fields>;
  stddev_pop?: Maybe<BaseCoatUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BaseCoatUsage_Stddev_Samp_Fields>;
  sum?: Maybe<BaseCoatUsage_Sum_Fields>;
  var_pop?: Maybe<BaseCoatUsage_Var_Pop_Fields>;
  var_samp?: Maybe<BaseCoatUsage_Var_Samp_Fields>;
  variance?: Maybe<BaseCoatUsage_Variance_Fields>;
};


/** aggregate fields of "BaseCoatUsage" */
export type BaseCoatUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<BaseCoatUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type BaseCoatUsage_Avg_Fields = {
  __typename?: 'BaseCoatUsage_avg_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "BaseCoatUsage". All fields are combined with a logical 'AND'. */
export type BaseCoatUsage_Bool_Exp = {
  ApplicationRateMinDry?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ApplicationRateMinWet?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  NumCoats?: Maybe<Int_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BaseCoatUsage_Bool_Exp>>;
  _not?: Maybe<BaseCoatUsage_Bool_Exp>;
  _or?: Maybe<Array<BaseCoatUsage_Bool_Exp>>;
};

/** upsert condition type for table "BaseCoatUsage" */
export type BaseCoatUsage_If_Matched = {
  match_columns?: Array<BaseCoatUsage_Insert_Match_Column>;
  update_columns?: Array<BaseCoatUsage_Update_Column>;
  where?: Maybe<BaseCoatUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BaseCoatUsage" */
export type BaseCoatUsage_Inc_Input = {
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BaseCoatUsage" */
export type BaseCoatUsage_Insert_Input = {
  ApplicationRateMinDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateMinWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "BaseCoatUsage" */
export enum BaseCoatUsage_Insert_Match_Column {
  /** column name */
  ApplicationRateMinDry = 'ApplicationRateMinDry',
  /** column name */
  ApplicationRateMinWet = 'ApplicationRateMinWet',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type BaseCoatUsage_Max_Fields = {
  __typename?: 'BaseCoatUsage_max_fields';
  ApplicationRateMinDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateMinWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type BaseCoatUsage_Min_Fields = {
  __typename?: 'BaseCoatUsage_min_fields';
  ApplicationRateMinDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateMinWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "BaseCoatUsage" */
export type BaseCoatUsage_Mutation_Response = {
  __typename?: 'BaseCoatUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BaseCoatUsage>;
};

/** Ordering options when selecting data from "BaseCoatUsage". */
export type BaseCoatUsage_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: BaseCoatUsage */
export type BaseCoatUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "BaseCoatUsage" */
export enum BaseCoatUsage_Select_Column {
  /** column name */
  ApplicationRateMinDry = 'ApplicationRateMinDry',
  /** column name */
  ApplicationRateMinWet = 'ApplicationRateMinWet',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "BaseCoatUsage" */
export type BaseCoatUsage_Set_Input = {
  ApplicationRateMinDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateMinWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type BaseCoatUsage_Stddev_Fields = {
  __typename?: 'BaseCoatUsage_stddev_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type BaseCoatUsage_Stddev_Pop_Fields = {
  __typename?: 'BaseCoatUsage_stddev_pop_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type BaseCoatUsage_Stddev_Samp_Fields = {
  __typename?: 'BaseCoatUsage_stddev_samp_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type BaseCoatUsage_Sum_Fields = {
  __typename?: 'BaseCoatUsage_sum_fields';
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "BaseCoatUsage" */
export enum BaseCoatUsage_Update_Column {
  /** column name */
  ApplicationRateMinDry = 'ApplicationRateMinDry',
  /** column name */
  ApplicationRateMinWet = 'ApplicationRateMinWet',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type BaseCoatUsage_Var_Pop_Fields = {
  __typename?: 'BaseCoatUsage_var_pop_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type BaseCoatUsage_Var_Samp_Fields = {
  __typename?: 'BaseCoatUsage_var_samp_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type BaseCoatUsage_Variance_Fields = {
  __typename?: 'BaseCoatUsage_variance_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "BaseCoat" */
export type BaseCoat_Aggregate = {
  __typename?: 'BaseCoat_aggregate';
  aggregate?: Maybe<BaseCoat_Aggregate_Fields>;
  nodes: Array<BaseCoat>;
};

/** aggregate fields of "BaseCoat" */
export type BaseCoat_Aggregate_Fields = {
  __typename?: 'BaseCoat_aggregate_fields';
  avg?: Maybe<BaseCoat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BaseCoat_Max_Fields>;
  min?: Maybe<BaseCoat_Min_Fields>;
  stddev?: Maybe<BaseCoat_Stddev_Fields>;
  stddev_pop?: Maybe<BaseCoat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BaseCoat_Stddev_Samp_Fields>;
  sum?: Maybe<BaseCoat_Sum_Fields>;
  var_pop?: Maybe<BaseCoat_Var_Pop_Fields>;
  var_samp?: Maybe<BaseCoat_Var_Samp_Fields>;
  variance?: Maybe<BaseCoat_Variance_Fields>;
};


/** aggregate fields of "BaseCoat" */
export type BaseCoat_Aggregate_FieldsCountArgs = {
  column?: Maybe<BaseCoat_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BaseCoat" */
export type BaseCoat_Aggregate_Order_By = {
  avg?: Maybe<BaseCoat_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<BaseCoat_Max_Order_By>;
  min?: Maybe<BaseCoat_Min_Order_By>;
  stddev?: Maybe<BaseCoat_Stddev_Order_By>;
  stddev_pop?: Maybe<BaseCoat_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<BaseCoat_Stddev_Samp_Order_By>;
  sum?: Maybe<BaseCoat_Sum_Order_By>;
  var_pop?: Maybe<BaseCoat_Var_Pop_Order_By>;
  var_samp?: Maybe<BaseCoat_Var_Samp_Order_By>;
  variance?: Maybe<BaseCoat_Variance_Order_By>;
};

/** aggregate avg on columns */
export type BaseCoat_Avg_Fields = {
  __typename?: 'BaseCoat_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "BaseCoat" */
export type BaseCoat_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "BaseCoat". All fields are combined with a logical 'AND'. */
export type BaseCoat_Bool_Exp = {
  C_CoverColor?: Maybe<C_CoverColor_Bool_Exp>;
  C_LiquidApplication?: Maybe<C_LiquidApplication_Bool_Exp>;
  C_LiquidAppliedMaterial?: Maybe<C_LiquidAppliedMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  CoverColorId?: Maybe<Int_Mssql_Comparison_Exp>;
  LiquidApplicationId?: Maybe<Int_Mssql_Comparison_Exp>;
  LiquidAppliedMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  _and?: Maybe<Array<BaseCoat_Bool_Exp>>;
  _not?: Maybe<BaseCoat_Bool_Exp>;
  _or?: Maybe<Array<BaseCoat_Bool_Exp>>;
};

/** upsert condition type for table "BaseCoat" */
export type BaseCoat_If_Matched = {
  match_columns?: Array<BaseCoat_Insert_Match_Column>;
  update_columns?: Array<BaseCoat_Update_Column>;
  where?: Maybe<BaseCoat_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BaseCoat" */
export type BaseCoat_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BaseCoat" */
export type BaseCoat_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "BaseCoat" */
export enum BaseCoat_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverColorId = 'CoverColorId',
  /** column name */
  LiquidApplicationId = 'LiquidApplicationId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId'
}

/** aggregate max on columns */
export type BaseCoat_Max_Fields = {
  __typename?: 'BaseCoat_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "BaseCoat" */
export type BaseCoat_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type BaseCoat_Min_Fields = {
  __typename?: 'BaseCoat_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "BaseCoat" */
export type BaseCoat_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** response of any mutation on the table "BaseCoat" */
export type BaseCoat_Mutation_Response = {
  __typename?: 'BaseCoat_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BaseCoat>;
};

/** Ordering options when selecting data from "BaseCoat". */
export type BaseCoat_Order_By = {
  C_CoverColor?: Maybe<C_CoverColor_Order_By>;
  C_LiquidApplication?: Maybe<C_LiquidApplication_Order_By>;
  C_LiquidAppliedMaterial?: Maybe<C_LiquidAppliedMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
};

/** primary key columns input for table: BaseCoat */
export type BaseCoat_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "BaseCoat" */
export enum BaseCoat_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverColorId = 'CoverColorId',
  /** column name */
  LiquidApplicationId = 'LiquidApplicationId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId'
}

/** input type for updating data in table "BaseCoat" */
export type BaseCoat_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type BaseCoat_Stddev_Fields = {
  __typename?: 'BaseCoat_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "BaseCoat" */
export type BaseCoat_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type BaseCoat_Stddev_Pop_Fields = {
  __typename?: 'BaseCoat_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "BaseCoat" */
export type BaseCoat_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type BaseCoat_Stddev_Samp_Fields = {
  __typename?: 'BaseCoat_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "BaseCoat" */
export type BaseCoat_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type BaseCoat_Sum_Fields = {
  __typename?: 'BaseCoat_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "BaseCoat" */
export type BaseCoat_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** update columns of table "BaseCoat" */
export enum BaseCoat_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverColorId = 'CoverColorId',
  /** column name */
  LiquidApplicationId = 'LiquidApplicationId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId'
}

/** aggregate var_pop on columns */
export type BaseCoat_Var_Pop_Fields = {
  __typename?: 'BaseCoat_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "BaseCoat" */
export type BaseCoat_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type BaseCoat_Var_Samp_Fields = {
  __typename?: 'BaseCoat_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "BaseCoat" */
export type BaseCoat_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type BaseCoat_Variance_Fields = {
  __typename?: 'BaseCoat_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "BaseCoat" */
export type BaseCoat_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "BasePly" */
export type BasePly = {
  __typename?: 'BasePly';
  BackingMaterialId: Scalars['Int'];
  /** An object relationship */
  C_BackingMaterial: C_BackingMaterial;
  /** An object relationship */
  C_MultiplyMaterial: C_MultiplyMaterial;
  /** An object relationship */
  C_Reinforcement: C_Reinforcement;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  MultiplyMaterialId: Scalars['Int'];
  ReinforcementId: Scalars['Int'];
  Width: Scalars['MeasurementValue'];
};

/** columns and relationships of "BasePlyUsage" */
export type BasePlyUsage = {
  __typename?: 'BasePlyUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "BasePlyUsage" */
export type BasePlyUsage_Aggregate = {
  __typename?: 'BasePlyUsage_aggregate';
  aggregate?: Maybe<BasePlyUsage_Aggregate_Fields>;
  nodes: Array<BasePlyUsage>;
};

/** aggregate fields of "BasePlyUsage" */
export type BasePlyUsage_Aggregate_Fields = {
  __typename?: 'BasePlyUsage_aggregate_fields';
  avg?: Maybe<BasePlyUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BasePlyUsage_Max_Fields>;
  min?: Maybe<BasePlyUsage_Min_Fields>;
  stddev?: Maybe<BasePlyUsage_Stddev_Fields>;
  stddev_pop?: Maybe<BasePlyUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BasePlyUsage_Stddev_Samp_Fields>;
  sum?: Maybe<BasePlyUsage_Sum_Fields>;
  var_pop?: Maybe<BasePlyUsage_Var_Pop_Fields>;
  var_samp?: Maybe<BasePlyUsage_Var_Samp_Fields>;
  variance?: Maybe<BasePlyUsage_Variance_Fields>;
};


/** aggregate fields of "BasePlyUsage" */
export type BasePlyUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<BasePlyUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type BasePlyUsage_Avg_Fields = {
  __typename?: 'BasePlyUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "BasePlyUsage". All fields are combined with a logical 'AND'. */
export type BasePlyUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BasePlyUsage_Bool_Exp>>;
  _not?: Maybe<BasePlyUsage_Bool_Exp>;
  _or?: Maybe<Array<BasePlyUsage_Bool_Exp>>;
};

/** upsert condition type for table "BasePlyUsage" */
export type BasePlyUsage_If_Matched = {
  match_columns?: Array<BasePlyUsage_Insert_Match_Column>;
  update_columns?: Array<BasePlyUsage_Update_Column>;
  where?: Maybe<BasePlyUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BasePlyUsage" */
export type BasePlyUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BasePlyUsage" */
export type BasePlyUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "BasePlyUsage" */
export enum BasePlyUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type BasePlyUsage_Max_Fields = {
  __typename?: 'BasePlyUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type BasePlyUsage_Min_Fields = {
  __typename?: 'BasePlyUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "BasePlyUsage" */
export type BasePlyUsage_Mutation_Response = {
  __typename?: 'BasePlyUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BasePlyUsage>;
};

/** Ordering options when selecting data from "BasePlyUsage". */
export type BasePlyUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: BasePlyUsage */
export type BasePlyUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "BasePlyUsage" */
export enum BasePlyUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "BasePlyUsage" */
export type BasePlyUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type BasePlyUsage_Stddev_Fields = {
  __typename?: 'BasePlyUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type BasePlyUsage_Stddev_Pop_Fields = {
  __typename?: 'BasePlyUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type BasePlyUsage_Stddev_Samp_Fields = {
  __typename?: 'BasePlyUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type BasePlyUsage_Sum_Fields = {
  __typename?: 'BasePlyUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "BasePlyUsage" */
export enum BasePlyUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type BasePlyUsage_Var_Pop_Fields = {
  __typename?: 'BasePlyUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type BasePlyUsage_Var_Samp_Fields = {
  __typename?: 'BasePlyUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type BasePlyUsage_Variance_Fields = {
  __typename?: 'BasePlyUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "BasePly" */
export type BasePly_Aggregate = {
  __typename?: 'BasePly_aggregate';
  aggregate?: Maybe<BasePly_Aggregate_Fields>;
  nodes: Array<BasePly>;
};

/** aggregate fields of "BasePly" */
export type BasePly_Aggregate_Fields = {
  __typename?: 'BasePly_aggregate_fields';
  avg?: Maybe<BasePly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BasePly_Max_Fields>;
  min?: Maybe<BasePly_Min_Fields>;
  stddev?: Maybe<BasePly_Stddev_Fields>;
  stddev_pop?: Maybe<BasePly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BasePly_Stddev_Samp_Fields>;
  sum?: Maybe<BasePly_Sum_Fields>;
  var_pop?: Maybe<BasePly_Var_Pop_Fields>;
  var_samp?: Maybe<BasePly_Var_Samp_Fields>;
  variance?: Maybe<BasePly_Variance_Fields>;
};


/** aggregate fields of "BasePly" */
export type BasePly_Aggregate_FieldsCountArgs = {
  column?: Maybe<BasePly_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BasePly" */
export type BasePly_Aggregate_Order_By = {
  avg?: Maybe<BasePly_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<BasePly_Max_Order_By>;
  min?: Maybe<BasePly_Min_Order_By>;
  stddev?: Maybe<BasePly_Stddev_Order_By>;
  stddev_pop?: Maybe<BasePly_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<BasePly_Stddev_Samp_Order_By>;
  sum?: Maybe<BasePly_Sum_Order_By>;
  var_pop?: Maybe<BasePly_Var_Pop_Order_By>;
  var_samp?: Maybe<BasePly_Var_Samp_Order_By>;
  variance?: Maybe<BasePly_Variance_Order_By>;
};

/** aggregate avg on columns */
export type BasePly_Avg_Fields = {
  __typename?: 'BasePly_avg_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "BasePly" */
export type BasePly_Avg_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "BasePly". All fields are combined with a logical 'AND'. */
export type BasePly_Bool_Exp = {
  BackingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_BackingMaterial?: Maybe<C_BackingMaterial_Bool_Exp>;
  C_MultiplyMaterial?: Maybe<C_MultiplyMaterial_Bool_Exp>;
  C_Reinforcement?: Maybe<C_Reinforcement_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  MultiplyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ReinforcementId?: Maybe<Int_Mssql_Comparison_Exp>;
  Width?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BasePly_Bool_Exp>>;
  _not?: Maybe<BasePly_Bool_Exp>;
  _or?: Maybe<Array<BasePly_Bool_Exp>>;
};

/** upsert condition type for table "BasePly" */
export type BasePly_If_Matched = {
  match_columns?: Array<BasePly_Insert_Match_Column>;
  update_columns?: Array<BasePly_Update_Column>;
  where?: Maybe<BasePly_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BasePly" */
export type BasePly_Inc_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BasePly" */
export type BasePly_Insert_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "BasePly" */
export enum BasePly_Insert_Match_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  Width = 'Width'
}

/** aggregate max on columns */
export type BasePly_Max_Fields = {
  __typename?: 'BasePly_max_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "BasePly" */
export type BasePly_Max_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type BasePly_Min_Fields = {
  __typename?: 'BasePly_min_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "BasePly" */
export type BasePly_Min_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** response of any mutation on the table "BasePly" */
export type BasePly_Mutation_Response = {
  __typename?: 'BasePly_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BasePly>;
};

/** Ordering options when selecting data from "BasePly". */
export type BasePly_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  C_BackingMaterial?: Maybe<C_BackingMaterial_Order_By>;
  C_MultiplyMaterial?: Maybe<C_MultiplyMaterial_Order_By>;
  C_Reinforcement?: Maybe<C_Reinforcement_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** primary key columns input for table: BasePly */
export type BasePly_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "BasePly" */
export enum BasePly_Select_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  Width = 'Width'
}

/** input type for updating data in table "BasePly" */
export type BasePly_Set_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type BasePly_Stddev_Fields = {
  __typename?: 'BasePly_stddev_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "BasePly" */
export type BasePly_Stddev_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type BasePly_Stddev_Pop_Fields = {
  __typename?: 'BasePly_stddev_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "BasePly" */
export type BasePly_Stddev_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type BasePly_Stddev_Samp_Fields = {
  __typename?: 'BasePly_stddev_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "BasePly" */
export type BasePly_Stddev_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type BasePly_Sum_Fields = {
  __typename?: 'BasePly_sum_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "BasePly" */
export type BasePly_Sum_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** update columns of table "BasePly" */
export enum BasePly_Update_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  Width = 'Width'
}

/** aggregate var_pop on columns */
export type BasePly_Var_Pop_Fields = {
  __typename?: 'BasePly_var_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "BasePly" */
export type BasePly_Var_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type BasePly_Var_Samp_Fields = {
  __typename?: 'BasePly_var_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "BasePly" */
export type BasePly_Var_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type BasePly_Variance_Fields = {
  __typename?: 'BasePly_variance_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "BasePly" */
export type BasePly_Variance_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** columns and relationships of "BaseSheet" */
export type BaseSheet = {
  __typename?: 'BaseSheet';
  BackingMaterialId: Scalars['Int'];
  /** An object relationship */
  C_BackingMaterial: C_BackingMaterial;
  /** An object relationship */
  C_MultiplyMaterial: C_MultiplyMaterial;
  /** An object relationship */
  C_Reinforcement: C_Reinforcement;
  ComponentId: Scalars['Int'];
  IsPermeable: Scalars['Boolean'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  MultiplyMaterialId: Scalars['Int'];
  ReinforcementId: Scalars['Int'];
  Width: Scalars['MeasurementValue'];
};

/** columns and relationships of "BaseSheetUsage" */
export type BaseSheetUsage = {
  __typename?: 'BaseSheetUsage';
  LapWidth: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
  WidthMax: Scalars['MeasurementValue'];
};

/** aggregated selection of "BaseSheetUsage" */
export type BaseSheetUsage_Aggregate = {
  __typename?: 'BaseSheetUsage_aggregate';
  aggregate?: Maybe<BaseSheetUsage_Aggregate_Fields>;
  nodes: Array<BaseSheetUsage>;
};

/** aggregate fields of "BaseSheetUsage" */
export type BaseSheetUsage_Aggregate_Fields = {
  __typename?: 'BaseSheetUsage_aggregate_fields';
  avg?: Maybe<BaseSheetUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BaseSheetUsage_Max_Fields>;
  min?: Maybe<BaseSheetUsage_Min_Fields>;
  stddev?: Maybe<BaseSheetUsage_Stddev_Fields>;
  stddev_pop?: Maybe<BaseSheetUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BaseSheetUsage_Stddev_Samp_Fields>;
  sum?: Maybe<BaseSheetUsage_Sum_Fields>;
  var_pop?: Maybe<BaseSheetUsage_Var_Pop_Fields>;
  var_samp?: Maybe<BaseSheetUsage_Var_Samp_Fields>;
  variance?: Maybe<BaseSheetUsage_Variance_Fields>;
};


/** aggregate fields of "BaseSheetUsage" */
export type BaseSheetUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<BaseSheetUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type BaseSheetUsage_Avg_Fields = {
  __typename?: 'BaseSheetUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "BaseSheetUsage". All fields are combined with a logical 'AND'. */
export type BaseSheetUsage_Bool_Exp = {
  LapWidth?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  WidthMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BaseSheetUsage_Bool_Exp>>;
  _not?: Maybe<BaseSheetUsage_Bool_Exp>;
  _or?: Maybe<Array<BaseSheetUsage_Bool_Exp>>;
};

/** upsert condition type for table "BaseSheetUsage" */
export type BaseSheetUsage_If_Matched = {
  match_columns?: Array<BaseSheetUsage_Insert_Match_Column>;
  update_columns?: Array<BaseSheetUsage_Update_Column>;
  where?: Maybe<BaseSheetUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BaseSheetUsage" */
export type BaseSheetUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BaseSheetUsage" */
export type BaseSheetUsage_Insert_Input = {
  LapWidth?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "BaseSheetUsage" */
export enum BaseSheetUsage_Insert_Match_Column {
  /** column name */
  LapWidth = 'LapWidth',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax'
}

/** aggregate max on columns */
export type BaseSheetUsage_Max_Fields = {
  __typename?: 'BaseSheetUsage_max_fields';
  LapWidth?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate min on columns */
export type BaseSheetUsage_Min_Fields = {
  __typename?: 'BaseSheetUsage_min_fields';
  LapWidth?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** response of any mutation on the table "BaseSheetUsage" */
export type BaseSheetUsage_Mutation_Response = {
  __typename?: 'BaseSheetUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BaseSheetUsage>;
};

/** Ordering options when selecting data from "BaseSheetUsage". */
export type BaseSheetUsage_Order_By = {
  LapWidth?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** primary key columns input for table: BaseSheetUsage */
export type BaseSheetUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "BaseSheetUsage" */
export enum BaseSheetUsage_Select_Column {
  /** column name */
  LapWidth = 'LapWidth',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax'
}

/** input type for updating data in table "BaseSheetUsage" */
export type BaseSheetUsage_Set_Input = {
  LapWidth?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type BaseSheetUsage_Stddev_Fields = {
  __typename?: 'BaseSheetUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type BaseSheetUsage_Stddev_Pop_Fields = {
  __typename?: 'BaseSheetUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type BaseSheetUsage_Stddev_Samp_Fields = {
  __typename?: 'BaseSheetUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type BaseSheetUsage_Sum_Fields = {
  __typename?: 'BaseSheetUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "BaseSheetUsage" */
export enum BaseSheetUsage_Update_Column {
  /** column name */
  LapWidth = 'LapWidth',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax'
}

/** aggregate var_pop on columns */
export type BaseSheetUsage_Var_Pop_Fields = {
  __typename?: 'BaseSheetUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type BaseSheetUsage_Var_Samp_Fields = {
  __typename?: 'BaseSheetUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type BaseSheetUsage_Variance_Fields = {
  __typename?: 'BaseSheetUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "BaseSheet" */
export type BaseSheet_Aggregate = {
  __typename?: 'BaseSheet_aggregate';
  aggregate?: Maybe<BaseSheet_Aggregate_Fields>;
  nodes: Array<BaseSheet>;
};

/** aggregate fields of "BaseSheet" */
export type BaseSheet_Aggregate_Fields = {
  __typename?: 'BaseSheet_aggregate_fields';
  avg?: Maybe<BaseSheet_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BaseSheet_Max_Fields>;
  min?: Maybe<BaseSheet_Min_Fields>;
  stddev?: Maybe<BaseSheet_Stddev_Fields>;
  stddev_pop?: Maybe<BaseSheet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BaseSheet_Stddev_Samp_Fields>;
  sum?: Maybe<BaseSheet_Sum_Fields>;
  var_pop?: Maybe<BaseSheet_Var_Pop_Fields>;
  var_samp?: Maybe<BaseSheet_Var_Samp_Fields>;
  variance?: Maybe<BaseSheet_Variance_Fields>;
};


/** aggregate fields of "BaseSheet" */
export type BaseSheet_Aggregate_FieldsCountArgs = {
  column?: Maybe<BaseSheet_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BaseSheet" */
export type BaseSheet_Aggregate_Order_By = {
  avg?: Maybe<BaseSheet_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<BaseSheet_Max_Order_By>;
  min?: Maybe<BaseSheet_Min_Order_By>;
  stddev?: Maybe<BaseSheet_Stddev_Order_By>;
  stddev_pop?: Maybe<BaseSheet_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<BaseSheet_Stddev_Samp_Order_By>;
  sum?: Maybe<BaseSheet_Sum_Order_By>;
  var_pop?: Maybe<BaseSheet_Var_Pop_Order_By>;
  var_samp?: Maybe<BaseSheet_Var_Samp_Order_By>;
  variance?: Maybe<BaseSheet_Variance_Order_By>;
};

/** aggregate avg on columns */
export type BaseSheet_Avg_Fields = {
  __typename?: 'BaseSheet_avg_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "BaseSheet" */
export type BaseSheet_Avg_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "BaseSheet". All fields are combined with a logical 'AND'. */
export type BaseSheet_Bool_Exp = {
  BackingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_BackingMaterial?: Maybe<C_BackingMaterial_Bool_Exp>;
  C_MultiplyMaterial?: Maybe<C_MultiplyMaterial_Bool_Exp>;
  C_Reinforcement?: Maybe<C_Reinforcement_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  IsPermeable?: Maybe<Boolean_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  MultiplyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ReinforcementId?: Maybe<Int_Mssql_Comparison_Exp>;
  Width?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BaseSheet_Bool_Exp>>;
  _not?: Maybe<BaseSheet_Bool_Exp>;
  _or?: Maybe<Array<BaseSheet_Bool_Exp>>;
};

/** upsert condition type for table "BaseSheet" */
export type BaseSheet_If_Matched = {
  match_columns?: Array<BaseSheet_Insert_Match_Column>;
  update_columns?: Array<BaseSheet_Update_Column>;
  where?: Maybe<BaseSheet_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BaseSheet" */
export type BaseSheet_Inc_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BaseSheet" */
export type BaseSheet_Insert_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  IsPermeable?: Maybe<Scalars['Boolean']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "BaseSheet" */
export enum BaseSheet_Insert_Match_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  IsPermeable = 'IsPermeable',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  Width = 'Width'
}

/** aggregate max on columns */
export type BaseSheet_Max_Fields = {
  __typename?: 'BaseSheet_max_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "BaseSheet" */
export type BaseSheet_Max_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type BaseSheet_Min_Fields = {
  __typename?: 'BaseSheet_min_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "BaseSheet" */
export type BaseSheet_Min_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** response of any mutation on the table "BaseSheet" */
export type BaseSheet_Mutation_Response = {
  __typename?: 'BaseSheet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BaseSheet>;
};

/** Ordering options when selecting data from "BaseSheet". */
export type BaseSheet_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  C_BackingMaterial?: Maybe<C_BackingMaterial_Order_By>;
  C_MultiplyMaterial?: Maybe<C_MultiplyMaterial_Order_By>;
  C_Reinforcement?: Maybe<C_Reinforcement_Order_By>;
  ComponentId?: Maybe<Order_By>;
  IsPermeable?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** primary key columns input for table: BaseSheet */
export type BaseSheet_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "BaseSheet" */
export enum BaseSheet_Select_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  IsPermeable = 'IsPermeable',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  Width = 'Width'
}

/** input type for updating data in table "BaseSheet" */
export type BaseSheet_Set_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  IsPermeable?: Maybe<Scalars['Boolean']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type BaseSheet_Stddev_Fields = {
  __typename?: 'BaseSheet_stddev_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "BaseSheet" */
export type BaseSheet_Stddev_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type BaseSheet_Stddev_Pop_Fields = {
  __typename?: 'BaseSheet_stddev_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "BaseSheet" */
export type BaseSheet_Stddev_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type BaseSheet_Stddev_Samp_Fields = {
  __typename?: 'BaseSheet_stddev_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "BaseSheet" */
export type BaseSheet_Stddev_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type BaseSheet_Sum_Fields = {
  __typename?: 'BaseSheet_sum_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "BaseSheet" */
export type BaseSheet_Sum_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** update columns of table "BaseSheet" */
export enum BaseSheet_Update_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  IsPermeable = 'IsPermeable',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  Width = 'Width'
}

/** aggregate var_pop on columns */
export type BaseSheet_Var_Pop_Fields = {
  __typename?: 'BaseSheet_var_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "BaseSheet" */
export type BaseSheet_Var_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type BaseSheet_Var_Samp_Fields = {
  __typename?: 'BaseSheet_var_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "BaseSheet" */
export type BaseSheet_Var_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type BaseSheet_Variance_Fields = {
  __typename?: 'BaseSheet_variance_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "BaseSheet" */
export type BaseSheet_Variance_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** columns and relationships of "BattBlanketInsulation" */
export type BattBlanketInsulation = {
  __typename?: 'BattBlanketInsulation';
  BattBlanketFacerMaterialId: Scalars['Int'];
  BattBlanketMaterialId: Scalars['Int'];
  /** An object relationship */
  C_BattBlanketFacerMaterial: C_BattBlanketFacerMaterial;
  /** An object relationship */
  C_BattBlanketMaterial: C_BattBlanketMaterial;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  ThicknessMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
};

/** aggregated selection of "BattBlanketInsulation" */
export type BattBlanketInsulation_Aggregate = {
  __typename?: 'BattBlanketInsulation_aggregate';
  aggregate?: Maybe<BattBlanketInsulation_Aggregate_Fields>;
  nodes: Array<BattBlanketInsulation>;
};

/** aggregate fields of "BattBlanketInsulation" */
export type BattBlanketInsulation_Aggregate_Fields = {
  __typename?: 'BattBlanketInsulation_aggregate_fields';
  avg?: Maybe<BattBlanketInsulation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BattBlanketInsulation_Max_Fields>;
  min?: Maybe<BattBlanketInsulation_Min_Fields>;
  stddev?: Maybe<BattBlanketInsulation_Stddev_Fields>;
  stddev_pop?: Maybe<BattBlanketInsulation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BattBlanketInsulation_Stddev_Samp_Fields>;
  sum?: Maybe<BattBlanketInsulation_Sum_Fields>;
  var_pop?: Maybe<BattBlanketInsulation_Var_Pop_Fields>;
  var_samp?: Maybe<BattBlanketInsulation_Var_Samp_Fields>;
  variance?: Maybe<BattBlanketInsulation_Variance_Fields>;
};


/** aggregate fields of "BattBlanketInsulation" */
export type BattBlanketInsulation_Aggregate_FieldsCountArgs = {
  column?: Maybe<BattBlanketInsulation_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BattBlanketInsulation" */
export type BattBlanketInsulation_Aggregate_Order_By = {
  avg?: Maybe<BattBlanketInsulation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<BattBlanketInsulation_Max_Order_By>;
  min?: Maybe<BattBlanketInsulation_Min_Order_By>;
  stddev?: Maybe<BattBlanketInsulation_Stddev_Order_By>;
  stddev_pop?: Maybe<BattBlanketInsulation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<BattBlanketInsulation_Stddev_Samp_Order_By>;
  sum?: Maybe<BattBlanketInsulation_Sum_Order_By>;
  var_pop?: Maybe<BattBlanketInsulation_Var_Pop_Order_By>;
  var_samp?: Maybe<BattBlanketInsulation_Var_Samp_Order_By>;
  variance?: Maybe<BattBlanketInsulation_Variance_Order_By>;
};

/** aggregate avg on columns */
export type BattBlanketInsulation_Avg_Fields = {
  __typename?: 'BattBlanketInsulation_avg_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "BattBlanketInsulation" */
export type BattBlanketInsulation_Avg_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "BattBlanketInsulation". All fields are combined with a logical 'AND'. */
export type BattBlanketInsulation_Bool_Exp = {
  BattBlanketFacerMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  BattBlanketMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_BattBlanketFacerMaterial?: Maybe<C_BattBlanketFacerMaterial_Bool_Exp>;
  C_BattBlanketMaterial?: Maybe<C_BattBlanketMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  ThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BattBlanketInsulation_Bool_Exp>>;
  _not?: Maybe<BattBlanketInsulation_Bool_Exp>;
  _or?: Maybe<Array<BattBlanketInsulation_Bool_Exp>>;
};

/** upsert condition type for table "BattBlanketInsulation" */
export type BattBlanketInsulation_If_Matched = {
  match_columns?: Array<BattBlanketInsulation_Insert_Match_Column>;
  update_columns?: Array<BattBlanketInsulation_Update_Column>;
  where?: Maybe<BattBlanketInsulation_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BattBlanketInsulation" */
export type BattBlanketInsulation_Inc_Input = {
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BattBlanketInsulation" */
export type BattBlanketInsulation_Insert_Input = {
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "BattBlanketInsulation" */
export enum BattBlanketInsulation_Insert_Match_Column {
  /** column name */
  BattBlanketFacerMaterialId = 'BattBlanketFacerMaterialId',
  /** column name */
  BattBlanketMaterialId = 'BattBlanketMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate max on columns */
export type BattBlanketInsulation_Max_Fields = {
  __typename?: 'BattBlanketInsulation_max_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "BattBlanketInsulation" */
export type BattBlanketInsulation_Max_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type BattBlanketInsulation_Min_Fields = {
  __typename?: 'BattBlanketInsulation_min_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "BattBlanketInsulation" */
export type BattBlanketInsulation_Min_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** response of any mutation on the table "BattBlanketInsulation" */
export type BattBlanketInsulation_Mutation_Response = {
  __typename?: 'BattBlanketInsulation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BattBlanketInsulation>;
};

/** Ordering options when selecting data from "BattBlanketInsulation". */
export type BattBlanketInsulation_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  C_BattBlanketFacerMaterial?: Maybe<C_BattBlanketFacerMaterial_Order_By>;
  C_BattBlanketMaterial?: Maybe<C_BattBlanketMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** primary key columns input for table: BattBlanketInsulation */
export type BattBlanketInsulation_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "BattBlanketInsulation" */
export enum BattBlanketInsulation_Select_Column {
  /** column name */
  BattBlanketFacerMaterialId = 'BattBlanketFacerMaterialId',
  /** column name */
  BattBlanketMaterialId = 'BattBlanketMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** input type for updating data in table "BattBlanketInsulation" */
export type BattBlanketInsulation_Set_Input = {
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type BattBlanketInsulation_Stddev_Fields = {
  __typename?: 'BattBlanketInsulation_stddev_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "BattBlanketInsulation" */
export type BattBlanketInsulation_Stddev_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type BattBlanketInsulation_Stddev_Pop_Fields = {
  __typename?: 'BattBlanketInsulation_stddev_pop_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "BattBlanketInsulation" */
export type BattBlanketInsulation_Stddev_Pop_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type BattBlanketInsulation_Stddev_Samp_Fields = {
  __typename?: 'BattBlanketInsulation_stddev_samp_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "BattBlanketInsulation" */
export type BattBlanketInsulation_Stddev_Samp_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type BattBlanketInsulation_Sum_Fields = {
  __typename?: 'BattBlanketInsulation_sum_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "BattBlanketInsulation" */
export type BattBlanketInsulation_Sum_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** update columns of table "BattBlanketInsulation" */
export enum BattBlanketInsulation_Update_Column {
  /** column name */
  BattBlanketFacerMaterialId = 'BattBlanketFacerMaterialId',
  /** column name */
  BattBlanketMaterialId = 'BattBlanketMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate var_pop on columns */
export type BattBlanketInsulation_Var_Pop_Fields = {
  __typename?: 'BattBlanketInsulation_var_pop_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "BattBlanketInsulation" */
export type BattBlanketInsulation_Var_Pop_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type BattBlanketInsulation_Var_Samp_Fields = {
  __typename?: 'BattBlanketInsulation_var_samp_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "BattBlanketInsulation" */
export type BattBlanketInsulation_Var_Samp_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type BattBlanketInsulation_Variance_Fields = {
  __typename?: 'BattBlanketInsulation_variance_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "BattBlanketInsulation" */
export type BattBlanketInsulation_Variance_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "BattBlanketUsage" */
export type BattBlanketUsage = {
  __typename?: 'BattBlanketUsage';
  ThicknessMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "BattBlanketUsage" */
export type BattBlanketUsage_Aggregate = {
  __typename?: 'BattBlanketUsage_aggregate';
  aggregate?: Maybe<BattBlanketUsage_Aggregate_Fields>;
  nodes: Array<BattBlanketUsage>;
};

/** aggregate fields of "BattBlanketUsage" */
export type BattBlanketUsage_Aggregate_Fields = {
  __typename?: 'BattBlanketUsage_aggregate_fields';
  avg?: Maybe<BattBlanketUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BattBlanketUsage_Max_Fields>;
  min?: Maybe<BattBlanketUsage_Min_Fields>;
  stddev?: Maybe<BattBlanketUsage_Stddev_Fields>;
  stddev_pop?: Maybe<BattBlanketUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BattBlanketUsage_Stddev_Samp_Fields>;
  sum?: Maybe<BattBlanketUsage_Sum_Fields>;
  var_pop?: Maybe<BattBlanketUsage_Var_Pop_Fields>;
  var_samp?: Maybe<BattBlanketUsage_Var_Samp_Fields>;
  variance?: Maybe<BattBlanketUsage_Variance_Fields>;
};


/** aggregate fields of "BattBlanketUsage" */
export type BattBlanketUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<BattBlanketUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type BattBlanketUsage_Avg_Fields = {
  __typename?: 'BattBlanketUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "BattBlanketUsage". All fields are combined with a logical 'AND'. */
export type BattBlanketUsage_Bool_Exp = {
  ThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BattBlanketUsage_Bool_Exp>>;
  _not?: Maybe<BattBlanketUsage_Bool_Exp>;
  _or?: Maybe<Array<BattBlanketUsage_Bool_Exp>>;
};

/** upsert condition type for table "BattBlanketUsage" */
export type BattBlanketUsage_If_Matched = {
  match_columns?: Array<BattBlanketUsage_Insert_Match_Column>;
  update_columns?: Array<BattBlanketUsage_Update_Column>;
  where?: Maybe<BattBlanketUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BattBlanketUsage" */
export type BattBlanketUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BattBlanketUsage" */
export type BattBlanketUsage_Insert_Input = {
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "BattBlanketUsage" */
export enum BattBlanketUsage_Insert_Match_Column {
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type BattBlanketUsage_Max_Fields = {
  __typename?: 'BattBlanketUsage_max_fields';
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type BattBlanketUsage_Min_Fields = {
  __typename?: 'BattBlanketUsage_min_fields';
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "BattBlanketUsage" */
export type BattBlanketUsage_Mutation_Response = {
  __typename?: 'BattBlanketUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BattBlanketUsage>;
};

/** Ordering options when selecting data from "BattBlanketUsage". */
export type BattBlanketUsage_Order_By = {
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: BattBlanketUsage */
export type BattBlanketUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "BattBlanketUsage" */
export enum BattBlanketUsage_Select_Column {
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "BattBlanketUsage" */
export type BattBlanketUsage_Set_Input = {
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type BattBlanketUsage_Stddev_Fields = {
  __typename?: 'BattBlanketUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type BattBlanketUsage_Stddev_Pop_Fields = {
  __typename?: 'BattBlanketUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type BattBlanketUsage_Stddev_Samp_Fields = {
  __typename?: 'BattBlanketUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type BattBlanketUsage_Sum_Fields = {
  __typename?: 'BattBlanketUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "BattBlanketUsage" */
export enum BattBlanketUsage_Update_Column {
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type BattBlanketUsage_Var_Pop_Fields = {
  __typename?: 'BattBlanketUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type BattBlanketUsage_Var_Samp_Fields = {
  __typename?: 'BattBlanketUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type BattBlanketUsage_Variance_Fields = {
  __typename?: 'BattBlanketUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "BattenBar" */
export type BattenBar = {
  __typename?: 'BattenBar';
  /** An object relationship */
  C_SecurementMaterial: C_SecurementMaterial;
  ComponentId: Scalars['Int'];
  HoleSpacing: Scalars['MeasurementValue'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  SecurementMaterialId: Scalars['Int'];
  ThicknessMin: Scalars['MeasurementValue'];
  Width: Scalars['MeasurementValue'];
};

/** columns and relationships of "BattenBarUsage" */
export type BattenBarUsage = {
  __typename?: 'BattenBarUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "BattenBarUsage" */
export type BattenBarUsage_Aggregate = {
  __typename?: 'BattenBarUsage_aggregate';
  aggregate?: Maybe<BattenBarUsage_Aggregate_Fields>;
  nodes: Array<BattenBarUsage>;
};

/** aggregate fields of "BattenBarUsage" */
export type BattenBarUsage_Aggregate_Fields = {
  __typename?: 'BattenBarUsage_aggregate_fields';
  avg?: Maybe<BattenBarUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BattenBarUsage_Max_Fields>;
  min?: Maybe<BattenBarUsage_Min_Fields>;
  stddev?: Maybe<BattenBarUsage_Stddev_Fields>;
  stddev_pop?: Maybe<BattenBarUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BattenBarUsage_Stddev_Samp_Fields>;
  sum?: Maybe<BattenBarUsage_Sum_Fields>;
  var_pop?: Maybe<BattenBarUsage_Var_Pop_Fields>;
  var_samp?: Maybe<BattenBarUsage_Var_Samp_Fields>;
  variance?: Maybe<BattenBarUsage_Variance_Fields>;
};


/** aggregate fields of "BattenBarUsage" */
export type BattenBarUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<BattenBarUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type BattenBarUsage_Avg_Fields = {
  __typename?: 'BattenBarUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "BattenBarUsage". All fields are combined with a logical 'AND'. */
export type BattenBarUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BattenBarUsage_Bool_Exp>>;
  _not?: Maybe<BattenBarUsage_Bool_Exp>;
  _or?: Maybe<Array<BattenBarUsage_Bool_Exp>>;
};

/** upsert condition type for table "BattenBarUsage" */
export type BattenBarUsage_If_Matched = {
  match_columns?: Array<BattenBarUsage_Insert_Match_Column>;
  update_columns?: Array<BattenBarUsage_Update_Column>;
  where?: Maybe<BattenBarUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BattenBarUsage" */
export type BattenBarUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BattenBarUsage" */
export type BattenBarUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "BattenBarUsage" */
export enum BattenBarUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type BattenBarUsage_Max_Fields = {
  __typename?: 'BattenBarUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type BattenBarUsage_Min_Fields = {
  __typename?: 'BattenBarUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "BattenBarUsage" */
export type BattenBarUsage_Mutation_Response = {
  __typename?: 'BattenBarUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BattenBarUsage>;
};

/** Ordering options when selecting data from "BattenBarUsage". */
export type BattenBarUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: BattenBarUsage */
export type BattenBarUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "BattenBarUsage" */
export enum BattenBarUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "BattenBarUsage" */
export type BattenBarUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type BattenBarUsage_Stddev_Fields = {
  __typename?: 'BattenBarUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type BattenBarUsage_Stddev_Pop_Fields = {
  __typename?: 'BattenBarUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type BattenBarUsage_Stddev_Samp_Fields = {
  __typename?: 'BattenBarUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type BattenBarUsage_Sum_Fields = {
  __typename?: 'BattenBarUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "BattenBarUsage" */
export enum BattenBarUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type BattenBarUsage_Var_Pop_Fields = {
  __typename?: 'BattenBarUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type BattenBarUsage_Var_Samp_Fields = {
  __typename?: 'BattenBarUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type BattenBarUsage_Variance_Fields = {
  __typename?: 'BattenBarUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "BattenBar" */
export type BattenBar_Aggregate = {
  __typename?: 'BattenBar_aggregate';
  aggregate?: Maybe<BattenBar_Aggregate_Fields>;
  nodes: Array<BattenBar>;
};

/** aggregate fields of "BattenBar" */
export type BattenBar_Aggregate_Fields = {
  __typename?: 'BattenBar_aggregate_fields';
  avg?: Maybe<BattenBar_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BattenBar_Max_Fields>;
  min?: Maybe<BattenBar_Min_Fields>;
  stddev?: Maybe<BattenBar_Stddev_Fields>;
  stddev_pop?: Maybe<BattenBar_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BattenBar_Stddev_Samp_Fields>;
  sum?: Maybe<BattenBar_Sum_Fields>;
  var_pop?: Maybe<BattenBar_Var_Pop_Fields>;
  var_samp?: Maybe<BattenBar_Var_Samp_Fields>;
  variance?: Maybe<BattenBar_Variance_Fields>;
};


/** aggregate fields of "BattenBar" */
export type BattenBar_Aggregate_FieldsCountArgs = {
  column?: Maybe<BattenBar_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BattenBar" */
export type BattenBar_Aggregate_Order_By = {
  avg?: Maybe<BattenBar_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<BattenBar_Max_Order_By>;
  min?: Maybe<BattenBar_Min_Order_By>;
  stddev?: Maybe<BattenBar_Stddev_Order_By>;
  stddev_pop?: Maybe<BattenBar_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<BattenBar_Stddev_Samp_Order_By>;
  sum?: Maybe<BattenBar_Sum_Order_By>;
  var_pop?: Maybe<BattenBar_Var_Pop_Order_By>;
  var_samp?: Maybe<BattenBar_Var_Samp_Order_By>;
  variance?: Maybe<BattenBar_Variance_Order_By>;
};

/** aggregate avg on columns */
export type BattenBar_Avg_Fields = {
  __typename?: 'BattenBar_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "BattenBar" */
export type BattenBar_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "BattenBar". All fields are combined with a logical 'AND'. */
export type BattenBar_Bool_Exp = {
  C_SecurementMaterial?: Maybe<C_SecurementMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  HoleSpacing?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  SecurementMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Width?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BattenBar_Bool_Exp>>;
  _not?: Maybe<BattenBar_Bool_Exp>;
  _or?: Maybe<Array<BattenBar_Bool_Exp>>;
};

/** upsert condition type for table "BattenBar" */
export type BattenBar_If_Matched = {
  match_columns?: Array<BattenBar_Insert_Match_Column>;
  update_columns?: Array<BattenBar_Update_Column>;
  where?: Maybe<BattenBar_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BattenBar" */
export type BattenBar_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BattenBar" */
export type BattenBar_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  HoleSpacing?: Maybe<Scalars['MeasurementValue']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "BattenBar" */
export enum BattenBar_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  HoleSpacing = 'HoleSpacing',
  /** column name */
  SecurementMaterialId = 'SecurementMaterialId',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  Width = 'Width'
}

/** aggregate max on columns */
export type BattenBar_Max_Fields = {
  __typename?: 'BattenBar_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  HoleSpacing?: Maybe<Scalars['MeasurementValue']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "BattenBar" */
export type BattenBar_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  HoleSpacing?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type BattenBar_Min_Fields = {
  __typename?: 'BattenBar_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  HoleSpacing?: Maybe<Scalars['MeasurementValue']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "BattenBar" */
export type BattenBar_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  HoleSpacing?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** response of any mutation on the table "BattenBar" */
export type BattenBar_Mutation_Response = {
  __typename?: 'BattenBar_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BattenBar>;
};

/** Ordering options when selecting data from "BattenBar". */
export type BattenBar_Order_By = {
  C_SecurementMaterial?: Maybe<C_SecurementMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  HoleSpacing?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** primary key columns input for table: BattenBar */
export type BattenBar_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "BattenBar" */
export enum BattenBar_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  HoleSpacing = 'HoleSpacing',
  /** column name */
  SecurementMaterialId = 'SecurementMaterialId',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  Width = 'Width'
}

/** input type for updating data in table "BattenBar" */
export type BattenBar_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  HoleSpacing?: Maybe<Scalars['MeasurementValue']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type BattenBar_Stddev_Fields = {
  __typename?: 'BattenBar_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "BattenBar" */
export type BattenBar_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type BattenBar_Stddev_Pop_Fields = {
  __typename?: 'BattenBar_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "BattenBar" */
export type BattenBar_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type BattenBar_Stddev_Samp_Fields = {
  __typename?: 'BattenBar_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "BattenBar" */
export type BattenBar_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type BattenBar_Sum_Fields = {
  __typename?: 'BattenBar_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "BattenBar" */
export type BattenBar_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** update columns of table "BattenBar" */
export enum BattenBar_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  HoleSpacing = 'HoleSpacing',
  /** column name */
  SecurementMaterialId = 'SecurementMaterialId',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  Width = 'Width'
}

/** aggregate var_pop on columns */
export type BattenBar_Var_Pop_Fields = {
  __typename?: 'BattenBar_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "BattenBar" */
export type BattenBar_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type BattenBar_Var_Samp_Fields = {
  __typename?: 'BattenBar_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "BattenBar" */
export type BattenBar_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type BattenBar_Variance_Fields = {
  __typename?: 'BattenBar_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "BattenBar" */
export type BattenBar_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "BoardSizes" */
export type BoardSizes = {
  __typename?: 'BoardSizes';
  BoardSize?: Maybe<Scalars['String']>;
  InsBoardSizeId: Scalars['Int'];
  IsMetric: Scalars['Boolean'];
};

/** aggregated selection of "BoardSizes" */
export type BoardSizes_Aggregate = {
  __typename?: 'BoardSizes_aggregate';
  aggregate?: Maybe<BoardSizes_Aggregate_Fields>;
  nodes: Array<BoardSizes>;
};

/** aggregate fields of "BoardSizes" */
export type BoardSizes_Aggregate_Fields = {
  __typename?: 'BoardSizes_aggregate_fields';
  avg?: Maybe<BoardSizes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BoardSizes_Max_Fields>;
  min?: Maybe<BoardSizes_Min_Fields>;
  stddev?: Maybe<BoardSizes_Stddev_Fields>;
  stddev_pop?: Maybe<BoardSizes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BoardSizes_Stddev_Samp_Fields>;
  sum?: Maybe<BoardSizes_Sum_Fields>;
  var_pop?: Maybe<BoardSizes_Var_Pop_Fields>;
  var_samp?: Maybe<BoardSizes_Var_Samp_Fields>;
  variance?: Maybe<BoardSizes_Variance_Fields>;
};


/** aggregate fields of "BoardSizes" */
export type BoardSizes_Aggregate_FieldsCountArgs = {
  column?: Maybe<BoardSizes_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type BoardSizes_Avg_Fields = {
  __typename?: 'BoardSizes_avg_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "BoardSizes". All fields are combined with a logical 'AND'. */
export type BoardSizes_Bool_Exp = {
  BoardSize?: Maybe<String_Mssql_Comparison_Exp>;
  InsBoardSizeId?: Maybe<Int_Mssql_Comparison_Exp>;
  IsMetric?: Maybe<Boolean_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BoardSizes_Bool_Exp>>;
  _not?: Maybe<BoardSizes_Bool_Exp>;
  _or?: Maybe<Array<BoardSizes_Bool_Exp>>;
};

/** upsert condition type for table "BoardSizes" */
export type BoardSizes_If_Matched = {
  match_columns?: Array<BoardSizes_Insert_Match_Column>;
  update_columns?: Array<BoardSizes_Update_Column>;
  where?: Maybe<BoardSizes_Bool_Exp>;
};

/** input type for inserting data into table "BoardSizes" */
export type BoardSizes_Insert_Input = {
  BoardSize?: Maybe<Scalars['String']>;
  IsMetric?: Maybe<Scalars['Boolean']>;
};

/** select match_columns of table "BoardSizes" */
export enum BoardSizes_Insert_Match_Column {
  /** column name */
  BoardSize = 'BoardSize',
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId',
  /** column name */
  IsMetric = 'IsMetric'
}

/** aggregate max on columns */
export type BoardSizes_Max_Fields = {
  __typename?: 'BoardSizes_max_fields';
  BoardSize?: Maybe<Scalars['String']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type BoardSizes_Min_Fields = {
  __typename?: 'BoardSizes_min_fields';
  BoardSize?: Maybe<Scalars['String']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "BoardSizes" */
export type BoardSizes_Mutation_Response = {
  __typename?: 'BoardSizes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BoardSizes>;
};

/** Ordering options when selecting data from "BoardSizes". */
export type BoardSizes_Order_By = {
  BoardSize?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
  IsMetric?: Maybe<Order_By>;
};

/** select columns of table "BoardSizes" */
export enum BoardSizes_Select_Column {
  /** column name */
  BoardSize = 'BoardSize',
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId',
  /** column name */
  IsMetric = 'IsMetric'
}

/** input type for updating data in table "BoardSizes" */
export type BoardSizes_Set_Input = {
  BoardSize?: Maybe<Scalars['String']>;
  IsMetric?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type BoardSizes_Stddev_Fields = {
  __typename?: 'BoardSizes_stddev_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type BoardSizes_Stddev_Pop_Fields = {
  __typename?: 'BoardSizes_stddev_pop_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type BoardSizes_Stddev_Samp_Fields = {
  __typename?: 'BoardSizes_stddev_samp_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type BoardSizes_Sum_Fields = {
  __typename?: 'BoardSizes_sum_fields';
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "BoardSizes" */
export enum BoardSizes_Update_Column {
  /** column name */
  BoardSize = 'BoardSize',
  /** column name */
  IsMetric = 'IsMetric'
}

/** aggregate var_pop on columns */
export type BoardSizes_Var_Pop_Fields = {
  __typename?: 'BoardSizes_var_pop_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type BoardSizes_Var_Samp_Fields = {
  __typename?: 'BoardSizes_var_samp_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type BoardSizes_Variance_Fields = {
  __typename?: 'BoardSizes_variance_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "BoardStock" */
export type BoardStock = {
  __typename?: 'BoardStock';
  BoardProfileId: Scalars['Int'];
  /** An array relationship */
  BoardStockBoardSizes: Array<BoardStockBoardSizes>;
  /** An aggregate relationship */
  BoardStockBoardSizes_aggregate: BoardStockBoardSizes_Aggregate;
  /** An array relationship */
  BoardStockFacerBottoms: Array<BoardStockFacerBottom>;
  /** An aggregate relationship */
  BoardStockFacerBottoms_aggregate: BoardStockFacerBottom_Aggregate;
  /** An array relationship */
  BoardStockFacerTops: Array<BoardStockFacerTop>;
  /** An aggregate relationship */
  BoardStockFacerTops_aggregate: BoardStockFacerTop_Aggregate;
  BoardStockMaterialId: Scalars['Int'];
  /** An object relationship */
  C_BoardProfile: C_BoardProfile;
  /** An object relationship */
  C_BoardStockMaterial: C_BoardStockMaterial;
  ComponentId: Scalars['Int'];
  DensityMin: Scalars['MeasurementValue'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  ThicknessMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
};


/** columns and relationships of "BoardStock" */
export type BoardStockBoardStockBoardSizesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockBoardSizes_Order_By>>;
  where?: Maybe<BoardStockBoardSizes_Bool_Exp>;
};


/** columns and relationships of "BoardStock" */
export type BoardStockBoardStockBoardSizes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockBoardSizes_Order_By>>;
  where?: Maybe<BoardStockBoardSizes_Bool_Exp>;
};


/** columns and relationships of "BoardStock" */
export type BoardStockBoardStockFacerBottomsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerBottom_Order_By>>;
  where?: Maybe<BoardStockFacerBottom_Bool_Exp>;
};


/** columns and relationships of "BoardStock" */
export type BoardStockBoardStockFacerBottoms_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerBottom_Order_By>>;
  where?: Maybe<BoardStockFacerBottom_Bool_Exp>;
};


/** columns and relationships of "BoardStock" */
export type BoardStockBoardStockFacerTopsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerTop_Order_By>>;
  where?: Maybe<BoardStockFacerTop_Bool_Exp>;
};


/** columns and relationships of "BoardStock" */
export type BoardStockBoardStockFacerTops_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerTop_Order_By>>;
  where?: Maybe<BoardStockFacerTop_Bool_Exp>;
};

/** columns and relationships of "BoardStockBoardSizes" */
export type BoardStockBoardSizes = {
  __typename?: 'BoardStockBoardSizes';
  /** An object relationship */
  BoardStock: BoardStock;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  InsBoardSize: InsBoardSize;
  InsBoardSizeId: Scalars['Int'];
};

/** aggregated selection of "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Aggregate = {
  __typename?: 'BoardStockBoardSizes_aggregate';
  aggregate?: Maybe<BoardStockBoardSizes_Aggregate_Fields>;
  nodes: Array<BoardStockBoardSizes>;
};

/** aggregate fields of "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Aggregate_Fields = {
  __typename?: 'BoardStockBoardSizes_aggregate_fields';
  avg?: Maybe<BoardStockBoardSizes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BoardStockBoardSizes_Max_Fields>;
  min?: Maybe<BoardStockBoardSizes_Min_Fields>;
  stddev?: Maybe<BoardStockBoardSizes_Stddev_Fields>;
  stddev_pop?: Maybe<BoardStockBoardSizes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BoardStockBoardSizes_Stddev_Samp_Fields>;
  sum?: Maybe<BoardStockBoardSizes_Sum_Fields>;
  var_pop?: Maybe<BoardStockBoardSizes_Var_Pop_Fields>;
  var_samp?: Maybe<BoardStockBoardSizes_Var_Samp_Fields>;
  variance?: Maybe<BoardStockBoardSizes_Variance_Fields>;
};


/** aggregate fields of "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Aggregate_FieldsCountArgs = {
  column?: Maybe<BoardStockBoardSizes_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Aggregate_Order_By = {
  avg?: Maybe<BoardStockBoardSizes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<BoardStockBoardSizes_Max_Order_By>;
  min?: Maybe<BoardStockBoardSizes_Min_Order_By>;
  stddev?: Maybe<BoardStockBoardSizes_Stddev_Order_By>;
  stddev_pop?: Maybe<BoardStockBoardSizes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<BoardStockBoardSizes_Stddev_Samp_Order_By>;
  sum?: Maybe<BoardStockBoardSizes_Sum_Order_By>;
  var_pop?: Maybe<BoardStockBoardSizes_Var_Pop_Order_By>;
  var_samp?: Maybe<BoardStockBoardSizes_Var_Samp_Order_By>;
  variance?: Maybe<BoardStockBoardSizes_Variance_Order_By>;
};

/** aggregate avg on columns */
export type BoardStockBoardSizes_Avg_Fields = {
  __typename?: 'BoardStockBoardSizes_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "BoardStockBoardSizes". All fields are combined with a logical 'AND'. */
export type BoardStockBoardSizes_Bool_Exp = {
  BoardStock?: Maybe<BoardStock_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  InsBoardSize?: Maybe<InsBoardSize_Bool_Exp>;
  InsBoardSizeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BoardStockBoardSizes_Bool_Exp>>;
  _not?: Maybe<BoardStockBoardSizes_Bool_Exp>;
  _or?: Maybe<Array<BoardStockBoardSizes_Bool_Exp>>;
};

/** upsert condition type for table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_If_Matched = {
  match_columns?: Array<BoardStockBoardSizes_Insert_Match_Column>;
  update_columns?: Array<BoardStockBoardSizes_Update_Column>;
  where?: Maybe<BoardStockBoardSizes_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "BoardStockBoardSizes" */
export enum BoardStockBoardSizes_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId'
}

/** aggregate max on columns */
export type BoardStockBoardSizes_Max_Fields = {
  __typename?: 'BoardStockBoardSizes_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type BoardStockBoardSizes_Min_Fields = {
  __typename?: 'BoardStockBoardSizes_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** response of any mutation on the table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Mutation_Response = {
  __typename?: 'BoardStockBoardSizes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BoardStockBoardSizes>;
};

/** Ordering options when selecting data from "BoardStockBoardSizes". */
export type BoardStockBoardSizes_Order_By = {
  BoardStock?: Maybe<BoardStock_Order_By>;
  ComponentId?: Maybe<Order_By>;
  InsBoardSize?: Maybe<InsBoardSize_Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** primary key columns input for table: BoardStockBoardSizes */
export type BoardStockBoardSizes_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  InsBoardSizeId: Scalars['Int'];
};

/** select columns of table "BoardStockBoardSizes" */
export enum BoardStockBoardSizes_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId'
}

/** input type for updating data in table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type BoardStockBoardSizes_Stddev_Fields = {
  __typename?: 'BoardStockBoardSizes_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type BoardStockBoardSizes_Stddev_Pop_Fields = {
  __typename?: 'BoardStockBoardSizes_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type BoardStockBoardSizes_Stddev_Samp_Fields = {
  __typename?: 'BoardStockBoardSizes_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type BoardStockBoardSizes_Sum_Fields = {
  __typename?: 'BoardStockBoardSizes_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** update columns of table "BoardStockBoardSizes" */
export enum BoardStockBoardSizes_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId'
}

/** aggregate var_pop on columns */
export type BoardStockBoardSizes_Var_Pop_Fields = {
  __typename?: 'BoardStockBoardSizes_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type BoardStockBoardSizes_Var_Samp_Fields = {
  __typename?: 'BoardStockBoardSizes_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type BoardStockBoardSizes_Variance_Fields = {
  __typename?: 'BoardStockBoardSizes_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "BoardStockBoardSizes" */
export type BoardStockBoardSizes_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** columns and relationships of "BoardStockFacerBottom" */
export type BoardStockFacerBottom = {
  __typename?: 'BoardStockFacerBottom';
  /** An object relationship */
  BoardStock: BoardStock;
  /** An object relationship */
  C_Facer: C_Facer;
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};

/** aggregated selection of "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Aggregate = {
  __typename?: 'BoardStockFacerBottom_aggregate';
  aggregate?: Maybe<BoardStockFacerBottom_Aggregate_Fields>;
  nodes: Array<BoardStockFacerBottom>;
};

/** aggregate fields of "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Aggregate_Fields = {
  __typename?: 'BoardStockFacerBottom_aggregate_fields';
  avg?: Maybe<BoardStockFacerBottom_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BoardStockFacerBottom_Max_Fields>;
  min?: Maybe<BoardStockFacerBottom_Min_Fields>;
  stddev?: Maybe<BoardStockFacerBottom_Stddev_Fields>;
  stddev_pop?: Maybe<BoardStockFacerBottom_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BoardStockFacerBottom_Stddev_Samp_Fields>;
  sum?: Maybe<BoardStockFacerBottom_Sum_Fields>;
  var_pop?: Maybe<BoardStockFacerBottom_Var_Pop_Fields>;
  var_samp?: Maybe<BoardStockFacerBottom_Var_Samp_Fields>;
  variance?: Maybe<BoardStockFacerBottom_Variance_Fields>;
};


/** aggregate fields of "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Aggregate_FieldsCountArgs = {
  column?: Maybe<BoardStockFacerBottom_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Aggregate_Order_By = {
  avg?: Maybe<BoardStockFacerBottom_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<BoardStockFacerBottom_Max_Order_By>;
  min?: Maybe<BoardStockFacerBottom_Min_Order_By>;
  stddev?: Maybe<BoardStockFacerBottom_Stddev_Order_By>;
  stddev_pop?: Maybe<BoardStockFacerBottom_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<BoardStockFacerBottom_Stddev_Samp_Order_By>;
  sum?: Maybe<BoardStockFacerBottom_Sum_Order_By>;
  var_pop?: Maybe<BoardStockFacerBottom_Var_Pop_Order_By>;
  var_samp?: Maybe<BoardStockFacerBottom_Var_Samp_Order_By>;
  variance?: Maybe<BoardStockFacerBottom_Variance_Order_By>;
};

/** aggregate avg on columns */
export type BoardStockFacerBottom_Avg_Fields = {
  __typename?: 'BoardStockFacerBottom_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "BoardStockFacerBottom". All fields are combined with a logical 'AND'. */
export type BoardStockFacerBottom_Bool_Exp = {
  BoardStock?: Maybe<BoardStock_Bool_Exp>;
  C_Facer?: Maybe<C_Facer_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  FacerId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BoardStockFacerBottom_Bool_Exp>>;
  _not?: Maybe<BoardStockFacerBottom_Bool_Exp>;
  _or?: Maybe<Array<BoardStockFacerBottom_Bool_Exp>>;
};

/** upsert condition type for table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_If_Matched = {
  match_columns?: Array<BoardStockFacerBottom_Insert_Match_Column>;
  update_columns?: Array<BoardStockFacerBottom_Update_Column>;
  where?: Maybe<BoardStockFacerBottom_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "BoardStockFacerBottom" */
export enum BoardStockFacerBottom_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** aggregate max on columns */
export type BoardStockFacerBottom_Max_Fields = {
  __typename?: 'BoardStockFacerBottom_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type BoardStockFacerBottom_Min_Fields = {
  __typename?: 'BoardStockFacerBottom_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** response of any mutation on the table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Mutation_Response = {
  __typename?: 'BoardStockFacerBottom_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BoardStockFacerBottom>;
};

/** Ordering options when selecting data from "BoardStockFacerBottom". */
export type BoardStockFacerBottom_Order_By = {
  BoardStock?: Maybe<BoardStock_Order_By>;
  C_Facer?: Maybe<C_Facer_Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** primary key columns input for table: BoardStockFacerBottom */
export type BoardStockFacerBottom_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};

/** select columns of table "BoardStockFacerBottom" */
export enum BoardStockFacerBottom_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** input type for updating data in table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type BoardStockFacerBottom_Stddev_Fields = {
  __typename?: 'BoardStockFacerBottom_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type BoardStockFacerBottom_Stddev_Pop_Fields = {
  __typename?: 'BoardStockFacerBottom_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type BoardStockFacerBottom_Stddev_Samp_Fields = {
  __typename?: 'BoardStockFacerBottom_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type BoardStockFacerBottom_Sum_Fields = {
  __typename?: 'BoardStockFacerBottom_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** update columns of table "BoardStockFacerBottom" */
export enum BoardStockFacerBottom_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** aggregate var_pop on columns */
export type BoardStockFacerBottom_Var_Pop_Fields = {
  __typename?: 'BoardStockFacerBottom_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type BoardStockFacerBottom_Var_Samp_Fields = {
  __typename?: 'BoardStockFacerBottom_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type BoardStockFacerBottom_Variance_Fields = {
  __typename?: 'BoardStockFacerBottom_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "BoardStockFacerBottom" */
export type BoardStockFacerBottom_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** columns and relationships of "BoardStockFacerTop" */
export type BoardStockFacerTop = {
  __typename?: 'BoardStockFacerTop';
  /** An object relationship */
  BoardStock: BoardStock;
  /** An object relationship */
  C_Facer: C_Facer;
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};

/** aggregated selection of "BoardStockFacerTop" */
export type BoardStockFacerTop_Aggregate = {
  __typename?: 'BoardStockFacerTop_aggregate';
  aggregate?: Maybe<BoardStockFacerTop_Aggregate_Fields>;
  nodes: Array<BoardStockFacerTop>;
};

/** aggregate fields of "BoardStockFacerTop" */
export type BoardStockFacerTop_Aggregate_Fields = {
  __typename?: 'BoardStockFacerTop_aggregate_fields';
  avg?: Maybe<BoardStockFacerTop_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BoardStockFacerTop_Max_Fields>;
  min?: Maybe<BoardStockFacerTop_Min_Fields>;
  stddev?: Maybe<BoardStockFacerTop_Stddev_Fields>;
  stddev_pop?: Maybe<BoardStockFacerTop_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BoardStockFacerTop_Stddev_Samp_Fields>;
  sum?: Maybe<BoardStockFacerTop_Sum_Fields>;
  var_pop?: Maybe<BoardStockFacerTop_Var_Pop_Fields>;
  var_samp?: Maybe<BoardStockFacerTop_Var_Samp_Fields>;
  variance?: Maybe<BoardStockFacerTop_Variance_Fields>;
};


/** aggregate fields of "BoardStockFacerTop" */
export type BoardStockFacerTop_Aggregate_FieldsCountArgs = {
  column?: Maybe<BoardStockFacerTop_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BoardStockFacerTop" */
export type BoardStockFacerTop_Aggregate_Order_By = {
  avg?: Maybe<BoardStockFacerTop_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<BoardStockFacerTop_Max_Order_By>;
  min?: Maybe<BoardStockFacerTop_Min_Order_By>;
  stddev?: Maybe<BoardStockFacerTop_Stddev_Order_By>;
  stddev_pop?: Maybe<BoardStockFacerTop_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<BoardStockFacerTop_Stddev_Samp_Order_By>;
  sum?: Maybe<BoardStockFacerTop_Sum_Order_By>;
  var_pop?: Maybe<BoardStockFacerTop_Var_Pop_Order_By>;
  var_samp?: Maybe<BoardStockFacerTop_Var_Samp_Order_By>;
  variance?: Maybe<BoardStockFacerTop_Variance_Order_By>;
};

/** aggregate avg on columns */
export type BoardStockFacerTop_Avg_Fields = {
  __typename?: 'BoardStockFacerTop_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "BoardStockFacerTop" */
export type BoardStockFacerTop_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "BoardStockFacerTop". All fields are combined with a logical 'AND'. */
export type BoardStockFacerTop_Bool_Exp = {
  BoardStock?: Maybe<BoardStock_Bool_Exp>;
  C_Facer?: Maybe<C_Facer_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  FacerId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BoardStockFacerTop_Bool_Exp>>;
  _not?: Maybe<BoardStockFacerTop_Bool_Exp>;
  _or?: Maybe<Array<BoardStockFacerTop_Bool_Exp>>;
};

/** upsert condition type for table "BoardStockFacerTop" */
export type BoardStockFacerTop_If_Matched = {
  match_columns?: Array<BoardStockFacerTop_Insert_Match_Column>;
  update_columns?: Array<BoardStockFacerTop_Update_Column>;
  where?: Maybe<BoardStockFacerTop_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BoardStockFacerTop" */
export type BoardStockFacerTop_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BoardStockFacerTop" */
export type BoardStockFacerTop_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "BoardStockFacerTop" */
export enum BoardStockFacerTop_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** aggregate max on columns */
export type BoardStockFacerTop_Max_Fields = {
  __typename?: 'BoardStockFacerTop_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "BoardStockFacerTop" */
export type BoardStockFacerTop_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type BoardStockFacerTop_Min_Fields = {
  __typename?: 'BoardStockFacerTop_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "BoardStockFacerTop" */
export type BoardStockFacerTop_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** response of any mutation on the table "BoardStockFacerTop" */
export type BoardStockFacerTop_Mutation_Response = {
  __typename?: 'BoardStockFacerTop_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BoardStockFacerTop>;
};

/** Ordering options when selecting data from "BoardStockFacerTop". */
export type BoardStockFacerTop_Order_By = {
  BoardStock?: Maybe<BoardStock_Order_By>;
  C_Facer?: Maybe<C_Facer_Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** primary key columns input for table: BoardStockFacerTop */
export type BoardStockFacerTop_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};

/** select columns of table "BoardStockFacerTop" */
export enum BoardStockFacerTop_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** input type for updating data in table "BoardStockFacerTop" */
export type BoardStockFacerTop_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type BoardStockFacerTop_Stddev_Fields = {
  __typename?: 'BoardStockFacerTop_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "BoardStockFacerTop" */
export type BoardStockFacerTop_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type BoardStockFacerTop_Stddev_Pop_Fields = {
  __typename?: 'BoardStockFacerTop_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "BoardStockFacerTop" */
export type BoardStockFacerTop_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type BoardStockFacerTop_Stddev_Samp_Fields = {
  __typename?: 'BoardStockFacerTop_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "BoardStockFacerTop" */
export type BoardStockFacerTop_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type BoardStockFacerTop_Sum_Fields = {
  __typename?: 'BoardStockFacerTop_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "BoardStockFacerTop" */
export type BoardStockFacerTop_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** update columns of table "BoardStockFacerTop" */
export enum BoardStockFacerTop_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** aggregate var_pop on columns */
export type BoardStockFacerTop_Var_Pop_Fields = {
  __typename?: 'BoardStockFacerTop_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "BoardStockFacerTop" */
export type BoardStockFacerTop_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type BoardStockFacerTop_Var_Samp_Fields = {
  __typename?: 'BoardStockFacerTop_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "BoardStockFacerTop" */
export type BoardStockFacerTop_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type BoardStockFacerTop_Variance_Fields = {
  __typename?: 'BoardStockFacerTop_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "BoardStockFacerTop" */
export type BoardStockFacerTop_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** columns and relationships of "BoardStockUsage" */
export type BoardStockUsage = {
  __typename?: 'BoardStockUsage';
  /** An object relationship */
  InsBoardSize: InsBoardSize;
  InsBoardSizeId: Scalars['Int'];
  ThicknessMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "BoardStockUsage" */
export type BoardStockUsage_Aggregate = {
  __typename?: 'BoardStockUsage_aggregate';
  aggregate?: Maybe<BoardStockUsage_Aggregate_Fields>;
  nodes: Array<BoardStockUsage>;
};

/** aggregate fields of "BoardStockUsage" */
export type BoardStockUsage_Aggregate_Fields = {
  __typename?: 'BoardStockUsage_aggregate_fields';
  avg?: Maybe<BoardStockUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BoardStockUsage_Max_Fields>;
  min?: Maybe<BoardStockUsage_Min_Fields>;
  stddev?: Maybe<BoardStockUsage_Stddev_Fields>;
  stddev_pop?: Maybe<BoardStockUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BoardStockUsage_Stddev_Samp_Fields>;
  sum?: Maybe<BoardStockUsage_Sum_Fields>;
  var_pop?: Maybe<BoardStockUsage_Var_Pop_Fields>;
  var_samp?: Maybe<BoardStockUsage_Var_Samp_Fields>;
  variance?: Maybe<BoardStockUsage_Variance_Fields>;
};


/** aggregate fields of "BoardStockUsage" */
export type BoardStockUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<BoardStockUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BoardStockUsage" */
export type BoardStockUsage_Aggregate_Order_By = {
  avg?: Maybe<BoardStockUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<BoardStockUsage_Max_Order_By>;
  min?: Maybe<BoardStockUsage_Min_Order_By>;
  stddev?: Maybe<BoardStockUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<BoardStockUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<BoardStockUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<BoardStockUsage_Sum_Order_By>;
  var_pop?: Maybe<BoardStockUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<BoardStockUsage_Var_Samp_Order_By>;
  variance?: Maybe<BoardStockUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type BoardStockUsage_Avg_Fields = {
  __typename?: 'BoardStockUsage_avg_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "BoardStockUsage" */
export type BoardStockUsage_Avg_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "BoardStockUsage". All fields are combined with a logical 'AND'. */
export type BoardStockUsage_Bool_Exp = {
  InsBoardSize?: Maybe<InsBoardSize_Bool_Exp>;
  InsBoardSizeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BoardStockUsage_Bool_Exp>>;
  _not?: Maybe<BoardStockUsage_Bool_Exp>;
  _or?: Maybe<Array<BoardStockUsage_Bool_Exp>>;
};

/** upsert condition type for table "BoardStockUsage" */
export type BoardStockUsage_If_Matched = {
  match_columns?: Array<BoardStockUsage_Insert_Match_Column>;
  update_columns?: Array<BoardStockUsage_Update_Column>;
  where?: Maybe<BoardStockUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BoardStockUsage" */
export type BoardStockUsage_Inc_Input = {
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BoardStockUsage" */
export type BoardStockUsage_Insert_Input = {
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "BoardStockUsage" */
export enum BoardStockUsage_Insert_Match_Column {
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type BoardStockUsage_Max_Fields = {
  __typename?: 'BoardStockUsage_max_fields';
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "BoardStockUsage" */
export type BoardStockUsage_Max_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type BoardStockUsage_Min_Fields = {
  __typename?: 'BoardStockUsage_min_fields';
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "BoardStockUsage" */
export type BoardStockUsage_Min_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "BoardStockUsage" */
export type BoardStockUsage_Mutation_Response = {
  __typename?: 'BoardStockUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BoardStockUsage>;
};

/** Ordering options when selecting data from "BoardStockUsage". */
export type BoardStockUsage_Order_By = {
  InsBoardSize?: Maybe<InsBoardSize_Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: BoardStockUsage */
export type BoardStockUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "BoardStockUsage" */
export enum BoardStockUsage_Select_Column {
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "BoardStockUsage" */
export type BoardStockUsage_Set_Input = {
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type BoardStockUsage_Stddev_Fields = {
  __typename?: 'BoardStockUsage_stddev_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "BoardStockUsage" */
export type BoardStockUsage_Stddev_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type BoardStockUsage_Stddev_Pop_Fields = {
  __typename?: 'BoardStockUsage_stddev_pop_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "BoardStockUsage" */
export type BoardStockUsage_Stddev_Pop_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type BoardStockUsage_Stddev_Samp_Fields = {
  __typename?: 'BoardStockUsage_stddev_samp_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "BoardStockUsage" */
export type BoardStockUsage_Stddev_Samp_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type BoardStockUsage_Sum_Fields = {
  __typename?: 'BoardStockUsage_sum_fields';
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "BoardStockUsage" */
export type BoardStockUsage_Sum_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "BoardStockUsage" */
export enum BoardStockUsage_Update_Column {
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type BoardStockUsage_Var_Pop_Fields = {
  __typename?: 'BoardStockUsage_var_pop_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "BoardStockUsage" */
export type BoardStockUsage_Var_Pop_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type BoardStockUsage_Var_Samp_Fields = {
  __typename?: 'BoardStockUsage_var_samp_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "BoardStockUsage" */
export type BoardStockUsage_Var_Samp_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type BoardStockUsage_Variance_Fields = {
  __typename?: 'BoardStockUsage_variance_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "BoardStockUsage" */
export type BoardStockUsage_Variance_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregated selection of "BoardStock" */
export type BoardStock_Aggregate = {
  __typename?: 'BoardStock_aggregate';
  aggregate?: Maybe<BoardStock_Aggregate_Fields>;
  nodes: Array<BoardStock>;
};

/** aggregate fields of "BoardStock" */
export type BoardStock_Aggregate_Fields = {
  __typename?: 'BoardStock_aggregate_fields';
  avg?: Maybe<BoardStock_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BoardStock_Max_Fields>;
  min?: Maybe<BoardStock_Min_Fields>;
  stddev?: Maybe<BoardStock_Stddev_Fields>;
  stddev_pop?: Maybe<BoardStock_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BoardStock_Stddev_Samp_Fields>;
  sum?: Maybe<BoardStock_Sum_Fields>;
  var_pop?: Maybe<BoardStock_Var_Pop_Fields>;
  var_samp?: Maybe<BoardStock_Var_Samp_Fields>;
  variance?: Maybe<BoardStock_Variance_Fields>;
};


/** aggregate fields of "BoardStock" */
export type BoardStock_Aggregate_FieldsCountArgs = {
  column?: Maybe<BoardStock_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BoardStock" */
export type BoardStock_Aggregate_Order_By = {
  avg?: Maybe<BoardStock_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<BoardStock_Max_Order_By>;
  min?: Maybe<BoardStock_Min_Order_By>;
  stddev?: Maybe<BoardStock_Stddev_Order_By>;
  stddev_pop?: Maybe<BoardStock_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<BoardStock_Stddev_Samp_Order_By>;
  sum?: Maybe<BoardStock_Sum_Order_By>;
  var_pop?: Maybe<BoardStock_Var_Pop_Order_By>;
  var_samp?: Maybe<BoardStock_Var_Samp_Order_By>;
  variance?: Maybe<BoardStock_Variance_Order_By>;
};

/** aggregate avg on columns */
export type BoardStock_Avg_Fields = {
  __typename?: 'BoardStock_avg_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "BoardStock" */
export type BoardStock_Avg_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "BoardStock". All fields are combined with a logical 'AND'. */
export type BoardStock_Bool_Exp = {
  BoardProfileId?: Maybe<Int_Mssql_Comparison_Exp>;
  BoardStockBoardSizes?: Maybe<BoardStockBoardSizes_Bool_Exp>;
  BoardStockFacerBottoms?: Maybe<BoardStockFacerBottom_Bool_Exp>;
  BoardStockFacerTops?: Maybe<BoardStockFacerTop_Bool_Exp>;
  BoardStockMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_BoardProfile?: Maybe<C_BoardProfile_Bool_Exp>;
  C_BoardStockMaterial?: Maybe<C_BoardStockMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  DensityMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  ThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BoardStock_Bool_Exp>>;
  _not?: Maybe<BoardStock_Bool_Exp>;
  _or?: Maybe<Array<BoardStock_Bool_Exp>>;
};

/** upsert condition type for table "BoardStock" */
export type BoardStock_If_Matched = {
  match_columns?: Array<BoardStock_Insert_Match_Column>;
  update_columns?: Array<BoardStock_Update_Column>;
  where?: Maybe<BoardStock_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BoardStock" */
export type BoardStock_Inc_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BoardStock" */
export type BoardStock_Insert_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "BoardStock" */
export enum BoardStock_Insert_Match_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  BoardStockMaterialId = 'BoardStockMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate max on columns */
export type BoardStock_Max_Fields = {
  __typename?: 'BoardStock_max_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "BoardStock" */
export type BoardStock_Max_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type BoardStock_Min_Fields = {
  __typename?: 'BoardStock_min_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "BoardStock" */
export type BoardStock_Min_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** response of any mutation on the table "BoardStock" */
export type BoardStock_Mutation_Response = {
  __typename?: 'BoardStock_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BoardStock>;
};

/** Ordering options when selecting data from "BoardStock". */
export type BoardStock_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockBoardSizes_aggregate?: Maybe<BoardStockBoardSizes_Aggregate_Order_By>;
  BoardStockFacerBottoms_aggregate?: Maybe<BoardStockFacerBottom_Aggregate_Order_By>;
  BoardStockFacerTops_aggregate?: Maybe<BoardStockFacerTop_Aggregate_Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  C_BoardProfile?: Maybe<C_BoardProfile_Order_By>;
  C_BoardStockMaterial?: Maybe<C_BoardStockMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** primary key columns input for table: BoardStock */
export type BoardStock_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "BoardStock" */
export enum BoardStock_Select_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  BoardStockMaterialId = 'BoardStockMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** input type for updating data in table "BoardStock" */
export type BoardStock_Set_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type BoardStock_Stddev_Fields = {
  __typename?: 'BoardStock_stddev_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "BoardStock" */
export type BoardStock_Stddev_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type BoardStock_Stddev_Pop_Fields = {
  __typename?: 'BoardStock_stddev_pop_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "BoardStock" */
export type BoardStock_Stddev_Pop_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type BoardStock_Stddev_Samp_Fields = {
  __typename?: 'BoardStock_stddev_samp_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "BoardStock" */
export type BoardStock_Stddev_Samp_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type BoardStock_Sum_Fields = {
  __typename?: 'BoardStock_sum_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "BoardStock" */
export type BoardStock_Sum_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** update columns of table "BoardStock" */
export enum BoardStock_Update_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  BoardStockMaterialId = 'BoardStockMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate var_pop on columns */
export type BoardStock_Var_Pop_Fields = {
  __typename?: 'BoardStock_var_pop_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "BoardStock" */
export type BoardStock_Var_Pop_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type BoardStock_Var_Samp_Fields = {
  __typename?: 'BoardStock_var_samp_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "BoardStock" */
export type BoardStock_Var_Samp_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type BoardStock_Variance_Fields = {
  __typename?: 'BoardStock_variance_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "BoardStock" */
export type BoardStock_Variance_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Mssql_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "BroadcastAlert" */
export type BroadcastAlert = {
  __typename?: 'BroadcastAlert';
  AlertDate: Scalars['datetime'];
  ApprovalsApplicationId: Scalars['Int'];
  BroadcastAlertId: Scalars['Int'];
  ExpirationDate: Scalars['datetime'];
  Message: Scalars['String'];
  Subject: Scalars['String'];
};

/** aggregated selection of "BroadcastAlert" */
export type BroadcastAlert_Aggregate = {
  __typename?: 'BroadcastAlert_aggregate';
  aggregate?: Maybe<BroadcastAlert_Aggregate_Fields>;
  nodes: Array<BroadcastAlert>;
};

/** aggregate fields of "BroadcastAlert" */
export type BroadcastAlert_Aggregate_Fields = {
  __typename?: 'BroadcastAlert_aggregate_fields';
  avg?: Maybe<BroadcastAlert_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BroadcastAlert_Max_Fields>;
  min?: Maybe<BroadcastAlert_Min_Fields>;
  stddev?: Maybe<BroadcastAlert_Stddev_Fields>;
  stddev_pop?: Maybe<BroadcastAlert_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BroadcastAlert_Stddev_Samp_Fields>;
  sum?: Maybe<BroadcastAlert_Sum_Fields>;
  var_pop?: Maybe<BroadcastAlert_Var_Pop_Fields>;
  var_samp?: Maybe<BroadcastAlert_Var_Samp_Fields>;
  variance?: Maybe<BroadcastAlert_Variance_Fields>;
};


/** aggregate fields of "BroadcastAlert" */
export type BroadcastAlert_Aggregate_FieldsCountArgs = {
  column?: Maybe<BroadcastAlert_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type BroadcastAlert_Avg_Fields = {
  __typename?: 'BroadcastAlert_avg_fields';
  ApprovalsApplicationId?: Maybe<Scalars['Float']>;
  BroadcastAlertId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "BroadcastAlert". All fields are combined with a logical 'AND'. */
export type BroadcastAlert_Bool_Exp = {
  AlertDate?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ApprovalsApplicationId?: Maybe<Int_Mssql_Comparison_Exp>;
  BroadcastAlertId?: Maybe<Int_Mssql_Comparison_Exp>;
  ExpirationDate?: Maybe<Datetime_Mssql_Comparison_Exp>;
  Message?: Maybe<String_Mssql_Comparison_Exp>;
  Subject?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BroadcastAlert_Bool_Exp>>;
  _not?: Maybe<BroadcastAlert_Bool_Exp>;
  _or?: Maybe<Array<BroadcastAlert_Bool_Exp>>;
};

/** upsert condition type for table "BroadcastAlert" */
export type BroadcastAlert_If_Matched = {
  match_columns?: Array<BroadcastAlert_Insert_Match_Column>;
  update_columns?: Array<BroadcastAlert_Update_Column>;
  where?: Maybe<BroadcastAlert_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BroadcastAlert" */
export type BroadcastAlert_Inc_Input = {
  ApprovalsApplicationId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BroadcastAlert" */
export type BroadcastAlert_Insert_Input = {
  AlertDate?: Maybe<Scalars['datetime']>;
  ApprovalsApplicationId?: Maybe<Scalars['Int']>;
  ExpirationDate?: Maybe<Scalars['datetime']>;
  Message?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
};

/** select match_columns of table "BroadcastAlert" */
export enum BroadcastAlert_Insert_Match_Column {
  /** column name */
  AlertDate = 'AlertDate',
  /** column name */
  ApprovalsApplicationId = 'ApprovalsApplicationId',
  /** column name */
  BroadcastAlertId = 'BroadcastAlertId',
  /** column name */
  ExpirationDate = 'ExpirationDate',
  /** column name */
  Message = 'Message',
  /** column name */
  Subject = 'Subject'
}

/** aggregate max on columns */
export type BroadcastAlert_Max_Fields = {
  __typename?: 'BroadcastAlert_max_fields';
  AlertDate?: Maybe<Scalars['datetime']>;
  ApprovalsApplicationId?: Maybe<Scalars['Int']>;
  BroadcastAlertId?: Maybe<Scalars['Int']>;
  ExpirationDate?: Maybe<Scalars['datetime']>;
  Message?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type BroadcastAlert_Min_Fields = {
  __typename?: 'BroadcastAlert_min_fields';
  AlertDate?: Maybe<Scalars['datetime']>;
  ApprovalsApplicationId?: Maybe<Scalars['Int']>;
  BroadcastAlertId?: Maybe<Scalars['Int']>;
  ExpirationDate?: Maybe<Scalars['datetime']>;
  Message?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "BroadcastAlert" */
export type BroadcastAlert_Mutation_Response = {
  __typename?: 'BroadcastAlert_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BroadcastAlert>;
};

/** Ordering options when selecting data from "BroadcastAlert". */
export type BroadcastAlert_Order_By = {
  AlertDate?: Maybe<Order_By>;
  ApprovalsApplicationId?: Maybe<Order_By>;
  BroadcastAlertId?: Maybe<Order_By>;
  ExpirationDate?: Maybe<Order_By>;
  Message?: Maybe<Order_By>;
  Subject?: Maybe<Order_By>;
};

/** primary key columns input for table: BroadcastAlert */
export type BroadcastAlert_Pk_Columns_Input = {
  BroadcastAlertId: Scalars['Int'];
};

/** select columns of table "BroadcastAlert" */
export enum BroadcastAlert_Select_Column {
  /** column name */
  AlertDate = 'AlertDate',
  /** column name */
  ApprovalsApplicationId = 'ApprovalsApplicationId',
  /** column name */
  BroadcastAlertId = 'BroadcastAlertId',
  /** column name */
  ExpirationDate = 'ExpirationDate',
  /** column name */
  Message = 'Message',
  /** column name */
  Subject = 'Subject'
}

/** input type for updating data in table "BroadcastAlert" */
export type BroadcastAlert_Set_Input = {
  AlertDate?: Maybe<Scalars['datetime']>;
  ApprovalsApplicationId?: Maybe<Scalars['Int']>;
  ExpirationDate?: Maybe<Scalars['datetime']>;
  Message?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type BroadcastAlert_Stddev_Fields = {
  __typename?: 'BroadcastAlert_stddev_fields';
  ApprovalsApplicationId?: Maybe<Scalars['Float']>;
  BroadcastAlertId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type BroadcastAlert_Stddev_Pop_Fields = {
  __typename?: 'BroadcastAlert_stddev_pop_fields';
  ApprovalsApplicationId?: Maybe<Scalars['Float']>;
  BroadcastAlertId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type BroadcastAlert_Stddev_Samp_Fields = {
  __typename?: 'BroadcastAlert_stddev_samp_fields';
  ApprovalsApplicationId?: Maybe<Scalars['Float']>;
  BroadcastAlertId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type BroadcastAlert_Sum_Fields = {
  __typename?: 'BroadcastAlert_sum_fields';
  ApprovalsApplicationId?: Maybe<Scalars['Int']>;
  BroadcastAlertId?: Maybe<Scalars['Int']>;
};

/** update columns of table "BroadcastAlert" */
export enum BroadcastAlert_Update_Column {
  /** column name */
  AlertDate = 'AlertDate',
  /** column name */
  ApprovalsApplicationId = 'ApprovalsApplicationId',
  /** column name */
  ExpirationDate = 'ExpirationDate',
  /** column name */
  Message = 'Message',
  /** column name */
  Subject = 'Subject'
}

/** aggregate var_pop on columns */
export type BroadcastAlert_Var_Pop_Fields = {
  __typename?: 'BroadcastAlert_var_pop_fields';
  ApprovalsApplicationId?: Maybe<Scalars['Float']>;
  BroadcastAlertId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type BroadcastAlert_Var_Samp_Fields = {
  __typename?: 'BroadcastAlert_var_samp_fields';
  ApprovalsApplicationId?: Maybe<Scalars['Float']>;
  BroadcastAlertId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type BroadcastAlert_Variance_Fields = {
  __typename?: 'BroadcastAlert_variance_fields';
  ApprovalsApplicationId?: Maybe<Scalars['Float']>;
  BroadcastAlertId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "BuiltupVaporRetarderSystemUsage" */
export type BuiltupVaporRetarderSystemUsage = {
  __typename?: 'BuiltupVaporRetarderSystemUsage';
  ApplicationRateDry: Scalars['MeasurementValue'];
  ApplicationRateWet: Scalars['MeasurementValue'];
  MaxNumberPlies: Scalars['Int'];
  MinNumberPlies: Scalars['Int'];
  NumberPlies: Scalars['Int'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "BuiltupVaporRetarderSystemUsage" */
export type BuiltupVaporRetarderSystemUsage_Aggregate = {
  __typename?: 'BuiltupVaporRetarderSystemUsage_aggregate';
  aggregate?: Maybe<BuiltupVaporRetarderSystemUsage_Aggregate_Fields>;
  nodes: Array<BuiltupVaporRetarderSystemUsage>;
};

/** aggregate fields of "BuiltupVaporRetarderSystemUsage" */
export type BuiltupVaporRetarderSystemUsage_Aggregate_Fields = {
  __typename?: 'BuiltupVaporRetarderSystemUsage_aggregate_fields';
  avg?: Maybe<BuiltupVaporRetarderSystemUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<BuiltupVaporRetarderSystemUsage_Max_Fields>;
  min?: Maybe<BuiltupVaporRetarderSystemUsage_Min_Fields>;
  stddev?: Maybe<BuiltupVaporRetarderSystemUsage_Stddev_Fields>;
  stddev_pop?: Maybe<BuiltupVaporRetarderSystemUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<BuiltupVaporRetarderSystemUsage_Stddev_Samp_Fields>;
  sum?: Maybe<BuiltupVaporRetarderSystemUsage_Sum_Fields>;
  var_pop?: Maybe<BuiltupVaporRetarderSystemUsage_Var_Pop_Fields>;
  var_samp?: Maybe<BuiltupVaporRetarderSystemUsage_Var_Samp_Fields>;
  variance?: Maybe<BuiltupVaporRetarderSystemUsage_Variance_Fields>;
};


/** aggregate fields of "BuiltupVaporRetarderSystemUsage" */
export type BuiltupVaporRetarderSystemUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<BuiltupVaporRetarderSystemUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type BuiltupVaporRetarderSystemUsage_Avg_Fields = {
  __typename?: 'BuiltupVaporRetarderSystemUsage_avg_fields';
  MaxNumberPlies?: Maybe<Scalars['Float']>;
  MinNumberPlies?: Maybe<Scalars['Float']>;
  NumberPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "BuiltupVaporRetarderSystemUsage". All fields are combined with a logical 'AND'. */
export type BuiltupVaporRetarderSystemUsage_Bool_Exp = {
  ApplicationRateDry?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ApplicationRateWet?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  MaxNumberPlies?: Maybe<Int_Mssql_Comparison_Exp>;
  MinNumberPlies?: Maybe<Int_Mssql_Comparison_Exp>;
  NumberPlies?: Maybe<Int_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<BuiltupVaporRetarderSystemUsage_Bool_Exp>>;
  _not?: Maybe<BuiltupVaporRetarderSystemUsage_Bool_Exp>;
  _or?: Maybe<Array<BuiltupVaporRetarderSystemUsage_Bool_Exp>>;
};

/** upsert condition type for table "BuiltupVaporRetarderSystemUsage" */
export type BuiltupVaporRetarderSystemUsage_If_Matched = {
  match_columns?: Array<BuiltupVaporRetarderSystemUsage_Insert_Match_Column>;
  update_columns?: Array<BuiltupVaporRetarderSystemUsage_Update_Column>;
  where?: Maybe<BuiltupVaporRetarderSystemUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "BuiltupVaporRetarderSystemUsage" */
export type BuiltupVaporRetarderSystemUsage_Inc_Input = {
  MaxNumberPlies?: Maybe<Scalars['Int']>;
  MinNumberPlies?: Maybe<Scalars['Int']>;
  NumberPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "BuiltupVaporRetarderSystemUsage" */
export type BuiltupVaporRetarderSystemUsage_Insert_Input = {
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  MaxNumberPlies?: Maybe<Scalars['Int']>;
  MinNumberPlies?: Maybe<Scalars['Int']>;
  NumberPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "BuiltupVaporRetarderSystemUsage" */
export enum BuiltupVaporRetarderSystemUsage_Insert_Match_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  MaxNumberPlies = 'MaxNumberPlies',
  /** column name */
  MinNumberPlies = 'MinNumberPlies',
  /** column name */
  NumberPlies = 'NumberPlies',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type BuiltupVaporRetarderSystemUsage_Max_Fields = {
  __typename?: 'BuiltupVaporRetarderSystemUsage_max_fields';
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  MaxNumberPlies?: Maybe<Scalars['Int']>;
  MinNumberPlies?: Maybe<Scalars['Int']>;
  NumberPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type BuiltupVaporRetarderSystemUsage_Min_Fields = {
  __typename?: 'BuiltupVaporRetarderSystemUsage_min_fields';
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  MaxNumberPlies?: Maybe<Scalars['Int']>;
  MinNumberPlies?: Maybe<Scalars['Int']>;
  NumberPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "BuiltupVaporRetarderSystemUsage" */
export type BuiltupVaporRetarderSystemUsage_Mutation_Response = {
  __typename?: 'BuiltupVaporRetarderSystemUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BuiltupVaporRetarderSystemUsage>;
};

/** Ordering options when selecting data from "BuiltupVaporRetarderSystemUsage". */
export type BuiltupVaporRetarderSystemUsage_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  MaxNumberPlies?: Maybe<Order_By>;
  MinNumberPlies?: Maybe<Order_By>;
  NumberPlies?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: BuiltupVaporRetarderSystemUsage */
export type BuiltupVaporRetarderSystemUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "BuiltupVaporRetarderSystemUsage" */
export enum BuiltupVaporRetarderSystemUsage_Select_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  MaxNumberPlies = 'MaxNumberPlies',
  /** column name */
  MinNumberPlies = 'MinNumberPlies',
  /** column name */
  NumberPlies = 'NumberPlies',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "BuiltupVaporRetarderSystemUsage" */
export type BuiltupVaporRetarderSystemUsage_Set_Input = {
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  MaxNumberPlies?: Maybe<Scalars['Int']>;
  MinNumberPlies?: Maybe<Scalars['Int']>;
  NumberPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type BuiltupVaporRetarderSystemUsage_Stddev_Fields = {
  __typename?: 'BuiltupVaporRetarderSystemUsage_stddev_fields';
  MaxNumberPlies?: Maybe<Scalars['Float']>;
  MinNumberPlies?: Maybe<Scalars['Float']>;
  NumberPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type BuiltupVaporRetarderSystemUsage_Stddev_Pop_Fields = {
  __typename?: 'BuiltupVaporRetarderSystemUsage_stddev_pop_fields';
  MaxNumberPlies?: Maybe<Scalars['Float']>;
  MinNumberPlies?: Maybe<Scalars['Float']>;
  NumberPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type BuiltupVaporRetarderSystemUsage_Stddev_Samp_Fields = {
  __typename?: 'BuiltupVaporRetarderSystemUsage_stddev_samp_fields';
  MaxNumberPlies?: Maybe<Scalars['Float']>;
  MinNumberPlies?: Maybe<Scalars['Float']>;
  NumberPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type BuiltupVaporRetarderSystemUsage_Sum_Fields = {
  __typename?: 'BuiltupVaporRetarderSystemUsage_sum_fields';
  MaxNumberPlies?: Maybe<Scalars['Int']>;
  MinNumberPlies?: Maybe<Scalars['Int']>;
  NumberPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "BuiltupVaporRetarderSystemUsage" */
export enum BuiltupVaporRetarderSystemUsage_Update_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  MaxNumberPlies = 'MaxNumberPlies',
  /** column name */
  MinNumberPlies = 'MinNumberPlies',
  /** column name */
  NumberPlies = 'NumberPlies',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type BuiltupVaporRetarderSystemUsage_Var_Pop_Fields = {
  __typename?: 'BuiltupVaporRetarderSystemUsage_var_pop_fields';
  MaxNumberPlies?: Maybe<Scalars['Float']>;
  MinNumberPlies?: Maybe<Scalars['Float']>;
  NumberPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type BuiltupVaporRetarderSystemUsage_Var_Samp_Fields = {
  __typename?: 'BuiltupVaporRetarderSystemUsage_var_samp_fields';
  MaxNumberPlies?: Maybe<Scalars['Float']>;
  MinNumberPlies?: Maybe<Scalars['Float']>;
  NumberPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type BuiltupVaporRetarderSystemUsage_Variance_Fields = {
  __typename?: 'BuiltupVaporRetarderSystemUsage_variance_fields';
  MaxNumberPlies?: Maybe<Scalars['Float']>;
  MinNumberPlies?: Maybe<Scalars['Float']>;
  NumberPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_Additive" */
export type C_Additive = {
  __typename?: 'C_Additive';
  AdditiveCode: Scalars['CodeTableValue'];
  AdditiveId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  GypsumDecks: Array<GypsumDeck>;
  /** An aggregate relationship */
  GypsumDecks_aggregate: GypsumDeck_Aggregate;
  /** An array relationship */
  LightweightInsulatingConcretes: Array<LightweightInsulatingConcrete>;
  /** An aggregate relationship */
  LightweightInsulatingConcretes_aggregate: LightweightInsulatingConcrete_Aggregate;
};


/** columns and relationships of "C_Additive" */
export type C_AdditiveGypsumDecksArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GypsumDeck_Order_By>>;
  where?: Maybe<GypsumDeck_Bool_Exp>;
};


/** columns and relationships of "C_Additive" */
export type C_AdditiveGypsumDecks_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GypsumDeck_Order_By>>;
  where?: Maybe<GypsumDeck_Bool_Exp>;
};


/** columns and relationships of "C_Additive" */
export type C_AdditiveLightweightInsulatingConcretesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
};


/** columns and relationships of "C_Additive" */
export type C_AdditiveLightweightInsulatingConcretes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
};

/** aggregated selection of "C_Additive" */
export type C_Additive_Aggregate = {
  __typename?: 'C_Additive_aggregate';
  aggregate?: Maybe<C_Additive_Aggregate_Fields>;
  nodes: Array<C_Additive>;
};

/** aggregate fields of "C_Additive" */
export type C_Additive_Aggregate_Fields = {
  __typename?: 'C_Additive_aggregate_fields';
  avg?: Maybe<C_Additive_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_Additive_Max_Fields>;
  min?: Maybe<C_Additive_Min_Fields>;
  stddev?: Maybe<C_Additive_Stddev_Fields>;
  stddev_pop?: Maybe<C_Additive_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_Additive_Stddev_Samp_Fields>;
  sum?: Maybe<C_Additive_Sum_Fields>;
  var_pop?: Maybe<C_Additive_Var_Pop_Fields>;
  var_samp?: Maybe<C_Additive_Var_Samp_Fields>;
  variance?: Maybe<C_Additive_Variance_Fields>;
};


/** aggregate fields of "C_Additive" */
export type C_Additive_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_Additive_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_Additive_Avg_Fields = {
  __typename?: 'C_Additive_avg_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_Additive". All fields are combined with a logical 'AND'. */
export type C_Additive_Bool_Exp = {
  AdditiveCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  AdditiveId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  GypsumDecks?: Maybe<GypsumDeck_Bool_Exp>;
  LightweightInsulatingConcretes?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
  _and?: Maybe<Array<C_Additive_Bool_Exp>>;
  _not?: Maybe<C_Additive_Bool_Exp>;
  _or?: Maybe<Array<C_Additive_Bool_Exp>>;
};

/** upsert condition type for table "C_Additive" */
export type C_Additive_If_Matched = {
  match_columns?: Array<C_Additive_Insert_Match_Column>;
  update_columns?: Array<C_Additive_Update_Column>;
  where?: Maybe<C_Additive_Bool_Exp>;
};

/** input type for inserting data into table "C_Additive" */
export type C_Additive_Insert_Input = {
  AdditiveCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_Additive" */
export enum C_Additive_Insert_Match_Column {
  /** column name */
  AdditiveCode = 'AdditiveCode',
  /** column name */
  AdditiveId = 'AdditiveId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_Additive_Max_Fields = {
  __typename?: 'C_Additive_max_fields';
  AdditiveCode?: Maybe<Scalars['CodeTableValue']>;
  AdditiveId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_Additive_Min_Fields = {
  __typename?: 'C_Additive_min_fields';
  AdditiveCode?: Maybe<Scalars['CodeTableValue']>;
  AdditiveId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_Additive" */
export type C_Additive_Mutation_Response = {
  __typename?: 'C_Additive_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_Additive>;
};

/** Ordering options when selecting data from "C_Additive". */
export type C_Additive_Order_By = {
  AdditiveCode?: Maybe<Order_By>;
  AdditiveId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
  GypsumDecks_aggregate?: Maybe<GypsumDeck_Aggregate_Order_By>;
  LightweightInsulatingConcretes_aggregate?: Maybe<LightweightInsulatingConcrete_Aggregate_Order_By>;
};

/** primary key columns input for table: C_Additive */
export type C_Additive_Pk_Columns_Input = {
  AdditiveId: Scalars['Int'];
};

/** select columns of table "C_Additive" */
export enum C_Additive_Select_Column {
  /** column name */
  AdditiveCode = 'AdditiveCode',
  /** column name */
  AdditiveId = 'AdditiveId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_Additive" */
export type C_Additive_Set_Input = {
  AdditiveCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_Additive_Stddev_Fields = {
  __typename?: 'C_Additive_stddev_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_Additive_Stddev_Pop_Fields = {
  __typename?: 'C_Additive_stddev_pop_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_Additive_Stddev_Samp_Fields = {
  __typename?: 'C_Additive_stddev_samp_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_Additive_Sum_Fields = {
  __typename?: 'C_Additive_sum_fields';
  AdditiveId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_Additive" */
export enum C_Additive_Update_Column {
  /** column name */
  AdditiveCode = 'AdditiveCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_Additive_Var_Pop_Fields = {
  __typename?: 'C_Additive_var_pop_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_Additive_Var_Samp_Fields = {
  __typename?: 'C_Additive_var_samp_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_Additive_Variance_Fields = {
  __typename?: 'C_Additive_variance_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_AdhesiveApplicationMethod" */
export type C_AdhesiveApplicationMethod = {
  __typename?: 'C_AdhesiveApplicationMethod';
  AdhesiveApplicationMethodCode: Scalars['CodeTableValue'];
  AdhesiveApplicationMethodId: Scalars['Int'];
  /** An array relationship */
  AdhesiveRibbonUsages: Array<AdhesiveRibbonUsage>;
  /** An aggregate relationship */
  AdhesiveRibbonUsages_aggregate: AdhesiveRibbonUsage_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
};


/** columns and relationships of "C_AdhesiveApplicationMethod" */
export type C_AdhesiveApplicationMethodAdhesiveRibbonUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveRibbonUsage_Order_By>>;
  where?: Maybe<AdhesiveRibbonUsage_Bool_Exp>;
};


/** columns and relationships of "C_AdhesiveApplicationMethod" */
export type C_AdhesiveApplicationMethodAdhesiveRibbonUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveRibbonUsage_Order_By>>;
  where?: Maybe<AdhesiveRibbonUsage_Bool_Exp>;
};

/** aggregated selection of "C_AdhesiveApplicationMethod" */
export type C_AdhesiveApplicationMethod_Aggregate = {
  __typename?: 'C_AdhesiveApplicationMethod_aggregate';
  aggregate?: Maybe<C_AdhesiveApplicationMethod_Aggregate_Fields>;
  nodes: Array<C_AdhesiveApplicationMethod>;
};

/** aggregate fields of "C_AdhesiveApplicationMethod" */
export type C_AdhesiveApplicationMethod_Aggregate_Fields = {
  __typename?: 'C_AdhesiveApplicationMethod_aggregate_fields';
  avg?: Maybe<C_AdhesiveApplicationMethod_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_AdhesiveApplicationMethod_Max_Fields>;
  min?: Maybe<C_AdhesiveApplicationMethod_Min_Fields>;
  stddev?: Maybe<C_AdhesiveApplicationMethod_Stddev_Fields>;
  stddev_pop?: Maybe<C_AdhesiveApplicationMethod_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_AdhesiveApplicationMethod_Stddev_Samp_Fields>;
  sum?: Maybe<C_AdhesiveApplicationMethod_Sum_Fields>;
  var_pop?: Maybe<C_AdhesiveApplicationMethod_Var_Pop_Fields>;
  var_samp?: Maybe<C_AdhesiveApplicationMethod_Var_Samp_Fields>;
  variance?: Maybe<C_AdhesiveApplicationMethod_Variance_Fields>;
};


/** aggregate fields of "C_AdhesiveApplicationMethod" */
export type C_AdhesiveApplicationMethod_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_AdhesiveApplicationMethod_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_AdhesiveApplicationMethod_Avg_Fields = {
  __typename?: 'C_AdhesiveApplicationMethod_avg_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_AdhesiveApplicationMethod". All fields are combined with a logical 'AND'. */
export type C_AdhesiveApplicationMethod_Bool_Exp = {
  AdhesiveApplicationMethodCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  AdhesiveApplicationMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  AdhesiveRibbonUsages?: Maybe<AdhesiveRibbonUsage_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_AdhesiveApplicationMethod_Bool_Exp>>;
  _not?: Maybe<C_AdhesiveApplicationMethod_Bool_Exp>;
  _or?: Maybe<Array<C_AdhesiveApplicationMethod_Bool_Exp>>;
};

/** upsert condition type for table "C_AdhesiveApplicationMethod" */
export type C_AdhesiveApplicationMethod_If_Matched = {
  match_columns?: Array<C_AdhesiveApplicationMethod_Insert_Match_Column>;
  update_columns?: Array<C_AdhesiveApplicationMethod_Update_Column>;
  where?: Maybe<C_AdhesiveApplicationMethod_Bool_Exp>;
};

/** input type for inserting data into table "C_AdhesiveApplicationMethod" */
export type C_AdhesiveApplicationMethod_Insert_Input = {
  AdhesiveApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_AdhesiveApplicationMethod" */
export enum C_AdhesiveApplicationMethod_Insert_Match_Column {
  /** column name */
  AdhesiveApplicationMethodCode = 'AdhesiveApplicationMethodCode',
  /** column name */
  AdhesiveApplicationMethodId = 'AdhesiveApplicationMethodId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_AdhesiveApplicationMethod_Max_Fields = {
  __typename?: 'C_AdhesiveApplicationMethod_max_fields';
  AdhesiveApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
  AdhesiveApplicationMethodId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_AdhesiveApplicationMethod_Min_Fields = {
  __typename?: 'C_AdhesiveApplicationMethod_min_fields';
  AdhesiveApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
  AdhesiveApplicationMethodId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_AdhesiveApplicationMethod" */
export type C_AdhesiveApplicationMethod_Mutation_Response = {
  __typename?: 'C_AdhesiveApplicationMethod_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_AdhesiveApplicationMethod>;
};

/** Ordering options when selecting data from "C_AdhesiveApplicationMethod". */
export type C_AdhesiveApplicationMethod_Order_By = {
  AdhesiveApplicationMethodCode?: Maybe<Order_By>;
  AdhesiveApplicationMethodId?: Maybe<Order_By>;
  AdhesiveRibbonUsages_aggregate?: Maybe<AdhesiveRibbonUsage_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** primary key columns input for table: C_AdhesiveApplicationMethod */
export type C_AdhesiveApplicationMethod_Pk_Columns_Input = {
  AdhesiveApplicationMethodId: Scalars['Int'];
};

/** select columns of table "C_AdhesiveApplicationMethod" */
export enum C_AdhesiveApplicationMethod_Select_Column {
  /** column name */
  AdhesiveApplicationMethodCode = 'AdhesiveApplicationMethodCode',
  /** column name */
  AdhesiveApplicationMethodId = 'AdhesiveApplicationMethodId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_AdhesiveApplicationMethod" */
export type C_AdhesiveApplicationMethod_Set_Input = {
  AdhesiveApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_AdhesiveApplicationMethod_Stddev_Fields = {
  __typename?: 'C_AdhesiveApplicationMethod_stddev_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_AdhesiveApplicationMethod_Stddev_Pop_Fields = {
  __typename?: 'C_AdhesiveApplicationMethod_stddev_pop_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_AdhesiveApplicationMethod_Stddev_Samp_Fields = {
  __typename?: 'C_AdhesiveApplicationMethod_stddev_samp_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_AdhesiveApplicationMethod_Sum_Fields = {
  __typename?: 'C_AdhesiveApplicationMethod_sum_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_AdhesiveApplicationMethod" */
export enum C_AdhesiveApplicationMethod_Update_Column {
  /** column name */
  AdhesiveApplicationMethodCode = 'AdhesiveApplicationMethodCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_AdhesiveApplicationMethod_Var_Pop_Fields = {
  __typename?: 'C_AdhesiveApplicationMethod_var_pop_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_AdhesiveApplicationMethod_Var_Samp_Fields = {
  __typename?: 'C_AdhesiveApplicationMethod_var_samp_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_AdhesiveApplicationMethod_Variance_Fields = {
  __typename?: 'C_AdhesiveApplicationMethod_variance_fields';
  AdhesiveApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_AdhesiveMaterial" */
export type C_AdhesiveMaterial = {
  __typename?: 'C_AdhesiveMaterial';
  /** An array relationship */
  AdhesiveFullApplications: Array<AdhesiveFullApplication>;
  /** An aggregate relationship */
  AdhesiveFullApplications_aggregate: AdhesiveFullApplication_Aggregate;
  AdhesiveMaterialCode: Scalars['CodeTableValue'];
  AdhesiveMaterialId: Scalars['Int'];
  /** An array relationship */
  AdhesiveRibbons: Array<AdhesiveRibbons>;
  /** An aggregate relationship */
  AdhesiveRibbons_aggregate: AdhesiveRibbons_Aggregate;
  /** An array relationship */
  AdhesiveSpots: Array<AdhesiveSpots>;
  /** An aggregate relationship */
  AdhesiveSpots_aggregate: AdhesiveSpots_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
};


/** columns and relationships of "C_AdhesiveMaterial" */
export type C_AdhesiveMaterialAdhesiveFullApplicationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveFullApplication_Order_By>>;
  where?: Maybe<AdhesiveFullApplication_Bool_Exp>;
};


/** columns and relationships of "C_AdhesiveMaterial" */
export type C_AdhesiveMaterialAdhesiveFullApplications_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveFullApplication_Order_By>>;
  where?: Maybe<AdhesiveFullApplication_Bool_Exp>;
};


/** columns and relationships of "C_AdhesiveMaterial" */
export type C_AdhesiveMaterialAdhesiveRibbonsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveRibbons_Order_By>>;
  where?: Maybe<AdhesiveRibbons_Bool_Exp>;
};


/** columns and relationships of "C_AdhesiveMaterial" */
export type C_AdhesiveMaterialAdhesiveRibbons_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveRibbons_Order_By>>;
  where?: Maybe<AdhesiveRibbons_Bool_Exp>;
};


/** columns and relationships of "C_AdhesiveMaterial" */
export type C_AdhesiveMaterialAdhesiveSpotsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSpots_Order_By>>;
  where?: Maybe<AdhesiveSpots_Bool_Exp>;
};


/** columns and relationships of "C_AdhesiveMaterial" */
export type C_AdhesiveMaterialAdhesiveSpots_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSpots_Order_By>>;
  where?: Maybe<AdhesiveSpots_Bool_Exp>;
};

/** aggregated selection of "C_AdhesiveMaterial" */
export type C_AdhesiveMaterial_Aggregate = {
  __typename?: 'C_AdhesiveMaterial_aggregate';
  aggregate?: Maybe<C_AdhesiveMaterial_Aggregate_Fields>;
  nodes: Array<C_AdhesiveMaterial>;
};

/** aggregate fields of "C_AdhesiveMaterial" */
export type C_AdhesiveMaterial_Aggregate_Fields = {
  __typename?: 'C_AdhesiveMaterial_aggregate_fields';
  avg?: Maybe<C_AdhesiveMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_AdhesiveMaterial_Max_Fields>;
  min?: Maybe<C_AdhesiveMaterial_Min_Fields>;
  stddev?: Maybe<C_AdhesiveMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_AdhesiveMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_AdhesiveMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_AdhesiveMaterial_Sum_Fields>;
  var_pop?: Maybe<C_AdhesiveMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_AdhesiveMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_AdhesiveMaterial_Variance_Fields>;
};


/** aggregate fields of "C_AdhesiveMaterial" */
export type C_AdhesiveMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_AdhesiveMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_AdhesiveMaterial_Avg_Fields = {
  __typename?: 'C_AdhesiveMaterial_avg_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_AdhesiveMaterial". All fields are combined with a logical 'AND'. */
export type C_AdhesiveMaterial_Bool_Exp = {
  AdhesiveFullApplications?: Maybe<AdhesiveFullApplication_Bool_Exp>;
  AdhesiveMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  AdhesiveMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  AdhesiveRibbons?: Maybe<AdhesiveRibbons_Bool_Exp>;
  AdhesiveSpots?: Maybe<AdhesiveSpots_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_AdhesiveMaterial_Bool_Exp>>;
  _not?: Maybe<C_AdhesiveMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_AdhesiveMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_AdhesiveMaterial" */
export type C_AdhesiveMaterial_If_Matched = {
  match_columns?: Array<C_AdhesiveMaterial_Insert_Match_Column>;
  update_columns?: Array<C_AdhesiveMaterial_Update_Column>;
  where?: Maybe<C_AdhesiveMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_AdhesiveMaterial" */
export type C_AdhesiveMaterial_Insert_Input = {
  AdhesiveMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_AdhesiveMaterial" */
export enum C_AdhesiveMaterial_Insert_Match_Column {
  /** column name */
  AdhesiveMaterialCode = 'AdhesiveMaterialCode',
  /** column name */
  AdhesiveMaterialId = 'AdhesiveMaterialId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_AdhesiveMaterial_Max_Fields = {
  __typename?: 'C_AdhesiveMaterial_max_fields';
  AdhesiveMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_AdhesiveMaterial_Min_Fields = {
  __typename?: 'C_AdhesiveMaterial_min_fields';
  AdhesiveMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_AdhesiveMaterial" */
export type C_AdhesiveMaterial_Mutation_Response = {
  __typename?: 'C_AdhesiveMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_AdhesiveMaterial>;
};

/** Ordering options when selecting data from "C_AdhesiveMaterial". */
export type C_AdhesiveMaterial_Order_By = {
  AdhesiveFullApplications_aggregate?: Maybe<AdhesiveFullApplication_Aggregate_Order_By>;
  AdhesiveMaterialCode?: Maybe<Order_By>;
  AdhesiveMaterialId?: Maybe<Order_By>;
  AdhesiveRibbons_aggregate?: Maybe<AdhesiveRibbons_Aggregate_Order_By>;
  AdhesiveSpots_aggregate?: Maybe<AdhesiveSpots_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** primary key columns input for table: C_AdhesiveMaterial */
export type C_AdhesiveMaterial_Pk_Columns_Input = {
  AdhesiveMaterialId: Scalars['Int'];
};

/** select columns of table "C_AdhesiveMaterial" */
export enum C_AdhesiveMaterial_Select_Column {
  /** column name */
  AdhesiveMaterialCode = 'AdhesiveMaterialCode',
  /** column name */
  AdhesiveMaterialId = 'AdhesiveMaterialId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_AdhesiveMaterial" */
export type C_AdhesiveMaterial_Set_Input = {
  AdhesiveMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_AdhesiveMaterial_Stddev_Fields = {
  __typename?: 'C_AdhesiveMaterial_stddev_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_AdhesiveMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_AdhesiveMaterial_stddev_pop_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_AdhesiveMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_AdhesiveMaterial_stddev_samp_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_AdhesiveMaterial_Sum_Fields = {
  __typename?: 'C_AdhesiveMaterial_sum_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_AdhesiveMaterial" */
export enum C_AdhesiveMaterial_Update_Column {
  /** column name */
  AdhesiveMaterialCode = 'AdhesiveMaterialCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_AdhesiveMaterial_Var_Pop_Fields = {
  __typename?: 'C_AdhesiveMaterial_var_pop_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_AdhesiveMaterial_Var_Samp_Fields = {
  __typename?: 'C_AdhesiveMaterial_var_samp_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_AdhesiveMaterial_Variance_Fields = {
  __typename?: 'C_AdhesiveMaterial_variance_fields';
  AdhesiveMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_AdhesiveType" */
export type C_AdhesiveType = {
  __typename?: 'C_AdhesiveType';
  /** An array relationship */
  AdhesiveFullApplications: Array<AdhesiveFullApplication>;
  /** An aggregate relationship */
  AdhesiveFullApplications_aggregate: AdhesiveFullApplication_Aggregate;
  /** An array relationship */
  AdhesiveRibbons: Array<AdhesiveRibbons>;
  /** An aggregate relationship */
  AdhesiveRibbons_aggregate: AdhesiveRibbons_Aggregate;
  /** An array relationship */
  AdhesiveSpots: Array<AdhesiveSpots>;
  /** An aggregate relationship */
  AdhesiveSpots_aggregate: AdhesiveSpots_Aggregate;
  AdhesiveTypeCode: Scalars['CodeTableValue'];
  AdhesiveTypeId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
};


/** columns and relationships of "C_AdhesiveType" */
export type C_AdhesiveTypeAdhesiveFullApplicationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveFullApplication_Order_By>>;
  where?: Maybe<AdhesiveFullApplication_Bool_Exp>;
};


/** columns and relationships of "C_AdhesiveType" */
export type C_AdhesiveTypeAdhesiveFullApplications_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveFullApplication_Order_By>>;
  where?: Maybe<AdhesiveFullApplication_Bool_Exp>;
};


/** columns and relationships of "C_AdhesiveType" */
export type C_AdhesiveTypeAdhesiveRibbonsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveRibbons_Order_By>>;
  where?: Maybe<AdhesiveRibbons_Bool_Exp>;
};


/** columns and relationships of "C_AdhesiveType" */
export type C_AdhesiveTypeAdhesiveRibbons_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveRibbons_Order_By>>;
  where?: Maybe<AdhesiveRibbons_Bool_Exp>;
};


/** columns and relationships of "C_AdhesiveType" */
export type C_AdhesiveTypeAdhesiveSpotsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSpots_Order_By>>;
  where?: Maybe<AdhesiveSpots_Bool_Exp>;
};


/** columns and relationships of "C_AdhesiveType" */
export type C_AdhesiveTypeAdhesiveSpots_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSpots_Order_By>>;
  where?: Maybe<AdhesiveSpots_Bool_Exp>;
};

/** aggregated selection of "C_AdhesiveType" */
export type C_AdhesiveType_Aggregate = {
  __typename?: 'C_AdhesiveType_aggregate';
  aggregate?: Maybe<C_AdhesiveType_Aggregate_Fields>;
  nodes: Array<C_AdhesiveType>;
};

/** aggregate fields of "C_AdhesiveType" */
export type C_AdhesiveType_Aggregate_Fields = {
  __typename?: 'C_AdhesiveType_aggregate_fields';
  avg?: Maybe<C_AdhesiveType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_AdhesiveType_Max_Fields>;
  min?: Maybe<C_AdhesiveType_Min_Fields>;
  stddev?: Maybe<C_AdhesiveType_Stddev_Fields>;
  stddev_pop?: Maybe<C_AdhesiveType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_AdhesiveType_Stddev_Samp_Fields>;
  sum?: Maybe<C_AdhesiveType_Sum_Fields>;
  var_pop?: Maybe<C_AdhesiveType_Var_Pop_Fields>;
  var_samp?: Maybe<C_AdhesiveType_Var_Samp_Fields>;
  variance?: Maybe<C_AdhesiveType_Variance_Fields>;
};


/** aggregate fields of "C_AdhesiveType" */
export type C_AdhesiveType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_AdhesiveType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_AdhesiveType_Avg_Fields = {
  __typename?: 'C_AdhesiveType_avg_fields';
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_AdhesiveType". All fields are combined with a logical 'AND'. */
export type C_AdhesiveType_Bool_Exp = {
  AdhesiveFullApplications?: Maybe<AdhesiveFullApplication_Bool_Exp>;
  AdhesiveRibbons?: Maybe<AdhesiveRibbons_Bool_Exp>;
  AdhesiveSpots?: Maybe<AdhesiveSpots_Bool_Exp>;
  AdhesiveTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  AdhesiveTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_AdhesiveType_Bool_Exp>>;
  _not?: Maybe<C_AdhesiveType_Bool_Exp>;
  _or?: Maybe<Array<C_AdhesiveType_Bool_Exp>>;
};

/** upsert condition type for table "C_AdhesiveType" */
export type C_AdhesiveType_If_Matched = {
  match_columns?: Array<C_AdhesiveType_Insert_Match_Column>;
  update_columns?: Array<C_AdhesiveType_Update_Column>;
  where?: Maybe<C_AdhesiveType_Bool_Exp>;
};

/** input type for inserting data into table "C_AdhesiveType" */
export type C_AdhesiveType_Insert_Input = {
  AdhesiveTypeCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_AdhesiveType" */
export enum C_AdhesiveType_Insert_Match_Column {
  /** column name */
  AdhesiveTypeCode = 'AdhesiveTypeCode',
  /** column name */
  AdhesiveTypeId = 'AdhesiveTypeId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_AdhesiveType_Max_Fields = {
  __typename?: 'C_AdhesiveType_max_fields';
  AdhesiveTypeCode?: Maybe<Scalars['CodeTableValue']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_AdhesiveType_Min_Fields = {
  __typename?: 'C_AdhesiveType_min_fields';
  AdhesiveTypeCode?: Maybe<Scalars['CodeTableValue']>;
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_AdhesiveType" */
export type C_AdhesiveType_Mutation_Response = {
  __typename?: 'C_AdhesiveType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_AdhesiveType>;
};

/** Ordering options when selecting data from "C_AdhesiveType". */
export type C_AdhesiveType_Order_By = {
  AdhesiveFullApplications_aggregate?: Maybe<AdhesiveFullApplication_Aggregate_Order_By>;
  AdhesiveRibbons_aggregate?: Maybe<AdhesiveRibbons_Aggregate_Order_By>;
  AdhesiveSpots_aggregate?: Maybe<AdhesiveSpots_Aggregate_Order_By>;
  AdhesiveTypeCode?: Maybe<Order_By>;
  AdhesiveTypeId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** primary key columns input for table: C_AdhesiveType */
export type C_AdhesiveType_Pk_Columns_Input = {
  AdhesiveTypeId: Scalars['Int'];
};

/** select columns of table "C_AdhesiveType" */
export enum C_AdhesiveType_Select_Column {
  /** column name */
  AdhesiveTypeCode = 'AdhesiveTypeCode',
  /** column name */
  AdhesiveTypeId = 'AdhesiveTypeId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_AdhesiveType" */
export type C_AdhesiveType_Set_Input = {
  AdhesiveTypeCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_AdhesiveType_Stddev_Fields = {
  __typename?: 'C_AdhesiveType_stddev_fields';
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_AdhesiveType_Stddev_Pop_Fields = {
  __typename?: 'C_AdhesiveType_stddev_pop_fields';
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_AdhesiveType_Stddev_Samp_Fields = {
  __typename?: 'C_AdhesiveType_stddev_samp_fields';
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_AdhesiveType_Sum_Fields = {
  __typename?: 'C_AdhesiveType_sum_fields';
  AdhesiveTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_AdhesiveType" */
export enum C_AdhesiveType_Update_Column {
  /** column name */
  AdhesiveTypeCode = 'AdhesiveTypeCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_AdhesiveType_Var_Pop_Fields = {
  __typename?: 'C_AdhesiveType_var_pop_fields';
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_AdhesiveType_Var_Samp_Fields = {
  __typename?: 'C_AdhesiveType_var_samp_fields';
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_AdhesiveType_Variance_Fields = {
  __typename?: 'C_AdhesiveType_variance_fields';
  AdhesiveTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_Aggregate" */
export type C_Aggregate = {
  __typename?: 'C_Aggregate';
  AggregateCode: Scalars['CodeTableValue'];
  AggregateId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  LightweightInsulatingConcretes: Array<LightweightInsulatingConcrete>;
  /** An aggregate relationship */
  LightweightInsulatingConcretes_aggregate: LightweightInsulatingConcrete_Aggregate;
};


/** columns and relationships of "C_Aggregate" */
export type C_AggregateLightweightInsulatingConcretesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
};


/** columns and relationships of "C_Aggregate" */
export type C_AggregateLightweightInsulatingConcretes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
};

/** aggregated selection of "C_Aggregate" */
export type C_Aggregate_Aggregate = {
  __typename?: 'C_Aggregate_aggregate';
  aggregate?: Maybe<C_Aggregate_Aggregate_Fields>;
  nodes: Array<C_Aggregate>;
};

/** aggregate fields of "C_Aggregate" */
export type C_Aggregate_Aggregate_Fields = {
  __typename?: 'C_Aggregate_aggregate_fields';
  avg?: Maybe<C_Aggregate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_Aggregate_Max_Fields>;
  min?: Maybe<C_Aggregate_Min_Fields>;
  stddev?: Maybe<C_Aggregate_Stddev_Fields>;
  stddev_pop?: Maybe<C_Aggregate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_Aggregate_Stddev_Samp_Fields>;
  sum?: Maybe<C_Aggregate_Sum_Fields>;
  var_pop?: Maybe<C_Aggregate_Var_Pop_Fields>;
  var_samp?: Maybe<C_Aggregate_Var_Samp_Fields>;
  variance?: Maybe<C_Aggregate_Variance_Fields>;
};


/** aggregate fields of "C_Aggregate" */
export type C_Aggregate_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_Aggregate_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_Aggregate_Avg_Fields = {
  __typename?: 'C_Aggregate_avg_fields';
  AggregateId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_Aggregate". All fields are combined with a logical 'AND'. */
export type C_Aggregate_Bool_Exp = {
  AggregateCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  AggregateId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  LightweightInsulatingConcretes?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
  _and?: Maybe<Array<C_Aggregate_Bool_Exp>>;
  _not?: Maybe<C_Aggregate_Bool_Exp>;
  _or?: Maybe<Array<C_Aggregate_Bool_Exp>>;
};

/** upsert condition type for table "C_Aggregate" */
export type C_Aggregate_If_Matched = {
  match_columns?: Array<C_Aggregate_Insert_Match_Column>;
  update_columns?: Array<C_Aggregate_Update_Column>;
  where?: Maybe<C_Aggregate_Bool_Exp>;
};

/** input type for inserting data into table "C_Aggregate" */
export type C_Aggregate_Insert_Input = {
  AggregateCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_Aggregate" */
export enum C_Aggregate_Insert_Match_Column {
  /** column name */
  AggregateCode = 'AggregateCode',
  /** column name */
  AggregateId = 'AggregateId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_Aggregate_Max_Fields = {
  __typename?: 'C_Aggregate_max_fields';
  AggregateCode?: Maybe<Scalars['CodeTableValue']>;
  AggregateId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_Aggregate_Min_Fields = {
  __typename?: 'C_Aggregate_min_fields';
  AggregateCode?: Maybe<Scalars['CodeTableValue']>;
  AggregateId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_Aggregate" */
export type C_Aggregate_Mutation_Response = {
  __typename?: 'C_Aggregate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_Aggregate>;
};

/** Ordering options when selecting data from "C_Aggregate". */
export type C_Aggregate_Order_By = {
  AggregateCode?: Maybe<Order_By>;
  AggregateId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
  LightweightInsulatingConcretes_aggregate?: Maybe<LightweightInsulatingConcrete_Aggregate_Order_By>;
};

/** primary key columns input for table: C_Aggregate */
export type C_Aggregate_Pk_Columns_Input = {
  AggregateId: Scalars['Int'];
};

/** select columns of table "C_Aggregate" */
export enum C_Aggregate_Select_Column {
  /** column name */
  AggregateCode = 'AggregateCode',
  /** column name */
  AggregateId = 'AggregateId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_Aggregate" */
export type C_Aggregate_Set_Input = {
  AggregateCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_Aggregate_Stddev_Fields = {
  __typename?: 'C_Aggregate_stddev_fields';
  AggregateId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_Aggregate_Stddev_Pop_Fields = {
  __typename?: 'C_Aggregate_stddev_pop_fields';
  AggregateId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_Aggregate_Stddev_Samp_Fields = {
  __typename?: 'C_Aggregate_stddev_samp_fields';
  AggregateId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_Aggregate_Sum_Fields = {
  __typename?: 'C_Aggregate_sum_fields';
  AggregateId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_Aggregate" */
export enum C_Aggregate_Update_Column {
  /** column name */
  AggregateCode = 'AggregateCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_Aggregate_Var_Pop_Fields = {
  __typename?: 'C_Aggregate_var_pop_fields';
  AggregateId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_Aggregate_Var_Samp_Fields = {
  __typename?: 'C_Aggregate_var_samp_fields';
  AggregateId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_Aggregate_Variance_Fields = {
  __typename?: 'C_Aggregate_variance_fields';
  AggregateId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ApprovalsDocCategory" */
export type C_ApprovalsDocCategory = {
  __typename?: 'C_ApprovalsDocCategory';
  ApprovalsDocCategoryCode: Scalars['CodeTableValue'];
  ApprovalsDocCategoryId: Scalars['Int'];
  /** An array relationship */
  ApprovalsDocs: Array<ApprovalsDoc>;
  /** An aggregate relationship */
  ApprovalsDocs_aggregate: ApprovalsDoc_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
};


/** columns and relationships of "C_ApprovalsDocCategory" */
export type C_ApprovalsDocCategoryApprovalsDocsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ApprovalsDoc_Order_By>>;
  where?: Maybe<ApprovalsDoc_Bool_Exp>;
};


/** columns and relationships of "C_ApprovalsDocCategory" */
export type C_ApprovalsDocCategoryApprovalsDocs_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ApprovalsDoc_Order_By>>;
  where?: Maybe<ApprovalsDoc_Bool_Exp>;
};

/** aggregated selection of "C_ApprovalsDocCategory" */
export type C_ApprovalsDocCategory_Aggregate = {
  __typename?: 'C_ApprovalsDocCategory_aggregate';
  aggregate?: Maybe<C_ApprovalsDocCategory_Aggregate_Fields>;
  nodes: Array<C_ApprovalsDocCategory>;
};

/** aggregate fields of "C_ApprovalsDocCategory" */
export type C_ApprovalsDocCategory_Aggregate_Fields = {
  __typename?: 'C_ApprovalsDocCategory_aggregate_fields';
  avg?: Maybe<C_ApprovalsDocCategory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ApprovalsDocCategory_Max_Fields>;
  min?: Maybe<C_ApprovalsDocCategory_Min_Fields>;
  stddev?: Maybe<C_ApprovalsDocCategory_Stddev_Fields>;
  stddev_pop?: Maybe<C_ApprovalsDocCategory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ApprovalsDocCategory_Stddev_Samp_Fields>;
  sum?: Maybe<C_ApprovalsDocCategory_Sum_Fields>;
  var_pop?: Maybe<C_ApprovalsDocCategory_Var_Pop_Fields>;
  var_samp?: Maybe<C_ApprovalsDocCategory_Var_Samp_Fields>;
  variance?: Maybe<C_ApprovalsDocCategory_Variance_Fields>;
};


/** aggregate fields of "C_ApprovalsDocCategory" */
export type C_ApprovalsDocCategory_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ApprovalsDocCategory_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ApprovalsDocCategory_Avg_Fields = {
  __typename?: 'C_ApprovalsDocCategory_avg_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ApprovalsDocCategory". All fields are combined with a logical 'AND'. */
export type C_ApprovalsDocCategory_Bool_Exp = {
  ApprovalsDocCategoryCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ApprovalsDocCategoryId?: Maybe<Int_Mssql_Comparison_Exp>;
  ApprovalsDocs?: Maybe<ApprovalsDoc_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_ApprovalsDocCategory_Bool_Exp>>;
  _not?: Maybe<C_ApprovalsDocCategory_Bool_Exp>;
  _or?: Maybe<Array<C_ApprovalsDocCategory_Bool_Exp>>;
};

/** upsert condition type for table "C_ApprovalsDocCategory" */
export type C_ApprovalsDocCategory_If_Matched = {
  match_columns?: Array<C_ApprovalsDocCategory_Insert_Match_Column>;
  update_columns?: Array<C_ApprovalsDocCategory_Update_Column>;
  where?: Maybe<C_ApprovalsDocCategory_Bool_Exp>;
};

/** input type for inserting data into table "C_ApprovalsDocCategory" */
export type C_ApprovalsDocCategory_Insert_Input = {
  ApprovalsDocCategoryCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_ApprovalsDocCategory" */
export enum C_ApprovalsDocCategory_Insert_Match_Column {
  /** column name */
  ApprovalsDocCategoryCode = 'ApprovalsDocCategoryCode',
  /** column name */
  ApprovalsDocCategoryId = 'ApprovalsDocCategoryId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_ApprovalsDocCategory_Max_Fields = {
  __typename?: 'C_ApprovalsDocCategory_max_fields';
  ApprovalsDocCategoryCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovalsDocCategoryId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_ApprovalsDocCategory_Min_Fields = {
  __typename?: 'C_ApprovalsDocCategory_min_fields';
  ApprovalsDocCategoryCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovalsDocCategoryId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_ApprovalsDocCategory" */
export type C_ApprovalsDocCategory_Mutation_Response = {
  __typename?: 'C_ApprovalsDocCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ApprovalsDocCategory>;
};

/** Ordering options when selecting data from "C_ApprovalsDocCategory". */
export type C_ApprovalsDocCategory_Order_By = {
  ApprovalsDocCategoryCode?: Maybe<Order_By>;
  ApprovalsDocCategoryId?: Maybe<Order_By>;
  ApprovalsDocs_aggregate?: Maybe<ApprovalsDoc_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** primary key columns input for table: C_ApprovalsDocCategory */
export type C_ApprovalsDocCategory_Pk_Columns_Input = {
  ApprovalsDocCategoryId: Scalars['Int'];
};

/** select columns of table "C_ApprovalsDocCategory" */
export enum C_ApprovalsDocCategory_Select_Column {
  /** column name */
  ApprovalsDocCategoryCode = 'ApprovalsDocCategoryCode',
  /** column name */
  ApprovalsDocCategoryId = 'ApprovalsDocCategoryId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_ApprovalsDocCategory" */
export type C_ApprovalsDocCategory_Set_Input = {
  ApprovalsDocCategoryCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_ApprovalsDocCategory_Stddev_Fields = {
  __typename?: 'C_ApprovalsDocCategory_stddev_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ApprovalsDocCategory_Stddev_Pop_Fields = {
  __typename?: 'C_ApprovalsDocCategory_stddev_pop_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ApprovalsDocCategory_Stddev_Samp_Fields = {
  __typename?: 'C_ApprovalsDocCategory_stddev_samp_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ApprovalsDocCategory_Sum_Fields = {
  __typename?: 'C_ApprovalsDocCategory_sum_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ApprovalsDocCategory" */
export enum C_ApprovalsDocCategory_Update_Column {
  /** column name */
  ApprovalsDocCategoryCode = 'ApprovalsDocCategoryCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_ApprovalsDocCategory_Var_Pop_Fields = {
  __typename?: 'C_ApprovalsDocCategory_var_pop_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ApprovalsDocCategory_Var_Samp_Fields = {
  __typename?: 'C_ApprovalsDocCategory_var_samp_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ApprovalsDocCategory_Variance_Fields = {
  __typename?: 'C_ApprovalsDocCategory_variance_fields';
  ApprovalsDocCategoryId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ApprovedUse" */
export type C_ApprovedUse = {
  __typename?: 'C_ApprovedUse';
  /** An array relationship */
  ApprovedLayerUses: Array<ApprovedLayerUse>;
  /** An aggregate relationship */
  ApprovedLayerUses_aggregate: ApprovedLayerUse_Aggregate;
  ApprovedUseCode: Scalars['CodeTableValue'];
  ApprovedUseId: Scalars['Int'];
  /** An array relationship */
  ComponentApprovedUses: Array<ComponentApprovedUse>;
  /** An aggregate relationship */
  ComponentApprovedUses_aggregate: ComponentApprovedUse_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  /** An object relationship */
  MD_ApprovedUse?: Maybe<Md_ApprovedUse>;
  /** An object relationship */
  MD_ComponentUsage?: Maybe<Md_ComponentUsage>;
  /** An array relationship */
  R_ApprovedUse_Subcategories: Array<R_ApprovedUse_Subcategory>;
  /** An aggregate relationship */
  R_ApprovedUse_Subcategories_aggregate: R_ApprovedUse_Subcategory_Aggregate;
  /** An array relationship */
  R_ComponentType_ApprovedUses: Array<R_ComponentType_ApprovedUse>;
  /** An aggregate relationship */
  R_ComponentType_ApprovedUses_aggregate: R_ComponentType_ApprovedUse_Aggregate;
};


/** columns and relationships of "C_ApprovedUse" */
export type C_ApprovedUseApprovedLayerUsesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ApprovedLayerUse_Order_By>>;
  where?: Maybe<ApprovedLayerUse_Bool_Exp>;
};


/** columns and relationships of "C_ApprovedUse" */
export type C_ApprovedUseApprovedLayerUses_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ApprovedLayerUse_Order_By>>;
  where?: Maybe<ApprovedLayerUse_Bool_Exp>;
};


/** columns and relationships of "C_ApprovedUse" */
export type C_ApprovedUseComponentApprovedUsesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentApprovedUse_Order_By>>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};


/** columns and relationships of "C_ApprovedUse" */
export type C_ApprovedUseComponentApprovedUses_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentApprovedUse_Order_By>>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};


/** columns and relationships of "C_ApprovedUse" */
export type C_ApprovedUseR_ApprovedUse_SubcategoriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ApprovedUse_Subcategory_Order_By>>;
  where?: Maybe<R_ApprovedUse_Subcategory_Bool_Exp>;
};


/** columns and relationships of "C_ApprovedUse" */
export type C_ApprovedUseR_ApprovedUse_Subcategories_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ApprovedUse_Subcategory_Order_By>>;
  where?: Maybe<R_ApprovedUse_Subcategory_Bool_Exp>;
};


/** columns and relationships of "C_ApprovedUse" */
export type C_ApprovedUseR_ComponentType_ApprovedUsesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ComponentType_ApprovedUse_Order_By>>;
  where?: Maybe<R_ComponentType_ApprovedUse_Bool_Exp>;
};


/** columns and relationships of "C_ApprovedUse" */
export type C_ApprovedUseR_ComponentType_ApprovedUses_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ComponentType_ApprovedUse_Order_By>>;
  where?: Maybe<R_ComponentType_ApprovedUse_Bool_Exp>;
};

/** aggregated selection of "C_ApprovedUse" */
export type C_ApprovedUse_Aggregate = {
  __typename?: 'C_ApprovedUse_aggregate';
  aggregate?: Maybe<C_ApprovedUse_Aggregate_Fields>;
  nodes: Array<C_ApprovedUse>;
};

/** aggregate fields of "C_ApprovedUse" */
export type C_ApprovedUse_Aggregate_Fields = {
  __typename?: 'C_ApprovedUse_aggregate_fields';
  avg?: Maybe<C_ApprovedUse_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ApprovedUse_Max_Fields>;
  min?: Maybe<C_ApprovedUse_Min_Fields>;
  stddev?: Maybe<C_ApprovedUse_Stddev_Fields>;
  stddev_pop?: Maybe<C_ApprovedUse_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ApprovedUse_Stddev_Samp_Fields>;
  sum?: Maybe<C_ApprovedUse_Sum_Fields>;
  var_pop?: Maybe<C_ApprovedUse_Var_Pop_Fields>;
  var_samp?: Maybe<C_ApprovedUse_Var_Samp_Fields>;
  variance?: Maybe<C_ApprovedUse_Variance_Fields>;
};


/** aggregate fields of "C_ApprovedUse" */
export type C_ApprovedUse_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ApprovedUse_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ApprovedUse_Avg_Fields = {
  __typename?: 'C_ApprovedUse_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ApprovedUse". All fields are combined with a logical 'AND'. */
export type C_ApprovedUse_Bool_Exp = {
  ApprovedLayerUses?: Maybe<ApprovedLayerUse_Bool_Exp>;
  ApprovedUseCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentApprovedUses?: Maybe<ComponentApprovedUse_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  MD_ApprovedUse?: Maybe<Md_ApprovedUse_Bool_Exp>;
  MD_ComponentUsage?: Maybe<Md_ComponentUsage_Bool_Exp>;
  R_ApprovedUse_Subcategories?: Maybe<R_ApprovedUse_Subcategory_Bool_Exp>;
  R_ComponentType_ApprovedUses?: Maybe<R_ComponentType_ApprovedUse_Bool_Exp>;
  _and?: Maybe<Array<C_ApprovedUse_Bool_Exp>>;
  _not?: Maybe<C_ApprovedUse_Bool_Exp>;
  _or?: Maybe<Array<C_ApprovedUse_Bool_Exp>>;
};

/** upsert condition type for table "C_ApprovedUse" */
export type C_ApprovedUse_If_Matched = {
  match_columns?: Array<C_ApprovedUse_Insert_Match_Column>;
  update_columns?: Array<C_ApprovedUse_Update_Column>;
  where?: Maybe<C_ApprovedUse_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "C_ApprovedUse" */
export type C_ApprovedUse_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "C_ApprovedUse" */
export type C_ApprovedUse_Insert_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_ApprovedUse" */
export enum C_ApprovedUse_Insert_Match_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_ApprovedUse_Max_Fields = {
  __typename?: 'C_ApprovedUse_max_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_ApprovedUse_Min_Fields = {
  __typename?: 'C_ApprovedUse_min_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_ApprovedUse" */
export type C_ApprovedUse_Mutation_Response = {
  __typename?: 'C_ApprovedUse_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ApprovedUse>;
};

/** Ordering options when selecting data from "C_ApprovedUse". */
export type C_ApprovedUse_Order_By = {
  ApprovedLayerUses_aggregate?: Maybe<ApprovedLayerUse_Aggregate_Order_By>;
  ApprovedUseCode?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  ComponentApprovedUses_aggregate?: Maybe<ComponentApprovedUse_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  MD_ApprovedUse?: Maybe<Md_ApprovedUse_Order_By>;
  MD_ComponentUsage?: Maybe<Md_ComponentUsage_Order_By>;
  R_ApprovedUse_Subcategories_aggregate?: Maybe<R_ApprovedUse_Subcategory_Aggregate_Order_By>;
  R_ComponentType_ApprovedUses_aggregate?: Maybe<R_ComponentType_ApprovedUse_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ApprovedUse */
export type C_ApprovedUse_Pk_Columns_Input = {
  ApprovedUseId: Scalars['Int'];
};

/** select columns of table "C_ApprovedUse" */
export enum C_ApprovedUse_Select_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_ApprovedUse" */
export type C_ApprovedUse_Set_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_ApprovedUse_Stddev_Fields = {
  __typename?: 'C_ApprovedUse_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ApprovedUse_Stddev_Pop_Fields = {
  __typename?: 'C_ApprovedUse_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ApprovedUse_Stddev_Samp_Fields = {
  __typename?: 'C_ApprovedUse_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ApprovedUse_Sum_Fields = {
  __typename?: 'C_ApprovedUse_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ApprovedUse" */
export enum C_ApprovedUse_Update_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_ApprovedUse_Var_Pop_Fields = {
  __typename?: 'C_ApprovedUse_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ApprovedUse_Var_Samp_Fields = {
  __typename?: 'C_ApprovedUse_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ApprovedUse_Variance_Fields = {
  __typename?: 'C_ApprovedUse_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_AssemblyApplicationType" */
export type C_AssemblyApplicationType = {
  __typename?: 'C_AssemblyApplicationType';
  AssemblyApplicationTypeCode: Scalars['CodeTableValue'];
  AssemblyApplicationTypeId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  RoofAssemblies: Array<RoofAssembly>;
  /** An aggregate relationship */
  RoofAssemblies_aggregate: RoofAssembly_Aggregate;
};


/** columns and relationships of "C_AssemblyApplicationType" */
export type C_AssemblyApplicationTypeRoofAssembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "C_AssemblyApplicationType" */
export type C_AssemblyApplicationTypeRoofAssemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};

/** aggregated selection of "C_AssemblyApplicationType" */
export type C_AssemblyApplicationType_Aggregate = {
  __typename?: 'C_AssemblyApplicationType_aggregate';
  aggregate?: Maybe<C_AssemblyApplicationType_Aggregate_Fields>;
  nodes: Array<C_AssemblyApplicationType>;
};

/** aggregate fields of "C_AssemblyApplicationType" */
export type C_AssemblyApplicationType_Aggregate_Fields = {
  __typename?: 'C_AssemblyApplicationType_aggregate_fields';
  avg?: Maybe<C_AssemblyApplicationType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_AssemblyApplicationType_Max_Fields>;
  min?: Maybe<C_AssemblyApplicationType_Min_Fields>;
  stddev?: Maybe<C_AssemblyApplicationType_Stddev_Fields>;
  stddev_pop?: Maybe<C_AssemblyApplicationType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_AssemblyApplicationType_Stddev_Samp_Fields>;
  sum?: Maybe<C_AssemblyApplicationType_Sum_Fields>;
  var_pop?: Maybe<C_AssemblyApplicationType_Var_Pop_Fields>;
  var_samp?: Maybe<C_AssemblyApplicationType_Var_Samp_Fields>;
  variance?: Maybe<C_AssemblyApplicationType_Variance_Fields>;
};


/** aggregate fields of "C_AssemblyApplicationType" */
export type C_AssemblyApplicationType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_AssemblyApplicationType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_AssemblyApplicationType_Avg_Fields = {
  __typename?: 'C_AssemblyApplicationType_avg_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_AssemblyApplicationType". All fields are combined with a logical 'AND'. */
export type C_AssemblyApplicationType_Bool_Exp = {
  AssemblyApplicationTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  AssemblyApplicationTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  RoofAssemblies?: Maybe<RoofAssembly_Bool_Exp>;
  _and?: Maybe<Array<C_AssemblyApplicationType_Bool_Exp>>;
  _not?: Maybe<C_AssemblyApplicationType_Bool_Exp>;
  _or?: Maybe<Array<C_AssemblyApplicationType_Bool_Exp>>;
};

/** upsert condition type for table "C_AssemblyApplicationType" */
export type C_AssemblyApplicationType_If_Matched = {
  match_columns?: Array<C_AssemblyApplicationType_Insert_Match_Column>;
  update_columns?: Array<C_AssemblyApplicationType_Update_Column>;
  where?: Maybe<C_AssemblyApplicationType_Bool_Exp>;
};

/** input type for inserting data into table "C_AssemblyApplicationType" */
export type C_AssemblyApplicationType_Insert_Input = {
  AssemblyApplicationTypeCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_AssemblyApplicationType" */
export enum C_AssemblyApplicationType_Insert_Match_Column {
  /** column name */
  AssemblyApplicationTypeCode = 'AssemblyApplicationTypeCode',
  /** column name */
  AssemblyApplicationTypeId = 'AssemblyApplicationTypeId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_AssemblyApplicationType_Max_Fields = {
  __typename?: 'C_AssemblyApplicationType_max_fields';
  AssemblyApplicationTypeCode?: Maybe<Scalars['CodeTableValue']>;
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_AssemblyApplicationType_Min_Fields = {
  __typename?: 'C_AssemblyApplicationType_min_fields';
  AssemblyApplicationTypeCode?: Maybe<Scalars['CodeTableValue']>;
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_AssemblyApplicationType" */
export type C_AssemblyApplicationType_Mutation_Response = {
  __typename?: 'C_AssemblyApplicationType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_AssemblyApplicationType>;
};

/** Ordering options when selecting data from "C_AssemblyApplicationType". */
export type C_AssemblyApplicationType_Order_By = {
  AssemblyApplicationTypeCode?: Maybe<Order_By>;
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
  RoofAssemblies_aggregate?: Maybe<RoofAssembly_Aggregate_Order_By>;
};

/** primary key columns input for table: C_AssemblyApplicationType */
export type C_AssemblyApplicationType_Pk_Columns_Input = {
  AssemblyApplicationTypeId: Scalars['Int'];
};

/** select columns of table "C_AssemblyApplicationType" */
export enum C_AssemblyApplicationType_Select_Column {
  /** column name */
  AssemblyApplicationTypeCode = 'AssemblyApplicationTypeCode',
  /** column name */
  AssemblyApplicationTypeId = 'AssemblyApplicationTypeId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_AssemblyApplicationType" */
export type C_AssemblyApplicationType_Set_Input = {
  AssemblyApplicationTypeCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_AssemblyApplicationType_Stddev_Fields = {
  __typename?: 'C_AssemblyApplicationType_stddev_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_AssemblyApplicationType_Stddev_Pop_Fields = {
  __typename?: 'C_AssemblyApplicationType_stddev_pop_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_AssemblyApplicationType_Stddev_Samp_Fields = {
  __typename?: 'C_AssemblyApplicationType_stddev_samp_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_AssemblyApplicationType_Sum_Fields = {
  __typename?: 'C_AssemblyApplicationType_sum_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_AssemblyApplicationType" */
export enum C_AssemblyApplicationType_Update_Column {
  /** column name */
  AssemblyApplicationTypeCode = 'AssemblyApplicationTypeCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_AssemblyApplicationType_Var_Pop_Fields = {
  __typename?: 'C_AssemblyApplicationType_var_pop_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_AssemblyApplicationType_Var_Samp_Fields = {
  __typename?: 'C_AssemblyApplicationType_var_samp_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_AssemblyApplicationType_Variance_Fields = {
  __typename?: 'C_AssemblyApplicationType_variance_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_AssemblyType" */
export type C_AssemblyType = {
  __typename?: 'C_AssemblyType';
  AssemblyTypeCode: Scalars['CodeTableValue'];
  AssemblyTypeId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  RoofAssemblies: Array<RoofAssembly>;
  /** An aggregate relationship */
  RoofAssemblies_aggregate: RoofAssembly_Aggregate;
};


/** columns and relationships of "C_AssemblyType" */
export type C_AssemblyTypeRoofAssembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "C_AssemblyType" */
export type C_AssemblyTypeRoofAssemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};

/** aggregated selection of "C_AssemblyType" */
export type C_AssemblyType_Aggregate = {
  __typename?: 'C_AssemblyType_aggregate';
  aggregate?: Maybe<C_AssemblyType_Aggregate_Fields>;
  nodes: Array<C_AssemblyType>;
};

/** aggregate fields of "C_AssemblyType" */
export type C_AssemblyType_Aggregate_Fields = {
  __typename?: 'C_AssemblyType_aggregate_fields';
  avg?: Maybe<C_AssemblyType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_AssemblyType_Max_Fields>;
  min?: Maybe<C_AssemblyType_Min_Fields>;
  stddev?: Maybe<C_AssemblyType_Stddev_Fields>;
  stddev_pop?: Maybe<C_AssemblyType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_AssemblyType_Stddev_Samp_Fields>;
  sum?: Maybe<C_AssemblyType_Sum_Fields>;
  var_pop?: Maybe<C_AssemblyType_Var_Pop_Fields>;
  var_samp?: Maybe<C_AssemblyType_Var_Samp_Fields>;
  variance?: Maybe<C_AssemblyType_Variance_Fields>;
};


/** aggregate fields of "C_AssemblyType" */
export type C_AssemblyType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_AssemblyType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_AssemblyType_Avg_Fields = {
  __typename?: 'C_AssemblyType_avg_fields';
  AssemblyTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_AssemblyType". All fields are combined with a logical 'AND'. */
export type C_AssemblyType_Bool_Exp = {
  AssemblyTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  AssemblyTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  RoofAssemblies?: Maybe<RoofAssembly_Bool_Exp>;
  _and?: Maybe<Array<C_AssemblyType_Bool_Exp>>;
  _not?: Maybe<C_AssemblyType_Bool_Exp>;
  _or?: Maybe<Array<C_AssemblyType_Bool_Exp>>;
};

/** upsert condition type for table "C_AssemblyType" */
export type C_AssemblyType_If_Matched = {
  match_columns?: Array<C_AssemblyType_Insert_Match_Column>;
  update_columns?: Array<C_AssemblyType_Update_Column>;
  where?: Maybe<C_AssemblyType_Bool_Exp>;
};

/** input type for inserting data into table "C_AssemblyType" */
export type C_AssemblyType_Insert_Input = {
  AssemblyTypeCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_AssemblyType" */
export enum C_AssemblyType_Insert_Match_Column {
  /** column name */
  AssemblyTypeCode = 'AssemblyTypeCode',
  /** column name */
  AssemblyTypeId = 'AssemblyTypeId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_AssemblyType_Max_Fields = {
  __typename?: 'C_AssemblyType_max_fields';
  AssemblyTypeCode?: Maybe<Scalars['CodeTableValue']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_AssemblyType_Min_Fields = {
  __typename?: 'C_AssemblyType_min_fields';
  AssemblyTypeCode?: Maybe<Scalars['CodeTableValue']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_AssemblyType" */
export type C_AssemblyType_Mutation_Response = {
  __typename?: 'C_AssemblyType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_AssemblyType>;
};

/** Ordering options when selecting data from "C_AssemblyType". */
export type C_AssemblyType_Order_By = {
  AssemblyTypeCode?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
  RoofAssemblies_aggregate?: Maybe<RoofAssembly_Aggregate_Order_By>;
};

/** primary key columns input for table: C_AssemblyType */
export type C_AssemblyType_Pk_Columns_Input = {
  AssemblyTypeId: Scalars['Int'];
};

/** select columns of table "C_AssemblyType" */
export enum C_AssemblyType_Select_Column {
  /** column name */
  AssemblyTypeCode = 'AssemblyTypeCode',
  /** column name */
  AssemblyTypeId = 'AssemblyTypeId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_AssemblyType" */
export type C_AssemblyType_Set_Input = {
  AssemblyTypeCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_AssemblyType_Stddev_Fields = {
  __typename?: 'C_AssemblyType_stddev_fields';
  AssemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_AssemblyType_Stddev_Pop_Fields = {
  __typename?: 'C_AssemblyType_stddev_pop_fields';
  AssemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_AssemblyType_Stddev_Samp_Fields = {
  __typename?: 'C_AssemblyType_stddev_samp_fields';
  AssemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_AssemblyType_Sum_Fields = {
  __typename?: 'C_AssemblyType_sum_fields';
  AssemblyTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_AssemblyType" */
export enum C_AssemblyType_Update_Column {
  /** column name */
  AssemblyTypeCode = 'AssemblyTypeCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_AssemblyType_Var_Pop_Fields = {
  __typename?: 'C_AssemblyType_var_pop_fields';
  AssemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_AssemblyType_Var_Samp_Fields = {
  __typename?: 'C_AssemblyType_var_samp_fields';
  AssemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_AssemblyType_Variance_Fields = {
  __typename?: 'C_AssemblyType_variance_fields';
  AssemblyTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_BackingMaterial" */
export type C_BackingMaterial = {
  __typename?: 'C_BackingMaterial';
  BackingMaterialCode: Scalars['CodeTableValue'];
  BackingMaterialId: Scalars['Int'];
  /** An array relationship */
  BasePlies: Array<BasePly>;
  /** An aggregate relationship */
  BasePlies_aggregate: BasePly_Aggregate;
  /** An array relationship */
  BaseSheets: Array<BaseSheet>;
  /** An aggregate relationship */
  BaseSheets_aggregate: BaseSheet_Aggregate;
  /** An array relationship */
  CapPlies: Array<CapPly>;
  /** An aggregate relationship */
  CapPlies_aggregate: CapPly_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  OtherPlies: Array<OtherPly>;
  /** An aggregate relationship */
  OtherPlies_aggregate: OtherPly_Aggregate;
  /** An array relationship */
  SinglePlyCovers: Array<SinglePlyCover>;
  /** An aggregate relationship */
  SinglePlyCovers_aggregate: SinglePlyCover_Aggregate;
};


/** columns and relationships of "C_BackingMaterial" */
export type C_BackingMaterialBasePliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BasePly_Order_By>>;
  where?: Maybe<BasePly_Bool_Exp>;
};


/** columns and relationships of "C_BackingMaterial" */
export type C_BackingMaterialBasePlies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BasePly_Order_By>>;
  where?: Maybe<BasePly_Bool_Exp>;
};


/** columns and relationships of "C_BackingMaterial" */
export type C_BackingMaterialBaseSheetsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseSheet_Order_By>>;
  where?: Maybe<BaseSheet_Bool_Exp>;
};


/** columns and relationships of "C_BackingMaterial" */
export type C_BackingMaterialBaseSheets_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseSheet_Order_By>>;
  where?: Maybe<BaseSheet_Bool_Exp>;
};


/** columns and relationships of "C_BackingMaterial" */
export type C_BackingMaterialCapPliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPly_Order_By>>;
  where?: Maybe<CapPly_Bool_Exp>;
};


/** columns and relationships of "C_BackingMaterial" */
export type C_BackingMaterialCapPlies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPly_Order_By>>;
  where?: Maybe<CapPly_Bool_Exp>;
};


/** columns and relationships of "C_BackingMaterial" */
export type C_BackingMaterialOtherPliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OtherPly_Order_By>>;
  where?: Maybe<OtherPly_Bool_Exp>;
};


/** columns and relationships of "C_BackingMaterial" */
export type C_BackingMaterialOtherPlies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OtherPly_Order_By>>;
  where?: Maybe<OtherPly_Bool_Exp>;
};


/** columns and relationships of "C_BackingMaterial" */
export type C_BackingMaterialSinglePlyCoversArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyCover_Order_By>>;
  where?: Maybe<SinglePlyCover_Bool_Exp>;
};


/** columns and relationships of "C_BackingMaterial" */
export type C_BackingMaterialSinglePlyCovers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyCover_Order_By>>;
  where?: Maybe<SinglePlyCover_Bool_Exp>;
};

/** aggregated selection of "C_BackingMaterial" */
export type C_BackingMaterial_Aggregate = {
  __typename?: 'C_BackingMaterial_aggregate';
  aggregate?: Maybe<C_BackingMaterial_Aggregate_Fields>;
  nodes: Array<C_BackingMaterial>;
};

/** aggregate fields of "C_BackingMaterial" */
export type C_BackingMaterial_Aggregate_Fields = {
  __typename?: 'C_BackingMaterial_aggregate_fields';
  avg?: Maybe<C_BackingMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_BackingMaterial_Max_Fields>;
  min?: Maybe<C_BackingMaterial_Min_Fields>;
  stddev?: Maybe<C_BackingMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_BackingMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_BackingMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_BackingMaterial_Sum_Fields>;
  var_pop?: Maybe<C_BackingMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_BackingMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_BackingMaterial_Variance_Fields>;
};


/** aggregate fields of "C_BackingMaterial" */
export type C_BackingMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_BackingMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_BackingMaterial_Avg_Fields = {
  __typename?: 'C_BackingMaterial_avg_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_BackingMaterial". All fields are combined with a logical 'AND'. */
export type C_BackingMaterial_Bool_Exp = {
  BackingMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  BackingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  BasePlies?: Maybe<BasePly_Bool_Exp>;
  BaseSheets?: Maybe<BaseSheet_Bool_Exp>;
  CapPlies?: Maybe<CapPly_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  OtherPlies?: Maybe<OtherPly_Bool_Exp>;
  SinglePlyCovers?: Maybe<SinglePlyCover_Bool_Exp>;
  _and?: Maybe<Array<C_BackingMaterial_Bool_Exp>>;
  _not?: Maybe<C_BackingMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_BackingMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_BackingMaterial" */
export type C_BackingMaterial_If_Matched = {
  match_columns?: Array<C_BackingMaterial_Insert_Match_Column>;
  update_columns?: Array<C_BackingMaterial_Update_Column>;
  where?: Maybe<C_BackingMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_BackingMaterial" */
export type C_BackingMaterial_Insert_Input = {
  BackingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_BackingMaterial" */
export enum C_BackingMaterial_Insert_Match_Column {
  /** column name */
  BackingMaterialCode = 'BackingMaterialCode',
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_BackingMaterial_Max_Fields = {
  __typename?: 'C_BackingMaterial_max_fields';
  BackingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  BackingMaterialId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_BackingMaterial_Min_Fields = {
  __typename?: 'C_BackingMaterial_min_fields';
  BackingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  BackingMaterialId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_BackingMaterial" */
export type C_BackingMaterial_Mutation_Response = {
  __typename?: 'C_BackingMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_BackingMaterial>;
};

/** Ordering options when selecting data from "C_BackingMaterial". */
export type C_BackingMaterial_Order_By = {
  BackingMaterialCode?: Maybe<Order_By>;
  BackingMaterialId?: Maybe<Order_By>;
  BasePlies_aggregate?: Maybe<BasePly_Aggregate_Order_By>;
  BaseSheets_aggregate?: Maybe<BaseSheet_Aggregate_Order_By>;
  CapPlies_aggregate?: Maybe<CapPly_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  OtherPlies_aggregate?: Maybe<OtherPly_Aggregate_Order_By>;
  SinglePlyCovers_aggregate?: Maybe<SinglePlyCover_Aggregate_Order_By>;
};

/** primary key columns input for table: C_BackingMaterial */
export type C_BackingMaterial_Pk_Columns_Input = {
  BackingMaterialId: Scalars['Int'];
};

/** select columns of table "C_BackingMaterial" */
export enum C_BackingMaterial_Select_Column {
  /** column name */
  BackingMaterialCode = 'BackingMaterialCode',
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_BackingMaterial" */
export type C_BackingMaterial_Set_Input = {
  BackingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_BackingMaterial_Stddev_Fields = {
  __typename?: 'C_BackingMaterial_stddev_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_BackingMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_BackingMaterial_stddev_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_BackingMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_BackingMaterial_stddev_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_BackingMaterial_Sum_Fields = {
  __typename?: 'C_BackingMaterial_sum_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_BackingMaterial" */
export enum C_BackingMaterial_Update_Column {
  /** column name */
  BackingMaterialCode = 'BackingMaterialCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_BackingMaterial_Var_Pop_Fields = {
  __typename?: 'C_BackingMaterial_var_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_BackingMaterial_Var_Samp_Fields = {
  __typename?: 'C_BackingMaterial_var_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_BackingMaterial_Variance_Fields = {
  __typename?: 'C_BackingMaterial_variance_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_BaseSheetApplicationMethod" */
export type C_BaseSheetApplicationMethod = {
  __typename?: 'C_BaseSheetApplicationMethod';
  BaseSheetApplicationMethodCode: Scalars['CodeTableValue'];
  BaseSheetApplicationMethodId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
};

/** aggregated selection of "C_BaseSheetApplicationMethod" */
export type C_BaseSheetApplicationMethod_Aggregate = {
  __typename?: 'C_BaseSheetApplicationMethod_aggregate';
  aggregate?: Maybe<C_BaseSheetApplicationMethod_Aggregate_Fields>;
  nodes: Array<C_BaseSheetApplicationMethod>;
};

/** aggregate fields of "C_BaseSheetApplicationMethod" */
export type C_BaseSheetApplicationMethod_Aggregate_Fields = {
  __typename?: 'C_BaseSheetApplicationMethod_aggregate_fields';
  avg?: Maybe<C_BaseSheetApplicationMethod_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_BaseSheetApplicationMethod_Max_Fields>;
  min?: Maybe<C_BaseSheetApplicationMethod_Min_Fields>;
  stddev?: Maybe<C_BaseSheetApplicationMethod_Stddev_Fields>;
  stddev_pop?: Maybe<C_BaseSheetApplicationMethod_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_BaseSheetApplicationMethod_Stddev_Samp_Fields>;
  sum?: Maybe<C_BaseSheetApplicationMethod_Sum_Fields>;
  var_pop?: Maybe<C_BaseSheetApplicationMethod_Var_Pop_Fields>;
  var_samp?: Maybe<C_BaseSheetApplicationMethod_Var_Samp_Fields>;
  variance?: Maybe<C_BaseSheetApplicationMethod_Variance_Fields>;
};


/** aggregate fields of "C_BaseSheetApplicationMethod" */
export type C_BaseSheetApplicationMethod_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_BaseSheetApplicationMethod_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_BaseSheetApplicationMethod_Avg_Fields = {
  __typename?: 'C_BaseSheetApplicationMethod_avg_fields';
  BaseSheetApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_BaseSheetApplicationMethod". All fields are combined with a logical 'AND'. */
export type C_BaseSheetApplicationMethod_Bool_Exp = {
  BaseSheetApplicationMethodCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  BaseSheetApplicationMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_BaseSheetApplicationMethod_Bool_Exp>>;
  _not?: Maybe<C_BaseSheetApplicationMethod_Bool_Exp>;
  _or?: Maybe<Array<C_BaseSheetApplicationMethod_Bool_Exp>>;
};

/** upsert condition type for table "C_BaseSheetApplicationMethod" */
export type C_BaseSheetApplicationMethod_If_Matched = {
  match_columns?: Array<C_BaseSheetApplicationMethod_Insert_Match_Column>;
  update_columns?: Array<C_BaseSheetApplicationMethod_Update_Column>;
  where?: Maybe<C_BaseSheetApplicationMethod_Bool_Exp>;
};

/** input type for inserting data into table "C_BaseSheetApplicationMethod" */
export type C_BaseSheetApplicationMethod_Insert_Input = {
  BaseSheetApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_BaseSheetApplicationMethod" */
export enum C_BaseSheetApplicationMethod_Insert_Match_Column {
  /** column name */
  BaseSheetApplicationMethodCode = 'BaseSheetApplicationMethodCode',
  /** column name */
  BaseSheetApplicationMethodId = 'BaseSheetApplicationMethodId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_BaseSheetApplicationMethod_Max_Fields = {
  __typename?: 'C_BaseSheetApplicationMethod_max_fields';
  BaseSheetApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
  BaseSheetApplicationMethodId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_BaseSheetApplicationMethod_Min_Fields = {
  __typename?: 'C_BaseSheetApplicationMethod_min_fields';
  BaseSheetApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
  BaseSheetApplicationMethodId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_BaseSheetApplicationMethod" */
export type C_BaseSheetApplicationMethod_Mutation_Response = {
  __typename?: 'C_BaseSheetApplicationMethod_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_BaseSheetApplicationMethod>;
};

/** Ordering options when selecting data from "C_BaseSheetApplicationMethod". */
export type C_BaseSheetApplicationMethod_Order_By = {
  BaseSheetApplicationMethodCode?: Maybe<Order_By>;
  BaseSheetApplicationMethodId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** primary key columns input for table: C_BaseSheetApplicationMethod */
export type C_BaseSheetApplicationMethod_Pk_Columns_Input = {
  BaseSheetApplicationMethodId: Scalars['Int'];
};

/** select columns of table "C_BaseSheetApplicationMethod" */
export enum C_BaseSheetApplicationMethod_Select_Column {
  /** column name */
  BaseSheetApplicationMethodCode = 'BaseSheetApplicationMethodCode',
  /** column name */
  BaseSheetApplicationMethodId = 'BaseSheetApplicationMethodId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_BaseSheetApplicationMethod" */
export type C_BaseSheetApplicationMethod_Set_Input = {
  BaseSheetApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_BaseSheetApplicationMethod_Stddev_Fields = {
  __typename?: 'C_BaseSheetApplicationMethod_stddev_fields';
  BaseSheetApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_BaseSheetApplicationMethod_Stddev_Pop_Fields = {
  __typename?: 'C_BaseSheetApplicationMethod_stddev_pop_fields';
  BaseSheetApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_BaseSheetApplicationMethod_Stddev_Samp_Fields = {
  __typename?: 'C_BaseSheetApplicationMethod_stddev_samp_fields';
  BaseSheetApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_BaseSheetApplicationMethod_Sum_Fields = {
  __typename?: 'C_BaseSheetApplicationMethod_sum_fields';
  BaseSheetApplicationMethodId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_BaseSheetApplicationMethod" */
export enum C_BaseSheetApplicationMethod_Update_Column {
  /** column name */
  BaseSheetApplicationMethodCode = 'BaseSheetApplicationMethodCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_BaseSheetApplicationMethod_Var_Pop_Fields = {
  __typename?: 'C_BaseSheetApplicationMethod_var_pop_fields';
  BaseSheetApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_BaseSheetApplicationMethod_Var_Samp_Fields = {
  __typename?: 'C_BaseSheetApplicationMethod_var_samp_fields';
  BaseSheetApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_BaseSheetApplicationMethod_Variance_Fields = {
  __typename?: 'C_BaseSheetApplicationMethod_variance_fields';
  BaseSheetApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_BattBlanketFacerMaterial" */
export type C_BattBlanketFacerMaterial = {
  __typename?: 'C_BattBlanketFacerMaterial';
  BattBlanketFacerMaterialCode: Scalars['CodeTableValue'];
  BattBlanketFacerMaterialId: Scalars['Int'];
  /** An array relationship */
  BattBlanketInsulations: Array<BattBlanketInsulation>;
  /** An aggregate relationship */
  BattBlanketInsulations_aggregate: BattBlanketInsulation_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
};


/** columns and relationships of "C_BattBlanketFacerMaterial" */
export type C_BattBlanketFacerMaterialBattBlanketInsulationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattBlanketInsulation_Order_By>>;
  where?: Maybe<BattBlanketInsulation_Bool_Exp>;
};


/** columns and relationships of "C_BattBlanketFacerMaterial" */
export type C_BattBlanketFacerMaterialBattBlanketInsulations_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattBlanketInsulation_Order_By>>;
  where?: Maybe<BattBlanketInsulation_Bool_Exp>;
};

/** aggregated selection of "C_BattBlanketFacerMaterial" */
export type C_BattBlanketFacerMaterial_Aggregate = {
  __typename?: 'C_BattBlanketFacerMaterial_aggregate';
  aggregate?: Maybe<C_BattBlanketFacerMaterial_Aggregate_Fields>;
  nodes: Array<C_BattBlanketFacerMaterial>;
};

/** aggregate fields of "C_BattBlanketFacerMaterial" */
export type C_BattBlanketFacerMaterial_Aggregate_Fields = {
  __typename?: 'C_BattBlanketFacerMaterial_aggregate_fields';
  avg?: Maybe<C_BattBlanketFacerMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_BattBlanketFacerMaterial_Max_Fields>;
  min?: Maybe<C_BattBlanketFacerMaterial_Min_Fields>;
  stddev?: Maybe<C_BattBlanketFacerMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_BattBlanketFacerMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_BattBlanketFacerMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_BattBlanketFacerMaterial_Sum_Fields>;
  var_pop?: Maybe<C_BattBlanketFacerMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_BattBlanketFacerMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_BattBlanketFacerMaterial_Variance_Fields>;
};


/** aggregate fields of "C_BattBlanketFacerMaterial" */
export type C_BattBlanketFacerMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_BattBlanketFacerMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_BattBlanketFacerMaterial_Avg_Fields = {
  __typename?: 'C_BattBlanketFacerMaterial_avg_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_BattBlanketFacerMaterial". All fields are combined with a logical 'AND'. */
export type C_BattBlanketFacerMaterial_Bool_Exp = {
  BattBlanketFacerMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  BattBlanketFacerMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  BattBlanketInsulations?: Maybe<BattBlanketInsulation_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_BattBlanketFacerMaterial_Bool_Exp>>;
  _not?: Maybe<C_BattBlanketFacerMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_BattBlanketFacerMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_BattBlanketFacerMaterial" */
export type C_BattBlanketFacerMaterial_If_Matched = {
  match_columns?: Array<C_BattBlanketFacerMaterial_Insert_Match_Column>;
  update_columns?: Array<C_BattBlanketFacerMaterial_Update_Column>;
  where?: Maybe<C_BattBlanketFacerMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_BattBlanketFacerMaterial" */
export type C_BattBlanketFacerMaterial_Insert_Input = {
  BattBlanketFacerMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_BattBlanketFacerMaterial" */
export enum C_BattBlanketFacerMaterial_Insert_Match_Column {
  /** column name */
  BattBlanketFacerMaterialCode = 'BattBlanketFacerMaterialCode',
  /** column name */
  BattBlanketFacerMaterialId = 'BattBlanketFacerMaterialId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_BattBlanketFacerMaterial_Max_Fields = {
  __typename?: 'C_BattBlanketFacerMaterial_max_fields';
  BattBlanketFacerMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_BattBlanketFacerMaterial_Min_Fields = {
  __typename?: 'C_BattBlanketFacerMaterial_min_fields';
  BattBlanketFacerMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_BattBlanketFacerMaterial" */
export type C_BattBlanketFacerMaterial_Mutation_Response = {
  __typename?: 'C_BattBlanketFacerMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_BattBlanketFacerMaterial>;
};

/** Ordering options when selecting data from "C_BattBlanketFacerMaterial". */
export type C_BattBlanketFacerMaterial_Order_By = {
  BattBlanketFacerMaterialCode?: Maybe<Order_By>;
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketInsulations_aggregate?: Maybe<BattBlanketInsulation_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** primary key columns input for table: C_BattBlanketFacerMaterial */
export type C_BattBlanketFacerMaterial_Pk_Columns_Input = {
  BattBlanketFacerMaterialId: Scalars['Int'];
};

/** select columns of table "C_BattBlanketFacerMaterial" */
export enum C_BattBlanketFacerMaterial_Select_Column {
  /** column name */
  BattBlanketFacerMaterialCode = 'BattBlanketFacerMaterialCode',
  /** column name */
  BattBlanketFacerMaterialId = 'BattBlanketFacerMaterialId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_BattBlanketFacerMaterial" */
export type C_BattBlanketFacerMaterial_Set_Input = {
  BattBlanketFacerMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_BattBlanketFacerMaterial_Stddev_Fields = {
  __typename?: 'C_BattBlanketFacerMaterial_stddev_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_BattBlanketFacerMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_BattBlanketFacerMaterial_stddev_pop_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_BattBlanketFacerMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_BattBlanketFacerMaterial_stddev_samp_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_BattBlanketFacerMaterial_Sum_Fields = {
  __typename?: 'C_BattBlanketFacerMaterial_sum_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_BattBlanketFacerMaterial" */
export enum C_BattBlanketFacerMaterial_Update_Column {
  /** column name */
  BattBlanketFacerMaterialCode = 'BattBlanketFacerMaterialCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_BattBlanketFacerMaterial_Var_Pop_Fields = {
  __typename?: 'C_BattBlanketFacerMaterial_var_pop_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_BattBlanketFacerMaterial_Var_Samp_Fields = {
  __typename?: 'C_BattBlanketFacerMaterial_var_samp_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_BattBlanketFacerMaterial_Variance_Fields = {
  __typename?: 'C_BattBlanketFacerMaterial_variance_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_BattBlanketMaterial" */
export type C_BattBlanketMaterial = {
  __typename?: 'C_BattBlanketMaterial';
  /** An array relationship */
  BattBlanketInsulations: Array<BattBlanketInsulation>;
  /** An aggregate relationship */
  BattBlanketInsulations_aggregate: BattBlanketInsulation_Aggregate;
  BattBlanketMaterialCode: Scalars['CodeTableValue'];
  BattBlanketMaterialId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
};


/** columns and relationships of "C_BattBlanketMaterial" */
export type C_BattBlanketMaterialBattBlanketInsulationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattBlanketInsulation_Order_By>>;
  where?: Maybe<BattBlanketInsulation_Bool_Exp>;
};


/** columns and relationships of "C_BattBlanketMaterial" */
export type C_BattBlanketMaterialBattBlanketInsulations_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattBlanketInsulation_Order_By>>;
  where?: Maybe<BattBlanketInsulation_Bool_Exp>;
};

/** aggregated selection of "C_BattBlanketMaterial" */
export type C_BattBlanketMaterial_Aggregate = {
  __typename?: 'C_BattBlanketMaterial_aggregate';
  aggregate?: Maybe<C_BattBlanketMaterial_Aggregate_Fields>;
  nodes: Array<C_BattBlanketMaterial>;
};

/** aggregate fields of "C_BattBlanketMaterial" */
export type C_BattBlanketMaterial_Aggregate_Fields = {
  __typename?: 'C_BattBlanketMaterial_aggregate_fields';
  avg?: Maybe<C_BattBlanketMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_BattBlanketMaterial_Max_Fields>;
  min?: Maybe<C_BattBlanketMaterial_Min_Fields>;
  stddev?: Maybe<C_BattBlanketMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_BattBlanketMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_BattBlanketMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_BattBlanketMaterial_Sum_Fields>;
  var_pop?: Maybe<C_BattBlanketMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_BattBlanketMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_BattBlanketMaterial_Variance_Fields>;
};


/** aggregate fields of "C_BattBlanketMaterial" */
export type C_BattBlanketMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_BattBlanketMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_BattBlanketMaterial_Avg_Fields = {
  __typename?: 'C_BattBlanketMaterial_avg_fields';
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_BattBlanketMaterial". All fields are combined with a logical 'AND'. */
export type C_BattBlanketMaterial_Bool_Exp = {
  BattBlanketInsulations?: Maybe<BattBlanketInsulation_Bool_Exp>;
  BattBlanketMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  BattBlanketMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_BattBlanketMaterial_Bool_Exp>>;
  _not?: Maybe<C_BattBlanketMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_BattBlanketMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_BattBlanketMaterial" */
export type C_BattBlanketMaterial_If_Matched = {
  match_columns?: Array<C_BattBlanketMaterial_Insert_Match_Column>;
  update_columns?: Array<C_BattBlanketMaterial_Update_Column>;
  where?: Maybe<C_BattBlanketMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_BattBlanketMaterial" */
export type C_BattBlanketMaterial_Insert_Input = {
  BattBlanketMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_BattBlanketMaterial" */
export enum C_BattBlanketMaterial_Insert_Match_Column {
  /** column name */
  BattBlanketMaterialCode = 'BattBlanketMaterialCode',
  /** column name */
  BattBlanketMaterialId = 'BattBlanketMaterialId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_BattBlanketMaterial_Max_Fields = {
  __typename?: 'C_BattBlanketMaterial_max_fields';
  BattBlanketMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_BattBlanketMaterial_Min_Fields = {
  __typename?: 'C_BattBlanketMaterial_min_fields';
  BattBlanketMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_BattBlanketMaterial" */
export type C_BattBlanketMaterial_Mutation_Response = {
  __typename?: 'C_BattBlanketMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_BattBlanketMaterial>;
};

/** Ordering options when selecting data from "C_BattBlanketMaterial". */
export type C_BattBlanketMaterial_Order_By = {
  BattBlanketInsulations_aggregate?: Maybe<BattBlanketInsulation_Aggregate_Order_By>;
  BattBlanketMaterialCode?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** primary key columns input for table: C_BattBlanketMaterial */
export type C_BattBlanketMaterial_Pk_Columns_Input = {
  BattBlanketMaterialId: Scalars['Int'];
};

/** select columns of table "C_BattBlanketMaterial" */
export enum C_BattBlanketMaterial_Select_Column {
  /** column name */
  BattBlanketMaterialCode = 'BattBlanketMaterialCode',
  /** column name */
  BattBlanketMaterialId = 'BattBlanketMaterialId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_BattBlanketMaterial" */
export type C_BattBlanketMaterial_Set_Input = {
  BattBlanketMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_BattBlanketMaterial_Stddev_Fields = {
  __typename?: 'C_BattBlanketMaterial_stddev_fields';
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_BattBlanketMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_BattBlanketMaterial_stddev_pop_fields';
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_BattBlanketMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_BattBlanketMaterial_stddev_samp_fields';
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_BattBlanketMaterial_Sum_Fields = {
  __typename?: 'C_BattBlanketMaterial_sum_fields';
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_BattBlanketMaterial" */
export enum C_BattBlanketMaterial_Update_Column {
  /** column name */
  BattBlanketMaterialCode = 'BattBlanketMaterialCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_BattBlanketMaterial_Var_Pop_Fields = {
  __typename?: 'C_BattBlanketMaterial_var_pop_fields';
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_BattBlanketMaterial_Var_Samp_Fields = {
  __typename?: 'C_BattBlanketMaterial_var_samp_fields';
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_BattBlanketMaterial_Variance_Fields = {
  __typename?: 'C_BattBlanketMaterial_variance_fields';
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_BoardProfile" */
export type C_BoardProfile = {
  __typename?: 'C_BoardProfile';
  BoardProfileCode: Scalars['CodeTableValue'];
  BoardProfileId: Scalars['Int'];
  /** An array relationship */
  BoardStocks: Array<BoardStock>;
  /** An aggregate relationship */
  BoardStocks_aggregate: BoardStock_Aggregate;
  /** An array relationship */
  CoverBoards: Array<CoverBoard>;
  /** An aggregate relationship */
  CoverBoards_aggregate: CoverBoard_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  ThermalBarriers: Array<ThermalBarrier>;
  /** An aggregate relationship */
  ThermalBarriers_aggregate: ThermalBarrier_Aggregate;
};


/** columns and relationships of "C_BoardProfile" */
export type C_BoardProfileBoardStocksArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStock_Order_By>>;
  where?: Maybe<BoardStock_Bool_Exp>;
};


/** columns and relationships of "C_BoardProfile" */
export type C_BoardProfileBoardStocks_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStock_Order_By>>;
  where?: Maybe<BoardStock_Bool_Exp>;
};


/** columns and relationships of "C_BoardProfile" */
export type C_BoardProfileCoverBoardsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoard_Order_By>>;
  where?: Maybe<CoverBoard_Bool_Exp>;
};


/** columns and relationships of "C_BoardProfile" */
export type C_BoardProfileCoverBoards_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoard_Order_By>>;
  where?: Maybe<CoverBoard_Bool_Exp>;
};


/** columns and relationships of "C_BoardProfile" */
export type C_BoardProfileThermalBarriersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrier_Order_By>>;
  where?: Maybe<ThermalBarrier_Bool_Exp>;
};


/** columns and relationships of "C_BoardProfile" */
export type C_BoardProfileThermalBarriers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrier_Order_By>>;
  where?: Maybe<ThermalBarrier_Bool_Exp>;
};

/** aggregated selection of "C_BoardProfile" */
export type C_BoardProfile_Aggregate = {
  __typename?: 'C_BoardProfile_aggregate';
  aggregate?: Maybe<C_BoardProfile_Aggregate_Fields>;
  nodes: Array<C_BoardProfile>;
};

/** aggregate fields of "C_BoardProfile" */
export type C_BoardProfile_Aggregate_Fields = {
  __typename?: 'C_BoardProfile_aggregate_fields';
  avg?: Maybe<C_BoardProfile_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_BoardProfile_Max_Fields>;
  min?: Maybe<C_BoardProfile_Min_Fields>;
  stddev?: Maybe<C_BoardProfile_Stddev_Fields>;
  stddev_pop?: Maybe<C_BoardProfile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_BoardProfile_Stddev_Samp_Fields>;
  sum?: Maybe<C_BoardProfile_Sum_Fields>;
  var_pop?: Maybe<C_BoardProfile_Var_Pop_Fields>;
  var_samp?: Maybe<C_BoardProfile_Var_Samp_Fields>;
  variance?: Maybe<C_BoardProfile_Variance_Fields>;
};


/** aggregate fields of "C_BoardProfile" */
export type C_BoardProfile_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_BoardProfile_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_BoardProfile_Avg_Fields = {
  __typename?: 'C_BoardProfile_avg_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_BoardProfile". All fields are combined with a logical 'AND'. */
export type C_BoardProfile_Bool_Exp = {
  BoardProfileCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  BoardProfileId?: Maybe<Int_Mssql_Comparison_Exp>;
  BoardStocks?: Maybe<BoardStock_Bool_Exp>;
  CoverBoards?: Maybe<CoverBoard_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  ThermalBarriers?: Maybe<ThermalBarrier_Bool_Exp>;
  _and?: Maybe<Array<C_BoardProfile_Bool_Exp>>;
  _not?: Maybe<C_BoardProfile_Bool_Exp>;
  _or?: Maybe<Array<C_BoardProfile_Bool_Exp>>;
};

/** upsert condition type for table "C_BoardProfile" */
export type C_BoardProfile_If_Matched = {
  match_columns?: Array<C_BoardProfile_Insert_Match_Column>;
  update_columns?: Array<C_BoardProfile_Update_Column>;
  where?: Maybe<C_BoardProfile_Bool_Exp>;
};

/** input type for inserting data into table "C_BoardProfile" */
export type C_BoardProfile_Insert_Input = {
  BoardProfileCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_BoardProfile" */
export enum C_BoardProfile_Insert_Match_Column {
  /** column name */
  BoardProfileCode = 'BoardProfileCode',
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_BoardProfile_Max_Fields = {
  __typename?: 'C_BoardProfile_max_fields';
  BoardProfileCode?: Maybe<Scalars['CodeTableValue']>;
  BoardProfileId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_BoardProfile_Min_Fields = {
  __typename?: 'C_BoardProfile_min_fields';
  BoardProfileCode?: Maybe<Scalars['CodeTableValue']>;
  BoardProfileId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_BoardProfile" */
export type C_BoardProfile_Mutation_Response = {
  __typename?: 'C_BoardProfile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_BoardProfile>;
};

/** Ordering options when selecting data from "C_BoardProfile". */
export type C_BoardProfile_Order_By = {
  BoardProfileCode?: Maybe<Order_By>;
  BoardProfileId?: Maybe<Order_By>;
  BoardStocks_aggregate?: Maybe<BoardStock_Aggregate_Order_By>;
  CoverBoards_aggregate?: Maybe<CoverBoard_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  ThermalBarriers_aggregate?: Maybe<ThermalBarrier_Aggregate_Order_By>;
};

/** primary key columns input for table: C_BoardProfile */
export type C_BoardProfile_Pk_Columns_Input = {
  BoardProfileId: Scalars['Int'];
};

/** select columns of table "C_BoardProfile" */
export enum C_BoardProfile_Select_Column {
  /** column name */
  BoardProfileCode = 'BoardProfileCode',
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_BoardProfile" */
export type C_BoardProfile_Set_Input = {
  BoardProfileCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_BoardProfile_Stddev_Fields = {
  __typename?: 'C_BoardProfile_stddev_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_BoardProfile_Stddev_Pop_Fields = {
  __typename?: 'C_BoardProfile_stddev_pop_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_BoardProfile_Stddev_Samp_Fields = {
  __typename?: 'C_BoardProfile_stddev_samp_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_BoardProfile_Sum_Fields = {
  __typename?: 'C_BoardProfile_sum_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_BoardProfile" */
export enum C_BoardProfile_Update_Column {
  /** column name */
  BoardProfileCode = 'BoardProfileCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_BoardProfile_Var_Pop_Fields = {
  __typename?: 'C_BoardProfile_var_pop_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_BoardProfile_Var_Samp_Fields = {
  __typename?: 'C_BoardProfile_var_samp_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_BoardProfile_Variance_Fields = {
  __typename?: 'C_BoardProfile_variance_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_BoardStockMaterial" */
export type C_BoardStockMaterial = {
  __typename?: 'C_BoardStockMaterial';
  BoardStockMaterialCode: Scalars['CodeTableValue'];
  BoardStockMaterialId: Scalars['Int'];
  /** An array relationship */
  BoardStocks: Array<BoardStock>;
  /** An aggregate relationship */
  BoardStocks_aggregate: BoardStock_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
};


/** columns and relationships of "C_BoardStockMaterial" */
export type C_BoardStockMaterialBoardStocksArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStock_Order_By>>;
  where?: Maybe<BoardStock_Bool_Exp>;
};


/** columns and relationships of "C_BoardStockMaterial" */
export type C_BoardStockMaterialBoardStocks_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStock_Order_By>>;
  where?: Maybe<BoardStock_Bool_Exp>;
};

/** aggregated selection of "C_BoardStockMaterial" */
export type C_BoardStockMaterial_Aggregate = {
  __typename?: 'C_BoardStockMaterial_aggregate';
  aggregate?: Maybe<C_BoardStockMaterial_Aggregate_Fields>;
  nodes: Array<C_BoardStockMaterial>;
};

/** aggregate fields of "C_BoardStockMaterial" */
export type C_BoardStockMaterial_Aggregate_Fields = {
  __typename?: 'C_BoardStockMaterial_aggregate_fields';
  avg?: Maybe<C_BoardStockMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_BoardStockMaterial_Max_Fields>;
  min?: Maybe<C_BoardStockMaterial_Min_Fields>;
  stddev?: Maybe<C_BoardStockMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_BoardStockMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_BoardStockMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_BoardStockMaterial_Sum_Fields>;
  var_pop?: Maybe<C_BoardStockMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_BoardStockMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_BoardStockMaterial_Variance_Fields>;
};


/** aggregate fields of "C_BoardStockMaterial" */
export type C_BoardStockMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_BoardStockMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_BoardStockMaterial_Avg_Fields = {
  __typename?: 'C_BoardStockMaterial_avg_fields';
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_BoardStockMaterial". All fields are combined with a logical 'AND'. */
export type C_BoardStockMaterial_Bool_Exp = {
  BoardStockMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  BoardStockMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  BoardStocks?: Maybe<BoardStock_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_BoardStockMaterial_Bool_Exp>>;
  _not?: Maybe<C_BoardStockMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_BoardStockMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_BoardStockMaterial" */
export type C_BoardStockMaterial_If_Matched = {
  match_columns?: Array<C_BoardStockMaterial_Insert_Match_Column>;
  update_columns?: Array<C_BoardStockMaterial_Update_Column>;
  where?: Maybe<C_BoardStockMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_BoardStockMaterial" */
export type C_BoardStockMaterial_Insert_Input = {
  BoardStockMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_BoardStockMaterial" */
export enum C_BoardStockMaterial_Insert_Match_Column {
  /** column name */
  BoardStockMaterialCode = 'BoardStockMaterialCode',
  /** column name */
  BoardStockMaterialId = 'BoardStockMaterialId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_BoardStockMaterial_Max_Fields = {
  __typename?: 'C_BoardStockMaterial_max_fields';
  BoardStockMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_BoardStockMaterial_Min_Fields = {
  __typename?: 'C_BoardStockMaterial_min_fields';
  BoardStockMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_BoardStockMaterial" */
export type C_BoardStockMaterial_Mutation_Response = {
  __typename?: 'C_BoardStockMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_BoardStockMaterial>;
};

/** Ordering options when selecting data from "C_BoardStockMaterial". */
export type C_BoardStockMaterial_Order_By = {
  BoardStockMaterialCode?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  BoardStocks_aggregate?: Maybe<BoardStock_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** primary key columns input for table: C_BoardStockMaterial */
export type C_BoardStockMaterial_Pk_Columns_Input = {
  BoardStockMaterialId: Scalars['Int'];
};

/** select columns of table "C_BoardStockMaterial" */
export enum C_BoardStockMaterial_Select_Column {
  /** column name */
  BoardStockMaterialCode = 'BoardStockMaterialCode',
  /** column name */
  BoardStockMaterialId = 'BoardStockMaterialId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_BoardStockMaterial" */
export type C_BoardStockMaterial_Set_Input = {
  BoardStockMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_BoardStockMaterial_Stddev_Fields = {
  __typename?: 'C_BoardStockMaterial_stddev_fields';
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_BoardStockMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_BoardStockMaterial_stddev_pop_fields';
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_BoardStockMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_BoardStockMaterial_stddev_samp_fields';
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_BoardStockMaterial_Sum_Fields = {
  __typename?: 'C_BoardStockMaterial_sum_fields';
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_BoardStockMaterial" */
export enum C_BoardStockMaterial_Update_Column {
  /** column name */
  BoardStockMaterialCode = 'BoardStockMaterialCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_BoardStockMaterial_Var_Pop_Fields = {
  __typename?: 'C_BoardStockMaterial_var_pop_fields';
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_BoardStockMaterial_Var_Samp_Fields = {
  __typename?: 'C_BoardStockMaterial_var_samp_fields';
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_BoardStockMaterial_Variance_Fields = {
  __typename?: 'C_BoardStockMaterial_variance_fields';
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_BuildingEnclosure" */
export type C_BuildingEnclosure = {
  __typename?: 'C_BuildingEnclosure';
  BuildingEnclosureCode: Scalars['CodeTableValue'];
  BuildingEnclosureId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  RoofAreas: Array<RoofArea>;
  /** An aggregate relationship */
  RoofAreas_aggregate: RoofArea_Aggregate;
};


/** columns and relationships of "C_BuildingEnclosure" */
export type C_BuildingEnclosureRoofAreasArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};


/** columns and relationships of "C_BuildingEnclosure" */
export type C_BuildingEnclosureRoofAreas_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};

/** aggregated selection of "C_BuildingEnclosure" */
export type C_BuildingEnclosure_Aggregate = {
  __typename?: 'C_BuildingEnclosure_aggregate';
  aggregate?: Maybe<C_BuildingEnclosure_Aggregate_Fields>;
  nodes: Array<C_BuildingEnclosure>;
};

/** aggregate fields of "C_BuildingEnclosure" */
export type C_BuildingEnclosure_Aggregate_Fields = {
  __typename?: 'C_BuildingEnclosure_aggregate_fields';
  avg?: Maybe<C_BuildingEnclosure_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_BuildingEnclosure_Max_Fields>;
  min?: Maybe<C_BuildingEnclosure_Min_Fields>;
  stddev?: Maybe<C_BuildingEnclosure_Stddev_Fields>;
  stddev_pop?: Maybe<C_BuildingEnclosure_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_BuildingEnclosure_Stddev_Samp_Fields>;
  sum?: Maybe<C_BuildingEnclosure_Sum_Fields>;
  var_pop?: Maybe<C_BuildingEnclosure_Var_Pop_Fields>;
  var_samp?: Maybe<C_BuildingEnclosure_Var_Samp_Fields>;
  variance?: Maybe<C_BuildingEnclosure_Variance_Fields>;
};


/** aggregate fields of "C_BuildingEnclosure" */
export type C_BuildingEnclosure_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_BuildingEnclosure_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_BuildingEnclosure_Avg_Fields = {
  __typename?: 'C_BuildingEnclosure_avg_fields';
  BuildingEnclosureId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_BuildingEnclosure". All fields are combined with a logical 'AND'. */
export type C_BuildingEnclosure_Bool_Exp = {
  BuildingEnclosureCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  BuildingEnclosureId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  RoofAreas?: Maybe<RoofArea_Bool_Exp>;
  _and?: Maybe<Array<C_BuildingEnclosure_Bool_Exp>>;
  _not?: Maybe<C_BuildingEnclosure_Bool_Exp>;
  _or?: Maybe<Array<C_BuildingEnclosure_Bool_Exp>>;
};

/** upsert condition type for table "C_BuildingEnclosure" */
export type C_BuildingEnclosure_If_Matched = {
  match_columns?: Array<C_BuildingEnclosure_Insert_Match_Column>;
  update_columns?: Array<C_BuildingEnclosure_Update_Column>;
  where?: Maybe<C_BuildingEnclosure_Bool_Exp>;
};

/** input type for inserting data into table "C_BuildingEnclosure" */
export type C_BuildingEnclosure_Insert_Input = {
  BuildingEnclosureCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_BuildingEnclosure" */
export enum C_BuildingEnclosure_Insert_Match_Column {
  /** column name */
  BuildingEnclosureCode = 'BuildingEnclosureCode',
  /** column name */
  BuildingEnclosureId = 'BuildingEnclosureId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_BuildingEnclosure_Max_Fields = {
  __typename?: 'C_BuildingEnclosure_max_fields';
  BuildingEnclosureCode?: Maybe<Scalars['CodeTableValue']>;
  BuildingEnclosureId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_BuildingEnclosure_Min_Fields = {
  __typename?: 'C_BuildingEnclosure_min_fields';
  BuildingEnclosureCode?: Maybe<Scalars['CodeTableValue']>;
  BuildingEnclosureId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_BuildingEnclosure" */
export type C_BuildingEnclosure_Mutation_Response = {
  __typename?: 'C_BuildingEnclosure_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_BuildingEnclosure>;
};

/** Ordering options when selecting data from "C_BuildingEnclosure". */
export type C_BuildingEnclosure_Order_By = {
  BuildingEnclosureCode?: Maybe<Order_By>;
  BuildingEnclosureId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
  RoofAreas_aggregate?: Maybe<RoofArea_Aggregate_Order_By>;
};

/** primary key columns input for table: C_BuildingEnclosure */
export type C_BuildingEnclosure_Pk_Columns_Input = {
  BuildingEnclosureId: Scalars['Int'];
};

/** select columns of table "C_BuildingEnclosure" */
export enum C_BuildingEnclosure_Select_Column {
  /** column name */
  BuildingEnclosureCode = 'BuildingEnclosureCode',
  /** column name */
  BuildingEnclosureId = 'BuildingEnclosureId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_BuildingEnclosure" */
export type C_BuildingEnclosure_Set_Input = {
  BuildingEnclosureCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_BuildingEnclosure_Stddev_Fields = {
  __typename?: 'C_BuildingEnclosure_stddev_fields';
  BuildingEnclosureId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_BuildingEnclosure_Stddev_Pop_Fields = {
  __typename?: 'C_BuildingEnclosure_stddev_pop_fields';
  BuildingEnclosureId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_BuildingEnclosure_Stddev_Samp_Fields = {
  __typename?: 'C_BuildingEnclosure_stddev_samp_fields';
  BuildingEnclosureId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_BuildingEnclosure_Sum_Fields = {
  __typename?: 'C_BuildingEnclosure_sum_fields';
  BuildingEnclosureId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_BuildingEnclosure" */
export enum C_BuildingEnclosure_Update_Column {
  /** column name */
  BuildingEnclosureCode = 'BuildingEnclosureCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_BuildingEnclosure_Var_Pop_Fields = {
  __typename?: 'C_BuildingEnclosure_var_pop_fields';
  BuildingEnclosureId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_BuildingEnclosure_Var_Samp_Fields = {
  __typename?: 'C_BuildingEnclosure_var_samp_fields';
  BuildingEnclosureId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_BuildingEnclosure_Variance_Fields = {
  __typename?: 'C_BuildingEnclosure_variance_fields';
  BuildingEnclosureId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_CementAggregateRatio" */
export type C_CementAggregateRatio = {
  __typename?: 'C_CementAggregateRatio';
  CementAggregateRatioCode: Scalars['CodeTableValue'];
  CementAggregateRatioId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  LightweightInsulatingConcretes: Array<LightweightInsulatingConcrete>;
  /** An aggregate relationship */
  LightweightInsulatingConcretes_aggregate: LightweightInsulatingConcrete_Aggregate;
};


/** columns and relationships of "C_CementAggregateRatio" */
export type C_CementAggregateRatioLightweightInsulatingConcretesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
};


/** columns and relationships of "C_CementAggregateRatio" */
export type C_CementAggregateRatioLightweightInsulatingConcretes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
};

/** aggregated selection of "C_CementAggregateRatio" */
export type C_CementAggregateRatio_Aggregate = {
  __typename?: 'C_CementAggregateRatio_aggregate';
  aggregate?: Maybe<C_CementAggregateRatio_Aggregate_Fields>;
  nodes: Array<C_CementAggregateRatio>;
};

/** aggregate fields of "C_CementAggregateRatio" */
export type C_CementAggregateRatio_Aggregate_Fields = {
  __typename?: 'C_CementAggregateRatio_aggregate_fields';
  avg?: Maybe<C_CementAggregateRatio_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_CementAggregateRatio_Max_Fields>;
  min?: Maybe<C_CementAggregateRatio_Min_Fields>;
  stddev?: Maybe<C_CementAggregateRatio_Stddev_Fields>;
  stddev_pop?: Maybe<C_CementAggregateRatio_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_CementAggregateRatio_Stddev_Samp_Fields>;
  sum?: Maybe<C_CementAggregateRatio_Sum_Fields>;
  var_pop?: Maybe<C_CementAggregateRatio_Var_Pop_Fields>;
  var_samp?: Maybe<C_CementAggregateRatio_Var_Samp_Fields>;
  variance?: Maybe<C_CementAggregateRatio_Variance_Fields>;
};


/** aggregate fields of "C_CementAggregateRatio" */
export type C_CementAggregateRatio_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_CementAggregateRatio_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_CementAggregateRatio_Avg_Fields = {
  __typename?: 'C_CementAggregateRatio_avg_fields';
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_CementAggregateRatio". All fields are combined with a logical 'AND'. */
export type C_CementAggregateRatio_Bool_Exp = {
  CementAggregateRatioCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  CementAggregateRatioId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  LightweightInsulatingConcretes?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
  _and?: Maybe<Array<C_CementAggregateRatio_Bool_Exp>>;
  _not?: Maybe<C_CementAggregateRatio_Bool_Exp>;
  _or?: Maybe<Array<C_CementAggregateRatio_Bool_Exp>>;
};

/** upsert condition type for table "C_CementAggregateRatio" */
export type C_CementAggregateRatio_If_Matched = {
  match_columns?: Array<C_CementAggregateRatio_Insert_Match_Column>;
  update_columns?: Array<C_CementAggregateRatio_Update_Column>;
  where?: Maybe<C_CementAggregateRatio_Bool_Exp>;
};

/** input type for inserting data into table "C_CementAggregateRatio" */
export type C_CementAggregateRatio_Insert_Input = {
  CementAggregateRatioCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_CementAggregateRatio" */
export enum C_CementAggregateRatio_Insert_Match_Column {
  /** column name */
  CementAggregateRatioCode = 'CementAggregateRatioCode',
  /** column name */
  CementAggregateRatioId = 'CementAggregateRatioId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_CementAggregateRatio_Max_Fields = {
  __typename?: 'C_CementAggregateRatio_max_fields';
  CementAggregateRatioCode?: Maybe<Scalars['CodeTableValue']>;
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_CementAggregateRatio_Min_Fields = {
  __typename?: 'C_CementAggregateRatio_min_fields';
  CementAggregateRatioCode?: Maybe<Scalars['CodeTableValue']>;
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_CementAggregateRatio" */
export type C_CementAggregateRatio_Mutation_Response = {
  __typename?: 'C_CementAggregateRatio_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_CementAggregateRatio>;
};

/** Ordering options when selecting data from "C_CementAggregateRatio". */
export type C_CementAggregateRatio_Order_By = {
  CementAggregateRatioCode?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
  LightweightInsulatingConcretes_aggregate?: Maybe<LightweightInsulatingConcrete_Aggregate_Order_By>;
};

/** primary key columns input for table: C_CementAggregateRatio */
export type C_CementAggregateRatio_Pk_Columns_Input = {
  CementAggregateRatioId: Scalars['Int'];
};

/** select columns of table "C_CementAggregateRatio" */
export enum C_CementAggregateRatio_Select_Column {
  /** column name */
  CementAggregateRatioCode = 'CementAggregateRatioCode',
  /** column name */
  CementAggregateRatioId = 'CementAggregateRatioId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_CementAggregateRatio" */
export type C_CementAggregateRatio_Set_Input = {
  CementAggregateRatioCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_CementAggregateRatio_Stddev_Fields = {
  __typename?: 'C_CementAggregateRatio_stddev_fields';
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_CementAggregateRatio_Stddev_Pop_Fields = {
  __typename?: 'C_CementAggregateRatio_stddev_pop_fields';
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_CementAggregateRatio_Stddev_Samp_Fields = {
  __typename?: 'C_CementAggregateRatio_stddev_samp_fields';
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_CementAggregateRatio_Sum_Fields = {
  __typename?: 'C_CementAggregateRatio_sum_fields';
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_CementAggregateRatio" */
export enum C_CementAggregateRatio_Update_Column {
  /** column name */
  CementAggregateRatioCode = 'CementAggregateRatioCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_CementAggregateRatio_Var_Pop_Fields = {
  __typename?: 'C_CementAggregateRatio_var_pop_fields';
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_CementAggregateRatio_Var_Samp_Fields = {
  __typename?: 'C_CementAggregateRatio_var_samp_fields';
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_CementAggregateRatio_Variance_Fields = {
  __typename?: 'C_CementAggregateRatio_variance_fields';
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_CoatingMaterial" */
export type C_CoatingMaterial = {
  __typename?: 'C_CoatingMaterial';
  CoatingMaterialCode: Scalars['CodeTableValue'];
  CoatingMaterialId: Scalars['Int'];
  /** An array relationship */
  Coatings: Array<Coating>;
  /** An aggregate relationship */
  Coatings_aggregate: Coating_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
};


/** columns and relationships of "C_CoatingMaterial" */
export type C_CoatingMaterialCoatingsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coating_Order_By>>;
  where?: Maybe<Coating_Bool_Exp>;
};


/** columns and relationships of "C_CoatingMaterial" */
export type C_CoatingMaterialCoatings_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coating_Order_By>>;
  where?: Maybe<Coating_Bool_Exp>;
};

/** aggregated selection of "C_CoatingMaterial" */
export type C_CoatingMaterial_Aggregate = {
  __typename?: 'C_CoatingMaterial_aggregate';
  aggregate?: Maybe<C_CoatingMaterial_Aggregate_Fields>;
  nodes: Array<C_CoatingMaterial>;
};

/** aggregate fields of "C_CoatingMaterial" */
export type C_CoatingMaterial_Aggregate_Fields = {
  __typename?: 'C_CoatingMaterial_aggregate_fields';
  avg?: Maybe<C_CoatingMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_CoatingMaterial_Max_Fields>;
  min?: Maybe<C_CoatingMaterial_Min_Fields>;
  stddev?: Maybe<C_CoatingMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_CoatingMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_CoatingMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_CoatingMaterial_Sum_Fields>;
  var_pop?: Maybe<C_CoatingMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_CoatingMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_CoatingMaterial_Variance_Fields>;
};


/** aggregate fields of "C_CoatingMaterial" */
export type C_CoatingMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_CoatingMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_CoatingMaterial_Avg_Fields = {
  __typename?: 'C_CoatingMaterial_avg_fields';
  CoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_CoatingMaterial". All fields are combined with a logical 'AND'. */
export type C_CoatingMaterial_Bool_Exp = {
  CoatingMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  CoatingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  Coatings?: Maybe<Coating_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_CoatingMaterial_Bool_Exp>>;
  _not?: Maybe<C_CoatingMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_CoatingMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_CoatingMaterial" */
export type C_CoatingMaterial_If_Matched = {
  match_columns?: Array<C_CoatingMaterial_Insert_Match_Column>;
  update_columns?: Array<C_CoatingMaterial_Update_Column>;
  where?: Maybe<C_CoatingMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_CoatingMaterial" */
export type C_CoatingMaterial_Insert_Input = {
  CoatingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_CoatingMaterial" */
export enum C_CoatingMaterial_Insert_Match_Column {
  /** column name */
  CoatingMaterialCode = 'CoatingMaterialCode',
  /** column name */
  CoatingMaterialId = 'CoatingMaterialId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_CoatingMaterial_Max_Fields = {
  __typename?: 'C_CoatingMaterial_max_fields';
  CoatingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  CoatingMaterialId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_CoatingMaterial_Min_Fields = {
  __typename?: 'C_CoatingMaterial_min_fields';
  CoatingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  CoatingMaterialId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_CoatingMaterial" */
export type C_CoatingMaterial_Mutation_Response = {
  __typename?: 'C_CoatingMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_CoatingMaterial>;
};

/** Ordering options when selecting data from "C_CoatingMaterial". */
export type C_CoatingMaterial_Order_By = {
  CoatingMaterialCode?: Maybe<Order_By>;
  CoatingMaterialId?: Maybe<Order_By>;
  Coatings_aggregate?: Maybe<Coating_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** primary key columns input for table: C_CoatingMaterial */
export type C_CoatingMaterial_Pk_Columns_Input = {
  CoatingMaterialId: Scalars['Int'];
};

/** select columns of table "C_CoatingMaterial" */
export enum C_CoatingMaterial_Select_Column {
  /** column name */
  CoatingMaterialCode = 'CoatingMaterialCode',
  /** column name */
  CoatingMaterialId = 'CoatingMaterialId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_CoatingMaterial" */
export type C_CoatingMaterial_Set_Input = {
  CoatingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_CoatingMaterial_Stddev_Fields = {
  __typename?: 'C_CoatingMaterial_stddev_fields';
  CoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_CoatingMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_CoatingMaterial_stddev_pop_fields';
  CoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_CoatingMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_CoatingMaterial_stddev_samp_fields';
  CoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_CoatingMaterial_Sum_Fields = {
  __typename?: 'C_CoatingMaterial_sum_fields';
  CoatingMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_CoatingMaterial" */
export enum C_CoatingMaterial_Update_Column {
  /** column name */
  CoatingMaterialCode = 'CoatingMaterialCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_CoatingMaterial_Var_Pop_Fields = {
  __typename?: 'C_CoatingMaterial_var_pop_fields';
  CoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_CoatingMaterial_Var_Samp_Fields = {
  __typename?: 'C_CoatingMaterial_var_samp_fields';
  CoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_CoatingMaterial_Variance_Fields = {
  __typename?: 'C_CoatingMaterial_variance_fields';
  CoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ComponentType" */
export type C_ComponentType = {
  __typename?: 'C_ComponentType';
  ComponentTypeCode: Scalars['CodeTableValue'];
  ComponentTypeId: Scalars['Int'];
  /** An array relationship */
  Components: Array<Component>;
  /** An aggregate relationship */
  Components_aggregate: Component_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  ItemWorkspaces: Array<ItemWorkspace>;
  /** An aggregate relationship */
  ItemWorkspaces_aggregate: ItemWorkspace_Aggregate;
  /** An array relationship */
  R_ComponentType_ApprovedUses: Array<R_ComponentType_ApprovedUse>;
  /** An aggregate relationship */
  R_ComponentType_ApprovedUses_aggregate: R_ComponentType_ApprovedUse_Aggregate;
  /** An array relationship */
  ReviewerWorkspaces: Array<ReviewerWorkspace>;
  /** An aggregate relationship */
  ReviewerWorkspaces_aggregate: ReviewerWorkspace_Aggregate;
};


/** columns and relationships of "C_ComponentType" */
export type C_ComponentTypeComponentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Order_By>>;
  where?: Maybe<Component_Bool_Exp>;
};


/** columns and relationships of "C_ComponentType" */
export type C_ComponentTypeComponents_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Order_By>>;
  where?: Maybe<Component_Bool_Exp>;
};


/** columns and relationships of "C_ComponentType" */
export type C_ComponentTypeItemWorkspacesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspace_Order_By>>;
  where?: Maybe<ItemWorkspace_Bool_Exp>;
};


/** columns and relationships of "C_ComponentType" */
export type C_ComponentTypeItemWorkspaces_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspace_Order_By>>;
  where?: Maybe<ItemWorkspace_Bool_Exp>;
};


/** columns and relationships of "C_ComponentType" */
export type C_ComponentTypeR_ComponentType_ApprovedUsesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ComponentType_ApprovedUse_Order_By>>;
  where?: Maybe<R_ComponentType_ApprovedUse_Bool_Exp>;
};


/** columns and relationships of "C_ComponentType" */
export type C_ComponentTypeR_ComponentType_ApprovedUses_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ComponentType_ApprovedUse_Order_By>>;
  where?: Maybe<R_ComponentType_ApprovedUse_Bool_Exp>;
};


/** columns and relationships of "C_ComponentType" */
export type C_ComponentTypeReviewerWorkspacesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspace_Order_By>>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};


/** columns and relationships of "C_ComponentType" */
export type C_ComponentTypeReviewerWorkspaces_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspace_Order_By>>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};

/** aggregated selection of "C_ComponentType" */
export type C_ComponentType_Aggregate = {
  __typename?: 'C_ComponentType_aggregate';
  aggregate?: Maybe<C_ComponentType_Aggregate_Fields>;
  nodes: Array<C_ComponentType>;
};

/** aggregate fields of "C_ComponentType" */
export type C_ComponentType_Aggregate_Fields = {
  __typename?: 'C_ComponentType_aggregate_fields';
  avg?: Maybe<C_ComponentType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ComponentType_Max_Fields>;
  min?: Maybe<C_ComponentType_Min_Fields>;
  stddev?: Maybe<C_ComponentType_Stddev_Fields>;
  stddev_pop?: Maybe<C_ComponentType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ComponentType_Stddev_Samp_Fields>;
  sum?: Maybe<C_ComponentType_Sum_Fields>;
  var_pop?: Maybe<C_ComponentType_Var_Pop_Fields>;
  var_samp?: Maybe<C_ComponentType_Var_Samp_Fields>;
  variance?: Maybe<C_ComponentType_Variance_Fields>;
};


/** aggregate fields of "C_ComponentType" */
export type C_ComponentType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ComponentType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ComponentType_Avg_Fields = {
  __typename?: 'C_ComponentType_avg_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ComponentType". All fields are combined with a logical 'AND'. */
export type C_ComponentType_Bool_Exp = {
  ComponentTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ComponentTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Components?: Maybe<Component_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  ItemWorkspaces?: Maybe<ItemWorkspace_Bool_Exp>;
  R_ComponentType_ApprovedUses?: Maybe<R_ComponentType_ApprovedUse_Bool_Exp>;
  ReviewerWorkspaces?: Maybe<ReviewerWorkspace_Bool_Exp>;
  _and?: Maybe<Array<C_ComponentType_Bool_Exp>>;
  _not?: Maybe<C_ComponentType_Bool_Exp>;
  _or?: Maybe<Array<C_ComponentType_Bool_Exp>>;
};

/** upsert condition type for table "C_ComponentType" */
export type C_ComponentType_If_Matched = {
  match_columns?: Array<C_ComponentType_Insert_Match_Column>;
  update_columns?: Array<C_ComponentType_Update_Column>;
  where?: Maybe<C_ComponentType_Bool_Exp>;
};

/** input type for inserting data into table "C_ComponentType" */
export type C_ComponentType_Insert_Input = {
  ComponentTypeCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_ComponentType" */
export enum C_ComponentType_Insert_Match_Column {
  /** column name */
  ComponentTypeCode = 'ComponentTypeCode',
  /** column name */
  ComponentTypeId = 'ComponentTypeId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_ComponentType_Max_Fields = {
  __typename?: 'C_ComponentType_max_fields';
  ComponentTypeCode?: Maybe<Scalars['CodeTableValue']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_ComponentType_Min_Fields = {
  __typename?: 'C_ComponentType_min_fields';
  ComponentTypeCode?: Maybe<Scalars['CodeTableValue']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_ComponentType" */
export type C_ComponentType_Mutation_Response = {
  __typename?: 'C_ComponentType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ComponentType>;
};

/** Ordering options when selecting data from "C_ComponentType". */
export type C_ComponentType_Order_By = {
  ComponentTypeCode?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  Components_aggregate?: Maybe<Component_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  ItemWorkspaces_aggregate?: Maybe<ItemWorkspace_Aggregate_Order_By>;
  R_ComponentType_ApprovedUses_aggregate?: Maybe<R_ComponentType_ApprovedUse_Aggregate_Order_By>;
  ReviewerWorkspaces_aggregate?: Maybe<ReviewerWorkspace_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ComponentType */
export type C_ComponentType_Pk_Columns_Input = {
  ComponentTypeId: Scalars['Int'];
};

/** select columns of table "C_ComponentType" */
export enum C_ComponentType_Select_Column {
  /** column name */
  ComponentTypeCode = 'ComponentTypeCode',
  /** column name */
  ComponentTypeId = 'ComponentTypeId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_ComponentType" */
export type C_ComponentType_Set_Input = {
  ComponentTypeCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_ComponentType_Stddev_Fields = {
  __typename?: 'C_ComponentType_stddev_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ComponentType_Stddev_Pop_Fields = {
  __typename?: 'C_ComponentType_stddev_pop_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ComponentType_Stddev_Samp_Fields = {
  __typename?: 'C_ComponentType_stddev_samp_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ComponentType_Sum_Fields = {
  __typename?: 'C_ComponentType_sum_fields';
  ComponentTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ComponentType" */
export enum C_ComponentType_Update_Column {
  /** column name */
  ComponentTypeCode = 'ComponentTypeCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_ComponentType_Var_Pop_Fields = {
  __typename?: 'C_ComponentType_var_pop_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ComponentType_Var_Samp_Fields = {
  __typename?: 'C_ComponentType_var_samp_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ComponentType_Variance_Fields = {
  __typename?: 'C_ComponentType_variance_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ContactType" */
export type C_ContactType = {
  __typename?: 'C_ContactType';
  ContactTypeCode: Scalars['CodeTableValue'];
  ContactTypeId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  R_ProjectContacts: Array<R_ProjectContact>;
  /** An aggregate relationship */
  R_ProjectContacts_aggregate: R_ProjectContact_Aggregate;
  /** An array relationship */
  R_RoofAreaContacts: Array<R_RoofAreaContact>;
  /** An aggregate relationship */
  R_RoofAreaContacts_aggregate: R_RoofAreaContact_Aggregate;
};


/** columns and relationships of "C_ContactType" */
export type C_ContactTypeR_ProjectContactsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectContact_Order_By>>;
  where?: Maybe<R_ProjectContact_Bool_Exp>;
};


/** columns and relationships of "C_ContactType" */
export type C_ContactTypeR_ProjectContacts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectContact_Order_By>>;
  where?: Maybe<R_ProjectContact_Bool_Exp>;
};


/** columns and relationships of "C_ContactType" */
export type C_ContactTypeR_RoofAreaContactsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaContact_Order_By>>;
  where?: Maybe<R_RoofAreaContact_Bool_Exp>;
};


/** columns and relationships of "C_ContactType" */
export type C_ContactTypeR_RoofAreaContacts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaContact_Order_By>>;
  where?: Maybe<R_RoofAreaContact_Bool_Exp>;
};

/** aggregated selection of "C_ContactType" */
export type C_ContactType_Aggregate = {
  __typename?: 'C_ContactType_aggregate';
  aggregate?: Maybe<C_ContactType_Aggregate_Fields>;
  nodes: Array<C_ContactType>;
};

/** aggregate fields of "C_ContactType" */
export type C_ContactType_Aggregate_Fields = {
  __typename?: 'C_ContactType_aggregate_fields';
  avg?: Maybe<C_ContactType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ContactType_Max_Fields>;
  min?: Maybe<C_ContactType_Min_Fields>;
  stddev?: Maybe<C_ContactType_Stddev_Fields>;
  stddev_pop?: Maybe<C_ContactType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ContactType_Stddev_Samp_Fields>;
  sum?: Maybe<C_ContactType_Sum_Fields>;
  var_pop?: Maybe<C_ContactType_Var_Pop_Fields>;
  var_samp?: Maybe<C_ContactType_Var_Samp_Fields>;
  variance?: Maybe<C_ContactType_Variance_Fields>;
};


/** aggregate fields of "C_ContactType" */
export type C_ContactType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ContactType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ContactType_Avg_Fields = {
  __typename?: 'C_ContactType_avg_fields';
  ContactTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ContactType". All fields are combined with a logical 'AND'. */
export type C_ContactType_Bool_Exp = {
  ContactTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ContactTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  R_ProjectContacts?: Maybe<R_ProjectContact_Bool_Exp>;
  R_RoofAreaContacts?: Maybe<R_RoofAreaContact_Bool_Exp>;
  _and?: Maybe<Array<C_ContactType_Bool_Exp>>;
  _not?: Maybe<C_ContactType_Bool_Exp>;
  _or?: Maybe<Array<C_ContactType_Bool_Exp>>;
};

/** upsert condition type for table "C_ContactType" */
export type C_ContactType_If_Matched = {
  match_columns?: Array<C_ContactType_Insert_Match_Column>;
  update_columns?: Array<C_ContactType_Update_Column>;
  where?: Maybe<C_ContactType_Bool_Exp>;
};

/** input type for inserting data into table "C_ContactType" */
export type C_ContactType_Insert_Input = {
  ContactTypeCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_ContactType" */
export enum C_ContactType_Insert_Match_Column {
  /** column name */
  ContactTypeCode = 'ContactTypeCode',
  /** column name */
  ContactTypeId = 'ContactTypeId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_ContactType_Max_Fields = {
  __typename?: 'C_ContactType_max_fields';
  ContactTypeCode?: Maybe<Scalars['CodeTableValue']>;
  ContactTypeId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_ContactType_Min_Fields = {
  __typename?: 'C_ContactType_min_fields';
  ContactTypeCode?: Maybe<Scalars['CodeTableValue']>;
  ContactTypeId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_ContactType" */
export type C_ContactType_Mutation_Response = {
  __typename?: 'C_ContactType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ContactType>;
};

/** Ordering options when selecting data from "C_ContactType". */
export type C_ContactType_Order_By = {
  ContactTypeCode?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
  R_ProjectContacts_aggregate?: Maybe<R_ProjectContact_Aggregate_Order_By>;
  R_RoofAreaContacts_aggregate?: Maybe<R_RoofAreaContact_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ContactType */
export type C_ContactType_Pk_Columns_Input = {
  ContactTypeId: Scalars['Int'];
};

/** select columns of table "C_ContactType" */
export enum C_ContactType_Select_Column {
  /** column name */
  ContactTypeCode = 'ContactTypeCode',
  /** column name */
  ContactTypeId = 'ContactTypeId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_ContactType" */
export type C_ContactType_Set_Input = {
  ContactTypeCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_ContactType_Stddev_Fields = {
  __typename?: 'C_ContactType_stddev_fields';
  ContactTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ContactType_Stddev_Pop_Fields = {
  __typename?: 'C_ContactType_stddev_pop_fields';
  ContactTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ContactType_Stddev_Samp_Fields = {
  __typename?: 'C_ContactType_stddev_samp_fields';
  ContactTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ContactType_Sum_Fields = {
  __typename?: 'C_ContactType_sum_fields';
  ContactTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ContactType" */
export enum C_ContactType_Update_Column {
  /** column name */
  ContactTypeCode = 'ContactTypeCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_ContactType_Var_Pop_Fields = {
  __typename?: 'C_ContactType_var_pop_fields';
  ContactTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ContactType_Var_Samp_Fields = {
  __typename?: 'C_ContactType_var_samp_fields';
  ContactTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ContactType_Variance_Fields = {
  __typename?: 'C_ContactType_variance_fields';
  ContactTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_Country" */
export type C_Country = {
  __typename?: 'C_Country';
  /** An array relationship */
  Addresses: Array<Address>;
  /** An aggregate relationship */
  Addresses_aggregate: Address_Aggregate;
  CountryCode: Scalars['CodeTableValue'];
  CountryId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  R_Country_StateProvinces: Array<R_Country_StateProvince>;
  /** An aggregate relationship */
  R_Country_StateProvinces_aggregate: R_Country_StateProvince_Aggregate;
};


/** columns and relationships of "C_Country" */
export type C_CountryAddressesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** columns and relationships of "C_Country" */
export type C_CountryAddresses_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** columns and relationships of "C_Country" */
export type C_CountryR_Country_StateProvincesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Country_StateProvince_Order_By>>;
  where?: Maybe<R_Country_StateProvince_Bool_Exp>;
};


/** columns and relationships of "C_Country" */
export type C_CountryR_Country_StateProvinces_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Country_StateProvince_Order_By>>;
  where?: Maybe<R_Country_StateProvince_Bool_Exp>;
};

/** aggregated selection of "C_Country" */
export type C_Country_Aggregate = {
  __typename?: 'C_Country_aggregate';
  aggregate?: Maybe<C_Country_Aggregate_Fields>;
  nodes: Array<C_Country>;
};

/** aggregate fields of "C_Country" */
export type C_Country_Aggregate_Fields = {
  __typename?: 'C_Country_aggregate_fields';
  avg?: Maybe<C_Country_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_Country_Max_Fields>;
  min?: Maybe<C_Country_Min_Fields>;
  stddev?: Maybe<C_Country_Stddev_Fields>;
  stddev_pop?: Maybe<C_Country_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_Country_Stddev_Samp_Fields>;
  sum?: Maybe<C_Country_Sum_Fields>;
  var_pop?: Maybe<C_Country_Var_Pop_Fields>;
  var_samp?: Maybe<C_Country_Var_Samp_Fields>;
  variance?: Maybe<C_Country_Variance_Fields>;
};


/** aggregate fields of "C_Country" */
export type C_Country_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_Country_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_Country_Avg_Fields = {
  __typename?: 'C_Country_avg_fields';
  CountryId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_Country". All fields are combined with a logical 'AND'. */
export type C_Country_Bool_Exp = {
  Addresses?: Maybe<Address_Bool_Exp>;
  CountryCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  CountryId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  R_Country_StateProvinces?: Maybe<R_Country_StateProvince_Bool_Exp>;
  _and?: Maybe<Array<C_Country_Bool_Exp>>;
  _not?: Maybe<C_Country_Bool_Exp>;
  _or?: Maybe<Array<C_Country_Bool_Exp>>;
};

/** upsert condition type for table "C_Country" */
export type C_Country_If_Matched = {
  match_columns?: Array<C_Country_Insert_Match_Column>;
  update_columns?: Array<C_Country_Update_Column>;
  where?: Maybe<C_Country_Bool_Exp>;
};

/** input type for inserting data into table "C_Country" */
export type C_Country_Insert_Input = {
  CountryCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_Country" */
export enum C_Country_Insert_Match_Column {
  /** column name */
  CountryCode = 'CountryCode',
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_Country_Max_Fields = {
  __typename?: 'C_Country_max_fields';
  CountryCode?: Maybe<Scalars['CodeTableValue']>;
  CountryId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_Country_Min_Fields = {
  __typename?: 'C_Country_min_fields';
  CountryCode?: Maybe<Scalars['CodeTableValue']>;
  CountryId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_Country" */
export type C_Country_Mutation_Response = {
  __typename?: 'C_Country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_Country>;
};

/** Ordering options when selecting data from "C_Country". */
export type C_Country_Order_By = {
  Addresses_aggregate?: Maybe<Address_Aggregate_Order_By>;
  CountryCode?: Maybe<Order_By>;
  CountryId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
  R_Country_StateProvinces_aggregate?: Maybe<R_Country_StateProvince_Aggregate_Order_By>;
};

/** primary key columns input for table: C_Country */
export type C_Country_Pk_Columns_Input = {
  CountryId: Scalars['Int'];
};

/** select columns of table "C_Country" */
export enum C_Country_Select_Column {
  /** column name */
  CountryCode = 'CountryCode',
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_Country" */
export type C_Country_Set_Input = {
  CountryCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_Country_Stddev_Fields = {
  __typename?: 'C_Country_stddev_fields';
  CountryId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_Country_Stddev_Pop_Fields = {
  __typename?: 'C_Country_stddev_pop_fields';
  CountryId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_Country_Stddev_Samp_Fields = {
  __typename?: 'C_Country_stddev_samp_fields';
  CountryId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_Country_Sum_Fields = {
  __typename?: 'C_Country_sum_fields';
  CountryId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_Country" */
export enum C_Country_Update_Column {
  /** column name */
  CountryCode = 'CountryCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_Country_Var_Pop_Fields = {
  __typename?: 'C_Country_var_pop_fields';
  CountryId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_Country_Var_Samp_Fields = {
  __typename?: 'C_Country_var_samp_fields';
  CountryId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_Country_Variance_Fields = {
  __typename?: 'C_Country_variance_fields';
  CountryId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_CoverBoardMaterial" */
export type C_CoverBoardMaterial = {
  __typename?: 'C_CoverBoardMaterial';
  CoverBoardMaterialCode: Scalars['CodeTableValue'];
  CoverBoardMaterialId: Scalars['Int'];
  /** An array relationship */
  CoverBoards: Array<CoverBoard>;
  /** An aggregate relationship */
  CoverBoards_aggregate: CoverBoard_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
};


/** columns and relationships of "C_CoverBoardMaterial" */
export type C_CoverBoardMaterialCoverBoardsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoard_Order_By>>;
  where?: Maybe<CoverBoard_Bool_Exp>;
};


/** columns and relationships of "C_CoverBoardMaterial" */
export type C_CoverBoardMaterialCoverBoards_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoard_Order_By>>;
  where?: Maybe<CoverBoard_Bool_Exp>;
};

/** aggregated selection of "C_CoverBoardMaterial" */
export type C_CoverBoardMaterial_Aggregate = {
  __typename?: 'C_CoverBoardMaterial_aggregate';
  aggregate?: Maybe<C_CoverBoardMaterial_Aggregate_Fields>;
  nodes: Array<C_CoverBoardMaterial>;
};

/** aggregate fields of "C_CoverBoardMaterial" */
export type C_CoverBoardMaterial_Aggregate_Fields = {
  __typename?: 'C_CoverBoardMaterial_aggregate_fields';
  avg?: Maybe<C_CoverBoardMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_CoverBoardMaterial_Max_Fields>;
  min?: Maybe<C_CoverBoardMaterial_Min_Fields>;
  stddev?: Maybe<C_CoverBoardMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_CoverBoardMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_CoverBoardMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_CoverBoardMaterial_Sum_Fields>;
  var_pop?: Maybe<C_CoverBoardMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_CoverBoardMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_CoverBoardMaterial_Variance_Fields>;
};


/** aggregate fields of "C_CoverBoardMaterial" */
export type C_CoverBoardMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_CoverBoardMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_CoverBoardMaterial_Avg_Fields = {
  __typename?: 'C_CoverBoardMaterial_avg_fields';
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_CoverBoardMaterial". All fields are combined with a logical 'AND'. */
export type C_CoverBoardMaterial_Bool_Exp = {
  CoverBoardMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  CoverBoardMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  CoverBoards?: Maybe<CoverBoard_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_CoverBoardMaterial_Bool_Exp>>;
  _not?: Maybe<C_CoverBoardMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_CoverBoardMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_CoverBoardMaterial" */
export type C_CoverBoardMaterial_If_Matched = {
  match_columns?: Array<C_CoverBoardMaterial_Insert_Match_Column>;
  update_columns?: Array<C_CoverBoardMaterial_Update_Column>;
  where?: Maybe<C_CoverBoardMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_CoverBoardMaterial" */
export type C_CoverBoardMaterial_Insert_Input = {
  CoverBoardMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_CoverBoardMaterial" */
export enum C_CoverBoardMaterial_Insert_Match_Column {
  /** column name */
  CoverBoardMaterialCode = 'CoverBoardMaterialCode',
  /** column name */
  CoverBoardMaterialId = 'CoverBoardMaterialId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_CoverBoardMaterial_Max_Fields = {
  __typename?: 'C_CoverBoardMaterial_max_fields';
  CoverBoardMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_CoverBoardMaterial_Min_Fields = {
  __typename?: 'C_CoverBoardMaterial_min_fields';
  CoverBoardMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_CoverBoardMaterial" */
export type C_CoverBoardMaterial_Mutation_Response = {
  __typename?: 'C_CoverBoardMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_CoverBoardMaterial>;
};

/** Ordering options when selecting data from "C_CoverBoardMaterial". */
export type C_CoverBoardMaterial_Order_By = {
  CoverBoardMaterialCode?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  CoverBoards_aggregate?: Maybe<CoverBoard_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** primary key columns input for table: C_CoverBoardMaterial */
export type C_CoverBoardMaterial_Pk_Columns_Input = {
  CoverBoardMaterialId: Scalars['Int'];
};

/** select columns of table "C_CoverBoardMaterial" */
export enum C_CoverBoardMaterial_Select_Column {
  /** column name */
  CoverBoardMaterialCode = 'CoverBoardMaterialCode',
  /** column name */
  CoverBoardMaterialId = 'CoverBoardMaterialId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_CoverBoardMaterial" */
export type C_CoverBoardMaterial_Set_Input = {
  CoverBoardMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_CoverBoardMaterial_Stddev_Fields = {
  __typename?: 'C_CoverBoardMaterial_stddev_fields';
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_CoverBoardMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_CoverBoardMaterial_stddev_pop_fields';
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_CoverBoardMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_CoverBoardMaterial_stddev_samp_fields';
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_CoverBoardMaterial_Sum_Fields = {
  __typename?: 'C_CoverBoardMaterial_sum_fields';
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_CoverBoardMaterial" */
export enum C_CoverBoardMaterial_Update_Column {
  /** column name */
  CoverBoardMaterialCode = 'CoverBoardMaterialCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_CoverBoardMaterial_Var_Pop_Fields = {
  __typename?: 'C_CoverBoardMaterial_var_pop_fields';
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_CoverBoardMaterial_Var_Samp_Fields = {
  __typename?: 'C_CoverBoardMaterial_var_samp_fields';
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_CoverBoardMaterial_Variance_Fields = {
  __typename?: 'C_CoverBoardMaterial_variance_fields';
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_CoverColor" */
export type C_CoverColor = {
  __typename?: 'C_CoverColor';
  /** An array relationship */
  BaseCoats: Array<BaseCoat>;
  /** An aggregate relationship */
  BaseCoats_aggregate: BaseCoat_Aggregate;
  /** An array relationship */
  Coats: Array<Coat>;
  /** An aggregate relationship */
  Coats_aggregate: Coat_Aggregate;
  CoverColorCode: Scalars['CodeTableValue'];
  CoverColorId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  SinglePlyCovers: Array<SinglePlyCover>;
  /** An aggregate relationship */
  SinglePlyCovers_aggregate: SinglePlyCover_Aggregate;
  /** An array relationship */
  TopCoats: Array<TopCoat>;
  /** An aggregate relationship */
  TopCoats_aggregate: TopCoat_Aggregate;
};


/** columns and relationships of "C_CoverColor" */
export type C_CoverColorBaseCoatsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseCoat_Order_By>>;
  where?: Maybe<BaseCoat_Bool_Exp>;
};


/** columns and relationships of "C_CoverColor" */
export type C_CoverColorBaseCoats_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseCoat_Order_By>>;
  where?: Maybe<BaseCoat_Bool_Exp>;
};


/** columns and relationships of "C_CoverColor" */
export type C_CoverColorCoatsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coat_Order_By>>;
  where?: Maybe<Coat_Bool_Exp>;
};


/** columns and relationships of "C_CoverColor" */
export type C_CoverColorCoats_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coat_Order_By>>;
  where?: Maybe<Coat_Bool_Exp>;
};


/** columns and relationships of "C_CoverColor" */
export type C_CoverColorSinglePlyCoversArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyCover_Order_By>>;
  where?: Maybe<SinglePlyCover_Bool_Exp>;
};


/** columns and relationships of "C_CoverColor" */
export type C_CoverColorSinglePlyCovers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyCover_Order_By>>;
  where?: Maybe<SinglePlyCover_Bool_Exp>;
};


/** columns and relationships of "C_CoverColor" */
export type C_CoverColorTopCoatsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TopCoat_Order_By>>;
  where?: Maybe<TopCoat_Bool_Exp>;
};


/** columns and relationships of "C_CoverColor" */
export type C_CoverColorTopCoats_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TopCoat_Order_By>>;
  where?: Maybe<TopCoat_Bool_Exp>;
};

/** aggregated selection of "C_CoverColor" */
export type C_CoverColor_Aggregate = {
  __typename?: 'C_CoverColor_aggregate';
  aggregate?: Maybe<C_CoverColor_Aggregate_Fields>;
  nodes: Array<C_CoverColor>;
};

/** aggregate fields of "C_CoverColor" */
export type C_CoverColor_Aggregate_Fields = {
  __typename?: 'C_CoverColor_aggregate_fields';
  avg?: Maybe<C_CoverColor_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_CoverColor_Max_Fields>;
  min?: Maybe<C_CoverColor_Min_Fields>;
  stddev?: Maybe<C_CoverColor_Stddev_Fields>;
  stddev_pop?: Maybe<C_CoverColor_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_CoverColor_Stddev_Samp_Fields>;
  sum?: Maybe<C_CoverColor_Sum_Fields>;
  var_pop?: Maybe<C_CoverColor_Var_Pop_Fields>;
  var_samp?: Maybe<C_CoverColor_Var_Samp_Fields>;
  variance?: Maybe<C_CoverColor_Variance_Fields>;
};


/** aggregate fields of "C_CoverColor" */
export type C_CoverColor_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_CoverColor_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_CoverColor_Avg_Fields = {
  __typename?: 'C_CoverColor_avg_fields';
  CoverColorId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_CoverColor". All fields are combined with a logical 'AND'. */
export type C_CoverColor_Bool_Exp = {
  BaseCoats?: Maybe<BaseCoat_Bool_Exp>;
  Coats?: Maybe<Coat_Bool_Exp>;
  CoverColorCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  CoverColorId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  SinglePlyCovers?: Maybe<SinglePlyCover_Bool_Exp>;
  TopCoats?: Maybe<TopCoat_Bool_Exp>;
  _and?: Maybe<Array<C_CoverColor_Bool_Exp>>;
  _not?: Maybe<C_CoverColor_Bool_Exp>;
  _or?: Maybe<Array<C_CoverColor_Bool_Exp>>;
};

/** upsert condition type for table "C_CoverColor" */
export type C_CoverColor_If_Matched = {
  match_columns?: Array<C_CoverColor_Insert_Match_Column>;
  update_columns?: Array<C_CoverColor_Update_Column>;
  where?: Maybe<C_CoverColor_Bool_Exp>;
};

/** input type for inserting data into table "C_CoverColor" */
export type C_CoverColor_Insert_Input = {
  CoverColorCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_CoverColor" */
export enum C_CoverColor_Insert_Match_Column {
  /** column name */
  CoverColorCode = 'CoverColorCode',
  /** column name */
  CoverColorId = 'CoverColorId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_CoverColor_Max_Fields = {
  __typename?: 'C_CoverColor_max_fields';
  CoverColorCode?: Maybe<Scalars['CodeTableValue']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_CoverColor_Min_Fields = {
  __typename?: 'C_CoverColor_min_fields';
  CoverColorCode?: Maybe<Scalars['CodeTableValue']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_CoverColor" */
export type C_CoverColor_Mutation_Response = {
  __typename?: 'C_CoverColor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_CoverColor>;
};

/** Ordering options when selecting data from "C_CoverColor". */
export type C_CoverColor_Order_By = {
  BaseCoats_aggregate?: Maybe<BaseCoat_Aggregate_Order_By>;
  Coats_aggregate?: Maybe<Coat_Aggregate_Order_By>;
  CoverColorCode?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
  SinglePlyCovers_aggregate?: Maybe<SinglePlyCover_Aggregate_Order_By>;
  TopCoats_aggregate?: Maybe<TopCoat_Aggregate_Order_By>;
};

/** primary key columns input for table: C_CoverColor */
export type C_CoverColor_Pk_Columns_Input = {
  CoverColorId: Scalars['Int'];
};

/** select columns of table "C_CoverColor" */
export enum C_CoverColor_Select_Column {
  /** column name */
  CoverColorCode = 'CoverColorCode',
  /** column name */
  CoverColorId = 'CoverColorId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_CoverColor" */
export type C_CoverColor_Set_Input = {
  CoverColorCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_CoverColor_Stddev_Fields = {
  __typename?: 'C_CoverColor_stddev_fields';
  CoverColorId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_CoverColor_Stddev_Pop_Fields = {
  __typename?: 'C_CoverColor_stddev_pop_fields';
  CoverColorId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_CoverColor_Stddev_Samp_Fields = {
  __typename?: 'C_CoverColor_stddev_samp_fields';
  CoverColorId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_CoverColor_Sum_Fields = {
  __typename?: 'C_CoverColor_sum_fields';
  CoverColorId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_CoverColor" */
export enum C_CoverColor_Update_Column {
  /** column name */
  CoverColorCode = 'CoverColorCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_CoverColor_Var_Pop_Fields = {
  __typename?: 'C_CoverColor_var_pop_fields';
  CoverColorId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_CoverColor_Var_Samp_Fields = {
  __typename?: 'C_CoverColor_var_samp_fields';
  CoverColorId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_CoverColor_Variance_Fields = {
  __typename?: 'C_CoverColor_variance_fields';
  CoverColorId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_CoverSecurementMethod" */
export type C_CoverSecurementMethod = {
  __typename?: 'C_CoverSecurementMethod';
  CoverSecurementMethodCode: Scalars['CodeTableValue'];
  CoverSecurementMethodId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  RoofAssemblies: Array<RoofAssembly>;
  /** An aggregate relationship */
  RoofAssemblies_aggregate: RoofAssembly_Aggregate;
};


/** columns and relationships of "C_CoverSecurementMethod" */
export type C_CoverSecurementMethodRoofAssembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "C_CoverSecurementMethod" */
export type C_CoverSecurementMethodRoofAssemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};

/** aggregated selection of "C_CoverSecurementMethod" */
export type C_CoverSecurementMethod_Aggregate = {
  __typename?: 'C_CoverSecurementMethod_aggregate';
  aggregate?: Maybe<C_CoverSecurementMethod_Aggregate_Fields>;
  nodes: Array<C_CoverSecurementMethod>;
};

/** aggregate fields of "C_CoverSecurementMethod" */
export type C_CoverSecurementMethod_Aggregate_Fields = {
  __typename?: 'C_CoverSecurementMethod_aggregate_fields';
  avg?: Maybe<C_CoverSecurementMethod_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_CoverSecurementMethod_Max_Fields>;
  min?: Maybe<C_CoverSecurementMethod_Min_Fields>;
  stddev?: Maybe<C_CoverSecurementMethod_Stddev_Fields>;
  stddev_pop?: Maybe<C_CoverSecurementMethod_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_CoverSecurementMethod_Stddev_Samp_Fields>;
  sum?: Maybe<C_CoverSecurementMethod_Sum_Fields>;
  var_pop?: Maybe<C_CoverSecurementMethod_Var_Pop_Fields>;
  var_samp?: Maybe<C_CoverSecurementMethod_Var_Samp_Fields>;
  variance?: Maybe<C_CoverSecurementMethod_Variance_Fields>;
};


/** aggregate fields of "C_CoverSecurementMethod" */
export type C_CoverSecurementMethod_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_CoverSecurementMethod_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_CoverSecurementMethod_Avg_Fields = {
  __typename?: 'C_CoverSecurementMethod_avg_fields';
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_CoverSecurementMethod". All fields are combined with a logical 'AND'. */
export type C_CoverSecurementMethod_Bool_Exp = {
  CoverSecurementMethodCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  CoverSecurementMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  RoofAssemblies?: Maybe<RoofAssembly_Bool_Exp>;
  _and?: Maybe<Array<C_CoverSecurementMethod_Bool_Exp>>;
  _not?: Maybe<C_CoverSecurementMethod_Bool_Exp>;
  _or?: Maybe<Array<C_CoverSecurementMethod_Bool_Exp>>;
};

/** upsert condition type for table "C_CoverSecurementMethod" */
export type C_CoverSecurementMethod_If_Matched = {
  match_columns?: Array<C_CoverSecurementMethod_Insert_Match_Column>;
  update_columns?: Array<C_CoverSecurementMethod_Update_Column>;
  where?: Maybe<C_CoverSecurementMethod_Bool_Exp>;
};

/** input type for inserting data into table "C_CoverSecurementMethod" */
export type C_CoverSecurementMethod_Insert_Input = {
  CoverSecurementMethodCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_CoverSecurementMethod" */
export enum C_CoverSecurementMethod_Insert_Match_Column {
  /** column name */
  CoverSecurementMethodCode = 'CoverSecurementMethodCode',
  /** column name */
  CoverSecurementMethodId = 'CoverSecurementMethodId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_CoverSecurementMethod_Max_Fields = {
  __typename?: 'C_CoverSecurementMethod_max_fields';
  CoverSecurementMethodCode?: Maybe<Scalars['CodeTableValue']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_CoverSecurementMethod_Min_Fields = {
  __typename?: 'C_CoverSecurementMethod_min_fields';
  CoverSecurementMethodCode?: Maybe<Scalars['CodeTableValue']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_CoverSecurementMethod" */
export type C_CoverSecurementMethod_Mutation_Response = {
  __typename?: 'C_CoverSecurementMethod_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_CoverSecurementMethod>;
};

/** Ordering options when selecting data from "C_CoverSecurementMethod". */
export type C_CoverSecurementMethod_Order_By = {
  CoverSecurementMethodCode?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
  RoofAssemblies_aggregate?: Maybe<RoofAssembly_Aggregate_Order_By>;
};

/** primary key columns input for table: C_CoverSecurementMethod */
export type C_CoverSecurementMethod_Pk_Columns_Input = {
  CoverSecurementMethodId: Scalars['Int'];
};

/** select columns of table "C_CoverSecurementMethod" */
export enum C_CoverSecurementMethod_Select_Column {
  /** column name */
  CoverSecurementMethodCode = 'CoverSecurementMethodCode',
  /** column name */
  CoverSecurementMethodId = 'CoverSecurementMethodId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_CoverSecurementMethod" */
export type C_CoverSecurementMethod_Set_Input = {
  CoverSecurementMethodCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_CoverSecurementMethod_Stddev_Fields = {
  __typename?: 'C_CoverSecurementMethod_stddev_fields';
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_CoverSecurementMethod_Stddev_Pop_Fields = {
  __typename?: 'C_CoverSecurementMethod_stddev_pop_fields';
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_CoverSecurementMethod_Stddev_Samp_Fields = {
  __typename?: 'C_CoverSecurementMethod_stddev_samp_fields';
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_CoverSecurementMethod_Sum_Fields = {
  __typename?: 'C_CoverSecurementMethod_sum_fields';
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_CoverSecurementMethod" */
export enum C_CoverSecurementMethod_Update_Column {
  /** column name */
  CoverSecurementMethodCode = 'CoverSecurementMethodCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_CoverSecurementMethod_Var_Pop_Fields = {
  __typename?: 'C_CoverSecurementMethod_var_pop_fields';
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_CoverSecurementMethod_Var_Samp_Fields = {
  __typename?: 'C_CoverSecurementMethod_var_samp_fields';
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_CoverSecurementMethod_Variance_Fields = {
  __typename?: 'C_CoverSecurementMethod_variance_fields';
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_DeckType" */
export type C_DeckType = {
  __typename?: 'C_DeckType';
  DeckTypeCode: Scalars['CodeTableValue'];
  DeckTypeId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  RoofAssemblies: Array<RoofAssembly>;
  /** An aggregate relationship */
  RoofAssemblies_aggregate: RoofAssembly_Aggregate;
};


/** columns and relationships of "C_DeckType" */
export type C_DeckTypeRoofAssembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "C_DeckType" */
export type C_DeckTypeRoofAssemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};

/** aggregated selection of "C_DeckType" */
export type C_DeckType_Aggregate = {
  __typename?: 'C_DeckType_aggregate';
  aggregate?: Maybe<C_DeckType_Aggregate_Fields>;
  nodes: Array<C_DeckType>;
};

/** aggregate fields of "C_DeckType" */
export type C_DeckType_Aggregate_Fields = {
  __typename?: 'C_DeckType_aggregate_fields';
  avg?: Maybe<C_DeckType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_DeckType_Max_Fields>;
  min?: Maybe<C_DeckType_Min_Fields>;
  stddev?: Maybe<C_DeckType_Stddev_Fields>;
  stddev_pop?: Maybe<C_DeckType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_DeckType_Stddev_Samp_Fields>;
  sum?: Maybe<C_DeckType_Sum_Fields>;
  var_pop?: Maybe<C_DeckType_Var_Pop_Fields>;
  var_samp?: Maybe<C_DeckType_Var_Samp_Fields>;
  variance?: Maybe<C_DeckType_Variance_Fields>;
};


/** aggregate fields of "C_DeckType" */
export type C_DeckType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_DeckType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_DeckType_Avg_Fields = {
  __typename?: 'C_DeckType_avg_fields';
  DeckTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_DeckType". All fields are combined with a logical 'AND'. */
export type C_DeckType_Bool_Exp = {
  DeckTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  DeckTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  RoofAssemblies?: Maybe<RoofAssembly_Bool_Exp>;
  _and?: Maybe<Array<C_DeckType_Bool_Exp>>;
  _not?: Maybe<C_DeckType_Bool_Exp>;
  _or?: Maybe<Array<C_DeckType_Bool_Exp>>;
};

/** upsert condition type for table "C_DeckType" */
export type C_DeckType_If_Matched = {
  match_columns?: Array<C_DeckType_Insert_Match_Column>;
  update_columns?: Array<C_DeckType_Update_Column>;
  where?: Maybe<C_DeckType_Bool_Exp>;
};

/** input type for inserting data into table "C_DeckType" */
export type C_DeckType_Insert_Input = {
  DeckTypeCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_DeckType" */
export enum C_DeckType_Insert_Match_Column {
  /** column name */
  DeckTypeCode = 'DeckTypeCode',
  /** column name */
  DeckTypeId = 'DeckTypeId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_DeckType_Max_Fields = {
  __typename?: 'C_DeckType_max_fields';
  DeckTypeCode?: Maybe<Scalars['CodeTableValue']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_DeckType_Min_Fields = {
  __typename?: 'C_DeckType_min_fields';
  DeckTypeCode?: Maybe<Scalars['CodeTableValue']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_DeckType" */
export type C_DeckType_Mutation_Response = {
  __typename?: 'C_DeckType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_DeckType>;
};

/** Ordering options when selecting data from "C_DeckType". */
export type C_DeckType_Order_By = {
  DeckTypeCode?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
  RoofAssemblies_aggregate?: Maybe<RoofAssembly_Aggregate_Order_By>;
};

/** primary key columns input for table: C_DeckType */
export type C_DeckType_Pk_Columns_Input = {
  DeckTypeId: Scalars['Int'];
};

/** select columns of table "C_DeckType" */
export enum C_DeckType_Select_Column {
  /** column name */
  DeckTypeCode = 'DeckTypeCode',
  /** column name */
  DeckTypeId = 'DeckTypeId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_DeckType" */
export type C_DeckType_Set_Input = {
  DeckTypeCode?: Maybe<Scalars['CodeTableValue']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_DeckType_Stddev_Fields = {
  __typename?: 'C_DeckType_stddev_fields';
  DeckTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_DeckType_Stddev_Pop_Fields = {
  __typename?: 'C_DeckType_stddev_pop_fields';
  DeckTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_DeckType_Stddev_Samp_Fields = {
  __typename?: 'C_DeckType_stddev_samp_fields';
  DeckTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_DeckType_Sum_Fields = {
  __typename?: 'C_DeckType_sum_fields';
  DeckTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_DeckType" */
export enum C_DeckType_Update_Column {
  /** column name */
  DeckTypeCode = 'DeckTypeCode',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_DeckType_Var_Pop_Fields = {
  __typename?: 'C_DeckType_var_pop_fields';
  DeckTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_DeckType_Var_Samp_Fields = {
  __typename?: 'C_DeckType_var_samp_fields';
  DeckTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_DeckType_Variance_Fields = {
  __typename?: 'C_DeckType_variance_fields';
  DeckTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ExistingRoofCover" */
export type C_ExistingRoofCover = {
  __typename?: 'C_ExistingRoofCover';
  Dsc: Scalars['CodeTableDescription'];
  ExistingRoofCoverCode: Scalars['CodeTableValue'];
  ExistingRoofCoverId: Scalars['Int'];
  /** An array relationship */
  ExistingRoofLayers: Array<ExistingRoofLayer>;
  /** An aggregate relationship */
  ExistingRoofLayers_aggregate: ExistingRoofLayer_Aggregate;
};


/** columns and relationships of "C_ExistingRoofCover" */
export type C_ExistingRoofCoverExistingRoofLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExistingRoofLayer_Order_By>>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};


/** columns and relationships of "C_ExistingRoofCover" */
export type C_ExistingRoofCoverExistingRoofLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExistingRoofLayer_Order_By>>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};

/** aggregated selection of "C_ExistingRoofCover" */
export type C_ExistingRoofCover_Aggregate = {
  __typename?: 'C_ExistingRoofCover_aggregate';
  aggregate?: Maybe<C_ExistingRoofCover_Aggregate_Fields>;
  nodes: Array<C_ExistingRoofCover>;
};

/** aggregate fields of "C_ExistingRoofCover" */
export type C_ExistingRoofCover_Aggregate_Fields = {
  __typename?: 'C_ExistingRoofCover_aggregate_fields';
  avg?: Maybe<C_ExistingRoofCover_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ExistingRoofCover_Max_Fields>;
  min?: Maybe<C_ExistingRoofCover_Min_Fields>;
  stddev?: Maybe<C_ExistingRoofCover_Stddev_Fields>;
  stddev_pop?: Maybe<C_ExistingRoofCover_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ExistingRoofCover_Stddev_Samp_Fields>;
  sum?: Maybe<C_ExistingRoofCover_Sum_Fields>;
  var_pop?: Maybe<C_ExistingRoofCover_Var_Pop_Fields>;
  var_samp?: Maybe<C_ExistingRoofCover_Var_Samp_Fields>;
  variance?: Maybe<C_ExistingRoofCover_Variance_Fields>;
};


/** aggregate fields of "C_ExistingRoofCover" */
export type C_ExistingRoofCover_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ExistingRoofCover_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ExistingRoofCover_Avg_Fields = {
  __typename?: 'C_ExistingRoofCover_avg_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ExistingRoofCover". All fields are combined with a logical 'AND'. */
export type C_ExistingRoofCover_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  ExistingRoofCoverCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ExistingRoofCoverId?: Maybe<Int_Mssql_Comparison_Exp>;
  ExistingRoofLayers?: Maybe<ExistingRoofLayer_Bool_Exp>;
  _and?: Maybe<Array<C_ExistingRoofCover_Bool_Exp>>;
  _not?: Maybe<C_ExistingRoofCover_Bool_Exp>;
  _or?: Maybe<Array<C_ExistingRoofCover_Bool_Exp>>;
};

/** upsert condition type for table "C_ExistingRoofCover" */
export type C_ExistingRoofCover_If_Matched = {
  match_columns?: Array<C_ExistingRoofCover_Insert_Match_Column>;
  update_columns?: Array<C_ExistingRoofCover_Update_Column>;
  where?: Maybe<C_ExistingRoofCover_Bool_Exp>;
};

/** input type for inserting data into table "C_ExistingRoofCover" */
export type C_ExistingRoofCover_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofCoverCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ExistingRoofCover" */
export enum C_ExistingRoofCover_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofCoverCode = 'ExistingRoofCoverCode',
  /** column name */
  ExistingRoofCoverId = 'ExistingRoofCoverId'
}

/** aggregate max on columns */
export type C_ExistingRoofCover_Max_Fields = {
  __typename?: 'C_ExistingRoofCover_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofCoverCode?: Maybe<Scalars['CodeTableValue']>;
  ExistingRoofCoverId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ExistingRoofCover_Min_Fields = {
  __typename?: 'C_ExistingRoofCover_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofCoverCode?: Maybe<Scalars['CodeTableValue']>;
  ExistingRoofCoverId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ExistingRoofCover" */
export type C_ExistingRoofCover_Mutation_Response = {
  __typename?: 'C_ExistingRoofCover_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ExistingRoofCover>;
};

/** Ordering options when selecting data from "C_ExistingRoofCover". */
export type C_ExistingRoofCover_Order_By = {
  Dsc?: Maybe<Order_By>;
  ExistingRoofCoverCode?: Maybe<Order_By>;
  ExistingRoofCoverId?: Maybe<Order_By>;
  ExistingRoofLayers_aggregate?: Maybe<ExistingRoofLayer_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ExistingRoofCover */
export type C_ExistingRoofCover_Pk_Columns_Input = {
  ExistingRoofCoverId: Scalars['Int'];
};

/** select columns of table "C_ExistingRoofCover" */
export enum C_ExistingRoofCover_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofCoverCode = 'ExistingRoofCoverCode',
  /** column name */
  ExistingRoofCoverId = 'ExistingRoofCoverId'
}

/** input type for updating data in table "C_ExistingRoofCover" */
export type C_ExistingRoofCover_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofCoverCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ExistingRoofCover_Stddev_Fields = {
  __typename?: 'C_ExistingRoofCover_stddev_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ExistingRoofCover_Stddev_Pop_Fields = {
  __typename?: 'C_ExistingRoofCover_stddev_pop_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ExistingRoofCover_Stddev_Samp_Fields = {
  __typename?: 'C_ExistingRoofCover_stddev_samp_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ExistingRoofCover_Sum_Fields = {
  __typename?: 'C_ExistingRoofCover_sum_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ExistingRoofCover" */
export enum C_ExistingRoofCover_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofCoverCode = 'ExistingRoofCoverCode'
}

/** aggregate var_pop on columns */
export type C_ExistingRoofCover_Var_Pop_Fields = {
  __typename?: 'C_ExistingRoofCover_var_pop_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ExistingRoofCover_Var_Samp_Fields = {
  __typename?: 'C_ExistingRoofCover_var_samp_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ExistingRoofCover_Variance_Fields = {
  __typename?: 'C_ExistingRoofCover_variance_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ExistingRoofDeck" */
export type C_ExistingRoofDeck = {
  __typename?: 'C_ExistingRoofDeck';
  Dsc: Scalars['CodeTableDescription'];
  ExistingRoofDeckCode: Scalars['CodeTableValue'];
  ExistingRoofDeckId: Scalars['Int'];
  /** An array relationship */
  ExistingRoofLayers: Array<ExistingRoofLayer>;
  /** An aggregate relationship */
  ExistingRoofLayers_aggregate: ExistingRoofLayer_Aggregate;
};


/** columns and relationships of "C_ExistingRoofDeck" */
export type C_ExistingRoofDeckExistingRoofLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExistingRoofLayer_Order_By>>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};


/** columns and relationships of "C_ExistingRoofDeck" */
export type C_ExistingRoofDeckExistingRoofLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExistingRoofLayer_Order_By>>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};

/** aggregated selection of "C_ExistingRoofDeck" */
export type C_ExistingRoofDeck_Aggregate = {
  __typename?: 'C_ExistingRoofDeck_aggregate';
  aggregate?: Maybe<C_ExistingRoofDeck_Aggregate_Fields>;
  nodes: Array<C_ExistingRoofDeck>;
};

/** aggregate fields of "C_ExistingRoofDeck" */
export type C_ExistingRoofDeck_Aggregate_Fields = {
  __typename?: 'C_ExistingRoofDeck_aggregate_fields';
  avg?: Maybe<C_ExistingRoofDeck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ExistingRoofDeck_Max_Fields>;
  min?: Maybe<C_ExistingRoofDeck_Min_Fields>;
  stddev?: Maybe<C_ExistingRoofDeck_Stddev_Fields>;
  stddev_pop?: Maybe<C_ExistingRoofDeck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ExistingRoofDeck_Stddev_Samp_Fields>;
  sum?: Maybe<C_ExistingRoofDeck_Sum_Fields>;
  var_pop?: Maybe<C_ExistingRoofDeck_Var_Pop_Fields>;
  var_samp?: Maybe<C_ExistingRoofDeck_Var_Samp_Fields>;
  variance?: Maybe<C_ExistingRoofDeck_Variance_Fields>;
};


/** aggregate fields of "C_ExistingRoofDeck" */
export type C_ExistingRoofDeck_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ExistingRoofDeck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ExistingRoofDeck_Avg_Fields = {
  __typename?: 'C_ExistingRoofDeck_avg_fields';
  ExistingRoofDeckId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ExistingRoofDeck". All fields are combined with a logical 'AND'. */
export type C_ExistingRoofDeck_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  ExistingRoofDeckCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ExistingRoofDeckId?: Maybe<Int_Mssql_Comparison_Exp>;
  ExistingRoofLayers?: Maybe<ExistingRoofLayer_Bool_Exp>;
  _and?: Maybe<Array<C_ExistingRoofDeck_Bool_Exp>>;
  _not?: Maybe<C_ExistingRoofDeck_Bool_Exp>;
  _or?: Maybe<Array<C_ExistingRoofDeck_Bool_Exp>>;
};

/** upsert condition type for table "C_ExistingRoofDeck" */
export type C_ExistingRoofDeck_If_Matched = {
  match_columns?: Array<C_ExistingRoofDeck_Insert_Match_Column>;
  update_columns?: Array<C_ExistingRoofDeck_Update_Column>;
  where?: Maybe<C_ExistingRoofDeck_Bool_Exp>;
};

/** input type for inserting data into table "C_ExistingRoofDeck" */
export type C_ExistingRoofDeck_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofDeckCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ExistingRoofDeck" */
export enum C_ExistingRoofDeck_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofDeckCode = 'ExistingRoofDeckCode',
  /** column name */
  ExistingRoofDeckId = 'ExistingRoofDeckId'
}

/** aggregate max on columns */
export type C_ExistingRoofDeck_Max_Fields = {
  __typename?: 'C_ExistingRoofDeck_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofDeckCode?: Maybe<Scalars['CodeTableValue']>;
  ExistingRoofDeckId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ExistingRoofDeck_Min_Fields = {
  __typename?: 'C_ExistingRoofDeck_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofDeckCode?: Maybe<Scalars['CodeTableValue']>;
  ExistingRoofDeckId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ExistingRoofDeck" */
export type C_ExistingRoofDeck_Mutation_Response = {
  __typename?: 'C_ExistingRoofDeck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ExistingRoofDeck>;
};

/** Ordering options when selecting data from "C_ExistingRoofDeck". */
export type C_ExistingRoofDeck_Order_By = {
  Dsc?: Maybe<Order_By>;
  ExistingRoofDeckCode?: Maybe<Order_By>;
  ExistingRoofDeckId?: Maybe<Order_By>;
  ExistingRoofLayers_aggregate?: Maybe<ExistingRoofLayer_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ExistingRoofDeck */
export type C_ExistingRoofDeck_Pk_Columns_Input = {
  ExistingRoofDeckId: Scalars['Int'];
};

/** select columns of table "C_ExistingRoofDeck" */
export enum C_ExistingRoofDeck_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofDeckCode = 'ExistingRoofDeckCode',
  /** column name */
  ExistingRoofDeckId = 'ExistingRoofDeckId'
}

/** input type for updating data in table "C_ExistingRoofDeck" */
export type C_ExistingRoofDeck_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofDeckCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ExistingRoofDeck_Stddev_Fields = {
  __typename?: 'C_ExistingRoofDeck_stddev_fields';
  ExistingRoofDeckId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ExistingRoofDeck_Stddev_Pop_Fields = {
  __typename?: 'C_ExistingRoofDeck_stddev_pop_fields';
  ExistingRoofDeckId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ExistingRoofDeck_Stddev_Samp_Fields = {
  __typename?: 'C_ExistingRoofDeck_stddev_samp_fields';
  ExistingRoofDeckId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ExistingRoofDeck_Sum_Fields = {
  __typename?: 'C_ExistingRoofDeck_sum_fields';
  ExistingRoofDeckId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ExistingRoofDeck" */
export enum C_ExistingRoofDeck_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofDeckCode = 'ExistingRoofDeckCode'
}

/** aggregate var_pop on columns */
export type C_ExistingRoofDeck_Var_Pop_Fields = {
  __typename?: 'C_ExistingRoofDeck_var_pop_fields';
  ExistingRoofDeckId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ExistingRoofDeck_Var_Samp_Fields = {
  __typename?: 'C_ExistingRoofDeck_var_samp_fields';
  ExistingRoofDeckId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ExistingRoofDeck_Variance_Fields = {
  __typename?: 'C_ExistingRoofDeck_variance_fields';
  ExistingRoofDeckId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ExistingRoofInsulationMaterial" */
export type C_ExistingRoofInsulationMaterial = {
  __typename?: 'C_ExistingRoofInsulationMaterial';
  Dsc: Scalars['CodeTableDescription'];
  ExistingRoofInsulationMaterialCode: Scalars['CodeTableValue'];
  ExistingRoofInsulationMaterialId: Scalars['Int'];
  /** An array relationship */
  ExistingRoofLayers: Array<ExistingRoofLayer>;
  /** An aggregate relationship */
  ExistingRoofLayers_aggregate: ExistingRoofLayer_Aggregate;
};


/** columns and relationships of "C_ExistingRoofInsulationMaterial" */
export type C_ExistingRoofInsulationMaterialExistingRoofLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExistingRoofLayer_Order_By>>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};


/** columns and relationships of "C_ExistingRoofInsulationMaterial" */
export type C_ExistingRoofInsulationMaterialExistingRoofLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExistingRoofLayer_Order_By>>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};

/** aggregated selection of "C_ExistingRoofInsulationMaterial" */
export type C_ExistingRoofInsulationMaterial_Aggregate = {
  __typename?: 'C_ExistingRoofInsulationMaterial_aggregate';
  aggregate?: Maybe<C_ExistingRoofInsulationMaterial_Aggregate_Fields>;
  nodes: Array<C_ExistingRoofInsulationMaterial>;
};

/** aggregate fields of "C_ExistingRoofInsulationMaterial" */
export type C_ExistingRoofInsulationMaterial_Aggregate_Fields = {
  __typename?: 'C_ExistingRoofInsulationMaterial_aggregate_fields';
  avg?: Maybe<C_ExistingRoofInsulationMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ExistingRoofInsulationMaterial_Max_Fields>;
  min?: Maybe<C_ExistingRoofInsulationMaterial_Min_Fields>;
  stddev?: Maybe<C_ExistingRoofInsulationMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_ExistingRoofInsulationMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ExistingRoofInsulationMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_ExistingRoofInsulationMaterial_Sum_Fields>;
  var_pop?: Maybe<C_ExistingRoofInsulationMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_ExistingRoofInsulationMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_ExistingRoofInsulationMaterial_Variance_Fields>;
};


/** aggregate fields of "C_ExistingRoofInsulationMaterial" */
export type C_ExistingRoofInsulationMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ExistingRoofInsulationMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ExistingRoofInsulationMaterial_Avg_Fields = {
  __typename?: 'C_ExistingRoofInsulationMaterial_avg_fields';
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ExistingRoofInsulationMaterial". All fields are combined with a logical 'AND'. */
export type C_ExistingRoofInsulationMaterial_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  ExistingRoofInsulationMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ExistingRoofInsulationMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ExistingRoofLayers?: Maybe<ExistingRoofLayer_Bool_Exp>;
  _and?: Maybe<Array<C_ExistingRoofInsulationMaterial_Bool_Exp>>;
  _not?: Maybe<C_ExistingRoofInsulationMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_ExistingRoofInsulationMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_ExistingRoofInsulationMaterial" */
export type C_ExistingRoofInsulationMaterial_If_Matched = {
  match_columns?: Array<C_ExistingRoofInsulationMaterial_Insert_Match_Column>;
  update_columns?: Array<C_ExistingRoofInsulationMaterial_Update_Column>;
  where?: Maybe<C_ExistingRoofInsulationMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_ExistingRoofInsulationMaterial" */
export type C_ExistingRoofInsulationMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofInsulationMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ExistingRoofInsulationMaterial" */
export enum C_ExistingRoofInsulationMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofInsulationMaterialCode = 'ExistingRoofInsulationMaterialCode',
  /** column name */
  ExistingRoofInsulationMaterialId = 'ExistingRoofInsulationMaterialId'
}

/** aggregate max on columns */
export type C_ExistingRoofInsulationMaterial_Max_Fields = {
  __typename?: 'C_ExistingRoofInsulationMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofInsulationMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ExistingRoofInsulationMaterial_Min_Fields = {
  __typename?: 'C_ExistingRoofInsulationMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofInsulationMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ExistingRoofInsulationMaterial" */
export type C_ExistingRoofInsulationMaterial_Mutation_Response = {
  __typename?: 'C_ExistingRoofInsulationMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ExistingRoofInsulationMaterial>;
};

/** Ordering options when selecting data from "C_ExistingRoofInsulationMaterial". */
export type C_ExistingRoofInsulationMaterial_Order_By = {
  Dsc?: Maybe<Order_By>;
  ExistingRoofInsulationMaterialCode?: Maybe<Order_By>;
  ExistingRoofInsulationMaterialId?: Maybe<Order_By>;
  ExistingRoofLayers_aggregate?: Maybe<ExistingRoofLayer_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ExistingRoofInsulationMaterial */
export type C_ExistingRoofInsulationMaterial_Pk_Columns_Input = {
  ExistingRoofInsulationMaterialId: Scalars['Int'];
};

/** select columns of table "C_ExistingRoofInsulationMaterial" */
export enum C_ExistingRoofInsulationMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofInsulationMaterialCode = 'ExistingRoofInsulationMaterialCode',
  /** column name */
  ExistingRoofInsulationMaterialId = 'ExistingRoofInsulationMaterialId'
}

/** input type for updating data in table "C_ExistingRoofInsulationMaterial" */
export type C_ExistingRoofInsulationMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofInsulationMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ExistingRoofInsulationMaterial_Stddev_Fields = {
  __typename?: 'C_ExistingRoofInsulationMaterial_stddev_fields';
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ExistingRoofInsulationMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_ExistingRoofInsulationMaterial_stddev_pop_fields';
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ExistingRoofInsulationMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_ExistingRoofInsulationMaterial_stddev_samp_fields';
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ExistingRoofInsulationMaterial_Sum_Fields = {
  __typename?: 'C_ExistingRoofInsulationMaterial_sum_fields';
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ExistingRoofInsulationMaterial" */
export enum C_ExistingRoofInsulationMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofInsulationMaterialCode = 'ExistingRoofInsulationMaterialCode'
}

/** aggregate var_pop on columns */
export type C_ExistingRoofInsulationMaterial_Var_Pop_Fields = {
  __typename?: 'C_ExistingRoofInsulationMaterial_var_pop_fields';
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ExistingRoofInsulationMaterial_Var_Samp_Fields = {
  __typename?: 'C_ExistingRoofInsulationMaterial_var_samp_fields';
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ExistingRoofInsulationMaterial_Variance_Fields = {
  __typename?: 'C_ExistingRoofInsulationMaterial_variance_fields';
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ExistingRoofSinglePlyMaterial" */
export type C_ExistingRoofSinglePlyMaterial = {
  __typename?: 'C_ExistingRoofSinglePlyMaterial';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  ExistingRoofLayers: Array<ExistingRoofLayer>;
  /** An aggregate relationship */
  ExistingRoofLayers_aggregate: ExistingRoofLayer_Aggregate;
  ExistingRoofSinglePlyMaterialCode: Scalars['CodeTableValue'];
  ExistingRoofSinglePlyMaterialId: Scalars['Int'];
};


/** columns and relationships of "C_ExistingRoofSinglePlyMaterial" */
export type C_ExistingRoofSinglePlyMaterialExistingRoofLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExistingRoofLayer_Order_By>>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};


/** columns and relationships of "C_ExistingRoofSinglePlyMaterial" */
export type C_ExistingRoofSinglePlyMaterialExistingRoofLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExistingRoofLayer_Order_By>>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};

/** aggregated selection of "C_ExistingRoofSinglePlyMaterial" */
export type C_ExistingRoofSinglePlyMaterial_Aggregate = {
  __typename?: 'C_ExistingRoofSinglePlyMaterial_aggregate';
  aggregate?: Maybe<C_ExistingRoofSinglePlyMaterial_Aggregate_Fields>;
  nodes: Array<C_ExistingRoofSinglePlyMaterial>;
};

/** aggregate fields of "C_ExistingRoofSinglePlyMaterial" */
export type C_ExistingRoofSinglePlyMaterial_Aggregate_Fields = {
  __typename?: 'C_ExistingRoofSinglePlyMaterial_aggregate_fields';
  avg?: Maybe<C_ExistingRoofSinglePlyMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ExistingRoofSinglePlyMaterial_Max_Fields>;
  min?: Maybe<C_ExistingRoofSinglePlyMaterial_Min_Fields>;
  stddev?: Maybe<C_ExistingRoofSinglePlyMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_ExistingRoofSinglePlyMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ExistingRoofSinglePlyMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_ExistingRoofSinglePlyMaterial_Sum_Fields>;
  var_pop?: Maybe<C_ExistingRoofSinglePlyMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_ExistingRoofSinglePlyMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_ExistingRoofSinglePlyMaterial_Variance_Fields>;
};


/** aggregate fields of "C_ExistingRoofSinglePlyMaterial" */
export type C_ExistingRoofSinglePlyMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ExistingRoofSinglePlyMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ExistingRoofSinglePlyMaterial_Avg_Fields = {
  __typename?: 'C_ExistingRoofSinglePlyMaterial_avg_fields';
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ExistingRoofSinglePlyMaterial". All fields are combined with a logical 'AND'. */
export type C_ExistingRoofSinglePlyMaterial_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  ExistingRoofLayers?: Maybe<ExistingRoofLayer_Bool_Exp>;
  ExistingRoofSinglePlyMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_ExistingRoofSinglePlyMaterial_Bool_Exp>>;
  _not?: Maybe<C_ExistingRoofSinglePlyMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_ExistingRoofSinglePlyMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_ExistingRoofSinglePlyMaterial" */
export type C_ExistingRoofSinglePlyMaterial_If_Matched = {
  match_columns?: Array<C_ExistingRoofSinglePlyMaterial_Insert_Match_Column>;
  update_columns?: Array<C_ExistingRoofSinglePlyMaterial_Update_Column>;
  where?: Maybe<C_ExistingRoofSinglePlyMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_ExistingRoofSinglePlyMaterial" */
export type C_ExistingRoofSinglePlyMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofSinglePlyMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ExistingRoofSinglePlyMaterial" */
export enum C_ExistingRoofSinglePlyMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofSinglePlyMaterialCode = 'ExistingRoofSinglePlyMaterialCode',
  /** column name */
  ExistingRoofSinglePlyMaterialId = 'ExistingRoofSinglePlyMaterialId'
}

/** aggregate max on columns */
export type C_ExistingRoofSinglePlyMaterial_Max_Fields = {
  __typename?: 'C_ExistingRoofSinglePlyMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofSinglePlyMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ExistingRoofSinglePlyMaterial_Min_Fields = {
  __typename?: 'C_ExistingRoofSinglePlyMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofSinglePlyMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ExistingRoofSinglePlyMaterial" */
export type C_ExistingRoofSinglePlyMaterial_Mutation_Response = {
  __typename?: 'C_ExistingRoofSinglePlyMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ExistingRoofSinglePlyMaterial>;
};

/** Ordering options when selecting data from "C_ExistingRoofSinglePlyMaterial". */
export type C_ExistingRoofSinglePlyMaterial_Order_By = {
  Dsc?: Maybe<Order_By>;
  ExistingRoofLayers_aggregate?: Maybe<ExistingRoofLayer_Aggregate_Order_By>;
  ExistingRoofSinglePlyMaterialCode?: Maybe<Order_By>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_ExistingRoofSinglePlyMaterial */
export type C_ExistingRoofSinglePlyMaterial_Pk_Columns_Input = {
  ExistingRoofSinglePlyMaterialId: Scalars['Int'];
};

/** select columns of table "C_ExistingRoofSinglePlyMaterial" */
export enum C_ExistingRoofSinglePlyMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofSinglePlyMaterialCode = 'ExistingRoofSinglePlyMaterialCode',
  /** column name */
  ExistingRoofSinglePlyMaterialId = 'ExistingRoofSinglePlyMaterialId'
}

/** input type for updating data in table "C_ExistingRoofSinglePlyMaterial" */
export type C_ExistingRoofSinglePlyMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofSinglePlyMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ExistingRoofSinglePlyMaterial_Stddev_Fields = {
  __typename?: 'C_ExistingRoofSinglePlyMaterial_stddev_fields';
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ExistingRoofSinglePlyMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_ExistingRoofSinglePlyMaterial_stddev_pop_fields';
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ExistingRoofSinglePlyMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_ExistingRoofSinglePlyMaterial_stddev_samp_fields';
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ExistingRoofSinglePlyMaterial_Sum_Fields = {
  __typename?: 'C_ExistingRoofSinglePlyMaterial_sum_fields';
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ExistingRoofSinglePlyMaterial" */
export enum C_ExistingRoofSinglePlyMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofSinglePlyMaterialCode = 'ExistingRoofSinglePlyMaterialCode'
}

/** aggregate var_pop on columns */
export type C_ExistingRoofSinglePlyMaterial_Var_Pop_Fields = {
  __typename?: 'C_ExistingRoofSinglePlyMaterial_var_pop_fields';
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ExistingRoofSinglePlyMaterial_Var_Samp_Fields = {
  __typename?: 'C_ExistingRoofSinglePlyMaterial_var_samp_fields';
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ExistingRoofSinglePlyMaterial_Variance_Fields = {
  __typename?: 'C_ExistingRoofSinglePlyMaterial_variance_fields';
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ExistingRoofSurface" */
export type C_ExistingRoofSurface = {
  __typename?: 'C_ExistingRoofSurface';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  ExistingRoofLayers: Array<ExistingRoofLayer>;
  /** An aggregate relationship */
  ExistingRoofLayers_aggregate: ExistingRoofLayer_Aggregate;
  ExistingRoofSurfaceCode: Scalars['CodeTableValue'];
  ExistingRoofSurfaceId: Scalars['Int'];
};


/** columns and relationships of "C_ExistingRoofSurface" */
export type C_ExistingRoofSurfaceExistingRoofLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExistingRoofLayer_Order_By>>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};


/** columns and relationships of "C_ExistingRoofSurface" */
export type C_ExistingRoofSurfaceExistingRoofLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExistingRoofLayer_Order_By>>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};

/** aggregated selection of "C_ExistingRoofSurface" */
export type C_ExistingRoofSurface_Aggregate = {
  __typename?: 'C_ExistingRoofSurface_aggregate';
  aggregate?: Maybe<C_ExistingRoofSurface_Aggregate_Fields>;
  nodes: Array<C_ExistingRoofSurface>;
};

/** aggregate fields of "C_ExistingRoofSurface" */
export type C_ExistingRoofSurface_Aggregate_Fields = {
  __typename?: 'C_ExistingRoofSurface_aggregate_fields';
  avg?: Maybe<C_ExistingRoofSurface_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ExistingRoofSurface_Max_Fields>;
  min?: Maybe<C_ExistingRoofSurface_Min_Fields>;
  stddev?: Maybe<C_ExistingRoofSurface_Stddev_Fields>;
  stddev_pop?: Maybe<C_ExistingRoofSurface_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ExistingRoofSurface_Stddev_Samp_Fields>;
  sum?: Maybe<C_ExistingRoofSurface_Sum_Fields>;
  var_pop?: Maybe<C_ExistingRoofSurface_Var_Pop_Fields>;
  var_samp?: Maybe<C_ExistingRoofSurface_Var_Samp_Fields>;
  variance?: Maybe<C_ExistingRoofSurface_Variance_Fields>;
};


/** aggregate fields of "C_ExistingRoofSurface" */
export type C_ExistingRoofSurface_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ExistingRoofSurface_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ExistingRoofSurface_Avg_Fields = {
  __typename?: 'C_ExistingRoofSurface_avg_fields';
  ExistingRoofSurfaceId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ExistingRoofSurface". All fields are combined with a logical 'AND'. */
export type C_ExistingRoofSurface_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  ExistingRoofLayers?: Maybe<ExistingRoofLayer_Bool_Exp>;
  ExistingRoofSurfaceCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ExistingRoofSurfaceId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_ExistingRoofSurface_Bool_Exp>>;
  _not?: Maybe<C_ExistingRoofSurface_Bool_Exp>;
  _or?: Maybe<Array<C_ExistingRoofSurface_Bool_Exp>>;
};

/** upsert condition type for table "C_ExistingRoofSurface" */
export type C_ExistingRoofSurface_If_Matched = {
  match_columns?: Array<C_ExistingRoofSurface_Insert_Match_Column>;
  update_columns?: Array<C_ExistingRoofSurface_Update_Column>;
  where?: Maybe<C_ExistingRoofSurface_Bool_Exp>;
};

/** input type for inserting data into table "C_ExistingRoofSurface" */
export type C_ExistingRoofSurface_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofSurfaceCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ExistingRoofSurface" */
export enum C_ExistingRoofSurface_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofSurfaceCode = 'ExistingRoofSurfaceCode',
  /** column name */
  ExistingRoofSurfaceId = 'ExistingRoofSurfaceId'
}

/** aggregate max on columns */
export type C_ExistingRoofSurface_Max_Fields = {
  __typename?: 'C_ExistingRoofSurface_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofSurfaceCode?: Maybe<Scalars['CodeTableValue']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ExistingRoofSurface_Min_Fields = {
  __typename?: 'C_ExistingRoofSurface_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofSurfaceCode?: Maybe<Scalars['CodeTableValue']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ExistingRoofSurface" */
export type C_ExistingRoofSurface_Mutation_Response = {
  __typename?: 'C_ExistingRoofSurface_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ExistingRoofSurface>;
};

/** Ordering options when selecting data from "C_ExistingRoofSurface". */
export type C_ExistingRoofSurface_Order_By = {
  Dsc?: Maybe<Order_By>;
  ExistingRoofLayers_aggregate?: Maybe<ExistingRoofLayer_Aggregate_Order_By>;
  ExistingRoofSurfaceCode?: Maybe<Order_By>;
  ExistingRoofSurfaceId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_ExistingRoofSurface */
export type C_ExistingRoofSurface_Pk_Columns_Input = {
  ExistingRoofSurfaceId: Scalars['Int'];
};

/** select columns of table "C_ExistingRoofSurface" */
export enum C_ExistingRoofSurface_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofSurfaceCode = 'ExistingRoofSurfaceCode',
  /** column name */
  ExistingRoofSurfaceId = 'ExistingRoofSurfaceId'
}

/** input type for updating data in table "C_ExistingRoofSurface" */
export type C_ExistingRoofSurface_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExistingRoofSurfaceCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ExistingRoofSurface_Stddev_Fields = {
  __typename?: 'C_ExistingRoofSurface_stddev_fields';
  ExistingRoofSurfaceId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ExistingRoofSurface_Stddev_Pop_Fields = {
  __typename?: 'C_ExistingRoofSurface_stddev_pop_fields';
  ExistingRoofSurfaceId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ExistingRoofSurface_Stddev_Samp_Fields = {
  __typename?: 'C_ExistingRoofSurface_stddev_samp_fields';
  ExistingRoofSurfaceId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ExistingRoofSurface_Sum_Fields = {
  __typename?: 'C_ExistingRoofSurface_sum_fields';
  ExistingRoofSurfaceId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ExistingRoofSurface" */
export enum C_ExistingRoofSurface_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExistingRoofSurfaceCode = 'ExistingRoofSurfaceCode'
}

/** aggregate var_pop on columns */
export type C_ExistingRoofSurface_Var_Pop_Fields = {
  __typename?: 'C_ExistingRoofSurface_var_pop_fields';
  ExistingRoofSurfaceId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ExistingRoofSurface_Var_Samp_Fields = {
  __typename?: 'C_ExistingRoofSurface_var_samp_fields';
  ExistingRoofSurfaceId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ExistingRoofSurface_Variance_Fields = {
  __typename?: 'C_ExistingRoofSurface_variance_fields';
  ExistingRoofSurfaceId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ExtFireRating" */
export type C_ExtFireRating = {
  __typename?: 'C_ExtFireRating';
  Dsc: Scalars['CodeTableDescription'];
  ExtFireRatingCode: Scalars['CodeTableValue'];
  ExtFireRatingId: Scalars['Int'];
  /** An array relationship */
  RoofAreas: Array<RoofArea>;
  /** An aggregate relationship */
  RoofAreas_aggregate: RoofArea_Aggregate;
  /** An array relationship */
  RoofAssemblies: Array<RoofAssembly>;
  /** An aggregate relationship */
  RoofAssemblies_aggregate: RoofAssembly_Aggregate;
};


/** columns and relationships of "C_ExtFireRating" */
export type C_ExtFireRatingRoofAreasArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};


/** columns and relationships of "C_ExtFireRating" */
export type C_ExtFireRatingRoofAreas_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};


/** columns and relationships of "C_ExtFireRating" */
export type C_ExtFireRatingRoofAssembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "C_ExtFireRating" */
export type C_ExtFireRatingRoofAssemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};

/** aggregated selection of "C_ExtFireRating" */
export type C_ExtFireRating_Aggregate = {
  __typename?: 'C_ExtFireRating_aggregate';
  aggregate?: Maybe<C_ExtFireRating_Aggregate_Fields>;
  nodes: Array<C_ExtFireRating>;
};

/** aggregate fields of "C_ExtFireRating" */
export type C_ExtFireRating_Aggregate_Fields = {
  __typename?: 'C_ExtFireRating_aggregate_fields';
  avg?: Maybe<C_ExtFireRating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ExtFireRating_Max_Fields>;
  min?: Maybe<C_ExtFireRating_Min_Fields>;
  stddev?: Maybe<C_ExtFireRating_Stddev_Fields>;
  stddev_pop?: Maybe<C_ExtFireRating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ExtFireRating_Stddev_Samp_Fields>;
  sum?: Maybe<C_ExtFireRating_Sum_Fields>;
  var_pop?: Maybe<C_ExtFireRating_Var_Pop_Fields>;
  var_samp?: Maybe<C_ExtFireRating_Var_Samp_Fields>;
  variance?: Maybe<C_ExtFireRating_Variance_Fields>;
};


/** aggregate fields of "C_ExtFireRating" */
export type C_ExtFireRating_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ExtFireRating_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ExtFireRating_Avg_Fields = {
  __typename?: 'C_ExtFireRating_avg_fields';
  ExtFireRatingId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ExtFireRating". All fields are combined with a logical 'AND'. */
export type C_ExtFireRating_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  ExtFireRatingCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ExtFireRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  RoofAreas?: Maybe<RoofArea_Bool_Exp>;
  RoofAssemblies?: Maybe<RoofAssembly_Bool_Exp>;
  _and?: Maybe<Array<C_ExtFireRating_Bool_Exp>>;
  _not?: Maybe<C_ExtFireRating_Bool_Exp>;
  _or?: Maybe<Array<C_ExtFireRating_Bool_Exp>>;
};

/** upsert condition type for table "C_ExtFireRating" */
export type C_ExtFireRating_If_Matched = {
  match_columns?: Array<C_ExtFireRating_Insert_Match_Column>;
  update_columns?: Array<C_ExtFireRating_Update_Column>;
  where?: Maybe<C_ExtFireRating_Bool_Exp>;
};

/** input type for inserting data into table "C_ExtFireRating" */
export type C_ExtFireRating_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExtFireRatingCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ExtFireRating" */
export enum C_ExtFireRating_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExtFireRatingCode = 'ExtFireRatingCode',
  /** column name */
  ExtFireRatingId = 'ExtFireRatingId'
}

/** aggregate max on columns */
export type C_ExtFireRating_Max_Fields = {
  __typename?: 'C_ExtFireRating_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExtFireRatingCode?: Maybe<Scalars['CodeTableValue']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ExtFireRating_Min_Fields = {
  __typename?: 'C_ExtFireRating_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExtFireRatingCode?: Maybe<Scalars['CodeTableValue']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ExtFireRating" */
export type C_ExtFireRating_Mutation_Response = {
  __typename?: 'C_ExtFireRating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ExtFireRating>;
};

/** Ordering options when selecting data from "C_ExtFireRating". */
export type C_ExtFireRating_Order_By = {
  Dsc?: Maybe<Order_By>;
  ExtFireRatingCode?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  RoofAreas_aggregate?: Maybe<RoofArea_Aggregate_Order_By>;
  RoofAssemblies_aggregate?: Maybe<RoofAssembly_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ExtFireRating */
export type C_ExtFireRating_Pk_Columns_Input = {
  ExtFireRatingId: Scalars['Int'];
};

/** select columns of table "C_ExtFireRating" */
export enum C_ExtFireRating_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExtFireRatingCode = 'ExtFireRatingCode',
  /** column name */
  ExtFireRatingId = 'ExtFireRatingId'
}

/** input type for updating data in table "C_ExtFireRating" */
export type C_ExtFireRating_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ExtFireRatingCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ExtFireRating_Stddev_Fields = {
  __typename?: 'C_ExtFireRating_stddev_fields';
  ExtFireRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ExtFireRating_Stddev_Pop_Fields = {
  __typename?: 'C_ExtFireRating_stddev_pop_fields';
  ExtFireRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ExtFireRating_Stddev_Samp_Fields = {
  __typename?: 'C_ExtFireRating_stddev_samp_fields';
  ExtFireRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ExtFireRating_Sum_Fields = {
  __typename?: 'C_ExtFireRating_sum_fields';
  ExtFireRatingId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ExtFireRating" */
export enum C_ExtFireRating_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ExtFireRatingCode = 'ExtFireRatingCode'
}

/** aggregate var_pop on columns */
export type C_ExtFireRating_Var_Pop_Fields = {
  __typename?: 'C_ExtFireRating_var_pop_fields';
  ExtFireRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ExtFireRating_Var_Samp_Fields = {
  __typename?: 'C_ExtFireRating_var_samp_fields';
  ExtFireRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ExtFireRating_Variance_Fields = {
  __typename?: 'C_ExtFireRating_variance_fields';
  ExtFireRatingId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_Facer" */
export type C_Facer = {
  __typename?: 'C_Facer';
  /** An array relationship */
  BoardStockFacerBottoms: Array<BoardStockFacerBottom>;
  /** An aggregate relationship */
  BoardStockFacerBottoms_aggregate: BoardStockFacerBottom_Aggregate;
  /** An array relationship */
  BoardStockFacerTops: Array<BoardStockFacerTop>;
  /** An aggregate relationship */
  BoardStockFacerTops_aggregate: BoardStockFacerTop_Aggregate;
  /** An array relationship */
  CoverBoardFacerBottoms: Array<CoverBoardFacerBottom>;
  /** An aggregate relationship */
  CoverBoardFacerBottoms_aggregate: CoverBoardFacerBottom_Aggregate;
  /** An array relationship */
  CoverBoardFacerTops: Array<CoverBoardFacerTop>;
  /** An aggregate relationship */
  CoverBoardFacerTops_aggregate: CoverBoardFacerTop_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  FacerCode: Scalars['CodeTableValue'];
  FacerId: Scalars['Int'];
  /** An array relationship */
  ThermalBarrierFacerBottoms: Array<ThermalBarrierFacerBottom>;
  /** An aggregate relationship */
  ThermalBarrierFacerBottoms_aggregate: ThermalBarrierFacerBottom_Aggregate;
  /** An array relationship */
  ThermalBarrierFacerTops: Array<ThermalBarrierFacerTop>;
  /** An aggregate relationship */
  ThermalBarrierFacerTops_aggregate: ThermalBarrierFacerTop_Aggregate;
};


/** columns and relationships of "C_Facer" */
export type C_FacerBoardStockFacerBottomsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerBottom_Order_By>>;
  where?: Maybe<BoardStockFacerBottom_Bool_Exp>;
};


/** columns and relationships of "C_Facer" */
export type C_FacerBoardStockFacerBottoms_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerBottom_Order_By>>;
  where?: Maybe<BoardStockFacerBottom_Bool_Exp>;
};


/** columns and relationships of "C_Facer" */
export type C_FacerBoardStockFacerTopsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerTop_Order_By>>;
  where?: Maybe<BoardStockFacerTop_Bool_Exp>;
};


/** columns and relationships of "C_Facer" */
export type C_FacerBoardStockFacerTops_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerTop_Order_By>>;
  where?: Maybe<BoardStockFacerTop_Bool_Exp>;
};


/** columns and relationships of "C_Facer" */
export type C_FacerCoverBoardFacerBottomsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerBottom_Order_By>>;
  where?: Maybe<CoverBoardFacerBottom_Bool_Exp>;
};


/** columns and relationships of "C_Facer" */
export type C_FacerCoverBoardFacerBottoms_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerBottom_Order_By>>;
  where?: Maybe<CoverBoardFacerBottom_Bool_Exp>;
};


/** columns and relationships of "C_Facer" */
export type C_FacerCoverBoardFacerTopsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerTop_Order_By>>;
  where?: Maybe<CoverBoardFacerTop_Bool_Exp>;
};


/** columns and relationships of "C_Facer" */
export type C_FacerCoverBoardFacerTops_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerTop_Order_By>>;
  where?: Maybe<CoverBoardFacerTop_Bool_Exp>;
};


/** columns and relationships of "C_Facer" */
export type C_FacerThermalBarrierFacerBottomsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerBottom_Order_By>>;
  where?: Maybe<ThermalBarrierFacerBottom_Bool_Exp>;
};


/** columns and relationships of "C_Facer" */
export type C_FacerThermalBarrierFacerBottoms_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerBottom_Order_By>>;
  where?: Maybe<ThermalBarrierFacerBottom_Bool_Exp>;
};


/** columns and relationships of "C_Facer" */
export type C_FacerThermalBarrierFacerTopsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerTop_Order_By>>;
  where?: Maybe<ThermalBarrierFacerTop_Bool_Exp>;
};


/** columns and relationships of "C_Facer" */
export type C_FacerThermalBarrierFacerTops_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerTop_Order_By>>;
  where?: Maybe<ThermalBarrierFacerTop_Bool_Exp>;
};

/** columns and relationships of "C_FacerMaterial" */
export type C_FacerMaterial = {
  __typename?: 'C_FacerMaterial';
  /** An array relationship */
  CompositePanelCovers: Array<CompositePanelCover>;
  /** An aggregate relationship */
  CompositePanelCovers_aggregate: CompositePanelCover_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  FacerMaterialCode: Scalars['CodeTableValue'];
  FacerMaterialId: Scalars['Int'];
  /** An array relationship */
  compositePanelCoversByBottomfacermaterialid: Array<CompositePanelCover>;
  /** An aggregate relationship */
  compositePanelCoversByBottomfacermaterialid_aggregate: CompositePanelCover_Aggregate;
};


/** columns and relationships of "C_FacerMaterial" */
export type C_FacerMaterialCompositePanelCoversArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompositePanelCover_Order_By>>;
  where?: Maybe<CompositePanelCover_Bool_Exp>;
};


/** columns and relationships of "C_FacerMaterial" */
export type C_FacerMaterialCompositePanelCovers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompositePanelCover_Order_By>>;
  where?: Maybe<CompositePanelCover_Bool_Exp>;
};


/** columns and relationships of "C_FacerMaterial" */
export type C_FacerMaterialCompositePanelCoversByBottomfacermaterialidArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompositePanelCover_Order_By>>;
  where?: Maybe<CompositePanelCover_Bool_Exp>;
};


/** columns and relationships of "C_FacerMaterial" */
export type C_FacerMaterialCompositePanelCoversByBottomfacermaterialid_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompositePanelCover_Order_By>>;
  where?: Maybe<CompositePanelCover_Bool_Exp>;
};

/** aggregated selection of "C_FacerMaterial" */
export type C_FacerMaterial_Aggregate = {
  __typename?: 'C_FacerMaterial_aggregate';
  aggregate?: Maybe<C_FacerMaterial_Aggregate_Fields>;
  nodes: Array<C_FacerMaterial>;
};

/** aggregate fields of "C_FacerMaterial" */
export type C_FacerMaterial_Aggregate_Fields = {
  __typename?: 'C_FacerMaterial_aggregate_fields';
  avg?: Maybe<C_FacerMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_FacerMaterial_Max_Fields>;
  min?: Maybe<C_FacerMaterial_Min_Fields>;
  stddev?: Maybe<C_FacerMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_FacerMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_FacerMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_FacerMaterial_Sum_Fields>;
  var_pop?: Maybe<C_FacerMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_FacerMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_FacerMaterial_Variance_Fields>;
};


/** aggregate fields of "C_FacerMaterial" */
export type C_FacerMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_FacerMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_FacerMaterial_Avg_Fields = {
  __typename?: 'C_FacerMaterial_avg_fields';
  FacerMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_FacerMaterial". All fields are combined with a logical 'AND'. */
export type C_FacerMaterial_Bool_Exp = {
  CompositePanelCovers?: Maybe<CompositePanelCover_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  FacerMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  FacerMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_FacerMaterial_Bool_Exp>>;
  _not?: Maybe<C_FacerMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_FacerMaterial_Bool_Exp>>;
  compositePanelCoversByBottomfacermaterialid?: Maybe<CompositePanelCover_Bool_Exp>;
};

/** upsert condition type for table "C_FacerMaterial" */
export type C_FacerMaterial_If_Matched = {
  match_columns?: Array<C_FacerMaterial_Insert_Match_Column>;
  update_columns?: Array<C_FacerMaterial_Update_Column>;
  where?: Maybe<C_FacerMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_FacerMaterial" */
export type C_FacerMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FacerMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_FacerMaterial" */
export enum C_FacerMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FacerMaterialCode = 'FacerMaterialCode',
  /** column name */
  FacerMaterialId = 'FacerMaterialId'
}

/** aggregate max on columns */
export type C_FacerMaterial_Max_Fields = {
  __typename?: 'C_FacerMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FacerMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  FacerMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_FacerMaterial_Min_Fields = {
  __typename?: 'C_FacerMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FacerMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  FacerMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_FacerMaterial" */
export type C_FacerMaterial_Mutation_Response = {
  __typename?: 'C_FacerMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_FacerMaterial>;
};

/** Ordering options when selecting data from "C_FacerMaterial". */
export type C_FacerMaterial_Order_By = {
  CompositePanelCovers_aggregate?: Maybe<CompositePanelCover_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  FacerMaterialCode?: Maybe<Order_By>;
  FacerMaterialId?: Maybe<Order_By>;
  compositePanelCoversByBottomfacermaterialid_aggregate?: Maybe<CompositePanelCover_Aggregate_Order_By>;
};

/** primary key columns input for table: C_FacerMaterial */
export type C_FacerMaterial_Pk_Columns_Input = {
  FacerMaterialId: Scalars['Int'];
};

/** select columns of table "C_FacerMaterial" */
export enum C_FacerMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FacerMaterialCode = 'FacerMaterialCode',
  /** column name */
  FacerMaterialId = 'FacerMaterialId'
}

/** input type for updating data in table "C_FacerMaterial" */
export type C_FacerMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FacerMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_FacerMaterial_Stddev_Fields = {
  __typename?: 'C_FacerMaterial_stddev_fields';
  FacerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_FacerMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_FacerMaterial_stddev_pop_fields';
  FacerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_FacerMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_FacerMaterial_stddev_samp_fields';
  FacerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_FacerMaterial_Sum_Fields = {
  __typename?: 'C_FacerMaterial_sum_fields';
  FacerMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_FacerMaterial" */
export enum C_FacerMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FacerMaterialCode = 'FacerMaterialCode'
}

/** aggregate var_pop on columns */
export type C_FacerMaterial_Var_Pop_Fields = {
  __typename?: 'C_FacerMaterial_var_pop_fields';
  FacerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_FacerMaterial_Var_Samp_Fields = {
  __typename?: 'C_FacerMaterial_var_samp_fields';
  FacerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_FacerMaterial_Variance_Fields = {
  __typename?: 'C_FacerMaterial_variance_fields';
  FacerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "C_Facer" */
export type C_Facer_Aggregate = {
  __typename?: 'C_Facer_aggregate';
  aggregate?: Maybe<C_Facer_Aggregate_Fields>;
  nodes: Array<C_Facer>;
};

/** aggregate fields of "C_Facer" */
export type C_Facer_Aggregate_Fields = {
  __typename?: 'C_Facer_aggregate_fields';
  avg?: Maybe<C_Facer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_Facer_Max_Fields>;
  min?: Maybe<C_Facer_Min_Fields>;
  stddev?: Maybe<C_Facer_Stddev_Fields>;
  stddev_pop?: Maybe<C_Facer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_Facer_Stddev_Samp_Fields>;
  sum?: Maybe<C_Facer_Sum_Fields>;
  var_pop?: Maybe<C_Facer_Var_Pop_Fields>;
  var_samp?: Maybe<C_Facer_Var_Samp_Fields>;
  variance?: Maybe<C_Facer_Variance_Fields>;
};


/** aggregate fields of "C_Facer" */
export type C_Facer_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_Facer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_Facer_Avg_Fields = {
  __typename?: 'C_Facer_avg_fields';
  FacerId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_Facer". All fields are combined with a logical 'AND'. */
export type C_Facer_Bool_Exp = {
  BoardStockFacerBottoms?: Maybe<BoardStockFacerBottom_Bool_Exp>;
  BoardStockFacerTops?: Maybe<BoardStockFacerTop_Bool_Exp>;
  CoverBoardFacerBottoms?: Maybe<CoverBoardFacerBottom_Bool_Exp>;
  CoverBoardFacerTops?: Maybe<CoverBoardFacerTop_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  FacerCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  FacerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThermalBarrierFacerBottoms?: Maybe<ThermalBarrierFacerBottom_Bool_Exp>;
  ThermalBarrierFacerTops?: Maybe<ThermalBarrierFacerTop_Bool_Exp>;
  _and?: Maybe<Array<C_Facer_Bool_Exp>>;
  _not?: Maybe<C_Facer_Bool_Exp>;
  _or?: Maybe<Array<C_Facer_Bool_Exp>>;
};

/** upsert condition type for table "C_Facer" */
export type C_Facer_If_Matched = {
  match_columns?: Array<C_Facer_Insert_Match_Column>;
  update_columns?: Array<C_Facer_Update_Column>;
  where?: Maybe<C_Facer_Bool_Exp>;
};

/** input type for inserting data into table "C_Facer" */
export type C_Facer_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FacerCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_Facer" */
export enum C_Facer_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FacerCode = 'FacerCode',
  /** column name */
  FacerId = 'FacerId'
}

/** aggregate max on columns */
export type C_Facer_Max_Fields = {
  __typename?: 'C_Facer_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FacerCode?: Maybe<Scalars['CodeTableValue']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_Facer_Min_Fields = {
  __typename?: 'C_Facer_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FacerCode?: Maybe<Scalars['CodeTableValue']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_Facer" */
export type C_Facer_Mutation_Response = {
  __typename?: 'C_Facer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_Facer>;
};

/** Ordering options when selecting data from "C_Facer". */
export type C_Facer_Order_By = {
  BoardStockFacerBottoms_aggregate?: Maybe<BoardStockFacerBottom_Aggregate_Order_By>;
  BoardStockFacerTops_aggregate?: Maybe<BoardStockFacerTop_Aggregate_Order_By>;
  CoverBoardFacerBottoms_aggregate?: Maybe<CoverBoardFacerBottom_Aggregate_Order_By>;
  CoverBoardFacerTops_aggregate?: Maybe<CoverBoardFacerTop_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  FacerCode?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
  ThermalBarrierFacerBottoms_aggregate?: Maybe<ThermalBarrierFacerBottom_Aggregate_Order_By>;
  ThermalBarrierFacerTops_aggregate?: Maybe<ThermalBarrierFacerTop_Aggregate_Order_By>;
};

/** primary key columns input for table: C_Facer */
export type C_Facer_Pk_Columns_Input = {
  FacerId: Scalars['Int'];
};

/** select columns of table "C_Facer" */
export enum C_Facer_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FacerCode = 'FacerCode',
  /** column name */
  FacerId = 'FacerId'
}

/** input type for updating data in table "C_Facer" */
export type C_Facer_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FacerCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_Facer_Stddev_Fields = {
  __typename?: 'C_Facer_stddev_fields';
  FacerId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_Facer_Stddev_Pop_Fields = {
  __typename?: 'C_Facer_stddev_pop_fields';
  FacerId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_Facer_Stddev_Samp_Fields = {
  __typename?: 'C_Facer_stddev_samp_fields';
  FacerId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_Facer_Sum_Fields = {
  __typename?: 'C_Facer_sum_fields';
  FacerId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_Facer" */
export enum C_Facer_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FacerCode = 'FacerCode'
}

/** aggregate var_pop on columns */
export type C_Facer_Var_Pop_Fields = {
  __typename?: 'C_Facer_var_pop_fields';
  FacerId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_Facer_Var_Samp_Fields = {
  __typename?: 'C_Facer_var_samp_fields';
  FacerId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_Facer_Variance_Fields = {
  __typename?: 'C_Facer_variance_fields';
  FacerId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_FastenerCoating" */
export type C_FastenerCoating = {
  __typename?: 'C_FastenerCoating';
  Dsc: Scalars['CodeTableDescription'];
  FastenerCoatingCode: Scalars['CodeTableValue'];
  FastenerCoatingId: Scalars['Int'];
  /** An array relationship */
  Fasteners: Array<Fastener>;
  /** An aggregate relationship */
  Fasteners_aggregate: Fastener_Aggregate;
  /** An array relationship */
  PreassembledFastenings: Array<PreassembledFastening>;
  /** An aggregate relationship */
  PreassembledFastenings_aggregate: PreassembledFastening_Aggregate;
};


/** columns and relationships of "C_FastenerCoating" */
export type C_FastenerCoatingFastenersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fastener_Order_By>>;
  where?: Maybe<Fastener_Bool_Exp>;
};


/** columns and relationships of "C_FastenerCoating" */
export type C_FastenerCoatingFasteners_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fastener_Order_By>>;
  where?: Maybe<Fastener_Bool_Exp>;
};


/** columns and relationships of "C_FastenerCoating" */
export type C_FastenerCoatingPreassembledFasteningsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};


/** columns and relationships of "C_FastenerCoating" */
export type C_FastenerCoatingPreassembledFastenings_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};

/** aggregated selection of "C_FastenerCoating" */
export type C_FastenerCoating_Aggregate = {
  __typename?: 'C_FastenerCoating_aggregate';
  aggregate?: Maybe<C_FastenerCoating_Aggregate_Fields>;
  nodes: Array<C_FastenerCoating>;
};

/** aggregate fields of "C_FastenerCoating" */
export type C_FastenerCoating_Aggregate_Fields = {
  __typename?: 'C_FastenerCoating_aggregate_fields';
  avg?: Maybe<C_FastenerCoating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_FastenerCoating_Max_Fields>;
  min?: Maybe<C_FastenerCoating_Min_Fields>;
  stddev?: Maybe<C_FastenerCoating_Stddev_Fields>;
  stddev_pop?: Maybe<C_FastenerCoating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_FastenerCoating_Stddev_Samp_Fields>;
  sum?: Maybe<C_FastenerCoating_Sum_Fields>;
  var_pop?: Maybe<C_FastenerCoating_Var_Pop_Fields>;
  var_samp?: Maybe<C_FastenerCoating_Var_Samp_Fields>;
  variance?: Maybe<C_FastenerCoating_Variance_Fields>;
};


/** aggregate fields of "C_FastenerCoating" */
export type C_FastenerCoating_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_FastenerCoating_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_FastenerCoating_Avg_Fields = {
  __typename?: 'C_FastenerCoating_avg_fields';
  FastenerCoatingId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_FastenerCoating". All fields are combined with a logical 'AND'. */
export type C_FastenerCoating_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  FastenerCoatingCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  FastenerCoatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  Fasteners?: Maybe<Fastener_Bool_Exp>;
  PreassembledFastenings?: Maybe<PreassembledFastening_Bool_Exp>;
  _and?: Maybe<Array<C_FastenerCoating_Bool_Exp>>;
  _not?: Maybe<C_FastenerCoating_Bool_Exp>;
  _or?: Maybe<Array<C_FastenerCoating_Bool_Exp>>;
};

/** upsert condition type for table "C_FastenerCoating" */
export type C_FastenerCoating_If_Matched = {
  match_columns?: Array<C_FastenerCoating_Insert_Match_Column>;
  update_columns?: Array<C_FastenerCoating_Update_Column>;
  where?: Maybe<C_FastenerCoating_Bool_Exp>;
};

/** input type for inserting data into table "C_FastenerCoating" */
export type C_FastenerCoating_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FastenerCoatingCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_FastenerCoating" */
export enum C_FastenerCoating_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FastenerCoatingCode = 'FastenerCoatingCode',
  /** column name */
  FastenerCoatingId = 'FastenerCoatingId'
}

/** aggregate max on columns */
export type C_FastenerCoating_Max_Fields = {
  __typename?: 'C_FastenerCoating_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FastenerCoatingCode?: Maybe<Scalars['CodeTableValue']>;
  FastenerCoatingId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_FastenerCoating_Min_Fields = {
  __typename?: 'C_FastenerCoating_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FastenerCoatingCode?: Maybe<Scalars['CodeTableValue']>;
  FastenerCoatingId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_FastenerCoating" */
export type C_FastenerCoating_Mutation_Response = {
  __typename?: 'C_FastenerCoating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_FastenerCoating>;
};

/** Ordering options when selecting data from "C_FastenerCoating". */
export type C_FastenerCoating_Order_By = {
  Dsc?: Maybe<Order_By>;
  FastenerCoatingCode?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  Fasteners_aggregate?: Maybe<Fastener_Aggregate_Order_By>;
  PreassembledFastenings_aggregate?: Maybe<PreassembledFastening_Aggregate_Order_By>;
};

/** primary key columns input for table: C_FastenerCoating */
export type C_FastenerCoating_Pk_Columns_Input = {
  FastenerCoatingId: Scalars['Int'];
};

/** select columns of table "C_FastenerCoating" */
export enum C_FastenerCoating_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FastenerCoatingCode = 'FastenerCoatingCode',
  /** column name */
  FastenerCoatingId = 'FastenerCoatingId'
}

/** input type for updating data in table "C_FastenerCoating" */
export type C_FastenerCoating_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FastenerCoatingCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_FastenerCoating_Stddev_Fields = {
  __typename?: 'C_FastenerCoating_stddev_fields';
  FastenerCoatingId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_FastenerCoating_Stddev_Pop_Fields = {
  __typename?: 'C_FastenerCoating_stddev_pop_fields';
  FastenerCoatingId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_FastenerCoating_Stddev_Samp_Fields = {
  __typename?: 'C_FastenerCoating_stddev_samp_fields';
  FastenerCoatingId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_FastenerCoating_Sum_Fields = {
  __typename?: 'C_FastenerCoating_sum_fields';
  FastenerCoatingId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_FastenerCoating" */
export enum C_FastenerCoating_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FastenerCoatingCode = 'FastenerCoatingCode'
}

/** aggregate var_pop on columns */
export type C_FastenerCoating_Var_Pop_Fields = {
  __typename?: 'C_FastenerCoating_var_pop_fields';
  FastenerCoatingId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_FastenerCoating_Var_Samp_Fields = {
  __typename?: 'C_FastenerCoating_var_samp_fields';
  FastenerCoatingId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_FastenerCoating_Variance_Fields = {
  __typename?: 'C_FastenerCoating_variance_fields';
  FastenerCoatingId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_FastenerDriveHead" */
export type C_FastenerDriveHead = {
  __typename?: 'C_FastenerDriveHead';
  Dsc: Scalars['CodeTableDescription'];
  FastenerDriveHeadCode: Scalars['CodeTableValue'];
  FastenerDriveHeadId: Scalars['Int'];
  /** An array relationship */
  Fasteners: Array<Fastener>;
  /** An aggregate relationship */
  Fasteners_aggregate: Fastener_Aggregate;
  /** An array relationship */
  PreassembledFastenings: Array<PreassembledFastening>;
  /** An aggregate relationship */
  PreassembledFastenings_aggregate: PreassembledFastening_Aggregate;
};


/** columns and relationships of "C_FastenerDriveHead" */
export type C_FastenerDriveHeadFastenersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fastener_Order_By>>;
  where?: Maybe<Fastener_Bool_Exp>;
};


/** columns and relationships of "C_FastenerDriveHead" */
export type C_FastenerDriveHeadFasteners_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fastener_Order_By>>;
  where?: Maybe<Fastener_Bool_Exp>;
};


/** columns and relationships of "C_FastenerDriveHead" */
export type C_FastenerDriveHeadPreassembledFasteningsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};


/** columns and relationships of "C_FastenerDriveHead" */
export type C_FastenerDriveHeadPreassembledFastenings_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};

/** aggregated selection of "C_FastenerDriveHead" */
export type C_FastenerDriveHead_Aggregate = {
  __typename?: 'C_FastenerDriveHead_aggregate';
  aggregate?: Maybe<C_FastenerDriveHead_Aggregate_Fields>;
  nodes: Array<C_FastenerDriveHead>;
};

/** aggregate fields of "C_FastenerDriveHead" */
export type C_FastenerDriveHead_Aggregate_Fields = {
  __typename?: 'C_FastenerDriveHead_aggregate_fields';
  avg?: Maybe<C_FastenerDriveHead_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_FastenerDriveHead_Max_Fields>;
  min?: Maybe<C_FastenerDriveHead_Min_Fields>;
  stddev?: Maybe<C_FastenerDriveHead_Stddev_Fields>;
  stddev_pop?: Maybe<C_FastenerDriveHead_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_FastenerDriveHead_Stddev_Samp_Fields>;
  sum?: Maybe<C_FastenerDriveHead_Sum_Fields>;
  var_pop?: Maybe<C_FastenerDriveHead_Var_Pop_Fields>;
  var_samp?: Maybe<C_FastenerDriveHead_Var_Samp_Fields>;
  variance?: Maybe<C_FastenerDriveHead_Variance_Fields>;
};


/** aggregate fields of "C_FastenerDriveHead" */
export type C_FastenerDriveHead_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_FastenerDriveHead_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_FastenerDriveHead_Avg_Fields = {
  __typename?: 'C_FastenerDriveHead_avg_fields';
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_FastenerDriveHead". All fields are combined with a logical 'AND'. */
export type C_FastenerDriveHead_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  FastenerDriveHeadCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  FastenerDriveHeadId?: Maybe<Int_Mssql_Comparison_Exp>;
  Fasteners?: Maybe<Fastener_Bool_Exp>;
  PreassembledFastenings?: Maybe<PreassembledFastening_Bool_Exp>;
  _and?: Maybe<Array<C_FastenerDriveHead_Bool_Exp>>;
  _not?: Maybe<C_FastenerDriveHead_Bool_Exp>;
  _or?: Maybe<Array<C_FastenerDriveHead_Bool_Exp>>;
};

/** upsert condition type for table "C_FastenerDriveHead" */
export type C_FastenerDriveHead_If_Matched = {
  match_columns?: Array<C_FastenerDriveHead_Insert_Match_Column>;
  update_columns?: Array<C_FastenerDriveHead_Update_Column>;
  where?: Maybe<C_FastenerDriveHead_Bool_Exp>;
};

/** input type for inserting data into table "C_FastenerDriveHead" */
export type C_FastenerDriveHead_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FastenerDriveHeadCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_FastenerDriveHead" */
export enum C_FastenerDriveHead_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FastenerDriveHeadCode = 'FastenerDriveHeadCode',
  /** column name */
  FastenerDriveHeadId = 'FastenerDriveHeadId'
}

/** aggregate max on columns */
export type C_FastenerDriveHead_Max_Fields = {
  __typename?: 'C_FastenerDriveHead_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FastenerDriveHeadCode?: Maybe<Scalars['CodeTableValue']>;
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_FastenerDriveHead_Min_Fields = {
  __typename?: 'C_FastenerDriveHead_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FastenerDriveHeadCode?: Maybe<Scalars['CodeTableValue']>;
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_FastenerDriveHead" */
export type C_FastenerDriveHead_Mutation_Response = {
  __typename?: 'C_FastenerDriveHead_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_FastenerDriveHead>;
};

/** Ordering options when selecting data from "C_FastenerDriveHead". */
export type C_FastenerDriveHead_Order_By = {
  Dsc?: Maybe<Order_By>;
  FastenerDriveHeadCode?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  Fasteners_aggregate?: Maybe<Fastener_Aggregate_Order_By>;
  PreassembledFastenings_aggregate?: Maybe<PreassembledFastening_Aggregate_Order_By>;
};

/** primary key columns input for table: C_FastenerDriveHead */
export type C_FastenerDriveHead_Pk_Columns_Input = {
  FastenerDriveHeadId: Scalars['Int'];
};

/** select columns of table "C_FastenerDriveHead" */
export enum C_FastenerDriveHead_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FastenerDriveHeadCode = 'FastenerDriveHeadCode',
  /** column name */
  FastenerDriveHeadId = 'FastenerDriveHeadId'
}

/** input type for updating data in table "C_FastenerDriveHead" */
export type C_FastenerDriveHead_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FastenerDriveHeadCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_FastenerDriveHead_Stddev_Fields = {
  __typename?: 'C_FastenerDriveHead_stddev_fields';
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_FastenerDriveHead_Stddev_Pop_Fields = {
  __typename?: 'C_FastenerDriveHead_stddev_pop_fields';
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_FastenerDriveHead_Stddev_Samp_Fields = {
  __typename?: 'C_FastenerDriveHead_stddev_samp_fields';
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_FastenerDriveHead_Sum_Fields = {
  __typename?: 'C_FastenerDriveHead_sum_fields';
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_FastenerDriveHead" */
export enum C_FastenerDriveHead_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FastenerDriveHeadCode = 'FastenerDriveHeadCode'
}

/** aggregate var_pop on columns */
export type C_FastenerDriveHead_Var_Pop_Fields = {
  __typename?: 'C_FastenerDriveHead_var_pop_fields';
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_FastenerDriveHead_Var_Samp_Fields = {
  __typename?: 'C_FastenerDriveHead_var_samp_fields';
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_FastenerDriveHead_Variance_Fields = {
  __typename?: 'C_FastenerDriveHead_variance_fields';
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_FastenerType" */
export type C_FastenerType = {
  __typename?: 'C_FastenerType';
  Dsc: Scalars['CodeTableDescription'];
  FastenerTypeCode: Scalars['CodeTableValue'];
  FastenerTypeId: Scalars['Int'];
  /** An array relationship */
  Fasteners: Array<Fastener>;
  /** An aggregate relationship */
  Fasteners_aggregate: Fastener_Aggregate;
  /** An array relationship */
  PreassembledFastenings: Array<PreassembledFastening>;
  /** An aggregate relationship */
  PreassembledFastenings_aggregate: PreassembledFastening_Aggregate;
};


/** columns and relationships of "C_FastenerType" */
export type C_FastenerTypeFastenersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fastener_Order_By>>;
  where?: Maybe<Fastener_Bool_Exp>;
};


/** columns and relationships of "C_FastenerType" */
export type C_FastenerTypeFasteners_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fastener_Order_By>>;
  where?: Maybe<Fastener_Bool_Exp>;
};


/** columns and relationships of "C_FastenerType" */
export type C_FastenerTypePreassembledFasteningsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};


/** columns and relationships of "C_FastenerType" */
export type C_FastenerTypePreassembledFastenings_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};

/** aggregated selection of "C_FastenerType" */
export type C_FastenerType_Aggregate = {
  __typename?: 'C_FastenerType_aggregate';
  aggregate?: Maybe<C_FastenerType_Aggregate_Fields>;
  nodes: Array<C_FastenerType>;
};

/** aggregate fields of "C_FastenerType" */
export type C_FastenerType_Aggregate_Fields = {
  __typename?: 'C_FastenerType_aggregate_fields';
  avg?: Maybe<C_FastenerType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_FastenerType_Max_Fields>;
  min?: Maybe<C_FastenerType_Min_Fields>;
  stddev?: Maybe<C_FastenerType_Stddev_Fields>;
  stddev_pop?: Maybe<C_FastenerType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_FastenerType_Stddev_Samp_Fields>;
  sum?: Maybe<C_FastenerType_Sum_Fields>;
  var_pop?: Maybe<C_FastenerType_Var_Pop_Fields>;
  var_samp?: Maybe<C_FastenerType_Var_Samp_Fields>;
  variance?: Maybe<C_FastenerType_Variance_Fields>;
};


/** aggregate fields of "C_FastenerType" */
export type C_FastenerType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_FastenerType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_FastenerType_Avg_Fields = {
  __typename?: 'C_FastenerType_avg_fields';
  FastenerTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_FastenerType". All fields are combined with a logical 'AND'. */
export type C_FastenerType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  FastenerTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  FastenerTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Fasteners?: Maybe<Fastener_Bool_Exp>;
  PreassembledFastenings?: Maybe<PreassembledFastening_Bool_Exp>;
  _and?: Maybe<Array<C_FastenerType_Bool_Exp>>;
  _not?: Maybe<C_FastenerType_Bool_Exp>;
  _or?: Maybe<Array<C_FastenerType_Bool_Exp>>;
};

/** upsert condition type for table "C_FastenerType" */
export type C_FastenerType_If_Matched = {
  match_columns?: Array<C_FastenerType_Insert_Match_Column>;
  update_columns?: Array<C_FastenerType_Update_Column>;
  where?: Maybe<C_FastenerType_Bool_Exp>;
};

/** input type for inserting data into table "C_FastenerType" */
export type C_FastenerType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FastenerTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_FastenerType" */
export enum C_FastenerType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FastenerTypeCode = 'FastenerTypeCode',
  /** column name */
  FastenerTypeId = 'FastenerTypeId'
}

/** aggregate max on columns */
export type C_FastenerType_Max_Fields = {
  __typename?: 'C_FastenerType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FastenerTypeCode?: Maybe<Scalars['CodeTableValue']>;
  FastenerTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_FastenerType_Min_Fields = {
  __typename?: 'C_FastenerType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FastenerTypeCode?: Maybe<Scalars['CodeTableValue']>;
  FastenerTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_FastenerType" */
export type C_FastenerType_Mutation_Response = {
  __typename?: 'C_FastenerType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_FastenerType>;
};

/** Ordering options when selecting data from "C_FastenerType". */
export type C_FastenerType_Order_By = {
  Dsc?: Maybe<Order_By>;
  FastenerTypeCode?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  Fasteners_aggregate?: Maybe<Fastener_Aggregate_Order_By>;
  PreassembledFastenings_aggregate?: Maybe<PreassembledFastening_Aggregate_Order_By>;
};

/** primary key columns input for table: C_FastenerType */
export type C_FastenerType_Pk_Columns_Input = {
  FastenerTypeId: Scalars['Int'];
};

/** select columns of table "C_FastenerType" */
export enum C_FastenerType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FastenerTypeCode = 'FastenerTypeCode',
  /** column name */
  FastenerTypeId = 'FastenerTypeId'
}

/** input type for updating data in table "C_FastenerType" */
export type C_FastenerType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FastenerTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_FastenerType_Stddev_Fields = {
  __typename?: 'C_FastenerType_stddev_fields';
  FastenerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_FastenerType_Stddev_Pop_Fields = {
  __typename?: 'C_FastenerType_stddev_pop_fields';
  FastenerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_FastenerType_Stddev_Samp_Fields = {
  __typename?: 'C_FastenerType_stddev_samp_fields';
  FastenerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_FastenerType_Sum_Fields = {
  __typename?: 'C_FastenerType_sum_fields';
  FastenerTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_FastenerType" */
export enum C_FastenerType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FastenerTypeCode = 'FastenerTypeCode'
}

/** aggregate var_pop on columns */
export type C_FastenerType_Var_Pop_Fields = {
  __typename?: 'C_FastenerType_var_pop_fields';
  FastenerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_FastenerType_Var_Samp_Fields = {
  __typename?: 'C_FastenerType_var_samp_fields';
  FastenerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_FastenerType_Variance_Fields = {
  __typename?: 'C_FastenerType_variance_fields';
  FastenerTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethod = {
  __typename?: 'C_FasteningApplicationMethod';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  FastenerUsages: Array<FastenerUsage>;
  /** An aggregate relationship */
  FastenerUsages_aggregate: FastenerUsage_Aggregate;
  FasteningApplicationMethodCode: Scalars['CodeTableValue'];
  FasteningApplicationMethodId: Scalars['Int'];
  /** An array relationship */
  FasteningSystemBattenBarUsages: Array<FasteningSystemBattenBarUsage>;
  /** An aggregate relationship */
  FasteningSystemBattenBarUsages_aggregate: FasteningSystemBattenBarUsage_Aggregate;
  /** An array relationship */
  FasteningSystemStressPlateUsages: Array<FasteningSystemStressPlateUsage>;
  /** An aggregate relationship */
  FasteningSystemStressPlateUsages_aggregate: FasteningSystemStressPlateUsage_Aggregate;
  /** An array relationship */
  PreassembledFasteningUsages: Array<PreassembledFasteningUsage>;
  /** An aggregate relationship */
  PreassembledFasteningUsages_aggregate: PreassembledFasteningUsage_Aggregate;
};


/** columns and relationships of "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethodFastenerUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FastenerUsage_Order_By>>;
  where?: Maybe<FastenerUsage_Bool_Exp>;
};


/** columns and relationships of "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethodFastenerUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FastenerUsage_Order_By>>;
  where?: Maybe<FastenerUsage_Bool_Exp>;
};


/** columns and relationships of "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethodFasteningSystemBattenBarUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemBattenBarUsage_Order_By>>;
  where?: Maybe<FasteningSystemBattenBarUsage_Bool_Exp>;
};


/** columns and relationships of "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethodFasteningSystemBattenBarUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemBattenBarUsage_Order_By>>;
  where?: Maybe<FasteningSystemBattenBarUsage_Bool_Exp>;
};


/** columns and relationships of "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethodFasteningSystemStressPlateUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemStressPlateUsage_Order_By>>;
  where?: Maybe<FasteningSystemStressPlateUsage_Bool_Exp>;
};


/** columns and relationships of "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethodFasteningSystemStressPlateUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemStressPlateUsage_Order_By>>;
  where?: Maybe<FasteningSystemStressPlateUsage_Bool_Exp>;
};


/** columns and relationships of "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethodPreassembledFasteningUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFasteningUsage_Order_By>>;
  where?: Maybe<PreassembledFasteningUsage_Bool_Exp>;
};


/** columns and relationships of "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethodPreassembledFasteningUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFasteningUsage_Order_By>>;
  where?: Maybe<PreassembledFasteningUsage_Bool_Exp>;
};

/** aggregated selection of "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethod_Aggregate = {
  __typename?: 'C_FasteningApplicationMethod_aggregate';
  aggregate?: Maybe<C_FasteningApplicationMethod_Aggregate_Fields>;
  nodes: Array<C_FasteningApplicationMethod>;
};

/** aggregate fields of "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethod_Aggregate_Fields = {
  __typename?: 'C_FasteningApplicationMethod_aggregate_fields';
  avg?: Maybe<C_FasteningApplicationMethod_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_FasteningApplicationMethod_Max_Fields>;
  min?: Maybe<C_FasteningApplicationMethod_Min_Fields>;
  stddev?: Maybe<C_FasteningApplicationMethod_Stddev_Fields>;
  stddev_pop?: Maybe<C_FasteningApplicationMethod_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_FasteningApplicationMethod_Stddev_Samp_Fields>;
  sum?: Maybe<C_FasteningApplicationMethod_Sum_Fields>;
  var_pop?: Maybe<C_FasteningApplicationMethod_Var_Pop_Fields>;
  var_samp?: Maybe<C_FasteningApplicationMethod_Var_Samp_Fields>;
  variance?: Maybe<C_FasteningApplicationMethod_Variance_Fields>;
};


/** aggregate fields of "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethod_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_FasteningApplicationMethod_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_FasteningApplicationMethod_Avg_Fields = {
  __typename?: 'C_FasteningApplicationMethod_avg_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_FasteningApplicationMethod". All fields are combined with a logical 'AND'. */
export type C_FasteningApplicationMethod_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  FastenerUsages?: Maybe<FastenerUsage_Bool_Exp>;
  FasteningApplicationMethodCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  FasteningApplicationMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  FasteningSystemBattenBarUsages?: Maybe<FasteningSystemBattenBarUsage_Bool_Exp>;
  FasteningSystemStressPlateUsages?: Maybe<FasteningSystemStressPlateUsage_Bool_Exp>;
  PreassembledFasteningUsages?: Maybe<PreassembledFasteningUsage_Bool_Exp>;
  _and?: Maybe<Array<C_FasteningApplicationMethod_Bool_Exp>>;
  _not?: Maybe<C_FasteningApplicationMethod_Bool_Exp>;
  _or?: Maybe<Array<C_FasteningApplicationMethod_Bool_Exp>>;
};

/** upsert condition type for table "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethod_If_Matched = {
  match_columns?: Array<C_FasteningApplicationMethod_Insert_Match_Column>;
  update_columns?: Array<C_FasteningApplicationMethod_Update_Column>;
  where?: Maybe<C_FasteningApplicationMethod_Bool_Exp>;
};

/** input type for inserting data into table "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethod_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FasteningApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_FasteningApplicationMethod" */
export enum C_FasteningApplicationMethod_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FasteningApplicationMethodCode = 'FasteningApplicationMethodCode',
  /** column name */
  FasteningApplicationMethodId = 'FasteningApplicationMethodId'
}

/** aggregate max on columns */
export type C_FasteningApplicationMethod_Max_Fields = {
  __typename?: 'C_FasteningApplicationMethod_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FasteningApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_FasteningApplicationMethod_Min_Fields = {
  __typename?: 'C_FasteningApplicationMethod_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FasteningApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethod_Mutation_Response = {
  __typename?: 'C_FasteningApplicationMethod_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_FasteningApplicationMethod>;
};

/** Ordering options when selecting data from "C_FasteningApplicationMethod". */
export type C_FasteningApplicationMethod_Order_By = {
  Dsc?: Maybe<Order_By>;
  FastenerUsages_aggregate?: Maybe<FastenerUsage_Aggregate_Order_By>;
  FasteningApplicationMethodCode?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  FasteningSystemBattenBarUsages_aggregate?: Maybe<FasteningSystemBattenBarUsage_Aggregate_Order_By>;
  FasteningSystemStressPlateUsages_aggregate?: Maybe<FasteningSystemStressPlateUsage_Aggregate_Order_By>;
  PreassembledFasteningUsages_aggregate?: Maybe<PreassembledFasteningUsage_Aggregate_Order_By>;
};

/** primary key columns input for table: C_FasteningApplicationMethod */
export type C_FasteningApplicationMethod_Pk_Columns_Input = {
  FasteningApplicationMethodId: Scalars['Int'];
};

/** select columns of table "C_FasteningApplicationMethod" */
export enum C_FasteningApplicationMethod_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FasteningApplicationMethodCode = 'FasteningApplicationMethodCode',
  /** column name */
  FasteningApplicationMethodId = 'FasteningApplicationMethodId'
}

/** input type for updating data in table "C_FasteningApplicationMethod" */
export type C_FasteningApplicationMethod_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FasteningApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_FasteningApplicationMethod_Stddev_Fields = {
  __typename?: 'C_FasteningApplicationMethod_stddev_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_FasteningApplicationMethod_Stddev_Pop_Fields = {
  __typename?: 'C_FasteningApplicationMethod_stddev_pop_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_FasteningApplicationMethod_Stddev_Samp_Fields = {
  __typename?: 'C_FasteningApplicationMethod_stddev_samp_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_FasteningApplicationMethod_Sum_Fields = {
  __typename?: 'C_FasteningApplicationMethod_sum_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_FasteningApplicationMethod" */
export enum C_FasteningApplicationMethod_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FasteningApplicationMethodCode = 'FasteningApplicationMethodCode'
}

/** aggregate var_pop on columns */
export type C_FasteningApplicationMethod_Var_Pop_Fields = {
  __typename?: 'C_FasteningApplicationMethod_var_pop_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_FasteningApplicationMethod_Var_Samp_Fields = {
  __typename?: 'C_FasteningApplicationMethod_var_samp_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_FasteningApplicationMethod_Variance_Fields = {
  __typename?: 'C_FasteningApplicationMethod_variance_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_FlashingMaterial" */
export type C_FlashingMaterial = {
  __typename?: 'C_FlashingMaterial';
  /** An array relationship */
  Copings: Array<Coping>;
  /** An aggregate relationship */
  Copings_aggregate: Coping_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  ExpansionJoints: Array<ExpansionJoint>;
  /** An aggregate relationship */
  ExpansionJoints_aggregate: ExpansionJoint_Aggregate;
  /** An array relationship */
  Fascia: Array<Fascia>;
  /** An aggregate relationship */
  Fascia_aggregate: Fascia_Aggregate;
  FlashingMaterialCode: Scalars['CodeTableValue'];
  FlashingMaterialId: Scalars['Int'];
  /** An array relationship */
  Reglets: Array<Reglet>;
  /** An aggregate relationship */
  Reglets_aggregate: Reglet_Aggregate;
};


/** columns and relationships of "C_FlashingMaterial" */
export type C_FlashingMaterialCopingsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coping_Order_By>>;
  where?: Maybe<Coping_Bool_Exp>;
};


/** columns and relationships of "C_FlashingMaterial" */
export type C_FlashingMaterialCopings_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coping_Order_By>>;
  where?: Maybe<Coping_Bool_Exp>;
};


/** columns and relationships of "C_FlashingMaterial" */
export type C_FlashingMaterialExpansionJointsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExpansionJoint_Order_By>>;
  where?: Maybe<ExpansionJoint_Bool_Exp>;
};


/** columns and relationships of "C_FlashingMaterial" */
export type C_FlashingMaterialExpansionJoints_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExpansionJoint_Order_By>>;
  where?: Maybe<ExpansionJoint_Bool_Exp>;
};


/** columns and relationships of "C_FlashingMaterial" */
export type C_FlashingMaterialFasciaArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fascia_Order_By>>;
  where?: Maybe<Fascia_Bool_Exp>;
};


/** columns and relationships of "C_FlashingMaterial" */
export type C_FlashingMaterialFascia_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fascia_Order_By>>;
  where?: Maybe<Fascia_Bool_Exp>;
};


/** columns and relationships of "C_FlashingMaterial" */
export type C_FlashingMaterialRegletsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reglet_Order_By>>;
  where?: Maybe<Reglet_Bool_Exp>;
};


/** columns and relationships of "C_FlashingMaterial" */
export type C_FlashingMaterialReglets_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reglet_Order_By>>;
  where?: Maybe<Reglet_Bool_Exp>;
};

/** aggregated selection of "C_FlashingMaterial" */
export type C_FlashingMaterial_Aggregate = {
  __typename?: 'C_FlashingMaterial_aggregate';
  aggregate?: Maybe<C_FlashingMaterial_Aggregate_Fields>;
  nodes: Array<C_FlashingMaterial>;
};

/** aggregate fields of "C_FlashingMaterial" */
export type C_FlashingMaterial_Aggregate_Fields = {
  __typename?: 'C_FlashingMaterial_aggregate_fields';
  avg?: Maybe<C_FlashingMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_FlashingMaterial_Max_Fields>;
  min?: Maybe<C_FlashingMaterial_Min_Fields>;
  stddev?: Maybe<C_FlashingMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_FlashingMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_FlashingMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_FlashingMaterial_Sum_Fields>;
  var_pop?: Maybe<C_FlashingMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_FlashingMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_FlashingMaterial_Variance_Fields>;
};


/** aggregate fields of "C_FlashingMaterial" */
export type C_FlashingMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_FlashingMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_FlashingMaterial_Avg_Fields = {
  __typename?: 'C_FlashingMaterial_avg_fields';
  FlashingMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_FlashingMaterial". All fields are combined with a logical 'AND'. */
export type C_FlashingMaterial_Bool_Exp = {
  Copings?: Maybe<Coping_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  ExpansionJoints?: Maybe<ExpansionJoint_Bool_Exp>;
  Fascia?: Maybe<Fascia_Bool_Exp>;
  FlashingMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  FlashingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  Reglets?: Maybe<Reglet_Bool_Exp>;
  _and?: Maybe<Array<C_FlashingMaterial_Bool_Exp>>;
  _not?: Maybe<C_FlashingMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_FlashingMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_FlashingMaterial" */
export type C_FlashingMaterial_If_Matched = {
  match_columns?: Array<C_FlashingMaterial_Insert_Match_Column>;
  update_columns?: Array<C_FlashingMaterial_Update_Column>;
  where?: Maybe<C_FlashingMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_FlashingMaterial" */
export type C_FlashingMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FlashingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_FlashingMaterial" */
export enum C_FlashingMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FlashingMaterialCode = 'FlashingMaterialCode',
  /** column name */
  FlashingMaterialId = 'FlashingMaterialId'
}

/** aggregate max on columns */
export type C_FlashingMaterial_Max_Fields = {
  __typename?: 'C_FlashingMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FlashingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_FlashingMaterial_Min_Fields = {
  __typename?: 'C_FlashingMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FlashingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_FlashingMaterial" */
export type C_FlashingMaterial_Mutation_Response = {
  __typename?: 'C_FlashingMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_FlashingMaterial>;
};

/** Ordering options when selecting data from "C_FlashingMaterial". */
export type C_FlashingMaterial_Order_By = {
  Copings_aggregate?: Maybe<Coping_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  ExpansionJoints_aggregate?: Maybe<ExpansionJoint_Aggregate_Order_By>;
  Fascia_aggregate?: Maybe<Fascia_Aggregate_Order_By>;
  FlashingMaterialCode?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  Reglets_aggregate?: Maybe<Reglet_Aggregate_Order_By>;
};

/** primary key columns input for table: C_FlashingMaterial */
export type C_FlashingMaterial_Pk_Columns_Input = {
  FlashingMaterialId: Scalars['Int'];
};

/** select columns of table "C_FlashingMaterial" */
export enum C_FlashingMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FlashingMaterialCode = 'FlashingMaterialCode',
  /** column name */
  FlashingMaterialId = 'FlashingMaterialId'
}

/** input type for updating data in table "C_FlashingMaterial" */
export type C_FlashingMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FlashingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_FlashingMaterial_Stddev_Fields = {
  __typename?: 'C_FlashingMaterial_stddev_fields';
  FlashingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_FlashingMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_FlashingMaterial_stddev_pop_fields';
  FlashingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_FlashingMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_FlashingMaterial_stddev_samp_fields';
  FlashingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_FlashingMaterial_Sum_Fields = {
  __typename?: 'C_FlashingMaterial_sum_fields';
  FlashingMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_FlashingMaterial" */
export enum C_FlashingMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FlashingMaterialCode = 'FlashingMaterialCode'
}

/** aggregate var_pop on columns */
export type C_FlashingMaterial_Var_Pop_Fields = {
  __typename?: 'C_FlashingMaterial_var_pop_fields';
  FlashingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_FlashingMaterial_Var_Samp_Fields = {
  __typename?: 'C_FlashingMaterial_var_samp_fields';
  FlashingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_FlashingMaterial_Variance_Fields = {
  __typename?: 'C_FlashingMaterial_variance_fields';
  FlashingMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_FlashingType" */
export type C_FlashingType = {
  __typename?: 'C_FlashingType';
  Dsc: Scalars['CodeTableDescription'];
  FlashingTypeCode: Scalars['CodeTableValue'];
  FlashingTypeId: Scalars['Int'];
};

/** aggregated selection of "C_FlashingType" */
export type C_FlashingType_Aggregate = {
  __typename?: 'C_FlashingType_aggregate';
  aggregate?: Maybe<C_FlashingType_Aggregate_Fields>;
  nodes: Array<C_FlashingType>;
};

/** aggregate fields of "C_FlashingType" */
export type C_FlashingType_Aggregate_Fields = {
  __typename?: 'C_FlashingType_aggregate_fields';
  avg?: Maybe<C_FlashingType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_FlashingType_Max_Fields>;
  min?: Maybe<C_FlashingType_Min_Fields>;
  stddev?: Maybe<C_FlashingType_Stddev_Fields>;
  stddev_pop?: Maybe<C_FlashingType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_FlashingType_Stddev_Samp_Fields>;
  sum?: Maybe<C_FlashingType_Sum_Fields>;
  var_pop?: Maybe<C_FlashingType_Var_Pop_Fields>;
  var_samp?: Maybe<C_FlashingType_Var_Samp_Fields>;
  variance?: Maybe<C_FlashingType_Variance_Fields>;
};


/** aggregate fields of "C_FlashingType" */
export type C_FlashingType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_FlashingType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_FlashingType_Avg_Fields = {
  __typename?: 'C_FlashingType_avg_fields';
  FlashingTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_FlashingType". All fields are combined with a logical 'AND'. */
export type C_FlashingType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  FlashingTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  FlashingTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_FlashingType_Bool_Exp>>;
  _not?: Maybe<C_FlashingType_Bool_Exp>;
  _or?: Maybe<Array<C_FlashingType_Bool_Exp>>;
};

/** upsert condition type for table "C_FlashingType" */
export type C_FlashingType_If_Matched = {
  match_columns?: Array<C_FlashingType_Insert_Match_Column>;
  update_columns?: Array<C_FlashingType_Update_Column>;
  where?: Maybe<C_FlashingType_Bool_Exp>;
};

/** input type for inserting data into table "C_FlashingType" */
export type C_FlashingType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FlashingTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_FlashingType" */
export enum C_FlashingType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FlashingTypeCode = 'FlashingTypeCode',
  /** column name */
  FlashingTypeId = 'FlashingTypeId'
}

/** aggregate max on columns */
export type C_FlashingType_Max_Fields = {
  __typename?: 'C_FlashingType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FlashingTypeCode?: Maybe<Scalars['CodeTableValue']>;
  FlashingTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_FlashingType_Min_Fields = {
  __typename?: 'C_FlashingType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FlashingTypeCode?: Maybe<Scalars['CodeTableValue']>;
  FlashingTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_FlashingType" */
export type C_FlashingType_Mutation_Response = {
  __typename?: 'C_FlashingType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_FlashingType>;
};

/** Ordering options when selecting data from "C_FlashingType". */
export type C_FlashingType_Order_By = {
  Dsc?: Maybe<Order_By>;
  FlashingTypeCode?: Maybe<Order_By>;
  FlashingTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_FlashingType */
export type C_FlashingType_Pk_Columns_Input = {
  FlashingTypeId: Scalars['Int'];
};

/** select columns of table "C_FlashingType" */
export enum C_FlashingType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FlashingTypeCode = 'FlashingTypeCode',
  /** column name */
  FlashingTypeId = 'FlashingTypeId'
}

/** input type for updating data in table "C_FlashingType" */
export type C_FlashingType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FlashingTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_FlashingType_Stddev_Fields = {
  __typename?: 'C_FlashingType_stddev_fields';
  FlashingTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_FlashingType_Stddev_Pop_Fields = {
  __typename?: 'C_FlashingType_stddev_pop_fields';
  FlashingTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_FlashingType_Stddev_Samp_Fields = {
  __typename?: 'C_FlashingType_stddev_samp_fields';
  FlashingTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_FlashingType_Sum_Fields = {
  __typename?: 'C_FlashingType_sum_fields';
  FlashingTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_FlashingType" */
export enum C_FlashingType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FlashingTypeCode = 'FlashingTypeCode'
}

/** aggregate var_pop on columns */
export type C_FlashingType_Var_Pop_Fields = {
  __typename?: 'C_FlashingType_var_pop_fields';
  FlashingTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_FlashingType_Var_Samp_Fields = {
  __typename?: 'C_FlashingType_var_samp_fields';
  FlashingTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_FlashingType_Variance_Fields = {
  __typename?: 'C_FlashingType_variance_fields';
  FlashingTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_FormDeckMaterial" */
export type C_FormDeckMaterial = {
  __typename?: 'C_FormDeckMaterial';
  Dsc: Scalars['CodeTableDescription'];
  FormDeckMaterialCode: Scalars['CodeTableValue'];
  FormDeckMaterialId: Scalars['Int'];
};

/** aggregated selection of "C_FormDeckMaterial" */
export type C_FormDeckMaterial_Aggregate = {
  __typename?: 'C_FormDeckMaterial_aggregate';
  aggregate?: Maybe<C_FormDeckMaterial_Aggregate_Fields>;
  nodes: Array<C_FormDeckMaterial>;
};

/** aggregate fields of "C_FormDeckMaterial" */
export type C_FormDeckMaterial_Aggregate_Fields = {
  __typename?: 'C_FormDeckMaterial_aggregate_fields';
  avg?: Maybe<C_FormDeckMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_FormDeckMaterial_Max_Fields>;
  min?: Maybe<C_FormDeckMaterial_Min_Fields>;
  stddev?: Maybe<C_FormDeckMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_FormDeckMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_FormDeckMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_FormDeckMaterial_Sum_Fields>;
  var_pop?: Maybe<C_FormDeckMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_FormDeckMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_FormDeckMaterial_Variance_Fields>;
};


/** aggregate fields of "C_FormDeckMaterial" */
export type C_FormDeckMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_FormDeckMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_FormDeckMaterial_Avg_Fields = {
  __typename?: 'C_FormDeckMaterial_avg_fields';
  FormDeckMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_FormDeckMaterial". All fields are combined with a logical 'AND'. */
export type C_FormDeckMaterial_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  FormDeckMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  FormDeckMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_FormDeckMaterial_Bool_Exp>>;
  _not?: Maybe<C_FormDeckMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_FormDeckMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_FormDeckMaterial" */
export type C_FormDeckMaterial_If_Matched = {
  match_columns?: Array<C_FormDeckMaterial_Insert_Match_Column>;
  update_columns?: Array<C_FormDeckMaterial_Update_Column>;
  where?: Maybe<C_FormDeckMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_FormDeckMaterial" */
export type C_FormDeckMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FormDeckMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_FormDeckMaterial" */
export enum C_FormDeckMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FormDeckMaterialCode = 'FormDeckMaterialCode',
  /** column name */
  FormDeckMaterialId = 'FormDeckMaterialId'
}

/** aggregate max on columns */
export type C_FormDeckMaterial_Max_Fields = {
  __typename?: 'C_FormDeckMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FormDeckMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  FormDeckMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_FormDeckMaterial_Min_Fields = {
  __typename?: 'C_FormDeckMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FormDeckMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  FormDeckMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_FormDeckMaterial" */
export type C_FormDeckMaterial_Mutation_Response = {
  __typename?: 'C_FormDeckMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_FormDeckMaterial>;
};

/** Ordering options when selecting data from "C_FormDeckMaterial". */
export type C_FormDeckMaterial_Order_By = {
  Dsc?: Maybe<Order_By>;
  FormDeckMaterialCode?: Maybe<Order_By>;
  FormDeckMaterialId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_FormDeckMaterial */
export type C_FormDeckMaterial_Pk_Columns_Input = {
  FormDeckMaterialId: Scalars['Int'];
};

/** select columns of table "C_FormDeckMaterial" */
export enum C_FormDeckMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FormDeckMaterialCode = 'FormDeckMaterialCode',
  /** column name */
  FormDeckMaterialId = 'FormDeckMaterialId'
}

/** input type for updating data in table "C_FormDeckMaterial" */
export type C_FormDeckMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  FormDeckMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_FormDeckMaterial_Stddev_Fields = {
  __typename?: 'C_FormDeckMaterial_stddev_fields';
  FormDeckMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_FormDeckMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_FormDeckMaterial_stddev_pop_fields';
  FormDeckMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_FormDeckMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_FormDeckMaterial_stddev_samp_fields';
  FormDeckMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_FormDeckMaterial_Sum_Fields = {
  __typename?: 'C_FormDeckMaterial_sum_fields';
  FormDeckMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_FormDeckMaterial" */
export enum C_FormDeckMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  FormDeckMaterialCode = 'FormDeckMaterialCode'
}

/** aggregate var_pop on columns */
export type C_FormDeckMaterial_Var_Pop_Fields = {
  __typename?: 'C_FormDeckMaterial_var_pop_fields';
  FormDeckMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_FormDeckMaterial_Var_Samp_Fields = {
  __typename?: 'C_FormDeckMaterial_var_samp_fields';
  FormDeckMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_FormDeckMaterial_Variance_Fields = {
  __typename?: 'C_FormDeckMaterial_variance_fields';
  FormDeckMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_GroundRoughnessRating" */
export type C_GroundRoughnessRating = {
  __typename?: 'C_GroundRoughnessRating';
  Dsc: Scalars['CodeTableDescription'];
  GroundRoughnessRatingCode: Scalars['CodeTableValue'];
  GroundRoughnessRatingId: Scalars['Int'];
  /** An array relationship */
  RoofAreas: Array<RoofArea>;
  /** An aggregate relationship */
  RoofAreas_aggregate: RoofArea_Aggregate;
};


/** columns and relationships of "C_GroundRoughnessRating" */
export type C_GroundRoughnessRatingRoofAreasArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};


/** columns and relationships of "C_GroundRoughnessRating" */
export type C_GroundRoughnessRatingRoofAreas_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};

/** aggregated selection of "C_GroundRoughnessRating" */
export type C_GroundRoughnessRating_Aggregate = {
  __typename?: 'C_GroundRoughnessRating_aggregate';
  aggregate?: Maybe<C_GroundRoughnessRating_Aggregate_Fields>;
  nodes: Array<C_GroundRoughnessRating>;
};

/** aggregate fields of "C_GroundRoughnessRating" */
export type C_GroundRoughnessRating_Aggregate_Fields = {
  __typename?: 'C_GroundRoughnessRating_aggregate_fields';
  avg?: Maybe<C_GroundRoughnessRating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_GroundRoughnessRating_Max_Fields>;
  min?: Maybe<C_GroundRoughnessRating_Min_Fields>;
  stddev?: Maybe<C_GroundRoughnessRating_Stddev_Fields>;
  stddev_pop?: Maybe<C_GroundRoughnessRating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_GroundRoughnessRating_Stddev_Samp_Fields>;
  sum?: Maybe<C_GroundRoughnessRating_Sum_Fields>;
  var_pop?: Maybe<C_GroundRoughnessRating_Var_Pop_Fields>;
  var_samp?: Maybe<C_GroundRoughnessRating_Var_Samp_Fields>;
  variance?: Maybe<C_GroundRoughnessRating_Variance_Fields>;
};


/** aggregate fields of "C_GroundRoughnessRating" */
export type C_GroundRoughnessRating_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_GroundRoughnessRating_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_GroundRoughnessRating_Avg_Fields = {
  __typename?: 'C_GroundRoughnessRating_avg_fields';
  GroundRoughnessRatingId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_GroundRoughnessRating". All fields are combined with a logical 'AND'. */
export type C_GroundRoughnessRating_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  GroundRoughnessRatingCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  GroundRoughnessRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  RoofAreas?: Maybe<RoofArea_Bool_Exp>;
  _and?: Maybe<Array<C_GroundRoughnessRating_Bool_Exp>>;
  _not?: Maybe<C_GroundRoughnessRating_Bool_Exp>;
  _or?: Maybe<Array<C_GroundRoughnessRating_Bool_Exp>>;
};

/** upsert condition type for table "C_GroundRoughnessRating" */
export type C_GroundRoughnessRating_If_Matched = {
  match_columns?: Array<C_GroundRoughnessRating_Insert_Match_Column>;
  update_columns?: Array<C_GroundRoughnessRating_Update_Column>;
  where?: Maybe<C_GroundRoughnessRating_Bool_Exp>;
};

/** input type for inserting data into table "C_GroundRoughnessRating" */
export type C_GroundRoughnessRating_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  GroundRoughnessRatingCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_GroundRoughnessRating" */
export enum C_GroundRoughnessRating_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  GroundRoughnessRatingCode = 'GroundRoughnessRatingCode',
  /** column name */
  GroundRoughnessRatingId = 'GroundRoughnessRatingId'
}

/** aggregate max on columns */
export type C_GroundRoughnessRating_Max_Fields = {
  __typename?: 'C_GroundRoughnessRating_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  GroundRoughnessRatingCode?: Maybe<Scalars['CodeTableValue']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_GroundRoughnessRating_Min_Fields = {
  __typename?: 'C_GroundRoughnessRating_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  GroundRoughnessRatingCode?: Maybe<Scalars['CodeTableValue']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_GroundRoughnessRating" */
export type C_GroundRoughnessRating_Mutation_Response = {
  __typename?: 'C_GroundRoughnessRating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_GroundRoughnessRating>;
};

/** Ordering options when selecting data from "C_GroundRoughnessRating". */
export type C_GroundRoughnessRating_Order_By = {
  Dsc?: Maybe<Order_By>;
  GroundRoughnessRatingCode?: Maybe<Order_By>;
  GroundRoughnessRatingId?: Maybe<Order_By>;
  RoofAreas_aggregate?: Maybe<RoofArea_Aggregate_Order_By>;
};

/** primary key columns input for table: C_GroundRoughnessRating */
export type C_GroundRoughnessRating_Pk_Columns_Input = {
  GroundRoughnessRatingId: Scalars['Int'];
};

/** select columns of table "C_GroundRoughnessRating" */
export enum C_GroundRoughnessRating_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  GroundRoughnessRatingCode = 'GroundRoughnessRatingCode',
  /** column name */
  GroundRoughnessRatingId = 'GroundRoughnessRatingId'
}

/** input type for updating data in table "C_GroundRoughnessRating" */
export type C_GroundRoughnessRating_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  GroundRoughnessRatingCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_GroundRoughnessRating_Stddev_Fields = {
  __typename?: 'C_GroundRoughnessRating_stddev_fields';
  GroundRoughnessRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_GroundRoughnessRating_Stddev_Pop_Fields = {
  __typename?: 'C_GroundRoughnessRating_stddev_pop_fields';
  GroundRoughnessRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_GroundRoughnessRating_Stddev_Samp_Fields = {
  __typename?: 'C_GroundRoughnessRating_stddev_samp_fields';
  GroundRoughnessRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_GroundRoughnessRating_Sum_Fields = {
  __typename?: 'C_GroundRoughnessRating_sum_fields';
  GroundRoughnessRatingId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_GroundRoughnessRating" */
export enum C_GroundRoughnessRating_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  GroundRoughnessRatingCode = 'GroundRoughnessRatingCode'
}

/** aggregate var_pop on columns */
export type C_GroundRoughnessRating_Var_Pop_Fields = {
  __typename?: 'C_GroundRoughnessRating_var_pop_fields';
  GroundRoughnessRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_GroundRoughnessRating_Var_Samp_Fields = {
  __typename?: 'C_GroundRoughnessRating_var_samp_fields';
  GroundRoughnessRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_GroundRoughnessRating_Variance_Fields = {
  __typename?: 'C_GroundRoughnessRating_variance_fields';
  GroundRoughnessRatingId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_GutterMaterial" */
export type C_GutterMaterial = {
  __typename?: 'C_GutterMaterial';
  Dsc: Scalars['CodeTableDescription'];
  GutterMaterialCode: Scalars['CodeTableValue'];
  GutterMaterialId: Scalars['Int'];
  /** An array relationship */
  Gutters: Array<Gutter>;
  /** An aggregate relationship */
  Gutters_aggregate: Gutter_Aggregate;
};


/** columns and relationships of "C_GutterMaterial" */
export type C_GutterMaterialGuttersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gutter_Order_By>>;
  where?: Maybe<Gutter_Bool_Exp>;
};


/** columns and relationships of "C_GutterMaterial" */
export type C_GutterMaterialGutters_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gutter_Order_By>>;
  where?: Maybe<Gutter_Bool_Exp>;
};

/** aggregated selection of "C_GutterMaterial" */
export type C_GutterMaterial_Aggregate = {
  __typename?: 'C_GutterMaterial_aggregate';
  aggregate?: Maybe<C_GutterMaterial_Aggregate_Fields>;
  nodes: Array<C_GutterMaterial>;
};

/** aggregate fields of "C_GutterMaterial" */
export type C_GutterMaterial_Aggregate_Fields = {
  __typename?: 'C_GutterMaterial_aggregate_fields';
  avg?: Maybe<C_GutterMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_GutterMaterial_Max_Fields>;
  min?: Maybe<C_GutterMaterial_Min_Fields>;
  stddev?: Maybe<C_GutterMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_GutterMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_GutterMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_GutterMaterial_Sum_Fields>;
  var_pop?: Maybe<C_GutterMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_GutterMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_GutterMaterial_Variance_Fields>;
};


/** aggregate fields of "C_GutterMaterial" */
export type C_GutterMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_GutterMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_GutterMaterial_Avg_Fields = {
  __typename?: 'C_GutterMaterial_avg_fields';
  GutterMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_GutterMaterial". All fields are combined with a logical 'AND'. */
export type C_GutterMaterial_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  GutterMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  GutterMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  Gutters?: Maybe<Gutter_Bool_Exp>;
  _and?: Maybe<Array<C_GutterMaterial_Bool_Exp>>;
  _not?: Maybe<C_GutterMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_GutterMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_GutterMaterial" */
export type C_GutterMaterial_If_Matched = {
  match_columns?: Array<C_GutterMaterial_Insert_Match_Column>;
  update_columns?: Array<C_GutterMaterial_Update_Column>;
  where?: Maybe<C_GutterMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_GutterMaterial" */
export type C_GutterMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  GutterMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_GutterMaterial" */
export enum C_GutterMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  GutterMaterialCode = 'GutterMaterialCode',
  /** column name */
  GutterMaterialId = 'GutterMaterialId'
}

/** aggregate max on columns */
export type C_GutterMaterial_Max_Fields = {
  __typename?: 'C_GutterMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  GutterMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  GutterMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_GutterMaterial_Min_Fields = {
  __typename?: 'C_GutterMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  GutterMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  GutterMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_GutterMaterial" */
export type C_GutterMaterial_Mutation_Response = {
  __typename?: 'C_GutterMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_GutterMaterial>;
};

/** Ordering options when selecting data from "C_GutterMaterial". */
export type C_GutterMaterial_Order_By = {
  Dsc?: Maybe<Order_By>;
  GutterMaterialCode?: Maybe<Order_By>;
  GutterMaterialId?: Maybe<Order_By>;
  Gutters_aggregate?: Maybe<Gutter_Aggregate_Order_By>;
};

/** primary key columns input for table: C_GutterMaterial */
export type C_GutterMaterial_Pk_Columns_Input = {
  GutterMaterialId: Scalars['Int'];
};

/** select columns of table "C_GutterMaterial" */
export enum C_GutterMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  GutterMaterialCode = 'GutterMaterialCode',
  /** column name */
  GutterMaterialId = 'GutterMaterialId'
}

/** input type for updating data in table "C_GutterMaterial" */
export type C_GutterMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  GutterMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_GutterMaterial_Stddev_Fields = {
  __typename?: 'C_GutterMaterial_stddev_fields';
  GutterMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_GutterMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_GutterMaterial_stddev_pop_fields';
  GutterMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_GutterMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_GutterMaterial_stddev_samp_fields';
  GutterMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_GutterMaterial_Sum_Fields = {
  __typename?: 'C_GutterMaterial_sum_fields';
  GutterMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_GutterMaterial" */
export enum C_GutterMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  GutterMaterialCode = 'GutterMaterialCode'
}

/** aggregate var_pop on columns */
export type C_GutterMaterial_Var_Pop_Fields = {
  __typename?: 'C_GutterMaterial_var_pop_fields';
  GutterMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_GutterMaterial_Var_Samp_Fields = {
  __typename?: 'C_GutterMaterial_var_samp_fields';
  GutterMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_GutterMaterial_Variance_Fields = {
  __typename?: 'C_GutterMaterial_variance_fields';
  GutterMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_HailRating" */
export type C_HailRating = {
  __typename?: 'C_HailRating';
  Dsc: Scalars['CodeTableDescription'];
  HailRatingCode: Scalars['CodeTableValue'];
  HailRatingId: Scalars['Int'];
  /** An array relationship */
  RoofAreas: Array<RoofArea>;
  /** An aggregate relationship */
  RoofAreas_aggregate: RoofArea_Aggregate;
  /** An array relationship */
  RoofAssemblies: Array<RoofAssembly>;
  /** An aggregate relationship */
  RoofAssemblies_aggregate: RoofAssembly_Aggregate;
};


/** columns and relationships of "C_HailRating" */
export type C_HailRatingRoofAreasArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};


/** columns and relationships of "C_HailRating" */
export type C_HailRatingRoofAreas_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};


/** columns and relationships of "C_HailRating" */
export type C_HailRatingRoofAssembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "C_HailRating" */
export type C_HailRatingRoofAssemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};

/** aggregated selection of "C_HailRating" */
export type C_HailRating_Aggregate = {
  __typename?: 'C_HailRating_aggregate';
  aggregate?: Maybe<C_HailRating_Aggregate_Fields>;
  nodes: Array<C_HailRating>;
};

/** aggregate fields of "C_HailRating" */
export type C_HailRating_Aggregate_Fields = {
  __typename?: 'C_HailRating_aggregate_fields';
  avg?: Maybe<C_HailRating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_HailRating_Max_Fields>;
  min?: Maybe<C_HailRating_Min_Fields>;
  stddev?: Maybe<C_HailRating_Stddev_Fields>;
  stddev_pop?: Maybe<C_HailRating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_HailRating_Stddev_Samp_Fields>;
  sum?: Maybe<C_HailRating_Sum_Fields>;
  var_pop?: Maybe<C_HailRating_Var_Pop_Fields>;
  var_samp?: Maybe<C_HailRating_Var_Samp_Fields>;
  variance?: Maybe<C_HailRating_Variance_Fields>;
};


/** aggregate fields of "C_HailRating" */
export type C_HailRating_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_HailRating_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_HailRating_Avg_Fields = {
  __typename?: 'C_HailRating_avg_fields';
  HailRatingId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_HailRating". All fields are combined with a logical 'AND'. */
export type C_HailRating_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  HailRatingCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  HailRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  RoofAreas?: Maybe<RoofArea_Bool_Exp>;
  RoofAssemblies?: Maybe<RoofAssembly_Bool_Exp>;
  _and?: Maybe<Array<C_HailRating_Bool_Exp>>;
  _not?: Maybe<C_HailRating_Bool_Exp>;
  _or?: Maybe<Array<C_HailRating_Bool_Exp>>;
};

/** upsert condition type for table "C_HailRating" */
export type C_HailRating_If_Matched = {
  match_columns?: Array<C_HailRating_Insert_Match_Column>;
  update_columns?: Array<C_HailRating_Update_Column>;
  where?: Maybe<C_HailRating_Bool_Exp>;
};

/** input type for inserting data into table "C_HailRating" */
export type C_HailRating_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  HailRatingCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_HailRating" */
export enum C_HailRating_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  HailRatingCode = 'HailRatingCode',
  /** column name */
  HailRatingId = 'HailRatingId'
}

/** aggregate max on columns */
export type C_HailRating_Max_Fields = {
  __typename?: 'C_HailRating_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  HailRatingCode?: Maybe<Scalars['CodeTableValue']>;
  HailRatingId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_HailRating_Min_Fields = {
  __typename?: 'C_HailRating_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  HailRatingCode?: Maybe<Scalars['CodeTableValue']>;
  HailRatingId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_HailRating" */
export type C_HailRating_Mutation_Response = {
  __typename?: 'C_HailRating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_HailRating>;
};

/** Ordering options when selecting data from "C_HailRating". */
export type C_HailRating_Order_By = {
  Dsc?: Maybe<Order_By>;
  HailRatingCode?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  RoofAreas_aggregate?: Maybe<RoofArea_Aggregate_Order_By>;
  RoofAssemblies_aggregate?: Maybe<RoofAssembly_Aggregate_Order_By>;
};

/** primary key columns input for table: C_HailRating */
export type C_HailRating_Pk_Columns_Input = {
  HailRatingId: Scalars['Int'];
};

/** select columns of table "C_HailRating" */
export enum C_HailRating_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  HailRatingCode = 'HailRatingCode',
  /** column name */
  HailRatingId = 'HailRatingId'
}

/** input type for updating data in table "C_HailRating" */
export type C_HailRating_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  HailRatingCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_HailRating_Stddev_Fields = {
  __typename?: 'C_HailRating_stddev_fields';
  HailRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_HailRating_Stddev_Pop_Fields = {
  __typename?: 'C_HailRating_stddev_pop_fields';
  HailRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_HailRating_Stddev_Samp_Fields = {
  __typename?: 'C_HailRating_stddev_samp_fields';
  HailRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_HailRating_Sum_Fields = {
  __typename?: 'C_HailRating_sum_fields';
  HailRatingId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_HailRating" */
export enum C_HailRating_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  HailRatingCode = 'HailRatingCode'
}

/** aggregate var_pop on columns */
export type C_HailRating_Var_Pop_Fields = {
  __typename?: 'C_HailRating_var_pop_fields';
  HailRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_HailRating_Var_Samp_Fields = {
  __typename?: 'C_HailRating_var_samp_fields';
  HailRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_HailRating_Variance_Fields = {
  __typename?: 'C_HailRating_variance_fields';
  HailRatingId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_IndustryType" */
export type C_IndustryType = {
  __typename?: 'C_IndustryType';
  Dsc: Scalars['CodeTableDescription'];
  IndustryTypeCode: Scalars['CodeTableValue'];
  IndustryTypeId: Scalars['Int'];
};

/** aggregated selection of "C_IndustryType" */
export type C_IndustryType_Aggregate = {
  __typename?: 'C_IndustryType_aggregate';
  aggregate?: Maybe<C_IndustryType_Aggregate_Fields>;
  nodes: Array<C_IndustryType>;
};

/** aggregate fields of "C_IndustryType" */
export type C_IndustryType_Aggregate_Fields = {
  __typename?: 'C_IndustryType_aggregate_fields';
  avg?: Maybe<C_IndustryType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_IndustryType_Max_Fields>;
  min?: Maybe<C_IndustryType_Min_Fields>;
  stddev?: Maybe<C_IndustryType_Stddev_Fields>;
  stddev_pop?: Maybe<C_IndustryType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_IndustryType_Stddev_Samp_Fields>;
  sum?: Maybe<C_IndustryType_Sum_Fields>;
  var_pop?: Maybe<C_IndustryType_Var_Pop_Fields>;
  var_samp?: Maybe<C_IndustryType_Var_Samp_Fields>;
  variance?: Maybe<C_IndustryType_Variance_Fields>;
};


/** aggregate fields of "C_IndustryType" */
export type C_IndustryType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_IndustryType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_IndustryType_Avg_Fields = {
  __typename?: 'C_IndustryType_avg_fields';
  IndustryTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_IndustryType". All fields are combined with a logical 'AND'. */
export type C_IndustryType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  IndustryTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  IndustryTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_IndustryType_Bool_Exp>>;
  _not?: Maybe<C_IndustryType_Bool_Exp>;
  _or?: Maybe<Array<C_IndustryType_Bool_Exp>>;
};

/** upsert condition type for table "C_IndustryType" */
export type C_IndustryType_If_Matched = {
  match_columns?: Array<C_IndustryType_Insert_Match_Column>;
  update_columns?: Array<C_IndustryType_Update_Column>;
  where?: Maybe<C_IndustryType_Bool_Exp>;
};

/** input type for inserting data into table "C_IndustryType" */
export type C_IndustryType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  IndustryTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_IndustryType" */
export enum C_IndustryType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  IndustryTypeCode = 'IndustryTypeCode',
  /** column name */
  IndustryTypeId = 'IndustryTypeId'
}

/** aggregate max on columns */
export type C_IndustryType_Max_Fields = {
  __typename?: 'C_IndustryType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  IndustryTypeCode?: Maybe<Scalars['CodeTableValue']>;
  IndustryTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_IndustryType_Min_Fields = {
  __typename?: 'C_IndustryType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  IndustryTypeCode?: Maybe<Scalars['CodeTableValue']>;
  IndustryTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_IndustryType" */
export type C_IndustryType_Mutation_Response = {
  __typename?: 'C_IndustryType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_IndustryType>;
};

/** Ordering options when selecting data from "C_IndustryType". */
export type C_IndustryType_Order_By = {
  Dsc?: Maybe<Order_By>;
  IndustryTypeCode?: Maybe<Order_By>;
  IndustryTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_IndustryType */
export type C_IndustryType_Pk_Columns_Input = {
  IndustryTypeId: Scalars['Int'];
};

/** select columns of table "C_IndustryType" */
export enum C_IndustryType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  IndustryTypeCode = 'IndustryTypeCode',
  /** column name */
  IndustryTypeId = 'IndustryTypeId'
}

/** input type for updating data in table "C_IndustryType" */
export type C_IndustryType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  IndustryTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_IndustryType_Stddev_Fields = {
  __typename?: 'C_IndustryType_stddev_fields';
  IndustryTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_IndustryType_Stddev_Pop_Fields = {
  __typename?: 'C_IndustryType_stddev_pop_fields';
  IndustryTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_IndustryType_Stddev_Samp_Fields = {
  __typename?: 'C_IndustryType_stddev_samp_fields';
  IndustryTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_IndustryType_Sum_Fields = {
  __typename?: 'C_IndustryType_sum_fields';
  IndustryTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_IndustryType" */
export enum C_IndustryType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  IndustryTypeCode = 'IndustryTypeCode'
}

/** aggregate var_pop on columns */
export type C_IndustryType_Var_Pop_Fields = {
  __typename?: 'C_IndustryType_var_pop_fields';
  IndustryTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_IndustryType_Var_Samp_Fields = {
  __typename?: 'C_IndustryType_var_samp_fields';
  IndustryTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_IndustryType_Variance_Fields = {
  __typename?: 'C_IndustryType_variance_fields';
  IndustryTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_InsulationCore" */
export type C_InsulationCore = {
  __typename?: 'C_InsulationCore';
  /** An array relationship */
  CompositePanelCovers: Array<CompositePanelCover>;
  /** An aggregate relationship */
  CompositePanelCovers_aggregate: CompositePanelCover_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  InsulationCoreCode: Scalars['CodeTableValue'];
  InsulationCoreId: Scalars['Int'];
};


/** columns and relationships of "C_InsulationCore" */
export type C_InsulationCoreCompositePanelCoversArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompositePanelCover_Order_By>>;
  where?: Maybe<CompositePanelCover_Bool_Exp>;
};


/** columns and relationships of "C_InsulationCore" */
export type C_InsulationCoreCompositePanelCovers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompositePanelCover_Order_By>>;
  where?: Maybe<CompositePanelCover_Bool_Exp>;
};

/** aggregated selection of "C_InsulationCore" */
export type C_InsulationCore_Aggregate = {
  __typename?: 'C_InsulationCore_aggregate';
  aggregate?: Maybe<C_InsulationCore_Aggregate_Fields>;
  nodes: Array<C_InsulationCore>;
};

/** aggregate fields of "C_InsulationCore" */
export type C_InsulationCore_Aggregate_Fields = {
  __typename?: 'C_InsulationCore_aggregate_fields';
  avg?: Maybe<C_InsulationCore_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_InsulationCore_Max_Fields>;
  min?: Maybe<C_InsulationCore_Min_Fields>;
  stddev?: Maybe<C_InsulationCore_Stddev_Fields>;
  stddev_pop?: Maybe<C_InsulationCore_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_InsulationCore_Stddev_Samp_Fields>;
  sum?: Maybe<C_InsulationCore_Sum_Fields>;
  var_pop?: Maybe<C_InsulationCore_Var_Pop_Fields>;
  var_samp?: Maybe<C_InsulationCore_Var_Samp_Fields>;
  variance?: Maybe<C_InsulationCore_Variance_Fields>;
};


/** aggregate fields of "C_InsulationCore" */
export type C_InsulationCore_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_InsulationCore_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_InsulationCore_Avg_Fields = {
  __typename?: 'C_InsulationCore_avg_fields';
  InsulationCoreId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_InsulationCore". All fields are combined with a logical 'AND'. */
export type C_InsulationCore_Bool_Exp = {
  CompositePanelCovers?: Maybe<CompositePanelCover_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  InsulationCoreCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  InsulationCoreId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_InsulationCore_Bool_Exp>>;
  _not?: Maybe<C_InsulationCore_Bool_Exp>;
  _or?: Maybe<Array<C_InsulationCore_Bool_Exp>>;
};

/** upsert condition type for table "C_InsulationCore" */
export type C_InsulationCore_If_Matched = {
  match_columns?: Array<C_InsulationCore_Insert_Match_Column>;
  update_columns?: Array<C_InsulationCore_Update_Column>;
  where?: Maybe<C_InsulationCore_Bool_Exp>;
};

/** input type for inserting data into table "C_InsulationCore" */
export type C_InsulationCore_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  InsulationCoreCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_InsulationCore" */
export enum C_InsulationCore_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  InsulationCoreCode = 'InsulationCoreCode',
  /** column name */
  InsulationCoreId = 'InsulationCoreId'
}

/** aggregate max on columns */
export type C_InsulationCore_Max_Fields = {
  __typename?: 'C_InsulationCore_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  InsulationCoreCode?: Maybe<Scalars['CodeTableValue']>;
  InsulationCoreId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_InsulationCore_Min_Fields = {
  __typename?: 'C_InsulationCore_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  InsulationCoreCode?: Maybe<Scalars['CodeTableValue']>;
  InsulationCoreId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_InsulationCore" */
export type C_InsulationCore_Mutation_Response = {
  __typename?: 'C_InsulationCore_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_InsulationCore>;
};

/** Ordering options when selecting data from "C_InsulationCore". */
export type C_InsulationCore_Order_By = {
  CompositePanelCovers_aggregate?: Maybe<CompositePanelCover_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  InsulationCoreCode?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_InsulationCore */
export type C_InsulationCore_Pk_Columns_Input = {
  InsulationCoreId: Scalars['Int'];
};

/** select columns of table "C_InsulationCore" */
export enum C_InsulationCore_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  InsulationCoreCode = 'InsulationCoreCode',
  /** column name */
  InsulationCoreId = 'InsulationCoreId'
}

/** input type for updating data in table "C_InsulationCore" */
export type C_InsulationCore_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  InsulationCoreCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_InsulationCore_Stddev_Fields = {
  __typename?: 'C_InsulationCore_stddev_fields';
  InsulationCoreId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_InsulationCore_Stddev_Pop_Fields = {
  __typename?: 'C_InsulationCore_stddev_pop_fields';
  InsulationCoreId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_InsulationCore_Stddev_Samp_Fields = {
  __typename?: 'C_InsulationCore_stddev_samp_fields';
  InsulationCoreId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_InsulationCore_Sum_Fields = {
  __typename?: 'C_InsulationCore_sum_fields';
  InsulationCoreId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_InsulationCore" */
export enum C_InsulationCore_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  InsulationCoreCode = 'InsulationCoreCode'
}

/** aggregate var_pop on columns */
export type C_InsulationCore_Var_Pop_Fields = {
  __typename?: 'C_InsulationCore_var_pop_fields';
  InsulationCoreId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_InsulationCore_Var_Samp_Fields = {
  __typename?: 'C_InsulationCore_var_samp_fields';
  InsulationCoreId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_InsulationCore_Variance_Fields = {
  __typename?: 'C_InsulationCore_variance_fields';
  InsulationCoreId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_InsulationFoamType" */
export type C_InsulationFoamType = {
  __typename?: 'C_InsulationFoamType';
  Dsc: Scalars['CodeTableDescription'];
  InsulationFoamTypeCode: Scalars['CodeTableValue'];
  InsulationFoamTypeId: Scalars['Int'];
  /** An array relationship */
  SprayedFoamInsulations: Array<SprayedFoamInsulation>;
  /** An aggregate relationship */
  SprayedFoamInsulations_aggregate: SprayedFoamInsulation_Aggregate;
};


/** columns and relationships of "C_InsulationFoamType" */
export type C_InsulationFoamTypeSprayedFoamInsulationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SprayedFoamInsulation_Order_By>>;
  where?: Maybe<SprayedFoamInsulation_Bool_Exp>;
};


/** columns and relationships of "C_InsulationFoamType" */
export type C_InsulationFoamTypeSprayedFoamInsulations_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SprayedFoamInsulation_Order_By>>;
  where?: Maybe<SprayedFoamInsulation_Bool_Exp>;
};

/** aggregated selection of "C_InsulationFoamType" */
export type C_InsulationFoamType_Aggregate = {
  __typename?: 'C_InsulationFoamType_aggregate';
  aggregate?: Maybe<C_InsulationFoamType_Aggregate_Fields>;
  nodes: Array<C_InsulationFoamType>;
};

/** aggregate fields of "C_InsulationFoamType" */
export type C_InsulationFoamType_Aggregate_Fields = {
  __typename?: 'C_InsulationFoamType_aggregate_fields';
  avg?: Maybe<C_InsulationFoamType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_InsulationFoamType_Max_Fields>;
  min?: Maybe<C_InsulationFoamType_Min_Fields>;
  stddev?: Maybe<C_InsulationFoamType_Stddev_Fields>;
  stddev_pop?: Maybe<C_InsulationFoamType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_InsulationFoamType_Stddev_Samp_Fields>;
  sum?: Maybe<C_InsulationFoamType_Sum_Fields>;
  var_pop?: Maybe<C_InsulationFoamType_Var_Pop_Fields>;
  var_samp?: Maybe<C_InsulationFoamType_Var_Samp_Fields>;
  variance?: Maybe<C_InsulationFoamType_Variance_Fields>;
};


/** aggregate fields of "C_InsulationFoamType" */
export type C_InsulationFoamType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_InsulationFoamType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_InsulationFoamType_Avg_Fields = {
  __typename?: 'C_InsulationFoamType_avg_fields';
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_InsulationFoamType". All fields are combined with a logical 'AND'. */
export type C_InsulationFoamType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  InsulationFoamTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  InsulationFoamTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  SprayedFoamInsulations?: Maybe<SprayedFoamInsulation_Bool_Exp>;
  _and?: Maybe<Array<C_InsulationFoamType_Bool_Exp>>;
  _not?: Maybe<C_InsulationFoamType_Bool_Exp>;
  _or?: Maybe<Array<C_InsulationFoamType_Bool_Exp>>;
};

/** upsert condition type for table "C_InsulationFoamType" */
export type C_InsulationFoamType_If_Matched = {
  match_columns?: Array<C_InsulationFoamType_Insert_Match_Column>;
  update_columns?: Array<C_InsulationFoamType_Update_Column>;
  where?: Maybe<C_InsulationFoamType_Bool_Exp>;
};

/** input type for inserting data into table "C_InsulationFoamType" */
export type C_InsulationFoamType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  InsulationFoamTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_InsulationFoamType" */
export enum C_InsulationFoamType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  InsulationFoamTypeCode = 'InsulationFoamTypeCode',
  /** column name */
  InsulationFoamTypeId = 'InsulationFoamTypeId'
}

/** aggregate max on columns */
export type C_InsulationFoamType_Max_Fields = {
  __typename?: 'C_InsulationFoamType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  InsulationFoamTypeCode?: Maybe<Scalars['CodeTableValue']>;
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_InsulationFoamType_Min_Fields = {
  __typename?: 'C_InsulationFoamType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  InsulationFoamTypeCode?: Maybe<Scalars['CodeTableValue']>;
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_InsulationFoamType" */
export type C_InsulationFoamType_Mutation_Response = {
  __typename?: 'C_InsulationFoamType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_InsulationFoamType>;
};

/** Ordering options when selecting data from "C_InsulationFoamType". */
export type C_InsulationFoamType_Order_By = {
  Dsc?: Maybe<Order_By>;
  InsulationFoamTypeCode?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
  SprayedFoamInsulations_aggregate?: Maybe<SprayedFoamInsulation_Aggregate_Order_By>;
};

/** primary key columns input for table: C_InsulationFoamType */
export type C_InsulationFoamType_Pk_Columns_Input = {
  InsulationFoamTypeId: Scalars['Int'];
};

/** select columns of table "C_InsulationFoamType" */
export enum C_InsulationFoamType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  InsulationFoamTypeCode = 'InsulationFoamTypeCode',
  /** column name */
  InsulationFoamTypeId = 'InsulationFoamTypeId'
}

/** input type for updating data in table "C_InsulationFoamType" */
export type C_InsulationFoamType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  InsulationFoamTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_InsulationFoamType_Stddev_Fields = {
  __typename?: 'C_InsulationFoamType_stddev_fields';
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_InsulationFoamType_Stddev_Pop_Fields = {
  __typename?: 'C_InsulationFoamType_stddev_pop_fields';
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_InsulationFoamType_Stddev_Samp_Fields = {
  __typename?: 'C_InsulationFoamType_stddev_samp_fields';
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_InsulationFoamType_Sum_Fields = {
  __typename?: 'C_InsulationFoamType_sum_fields';
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_InsulationFoamType" */
export enum C_InsulationFoamType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  InsulationFoamTypeCode = 'InsulationFoamTypeCode'
}

/** aggregate var_pop on columns */
export type C_InsulationFoamType_Var_Pop_Fields = {
  __typename?: 'C_InsulationFoamType_var_pop_fields';
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_InsulationFoamType_Var_Samp_Fields = {
  __typename?: 'C_InsulationFoamType_var_samp_fields';
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_InsulationFoamType_Variance_Fields = {
  __typename?: 'C_InsulationFoamType_variance_fields';
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_IntFireRating" */
export type C_IntFireRating = {
  __typename?: 'C_IntFireRating';
  Dsc: Scalars['CodeTableDescription'];
  IntFireRatingCode: Scalars['CodeTableValue'];
  IntFireRatingId: Scalars['Int'];
  /** An array relationship */
  RoofAreas: Array<RoofArea>;
  /** An aggregate relationship */
  RoofAreas_aggregate: RoofArea_Aggregate;
  /** An array relationship */
  RoofAssemblies: Array<RoofAssembly>;
  /** An aggregate relationship */
  RoofAssemblies_aggregate: RoofAssembly_Aggregate;
};


/** columns and relationships of "C_IntFireRating" */
export type C_IntFireRatingRoofAreasArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};


/** columns and relationships of "C_IntFireRating" */
export type C_IntFireRatingRoofAreas_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};


/** columns and relationships of "C_IntFireRating" */
export type C_IntFireRatingRoofAssembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "C_IntFireRating" */
export type C_IntFireRatingRoofAssemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};

/** aggregated selection of "C_IntFireRating" */
export type C_IntFireRating_Aggregate = {
  __typename?: 'C_IntFireRating_aggregate';
  aggregate?: Maybe<C_IntFireRating_Aggregate_Fields>;
  nodes: Array<C_IntFireRating>;
};

/** aggregate fields of "C_IntFireRating" */
export type C_IntFireRating_Aggregate_Fields = {
  __typename?: 'C_IntFireRating_aggregate_fields';
  avg?: Maybe<C_IntFireRating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_IntFireRating_Max_Fields>;
  min?: Maybe<C_IntFireRating_Min_Fields>;
  stddev?: Maybe<C_IntFireRating_Stddev_Fields>;
  stddev_pop?: Maybe<C_IntFireRating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_IntFireRating_Stddev_Samp_Fields>;
  sum?: Maybe<C_IntFireRating_Sum_Fields>;
  var_pop?: Maybe<C_IntFireRating_Var_Pop_Fields>;
  var_samp?: Maybe<C_IntFireRating_Var_Samp_Fields>;
  variance?: Maybe<C_IntFireRating_Variance_Fields>;
};


/** aggregate fields of "C_IntFireRating" */
export type C_IntFireRating_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_IntFireRating_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_IntFireRating_Avg_Fields = {
  __typename?: 'C_IntFireRating_avg_fields';
  IntFireRatingId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_IntFireRating". All fields are combined with a logical 'AND'. */
export type C_IntFireRating_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  IntFireRatingCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  IntFireRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  RoofAreas?: Maybe<RoofArea_Bool_Exp>;
  RoofAssemblies?: Maybe<RoofAssembly_Bool_Exp>;
  _and?: Maybe<Array<C_IntFireRating_Bool_Exp>>;
  _not?: Maybe<C_IntFireRating_Bool_Exp>;
  _or?: Maybe<Array<C_IntFireRating_Bool_Exp>>;
};

/** upsert condition type for table "C_IntFireRating" */
export type C_IntFireRating_If_Matched = {
  match_columns?: Array<C_IntFireRating_Insert_Match_Column>;
  update_columns?: Array<C_IntFireRating_Update_Column>;
  where?: Maybe<C_IntFireRating_Bool_Exp>;
};

/** input type for inserting data into table "C_IntFireRating" */
export type C_IntFireRating_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  IntFireRatingCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_IntFireRating" */
export enum C_IntFireRating_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  IntFireRatingCode = 'IntFireRatingCode',
  /** column name */
  IntFireRatingId = 'IntFireRatingId'
}

/** aggregate max on columns */
export type C_IntFireRating_Max_Fields = {
  __typename?: 'C_IntFireRating_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  IntFireRatingCode?: Maybe<Scalars['CodeTableValue']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_IntFireRating_Min_Fields = {
  __typename?: 'C_IntFireRating_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  IntFireRatingCode?: Maybe<Scalars['CodeTableValue']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_IntFireRating" */
export type C_IntFireRating_Mutation_Response = {
  __typename?: 'C_IntFireRating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_IntFireRating>;
};

/** Ordering options when selecting data from "C_IntFireRating". */
export type C_IntFireRating_Order_By = {
  Dsc?: Maybe<Order_By>;
  IntFireRatingCode?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  RoofAreas_aggregate?: Maybe<RoofArea_Aggregate_Order_By>;
  RoofAssemblies_aggregate?: Maybe<RoofAssembly_Aggregate_Order_By>;
};

/** primary key columns input for table: C_IntFireRating */
export type C_IntFireRating_Pk_Columns_Input = {
  IntFireRatingId: Scalars['Int'];
};

/** select columns of table "C_IntFireRating" */
export enum C_IntFireRating_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  IntFireRatingCode = 'IntFireRatingCode',
  /** column name */
  IntFireRatingId = 'IntFireRatingId'
}

/** input type for updating data in table "C_IntFireRating" */
export type C_IntFireRating_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  IntFireRatingCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_IntFireRating_Stddev_Fields = {
  __typename?: 'C_IntFireRating_stddev_fields';
  IntFireRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_IntFireRating_Stddev_Pop_Fields = {
  __typename?: 'C_IntFireRating_stddev_pop_fields';
  IntFireRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_IntFireRating_Stddev_Samp_Fields = {
  __typename?: 'C_IntFireRating_stddev_samp_fields';
  IntFireRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_IntFireRating_Sum_Fields = {
  __typename?: 'C_IntFireRating_sum_fields';
  IntFireRatingId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_IntFireRating" */
export enum C_IntFireRating_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  IntFireRatingCode = 'IntFireRatingCode'
}

/** aggregate var_pop on columns */
export type C_IntFireRating_Var_Pop_Fields = {
  __typename?: 'C_IntFireRating_var_pop_fields';
  IntFireRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_IntFireRating_Var_Samp_Fields = {
  __typename?: 'C_IntFireRating_var_samp_fields';
  IntFireRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_IntFireRating_Variance_Fields = {
  __typename?: 'C_IntFireRating_variance_fields';
  IntFireRatingId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_LapSealantType" */
export type C_LapSealantType = {
  __typename?: 'C_LapSealantType';
  Dsc: Scalars['CodeTableDescription'];
  LapSealantTypeCode: Scalars['CodeTableValue'];
  LapSealantTypeId: Scalars['Int'];
};

/** aggregated selection of "C_LapSealantType" */
export type C_LapSealantType_Aggregate = {
  __typename?: 'C_LapSealantType_aggregate';
  aggregate?: Maybe<C_LapSealantType_Aggregate_Fields>;
  nodes: Array<C_LapSealantType>;
};

/** aggregate fields of "C_LapSealantType" */
export type C_LapSealantType_Aggregate_Fields = {
  __typename?: 'C_LapSealantType_aggregate_fields';
  avg?: Maybe<C_LapSealantType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_LapSealantType_Max_Fields>;
  min?: Maybe<C_LapSealantType_Min_Fields>;
  stddev?: Maybe<C_LapSealantType_Stddev_Fields>;
  stddev_pop?: Maybe<C_LapSealantType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_LapSealantType_Stddev_Samp_Fields>;
  sum?: Maybe<C_LapSealantType_Sum_Fields>;
  var_pop?: Maybe<C_LapSealantType_Var_Pop_Fields>;
  var_samp?: Maybe<C_LapSealantType_Var_Samp_Fields>;
  variance?: Maybe<C_LapSealantType_Variance_Fields>;
};


/** aggregate fields of "C_LapSealantType" */
export type C_LapSealantType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_LapSealantType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_LapSealantType_Avg_Fields = {
  __typename?: 'C_LapSealantType_avg_fields';
  LapSealantTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_LapSealantType". All fields are combined with a logical 'AND'. */
export type C_LapSealantType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  LapSealantTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  LapSealantTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_LapSealantType_Bool_Exp>>;
  _not?: Maybe<C_LapSealantType_Bool_Exp>;
  _or?: Maybe<Array<C_LapSealantType_Bool_Exp>>;
};

/** upsert condition type for table "C_LapSealantType" */
export type C_LapSealantType_If_Matched = {
  match_columns?: Array<C_LapSealantType_Insert_Match_Column>;
  update_columns?: Array<C_LapSealantType_Update_Column>;
  where?: Maybe<C_LapSealantType_Bool_Exp>;
};

/** input type for inserting data into table "C_LapSealantType" */
export type C_LapSealantType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LapSealantTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_LapSealantType" */
export enum C_LapSealantType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LapSealantTypeCode = 'LapSealantTypeCode',
  /** column name */
  LapSealantTypeId = 'LapSealantTypeId'
}

/** aggregate max on columns */
export type C_LapSealantType_Max_Fields = {
  __typename?: 'C_LapSealantType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LapSealantTypeCode?: Maybe<Scalars['CodeTableValue']>;
  LapSealantTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_LapSealantType_Min_Fields = {
  __typename?: 'C_LapSealantType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LapSealantTypeCode?: Maybe<Scalars['CodeTableValue']>;
  LapSealantTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_LapSealantType" */
export type C_LapSealantType_Mutation_Response = {
  __typename?: 'C_LapSealantType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_LapSealantType>;
};

/** Ordering options when selecting data from "C_LapSealantType". */
export type C_LapSealantType_Order_By = {
  Dsc?: Maybe<Order_By>;
  LapSealantTypeCode?: Maybe<Order_By>;
  LapSealantTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_LapSealantType */
export type C_LapSealantType_Pk_Columns_Input = {
  LapSealantTypeId: Scalars['Int'];
};

/** select columns of table "C_LapSealantType" */
export enum C_LapSealantType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LapSealantTypeCode = 'LapSealantTypeCode',
  /** column name */
  LapSealantTypeId = 'LapSealantTypeId'
}

/** input type for updating data in table "C_LapSealantType" */
export type C_LapSealantType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LapSealantTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_LapSealantType_Stddev_Fields = {
  __typename?: 'C_LapSealantType_stddev_fields';
  LapSealantTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_LapSealantType_Stddev_Pop_Fields = {
  __typename?: 'C_LapSealantType_stddev_pop_fields';
  LapSealantTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_LapSealantType_Stddev_Samp_Fields = {
  __typename?: 'C_LapSealantType_stddev_samp_fields';
  LapSealantTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_LapSealantType_Sum_Fields = {
  __typename?: 'C_LapSealantType_sum_fields';
  LapSealantTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_LapSealantType" */
export enum C_LapSealantType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LapSealantTypeCode = 'LapSealantTypeCode'
}

/** aggregate var_pop on columns */
export type C_LapSealantType_Var_Pop_Fields = {
  __typename?: 'C_LapSealantType_var_pop_fields';
  LapSealantTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_LapSealantType_Var_Samp_Fields = {
  __typename?: 'C_LapSealantType_var_samp_fields';
  LapSealantTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_LapSealantType_Variance_Fields = {
  __typename?: 'C_LapSealantType_variance_fields';
  LapSealantTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_LayerType" */
export type C_LayerType = {
  __typename?: 'C_LayerType';
  /** An array relationship */
  ApprovedLayerUses: Array<ApprovedLayerUse>;
  /** An aggregate relationship */
  ApprovedLayerUses_aggregate: ApprovedLayerUse_Aggregate;
  /** An array relationship */
  ComponentLayers: Array<ComponentLayer>;
  /** An aggregate relationship */
  ComponentLayers_aggregate: ComponentLayer_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  LayerTypeCode: Scalars['CodeTableValue'];
  LayerTypeId: Scalars['Int'];
  /** An array relationship */
  Layers: Array<Layer>;
  /** An aggregate relationship */
  Layers_aggregate: Layer_Aggregate;
  /** An array relationship */
  NavLayers: Array<NavLayer>;
  /** An aggregate relationship */
  NavLayers_aggregate: NavLayer_Aggregate;
};


/** columns and relationships of "C_LayerType" */
export type C_LayerTypeApprovedLayerUsesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ApprovedLayerUse_Order_By>>;
  where?: Maybe<ApprovedLayerUse_Bool_Exp>;
};


/** columns and relationships of "C_LayerType" */
export type C_LayerTypeApprovedLayerUses_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ApprovedLayerUse_Order_By>>;
  where?: Maybe<ApprovedLayerUse_Bool_Exp>;
};


/** columns and relationships of "C_LayerType" */
export type C_LayerTypeComponentLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentLayer_Order_By>>;
  where?: Maybe<ComponentLayer_Bool_Exp>;
};


/** columns and relationships of "C_LayerType" */
export type C_LayerTypeComponentLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentLayer_Order_By>>;
  where?: Maybe<ComponentLayer_Bool_Exp>;
};


/** columns and relationships of "C_LayerType" */
export type C_LayerTypeLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Layer_Order_By>>;
  where?: Maybe<Layer_Bool_Exp>;
};


/** columns and relationships of "C_LayerType" */
export type C_LayerTypeLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Layer_Order_By>>;
  where?: Maybe<Layer_Bool_Exp>;
};


/** columns and relationships of "C_LayerType" */
export type C_LayerTypeNavLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavLayer_Order_By>>;
  where?: Maybe<NavLayer_Bool_Exp>;
};


/** columns and relationships of "C_LayerType" */
export type C_LayerTypeNavLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavLayer_Order_By>>;
  where?: Maybe<NavLayer_Bool_Exp>;
};

/** aggregated selection of "C_LayerType" */
export type C_LayerType_Aggregate = {
  __typename?: 'C_LayerType_aggregate';
  aggregate?: Maybe<C_LayerType_Aggregate_Fields>;
  nodes: Array<C_LayerType>;
};

/** aggregate fields of "C_LayerType" */
export type C_LayerType_Aggregate_Fields = {
  __typename?: 'C_LayerType_aggregate_fields';
  avg?: Maybe<C_LayerType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_LayerType_Max_Fields>;
  min?: Maybe<C_LayerType_Min_Fields>;
  stddev?: Maybe<C_LayerType_Stddev_Fields>;
  stddev_pop?: Maybe<C_LayerType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_LayerType_Stddev_Samp_Fields>;
  sum?: Maybe<C_LayerType_Sum_Fields>;
  var_pop?: Maybe<C_LayerType_Var_Pop_Fields>;
  var_samp?: Maybe<C_LayerType_Var_Samp_Fields>;
  variance?: Maybe<C_LayerType_Variance_Fields>;
};


/** aggregate fields of "C_LayerType" */
export type C_LayerType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_LayerType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_LayerType_Avg_Fields = {
  __typename?: 'C_LayerType_avg_fields';
  LayerTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_LayerType". All fields are combined with a logical 'AND'. */
export type C_LayerType_Bool_Exp = {
  ApprovedLayerUses?: Maybe<ApprovedLayerUse_Bool_Exp>;
  ComponentLayers?: Maybe<ComponentLayer_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  LayerTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  LayerTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Layers?: Maybe<Layer_Bool_Exp>;
  NavLayers?: Maybe<NavLayer_Bool_Exp>;
  _and?: Maybe<Array<C_LayerType_Bool_Exp>>;
  _not?: Maybe<C_LayerType_Bool_Exp>;
  _or?: Maybe<Array<C_LayerType_Bool_Exp>>;
};

/** upsert condition type for table "C_LayerType" */
export type C_LayerType_If_Matched = {
  match_columns?: Array<C_LayerType_Insert_Match_Column>;
  update_columns?: Array<C_LayerType_Update_Column>;
  where?: Maybe<C_LayerType_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "C_LayerType" */
export type C_LayerType_Inc_Input = {
  LayerTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "C_LayerType" */
export type C_LayerType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LayerTypeCode?: Maybe<Scalars['CodeTableValue']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "C_LayerType" */
export enum C_LayerType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LayerTypeCode = 'LayerTypeCode',
  /** column name */
  LayerTypeId = 'LayerTypeId'
}

/** aggregate max on columns */
export type C_LayerType_Max_Fields = {
  __typename?: 'C_LayerType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LayerTypeCode?: Maybe<Scalars['CodeTableValue']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_LayerType_Min_Fields = {
  __typename?: 'C_LayerType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LayerTypeCode?: Maybe<Scalars['CodeTableValue']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_LayerType" */
export type C_LayerType_Mutation_Response = {
  __typename?: 'C_LayerType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_LayerType>;
};

/** Ordering options when selecting data from "C_LayerType". */
export type C_LayerType_Order_By = {
  ApprovedLayerUses_aggregate?: Maybe<ApprovedLayerUse_Aggregate_Order_By>;
  ComponentLayers_aggregate?: Maybe<ComponentLayer_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  LayerTypeCode?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  Layers_aggregate?: Maybe<Layer_Aggregate_Order_By>;
  NavLayers_aggregate?: Maybe<NavLayer_Aggregate_Order_By>;
};

/** primary key columns input for table: C_LayerType */
export type C_LayerType_Pk_Columns_Input = {
  LayerTypeId: Scalars['Int'];
};

/** select columns of table "C_LayerType" */
export enum C_LayerType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LayerTypeCode = 'LayerTypeCode',
  /** column name */
  LayerTypeId = 'LayerTypeId'
}

/** input type for updating data in table "C_LayerType" */
export type C_LayerType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LayerTypeCode?: Maybe<Scalars['CodeTableValue']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type C_LayerType_Stddev_Fields = {
  __typename?: 'C_LayerType_stddev_fields';
  LayerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_LayerType_Stddev_Pop_Fields = {
  __typename?: 'C_LayerType_stddev_pop_fields';
  LayerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_LayerType_Stddev_Samp_Fields = {
  __typename?: 'C_LayerType_stddev_samp_fields';
  LayerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_LayerType_Sum_Fields = {
  __typename?: 'C_LayerType_sum_fields';
  LayerTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_LayerType" */
export enum C_LayerType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LayerTypeCode = 'LayerTypeCode',
  /** column name */
  LayerTypeId = 'LayerTypeId'
}

/** aggregate var_pop on columns */
export type C_LayerType_Var_Pop_Fields = {
  __typename?: 'C_LayerType_var_pop_fields';
  LayerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_LayerType_Var_Samp_Fields = {
  __typename?: 'C_LayerType_var_samp_fields';
  LayerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_LayerType_Variance_Fields = {
  __typename?: 'C_LayerType_variance_fields';
  LayerTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_LinerPanelMaterial" */
export type C_LinerPanelMaterial = {
  __typename?: 'C_LinerPanelMaterial';
  Dsc: Scalars['CodeTableDescription'];
  LinerPanelMaterialCode: Scalars['CodeTableValue'];
  LinerPanelMaterialId: Scalars['Int'];
  /** An array relationship */
  LinerPanels: Array<LinerPanel>;
  /** An aggregate relationship */
  LinerPanels_aggregate: LinerPanel_Aggregate;
  /** An array relationship */
  R_LinerPanelMaterialFiberReinforcedPlasticDecks: Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck>;
  /** An aggregate relationship */
  R_LinerPanelMaterialFiberReinforcedPlasticDecks_aggregate: R_LinerPanelMaterialFiberReinforcedPlasticDeck_Aggregate;
};


/** columns and relationships of "C_LinerPanelMaterial" */
export type C_LinerPanelMaterialLinerPanelsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LinerPanel_Order_By>>;
  where?: Maybe<LinerPanel_Bool_Exp>;
};


/** columns and relationships of "C_LinerPanelMaterial" */
export type C_LinerPanelMaterialLinerPanels_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LinerPanel_Order_By>>;
  where?: Maybe<LinerPanel_Bool_Exp>;
};


/** columns and relationships of "C_LinerPanelMaterial" */
export type C_LinerPanelMaterialR_LinerPanelMaterialFiberReinforcedPlasticDecksArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Order_By>>;
  where?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp>;
};


/** columns and relationships of "C_LinerPanelMaterial" */
export type C_LinerPanelMaterialR_LinerPanelMaterialFiberReinforcedPlasticDecks_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Order_By>>;
  where?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp>;
};

/** aggregated selection of "C_LinerPanelMaterial" */
export type C_LinerPanelMaterial_Aggregate = {
  __typename?: 'C_LinerPanelMaterial_aggregate';
  aggregate?: Maybe<C_LinerPanelMaterial_Aggregate_Fields>;
  nodes: Array<C_LinerPanelMaterial>;
};

/** aggregate fields of "C_LinerPanelMaterial" */
export type C_LinerPanelMaterial_Aggregate_Fields = {
  __typename?: 'C_LinerPanelMaterial_aggregate_fields';
  avg?: Maybe<C_LinerPanelMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_LinerPanelMaterial_Max_Fields>;
  min?: Maybe<C_LinerPanelMaterial_Min_Fields>;
  stddev?: Maybe<C_LinerPanelMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_LinerPanelMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_LinerPanelMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_LinerPanelMaterial_Sum_Fields>;
  var_pop?: Maybe<C_LinerPanelMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_LinerPanelMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_LinerPanelMaterial_Variance_Fields>;
};


/** aggregate fields of "C_LinerPanelMaterial" */
export type C_LinerPanelMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_LinerPanelMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_LinerPanelMaterial_Avg_Fields = {
  __typename?: 'C_LinerPanelMaterial_avg_fields';
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_LinerPanelMaterial". All fields are combined with a logical 'AND'. */
export type C_LinerPanelMaterial_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  LinerPanelMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  LinerPanelMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  LinerPanels?: Maybe<LinerPanel_Bool_Exp>;
  R_LinerPanelMaterialFiberReinforcedPlasticDecks?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp>;
  _and?: Maybe<Array<C_LinerPanelMaterial_Bool_Exp>>;
  _not?: Maybe<C_LinerPanelMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_LinerPanelMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_LinerPanelMaterial" */
export type C_LinerPanelMaterial_If_Matched = {
  match_columns?: Array<C_LinerPanelMaterial_Insert_Match_Column>;
  update_columns?: Array<C_LinerPanelMaterial_Update_Column>;
  where?: Maybe<C_LinerPanelMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_LinerPanelMaterial" */
export type C_LinerPanelMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LinerPanelMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_LinerPanelMaterial" */
export enum C_LinerPanelMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LinerPanelMaterialCode = 'LinerPanelMaterialCode',
  /** column name */
  LinerPanelMaterialId = 'LinerPanelMaterialId'
}

/** aggregate max on columns */
export type C_LinerPanelMaterial_Max_Fields = {
  __typename?: 'C_LinerPanelMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LinerPanelMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_LinerPanelMaterial_Min_Fields = {
  __typename?: 'C_LinerPanelMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LinerPanelMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_LinerPanelMaterial" */
export type C_LinerPanelMaterial_Mutation_Response = {
  __typename?: 'C_LinerPanelMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_LinerPanelMaterial>;
};

/** Ordering options when selecting data from "C_LinerPanelMaterial". */
export type C_LinerPanelMaterial_Order_By = {
  Dsc?: Maybe<Order_By>;
  LinerPanelMaterialCode?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
  LinerPanels_aggregate?: Maybe<LinerPanel_Aggregate_Order_By>;
  R_LinerPanelMaterialFiberReinforcedPlasticDecks_aggregate?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Aggregate_Order_By>;
};

/** primary key columns input for table: C_LinerPanelMaterial */
export type C_LinerPanelMaterial_Pk_Columns_Input = {
  LinerPanelMaterialId: Scalars['Int'];
};

/** select columns of table "C_LinerPanelMaterial" */
export enum C_LinerPanelMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LinerPanelMaterialCode = 'LinerPanelMaterialCode',
  /** column name */
  LinerPanelMaterialId = 'LinerPanelMaterialId'
}

/** input type for updating data in table "C_LinerPanelMaterial" */
export type C_LinerPanelMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LinerPanelMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_LinerPanelMaterial_Stddev_Fields = {
  __typename?: 'C_LinerPanelMaterial_stddev_fields';
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_LinerPanelMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_LinerPanelMaterial_stddev_pop_fields';
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_LinerPanelMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_LinerPanelMaterial_stddev_samp_fields';
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_LinerPanelMaterial_Sum_Fields = {
  __typename?: 'C_LinerPanelMaterial_sum_fields';
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_LinerPanelMaterial" */
export enum C_LinerPanelMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LinerPanelMaterialCode = 'LinerPanelMaterialCode'
}

/** aggregate var_pop on columns */
export type C_LinerPanelMaterial_Var_Pop_Fields = {
  __typename?: 'C_LinerPanelMaterial_var_pop_fields';
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_LinerPanelMaterial_Var_Samp_Fields = {
  __typename?: 'C_LinerPanelMaterial_var_samp_fields';
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_LinerPanelMaterial_Variance_Fields = {
  __typename?: 'C_LinerPanelMaterial_variance_fields';
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_LiquidApplication" */
export type C_LiquidApplication = {
  __typename?: 'C_LiquidApplication';
  /** An array relationship */
  BaseCoats: Array<BaseCoat>;
  /** An aggregate relationship */
  BaseCoats_aggregate: BaseCoat_Aggregate;
  /** An array relationship */
  Coats: Array<Coat>;
  /** An aggregate relationship */
  Coats_aggregate: Coat_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  LiquidApplicationCode: Scalars['CodeTableValue'];
  LiquidApplicationId: Scalars['Int'];
  /** An array relationship */
  TopCoats: Array<TopCoat>;
  /** An aggregate relationship */
  TopCoats_aggregate: TopCoat_Aggregate;
};


/** columns and relationships of "C_LiquidApplication" */
export type C_LiquidApplicationBaseCoatsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseCoat_Order_By>>;
  where?: Maybe<BaseCoat_Bool_Exp>;
};


/** columns and relationships of "C_LiquidApplication" */
export type C_LiquidApplicationBaseCoats_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseCoat_Order_By>>;
  where?: Maybe<BaseCoat_Bool_Exp>;
};


/** columns and relationships of "C_LiquidApplication" */
export type C_LiquidApplicationCoatsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coat_Order_By>>;
  where?: Maybe<Coat_Bool_Exp>;
};


/** columns and relationships of "C_LiquidApplication" */
export type C_LiquidApplicationCoats_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coat_Order_By>>;
  where?: Maybe<Coat_Bool_Exp>;
};


/** columns and relationships of "C_LiquidApplication" */
export type C_LiquidApplicationTopCoatsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TopCoat_Order_By>>;
  where?: Maybe<TopCoat_Bool_Exp>;
};


/** columns and relationships of "C_LiquidApplication" */
export type C_LiquidApplicationTopCoats_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TopCoat_Order_By>>;
  where?: Maybe<TopCoat_Bool_Exp>;
};

/** aggregated selection of "C_LiquidApplication" */
export type C_LiquidApplication_Aggregate = {
  __typename?: 'C_LiquidApplication_aggregate';
  aggregate?: Maybe<C_LiquidApplication_Aggregate_Fields>;
  nodes: Array<C_LiquidApplication>;
};

/** aggregate fields of "C_LiquidApplication" */
export type C_LiquidApplication_Aggregate_Fields = {
  __typename?: 'C_LiquidApplication_aggregate_fields';
  avg?: Maybe<C_LiquidApplication_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_LiquidApplication_Max_Fields>;
  min?: Maybe<C_LiquidApplication_Min_Fields>;
  stddev?: Maybe<C_LiquidApplication_Stddev_Fields>;
  stddev_pop?: Maybe<C_LiquidApplication_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_LiquidApplication_Stddev_Samp_Fields>;
  sum?: Maybe<C_LiquidApplication_Sum_Fields>;
  var_pop?: Maybe<C_LiquidApplication_Var_Pop_Fields>;
  var_samp?: Maybe<C_LiquidApplication_Var_Samp_Fields>;
  variance?: Maybe<C_LiquidApplication_Variance_Fields>;
};


/** aggregate fields of "C_LiquidApplication" */
export type C_LiquidApplication_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_LiquidApplication_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_LiquidApplication_Avg_Fields = {
  __typename?: 'C_LiquidApplication_avg_fields';
  LiquidApplicationId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_LiquidApplication". All fields are combined with a logical 'AND'. */
export type C_LiquidApplication_Bool_Exp = {
  BaseCoats?: Maybe<BaseCoat_Bool_Exp>;
  Coats?: Maybe<Coat_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  LiquidApplicationCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  LiquidApplicationId?: Maybe<Int_Mssql_Comparison_Exp>;
  TopCoats?: Maybe<TopCoat_Bool_Exp>;
  _and?: Maybe<Array<C_LiquidApplication_Bool_Exp>>;
  _not?: Maybe<C_LiquidApplication_Bool_Exp>;
  _or?: Maybe<Array<C_LiquidApplication_Bool_Exp>>;
};

/** upsert condition type for table "C_LiquidApplication" */
export type C_LiquidApplication_If_Matched = {
  match_columns?: Array<C_LiquidApplication_Insert_Match_Column>;
  update_columns?: Array<C_LiquidApplication_Update_Column>;
  where?: Maybe<C_LiquidApplication_Bool_Exp>;
};

/** input type for inserting data into table "C_LiquidApplication" */
export type C_LiquidApplication_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LiquidApplicationCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_LiquidApplication" */
export enum C_LiquidApplication_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LiquidApplicationCode = 'LiquidApplicationCode',
  /** column name */
  LiquidApplicationId = 'LiquidApplicationId'
}

/** aggregate max on columns */
export type C_LiquidApplication_Max_Fields = {
  __typename?: 'C_LiquidApplication_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LiquidApplicationCode?: Maybe<Scalars['CodeTableValue']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_LiquidApplication_Min_Fields = {
  __typename?: 'C_LiquidApplication_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LiquidApplicationCode?: Maybe<Scalars['CodeTableValue']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_LiquidApplication" */
export type C_LiquidApplication_Mutation_Response = {
  __typename?: 'C_LiquidApplication_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_LiquidApplication>;
};

/** Ordering options when selecting data from "C_LiquidApplication". */
export type C_LiquidApplication_Order_By = {
  BaseCoats_aggregate?: Maybe<BaseCoat_Aggregate_Order_By>;
  Coats_aggregate?: Maybe<Coat_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  LiquidApplicationCode?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  TopCoats_aggregate?: Maybe<TopCoat_Aggregate_Order_By>;
};

/** primary key columns input for table: C_LiquidApplication */
export type C_LiquidApplication_Pk_Columns_Input = {
  LiquidApplicationId: Scalars['Int'];
};

/** select columns of table "C_LiquidApplication" */
export enum C_LiquidApplication_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LiquidApplicationCode = 'LiquidApplicationCode',
  /** column name */
  LiquidApplicationId = 'LiquidApplicationId'
}

/** input type for updating data in table "C_LiquidApplication" */
export type C_LiquidApplication_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LiquidApplicationCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_LiquidApplication_Stddev_Fields = {
  __typename?: 'C_LiquidApplication_stddev_fields';
  LiquidApplicationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_LiquidApplication_Stddev_Pop_Fields = {
  __typename?: 'C_LiquidApplication_stddev_pop_fields';
  LiquidApplicationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_LiquidApplication_Stddev_Samp_Fields = {
  __typename?: 'C_LiquidApplication_stddev_samp_fields';
  LiquidApplicationId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_LiquidApplication_Sum_Fields = {
  __typename?: 'C_LiquidApplication_sum_fields';
  LiquidApplicationId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_LiquidApplication" */
export enum C_LiquidApplication_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LiquidApplicationCode = 'LiquidApplicationCode'
}

/** aggregate var_pop on columns */
export type C_LiquidApplication_Var_Pop_Fields = {
  __typename?: 'C_LiquidApplication_var_pop_fields';
  LiquidApplicationId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_LiquidApplication_Var_Samp_Fields = {
  __typename?: 'C_LiquidApplication_var_samp_fields';
  LiquidApplicationId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_LiquidApplication_Variance_Fields = {
  __typename?: 'C_LiquidApplication_variance_fields';
  LiquidApplicationId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_LiquidAppliedMaterial" */
export type C_LiquidAppliedMaterial = {
  __typename?: 'C_LiquidAppliedMaterial';
  /** An array relationship */
  BaseCoats: Array<BaseCoat>;
  /** An aggregate relationship */
  BaseCoats_aggregate: BaseCoat_Aggregate;
  /** An array relationship */
  Coats: Array<Coat>;
  /** An aggregate relationship */
  Coats_aggregate: Coat_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  LiquidAppliedMaterialCode: Scalars['CodeTableValue'];
  LiquidAppliedMaterialId: Scalars['Int'];
  /** An array relationship */
  TopCoats: Array<TopCoat>;
  /** An aggregate relationship */
  TopCoats_aggregate: TopCoat_Aggregate;
};


/** columns and relationships of "C_LiquidAppliedMaterial" */
export type C_LiquidAppliedMaterialBaseCoatsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseCoat_Order_By>>;
  where?: Maybe<BaseCoat_Bool_Exp>;
};


/** columns and relationships of "C_LiquidAppliedMaterial" */
export type C_LiquidAppliedMaterialBaseCoats_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseCoat_Order_By>>;
  where?: Maybe<BaseCoat_Bool_Exp>;
};


/** columns and relationships of "C_LiquidAppliedMaterial" */
export type C_LiquidAppliedMaterialCoatsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coat_Order_By>>;
  where?: Maybe<Coat_Bool_Exp>;
};


/** columns and relationships of "C_LiquidAppliedMaterial" */
export type C_LiquidAppliedMaterialCoats_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coat_Order_By>>;
  where?: Maybe<Coat_Bool_Exp>;
};


/** columns and relationships of "C_LiquidAppliedMaterial" */
export type C_LiquidAppliedMaterialTopCoatsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TopCoat_Order_By>>;
  where?: Maybe<TopCoat_Bool_Exp>;
};


/** columns and relationships of "C_LiquidAppliedMaterial" */
export type C_LiquidAppliedMaterialTopCoats_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TopCoat_Order_By>>;
  where?: Maybe<TopCoat_Bool_Exp>;
};

/** aggregated selection of "C_LiquidAppliedMaterial" */
export type C_LiquidAppliedMaterial_Aggregate = {
  __typename?: 'C_LiquidAppliedMaterial_aggregate';
  aggregate?: Maybe<C_LiquidAppliedMaterial_Aggregate_Fields>;
  nodes: Array<C_LiquidAppliedMaterial>;
};

/** aggregate fields of "C_LiquidAppliedMaterial" */
export type C_LiquidAppliedMaterial_Aggregate_Fields = {
  __typename?: 'C_LiquidAppliedMaterial_aggregate_fields';
  avg?: Maybe<C_LiquidAppliedMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_LiquidAppliedMaterial_Max_Fields>;
  min?: Maybe<C_LiquidAppliedMaterial_Min_Fields>;
  stddev?: Maybe<C_LiquidAppliedMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_LiquidAppliedMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_LiquidAppliedMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_LiquidAppliedMaterial_Sum_Fields>;
  var_pop?: Maybe<C_LiquidAppliedMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_LiquidAppliedMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_LiquidAppliedMaterial_Variance_Fields>;
};


/** aggregate fields of "C_LiquidAppliedMaterial" */
export type C_LiquidAppliedMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_LiquidAppliedMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_LiquidAppliedMaterial_Avg_Fields = {
  __typename?: 'C_LiquidAppliedMaterial_avg_fields';
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_LiquidAppliedMaterial". All fields are combined with a logical 'AND'. */
export type C_LiquidAppliedMaterial_Bool_Exp = {
  BaseCoats?: Maybe<BaseCoat_Bool_Exp>;
  Coats?: Maybe<Coat_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  LiquidAppliedMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  LiquidAppliedMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  TopCoats?: Maybe<TopCoat_Bool_Exp>;
  _and?: Maybe<Array<C_LiquidAppliedMaterial_Bool_Exp>>;
  _not?: Maybe<C_LiquidAppliedMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_LiquidAppliedMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_LiquidAppliedMaterial" */
export type C_LiquidAppliedMaterial_If_Matched = {
  match_columns?: Array<C_LiquidAppliedMaterial_Insert_Match_Column>;
  update_columns?: Array<C_LiquidAppliedMaterial_Update_Column>;
  where?: Maybe<C_LiquidAppliedMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_LiquidAppliedMaterial" */
export type C_LiquidAppliedMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LiquidAppliedMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_LiquidAppliedMaterial" */
export enum C_LiquidAppliedMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LiquidAppliedMaterialCode = 'LiquidAppliedMaterialCode',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId'
}

/** aggregate max on columns */
export type C_LiquidAppliedMaterial_Max_Fields = {
  __typename?: 'C_LiquidAppliedMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LiquidAppliedMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_LiquidAppliedMaterial_Min_Fields = {
  __typename?: 'C_LiquidAppliedMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LiquidAppliedMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_LiquidAppliedMaterial" */
export type C_LiquidAppliedMaterial_Mutation_Response = {
  __typename?: 'C_LiquidAppliedMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_LiquidAppliedMaterial>;
};

/** Ordering options when selecting data from "C_LiquidAppliedMaterial". */
export type C_LiquidAppliedMaterial_Order_By = {
  BaseCoats_aggregate?: Maybe<BaseCoat_Aggregate_Order_By>;
  Coats_aggregate?: Maybe<Coat_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  LiquidAppliedMaterialCode?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  TopCoats_aggregate?: Maybe<TopCoat_Aggregate_Order_By>;
};

/** primary key columns input for table: C_LiquidAppliedMaterial */
export type C_LiquidAppliedMaterial_Pk_Columns_Input = {
  LiquidAppliedMaterialId: Scalars['Int'];
};

/** select columns of table "C_LiquidAppliedMaterial" */
export enum C_LiquidAppliedMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LiquidAppliedMaterialCode = 'LiquidAppliedMaterialCode',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId'
}

/** input type for updating data in table "C_LiquidAppliedMaterial" */
export type C_LiquidAppliedMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LiquidAppliedMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_LiquidAppliedMaterial_Stddev_Fields = {
  __typename?: 'C_LiquidAppliedMaterial_stddev_fields';
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_LiquidAppliedMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_LiquidAppliedMaterial_stddev_pop_fields';
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_LiquidAppliedMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_LiquidAppliedMaterial_stddev_samp_fields';
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_LiquidAppliedMaterial_Sum_Fields = {
  __typename?: 'C_LiquidAppliedMaterial_sum_fields';
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_LiquidAppliedMaterial" */
export enum C_LiquidAppliedMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LiquidAppliedMaterialCode = 'LiquidAppliedMaterialCode'
}

/** aggregate var_pop on columns */
export type C_LiquidAppliedMaterial_Var_Pop_Fields = {
  __typename?: 'C_LiquidAppliedMaterial_var_pop_fields';
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_LiquidAppliedMaterial_Var_Samp_Fields = {
  __typename?: 'C_LiquidAppliedMaterial_var_samp_fields';
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_LiquidAppliedMaterial_Variance_Fields = {
  __typename?: 'C_LiquidAppliedMaterial_variance_fields';
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_LockStatus" */
export type C_LockStatus = {
  __typename?: 'C_LockStatus';
  Dsc: Scalars['CodeTableDescription'];
  LockStatusCode: Scalars['CodeTableValue'];
  LockStatusId: Scalars['Int'];
  /** An array relationship */
  ManufacturedProducts: Array<ManufacturedProduct>;
  /** An aggregate relationship */
  ManufacturedProducts_aggregate: ManufacturedProduct_Aggregate;
  /** An array relationship */
  RoofAssemblies: Array<RoofAssembly>;
  /** An aggregate relationship */
  RoofAssemblies_aggregate: RoofAssembly_Aggregate;
  /** An array relationship */
  Subassemblies: Array<Subassembly>;
  /** An aggregate relationship */
  Subassemblies_aggregate: Subassembly_Aggregate;
};


/** columns and relationships of "C_LockStatus" */
export type C_LockStatusManufacturedProductsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturedProduct_Order_By>>;
  where?: Maybe<ManufacturedProduct_Bool_Exp>;
};


/** columns and relationships of "C_LockStatus" */
export type C_LockStatusManufacturedProducts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturedProduct_Order_By>>;
  where?: Maybe<ManufacturedProduct_Bool_Exp>;
};


/** columns and relationships of "C_LockStatus" */
export type C_LockStatusRoofAssembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "C_LockStatus" */
export type C_LockStatusRoofAssemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "C_LockStatus" */
export type C_LockStatusSubassembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subassembly_Order_By>>;
  where?: Maybe<Subassembly_Bool_Exp>;
};


/** columns and relationships of "C_LockStatus" */
export type C_LockStatusSubassemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subassembly_Order_By>>;
  where?: Maybe<Subassembly_Bool_Exp>;
};

/** aggregated selection of "C_LockStatus" */
export type C_LockStatus_Aggregate = {
  __typename?: 'C_LockStatus_aggregate';
  aggregate?: Maybe<C_LockStatus_Aggregate_Fields>;
  nodes: Array<C_LockStatus>;
};

/** aggregate fields of "C_LockStatus" */
export type C_LockStatus_Aggregate_Fields = {
  __typename?: 'C_LockStatus_aggregate_fields';
  avg?: Maybe<C_LockStatus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_LockStatus_Max_Fields>;
  min?: Maybe<C_LockStatus_Min_Fields>;
  stddev?: Maybe<C_LockStatus_Stddev_Fields>;
  stddev_pop?: Maybe<C_LockStatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_LockStatus_Stddev_Samp_Fields>;
  sum?: Maybe<C_LockStatus_Sum_Fields>;
  var_pop?: Maybe<C_LockStatus_Var_Pop_Fields>;
  var_samp?: Maybe<C_LockStatus_Var_Samp_Fields>;
  variance?: Maybe<C_LockStatus_Variance_Fields>;
};


/** aggregate fields of "C_LockStatus" */
export type C_LockStatus_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_LockStatus_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_LockStatus_Avg_Fields = {
  __typename?: 'C_LockStatus_avg_fields';
  LockStatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_LockStatus". All fields are combined with a logical 'AND'. */
export type C_LockStatus_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  LockStatusCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProducts?: Maybe<ManufacturedProduct_Bool_Exp>;
  RoofAssemblies?: Maybe<RoofAssembly_Bool_Exp>;
  Subassemblies?: Maybe<Subassembly_Bool_Exp>;
  _and?: Maybe<Array<C_LockStatus_Bool_Exp>>;
  _not?: Maybe<C_LockStatus_Bool_Exp>;
  _or?: Maybe<Array<C_LockStatus_Bool_Exp>>;
};

/** upsert condition type for table "C_LockStatus" */
export type C_LockStatus_If_Matched = {
  match_columns?: Array<C_LockStatus_Insert_Match_Column>;
  update_columns?: Array<C_LockStatus_Update_Column>;
  where?: Maybe<C_LockStatus_Bool_Exp>;
};

/** input type for inserting data into table "C_LockStatus" */
export type C_LockStatus_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LockStatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_LockStatus" */
export enum C_LockStatus_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LockStatusCode = 'LockStatusCode',
  /** column name */
  LockStatusId = 'LockStatusId'
}

/** aggregate max on columns */
export type C_LockStatus_Max_Fields = {
  __typename?: 'C_LockStatus_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LockStatusCode?: Maybe<Scalars['CodeTableValue']>;
  LockStatusId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_LockStatus_Min_Fields = {
  __typename?: 'C_LockStatus_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LockStatusCode?: Maybe<Scalars['CodeTableValue']>;
  LockStatusId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_LockStatus" */
export type C_LockStatus_Mutation_Response = {
  __typename?: 'C_LockStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_LockStatus>;
};

/** Ordering options when selecting data from "C_LockStatus". */
export type C_LockStatus_Order_By = {
  Dsc?: Maybe<Order_By>;
  LockStatusCode?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  ManufacturedProducts_aggregate?: Maybe<ManufacturedProduct_Aggregate_Order_By>;
  RoofAssemblies_aggregate?: Maybe<RoofAssembly_Aggregate_Order_By>;
  Subassemblies_aggregate?: Maybe<Subassembly_Aggregate_Order_By>;
};

/** primary key columns input for table: C_LockStatus */
export type C_LockStatus_Pk_Columns_Input = {
  LockStatusId: Scalars['Int'];
};

/** select columns of table "C_LockStatus" */
export enum C_LockStatus_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LockStatusCode = 'LockStatusCode',
  /** column name */
  LockStatusId = 'LockStatusId'
}

/** input type for updating data in table "C_LockStatus" */
export type C_LockStatus_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  LockStatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_LockStatus_Stddev_Fields = {
  __typename?: 'C_LockStatus_stddev_fields';
  LockStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_LockStatus_Stddev_Pop_Fields = {
  __typename?: 'C_LockStatus_stddev_pop_fields';
  LockStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_LockStatus_Stddev_Samp_Fields = {
  __typename?: 'C_LockStatus_stddev_samp_fields';
  LockStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_LockStatus_Sum_Fields = {
  __typename?: 'C_LockStatus_sum_fields';
  LockStatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_LockStatus" */
export enum C_LockStatus_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  LockStatusCode = 'LockStatusCode'
}

/** aggregate var_pop on columns */
export type C_LockStatus_Var_Pop_Fields = {
  __typename?: 'C_LockStatus_var_pop_fields';
  LockStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_LockStatus_Var_Samp_Fields = {
  __typename?: 'C_LockStatus_var_samp_fields';
  LockStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_LockStatus_Variance_Fields = {
  __typename?: 'C_LockStatus_variance_fields';
  LockStatusId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_MDAttributeType" */
export type C_MdAttributeType = {
  __typename?: 'C_MDAttributeType';
  Dsc: Scalars['CodeTableDescription'];
  MDAttributeTypeCode: Scalars['CodeTableValue'];
  MDAttributeTypeId: Scalars['Int'];
  /** An array relationship */
  MD_AttributeBases: Array<Md_AttributeBase>;
  /** An aggregate relationship */
  MD_AttributeBases_aggregate: Md_AttributeBase_Aggregate;
};


/** columns and relationships of "C_MDAttributeType" */
export type C_MdAttributeTypeMd_AttributeBasesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_AttributeBase_Order_By>>;
  where?: Maybe<Md_AttributeBase_Bool_Exp>;
};


/** columns and relationships of "C_MDAttributeType" */
export type C_MdAttributeTypeMd_AttributeBases_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_AttributeBase_Order_By>>;
  where?: Maybe<Md_AttributeBase_Bool_Exp>;
};

/** aggregated selection of "C_MDAttributeType" */
export type C_MdAttributeType_Aggregate = {
  __typename?: 'C_MDAttributeType_aggregate';
  aggregate?: Maybe<C_MdAttributeType_Aggregate_Fields>;
  nodes: Array<C_MdAttributeType>;
};

/** aggregate fields of "C_MDAttributeType" */
export type C_MdAttributeType_Aggregate_Fields = {
  __typename?: 'C_MDAttributeType_aggregate_fields';
  avg?: Maybe<C_MdAttributeType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_MdAttributeType_Max_Fields>;
  min?: Maybe<C_MdAttributeType_Min_Fields>;
  stddev?: Maybe<C_MdAttributeType_Stddev_Fields>;
  stddev_pop?: Maybe<C_MdAttributeType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_MdAttributeType_Stddev_Samp_Fields>;
  sum?: Maybe<C_MdAttributeType_Sum_Fields>;
  var_pop?: Maybe<C_MdAttributeType_Var_Pop_Fields>;
  var_samp?: Maybe<C_MdAttributeType_Var_Samp_Fields>;
  variance?: Maybe<C_MdAttributeType_Variance_Fields>;
};


/** aggregate fields of "C_MDAttributeType" */
export type C_MdAttributeType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_MdAttributeType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_MdAttributeType_Avg_Fields = {
  __typename?: 'C_MDAttributeType_avg_fields';
  MDAttributeTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_MDAttributeType". All fields are combined with a logical 'AND'. */
export type C_MdAttributeType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  MDAttributeTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  MDAttributeTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  MD_AttributeBases?: Maybe<Md_AttributeBase_Bool_Exp>;
  _and?: Maybe<Array<C_MdAttributeType_Bool_Exp>>;
  _not?: Maybe<C_MdAttributeType_Bool_Exp>;
  _or?: Maybe<Array<C_MdAttributeType_Bool_Exp>>;
};

/** upsert condition type for table "C_MDAttributeType" */
export type C_MdAttributeType_If_Matched = {
  match_columns?: Array<C_MdAttributeType_Insert_Match_Column>;
  update_columns?: Array<C_MdAttributeType_Update_Column>;
  where?: Maybe<C_MdAttributeType_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "C_MDAttributeType" */
export type C_MdAttributeType_Inc_Input = {
  MDAttributeTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "C_MDAttributeType" */
export type C_MdAttributeType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MDAttributeTypeCode?: Maybe<Scalars['CodeTableValue']>;
  MDAttributeTypeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "C_MDAttributeType" */
export enum C_MdAttributeType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MdAttributeTypeCode = 'MDAttributeTypeCode',
  /** column name */
  MdAttributeTypeId = 'MDAttributeTypeId'
}

/** aggregate max on columns */
export type C_MdAttributeType_Max_Fields = {
  __typename?: 'C_MDAttributeType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MDAttributeTypeCode?: Maybe<Scalars['CodeTableValue']>;
  MDAttributeTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_MdAttributeType_Min_Fields = {
  __typename?: 'C_MDAttributeType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MDAttributeTypeCode?: Maybe<Scalars['CodeTableValue']>;
  MDAttributeTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_MDAttributeType" */
export type C_MdAttributeType_Mutation_Response = {
  __typename?: 'C_MDAttributeType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_MdAttributeType>;
};

/** Ordering options when selecting data from "C_MDAttributeType". */
export type C_MdAttributeType_Order_By = {
  Dsc?: Maybe<Order_By>;
  MDAttributeTypeCode?: Maybe<Order_By>;
  MDAttributeTypeId?: Maybe<Order_By>;
  MD_AttributeBases_aggregate?: Maybe<Md_AttributeBase_Aggregate_Order_By>;
};

/** primary key columns input for table: C_MDAttributeType */
export type C_MdAttributeType_Pk_Columns_Input = {
  MDAttributeTypeId: Scalars['Int'];
};

/** select columns of table "C_MDAttributeType" */
export enum C_MdAttributeType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MdAttributeTypeCode = 'MDAttributeTypeCode',
  /** column name */
  MdAttributeTypeId = 'MDAttributeTypeId'
}

/** input type for updating data in table "C_MDAttributeType" */
export type C_MdAttributeType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MDAttributeTypeCode?: Maybe<Scalars['CodeTableValue']>;
  MDAttributeTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type C_MdAttributeType_Stddev_Fields = {
  __typename?: 'C_MDAttributeType_stddev_fields';
  MDAttributeTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_MdAttributeType_Stddev_Pop_Fields = {
  __typename?: 'C_MDAttributeType_stddev_pop_fields';
  MDAttributeTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_MdAttributeType_Stddev_Samp_Fields = {
  __typename?: 'C_MDAttributeType_stddev_samp_fields';
  MDAttributeTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_MdAttributeType_Sum_Fields = {
  __typename?: 'C_MDAttributeType_sum_fields';
  MDAttributeTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_MDAttributeType" */
export enum C_MdAttributeType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MdAttributeTypeCode = 'MDAttributeTypeCode',
  /** column name */
  MdAttributeTypeId = 'MDAttributeTypeId'
}

/** aggregate var_pop on columns */
export type C_MdAttributeType_Var_Pop_Fields = {
  __typename?: 'C_MDAttributeType_var_pop_fields';
  MDAttributeTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_MdAttributeType_Var_Samp_Fields = {
  __typename?: 'C_MDAttributeType_var_samp_fields';
  MDAttributeTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_MdAttributeType_Variance_Fields = {
  __typename?: 'C_MDAttributeType_variance_fields';
  MDAttributeTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_MaintenanceCoatingMaterial" */
export type C_MaintenanceCoatingMaterial = {
  __typename?: 'C_MaintenanceCoatingMaterial';
  Dsc: Scalars['CodeTableDescription'];
  MaintenanceCoatingMaterialCode: Scalars['CodeTableValue'];
  MaintenanceCoatingMaterialId: Scalars['Int'];
  /** An array relationship */
  MaintenanceCoatings: Array<MaintenanceCoating>;
  /** An aggregate relationship */
  MaintenanceCoatings_aggregate: MaintenanceCoating_Aggregate;
};


/** columns and relationships of "C_MaintenanceCoatingMaterial" */
export type C_MaintenanceCoatingMaterialMaintenanceCoatingsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoating_Order_By>>;
  where?: Maybe<MaintenanceCoating_Bool_Exp>;
};


/** columns and relationships of "C_MaintenanceCoatingMaterial" */
export type C_MaintenanceCoatingMaterialMaintenanceCoatings_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoating_Order_By>>;
  where?: Maybe<MaintenanceCoating_Bool_Exp>;
};

/** aggregated selection of "C_MaintenanceCoatingMaterial" */
export type C_MaintenanceCoatingMaterial_Aggregate = {
  __typename?: 'C_MaintenanceCoatingMaterial_aggregate';
  aggregate?: Maybe<C_MaintenanceCoatingMaterial_Aggregate_Fields>;
  nodes: Array<C_MaintenanceCoatingMaterial>;
};

/** aggregate fields of "C_MaintenanceCoatingMaterial" */
export type C_MaintenanceCoatingMaterial_Aggregate_Fields = {
  __typename?: 'C_MaintenanceCoatingMaterial_aggregate_fields';
  avg?: Maybe<C_MaintenanceCoatingMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_MaintenanceCoatingMaterial_Max_Fields>;
  min?: Maybe<C_MaintenanceCoatingMaterial_Min_Fields>;
  stddev?: Maybe<C_MaintenanceCoatingMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_MaintenanceCoatingMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_MaintenanceCoatingMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_MaintenanceCoatingMaterial_Sum_Fields>;
  var_pop?: Maybe<C_MaintenanceCoatingMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_MaintenanceCoatingMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_MaintenanceCoatingMaterial_Variance_Fields>;
};


/** aggregate fields of "C_MaintenanceCoatingMaterial" */
export type C_MaintenanceCoatingMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_MaintenanceCoatingMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_MaintenanceCoatingMaterial_Avg_Fields = {
  __typename?: 'C_MaintenanceCoatingMaterial_avg_fields';
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_MaintenanceCoatingMaterial". All fields are combined with a logical 'AND'. */
export type C_MaintenanceCoatingMaterial_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  MaintenanceCoatingMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  MaintenanceCoatingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  MaintenanceCoatings?: Maybe<MaintenanceCoating_Bool_Exp>;
  _and?: Maybe<Array<C_MaintenanceCoatingMaterial_Bool_Exp>>;
  _not?: Maybe<C_MaintenanceCoatingMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_MaintenanceCoatingMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_MaintenanceCoatingMaterial" */
export type C_MaintenanceCoatingMaterial_If_Matched = {
  match_columns?: Array<C_MaintenanceCoatingMaterial_Insert_Match_Column>;
  update_columns?: Array<C_MaintenanceCoatingMaterial_Update_Column>;
  where?: Maybe<C_MaintenanceCoatingMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_MaintenanceCoatingMaterial" */
export type C_MaintenanceCoatingMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MaintenanceCoatingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_MaintenanceCoatingMaterial" */
export enum C_MaintenanceCoatingMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MaintenanceCoatingMaterialCode = 'MaintenanceCoatingMaterialCode',
  /** column name */
  MaintenanceCoatingMaterialId = 'MaintenanceCoatingMaterialId'
}

/** aggregate max on columns */
export type C_MaintenanceCoatingMaterial_Max_Fields = {
  __typename?: 'C_MaintenanceCoatingMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MaintenanceCoatingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_MaintenanceCoatingMaterial_Min_Fields = {
  __typename?: 'C_MaintenanceCoatingMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MaintenanceCoatingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_MaintenanceCoatingMaterial" */
export type C_MaintenanceCoatingMaterial_Mutation_Response = {
  __typename?: 'C_MaintenanceCoatingMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_MaintenanceCoatingMaterial>;
};

/** Ordering options when selecting data from "C_MaintenanceCoatingMaterial". */
export type C_MaintenanceCoatingMaterial_Order_By = {
  Dsc?: Maybe<Order_By>;
  MaintenanceCoatingMaterialCode?: Maybe<Order_By>;
  MaintenanceCoatingMaterialId?: Maybe<Order_By>;
  MaintenanceCoatings_aggregate?: Maybe<MaintenanceCoating_Aggregate_Order_By>;
};

/** primary key columns input for table: C_MaintenanceCoatingMaterial */
export type C_MaintenanceCoatingMaterial_Pk_Columns_Input = {
  MaintenanceCoatingMaterialId: Scalars['Int'];
};

/** select columns of table "C_MaintenanceCoatingMaterial" */
export enum C_MaintenanceCoatingMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MaintenanceCoatingMaterialCode = 'MaintenanceCoatingMaterialCode',
  /** column name */
  MaintenanceCoatingMaterialId = 'MaintenanceCoatingMaterialId'
}

/** input type for updating data in table "C_MaintenanceCoatingMaterial" */
export type C_MaintenanceCoatingMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MaintenanceCoatingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_MaintenanceCoatingMaterial_Stddev_Fields = {
  __typename?: 'C_MaintenanceCoatingMaterial_stddev_fields';
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_MaintenanceCoatingMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_MaintenanceCoatingMaterial_stddev_pop_fields';
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_MaintenanceCoatingMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_MaintenanceCoatingMaterial_stddev_samp_fields';
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_MaintenanceCoatingMaterial_Sum_Fields = {
  __typename?: 'C_MaintenanceCoatingMaterial_sum_fields';
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_MaintenanceCoatingMaterial" */
export enum C_MaintenanceCoatingMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MaintenanceCoatingMaterialCode = 'MaintenanceCoatingMaterialCode'
}

/** aggregate var_pop on columns */
export type C_MaintenanceCoatingMaterial_Var_Pop_Fields = {
  __typename?: 'C_MaintenanceCoatingMaterial_var_pop_fields';
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_MaintenanceCoatingMaterial_Var_Samp_Fields = {
  __typename?: 'C_MaintenanceCoatingMaterial_var_samp_fields';
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_MaintenanceCoatingMaterial_Variance_Fields = {
  __typename?: 'C_MaintenanceCoatingMaterial_variance_fields';
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_MaintenanceCoatingSubstrate" */
export type C_MaintenanceCoatingSubstrate = {
  __typename?: 'C_MaintenanceCoatingSubstrate';
  Dsc: Scalars['CodeTableDescription'];
  MaintenanceCoatingSubstrateCode: Scalars['CodeTableValue'];
  MaintenanceCoatingSubstrateId: Scalars['Int'];
  /** An array relationship */
  MaintenanceCoatingSubstrates: Array<MaintenanceCoatingSubstrate>;
  /** An aggregate relationship */
  MaintenanceCoatingSubstrates_aggregate: MaintenanceCoatingSubstrate_Aggregate;
  /** An array relationship */
  R_MaintenanceCoatingSubstrates: Array<R_MaintenanceCoatingSubstrate>;
  /** An aggregate relationship */
  R_MaintenanceCoatingSubstrates_aggregate: R_MaintenanceCoatingSubstrate_Aggregate;
};


/** columns and relationships of "C_MaintenanceCoatingSubstrate" */
export type C_MaintenanceCoatingSubstrateMaintenanceCoatingSubstratesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<MaintenanceCoatingSubstrate_Bool_Exp>;
};


/** columns and relationships of "C_MaintenanceCoatingSubstrate" */
export type C_MaintenanceCoatingSubstrateMaintenanceCoatingSubstrates_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<MaintenanceCoatingSubstrate_Bool_Exp>;
};


/** columns and relationships of "C_MaintenanceCoatingSubstrate" */
export type C_MaintenanceCoatingSubstrateR_MaintenanceCoatingSubstratesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<R_MaintenanceCoatingSubstrate_Bool_Exp>;
};


/** columns and relationships of "C_MaintenanceCoatingSubstrate" */
export type C_MaintenanceCoatingSubstrateR_MaintenanceCoatingSubstrates_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<R_MaintenanceCoatingSubstrate_Bool_Exp>;
};

/** aggregated selection of "C_MaintenanceCoatingSubstrate" */
export type C_MaintenanceCoatingSubstrate_Aggregate = {
  __typename?: 'C_MaintenanceCoatingSubstrate_aggregate';
  aggregate?: Maybe<C_MaintenanceCoatingSubstrate_Aggregate_Fields>;
  nodes: Array<C_MaintenanceCoatingSubstrate>;
};

/** aggregate fields of "C_MaintenanceCoatingSubstrate" */
export type C_MaintenanceCoatingSubstrate_Aggregate_Fields = {
  __typename?: 'C_MaintenanceCoatingSubstrate_aggregate_fields';
  avg?: Maybe<C_MaintenanceCoatingSubstrate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_MaintenanceCoatingSubstrate_Max_Fields>;
  min?: Maybe<C_MaintenanceCoatingSubstrate_Min_Fields>;
  stddev?: Maybe<C_MaintenanceCoatingSubstrate_Stddev_Fields>;
  stddev_pop?: Maybe<C_MaintenanceCoatingSubstrate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_MaintenanceCoatingSubstrate_Stddev_Samp_Fields>;
  sum?: Maybe<C_MaintenanceCoatingSubstrate_Sum_Fields>;
  var_pop?: Maybe<C_MaintenanceCoatingSubstrate_Var_Pop_Fields>;
  var_samp?: Maybe<C_MaintenanceCoatingSubstrate_Var_Samp_Fields>;
  variance?: Maybe<C_MaintenanceCoatingSubstrate_Variance_Fields>;
};


/** aggregate fields of "C_MaintenanceCoatingSubstrate" */
export type C_MaintenanceCoatingSubstrate_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_MaintenanceCoatingSubstrate_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_MaintenanceCoatingSubstrate_Avg_Fields = {
  __typename?: 'C_MaintenanceCoatingSubstrate_avg_fields';
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_MaintenanceCoatingSubstrate". All fields are combined with a logical 'AND'. */
export type C_MaintenanceCoatingSubstrate_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  MaintenanceCoatingSubstrateCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  MaintenanceCoatingSubstrateId?: Maybe<Int_Mssql_Comparison_Exp>;
  MaintenanceCoatingSubstrates?: Maybe<MaintenanceCoatingSubstrate_Bool_Exp>;
  R_MaintenanceCoatingSubstrates?: Maybe<R_MaintenanceCoatingSubstrate_Bool_Exp>;
  _and?: Maybe<Array<C_MaintenanceCoatingSubstrate_Bool_Exp>>;
  _not?: Maybe<C_MaintenanceCoatingSubstrate_Bool_Exp>;
  _or?: Maybe<Array<C_MaintenanceCoatingSubstrate_Bool_Exp>>;
};

/** upsert condition type for table "C_MaintenanceCoatingSubstrate" */
export type C_MaintenanceCoatingSubstrate_If_Matched = {
  match_columns?: Array<C_MaintenanceCoatingSubstrate_Insert_Match_Column>;
  update_columns?: Array<C_MaintenanceCoatingSubstrate_Update_Column>;
  where?: Maybe<C_MaintenanceCoatingSubstrate_Bool_Exp>;
};

/** input type for inserting data into table "C_MaintenanceCoatingSubstrate" */
export type C_MaintenanceCoatingSubstrate_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MaintenanceCoatingSubstrateCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_MaintenanceCoatingSubstrate" */
export enum C_MaintenanceCoatingSubstrate_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MaintenanceCoatingSubstrateCode = 'MaintenanceCoatingSubstrateCode',
  /** column name */
  MaintenanceCoatingSubstrateId = 'MaintenanceCoatingSubstrateId'
}

/** aggregate max on columns */
export type C_MaintenanceCoatingSubstrate_Max_Fields = {
  __typename?: 'C_MaintenanceCoatingSubstrate_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MaintenanceCoatingSubstrateCode?: Maybe<Scalars['CodeTableValue']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_MaintenanceCoatingSubstrate_Min_Fields = {
  __typename?: 'C_MaintenanceCoatingSubstrate_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MaintenanceCoatingSubstrateCode?: Maybe<Scalars['CodeTableValue']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_MaintenanceCoatingSubstrate" */
export type C_MaintenanceCoatingSubstrate_Mutation_Response = {
  __typename?: 'C_MaintenanceCoatingSubstrate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_MaintenanceCoatingSubstrate>;
};

/** Ordering options when selecting data from "C_MaintenanceCoatingSubstrate". */
export type C_MaintenanceCoatingSubstrate_Order_By = {
  Dsc?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateCode?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrates_aggregate?: Maybe<MaintenanceCoatingSubstrate_Aggregate_Order_By>;
  R_MaintenanceCoatingSubstrates_aggregate?: Maybe<R_MaintenanceCoatingSubstrate_Aggregate_Order_By>;
};

/** primary key columns input for table: C_MaintenanceCoatingSubstrate */
export type C_MaintenanceCoatingSubstrate_Pk_Columns_Input = {
  MaintenanceCoatingSubstrateId: Scalars['Int'];
};

/** select columns of table "C_MaintenanceCoatingSubstrate" */
export enum C_MaintenanceCoatingSubstrate_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MaintenanceCoatingSubstrateCode = 'MaintenanceCoatingSubstrateCode',
  /** column name */
  MaintenanceCoatingSubstrateId = 'MaintenanceCoatingSubstrateId'
}

/** input type for updating data in table "C_MaintenanceCoatingSubstrate" */
export type C_MaintenanceCoatingSubstrate_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MaintenanceCoatingSubstrateCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_MaintenanceCoatingSubstrate_Stddev_Fields = {
  __typename?: 'C_MaintenanceCoatingSubstrate_stddev_fields';
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_MaintenanceCoatingSubstrate_Stddev_Pop_Fields = {
  __typename?: 'C_MaintenanceCoatingSubstrate_stddev_pop_fields';
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_MaintenanceCoatingSubstrate_Stddev_Samp_Fields = {
  __typename?: 'C_MaintenanceCoatingSubstrate_stddev_samp_fields';
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_MaintenanceCoatingSubstrate_Sum_Fields = {
  __typename?: 'C_MaintenanceCoatingSubstrate_sum_fields';
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_MaintenanceCoatingSubstrate" */
export enum C_MaintenanceCoatingSubstrate_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MaintenanceCoatingSubstrateCode = 'MaintenanceCoatingSubstrateCode'
}

/** aggregate var_pop on columns */
export type C_MaintenanceCoatingSubstrate_Var_Pop_Fields = {
  __typename?: 'C_MaintenanceCoatingSubstrate_var_pop_fields';
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_MaintenanceCoatingSubstrate_Var_Samp_Fields = {
  __typename?: 'C_MaintenanceCoatingSubstrate_var_samp_fields';
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_MaintenanceCoatingSubstrate_Variance_Fields = {
  __typename?: 'C_MaintenanceCoatingSubstrate_variance_fields';
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_MembraneTermination" */
export type C_MembraneTermination = {
  __typename?: 'C_MembraneTermination';
  Dsc: Scalars['CodeTableDescription'];
  MembraneTerminationCode: Scalars['CodeTableValue'];
  MembraneTerminationId: Scalars['Int'];
};

/** aggregated selection of "C_MembraneTermination" */
export type C_MembraneTermination_Aggregate = {
  __typename?: 'C_MembraneTermination_aggregate';
  aggregate?: Maybe<C_MembraneTermination_Aggregate_Fields>;
  nodes: Array<C_MembraneTermination>;
};

/** aggregate fields of "C_MembraneTermination" */
export type C_MembraneTermination_Aggregate_Fields = {
  __typename?: 'C_MembraneTermination_aggregate_fields';
  avg?: Maybe<C_MembraneTermination_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_MembraneTermination_Max_Fields>;
  min?: Maybe<C_MembraneTermination_Min_Fields>;
  stddev?: Maybe<C_MembraneTermination_Stddev_Fields>;
  stddev_pop?: Maybe<C_MembraneTermination_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_MembraneTermination_Stddev_Samp_Fields>;
  sum?: Maybe<C_MembraneTermination_Sum_Fields>;
  var_pop?: Maybe<C_MembraneTermination_Var_Pop_Fields>;
  var_samp?: Maybe<C_MembraneTermination_Var_Samp_Fields>;
  variance?: Maybe<C_MembraneTermination_Variance_Fields>;
};


/** aggregate fields of "C_MembraneTermination" */
export type C_MembraneTermination_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_MembraneTermination_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_MembraneTermination_Avg_Fields = {
  __typename?: 'C_MembraneTermination_avg_fields';
  MembraneTerminationId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_MembraneTermination". All fields are combined with a logical 'AND'. */
export type C_MembraneTermination_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  MembraneTerminationCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  MembraneTerminationId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_MembraneTermination_Bool_Exp>>;
  _not?: Maybe<C_MembraneTermination_Bool_Exp>;
  _or?: Maybe<Array<C_MembraneTermination_Bool_Exp>>;
};

/** upsert condition type for table "C_MembraneTermination" */
export type C_MembraneTermination_If_Matched = {
  match_columns?: Array<C_MembraneTermination_Insert_Match_Column>;
  update_columns?: Array<C_MembraneTermination_Update_Column>;
  where?: Maybe<C_MembraneTermination_Bool_Exp>;
};

/** input type for inserting data into table "C_MembraneTermination" */
export type C_MembraneTermination_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MembraneTerminationCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_MembraneTermination" */
export enum C_MembraneTermination_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MembraneTerminationCode = 'MembraneTerminationCode',
  /** column name */
  MembraneTerminationId = 'MembraneTerminationId'
}

/** aggregate max on columns */
export type C_MembraneTermination_Max_Fields = {
  __typename?: 'C_MembraneTermination_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MembraneTerminationCode?: Maybe<Scalars['CodeTableValue']>;
  MembraneTerminationId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_MembraneTermination_Min_Fields = {
  __typename?: 'C_MembraneTermination_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MembraneTerminationCode?: Maybe<Scalars['CodeTableValue']>;
  MembraneTerminationId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_MembraneTermination" */
export type C_MembraneTermination_Mutation_Response = {
  __typename?: 'C_MembraneTermination_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_MembraneTermination>;
};

/** Ordering options when selecting data from "C_MembraneTermination". */
export type C_MembraneTermination_Order_By = {
  Dsc?: Maybe<Order_By>;
  MembraneTerminationCode?: Maybe<Order_By>;
  MembraneTerminationId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_MembraneTermination */
export type C_MembraneTermination_Pk_Columns_Input = {
  MembraneTerminationId: Scalars['Int'];
};

/** select columns of table "C_MembraneTermination" */
export enum C_MembraneTermination_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MembraneTerminationCode = 'MembraneTerminationCode',
  /** column name */
  MembraneTerminationId = 'MembraneTerminationId'
}

/** input type for updating data in table "C_MembraneTermination" */
export type C_MembraneTermination_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MembraneTerminationCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_MembraneTermination_Stddev_Fields = {
  __typename?: 'C_MembraneTermination_stddev_fields';
  MembraneTerminationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_MembraneTermination_Stddev_Pop_Fields = {
  __typename?: 'C_MembraneTermination_stddev_pop_fields';
  MembraneTerminationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_MembraneTermination_Stddev_Samp_Fields = {
  __typename?: 'C_MembraneTermination_stddev_samp_fields';
  MembraneTerminationId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_MembraneTermination_Sum_Fields = {
  __typename?: 'C_MembraneTermination_sum_fields';
  MembraneTerminationId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_MembraneTermination" */
export enum C_MembraneTermination_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MembraneTerminationCode = 'MembraneTerminationCode'
}

/** aggregate var_pop on columns */
export type C_MembraneTermination_Var_Pop_Fields = {
  __typename?: 'C_MembraneTermination_var_pop_fields';
  MembraneTerminationId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_MembraneTermination_Var_Samp_Fields = {
  __typename?: 'C_MembraneTermination_var_samp_fields';
  MembraneTerminationId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_MembraneTermination_Variance_Fields = {
  __typename?: 'C_MembraneTermination_variance_fields';
  MembraneTerminationId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_MultiplyMaterial" */
export type C_MultiplyMaterial = {
  __typename?: 'C_MultiplyMaterial';
  /** An array relationship */
  BasePlies: Array<BasePly>;
  /** An aggregate relationship */
  BasePlies_aggregate: BasePly_Aggregate;
  /** An array relationship */
  BaseSheets: Array<BaseSheet>;
  /** An aggregate relationship */
  BaseSheets_aggregate: BaseSheet_Aggregate;
  /** An array relationship */
  CapPlies: Array<CapPly>;
  /** An aggregate relationship */
  CapPlies_aggregate: CapPly_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  MultiplyMaterialCode: Scalars['CodeTableValue'];
  MultiplyMaterialId: Scalars['Int'];
  /** An array relationship */
  OtherPlies: Array<OtherPly>;
  /** An aggregate relationship */
  OtherPlies_aggregate: OtherPly_Aggregate;
};


/** columns and relationships of "C_MultiplyMaterial" */
export type C_MultiplyMaterialBasePliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BasePly_Order_By>>;
  where?: Maybe<BasePly_Bool_Exp>;
};


/** columns and relationships of "C_MultiplyMaterial" */
export type C_MultiplyMaterialBasePlies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BasePly_Order_By>>;
  where?: Maybe<BasePly_Bool_Exp>;
};


/** columns and relationships of "C_MultiplyMaterial" */
export type C_MultiplyMaterialBaseSheetsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseSheet_Order_By>>;
  where?: Maybe<BaseSheet_Bool_Exp>;
};


/** columns and relationships of "C_MultiplyMaterial" */
export type C_MultiplyMaterialBaseSheets_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseSheet_Order_By>>;
  where?: Maybe<BaseSheet_Bool_Exp>;
};


/** columns and relationships of "C_MultiplyMaterial" */
export type C_MultiplyMaterialCapPliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPly_Order_By>>;
  where?: Maybe<CapPly_Bool_Exp>;
};


/** columns and relationships of "C_MultiplyMaterial" */
export type C_MultiplyMaterialCapPlies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPly_Order_By>>;
  where?: Maybe<CapPly_Bool_Exp>;
};


/** columns and relationships of "C_MultiplyMaterial" */
export type C_MultiplyMaterialOtherPliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OtherPly_Order_By>>;
  where?: Maybe<OtherPly_Bool_Exp>;
};


/** columns and relationships of "C_MultiplyMaterial" */
export type C_MultiplyMaterialOtherPlies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OtherPly_Order_By>>;
  where?: Maybe<OtherPly_Bool_Exp>;
};

/** aggregated selection of "C_MultiplyMaterial" */
export type C_MultiplyMaterial_Aggregate = {
  __typename?: 'C_MultiplyMaterial_aggregate';
  aggregate?: Maybe<C_MultiplyMaterial_Aggregate_Fields>;
  nodes: Array<C_MultiplyMaterial>;
};

/** aggregate fields of "C_MultiplyMaterial" */
export type C_MultiplyMaterial_Aggregate_Fields = {
  __typename?: 'C_MultiplyMaterial_aggregate_fields';
  avg?: Maybe<C_MultiplyMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_MultiplyMaterial_Max_Fields>;
  min?: Maybe<C_MultiplyMaterial_Min_Fields>;
  stddev?: Maybe<C_MultiplyMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_MultiplyMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_MultiplyMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_MultiplyMaterial_Sum_Fields>;
  var_pop?: Maybe<C_MultiplyMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_MultiplyMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_MultiplyMaterial_Variance_Fields>;
};


/** aggregate fields of "C_MultiplyMaterial" */
export type C_MultiplyMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_MultiplyMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_MultiplyMaterial_Avg_Fields = {
  __typename?: 'C_MultiplyMaterial_avg_fields';
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_MultiplyMaterial". All fields are combined with a logical 'AND'. */
export type C_MultiplyMaterial_Bool_Exp = {
  BasePlies?: Maybe<BasePly_Bool_Exp>;
  BaseSheets?: Maybe<BaseSheet_Bool_Exp>;
  CapPlies?: Maybe<CapPly_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  MultiplyMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  MultiplyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  OtherPlies?: Maybe<OtherPly_Bool_Exp>;
  _and?: Maybe<Array<C_MultiplyMaterial_Bool_Exp>>;
  _not?: Maybe<C_MultiplyMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_MultiplyMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_MultiplyMaterial" */
export type C_MultiplyMaterial_If_Matched = {
  match_columns?: Array<C_MultiplyMaterial_Insert_Match_Column>;
  update_columns?: Array<C_MultiplyMaterial_Update_Column>;
  where?: Maybe<C_MultiplyMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_MultiplyMaterial" */
export type C_MultiplyMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MultiplyMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_MultiplyMaterial" */
export enum C_MultiplyMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MultiplyMaterialCode = 'MultiplyMaterialCode',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId'
}

/** aggregate max on columns */
export type C_MultiplyMaterial_Max_Fields = {
  __typename?: 'C_MultiplyMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MultiplyMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_MultiplyMaterial_Min_Fields = {
  __typename?: 'C_MultiplyMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MultiplyMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_MultiplyMaterial" */
export type C_MultiplyMaterial_Mutation_Response = {
  __typename?: 'C_MultiplyMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_MultiplyMaterial>;
};

/** Ordering options when selecting data from "C_MultiplyMaterial". */
export type C_MultiplyMaterial_Order_By = {
  BasePlies_aggregate?: Maybe<BasePly_Aggregate_Order_By>;
  BaseSheets_aggregate?: Maybe<BaseSheet_Aggregate_Order_By>;
  CapPlies_aggregate?: Maybe<CapPly_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  MultiplyMaterialCode?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  OtherPlies_aggregate?: Maybe<OtherPly_Aggregate_Order_By>;
};

/** primary key columns input for table: C_MultiplyMaterial */
export type C_MultiplyMaterial_Pk_Columns_Input = {
  MultiplyMaterialId: Scalars['Int'];
};

/** select columns of table "C_MultiplyMaterial" */
export enum C_MultiplyMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MultiplyMaterialCode = 'MultiplyMaterialCode',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId'
}

/** input type for updating data in table "C_MultiplyMaterial" */
export type C_MultiplyMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  MultiplyMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_MultiplyMaterial_Stddev_Fields = {
  __typename?: 'C_MultiplyMaterial_stddev_fields';
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_MultiplyMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_MultiplyMaterial_stddev_pop_fields';
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_MultiplyMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_MultiplyMaterial_stddev_samp_fields';
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_MultiplyMaterial_Sum_Fields = {
  __typename?: 'C_MultiplyMaterial_sum_fields';
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_MultiplyMaterial" */
export enum C_MultiplyMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  MultiplyMaterialCode = 'MultiplyMaterialCode'
}

/** aggregate var_pop on columns */
export type C_MultiplyMaterial_Var_Pop_Fields = {
  __typename?: 'C_MultiplyMaterial_var_pop_fields';
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_MultiplyMaterial_Var_Samp_Fields = {
  __typename?: 'C_MultiplyMaterial_var_samp_fields';
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_MultiplyMaterial_Variance_Fields = {
  __typename?: 'C_MultiplyMaterial_variance_fields';
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_PVApplicationMethod" */
export type C_PvApplicationMethod = {
  __typename?: 'C_PVApplicationMethod';
  Dsc: Scalars['CodeTableDescription'];
  PVApplicationMethodCode: Scalars['CodeTableValue'];
  PVApplicationMethodId: Scalars['Int'];
};

/** aggregated selection of "C_PVApplicationMethod" */
export type C_PvApplicationMethod_Aggregate = {
  __typename?: 'C_PVApplicationMethod_aggregate';
  aggregate?: Maybe<C_PvApplicationMethod_Aggregate_Fields>;
  nodes: Array<C_PvApplicationMethod>;
};

/** aggregate fields of "C_PVApplicationMethod" */
export type C_PvApplicationMethod_Aggregate_Fields = {
  __typename?: 'C_PVApplicationMethod_aggregate_fields';
  avg?: Maybe<C_PvApplicationMethod_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_PvApplicationMethod_Max_Fields>;
  min?: Maybe<C_PvApplicationMethod_Min_Fields>;
  stddev?: Maybe<C_PvApplicationMethod_Stddev_Fields>;
  stddev_pop?: Maybe<C_PvApplicationMethod_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_PvApplicationMethod_Stddev_Samp_Fields>;
  sum?: Maybe<C_PvApplicationMethod_Sum_Fields>;
  var_pop?: Maybe<C_PvApplicationMethod_Var_Pop_Fields>;
  var_samp?: Maybe<C_PvApplicationMethod_Var_Samp_Fields>;
  variance?: Maybe<C_PvApplicationMethod_Variance_Fields>;
};


/** aggregate fields of "C_PVApplicationMethod" */
export type C_PvApplicationMethod_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_PvApplicationMethod_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_PvApplicationMethod_Avg_Fields = {
  __typename?: 'C_PVApplicationMethod_avg_fields';
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_PVApplicationMethod". All fields are combined with a logical 'AND'. */
export type C_PvApplicationMethod_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  PVApplicationMethodCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  PVApplicationMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_PvApplicationMethod_Bool_Exp>>;
  _not?: Maybe<C_PvApplicationMethod_Bool_Exp>;
  _or?: Maybe<Array<C_PvApplicationMethod_Bool_Exp>>;
};

/** upsert condition type for table "C_PVApplicationMethod" */
export type C_PvApplicationMethod_If_Matched = {
  match_columns?: Array<C_PvApplicationMethod_Insert_Match_Column>;
  update_columns?: Array<C_PvApplicationMethod_Update_Column>;
  where?: Maybe<C_PvApplicationMethod_Bool_Exp>;
};

/** input type for inserting data into table "C_PVApplicationMethod" */
export type C_PvApplicationMethod_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  PVApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_PVApplicationMethod" */
export enum C_PvApplicationMethod_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  PvApplicationMethodCode = 'PVApplicationMethodCode',
  /** column name */
  PvApplicationMethodId = 'PVApplicationMethodId'
}

/** aggregate max on columns */
export type C_PvApplicationMethod_Max_Fields = {
  __typename?: 'C_PVApplicationMethod_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  PVApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_PvApplicationMethod_Min_Fields = {
  __typename?: 'C_PVApplicationMethod_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  PVApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_PVApplicationMethod" */
export type C_PvApplicationMethod_Mutation_Response = {
  __typename?: 'C_PVApplicationMethod_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_PvApplicationMethod>;
};

/** Ordering options when selecting data from "C_PVApplicationMethod". */
export type C_PvApplicationMethod_Order_By = {
  Dsc?: Maybe<Order_By>;
  PVApplicationMethodCode?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_PVApplicationMethod */
export type C_PvApplicationMethod_Pk_Columns_Input = {
  PVApplicationMethodId: Scalars['Int'];
};

/** select columns of table "C_PVApplicationMethod" */
export enum C_PvApplicationMethod_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  PvApplicationMethodCode = 'PVApplicationMethodCode',
  /** column name */
  PvApplicationMethodId = 'PVApplicationMethodId'
}

/** input type for updating data in table "C_PVApplicationMethod" */
export type C_PvApplicationMethod_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  PVApplicationMethodCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_PvApplicationMethod_Stddev_Fields = {
  __typename?: 'C_PVApplicationMethod_stddev_fields';
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_PvApplicationMethod_Stddev_Pop_Fields = {
  __typename?: 'C_PVApplicationMethod_stddev_pop_fields';
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_PvApplicationMethod_Stddev_Samp_Fields = {
  __typename?: 'C_PVApplicationMethod_stddev_samp_fields';
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_PvApplicationMethod_Sum_Fields = {
  __typename?: 'C_PVApplicationMethod_sum_fields';
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_PVApplicationMethod" */
export enum C_PvApplicationMethod_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  PvApplicationMethodCode = 'PVApplicationMethodCode'
}

/** aggregate var_pop on columns */
export type C_PvApplicationMethod_Var_Pop_Fields = {
  __typename?: 'C_PVApplicationMethod_var_pop_fields';
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_PvApplicationMethod_Var_Samp_Fields = {
  __typename?: 'C_PVApplicationMethod_var_samp_fields';
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_PvApplicationMethod_Variance_Fields = {
  __typename?: 'C_PVApplicationMethod_variance_fields';
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_PVHailRating" */
export type C_PvHailRating = {
  __typename?: 'C_PVHailRating';
  Dsc: Scalars['CodeTableDescription'];
  PVHailRatingCode: Scalars['CodeTableValue'];
  PVHailRatingId: Scalars['Int'];
};

/** aggregated selection of "C_PVHailRating" */
export type C_PvHailRating_Aggregate = {
  __typename?: 'C_PVHailRating_aggregate';
  aggregate?: Maybe<C_PvHailRating_Aggregate_Fields>;
  nodes: Array<C_PvHailRating>;
};

/** aggregate fields of "C_PVHailRating" */
export type C_PvHailRating_Aggregate_Fields = {
  __typename?: 'C_PVHailRating_aggregate_fields';
  avg?: Maybe<C_PvHailRating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_PvHailRating_Max_Fields>;
  min?: Maybe<C_PvHailRating_Min_Fields>;
  stddev?: Maybe<C_PvHailRating_Stddev_Fields>;
  stddev_pop?: Maybe<C_PvHailRating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_PvHailRating_Stddev_Samp_Fields>;
  sum?: Maybe<C_PvHailRating_Sum_Fields>;
  var_pop?: Maybe<C_PvHailRating_Var_Pop_Fields>;
  var_samp?: Maybe<C_PvHailRating_Var_Samp_Fields>;
  variance?: Maybe<C_PvHailRating_Variance_Fields>;
};


/** aggregate fields of "C_PVHailRating" */
export type C_PvHailRating_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_PvHailRating_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_PvHailRating_Avg_Fields = {
  __typename?: 'C_PVHailRating_avg_fields';
  PVHailRatingId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_PVHailRating". All fields are combined with a logical 'AND'. */
export type C_PvHailRating_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  PVHailRatingCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  PVHailRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_PvHailRating_Bool_Exp>>;
  _not?: Maybe<C_PvHailRating_Bool_Exp>;
  _or?: Maybe<Array<C_PvHailRating_Bool_Exp>>;
};

/** upsert condition type for table "C_PVHailRating" */
export type C_PvHailRating_If_Matched = {
  match_columns?: Array<C_PvHailRating_Insert_Match_Column>;
  update_columns?: Array<C_PvHailRating_Update_Column>;
  where?: Maybe<C_PvHailRating_Bool_Exp>;
};

/** input type for inserting data into table "C_PVHailRating" */
export type C_PvHailRating_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  PVHailRatingCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_PVHailRating" */
export enum C_PvHailRating_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  PvHailRatingCode = 'PVHailRatingCode',
  /** column name */
  PvHailRatingId = 'PVHailRatingId'
}

/** aggregate max on columns */
export type C_PvHailRating_Max_Fields = {
  __typename?: 'C_PVHailRating_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  PVHailRatingCode?: Maybe<Scalars['CodeTableValue']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_PvHailRating_Min_Fields = {
  __typename?: 'C_PVHailRating_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  PVHailRatingCode?: Maybe<Scalars['CodeTableValue']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_PVHailRating" */
export type C_PvHailRating_Mutation_Response = {
  __typename?: 'C_PVHailRating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_PvHailRating>;
};

/** Ordering options when selecting data from "C_PVHailRating". */
export type C_PvHailRating_Order_By = {
  Dsc?: Maybe<Order_By>;
  PVHailRatingCode?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_PVHailRating */
export type C_PvHailRating_Pk_Columns_Input = {
  PVHailRatingId: Scalars['Int'];
};

/** select columns of table "C_PVHailRating" */
export enum C_PvHailRating_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  PvHailRatingCode = 'PVHailRatingCode',
  /** column name */
  PvHailRatingId = 'PVHailRatingId'
}

/** input type for updating data in table "C_PVHailRating" */
export type C_PvHailRating_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  PVHailRatingCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_PvHailRating_Stddev_Fields = {
  __typename?: 'C_PVHailRating_stddev_fields';
  PVHailRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_PvHailRating_Stddev_Pop_Fields = {
  __typename?: 'C_PVHailRating_stddev_pop_fields';
  PVHailRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_PvHailRating_Stddev_Samp_Fields = {
  __typename?: 'C_PVHailRating_stddev_samp_fields';
  PVHailRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_PvHailRating_Sum_Fields = {
  __typename?: 'C_PVHailRating_sum_fields';
  PVHailRatingId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_PVHailRating" */
export enum C_PvHailRating_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  PvHailRatingCode = 'PVHailRatingCode'
}

/** aggregate var_pop on columns */
export type C_PvHailRating_Var_Pop_Fields = {
  __typename?: 'C_PVHailRating_var_pop_fields';
  PVHailRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_PvHailRating_Var_Samp_Fields = {
  __typename?: 'C_PVHailRating_var_samp_fields';
  PVHailRatingId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_PvHailRating_Variance_Fields = {
  __typename?: 'C_PVHailRating_variance_fields';
  PVHailRatingId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_PerimeterFlashingType" */
export type C_PerimeterFlashingType = {
  __typename?: 'C_PerimeterFlashingType';
  Dsc: Scalars['CodeTableDescription'];
  PerimeterFlashingTypeCode: Scalars['CodeTableValue'];
  PerimeterFlashingTypeId: Scalars['Int'];
};

/** aggregated selection of "C_PerimeterFlashingType" */
export type C_PerimeterFlashingType_Aggregate = {
  __typename?: 'C_PerimeterFlashingType_aggregate';
  aggregate?: Maybe<C_PerimeterFlashingType_Aggregate_Fields>;
  nodes: Array<C_PerimeterFlashingType>;
};

/** aggregate fields of "C_PerimeterFlashingType" */
export type C_PerimeterFlashingType_Aggregate_Fields = {
  __typename?: 'C_PerimeterFlashingType_aggregate_fields';
  avg?: Maybe<C_PerimeterFlashingType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_PerimeterFlashingType_Max_Fields>;
  min?: Maybe<C_PerimeterFlashingType_Min_Fields>;
  stddev?: Maybe<C_PerimeterFlashingType_Stddev_Fields>;
  stddev_pop?: Maybe<C_PerimeterFlashingType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_PerimeterFlashingType_Stddev_Samp_Fields>;
  sum?: Maybe<C_PerimeterFlashingType_Sum_Fields>;
  var_pop?: Maybe<C_PerimeterFlashingType_Var_Pop_Fields>;
  var_samp?: Maybe<C_PerimeterFlashingType_Var_Samp_Fields>;
  variance?: Maybe<C_PerimeterFlashingType_Variance_Fields>;
};


/** aggregate fields of "C_PerimeterFlashingType" */
export type C_PerimeterFlashingType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_PerimeterFlashingType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_PerimeterFlashingType_Avg_Fields = {
  __typename?: 'C_PerimeterFlashingType_avg_fields';
  PerimeterFlashingTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_PerimeterFlashingType". All fields are combined with a logical 'AND'. */
export type C_PerimeterFlashingType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  PerimeterFlashingTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  PerimeterFlashingTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_PerimeterFlashingType_Bool_Exp>>;
  _not?: Maybe<C_PerimeterFlashingType_Bool_Exp>;
  _or?: Maybe<Array<C_PerimeterFlashingType_Bool_Exp>>;
};

/** upsert condition type for table "C_PerimeterFlashingType" */
export type C_PerimeterFlashingType_If_Matched = {
  match_columns?: Array<C_PerimeterFlashingType_Insert_Match_Column>;
  update_columns?: Array<C_PerimeterFlashingType_Update_Column>;
  where?: Maybe<C_PerimeterFlashingType_Bool_Exp>;
};

/** input type for inserting data into table "C_PerimeterFlashingType" */
export type C_PerimeterFlashingType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  PerimeterFlashingTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_PerimeterFlashingType" */
export enum C_PerimeterFlashingType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  PerimeterFlashingTypeCode = 'PerimeterFlashingTypeCode',
  /** column name */
  PerimeterFlashingTypeId = 'PerimeterFlashingTypeId'
}

/** aggregate max on columns */
export type C_PerimeterFlashingType_Max_Fields = {
  __typename?: 'C_PerimeterFlashingType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  PerimeterFlashingTypeCode?: Maybe<Scalars['CodeTableValue']>;
  PerimeterFlashingTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_PerimeterFlashingType_Min_Fields = {
  __typename?: 'C_PerimeterFlashingType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  PerimeterFlashingTypeCode?: Maybe<Scalars['CodeTableValue']>;
  PerimeterFlashingTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_PerimeterFlashingType" */
export type C_PerimeterFlashingType_Mutation_Response = {
  __typename?: 'C_PerimeterFlashingType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_PerimeterFlashingType>;
};

/** Ordering options when selecting data from "C_PerimeterFlashingType". */
export type C_PerimeterFlashingType_Order_By = {
  Dsc?: Maybe<Order_By>;
  PerimeterFlashingTypeCode?: Maybe<Order_By>;
  PerimeterFlashingTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_PerimeterFlashingType */
export type C_PerimeterFlashingType_Pk_Columns_Input = {
  PerimeterFlashingTypeId: Scalars['Int'];
};

/** select columns of table "C_PerimeterFlashingType" */
export enum C_PerimeterFlashingType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  PerimeterFlashingTypeCode = 'PerimeterFlashingTypeCode',
  /** column name */
  PerimeterFlashingTypeId = 'PerimeterFlashingTypeId'
}

/** input type for updating data in table "C_PerimeterFlashingType" */
export type C_PerimeterFlashingType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  PerimeterFlashingTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_PerimeterFlashingType_Stddev_Fields = {
  __typename?: 'C_PerimeterFlashingType_stddev_fields';
  PerimeterFlashingTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_PerimeterFlashingType_Stddev_Pop_Fields = {
  __typename?: 'C_PerimeterFlashingType_stddev_pop_fields';
  PerimeterFlashingTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_PerimeterFlashingType_Stddev_Samp_Fields = {
  __typename?: 'C_PerimeterFlashingType_stddev_samp_fields';
  PerimeterFlashingTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_PerimeterFlashingType_Sum_Fields = {
  __typename?: 'C_PerimeterFlashingType_sum_fields';
  PerimeterFlashingTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_PerimeterFlashingType" */
export enum C_PerimeterFlashingType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  PerimeterFlashingTypeCode = 'PerimeterFlashingTypeCode'
}

/** aggregate var_pop on columns */
export type C_PerimeterFlashingType_Var_Pop_Fields = {
  __typename?: 'C_PerimeterFlashingType_var_pop_fields';
  PerimeterFlashingTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_PerimeterFlashingType_Var_Samp_Fields = {
  __typename?: 'C_PerimeterFlashingType_var_samp_fields';
  PerimeterFlashingTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_PerimeterFlashingType_Variance_Fields = {
  __typename?: 'C_PerimeterFlashingType_variance_fields';
  PerimeterFlashingTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_PressureZone" */
export type C_PressureZone = {
  __typename?: 'C_PressureZone';
  PressureZoneCode: Scalars['String'];
  PressureZoneId: Scalars['Int'];
  PressureZoneName: Scalars['String'];
  /** An array relationship */
  RoofAreaWindPressures: Array<RoofAreaWindPressure>;
  /** An aggregate relationship */
  RoofAreaWindPressures_aggregate: RoofAreaWindPressure_Aggregate;
};


/** columns and relationships of "C_PressureZone" */
export type C_PressureZoneRoofAreaWindPressuresArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAreaWindPressure_Order_By>>;
  where?: Maybe<RoofAreaWindPressure_Bool_Exp>;
};


/** columns and relationships of "C_PressureZone" */
export type C_PressureZoneRoofAreaWindPressures_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAreaWindPressure_Order_By>>;
  where?: Maybe<RoofAreaWindPressure_Bool_Exp>;
};

/** aggregated selection of "C_PressureZone" */
export type C_PressureZone_Aggregate = {
  __typename?: 'C_PressureZone_aggregate';
  aggregate?: Maybe<C_PressureZone_Aggregate_Fields>;
  nodes: Array<C_PressureZone>;
};

/** aggregate fields of "C_PressureZone" */
export type C_PressureZone_Aggregate_Fields = {
  __typename?: 'C_PressureZone_aggregate_fields';
  avg?: Maybe<C_PressureZone_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_PressureZone_Max_Fields>;
  min?: Maybe<C_PressureZone_Min_Fields>;
  stddev?: Maybe<C_PressureZone_Stddev_Fields>;
  stddev_pop?: Maybe<C_PressureZone_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_PressureZone_Stddev_Samp_Fields>;
  sum?: Maybe<C_PressureZone_Sum_Fields>;
  var_pop?: Maybe<C_PressureZone_Var_Pop_Fields>;
  var_samp?: Maybe<C_PressureZone_Var_Samp_Fields>;
  variance?: Maybe<C_PressureZone_Variance_Fields>;
};


/** aggregate fields of "C_PressureZone" */
export type C_PressureZone_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_PressureZone_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_PressureZone_Avg_Fields = {
  __typename?: 'C_PressureZone_avg_fields';
  PressureZoneId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_PressureZone". All fields are combined with a logical 'AND'. */
export type C_PressureZone_Bool_Exp = {
  PressureZoneCode?: Maybe<String_Mssql_Comparison_Exp>;
  PressureZoneId?: Maybe<Int_Mssql_Comparison_Exp>;
  PressureZoneName?: Maybe<String_Mssql_Comparison_Exp>;
  RoofAreaWindPressures?: Maybe<RoofAreaWindPressure_Bool_Exp>;
  _and?: Maybe<Array<C_PressureZone_Bool_Exp>>;
  _not?: Maybe<C_PressureZone_Bool_Exp>;
  _or?: Maybe<Array<C_PressureZone_Bool_Exp>>;
};

/** upsert condition type for table "C_PressureZone" */
export type C_PressureZone_If_Matched = {
  match_columns?: Array<C_PressureZone_Insert_Match_Column>;
  update_columns?: Array<C_PressureZone_Update_Column>;
  where?: Maybe<C_PressureZone_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "C_PressureZone" */
export type C_PressureZone_Inc_Input = {
  PressureZoneId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "C_PressureZone" */
export type C_PressureZone_Insert_Input = {
  PressureZoneCode?: Maybe<Scalars['String']>;
  PressureZoneId?: Maybe<Scalars['Int']>;
  PressureZoneName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "C_PressureZone" */
export enum C_PressureZone_Insert_Match_Column {
  /** column name */
  PressureZoneCode = 'PressureZoneCode',
  /** column name */
  PressureZoneId = 'PressureZoneId',
  /** column name */
  PressureZoneName = 'PressureZoneName'
}

/** aggregate max on columns */
export type C_PressureZone_Max_Fields = {
  __typename?: 'C_PressureZone_max_fields';
  PressureZoneCode?: Maybe<Scalars['String']>;
  PressureZoneId?: Maybe<Scalars['Int']>;
  PressureZoneName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type C_PressureZone_Min_Fields = {
  __typename?: 'C_PressureZone_min_fields';
  PressureZoneCode?: Maybe<Scalars['String']>;
  PressureZoneId?: Maybe<Scalars['Int']>;
  PressureZoneName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "C_PressureZone" */
export type C_PressureZone_Mutation_Response = {
  __typename?: 'C_PressureZone_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_PressureZone>;
};

/** Ordering options when selecting data from "C_PressureZone". */
export type C_PressureZone_Order_By = {
  PressureZoneCode?: Maybe<Order_By>;
  PressureZoneId?: Maybe<Order_By>;
  PressureZoneName?: Maybe<Order_By>;
  RoofAreaWindPressures_aggregate?: Maybe<RoofAreaWindPressure_Aggregate_Order_By>;
};

/** primary key columns input for table: C_PressureZone */
export type C_PressureZone_Pk_Columns_Input = {
  PressureZoneId: Scalars['Int'];
};

/** select columns of table "C_PressureZone" */
export enum C_PressureZone_Select_Column {
  /** column name */
  PressureZoneCode = 'PressureZoneCode',
  /** column name */
  PressureZoneId = 'PressureZoneId',
  /** column name */
  PressureZoneName = 'PressureZoneName'
}

/** input type for updating data in table "C_PressureZone" */
export type C_PressureZone_Set_Input = {
  PressureZoneCode?: Maybe<Scalars['String']>;
  PressureZoneId?: Maybe<Scalars['Int']>;
  PressureZoneName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type C_PressureZone_Stddev_Fields = {
  __typename?: 'C_PressureZone_stddev_fields';
  PressureZoneId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_PressureZone_Stddev_Pop_Fields = {
  __typename?: 'C_PressureZone_stddev_pop_fields';
  PressureZoneId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_PressureZone_Stddev_Samp_Fields = {
  __typename?: 'C_PressureZone_stddev_samp_fields';
  PressureZoneId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_PressureZone_Sum_Fields = {
  __typename?: 'C_PressureZone_sum_fields';
  PressureZoneId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_PressureZone" */
export enum C_PressureZone_Update_Column {
  /** column name */
  PressureZoneCode = 'PressureZoneCode',
  /** column name */
  PressureZoneId = 'PressureZoneId',
  /** column name */
  PressureZoneName = 'PressureZoneName'
}

/** aggregate var_pop on columns */
export type C_PressureZone_Var_Pop_Fields = {
  __typename?: 'C_PressureZone_var_pop_fields';
  PressureZoneId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_PressureZone_Var_Samp_Fields = {
  __typename?: 'C_PressureZone_var_samp_fields';
  PressureZoneId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_PressureZone_Variance_Fields = {
  __typename?: 'C_PressureZone_variance_fields';
  PressureZoneId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ProductApprovedUse" */
export type C_ProductApprovedUse = {
  __typename?: 'C_ProductApprovedUse';
  ApprovedUseCode: Scalars['CodeTableValue'];
  ApprovedUseId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
};

/** aggregated selection of "C_ProductApprovedUse" */
export type C_ProductApprovedUse_Aggregate = {
  __typename?: 'C_ProductApprovedUse_aggregate';
  aggregate?: Maybe<C_ProductApprovedUse_Aggregate_Fields>;
  nodes: Array<C_ProductApprovedUse>;
};

/** aggregate fields of "C_ProductApprovedUse" */
export type C_ProductApprovedUse_Aggregate_Fields = {
  __typename?: 'C_ProductApprovedUse_aggregate_fields';
  avg?: Maybe<C_ProductApprovedUse_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ProductApprovedUse_Max_Fields>;
  min?: Maybe<C_ProductApprovedUse_Min_Fields>;
  stddev?: Maybe<C_ProductApprovedUse_Stddev_Fields>;
  stddev_pop?: Maybe<C_ProductApprovedUse_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ProductApprovedUse_Stddev_Samp_Fields>;
  sum?: Maybe<C_ProductApprovedUse_Sum_Fields>;
  var_pop?: Maybe<C_ProductApprovedUse_Var_Pop_Fields>;
  var_samp?: Maybe<C_ProductApprovedUse_Var_Samp_Fields>;
  variance?: Maybe<C_ProductApprovedUse_Variance_Fields>;
};


/** aggregate fields of "C_ProductApprovedUse" */
export type C_ProductApprovedUse_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ProductApprovedUse_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ProductApprovedUse_Avg_Fields = {
  __typename?: 'C_ProductApprovedUse_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ProductApprovedUse". All fields are combined with a logical 'AND'. */
export type C_ProductApprovedUse_Bool_Exp = {
  ApprovedUseCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_ProductApprovedUse_Bool_Exp>>;
  _not?: Maybe<C_ProductApprovedUse_Bool_Exp>;
  _or?: Maybe<Array<C_ProductApprovedUse_Bool_Exp>>;
};

/** upsert condition type for table "C_ProductApprovedUse" */
export type C_ProductApprovedUse_If_Matched = {
  match_columns?: Array<C_ProductApprovedUse_Insert_Match_Column>;
  update_columns?: Array<C_ProductApprovedUse_Update_Column>;
  where?: Maybe<C_ProductApprovedUse_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "C_ProductApprovedUse" */
export type C_ProductApprovedUse_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "C_ProductApprovedUse" */
export type C_ProductApprovedUse_Insert_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_ProductApprovedUse" */
export enum C_ProductApprovedUse_Insert_Match_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_ProductApprovedUse_Max_Fields = {
  __typename?: 'C_ProductApprovedUse_max_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_ProductApprovedUse_Min_Fields = {
  __typename?: 'C_ProductApprovedUse_min_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_ProductApprovedUse" */
export type C_ProductApprovedUse_Mutation_Response = {
  __typename?: 'C_ProductApprovedUse_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ProductApprovedUse>;
};

/** Ordering options when selecting data from "C_ProductApprovedUse". */
export type C_ProductApprovedUse_Order_By = {
  ApprovedUseCode?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** select columns of table "C_ProductApprovedUse" */
export enum C_ProductApprovedUse_Select_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_ProductApprovedUse" */
export type C_ProductApprovedUse_Set_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_ProductApprovedUse_Stddev_Fields = {
  __typename?: 'C_ProductApprovedUse_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ProductApprovedUse_Stddev_Pop_Fields = {
  __typename?: 'C_ProductApprovedUse_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ProductApprovedUse_Stddev_Samp_Fields = {
  __typename?: 'C_ProductApprovedUse_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ProductApprovedUse_Sum_Fields = {
  __typename?: 'C_ProductApprovedUse_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ProductApprovedUse" */
export enum C_ProductApprovedUse_Update_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_ProductApprovedUse_Var_Pop_Fields = {
  __typename?: 'C_ProductApprovedUse_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ProductApprovedUse_Var_Samp_Fields = {
  __typename?: 'C_ProductApprovedUse_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ProductApprovedUse_Variance_Fields = {
  __typename?: 'C_ProductApprovedUse_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ProjectRole" */
export type C_ProjectRole = {
  __typename?: 'C_ProjectRole';
  Dsc: Scalars['CodeTableDescription'];
  ProjectRoleCode: Scalars['CodeTableValue'];
  ProjectRoleId: Scalars['Int'];
  /** An array relationship */
  R_ProjectContacts: Array<R_ProjectContact>;
  /** An aggregate relationship */
  R_ProjectContacts_aggregate: R_ProjectContact_Aggregate;
  /** An array relationship */
  R_RoofAreaContacts: Array<R_RoofAreaContact>;
  /** An aggregate relationship */
  R_RoofAreaContacts_aggregate: R_RoofAreaContact_Aggregate;
};


/** columns and relationships of "C_ProjectRole" */
export type C_ProjectRoleR_ProjectContactsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectContact_Order_By>>;
  where?: Maybe<R_ProjectContact_Bool_Exp>;
};


/** columns and relationships of "C_ProjectRole" */
export type C_ProjectRoleR_ProjectContacts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectContact_Order_By>>;
  where?: Maybe<R_ProjectContact_Bool_Exp>;
};


/** columns and relationships of "C_ProjectRole" */
export type C_ProjectRoleR_RoofAreaContactsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaContact_Order_By>>;
  where?: Maybe<R_RoofAreaContact_Bool_Exp>;
};


/** columns and relationships of "C_ProjectRole" */
export type C_ProjectRoleR_RoofAreaContacts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaContact_Order_By>>;
  where?: Maybe<R_RoofAreaContact_Bool_Exp>;
};

/** aggregated selection of "C_ProjectRole" */
export type C_ProjectRole_Aggregate = {
  __typename?: 'C_ProjectRole_aggregate';
  aggregate?: Maybe<C_ProjectRole_Aggregate_Fields>;
  nodes: Array<C_ProjectRole>;
};

/** aggregate fields of "C_ProjectRole" */
export type C_ProjectRole_Aggregate_Fields = {
  __typename?: 'C_ProjectRole_aggregate_fields';
  avg?: Maybe<C_ProjectRole_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ProjectRole_Max_Fields>;
  min?: Maybe<C_ProjectRole_Min_Fields>;
  stddev?: Maybe<C_ProjectRole_Stddev_Fields>;
  stddev_pop?: Maybe<C_ProjectRole_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ProjectRole_Stddev_Samp_Fields>;
  sum?: Maybe<C_ProjectRole_Sum_Fields>;
  var_pop?: Maybe<C_ProjectRole_Var_Pop_Fields>;
  var_samp?: Maybe<C_ProjectRole_Var_Samp_Fields>;
  variance?: Maybe<C_ProjectRole_Variance_Fields>;
};


/** aggregate fields of "C_ProjectRole" */
export type C_ProjectRole_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ProjectRole_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ProjectRole_Avg_Fields = {
  __typename?: 'C_ProjectRole_avg_fields';
  ProjectRoleId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ProjectRole". All fields are combined with a logical 'AND'. */
export type C_ProjectRole_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  ProjectRoleCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ProjectRoleId?: Maybe<Int_Mssql_Comparison_Exp>;
  R_ProjectContacts?: Maybe<R_ProjectContact_Bool_Exp>;
  R_RoofAreaContacts?: Maybe<R_RoofAreaContact_Bool_Exp>;
  _and?: Maybe<Array<C_ProjectRole_Bool_Exp>>;
  _not?: Maybe<C_ProjectRole_Bool_Exp>;
  _or?: Maybe<Array<C_ProjectRole_Bool_Exp>>;
};

/** upsert condition type for table "C_ProjectRole" */
export type C_ProjectRole_If_Matched = {
  match_columns?: Array<C_ProjectRole_Insert_Match_Column>;
  update_columns?: Array<C_ProjectRole_Update_Column>;
  where?: Maybe<C_ProjectRole_Bool_Exp>;
};

/** input type for inserting data into table "C_ProjectRole" */
export type C_ProjectRole_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ProjectRoleCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ProjectRole" */
export enum C_ProjectRole_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ProjectRoleCode = 'ProjectRoleCode',
  /** column name */
  ProjectRoleId = 'ProjectRoleId'
}

/** aggregate max on columns */
export type C_ProjectRole_Max_Fields = {
  __typename?: 'C_ProjectRole_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ProjectRoleCode?: Maybe<Scalars['CodeTableValue']>;
  ProjectRoleId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ProjectRole_Min_Fields = {
  __typename?: 'C_ProjectRole_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ProjectRoleCode?: Maybe<Scalars['CodeTableValue']>;
  ProjectRoleId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ProjectRole" */
export type C_ProjectRole_Mutation_Response = {
  __typename?: 'C_ProjectRole_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ProjectRole>;
};

/** Ordering options when selecting data from "C_ProjectRole". */
export type C_ProjectRole_Order_By = {
  Dsc?: Maybe<Order_By>;
  ProjectRoleCode?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
  R_ProjectContacts_aggregate?: Maybe<R_ProjectContact_Aggregate_Order_By>;
  R_RoofAreaContacts_aggregate?: Maybe<R_RoofAreaContact_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ProjectRole */
export type C_ProjectRole_Pk_Columns_Input = {
  ProjectRoleId: Scalars['Int'];
};

/** select columns of table "C_ProjectRole" */
export enum C_ProjectRole_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ProjectRoleCode = 'ProjectRoleCode',
  /** column name */
  ProjectRoleId = 'ProjectRoleId'
}

/** input type for updating data in table "C_ProjectRole" */
export type C_ProjectRole_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ProjectRoleCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ProjectRole_Stddev_Fields = {
  __typename?: 'C_ProjectRole_stddev_fields';
  ProjectRoleId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ProjectRole_Stddev_Pop_Fields = {
  __typename?: 'C_ProjectRole_stddev_pop_fields';
  ProjectRoleId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ProjectRole_Stddev_Samp_Fields = {
  __typename?: 'C_ProjectRole_stddev_samp_fields';
  ProjectRoleId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ProjectRole_Sum_Fields = {
  __typename?: 'C_ProjectRole_sum_fields';
  ProjectRoleId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ProjectRole" */
export enum C_ProjectRole_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ProjectRoleCode = 'ProjectRoleCode'
}

/** aggregate var_pop on columns */
export type C_ProjectRole_Var_Pop_Fields = {
  __typename?: 'C_ProjectRole_var_pop_fields';
  ProjectRoleId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ProjectRole_Var_Samp_Fields = {
  __typename?: 'C_ProjectRole_var_samp_fields';
  ProjectRoleId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ProjectRole_Variance_Fields = {
  __typename?: 'C_ProjectRole_variance_fields';
  ProjectRoleId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_Reinforcement" */
export type C_Reinforcement = {
  __typename?: 'C_Reinforcement';
  /** An array relationship */
  BasePlies: Array<BasePly>;
  /** An aggregate relationship */
  BasePlies_aggregate: BasePly_Aggregate;
  /** An array relationship */
  BaseSheets: Array<BaseSheet>;
  /** An aggregate relationship */
  BaseSheets_aggregate: BaseSheet_Aggregate;
  /** An array relationship */
  CapPlies: Array<CapPly>;
  /** An aggregate relationship */
  CapPlies_aggregate: CapPly_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  OtherPlies: Array<OtherPly>;
  /** An aggregate relationship */
  OtherPlies_aggregate: OtherPly_Aggregate;
  ReinforcementCode: Scalars['CodeTableValue'];
  ReinforcementId: Scalars['Int'];
  /** An array relationship */
  SinglePlyCovers: Array<SinglePlyCover>;
  /** An aggregate relationship */
  SinglePlyCovers_aggregate: SinglePlyCover_Aggregate;
};


/** columns and relationships of "C_Reinforcement" */
export type C_ReinforcementBasePliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BasePly_Order_By>>;
  where?: Maybe<BasePly_Bool_Exp>;
};


/** columns and relationships of "C_Reinforcement" */
export type C_ReinforcementBasePlies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BasePly_Order_By>>;
  where?: Maybe<BasePly_Bool_Exp>;
};


/** columns and relationships of "C_Reinforcement" */
export type C_ReinforcementBaseSheetsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseSheet_Order_By>>;
  where?: Maybe<BaseSheet_Bool_Exp>;
};


/** columns and relationships of "C_Reinforcement" */
export type C_ReinforcementBaseSheets_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseSheet_Order_By>>;
  where?: Maybe<BaseSheet_Bool_Exp>;
};


/** columns and relationships of "C_Reinforcement" */
export type C_ReinforcementCapPliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPly_Order_By>>;
  where?: Maybe<CapPly_Bool_Exp>;
};


/** columns and relationships of "C_Reinforcement" */
export type C_ReinforcementCapPlies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPly_Order_By>>;
  where?: Maybe<CapPly_Bool_Exp>;
};


/** columns and relationships of "C_Reinforcement" */
export type C_ReinforcementOtherPliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OtherPly_Order_By>>;
  where?: Maybe<OtherPly_Bool_Exp>;
};


/** columns and relationships of "C_Reinforcement" */
export type C_ReinforcementOtherPlies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OtherPly_Order_By>>;
  where?: Maybe<OtherPly_Bool_Exp>;
};


/** columns and relationships of "C_Reinforcement" */
export type C_ReinforcementSinglePlyCoversArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyCover_Order_By>>;
  where?: Maybe<SinglePlyCover_Bool_Exp>;
};


/** columns and relationships of "C_Reinforcement" */
export type C_ReinforcementSinglePlyCovers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyCover_Order_By>>;
  where?: Maybe<SinglePlyCover_Bool_Exp>;
};

/** aggregated selection of "C_Reinforcement" */
export type C_Reinforcement_Aggregate = {
  __typename?: 'C_Reinforcement_aggregate';
  aggregate?: Maybe<C_Reinforcement_Aggregate_Fields>;
  nodes: Array<C_Reinforcement>;
};

/** aggregate fields of "C_Reinforcement" */
export type C_Reinforcement_Aggregate_Fields = {
  __typename?: 'C_Reinforcement_aggregate_fields';
  avg?: Maybe<C_Reinforcement_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_Reinforcement_Max_Fields>;
  min?: Maybe<C_Reinforcement_Min_Fields>;
  stddev?: Maybe<C_Reinforcement_Stddev_Fields>;
  stddev_pop?: Maybe<C_Reinforcement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_Reinforcement_Stddev_Samp_Fields>;
  sum?: Maybe<C_Reinforcement_Sum_Fields>;
  var_pop?: Maybe<C_Reinforcement_Var_Pop_Fields>;
  var_samp?: Maybe<C_Reinforcement_Var_Samp_Fields>;
  variance?: Maybe<C_Reinforcement_Variance_Fields>;
};


/** aggregate fields of "C_Reinforcement" */
export type C_Reinforcement_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_Reinforcement_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_Reinforcement_Avg_Fields = {
  __typename?: 'C_Reinforcement_avg_fields';
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_Reinforcement". All fields are combined with a logical 'AND'. */
export type C_Reinforcement_Bool_Exp = {
  BasePlies?: Maybe<BasePly_Bool_Exp>;
  BaseSheets?: Maybe<BaseSheet_Bool_Exp>;
  CapPlies?: Maybe<CapPly_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  OtherPlies?: Maybe<OtherPly_Bool_Exp>;
  ReinforcementCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ReinforcementId?: Maybe<Int_Mssql_Comparison_Exp>;
  SinglePlyCovers?: Maybe<SinglePlyCover_Bool_Exp>;
  _and?: Maybe<Array<C_Reinforcement_Bool_Exp>>;
  _not?: Maybe<C_Reinforcement_Bool_Exp>;
  _or?: Maybe<Array<C_Reinforcement_Bool_Exp>>;
};

/** upsert condition type for table "C_Reinforcement" */
export type C_Reinforcement_If_Matched = {
  match_columns?: Array<C_Reinforcement_Insert_Match_Column>;
  update_columns?: Array<C_Reinforcement_Update_Column>;
  where?: Maybe<C_Reinforcement_Bool_Exp>;
};

/** input type for inserting data into table "C_Reinforcement" */
export type C_Reinforcement_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ReinforcementCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_Reinforcement" */
export enum C_Reinforcement_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ReinforcementCode = 'ReinforcementCode',
  /** column name */
  ReinforcementId = 'ReinforcementId'
}

/** aggregate max on columns */
export type C_Reinforcement_Max_Fields = {
  __typename?: 'C_Reinforcement_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ReinforcementCode?: Maybe<Scalars['CodeTableValue']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_Reinforcement_Min_Fields = {
  __typename?: 'C_Reinforcement_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ReinforcementCode?: Maybe<Scalars['CodeTableValue']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_Reinforcement" */
export type C_Reinforcement_Mutation_Response = {
  __typename?: 'C_Reinforcement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_Reinforcement>;
};

/** Ordering options when selecting data from "C_Reinforcement". */
export type C_Reinforcement_Order_By = {
  BasePlies_aggregate?: Maybe<BasePly_Aggregate_Order_By>;
  BaseSheets_aggregate?: Maybe<BaseSheet_Aggregate_Order_By>;
  CapPlies_aggregate?: Maybe<CapPly_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  OtherPlies_aggregate?: Maybe<OtherPly_Aggregate_Order_By>;
  ReinforcementCode?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyCovers_aggregate?: Maybe<SinglePlyCover_Aggregate_Order_By>;
};

/** primary key columns input for table: C_Reinforcement */
export type C_Reinforcement_Pk_Columns_Input = {
  ReinforcementId: Scalars['Int'];
};

/** select columns of table "C_Reinforcement" */
export enum C_Reinforcement_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ReinforcementCode = 'ReinforcementCode',
  /** column name */
  ReinforcementId = 'ReinforcementId'
}

/** input type for updating data in table "C_Reinforcement" */
export type C_Reinforcement_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ReinforcementCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_Reinforcement_Stddev_Fields = {
  __typename?: 'C_Reinforcement_stddev_fields';
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_Reinforcement_Stddev_Pop_Fields = {
  __typename?: 'C_Reinforcement_stddev_pop_fields';
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_Reinforcement_Stddev_Samp_Fields = {
  __typename?: 'C_Reinforcement_stddev_samp_fields';
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_Reinforcement_Sum_Fields = {
  __typename?: 'C_Reinforcement_sum_fields';
  ReinforcementId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_Reinforcement" */
export enum C_Reinforcement_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ReinforcementCode = 'ReinforcementCode'
}

/** aggregate var_pop on columns */
export type C_Reinforcement_Var_Pop_Fields = {
  __typename?: 'C_Reinforcement_var_pop_fields';
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_Reinforcement_Var_Samp_Fields = {
  __typename?: 'C_Reinforcement_var_samp_fields';
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_Reinforcement_Variance_Fields = {
  __typename?: 'C_Reinforcement_variance_fields';
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_RetarderMaterial" */
export type C_RetarderMaterial = {
  __typename?: 'C_RetarderMaterial';
  /** An array relationship */
  AirRetarders: Array<AirRetarder>;
  /** An aggregate relationship */
  AirRetarders_aggregate: AirRetarder_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  RetarderMaterialCode: Scalars['CodeTableValue'];
  RetarderMaterialId: Scalars['Int'];
  /** An array relationship */
  SeparatorSheets: Array<SeparatorSheet>;
  /** An aggregate relationship */
  SeparatorSheets_aggregate: SeparatorSheet_Aggregate;
  /** An array relationship */
  VaporRetarders: Array<VaporRetarder>;
  /** An aggregate relationship */
  VaporRetarders_aggregate: VaporRetarder_Aggregate;
};


/** columns and relationships of "C_RetarderMaterial" */
export type C_RetarderMaterialAirRetardersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AirRetarder_Order_By>>;
  where?: Maybe<AirRetarder_Bool_Exp>;
};


/** columns and relationships of "C_RetarderMaterial" */
export type C_RetarderMaterialAirRetarders_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AirRetarder_Order_By>>;
  where?: Maybe<AirRetarder_Bool_Exp>;
};


/** columns and relationships of "C_RetarderMaterial" */
export type C_RetarderMaterialSeparatorSheetsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeparatorSheet_Order_By>>;
  where?: Maybe<SeparatorSheet_Bool_Exp>;
};


/** columns and relationships of "C_RetarderMaterial" */
export type C_RetarderMaterialSeparatorSheets_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeparatorSheet_Order_By>>;
  where?: Maybe<SeparatorSheet_Bool_Exp>;
};


/** columns and relationships of "C_RetarderMaterial" */
export type C_RetarderMaterialVaporRetardersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaporRetarder_Order_By>>;
  where?: Maybe<VaporRetarder_Bool_Exp>;
};


/** columns and relationships of "C_RetarderMaterial" */
export type C_RetarderMaterialVaporRetarders_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaporRetarder_Order_By>>;
  where?: Maybe<VaporRetarder_Bool_Exp>;
};

/** aggregated selection of "C_RetarderMaterial" */
export type C_RetarderMaterial_Aggregate = {
  __typename?: 'C_RetarderMaterial_aggregate';
  aggregate?: Maybe<C_RetarderMaterial_Aggregate_Fields>;
  nodes: Array<C_RetarderMaterial>;
};

/** aggregate fields of "C_RetarderMaterial" */
export type C_RetarderMaterial_Aggregate_Fields = {
  __typename?: 'C_RetarderMaterial_aggregate_fields';
  avg?: Maybe<C_RetarderMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_RetarderMaterial_Max_Fields>;
  min?: Maybe<C_RetarderMaterial_Min_Fields>;
  stddev?: Maybe<C_RetarderMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_RetarderMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_RetarderMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_RetarderMaterial_Sum_Fields>;
  var_pop?: Maybe<C_RetarderMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_RetarderMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_RetarderMaterial_Variance_Fields>;
};


/** aggregate fields of "C_RetarderMaterial" */
export type C_RetarderMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_RetarderMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_RetarderMaterial_Avg_Fields = {
  __typename?: 'C_RetarderMaterial_avg_fields';
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_RetarderMaterial". All fields are combined with a logical 'AND'. */
export type C_RetarderMaterial_Bool_Exp = {
  AirRetarders?: Maybe<AirRetarder_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  RetarderMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  RetarderMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  SeparatorSheets?: Maybe<SeparatorSheet_Bool_Exp>;
  VaporRetarders?: Maybe<VaporRetarder_Bool_Exp>;
  _and?: Maybe<Array<C_RetarderMaterial_Bool_Exp>>;
  _not?: Maybe<C_RetarderMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_RetarderMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_RetarderMaterial" */
export type C_RetarderMaterial_If_Matched = {
  match_columns?: Array<C_RetarderMaterial_Insert_Match_Column>;
  update_columns?: Array<C_RetarderMaterial_Update_Column>;
  where?: Maybe<C_RetarderMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_RetarderMaterial" */
export type C_RetarderMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  RetarderMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_RetarderMaterial" */
export enum C_RetarderMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  RetarderMaterialCode = 'RetarderMaterialCode',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId'
}

/** aggregate max on columns */
export type C_RetarderMaterial_Max_Fields = {
  __typename?: 'C_RetarderMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  RetarderMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_RetarderMaterial_Min_Fields = {
  __typename?: 'C_RetarderMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  RetarderMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_RetarderMaterial" */
export type C_RetarderMaterial_Mutation_Response = {
  __typename?: 'C_RetarderMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_RetarderMaterial>;
};

/** Ordering options when selecting data from "C_RetarderMaterial". */
export type C_RetarderMaterial_Order_By = {
  AirRetarders_aggregate?: Maybe<AirRetarder_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  RetarderMaterialCode?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  SeparatorSheets_aggregate?: Maybe<SeparatorSheet_Aggregate_Order_By>;
  VaporRetarders_aggregate?: Maybe<VaporRetarder_Aggregate_Order_By>;
};

/** primary key columns input for table: C_RetarderMaterial */
export type C_RetarderMaterial_Pk_Columns_Input = {
  RetarderMaterialId: Scalars['Int'];
};

/** select columns of table "C_RetarderMaterial" */
export enum C_RetarderMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  RetarderMaterialCode = 'RetarderMaterialCode',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId'
}

/** input type for updating data in table "C_RetarderMaterial" */
export type C_RetarderMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  RetarderMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_RetarderMaterial_Stddev_Fields = {
  __typename?: 'C_RetarderMaterial_stddev_fields';
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_RetarderMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_RetarderMaterial_stddev_pop_fields';
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_RetarderMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_RetarderMaterial_stddev_samp_fields';
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_RetarderMaterial_Sum_Fields = {
  __typename?: 'C_RetarderMaterial_sum_fields';
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_RetarderMaterial" */
export enum C_RetarderMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  RetarderMaterialCode = 'RetarderMaterialCode'
}

/** aggregate var_pop on columns */
export type C_RetarderMaterial_Var_Pop_Fields = {
  __typename?: 'C_RetarderMaterial_var_pop_fields';
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_RetarderMaterial_Var_Samp_Fields = {
  __typename?: 'C_RetarderMaterial_var_samp_fields';
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_RetarderMaterial_Variance_Fields = {
  __typename?: 'C_RetarderMaterial_variance_fields';
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ScenarioStatus" */
export type C_ScenarioStatus = {
  __typename?: 'C_ScenarioStatus';
  Dsc: Scalars['CodeTableDescription'];
  ScenarioStatusCode: Scalars['CodeTableValue'];
  ScenarioStatusId: Scalars['Int'];
  /** An array relationship */
  Scenarios: Array<Scenario>;
  /** An aggregate relationship */
  Scenarios_aggregate: Scenario_Aggregate;
};


/** columns and relationships of "C_ScenarioStatus" */
export type C_ScenarioStatusScenariosArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scenario_Order_By>>;
  where?: Maybe<Scenario_Bool_Exp>;
};


/** columns and relationships of "C_ScenarioStatus" */
export type C_ScenarioStatusScenarios_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scenario_Order_By>>;
  where?: Maybe<Scenario_Bool_Exp>;
};

/** aggregated selection of "C_ScenarioStatus" */
export type C_ScenarioStatus_Aggregate = {
  __typename?: 'C_ScenarioStatus_aggregate';
  aggregate?: Maybe<C_ScenarioStatus_Aggregate_Fields>;
  nodes: Array<C_ScenarioStatus>;
};

/** aggregate fields of "C_ScenarioStatus" */
export type C_ScenarioStatus_Aggregate_Fields = {
  __typename?: 'C_ScenarioStatus_aggregate_fields';
  avg?: Maybe<C_ScenarioStatus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ScenarioStatus_Max_Fields>;
  min?: Maybe<C_ScenarioStatus_Min_Fields>;
  stddev?: Maybe<C_ScenarioStatus_Stddev_Fields>;
  stddev_pop?: Maybe<C_ScenarioStatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ScenarioStatus_Stddev_Samp_Fields>;
  sum?: Maybe<C_ScenarioStatus_Sum_Fields>;
  var_pop?: Maybe<C_ScenarioStatus_Var_Pop_Fields>;
  var_samp?: Maybe<C_ScenarioStatus_Var_Samp_Fields>;
  variance?: Maybe<C_ScenarioStatus_Variance_Fields>;
};


/** aggregate fields of "C_ScenarioStatus" */
export type C_ScenarioStatus_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ScenarioStatus_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ScenarioStatus_Avg_Fields = {
  __typename?: 'C_ScenarioStatus_avg_fields';
  ScenarioStatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ScenarioStatus". All fields are combined with a logical 'AND'. */
export type C_ScenarioStatus_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  ScenarioStatusCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ScenarioStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  Scenarios?: Maybe<Scenario_Bool_Exp>;
  _and?: Maybe<Array<C_ScenarioStatus_Bool_Exp>>;
  _not?: Maybe<C_ScenarioStatus_Bool_Exp>;
  _or?: Maybe<Array<C_ScenarioStatus_Bool_Exp>>;
};

/** upsert condition type for table "C_ScenarioStatus" */
export type C_ScenarioStatus_If_Matched = {
  match_columns?: Array<C_ScenarioStatus_Insert_Match_Column>;
  update_columns?: Array<C_ScenarioStatus_Update_Column>;
  where?: Maybe<C_ScenarioStatus_Bool_Exp>;
};

/** input type for inserting data into table "C_ScenarioStatus" */
export type C_ScenarioStatus_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ScenarioStatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ScenarioStatus" */
export enum C_ScenarioStatus_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ScenarioStatusCode = 'ScenarioStatusCode',
  /** column name */
  ScenarioStatusId = 'ScenarioStatusId'
}

/** aggregate max on columns */
export type C_ScenarioStatus_Max_Fields = {
  __typename?: 'C_ScenarioStatus_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ScenarioStatusCode?: Maybe<Scalars['CodeTableValue']>;
  ScenarioStatusId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ScenarioStatus_Min_Fields = {
  __typename?: 'C_ScenarioStatus_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ScenarioStatusCode?: Maybe<Scalars['CodeTableValue']>;
  ScenarioStatusId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ScenarioStatus" */
export type C_ScenarioStatus_Mutation_Response = {
  __typename?: 'C_ScenarioStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ScenarioStatus>;
};

/** Ordering options when selecting data from "C_ScenarioStatus". */
export type C_ScenarioStatus_Order_By = {
  Dsc?: Maybe<Order_By>;
  ScenarioStatusCode?: Maybe<Order_By>;
  ScenarioStatusId?: Maybe<Order_By>;
  Scenarios_aggregate?: Maybe<Scenario_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ScenarioStatus */
export type C_ScenarioStatus_Pk_Columns_Input = {
  ScenarioStatusId: Scalars['Int'];
};

/** select columns of table "C_ScenarioStatus" */
export enum C_ScenarioStatus_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ScenarioStatusCode = 'ScenarioStatusCode',
  /** column name */
  ScenarioStatusId = 'ScenarioStatusId'
}

/** input type for updating data in table "C_ScenarioStatus" */
export type C_ScenarioStatus_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ScenarioStatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ScenarioStatus_Stddev_Fields = {
  __typename?: 'C_ScenarioStatus_stddev_fields';
  ScenarioStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ScenarioStatus_Stddev_Pop_Fields = {
  __typename?: 'C_ScenarioStatus_stddev_pop_fields';
  ScenarioStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ScenarioStatus_Stddev_Samp_Fields = {
  __typename?: 'C_ScenarioStatus_stddev_samp_fields';
  ScenarioStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ScenarioStatus_Sum_Fields = {
  __typename?: 'C_ScenarioStatus_sum_fields';
  ScenarioStatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ScenarioStatus" */
export enum C_ScenarioStatus_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ScenarioStatusCode = 'ScenarioStatusCode'
}

/** aggregate var_pop on columns */
export type C_ScenarioStatus_Var_Pop_Fields = {
  __typename?: 'C_ScenarioStatus_var_pop_fields';
  ScenarioStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ScenarioStatus_Var_Samp_Fields = {
  __typename?: 'C_ScenarioStatus_var_samp_fields';
  ScenarioStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ScenarioStatus_Variance_Fields = {
  __typename?: 'C_ScenarioStatus_variance_fields';
  ScenarioStatusId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ScenarioType" */
export type C_ScenarioType = {
  __typename?: 'C_ScenarioType';
  Dsc: Scalars['CodeTableDescription'];
  ScenarioTypeCode: Scalars['CodeTableValue'];
  ScenarioTypeId: Scalars['Int'];
  /** An array relationship */
  Scenarios: Array<Scenario>;
  /** An aggregate relationship */
  Scenarios_aggregate: Scenario_Aggregate;
};


/** columns and relationships of "C_ScenarioType" */
export type C_ScenarioTypeScenariosArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scenario_Order_By>>;
  where?: Maybe<Scenario_Bool_Exp>;
};


/** columns and relationships of "C_ScenarioType" */
export type C_ScenarioTypeScenarios_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scenario_Order_By>>;
  where?: Maybe<Scenario_Bool_Exp>;
};

/** aggregated selection of "C_ScenarioType" */
export type C_ScenarioType_Aggregate = {
  __typename?: 'C_ScenarioType_aggregate';
  aggregate?: Maybe<C_ScenarioType_Aggregate_Fields>;
  nodes: Array<C_ScenarioType>;
};

/** aggregate fields of "C_ScenarioType" */
export type C_ScenarioType_Aggregate_Fields = {
  __typename?: 'C_ScenarioType_aggregate_fields';
  avg?: Maybe<C_ScenarioType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ScenarioType_Max_Fields>;
  min?: Maybe<C_ScenarioType_Min_Fields>;
  stddev?: Maybe<C_ScenarioType_Stddev_Fields>;
  stddev_pop?: Maybe<C_ScenarioType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ScenarioType_Stddev_Samp_Fields>;
  sum?: Maybe<C_ScenarioType_Sum_Fields>;
  var_pop?: Maybe<C_ScenarioType_Var_Pop_Fields>;
  var_samp?: Maybe<C_ScenarioType_Var_Samp_Fields>;
  variance?: Maybe<C_ScenarioType_Variance_Fields>;
};


/** aggregate fields of "C_ScenarioType" */
export type C_ScenarioType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ScenarioType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ScenarioType_Avg_Fields = {
  __typename?: 'C_ScenarioType_avg_fields';
  ScenarioTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ScenarioType". All fields are combined with a logical 'AND'. */
export type C_ScenarioType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  ScenarioTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ScenarioTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Scenarios?: Maybe<Scenario_Bool_Exp>;
  _and?: Maybe<Array<C_ScenarioType_Bool_Exp>>;
  _not?: Maybe<C_ScenarioType_Bool_Exp>;
  _or?: Maybe<Array<C_ScenarioType_Bool_Exp>>;
};

/** upsert condition type for table "C_ScenarioType" */
export type C_ScenarioType_If_Matched = {
  match_columns?: Array<C_ScenarioType_Insert_Match_Column>;
  update_columns?: Array<C_ScenarioType_Update_Column>;
  where?: Maybe<C_ScenarioType_Bool_Exp>;
};

/** input type for inserting data into table "C_ScenarioType" */
export type C_ScenarioType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ScenarioTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ScenarioType" */
export enum C_ScenarioType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ScenarioTypeCode = 'ScenarioTypeCode',
  /** column name */
  ScenarioTypeId = 'ScenarioTypeId'
}

/** aggregate max on columns */
export type C_ScenarioType_Max_Fields = {
  __typename?: 'C_ScenarioType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ScenarioTypeCode?: Maybe<Scalars['CodeTableValue']>;
  ScenarioTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ScenarioType_Min_Fields = {
  __typename?: 'C_ScenarioType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ScenarioTypeCode?: Maybe<Scalars['CodeTableValue']>;
  ScenarioTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ScenarioType" */
export type C_ScenarioType_Mutation_Response = {
  __typename?: 'C_ScenarioType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ScenarioType>;
};

/** Ordering options when selecting data from "C_ScenarioType". */
export type C_ScenarioType_Order_By = {
  Dsc?: Maybe<Order_By>;
  ScenarioTypeCode?: Maybe<Order_By>;
  ScenarioTypeId?: Maybe<Order_By>;
  Scenarios_aggregate?: Maybe<Scenario_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ScenarioType */
export type C_ScenarioType_Pk_Columns_Input = {
  ScenarioTypeId: Scalars['Int'];
};

/** select columns of table "C_ScenarioType" */
export enum C_ScenarioType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ScenarioTypeCode = 'ScenarioTypeCode',
  /** column name */
  ScenarioTypeId = 'ScenarioTypeId'
}

/** input type for updating data in table "C_ScenarioType" */
export type C_ScenarioType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ScenarioTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ScenarioType_Stddev_Fields = {
  __typename?: 'C_ScenarioType_stddev_fields';
  ScenarioTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ScenarioType_Stddev_Pop_Fields = {
  __typename?: 'C_ScenarioType_stddev_pop_fields';
  ScenarioTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ScenarioType_Stddev_Samp_Fields = {
  __typename?: 'C_ScenarioType_stddev_samp_fields';
  ScenarioTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ScenarioType_Sum_Fields = {
  __typename?: 'C_ScenarioType_sum_fields';
  ScenarioTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ScenarioType" */
export enum C_ScenarioType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ScenarioTypeCode = 'ScenarioTypeCode'
}

/** aggregate var_pop on columns */
export type C_ScenarioType_Var_Pop_Fields = {
  __typename?: 'C_ScenarioType_var_pop_fields';
  ScenarioTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ScenarioType_Var_Samp_Fields = {
  __typename?: 'C_ScenarioType_var_samp_fields';
  ScenarioTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ScenarioType_Variance_Fields = {
  __typename?: 'C_ScenarioType_variance_fields';
  ScenarioTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_SeamPrimerType" */
export type C_SeamPrimerType = {
  __typename?: 'C_SeamPrimerType';
  Dsc: Scalars['CodeTableDescription'];
  SeamPrimerTypeCode: Scalars['CodeTableValue'];
  SeamPrimerTypeId: Scalars['Int'];
};

/** aggregated selection of "C_SeamPrimerType" */
export type C_SeamPrimerType_Aggregate = {
  __typename?: 'C_SeamPrimerType_aggregate';
  aggregate?: Maybe<C_SeamPrimerType_Aggregate_Fields>;
  nodes: Array<C_SeamPrimerType>;
};

/** aggregate fields of "C_SeamPrimerType" */
export type C_SeamPrimerType_Aggregate_Fields = {
  __typename?: 'C_SeamPrimerType_aggregate_fields';
  avg?: Maybe<C_SeamPrimerType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_SeamPrimerType_Max_Fields>;
  min?: Maybe<C_SeamPrimerType_Min_Fields>;
  stddev?: Maybe<C_SeamPrimerType_Stddev_Fields>;
  stddev_pop?: Maybe<C_SeamPrimerType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_SeamPrimerType_Stddev_Samp_Fields>;
  sum?: Maybe<C_SeamPrimerType_Sum_Fields>;
  var_pop?: Maybe<C_SeamPrimerType_Var_Pop_Fields>;
  var_samp?: Maybe<C_SeamPrimerType_Var_Samp_Fields>;
  variance?: Maybe<C_SeamPrimerType_Variance_Fields>;
};


/** aggregate fields of "C_SeamPrimerType" */
export type C_SeamPrimerType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_SeamPrimerType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_SeamPrimerType_Avg_Fields = {
  __typename?: 'C_SeamPrimerType_avg_fields';
  SeamPrimerTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_SeamPrimerType". All fields are combined with a logical 'AND'. */
export type C_SeamPrimerType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  SeamPrimerTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  SeamPrimerTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_SeamPrimerType_Bool_Exp>>;
  _not?: Maybe<C_SeamPrimerType_Bool_Exp>;
  _or?: Maybe<Array<C_SeamPrimerType_Bool_Exp>>;
};

/** upsert condition type for table "C_SeamPrimerType" */
export type C_SeamPrimerType_If_Matched = {
  match_columns?: Array<C_SeamPrimerType_Insert_Match_Column>;
  update_columns?: Array<C_SeamPrimerType_Update_Column>;
  where?: Maybe<C_SeamPrimerType_Bool_Exp>;
};

/** input type for inserting data into table "C_SeamPrimerType" */
export type C_SeamPrimerType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SeamPrimerTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_SeamPrimerType" */
export enum C_SeamPrimerType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SeamPrimerTypeCode = 'SeamPrimerTypeCode',
  /** column name */
  SeamPrimerTypeId = 'SeamPrimerTypeId'
}

/** aggregate max on columns */
export type C_SeamPrimerType_Max_Fields = {
  __typename?: 'C_SeamPrimerType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SeamPrimerTypeCode?: Maybe<Scalars['CodeTableValue']>;
  SeamPrimerTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_SeamPrimerType_Min_Fields = {
  __typename?: 'C_SeamPrimerType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SeamPrimerTypeCode?: Maybe<Scalars['CodeTableValue']>;
  SeamPrimerTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_SeamPrimerType" */
export type C_SeamPrimerType_Mutation_Response = {
  __typename?: 'C_SeamPrimerType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_SeamPrimerType>;
};

/** Ordering options when selecting data from "C_SeamPrimerType". */
export type C_SeamPrimerType_Order_By = {
  Dsc?: Maybe<Order_By>;
  SeamPrimerTypeCode?: Maybe<Order_By>;
  SeamPrimerTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_SeamPrimerType */
export type C_SeamPrimerType_Pk_Columns_Input = {
  SeamPrimerTypeId: Scalars['Int'];
};

/** select columns of table "C_SeamPrimerType" */
export enum C_SeamPrimerType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SeamPrimerTypeCode = 'SeamPrimerTypeCode',
  /** column name */
  SeamPrimerTypeId = 'SeamPrimerTypeId'
}

/** input type for updating data in table "C_SeamPrimerType" */
export type C_SeamPrimerType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SeamPrimerTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_SeamPrimerType_Stddev_Fields = {
  __typename?: 'C_SeamPrimerType_stddev_fields';
  SeamPrimerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_SeamPrimerType_Stddev_Pop_Fields = {
  __typename?: 'C_SeamPrimerType_stddev_pop_fields';
  SeamPrimerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_SeamPrimerType_Stddev_Samp_Fields = {
  __typename?: 'C_SeamPrimerType_stddev_samp_fields';
  SeamPrimerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_SeamPrimerType_Sum_Fields = {
  __typename?: 'C_SeamPrimerType_sum_fields';
  SeamPrimerTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_SeamPrimerType" */
export enum C_SeamPrimerType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SeamPrimerTypeCode = 'SeamPrimerTypeCode'
}

/** aggregate var_pop on columns */
export type C_SeamPrimerType_Var_Pop_Fields = {
  __typename?: 'C_SeamPrimerType_var_pop_fields';
  SeamPrimerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_SeamPrimerType_Var_Samp_Fields = {
  __typename?: 'C_SeamPrimerType_var_samp_fields';
  SeamPrimerTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_SeamPrimerType_Variance_Fields = {
  __typename?: 'C_SeamPrimerType_variance_fields';
  SeamPrimerTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_SeamWashType" */
export type C_SeamWashType = {
  __typename?: 'C_SeamWashType';
  Dsc: Scalars['CodeTableDescription'];
  SeamWashTypeCode: Scalars['CodeTableValue'];
  SeamWashTypeId: Scalars['Int'];
};

/** aggregated selection of "C_SeamWashType" */
export type C_SeamWashType_Aggregate = {
  __typename?: 'C_SeamWashType_aggregate';
  aggregate?: Maybe<C_SeamWashType_Aggregate_Fields>;
  nodes: Array<C_SeamWashType>;
};

/** aggregate fields of "C_SeamWashType" */
export type C_SeamWashType_Aggregate_Fields = {
  __typename?: 'C_SeamWashType_aggregate_fields';
  avg?: Maybe<C_SeamWashType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_SeamWashType_Max_Fields>;
  min?: Maybe<C_SeamWashType_Min_Fields>;
  stddev?: Maybe<C_SeamWashType_Stddev_Fields>;
  stddev_pop?: Maybe<C_SeamWashType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_SeamWashType_Stddev_Samp_Fields>;
  sum?: Maybe<C_SeamWashType_Sum_Fields>;
  var_pop?: Maybe<C_SeamWashType_Var_Pop_Fields>;
  var_samp?: Maybe<C_SeamWashType_Var_Samp_Fields>;
  variance?: Maybe<C_SeamWashType_Variance_Fields>;
};


/** aggregate fields of "C_SeamWashType" */
export type C_SeamWashType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_SeamWashType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_SeamWashType_Avg_Fields = {
  __typename?: 'C_SeamWashType_avg_fields';
  SeamWashTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_SeamWashType". All fields are combined with a logical 'AND'. */
export type C_SeamWashType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  SeamWashTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  SeamWashTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_SeamWashType_Bool_Exp>>;
  _not?: Maybe<C_SeamWashType_Bool_Exp>;
  _or?: Maybe<Array<C_SeamWashType_Bool_Exp>>;
};

/** upsert condition type for table "C_SeamWashType" */
export type C_SeamWashType_If_Matched = {
  match_columns?: Array<C_SeamWashType_Insert_Match_Column>;
  update_columns?: Array<C_SeamWashType_Update_Column>;
  where?: Maybe<C_SeamWashType_Bool_Exp>;
};

/** input type for inserting data into table "C_SeamWashType" */
export type C_SeamWashType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SeamWashTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_SeamWashType" */
export enum C_SeamWashType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SeamWashTypeCode = 'SeamWashTypeCode',
  /** column name */
  SeamWashTypeId = 'SeamWashTypeId'
}

/** aggregate max on columns */
export type C_SeamWashType_Max_Fields = {
  __typename?: 'C_SeamWashType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SeamWashTypeCode?: Maybe<Scalars['CodeTableValue']>;
  SeamWashTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_SeamWashType_Min_Fields = {
  __typename?: 'C_SeamWashType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SeamWashTypeCode?: Maybe<Scalars['CodeTableValue']>;
  SeamWashTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_SeamWashType" */
export type C_SeamWashType_Mutation_Response = {
  __typename?: 'C_SeamWashType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_SeamWashType>;
};

/** Ordering options when selecting data from "C_SeamWashType". */
export type C_SeamWashType_Order_By = {
  Dsc?: Maybe<Order_By>;
  SeamWashTypeCode?: Maybe<Order_By>;
  SeamWashTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_SeamWashType */
export type C_SeamWashType_Pk_Columns_Input = {
  SeamWashTypeId: Scalars['Int'];
};

/** select columns of table "C_SeamWashType" */
export enum C_SeamWashType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SeamWashTypeCode = 'SeamWashTypeCode',
  /** column name */
  SeamWashTypeId = 'SeamWashTypeId'
}

/** input type for updating data in table "C_SeamWashType" */
export type C_SeamWashType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SeamWashTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_SeamWashType_Stddev_Fields = {
  __typename?: 'C_SeamWashType_stddev_fields';
  SeamWashTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_SeamWashType_Stddev_Pop_Fields = {
  __typename?: 'C_SeamWashType_stddev_pop_fields';
  SeamWashTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_SeamWashType_Stddev_Samp_Fields = {
  __typename?: 'C_SeamWashType_stddev_samp_fields';
  SeamWashTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_SeamWashType_Sum_Fields = {
  __typename?: 'C_SeamWashType_sum_fields';
  SeamWashTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_SeamWashType" */
export enum C_SeamWashType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SeamWashTypeCode = 'SeamWashTypeCode'
}

/** aggregate var_pop on columns */
export type C_SeamWashType_Var_Pop_Fields = {
  __typename?: 'C_SeamWashType_var_pop_fields';
  SeamWashTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_SeamWashType_Var_Samp_Fields = {
  __typename?: 'C_SeamWashType_var_samp_fields';
  SeamWashTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_SeamWashType_Variance_Fields = {
  __typename?: 'C_SeamWashType_variance_fields';
  SeamWashTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_SecurementMaterial" */
export type C_SecurementMaterial = {
  __typename?: 'C_SecurementMaterial';
  /** An array relationship */
  BattenBars: Array<BattenBar>;
  /** An aggregate relationship */
  BattenBars_aggregate: BattenBar_Aggregate;
  /** An array relationship */
  Clips: Array<Clip>;
  /** An aggregate relationship */
  Clips_aggregate: Clip_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  Fasteners: Array<Fastener>;
  /** An aggregate relationship */
  Fasteners_aggregate: Fastener_Aggregate;
  /** An array relationship */
  PreassembledFastenings: Array<PreassembledFastening>;
  /** An aggregate relationship */
  PreassembledFastenings_aggregate: PreassembledFastening_Aggregate;
  SecurementMaterialCode: Scalars['CodeTableValue'];
  SecurementMaterialId: Scalars['Int'];
  /** An array relationship */
  StressPlates: Array<StressPlate>;
  /** An aggregate relationship */
  StressPlates_aggregate: StressPlate_Aggregate;
  /** An array relationship */
  preassembledFasteningsByFastenermaterialid: Array<PreassembledFastening>;
  /** An aggregate relationship */
  preassembledFasteningsByFastenermaterialid_aggregate: PreassembledFastening_Aggregate;
};


/** columns and relationships of "C_SecurementMaterial" */
export type C_SecurementMaterialBattenBarsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattenBar_Order_By>>;
  where?: Maybe<BattenBar_Bool_Exp>;
};


/** columns and relationships of "C_SecurementMaterial" */
export type C_SecurementMaterialBattenBars_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattenBar_Order_By>>;
  where?: Maybe<BattenBar_Bool_Exp>;
};


/** columns and relationships of "C_SecurementMaterial" */
export type C_SecurementMaterialClipsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clip_Order_By>>;
  where?: Maybe<Clip_Bool_Exp>;
};


/** columns and relationships of "C_SecurementMaterial" */
export type C_SecurementMaterialClips_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clip_Order_By>>;
  where?: Maybe<Clip_Bool_Exp>;
};


/** columns and relationships of "C_SecurementMaterial" */
export type C_SecurementMaterialFastenersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fastener_Order_By>>;
  where?: Maybe<Fastener_Bool_Exp>;
};


/** columns and relationships of "C_SecurementMaterial" */
export type C_SecurementMaterialFasteners_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fastener_Order_By>>;
  where?: Maybe<Fastener_Bool_Exp>;
};


/** columns and relationships of "C_SecurementMaterial" */
export type C_SecurementMaterialPreassembledFasteningsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};


/** columns and relationships of "C_SecurementMaterial" */
export type C_SecurementMaterialPreassembledFastenings_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};


/** columns and relationships of "C_SecurementMaterial" */
export type C_SecurementMaterialStressPlatesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StressPlate_Order_By>>;
  where?: Maybe<StressPlate_Bool_Exp>;
};


/** columns and relationships of "C_SecurementMaterial" */
export type C_SecurementMaterialStressPlates_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StressPlate_Order_By>>;
  where?: Maybe<StressPlate_Bool_Exp>;
};


/** columns and relationships of "C_SecurementMaterial" */
export type C_SecurementMaterialPreassembledFasteningsByFastenermaterialidArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};


/** columns and relationships of "C_SecurementMaterial" */
export type C_SecurementMaterialPreassembledFasteningsByFastenermaterialid_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};

/** aggregated selection of "C_SecurementMaterial" */
export type C_SecurementMaterial_Aggregate = {
  __typename?: 'C_SecurementMaterial_aggregate';
  aggregate?: Maybe<C_SecurementMaterial_Aggregate_Fields>;
  nodes: Array<C_SecurementMaterial>;
};

/** aggregate fields of "C_SecurementMaterial" */
export type C_SecurementMaterial_Aggregate_Fields = {
  __typename?: 'C_SecurementMaterial_aggregate_fields';
  avg?: Maybe<C_SecurementMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_SecurementMaterial_Max_Fields>;
  min?: Maybe<C_SecurementMaterial_Min_Fields>;
  stddev?: Maybe<C_SecurementMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_SecurementMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_SecurementMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_SecurementMaterial_Sum_Fields>;
  var_pop?: Maybe<C_SecurementMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_SecurementMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_SecurementMaterial_Variance_Fields>;
};


/** aggregate fields of "C_SecurementMaterial" */
export type C_SecurementMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_SecurementMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_SecurementMaterial_Avg_Fields = {
  __typename?: 'C_SecurementMaterial_avg_fields';
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_SecurementMaterial". All fields are combined with a logical 'AND'. */
export type C_SecurementMaterial_Bool_Exp = {
  BattenBars?: Maybe<BattenBar_Bool_Exp>;
  Clips?: Maybe<Clip_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  Fasteners?: Maybe<Fastener_Bool_Exp>;
  PreassembledFastenings?: Maybe<PreassembledFastening_Bool_Exp>;
  SecurementMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  SecurementMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  StressPlates?: Maybe<StressPlate_Bool_Exp>;
  _and?: Maybe<Array<C_SecurementMaterial_Bool_Exp>>;
  _not?: Maybe<C_SecurementMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_SecurementMaterial_Bool_Exp>>;
  preassembledFasteningsByFastenermaterialid?: Maybe<PreassembledFastening_Bool_Exp>;
};

/** upsert condition type for table "C_SecurementMaterial" */
export type C_SecurementMaterial_If_Matched = {
  match_columns?: Array<C_SecurementMaterial_Insert_Match_Column>;
  update_columns?: Array<C_SecurementMaterial_Update_Column>;
  where?: Maybe<C_SecurementMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_SecurementMaterial" */
export type C_SecurementMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SecurementMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_SecurementMaterial" */
export enum C_SecurementMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SecurementMaterialCode = 'SecurementMaterialCode',
  /** column name */
  SecurementMaterialId = 'SecurementMaterialId'
}

/** aggregate max on columns */
export type C_SecurementMaterial_Max_Fields = {
  __typename?: 'C_SecurementMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SecurementMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_SecurementMaterial_Min_Fields = {
  __typename?: 'C_SecurementMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SecurementMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_SecurementMaterial" */
export type C_SecurementMaterial_Mutation_Response = {
  __typename?: 'C_SecurementMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_SecurementMaterial>;
};

/** Ordering options when selecting data from "C_SecurementMaterial". */
export type C_SecurementMaterial_Order_By = {
  BattenBars_aggregate?: Maybe<BattenBar_Aggregate_Order_By>;
  Clips_aggregate?: Maybe<Clip_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  Fasteners_aggregate?: Maybe<Fastener_Aggregate_Order_By>;
  PreassembledFastenings_aggregate?: Maybe<PreassembledFastening_Aggregate_Order_By>;
  SecurementMaterialCode?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StressPlates_aggregate?: Maybe<StressPlate_Aggregate_Order_By>;
  preassembledFasteningsByFastenermaterialid_aggregate?: Maybe<PreassembledFastening_Aggregate_Order_By>;
};

/** primary key columns input for table: C_SecurementMaterial */
export type C_SecurementMaterial_Pk_Columns_Input = {
  SecurementMaterialId: Scalars['Int'];
};

/** select columns of table "C_SecurementMaterial" */
export enum C_SecurementMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SecurementMaterialCode = 'SecurementMaterialCode',
  /** column name */
  SecurementMaterialId = 'SecurementMaterialId'
}

/** input type for updating data in table "C_SecurementMaterial" */
export type C_SecurementMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SecurementMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_SecurementMaterial_Stddev_Fields = {
  __typename?: 'C_SecurementMaterial_stddev_fields';
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_SecurementMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_SecurementMaterial_stddev_pop_fields';
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_SecurementMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_SecurementMaterial_stddev_samp_fields';
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_SecurementMaterial_Sum_Fields = {
  __typename?: 'C_SecurementMaterial_sum_fields';
  SecurementMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_SecurementMaterial" */
export enum C_SecurementMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SecurementMaterialCode = 'SecurementMaterialCode'
}

/** aggregate var_pop on columns */
export type C_SecurementMaterial_Var_Pop_Fields = {
  __typename?: 'C_SecurementMaterial_var_pop_fields';
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_SecurementMaterial_Var_Samp_Fields = {
  __typename?: 'C_SecurementMaterial_var_samp_fields';
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_SecurementMaterial_Variance_Fields = {
  __typename?: 'C_SecurementMaterial_variance_fields';
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ShinglesHail" */
export type C_ShinglesHail = {
  __typename?: 'C_ShinglesHail';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  Shingles: Array<Shingles>;
  ShinglesHailCode: Scalars['CodeTableValue'];
  ShinglesHailId: Scalars['Int'];
  /** An aggregate relationship */
  Shingles_aggregate: Shingles_Aggregate;
};


/** columns and relationships of "C_ShinglesHail" */
export type C_ShinglesHailShinglesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shingles_Order_By>>;
  where?: Maybe<Shingles_Bool_Exp>;
};


/** columns and relationships of "C_ShinglesHail" */
export type C_ShinglesHailShingles_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shingles_Order_By>>;
  where?: Maybe<Shingles_Bool_Exp>;
};

/** aggregated selection of "C_ShinglesHail" */
export type C_ShinglesHail_Aggregate = {
  __typename?: 'C_ShinglesHail_aggregate';
  aggregate?: Maybe<C_ShinglesHail_Aggregate_Fields>;
  nodes: Array<C_ShinglesHail>;
};

/** aggregate fields of "C_ShinglesHail" */
export type C_ShinglesHail_Aggregate_Fields = {
  __typename?: 'C_ShinglesHail_aggregate_fields';
  avg?: Maybe<C_ShinglesHail_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ShinglesHail_Max_Fields>;
  min?: Maybe<C_ShinglesHail_Min_Fields>;
  stddev?: Maybe<C_ShinglesHail_Stddev_Fields>;
  stddev_pop?: Maybe<C_ShinglesHail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ShinglesHail_Stddev_Samp_Fields>;
  sum?: Maybe<C_ShinglesHail_Sum_Fields>;
  var_pop?: Maybe<C_ShinglesHail_Var_Pop_Fields>;
  var_samp?: Maybe<C_ShinglesHail_Var_Samp_Fields>;
  variance?: Maybe<C_ShinglesHail_Variance_Fields>;
};


/** aggregate fields of "C_ShinglesHail" */
export type C_ShinglesHail_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ShinglesHail_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ShinglesHail_Avg_Fields = {
  __typename?: 'C_ShinglesHail_avg_fields';
  ShinglesHailId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ShinglesHail". All fields are combined with a logical 'AND'. */
export type C_ShinglesHail_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  Shingles?: Maybe<Shingles_Bool_Exp>;
  ShinglesHailCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ShinglesHailId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_ShinglesHail_Bool_Exp>>;
  _not?: Maybe<C_ShinglesHail_Bool_Exp>;
  _or?: Maybe<Array<C_ShinglesHail_Bool_Exp>>;
};

/** upsert condition type for table "C_ShinglesHail" */
export type C_ShinglesHail_If_Matched = {
  match_columns?: Array<C_ShinglesHail_Insert_Match_Column>;
  update_columns?: Array<C_ShinglesHail_Update_Column>;
  where?: Maybe<C_ShinglesHail_Bool_Exp>;
};

/** input type for inserting data into table "C_ShinglesHail" */
export type C_ShinglesHail_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesHailCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ShinglesHail" */
export enum C_ShinglesHail_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesHailCode = 'ShinglesHailCode',
  /** column name */
  ShinglesHailId = 'ShinglesHailId'
}

/** aggregate max on columns */
export type C_ShinglesHail_Max_Fields = {
  __typename?: 'C_ShinglesHail_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesHailCode?: Maybe<Scalars['CodeTableValue']>;
  ShinglesHailId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ShinglesHail_Min_Fields = {
  __typename?: 'C_ShinglesHail_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesHailCode?: Maybe<Scalars['CodeTableValue']>;
  ShinglesHailId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ShinglesHail" */
export type C_ShinglesHail_Mutation_Response = {
  __typename?: 'C_ShinglesHail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ShinglesHail>;
};

/** Ordering options when selecting data from "C_ShinglesHail". */
export type C_ShinglesHail_Order_By = {
  Dsc?: Maybe<Order_By>;
  ShinglesHailCode?: Maybe<Order_By>;
  ShinglesHailId?: Maybe<Order_By>;
  Shingles_aggregate?: Maybe<Shingles_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ShinglesHail */
export type C_ShinglesHail_Pk_Columns_Input = {
  ShinglesHailId: Scalars['Int'];
};

/** select columns of table "C_ShinglesHail" */
export enum C_ShinglesHail_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesHailCode = 'ShinglesHailCode',
  /** column name */
  ShinglesHailId = 'ShinglesHailId'
}

/** input type for updating data in table "C_ShinglesHail" */
export type C_ShinglesHail_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesHailCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ShinglesHail_Stddev_Fields = {
  __typename?: 'C_ShinglesHail_stddev_fields';
  ShinglesHailId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ShinglesHail_Stddev_Pop_Fields = {
  __typename?: 'C_ShinglesHail_stddev_pop_fields';
  ShinglesHailId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ShinglesHail_Stddev_Samp_Fields = {
  __typename?: 'C_ShinglesHail_stddev_samp_fields';
  ShinglesHailId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ShinglesHail_Sum_Fields = {
  __typename?: 'C_ShinglesHail_sum_fields';
  ShinglesHailId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ShinglesHail" */
export enum C_ShinglesHail_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesHailCode = 'ShinglesHailCode'
}

/** aggregate var_pop on columns */
export type C_ShinglesHail_Var_Pop_Fields = {
  __typename?: 'C_ShinglesHail_var_pop_fields';
  ShinglesHailId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ShinglesHail_Var_Samp_Fields = {
  __typename?: 'C_ShinglesHail_var_samp_fields';
  ShinglesHailId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ShinglesHail_Variance_Fields = {
  __typename?: 'C_ShinglesHail_variance_fields';
  ShinglesHailId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ShinglesMaterial" */
export type C_ShinglesMaterial = {
  __typename?: 'C_ShinglesMaterial';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  Shingles: Array<Shingles>;
  ShinglesMaterialCode: Scalars['CodeTableValue'];
  ShinglesMaterialId: Scalars['Int'];
  /** An aggregate relationship */
  Shingles_aggregate: Shingles_Aggregate;
};


/** columns and relationships of "C_ShinglesMaterial" */
export type C_ShinglesMaterialShinglesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shingles_Order_By>>;
  where?: Maybe<Shingles_Bool_Exp>;
};


/** columns and relationships of "C_ShinglesMaterial" */
export type C_ShinglesMaterialShingles_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shingles_Order_By>>;
  where?: Maybe<Shingles_Bool_Exp>;
};

/** aggregated selection of "C_ShinglesMaterial" */
export type C_ShinglesMaterial_Aggregate = {
  __typename?: 'C_ShinglesMaterial_aggregate';
  aggregate?: Maybe<C_ShinglesMaterial_Aggregate_Fields>;
  nodes: Array<C_ShinglesMaterial>;
};

/** aggregate fields of "C_ShinglesMaterial" */
export type C_ShinglesMaterial_Aggregate_Fields = {
  __typename?: 'C_ShinglesMaterial_aggregate_fields';
  avg?: Maybe<C_ShinglesMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ShinglesMaterial_Max_Fields>;
  min?: Maybe<C_ShinglesMaterial_Min_Fields>;
  stddev?: Maybe<C_ShinglesMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_ShinglesMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ShinglesMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_ShinglesMaterial_Sum_Fields>;
  var_pop?: Maybe<C_ShinglesMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_ShinglesMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_ShinglesMaterial_Variance_Fields>;
};


/** aggregate fields of "C_ShinglesMaterial" */
export type C_ShinglesMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ShinglesMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ShinglesMaterial_Avg_Fields = {
  __typename?: 'C_ShinglesMaterial_avg_fields';
  ShinglesMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ShinglesMaterial". All fields are combined with a logical 'AND'. */
export type C_ShinglesMaterial_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  Shingles?: Maybe<Shingles_Bool_Exp>;
  ShinglesMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ShinglesMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_ShinglesMaterial_Bool_Exp>>;
  _not?: Maybe<C_ShinglesMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_ShinglesMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_ShinglesMaterial" */
export type C_ShinglesMaterial_If_Matched = {
  match_columns?: Array<C_ShinglesMaterial_Insert_Match_Column>;
  update_columns?: Array<C_ShinglesMaterial_Update_Column>;
  where?: Maybe<C_ShinglesMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_ShinglesMaterial" */
export type C_ShinglesMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ShinglesMaterial" */
export enum C_ShinglesMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesMaterialCode = 'ShinglesMaterialCode',
  /** column name */
  ShinglesMaterialId = 'ShinglesMaterialId'
}

/** aggregate max on columns */
export type C_ShinglesMaterial_Max_Fields = {
  __typename?: 'C_ShinglesMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  ShinglesMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ShinglesMaterial_Min_Fields = {
  __typename?: 'C_ShinglesMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  ShinglesMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ShinglesMaterial" */
export type C_ShinglesMaterial_Mutation_Response = {
  __typename?: 'C_ShinglesMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ShinglesMaterial>;
};

/** Ordering options when selecting data from "C_ShinglesMaterial". */
export type C_ShinglesMaterial_Order_By = {
  Dsc?: Maybe<Order_By>;
  ShinglesMaterialCode?: Maybe<Order_By>;
  ShinglesMaterialId?: Maybe<Order_By>;
  Shingles_aggregate?: Maybe<Shingles_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ShinglesMaterial */
export type C_ShinglesMaterial_Pk_Columns_Input = {
  ShinglesMaterialId: Scalars['Int'];
};

/** select columns of table "C_ShinglesMaterial" */
export enum C_ShinglesMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesMaterialCode = 'ShinglesMaterialCode',
  /** column name */
  ShinglesMaterialId = 'ShinglesMaterialId'
}

/** input type for updating data in table "C_ShinglesMaterial" */
export type C_ShinglesMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ShinglesMaterial_Stddev_Fields = {
  __typename?: 'C_ShinglesMaterial_stddev_fields';
  ShinglesMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ShinglesMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_ShinglesMaterial_stddev_pop_fields';
  ShinglesMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ShinglesMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_ShinglesMaterial_stddev_samp_fields';
  ShinglesMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ShinglesMaterial_Sum_Fields = {
  __typename?: 'C_ShinglesMaterial_sum_fields';
  ShinglesMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ShinglesMaterial" */
export enum C_ShinglesMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesMaterialCode = 'ShinglesMaterialCode'
}

/** aggregate var_pop on columns */
export type C_ShinglesMaterial_Var_Pop_Fields = {
  __typename?: 'C_ShinglesMaterial_var_pop_fields';
  ShinglesMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ShinglesMaterial_Var_Samp_Fields = {
  __typename?: 'C_ShinglesMaterial_var_samp_fields';
  ShinglesMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ShinglesMaterial_Variance_Fields = {
  __typename?: 'C_ShinglesMaterial_variance_fields';
  ShinglesMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ShinglesReinforcement" */
export type C_ShinglesReinforcement = {
  __typename?: 'C_ShinglesReinforcement';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  Shingles: Array<Shingles>;
  ShinglesReinforcementCode: Scalars['CodeTableValue'];
  ShinglesReinforcementId: Scalars['Int'];
  /** An aggregate relationship */
  Shingles_aggregate: Shingles_Aggregate;
};


/** columns and relationships of "C_ShinglesReinforcement" */
export type C_ShinglesReinforcementShinglesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shingles_Order_By>>;
  where?: Maybe<Shingles_Bool_Exp>;
};


/** columns and relationships of "C_ShinglesReinforcement" */
export type C_ShinglesReinforcementShingles_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shingles_Order_By>>;
  where?: Maybe<Shingles_Bool_Exp>;
};

/** aggregated selection of "C_ShinglesReinforcement" */
export type C_ShinglesReinforcement_Aggregate = {
  __typename?: 'C_ShinglesReinforcement_aggregate';
  aggregate?: Maybe<C_ShinglesReinforcement_Aggregate_Fields>;
  nodes: Array<C_ShinglesReinforcement>;
};

/** aggregate fields of "C_ShinglesReinforcement" */
export type C_ShinglesReinforcement_Aggregate_Fields = {
  __typename?: 'C_ShinglesReinforcement_aggregate_fields';
  avg?: Maybe<C_ShinglesReinforcement_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ShinglesReinforcement_Max_Fields>;
  min?: Maybe<C_ShinglesReinforcement_Min_Fields>;
  stddev?: Maybe<C_ShinglesReinforcement_Stddev_Fields>;
  stddev_pop?: Maybe<C_ShinglesReinforcement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ShinglesReinforcement_Stddev_Samp_Fields>;
  sum?: Maybe<C_ShinglesReinforcement_Sum_Fields>;
  var_pop?: Maybe<C_ShinglesReinforcement_Var_Pop_Fields>;
  var_samp?: Maybe<C_ShinglesReinforcement_Var_Samp_Fields>;
  variance?: Maybe<C_ShinglesReinforcement_Variance_Fields>;
};


/** aggregate fields of "C_ShinglesReinforcement" */
export type C_ShinglesReinforcement_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ShinglesReinforcement_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ShinglesReinforcement_Avg_Fields = {
  __typename?: 'C_ShinglesReinforcement_avg_fields';
  ShinglesReinforcementId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ShinglesReinforcement". All fields are combined with a logical 'AND'. */
export type C_ShinglesReinforcement_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  Shingles?: Maybe<Shingles_Bool_Exp>;
  ShinglesReinforcementCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ShinglesReinforcementId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_ShinglesReinforcement_Bool_Exp>>;
  _not?: Maybe<C_ShinglesReinforcement_Bool_Exp>;
  _or?: Maybe<Array<C_ShinglesReinforcement_Bool_Exp>>;
};

/** upsert condition type for table "C_ShinglesReinforcement" */
export type C_ShinglesReinforcement_If_Matched = {
  match_columns?: Array<C_ShinglesReinforcement_Insert_Match_Column>;
  update_columns?: Array<C_ShinglesReinforcement_Update_Column>;
  where?: Maybe<C_ShinglesReinforcement_Bool_Exp>;
};

/** input type for inserting data into table "C_ShinglesReinforcement" */
export type C_ShinglesReinforcement_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesReinforcementCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ShinglesReinforcement" */
export enum C_ShinglesReinforcement_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesReinforcementCode = 'ShinglesReinforcementCode',
  /** column name */
  ShinglesReinforcementId = 'ShinglesReinforcementId'
}

/** aggregate max on columns */
export type C_ShinglesReinforcement_Max_Fields = {
  __typename?: 'C_ShinglesReinforcement_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesReinforcementCode?: Maybe<Scalars['CodeTableValue']>;
  ShinglesReinforcementId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ShinglesReinforcement_Min_Fields = {
  __typename?: 'C_ShinglesReinforcement_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesReinforcementCode?: Maybe<Scalars['CodeTableValue']>;
  ShinglesReinforcementId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ShinglesReinforcement" */
export type C_ShinglesReinforcement_Mutation_Response = {
  __typename?: 'C_ShinglesReinforcement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ShinglesReinforcement>;
};

/** Ordering options when selecting data from "C_ShinglesReinforcement". */
export type C_ShinglesReinforcement_Order_By = {
  Dsc?: Maybe<Order_By>;
  ShinglesReinforcementCode?: Maybe<Order_By>;
  ShinglesReinforcementId?: Maybe<Order_By>;
  Shingles_aggregate?: Maybe<Shingles_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ShinglesReinforcement */
export type C_ShinglesReinforcement_Pk_Columns_Input = {
  ShinglesReinforcementId: Scalars['Int'];
};

/** select columns of table "C_ShinglesReinforcement" */
export enum C_ShinglesReinforcement_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesReinforcementCode = 'ShinglesReinforcementCode',
  /** column name */
  ShinglesReinforcementId = 'ShinglesReinforcementId'
}

/** input type for updating data in table "C_ShinglesReinforcement" */
export type C_ShinglesReinforcement_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesReinforcementCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ShinglesReinforcement_Stddev_Fields = {
  __typename?: 'C_ShinglesReinforcement_stddev_fields';
  ShinglesReinforcementId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ShinglesReinforcement_Stddev_Pop_Fields = {
  __typename?: 'C_ShinglesReinforcement_stddev_pop_fields';
  ShinglesReinforcementId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ShinglesReinforcement_Stddev_Samp_Fields = {
  __typename?: 'C_ShinglesReinforcement_stddev_samp_fields';
  ShinglesReinforcementId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ShinglesReinforcement_Sum_Fields = {
  __typename?: 'C_ShinglesReinforcement_sum_fields';
  ShinglesReinforcementId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ShinglesReinforcement" */
export enum C_ShinglesReinforcement_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesReinforcementCode = 'ShinglesReinforcementCode'
}

/** aggregate var_pop on columns */
export type C_ShinglesReinforcement_Var_Pop_Fields = {
  __typename?: 'C_ShinglesReinforcement_var_pop_fields';
  ShinglesReinforcementId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ShinglesReinforcement_Var_Samp_Fields = {
  __typename?: 'C_ShinglesReinforcement_var_samp_fields';
  ShinglesReinforcementId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ShinglesReinforcement_Variance_Fields = {
  __typename?: 'C_ShinglesReinforcement_variance_fields';
  ShinglesReinforcementId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ShinglesSurfacing" */
export type C_ShinglesSurfacing = {
  __typename?: 'C_ShinglesSurfacing';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  Shingles: Array<Shingles>;
  ShinglesSurfacingCode: Scalars['CodeTableValue'];
  ShinglesSurfacingId: Scalars['Int'];
  /** An aggregate relationship */
  Shingles_aggregate: Shingles_Aggregate;
};


/** columns and relationships of "C_ShinglesSurfacing" */
export type C_ShinglesSurfacingShinglesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shingles_Order_By>>;
  where?: Maybe<Shingles_Bool_Exp>;
};


/** columns and relationships of "C_ShinglesSurfacing" */
export type C_ShinglesSurfacingShingles_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shingles_Order_By>>;
  where?: Maybe<Shingles_Bool_Exp>;
};

/** aggregated selection of "C_ShinglesSurfacing" */
export type C_ShinglesSurfacing_Aggregate = {
  __typename?: 'C_ShinglesSurfacing_aggregate';
  aggregate?: Maybe<C_ShinglesSurfacing_Aggregate_Fields>;
  nodes: Array<C_ShinglesSurfacing>;
};

/** aggregate fields of "C_ShinglesSurfacing" */
export type C_ShinglesSurfacing_Aggregate_Fields = {
  __typename?: 'C_ShinglesSurfacing_aggregate_fields';
  avg?: Maybe<C_ShinglesSurfacing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ShinglesSurfacing_Max_Fields>;
  min?: Maybe<C_ShinglesSurfacing_Min_Fields>;
  stddev?: Maybe<C_ShinglesSurfacing_Stddev_Fields>;
  stddev_pop?: Maybe<C_ShinglesSurfacing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ShinglesSurfacing_Stddev_Samp_Fields>;
  sum?: Maybe<C_ShinglesSurfacing_Sum_Fields>;
  var_pop?: Maybe<C_ShinglesSurfacing_Var_Pop_Fields>;
  var_samp?: Maybe<C_ShinglesSurfacing_Var_Samp_Fields>;
  variance?: Maybe<C_ShinglesSurfacing_Variance_Fields>;
};


/** aggregate fields of "C_ShinglesSurfacing" */
export type C_ShinglesSurfacing_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ShinglesSurfacing_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ShinglesSurfacing_Avg_Fields = {
  __typename?: 'C_ShinglesSurfacing_avg_fields';
  ShinglesSurfacingId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ShinglesSurfacing". All fields are combined with a logical 'AND'. */
export type C_ShinglesSurfacing_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  Shingles?: Maybe<Shingles_Bool_Exp>;
  ShinglesSurfacingCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ShinglesSurfacingId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_ShinglesSurfacing_Bool_Exp>>;
  _not?: Maybe<C_ShinglesSurfacing_Bool_Exp>;
  _or?: Maybe<Array<C_ShinglesSurfacing_Bool_Exp>>;
};

/** upsert condition type for table "C_ShinglesSurfacing" */
export type C_ShinglesSurfacing_If_Matched = {
  match_columns?: Array<C_ShinglesSurfacing_Insert_Match_Column>;
  update_columns?: Array<C_ShinglesSurfacing_Update_Column>;
  where?: Maybe<C_ShinglesSurfacing_Bool_Exp>;
};

/** input type for inserting data into table "C_ShinglesSurfacing" */
export type C_ShinglesSurfacing_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesSurfacingCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ShinglesSurfacing" */
export enum C_ShinglesSurfacing_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesSurfacingCode = 'ShinglesSurfacingCode',
  /** column name */
  ShinglesSurfacingId = 'ShinglesSurfacingId'
}

/** aggregate max on columns */
export type C_ShinglesSurfacing_Max_Fields = {
  __typename?: 'C_ShinglesSurfacing_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesSurfacingCode?: Maybe<Scalars['CodeTableValue']>;
  ShinglesSurfacingId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ShinglesSurfacing_Min_Fields = {
  __typename?: 'C_ShinglesSurfacing_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesSurfacingCode?: Maybe<Scalars['CodeTableValue']>;
  ShinglesSurfacingId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ShinglesSurfacing" */
export type C_ShinglesSurfacing_Mutation_Response = {
  __typename?: 'C_ShinglesSurfacing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ShinglesSurfacing>;
};

/** Ordering options when selecting data from "C_ShinglesSurfacing". */
export type C_ShinglesSurfacing_Order_By = {
  Dsc?: Maybe<Order_By>;
  ShinglesSurfacingCode?: Maybe<Order_By>;
  ShinglesSurfacingId?: Maybe<Order_By>;
  Shingles_aggregate?: Maybe<Shingles_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ShinglesSurfacing */
export type C_ShinglesSurfacing_Pk_Columns_Input = {
  ShinglesSurfacingId: Scalars['Int'];
};

/** select columns of table "C_ShinglesSurfacing" */
export enum C_ShinglesSurfacing_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesSurfacingCode = 'ShinglesSurfacingCode',
  /** column name */
  ShinglesSurfacingId = 'ShinglesSurfacingId'
}

/** input type for updating data in table "C_ShinglesSurfacing" */
export type C_ShinglesSurfacing_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesSurfacingCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ShinglesSurfacing_Stddev_Fields = {
  __typename?: 'C_ShinglesSurfacing_stddev_fields';
  ShinglesSurfacingId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ShinglesSurfacing_Stddev_Pop_Fields = {
  __typename?: 'C_ShinglesSurfacing_stddev_pop_fields';
  ShinglesSurfacingId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ShinglesSurfacing_Stddev_Samp_Fields = {
  __typename?: 'C_ShinglesSurfacing_stddev_samp_fields';
  ShinglesSurfacingId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ShinglesSurfacing_Sum_Fields = {
  __typename?: 'C_ShinglesSurfacing_sum_fields';
  ShinglesSurfacingId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ShinglesSurfacing" */
export enum C_ShinglesSurfacing_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesSurfacingCode = 'ShinglesSurfacingCode'
}

/** aggregate var_pop on columns */
export type C_ShinglesSurfacing_Var_Pop_Fields = {
  __typename?: 'C_ShinglesSurfacing_var_pop_fields';
  ShinglesSurfacingId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ShinglesSurfacing_Var_Samp_Fields = {
  __typename?: 'C_ShinglesSurfacing_var_samp_fields';
  ShinglesSurfacingId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ShinglesSurfacing_Variance_Fields = {
  __typename?: 'C_ShinglesSurfacing_variance_fields';
  ShinglesSurfacingId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ShinglesWindSpeed" */
export type C_ShinglesWindSpeed = {
  __typename?: 'C_ShinglesWindSpeed';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  Shingles: Array<Shingles>;
  ShinglesWindSpeedCode: Scalars['CodeTableValue'];
  ShinglesWindSpeedId: Scalars['Int'];
  /** An aggregate relationship */
  Shingles_aggregate: Shingles_Aggregate;
};


/** columns and relationships of "C_ShinglesWindSpeed" */
export type C_ShinglesWindSpeedShinglesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shingles_Order_By>>;
  where?: Maybe<Shingles_Bool_Exp>;
};


/** columns and relationships of "C_ShinglesWindSpeed" */
export type C_ShinglesWindSpeedShingles_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shingles_Order_By>>;
  where?: Maybe<Shingles_Bool_Exp>;
};

/** aggregated selection of "C_ShinglesWindSpeed" */
export type C_ShinglesWindSpeed_Aggregate = {
  __typename?: 'C_ShinglesWindSpeed_aggregate';
  aggregate?: Maybe<C_ShinglesWindSpeed_Aggregate_Fields>;
  nodes: Array<C_ShinglesWindSpeed>;
};

/** aggregate fields of "C_ShinglesWindSpeed" */
export type C_ShinglesWindSpeed_Aggregate_Fields = {
  __typename?: 'C_ShinglesWindSpeed_aggregate_fields';
  avg?: Maybe<C_ShinglesWindSpeed_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ShinglesWindSpeed_Max_Fields>;
  min?: Maybe<C_ShinglesWindSpeed_Min_Fields>;
  stddev?: Maybe<C_ShinglesWindSpeed_Stddev_Fields>;
  stddev_pop?: Maybe<C_ShinglesWindSpeed_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ShinglesWindSpeed_Stddev_Samp_Fields>;
  sum?: Maybe<C_ShinglesWindSpeed_Sum_Fields>;
  var_pop?: Maybe<C_ShinglesWindSpeed_Var_Pop_Fields>;
  var_samp?: Maybe<C_ShinglesWindSpeed_Var_Samp_Fields>;
  variance?: Maybe<C_ShinglesWindSpeed_Variance_Fields>;
};


/** aggregate fields of "C_ShinglesWindSpeed" */
export type C_ShinglesWindSpeed_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ShinglesWindSpeed_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ShinglesWindSpeed_Avg_Fields = {
  __typename?: 'C_ShinglesWindSpeed_avg_fields';
  ShinglesWindSpeedId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ShinglesWindSpeed". All fields are combined with a logical 'AND'. */
export type C_ShinglesWindSpeed_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  Shingles?: Maybe<Shingles_Bool_Exp>;
  ShinglesWindSpeedCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ShinglesWindSpeedId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_ShinglesWindSpeed_Bool_Exp>>;
  _not?: Maybe<C_ShinglesWindSpeed_Bool_Exp>;
  _or?: Maybe<Array<C_ShinglesWindSpeed_Bool_Exp>>;
};

/** upsert condition type for table "C_ShinglesWindSpeed" */
export type C_ShinglesWindSpeed_If_Matched = {
  match_columns?: Array<C_ShinglesWindSpeed_Insert_Match_Column>;
  update_columns?: Array<C_ShinglesWindSpeed_Update_Column>;
  where?: Maybe<C_ShinglesWindSpeed_Bool_Exp>;
};

/** input type for inserting data into table "C_ShinglesWindSpeed" */
export type C_ShinglesWindSpeed_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesWindSpeedCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ShinglesWindSpeed" */
export enum C_ShinglesWindSpeed_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesWindSpeedCode = 'ShinglesWindSpeedCode',
  /** column name */
  ShinglesWindSpeedId = 'ShinglesWindSpeedId'
}

/** aggregate max on columns */
export type C_ShinglesWindSpeed_Max_Fields = {
  __typename?: 'C_ShinglesWindSpeed_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesWindSpeedCode?: Maybe<Scalars['CodeTableValue']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ShinglesWindSpeed_Min_Fields = {
  __typename?: 'C_ShinglesWindSpeed_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesWindSpeedCode?: Maybe<Scalars['CodeTableValue']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ShinglesWindSpeed" */
export type C_ShinglesWindSpeed_Mutation_Response = {
  __typename?: 'C_ShinglesWindSpeed_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ShinglesWindSpeed>;
};

/** Ordering options when selecting data from "C_ShinglesWindSpeed". */
export type C_ShinglesWindSpeed_Order_By = {
  Dsc?: Maybe<Order_By>;
  ShinglesWindSpeedCode?: Maybe<Order_By>;
  ShinglesWindSpeedId?: Maybe<Order_By>;
  Shingles_aggregate?: Maybe<Shingles_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ShinglesWindSpeed */
export type C_ShinglesWindSpeed_Pk_Columns_Input = {
  ShinglesWindSpeedId: Scalars['Int'];
};

/** select columns of table "C_ShinglesWindSpeed" */
export enum C_ShinglesWindSpeed_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesWindSpeedCode = 'ShinglesWindSpeedCode',
  /** column name */
  ShinglesWindSpeedId = 'ShinglesWindSpeedId'
}

/** input type for updating data in table "C_ShinglesWindSpeed" */
export type C_ShinglesWindSpeed_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ShinglesWindSpeedCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ShinglesWindSpeed_Stddev_Fields = {
  __typename?: 'C_ShinglesWindSpeed_stddev_fields';
  ShinglesWindSpeedId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ShinglesWindSpeed_Stddev_Pop_Fields = {
  __typename?: 'C_ShinglesWindSpeed_stddev_pop_fields';
  ShinglesWindSpeedId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ShinglesWindSpeed_Stddev_Samp_Fields = {
  __typename?: 'C_ShinglesWindSpeed_stddev_samp_fields';
  ShinglesWindSpeedId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ShinglesWindSpeed_Sum_Fields = {
  __typename?: 'C_ShinglesWindSpeed_sum_fields';
  ShinglesWindSpeedId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ShinglesWindSpeed" */
export enum C_ShinglesWindSpeed_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ShinglesWindSpeedCode = 'ShinglesWindSpeedCode'
}

/** aggregate var_pop on columns */
export type C_ShinglesWindSpeed_Var_Pop_Fields = {
  __typename?: 'C_ShinglesWindSpeed_var_pop_fields';
  ShinglesWindSpeedId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ShinglesWindSpeed_Var_Samp_Fields = {
  __typename?: 'C_ShinglesWindSpeed_var_samp_fields';
  ShinglesWindSpeedId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ShinglesWindSpeed_Variance_Fields = {
  __typename?: 'C_ShinglesWindSpeed_variance_fields';
  ShinglesWindSpeedId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_SinglePlyMaterial" */
export type C_SinglePlyMaterial = {
  __typename?: 'C_SinglePlyMaterial';
  /** An array relationship */
  CoverStripDisks: Array<CoverStripDisk>;
  /** An aggregate relationship */
  CoverStripDisks_aggregate: CoverStripDisk_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  SinglePlyCovers: Array<SinglePlyCover>;
  /** An aggregate relationship */
  SinglePlyCovers_aggregate: SinglePlyCover_Aggregate;
  SinglePlyMaterialCode: Scalars['CodeTableValue'];
  SinglePlyMaterialId: Scalars['Int'];
};


/** columns and relationships of "C_SinglePlyMaterial" */
export type C_SinglePlyMaterialCoverStripDisksArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverStripDisk_Order_By>>;
  where?: Maybe<CoverStripDisk_Bool_Exp>;
};


/** columns and relationships of "C_SinglePlyMaterial" */
export type C_SinglePlyMaterialCoverStripDisks_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverStripDisk_Order_By>>;
  where?: Maybe<CoverStripDisk_Bool_Exp>;
};


/** columns and relationships of "C_SinglePlyMaterial" */
export type C_SinglePlyMaterialSinglePlyCoversArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyCover_Order_By>>;
  where?: Maybe<SinglePlyCover_Bool_Exp>;
};


/** columns and relationships of "C_SinglePlyMaterial" */
export type C_SinglePlyMaterialSinglePlyCovers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyCover_Order_By>>;
  where?: Maybe<SinglePlyCover_Bool_Exp>;
};

/** aggregated selection of "C_SinglePlyMaterial" */
export type C_SinglePlyMaterial_Aggregate = {
  __typename?: 'C_SinglePlyMaterial_aggregate';
  aggregate?: Maybe<C_SinglePlyMaterial_Aggregate_Fields>;
  nodes: Array<C_SinglePlyMaterial>;
};

/** aggregate fields of "C_SinglePlyMaterial" */
export type C_SinglePlyMaterial_Aggregate_Fields = {
  __typename?: 'C_SinglePlyMaterial_aggregate_fields';
  avg?: Maybe<C_SinglePlyMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_SinglePlyMaterial_Max_Fields>;
  min?: Maybe<C_SinglePlyMaterial_Min_Fields>;
  stddev?: Maybe<C_SinglePlyMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_SinglePlyMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_SinglePlyMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_SinglePlyMaterial_Sum_Fields>;
  var_pop?: Maybe<C_SinglePlyMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_SinglePlyMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_SinglePlyMaterial_Variance_Fields>;
};


/** aggregate fields of "C_SinglePlyMaterial" */
export type C_SinglePlyMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_SinglePlyMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_SinglePlyMaterial_Avg_Fields = {
  __typename?: 'C_SinglePlyMaterial_avg_fields';
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_SinglePlyMaterial". All fields are combined with a logical 'AND'. */
export type C_SinglePlyMaterial_Bool_Exp = {
  CoverStripDisks?: Maybe<CoverStripDisk_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  SinglePlyCovers?: Maybe<SinglePlyCover_Bool_Exp>;
  SinglePlyMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  SinglePlyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_SinglePlyMaterial_Bool_Exp>>;
  _not?: Maybe<C_SinglePlyMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_SinglePlyMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_SinglePlyMaterial" */
export type C_SinglePlyMaterial_If_Matched = {
  match_columns?: Array<C_SinglePlyMaterial_Insert_Match_Column>;
  update_columns?: Array<C_SinglePlyMaterial_Update_Column>;
  where?: Maybe<C_SinglePlyMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_SinglePlyMaterial" */
export type C_SinglePlyMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SinglePlyMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_SinglePlyMaterial" */
export enum C_SinglePlyMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SinglePlyMaterialCode = 'SinglePlyMaterialCode',
  /** column name */
  SinglePlyMaterialId = 'SinglePlyMaterialId'
}

/** aggregate max on columns */
export type C_SinglePlyMaterial_Max_Fields = {
  __typename?: 'C_SinglePlyMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SinglePlyMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_SinglePlyMaterial_Min_Fields = {
  __typename?: 'C_SinglePlyMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SinglePlyMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_SinglePlyMaterial" */
export type C_SinglePlyMaterial_Mutation_Response = {
  __typename?: 'C_SinglePlyMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_SinglePlyMaterial>;
};

/** Ordering options when selecting data from "C_SinglePlyMaterial". */
export type C_SinglePlyMaterial_Order_By = {
  CoverStripDisks_aggregate?: Maybe<CoverStripDisk_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  SinglePlyCovers_aggregate?: Maybe<SinglePlyCover_Aggregate_Order_By>;
  SinglePlyMaterialCode?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_SinglePlyMaterial */
export type C_SinglePlyMaterial_Pk_Columns_Input = {
  SinglePlyMaterialId: Scalars['Int'];
};

/** select columns of table "C_SinglePlyMaterial" */
export enum C_SinglePlyMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SinglePlyMaterialCode = 'SinglePlyMaterialCode',
  /** column name */
  SinglePlyMaterialId = 'SinglePlyMaterialId'
}

/** input type for updating data in table "C_SinglePlyMaterial" */
export type C_SinglePlyMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SinglePlyMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_SinglePlyMaterial_Stddev_Fields = {
  __typename?: 'C_SinglePlyMaterial_stddev_fields';
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_SinglePlyMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_SinglePlyMaterial_stddev_pop_fields';
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_SinglePlyMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_SinglePlyMaterial_stddev_samp_fields';
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_SinglePlyMaterial_Sum_Fields = {
  __typename?: 'C_SinglePlyMaterial_sum_fields';
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_SinglePlyMaterial" */
export enum C_SinglePlyMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SinglePlyMaterialCode = 'SinglePlyMaterialCode'
}

/** aggregate var_pop on columns */
export type C_SinglePlyMaterial_Var_Pop_Fields = {
  __typename?: 'C_SinglePlyMaterial_var_pop_fields';
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_SinglePlyMaterial_Var_Samp_Fields = {
  __typename?: 'C_SinglePlyMaterial_var_samp_fields';
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_SinglePlyMaterial_Variance_Fields = {
  __typename?: 'C_SinglePlyMaterial_variance_fields';
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_StandingLapSeamMaterial" */
export type C_StandingLapSeamMaterial = {
  __typename?: 'C_StandingLapSeamMaterial';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  StandingLapSeamCovers: Array<StandingLapSeamCover>;
  /** An aggregate relationship */
  StandingLapSeamCovers_aggregate: StandingLapSeamCover_Aggregate;
  StandingLapSeamMaterialCode: Scalars['CodeTableValue'];
  StandingLapSeamMaterialId: Scalars['Int'];
};


/** columns and relationships of "C_StandingLapSeamMaterial" */
export type C_StandingLapSeamMaterialStandingLapSeamCoversArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StandingLapSeamCover_Order_By>>;
  where?: Maybe<StandingLapSeamCover_Bool_Exp>;
};


/** columns and relationships of "C_StandingLapSeamMaterial" */
export type C_StandingLapSeamMaterialStandingLapSeamCovers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StandingLapSeamCover_Order_By>>;
  where?: Maybe<StandingLapSeamCover_Bool_Exp>;
};

/** aggregated selection of "C_StandingLapSeamMaterial" */
export type C_StandingLapSeamMaterial_Aggregate = {
  __typename?: 'C_StandingLapSeamMaterial_aggregate';
  aggregate?: Maybe<C_StandingLapSeamMaterial_Aggregate_Fields>;
  nodes: Array<C_StandingLapSeamMaterial>;
};

/** aggregate fields of "C_StandingLapSeamMaterial" */
export type C_StandingLapSeamMaterial_Aggregate_Fields = {
  __typename?: 'C_StandingLapSeamMaterial_aggregate_fields';
  avg?: Maybe<C_StandingLapSeamMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_StandingLapSeamMaterial_Max_Fields>;
  min?: Maybe<C_StandingLapSeamMaterial_Min_Fields>;
  stddev?: Maybe<C_StandingLapSeamMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_StandingLapSeamMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_StandingLapSeamMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_StandingLapSeamMaterial_Sum_Fields>;
  var_pop?: Maybe<C_StandingLapSeamMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_StandingLapSeamMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_StandingLapSeamMaterial_Variance_Fields>;
};


/** aggregate fields of "C_StandingLapSeamMaterial" */
export type C_StandingLapSeamMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_StandingLapSeamMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_StandingLapSeamMaterial_Avg_Fields = {
  __typename?: 'C_StandingLapSeamMaterial_avg_fields';
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_StandingLapSeamMaterial". All fields are combined with a logical 'AND'. */
export type C_StandingLapSeamMaterial_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  StandingLapSeamCovers?: Maybe<StandingLapSeamCover_Bool_Exp>;
  StandingLapSeamMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  StandingLapSeamMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_StandingLapSeamMaterial_Bool_Exp>>;
  _not?: Maybe<C_StandingLapSeamMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_StandingLapSeamMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_StandingLapSeamMaterial" */
export type C_StandingLapSeamMaterial_If_Matched = {
  match_columns?: Array<C_StandingLapSeamMaterial_Insert_Match_Column>;
  update_columns?: Array<C_StandingLapSeamMaterial_Update_Column>;
  where?: Maybe<C_StandingLapSeamMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_StandingLapSeamMaterial" */
export type C_StandingLapSeamMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StandingLapSeamMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_StandingLapSeamMaterial" */
export enum C_StandingLapSeamMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StandingLapSeamMaterialCode = 'StandingLapSeamMaterialCode',
  /** column name */
  StandingLapSeamMaterialId = 'StandingLapSeamMaterialId'
}

/** aggregate max on columns */
export type C_StandingLapSeamMaterial_Max_Fields = {
  __typename?: 'C_StandingLapSeamMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StandingLapSeamMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_StandingLapSeamMaterial_Min_Fields = {
  __typename?: 'C_StandingLapSeamMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StandingLapSeamMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_StandingLapSeamMaterial" */
export type C_StandingLapSeamMaterial_Mutation_Response = {
  __typename?: 'C_StandingLapSeamMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_StandingLapSeamMaterial>;
};

/** Ordering options when selecting data from "C_StandingLapSeamMaterial". */
export type C_StandingLapSeamMaterial_Order_By = {
  Dsc?: Maybe<Order_By>;
  StandingLapSeamCovers_aggregate?: Maybe<StandingLapSeamCover_Aggregate_Order_By>;
  StandingLapSeamMaterialCode?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_StandingLapSeamMaterial */
export type C_StandingLapSeamMaterial_Pk_Columns_Input = {
  StandingLapSeamMaterialId: Scalars['Int'];
};

/** select columns of table "C_StandingLapSeamMaterial" */
export enum C_StandingLapSeamMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StandingLapSeamMaterialCode = 'StandingLapSeamMaterialCode',
  /** column name */
  StandingLapSeamMaterialId = 'StandingLapSeamMaterialId'
}

/** input type for updating data in table "C_StandingLapSeamMaterial" */
export type C_StandingLapSeamMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StandingLapSeamMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_StandingLapSeamMaterial_Stddev_Fields = {
  __typename?: 'C_StandingLapSeamMaterial_stddev_fields';
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_StandingLapSeamMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_StandingLapSeamMaterial_stddev_pop_fields';
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_StandingLapSeamMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_StandingLapSeamMaterial_stddev_samp_fields';
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_StandingLapSeamMaterial_Sum_Fields = {
  __typename?: 'C_StandingLapSeamMaterial_sum_fields';
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_StandingLapSeamMaterial" */
export enum C_StandingLapSeamMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StandingLapSeamMaterialCode = 'StandingLapSeamMaterialCode'
}

/** aggregate var_pop on columns */
export type C_StandingLapSeamMaterial_Var_Pop_Fields = {
  __typename?: 'C_StandingLapSeamMaterial_var_pop_fields';
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_StandingLapSeamMaterial_Var_Samp_Fields = {
  __typename?: 'C_StandingLapSeamMaterial_var_samp_fields';
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_StandingLapSeamMaterial_Variance_Fields = {
  __typename?: 'C_StandingLapSeamMaterial_variance_fields';
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_StateProvince" */
export type C_StateProvince = {
  __typename?: 'C_StateProvince';
  /** An array relationship */
  Addresses: Array<Address>;
  /** An aggregate relationship */
  Addresses_aggregate: Address_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  R_Country_StateProvinces: Array<R_Country_StateProvince>;
  /** An aggregate relationship */
  R_Country_StateProvinces_aggregate: R_Country_StateProvince_Aggregate;
  StateProvinceCode: Scalars['CodeTableValue'];
  StateProvinceId: Scalars['Int'];
};


/** columns and relationships of "C_StateProvince" */
export type C_StateProvinceAddressesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** columns and relationships of "C_StateProvince" */
export type C_StateProvinceAddresses_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** columns and relationships of "C_StateProvince" */
export type C_StateProvinceR_Country_StateProvincesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Country_StateProvince_Order_By>>;
  where?: Maybe<R_Country_StateProvince_Bool_Exp>;
};


/** columns and relationships of "C_StateProvince" */
export type C_StateProvinceR_Country_StateProvinces_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Country_StateProvince_Order_By>>;
  where?: Maybe<R_Country_StateProvince_Bool_Exp>;
};

/** aggregated selection of "C_StateProvince" */
export type C_StateProvince_Aggregate = {
  __typename?: 'C_StateProvince_aggregate';
  aggregate?: Maybe<C_StateProvince_Aggregate_Fields>;
  nodes: Array<C_StateProvince>;
};

/** aggregate fields of "C_StateProvince" */
export type C_StateProvince_Aggregate_Fields = {
  __typename?: 'C_StateProvince_aggregate_fields';
  avg?: Maybe<C_StateProvince_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_StateProvince_Max_Fields>;
  min?: Maybe<C_StateProvince_Min_Fields>;
  stddev?: Maybe<C_StateProvince_Stddev_Fields>;
  stddev_pop?: Maybe<C_StateProvince_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_StateProvince_Stddev_Samp_Fields>;
  sum?: Maybe<C_StateProvince_Sum_Fields>;
  var_pop?: Maybe<C_StateProvince_Var_Pop_Fields>;
  var_samp?: Maybe<C_StateProvince_Var_Samp_Fields>;
  variance?: Maybe<C_StateProvince_Variance_Fields>;
};


/** aggregate fields of "C_StateProvince" */
export type C_StateProvince_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_StateProvince_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_StateProvince_Avg_Fields = {
  __typename?: 'C_StateProvince_avg_fields';
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_StateProvince". All fields are combined with a logical 'AND'. */
export type C_StateProvince_Bool_Exp = {
  Addresses?: Maybe<Address_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  R_Country_StateProvinces?: Maybe<R_Country_StateProvince_Bool_Exp>;
  StateProvinceCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  StateProvinceId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_StateProvince_Bool_Exp>>;
  _not?: Maybe<C_StateProvince_Bool_Exp>;
  _or?: Maybe<Array<C_StateProvince_Bool_Exp>>;
};

/** upsert condition type for table "C_StateProvince" */
export type C_StateProvince_If_Matched = {
  match_columns?: Array<C_StateProvince_Insert_Match_Column>;
  update_columns?: Array<C_StateProvince_Update_Column>;
  where?: Maybe<C_StateProvince_Bool_Exp>;
};

/** input type for inserting data into table "C_StateProvince" */
export type C_StateProvince_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StateProvinceCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_StateProvince" */
export enum C_StateProvince_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StateProvinceCode = 'StateProvinceCode',
  /** column name */
  StateProvinceId = 'StateProvinceId'
}

/** aggregate max on columns */
export type C_StateProvince_Max_Fields = {
  __typename?: 'C_StateProvince_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StateProvinceCode?: Maybe<Scalars['CodeTableValue']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_StateProvince_Min_Fields = {
  __typename?: 'C_StateProvince_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StateProvinceCode?: Maybe<Scalars['CodeTableValue']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_StateProvince" */
export type C_StateProvince_Mutation_Response = {
  __typename?: 'C_StateProvince_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_StateProvince>;
};

/** Ordering options when selecting data from "C_StateProvince". */
export type C_StateProvince_Order_By = {
  Addresses_aggregate?: Maybe<Address_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  R_Country_StateProvinces_aggregate?: Maybe<R_Country_StateProvince_Aggregate_Order_By>;
  StateProvinceCode?: Maybe<Order_By>;
  StateProvinceId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_StateProvince */
export type C_StateProvince_Pk_Columns_Input = {
  StateProvinceId: Scalars['Int'];
};

/** select columns of table "C_StateProvince" */
export enum C_StateProvince_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StateProvinceCode = 'StateProvinceCode',
  /** column name */
  StateProvinceId = 'StateProvinceId'
}

/** input type for updating data in table "C_StateProvince" */
export type C_StateProvince_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StateProvinceCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_StateProvince_Stddev_Fields = {
  __typename?: 'C_StateProvince_stddev_fields';
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_StateProvince_Stddev_Pop_Fields = {
  __typename?: 'C_StateProvince_stddev_pop_fields';
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_StateProvince_Stddev_Samp_Fields = {
  __typename?: 'C_StateProvince_stddev_samp_fields';
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_StateProvince_Sum_Fields = {
  __typename?: 'C_StateProvince_sum_fields';
  StateProvinceId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_StateProvince" */
export enum C_StateProvince_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StateProvinceCode = 'StateProvinceCode'
}

/** aggregate var_pop on columns */
export type C_StateProvince_Var_Pop_Fields = {
  __typename?: 'C_StateProvince_var_pop_fields';
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_StateProvince_Var_Samp_Fields = {
  __typename?: 'C_StateProvince_var_samp_fields';
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_StateProvince_Variance_Fields = {
  __typename?: 'C_StateProvince_variance_fields';
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_Status" */
export type C_Status = {
  __typename?: 'C_Status';
  /** An array relationship */
  ComponentApprovedUses: Array<ComponentApprovedUse>;
  /** An aggregate relationship */
  ComponentApprovedUses_aggregate: ComponentApprovedUse_Aggregate;
  /** An array relationship */
  Components: Array<Component>;
  /** An aggregate relationship */
  Components_aggregate: Component_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  Manufacturers: Array<Manufacturer>;
  /** An aggregate relationship */
  Manufacturers_aggregate: Manufacturer_Aggregate;
  /** An array relationship */
  NavAssemblies: Array<NavAssembly>;
  /** An aggregate relationship */
  NavAssemblies_aggregate: NavAssembly_Aggregate;
  /** An array relationship */
  NavLayers: Array<NavLayer>;
  /** An aggregate relationship */
  NavLayers_aggregate: NavLayer_Aggregate;
  /** An array relationship */
  RoofAssemblies: Array<RoofAssembly>;
  /** An aggregate relationship */
  RoofAssemblies_aggregate: RoofAssembly_Aggregate;
  StatusCode: Scalars['CodeTableValue'];
  StatusId: Scalars['Int'];
  /** An array relationship */
  Usages: Array<Usage>;
  /** An aggregate relationship */
  Usages_aggregate: Usage_Aggregate;
};


/** columns and relationships of "C_Status" */
export type C_StatusComponentApprovedUsesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentApprovedUse_Order_By>>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};


/** columns and relationships of "C_Status" */
export type C_StatusComponentApprovedUses_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentApprovedUse_Order_By>>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};


/** columns and relationships of "C_Status" */
export type C_StatusComponentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Order_By>>;
  where?: Maybe<Component_Bool_Exp>;
};


/** columns and relationships of "C_Status" */
export type C_StatusComponents_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Order_By>>;
  where?: Maybe<Component_Bool_Exp>;
};


/** columns and relationships of "C_Status" */
export type C_StatusManufacturersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manufacturer_Order_By>>;
  where?: Maybe<Manufacturer_Bool_Exp>;
};


/** columns and relationships of "C_Status" */
export type C_StatusManufacturers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manufacturer_Order_By>>;
  where?: Maybe<Manufacturer_Bool_Exp>;
};


/** columns and relationships of "C_Status" */
export type C_StatusNavAssembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssembly_Order_By>>;
  where?: Maybe<NavAssembly_Bool_Exp>;
};


/** columns and relationships of "C_Status" */
export type C_StatusNavAssemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssembly_Order_By>>;
  where?: Maybe<NavAssembly_Bool_Exp>;
};


/** columns and relationships of "C_Status" */
export type C_StatusNavLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavLayer_Order_By>>;
  where?: Maybe<NavLayer_Bool_Exp>;
};


/** columns and relationships of "C_Status" */
export type C_StatusNavLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavLayer_Order_By>>;
  where?: Maybe<NavLayer_Bool_Exp>;
};


/** columns and relationships of "C_Status" */
export type C_StatusRoofAssembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "C_Status" */
export type C_StatusRoofAssemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "C_Status" */
export type C_StatusUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Usage_Order_By>>;
  where?: Maybe<Usage_Bool_Exp>;
};


/** columns and relationships of "C_Status" */
export type C_StatusUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Usage_Order_By>>;
  where?: Maybe<Usage_Bool_Exp>;
};

/** aggregated selection of "C_Status" */
export type C_Status_Aggregate = {
  __typename?: 'C_Status_aggregate';
  aggregate?: Maybe<C_Status_Aggregate_Fields>;
  nodes: Array<C_Status>;
};

/** aggregate fields of "C_Status" */
export type C_Status_Aggregate_Fields = {
  __typename?: 'C_Status_aggregate_fields';
  avg?: Maybe<C_Status_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_Status_Max_Fields>;
  min?: Maybe<C_Status_Min_Fields>;
  stddev?: Maybe<C_Status_Stddev_Fields>;
  stddev_pop?: Maybe<C_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_Status_Stddev_Samp_Fields>;
  sum?: Maybe<C_Status_Sum_Fields>;
  var_pop?: Maybe<C_Status_Var_Pop_Fields>;
  var_samp?: Maybe<C_Status_Var_Samp_Fields>;
  variance?: Maybe<C_Status_Variance_Fields>;
};


/** aggregate fields of "C_Status" */
export type C_Status_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_Status_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_Status_Avg_Fields = {
  __typename?: 'C_Status_avg_fields';
  StatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_Status". All fields are combined with a logical 'AND'. */
export type C_Status_Bool_Exp = {
  ComponentApprovedUses?: Maybe<ComponentApprovedUse_Bool_Exp>;
  Components?: Maybe<Component_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  Manufacturers?: Maybe<Manufacturer_Bool_Exp>;
  NavAssemblies?: Maybe<NavAssembly_Bool_Exp>;
  NavLayers?: Maybe<NavLayer_Bool_Exp>;
  RoofAssemblies?: Maybe<RoofAssembly_Bool_Exp>;
  StatusCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  Usages?: Maybe<Usage_Bool_Exp>;
  _and?: Maybe<Array<C_Status_Bool_Exp>>;
  _not?: Maybe<C_Status_Bool_Exp>;
  _or?: Maybe<Array<C_Status_Bool_Exp>>;
};

/** upsert condition type for table "C_Status" */
export type C_Status_If_Matched = {
  match_columns?: Array<C_Status_Insert_Match_Column>;
  update_columns?: Array<C_Status_Update_Column>;
  where?: Maybe<C_Status_Bool_Exp>;
};

/** input type for inserting data into table "C_Status" */
export type C_Status_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_Status" */
export enum C_Status_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  StatusId = 'StatusId'
}

/** aggregate max on columns */
export type C_Status_Max_Fields = {
  __typename?: 'C_Status_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StatusCode?: Maybe<Scalars['CodeTableValue']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_Status_Min_Fields = {
  __typename?: 'C_Status_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StatusCode?: Maybe<Scalars['CodeTableValue']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_Status" */
export type C_Status_Mutation_Response = {
  __typename?: 'C_Status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_Status>;
};

/** Ordering options when selecting data from "C_Status". */
export type C_Status_Order_By = {
  ComponentApprovedUses_aggregate?: Maybe<ComponentApprovedUse_Aggregate_Order_By>;
  Components_aggregate?: Maybe<Component_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  Manufacturers_aggregate?: Maybe<Manufacturer_Aggregate_Order_By>;
  NavAssemblies_aggregate?: Maybe<NavAssembly_Aggregate_Order_By>;
  NavLayers_aggregate?: Maybe<NavLayer_Aggregate_Order_By>;
  RoofAssemblies_aggregate?: Maybe<RoofAssembly_Aggregate_Order_By>;
  StatusCode?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Usages_aggregate?: Maybe<Usage_Aggregate_Order_By>;
};

/** primary key columns input for table: C_Status */
export type C_Status_Pk_Columns_Input = {
  StatusId: Scalars['Int'];
};

/** select columns of table "C_Status" */
export enum C_Status_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StatusCode = 'StatusCode',
  /** column name */
  StatusId = 'StatusId'
}

/** input type for updating data in table "C_Status" */
export type C_Status_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_Status_Stddev_Fields = {
  __typename?: 'C_Status_stddev_fields';
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_Status_Stddev_Pop_Fields = {
  __typename?: 'C_Status_stddev_pop_fields';
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_Status_Stddev_Samp_Fields = {
  __typename?: 'C_Status_stddev_samp_fields';
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_Status_Sum_Fields = {
  __typename?: 'C_Status_sum_fields';
  StatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_Status" */
export enum C_Status_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StatusCode = 'StatusCode'
}

/** aggregate var_pop on columns */
export type C_Status_Var_Pop_Fields = {
  __typename?: 'C_Status_var_pop_fields';
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_Status_Var_Samp_Fields = {
  __typename?: 'C_Status_var_samp_fields';
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_Status_Variance_Fields = {
  __typename?: 'C_Status_variance_fields';
  StatusId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_SteelDeckRibType" */
export type C_SteelDeckRibType = {
  __typename?: 'C_SteelDeckRibType';
  Dsc: Scalars['CodeTableDescription'];
  SteelDeckRibTypeCode: Scalars['CodeTableValue'];
  SteelDeckRibTypeId: Scalars['Int'];
  /** An array relationship */
  SteelDecks: Array<SteelDeck>;
  /** An aggregate relationship */
  SteelDecks_aggregate: SteelDeck_Aggregate;
};


/** columns and relationships of "C_SteelDeckRibType" */
export type C_SteelDeckRibTypeSteelDecksArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SteelDeck_Order_By>>;
  where?: Maybe<SteelDeck_Bool_Exp>;
};


/** columns and relationships of "C_SteelDeckRibType" */
export type C_SteelDeckRibTypeSteelDecks_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SteelDeck_Order_By>>;
  where?: Maybe<SteelDeck_Bool_Exp>;
};

/** aggregated selection of "C_SteelDeckRibType" */
export type C_SteelDeckRibType_Aggregate = {
  __typename?: 'C_SteelDeckRibType_aggregate';
  aggregate?: Maybe<C_SteelDeckRibType_Aggregate_Fields>;
  nodes: Array<C_SteelDeckRibType>;
};

/** aggregate fields of "C_SteelDeckRibType" */
export type C_SteelDeckRibType_Aggregate_Fields = {
  __typename?: 'C_SteelDeckRibType_aggregate_fields';
  avg?: Maybe<C_SteelDeckRibType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_SteelDeckRibType_Max_Fields>;
  min?: Maybe<C_SteelDeckRibType_Min_Fields>;
  stddev?: Maybe<C_SteelDeckRibType_Stddev_Fields>;
  stddev_pop?: Maybe<C_SteelDeckRibType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_SteelDeckRibType_Stddev_Samp_Fields>;
  sum?: Maybe<C_SteelDeckRibType_Sum_Fields>;
  var_pop?: Maybe<C_SteelDeckRibType_Var_Pop_Fields>;
  var_samp?: Maybe<C_SteelDeckRibType_Var_Samp_Fields>;
  variance?: Maybe<C_SteelDeckRibType_Variance_Fields>;
};


/** aggregate fields of "C_SteelDeckRibType" */
export type C_SteelDeckRibType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_SteelDeckRibType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_SteelDeckRibType_Avg_Fields = {
  __typename?: 'C_SteelDeckRibType_avg_fields';
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_SteelDeckRibType". All fields are combined with a logical 'AND'. */
export type C_SteelDeckRibType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  SteelDeckRibTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  SteelDeckRibTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  SteelDecks?: Maybe<SteelDeck_Bool_Exp>;
  _and?: Maybe<Array<C_SteelDeckRibType_Bool_Exp>>;
  _not?: Maybe<C_SteelDeckRibType_Bool_Exp>;
  _or?: Maybe<Array<C_SteelDeckRibType_Bool_Exp>>;
};

/** upsert condition type for table "C_SteelDeckRibType" */
export type C_SteelDeckRibType_If_Matched = {
  match_columns?: Array<C_SteelDeckRibType_Insert_Match_Column>;
  update_columns?: Array<C_SteelDeckRibType_Update_Column>;
  where?: Maybe<C_SteelDeckRibType_Bool_Exp>;
};

/** input type for inserting data into table "C_SteelDeckRibType" */
export type C_SteelDeckRibType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SteelDeckRibTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_SteelDeckRibType" */
export enum C_SteelDeckRibType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SteelDeckRibTypeCode = 'SteelDeckRibTypeCode',
  /** column name */
  SteelDeckRibTypeId = 'SteelDeckRibTypeId'
}

/** aggregate max on columns */
export type C_SteelDeckRibType_Max_Fields = {
  __typename?: 'C_SteelDeckRibType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SteelDeckRibTypeCode?: Maybe<Scalars['CodeTableValue']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_SteelDeckRibType_Min_Fields = {
  __typename?: 'C_SteelDeckRibType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SteelDeckRibTypeCode?: Maybe<Scalars['CodeTableValue']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_SteelDeckRibType" */
export type C_SteelDeckRibType_Mutation_Response = {
  __typename?: 'C_SteelDeckRibType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_SteelDeckRibType>;
};

/** Ordering options when selecting data from "C_SteelDeckRibType". */
export type C_SteelDeckRibType_Order_By = {
  Dsc?: Maybe<Order_By>;
  SteelDeckRibTypeCode?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  SteelDecks_aggregate?: Maybe<SteelDeck_Aggregate_Order_By>;
};

/** primary key columns input for table: C_SteelDeckRibType */
export type C_SteelDeckRibType_Pk_Columns_Input = {
  SteelDeckRibTypeId: Scalars['Int'];
};

/** select columns of table "C_SteelDeckRibType" */
export enum C_SteelDeckRibType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SteelDeckRibTypeCode = 'SteelDeckRibTypeCode',
  /** column name */
  SteelDeckRibTypeId = 'SteelDeckRibTypeId'
}

/** input type for updating data in table "C_SteelDeckRibType" */
export type C_SteelDeckRibType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SteelDeckRibTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_SteelDeckRibType_Stddev_Fields = {
  __typename?: 'C_SteelDeckRibType_stddev_fields';
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_SteelDeckRibType_Stddev_Pop_Fields = {
  __typename?: 'C_SteelDeckRibType_stddev_pop_fields';
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_SteelDeckRibType_Stddev_Samp_Fields = {
  __typename?: 'C_SteelDeckRibType_stddev_samp_fields';
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_SteelDeckRibType_Sum_Fields = {
  __typename?: 'C_SteelDeckRibType_sum_fields';
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_SteelDeckRibType" */
export enum C_SteelDeckRibType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SteelDeckRibTypeCode = 'SteelDeckRibTypeCode'
}

/** aggregate var_pop on columns */
export type C_SteelDeckRibType_Var_Pop_Fields = {
  __typename?: 'C_SteelDeckRibType_var_pop_fields';
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_SteelDeckRibType_Var_Samp_Fields = {
  __typename?: 'C_SteelDeckRibType_var_samp_fields';
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_SteelDeckRibType_Variance_Fields = {
  __typename?: 'C_SteelDeckRibType_variance_fields';
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_StressDistributorType" */
export type C_StressDistributorType = {
  __typename?: 'C_StressDistributorType';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  PreassembledFasteningUsages: Array<PreassembledFasteningUsage>;
  /** An aggregate relationship */
  PreassembledFasteningUsages_aggregate: PreassembledFasteningUsage_Aggregate;
  /** An array relationship */
  PreassembledFastenings: Array<PreassembledFastening>;
  /** An aggregate relationship */
  PreassembledFastenings_aggregate: PreassembledFastening_Aggregate;
  StressDistributorTypeCode: Scalars['CodeTableValue'];
  StressDistributorTypeId: Scalars['Int'];
};


/** columns and relationships of "C_StressDistributorType" */
export type C_StressDistributorTypePreassembledFasteningUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFasteningUsage_Order_By>>;
  where?: Maybe<PreassembledFasteningUsage_Bool_Exp>;
};


/** columns and relationships of "C_StressDistributorType" */
export type C_StressDistributorTypePreassembledFasteningUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFasteningUsage_Order_By>>;
  where?: Maybe<PreassembledFasteningUsage_Bool_Exp>;
};


/** columns and relationships of "C_StressDistributorType" */
export type C_StressDistributorTypePreassembledFasteningsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};


/** columns and relationships of "C_StressDistributorType" */
export type C_StressDistributorTypePreassembledFastenings_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};

/** aggregated selection of "C_StressDistributorType" */
export type C_StressDistributorType_Aggregate = {
  __typename?: 'C_StressDistributorType_aggregate';
  aggregate?: Maybe<C_StressDistributorType_Aggregate_Fields>;
  nodes: Array<C_StressDistributorType>;
};

/** aggregate fields of "C_StressDistributorType" */
export type C_StressDistributorType_Aggregate_Fields = {
  __typename?: 'C_StressDistributorType_aggregate_fields';
  avg?: Maybe<C_StressDistributorType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_StressDistributorType_Max_Fields>;
  min?: Maybe<C_StressDistributorType_Min_Fields>;
  stddev?: Maybe<C_StressDistributorType_Stddev_Fields>;
  stddev_pop?: Maybe<C_StressDistributorType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_StressDistributorType_Stddev_Samp_Fields>;
  sum?: Maybe<C_StressDistributorType_Sum_Fields>;
  var_pop?: Maybe<C_StressDistributorType_Var_Pop_Fields>;
  var_samp?: Maybe<C_StressDistributorType_Var_Samp_Fields>;
  variance?: Maybe<C_StressDistributorType_Variance_Fields>;
};


/** aggregate fields of "C_StressDistributorType" */
export type C_StressDistributorType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_StressDistributorType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_StressDistributorType_Avg_Fields = {
  __typename?: 'C_StressDistributorType_avg_fields';
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_StressDistributorType". All fields are combined with a logical 'AND'. */
export type C_StressDistributorType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  PreassembledFasteningUsages?: Maybe<PreassembledFasteningUsage_Bool_Exp>;
  PreassembledFastenings?: Maybe<PreassembledFastening_Bool_Exp>;
  StressDistributorTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  StressDistributorTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_StressDistributorType_Bool_Exp>>;
  _not?: Maybe<C_StressDistributorType_Bool_Exp>;
  _or?: Maybe<Array<C_StressDistributorType_Bool_Exp>>;
};

/** upsert condition type for table "C_StressDistributorType" */
export type C_StressDistributorType_If_Matched = {
  match_columns?: Array<C_StressDistributorType_Insert_Match_Column>;
  update_columns?: Array<C_StressDistributorType_Update_Column>;
  where?: Maybe<C_StressDistributorType_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "C_StressDistributorType" */
export type C_StressDistributorType_Inc_Input = {
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "C_StressDistributorType" */
export type C_StressDistributorType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StressDistributorTypeCode?: Maybe<Scalars['CodeTableValue']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "C_StressDistributorType" */
export enum C_StressDistributorType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StressDistributorTypeCode = 'StressDistributorTypeCode',
  /** column name */
  StressDistributorTypeId = 'StressDistributorTypeId'
}

/** aggregate max on columns */
export type C_StressDistributorType_Max_Fields = {
  __typename?: 'C_StressDistributorType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StressDistributorTypeCode?: Maybe<Scalars['CodeTableValue']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_StressDistributorType_Min_Fields = {
  __typename?: 'C_StressDistributorType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StressDistributorTypeCode?: Maybe<Scalars['CodeTableValue']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_StressDistributorType" */
export type C_StressDistributorType_Mutation_Response = {
  __typename?: 'C_StressDistributorType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_StressDistributorType>;
};

/** Ordering options when selecting data from "C_StressDistributorType". */
export type C_StressDistributorType_Order_By = {
  Dsc?: Maybe<Order_By>;
  PreassembledFasteningUsages_aggregate?: Maybe<PreassembledFasteningUsage_Aggregate_Order_By>;
  PreassembledFastenings_aggregate?: Maybe<PreassembledFastening_Aggregate_Order_By>;
  StressDistributorTypeCode?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_StressDistributorType */
export type C_StressDistributorType_Pk_Columns_Input = {
  StressDistributorTypeId: Scalars['Int'];
};

/** select columns of table "C_StressDistributorType" */
export enum C_StressDistributorType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StressDistributorTypeCode = 'StressDistributorTypeCode',
  /** column name */
  StressDistributorTypeId = 'StressDistributorTypeId'
}

/** input type for updating data in table "C_StressDistributorType" */
export type C_StressDistributorType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StressDistributorTypeCode?: Maybe<Scalars['CodeTableValue']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type C_StressDistributorType_Stddev_Fields = {
  __typename?: 'C_StressDistributorType_stddev_fields';
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_StressDistributorType_Stddev_Pop_Fields = {
  __typename?: 'C_StressDistributorType_stddev_pop_fields';
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_StressDistributorType_Stddev_Samp_Fields = {
  __typename?: 'C_StressDistributorType_stddev_samp_fields';
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_StressDistributorType_Sum_Fields = {
  __typename?: 'C_StressDistributorType_sum_fields';
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_StressDistributorType" */
export enum C_StressDistributorType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StressDistributorTypeCode = 'StressDistributorTypeCode',
  /** column name */
  StressDistributorTypeId = 'StressDistributorTypeId'
}

/** aggregate var_pop on columns */
export type C_StressDistributorType_Var_Pop_Fields = {
  __typename?: 'C_StressDistributorType_var_pop_fields';
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_StressDistributorType_Var_Samp_Fields = {
  __typename?: 'C_StressDistributorType_var_samp_fields';
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_StressDistributorType_Variance_Fields = {
  __typename?: 'C_StressDistributorType_variance_fields';
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_StressPlateShape" */
export type C_StressPlateShape = {
  __typename?: 'C_StressPlateShape';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  PreassembledFastenings: Array<PreassembledFastening>;
  /** An aggregate relationship */
  PreassembledFastenings_aggregate: PreassembledFastening_Aggregate;
  StressPlateShapeCode: Scalars['CodeTableValue'];
  StressPlateShapeId: Scalars['Int'];
  /** An array relationship */
  StressPlates: Array<StressPlate>;
  /** An aggregate relationship */
  StressPlates_aggregate: StressPlate_Aggregate;
};


/** columns and relationships of "C_StressPlateShape" */
export type C_StressPlateShapePreassembledFasteningsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};


/** columns and relationships of "C_StressPlateShape" */
export type C_StressPlateShapePreassembledFastenings_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};


/** columns and relationships of "C_StressPlateShape" */
export type C_StressPlateShapeStressPlatesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StressPlate_Order_By>>;
  where?: Maybe<StressPlate_Bool_Exp>;
};


/** columns and relationships of "C_StressPlateShape" */
export type C_StressPlateShapeStressPlates_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StressPlate_Order_By>>;
  where?: Maybe<StressPlate_Bool_Exp>;
};

/** aggregated selection of "C_StressPlateShape" */
export type C_StressPlateShape_Aggregate = {
  __typename?: 'C_StressPlateShape_aggregate';
  aggregate?: Maybe<C_StressPlateShape_Aggregate_Fields>;
  nodes: Array<C_StressPlateShape>;
};

/** aggregate fields of "C_StressPlateShape" */
export type C_StressPlateShape_Aggregate_Fields = {
  __typename?: 'C_StressPlateShape_aggregate_fields';
  avg?: Maybe<C_StressPlateShape_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_StressPlateShape_Max_Fields>;
  min?: Maybe<C_StressPlateShape_Min_Fields>;
  stddev?: Maybe<C_StressPlateShape_Stddev_Fields>;
  stddev_pop?: Maybe<C_StressPlateShape_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_StressPlateShape_Stddev_Samp_Fields>;
  sum?: Maybe<C_StressPlateShape_Sum_Fields>;
  var_pop?: Maybe<C_StressPlateShape_Var_Pop_Fields>;
  var_samp?: Maybe<C_StressPlateShape_Var_Samp_Fields>;
  variance?: Maybe<C_StressPlateShape_Variance_Fields>;
};


/** aggregate fields of "C_StressPlateShape" */
export type C_StressPlateShape_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_StressPlateShape_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_StressPlateShape_Avg_Fields = {
  __typename?: 'C_StressPlateShape_avg_fields';
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_StressPlateShape". All fields are combined with a logical 'AND'. */
export type C_StressPlateShape_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  PreassembledFastenings?: Maybe<PreassembledFastening_Bool_Exp>;
  StressPlateShapeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  StressPlateShapeId?: Maybe<Int_Mssql_Comparison_Exp>;
  StressPlates?: Maybe<StressPlate_Bool_Exp>;
  _and?: Maybe<Array<C_StressPlateShape_Bool_Exp>>;
  _not?: Maybe<C_StressPlateShape_Bool_Exp>;
  _or?: Maybe<Array<C_StressPlateShape_Bool_Exp>>;
};

/** upsert condition type for table "C_StressPlateShape" */
export type C_StressPlateShape_If_Matched = {
  match_columns?: Array<C_StressPlateShape_Insert_Match_Column>;
  update_columns?: Array<C_StressPlateShape_Update_Column>;
  where?: Maybe<C_StressPlateShape_Bool_Exp>;
};

/** input type for inserting data into table "C_StressPlateShape" */
export type C_StressPlateShape_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StressPlateShapeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_StressPlateShape" */
export enum C_StressPlateShape_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StressPlateShapeCode = 'StressPlateShapeCode',
  /** column name */
  StressPlateShapeId = 'StressPlateShapeId'
}

/** aggregate max on columns */
export type C_StressPlateShape_Max_Fields = {
  __typename?: 'C_StressPlateShape_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StressPlateShapeCode?: Maybe<Scalars['CodeTableValue']>;
  StressPlateShapeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_StressPlateShape_Min_Fields = {
  __typename?: 'C_StressPlateShape_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StressPlateShapeCode?: Maybe<Scalars['CodeTableValue']>;
  StressPlateShapeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_StressPlateShape" */
export type C_StressPlateShape_Mutation_Response = {
  __typename?: 'C_StressPlateShape_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_StressPlateShape>;
};

/** Ordering options when selecting data from "C_StressPlateShape". */
export type C_StressPlateShape_Order_By = {
  Dsc?: Maybe<Order_By>;
  PreassembledFastenings_aggregate?: Maybe<PreassembledFastening_Aggregate_Order_By>;
  StressPlateShapeCode?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
  StressPlates_aggregate?: Maybe<StressPlate_Aggregate_Order_By>;
};

/** primary key columns input for table: C_StressPlateShape */
export type C_StressPlateShape_Pk_Columns_Input = {
  StressPlateShapeId: Scalars['Int'];
};

/** select columns of table "C_StressPlateShape" */
export enum C_StressPlateShape_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StressPlateShapeCode = 'StressPlateShapeCode',
  /** column name */
  StressPlateShapeId = 'StressPlateShapeId'
}

/** input type for updating data in table "C_StressPlateShape" */
export type C_StressPlateShape_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StressPlateShapeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_StressPlateShape_Stddev_Fields = {
  __typename?: 'C_StressPlateShape_stddev_fields';
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_StressPlateShape_Stddev_Pop_Fields = {
  __typename?: 'C_StressPlateShape_stddev_pop_fields';
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_StressPlateShape_Stddev_Samp_Fields = {
  __typename?: 'C_StressPlateShape_stddev_samp_fields';
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_StressPlateShape_Sum_Fields = {
  __typename?: 'C_StressPlateShape_sum_fields';
  StressPlateShapeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_StressPlateShape" */
export enum C_StressPlateShape_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StressPlateShapeCode = 'StressPlateShapeCode'
}

/** aggregate var_pop on columns */
export type C_StressPlateShape_Var_Pop_Fields = {
  __typename?: 'C_StressPlateShape_var_pop_fields';
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_StressPlateShape_Var_Samp_Fields = {
  __typename?: 'C_StressPlateShape_var_samp_fields';
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_StressPlateShape_Variance_Fields = {
  __typename?: 'C_StressPlateShape_variance_fields';
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_StructureType" */
export type C_StructureType = {
  __typename?: 'C_StructureType';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  StructureLayers: Array<StructureLayer>;
  /** An aggregate relationship */
  StructureLayers_aggregate: StructureLayer_Aggregate;
  StructureTypeCode: Scalars['CodeTableValue'];
  StructureTypeId: Scalars['Int'];
};


/** columns and relationships of "C_StructureType" */
export type C_StructureTypeStructureLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructureLayer_Order_By>>;
  where?: Maybe<StructureLayer_Bool_Exp>;
};


/** columns and relationships of "C_StructureType" */
export type C_StructureTypeStructureLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructureLayer_Order_By>>;
  where?: Maybe<StructureLayer_Bool_Exp>;
};

/** aggregated selection of "C_StructureType" */
export type C_StructureType_Aggregate = {
  __typename?: 'C_StructureType_aggregate';
  aggregate?: Maybe<C_StructureType_Aggregate_Fields>;
  nodes: Array<C_StructureType>;
};

/** aggregate fields of "C_StructureType" */
export type C_StructureType_Aggregate_Fields = {
  __typename?: 'C_StructureType_aggregate_fields';
  avg?: Maybe<C_StructureType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_StructureType_Max_Fields>;
  min?: Maybe<C_StructureType_Min_Fields>;
  stddev?: Maybe<C_StructureType_Stddev_Fields>;
  stddev_pop?: Maybe<C_StructureType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_StructureType_Stddev_Samp_Fields>;
  sum?: Maybe<C_StructureType_Sum_Fields>;
  var_pop?: Maybe<C_StructureType_Var_Pop_Fields>;
  var_samp?: Maybe<C_StructureType_Var_Samp_Fields>;
  variance?: Maybe<C_StructureType_Variance_Fields>;
};


/** aggregate fields of "C_StructureType" */
export type C_StructureType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_StructureType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_StructureType_Avg_Fields = {
  __typename?: 'C_StructureType_avg_fields';
  StructureTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_StructureType". All fields are combined with a logical 'AND'. */
export type C_StructureType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  StructureLayers?: Maybe<StructureLayer_Bool_Exp>;
  StructureTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  StructureTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_StructureType_Bool_Exp>>;
  _not?: Maybe<C_StructureType_Bool_Exp>;
  _or?: Maybe<Array<C_StructureType_Bool_Exp>>;
};

/** upsert condition type for table "C_StructureType" */
export type C_StructureType_If_Matched = {
  match_columns?: Array<C_StructureType_Insert_Match_Column>;
  update_columns?: Array<C_StructureType_Update_Column>;
  where?: Maybe<C_StructureType_Bool_Exp>;
};

/** input type for inserting data into table "C_StructureType" */
export type C_StructureType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StructureTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_StructureType" */
export enum C_StructureType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StructureTypeCode = 'StructureTypeCode',
  /** column name */
  StructureTypeId = 'StructureTypeId'
}

/** aggregate max on columns */
export type C_StructureType_Max_Fields = {
  __typename?: 'C_StructureType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StructureTypeCode?: Maybe<Scalars['CodeTableValue']>;
  StructureTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_StructureType_Min_Fields = {
  __typename?: 'C_StructureType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StructureTypeCode?: Maybe<Scalars['CodeTableValue']>;
  StructureTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_StructureType" */
export type C_StructureType_Mutation_Response = {
  __typename?: 'C_StructureType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_StructureType>;
};

/** Ordering options when selecting data from "C_StructureType". */
export type C_StructureType_Order_By = {
  Dsc?: Maybe<Order_By>;
  StructureLayers_aggregate?: Maybe<StructureLayer_Aggregate_Order_By>;
  StructureTypeCode?: Maybe<Order_By>;
  StructureTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_StructureType */
export type C_StructureType_Pk_Columns_Input = {
  StructureTypeId: Scalars['Int'];
};

/** select columns of table "C_StructureType" */
export enum C_StructureType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StructureTypeCode = 'StructureTypeCode',
  /** column name */
  StructureTypeId = 'StructureTypeId'
}

/** input type for updating data in table "C_StructureType" */
export type C_StructureType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  StructureTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_StructureType_Stddev_Fields = {
  __typename?: 'C_StructureType_stddev_fields';
  StructureTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_StructureType_Stddev_Pop_Fields = {
  __typename?: 'C_StructureType_stddev_pop_fields';
  StructureTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_StructureType_Stddev_Samp_Fields = {
  __typename?: 'C_StructureType_stddev_samp_fields';
  StructureTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_StructureType_Sum_Fields = {
  __typename?: 'C_StructureType_sum_fields';
  StructureTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_StructureType" */
export enum C_StructureType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  StructureTypeCode = 'StructureTypeCode'
}

/** aggregate var_pop on columns */
export type C_StructureType_Var_Pop_Fields = {
  __typename?: 'C_StructureType_var_pop_fields';
  StructureTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_StructureType_Var_Samp_Fields = {
  __typename?: 'C_StructureType_var_samp_fields';
  StructureTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_StructureType_Variance_Fields = {
  __typename?: 'C_StructureType_variance_fields';
  StructureTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_SubassemblyType" */
export type C_SubassemblyType = {
  __typename?: 'C_SubassemblyType';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  /** An array relationship */
  NavMultiplyCoverConfigs: Array<NavMultiplyCoverConfig>;
  /** An aggregate relationship */
  NavMultiplyCoverConfigs_aggregate: NavMultiplyCoverConfig_Aggregate;
  /** An array relationship */
  Subassemblies: Array<Subassembly>;
  /** An aggregate relationship */
  Subassemblies_aggregate: Subassembly_Aggregate;
  SubassemblyTypeCode: Scalars['CodeTableValue'];
  SubassemblyTypeId: Scalars['Int'];
};


/** columns and relationships of "C_SubassemblyType" */
export type C_SubassemblyTypeNavMultiplyCoverConfigsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavMultiplyCoverConfig_Order_By>>;
  where?: Maybe<NavMultiplyCoverConfig_Bool_Exp>;
};


/** columns and relationships of "C_SubassemblyType" */
export type C_SubassemblyTypeNavMultiplyCoverConfigs_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavMultiplyCoverConfig_Order_By>>;
  where?: Maybe<NavMultiplyCoverConfig_Bool_Exp>;
};


/** columns and relationships of "C_SubassemblyType" */
export type C_SubassemblyTypeSubassembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subassembly_Order_By>>;
  where?: Maybe<Subassembly_Bool_Exp>;
};


/** columns and relationships of "C_SubassemblyType" */
export type C_SubassemblyTypeSubassemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subassembly_Order_By>>;
  where?: Maybe<Subassembly_Bool_Exp>;
};

/** aggregated selection of "C_SubassemblyType" */
export type C_SubassemblyType_Aggregate = {
  __typename?: 'C_SubassemblyType_aggregate';
  aggregate?: Maybe<C_SubassemblyType_Aggregate_Fields>;
  nodes: Array<C_SubassemblyType>;
};

/** aggregate fields of "C_SubassemblyType" */
export type C_SubassemblyType_Aggregate_Fields = {
  __typename?: 'C_SubassemblyType_aggregate_fields';
  avg?: Maybe<C_SubassemblyType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_SubassemblyType_Max_Fields>;
  min?: Maybe<C_SubassemblyType_Min_Fields>;
  stddev?: Maybe<C_SubassemblyType_Stddev_Fields>;
  stddev_pop?: Maybe<C_SubassemblyType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_SubassemblyType_Stddev_Samp_Fields>;
  sum?: Maybe<C_SubassemblyType_Sum_Fields>;
  var_pop?: Maybe<C_SubassemblyType_Var_Pop_Fields>;
  var_samp?: Maybe<C_SubassemblyType_Var_Samp_Fields>;
  variance?: Maybe<C_SubassemblyType_Variance_Fields>;
};


/** aggregate fields of "C_SubassemblyType" */
export type C_SubassemblyType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_SubassemblyType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_SubassemblyType_Avg_Fields = {
  __typename?: 'C_SubassemblyType_avg_fields';
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_SubassemblyType". All fields are combined with a logical 'AND'. */
export type C_SubassemblyType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  NavMultiplyCoverConfigs?: Maybe<NavMultiplyCoverConfig_Bool_Exp>;
  Subassemblies?: Maybe<Subassembly_Bool_Exp>;
  SubassemblyTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  SubassemblyTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_SubassemblyType_Bool_Exp>>;
  _not?: Maybe<C_SubassemblyType_Bool_Exp>;
  _or?: Maybe<Array<C_SubassemblyType_Bool_Exp>>;
};

/** upsert condition type for table "C_SubassemblyType" */
export type C_SubassemblyType_If_Matched = {
  match_columns?: Array<C_SubassemblyType_Insert_Match_Column>;
  update_columns?: Array<C_SubassemblyType_Update_Column>;
  where?: Maybe<C_SubassemblyType_Bool_Exp>;
};

/** input type for inserting data into table "C_SubassemblyType" */
export type C_SubassemblyType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SubassemblyTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_SubassemblyType" */
export enum C_SubassemblyType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SubassemblyTypeCode = 'SubassemblyTypeCode',
  /** column name */
  SubassemblyTypeId = 'SubassemblyTypeId'
}

/** aggregate max on columns */
export type C_SubassemblyType_Max_Fields = {
  __typename?: 'C_SubassemblyType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SubassemblyTypeCode?: Maybe<Scalars['CodeTableValue']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_SubassemblyType_Min_Fields = {
  __typename?: 'C_SubassemblyType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SubassemblyTypeCode?: Maybe<Scalars['CodeTableValue']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_SubassemblyType" */
export type C_SubassemblyType_Mutation_Response = {
  __typename?: 'C_SubassemblyType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_SubassemblyType>;
};

/** Ordering options when selecting data from "C_SubassemblyType". */
export type C_SubassemblyType_Order_By = {
  Dsc?: Maybe<Order_By>;
  NavMultiplyCoverConfigs_aggregate?: Maybe<NavMultiplyCoverConfig_Aggregate_Order_By>;
  Subassemblies_aggregate?: Maybe<Subassembly_Aggregate_Order_By>;
  SubassemblyTypeCode?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_SubassemblyType */
export type C_SubassemblyType_Pk_Columns_Input = {
  SubassemblyTypeId: Scalars['Int'];
};

/** select columns of table "C_SubassemblyType" */
export enum C_SubassemblyType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SubassemblyTypeCode = 'SubassemblyTypeCode',
  /** column name */
  SubassemblyTypeId = 'SubassemblyTypeId'
}

/** input type for updating data in table "C_SubassemblyType" */
export type C_SubassemblyType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SubassemblyTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_SubassemblyType_Stddev_Fields = {
  __typename?: 'C_SubassemblyType_stddev_fields';
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_SubassemblyType_Stddev_Pop_Fields = {
  __typename?: 'C_SubassemblyType_stddev_pop_fields';
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_SubassemblyType_Stddev_Samp_Fields = {
  __typename?: 'C_SubassemblyType_stddev_samp_fields';
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_SubassemblyType_Sum_Fields = {
  __typename?: 'C_SubassemblyType_sum_fields';
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_SubassemblyType" */
export enum C_SubassemblyType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SubassemblyTypeCode = 'SubassemblyTypeCode'
}

/** aggregate var_pop on columns */
export type C_SubassemblyType_Var_Pop_Fields = {
  __typename?: 'C_SubassemblyType_var_pop_fields';
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_SubassemblyType_Var_Samp_Fields = {
  __typename?: 'C_SubassemblyType_var_samp_fields';
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_SubassemblyType_Variance_Fields = {
  __typename?: 'C_SubassemblyType_variance_fields';
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_Substrate" */
export type C_Substrate = {
  __typename?: 'C_Substrate';
  Dsc: Scalars['CodeTableDescription'];
  SubstrateCode: Scalars['CodeTableValue'];
  SubstrateId: Scalars['Int'];
};

/** columns and relationships of "C_SubstratePrimerMaterial" */
export type C_SubstratePrimerMaterial = {
  __typename?: 'C_SubstratePrimerMaterial';
  Dsc: Scalars['CodeTableDescription'];
  SubstratePrimerMaterialCode: Scalars['CodeTableValue'];
  SubstratePrimerMaterialId: Scalars['Int'];
  /** An array relationship */
  SubstratePrimers: Array<SubstratePrimer>;
  /** An aggregate relationship */
  SubstratePrimers_aggregate: SubstratePrimer_Aggregate;
};


/** columns and relationships of "C_SubstratePrimerMaterial" */
export type C_SubstratePrimerMaterialSubstratePrimersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubstratePrimer_Order_By>>;
  where?: Maybe<SubstratePrimer_Bool_Exp>;
};


/** columns and relationships of "C_SubstratePrimerMaterial" */
export type C_SubstratePrimerMaterialSubstratePrimers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubstratePrimer_Order_By>>;
  where?: Maybe<SubstratePrimer_Bool_Exp>;
};

/** aggregated selection of "C_SubstratePrimerMaterial" */
export type C_SubstratePrimerMaterial_Aggregate = {
  __typename?: 'C_SubstratePrimerMaterial_aggregate';
  aggregate?: Maybe<C_SubstratePrimerMaterial_Aggregate_Fields>;
  nodes: Array<C_SubstratePrimerMaterial>;
};

/** aggregate fields of "C_SubstratePrimerMaterial" */
export type C_SubstratePrimerMaterial_Aggregate_Fields = {
  __typename?: 'C_SubstratePrimerMaterial_aggregate_fields';
  avg?: Maybe<C_SubstratePrimerMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_SubstratePrimerMaterial_Max_Fields>;
  min?: Maybe<C_SubstratePrimerMaterial_Min_Fields>;
  stddev?: Maybe<C_SubstratePrimerMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_SubstratePrimerMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_SubstratePrimerMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_SubstratePrimerMaterial_Sum_Fields>;
  var_pop?: Maybe<C_SubstratePrimerMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_SubstratePrimerMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_SubstratePrimerMaterial_Variance_Fields>;
};


/** aggregate fields of "C_SubstratePrimerMaterial" */
export type C_SubstratePrimerMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_SubstratePrimerMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_SubstratePrimerMaterial_Avg_Fields = {
  __typename?: 'C_SubstratePrimerMaterial_avg_fields';
  SubstratePrimerMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_SubstratePrimerMaterial". All fields are combined with a logical 'AND'. */
export type C_SubstratePrimerMaterial_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  SubstratePrimerMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  SubstratePrimerMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubstratePrimers?: Maybe<SubstratePrimer_Bool_Exp>;
  _and?: Maybe<Array<C_SubstratePrimerMaterial_Bool_Exp>>;
  _not?: Maybe<C_SubstratePrimerMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_SubstratePrimerMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_SubstratePrimerMaterial" */
export type C_SubstratePrimerMaterial_If_Matched = {
  match_columns?: Array<C_SubstratePrimerMaterial_Insert_Match_Column>;
  update_columns?: Array<C_SubstratePrimerMaterial_Update_Column>;
  where?: Maybe<C_SubstratePrimerMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_SubstratePrimerMaterial" */
export type C_SubstratePrimerMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SubstratePrimerMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_SubstratePrimerMaterial" */
export enum C_SubstratePrimerMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SubstratePrimerMaterialCode = 'SubstratePrimerMaterialCode',
  /** column name */
  SubstratePrimerMaterialId = 'SubstratePrimerMaterialId'
}

/** aggregate max on columns */
export type C_SubstratePrimerMaterial_Max_Fields = {
  __typename?: 'C_SubstratePrimerMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SubstratePrimerMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_SubstratePrimerMaterial_Min_Fields = {
  __typename?: 'C_SubstratePrimerMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SubstratePrimerMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_SubstratePrimerMaterial" */
export type C_SubstratePrimerMaterial_Mutation_Response = {
  __typename?: 'C_SubstratePrimerMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_SubstratePrimerMaterial>;
};

/** Ordering options when selecting data from "C_SubstratePrimerMaterial". */
export type C_SubstratePrimerMaterial_Order_By = {
  Dsc?: Maybe<Order_By>;
  SubstratePrimerMaterialCode?: Maybe<Order_By>;
  SubstratePrimerMaterialId?: Maybe<Order_By>;
  SubstratePrimers_aggregate?: Maybe<SubstratePrimer_Aggregate_Order_By>;
};

/** primary key columns input for table: C_SubstratePrimerMaterial */
export type C_SubstratePrimerMaterial_Pk_Columns_Input = {
  SubstratePrimerMaterialId: Scalars['Int'];
};

/** select columns of table "C_SubstratePrimerMaterial" */
export enum C_SubstratePrimerMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SubstratePrimerMaterialCode = 'SubstratePrimerMaterialCode',
  /** column name */
  SubstratePrimerMaterialId = 'SubstratePrimerMaterialId'
}

/** input type for updating data in table "C_SubstratePrimerMaterial" */
export type C_SubstratePrimerMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SubstratePrimerMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_SubstratePrimerMaterial_Stddev_Fields = {
  __typename?: 'C_SubstratePrimerMaterial_stddev_fields';
  SubstratePrimerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_SubstratePrimerMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_SubstratePrimerMaterial_stddev_pop_fields';
  SubstratePrimerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_SubstratePrimerMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_SubstratePrimerMaterial_stddev_samp_fields';
  SubstratePrimerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_SubstratePrimerMaterial_Sum_Fields = {
  __typename?: 'C_SubstratePrimerMaterial_sum_fields';
  SubstratePrimerMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_SubstratePrimerMaterial" */
export enum C_SubstratePrimerMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SubstratePrimerMaterialCode = 'SubstratePrimerMaterialCode'
}

/** aggregate var_pop on columns */
export type C_SubstratePrimerMaterial_Var_Pop_Fields = {
  __typename?: 'C_SubstratePrimerMaterial_var_pop_fields';
  SubstratePrimerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_SubstratePrimerMaterial_Var_Samp_Fields = {
  __typename?: 'C_SubstratePrimerMaterial_var_samp_fields';
  SubstratePrimerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_SubstratePrimerMaterial_Variance_Fields = {
  __typename?: 'C_SubstratePrimerMaterial_variance_fields';
  SubstratePrimerMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "C_Substrate" */
export type C_Substrate_Aggregate = {
  __typename?: 'C_Substrate_aggregate';
  aggregate?: Maybe<C_Substrate_Aggregate_Fields>;
  nodes: Array<C_Substrate>;
};

/** aggregate fields of "C_Substrate" */
export type C_Substrate_Aggregate_Fields = {
  __typename?: 'C_Substrate_aggregate_fields';
  avg?: Maybe<C_Substrate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_Substrate_Max_Fields>;
  min?: Maybe<C_Substrate_Min_Fields>;
  stddev?: Maybe<C_Substrate_Stddev_Fields>;
  stddev_pop?: Maybe<C_Substrate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_Substrate_Stddev_Samp_Fields>;
  sum?: Maybe<C_Substrate_Sum_Fields>;
  var_pop?: Maybe<C_Substrate_Var_Pop_Fields>;
  var_samp?: Maybe<C_Substrate_Var_Samp_Fields>;
  variance?: Maybe<C_Substrate_Variance_Fields>;
};


/** aggregate fields of "C_Substrate" */
export type C_Substrate_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_Substrate_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_Substrate_Avg_Fields = {
  __typename?: 'C_Substrate_avg_fields';
  SubstrateId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_Substrate". All fields are combined with a logical 'AND'. */
export type C_Substrate_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  SubstrateCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  SubstrateId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_Substrate_Bool_Exp>>;
  _not?: Maybe<C_Substrate_Bool_Exp>;
  _or?: Maybe<Array<C_Substrate_Bool_Exp>>;
};

/** upsert condition type for table "C_Substrate" */
export type C_Substrate_If_Matched = {
  match_columns?: Array<C_Substrate_Insert_Match_Column>;
  update_columns?: Array<C_Substrate_Update_Column>;
  where?: Maybe<C_Substrate_Bool_Exp>;
};

/** input type for inserting data into table "C_Substrate" */
export type C_Substrate_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SubstrateCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_Substrate" */
export enum C_Substrate_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SubstrateCode = 'SubstrateCode',
  /** column name */
  SubstrateId = 'SubstrateId'
}

/** aggregate max on columns */
export type C_Substrate_Max_Fields = {
  __typename?: 'C_Substrate_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SubstrateCode?: Maybe<Scalars['CodeTableValue']>;
  SubstrateId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_Substrate_Min_Fields = {
  __typename?: 'C_Substrate_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SubstrateCode?: Maybe<Scalars['CodeTableValue']>;
  SubstrateId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_Substrate" */
export type C_Substrate_Mutation_Response = {
  __typename?: 'C_Substrate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_Substrate>;
};

/** Ordering options when selecting data from "C_Substrate". */
export type C_Substrate_Order_By = {
  Dsc?: Maybe<Order_By>;
  SubstrateCode?: Maybe<Order_By>;
  SubstrateId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_Substrate */
export type C_Substrate_Pk_Columns_Input = {
  SubstrateId: Scalars['Int'];
};

/** select columns of table "C_Substrate" */
export enum C_Substrate_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SubstrateCode = 'SubstrateCode',
  /** column name */
  SubstrateId = 'SubstrateId'
}

/** input type for updating data in table "C_Substrate" */
export type C_Substrate_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SubstrateCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_Substrate_Stddev_Fields = {
  __typename?: 'C_Substrate_stddev_fields';
  SubstrateId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_Substrate_Stddev_Pop_Fields = {
  __typename?: 'C_Substrate_stddev_pop_fields';
  SubstrateId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_Substrate_Stddev_Samp_Fields = {
  __typename?: 'C_Substrate_stddev_samp_fields';
  SubstrateId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_Substrate_Sum_Fields = {
  __typename?: 'C_Substrate_sum_fields';
  SubstrateId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_Substrate" */
export enum C_Substrate_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SubstrateCode = 'SubstrateCode'
}

/** aggregate var_pop on columns */
export type C_Substrate_Var_Pop_Fields = {
  __typename?: 'C_Substrate_var_pop_fields';
  SubstrateId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_Substrate_Var_Samp_Fields = {
  __typename?: 'C_Substrate_var_samp_fields';
  SubstrateId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_Substrate_Variance_Fields = {
  __typename?: 'C_Substrate_variance_fields';
  SubstrateId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_SurfaceType" */
export type C_SurfaceType = {
  __typename?: 'C_SurfaceType';
  /** An array relationship */
  CapPlies: Array<CapPly>;
  /** An aggregate relationship */
  CapPlies_aggregate: CapPly_Aggregate;
  Dsc: Scalars['CodeTableDescription'];
  SurfaceTypeCode: Scalars['CodeTableValue'];
  SurfaceTypeId: Scalars['Int'];
};


/** columns and relationships of "C_SurfaceType" */
export type C_SurfaceTypeCapPliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPly_Order_By>>;
  where?: Maybe<CapPly_Bool_Exp>;
};


/** columns and relationships of "C_SurfaceType" */
export type C_SurfaceTypeCapPlies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPly_Order_By>>;
  where?: Maybe<CapPly_Bool_Exp>;
};

/** aggregated selection of "C_SurfaceType" */
export type C_SurfaceType_Aggregate = {
  __typename?: 'C_SurfaceType_aggregate';
  aggregate?: Maybe<C_SurfaceType_Aggregate_Fields>;
  nodes: Array<C_SurfaceType>;
};

/** aggregate fields of "C_SurfaceType" */
export type C_SurfaceType_Aggregate_Fields = {
  __typename?: 'C_SurfaceType_aggregate_fields';
  avg?: Maybe<C_SurfaceType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_SurfaceType_Max_Fields>;
  min?: Maybe<C_SurfaceType_Min_Fields>;
  stddev?: Maybe<C_SurfaceType_Stddev_Fields>;
  stddev_pop?: Maybe<C_SurfaceType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_SurfaceType_Stddev_Samp_Fields>;
  sum?: Maybe<C_SurfaceType_Sum_Fields>;
  var_pop?: Maybe<C_SurfaceType_Var_Pop_Fields>;
  var_samp?: Maybe<C_SurfaceType_Var_Samp_Fields>;
  variance?: Maybe<C_SurfaceType_Variance_Fields>;
};


/** aggregate fields of "C_SurfaceType" */
export type C_SurfaceType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_SurfaceType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_SurfaceType_Avg_Fields = {
  __typename?: 'C_SurfaceType_avg_fields';
  SurfaceTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_SurfaceType". All fields are combined with a logical 'AND'. */
export type C_SurfaceType_Bool_Exp = {
  CapPlies?: Maybe<CapPly_Bool_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  SurfaceTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  SurfaceTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_SurfaceType_Bool_Exp>>;
  _not?: Maybe<C_SurfaceType_Bool_Exp>;
  _or?: Maybe<Array<C_SurfaceType_Bool_Exp>>;
};

/** upsert condition type for table "C_SurfaceType" */
export type C_SurfaceType_If_Matched = {
  match_columns?: Array<C_SurfaceType_Insert_Match_Column>;
  update_columns?: Array<C_SurfaceType_Update_Column>;
  where?: Maybe<C_SurfaceType_Bool_Exp>;
};

/** input type for inserting data into table "C_SurfaceType" */
export type C_SurfaceType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SurfaceTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_SurfaceType" */
export enum C_SurfaceType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SurfaceTypeCode = 'SurfaceTypeCode',
  /** column name */
  SurfaceTypeId = 'SurfaceTypeId'
}

/** aggregate max on columns */
export type C_SurfaceType_Max_Fields = {
  __typename?: 'C_SurfaceType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SurfaceTypeCode?: Maybe<Scalars['CodeTableValue']>;
  SurfaceTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_SurfaceType_Min_Fields = {
  __typename?: 'C_SurfaceType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SurfaceTypeCode?: Maybe<Scalars['CodeTableValue']>;
  SurfaceTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_SurfaceType" */
export type C_SurfaceType_Mutation_Response = {
  __typename?: 'C_SurfaceType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_SurfaceType>;
};

/** Ordering options when selecting data from "C_SurfaceType". */
export type C_SurfaceType_Order_By = {
  CapPlies_aggregate?: Maybe<CapPly_Aggregate_Order_By>;
  Dsc?: Maybe<Order_By>;
  SurfaceTypeCode?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_SurfaceType */
export type C_SurfaceType_Pk_Columns_Input = {
  SurfaceTypeId: Scalars['Int'];
};

/** select columns of table "C_SurfaceType" */
export enum C_SurfaceType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SurfaceTypeCode = 'SurfaceTypeCode',
  /** column name */
  SurfaceTypeId = 'SurfaceTypeId'
}

/** input type for updating data in table "C_SurfaceType" */
export type C_SurfaceType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SurfaceTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_SurfaceType_Stddev_Fields = {
  __typename?: 'C_SurfaceType_stddev_fields';
  SurfaceTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_SurfaceType_Stddev_Pop_Fields = {
  __typename?: 'C_SurfaceType_stddev_pop_fields';
  SurfaceTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_SurfaceType_Stddev_Samp_Fields = {
  __typename?: 'C_SurfaceType_stddev_samp_fields';
  SurfaceTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_SurfaceType_Sum_Fields = {
  __typename?: 'C_SurfaceType_sum_fields';
  SurfaceTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_SurfaceType" */
export enum C_SurfaceType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SurfaceTypeCode = 'SurfaceTypeCode'
}

/** aggregate var_pop on columns */
export type C_SurfaceType_Var_Pop_Fields = {
  __typename?: 'C_SurfaceType_var_pop_fields';
  SurfaceTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_SurfaceType_Var_Samp_Fields = {
  __typename?: 'C_SurfaceType_var_samp_fields';
  SurfaceTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_SurfaceType_Variance_Fields = {
  __typename?: 'C_SurfaceType_variance_fields';
  SurfaceTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_SurfacingMaterial" */
export type C_SurfacingMaterial = {
  __typename?: 'C_SurfacingMaterial';
  Dsc: Scalars['CodeTableDescription'];
  SurfacingMaterialCode: Scalars['CodeTableValue'];
  SurfacingMaterialId: Scalars['Int'];
  /** An array relationship */
  Surfacings: Array<Surfacing>;
  /** An aggregate relationship */
  Surfacings_aggregate: Surfacing_Aggregate;
};


/** columns and relationships of "C_SurfacingMaterial" */
export type C_SurfacingMaterialSurfacingsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Surfacing_Order_By>>;
  where?: Maybe<Surfacing_Bool_Exp>;
};


/** columns and relationships of "C_SurfacingMaterial" */
export type C_SurfacingMaterialSurfacings_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Surfacing_Order_By>>;
  where?: Maybe<Surfacing_Bool_Exp>;
};

/** aggregated selection of "C_SurfacingMaterial" */
export type C_SurfacingMaterial_Aggregate = {
  __typename?: 'C_SurfacingMaterial_aggregate';
  aggregate?: Maybe<C_SurfacingMaterial_Aggregate_Fields>;
  nodes: Array<C_SurfacingMaterial>;
};

/** aggregate fields of "C_SurfacingMaterial" */
export type C_SurfacingMaterial_Aggregate_Fields = {
  __typename?: 'C_SurfacingMaterial_aggregate_fields';
  avg?: Maybe<C_SurfacingMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_SurfacingMaterial_Max_Fields>;
  min?: Maybe<C_SurfacingMaterial_Min_Fields>;
  stddev?: Maybe<C_SurfacingMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_SurfacingMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_SurfacingMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_SurfacingMaterial_Sum_Fields>;
  var_pop?: Maybe<C_SurfacingMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_SurfacingMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_SurfacingMaterial_Variance_Fields>;
};


/** aggregate fields of "C_SurfacingMaterial" */
export type C_SurfacingMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_SurfacingMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_SurfacingMaterial_Avg_Fields = {
  __typename?: 'C_SurfacingMaterial_avg_fields';
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_SurfacingMaterial". All fields are combined with a logical 'AND'. */
export type C_SurfacingMaterial_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  SurfacingMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  SurfacingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  Surfacings?: Maybe<Surfacing_Bool_Exp>;
  _and?: Maybe<Array<C_SurfacingMaterial_Bool_Exp>>;
  _not?: Maybe<C_SurfacingMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_SurfacingMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_SurfacingMaterial" */
export type C_SurfacingMaterial_If_Matched = {
  match_columns?: Array<C_SurfacingMaterial_Insert_Match_Column>;
  update_columns?: Array<C_SurfacingMaterial_Update_Column>;
  where?: Maybe<C_SurfacingMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_SurfacingMaterial" */
export type C_SurfacingMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SurfacingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_SurfacingMaterial" */
export enum C_SurfacingMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SurfacingMaterialCode = 'SurfacingMaterialCode',
  /** column name */
  SurfacingMaterialId = 'SurfacingMaterialId'
}

/** aggregate max on columns */
export type C_SurfacingMaterial_Max_Fields = {
  __typename?: 'C_SurfacingMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SurfacingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_SurfacingMaterial_Min_Fields = {
  __typename?: 'C_SurfacingMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SurfacingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_SurfacingMaterial" */
export type C_SurfacingMaterial_Mutation_Response = {
  __typename?: 'C_SurfacingMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_SurfacingMaterial>;
};

/** Ordering options when selecting data from "C_SurfacingMaterial". */
export type C_SurfacingMaterial_Order_By = {
  Dsc?: Maybe<Order_By>;
  SurfacingMaterialCode?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
  Surfacings_aggregate?: Maybe<Surfacing_Aggregate_Order_By>;
};

/** primary key columns input for table: C_SurfacingMaterial */
export type C_SurfacingMaterial_Pk_Columns_Input = {
  SurfacingMaterialId: Scalars['Int'];
};

/** select columns of table "C_SurfacingMaterial" */
export enum C_SurfacingMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SurfacingMaterialCode = 'SurfacingMaterialCode',
  /** column name */
  SurfacingMaterialId = 'SurfacingMaterialId'
}

/** input type for updating data in table "C_SurfacingMaterial" */
export type C_SurfacingMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  SurfacingMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_SurfacingMaterial_Stddev_Fields = {
  __typename?: 'C_SurfacingMaterial_stddev_fields';
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_SurfacingMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_SurfacingMaterial_stddev_pop_fields';
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_SurfacingMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_SurfacingMaterial_stddev_samp_fields';
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_SurfacingMaterial_Sum_Fields = {
  __typename?: 'C_SurfacingMaterial_sum_fields';
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_SurfacingMaterial" */
export enum C_SurfacingMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  SurfacingMaterialCode = 'SurfacingMaterialCode'
}

/** aggregate var_pop on columns */
export type C_SurfacingMaterial_Var_Pop_Fields = {
  __typename?: 'C_SurfacingMaterial_var_pop_fields';
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_SurfacingMaterial_Var_Samp_Fields = {
  __typename?: 'C_SurfacingMaterial_var_samp_fields';
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_SurfacingMaterial_Variance_Fields = {
  __typename?: 'C_SurfacingMaterial_variance_fields';
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_SystemApprovedUse" */
export type C_SystemApprovedUse = {
  __typename?: 'C_SystemApprovedUse';
  ApprovedUseCode: Scalars['CodeTableValue'];
  ApprovedUseId: Scalars['Int'];
  Dsc: Scalars['CodeTableDescription'];
};

/** aggregated selection of "C_SystemApprovedUse" */
export type C_SystemApprovedUse_Aggregate = {
  __typename?: 'C_SystemApprovedUse_aggregate';
  aggregate?: Maybe<C_SystemApprovedUse_Aggregate_Fields>;
  nodes: Array<C_SystemApprovedUse>;
};

/** aggregate fields of "C_SystemApprovedUse" */
export type C_SystemApprovedUse_Aggregate_Fields = {
  __typename?: 'C_SystemApprovedUse_aggregate_fields';
  avg?: Maybe<C_SystemApprovedUse_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_SystemApprovedUse_Max_Fields>;
  min?: Maybe<C_SystemApprovedUse_Min_Fields>;
  stddev?: Maybe<C_SystemApprovedUse_Stddev_Fields>;
  stddev_pop?: Maybe<C_SystemApprovedUse_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_SystemApprovedUse_Stddev_Samp_Fields>;
  sum?: Maybe<C_SystemApprovedUse_Sum_Fields>;
  var_pop?: Maybe<C_SystemApprovedUse_Var_Pop_Fields>;
  var_samp?: Maybe<C_SystemApprovedUse_Var_Samp_Fields>;
  variance?: Maybe<C_SystemApprovedUse_Variance_Fields>;
};


/** aggregate fields of "C_SystemApprovedUse" */
export type C_SystemApprovedUse_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_SystemApprovedUse_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_SystemApprovedUse_Avg_Fields = {
  __typename?: 'C_SystemApprovedUse_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_SystemApprovedUse". All fields are combined with a logical 'AND'. */
export type C_SystemApprovedUse_Bool_Exp = {
  ApprovedUseCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_SystemApprovedUse_Bool_Exp>>;
  _not?: Maybe<C_SystemApprovedUse_Bool_Exp>;
  _or?: Maybe<Array<C_SystemApprovedUse_Bool_Exp>>;
};

/** upsert condition type for table "C_SystemApprovedUse" */
export type C_SystemApprovedUse_If_Matched = {
  match_columns?: Array<C_SystemApprovedUse_Insert_Match_Column>;
  update_columns?: Array<C_SystemApprovedUse_Update_Column>;
  where?: Maybe<C_SystemApprovedUse_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "C_SystemApprovedUse" */
export type C_SystemApprovedUse_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "C_SystemApprovedUse" */
export type C_SystemApprovedUse_Insert_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** select match_columns of table "C_SystemApprovedUse" */
export enum C_SystemApprovedUse_Insert_Match_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate max on columns */
export type C_SystemApprovedUse_Max_Fields = {
  __typename?: 'C_SystemApprovedUse_max_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate min on columns */
export type C_SystemApprovedUse_Min_Fields = {
  __typename?: 'C_SystemApprovedUse_min_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** response of any mutation on the table "C_SystemApprovedUse" */
export type C_SystemApprovedUse_Mutation_Response = {
  __typename?: 'C_SystemApprovedUse_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_SystemApprovedUse>;
};

/** Ordering options when selecting data from "C_SystemApprovedUse". */
export type C_SystemApprovedUse_Order_By = {
  ApprovedUseCode?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  Dsc?: Maybe<Order_By>;
};

/** select columns of table "C_SystemApprovedUse" */
export enum C_SystemApprovedUse_Select_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Dsc = 'Dsc'
}

/** input type for updating data in table "C_SystemApprovedUse" */
export type C_SystemApprovedUse_Set_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
};

/** aggregate stddev on columns */
export type C_SystemApprovedUse_Stddev_Fields = {
  __typename?: 'C_SystemApprovedUse_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_SystemApprovedUse_Stddev_Pop_Fields = {
  __typename?: 'C_SystemApprovedUse_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_SystemApprovedUse_Stddev_Samp_Fields = {
  __typename?: 'C_SystemApprovedUse_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_SystemApprovedUse_Sum_Fields = {
  __typename?: 'C_SystemApprovedUse_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_SystemApprovedUse" */
export enum C_SystemApprovedUse_Update_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Dsc = 'Dsc'
}

/** aggregate var_pop on columns */
export type C_SystemApprovedUse_Var_Pop_Fields = {
  __typename?: 'C_SystemApprovedUse_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_SystemApprovedUse_Var_Samp_Fields = {
  __typename?: 'C_SystemApprovedUse_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_SystemApprovedUse_Variance_Fields = {
  __typename?: 'C_SystemApprovedUse_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_ThermalBarrierMaterial" */
export type C_ThermalBarrierMaterial = {
  __typename?: 'C_ThermalBarrierMaterial';
  Dsc: Scalars['CodeTableDescription'];
  ThermalBarrierMaterialCode: Scalars['CodeTableValue'];
  ThermalBarrierMaterialId: Scalars['Int'];
  /** An array relationship */
  ThermalBarriers: Array<ThermalBarrier>;
  /** An aggregate relationship */
  ThermalBarriers_aggregate: ThermalBarrier_Aggregate;
};


/** columns and relationships of "C_ThermalBarrierMaterial" */
export type C_ThermalBarrierMaterialThermalBarriersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrier_Order_By>>;
  where?: Maybe<ThermalBarrier_Bool_Exp>;
};


/** columns and relationships of "C_ThermalBarrierMaterial" */
export type C_ThermalBarrierMaterialThermalBarriers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrier_Order_By>>;
  where?: Maybe<ThermalBarrier_Bool_Exp>;
};

/** aggregated selection of "C_ThermalBarrierMaterial" */
export type C_ThermalBarrierMaterial_Aggregate = {
  __typename?: 'C_ThermalBarrierMaterial_aggregate';
  aggregate?: Maybe<C_ThermalBarrierMaterial_Aggregate_Fields>;
  nodes: Array<C_ThermalBarrierMaterial>;
};

/** aggregate fields of "C_ThermalBarrierMaterial" */
export type C_ThermalBarrierMaterial_Aggregate_Fields = {
  __typename?: 'C_ThermalBarrierMaterial_aggregate_fields';
  avg?: Maybe<C_ThermalBarrierMaterial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_ThermalBarrierMaterial_Max_Fields>;
  min?: Maybe<C_ThermalBarrierMaterial_Min_Fields>;
  stddev?: Maybe<C_ThermalBarrierMaterial_Stddev_Fields>;
  stddev_pop?: Maybe<C_ThermalBarrierMaterial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_ThermalBarrierMaterial_Stddev_Samp_Fields>;
  sum?: Maybe<C_ThermalBarrierMaterial_Sum_Fields>;
  var_pop?: Maybe<C_ThermalBarrierMaterial_Var_Pop_Fields>;
  var_samp?: Maybe<C_ThermalBarrierMaterial_Var_Samp_Fields>;
  variance?: Maybe<C_ThermalBarrierMaterial_Variance_Fields>;
};


/** aggregate fields of "C_ThermalBarrierMaterial" */
export type C_ThermalBarrierMaterial_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_ThermalBarrierMaterial_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_ThermalBarrierMaterial_Avg_Fields = {
  __typename?: 'C_ThermalBarrierMaterial_avg_fields';
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_ThermalBarrierMaterial". All fields are combined with a logical 'AND'. */
export type C_ThermalBarrierMaterial_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  ThermalBarrierMaterialCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ThermalBarrierMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThermalBarriers?: Maybe<ThermalBarrier_Bool_Exp>;
  _and?: Maybe<Array<C_ThermalBarrierMaterial_Bool_Exp>>;
  _not?: Maybe<C_ThermalBarrierMaterial_Bool_Exp>;
  _or?: Maybe<Array<C_ThermalBarrierMaterial_Bool_Exp>>;
};

/** upsert condition type for table "C_ThermalBarrierMaterial" */
export type C_ThermalBarrierMaterial_If_Matched = {
  match_columns?: Array<C_ThermalBarrierMaterial_Insert_Match_Column>;
  update_columns?: Array<C_ThermalBarrierMaterial_Update_Column>;
  where?: Maybe<C_ThermalBarrierMaterial_Bool_Exp>;
};

/** input type for inserting data into table "C_ThermalBarrierMaterial" */
export type C_ThermalBarrierMaterial_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ThermalBarrierMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_ThermalBarrierMaterial" */
export enum C_ThermalBarrierMaterial_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ThermalBarrierMaterialCode = 'ThermalBarrierMaterialCode',
  /** column name */
  ThermalBarrierMaterialId = 'ThermalBarrierMaterialId'
}

/** aggregate max on columns */
export type C_ThermalBarrierMaterial_Max_Fields = {
  __typename?: 'C_ThermalBarrierMaterial_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ThermalBarrierMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_ThermalBarrierMaterial_Min_Fields = {
  __typename?: 'C_ThermalBarrierMaterial_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ThermalBarrierMaterialCode?: Maybe<Scalars['CodeTableValue']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_ThermalBarrierMaterial" */
export type C_ThermalBarrierMaterial_Mutation_Response = {
  __typename?: 'C_ThermalBarrierMaterial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_ThermalBarrierMaterial>;
};

/** Ordering options when selecting data from "C_ThermalBarrierMaterial". */
export type C_ThermalBarrierMaterial_Order_By = {
  Dsc?: Maybe<Order_By>;
  ThermalBarrierMaterialCode?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThermalBarriers_aggregate?: Maybe<ThermalBarrier_Aggregate_Order_By>;
};

/** primary key columns input for table: C_ThermalBarrierMaterial */
export type C_ThermalBarrierMaterial_Pk_Columns_Input = {
  ThermalBarrierMaterialId: Scalars['Int'];
};

/** select columns of table "C_ThermalBarrierMaterial" */
export enum C_ThermalBarrierMaterial_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ThermalBarrierMaterialCode = 'ThermalBarrierMaterialCode',
  /** column name */
  ThermalBarrierMaterialId = 'ThermalBarrierMaterialId'
}

/** input type for updating data in table "C_ThermalBarrierMaterial" */
export type C_ThermalBarrierMaterial_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  ThermalBarrierMaterialCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_ThermalBarrierMaterial_Stddev_Fields = {
  __typename?: 'C_ThermalBarrierMaterial_stddev_fields';
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_ThermalBarrierMaterial_Stddev_Pop_Fields = {
  __typename?: 'C_ThermalBarrierMaterial_stddev_pop_fields';
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_ThermalBarrierMaterial_Stddev_Samp_Fields = {
  __typename?: 'C_ThermalBarrierMaterial_stddev_samp_fields';
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_ThermalBarrierMaterial_Sum_Fields = {
  __typename?: 'C_ThermalBarrierMaterial_sum_fields';
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_ThermalBarrierMaterial" */
export enum C_ThermalBarrierMaterial_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  ThermalBarrierMaterialCode = 'ThermalBarrierMaterialCode'
}

/** aggregate var_pop on columns */
export type C_ThermalBarrierMaterial_Var_Pop_Fields = {
  __typename?: 'C_ThermalBarrierMaterial_var_pop_fields';
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_ThermalBarrierMaterial_Var_Samp_Fields = {
  __typename?: 'C_ThermalBarrierMaterial_var_samp_fields';
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_ThermalBarrierMaterial_Variance_Fields = {
  __typename?: 'C_ThermalBarrierMaterial_variance_fields';
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_WIPDelistReason" */
export type C_WipDelistReason = {
  __typename?: 'C_WIPDelistReason';
  Dsc: Scalars['CodeTableDescription'];
  WIPDelistReasonCode: Scalars['CodeTableValue'];
  WIPDelistReasonId: Scalars['Int'];
};

/** aggregated selection of "C_WIPDelistReason" */
export type C_WipDelistReason_Aggregate = {
  __typename?: 'C_WIPDelistReason_aggregate';
  aggregate?: Maybe<C_WipDelistReason_Aggregate_Fields>;
  nodes: Array<C_WipDelistReason>;
};

/** aggregate fields of "C_WIPDelistReason" */
export type C_WipDelistReason_Aggregate_Fields = {
  __typename?: 'C_WIPDelistReason_aggregate_fields';
  avg?: Maybe<C_WipDelistReason_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_WipDelistReason_Max_Fields>;
  min?: Maybe<C_WipDelistReason_Min_Fields>;
  stddev?: Maybe<C_WipDelistReason_Stddev_Fields>;
  stddev_pop?: Maybe<C_WipDelistReason_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_WipDelistReason_Stddev_Samp_Fields>;
  sum?: Maybe<C_WipDelistReason_Sum_Fields>;
  var_pop?: Maybe<C_WipDelistReason_Var_Pop_Fields>;
  var_samp?: Maybe<C_WipDelistReason_Var_Samp_Fields>;
  variance?: Maybe<C_WipDelistReason_Variance_Fields>;
};


/** aggregate fields of "C_WIPDelistReason" */
export type C_WipDelistReason_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_WipDelistReason_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_WipDelistReason_Avg_Fields = {
  __typename?: 'C_WIPDelistReason_avg_fields';
  WIPDelistReasonId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_WIPDelistReason". All fields are combined with a logical 'AND'. */
export type C_WipDelistReason_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  WIPDelistReasonCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  WIPDelistReasonId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_WipDelistReason_Bool_Exp>>;
  _not?: Maybe<C_WipDelistReason_Bool_Exp>;
  _or?: Maybe<Array<C_WipDelistReason_Bool_Exp>>;
};

/** upsert condition type for table "C_WIPDelistReason" */
export type C_WipDelistReason_If_Matched = {
  match_columns?: Array<C_WipDelistReason_Insert_Match_Column>;
  update_columns?: Array<C_WipDelistReason_Update_Column>;
  where?: Maybe<C_WipDelistReason_Bool_Exp>;
};

/** input type for inserting data into table "C_WIPDelistReason" */
export type C_WipDelistReason_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WIPDelistReasonCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_WIPDelistReason" */
export enum C_WipDelistReason_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WipDelistReasonCode = 'WIPDelistReasonCode',
  /** column name */
  WipDelistReasonId = 'WIPDelistReasonId'
}

/** aggregate max on columns */
export type C_WipDelistReason_Max_Fields = {
  __typename?: 'C_WIPDelistReason_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WIPDelistReasonCode?: Maybe<Scalars['CodeTableValue']>;
  WIPDelistReasonId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_WipDelistReason_Min_Fields = {
  __typename?: 'C_WIPDelistReason_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WIPDelistReasonCode?: Maybe<Scalars['CodeTableValue']>;
  WIPDelistReasonId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_WIPDelistReason" */
export type C_WipDelistReason_Mutation_Response = {
  __typename?: 'C_WIPDelistReason_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_WipDelistReason>;
};

/** Ordering options when selecting data from "C_WIPDelistReason". */
export type C_WipDelistReason_Order_By = {
  Dsc?: Maybe<Order_By>;
  WIPDelistReasonCode?: Maybe<Order_By>;
  WIPDelistReasonId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_WIPDelistReason */
export type C_WipDelistReason_Pk_Columns_Input = {
  WIPDelistReasonId: Scalars['Int'];
};

/** select columns of table "C_WIPDelistReason" */
export enum C_WipDelistReason_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WipDelistReasonCode = 'WIPDelistReasonCode',
  /** column name */
  WipDelistReasonId = 'WIPDelistReasonId'
}

/** input type for updating data in table "C_WIPDelistReason" */
export type C_WipDelistReason_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WIPDelistReasonCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_WipDelistReason_Stddev_Fields = {
  __typename?: 'C_WIPDelistReason_stddev_fields';
  WIPDelistReasonId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_WipDelistReason_Stddev_Pop_Fields = {
  __typename?: 'C_WIPDelistReason_stddev_pop_fields';
  WIPDelistReasonId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_WipDelistReason_Stddev_Samp_Fields = {
  __typename?: 'C_WIPDelistReason_stddev_samp_fields';
  WIPDelistReasonId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_WipDelistReason_Sum_Fields = {
  __typename?: 'C_WIPDelistReason_sum_fields';
  WIPDelistReasonId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_WIPDelistReason" */
export enum C_WipDelistReason_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WipDelistReasonCode = 'WIPDelistReasonCode'
}

/** aggregate var_pop on columns */
export type C_WipDelistReason_Var_Pop_Fields = {
  __typename?: 'C_WIPDelistReason_var_pop_fields';
  WIPDelistReasonId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_WipDelistReason_Var_Samp_Fields = {
  __typename?: 'C_WIPDelistReason_var_samp_fields';
  WIPDelistReasonId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_WipDelistReason_Variance_Fields = {
  __typename?: 'C_WIPDelistReason_variance_fields';
  WIPDelistReasonId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_WIPOnHoldReason" */
export type C_WipOnHoldReason = {
  __typename?: 'C_WIPOnHoldReason';
  Dsc: Scalars['CodeTableDescription'];
  WIPOnHoldReasonCode: Scalars['CodeTableValue'];
  WIPOnHoldReasonId: Scalars['Int'];
};

/** aggregated selection of "C_WIPOnHoldReason" */
export type C_WipOnHoldReason_Aggregate = {
  __typename?: 'C_WIPOnHoldReason_aggregate';
  aggregate?: Maybe<C_WipOnHoldReason_Aggregate_Fields>;
  nodes: Array<C_WipOnHoldReason>;
};

/** aggregate fields of "C_WIPOnHoldReason" */
export type C_WipOnHoldReason_Aggregate_Fields = {
  __typename?: 'C_WIPOnHoldReason_aggregate_fields';
  avg?: Maybe<C_WipOnHoldReason_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_WipOnHoldReason_Max_Fields>;
  min?: Maybe<C_WipOnHoldReason_Min_Fields>;
  stddev?: Maybe<C_WipOnHoldReason_Stddev_Fields>;
  stddev_pop?: Maybe<C_WipOnHoldReason_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_WipOnHoldReason_Stddev_Samp_Fields>;
  sum?: Maybe<C_WipOnHoldReason_Sum_Fields>;
  var_pop?: Maybe<C_WipOnHoldReason_Var_Pop_Fields>;
  var_samp?: Maybe<C_WipOnHoldReason_Var_Samp_Fields>;
  variance?: Maybe<C_WipOnHoldReason_Variance_Fields>;
};


/** aggregate fields of "C_WIPOnHoldReason" */
export type C_WipOnHoldReason_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_WipOnHoldReason_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_WipOnHoldReason_Avg_Fields = {
  __typename?: 'C_WIPOnHoldReason_avg_fields';
  WIPOnHoldReasonId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_WIPOnHoldReason". All fields are combined with a logical 'AND'. */
export type C_WipOnHoldReason_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  WIPOnHoldReasonCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  WIPOnHoldReasonId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_WipOnHoldReason_Bool_Exp>>;
  _not?: Maybe<C_WipOnHoldReason_Bool_Exp>;
  _or?: Maybe<Array<C_WipOnHoldReason_Bool_Exp>>;
};

/** upsert condition type for table "C_WIPOnHoldReason" */
export type C_WipOnHoldReason_If_Matched = {
  match_columns?: Array<C_WipOnHoldReason_Insert_Match_Column>;
  update_columns?: Array<C_WipOnHoldReason_Update_Column>;
  where?: Maybe<C_WipOnHoldReason_Bool_Exp>;
};

/** input type for inserting data into table "C_WIPOnHoldReason" */
export type C_WipOnHoldReason_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WIPOnHoldReasonCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_WIPOnHoldReason" */
export enum C_WipOnHoldReason_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WipOnHoldReasonCode = 'WIPOnHoldReasonCode',
  /** column name */
  WipOnHoldReasonId = 'WIPOnHoldReasonId'
}

/** aggregate max on columns */
export type C_WipOnHoldReason_Max_Fields = {
  __typename?: 'C_WIPOnHoldReason_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WIPOnHoldReasonCode?: Maybe<Scalars['CodeTableValue']>;
  WIPOnHoldReasonId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_WipOnHoldReason_Min_Fields = {
  __typename?: 'C_WIPOnHoldReason_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WIPOnHoldReasonCode?: Maybe<Scalars['CodeTableValue']>;
  WIPOnHoldReasonId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_WIPOnHoldReason" */
export type C_WipOnHoldReason_Mutation_Response = {
  __typename?: 'C_WIPOnHoldReason_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_WipOnHoldReason>;
};

/** Ordering options when selecting data from "C_WIPOnHoldReason". */
export type C_WipOnHoldReason_Order_By = {
  Dsc?: Maybe<Order_By>;
  WIPOnHoldReasonCode?: Maybe<Order_By>;
  WIPOnHoldReasonId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_WIPOnHoldReason */
export type C_WipOnHoldReason_Pk_Columns_Input = {
  WIPOnHoldReasonId: Scalars['Int'];
};

/** select columns of table "C_WIPOnHoldReason" */
export enum C_WipOnHoldReason_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WipOnHoldReasonCode = 'WIPOnHoldReasonCode',
  /** column name */
  WipOnHoldReasonId = 'WIPOnHoldReasonId'
}

/** input type for updating data in table "C_WIPOnHoldReason" */
export type C_WipOnHoldReason_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WIPOnHoldReasonCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_WipOnHoldReason_Stddev_Fields = {
  __typename?: 'C_WIPOnHoldReason_stddev_fields';
  WIPOnHoldReasonId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_WipOnHoldReason_Stddev_Pop_Fields = {
  __typename?: 'C_WIPOnHoldReason_stddev_pop_fields';
  WIPOnHoldReasonId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_WipOnHoldReason_Stddev_Samp_Fields = {
  __typename?: 'C_WIPOnHoldReason_stddev_samp_fields';
  WIPOnHoldReasonId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_WipOnHoldReason_Sum_Fields = {
  __typename?: 'C_WIPOnHoldReason_sum_fields';
  WIPOnHoldReasonId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_WIPOnHoldReason" */
export enum C_WipOnHoldReason_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WipOnHoldReasonCode = 'WIPOnHoldReasonCode'
}

/** aggregate var_pop on columns */
export type C_WipOnHoldReason_Var_Pop_Fields = {
  __typename?: 'C_WIPOnHoldReason_var_pop_fields';
  WIPOnHoldReasonId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_WipOnHoldReason_Var_Samp_Fields = {
  __typename?: 'C_WIPOnHoldReason_var_samp_fields';
  WIPOnHoldReasonId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_WipOnHoldReason_Variance_Fields = {
  __typename?: 'C_WIPOnHoldReason_variance_fields';
  WIPOnHoldReasonId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_WIPStatus" */
export type C_WipStatus = {
  __typename?: 'C_WIPStatus';
  Dsc: Scalars['CodeTableDescription'];
  WIPStatusCode: Scalars['CodeTableValue'];
  WIPStatusId: Scalars['Int'];
};

/** aggregated selection of "C_WIPStatus" */
export type C_WipStatus_Aggregate = {
  __typename?: 'C_WIPStatus_aggregate';
  aggregate?: Maybe<C_WipStatus_Aggregate_Fields>;
  nodes: Array<C_WipStatus>;
};

/** aggregate fields of "C_WIPStatus" */
export type C_WipStatus_Aggregate_Fields = {
  __typename?: 'C_WIPStatus_aggregate_fields';
  avg?: Maybe<C_WipStatus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_WipStatus_Max_Fields>;
  min?: Maybe<C_WipStatus_Min_Fields>;
  stddev?: Maybe<C_WipStatus_Stddev_Fields>;
  stddev_pop?: Maybe<C_WipStatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_WipStatus_Stddev_Samp_Fields>;
  sum?: Maybe<C_WipStatus_Sum_Fields>;
  var_pop?: Maybe<C_WipStatus_Var_Pop_Fields>;
  var_samp?: Maybe<C_WipStatus_Var_Samp_Fields>;
  variance?: Maybe<C_WipStatus_Variance_Fields>;
};


/** aggregate fields of "C_WIPStatus" */
export type C_WipStatus_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_WipStatus_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_WipStatus_Avg_Fields = {
  __typename?: 'C_WIPStatus_avg_fields';
  WIPStatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_WIPStatus". All fields are combined with a logical 'AND'. */
export type C_WipStatus_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  WIPStatusCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  WIPStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_WipStatus_Bool_Exp>>;
  _not?: Maybe<C_WipStatus_Bool_Exp>;
  _or?: Maybe<Array<C_WipStatus_Bool_Exp>>;
};

/** upsert condition type for table "C_WIPStatus" */
export type C_WipStatus_If_Matched = {
  match_columns?: Array<C_WipStatus_Insert_Match_Column>;
  update_columns?: Array<C_WipStatus_Update_Column>;
  where?: Maybe<C_WipStatus_Bool_Exp>;
};

/** input type for inserting data into table "C_WIPStatus" */
export type C_WipStatus_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WIPStatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_WIPStatus" */
export enum C_WipStatus_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WipStatusCode = 'WIPStatusCode',
  /** column name */
  WipStatusId = 'WIPStatusId'
}

/** aggregate max on columns */
export type C_WipStatus_Max_Fields = {
  __typename?: 'C_WIPStatus_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WIPStatusCode?: Maybe<Scalars['CodeTableValue']>;
  WIPStatusId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_WipStatus_Min_Fields = {
  __typename?: 'C_WIPStatus_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WIPStatusCode?: Maybe<Scalars['CodeTableValue']>;
  WIPStatusId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_WIPStatus" */
export type C_WipStatus_Mutation_Response = {
  __typename?: 'C_WIPStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_WipStatus>;
};

/** Ordering options when selecting data from "C_WIPStatus". */
export type C_WipStatus_Order_By = {
  Dsc?: Maybe<Order_By>;
  WIPStatusCode?: Maybe<Order_By>;
  WIPStatusId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_WIPStatus */
export type C_WipStatus_Pk_Columns_Input = {
  WIPStatusId: Scalars['Int'];
};

/** select columns of table "C_WIPStatus" */
export enum C_WipStatus_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WipStatusCode = 'WIPStatusCode',
  /** column name */
  WipStatusId = 'WIPStatusId'
}

/** input type for updating data in table "C_WIPStatus" */
export type C_WipStatus_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WIPStatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_WipStatus_Stddev_Fields = {
  __typename?: 'C_WIPStatus_stddev_fields';
  WIPStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_WipStatus_Stddev_Pop_Fields = {
  __typename?: 'C_WIPStatus_stddev_pop_fields';
  WIPStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_WipStatus_Stddev_Samp_Fields = {
  __typename?: 'C_WIPStatus_stddev_samp_fields';
  WIPStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_WipStatus_Sum_Fields = {
  __typename?: 'C_WIPStatus_sum_fields';
  WIPStatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_WIPStatus" */
export enum C_WipStatus_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WipStatusCode = 'WIPStatusCode'
}

/** aggregate var_pop on columns */
export type C_WipStatus_Var_Pop_Fields = {
  __typename?: 'C_WIPStatus_var_pop_fields';
  WIPStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_WipStatus_Var_Samp_Fields = {
  __typename?: 'C_WIPStatus_var_samp_fields';
  WIPStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_WipStatus_Variance_Fields = {
  __typename?: 'C_WIPStatus_variance_fields';
  WIPStatusId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_WeldApplication" */
export type C_WeldApplication = {
  __typename?: 'C_WeldApplication';
  Dsc: Scalars['CodeTableDescription'];
  WeldApplicationCode: Scalars['CodeTableValue'];
  WeldApplicationId: Scalars['Int'];
};

/** aggregated selection of "C_WeldApplication" */
export type C_WeldApplication_Aggregate = {
  __typename?: 'C_WeldApplication_aggregate';
  aggregate?: Maybe<C_WeldApplication_Aggregate_Fields>;
  nodes: Array<C_WeldApplication>;
};

/** aggregate fields of "C_WeldApplication" */
export type C_WeldApplication_Aggregate_Fields = {
  __typename?: 'C_WeldApplication_aggregate_fields';
  avg?: Maybe<C_WeldApplication_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_WeldApplication_Max_Fields>;
  min?: Maybe<C_WeldApplication_Min_Fields>;
  stddev?: Maybe<C_WeldApplication_Stddev_Fields>;
  stddev_pop?: Maybe<C_WeldApplication_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_WeldApplication_Stddev_Samp_Fields>;
  sum?: Maybe<C_WeldApplication_Sum_Fields>;
  var_pop?: Maybe<C_WeldApplication_Var_Pop_Fields>;
  var_samp?: Maybe<C_WeldApplication_Var_Samp_Fields>;
  variance?: Maybe<C_WeldApplication_Variance_Fields>;
};


/** aggregate fields of "C_WeldApplication" */
export type C_WeldApplication_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_WeldApplication_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_WeldApplication_Avg_Fields = {
  __typename?: 'C_WeldApplication_avg_fields';
  WeldApplicationId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_WeldApplication". All fields are combined with a logical 'AND'. */
export type C_WeldApplication_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  WeldApplicationCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  WeldApplicationId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_WeldApplication_Bool_Exp>>;
  _not?: Maybe<C_WeldApplication_Bool_Exp>;
  _or?: Maybe<Array<C_WeldApplication_Bool_Exp>>;
};

/** upsert condition type for table "C_WeldApplication" */
export type C_WeldApplication_If_Matched = {
  match_columns?: Array<C_WeldApplication_Insert_Match_Column>;
  update_columns?: Array<C_WeldApplication_Update_Column>;
  where?: Maybe<C_WeldApplication_Bool_Exp>;
};

/** input type for inserting data into table "C_WeldApplication" */
export type C_WeldApplication_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WeldApplicationCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_WeldApplication" */
export enum C_WeldApplication_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WeldApplicationCode = 'WeldApplicationCode',
  /** column name */
  WeldApplicationId = 'WeldApplicationId'
}

/** aggregate max on columns */
export type C_WeldApplication_Max_Fields = {
  __typename?: 'C_WeldApplication_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WeldApplicationCode?: Maybe<Scalars['CodeTableValue']>;
  WeldApplicationId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_WeldApplication_Min_Fields = {
  __typename?: 'C_WeldApplication_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WeldApplicationCode?: Maybe<Scalars['CodeTableValue']>;
  WeldApplicationId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_WeldApplication" */
export type C_WeldApplication_Mutation_Response = {
  __typename?: 'C_WeldApplication_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_WeldApplication>;
};

/** Ordering options when selecting data from "C_WeldApplication". */
export type C_WeldApplication_Order_By = {
  Dsc?: Maybe<Order_By>;
  WeldApplicationCode?: Maybe<Order_By>;
  WeldApplicationId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_WeldApplication */
export type C_WeldApplication_Pk_Columns_Input = {
  WeldApplicationId: Scalars['Int'];
};

/** select columns of table "C_WeldApplication" */
export enum C_WeldApplication_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WeldApplicationCode = 'WeldApplicationCode',
  /** column name */
  WeldApplicationId = 'WeldApplicationId'
}

/** input type for updating data in table "C_WeldApplication" */
export type C_WeldApplication_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WeldApplicationCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_WeldApplication_Stddev_Fields = {
  __typename?: 'C_WeldApplication_stddev_fields';
  WeldApplicationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_WeldApplication_Stddev_Pop_Fields = {
  __typename?: 'C_WeldApplication_stddev_pop_fields';
  WeldApplicationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_WeldApplication_Stddev_Samp_Fields = {
  __typename?: 'C_WeldApplication_stddev_samp_fields';
  WeldApplicationId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_WeldApplication_Sum_Fields = {
  __typename?: 'C_WeldApplication_sum_fields';
  WeldApplicationId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_WeldApplication" */
export enum C_WeldApplication_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WeldApplicationCode = 'WeldApplicationCode'
}

/** aggregate var_pop on columns */
export type C_WeldApplication_Var_Pop_Fields = {
  __typename?: 'C_WeldApplication_var_pop_fields';
  WeldApplicationId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_WeldApplication_Var_Samp_Fields = {
  __typename?: 'C_WeldApplication_var_samp_fields';
  WeldApplicationId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_WeldApplication_Variance_Fields = {
  __typename?: 'C_WeldApplication_variance_fields';
  WeldApplicationId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_WeldConfiguration" */
export type C_WeldConfiguration = {
  __typename?: 'C_WeldConfiguration';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  LapSecurementInfos: Array<LapSecurementInfo>;
  /** An aggregate relationship */
  LapSecurementInfos_aggregate: LapSecurementInfo_Aggregate;
  WeldConfigurationCode: Scalars['CodeTableValue'];
  WeldConfigurationId: Scalars['Int'];
  /** An array relationship */
  WeldHeatUsages: Array<WeldHeatUsage>;
  /** An aggregate relationship */
  WeldHeatUsages_aggregate: WeldHeatUsage_Aggregate;
  /** An array relationship */
  WeldSolventUsages: Array<WeldSolventUsage>;
  /** An aggregate relationship */
  WeldSolventUsages_aggregate: WeldSolventUsage_Aggregate;
};


/** columns and relationships of "C_WeldConfiguration" */
export type C_WeldConfigurationLapSecurementInfosArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSecurementInfo_Order_By>>;
  where?: Maybe<LapSecurementInfo_Bool_Exp>;
};


/** columns and relationships of "C_WeldConfiguration" */
export type C_WeldConfigurationLapSecurementInfos_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSecurementInfo_Order_By>>;
  where?: Maybe<LapSecurementInfo_Bool_Exp>;
};


/** columns and relationships of "C_WeldConfiguration" */
export type C_WeldConfigurationWeldHeatUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldHeatUsage_Order_By>>;
  where?: Maybe<WeldHeatUsage_Bool_Exp>;
};


/** columns and relationships of "C_WeldConfiguration" */
export type C_WeldConfigurationWeldHeatUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldHeatUsage_Order_By>>;
  where?: Maybe<WeldHeatUsage_Bool_Exp>;
};


/** columns and relationships of "C_WeldConfiguration" */
export type C_WeldConfigurationWeldSolventUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldSolventUsage_Order_By>>;
  where?: Maybe<WeldSolventUsage_Bool_Exp>;
};


/** columns and relationships of "C_WeldConfiguration" */
export type C_WeldConfigurationWeldSolventUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldSolventUsage_Order_By>>;
  where?: Maybe<WeldSolventUsage_Bool_Exp>;
};

/** aggregated selection of "C_WeldConfiguration" */
export type C_WeldConfiguration_Aggregate = {
  __typename?: 'C_WeldConfiguration_aggregate';
  aggregate?: Maybe<C_WeldConfiguration_Aggregate_Fields>;
  nodes: Array<C_WeldConfiguration>;
};

/** aggregate fields of "C_WeldConfiguration" */
export type C_WeldConfiguration_Aggregate_Fields = {
  __typename?: 'C_WeldConfiguration_aggregate_fields';
  avg?: Maybe<C_WeldConfiguration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_WeldConfiguration_Max_Fields>;
  min?: Maybe<C_WeldConfiguration_Min_Fields>;
  stddev?: Maybe<C_WeldConfiguration_Stddev_Fields>;
  stddev_pop?: Maybe<C_WeldConfiguration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_WeldConfiguration_Stddev_Samp_Fields>;
  sum?: Maybe<C_WeldConfiguration_Sum_Fields>;
  var_pop?: Maybe<C_WeldConfiguration_Var_Pop_Fields>;
  var_samp?: Maybe<C_WeldConfiguration_Var_Samp_Fields>;
  variance?: Maybe<C_WeldConfiguration_Variance_Fields>;
};


/** aggregate fields of "C_WeldConfiguration" */
export type C_WeldConfiguration_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_WeldConfiguration_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_WeldConfiguration_Avg_Fields = {
  __typename?: 'C_WeldConfiguration_avg_fields';
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_WeldConfiguration". All fields are combined with a logical 'AND'. */
export type C_WeldConfiguration_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  LapSecurementInfos?: Maybe<LapSecurementInfo_Bool_Exp>;
  WeldConfigurationCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  WeldConfigurationId?: Maybe<Int_Mssql_Comparison_Exp>;
  WeldHeatUsages?: Maybe<WeldHeatUsage_Bool_Exp>;
  WeldSolventUsages?: Maybe<WeldSolventUsage_Bool_Exp>;
  _and?: Maybe<Array<C_WeldConfiguration_Bool_Exp>>;
  _not?: Maybe<C_WeldConfiguration_Bool_Exp>;
  _or?: Maybe<Array<C_WeldConfiguration_Bool_Exp>>;
};

/** upsert condition type for table "C_WeldConfiguration" */
export type C_WeldConfiguration_If_Matched = {
  match_columns?: Array<C_WeldConfiguration_Insert_Match_Column>;
  update_columns?: Array<C_WeldConfiguration_Update_Column>;
  where?: Maybe<C_WeldConfiguration_Bool_Exp>;
};

/** input type for inserting data into table "C_WeldConfiguration" */
export type C_WeldConfiguration_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WeldConfigurationCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_WeldConfiguration" */
export enum C_WeldConfiguration_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WeldConfigurationCode = 'WeldConfigurationCode',
  /** column name */
  WeldConfigurationId = 'WeldConfigurationId'
}

/** aggregate max on columns */
export type C_WeldConfiguration_Max_Fields = {
  __typename?: 'C_WeldConfiguration_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WeldConfigurationCode?: Maybe<Scalars['CodeTableValue']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_WeldConfiguration_Min_Fields = {
  __typename?: 'C_WeldConfiguration_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WeldConfigurationCode?: Maybe<Scalars['CodeTableValue']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_WeldConfiguration" */
export type C_WeldConfiguration_Mutation_Response = {
  __typename?: 'C_WeldConfiguration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_WeldConfiguration>;
};

/** Ordering options when selecting data from "C_WeldConfiguration". */
export type C_WeldConfiguration_Order_By = {
  Dsc?: Maybe<Order_By>;
  LapSecurementInfos_aggregate?: Maybe<LapSecurementInfo_Aggregate_Order_By>;
  WeldConfigurationCode?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
  WeldHeatUsages_aggregate?: Maybe<WeldHeatUsage_Aggregate_Order_By>;
  WeldSolventUsages_aggregate?: Maybe<WeldSolventUsage_Aggregate_Order_By>;
};

/** primary key columns input for table: C_WeldConfiguration */
export type C_WeldConfiguration_Pk_Columns_Input = {
  WeldConfigurationId: Scalars['Int'];
};

/** select columns of table "C_WeldConfiguration" */
export enum C_WeldConfiguration_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WeldConfigurationCode = 'WeldConfigurationCode',
  /** column name */
  WeldConfigurationId = 'WeldConfigurationId'
}

/** input type for updating data in table "C_WeldConfiguration" */
export type C_WeldConfiguration_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WeldConfigurationCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_WeldConfiguration_Stddev_Fields = {
  __typename?: 'C_WeldConfiguration_stddev_fields';
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_WeldConfiguration_Stddev_Pop_Fields = {
  __typename?: 'C_WeldConfiguration_stddev_pop_fields';
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_WeldConfiguration_Stddev_Samp_Fields = {
  __typename?: 'C_WeldConfiguration_stddev_samp_fields';
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_WeldConfiguration_Sum_Fields = {
  __typename?: 'C_WeldConfiguration_sum_fields';
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_WeldConfiguration" */
export enum C_WeldConfiguration_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WeldConfigurationCode = 'WeldConfigurationCode'
}

/** aggregate var_pop on columns */
export type C_WeldConfiguration_Var_Pop_Fields = {
  __typename?: 'C_WeldConfiguration_var_pop_fields';
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_WeldConfiguration_Var_Samp_Fields = {
  __typename?: 'C_WeldConfiguration_var_samp_fields';
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_WeldConfiguration_Variance_Fields = {
  __typename?: 'C_WeldConfiguration_variance_fields';
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_WindBorneDebris" */
export type C_WindBorneDebris = {
  __typename?: 'C_WindBorneDebris';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  RoofAreas: Array<RoofArea>;
  /** An aggregate relationship */
  RoofAreas_aggregate: RoofArea_Aggregate;
  WindBorneDebrisCode: Scalars['CodeTableValue'];
  WindBorneDebrisId: Scalars['Int'];
};


/** columns and relationships of "C_WindBorneDebris" */
export type C_WindBorneDebrisRoofAreasArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};


/** columns and relationships of "C_WindBorneDebris" */
export type C_WindBorneDebrisRoofAreas_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};

/** aggregated selection of "C_WindBorneDebris" */
export type C_WindBorneDebris_Aggregate = {
  __typename?: 'C_WindBorneDebris_aggregate';
  aggregate?: Maybe<C_WindBorneDebris_Aggregate_Fields>;
  nodes: Array<C_WindBorneDebris>;
};

/** aggregate fields of "C_WindBorneDebris" */
export type C_WindBorneDebris_Aggregate_Fields = {
  __typename?: 'C_WindBorneDebris_aggregate_fields';
  avg?: Maybe<C_WindBorneDebris_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_WindBorneDebris_Max_Fields>;
  min?: Maybe<C_WindBorneDebris_Min_Fields>;
  stddev?: Maybe<C_WindBorneDebris_Stddev_Fields>;
  stddev_pop?: Maybe<C_WindBorneDebris_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_WindBorneDebris_Stddev_Samp_Fields>;
  sum?: Maybe<C_WindBorneDebris_Sum_Fields>;
  var_pop?: Maybe<C_WindBorneDebris_Var_Pop_Fields>;
  var_samp?: Maybe<C_WindBorneDebris_Var_Samp_Fields>;
  variance?: Maybe<C_WindBorneDebris_Variance_Fields>;
};


/** aggregate fields of "C_WindBorneDebris" */
export type C_WindBorneDebris_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_WindBorneDebris_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_WindBorneDebris_Avg_Fields = {
  __typename?: 'C_WindBorneDebris_avg_fields';
  WindBorneDebrisId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_WindBorneDebris". All fields are combined with a logical 'AND'. */
export type C_WindBorneDebris_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  RoofAreas?: Maybe<RoofArea_Bool_Exp>;
  WindBorneDebrisCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  WindBorneDebrisId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_WindBorneDebris_Bool_Exp>>;
  _not?: Maybe<C_WindBorneDebris_Bool_Exp>;
  _or?: Maybe<Array<C_WindBorneDebris_Bool_Exp>>;
};

/** upsert condition type for table "C_WindBorneDebris" */
export type C_WindBorneDebris_If_Matched = {
  match_columns?: Array<C_WindBorneDebris_Insert_Match_Column>;
  update_columns?: Array<C_WindBorneDebris_Update_Column>;
  where?: Maybe<C_WindBorneDebris_Bool_Exp>;
};

/** input type for inserting data into table "C_WindBorneDebris" */
export type C_WindBorneDebris_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WindBorneDebrisCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_WindBorneDebris" */
export enum C_WindBorneDebris_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WindBorneDebrisCode = 'WindBorneDebrisCode',
  /** column name */
  WindBorneDebrisId = 'WindBorneDebrisId'
}

/** aggregate max on columns */
export type C_WindBorneDebris_Max_Fields = {
  __typename?: 'C_WindBorneDebris_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WindBorneDebrisCode?: Maybe<Scalars['CodeTableValue']>;
  WindBorneDebrisId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_WindBorneDebris_Min_Fields = {
  __typename?: 'C_WindBorneDebris_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WindBorneDebrisCode?: Maybe<Scalars['CodeTableValue']>;
  WindBorneDebrisId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_WindBorneDebris" */
export type C_WindBorneDebris_Mutation_Response = {
  __typename?: 'C_WindBorneDebris_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_WindBorneDebris>;
};

/** Ordering options when selecting data from "C_WindBorneDebris". */
export type C_WindBorneDebris_Order_By = {
  Dsc?: Maybe<Order_By>;
  RoofAreas_aggregate?: Maybe<RoofArea_Aggregate_Order_By>;
  WindBorneDebrisCode?: Maybe<Order_By>;
  WindBorneDebrisId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_WindBorneDebris */
export type C_WindBorneDebris_Pk_Columns_Input = {
  WindBorneDebrisId: Scalars['Int'];
};

/** select columns of table "C_WindBorneDebris" */
export enum C_WindBorneDebris_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WindBorneDebrisCode = 'WindBorneDebrisCode',
  /** column name */
  WindBorneDebrisId = 'WindBorneDebrisId'
}

/** input type for updating data in table "C_WindBorneDebris" */
export type C_WindBorneDebris_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WindBorneDebrisCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_WindBorneDebris_Stddev_Fields = {
  __typename?: 'C_WindBorneDebris_stddev_fields';
  WindBorneDebrisId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_WindBorneDebris_Stddev_Pop_Fields = {
  __typename?: 'C_WindBorneDebris_stddev_pop_fields';
  WindBorneDebrisId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_WindBorneDebris_Stddev_Samp_Fields = {
  __typename?: 'C_WindBorneDebris_stddev_samp_fields';
  WindBorneDebrisId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_WindBorneDebris_Sum_Fields = {
  __typename?: 'C_WindBorneDebris_sum_fields';
  WindBorneDebrisId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_WindBorneDebris" */
export enum C_WindBorneDebris_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WindBorneDebrisCode = 'WindBorneDebrisCode'
}

/** aggregate var_pop on columns */
export type C_WindBorneDebris_Var_Pop_Fields = {
  __typename?: 'C_WindBorneDebris_var_pop_fields';
  WindBorneDebrisId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_WindBorneDebris_Var_Samp_Fields = {
  __typename?: 'C_WindBorneDebris_var_samp_fields';
  WindBorneDebrisId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_WindBorneDebris_Variance_Fields = {
  __typename?: 'C_WindBorneDebris_variance_fields';
  WindBorneDebrisId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_WoodSpecies" */
export type C_WoodSpecies = {
  __typename?: 'C_WoodSpecies';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  WoodDecks: Array<WoodDeck>;
  /** An aggregate relationship */
  WoodDecks_aggregate: WoodDeck_Aggregate;
  WoodSpeciesCode: Scalars['CodeTableValue'];
  WoodSpeciesId: Scalars['Int'];
};


/** columns and relationships of "C_WoodSpecies" */
export type C_WoodSpeciesWoodDecksArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WoodDeck_Order_By>>;
  where?: Maybe<WoodDeck_Bool_Exp>;
};


/** columns and relationships of "C_WoodSpecies" */
export type C_WoodSpeciesWoodDecks_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WoodDeck_Order_By>>;
  where?: Maybe<WoodDeck_Bool_Exp>;
};

/** aggregated selection of "C_WoodSpecies" */
export type C_WoodSpecies_Aggregate = {
  __typename?: 'C_WoodSpecies_aggregate';
  aggregate?: Maybe<C_WoodSpecies_Aggregate_Fields>;
  nodes: Array<C_WoodSpecies>;
};

/** aggregate fields of "C_WoodSpecies" */
export type C_WoodSpecies_Aggregate_Fields = {
  __typename?: 'C_WoodSpecies_aggregate_fields';
  avg?: Maybe<C_WoodSpecies_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_WoodSpecies_Max_Fields>;
  min?: Maybe<C_WoodSpecies_Min_Fields>;
  stddev?: Maybe<C_WoodSpecies_Stddev_Fields>;
  stddev_pop?: Maybe<C_WoodSpecies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_WoodSpecies_Stddev_Samp_Fields>;
  sum?: Maybe<C_WoodSpecies_Sum_Fields>;
  var_pop?: Maybe<C_WoodSpecies_Var_Pop_Fields>;
  var_samp?: Maybe<C_WoodSpecies_Var_Samp_Fields>;
  variance?: Maybe<C_WoodSpecies_Variance_Fields>;
};


/** aggregate fields of "C_WoodSpecies" */
export type C_WoodSpecies_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_WoodSpecies_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_WoodSpecies_Avg_Fields = {
  __typename?: 'C_WoodSpecies_avg_fields';
  WoodSpeciesId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_WoodSpecies". All fields are combined with a logical 'AND'. */
export type C_WoodSpecies_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  WoodDecks?: Maybe<WoodDeck_Bool_Exp>;
  WoodSpeciesCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  WoodSpeciesId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_WoodSpecies_Bool_Exp>>;
  _not?: Maybe<C_WoodSpecies_Bool_Exp>;
  _or?: Maybe<Array<C_WoodSpecies_Bool_Exp>>;
};

/** upsert condition type for table "C_WoodSpecies" */
export type C_WoodSpecies_If_Matched = {
  match_columns?: Array<C_WoodSpecies_Insert_Match_Column>;
  update_columns?: Array<C_WoodSpecies_Update_Column>;
  where?: Maybe<C_WoodSpecies_Bool_Exp>;
};

/** input type for inserting data into table "C_WoodSpecies" */
export type C_WoodSpecies_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WoodSpeciesCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_WoodSpecies" */
export enum C_WoodSpecies_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WoodSpeciesCode = 'WoodSpeciesCode',
  /** column name */
  WoodSpeciesId = 'WoodSpeciesId'
}

/** aggregate max on columns */
export type C_WoodSpecies_Max_Fields = {
  __typename?: 'C_WoodSpecies_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WoodSpeciesCode?: Maybe<Scalars['CodeTableValue']>;
  WoodSpeciesId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_WoodSpecies_Min_Fields = {
  __typename?: 'C_WoodSpecies_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WoodSpeciesCode?: Maybe<Scalars['CodeTableValue']>;
  WoodSpeciesId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_WoodSpecies" */
export type C_WoodSpecies_Mutation_Response = {
  __typename?: 'C_WoodSpecies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_WoodSpecies>;
};

/** Ordering options when selecting data from "C_WoodSpecies". */
export type C_WoodSpecies_Order_By = {
  Dsc?: Maybe<Order_By>;
  WoodDecks_aggregate?: Maybe<WoodDeck_Aggregate_Order_By>;
  WoodSpeciesCode?: Maybe<Order_By>;
  WoodSpeciesId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_WoodSpecies */
export type C_WoodSpecies_Pk_Columns_Input = {
  WoodSpeciesId: Scalars['Int'];
};

/** select columns of table "C_WoodSpecies" */
export enum C_WoodSpecies_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WoodSpeciesCode = 'WoodSpeciesCode',
  /** column name */
  WoodSpeciesId = 'WoodSpeciesId'
}

/** input type for updating data in table "C_WoodSpecies" */
export type C_WoodSpecies_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WoodSpeciesCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_WoodSpecies_Stddev_Fields = {
  __typename?: 'C_WoodSpecies_stddev_fields';
  WoodSpeciesId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_WoodSpecies_Stddev_Pop_Fields = {
  __typename?: 'C_WoodSpecies_stddev_pop_fields';
  WoodSpeciesId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_WoodSpecies_Stddev_Samp_Fields = {
  __typename?: 'C_WoodSpecies_stddev_samp_fields';
  WoodSpeciesId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_WoodSpecies_Sum_Fields = {
  __typename?: 'C_WoodSpecies_sum_fields';
  WoodSpeciesId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_WoodSpecies" */
export enum C_WoodSpecies_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WoodSpeciesCode = 'WoodSpeciesCode'
}

/** aggregate var_pop on columns */
export type C_WoodSpecies_Var_Pop_Fields = {
  __typename?: 'C_WoodSpecies_var_pop_fields';
  WoodSpeciesId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_WoodSpecies_Var_Samp_Fields = {
  __typename?: 'C_WoodSpecies_var_samp_fields';
  WoodSpeciesId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_WoodSpecies_Variance_Fields = {
  __typename?: 'C_WoodSpecies_variance_fields';
  WoodSpeciesId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_WoodType" */
export type C_WoodType = {
  __typename?: 'C_WoodType';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  WoodDecks: Array<WoodDeck>;
  /** An aggregate relationship */
  WoodDecks_aggregate: WoodDeck_Aggregate;
  WoodTypeCode: Scalars['CodeTableValue'];
  WoodTypeId: Scalars['Int'];
};


/** columns and relationships of "C_WoodType" */
export type C_WoodTypeWoodDecksArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WoodDeck_Order_By>>;
  where?: Maybe<WoodDeck_Bool_Exp>;
};


/** columns and relationships of "C_WoodType" */
export type C_WoodTypeWoodDecks_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WoodDeck_Order_By>>;
  where?: Maybe<WoodDeck_Bool_Exp>;
};

/** aggregated selection of "C_WoodType" */
export type C_WoodType_Aggregate = {
  __typename?: 'C_WoodType_aggregate';
  aggregate?: Maybe<C_WoodType_Aggregate_Fields>;
  nodes: Array<C_WoodType>;
};

/** aggregate fields of "C_WoodType" */
export type C_WoodType_Aggregate_Fields = {
  __typename?: 'C_WoodType_aggregate_fields';
  avg?: Maybe<C_WoodType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_WoodType_Max_Fields>;
  min?: Maybe<C_WoodType_Min_Fields>;
  stddev?: Maybe<C_WoodType_Stddev_Fields>;
  stddev_pop?: Maybe<C_WoodType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_WoodType_Stddev_Samp_Fields>;
  sum?: Maybe<C_WoodType_Sum_Fields>;
  var_pop?: Maybe<C_WoodType_Var_Pop_Fields>;
  var_samp?: Maybe<C_WoodType_Var_Samp_Fields>;
  variance?: Maybe<C_WoodType_Variance_Fields>;
};


/** aggregate fields of "C_WoodType" */
export type C_WoodType_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_WoodType_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_WoodType_Avg_Fields = {
  __typename?: 'C_WoodType_avg_fields';
  WoodTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_WoodType". All fields are combined with a logical 'AND'. */
export type C_WoodType_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  WoodDecks?: Maybe<WoodDeck_Bool_Exp>;
  WoodTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  WoodTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_WoodType_Bool_Exp>>;
  _not?: Maybe<C_WoodType_Bool_Exp>;
  _or?: Maybe<Array<C_WoodType_Bool_Exp>>;
};

/** upsert condition type for table "C_WoodType" */
export type C_WoodType_If_Matched = {
  match_columns?: Array<C_WoodType_Insert_Match_Column>;
  update_columns?: Array<C_WoodType_Update_Column>;
  where?: Maybe<C_WoodType_Bool_Exp>;
};

/** input type for inserting data into table "C_WoodType" */
export type C_WoodType_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WoodTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_WoodType" */
export enum C_WoodType_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WoodTypeCode = 'WoodTypeCode',
  /** column name */
  WoodTypeId = 'WoodTypeId'
}

/** aggregate max on columns */
export type C_WoodType_Max_Fields = {
  __typename?: 'C_WoodType_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WoodTypeCode?: Maybe<Scalars['CodeTableValue']>;
  WoodTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_WoodType_Min_Fields = {
  __typename?: 'C_WoodType_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WoodTypeCode?: Maybe<Scalars['CodeTableValue']>;
  WoodTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_WoodType" */
export type C_WoodType_Mutation_Response = {
  __typename?: 'C_WoodType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_WoodType>;
};

/** Ordering options when selecting data from "C_WoodType". */
export type C_WoodType_Order_By = {
  Dsc?: Maybe<Order_By>;
  WoodDecks_aggregate?: Maybe<WoodDeck_Aggregate_Order_By>;
  WoodTypeCode?: Maybe<Order_By>;
  WoodTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_WoodType */
export type C_WoodType_Pk_Columns_Input = {
  WoodTypeId: Scalars['Int'];
};

/** select columns of table "C_WoodType" */
export enum C_WoodType_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WoodTypeCode = 'WoodTypeCode',
  /** column name */
  WoodTypeId = 'WoodTypeId'
}

/** input type for updating data in table "C_WoodType" */
export type C_WoodType_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WoodTypeCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_WoodType_Stddev_Fields = {
  __typename?: 'C_WoodType_stddev_fields';
  WoodTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_WoodType_Stddev_Pop_Fields = {
  __typename?: 'C_WoodType_stddev_pop_fields';
  WoodTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_WoodType_Stddev_Samp_Fields = {
  __typename?: 'C_WoodType_stddev_samp_fields';
  WoodTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_WoodType_Sum_Fields = {
  __typename?: 'C_WoodType_sum_fields';
  WoodTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_WoodType" */
export enum C_WoodType_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WoodTypeCode = 'WoodTypeCode'
}

/** aggregate var_pop on columns */
export type C_WoodType_Var_Pop_Fields = {
  __typename?: 'C_WoodType_var_pop_fields';
  WoodTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_WoodType_Var_Samp_Fields = {
  __typename?: 'C_WoodType_var_samp_fields';
  WoodTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_WoodType_Variance_Fields = {
  __typename?: 'C_WoodType_variance_fields';
  WoodTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "C_WorkflowStatus" */
export type C_WorkflowStatus = {
  __typename?: 'C_WorkflowStatus';
  Dsc: Scalars['CodeTableDescription'];
  /** An array relationship */
  ItemWorkspaces: Array<ItemWorkspace>;
  /** An aggregate relationship */
  ItemWorkspaces_aggregate: ItemWorkspace_Aggregate;
  /** An array relationship */
  ReviewerWorkspaces: Array<ReviewerWorkspace>;
  /** An aggregate relationship */
  ReviewerWorkspaces_aggregate: ReviewerWorkspace_Aggregate;
  WorkflowStatusCode: Scalars['CodeTableValue'];
  WorkflowStatusId: Scalars['Int'];
};


/** columns and relationships of "C_WorkflowStatus" */
export type C_WorkflowStatusItemWorkspacesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspace_Order_By>>;
  where?: Maybe<ItemWorkspace_Bool_Exp>;
};


/** columns and relationships of "C_WorkflowStatus" */
export type C_WorkflowStatusItemWorkspaces_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspace_Order_By>>;
  where?: Maybe<ItemWorkspace_Bool_Exp>;
};


/** columns and relationships of "C_WorkflowStatus" */
export type C_WorkflowStatusReviewerWorkspacesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspace_Order_By>>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};


/** columns and relationships of "C_WorkflowStatus" */
export type C_WorkflowStatusReviewerWorkspaces_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspace_Order_By>>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};

/** aggregated selection of "C_WorkflowStatus" */
export type C_WorkflowStatus_Aggregate = {
  __typename?: 'C_WorkflowStatus_aggregate';
  aggregate?: Maybe<C_WorkflowStatus_Aggregate_Fields>;
  nodes: Array<C_WorkflowStatus>;
};

/** aggregate fields of "C_WorkflowStatus" */
export type C_WorkflowStatus_Aggregate_Fields = {
  __typename?: 'C_WorkflowStatus_aggregate_fields';
  avg?: Maybe<C_WorkflowStatus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<C_WorkflowStatus_Max_Fields>;
  min?: Maybe<C_WorkflowStatus_Min_Fields>;
  stddev?: Maybe<C_WorkflowStatus_Stddev_Fields>;
  stddev_pop?: Maybe<C_WorkflowStatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<C_WorkflowStatus_Stddev_Samp_Fields>;
  sum?: Maybe<C_WorkflowStatus_Sum_Fields>;
  var_pop?: Maybe<C_WorkflowStatus_Var_Pop_Fields>;
  var_samp?: Maybe<C_WorkflowStatus_Var_Samp_Fields>;
  variance?: Maybe<C_WorkflowStatus_Variance_Fields>;
};


/** aggregate fields of "C_WorkflowStatus" */
export type C_WorkflowStatus_Aggregate_FieldsCountArgs = {
  column?: Maybe<C_WorkflowStatus_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type C_WorkflowStatus_Avg_Fields = {
  __typename?: 'C_WorkflowStatus_avg_fields';
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "C_WorkflowStatus". All fields are combined with a logical 'AND'. */
export type C_WorkflowStatus_Bool_Exp = {
  Dsc?: Maybe<CodeTableDescription_Mssql_Comparison_Exp>;
  ItemWorkspaces?: Maybe<ItemWorkspace_Bool_Exp>;
  ReviewerWorkspaces?: Maybe<ReviewerWorkspace_Bool_Exp>;
  WorkflowStatusCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  WorkflowStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<C_WorkflowStatus_Bool_Exp>>;
  _not?: Maybe<C_WorkflowStatus_Bool_Exp>;
  _or?: Maybe<Array<C_WorkflowStatus_Bool_Exp>>;
};

/** upsert condition type for table "C_WorkflowStatus" */
export type C_WorkflowStatus_If_Matched = {
  match_columns?: Array<C_WorkflowStatus_Insert_Match_Column>;
  update_columns?: Array<C_WorkflowStatus_Update_Column>;
  where?: Maybe<C_WorkflowStatus_Bool_Exp>;
};

/** input type for inserting data into table "C_WorkflowStatus" */
export type C_WorkflowStatus_Insert_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WorkflowStatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "C_WorkflowStatus" */
export enum C_WorkflowStatus_Insert_Match_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WorkflowStatusCode = 'WorkflowStatusCode',
  /** column name */
  WorkflowStatusId = 'WorkflowStatusId'
}

/** aggregate max on columns */
export type C_WorkflowStatus_Max_Fields = {
  __typename?: 'C_WorkflowStatus_max_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WorkflowStatusCode?: Maybe<Scalars['CodeTableValue']>;
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type C_WorkflowStatus_Min_Fields = {
  __typename?: 'C_WorkflowStatus_min_fields';
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WorkflowStatusCode?: Maybe<Scalars['CodeTableValue']>;
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "C_WorkflowStatus" */
export type C_WorkflowStatus_Mutation_Response = {
  __typename?: 'C_WorkflowStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<C_WorkflowStatus>;
};

/** Ordering options when selecting data from "C_WorkflowStatus". */
export type C_WorkflowStatus_Order_By = {
  Dsc?: Maybe<Order_By>;
  ItemWorkspaces_aggregate?: Maybe<ItemWorkspace_Aggregate_Order_By>;
  ReviewerWorkspaces_aggregate?: Maybe<ReviewerWorkspace_Aggregate_Order_By>;
  WorkflowStatusCode?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** primary key columns input for table: C_WorkflowStatus */
export type C_WorkflowStatus_Pk_Columns_Input = {
  WorkflowStatusId: Scalars['Int'];
};

/** select columns of table "C_WorkflowStatus" */
export enum C_WorkflowStatus_Select_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WorkflowStatusCode = 'WorkflowStatusCode',
  /** column name */
  WorkflowStatusId = 'WorkflowStatusId'
}

/** input type for updating data in table "C_WorkflowStatus" */
export type C_WorkflowStatus_Set_Input = {
  Dsc?: Maybe<Scalars['CodeTableDescription']>;
  WorkflowStatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type C_WorkflowStatus_Stddev_Fields = {
  __typename?: 'C_WorkflowStatus_stddev_fields';
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type C_WorkflowStatus_Stddev_Pop_Fields = {
  __typename?: 'C_WorkflowStatus_stddev_pop_fields';
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type C_WorkflowStatus_Stddev_Samp_Fields = {
  __typename?: 'C_WorkflowStatus_stddev_samp_fields';
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type C_WorkflowStatus_Sum_Fields = {
  __typename?: 'C_WorkflowStatus_sum_fields';
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "C_WorkflowStatus" */
export enum C_WorkflowStatus_Update_Column {
  /** column name */
  Dsc = 'Dsc',
  /** column name */
  WorkflowStatusCode = 'WorkflowStatusCode'
}

/** aggregate var_pop on columns */
export type C_WorkflowStatus_Var_Pop_Fields = {
  __typename?: 'C_WorkflowStatus_var_pop_fields';
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type C_WorkflowStatus_Var_Samp_Fields = {
  __typename?: 'C_WorkflowStatus_var_samp_fields';
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type C_WorkflowStatus_Variance_Fields = {
  __typename?: 'C_WorkflowStatus_variance_fields';
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "CapPly" */
export type CapPly = {
  __typename?: 'CapPly';
  BackingMaterialId: Scalars['Int'];
  /** An object relationship */
  C_BackingMaterial: C_BackingMaterial;
  /** An object relationship */
  C_MultiplyMaterial: C_MultiplyMaterial;
  /** An object relationship */
  C_Reinforcement: C_Reinforcement;
  /** An object relationship */
  C_SurfaceType: C_SurfaceType;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  MultiplyMaterialId: Scalars['Int'];
  ReinforcementId: Scalars['Int'];
  SurfaceTypeId: Scalars['Int'];
  Width: Scalars['MeasurementValue'];
};

/** columns and relationships of "CapPlyUsage" */
export type CapPlyUsage = {
  __typename?: 'CapPlyUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "CapPlyUsage" */
export type CapPlyUsage_Aggregate = {
  __typename?: 'CapPlyUsage_aggregate';
  aggregate?: Maybe<CapPlyUsage_Aggregate_Fields>;
  nodes: Array<CapPlyUsage>;
};

/** aggregate fields of "CapPlyUsage" */
export type CapPlyUsage_Aggregate_Fields = {
  __typename?: 'CapPlyUsage_aggregate_fields';
  avg?: Maybe<CapPlyUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CapPlyUsage_Max_Fields>;
  min?: Maybe<CapPlyUsage_Min_Fields>;
  stddev?: Maybe<CapPlyUsage_Stddev_Fields>;
  stddev_pop?: Maybe<CapPlyUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CapPlyUsage_Stddev_Samp_Fields>;
  sum?: Maybe<CapPlyUsage_Sum_Fields>;
  var_pop?: Maybe<CapPlyUsage_Var_Pop_Fields>;
  var_samp?: Maybe<CapPlyUsage_Var_Samp_Fields>;
  variance?: Maybe<CapPlyUsage_Variance_Fields>;
};


/** aggregate fields of "CapPlyUsage" */
export type CapPlyUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<CapPlyUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CapPlyUsage_Avg_Fields = {
  __typename?: 'CapPlyUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CapPlyUsage". All fields are combined with a logical 'AND'. */
export type CapPlyUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CapPlyUsage_Bool_Exp>>;
  _not?: Maybe<CapPlyUsage_Bool_Exp>;
  _or?: Maybe<Array<CapPlyUsage_Bool_Exp>>;
};

/** upsert condition type for table "CapPlyUsage" */
export type CapPlyUsage_If_Matched = {
  match_columns?: Array<CapPlyUsage_Insert_Match_Column>;
  update_columns?: Array<CapPlyUsage_Update_Column>;
  where?: Maybe<CapPlyUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CapPlyUsage" */
export type CapPlyUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CapPlyUsage" */
export type CapPlyUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "CapPlyUsage" */
export enum CapPlyUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type CapPlyUsage_Max_Fields = {
  __typename?: 'CapPlyUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CapPlyUsage_Min_Fields = {
  __typename?: 'CapPlyUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "CapPlyUsage" */
export type CapPlyUsage_Mutation_Response = {
  __typename?: 'CapPlyUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CapPlyUsage>;
};

/** Ordering options when selecting data from "CapPlyUsage". */
export type CapPlyUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: CapPlyUsage */
export type CapPlyUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "CapPlyUsage" */
export enum CapPlyUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "CapPlyUsage" */
export type CapPlyUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CapPlyUsage_Stddev_Fields = {
  __typename?: 'CapPlyUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CapPlyUsage_Stddev_Pop_Fields = {
  __typename?: 'CapPlyUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CapPlyUsage_Stddev_Samp_Fields = {
  __typename?: 'CapPlyUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CapPlyUsage_Sum_Fields = {
  __typename?: 'CapPlyUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "CapPlyUsage" */
export enum CapPlyUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type CapPlyUsage_Var_Pop_Fields = {
  __typename?: 'CapPlyUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CapPlyUsage_Var_Samp_Fields = {
  __typename?: 'CapPlyUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CapPlyUsage_Variance_Fields = {
  __typename?: 'CapPlyUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "CapPly" */
export type CapPly_Aggregate = {
  __typename?: 'CapPly_aggregate';
  aggregate?: Maybe<CapPly_Aggregate_Fields>;
  nodes: Array<CapPly>;
};

/** aggregate fields of "CapPly" */
export type CapPly_Aggregate_Fields = {
  __typename?: 'CapPly_aggregate_fields';
  avg?: Maybe<CapPly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CapPly_Max_Fields>;
  min?: Maybe<CapPly_Min_Fields>;
  stddev?: Maybe<CapPly_Stddev_Fields>;
  stddev_pop?: Maybe<CapPly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CapPly_Stddev_Samp_Fields>;
  sum?: Maybe<CapPly_Sum_Fields>;
  var_pop?: Maybe<CapPly_Var_Pop_Fields>;
  var_samp?: Maybe<CapPly_Var_Samp_Fields>;
  variance?: Maybe<CapPly_Variance_Fields>;
};


/** aggregate fields of "CapPly" */
export type CapPly_Aggregate_FieldsCountArgs = {
  column?: Maybe<CapPly_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CapPly" */
export type CapPly_Aggregate_Order_By = {
  avg?: Maybe<CapPly_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<CapPly_Max_Order_By>;
  min?: Maybe<CapPly_Min_Order_By>;
  stddev?: Maybe<CapPly_Stddev_Order_By>;
  stddev_pop?: Maybe<CapPly_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<CapPly_Stddev_Samp_Order_By>;
  sum?: Maybe<CapPly_Sum_Order_By>;
  var_pop?: Maybe<CapPly_Var_Pop_Order_By>;
  var_samp?: Maybe<CapPly_Var_Samp_Order_By>;
  variance?: Maybe<CapPly_Variance_Order_By>;
};

/** aggregate avg on columns */
export type CapPly_Avg_Fields = {
  __typename?: 'CapPly_avg_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SurfaceTypeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CapPly" */
export type CapPly_Avg_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CapPly". All fields are combined with a logical 'AND'. */
export type CapPly_Bool_Exp = {
  BackingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_BackingMaterial?: Maybe<C_BackingMaterial_Bool_Exp>;
  C_MultiplyMaterial?: Maybe<C_MultiplyMaterial_Bool_Exp>;
  C_Reinforcement?: Maybe<C_Reinforcement_Bool_Exp>;
  C_SurfaceType?: Maybe<C_SurfaceType_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  MultiplyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ReinforcementId?: Maybe<Int_Mssql_Comparison_Exp>;
  SurfaceTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Width?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CapPly_Bool_Exp>>;
  _not?: Maybe<CapPly_Bool_Exp>;
  _or?: Maybe<Array<CapPly_Bool_Exp>>;
};

/** upsert condition type for table "CapPly" */
export type CapPly_If_Matched = {
  match_columns?: Array<CapPly_Insert_Match_Column>;
  update_columns?: Array<CapPly_Update_Column>;
  where?: Maybe<CapPly_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CapPly" */
export type CapPly_Inc_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SurfaceTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CapPly" */
export type CapPly_Insert_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SurfaceTypeId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "CapPly" */
export enum CapPly_Insert_Match_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  SurfaceTypeId = 'SurfaceTypeId',
  /** column name */
  Width = 'Width'
}

/** aggregate max on columns */
export type CapPly_Max_Fields = {
  __typename?: 'CapPly_max_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SurfaceTypeId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "CapPly" */
export type CapPly_Max_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CapPly_Min_Fields = {
  __typename?: 'CapPly_min_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SurfaceTypeId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "CapPly" */
export type CapPly_Min_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** response of any mutation on the table "CapPly" */
export type CapPly_Mutation_Response = {
  __typename?: 'CapPly_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CapPly>;
};

/** Ordering options when selecting data from "CapPly". */
export type CapPly_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  C_BackingMaterial?: Maybe<C_BackingMaterial_Order_By>;
  C_MultiplyMaterial?: Maybe<C_MultiplyMaterial_Order_By>;
  C_Reinforcement?: Maybe<C_Reinforcement_Order_By>;
  C_SurfaceType?: Maybe<C_SurfaceType_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** primary key columns input for table: CapPly */
export type CapPly_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "CapPly" */
export enum CapPly_Select_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  SurfaceTypeId = 'SurfaceTypeId',
  /** column name */
  Width = 'Width'
}

/** input type for updating data in table "CapPly" */
export type CapPly_Set_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SurfaceTypeId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type CapPly_Stddev_Fields = {
  __typename?: 'CapPly_stddev_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SurfaceTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CapPly" */
export type CapPly_Stddev_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CapPly_Stddev_Pop_Fields = {
  __typename?: 'CapPly_stddev_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SurfaceTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CapPly" */
export type CapPly_Stddev_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CapPly_Stddev_Samp_Fields = {
  __typename?: 'CapPly_stddev_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SurfaceTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CapPly" */
export type CapPly_Stddev_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type CapPly_Sum_Fields = {
  __typename?: 'CapPly_sum_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SurfaceTypeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CapPly" */
export type CapPly_Sum_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
};

/** update columns of table "CapPly" */
export enum CapPly_Update_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  SurfaceTypeId = 'SurfaceTypeId',
  /** column name */
  Width = 'Width'
}

/** aggregate var_pop on columns */
export type CapPly_Var_Pop_Fields = {
  __typename?: 'CapPly_var_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SurfaceTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CapPly" */
export type CapPly_Var_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CapPly_Var_Samp_Fields = {
  __typename?: 'CapPly_var_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SurfaceTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CapPly" */
export type CapPly_Var_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type CapPly_Variance_Fields = {
  __typename?: 'CapPly_variance_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SurfaceTypeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CapPly" */
export type CapPly_Variance_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
};

/** columns and relationships of "Category" */
export type Category = {
  __typename?: 'Category';
  CategoryID: Scalars['Int'];
  CategoryName: Scalars['String'];
};

/** columns and relationships of "CategoryLog" */
export type CategoryLog = {
  __typename?: 'CategoryLog';
  CategoryID: Scalars['Int'];
  CategoryLogID: Scalars['Int'];
  LogID: Scalars['Int'];
};

/** aggregated selection of "CategoryLog" */
export type CategoryLog_Aggregate = {
  __typename?: 'CategoryLog_aggregate';
  aggregate?: Maybe<CategoryLog_Aggregate_Fields>;
  nodes: Array<CategoryLog>;
};

/** aggregate fields of "CategoryLog" */
export type CategoryLog_Aggregate_Fields = {
  __typename?: 'CategoryLog_aggregate_fields';
  avg?: Maybe<CategoryLog_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CategoryLog_Max_Fields>;
  min?: Maybe<CategoryLog_Min_Fields>;
  stddev?: Maybe<CategoryLog_Stddev_Fields>;
  stddev_pop?: Maybe<CategoryLog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CategoryLog_Stddev_Samp_Fields>;
  sum?: Maybe<CategoryLog_Sum_Fields>;
  var_pop?: Maybe<CategoryLog_Var_Pop_Fields>;
  var_samp?: Maybe<CategoryLog_Var_Samp_Fields>;
  variance?: Maybe<CategoryLog_Variance_Fields>;
};


/** aggregate fields of "CategoryLog" */
export type CategoryLog_Aggregate_FieldsCountArgs = {
  column?: Maybe<CategoryLog_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CategoryLog_Avg_Fields = {
  __typename?: 'CategoryLog_avg_fields';
  CategoryID?: Maybe<Scalars['Float']>;
  CategoryLogID?: Maybe<Scalars['Float']>;
  LogID?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CategoryLog". All fields are combined with a logical 'AND'. */
export type CategoryLog_Bool_Exp = {
  CategoryID?: Maybe<Int_Mssql_Comparison_Exp>;
  CategoryLogID?: Maybe<Int_Mssql_Comparison_Exp>;
  LogID?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CategoryLog_Bool_Exp>>;
  _not?: Maybe<CategoryLog_Bool_Exp>;
  _or?: Maybe<Array<CategoryLog_Bool_Exp>>;
};

/** upsert condition type for table "CategoryLog" */
export type CategoryLog_If_Matched = {
  match_columns?: Array<CategoryLog_Insert_Match_Column>;
  update_columns?: Array<CategoryLog_Update_Column>;
  where?: Maybe<CategoryLog_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CategoryLog" */
export type CategoryLog_Inc_Input = {
  CategoryID?: Maybe<Scalars['Int']>;
  LogID?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CategoryLog" */
export type CategoryLog_Insert_Input = {
  CategoryID?: Maybe<Scalars['Int']>;
  LogID?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "CategoryLog" */
export enum CategoryLog_Insert_Match_Column {
  /** column name */
  CategoryId = 'CategoryID',
  /** column name */
  CategoryLogId = 'CategoryLogID',
  /** column name */
  LogId = 'LogID'
}

/** aggregate max on columns */
export type CategoryLog_Max_Fields = {
  __typename?: 'CategoryLog_max_fields';
  CategoryID?: Maybe<Scalars['Int']>;
  CategoryLogID?: Maybe<Scalars['Int']>;
  LogID?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CategoryLog_Min_Fields = {
  __typename?: 'CategoryLog_min_fields';
  CategoryID?: Maybe<Scalars['Int']>;
  CategoryLogID?: Maybe<Scalars['Int']>;
  LogID?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "CategoryLog" */
export type CategoryLog_Mutation_Response = {
  __typename?: 'CategoryLog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CategoryLog>;
};

/** Ordering options when selecting data from "CategoryLog". */
export type CategoryLog_Order_By = {
  CategoryID?: Maybe<Order_By>;
  CategoryLogID?: Maybe<Order_By>;
  LogID?: Maybe<Order_By>;
};

/** primary key columns input for table: CategoryLog */
export type CategoryLog_Pk_Columns_Input = {
  CategoryLogID: Scalars['Int'];
};

/** select columns of table "CategoryLog" */
export enum CategoryLog_Select_Column {
  /** column name */
  CategoryId = 'CategoryID',
  /** column name */
  CategoryLogId = 'CategoryLogID',
  /** column name */
  LogId = 'LogID'
}

/** input type for updating data in table "CategoryLog" */
export type CategoryLog_Set_Input = {
  CategoryID?: Maybe<Scalars['Int']>;
  LogID?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CategoryLog_Stddev_Fields = {
  __typename?: 'CategoryLog_stddev_fields';
  CategoryID?: Maybe<Scalars['Float']>;
  CategoryLogID?: Maybe<Scalars['Float']>;
  LogID?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CategoryLog_Stddev_Pop_Fields = {
  __typename?: 'CategoryLog_stddev_pop_fields';
  CategoryID?: Maybe<Scalars['Float']>;
  CategoryLogID?: Maybe<Scalars['Float']>;
  LogID?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CategoryLog_Stddev_Samp_Fields = {
  __typename?: 'CategoryLog_stddev_samp_fields';
  CategoryID?: Maybe<Scalars['Float']>;
  CategoryLogID?: Maybe<Scalars['Float']>;
  LogID?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CategoryLog_Sum_Fields = {
  __typename?: 'CategoryLog_sum_fields';
  CategoryID?: Maybe<Scalars['Int']>;
  CategoryLogID?: Maybe<Scalars['Int']>;
  LogID?: Maybe<Scalars['Int']>;
};

/** update columns of table "CategoryLog" */
export enum CategoryLog_Update_Column {
  /** column name */
  CategoryId = 'CategoryID',
  /** column name */
  LogId = 'LogID'
}

/** aggregate var_pop on columns */
export type CategoryLog_Var_Pop_Fields = {
  __typename?: 'CategoryLog_var_pop_fields';
  CategoryID?: Maybe<Scalars['Float']>;
  CategoryLogID?: Maybe<Scalars['Float']>;
  LogID?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CategoryLog_Var_Samp_Fields = {
  __typename?: 'CategoryLog_var_samp_fields';
  CategoryID?: Maybe<Scalars['Float']>;
  CategoryLogID?: Maybe<Scalars['Float']>;
  LogID?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CategoryLog_Variance_Fields = {
  __typename?: 'CategoryLog_variance_fields';
  CategoryID?: Maybe<Scalars['Float']>;
  CategoryLogID?: Maybe<Scalars['Float']>;
  LogID?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Category_Coating" */
export type Category_Coating = {
  __typename?: 'Category_Coating';
  ApprovedUseCode: Scalars['CodeTableValue'];
  ApprovedUseId: Scalars['Int'];
  ApprovedUseTableName: Scalars['String'];
  CategoryName: Scalars['String'];
  ComponentId: Scalars['Int'];
  ComponentStatusId: Scalars['Int'];
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified: Scalars['datetime'];
  LockStatusId: Scalars['Int'];
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  ManufacturerName: Scalars['String'];
  ModifiedByUserAccountId: Scalars['Int'];
  StatusId: Scalars['Int'];
  SubcategoryName: Scalars['String'];
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Category_Coating" */
export type Category_Coating_Aggregate = {
  __typename?: 'Category_Coating_aggregate';
  aggregate?: Maybe<Category_Coating_Aggregate_Fields>;
  nodes: Array<Category_Coating>;
};

/** aggregate fields of "Category_Coating" */
export type Category_Coating_Aggregate_Fields = {
  __typename?: 'Category_Coating_aggregate_fields';
  avg?: Maybe<Category_Coating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Category_Coating_Max_Fields>;
  min?: Maybe<Category_Coating_Min_Fields>;
  stddev?: Maybe<Category_Coating_Stddev_Fields>;
  stddev_pop?: Maybe<Category_Coating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Category_Coating_Stddev_Samp_Fields>;
  sum?: Maybe<Category_Coating_Sum_Fields>;
  var_pop?: Maybe<Category_Coating_Var_Pop_Fields>;
  var_samp?: Maybe<Category_Coating_Var_Samp_Fields>;
  variance?: Maybe<Category_Coating_Variance_Fields>;
};


/** aggregate fields of "Category_Coating" */
export type Category_Coating_Aggregate_FieldsCountArgs = {
  column?: Maybe<Category_Coating_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Category_Coating_Avg_Fields = {
  __typename?: 'Category_Coating_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Category_Coating". All fields are combined with a logical 'AND'. */
export type Category_Coating_Bool_Exp = {
  ApprovedUseCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  ApprovedUseTableName?: Maybe<String_Mssql_Comparison_Exp>;
  CategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateLocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerName?: Maybe<String_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubcategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Category_Coating_Bool_Exp>>;
  _not?: Maybe<Category_Coating_Bool_Exp>;
  _or?: Maybe<Array<Category_Coating_Bool_Exp>>;
};

/** upsert condition type for table "Category_Coating" */
export type Category_Coating_If_Matched = {
  match_columns?: Array<Category_Coating_Insert_Match_Column>;
  update_columns?: Array<Category_Coating_Update_Column>;
  where?: Maybe<Category_Coating_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Category_Coating" */
export type Category_Coating_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Category_Coating" */
export type Category_Coating_Insert_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Category_Coating" */
export enum Category_Coating_Insert_Match_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate max on columns */
export type Category_Coating_Max_Fields = {
  __typename?: 'Category_Coating_max_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Category_Coating_Min_Fields = {
  __typename?: 'Category_Coating_min_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Category_Coating" */
export type Category_Coating_Mutation_Response = {
  __typename?: 'Category_Coating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Coating>;
};

/** Ordering options when selecting data from "Category_Coating". */
export type Category_Coating_Order_By = {
  ApprovedUseCode?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  ApprovedUseTableName?: Maybe<Order_By>;
  CategoryName?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ComponentStatusId?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ManufacturerName?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubcategoryName?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
};

/** select columns of table "Category_Coating" */
export enum Category_Coating_Select_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** input type for updating data in table "Category_Coating" */
export type Category_Coating_Set_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Category_Coating_Stddev_Fields = {
  __typename?: 'Category_Coating_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Category_Coating_Stddev_Pop_Fields = {
  __typename?: 'Category_Coating_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Category_Coating_Stddev_Samp_Fields = {
  __typename?: 'Category_Coating_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Category_Coating_Sum_Fields = {
  __typename?: 'Category_Coating_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "Category_Coating" */
export enum Category_Coating_Update_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate var_pop on columns */
export type Category_Coating_Var_Pop_Fields = {
  __typename?: 'Category_Coating_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Category_Coating_Var_Samp_Fields = {
  __typename?: 'Category_Coating_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Category_Coating_Variance_Fields = {
  __typename?: 'Category_Coating_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Category_Cover" */
export type Category_Cover = {
  __typename?: 'Category_Cover';
  ApprovedUseCode: Scalars['CodeTableValue'];
  ApprovedUseId: Scalars['Int'];
  ApprovedUseTableName: Scalars['String'];
  CategoryName: Scalars['String'];
  ComponentId: Scalars['Int'];
  ComponentStatusId: Scalars['Int'];
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified: Scalars['datetime'];
  LockStatusId: Scalars['Int'];
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  ManufacturerName: Scalars['String'];
  ModifiedByUserAccountId: Scalars['Int'];
  StatusId: Scalars['Int'];
  SubcategoryName: Scalars['String'];
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Category_Cover" */
export type Category_Cover_Aggregate = {
  __typename?: 'Category_Cover_aggregate';
  aggregate?: Maybe<Category_Cover_Aggregate_Fields>;
  nodes: Array<Category_Cover>;
};

/** aggregate fields of "Category_Cover" */
export type Category_Cover_Aggregate_Fields = {
  __typename?: 'Category_Cover_aggregate_fields';
  avg?: Maybe<Category_Cover_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Category_Cover_Max_Fields>;
  min?: Maybe<Category_Cover_Min_Fields>;
  stddev?: Maybe<Category_Cover_Stddev_Fields>;
  stddev_pop?: Maybe<Category_Cover_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Category_Cover_Stddev_Samp_Fields>;
  sum?: Maybe<Category_Cover_Sum_Fields>;
  var_pop?: Maybe<Category_Cover_Var_Pop_Fields>;
  var_samp?: Maybe<Category_Cover_Var_Samp_Fields>;
  variance?: Maybe<Category_Cover_Variance_Fields>;
};


/** aggregate fields of "Category_Cover" */
export type Category_Cover_Aggregate_FieldsCountArgs = {
  column?: Maybe<Category_Cover_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Category_Cover_Avg_Fields = {
  __typename?: 'Category_Cover_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Category_Cover". All fields are combined with a logical 'AND'. */
export type Category_Cover_Bool_Exp = {
  ApprovedUseCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  ApprovedUseTableName?: Maybe<String_Mssql_Comparison_Exp>;
  CategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateLocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerName?: Maybe<String_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubcategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Category_Cover_Bool_Exp>>;
  _not?: Maybe<Category_Cover_Bool_Exp>;
  _or?: Maybe<Array<Category_Cover_Bool_Exp>>;
};

/** upsert condition type for table "Category_Cover" */
export type Category_Cover_If_Matched = {
  match_columns?: Array<Category_Cover_Insert_Match_Column>;
  update_columns?: Array<Category_Cover_Update_Column>;
  where?: Maybe<Category_Cover_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Category_Cover" */
export type Category_Cover_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Category_Cover" */
export type Category_Cover_Insert_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Category_Cover" */
export enum Category_Cover_Insert_Match_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate max on columns */
export type Category_Cover_Max_Fields = {
  __typename?: 'Category_Cover_max_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Category_Cover_Min_Fields = {
  __typename?: 'Category_Cover_min_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Category_Cover" */
export type Category_Cover_Mutation_Response = {
  __typename?: 'Category_Cover_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Cover>;
};

/** Ordering options when selecting data from "Category_Cover". */
export type Category_Cover_Order_By = {
  ApprovedUseCode?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  ApprovedUseTableName?: Maybe<Order_By>;
  CategoryName?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ComponentStatusId?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ManufacturerName?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubcategoryName?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
};

/** select columns of table "Category_Cover" */
export enum Category_Cover_Select_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** input type for updating data in table "Category_Cover" */
export type Category_Cover_Set_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Category_Cover_Stddev_Fields = {
  __typename?: 'Category_Cover_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Category_Cover_Stddev_Pop_Fields = {
  __typename?: 'Category_Cover_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Category_Cover_Stddev_Samp_Fields = {
  __typename?: 'Category_Cover_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Category_Cover_Sum_Fields = {
  __typename?: 'Category_Cover_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "Category_Cover" */
export enum Category_Cover_Update_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate var_pop on columns */
export type Category_Cover_Var_Pop_Fields = {
  __typename?: 'Category_Cover_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Category_Cover_Var_Samp_Fields = {
  __typename?: 'Category_Cover_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Category_Cover_Variance_Fields = {
  __typename?: 'Category_Cover_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Category_Deck" */
export type Category_Deck = {
  __typename?: 'Category_Deck';
  ApprovedUseCode: Scalars['CodeTableValue'];
  ApprovedUseId: Scalars['Int'];
  ApprovedUseTableName: Scalars['String'];
  CategoryName: Scalars['String'];
  ComponentId: Scalars['Int'];
  ComponentStatusId: Scalars['Int'];
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified: Scalars['datetime'];
  LockStatusId: Scalars['Int'];
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  ManufacturerName: Scalars['String'];
  ModifiedByUserAccountId: Scalars['Int'];
  StatusId: Scalars['Int'];
  SubcategoryName: Scalars['String'];
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Category_Deck" */
export type Category_Deck_Aggregate = {
  __typename?: 'Category_Deck_aggregate';
  aggregate?: Maybe<Category_Deck_Aggregate_Fields>;
  nodes: Array<Category_Deck>;
};

/** aggregate fields of "Category_Deck" */
export type Category_Deck_Aggregate_Fields = {
  __typename?: 'Category_Deck_aggregate_fields';
  avg?: Maybe<Category_Deck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Category_Deck_Max_Fields>;
  min?: Maybe<Category_Deck_Min_Fields>;
  stddev?: Maybe<Category_Deck_Stddev_Fields>;
  stddev_pop?: Maybe<Category_Deck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Category_Deck_Stddev_Samp_Fields>;
  sum?: Maybe<Category_Deck_Sum_Fields>;
  var_pop?: Maybe<Category_Deck_Var_Pop_Fields>;
  var_samp?: Maybe<Category_Deck_Var_Samp_Fields>;
  variance?: Maybe<Category_Deck_Variance_Fields>;
};


/** aggregate fields of "Category_Deck" */
export type Category_Deck_Aggregate_FieldsCountArgs = {
  column?: Maybe<Category_Deck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Category_Deck_Avg_Fields = {
  __typename?: 'Category_Deck_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Category_Deck". All fields are combined with a logical 'AND'. */
export type Category_Deck_Bool_Exp = {
  ApprovedUseCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  ApprovedUseTableName?: Maybe<String_Mssql_Comparison_Exp>;
  CategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateLocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerName?: Maybe<String_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubcategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Category_Deck_Bool_Exp>>;
  _not?: Maybe<Category_Deck_Bool_Exp>;
  _or?: Maybe<Array<Category_Deck_Bool_Exp>>;
};

/** upsert condition type for table "Category_Deck" */
export type Category_Deck_If_Matched = {
  match_columns?: Array<Category_Deck_Insert_Match_Column>;
  update_columns?: Array<Category_Deck_Update_Column>;
  where?: Maybe<Category_Deck_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Category_Deck" */
export type Category_Deck_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Category_Deck" */
export type Category_Deck_Insert_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Category_Deck" */
export enum Category_Deck_Insert_Match_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate max on columns */
export type Category_Deck_Max_Fields = {
  __typename?: 'Category_Deck_max_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Category_Deck_Min_Fields = {
  __typename?: 'Category_Deck_min_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Category_Deck" */
export type Category_Deck_Mutation_Response = {
  __typename?: 'Category_Deck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Deck>;
};

/** Ordering options when selecting data from "Category_Deck". */
export type Category_Deck_Order_By = {
  ApprovedUseCode?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  ApprovedUseTableName?: Maybe<Order_By>;
  CategoryName?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ComponentStatusId?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ManufacturerName?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubcategoryName?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
};

/** select columns of table "Category_Deck" */
export enum Category_Deck_Select_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** input type for updating data in table "Category_Deck" */
export type Category_Deck_Set_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Category_Deck_Stddev_Fields = {
  __typename?: 'Category_Deck_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Category_Deck_Stddev_Pop_Fields = {
  __typename?: 'Category_Deck_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Category_Deck_Stddev_Samp_Fields = {
  __typename?: 'Category_Deck_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Category_Deck_Sum_Fields = {
  __typename?: 'Category_Deck_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "Category_Deck" */
export enum Category_Deck_Update_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate var_pop on columns */
export type Category_Deck_Var_Pop_Fields = {
  __typename?: 'Category_Deck_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Category_Deck_Var_Samp_Fields = {
  __typename?: 'Category_Deck_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Category_Deck_Variance_Fields = {
  __typename?: 'Category_Deck_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Category_Flashing" */
export type Category_Flashing = {
  __typename?: 'Category_Flashing';
  ApprovedUseCode: Scalars['CodeTableValue'];
  ApprovedUseId: Scalars['Int'];
  ApprovedUseTableName: Scalars['String'];
  CategoryName: Scalars['String'];
  ComponentId: Scalars['Int'];
  ComponentStatusId: Scalars['Int'];
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified: Scalars['datetime'];
  LockStatusId: Scalars['Int'];
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  ManufacturerName: Scalars['String'];
  ModifiedByUserAccountId: Scalars['Int'];
  StatusId: Scalars['Int'];
  SubcategoryName: Scalars['String'];
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Category_Flashing" */
export type Category_Flashing_Aggregate = {
  __typename?: 'Category_Flashing_aggregate';
  aggregate?: Maybe<Category_Flashing_Aggregate_Fields>;
  nodes: Array<Category_Flashing>;
};

/** aggregate fields of "Category_Flashing" */
export type Category_Flashing_Aggregate_Fields = {
  __typename?: 'Category_Flashing_aggregate_fields';
  avg?: Maybe<Category_Flashing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Category_Flashing_Max_Fields>;
  min?: Maybe<Category_Flashing_Min_Fields>;
  stddev?: Maybe<Category_Flashing_Stddev_Fields>;
  stddev_pop?: Maybe<Category_Flashing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Category_Flashing_Stddev_Samp_Fields>;
  sum?: Maybe<Category_Flashing_Sum_Fields>;
  var_pop?: Maybe<Category_Flashing_Var_Pop_Fields>;
  var_samp?: Maybe<Category_Flashing_Var_Samp_Fields>;
  variance?: Maybe<Category_Flashing_Variance_Fields>;
};


/** aggregate fields of "Category_Flashing" */
export type Category_Flashing_Aggregate_FieldsCountArgs = {
  column?: Maybe<Category_Flashing_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Category_Flashing_Avg_Fields = {
  __typename?: 'Category_Flashing_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Category_Flashing". All fields are combined with a logical 'AND'. */
export type Category_Flashing_Bool_Exp = {
  ApprovedUseCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  ApprovedUseTableName?: Maybe<String_Mssql_Comparison_Exp>;
  CategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateLocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerName?: Maybe<String_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubcategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Category_Flashing_Bool_Exp>>;
  _not?: Maybe<Category_Flashing_Bool_Exp>;
  _or?: Maybe<Array<Category_Flashing_Bool_Exp>>;
};

/** upsert condition type for table "Category_Flashing" */
export type Category_Flashing_If_Matched = {
  match_columns?: Array<Category_Flashing_Insert_Match_Column>;
  update_columns?: Array<Category_Flashing_Update_Column>;
  where?: Maybe<Category_Flashing_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Category_Flashing" */
export type Category_Flashing_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Category_Flashing" */
export type Category_Flashing_Insert_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Category_Flashing" */
export enum Category_Flashing_Insert_Match_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate max on columns */
export type Category_Flashing_Max_Fields = {
  __typename?: 'Category_Flashing_max_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Category_Flashing_Min_Fields = {
  __typename?: 'Category_Flashing_min_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Category_Flashing" */
export type Category_Flashing_Mutation_Response = {
  __typename?: 'Category_Flashing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Flashing>;
};

/** Ordering options when selecting data from "Category_Flashing". */
export type Category_Flashing_Order_By = {
  ApprovedUseCode?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  ApprovedUseTableName?: Maybe<Order_By>;
  CategoryName?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ComponentStatusId?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ManufacturerName?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubcategoryName?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
};

/** select columns of table "Category_Flashing" */
export enum Category_Flashing_Select_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** input type for updating data in table "Category_Flashing" */
export type Category_Flashing_Set_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Category_Flashing_Stddev_Fields = {
  __typename?: 'Category_Flashing_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Category_Flashing_Stddev_Pop_Fields = {
  __typename?: 'Category_Flashing_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Category_Flashing_Stddev_Samp_Fields = {
  __typename?: 'Category_Flashing_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Category_Flashing_Sum_Fields = {
  __typename?: 'Category_Flashing_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "Category_Flashing" */
export enum Category_Flashing_Update_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate var_pop on columns */
export type Category_Flashing_Var_Pop_Fields = {
  __typename?: 'Category_Flashing_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Category_Flashing_Var_Samp_Fields = {
  __typename?: 'Category_Flashing_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Category_Flashing_Variance_Fields = {
  __typename?: 'Category_Flashing_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Category_Insulation" */
export type Category_Insulation = {
  __typename?: 'Category_Insulation';
  ApprovedUseCode: Scalars['CodeTableValue'];
  ApprovedUseId: Scalars['Int'];
  ApprovedUseTableName: Scalars['String'];
  CategoryName: Scalars['String'];
  ComponentId: Scalars['Int'];
  ComponentStatusId: Scalars['Int'];
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified: Scalars['datetime'];
  LockStatusId: Scalars['Int'];
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  ManufacturerName: Scalars['String'];
  ModifiedByUserAccountId: Scalars['Int'];
  StatusId: Scalars['Int'];
  SubcategoryName: Scalars['String'];
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Category_Insulation" */
export type Category_Insulation_Aggregate = {
  __typename?: 'Category_Insulation_aggregate';
  aggregate?: Maybe<Category_Insulation_Aggregate_Fields>;
  nodes: Array<Category_Insulation>;
};

/** aggregate fields of "Category_Insulation" */
export type Category_Insulation_Aggregate_Fields = {
  __typename?: 'Category_Insulation_aggregate_fields';
  avg?: Maybe<Category_Insulation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Category_Insulation_Max_Fields>;
  min?: Maybe<Category_Insulation_Min_Fields>;
  stddev?: Maybe<Category_Insulation_Stddev_Fields>;
  stddev_pop?: Maybe<Category_Insulation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Category_Insulation_Stddev_Samp_Fields>;
  sum?: Maybe<Category_Insulation_Sum_Fields>;
  var_pop?: Maybe<Category_Insulation_Var_Pop_Fields>;
  var_samp?: Maybe<Category_Insulation_Var_Samp_Fields>;
  variance?: Maybe<Category_Insulation_Variance_Fields>;
};


/** aggregate fields of "Category_Insulation" */
export type Category_Insulation_Aggregate_FieldsCountArgs = {
  column?: Maybe<Category_Insulation_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Category_Insulation_Avg_Fields = {
  __typename?: 'Category_Insulation_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Category_Insulation". All fields are combined with a logical 'AND'. */
export type Category_Insulation_Bool_Exp = {
  ApprovedUseCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  ApprovedUseTableName?: Maybe<String_Mssql_Comparison_Exp>;
  CategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateLocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerName?: Maybe<String_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubcategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Category_Insulation_Bool_Exp>>;
  _not?: Maybe<Category_Insulation_Bool_Exp>;
  _or?: Maybe<Array<Category_Insulation_Bool_Exp>>;
};

/** upsert condition type for table "Category_Insulation" */
export type Category_Insulation_If_Matched = {
  match_columns?: Array<Category_Insulation_Insert_Match_Column>;
  update_columns?: Array<Category_Insulation_Update_Column>;
  where?: Maybe<Category_Insulation_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Category_Insulation" */
export type Category_Insulation_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Category_Insulation" */
export type Category_Insulation_Insert_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Category_Insulation" */
export enum Category_Insulation_Insert_Match_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate max on columns */
export type Category_Insulation_Max_Fields = {
  __typename?: 'Category_Insulation_max_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Category_Insulation_Min_Fields = {
  __typename?: 'Category_Insulation_min_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Category_Insulation" */
export type Category_Insulation_Mutation_Response = {
  __typename?: 'Category_Insulation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Insulation>;
};

/** Ordering options when selecting data from "Category_Insulation". */
export type Category_Insulation_Order_By = {
  ApprovedUseCode?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  ApprovedUseTableName?: Maybe<Order_By>;
  CategoryName?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ComponentStatusId?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ManufacturerName?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubcategoryName?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
};

/** select columns of table "Category_Insulation" */
export enum Category_Insulation_Select_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** input type for updating data in table "Category_Insulation" */
export type Category_Insulation_Set_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Category_Insulation_Stddev_Fields = {
  __typename?: 'Category_Insulation_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Category_Insulation_Stddev_Pop_Fields = {
  __typename?: 'Category_Insulation_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Category_Insulation_Stddev_Samp_Fields = {
  __typename?: 'Category_Insulation_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Category_Insulation_Sum_Fields = {
  __typename?: 'Category_Insulation_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "Category_Insulation" */
export enum Category_Insulation_Update_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate var_pop on columns */
export type Category_Insulation_Var_Pop_Fields = {
  __typename?: 'Category_Insulation_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Category_Insulation_Var_Samp_Fields = {
  __typename?: 'Category_Insulation_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Category_Insulation_Variance_Fields = {
  __typename?: 'Category_Insulation_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Category_Other" */
export type Category_Other = {
  __typename?: 'Category_Other';
  ApprovedUseCode: Scalars['CodeTableValue'];
  ApprovedUseId: Scalars['Int'];
  ApprovedUseTableName: Scalars['String'];
  CategoryName: Scalars['String'];
  ComponentId: Scalars['Int'];
  ComponentStatusId: Scalars['Int'];
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified: Scalars['datetime'];
  LockStatusId: Scalars['Int'];
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  ManufacturerName: Scalars['String'];
  ModifiedByUserAccountId: Scalars['Int'];
  StatusId: Scalars['Int'];
  SubcategoryName: Scalars['String'];
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Category_Other" */
export type Category_Other_Aggregate = {
  __typename?: 'Category_Other_aggregate';
  aggregate?: Maybe<Category_Other_Aggregate_Fields>;
  nodes: Array<Category_Other>;
};

/** aggregate fields of "Category_Other" */
export type Category_Other_Aggregate_Fields = {
  __typename?: 'Category_Other_aggregate_fields';
  avg?: Maybe<Category_Other_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Category_Other_Max_Fields>;
  min?: Maybe<Category_Other_Min_Fields>;
  stddev?: Maybe<Category_Other_Stddev_Fields>;
  stddev_pop?: Maybe<Category_Other_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Category_Other_Stddev_Samp_Fields>;
  sum?: Maybe<Category_Other_Sum_Fields>;
  var_pop?: Maybe<Category_Other_Var_Pop_Fields>;
  var_samp?: Maybe<Category_Other_Var_Samp_Fields>;
  variance?: Maybe<Category_Other_Variance_Fields>;
};


/** aggregate fields of "Category_Other" */
export type Category_Other_Aggregate_FieldsCountArgs = {
  column?: Maybe<Category_Other_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Category_Other_Avg_Fields = {
  __typename?: 'Category_Other_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Category_Other". All fields are combined with a logical 'AND'. */
export type Category_Other_Bool_Exp = {
  ApprovedUseCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  ApprovedUseTableName?: Maybe<String_Mssql_Comparison_Exp>;
  CategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateLocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerName?: Maybe<String_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubcategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Category_Other_Bool_Exp>>;
  _not?: Maybe<Category_Other_Bool_Exp>;
  _or?: Maybe<Array<Category_Other_Bool_Exp>>;
};

/** upsert condition type for table "Category_Other" */
export type Category_Other_If_Matched = {
  match_columns?: Array<Category_Other_Insert_Match_Column>;
  update_columns?: Array<Category_Other_Update_Column>;
  where?: Maybe<Category_Other_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Category_Other" */
export type Category_Other_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Category_Other" */
export type Category_Other_Insert_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Category_Other" */
export enum Category_Other_Insert_Match_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate max on columns */
export type Category_Other_Max_Fields = {
  __typename?: 'Category_Other_max_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Category_Other_Min_Fields = {
  __typename?: 'Category_Other_min_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Category_Other" */
export type Category_Other_Mutation_Response = {
  __typename?: 'Category_Other_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Other>;
};

/** Ordering options when selecting data from "Category_Other". */
export type Category_Other_Order_By = {
  ApprovedUseCode?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  ApprovedUseTableName?: Maybe<Order_By>;
  CategoryName?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ComponentStatusId?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ManufacturerName?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubcategoryName?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
};

/** select columns of table "Category_Other" */
export enum Category_Other_Select_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** input type for updating data in table "Category_Other" */
export type Category_Other_Set_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Category_Other_Stddev_Fields = {
  __typename?: 'Category_Other_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Category_Other_Stddev_Pop_Fields = {
  __typename?: 'Category_Other_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Category_Other_Stddev_Samp_Fields = {
  __typename?: 'Category_Other_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Category_Other_Sum_Fields = {
  __typename?: 'Category_Other_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "Category_Other" */
export enum Category_Other_Update_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate var_pop on columns */
export type Category_Other_Var_Pop_Fields = {
  __typename?: 'Category_Other_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Category_Other_Var_Samp_Fields = {
  __typename?: 'Category_Other_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Category_Other_Variance_Fields = {
  __typename?: 'Category_Other_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Category_Seaming" */
export type Category_Seaming = {
  __typename?: 'Category_Seaming';
  ApprovedUseCode: Scalars['CodeTableValue'];
  ApprovedUseId: Scalars['Int'];
  ApprovedUseTableName: Scalars['String'];
  CategoryName: Scalars['String'];
  ComponentId: Scalars['Int'];
  ComponentStatusId: Scalars['Int'];
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified: Scalars['datetime'];
  LockStatusId: Scalars['Int'];
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  ManufacturerName: Scalars['String'];
  ModifiedByUserAccountId: Scalars['Int'];
  StatusId: Scalars['Int'];
  SubcategoryName: Scalars['String'];
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Category_Seaming" */
export type Category_Seaming_Aggregate = {
  __typename?: 'Category_Seaming_aggregate';
  aggregate?: Maybe<Category_Seaming_Aggregate_Fields>;
  nodes: Array<Category_Seaming>;
};

/** aggregate fields of "Category_Seaming" */
export type Category_Seaming_Aggregate_Fields = {
  __typename?: 'Category_Seaming_aggregate_fields';
  avg?: Maybe<Category_Seaming_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Category_Seaming_Max_Fields>;
  min?: Maybe<Category_Seaming_Min_Fields>;
  stddev?: Maybe<Category_Seaming_Stddev_Fields>;
  stddev_pop?: Maybe<Category_Seaming_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Category_Seaming_Stddev_Samp_Fields>;
  sum?: Maybe<Category_Seaming_Sum_Fields>;
  var_pop?: Maybe<Category_Seaming_Var_Pop_Fields>;
  var_samp?: Maybe<Category_Seaming_Var_Samp_Fields>;
  variance?: Maybe<Category_Seaming_Variance_Fields>;
};


/** aggregate fields of "Category_Seaming" */
export type Category_Seaming_Aggregate_FieldsCountArgs = {
  column?: Maybe<Category_Seaming_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Category_Seaming_Avg_Fields = {
  __typename?: 'Category_Seaming_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Category_Seaming". All fields are combined with a logical 'AND'. */
export type Category_Seaming_Bool_Exp = {
  ApprovedUseCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  ApprovedUseTableName?: Maybe<String_Mssql_Comparison_Exp>;
  CategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateLocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerName?: Maybe<String_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubcategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Category_Seaming_Bool_Exp>>;
  _not?: Maybe<Category_Seaming_Bool_Exp>;
  _or?: Maybe<Array<Category_Seaming_Bool_Exp>>;
};

/** upsert condition type for table "Category_Seaming" */
export type Category_Seaming_If_Matched = {
  match_columns?: Array<Category_Seaming_Insert_Match_Column>;
  update_columns?: Array<Category_Seaming_Update_Column>;
  where?: Maybe<Category_Seaming_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Category_Seaming" */
export type Category_Seaming_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Category_Seaming" */
export type Category_Seaming_Insert_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Category_Seaming" */
export enum Category_Seaming_Insert_Match_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate max on columns */
export type Category_Seaming_Max_Fields = {
  __typename?: 'Category_Seaming_max_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Category_Seaming_Min_Fields = {
  __typename?: 'Category_Seaming_min_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Category_Seaming" */
export type Category_Seaming_Mutation_Response = {
  __typename?: 'Category_Seaming_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Seaming>;
};

/** Ordering options when selecting data from "Category_Seaming". */
export type Category_Seaming_Order_By = {
  ApprovedUseCode?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  ApprovedUseTableName?: Maybe<Order_By>;
  CategoryName?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ComponentStatusId?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ManufacturerName?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubcategoryName?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
};

/** select columns of table "Category_Seaming" */
export enum Category_Seaming_Select_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** input type for updating data in table "Category_Seaming" */
export type Category_Seaming_Set_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Category_Seaming_Stddev_Fields = {
  __typename?: 'Category_Seaming_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Category_Seaming_Stddev_Pop_Fields = {
  __typename?: 'Category_Seaming_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Category_Seaming_Stddev_Samp_Fields = {
  __typename?: 'Category_Seaming_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Category_Seaming_Sum_Fields = {
  __typename?: 'Category_Seaming_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "Category_Seaming" */
export enum Category_Seaming_Update_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate var_pop on columns */
export type Category_Seaming_Var_Pop_Fields = {
  __typename?: 'Category_Seaming_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Category_Seaming_Var_Samp_Fields = {
  __typename?: 'Category_Seaming_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Category_Seaming_Variance_Fields = {
  __typename?: 'Category_Seaming_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Category_Securement" */
export type Category_Securement = {
  __typename?: 'Category_Securement';
  ApprovedUseCode: Scalars['CodeTableValue'];
  ApprovedUseId: Scalars['Int'];
  ApprovedUseTableName: Scalars['String'];
  CategoryName: Scalars['String'];
  ComponentId: Scalars['Int'];
  ComponentStatusId: Scalars['Int'];
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified: Scalars['datetime'];
  LockStatusId: Scalars['Int'];
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  ManufacturerName: Scalars['String'];
  ModifiedByUserAccountId: Scalars['Int'];
  StatusId: Scalars['Int'];
  SubcategoryName: Scalars['String'];
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Category_Securement" */
export type Category_Securement_Aggregate = {
  __typename?: 'Category_Securement_aggregate';
  aggregate?: Maybe<Category_Securement_Aggregate_Fields>;
  nodes: Array<Category_Securement>;
};

/** aggregate fields of "Category_Securement" */
export type Category_Securement_Aggregate_Fields = {
  __typename?: 'Category_Securement_aggregate_fields';
  avg?: Maybe<Category_Securement_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Category_Securement_Max_Fields>;
  min?: Maybe<Category_Securement_Min_Fields>;
  stddev?: Maybe<Category_Securement_Stddev_Fields>;
  stddev_pop?: Maybe<Category_Securement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Category_Securement_Stddev_Samp_Fields>;
  sum?: Maybe<Category_Securement_Sum_Fields>;
  var_pop?: Maybe<Category_Securement_Var_Pop_Fields>;
  var_samp?: Maybe<Category_Securement_Var_Samp_Fields>;
  variance?: Maybe<Category_Securement_Variance_Fields>;
};


/** aggregate fields of "Category_Securement" */
export type Category_Securement_Aggregate_FieldsCountArgs = {
  column?: Maybe<Category_Securement_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Category_Securement_Avg_Fields = {
  __typename?: 'Category_Securement_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Category_Securement". All fields are combined with a logical 'AND'. */
export type Category_Securement_Bool_Exp = {
  ApprovedUseCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  ApprovedUseTableName?: Maybe<String_Mssql_Comparison_Exp>;
  CategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateLocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerName?: Maybe<String_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubcategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Category_Securement_Bool_Exp>>;
  _not?: Maybe<Category_Securement_Bool_Exp>;
  _or?: Maybe<Array<Category_Securement_Bool_Exp>>;
};

/** upsert condition type for table "Category_Securement" */
export type Category_Securement_If_Matched = {
  match_columns?: Array<Category_Securement_Insert_Match_Column>;
  update_columns?: Array<Category_Securement_Update_Column>;
  where?: Maybe<Category_Securement_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Category_Securement" */
export type Category_Securement_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Category_Securement" */
export type Category_Securement_Insert_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Category_Securement" */
export enum Category_Securement_Insert_Match_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate max on columns */
export type Category_Securement_Max_Fields = {
  __typename?: 'Category_Securement_max_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Category_Securement_Min_Fields = {
  __typename?: 'Category_Securement_min_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Category_Securement" */
export type Category_Securement_Mutation_Response = {
  __typename?: 'Category_Securement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Securement>;
};

/** Ordering options when selecting data from "Category_Securement". */
export type Category_Securement_Order_By = {
  ApprovedUseCode?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  ApprovedUseTableName?: Maybe<Order_By>;
  CategoryName?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ComponentStatusId?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ManufacturerName?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubcategoryName?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
};

/** select columns of table "Category_Securement" */
export enum Category_Securement_Select_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** input type for updating data in table "Category_Securement" */
export type Category_Securement_Set_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Category_Securement_Stddev_Fields = {
  __typename?: 'Category_Securement_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Category_Securement_Stddev_Pop_Fields = {
  __typename?: 'Category_Securement_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Category_Securement_Stddev_Samp_Fields = {
  __typename?: 'Category_Securement_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Category_Securement_Sum_Fields = {
  __typename?: 'Category_Securement_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "Category_Securement" */
export enum Category_Securement_Update_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate var_pop on columns */
export type Category_Securement_Var_Pop_Fields = {
  __typename?: 'Category_Securement_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Category_Securement_Var_Samp_Fields = {
  __typename?: 'Category_Securement_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Category_Securement_Variance_Fields = {
  __typename?: 'Category_Securement_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Category_Sheet" */
export type Category_Sheet = {
  __typename?: 'Category_Sheet';
  ApprovedUseCode: Scalars['CodeTableValue'];
  ApprovedUseId: Scalars['Int'];
  ApprovedUseTableName: Scalars['String'];
  CategoryName: Scalars['String'];
  ComponentId: Scalars['Int'];
  ComponentStatusId: Scalars['Int'];
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified: Scalars['datetime'];
  LockStatusId: Scalars['Int'];
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  ManufacturerName: Scalars['String'];
  ModifiedByUserAccountId: Scalars['Int'];
  StatusId: Scalars['Int'];
  SubcategoryName: Scalars['String'];
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Category_Sheet" */
export type Category_Sheet_Aggregate = {
  __typename?: 'Category_Sheet_aggregate';
  aggregate?: Maybe<Category_Sheet_Aggregate_Fields>;
  nodes: Array<Category_Sheet>;
};

/** aggregate fields of "Category_Sheet" */
export type Category_Sheet_Aggregate_Fields = {
  __typename?: 'Category_Sheet_aggregate_fields';
  avg?: Maybe<Category_Sheet_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Category_Sheet_Max_Fields>;
  min?: Maybe<Category_Sheet_Min_Fields>;
  stddev?: Maybe<Category_Sheet_Stddev_Fields>;
  stddev_pop?: Maybe<Category_Sheet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Category_Sheet_Stddev_Samp_Fields>;
  sum?: Maybe<Category_Sheet_Sum_Fields>;
  var_pop?: Maybe<Category_Sheet_Var_Pop_Fields>;
  var_samp?: Maybe<Category_Sheet_Var_Samp_Fields>;
  variance?: Maybe<Category_Sheet_Variance_Fields>;
};


/** aggregate fields of "Category_Sheet" */
export type Category_Sheet_Aggregate_FieldsCountArgs = {
  column?: Maybe<Category_Sheet_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Category_Sheet_Avg_Fields = {
  __typename?: 'Category_Sheet_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Category_Sheet". All fields are combined with a logical 'AND'. */
export type Category_Sheet_Bool_Exp = {
  ApprovedUseCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  ApprovedUseTableName?: Maybe<String_Mssql_Comparison_Exp>;
  CategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateLocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerName?: Maybe<String_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubcategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Category_Sheet_Bool_Exp>>;
  _not?: Maybe<Category_Sheet_Bool_Exp>;
  _or?: Maybe<Array<Category_Sheet_Bool_Exp>>;
};

/** upsert condition type for table "Category_Sheet" */
export type Category_Sheet_If_Matched = {
  match_columns?: Array<Category_Sheet_Insert_Match_Column>;
  update_columns?: Array<Category_Sheet_Update_Column>;
  where?: Maybe<Category_Sheet_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Category_Sheet" */
export type Category_Sheet_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Category_Sheet" */
export type Category_Sheet_Insert_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Category_Sheet" */
export enum Category_Sheet_Insert_Match_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate max on columns */
export type Category_Sheet_Max_Fields = {
  __typename?: 'Category_Sheet_max_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Category_Sheet_Min_Fields = {
  __typename?: 'Category_Sheet_min_fields';
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Category_Sheet" */
export type Category_Sheet_Mutation_Response = {
  __typename?: 'Category_Sheet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Sheet>;
};

/** Ordering options when selecting data from "Category_Sheet". */
export type Category_Sheet_Order_By = {
  ApprovedUseCode?: Maybe<Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  ApprovedUseTableName?: Maybe<Order_By>;
  CategoryName?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ComponentStatusId?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ManufacturerName?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubcategoryName?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
};

/** select columns of table "Category_Sheet" */
export enum Category_Sheet_Select_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** input type for updating data in table "Category_Sheet" */
export type Category_Sheet_Set_Input = {
  ApprovedUseCode?: Maybe<Scalars['CodeTableValue']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ApprovedUseTableName?: Maybe<Scalars['String']>;
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Category_Sheet_Stddev_Fields = {
  __typename?: 'Category_Sheet_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Category_Sheet_Stddev_Pop_Fields = {
  __typename?: 'Category_Sheet_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Category_Sheet_Stddev_Samp_Fields = {
  __typename?: 'Category_Sheet_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Category_Sheet_Sum_Fields = {
  __typename?: 'Category_Sheet_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentStatusId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "Category_Sheet" */
export enum Category_Sheet_Update_Column {
  /** column name */
  ApprovedUseCode = 'ApprovedUseCode',
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ApprovedUseTableName = 'ApprovedUseTableName',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate var_pop on columns */
export type Category_Sheet_Var_Pop_Fields = {
  __typename?: 'Category_Sheet_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Category_Sheet_Var_Samp_Fields = {
  __typename?: 'Category_Sheet_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Category_Sheet_Variance_Fields = {
  __typename?: 'Category_Sheet_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentStatusId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Category" */
export type Category_Aggregate = {
  __typename?: 'Category_aggregate';
  aggregate?: Maybe<Category_Aggregate_Fields>;
  nodes: Array<Category>;
};

/** aggregate fields of "Category" */
export type Category_Aggregate_Fields = {
  __typename?: 'Category_aggregate_fields';
  avg?: Maybe<Category_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Category_Max_Fields>;
  min?: Maybe<Category_Min_Fields>;
  stddev?: Maybe<Category_Stddev_Fields>;
  stddev_pop?: Maybe<Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Category_Stddev_Samp_Fields>;
  sum?: Maybe<Category_Sum_Fields>;
  var_pop?: Maybe<Category_Var_Pop_Fields>;
  var_samp?: Maybe<Category_Var_Samp_Fields>;
  variance?: Maybe<Category_Variance_Fields>;
};


/** aggregate fields of "Category" */
export type Category_Aggregate_FieldsCountArgs = {
  column?: Maybe<Category_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Category_Avg_Fields = {
  __typename?: 'Category_avg_fields';
  CategoryID?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Category". All fields are combined with a logical 'AND'. */
export type Category_Bool_Exp = {
  CategoryID?: Maybe<Int_Mssql_Comparison_Exp>;
  CategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Category_Bool_Exp>>;
  _not?: Maybe<Category_Bool_Exp>;
  _or?: Maybe<Array<Category_Bool_Exp>>;
};

/** upsert condition type for table "Category" */
export type Category_If_Matched = {
  match_columns?: Array<Category_Insert_Match_Column>;
  update_columns?: Array<Category_Update_Column>;
  where?: Maybe<Category_Bool_Exp>;
};

/** input type for inserting data into table "Category" */
export type Category_Insert_Input = {
  CategoryName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Category" */
export enum Category_Insert_Match_Column {
  /** column name */
  CategoryId = 'CategoryID',
  /** column name */
  CategoryName = 'CategoryName'
}

/** aggregate max on columns */
export type Category_Max_Fields = {
  __typename?: 'Category_max_fields';
  CategoryID?: Maybe<Scalars['Int']>;
  CategoryName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Category_Min_Fields = {
  __typename?: 'Category_min_fields';
  CategoryID?: Maybe<Scalars['Int']>;
  CategoryName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Category" */
export type Category_Mutation_Response = {
  __typename?: 'Category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category>;
};

/** Ordering options when selecting data from "Category". */
export type Category_Order_By = {
  CategoryID?: Maybe<Order_By>;
  CategoryName?: Maybe<Order_By>;
};

/** primary key columns input for table: Category */
export type Category_Pk_Columns_Input = {
  CategoryID: Scalars['Int'];
};

/** select columns of table "Category" */
export enum Category_Select_Column {
  /** column name */
  CategoryId = 'CategoryID',
  /** column name */
  CategoryName = 'CategoryName'
}

/** input type for updating data in table "Category" */
export type Category_Set_Input = {
  CategoryName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Category_Stddev_Fields = {
  __typename?: 'Category_stddev_fields';
  CategoryID?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Category_Stddev_Pop_Fields = {
  __typename?: 'Category_stddev_pop_fields';
  CategoryID?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Category_Stddev_Samp_Fields = {
  __typename?: 'Category_stddev_samp_fields';
  CategoryID?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Category_Sum_Fields = {
  __typename?: 'Category_sum_fields';
  CategoryID?: Maybe<Scalars['Int']>;
};

/** update columns of table "Category" */
export enum Category_Update_Column {
  /** column name */
  CategoryName = 'CategoryName'
}

/** aggregate var_pop on columns */
export type Category_Var_Pop_Fields = {
  __typename?: 'Category_var_pop_fields';
  CategoryID?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Category_Var_Samp_Fields = {
  __typename?: 'Category_var_samp_fields';
  CategoryID?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Category_Variance_Fields = {
  __typename?: 'Category_variance_fields';
  CategoryID?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "CementitiousWoodFiberDeck" */
export type CementitiousWoodFiberDeck = {
  __typename?: 'CementitiousWoodFiberDeck';
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  SpanMax: Scalars['MeasurementValue'];
  ThicknessMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
};

/** columns and relationships of "CementitiousWoodFiberDeckUsage" */
export type CementitiousWoodFiberDeckUsage = {
  __typename?: 'CementitiousWoodFiberDeckUsage';
  SpanMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "CementitiousWoodFiberDeckUsage" */
export type CementitiousWoodFiberDeckUsage_Aggregate = {
  __typename?: 'CementitiousWoodFiberDeckUsage_aggregate';
  aggregate?: Maybe<CementitiousWoodFiberDeckUsage_Aggregate_Fields>;
  nodes: Array<CementitiousWoodFiberDeckUsage>;
};

/** aggregate fields of "CementitiousWoodFiberDeckUsage" */
export type CementitiousWoodFiberDeckUsage_Aggregate_Fields = {
  __typename?: 'CementitiousWoodFiberDeckUsage_aggregate_fields';
  avg?: Maybe<CementitiousWoodFiberDeckUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CementitiousWoodFiberDeckUsage_Max_Fields>;
  min?: Maybe<CementitiousWoodFiberDeckUsage_Min_Fields>;
  stddev?: Maybe<CementitiousWoodFiberDeckUsage_Stddev_Fields>;
  stddev_pop?: Maybe<CementitiousWoodFiberDeckUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CementitiousWoodFiberDeckUsage_Stddev_Samp_Fields>;
  sum?: Maybe<CementitiousWoodFiberDeckUsage_Sum_Fields>;
  var_pop?: Maybe<CementitiousWoodFiberDeckUsage_Var_Pop_Fields>;
  var_samp?: Maybe<CementitiousWoodFiberDeckUsage_Var_Samp_Fields>;
  variance?: Maybe<CementitiousWoodFiberDeckUsage_Variance_Fields>;
};


/** aggregate fields of "CementitiousWoodFiberDeckUsage" */
export type CementitiousWoodFiberDeckUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<CementitiousWoodFiberDeckUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CementitiousWoodFiberDeckUsage_Avg_Fields = {
  __typename?: 'CementitiousWoodFiberDeckUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CementitiousWoodFiberDeckUsage". All fields are combined with a logical 'AND'. */
export type CementitiousWoodFiberDeckUsage_Bool_Exp = {
  SpanMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CementitiousWoodFiberDeckUsage_Bool_Exp>>;
  _not?: Maybe<CementitiousWoodFiberDeckUsage_Bool_Exp>;
  _or?: Maybe<Array<CementitiousWoodFiberDeckUsage_Bool_Exp>>;
};

/** upsert condition type for table "CementitiousWoodFiberDeckUsage" */
export type CementitiousWoodFiberDeckUsage_If_Matched = {
  match_columns?: Array<CementitiousWoodFiberDeckUsage_Insert_Match_Column>;
  update_columns?: Array<CementitiousWoodFiberDeckUsage_Update_Column>;
  where?: Maybe<CementitiousWoodFiberDeckUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CementitiousWoodFiberDeckUsage" */
export type CementitiousWoodFiberDeckUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CementitiousWoodFiberDeckUsage" */
export type CementitiousWoodFiberDeckUsage_Insert_Input = {
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "CementitiousWoodFiberDeckUsage" */
export enum CementitiousWoodFiberDeckUsage_Insert_Match_Column {
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type CementitiousWoodFiberDeckUsage_Max_Fields = {
  __typename?: 'CementitiousWoodFiberDeckUsage_max_fields';
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CementitiousWoodFiberDeckUsage_Min_Fields = {
  __typename?: 'CementitiousWoodFiberDeckUsage_min_fields';
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "CementitiousWoodFiberDeckUsage" */
export type CementitiousWoodFiberDeckUsage_Mutation_Response = {
  __typename?: 'CementitiousWoodFiberDeckUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CementitiousWoodFiberDeckUsage>;
};

/** Ordering options when selecting data from "CementitiousWoodFiberDeckUsage". */
export type CementitiousWoodFiberDeckUsage_Order_By = {
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: CementitiousWoodFiberDeckUsage */
export type CementitiousWoodFiberDeckUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "CementitiousWoodFiberDeckUsage" */
export enum CementitiousWoodFiberDeckUsage_Select_Column {
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "CementitiousWoodFiberDeckUsage" */
export type CementitiousWoodFiberDeckUsage_Set_Input = {
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CementitiousWoodFiberDeckUsage_Stddev_Fields = {
  __typename?: 'CementitiousWoodFiberDeckUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CementitiousWoodFiberDeckUsage_Stddev_Pop_Fields = {
  __typename?: 'CementitiousWoodFiberDeckUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CementitiousWoodFiberDeckUsage_Stddev_Samp_Fields = {
  __typename?: 'CementitiousWoodFiberDeckUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CementitiousWoodFiberDeckUsage_Sum_Fields = {
  __typename?: 'CementitiousWoodFiberDeckUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "CementitiousWoodFiberDeckUsage" */
export enum CementitiousWoodFiberDeckUsage_Update_Column {
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type CementitiousWoodFiberDeckUsage_Var_Pop_Fields = {
  __typename?: 'CementitiousWoodFiberDeckUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CementitiousWoodFiberDeckUsage_Var_Samp_Fields = {
  __typename?: 'CementitiousWoodFiberDeckUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CementitiousWoodFiberDeckUsage_Variance_Fields = {
  __typename?: 'CementitiousWoodFiberDeckUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "CementitiousWoodFiberDeck" */
export type CementitiousWoodFiberDeck_Aggregate = {
  __typename?: 'CementitiousWoodFiberDeck_aggregate';
  aggregate?: Maybe<CementitiousWoodFiberDeck_Aggregate_Fields>;
  nodes: Array<CementitiousWoodFiberDeck>;
};

/** aggregate fields of "CementitiousWoodFiberDeck" */
export type CementitiousWoodFiberDeck_Aggregate_Fields = {
  __typename?: 'CementitiousWoodFiberDeck_aggregate_fields';
  avg?: Maybe<CementitiousWoodFiberDeck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CementitiousWoodFiberDeck_Max_Fields>;
  min?: Maybe<CementitiousWoodFiberDeck_Min_Fields>;
  stddev?: Maybe<CementitiousWoodFiberDeck_Stddev_Fields>;
  stddev_pop?: Maybe<CementitiousWoodFiberDeck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CementitiousWoodFiberDeck_Stddev_Samp_Fields>;
  sum?: Maybe<CementitiousWoodFiberDeck_Sum_Fields>;
  var_pop?: Maybe<CementitiousWoodFiberDeck_Var_Pop_Fields>;
  var_samp?: Maybe<CementitiousWoodFiberDeck_Var_Samp_Fields>;
  variance?: Maybe<CementitiousWoodFiberDeck_Variance_Fields>;
};


/** aggregate fields of "CementitiousWoodFiberDeck" */
export type CementitiousWoodFiberDeck_Aggregate_FieldsCountArgs = {
  column?: Maybe<CementitiousWoodFiberDeck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CementitiousWoodFiberDeck_Avg_Fields = {
  __typename?: 'CementitiousWoodFiberDeck_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CementitiousWoodFiberDeck". All fields are combined with a logical 'AND'. */
export type CementitiousWoodFiberDeck_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  SpanMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CementitiousWoodFiberDeck_Bool_Exp>>;
  _not?: Maybe<CementitiousWoodFiberDeck_Bool_Exp>;
  _or?: Maybe<Array<CementitiousWoodFiberDeck_Bool_Exp>>;
};

/** upsert condition type for table "CementitiousWoodFiberDeck" */
export type CementitiousWoodFiberDeck_If_Matched = {
  match_columns?: Array<CementitiousWoodFiberDeck_Insert_Match_Column>;
  update_columns?: Array<CementitiousWoodFiberDeck_Update_Column>;
  where?: Maybe<CementitiousWoodFiberDeck_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CementitiousWoodFiberDeck" */
export type CementitiousWoodFiberDeck_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CementitiousWoodFiberDeck" */
export type CementitiousWoodFiberDeck_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "CementitiousWoodFiberDeck" */
export enum CementitiousWoodFiberDeck_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate max on columns */
export type CementitiousWoodFiberDeck_Max_Fields = {
  __typename?: 'CementitiousWoodFiberDeck_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate min on columns */
export type CementitiousWoodFiberDeck_Min_Fields = {
  __typename?: 'CementitiousWoodFiberDeck_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** response of any mutation on the table "CementitiousWoodFiberDeck" */
export type CementitiousWoodFiberDeck_Mutation_Response = {
  __typename?: 'CementitiousWoodFiberDeck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CementitiousWoodFiberDeck>;
};

/** Ordering options when selecting data from "CementitiousWoodFiberDeck". */
export type CementitiousWoodFiberDeck_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** primary key columns input for table: CementitiousWoodFiberDeck */
export type CementitiousWoodFiberDeck_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "CementitiousWoodFiberDeck" */
export enum CementitiousWoodFiberDeck_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** input type for updating data in table "CementitiousWoodFiberDeck" */
export type CementitiousWoodFiberDeck_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type CementitiousWoodFiberDeck_Stddev_Fields = {
  __typename?: 'CementitiousWoodFiberDeck_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CementitiousWoodFiberDeck_Stddev_Pop_Fields = {
  __typename?: 'CementitiousWoodFiberDeck_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CementitiousWoodFiberDeck_Stddev_Samp_Fields = {
  __typename?: 'CementitiousWoodFiberDeck_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CementitiousWoodFiberDeck_Sum_Fields = {
  __typename?: 'CementitiousWoodFiberDeck_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
};

/** update columns of table "CementitiousWoodFiberDeck" */
export enum CementitiousWoodFiberDeck_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate var_pop on columns */
export type CementitiousWoodFiberDeck_Var_Pop_Fields = {
  __typename?: 'CementitiousWoodFiberDeck_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CementitiousWoodFiberDeck_Var_Samp_Fields = {
  __typename?: 'CementitiousWoodFiberDeck_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CementitiousWoodFiberDeck_Variance_Fields = {
  __typename?: 'CementitiousWoodFiberDeck_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Clip" */
export type Clip = {
  __typename?: 'Clip';
  /** An object relationship */
  C_SecurementMaterial: C_SecurementMaterial;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  SecurementMaterialId: Scalars['Int'];
};

/** columns and relationships of "ClipUsage" */
export type ClipUsage = {
  __typename?: 'ClipUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "ClipUsage" */
export type ClipUsage_Aggregate = {
  __typename?: 'ClipUsage_aggregate';
  aggregate?: Maybe<ClipUsage_Aggregate_Fields>;
  nodes: Array<ClipUsage>;
};

/** aggregate fields of "ClipUsage" */
export type ClipUsage_Aggregate_Fields = {
  __typename?: 'ClipUsage_aggregate_fields';
  avg?: Maybe<ClipUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ClipUsage_Max_Fields>;
  min?: Maybe<ClipUsage_Min_Fields>;
  stddev?: Maybe<ClipUsage_Stddev_Fields>;
  stddev_pop?: Maybe<ClipUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ClipUsage_Stddev_Samp_Fields>;
  sum?: Maybe<ClipUsage_Sum_Fields>;
  var_pop?: Maybe<ClipUsage_Var_Pop_Fields>;
  var_samp?: Maybe<ClipUsage_Var_Samp_Fields>;
  variance?: Maybe<ClipUsage_Variance_Fields>;
};


/** aggregate fields of "ClipUsage" */
export type ClipUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<ClipUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ClipUsage_Avg_Fields = {
  __typename?: 'ClipUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ClipUsage". All fields are combined with a logical 'AND'. */
export type ClipUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ClipUsage_Bool_Exp>>;
  _not?: Maybe<ClipUsage_Bool_Exp>;
  _or?: Maybe<Array<ClipUsage_Bool_Exp>>;
};

/** upsert condition type for table "ClipUsage" */
export type ClipUsage_If_Matched = {
  match_columns?: Array<ClipUsage_Insert_Match_Column>;
  update_columns?: Array<ClipUsage_Update_Column>;
  where?: Maybe<ClipUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ClipUsage" */
export type ClipUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ClipUsage" */
export type ClipUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ClipUsage" */
export enum ClipUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type ClipUsage_Max_Fields = {
  __typename?: 'ClipUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ClipUsage_Min_Fields = {
  __typename?: 'ClipUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "ClipUsage" */
export type ClipUsage_Mutation_Response = {
  __typename?: 'ClipUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClipUsage>;
};

/** Ordering options when selecting data from "ClipUsage". */
export type ClipUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: ClipUsage */
export type ClipUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "ClipUsage" */
export enum ClipUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "ClipUsage" */
export type ClipUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ClipUsage_Stddev_Fields = {
  __typename?: 'ClipUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ClipUsage_Stddev_Pop_Fields = {
  __typename?: 'ClipUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ClipUsage_Stddev_Samp_Fields = {
  __typename?: 'ClipUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ClipUsage_Sum_Fields = {
  __typename?: 'ClipUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "ClipUsage" */
export enum ClipUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type ClipUsage_Var_Pop_Fields = {
  __typename?: 'ClipUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ClipUsage_Var_Samp_Fields = {
  __typename?: 'ClipUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ClipUsage_Variance_Fields = {
  __typename?: 'ClipUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Clip" */
export type Clip_Aggregate = {
  __typename?: 'Clip_aggregate';
  aggregate?: Maybe<Clip_Aggregate_Fields>;
  nodes: Array<Clip>;
};

/** aggregate fields of "Clip" */
export type Clip_Aggregate_Fields = {
  __typename?: 'Clip_aggregate_fields';
  avg?: Maybe<Clip_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Clip_Max_Fields>;
  min?: Maybe<Clip_Min_Fields>;
  stddev?: Maybe<Clip_Stddev_Fields>;
  stddev_pop?: Maybe<Clip_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Clip_Stddev_Samp_Fields>;
  sum?: Maybe<Clip_Sum_Fields>;
  var_pop?: Maybe<Clip_Var_Pop_Fields>;
  var_samp?: Maybe<Clip_Var_Samp_Fields>;
  variance?: Maybe<Clip_Variance_Fields>;
};


/** aggregate fields of "Clip" */
export type Clip_Aggregate_FieldsCountArgs = {
  column?: Maybe<Clip_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Clip" */
export type Clip_Aggregate_Order_By = {
  avg?: Maybe<Clip_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Clip_Max_Order_By>;
  min?: Maybe<Clip_Min_Order_By>;
  stddev?: Maybe<Clip_Stddev_Order_By>;
  stddev_pop?: Maybe<Clip_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Clip_Stddev_Samp_Order_By>;
  sum?: Maybe<Clip_Sum_Order_By>;
  var_pop?: Maybe<Clip_Var_Pop_Order_By>;
  var_samp?: Maybe<Clip_Var_Samp_Order_By>;
  variance?: Maybe<Clip_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Clip_Avg_Fields = {
  __typename?: 'Clip_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Clip" */
export type Clip_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Clip". All fields are combined with a logical 'AND'. */
export type Clip_Bool_Exp = {
  C_SecurementMaterial?: Maybe<C_SecurementMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  SecurementMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Clip_Bool_Exp>>;
  _not?: Maybe<Clip_Bool_Exp>;
  _or?: Maybe<Array<Clip_Bool_Exp>>;
};

/** upsert condition type for table "Clip" */
export type Clip_If_Matched = {
  match_columns?: Array<Clip_Insert_Match_Column>;
  update_columns?: Array<Clip_Update_Column>;
  where?: Maybe<Clip_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Clip" */
export type Clip_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Clip" */
export type Clip_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "Clip" */
export enum Clip_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SecurementMaterialId = 'SecurementMaterialId'
}

/** aggregate max on columns */
export type Clip_Max_Fields = {
  __typename?: 'Clip_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Clip" */
export type Clip_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Clip_Min_Fields = {
  __typename?: 'Clip_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Clip" */
export type Clip_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Clip" */
export type Clip_Mutation_Response = {
  __typename?: 'Clip_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clip>;
};

/** Ordering options when selecting data from "Clip". */
export type Clip_Order_By = {
  C_SecurementMaterial?: Maybe<C_SecurementMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** primary key columns input for table: Clip */
export type Clip_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "Clip" */
export enum Clip_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SecurementMaterialId = 'SecurementMaterialId'
}

/** input type for updating data in table "Clip" */
export type Clip_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Clip_Stddev_Fields = {
  __typename?: 'Clip_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Clip" */
export type Clip_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Clip_Stddev_Pop_Fields = {
  __typename?: 'Clip_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Clip" */
export type Clip_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Clip_Stddev_Samp_Fields = {
  __typename?: 'Clip_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Clip" */
export type Clip_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Clip_Sum_Fields = {
  __typename?: 'Clip_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Clip" */
export type Clip_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** update columns of table "Clip" */
export enum Clip_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SecurementMaterialId = 'SecurementMaterialId'
}

/** aggregate var_pop on columns */
export type Clip_Var_Pop_Fields = {
  __typename?: 'Clip_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Clip" */
export type Clip_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Clip_Var_Samp_Fields = {
  __typename?: 'Clip_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Clip" */
export type Clip_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Clip_Variance_Fields = {
  __typename?: 'Clip_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Clip" */
export type Clip_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "Coat" */
export type Coat = {
  __typename?: 'Coat';
  /** An object relationship */
  C_CoverColor: C_CoverColor;
  /** An object relationship */
  C_LiquidApplication: C_LiquidApplication;
  /** An object relationship */
  C_LiquidAppliedMaterial: C_LiquidAppliedMaterial;
  ComponentId: Scalars['Int'];
  CoverColorId: Scalars['Int'];
  LiquidApplicationId: Scalars['Int'];
  LiquidAppliedMaterialId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
};

/** columns and relationships of "CoatUsage" */
export type CoatUsage = {
  __typename?: 'CoatUsage';
  MinAppRatePerCoatDry: Scalars['MeasurementValue'];
  MinAppRatePerCoatWet: Scalars['MeasurementValue'];
  NumCoats: Scalars['Int'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "CoatUsage" */
export type CoatUsage_Aggregate = {
  __typename?: 'CoatUsage_aggregate';
  aggregate?: Maybe<CoatUsage_Aggregate_Fields>;
  nodes: Array<CoatUsage>;
};

/** aggregate fields of "CoatUsage" */
export type CoatUsage_Aggregate_Fields = {
  __typename?: 'CoatUsage_aggregate_fields';
  avg?: Maybe<CoatUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CoatUsage_Max_Fields>;
  min?: Maybe<CoatUsage_Min_Fields>;
  stddev?: Maybe<CoatUsage_Stddev_Fields>;
  stddev_pop?: Maybe<CoatUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CoatUsage_Stddev_Samp_Fields>;
  sum?: Maybe<CoatUsage_Sum_Fields>;
  var_pop?: Maybe<CoatUsage_Var_Pop_Fields>;
  var_samp?: Maybe<CoatUsage_Var_Samp_Fields>;
  variance?: Maybe<CoatUsage_Variance_Fields>;
};


/** aggregate fields of "CoatUsage" */
export type CoatUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<CoatUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CoatUsage_Avg_Fields = {
  __typename?: 'CoatUsage_avg_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CoatUsage". All fields are combined with a logical 'AND'. */
export type CoatUsage_Bool_Exp = {
  MinAppRatePerCoatDry?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  MinAppRatePerCoatWet?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  NumCoats?: Maybe<Int_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CoatUsage_Bool_Exp>>;
  _not?: Maybe<CoatUsage_Bool_Exp>;
  _or?: Maybe<Array<CoatUsage_Bool_Exp>>;
};

/** upsert condition type for table "CoatUsage" */
export type CoatUsage_If_Matched = {
  match_columns?: Array<CoatUsage_Insert_Match_Column>;
  update_columns?: Array<CoatUsage_Update_Column>;
  where?: Maybe<CoatUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CoatUsage" */
export type CoatUsage_Inc_Input = {
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CoatUsage" */
export type CoatUsage_Insert_Input = {
  MinAppRatePerCoatDry?: Maybe<Scalars['MeasurementValue']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "CoatUsage" */
export enum CoatUsage_Insert_Match_Column {
  /** column name */
  MinAppRatePerCoatDry = 'MinAppRatePerCoatDry',
  /** column name */
  MinAppRatePerCoatWet = 'MinAppRatePerCoatWet',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type CoatUsage_Max_Fields = {
  __typename?: 'CoatUsage_max_fields';
  MinAppRatePerCoatDry?: Maybe<Scalars['MeasurementValue']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CoatUsage_Min_Fields = {
  __typename?: 'CoatUsage_min_fields';
  MinAppRatePerCoatDry?: Maybe<Scalars['MeasurementValue']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "CoatUsage" */
export type CoatUsage_Mutation_Response = {
  __typename?: 'CoatUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoatUsage>;
};

/** Ordering options when selecting data from "CoatUsage". */
export type CoatUsage_Order_By = {
  MinAppRatePerCoatDry?: Maybe<Order_By>;
  MinAppRatePerCoatWet?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: CoatUsage */
export type CoatUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "CoatUsage" */
export enum CoatUsage_Select_Column {
  /** column name */
  MinAppRatePerCoatDry = 'MinAppRatePerCoatDry',
  /** column name */
  MinAppRatePerCoatWet = 'MinAppRatePerCoatWet',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "CoatUsage" */
export type CoatUsage_Set_Input = {
  MinAppRatePerCoatDry?: Maybe<Scalars['MeasurementValue']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CoatUsage_Stddev_Fields = {
  __typename?: 'CoatUsage_stddev_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CoatUsage_Stddev_Pop_Fields = {
  __typename?: 'CoatUsage_stddev_pop_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CoatUsage_Stddev_Samp_Fields = {
  __typename?: 'CoatUsage_stddev_samp_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CoatUsage_Sum_Fields = {
  __typename?: 'CoatUsage_sum_fields';
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "CoatUsage" */
export enum CoatUsage_Update_Column {
  /** column name */
  MinAppRatePerCoatDry = 'MinAppRatePerCoatDry',
  /** column name */
  MinAppRatePerCoatWet = 'MinAppRatePerCoatWet',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type CoatUsage_Var_Pop_Fields = {
  __typename?: 'CoatUsage_var_pop_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CoatUsage_Var_Samp_Fields = {
  __typename?: 'CoatUsage_var_samp_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CoatUsage_Variance_Fields = {
  __typename?: 'CoatUsage_variance_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Coat" */
export type Coat_Aggregate = {
  __typename?: 'Coat_aggregate';
  aggregate?: Maybe<Coat_Aggregate_Fields>;
  nodes: Array<Coat>;
};

/** aggregate fields of "Coat" */
export type Coat_Aggregate_Fields = {
  __typename?: 'Coat_aggregate_fields';
  avg?: Maybe<Coat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Coat_Max_Fields>;
  min?: Maybe<Coat_Min_Fields>;
  stddev?: Maybe<Coat_Stddev_Fields>;
  stddev_pop?: Maybe<Coat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Coat_Stddev_Samp_Fields>;
  sum?: Maybe<Coat_Sum_Fields>;
  var_pop?: Maybe<Coat_Var_Pop_Fields>;
  var_samp?: Maybe<Coat_Var_Samp_Fields>;
  variance?: Maybe<Coat_Variance_Fields>;
};


/** aggregate fields of "Coat" */
export type Coat_Aggregate_FieldsCountArgs = {
  column?: Maybe<Coat_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Coat" */
export type Coat_Aggregate_Order_By = {
  avg?: Maybe<Coat_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Coat_Max_Order_By>;
  min?: Maybe<Coat_Min_Order_By>;
  stddev?: Maybe<Coat_Stddev_Order_By>;
  stddev_pop?: Maybe<Coat_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Coat_Stddev_Samp_Order_By>;
  sum?: Maybe<Coat_Sum_Order_By>;
  var_pop?: Maybe<Coat_Var_Pop_Order_By>;
  var_samp?: Maybe<Coat_Var_Samp_Order_By>;
  variance?: Maybe<Coat_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Coat_Avg_Fields = {
  __typename?: 'Coat_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Coat" */
export type Coat_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Coat". All fields are combined with a logical 'AND'. */
export type Coat_Bool_Exp = {
  C_CoverColor?: Maybe<C_CoverColor_Bool_Exp>;
  C_LiquidApplication?: Maybe<C_LiquidApplication_Bool_Exp>;
  C_LiquidAppliedMaterial?: Maybe<C_LiquidAppliedMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  CoverColorId?: Maybe<Int_Mssql_Comparison_Exp>;
  LiquidApplicationId?: Maybe<Int_Mssql_Comparison_Exp>;
  LiquidAppliedMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  _and?: Maybe<Array<Coat_Bool_Exp>>;
  _not?: Maybe<Coat_Bool_Exp>;
  _or?: Maybe<Array<Coat_Bool_Exp>>;
};

/** upsert condition type for table "Coat" */
export type Coat_If_Matched = {
  match_columns?: Array<Coat_Insert_Match_Column>;
  update_columns?: Array<Coat_Update_Column>;
  where?: Maybe<Coat_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Coat" */
export type Coat_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Coat" */
export type Coat_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "Coat" */
export enum Coat_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverColorId = 'CoverColorId',
  /** column name */
  LiquidApplicationId = 'LiquidApplicationId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId'
}

/** aggregate max on columns */
export type Coat_Max_Fields = {
  __typename?: 'Coat_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Coat" */
export type Coat_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Coat_Min_Fields = {
  __typename?: 'Coat_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Coat" */
export type Coat_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Coat" */
export type Coat_Mutation_Response = {
  __typename?: 'Coat_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coat>;
};

/** Ordering options when selecting data from "Coat". */
export type Coat_Order_By = {
  C_CoverColor?: Maybe<C_CoverColor_Order_By>;
  C_LiquidApplication?: Maybe<C_LiquidApplication_Order_By>;
  C_LiquidAppliedMaterial?: Maybe<C_LiquidAppliedMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
};

/** primary key columns input for table: Coat */
export type Coat_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "Coat" */
export enum Coat_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverColorId = 'CoverColorId',
  /** column name */
  LiquidApplicationId = 'LiquidApplicationId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId'
}

/** input type for updating data in table "Coat" */
export type Coat_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Coat_Stddev_Fields = {
  __typename?: 'Coat_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Coat" */
export type Coat_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Coat_Stddev_Pop_Fields = {
  __typename?: 'Coat_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Coat" */
export type Coat_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Coat_Stddev_Samp_Fields = {
  __typename?: 'Coat_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Coat" */
export type Coat_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Coat_Sum_Fields = {
  __typename?: 'Coat_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Coat" */
export type Coat_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** update columns of table "Coat" */
export enum Coat_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverColorId = 'CoverColorId',
  /** column name */
  LiquidApplicationId = 'LiquidApplicationId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId'
}

/** aggregate var_pop on columns */
export type Coat_Var_Pop_Fields = {
  __typename?: 'Coat_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Coat" */
export type Coat_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Coat_Var_Samp_Fields = {
  __typename?: 'Coat_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Coat" */
export type Coat_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Coat_Variance_Fields = {
  __typename?: 'Coat_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Coat" */
export type Coat_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "Coating" */
export type Coating = {
  __typename?: 'Coating';
  /** An object relationship */
  C_CoatingMaterial: C_CoatingMaterial;
  CoatingMaterialId: Scalars['Int'];
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
};

/** columns and relationships of "CoatingSystemUsage" */
export type CoatingSystemUsage = {
  __typename?: 'CoatingSystemUsage';
  ApplicationRateDry: Scalars['MeasurementValue'];
  ApplicationRateWet: Scalars['MeasurementValue'];
  NumberCoats: Scalars['Int'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "CoatingSystemUsage" */
export type CoatingSystemUsage_Aggregate = {
  __typename?: 'CoatingSystemUsage_aggregate';
  aggregate?: Maybe<CoatingSystemUsage_Aggregate_Fields>;
  nodes: Array<CoatingSystemUsage>;
};

/** aggregate fields of "CoatingSystemUsage" */
export type CoatingSystemUsage_Aggregate_Fields = {
  __typename?: 'CoatingSystemUsage_aggregate_fields';
  avg?: Maybe<CoatingSystemUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CoatingSystemUsage_Max_Fields>;
  min?: Maybe<CoatingSystemUsage_Min_Fields>;
  stddev?: Maybe<CoatingSystemUsage_Stddev_Fields>;
  stddev_pop?: Maybe<CoatingSystemUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CoatingSystemUsage_Stddev_Samp_Fields>;
  sum?: Maybe<CoatingSystemUsage_Sum_Fields>;
  var_pop?: Maybe<CoatingSystemUsage_Var_Pop_Fields>;
  var_samp?: Maybe<CoatingSystemUsage_Var_Samp_Fields>;
  variance?: Maybe<CoatingSystemUsage_Variance_Fields>;
};


/** aggregate fields of "CoatingSystemUsage" */
export type CoatingSystemUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<CoatingSystemUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CoatingSystemUsage_Avg_Fields = {
  __typename?: 'CoatingSystemUsage_avg_fields';
  NumberCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CoatingSystemUsage". All fields are combined with a logical 'AND'. */
export type CoatingSystemUsage_Bool_Exp = {
  ApplicationRateDry?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ApplicationRateWet?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  NumberCoats?: Maybe<Int_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CoatingSystemUsage_Bool_Exp>>;
  _not?: Maybe<CoatingSystemUsage_Bool_Exp>;
  _or?: Maybe<Array<CoatingSystemUsage_Bool_Exp>>;
};

/** upsert condition type for table "CoatingSystemUsage" */
export type CoatingSystemUsage_If_Matched = {
  match_columns?: Array<CoatingSystemUsage_Insert_Match_Column>;
  update_columns?: Array<CoatingSystemUsage_Update_Column>;
  where?: Maybe<CoatingSystemUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CoatingSystemUsage" */
export type CoatingSystemUsage_Inc_Input = {
  NumberCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CoatingSystemUsage" */
export type CoatingSystemUsage_Insert_Input = {
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  NumberCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "CoatingSystemUsage" */
export enum CoatingSystemUsage_Insert_Match_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  NumberCoats = 'NumberCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type CoatingSystemUsage_Max_Fields = {
  __typename?: 'CoatingSystemUsage_max_fields';
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  NumberCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CoatingSystemUsage_Min_Fields = {
  __typename?: 'CoatingSystemUsage_min_fields';
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  NumberCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "CoatingSystemUsage" */
export type CoatingSystemUsage_Mutation_Response = {
  __typename?: 'CoatingSystemUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoatingSystemUsage>;
};

/** Ordering options when selecting data from "CoatingSystemUsage". */
export type CoatingSystemUsage_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  NumberCoats?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: CoatingSystemUsage */
export type CoatingSystemUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "CoatingSystemUsage" */
export enum CoatingSystemUsage_Select_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  NumberCoats = 'NumberCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "CoatingSystemUsage" */
export type CoatingSystemUsage_Set_Input = {
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  NumberCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CoatingSystemUsage_Stddev_Fields = {
  __typename?: 'CoatingSystemUsage_stddev_fields';
  NumberCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CoatingSystemUsage_Stddev_Pop_Fields = {
  __typename?: 'CoatingSystemUsage_stddev_pop_fields';
  NumberCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CoatingSystemUsage_Stddev_Samp_Fields = {
  __typename?: 'CoatingSystemUsage_stddev_samp_fields';
  NumberCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CoatingSystemUsage_Sum_Fields = {
  __typename?: 'CoatingSystemUsage_sum_fields';
  NumberCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "CoatingSystemUsage" */
export enum CoatingSystemUsage_Update_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  NumberCoats = 'NumberCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type CoatingSystemUsage_Var_Pop_Fields = {
  __typename?: 'CoatingSystemUsage_var_pop_fields';
  NumberCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CoatingSystemUsage_Var_Samp_Fields = {
  __typename?: 'CoatingSystemUsage_var_samp_fields';
  NumberCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CoatingSystemUsage_Variance_Fields = {
  __typename?: 'CoatingSystemUsage_variance_fields';
  NumberCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "CoatingUsage" */
export type CoatingUsage = {
  __typename?: 'CoatingUsage';
  ApplicationRateDry: Scalars['MeasurementValue'];
  ApplicationRateWet: Scalars['MeasurementValue'];
  NumCoats: Scalars['Int'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "CoatingUsage" */
export type CoatingUsage_Aggregate = {
  __typename?: 'CoatingUsage_aggregate';
  aggregate?: Maybe<CoatingUsage_Aggregate_Fields>;
  nodes: Array<CoatingUsage>;
};

/** aggregate fields of "CoatingUsage" */
export type CoatingUsage_Aggregate_Fields = {
  __typename?: 'CoatingUsage_aggregate_fields';
  avg?: Maybe<CoatingUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CoatingUsage_Max_Fields>;
  min?: Maybe<CoatingUsage_Min_Fields>;
  stddev?: Maybe<CoatingUsage_Stddev_Fields>;
  stddev_pop?: Maybe<CoatingUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CoatingUsage_Stddev_Samp_Fields>;
  sum?: Maybe<CoatingUsage_Sum_Fields>;
  var_pop?: Maybe<CoatingUsage_Var_Pop_Fields>;
  var_samp?: Maybe<CoatingUsage_Var_Samp_Fields>;
  variance?: Maybe<CoatingUsage_Variance_Fields>;
};


/** aggregate fields of "CoatingUsage" */
export type CoatingUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<CoatingUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CoatingUsage_Avg_Fields = {
  __typename?: 'CoatingUsage_avg_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CoatingUsage". All fields are combined with a logical 'AND'. */
export type CoatingUsage_Bool_Exp = {
  ApplicationRateDry?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ApplicationRateWet?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  NumCoats?: Maybe<Int_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CoatingUsage_Bool_Exp>>;
  _not?: Maybe<CoatingUsage_Bool_Exp>;
  _or?: Maybe<Array<CoatingUsage_Bool_Exp>>;
};

/** upsert condition type for table "CoatingUsage" */
export type CoatingUsage_If_Matched = {
  match_columns?: Array<CoatingUsage_Insert_Match_Column>;
  update_columns?: Array<CoatingUsage_Update_Column>;
  where?: Maybe<CoatingUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CoatingUsage" */
export type CoatingUsage_Inc_Input = {
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CoatingUsage" */
export type CoatingUsage_Insert_Input = {
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "CoatingUsage" */
export enum CoatingUsage_Insert_Match_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type CoatingUsage_Max_Fields = {
  __typename?: 'CoatingUsage_max_fields';
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CoatingUsage_Min_Fields = {
  __typename?: 'CoatingUsage_min_fields';
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "CoatingUsage" */
export type CoatingUsage_Mutation_Response = {
  __typename?: 'CoatingUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoatingUsage>;
};

/** Ordering options when selecting data from "CoatingUsage". */
export type CoatingUsage_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: CoatingUsage */
export type CoatingUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "CoatingUsage" */
export enum CoatingUsage_Select_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "CoatingUsage" */
export type CoatingUsage_Set_Input = {
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CoatingUsage_Stddev_Fields = {
  __typename?: 'CoatingUsage_stddev_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CoatingUsage_Stddev_Pop_Fields = {
  __typename?: 'CoatingUsage_stddev_pop_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CoatingUsage_Stddev_Samp_Fields = {
  __typename?: 'CoatingUsage_stddev_samp_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CoatingUsage_Sum_Fields = {
  __typename?: 'CoatingUsage_sum_fields';
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "CoatingUsage" */
export enum CoatingUsage_Update_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type CoatingUsage_Var_Pop_Fields = {
  __typename?: 'CoatingUsage_var_pop_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CoatingUsage_Var_Samp_Fields = {
  __typename?: 'CoatingUsage_var_samp_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CoatingUsage_Variance_Fields = {
  __typename?: 'CoatingUsage_variance_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Coating" */
export type Coating_Aggregate = {
  __typename?: 'Coating_aggregate';
  aggregate?: Maybe<Coating_Aggregate_Fields>;
  nodes: Array<Coating>;
};

/** aggregate fields of "Coating" */
export type Coating_Aggregate_Fields = {
  __typename?: 'Coating_aggregate_fields';
  avg?: Maybe<Coating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Coating_Max_Fields>;
  min?: Maybe<Coating_Min_Fields>;
  stddev?: Maybe<Coating_Stddev_Fields>;
  stddev_pop?: Maybe<Coating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Coating_Stddev_Samp_Fields>;
  sum?: Maybe<Coating_Sum_Fields>;
  var_pop?: Maybe<Coating_Var_Pop_Fields>;
  var_samp?: Maybe<Coating_Var_Samp_Fields>;
  variance?: Maybe<Coating_Variance_Fields>;
};


/** aggregate fields of "Coating" */
export type Coating_Aggregate_FieldsCountArgs = {
  column?: Maybe<Coating_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Coating" */
export type Coating_Aggregate_Order_By = {
  avg?: Maybe<Coating_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Coating_Max_Order_By>;
  min?: Maybe<Coating_Min_Order_By>;
  stddev?: Maybe<Coating_Stddev_Order_By>;
  stddev_pop?: Maybe<Coating_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Coating_Stddev_Samp_Order_By>;
  sum?: Maybe<Coating_Sum_Order_By>;
  var_pop?: Maybe<Coating_Var_Pop_Order_By>;
  var_samp?: Maybe<Coating_Var_Samp_Order_By>;
  variance?: Maybe<Coating_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Coating_Avg_Fields = {
  __typename?: 'Coating_avg_fields';
  CoatingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Coating" */
export type Coating_Avg_Order_By = {
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Coating". All fields are combined with a logical 'AND'. */
export type Coating_Bool_Exp = {
  C_CoatingMaterial?: Maybe<C_CoatingMaterial_Bool_Exp>;
  CoatingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  _and?: Maybe<Array<Coating_Bool_Exp>>;
  _not?: Maybe<Coating_Bool_Exp>;
  _or?: Maybe<Array<Coating_Bool_Exp>>;
};

/** upsert condition type for table "Coating" */
export type Coating_If_Matched = {
  match_columns?: Array<Coating_Insert_Match_Column>;
  update_columns?: Array<Coating_Update_Column>;
  where?: Maybe<Coating_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Coating" */
export type Coating_Inc_Input = {
  CoatingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Coating" */
export type Coating_Insert_Input = {
  CoatingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "Coating" */
export enum Coating_Insert_Match_Column {
  /** column name */
  CoatingMaterialId = 'CoatingMaterialId',
  /** column name */
  ComponentId = 'ComponentId'
}

/** aggregate max on columns */
export type Coating_Max_Fields = {
  __typename?: 'Coating_max_fields';
  CoatingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Coating" */
export type Coating_Max_Order_By = {
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Coating_Min_Fields = {
  __typename?: 'Coating_min_fields';
  CoatingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Coating" */
export type Coating_Min_Order_By = {
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Coating" */
export type Coating_Mutation_Response = {
  __typename?: 'Coating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coating>;
};

/** Ordering options when selecting data from "Coating". */
export type Coating_Order_By = {
  C_CoatingMaterial?: Maybe<C_CoatingMaterial_Order_By>;
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
};

/** primary key columns input for table: Coating */
export type Coating_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "Coating" */
export enum Coating_Select_Column {
  /** column name */
  CoatingMaterialId = 'CoatingMaterialId',
  /** column name */
  ComponentId = 'ComponentId'
}

/** input type for updating data in table "Coating" */
export type Coating_Set_Input = {
  CoatingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Coating_Stddev_Fields = {
  __typename?: 'Coating_stddev_fields';
  CoatingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Coating" */
export type Coating_Stddev_Order_By = {
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Coating_Stddev_Pop_Fields = {
  __typename?: 'Coating_stddev_pop_fields';
  CoatingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Coating" */
export type Coating_Stddev_Pop_Order_By = {
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Coating_Stddev_Samp_Fields = {
  __typename?: 'Coating_stddev_samp_fields';
  CoatingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Coating" */
export type Coating_Stddev_Samp_Order_By = {
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Coating_Sum_Fields = {
  __typename?: 'Coating_sum_fields';
  CoatingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Coating" */
export type Coating_Sum_Order_By = {
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** update columns of table "Coating" */
export enum Coating_Update_Column {
  /** column name */
  CoatingMaterialId = 'CoatingMaterialId',
  /** column name */
  ComponentId = 'ComponentId'
}

/** aggregate var_pop on columns */
export type Coating_Var_Pop_Fields = {
  __typename?: 'Coating_var_pop_fields';
  CoatingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Coating" */
export type Coating_Var_Pop_Order_By = {
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Coating_Var_Samp_Fields = {
  __typename?: 'Coating_var_samp_fields';
  CoatingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Coating" */
export type Coating_Var_Samp_Order_By = {
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Coating_Variance_Fields = {
  __typename?: 'Coating_variance_fields';
  CoatingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Coating" */
export type Coating_Variance_Order_By = {
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "CodeTableDescription". All fields are combined with logical 'AND'. */
export type CodeTableDescription_Mssql_Comparison_Exp = {
  _eq?: Maybe<Scalars['CodeTableDescription']>;
  _gt?: Maybe<Scalars['CodeTableDescription']>;
  _gte?: Maybe<Scalars['CodeTableDescription']>;
  _in?: Maybe<Array<Scalars['CodeTableDescription']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['CodeTableDescription']>;
  _lte?: Maybe<Scalars['CodeTableDescription']>;
  _neq?: Maybe<Scalars['CodeTableDescription']>;
  _nin?: Maybe<Array<Scalars['CodeTableDescription']>>;
};


/** Boolean expression to compare columns of type "CodeTableValue". All fields are combined with logical 'AND'. */
export type CodeTableValue_Mssql_Comparison_Exp = {
  _eq?: Maybe<Scalars['CodeTableValue']>;
  _gt?: Maybe<Scalars['CodeTableValue']>;
  _gte?: Maybe<Scalars['CodeTableValue']>;
  _in?: Maybe<Array<Scalars['CodeTableValue']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['CodeTableValue']>;
  _lte?: Maybe<Scalars['CodeTableValue']>;
  _neq?: Maybe<Scalars['CodeTableValue']>;
  _nin?: Maybe<Array<Scalars['CodeTableValue']>>;
};

/** columns and relationships of "Component" */
export type Component = {
  __typename?: 'Component';
  /** An object relationship */
  C_ComponentType: C_ComponentType;
  /** An object relationship */
  C_Status: C_Status;
  Comments: Scalars['ItemComment'];
  /** An array relationship */
  ComponentApprovedUses: Array<ComponentApprovedUse>;
  /** An aggregate relationship */
  ComponentApprovedUses_aggregate: ComponentApprovedUse_Aggregate;
  /** An array relationship */
  ComponentDocs: Array<ComponentDoc>;
  /** An aggregate relationship */
  ComponentDocs_aggregate: ComponentDoc_Aggregate;
  ComponentId: Scalars['Int'];
  ComponentTypeId: Scalars['Int'];
  CreatedByUserAccountId: Scalars['Int'];
  DateCreated: Scalars['datetime'];
  DateEffective: Scalars['datetime'];
  DateInception: Scalars['datetime'];
  DateModified: Scalars['datetime'];
  /** An object relationship */
  FMUserAccount: FmUserAccount;
  /** An array relationship */
  LapSealantUsages: Array<LapSealantUsage>;
  /** An aggregate relationship */
  LapSealantUsages_aggregate: LapSealantUsage_Aggregate;
  /** An object relationship */
  ManufacturedProduct?: Maybe<ManufacturedProduct>;
  ModifiedByUserAccountId: Scalars['Int'];
  /** An array relationship */
  R_SinglePly_BondingAdhesives: Array<R_SinglePly_BondingAdhesives>;
  /** An aggregate relationship */
  R_SinglePly_BondingAdhesives_aggregate: R_SinglePly_BondingAdhesives_Aggregate;
  /** An array relationship */
  R_SinglePly_LapAdhesives: Array<R_SinglePly_LapAdhesives>;
  /** An aggregate relationship */
  R_SinglePly_LapAdhesives_aggregate: R_SinglePly_LapAdhesives_Aggregate;
  StatusId: Scalars['Int'];
  /** An object relationship */
  StructuredSystem?: Maybe<StructuredSystem>;
  /** An object relationship */
  Subassembly?: Maybe<Subassembly>;
  /** An array relationship */
  Usages: Array<Usage>;
  /** An aggregate relationship */
  Usages_aggregate: Usage_Aggregate;
  VersionMajor: Scalars['Int'];
  VersionMinor: Scalars['Int'];
  /** An object relationship */
  fMUserAccountByModifiedbyuseraccountid: FmUserAccount;
};


/** columns and relationships of "Component" */
export type ComponentComponentApprovedUsesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentApprovedUse_Order_By>>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};


/** columns and relationships of "Component" */
export type ComponentComponentApprovedUses_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentApprovedUse_Order_By>>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};


/** columns and relationships of "Component" */
export type ComponentComponentDocsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentDoc_Order_By>>;
  where?: Maybe<ComponentDoc_Bool_Exp>;
};


/** columns and relationships of "Component" */
export type ComponentComponentDocs_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentDoc_Order_By>>;
  where?: Maybe<ComponentDoc_Bool_Exp>;
};


/** columns and relationships of "Component" */
export type ComponentLapSealantUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSealantUsage_Order_By>>;
  where?: Maybe<LapSealantUsage_Bool_Exp>;
};


/** columns and relationships of "Component" */
export type ComponentLapSealantUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSealantUsage_Order_By>>;
  where?: Maybe<LapSealantUsage_Bool_Exp>;
};


/** columns and relationships of "Component" */
export type ComponentR_SinglePly_BondingAdhesivesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_BondingAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_BondingAdhesives_Bool_Exp>;
};


/** columns and relationships of "Component" */
export type ComponentR_SinglePly_BondingAdhesives_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_BondingAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_BondingAdhesives_Bool_Exp>;
};


/** columns and relationships of "Component" */
export type ComponentR_SinglePly_LapAdhesivesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_LapAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_LapAdhesives_Bool_Exp>;
};


/** columns and relationships of "Component" */
export type ComponentR_SinglePly_LapAdhesives_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_LapAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_LapAdhesives_Bool_Exp>;
};


/** columns and relationships of "Component" */
export type ComponentUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Usage_Order_By>>;
  where?: Maybe<Usage_Bool_Exp>;
};


/** columns and relationships of "Component" */
export type ComponentUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Usage_Order_By>>;
  where?: Maybe<Usage_Bool_Exp>;
};

/** columns and relationships of "ComponentApprovedUse" */
export type ComponentApprovedUse = {
  __typename?: 'ComponentApprovedUse';
  ApprovedUseId: Scalars['Int'];
  /** An object relationship */
  C_ApprovedUse: C_ApprovedUse;
  /** An object relationship */
  C_Status: C_Status;
  Comments: Scalars['ItemComment'];
  /** An object relationship */
  Component: Component;
  ComponentId: Scalars['Int'];
  CreatedByUserAccountId: Scalars['Int'];
  DateCreated: Scalars['datetime'];
  DateEffective: Scalars['datetime'];
  DateModified: Scalars['datetime'];
  /** An object relationship */
  FMUserAccount: FmUserAccount;
  IsInactive: Scalars['Boolean'];
  ModifiedByUserAccountId: Scalars['Int'];
  StatusId: Scalars['Int'];
  /** An array relationship */
  Usages: Array<Usage>;
  /** An aggregate relationship */
  Usages_aggregate: Usage_Aggregate;
  /** An object relationship */
  fMUserAccountByModifiedbyuseraccountid: FmUserAccount;
};


/** columns and relationships of "ComponentApprovedUse" */
export type ComponentApprovedUseUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Usage_Order_By>>;
  where?: Maybe<Usage_Bool_Exp>;
};


/** columns and relationships of "ComponentApprovedUse" */
export type ComponentApprovedUseUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Usage_Order_By>>;
  where?: Maybe<Usage_Bool_Exp>;
};

/** aggregated selection of "ComponentApprovedUse" */
export type ComponentApprovedUse_Aggregate = {
  __typename?: 'ComponentApprovedUse_aggregate';
  aggregate?: Maybe<ComponentApprovedUse_Aggregate_Fields>;
  nodes: Array<ComponentApprovedUse>;
};

/** aggregate fields of "ComponentApprovedUse" */
export type ComponentApprovedUse_Aggregate_Fields = {
  __typename?: 'ComponentApprovedUse_aggregate_fields';
  avg?: Maybe<ComponentApprovedUse_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ComponentApprovedUse_Max_Fields>;
  min?: Maybe<ComponentApprovedUse_Min_Fields>;
  stddev?: Maybe<ComponentApprovedUse_Stddev_Fields>;
  stddev_pop?: Maybe<ComponentApprovedUse_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ComponentApprovedUse_Stddev_Samp_Fields>;
  sum?: Maybe<ComponentApprovedUse_Sum_Fields>;
  var_pop?: Maybe<ComponentApprovedUse_Var_Pop_Fields>;
  var_samp?: Maybe<ComponentApprovedUse_Var_Samp_Fields>;
  variance?: Maybe<ComponentApprovedUse_Variance_Fields>;
};


/** aggregate fields of "ComponentApprovedUse" */
export type ComponentApprovedUse_Aggregate_FieldsCountArgs = {
  column?: Maybe<ComponentApprovedUse_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ComponentApprovedUse" */
export type ComponentApprovedUse_Aggregate_Order_By = {
  avg?: Maybe<ComponentApprovedUse_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ComponentApprovedUse_Max_Order_By>;
  min?: Maybe<ComponentApprovedUse_Min_Order_By>;
  stddev?: Maybe<ComponentApprovedUse_Stddev_Order_By>;
  stddev_pop?: Maybe<ComponentApprovedUse_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ComponentApprovedUse_Stddev_Samp_Order_By>;
  sum?: Maybe<ComponentApprovedUse_Sum_Order_By>;
  var_pop?: Maybe<ComponentApprovedUse_Var_Pop_Order_By>;
  var_samp?: Maybe<ComponentApprovedUse_Var_Samp_Order_By>;
  variance?: Maybe<ComponentApprovedUse_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ComponentApprovedUse_Avg_Fields = {
  __typename?: 'ComponentApprovedUse_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ComponentApprovedUse" */
export type ComponentApprovedUse_Avg_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ComponentApprovedUse". All fields are combined with a logical 'AND'. */
export type ComponentApprovedUse_Bool_Exp = {
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_ApprovedUse?: Maybe<C_ApprovedUse_Bool_Exp>;
  C_Status?: Maybe<C_Status_Bool_Exp>;
  Comments?: Maybe<ItemComment_Mssql_Comparison_Exp>;
  Component?: Maybe<Component_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  CreatedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateCreated?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateEffective?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  FMUserAccount?: Maybe<FmUserAccount_Bool_Exp>;
  IsInactive?: Maybe<Boolean_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  Usages?: Maybe<Usage_Bool_Exp>;
  _and?: Maybe<Array<ComponentApprovedUse_Bool_Exp>>;
  _not?: Maybe<ComponentApprovedUse_Bool_Exp>;
  _or?: Maybe<Array<ComponentApprovedUse_Bool_Exp>>;
  fMUserAccountByModifiedbyuseraccountid?: Maybe<FmUserAccount_Bool_Exp>;
};

/** upsert condition type for table "ComponentApprovedUse" */
export type ComponentApprovedUse_If_Matched = {
  match_columns?: Array<ComponentApprovedUse_Insert_Match_Column>;
  update_columns?: Array<ComponentApprovedUse_Update_Column>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ComponentApprovedUse" */
export type ComponentApprovedUse_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ComponentApprovedUse" */
export type ComponentApprovedUse_Insert_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  IsInactive?: Maybe<Scalars['Boolean']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ComponentApprovedUse" */
export enum ComponentApprovedUse_Insert_Match_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Comments = 'Comments',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CreatedByUserAccountId = 'CreatedByUserAccountId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  DateEffective = 'DateEffective',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  IsInactive = 'IsInactive',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId'
}

/** aggregate max on columns */
export type ComponentApprovedUse_Max_Fields = {
  __typename?: 'ComponentApprovedUse_max_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ComponentApprovedUse" */
export type ComponentApprovedUse_Max_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  Comments?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  DateEffective?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ComponentApprovedUse_Min_Fields = {
  __typename?: 'ComponentApprovedUse_min_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ComponentApprovedUse" */
export type ComponentApprovedUse_Min_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  Comments?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  DateEffective?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ComponentApprovedUse" */
export type ComponentApprovedUse_Mutation_Response = {
  __typename?: 'ComponentApprovedUse_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ComponentApprovedUse>;
};

/** Ordering options when selecting data from "ComponentApprovedUse". */
export type ComponentApprovedUse_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  C_ApprovedUse?: Maybe<C_ApprovedUse_Order_By>;
  C_Status?: Maybe<C_Status_Order_By>;
  Comments?: Maybe<Order_By>;
  Component?: Maybe<Component_Order_By>;
  ComponentId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  DateEffective?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  FMUserAccount?: Maybe<FmUserAccount_Order_By>;
  IsInactive?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Usages_aggregate?: Maybe<Usage_Aggregate_Order_By>;
  fMUserAccountByModifiedbyuseraccountid?: Maybe<FmUserAccount_Order_By>;
};

/** primary key columns input for table: ComponentApprovedUse */
export type ComponentApprovedUse_Pk_Columns_Input = {
  ApprovedUseId: Scalars['Int'];
  ComponentId: Scalars['Int'];
};

/** select columns of table "ComponentApprovedUse" */
export enum ComponentApprovedUse_Select_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Comments = 'Comments',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CreatedByUserAccountId = 'CreatedByUserAccountId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  DateEffective = 'DateEffective',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  IsInactive = 'IsInactive',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId'
}

/** input type for updating data in table "ComponentApprovedUse" */
export type ComponentApprovedUse_Set_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  IsInactive?: Maybe<Scalars['Boolean']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ComponentApprovedUse_Stddev_Fields = {
  __typename?: 'ComponentApprovedUse_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ComponentApprovedUse" */
export type ComponentApprovedUse_Stddev_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ComponentApprovedUse_Stddev_Pop_Fields = {
  __typename?: 'ComponentApprovedUse_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ComponentApprovedUse" */
export type ComponentApprovedUse_Stddev_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ComponentApprovedUse_Stddev_Samp_Fields = {
  __typename?: 'ComponentApprovedUse_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ComponentApprovedUse" */
export type ComponentApprovedUse_Stddev_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ComponentApprovedUse_Sum_Fields = {
  __typename?: 'ComponentApprovedUse_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ComponentApprovedUse" */
export type ComponentApprovedUse_Sum_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** update columns of table "ComponentApprovedUse" */
export enum ComponentApprovedUse_Update_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Comments = 'Comments',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CreatedByUserAccountId = 'CreatedByUserAccountId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  DateEffective = 'DateEffective',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  IsInactive = 'IsInactive',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId'
}

/** aggregate var_pop on columns */
export type ComponentApprovedUse_Var_Pop_Fields = {
  __typename?: 'ComponentApprovedUse_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ComponentApprovedUse" */
export type ComponentApprovedUse_Var_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ComponentApprovedUse_Var_Samp_Fields = {
  __typename?: 'ComponentApprovedUse_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ComponentApprovedUse" */
export type ComponentApprovedUse_Var_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ComponentApprovedUse_Variance_Fields = {
  __typename?: 'ComponentApprovedUse_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ComponentApprovedUse" */
export type ComponentApprovedUse_Variance_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "ComponentDoc" */
export type ComponentDoc = {
  __typename?: 'ComponentDoc';
  /** An object relationship */
  Component: Component;
  ComponentDocId: Scalars['Int'];
  ComponentId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  DocData?: Maybe<Scalars['varbinary']>;
  DocName: Scalars['String'];
};

/** aggregated selection of "ComponentDoc" */
export type ComponentDoc_Aggregate = {
  __typename?: 'ComponentDoc_aggregate';
  aggregate?: Maybe<ComponentDoc_Aggregate_Fields>;
  nodes: Array<ComponentDoc>;
};

/** aggregate fields of "ComponentDoc" */
export type ComponentDoc_Aggregate_Fields = {
  __typename?: 'ComponentDoc_aggregate_fields';
  avg?: Maybe<ComponentDoc_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ComponentDoc_Max_Fields>;
  min?: Maybe<ComponentDoc_Min_Fields>;
  stddev?: Maybe<ComponentDoc_Stddev_Fields>;
  stddev_pop?: Maybe<ComponentDoc_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ComponentDoc_Stddev_Samp_Fields>;
  sum?: Maybe<ComponentDoc_Sum_Fields>;
  var_pop?: Maybe<ComponentDoc_Var_Pop_Fields>;
  var_samp?: Maybe<ComponentDoc_Var_Samp_Fields>;
  variance?: Maybe<ComponentDoc_Variance_Fields>;
};


/** aggregate fields of "ComponentDoc" */
export type ComponentDoc_Aggregate_FieldsCountArgs = {
  column?: Maybe<ComponentDoc_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ComponentDoc" */
export type ComponentDoc_Aggregate_Order_By = {
  avg?: Maybe<ComponentDoc_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ComponentDoc_Max_Order_By>;
  min?: Maybe<ComponentDoc_Min_Order_By>;
  stddev?: Maybe<ComponentDoc_Stddev_Order_By>;
  stddev_pop?: Maybe<ComponentDoc_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ComponentDoc_Stddev_Samp_Order_By>;
  sum?: Maybe<ComponentDoc_Sum_Order_By>;
  var_pop?: Maybe<ComponentDoc_Var_Pop_Order_By>;
  var_samp?: Maybe<ComponentDoc_Var_Samp_Order_By>;
  variance?: Maybe<ComponentDoc_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ComponentDoc_Avg_Fields = {
  __typename?: 'ComponentDoc_avg_fields';
  ComponentDocId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ComponentDoc" */
export type ComponentDoc_Avg_Order_By = {
  ComponentDocId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ComponentDoc". All fields are combined with a logical 'AND'. */
export type ComponentDoc_Bool_Exp = {
  Component?: Maybe<Component_Bool_Exp>;
  ComponentDocId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DocData?: Maybe<Varbinary_Mssql_Comparison_Exp>;
  DocName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ComponentDoc_Bool_Exp>>;
  _not?: Maybe<ComponentDoc_Bool_Exp>;
  _or?: Maybe<Array<ComponentDoc_Bool_Exp>>;
};

/** upsert condition type for table "ComponentDoc" */
export type ComponentDoc_If_Matched = {
  match_columns?: Array<ComponentDoc_Insert_Match_Column>;
  update_columns?: Array<ComponentDoc_Update_Column>;
  where?: Maybe<ComponentDoc_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ComponentDoc" */
export type ComponentDoc_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ComponentDoc" */
export type ComponentDoc_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DocData?: Maybe<Scalars['varbinary']>;
  DocName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "ComponentDoc" */
export enum ComponentDoc_Insert_Match_Column {
  /** column name */
  ComponentDocId = 'ComponentDocId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  DocData = 'DocData',
  /** column name */
  DocName = 'DocName'
}

/** aggregate max on columns */
export type ComponentDoc_Max_Fields = {
  __typename?: 'ComponentDoc_max_fields';
  ComponentDocId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DocName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ComponentDoc" */
export type ComponentDoc_Max_Order_By = {
  ComponentDocId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  DocName?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ComponentDoc_Min_Fields = {
  __typename?: 'ComponentDoc_min_fields';
  ComponentDocId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DocName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ComponentDoc" */
export type ComponentDoc_Min_Order_By = {
  ComponentDocId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  DocName?: Maybe<Order_By>;
};

/** response of any mutation on the table "ComponentDoc" */
export type ComponentDoc_Mutation_Response = {
  __typename?: 'ComponentDoc_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ComponentDoc>;
};

/** Ordering options when selecting data from "ComponentDoc". */
export type ComponentDoc_Order_By = {
  Component?: Maybe<Component_Order_By>;
  ComponentDocId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  DocData?: Maybe<Order_By>;
  DocName?: Maybe<Order_By>;
};

/** primary key columns input for table: ComponentDoc */
export type ComponentDoc_Pk_Columns_Input = {
  ComponentDocId: Scalars['Int'];
};

/** select columns of table "ComponentDoc" */
export enum ComponentDoc_Select_Column {
  /** column name */
  ComponentDocId = 'ComponentDocId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  DocData = 'DocData',
  /** column name */
  DocName = 'DocName'
}

/** input type for updating data in table "ComponentDoc" */
export type ComponentDoc_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DocData?: Maybe<Scalars['varbinary']>;
  DocName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ComponentDoc_Stddev_Fields = {
  __typename?: 'ComponentDoc_stddev_fields';
  ComponentDocId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ComponentDoc" */
export type ComponentDoc_Stddev_Order_By = {
  ComponentDocId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ComponentDoc_Stddev_Pop_Fields = {
  __typename?: 'ComponentDoc_stddev_pop_fields';
  ComponentDocId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ComponentDoc" */
export type ComponentDoc_Stddev_Pop_Order_By = {
  ComponentDocId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ComponentDoc_Stddev_Samp_Fields = {
  __typename?: 'ComponentDoc_stddev_samp_fields';
  ComponentDocId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ComponentDoc" */
export type ComponentDoc_Stddev_Samp_Order_By = {
  ComponentDocId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ComponentDoc_Sum_Fields = {
  __typename?: 'ComponentDoc_sum_fields';
  ComponentDocId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ComponentDoc" */
export type ComponentDoc_Sum_Order_By = {
  ComponentDocId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** update columns of table "ComponentDoc" */
export enum ComponentDoc_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  DocData = 'DocData',
  /** column name */
  DocName = 'DocName'
}

/** aggregate var_pop on columns */
export type ComponentDoc_Var_Pop_Fields = {
  __typename?: 'ComponentDoc_var_pop_fields';
  ComponentDocId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ComponentDoc" */
export type ComponentDoc_Var_Pop_Order_By = {
  ComponentDocId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ComponentDoc_Var_Samp_Fields = {
  __typename?: 'ComponentDoc_var_samp_fields';
  ComponentDocId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ComponentDoc" */
export type ComponentDoc_Var_Samp_Order_By = {
  ComponentDocId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ComponentDoc_Variance_Fields = {
  __typename?: 'ComponentDoc_variance_fields';
  ComponentDocId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ComponentDoc" */
export type ComponentDoc_Variance_Order_By = {
  ComponentDocId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "ComponentLayer" */
export type ComponentLayer = {
  __typename?: 'ComponentLayer';
  /** An object relationship */
  C_LayerType?: Maybe<C_LayerType>;
  /** An array relationship */
  ComponentLayerUsages: Array<ComponentLayerUsage>;
  /** An aggregate relationship */
  ComponentLayerUsages_aggregate: ComponentLayerUsage_Aggregate;
  IsPermable: Scalars['Boolean'];
  /** An object relationship */
  Layer: Layer;
  LayerId: Scalars['Int'];
  SelfSecurementComments?: Maybe<Scalars['ItemComment']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  SelfSecurementUsages: Array<SelfSecurementUsage>;
  /** An aggregate relationship */
  SelfSecurementUsages_aggregate: SelfSecurementUsage_Aggregate;
};


/** columns and relationships of "ComponentLayer" */
export type ComponentLayerComponentLayerUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentLayerUsage_Order_By>>;
  where?: Maybe<ComponentLayerUsage_Bool_Exp>;
};


/** columns and relationships of "ComponentLayer" */
export type ComponentLayerComponentLayerUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentLayerUsage_Order_By>>;
  where?: Maybe<ComponentLayerUsage_Bool_Exp>;
};


/** columns and relationships of "ComponentLayer" */
export type ComponentLayerSelfSecurementUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SelfSecurementUsage_Order_By>>;
  where?: Maybe<SelfSecurementUsage_Bool_Exp>;
};


/** columns and relationships of "ComponentLayer" */
export type ComponentLayerSelfSecurementUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SelfSecurementUsage_Order_By>>;
  where?: Maybe<SelfSecurementUsage_Bool_Exp>;
};

/** columns and relationships of "ComponentLayerUsage" */
export type ComponentLayerUsage = {
  __typename?: 'ComponentLayerUsage';
  /** An object relationship */
  ComponentLayer: ComponentLayer;
  LayerId: Scalars['Int'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "ComponentLayerUsage" */
export type ComponentLayerUsage_Aggregate = {
  __typename?: 'ComponentLayerUsage_aggregate';
  aggregate?: Maybe<ComponentLayerUsage_Aggregate_Fields>;
  nodes: Array<ComponentLayerUsage>;
};

/** aggregate fields of "ComponentLayerUsage" */
export type ComponentLayerUsage_Aggregate_Fields = {
  __typename?: 'ComponentLayerUsage_aggregate_fields';
  avg?: Maybe<ComponentLayerUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ComponentLayerUsage_Max_Fields>;
  min?: Maybe<ComponentLayerUsage_Min_Fields>;
  stddev?: Maybe<ComponentLayerUsage_Stddev_Fields>;
  stddev_pop?: Maybe<ComponentLayerUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ComponentLayerUsage_Stddev_Samp_Fields>;
  sum?: Maybe<ComponentLayerUsage_Sum_Fields>;
  var_pop?: Maybe<ComponentLayerUsage_Var_Pop_Fields>;
  var_samp?: Maybe<ComponentLayerUsage_Var_Samp_Fields>;
  variance?: Maybe<ComponentLayerUsage_Variance_Fields>;
};


/** aggregate fields of "ComponentLayerUsage" */
export type ComponentLayerUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<ComponentLayerUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ComponentLayerUsage" */
export type ComponentLayerUsage_Aggregate_Order_By = {
  avg?: Maybe<ComponentLayerUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ComponentLayerUsage_Max_Order_By>;
  min?: Maybe<ComponentLayerUsage_Min_Order_By>;
  stddev?: Maybe<ComponentLayerUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<ComponentLayerUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ComponentLayerUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<ComponentLayerUsage_Sum_Order_By>;
  var_pop?: Maybe<ComponentLayerUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<ComponentLayerUsage_Var_Samp_Order_By>;
  variance?: Maybe<ComponentLayerUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ComponentLayerUsage_Avg_Fields = {
  __typename?: 'ComponentLayerUsage_avg_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ComponentLayerUsage" */
export type ComponentLayerUsage_Avg_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ComponentLayerUsage". All fields are combined with a logical 'AND'. */
export type ComponentLayerUsage_Bool_Exp = {
  ComponentLayer?: Maybe<ComponentLayer_Bool_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ComponentLayerUsage_Bool_Exp>>;
  _not?: Maybe<ComponentLayerUsage_Bool_Exp>;
  _or?: Maybe<Array<ComponentLayerUsage_Bool_Exp>>;
};

/** upsert condition type for table "ComponentLayerUsage" */
export type ComponentLayerUsage_If_Matched = {
  match_columns?: Array<ComponentLayerUsage_Insert_Match_Column>;
  update_columns?: Array<ComponentLayerUsage_Update_Column>;
  where?: Maybe<ComponentLayerUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ComponentLayerUsage" */
export type ComponentLayerUsage_Inc_Input = {
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ComponentLayerUsage" */
export type ComponentLayerUsage_Insert_Input = {
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ComponentLayerUsage" */
export enum ComponentLayerUsage_Insert_Match_Column {
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type ComponentLayerUsage_Max_Fields = {
  __typename?: 'ComponentLayerUsage_max_fields';
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ComponentLayerUsage" */
export type ComponentLayerUsage_Max_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ComponentLayerUsage_Min_Fields = {
  __typename?: 'ComponentLayerUsage_min_fields';
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ComponentLayerUsage" */
export type ComponentLayerUsage_Min_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ComponentLayerUsage" */
export type ComponentLayerUsage_Mutation_Response = {
  __typename?: 'ComponentLayerUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ComponentLayerUsage>;
};

/** Ordering options when selecting data from "ComponentLayerUsage". */
export type ComponentLayerUsage_Order_By = {
  ComponentLayer?: Maybe<ComponentLayer_Order_By>;
  LayerId?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: ComponentLayerUsage */
export type ComponentLayerUsage_Pk_Columns_Input = {
  LayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};

/** select columns of table "ComponentLayerUsage" */
export enum ComponentLayerUsage_Select_Column {
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "ComponentLayerUsage" */
export type ComponentLayerUsage_Set_Input = {
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ComponentLayerUsage_Stddev_Fields = {
  __typename?: 'ComponentLayerUsage_stddev_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ComponentLayerUsage" */
export type ComponentLayerUsage_Stddev_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ComponentLayerUsage_Stddev_Pop_Fields = {
  __typename?: 'ComponentLayerUsage_stddev_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ComponentLayerUsage" */
export type ComponentLayerUsage_Stddev_Pop_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ComponentLayerUsage_Stddev_Samp_Fields = {
  __typename?: 'ComponentLayerUsage_stddev_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ComponentLayerUsage" */
export type ComponentLayerUsage_Stddev_Samp_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ComponentLayerUsage_Sum_Fields = {
  __typename?: 'ComponentLayerUsage_sum_fields';
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ComponentLayerUsage" */
export type ComponentLayerUsage_Sum_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "ComponentLayerUsage" */
export enum ComponentLayerUsage_Update_Column {
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type ComponentLayerUsage_Var_Pop_Fields = {
  __typename?: 'ComponentLayerUsage_var_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ComponentLayerUsage" */
export type ComponentLayerUsage_Var_Pop_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ComponentLayerUsage_Var_Samp_Fields = {
  __typename?: 'ComponentLayerUsage_var_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ComponentLayerUsage" */
export type ComponentLayerUsage_Var_Samp_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ComponentLayerUsage_Variance_Fields = {
  __typename?: 'ComponentLayerUsage_variance_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ComponentLayerUsage" */
export type ComponentLayerUsage_Variance_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregated selection of "ComponentLayer" */
export type ComponentLayer_Aggregate = {
  __typename?: 'ComponentLayer_aggregate';
  aggregate?: Maybe<ComponentLayer_Aggregate_Fields>;
  nodes: Array<ComponentLayer>;
};

/** aggregate fields of "ComponentLayer" */
export type ComponentLayer_Aggregate_Fields = {
  __typename?: 'ComponentLayer_aggregate_fields';
  avg?: Maybe<ComponentLayer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ComponentLayer_Max_Fields>;
  min?: Maybe<ComponentLayer_Min_Fields>;
  stddev?: Maybe<ComponentLayer_Stddev_Fields>;
  stddev_pop?: Maybe<ComponentLayer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ComponentLayer_Stddev_Samp_Fields>;
  sum?: Maybe<ComponentLayer_Sum_Fields>;
  var_pop?: Maybe<ComponentLayer_Var_Pop_Fields>;
  var_samp?: Maybe<ComponentLayer_Var_Samp_Fields>;
  variance?: Maybe<ComponentLayer_Variance_Fields>;
};


/** aggregate fields of "ComponentLayer" */
export type ComponentLayer_Aggregate_FieldsCountArgs = {
  column?: Maybe<ComponentLayer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ComponentLayer" */
export type ComponentLayer_Aggregate_Order_By = {
  avg?: Maybe<ComponentLayer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ComponentLayer_Max_Order_By>;
  min?: Maybe<ComponentLayer_Min_Order_By>;
  stddev?: Maybe<ComponentLayer_Stddev_Order_By>;
  stddev_pop?: Maybe<ComponentLayer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ComponentLayer_Stddev_Samp_Order_By>;
  sum?: Maybe<ComponentLayer_Sum_Order_By>;
  var_pop?: Maybe<ComponentLayer_Var_Pop_Order_By>;
  var_samp?: Maybe<ComponentLayer_Var_Samp_Order_By>;
  variance?: Maybe<ComponentLayer_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ComponentLayer_Avg_Fields = {
  __typename?: 'ComponentLayer_avg_fields';
  LayerId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ComponentLayer" */
export type ComponentLayer_Avg_Order_By = {
  LayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ComponentLayer". All fields are combined with a logical 'AND'. */
export type ComponentLayer_Bool_Exp = {
  C_LayerType?: Maybe<C_LayerType_Bool_Exp>;
  ComponentLayerUsages?: Maybe<ComponentLayerUsage_Bool_Exp>;
  IsPermable?: Maybe<Boolean_Mssql_Comparison_Exp>;
  Layer?: Maybe<Layer_Bool_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  SelfSecurementComments?: Maybe<ItemComment_Mssql_Comparison_Exp>;
  SelfSecurementLayerTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  SelfSecurementUsages?: Maybe<SelfSecurementUsage_Bool_Exp>;
  _and?: Maybe<Array<ComponentLayer_Bool_Exp>>;
  _not?: Maybe<ComponentLayer_Bool_Exp>;
  _or?: Maybe<Array<ComponentLayer_Bool_Exp>>;
};

/** upsert condition type for table "ComponentLayer" */
export type ComponentLayer_If_Matched = {
  match_columns?: Array<ComponentLayer_Insert_Match_Column>;
  update_columns?: Array<ComponentLayer_Update_Column>;
  where?: Maybe<ComponentLayer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ComponentLayer" */
export type ComponentLayer_Inc_Input = {
  LayerId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ComponentLayer" */
export type ComponentLayer_Insert_Input = {
  IsPermable?: Maybe<Scalars['Boolean']>;
  LayerId?: Maybe<Scalars['Int']>;
  SelfSecurementComments?: Maybe<Scalars['ItemComment']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ComponentLayer" */
export enum ComponentLayer_Insert_Match_Column {
  /** column name */
  IsPermable = 'IsPermable',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SelfSecurementComments = 'SelfSecurementComments',
  /** column name */
  SelfSecurementLayerTypeId = 'SelfSecurementLayerTypeId'
}

/** aggregate max on columns */
export type ComponentLayer_Max_Fields = {
  __typename?: 'ComponentLayer_max_fields';
  LayerId?: Maybe<Scalars['Int']>;
  SelfSecurementComments?: Maybe<Scalars['ItemComment']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ComponentLayer" */
export type ComponentLayer_Max_Order_By = {
  LayerId?: Maybe<Order_By>;
  SelfSecurementComments?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ComponentLayer_Min_Fields = {
  __typename?: 'ComponentLayer_min_fields';
  LayerId?: Maybe<Scalars['Int']>;
  SelfSecurementComments?: Maybe<Scalars['ItemComment']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ComponentLayer" */
export type ComponentLayer_Min_Order_By = {
  LayerId?: Maybe<Order_By>;
  SelfSecurementComments?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ComponentLayer" */
export type ComponentLayer_Mutation_Response = {
  __typename?: 'ComponentLayer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ComponentLayer>;
};

/** Ordering options when selecting data from "ComponentLayer". */
export type ComponentLayer_Order_By = {
  C_LayerType?: Maybe<C_LayerType_Order_By>;
  ComponentLayerUsages_aggregate?: Maybe<ComponentLayerUsage_Aggregate_Order_By>;
  IsPermable?: Maybe<Order_By>;
  Layer?: Maybe<Layer_Order_By>;
  LayerId?: Maybe<Order_By>;
  SelfSecurementComments?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SelfSecurementUsages_aggregate?: Maybe<SelfSecurementUsage_Aggregate_Order_By>;
};

/** primary key columns input for table: ComponentLayer */
export type ComponentLayer_Pk_Columns_Input = {
  LayerId: Scalars['Int'];
};

/** select columns of table "ComponentLayer" */
export enum ComponentLayer_Select_Column {
  /** column name */
  IsPermable = 'IsPermable',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SelfSecurementComments = 'SelfSecurementComments',
  /** column name */
  SelfSecurementLayerTypeId = 'SelfSecurementLayerTypeId'
}

/** input type for updating data in table "ComponentLayer" */
export type ComponentLayer_Set_Input = {
  IsPermable?: Maybe<Scalars['Boolean']>;
  LayerId?: Maybe<Scalars['Int']>;
  SelfSecurementComments?: Maybe<Scalars['ItemComment']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ComponentLayer_Stddev_Fields = {
  __typename?: 'ComponentLayer_stddev_fields';
  LayerId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ComponentLayer" */
export type ComponentLayer_Stddev_Order_By = {
  LayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ComponentLayer_Stddev_Pop_Fields = {
  __typename?: 'ComponentLayer_stddev_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ComponentLayer" */
export type ComponentLayer_Stddev_Pop_Order_By = {
  LayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ComponentLayer_Stddev_Samp_Fields = {
  __typename?: 'ComponentLayer_stddev_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ComponentLayer" */
export type ComponentLayer_Stddev_Samp_Order_By = {
  LayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ComponentLayer_Sum_Fields = {
  __typename?: 'ComponentLayer_sum_fields';
  LayerId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ComponentLayer" */
export type ComponentLayer_Sum_Order_By = {
  LayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
};

/** update columns of table "ComponentLayer" */
export enum ComponentLayer_Update_Column {
  /** column name */
  IsPermable = 'IsPermable',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SelfSecurementComments = 'SelfSecurementComments',
  /** column name */
  SelfSecurementLayerTypeId = 'SelfSecurementLayerTypeId'
}

/** aggregate var_pop on columns */
export type ComponentLayer_Var_Pop_Fields = {
  __typename?: 'ComponentLayer_var_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ComponentLayer" */
export type ComponentLayer_Var_Pop_Order_By = {
  LayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ComponentLayer_Var_Samp_Fields = {
  __typename?: 'ComponentLayer_var_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ComponentLayer" */
export type ComponentLayer_Var_Samp_Order_By = {
  LayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ComponentLayer_Variance_Fields = {
  __typename?: 'ComponentLayer_variance_fields';
  LayerId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ComponentLayer" */
export type ComponentLayer_Variance_Order_By = {
  LayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
};

/** aggregated selection of "Component" */
export type Component_Aggregate = {
  __typename?: 'Component_aggregate';
  aggregate?: Maybe<Component_Aggregate_Fields>;
  nodes: Array<Component>;
};

/** aggregate fields of "Component" */
export type Component_Aggregate_Fields = {
  __typename?: 'Component_aggregate_fields';
  avg?: Maybe<Component_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Component_Max_Fields>;
  min?: Maybe<Component_Min_Fields>;
  stddev?: Maybe<Component_Stddev_Fields>;
  stddev_pop?: Maybe<Component_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Component_Stddev_Samp_Fields>;
  sum?: Maybe<Component_Sum_Fields>;
  var_pop?: Maybe<Component_Var_Pop_Fields>;
  var_samp?: Maybe<Component_Var_Samp_Fields>;
  variance?: Maybe<Component_Variance_Fields>;
};


/** aggregate fields of "Component" */
export type Component_Aggregate_FieldsCountArgs = {
  column?: Maybe<Component_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Component" */
export type Component_Aggregate_Order_By = {
  avg?: Maybe<Component_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Component_Max_Order_By>;
  min?: Maybe<Component_Min_Order_By>;
  stddev?: Maybe<Component_Stddev_Order_By>;
  stddev_pop?: Maybe<Component_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Component_Stddev_Samp_Order_By>;
  sum?: Maybe<Component_Sum_Order_By>;
  var_pop?: Maybe<Component_Var_Pop_Order_By>;
  var_samp?: Maybe<Component_Var_Samp_Order_By>;
  variance?: Maybe<Component_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Component_Avg_Fields = {
  __typename?: 'Component_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentTypeId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Component" */
export type Component_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Component". All fields are combined with a logical 'AND'. */
export type Component_Bool_Exp = {
  C_ComponentType?: Maybe<C_ComponentType_Bool_Exp>;
  C_Status?: Maybe<C_Status_Bool_Exp>;
  Comments?: Maybe<ItemComment_Mssql_Comparison_Exp>;
  ComponentApprovedUses?: Maybe<ComponentApprovedUse_Bool_Exp>;
  ComponentDocs?: Maybe<ComponentDoc_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  CreatedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateCreated?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateEffective?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateInception?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  FMUserAccount?: Maybe<FmUserAccount_Bool_Exp>;
  LapSealantUsages?: Maybe<LapSealantUsage_Bool_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  R_SinglePly_BondingAdhesives?: Maybe<R_SinglePly_BondingAdhesives_Bool_Exp>;
  R_SinglePly_LapAdhesives?: Maybe<R_SinglePly_LapAdhesives_Bool_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  StructuredSystem?: Maybe<StructuredSystem_Bool_Exp>;
  Subassembly?: Maybe<Subassembly_Bool_Exp>;
  Usages?: Maybe<Usage_Bool_Exp>;
  VersionMajor?: Maybe<Int_Mssql_Comparison_Exp>;
  VersionMinor?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Component_Bool_Exp>>;
  _not?: Maybe<Component_Bool_Exp>;
  _or?: Maybe<Array<Component_Bool_Exp>>;
  fMUserAccountByModifiedbyuseraccountid?: Maybe<FmUserAccount_Bool_Exp>;
};

/** upsert condition type for table "Component" */
export type Component_If_Matched = {
  match_columns?: Array<Component_Insert_Match_Column>;
  update_columns?: Array<Component_Update_Column>;
  where?: Maybe<Component_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Component" */
export type Component_Inc_Input = {
  ComponentTypeId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Component" */
export type Component_Insert_Input = {
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateInception?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "Component" */
export enum Component_Insert_Match_Column {
  /** column name */
  Comments = 'Comments',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentTypeId = 'ComponentTypeId',
  /** column name */
  CreatedByUserAccountId = 'CreatedByUserAccountId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  DateEffective = 'DateEffective',
  /** column name */
  DateInception = 'DateInception',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  VersionMajor = 'VersionMajor',
  /** column name */
  VersionMinor = 'VersionMinor'
}

/** aggregate max on columns */
export type Component_Max_Fields = {
  __typename?: 'Component_max_fields';
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateInception?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Component" */
export type Component_Max_Order_By = {
  Comments?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  DateEffective?: Maybe<Order_By>;
  DateInception?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Component_Min_Fields = {
  __typename?: 'Component_min_fields';
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateInception?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Component" */
export type Component_Min_Order_By = {
  Comments?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  DateEffective?: Maybe<Order_By>;
  DateInception?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** response of any mutation on the table "Component" */
export type Component_Mutation_Response = {
  __typename?: 'Component_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Component>;
};

/** Ordering options when selecting data from "Component". */
export type Component_Order_By = {
  C_ComponentType?: Maybe<C_ComponentType_Order_By>;
  C_Status?: Maybe<C_Status_Order_By>;
  Comments?: Maybe<Order_By>;
  ComponentApprovedUses_aggregate?: Maybe<ComponentApprovedUse_Aggregate_Order_By>;
  ComponentDocs_aggregate?: Maybe<ComponentDoc_Aggregate_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  DateEffective?: Maybe<Order_By>;
  DateInception?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  FMUserAccount?: Maybe<FmUserAccount_Order_By>;
  LapSealantUsages_aggregate?: Maybe<LapSealantUsage_Aggregate_Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  R_SinglePly_BondingAdhesives_aggregate?: Maybe<R_SinglePly_BondingAdhesives_Aggregate_Order_By>;
  R_SinglePly_LapAdhesives_aggregate?: Maybe<R_SinglePly_LapAdhesives_Aggregate_Order_By>;
  StatusId?: Maybe<Order_By>;
  StructuredSystem?: Maybe<StructuredSystem_Order_By>;
  Subassembly?: Maybe<Subassembly_Order_By>;
  Usages_aggregate?: Maybe<Usage_Aggregate_Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  fMUserAccountByModifiedbyuseraccountid?: Maybe<FmUserAccount_Order_By>;
};

/** primary key columns input for table: Component */
export type Component_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "Component" */
export enum Component_Select_Column {
  /** column name */
  Comments = 'Comments',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ComponentTypeId = 'ComponentTypeId',
  /** column name */
  CreatedByUserAccountId = 'CreatedByUserAccountId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  DateEffective = 'DateEffective',
  /** column name */
  DateInception = 'DateInception',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  VersionMajor = 'VersionMajor',
  /** column name */
  VersionMinor = 'VersionMinor'
}

/** input type for updating data in table "Component" */
export type Component_Set_Input = {
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateInception?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Component_Stddev_Fields = {
  __typename?: 'Component_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentTypeId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Component" */
export type Component_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Component_Stddev_Pop_Fields = {
  __typename?: 'Component_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentTypeId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Component" */
export type Component_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Component_Stddev_Samp_Fields = {
  __typename?: 'Component_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentTypeId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Component" */
export type Component_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Component_Sum_Fields = {
  __typename?: 'Component_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Component" */
export type Component_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** update columns of table "Component" */
export enum Component_Update_Column {
  /** column name */
  Comments = 'Comments',
  /** column name */
  ComponentTypeId = 'ComponentTypeId',
  /** column name */
  CreatedByUserAccountId = 'CreatedByUserAccountId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  DateEffective = 'DateEffective',
  /** column name */
  DateInception = 'DateInception',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  VersionMajor = 'VersionMajor',
  /** column name */
  VersionMinor = 'VersionMinor'
}

/** aggregate var_pop on columns */
export type Component_Var_Pop_Fields = {
  __typename?: 'Component_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentTypeId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Component" */
export type Component_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Component_Var_Samp_Fields = {
  __typename?: 'Component_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentTypeId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Component" */
export type Component_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Component_Variance_Fields = {
  __typename?: 'Component_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ComponentTypeId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Component" */
export type Component_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** columns and relationships of "CompositePanelCover" */
export type CompositePanelCover = {
  __typename?: 'CompositePanelCover';
  BottomFacerMaterialId: Scalars['Int'];
  /** An object relationship */
  C_FacerMaterial: C_FacerMaterial;
  /** An object relationship */
  C_InsulationCore: C_InsulationCore;
  ComponentId: Scalars['Int'];
  FacerThicknessBottom: Scalars['MeasurementValue'];
  FacerThicknessTop: Scalars['MeasurementValue'];
  InsulationCoreId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  ThicknessMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  TopFacerMaterialId: Scalars['Int'];
  WidthMax: Scalars['MeasurementValue'];
  WidthMin: Scalars['MeasurementValue'];
  /** An object relationship */
  cFacermaterialByBottomfacermaterialid: C_FacerMaterial;
};

/** aggregated selection of "CompositePanelCover" */
export type CompositePanelCover_Aggregate = {
  __typename?: 'CompositePanelCover_aggregate';
  aggregate?: Maybe<CompositePanelCover_Aggregate_Fields>;
  nodes: Array<CompositePanelCover>;
};

/** aggregate fields of "CompositePanelCover" */
export type CompositePanelCover_Aggregate_Fields = {
  __typename?: 'CompositePanelCover_aggregate_fields';
  avg?: Maybe<CompositePanelCover_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CompositePanelCover_Max_Fields>;
  min?: Maybe<CompositePanelCover_Min_Fields>;
  stddev?: Maybe<CompositePanelCover_Stddev_Fields>;
  stddev_pop?: Maybe<CompositePanelCover_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CompositePanelCover_Stddev_Samp_Fields>;
  sum?: Maybe<CompositePanelCover_Sum_Fields>;
  var_pop?: Maybe<CompositePanelCover_Var_Pop_Fields>;
  var_samp?: Maybe<CompositePanelCover_Var_Samp_Fields>;
  variance?: Maybe<CompositePanelCover_Variance_Fields>;
};


/** aggregate fields of "CompositePanelCover" */
export type CompositePanelCover_Aggregate_FieldsCountArgs = {
  column?: Maybe<CompositePanelCover_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CompositePanelCover" */
export type CompositePanelCover_Aggregate_Order_By = {
  avg?: Maybe<CompositePanelCover_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<CompositePanelCover_Max_Order_By>;
  min?: Maybe<CompositePanelCover_Min_Order_By>;
  stddev?: Maybe<CompositePanelCover_Stddev_Order_By>;
  stddev_pop?: Maybe<CompositePanelCover_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<CompositePanelCover_Stddev_Samp_Order_By>;
  sum?: Maybe<CompositePanelCover_Sum_Order_By>;
  var_pop?: Maybe<CompositePanelCover_Var_Pop_Order_By>;
  var_samp?: Maybe<CompositePanelCover_Var_Samp_Order_By>;
  variance?: Maybe<CompositePanelCover_Variance_Order_By>;
};

/** aggregate avg on columns */
export type CompositePanelCover_Avg_Fields = {
  __typename?: 'CompositePanelCover_avg_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CompositePanelCover" */
export type CompositePanelCover_Avg_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CompositePanelCover". All fields are combined with a logical 'AND'. */
export type CompositePanelCover_Bool_Exp = {
  BottomFacerMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_FacerMaterial?: Maybe<C_FacerMaterial_Bool_Exp>;
  C_InsulationCore?: Maybe<C_InsulationCore_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  FacerThicknessBottom?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  FacerThicknessTop?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  InsulationCoreId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  ThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  TopFacerMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  WidthMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  WidthMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CompositePanelCover_Bool_Exp>>;
  _not?: Maybe<CompositePanelCover_Bool_Exp>;
  _or?: Maybe<Array<CompositePanelCover_Bool_Exp>>;
  cFacermaterialByBottomfacermaterialid?: Maybe<C_FacerMaterial_Bool_Exp>;
};

/** upsert condition type for table "CompositePanelCover" */
export type CompositePanelCover_If_Matched = {
  match_columns?: Array<CompositePanelCover_Insert_Match_Column>;
  update_columns?: Array<CompositePanelCover_Update_Column>;
  where?: Maybe<CompositePanelCover_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CompositePanelCover" */
export type CompositePanelCover_Inc_Input = {
  BottomFacerMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  InsulationCoreId?: Maybe<Scalars['Int']>;
  TopFacerMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CompositePanelCover" */
export type CompositePanelCover_Insert_Input = {
  BottomFacerMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  FacerThicknessBottom?: Maybe<Scalars['MeasurementValue']>;
  FacerThicknessTop?: Maybe<Scalars['MeasurementValue']>;
  InsulationCoreId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  TopFacerMaterialId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
  WidthMin?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "CompositePanelCover" */
export enum CompositePanelCover_Insert_Match_Column {
  /** column name */
  BottomFacerMaterialId = 'BottomFacerMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerThicknessBottom = 'FacerThicknessBottom',
  /** column name */
  FacerThicknessTop = 'FacerThicknessTop',
  /** column name */
  InsulationCoreId = 'InsulationCoreId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TopFacerMaterialId = 'TopFacerMaterialId',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** aggregate max on columns */
export type CompositePanelCover_Max_Fields = {
  __typename?: 'CompositePanelCover_max_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  FacerThicknessBottom?: Maybe<Scalars['MeasurementValue']>;
  FacerThicknessTop?: Maybe<Scalars['MeasurementValue']>;
  InsulationCoreId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  TopFacerMaterialId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
  WidthMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "CompositePanelCover" */
export type CompositePanelCover_Max_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerThicknessBottom?: Maybe<Order_By>;
  FacerThicknessTop?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CompositePanelCover_Min_Fields = {
  __typename?: 'CompositePanelCover_min_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  FacerThicknessBottom?: Maybe<Scalars['MeasurementValue']>;
  FacerThicknessTop?: Maybe<Scalars['MeasurementValue']>;
  InsulationCoreId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  TopFacerMaterialId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
  WidthMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "CompositePanelCover" */
export type CompositePanelCover_Min_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerThicknessBottom?: Maybe<Order_By>;
  FacerThicknessTop?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** response of any mutation on the table "CompositePanelCover" */
export type CompositePanelCover_Mutation_Response = {
  __typename?: 'CompositePanelCover_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompositePanelCover>;
};

/** Ordering options when selecting data from "CompositePanelCover". */
export type CompositePanelCover_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  C_FacerMaterial?: Maybe<C_FacerMaterial_Order_By>;
  C_InsulationCore?: Maybe<C_InsulationCore_Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerThicknessBottom?: Maybe<Order_By>;
  FacerThicknessTop?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
  cFacermaterialByBottomfacermaterialid?: Maybe<C_FacerMaterial_Order_By>;
};

/** primary key columns input for table: CompositePanelCover */
export type CompositePanelCover_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "CompositePanelCover" */
export enum CompositePanelCover_Select_Column {
  /** column name */
  BottomFacerMaterialId = 'BottomFacerMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerThicknessBottom = 'FacerThicknessBottom',
  /** column name */
  FacerThicknessTop = 'FacerThicknessTop',
  /** column name */
  InsulationCoreId = 'InsulationCoreId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TopFacerMaterialId = 'TopFacerMaterialId',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** input type for updating data in table "CompositePanelCover" */
export type CompositePanelCover_Set_Input = {
  BottomFacerMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  FacerThicknessBottom?: Maybe<Scalars['MeasurementValue']>;
  FacerThicknessTop?: Maybe<Scalars['MeasurementValue']>;
  InsulationCoreId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  TopFacerMaterialId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
  WidthMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type CompositePanelCover_Stddev_Fields = {
  __typename?: 'CompositePanelCover_stddev_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CompositePanelCover" */
export type CompositePanelCover_Stddev_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CompositePanelCover_Stddev_Pop_Fields = {
  __typename?: 'CompositePanelCover_stddev_pop_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CompositePanelCover" */
export type CompositePanelCover_Stddev_Pop_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CompositePanelCover_Stddev_Samp_Fields = {
  __typename?: 'CompositePanelCover_stddev_samp_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CompositePanelCover" */
export type CompositePanelCover_Stddev_Samp_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type CompositePanelCover_Sum_Fields = {
  __typename?: 'CompositePanelCover_sum_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  InsulationCoreId?: Maybe<Scalars['Int']>;
  TopFacerMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CompositePanelCover" */
export type CompositePanelCover_Sum_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
};

/** update columns of table "CompositePanelCover" */
export enum CompositePanelCover_Update_Column {
  /** column name */
  BottomFacerMaterialId = 'BottomFacerMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerThicknessBottom = 'FacerThicknessBottom',
  /** column name */
  FacerThicknessTop = 'FacerThicknessTop',
  /** column name */
  InsulationCoreId = 'InsulationCoreId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TopFacerMaterialId = 'TopFacerMaterialId',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** aggregate var_pop on columns */
export type CompositePanelCover_Var_Pop_Fields = {
  __typename?: 'CompositePanelCover_var_pop_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CompositePanelCover" */
export type CompositePanelCover_Var_Pop_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CompositePanelCover_Var_Samp_Fields = {
  __typename?: 'CompositePanelCover_var_samp_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CompositePanelCover" */
export type CompositePanelCover_Var_Samp_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type CompositePanelCover_Variance_Fields = {
  __typename?: 'CompositePanelCover_variance_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CompositePanelCover" */
export type CompositePanelCover_Variance_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "CompositePanelUsage" */
export type CompositePanelUsage = {
  __typename?: 'CompositePanelUsage';
  ThicknessMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
  WidthMax: Scalars['MeasurementValue'];
};

/** aggregated selection of "CompositePanelUsage" */
export type CompositePanelUsage_Aggregate = {
  __typename?: 'CompositePanelUsage_aggregate';
  aggregate?: Maybe<CompositePanelUsage_Aggregate_Fields>;
  nodes: Array<CompositePanelUsage>;
};

/** aggregate fields of "CompositePanelUsage" */
export type CompositePanelUsage_Aggregate_Fields = {
  __typename?: 'CompositePanelUsage_aggregate_fields';
  avg?: Maybe<CompositePanelUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CompositePanelUsage_Max_Fields>;
  min?: Maybe<CompositePanelUsage_Min_Fields>;
  stddev?: Maybe<CompositePanelUsage_Stddev_Fields>;
  stddev_pop?: Maybe<CompositePanelUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CompositePanelUsage_Stddev_Samp_Fields>;
  sum?: Maybe<CompositePanelUsage_Sum_Fields>;
  var_pop?: Maybe<CompositePanelUsage_Var_Pop_Fields>;
  var_samp?: Maybe<CompositePanelUsage_Var_Samp_Fields>;
  variance?: Maybe<CompositePanelUsage_Variance_Fields>;
};


/** aggregate fields of "CompositePanelUsage" */
export type CompositePanelUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<CompositePanelUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CompositePanelUsage_Avg_Fields = {
  __typename?: 'CompositePanelUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CompositePanelUsage". All fields are combined with a logical 'AND'. */
export type CompositePanelUsage_Bool_Exp = {
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  WidthMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CompositePanelUsage_Bool_Exp>>;
  _not?: Maybe<CompositePanelUsage_Bool_Exp>;
  _or?: Maybe<Array<CompositePanelUsage_Bool_Exp>>;
};

/** upsert condition type for table "CompositePanelUsage" */
export type CompositePanelUsage_If_Matched = {
  match_columns?: Array<CompositePanelUsage_Insert_Match_Column>;
  update_columns?: Array<CompositePanelUsage_Update_Column>;
  where?: Maybe<CompositePanelUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CompositePanelUsage" */
export type CompositePanelUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CompositePanelUsage" */
export type CompositePanelUsage_Insert_Input = {
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "CompositePanelUsage" */
export enum CompositePanelUsage_Insert_Match_Column {
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax'
}

/** aggregate max on columns */
export type CompositePanelUsage_Max_Fields = {
  __typename?: 'CompositePanelUsage_max_fields';
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate min on columns */
export type CompositePanelUsage_Min_Fields = {
  __typename?: 'CompositePanelUsage_min_fields';
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** response of any mutation on the table "CompositePanelUsage" */
export type CompositePanelUsage_Mutation_Response = {
  __typename?: 'CompositePanelUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompositePanelUsage>;
};

/** Ordering options when selecting data from "CompositePanelUsage". */
export type CompositePanelUsage_Order_By = {
  ThicknessMin?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** primary key columns input for table: CompositePanelUsage */
export type CompositePanelUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "CompositePanelUsage" */
export enum CompositePanelUsage_Select_Column {
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax'
}

/** input type for updating data in table "CompositePanelUsage" */
export type CompositePanelUsage_Set_Input = {
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type CompositePanelUsage_Stddev_Fields = {
  __typename?: 'CompositePanelUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CompositePanelUsage_Stddev_Pop_Fields = {
  __typename?: 'CompositePanelUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CompositePanelUsage_Stddev_Samp_Fields = {
  __typename?: 'CompositePanelUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CompositePanelUsage_Sum_Fields = {
  __typename?: 'CompositePanelUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "CompositePanelUsage" */
export enum CompositePanelUsage_Update_Column {
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax'
}

/** aggregate var_pop on columns */
export type CompositePanelUsage_Var_Pop_Fields = {
  __typename?: 'CompositePanelUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CompositePanelUsage_Var_Samp_Fields = {
  __typename?: 'CompositePanelUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CompositePanelUsage_Variance_Fields = {
  __typename?: 'CompositePanelUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

export type ContactDetail = {
  Address?: Maybe<AddressInfo>;
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  ContactInfoId?: Maybe<Scalars['Int']>;
  Email?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  IndustryTypeId?: Maybe<Scalars['Int']>;
  Initial?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  Phone?: Maybe<Scalars['String']>;
};

/** columns and relationships of "ContactInfo" */
export type ContactInfo = {
  __typename?: 'ContactInfo';
  /** An object relationship */
  Address?: Maybe<Address>;
  AddressId?: Maybe<Scalars['Int']>;
  CompanyName: Scalars['String'];
  ContactInfoId: Scalars['Int'];
  Email: Scalars['String'];
  Fax: Scalars['String'];
  FirstName: Scalars['String'];
  IndustryTypeId: Scalars['Int'];
  Initial: Scalars['String'];
  LastName: Scalars['String'];
  OldContactId?: Maybe<Scalars['Int']>;
  Phone: Scalars['String'];
};

/** aggregated selection of "ContactInfo" */
export type ContactInfo_Aggregate = {
  __typename?: 'ContactInfo_aggregate';
  aggregate?: Maybe<ContactInfo_Aggregate_Fields>;
  nodes: Array<ContactInfo>;
};

/** aggregate fields of "ContactInfo" */
export type ContactInfo_Aggregate_Fields = {
  __typename?: 'ContactInfo_aggregate_fields';
  avg?: Maybe<ContactInfo_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ContactInfo_Max_Fields>;
  min?: Maybe<ContactInfo_Min_Fields>;
  stddev?: Maybe<ContactInfo_Stddev_Fields>;
  stddev_pop?: Maybe<ContactInfo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ContactInfo_Stddev_Samp_Fields>;
  sum?: Maybe<ContactInfo_Sum_Fields>;
  var_pop?: Maybe<ContactInfo_Var_Pop_Fields>;
  var_samp?: Maybe<ContactInfo_Var_Samp_Fields>;
  variance?: Maybe<ContactInfo_Variance_Fields>;
};


/** aggregate fields of "ContactInfo" */
export type ContactInfo_Aggregate_FieldsCountArgs = {
  column?: Maybe<ContactInfo_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ContactInfo_Avg_Fields = {
  __typename?: 'ContactInfo_avg_fields';
  AddressId?: Maybe<Scalars['Float']>;
  ContactInfoId?: Maybe<Scalars['Float']>;
  IndustryTypeId?: Maybe<Scalars['Float']>;
  OldContactId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ContactInfo". All fields are combined with a logical 'AND'. */
export type ContactInfo_Bool_Exp = {
  Address?: Maybe<Address_Bool_Exp>;
  AddressId?: Maybe<Int_Mssql_Comparison_Exp>;
  CompanyName?: Maybe<String_Mssql_Comparison_Exp>;
  ContactInfoId?: Maybe<Int_Mssql_Comparison_Exp>;
  Email?: Maybe<String_Mssql_Comparison_Exp>;
  Fax?: Maybe<String_Mssql_Comparison_Exp>;
  FirstName?: Maybe<String_Mssql_Comparison_Exp>;
  IndustryTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Initial?: Maybe<String_Mssql_Comparison_Exp>;
  LastName?: Maybe<String_Mssql_Comparison_Exp>;
  OldContactId?: Maybe<Int_Mssql_Comparison_Exp>;
  Phone?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ContactInfo_Bool_Exp>>;
  _not?: Maybe<ContactInfo_Bool_Exp>;
  _or?: Maybe<Array<ContactInfo_Bool_Exp>>;
};

/** upsert condition type for table "ContactInfo" */
export type ContactInfo_If_Matched = {
  match_columns?: Array<ContactInfo_Insert_Match_Column>;
  update_columns?: Array<ContactInfo_Update_Column>;
  where?: Maybe<ContactInfo_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ContactInfo" */
export type ContactInfo_Inc_Input = {
  AddressId?: Maybe<Scalars['Int']>;
  IndustryTypeId?: Maybe<Scalars['Int']>;
  OldContactId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ContactInfo" */
export type ContactInfo_Insert_Input = {
  AddressId?: Maybe<Scalars['Int']>;
  CompanyName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  IndustryTypeId?: Maybe<Scalars['Int']>;
  Initial?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  OldContactId?: Maybe<Scalars['Int']>;
  Phone?: Maybe<Scalars['String']>;
};

/** select match_columns of table "ContactInfo" */
export enum ContactInfo_Insert_Match_Column {
  /** column name */
  AddressId = 'AddressId',
  /** column name */
  CompanyName = 'CompanyName',
  /** column name */
  ContactInfoId = 'ContactInfoId',
  /** column name */
  Email = 'Email',
  /** column name */
  Fax = 'Fax',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  IndustryTypeId = 'IndustryTypeId',
  /** column name */
  Initial = 'Initial',
  /** column name */
  LastName = 'LastName',
  /** column name */
  OldContactId = 'OldContactId',
  /** column name */
  Phone = 'Phone'
}

/** aggregate max on columns */
export type ContactInfo_Max_Fields = {
  __typename?: 'ContactInfo_max_fields';
  AddressId?: Maybe<Scalars['Int']>;
  CompanyName?: Maybe<Scalars['String']>;
  ContactInfoId?: Maybe<Scalars['Int']>;
  Email?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  IndustryTypeId?: Maybe<Scalars['Int']>;
  Initial?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  OldContactId?: Maybe<Scalars['Int']>;
  Phone?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ContactInfo_Min_Fields = {
  __typename?: 'ContactInfo_min_fields';
  AddressId?: Maybe<Scalars['Int']>;
  CompanyName?: Maybe<Scalars['String']>;
  ContactInfoId?: Maybe<Scalars['Int']>;
  Email?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  IndustryTypeId?: Maybe<Scalars['Int']>;
  Initial?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  OldContactId?: Maybe<Scalars['Int']>;
  Phone?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ContactInfo" */
export type ContactInfo_Mutation_Response = {
  __typename?: 'ContactInfo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContactInfo>;
};

/** Ordering options when selecting data from "ContactInfo". */
export type ContactInfo_Order_By = {
  Address?: Maybe<Address_Order_By>;
  AddressId?: Maybe<Order_By>;
  CompanyName?: Maybe<Order_By>;
  ContactInfoId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  IndustryTypeId?: Maybe<Order_By>;
  Initial?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  OldContactId?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
};

/** primary key columns input for table: ContactInfo */
export type ContactInfo_Pk_Columns_Input = {
  ContactInfoId: Scalars['Int'];
};

/** select columns of table "ContactInfo" */
export enum ContactInfo_Select_Column {
  /** column name */
  AddressId = 'AddressId',
  /** column name */
  CompanyName = 'CompanyName',
  /** column name */
  ContactInfoId = 'ContactInfoId',
  /** column name */
  Email = 'Email',
  /** column name */
  Fax = 'Fax',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  IndustryTypeId = 'IndustryTypeId',
  /** column name */
  Initial = 'Initial',
  /** column name */
  LastName = 'LastName',
  /** column name */
  OldContactId = 'OldContactId',
  /** column name */
  Phone = 'Phone'
}

/** input type for updating data in table "ContactInfo" */
export type ContactInfo_Set_Input = {
  AddressId?: Maybe<Scalars['Int']>;
  CompanyName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  IndustryTypeId?: Maybe<Scalars['Int']>;
  Initial?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  OldContactId?: Maybe<Scalars['Int']>;
  Phone?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ContactInfo_Stddev_Fields = {
  __typename?: 'ContactInfo_stddev_fields';
  AddressId?: Maybe<Scalars['Float']>;
  ContactInfoId?: Maybe<Scalars['Float']>;
  IndustryTypeId?: Maybe<Scalars['Float']>;
  OldContactId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ContactInfo_Stddev_Pop_Fields = {
  __typename?: 'ContactInfo_stddev_pop_fields';
  AddressId?: Maybe<Scalars['Float']>;
  ContactInfoId?: Maybe<Scalars['Float']>;
  IndustryTypeId?: Maybe<Scalars['Float']>;
  OldContactId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ContactInfo_Stddev_Samp_Fields = {
  __typename?: 'ContactInfo_stddev_samp_fields';
  AddressId?: Maybe<Scalars['Float']>;
  ContactInfoId?: Maybe<Scalars['Float']>;
  IndustryTypeId?: Maybe<Scalars['Float']>;
  OldContactId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ContactInfo_Sum_Fields = {
  __typename?: 'ContactInfo_sum_fields';
  AddressId?: Maybe<Scalars['Int']>;
  ContactInfoId?: Maybe<Scalars['Int']>;
  IndustryTypeId?: Maybe<Scalars['Int']>;
  OldContactId?: Maybe<Scalars['Int']>;
};

/** update columns of table "ContactInfo" */
export enum ContactInfo_Update_Column {
  /** column name */
  AddressId = 'AddressId',
  /** column name */
  CompanyName = 'CompanyName',
  /** column name */
  Email = 'Email',
  /** column name */
  Fax = 'Fax',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  IndustryTypeId = 'IndustryTypeId',
  /** column name */
  Initial = 'Initial',
  /** column name */
  LastName = 'LastName',
  /** column name */
  OldContactId = 'OldContactId',
  /** column name */
  Phone = 'Phone'
}

/** aggregate var_pop on columns */
export type ContactInfo_Var_Pop_Fields = {
  __typename?: 'ContactInfo_var_pop_fields';
  AddressId?: Maybe<Scalars['Float']>;
  ContactInfoId?: Maybe<Scalars['Float']>;
  IndustryTypeId?: Maybe<Scalars['Float']>;
  OldContactId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ContactInfo_Var_Samp_Fields = {
  __typename?: 'ContactInfo_var_samp_fields';
  AddressId?: Maybe<Scalars['Float']>;
  ContactInfoId?: Maybe<Scalars['Float']>;
  IndustryTypeId?: Maybe<Scalars['Float']>;
  OldContactId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ContactInfo_Variance_Fields = {
  __typename?: 'ContactInfo_variance_fields';
  AddressId?: Maybe<Scalars['Float']>;
  ContactInfoId?: Maybe<Scalars['Float']>;
  IndustryTypeId?: Maybe<Scalars['Float']>;
  OldContactId?: Maybe<Scalars['Float']>;
};

export type ContactSaveResult = {
  __typename?: 'ContactSaveResult';
  ContactInfoId: Scalars['Int'];
};

/** columns and relationships of "Coping" */
export type Coping = {
  __typename?: 'Coping';
  /** An object relationship */
  C_FlashingMaterial: C_FlashingMaterial;
  ComponentId: Scalars['Int'];
  FlashingMaterialId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  MaxWindRating: Scalars['Float'];
  /** An array relationship */
  R_CopingFasteners: Array<R_CopingFastener>;
  /** An aggregate relationship */
  R_CopingFasteners_aggregate: R_CopingFastener_Aggregate;
};


/** columns and relationships of "Coping" */
export type CopingR_CopingFastenersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_CopingFastener_Order_By>>;
  where?: Maybe<R_CopingFastener_Bool_Exp>;
};


/** columns and relationships of "Coping" */
export type CopingR_CopingFasteners_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_CopingFastener_Order_By>>;
  where?: Maybe<R_CopingFastener_Bool_Exp>;
};

/** aggregated selection of "Coping" */
export type Coping_Aggregate = {
  __typename?: 'Coping_aggregate';
  aggregate?: Maybe<Coping_Aggregate_Fields>;
  nodes: Array<Coping>;
};

/** aggregate fields of "Coping" */
export type Coping_Aggregate_Fields = {
  __typename?: 'Coping_aggregate_fields';
  avg?: Maybe<Coping_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Coping_Max_Fields>;
  min?: Maybe<Coping_Min_Fields>;
  stddev?: Maybe<Coping_Stddev_Fields>;
  stddev_pop?: Maybe<Coping_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Coping_Stddev_Samp_Fields>;
  sum?: Maybe<Coping_Sum_Fields>;
  var_pop?: Maybe<Coping_Var_Pop_Fields>;
  var_samp?: Maybe<Coping_Var_Samp_Fields>;
  variance?: Maybe<Coping_Variance_Fields>;
};


/** aggregate fields of "Coping" */
export type Coping_Aggregate_FieldsCountArgs = {
  column?: Maybe<Coping_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Coping" */
export type Coping_Aggregate_Order_By = {
  avg?: Maybe<Coping_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Coping_Max_Order_By>;
  min?: Maybe<Coping_Min_Order_By>;
  stddev?: Maybe<Coping_Stddev_Order_By>;
  stddev_pop?: Maybe<Coping_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Coping_Stddev_Samp_Order_By>;
  sum?: Maybe<Coping_Sum_Order_By>;
  var_pop?: Maybe<Coping_Var_Pop_Order_By>;
  var_samp?: Maybe<Coping_Var_Samp_Order_By>;
  variance?: Maybe<Coping_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Coping_Avg_Fields = {
  __typename?: 'Coping_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Coping" */
export type Coping_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Coping". All fields are combined with a logical 'AND'. */
export type Coping_Bool_Exp = {
  C_FlashingMaterial?: Maybe<C_FlashingMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  FlashingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  MaxWindRating?: Maybe<Float_Mssql_Comparison_Exp>;
  R_CopingFasteners?: Maybe<R_CopingFastener_Bool_Exp>;
  _and?: Maybe<Array<Coping_Bool_Exp>>;
  _not?: Maybe<Coping_Bool_Exp>;
  _or?: Maybe<Array<Coping_Bool_Exp>>;
};

/** upsert condition type for table "Coping" */
export type Coping_If_Matched = {
  match_columns?: Array<Coping_Insert_Match_Column>;
  update_columns?: Array<Coping_Update_Column>;
  where?: Maybe<Coping_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Coping" */
export type Coping_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "Coping" */
export type Coping_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "Coping" */
export enum Coping_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FlashingMaterialId = 'FlashingMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** aggregate max on columns */
export type Coping_Max_Fields = {
  __typename?: 'Coping_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "Coping" */
export type Coping_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Coping_Min_Fields = {
  __typename?: 'Coping_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "Coping" */
export type Coping_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** response of any mutation on the table "Coping" */
export type Coping_Mutation_Response = {
  __typename?: 'Coping_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coping>;
};

/** Ordering options when selecting data from "Coping". */
export type Coping_Order_By = {
  C_FlashingMaterial?: Maybe<C_FlashingMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  MaxWindRating?: Maybe<Order_By>;
  R_CopingFasteners_aggregate?: Maybe<R_CopingFastener_Aggregate_Order_By>;
};

/** primary key columns input for table: Coping */
export type Coping_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "Coping" */
export enum Coping_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FlashingMaterialId = 'FlashingMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** input type for updating data in table "Coping" */
export type Coping_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Coping_Stddev_Fields = {
  __typename?: 'Coping_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Coping" */
export type Coping_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Coping_Stddev_Pop_Fields = {
  __typename?: 'Coping_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Coping" */
export type Coping_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Coping_Stddev_Samp_Fields = {
  __typename?: 'Coping_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Coping" */
export type Coping_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Coping_Sum_Fields = {
  __typename?: 'Coping_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "Coping" */
export type Coping_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** update columns of table "Coping" */
export enum Coping_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FlashingMaterialId = 'FlashingMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** aggregate var_pop on columns */
export type Coping_Var_Pop_Fields = {
  __typename?: 'Coping_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Coping" */
export type Coping_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Coping_Var_Samp_Fields = {
  __typename?: 'Coping_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Coping" */
export type Coping_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Coping_Variance_Fields = {
  __typename?: 'Coping_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Coping" */
export type Coping_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** columns and relationships of "CoverBoard" */
export type CoverBoard = {
  __typename?: 'CoverBoard';
  BoardProfileId: Scalars['Int'];
  /** An object relationship */
  C_BoardProfile: C_BoardProfile;
  /** An object relationship */
  C_CoverBoardMaterial: C_CoverBoardMaterial;
  ComponentId: Scalars['Int'];
  /** An array relationship */
  CoverBoardBoardSizes: Array<CoverBoardBoardSizes>;
  /** An aggregate relationship */
  CoverBoardBoardSizes_aggregate: CoverBoardBoardSizes_Aggregate;
  /** An array relationship */
  CoverBoardFacerBottoms: Array<CoverBoardFacerBottom>;
  /** An aggregate relationship */
  CoverBoardFacerBottoms_aggregate: CoverBoardFacerBottom_Aggregate;
  /** An array relationship */
  CoverBoardFacerTops: Array<CoverBoardFacerTop>;
  /** An aggregate relationship */
  CoverBoardFacerTops_aggregate: CoverBoardFacerTop_Aggregate;
  CoverBoardMaterialId: Scalars['Int'];
  DensityMin: Scalars['MeasurementValue'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  ThicknessMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
};


/** columns and relationships of "CoverBoard" */
export type CoverBoardCoverBoardBoardSizesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardBoardSizes_Order_By>>;
  where?: Maybe<CoverBoardBoardSizes_Bool_Exp>;
};


/** columns and relationships of "CoverBoard" */
export type CoverBoardCoverBoardBoardSizes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardBoardSizes_Order_By>>;
  where?: Maybe<CoverBoardBoardSizes_Bool_Exp>;
};


/** columns and relationships of "CoverBoard" */
export type CoverBoardCoverBoardFacerBottomsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerBottom_Order_By>>;
  where?: Maybe<CoverBoardFacerBottom_Bool_Exp>;
};


/** columns and relationships of "CoverBoard" */
export type CoverBoardCoverBoardFacerBottoms_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerBottom_Order_By>>;
  where?: Maybe<CoverBoardFacerBottom_Bool_Exp>;
};


/** columns and relationships of "CoverBoard" */
export type CoverBoardCoverBoardFacerTopsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerTop_Order_By>>;
  where?: Maybe<CoverBoardFacerTop_Bool_Exp>;
};


/** columns and relationships of "CoverBoard" */
export type CoverBoardCoverBoardFacerTops_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerTop_Order_By>>;
  where?: Maybe<CoverBoardFacerTop_Bool_Exp>;
};

/** columns and relationships of "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes = {
  __typename?: 'CoverBoardBoardSizes';
  ComponentId: Scalars['Int'];
  /** An object relationship */
  CoverBoard: CoverBoard;
  /** An object relationship */
  InsBoardSize: InsBoardSize;
  InsBoardSizeId: Scalars['Int'];
};

/** aggregated selection of "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Aggregate = {
  __typename?: 'CoverBoardBoardSizes_aggregate';
  aggregate?: Maybe<CoverBoardBoardSizes_Aggregate_Fields>;
  nodes: Array<CoverBoardBoardSizes>;
};

/** aggregate fields of "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Aggregate_Fields = {
  __typename?: 'CoverBoardBoardSizes_aggregate_fields';
  avg?: Maybe<CoverBoardBoardSizes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CoverBoardBoardSizes_Max_Fields>;
  min?: Maybe<CoverBoardBoardSizes_Min_Fields>;
  stddev?: Maybe<CoverBoardBoardSizes_Stddev_Fields>;
  stddev_pop?: Maybe<CoverBoardBoardSizes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CoverBoardBoardSizes_Stddev_Samp_Fields>;
  sum?: Maybe<CoverBoardBoardSizes_Sum_Fields>;
  var_pop?: Maybe<CoverBoardBoardSizes_Var_Pop_Fields>;
  var_samp?: Maybe<CoverBoardBoardSizes_Var_Samp_Fields>;
  variance?: Maybe<CoverBoardBoardSizes_Variance_Fields>;
};


/** aggregate fields of "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Aggregate_FieldsCountArgs = {
  column?: Maybe<CoverBoardBoardSizes_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Aggregate_Order_By = {
  avg?: Maybe<CoverBoardBoardSizes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<CoverBoardBoardSizes_Max_Order_By>;
  min?: Maybe<CoverBoardBoardSizes_Min_Order_By>;
  stddev?: Maybe<CoverBoardBoardSizes_Stddev_Order_By>;
  stddev_pop?: Maybe<CoverBoardBoardSizes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<CoverBoardBoardSizes_Stddev_Samp_Order_By>;
  sum?: Maybe<CoverBoardBoardSizes_Sum_Order_By>;
  var_pop?: Maybe<CoverBoardBoardSizes_Var_Pop_Order_By>;
  var_samp?: Maybe<CoverBoardBoardSizes_Var_Samp_Order_By>;
  variance?: Maybe<CoverBoardBoardSizes_Variance_Order_By>;
};

/** aggregate avg on columns */
export type CoverBoardBoardSizes_Avg_Fields = {
  __typename?: 'CoverBoardBoardSizes_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CoverBoardBoardSizes". All fields are combined with a logical 'AND'. */
export type CoverBoardBoardSizes_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  CoverBoard?: Maybe<CoverBoard_Bool_Exp>;
  InsBoardSize?: Maybe<InsBoardSize_Bool_Exp>;
  InsBoardSizeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CoverBoardBoardSizes_Bool_Exp>>;
  _not?: Maybe<CoverBoardBoardSizes_Bool_Exp>;
  _or?: Maybe<Array<CoverBoardBoardSizes_Bool_Exp>>;
};

/** upsert condition type for table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_If_Matched = {
  match_columns?: Array<CoverBoardBoardSizes_Insert_Match_Column>;
  update_columns?: Array<CoverBoardBoardSizes_Update_Column>;
  where?: Maybe<CoverBoardBoardSizes_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "CoverBoardBoardSizes" */
export enum CoverBoardBoardSizes_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId'
}

/** aggregate max on columns */
export type CoverBoardBoardSizes_Max_Fields = {
  __typename?: 'CoverBoardBoardSizes_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CoverBoardBoardSizes_Min_Fields = {
  __typename?: 'CoverBoardBoardSizes_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** response of any mutation on the table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Mutation_Response = {
  __typename?: 'CoverBoardBoardSizes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoverBoardBoardSizes>;
};

/** Ordering options when selecting data from "CoverBoardBoardSizes". */
export type CoverBoardBoardSizes_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverBoard?: Maybe<CoverBoard_Order_By>;
  InsBoardSize?: Maybe<InsBoardSize_Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** primary key columns input for table: CoverBoardBoardSizes */
export type CoverBoardBoardSizes_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  InsBoardSizeId: Scalars['Int'];
};

/** select columns of table "CoverBoardBoardSizes" */
export enum CoverBoardBoardSizes_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId'
}

/** input type for updating data in table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CoverBoardBoardSizes_Stddev_Fields = {
  __typename?: 'CoverBoardBoardSizes_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CoverBoardBoardSizes_Stddev_Pop_Fields = {
  __typename?: 'CoverBoardBoardSizes_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CoverBoardBoardSizes_Stddev_Samp_Fields = {
  __typename?: 'CoverBoardBoardSizes_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type CoverBoardBoardSizes_Sum_Fields = {
  __typename?: 'CoverBoardBoardSizes_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** update columns of table "CoverBoardBoardSizes" */
export enum CoverBoardBoardSizes_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId'
}

/** aggregate var_pop on columns */
export type CoverBoardBoardSizes_Var_Pop_Fields = {
  __typename?: 'CoverBoardBoardSizes_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CoverBoardBoardSizes_Var_Samp_Fields = {
  __typename?: 'CoverBoardBoardSizes_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type CoverBoardBoardSizes_Variance_Fields = {
  __typename?: 'CoverBoardBoardSizes_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CoverBoardBoardSizes" */
export type CoverBoardBoardSizes_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** columns and relationships of "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom = {
  __typename?: 'CoverBoardFacerBottom';
  /** An object relationship */
  C_Facer: C_Facer;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  CoverBoard: CoverBoard;
  FacerId: Scalars['Int'];
};

/** aggregated selection of "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Aggregate = {
  __typename?: 'CoverBoardFacerBottom_aggregate';
  aggregate?: Maybe<CoverBoardFacerBottom_Aggregate_Fields>;
  nodes: Array<CoverBoardFacerBottom>;
};

/** aggregate fields of "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Aggregate_Fields = {
  __typename?: 'CoverBoardFacerBottom_aggregate_fields';
  avg?: Maybe<CoverBoardFacerBottom_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CoverBoardFacerBottom_Max_Fields>;
  min?: Maybe<CoverBoardFacerBottom_Min_Fields>;
  stddev?: Maybe<CoverBoardFacerBottom_Stddev_Fields>;
  stddev_pop?: Maybe<CoverBoardFacerBottom_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CoverBoardFacerBottom_Stddev_Samp_Fields>;
  sum?: Maybe<CoverBoardFacerBottom_Sum_Fields>;
  var_pop?: Maybe<CoverBoardFacerBottom_Var_Pop_Fields>;
  var_samp?: Maybe<CoverBoardFacerBottom_Var_Samp_Fields>;
  variance?: Maybe<CoverBoardFacerBottom_Variance_Fields>;
};


/** aggregate fields of "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Aggregate_FieldsCountArgs = {
  column?: Maybe<CoverBoardFacerBottom_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Aggregate_Order_By = {
  avg?: Maybe<CoverBoardFacerBottom_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<CoverBoardFacerBottom_Max_Order_By>;
  min?: Maybe<CoverBoardFacerBottom_Min_Order_By>;
  stddev?: Maybe<CoverBoardFacerBottom_Stddev_Order_By>;
  stddev_pop?: Maybe<CoverBoardFacerBottom_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<CoverBoardFacerBottom_Stddev_Samp_Order_By>;
  sum?: Maybe<CoverBoardFacerBottom_Sum_Order_By>;
  var_pop?: Maybe<CoverBoardFacerBottom_Var_Pop_Order_By>;
  var_samp?: Maybe<CoverBoardFacerBottom_Var_Samp_Order_By>;
  variance?: Maybe<CoverBoardFacerBottom_Variance_Order_By>;
};

/** aggregate avg on columns */
export type CoverBoardFacerBottom_Avg_Fields = {
  __typename?: 'CoverBoardFacerBottom_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CoverBoardFacerBottom". All fields are combined with a logical 'AND'. */
export type CoverBoardFacerBottom_Bool_Exp = {
  C_Facer?: Maybe<C_Facer_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  CoverBoard?: Maybe<CoverBoard_Bool_Exp>;
  FacerId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CoverBoardFacerBottom_Bool_Exp>>;
  _not?: Maybe<CoverBoardFacerBottom_Bool_Exp>;
  _or?: Maybe<Array<CoverBoardFacerBottom_Bool_Exp>>;
};

/** upsert condition type for table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_If_Matched = {
  match_columns?: Array<CoverBoardFacerBottom_Insert_Match_Column>;
  update_columns?: Array<CoverBoardFacerBottom_Update_Column>;
  where?: Maybe<CoverBoardFacerBottom_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "CoverBoardFacerBottom" */
export enum CoverBoardFacerBottom_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** aggregate max on columns */
export type CoverBoardFacerBottom_Max_Fields = {
  __typename?: 'CoverBoardFacerBottom_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CoverBoardFacerBottom_Min_Fields = {
  __typename?: 'CoverBoardFacerBottom_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** response of any mutation on the table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Mutation_Response = {
  __typename?: 'CoverBoardFacerBottom_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoverBoardFacerBottom>;
};

/** Ordering options when selecting data from "CoverBoardFacerBottom". */
export type CoverBoardFacerBottom_Order_By = {
  C_Facer?: Maybe<C_Facer_Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoard?: Maybe<CoverBoard_Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** primary key columns input for table: CoverBoardFacerBottom */
export type CoverBoardFacerBottom_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};

/** select columns of table "CoverBoardFacerBottom" */
export enum CoverBoardFacerBottom_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** input type for updating data in table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CoverBoardFacerBottom_Stddev_Fields = {
  __typename?: 'CoverBoardFacerBottom_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CoverBoardFacerBottom_Stddev_Pop_Fields = {
  __typename?: 'CoverBoardFacerBottom_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CoverBoardFacerBottom_Stddev_Samp_Fields = {
  __typename?: 'CoverBoardFacerBottom_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type CoverBoardFacerBottom_Sum_Fields = {
  __typename?: 'CoverBoardFacerBottom_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** update columns of table "CoverBoardFacerBottom" */
export enum CoverBoardFacerBottom_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** aggregate var_pop on columns */
export type CoverBoardFacerBottom_Var_Pop_Fields = {
  __typename?: 'CoverBoardFacerBottom_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CoverBoardFacerBottom_Var_Samp_Fields = {
  __typename?: 'CoverBoardFacerBottom_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type CoverBoardFacerBottom_Variance_Fields = {
  __typename?: 'CoverBoardFacerBottom_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CoverBoardFacerBottom" */
export type CoverBoardFacerBottom_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** columns and relationships of "CoverBoardFacerTop" */
export type CoverBoardFacerTop = {
  __typename?: 'CoverBoardFacerTop';
  /** An object relationship */
  C_Facer: C_Facer;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  CoverBoard: CoverBoard;
  FacerId: Scalars['Int'];
};

/** aggregated selection of "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Aggregate = {
  __typename?: 'CoverBoardFacerTop_aggregate';
  aggregate?: Maybe<CoverBoardFacerTop_Aggregate_Fields>;
  nodes: Array<CoverBoardFacerTop>;
};

/** aggregate fields of "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Aggregate_Fields = {
  __typename?: 'CoverBoardFacerTop_aggregate_fields';
  avg?: Maybe<CoverBoardFacerTop_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CoverBoardFacerTop_Max_Fields>;
  min?: Maybe<CoverBoardFacerTop_Min_Fields>;
  stddev?: Maybe<CoverBoardFacerTop_Stddev_Fields>;
  stddev_pop?: Maybe<CoverBoardFacerTop_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CoverBoardFacerTop_Stddev_Samp_Fields>;
  sum?: Maybe<CoverBoardFacerTop_Sum_Fields>;
  var_pop?: Maybe<CoverBoardFacerTop_Var_Pop_Fields>;
  var_samp?: Maybe<CoverBoardFacerTop_Var_Samp_Fields>;
  variance?: Maybe<CoverBoardFacerTop_Variance_Fields>;
};


/** aggregate fields of "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Aggregate_FieldsCountArgs = {
  column?: Maybe<CoverBoardFacerTop_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Aggregate_Order_By = {
  avg?: Maybe<CoverBoardFacerTop_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<CoverBoardFacerTop_Max_Order_By>;
  min?: Maybe<CoverBoardFacerTop_Min_Order_By>;
  stddev?: Maybe<CoverBoardFacerTop_Stddev_Order_By>;
  stddev_pop?: Maybe<CoverBoardFacerTop_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<CoverBoardFacerTop_Stddev_Samp_Order_By>;
  sum?: Maybe<CoverBoardFacerTop_Sum_Order_By>;
  var_pop?: Maybe<CoverBoardFacerTop_Var_Pop_Order_By>;
  var_samp?: Maybe<CoverBoardFacerTop_Var_Samp_Order_By>;
  variance?: Maybe<CoverBoardFacerTop_Variance_Order_By>;
};

/** aggregate avg on columns */
export type CoverBoardFacerTop_Avg_Fields = {
  __typename?: 'CoverBoardFacerTop_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CoverBoardFacerTop". All fields are combined with a logical 'AND'. */
export type CoverBoardFacerTop_Bool_Exp = {
  C_Facer?: Maybe<C_Facer_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  CoverBoard?: Maybe<CoverBoard_Bool_Exp>;
  FacerId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CoverBoardFacerTop_Bool_Exp>>;
  _not?: Maybe<CoverBoardFacerTop_Bool_Exp>;
  _or?: Maybe<Array<CoverBoardFacerTop_Bool_Exp>>;
};

/** upsert condition type for table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_If_Matched = {
  match_columns?: Array<CoverBoardFacerTop_Insert_Match_Column>;
  update_columns?: Array<CoverBoardFacerTop_Update_Column>;
  where?: Maybe<CoverBoardFacerTop_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "CoverBoardFacerTop" */
export enum CoverBoardFacerTop_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** aggregate max on columns */
export type CoverBoardFacerTop_Max_Fields = {
  __typename?: 'CoverBoardFacerTop_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CoverBoardFacerTop_Min_Fields = {
  __typename?: 'CoverBoardFacerTop_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** response of any mutation on the table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Mutation_Response = {
  __typename?: 'CoverBoardFacerTop_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoverBoardFacerTop>;
};

/** Ordering options when selecting data from "CoverBoardFacerTop". */
export type CoverBoardFacerTop_Order_By = {
  C_Facer?: Maybe<C_Facer_Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoard?: Maybe<CoverBoard_Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** primary key columns input for table: CoverBoardFacerTop */
export type CoverBoardFacerTop_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};

/** select columns of table "CoverBoardFacerTop" */
export enum CoverBoardFacerTop_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** input type for updating data in table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CoverBoardFacerTop_Stddev_Fields = {
  __typename?: 'CoverBoardFacerTop_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CoverBoardFacerTop_Stddev_Pop_Fields = {
  __typename?: 'CoverBoardFacerTop_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CoverBoardFacerTop_Stddev_Samp_Fields = {
  __typename?: 'CoverBoardFacerTop_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type CoverBoardFacerTop_Sum_Fields = {
  __typename?: 'CoverBoardFacerTop_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** update columns of table "CoverBoardFacerTop" */
export enum CoverBoardFacerTop_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** aggregate var_pop on columns */
export type CoverBoardFacerTop_Var_Pop_Fields = {
  __typename?: 'CoverBoardFacerTop_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CoverBoardFacerTop_Var_Samp_Fields = {
  __typename?: 'CoverBoardFacerTop_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type CoverBoardFacerTop_Variance_Fields = {
  __typename?: 'CoverBoardFacerTop_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CoverBoardFacerTop" */
export type CoverBoardFacerTop_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** columns and relationships of "CoverBoardUsage" */
export type CoverBoardUsage = {
  __typename?: 'CoverBoardUsage';
  /** An object relationship */
  InsBoardSize: InsBoardSize;
  InsBoardSizeId: Scalars['Int'];
  ThicknessMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "CoverBoardUsage" */
export type CoverBoardUsage_Aggregate = {
  __typename?: 'CoverBoardUsage_aggregate';
  aggregate?: Maybe<CoverBoardUsage_Aggregate_Fields>;
  nodes: Array<CoverBoardUsage>;
};

/** aggregate fields of "CoverBoardUsage" */
export type CoverBoardUsage_Aggregate_Fields = {
  __typename?: 'CoverBoardUsage_aggregate_fields';
  avg?: Maybe<CoverBoardUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CoverBoardUsage_Max_Fields>;
  min?: Maybe<CoverBoardUsage_Min_Fields>;
  stddev?: Maybe<CoverBoardUsage_Stddev_Fields>;
  stddev_pop?: Maybe<CoverBoardUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CoverBoardUsage_Stddev_Samp_Fields>;
  sum?: Maybe<CoverBoardUsage_Sum_Fields>;
  var_pop?: Maybe<CoverBoardUsage_Var_Pop_Fields>;
  var_samp?: Maybe<CoverBoardUsage_Var_Samp_Fields>;
  variance?: Maybe<CoverBoardUsage_Variance_Fields>;
};


/** aggregate fields of "CoverBoardUsage" */
export type CoverBoardUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<CoverBoardUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CoverBoardUsage" */
export type CoverBoardUsage_Aggregate_Order_By = {
  avg?: Maybe<CoverBoardUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<CoverBoardUsage_Max_Order_By>;
  min?: Maybe<CoverBoardUsage_Min_Order_By>;
  stddev?: Maybe<CoverBoardUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<CoverBoardUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<CoverBoardUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<CoverBoardUsage_Sum_Order_By>;
  var_pop?: Maybe<CoverBoardUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<CoverBoardUsage_Var_Samp_Order_By>;
  variance?: Maybe<CoverBoardUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type CoverBoardUsage_Avg_Fields = {
  __typename?: 'CoverBoardUsage_avg_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CoverBoardUsage" */
export type CoverBoardUsage_Avg_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CoverBoardUsage". All fields are combined with a logical 'AND'. */
export type CoverBoardUsage_Bool_Exp = {
  InsBoardSize?: Maybe<InsBoardSize_Bool_Exp>;
  InsBoardSizeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CoverBoardUsage_Bool_Exp>>;
  _not?: Maybe<CoverBoardUsage_Bool_Exp>;
  _or?: Maybe<Array<CoverBoardUsage_Bool_Exp>>;
};

/** upsert condition type for table "CoverBoardUsage" */
export type CoverBoardUsage_If_Matched = {
  match_columns?: Array<CoverBoardUsage_Insert_Match_Column>;
  update_columns?: Array<CoverBoardUsage_Update_Column>;
  where?: Maybe<CoverBoardUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CoverBoardUsage" */
export type CoverBoardUsage_Inc_Input = {
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CoverBoardUsage" */
export type CoverBoardUsage_Insert_Input = {
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "CoverBoardUsage" */
export enum CoverBoardUsage_Insert_Match_Column {
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type CoverBoardUsage_Max_Fields = {
  __typename?: 'CoverBoardUsage_max_fields';
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "CoverBoardUsage" */
export type CoverBoardUsage_Max_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CoverBoardUsage_Min_Fields = {
  __typename?: 'CoverBoardUsage_min_fields';
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "CoverBoardUsage" */
export type CoverBoardUsage_Min_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "CoverBoardUsage" */
export type CoverBoardUsage_Mutation_Response = {
  __typename?: 'CoverBoardUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoverBoardUsage>;
};

/** Ordering options when selecting data from "CoverBoardUsage". */
export type CoverBoardUsage_Order_By = {
  InsBoardSize?: Maybe<InsBoardSize_Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: CoverBoardUsage */
export type CoverBoardUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "CoverBoardUsage" */
export enum CoverBoardUsage_Select_Column {
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "CoverBoardUsage" */
export type CoverBoardUsage_Set_Input = {
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CoverBoardUsage_Stddev_Fields = {
  __typename?: 'CoverBoardUsage_stddev_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CoverBoardUsage" */
export type CoverBoardUsage_Stddev_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CoverBoardUsage_Stddev_Pop_Fields = {
  __typename?: 'CoverBoardUsage_stddev_pop_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CoverBoardUsage" */
export type CoverBoardUsage_Stddev_Pop_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CoverBoardUsage_Stddev_Samp_Fields = {
  __typename?: 'CoverBoardUsage_stddev_samp_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CoverBoardUsage" */
export type CoverBoardUsage_Stddev_Samp_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type CoverBoardUsage_Sum_Fields = {
  __typename?: 'CoverBoardUsage_sum_fields';
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CoverBoardUsage" */
export type CoverBoardUsage_Sum_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "CoverBoardUsage" */
export enum CoverBoardUsage_Update_Column {
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type CoverBoardUsage_Var_Pop_Fields = {
  __typename?: 'CoverBoardUsage_var_pop_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CoverBoardUsage" */
export type CoverBoardUsage_Var_Pop_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CoverBoardUsage_Var_Samp_Fields = {
  __typename?: 'CoverBoardUsage_var_samp_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CoverBoardUsage" */
export type CoverBoardUsage_Var_Samp_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type CoverBoardUsage_Variance_Fields = {
  __typename?: 'CoverBoardUsage_variance_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CoverBoardUsage" */
export type CoverBoardUsage_Variance_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregated selection of "CoverBoard" */
export type CoverBoard_Aggregate = {
  __typename?: 'CoverBoard_aggregate';
  aggregate?: Maybe<CoverBoard_Aggregate_Fields>;
  nodes: Array<CoverBoard>;
};

/** aggregate fields of "CoverBoard" */
export type CoverBoard_Aggregate_Fields = {
  __typename?: 'CoverBoard_aggregate_fields';
  avg?: Maybe<CoverBoard_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CoverBoard_Max_Fields>;
  min?: Maybe<CoverBoard_Min_Fields>;
  stddev?: Maybe<CoverBoard_Stddev_Fields>;
  stddev_pop?: Maybe<CoverBoard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CoverBoard_Stddev_Samp_Fields>;
  sum?: Maybe<CoverBoard_Sum_Fields>;
  var_pop?: Maybe<CoverBoard_Var_Pop_Fields>;
  var_samp?: Maybe<CoverBoard_Var_Samp_Fields>;
  variance?: Maybe<CoverBoard_Variance_Fields>;
};


/** aggregate fields of "CoverBoard" */
export type CoverBoard_Aggregate_FieldsCountArgs = {
  column?: Maybe<CoverBoard_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CoverBoard" */
export type CoverBoard_Aggregate_Order_By = {
  avg?: Maybe<CoverBoard_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<CoverBoard_Max_Order_By>;
  min?: Maybe<CoverBoard_Min_Order_By>;
  stddev?: Maybe<CoverBoard_Stddev_Order_By>;
  stddev_pop?: Maybe<CoverBoard_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<CoverBoard_Stddev_Samp_Order_By>;
  sum?: Maybe<CoverBoard_Sum_Order_By>;
  var_pop?: Maybe<CoverBoard_Var_Pop_Order_By>;
  var_samp?: Maybe<CoverBoard_Var_Samp_Order_By>;
  variance?: Maybe<CoverBoard_Variance_Order_By>;
};

/** aggregate avg on columns */
export type CoverBoard_Avg_Fields = {
  __typename?: 'CoverBoard_avg_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CoverBoard" */
export type CoverBoard_Avg_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CoverBoard". All fields are combined with a logical 'AND'. */
export type CoverBoard_Bool_Exp = {
  BoardProfileId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_BoardProfile?: Maybe<C_BoardProfile_Bool_Exp>;
  C_CoverBoardMaterial?: Maybe<C_CoverBoardMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  CoverBoardBoardSizes?: Maybe<CoverBoardBoardSizes_Bool_Exp>;
  CoverBoardFacerBottoms?: Maybe<CoverBoardFacerBottom_Bool_Exp>;
  CoverBoardFacerTops?: Maybe<CoverBoardFacerTop_Bool_Exp>;
  CoverBoardMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  DensityMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  ThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CoverBoard_Bool_Exp>>;
  _not?: Maybe<CoverBoard_Bool_Exp>;
  _or?: Maybe<Array<CoverBoard_Bool_Exp>>;
};

/** upsert condition type for table "CoverBoard" */
export type CoverBoard_If_Matched = {
  match_columns?: Array<CoverBoard_Insert_Match_Column>;
  update_columns?: Array<CoverBoard_Update_Column>;
  where?: Maybe<CoverBoard_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CoverBoard" */
export type CoverBoard_Inc_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CoverBoard" */
export type CoverBoard_Insert_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "CoverBoard" */
export enum CoverBoard_Insert_Match_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverBoardMaterialId = 'CoverBoardMaterialId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate max on columns */
export type CoverBoard_Max_Fields = {
  __typename?: 'CoverBoard_max_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "CoverBoard" */
export type CoverBoard_Max_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CoverBoard_Min_Fields = {
  __typename?: 'CoverBoard_min_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "CoverBoard" */
export type CoverBoard_Min_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** response of any mutation on the table "CoverBoard" */
export type CoverBoard_Mutation_Response = {
  __typename?: 'CoverBoard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoverBoard>;
};

/** Ordering options when selecting data from "CoverBoard". */
export type CoverBoard_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  C_BoardProfile?: Maybe<C_BoardProfile_Order_By>;
  C_CoverBoardMaterial?: Maybe<C_CoverBoardMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardBoardSizes_aggregate?: Maybe<CoverBoardBoardSizes_Aggregate_Order_By>;
  CoverBoardFacerBottoms_aggregate?: Maybe<CoverBoardFacerBottom_Aggregate_Order_By>;
  CoverBoardFacerTops_aggregate?: Maybe<CoverBoardFacerTop_Aggregate_Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** primary key columns input for table: CoverBoard */
export type CoverBoard_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "CoverBoard" */
export enum CoverBoard_Select_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverBoardMaterialId = 'CoverBoardMaterialId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** input type for updating data in table "CoverBoard" */
export type CoverBoard_Set_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type CoverBoard_Stddev_Fields = {
  __typename?: 'CoverBoard_stddev_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CoverBoard" */
export type CoverBoard_Stddev_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CoverBoard_Stddev_Pop_Fields = {
  __typename?: 'CoverBoard_stddev_pop_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CoverBoard" */
export type CoverBoard_Stddev_Pop_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CoverBoard_Stddev_Samp_Fields = {
  __typename?: 'CoverBoard_stddev_samp_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CoverBoard" */
export type CoverBoard_Stddev_Samp_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type CoverBoard_Sum_Fields = {
  __typename?: 'CoverBoard_sum_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CoverBoard" */
export type CoverBoard_Sum_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
};

/** update columns of table "CoverBoard" */
export enum CoverBoard_Update_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverBoardMaterialId = 'CoverBoardMaterialId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate var_pop on columns */
export type CoverBoard_Var_Pop_Fields = {
  __typename?: 'CoverBoard_var_pop_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CoverBoard" */
export type CoverBoard_Var_Pop_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CoverBoard_Var_Samp_Fields = {
  __typename?: 'CoverBoard_var_samp_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CoverBoard" */
export type CoverBoard_Var_Samp_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type CoverBoard_Variance_Fields = {
  __typename?: 'CoverBoard_variance_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CoverBoard" */
export type CoverBoard_Variance_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "CoverStripDisk" */
export type CoverStripDisk = {
  __typename?: 'CoverStripDisk';
  /** An object relationship */
  C_SinglePlyMaterial: C_SinglePlyMaterial;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  SinglePlyMaterialId: Scalars['Int'];
};

/** columns and relationships of "CoverStripDiskUsage" */
export type CoverStripDiskUsage = {
  __typename?: 'CoverStripDiskUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "CoverStripDiskUsage" */
export type CoverStripDiskUsage_Aggregate = {
  __typename?: 'CoverStripDiskUsage_aggregate';
  aggregate?: Maybe<CoverStripDiskUsage_Aggregate_Fields>;
  nodes: Array<CoverStripDiskUsage>;
};

/** aggregate fields of "CoverStripDiskUsage" */
export type CoverStripDiskUsage_Aggregate_Fields = {
  __typename?: 'CoverStripDiskUsage_aggregate_fields';
  avg?: Maybe<CoverStripDiskUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CoverStripDiskUsage_Max_Fields>;
  min?: Maybe<CoverStripDiskUsage_Min_Fields>;
  stddev?: Maybe<CoverStripDiskUsage_Stddev_Fields>;
  stddev_pop?: Maybe<CoverStripDiskUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CoverStripDiskUsage_Stddev_Samp_Fields>;
  sum?: Maybe<CoverStripDiskUsage_Sum_Fields>;
  var_pop?: Maybe<CoverStripDiskUsage_Var_Pop_Fields>;
  var_samp?: Maybe<CoverStripDiskUsage_Var_Samp_Fields>;
  variance?: Maybe<CoverStripDiskUsage_Variance_Fields>;
};


/** aggregate fields of "CoverStripDiskUsage" */
export type CoverStripDiskUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<CoverStripDiskUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CoverStripDiskUsage_Avg_Fields = {
  __typename?: 'CoverStripDiskUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CoverStripDiskUsage". All fields are combined with a logical 'AND'. */
export type CoverStripDiskUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CoverStripDiskUsage_Bool_Exp>>;
  _not?: Maybe<CoverStripDiskUsage_Bool_Exp>;
  _or?: Maybe<Array<CoverStripDiskUsage_Bool_Exp>>;
};

/** upsert condition type for table "CoverStripDiskUsage" */
export type CoverStripDiskUsage_If_Matched = {
  match_columns?: Array<CoverStripDiskUsage_Insert_Match_Column>;
  update_columns?: Array<CoverStripDiskUsage_Update_Column>;
  where?: Maybe<CoverStripDiskUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CoverStripDiskUsage" */
export type CoverStripDiskUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CoverStripDiskUsage" */
export type CoverStripDiskUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "CoverStripDiskUsage" */
export enum CoverStripDiskUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type CoverStripDiskUsage_Max_Fields = {
  __typename?: 'CoverStripDiskUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CoverStripDiskUsage_Min_Fields = {
  __typename?: 'CoverStripDiskUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "CoverStripDiskUsage" */
export type CoverStripDiskUsage_Mutation_Response = {
  __typename?: 'CoverStripDiskUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoverStripDiskUsage>;
};

/** Ordering options when selecting data from "CoverStripDiskUsage". */
export type CoverStripDiskUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: CoverStripDiskUsage */
export type CoverStripDiskUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "CoverStripDiskUsage" */
export enum CoverStripDiskUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "CoverStripDiskUsage" */
export type CoverStripDiskUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CoverStripDiskUsage_Stddev_Fields = {
  __typename?: 'CoverStripDiskUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CoverStripDiskUsage_Stddev_Pop_Fields = {
  __typename?: 'CoverStripDiskUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CoverStripDiskUsage_Stddev_Samp_Fields = {
  __typename?: 'CoverStripDiskUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CoverStripDiskUsage_Sum_Fields = {
  __typename?: 'CoverStripDiskUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "CoverStripDiskUsage" */
export enum CoverStripDiskUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type CoverStripDiskUsage_Var_Pop_Fields = {
  __typename?: 'CoverStripDiskUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CoverStripDiskUsage_Var_Samp_Fields = {
  __typename?: 'CoverStripDiskUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CoverStripDiskUsage_Variance_Fields = {
  __typename?: 'CoverStripDiskUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "CoverStripDisk" */
export type CoverStripDisk_Aggregate = {
  __typename?: 'CoverStripDisk_aggregate';
  aggregate?: Maybe<CoverStripDisk_Aggregate_Fields>;
  nodes: Array<CoverStripDisk>;
};

/** aggregate fields of "CoverStripDisk" */
export type CoverStripDisk_Aggregate_Fields = {
  __typename?: 'CoverStripDisk_aggregate_fields';
  avg?: Maybe<CoverStripDisk_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CoverStripDisk_Max_Fields>;
  min?: Maybe<CoverStripDisk_Min_Fields>;
  stddev?: Maybe<CoverStripDisk_Stddev_Fields>;
  stddev_pop?: Maybe<CoverStripDisk_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CoverStripDisk_Stddev_Samp_Fields>;
  sum?: Maybe<CoverStripDisk_Sum_Fields>;
  var_pop?: Maybe<CoverStripDisk_Var_Pop_Fields>;
  var_samp?: Maybe<CoverStripDisk_Var_Samp_Fields>;
  variance?: Maybe<CoverStripDisk_Variance_Fields>;
};


/** aggregate fields of "CoverStripDisk" */
export type CoverStripDisk_Aggregate_FieldsCountArgs = {
  column?: Maybe<CoverStripDisk_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CoverStripDisk" */
export type CoverStripDisk_Aggregate_Order_By = {
  avg?: Maybe<CoverStripDisk_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<CoverStripDisk_Max_Order_By>;
  min?: Maybe<CoverStripDisk_Min_Order_By>;
  stddev?: Maybe<CoverStripDisk_Stddev_Order_By>;
  stddev_pop?: Maybe<CoverStripDisk_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<CoverStripDisk_Stddev_Samp_Order_By>;
  sum?: Maybe<CoverStripDisk_Sum_Order_By>;
  var_pop?: Maybe<CoverStripDisk_Var_Pop_Order_By>;
  var_samp?: Maybe<CoverStripDisk_Var_Samp_Order_By>;
  variance?: Maybe<CoverStripDisk_Variance_Order_By>;
};

/** aggregate avg on columns */
export type CoverStripDisk_Avg_Fields = {
  __typename?: 'CoverStripDisk_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CoverStripDisk" */
export type CoverStripDisk_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CoverStripDisk". All fields are combined with a logical 'AND'. */
export type CoverStripDisk_Bool_Exp = {
  C_SinglePlyMaterial?: Maybe<C_SinglePlyMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  SinglePlyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<CoverStripDisk_Bool_Exp>>;
  _not?: Maybe<CoverStripDisk_Bool_Exp>;
  _or?: Maybe<Array<CoverStripDisk_Bool_Exp>>;
};

/** upsert condition type for table "CoverStripDisk" */
export type CoverStripDisk_If_Matched = {
  match_columns?: Array<CoverStripDisk_Insert_Match_Column>;
  update_columns?: Array<CoverStripDisk_Update_Column>;
  where?: Maybe<CoverStripDisk_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "CoverStripDisk" */
export type CoverStripDisk_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "CoverStripDisk" */
export type CoverStripDisk_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "CoverStripDisk" */
export enum CoverStripDisk_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SinglePlyMaterialId = 'SinglePlyMaterialId'
}

/** aggregate max on columns */
export type CoverStripDisk_Max_Fields = {
  __typename?: 'CoverStripDisk_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "CoverStripDisk" */
export type CoverStripDisk_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CoverStripDisk_Min_Fields = {
  __typename?: 'CoverStripDisk_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "CoverStripDisk" */
export type CoverStripDisk_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** response of any mutation on the table "CoverStripDisk" */
export type CoverStripDisk_Mutation_Response = {
  __typename?: 'CoverStripDisk_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoverStripDisk>;
};

/** Ordering options when selecting data from "CoverStripDisk". */
export type CoverStripDisk_Order_By = {
  C_SinglePlyMaterial?: Maybe<C_SinglePlyMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** primary key columns input for table: CoverStripDisk */
export type CoverStripDisk_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "CoverStripDisk" */
export enum CoverStripDisk_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SinglePlyMaterialId = 'SinglePlyMaterialId'
}

/** input type for updating data in table "CoverStripDisk" */
export type CoverStripDisk_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CoverStripDisk_Stddev_Fields = {
  __typename?: 'CoverStripDisk_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CoverStripDisk" */
export type CoverStripDisk_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CoverStripDisk_Stddev_Pop_Fields = {
  __typename?: 'CoverStripDisk_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CoverStripDisk" */
export type CoverStripDisk_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CoverStripDisk_Stddev_Samp_Fields = {
  __typename?: 'CoverStripDisk_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CoverStripDisk" */
export type CoverStripDisk_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type CoverStripDisk_Sum_Fields = {
  __typename?: 'CoverStripDisk_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CoverStripDisk" */
export type CoverStripDisk_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** update columns of table "CoverStripDisk" */
export enum CoverStripDisk_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SinglePlyMaterialId = 'SinglePlyMaterialId'
}

/** aggregate var_pop on columns */
export type CoverStripDisk_Var_Pop_Fields = {
  __typename?: 'CoverStripDisk_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CoverStripDisk" */
export type CoverStripDisk_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CoverStripDisk_Var_Samp_Fields = {
  __typename?: 'CoverStripDisk_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CoverStripDisk" */
export type CoverStripDisk_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type CoverStripDisk_Variance_Fields = {
  __typename?: 'CoverStripDisk_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CoverStripDisk" */
export type CoverStripDisk_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "ExistingRoofLayer" */
export type ExistingRoofLayer = {
  __typename?: 'ExistingRoofLayer';
  /** An object relationship */
  C_ExistingRoofCover: C_ExistingRoofCover;
  /** An object relationship */
  C_ExistingRoofDeck: C_ExistingRoofDeck;
  /** An object relationship */
  C_ExistingRoofInsulationMaterial: C_ExistingRoofInsulationMaterial;
  /** An object relationship */
  C_ExistingRoofSinglePlyMaterial: C_ExistingRoofSinglePlyMaterial;
  /** An object relationship */
  C_ExistingRoofSurface: C_ExistingRoofSurface;
  EnteredAsMetric: Scalars['MetricEntryFlag'];
  ExistingRoofCoverId: Scalars['Int'];
  ExistingRoofDeckId: Scalars['Int'];
  ExistingRoofInsulationMaterialId: Scalars['Int'];
  ExistingRoofSinglePlyMaterialId: Scalars['Int'];
  ExistingRoofSurfaceId: Scalars['Int'];
  InsulationThicknessMax: Scalars['MeasurementValue'];
  InsulationThicknessMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Layer: Layer;
  LayerId: Scalars['Int'];
  SteelStrength: Scalars['MeasurementValue'];
  SteelThicknessMax: Scalars['MeasurementValue'];
  SteelThicknessMin: Scalars['MeasurementValue'];
  V2_CMPNNT_ID?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "ExistingRoofLayer" */
export type ExistingRoofLayer_Aggregate = {
  __typename?: 'ExistingRoofLayer_aggregate';
  aggregate?: Maybe<ExistingRoofLayer_Aggregate_Fields>;
  nodes: Array<ExistingRoofLayer>;
};

/** aggregate fields of "ExistingRoofLayer" */
export type ExistingRoofLayer_Aggregate_Fields = {
  __typename?: 'ExistingRoofLayer_aggregate_fields';
  avg?: Maybe<ExistingRoofLayer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ExistingRoofLayer_Max_Fields>;
  min?: Maybe<ExistingRoofLayer_Min_Fields>;
  stddev?: Maybe<ExistingRoofLayer_Stddev_Fields>;
  stddev_pop?: Maybe<ExistingRoofLayer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ExistingRoofLayer_Stddev_Samp_Fields>;
  sum?: Maybe<ExistingRoofLayer_Sum_Fields>;
  var_pop?: Maybe<ExistingRoofLayer_Var_Pop_Fields>;
  var_samp?: Maybe<ExistingRoofLayer_Var_Samp_Fields>;
  variance?: Maybe<ExistingRoofLayer_Variance_Fields>;
};


/** aggregate fields of "ExistingRoofLayer" */
export type ExistingRoofLayer_Aggregate_FieldsCountArgs = {
  column?: Maybe<ExistingRoofLayer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ExistingRoofLayer" */
export type ExistingRoofLayer_Aggregate_Order_By = {
  avg?: Maybe<ExistingRoofLayer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ExistingRoofLayer_Max_Order_By>;
  min?: Maybe<ExistingRoofLayer_Min_Order_By>;
  stddev?: Maybe<ExistingRoofLayer_Stddev_Order_By>;
  stddev_pop?: Maybe<ExistingRoofLayer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ExistingRoofLayer_Stddev_Samp_Order_By>;
  sum?: Maybe<ExistingRoofLayer_Sum_Order_By>;
  var_pop?: Maybe<ExistingRoofLayer_Var_Pop_Order_By>;
  var_samp?: Maybe<ExistingRoofLayer_Var_Samp_Order_By>;
  variance?: Maybe<ExistingRoofLayer_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ExistingRoofLayer_Avg_Fields = {
  __typename?: 'ExistingRoofLayer_avg_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Float']>;
  ExistingRoofDeckId?: Maybe<Scalars['Float']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Float']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Float']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  V2_CMPNNT_ID?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ExistingRoofLayer" */
export type ExistingRoofLayer_Avg_Order_By = {
  ExistingRoofCoverId?: Maybe<Order_By>;
  ExistingRoofDeckId?: Maybe<Order_By>;
  ExistingRoofInsulationMaterialId?: Maybe<Order_By>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Order_By>;
  ExistingRoofSurfaceId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  V2_CMPNNT_ID?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ExistingRoofLayer". All fields are combined with a logical 'AND'. */
export type ExistingRoofLayer_Bool_Exp = {
  C_ExistingRoofCover?: Maybe<C_ExistingRoofCover_Bool_Exp>;
  C_ExistingRoofDeck?: Maybe<C_ExistingRoofDeck_Bool_Exp>;
  C_ExistingRoofInsulationMaterial?: Maybe<C_ExistingRoofInsulationMaterial_Bool_Exp>;
  C_ExistingRoofSinglePlyMaterial?: Maybe<C_ExistingRoofSinglePlyMaterial_Bool_Exp>;
  C_ExistingRoofSurface?: Maybe<C_ExistingRoofSurface_Bool_Exp>;
  EnteredAsMetric?: Maybe<MetricEntryFlag_Mssql_Comparison_Exp>;
  ExistingRoofCoverId?: Maybe<Int_Mssql_Comparison_Exp>;
  ExistingRoofDeckId?: Maybe<Int_Mssql_Comparison_Exp>;
  ExistingRoofInsulationMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ExistingRoofSurfaceId?: Maybe<Int_Mssql_Comparison_Exp>;
  InsulationThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  InsulationThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Layer?: Maybe<Layer_Bool_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  SteelStrength?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  SteelThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  SteelThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  V2_CMPNNT_ID?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ExistingRoofLayer_Bool_Exp>>;
  _not?: Maybe<ExistingRoofLayer_Bool_Exp>;
  _or?: Maybe<Array<ExistingRoofLayer_Bool_Exp>>;
};

/** upsert condition type for table "ExistingRoofLayer" */
export type ExistingRoofLayer_If_Matched = {
  match_columns?: Array<ExistingRoofLayer_Insert_Match_Column>;
  update_columns?: Array<ExistingRoofLayer_Update_Column>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ExistingRoofLayer" */
export type ExistingRoofLayer_Inc_Input = {
  ExistingRoofCoverId?: Maybe<Scalars['Int']>;
  ExistingRoofDeckId?: Maybe<Scalars['Int']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Int']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  V2_CMPNNT_ID?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ExistingRoofLayer" */
export type ExistingRoofLayer_Insert_Input = {
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  ExistingRoofCoverId?: Maybe<Scalars['Int']>;
  ExistingRoofDeckId?: Maybe<Scalars['Int']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Int']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Int']>;
  InsulationThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  InsulationThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  LayerId?: Maybe<Scalars['Int']>;
  SteelStrength?: Maybe<Scalars['MeasurementValue']>;
  SteelThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  SteelThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  V2_CMPNNT_ID?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ExistingRoofLayer" */
export enum ExistingRoofLayer_Insert_Match_Column {
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  ExistingRoofCoverId = 'ExistingRoofCoverId',
  /** column name */
  ExistingRoofDeckId = 'ExistingRoofDeckId',
  /** column name */
  ExistingRoofInsulationMaterialId = 'ExistingRoofInsulationMaterialId',
  /** column name */
  ExistingRoofSinglePlyMaterialId = 'ExistingRoofSinglePlyMaterialId',
  /** column name */
  ExistingRoofSurfaceId = 'ExistingRoofSurfaceId',
  /** column name */
  InsulationThicknessMax = 'InsulationThicknessMax',
  /** column name */
  InsulationThicknessMin = 'InsulationThicknessMin',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SteelStrength = 'SteelStrength',
  /** column name */
  SteelThicknessMax = 'SteelThicknessMax',
  /** column name */
  SteelThicknessMin = 'SteelThicknessMin',
  /** column name */
  V2CmpnntId = 'V2_CMPNNT_ID'
}

/** aggregate max on columns */
export type ExistingRoofLayer_Max_Fields = {
  __typename?: 'ExistingRoofLayer_max_fields';
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  ExistingRoofCoverId?: Maybe<Scalars['Int']>;
  ExistingRoofDeckId?: Maybe<Scalars['Int']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Int']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Int']>;
  InsulationThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  InsulationThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  LayerId?: Maybe<Scalars['Int']>;
  SteelStrength?: Maybe<Scalars['MeasurementValue']>;
  SteelThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  SteelThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  V2_CMPNNT_ID?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ExistingRoofLayer" */
export type ExistingRoofLayer_Max_Order_By = {
  EnteredAsMetric?: Maybe<Order_By>;
  ExistingRoofCoverId?: Maybe<Order_By>;
  ExistingRoofDeckId?: Maybe<Order_By>;
  ExistingRoofInsulationMaterialId?: Maybe<Order_By>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Order_By>;
  ExistingRoofSurfaceId?: Maybe<Order_By>;
  InsulationThicknessMax?: Maybe<Order_By>;
  InsulationThicknessMin?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  SteelStrength?: Maybe<Order_By>;
  SteelThicknessMax?: Maybe<Order_By>;
  SteelThicknessMin?: Maybe<Order_By>;
  V2_CMPNNT_ID?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ExistingRoofLayer_Min_Fields = {
  __typename?: 'ExistingRoofLayer_min_fields';
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  ExistingRoofCoverId?: Maybe<Scalars['Int']>;
  ExistingRoofDeckId?: Maybe<Scalars['Int']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Int']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Int']>;
  InsulationThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  InsulationThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  LayerId?: Maybe<Scalars['Int']>;
  SteelStrength?: Maybe<Scalars['MeasurementValue']>;
  SteelThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  SteelThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  V2_CMPNNT_ID?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ExistingRoofLayer" */
export type ExistingRoofLayer_Min_Order_By = {
  EnteredAsMetric?: Maybe<Order_By>;
  ExistingRoofCoverId?: Maybe<Order_By>;
  ExistingRoofDeckId?: Maybe<Order_By>;
  ExistingRoofInsulationMaterialId?: Maybe<Order_By>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Order_By>;
  ExistingRoofSurfaceId?: Maybe<Order_By>;
  InsulationThicknessMax?: Maybe<Order_By>;
  InsulationThicknessMin?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  SteelStrength?: Maybe<Order_By>;
  SteelThicknessMax?: Maybe<Order_By>;
  SteelThicknessMin?: Maybe<Order_By>;
  V2_CMPNNT_ID?: Maybe<Order_By>;
};

/** response of any mutation on the table "ExistingRoofLayer" */
export type ExistingRoofLayer_Mutation_Response = {
  __typename?: 'ExistingRoofLayer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExistingRoofLayer>;
};

/** Ordering options when selecting data from "ExistingRoofLayer". */
export type ExistingRoofLayer_Order_By = {
  C_ExistingRoofCover?: Maybe<C_ExistingRoofCover_Order_By>;
  C_ExistingRoofDeck?: Maybe<C_ExistingRoofDeck_Order_By>;
  C_ExistingRoofInsulationMaterial?: Maybe<C_ExistingRoofInsulationMaterial_Order_By>;
  C_ExistingRoofSinglePlyMaterial?: Maybe<C_ExistingRoofSinglePlyMaterial_Order_By>;
  C_ExistingRoofSurface?: Maybe<C_ExistingRoofSurface_Order_By>;
  EnteredAsMetric?: Maybe<Order_By>;
  ExistingRoofCoverId?: Maybe<Order_By>;
  ExistingRoofDeckId?: Maybe<Order_By>;
  ExistingRoofInsulationMaterialId?: Maybe<Order_By>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Order_By>;
  ExistingRoofSurfaceId?: Maybe<Order_By>;
  InsulationThicknessMax?: Maybe<Order_By>;
  InsulationThicknessMin?: Maybe<Order_By>;
  Layer?: Maybe<Layer_Order_By>;
  LayerId?: Maybe<Order_By>;
  SteelStrength?: Maybe<Order_By>;
  SteelThicknessMax?: Maybe<Order_By>;
  SteelThicknessMin?: Maybe<Order_By>;
  V2_CMPNNT_ID?: Maybe<Order_By>;
};

/** primary key columns input for table: ExistingRoofLayer */
export type ExistingRoofLayer_Pk_Columns_Input = {
  LayerId: Scalars['Int'];
};

/** select columns of table "ExistingRoofLayer" */
export enum ExistingRoofLayer_Select_Column {
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  ExistingRoofCoverId = 'ExistingRoofCoverId',
  /** column name */
  ExistingRoofDeckId = 'ExistingRoofDeckId',
  /** column name */
  ExistingRoofInsulationMaterialId = 'ExistingRoofInsulationMaterialId',
  /** column name */
  ExistingRoofSinglePlyMaterialId = 'ExistingRoofSinglePlyMaterialId',
  /** column name */
  ExistingRoofSurfaceId = 'ExistingRoofSurfaceId',
  /** column name */
  InsulationThicknessMax = 'InsulationThicknessMax',
  /** column name */
  InsulationThicknessMin = 'InsulationThicknessMin',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SteelStrength = 'SteelStrength',
  /** column name */
  SteelThicknessMax = 'SteelThicknessMax',
  /** column name */
  SteelThicknessMin = 'SteelThicknessMin',
  /** column name */
  V2CmpnntId = 'V2_CMPNNT_ID'
}

/** input type for updating data in table "ExistingRoofLayer" */
export type ExistingRoofLayer_Set_Input = {
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  ExistingRoofCoverId?: Maybe<Scalars['Int']>;
  ExistingRoofDeckId?: Maybe<Scalars['Int']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Int']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Int']>;
  InsulationThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  InsulationThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  LayerId?: Maybe<Scalars['Int']>;
  SteelStrength?: Maybe<Scalars['MeasurementValue']>;
  SteelThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  SteelThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  V2_CMPNNT_ID?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ExistingRoofLayer_Stddev_Fields = {
  __typename?: 'ExistingRoofLayer_stddev_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Float']>;
  ExistingRoofDeckId?: Maybe<Scalars['Float']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Float']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Float']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  V2_CMPNNT_ID?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ExistingRoofLayer" */
export type ExistingRoofLayer_Stddev_Order_By = {
  ExistingRoofCoverId?: Maybe<Order_By>;
  ExistingRoofDeckId?: Maybe<Order_By>;
  ExistingRoofInsulationMaterialId?: Maybe<Order_By>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Order_By>;
  ExistingRoofSurfaceId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  V2_CMPNNT_ID?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ExistingRoofLayer_Stddev_Pop_Fields = {
  __typename?: 'ExistingRoofLayer_stddev_pop_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Float']>;
  ExistingRoofDeckId?: Maybe<Scalars['Float']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Float']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Float']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  V2_CMPNNT_ID?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ExistingRoofLayer" */
export type ExistingRoofLayer_Stddev_Pop_Order_By = {
  ExistingRoofCoverId?: Maybe<Order_By>;
  ExistingRoofDeckId?: Maybe<Order_By>;
  ExistingRoofInsulationMaterialId?: Maybe<Order_By>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Order_By>;
  ExistingRoofSurfaceId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  V2_CMPNNT_ID?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ExistingRoofLayer_Stddev_Samp_Fields = {
  __typename?: 'ExistingRoofLayer_stddev_samp_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Float']>;
  ExistingRoofDeckId?: Maybe<Scalars['Float']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Float']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Float']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  V2_CMPNNT_ID?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ExistingRoofLayer" */
export type ExistingRoofLayer_Stddev_Samp_Order_By = {
  ExistingRoofCoverId?: Maybe<Order_By>;
  ExistingRoofDeckId?: Maybe<Order_By>;
  ExistingRoofInsulationMaterialId?: Maybe<Order_By>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Order_By>;
  ExistingRoofSurfaceId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  V2_CMPNNT_ID?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ExistingRoofLayer_Sum_Fields = {
  __typename?: 'ExistingRoofLayer_sum_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Int']>;
  ExistingRoofDeckId?: Maybe<Scalars['Int']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Int']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  V2_CMPNNT_ID?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ExistingRoofLayer" */
export type ExistingRoofLayer_Sum_Order_By = {
  ExistingRoofCoverId?: Maybe<Order_By>;
  ExistingRoofDeckId?: Maybe<Order_By>;
  ExistingRoofInsulationMaterialId?: Maybe<Order_By>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Order_By>;
  ExistingRoofSurfaceId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  V2_CMPNNT_ID?: Maybe<Order_By>;
};

/** update columns of table "ExistingRoofLayer" */
export enum ExistingRoofLayer_Update_Column {
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  ExistingRoofCoverId = 'ExistingRoofCoverId',
  /** column name */
  ExistingRoofDeckId = 'ExistingRoofDeckId',
  /** column name */
  ExistingRoofInsulationMaterialId = 'ExistingRoofInsulationMaterialId',
  /** column name */
  ExistingRoofSinglePlyMaterialId = 'ExistingRoofSinglePlyMaterialId',
  /** column name */
  ExistingRoofSurfaceId = 'ExistingRoofSurfaceId',
  /** column name */
  InsulationThicknessMax = 'InsulationThicknessMax',
  /** column name */
  InsulationThicknessMin = 'InsulationThicknessMin',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SteelStrength = 'SteelStrength',
  /** column name */
  SteelThicknessMax = 'SteelThicknessMax',
  /** column name */
  SteelThicknessMin = 'SteelThicknessMin',
  /** column name */
  V2CmpnntId = 'V2_CMPNNT_ID'
}

/** aggregate var_pop on columns */
export type ExistingRoofLayer_Var_Pop_Fields = {
  __typename?: 'ExistingRoofLayer_var_pop_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Float']>;
  ExistingRoofDeckId?: Maybe<Scalars['Float']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Float']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Float']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  V2_CMPNNT_ID?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ExistingRoofLayer" */
export type ExistingRoofLayer_Var_Pop_Order_By = {
  ExistingRoofCoverId?: Maybe<Order_By>;
  ExistingRoofDeckId?: Maybe<Order_By>;
  ExistingRoofInsulationMaterialId?: Maybe<Order_By>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Order_By>;
  ExistingRoofSurfaceId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  V2_CMPNNT_ID?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ExistingRoofLayer_Var_Samp_Fields = {
  __typename?: 'ExistingRoofLayer_var_samp_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Float']>;
  ExistingRoofDeckId?: Maybe<Scalars['Float']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Float']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Float']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  V2_CMPNNT_ID?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ExistingRoofLayer" */
export type ExistingRoofLayer_Var_Samp_Order_By = {
  ExistingRoofCoverId?: Maybe<Order_By>;
  ExistingRoofDeckId?: Maybe<Order_By>;
  ExistingRoofInsulationMaterialId?: Maybe<Order_By>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Order_By>;
  ExistingRoofSurfaceId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  V2_CMPNNT_ID?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ExistingRoofLayer_Variance_Fields = {
  __typename?: 'ExistingRoofLayer_variance_fields';
  ExistingRoofCoverId?: Maybe<Scalars['Float']>;
  ExistingRoofDeckId?: Maybe<Scalars['Float']>;
  ExistingRoofInsulationMaterialId?: Maybe<Scalars['Float']>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Scalars['Float']>;
  ExistingRoofSurfaceId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  V2_CMPNNT_ID?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ExistingRoofLayer" */
export type ExistingRoofLayer_Variance_Order_By = {
  ExistingRoofCoverId?: Maybe<Order_By>;
  ExistingRoofDeckId?: Maybe<Order_By>;
  ExistingRoofInsulationMaterialId?: Maybe<Order_By>;
  ExistingRoofSinglePlyMaterialId?: Maybe<Order_By>;
  ExistingRoofSurfaceId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  V2_CMPNNT_ID?: Maybe<Order_By>;
};

/** columns and relationships of "ExpansionJoint" */
export type ExpansionJoint = {
  __typename?: 'ExpansionJoint';
  /** An object relationship */
  C_FlashingMaterial: C_FlashingMaterial;
  ComponentId: Scalars['Int'];
  FlashingMaterialId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  MaxWindRating: Scalars['Float'];
  /** An array relationship */
  R_ExpansionJoinFasteners: Array<R_ExpansionJoinFastener>;
  /** An aggregate relationship */
  R_ExpansionJoinFasteners_aggregate: R_ExpansionJoinFastener_Aggregate;
};


/** columns and relationships of "ExpansionJoint" */
export type ExpansionJointR_ExpansionJoinFastenersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ExpansionJoinFastener_Order_By>>;
  where?: Maybe<R_ExpansionJoinFastener_Bool_Exp>;
};


/** columns and relationships of "ExpansionJoint" */
export type ExpansionJointR_ExpansionJoinFasteners_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ExpansionJoinFastener_Order_By>>;
  where?: Maybe<R_ExpansionJoinFastener_Bool_Exp>;
};

/** columns and relationships of "ExpansionJointUsage" */
export type ExpansionJointUsage = {
  __typename?: 'ExpansionJointUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "ExpansionJointUsage" */
export type ExpansionJointUsage_Aggregate = {
  __typename?: 'ExpansionJointUsage_aggregate';
  aggregate?: Maybe<ExpansionJointUsage_Aggregate_Fields>;
  nodes: Array<ExpansionJointUsage>;
};

/** aggregate fields of "ExpansionJointUsage" */
export type ExpansionJointUsage_Aggregate_Fields = {
  __typename?: 'ExpansionJointUsage_aggregate_fields';
  avg?: Maybe<ExpansionJointUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ExpansionJointUsage_Max_Fields>;
  min?: Maybe<ExpansionJointUsage_Min_Fields>;
  stddev?: Maybe<ExpansionJointUsage_Stddev_Fields>;
  stddev_pop?: Maybe<ExpansionJointUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ExpansionJointUsage_Stddev_Samp_Fields>;
  sum?: Maybe<ExpansionJointUsage_Sum_Fields>;
  var_pop?: Maybe<ExpansionJointUsage_Var_Pop_Fields>;
  var_samp?: Maybe<ExpansionJointUsage_Var_Samp_Fields>;
  variance?: Maybe<ExpansionJointUsage_Variance_Fields>;
};


/** aggregate fields of "ExpansionJointUsage" */
export type ExpansionJointUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<ExpansionJointUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ExpansionJointUsage_Avg_Fields = {
  __typename?: 'ExpansionJointUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ExpansionJointUsage". All fields are combined with a logical 'AND'. */
export type ExpansionJointUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ExpansionJointUsage_Bool_Exp>>;
  _not?: Maybe<ExpansionJointUsage_Bool_Exp>;
  _or?: Maybe<Array<ExpansionJointUsage_Bool_Exp>>;
};

/** upsert condition type for table "ExpansionJointUsage" */
export type ExpansionJointUsage_If_Matched = {
  match_columns?: Array<ExpansionJointUsage_Insert_Match_Column>;
  update_columns?: Array<ExpansionJointUsage_Update_Column>;
  where?: Maybe<ExpansionJointUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ExpansionJointUsage" */
export type ExpansionJointUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ExpansionJointUsage" */
export type ExpansionJointUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ExpansionJointUsage" */
export enum ExpansionJointUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type ExpansionJointUsage_Max_Fields = {
  __typename?: 'ExpansionJointUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ExpansionJointUsage_Min_Fields = {
  __typename?: 'ExpansionJointUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "ExpansionJointUsage" */
export type ExpansionJointUsage_Mutation_Response = {
  __typename?: 'ExpansionJointUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExpansionJointUsage>;
};

/** Ordering options when selecting data from "ExpansionJointUsage". */
export type ExpansionJointUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: ExpansionJointUsage */
export type ExpansionJointUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "ExpansionJointUsage" */
export enum ExpansionJointUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "ExpansionJointUsage" */
export type ExpansionJointUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ExpansionJointUsage_Stddev_Fields = {
  __typename?: 'ExpansionJointUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ExpansionJointUsage_Stddev_Pop_Fields = {
  __typename?: 'ExpansionJointUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ExpansionJointUsage_Stddev_Samp_Fields = {
  __typename?: 'ExpansionJointUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ExpansionJointUsage_Sum_Fields = {
  __typename?: 'ExpansionJointUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "ExpansionJointUsage" */
export enum ExpansionJointUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type ExpansionJointUsage_Var_Pop_Fields = {
  __typename?: 'ExpansionJointUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ExpansionJointUsage_Var_Samp_Fields = {
  __typename?: 'ExpansionJointUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ExpansionJointUsage_Variance_Fields = {
  __typename?: 'ExpansionJointUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "ExpansionJoint" */
export type ExpansionJoint_Aggregate = {
  __typename?: 'ExpansionJoint_aggregate';
  aggregate?: Maybe<ExpansionJoint_Aggregate_Fields>;
  nodes: Array<ExpansionJoint>;
};

/** aggregate fields of "ExpansionJoint" */
export type ExpansionJoint_Aggregate_Fields = {
  __typename?: 'ExpansionJoint_aggregate_fields';
  avg?: Maybe<ExpansionJoint_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ExpansionJoint_Max_Fields>;
  min?: Maybe<ExpansionJoint_Min_Fields>;
  stddev?: Maybe<ExpansionJoint_Stddev_Fields>;
  stddev_pop?: Maybe<ExpansionJoint_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ExpansionJoint_Stddev_Samp_Fields>;
  sum?: Maybe<ExpansionJoint_Sum_Fields>;
  var_pop?: Maybe<ExpansionJoint_Var_Pop_Fields>;
  var_samp?: Maybe<ExpansionJoint_Var_Samp_Fields>;
  variance?: Maybe<ExpansionJoint_Variance_Fields>;
};


/** aggregate fields of "ExpansionJoint" */
export type ExpansionJoint_Aggregate_FieldsCountArgs = {
  column?: Maybe<ExpansionJoint_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ExpansionJoint" */
export type ExpansionJoint_Aggregate_Order_By = {
  avg?: Maybe<ExpansionJoint_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ExpansionJoint_Max_Order_By>;
  min?: Maybe<ExpansionJoint_Min_Order_By>;
  stddev?: Maybe<ExpansionJoint_Stddev_Order_By>;
  stddev_pop?: Maybe<ExpansionJoint_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ExpansionJoint_Stddev_Samp_Order_By>;
  sum?: Maybe<ExpansionJoint_Sum_Order_By>;
  var_pop?: Maybe<ExpansionJoint_Var_Pop_Order_By>;
  var_samp?: Maybe<ExpansionJoint_Var_Samp_Order_By>;
  variance?: Maybe<ExpansionJoint_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ExpansionJoint_Avg_Fields = {
  __typename?: 'ExpansionJoint_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ExpansionJoint" */
export type ExpansionJoint_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ExpansionJoint". All fields are combined with a logical 'AND'. */
export type ExpansionJoint_Bool_Exp = {
  C_FlashingMaterial?: Maybe<C_FlashingMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  FlashingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  MaxWindRating?: Maybe<Float_Mssql_Comparison_Exp>;
  R_ExpansionJoinFasteners?: Maybe<R_ExpansionJoinFastener_Bool_Exp>;
  _and?: Maybe<Array<ExpansionJoint_Bool_Exp>>;
  _not?: Maybe<ExpansionJoint_Bool_Exp>;
  _or?: Maybe<Array<ExpansionJoint_Bool_Exp>>;
};

/** upsert condition type for table "ExpansionJoint" */
export type ExpansionJoint_If_Matched = {
  match_columns?: Array<ExpansionJoint_Insert_Match_Column>;
  update_columns?: Array<ExpansionJoint_Update_Column>;
  where?: Maybe<ExpansionJoint_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ExpansionJoint" */
export type ExpansionJoint_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "ExpansionJoint" */
export type ExpansionJoint_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "ExpansionJoint" */
export enum ExpansionJoint_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FlashingMaterialId = 'FlashingMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** aggregate max on columns */
export type ExpansionJoint_Max_Fields = {
  __typename?: 'ExpansionJoint_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "ExpansionJoint" */
export type ExpansionJoint_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ExpansionJoint_Min_Fields = {
  __typename?: 'ExpansionJoint_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "ExpansionJoint" */
export type ExpansionJoint_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** response of any mutation on the table "ExpansionJoint" */
export type ExpansionJoint_Mutation_Response = {
  __typename?: 'ExpansionJoint_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExpansionJoint>;
};

/** Ordering options when selecting data from "ExpansionJoint". */
export type ExpansionJoint_Order_By = {
  C_FlashingMaterial?: Maybe<C_FlashingMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  MaxWindRating?: Maybe<Order_By>;
  R_ExpansionJoinFasteners_aggregate?: Maybe<R_ExpansionJoinFastener_Aggregate_Order_By>;
};

/** primary key columns input for table: ExpansionJoint */
export type ExpansionJoint_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "ExpansionJoint" */
export enum ExpansionJoint_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FlashingMaterialId = 'FlashingMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** input type for updating data in table "ExpansionJoint" */
export type ExpansionJoint_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type ExpansionJoint_Stddev_Fields = {
  __typename?: 'ExpansionJoint_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ExpansionJoint" */
export type ExpansionJoint_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ExpansionJoint_Stddev_Pop_Fields = {
  __typename?: 'ExpansionJoint_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ExpansionJoint" */
export type ExpansionJoint_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ExpansionJoint_Stddev_Samp_Fields = {
  __typename?: 'ExpansionJoint_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ExpansionJoint" */
export type ExpansionJoint_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ExpansionJoint_Sum_Fields = {
  __typename?: 'ExpansionJoint_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "ExpansionJoint" */
export type ExpansionJoint_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** update columns of table "ExpansionJoint" */
export enum ExpansionJoint_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FlashingMaterialId = 'FlashingMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** aggregate var_pop on columns */
export type ExpansionJoint_Var_Pop_Fields = {
  __typename?: 'ExpansionJoint_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ExpansionJoint" */
export type ExpansionJoint_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ExpansionJoint_Var_Samp_Fields = {
  __typename?: 'ExpansionJoint_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ExpansionJoint" */
export type ExpansionJoint_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ExpansionJoint_Variance_Fields = {
  __typename?: 'ExpansionJoint_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ExpansionJoint" */
export type ExpansionJoint_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** columns and relationships of "ExtendedLayerConfigSplit" */
export type ExtendedLayerConfigSplit = {
  __typename?: 'ExtendedLayerConfigSplit';
  CompleteConfigString?: Maybe<Scalars['String']>;
  ConfigStringGenericSecurementDuplicateId?: Maybe<Scalars['String']>;
  ExtendedLayerConfigId: Scalars['Int'];
  FullConfigString?: Maybe<Scalars['String']>;
  LayerConfigString?: Maybe<Scalars['String']>;
  SecurementConfigString?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ExtendedLayerConfigSplit" */
export type ExtendedLayerConfigSplit_Aggregate = {
  __typename?: 'ExtendedLayerConfigSplit_aggregate';
  aggregate?: Maybe<ExtendedLayerConfigSplit_Aggregate_Fields>;
  nodes: Array<ExtendedLayerConfigSplit>;
};

/** aggregate fields of "ExtendedLayerConfigSplit" */
export type ExtendedLayerConfigSplit_Aggregate_Fields = {
  __typename?: 'ExtendedLayerConfigSplit_aggregate_fields';
  avg?: Maybe<ExtendedLayerConfigSplit_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ExtendedLayerConfigSplit_Max_Fields>;
  min?: Maybe<ExtendedLayerConfigSplit_Min_Fields>;
  stddev?: Maybe<ExtendedLayerConfigSplit_Stddev_Fields>;
  stddev_pop?: Maybe<ExtendedLayerConfigSplit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ExtendedLayerConfigSplit_Stddev_Samp_Fields>;
  sum?: Maybe<ExtendedLayerConfigSplit_Sum_Fields>;
  var_pop?: Maybe<ExtendedLayerConfigSplit_Var_Pop_Fields>;
  var_samp?: Maybe<ExtendedLayerConfigSplit_Var_Samp_Fields>;
  variance?: Maybe<ExtendedLayerConfigSplit_Variance_Fields>;
};


/** aggregate fields of "ExtendedLayerConfigSplit" */
export type ExtendedLayerConfigSplit_Aggregate_FieldsCountArgs = {
  column?: Maybe<ExtendedLayerConfigSplit_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ExtendedLayerConfigSplit_Avg_Fields = {
  __typename?: 'ExtendedLayerConfigSplit_avg_fields';
  ExtendedLayerConfigId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ExtendedLayerConfigSplit". All fields are combined with a logical 'AND'. */
export type ExtendedLayerConfigSplit_Bool_Exp = {
  CompleteConfigString?: Maybe<String_Mssql_Comparison_Exp>;
  ConfigStringGenericSecurementDuplicateId?: Maybe<String_Mssql_Comparison_Exp>;
  ExtendedLayerConfigId?: Maybe<Int_Mssql_Comparison_Exp>;
  FullConfigString?: Maybe<String_Mssql_Comparison_Exp>;
  LayerConfigString?: Maybe<String_Mssql_Comparison_Exp>;
  SecurementConfigString?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ExtendedLayerConfigSplit_Bool_Exp>>;
  _not?: Maybe<ExtendedLayerConfigSplit_Bool_Exp>;
  _or?: Maybe<Array<ExtendedLayerConfigSplit_Bool_Exp>>;
};

/** upsert condition type for table "ExtendedLayerConfigSplit" */
export type ExtendedLayerConfigSplit_If_Matched = {
  match_columns?: Array<ExtendedLayerConfigSplit_Insert_Match_Column>;
  update_columns?: Array<ExtendedLayerConfigSplit_Update_Column>;
  where?: Maybe<ExtendedLayerConfigSplit_Bool_Exp>;
};

/** input type for inserting data into table "ExtendedLayerConfigSplit" */
export type ExtendedLayerConfigSplit_Insert_Input = {
  CompleteConfigString?: Maybe<Scalars['String']>;
  ConfigStringGenericSecurementDuplicateId?: Maybe<Scalars['String']>;
  FullConfigString?: Maybe<Scalars['String']>;
  LayerConfigString?: Maybe<Scalars['String']>;
  SecurementConfigString?: Maybe<Scalars['String']>;
};

/** select match_columns of table "ExtendedLayerConfigSplit" */
export enum ExtendedLayerConfigSplit_Insert_Match_Column {
  /** column name */
  CompleteConfigString = 'CompleteConfigString',
  /** column name */
  ConfigStringGenericSecurementDuplicateId = 'ConfigStringGenericSecurementDuplicateId',
  /** column name */
  ExtendedLayerConfigId = 'ExtendedLayerConfigId',
  /** column name */
  FullConfigString = 'FullConfigString',
  /** column name */
  LayerConfigString = 'LayerConfigString',
  /** column name */
  SecurementConfigString = 'SecurementConfigString'
}

/** aggregate max on columns */
export type ExtendedLayerConfigSplit_Max_Fields = {
  __typename?: 'ExtendedLayerConfigSplit_max_fields';
  CompleteConfigString?: Maybe<Scalars['String']>;
  ConfigStringGenericSecurementDuplicateId?: Maybe<Scalars['String']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Int']>;
  FullConfigString?: Maybe<Scalars['String']>;
  LayerConfigString?: Maybe<Scalars['String']>;
  SecurementConfigString?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ExtendedLayerConfigSplit_Min_Fields = {
  __typename?: 'ExtendedLayerConfigSplit_min_fields';
  CompleteConfigString?: Maybe<Scalars['String']>;
  ConfigStringGenericSecurementDuplicateId?: Maybe<Scalars['String']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Int']>;
  FullConfigString?: Maybe<Scalars['String']>;
  LayerConfigString?: Maybe<Scalars['String']>;
  SecurementConfigString?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ExtendedLayerConfigSplit" */
export type ExtendedLayerConfigSplit_Mutation_Response = {
  __typename?: 'ExtendedLayerConfigSplit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExtendedLayerConfigSplit>;
};

/** Ordering options when selecting data from "ExtendedLayerConfigSplit". */
export type ExtendedLayerConfigSplit_Order_By = {
  CompleteConfigString?: Maybe<Order_By>;
  ConfigStringGenericSecurementDuplicateId?: Maybe<Order_By>;
  ExtendedLayerConfigId?: Maybe<Order_By>;
  FullConfigString?: Maybe<Order_By>;
  LayerConfigString?: Maybe<Order_By>;
  SecurementConfigString?: Maybe<Order_By>;
};

/** primary key columns input for table: ExtendedLayerConfigSplit */
export type ExtendedLayerConfigSplit_Pk_Columns_Input = {
  ExtendedLayerConfigId: Scalars['Int'];
};

/** select columns of table "ExtendedLayerConfigSplit" */
export enum ExtendedLayerConfigSplit_Select_Column {
  /** column name */
  CompleteConfigString = 'CompleteConfigString',
  /** column name */
  ConfigStringGenericSecurementDuplicateId = 'ConfigStringGenericSecurementDuplicateId',
  /** column name */
  ExtendedLayerConfigId = 'ExtendedLayerConfigId',
  /** column name */
  FullConfigString = 'FullConfigString',
  /** column name */
  LayerConfigString = 'LayerConfigString',
  /** column name */
  SecurementConfigString = 'SecurementConfigString'
}

/** input type for updating data in table "ExtendedLayerConfigSplit" */
export type ExtendedLayerConfigSplit_Set_Input = {
  CompleteConfigString?: Maybe<Scalars['String']>;
  ConfigStringGenericSecurementDuplicateId?: Maybe<Scalars['String']>;
  FullConfigString?: Maybe<Scalars['String']>;
  LayerConfigString?: Maybe<Scalars['String']>;
  SecurementConfigString?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ExtendedLayerConfigSplit_Stddev_Fields = {
  __typename?: 'ExtendedLayerConfigSplit_stddev_fields';
  ExtendedLayerConfigId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ExtendedLayerConfigSplit_Stddev_Pop_Fields = {
  __typename?: 'ExtendedLayerConfigSplit_stddev_pop_fields';
  ExtendedLayerConfigId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ExtendedLayerConfigSplit_Stddev_Samp_Fields = {
  __typename?: 'ExtendedLayerConfigSplit_stddev_samp_fields';
  ExtendedLayerConfigId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ExtendedLayerConfigSplit_Sum_Fields = {
  __typename?: 'ExtendedLayerConfigSplit_sum_fields';
  ExtendedLayerConfigId?: Maybe<Scalars['Int']>;
};

/** update columns of table "ExtendedLayerConfigSplit" */
export enum ExtendedLayerConfigSplit_Update_Column {
  /** column name */
  CompleteConfigString = 'CompleteConfigString',
  /** column name */
  ConfigStringGenericSecurementDuplicateId = 'ConfigStringGenericSecurementDuplicateId',
  /** column name */
  FullConfigString = 'FullConfigString',
  /** column name */
  LayerConfigString = 'LayerConfigString',
  /** column name */
  SecurementConfigString = 'SecurementConfigString'
}

/** aggregate var_pop on columns */
export type ExtendedLayerConfigSplit_Var_Pop_Fields = {
  __typename?: 'ExtendedLayerConfigSplit_var_pop_fields';
  ExtendedLayerConfigId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ExtendedLayerConfigSplit_Var_Samp_Fields = {
  __typename?: 'ExtendedLayerConfigSplit_var_samp_fields';
  ExtendedLayerConfigId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ExtendedLayerConfigSplit_Variance_Fields = {
  __typename?: 'ExtendedLayerConfigSplit_variance_fields';
  ExtendedLayerConfigId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "FMUserAccount" */
export type FmUserAccount = {
  __typename?: 'FMUserAccount';
  /** An array relationship */
  ComponentApprovedUses: Array<ComponentApprovedUse>;
  /** An aggregate relationship */
  ComponentApprovedUses_aggregate: ComponentApprovedUse_Aggregate;
  /** An array relationship */
  Components: Array<Component>;
  /** An aggregate relationship */
  Components_aggregate: Component_Aggregate;
  DomainAccountName: Scalars['String'];
  /** An array relationship */
  ItemWorkspaces: Array<ItemWorkspace>;
  /** An aggregate relationship */
  ItemWorkspaces_aggregate: ItemWorkspace_Aggregate;
  /** An array relationship */
  ManufacturedProducts: Array<ManufacturedProduct>;
  /** An aggregate relationship */
  ManufacturedProducts_aggregate: ManufacturedProduct_Aggregate;
  /** An array relationship */
  Manufacturers: Array<Manufacturer>;
  /** An aggregate relationship */
  Manufacturers_aggregate: Manufacturer_Aggregate;
  /** An array relationship */
  ReviewerWorkspaces: Array<ReviewerWorkspace>;
  /** An aggregate relationship */
  ReviewerWorkspaces_aggregate: ReviewerWorkspace_Aggregate;
  /** An array relationship */
  RoofAssemblies: Array<RoofAssembly>;
  /** An aggregate relationship */
  RoofAssemblies_aggregate: RoofAssembly_Aggregate;
  /** An array relationship */
  Subassemblies: Array<Subassembly>;
  /** An aggregate relationship */
  Subassemblies_aggregate: Subassembly_Aggregate;
  UserAccountId: Scalars['Int'];
  /** An array relationship */
  componentApprovedUsesByModifiedbyuseraccountid: Array<ComponentApprovedUse>;
  /** An aggregate relationship */
  componentApprovedUsesByModifiedbyuseraccountid_aggregate: ComponentApprovedUse_Aggregate;
  /** An array relationship */
  componentsByModifiedbyuseraccountid: Array<Component>;
  /** An aggregate relationship */
  componentsByModifiedbyuseraccountid_aggregate: Component_Aggregate;
  /** An array relationship */
  manufacturersByModifiedbyuseraccountid: Array<Manufacturer>;
  /** An aggregate relationship */
  manufacturersByModifiedbyuseraccountid_aggregate: Manufacturer_Aggregate;
  /** An array relationship */
  reviewerWorkspacesBySubmitteruseracountid: Array<ReviewerWorkspace>;
  /** An aggregate relationship */
  reviewerWorkspacesBySubmitteruseracountid_aggregate: ReviewerWorkspace_Aggregate;
  /** An array relationship */
  roofAssembliesByLockedbyuseraccountid: Array<RoofAssembly>;
  /** An aggregate relationship */
  roofAssembliesByLockedbyuseraccountid_aggregate: RoofAssembly_Aggregate;
  /** An array relationship */
  roofAssembliesByModifiedbyuseraccountid: Array<RoofAssembly>;
  /** An aggregate relationship */
  roofAssembliesByModifiedbyuseraccountid_aggregate: RoofAssembly_Aggregate;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountComponentApprovedUsesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentApprovedUse_Order_By>>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountComponentApprovedUses_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentApprovedUse_Order_By>>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountComponentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Order_By>>;
  where?: Maybe<Component_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountComponents_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Order_By>>;
  where?: Maybe<Component_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountItemWorkspacesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspace_Order_By>>;
  where?: Maybe<ItemWorkspace_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountItemWorkspaces_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspace_Order_By>>;
  where?: Maybe<ItemWorkspace_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountManufacturedProductsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturedProduct_Order_By>>;
  where?: Maybe<ManufacturedProduct_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountManufacturedProducts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturedProduct_Order_By>>;
  where?: Maybe<ManufacturedProduct_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountManufacturersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manufacturer_Order_By>>;
  where?: Maybe<Manufacturer_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountManufacturers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manufacturer_Order_By>>;
  where?: Maybe<Manufacturer_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountReviewerWorkspacesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspace_Order_By>>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountReviewerWorkspaces_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspace_Order_By>>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountRoofAssembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountRoofAssemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountSubassembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subassembly_Order_By>>;
  where?: Maybe<Subassembly_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountSubassemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subassembly_Order_By>>;
  where?: Maybe<Subassembly_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountComponentApprovedUsesByModifiedbyuseraccountidArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentApprovedUse_Order_By>>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountComponentApprovedUsesByModifiedbyuseraccountid_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentApprovedUse_Order_By>>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountComponentsByModifiedbyuseraccountidArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Order_By>>;
  where?: Maybe<Component_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountComponentsByModifiedbyuseraccountid_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Order_By>>;
  where?: Maybe<Component_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountManufacturersByModifiedbyuseraccountidArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manufacturer_Order_By>>;
  where?: Maybe<Manufacturer_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountManufacturersByModifiedbyuseraccountid_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manufacturer_Order_By>>;
  where?: Maybe<Manufacturer_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountReviewerWorkspacesBySubmitteruseracountidArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspace_Order_By>>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountReviewerWorkspacesBySubmitteruseracountid_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspace_Order_By>>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountRoofAssembliesByLockedbyuseraccountidArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountRoofAssembliesByLockedbyuseraccountid_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountRoofAssembliesByModifiedbyuseraccountidArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "FMUserAccount" */
export type FmUserAccountRoofAssembliesByModifiedbyuseraccountid_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};

/** aggregated selection of "FMUserAccount" */
export type FmUserAccount_Aggregate = {
  __typename?: 'FMUserAccount_aggregate';
  aggregate?: Maybe<FmUserAccount_Aggregate_Fields>;
  nodes: Array<FmUserAccount>;
};

/** aggregate fields of "FMUserAccount" */
export type FmUserAccount_Aggregate_Fields = {
  __typename?: 'FMUserAccount_aggregate_fields';
  avg?: Maybe<FmUserAccount_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FmUserAccount_Max_Fields>;
  min?: Maybe<FmUserAccount_Min_Fields>;
  stddev?: Maybe<FmUserAccount_Stddev_Fields>;
  stddev_pop?: Maybe<FmUserAccount_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FmUserAccount_Stddev_Samp_Fields>;
  sum?: Maybe<FmUserAccount_Sum_Fields>;
  var_pop?: Maybe<FmUserAccount_Var_Pop_Fields>;
  var_samp?: Maybe<FmUserAccount_Var_Samp_Fields>;
  variance?: Maybe<FmUserAccount_Variance_Fields>;
};


/** aggregate fields of "FMUserAccount" */
export type FmUserAccount_Aggregate_FieldsCountArgs = {
  column?: Maybe<FmUserAccount_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type FmUserAccount_Avg_Fields = {
  __typename?: 'FMUserAccount_avg_fields';
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "FMUserAccount". All fields are combined with a logical 'AND'. */
export type FmUserAccount_Bool_Exp = {
  ComponentApprovedUses?: Maybe<ComponentApprovedUse_Bool_Exp>;
  Components?: Maybe<Component_Bool_Exp>;
  DomainAccountName?: Maybe<String_Mssql_Comparison_Exp>;
  ItemWorkspaces?: Maybe<ItemWorkspace_Bool_Exp>;
  ManufacturedProducts?: Maybe<ManufacturedProduct_Bool_Exp>;
  Manufacturers?: Maybe<Manufacturer_Bool_Exp>;
  ReviewerWorkspaces?: Maybe<ReviewerWorkspace_Bool_Exp>;
  RoofAssemblies?: Maybe<RoofAssembly_Bool_Exp>;
  Subassemblies?: Maybe<Subassembly_Bool_Exp>;
  UserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<FmUserAccount_Bool_Exp>>;
  _not?: Maybe<FmUserAccount_Bool_Exp>;
  _or?: Maybe<Array<FmUserAccount_Bool_Exp>>;
  componentApprovedUsesByModifiedbyuseraccountid?: Maybe<ComponentApprovedUse_Bool_Exp>;
  componentsByModifiedbyuseraccountid?: Maybe<Component_Bool_Exp>;
  manufacturersByModifiedbyuseraccountid?: Maybe<Manufacturer_Bool_Exp>;
  reviewerWorkspacesBySubmitteruseracountid?: Maybe<ReviewerWorkspace_Bool_Exp>;
  roofAssembliesByLockedbyuseraccountid?: Maybe<RoofAssembly_Bool_Exp>;
  roofAssembliesByModifiedbyuseraccountid?: Maybe<RoofAssembly_Bool_Exp>;
};

/** upsert condition type for table "FMUserAccount" */
export type FmUserAccount_If_Matched = {
  match_columns?: Array<FmUserAccount_Insert_Match_Column>;
  update_columns?: Array<FmUserAccount_Update_Column>;
  where?: Maybe<FmUserAccount_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "FMUserAccount" */
export type FmUserAccount_Inc_Input = {
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "FMUserAccount" */
export type FmUserAccount_Insert_Input = {
  DomainAccountName?: Maybe<Scalars['String']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "FMUserAccount" */
export enum FmUserAccount_Insert_Match_Column {
  /** column name */
  DomainAccountName = 'DomainAccountName',
  /** column name */
  UserAccountId = 'UserAccountId'
}

/** aggregate max on columns */
export type FmUserAccount_Max_Fields = {
  __typename?: 'FMUserAccount_max_fields';
  DomainAccountName?: Maybe<Scalars['String']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type FmUserAccount_Min_Fields = {
  __typename?: 'FMUserAccount_min_fields';
  DomainAccountName?: Maybe<Scalars['String']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "FMUserAccount" */
export type FmUserAccount_Mutation_Response = {
  __typename?: 'FMUserAccount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FmUserAccount>;
};

/** Ordering options when selecting data from "FMUserAccount". */
export type FmUserAccount_Order_By = {
  ComponentApprovedUses_aggregate?: Maybe<ComponentApprovedUse_Aggregate_Order_By>;
  Components_aggregate?: Maybe<Component_Aggregate_Order_By>;
  DomainAccountName?: Maybe<Order_By>;
  ItemWorkspaces_aggregate?: Maybe<ItemWorkspace_Aggregate_Order_By>;
  ManufacturedProducts_aggregate?: Maybe<ManufacturedProduct_Aggregate_Order_By>;
  Manufacturers_aggregate?: Maybe<Manufacturer_Aggregate_Order_By>;
  ReviewerWorkspaces_aggregate?: Maybe<ReviewerWorkspace_Aggregate_Order_By>;
  RoofAssemblies_aggregate?: Maybe<RoofAssembly_Aggregate_Order_By>;
  Subassemblies_aggregate?: Maybe<Subassembly_Aggregate_Order_By>;
  UserAccountId?: Maybe<Order_By>;
  componentApprovedUsesByModifiedbyuseraccountid_aggregate?: Maybe<ComponentApprovedUse_Aggregate_Order_By>;
  componentsByModifiedbyuseraccountid_aggregate?: Maybe<Component_Aggregate_Order_By>;
  manufacturersByModifiedbyuseraccountid_aggregate?: Maybe<Manufacturer_Aggregate_Order_By>;
  reviewerWorkspacesBySubmitteruseracountid_aggregate?: Maybe<ReviewerWorkspace_Aggregate_Order_By>;
  roofAssembliesByLockedbyuseraccountid_aggregate?: Maybe<RoofAssembly_Aggregate_Order_By>;
  roofAssembliesByModifiedbyuseraccountid_aggregate?: Maybe<RoofAssembly_Aggregate_Order_By>;
};

/** primary key columns input for table: FMUserAccount */
export type FmUserAccount_Pk_Columns_Input = {
  UserAccountId: Scalars['Int'];
};

/** select columns of table "FMUserAccount" */
export enum FmUserAccount_Select_Column {
  /** column name */
  DomainAccountName = 'DomainAccountName',
  /** column name */
  UserAccountId = 'UserAccountId'
}

/** input type for updating data in table "FMUserAccount" */
export type FmUserAccount_Set_Input = {
  DomainAccountName?: Maybe<Scalars['String']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type FmUserAccount_Stddev_Fields = {
  __typename?: 'FMUserAccount_stddev_fields';
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FmUserAccount_Stddev_Pop_Fields = {
  __typename?: 'FMUserAccount_stddev_pop_fields';
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FmUserAccount_Stddev_Samp_Fields = {
  __typename?: 'FMUserAccount_stddev_samp_fields';
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type FmUserAccount_Sum_Fields = {
  __typename?: 'FMUserAccount_sum_fields';
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** update columns of table "FMUserAccount" */
export enum FmUserAccount_Update_Column {
  /** column name */
  DomainAccountName = 'DomainAccountName',
  /** column name */
  UserAccountId = 'UserAccountId'
}

/** aggregate var_pop on columns */
export type FmUserAccount_Var_Pop_Fields = {
  __typename?: 'FMUserAccount_var_pop_fields';
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FmUserAccount_Var_Samp_Fields = {
  __typename?: 'FMUserAccount_var_samp_fields';
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FmUserAccount_Variance_Fields = {
  __typename?: 'FMUserAccount_variance_fields';
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Fascia" */
export type Fascia = {
  __typename?: 'Fascia';
  /** An object relationship */
  C_FlashingMaterial: C_FlashingMaterial;
  ComponentId: Scalars['Int'];
  FlashingMaterialId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  MaxWindRating: Scalars['Float'];
  /** An array relationship */
  R_FasciaComponentFastenerComponents: Array<R_FasciaComponentFastenerComponent>;
  /** An aggregate relationship */
  R_FasciaComponentFastenerComponents_aggregate: R_FasciaComponentFastenerComponent_Aggregate;
};


/** columns and relationships of "Fascia" */
export type FasciaR_FasciaComponentFastenerComponentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_FasciaComponentFastenerComponent_Order_By>>;
  where?: Maybe<R_FasciaComponentFastenerComponent_Bool_Exp>;
};


/** columns and relationships of "Fascia" */
export type FasciaR_FasciaComponentFastenerComponents_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_FasciaComponentFastenerComponent_Order_By>>;
  where?: Maybe<R_FasciaComponentFastenerComponent_Bool_Exp>;
};

/** aggregated selection of "Fascia" */
export type Fascia_Aggregate = {
  __typename?: 'Fascia_aggregate';
  aggregate?: Maybe<Fascia_Aggregate_Fields>;
  nodes: Array<Fascia>;
};

/** aggregate fields of "Fascia" */
export type Fascia_Aggregate_Fields = {
  __typename?: 'Fascia_aggregate_fields';
  avg?: Maybe<Fascia_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Fascia_Max_Fields>;
  min?: Maybe<Fascia_Min_Fields>;
  stddev?: Maybe<Fascia_Stddev_Fields>;
  stddev_pop?: Maybe<Fascia_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fascia_Stddev_Samp_Fields>;
  sum?: Maybe<Fascia_Sum_Fields>;
  var_pop?: Maybe<Fascia_Var_Pop_Fields>;
  var_samp?: Maybe<Fascia_Var_Samp_Fields>;
  variance?: Maybe<Fascia_Variance_Fields>;
};


/** aggregate fields of "Fascia" */
export type Fascia_Aggregate_FieldsCountArgs = {
  column?: Maybe<Fascia_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Fascia" */
export type Fascia_Aggregate_Order_By = {
  avg?: Maybe<Fascia_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Fascia_Max_Order_By>;
  min?: Maybe<Fascia_Min_Order_By>;
  stddev?: Maybe<Fascia_Stddev_Order_By>;
  stddev_pop?: Maybe<Fascia_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Fascia_Stddev_Samp_Order_By>;
  sum?: Maybe<Fascia_Sum_Order_By>;
  var_pop?: Maybe<Fascia_Var_Pop_Order_By>;
  var_samp?: Maybe<Fascia_Var_Samp_Order_By>;
  variance?: Maybe<Fascia_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Fascia_Avg_Fields = {
  __typename?: 'Fascia_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Fascia" */
export type Fascia_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Fascia". All fields are combined with a logical 'AND'. */
export type Fascia_Bool_Exp = {
  C_FlashingMaterial?: Maybe<C_FlashingMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  FlashingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  MaxWindRating?: Maybe<Float_Mssql_Comparison_Exp>;
  R_FasciaComponentFastenerComponents?: Maybe<R_FasciaComponentFastenerComponent_Bool_Exp>;
  _and?: Maybe<Array<Fascia_Bool_Exp>>;
  _not?: Maybe<Fascia_Bool_Exp>;
  _or?: Maybe<Array<Fascia_Bool_Exp>>;
};

/** upsert condition type for table "Fascia" */
export type Fascia_If_Matched = {
  match_columns?: Array<Fascia_Insert_Match_Column>;
  update_columns?: Array<Fascia_Update_Column>;
  where?: Maybe<Fascia_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Fascia" */
export type Fascia_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "Fascia" */
export type Fascia_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "Fascia" */
export enum Fascia_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FlashingMaterialId = 'FlashingMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** aggregate max on columns */
export type Fascia_Max_Fields = {
  __typename?: 'Fascia_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "Fascia" */
export type Fascia_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Fascia_Min_Fields = {
  __typename?: 'Fascia_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "Fascia" */
export type Fascia_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** response of any mutation on the table "Fascia" */
export type Fascia_Mutation_Response = {
  __typename?: 'Fascia_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fascia>;
};

/** Ordering options when selecting data from "Fascia". */
export type Fascia_Order_By = {
  C_FlashingMaterial?: Maybe<C_FlashingMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  MaxWindRating?: Maybe<Order_By>;
  R_FasciaComponentFastenerComponents_aggregate?: Maybe<R_FasciaComponentFastenerComponent_Aggregate_Order_By>;
};

/** primary key columns input for table: Fascia */
export type Fascia_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "Fascia" */
export enum Fascia_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FlashingMaterialId = 'FlashingMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** input type for updating data in table "Fascia" */
export type Fascia_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Fascia_Stddev_Fields = {
  __typename?: 'Fascia_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Fascia" */
export type Fascia_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Fascia_Stddev_Pop_Fields = {
  __typename?: 'Fascia_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Fascia" */
export type Fascia_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Fascia_Stddev_Samp_Fields = {
  __typename?: 'Fascia_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Fascia" */
export type Fascia_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Fascia_Sum_Fields = {
  __typename?: 'Fascia_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "Fascia" */
export type Fascia_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** update columns of table "Fascia" */
export enum Fascia_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FlashingMaterialId = 'FlashingMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** aggregate var_pop on columns */
export type Fascia_Var_Pop_Fields = {
  __typename?: 'Fascia_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Fascia" */
export type Fascia_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Fascia_Var_Samp_Fields = {
  __typename?: 'Fascia_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Fascia" */
export type Fascia_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Fascia_Variance_Fields = {
  __typename?: 'Fascia_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Fascia" */
export type Fascia_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** columns and relationships of "Fastener" */
export type Fastener = {
  __typename?: 'Fastener';
  /** An object relationship */
  C_FastenerCoating: C_FastenerCoating;
  /** An object relationship */
  C_FastenerDriveHead: C_FastenerDriveHead;
  /** An object relationship */
  C_FastenerType: C_FastenerType;
  /** An object relationship */
  C_SecurementMaterial: C_SecurementMaterial;
  ComponentId: Scalars['Int'];
  DeckDesignThicknessMax: Scalars['MeasurementValue'];
  Diameter: Scalars['MeasurementValue'];
  FastenerCoatingId: Scalars['Int'];
  FastenerDriveHeadId: Scalars['Int'];
  FastenerTypeId: Scalars['Int'];
  IsSecuredToConcreteDeck: Scalars['Boolean'];
  IsSteelDeckFastener: Scalars['Boolean'];
  LengthMax: Scalars['MeasurementValue'];
  LengthMin: Scalars['MeasurementValue'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  /** An array relationship */
  R_CopingFasteners: Array<R_CopingFastener>;
  /** An aggregate relationship */
  R_CopingFasteners_aggregate: R_CopingFastener_Aggregate;
  /** An array relationship */
  R_FasciaComponentFastenerComponents: Array<R_FasciaComponentFastenerComponent>;
  /** An aggregate relationship */
  R_FasciaComponentFastenerComponents_aggregate: R_FasciaComponentFastenerComponent_Aggregate;
  /** An array relationship */
  R_GutterFasteners: Array<R_GutterFasteners>;
  /** An aggregate relationship */
  R_GutterFasteners_aggregate: R_GutterFasteners_Aggregate;
  /** An array relationship */
  R_RegletFasteners: Array<R_RegletFastener>;
  /** An aggregate relationship */
  R_RegletFasteners_aggregate: R_RegletFastener_Aggregate;
  SecurementMaterialId: Scalars['Int'];
  StructureThicknessMax: Scalars['MeasurementValue'];
  StructureThicknessMin: Scalars['MeasurementValue'];
};


/** columns and relationships of "Fastener" */
export type FastenerR_CopingFastenersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_CopingFastener_Order_By>>;
  where?: Maybe<R_CopingFastener_Bool_Exp>;
};


/** columns and relationships of "Fastener" */
export type FastenerR_CopingFasteners_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_CopingFastener_Order_By>>;
  where?: Maybe<R_CopingFastener_Bool_Exp>;
};


/** columns and relationships of "Fastener" */
export type FastenerR_FasciaComponentFastenerComponentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_FasciaComponentFastenerComponent_Order_By>>;
  where?: Maybe<R_FasciaComponentFastenerComponent_Bool_Exp>;
};


/** columns and relationships of "Fastener" */
export type FastenerR_FasciaComponentFastenerComponents_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_FasciaComponentFastenerComponent_Order_By>>;
  where?: Maybe<R_FasciaComponentFastenerComponent_Bool_Exp>;
};


/** columns and relationships of "Fastener" */
export type FastenerR_GutterFastenersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_GutterFasteners_Order_By>>;
  where?: Maybe<R_GutterFasteners_Bool_Exp>;
};


/** columns and relationships of "Fastener" */
export type FastenerR_GutterFasteners_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_GutterFasteners_Order_By>>;
  where?: Maybe<R_GutterFasteners_Bool_Exp>;
};


/** columns and relationships of "Fastener" */
export type FastenerR_RegletFastenersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RegletFastener_Order_By>>;
  where?: Maybe<R_RegletFastener_Bool_Exp>;
};


/** columns and relationships of "Fastener" */
export type FastenerR_RegletFasteners_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RegletFastener_Order_By>>;
  where?: Maybe<R_RegletFastener_Bool_Exp>;
};

/** columns and relationships of "FastenerUsage" */
export type FastenerUsage = {
  __typename?: 'FastenerUsage';
  /** An object relationship */
  C_FasteningApplicationMethod: C_FasteningApplicationMethod;
  ContributoryArea: Scalars['MeasurementValue'];
  Embedment: Scalars['MeasurementValue'];
  FasteningApplicationMethodId: Scalars['Int'];
  FieldRowsOnCenter: Scalars['MeasurementValue'];
  IsFieldRows: Scalars['Boolean'];
  NumFieldRows: Scalars['Int'];
  OnCenter: Scalars['MeasurementValue'];
  RowSpacing: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "FastenerUsage" */
export type FastenerUsage_Aggregate = {
  __typename?: 'FastenerUsage_aggregate';
  aggregate?: Maybe<FastenerUsage_Aggregate_Fields>;
  nodes: Array<FastenerUsage>;
};

/** aggregate fields of "FastenerUsage" */
export type FastenerUsage_Aggregate_Fields = {
  __typename?: 'FastenerUsage_aggregate_fields';
  avg?: Maybe<FastenerUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FastenerUsage_Max_Fields>;
  min?: Maybe<FastenerUsage_Min_Fields>;
  stddev?: Maybe<FastenerUsage_Stddev_Fields>;
  stddev_pop?: Maybe<FastenerUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FastenerUsage_Stddev_Samp_Fields>;
  sum?: Maybe<FastenerUsage_Sum_Fields>;
  var_pop?: Maybe<FastenerUsage_Var_Pop_Fields>;
  var_samp?: Maybe<FastenerUsage_Var_Samp_Fields>;
  variance?: Maybe<FastenerUsage_Variance_Fields>;
};


/** aggregate fields of "FastenerUsage" */
export type FastenerUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<FastenerUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FastenerUsage" */
export type FastenerUsage_Aggregate_Order_By = {
  avg?: Maybe<FastenerUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<FastenerUsage_Max_Order_By>;
  min?: Maybe<FastenerUsage_Min_Order_By>;
  stddev?: Maybe<FastenerUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<FastenerUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<FastenerUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<FastenerUsage_Sum_Order_By>;
  var_pop?: Maybe<FastenerUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<FastenerUsage_Var_Samp_Order_By>;
  variance?: Maybe<FastenerUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type FastenerUsage_Avg_Fields = {
  __typename?: 'FastenerUsage_avg_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FastenerUsage" */
export type FastenerUsage_Avg_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FastenerUsage". All fields are combined with a logical 'AND'. */
export type FastenerUsage_Bool_Exp = {
  C_FasteningApplicationMethod?: Maybe<C_FasteningApplicationMethod_Bool_Exp>;
  ContributoryArea?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Embedment?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  FasteningApplicationMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  FieldRowsOnCenter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  IsFieldRows?: Maybe<Boolean_Mssql_Comparison_Exp>;
  NumFieldRows?: Maybe<Int_Mssql_Comparison_Exp>;
  OnCenter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  RowSpacing?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<FastenerUsage_Bool_Exp>>;
  _not?: Maybe<FastenerUsage_Bool_Exp>;
  _or?: Maybe<Array<FastenerUsage_Bool_Exp>>;
};

/** upsert condition type for table "FastenerUsage" */
export type FastenerUsage_If_Matched = {
  match_columns?: Array<FastenerUsage_Insert_Match_Column>;
  update_columns?: Array<FastenerUsage_Update_Column>;
  where?: Maybe<FastenerUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "FastenerUsage" */
export type FastenerUsage_Inc_Input = {
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "FastenerUsage" */
export type FastenerUsage_Insert_Input = {
  ContributoryArea?: Maybe<Scalars['MeasurementValue']>;
  Embedment?: Maybe<Scalars['MeasurementValue']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  IsFieldRows?: Maybe<Scalars['Boolean']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "FastenerUsage" */
export enum FastenerUsage_Insert_Match_Column {
  /** column name */
  ContributoryArea = 'ContributoryArea',
  /** column name */
  Embedment = 'Embedment',
  /** column name */
  FasteningApplicationMethodId = 'FasteningApplicationMethodId',
  /** column name */
  FieldRowsOnCenter = 'FieldRowsOnCenter',
  /** column name */
  IsFieldRows = 'IsFieldRows',
  /** column name */
  NumFieldRows = 'NumFieldRows',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type FastenerUsage_Max_Fields = {
  __typename?: 'FastenerUsage_max_fields';
  ContributoryArea?: Maybe<Scalars['MeasurementValue']>;
  Embedment?: Maybe<Scalars['MeasurementValue']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "FastenerUsage" */
export type FastenerUsage_Max_Order_By = {
  ContributoryArea?: Maybe<Order_By>;
  Embedment?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  FieldRowsOnCenter?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type FastenerUsage_Min_Fields = {
  __typename?: 'FastenerUsage_min_fields';
  ContributoryArea?: Maybe<Scalars['MeasurementValue']>;
  Embedment?: Maybe<Scalars['MeasurementValue']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "FastenerUsage" */
export type FastenerUsage_Min_Order_By = {
  ContributoryArea?: Maybe<Order_By>;
  Embedment?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  FieldRowsOnCenter?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "FastenerUsage" */
export type FastenerUsage_Mutation_Response = {
  __typename?: 'FastenerUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FastenerUsage>;
};

/** Ordering options when selecting data from "FastenerUsage". */
export type FastenerUsage_Order_By = {
  C_FasteningApplicationMethod?: Maybe<C_FasteningApplicationMethod_Order_By>;
  ContributoryArea?: Maybe<Order_By>;
  Embedment?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  FieldRowsOnCenter?: Maybe<Order_By>;
  IsFieldRows?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: FastenerUsage */
export type FastenerUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "FastenerUsage" */
export enum FastenerUsage_Select_Column {
  /** column name */
  ContributoryArea = 'ContributoryArea',
  /** column name */
  Embedment = 'Embedment',
  /** column name */
  FasteningApplicationMethodId = 'FasteningApplicationMethodId',
  /** column name */
  FieldRowsOnCenter = 'FieldRowsOnCenter',
  /** column name */
  IsFieldRows = 'IsFieldRows',
  /** column name */
  NumFieldRows = 'NumFieldRows',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "FastenerUsage" */
export type FastenerUsage_Set_Input = {
  ContributoryArea?: Maybe<Scalars['MeasurementValue']>;
  Embedment?: Maybe<Scalars['MeasurementValue']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  IsFieldRows?: Maybe<Scalars['Boolean']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type FastenerUsage_Stddev_Fields = {
  __typename?: 'FastenerUsage_stddev_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FastenerUsage" */
export type FastenerUsage_Stddev_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FastenerUsage_Stddev_Pop_Fields = {
  __typename?: 'FastenerUsage_stddev_pop_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FastenerUsage" */
export type FastenerUsage_Stddev_Pop_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FastenerUsage_Stddev_Samp_Fields = {
  __typename?: 'FastenerUsage_stddev_samp_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FastenerUsage" */
export type FastenerUsage_Stddev_Samp_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type FastenerUsage_Sum_Fields = {
  __typename?: 'FastenerUsage_sum_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "FastenerUsage" */
export type FastenerUsage_Sum_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "FastenerUsage" */
export enum FastenerUsage_Update_Column {
  /** column name */
  ContributoryArea = 'ContributoryArea',
  /** column name */
  Embedment = 'Embedment',
  /** column name */
  FasteningApplicationMethodId = 'FasteningApplicationMethodId',
  /** column name */
  FieldRowsOnCenter = 'FieldRowsOnCenter',
  /** column name */
  IsFieldRows = 'IsFieldRows',
  /** column name */
  NumFieldRows = 'NumFieldRows',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type FastenerUsage_Var_Pop_Fields = {
  __typename?: 'FastenerUsage_var_pop_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FastenerUsage" */
export type FastenerUsage_Var_Pop_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FastenerUsage_Var_Samp_Fields = {
  __typename?: 'FastenerUsage_var_samp_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FastenerUsage" */
export type FastenerUsage_Var_Samp_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type FastenerUsage_Variance_Fields = {
  __typename?: 'FastenerUsage_variance_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FastenerUsage" */
export type FastenerUsage_Variance_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregated selection of "Fastener" */
export type Fastener_Aggregate = {
  __typename?: 'Fastener_aggregate';
  aggregate?: Maybe<Fastener_Aggregate_Fields>;
  nodes: Array<Fastener>;
};

/** aggregate fields of "Fastener" */
export type Fastener_Aggregate_Fields = {
  __typename?: 'Fastener_aggregate_fields';
  avg?: Maybe<Fastener_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Fastener_Max_Fields>;
  min?: Maybe<Fastener_Min_Fields>;
  stddev?: Maybe<Fastener_Stddev_Fields>;
  stddev_pop?: Maybe<Fastener_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fastener_Stddev_Samp_Fields>;
  sum?: Maybe<Fastener_Sum_Fields>;
  var_pop?: Maybe<Fastener_Var_Pop_Fields>;
  var_samp?: Maybe<Fastener_Var_Samp_Fields>;
  variance?: Maybe<Fastener_Variance_Fields>;
};


/** aggregate fields of "Fastener" */
export type Fastener_Aggregate_FieldsCountArgs = {
  column?: Maybe<Fastener_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Fastener" */
export type Fastener_Aggregate_Order_By = {
  avg?: Maybe<Fastener_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Fastener_Max_Order_By>;
  min?: Maybe<Fastener_Min_Order_By>;
  stddev?: Maybe<Fastener_Stddev_Order_By>;
  stddev_pop?: Maybe<Fastener_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Fastener_Stddev_Samp_Order_By>;
  sum?: Maybe<Fastener_Sum_Order_By>;
  var_pop?: Maybe<Fastener_Var_Pop_Order_By>;
  var_samp?: Maybe<Fastener_Var_Samp_Order_By>;
  variance?: Maybe<Fastener_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Fastener_Avg_Fields = {
  __typename?: 'Fastener_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FastenerCoatingId?: Maybe<Scalars['Float']>;
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
  FastenerTypeId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Fastener" */
export type Fastener_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Fastener". All fields are combined with a logical 'AND'. */
export type Fastener_Bool_Exp = {
  C_FastenerCoating?: Maybe<C_FastenerCoating_Bool_Exp>;
  C_FastenerDriveHead?: Maybe<C_FastenerDriveHead_Bool_Exp>;
  C_FastenerType?: Maybe<C_FastenerType_Bool_Exp>;
  C_SecurementMaterial?: Maybe<C_SecurementMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  DeckDesignThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Diameter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  FastenerCoatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  FastenerDriveHeadId?: Maybe<Int_Mssql_Comparison_Exp>;
  FastenerTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  IsSecuredToConcreteDeck?: Maybe<Boolean_Mssql_Comparison_Exp>;
  IsSteelDeckFastener?: Maybe<Boolean_Mssql_Comparison_Exp>;
  LengthMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  LengthMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  R_CopingFasteners?: Maybe<R_CopingFastener_Bool_Exp>;
  R_FasciaComponentFastenerComponents?: Maybe<R_FasciaComponentFastenerComponent_Bool_Exp>;
  R_GutterFasteners?: Maybe<R_GutterFasteners_Bool_Exp>;
  R_RegletFasteners?: Maybe<R_RegletFastener_Bool_Exp>;
  SecurementMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  StructureThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  StructureThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Fastener_Bool_Exp>>;
  _not?: Maybe<Fastener_Bool_Exp>;
  _or?: Maybe<Array<Fastener_Bool_Exp>>;
};

/** upsert condition type for table "Fastener" */
export type Fastener_If_Matched = {
  match_columns?: Array<Fastener_Insert_Match_Column>;
  update_columns?: Array<Fastener_Update_Column>;
  where?: Maybe<Fastener_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Fastener" */
export type Fastener_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FastenerCoatingId?: Maybe<Scalars['Int']>;
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
  FastenerTypeId?: Maybe<Scalars['Int']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Fastener" */
export type Fastener_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DeckDesignThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  FastenerCoatingId?: Maybe<Scalars['Int']>;
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
  FastenerTypeId?: Maybe<Scalars['Int']>;
  IsSecuredToConcreteDeck?: Maybe<Scalars['Boolean']>;
  IsSteelDeckFastener?: Maybe<Scalars['Boolean']>;
  LengthMax?: Maybe<Scalars['MeasurementValue']>;
  LengthMin?: Maybe<Scalars['MeasurementValue']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
  StructureThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  StructureThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "Fastener" */
export enum Fastener_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DeckDesignThicknessMax = 'DeckDesignThicknessMax',
  /** column name */
  Diameter = 'Diameter',
  /** column name */
  FastenerCoatingId = 'FastenerCoatingId',
  /** column name */
  FastenerDriveHeadId = 'FastenerDriveHeadId',
  /** column name */
  FastenerTypeId = 'FastenerTypeId',
  /** column name */
  IsSecuredToConcreteDeck = 'IsSecuredToConcreteDeck',
  /** column name */
  IsSteelDeckFastener = 'IsSteelDeckFastener',
  /** column name */
  LengthMax = 'LengthMax',
  /** column name */
  LengthMin = 'LengthMin',
  /** column name */
  SecurementMaterialId = 'SecurementMaterialId',
  /** column name */
  StructureThicknessMax = 'StructureThicknessMax',
  /** column name */
  StructureThicknessMin = 'StructureThicknessMin'
}

/** aggregate max on columns */
export type Fastener_Max_Fields = {
  __typename?: 'Fastener_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DeckDesignThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  FastenerCoatingId?: Maybe<Scalars['Int']>;
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
  FastenerTypeId?: Maybe<Scalars['Int']>;
  LengthMax?: Maybe<Scalars['MeasurementValue']>;
  LengthMin?: Maybe<Scalars['MeasurementValue']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
  StructureThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  StructureThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "Fastener" */
export type Fastener_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DeckDesignThicknessMax?: Maybe<Order_By>;
  Diameter?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  LengthMax?: Maybe<Order_By>;
  LengthMin?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StructureThicknessMax?: Maybe<Order_By>;
  StructureThicknessMin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Fastener_Min_Fields = {
  __typename?: 'Fastener_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DeckDesignThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  FastenerCoatingId?: Maybe<Scalars['Int']>;
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
  FastenerTypeId?: Maybe<Scalars['Int']>;
  LengthMax?: Maybe<Scalars['MeasurementValue']>;
  LengthMin?: Maybe<Scalars['MeasurementValue']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
  StructureThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  StructureThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "Fastener" */
export type Fastener_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DeckDesignThicknessMax?: Maybe<Order_By>;
  Diameter?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  LengthMax?: Maybe<Order_By>;
  LengthMin?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StructureThicknessMax?: Maybe<Order_By>;
  StructureThicknessMin?: Maybe<Order_By>;
};

/** response of any mutation on the table "Fastener" */
export type Fastener_Mutation_Response = {
  __typename?: 'Fastener_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fastener>;
};

/** Ordering options when selecting data from "Fastener". */
export type Fastener_Order_By = {
  C_FastenerCoating?: Maybe<C_FastenerCoating_Order_By>;
  C_FastenerDriveHead?: Maybe<C_FastenerDriveHead_Order_By>;
  C_FastenerType?: Maybe<C_FastenerType_Order_By>;
  C_SecurementMaterial?: Maybe<C_SecurementMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  DeckDesignThicknessMax?: Maybe<Order_By>;
  Diameter?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  IsSecuredToConcreteDeck?: Maybe<Order_By>;
  IsSteelDeckFastener?: Maybe<Order_By>;
  LengthMax?: Maybe<Order_By>;
  LengthMin?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  R_CopingFasteners_aggregate?: Maybe<R_CopingFastener_Aggregate_Order_By>;
  R_FasciaComponentFastenerComponents_aggregate?: Maybe<R_FasciaComponentFastenerComponent_Aggregate_Order_By>;
  R_GutterFasteners_aggregate?: Maybe<R_GutterFasteners_Aggregate_Order_By>;
  R_RegletFasteners_aggregate?: Maybe<R_RegletFastener_Aggregate_Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StructureThicknessMax?: Maybe<Order_By>;
  StructureThicknessMin?: Maybe<Order_By>;
};

/** primary key columns input for table: Fastener */
export type Fastener_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "Fastener" */
export enum Fastener_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DeckDesignThicknessMax = 'DeckDesignThicknessMax',
  /** column name */
  Diameter = 'Diameter',
  /** column name */
  FastenerCoatingId = 'FastenerCoatingId',
  /** column name */
  FastenerDriveHeadId = 'FastenerDriveHeadId',
  /** column name */
  FastenerTypeId = 'FastenerTypeId',
  /** column name */
  IsSecuredToConcreteDeck = 'IsSecuredToConcreteDeck',
  /** column name */
  IsSteelDeckFastener = 'IsSteelDeckFastener',
  /** column name */
  LengthMax = 'LengthMax',
  /** column name */
  LengthMin = 'LengthMin',
  /** column name */
  SecurementMaterialId = 'SecurementMaterialId',
  /** column name */
  StructureThicknessMax = 'StructureThicknessMax',
  /** column name */
  StructureThicknessMin = 'StructureThicknessMin'
}

/** input type for updating data in table "Fastener" */
export type Fastener_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DeckDesignThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  FastenerCoatingId?: Maybe<Scalars['Int']>;
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
  FastenerTypeId?: Maybe<Scalars['Int']>;
  IsSecuredToConcreteDeck?: Maybe<Scalars['Boolean']>;
  IsSteelDeckFastener?: Maybe<Scalars['Boolean']>;
  LengthMax?: Maybe<Scalars['MeasurementValue']>;
  LengthMin?: Maybe<Scalars['MeasurementValue']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
  StructureThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  StructureThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type Fastener_Stddev_Fields = {
  __typename?: 'Fastener_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FastenerCoatingId?: Maybe<Scalars['Float']>;
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
  FastenerTypeId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Fastener" */
export type Fastener_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Fastener_Stddev_Pop_Fields = {
  __typename?: 'Fastener_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FastenerCoatingId?: Maybe<Scalars['Float']>;
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
  FastenerTypeId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Fastener" */
export type Fastener_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Fastener_Stddev_Samp_Fields = {
  __typename?: 'Fastener_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FastenerCoatingId?: Maybe<Scalars['Float']>;
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
  FastenerTypeId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Fastener" */
export type Fastener_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Fastener_Sum_Fields = {
  __typename?: 'Fastener_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FastenerCoatingId?: Maybe<Scalars['Int']>;
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
  FastenerTypeId?: Maybe<Scalars['Int']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Fastener" */
export type Fastener_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** update columns of table "Fastener" */
export enum Fastener_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DeckDesignThicknessMax = 'DeckDesignThicknessMax',
  /** column name */
  Diameter = 'Diameter',
  /** column name */
  FastenerCoatingId = 'FastenerCoatingId',
  /** column name */
  FastenerDriveHeadId = 'FastenerDriveHeadId',
  /** column name */
  FastenerTypeId = 'FastenerTypeId',
  /** column name */
  IsSecuredToConcreteDeck = 'IsSecuredToConcreteDeck',
  /** column name */
  IsSteelDeckFastener = 'IsSteelDeckFastener',
  /** column name */
  LengthMax = 'LengthMax',
  /** column name */
  LengthMin = 'LengthMin',
  /** column name */
  SecurementMaterialId = 'SecurementMaterialId',
  /** column name */
  StructureThicknessMax = 'StructureThicknessMax',
  /** column name */
  StructureThicknessMin = 'StructureThicknessMin'
}

/** aggregate var_pop on columns */
export type Fastener_Var_Pop_Fields = {
  __typename?: 'Fastener_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FastenerCoatingId?: Maybe<Scalars['Float']>;
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
  FastenerTypeId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Fastener" */
export type Fastener_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Fastener_Var_Samp_Fields = {
  __typename?: 'Fastener_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FastenerCoatingId?: Maybe<Scalars['Float']>;
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
  FastenerTypeId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Fastener" */
export type Fastener_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Fastener_Variance_Fields = {
  __typename?: 'Fastener_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FastenerCoatingId?: Maybe<Scalars['Float']>;
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
  FastenerTypeId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Fastener" */
export type Fastener_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage = {
  __typename?: 'FasteningSystemBattenBarUsage';
  /** An object relationship */
  C_FasteningApplicationMethod: C_FasteningApplicationMethod;
  FasteningApplicationMethodId: Scalars['Int'];
  FieldRowsOnCenter: Scalars['MeasurementValue'];
  IsFieldRows: Scalars['Boolean'];
  NumFieldRows: Scalars['Int'];
  OnCenter: Scalars['MeasurementValue'];
  RowSpacing: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Aggregate = {
  __typename?: 'FasteningSystemBattenBarUsage_aggregate';
  aggregate?: Maybe<FasteningSystemBattenBarUsage_Aggregate_Fields>;
  nodes: Array<FasteningSystemBattenBarUsage>;
};

/** aggregate fields of "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Aggregate_Fields = {
  __typename?: 'FasteningSystemBattenBarUsage_aggregate_fields';
  avg?: Maybe<FasteningSystemBattenBarUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FasteningSystemBattenBarUsage_Max_Fields>;
  min?: Maybe<FasteningSystemBattenBarUsage_Min_Fields>;
  stddev?: Maybe<FasteningSystemBattenBarUsage_Stddev_Fields>;
  stddev_pop?: Maybe<FasteningSystemBattenBarUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FasteningSystemBattenBarUsage_Stddev_Samp_Fields>;
  sum?: Maybe<FasteningSystemBattenBarUsage_Sum_Fields>;
  var_pop?: Maybe<FasteningSystemBattenBarUsage_Var_Pop_Fields>;
  var_samp?: Maybe<FasteningSystemBattenBarUsage_Var_Samp_Fields>;
  variance?: Maybe<FasteningSystemBattenBarUsage_Variance_Fields>;
};


/** aggregate fields of "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<FasteningSystemBattenBarUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Aggregate_Order_By = {
  avg?: Maybe<FasteningSystemBattenBarUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<FasteningSystemBattenBarUsage_Max_Order_By>;
  min?: Maybe<FasteningSystemBattenBarUsage_Min_Order_By>;
  stddev?: Maybe<FasteningSystemBattenBarUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<FasteningSystemBattenBarUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<FasteningSystemBattenBarUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<FasteningSystemBattenBarUsage_Sum_Order_By>;
  var_pop?: Maybe<FasteningSystemBattenBarUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<FasteningSystemBattenBarUsage_Var_Samp_Order_By>;
  variance?: Maybe<FasteningSystemBattenBarUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type FasteningSystemBattenBarUsage_Avg_Fields = {
  __typename?: 'FasteningSystemBattenBarUsage_avg_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Avg_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FasteningSystemBattenBarUsage". All fields are combined with a logical 'AND'. */
export type FasteningSystemBattenBarUsage_Bool_Exp = {
  C_FasteningApplicationMethod?: Maybe<C_FasteningApplicationMethod_Bool_Exp>;
  FasteningApplicationMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  FieldRowsOnCenter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  IsFieldRows?: Maybe<Boolean_Mssql_Comparison_Exp>;
  NumFieldRows?: Maybe<Int_Mssql_Comparison_Exp>;
  OnCenter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  RowSpacing?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<FasteningSystemBattenBarUsage_Bool_Exp>>;
  _not?: Maybe<FasteningSystemBattenBarUsage_Bool_Exp>;
  _or?: Maybe<Array<FasteningSystemBattenBarUsage_Bool_Exp>>;
};

/** upsert condition type for table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_If_Matched = {
  match_columns?: Array<FasteningSystemBattenBarUsage_Insert_Match_Column>;
  update_columns?: Array<FasteningSystemBattenBarUsage_Update_Column>;
  where?: Maybe<FasteningSystemBattenBarUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Inc_Input = {
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Insert_Input = {
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  IsFieldRows?: Maybe<Scalars['Boolean']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "FasteningSystemBattenBarUsage" */
export enum FasteningSystemBattenBarUsage_Insert_Match_Column {
  /** column name */
  FasteningApplicationMethodId = 'FasteningApplicationMethodId',
  /** column name */
  FieldRowsOnCenter = 'FieldRowsOnCenter',
  /** column name */
  IsFieldRows = 'IsFieldRows',
  /** column name */
  NumFieldRows = 'NumFieldRows',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type FasteningSystemBattenBarUsage_Max_Fields = {
  __typename?: 'FasteningSystemBattenBarUsage_max_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Max_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  FieldRowsOnCenter?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type FasteningSystemBattenBarUsage_Min_Fields = {
  __typename?: 'FasteningSystemBattenBarUsage_min_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Min_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  FieldRowsOnCenter?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Mutation_Response = {
  __typename?: 'FasteningSystemBattenBarUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FasteningSystemBattenBarUsage>;
};

/** Ordering options when selecting data from "FasteningSystemBattenBarUsage". */
export type FasteningSystemBattenBarUsage_Order_By = {
  C_FasteningApplicationMethod?: Maybe<C_FasteningApplicationMethod_Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  FieldRowsOnCenter?: Maybe<Order_By>;
  IsFieldRows?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: FasteningSystemBattenBarUsage */
export type FasteningSystemBattenBarUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "FasteningSystemBattenBarUsage" */
export enum FasteningSystemBattenBarUsage_Select_Column {
  /** column name */
  FasteningApplicationMethodId = 'FasteningApplicationMethodId',
  /** column name */
  FieldRowsOnCenter = 'FieldRowsOnCenter',
  /** column name */
  IsFieldRows = 'IsFieldRows',
  /** column name */
  NumFieldRows = 'NumFieldRows',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Set_Input = {
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  IsFieldRows?: Maybe<Scalars['Boolean']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type FasteningSystemBattenBarUsage_Stddev_Fields = {
  __typename?: 'FasteningSystemBattenBarUsage_stddev_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Stddev_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FasteningSystemBattenBarUsage_Stddev_Pop_Fields = {
  __typename?: 'FasteningSystemBattenBarUsage_stddev_pop_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Stddev_Pop_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FasteningSystemBattenBarUsage_Stddev_Samp_Fields = {
  __typename?: 'FasteningSystemBattenBarUsage_stddev_samp_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Stddev_Samp_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type FasteningSystemBattenBarUsage_Sum_Fields = {
  __typename?: 'FasteningSystemBattenBarUsage_sum_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Sum_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "FasteningSystemBattenBarUsage" */
export enum FasteningSystemBattenBarUsage_Update_Column {
  /** column name */
  FasteningApplicationMethodId = 'FasteningApplicationMethodId',
  /** column name */
  FieldRowsOnCenter = 'FieldRowsOnCenter',
  /** column name */
  IsFieldRows = 'IsFieldRows',
  /** column name */
  NumFieldRows = 'NumFieldRows',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type FasteningSystemBattenBarUsage_Var_Pop_Fields = {
  __typename?: 'FasteningSystemBattenBarUsage_var_pop_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Var_Pop_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FasteningSystemBattenBarUsage_Var_Samp_Fields = {
  __typename?: 'FasteningSystemBattenBarUsage_var_samp_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Var_Samp_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type FasteningSystemBattenBarUsage_Variance_Fields = {
  __typename?: 'FasteningSystemBattenBarUsage_variance_fields';
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FasteningSystemBattenBarUsage" */
export type FasteningSystemBattenBarUsage_Variance_Order_By = {
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "FasteningSystemClipUsage" */
export type FasteningSystemClipUsage = {
  __typename?: 'FasteningSystemClipUsage';
  FastenersPerPlateOrClip: Scalars['Int'];
  OnCenter: Scalars['MeasurementValue'];
  RowSpacing: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "FasteningSystemClipUsage" */
export type FasteningSystemClipUsage_Aggregate = {
  __typename?: 'FasteningSystemClipUsage_aggregate';
  aggregate?: Maybe<FasteningSystemClipUsage_Aggregate_Fields>;
  nodes: Array<FasteningSystemClipUsage>;
};

/** aggregate fields of "FasteningSystemClipUsage" */
export type FasteningSystemClipUsage_Aggregate_Fields = {
  __typename?: 'FasteningSystemClipUsage_aggregate_fields';
  avg?: Maybe<FasteningSystemClipUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FasteningSystemClipUsage_Max_Fields>;
  min?: Maybe<FasteningSystemClipUsage_Min_Fields>;
  stddev?: Maybe<FasteningSystemClipUsage_Stddev_Fields>;
  stddev_pop?: Maybe<FasteningSystemClipUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FasteningSystemClipUsage_Stddev_Samp_Fields>;
  sum?: Maybe<FasteningSystemClipUsage_Sum_Fields>;
  var_pop?: Maybe<FasteningSystemClipUsage_Var_Pop_Fields>;
  var_samp?: Maybe<FasteningSystemClipUsage_Var_Samp_Fields>;
  variance?: Maybe<FasteningSystemClipUsage_Variance_Fields>;
};


/** aggregate fields of "FasteningSystemClipUsage" */
export type FasteningSystemClipUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<FasteningSystemClipUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type FasteningSystemClipUsage_Avg_Fields = {
  __typename?: 'FasteningSystemClipUsage_avg_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "FasteningSystemClipUsage". All fields are combined with a logical 'AND'. */
export type FasteningSystemClipUsage_Bool_Exp = {
  FastenersPerPlateOrClip?: Maybe<Int_Mssql_Comparison_Exp>;
  OnCenter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  RowSpacing?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<FasteningSystemClipUsage_Bool_Exp>>;
  _not?: Maybe<FasteningSystemClipUsage_Bool_Exp>;
  _or?: Maybe<Array<FasteningSystemClipUsage_Bool_Exp>>;
};

/** upsert condition type for table "FasteningSystemClipUsage" */
export type FasteningSystemClipUsage_If_Matched = {
  match_columns?: Array<FasteningSystemClipUsage_Insert_Match_Column>;
  update_columns?: Array<FasteningSystemClipUsage_Update_Column>;
  where?: Maybe<FasteningSystemClipUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "FasteningSystemClipUsage" */
export type FasteningSystemClipUsage_Inc_Input = {
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "FasteningSystemClipUsage" */
export type FasteningSystemClipUsage_Insert_Input = {
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "FasteningSystemClipUsage" */
export enum FasteningSystemClipUsage_Insert_Match_Column {
  /** column name */
  FastenersPerPlateOrClip = 'FastenersPerPlateOrClip',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type FasteningSystemClipUsage_Max_Fields = {
  __typename?: 'FasteningSystemClipUsage_max_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type FasteningSystemClipUsage_Min_Fields = {
  __typename?: 'FasteningSystemClipUsage_min_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "FasteningSystemClipUsage" */
export type FasteningSystemClipUsage_Mutation_Response = {
  __typename?: 'FasteningSystemClipUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FasteningSystemClipUsage>;
};

/** Ordering options when selecting data from "FasteningSystemClipUsage". */
export type FasteningSystemClipUsage_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: FasteningSystemClipUsage */
export type FasteningSystemClipUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "FasteningSystemClipUsage" */
export enum FasteningSystemClipUsage_Select_Column {
  /** column name */
  FastenersPerPlateOrClip = 'FastenersPerPlateOrClip',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "FasteningSystemClipUsage" */
export type FasteningSystemClipUsage_Set_Input = {
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type FasteningSystemClipUsage_Stddev_Fields = {
  __typename?: 'FasteningSystemClipUsage_stddev_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FasteningSystemClipUsage_Stddev_Pop_Fields = {
  __typename?: 'FasteningSystemClipUsage_stddev_pop_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FasteningSystemClipUsage_Stddev_Samp_Fields = {
  __typename?: 'FasteningSystemClipUsage_stddev_samp_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type FasteningSystemClipUsage_Sum_Fields = {
  __typename?: 'FasteningSystemClipUsage_sum_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "FasteningSystemClipUsage" */
export enum FasteningSystemClipUsage_Update_Column {
  /** column name */
  FastenersPerPlateOrClip = 'FastenersPerPlateOrClip',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type FasteningSystemClipUsage_Var_Pop_Fields = {
  __typename?: 'FasteningSystemClipUsage_var_pop_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FasteningSystemClipUsage_Var_Samp_Fields = {
  __typename?: 'FasteningSystemClipUsage_var_samp_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FasteningSystemClipUsage_Variance_Fields = {
  __typename?: 'FasteningSystemClipUsage_variance_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage = {
  __typename?: 'FasteningSystemStressPlateUsage';
  /** An object relationship */
  C_FasteningApplicationMethod: C_FasteningApplicationMethod;
  ContributoryArea: Scalars['MeasurementValue'];
  Embedment: Scalars['MeasurementValue'];
  FastenersPerPlateOrClip: Scalars['Int'];
  FasteningApplicationMethodId: Scalars['Int'];
  FieldRowsOnCenter: Scalars['MeasurementValue'];
  IsFieldRows: Scalars['Boolean'];
  NumFieldRows: Scalars['Int'];
  OnCenter: Scalars['MeasurementValue'];
  RowSpacing: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Aggregate = {
  __typename?: 'FasteningSystemStressPlateUsage_aggregate';
  aggregate?: Maybe<FasteningSystemStressPlateUsage_Aggregate_Fields>;
  nodes: Array<FasteningSystemStressPlateUsage>;
};

/** aggregate fields of "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Aggregate_Fields = {
  __typename?: 'FasteningSystemStressPlateUsage_aggregate_fields';
  avg?: Maybe<FasteningSystemStressPlateUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FasteningSystemStressPlateUsage_Max_Fields>;
  min?: Maybe<FasteningSystemStressPlateUsage_Min_Fields>;
  stddev?: Maybe<FasteningSystemStressPlateUsage_Stddev_Fields>;
  stddev_pop?: Maybe<FasteningSystemStressPlateUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FasteningSystemStressPlateUsage_Stddev_Samp_Fields>;
  sum?: Maybe<FasteningSystemStressPlateUsage_Sum_Fields>;
  var_pop?: Maybe<FasteningSystemStressPlateUsage_Var_Pop_Fields>;
  var_samp?: Maybe<FasteningSystemStressPlateUsage_Var_Samp_Fields>;
  variance?: Maybe<FasteningSystemStressPlateUsage_Variance_Fields>;
};


/** aggregate fields of "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<FasteningSystemStressPlateUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Aggregate_Order_By = {
  avg?: Maybe<FasteningSystemStressPlateUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<FasteningSystemStressPlateUsage_Max_Order_By>;
  min?: Maybe<FasteningSystemStressPlateUsage_Min_Order_By>;
  stddev?: Maybe<FasteningSystemStressPlateUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<FasteningSystemStressPlateUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<FasteningSystemStressPlateUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<FasteningSystemStressPlateUsage_Sum_Order_By>;
  var_pop?: Maybe<FasteningSystemStressPlateUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<FasteningSystemStressPlateUsage_Var_Samp_Order_By>;
  variance?: Maybe<FasteningSystemStressPlateUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type FasteningSystemStressPlateUsage_Avg_Fields = {
  __typename?: 'FasteningSystemStressPlateUsage_avg_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Avg_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FasteningSystemStressPlateUsage". All fields are combined with a logical 'AND'. */
export type FasteningSystemStressPlateUsage_Bool_Exp = {
  C_FasteningApplicationMethod?: Maybe<C_FasteningApplicationMethod_Bool_Exp>;
  ContributoryArea?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Embedment?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  FastenersPerPlateOrClip?: Maybe<Int_Mssql_Comparison_Exp>;
  FasteningApplicationMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  FieldRowsOnCenter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  IsFieldRows?: Maybe<Boolean_Mssql_Comparison_Exp>;
  NumFieldRows?: Maybe<Int_Mssql_Comparison_Exp>;
  OnCenter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  RowSpacing?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<FasteningSystemStressPlateUsage_Bool_Exp>>;
  _not?: Maybe<FasteningSystemStressPlateUsage_Bool_Exp>;
  _or?: Maybe<Array<FasteningSystemStressPlateUsage_Bool_Exp>>;
};

/** upsert condition type for table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_If_Matched = {
  match_columns?: Array<FasteningSystemStressPlateUsage_Insert_Match_Column>;
  update_columns?: Array<FasteningSystemStressPlateUsage_Update_Column>;
  where?: Maybe<FasteningSystemStressPlateUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Inc_Input = {
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Insert_Input = {
  ContributoryArea?: Maybe<Scalars['MeasurementValue']>;
  Embedment?: Maybe<Scalars['MeasurementValue']>;
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  IsFieldRows?: Maybe<Scalars['Boolean']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "FasteningSystemStressPlateUsage" */
export enum FasteningSystemStressPlateUsage_Insert_Match_Column {
  /** column name */
  ContributoryArea = 'ContributoryArea',
  /** column name */
  Embedment = 'Embedment',
  /** column name */
  FastenersPerPlateOrClip = 'FastenersPerPlateOrClip',
  /** column name */
  FasteningApplicationMethodId = 'FasteningApplicationMethodId',
  /** column name */
  FieldRowsOnCenter = 'FieldRowsOnCenter',
  /** column name */
  IsFieldRows = 'IsFieldRows',
  /** column name */
  NumFieldRows = 'NumFieldRows',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type FasteningSystemStressPlateUsage_Max_Fields = {
  __typename?: 'FasteningSystemStressPlateUsage_max_fields';
  ContributoryArea?: Maybe<Scalars['MeasurementValue']>;
  Embedment?: Maybe<Scalars['MeasurementValue']>;
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Max_Order_By = {
  ContributoryArea?: Maybe<Order_By>;
  Embedment?: Maybe<Order_By>;
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  FieldRowsOnCenter?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type FasteningSystemStressPlateUsage_Min_Fields = {
  __typename?: 'FasteningSystemStressPlateUsage_min_fields';
  ContributoryArea?: Maybe<Scalars['MeasurementValue']>;
  Embedment?: Maybe<Scalars['MeasurementValue']>;
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Min_Order_By = {
  ContributoryArea?: Maybe<Order_By>;
  Embedment?: Maybe<Order_By>;
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  FieldRowsOnCenter?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Mutation_Response = {
  __typename?: 'FasteningSystemStressPlateUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FasteningSystemStressPlateUsage>;
};

/** Ordering options when selecting data from "FasteningSystemStressPlateUsage". */
export type FasteningSystemStressPlateUsage_Order_By = {
  C_FasteningApplicationMethod?: Maybe<C_FasteningApplicationMethod_Order_By>;
  ContributoryArea?: Maybe<Order_By>;
  Embedment?: Maybe<Order_By>;
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  FieldRowsOnCenter?: Maybe<Order_By>;
  IsFieldRows?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: FasteningSystemStressPlateUsage */
export type FasteningSystemStressPlateUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "FasteningSystemStressPlateUsage" */
export enum FasteningSystemStressPlateUsage_Select_Column {
  /** column name */
  ContributoryArea = 'ContributoryArea',
  /** column name */
  Embedment = 'Embedment',
  /** column name */
  FastenersPerPlateOrClip = 'FastenersPerPlateOrClip',
  /** column name */
  FasteningApplicationMethodId = 'FasteningApplicationMethodId',
  /** column name */
  FieldRowsOnCenter = 'FieldRowsOnCenter',
  /** column name */
  IsFieldRows = 'IsFieldRows',
  /** column name */
  NumFieldRows = 'NumFieldRows',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Set_Input = {
  ContributoryArea?: Maybe<Scalars['MeasurementValue']>;
  Embedment?: Maybe<Scalars['MeasurementValue']>;
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  IsFieldRows?: Maybe<Scalars['Boolean']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type FasteningSystemStressPlateUsage_Stddev_Fields = {
  __typename?: 'FasteningSystemStressPlateUsage_stddev_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Stddev_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FasteningSystemStressPlateUsage_Stddev_Pop_Fields = {
  __typename?: 'FasteningSystemStressPlateUsage_stddev_pop_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Stddev_Pop_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FasteningSystemStressPlateUsage_Stddev_Samp_Fields = {
  __typename?: 'FasteningSystemStressPlateUsage_stddev_samp_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Stddev_Samp_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type FasteningSystemStressPlateUsage_Sum_Fields = {
  __typename?: 'FasteningSystemStressPlateUsage_sum_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Sum_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "FasteningSystemStressPlateUsage" */
export enum FasteningSystemStressPlateUsage_Update_Column {
  /** column name */
  ContributoryArea = 'ContributoryArea',
  /** column name */
  Embedment = 'Embedment',
  /** column name */
  FastenersPerPlateOrClip = 'FastenersPerPlateOrClip',
  /** column name */
  FasteningApplicationMethodId = 'FasteningApplicationMethodId',
  /** column name */
  FieldRowsOnCenter = 'FieldRowsOnCenter',
  /** column name */
  IsFieldRows = 'IsFieldRows',
  /** column name */
  NumFieldRows = 'NumFieldRows',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type FasteningSystemStressPlateUsage_Var_Pop_Fields = {
  __typename?: 'FasteningSystemStressPlateUsage_var_pop_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Var_Pop_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FasteningSystemStressPlateUsage_Var_Samp_Fields = {
  __typename?: 'FasteningSystemStressPlateUsage_var_samp_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Var_Samp_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type FasteningSystemStressPlateUsage_Variance_Fields = {
  __typename?: 'FasteningSystemStressPlateUsage_variance_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FasteningSystemStressPlateUsage" */
export type FasteningSystemStressPlateUsage_Variance_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "FiberReinforcedPlasticDeck" */
export type FiberReinforcedPlasticDeck = {
  __typename?: 'FiberReinforcedPlasticDeck';
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  /** An array relationship */
  R_LinerPanelMaterialFiberReinforcedPlasticDecks: Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck>;
  /** An aggregate relationship */
  R_LinerPanelMaterialFiberReinforcedPlasticDecks_aggregate: R_LinerPanelMaterialFiberReinforcedPlasticDeck_Aggregate;
  SpanMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  Weight: Scalars['MeasurementValue'];
};


/** columns and relationships of "FiberReinforcedPlasticDeck" */
export type FiberReinforcedPlasticDeckR_LinerPanelMaterialFiberReinforcedPlasticDecksArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Order_By>>;
  where?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp>;
};


/** columns and relationships of "FiberReinforcedPlasticDeck" */
export type FiberReinforcedPlasticDeckR_LinerPanelMaterialFiberReinforcedPlasticDecks_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Order_By>>;
  where?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp>;
};

/** columns and relationships of "FiberReinforcedPlasticDeckUsage" */
export type FiberReinforcedPlasticDeckUsage = {
  __typename?: 'FiberReinforcedPlasticDeckUsage';
  SpanMax: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "FiberReinforcedPlasticDeckUsage" */
export type FiberReinforcedPlasticDeckUsage_Aggregate = {
  __typename?: 'FiberReinforcedPlasticDeckUsage_aggregate';
  aggregate?: Maybe<FiberReinforcedPlasticDeckUsage_Aggregate_Fields>;
  nodes: Array<FiberReinforcedPlasticDeckUsage>;
};

/** aggregate fields of "FiberReinforcedPlasticDeckUsage" */
export type FiberReinforcedPlasticDeckUsage_Aggregate_Fields = {
  __typename?: 'FiberReinforcedPlasticDeckUsage_aggregate_fields';
  avg?: Maybe<FiberReinforcedPlasticDeckUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FiberReinforcedPlasticDeckUsage_Max_Fields>;
  min?: Maybe<FiberReinforcedPlasticDeckUsage_Min_Fields>;
  stddev?: Maybe<FiberReinforcedPlasticDeckUsage_Stddev_Fields>;
  stddev_pop?: Maybe<FiberReinforcedPlasticDeckUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FiberReinforcedPlasticDeckUsage_Stddev_Samp_Fields>;
  sum?: Maybe<FiberReinforcedPlasticDeckUsage_Sum_Fields>;
  var_pop?: Maybe<FiberReinforcedPlasticDeckUsage_Var_Pop_Fields>;
  var_samp?: Maybe<FiberReinforcedPlasticDeckUsage_Var_Samp_Fields>;
  variance?: Maybe<FiberReinforcedPlasticDeckUsage_Variance_Fields>;
};


/** aggregate fields of "FiberReinforcedPlasticDeckUsage" */
export type FiberReinforcedPlasticDeckUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<FiberReinforcedPlasticDeckUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type FiberReinforcedPlasticDeckUsage_Avg_Fields = {
  __typename?: 'FiberReinforcedPlasticDeckUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "FiberReinforcedPlasticDeckUsage". All fields are combined with a logical 'AND'. */
export type FiberReinforcedPlasticDeckUsage_Bool_Exp = {
  SpanMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<FiberReinforcedPlasticDeckUsage_Bool_Exp>>;
  _not?: Maybe<FiberReinforcedPlasticDeckUsage_Bool_Exp>;
  _or?: Maybe<Array<FiberReinforcedPlasticDeckUsage_Bool_Exp>>;
};

/** upsert condition type for table "FiberReinforcedPlasticDeckUsage" */
export type FiberReinforcedPlasticDeckUsage_If_Matched = {
  match_columns?: Array<FiberReinforcedPlasticDeckUsage_Insert_Match_Column>;
  update_columns?: Array<FiberReinforcedPlasticDeckUsage_Update_Column>;
  where?: Maybe<FiberReinforcedPlasticDeckUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "FiberReinforcedPlasticDeckUsage" */
export type FiberReinforcedPlasticDeckUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "FiberReinforcedPlasticDeckUsage" */
export type FiberReinforcedPlasticDeckUsage_Insert_Input = {
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "FiberReinforcedPlasticDeckUsage" */
export enum FiberReinforcedPlasticDeckUsage_Insert_Match_Column {
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type FiberReinforcedPlasticDeckUsage_Max_Fields = {
  __typename?: 'FiberReinforcedPlasticDeckUsage_max_fields';
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type FiberReinforcedPlasticDeckUsage_Min_Fields = {
  __typename?: 'FiberReinforcedPlasticDeckUsage_min_fields';
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "FiberReinforcedPlasticDeckUsage" */
export type FiberReinforcedPlasticDeckUsage_Mutation_Response = {
  __typename?: 'FiberReinforcedPlasticDeckUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FiberReinforcedPlasticDeckUsage>;
};

/** Ordering options when selecting data from "FiberReinforcedPlasticDeckUsage". */
export type FiberReinforcedPlasticDeckUsage_Order_By = {
  SpanMax?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: FiberReinforcedPlasticDeckUsage */
export type FiberReinforcedPlasticDeckUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "FiberReinforcedPlasticDeckUsage" */
export enum FiberReinforcedPlasticDeckUsage_Select_Column {
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "FiberReinforcedPlasticDeckUsage" */
export type FiberReinforcedPlasticDeckUsage_Set_Input = {
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type FiberReinforcedPlasticDeckUsage_Stddev_Fields = {
  __typename?: 'FiberReinforcedPlasticDeckUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FiberReinforcedPlasticDeckUsage_Stddev_Pop_Fields = {
  __typename?: 'FiberReinforcedPlasticDeckUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FiberReinforcedPlasticDeckUsage_Stddev_Samp_Fields = {
  __typename?: 'FiberReinforcedPlasticDeckUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type FiberReinforcedPlasticDeckUsage_Sum_Fields = {
  __typename?: 'FiberReinforcedPlasticDeckUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "FiberReinforcedPlasticDeckUsage" */
export enum FiberReinforcedPlasticDeckUsage_Update_Column {
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type FiberReinforcedPlasticDeckUsage_Var_Pop_Fields = {
  __typename?: 'FiberReinforcedPlasticDeckUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FiberReinforcedPlasticDeckUsage_Var_Samp_Fields = {
  __typename?: 'FiberReinforcedPlasticDeckUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FiberReinforcedPlasticDeckUsage_Variance_Fields = {
  __typename?: 'FiberReinforcedPlasticDeckUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "FiberReinforcedPlasticDeck" */
export type FiberReinforcedPlasticDeck_Aggregate = {
  __typename?: 'FiberReinforcedPlasticDeck_aggregate';
  aggregate?: Maybe<FiberReinforcedPlasticDeck_Aggregate_Fields>;
  nodes: Array<FiberReinforcedPlasticDeck>;
};

/** aggregate fields of "FiberReinforcedPlasticDeck" */
export type FiberReinforcedPlasticDeck_Aggregate_Fields = {
  __typename?: 'FiberReinforcedPlasticDeck_aggregate_fields';
  avg?: Maybe<FiberReinforcedPlasticDeck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FiberReinforcedPlasticDeck_Max_Fields>;
  min?: Maybe<FiberReinforcedPlasticDeck_Min_Fields>;
  stddev?: Maybe<FiberReinforcedPlasticDeck_Stddev_Fields>;
  stddev_pop?: Maybe<FiberReinforcedPlasticDeck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FiberReinforcedPlasticDeck_Stddev_Samp_Fields>;
  sum?: Maybe<FiberReinforcedPlasticDeck_Sum_Fields>;
  var_pop?: Maybe<FiberReinforcedPlasticDeck_Var_Pop_Fields>;
  var_samp?: Maybe<FiberReinforcedPlasticDeck_Var_Samp_Fields>;
  variance?: Maybe<FiberReinforcedPlasticDeck_Variance_Fields>;
};


/** aggregate fields of "FiberReinforcedPlasticDeck" */
export type FiberReinforcedPlasticDeck_Aggregate_FieldsCountArgs = {
  column?: Maybe<FiberReinforcedPlasticDeck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type FiberReinforcedPlasticDeck_Avg_Fields = {
  __typename?: 'FiberReinforcedPlasticDeck_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "FiberReinforcedPlasticDeck". All fields are combined with a logical 'AND'. */
export type FiberReinforcedPlasticDeck_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  R_LinerPanelMaterialFiberReinforcedPlasticDecks?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp>;
  SpanMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Weight?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<FiberReinforcedPlasticDeck_Bool_Exp>>;
  _not?: Maybe<FiberReinforcedPlasticDeck_Bool_Exp>;
  _or?: Maybe<Array<FiberReinforcedPlasticDeck_Bool_Exp>>;
};

/** upsert condition type for table "FiberReinforcedPlasticDeck" */
export type FiberReinforcedPlasticDeck_If_Matched = {
  match_columns?: Array<FiberReinforcedPlasticDeck_Insert_Match_Column>;
  update_columns?: Array<FiberReinforcedPlasticDeck_Update_Column>;
  where?: Maybe<FiberReinforcedPlasticDeck_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "FiberReinforcedPlasticDeck" */
export type FiberReinforcedPlasticDeck_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "FiberReinforcedPlasticDeck" */
export type FiberReinforcedPlasticDeck_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Weight?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "FiberReinforcedPlasticDeck" */
export enum FiberReinforcedPlasticDeck_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  Weight = 'Weight'
}

/** aggregate max on columns */
export type FiberReinforcedPlasticDeck_Max_Fields = {
  __typename?: 'FiberReinforcedPlasticDeck_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Weight?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate min on columns */
export type FiberReinforcedPlasticDeck_Min_Fields = {
  __typename?: 'FiberReinforcedPlasticDeck_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Weight?: Maybe<Scalars['MeasurementValue']>;
};

/** response of any mutation on the table "FiberReinforcedPlasticDeck" */
export type FiberReinforcedPlasticDeck_Mutation_Response = {
  __typename?: 'FiberReinforcedPlasticDeck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FiberReinforcedPlasticDeck>;
};

/** Ordering options when selecting data from "FiberReinforcedPlasticDeck". */
export type FiberReinforcedPlasticDeck_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  R_LinerPanelMaterialFiberReinforcedPlasticDecks_aggregate?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Aggregate_Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Weight?: Maybe<Order_By>;
};

/** primary key columns input for table: FiberReinforcedPlasticDeck */
export type FiberReinforcedPlasticDeck_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "FiberReinforcedPlasticDeck" */
export enum FiberReinforcedPlasticDeck_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  Weight = 'Weight'
}

/** input type for updating data in table "FiberReinforcedPlasticDeck" */
export type FiberReinforcedPlasticDeck_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Weight?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type FiberReinforcedPlasticDeck_Stddev_Fields = {
  __typename?: 'FiberReinforcedPlasticDeck_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FiberReinforcedPlasticDeck_Stddev_Pop_Fields = {
  __typename?: 'FiberReinforcedPlasticDeck_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FiberReinforcedPlasticDeck_Stddev_Samp_Fields = {
  __typename?: 'FiberReinforcedPlasticDeck_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type FiberReinforcedPlasticDeck_Sum_Fields = {
  __typename?: 'FiberReinforcedPlasticDeck_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
};

/** update columns of table "FiberReinforcedPlasticDeck" */
export enum FiberReinforcedPlasticDeck_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  Weight = 'Weight'
}

/** aggregate var_pop on columns */
export type FiberReinforcedPlasticDeck_Var_Pop_Fields = {
  __typename?: 'FiberReinforcedPlasticDeck_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FiberReinforcedPlasticDeck_Var_Samp_Fields = {
  __typename?: 'FiberReinforcedPlasticDeck_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FiberReinforcedPlasticDeck_Variance_Fields = {
  __typename?: 'FiberReinforcedPlasticDeck_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Mssql_Comparison_Exp = {
  _eq?: Maybe<Scalars['Float']>;
  _gt?: Maybe<Scalars['Float']>;
  _gte?: Maybe<Scalars['Float']>;
  _in?: Maybe<Array<Scalars['Float']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Float']>;
  _lte?: Maybe<Scalars['Float']>;
  _neq?: Maybe<Scalars['Float']>;
  _nin?: Maybe<Array<Scalars['Float']>>;
};

/** columns and relationships of "FormDeck" */
export type FormDeck = {
  __typename?: 'FormDeck';
  ComponentId: Scalars['Int'];
  Depth: Scalars['MeasurementValue'];
  IsVented: Scalars['Boolean'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  PercentVented: Scalars['MeasurementValue'];
  SpanMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
};

/** columns and relationships of "FormDeckUsage" */
export type FormDeckUsage = {
  __typename?: 'FormDeckUsage';
  SpanMax: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "FormDeckUsage" */
export type FormDeckUsage_Aggregate = {
  __typename?: 'FormDeckUsage_aggregate';
  aggregate?: Maybe<FormDeckUsage_Aggregate_Fields>;
  nodes: Array<FormDeckUsage>;
};

/** aggregate fields of "FormDeckUsage" */
export type FormDeckUsage_Aggregate_Fields = {
  __typename?: 'FormDeckUsage_aggregate_fields';
  avg?: Maybe<FormDeckUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FormDeckUsage_Max_Fields>;
  min?: Maybe<FormDeckUsage_Min_Fields>;
  stddev?: Maybe<FormDeckUsage_Stddev_Fields>;
  stddev_pop?: Maybe<FormDeckUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FormDeckUsage_Stddev_Samp_Fields>;
  sum?: Maybe<FormDeckUsage_Sum_Fields>;
  var_pop?: Maybe<FormDeckUsage_Var_Pop_Fields>;
  var_samp?: Maybe<FormDeckUsage_Var_Samp_Fields>;
  variance?: Maybe<FormDeckUsage_Variance_Fields>;
};


/** aggregate fields of "FormDeckUsage" */
export type FormDeckUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<FormDeckUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type FormDeckUsage_Avg_Fields = {
  __typename?: 'FormDeckUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "FormDeckUsage". All fields are combined with a logical 'AND'. */
export type FormDeckUsage_Bool_Exp = {
  SpanMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<FormDeckUsage_Bool_Exp>>;
  _not?: Maybe<FormDeckUsage_Bool_Exp>;
  _or?: Maybe<Array<FormDeckUsage_Bool_Exp>>;
};

/** upsert condition type for table "FormDeckUsage" */
export type FormDeckUsage_If_Matched = {
  match_columns?: Array<FormDeckUsage_Insert_Match_Column>;
  update_columns?: Array<FormDeckUsage_Update_Column>;
  where?: Maybe<FormDeckUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "FormDeckUsage" */
export type FormDeckUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "FormDeckUsage" */
export type FormDeckUsage_Insert_Input = {
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "FormDeckUsage" */
export enum FormDeckUsage_Insert_Match_Column {
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type FormDeckUsage_Max_Fields = {
  __typename?: 'FormDeckUsage_max_fields';
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type FormDeckUsage_Min_Fields = {
  __typename?: 'FormDeckUsage_min_fields';
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "FormDeckUsage" */
export type FormDeckUsage_Mutation_Response = {
  __typename?: 'FormDeckUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FormDeckUsage>;
};

/** Ordering options when selecting data from "FormDeckUsage". */
export type FormDeckUsage_Order_By = {
  SpanMax?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: FormDeckUsage */
export type FormDeckUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "FormDeckUsage" */
export enum FormDeckUsage_Select_Column {
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "FormDeckUsage" */
export type FormDeckUsage_Set_Input = {
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type FormDeckUsage_Stddev_Fields = {
  __typename?: 'FormDeckUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FormDeckUsage_Stddev_Pop_Fields = {
  __typename?: 'FormDeckUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FormDeckUsage_Stddev_Samp_Fields = {
  __typename?: 'FormDeckUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type FormDeckUsage_Sum_Fields = {
  __typename?: 'FormDeckUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "FormDeckUsage" */
export enum FormDeckUsage_Update_Column {
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type FormDeckUsage_Var_Pop_Fields = {
  __typename?: 'FormDeckUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FormDeckUsage_Var_Samp_Fields = {
  __typename?: 'FormDeckUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FormDeckUsage_Variance_Fields = {
  __typename?: 'FormDeckUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "FormDeck" */
export type FormDeck_Aggregate = {
  __typename?: 'FormDeck_aggregate';
  aggregate?: Maybe<FormDeck_Aggregate_Fields>;
  nodes: Array<FormDeck>;
};

/** aggregate fields of "FormDeck" */
export type FormDeck_Aggregate_Fields = {
  __typename?: 'FormDeck_aggregate_fields';
  avg?: Maybe<FormDeck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FormDeck_Max_Fields>;
  min?: Maybe<FormDeck_Min_Fields>;
  stddev?: Maybe<FormDeck_Stddev_Fields>;
  stddev_pop?: Maybe<FormDeck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FormDeck_Stddev_Samp_Fields>;
  sum?: Maybe<FormDeck_Sum_Fields>;
  var_pop?: Maybe<FormDeck_Var_Pop_Fields>;
  var_samp?: Maybe<FormDeck_Var_Samp_Fields>;
  variance?: Maybe<FormDeck_Variance_Fields>;
};


/** aggregate fields of "FormDeck" */
export type FormDeck_Aggregate_FieldsCountArgs = {
  column?: Maybe<FormDeck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type FormDeck_Avg_Fields = {
  __typename?: 'FormDeck_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "FormDeck". All fields are combined with a logical 'AND'. */
export type FormDeck_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  Depth?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  IsVented?: Maybe<Boolean_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  PercentVented?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  SpanMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<FormDeck_Bool_Exp>>;
  _not?: Maybe<FormDeck_Bool_Exp>;
  _or?: Maybe<Array<FormDeck_Bool_Exp>>;
};

/** upsert condition type for table "FormDeck" */
export type FormDeck_If_Matched = {
  match_columns?: Array<FormDeck_Insert_Match_Column>;
  update_columns?: Array<FormDeck_Update_Column>;
  where?: Maybe<FormDeck_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "FormDeck" */
export type FormDeck_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "FormDeck" */
export type FormDeck_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  Depth?: Maybe<Scalars['MeasurementValue']>;
  IsVented?: Maybe<Scalars['Boolean']>;
  PercentVented?: Maybe<Scalars['MeasurementValue']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "FormDeck" */
export enum FormDeck_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  Depth = 'Depth',
  /** column name */
  IsVented = 'IsVented',
  /** column name */
  PercentVented = 'PercentVented',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate max on columns */
export type FormDeck_Max_Fields = {
  __typename?: 'FormDeck_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  Depth?: Maybe<Scalars['MeasurementValue']>;
  PercentVented?: Maybe<Scalars['MeasurementValue']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate min on columns */
export type FormDeck_Min_Fields = {
  __typename?: 'FormDeck_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  Depth?: Maybe<Scalars['MeasurementValue']>;
  PercentVented?: Maybe<Scalars['MeasurementValue']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** response of any mutation on the table "FormDeck" */
export type FormDeck_Mutation_Response = {
  __typename?: 'FormDeck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FormDeck>;
};

/** Ordering options when selecting data from "FormDeck". */
export type FormDeck_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Depth?: Maybe<Order_By>;
  IsVented?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  PercentVented?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** primary key columns input for table: FormDeck */
export type FormDeck_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "FormDeck" */
export enum FormDeck_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  Depth = 'Depth',
  /** column name */
  IsVented = 'IsVented',
  /** column name */
  PercentVented = 'PercentVented',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** input type for updating data in table "FormDeck" */
export type FormDeck_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  Depth?: Maybe<Scalars['MeasurementValue']>;
  IsVented?: Maybe<Scalars['Boolean']>;
  PercentVented?: Maybe<Scalars['MeasurementValue']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type FormDeck_Stddev_Fields = {
  __typename?: 'FormDeck_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FormDeck_Stddev_Pop_Fields = {
  __typename?: 'FormDeck_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FormDeck_Stddev_Samp_Fields = {
  __typename?: 'FormDeck_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type FormDeck_Sum_Fields = {
  __typename?: 'FormDeck_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
};

/** update columns of table "FormDeck" */
export enum FormDeck_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  Depth = 'Depth',
  /** column name */
  IsVented = 'IsVented',
  /** column name */
  PercentVented = 'PercentVented',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate var_pop on columns */
export type FormDeck_Var_Pop_Fields = {
  __typename?: 'FormDeck_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FormDeck_Var_Samp_Fields = {
  __typename?: 'FormDeck_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FormDeck_Variance_Fields = {
  __typename?: 'FormDeck_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Gutter" */
export type Gutter = {
  __typename?: 'Gutter';
  /** An object relationship */
  C_GutterMaterial: C_GutterMaterial;
  ComponentId: Scalars['Int'];
  GutterMaterialId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  MaxWindRating: Scalars['Float'];
  /** An array relationship */
  R_GutterFasteners: Array<R_GutterFasteners>;
  /** An aggregate relationship */
  R_GutterFasteners_aggregate: R_GutterFasteners_Aggregate;
};


/** columns and relationships of "Gutter" */
export type GutterR_GutterFastenersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_GutterFasteners_Order_By>>;
  where?: Maybe<R_GutterFasteners_Bool_Exp>;
};


/** columns and relationships of "Gutter" */
export type GutterR_GutterFasteners_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_GutterFasteners_Order_By>>;
  where?: Maybe<R_GutterFasteners_Bool_Exp>;
};

/** aggregated selection of "Gutter" */
export type Gutter_Aggregate = {
  __typename?: 'Gutter_aggregate';
  aggregate?: Maybe<Gutter_Aggregate_Fields>;
  nodes: Array<Gutter>;
};

/** aggregate fields of "Gutter" */
export type Gutter_Aggregate_Fields = {
  __typename?: 'Gutter_aggregate_fields';
  avg?: Maybe<Gutter_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gutter_Max_Fields>;
  min?: Maybe<Gutter_Min_Fields>;
  stddev?: Maybe<Gutter_Stddev_Fields>;
  stddev_pop?: Maybe<Gutter_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gutter_Stddev_Samp_Fields>;
  sum?: Maybe<Gutter_Sum_Fields>;
  var_pop?: Maybe<Gutter_Var_Pop_Fields>;
  var_samp?: Maybe<Gutter_Var_Samp_Fields>;
  variance?: Maybe<Gutter_Variance_Fields>;
};


/** aggregate fields of "Gutter" */
export type Gutter_Aggregate_FieldsCountArgs = {
  column?: Maybe<Gutter_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Gutter" */
export type Gutter_Aggregate_Order_By = {
  avg?: Maybe<Gutter_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Gutter_Max_Order_By>;
  min?: Maybe<Gutter_Min_Order_By>;
  stddev?: Maybe<Gutter_Stddev_Order_By>;
  stddev_pop?: Maybe<Gutter_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Gutter_Stddev_Samp_Order_By>;
  sum?: Maybe<Gutter_Sum_Order_By>;
  var_pop?: Maybe<Gutter_Var_Pop_Order_By>;
  var_samp?: Maybe<Gutter_Var_Samp_Order_By>;
  variance?: Maybe<Gutter_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Gutter_Avg_Fields = {
  __typename?: 'Gutter_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  GutterMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Gutter" */
export type Gutter_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  GutterMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Gutter". All fields are combined with a logical 'AND'. */
export type Gutter_Bool_Exp = {
  C_GutterMaterial?: Maybe<C_GutterMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  GutterMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  MaxWindRating?: Maybe<Float_Mssql_Comparison_Exp>;
  R_GutterFasteners?: Maybe<R_GutterFasteners_Bool_Exp>;
  _and?: Maybe<Array<Gutter_Bool_Exp>>;
  _not?: Maybe<Gutter_Bool_Exp>;
  _or?: Maybe<Array<Gutter_Bool_Exp>>;
};

/** upsert condition type for table "Gutter" */
export type Gutter_If_Matched = {
  match_columns?: Array<Gutter_Insert_Match_Column>;
  update_columns?: Array<Gutter_Update_Column>;
  where?: Maybe<Gutter_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Gutter" */
export type Gutter_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  GutterMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "Gutter" */
export type Gutter_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  GutterMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "Gutter" */
export enum Gutter_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  GutterMaterialId = 'GutterMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** aggregate max on columns */
export type Gutter_Max_Fields = {
  __typename?: 'Gutter_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  GutterMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "Gutter" */
export type Gutter_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  GutterMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Gutter_Min_Fields = {
  __typename?: 'Gutter_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  GutterMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "Gutter" */
export type Gutter_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  GutterMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** response of any mutation on the table "Gutter" */
export type Gutter_Mutation_Response = {
  __typename?: 'Gutter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gutter>;
};

/** Ordering options when selecting data from "Gutter". */
export type Gutter_Order_By = {
  C_GutterMaterial?: Maybe<C_GutterMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  GutterMaterialId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  MaxWindRating?: Maybe<Order_By>;
  R_GutterFasteners_aggregate?: Maybe<R_GutterFasteners_Aggregate_Order_By>;
};

/** primary key columns input for table: Gutter */
export type Gutter_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "Gutter" */
export enum Gutter_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  GutterMaterialId = 'GutterMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** input type for updating data in table "Gutter" */
export type Gutter_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  GutterMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Gutter_Stddev_Fields = {
  __typename?: 'Gutter_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  GutterMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Gutter" */
export type Gutter_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  GutterMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Gutter_Stddev_Pop_Fields = {
  __typename?: 'Gutter_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  GutterMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Gutter" */
export type Gutter_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  GutterMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Gutter_Stddev_Samp_Fields = {
  __typename?: 'Gutter_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  GutterMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Gutter" */
export type Gutter_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  GutterMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Gutter_Sum_Fields = {
  __typename?: 'Gutter_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  GutterMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "Gutter" */
export type Gutter_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  GutterMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** update columns of table "Gutter" */
export enum Gutter_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  GutterMaterialId = 'GutterMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** aggregate var_pop on columns */
export type Gutter_Var_Pop_Fields = {
  __typename?: 'Gutter_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  GutterMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Gutter" */
export type Gutter_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  GutterMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Gutter_Var_Samp_Fields = {
  __typename?: 'Gutter_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  GutterMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Gutter" */
export type Gutter_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  GutterMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Gutter_Variance_Fields = {
  __typename?: 'Gutter_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  GutterMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Gutter" */
export type Gutter_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  GutterMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** columns and relationships of "GypsumDeck" */
export type GypsumDeck = {
  __typename?: 'GypsumDeck';
  AdditiveId: Scalars['Int'];
  /** An object relationship */
  C_Additive: C_Additive;
  CastDensityMin: Scalars['MeasurementValue'];
  ComponentId: Scalars['Int'];
  CompressiveStrengthMin: Scalars['MeasurementValue'];
  DryDensityMin: Scalars['MeasurementValue'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  ThicknessMin: Scalars['MeasurementValue'];
};

/** columns and relationships of "GypsumDeckUsage" */
export type GypsumDeckUsage = {
  __typename?: 'GypsumDeckUsage';
  CastDensityMin: Scalars['MeasurementValue'];
  CompressiveStrengthMin: Scalars['MeasurementValue'];
  DryDensityMin: Scalars['MeasurementValue'];
  SpanMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "GypsumDeckUsage" */
export type GypsumDeckUsage_Aggregate = {
  __typename?: 'GypsumDeckUsage_aggregate';
  aggregate?: Maybe<GypsumDeckUsage_Aggregate_Fields>;
  nodes: Array<GypsumDeckUsage>;
};

/** aggregate fields of "GypsumDeckUsage" */
export type GypsumDeckUsage_Aggregate_Fields = {
  __typename?: 'GypsumDeckUsage_aggregate_fields';
  avg?: Maybe<GypsumDeckUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GypsumDeckUsage_Max_Fields>;
  min?: Maybe<GypsumDeckUsage_Min_Fields>;
  stddev?: Maybe<GypsumDeckUsage_Stddev_Fields>;
  stddev_pop?: Maybe<GypsumDeckUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GypsumDeckUsage_Stddev_Samp_Fields>;
  sum?: Maybe<GypsumDeckUsage_Sum_Fields>;
  var_pop?: Maybe<GypsumDeckUsage_Var_Pop_Fields>;
  var_samp?: Maybe<GypsumDeckUsage_Var_Samp_Fields>;
  variance?: Maybe<GypsumDeckUsage_Variance_Fields>;
};


/** aggregate fields of "GypsumDeckUsage" */
export type GypsumDeckUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<GypsumDeckUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type GypsumDeckUsage_Avg_Fields = {
  __typename?: 'GypsumDeckUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "GypsumDeckUsage". All fields are combined with a logical 'AND'. */
export type GypsumDeckUsage_Bool_Exp = {
  CastDensityMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  CompressiveStrengthMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  DryDensityMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  SpanMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<GypsumDeckUsage_Bool_Exp>>;
  _not?: Maybe<GypsumDeckUsage_Bool_Exp>;
  _or?: Maybe<Array<GypsumDeckUsage_Bool_Exp>>;
};

/** upsert condition type for table "GypsumDeckUsage" */
export type GypsumDeckUsage_If_Matched = {
  match_columns?: Array<GypsumDeckUsage_Insert_Match_Column>;
  update_columns?: Array<GypsumDeckUsage_Update_Column>;
  where?: Maybe<GypsumDeckUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "GypsumDeckUsage" */
export type GypsumDeckUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "GypsumDeckUsage" */
export type GypsumDeckUsage_Insert_Input = {
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  CompressiveStrengthMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "GypsumDeckUsage" */
export enum GypsumDeckUsage_Insert_Match_Column {
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  CompressiveStrengthMin = 'CompressiveStrengthMin',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type GypsumDeckUsage_Max_Fields = {
  __typename?: 'GypsumDeckUsage_max_fields';
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  CompressiveStrengthMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type GypsumDeckUsage_Min_Fields = {
  __typename?: 'GypsumDeckUsage_min_fields';
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  CompressiveStrengthMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "GypsumDeckUsage" */
export type GypsumDeckUsage_Mutation_Response = {
  __typename?: 'GypsumDeckUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GypsumDeckUsage>;
};

/** Ordering options when selecting data from "GypsumDeckUsage". */
export type GypsumDeckUsage_Order_By = {
  CastDensityMin?: Maybe<Order_By>;
  CompressiveStrengthMin?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: GypsumDeckUsage */
export type GypsumDeckUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "GypsumDeckUsage" */
export enum GypsumDeckUsage_Select_Column {
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  CompressiveStrengthMin = 'CompressiveStrengthMin',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "GypsumDeckUsage" */
export type GypsumDeckUsage_Set_Input = {
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  CompressiveStrengthMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type GypsumDeckUsage_Stddev_Fields = {
  __typename?: 'GypsumDeckUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type GypsumDeckUsage_Stddev_Pop_Fields = {
  __typename?: 'GypsumDeckUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type GypsumDeckUsage_Stddev_Samp_Fields = {
  __typename?: 'GypsumDeckUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type GypsumDeckUsage_Sum_Fields = {
  __typename?: 'GypsumDeckUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "GypsumDeckUsage" */
export enum GypsumDeckUsage_Update_Column {
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  CompressiveStrengthMin = 'CompressiveStrengthMin',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type GypsumDeckUsage_Var_Pop_Fields = {
  __typename?: 'GypsumDeckUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type GypsumDeckUsage_Var_Samp_Fields = {
  __typename?: 'GypsumDeckUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type GypsumDeckUsage_Variance_Fields = {
  __typename?: 'GypsumDeckUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "GypsumDeck" */
export type GypsumDeck_Aggregate = {
  __typename?: 'GypsumDeck_aggregate';
  aggregate?: Maybe<GypsumDeck_Aggregate_Fields>;
  nodes: Array<GypsumDeck>;
};

/** aggregate fields of "GypsumDeck" */
export type GypsumDeck_Aggregate_Fields = {
  __typename?: 'GypsumDeck_aggregate_fields';
  avg?: Maybe<GypsumDeck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GypsumDeck_Max_Fields>;
  min?: Maybe<GypsumDeck_Min_Fields>;
  stddev?: Maybe<GypsumDeck_Stddev_Fields>;
  stddev_pop?: Maybe<GypsumDeck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GypsumDeck_Stddev_Samp_Fields>;
  sum?: Maybe<GypsumDeck_Sum_Fields>;
  var_pop?: Maybe<GypsumDeck_Var_Pop_Fields>;
  var_samp?: Maybe<GypsumDeck_Var_Samp_Fields>;
  variance?: Maybe<GypsumDeck_Variance_Fields>;
};


/** aggregate fields of "GypsumDeck" */
export type GypsumDeck_Aggregate_FieldsCountArgs = {
  column?: Maybe<GypsumDeck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "GypsumDeck" */
export type GypsumDeck_Aggregate_Order_By = {
  avg?: Maybe<GypsumDeck_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<GypsumDeck_Max_Order_By>;
  min?: Maybe<GypsumDeck_Min_Order_By>;
  stddev?: Maybe<GypsumDeck_Stddev_Order_By>;
  stddev_pop?: Maybe<GypsumDeck_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<GypsumDeck_Stddev_Samp_Order_By>;
  sum?: Maybe<GypsumDeck_Sum_Order_By>;
  var_pop?: Maybe<GypsumDeck_Var_Pop_Order_By>;
  var_samp?: Maybe<GypsumDeck_Var_Samp_Order_By>;
  variance?: Maybe<GypsumDeck_Variance_Order_By>;
};

/** aggregate avg on columns */
export type GypsumDeck_Avg_Fields = {
  __typename?: 'GypsumDeck_avg_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "GypsumDeck" */
export type GypsumDeck_Avg_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "GypsumDeck". All fields are combined with a logical 'AND'. */
export type GypsumDeck_Bool_Exp = {
  AdditiveId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_Additive?: Maybe<C_Additive_Bool_Exp>;
  CastDensityMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  CompressiveStrengthMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  DryDensityMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<GypsumDeck_Bool_Exp>>;
  _not?: Maybe<GypsumDeck_Bool_Exp>;
  _or?: Maybe<Array<GypsumDeck_Bool_Exp>>;
};

/** upsert condition type for table "GypsumDeck" */
export type GypsumDeck_If_Matched = {
  match_columns?: Array<GypsumDeck_Insert_Match_Column>;
  update_columns?: Array<GypsumDeck_Update_Column>;
  where?: Maybe<GypsumDeck_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "GypsumDeck" */
export type GypsumDeck_Inc_Input = {
  AdditiveId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "GypsumDeck" */
export type GypsumDeck_Insert_Input = {
  AdditiveId?: Maybe<Scalars['Int']>;
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CompressiveStrengthMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "GypsumDeck" */
export enum GypsumDeck_Insert_Match_Column {
  /** column name */
  AdditiveId = 'AdditiveId',
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CompressiveStrengthMin = 'CompressiveStrengthMin',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate max on columns */
export type GypsumDeck_Max_Fields = {
  __typename?: 'GypsumDeck_max_fields';
  AdditiveId?: Maybe<Scalars['Int']>;
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CompressiveStrengthMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "GypsumDeck" */
export type GypsumDeck_Max_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CompressiveStrengthMin?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type GypsumDeck_Min_Fields = {
  __typename?: 'GypsumDeck_min_fields';
  AdditiveId?: Maybe<Scalars['Int']>;
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CompressiveStrengthMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "GypsumDeck" */
export type GypsumDeck_Min_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CompressiveStrengthMin?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** response of any mutation on the table "GypsumDeck" */
export type GypsumDeck_Mutation_Response = {
  __typename?: 'GypsumDeck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GypsumDeck>;
};

/** Ordering options when selecting data from "GypsumDeck". */
export type GypsumDeck_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  C_Additive?: Maybe<C_Additive_Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CompressiveStrengthMin?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** primary key columns input for table: GypsumDeck */
export type GypsumDeck_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "GypsumDeck" */
export enum GypsumDeck_Select_Column {
  /** column name */
  AdditiveId = 'AdditiveId',
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CompressiveStrengthMin = 'CompressiveStrengthMin',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** input type for updating data in table "GypsumDeck" */
export type GypsumDeck_Set_Input = {
  AdditiveId?: Maybe<Scalars['Int']>;
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CompressiveStrengthMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type GypsumDeck_Stddev_Fields = {
  __typename?: 'GypsumDeck_stddev_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "GypsumDeck" */
export type GypsumDeck_Stddev_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type GypsumDeck_Stddev_Pop_Fields = {
  __typename?: 'GypsumDeck_stddev_pop_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "GypsumDeck" */
export type GypsumDeck_Stddev_Pop_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type GypsumDeck_Stddev_Samp_Fields = {
  __typename?: 'GypsumDeck_stddev_samp_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "GypsumDeck" */
export type GypsumDeck_Stddev_Samp_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type GypsumDeck_Sum_Fields = {
  __typename?: 'GypsumDeck_sum_fields';
  AdditiveId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "GypsumDeck" */
export type GypsumDeck_Sum_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** update columns of table "GypsumDeck" */
export enum GypsumDeck_Update_Column {
  /** column name */
  AdditiveId = 'AdditiveId',
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CompressiveStrengthMin = 'CompressiveStrengthMin',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate var_pop on columns */
export type GypsumDeck_Var_Pop_Fields = {
  __typename?: 'GypsumDeck_var_pop_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "GypsumDeck" */
export type GypsumDeck_Var_Pop_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type GypsumDeck_Var_Samp_Fields = {
  __typename?: 'GypsumDeck_var_samp_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "GypsumDeck" */
export type GypsumDeck_Var_Samp_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type GypsumDeck_Variance_Fields = {
  __typename?: 'GypsumDeck_variance_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "GypsumDeck" */
export type GypsumDeck_Variance_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

export type IRoofAreaRatingInput = {
  BuildingEnclosureId?: Maybe<Scalars['Int']>;
  CalculatorVersion?: Maybe<Scalars['String']>;
  ContinuousParapet?: Maybe<Scalars['Boolean']>;
  EnteredAsMetric?: Maybe<Scalars['Boolean']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  Height?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  Length?: Maybe<Scalars['Float']>;
  Slope?: Maybe<Scalars['Float']>;
  TropicalCycloneRegion?: Maybe<Scalars['Boolean']>;
  Width?: Maybe<Scalars['Float']>;
  WindBorneDebrisId?: Maybe<Scalars['Int']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

export type IRoofAreaWindPressure = {
  CopingRating?: Maybe<Scalars['Float']>;
  FasciaRating?: Maybe<Scalars['Float']>;
  PressureZoneId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "InsBoardSize" */
export type InsBoardSize = {
  __typename?: 'InsBoardSize';
  /** An array relationship */
  BoardStockBoardSizes: Array<BoardStockBoardSizes>;
  /** An aggregate relationship */
  BoardStockBoardSizes_aggregate: BoardStockBoardSizes_Aggregate;
  /** An array relationship */
  BoardStockUsages: Array<BoardStockUsage>;
  /** An aggregate relationship */
  BoardStockUsages_aggregate: BoardStockUsage_Aggregate;
  /** An array relationship */
  CoverBoardBoardSizes: Array<CoverBoardBoardSizes>;
  /** An aggregate relationship */
  CoverBoardBoardSizes_aggregate: CoverBoardBoardSizes_Aggregate;
  /** An array relationship */
  CoverBoardUsages: Array<CoverBoardUsage>;
  /** An aggregate relationship */
  CoverBoardUsages_aggregate: CoverBoardUsage_Aggregate;
  EquivSize?: Maybe<Scalars['String']>;
  InsBoardSizeId: Scalars['Int'];
  IsMetric: Scalars['Boolean'];
  Length: Scalars['MeasurementValue'];
  /** An array relationship */
  ThermalBarrierBoardSizes: Array<ThermalBarrierBoardSizes>;
  /** An aggregate relationship */
  ThermalBarrierBoardSizes_aggregate: ThermalBarrierBoardSizes_Aggregate;
  /** An array relationship */
  ThermalBarrierUsages: Array<ThermalBarrierUsage>;
  /** An aggregate relationship */
  ThermalBarrierUsages_aggregate: ThermalBarrierUsage_Aggregate;
  Width: Scalars['MeasurementValue'];
};


/** columns and relationships of "InsBoardSize" */
export type InsBoardSizeBoardStockBoardSizesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockBoardSizes_Order_By>>;
  where?: Maybe<BoardStockBoardSizes_Bool_Exp>;
};


/** columns and relationships of "InsBoardSize" */
export type InsBoardSizeBoardStockBoardSizes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockBoardSizes_Order_By>>;
  where?: Maybe<BoardStockBoardSizes_Bool_Exp>;
};


/** columns and relationships of "InsBoardSize" */
export type InsBoardSizeBoardStockUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockUsage_Order_By>>;
  where?: Maybe<BoardStockUsage_Bool_Exp>;
};


/** columns and relationships of "InsBoardSize" */
export type InsBoardSizeBoardStockUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockUsage_Order_By>>;
  where?: Maybe<BoardStockUsage_Bool_Exp>;
};


/** columns and relationships of "InsBoardSize" */
export type InsBoardSizeCoverBoardBoardSizesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardBoardSizes_Order_By>>;
  where?: Maybe<CoverBoardBoardSizes_Bool_Exp>;
};


/** columns and relationships of "InsBoardSize" */
export type InsBoardSizeCoverBoardBoardSizes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardBoardSizes_Order_By>>;
  where?: Maybe<CoverBoardBoardSizes_Bool_Exp>;
};


/** columns and relationships of "InsBoardSize" */
export type InsBoardSizeCoverBoardUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardUsage_Order_By>>;
  where?: Maybe<CoverBoardUsage_Bool_Exp>;
};


/** columns and relationships of "InsBoardSize" */
export type InsBoardSizeCoverBoardUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardUsage_Order_By>>;
  where?: Maybe<CoverBoardUsage_Bool_Exp>;
};


/** columns and relationships of "InsBoardSize" */
export type InsBoardSizeThermalBarrierBoardSizesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierBoardSizes_Order_By>>;
  where?: Maybe<ThermalBarrierBoardSizes_Bool_Exp>;
};


/** columns and relationships of "InsBoardSize" */
export type InsBoardSizeThermalBarrierBoardSizes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierBoardSizes_Order_By>>;
  where?: Maybe<ThermalBarrierBoardSizes_Bool_Exp>;
};


/** columns and relationships of "InsBoardSize" */
export type InsBoardSizeThermalBarrierUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierUsage_Order_By>>;
  where?: Maybe<ThermalBarrierUsage_Bool_Exp>;
};


/** columns and relationships of "InsBoardSize" */
export type InsBoardSizeThermalBarrierUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierUsage_Order_By>>;
  where?: Maybe<ThermalBarrierUsage_Bool_Exp>;
};

/** aggregated selection of "InsBoardSize" */
export type InsBoardSize_Aggregate = {
  __typename?: 'InsBoardSize_aggregate';
  aggregate?: Maybe<InsBoardSize_Aggregate_Fields>;
  nodes: Array<InsBoardSize>;
};

/** aggregate fields of "InsBoardSize" */
export type InsBoardSize_Aggregate_Fields = {
  __typename?: 'InsBoardSize_aggregate_fields';
  avg?: Maybe<InsBoardSize_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<InsBoardSize_Max_Fields>;
  min?: Maybe<InsBoardSize_Min_Fields>;
  stddev?: Maybe<InsBoardSize_Stddev_Fields>;
  stddev_pop?: Maybe<InsBoardSize_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<InsBoardSize_Stddev_Samp_Fields>;
  sum?: Maybe<InsBoardSize_Sum_Fields>;
  var_pop?: Maybe<InsBoardSize_Var_Pop_Fields>;
  var_samp?: Maybe<InsBoardSize_Var_Samp_Fields>;
  variance?: Maybe<InsBoardSize_Variance_Fields>;
};


/** aggregate fields of "InsBoardSize" */
export type InsBoardSize_Aggregate_FieldsCountArgs = {
  column?: Maybe<InsBoardSize_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type InsBoardSize_Avg_Fields = {
  __typename?: 'InsBoardSize_avg_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "InsBoardSize". All fields are combined with a logical 'AND'. */
export type InsBoardSize_Bool_Exp = {
  BoardStockBoardSizes?: Maybe<BoardStockBoardSizes_Bool_Exp>;
  BoardStockUsages?: Maybe<BoardStockUsage_Bool_Exp>;
  CoverBoardBoardSizes?: Maybe<CoverBoardBoardSizes_Bool_Exp>;
  CoverBoardUsages?: Maybe<CoverBoardUsage_Bool_Exp>;
  EquivSize?: Maybe<String_Mssql_Comparison_Exp>;
  InsBoardSizeId?: Maybe<Int_Mssql_Comparison_Exp>;
  IsMetric?: Maybe<Boolean_Mssql_Comparison_Exp>;
  Length?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThermalBarrierBoardSizes?: Maybe<ThermalBarrierBoardSizes_Bool_Exp>;
  ThermalBarrierUsages?: Maybe<ThermalBarrierUsage_Bool_Exp>;
  Width?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<InsBoardSize_Bool_Exp>>;
  _not?: Maybe<InsBoardSize_Bool_Exp>;
  _or?: Maybe<Array<InsBoardSize_Bool_Exp>>;
};

/** upsert condition type for table "InsBoardSize" */
export type InsBoardSize_If_Matched = {
  match_columns?: Array<InsBoardSize_Insert_Match_Column>;
  update_columns?: Array<InsBoardSize_Update_Column>;
  where?: Maybe<InsBoardSize_Bool_Exp>;
};

/** input type for inserting data into table "InsBoardSize" */
export type InsBoardSize_Insert_Input = {
  EquivSize?: Maybe<Scalars['String']>;
  IsMetric?: Maybe<Scalars['Boolean']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "InsBoardSize" */
export enum InsBoardSize_Insert_Match_Column {
  /** column name */
  EquivSize = 'EquivSize',
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId',
  /** column name */
  IsMetric = 'IsMetric',
  /** column name */
  Length = 'Length',
  /** column name */
  Width = 'Width'
}

/** aggregate max on columns */
export type InsBoardSize_Max_Fields = {
  __typename?: 'InsBoardSize_max_fields';
  EquivSize?: Maybe<Scalars['String']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate min on columns */
export type InsBoardSize_Min_Fields = {
  __typename?: 'InsBoardSize_min_fields';
  EquivSize?: Maybe<Scalars['String']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** response of any mutation on the table "InsBoardSize" */
export type InsBoardSize_Mutation_Response = {
  __typename?: 'InsBoardSize_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InsBoardSize>;
};

/** Ordering options when selecting data from "InsBoardSize". */
export type InsBoardSize_Order_By = {
  BoardStockBoardSizes_aggregate?: Maybe<BoardStockBoardSizes_Aggregate_Order_By>;
  BoardStockUsages_aggregate?: Maybe<BoardStockUsage_Aggregate_Order_By>;
  CoverBoardBoardSizes_aggregate?: Maybe<CoverBoardBoardSizes_Aggregate_Order_By>;
  CoverBoardUsages_aggregate?: Maybe<CoverBoardUsage_Aggregate_Order_By>;
  EquivSize?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
  IsMetric?: Maybe<Order_By>;
  Length?: Maybe<Order_By>;
  ThermalBarrierBoardSizes_aggregate?: Maybe<ThermalBarrierBoardSizes_Aggregate_Order_By>;
  ThermalBarrierUsages_aggregate?: Maybe<ThermalBarrierUsage_Aggregate_Order_By>;
  Width?: Maybe<Order_By>;
};

/** primary key columns input for table: InsBoardSize */
export type InsBoardSize_Pk_Columns_Input = {
  InsBoardSizeId: Scalars['Int'];
};

/** select columns of table "InsBoardSize" */
export enum InsBoardSize_Select_Column {
  /** column name */
  EquivSize = 'EquivSize',
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId',
  /** column name */
  IsMetric = 'IsMetric',
  /** column name */
  Length = 'Length',
  /** column name */
  Width = 'Width'
}

/** input type for updating data in table "InsBoardSize" */
export type InsBoardSize_Set_Input = {
  EquivSize?: Maybe<Scalars['String']>;
  IsMetric?: Maybe<Scalars['Boolean']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type InsBoardSize_Stddev_Fields = {
  __typename?: 'InsBoardSize_stddev_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type InsBoardSize_Stddev_Pop_Fields = {
  __typename?: 'InsBoardSize_stddev_pop_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type InsBoardSize_Stddev_Samp_Fields = {
  __typename?: 'InsBoardSize_stddev_samp_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type InsBoardSize_Sum_Fields = {
  __typename?: 'InsBoardSize_sum_fields';
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "InsBoardSize" */
export enum InsBoardSize_Update_Column {
  /** column name */
  EquivSize = 'EquivSize',
  /** column name */
  IsMetric = 'IsMetric',
  /** column name */
  Length = 'Length',
  /** column name */
  Width = 'Width'
}

/** aggregate var_pop on columns */
export type InsBoardSize_Var_Pop_Fields = {
  __typename?: 'InsBoardSize_var_pop_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type InsBoardSize_Var_Samp_Fields = {
  __typename?: 'InsBoardSize_var_samp_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type InsBoardSize_Variance_Fields = {
  __typename?: 'InsBoardSize_variance_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Mssql_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};


/** Boolean expression to compare columns of type "ItemComment". All fields are combined with logical 'AND'. */
export type ItemComment_Mssql_Comparison_Exp = {
  _eq?: Maybe<Scalars['ItemComment']>;
  _gt?: Maybe<Scalars['ItemComment']>;
  _gte?: Maybe<Scalars['ItemComment']>;
  _in?: Maybe<Array<Scalars['ItemComment']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['ItemComment']>;
  _lte?: Maybe<Scalars['ItemComment']>;
  _neq?: Maybe<Scalars['ItemComment']>;
  _nin?: Maybe<Array<Scalars['ItemComment']>>;
};

/** columns and relationships of "ItemWorkspace" */
export type ItemWorkspace = {
  __typename?: 'ItemWorkspace';
  AdditionalData?: Maybe<Scalars['String']>;
  /** An object relationship */
  C_ComponentType?: Maybe<C_ComponentType>;
  /** An object relationship */
  C_WorkflowStatus: C_WorkflowStatus;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  DataOriginationType: Scalars['Int'];
  DateLastSaved: Scalars['datetime'];
  /** An object relationship */
  FMUserAccount: FmUserAccount;
  HistoryChangeType: Scalars['Int'];
  ItemId: Scalars['Int'];
  ItemName: Scalars['String'];
  /** An array relationship */
  ItemWorkspaceData: Array<ItemWorkspaceData>;
  /** An aggregate relationship */
  ItemWorkspaceData_aggregate: ItemWorkspaceData_Aggregate;
  ItemWorkspaceId: Scalars['Int'];
  /** An object relationship */
  Manufacturer?: Maybe<Manufacturer>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ReviewerComment: Scalars['ItemComment'];
  UserAccountId: Scalars['Int'];
  WIPNumber?: Maybe<Scalars['String']>;
  WorkflowStatusId: Scalars['Int'];
};


/** columns and relationships of "ItemWorkspace" */
export type ItemWorkspaceItemWorkspaceDataArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspaceData_Order_By>>;
  where?: Maybe<ItemWorkspaceData_Bool_Exp>;
};


/** columns and relationships of "ItemWorkspace" */
export type ItemWorkspaceItemWorkspaceData_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspaceData_Order_By>>;
  where?: Maybe<ItemWorkspaceData_Bool_Exp>;
};

/** columns and relationships of "ItemWorkspaceData" */
export type ItemWorkspaceData = {
  __typename?: 'ItemWorkspaceData';
  EntityType: Scalars['String'];
  ItemDataBinary: Scalars['varbinary'];
  /** An object relationship */
  ItemWorkspace: ItemWorkspace;
  ItemWorkspaceDataId: Scalars['Int'];
  ItemWorkspaceId: Scalars['Int'];
};

/** aggregated selection of "ItemWorkspaceData" */
export type ItemWorkspaceData_Aggregate = {
  __typename?: 'ItemWorkspaceData_aggregate';
  aggregate?: Maybe<ItemWorkspaceData_Aggregate_Fields>;
  nodes: Array<ItemWorkspaceData>;
};

/** aggregate fields of "ItemWorkspaceData" */
export type ItemWorkspaceData_Aggregate_Fields = {
  __typename?: 'ItemWorkspaceData_aggregate_fields';
  avg?: Maybe<ItemWorkspaceData_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ItemWorkspaceData_Max_Fields>;
  min?: Maybe<ItemWorkspaceData_Min_Fields>;
  stddev?: Maybe<ItemWorkspaceData_Stddev_Fields>;
  stddev_pop?: Maybe<ItemWorkspaceData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ItemWorkspaceData_Stddev_Samp_Fields>;
  sum?: Maybe<ItemWorkspaceData_Sum_Fields>;
  var_pop?: Maybe<ItemWorkspaceData_Var_Pop_Fields>;
  var_samp?: Maybe<ItemWorkspaceData_Var_Samp_Fields>;
  variance?: Maybe<ItemWorkspaceData_Variance_Fields>;
};


/** aggregate fields of "ItemWorkspaceData" */
export type ItemWorkspaceData_Aggregate_FieldsCountArgs = {
  column?: Maybe<ItemWorkspaceData_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ItemWorkspaceData" */
export type ItemWorkspaceData_Aggregate_Order_By = {
  avg?: Maybe<ItemWorkspaceData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ItemWorkspaceData_Max_Order_By>;
  min?: Maybe<ItemWorkspaceData_Min_Order_By>;
  stddev?: Maybe<ItemWorkspaceData_Stddev_Order_By>;
  stddev_pop?: Maybe<ItemWorkspaceData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ItemWorkspaceData_Stddev_Samp_Order_By>;
  sum?: Maybe<ItemWorkspaceData_Sum_Order_By>;
  var_pop?: Maybe<ItemWorkspaceData_Var_Pop_Order_By>;
  var_samp?: Maybe<ItemWorkspaceData_Var_Samp_Order_By>;
  variance?: Maybe<ItemWorkspaceData_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ItemWorkspaceData_Avg_Fields = {
  __typename?: 'ItemWorkspaceData_avg_fields';
  ItemWorkspaceDataId?: Maybe<Scalars['Float']>;
  ItemWorkspaceId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ItemWorkspaceData" */
export type ItemWorkspaceData_Avg_Order_By = {
  ItemWorkspaceDataId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ItemWorkspaceData". All fields are combined with a logical 'AND'. */
export type ItemWorkspaceData_Bool_Exp = {
  EntityType?: Maybe<String_Mssql_Comparison_Exp>;
  ItemDataBinary?: Maybe<Varbinary_Mssql_Comparison_Exp>;
  ItemWorkspace?: Maybe<ItemWorkspace_Bool_Exp>;
  ItemWorkspaceDataId?: Maybe<Int_Mssql_Comparison_Exp>;
  ItemWorkspaceId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ItemWorkspaceData_Bool_Exp>>;
  _not?: Maybe<ItemWorkspaceData_Bool_Exp>;
  _or?: Maybe<Array<ItemWorkspaceData_Bool_Exp>>;
};

/** upsert condition type for table "ItemWorkspaceData" */
export type ItemWorkspaceData_If_Matched = {
  match_columns?: Array<ItemWorkspaceData_Insert_Match_Column>;
  update_columns?: Array<ItemWorkspaceData_Update_Column>;
  where?: Maybe<ItemWorkspaceData_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ItemWorkspaceData" */
export type ItemWorkspaceData_Inc_Input = {
  ItemWorkspaceId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ItemWorkspaceData" */
export type ItemWorkspaceData_Insert_Input = {
  EntityType?: Maybe<Scalars['String']>;
  ItemDataBinary?: Maybe<Scalars['varbinary']>;
  ItemWorkspaceId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ItemWorkspaceData" */
export enum ItemWorkspaceData_Insert_Match_Column {
  /** column name */
  EntityType = 'EntityType',
  /** column name */
  ItemDataBinary = 'ItemDataBinary',
  /** column name */
  ItemWorkspaceDataId = 'ItemWorkspaceDataId',
  /** column name */
  ItemWorkspaceId = 'ItemWorkspaceId'
}

/** aggregate max on columns */
export type ItemWorkspaceData_Max_Fields = {
  __typename?: 'ItemWorkspaceData_max_fields';
  EntityType?: Maybe<Scalars['String']>;
  ItemWorkspaceDataId?: Maybe<Scalars['Int']>;
  ItemWorkspaceId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ItemWorkspaceData" */
export type ItemWorkspaceData_Max_Order_By = {
  EntityType?: Maybe<Order_By>;
  ItemWorkspaceDataId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ItemWorkspaceData_Min_Fields = {
  __typename?: 'ItemWorkspaceData_min_fields';
  EntityType?: Maybe<Scalars['String']>;
  ItemWorkspaceDataId?: Maybe<Scalars['Int']>;
  ItemWorkspaceId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ItemWorkspaceData" */
export type ItemWorkspaceData_Min_Order_By = {
  EntityType?: Maybe<Order_By>;
  ItemWorkspaceDataId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ItemWorkspaceData" */
export type ItemWorkspaceData_Mutation_Response = {
  __typename?: 'ItemWorkspaceData_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ItemWorkspaceData>;
};

/** Ordering options when selecting data from "ItemWorkspaceData". */
export type ItemWorkspaceData_Order_By = {
  EntityType?: Maybe<Order_By>;
  ItemDataBinary?: Maybe<Order_By>;
  ItemWorkspace?: Maybe<ItemWorkspace_Order_By>;
  ItemWorkspaceDataId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
};

/** primary key columns input for table: ItemWorkspaceData */
export type ItemWorkspaceData_Pk_Columns_Input = {
  ItemWorkspaceDataId: Scalars['Int'];
};

/** select columns of table "ItemWorkspaceData" */
export enum ItemWorkspaceData_Select_Column {
  /** column name */
  EntityType = 'EntityType',
  /** column name */
  ItemDataBinary = 'ItemDataBinary',
  /** column name */
  ItemWorkspaceDataId = 'ItemWorkspaceDataId',
  /** column name */
  ItemWorkspaceId = 'ItemWorkspaceId'
}

/** input type for updating data in table "ItemWorkspaceData" */
export type ItemWorkspaceData_Set_Input = {
  EntityType?: Maybe<Scalars['String']>;
  ItemDataBinary?: Maybe<Scalars['varbinary']>;
  ItemWorkspaceId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ItemWorkspaceData_Stddev_Fields = {
  __typename?: 'ItemWorkspaceData_stddev_fields';
  ItemWorkspaceDataId?: Maybe<Scalars['Float']>;
  ItemWorkspaceId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ItemWorkspaceData" */
export type ItemWorkspaceData_Stddev_Order_By = {
  ItemWorkspaceDataId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ItemWorkspaceData_Stddev_Pop_Fields = {
  __typename?: 'ItemWorkspaceData_stddev_pop_fields';
  ItemWorkspaceDataId?: Maybe<Scalars['Float']>;
  ItemWorkspaceId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ItemWorkspaceData" */
export type ItemWorkspaceData_Stddev_Pop_Order_By = {
  ItemWorkspaceDataId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ItemWorkspaceData_Stddev_Samp_Fields = {
  __typename?: 'ItemWorkspaceData_stddev_samp_fields';
  ItemWorkspaceDataId?: Maybe<Scalars['Float']>;
  ItemWorkspaceId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ItemWorkspaceData" */
export type ItemWorkspaceData_Stddev_Samp_Order_By = {
  ItemWorkspaceDataId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ItemWorkspaceData_Sum_Fields = {
  __typename?: 'ItemWorkspaceData_sum_fields';
  ItemWorkspaceDataId?: Maybe<Scalars['Int']>;
  ItemWorkspaceId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ItemWorkspaceData" */
export type ItemWorkspaceData_Sum_Order_By = {
  ItemWorkspaceDataId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
};

/** update columns of table "ItemWorkspaceData" */
export enum ItemWorkspaceData_Update_Column {
  /** column name */
  EntityType = 'EntityType',
  /** column name */
  ItemDataBinary = 'ItemDataBinary',
  /** column name */
  ItemWorkspaceId = 'ItemWorkspaceId'
}

/** aggregate var_pop on columns */
export type ItemWorkspaceData_Var_Pop_Fields = {
  __typename?: 'ItemWorkspaceData_var_pop_fields';
  ItemWorkspaceDataId?: Maybe<Scalars['Float']>;
  ItemWorkspaceId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ItemWorkspaceData" */
export type ItemWorkspaceData_Var_Pop_Order_By = {
  ItemWorkspaceDataId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ItemWorkspaceData_Var_Samp_Fields = {
  __typename?: 'ItemWorkspaceData_var_samp_fields';
  ItemWorkspaceDataId?: Maybe<Scalars['Float']>;
  ItemWorkspaceId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ItemWorkspaceData" */
export type ItemWorkspaceData_Var_Samp_Order_By = {
  ItemWorkspaceDataId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ItemWorkspaceData_Variance_Fields = {
  __typename?: 'ItemWorkspaceData_variance_fields';
  ItemWorkspaceDataId?: Maybe<Scalars['Float']>;
  ItemWorkspaceId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ItemWorkspaceData" */
export type ItemWorkspaceData_Variance_Order_By = {
  ItemWorkspaceDataId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
};

/** aggregated selection of "ItemWorkspace" */
export type ItemWorkspace_Aggregate = {
  __typename?: 'ItemWorkspace_aggregate';
  aggregate?: Maybe<ItemWorkspace_Aggregate_Fields>;
  nodes: Array<ItemWorkspace>;
};

/** aggregate fields of "ItemWorkspace" */
export type ItemWorkspace_Aggregate_Fields = {
  __typename?: 'ItemWorkspace_aggregate_fields';
  avg?: Maybe<ItemWorkspace_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ItemWorkspace_Max_Fields>;
  min?: Maybe<ItemWorkspace_Min_Fields>;
  stddev?: Maybe<ItemWorkspace_Stddev_Fields>;
  stddev_pop?: Maybe<ItemWorkspace_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ItemWorkspace_Stddev_Samp_Fields>;
  sum?: Maybe<ItemWorkspace_Sum_Fields>;
  var_pop?: Maybe<ItemWorkspace_Var_Pop_Fields>;
  var_samp?: Maybe<ItemWorkspace_Var_Samp_Fields>;
  variance?: Maybe<ItemWorkspace_Variance_Fields>;
};


/** aggregate fields of "ItemWorkspace" */
export type ItemWorkspace_Aggregate_FieldsCountArgs = {
  column?: Maybe<ItemWorkspace_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ItemWorkspace" */
export type ItemWorkspace_Aggregate_Order_By = {
  avg?: Maybe<ItemWorkspace_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ItemWorkspace_Max_Order_By>;
  min?: Maybe<ItemWorkspace_Min_Order_By>;
  stddev?: Maybe<ItemWorkspace_Stddev_Order_By>;
  stddev_pop?: Maybe<ItemWorkspace_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ItemWorkspace_Stddev_Samp_Order_By>;
  sum?: Maybe<ItemWorkspace_Sum_Order_By>;
  var_pop?: Maybe<ItemWorkspace_Var_Pop_Order_By>;
  var_samp?: Maybe<ItemWorkspace_Var_Samp_Order_By>;
  variance?: Maybe<ItemWorkspace_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ItemWorkspace_Avg_Fields = {
  __typename?: 'ItemWorkspace_avg_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
  DataOriginationType?: Maybe<Scalars['Float']>;
  HistoryChangeType?: Maybe<Scalars['Float']>;
  ItemId?: Maybe<Scalars['Float']>;
  ItemWorkspaceId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ItemWorkspace" */
export type ItemWorkspace_Avg_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ItemWorkspace". All fields are combined with a logical 'AND'. */
export type ItemWorkspace_Bool_Exp = {
  AdditionalData?: Maybe<String_Mssql_Comparison_Exp>;
  C_ComponentType?: Maybe<C_ComponentType_Bool_Exp>;
  C_WorkflowStatus?: Maybe<C_WorkflowStatus_Bool_Exp>;
  ComponentTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  DataOriginationType?: Maybe<Int_Mssql_Comparison_Exp>;
  DateLastSaved?: Maybe<Datetime_Mssql_Comparison_Exp>;
  FMUserAccount?: Maybe<FmUserAccount_Bool_Exp>;
  HistoryChangeType?: Maybe<Int_Mssql_Comparison_Exp>;
  ItemId?: Maybe<Int_Mssql_Comparison_Exp>;
  ItemName?: Maybe<String_Mssql_Comparison_Exp>;
  ItemWorkspaceData?: Maybe<ItemWorkspaceData_Bool_Exp>;
  ItemWorkspaceId?: Maybe<Int_Mssql_Comparison_Exp>;
  Manufacturer?: Maybe<Manufacturer_Bool_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ReviewerComment?: Maybe<ItemComment_Mssql_Comparison_Exp>;
  UserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  WIPNumber?: Maybe<String_Mssql_Comparison_Exp>;
  WorkflowStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ItemWorkspace_Bool_Exp>>;
  _not?: Maybe<ItemWorkspace_Bool_Exp>;
  _or?: Maybe<Array<ItemWorkspace_Bool_Exp>>;
};

/** upsert condition type for table "ItemWorkspace" */
export type ItemWorkspace_If_Matched = {
  match_columns?: Array<ItemWorkspace_Insert_Match_Column>;
  update_columns?: Array<ItemWorkspace_Update_Column>;
  where?: Maybe<ItemWorkspace_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ItemWorkspace" */
export type ItemWorkspace_Inc_Input = {
  ComponentTypeId?: Maybe<Scalars['Int']>;
  DataOriginationType?: Maybe<Scalars['Int']>;
  HistoryChangeType?: Maybe<Scalars['Int']>;
  ItemId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ItemWorkspace" */
export type ItemWorkspace_Insert_Input = {
  AdditionalData?: Maybe<Scalars['String']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  DataOriginationType?: Maybe<Scalars['Int']>;
  DateLastSaved?: Maybe<Scalars['datetime']>;
  HistoryChangeType?: Maybe<Scalars['Int']>;
  ItemId?: Maybe<Scalars['Int']>;
  ItemName?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ReviewerComment?: Maybe<Scalars['ItemComment']>;
  UserAccountId?: Maybe<Scalars['Int']>;
  WIPNumber?: Maybe<Scalars['String']>;
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ItemWorkspace" */
export enum ItemWorkspace_Insert_Match_Column {
  /** column name */
  AdditionalData = 'AdditionalData',
  /** column name */
  ComponentTypeId = 'ComponentTypeId',
  /** column name */
  DataOriginationType = 'DataOriginationType',
  /** column name */
  DateLastSaved = 'DateLastSaved',
  /** column name */
  HistoryChangeType = 'HistoryChangeType',
  /** column name */
  ItemId = 'ItemId',
  /** column name */
  ItemName = 'ItemName',
  /** column name */
  ItemWorkspaceId = 'ItemWorkspaceId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ReviewerComment = 'ReviewerComment',
  /** column name */
  UserAccountId = 'UserAccountId',
  /** column name */
  WipNumber = 'WIPNumber',
  /** column name */
  WorkflowStatusId = 'WorkflowStatusId'
}

/** aggregate max on columns */
export type ItemWorkspace_Max_Fields = {
  __typename?: 'ItemWorkspace_max_fields';
  AdditionalData?: Maybe<Scalars['String']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  DataOriginationType?: Maybe<Scalars['Int']>;
  DateLastSaved?: Maybe<Scalars['datetime']>;
  HistoryChangeType?: Maybe<Scalars['Int']>;
  ItemId?: Maybe<Scalars['Int']>;
  ItemName?: Maybe<Scalars['String']>;
  ItemWorkspaceId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ReviewerComment?: Maybe<Scalars['ItemComment']>;
  UserAccountId?: Maybe<Scalars['Int']>;
  WIPNumber?: Maybe<Scalars['String']>;
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ItemWorkspace" */
export type ItemWorkspace_Max_Order_By = {
  AdditionalData?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  DateLastSaved?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ItemName?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ReviewerComment?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
  WIPNumber?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ItemWorkspace_Min_Fields = {
  __typename?: 'ItemWorkspace_min_fields';
  AdditionalData?: Maybe<Scalars['String']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  DataOriginationType?: Maybe<Scalars['Int']>;
  DateLastSaved?: Maybe<Scalars['datetime']>;
  HistoryChangeType?: Maybe<Scalars['Int']>;
  ItemId?: Maybe<Scalars['Int']>;
  ItemName?: Maybe<Scalars['String']>;
  ItemWorkspaceId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ReviewerComment?: Maybe<Scalars['ItemComment']>;
  UserAccountId?: Maybe<Scalars['Int']>;
  WIPNumber?: Maybe<Scalars['String']>;
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ItemWorkspace" */
export type ItemWorkspace_Min_Order_By = {
  AdditionalData?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  DateLastSaved?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ItemName?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ReviewerComment?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
  WIPNumber?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ItemWorkspace" */
export type ItemWorkspace_Mutation_Response = {
  __typename?: 'ItemWorkspace_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ItemWorkspace>;
};

/** Ordering options when selecting data from "ItemWorkspace". */
export type ItemWorkspace_Order_By = {
  AdditionalData?: Maybe<Order_By>;
  C_ComponentType?: Maybe<C_ComponentType_Order_By>;
  C_WorkflowStatus?: Maybe<C_WorkflowStatus_Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  DateLastSaved?: Maybe<Order_By>;
  FMUserAccount?: Maybe<FmUserAccount_Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ItemName?: Maybe<Order_By>;
  ItemWorkspaceData_aggregate?: Maybe<ItemWorkspaceData_Aggregate_Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
  Manufacturer?: Maybe<Manufacturer_Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ReviewerComment?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
  WIPNumber?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** primary key columns input for table: ItemWorkspace */
export type ItemWorkspace_Pk_Columns_Input = {
  ItemWorkspaceId: Scalars['Int'];
};

/** select columns of table "ItemWorkspace" */
export enum ItemWorkspace_Select_Column {
  /** column name */
  AdditionalData = 'AdditionalData',
  /** column name */
  ComponentTypeId = 'ComponentTypeId',
  /** column name */
  DataOriginationType = 'DataOriginationType',
  /** column name */
  DateLastSaved = 'DateLastSaved',
  /** column name */
  HistoryChangeType = 'HistoryChangeType',
  /** column name */
  ItemId = 'ItemId',
  /** column name */
  ItemName = 'ItemName',
  /** column name */
  ItemWorkspaceId = 'ItemWorkspaceId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ReviewerComment = 'ReviewerComment',
  /** column name */
  UserAccountId = 'UserAccountId',
  /** column name */
  WipNumber = 'WIPNumber',
  /** column name */
  WorkflowStatusId = 'WorkflowStatusId'
}

/** input type for updating data in table "ItemWorkspace" */
export type ItemWorkspace_Set_Input = {
  AdditionalData?: Maybe<Scalars['String']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  DataOriginationType?: Maybe<Scalars['Int']>;
  DateLastSaved?: Maybe<Scalars['datetime']>;
  HistoryChangeType?: Maybe<Scalars['Int']>;
  ItemId?: Maybe<Scalars['Int']>;
  ItemName?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ReviewerComment?: Maybe<Scalars['ItemComment']>;
  UserAccountId?: Maybe<Scalars['Int']>;
  WIPNumber?: Maybe<Scalars['String']>;
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ItemWorkspace_Stddev_Fields = {
  __typename?: 'ItemWorkspace_stddev_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
  DataOriginationType?: Maybe<Scalars['Float']>;
  HistoryChangeType?: Maybe<Scalars['Float']>;
  ItemId?: Maybe<Scalars['Float']>;
  ItemWorkspaceId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ItemWorkspace" */
export type ItemWorkspace_Stddev_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ItemWorkspace_Stddev_Pop_Fields = {
  __typename?: 'ItemWorkspace_stddev_pop_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
  DataOriginationType?: Maybe<Scalars['Float']>;
  HistoryChangeType?: Maybe<Scalars['Float']>;
  ItemId?: Maybe<Scalars['Float']>;
  ItemWorkspaceId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ItemWorkspace" */
export type ItemWorkspace_Stddev_Pop_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ItemWorkspace_Stddev_Samp_Fields = {
  __typename?: 'ItemWorkspace_stddev_samp_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
  DataOriginationType?: Maybe<Scalars['Float']>;
  HistoryChangeType?: Maybe<Scalars['Float']>;
  ItemId?: Maybe<Scalars['Float']>;
  ItemWorkspaceId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ItemWorkspace" */
export type ItemWorkspace_Stddev_Samp_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ItemWorkspace_Sum_Fields = {
  __typename?: 'ItemWorkspace_sum_fields';
  ComponentTypeId?: Maybe<Scalars['Int']>;
  DataOriginationType?: Maybe<Scalars['Int']>;
  HistoryChangeType?: Maybe<Scalars['Int']>;
  ItemId?: Maybe<Scalars['Int']>;
  ItemWorkspaceId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ItemWorkspace" */
export type ItemWorkspace_Sum_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** update columns of table "ItemWorkspace" */
export enum ItemWorkspace_Update_Column {
  /** column name */
  AdditionalData = 'AdditionalData',
  /** column name */
  ComponentTypeId = 'ComponentTypeId',
  /** column name */
  DataOriginationType = 'DataOriginationType',
  /** column name */
  DateLastSaved = 'DateLastSaved',
  /** column name */
  HistoryChangeType = 'HistoryChangeType',
  /** column name */
  ItemId = 'ItemId',
  /** column name */
  ItemName = 'ItemName',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ReviewerComment = 'ReviewerComment',
  /** column name */
  UserAccountId = 'UserAccountId',
  /** column name */
  WipNumber = 'WIPNumber',
  /** column name */
  WorkflowStatusId = 'WorkflowStatusId'
}

/** aggregate var_pop on columns */
export type ItemWorkspace_Var_Pop_Fields = {
  __typename?: 'ItemWorkspace_var_pop_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
  DataOriginationType?: Maybe<Scalars['Float']>;
  HistoryChangeType?: Maybe<Scalars['Float']>;
  ItemId?: Maybe<Scalars['Float']>;
  ItemWorkspaceId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ItemWorkspace" */
export type ItemWorkspace_Var_Pop_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ItemWorkspace_Var_Samp_Fields = {
  __typename?: 'ItemWorkspace_var_samp_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
  DataOriginationType?: Maybe<Scalars['Float']>;
  HistoryChangeType?: Maybe<Scalars['Float']>;
  ItemId?: Maybe<Scalars['Float']>;
  ItemWorkspaceId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ItemWorkspace" */
export type ItemWorkspace_Var_Samp_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ItemWorkspace_Variance_Fields = {
  __typename?: 'ItemWorkspace_variance_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
  DataOriginationType?: Maybe<Scalars['Float']>;
  HistoryChangeType?: Maybe<Scalars['Float']>;
  ItemId?: Maybe<Scalars['Float']>;
  ItemWorkspaceId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ItemWorkspace" */
export type ItemWorkspace_Variance_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ItemWorkspaceId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** columns and relationships of "LapSealant" */
export type LapSealant = {
  __typename?: 'LapSealant';
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
};

/** columns and relationships of "LapSealantUsage" */
export type LapSealantUsage = {
  __typename?: 'LapSealantUsage';
  /** An object relationship */
  Component: Component;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "LapSealantUsage" */
export type LapSealantUsage_Aggregate = {
  __typename?: 'LapSealantUsage_aggregate';
  aggregate?: Maybe<LapSealantUsage_Aggregate_Fields>;
  nodes: Array<LapSealantUsage>;
};

/** aggregate fields of "LapSealantUsage" */
export type LapSealantUsage_Aggregate_Fields = {
  __typename?: 'LapSealantUsage_aggregate_fields';
  avg?: Maybe<LapSealantUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LapSealantUsage_Max_Fields>;
  min?: Maybe<LapSealantUsage_Min_Fields>;
  stddev?: Maybe<LapSealantUsage_Stddev_Fields>;
  stddev_pop?: Maybe<LapSealantUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LapSealantUsage_Stddev_Samp_Fields>;
  sum?: Maybe<LapSealantUsage_Sum_Fields>;
  var_pop?: Maybe<LapSealantUsage_Var_Pop_Fields>;
  var_samp?: Maybe<LapSealantUsage_Var_Samp_Fields>;
  variance?: Maybe<LapSealantUsage_Variance_Fields>;
};


/** aggregate fields of "LapSealantUsage" */
export type LapSealantUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<LapSealantUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "LapSealantUsage" */
export type LapSealantUsage_Aggregate_Order_By = {
  avg?: Maybe<LapSealantUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<LapSealantUsage_Max_Order_By>;
  min?: Maybe<LapSealantUsage_Min_Order_By>;
  stddev?: Maybe<LapSealantUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<LapSealantUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<LapSealantUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<LapSealantUsage_Sum_Order_By>;
  var_pop?: Maybe<LapSealantUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<LapSealantUsage_Var_Samp_Order_By>;
  variance?: Maybe<LapSealantUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type LapSealantUsage_Avg_Fields = {
  __typename?: 'LapSealantUsage_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "LapSealantUsage" */
export type LapSealantUsage_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "LapSealantUsage". All fields are combined with a logical 'AND'. */
export type LapSealantUsage_Bool_Exp = {
  Component?: Maybe<Component_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<LapSealantUsage_Bool_Exp>>;
  _not?: Maybe<LapSealantUsage_Bool_Exp>;
  _or?: Maybe<Array<LapSealantUsage_Bool_Exp>>;
};

/** upsert condition type for table "LapSealantUsage" */
export type LapSealantUsage_If_Matched = {
  match_columns?: Array<LapSealantUsage_Insert_Match_Column>;
  update_columns?: Array<LapSealantUsage_Update_Column>;
  where?: Maybe<LapSealantUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "LapSealantUsage" */
export type LapSealantUsage_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "LapSealantUsage" */
export type LapSealantUsage_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "LapSealantUsage" */
export enum LapSealantUsage_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type LapSealantUsage_Max_Fields = {
  __typename?: 'LapSealantUsage_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "LapSealantUsage" */
export type LapSealantUsage_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type LapSealantUsage_Min_Fields = {
  __typename?: 'LapSealantUsage_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "LapSealantUsage" */
export type LapSealantUsage_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "LapSealantUsage" */
export type LapSealantUsage_Mutation_Response = {
  __typename?: 'LapSealantUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LapSealantUsage>;
};

/** Ordering options when selecting data from "LapSealantUsage". */
export type LapSealantUsage_Order_By = {
  Component?: Maybe<Component_Order_By>;
  ComponentId?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: LapSealantUsage */
export type LapSealantUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "LapSealantUsage" */
export enum LapSealantUsage_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "LapSealantUsage" */
export type LapSealantUsage_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type LapSealantUsage_Stddev_Fields = {
  __typename?: 'LapSealantUsage_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "LapSealantUsage" */
export type LapSealantUsage_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type LapSealantUsage_Stddev_Pop_Fields = {
  __typename?: 'LapSealantUsage_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "LapSealantUsage" */
export type LapSealantUsage_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type LapSealantUsage_Stddev_Samp_Fields = {
  __typename?: 'LapSealantUsage_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "LapSealantUsage" */
export type LapSealantUsage_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type LapSealantUsage_Sum_Fields = {
  __typename?: 'LapSealantUsage_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "LapSealantUsage" */
export type LapSealantUsage_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "LapSealantUsage" */
export enum LapSealantUsage_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type LapSealantUsage_Var_Pop_Fields = {
  __typename?: 'LapSealantUsage_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "LapSealantUsage" */
export type LapSealantUsage_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type LapSealantUsage_Var_Samp_Fields = {
  __typename?: 'LapSealantUsage_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "LapSealantUsage" */
export type LapSealantUsage_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type LapSealantUsage_Variance_Fields = {
  __typename?: 'LapSealantUsage_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "LapSealantUsage" */
export type LapSealantUsage_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregated selection of "LapSealant" */
export type LapSealant_Aggregate = {
  __typename?: 'LapSealant_aggregate';
  aggregate?: Maybe<LapSealant_Aggregate_Fields>;
  nodes: Array<LapSealant>;
};

/** aggregate fields of "LapSealant" */
export type LapSealant_Aggregate_Fields = {
  __typename?: 'LapSealant_aggregate_fields';
  avg?: Maybe<LapSealant_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LapSealant_Max_Fields>;
  min?: Maybe<LapSealant_Min_Fields>;
  stddev?: Maybe<LapSealant_Stddev_Fields>;
  stddev_pop?: Maybe<LapSealant_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LapSealant_Stddev_Samp_Fields>;
  sum?: Maybe<LapSealant_Sum_Fields>;
  var_pop?: Maybe<LapSealant_Var_Pop_Fields>;
  var_samp?: Maybe<LapSealant_Var_Samp_Fields>;
  variance?: Maybe<LapSealant_Variance_Fields>;
};


/** aggregate fields of "LapSealant" */
export type LapSealant_Aggregate_FieldsCountArgs = {
  column?: Maybe<LapSealant_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LapSealant_Avg_Fields = {
  __typename?: 'LapSealant_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "LapSealant". All fields are combined with a logical 'AND'. */
export type LapSealant_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  _and?: Maybe<Array<LapSealant_Bool_Exp>>;
  _not?: Maybe<LapSealant_Bool_Exp>;
  _or?: Maybe<Array<LapSealant_Bool_Exp>>;
};

/** upsert condition type for table "LapSealant" */
export type LapSealant_If_Matched = {
  match_columns?: Array<LapSealant_Insert_Match_Column>;
  update_columns?: Array<LapSealant_Update_Column>;
  where?: Maybe<LapSealant_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "LapSealant" */
export type LapSealant_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "LapSealant" */
export type LapSealant_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "LapSealant" */
export enum LapSealant_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId'
}

/** aggregate max on columns */
export type LapSealant_Max_Fields = {
  __typename?: 'LapSealant_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type LapSealant_Min_Fields = {
  __typename?: 'LapSealant_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "LapSealant" */
export type LapSealant_Mutation_Response = {
  __typename?: 'LapSealant_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LapSealant>;
};

/** Ordering options when selecting data from "LapSealant". */
export type LapSealant_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
};

/** primary key columns input for table: LapSealant */
export type LapSealant_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "LapSealant" */
export enum LapSealant_Select_Column {
  /** column name */
  ComponentId = 'ComponentId'
}

/** input type for updating data in table "LapSealant" */
export type LapSealant_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type LapSealant_Stddev_Fields = {
  __typename?: 'LapSealant_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LapSealant_Stddev_Pop_Fields = {
  __typename?: 'LapSealant_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LapSealant_Stddev_Samp_Fields = {
  __typename?: 'LapSealant_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LapSealant_Sum_Fields = {
  __typename?: 'LapSealant_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
};

/** update columns of table "LapSealant" */
export enum LapSealant_Update_Column {
  /** column name */
  ComponentId = 'ComponentId'
}

/** aggregate var_pop on columns */
export type LapSealant_Var_Pop_Fields = {
  __typename?: 'LapSealant_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LapSealant_Var_Samp_Fields = {
  __typename?: 'LapSealant_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LapSealant_Variance_Fields = {
  __typename?: 'LapSealant_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "LapSecurementInfo" */
export type LapSecurementInfo = {
  __typename?: 'LapSecurementInfo';
  /** An object relationship */
  C_WeldConfiguration: C_WeldConfiguration;
  InsideWidth: Scalars['MeasurementValue'];
  LapWidthMin: Scalars['MeasurementValue'];
  OutsideWidth: Scalars['MeasurementValue'];
  Type: Scalars['String'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
  WeldConfigurationId: Scalars['Int'];
};

/** aggregated selection of "LapSecurementInfo" */
export type LapSecurementInfo_Aggregate = {
  __typename?: 'LapSecurementInfo_aggregate';
  aggregate?: Maybe<LapSecurementInfo_Aggregate_Fields>;
  nodes: Array<LapSecurementInfo>;
};

/** aggregate fields of "LapSecurementInfo" */
export type LapSecurementInfo_Aggregate_Fields = {
  __typename?: 'LapSecurementInfo_aggregate_fields';
  avg?: Maybe<LapSecurementInfo_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LapSecurementInfo_Max_Fields>;
  min?: Maybe<LapSecurementInfo_Min_Fields>;
  stddev?: Maybe<LapSecurementInfo_Stddev_Fields>;
  stddev_pop?: Maybe<LapSecurementInfo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LapSecurementInfo_Stddev_Samp_Fields>;
  sum?: Maybe<LapSecurementInfo_Sum_Fields>;
  var_pop?: Maybe<LapSecurementInfo_Var_Pop_Fields>;
  var_samp?: Maybe<LapSecurementInfo_Var_Samp_Fields>;
  variance?: Maybe<LapSecurementInfo_Variance_Fields>;
};


/** aggregate fields of "LapSecurementInfo" */
export type LapSecurementInfo_Aggregate_FieldsCountArgs = {
  column?: Maybe<LapSecurementInfo_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "LapSecurementInfo" */
export type LapSecurementInfo_Aggregate_Order_By = {
  avg?: Maybe<LapSecurementInfo_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<LapSecurementInfo_Max_Order_By>;
  min?: Maybe<LapSecurementInfo_Min_Order_By>;
  stddev?: Maybe<LapSecurementInfo_Stddev_Order_By>;
  stddev_pop?: Maybe<LapSecurementInfo_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<LapSecurementInfo_Stddev_Samp_Order_By>;
  sum?: Maybe<LapSecurementInfo_Sum_Order_By>;
  var_pop?: Maybe<LapSecurementInfo_Var_Pop_Order_By>;
  var_samp?: Maybe<LapSecurementInfo_Var_Samp_Order_By>;
  variance?: Maybe<LapSecurementInfo_Variance_Order_By>;
};

/** aggregate avg on columns */
export type LapSecurementInfo_Avg_Fields = {
  __typename?: 'LapSecurementInfo_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "LapSecurementInfo" */
export type LapSecurementInfo_Avg_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "LapSecurementInfo". All fields are combined with a logical 'AND'. */
export type LapSecurementInfo_Bool_Exp = {
  C_WeldConfiguration?: Maybe<C_WeldConfiguration_Bool_Exp>;
  InsideWidth?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  LapWidthMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  OutsideWidth?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Type?: Maybe<String_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  WeldConfigurationId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<LapSecurementInfo_Bool_Exp>>;
  _not?: Maybe<LapSecurementInfo_Bool_Exp>;
  _or?: Maybe<Array<LapSecurementInfo_Bool_Exp>>;
};

/** upsert condition type for table "LapSecurementInfo" */
export type LapSecurementInfo_If_Matched = {
  match_columns?: Array<LapSecurementInfo_Insert_Match_Column>;
  update_columns?: Array<LapSecurementInfo_Update_Column>;
  where?: Maybe<LapSecurementInfo_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "LapSecurementInfo" */
export type LapSecurementInfo_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "LapSecurementInfo" */
export type LapSecurementInfo_Insert_Input = {
  InsideWidth?: Maybe<Scalars['MeasurementValue']>;
  LapWidthMin?: Maybe<Scalars['MeasurementValue']>;
  OutsideWidth?: Maybe<Scalars['MeasurementValue']>;
  Type?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "LapSecurementInfo" */
export enum LapSecurementInfo_Insert_Match_Column {
  /** column name */
  InsideWidth = 'InsideWidth',
  /** column name */
  LapWidthMin = 'LapWidthMin',
  /** column name */
  OutsideWidth = 'OutsideWidth',
  /** column name */
  Type = 'Type',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeldConfigurationId = 'WeldConfigurationId'
}

/** aggregate max on columns */
export type LapSecurementInfo_Max_Fields = {
  __typename?: 'LapSecurementInfo_max_fields';
  InsideWidth?: Maybe<Scalars['MeasurementValue']>;
  LapWidthMin?: Maybe<Scalars['MeasurementValue']>;
  OutsideWidth?: Maybe<Scalars['MeasurementValue']>;
  Type?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "LapSecurementInfo" */
export type LapSecurementInfo_Max_Order_By = {
  InsideWidth?: Maybe<Order_By>;
  LapWidthMin?: Maybe<Order_By>;
  OutsideWidth?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type LapSecurementInfo_Min_Fields = {
  __typename?: 'LapSecurementInfo_min_fields';
  InsideWidth?: Maybe<Scalars['MeasurementValue']>;
  LapWidthMin?: Maybe<Scalars['MeasurementValue']>;
  OutsideWidth?: Maybe<Scalars['MeasurementValue']>;
  Type?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "LapSecurementInfo" */
export type LapSecurementInfo_Min_Order_By = {
  InsideWidth?: Maybe<Order_By>;
  LapWidthMin?: Maybe<Order_By>;
  OutsideWidth?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** response of any mutation on the table "LapSecurementInfo" */
export type LapSecurementInfo_Mutation_Response = {
  __typename?: 'LapSecurementInfo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LapSecurementInfo>;
};

/** Ordering options when selecting data from "LapSecurementInfo". */
export type LapSecurementInfo_Order_By = {
  C_WeldConfiguration?: Maybe<C_WeldConfiguration_Order_By>;
  InsideWidth?: Maybe<Order_By>;
  LapWidthMin?: Maybe<Order_By>;
  OutsideWidth?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** primary key columns input for table: LapSecurementInfo */
export type LapSecurementInfo_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "LapSecurementInfo" */
export enum LapSecurementInfo_Select_Column {
  /** column name */
  InsideWidth = 'InsideWidth',
  /** column name */
  LapWidthMin = 'LapWidthMin',
  /** column name */
  OutsideWidth = 'OutsideWidth',
  /** column name */
  Type = 'Type',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeldConfigurationId = 'WeldConfigurationId'
}

/** input type for updating data in table "LapSecurementInfo" */
export type LapSecurementInfo_Set_Input = {
  InsideWidth?: Maybe<Scalars['MeasurementValue']>;
  LapWidthMin?: Maybe<Scalars['MeasurementValue']>;
  OutsideWidth?: Maybe<Scalars['MeasurementValue']>;
  Type?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type LapSecurementInfo_Stddev_Fields = {
  __typename?: 'LapSecurementInfo_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "LapSecurementInfo" */
export type LapSecurementInfo_Stddev_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type LapSecurementInfo_Stddev_Pop_Fields = {
  __typename?: 'LapSecurementInfo_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "LapSecurementInfo" */
export type LapSecurementInfo_Stddev_Pop_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type LapSecurementInfo_Stddev_Samp_Fields = {
  __typename?: 'LapSecurementInfo_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "LapSecurementInfo" */
export type LapSecurementInfo_Stddev_Samp_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type LapSecurementInfo_Sum_Fields = {
  __typename?: 'LapSecurementInfo_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "LapSecurementInfo" */
export type LapSecurementInfo_Sum_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** update columns of table "LapSecurementInfo" */
export enum LapSecurementInfo_Update_Column {
  /** column name */
  InsideWidth = 'InsideWidth',
  /** column name */
  LapWidthMin = 'LapWidthMin',
  /** column name */
  OutsideWidth = 'OutsideWidth',
  /** column name */
  Type = 'Type',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeldConfigurationId = 'WeldConfigurationId'
}

/** aggregate var_pop on columns */
export type LapSecurementInfo_Var_Pop_Fields = {
  __typename?: 'LapSecurementInfo_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "LapSecurementInfo" */
export type LapSecurementInfo_Var_Pop_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type LapSecurementInfo_Var_Samp_Fields = {
  __typename?: 'LapSecurementInfo_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "LapSecurementInfo" */
export type LapSecurementInfo_Var_Samp_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type LapSecurementInfo_Variance_Fields = {
  __typename?: 'LapSecurementInfo_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "LapSecurementInfo" */
export type LapSecurementInfo_Variance_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** columns and relationships of "Layer" */
export type Layer = {
  __typename?: 'Layer';
  /** An object relationship */
  C_LayerType: C_LayerType;
  Comments: Scalars['ItemComment'];
  /** An object relationship */
  ComponentLayer?: Maybe<ComponentLayer>;
  /** An object relationship */
  ExistingRoofLayer?: Maybe<ExistingRoofLayer>;
  IsOptional: Scalars['Boolean'];
  LayerId: Scalars['Int'];
  LayerNumber: Scalars['Int'];
  LayerTypeId: Scalars['Int'];
  /** An array relationship */
  RoofAssemblyLayers: Array<RoofAssemblyLayers>;
  /** An aggregate relationship */
  RoofAssemblyLayers_aggregate: RoofAssemblyLayers_Aggregate;
  SecurementGroupId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  SecurementLayer?: Maybe<SecurementLayer>;
  /** An array relationship */
  SecurementLayers: Array<SecurementLayer>;
  /** An aggregate relationship */
  SecurementLayers_aggregate: SecurementLayer_Aggregate;
  /** An object relationship */
  SteelDeckLayer?: Maybe<SteelDeckLayer>;
  /** An object relationship */
  StructuralConcreteLayer?: Maybe<StructuralConcreteLayer>;
  /** An object relationship */
  StructureLayer?: Maybe<StructureLayer>;
  /** An array relationship */
  SubassemblyLayers: Array<SubassemblyLayers>;
  /** An aggregate relationship */
  SubassemblyLayers_aggregate: SubassemblyLayers_Aggregate;
  /** An array relationship */
  securementLayersByFromlayerid: Array<SecurementLayer>;
  /** An aggregate relationship */
  securementLayersByFromlayerid_aggregate: SecurementLayer_Aggregate;
};


/** columns and relationships of "Layer" */
export type LayerRoofAssemblyLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssemblyLayers_Order_By>>;
  where?: Maybe<RoofAssemblyLayers_Bool_Exp>;
};


/** columns and relationships of "Layer" */
export type LayerRoofAssemblyLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssemblyLayers_Order_By>>;
  where?: Maybe<RoofAssemblyLayers_Bool_Exp>;
};


/** columns and relationships of "Layer" */
export type LayerSecurementLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayer_Order_By>>;
  where?: Maybe<SecurementLayer_Bool_Exp>;
};


/** columns and relationships of "Layer" */
export type LayerSecurementLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayer_Order_By>>;
  where?: Maybe<SecurementLayer_Bool_Exp>;
};


/** columns and relationships of "Layer" */
export type LayerSubassemblyLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyLayers_Order_By>>;
  where?: Maybe<SubassemblyLayers_Bool_Exp>;
};


/** columns and relationships of "Layer" */
export type LayerSubassemblyLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyLayers_Order_By>>;
  where?: Maybe<SubassemblyLayers_Bool_Exp>;
};


/** columns and relationships of "Layer" */
export type LayerSecurementLayersByFromlayeridArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayer_Order_By>>;
  where?: Maybe<SecurementLayer_Bool_Exp>;
};


/** columns and relationships of "Layer" */
export type LayerSecurementLayersByFromlayerid_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayer_Order_By>>;
  where?: Maybe<SecurementLayer_Bool_Exp>;
};

/** columns and relationships of "LayerConfig" */
export type LayerConfig = {
  __typename?: 'LayerConfig';
  ConfigString: Scalars['String'];
  ConfigStringEndComma?: Maybe<Scalars['String']>;
  LayerConfigId: Scalars['Int'];
  /** An array relationship */
  NavAssemblies: Array<NavAssembly>;
  /** An aggregate relationship */
  NavAssemblies_aggregate: NavAssembly_Aggregate;
  /** An array relationship */
  RoofAssemblies: Array<RoofAssembly>;
  /** An aggregate relationship */
  RoofAssemblies_aggregate: RoofAssembly_Aggregate;
};


/** columns and relationships of "LayerConfig" */
export type LayerConfigNavAssembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssembly_Order_By>>;
  where?: Maybe<NavAssembly_Bool_Exp>;
};


/** columns and relationships of "LayerConfig" */
export type LayerConfigNavAssemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssembly_Order_By>>;
  where?: Maybe<NavAssembly_Bool_Exp>;
};


/** columns and relationships of "LayerConfig" */
export type LayerConfigRoofAssembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


/** columns and relationships of "LayerConfig" */
export type LayerConfigRoofAssemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};

/** aggregated selection of "LayerConfig" */
export type LayerConfig_Aggregate = {
  __typename?: 'LayerConfig_aggregate';
  aggregate?: Maybe<LayerConfig_Aggregate_Fields>;
  nodes: Array<LayerConfig>;
};

/** aggregate fields of "LayerConfig" */
export type LayerConfig_Aggregate_Fields = {
  __typename?: 'LayerConfig_aggregate_fields';
  avg?: Maybe<LayerConfig_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LayerConfig_Max_Fields>;
  min?: Maybe<LayerConfig_Min_Fields>;
  stddev?: Maybe<LayerConfig_Stddev_Fields>;
  stddev_pop?: Maybe<LayerConfig_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LayerConfig_Stddev_Samp_Fields>;
  sum?: Maybe<LayerConfig_Sum_Fields>;
  var_pop?: Maybe<LayerConfig_Var_Pop_Fields>;
  var_samp?: Maybe<LayerConfig_Var_Samp_Fields>;
  variance?: Maybe<LayerConfig_Variance_Fields>;
};


/** aggregate fields of "LayerConfig" */
export type LayerConfig_Aggregate_FieldsCountArgs = {
  column?: Maybe<LayerConfig_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LayerConfig_Avg_Fields = {
  __typename?: 'LayerConfig_avg_fields';
  LayerConfigId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "LayerConfig". All fields are combined with a logical 'AND'. */
export type LayerConfig_Bool_Exp = {
  ConfigString?: Maybe<String_Mssql_Comparison_Exp>;
  ConfigStringEndComma?: Maybe<String_Mssql_Comparison_Exp>;
  LayerConfigId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblies?: Maybe<NavAssembly_Bool_Exp>;
  RoofAssemblies?: Maybe<RoofAssembly_Bool_Exp>;
  _and?: Maybe<Array<LayerConfig_Bool_Exp>>;
  _not?: Maybe<LayerConfig_Bool_Exp>;
  _or?: Maybe<Array<LayerConfig_Bool_Exp>>;
};

/** upsert condition type for table "LayerConfig" */
export type LayerConfig_If_Matched = {
  match_columns?: Array<LayerConfig_Insert_Match_Column>;
  update_columns?: Array<LayerConfig_Update_Column>;
  where?: Maybe<LayerConfig_Bool_Exp>;
};

/** input type for inserting data into table "LayerConfig" */
export type LayerConfig_Insert_Input = {
  ConfigString?: Maybe<Scalars['String']>;
  ConfigStringEndComma?: Maybe<Scalars['String']>;
};

/** select match_columns of table "LayerConfig" */
export enum LayerConfig_Insert_Match_Column {
  /** column name */
  ConfigString = 'ConfigString',
  /** column name */
  ConfigStringEndComma = 'ConfigStringEndComma',
  /** column name */
  LayerConfigId = 'LayerConfigId'
}

/** aggregate max on columns */
export type LayerConfig_Max_Fields = {
  __typename?: 'LayerConfig_max_fields';
  ConfigString?: Maybe<Scalars['String']>;
  ConfigStringEndComma?: Maybe<Scalars['String']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type LayerConfig_Min_Fields = {
  __typename?: 'LayerConfig_min_fields';
  ConfigString?: Maybe<Scalars['String']>;
  ConfigStringEndComma?: Maybe<Scalars['String']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "LayerConfig" */
export type LayerConfig_Mutation_Response = {
  __typename?: 'LayerConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LayerConfig>;
};

/** Ordering options when selecting data from "LayerConfig". */
export type LayerConfig_Order_By = {
  ConfigString?: Maybe<Order_By>;
  ConfigStringEndComma?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  NavAssemblies_aggregate?: Maybe<NavAssembly_Aggregate_Order_By>;
  RoofAssemblies_aggregate?: Maybe<RoofAssembly_Aggregate_Order_By>;
};

/** primary key columns input for table: LayerConfig */
export type LayerConfig_Pk_Columns_Input = {
  LayerConfigId: Scalars['Int'];
};

/** select columns of table "LayerConfig" */
export enum LayerConfig_Select_Column {
  /** column name */
  ConfigString = 'ConfigString',
  /** column name */
  ConfigStringEndComma = 'ConfigStringEndComma',
  /** column name */
  LayerConfigId = 'LayerConfigId'
}

/** input type for updating data in table "LayerConfig" */
export type LayerConfig_Set_Input = {
  ConfigString?: Maybe<Scalars['String']>;
  ConfigStringEndComma?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type LayerConfig_Stddev_Fields = {
  __typename?: 'LayerConfig_stddev_fields';
  LayerConfigId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LayerConfig_Stddev_Pop_Fields = {
  __typename?: 'LayerConfig_stddev_pop_fields';
  LayerConfigId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LayerConfig_Stddev_Samp_Fields = {
  __typename?: 'LayerConfig_stddev_samp_fields';
  LayerConfigId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LayerConfig_Sum_Fields = {
  __typename?: 'LayerConfig_sum_fields';
  LayerConfigId?: Maybe<Scalars['Int']>;
};

/** update columns of table "LayerConfig" */
export enum LayerConfig_Update_Column {
  /** column name */
  ConfigString = 'ConfigString',
  /** column name */
  ConfigStringEndComma = 'ConfigStringEndComma'
}

/** aggregate var_pop on columns */
export type LayerConfig_Var_Pop_Fields = {
  __typename?: 'LayerConfig_var_pop_fields';
  LayerConfigId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LayerConfig_Var_Samp_Fields = {
  __typename?: 'LayerConfig_var_samp_fields';
  LayerConfigId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LayerConfig_Variance_Fields = {
  __typename?: 'LayerConfig_variance_fields';
  LayerConfigId?: Maybe<Scalars['Float']>;
};

export type LayerSearchCriteriaBase = {
  approvedUseId: Scalars['Int'];
  attributes: Array<Maybe<AttributeSearchCriteria>>;
  componentIds: Array<Maybe<Scalars['Int']>>;
  fromlayerNum: Scalars['Int'];
  layerNum: Scalars['Int'];
  layerTypeId: Scalars['Int'];
  manufacturerIds: Array<Maybe<Scalars['Int']>>;
  selfSecurement?: Maybe<SelfSecurementSearchCriteria>;
  subCategoryId: Scalars['Int'];
  tolayerNum: Scalars['Int'];
};

/** aggregated selection of "Layer" */
export type Layer_Aggregate = {
  __typename?: 'Layer_aggregate';
  aggregate?: Maybe<Layer_Aggregate_Fields>;
  nodes: Array<Layer>;
};

/** aggregate fields of "Layer" */
export type Layer_Aggregate_Fields = {
  __typename?: 'Layer_aggregate_fields';
  avg?: Maybe<Layer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Layer_Max_Fields>;
  min?: Maybe<Layer_Min_Fields>;
  stddev?: Maybe<Layer_Stddev_Fields>;
  stddev_pop?: Maybe<Layer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Layer_Stddev_Samp_Fields>;
  sum?: Maybe<Layer_Sum_Fields>;
  var_pop?: Maybe<Layer_Var_Pop_Fields>;
  var_samp?: Maybe<Layer_Var_Samp_Fields>;
  variance?: Maybe<Layer_Variance_Fields>;
};


/** aggregate fields of "Layer" */
export type Layer_Aggregate_FieldsCountArgs = {
  column?: Maybe<Layer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Layer" */
export type Layer_Aggregate_Order_By = {
  avg?: Maybe<Layer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Layer_Max_Order_By>;
  min?: Maybe<Layer_Min_Order_By>;
  stddev?: Maybe<Layer_Stddev_Order_By>;
  stddev_pop?: Maybe<Layer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Layer_Stddev_Samp_Order_By>;
  sum?: Maybe<Layer_Sum_Order_By>;
  var_pop?: Maybe<Layer_Var_Pop_Order_By>;
  var_samp?: Maybe<Layer_Var_Samp_Order_By>;
  variance?: Maybe<Layer_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Layer_Avg_Fields = {
  __typename?: 'Layer_avg_fields';
  LayerId?: Maybe<Scalars['Float']>;
  LayerNumber?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  SecurementGroupId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Layer" */
export type Layer_Avg_Order_By = {
  LayerId?: Maybe<Order_By>;
  LayerNumber?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  SecurementGroupId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Layer". All fields are combined with a logical 'AND'. */
export type Layer_Bool_Exp = {
  C_LayerType?: Maybe<C_LayerType_Bool_Exp>;
  Comments?: Maybe<ItemComment_Mssql_Comparison_Exp>;
  ComponentLayer?: Maybe<ComponentLayer_Bool_Exp>;
  ExistingRoofLayer?: Maybe<ExistingRoofLayer_Bool_Exp>;
  IsOptional?: Maybe<Boolean_Mssql_Comparison_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  LayerNumber?: Maybe<Int_Mssql_Comparison_Exp>;
  LayerTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  RoofAssemblyLayers?: Maybe<RoofAssemblyLayers_Bool_Exp>;
  SecurementGroupId?: Maybe<Int_Mssql_Comparison_Exp>;
  SecurementLayer?: Maybe<SecurementLayer_Bool_Exp>;
  SecurementLayers?: Maybe<SecurementLayer_Bool_Exp>;
  SteelDeckLayer?: Maybe<SteelDeckLayer_Bool_Exp>;
  StructuralConcreteLayer?: Maybe<StructuralConcreteLayer_Bool_Exp>;
  StructureLayer?: Maybe<StructureLayer_Bool_Exp>;
  SubassemblyLayers?: Maybe<SubassemblyLayers_Bool_Exp>;
  _and?: Maybe<Array<Layer_Bool_Exp>>;
  _not?: Maybe<Layer_Bool_Exp>;
  _or?: Maybe<Array<Layer_Bool_Exp>>;
  securementLayersByFromlayerid?: Maybe<SecurementLayer_Bool_Exp>;
};

/** upsert condition type for table "Layer" */
export type Layer_If_Matched = {
  match_columns?: Array<Layer_Insert_Match_Column>;
  update_columns?: Array<Layer_Update_Column>;
  where?: Maybe<Layer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Layer" */
export type Layer_Inc_Input = {
  LayerNumber?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  SecurementGroupId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Layer" */
export type Layer_Insert_Input = {
  Comments?: Maybe<Scalars['ItemComment']>;
  IsOptional?: Maybe<Scalars['Boolean']>;
  LayerNumber?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  SecurementGroupId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "Layer" */
export enum Layer_Insert_Match_Column {
  /** column name */
  Comments = 'Comments',
  /** column name */
  IsOptional = 'IsOptional',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerNumber = 'LayerNumber',
  /** column name */
  LayerTypeId = 'LayerTypeId',
  /** column name */
  SecurementGroupId = 'SecurementGroupId'
}

/** aggregate max on columns */
export type Layer_Max_Fields = {
  __typename?: 'Layer_max_fields';
  Comments?: Maybe<Scalars['ItemComment']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerNumber?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  SecurementGroupId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Layer" */
export type Layer_Max_Order_By = {
  Comments?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerNumber?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  SecurementGroupId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Layer_Min_Fields = {
  __typename?: 'Layer_min_fields';
  Comments?: Maybe<Scalars['ItemComment']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerNumber?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  SecurementGroupId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Layer" */
export type Layer_Min_Order_By = {
  Comments?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerNumber?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  SecurementGroupId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Layer" */
export type Layer_Mutation_Response = {
  __typename?: 'Layer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Layer>;
};

/** Ordering options when selecting data from "Layer". */
export type Layer_Order_By = {
  C_LayerType?: Maybe<C_LayerType_Order_By>;
  Comments?: Maybe<Order_By>;
  ComponentLayer?: Maybe<ComponentLayer_Order_By>;
  ExistingRoofLayer?: Maybe<ExistingRoofLayer_Order_By>;
  IsOptional?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerNumber?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  RoofAssemblyLayers_aggregate?: Maybe<RoofAssemblyLayers_Aggregate_Order_By>;
  SecurementGroupId?: Maybe<Order_By>;
  SecurementLayer?: Maybe<SecurementLayer_Order_By>;
  SecurementLayers_aggregate?: Maybe<SecurementLayer_Aggregate_Order_By>;
  SteelDeckLayer?: Maybe<SteelDeckLayer_Order_By>;
  StructuralConcreteLayer?: Maybe<StructuralConcreteLayer_Order_By>;
  StructureLayer?: Maybe<StructureLayer_Order_By>;
  SubassemblyLayers_aggregate?: Maybe<SubassemblyLayers_Aggregate_Order_By>;
  securementLayersByFromlayerid_aggregate?: Maybe<SecurementLayer_Aggregate_Order_By>;
};

/** primary key columns input for table: Layer */
export type Layer_Pk_Columns_Input = {
  LayerId: Scalars['Int'];
};

/** select columns of table "Layer" */
export enum Layer_Select_Column {
  /** column name */
  Comments = 'Comments',
  /** column name */
  IsOptional = 'IsOptional',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerNumber = 'LayerNumber',
  /** column name */
  LayerTypeId = 'LayerTypeId',
  /** column name */
  SecurementGroupId = 'SecurementGroupId'
}

/** input type for updating data in table "Layer" */
export type Layer_Set_Input = {
  Comments?: Maybe<Scalars['ItemComment']>;
  IsOptional?: Maybe<Scalars['Boolean']>;
  LayerNumber?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  SecurementGroupId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Layer_Stddev_Fields = {
  __typename?: 'Layer_stddev_fields';
  LayerId?: Maybe<Scalars['Float']>;
  LayerNumber?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  SecurementGroupId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Layer" */
export type Layer_Stddev_Order_By = {
  LayerId?: Maybe<Order_By>;
  LayerNumber?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  SecurementGroupId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Layer_Stddev_Pop_Fields = {
  __typename?: 'Layer_stddev_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
  LayerNumber?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  SecurementGroupId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Layer" */
export type Layer_Stddev_Pop_Order_By = {
  LayerId?: Maybe<Order_By>;
  LayerNumber?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  SecurementGroupId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Layer_Stddev_Samp_Fields = {
  __typename?: 'Layer_stddev_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
  LayerNumber?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  SecurementGroupId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Layer" */
export type Layer_Stddev_Samp_Order_By = {
  LayerId?: Maybe<Order_By>;
  LayerNumber?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  SecurementGroupId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Layer_Sum_Fields = {
  __typename?: 'Layer_sum_fields';
  LayerId?: Maybe<Scalars['Int']>;
  LayerNumber?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  SecurementGroupId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Layer" */
export type Layer_Sum_Order_By = {
  LayerId?: Maybe<Order_By>;
  LayerNumber?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  SecurementGroupId?: Maybe<Order_By>;
};

/** update columns of table "Layer" */
export enum Layer_Update_Column {
  /** column name */
  Comments = 'Comments',
  /** column name */
  IsOptional = 'IsOptional',
  /** column name */
  LayerNumber = 'LayerNumber',
  /** column name */
  LayerTypeId = 'LayerTypeId',
  /** column name */
  SecurementGroupId = 'SecurementGroupId'
}

/** aggregate var_pop on columns */
export type Layer_Var_Pop_Fields = {
  __typename?: 'Layer_var_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
  LayerNumber?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  SecurementGroupId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Layer" */
export type Layer_Var_Pop_Order_By = {
  LayerId?: Maybe<Order_By>;
  LayerNumber?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  SecurementGroupId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Layer_Var_Samp_Fields = {
  __typename?: 'Layer_var_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
  LayerNumber?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  SecurementGroupId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Layer" */
export type Layer_Var_Samp_Order_By = {
  LayerId?: Maybe<Order_By>;
  LayerNumber?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  SecurementGroupId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Layer_Variance_Fields = {
  __typename?: 'Layer_variance_fields';
  LayerId?: Maybe<Scalars['Float']>;
  LayerNumber?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  SecurementGroupId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Layer" */
export type Layer_Variance_Order_By = {
  LayerId?: Maybe<Order_By>;
  LayerNumber?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  SecurementGroupId?: Maybe<Order_By>;
};

/** columns and relationships of "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete = {
  __typename?: 'LightweightInsulatingConcrete';
  AdditiveId: Scalars['Int'];
  AggregateId: Scalars['Int'];
  /** An object relationship */
  C_Additive: C_Additive;
  /** An object relationship */
  C_Aggregate: C_Aggregate;
  /** An object relationship */
  C_CementAggregateRatio: C_CementAggregateRatio;
  CastDensityMin: Scalars['MeasurementValue'];
  CementAggregateRatioId: Scalars['Int'];
  ComponentId: Scalars['Int'];
  CompressiveStrengthMin: Scalars['MeasurementValue'];
  DryDensityMin: Scalars['MeasurementValue'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  ThicknessMin: Scalars['MeasurementValue'];
};

/** columns and relationships of "LightweightInsulatingConcreteSubassemblyUsage" */
export type LightweightInsulatingConcreteSubassemblyUsage = {
  __typename?: 'LightweightInsulatingConcreteSubassemblyUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "LightweightInsulatingConcreteSubassemblyUsage" */
export type LightweightInsulatingConcreteSubassemblyUsage_Aggregate = {
  __typename?: 'LightweightInsulatingConcreteSubassemblyUsage_aggregate';
  aggregate?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Aggregate_Fields>;
  nodes: Array<LightweightInsulatingConcreteSubassemblyUsage>;
};

/** aggregate fields of "LightweightInsulatingConcreteSubassemblyUsage" */
export type LightweightInsulatingConcreteSubassemblyUsage_Aggregate_Fields = {
  __typename?: 'LightweightInsulatingConcreteSubassemblyUsage_aggregate_fields';
  avg?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Max_Fields>;
  min?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Min_Fields>;
  stddev?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Stddev_Fields>;
  stddev_pop?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Stddev_Samp_Fields>;
  sum?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Sum_Fields>;
  var_pop?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Var_Pop_Fields>;
  var_samp?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Var_Samp_Fields>;
  variance?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Variance_Fields>;
};


/** aggregate fields of "LightweightInsulatingConcreteSubassemblyUsage" */
export type LightweightInsulatingConcreteSubassemblyUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LightweightInsulatingConcreteSubassemblyUsage_Avg_Fields = {
  __typename?: 'LightweightInsulatingConcreteSubassemblyUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "LightweightInsulatingConcreteSubassemblyUsage". All fields are combined with a logical 'AND'. */
export type LightweightInsulatingConcreteSubassemblyUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<LightweightInsulatingConcreteSubassemblyUsage_Bool_Exp>>;
  _not?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Bool_Exp>;
  _or?: Maybe<Array<LightweightInsulatingConcreteSubassemblyUsage_Bool_Exp>>;
};

/** upsert condition type for table "LightweightInsulatingConcreteSubassemblyUsage" */
export type LightweightInsulatingConcreteSubassemblyUsage_If_Matched = {
  match_columns?: Array<LightweightInsulatingConcreteSubassemblyUsage_Insert_Match_Column>;
  update_columns?: Array<LightweightInsulatingConcreteSubassemblyUsage_Update_Column>;
  where?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "LightweightInsulatingConcreteSubassemblyUsage" */
export type LightweightInsulatingConcreteSubassemblyUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "LightweightInsulatingConcreteSubassemblyUsage" */
export type LightweightInsulatingConcreteSubassemblyUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "LightweightInsulatingConcreteSubassemblyUsage" */
export enum LightweightInsulatingConcreteSubassemblyUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type LightweightInsulatingConcreteSubassemblyUsage_Max_Fields = {
  __typename?: 'LightweightInsulatingConcreteSubassemblyUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type LightweightInsulatingConcreteSubassemblyUsage_Min_Fields = {
  __typename?: 'LightweightInsulatingConcreteSubassemblyUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "LightweightInsulatingConcreteSubassemblyUsage" */
export type LightweightInsulatingConcreteSubassemblyUsage_Mutation_Response = {
  __typename?: 'LightweightInsulatingConcreteSubassemblyUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LightweightInsulatingConcreteSubassemblyUsage>;
};

/** Ordering options when selecting data from "LightweightInsulatingConcreteSubassemblyUsage". */
export type LightweightInsulatingConcreteSubassemblyUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: LightweightInsulatingConcreteSubassemblyUsage */
export type LightweightInsulatingConcreteSubassemblyUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "LightweightInsulatingConcreteSubassemblyUsage" */
export enum LightweightInsulatingConcreteSubassemblyUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "LightweightInsulatingConcreteSubassemblyUsage" */
export type LightweightInsulatingConcreteSubassemblyUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type LightweightInsulatingConcreteSubassemblyUsage_Stddev_Fields = {
  __typename?: 'LightweightInsulatingConcreteSubassemblyUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LightweightInsulatingConcreteSubassemblyUsage_Stddev_Pop_Fields = {
  __typename?: 'LightweightInsulatingConcreteSubassemblyUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LightweightInsulatingConcreteSubassemblyUsage_Stddev_Samp_Fields = {
  __typename?: 'LightweightInsulatingConcreteSubassemblyUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LightweightInsulatingConcreteSubassemblyUsage_Sum_Fields = {
  __typename?: 'LightweightInsulatingConcreteSubassemblyUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "LightweightInsulatingConcreteSubassemblyUsage" */
export enum LightweightInsulatingConcreteSubassemblyUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type LightweightInsulatingConcreteSubassemblyUsage_Var_Pop_Fields = {
  __typename?: 'LightweightInsulatingConcreteSubassemblyUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LightweightInsulatingConcreteSubassemblyUsage_Var_Samp_Fields = {
  __typename?: 'LightweightInsulatingConcreteSubassemblyUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LightweightInsulatingConcreteSubassemblyUsage_Variance_Fields = {
  __typename?: 'LightweightInsulatingConcreteSubassemblyUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "LightweightInsulatingConcreteUsage" */
export type LightweightInsulatingConcreteUsage = {
  __typename?: 'LightweightInsulatingConcreteUsage';
  CastDensityMin: Scalars['MeasurementValue'];
  DryDensityMin: Scalars['MeasurementValue'];
  MinCompressiveStrength: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "LightweightInsulatingConcreteUsage" */
export type LightweightInsulatingConcreteUsage_Aggregate = {
  __typename?: 'LightweightInsulatingConcreteUsage_aggregate';
  aggregate?: Maybe<LightweightInsulatingConcreteUsage_Aggregate_Fields>;
  nodes: Array<LightweightInsulatingConcreteUsage>;
};

/** aggregate fields of "LightweightInsulatingConcreteUsage" */
export type LightweightInsulatingConcreteUsage_Aggregate_Fields = {
  __typename?: 'LightweightInsulatingConcreteUsage_aggregate_fields';
  avg?: Maybe<LightweightInsulatingConcreteUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LightweightInsulatingConcreteUsage_Max_Fields>;
  min?: Maybe<LightweightInsulatingConcreteUsage_Min_Fields>;
  stddev?: Maybe<LightweightInsulatingConcreteUsage_Stddev_Fields>;
  stddev_pop?: Maybe<LightweightInsulatingConcreteUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LightweightInsulatingConcreteUsage_Stddev_Samp_Fields>;
  sum?: Maybe<LightweightInsulatingConcreteUsage_Sum_Fields>;
  var_pop?: Maybe<LightweightInsulatingConcreteUsage_Var_Pop_Fields>;
  var_samp?: Maybe<LightweightInsulatingConcreteUsage_Var_Samp_Fields>;
  variance?: Maybe<LightweightInsulatingConcreteUsage_Variance_Fields>;
};


/** aggregate fields of "LightweightInsulatingConcreteUsage" */
export type LightweightInsulatingConcreteUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<LightweightInsulatingConcreteUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LightweightInsulatingConcreteUsage_Avg_Fields = {
  __typename?: 'LightweightInsulatingConcreteUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "LightweightInsulatingConcreteUsage". All fields are combined with a logical 'AND'. */
export type LightweightInsulatingConcreteUsage_Bool_Exp = {
  CastDensityMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  DryDensityMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  MinCompressiveStrength?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<LightweightInsulatingConcreteUsage_Bool_Exp>>;
  _not?: Maybe<LightweightInsulatingConcreteUsage_Bool_Exp>;
  _or?: Maybe<Array<LightweightInsulatingConcreteUsage_Bool_Exp>>;
};

/** upsert condition type for table "LightweightInsulatingConcreteUsage" */
export type LightweightInsulatingConcreteUsage_If_Matched = {
  match_columns?: Array<LightweightInsulatingConcreteUsage_Insert_Match_Column>;
  update_columns?: Array<LightweightInsulatingConcreteUsage_Update_Column>;
  where?: Maybe<LightweightInsulatingConcreteUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "LightweightInsulatingConcreteUsage" */
export type LightweightInsulatingConcreteUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "LightweightInsulatingConcreteUsage" */
export type LightweightInsulatingConcreteUsage_Insert_Input = {
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  MinCompressiveStrength?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "LightweightInsulatingConcreteUsage" */
export enum LightweightInsulatingConcreteUsage_Insert_Match_Column {
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  MinCompressiveStrength = 'MinCompressiveStrength',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type LightweightInsulatingConcreteUsage_Max_Fields = {
  __typename?: 'LightweightInsulatingConcreteUsage_max_fields';
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  MinCompressiveStrength?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type LightweightInsulatingConcreteUsage_Min_Fields = {
  __typename?: 'LightweightInsulatingConcreteUsage_min_fields';
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  MinCompressiveStrength?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "LightweightInsulatingConcreteUsage" */
export type LightweightInsulatingConcreteUsage_Mutation_Response = {
  __typename?: 'LightweightInsulatingConcreteUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LightweightInsulatingConcreteUsage>;
};

/** Ordering options when selecting data from "LightweightInsulatingConcreteUsage". */
export type LightweightInsulatingConcreteUsage_Order_By = {
  CastDensityMin?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  MinCompressiveStrength?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: LightweightInsulatingConcreteUsage */
export type LightweightInsulatingConcreteUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "LightweightInsulatingConcreteUsage" */
export enum LightweightInsulatingConcreteUsage_Select_Column {
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  MinCompressiveStrength = 'MinCompressiveStrength',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "LightweightInsulatingConcreteUsage" */
export type LightweightInsulatingConcreteUsage_Set_Input = {
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  MinCompressiveStrength?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type LightweightInsulatingConcreteUsage_Stddev_Fields = {
  __typename?: 'LightweightInsulatingConcreteUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LightweightInsulatingConcreteUsage_Stddev_Pop_Fields = {
  __typename?: 'LightweightInsulatingConcreteUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LightweightInsulatingConcreteUsage_Stddev_Samp_Fields = {
  __typename?: 'LightweightInsulatingConcreteUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LightweightInsulatingConcreteUsage_Sum_Fields = {
  __typename?: 'LightweightInsulatingConcreteUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "LightweightInsulatingConcreteUsage" */
export enum LightweightInsulatingConcreteUsage_Update_Column {
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  MinCompressiveStrength = 'MinCompressiveStrength',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type LightweightInsulatingConcreteUsage_Var_Pop_Fields = {
  __typename?: 'LightweightInsulatingConcreteUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LightweightInsulatingConcreteUsage_Var_Samp_Fields = {
  __typename?: 'LightweightInsulatingConcreteUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LightweightInsulatingConcreteUsage_Variance_Fields = {
  __typename?: 'LightweightInsulatingConcreteUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Aggregate = {
  __typename?: 'LightweightInsulatingConcrete_aggregate';
  aggregate?: Maybe<LightweightInsulatingConcrete_Aggregate_Fields>;
  nodes: Array<LightweightInsulatingConcrete>;
};

/** aggregate fields of "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Aggregate_Fields = {
  __typename?: 'LightweightInsulatingConcrete_aggregate_fields';
  avg?: Maybe<LightweightInsulatingConcrete_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LightweightInsulatingConcrete_Max_Fields>;
  min?: Maybe<LightweightInsulatingConcrete_Min_Fields>;
  stddev?: Maybe<LightweightInsulatingConcrete_Stddev_Fields>;
  stddev_pop?: Maybe<LightweightInsulatingConcrete_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LightweightInsulatingConcrete_Stddev_Samp_Fields>;
  sum?: Maybe<LightweightInsulatingConcrete_Sum_Fields>;
  var_pop?: Maybe<LightweightInsulatingConcrete_Var_Pop_Fields>;
  var_samp?: Maybe<LightweightInsulatingConcrete_Var_Samp_Fields>;
  variance?: Maybe<LightweightInsulatingConcrete_Variance_Fields>;
};


/** aggregate fields of "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Aggregate_FieldsCountArgs = {
  column?: Maybe<LightweightInsulatingConcrete_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Aggregate_Order_By = {
  avg?: Maybe<LightweightInsulatingConcrete_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<LightweightInsulatingConcrete_Max_Order_By>;
  min?: Maybe<LightweightInsulatingConcrete_Min_Order_By>;
  stddev?: Maybe<LightweightInsulatingConcrete_Stddev_Order_By>;
  stddev_pop?: Maybe<LightweightInsulatingConcrete_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<LightweightInsulatingConcrete_Stddev_Samp_Order_By>;
  sum?: Maybe<LightweightInsulatingConcrete_Sum_Order_By>;
  var_pop?: Maybe<LightweightInsulatingConcrete_Var_Pop_Order_By>;
  var_samp?: Maybe<LightweightInsulatingConcrete_Var_Samp_Order_By>;
  variance?: Maybe<LightweightInsulatingConcrete_Variance_Order_By>;
};

/** aggregate avg on columns */
export type LightweightInsulatingConcrete_Avg_Fields = {
  __typename?: 'LightweightInsulatingConcrete_avg_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
  AggregateId?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Avg_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  AggregateId?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "LightweightInsulatingConcrete". All fields are combined with a logical 'AND'. */
export type LightweightInsulatingConcrete_Bool_Exp = {
  AdditiveId?: Maybe<Int_Mssql_Comparison_Exp>;
  AggregateId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_Additive?: Maybe<C_Additive_Bool_Exp>;
  C_Aggregate?: Maybe<C_Aggregate_Bool_Exp>;
  C_CementAggregateRatio?: Maybe<C_CementAggregateRatio_Bool_Exp>;
  CastDensityMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  CementAggregateRatioId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  CompressiveStrengthMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  DryDensityMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<LightweightInsulatingConcrete_Bool_Exp>>;
  _not?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
  _or?: Maybe<Array<LightweightInsulatingConcrete_Bool_Exp>>;
};

/** upsert condition type for table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_If_Matched = {
  match_columns?: Array<LightweightInsulatingConcrete_Insert_Match_Column>;
  update_columns?: Array<LightweightInsulatingConcrete_Update_Column>;
  where?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Inc_Input = {
  AdditiveId?: Maybe<Scalars['Int']>;
  AggregateId?: Maybe<Scalars['Int']>;
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Insert_Input = {
  AdditiveId?: Maybe<Scalars['Int']>;
  AggregateId?: Maybe<Scalars['Int']>;
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CompressiveStrengthMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "LightweightInsulatingConcrete" */
export enum LightweightInsulatingConcrete_Insert_Match_Column {
  /** column name */
  AdditiveId = 'AdditiveId',
  /** column name */
  AggregateId = 'AggregateId',
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  CementAggregateRatioId = 'CementAggregateRatioId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CompressiveStrengthMin = 'CompressiveStrengthMin',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate max on columns */
export type LightweightInsulatingConcrete_Max_Fields = {
  __typename?: 'LightweightInsulatingConcrete_max_fields';
  AdditiveId?: Maybe<Scalars['Int']>;
  AggregateId?: Maybe<Scalars['Int']>;
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CompressiveStrengthMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Max_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  AggregateId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CompressiveStrengthMin?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type LightweightInsulatingConcrete_Min_Fields = {
  __typename?: 'LightweightInsulatingConcrete_min_fields';
  AdditiveId?: Maybe<Scalars['Int']>;
  AggregateId?: Maybe<Scalars['Int']>;
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CompressiveStrengthMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Min_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  AggregateId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CompressiveStrengthMin?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** response of any mutation on the table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Mutation_Response = {
  __typename?: 'LightweightInsulatingConcrete_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LightweightInsulatingConcrete>;
};

/** Ordering options when selecting data from "LightweightInsulatingConcrete". */
export type LightweightInsulatingConcrete_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  AggregateId?: Maybe<Order_By>;
  C_Additive?: Maybe<C_Additive_Order_By>;
  C_Aggregate?: Maybe<C_Aggregate_Order_By>;
  C_CementAggregateRatio?: Maybe<C_CementAggregateRatio_Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CompressiveStrengthMin?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** primary key columns input for table: LightweightInsulatingConcrete */
export type LightweightInsulatingConcrete_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "LightweightInsulatingConcrete" */
export enum LightweightInsulatingConcrete_Select_Column {
  /** column name */
  AdditiveId = 'AdditiveId',
  /** column name */
  AggregateId = 'AggregateId',
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  CementAggregateRatioId = 'CementAggregateRatioId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CompressiveStrengthMin = 'CompressiveStrengthMin',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** input type for updating data in table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Set_Input = {
  AdditiveId?: Maybe<Scalars['Int']>;
  AggregateId?: Maybe<Scalars['Int']>;
  CastDensityMin?: Maybe<Scalars['MeasurementValue']>;
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CompressiveStrengthMin?: Maybe<Scalars['MeasurementValue']>;
  DryDensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type LightweightInsulatingConcrete_Stddev_Fields = {
  __typename?: 'LightweightInsulatingConcrete_stddev_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
  AggregateId?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Stddev_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  AggregateId?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type LightweightInsulatingConcrete_Stddev_Pop_Fields = {
  __typename?: 'LightweightInsulatingConcrete_stddev_pop_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
  AggregateId?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Stddev_Pop_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  AggregateId?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type LightweightInsulatingConcrete_Stddev_Samp_Fields = {
  __typename?: 'LightweightInsulatingConcrete_stddev_samp_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
  AggregateId?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Stddev_Samp_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  AggregateId?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type LightweightInsulatingConcrete_Sum_Fields = {
  __typename?: 'LightweightInsulatingConcrete_sum_fields';
  AdditiveId?: Maybe<Scalars['Int']>;
  AggregateId?: Maybe<Scalars['Int']>;
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Sum_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  AggregateId?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** update columns of table "LightweightInsulatingConcrete" */
export enum LightweightInsulatingConcrete_Update_Column {
  /** column name */
  AdditiveId = 'AdditiveId',
  /** column name */
  AggregateId = 'AggregateId',
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  CementAggregateRatioId = 'CementAggregateRatioId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CompressiveStrengthMin = 'CompressiveStrengthMin',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate var_pop on columns */
export type LightweightInsulatingConcrete_Var_Pop_Fields = {
  __typename?: 'LightweightInsulatingConcrete_var_pop_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
  AggregateId?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Var_Pop_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  AggregateId?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type LightweightInsulatingConcrete_Var_Samp_Fields = {
  __typename?: 'LightweightInsulatingConcrete_var_samp_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
  AggregateId?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Var_Samp_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  AggregateId?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type LightweightInsulatingConcrete_Variance_Fields = {
  __typename?: 'LightweightInsulatingConcrete_variance_fields';
  AdditiveId?: Maybe<Scalars['Float']>;
  AggregateId?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "LightweightInsulatingConcrete" */
export type LightweightInsulatingConcrete_Variance_Order_By = {
  AdditiveId?: Maybe<Order_By>;
  AggregateId?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "LinerPanel" */
export type LinerPanel = {
  __typename?: 'LinerPanel';
  /** An object relationship */
  C_LinerPanelMaterial: C_LinerPanelMaterial;
  ComponentId: Scalars['Int'];
  LinerPanelMaterialId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
};

/** columns and relationships of "LinerPanelUsage" */
export type LinerPanelUsage = {
  __typename?: 'LinerPanelUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "LinerPanelUsage" */
export type LinerPanelUsage_Aggregate = {
  __typename?: 'LinerPanelUsage_aggregate';
  aggregate?: Maybe<LinerPanelUsage_Aggregate_Fields>;
  nodes: Array<LinerPanelUsage>;
};

/** aggregate fields of "LinerPanelUsage" */
export type LinerPanelUsage_Aggregate_Fields = {
  __typename?: 'LinerPanelUsage_aggregate_fields';
  avg?: Maybe<LinerPanelUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LinerPanelUsage_Max_Fields>;
  min?: Maybe<LinerPanelUsage_Min_Fields>;
  stddev?: Maybe<LinerPanelUsage_Stddev_Fields>;
  stddev_pop?: Maybe<LinerPanelUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LinerPanelUsage_Stddev_Samp_Fields>;
  sum?: Maybe<LinerPanelUsage_Sum_Fields>;
  var_pop?: Maybe<LinerPanelUsage_Var_Pop_Fields>;
  var_samp?: Maybe<LinerPanelUsage_Var_Samp_Fields>;
  variance?: Maybe<LinerPanelUsage_Variance_Fields>;
};


/** aggregate fields of "LinerPanelUsage" */
export type LinerPanelUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<LinerPanelUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LinerPanelUsage_Avg_Fields = {
  __typename?: 'LinerPanelUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "LinerPanelUsage". All fields are combined with a logical 'AND'. */
export type LinerPanelUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<LinerPanelUsage_Bool_Exp>>;
  _not?: Maybe<LinerPanelUsage_Bool_Exp>;
  _or?: Maybe<Array<LinerPanelUsage_Bool_Exp>>;
};

/** upsert condition type for table "LinerPanelUsage" */
export type LinerPanelUsage_If_Matched = {
  match_columns?: Array<LinerPanelUsage_Insert_Match_Column>;
  update_columns?: Array<LinerPanelUsage_Update_Column>;
  where?: Maybe<LinerPanelUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "LinerPanelUsage" */
export type LinerPanelUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "LinerPanelUsage" */
export type LinerPanelUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "LinerPanelUsage" */
export enum LinerPanelUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type LinerPanelUsage_Max_Fields = {
  __typename?: 'LinerPanelUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type LinerPanelUsage_Min_Fields = {
  __typename?: 'LinerPanelUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "LinerPanelUsage" */
export type LinerPanelUsage_Mutation_Response = {
  __typename?: 'LinerPanelUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LinerPanelUsage>;
};

/** Ordering options when selecting data from "LinerPanelUsage". */
export type LinerPanelUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: LinerPanelUsage */
export type LinerPanelUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "LinerPanelUsage" */
export enum LinerPanelUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "LinerPanelUsage" */
export type LinerPanelUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type LinerPanelUsage_Stddev_Fields = {
  __typename?: 'LinerPanelUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LinerPanelUsage_Stddev_Pop_Fields = {
  __typename?: 'LinerPanelUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LinerPanelUsage_Stddev_Samp_Fields = {
  __typename?: 'LinerPanelUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LinerPanelUsage_Sum_Fields = {
  __typename?: 'LinerPanelUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "LinerPanelUsage" */
export enum LinerPanelUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type LinerPanelUsage_Var_Pop_Fields = {
  __typename?: 'LinerPanelUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LinerPanelUsage_Var_Samp_Fields = {
  __typename?: 'LinerPanelUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LinerPanelUsage_Variance_Fields = {
  __typename?: 'LinerPanelUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "LinerPanel" */
export type LinerPanel_Aggregate = {
  __typename?: 'LinerPanel_aggregate';
  aggregate?: Maybe<LinerPanel_Aggregate_Fields>;
  nodes: Array<LinerPanel>;
};

/** aggregate fields of "LinerPanel" */
export type LinerPanel_Aggregate_Fields = {
  __typename?: 'LinerPanel_aggregate_fields';
  avg?: Maybe<LinerPanel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LinerPanel_Max_Fields>;
  min?: Maybe<LinerPanel_Min_Fields>;
  stddev?: Maybe<LinerPanel_Stddev_Fields>;
  stddev_pop?: Maybe<LinerPanel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LinerPanel_Stddev_Samp_Fields>;
  sum?: Maybe<LinerPanel_Sum_Fields>;
  var_pop?: Maybe<LinerPanel_Var_Pop_Fields>;
  var_samp?: Maybe<LinerPanel_Var_Samp_Fields>;
  variance?: Maybe<LinerPanel_Variance_Fields>;
};


/** aggregate fields of "LinerPanel" */
export type LinerPanel_Aggregate_FieldsCountArgs = {
  column?: Maybe<LinerPanel_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "LinerPanel" */
export type LinerPanel_Aggregate_Order_By = {
  avg?: Maybe<LinerPanel_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<LinerPanel_Max_Order_By>;
  min?: Maybe<LinerPanel_Min_Order_By>;
  stddev?: Maybe<LinerPanel_Stddev_Order_By>;
  stddev_pop?: Maybe<LinerPanel_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<LinerPanel_Stddev_Samp_Order_By>;
  sum?: Maybe<LinerPanel_Sum_Order_By>;
  var_pop?: Maybe<LinerPanel_Var_Pop_Order_By>;
  var_samp?: Maybe<LinerPanel_Var_Samp_Order_By>;
  variance?: Maybe<LinerPanel_Variance_Order_By>;
};

/** aggregate avg on columns */
export type LinerPanel_Avg_Fields = {
  __typename?: 'LinerPanel_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "LinerPanel" */
export type LinerPanel_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "LinerPanel". All fields are combined with a logical 'AND'. */
export type LinerPanel_Bool_Exp = {
  C_LinerPanelMaterial?: Maybe<C_LinerPanelMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  LinerPanelMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  _and?: Maybe<Array<LinerPanel_Bool_Exp>>;
  _not?: Maybe<LinerPanel_Bool_Exp>;
  _or?: Maybe<Array<LinerPanel_Bool_Exp>>;
};

/** upsert condition type for table "LinerPanel" */
export type LinerPanel_If_Matched = {
  match_columns?: Array<LinerPanel_Insert_Match_Column>;
  update_columns?: Array<LinerPanel_Update_Column>;
  where?: Maybe<LinerPanel_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "LinerPanel" */
export type LinerPanel_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "LinerPanel" */
export type LinerPanel_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "LinerPanel" */
export enum LinerPanel_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LinerPanelMaterialId = 'LinerPanelMaterialId'
}

/** aggregate max on columns */
export type LinerPanel_Max_Fields = {
  __typename?: 'LinerPanel_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "LinerPanel" */
export type LinerPanel_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type LinerPanel_Min_Fields = {
  __typename?: 'LinerPanel_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "LinerPanel" */
export type LinerPanel_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** response of any mutation on the table "LinerPanel" */
export type LinerPanel_Mutation_Response = {
  __typename?: 'LinerPanel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LinerPanel>;
};

/** Ordering options when selecting data from "LinerPanel". */
export type LinerPanel_Order_By = {
  C_LinerPanelMaterial?: Maybe<C_LinerPanelMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
};

/** primary key columns input for table: LinerPanel */
export type LinerPanel_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "LinerPanel" */
export enum LinerPanel_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LinerPanelMaterialId = 'LinerPanelMaterialId'
}

/** input type for updating data in table "LinerPanel" */
export type LinerPanel_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type LinerPanel_Stddev_Fields = {
  __typename?: 'LinerPanel_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "LinerPanel" */
export type LinerPanel_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type LinerPanel_Stddev_Pop_Fields = {
  __typename?: 'LinerPanel_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "LinerPanel" */
export type LinerPanel_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type LinerPanel_Stddev_Samp_Fields = {
  __typename?: 'LinerPanel_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "LinerPanel" */
export type LinerPanel_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type LinerPanel_Sum_Fields = {
  __typename?: 'LinerPanel_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "LinerPanel" */
export type LinerPanel_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** update columns of table "LinerPanel" */
export enum LinerPanel_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LinerPanelMaterialId = 'LinerPanelMaterialId'
}

/** aggregate var_pop on columns */
export type LinerPanel_Var_Pop_Fields = {
  __typename?: 'LinerPanel_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "LinerPanel" */
export type LinerPanel_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type LinerPanel_Var_Samp_Fields = {
  __typename?: 'LinerPanel_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "LinerPanel" */
export type LinerPanel_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type LinerPanel_Variance_Fields = {
  __typename?: 'LinerPanel_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "LinerPanel" */
export type LinerPanel_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "LiquidAppliedSubassemblyUsage" */
export type LiquidAppliedSubassemblyUsage = {
  __typename?: 'LiquidAppliedSubassemblyUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "LiquidAppliedSubassemblyUsage" */
export type LiquidAppliedSubassemblyUsage_Aggregate = {
  __typename?: 'LiquidAppliedSubassemblyUsage_aggregate';
  aggregate?: Maybe<LiquidAppliedSubassemblyUsage_Aggregate_Fields>;
  nodes: Array<LiquidAppliedSubassemblyUsage>;
};

/** aggregate fields of "LiquidAppliedSubassemblyUsage" */
export type LiquidAppliedSubassemblyUsage_Aggregate_Fields = {
  __typename?: 'LiquidAppliedSubassemblyUsage_aggregate_fields';
  avg?: Maybe<LiquidAppliedSubassemblyUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LiquidAppliedSubassemblyUsage_Max_Fields>;
  min?: Maybe<LiquidAppliedSubassemblyUsage_Min_Fields>;
  stddev?: Maybe<LiquidAppliedSubassemblyUsage_Stddev_Fields>;
  stddev_pop?: Maybe<LiquidAppliedSubassemblyUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LiquidAppliedSubassemblyUsage_Stddev_Samp_Fields>;
  sum?: Maybe<LiquidAppliedSubassemblyUsage_Sum_Fields>;
  var_pop?: Maybe<LiquidAppliedSubassemblyUsage_Var_Pop_Fields>;
  var_samp?: Maybe<LiquidAppliedSubassemblyUsage_Var_Samp_Fields>;
  variance?: Maybe<LiquidAppliedSubassemblyUsage_Variance_Fields>;
};


/** aggregate fields of "LiquidAppliedSubassemblyUsage" */
export type LiquidAppliedSubassemblyUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<LiquidAppliedSubassemblyUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LiquidAppliedSubassemblyUsage_Avg_Fields = {
  __typename?: 'LiquidAppliedSubassemblyUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "LiquidAppliedSubassemblyUsage". All fields are combined with a logical 'AND'. */
export type LiquidAppliedSubassemblyUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<LiquidAppliedSubassemblyUsage_Bool_Exp>>;
  _not?: Maybe<LiquidAppliedSubassemblyUsage_Bool_Exp>;
  _or?: Maybe<Array<LiquidAppliedSubassemblyUsage_Bool_Exp>>;
};

/** upsert condition type for table "LiquidAppliedSubassemblyUsage" */
export type LiquidAppliedSubassemblyUsage_If_Matched = {
  match_columns?: Array<LiquidAppliedSubassemblyUsage_Insert_Match_Column>;
  update_columns?: Array<LiquidAppliedSubassemblyUsage_Update_Column>;
  where?: Maybe<LiquidAppliedSubassemblyUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "LiquidAppliedSubassemblyUsage" */
export type LiquidAppliedSubassemblyUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "LiquidAppliedSubassemblyUsage" */
export type LiquidAppliedSubassemblyUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "LiquidAppliedSubassemblyUsage" */
export enum LiquidAppliedSubassemblyUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type LiquidAppliedSubassemblyUsage_Max_Fields = {
  __typename?: 'LiquidAppliedSubassemblyUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type LiquidAppliedSubassemblyUsage_Min_Fields = {
  __typename?: 'LiquidAppliedSubassemblyUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "LiquidAppliedSubassemblyUsage" */
export type LiquidAppliedSubassemblyUsage_Mutation_Response = {
  __typename?: 'LiquidAppliedSubassemblyUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LiquidAppliedSubassemblyUsage>;
};

/** Ordering options when selecting data from "LiquidAppliedSubassemblyUsage". */
export type LiquidAppliedSubassemblyUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: LiquidAppliedSubassemblyUsage */
export type LiquidAppliedSubassemblyUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "LiquidAppliedSubassemblyUsage" */
export enum LiquidAppliedSubassemblyUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "LiquidAppliedSubassemblyUsage" */
export type LiquidAppliedSubassemblyUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type LiquidAppliedSubassemblyUsage_Stddev_Fields = {
  __typename?: 'LiquidAppliedSubassemblyUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LiquidAppliedSubassemblyUsage_Stddev_Pop_Fields = {
  __typename?: 'LiquidAppliedSubassemblyUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LiquidAppliedSubassemblyUsage_Stddev_Samp_Fields = {
  __typename?: 'LiquidAppliedSubassemblyUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LiquidAppliedSubassemblyUsage_Sum_Fields = {
  __typename?: 'LiquidAppliedSubassemblyUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "LiquidAppliedSubassemblyUsage" */
export enum LiquidAppliedSubassemblyUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type LiquidAppliedSubassemblyUsage_Var_Pop_Fields = {
  __typename?: 'LiquidAppliedSubassemblyUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LiquidAppliedSubassemblyUsage_Var_Samp_Fields = {
  __typename?: 'LiquidAppliedSubassemblyUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LiquidAppliedSubassemblyUsage_Variance_Fields = {
  __typename?: 'LiquidAppliedSubassemblyUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "LiquidAppliedUsage" */
export type LiquidAppliedUsage = {
  __typename?: 'LiquidAppliedUsage';
  DryFilmThickness: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "LiquidAppliedUsage" */
export type LiquidAppliedUsage_Aggregate = {
  __typename?: 'LiquidAppliedUsage_aggregate';
  aggregate?: Maybe<LiquidAppliedUsage_Aggregate_Fields>;
  nodes: Array<LiquidAppliedUsage>;
};

/** aggregate fields of "LiquidAppliedUsage" */
export type LiquidAppliedUsage_Aggregate_Fields = {
  __typename?: 'LiquidAppliedUsage_aggregate_fields';
  avg?: Maybe<LiquidAppliedUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LiquidAppliedUsage_Max_Fields>;
  min?: Maybe<LiquidAppliedUsage_Min_Fields>;
  stddev?: Maybe<LiquidAppliedUsage_Stddev_Fields>;
  stddev_pop?: Maybe<LiquidAppliedUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LiquidAppliedUsage_Stddev_Samp_Fields>;
  sum?: Maybe<LiquidAppliedUsage_Sum_Fields>;
  var_pop?: Maybe<LiquidAppliedUsage_Var_Pop_Fields>;
  var_samp?: Maybe<LiquidAppliedUsage_Var_Samp_Fields>;
  variance?: Maybe<LiquidAppliedUsage_Variance_Fields>;
};


/** aggregate fields of "LiquidAppliedUsage" */
export type LiquidAppliedUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<LiquidAppliedUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LiquidAppliedUsage_Avg_Fields = {
  __typename?: 'LiquidAppliedUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "LiquidAppliedUsage". All fields are combined with a logical 'AND'. */
export type LiquidAppliedUsage_Bool_Exp = {
  DryFilmThickness?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<LiquidAppliedUsage_Bool_Exp>>;
  _not?: Maybe<LiquidAppliedUsage_Bool_Exp>;
  _or?: Maybe<Array<LiquidAppliedUsage_Bool_Exp>>;
};

/** upsert condition type for table "LiquidAppliedUsage" */
export type LiquidAppliedUsage_If_Matched = {
  match_columns?: Array<LiquidAppliedUsage_Insert_Match_Column>;
  update_columns?: Array<LiquidAppliedUsage_Update_Column>;
  where?: Maybe<LiquidAppliedUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "LiquidAppliedUsage" */
export type LiquidAppliedUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "LiquidAppliedUsage" */
export type LiquidAppliedUsage_Insert_Input = {
  DryFilmThickness?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "LiquidAppliedUsage" */
export enum LiquidAppliedUsage_Insert_Match_Column {
  /** column name */
  DryFilmThickness = 'DryFilmThickness',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type LiquidAppliedUsage_Max_Fields = {
  __typename?: 'LiquidAppliedUsage_max_fields';
  DryFilmThickness?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type LiquidAppliedUsage_Min_Fields = {
  __typename?: 'LiquidAppliedUsage_min_fields';
  DryFilmThickness?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "LiquidAppliedUsage" */
export type LiquidAppliedUsage_Mutation_Response = {
  __typename?: 'LiquidAppliedUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LiquidAppliedUsage>;
};

/** Ordering options when selecting data from "LiquidAppliedUsage". */
export type LiquidAppliedUsage_Order_By = {
  DryFilmThickness?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: LiquidAppliedUsage */
export type LiquidAppliedUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "LiquidAppliedUsage" */
export enum LiquidAppliedUsage_Select_Column {
  /** column name */
  DryFilmThickness = 'DryFilmThickness',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "LiquidAppliedUsage" */
export type LiquidAppliedUsage_Set_Input = {
  DryFilmThickness?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type LiquidAppliedUsage_Stddev_Fields = {
  __typename?: 'LiquidAppliedUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LiquidAppliedUsage_Stddev_Pop_Fields = {
  __typename?: 'LiquidAppliedUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LiquidAppliedUsage_Stddev_Samp_Fields = {
  __typename?: 'LiquidAppliedUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LiquidAppliedUsage_Sum_Fields = {
  __typename?: 'LiquidAppliedUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "LiquidAppliedUsage" */
export enum LiquidAppliedUsage_Update_Column {
  /** column name */
  DryFilmThickness = 'DryFilmThickness',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type LiquidAppliedUsage_Var_Pop_Fields = {
  __typename?: 'LiquidAppliedUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LiquidAppliedUsage_Var_Samp_Fields = {
  __typename?: 'LiquidAppliedUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LiquidAppliedUsage_Variance_Fields = {
  __typename?: 'LiquidAppliedUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic = {
  __typename?: 'Load_SearchLayerUsage_DeckFiberReinforcedPlastic';
  NavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Aggregate = {
  __typename?: 'Load_SearchLayerUsage_DeckFiberReinforcedPlastic_aggregate';
  aggregate?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Aggregate_Fields>;
  nodes: Array<Load_SearchLayerUsage_DeckFiberReinforcedPlastic>;
};

/** aggregate fields of "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Aggregate_Fields = {
  __typename?: 'Load_SearchLayerUsage_DeckFiberReinforcedPlastic_aggregate_fields';
  avg?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Max_Fields>;
  min?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Min_Fields>;
  stddev?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Stddev_Fields>;
  stddev_pop?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Stddev_Samp_Fields>;
  sum?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Sum_Fields>;
  var_pop?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Var_Pop_Fields>;
  var_samp?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Var_Samp_Fields>;
  variance?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Variance_Fields>;
};


/** aggregate fields of "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Aggregate_FieldsCountArgs = {
  column?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Avg_Fields = {
  __typename?: 'Load_SearchLayerUsage_DeckFiberReinforcedPlastic_avg_fields';
  NavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Load_SearchLayerUsage_DeckFiberReinforcedPlastic". All fields are combined with a logical 'AND'. */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Bool_Exp = {
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Bool_Exp>>;
  _not?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Bool_Exp>;
  _or?: Maybe<Array<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Bool_Exp>>;
};

/** upsert condition type for table "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_If_Matched = {
  match_columns?: Array<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Insert_Match_Column>;
  update_columns?: Array<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Update_Column>;
  where?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Inc_Input = {
  NavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Insert_Input = {
  NavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
export enum Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Insert_Match_Column {
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Max_Fields = {
  __typename?: 'Load_SearchLayerUsage_DeckFiberReinforcedPlastic_max_fields';
  NavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Min_Fields = {
  __typename?: 'Load_SearchLayerUsage_DeckFiberReinforcedPlastic_min_fields';
  NavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Mutation_Response = {
  __typename?: 'Load_SearchLayerUsage_DeckFiberReinforcedPlastic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Load_SearchLayerUsage_DeckFiberReinforcedPlastic>;
};

/** Ordering options when selecting data from "Load_SearchLayerUsage_DeckFiberReinforcedPlastic". */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Order_By = {
  NavLayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
export enum Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Select_Column {
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Set_Input = {
  NavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Stddev_Fields = {
  __typename?: 'Load_SearchLayerUsage_DeckFiberReinforcedPlastic_stddev_fields';
  NavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Stddev_Pop_Fields = {
  __typename?: 'Load_SearchLayerUsage_DeckFiberReinforcedPlastic_stddev_pop_fields';
  NavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Stddev_Samp_Fields = {
  __typename?: 'Load_SearchLayerUsage_DeckFiberReinforcedPlastic_stddev_samp_fields';
  NavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Sum_Fields = {
  __typename?: 'Load_SearchLayerUsage_DeckFiberReinforcedPlastic_sum_fields';
  NavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
export enum Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Update_Column {
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Var_Pop_Fields = {
  __typename?: 'Load_SearchLayerUsage_DeckFiberReinforcedPlastic_var_pop_fields';
  NavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Var_Samp_Fields = {
  __typename?: 'Load_SearchLayerUsage_DeckFiberReinforcedPlastic_var_samp_fields';
  NavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Variance_Fields = {
  __typename?: 'Load_SearchLayerUsage_DeckFiberReinforcedPlastic_variance_fields';
  NavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "MD_ApprovedUse" */
export type Md_ApprovedUse = {
  __typename?: 'MD_ApprovedUse';
  ApprovedUseId: Scalars['Int'];
  /** An object relationship */
  C_ApprovedUse: C_ApprovedUse;
  DisplayName: Scalars['String'];
  EntityClass: Scalars['String'];
  FactoryClass: Scalars['String'];
  /** An array relationship */
  MD_ApprovedUseAttributes: Array<Md_ApprovedUseAttributes>;
  /** An aggregate relationship */
  MD_ApprovedUseAttributes_aggregate: Md_ApprovedUseAttributes_Aggregate;
  /** An object relationship */
  MD_Catalog: Md_Catalog;
  MetadataCatalogId: Scalars['Int'];
  TableName: Scalars['String'];
};


/** columns and relationships of "MD_ApprovedUse" */
export type Md_ApprovedUseMd_ApprovedUseAttributesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ApprovedUseAttributes_Order_By>>;
  where?: Maybe<Md_ApprovedUseAttributes_Bool_Exp>;
};


/** columns and relationships of "MD_ApprovedUse" */
export type Md_ApprovedUseMd_ApprovedUseAttributes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ApprovedUseAttributes_Order_By>>;
  where?: Maybe<Md_ApprovedUseAttributes_Bool_Exp>;
};

/** columns and relationships of "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes = {
  __typename?: 'MD_ApprovedUseAttributes';
  ApprovedUseId: Scalars['Int'];
  AttributeId: Scalars['Int'];
  /** An object relationship */
  MD_ApprovedUse: Md_ApprovedUse;
  /** An object relationship */
  MD_AttributeBase: Md_AttributeBase;
  OrderNum: Scalars['Int'];
};

/** aggregated selection of "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Aggregate = {
  __typename?: 'MD_ApprovedUseAttributes_aggregate';
  aggregate?: Maybe<Md_ApprovedUseAttributes_Aggregate_Fields>;
  nodes: Array<Md_ApprovedUseAttributes>;
};

/** aggregate fields of "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Aggregate_Fields = {
  __typename?: 'MD_ApprovedUseAttributes_aggregate_fields';
  avg?: Maybe<Md_ApprovedUseAttributes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_ApprovedUseAttributes_Max_Fields>;
  min?: Maybe<Md_ApprovedUseAttributes_Min_Fields>;
  stddev?: Maybe<Md_ApprovedUseAttributes_Stddev_Fields>;
  stddev_pop?: Maybe<Md_ApprovedUseAttributes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_ApprovedUseAttributes_Stddev_Samp_Fields>;
  sum?: Maybe<Md_ApprovedUseAttributes_Sum_Fields>;
  var_pop?: Maybe<Md_ApprovedUseAttributes_Var_Pop_Fields>;
  var_samp?: Maybe<Md_ApprovedUseAttributes_Var_Samp_Fields>;
  variance?: Maybe<Md_ApprovedUseAttributes_Variance_Fields>;
};


/** aggregate fields of "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_ApprovedUseAttributes_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Aggregate_Order_By = {
  avg?: Maybe<Md_ApprovedUseAttributes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Md_ApprovedUseAttributes_Max_Order_By>;
  min?: Maybe<Md_ApprovedUseAttributes_Min_Order_By>;
  stddev?: Maybe<Md_ApprovedUseAttributes_Stddev_Order_By>;
  stddev_pop?: Maybe<Md_ApprovedUseAttributes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Md_ApprovedUseAttributes_Stddev_Samp_Order_By>;
  sum?: Maybe<Md_ApprovedUseAttributes_Sum_Order_By>;
  var_pop?: Maybe<Md_ApprovedUseAttributes_Var_Pop_Order_By>;
  var_samp?: Maybe<Md_ApprovedUseAttributes_Var_Samp_Order_By>;
  variance?: Maybe<Md_ApprovedUseAttributes_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Md_ApprovedUseAttributes_Avg_Fields = {
  __typename?: 'MD_ApprovedUseAttributes_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AttributeId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Avg_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "MD_ApprovedUseAttributes". All fields are combined with a logical 'AND'. */
export type Md_ApprovedUseAttributes_Bool_Exp = {
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  AttributeId?: Maybe<Int_Mssql_Comparison_Exp>;
  MD_ApprovedUse?: Maybe<Md_ApprovedUse_Bool_Exp>;
  MD_AttributeBase?: Maybe<Md_AttributeBase_Bool_Exp>;
  OrderNum?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Md_ApprovedUseAttributes_Bool_Exp>>;
  _not?: Maybe<Md_ApprovedUseAttributes_Bool_Exp>;
  _or?: Maybe<Array<Md_ApprovedUseAttributes_Bool_Exp>>;
};

/** upsert condition type for table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_If_Matched = {
  match_columns?: Array<Md_ApprovedUseAttributes_Insert_Match_Column>;
  update_columns?: Array<Md_ApprovedUseAttributes_Update_Column>;
  where?: Maybe<Md_ApprovedUseAttributes_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AttributeId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Insert_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AttributeId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "MD_ApprovedUseAttributes" */
export enum Md_ApprovedUseAttributes_Insert_Match_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  OrderNum = 'OrderNum'
}

/** aggregate max on columns */
export type Md_ApprovedUseAttributes_Max_Fields = {
  __typename?: 'MD_ApprovedUseAttributes_max_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AttributeId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Max_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Md_ApprovedUseAttributes_Min_Fields = {
  __typename?: 'MD_ApprovedUseAttributes_min_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AttributeId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Min_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** response of any mutation on the table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Mutation_Response = {
  __typename?: 'MD_ApprovedUseAttributes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_ApprovedUseAttributes>;
};

/** Ordering options when selecting data from "MD_ApprovedUseAttributes". */
export type Md_ApprovedUseAttributes_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  MD_ApprovedUse?: Maybe<Md_ApprovedUse_Order_By>;
  MD_AttributeBase?: Maybe<Md_AttributeBase_Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** primary key columns input for table: MD_ApprovedUseAttributes */
export type Md_ApprovedUseAttributes_Pk_Columns_Input = {
  ApprovedUseId: Scalars['Int'];
  AttributeId: Scalars['Int'];
};

/** select columns of table "MD_ApprovedUseAttributes" */
export enum Md_ApprovedUseAttributes_Select_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  OrderNum = 'OrderNum'
}

/** input type for updating data in table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Set_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AttributeId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Md_ApprovedUseAttributes_Stddev_Fields = {
  __typename?: 'MD_ApprovedUseAttributes_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AttributeId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Stddev_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Md_ApprovedUseAttributes_Stddev_Pop_Fields = {
  __typename?: 'MD_ApprovedUseAttributes_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AttributeId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Stddev_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Md_ApprovedUseAttributes_Stddev_Samp_Fields = {
  __typename?: 'MD_ApprovedUseAttributes_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AttributeId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Stddev_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Md_ApprovedUseAttributes_Sum_Fields = {
  __typename?: 'MD_ApprovedUseAttributes_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AttributeId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Sum_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** update columns of table "MD_ApprovedUseAttributes" */
export enum Md_ApprovedUseAttributes_Update_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  OrderNum = 'OrderNum'
}

/** aggregate var_pop on columns */
export type Md_ApprovedUseAttributes_Var_Pop_Fields = {
  __typename?: 'MD_ApprovedUseAttributes_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AttributeId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Var_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Md_ApprovedUseAttributes_Var_Samp_Fields = {
  __typename?: 'MD_ApprovedUseAttributes_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AttributeId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Var_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Md_ApprovedUseAttributes_Variance_Fields = {
  __typename?: 'MD_ApprovedUseAttributes_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AttributeId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "MD_ApprovedUseAttributes" */
export type Md_ApprovedUseAttributes_Variance_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** aggregated selection of "MD_ApprovedUse" */
export type Md_ApprovedUse_Aggregate = {
  __typename?: 'MD_ApprovedUse_aggregate';
  aggregate?: Maybe<Md_ApprovedUse_Aggregate_Fields>;
  nodes: Array<Md_ApprovedUse>;
};

/** aggregate fields of "MD_ApprovedUse" */
export type Md_ApprovedUse_Aggregate_Fields = {
  __typename?: 'MD_ApprovedUse_aggregate_fields';
  avg?: Maybe<Md_ApprovedUse_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_ApprovedUse_Max_Fields>;
  min?: Maybe<Md_ApprovedUse_Min_Fields>;
  stddev?: Maybe<Md_ApprovedUse_Stddev_Fields>;
  stddev_pop?: Maybe<Md_ApprovedUse_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_ApprovedUse_Stddev_Samp_Fields>;
  sum?: Maybe<Md_ApprovedUse_Sum_Fields>;
  var_pop?: Maybe<Md_ApprovedUse_Var_Pop_Fields>;
  var_samp?: Maybe<Md_ApprovedUse_Var_Samp_Fields>;
  variance?: Maybe<Md_ApprovedUse_Variance_Fields>;
};


/** aggregate fields of "MD_ApprovedUse" */
export type Md_ApprovedUse_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_ApprovedUse_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "MD_ApprovedUse" */
export type Md_ApprovedUse_Aggregate_Order_By = {
  avg?: Maybe<Md_ApprovedUse_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Md_ApprovedUse_Max_Order_By>;
  min?: Maybe<Md_ApprovedUse_Min_Order_By>;
  stddev?: Maybe<Md_ApprovedUse_Stddev_Order_By>;
  stddev_pop?: Maybe<Md_ApprovedUse_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Md_ApprovedUse_Stddev_Samp_Order_By>;
  sum?: Maybe<Md_ApprovedUse_Sum_Order_By>;
  var_pop?: Maybe<Md_ApprovedUse_Var_Pop_Order_By>;
  var_samp?: Maybe<Md_ApprovedUse_Var_Samp_Order_By>;
  variance?: Maybe<Md_ApprovedUse_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Md_ApprovedUse_Avg_Fields = {
  __typename?: 'MD_ApprovedUse_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "MD_ApprovedUse" */
export type Md_ApprovedUse_Avg_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "MD_ApprovedUse". All fields are combined with a logical 'AND'. */
export type Md_ApprovedUse_Bool_Exp = {
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_ApprovedUse?: Maybe<C_ApprovedUse_Bool_Exp>;
  DisplayName?: Maybe<String_Mssql_Comparison_Exp>;
  EntityClass?: Maybe<String_Mssql_Comparison_Exp>;
  FactoryClass?: Maybe<String_Mssql_Comparison_Exp>;
  MD_ApprovedUseAttributes?: Maybe<Md_ApprovedUseAttributes_Bool_Exp>;
  MD_Catalog?: Maybe<Md_Catalog_Bool_Exp>;
  MetadataCatalogId?: Maybe<Int_Mssql_Comparison_Exp>;
  TableName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Md_ApprovedUse_Bool_Exp>>;
  _not?: Maybe<Md_ApprovedUse_Bool_Exp>;
  _or?: Maybe<Array<Md_ApprovedUse_Bool_Exp>>;
};

/** upsert condition type for table "MD_ApprovedUse" */
export type Md_ApprovedUse_If_Matched = {
  match_columns?: Array<Md_ApprovedUse_Insert_Match_Column>;
  update_columns?: Array<Md_ApprovedUse_Update_Column>;
  where?: Maybe<Md_ApprovedUse_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_ApprovedUse" */
export type Md_ApprovedUse_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_ApprovedUse" */
export type Md_ApprovedUse_Insert_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  DisplayName?: Maybe<Scalars['String']>;
  EntityClass?: Maybe<Scalars['String']>;
  FactoryClass?: Maybe<Scalars['String']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
  TableName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "MD_ApprovedUse" */
export enum Md_ApprovedUse_Insert_Match_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  EntityClass = 'EntityClass',
  /** column name */
  FactoryClass = 'FactoryClass',
  /** column name */
  MetadataCatalogId = 'MetadataCatalogId',
  /** column name */
  TableName = 'TableName'
}

/** aggregate max on columns */
export type Md_ApprovedUse_Max_Fields = {
  __typename?: 'MD_ApprovedUse_max_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  DisplayName?: Maybe<Scalars['String']>;
  EntityClass?: Maybe<Scalars['String']>;
  FactoryClass?: Maybe<Scalars['String']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
  TableName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "MD_ApprovedUse" */
export type Md_ApprovedUse_Max_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  DisplayName?: Maybe<Order_By>;
  EntityClass?: Maybe<Order_By>;
  FactoryClass?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
  TableName?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Md_ApprovedUse_Min_Fields = {
  __typename?: 'MD_ApprovedUse_min_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  DisplayName?: Maybe<Scalars['String']>;
  EntityClass?: Maybe<Scalars['String']>;
  FactoryClass?: Maybe<Scalars['String']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
  TableName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "MD_ApprovedUse" */
export type Md_ApprovedUse_Min_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  DisplayName?: Maybe<Order_By>;
  EntityClass?: Maybe<Order_By>;
  FactoryClass?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
  TableName?: Maybe<Order_By>;
};

/** response of any mutation on the table "MD_ApprovedUse" */
export type Md_ApprovedUse_Mutation_Response = {
  __typename?: 'MD_ApprovedUse_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_ApprovedUse>;
};

/** Ordering options when selecting data from "MD_ApprovedUse". */
export type Md_ApprovedUse_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  C_ApprovedUse?: Maybe<C_ApprovedUse_Order_By>;
  DisplayName?: Maybe<Order_By>;
  EntityClass?: Maybe<Order_By>;
  FactoryClass?: Maybe<Order_By>;
  MD_ApprovedUseAttributes_aggregate?: Maybe<Md_ApprovedUseAttributes_Aggregate_Order_By>;
  MD_Catalog?: Maybe<Md_Catalog_Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
  TableName?: Maybe<Order_By>;
};

/** primary key columns input for table: MD_ApprovedUse */
export type Md_ApprovedUse_Pk_Columns_Input = {
  ApprovedUseId: Scalars['Int'];
};

/** select columns of table "MD_ApprovedUse" */
export enum Md_ApprovedUse_Select_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  EntityClass = 'EntityClass',
  /** column name */
  FactoryClass = 'FactoryClass',
  /** column name */
  MetadataCatalogId = 'MetadataCatalogId',
  /** column name */
  TableName = 'TableName'
}

/** input type for updating data in table "MD_ApprovedUse" */
export type Md_ApprovedUse_Set_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  DisplayName?: Maybe<Scalars['String']>;
  EntityClass?: Maybe<Scalars['String']>;
  FactoryClass?: Maybe<Scalars['String']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
  TableName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Md_ApprovedUse_Stddev_Fields = {
  __typename?: 'MD_ApprovedUse_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "MD_ApprovedUse" */
export type Md_ApprovedUse_Stddev_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Md_ApprovedUse_Stddev_Pop_Fields = {
  __typename?: 'MD_ApprovedUse_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "MD_ApprovedUse" */
export type Md_ApprovedUse_Stddev_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Md_ApprovedUse_Stddev_Samp_Fields = {
  __typename?: 'MD_ApprovedUse_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "MD_ApprovedUse" */
export type Md_ApprovedUse_Stddev_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Md_ApprovedUse_Sum_Fields = {
  __typename?: 'MD_ApprovedUse_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "MD_ApprovedUse" */
export type Md_ApprovedUse_Sum_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** update columns of table "MD_ApprovedUse" */
export enum Md_ApprovedUse_Update_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  EntityClass = 'EntityClass',
  /** column name */
  FactoryClass = 'FactoryClass',
  /** column name */
  MetadataCatalogId = 'MetadataCatalogId',
  /** column name */
  TableName = 'TableName'
}

/** aggregate var_pop on columns */
export type Md_ApprovedUse_Var_Pop_Fields = {
  __typename?: 'MD_ApprovedUse_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "MD_ApprovedUse" */
export type Md_ApprovedUse_Var_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Md_ApprovedUse_Var_Samp_Fields = {
  __typename?: 'MD_ApprovedUse_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "MD_ApprovedUse" */
export type Md_ApprovedUse_Var_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Md_ApprovedUse_Variance_Fields = {
  __typename?: 'MD_ApprovedUse_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "MD_ApprovedUse" */
export type Md_ApprovedUse_Variance_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** columns and relationships of "MD_AttributeBase" */
export type Md_AttributeBase = {
  __typename?: 'MD_AttributeBase';
  AttributeId: Scalars['Int'];
  /** An object relationship */
  C_MDAttributeType: C_MdAttributeType;
  DisplayName: Scalars['String'];
  IsSearchable: Scalars['Boolean'];
  MDAttributeTypeId: Scalars['Int'];
  /** An array relationship */
  MD_ApprovedUseAttributes: Array<Md_ApprovedUseAttributes>;
  /** An aggregate relationship */
  MD_ApprovedUseAttributes_aggregate: Md_ApprovedUseAttributes_Aggregate;
  /** An object relationship */
  MD_BooleanAttribute?: Maybe<Md_BooleanAttribute>;
  /** An object relationship */
  MD_CodeTableAttribute?: Maybe<Md_CodeTableAttribute>;
  /** An array relationship */
  MD_ComponentUsageAttributes: Array<Md_ComponentUsageAttributes>;
  /** An aggregate relationship */
  MD_ComponentUsageAttributes_aggregate: Md_ComponentUsageAttributes_Aggregate;
  /** An object relationship */
  MD_MeasurementAttribute?: Maybe<Md_MeasurementAttribute>;
  /** An object relationship */
  MD_MultivalueAttribute?: Maybe<Md_MultivalueAttribute>;
};


/** columns and relationships of "MD_AttributeBase" */
export type Md_AttributeBaseMd_ApprovedUseAttributesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ApprovedUseAttributes_Order_By>>;
  where?: Maybe<Md_ApprovedUseAttributes_Bool_Exp>;
};


/** columns and relationships of "MD_AttributeBase" */
export type Md_AttributeBaseMd_ApprovedUseAttributes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ApprovedUseAttributes_Order_By>>;
  where?: Maybe<Md_ApprovedUseAttributes_Bool_Exp>;
};


/** columns and relationships of "MD_AttributeBase" */
export type Md_AttributeBaseMd_ComponentUsageAttributesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ComponentUsageAttributes_Order_By>>;
  where?: Maybe<Md_ComponentUsageAttributes_Bool_Exp>;
};


/** columns and relationships of "MD_AttributeBase" */
export type Md_AttributeBaseMd_ComponentUsageAttributes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ComponentUsageAttributes_Order_By>>;
  where?: Maybe<Md_ComponentUsageAttributes_Bool_Exp>;
};

/** aggregated selection of "MD_AttributeBase" */
export type Md_AttributeBase_Aggregate = {
  __typename?: 'MD_AttributeBase_aggregate';
  aggregate?: Maybe<Md_AttributeBase_Aggregate_Fields>;
  nodes: Array<Md_AttributeBase>;
};

/** aggregate fields of "MD_AttributeBase" */
export type Md_AttributeBase_Aggregate_Fields = {
  __typename?: 'MD_AttributeBase_aggregate_fields';
  avg?: Maybe<Md_AttributeBase_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_AttributeBase_Max_Fields>;
  min?: Maybe<Md_AttributeBase_Min_Fields>;
  stddev?: Maybe<Md_AttributeBase_Stddev_Fields>;
  stddev_pop?: Maybe<Md_AttributeBase_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_AttributeBase_Stddev_Samp_Fields>;
  sum?: Maybe<Md_AttributeBase_Sum_Fields>;
  var_pop?: Maybe<Md_AttributeBase_Var_Pop_Fields>;
  var_samp?: Maybe<Md_AttributeBase_Var_Samp_Fields>;
  variance?: Maybe<Md_AttributeBase_Variance_Fields>;
};


/** aggregate fields of "MD_AttributeBase" */
export type Md_AttributeBase_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_AttributeBase_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "MD_AttributeBase" */
export type Md_AttributeBase_Aggregate_Order_By = {
  avg?: Maybe<Md_AttributeBase_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Md_AttributeBase_Max_Order_By>;
  min?: Maybe<Md_AttributeBase_Min_Order_By>;
  stddev?: Maybe<Md_AttributeBase_Stddev_Order_By>;
  stddev_pop?: Maybe<Md_AttributeBase_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Md_AttributeBase_Stddev_Samp_Order_By>;
  sum?: Maybe<Md_AttributeBase_Sum_Order_By>;
  var_pop?: Maybe<Md_AttributeBase_Var_Pop_Order_By>;
  var_samp?: Maybe<Md_AttributeBase_Var_Samp_Order_By>;
  variance?: Maybe<Md_AttributeBase_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Md_AttributeBase_Avg_Fields = {
  __typename?: 'MD_AttributeBase_avg_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MDAttributeTypeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "MD_AttributeBase" */
export type Md_AttributeBase_Avg_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MDAttributeTypeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "MD_AttributeBase". All fields are combined with a logical 'AND'. */
export type Md_AttributeBase_Bool_Exp = {
  AttributeId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_MDAttributeType?: Maybe<C_MdAttributeType_Bool_Exp>;
  DisplayName?: Maybe<String_Mssql_Comparison_Exp>;
  IsSearchable?: Maybe<Boolean_Mssql_Comparison_Exp>;
  MDAttributeTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  MD_ApprovedUseAttributes?: Maybe<Md_ApprovedUseAttributes_Bool_Exp>;
  MD_BooleanAttribute?: Maybe<Md_BooleanAttribute_Bool_Exp>;
  MD_CodeTableAttribute?: Maybe<Md_CodeTableAttribute_Bool_Exp>;
  MD_ComponentUsageAttributes?: Maybe<Md_ComponentUsageAttributes_Bool_Exp>;
  MD_MeasurementAttribute?: Maybe<Md_MeasurementAttribute_Bool_Exp>;
  MD_MultivalueAttribute?: Maybe<Md_MultivalueAttribute_Bool_Exp>;
  _and?: Maybe<Array<Md_AttributeBase_Bool_Exp>>;
  _not?: Maybe<Md_AttributeBase_Bool_Exp>;
  _or?: Maybe<Array<Md_AttributeBase_Bool_Exp>>;
};

/** upsert condition type for table "MD_AttributeBase" */
export type Md_AttributeBase_If_Matched = {
  match_columns?: Array<Md_AttributeBase_Insert_Match_Column>;
  update_columns?: Array<Md_AttributeBase_Update_Column>;
  where?: Maybe<Md_AttributeBase_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_AttributeBase" */
export type Md_AttributeBase_Inc_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
  MDAttributeTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_AttributeBase" */
export type Md_AttributeBase_Insert_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
  DisplayName?: Maybe<Scalars['String']>;
  IsSearchable?: Maybe<Scalars['Boolean']>;
  MDAttributeTypeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "MD_AttributeBase" */
export enum Md_AttributeBase_Insert_Match_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  IsSearchable = 'IsSearchable',
  /** column name */
  MdAttributeTypeId = 'MDAttributeTypeId'
}

/** aggregate max on columns */
export type Md_AttributeBase_Max_Fields = {
  __typename?: 'MD_AttributeBase_max_fields';
  AttributeId?: Maybe<Scalars['Int']>;
  DisplayName?: Maybe<Scalars['String']>;
  MDAttributeTypeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "MD_AttributeBase" */
export type Md_AttributeBase_Max_Order_By = {
  AttributeId?: Maybe<Order_By>;
  DisplayName?: Maybe<Order_By>;
  MDAttributeTypeId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Md_AttributeBase_Min_Fields = {
  __typename?: 'MD_AttributeBase_min_fields';
  AttributeId?: Maybe<Scalars['Int']>;
  DisplayName?: Maybe<Scalars['String']>;
  MDAttributeTypeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "MD_AttributeBase" */
export type Md_AttributeBase_Min_Order_By = {
  AttributeId?: Maybe<Order_By>;
  DisplayName?: Maybe<Order_By>;
  MDAttributeTypeId?: Maybe<Order_By>;
};

/** response of any mutation on the table "MD_AttributeBase" */
export type Md_AttributeBase_Mutation_Response = {
  __typename?: 'MD_AttributeBase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_AttributeBase>;
};

/** Ordering options when selecting data from "MD_AttributeBase". */
export type Md_AttributeBase_Order_By = {
  AttributeId?: Maybe<Order_By>;
  C_MDAttributeType?: Maybe<C_MdAttributeType_Order_By>;
  DisplayName?: Maybe<Order_By>;
  IsSearchable?: Maybe<Order_By>;
  MDAttributeTypeId?: Maybe<Order_By>;
  MD_ApprovedUseAttributes_aggregate?: Maybe<Md_ApprovedUseAttributes_Aggregate_Order_By>;
  MD_BooleanAttribute?: Maybe<Md_BooleanAttribute_Order_By>;
  MD_CodeTableAttribute?: Maybe<Md_CodeTableAttribute_Order_By>;
  MD_ComponentUsageAttributes_aggregate?: Maybe<Md_ComponentUsageAttributes_Aggregate_Order_By>;
  MD_MeasurementAttribute?: Maybe<Md_MeasurementAttribute_Order_By>;
  MD_MultivalueAttribute?: Maybe<Md_MultivalueAttribute_Order_By>;
};

/** primary key columns input for table: MD_AttributeBase */
export type Md_AttributeBase_Pk_Columns_Input = {
  AttributeId: Scalars['Int'];
};

/** select columns of table "MD_AttributeBase" */
export enum Md_AttributeBase_Select_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  IsSearchable = 'IsSearchable',
  /** column name */
  MdAttributeTypeId = 'MDAttributeTypeId'
}

/** input type for updating data in table "MD_AttributeBase" */
export type Md_AttributeBase_Set_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
  DisplayName?: Maybe<Scalars['String']>;
  IsSearchable?: Maybe<Scalars['Boolean']>;
  MDAttributeTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Md_AttributeBase_Stddev_Fields = {
  __typename?: 'MD_AttributeBase_stddev_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MDAttributeTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "MD_AttributeBase" */
export type Md_AttributeBase_Stddev_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MDAttributeTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Md_AttributeBase_Stddev_Pop_Fields = {
  __typename?: 'MD_AttributeBase_stddev_pop_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MDAttributeTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "MD_AttributeBase" */
export type Md_AttributeBase_Stddev_Pop_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MDAttributeTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Md_AttributeBase_Stddev_Samp_Fields = {
  __typename?: 'MD_AttributeBase_stddev_samp_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MDAttributeTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "MD_AttributeBase" */
export type Md_AttributeBase_Stddev_Samp_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MDAttributeTypeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Md_AttributeBase_Sum_Fields = {
  __typename?: 'MD_AttributeBase_sum_fields';
  AttributeId?: Maybe<Scalars['Int']>;
  MDAttributeTypeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "MD_AttributeBase" */
export type Md_AttributeBase_Sum_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MDAttributeTypeId?: Maybe<Order_By>;
};

/** update columns of table "MD_AttributeBase" */
export enum Md_AttributeBase_Update_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  IsSearchable = 'IsSearchable',
  /** column name */
  MdAttributeTypeId = 'MDAttributeTypeId'
}

/** aggregate var_pop on columns */
export type Md_AttributeBase_Var_Pop_Fields = {
  __typename?: 'MD_AttributeBase_var_pop_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MDAttributeTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "MD_AttributeBase" */
export type Md_AttributeBase_Var_Pop_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MDAttributeTypeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Md_AttributeBase_Var_Samp_Fields = {
  __typename?: 'MD_AttributeBase_var_samp_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MDAttributeTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "MD_AttributeBase" */
export type Md_AttributeBase_Var_Samp_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MDAttributeTypeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Md_AttributeBase_Variance_Fields = {
  __typename?: 'MD_AttributeBase_variance_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MDAttributeTypeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "MD_AttributeBase" */
export type Md_AttributeBase_Variance_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MDAttributeTypeId?: Maybe<Order_By>;
};

/** columns and relationships of "MD_BooleanAttribute" */
export type Md_BooleanAttribute = {
  __typename?: 'MD_BooleanAttribute';
  AttributeId: Scalars['Int'];
  ColumnName: Scalars['String'];
  DefaultVal: Scalars['Boolean'];
  DisplayOptionId: Scalars['Int'];
  /** An object relationship */
  MD_AttributeBase: Md_AttributeBase;
  /** An object relationship */
  MD_BooleanDisplayOption: Md_BooleanDisplayOption;
};

/** aggregated selection of "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Aggregate = {
  __typename?: 'MD_BooleanAttribute_aggregate';
  aggregate?: Maybe<Md_BooleanAttribute_Aggregate_Fields>;
  nodes: Array<Md_BooleanAttribute>;
};

/** aggregate fields of "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Aggregate_Fields = {
  __typename?: 'MD_BooleanAttribute_aggregate_fields';
  avg?: Maybe<Md_BooleanAttribute_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_BooleanAttribute_Max_Fields>;
  min?: Maybe<Md_BooleanAttribute_Min_Fields>;
  stddev?: Maybe<Md_BooleanAttribute_Stddev_Fields>;
  stddev_pop?: Maybe<Md_BooleanAttribute_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_BooleanAttribute_Stddev_Samp_Fields>;
  sum?: Maybe<Md_BooleanAttribute_Sum_Fields>;
  var_pop?: Maybe<Md_BooleanAttribute_Var_Pop_Fields>;
  var_samp?: Maybe<Md_BooleanAttribute_Var_Samp_Fields>;
  variance?: Maybe<Md_BooleanAttribute_Variance_Fields>;
};


/** aggregate fields of "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_BooleanAttribute_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Aggregate_Order_By = {
  avg?: Maybe<Md_BooleanAttribute_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Md_BooleanAttribute_Max_Order_By>;
  min?: Maybe<Md_BooleanAttribute_Min_Order_By>;
  stddev?: Maybe<Md_BooleanAttribute_Stddev_Order_By>;
  stddev_pop?: Maybe<Md_BooleanAttribute_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Md_BooleanAttribute_Stddev_Samp_Order_By>;
  sum?: Maybe<Md_BooleanAttribute_Sum_Order_By>;
  var_pop?: Maybe<Md_BooleanAttribute_Var_Pop_Order_By>;
  var_samp?: Maybe<Md_BooleanAttribute_Var_Samp_Order_By>;
  variance?: Maybe<Md_BooleanAttribute_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Md_BooleanAttribute_Avg_Fields = {
  __typename?: 'MD_BooleanAttribute_avg_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  DisplayOptionId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Avg_Order_By = {
  AttributeId?: Maybe<Order_By>;
  DisplayOptionId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "MD_BooleanAttribute". All fields are combined with a logical 'AND'. */
export type Md_BooleanAttribute_Bool_Exp = {
  AttributeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ColumnName?: Maybe<String_Mssql_Comparison_Exp>;
  DefaultVal?: Maybe<Boolean_Mssql_Comparison_Exp>;
  DisplayOptionId?: Maybe<Int_Mssql_Comparison_Exp>;
  MD_AttributeBase?: Maybe<Md_AttributeBase_Bool_Exp>;
  MD_BooleanDisplayOption?: Maybe<Md_BooleanDisplayOption_Bool_Exp>;
  _and?: Maybe<Array<Md_BooleanAttribute_Bool_Exp>>;
  _not?: Maybe<Md_BooleanAttribute_Bool_Exp>;
  _or?: Maybe<Array<Md_BooleanAttribute_Bool_Exp>>;
};

/** upsert condition type for table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_If_Matched = {
  match_columns?: Array<Md_BooleanAttribute_Insert_Match_Column>;
  update_columns?: Array<Md_BooleanAttribute_Update_Column>;
  where?: Maybe<Md_BooleanAttribute_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Inc_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
  DisplayOptionId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Insert_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
  ColumnName?: Maybe<Scalars['String']>;
  DefaultVal?: Maybe<Scalars['Boolean']>;
  DisplayOptionId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "MD_BooleanAttribute" */
export enum Md_BooleanAttribute_Insert_Match_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  ColumnName = 'ColumnName',
  /** column name */
  DefaultVal = 'DefaultVal',
  /** column name */
  DisplayOptionId = 'DisplayOptionId'
}

/** aggregate max on columns */
export type Md_BooleanAttribute_Max_Fields = {
  __typename?: 'MD_BooleanAttribute_max_fields';
  AttributeId?: Maybe<Scalars['Int']>;
  ColumnName?: Maybe<Scalars['String']>;
  DisplayOptionId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Max_Order_By = {
  AttributeId?: Maybe<Order_By>;
  ColumnName?: Maybe<Order_By>;
  DisplayOptionId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Md_BooleanAttribute_Min_Fields = {
  __typename?: 'MD_BooleanAttribute_min_fields';
  AttributeId?: Maybe<Scalars['Int']>;
  ColumnName?: Maybe<Scalars['String']>;
  DisplayOptionId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Min_Order_By = {
  AttributeId?: Maybe<Order_By>;
  ColumnName?: Maybe<Order_By>;
  DisplayOptionId?: Maybe<Order_By>;
};

/** response of any mutation on the table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Mutation_Response = {
  __typename?: 'MD_BooleanAttribute_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_BooleanAttribute>;
};

/** Ordering options when selecting data from "MD_BooleanAttribute". */
export type Md_BooleanAttribute_Order_By = {
  AttributeId?: Maybe<Order_By>;
  ColumnName?: Maybe<Order_By>;
  DefaultVal?: Maybe<Order_By>;
  DisplayOptionId?: Maybe<Order_By>;
  MD_AttributeBase?: Maybe<Md_AttributeBase_Order_By>;
  MD_BooleanDisplayOption?: Maybe<Md_BooleanDisplayOption_Order_By>;
};

/** primary key columns input for table: MD_BooleanAttribute */
export type Md_BooleanAttribute_Pk_Columns_Input = {
  AttributeId: Scalars['Int'];
};

/** select columns of table "MD_BooleanAttribute" */
export enum Md_BooleanAttribute_Select_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  ColumnName = 'ColumnName',
  /** column name */
  DefaultVal = 'DefaultVal',
  /** column name */
  DisplayOptionId = 'DisplayOptionId'
}

/** input type for updating data in table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Set_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
  ColumnName?: Maybe<Scalars['String']>;
  DefaultVal?: Maybe<Scalars['Boolean']>;
  DisplayOptionId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Md_BooleanAttribute_Stddev_Fields = {
  __typename?: 'MD_BooleanAttribute_stddev_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  DisplayOptionId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Stddev_Order_By = {
  AttributeId?: Maybe<Order_By>;
  DisplayOptionId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Md_BooleanAttribute_Stddev_Pop_Fields = {
  __typename?: 'MD_BooleanAttribute_stddev_pop_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  DisplayOptionId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Stddev_Pop_Order_By = {
  AttributeId?: Maybe<Order_By>;
  DisplayOptionId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Md_BooleanAttribute_Stddev_Samp_Fields = {
  __typename?: 'MD_BooleanAttribute_stddev_samp_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  DisplayOptionId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Stddev_Samp_Order_By = {
  AttributeId?: Maybe<Order_By>;
  DisplayOptionId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Md_BooleanAttribute_Sum_Fields = {
  __typename?: 'MD_BooleanAttribute_sum_fields';
  AttributeId?: Maybe<Scalars['Int']>;
  DisplayOptionId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Sum_Order_By = {
  AttributeId?: Maybe<Order_By>;
  DisplayOptionId?: Maybe<Order_By>;
};

/** update columns of table "MD_BooleanAttribute" */
export enum Md_BooleanAttribute_Update_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  ColumnName = 'ColumnName',
  /** column name */
  DefaultVal = 'DefaultVal',
  /** column name */
  DisplayOptionId = 'DisplayOptionId'
}

/** aggregate var_pop on columns */
export type Md_BooleanAttribute_Var_Pop_Fields = {
  __typename?: 'MD_BooleanAttribute_var_pop_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  DisplayOptionId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Var_Pop_Order_By = {
  AttributeId?: Maybe<Order_By>;
  DisplayOptionId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Md_BooleanAttribute_Var_Samp_Fields = {
  __typename?: 'MD_BooleanAttribute_var_samp_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  DisplayOptionId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Var_Samp_Order_By = {
  AttributeId?: Maybe<Order_By>;
  DisplayOptionId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Md_BooleanAttribute_Variance_Fields = {
  __typename?: 'MD_BooleanAttribute_variance_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  DisplayOptionId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "MD_BooleanAttribute" */
export type Md_BooleanAttribute_Variance_Order_By = {
  AttributeId?: Maybe<Order_By>;
  DisplayOptionId?: Maybe<Order_By>;
};

/** columns and relationships of "MD_BooleanDisplayOption" */
export type Md_BooleanDisplayOption = {
  __typename?: 'MD_BooleanDisplayOption';
  BooleanDisplayOptionId: Scalars['Int'];
  DisplayOption: Scalars['String'];
  /** An array relationship */
  MD_BooleanAttributes: Array<Md_BooleanAttribute>;
  /** An aggregate relationship */
  MD_BooleanAttributes_aggregate: Md_BooleanAttribute_Aggregate;
};


/** columns and relationships of "MD_BooleanDisplayOption" */
export type Md_BooleanDisplayOptionMd_BooleanAttributesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_BooleanAttribute_Order_By>>;
  where?: Maybe<Md_BooleanAttribute_Bool_Exp>;
};


/** columns and relationships of "MD_BooleanDisplayOption" */
export type Md_BooleanDisplayOptionMd_BooleanAttributes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_BooleanAttribute_Order_By>>;
  where?: Maybe<Md_BooleanAttribute_Bool_Exp>;
};

/** aggregated selection of "MD_BooleanDisplayOption" */
export type Md_BooleanDisplayOption_Aggregate = {
  __typename?: 'MD_BooleanDisplayOption_aggregate';
  aggregate?: Maybe<Md_BooleanDisplayOption_Aggregate_Fields>;
  nodes: Array<Md_BooleanDisplayOption>;
};

/** aggregate fields of "MD_BooleanDisplayOption" */
export type Md_BooleanDisplayOption_Aggregate_Fields = {
  __typename?: 'MD_BooleanDisplayOption_aggregate_fields';
  avg?: Maybe<Md_BooleanDisplayOption_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_BooleanDisplayOption_Max_Fields>;
  min?: Maybe<Md_BooleanDisplayOption_Min_Fields>;
  stddev?: Maybe<Md_BooleanDisplayOption_Stddev_Fields>;
  stddev_pop?: Maybe<Md_BooleanDisplayOption_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_BooleanDisplayOption_Stddev_Samp_Fields>;
  sum?: Maybe<Md_BooleanDisplayOption_Sum_Fields>;
  var_pop?: Maybe<Md_BooleanDisplayOption_Var_Pop_Fields>;
  var_samp?: Maybe<Md_BooleanDisplayOption_Var_Samp_Fields>;
  variance?: Maybe<Md_BooleanDisplayOption_Variance_Fields>;
};


/** aggregate fields of "MD_BooleanDisplayOption" */
export type Md_BooleanDisplayOption_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_BooleanDisplayOption_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Md_BooleanDisplayOption_Avg_Fields = {
  __typename?: 'MD_BooleanDisplayOption_avg_fields';
  BooleanDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MD_BooleanDisplayOption". All fields are combined with a logical 'AND'. */
export type Md_BooleanDisplayOption_Bool_Exp = {
  BooleanDisplayOptionId?: Maybe<Int_Mssql_Comparison_Exp>;
  DisplayOption?: Maybe<String_Mssql_Comparison_Exp>;
  MD_BooleanAttributes?: Maybe<Md_BooleanAttribute_Bool_Exp>;
  _and?: Maybe<Array<Md_BooleanDisplayOption_Bool_Exp>>;
  _not?: Maybe<Md_BooleanDisplayOption_Bool_Exp>;
  _or?: Maybe<Array<Md_BooleanDisplayOption_Bool_Exp>>;
};

/** upsert condition type for table "MD_BooleanDisplayOption" */
export type Md_BooleanDisplayOption_If_Matched = {
  match_columns?: Array<Md_BooleanDisplayOption_Insert_Match_Column>;
  update_columns?: Array<Md_BooleanDisplayOption_Update_Column>;
  where?: Maybe<Md_BooleanDisplayOption_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_BooleanDisplayOption" */
export type Md_BooleanDisplayOption_Inc_Input = {
  BooleanDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_BooleanDisplayOption" */
export type Md_BooleanDisplayOption_Insert_Input = {
  BooleanDisplayOptionId?: Maybe<Scalars['Int']>;
  DisplayOption?: Maybe<Scalars['String']>;
};

/** select match_columns of table "MD_BooleanDisplayOption" */
export enum Md_BooleanDisplayOption_Insert_Match_Column {
  /** column name */
  BooleanDisplayOptionId = 'BooleanDisplayOptionId',
  /** column name */
  DisplayOption = 'DisplayOption'
}

/** aggregate max on columns */
export type Md_BooleanDisplayOption_Max_Fields = {
  __typename?: 'MD_BooleanDisplayOption_max_fields';
  BooleanDisplayOptionId?: Maybe<Scalars['Int']>;
  DisplayOption?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Md_BooleanDisplayOption_Min_Fields = {
  __typename?: 'MD_BooleanDisplayOption_min_fields';
  BooleanDisplayOptionId?: Maybe<Scalars['Int']>;
  DisplayOption?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "MD_BooleanDisplayOption" */
export type Md_BooleanDisplayOption_Mutation_Response = {
  __typename?: 'MD_BooleanDisplayOption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_BooleanDisplayOption>;
};

/** Ordering options when selecting data from "MD_BooleanDisplayOption". */
export type Md_BooleanDisplayOption_Order_By = {
  BooleanDisplayOptionId?: Maybe<Order_By>;
  DisplayOption?: Maybe<Order_By>;
  MD_BooleanAttributes_aggregate?: Maybe<Md_BooleanAttribute_Aggregate_Order_By>;
};

/** primary key columns input for table: MD_BooleanDisplayOption */
export type Md_BooleanDisplayOption_Pk_Columns_Input = {
  BooleanDisplayOptionId: Scalars['Int'];
};

/** select columns of table "MD_BooleanDisplayOption" */
export enum Md_BooleanDisplayOption_Select_Column {
  /** column name */
  BooleanDisplayOptionId = 'BooleanDisplayOptionId',
  /** column name */
  DisplayOption = 'DisplayOption'
}

/** input type for updating data in table "MD_BooleanDisplayOption" */
export type Md_BooleanDisplayOption_Set_Input = {
  BooleanDisplayOptionId?: Maybe<Scalars['Int']>;
  DisplayOption?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Md_BooleanDisplayOption_Stddev_Fields = {
  __typename?: 'MD_BooleanDisplayOption_stddev_fields';
  BooleanDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Md_BooleanDisplayOption_Stddev_Pop_Fields = {
  __typename?: 'MD_BooleanDisplayOption_stddev_pop_fields';
  BooleanDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Md_BooleanDisplayOption_Stddev_Samp_Fields = {
  __typename?: 'MD_BooleanDisplayOption_stddev_samp_fields';
  BooleanDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Md_BooleanDisplayOption_Sum_Fields = {
  __typename?: 'MD_BooleanDisplayOption_sum_fields';
  BooleanDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** update columns of table "MD_BooleanDisplayOption" */
export enum Md_BooleanDisplayOption_Update_Column {
  /** column name */
  BooleanDisplayOptionId = 'BooleanDisplayOptionId',
  /** column name */
  DisplayOption = 'DisplayOption'
}

/** aggregate var_pop on columns */
export type Md_BooleanDisplayOption_Var_Pop_Fields = {
  __typename?: 'MD_BooleanDisplayOption_var_pop_fields';
  BooleanDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Md_BooleanDisplayOption_Var_Samp_Fields = {
  __typename?: 'MD_BooleanDisplayOption_var_samp_fields';
  BooleanDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Md_BooleanDisplayOption_Variance_Fields = {
  __typename?: 'MD_BooleanDisplayOption_variance_fields';
  BooleanDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "MD_Catalog" */
export type Md_Catalog = {
  __typename?: 'MD_Catalog';
  AssemblyName: Scalars['String'];
  EntityNamespace: Scalars['String'];
  FactoryNamespace: Scalars['String'];
  /** An array relationship */
  MD_ApprovedUses: Array<Md_ApprovedUse>;
  /** An aggregate relationship */
  MD_ApprovedUses_aggregate: Md_ApprovedUse_Aggregate;
  /** An array relationship */
  MD_ComponentUsages: Array<Md_ComponentUsage>;
  /** An aggregate relationship */
  MD_ComponentUsages_aggregate: Md_ComponentUsage_Aggregate;
  MetadataCatalogId: Scalars['Int'];
  TypedViewNamespace: Scalars['String'];
};


/** columns and relationships of "MD_Catalog" */
export type Md_CatalogMd_ApprovedUsesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ApprovedUse_Order_By>>;
  where?: Maybe<Md_ApprovedUse_Bool_Exp>;
};


/** columns and relationships of "MD_Catalog" */
export type Md_CatalogMd_ApprovedUses_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ApprovedUse_Order_By>>;
  where?: Maybe<Md_ApprovedUse_Bool_Exp>;
};


/** columns and relationships of "MD_Catalog" */
export type Md_CatalogMd_ComponentUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ComponentUsage_Order_By>>;
  where?: Maybe<Md_ComponentUsage_Bool_Exp>;
};


/** columns and relationships of "MD_Catalog" */
export type Md_CatalogMd_ComponentUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ComponentUsage_Order_By>>;
  where?: Maybe<Md_ComponentUsage_Bool_Exp>;
};

/** aggregated selection of "MD_Catalog" */
export type Md_Catalog_Aggregate = {
  __typename?: 'MD_Catalog_aggregate';
  aggregate?: Maybe<Md_Catalog_Aggregate_Fields>;
  nodes: Array<Md_Catalog>;
};

/** aggregate fields of "MD_Catalog" */
export type Md_Catalog_Aggregate_Fields = {
  __typename?: 'MD_Catalog_aggregate_fields';
  avg?: Maybe<Md_Catalog_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_Catalog_Max_Fields>;
  min?: Maybe<Md_Catalog_Min_Fields>;
  stddev?: Maybe<Md_Catalog_Stddev_Fields>;
  stddev_pop?: Maybe<Md_Catalog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_Catalog_Stddev_Samp_Fields>;
  sum?: Maybe<Md_Catalog_Sum_Fields>;
  var_pop?: Maybe<Md_Catalog_Var_Pop_Fields>;
  var_samp?: Maybe<Md_Catalog_Var_Samp_Fields>;
  variance?: Maybe<Md_Catalog_Variance_Fields>;
};


/** aggregate fields of "MD_Catalog" */
export type Md_Catalog_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_Catalog_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Md_Catalog_Avg_Fields = {
  __typename?: 'MD_Catalog_avg_fields';
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MD_Catalog". All fields are combined with a logical 'AND'. */
export type Md_Catalog_Bool_Exp = {
  AssemblyName?: Maybe<String_Mssql_Comparison_Exp>;
  EntityNamespace?: Maybe<String_Mssql_Comparison_Exp>;
  FactoryNamespace?: Maybe<String_Mssql_Comparison_Exp>;
  MD_ApprovedUses?: Maybe<Md_ApprovedUse_Bool_Exp>;
  MD_ComponentUsages?: Maybe<Md_ComponentUsage_Bool_Exp>;
  MetadataCatalogId?: Maybe<Int_Mssql_Comparison_Exp>;
  TypedViewNamespace?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Md_Catalog_Bool_Exp>>;
  _not?: Maybe<Md_Catalog_Bool_Exp>;
  _or?: Maybe<Array<Md_Catalog_Bool_Exp>>;
};

/** upsert condition type for table "MD_Catalog" */
export type Md_Catalog_If_Matched = {
  match_columns?: Array<Md_Catalog_Insert_Match_Column>;
  update_columns?: Array<Md_Catalog_Update_Column>;
  where?: Maybe<Md_Catalog_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_Catalog" */
export type Md_Catalog_Inc_Input = {
  MetadataCatalogId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_Catalog" */
export type Md_Catalog_Insert_Input = {
  AssemblyName?: Maybe<Scalars['String']>;
  EntityNamespace?: Maybe<Scalars['String']>;
  FactoryNamespace?: Maybe<Scalars['String']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
  TypedViewNamespace?: Maybe<Scalars['String']>;
};

/** select match_columns of table "MD_Catalog" */
export enum Md_Catalog_Insert_Match_Column {
  /** column name */
  AssemblyName = 'AssemblyName',
  /** column name */
  EntityNamespace = 'EntityNamespace',
  /** column name */
  FactoryNamespace = 'FactoryNamespace',
  /** column name */
  MetadataCatalogId = 'MetadataCatalogId',
  /** column name */
  TypedViewNamespace = 'TypedViewNamespace'
}

/** aggregate max on columns */
export type Md_Catalog_Max_Fields = {
  __typename?: 'MD_Catalog_max_fields';
  AssemblyName?: Maybe<Scalars['String']>;
  EntityNamespace?: Maybe<Scalars['String']>;
  FactoryNamespace?: Maybe<Scalars['String']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
  TypedViewNamespace?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Md_Catalog_Min_Fields = {
  __typename?: 'MD_Catalog_min_fields';
  AssemblyName?: Maybe<Scalars['String']>;
  EntityNamespace?: Maybe<Scalars['String']>;
  FactoryNamespace?: Maybe<Scalars['String']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
  TypedViewNamespace?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "MD_Catalog" */
export type Md_Catalog_Mutation_Response = {
  __typename?: 'MD_Catalog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_Catalog>;
};

/** Ordering options when selecting data from "MD_Catalog". */
export type Md_Catalog_Order_By = {
  AssemblyName?: Maybe<Order_By>;
  EntityNamespace?: Maybe<Order_By>;
  FactoryNamespace?: Maybe<Order_By>;
  MD_ApprovedUses_aggregate?: Maybe<Md_ApprovedUse_Aggregate_Order_By>;
  MD_ComponentUsages_aggregate?: Maybe<Md_ComponentUsage_Aggregate_Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
  TypedViewNamespace?: Maybe<Order_By>;
};

/** primary key columns input for table: MD_Catalog */
export type Md_Catalog_Pk_Columns_Input = {
  MetadataCatalogId: Scalars['Int'];
};

/** select columns of table "MD_Catalog" */
export enum Md_Catalog_Select_Column {
  /** column name */
  AssemblyName = 'AssemblyName',
  /** column name */
  EntityNamespace = 'EntityNamespace',
  /** column name */
  FactoryNamespace = 'FactoryNamespace',
  /** column name */
  MetadataCatalogId = 'MetadataCatalogId',
  /** column name */
  TypedViewNamespace = 'TypedViewNamespace'
}

/** input type for updating data in table "MD_Catalog" */
export type Md_Catalog_Set_Input = {
  AssemblyName?: Maybe<Scalars['String']>;
  EntityNamespace?: Maybe<Scalars['String']>;
  FactoryNamespace?: Maybe<Scalars['String']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
  TypedViewNamespace?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Md_Catalog_Stddev_Fields = {
  __typename?: 'MD_Catalog_stddev_fields';
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Md_Catalog_Stddev_Pop_Fields = {
  __typename?: 'MD_Catalog_stddev_pop_fields';
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Md_Catalog_Stddev_Samp_Fields = {
  __typename?: 'MD_Catalog_stddev_samp_fields';
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Md_Catalog_Sum_Fields = {
  __typename?: 'MD_Catalog_sum_fields';
  MetadataCatalogId?: Maybe<Scalars['Int']>;
};

/** update columns of table "MD_Catalog" */
export enum Md_Catalog_Update_Column {
  /** column name */
  AssemblyName = 'AssemblyName',
  /** column name */
  EntityNamespace = 'EntityNamespace',
  /** column name */
  FactoryNamespace = 'FactoryNamespace',
  /** column name */
  MetadataCatalogId = 'MetadataCatalogId',
  /** column name */
  TypedViewNamespace = 'TypedViewNamespace'
}

/** aggregate var_pop on columns */
export type Md_Catalog_Var_Pop_Fields = {
  __typename?: 'MD_Catalog_var_pop_fields';
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Md_Catalog_Var_Samp_Fields = {
  __typename?: 'MD_Catalog_var_samp_fields';
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Md_Catalog_Variance_Fields = {
  __typename?: 'MD_Catalog_variance_fields';
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "MD_CodeTableAttribute" */
export type Md_CodeTableAttribute = {
  __typename?: 'MD_CodeTableAttribute';
  AttributeId: Scalars['Int'];
  CodeTableDisplayColumn: Scalars['String'];
  CodeTableName: Scalars['String'];
  CodeTableValueColumn: Scalars['String'];
  ColumnName: Scalars['String'];
  /** An object relationship */
  MD_AttributeBase: Md_AttributeBase;
};

/** aggregated selection of "MD_CodeTableAttribute" */
export type Md_CodeTableAttribute_Aggregate = {
  __typename?: 'MD_CodeTableAttribute_aggregate';
  aggregate?: Maybe<Md_CodeTableAttribute_Aggregate_Fields>;
  nodes: Array<Md_CodeTableAttribute>;
};

/** aggregate fields of "MD_CodeTableAttribute" */
export type Md_CodeTableAttribute_Aggregate_Fields = {
  __typename?: 'MD_CodeTableAttribute_aggregate_fields';
  avg?: Maybe<Md_CodeTableAttribute_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_CodeTableAttribute_Max_Fields>;
  min?: Maybe<Md_CodeTableAttribute_Min_Fields>;
  stddev?: Maybe<Md_CodeTableAttribute_Stddev_Fields>;
  stddev_pop?: Maybe<Md_CodeTableAttribute_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_CodeTableAttribute_Stddev_Samp_Fields>;
  sum?: Maybe<Md_CodeTableAttribute_Sum_Fields>;
  var_pop?: Maybe<Md_CodeTableAttribute_Var_Pop_Fields>;
  var_samp?: Maybe<Md_CodeTableAttribute_Var_Samp_Fields>;
  variance?: Maybe<Md_CodeTableAttribute_Variance_Fields>;
};


/** aggregate fields of "MD_CodeTableAttribute" */
export type Md_CodeTableAttribute_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_CodeTableAttribute_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Md_CodeTableAttribute_Avg_Fields = {
  __typename?: 'MD_CodeTableAttribute_avg_fields';
  AttributeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MD_CodeTableAttribute". All fields are combined with a logical 'AND'. */
export type Md_CodeTableAttribute_Bool_Exp = {
  AttributeId?: Maybe<Int_Mssql_Comparison_Exp>;
  CodeTableDisplayColumn?: Maybe<String_Mssql_Comparison_Exp>;
  CodeTableName?: Maybe<String_Mssql_Comparison_Exp>;
  CodeTableValueColumn?: Maybe<String_Mssql_Comparison_Exp>;
  ColumnName?: Maybe<String_Mssql_Comparison_Exp>;
  MD_AttributeBase?: Maybe<Md_AttributeBase_Bool_Exp>;
  _and?: Maybe<Array<Md_CodeTableAttribute_Bool_Exp>>;
  _not?: Maybe<Md_CodeTableAttribute_Bool_Exp>;
  _or?: Maybe<Array<Md_CodeTableAttribute_Bool_Exp>>;
};

/** upsert condition type for table "MD_CodeTableAttribute" */
export type Md_CodeTableAttribute_If_Matched = {
  match_columns?: Array<Md_CodeTableAttribute_Insert_Match_Column>;
  update_columns?: Array<Md_CodeTableAttribute_Update_Column>;
  where?: Maybe<Md_CodeTableAttribute_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_CodeTableAttribute" */
export type Md_CodeTableAttribute_Inc_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_CodeTableAttribute" */
export type Md_CodeTableAttribute_Insert_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
  CodeTableDisplayColumn?: Maybe<Scalars['String']>;
  CodeTableName?: Maybe<Scalars['String']>;
  CodeTableValueColumn?: Maybe<Scalars['String']>;
  ColumnName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "MD_CodeTableAttribute" */
export enum Md_CodeTableAttribute_Insert_Match_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  CodeTableDisplayColumn = 'CodeTableDisplayColumn',
  /** column name */
  CodeTableName = 'CodeTableName',
  /** column name */
  CodeTableValueColumn = 'CodeTableValueColumn',
  /** column name */
  ColumnName = 'ColumnName'
}

/** aggregate max on columns */
export type Md_CodeTableAttribute_Max_Fields = {
  __typename?: 'MD_CodeTableAttribute_max_fields';
  AttributeId?: Maybe<Scalars['Int']>;
  CodeTableDisplayColumn?: Maybe<Scalars['String']>;
  CodeTableName?: Maybe<Scalars['String']>;
  CodeTableValueColumn?: Maybe<Scalars['String']>;
  ColumnName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Md_CodeTableAttribute_Min_Fields = {
  __typename?: 'MD_CodeTableAttribute_min_fields';
  AttributeId?: Maybe<Scalars['Int']>;
  CodeTableDisplayColumn?: Maybe<Scalars['String']>;
  CodeTableName?: Maybe<Scalars['String']>;
  CodeTableValueColumn?: Maybe<Scalars['String']>;
  ColumnName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "MD_CodeTableAttribute" */
export type Md_CodeTableAttribute_Mutation_Response = {
  __typename?: 'MD_CodeTableAttribute_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_CodeTableAttribute>;
};

/** Ordering options when selecting data from "MD_CodeTableAttribute". */
export type Md_CodeTableAttribute_Order_By = {
  AttributeId?: Maybe<Order_By>;
  CodeTableDisplayColumn?: Maybe<Order_By>;
  CodeTableName?: Maybe<Order_By>;
  CodeTableValueColumn?: Maybe<Order_By>;
  ColumnName?: Maybe<Order_By>;
  MD_AttributeBase?: Maybe<Md_AttributeBase_Order_By>;
};

/** primary key columns input for table: MD_CodeTableAttribute */
export type Md_CodeTableAttribute_Pk_Columns_Input = {
  AttributeId: Scalars['Int'];
};

/** select columns of table "MD_CodeTableAttribute" */
export enum Md_CodeTableAttribute_Select_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  CodeTableDisplayColumn = 'CodeTableDisplayColumn',
  /** column name */
  CodeTableName = 'CodeTableName',
  /** column name */
  CodeTableValueColumn = 'CodeTableValueColumn',
  /** column name */
  ColumnName = 'ColumnName'
}

/** input type for updating data in table "MD_CodeTableAttribute" */
export type Md_CodeTableAttribute_Set_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
  CodeTableDisplayColumn?: Maybe<Scalars['String']>;
  CodeTableName?: Maybe<Scalars['String']>;
  CodeTableValueColumn?: Maybe<Scalars['String']>;
  ColumnName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Md_CodeTableAttribute_Stddev_Fields = {
  __typename?: 'MD_CodeTableAttribute_stddev_fields';
  AttributeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Md_CodeTableAttribute_Stddev_Pop_Fields = {
  __typename?: 'MD_CodeTableAttribute_stddev_pop_fields';
  AttributeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Md_CodeTableAttribute_Stddev_Samp_Fields = {
  __typename?: 'MD_CodeTableAttribute_stddev_samp_fields';
  AttributeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Md_CodeTableAttribute_Sum_Fields = {
  __typename?: 'MD_CodeTableAttribute_sum_fields';
  AttributeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "MD_CodeTableAttribute" */
export enum Md_CodeTableAttribute_Update_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  CodeTableDisplayColumn = 'CodeTableDisplayColumn',
  /** column name */
  CodeTableName = 'CodeTableName',
  /** column name */
  CodeTableValueColumn = 'CodeTableValueColumn',
  /** column name */
  ColumnName = 'ColumnName'
}

/** aggregate var_pop on columns */
export type Md_CodeTableAttribute_Var_Pop_Fields = {
  __typename?: 'MD_CodeTableAttribute_var_pop_fields';
  AttributeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Md_CodeTableAttribute_Var_Samp_Fields = {
  __typename?: 'MD_CodeTableAttribute_var_samp_fields';
  AttributeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Md_CodeTableAttribute_Variance_Fields = {
  __typename?: 'MD_CodeTableAttribute_variance_fields';
  AttributeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "MD_CodeTables" */
export type Md_CodeTables = {
  __typename?: 'MD_CodeTables';
  CodeTableId: Scalars['Int'];
  EntityClass: Scalars['String'];
  FactoryClass: Scalars['String'];
  IsUserEditable: Scalars['Boolean'];
  TableName: Scalars['String'];
};

/** aggregated selection of "MD_CodeTables" */
export type Md_CodeTables_Aggregate = {
  __typename?: 'MD_CodeTables_aggregate';
  aggregate?: Maybe<Md_CodeTables_Aggregate_Fields>;
  nodes: Array<Md_CodeTables>;
};

/** aggregate fields of "MD_CodeTables" */
export type Md_CodeTables_Aggregate_Fields = {
  __typename?: 'MD_CodeTables_aggregate_fields';
  avg?: Maybe<Md_CodeTables_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_CodeTables_Max_Fields>;
  min?: Maybe<Md_CodeTables_Min_Fields>;
  stddev?: Maybe<Md_CodeTables_Stddev_Fields>;
  stddev_pop?: Maybe<Md_CodeTables_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_CodeTables_Stddev_Samp_Fields>;
  sum?: Maybe<Md_CodeTables_Sum_Fields>;
  var_pop?: Maybe<Md_CodeTables_Var_Pop_Fields>;
  var_samp?: Maybe<Md_CodeTables_Var_Samp_Fields>;
  variance?: Maybe<Md_CodeTables_Variance_Fields>;
};


/** aggregate fields of "MD_CodeTables" */
export type Md_CodeTables_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_CodeTables_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Md_CodeTables_Avg_Fields = {
  __typename?: 'MD_CodeTables_avg_fields';
  CodeTableId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MD_CodeTables". All fields are combined with a logical 'AND'. */
export type Md_CodeTables_Bool_Exp = {
  CodeTableId?: Maybe<Int_Mssql_Comparison_Exp>;
  EntityClass?: Maybe<String_Mssql_Comparison_Exp>;
  FactoryClass?: Maybe<String_Mssql_Comparison_Exp>;
  IsUserEditable?: Maybe<Boolean_Mssql_Comparison_Exp>;
  TableName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Md_CodeTables_Bool_Exp>>;
  _not?: Maybe<Md_CodeTables_Bool_Exp>;
  _or?: Maybe<Array<Md_CodeTables_Bool_Exp>>;
};

/** upsert condition type for table "MD_CodeTables" */
export type Md_CodeTables_If_Matched = {
  match_columns?: Array<Md_CodeTables_Insert_Match_Column>;
  update_columns?: Array<Md_CodeTables_Update_Column>;
  where?: Maybe<Md_CodeTables_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_CodeTables" */
export type Md_CodeTables_Inc_Input = {
  CodeTableId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_CodeTables" */
export type Md_CodeTables_Insert_Input = {
  CodeTableId?: Maybe<Scalars['Int']>;
  EntityClass?: Maybe<Scalars['String']>;
  FactoryClass?: Maybe<Scalars['String']>;
  IsUserEditable?: Maybe<Scalars['Boolean']>;
  TableName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "MD_CodeTables" */
export enum Md_CodeTables_Insert_Match_Column {
  /** column name */
  CodeTableId = 'CodeTableId',
  /** column name */
  EntityClass = 'EntityClass',
  /** column name */
  FactoryClass = 'FactoryClass',
  /** column name */
  IsUserEditable = 'IsUserEditable',
  /** column name */
  TableName = 'TableName'
}

/** aggregate max on columns */
export type Md_CodeTables_Max_Fields = {
  __typename?: 'MD_CodeTables_max_fields';
  CodeTableId?: Maybe<Scalars['Int']>;
  EntityClass?: Maybe<Scalars['String']>;
  FactoryClass?: Maybe<Scalars['String']>;
  TableName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Md_CodeTables_Min_Fields = {
  __typename?: 'MD_CodeTables_min_fields';
  CodeTableId?: Maybe<Scalars['Int']>;
  EntityClass?: Maybe<Scalars['String']>;
  FactoryClass?: Maybe<Scalars['String']>;
  TableName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "MD_CodeTables" */
export type Md_CodeTables_Mutation_Response = {
  __typename?: 'MD_CodeTables_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_CodeTables>;
};

/** Ordering options when selecting data from "MD_CodeTables". */
export type Md_CodeTables_Order_By = {
  CodeTableId?: Maybe<Order_By>;
  EntityClass?: Maybe<Order_By>;
  FactoryClass?: Maybe<Order_By>;
  IsUserEditable?: Maybe<Order_By>;
  TableName?: Maybe<Order_By>;
};

/** primary key columns input for table: MD_CodeTables */
export type Md_CodeTables_Pk_Columns_Input = {
  CodeTableId: Scalars['Int'];
};

/** select columns of table "MD_CodeTables" */
export enum Md_CodeTables_Select_Column {
  /** column name */
  CodeTableId = 'CodeTableId',
  /** column name */
  EntityClass = 'EntityClass',
  /** column name */
  FactoryClass = 'FactoryClass',
  /** column name */
  IsUserEditable = 'IsUserEditable',
  /** column name */
  TableName = 'TableName'
}

/** input type for updating data in table "MD_CodeTables" */
export type Md_CodeTables_Set_Input = {
  CodeTableId?: Maybe<Scalars['Int']>;
  EntityClass?: Maybe<Scalars['String']>;
  FactoryClass?: Maybe<Scalars['String']>;
  IsUserEditable?: Maybe<Scalars['Boolean']>;
  TableName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Md_CodeTables_Stddev_Fields = {
  __typename?: 'MD_CodeTables_stddev_fields';
  CodeTableId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Md_CodeTables_Stddev_Pop_Fields = {
  __typename?: 'MD_CodeTables_stddev_pop_fields';
  CodeTableId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Md_CodeTables_Stddev_Samp_Fields = {
  __typename?: 'MD_CodeTables_stddev_samp_fields';
  CodeTableId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Md_CodeTables_Sum_Fields = {
  __typename?: 'MD_CodeTables_sum_fields';
  CodeTableId?: Maybe<Scalars['Int']>;
};

/** update columns of table "MD_CodeTables" */
export enum Md_CodeTables_Update_Column {
  /** column name */
  CodeTableId = 'CodeTableId',
  /** column name */
  EntityClass = 'EntityClass',
  /** column name */
  FactoryClass = 'FactoryClass',
  /** column name */
  IsUserEditable = 'IsUserEditable',
  /** column name */
  TableName = 'TableName'
}

/** aggregate var_pop on columns */
export type Md_CodeTables_Var_Pop_Fields = {
  __typename?: 'MD_CodeTables_var_pop_fields';
  CodeTableId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Md_CodeTables_Var_Samp_Fields = {
  __typename?: 'MD_CodeTables_var_samp_fields';
  CodeTableId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Md_CodeTables_Variance_Fields = {
  __typename?: 'MD_CodeTables_variance_fields';
  CodeTableId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "MD_ComponentUsage" */
export type Md_ComponentUsage = {
  __typename?: 'MD_ComponentUsage';
  ApprovedUseId: Scalars['Int'];
  /** An object relationship */
  C_ApprovedUse: C_ApprovedUse;
  DisplayName: Scalars['String'];
  EntityClass: Scalars['String'];
  FactoryClass: Scalars['String'];
  IsSecurement?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  MD_Catalog: Md_Catalog;
  /** An array relationship */
  MD_ComponentUsageAttributes: Array<Md_ComponentUsageAttributes>;
  /** An aggregate relationship */
  MD_ComponentUsageAttributes_aggregate: Md_ComponentUsageAttributes_Aggregate;
  MetadataCatalogId: Scalars['Int'];
  SearchViewName: Scalars['String'];
  TableName: Scalars['String'];
};


/** columns and relationships of "MD_ComponentUsage" */
export type Md_ComponentUsageMd_ComponentUsageAttributesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ComponentUsageAttributes_Order_By>>;
  where?: Maybe<Md_ComponentUsageAttributes_Bool_Exp>;
};


/** columns and relationships of "MD_ComponentUsage" */
export type Md_ComponentUsageMd_ComponentUsageAttributes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ComponentUsageAttributes_Order_By>>;
  where?: Maybe<Md_ComponentUsageAttributes_Bool_Exp>;
};

/** columns and relationships of "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes = {
  __typename?: 'MD_ComponentUsageAttributes';
  ApprovedUseId: Scalars['Int'];
  AttributeId: Scalars['Int'];
  /** An object relationship */
  MD_AttributeBase: Md_AttributeBase;
  /** An object relationship */
  MD_ComponentUsage: Md_ComponentUsage;
  OrderNum: Scalars['Int'];
};

/** aggregated selection of "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Aggregate = {
  __typename?: 'MD_ComponentUsageAttributes_aggregate';
  aggregate?: Maybe<Md_ComponentUsageAttributes_Aggregate_Fields>;
  nodes: Array<Md_ComponentUsageAttributes>;
};

/** aggregate fields of "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Aggregate_Fields = {
  __typename?: 'MD_ComponentUsageAttributes_aggregate_fields';
  avg?: Maybe<Md_ComponentUsageAttributes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_ComponentUsageAttributes_Max_Fields>;
  min?: Maybe<Md_ComponentUsageAttributes_Min_Fields>;
  stddev?: Maybe<Md_ComponentUsageAttributes_Stddev_Fields>;
  stddev_pop?: Maybe<Md_ComponentUsageAttributes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_ComponentUsageAttributes_Stddev_Samp_Fields>;
  sum?: Maybe<Md_ComponentUsageAttributes_Sum_Fields>;
  var_pop?: Maybe<Md_ComponentUsageAttributes_Var_Pop_Fields>;
  var_samp?: Maybe<Md_ComponentUsageAttributes_Var_Samp_Fields>;
  variance?: Maybe<Md_ComponentUsageAttributes_Variance_Fields>;
};


/** aggregate fields of "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_ComponentUsageAttributes_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Aggregate_Order_By = {
  avg?: Maybe<Md_ComponentUsageAttributes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Md_ComponentUsageAttributes_Max_Order_By>;
  min?: Maybe<Md_ComponentUsageAttributes_Min_Order_By>;
  stddev?: Maybe<Md_ComponentUsageAttributes_Stddev_Order_By>;
  stddev_pop?: Maybe<Md_ComponentUsageAttributes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Md_ComponentUsageAttributes_Stddev_Samp_Order_By>;
  sum?: Maybe<Md_ComponentUsageAttributes_Sum_Order_By>;
  var_pop?: Maybe<Md_ComponentUsageAttributes_Var_Pop_Order_By>;
  var_samp?: Maybe<Md_ComponentUsageAttributes_Var_Samp_Order_By>;
  variance?: Maybe<Md_ComponentUsageAttributes_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Md_ComponentUsageAttributes_Avg_Fields = {
  __typename?: 'MD_ComponentUsageAttributes_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AttributeId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Avg_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "MD_ComponentUsageAttributes". All fields are combined with a logical 'AND'. */
export type Md_ComponentUsageAttributes_Bool_Exp = {
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  AttributeId?: Maybe<Int_Mssql_Comparison_Exp>;
  MD_AttributeBase?: Maybe<Md_AttributeBase_Bool_Exp>;
  MD_ComponentUsage?: Maybe<Md_ComponentUsage_Bool_Exp>;
  OrderNum?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Md_ComponentUsageAttributes_Bool_Exp>>;
  _not?: Maybe<Md_ComponentUsageAttributes_Bool_Exp>;
  _or?: Maybe<Array<Md_ComponentUsageAttributes_Bool_Exp>>;
};

/** upsert condition type for table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_If_Matched = {
  match_columns?: Array<Md_ComponentUsageAttributes_Insert_Match_Column>;
  update_columns?: Array<Md_ComponentUsageAttributes_Update_Column>;
  where?: Maybe<Md_ComponentUsageAttributes_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AttributeId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Insert_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AttributeId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "MD_ComponentUsageAttributes" */
export enum Md_ComponentUsageAttributes_Insert_Match_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  OrderNum = 'OrderNum'
}

/** aggregate max on columns */
export type Md_ComponentUsageAttributes_Max_Fields = {
  __typename?: 'MD_ComponentUsageAttributes_max_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AttributeId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Max_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Md_ComponentUsageAttributes_Min_Fields = {
  __typename?: 'MD_ComponentUsageAttributes_min_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AttributeId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Min_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** response of any mutation on the table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Mutation_Response = {
  __typename?: 'MD_ComponentUsageAttributes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_ComponentUsageAttributes>;
};

/** Ordering options when selecting data from "MD_ComponentUsageAttributes". */
export type Md_ComponentUsageAttributes_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  MD_AttributeBase?: Maybe<Md_AttributeBase_Order_By>;
  MD_ComponentUsage?: Maybe<Md_ComponentUsage_Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** primary key columns input for table: MD_ComponentUsageAttributes */
export type Md_ComponentUsageAttributes_Pk_Columns_Input = {
  ApprovedUseId: Scalars['Int'];
  AttributeId: Scalars['Int'];
};

/** select columns of table "MD_ComponentUsageAttributes" */
export enum Md_ComponentUsageAttributes_Select_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  OrderNum = 'OrderNum'
}

/** input type for updating data in table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Set_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AttributeId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Md_ComponentUsageAttributes_Stddev_Fields = {
  __typename?: 'MD_ComponentUsageAttributes_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AttributeId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Stddev_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Md_ComponentUsageAttributes_Stddev_Pop_Fields = {
  __typename?: 'MD_ComponentUsageAttributes_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AttributeId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Stddev_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Md_ComponentUsageAttributes_Stddev_Samp_Fields = {
  __typename?: 'MD_ComponentUsageAttributes_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AttributeId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Stddev_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Md_ComponentUsageAttributes_Sum_Fields = {
  __typename?: 'MD_ComponentUsageAttributes_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AttributeId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Sum_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** update columns of table "MD_ComponentUsageAttributes" */
export enum Md_ComponentUsageAttributes_Update_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  OrderNum = 'OrderNum'
}

/** aggregate var_pop on columns */
export type Md_ComponentUsageAttributes_Var_Pop_Fields = {
  __typename?: 'MD_ComponentUsageAttributes_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AttributeId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Var_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Md_ComponentUsageAttributes_Var_Samp_Fields = {
  __typename?: 'MD_ComponentUsageAttributes_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AttributeId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Var_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Md_ComponentUsageAttributes_Variance_Fields = {
  __typename?: 'MD_ComponentUsageAttributes_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AttributeId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "MD_ComponentUsageAttributes" */
export type Md_ComponentUsageAttributes_Variance_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AttributeId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
};

/** aggregated selection of "MD_ComponentUsage" */
export type Md_ComponentUsage_Aggregate = {
  __typename?: 'MD_ComponentUsage_aggregate';
  aggregate?: Maybe<Md_ComponentUsage_Aggregate_Fields>;
  nodes: Array<Md_ComponentUsage>;
};

/** aggregate fields of "MD_ComponentUsage" */
export type Md_ComponentUsage_Aggregate_Fields = {
  __typename?: 'MD_ComponentUsage_aggregate_fields';
  avg?: Maybe<Md_ComponentUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_ComponentUsage_Max_Fields>;
  min?: Maybe<Md_ComponentUsage_Min_Fields>;
  stddev?: Maybe<Md_ComponentUsage_Stddev_Fields>;
  stddev_pop?: Maybe<Md_ComponentUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_ComponentUsage_Stddev_Samp_Fields>;
  sum?: Maybe<Md_ComponentUsage_Sum_Fields>;
  var_pop?: Maybe<Md_ComponentUsage_Var_Pop_Fields>;
  var_samp?: Maybe<Md_ComponentUsage_Var_Samp_Fields>;
  variance?: Maybe<Md_ComponentUsage_Variance_Fields>;
};


/** aggregate fields of "MD_ComponentUsage" */
export type Md_ComponentUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_ComponentUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "MD_ComponentUsage" */
export type Md_ComponentUsage_Aggregate_Order_By = {
  avg?: Maybe<Md_ComponentUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Md_ComponentUsage_Max_Order_By>;
  min?: Maybe<Md_ComponentUsage_Min_Order_By>;
  stddev?: Maybe<Md_ComponentUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<Md_ComponentUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Md_ComponentUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<Md_ComponentUsage_Sum_Order_By>;
  var_pop?: Maybe<Md_ComponentUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<Md_ComponentUsage_Var_Samp_Order_By>;
  variance?: Maybe<Md_ComponentUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Md_ComponentUsage_Avg_Fields = {
  __typename?: 'MD_ComponentUsage_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "MD_ComponentUsage" */
export type Md_ComponentUsage_Avg_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "MD_ComponentUsage". All fields are combined with a logical 'AND'. */
export type Md_ComponentUsage_Bool_Exp = {
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_ApprovedUse?: Maybe<C_ApprovedUse_Bool_Exp>;
  DisplayName?: Maybe<String_Mssql_Comparison_Exp>;
  EntityClass?: Maybe<String_Mssql_Comparison_Exp>;
  FactoryClass?: Maybe<String_Mssql_Comparison_Exp>;
  IsSecurement?: Maybe<Boolean_Mssql_Comparison_Exp>;
  MD_Catalog?: Maybe<Md_Catalog_Bool_Exp>;
  MD_ComponentUsageAttributes?: Maybe<Md_ComponentUsageAttributes_Bool_Exp>;
  MetadataCatalogId?: Maybe<Int_Mssql_Comparison_Exp>;
  SearchViewName?: Maybe<String_Mssql_Comparison_Exp>;
  TableName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Md_ComponentUsage_Bool_Exp>>;
  _not?: Maybe<Md_ComponentUsage_Bool_Exp>;
  _or?: Maybe<Array<Md_ComponentUsage_Bool_Exp>>;
};

/** upsert condition type for table "MD_ComponentUsage" */
export type Md_ComponentUsage_If_Matched = {
  match_columns?: Array<Md_ComponentUsage_Insert_Match_Column>;
  update_columns?: Array<Md_ComponentUsage_Update_Column>;
  where?: Maybe<Md_ComponentUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_ComponentUsage" */
export type Md_ComponentUsage_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_ComponentUsage" */
export type Md_ComponentUsage_Insert_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  DisplayName?: Maybe<Scalars['String']>;
  EntityClass?: Maybe<Scalars['String']>;
  FactoryClass?: Maybe<Scalars['String']>;
  IsSecurement?: Maybe<Scalars['Boolean']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
  SearchViewName?: Maybe<Scalars['String']>;
  TableName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "MD_ComponentUsage" */
export enum Md_ComponentUsage_Insert_Match_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  EntityClass = 'EntityClass',
  /** column name */
  FactoryClass = 'FactoryClass',
  /** column name */
  IsSecurement = 'IsSecurement',
  /** column name */
  MetadataCatalogId = 'MetadataCatalogId',
  /** column name */
  SearchViewName = 'SearchViewName',
  /** column name */
  TableName = 'TableName'
}

/** aggregate max on columns */
export type Md_ComponentUsage_Max_Fields = {
  __typename?: 'MD_ComponentUsage_max_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  DisplayName?: Maybe<Scalars['String']>;
  EntityClass?: Maybe<Scalars['String']>;
  FactoryClass?: Maybe<Scalars['String']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
  SearchViewName?: Maybe<Scalars['String']>;
  TableName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "MD_ComponentUsage" */
export type Md_ComponentUsage_Max_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  DisplayName?: Maybe<Order_By>;
  EntityClass?: Maybe<Order_By>;
  FactoryClass?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
  SearchViewName?: Maybe<Order_By>;
  TableName?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Md_ComponentUsage_Min_Fields = {
  __typename?: 'MD_ComponentUsage_min_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  DisplayName?: Maybe<Scalars['String']>;
  EntityClass?: Maybe<Scalars['String']>;
  FactoryClass?: Maybe<Scalars['String']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
  SearchViewName?: Maybe<Scalars['String']>;
  TableName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "MD_ComponentUsage" */
export type Md_ComponentUsage_Min_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  DisplayName?: Maybe<Order_By>;
  EntityClass?: Maybe<Order_By>;
  FactoryClass?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
  SearchViewName?: Maybe<Order_By>;
  TableName?: Maybe<Order_By>;
};

/** response of any mutation on the table "MD_ComponentUsage" */
export type Md_ComponentUsage_Mutation_Response = {
  __typename?: 'MD_ComponentUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_ComponentUsage>;
};

/** Ordering options when selecting data from "MD_ComponentUsage". */
export type Md_ComponentUsage_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  C_ApprovedUse?: Maybe<C_ApprovedUse_Order_By>;
  DisplayName?: Maybe<Order_By>;
  EntityClass?: Maybe<Order_By>;
  FactoryClass?: Maybe<Order_By>;
  IsSecurement?: Maybe<Order_By>;
  MD_Catalog?: Maybe<Md_Catalog_Order_By>;
  MD_ComponentUsageAttributes_aggregate?: Maybe<Md_ComponentUsageAttributes_Aggregate_Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
  SearchViewName?: Maybe<Order_By>;
  TableName?: Maybe<Order_By>;
};

/** primary key columns input for table: MD_ComponentUsage */
export type Md_ComponentUsage_Pk_Columns_Input = {
  ApprovedUseId: Scalars['Int'];
};

/** select columns of table "MD_ComponentUsage" */
export enum Md_ComponentUsage_Select_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  EntityClass = 'EntityClass',
  /** column name */
  FactoryClass = 'FactoryClass',
  /** column name */
  IsSecurement = 'IsSecurement',
  /** column name */
  MetadataCatalogId = 'MetadataCatalogId',
  /** column name */
  SearchViewName = 'SearchViewName',
  /** column name */
  TableName = 'TableName'
}

/** input type for updating data in table "MD_ComponentUsage" */
export type Md_ComponentUsage_Set_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  DisplayName?: Maybe<Scalars['String']>;
  EntityClass?: Maybe<Scalars['String']>;
  FactoryClass?: Maybe<Scalars['String']>;
  IsSecurement?: Maybe<Scalars['Boolean']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
  SearchViewName?: Maybe<Scalars['String']>;
  TableName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Md_ComponentUsage_Stddev_Fields = {
  __typename?: 'MD_ComponentUsage_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "MD_ComponentUsage" */
export type Md_ComponentUsage_Stddev_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Md_ComponentUsage_Stddev_Pop_Fields = {
  __typename?: 'MD_ComponentUsage_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "MD_ComponentUsage" */
export type Md_ComponentUsage_Stddev_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Md_ComponentUsage_Stddev_Samp_Fields = {
  __typename?: 'MD_ComponentUsage_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "MD_ComponentUsage" */
export type Md_ComponentUsage_Stddev_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Md_ComponentUsage_Sum_Fields = {
  __typename?: 'MD_ComponentUsage_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  MetadataCatalogId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "MD_ComponentUsage" */
export type Md_ComponentUsage_Sum_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** update columns of table "MD_ComponentUsage" */
export enum Md_ComponentUsage_Update_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  EntityClass = 'EntityClass',
  /** column name */
  FactoryClass = 'FactoryClass',
  /** column name */
  IsSecurement = 'IsSecurement',
  /** column name */
  MetadataCatalogId = 'MetadataCatalogId',
  /** column name */
  SearchViewName = 'SearchViewName',
  /** column name */
  TableName = 'TableName'
}

/** aggregate var_pop on columns */
export type Md_ComponentUsage_Var_Pop_Fields = {
  __typename?: 'MD_ComponentUsage_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "MD_ComponentUsage" */
export type Md_ComponentUsage_Var_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Md_ComponentUsage_Var_Samp_Fields = {
  __typename?: 'MD_ComponentUsage_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "MD_ComponentUsage" */
export type Md_ComponentUsage_Var_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Md_ComponentUsage_Variance_Fields = {
  __typename?: 'MD_ComponentUsage_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  MetadataCatalogId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "MD_ComponentUsage" */
export type Md_ComponentUsage_Variance_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  MetadataCatalogId?: Maybe<Order_By>;
};

/** columns and relationships of "MD_Measure" */
export type Md_Measure = {
  __typename?: 'MD_Measure';
  /** An array relationship */
  MD_MeasurementAttributes: Array<Md_MeasurementAttribute>;
  /** An aggregate relationship */
  MD_MeasurementAttributes_aggregate: Md_MeasurementAttribute_Aggregate;
  MeasureId: Scalars['Int'];
  MeasureName: Scalars['String'];
  StorageUnit: Scalars['String'];
};


/** columns and relationships of "MD_Measure" */
export type Md_MeasureMd_MeasurementAttributesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MeasurementAttribute_Order_By>>;
  where?: Maybe<Md_MeasurementAttribute_Bool_Exp>;
};


/** columns and relationships of "MD_Measure" */
export type Md_MeasureMd_MeasurementAttributes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MeasurementAttribute_Order_By>>;
  where?: Maybe<Md_MeasurementAttribute_Bool_Exp>;
};

/** aggregated selection of "MD_Measure" */
export type Md_Measure_Aggregate = {
  __typename?: 'MD_Measure_aggregate';
  aggregate?: Maybe<Md_Measure_Aggregate_Fields>;
  nodes: Array<Md_Measure>;
};

/** aggregate fields of "MD_Measure" */
export type Md_Measure_Aggregate_Fields = {
  __typename?: 'MD_Measure_aggregate_fields';
  avg?: Maybe<Md_Measure_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_Measure_Max_Fields>;
  min?: Maybe<Md_Measure_Min_Fields>;
  stddev?: Maybe<Md_Measure_Stddev_Fields>;
  stddev_pop?: Maybe<Md_Measure_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_Measure_Stddev_Samp_Fields>;
  sum?: Maybe<Md_Measure_Sum_Fields>;
  var_pop?: Maybe<Md_Measure_Var_Pop_Fields>;
  var_samp?: Maybe<Md_Measure_Var_Samp_Fields>;
  variance?: Maybe<Md_Measure_Variance_Fields>;
};


/** aggregate fields of "MD_Measure" */
export type Md_Measure_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_Measure_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Md_Measure_Avg_Fields = {
  __typename?: 'MD_Measure_avg_fields';
  MeasureId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MD_Measure". All fields are combined with a logical 'AND'. */
export type Md_Measure_Bool_Exp = {
  MD_MeasurementAttributes?: Maybe<Md_MeasurementAttribute_Bool_Exp>;
  MeasureId?: Maybe<Int_Mssql_Comparison_Exp>;
  MeasureName?: Maybe<String_Mssql_Comparison_Exp>;
  StorageUnit?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Md_Measure_Bool_Exp>>;
  _not?: Maybe<Md_Measure_Bool_Exp>;
  _or?: Maybe<Array<Md_Measure_Bool_Exp>>;
};

/** upsert condition type for table "MD_Measure" */
export type Md_Measure_If_Matched = {
  match_columns?: Array<Md_Measure_Insert_Match_Column>;
  update_columns?: Array<Md_Measure_Update_Column>;
  where?: Maybe<Md_Measure_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_Measure" */
export type Md_Measure_Inc_Input = {
  MeasureId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_Measure" */
export type Md_Measure_Insert_Input = {
  MeasureId?: Maybe<Scalars['Int']>;
  MeasureName?: Maybe<Scalars['String']>;
  StorageUnit?: Maybe<Scalars['String']>;
};

/** select match_columns of table "MD_Measure" */
export enum Md_Measure_Insert_Match_Column {
  /** column name */
  MeasureId = 'MeasureId',
  /** column name */
  MeasureName = 'MeasureName',
  /** column name */
  StorageUnit = 'StorageUnit'
}

/** aggregate max on columns */
export type Md_Measure_Max_Fields = {
  __typename?: 'MD_Measure_max_fields';
  MeasureId?: Maybe<Scalars['Int']>;
  MeasureName?: Maybe<Scalars['String']>;
  StorageUnit?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Md_Measure_Min_Fields = {
  __typename?: 'MD_Measure_min_fields';
  MeasureId?: Maybe<Scalars['Int']>;
  MeasureName?: Maybe<Scalars['String']>;
  StorageUnit?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "MD_Measure" */
export type Md_Measure_Mutation_Response = {
  __typename?: 'MD_Measure_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_Measure>;
};

/** Ordering options when selecting data from "MD_Measure". */
export type Md_Measure_Order_By = {
  MD_MeasurementAttributes_aggregate?: Maybe<Md_MeasurementAttribute_Aggregate_Order_By>;
  MeasureId?: Maybe<Order_By>;
  MeasureName?: Maybe<Order_By>;
  StorageUnit?: Maybe<Order_By>;
};

/** primary key columns input for table: MD_Measure */
export type Md_Measure_Pk_Columns_Input = {
  MeasureId: Scalars['Int'];
};

/** select columns of table "MD_Measure" */
export enum Md_Measure_Select_Column {
  /** column name */
  MeasureId = 'MeasureId',
  /** column name */
  MeasureName = 'MeasureName',
  /** column name */
  StorageUnit = 'StorageUnit'
}

/** input type for updating data in table "MD_Measure" */
export type Md_Measure_Set_Input = {
  MeasureId?: Maybe<Scalars['Int']>;
  MeasureName?: Maybe<Scalars['String']>;
  StorageUnit?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Md_Measure_Stddev_Fields = {
  __typename?: 'MD_Measure_stddev_fields';
  MeasureId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Md_Measure_Stddev_Pop_Fields = {
  __typename?: 'MD_Measure_stddev_pop_fields';
  MeasureId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Md_Measure_Stddev_Samp_Fields = {
  __typename?: 'MD_Measure_stddev_samp_fields';
  MeasureId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Md_Measure_Sum_Fields = {
  __typename?: 'MD_Measure_sum_fields';
  MeasureId?: Maybe<Scalars['Int']>;
};

/** update columns of table "MD_Measure" */
export enum Md_Measure_Update_Column {
  /** column name */
  MeasureId = 'MeasureId',
  /** column name */
  MeasureName = 'MeasureName',
  /** column name */
  StorageUnit = 'StorageUnit'
}

/** aggregate var_pop on columns */
export type Md_Measure_Var_Pop_Fields = {
  __typename?: 'MD_Measure_var_pop_fields';
  MeasureId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Md_Measure_Var_Samp_Fields = {
  __typename?: 'MD_Measure_var_samp_fields';
  MeasureId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Md_Measure_Variance_Fields = {
  __typename?: 'MD_Measure_variance_fields';
  MeasureId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute = {
  __typename?: 'MD_MeasurementAttribute';
  AttributeId: Scalars['Int'];
  ColumnName: Scalars['String'];
  EnglishUnit: Scalars['String'];
  /** An object relationship */
  MD_AttributeBase: Md_AttributeBase;
  /** An object relationship */
  MD_Measure?: Maybe<Md_Measure>;
  /** An object relationship */
  MD_MeasurementDisplayOption?: Maybe<Md_MeasurementDisplayOption>;
  MeasureId?: Maybe<Scalars['Int']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Int']>;
  MetricUnit: Scalars['String'];
  NumDecimalPlaces: Scalars['Int'];
};

/** aggregated selection of "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Aggregate = {
  __typename?: 'MD_MeasurementAttribute_aggregate';
  aggregate?: Maybe<Md_MeasurementAttribute_Aggregate_Fields>;
  nodes: Array<Md_MeasurementAttribute>;
};

/** aggregate fields of "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Aggregate_Fields = {
  __typename?: 'MD_MeasurementAttribute_aggregate_fields';
  avg?: Maybe<Md_MeasurementAttribute_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_MeasurementAttribute_Max_Fields>;
  min?: Maybe<Md_MeasurementAttribute_Min_Fields>;
  stddev?: Maybe<Md_MeasurementAttribute_Stddev_Fields>;
  stddev_pop?: Maybe<Md_MeasurementAttribute_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_MeasurementAttribute_Stddev_Samp_Fields>;
  sum?: Maybe<Md_MeasurementAttribute_Sum_Fields>;
  var_pop?: Maybe<Md_MeasurementAttribute_Var_Pop_Fields>;
  var_samp?: Maybe<Md_MeasurementAttribute_Var_Samp_Fields>;
  variance?: Maybe<Md_MeasurementAttribute_Variance_Fields>;
};


/** aggregate fields of "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_MeasurementAttribute_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Aggregate_Order_By = {
  avg?: Maybe<Md_MeasurementAttribute_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Md_MeasurementAttribute_Max_Order_By>;
  min?: Maybe<Md_MeasurementAttribute_Min_Order_By>;
  stddev?: Maybe<Md_MeasurementAttribute_Stddev_Order_By>;
  stddev_pop?: Maybe<Md_MeasurementAttribute_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Md_MeasurementAttribute_Stddev_Samp_Order_By>;
  sum?: Maybe<Md_MeasurementAttribute_Sum_Order_By>;
  var_pop?: Maybe<Md_MeasurementAttribute_Var_Pop_Order_By>;
  var_samp?: Maybe<Md_MeasurementAttribute_Var_Samp_Order_By>;
  variance?: Maybe<Md_MeasurementAttribute_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Md_MeasurementAttribute_Avg_Fields = {
  __typename?: 'MD_MeasurementAttribute_avg_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MeasureId?: Maybe<Scalars['Float']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Float']>;
  NumDecimalPlaces?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Avg_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MeasureId?: Maybe<Order_By>;
  MeasurementDisplayOptionId?: Maybe<Order_By>;
  NumDecimalPlaces?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "MD_MeasurementAttribute". All fields are combined with a logical 'AND'. */
export type Md_MeasurementAttribute_Bool_Exp = {
  AttributeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ColumnName?: Maybe<String_Mssql_Comparison_Exp>;
  EnglishUnit?: Maybe<String_Mssql_Comparison_Exp>;
  MD_AttributeBase?: Maybe<Md_AttributeBase_Bool_Exp>;
  MD_Measure?: Maybe<Md_Measure_Bool_Exp>;
  MD_MeasurementDisplayOption?: Maybe<Md_MeasurementDisplayOption_Bool_Exp>;
  MeasureId?: Maybe<Int_Mssql_Comparison_Exp>;
  MeasurementDisplayOptionId?: Maybe<Int_Mssql_Comparison_Exp>;
  MetricUnit?: Maybe<String_Mssql_Comparison_Exp>;
  NumDecimalPlaces?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Md_MeasurementAttribute_Bool_Exp>>;
  _not?: Maybe<Md_MeasurementAttribute_Bool_Exp>;
  _or?: Maybe<Array<Md_MeasurementAttribute_Bool_Exp>>;
};

/** upsert condition type for table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_If_Matched = {
  match_columns?: Array<Md_MeasurementAttribute_Insert_Match_Column>;
  update_columns?: Array<Md_MeasurementAttribute_Update_Column>;
  where?: Maybe<Md_MeasurementAttribute_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Inc_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
  MeasureId?: Maybe<Scalars['Int']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Int']>;
  NumDecimalPlaces?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Insert_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
  ColumnName?: Maybe<Scalars['String']>;
  EnglishUnit?: Maybe<Scalars['String']>;
  MeasureId?: Maybe<Scalars['Int']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Int']>;
  MetricUnit?: Maybe<Scalars['String']>;
  NumDecimalPlaces?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "MD_MeasurementAttribute" */
export enum Md_MeasurementAttribute_Insert_Match_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  ColumnName = 'ColumnName',
  /** column name */
  EnglishUnit = 'EnglishUnit',
  /** column name */
  MeasureId = 'MeasureId',
  /** column name */
  MeasurementDisplayOptionId = 'MeasurementDisplayOptionId',
  /** column name */
  MetricUnit = 'MetricUnit',
  /** column name */
  NumDecimalPlaces = 'NumDecimalPlaces'
}

/** aggregate max on columns */
export type Md_MeasurementAttribute_Max_Fields = {
  __typename?: 'MD_MeasurementAttribute_max_fields';
  AttributeId?: Maybe<Scalars['Int']>;
  ColumnName?: Maybe<Scalars['String']>;
  EnglishUnit?: Maybe<Scalars['String']>;
  MeasureId?: Maybe<Scalars['Int']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Int']>;
  MetricUnit?: Maybe<Scalars['String']>;
  NumDecimalPlaces?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Max_Order_By = {
  AttributeId?: Maybe<Order_By>;
  ColumnName?: Maybe<Order_By>;
  EnglishUnit?: Maybe<Order_By>;
  MeasureId?: Maybe<Order_By>;
  MeasurementDisplayOptionId?: Maybe<Order_By>;
  MetricUnit?: Maybe<Order_By>;
  NumDecimalPlaces?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Md_MeasurementAttribute_Min_Fields = {
  __typename?: 'MD_MeasurementAttribute_min_fields';
  AttributeId?: Maybe<Scalars['Int']>;
  ColumnName?: Maybe<Scalars['String']>;
  EnglishUnit?: Maybe<Scalars['String']>;
  MeasureId?: Maybe<Scalars['Int']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Int']>;
  MetricUnit?: Maybe<Scalars['String']>;
  NumDecimalPlaces?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Min_Order_By = {
  AttributeId?: Maybe<Order_By>;
  ColumnName?: Maybe<Order_By>;
  EnglishUnit?: Maybe<Order_By>;
  MeasureId?: Maybe<Order_By>;
  MeasurementDisplayOptionId?: Maybe<Order_By>;
  MetricUnit?: Maybe<Order_By>;
  NumDecimalPlaces?: Maybe<Order_By>;
};

/** response of any mutation on the table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Mutation_Response = {
  __typename?: 'MD_MeasurementAttribute_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_MeasurementAttribute>;
};

/** Ordering options when selecting data from "MD_MeasurementAttribute". */
export type Md_MeasurementAttribute_Order_By = {
  AttributeId?: Maybe<Order_By>;
  ColumnName?: Maybe<Order_By>;
  EnglishUnit?: Maybe<Order_By>;
  MD_AttributeBase?: Maybe<Md_AttributeBase_Order_By>;
  MD_Measure?: Maybe<Md_Measure_Order_By>;
  MD_MeasurementDisplayOption?: Maybe<Md_MeasurementDisplayOption_Order_By>;
  MeasureId?: Maybe<Order_By>;
  MeasurementDisplayOptionId?: Maybe<Order_By>;
  MetricUnit?: Maybe<Order_By>;
  NumDecimalPlaces?: Maybe<Order_By>;
};

/** primary key columns input for table: MD_MeasurementAttribute */
export type Md_MeasurementAttribute_Pk_Columns_Input = {
  AttributeId: Scalars['Int'];
};

/** select columns of table "MD_MeasurementAttribute" */
export enum Md_MeasurementAttribute_Select_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  ColumnName = 'ColumnName',
  /** column name */
  EnglishUnit = 'EnglishUnit',
  /** column name */
  MeasureId = 'MeasureId',
  /** column name */
  MeasurementDisplayOptionId = 'MeasurementDisplayOptionId',
  /** column name */
  MetricUnit = 'MetricUnit',
  /** column name */
  NumDecimalPlaces = 'NumDecimalPlaces'
}

/** input type for updating data in table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Set_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
  ColumnName?: Maybe<Scalars['String']>;
  EnglishUnit?: Maybe<Scalars['String']>;
  MeasureId?: Maybe<Scalars['Int']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Int']>;
  MetricUnit?: Maybe<Scalars['String']>;
  NumDecimalPlaces?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Md_MeasurementAttribute_Stddev_Fields = {
  __typename?: 'MD_MeasurementAttribute_stddev_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MeasureId?: Maybe<Scalars['Float']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Float']>;
  NumDecimalPlaces?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Stddev_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MeasureId?: Maybe<Order_By>;
  MeasurementDisplayOptionId?: Maybe<Order_By>;
  NumDecimalPlaces?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Md_MeasurementAttribute_Stddev_Pop_Fields = {
  __typename?: 'MD_MeasurementAttribute_stddev_pop_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MeasureId?: Maybe<Scalars['Float']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Float']>;
  NumDecimalPlaces?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Stddev_Pop_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MeasureId?: Maybe<Order_By>;
  MeasurementDisplayOptionId?: Maybe<Order_By>;
  NumDecimalPlaces?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Md_MeasurementAttribute_Stddev_Samp_Fields = {
  __typename?: 'MD_MeasurementAttribute_stddev_samp_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MeasureId?: Maybe<Scalars['Float']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Float']>;
  NumDecimalPlaces?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Stddev_Samp_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MeasureId?: Maybe<Order_By>;
  MeasurementDisplayOptionId?: Maybe<Order_By>;
  NumDecimalPlaces?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Md_MeasurementAttribute_Sum_Fields = {
  __typename?: 'MD_MeasurementAttribute_sum_fields';
  AttributeId?: Maybe<Scalars['Int']>;
  MeasureId?: Maybe<Scalars['Int']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Int']>;
  NumDecimalPlaces?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Sum_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MeasureId?: Maybe<Order_By>;
  MeasurementDisplayOptionId?: Maybe<Order_By>;
  NumDecimalPlaces?: Maybe<Order_By>;
};

/** update columns of table "MD_MeasurementAttribute" */
export enum Md_MeasurementAttribute_Update_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  ColumnName = 'ColumnName',
  /** column name */
  EnglishUnit = 'EnglishUnit',
  /** column name */
  MeasureId = 'MeasureId',
  /** column name */
  MeasurementDisplayOptionId = 'MeasurementDisplayOptionId',
  /** column name */
  MetricUnit = 'MetricUnit',
  /** column name */
  NumDecimalPlaces = 'NumDecimalPlaces'
}

/** aggregate var_pop on columns */
export type Md_MeasurementAttribute_Var_Pop_Fields = {
  __typename?: 'MD_MeasurementAttribute_var_pop_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MeasureId?: Maybe<Scalars['Float']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Float']>;
  NumDecimalPlaces?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Var_Pop_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MeasureId?: Maybe<Order_By>;
  MeasurementDisplayOptionId?: Maybe<Order_By>;
  NumDecimalPlaces?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Md_MeasurementAttribute_Var_Samp_Fields = {
  __typename?: 'MD_MeasurementAttribute_var_samp_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MeasureId?: Maybe<Scalars['Float']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Float']>;
  NumDecimalPlaces?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Var_Samp_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MeasureId?: Maybe<Order_By>;
  MeasurementDisplayOptionId?: Maybe<Order_By>;
  NumDecimalPlaces?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Md_MeasurementAttribute_Variance_Fields = {
  __typename?: 'MD_MeasurementAttribute_variance_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MeasureId?: Maybe<Scalars['Float']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Float']>;
  NumDecimalPlaces?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "MD_MeasurementAttribute" */
export type Md_MeasurementAttribute_Variance_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MeasureId?: Maybe<Order_By>;
  MeasurementDisplayOptionId?: Maybe<Order_By>;
  NumDecimalPlaces?: Maybe<Order_By>;
};

/** columns and relationships of "MD_MeasurementDisplayOption" */
export type Md_MeasurementDisplayOption = {
  __typename?: 'MD_MeasurementDisplayOption';
  DisplayOption: Scalars['String'];
  /** An array relationship */
  MD_MeasurementAttributes: Array<Md_MeasurementAttribute>;
  /** An aggregate relationship */
  MD_MeasurementAttributes_aggregate: Md_MeasurementAttribute_Aggregate;
  MeasurementDisplayOptionId: Scalars['Int'];
};


/** columns and relationships of "MD_MeasurementDisplayOption" */
export type Md_MeasurementDisplayOptionMd_MeasurementAttributesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MeasurementAttribute_Order_By>>;
  where?: Maybe<Md_MeasurementAttribute_Bool_Exp>;
};


/** columns and relationships of "MD_MeasurementDisplayOption" */
export type Md_MeasurementDisplayOptionMd_MeasurementAttributes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MeasurementAttribute_Order_By>>;
  where?: Maybe<Md_MeasurementAttribute_Bool_Exp>;
};

/** aggregated selection of "MD_MeasurementDisplayOption" */
export type Md_MeasurementDisplayOption_Aggregate = {
  __typename?: 'MD_MeasurementDisplayOption_aggregate';
  aggregate?: Maybe<Md_MeasurementDisplayOption_Aggregate_Fields>;
  nodes: Array<Md_MeasurementDisplayOption>;
};

/** aggregate fields of "MD_MeasurementDisplayOption" */
export type Md_MeasurementDisplayOption_Aggregate_Fields = {
  __typename?: 'MD_MeasurementDisplayOption_aggregate_fields';
  avg?: Maybe<Md_MeasurementDisplayOption_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_MeasurementDisplayOption_Max_Fields>;
  min?: Maybe<Md_MeasurementDisplayOption_Min_Fields>;
  stddev?: Maybe<Md_MeasurementDisplayOption_Stddev_Fields>;
  stddev_pop?: Maybe<Md_MeasurementDisplayOption_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_MeasurementDisplayOption_Stddev_Samp_Fields>;
  sum?: Maybe<Md_MeasurementDisplayOption_Sum_Fields>;
  var_pop?: Maybe<Md_MeasurementDisplayOption_Var_Pop_Fields>;
  var_samp?: Maybe<Md_MeasurementDisplayOption_Var_Samp_Fields>;
  variance?: Maybe<Md_MeasurementDisplayOption_Variance_Fields>;
};


/** aggregate fields of "MD_MeasurementDisplayOption" */
export type Md_MeasurementDisplayOption_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_MeasurementDisplayOption_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Md_MeasurementDisplayOption_Avg_Fields = {
  __typename?: 'MD_MeasurementDisplayOption_avg_fields';
  MeasurementDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MD_MeasurementDisplayOption". All fields are combined with a logical 'AND'. */
export type Md_MeasurementDisplayOption_Bool_Exp = {
  DisplayOption?: Maybe<String_Mssql_Comparison_Exp>;
  MD_MeasurementAttributes?: Maybe<Md_MeasurementAttribute_Bool_Exp>;
  MeasurementDisplayOptionId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Md_MeasurementDisplayOption_Bool_Exp>>;
  _not?: Maybe<Md_MeasurementDisplayOption_Bool_Exp>;
  _or?: Maybe<Array<Md_MeasurementDisplayOption_Bool_Exp>>;
};

/** upsert condition type for table "MD_MeasurementDisplayOption" */
export type Md_MeasurementDisplayOption_If_Matched = {
  match_columns?: Array<Md_MeasurementDisplayOption_Insert_Match_Column>;
  update_columns?: Array<Md_MeasurementDisplayOption_Update_Column>;
  where?: Maybe<Md_MeasurementDisplayOption_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_MeasurementDisplayOption" */
export type Md_MeasurementDisplayOption_Inc_Input = {
  MeasurementDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_MeasurementDisplayOption" */
export type Md_MeasurementDisplayOption_Insert_Input = {
  DisplayOption?: Maybe<Scalars['String']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "MD_MeasurementDisplayOption" */
export enum Md_MeasurementDisplayOption_Insert_Match_Column {
  /** column name */
  DisplayOption = 'DisplayOption',
  /** column name */
  MeasurementDisplayOptionId = 'MeasurementDisplayOptionId'
}

/** aggregate max on columns */
export type Md_MeasurementDisplayOption_Max_Fields = {
  __typename?: 'MD_MeasurementDisplayOption_max_fields';
  DisplayOption?: Maybe<Scalars['String']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Md_MeasurementDisplayOption_Min_Fields = {
  __typename?: 'MD_MeasurementDisplayOption_min_fields';
  DisplayOption?: Maybe<Scalars['String']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "MD_MeasurementDisplayOption" */
export type Md_MeasurementDisplayOption_Mutation_Response = {
  __typename?: 'MD_MeasurementDisplayOption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_MeasurementDisplayOption>;
};

/** Ordering options when selecting data from "MD_MeasurementDisplayOption". */
export type Md_MeasurementDisplayOption_Order_By = {
  DisplayOption?: Maybe<Order_By>;
  MD_MeasurementAttributes_aggregate?: Maybe<Md_MeasurementAttribute_Aggregate_Order_By>;
  MeasurementDisplayOptionId?: Maybe<Order_By>;
};

/** primary key columns input for table: MD_MeasurementDisplayOption */
export type Md_MeasurementDisplayOption_Pk_Columns_Input = {
  MeasurementDisplayOptionId: Scalars['Int'];
};

/** select columns of table "MD_MeasurementDisplayOption" */
export enum Md_MeasurementDisplayOption_Select_Column {
  /** column name */
  DisplayOption = 'DisplayOption',
  /** column name */
  MeasurementDisplayOptionId = 'MeasurementDisplayOptionId'
}

/** input type for updating data in table "MD_MeasurementDisplayOption" */
export type Md_MeasurementDisplayOption_Set_Input = {
  DisplayOption?: Maybe<Scalars['String']>;
  MeasurementDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Md_MeasurementDisplayOption_Stddev_Fields = {
  __typename?: 'MD_MeasurementDisplayOption_stddev_fields';
  MeasurementDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Md_MeasurementDisplayOption_Stddev_Pop_Fields = {
  __typename?: 'MD_MeasurementDisplayOption_stddev_pop_fields';
  MeasurementDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Md_MeasurementDisplayOption_Stddev_Samp_Fields = {
  __typename?: 'MD_MeasurementDisplayOption_stddev_samp_fields';
  MeasurementDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Md_MeasurementDisplayOption_Sum_Fields = {
  __typename?: 'MD_MeasurementDisplayOption_sum_fields';
  MeasurementDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** update columns of table "MD_MeasurementDisplayOption" */
export enum Md_MeasurementDisplayOption_Update_Column {
  /** column name */
  DisplayOption = 'DisplayOption',
  /** column name */
  MeasurementDisplayOptionId = 'MeasurementDisplayOptionId'
}

/** aggregate var_pop on columns */
export type Md_MeasurementDisplayOption_Var_Pop_Fields = {
  __typename?: 'MD_MeasurementDisplayOption_var_pop_fields';
  MeasurementDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Md_MeasurementDisplayOption_Var_Samp_Fields = {
  __typename?: 'MD_MeasurementDisplayOption_var_samp_fields';
  MeasurementDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Md_MeasurementDisplayOption_Variance_Fields = {
  __typename?: 'MD_MeasurementDisplayOption_variance_fields';
  MeasurementDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute = {
  __typename?: 'MD_MultivalueAttribute';
  AttributeId: Scalars['Int'];
  CodeTableDisplayColumn: Scalars['String'];
  CodeTableJunctionKey: Scalars['String'];
  CodeTableName: Scalars['String'];
  CodeTableValueColumn: Scalars['String'];
  EntityTableJunctionKey: Scalars['String'];
  JunctionTable: Scalars['String'];
  /** An object relationship */
  MD_AttributeBase: Md_AttributeBase;
  /** An object relationship */
  MD_MultivalueDisplayOption: Md_MultivalueDisplayOption;
  MultivalueDisplayOptionId: Scalars['Int'];
};

/** aggregated selection of "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Aggregate = {
  __typename?: 'MD_MultivalueAttribute_aggregate';
  aggregate?: Maybe<Md_MultivalueAttribute_Aggregate_Fields>;
  nodes: Array<Md_MultivalueAttribute>;
};

/** aggregate fields of "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Aggregate_Fields = {
  __typename?: 'MD_MultivalueAttribute_aggregate_fields';
  avg?: Maybe<Md_MultivalueAttribute_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_MultivalueAttribute_Max_Fields>;
  min?: Maybe<Md_MultivalueAttribute_Min_Fields>;
  stddev?: Maybe<Md_MultivalueAttribute_Stddev_Fields>;
  stddev_pop?: Maybe<Md_MultivalueAttribute_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_MultivalueAttribute_Stddev_Samp_Fields>;
  sum?: Maybe<Md_MultivalueAttribute_Sum_Fields>;
  var_pop?: Maybe<Md_MultivalueAttribute_Var_Pop_Fields>;
  var_samp?: Maybe<Md_MultivalueAttribute_Var_Samp_Fields>;
  variance?: Maybe<Md_MultivalueAttribute_Variance_Fields>;
};


/** aggregate fields of "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_MultivalueAttribute_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Aggregate_Order_By = {
  avg?: Maybe<Md_MultivalueAttribute_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Md_MultivalueAttribute_Max_Order_By>;
  min?: Maybe<Md_MultivalueAttribute_Min_Order_By>;
  stddev?: Maybe<Md_MultivalueAttribute_Stddev_Order_By>;
  stddev_pop?: Maybe<Md_MultivalueAttribute_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Md_MultivalueAttribute_Stddev_Samp_Order_By>;
  sum?: Maybe<Md_MultivalueAttribute_Sum_Order_By>;
  var_pop?: Maybe<Md_MultivalueAttribute_Var_Pop_Order_By>;
  var_samp?: Maybe<Md_MultivalueAttribute_Var_Samp_Order_By>;
  variance?: Maybe<Md_MultivalueAttribute_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Md_MultivalueAttribute_Avg_Fields = {
  __typename?: 'MD_MultivalueAttribute_avg_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Avg_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MultivalueDisplayOptionId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "MD_MultivalueAttribute". All fields are combined with a logical 'AND'. */
export type Md_MultivalueAttribute_Bool_Exp = {
  AttributeId?: Maybe<Int_Mssql_Comparison_Exp>;
  CodeTableDisplayColumn?: Maybe<String_Mssql_Comparison_Exp>;
  CodeTableJunctionKey?: Maybe<String_Mssql_Comparison_Exp>;
  CodeTableName?: Maybe<String_Mssql_Comparison_Exp>;
  CodeTableValueColumn?: Maybe<String_Mssql_Comparison_Exp>;
  EntityTableJunctionKey?: Maybe<String_Mssql_Comparison_Exp>;
  JunctionTable?: Maybe<String_Mssql_Comparison_Exp>;
  MD_AttributeBase?: Maybe<Md_AttributeBase_Bool_Exp>;
  MD_MultivalueDisplayOption?: Maybe<Md_MultivalueDisplayOption_Bool_Exp>;
  MultivalueDisplayOptionId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Md_MultivalueAttribute_Bool_Exp>>;
  _not?: Maybe<Md_MultivalueAttribute_Bool_Exp>;
  _or?: Maybe<Array<Md_MultivalueAttribute_Bool_Exp>>;
};

/** upsert condition type for table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_If_Matched = {
  match_columns?: Array<Md_MultivalueAttribute_Insert_Match_Column>;
  update_columns?: Array<Md_MultivalueAttribute_Update_Column>;
  where?: Maybe<Md_MultivalueAttribute_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Inc_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Insert_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
  CodeTableDisplayColumn?: Maybe<Scalars['String']>;
  CodeTableJunctionKey?: Maybe<Scalars['String']>;
  CodeTableName?: Maybe<Scalars['String']>;
  CodeTableValueColumn?: Maybe<Scalars['String']>;
  EntityTableJunctionKey?: Maybe<Scalars['String']>;
  JunctionTable?: Maybe<Scalars['String']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "MD_MultivalueAttribute" */
export enum Md_MultivalueAttribute_Insert_Match_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  CodeTableDisplayColumn = 'CodeTableDisplayColumn',
  /** column name */
  CodeTableJunctionKey = 'CodeTableJunctionKey',
  /** column name */
  CodeTableName = 'CodeTableName',
  /** column name */
  CodeTableValueColumn = 'CodeTableValueColumn',
  /** column name */
  EntityTableJunctionKey = 'EntityTableJunctionKey',
  /** column name */
  JunctionTable = 'JunctionTable',
  /** column name */
  MultivalueDisplayOptionId = 'MultivalueDisplayOptionId'
}

/** aggregate max on columns */
export type Md_MultivalueAttribute_Max_Fields = {
  __typename?: 'MD_MultivalueAttribute_max_fields';
  AttributeId?: Maybe<Scalars['Int']>;
  CodeTableDisplayColumn?: Maybe<Scalars['String']>;
  CodeTableJunctionKey?: Maybe<Scalars['String']>;
  CodeTableName?: Maybe<Scalars['String']>;
  CodeTableValueColumn?: Maybe<Scalars['String']>;
  EntityTableJunctionKey?: Maybe<Scalars['String']>;
  JunctionTable?: Maybe<Scalars['String']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Max_Order_By = {
  AttributeId?: Maybe<Order_By>;
  CodeTableDisplayColumn?: Maybe<Order_By>;
  CodeTableJunctionKey?: Maybe<Order_By>;
  CodeTableName?: Maybe<Order_By>;
  CodeTableValueColumn?: Maybe<Order_By>;
  EntityTableJunctionKey?: Maybe<Order_By>;
  JunctionTable?: Maybe<Order_By>;
  MultivalueDisplayOptionId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Md_MultivalueAttribute_Min_Fields = {
  __typename?: 'MD_MultivalueAttribute_min_fields';
  AttributeId?: Maybe<Scalars['Int']>;
  CodeTableDisplayColumn?: Maybe<Scalars['String']>;
  CodeTableJunctionKey?: Maybe<Scalars['String']>;
  CodeTableName?: Maybe<Scalars['String']>;
  CodeTableValueColumn?: Maybe<Scalars['String']>;
  EntityTableJunctionKey?: Maybe<Scalars['String']>;
  JunctionTable?: Maybe<Scalars['String']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Min_Order_By = {
  AttributeId?: Maybe<Order_By>;
  CodeTableDisplayColumn?: Maybe<Order_By>;
  CodeTableJunctionKey?: Maybe<Order_By>;
  CodeTableName?: Maybe<Order_By>;
  CodeTableValueColumn?: Maybe<Order_By>;
  EntityTableJunctionKey?: Maybe<Order_By>;
  JunctionTable?: Maybe<Order_By>;
  MultivalueDisplayOptionId?: Maybe<Order_By>;
};

/** response of any mutation on the table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Mutation_Response = {
  __typename?: 'MD_MultivalueAttribute_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_MultivalueAttribute>;
};

/** Ordering options when selecting data from "MD_MultivalueAttribute". */
export type Md_MultivalueAttribute_Order_By = {
  AttributeId?: Maybe<Order_By>;
  CodeTableDisplayColumn?: Maybe<Order_By>;
  CodeTableJunctionKey?: Maybe<Order_By>;
  CodeTableName?: Maybe<Order_By>;
  CodeTableValueColumn?: Maybe<Order_By>;
  EntityTableJunctionKey?: Maybe<Order_By>;
  JunctionTable?: Maybe<Order_By>;
  MD_AttributeBase?: Maybe<Md_AttributeBase_Order_By>;
  MD_MultivalueDisplayOption?: Maybe<Md_MultivalueDisplayOption_Order_By>;
  MultivalueDisplayOptionId?: Maybe<Order_By>;
};

/** primary key columns input for table: MD_MultivalueAttribute */
export type Md_MultivalueAttribute_Pk_Columns_Input = {
  AttributeId: Scalars['Int'];
};

/** select columns of table "MD_MultivalueAttribute" */
export enum Md_MultivalueAttribute_Select_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  CodeTableDisplayColumn = 'CodeTableDisplayColumn',
  /** column name */
  CodeTableJunctionKey = 'CodeTableJunctionKey',
  /** column name */
  CodeTableName = 'CodeTableName',
  /** column name */
  CodeTableValueColumn = 'CodeTableValueColumn',
  /** column name */
  EntityTableJunctionKey = 'EntityTableJunctionKey',
  /** column name */
  JunctionTable = 'JunctionTable',
  /** column name */
  MultivalueDisplayOptionId = 'MultivalueDisplayOptionId'
}

/** input type for updating data in table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Set_Input = {
  AttributeId?: Maybe<Scalars['Int']>;
  CodeTableDisplayColumn?: Maybe<Scalars['String']>;
  CodeTableJunctionKey?: Maybe<Scalars['String']>;
  CodeTableName?: Maybe<Scalars['String']>;
  CodeTableValueColumn?: Maybe<Scalars['String']>;
  EntityTableJunctionKey?: Maybe<Scalars['String']>;
  JunctionTable?: Maybe<Scalars['String']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Md_MultivalueAttribute_Stddev_Fields = {
  __typename?: 'MD_MultivalueAttribute_stddev_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Stddev_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MultivalueDisplayOptionId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Md_MultivalueAttribute_Stddev_Pop_Fields = {
  __typename?: 'MD_MultivalueAttribute_stddev_pop_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Stddev_Pop_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MultivalueDisplayOptionId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Md_MultivalueAttribute_Stddev_Samp_Fields = {
  __typename?: 'MD_MultivalueAttribute_stddev_samp_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Stddev_Samp_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MultivalueDisplayOptionId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Md_MultivalueAttribute_Sum_Fields = {
  __typename?: 'MD_MultivalueAttribute_sum_fields';
  AttributeId?: Maybe<Scalars['Int']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Sum_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MultivalueDisplayOptionId?: Maybe<Order_By>;
};

/** update columns of table "MD_MultivalueAttribute" */
export enum Md_MultivalueAttribute_Update_Column {
  /** column name */
  AttributeId = 'AttributeId',
  /** column name */
  CodeTableDisplayColumn = 'CodeTableDisplayColumn',
  /** column name */
  CodeTableJunctionKey = 'CodeTableJunctionKey',
  /** column name */
  CodeTableName = 'CodeTableName',
  /** column name */
  CodeTableValueColumn = 'CodeTableValueColumn',
  /** column name */
  EntityTableJunctionKey = 'EntityTableJunctionKey',
  /** column name */
  JunctionTable = 'JunctionTable',
  /** column name */
  MultivalueDisplayOptionId = 'MultivalueDisplayOptionId'
}

/** aggregate var_pop on columns */
export type Md_MultivalueAttribute_Var_Pop_Fields = {
  __typename?: 'MD_MultivalueAttribute_var_pop_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Var_Pop_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MultivalueDisplayOptionId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Md_MultivalueAttribute_Var_Samp_Fields = {
  __typename?: 'MD_MultivalueAttribute_var_samp_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Var_Samp_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MultivalueDisplayOptionId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Md_MultivalueAttribute_Variance_Fields = {
  __typename?: 'MD_MultivalueAttribute_variance_fields';
  AttributeId?: Maybe<Scalars['Float']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "MD_MultivalueAttribute" */
export type Md_MultivalueAttribute_Variance_Order_By = {
  AttributeId?: Maybe<Order_By>;
  MultivalueDisplayOptionId?: Maybe<Order_By>;
};

/** columns and relationships of "MD_MultivalueDisplayOption" */
export type Md_MultivalueDisplayOption = {
  __typename?: 'MD_MultivalueDisplayOption';
  DisplayOption: Scalars['String'];
  /** An array relationship */
  MD_MultivalueAttributes: Array<Md_MultivalueAttribute>;
  /** An aggregate relationship */
  MD_MultivalueAttributes_aggregate: Md_MultivalueAttribute_Aggregate;
  MultivalueDisplayOptionId: Scalars['Int'];
};


/** columns and relationships of "MD_MultivalueDisplayOption" */
export type Md_MultivalueDisplayOptionMd_MultivalueAttributesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MultivalueAttribute_Order_By>>;
  where?: Maybe<Md_MultivalueAttribute_Bool_Exp>;
};


/** columns and relationships of "MD_MultivalueDisplayOption" */
export type Md_MultivalueDisplayOptionMd_MultivalueAttributes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MultivalueAttribute_Order_By>>;
  where?: Maybe<Md_MultivalueAttribute_Bool_Exp>;
};

/** aggregated selection of "MD_MultivalueDisplayOption" */
export type Md_MultivalueDisplayOption_Aggregate = {
  __typename?: 'MD_MultivalueDisplayOption_aggregate';
  aggregate?: Maybe<Md_MultivalueDisplayOption_Aggregate_Fields>;
  nodes: Array<Md_MultivalueDisplayOption>;
};

/** aggregate fields of "MD_MultivalueDisplayOption" */
export type Md_MultivalueDisplayOption_Aggregate_Fields = {
  __typename?: 'MD_MultivalueDisplayOption_aggregate_fields';
  avg?: Maybe<Md_MultivalueDisplayOption_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_MultivalueDisplayOption_Max_Fields>;
  min?: Maybe<Md_MultivalueDisplayOption_Min_Fields>;
  stddev?: Maybe<Md_MultivalueDisplayOption_Stddev_Fields>;
  stddev_pop?: Maybe<Md_MultivalueDisplayOption_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_MultivalueDisplayOption_Stddev_Samp_Fields>;
  sum?: Maybe<Md_MultivalueDisplayOption_Sum_Fields>;
  var_pop?: Maybe<Md_MultivalueDisplayOption_Var_Pop_Fields>;
  var_samp?: Maybe<Md_MultivalueDisplayOption_Var_Samp_Fields>;
  variance?: Maybe<Md_MultivalueDisplayOption_Variance_Fields>;
};


/** aggregate fields of "MD_MultivalueDisplayOption" */
export type Md_MultivalueDisplayOption_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_MultivalueDisplayOption_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Md_MultivalueDisplayOption_Avg_Fields = {
  __typename?: 'MD_MultivalueDisplayOption_avg_fields';
  MultivalueDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MD_MultivalueDisplayOption". All fields are combined with a logical 'AND'. */
export type Md_MultivalueDisplayOption_Bool_Exp = {
  DisplayOption?: Maybe<String_Mssql_Comparison_Exp>;
  MD_MultivalueAttributes?: Maybe<Md_MultivalueAttribute_Bool_Exp>;
  MultivalueDisplayOptionId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Md_MultivalueDisplayOption_Bool_Exp>>;
  _not?: Maybe<Md_MultivalueDisplayOption_Bool_Exp>;
  _or?: Maybe<Array<Md_MultivalueDisplayOption_Bool_Exp>>;
};

/** upsert condition type for table "MD_MultivalueDisplayOption" */
export type Md_MultivalueDisplayOption_If_Matched = {
  match_columns?: Array<Md_MultivalueDisplayOption_Insert_Match_Column>;
  update_columns?: Array<Md_MultivalueDisplayOption_Update_Column>;
  where?: Maybe<Md_MultivalueDisplayOption_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_MultivalueDisplayOption" */
export type Md_MultivalueDisplayOption_Inc_Input = {
  MultivalueDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_MultivalueDisplayOption" */
export type Md_MultivalueDisplayOption_Insert_Input = {
  DisplayOption?: Maybe<Scalars['String']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "MD_MultivalueDisplayOption" */
export enum Md_MultivalueDisplayOption_Insert_Match_Column {
  /** column name */
  DisplayOption = 'DisplayOption',
  /** column name */
  MultivalueDisplayOptionId = 'MultivalueDisplayOptionId'
}

/** aggregate max on columns */
export type Md_MultivalueDisplayOption_Max_Fields = {
  __typename?: 'MD_MultivalueDisplayOption_max_fields';
  DisplayOption?: Maybe<Scalars['String']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Md_MultivalueDisplayOption_Min_Fields = {
  __typename?: 'MD_MultivalueDisplayOption_min_fields';
  DisplayOption?: Maybe<Scalars['String']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "MD_MultivalueDisplayOption" */
export type Md_MultivalueDisplayOption_Mutation_Response = {
  __typename?: 'MD_MultivalueDisplayOption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_MultivalueDisplayOption>;
};

/** Ordering options when selecting data from "MD_MultivalueDisplayOption". */
export type Md_MultivalueDisplayOption_Order_By = {
  DisplayOption?: Maybe<Order_By>;
  MD_MultivalueAttributes_aggregate?: Maybe<Md_MultivalueAttribute_Aggregate_Order_By>;
  MultivalueDisplayOptionId?: Maybe<Order_By>;
};

/** primary key columns input for table: MD_MultivalueDisplayOption */
export type Md_MultivalueDisplayOption_Pk_Columns_Input = {
  MultivalueDisplayOptionId: Scalars['Int'];
};

/** select columns of table "MD_MultivalueDisplayOption" */
export enum Md_MultivalueDisplayOption_Select_Column {
  /** column name */
  DisplayOption = 'DisplayOption',
  /** column name */
  MultivalueDisplayOptionId = 'MultivalueDisplayOptionId'
}

/** input type for updating data in table "MD_MultivalueDisplayOption" */
export type Md_MultivalueDisplayOption_Set_Input = {
  DisplayOption?: Maybe<Scalars['String']>;
  MultivalueDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Md_MultivalueDisplayOption_Stddev_Fields = {
  __typename?: 'MD_MultivalueDisplayOption_stddev_fields';
  MultivalueDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Md_MultivalueDisplayOption_Stddev_Pop_Fields = {
  __typename?: 'MD_MultivalueDisplayOption_stddev_pop_fields';
  MultivalueDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Md_MultivalueDisplayOption_Stddev_Samp_Fields = {
  __typename?: 'MD_MultivalueDisplayOption_stddev_samp_fields';
  MultivalueDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Md_MultivalueDisplayOption_Sum_Fields = {
  __typename?: 'MD_MultivalueDisplayOption_sum_fields';
  MultivalueDisplayOptionId?: Maybe<Scalars['Int']>;
};

/** update columns of table "MD_MultivalueDisplayOption" */
export enum Md_MultivalueDisplayOption_Update_Column {
  /** column name */
  DisplayOption = 'DisplayOption',
  /** column name */
  MultivalueDisplayOptionId = 'MultivalueDisplayOptionId'
}

/** aggregate var_pop on columns */
export type Md_MultivalueDisplayOption_Var_Pop_Fields = {
  __typename?: 'MD_MultivalueDisplayOption_var_pop_fields';
  MultivalueDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Md_MultivalueDisplayOption_Var_Samp_Fields = {
  __typename?: 'MD_MultivalueDisplayOption_var_samp_fields';
  MultivalueDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Md_MultivalueDisplayOption_Variance_Fields = {
  __typename?: 'MD_MultivalueDisplayOption_variance_fields';
  MultivalueDisplayOptionId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "MD_ProductCategory" */
export type Md_ProductCategory = {
  __typename?: 'MD_ProductCategory';
  CategoryId: Scalars['Int'];
  CategoryName: Scalars['String'];
  /** An array relationship */
  MD_ProductSubcategories: Array<Md_ProductSubcategory>;
  /** An aggregate relationship */
  MD_ProductSubcategories_aggregate: Md_ProductSubcategory_Aggregate;
  SearchView: Scalars['String'];
  TypedViewClass: Scalars['String'];
};


/** columns and relationships of "MD_ProductCategory" */
export type Md_ProductCategoryMd_ProductSubcategoriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ProductSubcategory_Order_By>>;
  where?: Maybe<Md_ProductSubcategory_Bool_Exp>;
};


/** columns and relationships of "MD_ProductCategory" */
export type Md_ProductCategoryMd_ProductSubcategories_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ProductSubcategory_Order_By>>;
  where?: Maybe<Md_ProductSubcategory_Bool_Exp>;
};

/** aggregated selection of "MD_ProductCategory" */
export type Md_ProductCategory_Aggregate = {
  __typename?: 'MD_ProductCategory_aggregate';
  aggregate?: Maybe<Md_ProductCategory_Aggregate_Fields>;
  nodes: Array<Md_ProductCategory>;
};

/** aggregate fields of "MD_ProductCategory" */
export type Md_ProductCategory_Aggregate_Fields = {
  __typename?: 'MD_ProductCategory_aggregate_fields';
  avg?: Maybe<Md_ProductCategory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_ProductCategory_Max_Fields>;
  min?: Maybe<Md_ProductCategory_Min_Fields>;
  stddev?: Maybe<Md_ProductCategory_Stddev_Fields>;
  stddev_pop?: Maybe<Md_ProductCategory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_ProductCategory_Stddev_Samp_Fields>;
  sum?: Maybe<Md_ProductCategory_Sum_Fields>;
  var_pop?: Maybe<Md_ProductCategory_Var_Pop_Fields>;
  var_samp?: Maybe<Md_ProductCategory_Var_Samp_Fields>;
  variance?: Maybe<Md_ProductCategory_Variance_Fields>;
};


/** aggregate fields of "MD_ProductCategory" */
export type Md_ProductCategory_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_ProductCategory_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Md_ProductCategory_Avg_Fields = {
  __typename?: 'MD_ProductCategory_avg_fields';
  CategoryId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MD_ProductCategory". All fields are combined with a logical 'AND'. */
export type Md_ProductCategory_Bool_Exp = {
  CategoryId?: Maybe<Int_Mssql_Comparison_Exp>;
  CategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  MD_ProductSubcategories?: Maybe<Md_ProductSubcategory_Bool_Exp>;
  SearchView?: Maybe<String_Mssql_Comparison_Exp>;
  TypedViewClass?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Md_ProductCategory_Bool_Exp>>;
  _not?: Maybe<Md_ProductCategory_Bool_Exp>;
  _or?: Maybe<Array<Md_ProductCategory_Bool_Exp>>;
};

/** upsert condition type for table "MD_ProductCategory" */
export type Md_ProductCategory_If_Matched = {
  match_columns?: Array<Md_ProductCategory_Insert_Match_Column>;
  update_columns?: Array<Md_ProductCategory_Update_Column>;
  where?: Maybe<Md_ProductCategory_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_ProductCategory" */
export type Md_ProductCategory_Inc_Input = {
  CategoryId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_ProductCategory" */
export type Md_ProductCategory_Insert_Input = {
  CategoryId?: Maybe<Scalars['Int']>;
  CategoryName?: Maybe<Scalars['String']>;
  SearchView?: Maybe<Scalars['String']>;
  TypedViewClass?: Maybe<Scalars['String']>;
};

/** select match_columns of table "MD_ProductCategory" */
export enum Md_ProductCategory_Insert_Match_Column {
  /** column name */
  CategoryId = 'CategoryId',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  SearchView = 'SearchView',
  /** column name */
  TypedViewClass = 'TypedViewClass'
}

/** aggregate max on columns */
export type Md_ProductCategory_Max_Fields = {
  __typename?: 'MD_ProductCategory_max_fields';
  CategoryId?: Maybe<Scalars['Int']>;
  CategoryName?: Maybe<Scalars['String']>;
  SearchView?: Maybe<Scalars['String']>;
  TypedViewClass?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Md_ProductCategory_Min_Fields = {
  __typename?: 'MD_ProductCategory_min_fields';
  CategoryId?: Maybe<Scalars['Int']>;
  CategoryName?: Maybe<Scalars['String']>;
  SearchView?: Maybe<Scalars['String']>;
  TypedViewClass?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "MD_ProductCategory" */
export type Md_ProductCategory_Mutation_Response = {
  __typename?: 'MD_ProductCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_ProductCategory>;
};

/** Ordering options when selecting data from "MD_ProductCategory". */
export type Md_ProductCategory_Order_By = {
  CategoryId?: Maybe<Order_By>;
  CategoryName?: Maybe<Order_By>;
  MD_ProductSubcategories_aggregate?: Maybe<Md_ProductSubcategory_Aggregate_Order_By>;
  SearchView?: Maybe<Order_By>;
  TypedViewClass?: Maybe<Order_By>;
};

/** primary key columns input for table: MD_ProductCategory */
export type Md_ProductCategory_Pk_Columns_Input = {
  CategoryId: Scalars['Int'];
};

/** select columns of table "MD_ProductCategory" */
export enum Md_ProductCategory_Select_Column {
  /** column name */
  CategoryId = 'CategoryId',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  SearchView = 'SearchView',
  /** column name */
  TypedViewClass = 'TypedViewClass'
}

/** input type for updating data in table "MD_ProductCategory" */
export type Md_ProductCategory_Set_Input = {
  CategoryId?: Maybe<Scalars['Int']>;
  CategoryName?: Maybe<Scalars['String']>;
  SearchView?: Maybe<Scalars['String']>;
  TypedViewClass?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Md_ProductCategory_Stddev_Fields = {
  __typename?: 'MD_ProductCategory_stddev_fields';
  CategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Md_ProductCategory_Stddev_Pop_Fields = {
  __typename?: 'MD_ProductCategory_stddev_pop_fields';
  CategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Md_ProductCategory_Stddev_Samp_Fields = {
  __typename?: 'MD_ProductCategory_stddev_samp_fields';
  CategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Md_ProductCategory_Sum_Fields = {
  __typename?: 'MD_ProductCategory_sum_fields';
  CategoryId?: Maybe<Scalars['Int']>;
};

/** update columns of table "MD_ProductCategory" */
export enum Md_ProductCategory_Update_Column {
  /** column name */
  CategoryId = 'CategoryId',
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  SearchView = 'SearchView',
  /** column name */
  TypedViewClass = 'TypedViewClass'
}

/** aggregate var_pop on columns */
export type Md_ProductCategory_Var_Pop_Fields = {
  __typename?: 'MD_ProductCategory_var_pop_fields';
  CategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Md_ProductCategory_Var_Samp_Fields = {
  __typename?: 'MD_ProductCategory_var_samp_fields';
  CategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Md_ProductCategory_Variance_Fields = {
  __typename?: 'MD_ProductCategory_variance_fields';
  CategoryId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "MD_ProductSubcategory" */
export type Md_ProductSubcategory = {
  __typename?: 'MD_ProductSubcategory';
  CategoryId: Scalars['Int'];
  /** An object relationship */
  MD_ProductCategory: Md_ProductCategory;
  /** An array relationship */
  R_ApprovedUse_Subcategories: Array<R_ApprovedUse_Subcategory>;
  /** An aggregate relationship */
  R_ApprovedUse_Subcategories_aggregate: R_ApprovedUse_Subcategory_Aggregate;
  SearchView: Scalars['String'];
  SubcategoryId: Scalars['Int'];
  SubcategoryName: Scalars['String'];
  TypedViewClass: Scalars['String'];
};


/** columns and relationships of "MD_ProductSubcategory" */
export type Md_ProductSubcategoryR_ApprovedUse_SubcategoriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ApprovedUse_Subcategory_Order_By>>;
  where?: Maybe<R_ApprovedUse_Subcategory_Bool_Exp>;
};


/** columns and relationships of "MD_ProductSubcategory" */
export type Md_ProductSubcategoryR_ApprovedUse_Subcategories_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ApprovedUse_Subcategory_Order_By>>;
  where?: Maybe<R_ApprovedUse_Subcategory_Bool_Exp>;
};

/** aggregated selection of "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Aggregate = {
  __typename?: 'MD_ProductSubcategory_aggregate';
  aggregate?: Maybe<Md_ProductSubcategory_Aggregate_Fields>;
  nodes: Array<Md_ProductSubcategory>;
};

/** aggregate fields of "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Aggregate_Fields = {
  __typename?: 'MD_ProductSubcategory_aggregate_fields';
  avg?: Maybe<Md_ProductSubcategory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Md_ProductSubcategory_Max_Fields>;
  min?: Maybe<Md_ProductSubcategory_Min_Fields>;
  stddev?: Maybe<Md_ProductSubcategory_Stddev_Fields>;
  stddev_pop?: Maybe<Md_ProductSubcategory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Md_ProductSubcategory_Stddev_Samp_Fields>;
  sum?: Maybe<Md_ProductSubcategory_Sum_Fields>;
  var_pop?: Maybe<Md_ProductSubcategory_Var_Pop_Fields>;
  var_samp?: Maybe<Md_ProductSubcategory_Var_Samp_Fields>;
  variance?: Maybe<Md_ProductSubcategory_Variance_Fields>;
};


/** aggregate fields of "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Aggregate_FieldsCountArgs = {
  column?: Maybe<Md_ProductSubcategory_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Aggregate_Order_By = {
  avg?: Maybe<Md_ProductSubcategory_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Md_ProductSubcategory_Max_Order_By>;
  min?: Maybe<Md_ProductSubcategory_Min_Order_By>;
  stddev?: Maybe<Md_ProductSubcategory_Stddev_Order_By>;
  stddev_pop?: Maybe<Md_ProductSubcategory_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Md_ProductSubcategory_Stddev_Samp_Order_By>;
  sum?: Maybe<Md_ProductSubcategory_Sum_Order_By>;
  var_pop?: Maybe<Md_ProductSubcategory_Var_Pop_Order_By>;
  var_samp?: Maybe<Md_ProductSubcategory_Var_Samp_Order_By>;
  variance?: Maybe<Md_ProductSubcategory_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Md_ProductSubcategory_Avg_Fields = {
  __typename?: 'MD_ProductSubcategory_avg_fields';
  CategoryId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Avg_Order_By = {
  CategoryId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "MD_ProductSubcategory". All fields are combined with a logical 'AND'. */
export type Md_ProductSubcategory_Bool_Exp = {
  CategoryId?: Maybe<Int_Mssql_Comparison_Exp>;
  MD_ProductCategory?: Maybe<Md_ProductCategory_Bool_Exp>;
  R_ApprovedUse_Subcategories?: Maybe<R_ApprovedUse_Subcategory_Bool_Exp>;
  SearchView?: Maybe<String_Mssql_Comparison_Exp>;
  SubcategoryId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubcategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  TypedViewClass?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Md_ProductSubcategory_Bool_Exp>>;
  _not?: Maybe<Md_ProductSubcategory_Bool_Exp>;
  _or?: Maybe<Array<Md_ProductSubcategory_Bool_Exp>>;
};

/** upsert condition type for table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_If_Matched = {
  match_columns?: Array<Md_ProductSubcategory_Insert_Match_Column>;
  update_columns?: Array<Md_ProductSubcategory_Update_Column>;
  where?: Maybe<Md_ProductSubcategory_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Inc_Input = {
  CategoryId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Insert_Input = {
  CategoryId?: Maybe<Scalars['Int']>;
  SearchView?: Maybe<Scalars['String']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TypedViewClass?: Maybe<Scalars['String']>;
};

/** select match_columns of table "MD_ProductSubcategory" */
export enum Md_ProductSubcategory_Insert_Match_Column {
  /** column name */
  CategoryId = 'CategoryId',
  /** column name */
  SearchView = 'SearchView',
  /** column name */
  SubcategoryId = 'SubcategoryId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TypedViewClass = 'TypedViewClass'
}

/** aggregate max on columns */
export type Md_ProductSubcategory_Max_Fields = {
  __typename?: 'MD_ProductSubcategory_max_fields';
  CategoryId?: Maybe<Scalars['Int']>;
  SearchView?: Maybe<Scalars['String']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TypedViewClass?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Max_Order_By = {
  CategoryId?: Maybe<Order_By>;
  SearchView?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
  SubcategoryName?: Maybe<Order_By>;
  TypedViewClass?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Md_ProductSubcategory_Min_Fields = {
  __typename?: 'MD_ProductSubcategory_min_fields';
  CategoryId?: Maybe<Scalars['Int']>;
  SearchView?: Maybe<Scalars['String']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TypedViewClass?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Min_Order_By = {
  CategoryId?: Maybe<Order_By>;
  SearchView?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
  SubcategoryName?: Maybe<Order_By>;
  TypedViewClass?: Maybe<Order_By>;
};

/** response of any mutation on the table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Mutation_Response = {
  __typename?: 'MD_ProductSubcategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Md_ProductSubcategory>;
};

/** Ordering options when selecting data from "MD_ProductSubcategory". */
export type Md_ProductSubcategory_Order_By = {
  CategoryId?: Maybe<Order_By>;
  MD_ProductCategory?: Maybe<Md_ProductCategory_Order_By>;
  R_ApprovedUse_Subcategories_aggregate?: Maybe<R_ApprovedUse_Subcategory_Aggregate_Order_By>;
  SearchView?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
  SubcategoryName?: Maybe<Order_By>;
  TypedViewClass?: Maybe<Order_By>;
};

/** primary key columns input for table: MD_ProductSubcategory */
export type Md_ProductSubcategory_Pk_Columns_Input = {
  SubcategoryId: Scalars['Int'];
};

/** select columns of table "MD_ProductSubcategory" */
export enum Md_ProductSubcategory_Select_Column {
  /** column name */
  CategoryId = 'CategoryId',
  /** column name */
  SearchView = 'SearchView',
  /** column name */
  SubcategoryId = 'SubcategoryId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TypedViewClass = 'TypedViewClass'
}

/** input type for updating data in table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Set_Input = {
  CategoryId?: Maybe<Scalars['Int']>;
  SearchView?: Maybe<Scalars['String']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TypedViewClass?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Md_ProductSubcategory_Stddev_Fields = {
  __typename?: 'MD_ProductSubcategory_stddev_fields';
  CategoryId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Stddev_Order_By = {
  CategoryId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Md_ProductSubcategory_Stddev_Pop_Fields = {
  __typename?: 'MD_ProductSubcategory_stddev_pop_fields';
  CategoryId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Stddev_Pop_Order_By = {
  CategoryId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Md_ProductSubcategory_Stddev_Samp_Fields = {
  __typename?: 'MD_ProductSubcategory_stddev_samp_fields';
  CategoryId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Stddev_Samp_Order_By = {
  CategoryId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Md_ProductSubcategory_Sum_Fields = {
  __typename?: 'MD_ProductSubcategory_sum_fields';
  CategoryId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Sum_Order_By = {
  CategoryId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** update columns of table "MD_ProductSubcategory" */
export enum Md_ProductSubcategory_Update_Column {
  /** column name */
  CategoryId = 'CategoryId',
  /** column name */
  SearchView = 'SearchView',
  /** column name */
  SubcategoryId = 'SubcategoryId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TypedViewClass = 'TypedViewClass'
}

/** aggregate var_pop on columns */
export type Md_ProductSubcategory_Var_Pop_Fields = {
  __typename?: 'MD_ProductSubcategory_var_pop_fields';
  CategoryId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Var_Pop_Order_By = {
  CategoryId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Md_ProductSubcategory_Var_Samp_Fields = {
  __typename?: 'MD_ProductSubcategory_var_samp_fields';
  CategoryId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Var_Samp_Order_By = {
  CategoryId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Md_ProductSubcategory_Variance_Fields = {
  __typename?: 'MD_ProductSubcategory_variance_fields';
  CategoryId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "MD_ProductSubcategory" */
export type Md_ProductSubcategory_Variance_Order_By = {
  CategoryId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** columns and relationships of "MaintenanceCoating" */
export type MaintenanceCoating = {
  __typename?: 'MaintenanceCoating';
  /** An object relationship */
  C_MaintenanceCoatingMaterial: C_MaintenanceCoatingMaterial;
  ComponentId: Scalars['Int'];
  MaintenanceCoatingMaterialId: Scalars['Int'];
  /** An array relationship */
  MaintenanceCoatingSubstrates: Array<MaintenanceCoatingSubstrate>;
  /** An aggregate relationship */
  MaintenanceCoatingSubstrates_aggregate: MaintenanceCoatingSubstrate_Aggregate;
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  /** An array relationship */
  R_MaintenanceCoatingSubstrates: Array<R_MaintenanceCoatingSubstrate>;
  /** An aggregate relationship */
  R_MaintenanceCoatingSubstrates_aggregate: R_MaintenanceCoatingSubstrate_Aggregate;
};


/** columns and relationships of "MaintenanceCoating" */
export type MaintenanceCoatingMaintenanceCoatingSubstratesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<MaintenanceCoatingSubstrate_Bool_Exp>;
};


/** columns and relationships of "MaintenanceCoating" */
export type MaintenanceCoatingMaintenanceCoatingSubstrates_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<MaintenanceCoatingSubstrate_Bool_Exp>;
};


/** columns and relationships of "MaintenanceCoating" */
export type MaintenanceCoatingR_MaintenanceCoatingSubstratesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<R_MaintenanceCoatingSubstrate_Bool_Exp>;
};


/** columns and relationships of "MaintenanceCoating" */
export type MaintenanceCoatingR_MaintenanceCoatingSubstrates_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<R_MaintenanceCoatingSubstrate_Bool_Exp>;
};

/** columns and relationships of "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate = {
  __typename?: 'MaintenanceCoatingSubstrate';
  /** An object relationship */
  C_MaintenanceCoatingSubstrate: C_MaintenanceCoatingSubstrate;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  MaintenanceCoating: MaintenanceCoating;
  MaintenanceCoatingSubstrateId: Scalars['Int'];
};

/** aggregated selection of "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Aggregate = {
  __typename?: 'MaintenanceCoatingSubstrate_aggregate';
  aggregate?: Maybe<MaintenanceCoatingSubstrate_Aggregate_Fields>;
  nodes: Array<MaintenanceCoatingSubstrate>;
};

/** aggregate fields of "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Aggregate_Fields = {
  __typename?: 'MaintenanceCoatingSubstrate_aggregate_fields';
  avg?: Maybe<MaintenanceCoatingSubstrate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MaintenanceCoatingSubstrate_Max_Fields>;
  min?: Maybe<MaintenanceCoatingSubstrate_Min_Fields>;
  stddev?: Maybe<MaintenanceCoatingSubstrate_Stddev_Fields>;
  stddev_pop?: Maybe<MaintenanceCoatingSubstrate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MaintenanceCoatingSubstrate_Stddev_Samp_Fields>;
  sum?: Maybe<MaintenanceCoatingSubstrate_Sum_Fields>;
  var_pop?: Maybe<MaintenanceCoatingSubstrate_Var_Pop_Fields>;
  var_samp?: Maybe<MaintenanceCoatingSubstrate_Var_Samp_Fields>;
  variance?: Maybe<MaintenanceCoatingSubstrate_Variance_Fields>;
};


/** aggregate fields of "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Aggregate_FieldsCountArgs = {
  column?: Maybe<MaintenanceCoatingSubstrate_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Aggregate_Order_By = {
  avg?: Maybe<MaintenanceCoatingSubstrate_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<MaintenanceCoatingSubstrate_Max_Order_By>;
  min?: Maybe<MaintenanceCoatingSubstrate_Min_Order_By>;
  stddev?: Maybe<MaintenanceCoatingSubstrate_Stddev_Order_By>;
  stddev_pop?: Maybe<MaintenanceCoatingSubstrate_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<MaintenanceCoatingSubstrate_Stddev_Samp_Order_By>;
  sum?: Maybe<MaintenanceCoatingSubstrate_Sum_Order_By>;
  var_pop?: Maybe<MaintenanceCoatingSubstrate_Var_Pop_Order_By>;
  var_samp?: Maybe<MaintenanceCoatingSubstrate_Var_Samp_Order_By>;
  variance?: Maybe<MaintenanceCoatingSubstrate_Variance_Order_By>;
};

/** aggregate avg on columns */
export type MaintenanceCoatingSubstrate_Avg_Fields = {
  __typename?: 'MaintenanceCoatingSubstrate_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "MaintenanceCoatingSubstrate". All fields are combined with a logical 'AND'. */
export type MaintenanceCoatingSubstrate_Bool_Exp = {
  C_MaintenanceCoatingSubstrate?: Maybe<C_MaintenanceCoatingSubstrate_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  MaintenanceCoating?: Maybe<MaintenanceCoating_Bool_Exp>;
  MaintenanceCoatingSubstrateId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<MaintenanceCoatingSubstrate_Bool_Exp>>;
  _not?: Maybe<MaintenanceCoatingSubstrate_Bool_Exp>;
  _or?: Maybe<Array<MaintenanceCoatingSubstrate_Bool_Exp>>;
};

/** upsert condition type for table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_If_Matched = {
  match_columns?: Array<MaintenanceCoatingSubstrate_Insert_Match_Column>;
  update_columns?: Array<MaintenanceCoatingSubstrate_Update_Column>;
  where?: Maybe<MaintenanceCoatingSubstrate_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "MaintenanceCoatingSubstrate" */
export enum MaintenanceCoatingSubstrate_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MaintenanceCoatingSubstrateId = 'MaintenanceCoatingSubstrateId'
}

/** aggregate max on columns */
export type MaintenanceCoatingSubstrate_Max_Fields = {
  __typename?: 'MaintenanceCoatingSubstrate_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type MaintenanceCoatingSubstrate_Min_Fields = {
  __typename?: 'MaintenanceCoatingSubstrate_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** response of any mutation on the table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Mutation_Response = {
  __typename?: 'MaintenanceCoatingSubstrate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MaintenanceCoatingSubstrate>;
};

/** Ordering options when selecting data from "MaintenanceCoatingSubstrate". */
export type MaintenanceCoatingSubstrate_Order_By = {
  C_MaintenanceCoatingSubstrate?: Maybe<C_MaintenanceCoatingSubstrate_Order_By>;
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoating?: Maybe<MaintenanceCoating_Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** primary key columns input for table: MaintenanceCoatingSubstrate */
export type MaintenanceCoatingSubstrate_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  MaintenanceCoatingSubstrateId: Scalars['Int'];
};

/** select columns of table "MaintenanceCoatingSubstrate" */
export enum MaintenanceCoatingSubstrate_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MaintenanceCoatingSubstrateId = 'MaintenanceCoatingSubstrateId'
}

/** input type for updating data in table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type MaintenanceCoatingSubstrate_Stddev_Fields = {
  __typename?: 'MaintenanceCoatingSubstrate_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type MaintenanceCoatingSubstrate_Stddev_Pop_Fields = {
  __typename?: 'MaintenanceCoatingSubstrate_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type MaintenanceCoatingSubstrate_Stddev_Samp_Fields = {
  __typename?: 'MaintenanceCoatingSubstrate_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type MaintenanceCoatingSubstrate_Sum_Fields = {
  __typename?: 'MaintenanceCoatingSubstrate_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** update columns of table "MaintenanceCoatingSubstrate" */
export enum MaintenanceCoatingSubstrate_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MaintenanceCoatingSubstrateId = 'MaintenanceCoatingSubstrateId'
}

/** aggregate var_pop on columns */
export type MaintenanceCoatingSubstrate_Var_Pop_Fields = {
  __typename?: 'MaintenanceCoatingSubstrate_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type MaintenanceCoatingSubstrate_Var_Samp_Fields = {
  __typename?: 'MaintenanceCoatingSubstrate_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type MaintenanceCoatingSubstrate_Variance_Fields = {
  __typename?: 'MaintenanceCoatingSubstrate_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "MaintenanceCoatingSubstrate" */
export type MaintenanceCoatingSubstrate_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** columns and relationships of "MaintenanceCoatingUsage" */
export type MaintenanceCoatingUsage = {
  __typename?: 'MaintenanceCoatingUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "MaintenanceCoatingUsage" */
export type MaintenanceCoatingUsage_Aggregate = {
  __typename?: 'MaintenanceCoatingUsage_aggregate';
  aggregate?: Maybe<MaintenanceCoatingUsage_Aggregate_Fields>;
  nodes: Array<MaintenanceCoatingUsage>;
};

/** aggregate fields of "MaintenanceCoatingUsage" */
export type MaintenanceCoatingUsage_Aggregate_Fields = {
  __typename?: 'MaintenanceCoatingUsage_aggregate_fields';
  avg?: Maybe<MaintenanceCoatingUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MaintenanceCoatingUsage_Max_Fields>;
  min?: Maybe<MaintenanceCoatingUsage_Min_Fields>;
  stddev?: Maybe<MaintenanceCoatingUsage_Stddev_Fields>;
  stddev_pop?: Maybe<MaintenanceCoatingUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MaintenanceCoatingUsage_Stddev_Samp_Fields>;
  sum?: Maybe<MaintenanceCoatingUsage_Sum_Fields>;
  var_pop?: Maybe<MaintenanceCoatingUsage_Var_Pop_Fields>;
  var_samp?: Maybe<MaintenanceCoatingUsage_Var_Samp_Fields>;
  variance?: Maybe<MaintenanceCoatingUsage_Variance_Fields>;
};


/** aggregate fields of "MaintenanceCoatingUsage" */
export type MaintenanceCoatingUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<MaintenanceCoatingUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MaintenanceCoatingUsage_Avg_Fields = {
  __typename?: 'MaintenanceCoatingUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MaintenanceCoatingUsage". All fields are combined with a logical 'AND'. */
export type MaintenanceCoatingUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<MaintenanceCoatingUsage_Bool_Exp>>;
  _not?: Maybe<MaintenanceCoatingUsage_Bool_Exp>;
  _or?: Maybe<Array<MaintenanceCoatingUsage_Bool_Exp>>;
};

/** upsert condition type for table "MaintenanceCoatingUsage" */
export type MaintenanceCoatingUsage_If_Matched = {
  match_columns?: Array<MaintenanceCoatingUsage_Insert_Match_Column>;
  update_columns?: Array<MaintenanceCoatingUsage_Update_Column>;
  where?: Maybe<MaintenanceCoatingUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MaintenanceCoatingUsage" */
export type MaintenanceCoatingUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MaintenanceCoatingUsage" */
export type MaintenanceCoatingUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "MaintenanceCoatingUsage" */
export enum MaintenanceCoatingUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type MaintenanceCoatingUsage_Max_Fields = {
  __typename?: 'MaintenanceCoatingUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type MaintenanceCoatingUsage_Min_Fields = {
  __typename?: 'MaintenanceCoatingUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "MaintenanceCoatingUsage" */
export type MaintenanceCoatingUsage_Mutation_Response = {
  __typename?: 'MaintenanceCoatingUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MaintenanceCoatingUsage>;
};

/** Ordering options when selecting data from "MaintenanceCoatingUsage". */
export type MaintenanceCoatingUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: MaintenanceCoatingUsage */
export type MaintenanceCoatingUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "MaintenanceCoatingUsage" */
export enum MaintenanceCoatingUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "MaintenanceCoatingUsage" */
export type MaintenanceCoatingUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type MaintenanceCoatingUsage_Stddev_Fields = {
  __typename?: 'MaintenanceCoatingUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MaintenanceCoatingUsage_Stddev_Pop_Fields = {
  __typename?: 'MaintenanceCoatingUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MaintenanceCoatingUsage_Stddev_Samp_Fields = {
  __typename?: 'MaintenanceCoatingUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type MaintenanceCoatingUsage_Sum_Fields = {
  __typename?: 'MaintenanceCoatingUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "MaintenanceCoatingUsage" */
export enum MaintenanceCoatingUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type MaintenanceCoatingUsage_Var_Pop_Fields = {
  __typename?: 'MaintenanceCoatingUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MaintenanceCoatingUsage_Var_Samp_Fields = {
  __typename?: 'MaintenanceCoatingUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MaintenanceCoatingUsage_Variance_Fields = {
  __typename?: 'MaintenanceCoatingUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "MaintenanceCoating" */
export type MaintenanceCoating_Aggregate = {
  __typename?: 'MaintenanceCoating_aggregate';
  aggregate?: Maybe<MaintenanceCoating_Aggregate_Fields>;
  nodes: Array<MaintenanceCoating>;
};

/** aggregate fields of "MaintenanceCoating" */
export type MaintenanceCoating_Aggregate_Fields = {
  __typename?: 'MaintenanceCoating_aggregate_fields';
  avg?: Maybe<MaintenanceCoating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MaintenanceCoating_Max_Fields>;
  min?: Maybe<MaintenanceCoating_Min_Fields>;
  stddev?: Maybe<MaintenanceCoating_Stddev_Fields>;
  stddev_pop?: Maybe<MaintenanceCoating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MaintenanceCoating_Stddev_Samp_Fields>;
  sum?: Maybe<MaintenanceCoating_Sum_Fields>;
  var_pop?: Maybe<MaintenanceCoating_Var_Pop_Fields>;
  var_samp?: Maybe<MaintenanceCoating_Var_Samp_Fields>;
  variance?: Maybe<MaintenanceCoating_Variance_Fields>;
};


/** aggregate fields of "MaintenanceCoating" */
export type MaintenanceCoating_Aggregate_FieldsCountArgs = {
  column?: Maybe<MaintenanceCoating_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "MaintenanceCoating" */
export type MaintenanceCoating_Aggregate_Order_By = {
  avg?: Maybe<MaintenanceCoating_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<MaintenanceCoating_Max_Order_By>;
  min?: Maybe<MaintenanceCoating_Min_Order_By>;
  stddev?: Maybe<MaintenanceCoating_Stddev_Order_By>;
  stddev_pop?: Maybe<MaintenanceCoating_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<MaintenanceCoating_Stddev_Samp_Order_By>;
  sum?: Maybe<MaintenanceCoating_Sum_Order_By>;
  var_pop?: Maybe<MaintenanceCoating_Var_Pop_Order_By>;
  var_samp?: Maybe<MaintenanceCoating_Var_Samp_Order_By>;
  variance?: Maybe<MaintenanceCoating_Variance_Order_By>;
};

/** aggregate avg on columns */
export type MaintenanceCoating_Avg_Fields = {
  __typename?: 'MaintenanceCoating_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "MaintenanceCoating" */
export type MaintenanceCoating_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "MaintenanceCoating". All fields are combined with a logical 'AND'. */
export type MaintenanceCoating_Bool_Exp = {
  C_MaintenanceCoatingMaterial?: Maybe<C_MaintenanceCoatingMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  MaintenanceCoatingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  MaintenanceCoatingSubstrates?: Maybe<MaintenanceCoatingSubstrate_Bool_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  R_MaintenanceCoatingSubstrates?: Maybe<R_MaintenanceCoatingSubstrate_Bool_Exp>;
  _and?: Maybe<Array<MaintenanceCoating_Bool_Exp>>;
  _not?: Maybe<MaintenanceCoating_Bool_Exp>;
  _or?: Maybe<Array<MaintenanceCoating_Bool_Exp>>;
};

/** upsert condition type for table "MaintenanceCoating" */
export type MaintenanceCoating_If_Matched = {
  match_columns?: Array<MaintenanceCoating_Insert_Match_Column>;
  update_columns?: Array<MaintenanceCoating_Update_Column>;
  where?: Maybe<MaintenanceCoating_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MaintenanceCoating" */
export type MaintenanceCoating_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MaintenanceCoating" */
export type MaintenanceCoating_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "MaintenanceCoating" */
export enum MaintenanceCoating_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MaintenanceCoatingMaterialId = 'MaintenanceCoatingMaterialId'
}

/** aggregate max on columns */
export type MaintenanceCoating_Max_Fields = {
  __typename?: 'MaintenanceCoating_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "MaintenanceCoating" */
export type MaintenanceCoating_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingMaterialId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type MaintenanceCoating_Min_Fields = {
  __typename?: 'MaintenanceCoating_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "MaintenanceCoating" */
export type MaintenanceCoating_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingMaterialId?: Maybe<Order_By>;
};

/** response of any mutation on the table "MaintenanceCoating" */
export type MaintenanceCoating_Mutation_Response = {
  __typename?: 'MaintenanceCoating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MaintenanceCoating>;
};

/** Ordering options when selecting data from "MaintenanceCoating". */
export type MaintenanceCoating_Order_By = {
  C_MaintenanceCoatingMaterial?: Maybe<C_MaintenanceCoatingMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingMaterialId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrates_aggregate?: Maybe<MaintenanceCoatingSubstrate_Aggregate_Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  R_MaintenanceCoatingSubstrates_aggregate?: Maybe<R_MaintenanceCoatingSubstrate_Aggregate_Order_By>;
};

/** primary key columns input for table: MaintenanceCoating */
export type MaintenanceCoating_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "MaintenanceCoating" */
export enum MaintenanceCoating_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MaintenanceCoatingMaterialId = 'MaintenanceCoatingMaterialId'
}

/** input type for updating data in table "MaintenanceCoating" */
export type MaintenanceCoating_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type MaintenanceCoating_Stddev_Fields = {
  __typename?: 'MaintenanceCoating_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "MaintenanceCoating" */
export type MaintenanceCoating_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type MaintenanceCoating_Stddev_Pop_Fields = {
  __typename?: 'MaintenanceCoating_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "MaintenanceCoating" */
export type MaintenanceCoating_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type MaintenanceCoating_Stddev_Samp_Fields = {
  __typename?: 'MaintenanceCoating_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "MaintenanceCoating" */
export type MaintenanceCoating_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type MaintenanceCoating_Sum_Fields = {
  __typename?: 'MaintenanceCoating_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "MaintenanceCoating" */
export type MaintenanceCoating_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingMaterialId?: Maybe<Order_By>;
};

/** update columns of table "MaintenanceCoating" */
export enum MaintenanceCoating_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MaintenanceCoatingMaterialId = 'MaintenanceCoatingMaterialId'
}

/** aggregate var_pop on columns */
export type MaintenanceCoating_Var_Pop_Fields = {
  __typename?: 'MaintenanceCoating_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "MaintenanceCoating" */
export type MaintenanceCoating_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type MaintenanceCoating_Var_Samp_Fields = {
  __typename?: 'MaintenanceCoating_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "MaintenanceCoating" */
export type MaintenanceCoating_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type MaintenanceCoating_Variance_Fields = {
  __typename?: 'MaintenanceCoating_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "MaintenanceCoating" */
export type MaintenanceCoating_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "ManufacturedProduct" */
export type ManufacturedProduct = {
  __typename?: 'ManufacturedProduct';
  /** An object relationship */
  AdhesiveFullApplication?: Maybe<AdhesiveFullApplication>;
  /** An object relationship */
  AdhesiveRibbon?: Maybe<AdhesiveRibbons>;
  /** An object relationship */
  AdhesiveSpot?: Maybe<AdhesiveSpots>;
  /** An object relationship */
  AirRetarder?: Maybe<AirRetarder>;
  /** An object relationship */
  Anchor?: Maybe<Anchor>;
  /** An object relationship */
  BaseCoat?: Maybe<BaseCoat>;
  /** An object relationship */
  BasePly?: Maybe<BasePly>;
  /** An object relationship */
  BaseSheet?: Maybe<BaseSheet>;
  /** An object relationship */
  BattBlanketInsulation?: Maybe<BattBlanketInsulation>;
  /** An object relationship */
  BattenBar?: Maybe<BattenBar>;
  /** An object relationship */
  BoardStock?: Maybe<BoardStock>;
  /** An object relationship */
  C_LockStatus: C_LockStatus;
  /** An object relationship */
  CapPly?: Maybe<CapPly>;
  /** An object relationship */
  CementitiousWoodFiberDeck?: Maybe<CementitiousWoodFiberDeck>;
  /** An object relationship */
  Clip?: Maybe<Clip>;
  /** An object relationship */
  Coat?: Maybe<Coat>;
  /** An object relationship */
  Coating?: Maybe<Coating>;
  /** An object relationship */
  Component: Component;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  CompositePanelCover?: Maybe<CompositePanelCover>;
  /** An object relationship */
  Coping?: Maybe<Coping>;
  /** An object relationship */
  CoverBoard?: Maybe<CoverBoard>;
  /** An object relationship */
  CoverStripDisk?: Maybe<CoverStripDisk>;
  DateLocked?: Maybe<Scalars['datetime']>;
  EnteredAsMetric: Scalars['MetricEntryFlag'];
  /** An object relationship */
  ExpansionJoint?: Maybe<ExpansionJoint>;
  /** An object relationship */
  FMUserAccount?: Maybe<FmUserAccount>;
  /** An object relationship */
  Fascium?: Maybe<Fascia>;
  /** An object relationship */
  Fastener?: Maybe<Fastener>;
  /** An object relationship */
  FiberReinforcedPlasticDeck?: Maybe<FiberReinforcedPlasticDeck>;
  /** An object relationship */
  FormDeck?: Maybe<FormDeck>;
  /** An object relationship */
  Gutter?: Maybe<Gutter>;
  /** An object relationship */
  GypsumDeck?: Maybe<GypsumDeck>;
  /** An object relationship */
  LapSealant?: Maybe<LapSealant>;
  /** An object relationship */
  LightweightInsulatingConcrete?: Maybe<LightweightInsulatingConcrete>;
  /** An object relationship */
  LinerPanel?: Maybe<LinerPanel>;
  LockStatusId: Scalars['Int'];
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  MaintenanceCoating?: Maybe<MaintenanceCoating>;
  /** An object relationship */
  Manufacturer: Manufacturer;
  ManufacturerId: Scalars['Int'];
  /** An object relationship */
  OtherPly?: Maybe<OtherPly>;
  /** An object relationship */
  PreassembledFastening?: Maybe<PreassembledFastening>;
  /** An array relationship */
  PrivateLabels: Array<PrivateLabel>;
  /** An aggregate relationship */
  PrivateLabels_aggregate: PrivateLabel_Aggregate;
  /** An array relationship */
  ProductDependencies: Array<ProductDependencies>;
  /** An aggregate relationship */
  ProductDependencies_aggregate: ProductDependencies_Aggregate;
  RValue: Scalars['MeasurementValue'];
  /** An object relationship */
  Reglet?: Maybe<Reglet>;
  /** An object relationship */
  SeamPrimer?: Maybe<SeamPrimer>;
  /** An object relationship */
  SeamWash?: Maybe<SeamWash>;
  /** An array relationship */
  SearchNavAssembly_ManufacturerProducts: Array<SearchNavAssembly_ManufacturerProduct>;
  /** An aggregate relationship */
  SearchNavAssembly_ManufacturerProducts_aggregate: SearchNavAssembly_ManufacturerProduct_Aggregate;
  /** An object relationship */
  SeparatorSheet?: Maybe<SeparatorSheet>;
  /** An object relationship */
  Shingle?: Maybe<Shingles>;
  /** An object relationship */
  SinglePlyCover?: Maybe<SinglePlyCover>;
  /** An object relationship */
  SprayedFoamInsulation?: Maybe<SprayedFoamInsulation>;
  /** An object relationship */
  StandingLapSeamCover?: Maybe<StandingLapSeamCover>;
  /** An object relationship */
  SteelDeck?: Maybe<SteelDeck>;
  /** An object relationship */
  StressPlate?: Maybe<StressPlate>;
  /** An array relationship */
  StructuredSystemProducts: Array<StructuredSystemProducts>;
  /** An aggregate relationship */
  StructuredSystemProducts_aggregate: StructuredSystemProducts_Aggregate;
  /** An object relationship */
  SubstratePrimer?: Maybe<SubstratePrimer>;
  /** An object relationship */
  Surfacing?: Maybe<Surfacing>;
  /** An object relationship */
  ThermalBarrier?: Maybe<ThermalBarrier>;
  /** An object relationship */
  TopCoat?: Maybe<TopCoat>;
  TradeName?: Maybe<Scalars['String']>;
  /** An object relationship */
  VaporRetarder?: Maybe<VaporRetarder>;
  /** An object relationship */
  WoodDeck?: Maybe<WoodDeck>;
  /** An array relationship */
  privateLabelsByChildcomponentid: Array<PrivateLabel>;
  /** An aggregate relationship */
  privateLabelsByChildcomponentid_aggregate: PrivateLabel_Aggregate;
  /** An array relationship */
  productDependenciesByComponent2id: Array<ProductDependencies>;
  /** An aggregate relationship */
  productDependenciesByComponent2id_aggregate: ProductDependencies_Aggregate;
};


/** columns and relationships of "ManufacturedProduct" */
export type ManufacturedProductPrivateLabelsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PrivateLabel_Order_By>>;
  where?: Maybe<PrivateLabel_Bool_Exp>;
};


/** columns and relationships of "ManufacturedProduct" */
export type ManufacturedProductPrivateLabels_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PrivateLabel_Order_By>>;
  where?: Maybe<PrivateLabel_Bool_Exp>;
};


/** columns and relationships of "ManufacturedProduct" */
export type ManufacturedProductProductDependenciesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductDependencies_Order_By>>;
  where?: Maybe<ProductDependencies_Bool_Exp>;
};


/** columns and relationships of "ManufacturedProduct" */
export type ManufacturedProductProductDependencies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductDependencies_Order_By>>;
  where?: Maybe<ProductDependencies_Bool_Exp>;
};


/** columns and relationships of "ManufacturedProduct" */
export type ManufacturedProductSearchNavAssembly_ManufacturerProductsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SearchNavAssembly_ManufacturerProduct_Order_By>>;
  where?: Maybe<SearchNavAssembly_ManufacturerProduct_Bool_Exp>;
};


/** columns and relationships of "ManufacturedProduct" */
export type ManufacturedProductSearchNavAssembly_ManufacturerProducts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SearchNavAssembly_ManufacturerProduct_Order_By>>;
  where?: Maybe<SearchNavAssembly_ManufacturerProduct_Bool_Exp>;
};


/** columns and relationships of "ManufacturedProduct" */
export type ManufacturedProductStructuredSystemProductsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuredSystemProducts_Order_By>>;
  where?: Maybe<StructuredSystemProducts_Bool_Exp>;
};


/** columns and relationships of "ManufacturedProduct" */
export type ManufacturedProductStructuredSystemProducts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuredSystemProducts_Order_By>>;
  where?: Maybe<StructuredSystemProducts_Bool_Exp>;
};


/** columns and relationships of "ManufacturedProduct" */
export type ManufacturedProductPrivateLabelsByChildcomponentidArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PrivateLabel_Order_By>>;
  where?: Maybe<PrivateLabel_Bool_Exp>;
};


/** columns and relationships of "ManufacturedProduct" */
export type ManufacturedProductPrivateLabelsByChildcomponentid_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PrivateLabel_Order_By>>;
  where?: Maybe<PrivateLabel_Bool_Exp>;
};


/** columns and relationships of "ManufacturedProduct" */
export type ManufacturedProductProductDependenciesByComponent2idArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductDependencies_Order_By>>;
  where?: Maybe<ProductDependencies_Bool_Exp>;
};


/** columns and relationships of "ManufacturedProduct" */
export type ManufacturedProductProductDependenciesByComponent2id_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductDependencies_Order_By>>;
  where?: Maybe<ProductDependencies_Bool_Exp>;
};

/** aggregated selection of "ManufacturedProduct" */
export type ManufacturedProduct_Aggregate = {
  __typename?: 'ManufacturedProduct_aggregate';
  aggregate?: Maybe<ManufacturedProduct_Aggregate_Fields>;
  nodes: Array<ManufacturedProduct>;
};

/** aggregate fields of "ManufacturedProduct" */
export type ManufacturedProduct_Aggregate_Fields = {
  __typename?: 'ManufacturedProduct_aggregate_fields';
  avg?: Maybe<ManufacturedProduct_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ManufacturedProduct_Max_Fields>;
  min?: Maybe<ManufacturedProduct_Min_Fields>;
  stddev?: Maybe<ManufacturedProduct_Stddev_Fields>;
  stddev_pop?: Maybe<ManufacturedProduct_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ManufacturedProduct_Stddev_Samp_Fields>;
  sum?: Maybe<ManufacturedProduct_Sum_Fields>;
  var_pop?: Maybe<ManufacturedProduct_Var_Pop_Fields>;
  var_samp?: Maybe<ManufacturedProduct_Var_Samp_Fields>;
  variance?: Maybe<ManufacturedProduct_Variance_Fields>;
};


/** aggregate fields of "ManufacturedProduct" */
export type ManufacturedProduct_Aggregate_FieldsCountArgs = {
  column?: Maybe<ManufacturedProduct_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ManufacturedProduct" */
export type ManufacturedProduct_Aggregate_Order_By = {
  avg?: Maybe<ManufacturedProduct_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ManufacturedProduct_Max_Order_By>;
  min?: Maybe<ManufacturedProduct_Min_Order_By>;
  stddev?: Maybe<ManufacturedProduct_Stddev_Order_By>;
  stddev_pop?: Maybe<ManufacturedProduct_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ManufacturedProduct_Stddev_Samp_Order_By>;
  sum?: Maybe<ManufacturedProduct_Sum_Order_By>;
  var_pop?: Maybe<ManufacturedProduct_Var_Pop_Order_By>;
  var_samp?: Maybe<ManufacturedProduct_Var_Samp_Order_By>;
  variance?: Maybe<ManufacturedProduct_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ManufacturedProduct_Avg_Fields = {
  __typename?: 'ManufacturedProduct_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ManufacturedProduct" */
export type ManufacturedProduct_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ManufacturedProduct". All fields are combined with a logical 'AND'. */
export type ManufacturedProduct_Bool_Exp = {
  AdhesiveFullApplication?: Maybe<AdhesiveFullApplication_Bool_Exp>;
  AdhesiveRibbon?: Maybe<AdhesiveRibbons_Bool_Exp>;
  AdhesiveSpot?: Maybe<AdhesiveSpots_Bool_Exp>;
  AirRetarder?: Maybe<AirRetarder_Bool_Exp>;
  Anchor?: Maybe<Anchor_Bool_Exp>;
  BaseCoat?: Maybe<BaseCoat_Bool_Exp>;
  BasePly?: Maybe<BasePly_Bool_Exp>;
  BaseSheet?: Maybe<BaseSheet_Bool_Exp>;
  BattBlanketInsulation?: Maybe<BattBlanketInsulation_Bool_Exp>;
  BattenBar?: Maybe<BattenBar_Bool_Exp>;
  BoardStock?: Maybe<BoardStock_Bool_Exp>;
  C_LockStatus?: Maybe<C_LockStatus_Bool_Exp>;
  CapPly?: Maybe<CapPly_Bool_Exp>;
  CementitiousWoodFiberDeck?: Maybe<CementitiousWoodFiberDeck_Bool_Exp>;
  Clip?: Maybe<Clip_Bool_Exp>;
  Coat?: Maybe<Coat_Bool_Exp>;
  Coating?: Maybe<Coating_Bool_Exp>;
  Component?: Maybe<Component_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  CompositePanelCover?: Maybe<CompositePanelCover_Bool_Exp>;
  Coping?: Maybe<Coping_Bool_Exp>;
  CoverBoard?: Maybe<CoverBoard_Bool_Exp>;
  CoverStripDisk?: Maybe<CoverStripDisk_Bool_Exp>;
  DateLocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  EnteredAsMetric?: Maybe<MetricEntryFlag_Mssql_Comparison_Exp>;
  ExpansionJoint?: Maybe<ExpansionJoint_Bool_Exp>;
  FMUserAccount?: Maybe<FmUserAccount_Bool_Exp>;
  Fascium?: Maybe<Fascia_Bool_Exp>;
  Fastener?: Maybe<Fastener_Bool_Exp>;
  FiberReinforcedPlasticDeck?: Maybe<FiberReinforcedPlasticDeck_Bool_Exp>;
  FormDeck?: Maybe<FormDeck_Bool_Exp>;
  Gutter?: Maybe<Gutter_Bool_Exp>;
  GypsumDeck?: Maybe<GypsumDeck_Bool_Exp>;
  LapSealant?: Maybe<LapSealant_Bool_Exp>;
  LightweightInsulatingConcrete?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
  LinerPanel?: Maybe<LinerPanel_Bool_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  MaintenanceCoating?: Maybe<MaintenanceCoating_Bool_Exp>;
  Manufacturer?: Maybe<Manufacturer_Bool_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  OtherPly?: Maybe<OtherPly_Bool_Exp>;
  PreassembledFastening?: Maybe<PreassembledFastening_Bool_Exp>;
  PrivateLabels?: Maybe<PrivateLabel_Bool_Exp>;
  ProductDependencies?: Maybe<ProductDependencies_Bool_Exp>;
  RValue?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Reglet?: Maybe<Reglet_Bool_Exp>;
  SeamPrimer?: Maybe<SeamPrimer_Bool_Exp>;
  SeamWash?: Maybe<SeamWash_Bool_Exp>;
  SearchNavAssembly_ManufacturerProducts?: Maybe<SearchNavAssembly_ManufacturerProduct_Bool_Exp>;
  SeparatorSheet?: Maybe<SeparatorSheet_Bool_Exp>;
  Shingle?: Maybe<Shingles_Bool_Exp>;
  SinglePlyCover?: Maybe<SinglePlyCover_Bool_Exp>;
  SprayedFoamInsulation?: Maybe<SprayedFoamInsulation_Bool_Exp>;
  StandingLapSeamCover?: Maybe<StandingLapSeamCover_Bool_Exp>;
  SteelDeck?: Maybe<SteelDeck_Bool_Exp>;
  StressPlate?: Maybe<StressPlate_Bool_Exp>;
  StructuredSystemProducts?: Maybe<StructuredSystemProducts_Bool_Exp>;
  SubstratePrimer?: Maybe<SubstratePrimer_Bool_Exp>;
  Surfacing?: Maybe<Surfacing_Bool_Exp>;
  ThermalBarrier?: Maybe<ThermalBarrier_Bool_Exp>;
  TopCoat?: Maybe<TopCoat_Bool_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  VaporRetarder?: Maybe<VaporRetarder_Bool_Exp>;
  WoodDeck?: Maybe<WoodDeck_Bool_Exp>;
  _and?: Maybe<Array<ManufacturedProduct_Bool_Exp>>;
  _not?: Maybe<ManufacturedProduct_Bool_Exp>;
  _or?: Maybe<Array<ManufacturedProduct_Bool_Exp>>;
  privateLabelsByChildcomponentid?: Maybe<PrivateLabel_Bool_Exp>;
  productDependenciesByComponent2id?: Maybe<ProductDependencies_Bool_Exp>;
};

/** upsert condition type for table "ManufacturedProduct" */
export type ManufacturedProduct_If_Matched = {
  match_columns?: Array<ManufacturedProduct_Insert_Match_Column>;
  update_columns?: Array<ManufacturedProduct_Update_Column>;
  where?: Maybe<ManufacturedProduct_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ManufacturedProduct" */
export type ManufacturedProduct_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ManufacturedProduct" */
export type ManufacturedProduct_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  RValue?: Maybe<Scalars['MeasurementValue']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "ManufacturedProduct" */
export enum ManufacturedProduct_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  RValue = 'RValue',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate max on columns */
export type ManufacturedProduct_Max_Fields = {
  __typename?: 'ManufacturedProduct_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  RValue?: Maybe<Scalars['MeasurementValue']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ManufacturedProduct" */
export type ManufacturedProduct_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  EnteredAsMetric?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  RValue?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ManufacturedProduct_Min_Fields = {
  __typename?: 'ManufacturedProduct_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  RValue?: Maybe<Scalars['MeasurementValue']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ManufacturedProduct" */
export type ManufacturedProduct_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  EnteredAsMetric?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  RValue?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
};

/** response of any mutation on the table "ManufacturedProduct" */
export type ManufacturedProduct_Mutation_Response = {
  __typename?: 'ManufacturedProduct_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ManufacturedProduct>;
};

/** Ordering options when selecting data from "ManufacturedProduct". */
export type ManufacturedProduct_Order_By = {
  AdhesiveFullApplication?: Maybe<AdhesiveFullApplication_Order_By>;
  AdhesiveRibbon?: Maybe<AdhesiveRibbons_Order_By>;
  AdhesiveSpot?: Maybe<AdhesiveSpots_Order_By>;
  AirRetarder?: Maybe<AirRetarder_Order_By>;
  Anchor?: Maybe<Anchor_Order_By>;
  BaseCoat?: Maybe<BaseCoat_Order_By>;
  BasePly?: Maybe<BasePly_Order_By>;
  BaseSheet?: Maybe<BaseSheet_Order_By>;
  BattBlanketInsulation?: Maybe<BattBlanketInsulation_Order_By>;
  BattenBar?: Maybe<BattenBar_Order_By>;
  BoardStock?: Maybe<BoardStock_Order_By>;
  C_LockStatus?: Maybe<C_LockStatus_Order_By>;
  CapPly?: Maybe<CapPly_Order_By>;
  CementitiousWoodFiberDeck?: Maybe<CementitiousWoodFiberDeck_Order_By>;
  Clip?: Maybe<Clip_Order_By>;
  Coat?: Maybe<Coat_Order_By>;
  Coating?: Maybe<Coating_Order_By>;
  Component?: Maybe<Component_Order_By>;
  ComponentId?: Maybe<Order_By>;
  CompositePanelCover?: Maybe<CompositePanelCover_Order_By>;
  Coping?: Maybe<Coping_Order_By>;
  CoverBoard?: Maybe<CoverBoard_Order_By>;
  CoverStripDisk?: Maybe<CoverStripDisk_Order_By>;
  DateLocked?: Maybe<Order_By>;
  EnteredAsMetric?: Maybe<Order_By>;
  ExpansionJoint?: Maybe<ExpansionJoint_Order_By>;
  FMUserAccount?: Maybe<FmUserAccount_Order_By>;
  Fascium?: Maybe<Fascia_Order_By>;
  Fastener?: Maybe<Fastener_Order_By>;
  FiberReinforcedPlasticDeck?: Maybe<FiberReinforcedPlasticDeck_Order_By>;
  FormDeck?: Maybe<FormDeck_Order_By>;
  Gutter?: Maybe<Gutter_Order_By>;
  GypsumDeck?: Maybe<GypsumDeck_Order_By>;
  LapSealant?: Maybe<LapSealant_Order_By>;
  LightweightInsulatingConcrete?: Maybe<LightweightInsulatingConcrete_Order_By>;
  LinerPanel?: Maybe<LinerPanel_Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  MaintenanceCoating?: Maybe<MaintenanceCoating_Order_By>;
  Manufacturer?: Maybe<Manufacturer_Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  OtherPly?: Maybe<OtherPly_Order_By>;
  PreassembledFastening?: Maybe<PreassembledFastening_Order_By>;
  PrivateLabels_aggregate?: Maybe<PrivateLabel_Aggregate_Order_By>;
  ProductDependencies_aggregate?: Maybe<ProductDependencies_Aggregate_Order_By>;
  RValue?: Maybe<Order_By>;
  Reglet?: Maybe<Reglet_Order_By>;
  SeamPrimer?: Maybe<SeamPrimer_Order_By>;
  SeamWash?: Maybe<SeamWash_Order_By>;
  SearchNavAssembly_ManufacturerProducts_aggregate?: Maybe<SearchNavAssembly_ManufacturerProduct_Aggregate_Order_By>;
  SeparatorSheet?: Maybe<SeparatorSheet_Order_By>;
  Shingle?: Maybe<Shingles_Order_By>;
  SinglePlyCover?: Maybe<SinglePlyCover_Order_By>;
  SprayedFoamInsulation?: Maybe<SprayedFoamInsulation_Order_By>;
  StandingLapSeamCover?: Maybe<StandingLapSeamCover_Order_By>;
  SteelDeck?: Maybe<SteelDeck_Order_By>;
  StressPlate?: Maybe<StressPlate_Order_By>;
  StructuredSystemProducts_aggregate?: Maybe<StructuredSystemProducts_Aggregate_Order_By>;
  SubstratePrimer?: Maybe<SubstratePrimer_Order_By>;
  Surfacing?: Maybe<Surfacing_Order_By>;
  ThermalBarrier?: Maybe<ThermalBarrier_Order_By>;
  TopCoat?: Maybe<TopCoat_Order_By>;
  TradeName?: Maybe<Order_By>;
  VaporRetarder?: Maybe<VaporRetarder_Order_By>;
  WoodDeck?: Maybe<WoodDeck_Order_By>;
  privateLabelsByChildcomponentid_aggregate?: Maybe<PrivateLabel_Aggregate_Order_By>;
  productDependenciesByComponent2id_aggregate?: Maybe<ProductDependencies_Aggregate_Order_By>;
};

/** primary key columns input for table: ManufacturedProduct */
export type ManufacturedProduct_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "ManufacturedProduct" */
export enum ManufacturedProduct_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  RValue = 'RValue',
  /** column name */
  TradeName = 'TradeName'
}

/** input type for updating data in table "ManufacturedProduct" */
export type ManufacturedProduct_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  RValue?: Maybe<Scalars['MeasurementValue']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ManufacturedProduct_Stddev_Fields = {
  __typename?: 'ManufacturedProduct_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ManufacturedProduct" */
export type ManufacturedProduct_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ManufacturedProduct_Stddev_Pop_Fields = {
  __typename?: 'ManufacturedProduct_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ManufacturedProduct" */
export type ManufacturedProduct_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ManufacturedProduct_Stddev_Samp_Fields = {
  __typename?: 'ManufacturedProduct_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ManufacturedProduct" */
export type ManufacturedProduct_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ManufacturedProduct_Sum_Fields = {
  __typename?: 'ManufacturedProduct_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ManufacturedProduct" */
export type ManufacturedProduct_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
};

/** update columns of table "ManufacturedProduct" */
export enum ManufacturedProduct_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  RValue = 'RValue',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate var_pop on columns */
export type ManufacturedProduct_Var_Pop_Fields = {
  __typename?: 'ManufacturedProduct_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ManufacturedProduct" */
export type ManufacturedProduct_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ManufacturedProduct_Var_Samp_Fields = {
  __typename?: 'ManufacturedProduct_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ManufacturedProduct" */
export type ManufacturedProduct_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ManufacturedProduct_Variance_Fields = {
  __typename?: 'ManufacturedProduct_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ManufacturedProduct" */
export type ManufacturedProduct_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
};

/** columns and relationships of "Manufacturer" */
export type Manufacturer = {
  __typename?: 'Manufacturer';
  /** An object relationship */
  Address: Address;
  AddressId: Scalars['Int'];
  /** An object relationship */
  C_Status: C_Status;
  CreatedByUserAccountId: Scalars['Int'];
  DateCreated: Scalars['datetime'];
  DateEffective: Scalars['datetime'];
  DateInception: Scalars['datetime'];
  DateModified: Scalars['datetime'];
  /** An object relationship */
  FMUserAccount: FmUserAccount;
  /** An array relationship */
  ItemWorkspaces: Array<ItemWorkspace>;
  /** An aggregate relationship */
  ItemWorkspaces_aggregate: ItemWorkspace_Aggregate;
  /** An array relationship */
  ManufacturedProducts: Array<ManufacturedProduct>;
  /** An aggregate relationship */
  ManufacturedProducts_aggregate: ManufacturedProduct_Aggregate;
  /** An array relationship */
  ManufacturerChangeHistories: Array<ManufacturerChangeHistory>;
  /** An aggregate relationship */
  ManufacturerChangeHistories_aggregate: ManufacturerChangeHistory_Aggregate;
  ManufacturerId: Scalars['Int'];
  ModifiedByUserAccountId: Scalars['Int'];
  Name: Scalars['String'];
  /** An array relationship */
  ReviewerWorkspaces: Array<ReviewerWorkspace>;
  /** An aggregate relationship */
  ReviewerWorkspaces_aggregate: ReviewerWorkspace_Aggregate;
  StatusId: Scalars['Int'];
  VersionMajor: Scalars['Int'];
  VersionMinor: Scalars['Int'];
  WebSite?: Maybe<Scalars['String']>;
  /** An object relationship */
  fMUserAccountByModifiedbyuseraccountid: FmUserAccount;
};


/** columns and relationships of "Manufacturer" */
export type ManufacturerItemWorkspacesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspace_Order_By>>;
  where?: Maybe<ItemWorkspace_Bool_Exp>;
};


/** columns and relationships of "Manufacturer" */
export type ManufacturerItemWorkspaces_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspace_Order_By>>;
  where?: Maybe<ItemWorkspace_Bool_Exp>;
};


/** columns and relationships of "Manufacturer" */
export type ManufacturerManufacturedProductsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturedProduct_Order_By>>;
  where?: Maybe<ManufacturedProduct_Bool_Exp>;
};


/** columns and relationships of "Manufacturer" */
export type ManufacturerManufacturedProducts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturedProduct_Order_By>>;
  where?: Maybe<ManufacturedProduct_Bool_Exp>;
};


/** columns and relationships of "Manufacturer" */
export type ManufacturerManufacturerChangeHistoriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturerChangeHistory_Order_By>>;
  where?: Maybe<ManufacturerChangeHistory_Bool_Exp>;
};


/** columns and relationships of "Manufacturer" */
export type ManufacturerManufacturerChangeHistories_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturerChangeHistory_Order_By>>;
  where?: Maybe<ManufacturerChangeHistory_Bool_Exp>;
};


/** columns and relationships of "Manufacturer" */
export type ManufacturerReviewerWorkspacesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspace_Order_By>>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};


/** columns and relationships of "Manufacturer" */
export type ManufacturerReviewerWorkspaces_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspace_Order_By>>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};

/** columns and relationships of "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory = {
  __typename?: 'ManufacturerChangeHistory';
  ChangeDateTime: Scalars['datetime'];
  ChangeDescription: Scalars['ItemComment'];
  ChangeHistoryId: Scalars['Int'];
  ChangeUserAccountId: Scalars['Int'];
  /** An object relationship */
  Manufacturer: Manufacturer;
  ManufacturerId: Scalars['Int'];
  VersionMajor: Scalars['Int'];
  VersionMinor: Scalars['Int'];
  WIPNumber?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Aggregate = {
  __typename?: 'ManufacturerChangeHistory_aggregate';
  aggregate?: Maybe<ManufacturerChangeHistory_Aggregate_Fields>;
  nodes: Array<ManufacturerChangeHistory>;
};

/** aggregate fields of "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Aggregate_Fields = {
  __typename?: 'ManufacturerChangeHistory_aggregate_fields';
  avg?: Maybe<ManufacturerChangeHistory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ManufacturerChangeHistory_Max_Fields>;
  min?: Maybe<ManufacturerChangeHistory_Min_Fields>;
  stddev?: Maybe<ManufacturerChangeHistory_Stddev_Fields>;
  stddev_pop?: Maybe<ManufacturerChangeHistory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ManufacturerChangeHistory_Stddev_Samp_Fields>;
  sum?: Maybe<ManufacturerChangeHistory_Sum_Fields>;
  var_pop?: Maybe<ManufacturerChangeHistory_Var_Pop_Fields>;
  var_samp?: Maybe<ManufacturerChangeHistory_Var_Samp_Fields>;
  variance?: Maybe<ManufacturerChangeHistory_Variance_Fields>;
};


/** aggregate fields of "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Aggregate_FieldsCountArgs = {
  column?: Maybe<ManufacturerChangeHistory_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Aggregate_Order_By = {
  avg?: Maybe<ManufacturerChangeHistory_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ManufacturerChangeHistory_Max_Order_By>;
  min?: Maybe<ManufacturerChangeHistory_Min_Order_By>;
  stddev?: Maybe<ManufacturerChangeHistory_Stddev_Order_By>;
  stddev_pop?: Maybe<ManufacturerChangeHistory_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ManufacturerChangeHistory_Stddev_Samp_Order_By>;
  sum?: Maybe<ManufacturerChangeHistory_Sum_Order_By>;
  var_pop?: Maybe<ManufacturerChangeHistory_Var_Pop_Order_By>;
  var_samp?: Maybe<ManufacturerChangeHistory_Var_Samp_Order_By>;
  variance?: Maybe<ManufacturerChangeHistory_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ManufacturerChangeHistory_Avg_Fields = {
  __typename?: 'ManufacturerChangeHistory_avg_fields';
  ChangeHistoryId?: Maybe<Scalars['Float']>;
  ChangeUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Avg_Order_By = {
  ChangeHistoryId?: Maybe<Order_By>;
  ChangeUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ManufacturerChangeHistory". All fields are combined with a logical 'AND'. */
export type ManufacturerChangeHistory_Bool_Exp = {
  ChangeDateTime?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ChangeDescription?: Maybe<ItemComment_Mssql_Comparison_Exp>;
  ChangeHistoryId?: Maybe<Int_Mssql_Comparison_Exp>;
  ChangeUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  Manufacturer?: Maybe<Manufacturer_Bool_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  VersionMajor?: Maybe<Int_Mssql_Comparison_Exp>;
  VersionMinor?: Maybe<Int_Mssql_Comparison_Exp>;
  WIPNumber?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ManufacturerChangeHistory_Bool_Exp>>;
  _not?: Maybe<ManufacturerChangeHistory_Bool_Exp>;
  _or?: Maybe<Array<ManufacturerChangeHistory_Bool_Exp>>;
};

/** upsert condition type for table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_If_Matched = {
  match_columns?: Array<ManufacturerChangeHistory_Insert_Match_Column>;
  update_columns?: Array<ManufacturerChangeHistory_Update_Column>;
  where?: Maybe<ManufacturerChangeHistory_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Inc_Input = {
  ChangeUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Insert_Input = {
  ChangeDateTime?: Maybe<Scalars['datetime']>;
  ChangeDescription?: Maybe<Scalars['ItemComment']>;
  ChangeUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
  WIPNumber?: Maybe<Scalars['String']>;
};

/** select match_columns of table "ManufacturerChangeHistory" */
export enum ManufacturerChangeHistory_Insert_Match_Column {
  /** column name */
  ChangeDateTime = 'ChangeDateTime',
  /** column name */
  ChangeDescription = 'ChangeDescription',
  /** column name */
  ChangeHistoryId = 'ChangeHistoryId',
  /** column name */
  ChangeUserAccountId = 'ChangeUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  VersionMajor = 'VersionMajor',
  /** column name */
  VersionMinor = 'VersionMinor',
  /** column name */
  WipNumber = 'WIPNumber'
}

/** aggregate max on columns */
export type ManufacturerChangeHistory_Max_Fields = {
  __typename?: 'ManufacturerChangeHistory_max_fields';
  ChangeDateTime?: Maybe<Scalars['datetime']>;
  ChangeDescription?: Maybe<Scalars['ItemComment']>;
  ChangeHistoryId?: Maybe<Scalars['Int']>;
  ChangeUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
  WIPNumber?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Max_Order_By = {
  ChangeDateTime?: Maybe<Order_By>;
  ChangeDescription?: Maybe<Order_By>;
  ChangeHistoryId?: Maybe<Order_By>;
  ChangeUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WIPNumber?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ManufacturerChangeHistory_Min_Fields = {
  __typename?: 'ManufacturerChangeHistory_min_fields';
  ChangeDateTime?: Maybe<Scalars['datetime']>;
  ChangeDescription?: Maybe<Scalars['ItemComment']>;
  ChangeHistoryId?: Maybe<Scalars['Int']>;
  ChangeUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
  WIPNumber?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Min_Order_By = {
  ChangeDateTime?: Maybe<Order_By>;
  ChangeDescription?: Maybe<Order_By>;
  ChangeHistoryId?: Maybe<Order_By>;
  ChangeUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WIPNumber?: Maybe<Order_By>;
};

/** response of any mutation on the table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Mutation_Response = {
  __typename?: 'ManufacturerChangeHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ManufacturerChangeHistory>;
};

/** Ordering options when selecting data from "ManufacturerChangeHistory". */
export type ManufacturerChangeHistory_Order_By = {
  ChangeDateTime?: Maybe<Order_By>;
  ChangeDescription?: Maybe<Order_By>;
  ChangeHistoryId?: Maybe<Order_By>;
  ChangeUserAccountId?: Maybe<Order_By>;
  Manufacturer?: Maybe<Manufacturer_Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WIPNumber?: Maybe<Order_By>;
};

/** primary key columns input for table: ManufacturerChangeHistory */
export type ManufacturerChangeHistory_Pk_Columns_Input = {
  ChangeHistoryId: Scalars['Int'];
};

/** select columns of table "ManufacturerChangeHistory" */
export enum ManufacturerChangeHistory_Select_Column {
  /** column name */
  ChangeDateTime = 'ChangeDateTime',
  /** column name */
  ChangeDescription = 'ChangeDescription',
  /** column name */
  ChangeHistoryId = 'ChangeHistoryId',
  /** column name */
  ChangeUserAccountId = 'ChangeUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  VersionMajor = 'VersionMajor',
  /** column name */
  VersionMinor = 'VersionMinor',
  /** column name */
  WipNumber = 'WIPNumber'
}

/** input type for updating data in table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Set_Input = {
  ChangeDateTime?: Maybe<Scalars['datetime']>;
  ChangeDescription?: Maybe<Scalars['ItemComment']>;
  ChangeUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
  WIPNumber?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ManufacturerChangeHistory_Stddev_Fields = {
  __typename?: 'ManufacturerChangeHistory_stddev_fields';
  ChangeHistoryId?: Maybe<Scalars['Float']>;
  ChangeUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Stddev_Order_By = {
  ChangeHistoryId?: Maybe<Order_By>;
  ChangeUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ManufacturerChangeHistory_Stddev_Pop_Fields = {
  __typename?: 'ManufacturerChangeHistory_stddev_pop_fields';
  ChangeHistoryId?: Maybe<Scalars['Float']>;
  ChangeUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Stddev_Pop_Order_By = {
  ChangeHistoryId?: Maybe<Order_By>;
  ChangeUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ManufacturerChangeHistory_Stddev_Samp_Fields = {
  __typename?: 'ManufacturerChangeHistory_stddev_samp_fields';
  ChangeHistoryId?: Maybe<Scalars['Float']>;
  ChangeUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Stddev_Samp_Order_By = {
  ChangeHistoryId?: Maybe<Order_By>;
  ChangeUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ManufacturerChangeHistory_Sum_Fields = {
  __typename?: 'ManufacturerChangeHistory_sum_fields';
  ChangeHistoryId?: Maybe<Scalars['Int']>;
  ChangeUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Sum_Order_By = {
  ChangeHistoryId?: Maybe<Order_By>;
  ChangeUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** update columns of table "ManufacturerChangeHistory" */
export enum ManufacturerChangeHistory_Update_Column {
  /** column name */
  ChangeDateTime = 'ChangeDateTime',
  /** column name */
  ChangeDescription = 'ChangeDescription',
  /** column name */
  ChangeUserAccountId = 'ChangeUserAccountId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  VersionMajor = 'VersionMajor',
  /** column name */
  VersionMinor = 'VersionMinor',
  /** column name */
  WipNumber = 'WIPNumber'
}

/** aggregate var_pop on columns */
export type ManufacturerChangeHistory_Var_Pop_Fields = {
  __typename?: 'ManufacturerChangeHistory_var_pop_fields';
  ChangeHistoryId?: Maybe<Scalars['Float']>;
  ChangeUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Var_Pop_Order_By = {
  ChangeHistoryId?: Maybe<Order_By>;
  ChangeUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ManufacturerChangeHistory_Var_Samp_Fields = {
  __typename?: 'ManufacturerChangeHistory_var_samp_fields';
  ChangeHistoryId?: Maybe<Scalars['Float']>;
  ChangeUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Var_Samp_Order_By = {
  ChangeHistoryId?: Maybe<Order_By>;
  ChangeUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ManufacturerChangeHistory_Variance_Fields = {
  __typename?: 'ManufacturerChangeHistory_variance_fields';
  ChangeHistoryId?: Maybe<Scalars['Float']>;
  ChangeUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ManufacturerChangeHistory" */
export type ManufacturerChangeHistory_Variance_Order_By = {
  ChangeHistoryId?: Maybe<Order_By>;
  ChangeUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** columns and relationships of "ManufacturerList" */
export type ManufacturerList = {
  __typename?: 'ManufacturerList';
  City: Scalars['String'];
  Country?: Maybe<Scalars['CodeTableValue']>;
  CountryId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['String']>;
  ManufacturerId: Scalars['Int'];
  Name: Scalars['String'];
  State?: Maybe<Scalars['CodeTableValue']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
  StatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregated selection of "ManufacturerList" */
export type ManufacturerList_Aggregate = {
  __typename?: 'ManufacturerList_aggregate';
  aggregate?: Maybe<ManufacturerList_Aggregate_Fields>;
  nodes: Array<ManufacturerList>;
};

/** aggregate fields of "ManufacturerList" */
export type ManufacturerList_Aggregate_Fields = {
  __typename?: 'ManufacturerList_aggregate_fields';
  avg?: Maybe<ManufacturerList_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ManufacturerList_Max_Fields>;
  min?: Maybe<ManufacturerList_Min_Fields>;
  stddev?: Maybe<ManufacturerList_Stddev_Fields>;
  stddev_pop?: Maybe<ManufacturerList_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ManufacturerList_Stddev_Samp_Fields>;
  sum?: Maybe<ManufacturerList_Sum_Fields>;
  var_pop?: Maybe<ManufacturerList_Var_Pop_Fields>;
  var_samp?: Maybe<ManufacturerList_Var_Samp_Fields>;
  variance?: Maybe<ManufacturerList_Variance_Fields>;
};


/** aggregate fields of "ManufacturerList" */
export type ManufacturerList_Aggregate_FieldsCountArgs = {
  column?: Maybe<ManufacturerList_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ManufacturerList_Avg_Fields = {
  __typename?: 'ManufacturerList_avg_fields';
  CountryId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ManufacturerList". All fields are combined with a logical 'AND'. */
export type ManufacturerList_Bool_Exp = {
  City?: Maybe<String_Mssql_Comparison_Exp>;
  Country?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  CountryId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateCreated?: Maybe<String_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  Name?: Maybe<String_Mssql_Comparison_Exp>;
  State?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  StateProvinceId?: Maybe<Int_Mssql_Comparison_Exp>;
  StatusCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ManufacturerList_Bool_Exp>>;
  _not?: Maybe<ManufacturerList_Bool_Exp>;
  _or?: Maybe<Array<ManufacturerList_Bool_Exp>>;
};

/** upsert condition type for table "ManufacturerList" */
export type ManufacturerList_If_Matched = {
  match_columns?: Array<ManufacturerList_Insert_Match_Column>;
  update_columns?: Array<ManufacturerList_Update_Column>;
  where?: Maybe<ManufacturerList_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ManufacturerList" */
export type ManufacturerList_Inc_Input = {
  CountryId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ManufacturerList" */
export type ManufacturerList_Insert_Input = {
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['CodeTableValue']>;
  CountryId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['CodeTableValue']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
  StatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** select match_columns of table "ManufacturerList" */
export enum ManufacturerList_Insert_Match_Column {
  /** column name */
  City = 'City',
  /** column name */
  Country = 'Country',
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  Name = 'Name',
  /** column name */
  State = 'State',
  /** column name */
  StateProvinceId = 'StateProvinceId',
  /** column name */
  StatusCode = 'StatusCode'
}

/** aggregate max on columns */
export type ManufacturerList_Max_Fields = {
  __typename?: 'ManufacturerList_max_fields';
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['CodeTableValue']>;
  CountryId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['CodeTableValue']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
  StatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate min on columns */
export type ManufacturerList_Min_Fields = {
  __typename?: 'ManufacturerList_min_fields';
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['CodeTableValue']>;
  CountryId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['CodeTableValue']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
  StatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** response of any mutation on the table "ManufacturerList" */
export type ManufacturerList_Mutation_Response = {
  __typename?: 'ManufacturerList_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ManufacturerList>;
};

/** Ordering options when selecting data from "ManufacturerList". */
export type ManufacturerList_Order_By = {
  City?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  CountryId?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  State?: Maybe<Order_By>;
  StateProvinceId?: Maybe<Order_By>;
  StatusCode?: Maybe<Order_By>;
};

/** select columns of table "ManufacturerList" */
export enum ManufacturerList_Select_Column {
  /** column name */
  City = 'City',
  /** column name */
  Country = 'Country',
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  Name = 'Name',
  /** column name */
  State = 'State',
  /** column name */
  StateProvinceId = 'StateProvinceId',
  /** column name */
  StatusCode = 'StatusCode'
}

/** input type for updating data in table "ManufacturerList" */
export type ManufacturerList_Set_Input = {
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['CodeTableValue']>;
  CountryId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['CodeTableValue']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
  StatusCode?: Maybe<Scalars['CodeTableValue']>;
};

/** aggregate stddev on columns */
export type ManufacturerList_Stddev_Fields = {
  __typename?: 'ManufacturerList_stddev_fields';
  CountryId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ManufacturerList_Stddev_Pop_Fields = {
  __typename?: 'ManufacturerList_stddev_pop_fields';
  CountryId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ManufacturerList_Stddev_Samp_Fields = {
  __typename?: 'ManufacturerList_stddev_samp_fields';
  CountryId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ManufacturerList_Sum_Fields = {
  __typename?: 'ManufacturerList_sum_fields';
  CountryId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
};

/** update columns of table "ManufacturerList" */
export enum ManufacturerList_Update_Column {
  /** column name */
  City = 'City',
  /** column name */
  Country = 'Country',
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  Name = 'Name',
  /** column name */
  State = 'State',
  /** column name */
  StateProvinceId = 'StateProvinceId',
  /** column name */
  StatusCode = 'StatusCode'
}

/** aggregate var_pop on columns */
export type ManufacturerList_Var_Pop_Fields = {
  __typename?: 'ManufacturerList_var_pop_fields';
  CountryId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ManufacturerList_Var_Samp_Fields = {
  __typename?: 'ManufacturerList_var_samp_fields';
  CountryId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ManufacturerList_Variance_Fields = {
  __typename?: 'ManufacturerList_variance_fields';
  CountryId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Manufacturer" */
export type Manufacturer_Aggregate = {
  __typename?: 'Manufacturer_aggregate';
  aggregate?: Maybe<Manufacturer_Aggregate_Fields>;
  nodes: Array<Manufacturer>;
};

/** aggregate fields of "Manufacturer" */
export type Manufacturer_Aggregate_Fields = {
  __typename?: 'Manufacturer_aggregate_fields';
  avg?: Maybe<Manufacturer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Manufacturer_Max_Fields>;
  min?: Maybe<Manufacturer_Min_Fields>;
  stddev?: Maybe<Manufacturer_Stddev_Fields>;
  stddev_pop?: Maybe<Manufacturer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Manufacturer_Stddev_Samp_Fields>;
  sum?: Maybe<Manufacturer_Sum_Fields>;
  var_pop?: Maybe<Manufacturer_Var_Pop_Fields>;
  var_samp?: Maybe<Manufacturer_Var_Samp_Fields>;
  variance?: Maybe<Manufacturer_Variance_Fields>;
};


/** aggregate fields of "Manufacturer" */
export type Manufacturer_Aggregate_FieldsCountArgs = {
  column?: Maybe<Manufacturer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Manufacturer" */
export type Manufacturer_Aggregate_Order_By = {
  avg?: Maybe<Manufacturer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Manufacturer_Max_Order_By>;
  min?: Maybe<Manufacturer_Min_Order_By>;
  stddev?: Maybe<Manufacturer_Stddev_Order_By>;
  stddev_pop?: Maybe<Manufacturer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Manufacturer_Stddev_Samp_Order_By>;
  sum?: Maybe<Manufacturer_Sum_Order_By>;
  var_pop?: Maybe<Manufacturer_Var_Pop_Order_By>;
  var_samp?: Maybe<Manufacturer_Var_Samp_Order_By>;
  variance?: Maybe<Manufacturer_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Manufacturer_Avg_Fields = {
  __typename?: 'Manufacturer_avg_fields';
  AddressId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Manufacturer" */
export type Manufacturer_Avg_Order_By = {
  AddressId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Manufacturer". All fields are combined with a logical 'AND'. */
export type Manufacturer_Bool_Exp = {
  Address?: Maybe<Address_Bool_Exp>;
  AddressId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_Status?: Maybe<C_Status_Bool_Exp>;
  CreatedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateCreated?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateEffective?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateInception?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  FMUserAccount?: Maybe<FmUserAccount_Bool_Exp>;
  ItemWorkspaces?: Maybe<ItemWorkspace_Bool_Exp>;
  ManufacturedProducts?: Maybe<ManufacturedProduct_Bool_Exp>;
  ManufacturerChangeHistories?: Maybe<ManufacturerChangeHistory_Bool_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  Name?: Maybe<String_Mssql_Comparison_Exp>;
  ReviewerWorkspaces?: Maybe<ReviewerWorkspace_Bool_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  VersionMajor?: Maybe<Int_Mssql_Comparison_Exp>;
  VersionMinor?: Maybe<Int_Mssql_Comparison_Exp>;
  WebSite?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Manufacturer_Bool_Exp>>;
  _not?: Maybe<Manufacturer_Bool_Exp>;
  _or?: Maybe<Array<Manufacturer_Bool_Exp>>;
  fMUserAccountByModifiedbyuseraccountid?: Maybe<FmUserAccount_Bool_Exp>;
};

/** upsert condition type for table "Manufacturer" */
export type Manufacturer_If_Matched = {
  match_columns?: Array<Manufacturer_Insert_Match_Column>;
  update_columns?: Array<Manufacturer_Update_Column>;
  where?: Maybe<Manufacturer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Manufacturer" */
export type Manufacturer_Inc_Input = {
  AddressId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Manufacturer" */
export type Manufacturer_Insert_Input = {
  AddressId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateInception?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
  WebSite?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Manufacturer" */
export enum Manufacturer_Insert_Match_Column {
  /** column name */
  AddressId = 'AddressId',
  /** column name */
  CreatedByUserAccountId = 'CreatedByUserAccountId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  DateEffective = 'DateEffective',
  /** column name */
  DateInception = 'DateInception',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Name = 'Name',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  VersionMajor = 'VersionMajor',
  /** column name */
  VersionMinor = 'VersionMinor',
  /** column name */
  WebSite = 'WebSite'
}

/** aggregate max on columns */
export type Manufacturer_Max_Fields = {
  __typename?: 'Manufacturer_max_fields';
  AddressId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateInception?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
  WebSite?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Manufacturer" */
export type Manufacturer_Max_Order_By = {
  AddressId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  DateEffective?: Maybe<Order_By>;
  DateInception?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WebSite?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Manufacturer_Min_Fields = {
  __typename?: 'Manufacturer_min_fields';
  AddressId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateInception?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
  WebSite?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Manufacturer" */
export type Manufacturer_Min_Order_By = {
  AddressId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  DateEffective?: Maybe<Order_By>;
  DateInception?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WebSite?: Maybe<Order_By>;
};

/** response of any mutation on the table "Manufacturer" */
export type Manufacturer_Mutation_Response = {
  __typename?: 'Manufacturer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Manufacturer>;
};

/** Ordering options when selecting data from "Manufacturer". */
export type Manufacturer_Order_By = {
  Address?: Maybe<Address_Order_By>;
  AddressId?: Maybe<Order_By>;
  C_Status?: Maybe<C_Status_Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  DateEffective?: Maybe<Order_By>;
  DateInception?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  FMUserAccount?: Maybe<FmUserAccount_Order_By>;
  ItemWorkspaces_aggregate?: Maybe<ItemWorkspace_Aggregate_Order_By>;
  ManufacturedProducts_aggregate?: Maybe<ManufacturedProduct_Aggregate_Order_By>;
  ManufacturerChangeHistories_aggregate?: Maybe<ManufacturerChangeHistory_Aggregate_Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  ReviewerWorkspaces_aggregate?: Maybe<ReviewerWorkspace_Aggregate_Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WebSite?: Maybe<Order_By>;
  fMUserAccountByModifiedbyuseraccountid?: Maybe<FmUserAccount_Order_By>;
};

/** primary key columns input for table: Manufacturer */
export type Manufacturer_Pk_Columns_Input = {
  ManufacturerId: Scalars['Int'];
};

/** select columns of table "Manufacturer" */
export enum Manufacturer_Select_Column {
  /** column name */
  AddressId = 'AddressId',
  /** column name */
  CreatedByUserAccountId = 'CreatedByUserAccountId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  DateEffective = 'DateEffective',
  /** column name */
  DateInception = 'DateInception',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Name = 'Name',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  VersionMajor = 'VersionMajor',
  /** column name */
  VersionMinor = 'VersionMinor',
  /** column name */
  WebSite = 'WebSite'
}

/** input type for updating data in table "Manufacturer" */
export type Manufacturer_Set_Input = {
  AddressId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateInception?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
  WebSite?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Manufacturer_Stddev_Fields = {
  __typename?: 'Manufacturer_stddev_fields';
  AddressId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Manufacturer" */
export type Manufacturer_Stddev_Order_By = {
  AddressId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Manufacturer_Stddev_Pop_Fields = {
  __typename?: 'Manufacturer_stddev_pop_fields';
  AddressId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Manufacturer" */
export type Manufacturer_Stddev_Pop_Order_By = {
  AddressId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Manufacturer_Stddev_Samp_Fields = {
  __typename?: 'Manufacturer_stddev_samp_fields';
  AddressId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Manufacturer" */
export type Manufacturer_Stddev_Samp_Order_By = {
  AddressId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Manufacturer_Sum_Fields = {
  __typename?: 'Manufacturer_sum_fields';
  AddressId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Manufacturer" */
export type Manufacturer_Sum_Order_By = {
  AddressId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** update columns of table "Manufacturer" */
export enum Manufacturer_Update_Column {
  /** column name */
  AddressId = 'AddressId',
  /** column name */
  CreatedByUserAccountId = 'CreatedByUserAccountId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  DateEffective = 'DateEffective',
  /** column name */
  DateInception = 'DateInception',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Name = 'Name',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  VersionMajor = 'VersionMajor',
  /** column name */
  VersionMinor = 'VersionMinor',
  /** column name */
  WebSite = 'WebSite'
}

/** aggregate var_pop on columns */
export type Manufacturer_Var_Pop_Fields = {
  __typename?: 'Manufacturer_var_pop_fields';
  AddressId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Manufacturer" */
export type Manufacturer_Var_Pop_Order_By = {
  AddressId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Manufacturer_Var_Samp_Fields = {
  __typename?: 'Manufacturer_var_samp_fields';
  AddressId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Manufacturer" */
export type Manufacturer_Var_Samp_Order_By = {
  AddressId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Manufacturer_Variance_Fields = {
  __typename?: 'Manufacturer_variance_fields';
  AddressId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Manufacturer" */
export type Manufacturer_Variance_Order_By = {
  AddressId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "MeasurementValue". All fields are combined with logical 'AND'. */
export type MeasurementValue_Mssql_Comparison_Exp = {
  _eq?: Maybe<Scalars['MeasurementValue']>;
  _gt?: Maybe<Scalars['MeasurementValue']>;
  _gte?: Maybe<Scalars['MeasurementValue']>;
  _in?: Maybe<Array<Scalars['MeasurementValue']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['MeasurementValue']>;
  _lte?: Maybe<Scalars['MeasurementValue']>;
  _neq?: Maybe<Scalars['MeasurementValue']>;
  _nin?: Maybe<Array<Scalars['MeasurementValue']>>;
};


/** Boolean expression to compare columns of type "MetricEntryFlag". All fields are combined with logical 'AND'. */
export type MetricEntryFlag_Mssql_Comparison_Exp = {
  _eq?: Maybe<Scalars['MetricEntryFlag']>;
  _gt?: Maybe<Scalars['MetricEntryFlag']>;
  _gte?: Maybe<Scalars['MetricEntryFlag']>;
  _in?: Maybe<Array<Scalars['MetricEntryFlag']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['MetricEntryFlag']>;
  _lte?: Maybe<Scalars['MetricEntryFlag']>;
  _neq?: Maybe<Scalars['MetricEntryFlag']>;
  _nin?: Maybe<Array<Scalars['MetricEntryFlag']>>;
};

/** columns and relationships of "MultiplyCoverConfig" */
export type MultiplyCoverConfig = {
  __typename?: 'MultiplyCoverConfig';
  CoverConfiguration: Scalars['String'];
  MultiplyCoverConfigId: Scalars['Int'];
  NumPlies: Scalars['Int'];
  /** An array relationship */
  R_Subassembly_MultiplyCoverConfigs: Array<R_Subassembly_MultiplyCoverConfig>;
  /** An aggregate relationship */
  R_Subassembly_MultiplyCoverConfigs_aggregate: R_Subassembly_MultiplyCoverConfig_Aggregate;
};


/** columns and relationships of "MultiplyCoverConfig" */
export type MultiplyCoverConfigR_Subassembly_MultiplyCoverConfigsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Subassembly_MultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_Subassembly_MultiplyCoverConfig_Bool_Exp>;
};


/** columns and relationships of "MultiplyCoverConfig" */
export type MultiplyCoverConfigR_Subassembly_MultiplyCoverConfigs_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Subassembly_MultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_Subassembly_MultiplyCoverConfig_Bool_Exp>;
};

/** aggregated selection of "MultiplyCoverConfig" */
export type MultiplyCoverConfig_Aggregate = {
  __typename?: 'MultiplyCoverConfig_aggregate';
  aggregate?: Maybe<MultiplyCoverConfig_Aggregate_Fields>;
  nodes: Array<MultiplyCoverConfig>;
};

/** aggregate fields of "MultiplyCoverConfig" */
export type MultiplyCoverConfig_Aggregate_Fields = {
  __typename?: 'MultiplyCoverConfig_aggregate_fields';
  avg?: Maybe<MultiplyCoverConfig_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MultiplyCoverConfig_Max_Fields>;
  min?: Maybe<MultiplyCoverConfig_Min_Fields>;
  stddev?: Maybe<MultiplyCoverConfig_Stddev_Fields>;
  stddev_pop?: Maybe<MultiplyCoverConfig_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MultiplyCoverConfig_Stddev_Samp_Fields>;
  sum?: Maybe<MultiplyCoverConfig_Sum_Fields>;
  var_pop?: Maybe<MultiplyCoverConfig_Var_Pop_Fields>;
  var_samp?: Maybe<MultiplyCoverConfig_Var_Samp_Fields>;
  variance?: Maybe<MultiplyCoverConfig_Variance_Fields>;
};


/** aggregate fields of "MultiplyCoverConfig" */
export type MultiplyCoverConfig_Aggregate_FieldsCountArgs = {
  column?: Maybe<MultiplyCoverConfig_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MultiplyCoverConfig_Avg_Fields = {
  __typename?: 'MultiplyCoverConfig_avg_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MultiplyCoverConfig". All fields are combined with a logical 'AND'. */
export type MultiplyCoverConfig_Bool_Exp = {
  CoverConfiguration?: Maybe<String_Mssql_Comparison_Exp>;
  MultiplyCoverConfigId?: Maybe<Int_Mssql_Comparison_Exp>;
  NumPlies?: Maybe<Int_Mssql_Comparison_Exp>;
  R_Subassembly_MultiplyCoverConfigs?: Maybe<R_Subassembly_MultiplyCoverConfig_Bool_Exp>;
  _and?: Maybe<Array<MultiplyCoverConfig_Bool_Exp>>;
  _not?: Maybe<MultiplyCoverConfig_Bool_Exp>;
  _or?: Maybe<Array<MultiplyCoverConfig_Bool_Exp>>;
};

/** upsert condition type for table "MultiplyCoverConfig" */
export type MultiplyCoverConfig_If_Matched = {
  match_columns?: Array<MultiplyCoverConfig_Insert_Match_Column>;
  update_columns?: Array<MultiplyCoverConfig_Update_Column>;
  where?: Maybe<MultiplyCoverConfig_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MultiplyCoverConfig" */
export type MultiplyCoverConfig_Inc_Input = {
  NumPlies?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MultiplyCoverConfig" */
export type MultiplyCoverConfig_Insert_Input = {
  CoverConfiguration?: Maybe<Scalars['String']>;
  NumPlies?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "MultiplyCoverConfig" */
export enum MultiplyCoverConfig_Insert_Match_Column {
  /** column name */
  CoverConfiguration = 'CoverConfiguration',
  /** column name */
  MultiplyCoverConfigId = 'MultiplyCoverConfigId',
  /** column name */
  NumPlies = 'NumPlies'
}

/** aggregate max on columns */
export type MultiplyCoverConfig_Max_Fields = {
  __typename?: 'MultiplyCoverConfig_max_fields';
  CoverConfiguration?: Maybe<Scalars['String']>;
  MultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type MultiplyCoverConfig_Min_Fields = {
  __typename?: 'MultiplyCoverConfig_min_fields';
  CoverConfiguration?: Maybe<Scalars['String']>;
  MultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "MultiplyCoverConfig" */
export type MultiplyCoverConfig_Mutation_Response = {
  __typename?: 'MultiplyCoverConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MultiplyCoverConfig>;
};

/** Ordering options when selecting data from "MultiplyCoverConfig". */
export type MultiplyCoverConfig_Order_By = {
  CoverConfiguration?: Maybe<Order_By>;
  MultiplyCoverConfigId?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  R_Subassembly_MultiplyCoverConfigs_aggregate?: Maybe<R_Subassembly_MultiplyCoverConfig_Aggregate_Order_By>;
};

/** primary key columns input for table: MultiplyCoverConfig */
export type MultiplyCoverConfig_Pk_Columns_Input = {
  MultiplyCoverConfigId: Scalars['Int'];
};

/** select columns of table "MultiplyCoverConfig" */
export enum MultiplyCoverConfig_Select_Column {
  /** column name */
  CoverConfiguration = 'CoverConfiguration',
  /** column name */
  MultiplyCoverConfigId = 'MultiplyCoverConfigId',
  /** column name */
  NumPlies = 'NumPlies'
}

/** input type for updating data in table "MultiplyCoverConfig" */
export type MultiplyCoverConfig_Set_Input = {
  CoverConfiguration?: Maybe<Scalars['String']>;
  NumPlies?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type MultiplyCoverConfig_Stddev_Fields = {
  __typename?: 'MultiplyCoverConfig_stddev_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MultiplyCoverConfig_Stddev_Pop_Fields = {
  __typename?: 'MultiplyCoverConfig_stddev_pop_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MultiplyCoverConfig_Stddev_Samp_Fields = {
  __typename?: 'MultiplyCoverConfig_stddev_samp_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type MultiplyCoverConfig_Sum_Fields = {
  __typename?: 'MultiplyCoverConfig_sum_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['Int']>;
};

/** update columns of table "MultiplyCoverConfig" */
export enum MultiplyCoverConfig_Update_Column {
  /** column name */
  CoverConfiguration = 'CoverConfiguration',
  /** column name */
  NumPlies = 'NumPlies'
}

/** aggregate var_pop on columns */
export type MultiplyCoverConfig_Var_Pop_Fields = {
  __typename?: 'MultiplyCoverConfig_var_pop_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MultiplyCoverConfig_Var_Samp_Fields = {
  __typename?: 'MultiplyCoverConfig_var_samp_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MultiplyCoverConfig_Variance_Fields = {
  __typename?: 'MultiplyCoverConfig_variance_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "MultiplyCoverSubassemblyUsage" */
export type MultiplyCoverSubassemblyUsage = {
  __typename?: 'MultiplyCoverSubassemblyUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "MultiplyCoverSubassemblyUsage" */
export type MultiplyCoverSubassemblyUsage_Aggregate = {
  __typename?: 'MultiplyCoverSubassemblyUsage_aggregate';
  aggregate?: Maybe<MultiplyCoverSubassemblyUsage_Aggregate_Fields>;
  nodes: Array<MultiplyCoverSubassemblyUsage>;
};

/** aggregate fields of "MultiplyCoverSubassemblyUsage" */
export type MultiplyCoverSubassemblyUsage_Aggregate_Fields = {
  __typename?: 'MultiplyCoverSubassemblyUsage_aggregate_fields';
  avg?: Maybe<MultiplyCoverSubassemblyUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MultiplyCoverSubassemblyUsage_Max_Fields>;
  min?: Maybe<MultiplyCoverSubassemblyUsage_Min_Fields>;
  stddev?: Maybe<MultiplyCoverSubassemblyUsage_Stddev_Fields>;
  stddev_pop?: Maybe<MultiplyCoverSubassemblyUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MultiplyCoverSubassemblyUsage_Stddev_Samp_Fields>;
  sum?: Maybe<MultiplyCoverSubassemblyUsage_Sum_Fields>;
  var_pop?: Maybe<MultiplyCoverSubassemblyUsage_Var_Pop_Fields>;
  var_samp?: Maybe<MultiplyCoverSubassemblyUsage_Var_Samp_Fields>;
  variance?: Maybe<MultiplyCoverSubassemblyUsage_Variance_Fields>;
};


/** aggregate fields of "MultiplyCoverSubassemblyUsage" */
export type MultiplyCoverSubassemblyUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<MultiplyCoverSubassemblyUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MultiplyCoverSubassemblyUsage_Avg_Fields = {
  __typename?: 'MultiplyCoverSubassemblyUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MultiplyCoverSubassemblyUsage". All fields are combined with a logical 'AND'. */
export type MultiplyCoverSubassemblyUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<MultiplyCoverSubassemblyUsage_Bool_Exp>>;
  _not?: Maybe<MultiplyCoverSubassemblyUsage_Bool_Exp>;
  _or?: Maybe<Array<MultiplyCoverSubassemblyUsage_Bool_Exp>>;
};

/** upsert condition type for table "MultiplyCoverSubassemblyUsage" */
export type MultiplyCoverSubassemblyUsage_If_Matched = {
  match_columns?: Array<MultiplyCoverSubassemblyUsage_Insert_Match_Column>;
  update_columns?: Array<MultiplyCoverSubassemblyUsage_Update_Column>;
  where?: Maybe<MultiplyCoverSubassemblyUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "MultiplyCoverSubassemblyUsage" */
export type MultiplyCoverSubassemblyUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MultiplyCoverSubassemblyUsage" */
export type MultiplyCoverSubassemblyUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "MultiplyCoverSubassemblyUsage" */
export enum MultiplyCoverSubassemblyUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type MultiplyCoverSubassemblyUsage_Max_Fields = {
  __typename?: 'MultiplyCoverSubassemblyUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type MultiplyCoverSubassemblyUsage_Min_Fields = {
  __typename?: 'MultiplyCoverSubassemblyUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "MultiplyCoverSubassemblyUsage" */
export type MultiplyCoverSubassemblyUsage_Mutation_Response = {
  __typename?: 'MultiplyCoverSubassemblyUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MultiplyCoverSubassemblyUsage>;
};

/** Ordering options when selecting data from "MultiplyCoverSubassemblyUsage". */
export type MultiplyCoverSubassemblyUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: MultiplyCoverSubassemblyUsage */
export type MultiplyCoverSubassemblyUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "MultiplyCoverSubassemblyUsage" */
export enum MultiplyCoverSubassemblyUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "MultiplyCoverSubassemblyUsage" */
export type MultiplyCoverSubassemblyUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type MultiplyCoverSubassemblyUsage_Stddev_Fields = {
  __typename?: 'MultiplyCoverSubassemblyUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MultiplyCoverSubassemblyUsage_Stddev_Pop_Fields = {
  __typename?: 'MultiplyCoverSubassemblyUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MultiplyCoverSubassemblyUsage_Stddev_Samp_Fields = {
  __typename?: 'MultiplyCoverSubassemblyUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type MultiplyCoverSubassemblyUsage_Sum_Fields = {
  __typename?: 'MultiplyCoverSubassemblyUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "MultiplyCoverSubassemblyUsage" */
export enum MultiplyCoverSubassemblyUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type MultiplyCoverSubassemblyUsage_Var_Pop_Fields = {
  __typename?: 'MultiplyCoverSubassemblyUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MultiplyCoverSubassemblyUsage_Var_Samp_Fields = {
  __typename?: 'MultiplyCoverSubassemblyUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MultiplyCoverSubassemblyUsage_Variance_Fields = {
  __typename?: 'MultiplyCoverSubassemblyUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "NavAssembly" */
export type NavAssembly = {
  __typename?: 'NavAssembly';
  AssemblyApplicationTypeCode?: Maybe<Scalars['String']>;
  AssemblyApplicationTypeId: Scalars['Int'];
  AssemblyId: Scalars['Int'];
  AssemblyTypeCode?: Maybe<Scalars['String']>;
  AssemblyTypeId: Scalars['Int'];
  /** An object relationship */
  C_Status: C_Status;
  CompleteLayerConfigId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodCode?: Maybe<Scalars['String']>;
  CoverSecurementMethodId: Scalars['Int'];
  CoverSubassemblyId?: Maybe<Scalars['Int']>;
  DeckSubassemblyId?: Maybe<Scalars['Int']>;
  DeckTypeCode?: Maybe<Scalars['String']>;
  DeckTypeId: Scalars['Int'];
  ExtFireRatingCode?: Maybe<Scalars['String']>;
  ExtFireRatingId: Scalars['Int'];
  ExtendedLayerConfigId?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId2?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId3?: Maybe<Scalars['Int']>;
  HailRatingCode?: Maybe<Scalars['String']>;
  HailRatingId: Scalars['Int'];
  IncludesPV?: Maybe<Scalars['Boolean']>;
  IntFireRatingCode?: Maybe<Scalars['String']>;
  IntFireRatingId: Scalars['Int'];
  /** An object relationship */
  LayerConfig?: Maybe<LayerConfig>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  NavAssemblyId: Scalars['Int'];
  NavAssemblyName: Scalars['String'];
  NavAssemblyValue?: Maybe<Scalars['Int']>;
  /** An array relationship */
  NavLayers: Array<NavLayer>;
  /** An aggregate relationship */
  NavLayers_aggregate: NavLayer_Aggregate;
  /** An array relationship */
  Nav_SinglePlyBondingAdhesives: Array<Nav_SinglePlyBondingAdhesives>;
  /** An aggregate relationship */
  Nav_SinglePlyBondingAdhesives_aggregate: Nav_SinglePlyBondingAdhesives_Aggregate;
  /** An array relationship */
  Nav_SinglePlyLapAdhesives: Array<Nav_SinglePlyLapAdhesives>;
  /** An aggregate relationship */
  Nav_SinglePlyLapAdhesives_aggregate: Nav_SinglePlyLapAdhesives_Aggregate;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  PVWindUplift?: Maybe<Scalars['MeasurementValue']>;
  /** An array relationship */
  R_NavAssembly_NavMultiplyCoverConfigs: Array<R_NavAssembly_NavMultiplyCoverConfig>;
  /** An aggregate relationship */
  R_NavAssembly_NavMultiplyCoverConfigs_aggregate: R_NavAssembly_NavMultiplyCoverConfig_Aggregate;
  /** An object relationship */
  RoofAssembly: RoofAssembly;
  /** An array relationship */
  SearchNavAssembly_ManufacturerProducts: Array<SearchNavAssembly_ManufacturerProduct>;
  /** An aggregate relationship */
  SearchNavAssembly_ManufacturerProducts_aggregate: SearchNavAssembly_ManufacturerProduct_Aggregate;
  Slope: Scalars['MeasurementValue'];
  SlopeValue?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Int']>;
  StatusId: Scalars['Int'];
  /** An array relationship */
  View_Assembly_Search_AirRetarder: Array<VNavAssembly_Search_AirRetarder>;
  /** An aggregate relationship */
  View_Assembly_Search_AirRetarder_aggregate: VNavAssembly_Search_AirRetarder_Aggregate;
  /** An array relationship */
  View_Assembly_Search_BaseCoat: Array<VNavAssembly_Search_BaseCoat>;
  /** An aggregate relationship */
  View_Assembly_Search_BaseCoat_aggregate: VNavAssembly_Search_BaseCoat_Aggregate;
  /** An array relationship */
  View_Assembly_Search_BasePly: Array<VNavAssembly_Search_BasePly>;
  /** An aggregate relationship */
  View_Assembly_Search_BasePly_aggregate: VNavAssembly_Search_BasePly_Aggregate;
  /** An array relationship */
  View_Assembly_Search_BaseSheet: Array<VNavAssembly_Search_BaseSheet>;
  /** An aggregate relationship */
  View_Assembly_Search_BaseSheet_aggregate: VNavAssembly_Search_BaseSheet_Aggregate;
  /** An array relationship */
  View_Assembly_Search_BoardStock: Array<VNavAssembly_Search_BoardStock>;
  /** An aggregate relationship */
  View_Assembly_Search_BoardStock_aggregate: VNavAssembly_Search_BoardStock_Aggregate;
  /** An array relationship */
  View_Assembly_Search_CapPly: Array<VNavAssembly_Search_CapPly>;
  /** An aggregate relationship */
  View_Assembly_Search_CapPly_aggregate: VNavAssembly_Search_CapPly_Aggregate;
  /** An array relationship */
  View_Assembly_Search_Coat: Array<VNavAssembly_Search_Coat>;
  /** An aggregate relationship */
  View_Assembly_Search_Coat_aggregate: VNavAssembly_Search_Coat_Aggregate;
  /** An array relationship */
  View_Assembly_Search_Coating: Array<VNavAssembly_Search_Coating>;
  /** An aggregate relationship */
  View_Assembly_Search_Coating_aggregate: VNavAssembly_Search_Coating_Aggregate;
  /** An array relationship */
  View_Assembly_Search_CoverBoard: Array<VNavAssembly_Search_CoverBoard>;
  /** An aggregate relationship */
  View_Assembly_Search_CoverBoard_aggregate: VNavAssembly_Search_CoverBoard_Aggregate;
  /** An array relationship */
  View_Assembly_Search_CoverCompositePanel: Array<VNavAssembly_Search_CoverCompositePanel>;
  /** An aggregate relationship */
  View_Assembly_Search_CoverCompositePanel_aggregate: VNavAssembly_Search_CoverCompositePanel_Aggregate;
  /** An array relationship */
  View_Assembly_Search_CoverSingleply: Array<VNavAssembly_Search_CoverSingleply>;
  /** An aggregate relationship */
  View_Assembly_Search_CoverSingleply_aggregate: VNavAssembly_Search_CoverSingleply_Aggregate;
  /** An array relationship */
  View_Assembly_Search_CoverStandingLapSeam: Array<VNavAssembly_Search_CoverStandingLapSeam>;
  /** An aggregate relationship */
  View_Assembly_Search_CoverStandingLapSeam_aggregate: VNavAssembly_Search_CoverStandingLapSeam_Aggregate;
  /** An array relationship */
  View_Assembly_Search_CoverStripDisk: Array<VNavAssembly_Search_CoverStripDisk>;
  /** An aggregate relationship */
  View_Assembly_Search_CoverStripDisk_aggregate: VNavAssembly_Search_CoverStripDisk_Aggregate;
  /** An array relationship */
  View_Assembly_Search_DeckCementitiousPanel: Array<VNavAssembly_Search_DeckCementitiousPanel>;
  /** An aggregate relationship */
  View_Assembly_Search_DeckCementitiousPanel_aggregate: VNavAssembly_Search_DeckCementitiousPanel_Aggregate;
  /** An array relationship */
  View_Assembly_Search_DeckSteel: Array<VNavAssembly_Search_DeckSteel>;
  /** An aggregate relationship */
  View_Assembly_Search_DeckSteel_aggregate: VNavAssembly_Search_DeckSteel_Aggregate;
  /** An array relationship */
  View_Assembly_Search_FormDeck: Array<VNavAssembly_Search_FormDeck>;
  /** An aggregate relationship */
  View_Assembly_Search_FormDeck_aggregate: VNavAssembly_Search_FormDeck_Aggregate;
  /** An array relationship */
  View_Assembly_Search_InsulationBattBlanket: Array<VNavAssembly_Search_InsulationBattBlanket>;
  /** An aggregate relationship */
  View_Assembly_Search_InsulationBattBlanket_aggregate: VNavAssembly_Search_InsulationBattBlanket_Aggregate;
  /** An array relationship */
  View_Assembly_Search_InsulationSprayFoam: Array<VNavAssembly_Search_InsulationSprayFoam>;
  /** An aggregate relationship */
  View_Assembly_Search_InsulationSprayFoam_aggregate: VNavAssembly_Search_InsulationSprayFoam_Aggregate;
  /** An array relationship */
  View_Assembly_Search_LightweightInsulatingConcrete: Array<VNavAssembly_Search_LightweightInsulatingConcrete>;
  /** An aggregate relationship */
  View_Assembly_Search_LightweightInsulatingConcrete_aggregate: VNavAssembly_Search_LightweightInsulatingConcrete_Aggregate;
  /** An array relationship */
  View_Assembly_Search_LinerPanel: Array<VNavAssembly_Search_LinerPanel>;
  /** An aggregate relationship */
  View_Assembly_Search_LinerPanel_aggregate: VNavAssembly_Search_LinerPanel_Aggregate;
  /** An array relationship */
  View_Assembly_Search_Ply: Array<VNavAssembly_Search_Ply>;
  /** An aggregate relationship */
  View_Assembly_Search_Ply_aggregate: VNavAssembly_Search_Ply_Aggregate;
  /** An array relationship */
  View_Assembly_Search_SeparatorSheet: Array<VNavAssembly_Search_SeparatorSheet>;
  /** An aggregate relationship */
  View_Assembly_Search_SeparatorSheet_aggregate: VNavAssembly_Search_SeparatorSheet_Aggregate;
  /** An array relationship */
  View_Assembly_Search_SubstratePrimer: Array<VNavAssembly_Search_SubstratePrimer>;
  /** An aggregate relationship */
  View_Assembly_Search_SubstratePrimer_aggregate: VNavAssembly_Search_SubstratePrimer_Aggregate;
  /** An array relationship */
  View_Assembly_Search_Surfacing: Array<VNavAssembly_Search_Surfacing>;
  /** An aggregate relationship */
  View_Assembly_Search_Surfacing_aggregate: VNavAssembly_Search_Surfacing_Aggregate;
  /** An array relationship */
  View_Assembly_Search_ThermalBarrier: Array<VNavAssembly_Search_ThermalBarrier>;
  /** An aggregate relationship */
  View_Assembly_Search_ThermalBarrier_aggregate: VNavAssembly_Search_ThermalBarrier_Aggregate;
  /** An array relationship */
  View_Assembly_Search_TopCoat: Array<VNavAssembly_Search_TopCoat>;
  /** An aggregate relationship */
  View_Assembly_Search_TopCoat_aggregate: VNavAssembly_Search_TopCoat_Aggregate;
  /** An array relationship */
  View_Assembly_Search_VaporRetarder: Array<VNavAssembly_Search_VaporRetarder>;
  /** An aggregate relationship */
  View_Assembly_Search_VaporRetarder_aggregate: VNavAssembly_Search_VaporRetarder_Aggregate;
  WindUplift: Scalars['MeasurementValue'];
  WindUpliftValue?: Maybe<Scalars['Float']>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyNavLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavLayer_Order_By>>;
  where?: Maybe<NavLayer_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyNavLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavLayer_Order_By>>;
  where?: Maybe<NavLayer_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyNav_SinglePlyBondingAdhesivesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nav_SinglePlyBondingAdhesives_Order_By>>;
  where?: Maybe<Nav_SinglePlyBondingAdhesives_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyNav_SinglePlyBondingAdhesives_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nav_SinglePlyBondingAdhesives_Order_By>>;
  where?: Maybe<Nav_SinglePlyBondingAdhesives_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyNav_SinglePlyLapAdhesivesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nav_SinglePlyLapAdhesives_Order_By>>;
  where?: Maybe<Nav_SinglePlyLapAdhesives_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyNav_SinglePlyLapAdhesives_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nav_SinglePlyLapAdhesives_Order_By>>;
  where?: Maybe<Nav_SinglePlyLapAdhesives_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyR_NavAssembly_NavMultiplyCoverConfigsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_NavAssembly_NavMultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyR_NavAssembly_NavMultiplyCoverConfigs_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_NavAssembly_NavMultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblySearchNavAssembly_ManufacturerProductsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SearchNavAssembly_ManufacturerProduct_Order_By>>;
  where?: Maybe<SearchNavAssembly_ManufacturerProduct_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblySearchNavAssembly_ManufacturerProducts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SearchNavAssembly_ManufacturerProduct_Order_By>>;
  where?: Maybe<SearchNavAssembly_ManufacturerProduct_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_AirRetarderArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_AirRetarder_Order_By>>;
  where?: Maybe<VNavAssembly_Search_AirRetarder_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_AirRetarder_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_AirRetarder_Order_By>>;
  where?: Maybe<VNavAssembly_Search_AirRetarder_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_BaseCoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BaseCoat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BaseCoat_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_BaseCoat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BaseCoat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BaseCoat_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_BasePlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BasePly_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BasePly_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_BasePly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BasePly_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BasePly_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_BaseSheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BaseSheet_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BaseSheet_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_BaseSheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BaseSheet_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BaseSheet_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_BoardStockArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BoardStock_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BoardStock_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_BoardStock_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BoardStock_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BoardStock_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_CapPlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CapPly_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CapPly_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_CapPly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CapPly_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CapPly_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_CoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Coat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Coat_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_Coat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Coat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Coat_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_CoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Coating_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Coating_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_Coating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Coating_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Coating_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_CoverBoardArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverBoard_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverBoard_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_CoverBoard_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverBoard_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverBoard_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_CoverCompositePanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverCompositePanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverCompositePanel_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_CoverCompositePanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverCompositePanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverCompositePanel_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_CoverSingleplyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverSingleply_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverSingleply_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_CoverSingleply_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverSingleply_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverSingleply_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_CoverStandingLapSeamArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverStandingLapSeam_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_CoverStandingLapSeam_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverStandingLapSeam_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_CoverStripDiskArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverStripDisk_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverStripDisk_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_CoverStripDisk_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverStripDisk_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverStripDisk_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_DeckCementitiousPanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_DeckCementitiousPanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_DeckCementitiousPanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_DeckCementitiousPanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_DeckSteelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_DeckSteel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_DeckSteel_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_DeckSteel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_DeckSteel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_DeckSteel_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_FormDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_FormDeck_Order_By>>;
  where?: Maybe<VNavAssembly_Search_FormDeck_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_FormDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_FormDeck_Order_By>>;
  where?: Maybe<VNavAssembly_Search_FormDeck_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_InsulationBattBlanketArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_InsulationBattBlanket_Order_By>>;
  where?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_InsulationBattBlanket_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_InsulationBattBlanket_Order_By>>;
  where?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_InsulationSprayFoamArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_InsulationSprayFoam_Order_By>>;
  where?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_InsulationSprayFoam_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_InsulationSprayFoam_Order_By>>;
  where?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_LightweightInsulatingConcreteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_LightweightInsulatingConcrete_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_LinerPanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_LinerPanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_LinerPanel_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_LinerPanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_LinerPanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_LinerPanel_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_PlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Ply_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Ply_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_Ply_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Ply_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Ply_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_SeparatorSheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_SeparatorSheet_Order_By>>;
  where?: Maybe<VNavAssembly_Search_SeparatorSheet_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_SeparatorSheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_SeparatorSheet_Order_By>>;
  where?: Maybe<VNavAssembly_Search_SeparatorSheet_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_SubstratePrimerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_SubstratePrimer_Order_By>>;
  where?: Maybe<VNavAssembly_Search_SubstratePrimer_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_SubstratePrimer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_SubstratePrimer_Order_By>>;
  where?: Maybe<VNavAssembly_Search_SubstratePrimer_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_SurfacingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Surfacing_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Surfacing_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_Surfacing_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Surfacing_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Surfacing_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_ThermalBarrierArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_ThermalBarrier_Order_By>>;
  where?: Maybe<VNavAssembly_Search_ThermalBarrier_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_ThermalBarrier_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_ThermalBarrier_Order_By>>;
  where?: Maybe<VNavAssembly_Search_ThermalBarrier_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_TopCoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_TopCoat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_TopCoat_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_TopCoat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_TopCoat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_TopCoat_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_VaporRetarderArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_VaporRetarder_Order_By>>;
  where?: Maybe<VNavAssembly_Search_VaporRetarder_Bool_Exp>;
};


/** columns and relationships of "NavAssembly" */
export type NavAssemblyView_Assembly_Search_VaporRetarder_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_VaporRetarder_Order_By>>;
  where?: Maybe<VNavAssembly_Search_VaporRetarder_Bool_Exp>;
};

/** columns and relationships of "NavAssemblyMultiplyInfo" */
export type NavAssemblyMultiplyInfo = {
  __typename?: 'NavAssemblyMultiplyInfo';
  CoverConfiguration: Scalars['String'];
  NavAssemblyId: Scalars['Int'];
  NumPlies: Scalars['Int'];
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "NavAssemblyMultiplyInfo" */
export type NavAssemblyMultiplyInfo_Aggregate = {
  __typename?: 'NavAssemblyMultiplyInfo_aggregate';
  aggregate?: Maybe<NavAssemblyMultiplyInfo_Aggregate_Fields>;
  nodes: Array<NavAssemblyMultiplyInfo>;
};

/** aggregate fields of "NavAssemblyMultiplyInfo" */
export type NavAssemblyMultiplyInfo_Aggregate_Fields = {
  __typename?: 'NavAssemblyMultiplyInfo_aggregate_fields';
  avg?: Maybe<NavAssemblyMultiplyInfo_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<NavAssemblyMultiplyInfo_Max_Fields>;
  min?: Maybe<NavAssemblyMultiplyInfo_Min_Fields>;
  stddev?: Maybe<NavAssemblyMultiplyInfo_Stddev_Fields>;
  stddev_pop?: Maybe<NavAssemblyMultiplyInfo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<NavAssemblyMultiplyInfo_Stddev_Samp_Fields>;
  sum?: Maybe<NavAssemblyMultiplyInfo_Sum_Fields>;
  var_pop?: Maybe<NavAssemblyMultiplyInfo_Var_Pop_Fields>;
  var_samp?: Maybe<NavAssemblyMultiplyInfo_Var_Samp_Fields>;
  variance?: Maybe<NavAssemblyMultiplyInfo_Variance_Fields>;
};


/** aggregate fields of "NavAssemblyMultiplyInfo" */
export type NavAssemblyMultiplyInfo_Aggregate_FieldsCountArgs = {
  column?: Maybe<NavAssemblyMultiplyInfo_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type NavAssemblyMultiplyInfo_Avg_Fields = {
  __typename?: 'NavAssemblyMultiplyInfo_avg_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "NavAssemblyMultiplyInfo". All fields are combined with a logical 'AND'. */
export type NavAssemblyMultiplyInfo_Bool_Exp = {
  CoverConfiguration?: Maybe<String_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NumPlies?: Maybe<Int_Mssql_Comparison_Exp>;
  SubassemblyTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<NavAssemblyMultiplyInfo_Bool_Exp>>;
  _not?: Maybe<NavAssemblyMultiplyInfo_Bool_Exp>;
  _or?: Maybe<Array<NavAssemblyMultiplyInfo_Bool_Exp>>;
};

/** upsert condition type for table "NavAssemblyMultiplyInfo" */
export type NavAssemblyMultiplyInfo_If_Matched = {
  match_columns?: Array<NavAssemblyMultiplyInfo_Insert_Match_Column>;
  update_columns?: Array<NavAssemblyMultiplyInfo_Update_Column>;
  where?: Maybe<NavAssemblyMultiplyInfo_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "NavAssemblyMultiplyInfo" */
export type NavAssemblyMultiplyInfo_Inc_Input = {
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "NavAssemblyMultiplyInfo" */
export type NavAssemblyMultiplyInfo_Insert_Input = {
  CoverConfiguration?: Maybe<Scalars['String']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "NavAssemblyMultiplyInfo" */
export enum NavAssemblyMultiplyInfo_Insert_Match_Column {
  /** column name */
  CoverConfiguration = 'CoverConfiguration',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  SubassemblyTypeId = 'SubassemblyTypeId'
}

/** aggregate max on columns */
export type NavAssemblyMultiplyInfo_Max_Fields = {
  __typename?: 'NavAssemblyMultiplyInfo_max_fields';
  CoverConfiguration?: Maybe<Scalars['String']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type NavAssemblyMultiplyInfo_Min_Fields = {
  __typename?: 'NavAssemblyMultiplyInfo_min_fields';
  CoverConfiguration?: Maybe<Scalars['String']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "NavAssemblyMultiplyInfo" */
export type NavAssemblyMultiplyInfo_Mutation_Response = {
  __typename?: 'NavAssemblyMultiplyInfo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NavAssemblyMultiplyInfo>;
};

/** Ordering options when selecting data from "NavAssemblyMultiplyInfo". */
export type NavAssemblyMultiplyInfo_Order_By = {
  CoverConfiguration?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** select columns of table "NavAssemblyMultiplyInfo" */
export enum NavAssemblyMultiplyInfo_Select_Column {
  /** column name */
  CoverConfiguration = 'CoverConfiguration',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  SubassemblyTypeId = 'SubassemblyTypeId'
}

/** input type for updating data in table "NavAssemblyMultiplyInfo" */
export type NavAssemblyMultiplyInfo_Set_Input = {
  CoverConfiguration?: Maybe<Scalars['String']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type NavAssemblyMultiplyInfo_Stddev_Fields = {
  __typename?: 'NavAssemblyMultiplyInfo_stddev_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type NavAssemblyMultiplyInfo_Stddev_Pop_Fields = {
  __typename?: 'NavAssemblyMultiplyInfo_stddev_pop_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type NavAssemblyMultiplyInfo_Stddev_Samp_Fields = {
  __typename?: 'NavAssemblyMultiplyInfo_stddev_samp_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type NavAssemblyMultiplyInfo_Sum_Fields = {
  __typename?: 'NavAssemblyMultiplyInfo_sum_fields';
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "NavAssemblyMultiplyInfo" */
export enum NavAssemblyMultiplyInfo_Update_Column {
  /** column name */
  CoverConfiguration = 'CoverConfiguration',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  SubassemblyTypeId = 'SubassemblyTypeId'
}

/** aggregate var_pop on columns */
export type NavAssemblyMultiplyInfo_Var_Pop_Fields = {
  __typename?: 'NavAssemblyMultiplyInfo_var_pop_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type NavAssemblyMultiplyInfo_Var_Samp_Fields = {
  __typename?: 'NavAssemblyMultiplyInfo_var_samp_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type NavAssemblyMultiplyInfo_Variance_Fields = {
  __typename?: 'NavAssemblyMultiplyInfo_variance_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "NavAssembly" */
export type NavAssembly_Aggregate = {
  __typename?: 'NavAssembly_aggregate';
  aggregate?: Maybe<NavAssembly_Aggregate_Fields>;
  nodes: Array<NavAssembly>;
};

/** aggregate fields of "NavAssembly" */
export type NavAssembly_Aggregate_Fields = {
  __typename?: 'NavAssembly_aggregate_fields';
  avg?: Maybe<NavAssembly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<NavAssembly_Max_Fields>;
  min?: Maybe<NavAssembly_Min_Fields>;
  stddev?: Maybe<NavAssembly_Stddev_Fields>;
  stddev_pop?: Maybe<NavAssembly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<NavAssembly_Stddev_Samp_Fields>;
  sum?: Maybe<NavAssembly_Sum_Fields>;
  var_pop?: Maybe<NavAssembly_Var_Pop_Fields>;
  var_samp?: Maybe<NavAssembly_Var_Samp_Fields>;
  variance?: Maybe<NavAssembly_Variance_Fields>;
};


/** aggregate fields of "NavAssembly" */
export type NavAssembly_Aggregate_FieldsCountArgs = {
  column?: Maybe<NavAssembly_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "NavAssembly" */
export type NavAssembly_Aggregate_Order_By = {
  avg?: Maybe<NavAssembly_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<NavAssembly_Max_Order_By>;
  min?: Maybe<NavAssembly_Min_Order_By>;
  stddev?: Maybe<NavAssembly_Stddev_Order_By>;
  stddev_pop?: Maybe<NavAssembly_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<NavAssembly_Stddev_Samp_Order_By>;
  sum?: Maybe<NavAssembly_Sum_Order_By>;
  var_pop?: Maybe<NavAssembly_Var_Pop_Order_By>;
  var_samp?: Maybe<NavAssembly_Var_Samp_Order_By>;
  variance?: Maybe<NavAssembly_Variance_Order_By>;
};

/** aggregate avg on columns */
export type NavAssembly_Avg_Fields = {
  __typename?: 'NavAssembly_avg_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  CompleteLayerConfigId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  CoverSubassemblyId?: Maybe<Scalars['Float']>;
  DeckSubassemblyId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId2?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId3?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  LayerConfigId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavAssemblyValue?: Maybe<Scalars['Float']>;
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
  PVHailRatingId?: Maybe<Scalars['Float']>;
  SlopeValue?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  WindUpliftValue?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "NavAssembly" */
export type NavAssembly_Avg_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CompleteLayerConfigId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CoverSubassemblyId?: Maybe<Order_By>;
  DeckSubassemblyId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  ExtendedLayerConfigId?: Maybe<Order_By>;
  ExtendedLayerConfigId2?: Maybe<Order_By>;
  ExtendedLayerConfigId3?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyValue?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  SlopeValue?: Maybe<Order_By>;
  SplitLayerConfigId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WindUpliftValue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "NavAssembly". All fields are combined with a logical 'AND'. */
export type NavAssembly_Bool_Exp = {
  AssemblyApplicationTypeCode?: Maybe<String_Mssql_Comparison_Exp>;
  AssemblyApplicationTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  AssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  AssemblyTypeCode?: Maybe<String_Mssql_Comparison_Exp>;
  AssemblyTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_Status?: Maybe<C_Status_Bool_Exp>;
  CompleteLayerConfigId?: Maybe<Int_Mssql_Comparison_Exp>;
  CoverSecurementMethodCode?: Maybe<String_Mssql_Comparison_Exp>;
  CoverSecurementMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  CoverSubassemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  DeckSubassemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  DeckTypeCode?: Maybe<String_Mssql_Comparison_Exp>;
  DeckTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ExtFireRatingCode?: Maybe<String_Mssql_Comparison_Exp>;
  ExtFireRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  ExtendedLayerConfigId?: Maybe<Int_Mssql_Comparison_Exp>;
  ExtendedLayerConfigId2?: Maybe<Int_Mssql_Comparison_Exp>;
  ExtendedLayerConfigId3?: Maybe<Int_Mssql_Comparison_Exp>;
  HailRatingCode?: Maybe<String_Mssql_Comparison_Exp>;
  HailRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  IncludesPV?: Maybe<Boolean_Mssql_Comparison_Exp>;
  IntFireRatingCode?: Maybe<String_Mssql_Comparison_Exp>;
  IntFireRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  LayerConfig?: Maybe<LayerConfig_Bool_Exp>;
  LayerConfigId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyName?: Maybe<String_Mssql_Comparison_Exp>;
  NavAssemblyValue?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayers?: Maybe<NavLayer_Bool_Exp>;
  Nav_SinglePlyBondingAdhesives?: Maybe<Nav_SinglePlyBondingAdhesives_Bool_Exp>;
  Nav_SinglePlyLapAdhesives?: Maybe<Nav_SinglePlyLapAdhesives_Bool_Exp>;
  PVApplicationMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  PVHailRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  PVWindUplift?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  R_NavAssembly_NavMultiplyCoverConfigs?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp>;
  RoofAssembly?: Maybe<RoofAssembly_Bool_Exp>;
  SearchNavAssembly_ManufacturerProducts?: Maybe<SearchNavAssembly_ManufacturerProduct_Bool_Exp>;
  Slope?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  SlopeValue?: Maybe<Float_Mssql_Comparison_Exp>;
  SplitLayerConfigId?: Maybe<Int_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  View_Assembly_Search_AirRetarder?: Maybe<VNavAssembly_Search_AirRetarder_Bool_Exp>;
  View_Assembly_Search_BaseCoat?: Maybe<VNavAssembly_Search_BaseCoat_Bool_Exp>;
  View_Assembly_Search_BasePly?: Maybe<VNavAssembly_Search_BasePly_Bool_Exp>;
  View_Assembly_Search_BaseSheet?: Maybe<VNavAssembly_Search_BaseSheet_Bool_Exp>;
  View_Assembly_Search_BoardStock?: Maybe<VNavAssembly_Search_BoardStock_Bool_Exp>;
  View_Assembly_Search_CapPly?: Maybe<VNavAssembly_Search_CapPly_Bool_Exp>;
  View_Assembly_Search_Coat?: Maybe<VNavAssembly_Search_Coat_Bool_Exp>;
  View_Assembly_Search_Coating?: Maybe<VNavAssembly_Search_Coating_Bool_Exp>;
  View_Assembly_Search_CoverBoard?: Maybe<VNavAssembly_Search_CoverBoard_Bool_Exp>;
  View_Assembly_Search_CoverCompositePanel?: Maybe<VNavAssembly_Search_CoverCompositePanel_Bool_Exp>;
  View_Assembly_Search_CoverSingleply?: Maybe<VNavAssembly_Search_CoverSingleply_Bool_Exp>;
  View_Assembly_Search_CoverStandingLapSeam?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Bool_Exp>;
  View_Assembly_Search_CoverStripDisk?: Maybe<VNavAssembly_Search_CoverStripDisk_Bool_Exp>;
  View_Assembly_Search_DeckCementitiousPanel?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Bool_Exp>;
  View_Assembly_Search_DeckSteel?: Maybe<VNavAssembly_Search_DeckSteel_Bool_Exp>;
  View_Assembly_Search_FormDeck?: Maybe<VNavAssembly_Search_FormDeck_Bool_Exp>;
  View_Assembly_Search_InsulationBattBlanket?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Bool_Exp>;
  View_Assembly_Search_InsulationSprayFoam?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Bool_Exp>;
  View_Assembly_Search_LightweightInsulatingConcrete?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Bool_Exp>;
  View_Assembly_Search_LinerPanel?: Maybe<VNavAssembly_Search_LinerPanel_Bool_Exp>;
  View_Assembly_Search_Ply?: Maybe<VNavAssembly_Search_Ply_Bool_Exp>;
  View_Assembly_Search_SeparatorSheet?: Maybe<VNavAssembly_Search_SeparatorSheet_Bool_Exp>;
  View_Assembly_Search_SubstratePrimer?: Maybe<VNavAssembly_Search_SubstratePrimer_Bool_Exp>;
  View_Assembly_Search_Surfacing?: Maybe<VNavAssembly_Search_Surfacing_Bool_Exp>;
  View_Assembly_Search_ThermalBarrier?: Maybe<VNavAssembly_Search_ThermalBarrier_Bool_Exp>;
  View_Assembly_Search_TopCoat?: Maybe<VNavAssembly_Search_TopCoat_Bool_Exp>;
  View_Assembly_Search_VaporRetarder?: Maybe<VNavAssembly_Search_VaporRetarder_Bool_Exp>;
  WindUplift?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  WindUpliftValue?: Maybe<Float_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<NavAssembly_Bool_Exp>>;
  _not?: Maybe<NavAssembly_Bool_Exp>;
  _or?: Maybe<Array<NavAssembly_Bool_Exp>>;
};

/** upsert condition type for table "NavAssembly" */
export type NavAssembly_If_Matched = {
  match_columns?: Array<NavAssembly_Insert_Match_Column>;
  update_columns?: Array<NavAssembly_Update_Column>;
  where?: Maybe<NavAssembly_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "NavAssembly" */
export type NavAssembly_Inc_Input = {
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  CompleteLayerConfigId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  CoverSubassemblyId?: Maybe<Scalars['Int']>;
  DeckSubassemblyId?: Maybe<Scalars['Int']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId2?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId3?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  NavAssemblyValue?: Maybe<Scalars['Int']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  SlopeValue?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  WindUpliftValue?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "NavAssembly" */
export type NavAssembly_Insert_Input = {
  AssemblyApplicationTypeCode?: Maybe<Scalars['String']>;
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyTypeCode?: Maybe<Scalars['String']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  CompleteLayerConfigId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodCode?: Maybe<Scalars['String']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  CoverSubassemblyId?: Maybe<Scalars['Int']>;
  DeckSubassemblyId?: Maybe<Scalars['Int']>;
  DeckTypeCode?: Maybe<Scalars['String']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingCode?: Maybe<Scalars['String']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId2?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId3?: Maybe<Scalars['Int']>;
  HailRatingCode?: Maybe<Scalars['String']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IncludesPV?: Maybe<Scalars['Boolean']>;
  IntFireRatingCode?: Maybe<Scalars['String']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  NavAssemblyName?: Maybe<Scalars['String']>;
  NavAssemblyValue?: Maybe<Scalars['Int']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  PVWindUplift?: Maybe<Scalars['MeasurementValue']>;
  Slope?: Maybe<Scalars['MeasurementValue']>;
  SlopeValue?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
  WindUpliftValue?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "NavAssembly" */
export enum NavAssembly_Insert_Match_Column {
  /** column name */
  AssemblyApplicationTypeCode = 'AssemblyApplicationTypeCode',
  /** column name */
  AssemblyApplicationTypeId = 'AssemblyApplicationTypeId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  AssemblyTypeCode = 'AssemblyTypeCode',
  /** column name */
  AssemblyTypeId = 'AssemblyTypeId',
  /** column name */
  CompleteLayerConfigId = 'CompleteLayerConfigId',
  /** column name */
  CoverSecurementMethodCode = 'CoverSecurementMethodCode',
  /** column name */
  CoverSecurementMethodId = 'CoverSecurementMethodId',
  /** column name */
  CoverSubassemblyId = 'CoverSubassemblyId',
  /** column name */
  DeckSubassemblyId = 'DeckSubassemblyId',
  /** column name */
  DeckTypeCode = 'DeckTypeCode',
  /** column name */
  DeckTypeId = 'DeckTypeId',
  /** column name */
  ExtFireRatingCode = 'ExtFireRatingCode',
  /** column name */
  ExtFireRatingId = 'ExtFireRatingId',
  /** column name */
  ExtendedLayerConfigId = 'ExtendedLayerConfigId',
  /** column name */
  ExtendedLayerConfigId2 = 'ExtendedLayerConfigId2',
  /** column name */
  ExtendedLayerConfigId3 = 'ExtendedLayerConfigId3',
  /** column name */
  HailRatingCode = 'HailRatingCode',
  /** column name */
  HailRatingId = 'HailRatingId',
  /** column name */
  IncludesPv = 'IncludesPV',
  /** column name */
  IntFireRatingCode = 'IntFireRatingCode',
  /** column name */
  IntFireRatingId = 'IntFireRatingId',
  /** column name */
  LayerConfigId = 'LayerConfigId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavAssemblyName = 'NavAssemblyName',
  /** column name */
  NavAssemblyValue = 'NavAssemblyValue',
  /** column name */
  PvApplicationMethodId = 'PVApplicationMethodId',
  /** column name */
  PvHailRatingId = 'PVHailRatingId',
  /** column name */
  PvWindUplift = 'PVWindUplift',
  /** column name */
  Slope = 'Slope',
  /** column name */
  SlopeValue = 'SlopeValue',
  /** column name */
  SplitLayerConfigId = 'SplitLayerConfigId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  WindUplift = 'WindUplift',
  /** column name */
  WindUpliftValue = 'WindUpliftValue'
}

/** aggregate max on columns */
export type NavAssembly_Max_Fields = {
  __typename?: 'NavAssembly_max_fields';
  AssemblyApplicationTypeCode?: Maybe<Scalars['String']>;
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyTypeCode?: Maybe<Scalars['String']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  CompleteLayerConfigId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodCode?: Maybe<Scalars['String']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  CoverSubassemblyId?: Maybe<Scalars['Int']>;
  DeckSubassemblyId?: Maybe<Scalars['Int']>;
  DeckTypeCode?: Maybe<Scalars['String']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingCode?: Maybe<Scalars['String']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId2?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId3?: Maybe<Scalars['Int']>;
  HailRatingCode?: Maybe<Scalars['String']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingCode?: Maybe<Scalars['String']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyName?: Maybe<Scalars['String']>;
  NavAssemblyValue?: Maybe<Scalars['Int']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  PVWindUplift?: Maybe<Scalars['MeasurementValue']>;
  Slope?: Maybe<Scalars['MeasurementValue']>;
  SlopeValue?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
  WindUpliftValue?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "NavAssembly" */
export type NavAssembly_Max_Order_By = {
  AssemblyApplicationTypeCode?: Maybe<Order_By>;
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeCode?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CompleteLayerConfigId?: Maybe<Order_By>;
  CoverSecurementMethodCode?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CoverSubassemblyId?: Maybe<Order_By>;
  DeckSubassemblyId?: Maybe<Order_By>;
  DeckTypeCode?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingCode?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  ExtendedLayerConfigId?: Maybe<Order_By>;
  ExtendedLayerConfigId2?: Maybe<Order_By>;
  ExtendedLayerConfigId3?: Maybe<Order_By>;
  HailRatingCode?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingCode?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyName?: Maybe<Order_By>;
  NavAssemblyValue?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  PVWindUplift?: Maybe<Order_By>;
  Slope?: Maybe<Order_By>;
  SlopeValue?: Maybe<Order_By>;
  SplitLayerConfigId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
  WindUpliftValue?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type NavAssembly_Min_Fields = {
  __typename?: 'NavAssembly_min_fields';
  AssemblyApplicationTypeCode?: Maybe<Scalars['String']>;
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyTypeCode?: Maybe<Scalars['String']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  CompleteLayerConfigId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodCode?: Maybe<Scalars['String']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  CoverSubassemblyId?: Maybe<Scalars['Int']>;
  DeckSubassemblyId?: Maybe<Scalars['Int']>;
  DeckTypeCode?: Maybe<Scalars['String']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingCode?: Maybe<Scalars['String']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId2?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId3?: Maybe<Scalars['Int']>;
  HailRatingCode?: Maybe<Scalars['String']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingCode?: Maybe<Scalars['String']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyName?: Maybe<Scalars['String']>;
  NavAssemblyValue?: Maybe<Scalars['Int']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  PVWindUplift?: Maybe<Scalars['MeasurementValue']>;
  Slope?: Maybe<Scalars['MeasurementValue']>;
  SlopeValue?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
  WindUpliftValue?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "NavAssembly" */
export type NavAssembly_Min_Order_By = {
  AssemblyApplicationTypeCode?: Maybe<Order_By>;
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeCode?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CompleteLayerConfigId?: Maybe<Order_By>;
  CoverSecurementMethodCode?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CoverSubassemblyId?: Maybe<Order_By>;
  DeckSubassemblyId?: Maybe<Order_By>;
  DeckTypeCode?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingCode?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  ExtendedLayerConfigId?: Maybe<Order_By>;
  ExtendedLayerConfigId2?: Maybe<Order_By>;
  ExtendedLayerConfigId3?: Maybe<Order_By>;
  HailRatingCode?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingCode?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyName?: Maybe<Order_By>;
  NavAssemblyValue?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  PVWindUplift?: Maybe<Order_By>;
  Slope?: Maybe<Order_By>;
  SlopeValue?: Maybe<Order_By>;
  SplitLayerConfigId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
  WindUpliftValue?: Maybe<Order_By>;
};

/** response of any mutation on the table "NavAssembly" */
export type NavAssembly_Mutation_Response = {
  __typename?: 'NavAssembly_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NavAssembly>;
};

/** Ordering options when selecting data from "NavAssembly". */
export type NavAssembly_Order_By = {
  AssemblyApplicationTypeCode?: Maybe<Order_By>;
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeCode?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  C_Status?: Maybe<C_Status_Order_By>;
  CompleteLayerConfigId?: Maybe<Order_By>;
  CoverSecurementMethodCode?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CoverSubassemblyId?: Maybe<Order_By>;
  DeckSubassemblyId?: Maybe<Order_By>;
  DeckTypeCode?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingCode?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  ExtendedLayerConfigId?: Maybe<Order_By>;
  ExtendedLayerConfigId2?: Maybe<Order_By>;
  ExtendedLayerConfigId3?: Maybe<Order_By>;
  HailRatingCode?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IncludesPV?: Maybe<Order_By>;
  IntFireRatingCode?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfig?: Maybe<LayerConfig_Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyName?: Maybe<Order_By>;
  NavAssemblyValue?: Maybe<Order_By>;
  NavLayers_aggregate?: Maybe<NavLayer_Aggregate_Order_By>;
  Nav_SinglePlyBondingAdhesives_aggregate?: Maybe<Nav_SinglePlyBondingAdhesives_Aggregate_Order_By>;
  Nav_SinglePlyLapAdhesives_aggregate?: Maybe<Nav_SinglePlyLapAdhesives_Aggregate_Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  PVWindUplift?: Maybe<Order_By>;
  R_NavAssembly_NavMultiplyCoverConfigs_aggregate?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Aggregate_Order_By>;
  RoofAssembly?: Maybe<RoofAssembly_Order_By>;
  SearchNavAssembly_ManufacturerProducts_aggregate?: Maybe<SearchNavAssembly_ManufacturerProduct_Aggregate_Order_By>;
  Slope?: Maybe<Order_By>;
  SlopeValue?: Maybe<Order_By>;
  SplitLayerConfigId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  View_Assembly_Search_AirRetarder_aggregate?: Maybe<VNavAssembly_Search_AirRetarder_Aggregate_Order_By>;
  View_Assembly_Search_BaseCoat_aggregate?: Maybe<VNavAssembly_Search_BaseCoat_Aggregate_Order_By>;
  View_Assembly_Search_BasePly_aggregate?: Maybe<VNavAssembly_Search_BasePly_Aggregate_Order_By>;
  View_Assembly_Search_BaseSheet_aggregate?: Maybe<VNavAssembly_Search_BaseSheet_Aggregate_Order_By>;
  View_Assembly_Search_BoardStock_aggregate?: Maybe<VNavAssembly_Search_BoardStock_Aggregate_Order_By>;
  View_Assembly_Search_CapPly_aggregate?: Maybe<VNavAssembly_Search_CapPly_Aggregate_Order_By>;
  View_Assembly_Search_Coat_aggregate?: Maybe<VNavAssembly_Search_Coat_Aggregate_Order_By>;
  View_Assembly_Search_Coating_aggregate?: Maybe<VNavAssembly_Search_Coating_Aggregate_Order_By>;
  View_Assembly_Search_CoverBoard_aggregate?: Maybe<VNavAssembly_Search_CoverBoard_Aggregate_Order_By>;
  View_Assembly_Search_CoverCompositePanel_aggregate?: Maybe<VNavAssembly_Search_CoverCompositePanel_Aggregate_Order_By>;
  View_Assembly_Search_CoverSingleply_aggregate?: Maybe<VNavAssembly_Search_CoverSingleply_Aggregate_Order_By>;
  View_Assembly_Search_CoverStandingLapSeam_aggregate?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Aggregate_Order_By>;
  View_Assembly_Search_CoverStripDisk_aggregate?: Maybe<VNavAssembly_Search_CoverStripDisk_Aggregate_Order_By>;
  View_Assembly_Search_DeckCementitiousPanel_aggregate?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Aggregate_Order_By>;
  View_Assembly_Search_DeckSteel_aggregate?: Maybe<VNavAssembly_Search_DeckSteel_Aggregate_Order_By>;
  View_Assembly_Search_FormDeck_aggregate?: Maybe<VNavAssembly_Search_FormDeck_Aggregate_Order_By>;
  View_Assembly_Search_InsulationBattBlanket_aggregate?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Aggregate_Order_By>;
  View_Assembly_Search_InsulationSprayFoam_aggregate?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Aggregate_Order_By>;
  View_Assembly_Search_LightweightInsulatingConcrete_aggregate?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Aggregate_Order_By>;
  View_Assembly_Search_LinerPanel_aggregate?: Maybe<VNavAssembly_Search_LinerPanel_Aggregate_Order_By>;
  View_Assembly_Search_Ply_aggregate?: Maybe<VNavAssembly_Search_Ply_Aggregate_Order_By>;
  View_Assembly_Search_SeparatorSheet_aggregate?: Maybe<VNavAssembly_Search_SeparatorSheet_Aggregate_Order_By>;
  View_Assembly_Search_SubstratePrimer_aggregate?: Maybe<VNavAssembly_Search_SubstratePrimer_Aggregate_Order_By>;
  View_Assembly_Search_Surfacing_aggregate?: Maybe<VNavAssembly_Search_Surfacing_Aggregate_Order_By>;
  View_Assembly_Search_ThermalBarrier_aggregate?: Maybe<VNavAssembly_Search_ThermalBarrier_Aggregate_Order_By>;
  View_Assembly_Search_TopCoat_aggregate?: Maybe<VNavAssembly_Search_TopCoat_Aggregate_Order_By>;
  View_Assembly_Search_VaporRetarder_aggregate?: Maybe<VNavAssembly_Search_VaporRetarder_Aggregate_Order_By>;
  WindUplift?: Maybe<Order_By>;
  WindUpliftValue?: Maybe<Order_By>;
};

/** primary key columns input for table: NavAssembly */
export type NavAssembly_Pk_Columns_Input = {
  NavAssemblyId: Scalars['Int'];
};

/** select columns of table "NavAssembly" */
export enum NavAssembly_Select_Column {
  /** column name */
  AssemblyApplicationTypeCode = 'AssemblyApplicationTypeCode',
  /** column name */
  AssemblyApplicationTypeId = 'AssemblyApplicationTypeId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  AssemblyTypeCode = 'AssemblyTypeCode',
  /** column name */
  AssemblyTypeId = 'AssemblyTypeId',
  /** column name */
  CompleteLayerConfigId = 'CompleteLayerConfigId',
  /** column name */
  CoverSecurementMethodCode = 'CoverSecurementMethodCode',
  /** column name */
  CoverSecurementMethodId = 'CoverSecurementMethodId',
  /** column name */
  CoverSubassemblyId = 'CoverSubassemblyId',
  /** column name */
  DeckSubassemblyId = 'DeckSubassemblyId',
  /** column name */
  DeckTypeCode = 'DeckTypeCode',
  /** column name */
  DeckTypeId = 'DeckTypeId',
  /** column name */
  ExtFireRatingCode = 'ExtFireRatingCode',
  /** column name */
  ExtFireRatingId = 'ExtFireRatingId',
  /** column name */
  ExtendedLayerConfigId = 'ExtendedLayerConfigId',
  /** column name */
  ExtendedLayerConfigId2 = 'ExtendedLayerConfigId2',
  /** column name */
  ExtendedLayerConfigId3 = 'ExtendedLayerConfigId3',
  /** column name */
  HailRatingCode = 'HailRatingCode',
  /** column name */
  HailRatingId = 'HailRatingId',
  /** column name */
  IncludesPv = 'IncludesPV',
  /** column name */
  IntFireRatingCode = 'IntFireRatingCode',
  /** column name */
  IntFireRatingId = 'IntFireRatingId',
  /** column name */
  LayerConfigId = 'LayerConfigId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavAssemblyName = 'NavAssemblyName',
  /** column name */
  NavAssemblyValue = 'NavAssemblyValue',
  /** column name */
  PvApplicationMethodId = 'PVApplicationMethodId',
  /** column name */
  PvHailRatingId = 'PVHailRatingId',
  /** column name */
  PvWindUplift = 'PVWindUplift',
  /** column name */
  Slope = 'Slope',
  /** column name */
  SlopeValue = 'SlopeValue',
  /** column name */
  SplitLayerConfigId = 'SplitLayerConfigId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  WindUplift = 'WindUplift',
  /** column name */
  WindUpliftValue = 'WindUpliftValue'
}

/** input type for updating data in table "NavAssembly" */
export type NavAssembly_Set_Input = {
  AssemblyApplicationTypeCode?: Maybe<Scalars['String']>;
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyTypeCode?: Maybe<Scalars['String']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  CompleteLayerConfigId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodCode?: Maybe<Scalars['String']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  CoverSubassemblyId?: Maybe<Scalars['Int']>;
  DeckSubassemblyId?: Maybe<Scalars['Int']>;
  DeckTypeCode?: Maybe<Scalars['String']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingCode?: Maybe<Scalars['String']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId2?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId3?: Maybe<Scalars['Int']>;
  HailRatingCode?: Maybe<Scalars['String']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IncludesPV?: Maybe<Scalars['Boolean']>;
  IntFireRatingCode?: Maybe<Scalars['String']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  NavAssemblyName?: Maybe<Scalars['String']>;
  NavAssemblyValue?: Maybe<Scalars['Int']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  PVWindUplift?: Maybe<Scalars['MeasurementValue']>;
  Slope?: Maybe<Scalars['MeasurementValue']>;
  SlopeValue?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
  WindUpliftValue?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type NavAssembly_Stddev_Fields = {
  __typename?: 'NavAssembly_stddev_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  CompleteLayerConfigId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  CoverSubassemblyId?: Maybe<Scalars['Float']>;
  DeckSubassemblyId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId2?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId3?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  LayerConfigId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavAssemblyValue?: Maybe<Scalars['Float']>;
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
  PVHailRatingId?: Maybe<Scalars['Float']>;
  SlopeValue?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  WindUpliftValue?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "NavAssembly" */
export type NavAssembly_Stddev_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CompleteLayerConfigId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CoverSubassemblyId?: Maybe<Order_By>;
  DeckSubassemblyId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  ExtendedLayerConfigId?: Maybe<Order_By>;
  ExtendedLayerConfigId2?: Maybe<Order_By>;
  ExtendedLayerConfigId3?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyValue?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  SlopeValue?: Maybe<Order_By>;
  SplitLayerConfigId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WindUpliftValue?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type NavAssembly_Stddev_Pop_Fields = {
  __typename?: 'NavAssembly_stddev_pop_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  CompleteLayerConfigId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  CoverSubassemblyId?: Maybe<Scalars['Float']>;
  DeckSubassemblyId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId2?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId3?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  LayerConfigId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavAssemblyValue?: Maybe<Scalars['Float']>;
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
  PVHailRatingId?: Maybe<Scalars['Float']>;
  SlopeValue?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  WindUpliftValue?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "NavAssembly" */
export type NavAssembly_Stddev_Pop_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CompleteLayerConfigId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CoverSubassemblyId?: Maybe<Order_By>;
  DeckSubassemblyId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  ExtendedLayerConfigId?: Maybe<Order_By>;
  ExtendedLayerConfigId2?: Maybe<Order_By>;
  ExtendedLayerConfigId3?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyValue?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  SlopeValue?: Maybe<Order_By>;
  SplitLayerConfigId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WindUpliftValue?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type NavAssembly_Stddev_Samp_Fields = {
  __typename?: 'NavAssembly_stddev_samp_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  CompleteLayerConfigId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  CoverSubassemblyId?: Maybe<Scalars['Float']>;
  DeckSubassemblyId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId2?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId3?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  LayerConfigId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavAssemblyValue?: Maybe<Scalars['Float']>;
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
  PVHailRatingId?: Maybe<Scalars['Float']>;
  SlopeValue?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  WindUpliftValue?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "NavAssembly" */
export type NavAssembly_Stddev_Samp_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CompleteLayerConfigId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CoverSubassemblyId?: Maybe<Order_By>;
  DeckSubassemblyId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  ExtendedLayerConfigId?: Maybe<Order_By>;
  ExtendedLayerConfigId2?: Maybe<Order_By>;
  ExtendedLayerConfigId3?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyValue?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  SlopeValue?: Maybe<Order_By>;
  SplitLayerConfigId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WindUpliftValue?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type NavAssembly_Sum_Fields = {
  __typename?: 'NavAssembly_sum_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  CompleteLayerConfigId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  CoverSubassemblyId?: Maybe<Scalars['Int']>;
  DeckSubassemblyId?: Maybe<Scalars['Int']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId2?: Maybe<Scalars['Int']>;
  ExtendedLayerConfigId3?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyValue?: Maybe<Scalars['Int']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  SlopeValue?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  WindUpliftValue?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "NavAssembly" */
export type NavAssembly_Sum_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CompleteLayerConfigId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CoverSubassemblyId?: Maybe<Order_By>;
  DeckSubassemblyId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  ExtendedLayerConfigId?: Maybe<Order_By>;
  ExtendedLayerConfigId2?: Maybe<Order_By>;
  ExtendedLayerConfigId3?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyValue?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  SlopeValue?: Maybe<Order_By>;
  SplitLayerConfigId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WindUpliftValue?: Maybe<Order_By>;
};

/** update columns of table "NavAssembly" */
export enum NavAssembly_Update_Column {
  /** column name */
  AssemblyApplicationTypeCode = 'AssemblyApplicationTypeCode',
  /** column name */
  AssemblyApplicationTypeId = 'AssemblyApplicationTypeId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  AssemblyTypeCode = 'AssemblyTypeCode',
  /** column name */
  AssemblyTypeId = 'AssemblyTypeId',
  /** column name */
  CompleteLayerConfigId = 'CompleteLayerConfigId',
  /** column name */
  CoverSecurementMethodCode = 'CoverSecurementMethodCode',
  /** column name */
  CoverSecurementMethodId = 'CoverSecurementMethodId',
  /** column name */
  CoverSubassemblyId = 'CoverSubassemblyId',
  /** column name */
  DeckSubassemblyId = 'DeckSubassemblyId',
  /** column name */
  DeckTypeCode = 'DeckTypeCode',
  /** column name */
  DeckTypeId = 'DeckTypeId',
  /** column name */
  ExtFireRatingCode = 'ExtFireRatingCode',
  /** column name */
  ExtFireRatingId = 'ExtFireRatingId',
  /** column name */
  ExtendedLayerConfigId = 'ExtendedLayerConfigId',
  /** column name */
  ExtendedLayerConfigId2 = 'ExtendedLayerConfigId2',
  /** column name */
  ExtendedLayerConfigId3 = 'ExtendedLayerConfigId3',
  /** column name */
  HailRatingCode = 'HailRatingCode',
  /** column name */
  HailRatingId = 'HailRatingId',
  /** column name */
  IncludesPv = 'IncludesPV',
  /** column name */
  IntFireRatingCode = 'IntFireRatingCode',
  /** column name */
  IntFireRatingId = 'IntFireRatingId',
  /** column name */
  LayerConfigId = 'LayerConfigId',
  /** column name */
  NavAssemblyName = 'NavAssemblyName',
  /** column name */
  NavAssemblyValue = 'NavAssemblyValue',
  /** column name */
  PvApplicationMethodId = 'PVApplicationMethodId',
  /** column name */
  PvHailRatingId = 'PVHailRatingId',
  /** column name */
  PvWindUplift = 'PVWindUplift',
  /** column name */
  Slope = 'Slope',
  /** column name */
  SlopeValue = 'SlopeValue',
  /** column name */
  SplitLayerConfigId = 'SplitLayerConfigId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  WindUplift = 'WindUplift',
  /** column name */
  WindUpliftValue = 'WindUpliftValue'
}

/** aggregate var_pop on columns */
export type NavAssembly_Var_Pop_Fields = {
  __typename?: 'NavAssembly_var_pop_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  CompleteLayerConfigId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  CoverSubassemblyId?: Maybe<Scalars['Float']>;
  DeckSubassemblyId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId2?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId3?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  LayerConfigId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavAssemblyValue?: Maybe<Scalars['Float']>;
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
  PVHailRatingId?: Maybe<Scalars['Float']>;
  SlopeValue?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  WindUpliftValue?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "NavAssembly" */
export type NavAssembly_Var_Pop_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CompleteLayerConfigId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CoverSubassemblyId?: Maybe<Order_By>;
  DeckSubassemblyId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  ExtendedLayerConfigId?: Maybe<Order_By>;
  ExtendedLayerConfigId2?: Maybe<Order_By>;
  ExtendedLayerConfigId3?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyValue?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  SlopeValue?: Maybe<Order_By>;
  SplitLayerConfigId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WindUpliftValue?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type NavAssembly_Var_Samp_Fields = {
  __typename?: 'NavAssembly_var_samp_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  CompleteLayerConfigId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  CoverSubassemblyId?: Maybe<Scalars['Float']>;
  DeckSubassemblyId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId2?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId3?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  LayerConfigId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavAssemblyValue?: Maybe<Scalars['Float']>;
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
  PVHailRatingId?: Maybe<Scalars['Float']>;
  SlopeValue?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  WindUpliftValue?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "NavAssembly" */
export type NavAssembly_Var_Samp_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CompleteLayerConfigId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CoverSubassemblyId?: Maybe<Order_By>;
  DeckSubassemblyId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  ExtendedLayerConfigId?: Maybe<Order_By>;
  ExtendedLayerConfigId2?: Maybe<Order_By>;
  ExtendedLayerConfigId3?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyValue?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  SlopeValue?: Maybe<Order_By>;
  SplitLayerConfigId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WindUpliftValue?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type NavAssembly_Variance_Fields = {
  __typename?: 'NavAssembly_variance_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  CompleteLayerConfigId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  CoverSubassemblyId?: Maybe<Scalars['Float']>;
  DeckSubassemblyId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId2?: Maybe<Scalars['Float']>;
  ExtendedLayerConfigId3?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  LayerConfigId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavAssemblyValue?: Maybe<Scalars['Float']>;
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
  PVHailRatingId?: Maybe<Scalars['Float']>;
  SlopeValue?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  WindUpliftValue?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "NavAssembly" */
export type NavAssembly_Variance_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CompleteLayerConfigId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CoverSubassemblyId?: Maybe<Order_By>;
  DeckSubassemblyId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  ExtendedLayerConfigId?: Maybe<Order_By>;
  ExtendedLayerConfigId2?: Maybe<Order_By>;
  ExtendedLayerConfigId3?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyValue?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  SlopeValue?: Maybe<Order_By>;
  SplitLayerConfigId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WindUpliftValue?: Maybe<Order_By>;
};

/** columns and relationships of "NavLayer" */
export type NavLayer = {
  __typename?: 'NavLayer';
  AssemblyId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  C_LayerType: C_LayerType;
  /** An object relationship */
  C_Status: C_Status;
  /** An object relationship */
  Layer?: Maybe<Layer>;
  LayerId: Scalars['Int'];
  LayerTypeId: Scalars['Int'];
  /** An object relationship */
  NavAssembly: NavAssembly;
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  /** An array relationship */
  NavUsages: Array<NavUsage>;
  /** An aggregate relationship */
  NavUsages_aggregate: NavUsage_Aggregate;
  OrderKey?: Maybe<Scalars['Int']>;
  RALLayerIdForSA?: Maybe<Scalars['Int']>;
  /** An object relationship */
  RoofAssembly?: Maybe<RoofAssembly>;
  StatusId: Scalars['Int'];
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "NavLayer" */
export type NavLayerNavUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavUsage_Order_By>>;
  where?: Maybe<NavUsage_Bool_Exp>;
};


/** columns and relationships of "NavLayer" */
export type NavLayerNavUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavUsage_Order_By>>;
  where?: Maybe<NavUsage_Bool_Exp>;
};

/** aggregated selection of "NavLayer" */
export type NavLayer_Aggregate = {
  __typename?: 'NavLayer_aggregate';
  aggregate?: Maybe<NavLayer_Aggregate_Fields>;
  nodes: Array<NavLayer>;
};

/** aggregate fields of "NavLayer" */
export type NavLayer_Aggregate_Fields = {
  __typename?: 'NavLayer_aggregate_fields';
  avg?: Maybe<NavLayer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<NavLayer_Max_Fields>;
  min?: Maybe<NavLayer_Min_Fields>;
  stddev?: Maybe<NavLayer_Stddev_Fields>;
  stddev_pop?: Maybe<NavLayer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<NavLayer_Stddev_Samp_Fields>;
  sum?: Maybe<NavLayer_Sum_Fields>;
  var_pop?: Maybe<NavLayer_Var_Pop_Fields>;
  var_samp?: Maybe<NavLayer_Var_Samp_Fields>;
  variance?: Maybe<NavLayer_Variance_Fields>;
};


/** aggregate fields of "NavLayer" */
export type NavLayer_Aggregate_FieldsCountArgs = {
  column?: Maybe<NavLayer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "NavLayer" */
export type NavLayer_Aggregate_Order_By = {
  avg?: Maybe<NavLayer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<NavLayer_Max_Order_By>;
  min?: Maybe<NavLayer_Min_Order_By>;
  stddev?: Maybe<NavLayer_Stddev_Order_By>;
  stddev_pop?: Maybe<NavLayer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<NavLayer_Stddev_Samp_Order_By>;
  sum?: Maybe<NavLayer_Sum_Order_By>;
  var_pop?: Maybe<NavLayer_Var_Pop_Order_By>;
  var_samp?: Maybe<NavLayer_Var_Samp_Order_By>;
  variance?: Maybe<NavLayer_Variance_Order_By>;
};

/** aggregate avg on columns */
export type NavLayer_Avg_Fields = {
  __typename?: 'NavLayer_avg_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  OrderKey?: Maybe<Scalars['Float']>;
  RALLayerIdForSA?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "NavLayer" */
export type NavLayer_Avg_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  OrderKey?: Maybe<Order_By>;
  RALLayerIdForSA?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "NavLayer". All fields are combined with a logical 'AND'. */
export type NavLayer_Bool_Exp = {
  AssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_LayerType?: Maybe<C_LayerType_Bool_Exp>;
  C_Status?: Maybe<C_Status_Bool_Exp>;
  Layer?: Maybe<Layer_Bool_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  LayerTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssembly?: Maybe<NavAssembly_Bool_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  NavUsages?: Maybe<NavUsage_Bool_Exp>;
  OrderKey?: Maybe<Int_Mssql_Comparison_Exp>;
  RALLayerIdForSA?: Maybe<Int_Mssql_Comparison_Exp>;
  RoofAssembly?: Maybe<RoofAssembly_Bool_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubassemblyComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<NavLayer_Bool_Exp>>;
  _not?: Maybe<NavLayer_Bool_Exp>;
  _or?: Maybe<Array<NavLayer_Bool_Exp>>;
};

/** upsert condition type for table "NavLayer" */
export type NavLayer_If_Matched = {
  match_columns?: Array<NavLayer_Insert_Match_Column>;
  update_columns?: Array<NavLayer_Update_Column>;
  where?: Maybe<NavLayer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "NavLayer" */
export type NavLayer_Inc_Input = {
  AssemblyId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  OrderKey?: Maybe<Scalars['Int']>;
  RALLayerIdForSA?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "NavLayer" */
export type NavLayer_Insert_Input = {
  AssemblyId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  OrderKey?: Maybe<Scalars['Int']>;
  RALLayerIdForSA?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "NavLayer" */
export enum NavLayer_Insert_Match_Column {
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerTypeId = 'LayerTypeId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  OrderKey = 'OrderKey',
  /** column name */
  RalLayerIdForSa = 'RALLayerIdForSA',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** aggregate max on columns */
export type NavLayer_Max_Fields = {
  __typename?: 'NavLayer_max_fields';
  AssemblyId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  OrderKey?: Maybe<Scalars['Int']>;
  RALLayerIdForSA?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "NavLayer" */
export type NavLayer_Max_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  OrderKey?: Maybe<Order_By>;
  RALLayerIdForSA?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type NavLayer_Min_Fields = {
  __typename?: 'NavLayer_min_fields';
  AssemblyId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  OrderKey?: Maybe<Scalars['Int']>;
  RALLayerIdForSA?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "NavLayer" */
export type NavLayer_Min_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  OrderKey?: Maybe<Order_By>;
  RALLayerIdForSA?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "NavLayer" */
export type NavLayer_Mutation_Response = {
  __typename?: 'NavLayer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NavLayer>;
};

/** Ordering options when selecting data from "NavLayer". */
export type NavLayer_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  C_LayerType?: Maybe<C_LayerType_Order_By>;
  C_Status?: Maybe<C_Status_Order_By>;
  Layer?: Maybe<Layer_Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  NavAssembly?: Maybe<NavAssembly_Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NavUsages_aggregate?: Maybe<NavUsage_Aggregate_Order_By>;
  OrderKey?: Maybe<Order_By>;
  RALLayerIdForSA?: Maybe<Order_By>;
  RoofAssembly?: Maybe<RoofAssembly_Order_By>;
  StatusId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** primary key columns input for table: NavLayer */
export type NavLayer_Pk_Columns_Input = {
  NavLayerId: Scalars['Int'];
};

/** select columns of table "NavLayer" */
export enum NavLayer_Select_Column {
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerTypeId = 'LayerTypeId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  OrderKey = 'OrderKey',
  /** column name */
  RalLayerIdForSa = 'RALLayerIdForSA',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** input type for updating data in table "NavLayer" */
export type NavLayer_Set_Input = {
  AssemblyId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  OrderKey?: Maybe<Scalars['Int']>;
  RALLayerIdForSA?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type NavLayer_Stddev_Fields = {
  __typename?: 'NavLayer_stddev_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  OrderKey?: Maybe<Scalars['Float']>;
  RALLayerIdForSA?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "NavLayer" */
export type NavLayer_Stddev_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  OrderKey?: Maybe<Order_By>;
  RALLayerIdForSA?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type NavLayer_Stddev_Pop_Fields = {
  __typename?: 'NavLayer_stddev_pop_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  OrderKey?: Maybe<Scalars['Float']>;
  RALLayerIdForSA?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "NavLayer" */
export type NavLayer_Stddev_Pop_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  OrderKey?: Maybe<Order_By>;
  RALLayerIdForSA?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type NavLayer_Stddev_Samp_Fields = {
  __typename?: 'NavLayer_stddev_samp_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  OrderKey?: Maybe<Scalars['Float']>;
  RALLayerIdForSA?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "NavLayer" */
export type NavLayer_Stddev_Samp_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  OrderKey?: Maybe<Order_By>;
  RALLayerIdForSA?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type NavLayer_Sum_Fields = {
  __typename?: 'NavLayer_sum_fields';
  AssemblyId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  OrderKey?: Maybe<Scalars['Int']>;
  RALLayerIdForSA?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "NavLayer" */
export type NavLayer_Sum_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  OrderKey?: Maybe<Order_By>;
  RALLayerIdForSA?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** update columns of table "NavLayer" */
export enum NavLayer_Update_Column {
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerTypeId = 'LayerTypeId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  OrderKey = 'OrderKey',
  /** column name */
  RalLayerIdForSa = 'RALLayerIdForSA',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** aggregate var_pop on columns */
export type NavLayer_Var_Pop_Fields = {
  __typename?: 'NavLayer_var_pop_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  OrderKey?: Maybe<Scalars['Float']>;
  RALLayerIdForSA?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "NavLayer" */
export type NavLayer_Var_Pop_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  OrderKey?: Maybe<Order_By>;
  RALLayerIdForSA?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type NavLayer_Var_Samp_Fields = {
  __typename?: 'NavLayer_var_samp_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  OrderKey?: Maybe<Scalars['Float']>;
  RALLayerIdForSA?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "NavLayer" */
export type NavLayer_Var_Samp_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  OrderKey?: Maybe<Order_By>;
  RALLayerIdForSA?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type NavLayer_Variance_Fields = {
  __typename?: 'NavLayer_variance_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  OrderKey?: Maybe<Scalars['Float']>;
  RALLayerIdForSA?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "NavLayer" */
export type NavLayer_Variance_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  OrderKey?: Maybe<Order_By>;
  RALLayerIdForSA?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig = {
  __typename?: 'NavMultiplyCoverConfig';
  /** An object relationship */
  C_SubassemblyType?: Maybe<C_SubassemblyType>;
  CoverConfiguration: Scalars['String'];
  NavMultiplyCoverConfigId: Scalars['Int'];
  NumPlies: Scalars['Int'];
  /** An array relationship */
  R_NavAssembly_NavMultiplyCoverConfigs: Array<R_NavAssembly_NavMultiplyCoverConfig>;
  /** An aggregate relationship */
  R_NavAssembly_NavMultiplyCoverConfigs_aggregate: R_NavAssembly_NavMultiplyCoverConfig_Aggregate;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfigR_NavAssembly_NavMultiplyCoverConfigsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_NavAssembly_NavMultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp>;
};


/** columns and relationships of "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfigR_NavAssembly_NavMultiplyCoverConfigs_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_NavAssembly_NavMultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp>;
};

/** aggregated selection of "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Aggregate = {
  __typename?: 'NavMultiplyCoverConfig_aggregate';
  aggregate?: Maybe<NavMultiplyCoverConfig_Aggregate_Fields>;
  nodes: Array<NavMultiplyCoverConfig>;
};

/** aggregate fields of "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Aggregate_Fields = {
  __typename?: 'NavMultiplyCoverConfig_aggregate_fields';
  avg?: Maybe<NavMultiplyCoverConfig_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<NavMultiplyCoverConfig_Max_Fields>;
  min?: Maybe<NavMultiplyCoverConfig_Min_Fields>;
  stddev?: Maybe<NavMultiplyCoverConfig_Stddev_Fields>;
  stddev_pop?: Maybe<NavMultiplyCoverConfig_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<NavMultiplyCoverConfig_Stddev_Samp_Fields>;
  sum?: Maybe<NavMultiplyCoverConfig_Sum_Fields>;
  var_pop?: Maybe<NavMultiplyCoverConfig_Var_Pop_Fields>;
  var_samp?: Maybe<NavMultiplyCoverConfig_Var_Samp_Fields>;
  variance?: Maybe<NavMultiplyCoverConfig_Variance_Fields>;
};


/** aggregate fields of "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Aggregate_FieldsCountArgs = {
  column?: Maybe<NavMultiplyCoverConfig_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Aggregate_Order_By = {
  avg?: Maybe<NavMultiplyCoverConfig_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<NavMultiplyCoverConfig_Max_Order_By>;
  min?: Maybe<NavMultiplyCoverConfig_Min_Order_By>;
  stddev?: Maybe<NavMultiplyCoverConfig_Stddev_Order_By>;
  stddev_pop?: Maybe<NavMultiplyCoverConfig_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<NavMultiplyCoverConfig_Stddev_Samp_Order_By>;
  sum?: Maybe<NavMultiplyCoverConfig_Sum_Order_By>;
  var_pop?: Maybe<NavMultiplyCoverConfig_Var_Pop_Order_By>;
  var_samp?: Maybe<NavMultiplyCoverConfig_Var_Samp_Order_By>;
  variance?: Maybe<NavMultiplyCoverConfig_Variance_Order_By>;
};

/** aggregate avg on columns */
export type NavMultiplyCoverConfig_Avg_Fields = {
  __typename?: 'NavMultiplyCoverConfig_avg_fields';
  NavMultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Avg_Order_By = {
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "NavMultiplyCoverConfig". All fields are combined with a logical 'AND'. */
export type NavMultiplyCoverConfig_Bool_Exp = {
  C_SubassemblyType?: Maybe<C_SubassemblyType_Bool_Exp>;
  CoverConfiguration?: Maybe<String_Mssql_Comparison_Exp>;
  NavMultiplyCoverConfigId?: Maybe<Int_Mssql_Comparison_Exp>;
  NumPlies?: Maybe<Int_Mssql_Comparison_Exp>;
  R_NavAssembly_NavMultiplyCoverConfigs?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp>;
  SubassemblyTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<NavMultiplyCoverConfig_Bool_Exp>>;
  _not?: Maybe<NavMultiplyCoverConfig_Bool_Exp>;
  _or?: Maybe<Array<NavMultiplyCoverConfig_Bool_Exp>>;
};

/** upsert condition type for table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_If_Matched = {
  match_columns?: Array<NavMultiplyCoverConfig_Insert_Match_Column>;
  update_columns?: Array<NavMultiplyCoverConfig_Update_Column>;
  where?: Maybe<NavMultiplyCoverConfig_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Inc_Input = {
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Insert_Input = {
  CoverConfiguration?: Maybe<Scalars['String']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "NavMultiplyCoverConfig" */
export enum NavMultiplyCoverConfig_Insert_Match_Column {
  /** column name */
  CoverConfiguration = 'CoverConfiguration',
  /** column name */
  NavMultiplyCoverConfigId = 'NavMultiplyCoverConfigId',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  SubassemblyTypeId = 'SubassemblyTypeId'
}

/** aggregate max on columns */
export type NavMultiplyCoverConfig_Max_Fields = {
  __typename?: 'NavMultiplyCoverConfig_max_fields';
  CoverConfiguration?: Maybe<Scalars['String']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Max_Order_By = {
  CoverConfiguration?: Maybe<Order_By>;
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type NavMultiplyCoverConfig_Min_Fields = {
  __typename?: 'NavMultiplyCoverConfig_min_fields';
  CoverConfiguration?: Maybe<Scalars['String']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Min_Order_By = {
  CoverConfiguration?: Maybe<Order_By>;
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** response of any mutation on the table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Mutation_Response = {
  __typename?: 'NavMultiplyCoverConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NavMultiplyCoverConfig>;
};

/** Ordering options when selecting data from "NavMultiplyCoverConfig". */
export type NavMultiplyCoverConfig_Order_By = {
  C_SubassemblyType?: Maybe<C_SubassemblyType_Order_By>;
  CoverConfiguration?: Maybe<Order_By>;
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  R_NavAssembly_NavMultiplyCoverConfigs_aggregate?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Aggregate_Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: NavMultiplyCoverConfig */
export type NavMultiplyCoverConfig_Pk_Columns_Input = {
  NavMultiplyCoverConfigId: Scalars['Int'];
};

/** select columns of table "NavMultiplyCoverConfig" */
export enum NavMultiplyCoverConfig_Select_Column {
  /** column name */
  CoverConfiguration = 'CoverConfiguration',
  /** column name */
  NavMultiplyCoverConfigId = 'NavMultiplyCoverConfigId',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  SubassemblyTypeId = 'SubassemblyTypeId'
}

/** input type for updating data in table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Set_Input = {
  CoverConfiguration?: Maybe<Scalars['String']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type NavMultiplyCoverConfig_Stddev_Fields = {
  __typename?: 'NavMultiplyCoverConfig_stddev_fields';
  NavMultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Stddev_Order_By = {
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type NavMultiplyCoverConfig_Stddev_Pop_Fields = {
  __typename?: 'NavMultiplyCoverConfig_stddev_pop_fields';
  NavMultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Stddev_Pop_Order_By = {
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type NavMultiplyCoverConfig_Stddev_Samp_Fields = {
  __typename?: 'NavMultiplyCoverConfig_stddev_samp_fields';
  NavMultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Stddev_Samp_Order_By = {
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type NavMultiplyCoverConfig_Sum_Fields = {
  __typename?: 'NavMultiplyCoverConfig_sum_fields';
  NavMultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Sum_Order_By = {
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** update columns of table "NavMultiplyCoverConfig" */
export enum NavMultiplyCoverConfig_Update_Column {
  /** column name */
  CoverConfiguration = 'CoverConfiguration',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  SubassemblyTypeId = 'SubassemblyTypeId'
}

/** aggregate var_pop on columns */
export type NavMultiplyCoverConfig_Var_Pop_Fields = {
  __typename?: 'NavMultiplyCoverConfig_var_pop_fields';
  NavMultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Var_Pop_Order_By = {
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type NavMultiplyCoverConfig_Var_Samp_Fields = {
  __typename?: 'NavMultiplyCoverConfig_var_samp_fields';
  NavMultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Var_Samp_Order_By = {
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type NavMultiplyCoverConfig_Variance_Fields = {
  __typename?: 'NavMultiplyCoverConfig_variance_fields';
  NavMultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "NavMultiplyCoverConfig" */
export type NavMultiplyCoverConfig_Variance_Order_By = {
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** columns and relationships of "NavSecurementLayer" */
export type NavSecurementLayer = {
  __typename?: 'NavSecurementLayer';
  FromNavLayerId: Scalars['Int'];
  IsSelfSecurement?: Maybe<Scalars['Boolean']>;
  NavAssemblyId: Scalars['Int'];
  NavAssemblyStatusId?: Maybe<Scalars['Int']>;
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  NumOfDuplicates?: Maybe<Scalars['Int']>;
  RelativeSeqNum?: Maybe<Scalars['Int']>;
  SplitLayerConfigId?: Maybe<Scalars['Int']>;
  ToNavLayerId: Scalars['Int'];
};

/** aggregated selection of "NavSecurementLayer" */
export type NavSecurementLayer_Aggregate = {
  __typename?: 'NavSecurementLayer_aggregate';
  aggregate?: Maybe<NavSecurementLayer_Aggregate_Fields>;
  nodes: Array<NavSecurementLayer>;
};

/** aggregate fields of "NavSecurementLayer" */
export type NavSecurementLayer_Aggregate_Fields = {
  __typename?: 'NavSecurementLayer_aggregate_fields';
  avg?: Maybe<NavSecurementLayer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<NavSecurementLayer_Max_Fields>;
  min?: Maybe<NavSecurementLayer_Min_Fields>;
  stddev?: Maybe<NavSecurementLayer_Stddev_Fields>;
  stddev_pop?: Maybe<NavSecurementLayer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<NavSecurementLayer_Stddev_Samp_Fields>;
  sum?: Maybe<NavSecurementLayer_Sum_Fields>;
  var_pop?: Maybe<NavSecurementLayer_Var_Pop_Fields>;
  var_samp?: Maybe<NavSecurementLayer_Var_Samp_Fields>;
  variance?: Maybe<NavSecurementLayer_Variance_Fields>;
};


/** aggregate fields of "NavSecurementLayer" */
export type NavSecurementLayer_Aggregate_FieldsCountArgs = {
  column?: Maybe<NavSecurementLayer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type NavSecurementLayer_Avg_Fields = {
  __typename?: 'NavSecurementLayer_avg_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavAssemblyStatusId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumOfDuplicates?: Maybe<Scalars['Float']>;
  RelativeSeqNum?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "NavSecurementLayer". All fields are combined with a logical 'AND'. */
export type NavSecurementLayer_Bool_Exp = {
  FromNavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  IsSelfSecurement?: Maybe<Boolean_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  NumOfDuplicates?: Maybe<Int_Mssql_Comparison_Exp>;
  RelativeSeqNum?: Maybe<Int_Mssql_Comparison_Exp>;
  SplitLayerConfigId?: Maybe<Int_Mssql_Comparison_Exp>;
  ToNavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<NavSecurementLayer_Bool_Exp>>;
  _not?: Maybe<NavSecurementLayer_Bool_Exp>;
  _or?: Maybe<Array<NavSecurementLayer_Bool_Exp>>;
};

/** upsert condition type for table "NavSecurementLayer" */
export type NavSecurementLayer_If_Matched = {
  match_columns?: Array<NavSecurementLayer_Insert_Match_Column>;
  update_columns?: Array<NavSecurementLayer_Update_Column>;
  where?: Maybe<NavSecurementLayer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "NavSecurementLayer" */
export type NavSecurementLayer_Inc_Input = {
  FromNavLayerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyStatusId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumOfDuplicates?: Maybe<Scalars['Int']>;
  RelativeSeqNum?: Maybe<Scalars['Int']>;
  SplitLayerConfigId?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "NavSecurementLayer" */
export type NavSecurementLayer_Insert_Input = {
  FromNavLayerId?: Maybe<Scalars['Int']>;
  IsSelfSecurement?: Maybe<Scalars['Boolean']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyStatusId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumOfDuplicates?: Maybe<Scalars['Int']>;
  RelativeSeqNum?: Maybe<Scalars['Int']>;
  SplitLayerConfigId?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "NavSecurementLayer" */
export enum NavSecurementLayer_Insert_Match_Column {
  /** column name */
  FromNavLayerId = 'FromNavLayerId',
  /** column name */
  IsSelfSecurement = 'IsSelfSecurement',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavAssemblyStatusId = 'NavAssemblyStatusId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumOfDuplicates = 'NumOfDuplicates',
  /** column name */
  RelativeSeqNum = 'RelativeSeqNum',
  /** column name */
  SplitLayerConfigId = 'SplitLayerConfigId',
  /** column name */
  ToNavLayerId = 'ToNavLayerId'
}

/** aggregate max on columns */
export type NavSecurementLayer_Max_Fields = {
  __typename?: 'NavSecurementLayer_max_fields';
  FromNavLayerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyStatusId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumOfDuplicates?: Maybe<Scalars['Int']>;
  RelativeSeqNum?: Maybe<Scalars['Int']>;
  SplitLayerConfigId?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type NavSecurementLayer_Min_Fields = {
  __typename?: 'NavSecurementLayer_min_fields';
  FromNavLayerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyStatusId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumOfDuplicates?: Maybe<Scalars['Int']>;
  RelativeSeqNum?: Maybe<Scalars['Int']>;
  SplitLayerConfigId?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "NavSecurementLayer" */
export type NavSecurementLayer_Mutation_Response = {
  __typename?: 'NavSecurementLayer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NavSecurementLayer>;
};

/** Ordering options when selecting data from "NavSecurementLayer". */
export type NavSecurementLayer_Order_By = {
  FromNavLayerId?: Maybe<Order_By>;
  IsSelfSecurement?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyStatusId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumOfDuplicates?: Maybe<Order_By>;
  RelativeSeqNum?: Maybe<Order_By>;
  SplitLayerConfigId?: Maybe<Order_By>;
  ToNavLayerId?: Maybe<Order_By>;
};

/** primary key columns input for table: NavSecurementLayer */
export type NavSecurementLayer_Pk_Columns_Input = {
  NavLayerId: Scalars['Int'];
};

/** select columns of table "NavSecurementLayer" */
export enum NavSecurementLayer_Select_Column {
  /** column name */
  FromNavLayerId = 'FromNavLayerId',
  /** column name */
  IsSelfSecurement = 'IsSelfSecurement',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavAssemblyStatusId = 'NavAssemblyStatusId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumOfDuplicates = 'NumOfDuplicates',
  /** column name */
  RelativeSeqNum = 'RelativeSeqNum',
  /** column name */
  SplitLayerConfigId = 'SplitLayerConfigId',
  /** column name */
  ToNavLayerId = 'ToNavLayerId'
}

/** input type for updating data in table "NavSecurementLayer" */
export type NavSecurementLayer_Set_Input = {
  FromNavLayerId?: Maybe<Scalars['Int']>;
  IsSelfSecurement?: Maybe<Scalars['Boolean']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyStatusId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumOfDuplicates?: Maybe<Scalars['Int']>;
  RelativeSeqNum?: Maybe<Scalars['Int']>;
  SplitLayerConfigId?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type NavSecurementLayer_Stddev_Fields = {
  __typename?: 'NavSecurementLayer_stddev_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavAssemblyStatusId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumOfDuplicates?: Maybe<Scalars['Float']>;
  RelativeSeqNum?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type NavSecurementLayer_Stddev_Pop_Fields = {
  __typename?: 'NavSecurementLayer_stddev_pop_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavAssemblyStatusId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumOfDuplicates?: Maybe<Scalars['Float']>;
  RelativeSeqNum?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type NavSecurementLayer_Stddev_Samp_Fields = {
  __typename?: 'NavSecurementLayer_stddev_samp_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavAssemblyStatusId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumOfDuplicates?: Maybe<Scalars['Float']>;
  RelativeSeqNum?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type NavSecurementLayer_Sum_Fields = {
  __typename?: 'NavSecurementLayer_sum_fields';
  FromNavLayerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyStatusId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumOfDuplicates?: Maybe<Scalars['Int']>;
  RelativeSeqNum?: Maybe<Scalars['Int']>;
  SplitLayerConfigId?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
};

/** update columns of table "NavSecurementLayer" */
export enum NavSecurementLayer_Update_Column {
  /** column name */
  FromNavLayerId = 'FromNavLayerId',
  /** column name */
  IsSelfSecurement = 'IsSelfSecurement',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavAssemblyStatusId = 'NavAssemblyStatusId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumOfDuplicates = 'NumOfDuplicates',
  /** column name */
  RelativeSeqNum = 'RelativeSeqNum',
  /** column name */
  SplitLayerConfigId = 'SplitLayerConfigId',
  /** column name */
  ToNavLayerId = 'ToNavLayerId'
}

/** aggregate var_pop on columns */
export type NavSecurementLayer_Var_Pop_Fields = {
  __typename?: 'NavSecurementLayer_var_pop_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavAssemblyStatusId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumOfDuplicates?: Maybe<Scalars['Float']>;
  RelativeSeqNum?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type NavSecurementLayer_Var_Samp_Fields = {
  __typename?: 'NavSecurementLayer_var_samp_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavAssemblyStatusId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumOfDuplicates?: Maybe<Scalars['Float']>;
  RelativeSeqNum?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type NavSecurementLayer_Variance_Fields = {
  __typename?: 'NavSecurementLayer_variance_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavAssemblyStatusId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumOfDuplicates?: Maybe<Scalars['Float']>;
  RelativeSeqNum?: Maybe<Scalars['Float']>;
  SplitLayerConfigId?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "NavSecurementUsage" */
export type NavSecurementUsage = {
  __typename?: 'NavSecurementUsage';
  FromNavLayerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  ToNavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "NavSecurementUsage" */
export type NavSecurementUsage_Aggregate = {
  __typename?: 'NavSecurementUsage_aggregate';
  aggregate?: Maybe<NavSecurementUsage_Aggregate_Fields>;
  nodes: Array<NavSecurementUsage>;
};

/** aggregate fields of "NavSecurementUsage" */
export type NavSecurementUsage_Aggregate_Fields = {
  __typename?: 'NavSecurementUsage_aggregate_fields';
  avg?: Maybe<NavSecurementUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<NavSecurementUsage_Max_Fields>;
  min?: Maybe<NavSecurementUsage_Min_Fields>;
  stddev?: Maybe<NavSecurementUsage_Stddev_Fields>;
  stddev_pop?: Maybe<NavSecurementUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<NavSecurementUsage_Stddev_Samp_Fields>;
  sum?: Maybe<NavSecurementUsage_Sum_Fields>;
  var_pop?: Maybe<NavSecurementUsage_Var_Pop_Fields>;
  var_samp?: Maybe<NavSecurementUsage_Var_Samp_Fields>;
  variance?: Maybe<NavSecurementUsage_Variance_Fields>;
};


/** aggregate fields of "NavSecurementUsage" */
export type NavSecurementUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<NavSecurementUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type NavSecurementUsage_Avg_Fields = {
  __typename?: 'NavSecurementUsage_avg_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "NavSecurementUsage". All fields are combined with a logical 'AND'. */
export type NavSecurementUsage_Bool_Exp = {
  FromNavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ToNavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<NavSecurementUsage_Bool_Exp>>;
  _not?: Maybe<NavSecurementUsage_Bool_Exp>;
  _or?: Maybe<Array<NavSecurementUsage_Bool_Exp>>;
};

/** upsert condition type for table "NavSecurementUsage" */
export type NavSecurementUsage_If_Matched = {
  match_columns?: Array<NavSecurementUsage_Insert_Match_Column>;
  update_columns?: Array<NavSecurementUsage_Update_Column>;
  where?: Maybe<NavSecurementUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "NavSecurementUsage" */
export type NavSecurementUsage_Inc_Input = {
  FromNavLayerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "NavSecurementUsage" */
export type NavSecurementUsage_Insert_Input = {
  FromNavLayerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "NavSecurementUsage" */
export enum NavSecurementUsage_Insert_Match_Column {
  /** column name */
  FromNavLayerId = 'FromNavLayerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  ToNavLayerId = 'ToNavLayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type NavSecurementUsage_Max_Fields = {
  __typename?: 'NavSecurementUsage_max_fields';
  FromNavLayerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type NavSecurementUsage_Min_Fields = {
  __typename?: 'NavSecurementUsage_min_fields';
  FromNavLayerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "NavSecurementUsage" */
export type NavSecurementUsage_Mutation_Response = {
  __typename?: 'NavSecurementUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NavSecurementUsage>;
};

/** Ordering options when selecting data from "NavSecurementUsage". */
export type NavSecurementUsage_Order_By = {
  FromNavLayerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  ToNavLayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: NavSecurementUsage */
export type NavSecurementUsage_Pk_Columns_Input = {
  NavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};

/** select columns of table "NavSecurementUsage" */
export enum NavSecurementUsage_Select_Column {
  /** column name */
  FromNavLayerId = 'FromNavLayerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  ToNavLayerId = 'ToNavLayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "NavSecurementUsage" */
export type NavSecurementUsage_Set_Input = {
  FromNavLayerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type NavSecurementUsage_Stddev_Fields = {
  __typename?: 'NavSecurementUsage_stddev_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type NavSecurementUsage_Stddev_Pop_Fields = {
  __typename?: 'NavSecurementUsage_stddev_pop_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type NavSecurementUsage_Stddev_Samp_Fields = {
  __typename?: 'NavSecurementUsage_stddev_samp_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type NavSecurementUsage_Sum_Fields = {
  __typename?: 'NavSecurementUsage_sum_fields';
  FromNavLayerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "NavSecurementUsage" */
export enum NavSecurementUsage_Update_Column {
  /** column name */
  FromNavLayerId = 'FromNavLayerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  ToNavLayerId = 'ToNavLayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type NavSecurementUsage_Var_Pop_Fields = {
  __typename?: 'NavSecurementUsage_var_pop_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type NavSecurementUsage_Var_Samp_Fields = {
  __typename?: 'NavSecurementUsage_var_samp_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type NavSecurementUsage_Variance_Fields = {
  __typename?: 'NavSecurementUsage_variance_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "NavUsage" */
export type NavUsage = {
  __typename?: 'NavUsage';
  AssemblyId: Scalars['Int'];
  /** An object relationship */
  NavLayer: NavLayer;
  NavLayerId: Scalars['Int'];
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** columns and relationships of "NavUsageSA" */
export type NavUsageSa = {
  __typename?: 'NavUsageSA';
  NavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "NavUsageSA" */
export type NavUsageSa_Aggregate = {
  __typename?: 'NavUsageSA_aggregate';
  aggregate?: Maybe<NavUsageSa_Aggregate_Fields>;
  nodes: Array<NavUsageSa>;
};

/** aggregate fields of "NavUsageSA" */
export type NavUsageSa_Aggregate_Fields = {
  __typename?: 'NavUsageSA_aggregate_fields';
  avg?: Maybe<NavUsageSa_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<NavUsageSa_Max_Fields>;
  min?: Maybe<NavUsageSa_Min_Fields>;
  stddev?: Maybe<NavUsageSa_Stddev_Fields>;
  stddev_pop?: Maybe<NavUsageSa_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<NavUsageSa_Stddev_Samp_Fields>;
  sum?: Maybe<NavUsageSa_Sum_Fields>;
  var_pop?: Maybe<NavUsageSa_Var_Pop_Fields>;
  var_samp?: Maybe<NavUsageSa_Var_Samp_Fields>;
  variance?: Maybe<NavUsageSa_Variance_Fields>;
};


/** aggregate fields of "NavUsageSA" */
export type NavUsageSa_Aggregate_FieldsCountArgs = {
  column?: Maybe<NavUsageSa_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type NavUsageSa_Avg_Fields = {
  __typename?: 'NavUsageSA_avg_fields';
  NavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "NavUsageSA". All fields are combined with a logical 'AND'. */
export type NavUsageSa_Bool_Exp = {
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<NavUsageSa_Bool_Exp>>;
  _not?: Maybe<NavUsageSa_Bool_Exp>;
  _or?: Maybe<Array<NavUsageSa_Bool_Exp>>;
};

/** upsert condition type for table "NavUsageSA" */
export type NavUsageSa_If_Matched = {
  match_columns?: Array<NavUsageSa_Insert_Match_Column>;
  update_columns?: Array<NavUsageSa_Update_Column>;
  where?: Maybe<NavUsageSa_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "NavUsageSA" */
export type NavUsageSa_Inc_Input = {
  NavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "NavUsageSA" */
export type NavUsageSa_Insert_Input = {
  NavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "NavUsageSA" */
export enum NavUsageSa_Insert_Match_Column {
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type NavUsageSa_Max_Fields = {
  __typename?: 'NavUsageSA_max_fields';
  NavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type NavUsageSa_Min_Fields = {
  __typename?: 'NavUsageSA_min_fields';
  NavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "NavUsageSA" */
export type NavUsageSa_Mutation_Response = {
  __typename?: 'NavUsageSA_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NavUsageSa>;
};

/** Ordering options when selecting data from "NavUsageSA". */
export type NavUsageSa_Order_By = {
  NavLayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: NavUsageSA */
export type NavUsageSa_Pk_Columns_Input = {
  NavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};

/** select columns of table "NavUsageSA" */
export enum NavUsageSa_Select_Column {
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "NavUsageSA" */
export type NavUsageSa_Set_Input = {
  NavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type NavUsageSa_Stddev_Fields = {
  __typename?: 'NavUsageSA_stddev_fields';
  NavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type NavUsageSa_Stddev_Pop_Fields = {
  __typename?: 'NavUsageSA_stddev_pop_fields';
  NavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type NavUsageSa_Stddev_Samp_Fields = {
  __typename?: 'NavUsageSA_stddev_samp_fields';
  NavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type NavUsageSa_Sum_Fields = {
  __typename?: 'NavUsageSA_sum_fields';
  NavLayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "NavUsageSA" */
export enum NavUsageSa_Update_Column {
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type NavUsageSa_Var_Pop_Fields = {
  __typename?: 'NavUsageSA_var_pop_fields';
  NavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type NavUsageSa_Var_Samp_Fields = {
  __typename?: 'NavUsageSA_var_samp_fields';
  NavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type NavUsageSa_Variance_Fields = {
  __typename?: 'NavUsageSA_variance_fields';
  NavLayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "NavUsage" */
export type NavUsage_Aggregate = {
  __typename?: 'NavUsage_aggregate';
  aggregate?: Maybe<NavUsage_Aggregate_Fields>;
  nodes: Array<NavUsage>;
};

/** aggregate fields of "NavUsage" */
export type NavUsage_Aggregate_Fields = {
  __typename?: 'NavUsage_aggregate_fields';
  avg?: Maybe<NavUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<NavUsage_Max_Fields>;
  min?: Maybe<NavUsage_Min_Fields>;
  stddev?: Maybe<NavUsage_Stddev_Fields>;
  stddev_pop?: Maybe<NavUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<NavUsage_Stddev_Samp_Fields>;
  sum?: Maybe<NavUsage_Sum_Fields>;
  var_pop?: Maybe<NavUsage_Var_Pop_Fields>;
  var_samp?: Maybe<NavUsage_Var_Samp_Fields>;
  variance?: Maybe<NavUsage_Variance_Fields>;
};


/** aggregate fields of "NavUsage" */
export type NavUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<NavUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "NavUsage" */
export type NavUsage_Aggregate_Order_By = {
  avg?: Maybe<NavUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<NavUsage_Max_Order_By>;
  min?: Maybe<NavUsage_Min_Order_By>;
  stddev?: Maybe<NavUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<NavUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<NavUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<NavUsage_Sum_Order_By>;
  var_pop?: Maybe<NavUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<NavUsage_Var_Samp_Order_By>;
  variance?: Maybe<NavUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type NavUsage_Avg_Fields = {
  __typename?: 'NavUsage_avg_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "NavUsage" */
export type NavUsage_Avg_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "NavUsage". All fields are combined with a logical 'AND'. */
export type NavUsage_Bool_Exp = {
  AssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayer?: Maybe<NavLayer_Bool_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  SelfSecurementLayerTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubassemblyComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<NavUsage_Bool_Exp>>;
  _not?: Maybe<NavUsage_Bool_Exp>;
  _or?: Maybe<Array<NavUsage_Bool_Exp>>;
};

/** upsert condition type for table "NavUsage" */
export type NavUsage_If_Matched = {
  match_columns?: Array<NavUsage_Insert_Match_Column>;
  update_columns?: Array<NavUsage_Update_Column>;
  where?: Maybe<NavUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "NavUsage" */
export type NavUsage_Inc_Input = {
  AssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "NavUsage" */
export type NavUsage_Insert_Input = {
  AssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "NavUsage" */
export enum NavUsage_Insert_Match_Column {
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  SelfSecurementLayerTypeId = 'SelfSecurementLayerTypeId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type NavUsage_Max_Fields = {
  __typename?: 'NavUsage_max_fields';
  AssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "NavUsage" */
export type NavUsage_Max_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type NavUsage_Min_Fields = {
  __typename?: 'NavUsage_min_fields';
  AssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "NavUsage" */
export type NavUsage_Min_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "NavUsage" */
export type NavUsage_Mutation_Response = {
  __typename?: 'NavUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NavUsage>;
};

/** Ordering options when selecting data from "NavUsage". */
export type NavUsage_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  NavLayer?: Maybe<NavLayer_Order_By>;
  NavLayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: NavUsage */
export type NavUsage_Pk_Columns_Input = {
  NavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};

/** select columns of table "NavUsage" */
export enum NavUsage_Select_Column {
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  SelfSecurementLayerTypeId = 'SelfSecurementLayerTypeId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "NavUsage" */
export type NavUsage_Set_Input = {
  AssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type NavUsage_Stddev_Fields = {
  __typename?: 'NavUsage_stddev_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "NavUsage" */
export type NavUsage_Stddev_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type NavUsage_Stddev_Pop_Fields = {
  __typename?: 'NavUsage_stddev_pop_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "NavUsage" */
export type NavUsage_Stddev_Pop_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type NavUsage_Stddev_Samp_Fields = {
  __typename?: 'NavUsage_stddev_samp_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "NavUsage" */
export type NavUsage_Stddev_Samp_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type NavUsage_Sum_Fields = {
  __typename?: 'NavUsage_sum_fields';
  AssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "NavUsage" */
export type NavUsage_Sum_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "NavUsage" */
export enum NavUsage_Update_Column {
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  SelfSecurementLayerTypeId = 'SelfSecurementLayerTypeId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type NavUsage_Var_Pop_Fields = {
  __typename?: 'NavUsage_var_pop_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "NavUsage" */
export type NavUsage_Var_Pop_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type NavUsage_Var_Samp_Fields = {
  __typename?: 'NavUsage_var_samp_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "NavUsage" */
export type NavUsage_Var_Samp_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type NavUsage_Variance_Fields = {
  __typename?: 'NavUsage_variance_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "NavUsage" */
export type NavUsage_Variance_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives = {
  __typename?: 'Nav_SinglePlyBondingAdhesives';
  AdhesiveComponentId: Scalars['Int'];
  AdhesiveUsageId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  SinglePlyComponentId: Scalars['Int'];
  SinglePlyUsageId: Scalars['Int'];
};

/** aggregated selection of "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Aggregate = {
  __typename?: 'Nav_SinglePlyBondingAdhesives_aggregate';
  aggregate?: Maybe<Nav_SinglePlyBondingAdhesives_Aggregate_Fields>;
  nodes: Array<Nav_SinglePlyBondingAdhesives>;
};

/** aggregate fields of "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Aggregate_Fields = {
  __typename?: 'Nav_SinglePlyBondingAdhesives_aggregate_fields';
  avg?: Maybe<Nav_SinglePlyBondingAdhesives_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nav_SinglePlyBondingAdhesives_Max_Fields>;
  min?: Maybe<Nav_SinglePlyBondingAdhesives_Min_Fields>;
  stddev?: Maybe<Nav_SinglePlyBondingAdhesives_Stddev_Fields>;
  stddev_pop?: Maybe<Nav_SinglePlyBondingAdhesives_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nav_SinglePlyBondingAdhesives_Stddev_Samp_Fields>;
  sum?: Maybe<Nav_SinglePlyBondingAdhesives_Sum_Fields>;
  var_pop?: Maybe<Nav_SinglePlyBondingAdhesives_Var_Pop_Fields>;
  var_samp?: Maybe<Nav_SinglePlyBondingAdhesives_Var_Samp_Fields>;
  variance?: Maybe<Nav_SinglePlyBondingAdhesives_Variance_Fields>;
};


/** aggregate fields of "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Aggregate_FieldsCountArgs = {
  column?: Maybe<Nav_SinglePlyBondingAdhesives_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Aggregate_Order_By = {
  avg?: Maybe<Nav_SinglePlyBondingAdhesives_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Nav_SinglePlyBondingAdhesives_Max_Order_By>;
  min?: Maybe<Nav_SinglePlyBondingAdhesives_Min_Order_By>;
  stddev?: Maybe<Nav_SinglePlyBondingAdhesives_Stddev_Order_By>;
  stddev_pop?: Maybe<Nav_SinglePlyBondingAdhesives_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Nav_SinglePlyBondingAdhesives_Stddev_Samp_Order_By>;
  sum?: Maybe<Nav_SinglePlyBondingAdhesives_Sum_Order_By>;
  var_pop?: Maybe<Nav_SinglePlyBondingAdhesives_Var_Pop_Order_By>;
  var_samp?: Maybe<Nav_SinglePlyBondingAdhesives_Var_Samp_Order_By>;
  variance?: Maybe<Nav_SinglePlyBondingAdhesives_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Nav_SinglePlyBondingAdhesives_Avg_Fields = {
  __typename?: 'Nav_SinglePlyBondingAdhesives_avg_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  AdhesiveUsageId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
  SinglePlyUsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Avg_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Nav_SinglePlyBondingAdhesives". All fields are combined with a logical 'AND'. */
export type Nav_SinglePlyBondingAdhesives_Bool_Exp = {
  AdhesiveComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  AdhesiveUsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  SinglePlyComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  SinglePlyUsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Nav_SinglePlyBondingAdhesives_Bool_Exp>>;
  _not?: Maybe<Nav_SinglePlyBondingAdhesives_Bool_Exp>;
  _or?: Maybe<Array<Nav_SinglePlyBondingAdhesives_Bool_Exp>>;
};

/** upsert condition type for table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_If_Matched = {
  match_columns?: Array<Nav_SinglePlyBondingAdhesives_Insert_Match_Column>;
  update_columns?: Array<Nav_SinglePlyBondingAdhesives_Update_Column>;
  where?: Maybe<Nav_SinglePlyBondingAdhesives_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Inc_Input = {
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  AdhesiveUsageId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
  SinglePlyUsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Insert_Input = {
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  AdhesiveUsageId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
  SinglePlyUsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "Nav_SinglePlyBondingAdhesives" */
export enum Nav_SinglePlyBondingAdhesives_Insert_Match_Column {
  /** column name */
  AdhesiveComponentId = 'AdhesiveComponentId',
  /** column name */
  AdhesiveUsageId = 'AdhesiveUsageId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  SinglePlyComponentId = 'SinglePlyComponentId',
  /** column name */
  SinglePlyUsageId = 'SinglePlyUsageId'
}

/** aggregate max on columns */
export type Nav_SinglePlyBondingAdhesives_Max_Fields = {
  __typename?: 'Nav_SinglePlyBondingAdhesives_max_fields';
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  AdhesiveUsageId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
  SinglePlyUsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Max_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Nav_SinglePlyBondingAdhesives_Min_Fields = {
  __typename?: 'Nav_SinglePlyBondingAdhesives_min_fields';
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  AdhesiveUsageId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
  SinglePlyUsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Min_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Mutation_Response = {
  __typename?: 'Nav_SinglePlyBondingAdhesives_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nav_SinglePlyBondingAdhesives>;
};

/** Ordering options when selecting data from "Nav_SinglePlyBondingAdhesives". */
export type Nav_SinglePlyBondingAdhesives_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** select columns of table "Nav_SinglePlyBondingAdhesives" */
export enum Nav_SinglePlyBondingAdhesives_Select_Column {
  /** column name */
  AdhesiveComponentId = 'AdhesiveComponentId',
  /** column name */
  AdhesiveUsageId = 'AdhesiveUsageId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  SinglePlyComponentId = 'SinglePlyComponentId',
  /** column name */
  SinglePlyUsageId = 'SinglePlyUsageId'
}

/** input type for updating data in table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Set_Input = {
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  AdhesiveUsageId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
  SinglePlyUsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Nav_SinglePlyBondingAdhesives_Stddev_Fields = {
  __typename?: 'Nav_SinglePlyBondingAdhesives_stddev_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  AdhesiveUsageId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
  SinglePlyUsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Stddev_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nav_SinglePlyBondingAdhesives_Stddev_Pop_Fields = {
  __typename?: 'Nav_SinglePlyBondingAdhesives_stddev_pop_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  AdhesiveUsageId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
  SinglePlyUsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Stddev_Pop_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nav_SinglePlyBondingAdhesives_Stddev_Samp_Fields = {
  __typename?: 'Nav_SinglePlyBondingAdhesives_stddev_samp_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  AdhesiveUsageId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
  SinglePlyUsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Stddev_Samp_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Nav_SinglePlyBondingAdhesives_Sum_Fields = {
  __typename?: 'Nav_SinglePlyBondingAdhesives_sum_fields';
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  AdhesiveUsageId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
  SinglePlyUsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Sum_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** update columns of table "Nav_SinglePlyBondingAdhesives" */
export enum Nav_SinglePlyBondingAdhesives_Update_Column {
  /** column name */
  AdhesiveComponentId = 'AdhesiveComponentId',
  /** column name */
  AdhesiveUsageId = 'AdhesiveUsageId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  SinglePlyComponentId = 'SinglePlyComponentId',
  /** column name */
  SinglePlyUsageId = 'SinglePlyUsageId'
}

/** aggregate var_pop on columns */
export type Nav_SinglePlyBondingAdhesives_Var_Pop_Fields = {
  __typename?: 'Nav_SinglePlyBondingAdhesives_var_pop_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  AdhesiveUsageId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
  SinglePlyUsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Var_Pop_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nav_SinglePlyBondingAdhesives_Var_Samp_Fields = {
  __typename?: 'Nav_SinglePlyBondingAdhesives_var_samp_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  AdhesiveUsageId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
  SinglePlyUsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Var_Samp_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Nav_SinglePlyBondingAdhesives_Variance_Fields = {
  __typename?: 'Nav_SinglePlyBondingAdhesives_variance_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  AdhesiveUsageId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
  SinglePlyUsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Nav_SinglePlyBondingAdhesives" */
export type Nav_SinglePlyBondingAdhesives_Variance_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** columns and relationships of "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives = {
  __typename?: 'Nav_SinglePlyLapAdhesives';
  AdhesiveComponentId: Scalars['Int'];
  AdhesiveUsageId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  SinglePlyComponentId: Scalars['Int'];
  SinglePlyUsageId: Scalars['Int'];
};

/** aggregated selection of "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Aggregate = {
  __typename?: 'Nav_SinglePlyLapAdhesives_aggregate';
  aggregate?: Maybe<Nav_SinglePlyLapAdhesives_Aggregate_Fields>;
  nodes: Array<Nav_SinglePlyLapAdhesives>;
};

/** aggregate fields of "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Aggregate_Fields = {
  __typename?: 'Nav_SinglePlyLapAdhesives_aggregate_fields';
  avg?: Maybe<Nav_SinglePlyLapAdhesives_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nav_SinglePlyLapAdhesives_Max_Fields>;
  min?: Maybe<Nav_SinglePlyLapAdhesives_Min_Fields>;
  stddev?: Maybe<Nav_SinglePlyLapAdhesives_Stddev_Fields>;
  stddev_pop?: Maybe<Nav_SinglePlyLapAdhesives_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nav_SinglePlyLapAdhesives_Stddev_Samp_Fields>;
  sum?: Maybe<Nav_SinglePlyLapAdhesives_Sum_Fields>;
  var_pop?: Maybe<Nav_SinglePlyLapAdhesives_Var_Pop_Fields>;
  var_samp?: Maybe<Nav_SinglePlyLapAdhesives_Var_Samp_Fields>;
  variance?: Maybe<Nav_SinglePlyLapAdhesives_Variance_Fields>;
};


/** aggregate fields of "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Aggregate_FieldsCountArgs = {
  column?: Maybe<Nav_SinglePlyLapAdhesives_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Aggregate_Order_By = {
  avg?: Maybe<Nav_SinglePlyLapAdhesives_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Nav_SinglePlyLapAdhesives_Max_Order_By>;
  min?: Maybe<Nav_SinglePlyLapAdhesives_Min_Order_By>;
  stddev?: Maybe<Nav_SinglePlyLapAdhesives_Stddev_Order_By>;
  stddev_pop?: Maybe<Nav_SinglePlyLapAdhesives_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Nav_SinglePlyLapAdhesives_Stddev_Samp_Order_By>;
  sum?: Maybe<Nav_SinglePlyLapAdhesives_Sum_Order_By>;
  var_pop?: Maybe<Nav_SinglePlyLapAdhesives_Var_Pop_Order_By>;
  var_samp?: Maybe<Nav_SinglePlyLapAdhesives_Var_Samp_Order_By>;
  variance?: Maybe<Nav_SinglePlyLapAdhesives_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Nav_SinglePlyLapAdhesives_Avg_Fields = {
  __typename?: 'Nav_SinglePlyLapAdhesives_avg_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  AdhesiveUsageId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
  SinglePlyUsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Avg_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Nav_SinglePlyLapAdhesives". All fields are combined with a logical 'AND'. */
export type Nav_SinglePlyLapAdhesives_Bool_Exp = {
  AdhesiveComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  AdhesiveUsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  SinglePlyComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  SinglePlyUsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Nav_SinglePlyLapAdhesives_Bool_Exp>>;
  _not?: Maybe<Nav_SinglePlyLapAdhesives_Bool_Exp>;
  _or?: Maybe<Array<Nav_SinglePlyLapAdhesives_Bool_Exp>>;
};

/** upsert condition type for table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_If_Matched = {
  match_columns?: Array<Nav_SinglePlyLapAdhesives_Insert_Match_Column>;
  update_columns?: Array<Nav_SinglePlyLapAdhesives_Update_Column>;
  where?: Maybe<Nav_SinglePlyLapAdhesives_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Inc_Input = {
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  AdhesiveUsageId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
  SinglePlyUsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Insert_Input = {
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  AdhesiveUsageId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
  SinglePlyUsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "Nav_SinglePlyLapAdhesives" */
export enum Nav_SinglePlyLapAdhesives_Insert_Match_Column {
  /** column name */
  AdhesiveComponentId = 'AdhesiveComponentId',
  /** column name */
  AdhesiveUsageId = 'AdhesiveUsageId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  SinglePlyComponentId = 'SinglePlyComponentId',
  /** column name */
  SinglePlyUsageId = 'SinglePlyUsageId'
}

/** aggregate max on columns */
export type Nav_SinglePlyLapAdhesives_Max_Fields = {
  __typename?: 'Nav_SinglePlyLapAdhesives_max_fields';
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  AdhesiveUsageId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
  SinglePlyUsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Max_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Nav_SinglePlyLapAdhesives_Min_Fields = {
  __typename?: 'Nav_SinglePlyLapAdhesives_min_fields';
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  AdhesiveUsageId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
  SinglePlyUsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Min_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Mutation_Response = {
  __typename?: 'Nav_SinglePlyLapAdhesives_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nav_SinglePlyLapAdhesives>;
};

/** Ordering options when selecting data from "Nav_SinglePlyLapAdhesives". */
export type Nav_SinglePlyLapAdhesives_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** select columns of table "Nav_SinglePlyLapAdhesives" */
export enum Nav_SinglePlyLapAdhesives_Select_Column {
  /** column name */
  AdhesiveComponentId = 'AdhesiveComponentId',
  /** column name */
  AdhesiveUsageId = 'AdhesiveUsageId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  SinglePlyComponentId = 'SinglePlyComponentId',
  /** column name */
  SinglePlyUsageId = 'SinglePlyUsageId'
}

/** input type for updating data in table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Set_Input = {
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  AdhesiveUsageId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
  SinglePlyUsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Nav_SinglePlyLapAdhesives_Stddev_Fields = {
  __typename?: 'Nav_SinglePlyLapAdhesives_stddev_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  AdhesiveUsageId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
  SinglePlyUsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Stddev_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nav_SinglePlyLapAdhesives_Stddev_Pop_Fields = {
  __typename?: 'Nav_SinglePlyLapAdhesives_stddev_pop_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  AdhesiveUsageId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
  SinglePlyUsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Stddev_Pop_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nav_SinglePlyLapAdhesives_Stddev_Samp_Fields = {
  __typename?: 'Nav_SinglePlyLapAdhesives_stddev_samp_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  AdhesiveUsageId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
  SinglePlyUsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Stddev_Samp_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Nav_SinglePlyLapAdhesives_Sum_Fields = {
  __typename?: 'Nav_SinglePlyLapAdhesives_sum_fields';
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  AdhesiveUsageId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
  SinglePlyUsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Sum_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** update columns of table "Nav_SinglePlyLapAdhesives" */
export enum Nav_SinglePlyLapAdhesives_Update_Column {
  /** column name */
  AdhesiveComponentId = 'AdhesiveComponentId',
  /** column name */
  AdhesiveUsageId = 'AdhesiveUsageId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  SinglePlyComponentId = 'SinglePlyComponentId',
  /** column name */
  SinglePlyUsageId = 'SinglePlyUsageId'
}

/** aggregate var_pop on columns */
export type Nav_SinglePlyLapAdhesives_Var_Pop_Fields = {
  __typename?: 'Nav_SinglePlyLapAdhesives_var_pop_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  AdhesiveUsageId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
  SinglePlyUsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Var_Pop_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nav_SinglePlyLapAdhesives_Var_Samp_Fields = {
  __typename?: 'Nav_SinglePlyLapAdhesives_var_samp_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  AdhesiveUsageId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
  SinglePlyUsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Var_Samp_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Nav_SinglePlyLapAdhesives_Variance_Fields = {
  __typename?: 'Nav_SinglePlyLapAdhesives_variance_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  AdhesiveUsageId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
  SinglePlyUsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Nav_SinglePlyLapAdhesives" */
export type Nav_SinglePlyLapAdhesives_Variance_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  AdhesiveUsageId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyUsageId?: Maybe<Order_By>;
};

/** columns and relationships of "NextAssemblyNum" */
export type NextAssemblyNum = {
  __typename?: 'NextAssemblyNum';
  AssemblyNum: Scalars['Int'];
};

/** aggregated selection of "NextAssemblyNum" */
export type NextAssemblyNum_Aggregate = {
  __typename?: 'NextAssemblyNum_aggregate';
  aggregate?: Maybe<NextAssemblyNum_Aggregate_Fields>;
  nodes: Array<NextAssemblyNum>;
};

/** aggregate fields of "NextAssemblyNum" */
export type NextAssemblyNum_Aggregate_Fields = {
  __typename?: 'NextAssemblyNum_aggregate_fields';
  avg?: Maybe<NextAssemblyNum_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<NextAssemblyNum_Max_Fields>;
  min?: Maybe<NextAssemblyNum_Min_Fields>;
  stddev?: Maybe<NextAssemblyNum_Stddev_Fields>;
  stddev_pop?: Maybe<NextAssemblyNum_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<NextAssemblyNum_Stddev_Samp_Fields>;
  sum?: Maybe<NextAssemblyNum_Sum_Fields>;
  var_pop?: Maybe<NextAssemblyNum_Var_Pop_Fields>;
  var_samp?: Maybe<NextAssemblyNum_Var_Samp_Fields>;
  variance?: Maybe<NextAssemblyNum_Variance_Fields>;
};


/** aggregate fields of "NextAssemblyNum" */
export type NextAssemblyNum_Aggregate_FieldsCountArgs = {
  column?: Maybe<NextAssemblyNum_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type NextAssemblyNum_Avg_Fields = {
  __typename?: 'NextAssemblyNum_avg_fields';
  AssemblyNum?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "NextAssemblyNum". All fields are combined with a logical 'AND'. */
export type NextAssemblyNum_Bool_Exp = {
  AssemblyNum?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<NextAssemblyNum_Bool_Exp>>;
  _not?: Maybe<NextAssemblyNum_Bool_Exp>;
  _or?: Maybe<Array<NextAssemblyNum_Bool_Exp>>;
};

/** upsert condition type for table "NextAssemblyNum" */
export type NextAssemblyNum_If_Matched = {
  match_columns?: Array<NextAssemblyNum_Insert_Match_Column>;
  update_columns?: Array<NextAssemblyNum_Update_Column>;
  where?: Maybe<NextAssemblyNum_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "NextAssemblyNum" */
export type NextAssemblyNum_Inc_Input = {
  AssemblyNum?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "NextAssemblyNum" */
export type NextAssemblyNum_Insert_Input = {
  AssemblyNum?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "NextAssemblyNum" */
export enum NextAssemblyNum_Insert_Match_Column {
  /** column name */
  AssemblyNum = 'AssemblyNum'
}

/** aggregate max on columns */
export type NextAssemblyNum_Max_Fields = {
  __typename?: 'NextAssemblyNum_max_fields';
  AssemblyNum?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type NextAssemblyNum_Min_Fields = {
  __typename?: 'NextAssemblyNum_min_fields';
  AssemblyNum?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "NextAssemblyNum" */
export type NextAssemblyNum_Mutation_Response = {
  __typename?: 'NextAssemblyNum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NextAssemblyNum>;
};

/** Ordering options when selecting data from "NextAssemblyNum". */
export type NextAssemblyNum_Order_By = {
  AssemblyNum?: Maybe<Order_By>;
};

/** primary key columns input for table: NextAssemblyNum */
export type NextAssemblyNum_Pk_Columns_Input = {
  AssemblyNum: Scalars['Int'];
};

/** select columns of table "NextAssemblyNum" */
export enum NextAssemblyNum_Select_Column {
  /** column name */
  AssemblyNum = 'AssemblyNum'
}

/** input type for updating data in table "NextAssemblyNum" */
export type NextAssemblyNum_Set_Input = {
  AssemblyNum?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type NextAssemblyNum_Stddev_Fields = {
  __typename?: 'NextAssemblyNum_stddev_fields';
  AssemblyNum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type NextAssemblyNum_Stddev_Pop_Fields = {
  __typename?: 'NextAssemblyNum_stddev_pop_fields';
  AssemblyNum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type NextAssemblyNum_Stddev_Samp_Fields = {
  __typename?: 'NextAssemblyNum_stddev_samp_fields';
  AssemblyNum?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type NextAssemblyNum_Sum_Fields = {
  __typename?: 'NextAssemblyNum_sum_fields';
  AssemblyNum?: Maybe<Scalars['Int']>;
};

/** update columns of table "NextAssemblyNum" */
export enum NextAssemblyNum_Update_Column {
  /** column name */
  AssemblyNum = 'AssemblyNum'
}

/** aggregate var_pop on columns */
export type NextAssemblyNum_Var_Pop_Fields = {
  __typename?: 'NextAssemblyNum_var_pop_fields';
  AssemblyNum?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type NextAssemblyNum_Var_Samp_Fields = {
  __typename?: 'NextAssemblyNum_var_samp_fields';
  AssemblyNum?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type NextAssemblyNum_Variance_Fields = {
  __typename?: 'NextAssemblyNum_variance_fields';
  AssemblyNum?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Note" */
export type Note = {
  __typename?: 'Note';
  Body: Scalars['String'];
  CreatedDate: Scalars['datetime'];
  NoteId: Scalars['Int'];
  /** An array relationship */
  R_ProjectNotes: Array<R_ProjectNote>;
  /** An aggregate relationship */
  R_ProjectNotes_aggregate: R_ProjectNote_Aggregate;
  /** An array relationship */
  R_RoofAreaNotes: Array<R_RoofAreaNote>;
  /** An aggregate relationship */
  R_RoofAreaNotes_aggregate: R_RoofAreaNote_Aggregate;
  /** An array relationship */
  R_ScenarioNotes: Array<R_ScenarioNote>;
  /** An aggregate relationship */
  R_ScenarioNotes_aggregate: R_ScenarioNote_Aggregate;
  Title: Scalars['String'];
};


/** columns and relationships of "Note" */
export type NoteR_ProjectNotesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectNote_Order_By>>;
  where?: Maybe<R_ProjectNote_Bool_Exp>;
};


/** columns and relationships of "Note" */
export type NoteR_ProjectNotes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectNote_Order_By>>;
  where?: Maybe<R_ProjectNote_Bool_Exp>;
};


/** columns and relationships of "Note" */
export type NoteR_RoofAreaNotesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaNote_Order_By>>;
  where?: Maybe<R_RoofAreaNote_Bool_Exp>;
};


/** columns and relationships of "Note" */
export type NoteR_RoofAreaNotes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaNote_Order_By>>;
  where?: Maybe<R_RoofAreaNote_Bool_Exp>;
};


/** columns and relationships of "Note" */
export type NoteR_ScenarioNotesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ScenarioNote_Order_By>>;
  where?: Maybe<R_ScenarioNote_Bool_Exp>;
};


/** columns and relationships of "Note" */
export type NoteR_ScenarioNotes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ScenarioNote_Order_By>>;
  where?: Maybe<R_ScenarioNote_Bool_Exp>;
};

export type NoteInfo = {
  Body?: Maybe<Scalars['String']>;
  NoteId?: Maybe<Scalars['Int']>;
  Title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Note" */
export type Note_Aggregate = {
  __typename?: 'Note_aggregate';
  aggregate?: Maybe<Note_Aggregate_Fields>;
  nodes: Array<Note>;
};

/** aggregate fields of "Note" */
export type Note_Aggregate_Fields = {
  __typename?: 'Note_aggregate_fields';
  avg?: Maybe<Note_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Note_Max_Fields>;
  min?: Maybe<Note_Min_Fields>;
  stddev?: Maybe<Note_Stddev_Fields>;
  stddev_pop?: Maybe<Note_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Note_Stddev_Samp_Fields>;
  sum?: Maybe<Note_Sum_Fields>;
  var_pop?: Maybe<Note_Var_Pop_Fields>;
  var_samp?: Maybe<Note_Var_Samp_Fields>;
  variance?: Maybe<Note_Variance_Fields>;
};


/** aggregate fields of "Note" */
export type Note_Aggregate_FieldsCountArgs = {
  column?: Maybe<Note_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Note_Avg_Fields = {
  __typename?: 'Note_avg_fields';
  NoteId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Note". All fields are combined with a logical 'AND'. */
export type Note_Bool_Exp = {
  Body?: Maybe<String_Mssql_Comparison_Exp>;
  CreatedDate?: Maybe<Datetime_Mssql_Comparison_Exp>;
  NoteId?: Maybe<Int_Mssql_Comparison_Exp>;
  R_ProjectNotes?: Maybe<R_ProjectNote_Bool_Exp>;
  R_RoofAreaNotes?: Maybe<R_RoofAreaNote_Bool_Exp>;
  R_ScenarioNotes?: Maybe<R_ScenarioNote_Bool_Exp>;
  Title?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Note_Bool_Exp>>;
  _not?: Maybe<Note_Bool_Exp>;
  _or?: Maybe<Array<Note_Bool_Exp>>;
};

/** upsert condition type for table "Note" */
export type Note_If_Matched = {
  match_columns?: Array<Note_Insert_Match_Column>;
  update_columns?: Array<Note_Update_Column>;
  where?: Maybe<Note_Bool_Exp>;
};

/** input type for inserting data into table "Note" */
export type Note_Insert_Input = {
  Body?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['datetime']>;
  Title?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Note" */
export enum Note_Insert_Match_Column {
  /** column name */
  Body = 'Body',
  /** column name */
  CreatedDate = 'CreatedDate',
  /** column name */
  NoteId = 'NoteId',
  /** column name */
  Title = 'Title'
}

/** aggregate max on columns */
export type Note_Max_Fields = {
  __typename?: 'Note_max_fields';
  Body?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['datetime']>;
  NoteId?: Maybe<Scalars['Int']>;
  Title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Note_Min_Fields = {
  __typename?: 'Note_min_fields';
  Body?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['datetime']>;
  NoteId?: Maybe<Scalars['Int']>;
  Title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Note" */
export type Note_Mutation_Response = {
  __typename?: 'Note_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Note>;
};

/** Ordering options when selecting data from "Note". */
export type Note_Order_By = {
  Body?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  NoteId?: Maybe<Order_By>;
  R_ProjectNotes_aggregate?: Maybe<R_ProjectNote_Aggregate_Order_By>;
  R_RoofAreaNotes_aggregate?: Maybe<R_RoofAreaNote_Aggregate_Order_By>;
  R_ScenarioNotes_aggregate?: Maybe<R_ScenarioNote_Aggregate_Order_By>;
  Title?: Maybe<Order_By>;
};

/** primary key columns input for table: Note */
export type Note_Pk_Columns_Input = {
  NoteId: Scalars['Int'];
};

/** select columns of table "Note" */
export enum Note_Select_Column {
  /** column name */
  Body = 'Body',
  /** column name */
  CreatedDate = 'CreatedDate',
  /** column name */
  NoteId = 'NoteId',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "Note" */
export type Note_Set_Input = {
  Body?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['datetime']>;
  Title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Note_Stddev_Fields = {
  __typename?: 'Note_stddev_fields';
  NoteId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Note_Stddev_Pop_Fields = {
  __typename?: 'Note_stddev_pop_fields';
  NoteId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Note_Stddev_Samp_Fields = {
  __typename?: 'Note_stddev_samp_fields';
  NoteId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Note_Sum_Fields = {
  __typename?: 'Note_sum_fields';
  NoteId?: Maybe<Scalars['Int']>;
};

/** update columns of table "Note" */
export enum Note_Update_Column {
  /** column name */
  Body = 'Body',
  /** column name */
  CreatedDate = 'CreatedDate',
  /** column name */
  Title = 'Title'
}

/** aggregate var_pop on columns */
export type Note_Var_Pop_Fields = {
  __typename?: 'Note_var_pop_fields';
  NoteId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Note_Var_Samp_Fields = {
  __typename?: 'Note_var_samp_fields';
  NoteId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Note_Variance_Fields = {
  __typename?: 'Note_variance_fields';
  NoteId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "OtherPly" */
export type OtherPly = {
  __typename?: 'OtherPly';
  BackingMaterialId: Scalars['Int'];
  /** An object relationship */
  C_BackingMaterial: C_BackingMaterial;
  /** An object relationship */
  C_MultiplyMaterial: C_MultiplyMaterial;
  /** An object relationship */
  C_Reinforcement: C_Reinforcement;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  MultiplyMaterialId: Scalars['Int'];
  ReinforcementId: Scalars['Int'];
  Width: Scalars['MeasurementValue'];
};

/** columns and relationships of "OtherPlyUsage" */
export type OtherPlyUsage = {
  __typename?: 'OtherPlyUsage';
  NumPlies: Scalars['Int'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "OtherPlyUsage" */
export type OtherPlyUsage_Aggregate = {
  __typename?: 'OtherPlyUsage_aggregate';
  aggregate?: Maybe<OtherPlyUsage_Aggregate_Fields>;
  nodes: Array<OtherPlyUsage>;
};

/** aggregate fields of "OtherPlyUsage" */
export type OtherPlyUsage_Aggregate_Fields = {
  __typename?: 'OtherPlyUsage_aggregate_fields';
  avg?: Maybe<OtherPlyUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<OtherPlyUsage_Max_Fields>;
  min?: Maybe<OtherPlyUsage_Min_Fields>;
  stddev?: Maybe<OtherPlyUsage_Stddev_Fields>;
  stddev_pop?: Maybe<OtherPlyUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<OtherPlyUsage_Stddev_Samp_Fields>;
  sum?: Maybe<OtherPlyUsage_Sum_Fields>;
  var_pop?: Maybe<OtherPlyUsage_Var_Pop_Fields>;
  var_samp?: Maybe<OtherPlyUsage_Var_Samp_Fields>;
  variance?: Maybe<OtherPlyUsage_Variance_Fields>;
};


/** aggregate fields of "OtherPlyUsage" */
export type OtherPlyUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<OtherPlyUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type OtherPlyUsage_Avg_Fields = {
  __typename?: 'OtherPlyUsage_avg_fields';
  NumPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "OtherPlyUsage". All fields are combined with a logical 'AND'. */
export type OtherPlyUsage_Bool_Exp = {
  NumPlies?: Maybe<Int_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<OtherPlyUsage_Bool_Exp>>;
  _not?: Maybe<OtherPlyUsage_Bool_Exp>;
  _or?: Maybe<Array<OtherPlyUsage_Bool_Exp>>;
};

/** upsert condition type for table "OtherPlyUsage" */
export type OtherPlyUsage_If_Matched = {
  match_columns?: Array<OtherPlyUsage_Insert_Match_Column>;
  update_columns?: Array<OtherPlyUsage_Update_Column>;
  where?: Maybe<OtherPlyUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "OtherPlyUsage" */
export type OtherPlyUsage_Inc_Input = {
  NumPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "OtherPlyUsage" */
export type OtherPlyUsage_Insert_Input = {
  NumPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "OtherPlyUsage" */
export enum OtherPlyUsage_Insert_Match_Column {
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type OtherPlyUsage_Max_Fields = {
  __typename?: 'OtherPlyUsage_max_fields';
  NumPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type OtherPlyUsage_Min_Fields = {
  __typename?: 'OtherPlyUsage_min_fields';
  NumPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "OtherPlyUsage" */
export type OtherPlyUsage_Mutation_Response = {
  __typename?: 'OtherPlyUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OtherPlyUsage>;
};

/** Ordering options when selecting data from "OtherPlyUsage". */
export type OtherPlyUsage_Order_By = {
  NumPlies?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: OtherPlyUsage */
export type OtherPlyUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "OtherPlyUsage" */
export enum OtherPlyUsage_Select_Column {
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "OtherPlyUsage" */
export type OtherPlyUsage_Set_Input = {
  NumPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type OtherPlyUsage_Stddev_Fields = {
  __typename?: 'OtherPlyUsage_stddev_fields';
  NumPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OtherPlyUsage_Stddev_Pop_Fields = {
  __typename?: 'OtherPlyUsage_stddev_pop_fields';
  NumPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OtherPlyUsage_Stddev_Samp_Fields = {
  __typename?: 'OtherPlyUsage_stddev_samp_fields';
  NumPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OtherPlyUsage_Sum_Fields = {
  __typename?: 'OtherPlyUsage_sum_fields';
  NumPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "OtherPlyUsage" */
export enum OtherPlyUsage_Update_Column {
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type OtherPlyUsage_Var_Pop_Fields = {
  __typename?: 'OtherPlyUsage_var_pop_fields';
  NumPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OtherPlyUsage_Var_Samp_Fields = {
  __typename?: 'OtherPlyUsage_var_samp_fields';
  NumPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OtherPlyUsage_Variance_Fields = {
  __typename?: 'OtherPlyUsage_variance_fields';
  NumPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "OtherPly" */
export type OtherPly_Aggregate = {
  __typename?: 'OtherPly_aggregate';
  aggregate?: Maybe<OtherPly_Aggregate_Fields>;
  nodes: Array<OtherPly>;
};

/** aggregate fields of "OtherPly" */
export type OtherPly_Aggregate_Fields = {
  __typename?: 'OtherPly_aggregate_fields';
  avg?: Maybe<OtherPly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<OtherPly_Max_Fields>;
  min?: Maybe<OtherPly_Min_Fields>;
  stddev?: Maybe<OtherPly_Stddev_Fields>;
  stddev_pop?: Maybe<OtherPly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<OtherPly_Stddev_Samp_Fields>;
  sum?: Maybe<OtherPly_Sum_Fields>;
  var_pop?: Maybe<OtherPly_Var_Pop_Fields>;
  var_samp?: Maybe<OtherPly_Var_Samp_Fields>;
  variance?: Maybe<OtherPly_Variance_Fields>;
};


/** aggregate fields of "OtherPly" */
export type OtherPly_Aggregate_FieldsCountArgs = {
  column?: Maybe<OtherPly_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "OtherPly" */
export type OtherPly_Aggregate_Order_By = {
  avg?: Maybe<OtherPly_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<OtherPly_Max_Order_By>;
  min?: Maybe<OtherPly_Min_Order_By>;
  stddev?: Maybe<OtherPly_Stddev_Order_By>;
  stddev_pop?: Maybe<OtherPly_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<OtherPly_Stddev_Samp_Order_By>;
  sum?: Maybe<OtherPly_Sum_Order_By>;
  var_pop?: Maybe<OtherPly_Var_Pop_Order_By>;
  var_samp?: Maybe<OtherPly_Var_Samp_Order_By>;
  variance?: Maybe<OtherPly_Variance_Order_By>;
};

/** aggregate avg on columns */
export type OtherPly_Avg_Fields = {
  __typename?: 'OtherPly_avg_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "OtherPly" */
export type OtherPly_Avg_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "OtherPly". All fields are combined with a logical 'AND'. */
export type OtherPly_Bool_Exp = {
  BackingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_BackingMaterial?: Maybe<C_BackingMaterial_Bool_Exp>;
  C_MultiplyMaterial?: Maybe<C_MultiplyMaterial_Bool_Exp>;
  C_Reinforcement?: Maybe<C_Reinforcement_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  MultiplyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ReinforcementId?: Maybe<Int_Mssql_Comparison_Exp>;
  Width?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<OtherPly_Bool_Exp>>;
  _not?: Maybe<OtherPly_Bool_Exp>;
  _or?: Maybe<Array<OtherPly_Bool_Exp>>;
};

/** upsert condition type for table "OtherPly" */
export type OtherPly_If_Matched = {
  match_columns?: Array<OtherPly_Insert_Match_Column>;
  update_columns?: Array<OtherPly_Update_Column>;
  where?: Maybe<OtherPly_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "OtherPly" */
export type OtherPly_Inc_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "OtherPly" */
export type OtherPly_Insert_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "OtherPly" */
export enum OtherPly_Insert_Match_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  Width = 'Width'
}

/** aggregate max on columns */
export type OtherPly_Max_Fields = {
  __typename?: 'OtherPly_max_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "OtherPly" */
export type OtherPly_Max_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type OtherPly_Min_Fields = {
  __typename?: 'OtherPly_min_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "OtherPly" */
export type OtherPly_Min_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** response of any mutation on the table "OtherPly" */
export type OtherPly_Mutation_Response = {
  __typename?: 'OtherPly_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OtherPly>;
};

/** Ordering options when selecting data from "OtherPly". */
export type OtherPly_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  C_BackingMaterial?: Maybe<C_BackingMaterial_Order_By>;
  C_MultiplyMaterial?: Maybe<C_MultiplyMaterial_Order_By>;
  C_Reinforcement?: Maybe<C_Reinforcement_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** primary key columns input for table: OtherPly */
export type OtherPly_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "OtherPly" */
export enum OtherPly_Select_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  Width = 'Width'
}

/** input type for updating data in table "OtherPly" */
export type OtherPly_Set_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type OtherPly_Stddev_Fields = {
  __typename?: 'OtherPly_stddev_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "OtherPly" */
export type OtherPly_Stddev_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type OtherPly_Stddev_Pop_Fields = {
  __typename?: 'OtherPly_stddev_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "OtherPly" */
export type OtherPly_Stddev_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type OtherPly_Stddev_Samp_Fields = {
  __typename?: 'OtherPly_stddev_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "OtherPly" */
export type OtherPly_Stddev_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type OtherPly_Sum_Fields = {
  __typename?: 'OtherPly_sum_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "OtherPly" */
export type OtherPly_Sum_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** update columns of table "OtherPly" */
export enum OtherPly_Update_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  Width = 'Width'
}

/** aggregate var_pop on columns */
export type OtherPly_Var_Pop_Fields = {
  __typename?: 'OtherPly_var_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "OtherPly" */
export type OtherPly_Var_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type OtherPly_Var_Samp_Fields = {
  __typename?: 'OtherPly_var_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "OtherPly" */
export type OtherPly_Var_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type OtherPly_Variance_Fields = {
  __typename?: 'OtherPly_variance_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "OtherPly" */
export type OtherPly_Variance_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
};

/** columns and relationships of "PreassembledFastening" */
export type PreassembledFastening = {
  __typename?: 'PreassembledFastening';
  /** An object relationship */
  C_FastenerCoating: C_FastenerCoating;
  /** An object relationship */
  C_FastenerDriveHead: C_FastenerDriveHead;
  /** An object relationship */
  C_FastenerType: C_FastenerType;
  /** An object relationship */
  C_SecurementMaterial: C_SecurementMaterial;
  /** An object relationship */
  C_StressDistributorType: C_StressDistributorType;
  /** An object relationship */
  C_StressPlateShape: C_StressPlateShape;
  ComponentId: Scalars['Int'];
  DeckDesignThicknessMax: Scalars['MeasurementValue'];
  Diameter: Scalars['MeasurementValue'];
  FastenerCoatingId: Scalars['Int'];
  FastenerDiameter: Scalars['MeasurementValue'];
  FastenerDriveHeadId: Scalars['Int'];
  FastenerMaterialId: Scalars['Int'];
  FastenerTypeId: Scalars['Int'];
  HoleSpacing: Scalars['MeasurementValue'];
  IsSecuredToConcreteDeck: Scalars['Boolean'];
  IsSteelDeckFastener: Scalars['Boolean'];
  Length: Scalars['MeasurementValue'];
  LengthMax: Scalars['MeasurementValue'];
  LengthMin: Scalars['MeasurementValue'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  StressDistributorMaterialId: Scalars['Int'];
  StressDistributorTypeId: Scalars['Int'];
  StressPlateShapeId: Scalars['Int'];
  StructureThicknessMax: Scalars['MeasurementValue'];
  StructureThicknessMin: Scalars['MeasurementValue'];
  Thickness: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  Width: Scalars['MeasurementValue'];
  /** An object relationship */
  cSecurementmaterialByFastenermaterialid: C_SecurementMaterial;
};

/** columns and relationships of "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage = {
  __typename?: 'PreassembledFasteningUsage';
  /** An object relationship */
  C_FasteningApplicationMethod: C_FasteningApplicationMethod;
  /** An object relationship */
  C_StressDistributorType: C_StressDistributorType;
  ContributoryArea: Scalars['MeasurementValue'];
  Embedment: Scalars['MeasurementValue'];
  FastenersPerPlateOrClip: Scalars['Int'];
  FasteningApplicationMethodId: Scalars['Int'];
  FieldRowsOnCenter: Scalars['MeasurementValue'];
  IsFieldRows: Scalars['Boolean'];
  NumFieldRows: Scalars['Int'];
  OnCenter: Scalars['MeasurementValue'];
  RowSpacing: Scalars['MeasurementValue'];
  StressDistributorTypeId: Scalars['Int'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Aggregate = {
  __typename?: 'PreassembledFasteningUsage_aggregate';
  aggregate?: Maybe<PreassembledFasteningUsage_Aggregate_Fields>;
  nodes: Array<PreassembledFasteningUsage>;
};

/** aggregate fields of "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Aggregate_Fields = {
  __typename?: 'PreassembledFasteningUsage_aggregate_fields';
  avg?: Maybe<PreassembledFasteningUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PreassembledFasteningUsage_Max_Fields>;
  min?: Maybe<PreassembledFasteningUsage_Min_Fields>;
  stddev?: Maybe<PreassembledFasteningUsage_Stddev_Fields>;
  stddev_pop?: Maybe<PreassembledFasteningUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PreassembledFasteningUsage_Stddev_Samp_Fields>;
  sum?: Maybe<PreassembledFasteningUsage_Sum_Fields>;
  var_pop?: Maybe<PreassembledFasteningUsage_Var_Pop_Fields>;
  var_samp?: Maybe<PreassembledFasteningUsage_Var_Samp_Fields>;
  variance?: Maybe<PreassembledFasteningUsage_Variance_Fields>;
};


/** aggregate fields of "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<PreassembledFasteningUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Aggregate_Order_By = {
  avg?: Maybe<PreassembledFasteningUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<PreassembledFasteningUsage_Max_Order_By>;
  min?: Maybe<PreassembledFasteningUsage_Min_Order_By>;
  stddev?: Maybe<PreassembledFasteningUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<PreassembledFasteningUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<PreassembledFasteningUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<PreassembledFasteningUsage_Sum_Order_By>;
  var_pop?: Maybe<PreassembledFasteningUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<PreassembledFasteningUsage_Var_Samp_Order_By>;
  variance?: Maybe<PreassembledFasteningUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type PreassembledFasteningUsage_Avg_Fields = {
  __typename?: 'PreassembledFasteningUsage_avg_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Avg_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PreassembledFasteningUsage". All fields are combined with a logical 'AND'. */
export type PreassembledFasteningUsage_Bool_Exp = {
  C_FasteningApplicationMethod?: Maybe<C_FasteningApplicationMethod_Bool_Exp>;
  C_StressDistributorType?: Maybe<C_StressDistributorType_Bool_Exp>;
  ContributoryArea?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Embedment?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  FastenersPerPlateOrClip?: Maybe<Int_Mssql_Comparison_Exp>;
  FasteningApplicationMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  FieldRowsOnCenter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  IsFieldRows?: Maybe<Boolean_Mssql_Comparison_Exp>;
  NumFieldRows?: Maybe<Int_Mssql_Comparison_Exp>;
  OnCenter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  RowSpacing?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  StressDistributorTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<PreassembledFasteningUsage_Bool_Exp>>;
  _not?: Maybe<PreassembledFasteningUsage_Bool_Exp>;
  _or?: Maybe<Array<PreassembledFasteningUsage_Bool_Exp>>;
};

/** upsert condition type for table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_If_Matched = {
  match_columns?: Array<PreassembledFasteningUsage_Insert_Match_Column>;
  update_columns?: Array<PreassembledFasteningUsage_Update_Column>;
  where?: Maybe<PreassembledFasteningUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Inc_Input = {
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Insert_Input = {
  ContributoryArea?: Maybe<Scalars['MeasurementValue']>;
  Embedment?: Maybe<Scalars['MeasurementValue']>;
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  IsFieldRows?: Maybe<Scalars['Boolean']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "PreassembledFasteningUsage" */
export enum PreassembledFasteningUsage_Insert_Match_Column {
  /** column name */
  ContributoryArea = 'ContributoryArea',
  /** column name */
  Embedment = 'Embedment',
  /** column name */
  FastenersPerPlateOrClip = 'FastenersPerPlateOrClip',
  /** column name */
  FasteningApplicationMethodId = 'FasteningApplicationMethodId',
  /** column name */
  FieldRowsOnCenter = 'FieldRowsOnCenter',
  /** column name */
  IsFieldRows = 'IsFieldRows',
  /** column name */
  NumFieldRows = 'NumFieldRows',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  StressDistributorTypeId = 'StressDistributorTypeId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type PreassembledFasteningUsage_Max_Fields = {
  __typename?: 'PreassembledFasteningUsage_max_fields';
  ContributoryArea?: Maybe<Scalars['MeasurementValue']>;
  Embedment?: Maybe<Scalars['MeasurementValue']>;
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Max_Order_By = {
  ContributoryArea?: Maybe<Order_By>;
  Embedment?: Maybe<Order_By>;
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  FieldRowsOnCenter?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type PreassembledFasteningUsage_Min_Fields = {
  __typename?: 'PreassembledFasteningUsage_min_fields';
  ContributoryArea?: Maybe<Scalars['MeasurementValue']>;
  Embedment?: Maybe<Scalars['MeasurementValue']>;
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Min_Order_By = {
  ContributoryArea?: Maybe<Order_By>;
  Embedment?: Maybe<Order_By>;
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  FieldRowsOnCenter?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Mutation_Response = {
  __typename?: 'PreassembledFasteningUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PreassembledFasteningUsage>;
};

/** Ordering options when selecting data from "PreassembledFasteningUsage". */
export type PreassembledFasteningUsage_Order_By = {
  C_FasteningApplicationMethod?: Maybe<C_FasteningApplicationMethod_Order_By>;
  C_StressDistributorType?: Maybe<C_StressDistributorType_Order_By>;
  ContributoryArea?: Maybe<Order_By>;
  Embedment?: Maybe<Order_By>;
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  FieldRowsOnCenter?: Maybe<Order_By>;
  IsFieldRows?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: PreassembledFasteningUsage */
export type PreassembledFasteningUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "PreassembledFasteningUsage" */
export enum PreassembledFasteningUsage_Select_Column {
  /** column name */
  ContributoryArea = 'ContributoryArea',
  /** column name */
  Embedment = 'Embedment',
  /** column name */
  FastenersPerPlateOrClip = 'FastenersPerPlateOrClip',
  /** column name */
  FasteningApplicationMethodId = 'FasteningApplicationMethodId',
  /** column name */
  FieldRowsOnCenter = 'FieldRowsOnCenter',
  /** column name */
  IsFieldRows = 'IsFieldRows',
  /** column name */
  NumFieldRows = 'NumFieldRows',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  StressDistributorTypeId = 'StressDistributorTypeId',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Set_Input = {
  ContributoryArea?: Maybe<Scalars['MeasurementValue']>;
  Embedment?: Maybe<Scalars['MeasurementValue']>;
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  FieldRowsOnCenter?: Maybe<Scalars['MeasurementValue']>;
  IsFieldRows?: Maybe<Scalars['Boolean']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type PreassembledFasteningUsage_Stddev_Fields = {
  __typename?: 'PreassembledFasteningUsage_stddev_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Stddev_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PreassembledFasteningUsage_Stddev_Pop_Fields = {
  __typename?: 'PreassembledFasteningUsage_stddev_pop_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Stddev_Pop_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PreassembledFasteningUsage_Stddev_Samp_Fields = {
  __typename?: 'PreassembledFasteningUsage_stddev_samp_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Stddev_Samp_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type PreassembledFasteningUsage_Sum_Fields = {
  __typename?: 'PreassembledFasteningUsage_sum_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Int']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Int']>;
  NumFieldRows?: Maybe<Scalars['Int']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Sum_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "PreassembledFasteningUsage" */
export enum PreassembledFasteningUsage_Update_Column {
  /** column name */
  ContributoryArea = 'ContributoryArea',
  /** column name */
  Embedment = 'Embedment',
  /** column name */
  FastenersPerPlateOrClip = 'FastenersPerPlateOrClip',
  /** column name */
  FasteningApplicationMethodId = 'FasteningApplicationMethodId',
  /** column name */
  FieldRowsOnCenter = 'FieldRowsOnCenter',
  /** column name */
  IsFieldRows = 'IsFieldRows',
  /** column name */
  NumFieldRows = 'NumFieldRows',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  StressDistributorTypeId = 'StressDistributorTypeId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type PreassembledFasteningUsage_Var_Pop_Fields = {
  __typename?: 'PreassembledFasteningUsage_var_pop_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Var_Pop_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PreassembledFasteningUsage_Var_Samp_Fields = {
  __typename?: 'PreassembledFasteningUsage_var_samp_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Var_Samp_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type PreassembledFasteningUsage_Variance_Fields = {
  __typename?: 'PreassembledFasteningUsage_variance_fields';
  FastenersPerPlateOrClip?: Maybe<Scalars['Float']>;
  FasteningApplicationMethodId?: Maybe<Scalars['Float']>;
  NumFieldRows?: Maybe<Scalars['Float']>;
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "PreassembledFasteningUsage" */
export type PreassembledFasteningUsage_Variance_Order_By = {
  FastenersPerPlateOrClip?: Maybe<Order_By>;
  FasteningApplicationMethodId?: Maybe<Order_By>;
  NumFieldRows?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregated selection of "PreassembledFastening" */
export type PreassembledFastening_Aggregate = {
  __typename?: 'PreassembledFastening_aggregate';
  aggregate?: Maybe<PreassembledFastening_Aggregate_Fields>;
  nodes: Array<PreassembledFastening>;
};

/** aggregate fields of "PreassembledFastening" */
export type PreassembledFastening_Aggregate_Fields = {
  __typename?: 'PreassembledFastening_aggregate_fields';
  avg?: Maybe<PreassembledFastening_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PreassembledFastening_Max_Fields>;
  min?: Maybe<PreassembledFastening_Min_Fields>;
  stddev?: Maybe<PreassembledFastening_Stddev_Fields>;
  stddev_pop?: Maybe<PreassembledFastening_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PreassembledFastening_Stddev_Samp_Fields>;
  sum?: Maybe<PreassembledFastening_Sum_Fields>;
  var_pop?: Maybe<PreassembledFastening_Var_Pop_Fields>;
  var_samp?: Maybe<PreassembledFastening_Var_Samp_Fields>;
  variance?: Maybe<PreassembledFastening_Variance_Fields>;
};


/** aggregate fields of "PreassembledFastening" */
export type PreassembledFastening_Aggregate_FieldsCountArgs = {
  column?: Maybe<PreassembledFastening_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PreassembledFastening" */
export type PreassembledFastening_Aggregate_Order_By = {
  avg?: Maybe<PreassembledFastening_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<PreassembledFastening_Max_Order_By>;
  min?: Maybe<PreassembledFastening_Min_Order_By>;
  stddev?: Maybe<PreassembledFastening_Stddev_Order_By>;
  stddev_pop?: Maybe<PreassembledFastening_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<PreassembledFastening_Stddev_Samp_Order_By>;
  sum?: Maybe<PreassembledFastening_Sum_Order_By>;
  var_pop?: Maybe<PreassembledFastening_Var_Pop_Order_By>;
  var_samp?: Maybe<PreassembledFastening_Var_Samp_Order_By>;
  variance?: Maybe<PreassembledFastening_Variance_Order_By>;
};

/** aggregate avg on columns */
export type PreassembledFastening_Avg_Fields = {
  __typename?: 'PreassembledFastening_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FastenerCoatingId?: Maybe<Scalars['Float']>;
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
  FastenerMaterialId?: Maybe<Scalars['Float']>;
  FastenerTypeId?: Maybe<Scalars['Float']>;
  StressDistributorMaterialId?: Maybe<Scalars['Float']>;
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "PreassembledFastening" */
export type PreassembledFastening_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerMaterialId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  StressDistributorMaterialId?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PreassembledFastening". All fields are combined with a logical 'AND'. */
export type PreassembledFastening_Bool_Exp = {
  C_FastenerCoating?: Maybe<C_FastenerCoating_Bool_Exp>;
  C_FastenerDriveHead?: Maybe<C_FastenerDriveHead_Bool_Exp>;
  C_FastenerType?: Maybe<C_FastenerType_Bool_Exp>;
  C_SecurementMaterial?: Maybe<C_SecurementMaterial_Bool_Exp>;
  C_StressDistributorType?: Maybe<C_StressDistributorType_Bool_Exp>;
  C_StressPlateShape?: Maybe<C_StressPlateShape_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  DeckDesignThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Diameter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  FastenerCoatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  FastenerDiameter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  FastenerDriveHeadId?: Maybe<Int_Mssql_Comparison_Exp>;
  FastenerMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  FastenerTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  HoleSpacing?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  IsSecuredToConcreteDeck?: Maybe<Boolean_Mssql_Comparison_Exp>;
  IsSteelDeckFastener?: Maybe<Boolean_Mssql_Comparison_Exp>;
  Length?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  LengthMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  LengthMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  StressDistributorMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  StressDistributorTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  StressPlateShapeId?: Maybe<Int_Mssql_Comparison_Exp>;
  StructureThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  StructureThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Thickness?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Width?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<PreassembledFastening_Bool_Exp>>;
  _not?: Maybe<PreassembledFastening_Bool_Exp>;
  _or?: Maybe<Array<PreassembledFastening_Bool_Exp>>;
  cSecurementmaterialByFastenermaterialid?: Maybe<C_SecurementMaterial_Bool_Exp>;
};

/** upsert condition type for table "PreassembledFastening" */
export type PreassembledFastening_If_Matched = {
  match_columns?: Array<PreassembledFastening_Insert_Match_Column>;
  update_columns?: Array<PreassembledFastening_Update_Column>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "PreassembledFastening" */
export type PreassembledFastening_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FastenerCoatingId?: Maybe<Scalars['Int']>;
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
  FastenerMaterialId?: Maybe<Scalars['Int']>;
  FastenerTypeId?: Maybe<Scalars['Int']>;
  StressDistributorMaterialId?: Maybe<Scalars['Int']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
  StressPlateShapeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "PreassembledFastening" */
export type PreassembledFastening_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DeckDesignThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  FastenerCoatingId?: Maybe<Scalars['Int']>;
  FastenerDiameter?: Maybe<Scalars['MeasurementValue']>;
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
  FastenerMaterialId?: Maybe<Scalars['Int']>;
  FastenerTypeId?: Maybe<Scalars['Int']>;
  HoleSpacing?: Maybe<Scalars['MeasurementValue']>;
  IsSecuredToConcreteDeck?: Maybe<Scalars['Boolean']>;
  IsSteelDeckFastener?: Maybe<Scalars['Boolean']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  LengthMax?: Maybe<Scalars['MeasurementValue']>;
  LengthMin?: Maybe<Scalars['MeasurementValue']>;
  StressDistributorMaterialId?: Maybe<Scalars['Int']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
  StressPlateShapeId?: Maybe<Scalars['Int']>;
  StructureThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  StructureThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Thickness?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "PreassembledFastening" */
export enum PreassembledFastening_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DeckDesignThicknessMax = 'DeckDesignThicknessMax',
  /** column name */
  Diameter = 'Diameter',
  /** column name */
  FastenerCoatingId = 'FastenerCoatingId',
  /** column name */
  FastenerDiameter = 'FastenerDiameter',
  /** column name */
  FastenerDriveHeadId = 'FastenerDriveHeadId',
  /** column name */
  FastenerMaterialId = 'FastenerMaterialId',
  /** column name */
  FastenerTypeId = 'FastenerTypeId',
  /** column name */
  HoleSpacing = 'HoleSpacing',
  /** column name */
  IsSecuredToConcreteDeck = 'IsSecuredToConcreteDeck',
  /** column name */
  IsSteelDeckFastener = 'IsSteelDeckFastener',
  /** column name */
  Length = 'Length',
  /** column name */
  LengthMax = 'LengthMax',
  /** column name */
  LengthMin = 'LengthMin',
  /** column name */
  StressDistributorMaterialId = 'StressDistributorMaterialId',
  /** column name */
  StressDistributorTypeId = 'StressDistributorTypeId',
  /** column name */
  StressPlateShapeId = 'StressPlateShapeId',
  /** column name */
  StructureThicknessMax = 'StructureThicknessMax',
  /** column name */
  StructureThicknessMin = 'StructureThicknessMin',
  /** column name */
  Thickness = 'Thickness',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  Width = 'Width'
}

/** aggregate max on columns */
export type PreassembledFastening_Max_Fields = {
  __typename?: 'PreassembledFastening_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DeckDesignThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  FastenerCoatingId?: Maybe<Scalars['Int']>;
  FastenerDiameter?: Maybe<Scalars['MeasurementValue']>;
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
  FastenerMaterialId?: Maybe<Scalars['Int']>;
  FastenerTypeId?: Maybe<Scalars['Int']>;
  HoleSpacing?: Maybe<Scalars['MeasurementValue']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  LengthMax?: Maybe<Scalars['MeasurementValue']>;
  LengthMin?: Maybe<Scalars['MeasurementValue']>;
  StressDistributorMaterialId?: Maybe<Scalars['Int']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
  StressPlateShapeId?: Maybe<Scalars['Int']>;
  StructureThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  StructureThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Thickness?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "PreassembledFastening" */
export type PreassembledFastening_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DeckDesignThicknessMax?: Maybe<Order_By>;
  Diameter?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDiameter?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerMaterialId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  HoleSpacing?: Maybe<Order_By>;
  Length?: Maybe<Order_By>;
  LengthMax?: Maybe<Order_By>;
  LengthMin?: Maybe<Order_By>;
  StressDistributorMaterialId?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
  StructureThicknessMax?: Maybe<Order_By>;
  StructureThicknessMin?: Maybe<Order_By>;
  Thickness?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type PreassembledFastening_Min_Fields = {
  __typename?: 'PreassembledFastening_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DeckDesignThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  FastenerCoatingId?: Maybe<Scalars['Int']>;
  FastenerDiameter?: Maybe<Scalars['MeasurementValue']>;
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
  FastenerMaterialId?: Maybe<Scalars['Int']>;
  FastenerTypeId?: Maybe<Scalars['Int']>;
  HoleSpacing?: Maybe<Scalars['MeasurementValue']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  LengthMax?: Maybe<Scalars['MeasurementValue']>;
  LengthMin?: Maybe<Scalars['MeasurementValue']>;
  StressDistributorMaterialId?: Maybe<Scalars['Int']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
  StressPlateShapeId?: Maybe<Scalars['Int']>;
  StructureThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  StructureThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Thickness?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "PreassembledFastening" */
export type PreassembledFastening_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DeckDesignThicknessMax?: Maybe<Order_By>;
  Diameter?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDiameter?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerMaterialId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  HoleSpacing?: Maybe<Order_By>;
  Length?: Maybe<Order_By>;
  LengthMax?: Maybe<Order_By>;
  LengthMin?: Maybe<Order_By>;
  StressDistributorMaterialId?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
  StructureThicknessMax?: Maybe<Order_By>;
  StructureThicknessMin?: Maybe<Order_By>;
  Thickness?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** response of any mutation on the table "PreassembledFastening" */
export type PreassembledFastening_Mutation_Response = {
  __typename?: 'PreassembledFastening_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PreassembledFastening>;
};

/** Ordering options when selecting data from "PreassembledFastening". */
export type PreassembledFastening_Order_By = {
  C_FastenerCoating?: Maybe<C_FastenerCoating_Order_By>;
  C_FastenerDriveHead?: Maybe<C_FastenerDriveHead_Order_By>;
  C_FastenerType?: Maybe<C_FastenerType_Order_By>;
  C_SecurementMaterial?: Maybe<C_SecurementMaterial_Order_By>;
  C_StressDistributorType?: Maybe<C_StressDistributorType_Order_By>;
  C_StressPlateShape?: Maybe<C_StressPlateShape_Order_By>;
  ComponentId?: Maybe<Order_By>;
  DeckDesignThicknessMax?: Maybe<Order_By>;
  Diameter?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDiameter?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerMaterialId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  HoleSpacing?: Maybe<Order_By>;
  IsSecuredToConcreteDeck?: Maybe<Order_By>;
  IsSteelDeckFastener?: Maybe<Order_By>;
  Length?: Maybe<Order_By>;
  LengthMax?: Maybe<Order_By>;
  LengthMin?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  StressDistributorMaterialId?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
  StructureThicknessMax?: Maybe<Order_By>;
  StructureThicknessMin?: Maybe<Order_By>;
  Thickness?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  cSecurementmaterialByFastenermaterialid?: Maybe<C_SecurementMaterial_Order_By>;
};

/** primary key columns input for table: PreassembledFastening */
export type PreassembledFastening_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "PreassembledFastening" */
export enum PreassembledFastening_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DeckDesignThicknessMax = 'DeckDesignThicknessMax',
  /** column name */
  Diameter = 'Diameter',
  /** column name */
  FastenerCoatingId = 'FastenerCoatingId',
  /** column name */
  FastenerDiameter = 'FastenerDiameter',
  /** column name */
  FastenerDriveHeadId = 'FastenerDriveHeadId',
  /** column name */
  FastenerMaterialId = 'FastenerMaterialId',
  /** column name */
  FastenerTypeId = 'FastenerTypeId',
  /** column name */
  HoleSpacing = 'HoleSpacing',
  /** column name */
  IsSecuredToConcreteDeck = 'IsSecuredToConcreteDeck',
  /** column name */
  IsSteelDeckFastener = 'IsSteelDeckFastener',
  /** column name */
  Length = 'Length',
  /** column name */
  LengthMax = 'LengthMax',
  /** column name */
  LengthMin = 'LengthMin',
  /** column name */
  StressDistributorMaterialId = 'StressDistributorMaterialId',
  /** column name */
  StressDistributorTypeId = 'StressDistributorTypeId',
  /** column name */
  StressPlateShapeId = 'StressPlateShapeId',
  /** column name */
  StructureThicknessMax = 'StructureThicknessMax',
  /** column name */
  StructureThicknessMin = 'StructureThicknessMin',
  /** column name */
  Thickness = 'Thickness',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  Width = 'Width'
}

/** input type for updating data in table "PreassembledFastening" */
export type PreassembledFastening_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DeckDesignThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  FastenerCoatingId?: Maybe<Scalars['Int']>;
  FastenerDiameter?: Maybe<Scalars['MeasurementValue']>;
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
  FastenerMaterialId?: Maybe<Scalars['Int']>;
  FastenerTypeId?: Maybe<Scalars['Int']>;
  HoleSpacing?: Maybe<Scalars['MeasurementValue']>;
  IsSecuredToConcreteDeck?: Maybe<Scalars['Boolean']>;
  IsSteelDeckFastener?: Maybe<Scalars['Boolean']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  LengthMax?: Maybe<Scalars['MeasurementValue']>;
  LengthMin?: Maybe<Scalars['MeasurementValue']>;
  StressDistributorMaterialId?: Maybe<Scalars['Int']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
  StressPlateShapeId?: Maybe<Scalars['Int']>;
  StructureThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  StructureThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Thickness?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type PreassembledFastening_Stddev_Fields = {
  __typename?: 'PreassembledFastening_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FastenerCoatingId?: Maybe<Scalars['Float']>;
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
  FastenerMaterialId?: Maybe<Scalars['Float']>;
  FastenerTypeId?: Maybe<Scalars['Float']>;
  StressDistributorMaterialId?: Maybe<Scalars['Float']>;
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "PreassembledFastening" */
export type PreassembledFastening_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerMaterialId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  StressDistributorMaterialId?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PreassembledFastening_Stddev_Pop_Fields = {
  __typename?: 'PreassembledFastening_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FastenerCoatingId?: Maybe<Scalars['Float']>;
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
  FastenerMaterialId?: Maybe<Scalars['Float']>;
  FastenerTypeId?: Maybe<Scalars['Float']>;
  StressDistributorMaterialId?: Maybe<Scalars['Float']>;
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "PreassembledFastening" */
export type PreassembledFastening_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerMaterialId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  StressDistributorMaterialId?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PreassembledFastening_Stddev_Samp_Fields = {
  __typename?: 'PreassembledFastening_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FastenerCoatingId?: Maybe<Scalars['Float']>;
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
  FastenerMaterialId?: Maybe<Scalars['Float']>;
  FastenerTypeId?: Maybe<Scalars['Float']>;
  StressDistributorMaterialId?: Maybe<Scalars['Float']>;
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "PreassembledFastening" */
export type PreassembledFastening_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerMaterialId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  StressDistributorMaterialId?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type PreassembledFastening_Sum_Fields = {
  __typename?: 'PreassembledFastening_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FastenerCoatingId?: Maybe<Scalars['Int']>;
  FastenerDriveHeadId?: Maybe<Scalars['Int']>;
  FastenerMaterialId?: Maybe<Scalars['Int']>;
  FastenerTypeId?: Maybe<Scalars['Int']>;
  StressDistributorMaterialId?: Maybe<Scalars['Int']>;
  StressDistributorTypeId?: Maybe<Scalars['Int']>;
  StressPlateShapeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "PreassembledFastening" */
export type PreassembledFastening_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerMaterialId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  StressDistributorMaterialId?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** update columns of table "PreassembledFastening" */
export enum PreassembledFastening_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DeckDesignThicknessMax = 'DeckDesignThicknessMax',
  /** column name */
  Diameter = 'Diameter',
  /** column name */
  FastenerCoatingId = 'FastenerCoatingId',
  /** column name */
  FastenerDiameter = 'FastenerDiameter',
  /** column name */
  FastenerDriveHeadId = 'FastenerDriveHeadId',
  /** column name */
  FastenerMaterialId = 'FastenerMaterialId',
  /** column name */
  FastenerTypeId = 'FastenerTypeId',
  /** column name */
  HoleSpacing = 'HoleSpacing',
  /** column name */
  IsSecuredToConcreteDeck = 'IsSecuredToConcreteDeck',
  /** column name */
  IsSteelDeckFastener = 'IsSteelDeckFastener',
  /** column name */
  Length = 'Length',
  /** column name */
  LengthMax = 'LengthMax',
  /** column name */
  LengthMin = 'LengthMin',
  /** column name */
  StressDistributorMaterialId = 'StressDistributorMaterialId',
  /** column name */
  StressDistributorTypeId = 'StressDistributorTypeId',
  /** column name */
  StressPlateShapeId = 'StressPlateShapeId',
  /** column name */
  StructureThicknessMax = 'StructureThicknessMax',
  /** column name */
  StructureThicknessMin = 'StructureThicknessMin',
  /** column name */
  Thickness = 'Thickness',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  Width = 'Width'
}

/** aggregate var_pop on columns */
export type PreassembledFastening_Var_Pop_Fields = {
  __typename?: 'PreassembledFastening_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FastenerCoatingId?: Maybe<Scalars['Float']>;
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
  FastenerMaterialId?: Maybe<Scalars['Float']>;
  FastenerTypeId?: Maybe<Scalars['Float']>;
  StressDistributorMaterialId?: Maybe<Scalars['Float']>;
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "PreassembledFastening" */
export type PreassembledFastening_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerMaterialId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  StressDistributorMaterialId?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PreassembledFastening_Var_Samp_Fields = {
  __typename?: 'PreassembledFastening_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FastenerCoatingId?: Maybe<Scalars['Float']>;
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
  FastenerMaterialId?: Maybe<Scalars['Float']>;
  FastenerTypeId?: Maybe<Scalars['Float']>;
  StressDistributorMaterialId?: Maybe<Scalars['Float']>;
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "PreassembledFastening" */
export type PreassembledFastening_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerMaterialId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  StressDistributorMaterialId?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type PreassembledFastening_Variance_Fields = {
  __typename?: 'PreassembledFastening_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FastenerCoatingId?: Maybe<Scalars['Float']>;
  FastenerDriveHeadId?: Maybe<Scalars['Float']>;
  FastenerMaterialId?: Maybe<Scalars['Float']>;
  FastenerTypeId?: Maybe<Scalars['Float']>;
  StressDistributorMaterialId?: Maybe<Scalars['Float']>;
  StressDistributorTypeId?: Maybe<Scalars['Float']>;
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "PreassembledFastening" */
export type PreassembledFastening_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FastenerCoatingId?: Maybe<Order_By>;
  FastenerDriveHeadId?: Maybe<Order_By>;
  FastenerMaterialId?: Maybe<Order_By>;
  FastenerTypeId?: Maybe<Order_By>;
  StressDistributorMaterialId?: Maybe<Order_By>;
  StressDistributorTypeId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** columns and relationships of "PrivateLabel" */
export type PrivateLabel = {
  __typename?: 'PrivateLabel';
  ChildComponentId: Scalars['Int'];
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  /** An object relationship */
  manufacturedProductByChildcomponentid: ManufacturedProduct;
};

/** aggregated selection of "PrivateLabel" */
export type PrivateLabel_Aggregate = {
  __typename?: 'PrivateLabel_aggregate';
  aggregate?: Maybe<PrivateLabel_Aggregate_Fields>;
  nodes: Array<PrivateLabel>;
};

/** aggregate fields of "PrivateLabel" */
export type PrivateLabel_Aggregate_Fields = {
  __typename?: 'PrivateLabel_aggregate_fields';
  avg?: Maybe<PrivateLabel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PrivateLabel_Max_Fields>;
  min?: Maybe<PrivateLabel_Min_Fields>;
  stddev?: Maybe<PrivateLabel_Stddev_Fields>;
  stddev_pop?: Maybe<PrivateLabel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PrivateLabel_Stddev_Samp_Fields>;
  sum?: Maybe<PrivateLabel_Sum_Fields>;
  var_pop?: Maybe<PrivateLabel_Var_Pop_Fields>;
  var_samp?: Maybe<PrivateLabel_Var_Samp_Fields>;
  variance?: Maybe<PrivateLabel_Variance_Fields>;
};


/** aggregate fields of "PrivateLabel" */
export type PrivateLabel_Aggregate_FieldsCountArgs = {
  column?: Maybe<PrivateLabel_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PrivateLabel" */
export type PrivateLabel_Aggregate_Order_By = {
  avg?: Maybe<PrivateLabel_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<PrivateLabel_Max_Order_By>;
  min?: Maybe<PrivateLabel_Min_Order_By>;
  stddev?: Maybe<PrivateLabel_Stddev_Order_By>;
  stddev_pop?: Maybe<PrivateLabel_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<PrivateLabel_Stddev_Samp_Order_By>;
  sum?: Maybe<PrivateLabel_Sum_Order_By>;
  var_pop?: Maybe<PrivateLabel_Var_Pop_Order_By>;
  var_samp?: Maybe<PrivateLabel_Var_Samp_Order_By>;
  variance?: Maybe<PrivateLabel_Variance_Order_By>;
};

/** aggregate avg on columns */
export type PrivateLabel_Avg_Fields = {
  __typename?: 'PrivateLabel_avg_fields';
  ChildComponentId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "PrivateLabel" */
export type PrivateLabel_Avg_Order_By = {
  ChildComponentId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PrivateLabel". All fields are combined with a logical 'AND'. */
export type PrivateLabel_Bool_Exp = {
  ChildComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  _and?: Maybe<Array<PrivateLabel_Bool_Exp>>;
  _not?: Maybe<PrivateLabel_Bool_Exp>;
  _or?: Maybe<Array<PrivateLabel_Bool_Exp>>;
  manufacturedProductByChildcomponentid?: Maybe<ManufacturedProduct_Bool_Exp>;
};

/** upsert condition type for table "PrivateLabel" */
export type PrivateLabel_If_Matched = {
  match_columns?: Array<PrivateLabel_Insert_Match_Column>;
  update_columns?: Array<PrivateLabel_Update_Column>;
  where?: Maybe<PrivateLabel_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "PrivateLabel" */
export type PrivateLabel_Inc_Input = {
  ChildComponentId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "PrivateLabel" */
export type PrivateLabel_Insert_Input = {
  ChildComponentId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "PrivateLabel" */
export enum PrivateLabel_Insert_Match_Column {
  /** column name */
  ChildComponentId = 'ChildComponentId',
  /** column name */
  ComponentId = 'ComponentId'
}

/** aggregate max on columns */
export type PrivateLabel_Max_Fields = {
  __typename?: 'PrivateLabel_max_fields';
  ChildComponentId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "PrivateLabel" */
export type PrivateLabel_Max_Order_By = {
  ChildComponentId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type PrivateLabel_Min_Fields = {
  __typename?: 'PrivateLabel_min_fields';
  ChildComponentId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "PrivateLabel" */
export type PrivateLabel_Min_Order_By = {
  ChildComponentId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "PrivateLabel" */
export type PrivateLabel_Mutation_Response = {
  __typename?: 'PrivateLabel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PrivateLabel>;
};

/** Ordering options when selecting data from "PrivateLabel". */
export type PrivateLabel_Order_By = {
  ChildComponentId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  manufacturedProductByChildcomponentid?: Maybe<ManufacturedProduct_Order_By>;
};

/** primary key columns input for table: PrivateLabel */
export type PrivateLabel_Pk_Columns_Input = {
  ChildComponentId: Scalars['Int'];
  ComponentId: Scalars['Int'];
};

/** select columns of table "PrivateLabel" */
export enum PrivateLabel_Select_Column {
  /** column name */
  ChildComponentId = 'ChildComponentId',
  /** column name */
  ComponentId = 'ComponentId'
}

/** input type for updating data in table "PrivateLabel" */
export type PrivateLabel_Set_Input = {
  ChildComponentId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type PrivateLabel_Stddev_Fields = {
  __typename?: 'PrivateLabel_stddev_fields';
  ChildComponentId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "PrivateLabel" */
export type PrivateLabel_Stddev_Order_By = {
  ChildComponentId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PrivateLabel_Stddev_Pop_Fields = {
  __typename?: 'PrivateLabel_stddev_pop_fields';
  ChildComponentId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "PrivateLabel" */
export type PrivateLabel_Stddev_Pop_Order_By = {
  ChildComponentId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PrivateLabel_Stddev_Samp_Fields = {
  __typename?: 'PrivateLabel_stddev_samp_fields';
  ChildComponentId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "PrivateLabel" */
export type PrivateLabel_Stddev_Samp_Order_By = {
  ChildComponentId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type PrivateLabel_Sum_Fields = {
  __typename?: 'PrivateLabel_sum_fields';
  ChildComponentId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "PrivateLabel" */
export type PrivateLabel_Sum_Order_By = {
  ChildComponentId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** update columns of table "PrivateLabel" */
export enum PrivateLabel_Update_Column {
  /** column name */
  ChildComponentId = 'ChildComponentId',
  /** column name */
  ComponentId = 'ComponentId'
}

/** aggregate var_pop on columns */
export type PrivateLabel_Var_Pop_Fields = {
  __typename?: 'PrivateLabel_var_pop_fields';
  ChildComponentId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "PrivateLabel" */
export type PrivateLabel_Var_Pop_Order_By = {
  ChildComponentId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PrivateLabel_Var_Samp_Fields = {
  __typename?: 'PrivateLabel_var_samp_fields';
  ChildComponentId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "PrivateLabel" */
export type PrivateLabel_Var_Samp_Order_By = {
  ChildComponentId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type PrivateLabel_Variance_Fields = {
  __typename?: 'PrivateLabel_variance_fields';
  ChildComponentId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "PrivateLabel" */
export type PrivateLabel_Variance_Order_By = {
  ChildComponentId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "PrivateLabeler" */
export type PrivateLabeler = {
  __typename?: 'PrivateLabeler';
  Name: Scalars['String'];
  WIPNumber: Scalars['String'];
};

/** aggregated selection of "PrivateLabeler" */
export type PrivateLabeler_Aggregate = {
  __typename?: 'PrivateLabeler_aggregate';
  aggregate?: Maybe<PrivateLabeler_Aggregate_Fields>;
  nodes: Array<PrivateLabeler>;
};

/** aggregate fields of "PrivateLabeler" */
export type PrivateLabeler_Aggregate_Fields = {
  __typename?: 'PrivateLabeler_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PrivateLabeler_Max_Fields>;
  min?: Maybe<PrivateLabeler_Min_Fields>;
};


/** aggregate fields of "PrivateLabeler" */
export type PrivateLabeler_Aggregate_FieldsCountArgs = {
  column?: Maybe<PrivateLabeler_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "PrivateLabeler". All fields are combined with a logical 'AND'. */
export type PrivateLabeler_Bool_Exp = {
  Name?: Maybe<String_Mssql_Comparison_Exp>;
  WIPNumber?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<PrivateLabeler_Bool_Exp>>;
  _not?: Maybe<PrivateLabeler_Bool_Exp>;
  _or?: Maybe<Array<PrivateLabeler_Bool_Exp>>;
};

/** upsert condition type for table "PrivateLabeler" */
export type PrivateLabeler_If_Matched = {
  match_columns?: Array<PrivateLabeler_Insert_Match_Column>;
  update_columns?: Array<PrivateLabeler_Update_Column>;
  where?: Maybe<PrivateLabeler_Bool_Exp>;
};

/** input type for inserting data into table "PrivateLabeler" */
export type PrivateLabeler_Insert_Input = {
  Name?: Maybe<Scalars['String']>;
  WIPNumber?: Maybe<Scalars['String']>;
};

/** select match_columns of table "PrivateLabeler" */
export enum PrivateLabeler_Insert_Match_Column {
  /** column name */
  Name = 'Name',
  /** column name */
  WipNumber = 'WIPNumber'
}

/** aggregate max on columns */
export type PrivateLabeler_Max_Fields = {
  __typename?: 'PrivateLabeler_max_fields';
  Name?: Maybe<Scalars['String']>;
  WIPNumber?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PrivateLabeler_Min_Fields = {
  __typename?: 'PrivateLabeler_min_fields';
  Name?: Maybe<Scalars['String']>;
  WIPNumber?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "PrivateLabeler" */
export type PrivateLabeler_Mutation_Response = {
  __typename?: 'PrivateLabeler_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PrivateLabeler>;
};

/** Ordering options when selecting data from "PrivateLabeler". */
export type PrivateLabeler_Order_By = {
  Name?: Maybe<Order_By>;
  WIPNumber?: Maybe<Order_By>;
};

/** select columns of table "PrivateLabeler" */
export enum PrivateLabeler_Select_Column {
  /** column name */
  Name = 'Name',
  /** column name */
  WipNumber = 'WIPNumber'
}

/** input type for updating data in table "PrivateLabeler" */
export type PrivateLabeler_Set_Input = {
  Name?: Maybe<Scalars['String']>;
  WIPNumber?: Maybe<Scalars['String']>;
};

/** update columns of table "PrivateLabeler" */
export enum PrivateLabeler_Update_Column {
  /** column name */
  Name = 'Name',
  /** column name */
  WipNumber = 'WIPNumber'
}

export type ProducMatchAssemblyCountCriteria = {
  componentId: Scalars['Int'];
};

/** columns and relationships of "ProductDependencies" */
export type ProductDependencies = {
  __typename?: 'ProductDependencies';
  Comments: Scalars['ItemComment'];
  Component1Id: Scalars['Int'];
  Component2Id: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  /** An object relationship */
  manufacturedProductByComponent2id: ManufacturedProduct;
};

/** aggregated selection of "ProductDependencies" */
export type ProductDependencies_Aggregate = {
  __typename?: 'ProductDependencies_aggregate';
  aggregate?: Maybe<ProductDependencies_Aggregate_Fields>;
  nodes: Array<ProductDependencies>;
};

/** aggregate fields of "ProductDependencies" */
export type ProductDependencies_Aggregate_Fields = {
  __typename?: 'ProductDependencies_aggregate_fields';
  avg?: Maybe<ProductDependencies_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductDependencies_Max_Fields>;
  min?: Maybe<ProductDependencies_Min_Fields>;
  stddev?: Maybe<ProductDependencies_Stddev_Fields>;
  stddev_pop?: Maybe<ProductDependencies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductDependencies_Stddev_Samp_Fields>;
  sum?: Maybe<ProductDependencies_Sum_Fields>;
  var_pop?: Maybe<ProductDependencies_Var_Pop_Fields>;
  var_samp?: Maybe<ProductDependencies_Var_Samp_Fields>;
  variance?: Maybe<ProductDependencies_Variance_Fields>;
};


/** aggregate fields of "ProductDependencies" */
export type ProductDependencies_Aggregate_FieldsCountArgs = {
  column?: Maybe<ProductDependencies_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ProductDependencies" */
export type ProductDependencies_Aggregate_Order_By = {
  avg?: Maybe<ProductDependencies_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ProductDependencies_Max_Order_By>;
  min?: Maybe<ProductDependencies_Min_Order_By>;
  stddev?: Maybe<ProductDependencies_Stddev_Order_By>;
  stddev_pop?: Maybe<ProductDependencies_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ProductDependencies_Stddev_Samp_Order_By>;
  sum?: Maybe<ProductDependencies_Sum_Order_By>;
  var_pop?: Maybe<ProductDependencies_Var_Pop_Order_By>;
  var_samp?: Maybe<ProductDependencies_Var_Samp_Order_By>;
  variance?: Maybe<ProductDependencies_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ProductDependencies_Avg_Fields = {
  __typename?: 'ProductDependencies_avg_fields';
  Component1Id?: Maybe<Scalars['Float']>;
  Component2Id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ProductDependencies" */
export type ProductDependencies_Avg_Order_By = {
  Component1Id?: Maybe<Order_By>;
  Component2Id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ProductDependencies". All fields are combined with a logical 'AND'. */
export type ProductDependencies_Bool_Exp = {
  Comments?: Maybe<ItemComment_Mssql_Comparison_Exp>;
  Component1Id?: Maybe<Int_Mssql_Comparison_Exp>;
  Component2Id?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  _and?: Maybe<Array<ProductDependencies_Bool_Exp>>;
  _not?: Maybe<ProductDependencies_Bool_Exp>;
  _or?: Maybe<Array<ProductDependencies_Bool_Exp>>;
  manufacturedProductByComponent2id?: Maybe<ManufacturedProduct_Bool_Exp>;
};

/** upsert condition type for table "ProductDependencies" */
export type ProductDependencies_If_Matched = {
  match_columns?: Array<ProductDependencies_Insert_Match_Column>;
  update_columns?: Array<ProductDependencies_Update_Column>;
  where?: Maybe<ProductDependencies_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ProductDependencies" */
export type ProductDependencies_Inc_Input = {
  Component1Id?: Maybe<Scalars['Int']>;
  Component2Id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ProductDependencies" */
export type ProductDependencies_Insert_Input = {
  Comments?: Maybe<Scalars['ItemComment']>;
  Component1Id?: Maybe<Scalars['Int']>;
  Component2Id?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ProductDependencies" */
export enum ProductDependencies_Insert_Match_Column {
  /** column name */
  Comments = 'Comments',
  /** column name */
  Component1Id = 'Component1Id',
  /** column name */
  Component2Id = 'Component2Id'
}

/** aggregate max on columns */
export type ProductDependencies_Max_Fields = {
  __typename?: 'ProductDependencies_max_fields';
  Comments?: Maybe<Scalars['ItemComment']>;
  Component1Id?: Maybe<Scalars['Int']>;
  Component2Id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ProductDependencies" */
export type ProductDependencies_Max_Order_By = {
  Comments?: Maybe<Order_By>;
  Component1Id?: Maybe<Order_By>;
  Component2Id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ProductDependencies_Min_Fields = {
  __typename?: 'ProductDependencies_min_fields';
  Comments?: Maybe<Scalars['ItemComment']>;
  Component1Id?: Maybe<Scalars['Int']>;
  Component2Id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ProductDependencies" */
export type ProductDependencies_Min_Order_By = {
  Comments?: Maybe<Order_By>;
  Component1Id?: Maybe<Order_By>;
  Component2Id?: Maybe<Order_By>;
};

/** response of any mutation on the table "ProductDependencies" */
export type ProductDependencies_Mutation_Response = {
  __typename?: 'ProductDependencies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductDependencies>;
};

/** Ordering options when selecting data from "ProductDependencies". */
export type ProductDependencies_Order_By = {
  Comments?: Maybe<Order_By>;
  Component1Id?: Maybe<Order_By>;
  Component2Id?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  manufacturedProductByComponent2id?: Maybe<ManufacturedProduct_Order_By>;
};

/** primary key columns input for table: ProductDependencies */
export type ProductDependencies_Pk_Columns_Input = {
  Component1Id: Scalars['Int'];
  Component2Id: Scalars['Int'];
};

/** select columns of table "ProductDependencies" */
export enum ProductDependencies_Select_Column {
  /** column name */
  Comments = 'Comments',
  /** column name */
  Component1Id = 'Component1Id',
  /** column name */
  Component2Id = 'Component2Id'
}

/** input type for updating data in table "ProductDependencies" */
export type ProductDependencies_Set_Input = {
  Comments?: Maybe<Scalars['ItemComment']>;
  Component1Id?: Maybe<Scalars['Int']>;
  Component2Id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProductDependencies_Stddev_Fields = {
  __typename?: 'ProductDependencies_stddev_fields';
  Component1Id?: Maybe<Scalars['Float']>;
  Component2Id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ProductDependencies" */
export type ProductDependencies_Stddev_Order_By = {
  Component1Id?: Maybe<Order_By>;
  Component2Id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ProductDependencies_Stddev_Pop_Fields = {
  __typename?: 'ProductDependencies_stddev_pop_fields';
  Component1Id?: Maybe<Scalars['Float']>;
  Component2Id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ProductDependencies" */
export type ProductDependencies_Stddev_Pop_Order_By = {
  Component1Id?: Maybe<Order_By>;
  Component2Id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ProductDependencies_Stddev_Samp_Fields = {
  __typename?: 'ProductDependencies_stddev_samp_fields';
  Component1Id?: Maybe<Scalars['Float']>;
  Component2Id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ProductDependencies" */
export type ProductDependencies_Stddev_Samp_Order_By = {
  Component1Id?: Maybe<Order_By>;
  Component2Id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ProductDependencies_Sum_Fields = {
  __typename?: 'ProductDependencies_sum_fields';
  Component1Id?: Maybe<Scalars['Int']>;
  Component2Id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ProductDependencies" */
export type ProductDependencies_Sum_Order_By = {
  Component1Id?: Maybe<Order_By>;
  Component2Id?: Maybe<Order_By>;
};

/** update columns of table "ProductDependencies" */
export enum ProductDependencies_Update_Column {
  /** column name */
  Comments = 'Comments',
  /** column name */
  Component1Id = 'Component1Id',
  /** column name */
  Component2Id = 'Component2Id'
}

/** aggregate var_pop on columns */
export type ProductDependencies_Var_Pop_Fields = {
  __typename?: 'ProductDependencies_var_pop_fields';
  Component1Id?: Maybe<Scalars['Float']>;
  Component2Id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ProductDependencies" */
export type ProductDependencies_Var_Pop_Order_By = {
  Component1Id?: Maybe<Order_By>;
  Component2Id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ProductDependencies_Var_Samp_Fields = {
  __typename?: 'ProductDependencies_var_samp_fields';
  Component1Id?: Maybe<Scalars['Float']>;
  Component2Id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ProductDependencies" */
export type ProductDependencies_Var_Samp_Order_By = {
  Component1Id?: Maybe<Order_By>;
  Component2Id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ProductDependencies_Variance_Fields = {
  __typename?: 'ProductDependencies_variance_fields';
  Component1Id?: Maybe<Scalars['Float']>;
  Component2Id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ProductDependencies" */
export type ProductDependencies_Variance_Order_By = {
  Component1Id?: Maybe<Order_By>;
  Component2Id?: Maybe<Order_By>;
};

export type ProductListItem = {
  __typename?: 'ProductListItem';
  ApprovedUseCode?: Maybe<Scalars['String']>;
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CountryCode?: Maybe<Scalars['String']>;
  CountryId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

export type ProductMatchAssemblyListCriteria = {
  approvedUseId?: Maybe<Scalars['Int']>;
  componentId: Scalars['Int'];
  pageNumber: Scalars['Int'];
  rowsPerPage: Scalars['Int'];
  sortColumn: Scalars['String'];
  sortDirection: Scalars['Int'];
};

export type ProductMatchedAssemblyCounting = {
  __typename?: 'ProductMatchedAssemblyCounting';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  NumMatchedAssembly?: Maybe<Scalars['Int']>;
};

export type ProductSearchCriteria = {
  approvedUseId?: Maybe<Scalars['Int']>;
  attributes?: Maybe<Array<Maybe<AttributeSearchCriteria>>>;
  categoryId?: Maybe<Scalars['Int']>;
  componentId?: Maybe<Scalars['Int']>;
  countryId?: Maybe<Scalars['Int']>;
  manufacturerId?: Maybe<Scalars['Int']>;
  subCategoryId?: Maybe<Scalars['Int']>;
};

export type ProductSearchCriteriaWithTarget = {
  iniSearchCriteria?: Maybe<ProductSearchCriteria>;
  pageNumber: Scalars['Int'];
  rowsPerPage: Scalars['Int'];
  sortColumn: Scalars['String'];
  sortDirection: Scalars['Int'];
  targetApprovedUseId?: Maybe<Scalars['Int']>;
  targetCategoryId?: Maybe<Scalars['Int']>;
  targetSubCategoryId?: Maybe<Scalars['Int']>;
};

export type ProductSearchResultCounting = {
  __typename?: 'ProductSearchResultCounting';
  ApprovedUseCode: Scalars['String'];
  ApprovedUseId: Scalars['Int'];
  CategoryId: Scalars['Int'];
  CategoryName: Scalars['String'];
  NumOfProducts: Scalars['Int'];
  SubcategoryId: Scalars['Int'];
  SubcategoryName: Scalars['String'];
};

/** columns and relationships of "ProductsBySubcategory" */
export type ProductsBySubcategory = {
  __typename?: 'ProductsBySubcategory';
  CategoryName: Scalars['String'];
  ComponentId: Scalars['Int'];
  LockStatusId: Scalars['Int'];
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerName: Scalars['String'];
  StatusId: Scalars['Int'];
  SubcategoryId: Scalars['Int'];
  SubcategoryName: Scalars['String'];
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ProductsBySubcategory" */
export type ProductsBySubcategory_Aggregate = {
  __typename?: 'ProductsBySubcategory_aggregate';
  aggregate?: Maybe<ProductsBySubcategory_Aggregate_Fields>;
  nodes: Array<ProductsBySubcategory>;
};

/** aggregate fields of "ProductsBySubcategory" */
export type ProductsBySubcategory_Aggregate_Fields = {
  __typename?: 'ProductsBySubcategory_aggregate_fields';
  avg?: Maybe<ProductsBySubcategory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductsBySubcategory_Max_Fields>;
  min?: Maybe<ProductsBySubcategory_Min_Fields>;
  stddev?: Maybe<ProductsBySubcategory_Stddev_Fields>;
  stddev_pop?: Maybe<ProductsBySubcategory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductsBySubcategory_Stddev_Samp_Fields>;
  sum?: Maybe<ProductsBySubcategory_Sum_Fields>;
  var_pop?: Maybe<ProductsBySubcategory_Var_Pop_Fields>;
  var_samp?: Maybe<ProductsBySubcategory_Var_Samp_Fields>;
  variance?: Maybe<ProductsBySubcategory_Variance_Fields>;
};


/** aggregate fields of "ProductsBySubcategory" */
export type ProductsBySubcategory_Aggregate_FieldsCountArgs = {
  column?: Maybe<ProductsBySubcategory_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProductsBySubcategory_Avg_Fields = {
  __typename?: 'ProductsBySubcategory_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ProductsBySubcategory". All fields are combined with a logical 'AND'. */
export type ProductsBySubcategory_Bool_Exp = {
  CategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerName?: Maybe<String_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubcategoryId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubcategoryName?: Maybe<String_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ProductsBySubcategory_Bool_Exp>>;
  _not?: Maybe<ProductsBySubcategory_Bool_Exp>;
  _or?: Maybe<Array<ProductsBySubcategory_Bool_Exp>>;
};

/** upsert condition type for table "ProductsBySubcategory" */
export type ProductsBySubcategory_If_Matched = {
  match_columns?: Array<ProductsBySubcategory_Insert_Match_Column>;
  update_columns?: Array<ProductsBySubcategory_Update_Column>;
  where?: Maybe<ProductsBySubcategory_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ProductsBySubcategory" */
export type ProductsBySubcategory_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ProductsBySubcategory" */
export type ProductsBySubcategory_Insert_Input = {
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "ProductsBySubcategory" */
export enum ProductsBySubcategory_Insert_Match_Column {
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryId = 'SubcategoryId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate max on columns */
export type ProductsBySubcategory_Max_Fields = {
  __typename?: 'ProductsBySubcategory_max_fields';
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProductsBySubcategory_Min_Fields = {
  __typename?: 'ProductsBySubcategory_min_fields';
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ProductsBySubcategory" */
export type ProductsBySubcategory_Mutation_Response = {
  __typename?: 'ProductsBySubcategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductsBySubcategory>;
};

/** Ordering options when selecting data from "ProductsBySubcategory". */
export type ProductsBySubcategory_Order_By = {
  CategoryName?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ManufacturerName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
  SubcategoryName?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
};

/** select columns of table "ProductsBySubcategory" */
export enum ProductsBySubcategory_Select_Column {
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryId = 'SubcategoryId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** input type for updating data in table "ProductsBySubcategory" */
export type ProductsBySubcategory_Set_Input = {
  CategoryName?: Maybe<Scalars['String']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ManufacturerName?: Maybe<Scalars['String']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
  SubcategoryName?: Maybe<Scalars['String']>;
  TradeName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProductsBySubcategory_Stddev_Fields = {
  __typename?: 'ProductsBySubcategory_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProductsBySubcategory_Stddev_Pop_Fields = {
  __typename?: 'ProductsBySubcategory_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProductsBySubcategory_Stddev_Samp_Fields = {
  __typename?: 'ProductsBySubcategory_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ProductsBySubcategory_Sum_Fields = {
  __typename?: 'ProductsBySubcategory_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
};

/** update columns of table "ProductsBySubcategory" */
export enum ProductsBySubcategory_Update_Column {
  /** column name */
  CategoryName = 'CategoryName',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ManufacturerName = 'ManufacturerName',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryId = 'SubcategoryId',
  /** column name */
  SubcategoryName = 'SubcategoryName',
  /** column name */
  TradeName = 'TradeName'
}

/** aggregate var_pop on columns */
export type ProductsBySubcategory_Var_Pop_Fields = {
  __typename?: 'ProductsBySubcategory_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProductsBySubcategory_Var_Samp_Fields = {
  __typename?: 'ProductsBySubcategory_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProductsBySubcategory_Variance_Fields = {
  __typename?: 'ProductsBySubcategory_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth = {
  __typename?: 'R_AdhesiveTapeWidth';
  ComponentId: Scalars['Int'];
  /** An object relationship */
  TapeWidth: TapeWidth;
  TapeWidthId: Scalars['Int'];
};

/** aggregated selection of "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Aggregate = {
  __typename?: 'R_AdhesiveTapeWidth_aggregate';
  aggregate?: Maybe<R_AdhesiveTapeWidth_Aggregate_Fields>;
  nodes: Array<R_AdhesiveTapeWidth>;
};

/** aggregate fields of "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Aggregate_Fields = {
  __typename?: 'R_AdhesiveTapeWidth_aggregate_fields';
  avg?: Maybe<R_AdhesiveTapeWidth_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_AdhesiveTapeWidth_Max_Fields>;
  min?: Maybe<R_AdhesiveTapeWidth_Min_Fields>;
  stddev?: Maybe<R_AdhesiveTapeWidth_Stddev_Fields>;
  stddev_pop?: Maybe<R_AdhesiveTapeWidth_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_AdhesiveTapeWidth_Stddev_Samp_Fields>;
  sum?: Maybe<R_AdhesiveTapeWidth_Sum_Fields>;
  var_pop?: Maybe<R_AdhesiveTapeWidth_Var_Pop_Fields>;
  var_samp?: Maybe<R_AdhesiveTapeWidth_Var_Samp_Fields>;
  variance?: Maybe<R_AdhesiveTapeWidth_Variance_Fields>;
};


/** aggregate fields of "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_AdhesiveTapeWidth_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Aggregate_Order_By = {
  avg?: Maybe<R_AdhesiveTapeWidth_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_AdhesiveTapeWidth_Max_Order_By>;
  min?: Maybe<R_AdhesiveTapeWidth_Min_Order_By>;
  stddev?: Maybe<R_AdhesiveTapeWidth_Stddev_Order_By>;
  stddev_pop?: Maybe<R_AdhesiveTapeWidth_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_AdhesiveTapeWidth_Stddev_Samp_Order_By>;
  sum?: Maybe<R_AdhesiveTapeWidth_Sum_Order_By>;
  var_pop?: Maybe<R_AdhesiveTapeWidth_Var_Pop_Order_By>;
  var_samp?: Maybe<R_AdhesiveTapeWidth_Var_Samp_Order_By>;
  variance?: Maybe<R_AdhesiveTapeWidth_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_AdhesiveTapeWidth_Avg_Fields = {
  __typename?: 'R_AdhesiveTapeWidth_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  TapeWidthId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  TapeWidthId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_AdhesiveTapeWidth". All fields are combined with a logical 'AND'. */
export type R_AdhesiveTapeWidth_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  TapeWidth?: Maybe<TapeWidth_Bool_Exp>;
  TapeWidthId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_AdhesiveTapeWidth_Bool_Exp>>;
  _not?: Maybe<R_AdhesiveTapeWidth_Bool_Exp>;
  _or?: Maybe<Array<R_AdhesiveTapeWidth_Bool_Exp>>;
};

/** upsert condition type for table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_If_Matched = {
  match_columns?: Array<R_AdhesiveTapeWidth_Insert_Match_Column>;
  update_columns?: Array<R_AdhesiveTapeWidth_Update_Column>;
  where?: Maybe<R_AdhesiveTapeWidth_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  TapeWidthId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  TapeWidthId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_AdhesiveTapeWidth" */
export enum R_AdhesiveTapeWidth_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  TapeWidthId = 'TapeWidthId'
}

/** aggregate max on columns */
export type R_AdhesiveTapeWidth_Max_Fields = {
  __typename?: 'R_AdhesiveTapeWidth_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  TapeWidthId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  TapeWidthId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_AdhesiveTapeWidth_Min_Fields = {
  __typename?: 'R_AdhesiveTapeWidth_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  TapeWidthId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  TapeWidthId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Mutation_Response = {
  __typename?: 'R_AdhesiveTapeWidth_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_AdhesiveTapeWidth>;
};

/** Ordering options when selecting data from "R_AdhesiveTapeWidth". */
export type R_AdhesiveTapeWidth_Order_By = {
  ComponentId?: Maybe<Order_By>;
  TapeWidth?: Maybe<TapeWidth_Order_By>;
  TapeWidthId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_AdhesiveTapeWidth */
export type R_AdhesiveTapeWidth_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  TapeWidthId: Scalars['Int'];
};

/** select columns of table "R_AdhesiveTapeWidth" */
export enum R_AdhesiveTapeWidth_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  TapeWidthId = 'TapeWidthId'
}

/** input type for updating data in table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  TapeWidthId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_AdhesiveTapeWidth_Stddev_Fields = {
  __typename?: 'R_AdhesiveTapeWidth_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  TapeWidthId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  TapeWidthId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_AdhesiveTapeWidth_Stddev_Pop_Fields = {
  __typename?: 'R_AdhesiveTapeWidth_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  TapeWidthId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  TapeWidthId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_AdhesiveTapeWidth_Stddev_Samp_Fields = {
  __typename?: 'R_AdhesiveTapeWidth_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  TapeWidthId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  TapeWidthId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_AdhesiveTapeWidth_Sum_Fields = {
  __typename?: 'R_AdhesiveTapeWidth_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  TapeWidthId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  TapeWidthId?: Maybe<Order_By>;
};

/** update columns of table "R_AdhesiveTapeWidth" */
export enum R_AdhesiveTapeWidth_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  TapeWidthId = 'TapeWidthId'
}

/** aggregate var_pop on columns */
export type R_AdhesiveTapeWidth_Var_Pop_Fields = {
  __typename?: 'R_AdhesiveTapeWidth_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  TapeWidthId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  TapeWidthId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_AdhesiveTapeWidth_Var_Samp_Fields = {
  __typename?: 'R_AdhesiveTapeWidth_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  TapeWidthId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  TapeWidthId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_AdhesiveTapeWidth_Variance_Fields = {
  __typename?: 'R_AdhesiveTapeWidth_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  TapeWidthId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_AdhesiveTapeWidth" */
export type R_AdhesiveTapeWidth_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  TapeWidthId?: Maybe<Order_By>;
};

/** columns and relationships of "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory = {
  __typename?: 'R_ApprovedUse_Subcategory';
  ApprovedUseId: Scalars['Int'];
  /** An object relationship */
  C_ApprovedUse: C_ApprovedUse;
  /** An object relationship */
  MD_ProductSubcategory: Md_ProductSubcategory;
  SubcategoryId: Scalars['Int'];
};

/** aggregated selection of "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Aggregate = {
  __typename?: 'R_ApprovedUse_Subcategory_aggregate';
  aggregate?: Maybe<R_ApprovedUse_Subcategory_Aggregate_Fields>;
  nodes: Array<R_ApprovedUse_Subcategory>;
};

/** aggregate fields of "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Aggregate_Fields = {
  __typename?: 'R_ApprovedUse_Subcategory_aggregate_fields';
  avg?: Maybe<R_ApprovedUse_Subcategory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_ApprovedUse_Subcategory_Max_Fields>;
  min?: Maybe<R_ApprovedUse_Subcategory_Min_Fields>;
  stddev?: Maybe<R_ApprovedUse_Subcategory_Stddev_Fields>;
  stddev_pop?: Maybe<R_ApprovedUse_Subcategory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_ApprovedUse_Subcategory_Stddev_Samp_Fields>;
  sum?: Maybe<R_ApprovedUse_Subcategory_Sum_Fields>;
  var_pop?: Maybe<R_ApprovedUse_Subcategory_Var_Pop_Fields>;
  var_samp?: Maybe<R_ApprovedUse_Subcategory_Var_Samp_Fields>;
  variance?: Maybe<R_ApprovedUse_Subcategory_Variance_Fields>;
};


/** aggregate fields of "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_ApprovedUse_Subcategory_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Aggregate_Order_By = {
  avg?: Maybe<R_ApprovedUse_Subcategory_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_ApprovedUse_Subcategory_Max_Order_By>;
  min?: Maybe<R_ApprovedUse_Subcategory_Min_Order_By>;
  stddev?: Maybe<R_ApprovedUse_Subcategory_Stddev_Order_By>;
  stddev_pop?: Maybe<R_ApprovedUse_Subcategory_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_ApprovedUse_Subcategory_Stddev_Samp_Order_By>;
  sum?: Maybe<R_ApprovedUse_Subcategory_Sum_Order_By>;
  var_pop?: Maybe<R_ApprovedUse_Subcategory_Var_Pop_Order_By>;
  var_samp?: Maybe<R_ApprovedUse_Subcategory_Var_Samp_Order_By>;
  variance?: Maybe<R_ApprovedUse_Subcategory_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_ApprovedUse_Subcategory_Avg_Fields = {
  __typename?: 'R_ApprovedUse_Subcategory_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Avg_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_ApprovedUse_Subcategory". All fields are combined with a logical 'AND'. */
export type R_ApprovedUse_Subcategory_Bool_Exp = {
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_ApprovedUse?: Maybe<C_ApprovedUse_Bool_Exp>;
  MD_ProductSubcategory?: Maybe<Md_ProductSubcategory_Bool_Exp>;
  SubcategoryId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_ApprovedUse_Subcategory_Bool_Exp>>;
  _not?: Maybe<R_ApprovedUse_Subcategory_Bool_Exp>;
  _or?: Maybe<Array<R_ApprovedUse_Subcategory_Bool_Exp>>;
};

/** upsert condition type for table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_If_Matched = {
  match_columns?: Array<R_ApprovedUse_Subcategory_Insert_Match_Column>;
  update_columns?: Array<R_ApprovedUse_Subcategory_Update_Column>;
  where?: Maybe<R_ApprovedUse_Subcategory_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Insert_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_ApprovedUse_Subcategory" */
export enum R_ApprovedUse_Subcategory_Insert_Match_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  SubcategoryId = 'SubcategoryId'
}

/** aggregate max on columns */
export type R_ApprovedUse_Subcategory_Max_Fields = {
  __typename?: 'R_ApprovedUse_Subcategory_max_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Max_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_ApprovedUse_Subcategory_Min_Fields = {
  __typename?: 'R_ApprovedUse_Subcategory_min_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Min_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Mutation_Response = {
  __typename?: 'R_ApprovedUse_Subcategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_ApprovedUse_Subcategory>;
};

/** Ordering options when selecting data from "R_ApprovedUse_Subcategory". */
export type R_ApprovedUse_Subcategory_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  C_ApprovedUse?: Maybe<C_ApprovedUse_Order_By>;
  MD_ProductSubcategory?: Maybe<Md_ProductSubcategory_Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_ApprovedUse_Subcategory */
export type R_ApprovedUse_Subcategory_Pk_Columns_Input = {
  ApprovedUseId: Scalars['Int'];
  SubcategoryId: Scalars['Int'];
};

/** select columns of table "R_ApprovedUse_Subcategory" */
export enum R_ApprovedUse_Subcategory_Select_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  SubcategoryId = 'SubcategoryId'
}

/** input type for updating data in table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Set_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_ApprovedUse_Subcategory_Stddev_Fields = {
  __typename?: 'R_ApprovedUse_Subcategory_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Stddev_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_ApprovedUse_Subcategory_Stddev_Pop_Fields = {
  __typename?: 'R_ApprovedUse_Subcategory_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Stddev_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_ApprovedUse_Subcategory_Stddev_Samp_Fields = {
  __typename?: 'R_ApprovedUse_Subcategory_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Stddev_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_ApprovedUse_Subcategory_Sum_Fields = {
  __typename?: 'R_ApprovedUse_Subcategory_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Sum_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** update columns of table "R_ApprovedUse_Subcategory" */
export enum R_ApprovedUse_Subcategory_Update_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  SubcategoryId = 'SubcategoryId'
}

/** aggregate var_pop on columns */
export type R_ApprovedUse_Subcategory_Var_Pop_Fields = {
  __typename?: 'R_ApprovedUse_Subcategory_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Var_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_ApprovedUse_Subcategory_Var_Samp_Fields = {
  __typename?: 'R_ApprovedUse_Subcategory_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Var_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_ApprovedUse_Subcategory_Variance_Fields = {
  __typename?: 'R_ApprovedUse_Subcategory_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_ApprovedUse_Subcategory" */
export type R_ApprovedUse_Subcategory_Variance_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
};

/** columns and relationships of "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse = {
  __typename?: 'R_ComponentType_ApprovedUse';
  ApprovedUseId: Scalars['Int'];
  /** An object relationship */
  C_ApprovedUse: C_ApprovedUse;
  /** An object relationship */
  C_ComponentType: C_ComponentType;
  ComponentTypeId: Scalars['Int'];
};

/** aggregated selection of "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Aggregate = {
  __typename?: 'R_ComponentType_ApprovedUse_aggregate';
  aggregate?: Maybe<R_ComponentType_ApprovedUse_Aggregate_Fields>;
  nodes: Array<R_ComponentType_ApprovedUse>;
};

/** aggregate fields of "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Aggregate_Fields = {
  __typename?: 'R_ComponentType_ApprovedUse_aggregate_fields';
  avg?: Maybe<R_ComponentType_ApprovedUse_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_ComponentType_ApprovedUse_Max_Fields>;
  min?: Maybe<R_ComponentType_ApprovedUse_Min_Fields>;
  stddev?: Maybe<R_ComponentType_ApprovedUse_Stddev_Fields>;
  stddev_pop?: Maybe<R_ComponentType_ApprovedUse_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_ComponentType_ApprovedUse_Stddev_Samp_Fields>;
  sum?: Maybe<R_ComponentType_ApprovedUse_Sum_Fields>;
  var_pop?: Maybe<R_ComponentType_ApprovedUse_Var_Pop_Fields>;
  var_samp?: Maybe<R_ComponentType_ApprovedUse_Var_Samp_Fields>;
  variance?: Maybe<R_ComponentType_ApprovedUse_Variance_Fields>;
};


/** aggregate fields of "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_ComponentType_ApprovedUse_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Aggregate_Order_By = {
  avg?: Maybe<R_ComponentType_ApprovedUse_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_ComponentType_ApprovedUse_Max_Order_By>;
  min?: Maybe<R_ComponentType_ApprovedUse_Min_Order_By>;
  stddev?: Maybe<R_ComponentType_ApprovedUse_Stddev_Order_By>;
  stddev_pop?: Maybe<R_ComponentType_ApprovedUse_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_ComponentType_ApprovedUse_Stddev_Samp_Order_By>;
  sum?: Maybe<R_ComponentType_ApprovedUse_Sum_Order_By>;
  var_pop?: Maybe<R_ComponentType_ApprovedUse_Var_Pop_Order_By>;
  var_samp?: Maybe<R_ComponentType_ApprovedUse_Var_Samp_Order_By>;
  variance?: Maybe<R_ComponentType_ApprovedUse_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_ComponentType_ApprovedUse_Avg_Fields = {
  __typename?: 'R_ComponentType_ApprovedUse_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentTypeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Avg_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_ComponentType_ApprovedUse". All fields are combined with a logical 'AND'. */
export type R_ComponentType_ApprovedUse_Bool_Exp = {
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_ApprovedUse?: Maybe<C_ApprovedUse_Bool_Exp>;
  C_ComponentType?: Maybe<C_ComponentType_Bool_Exp>;
  ComponentTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_ComponentType_ApprovedUse_Bool_Exp>>;
  _not?: Maybe<R_ComponentType_ApprovedUse_Bool_Exp>;
  _or?: Maybe<Array<R_ComponentType_ApprovedUse_Bool_Exp>>;
};

/** upsert condition type for table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_If_Matched = {
  match_columns?: Array<R_ComponentType_ApprovedUse_Insert_Match_Column>;
  update_columns?: Array<R_ComponentType_ApprovedUse_Update_Column>;
  where?: Maybe<R_ComponentType_ApprovedUse_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Insert_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_ComponentType_ApprovedUse" */
export enum R_ComponentType_ApprovedUse_Insert_Match_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ComponentTypeId = 'ComponentTypeId'
}

/** aggregate max on columns */
export type R_ComponentType_ApprovedUse_Max_Fields = {
  __typename?: 'R_ComponentType_ApprovedUse_max_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Max_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_ComponentType_ApprovedUse_Min_Fields = {
  __typename?: 'R_ComponentType_ApprovedUse_min_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Min_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Mutation_Response = {
  __typename?: 'R_ComponentType_ApprovedUse_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_ComponentType_ApprovedUse>;
};

/** Ordering options when selecting data from "R_ComponentType_ApprovedUse". */
export type R_ComponentType_ApprovedUse_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  C_ApprovedUse?: Maybe<C_ApprovedUse_Order_By>;
  C_ComponentType?: Maybe<C_ComponentType_Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_ComponentType_ApprovedUse */
export type R_ComponentType_ApprovedUse_Pk_Columns_Input = {
  ApprovedUseId: Scalars['Int'];
  ComponentTypeId: Scalars['Int'];
};

/** select columns of table "R_ComponentType_ApprovedUse" */
export enum R_ComponentType_ApprovedUse_Select_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ComponentTypeId = 'ComponentTypeId'
}

/** input type for updating data in table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Set_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_ComponentType_ApprovedUse_Stddev_Fields = {
  __typename?: 'R_ComponentType_ApprovedUse_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Stddev_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_ComponentType_ApprovedUse_Stddev_Pop_Fields = {
  __typename?: 'R_ComponentType_ApprovedUse_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Stddev_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_ComponentType_ApprovedUse_Stddev_Samp_Fields = {
  __typename?: 'R_ComponentType_ApprovedUse_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Stddev_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_ComponentType_ApprovedUse_Sum_Fields = {
  __typename?: 'R_ComponentType_ApprovedUse_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Sum_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
};

/** update columns of table "R_ComponentType_ApprovedUse" */
export enum R_ComponentType_ApprovedUse_Update_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  ComponentTypeId = 'ComponentTypeId'
}

/** aggregate var_pop on columns */
export type R_ComponentType_ApprovedUse_Var_Pop_Fields = {
  __typename?: 'R_ComponentType_ApprovedUse_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Var_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_ComponentType_ApprovedUse_Var_Samp_Fields = {
  __typename?: 'R_ComponentType_ApprovedUse_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Var_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_ComponentType_ApprovedUse_Variance_Fields = {
  __typename?: 'R_ComponentType_ApprovedUse_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentTypeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_ComponentType_ApprovedUse" */
export type R_ComponentType_ApprovedUse_Variance_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
};

/** columns and relationships of "R_CopingFastener" */
export type R_CopingFastener = {
  __typename?: 'R_CopingFastener';
  /** An object relationship */
  Coping: Coping;
  CopingComponentId: Scalars['Int'];
  /** An object relationship */
  Fastener: Fastener;
  FastenerComponentId: Scalars['Int'];
};

/** aggregated selection of "R_CopingFastener" */
export type R_CopingFastener_Aggregate = {
  __typename?: 'R_CopingFastener_aggregate';
  aggregate?: Maybe<R_CopingFastener_Aggregate_Fields>;
  nodes: Array<R_CopingFastener>;
};

/** aggregate fields of "R_CopingFastener" */
export type R_CopingFastener_Aggregate_Fields = {
  __typename?: 'R_CopingFastener_aggregate_fields';
  avg?: Maybe<R_CopingFastener_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_CopingFastener_Max_Fields>;
  min?: Maybe<R_CopingFastener_Min_Fields>;
  stddev?: Maybe<R_CopingFastener_Stddev_Fields>;
  stddev_pop?: Maybe<R_CopingFastener_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_CopingFastener_Stddev_Samp_Fields>;
  sum?: Maybe<R_CopingFastener_Sum_Fields>;
  var_pop?: Maybe<R_CopingFastener_Var_Pop_Fields>;
  var_samp?: Maybe<R_CopingFastener_Var_Samp_Fields>;
  variance?: Maybe<R_CopingFastener_Variance_Fields>;
};


/** aggregate fields of "R_CopingFastener" */
export type R_CopingFastener_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_CopingFastener_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_CopingFastener" */
export type R_CopingFastener_Aggregate_Order_By = {
  avg?: Maybe<R_CopingFastener_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_CopingFastener_Max_Order_By>;
  min?: Maybe<R_CopingFastener_Min_Order_By>;
  stddev?: Maybe<R_CopingFastener_Stddev_Order_By>;
  stddev_pop?: Maybe<R_CopingFastener_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_CopingFastener_Stddev_Samp_Order_By>;
  sum?: Maybe<R_CopingFastener_Sum_Order_By>;
  var_pop?: Maybe<R_CopingFastener_Var_Pop_Order_By>;
  var_samp?: Maybe<R_CopingFastener_Var_Samp_Order_By>;
  variance?: Maybe<R_CopingFastener_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_CopingFastener_Avg_Fields = {
  __typename?: 'R_CopingFastener_avg_fields';
  CopingComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_CopingFastener" */
export type R_CopingFastener_Avg_Order_By = {
  CopingComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_CopingFastener". All fields are combined with a logical 'AND'. */
export type R_CopingFastener_Bool_Exp = {
  Coping?: Maybe<Coping_Bool_Exp>;
  CopingComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  Fastener?: Maybe<Fastener_Bool_Exp>;
  FastenerComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_CopingFastener_Bool_Exp>>;
  _not?: Maybe<R_CopingFastener_Bool_Exp>;
  _or?: Maybe<Array<R_CopingFastener_Bool_Exp>>;
};

/** upsert condition type for table "R_CopingFastener" */
export type R_CopingFastener_If_Matched = {
  match_columns?: Array<R_CopingFastener_Insert_Match_Column>;
  update_columns?: Array<R_CopingFastener_Update_Column>;
  where?: Maybe<R_CopingFastener_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_CopingFastener" */
export type R_CopingFastener_Inc_Input = {
  CopingComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_CopingFastener" */
export type R_CopingFastener_Insert_Input = {
  CopingComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_CopingFastener" */
export enum R_CopingFastener_Insert_Match_Column {
  /** column name */
  CopingComponentId = 'CopingComponentId',
  /** column name */
  FastenerComponentId = 'FastenerComponentId'
}

/** aggregate max on columns */
export type R_CopingFastener_Max_Fields = {
  __typename?: 'R_CopingFastener_max_fields';
  CopingComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_CopingFastener" */
export type R_CopingFastener_Max_Order_By = {
  CopingComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_CopingFastener_Min_Fields = {
  __typename?: 'R_CopingFastener_min_fields';
  CopingComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_CopingFastener" */
export type R_CopingFastener_Min_Order_By = {
  CopingComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_CopingFastener" */
export type R_CopingFastener_Mutation_Response = {
  __typename?: 'R_CopingFastener_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_CopingFastener>;
};

/** Ordering options when selecting data from "R_CopingFastener". */
export type R_CopingFastener_Order_By = {
  Coping?: Maybe<Coping_Order_By>;
  CopingComponentId?: Maybe<Order_By>;
  Fastener?: Maybe<Fastener_Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_CopingFastener */
export type R_CopingFastener_Pk_Columns_Input = {
  CopingComponentId: Scalars['Int'];
  FastenerComponentId: Scalars['Int'];
};

/** select columns of table "R_CopingFastener" */
export enum R_CopingFastener_Select_Column {
  /** column name */
  CopingComponentId = 'CopingComponentId',
  /** column name */
  FastenerComponentId = 'FastenerComponentId'
}

/** input type for updating data in table "R_CopingFastener" */
export type R_CopingFastener_Set_Input = {
  CopingComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_CopingFastener_Stddev_Fields = {
  __typename?: 'R_CopingFastener_stddev_fields';
  CopingComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_CopingFastener" */
export type R_CopingFastener_Stddev_Order_By = {
  CopingComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_CopingFastener_Stddev_Pop_Fields = {
  __typename?: 'R_CopingFastener_stddev_pop_fields';
  CopingComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_CopingFastener" */
export type R_CopingFastener_Stddev_Pop_Order_By = {
  CopingComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_CopingFastener_Stddev_Samp_Fields = {
  __typename?: 'R_CopingFastener_stddev_samp_fields';
  CopingComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_CopingFastener" */
export type R_CopingFastener_Stddev_Samp_Order_By = {
  CopingComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_CopingFastener_Sum_Fields = {
  __typename?: 'R_CopingFastener_sum_fields';
  CopingComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_CopingFastener" */
export type R_CopingFastener_Sum_Order_By = {
  CopingComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** update columns of table "R_CopingFastener" */
export enum R_CopingFastener_Update_Column {
  /** column name */
  CopingComponentId = 'CopingComponentId',
  /** column name */
  FastenerComponentId = 'FastenerComponentId'
}

/** aggregate var_pop on columns */
export type R_CopingFastener_Var_Pop_Fields = {
  __typename?: 'R_CopingFastener_var_pop_fields';
  CopingComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_CopingFastener" */
export type R_CopingFastener_Var_Pop_Order_By = {
  CopingComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_CopingFastener_Var_Samp_Fields = {
  __typename?: 'R_CopingFastener_var_samp_fields';
  CopingComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_CopingFastener" */
export type R_CopingFastener_Var_Samp_Order_By = {
  CopingComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_CopingFastener_Variance_Fields = {
  __typename?: 'R_CopingFastener_variance_fields';
  CopingComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_CopingFastener" */
export type R_CopingFastener_Variance_Order_By = {
  CopingComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "R_Country_StateProvince" */
export type R_Country_StateProvince = {
  __typename?: 'R_Country_StateProvince';
  /** An object relationship */
  C_Country: C_Country;
  /** An object relationship */
  C_StateProvince: C_StateProvince;
  CountryId: Scalars['Int'];
  StateProvinceId: Scalars['Int'];
};

/** aggregated selection of "R_Country_StateProvince" */
export type R_Country_StateProvince_Aggregate = {
  __typename?: 'R_Country_StateProvince_aggregate';
  aggregate?: Maybe<R_Country_StateProvince_Aggregate_Fields>;
  nodes: Array<R_Country_StateProvince>;
};

/** aggregate fields of "R_Country_StateProvince" */
export type R_Country_StateProvince_Aggregate_Fields = {
  __typename?: 'R_Country_StateProvince_aggregate_fields';
  avg?: Maybe<R_Country_StateProvince_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_Country_StateProvince_Max_Fields>;
  min?: Maybe<R_Country_StateProvince_Min_Fields>;
  stddev?: Maybe<R_Country_StateProvince_Stddev_Fields>;
  stddev_pop?: Maybe<R_Country_StateProvince_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_Country_StateProvince_Stddev_Samp_Fields>;
  sum?: Maybe<R_Country_StateProvince_Sum_Fields>;
  var_pop?: Maybe<R_Country_StateProvince_Var_Pop_Fields>;
  var_samp?: Maybe<R_Country_StateProvince_Var_Samp_Fields>;
  variance?: Maybe<R_Country_StateProvince_Variance_Fields>;
};


/** aggregate fields of "R_Country_StateProvince" */
export type R_Country_StateProvince_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_Country_StateProvince_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_Country_StateProvince" */
export type R_Country_StateProvince_Aggregate_Order_By = {
  avg?: Maybe<R_Country_StateProvince_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_Country_StateProvince_Max_Order_By>;
  min?: Maybe<R_Country_StateProvince_Min_Order_By>;
  stddev?: Maybe<R_Country_StateProvince_Stddev_Order_By>;
  stddev_pop?: Maybe<R_Country_StateProvince_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_Country_StateProvince_Stddev_Samp_Order_By>;
  sum?: Maybe<R_Country_StateProvince_Sum_Order_By>;
  var_pop?: Maybe<R_Country_StateProvince_Var_Pop_Order_By>;
  var_samp?: Maybe<R_Country_StateProvince_Var_Samp_Order_By>;
  variance?: Maybe<R_Country_StateProvince_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_Country_StateProvince_Avg_Fields = {
  __typename?: 'R_Country_StateProvince_avg_fields';
  CountryId?: Maybe<Scalars['Float']>;
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_Country_StateProvince" */
export type R_Country_StateProvince_Avg_Order_By = {
  CountryId?: Maybe<Order_By>;
  StateProvinceId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_Country_StateProvince". All fields are combined with a logical 'AND'. */
export type R_Country_StateProvince_Bool_Exp = {
  C_Country?: Maybe<C_Country_Bool_Exp>;
  C_StateProvince?: Maybe<C_StateProvince_Bool_Exp>;
  CountryId?: Maybe<Int_Mssql_Comparison_Exp>;
  StateProvinceId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_Country_StateProvince_Bool_Exp>>;
  _not?: Maybe<R_Country_StateProvince_Bool_Exp>;
  _or?: Maybe<Array<R_Country_StateProvince_Bool_Exp>>;
};

/** upsert condition type for table "R_Country_StateProvince" */
export type R_Country_StateProvince_If_Matched = {
  match_columns?: Array<R_Country_StateProvince_Insert_Match_Column>;
  update_columns?: Array<R_Country_StateProvince_Update_Column>;
  where?: Maybe<R_Country_StateProvince_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_Country_StateProvince" */
export type R_Country_StateProvince_Inc_Input = {
  CountryId?: Maybe<Scalars['Int']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_Country_StateProvince" */
export type R_Country_StateProvince_Insert_Input = {
  CountryId?: Maybe<Scalars['Int']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_Country_StateProvince" */
export enum R_Country_StateProvince_Insert_Match_Column {
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  StateProvinceId = 'StateProvinceId'
}

/** aggregate max on columns */
export type R_Country_StateProvince_Max_Fields = {
  __typename?: 'R_Country_StateProvince_max_fields';
  CountryId?: Maybe<Scalars['Int']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_Country_StateProvince" */
export type R_Country_StateProvince_Max_Order_By = {
  CountryId?: Maybe<Order_By>;
  StateProvinceId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_Country_StateProvince_Min_Fields = {
  __typename?: 'R_Country_StateProvince_min_fields';
  CountryId?: Maybe<Scalars['Int']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_Country_StateProvince" */
export type R_Country_StateProvince_Min_Order_By = {
  CountryId?: Maybe<Order_By>;
  StateProvinceId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_Country_StateProvince" */
export type R_Country_StateProvince_Mutation_Response = {
  __typename?: 'R_Country_StateProvince_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_Country_StateProvince>;
};

/** Ordering options when selecting data from "R_Country_StateProvince". */
export type R_Country_StateProvince_Order_By = {
  C_Country?: Maybe<C_Country_Order_By>;
  C_StateProvince?: Maybe<C_StateProvince_Order_By>;
  CountryId?: Maybe<Order_By>;
  StateProvinceId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_Country_StateProvince */
export type R_Country_StateProvince_Pk_Columns_Input = {
  CountryId: Scalars['Int'];
  StateProvinceId: Scalars['Int'];
};

/** select columns of table "R_Country_StateProvince" */
export enum R_Country_StateProvince_Select_Column {
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  StateProvinceId = 'StateProvinceId'
}

/** input type for updating data in table "R_Country_StateProvince" */
export type R_Country_StateProvince_Set_Input = {
  CountryId?: Maybe<Scalars['Int']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_Country_StateProvince_Stddev_Fields = {
  __typename?: 'R_Country_StateProvince_stddev_fields';
  CountryId?: Maybe<Scalars['Float']>;
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_Country_StateProvince" */
export type R_Country_StateProvince_Stddev_Order_By = {
  CountryId?: Maybe<Order_By>;
  StateProvinceId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_Country_StateProvince_Stddev_Pop_Fields = {
  __typename?: 'R_Country_StateProvince_stddev_pop_fields';
  CountryId?: Maybe<Scalars['Float']>;
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_Country_StateProvince" */
export type R_Country_StateProvince_Stddev_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  StateProvinceId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_Country_StateProvince_Stddev_Samp_Fields = {
  __typename?: 'R_Country_StateProvince_stddev_samp_fields';
  CountryId?: Maybe<Scalars['Float']>;
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_Country_StateProvince" */
export type R_Country_StateProvince_Stddev_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  StateProvinceId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_Country_StateProvince_Sum_Fields = {
  __typename?: 'R_Country_StateProvince_sum_fields';
  CountryId?: Maybe<Scalars['Int']>;
  StateProvinceId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_Country_StateProvince" */
export type R_Country_StateProvince_Sum_Order_By = {
  CountryId?: Maybe<Order_By>;
  StateProvinceId?: Maybe<Order_By>;
};

/** update columns of table "R_Country_StateProvince" */
export enum R_Country_StateProvince_Update_Column {
  /** column name */
  CountryId = 'CountryId',
  /** column name */
  StateProvinceId = 'StateProvinceId'
}

/** aggregate var_pop on columns */
export type R_Country_StateProvince_Var_Pop_Fields = {
  __typename?: 'R_Country_StateProvince_var_pop_fields';
  CountryId?: Maybe<Scalars['Float']>;
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_Country_StateProvince" */
export type R_Country_StateProvince_Var_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  StateProvinceId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_Country_StateProvince_Var_Samp_Fields = {
  __typename?: 'R_Country_StateProvince_var_samp_fields';
  CountryId?: Maybe<Scalars['Float']>;
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_Country_StateProvince" */
export type R_Country_StateProvince_Var_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  StateProvinceId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_Country_StateProvince_Variance_Fields = {
  __typename?: 'R_Country_StateProvince_variance_fields';
  CountryId?: Maybe<Scalars['Float']>;
  StateProvinceId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_Country_StateProvince" */
export type R_Country_StateProvince_Variance_Order_By = {
  CountryId?: Maybe<Order_By>;
  StateProvinceId?: Maybe<Order_By>;
};

/** columns and relationships of "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener = {
  __typename?: 'R_ExpansionJoinFastener';
  /** An object relationship */
  ExpansionJoint: ExpansionJoint;
  ExpansionJointComponentId: Scalars['Int'];
  FastenerComponentId: Scalars['Int'];
};

/** aggregated selection of "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Aggregate = {
  __typename?: 'R_ExpansionJoinFastener_aggregate';
  aggregate?: Maybe<R_ExpansionJoinFastener_Aggregate_Fields>;
  nodes: Array<R_ExpansionJoinFastener>;
};

/** aggregate fields of "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Aggregate_Fields = {
  __typename?: 'R_ExpansionJoinFastener_aggregate_fields';
  avg?: Maybe<R_ExpansionJoinFastener_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_ExpansionJoinFastener_Max_Fields>;
  min?: Maybe<R_ExpansionJoinFastener_Min_Fields>;
  stddev?: Maybe<R_ExpansionJoinFastener_Stddev_Fields>;
  stddev_pop?: Maybe<R_ExpansionJoinFastener_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_ExpansionJoinFastener_Stddev_Samp_Fields>;
  sum?: Maybe<R_ExpansionJoinFastener_Sum_Fields>;
  var_pop?: Maybe<R_ExpansionJoinFastener_Var_Pop_Fields>;
  var_samp?: Maybe<R_ExpansionJoinFastener_Var_Samp_Fields>;
  variance?: Maybe<R_ExpansionJoinFastener_Variance_Fields>;
};


/** aggregate fields of "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_ExpansionJoinFastener_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Aggregate_Order_By = {
  avg?: Maybe<R_ExpansionJoinFastener_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_ExpansionJoinFastener_Max_Order_By>;
  min?: Maybe<R_ExpansionJoinFastener_Min_Order_By>;
  stddev?: Maybe<R_ExpansionJoinFastener_Stddev_Order_By>;
  stddev_pop?: Maybe<R_ExpansionJoinFastener_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_ExpansionJoinFastener_Stddev_Samp_Order_By>;
  sum?: Maybe<R_ExpansionJoinFastener_Sum_Order_By>;
  var_pop?: Maybe<R_ExpansionJoinFastener_Var_Pop_Order_By>;
  var_samp?: Maybe<R_ExpansionJoinFastener_Var_Samp_Order_By>;
  variance?: Maybe<R_ExpansionJoinFastener_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_ExpansionJoinFastener_Avg_Fields = {
  __typename?: 'R_ExpansionJoinFastener_avg_fields';
  ExpansionJointComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Avg_Order_By = {
  ExpansionJointComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_ExpansionJoinFastener". All fields are combined with a logical 'AND'. */
export type R_ExpansionJoinFastener_Bool_Exp = {
  ExpansionJoint?: Maybe<ExpansionJoint_Bool_Exp>;
  ExpansionJointComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  FastenerComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_ExpansionJoinFastener_Bool_Exp>>;
  _not?: Maybe<R_ExpansionJoinFastener_Bool_Exp>;
  _or?: Maybe<Array<R_ExpansionJoinFastener_Bool_Exp>>;
};

/** upsert condition type for table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_If_Matched = {
  match_columns?: Array<R_ExpansionJoinFastener_Insert_Match_Column>;
  update_columns?: Array<R_ExpansionJoinFastener_Update_Column>;
  where?: Maybe<R_ExpansionJoinFastener_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Inc_Input = {
  ExpansionJointComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Insert_Input = {
  ExpansionJointComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_ExpansionJoinFastener" */
export enum R_ExpansionJoinFastener_Insert_Match_Column {
  /** column name */
  ExpansionJointComponentId = 'ExpansionJointComponentId',
  /** column name */
  FastenerComponentId = 'FastenerComponentId'
}

/** aggregate max on columns */
export type R_ExpansionJoinFastener_Max_Fields = {
  __typename?: 'R_ExpansionJoinFastener_max_fields';
  ExpansionJointComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Max_Order_By = {
  ExpansionJointComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_ExpansionJoinFastener_Min_Fields = {
  __typename?: 'R_ExpansionJoinFastener_min_fields';
  ExpansionJointComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Min_Order_By = {
  ExpansionJointComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Mutation_Response = {
  __typename?: 'R_ExpansionJoinFastener_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_ExpansionJoinFastener>;
};

/** Ordering options when selecting data from "R_ExpansionJoinFastener". */
export type R_ExpansionJoinFastener_Order_By = {
  ExpansionJoint?: Maybe<ExpansionJoint_Order_By>;
  ExpansionJointComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_ExpansionJoinFastener */
export type R_ExpansionJoinFastener_Pk_Columns_Input = {
  ExpansionJointComponentId: Scalars['Int'];
  FastenerComponentId: Scalars['Int'];
};

/** select columns of table "R_ExpansionJoinFastener" */
export enum R_ExpansionJoinFastener_Select_Column {
  /** column name */
  ExpansionJointComponentId = 'ExpansionJointComponentId',
  /** column name */
  FastenerComponentId = 'FastenerComponentId'
}

/** input type for updating data in table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Set_Input = {
  ExpansionJointComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_ExpansionJoinFastener_Stddev_Fields = {
  __typename?: 'R_ExpansionJoinFastener_stddev_fields';
  ExpansionJointComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Stddev_Order_By = {
  ExpansionJointComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_ExpansionJoinFastener_Stddev_Pop_Fields = {
  __typename?: 'R_ExpansionJoinFastener_stddev_pop_fields';
  ExpansionJointComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Stddev_Pop_Order_By = {
  ExpansionJointComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_ExpansionJoinFastener_Stddev_Samp_Fields = {
  __typename?: 'R_ExpansionJoinFastener_stddev_samp_fields';
  ExpansionJointComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Stddev_Samp_Order_By = {
  ExpansionJointComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_ExpansionJoinFastener_Sum_Fields = {
  __typename?: 'R_ExpansionJoinFastener_sum_fields';
  ExpansionJointComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Sum_Order_By = {
  ExpansionJointComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** update columns of table "R_ExpansionJoinFastener" */
export enum R_ExpansionJoinFastener_Update_Column {
  /** column name */
  ExpansionJointComponentId = 'ExpansionJointComponentId',
  /** column name */
  FastenerComponentId = 'FastenerComponentId'
}

/** aggregate var_pop on columns */
export type R_ExpansionJoinFastener_Var_Pop_Fields = {
  __typename?: 'R_ExpansionJoinFastener_var_pop_fields';
  ExpansionJointComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Var_Pop_Order_By = {
  ExpansionJointComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_ExpansionJoinFastener_Var_Samp_Fields = {
  __typename?: 'R_ExpansionJoinFastener_var_samp_fields';
  ExpansionJointComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Var_Samp_Order_By = {
  ExpansionJointComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_ExpansionJoinFastener_Variance_Fields = {
  __typename?: 'R_ExpansionJoinFastener_variance_fields';
  ExpansionJointComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_ExpansionJoinFastener" */
export type R_ExpansionJoinFastener_Variance_Order_By = {
  ExpansionJointComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent = {
  __typename?: 'R_FasciaComponentFastenerComponent';
  FasciaComponentId: Scalars['Int'];
  /** An object relationship */
  Fascium: Fascia;
  /** An object relationship */
  Fastener: Fastener;
  FastenerComponentId: Scalars['Int'];
};

/** aggregated selection of "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Aggregate = {
  __typename?: 'R_FasciaComponentFastenerComponent_aggregate';
  aggregate?: Maybe<R_FasciaComponentFastenerComponent_Aggregate_Fields>;
  nodes: Array<R_FasciaComponentFastenerComponent>;
};

/** aggregate fields of "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Aggregate_Fields = {
  __typename?: 'R_FasciaComponentFastenerComponent_aggregate_fields';
  avg?: Maybe<R_FasciaComponentFastenerComponent_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_FasciaComponentFastenerComponent_Max_Fields>;
  min?: Maybe<R_FasciaComponentFastenerComponent_Min_Fields>;
  stddev?: Maybe<R_FasciaComponentFastenerComponent_Stddev_Fields>;
  stddev_pop?: Maybe<R_FasciaComponentFastenerComponent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_FasciaComponentFastenerComponent_Stddev_Samp_Fields>;
  sum?: Maybe<R_FasciaComponentFastenerComponent_Sum_Fields>;
  var_pop?: Maybe<R_FasciaComponentFastenerComponent_Var_Pop_Fields>;
  var_samp?: Maybe<R_FasciaComponentFastenerComponent_Var_Samp_Fields>;
  variance?: Maybe<R_FasciaComponentFastenerComponent_Variance_Fields>;
};


/** aggregate fields of "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_FasciaComponentFastenerComponent_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Aggregate_Order_By = {
  avg?: Maybe<R_FasciaComponentFastenerComponent_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_FasciaComponentFastenerComponent_Max_Order_By>;
  min?: Maybe<R_FasciaComponentFastenerComponent_Min_Order_By>;
  stddev?: Maybe<R_FasciaComponentFastenerComponent_Stddev_Order_By>;
  stddev_pop?: Maybe<R_FasciaComponentFastenerComponent_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_FasciaComponentFastenerComponent_Stddev_Samp_Order_By>;
  sum?: Maybe<R_FasciaComponentFastenerComponent_Sum_Order_By>;
  var_pop?: Maybe<R_FasciaComponentFastenerComponent_Var_Pop_Order_By>;
  var_samp?: Maybe<R_FasciaComponentFastenerComponent_Var_Samp_Order_By>;
  variance?: Maybe<R_FasciaComponentFastenerComponent_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_FasciaComponentFastenerComponent_Avg_Fields = {
  __typename?: 'R_FasciaComponentFastenerComponent_avg_fields';
  FasciaComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Avg_Order_By = {
  FasciaComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_FasciaComponentFastenerComponent". All fields are combined with a logical 'AND'. */
export type R_FasciaComponentFastenerComponent_Bool_Exp = {
  FasciaComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  Fascium?: Maybe<Fascia_Bool_Exp>;
  Fastener?: Maybe<Fastener_Bool_Exp>;
  FastenerComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_FasciaComponentFastenerComponent_Bool_Exp>>;
  _not?: Maybe<R_FasciaComponentFastenerComponent_Bool_Exp>;
  _or?: Maybe<Array<R_FasciaComponentFastenerComponent_Bool_Exp>>;
};

/** upsert condition type for table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_If_Matched = {
  match_columns?: Array<R_FasciaComponentFastenerComponent_Insert_Match_Column>;
  update_columns?: Array<R_FasciaComponentFastenerComponent_Update_Column>;
  where?: Maybe<R_FasciaComponentFastenerComponent_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Inc_Input = {
  FasciaComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Insert_Input = {
  FasciaComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_FasciaComponentFastenerComponent" */
export enum R_FasciaComponentFastenerComponent_Insert_Match_Column {
  /** column name */
  FasciaComponentId = 'FasciaComponentId',
  /** column name */
  FastenerComponentId = 'FastenerComponentId'
}

/** aggregate max on columns */
export type R_FasciaComponentFastenerComponent_Max_Fields = {
  __typename?: 'R_FasciaComponentFastenerComponent_max_fields';
  FasciaComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Max_Order_By = {
  FasciaComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_FasciaComponentFastenerComponent_Min_Fields = {
  __typename?: 'R_FasciaComponentFastenerComponent_min_fields';
  FasciaComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Min_Order_By = {
  FasciaComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Mutation_Response = {
  __typename?: 'R_FasciaComponentFastenerComponent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_FasciaComponentFastenerComponent>;
};

/** Ordering options when selecting data from "R_FasciaComponentFastenerComponent". */
export type R_FasciaComponentFastenerComponent_Order_By = {
  FasciaComponentId?: Maybe<Order_By>;
  Fascium?: Maybe<Fascia_Order_By>;
  Fastener?: Maybe<Fastener_Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_FasciaComponentFastenerComponent */
export type R_FasciaComponentFastenerComponent_Pk_Columns_Input = {
  FasciaComponentId: Scalars['Int'];
  FastenerComponentId: Scalars['Int'];
};

/** select columns of table "R_FasciaComponentFastenerComponent" */
export enum R_FasciaComponentFastenerComponent_Select_Column {
  /** column name */
  FasciaComponentId = 'FasciaComponentId',
  /** column name */
  FastenerComponentId = 'FastenerComponentId'
}

/** input type for updating data in table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Set_Input = {
  FasciaComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_FasciaComponentFastenerComponent_Stddev_Fields = {
  __typename?: 'R_FasciaComponentFastenerComponent_stddev_fields';
  FasciaComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Stddev_Order_By = {
  FasciaComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_FasciaComponentFastenerComponent_Stddev_Pop_Fields = {
  __typename?: 'R_FasciaComponentFastenerComponent_stddev_pop_fields';
  FasciaComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Stddev_Pop_Order_By = {
  FasciaComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_FasciaComponentFastenerComponent_Stddev_Samp_Fields = {
  __typename?: 'R_FasciaComponentFastenerComponent_stddev_samp_fields';
  FasciaComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Stddev_Samp_Order_By = {
  FasciaComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_FasciaComponentFastenerComponent_Sum_Fields = {
  __typename?: 'R_FasciaComponentFastenerComponent_sum_fields';
  FasciaComponentId?: Maybe<Scalars['Int']>;
  FastenerComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Sum_Order_By = {
  FasciaComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** update columns of table "R_FasciaComponentFastenerComponent" */
export enum R_FasciaComponentFastenerComponent_Update_Column {
  /** column name */
  FasciaComponentId = 'FasciaComponentId',
  /** column name */
  FastenerComponentId = 'FastenerComponentId'
}

/** aggregate var_pop on columns */
export type R_FasciaComponentFastenerComponent_Var_Pop_Fields = {
  __typename?: 'R_FasciaComponentFastenerComponent_var_pop_fields';
  FasciaComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Var_Pop_Order_By = {
  FasciaComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_FasciaComponentFastenerComponent_Var_Samp_Fields = {
  __typename?: 'R_FasciaComponentFastenerComponent_var_samp_fields';
  FasciaComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Var_Samp_Order_By = {
  FasciaComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_FasciaComponentFastenerComponent_Variance_Fields = {
  __typename?: 'R_FasciaComponentFastenerComponent_variance_fields';
  FasciaComponentId?: Maybe<Scalars['Float']>;
  FastenerComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_FasciaComponentFastenerComponent" */
export type R_FasciaComponentFastenerComponent_Variance_Order_By = {
  FasciaComponentId?: Maybe<Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "R_GutterFasteners" */
export type R_GutterFasteners = {
  __typename?: 'R_GutterFasteners';
  /** An object relationship */
  Fastener: Fastener;
  FastenerId: Scalars['Int'];
  /** An object relationship */
  Gutter: Gutter;
  GutterId: Scalars['Int'];
};

/** aggregated selection of "R_GutterFasteners" */
export type R_GutterFasteners_Aggregate = {
  __typename?: 'R_GutterFasteners_aggregate';
  aggregate?: Maybe<R_GutterFasteners_Aggregate_Fields>;
  nodes: Array<R_GutterFasteners>;
};

/** aggregate fields of "R_GutterFasteners" */
export type R_GutterFasteners_Aggregate_Fields = {
  __typename?: 'R_GutterFasteners_aggregate_fields';
  avg?: Maybe<R_GutterFasteners_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_GutterFasteners_Max_Fields>;
  min?: Maybe<R_GutterFasteners_Min_Fields>;
  stddev?: Maybe<R_GutterFasteners_Stddev_Fields>;
  stddev_pop?: Maybe<R_GutterFasteners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_GutterFasteners_Stddev_Samp_Fields>;
  sum?: Maybe<R_GutterFasteners_Sum_Fields>;
  var_pop?: Maybe<R_GutterFasteners_Var_Pop_Fields>;
  var_samp?: Maybe<R_GutterFasteners_Var_Samp_Fields>;
  variance?: Maybe<R_GutterFasteners_Variance_Fields>;
};


/** aggregate fields of "R_GutterFasteners" */
export type R_GutterFasteners_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_GutterFasteners_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_GutterFasteners" */
export type R_GutterFasteners_Aggregate_Order_By = {
  avg?: Maybe<R_GutterFasteners_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_GutterFasteners_Max_Order_By>;
  min?: Maybe<R_GutterFasteners_Min_Order_By>;
  stddev?: Maybe<R_GutterFasteners_Stddev_Order_By>;
  stddev_pop?: Maybe<R_GutterFasteners_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_GutterFasteners_Stddev_Samp_Order_By>;
  sum?: Maybe<R_GutterFasteners_Sum_Order_By>;
  var_pop?: Maybe<R_GutterFasteners_Var_Pop_Order_By>;
  var_samp?: Maybe<R_GutterFasteners_Var_Samp_Order_By>;
  variance?: Maybe<R_GutterFasteners_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_GutterFasteners_Avg_Fields = {
  __typename?: 'R_GutterFasteners_avg_fields';
  FastenerId?: Maybe<Scalars['Float']>;
  GutterId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_GutterFasteners" */
export type R_GutterFasteners_Avg_Order_By = {
  FastenerId?: Maybe<Order_By>;
  GutterId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_GutterFasteners". All fields are combined with a logical 'AND'. */
export type R_GutterFasteners_Bool_Exp = {
  Fastener?: Maybe<Fastener_Bool_Exp>;
  FastenerId?: Maybe<Int_Mssql_Comparison_Exp>;
  Gutter?: Maybe<Gutter_Bool_Exp>;
  GutterId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_GutterFasteners_Bool_Exp>>;
  _not?: Maybe<R_GutterFasteners_Bool_Exp>;
  _or?: Maybe<Array<R_GutterFasteners_Bool_Exp>>;
};

/** upsert condition type for table "R_GutterFasteners" */
export type R_GutterFasteners_If_Matched = {
  match_columns?: Array<R_GutterFasteners_Insert_Match_Column>;
  update_columns?: Array<R_GutterFasteners_Update_Column>;
  where?: Maybe<R_GutterFasteners_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_GutterFasteners" */
export type R_GutterFasteners_Inc_Input = {
  FastenerId?: Maybe<Scalars['Int']>;
  GutterId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_GutterFasteners" */
export type R_GutterFasteners_Insert_Input = {
  FastenerId?: Maybe<Scalars['Int']>;
  GutterId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_GutterFasteners" */
export enum R_GutterFasteners_Insert_Match_Column {
  /** column name */
  FastenerId = 'FastenerId',
  /** column name */
  GutterId = 'GutterId'
}

/** aggregate max on columns */
export type R_GutterFasteners_Max_Fields = {
  __typename?: 'R_GutterFasteners_max_fields';
  FastenerId?: Maybe<Scalars['Int']>;
  GutterId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_GutterFasteners" */
export type R_GutterFasteners_Max_Order_By = {
  FastenerId?: Maybe<Order_By>;
  GutterId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_GutterFasteners_Min_Fields = {
  __typename?: 'R_GutterFasteners_min_fields';
  FastenerId?: Maybe<Scalars['Int']>;
  GutterId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_GutterFasteners" */
export type R_GutterFasteners_Min_Order_By = {
  FastenerId?: Maybe<Order_By>;
  GutterId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_GutterFasteners" */
export type R_GutterFasteners_Mutation_Response = {
  __typename?: 'R_GutterFasteners_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_GutterFasteners>;
};

/** Ordering options when selecting data from "R_GutterFasteners". */
export type R_GutterFasteners_Order_By = {
  Fastener?: Maybe<Fastener_Order_By>;
  FastenerId?: Maybe<Order_By>;
  Gutter?: Maybe<Gutter_Order_By>;
  GutterId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_GutterFasteners */
export type R_GutterFasteners_Pk_Columns_Input = {
  FastenerId: Scalars['Int'];
  GutterId: Scalars['Int'];
};

/** select columns of table "R_GutterFasteners" */
export enum R_GutterFasteners_Select_Column {
  /** column name */
  FastenerId = 'FastenerId',
  /** column name */
  GutterId = 'GutterId'
}

/** input type for updating data in table "R_GutterFasteners" */
export type R_GutterFasteners_Set_Input = {
  FastenerId?: Maybe<Scalars['Int']>;
  GutterId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_GutterFasteners_Stddev_Fields = {
  __typename?: 'R_GutterFasteners_stddev_fields';
  FastenerId?: Maybe<Scalars['Float']>;
  GutterId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_GutterFasteners" */
export type R_GutterFasteners_Stddev_Order_By = {
  FastenerId?: Maybe<Order_By>;
  GutterId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_GutterFasteners_Stddev_Pop_Fields = {
  __typename?: 'R_GutterFasteners_stddev_pop_fields';
  FastenerId?: Maybe<Scalars['Float']>;
  GutterId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_GutterFasteners" */
export type R_GutterFasteners_Stddev_Pop_Order_By = {
  FastenerId?: Maybe<Order_By>;
  GutterId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_GutterFasteners_Stddev_Samp_Fields = {
  __typename?: 'R_GutterFasteners_stddev_samp_fields';
  FastenerId?: Maybe<Scalars['Float']>;
  GutterId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_GutterFasteners" */
export type R_GutterFasteners_Stddev_Samp_Order_By = {
  FastenerId?: Maybe<Order_By>;
  GutterId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_GutterFasteners_Sum_Fields = {
  __typename?: 'R_GutterFasteners_sum_fields';
  FastenerId?: Maybe<Scalars['Int']>;
  GutterId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_GutterFasteners" */
export type R_GutterFasteners_Sum_Order_By = {
  FastenerId?: Maybe<Order_By>;
  GutterId?: Maybe<Order_By>;
};

/** update columns of table "R_GutterFasteners" */
export enum R_GutterFasteners_Update_Column {
  /** column name */
  FastenerId = 'FastenerId',
  /** column name */
  GutterId = 'GutterId'
}

/** aggregate var_pop on columns */
export type R_GutterFasteners_Var_Pop_Fields = {
  __typename?: 'R_GutterFasteners_var_pop_fields';
  FastenerId?: Maybe<Scalars['Float']>;
  GutterId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_GutterFasteners" */
export type R_GutterFasteners_Var_Pop_Order_By = {
  FastenerId?: Maybe<Order_By>;
  GutterId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_GutterFasteners_Var_Samp_Fields = {
  __typename?: 'R_GutterFasteners_var_samp_fields';
  FastenerId?: Maybe<Scalars['Float']>;
  GutterId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_GutterFasteners" */
export type R_GutterFasteners_Var_Samp_Order_By = {
  FastenerId?: Maybe<Order_By>;
  GutterId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_GutterFasteners_Variance_Fields = {
  __typename?: 'R_GutterFasteners_variance_fields';
  FastenerId?: Maybe<Scalars['Float']>;
  GutterId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_GutterFasteners" */
export type R_GutterFasteners_Variance_Order_By = {
  FastenerId?: Maybe<Order_By>;
  GutterId?: Maybe<Order_By>;
};

/** columns and relationships of "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck = {
  __typename?: 'R_LinerPanelMaterialFiberReinforcedPlasticDeck';
  /** An object relationship */
  C_LinerPanelMaterial: C_LinerPanelMaterial;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  FiberReinforcedPlasticDeck: FiberReinforcedPlasticDeck;
  LinerPanelMaterialId: Scalars['Int'];
};

/** aggregated selection of "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Aggregate = {
  __typename?: 'R_LinerPanelMaterialFiberReinforcedPlasticDeck_aggregate';
  aggregate?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Aggregate_Fields>;
  nodes: Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck>;
};

/** aggregate fields of "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Aggregate_Fields = {
  __typename?: 'R_LinerPanelMaterialFiberReinforcedPlasticDeck_aggregate_fields';
  avg?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Max_Fields>;
  min?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Min_Fields>;
  stddev?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Stddev_Fields>;
  stddev_pop?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Stddev_Samp_Fields>;
  sum?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Sum_Fields>;
  var_pop?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Var_Pop_Fields>;
  var_samp?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Var_Samp_Fields>;
  variance?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Variance_Fields>;
};


/** aggregate fields of "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Aggregate_Order_By = {
  avg?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Max_Order_By>;
  min?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Min_Order_By>;
  stddev?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Stddev_Order_By>;
  stddev_pop?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Stddev_Samp_Order_By>;
  sum?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Sum_Order_By>;
  var_pop?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Var_Pop_Order_By>;
  var_samp?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Var_Samp_Order_By>;
  variance?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Avg_Fields = {
  __typename?: 'R_LinerPanelMaterialFiberReinforcedPlasticDeck_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_LinerPanelMaterialFiberReinforcedPlasticDeck". All fields are combined with a logical 'AND'. */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp = {
  C_LinerPanelMaterial?: Maybe<C_LinerPanelMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  FiberReinforcedPlasticDeck?: Maybe<FiberReinforcedPlasticDeck_Bool_Exp>;
  LinerPanelMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp>>;
  _not?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp>;
  _or?: Maybe<Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp>>;
};

/** upsert condition type for table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_If_Matched = {
  match_columns?: Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Insert_Match_Column>;
  update_columns?: Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Update_Column>;
  where?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export enum R_LinerPanelMaterialFiberReinforcedPlasticDeck_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LinerPanelMaterialId = 'LinerPanelMaterialId'
}

/** aggregate max on columns */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Max_Fields = {
  __typename?: 'R_LinerPanelMaterialFiberReinforcedPlasticDeck_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Min_Fields = {
  __typename?: 'R_LinerPanelMaterialFiberReinforcedPlasticDeck_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Mutation_Response = {
  __typename?: 'R_LinerPanelMaterialFiberReinforcedPlasticDeck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck>;
};

/** Ordering options when selecting data from "R_LinerPanelMaterialFiberReinforcedPlasticDeck". */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Order_By = {
  C_LinerPanelMaterial?: Maybe<C_LinerPanelMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  FiberReinforcedPlasticDeck?: Maybe<FiberReinforcedPlasticDeck_Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_LinerPanelMaterialFiberReinforcedPlasticDeck */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  LinerPanelMaterialId: Scalars['Int'];
};

/** select columns of table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export enum R_LinerPanelMaterialFiberReinforcedPlasticDeck_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LinerPanelMaterialId = 'LinerPanelMaterialId'
}

/** input type for updating data in table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Stddev_Fields = {
  __typename?: 'R_LinerPanelMaterialFiberReinforcedPlasticDeck_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Stddev_Pop_Fields = {
  __typename?: 'R_LinerPanelMaterialFiberReinforcedPlasticDeck_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Stddev_Samp_Fields = {
  __typename?: 'R_LinerPanelMaterialFiberReinforcedPlasticDeck_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Sum_Fields = {
  __typename?: 'R_LinerPanelMaterialFiberReinforcedPlasticDeck_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** update columns of table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export enum R_LinerPanelMaterialFiberReinforcedPlasticDeck_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LinerPanelMaterialId = 'LinerPanelMaterialId'
}

/** aggregate var_pop on columns */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Var_Pop_Fields = {
  __typename?: 'R_LinerPanelMaterialFiberReinforcedPlasticDeck_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Var_Samp_Fields = {
  __typename?: 'R_LinerPanelMaterialFiberReinforcedPlasticDeck_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Variance_Fields = {
  __typename?: 'R_LinerPanelMaterialFiberReinforcedPlasticDeck_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
export type R_LinerPanelMaterialFiberReinforcedPlasticDeck_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate = {
  __typename?: 'R_MaintenanceCoatingSubstrate';
  /** An object relationship */
  C_MaintenanceCoatingSubstrate: C_MaintenanceCoatingSubstrate;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  MaintenanceCoating: MaintenanceCoating;
  MaintenanceCoatingSubstrateId: Scalars['Int'];
};

/** aggregated selection of "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Aggregate = {
  __typename?: 'R_MaintenanceCoatingSubstrate_aggregate';
  aggregate?: Maybe<R_MaintenanceCoatingSubstrate_Aggregate_Fields>;
  nodes: Array<R_MaintenanceCoatingSubstrate>;
};

/** aggregate fields of "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Aggregate_Fields = {
  __typename?: 'R_MaintenanceCoatingSubstrate_aggregate_fields';
  avg?: Maybe<R_MaintenanceCoatingSubstrate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_MaintenanceCoatingSubstrate_Max_Fields>;
  min?: Maybe<R_MaintenanceCoatingSubstrate_Min_Fields>;
  stddev?: Maybe<R_MaintenanceCoatingSubstrate_Stddev_Fields>;
  stddev_pop?: Maybe<R_MaintenanceCoatingSubstrate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_MaintenanceCoatingSubstrate_Stddev_Samp_Fields>;
  sum?: Maybe<R_MaintenanceCoatingSubstrate_Sum_Fields>;
  var_pop?: Maybe<R_MaintenanceCoatingSubstrate_Var_Pop_Fields>;
  var_samp?: Maybe<R_MaintenanceCoatingSubstrate_Var_Samp_Fields>;
  variance?: Maybe<R_MaintenanceCoatingSubstrate_Variance_Fields>;
};


/** aggregate fields of "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_MaintenanceCoatingSubstrate_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Aggregate_Order_By = {
  avg?: Maybe<R_MaintenanceCoatingSubstrate_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_MaintenanceCoatingSubstrate_Max_Order_By>;
  min?: Maybe<R_MaintenanceCoatingSubstrate_Min_Order_By>;
  stddev?: Maybe<R_MaintenanceCoatingSubstrate_Stddev_Order_By>;
  stddev_pop?: Maybe<R_MaintenanceCoatingSubstrate_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_MaintenanceCoatingSubstrate_Stddev_Samp_Order_By>;
  sum?: Maybe<R_MaintenanceCoatingSubstrate_Sum_Order_By>;
  var_pop?: Maybe<R_MaintenanceCoatingSubstrate_Var_Pop_Order_By>;
  var_samp?: Maybe<R_MaintenanceCoatingSubstrate_Var_Samp_Order_By>;
  variance?: Maybe<R_MaintenanceCoatingSubstrate_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_MaintenanceCoatingSubstrate_Avg_Fields = {
  __typename?: 'R_MaintenanceCoatingSubstrate_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_MaintenanceCoatingSubstrate". All fields are combined with a logical 'AND'. */
export type R_MaintenanceCoatingSubstrate_Bool_Exp = {
  C_MaintenanceCoatingSubstrate?: Maybe<C_MaintenanceCoatingSubstrate_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  MaintenanceCoating?: Maybe<MaintenanceCoating_Bool_Exp>;
  MaintenanceCoatingSubstrateId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_MaintenanceCoatingSubstrate_Bool_Exp>>;
  _not?: Maybe<R_MaintenanceCoatingSubstrate_Bool_Exp>;
  _or?: Maybe<Array<R_MaintenanceCoatingSubstrate_Bool_Exp>>;
};

/** upsert condition type for table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_If_Matched = {
  match_columns?: Array<R_MaintenanceCoatingSubstrate_Insert_Match_Column>;
  update_columns?: Array<R_MaintenanceCoatingSubstrate_Update_Column>;
  where?: Maybe<R_MaintenanceCoatingSubstrate_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_MaintenanceCoatingSubstrate" */
export enum R_MaintenanceCoatingSubstrate_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MaintenanceCoatingSubstrateId = 'MaintenanceCoatingSubstrateId'
}

/** aggregate max on columns */
export type R_MaintenanceCoatingSubstrate_Max_Fields = {
  __typename?: 'R_MaintenanceCoatingSubstrate_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_MaintenanceCoatingSubstrate_Min_Fields = {
  __typename?: 'R_MaintenanceCoatingSubstrate_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Mutation_Response = {
  __typename?: 'R_MaintenanceCoatingSubstrate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_MaintenanceCoatingSubstrate>;
};

/** Ordering options when selecting data from "R_MaintenanceCoatingSubstrate". */
export type R_MaintenanceCoatingSubstrate_Order_By = {
  C_MaintenanceCoatingSubstrate?: Maybe<C_MaintenanceCoatingSubstrate_Order_By>;
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoating?: Maybe<MaintenanceCoating_Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_MaintenanceCoatingSubstrate */
export type R_MaintenanceCoatingSubstrate_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  MaintenanceCoatingSubstrateId: Scalars['Int'];
};

/** select columns of table "R_MaintenanceCoatingSubstrate" */
export enum R_MaintenanceCoatingSubstrate_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MaintenanceCoatingSubstrateId = 'MaintenanceCoatingSubstrateId'
}

/** input type for updating data in table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_MaintenanceCoatingSubstrate_Stddev_Fields = {
  __typename?: 'R_MaintenanceCoatingSubstrate_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_MaintenanceCoatingSubstrate_Stddev_Pop_Fields = {
  __typename?: 'R_MaintenanceCoatingSubstrate_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_MaintenanceCoatingSubstrate_Stddev_Samp_Fields = {
  __typename?: 'R_MaintenanceCoatingSubstrate_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_MaintenanceCoatingSubstrate_Sum_Fields = {
  __typename?: 'R_MaintenanceCoatingSubstrate_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** update columns of table "R_MaintenanceCoatingSubstrate" */
export enum R_MaintenanceCoatingSubstrate_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  MaintenanceCoatingSubstrateId = 'MaintenanceCoatingSubstrateId'
}

/** aggregate var_pop on columns */
export type R_MaintenanceCoatingSubstrate_Var_Pop_Fields = {
  __typename?: 'R_MaintenanceCoatingSubstrate_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_MaintenanceCoatingSubstrate_Var_Samp_Fields = {
  __typename?: 'R_MaintenanceCoatingSubstrate_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_MaintenanceCoatingSubstrate_Variance_Fields = {
  __typename?: 'R_MaintenanceCoatingSubstrate_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  MaintenanceCoatingSubstrateId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_MaintenanceCoatingSubstrate" */
export type R_MaintenanceCoatingSubstrate_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  MaintenanceCoatingSubstrateId?: Maybe<Order_By>;
};

/** columns and relationships of "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig = {
  __typename?: 'R_NavAssembly_NavMultiplyCoverConfig';
  /** An object relationship */
  NavAssembly: NavAssembly;
  NavAssemblyId: Scalars['Int'];
  /** An object relationship */
  NavMultiplyCoverConfig: NavMultiplyCoverConfig;
  NavMultiplyCoverConfigId: Scalars['Int'];
  SubassemblyComponentId: Scalars['Int'];
};

/** aggregated selection of "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Aggregate = {
  __typename?: 'R_NavAssembly_NavMultiplyCoverConfig_aggregate';
  aggregate?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Aggregate_Fields>;
  nodes: Array<R_NavAssembly_NavMultiplyCoverConfig>;
};

/** aggregate fields of "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Aggregate_Fields = {
  __typename?: 'R_NavAssembly_NavMultiplyCoverConfig_aggregate_fields';
  avg?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Max_Fields>;
  min?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Min_Fields>;
  stddev?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Stddev_Fields>;
  stddev_pop?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Stddev_Samp_Fields>;
  sum?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Sum_Fields>;
  var_pop?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Var_Pop_Fields>;
  var_samp?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Var_Samp_Fields>;
  variance?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Variance_Fields>;
};


/** aggregate fields of "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Aggregate_Order_By = {
  avg?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Max_Order_By>;
  min?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Min_Order_By>;
  stddev?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Stddev_Order_By>;
  stddev_pop?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Stddev_Samp_Order_By>;
  sum?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Sum_Order_By>;
  var_pop?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Var_Pop_Order_By>;
  var_samp?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Var_Samp_Order_By>;
  variance?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_NavAssembly_NavMultiplyCoverConfig_Avg_Fields = {
  __typename?: 'R_NavAssembly_NavMultiplyCoverConfig_avg_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Avg_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_NavAssembly_NavMultiplyCoverConfig". All fields are combined with a logical 'AND'. */
export type R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp = {
  NavAssembly?: Maybe<NavAssembly_Bool_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavMultiplyCoverConfig?: Maybe<NavMultiplyCoverConfig_Bool_Exp>;
  NavMultiplyCoverConfigId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubassemblyComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp>>;
  _not?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp>;
  _or?: Maybe<Array<R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp>>;
};

/** upsert condition type for table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_If_Matched = {
  match_columns?: Array<R_NavAssembly_NavMultiplyCoverConfig_Insert_Match_Column>;
  update_columns?: Array<R_NavAssembly_NavMultiplyCoverConfig_Update_Column>;
  where?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Inc_Input = {
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Insert_Input = {
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_NavAssembly_NavMultiplyCoverConfig" */
export enum R_NavAssembly_NavMultiplyCoverConfig_Insert_Match_Column {
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavMultiplyCoverConfigId = 'NavMultiplyCoverConfigId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** aggregate max on columns */
export type R_NavAssembly_NavMultiplyCoverConfig_Max_Fields = {
  __typename?: 'R_NavAssembly_NavMultiplyCoverConfig_max_fields';
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Max_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_NavAssembly_NavMultiplyCoverConfig_Min_Fields = {
  __typename?: 'R_NavAssembly_NavMultiplyCoverConfig_min_fields';
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Min_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Mutation_Response = {
  __typename?: 'R_NavAssembly_NavMultiplyCoverConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_NavAssembly_NavMultiplyCoverConfig>;
};

/** Ordering options when selecting data from "R_NavAssembly_NavMultiplyCoverConfig". */
export type R_NavAssembly_NavMultiplyCoverConfig_Order_By = {
  NavAssembly?: Maybe<NavAssembly_Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavMultiplyCoverConfig?: Maybe<NavMultiplyCoverConfig_Order_By>;
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_NavAssembly_NavMultiplyCoverConfig */
export type R_NavAssembly_NavMultiplyCoverConfig_Pk_Columns_Input = {
  NavAssemblyId: Scalars['Int'];
  NavMultiplyCoverConfigId: Scalars['Int'];
};

/** select columns of table "R_NavAssembly_NavMultiplyCoverConfig" */
export enum R_NavAssembly_NavMultiplyCoverConfig_Select_Column {
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavMultiplyCoverConfigId = 'NavMultiplyCoverConfigId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** input type for updating data in table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Set_Input = {
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_NavAssembly_NavMultiplyCoverConfig_Stddev_Fields = {
  __typename?: 'R_NavAssembly_NavMultiplyCoverConfig_stddev_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Stddev_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_NavAssembly_NavMultiplyCoverConfig_Stddev_Pop_Fields = {
  __typename?: 'R_NavAssembly_NavMultiplyCoverConfig_stddev_pop_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Stddev_Pop_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_NavAssembly_NavMultiplyCoverConfig_Stddev_Samp_Fields = {
  __typename?: 'R_NavAssembly_NavMultiplyCoverConfig_stddev_samp_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Stddev_Samp_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_NavAssembly_NavMultiplyCoverConfig_Sum_Fields = {
  __typename?: 'R_NavAssembly_NavMultiplyCoverConfig_sum_fields';
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Sum_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** update columns of table "R_NavAssembly_NavMultiplyCoverConfig" */
export enum R_NavAssembly_NavMultiplyCoverConfig_Update_Column {
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavMultiplyCoverConfigId = 'NavMultiplyCoverConfigId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** aggregate var_pop on columns */
export type R_NavAssembly_NavMultiplyCoverConfig_Var_Pop_Fields = {
  __typename?: 'R_NavAssembly_NavMultiplyCoverConfig_var_pop_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Var_Pop_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_NavAssembly_NavMultiplyCoverConfig_Var_Samp_Fields = {
  __typename?: 'R_NavAssembly_NavMultiplyCoverConfig_var_samp_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Var_Samp_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_NavAssembly_NavMultiplyCoverConfig_Variance_Fields = {
  __typename?: 'R_NavAssembly_NavMultiplyCoverConfig_variance_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavMultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_NavAssembly_NavMultiplyCoverConfig" */
export type R_NavAssembly_NavMultiplyCoverConfig_Variance_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  NavMultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "R_ProjectContact" */
export type R_ProjectContact = {
  __typename?: 'R_ProjectContact';
  /** An object relationship */
  C_ContactType: C_ContactType;
  /** An object relationship */
  C_ProjectRole: C_ProjectRole;
  ContactInfoId: Scalars['Int'];
  ContactTypeId: Scalars['Int'];
  ProjectId: Scalars['Int'];
  ProjectRoleId: Scalars['Int'];
  /** An object relationship */
  RoofProject: RoofProject;
};

/** aggregated selection of "R_ProjectContact" */
export type R_ProjectContact_Aggregate = {
  __typename?: 'R_ProjectContact_aggregate';
  aggregate?: Maybe<R_ProjectContact_Aggregate_Fields>;
  nodes: Array<R_ProjectContact>;
};

/** aggregate fields of "R_ProjectContact" */
export type R_ProjectContact_Aggregate_Fields = {
  __typename?: 'R_ProjectContact_aggregate_fields';
  avg?: Maybe<R_ProjectContact_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_ProjectContact_Max_Fields>;
  min?: Maybe<R_ProjectContact_Min_Fields>;
  stddev?: Maybe<R_ProjectContact_Stddev_Fields>;
  stddev_pop?: Maybe<R_ProjectContact_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_ProjectContact_Stddev_Samp_Fields>;
  sum?: Maybe<R_ProjectContact_Sum_Fields>;
  var_pop?: Maybe<R_ProjectContact_Var_Pop_Fields>;
  var_samp?: Maybe<R_ProjectContact_Var_Samp_Fields>;
  variance?: Maybe<R_ProjectContact_Variance_Fields>;
};


/** aggregate fields of "R_ProjectContact" */
export type R_ProjectContact_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_ProjectContact_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_ProjectContact" */
export type R_ProjectContact_Aggregate_Order_By = {
  avg?: Maybe<R_ProjectContact_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_ProjectContact_Max_Order_By>;
  min?: Maybe<R_ProjectContact_Min_Order_By>;
  stddev?: Maybe<R_ProjectContact_Stddev_Order_By>;
  stddev_pop?: Maybe<R_ProjectContact_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_ProjectContact_Stddev_Samp_Order_By>;
  sum?: Maybe<R_ProjectContact_Sum_Order_By>;
  var_pop?: Maybe<R_ProjectContact_Var_Pop_Order_By>;
  var_samp?: Maybe<R_ProjectContact_Var_Samp_Order_By>;
  variance?: Maybe<R_ProjectContact_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_ProjectContact_Avg_Fields = {
  __typename?: 'R_ProjectContact_avg_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  ContactTypeId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  ProjectRoleId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_ProjectContact" */
export type R_ProjectContact_Avg_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_ProjectContact". All fields are combined with a logical 'AND'. */
export type R_ProjectContact_Bool_Exp = {
  C_ContactType?: Maybe<C_ContactType_Bool_Exp>;
  C_ProjectRole?: Maybe<C_ProjectRole_Bool_Exp>;
  ContactInfoId?: Maybe<Int_Mssql_Comparison_Exp>;
  ContactTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ProjectId?: Maybe<Int_Mssql_Comparison_Exp>;
  ProjectRoleId?: Maybe<Int_Mssql_Comparison_Exp>;
  RoofProject?: Maybe<RoofProject_Bool_Exp>;
  _and?: Maybe<Array<R_ProjectContact_Bool_Exp>>;
  _not?: Maybe<R_ProjectContact_Bool_Exp>;
  _or?: Maybe<Array<R_ProjectContact_Bool_Exp>>;
};

/** upsert condition type for table "R_ProjectContact" */
export type R_ProjectContact_If_Matched = {
  match_columns?: Array<R_ProjectContact_Insert_Match_Column>;
  update_columns?: Array<R_ProjectContact_Update_Column>;
  where?: Maybe<R_ProjectContact_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_ProjectContact" */
export type R_ProjectContact_Inc_Input = {
  ContactInfoId?: Maybe<Scalars['Int']>;
  ContactTypeId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
  ProjectRoleId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_ProjectContact" */
export type R_ProjectContact_Insert_Input = {
  ContactInfoId?: Maybe<Scalars['Int']>;
  ContactTypeId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
  ProjectRoleId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_ProjectContact" */
export enum R_ProjectContact_Insert_Match_Column {
  /** column name */
  ContactInfoId = 'ContactInfoId',
  /** column name */
  ContactTypeId = 'ContactTypeId',
  /** column name */
  ProjectId = 'ProjectId',
  /** column name */
  ProjectRoleId = 'ProjectRoleId'
}

/** aggregate max on columns */
export type R_ProjectContact_Max_Fields = {
  __typename?: 'R_ProjectContact_max_fields';
  ContactInfoId?: Maybe<Scalars['Int']>;
  ContactTypeId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
  ProjectRoleId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_ProjectContact" */
export type R_ProjectContact_Max_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_ProjectContact_Min_Fields = {
  __typename?: 'R_ProjectContact_min_fields';
  ContactInfoId?: Maybe<Scalars['Int']>;
  ContactTypeId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
  ProjectRoleId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_ProjectContact" */
export type R_ProjectContact_Min_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_ProjectContact" */
export type R_ProjectContact_Mutation_Response = {
  __typename?: 'R_ProjectContact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_ProjectContact>;
};

/** Ordering options when selecting data from "R_ProjectContact". */
export type R_ProjectContact_Order_By = {
  C_ContactType?: Maybe<C_ContactType_Order_By>;
  C_ProjectRole?: Maybe<C_ProjectRole_Order_By>;
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
  RoofProject?: Maybe<RoofProject_Order_By>;
};

/** primary key columns input for table: R_ProjectContact */
export type R_ProjectContact_Pk_Columns_Input = {
  ContactInfoId: Scalars['Int'];
  ProjectId: Scalars['Int'];
};

/** select columns of table "R_ProjectContact" */
export enum R_ProjectContact_Select_Column {
  /** column name */
  ContactInfoId = 'ContactInfoId',
  /** column name */
  ContactTypeId = 'ContactTypeId',
  /** column name */
  ProjectId = 'ProjectId',
  /** column name */
  ProjectRoleId = 'ProjectRoleId'
}

/** input type for updating data in table "R_ProjectContact" */
export type R_ProjectContact_Set_Input = {
  ContactInfoId?: Maybe<Scalars['Int']>;
  ContactTypeId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
  ProjectRoleId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_ProjectContact_Stddev_Fields = {
  __typename?: 'R_ProjectContact_stddev_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  ContactTypeId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  ProjectRoleId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_ProjectContact" */
export type R_ProjectContact_Stddev_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_ProjectContact_Stddev_Pop_Fields = {
  __typename?: 'R_ProjectContact_stddev_pop_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  ContactTypeId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  ProjectRoleId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_ProjectContact" */
export type R_ProjectContact_Stddev_Pop_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_ProjectContact_Stddev_Samp_Fields = {
  __typename?: 'R_ProjectContact_stddev_samp_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  ContactTypeId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  ProjectRoleId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_ProjectContact" */
export type R_ProjectContact_Stddev_Samp_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_ProjectContact_Sum_Fields = {
  __typename?: 'R_ProjectContact_sum_fields';
  ContactInfoId?: Maybe<Scalars['Int']>;
  ContactTypeId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
  ProjectRoleId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_ProjectContact" */
export type R_ProjectContact_Sum_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
};

/** update columns of table "R_ProjectContact" */
export enum R_ProjectContact_Update_Column {
  /** column name */
  ContactInfoId = 'ContactInfoId',
  /** column name */
  ContactTypeId = 'ContactTypeId',
  /** column name */
  ProjectId = 'ProjectId',
  /** column name */
  ProjectRoleId = 'ProjectRoleId'
}

/** aggregate var_pop on columns */
export type R_ProjectContact_Var_Pop_Fields = {
  __typename?: 'R_ProjectContact_var_pop_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  ContactTypeId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  ProjectRoleId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_ProjectContact" */
export type R_ProjectContact_Var_Pop_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_ProjectContact_Var_Samp_Fields = {
  __typename?: 'R_ProjectContact_var_samp_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  ContactTypeId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  ProjectRoleId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_ProjectContact" */
export type R_ProjectContact_Var_Samp_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_ProjectContact_Variance_Fields = {
  __typename?: 'R_ProjectContact_variance_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  ContactTypeId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  ProjectRoleId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_ProjectContact" */
export type R_ProjectContact_Variance_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
};

/** columns and relationships of "R_ProjectNote" */
export type R_ProjectNote = {
  __typename?: 'R_ProjectNote';
  /** An object relationship */
  Note: Note;
  NoteId: Scalars['Int'];
  ProjectId: Scalars['Int'];
  /** An object relationship */
  RoofProject: RoofProject;
};

/** aggregated selection of "R_ProjectNote" */
export type R_ProjectNote_Aggregate = {
  __typename?: 'R_ProjectNote_aggregate';
  aggregate?: Maybe<R_ProjectNote_Aggregate_Fields>;
  nodes: Array<R_ProjectNote>;
};

/** aggregate fields of "R_ProjectNote" */
export type R_ProjectNote_Aggregate_Fields = {
  __typename?: 'R_ProjectNote_aggregate_fields';
  avg?: Maybe<R_ProjectNote_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_ProjectNote_Max_Fields>;
  min?: Maybe<R_ProjectNote_Min_Fields>;
  stddev?: Maybe<R_ProjectNote_Stddev_Fields>;
  stddev_pop?: Maybe<R_ProjectNote_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_ProjectNote_Stddev_Samp_Fields>;
  sum?: Maybe<R_ProjectNote_Sum_Fields>;
  var_pop?: Maybe<R_ProjectNote_Var_Pop_Fields>;
  var_samp?: Maybe<R_ProjectNote_Var_Samp_Fields>;
  variance?: Maybe<R_ProjectNote_Variance_Fields>;
};


/** aggregate fields of "R_ProjectNote" */
export type R_ProjectNote_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_ProjectNote_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_ProjectNote" */
export type R_ProjectNote_Aggregate_Order_By = {
  avg?: Maybe<R_ProjectNote_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_ProjectNote_Max_Order_By>;
  min?: Maybe<R_ProjectNote_Min_Order_By>;
  stddev?: Maybe<R_ProjectNote_Stddev_Order_By>;
  stddev_pop?: Maybe<R_ProjectNote_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_ProjectNote_Stddev_Samp_Order_By>;
  sum?: Maybe<R_ProjectNote_Sum_Order_By>;
  var_pop?: Maybe<R_ProjectNote_Var_Pop_Order_By>;
  var_samp?: Maybe<R_ProjectNote_Var_Samp_Order_By>;
  variance?: Maybe<R_ProjectNote_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_ProjectNote_Avg_Fields = {
  __typename?: 'R_ProjectNote_avg_fields';
  NoteId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_ProjectNote" */
export type R_ProjectNote_Avg_Order_By = {
  NoteId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_ProjectNote". All fields are combined with a logical 'AND'. */
export type R_ProjectNote_Bool_Exp = {
  Note?: Maybe<Note_Bool_Exp>;
  NoteId?: Maybe<Int_Mssql_Comparison_Exp>;
  ProjectId?: Maybe<Int_Mssql_Comparison_Exp>;
  RoofProject?: Maybe<RoofProject_Bool_Exp>;
  _and?: Maybe<Array<R_ProjectNote_Bool_Exp>>;
  _not?: Maybe<R_ProjectNote_Bool_Exp>;
  _or?: Maybe<Array<R_ProjectNote_Bool_Exp>>;
};

/** upsert condition type for table "R_ProjectNote" */
export type R_ProjectNote_If_Matched = {
  match_columns?: Array<R_ProjectNote_Insert_Match_Column>;
  update_columns?: Array<R_ProjectNote_Update_Column>;
  where?: Maybe<R_ProjectNote_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_ProjectNote" */
export type R_ProjectNote_Inc_Input = {
  NoteId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_ProjectNote" */
export type R_ProjectNote_Insert_Input = {
  NoteId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_ProjectNote" */
export enum R_ProjectNote_Insert_Match_Column {
  /** column name */
  NoteId = 'NoteId',
  /** column name */
  ProjectId = 'ProjectId'
}

/** aggregate max on columns */
export type R_ProjectNote_Max_Fields = {
  __typename?: 'R_ProjectNote_max_fields';
  NoteId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_ProjectNote" */
export type R_ProjectNote_Max_Order_By = {
  NoteId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_ProjectNote_Min_Fields = {
  __typename?: 'R_ProjectNote_min_fields';
  NoteId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_ProjectNote" */
export type R_ProjectNote_Min_Order_By = {
  NoteId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_ProjectNote" */
export type R_ProjectNote_Mutation_Response = {
  __typename?: 'R_ProjectNote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_ProjectNote>;
};

/** Ordering options when selecting data from "R_ProjectNote". */
export type R_ProjectNote_Order_By = {
  Note?: Maybe<Note_Order_By>;
  NoteId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  RoofProject?: Maybe<RoofProject_Order_By>;
};

/** primary key columns input for table: R_ProjectNote */
export type R_ProjectNote_Pk_Columns_Input = {
  NoteId: Scalars['Int'];
  ProjectId: Scalars['Int'];
};

/** select columns of table "R_ProjectNote" */
export enum R_ProjectNote_Select_Column {
  /** column name */
  NoteId = 'NoteId',
  /** column name */
  ProjectId = 'ProjectId'
}

/** input type for updating data in table "R_ProjectNote" */
export type R_ProjectNote_Set_Input = {
  NoteId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_ProjectNote_Stddev_Fields = {
  __typename?: 'R_ProjectNote_stddev_fields';
  NoteId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_ProjectNote" */
export type R_ProjectNote_Stddev_Order_By = {
  NoteId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_ProjectNote_Stddev_Pop_Fields = {
  __typename?: 'R_ProjectNote_stddev_pop_fields';
  NoteId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_ProjectNote" */
export type R_ProjectNote_Stddev_Pop_Order_By = {
  NoteId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_ProjectNote_Stddev_Samp_Fields = {
  __typename?: 'R_ProjectNote_stddev_samp_fields';
  NoteId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_ProjectNote" */
export type R_ProjectNote_Stddev_Samp_Order_By = {
  NoteId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_ProjectNote_Sum_Fields = {
  __typename?: 'R_ProjectNote_sum_fields';
  NoteId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_ProjectNote" */
export type R_ProjectNote_Sum_Order_By = {
  NoteId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
};

/** update columns of table "R_ProjectNote" */
export enum R_ProjectNote_Update_Column {
  /** column name */
  NoteId = 'NoteId',
  /** column name */
  ProjectId = 'ProjectId'
}

/** aggregate var_pop on columns */
export type R_ProjectNote_Var_Pop_Fields = {
  __typename?: 'R_ProjectNote_var_pop_fields';
  NoteId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_ProjectNote" */
export type R_ProjectNote_Var_Pop_Order_By = {
  NoteId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_ProjectNote_Var_Samp_Fields = {
  __typename?: 'R_ProjectNote_var_samp_fields';
  NoteId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_ProjectNote" */
export type R_ProjectNote_Var_Samp_Order_By = {
  NoteId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_ProjectNote_Variance_Fields = {
  __typename?: 'R_ProjectNote_variance_fields';
  NoteId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_ProjectNote" */
export type R_ProjectNote_Variance_Order_By = {
  NoteId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
};

/** columns and relationships of "R_RegletFastener" */
export type R_RegletFastener = {
  __typename?: 'R_RegletFastener';
  /** An object relationship */
  Fastener: Fastener;
  FastenerComponentId: Scalars['Int'];
  /** An object relationship */
  Reglet: Reglet;
  RegletComponentId: Scalars['Int'];
};

/** aggregated selection of "R_RegletFastener" */
export type R_RegletFastener_Aggregate = {
  __typename?: 'R_RegletFastener_aggregate';
  aggregate?: Maybe<R_RegletFastener_Aggregate_Fields>;
  nodes: Array<R_RegletFastener>;
};

/** aggregate fields of "R_RegletFastener" */
export type R_RegletFastener_Aggregate_Fields = {
  __typename?: 'R_RegletFastener_aggregate_fields';
  avg?: Maybe<R_RegletFastener_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_RegletFastener_Max_Fields>;
  min?: Maybe<R_RegletFastener_Min_Fields>;
  stddev?: Maybe<R_RegletFastener_Stddev_Fields>;
  stddev_pop?: Maybe<R_RegletFastener_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_RegletFastener_Stddev_Samp_Fields>;
  sum?: Maybe<R_RegletFastener_Sum_Fields>;
  var_pop?: Maybe<R_RegletFastener_Var_Pop_Fields>;
  var_samp?: Maybe<R_RegletFastener_Var_Samp_Fields>;
  variance?: Maybe<R_RegletFastener_Variance_Fields>;
};


/** aggregate fields of "R_RegletFastener" */
export type R_RegletFastener_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_RegletFastener_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_RegletFastener" */
export type R_RegletFastener_Aggregate_Order_By = {
  avg?: Maybe<R_RegletFastener_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_RegletFastener_Max_Order_By>;
  min?: Maybe<R_RegletFastener_Min_Order_By>;
  stddev?: Maybe<R_RegletFastener_Stddev_Order_By>;
  stddev_pop?: Maybe<R_RegletFastener_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_RegletFastener_Stddev_Samp_Order_By>;
  sum?: Maybe<R_RegletFastener_Sum_Order_By>;
  var_pop?: Maybe<R_RegletFastener_Var_Pop_Order_By>;
  var_samp?: Maybe<R_RegletFastener_Var_Samp_Order_By>;
  variance?: Maybe<R_RegletFastener_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_RegletFastener_Avg_Fields = {
  __typename?: 'R_RegletFastener_avg_fields';
  FastenerComponentId?: Maybe<Scalars['Float']>;
  RegletComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_RegletFastener" */
export type R_RegletFastener_Avg_Order_By = {
  FastenerComponentId?: Maybe<Order_By>;
  RegletComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_RegletFastener". All fields are combined with a logical 'AND'. */
export type R_RegletFastener_Bool_Exp = {
  Fastener?: Maybe<Fastener_Bool_Exp>;
  FastenerComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  Reglet?: Maybe<Reglet_Bool_Exp>;
  RegletComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_RegletFastener_Bool_Exp>>;
  _not?: Maybe<R_RegletFastener_Bool_Exp>;
  _or?: Maybe<Array<R_RegletFastener_Bool_Exp>>;
};

/** upsert condition type for table "R_RegletFastener" */
export type R_RegletFastener_If_Matched = {
  match_columns?: Array<R_RegletFastener_Insert_Match_Column>;
  update_columns?: Array<R_RegletFastener_Update_Column>;
  where?: Maybe<R_RegletFastener_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_RegletFastener" */
export type R_RegletFastener_Inc_Input = {
  FastenerComponentId?: Maybe<Scalars['Int']>;
  RegletComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_RegletFastener" */
export type R_RegletFastener_Insert_Input = {
  FastenerComponentId?: Maybe<Scalars['Int']>;
  RegletComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_RegletFastener" */
export enum R_RegletFastener_Insert_Match_Column {
  /** column name */
  FastenerComponentId = 'FastenerComponentId',
  /** column name */
  RegletComponentId = 'RegletComponentId'
}

/** aggregate max on columns */
export type R_RegletFastener_Max_Fields = {
  __typename?: 'R_RegletFastener_max_fields';
  FastenerComponentId?: Maybe<Scalars['Int']>;
  RegletComponentId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_RegletFastener" */
export type R_RegletFastener_Max_Order_By = {
  FastenerComponentId?: Maybe<Order_By>;
  RegletComponentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_RegletFastener_Min_Fields = {
  __typename?: 'R_RegletFastener_min_fields';
  FastenerComponentId?: Maybe<Scalars['Int']>;
  RegletComponentId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_RegletFastener" */
export type R_RegletFastener_Min_Order_By = {
  FastenerComponentId?: Maybe<Order_By>;
  RegletComponentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_RegletFastener" */
export type R_RegletFastener_Mutation_Response = {
  __typename?: 'R_RegletFastener_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_RegletFastener>;
};

/** Ordering options when selecting data from "R_RegletFastener". */
export type R_RegletFastener_Order_By = {
  Fastener?: Maybe<Fastener_Order_By>;
  FastenerComponentId?: Maybe<Order_By>;
  Reglet?: Maybe<Reglet_Order_By>;
  RegletComponentId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_RegletFastener */
export type R_RegletFastener_Pk_Columns_Input = {
  FastenerComponentId: Scalars['Int'];
  RegletComponentId: Scalars['Int'];
};

/** select columns of table "R_RegletFastener" */
export enum R_RegletFastener_Select_Column {
  /** column name */
  FastenerComponentId = 'FastenerComponentId',
  /** column name */
  RegletComponentId = 'RegletComponentId'
}

/** input type for updating data in table "R_RegletFastener" */
export type R_RegletFastener_Set_Input = {
  FastenerComponentId?: Maybe<Scalars['Int']>;
  RegletComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_RegletFastener_Stddev_Fields = {
  __typename?: 'R_RegletFastener_stddev_fields';
  FastenerComponentId?: Maybe<Scalars['Float']>;
  RegletComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_RegletFastener" */
export type R_RegletFastener_Stddev_Order_By = {
  FastenerComponentId?: Maybe<Order_By>;
  RegletComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_RegletFastener_Stddev_Pop_Fields = {
  __typename?: 'R_RegletFastener_stddev_pop_fields';
  FastenerComponentId?: Maybe<Scalars['Float']>;
  RegletComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_RegletFastener" */
export type R_RegletFastener_Stddev_Pop_Order_By = {
  FastenerComponentId?: Maybe<Order_By>;
  RegletComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_RegletFastener_Stddev_Samp_Fields = {
  __typename?: 'R_RegletFastener_stddev_samp_fields';
  FastenerComponentId?: Maybe<Scalars['Float']>;
  RegletComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_RegletFastener" */
export type R_RegletFastener_Stddev_Samp_Order_By = {
  FastenerComponentId?: Maybe<Order_By>;
  RegletComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_RegletFastener_Sum_Fields = {
  __typename?: 'R_RegletFastener_sum_fields';
  FastenerComponentId?: Maybe<Scalars['Int']>;
  RegletComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_RegletFastener" */
export type R_RegletFastener_Sum_Order_By = {
  FastenerComponentId?: Maybe<Order_By>;
  RegletComponentId?: Maybe<Order_By>;
};

/** update columns of table "R_RegletFastener" */
export enum R_RegletFastener_Update_Column {
  /** column name */
  FastenerComponentId = 'FastenerComponentId',
  /** column name */
  RegletComponentId = 'RegletComponentId'
}

/** aggregate var_pop on columns */
export type R_RegletFastener_Var_Pop_Fields = {
  __typename?: 'R_RegletFastener_var_pop_fields';
  FastenerComponentId?: Maybe<Scalars['Float']>;
  RegletComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_RegletFastener" */
export type R_RegletFastener_Var_Pop_Order_By = {
  FastenerComponentId?: Maybe<Order_By>;
  RegletComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_RegletFastener_Var_Samp_Fields = {
  __typename?: 'R_RegletFastener_var_samp_fields';
  FastenerComponentId?: Maybe<Scalars['Float']>;
  RegletComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_RegletFastener" */
export type R_RegletFastener_Var_Samp_Order_By = {
  FastenerComponentId?: Maybe<Order_By>;
  RegletComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_RegletFastener_Variance_Fields = {
  __typename?: 'R_RegletFastener_variance_fields';
  FastenerComponentId?: Maybe<Scalars['Float']>;
  RegletComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_RegletFastener" */
export type R_RegletFastener_Variance_Order_By = {
  FastenerComponentId?: Maybe<Order_By>;
  RegletComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "R_RoofAreaContact" */
export type R_RoofAreaContact = {
  __typename?: 'R_RoofAreaContact';
  /** An object relationship */
  C_ContactType: C_ContactType;
  /** An object relationship */
  C_ProjectRole: C_ProjectRole;
  /** An object relationship */
  ContactInfo: ContactInfo;
  ContactInfoId: Scalars['Int'];
  ContactTypeId: Scalars['Int'];
  ProjectRoleId: Scalars['Int'];
  /** An object relationship */
  RoofArea: RoofArea;
  RoofAreaId: Scalars['Int'];
};

/** aggregated selection of "R_RoofAreaContact" */
export type R_RoofAreaContact_Aggregate = {
  __typename?: 'R_RoofAreaContact_aggregate';
  aggregate?: Maybe<R_RoofAreaContact_Aggregate_Fields>;
  nodes: Array<R_RoofAreaContact>;
};

/** aggregate fields of "R_RoofAreaContact" */
export type R_RoofAreaContact_Aggregate_Fields = {
  __typename?: 'R_RoofAreaContact_aggregate_fields';
  avg?: Maybe<R_RoofAreaContact_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_RoofAreaContact_Max_Fields>;
  min?: Maybe<R_RoofAreaContact_Min_Fields>;
  stddev?: Maybe<R_RoofAreaContact_Stddev_Fields>;
  stddev_pop?: Maybe<R_RoofAreaContact_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_RoofAreaContact_Stddev_Samp_Fields>;
  sum?: Maybe<R_RoofAreaContact_Sum_Fields>;
  var_pop?: Maybe<R_RoofAreaContact_Var_Pop_Fields>;
  var_samp?: Maybe<R_RoofAreaContact_Var_Samp_Fields>;
  variance?: Maybe<R_RoofAreaContact_Variance_Fields>;
};


/** aggregate fields of "R_RoofAreaContact" */
export type R_RoofAreaContact_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_RoofAreaContact_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_RoofAreaContact" */
export type R_RoofAreaContact_Aggregate_Order_By = {
  avg?: Maybe<R_RoofAreaContact_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_RoofAreaContact_Max_Order_By>;
  min?: Maybe<R_RoofAreaContact_Min_Order_By>;
  stddev?: Maybe<R_RoofAreaContact_Stddev_Order_By>;
  stddev_pop?: Maybe<R_RoofAreaContact_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_RoofAreaContact_Stddev_Samp_Order_By>;
  sum?: Maybe<R_RoofAreaContact_Sum_Order_By>;
  var_pop?: Maybe<R_RoofAreaContact_Var_Pop_Order_By>;
  var_samp?: Maybe<R_RoofAreaContact_Var_Samp_Order_By>;
  variance?: Maybe<R_RoofAreaContact_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_RoofAreaContact_Avg_Fields = {
  __typename?: 'R_RoofAreaContact_avg_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  ContactTypeId?: Maybe<Scalars['Float']>;
  ProjectRoleId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_RoofAreaContact" */
export type R_RoofAreaContact_Avg_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_RoofAreaContact". All fields are combined with a logical 'AND'. */
export type R_RoofAreaContact_Bool_Exp = {
  C_ContactType?: Maybe<C_ContactType_Bool_Exp>;
  C_ProjectRole?: Maybe<C_ProjectRole_Bool_Exp>;
  ContactInfo?: Maybe<ContactInfo_Bool_Exp>;
  ContactInfoId?: Maybe<Int_Mssql_Comparison_Exp>;
  ContactTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ProjectRoleId?: Maybe<Int_Mssql_Comparison_Exp>;
  RoofArea?: Maybe<RoofArea_Bool_Exp>;
  RoofAreaId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_RoofAreaContact_Bool_Exp>>;
  _not?: Maybe<R_RoofAreaContact_Bool_Exp>;
  _or?: Maybe<Array<R_RoofAreaContact_Bool_Exp>>;
};

/** upsert condition type for table "R_RoofAreaContact" */
export type R_RoofAreaContact_If_Matched = {
  match_columns?: Array<R_RoofAreaContact_Insert_Match_Column>;
  update_columns?: Array<R_RoofAreaContact_Update_Column>;
  where?: Maybe<R_RoofAreaContact_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_RoofAreaContact" */
export type R_RoofAreaContact_Inc_Input = {
  ContactInfoId?: Maybe<Scalars['Int']>;
  ContactTypeId?: Maybe<Scalars['Int']>;
  ProjectRoleId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_RoofAreaContact" */
export type R_RoofAreaContact_Insert_Input = {
  ContactInfoId?: Maybe<Scalars['Int']>;
  ContactTypeId?: Maybe<Scalars['Int']>;
  ProjectRoleId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_RoofAreaContact" */
export enum R_RoofAreaContact_Insert_Match_Column {
  /** column name */
  ContactInfoId = 'ContactInfoId',
  /** column name */
  ContactTypeId = 'ContactTypeId',
  /** column name */
  ProjectRoleId = 'ProjectRoleId',
  /** column name */
  RoofAreaId = 'RoofAreaId'
}

/** aggregate max on columns */
export type R_RoofAreaContact_Max_Fields = {
  __typename?: 'R_RoofAreaContact_max_fields';
  ContactInfoId?: Maybe<Scalars['Int']>;
  ContactTypeId?: Maybe<Scalars['Int']>;
  ProjectRoleId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_RoofAreaContact" */
export type R_RoofAreaContact_Max_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_RoofAreaContact_Min_Fields = {
  __typename?: 'R_RoofAreaContact_min_fields';
  ContactInfoId?: Maybe<Scalars['Int']>;
  ContactTypeId?: Maybe<Scalars['Int']>;
  ProjectRoleId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_RoofAreaContact" */
export type R_RoofAreaContact_Min_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_RoofAreaContact" */
export type R_RoofAreaContact_Mutation_Response = {
  __typename?: 'R_RoofAreaContact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_RoofAreaContact>;
};

/** Ordering options when selecting data from "R_RoofAreaContact". */
export type R_RoofAreaContact_Order_By = {
  C_ContactType?: Maybe<C_ContactType_Order_By>;
  C_ProjectRole?: Maybe<C_ProjectRole_Order_By>;
  ContactInfo?: Maybe<ContactInfo_Order_By>;
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
  RoofArea?: Maybe<RoofArea_Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_RoofAreaContact */
export type R_RoofAreaContact_Pk_Columns_Input = {
  ContactInfoId: Scalars['Int'];
  ProjectRoleId: Scalars['Int'];
  RoofAreaId: Scalars['Int'];
};

/** select columns of table "R_RoofAreaContact" */
export enum R_RoofAreaContact_Select_Column {
  /** column name */
  ContactInfoId = 'ContactInfoId',
  /** column name */
  ContactTypeId = 'ContactTypeId',
  /** column name */
  ProjectRoleId = 'ProjectRoleId',
  /** column name */
  RoofAreaId = 'RoofAreaId'
}

/** input type for updating data in table "R_RoofAreaContact" */
export type R_RoofAreaContact_Set_Input = {
  ContactInfoId?: Maybe<Scalars['Int']>;
  ContactTypeId?: Maybe<Scalars['Int']>;
  ProjectRoleId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_RoofAreaContact_Stddev_Fields = {
  __typename?: 'R_RoofAreaContact_stddev_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  ContactTypeId?: Maybe<Scalars['Float']>;
  ProjectRoleId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_RoofAreaContact" */
export type R_RoofAreaContact_Stddev_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_RoofAreaContact_Stddev_Pop_Fields = {
  __typename?: 'R_RoofAreaContact_stddev_pop_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  ContactTypeId?: Maybe<Scalars['Float']>;
  ProjectRoleId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_RoofAreaContact" */
export type R_RoofAreaContact_Stddev_Pop_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_RoofAreaContact_Stddev_Samp_Fields = {
  __typename?: 'R_RoofAreaContact_stddev_samp_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  ContactTypeId?: Maybe<Scalars['Float']>;
  ProjectRoleId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_RoofAreaContact" */
export type R_RoofAreaContact_Stddev_Samp_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_RoofAreaContact_Sum_Fields = {
  __typename?: 'R_RoofAreaContact_sum_fields';
  ContactInfoId?: Maybe<Scalars['Int']>;
  ContactTypeId?: Maybe<Scalars['Int']>;
  ProjectRoleId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_RoofAreaContact" */
export type R_RoofAreaContact_Sum_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** update columns of table "R_RoofAreaContact" */
export enum R_RoofAreaContact_Update_Column {
  /** column name */
  ContactInfoId = 'ContactInfoId',
  /** column name */
  ContactTypeId = 'ContactTypeId',
  /** column name */
  ProjectRoleId = 'ProjectRoleId',
  /** column name */
  RoofAreaId = 'RoofAreaId'
}

/** aggregate var_pop on columns */
export type R_RoofAreaContact_Var_Pop_Fields = {
  __typename?: 'R_RoofAreaContact_var_pop_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  ContactTypeId?: Maybe<Scalars['Float']>;
  ProjectRoleId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_RoofAreaContact" */
export type R_RoofAreaContact_Var_Pop_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_RoofAreaContact_Var_Samp_Fields = {
  __typename?: 'R_RoofAreaContact_var_samp_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  ContactTypeId?: Maybe<Scalars['Float']>;
  ProjectRoleId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_RoofAreaContact" */
export type R_RoofAreaContact_Var_Samp_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_RoofAreaContact_Variance_Fields = {
  __typename?: 'R_RoofAreaContact_variance_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  ContactTypeId?: Maybe<Scalars['Float']>;
  ProjectRoleId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_RoofAreaContact" */
export type R_RoofAreaContact_Variance_Order_By = {
  ContactInfoId?: Maybe<Order_By>;
  ContactTypeId?: Maybe<Order_By>;
  ProjectRoleId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** columns and relationships of "R_RoofAreaNote" */
export type R_RoofAreaNote = {
  __typename?: 'R_RoofAreaNote';
  /** An object relationship */
  Note: Note;
  NoteId: Scalars['Int'];
  /** An object relationship */
  RoofArea: RoofArea;
  RoofAreaId: Scalars['Int'];
};

/** aggregated selection of "R_RoofAreaNote" */
export type R_RoofAreaNote_Aggregate = {
  __typename?: 'R_RoofAreaNote_aggregate';
  aggregate?: Maybe<R_RoofAreaNote_Aggregate_Fields>;
  nodes: Array<R_RoofAreaNote>;
};

/** aggregate fields of "R_RoofAreaNote" */
export type R_RoofAreaNote_Aggregate_Fields = {
  __typename?: 'R_RoofAreaNote_aggregate_fields';
  avg?: Maybe<R_RoofAreaNote_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_RoofAreaNote_Max_Fields>;
  min?: Maybe<R_RoofAreaNote_Min_Fields>;
  stddev?: Maybe<R_RoofAreaNote_Stddev_Fields>;
  stddev_pop?: Maybe<R_RoofAreaNote_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_RoofAreaNote_Stddev_Samp_Fields>;
  sum?: Maybe<R_RoofAreaNote_Sum_Fields>;
  var_pop?: Maybe<R_RoofAreaNote_Var_Pop_Fields>;
  var_samp?: Maybe<R_RoofAreaNote_Var_Samp_Fields>;
  variance?: Maybe<R_RoofAreaNote_Variance_Fields>;
};


/** aggregate fields of "R_RoofAreaNote" */
export type R_RoofAreaNote_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_RoofAreaNote_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_RoofAreaNote" */
export type R_RoofAreaNote_Aggregate_Order_By = {
  avg?: Maybe<R_RoofAreaNote_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_RoofAreaNote_Max_Order_By>;
  min?: Maybe<R_RoofAreaNote_Min_Order_By>;
  stddev?: Maybe<R_RoofAreaNote_Stddev_Order_By>;
  stddev_pop?: Maybe<R_RoofAreaNote_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_RoofAreaNote_Stddev_Samp_Order_By>;
  sum?: Maybe<R_RoofAreaNote_Sum_Order_By>;
  var_pop?: Maybe<R_RoofAreaNote_Var_Pop_Order_By>;
  var_samp?: Maybe<R_RoofAreaNote_Var_Samp_Order_By>;
  variance?: Maybe<R_RoofAreaNote_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_RoofAreaNote_Avg_Fields = {
  __typename?: 'R_RoofAreaNote_avg_fields';
  NoteId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_RoofAreaNote" */
export type R_RoofAreaNote_Avg_Order_By = {
  NoteId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_RoofAreaNote". All fields are combined with a logical 'AND'. */
export type R_RoofAreaNote_Bool_Exp = {
  Note?: Maybe<Note_Bool_Exp>;
  NoteId?: Maybe<Int_Mssql_Comparison_Exp>;
  RoofArea?: Maybe<RoofArea_Bool_Exp>;
  RoofAreaId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_RoofAreaNote_Bool_Exp>>;
  _not?: Maybe<R_RoofAreaNote_Bool_Exp>;
  _or?: Maybe<Array<R_RoofAreaNote_Bool_Exp>>;
};

/** upsert condition type for table "R_RoofAreaNote" */
export type R_RoofAreaNote_If_Matched = {
  match_columns?: Array<R_RoofAreaNote_Insert_Match_Column>;
  update_columns?: Array<R_RoofAreaNote_Update_Column>;
  where?: Maybe<R_RoofAreaNote_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_RoofAreaNote" */
export type R_RoofAreaNote_Inc_Input = {
  NoteId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_RoofAreaNote" */
export type R_RoofAreaNote_Insert_Input = {
  NoteId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_RoofAreaNote" */
export enum R_RoofAreaNote_Insert_Match_Column {
  /** column name */
  NoteId = 'NoteId',
  /** column name */
  RoofAreaId = 'RoofAreaId'
}

/** aggregate max on columns */
export type R_RoofAreaNote_Max_Fields = {
  __typename?: 'R_RoofAreaNote_max_fields';
  NoteId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_RoofAreaNote" */
export type R_RoofAreaNote_Max_Order_By = {
  NoteId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_RoofAreaNote_Min_Fields = {
  __typename?: 'R_RoofAreaNote_min_fields';
  NoteId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_RoofAreaNote" */
export type R_RoofAreaNote_Min_Order_By = {
  NoteId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_RoofAreaNote" */
export type R_RoofAreaNote_Mutation_Response = {
  __typename?: 'R_RoofAreaNote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_RoofAreaNote>;
};

/** Ordering options when selecting data from "R_RoofAreaNote". */
export type R_RoofAreaNote_Order_By = {
  Note?: Maybe<Note_Order_By>;
  NoteId?: Maybe<Order_By>;
  RoofArea?: Maybe<RoofArea_Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_RoofAreaNote */
export type R_RoofAreaNote_Pk_Columns_Input = {
  NoteId: Scalars['Int'];
  RoofAreaId: Scalars['Int'];
};

/** select columns of table "R_RoofAreaNote" */
export enum R_RoofAreaNote_Select_Column {
  /** column name */
  NoteId = 'NoteId',
  /** column name */
  RoofAreaId = 'RoofAreaId'
}

/** input type for updating data in table "R_RoofAreaNote" */
export type R_RoofAreaNote_Set_Input = {
  NoteId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_RoofAreaNote_Stddev_Fields = {
  __typename?: 'R_RoofAreaNote_stddev_fields';
  NoteId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_RoofAreaNote" */
export type R_RoofAreaNote_Stddev_Order_By = {
  NoteId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_RoofAreaNote_Stddev_Pop_Fields = {
  __typename?: 'R_RoofAreaNote_stddev_pop_fields';
  NoteId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_RoofAreaNote" */
export type R_RoofAreaNote_Stddev_Pop_Order_By = {
  NoteId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_RoofAreaNote_Stddev_Samp_Fields = {
  __typename?: 'R_RoofAreaNote_stddev_samp_fields';
  NoteId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_RoofAreaNote" */
export type R_RoofAreaNote_Stddev_Samp_Order_By = {
  NoteId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_RoofAreaNote_Sum_Fields = {
  __typename?: 'R_RoofAreaNote_sum_fields';
  NoteId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_RoofAreaNote" */
export type R_RoofAreaNote_Sum_Order_By = {
  NoteId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** update columns of table "R_RoofAreaNote" */
export enum R_RoofAreaNote_Update_Column {
  /** column name */
  NoteId = 'NoteId',
  /** column name */
  RoofAreaId = 'RoofAreaId'
}

/** aggregate var_pop on columns */
export type R_RoofAreaNote_Var_Pop_Fields = {
  __typename?: 'R_RoofAreaNote_var_pop_fields';
  NoteId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_RoofAreaNote" */
export type R_RoofAreaNote_Var_Pop_Order_By = {
  NoteId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_RoofAreaNote_Var_Samp_Fields = {
  __typename?: 'R_RoofAreaNote_var_samp_fields';
  NoteId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_RoofAreaNote" */
export type R_RoofAreaNote_Var_Samp_Order_By = {
  NoteId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_RoofAreaNote_Variance_Fields = {
  __typename?: 'R_RoofAreaNote_variance_fields';
  NoteId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_RoofAreaNote" */
export type R_RoofAreaNote_Variance_Order_By = {
  NoteId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
};

/** columns and relationships of "R_RoofAreaScenario" */
export type R_RoofAreaScenario = {
  __typename?: 'R_RoofAreaScenario';
  /** An object relationship */
  RoofArea: RoofArea;
  RoofAreaId: Scalars['Int'];
  /** An object relationship */
  Scenario: Scenario;
  ScenarioId: Scalars['Int'];
};

/** aggregated selection of "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Aggregate = {
  __typename?: 'R_RoofAreaScenario_aggregate';
  aggregate?: Maybe<R_RoofAreaScenario_Aggregate_Fields>;
  nodes: Array<R_RoofAreaScenario>;
};

/** aggregate fields of "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Aggregate_Fields = {
  __typename?: 'R_RoofAreaScenario_aggregate_fields';
  avg?: Maybe<R_RoofAreaScenario_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_RoofAreaScenario_Max_Fields>;
  min?: Maybe<R_RoofAreaScenario_Min_Fields>;
  stddev?: Maybe<R_RoofAreaScenario_Stddev_Fields>;
  stddev_pop?: Maybe<R_RoofAreaScenario_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_RoofAreaScenario_Stddev_Samp_Fields>;
  sum?: Maybe<R_RoofAreaScenario_Sum_Fields>;
  var_pop?: Maybe<R_RoofAreaScenario_Var_Pop_Fields>;
  var_samp?: Maybe<R_RoofAreaScenario_Var_Samp_Fields>;
  variance?: Maybe<R_RoofAreaScenario_Variance_Fields>;
};


/** aggregate fields of "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_RoofAreaScenario_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Aggregate_Order_By = {
  avg?: Maybe<R_RoofAreaScenario_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_RoofAreaScenario_Max_Order_By>;
  min?: Maybe<R_RoofAreaScenario_Min_Order_By>;
  stddev?: Maybe<R_RoofAreaScenario_Stddev_Order_By>;
  stddev_pop?: Maybe<R_RoofAreaScenario_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_RoofAreaScenario_Stddev_Samp_Order_By>;
  sum?: Maybe<R_RoofAreaScenario_Sum_Order_By>;
  var_pop?: Maybe<R_RoofAreaScenario_Var_Pop_Order_By>;
  var_samp?: Maybe<R_RoofAreaScenario_Var_Samp_Order_By>;
  variance?: Maybe<R_RoofAreaScenario_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_RoofAreaScenario_Avg_Fields = {
  __typename?: 'R_RoofAreaScenario_avg_fields';
  RoofAreaId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Avg_Order_By = {
  RoofAreaId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_RoofAreaScenario". All fields are combined with a logical 'AND'. */
export type R_RoofAreaScenario_Bool_Exp = {
  RoofArea?: Maybe<RoofArea_Bool_Exp>;
  RoofAreaId?: Maybe<Int_Mssql_Comparison_Exp>;
  Scenario?: Maybe<Scenario_Bool_Exp>;
  ScenarioId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_RoofAreaScenario_Bool_Exp>>;
  _not?: Maybe<R_RoofAreaScenario_Bool_Exp>;
  _or?: Maybe<Array<R_RoofAreaScenario_Bool_Exp>>;
};

/** upsert condition type for table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_If_Matched = {
  match_columns?: Array<R_RoofAreaScenario_Insert_Match_Column>;
  update_columns?: Array<R_RoofAreaScenario_Update_Column>;
  where?: Maybe<R_RoofAreaScenario_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Inc_Input = {
  RoofAreaId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Insert_Input = {
  RoofAreaId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_RoofAreaScenario" */
export enum R_RoofAreaScenario_Insert_Match_Column {
  /** column name */
  RoofAreaId = 'RoofAreaId',
  /** column name */
  ScenarioId = 'ScenarioId'
}

/** aggregate max on columns */
export type R_RoofAreaScenario_Max_Fields = {
  __typename?: 'R_RoofAreaScenario_max_fields';
  RoofAreaId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Max_Order_By = {
  RoofAreaId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_RoofAreaScenario_Min_Fields = {
  __typename?: 'R_RoofAreaScenario_min_fields';
  RoofAreaId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Min_Order_By = {
  RoofAreaId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Mutation_Response = {
  __typename?: 'R_RoofAreaScenario_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_RoofAreaScenario>;
};

/** Ordering options when selecting data from "R_RoofAreaScenario". */
export type R_RoofAreaScenario_Order_By = {
  RoofArea?: Maybe<RoofArea_Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  Scenario?: Maybe<Scenario_Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_RoofAreaScenario */
export type R_RoofAreaScenario_Pk_Columns_Input = {
  RoofAreaId: Scalars['Int'];
  ScenarioId: Scalars['Int'];
};

/** select columns of table "R_RoofAreaScenario" */
export enum R_RoofAreaScenario_Select_Column {
  /** column name */
  RoofAreaId = 'RoofAreaId',
  /** column name */
  ScenarioId = 'ScenarioId'
}

/** input type for updating data in table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Set_Input = {
  RoofAreaId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_RoofAreaScenario_Stddev_Fields = {
  __typename?: 'R_RoofAreaScenario_stddev_fields';
  RoofAreaId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Stddev_Order_By = {
  RoofAreaId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_RoofAreaScenario_Stddev_Pop_Fields = {
  __typename?: 'R_RoofAreaScenario_stddev_pop_fields';
  RoofAreaId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Stddev_Pop_Order_By = {
  RoofAreaId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_RoofAreaScenario_Stddev_Samp_Fields = {
  __typename?: 'R_RoofAreaScenario_stddev_samp_fields';
  RoofAreaId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Stddev_Samp_Order_By = {
  RoofAreaId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_RoofAreaScenario_Sum_Fields = {
  __typename?: 'R_RoofAreaScenario_sum_fields';
  RoofAreaId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Sum_Order_By = {
  RoofAreaId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** update columns of table "R_RoofAreaScenario" */
export enum R_RoofAreaScenario_Update_Column {
  /** column name */
  RoofAreaId = 'RoofAreaId',
  /** column name */
  ScenarioId = 'ScenarioId'
}

/** aggregate var_pop on columns */
export type R_RoofAreaScenario_Var_Pop_Fields = {
  __typename?: 'R_RoofAreaScenario_var_pop_fields';
  RoofAreaId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Var_Pop_Order_By = {
  RoofAreaId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_RoofAreaScenario_Var_Samp_Fields = {
  __typename?: 'R_RoofAreaScenario_var_samp_fields';
  RoofAreaId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Var_Samp_Order_By = {
  RoofAreaId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_RoofAreaScenario_Variance_Fields = {
  __typename?: 'R_RoofAreaScenario_variance_fields';
  RoofAreaId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_RoofAreaScenario" */
export type R_RoofAreaScenario_Variance_Order_By = {
  RoofAreaId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** columns and relationships of "R_ScenarioNote" */
export type R_ScenarioNote = {
  __typename?: 'R_ScenarioNote';
  /** An object relationship */
  Note: Note;
  NoteId: Scalars['Int'];
  /** An object relationship */
  Scenario: Scenario;
  ScenarioId: Scalars['Int'];
};

/** aggregated selection of "R_ScenarioNote" */
export type R_ScenarioNote_Aggregate = {
  __typename?: 'R_ScenarioNote_aggregate';
  aggregate?: Maybe<R_ScenarioNote_Aggregate_Fields>;
  nodes: Array<R_ScenarioNote>;
};

/** aggregate fields of "R_ScenarioNote" */
export type R_ScenarioNote_Aggregate_Fields = {
  __typename?: 'R_ScenarioNote_aggregate_fields';
  avg?: Maybe<R_ScenarioNote_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_ScenarioNote_Max_Fields>;
  min?: Maybe<R_ScenarioNote_Min_Fields>;
  stddev?: Maybe<R_ScenarioNote_Stddev_Fields>;
  stddev_pop?: Maybe<R_ScenarioNote_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_ScenarioNote_Stddev_Samp_Fields>;
  sum?: Maybe<R_ScenarioNote_Sum_Fields>;
  var_pop?: Maybe<R_ScenarioNote_Var_Pop_Fields>;
  var_samp?: Maybe<R_ScenarioNote_Var_Samp_Fields>;
  variance?: Maybe<R_ScenarioNote_Variance_Fields>;
};


/** aggregate fields of "R_ScenarioNote" */
export type R_ScenarioNote_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_ScenarioNote_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_ScenarioNote" */
export type R_ScenarioNote_Aggregate_Order_By = {
  avg?: Maybe<R_ScenarioNote_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_ScenarioNote_Max_Order_By>;
  min?: Maybe<R_ScenarioNote_Min_Order_By>;
  stddev?: Maybe<R_ScenarioNote_Stddev_Order_By>;
  stddev_pop?: Maybe<R_ScenarioNote_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_ScenarioNote_Stddev_Samp_Order_By>;
  sum?: Maybe<R_ScenarioNote_Sum_Order_By>;
  var_pop?: Maybe<R_ScenarioNote_Var_Pop_Order_By>;
  var_samp?: Maybe<R_ScenarioNote_Var_Samp_Order_By>;
  variance?: Maybe<R_ScenarioNote_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_ScenarioNote_Avg_Fields = {
  __typename?: 'R_ScenarioNote_avg_fields';
  NoteId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_ScenarioNote" */
export type R_ScenarioNote_Avg_Order_By = {
  NoteId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_ScenarioNote". All fields are combined with a logical 'AND'. */
export type R_ScenarioNote_Bool_Exp = {
  Note?: Maybe<Note_Bool_Exp>;
  NoteId?: Maybe<Int_Mssql_Comparison_Exp>;
  Scenario?: Maybe<Scenario_Bool_Exp>;
  ScenarioId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_ScenarioNote_Bool_Exp>>;
  _not?: Maybe<R_ScenarioNote_Bool_Exp>;
  _or?: Maybe<Array<R_ScenarioNote_Bool_Exp>>;
};

/** upsert condition type for table "R_ScenarioNote" */
export type R_ScenarioNote_If_Matched = {
  match_columns?: Array<R_ScenarioNote_Insert_Match_Column>;
  update_columns?: Array<R_ScenarioNote_Update_Column>;
  where?: Maybe<R_ScenarioNote_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_ScenarioNote" */
export type R_ScenarioNote_Inc_Input = {
  NoteId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_ScenarioNote" */
export type R_ScenarioNote_Insert_Input = {
  NoteId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_ScenarioNote" */
export enum R_ScenarioNote_Insert_Match_Column {
  /** column name */
  NoteId = 'NoteId',
  /** column name */
  ScenarioId = 'ScenarioId'
}

/** aggregate max on columns */
export type R_ScenarioNote_Max_Fields = {
  __typename?: 'R_ScenarioNote_max_fields';
  NoteId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_ScenarioNote" */
export type R_ScenarioNote_Max_Order_By = {
  NoteId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_ScenarioNote_Min_Fields = {
  __typename?: 'R_ScenarioNote_min_fields';
  NoteId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_ScenarioNote" */
export type R_ScenarioNote_Min_Order_By = {
  NoteId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_ScenarioNote" */
export type R_ScenarioNote_Mutation_Response = {
  __typename?: 'R_ScenarioNote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_ScenarioNote>;
};

/** Ordering options when selecting data from "R_ScenarioNote". */
export type R_ScenarioNote_Order_By = {
  Note?: Maybe<Note_Order_By>;
  NoteId?: Maybe<Order_By>;
  Scenario?: Maybe<Scenario_Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_ScenarioNote */
export type R_ScenarioNote_Pk_Columns_Input = {
  NoteId: Scalars['Int'];
  ScenarioId: Scalars['Int'];
};

/** select columns of table "R_ScenarioNote" */
export enum R_ScenarioNote_Select_Column {
  /** column name */
  NoteId = 'NoteId',
  /** column name */
  ScenarioId = 'ScenarioId'
}

/** input type for updating data in table "R_ScenarioNote" */
export type R_ScenarioNote_Set_Input = {
  NoteId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_ScenarioNote_Stddev_Fields = {
  __typename?: 'R_ScenarioNote_stddev_fields';
  NoteId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_ScenarioNote" */
export type R_ScenarioNote_Stddev_Order_By = {
  NoteId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_ScenarioNote_Stddev_Pop_Fields = {
  __typename?: 'R_ScenarioNote_stddev_pop_fields';
  NoteId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_ScenarioNote" */
export type R_ScenarioNote_Stddev_Pop_Order_By = {
  NoteId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_ScenarioNote_Stddev_Samp_Fields = {
  __typename?: 'R_ScenarioNote_stddev_samp_fields';
  NoteId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_ScenarioNote" */
export type R_ScenarioNote_Stddev_Samp_Order_By = {
  NoteId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_ScenarioNote_Sum_Fields = {
  __typename?: 'R_ScenarioNote_sum_fields';
  NoteId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_ScenarioNote" */
export type R_ScenarioNote_Sum_Order_By = {
  NoteId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** update columns of table "R_ScenarioNote" */
export enum R_ScenarioNote_Update_Column {
  /** column name */
  NoteId = 'NoteId',
  /** column name */
  ScenarioId = 'ScenarioId'
}

/** aggregate var_pop on columns */
export type R_ScenarioNote_Var_Pop_Fields = {
  __typename?: 'R_ScenarioNote_var_pop_fields';
  NoteId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_ScenarioNote" */
export type R_ScenarioNote_Var_Pop_Order_By = {
  NoteId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_ScenarioNote_Var_Samp_Fields = {
  __typename?: 'R_ScenarioNote_var_samp_fields';
  NoteId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_ScenarioNote" */
export type R_ScenarioNote_Var_Samp_Order_By = {
  NoteId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_ScenarioNote_Variance_Fields = {
  __typename?: 'R_ScenarioNote_variance_fields';
  NoteId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_ScenarioNote" */
export type R_ScenarioNote_Variance_Order_By = {
  NoteId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** columns and relationships of "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives = {
  __typename?: 'R_SinglePly_BondingAdhesives';
  AdhesiveComponentId: Scalars['Int'];
  /** An object relationship */
  Component: Component;
  SinglePlyComponentId: Scalars['Int'];
  /** An object relationship */
  SinglePlyCover: SinglePlyCover;
};

/** aggregated selection of "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Aggregate = {
  __typename?: 'R_SinglePly_BondingAdhesives_aggregate';
  aggregate?: Maybe<R_SinglePly_BondingAdhesives_Aggregate_Fields>;
  nodes: Array<R_SinglePly_BondingAdhesives>;
};

/** aggregate fields of "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Aggregate_Fields = {
  __typename?: 'R_SinglePly_BondingAdhesives_aggregate_fields';
  avg?: Maybe<R_SinglePly_BondingAdhesives_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_SinglePly_BondingAdhesives_Max_Fields>;
  min?: Maybe<R_SinglePly_BondingAdhesives_Min_Fields>;
  stddev?: Maybe<R_SinglePly_BondingAdhesives_Stddev_Fields>;
  stddev_pop?: Maybe<R_SinglePly_BondingAdhesives_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_SinglePly_BondingAdhesives_Stddev_Samp_Fields>;
  sum?: Maybe<R_SinglePly_BondingAdhesives_Sum_Fields>;
  var_pop?: Maybe<R_SinglePly_BondingAdhesives_Var_Pop_Fields>;
  var_samp?: Maybe<R_SinglePly_BondingAdhesives_Var_Samp_Fields>;
  variance?: Maybe<R_SinglePly_BondingAdhesives_Variance_Fields>;
};


/** aggregate fields of "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_SinglePly_BondingAdhesives_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Aggregate_Order_By = {
  avg?: Maybe<R_SinglePly_BondingAdhesives_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_SinglePly_BondingAdhesives_Max_Order_By>;
  min?: Maybe<R_SinglePly_BondingAdhesives_Min_Order_By>;
  stddev?: Maybe<R_SinglePly_BondingAdhesives_Stddev_Order_By>;
  stddev_pop?: Maybe<R_SinglePly_BondingAdhesives_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_SinglePly_BondingAdhesives_Stddev_Samp_Order_By>;
  sum?: Maybe<R_SinglePly_BondingAdhesives_Sum_Order_By>;
  var_pop?: Maybe<R_SinglePly_BondingAdhesives_Var_Pop_Order_By>;
  var_samp?: Maybe<R_SinglePly_BondingAdhesives_Var_Samp_Order_By>;
  variance?: Maybe<R_SinglePly_BondingAdhesives_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_SinglePly_BondingAdhesives_Avg_Fields = {
  __typename?: 'R_SinglePly_BondingAdhesives_avg_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Avg_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_SinglePly_BondingAdhesives". All fields are combined with a logical 'AND'. */
export type R_SinglePly_BondingAdhesives_Bool_Exp = {
  AdhesiveComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  Component?: Maybe<Component_Bool_Exp>;
  SinglePlyComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  SinglePlyCover?: Maybe<SinglePlyCover_Bool_Exp>;
  _and?: Maybe<Array<R_SinglePly_BondingAdhesives_Bool_Exp>>;
  _not?: Maybe<R_SinglePly_BondingAdhesives_Bool_Exp>;
  _or?: Maybe<Array<R_SinglePly_BondingAdhesives_Bool_Exp>>;
};

/** upsert condition type for table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_If_Matched = {
  match_columns?: Array<R_SinglePly_BondingAdhesives_Insert_Match_Column>;
  update_columns?: Array<R_SinglePly_BondingAdhesives_Update_Column>;
  where?: Maybe<R_SinglePly_BondingAdhesives_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Inc_Input = {
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Insert_Input = {
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_SinglePly_BondingAdhesives" */
export enum R_SinglePly_BondingAdhesives_Insert_Match_Column {
  /** column name */
  AdhesiveComponentId = 'AdhesiveComponentId',
  /** column name */
  SinglePlyComponentId = 'SinglePlyComponentId'
}

/** aggregate max on columns */
export type R_SinglePly_BondingAdhesives_Max_Fields = {
  __typename?: 'R_SinglePly_BondingAdhesives_max_fields';
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Max_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_SinglePly_BondingAdhesives_Min_Fields = {
  __typename?: 'R_SinglePly_BondingAdhesives_min_fields';
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Min_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Mutation_Response = {
  __typename?: 'R_SinglePly_BondingAdhesives_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_SinglePly_BondingAdhesives>;
};

/** Ordering options when selecting data from "R_SinglePly_BondingAdhesives". */
export type R_SinglePly_BondingAdhesives_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  Component?: Maybe<Component_Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyCover?: Maybe<SinglePlyCover_Order_By>;
};

/** primary key columns input for table: R_SinglePly_BondingAdhesives */
export type R_SinglePly_BondingAdhesives_Pk_Columns_Input = {
  AdhesiveComponentId: Scalars['Int'];
  SinglePlyComponentId: Scalars['Int'];
};

/** select columns of table "R_SinglePly_BondingAdhesives" */
export enum R_SinglePly_BondingAdhesives_Select_Column {
  /** column name */
  AdhesiveComponentId = 'AdhesiveComponentId',
  /** column name */
  SinglePlyComponentId = 'SinglePlyComponentId'
}

/** input type for updating data in table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Set_Input = {
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_SinglePly_BondingAdhesives_Stddev_Fields = {
  __typename?: 'R_SinglePly_BondingAdhesives_stddev_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Stddev_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_SinglePly_BondingAdhesives_Stddev_Pop_Fields = {
  __typename?: 'R_SinglePly_BondingAdhesives_stddev_pop_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Stddev_Pop_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_SinglePly_BondingAdhesives_Stddev_Samp_Fields = {
  __typename?: 'R_SinglePly_BondingAdhesives_stddev_samp_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Stddev_Samp_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_SinglePly_BondingAdhesives_Sum_Fields = {
  __typename?: 'R_SinglePly_BondingAdhesives_sum_fields';
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Sum_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** update columns of table "R_SinglePly_BondingAdhesives" */
export enum R_SinglePly_BondingAdhesives_Update_Column {
  /** column name */
  AdhesiveComponentId = 'AdhesiveComponentId',
  /** column name */
  SinglePlyComponentId = 'SinglePlyComponentId'
}

/** aggregate var_pop on columns */
export type R_SinglePly_BondingAdhesives_Var_Pop_Fields = {
  __typename?: 'R_SinglePly_BondingAdhesives_var_pop_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Var_Pop_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_SinglePly_BondingAdhesives_Var_Samp_Fields = {
  __typename?: 'R_SinglePly_BondingAdhesives_var_samp_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Var_Samp_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_SinglePly_BondingAdhesives_Variance_Fields = {
  __typename?: 'R_SinglePly_BondingAdhesives_variance_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_SinglePly_BondingAdhesives" */
export type R_SinglePly_BondingAdhesives_Variance_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives = {
  __typename?: 'R_SinglePly_LapAdhesives';
  AdhesiveComponentId: Scalars['Int'];
  /** An object relationship */
  Component: Component;
  SinglePlyComponentId: Scalars['Int'];
  /** An object relationship */
  SinglePlyCover: SinglePlyCover;
};

/** aggregated selection of "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Aggregate = {
  __typename?: 'R_SinglePly_LapAdhesives_aggregate';
  aggregate?: Maybe<R_SinglePly_LapAdhesives_Aggregate_Fields>;
  nodes: Array<R_SinglePly_LapAdhesives>;
};

/** aggregate fields of "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Aggregate_Fields = {
  __typename?: 'R_SinglePly_LapAdhesives_aggregate_fields';
  avg?: Maybe<R_SinglePly_LapAdhesives_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_SinglePly_LapAdhesives_Max_Fields>;
  min?: Maybe<R_SinglePly_LapAdhesives_Min_Fields>;
  stddev?: Maybe<R_SinglePly_LapAdhesives_Stddev_Fields>;
  stddev_pop?: Maybe<R_SinglePly_LapAdhesives_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_SinglePly_LapAdhesives_Stddev_Samp_Fields>;
  sum?: Maybe<R_SinglePly_LapAdhesives_Sum_Fields>;
  var_pop?: Maybe<R_SinglePly_LapAdhesives_Var_Pop_Fields>;
  var_samp?: Maybe<R_SinglePly_LapAdhesives_Var_Samp_Fields>;
  variance?: Maybe<R_SinglePly_LapAdhesives_Variance_Fields>;
};


/** aggregate fields of "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_SinglePly_LapAdhesives_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Aggregate_Order_By = {
  avg?: Maybe<R_SinglePly_LapAdhesives_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_SinglePly_LapAdhesives_Max_Order_By>;
  min?: Maybe<R_SinglePly_LapAdhesives_Min_Order_By>;
  stddev?: Maybe<R_SinglePly_LapAdhesives_Stddev_Order_By>;
  stddev_pop?: Maybe<R_SinglePly_LapAdhesives_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_SinglePly_LapAdhesives_Stddev_Samp_Order_By>;
  sum?: Maybe<R_SinglePly_LapAdhesives_Sum_Order_By>;
  var_pop?: Maybe<R_SinglePly_LapAdhesives_Var_Pop_Order_By>;
  var_samp?: Maybe<R_SinglePly_LapAdhesives_Var_Samp_Order_By>;
  variance?: Maybe<R_SinglePly_LapAdhesives_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_SinglePly_LapAdhesives_Avg_Fields = {
  __typename?: 'R_SinglePly_LapAdhesives_avg_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Avg_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_SinglePly_LapAdhesives". All fields are combined with a logical 'AND'. */
export type R_SinglePly_LapAdhesives_Bool_Exp = {
  AdhesiveComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  Component?: Maybe<Component_Bool_Exp>;
  SinglePlyComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  SinglePlyCover?: Maybe<SinglePlyCover_Bool_Exp>;
  _and?: Maybe<Array<R_SinglePly_LapAdhesives_Bool_Exp>>;
  _not?: Maybe<R_SinglePly_LapAdhesives_Bool_Exp>;
  _or?: Maybe<Array<R_SinglePly_LapAdhesives_Bool_Exp>>;
};

/** upsert condition type for table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_If_Matched = {
  match_columns?: Array<R_SinglePly_LapAdhesives_Insert_Match_Column>;
  update_columns?: Array<R_SinglePly_LapAdhesives_Update_Column>;
  where?: Maybe<R_SinglePly_LapAdhesives_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Inc_Input = {
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Insert_Input = {
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_SinglePly_LapAdhesives" */
export enum R_SinglePly_LapAdhesives_Insert_Match_Column {
  /** column name */
  AdhesiveComponentId = 'AdhesiveComponentId',
  /** column name */
  SinglePlyComponentId = 'SinglePlyComponentId'
}

/** aggregate max on columns */
export type R_SinglePly_LapAdhesives_Max_Fields = {
  __typename?: 'R_SinglePly_LapAdhesives_max_fields';
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Max_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_SinglePly_LapAdhesives_Min_Fields = {
  __typename?: 'R_SinglePly_LapAdhesives_min_fields';
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Min_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Mutation_Response = {
  __typename?: 'R_SinglePly_LapAdhesives_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_SinglePly_LapAdhesives>;
};

/** Ordering options when selecting data from "R_SinglePly_LapAdhesives". */
export type R_SinglePly_LapAdhesives_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  Component?: Maybe<Component_Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
  SinglePlyCover?: Maybe<SinglePlyCover_Order_By>;
};

/** primary key columns input for table: R_SinglePly_LapAdhesives */
export type R_SinglePly_LapAdhesives_Pk_Columns_Input = {
  AdhesiveComponentId: Scalars['Int'];
  SinglePlyComponentId: Scalars['Int'];
};

/** select columns of table "R_SinglePly_LapAdhesives" */
export enum R_SinglePly_LapAdhesives_Select_Column {
  /** column name */
  AdhesiveComponentId = 'AdhesiveComponentId',
  /** column name */
  SinglePlyComponentId = 'SinglePlyComponentId'
}

/** input type for updating data in table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Set_Input = {
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_SinglePly_LapAdhesives_Stddev_Fields = {
  __typename?: 'R_SinglePly_LapAdhesives_stddev_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Stddev_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_SinglePly_LapAdhesives_Stddev_Pop_Fields = {
  __typename?: 'R_SinglePly_LapAdhesives_stddev_pop_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Stddev_Pop_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_SinglePly_LapAdhesives_Stddev_Samp_Fields = {
  __typename?: 'R_SinglePly_LapAdhesives_stddev_samp_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Stddev_Samp_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_SinglePly_LapAdhesives_Sum_Fields = {
  __typename?: 'R_SinglePly_LapAdhesives_sum_fields';
  AdhesiveComponentId?: Maybe<Scalars['Int']>;
  SinglePlyComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Sum_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** update columns of table "R_SinglePly_LapAdhesives" */
export enum R_SinglePly_LapAdhesives_Update_Column {
  /** column name */
  AdhesiveComponentId = 'AdhesiveComponentId',
  /** column name */
  SinglePlyComponentId = 'SinglePlyComponentId'
}

/** aggregate var_pop on columns */
export type R_SinglePly_LapAdhesives_Var_Pop_Fields = {
  __typename?: 'R_SinglePly_LapAdhesives_var_pop_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Var_Pop_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_SinglePly_LapAdhesives_Var_Samp_Fields = {
  __typename?: 'R_SinglePly_LapAdhesives_var_samp_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Var_Samp_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_SinglePly_LapAdhesives_Variance_Fields = {
  __typename?: 'R_SinglePly_LapAdhesives_variance_fields';
  AdhesiveComponentId?: Maybe<Scalars['Float']>;
  SinglePlyComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_SinglePly_LapAdhesives" */
export type R_SinglePly_LapAdhesives_Variance_Order_By = {
  AdhesiveComponentId?: Maybe<Order_By>;
  SinglePlyComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig = {
  __typename?: 'R_Subassembly_MultiplyCoverConfig';
  /** An object relationship */
  MultiplyCoverConfig: MultiplyCoverConfig;
  MultiplyCoverConfigId: Scalars['Int'];
  /** An object relationship */
  Subassembly: Subassembly;
  SubassemblyComponentId: Scalars['Int'];
};

/** aggregated selection of "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Aggregate = {
  __typename?: 'R_Subassembly_MultiplyCoverConfig_aggregate';
  aggregate?: Maybe<R_Subassembly_MultiplyCoverConfig_Aggregate_Fields>;
  nodes: Array<R_Subassembly_MultiplyCoverConfig>;
};

/** aggregate fields of "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Aggregate_Fields = {
  __typename?: 'R_Subassembly_MultiplyCoverConfig_aggregate_fields';
  avg?: Maybe<R_Subassembly_MultiplyCoverConfig_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_Subassembly_MultiplyCoverConfig_Max_Fields>;
  min?: Maybe<R_Subassembly_MultiplyCoverConfig_Min_Fields>;
  stddev?: Maybe<R_Subassembly_MultiplyCoverConfig_Stddev_Fields>;
  stddev_pop?: Maybe<R_Subassembly_MultiplyCoverConfig_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_Subassembly_MultiplyCoverConfig_Stddev_Samp_Fields>;
  sum?: Maybe<R_Subassembly_MultiplyCoverConfig_Sum_Fields>;
  var_pop?: Maybe<R_Subassembly_MultiplyCoverConfig_Var_Pop_Fields>;
  var_samp?: Maybe<R_Subassembly_MultiplyCoverConfig_Var_Samp_Fields>;
  variance?: Maybe<R_Subassembly_MultiplyCoverConfig_Variance_Fields>;
};


/** aggregate fields of "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_Subassembly_MultiplyCoverConfig_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Aggregate_Order_By = {
  avg?: Maybe<R_Subassembly_MultiplyCoverConfig_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<R_Subassembly_MultiplyCoverConfig_Max_Order_By>;
  min?: Maybe<R_Subassembly_MultiplyCoverConfig_Min_Order_By>;
  stddev?: Maybe<R_Subassembly_MultiplyCoverConfig_Stddev_Order_By>;
  stddev_pop?: Maybe<R_Subassembly_MultiplyCoverConfig_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<R_Subassembly_MultiplyCoverConfig_Stddev_Samp_Order_By>;
  sum?: Maybe<R_Subassembly_MultiplyCoverConfig_Sum_Order_By>;
  var_pop?: Maybe<R_Subassembly_MultiplyCoverConfig_Var_Pop_Order_By>;
  var_samp?: Maybe<R_Subassembly_MultiplyCoverConfig_Var_Samp_Order_By>;
  variance?: Maybe<R_Subassembly_MultiplyCoverConfig_Variance_Order_By>;
};

/** aggregate avg on columns */
export type R_Subassembly_MultiplyCoverConfig_Avg_Fields = {
  __typename?: 'R_Subassembly_MultiplyCoverConfig_avg_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Avg_Order_By = {
  MultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "R_Subassembly_MultiplyCoverConfig". All fields are combined with a logical 'AND'. */
export type R_Subassembly_MultiplyCoverConfig_Bool_Exp = {
  MultiplyCoverConfig?: Maybe<MultiplyCoverConfig_Bool_Exp>;
  MultiplyCoverConfigId?: Maybe<Int_Mssql_Comparison_Exp>;
  Subassembly?: Maybe<Subassembly_Bool_Exp>;
  SubassemblyComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_Subassembly_MultiplyCoverConfig_Bool_Exp>>;
  _not?: Maybe<R_Subassembly_MultiplyCoverConfig_Bool_Exp>;
  _or?: Maybe<Array<R_Subassembly_MultiplyCoverConfig_Bool_Exp>>;
};

/** upsert condition type for table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_If_Matched = {
  match_columns?: Array<R_Subassembly_MultiplyCoverConfig_Insert_Match_Column>;
  update_columns?: Array<R_Subassembly_MultiplyCoverConfig_Update_Column>;
  where?: Maybe<R_Subassembly_MultiplyCoverConfig_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Inc_Input = {
  MultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Insert_Input = {
  MultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_Subassembly_MultiplyCoverConfig" */
export enum R_Subassembly_MultiplyCoverConfig_Insert_Match_Column {
  /** column name */
  MultiplyCoverConfigId = 'MultiplyCoverConfigId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** aggregate max on columns */
export type R_Subassembly_MultiplyCoverConfig_Max_Fields = {
  __typename?: 'R_Subassembly_MultiplyCoverConfig_max_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Max_Order_By = {
  MultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type R_Subassembly_MultiplyCoverConfig_Min_Fields = {
  __typename?: 'R_Subassembly_MultiplyCoverConfig_min_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Min_Order_By = {
  MultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Mutation_Response = {
  __typename?: 'R_Subassembly_MultiplyCoverConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_Subassembly_MultiplyCoverConfig>;
};

/** Ordering options when selecting data from "R_Subassembly_MultiplyCoverConfig". */
export type R_Subassembly_MultiplyCoverConfig_Order_By = {
  MultiplyCoverConfig?: Maybe<MultiplyCoverConfig_Order_By>;
  MultiplyCoverConfigId?: Maybe<Order_By>;
  Subassembly?: Maybe<Subassembly_Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_Subassembly_MultiplyCoverConfig */
export type R_Subassembly_MultiplyCoverConfig_Pk_Columns_Input = {
  MultiplyCoverConfigId: Scalars['Int'];
  SubassemblyComponentId: Scalars['Int'];
};

/** select columns of table "R_Subassembly_MultiplyCoverConfig" */
export enum R_Subassembly_MultiplyCoverConfig_Select_Column {
  /** column name */
  MultiplyCoverConfigId = 'MultiplyCoverConfigId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** input type for updating data in table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Set_Input = {
  MultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_Subassembly_MultiplyCoverConfig_Stddev_Fields = {
  __typename?: 'R_Subassembly_MultiplyCoverConfig_stddev_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Stddev_Order_By = {
  MultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type R_Subassembly_MultiplyCoverConfig_Stddev_Pop_Fields = {
  __typename?: 'R_Subassembly_MultiplyCoverConfig_stddev_pop_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Stddev_Pop_Order_By = {
  MultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type R_Subassembly_MultiplyCoverConfig_Stddev_Samp_Fields = {
  __typename?: 'R_Subassembly_MultiplyCoverConfig_stddev_samp_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Stddev_Samp_Order_By = {
  MultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type R_Subassembly_MultiplyCoverConfig_Sum_Fields = {
  __typename?: 'R_Subassembly_MultiplyCoverConfig_sum_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Sum_Order_By = {
  MultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** update columns of table "R_Subassembly_MultiplyCoverConfig" */
export enum R_Subassembly_MultiplyCoverConfig_Update_Column {
  /** column name */
  MultiplyCoverConfigId = 'MultiplyCoverConfigId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** aggregate var_pop on columns */
export type R_Subassembly_MultiplyCoverConfig_Var_Pop_Fields = {
  __typename?: 'R_Subassembly_MultiplyCoverConfig_var_pop_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Var_Pop_Order_By = {
  MultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type R_Subassembly_MultiplyCoverConfig_Var_Samp_Fields = {
  __typename?: 'R_Subassembly_MultiplyCoverConfig_var_samp_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Var_Samp_Order_By = {
  MultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type R_Subassembly_MultiplyCoverConfig_Variance_Fields = {
  __typename?: 'R_Subassembly_MultiplyCoverConfig_variance_fields';
  MultiplyCoverConfigId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "R_Subassembly_MultiplyCoverConfig" */
export type R_Subassembly_MultiplyCoverConfig_Variance_Order_By = {
  MultiplyCoverConfigId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "R_UserContact" */
export type R_UserContact = {
  __typename?: 'R_UserContact';
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  /** An object relationship */
  ContactInfo: ContactInfo;
  ContactInfoId: Scalars['Int'];
  UserAccountId: Scalars['Int'];
};

/** aggregated selection of "R_UserContact" */
export type R_UserContact_Aggregate = {
  __typename?: 'R_UserContact_aggregate';
  aggregate?: Maybe<R_UserContact_Aggregate_Fields>;
  nodes: Array<R_UserContact>;
};

/** aggregate fields of "R_UserContact" */
export type R_UserContact_Aggregate_Fields = {
  __typename?: 'R_UserContact_aggregate_fields';
  avg?: Maybe<R_UserContact_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<R_UserContact_Max_Fields>;
  min?: Maybe<R_UserContact_Min_Fields>;
  stddev?: Maybe<R_UserContact_Stddev_Fields>;
  stddev_pop?: Maybe<R_UserContact_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<R_UserContact_Stddev_Samp_Fields>;
  sum?: Maybe<R_UserContact_Sum_Fields>;
  var_pop?: Maybe<R_UserContact_Var_Pop_Fields>;
  var_samp?: Maybe<R_UserContact_Var_Samp_Fields>;
  variance?: Maybe<R_UserContact_Variance_Fields>;
};


/** aggregate fields of "R_UserContact" */
export type R_UserContact_Aggregate_FieldsCountArgs = {
  column?: Maybe<R_UserContact_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type R_UserContact_Avg_Fields = {
  __typename?: 'R_UserContact_avg_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "R_UserContact". All fields are combined with a logical 'AND'. */
export type R_UserContact_Bool_Exp = {
  AzureB2CUserEmail?: Maybe<String_Mssql_Comparison_Exp>;
  AzureB2CUserGuid?: Maybe<String_Mssql_Comparison_Exp>;
  ContactInfo?: Maybe<ContactInfo_Bool_Exp>;
  ContactInfoId?: Maybe<Int_Mssql_Comparison_Exp>;
  UserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<R_UserContact_Bool_Exp>>;
  _not?: Maybe<R_UserContact_Bool_Exp>;
  _or?: Maybe<Array<R_UserContact_Bool_Exp>>;
};

/** upsert condition type for table "R_UserContact" */
export type R_UserContact_If_Matched = {
  match_columns?: Array<R_UserContact_Insert_Match_Column>;
  update_columns?: Array<R_UserContact_Update_Column>;
  where?: Maybe<R_UserContact_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "R_UserContact" */
export type R_UserContact_Inc_Input = {
  ContactInfoId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "R_UserContact" */
export type R_UserContact_Insert_Input = {
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  ContactInfoId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "R_UserContact" */
export enum R_UserContact_Insert_Match_Column {
  /** column name */
  AzureB2CUserEmail = 'AzureB2CUserEmail',
  /** column name */
  AzureB2CUserGuid = 'AzureB2CUserGuid',
  /** column name */
  ContactInfoId = 'ContactInfoId',
  /** column name */
  UserAccountId = 'UserAccountId'
}

/** aggregate max on columns */
export type R_UserContact_Max_Fields = {
  __typename?: 'R_UserContact_max_fields';
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  ContactInfoId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type R_UserContact_Min_Fields = {
  __typename?: 'R_UserContact_min_fields';
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  ContactInfoId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "R_UserContact" */
export type R_UserContact_Mutation_Response = {
  __typename?: 'R_UserContact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<R_UserContact>;
};

/** Ordering options when selecting data from "R_UserContact". */
export type R_UserContact_Order_By = {
  AzureB2CUserEmail?: Maybe<Order_By>;
  AzureB2CUserGuid?: Maybe<Order_By>;
  ContactInfo?: Maybe<ContactInfo_Order_By>;
  ContactInfoId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** primary key columns input for table: R_UserContact */
export type R_UserContact_Pk_Columns_Input = {
  ContactInfoId: Scalars['Int'];
  UserAccountId: Scalars['Int'];
};

/** select columns of table "R_UserContact" */
export enum R_UserContact_Select_Column {
  /** column name */
  AzureB2CUserEmail = 'AzureB2CUserEmail',
  /** column name */
  AzureB2CUserGuid = 'AzureB2CUserGuid',
  /** column name */
  ContactInfoId = 'ContactInfoId',
  /** column name */
  UserAccountId = 'UserAccountId'
}

/** input type for updating data in table "R_UserContact" */
export type R_UserContact_Set_Input = {
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  ContactInfoId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type R_UserContact_Stddev_Fields = {
  __typename?: 'R_UserContact_stddev_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type R_UserContact_Stddev_Pop_Fields = {
  __typename?: 'R_UserContact_stddev_pop_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type R_UserContact_Stddev_Samp_Fields = {
  __typename?: 'R_UserContact_stddev_samp_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type R_UserContact_Sum_Fields = {
  __typename?: 'R_UserContact_sum_fields';
  ContactInfoId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** update columns of table "R_UserContact" */
export enum R_UserContact_Update_Column {
  /** column name */
  AzureB2CUserEmail = 'AzureB2CUserEmail',
  /** column name */
  AzureB2CUserGuid = 'AzureB2CUserGuid',
  /** column name */
  ContactInfoId = 'ContactInfoId',
  /** column name */
  UserAccountId = 'UserAccountId'
}

/** aggregate var_pop on columns */
export type R_UserContact_Var_Pop_Fields = {
  __typename?: 'R_UserContact_var_pop_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type R_UserContact_Var_Samp_Fields = {
  __typename?: 'R_UserContact_var_samp_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type R_UserContact_Variance_Fields = {
  __typename?: 'R_UserContact_variance_fields';
  ContactInfoId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Reglet" */
export type Reglet = {
  __typename?: 'Reglet';
  /** An object relationship */
  C_FlashingMaterial: C_FlashingMaterial;
  ComponentId: Scalars['Int'];
  FlashingMaterialId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  MaxWindRating: Scalars['Float'];
  /** An array relationship */
  R_RegletFasteners: Array<R_RegletFastener>;
  /** An aggregate relationship */
  R_RegletFasteners_aggregate: R_RegletFastener_Aggregate;
};


/** columns and relationships of "Reglet" */
export type RegletR_RegletFastenersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RegletFastener_Order_By>>;
  where?: Maybe<R_RegletFastener_Bool_Exp>;
};


/** columns and relationships of "Reglet" */
export type RegletR_RegletFasteners_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RegletFastener_Order_By>>;
  where?: Maybe<R_RegletFastener_Bool_Exp>;
};

/** aggregated selection of "Reglet" */
export type Reglet_Aggregate = {
  __typename?: 'Reglet_aggregate';
  aggregate?: Maybe<Reglet_Aggregate_Fields>;
  nodes: Array<Reglet>;
};

/** aggregate fields of "Reglet" */
export type Reglet_Aggregate_Fields = {
  __typename?: 'Reglet_aggregate_fields';
  avg?: Maybe<Reglet_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Reglet_Max_Fields>;
  min?: Maybe<Reglet_Min_Fields>;
  stddev?: Maybe<Reglet_Stddev_Fields>;
  stddev_pop?: Maybe<Reglet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reglet_Stddev_Samp_Fields>;
  sum?: Maybe<Reglet_Sum_Fields>;
  var_pop?: Maybe<Reglet_Var_Pop_Fields>;
  var_samp?: Maybe<Reglet_Var_Samp_Fields>;
  variance?: Maybe<Reglet_Variance_Fields>;
};


/** aggregate fields of "Reglet" */
export type Reglet_Aggregate_FieldsCountArgs = {
  column?: Maybe<Reglet_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Reglet" */
export type Reglet_Aggregate_Order_By = {
  avg?: Maybe<Reglet_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Reglet_Max_Order_By>;
  min?: Maybe<Reglet_Min_Order_By>;
  stddev?: Maybe<Reglet_Stddev_Order_By>;
  stddev_pop?: Maybe<Reglet_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Reglet_Stddev_Samp_Order_By>;
  sum?: Maybe<Reglet_Sum_Order_By>;
  var_pop?: Maybe<Reglet_Var_Pop_Order_By>;
  var_samp?: Maybe<Reglet_Var_Samp_Order_By>;
  variance?: Maybe<Reglet_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Reglet_Avg_Fields = {
  __typename?: 'Reglet_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Reglet" */
export type Reglet_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Reglet". All fields are combined with a logical 'AND'. */
export type Reglet_Bool_Exp = {
  C_FlashingMaterial?: Maybe<C_FlashingMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  FlashingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  MaxWindRating?: Maybe<Float_Mssql_Comparison_Exp>;
  R_RegletFasteners?: Maybe<R_RegletFastener_Bool_Exp>;
  _and?: Maybe<Array<Reglet_Bool_Exp>>;
  _not?: Maybe<Reglet_Bool_Exp>;
  _or?: Maybe<Array<Reglet_Bool_Exp>>;
};

/** upsert condition type for table "Reglet" */
export type Reglet_If_Matched = {
  match_columns?: Array<Reglet_Insert_Match_Column>;
  update_columns?: Array<Reglet_Update_Column>;
  where?: Maybe<Reglet_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Reglet" */
export type Reglet_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "Reglet" */
export type Reglet_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "Reglet" */
export enum Reglet_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FlashingMaterialId = 'FlashingMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** aggregate max on columns */
export type Reglet_Max_Fields = {
  __typename?: 'Reglet_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "Reglet" */
export type Reglet_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Reglet_Min_Fields = {
  __typename?: 'Reglet_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "Reglet" */
export type Reglet_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** response of any mutation on the table "Reglet" */
export type Reglet_Mutation_Response = {
  __typename?: 'Reglet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reglet>;
};

/** Ordering options when selecting data from "Reglet". */
export type Reglet_Order_By = {
  C_FlashingMaterial?: Maybe<C_FlashingMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  MaxWindRating?: Maybe<Order_By>;
  R_RegletFasteners_aggregate?: Maybe<R_RegletFastener_Aggregate_Order_By>;
};

/** primary key columns input for table: Reglet */
export type Reglet_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "Reglet" */
export enum Reglet_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FlashingMaterialId = 'FlashingMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** input type for updating data in table "Reglet" */
export type Reglet_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Reglet_Stddev_Fields = {
  __typename?: 'Reglet_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Reglet" */
export type Reglet_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reglet_Stddev_Pop_Fields = {
  __typename?: 'Reglet_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Reglet" */
export type Reglet_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reglet_Stddev_Samp_Fields = {
  __typename?: 'Reglet_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Reglet" */
export type Reglet_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Reglet_Sum_Fields = {
  __typename?: 'Reglet_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FlashingMaterialId?: Maybe<Scalars['Int']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "Reglet" */
export type Reglet_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** update columns of table "Reglet" */
export enum Reglet_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FlashingMaterialId = 'FlashingMaterialId',
  /** column name */
  MaxWindRating = 'MaxWindRating'
}

/** aggregate var_pop on columns */
export type Reglet_Var_Pop_Fields = {
  __typename?: 'Reglet_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Reglet" */
export type Reglet_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reglet_Var_Samp_Fields = {
  __typename?: 'Reglet_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Reglet" */
export type Reglet_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Reglet_Variance_Fields = {
  __typename?: 'Reglet_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FlashingMaterialId?: Maybe<Scalars['Float']>;
  MaxWindRating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Reglet" */
export type Reglet_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FlashingMaterialId?: Maybe<Order_By>;
  MaxWindRating?: Maybe<Order_By>;
};

/** columns and relationships of "ReviewerWorkspace" */
export type ReviewerWorkspace = {
  __typename?: 'ReviewerWorkspace';
  AdditionalData?: Maybe<Scalars['String']>;
  /** An object relationship */
  C_ComponentType: C_ComponentType;
  /** An object relationship */
  C_WorkflowStatus: C_WorkflowStatus;
  Comments: Scalars['ItemComment'];
  ComponentTypeId: Scalars['Int'];
  DataOriginationType: Scalars['Int'];
  DateModified: Scalars['datetime'];
  DateSubmitted: Scalars['datetime'];
  /** An object relationship */
  FMUserAccount: FmUserAccount;
  HistoryChangeType: Scalars['Int'];
  ItemId: Scalars['Int'];
  ItemName: Scalars['String'];
  /** An object relationship */
  Manufacturer?: Maybe<Manufacturer>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ReviewerUserAccountId: Scalars['Int'];
  /** An array relationship */
  ReviewerWorkspaceData: Array<ReviewerWorkspaceData>;
  /** An aggregate relationship */
  ReviewerWorkspaceData_aggregate: ReviewerWorkspaceData_Aggregate;
  ReviewerWorkspaceId: Scalars['Int'];
  SubmitterUserAcountId: Scalars['Int'];
  WIPNumber?: Maybe<Scalars['String']>;
  WorkflowStatusId: Scalars['Int'];
  /** An object relationship */
  fMUserAccountBySubmitteruseracountid: FmUserAccount;
};


/** columns and relationships of "ReviewerWorkspace" */
export type ReviewerWorkspaceReviewerWorkspaceDataArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspaceData_Order_By>>;
  where?: Maybe<ReviewerWorkspaceData_Bool_Exp>;
};


/** columns and relationships of "ReviewerWorkspace" */
export type ReviewerWorkspaceReviewerWorkspaceData_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspaceData_Order_By>>;
  where?: Maybe<ReviewerWorkspaceData_Bool_Exp>;
};

/** columns and relationships of "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData = {
  __typename?: 'ReviewerWorkspaceData';
  EntityType: Scalars['String'];
  ItemDataBinary: Scalars['varbinary'];
  /** An object relationship */
  ReviewerWorkspace: ReviewerWorkspace;
  ReviewerWorkspaceDataId: Scalars['Int'];
  ReviewerWorkspaceId: Scalars['Int'];
};

/** aggregated selection of "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Aggregate = {
  __typename?: 'ReviewerWorkspaceData_aggregate';
  aggregate?: Maybe<ReviewerWorkspaceData_Aggregate_Fields>;
  nodes: Array<ReviewerWorkspaceData>;
};

/** aggregate fields of "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Aggregate_Fields = {
  __typename?: 'ReviewerWorkspaceData_aggregate_fields';
  avg?: Maybe<ReviewerWorkspaceData_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ReviewerWorkspaceData_Max_Fields>;
  min?: Maybe<ReviewerWorkspaceData_Min_Fields>;
  stddev?: Maybe<ReviewerWorkspaceData_Stddev_Fields>;
  stddev_pop?: Maybe<ReviewerWorkspaceData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ReviewerWorkspaceData_Stddev_Samp_Fields>;
  sum?: Maybe<ReviewerWorkspaceData_Sum_Fields>;
  var_pop?: Maybe<ReviewerWorkspaceData_Var_Pop_Fields>;
  var_samp?: Maybe<ReviewerWorkspaceData_Var_Samp_Fields>;
  variance?: Maybe<ReviewerWorkspaceData_Variance_Fields>;
};


/** aggregate fields of "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Aggregate_FieldsCountArgs = {
  column?: Maybe<ReviewerWorkspaceData_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Aggregate_Order_By = {
  avg?: Maybe<ReviewerWorkspaceData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ReviewerWorkspaceData_Max_Order_By>;
  min?: Maybe<ReviewerWorkspaceData_Min_Order_By>;
  stddev?: Maybe<ReviewerWorkspaceData_Stddev_Order_By>;
  stddev_pop?: Maybe<ReviewerWorkspaceData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ReviewerWorkspaceData_Stddev_Samp_Order_By>;
  sum?: Maybe<ReviewerWorkspaceData_Sum_Order_By>;
  var_pop?: Maybe<ReviewerWorkspaceData_Var_Pop_Order_By>;
  var_samp?: Maybe<ReviewerWorkspaceData_Var_Samp_Order_By>;
  variance?: Maybe<ReviewerWorkspaceData_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ReviewerWorkspaceData_Avg_Fields = {
  __typename?: 'ReviewerWorkspaceData_avg_fields';
  ReviewerWorkspaceDataId?: Maybe<Scalars['Float']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Avg_Order_By = {
  ReviewerWorkspaceDataId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ReviewerWorkspaceData". All fields are combined with a logical 'AND'. */
export type ReviewerWorkspaceData_Bool_Exp = {
  EntityType?: Maybe<String_Mssql_Comparison_Exp>;
  ItemDataBinary?: Maybe<Varbinary_Mssql_Comparison_Exp>;
  ReviewerWorkspace?: Maybe<ReviewerWorkspace_Bool_Exp>;
  ReviewerWorkspaceDataId?: Maybe<Int_Mssql_Comparison_Exp>;
  ReviewerWorkspaceId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ReviewerWorkspaceData_Bool_Exp>>;
  _not?: Maybe<ReviewerWorkspaceData_Bool_Exp>;
  _or?: Maybe<Array<ReviewerWorkspaceData_Bool_Exp>>;
};

/** upsert condition type for table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_If_Matched = {
  match_columns?: Array<ReviewerWorkspaceData_Insert_Match_Column>;
  update_columns?: Array<ReviewerWorkspaceData_Update_Column>;
  where?: Maybe<ReviewerWorkspaceData_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Inc_Input = {
  ReviewerWorkspaceId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Insert_Input = {
  EntityType?: Maybe<Scalars['String']>;
  ItemDataBinary?: Maybe<Scalars['varbinary']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ReviewerWorkspaceData" */
export enum ReviewerWorkspaceData_Insert_Match_Column {
  /** column name */
  EntityType = 'EntityType',
  /** column name */
  ItemDataBinary = 'ItemDataBinary',
  /** column name */
  ReviewerWorkspaceDataId = 'ReviewerWorkspaceDataId',
  /** column name */
  ReviewerWorkspaceId = 'ReviewerWorkspaceId'
}

/** aggregate max on columns */
export type ReviewerWorkspaceData_Max_Fields = {
  __typename?: 'ReviewerWorkspaceData_max_fields';
  EntityType?: Maybe<Scalars['String']>;
  ReviewerWorkspaceDataId?: Maybe<Scalars['Int']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Max_Order_By = {
  EntityType?: Maybe<Order_By>;
  ReviewerWorkspaceDataId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ReviewerWorkspaceData_Min_Fields = {
  __typename?: 'ReviewerWorkspaceData_min_fields';
  EntityType?: Maybe<Scalars['String']>;
  ReviewerWorkspaceDataId?: Maybe<Scalars['Int']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Min_Order_By = {
  EntityType?: Maybe<Order_By>;
  ReviewerWorkspaceDataId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Mutation_Response = {
  __typename?: 'ReviewerWorkspaceData_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReviewerWorkspaceData>;
};

/** Ordering options when selecting data from "ReviewerWorkspaceData". */
export type ReviewerWorkspaceData_Order_By = {
  EntityType?: Maybe<Order_By>;
  ItemDataBinary?: Maybe<Order_By>;
  ReviewerWorkspace?: Maybe<ReviewerWorkspace_Order_By>;
  ReviewerWorkspaceDataId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
};

/** primary key columns input for table: ReviewerWorkspaceData */
export type ReviewerWorkspaceData_Pk_Columns_Input = {
  ReviewerWorkspaceDataId: Scalars['Int'];
};

/** select columns of table "ReviewerWorkspaceData" */
export enum ReviewerWorkspaceData_Select_Column {
  /** column name */
  EntityType = 'EntityType',
  /** column name */
  ItemDataBinary = 'ItemDataBinary',
  /** column name */
  ReviewerWorkspaceDataId = 'ReviewerWorkspaceDataId',
  /** column name */
  ReviewerWorkspaceId = 'ReviewerWorkspaceId'
}

/** input type for updating data in table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Set_Input = {
  EntityType?: Maybe<Scalars['String']>;
  ItemDataBinary?: Maybe<Scalars['varbinary']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ReviewerWorkspaceData_Stddev_Fields = {
  __typename?: 'ReviewerWorkspaceData_stddev_fields';
  ReviewerWorkspaceDataId?: Maybe<Scalars['Float']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Stddev_Order_By = {
  ReviewerWorkspaceDataId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ReviewerWorkspaceData_Stddev_Pop_Fields = {
  __typename?: 'ReviewerWorkspaceData_stddev_pop_fields';
  ReviewerWorkspaceDataId?: Maybe<Scalars['Float']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Stddev_Pop_Order_By = {
  ReviewerWorkspaceDataId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ReviewerWorkspaceData_Stddev_Samp_Fields = {
  __typename?: 'ReviewerWorkspaceData_stddev_samp_fields';
  ReviewerWorkspaceDataId?: Maybe<Scalars['Float']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Stddev_Samp_Order_By = {
  ReviewerWorkspaceDataId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ReviewerWorkspaceData_Sum_Fields = {
  __typename?: 'ReviewerWorkspaceData_sum_fields';
  ReviewerWorkspaceDataId?: Maybe<Scalars['Int']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Sum_Order_By = {
  ReviewerWorkspaceDataId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
};

/** update columns of table "ReviewerWorkspaceData" */
export enum ReviewerWorkspaceData_Update_Column {
  /** column name */
  EntityType = 'EntityType',
  /** column name */
  ItemDataBinary = 'ItemDataBinary',
  /** column name */
  ReviewerWorkspaceId = 'ReviewerWorkspaceId'
}

/** aggregate var_pop on columns */
export type ReviewerWorkspaceData_Var_Pop_Fields = {
  __typename?: 'ReviewerWorkspaceData_var_pop_fields';
  ReviewerWorkspaceDataId?: Maybe<Scalars['Float']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Var_Pop_Order_By = {
  ReviewerWorkspaceDataId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ReviewerWorkspaceData_Var_Samp_Fields = {
  __typename?: 'ReviewerWorkspaceData_var_samp_fields';
  ReviewerWorkspaceDataId?: Maybe<Scalars['Float']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Var_Samp_Order_By = {
  ReviewerWorkspaceDataId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ReviewerWorkspaceData_Variance_Fields = {
  __typename?: 'ReviewerWorkspaceData_variance_fields';
  ReviewerWorkspaceDataId?: Maybe<Scalars['Float']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ReviewerWorkspaceData" */
export type ReviewerWorkspaceData_Variance_Order_By = {
  ReviewerWorkspaceDataId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
};

/** aggregated selection of "ReviewerWorkspace" */
export type ReviewerWorkspace_Aggregate = {
  __typename?: 'ReviewerWorkspace_aggregate';
  aggregate?: Maybe<ReviewerWorkspace_Aggregate_Fields>;
  nodes: Array<ReviewerWorkspace>;
};

/** aggregate fields of "ReviewerWorkspace" */
export type ReviewerWorkspace_Aggregate_Fields = {
  __typename?: 'ReviewerWorkspace_aggregate_fields';
  avg?: Maybe<ReviewerWorkspace_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ReviewerWorkspace_Max_Fields>;
  min?: Maybe<ReviewerWorkspace_Min_Fields>;
  stddev?: Maybe<ReviewerWorkspace_Stddev_Fields>;
  stddev_pop?: Maybe<ReviewerWorkspace_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ReviewerWorkspace_Stddev_Samp_Fields>;
  sum?: Maybe<ReviewerWorkspace_Sum_Fields>;
  var_pop?: Maybe<ReviewerWorkspace_Var_Pop_Fields>;
  var_samp?: Maybe<ReviewerWorkspace_Var_Samp_Fields>;
  variance?: Maybe<ReviewerWorkspace_Variance_Fields>;
};


/** aggregate fields of "ReviewerWorkspace" */
export type ReviewerWorkspace_Aggregate_FieldsCountArgs = {
  column?: Maybe<ReviewerWorkspace_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ReviewerWorkspace" */
export type ReviewerWorkspace_Aggregate_Order_By = {
  avg?: Maybe<ReviewerWorkspace_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ReviewerWorkspace_Max_Order_By>;
  min?: Maybe<ReviewerWorkspace_Min_Order_By>;
  stddev?: Maybe<ReviewerWorkspace_Stddev_Order_By>;
  stddev_pop?: Maybe<ReviewerWorkspace_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ReviewerWorkspace_Stddev_Samp_Order_By>;
  sum?: Maybe<ReviewerWorkspace_Sum_Order_By>;
  var_pop?: Maybe<ReviewerWorkspace_Var_Pop_Order_By>;
  var_samp?: Maybe<ReviewerWorkspace_Var_Samp_Order_By>;
  variance?: Maybe<ReviewerWorkspace_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ReviewerWorkspace_Avg_Fields = {
  __typename?: 'ReviewerWorkspace_avg_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
  DataOriginationType?: Maybe<Scalars['Float']>;
  HistoryChangeType?: Maybe<Scalars['Float']>;
  ItemId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ReviewerUserAccountId?: Maybe<Scalars['Float']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Float']>;
  SubmitterUserAcountId?: Maybe<Scalars['Float']>;
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ReviewerWorkspace" */
export type ReviewerWorkspace_Avg_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ReviewerUserAccountId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
  SubmitterUserAcountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ReviewerWorkspace". All fields are combined with a logical 'AND'. */
export type ReviewerWorkspace_Bool_Exp = {
  AdditionalData?: Maybe<String_Mssql_Comparison_Exp>;
  C_ComponentType?: Maybe<C_ComponentType_Bool_Exp>;
  C_WorkflowStatus?: Maybe<C_WorkflowStatus_Bool_Exp>;
  Comments?: Maybe<ItemComment_Mssql_Comparison_Exp>;
  ComponentTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  DataOriginationType?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateSubmitted?: Maybe<Datetime_Mssql_Comparison_Exp>;
  FMUserAccount?: Maybe<FmUserAccount_Bool_Exp>;
  HistoryChangeType?: Maybe<Int_Mssql_Comparison_Exp>;
  ItemId?: Maybe<Int_Mssql_Comparison_Exp>;
  ItemName?: Maybe<String_Mssql_Comparison_Exp>;
  Manufacturer?: Maybe<Manufacturer_Bool_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ReviewerUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  ReviewerWorkspaceData?: Maybe<ReviewerWorkspaceData_Bool_Exp>;
  ReviewerWorkspaceId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubmitterUserAcountId?: Maybe<Int_Mssql_Comparison_Exp>;
  WIPNumber?: Maybe<String_Mssql_Comparison_Exp>;
  WorkflowStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ReviewerWorkspace_Bool_Exp>>;
  _not?: Maybe<ReviewerWorkspace_Bool_Exp>;
  _or?: Maybe<Array<ReviewerWorkspace_Bool_Exp>>;
  fMUserAccountBySubmitteruseracountid?: Maybe<FmUserAccount_Bool_Exp>;
};

/** upsert condition type for table "ReviewerWorkspace" */
export type ReviewerWorkspace_If_Matched = {
  match_columns?: Array<ReviewerWorkspace_Insert_Match_Column>;
  update_columns?: Array<ReviewerWorkspace_Update_Column>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ReviewerWorkspace" */
export type ReviewerWorkspace_Inc_Input = {
  ComponentTypeId?: Maybe<Scalars['Int']>;
  DataOriginationType?: Maybe<Scalars['Int']>;
  HistoryChangeType?: Maybe<Scalars['Int']>;
  ItemId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ReviewerUserAccountId?: Maybe<Scalars['Int']>;
  SubmitterUserAcountId?: Maybe<Scalars['Int']>;
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ReviewerWorkspace" */
export type ReviewerWorkspace_Insert_Input = {
  AdditionalData?: Maybe<Scalars['String']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  DataOriginationType?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DateSubmitted?: Maybe<Scalars['datetime']>;
  HistoryChangeType?: Maybe<Scalars['Int']>;
  ItemId?: Maybe<Scalars['Int']>;
  ItemName?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ReviewerUserAccountId?: Maybe<Scalars['Int']>;
  SubmitterUserAcountId?: Maybe<Scalars['Int']>;
  WIPNumber?: Maybe<Scalars['String']>;
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ReviewerWorkspace" */
export enum ReviewerWorkspace_Insert_Match_Column {
  /** column name */
  AdditionalData = 'AdditionalData',
  /** column name */
  Comments = 'Comments',
  /** column name */
  ComponentTypeId = 'ComponentTypeId',
  /** column name */
  DataOriginationType = 'DataOriginationType',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  DateSubmitted = 'DateSubmitted',
  /** column name */
  HistoryChangeType = 'HistoryChangeType',
  /** column name */
  ItemId = 'ItemId',
  /** column name */
  ItemName = 'ItemName',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ReviewerUserAccountId = 'ReviewerUserAccountId',
  /** column name */
  ReviewerWorkspaceId = 'ReviewerWorkspaceId',
  /** column name */
  SubmitterUserAcountId = 'SubmitterUserAcountId',
  /** column name */
  WipNumber = 'WIPNumber',
  /** column name */
  WorkflowStatusId = 'WorkflowStatusId'
}

/** aggregate max on columns */
export type ReviewerWorkspace_Max_Fields = {
  __typename?: 'ReviewerWorkspace_max_fields';
  AdditionalData?: Maybe<Scalars['String']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  DataOriginationType?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DateSubmitted?: Maybe<Scalars['datetime']>;
  HistoryChangeType?: Maybe<Scalars['Int']>;
  ItemId?: Maybe<Scalars['Int']>;
  ItemName?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ReviewerUserAccountId?: Maybe<Scalars['Int']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Int']>;
  SubmitterUserAcountId?: Maybe<Scalars['Int']>;
  WIPNumber?: Maybe<Scalars['String']>;
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ReviewerWorkspace" */
export type ReviewerWorkspace_Max_Order_By = {
  AdditionalData?: Maybe<Order_By>;
  Comments?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  DateSubmitted?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ItemName?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ReviewerUserAccountId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
  SubmitterUserAcountId?: Maybe<Order_By>;
  WIPNumber?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ReviewerWorkspace_Min_Fields = {
  __typename?: 'ReviewerWorkspace_min_fields';
  AdditionalData?: Maybe<Scalars['String']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  DataOriginationType?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DateSubmitted?: Maybe<Scalars['datetime']>;
  HistoryChangeType?: Maybe<Scalars['Int']>;
  ItemId?: Maybe<Scalars['Int']>;
  ItemName?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ReviewerUserAccountId?: Maybe<Scalars['Int']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Int']>;
  SubmitterUserAcountId?: Maybe<Scalars['Int']>;
  WIPNumber?: Maybe<Scalars['String']>;
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ReviewerWorkspace" */
export type ReviewerWorkspace_Min_Order_By = {
  AdditionalData?: Maybe<Order_By>;
  Comments?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  DateSubmitted?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ItemName?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ReviewerUserAccountId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
  SubmitterUserAcountId?: Maybe<Order_By>;
  WIPNumber?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ReviewerWorkspace" */
export type ReviewerWorkspace_Mutation_Response = {
  __typename?: 'ReviewerWorkspace_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReviewerWorkspace>;
};

/** Ordering options when selecting data from "ReviewerWorkspace". */
export type ReviewerWorkspace_Order_By = {
  AdditionalData?: Maybe<Order_By>;
  C_ComponentType?: Maybe<C_ComponentType_Order_By>;
  C_WorkflowStatus?: Maybe<C_WorkflowStatus_Order_By>;
  Comments?: Maybe<Order_By>;
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  DateSubmitted?: Maybe<Order_By>;
  FMUserAccount?: Maybe<FmUserAccount_Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ItemName?: Maybe<Order_By>;
  Manufacturer?: Maybe<Manufacturer_Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ReviewerUserAccountId?: Maybe<Order_By>;
  ReviewerWorkspaceData_aggregate?: Maybe<ReviewerWorkspaceData_Aggregate_Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
  SubmitterUserAcountId?: Maybe<Order_By>;
  WIPNumber?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
  fMUserAccountBySubmitteruseracountid?: Maybe<FmUserAccount_Order_By>;
};

/** primary key columns input for table: ReviewerWorkspace */
export type ReviewerWorkspace_Pk_Columns_Input = {
  ReviewerWorkspaceId: Scalars['Int'];
};

/** select columns of table "ReviewerWorkspace" */
export enum ReviewerWorkspace_Select_Column {
  /** column name */
  AdditionalData = 'AdditionalData',
  /** column name */
  Comments = 'Comments',
  /** column name */
  ComponentTypeId = 'ComponentTypeId',
  /** column name */
  DataOriginationType = 'DataOriginationType',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  DateSubmitted = 'DateSubmitted',
  /** column name */
  HistoryChangeType = 'HistoryChangeType',
  /** column name */
  ItemId = 'ItemId',
  /** column name */
  ItemName = 'ItemName',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ReviewerUserAccountId = 'ReviewerUserAccountId',
  /** column name */
  ReviewerWorkspaceId = 'ReviewerWorkspaceId',
  /** column name */
  SubmitterUserAcountId = 'SubmitterUserAcountId',
  /** column name */
  WipNumber = 'WIPNumber',
  /** column name */
  WorkflowStatusId = 'WorkflowStatusId'
}

/** input type for updating data in table "ReviewerWorkspace" */
export type ReviewerWorkspace_Set_Input = {
  AdditionalData?: Maybe<Scalars['String']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentTypeId?: Maybe<Scalars['Int']>;
  DataOriginationType?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DateSubmitted?: Maybe<Scalars['datetime']>;
  HistoryChangeType?: Maybe<Scalars['Int']>;
  ItemId?: Maybe<Scalars['Int']>;
  ItemName?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ReviewerUserAccountId?: Maybe<Scalars['Int']>;
  SubmitterUserAcountId?: Maybe<Scalars['Int']>;
  WIPNumber?: Maybe<Scalars['String']>;
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ReviewerWorkspace_Stddev_Fields = {
  __typename?: 'ReviewerWorkspace_stddev_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
  DataOriginationType?: Maybe<Scalars['Float']>;
  HistoryChangeType?: Maybe<Scalars['Float']>;
  ItemId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ReviewerUserAccountId?: Maybe<Scalars['Float']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Float']>;
  SubmitterUserAcountId?: Maybe<Scalars['Float']>;
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ReviewerWorkspace" */
export type ReviewerWorkspace_Stddev_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ReviewerUserAccountId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
  SubmitterUserAcountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ReviewerWorkspace_Stddev_Pop_Fields = {
  __typename?: 'ReviewerWorkspace_stddev_pop_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
  DataOriginationType?: Maybe<Scalars['Float']>;
  HistoryChangeType?: Maybe<Scalars['Float']>;
  ItemId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ReviewerUserAccountId?: Maybe<Scalars['Float']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Float']>;
  SubmitterUserAcountId?: Maybe<Scalars['Float']>;
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ReviewerWorkspace" */
export type ReviewerWorkspace_Stddev_Pop_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ReviewerUserAccountId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
  SubmitterUserAcountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ReviewerWorkspace_Stddev_Samp_Fields = {
  __typename?: 'ReviewerWorkspace_stddev_samp_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
  DataOriginationType?: Maybe<Scalars['Float']>;
  HistoryChangeType?: Maybe<Scalars['Float']>;
  ItemId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ReviewerUserAccountId?: Maybe<Scalars['Float']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Float']>;
  SubmitterUserAcountId?: Maybe<Scalars['Float']>;
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ReviewerWorkspace" */
export type ReviewerWorkspace_Stddev_Samp_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ReviewerUserAccountId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
  SubmitterUserAcountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ReviewerWorkspace_Sum_Fields = {
  __typename?: 'ReviewerWorkspace_sum_fields';
  ComponentTypeId?: Maybe<Scalars['Int']>;
  DataOriginationType?: Maybe<Scalars['Int']>;
  HistoryChangeType?: Maybe<Scalars['Int']>;
  ItemId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  ReviewerUserAccountId?: Maybe<Scalars['Int']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Int']>;
  SubmitterUserAcountId?: Maybe<Scalars['Int']>;
  WorkflowStatusId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ReviewerWorkspace" */
export type ReviewerWorkspace_Sum_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ReviewerUserAccountId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
  SubmitterUserAcountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** update columns of table "ReviewerWorkspace" */
export enum ReviewerWorkspace_Update_Column {
  /** column name */
  AdditionalData = 'AdditionalData',
  /** column name */
  Comments = 'Comments',
  /** column name */
  ComponentTypeId = 'ComponentTypeId',
  /** column name */
  DataOriginationType = 'DataOriginationType',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  DateSubmitted = 'DateSubmitted',
  /** column name */
  HistoryChangeType = 'HistoryChangeType',
  /** column name */
  ItemId = 'ItemId',
  /** column name */
  ItemName = 'ItemName',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  ReviewerUserAccountId = 'ReviewerUserAccountId',
  /** column name */
  SubmitterUserAcountId = 'SubmitterUserAcountId',
  /** column name */
  WipNumber = 'WIPNumber',
  /** column name */
  WorkflowStatusId = 'WorkflowStatusId'
}

/** aggregate var_pop on columns */
export type ReviewerWorkspace_Var_Pop_Fields = {
  __typename?: 'ReviewerWorkspace_var_pop_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
  DataOriginationType?: Maybe<Scalars['Float']>;
  HistoryChangeType?: Maybe<Scalars['Float']>;
  ItemId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ReviewerUserAccountId?: Maybe<Scalars['Float']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Float']>;
  SubmitterUserAcountId?: Maybe<Scalars['Float']>;
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ReviewerWorkspace" */
export type ReviewerWorkspace_Var_Pop_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ReviewerUserAccountId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
  SubmitterUserAcountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ReviewerWorkspace_Var_Samp_Fields = {
  __typename?: 'ReviewerWorkspace_var_samp_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
  DataOriginationType?: Maybe<Scalars['Float']>;
  HistoryChangeType?: Maybe<Scalars['Float']>;
  ItemId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ReviewerUserAccountId?: Maybe<Scalars['Float']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Float']>;
  SubmitterUserAcountId?: Maybe<Scalars['Float']>;
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ReviewerWorkspace" */
export type ReviewerWorkspace_Var_Samp_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ReviewerUserAccountId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
  SubmitterUserAcountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ReviewerWorkspace_Variance_Fields = {
  __typename?: 'ReviewerWorkspace_variance_fields';
  ComponentTypeId?: Maybe<Scalars['Float']>;
  DataOriginationType?: Maybe<Scalars['Float']>;
  HistoryChangeType?: Maybe<Scalars['Float']>;
  ItemId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ReviewerUserAccountId?: Maybe<Scalars['Float']>;
  ReviewerWorkspaceId?: Maybe<Scalars['Float']>;
  SubmitterUserAcountId?: Maybe<Scalars['Float']>;
  WorkflowStatusId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ReviewerWorkspace" */
export type ReviewerWorkspace_Variance_Order_By = {
  ComponentTypeId?: Maybe<Order_By>;
  DataOriginationType?: Maybe<Order_By>;
  HistoryChangeType?: Maybe<Order_By>;
  ItemId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  ReviewerUserAccountId?: Maybe<Order_By>;
  ReviewerWorkspaceId?: Maybe<Order_By>;
  SubmitterUserAcountId?: Maybe<Order_By>;
  WorkflowStatusId?: Maybe<Order_By>;
};

/** columns and relationships of "RoofArea" */
export type RoofArea = {
  __typename?: 'RoofArea';
  BuildingEnclosureId?: Maybe<Scalars['Int']>;
  BuildingName: Scalars['String'];
  /** An object relationship */
  C_BuildingEnclosure?: Maybe<C_BuildingEnclosure>;
  /** An object relationship */
  C_ExtFireRating?: Maybe<C_ExtFireRating>;
  /** An object relationship */
  C_GroundRoughnessRating?: Maybe<C_GroundRoughnessRating>;
  /** An object relationship */
  C_HailRating?: Maybe<C_HailRating>;
  /** An object relationship */
  C_IntFireRating?: Maybe<C_IntFireRating>;
  /** An object relationship */
  C_WindBorneDebri?: Maybe<C_WindBorneDebris>;
  CalculationLastUpdatedDate?: Maybe<Scalars['datetime']>;
  CalculatorVersion?: Maybe<Scalars['String']>;
  ContinuousParapet?: Maybe<Scalars['Boolean']>;
  CornerWindUplift?: Maybe<Scalars['MeasurementValue']>;
  Description: Scalars['String'];
  EnteredAsMetric: Scalars['MetricEntryFlag'];
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  Height?: Maybe<Scalars['MeasurementValue']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  Name: Scalars['String'];
  PerimeterWindUplift?: Maybe<Scalars['MeasurementValue']>;
  ProjectId: Scalars['Int'];
  /** An array relationship */
  R_RoofAreaContacts: Array<R_RoofAreaContact>;
  /** An aggregate relationship */
  R_RoofAreaContacts_aggregate: R_RoofAreaContact_Aggregate;
  /** An array relationship */
  R_RoofAreaNotes: Array<R_RoofAreaNote>;
  /** An aggregate relationship */
  R_RoofAreaNotes_aggregate: R_RoofAreaNote_Aggregate;
  /** An array relationship */
  R_RoofAreaScenarios: Array<R_RoofAreaScenario>;
  /** An aggregate relationship */
  R_RoofAreaScenarios_aggregate: R_RoofAreaScenario_Aggregate;
  RoofAreaId: Scalars['Int'];
  /** An array relationship */
  RoofAreaWindPressures: Array<RoofAreaWindPressure>;
  /** An aggregate relationship */
  RoofAreaWindPressures_aggregate: RoofAreaWindPressure_Aggregate;
  /** An object relationship */
  RoofProject: RoofProject;
  Slope?: Maybe<Scalars['MeasurementValue']>;
  TropicalCycloneRegion?: Maybe<Scalars['Boolean']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
  WindBorneDebrisId?: Maybe<Scalars['Int']>;
  WindSpeed?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};


/** columns and relationships of "RoofArea" */
export type RoofAreaR_RoofAreaContactsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaContact_Order_By>>;
  where?: Maybe<R_RoofAreaContact_Bool_Exp>;
};


/** columns and relationships of "RoofArea" */
export type RoofAreaR_RoofAreaContacts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaContact_Order_By>>;
  where?: Maybe<R_RoofAreaContact_Bool_Exp>;
};


/** columns and relationships of "RoofArea" */
export type RoofAreaR_RoofAreaNotesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaNote_Order_By>>;
  where?: Maybe<R_RoofAreaNote_Bool_Exp>;
};


/** columns and relationships of "RoofArea" */
export type RoofAreaR_RoofAreaNotes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaNote_Order_By>>;
  where?: Maybe<R_RoofAreaNote_Bool_Exp>;
};


/** columns and relationships of "RoofArea" */
export type RoofAreaR_RoofAreaScenariosArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaScenario_Order_By>>;
  where?: Maybe<R_RoofAreaScenario_Bool_Exp>;
};


/** columns and relationships of "RoofArea" */
export type RoofAreaR_RoofAreaScenarios_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaScenario_Order_By>>;
  where?: Maybe<R_RoofAreaScenario_Bool_Exp>;
};


/** columns and relationships of "RoofArea" */
export type RoofAreaRoofAreaWindPressuresArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAreaWindPressure_Order_By>>;
  where?: Maybe<RoofAreaWindPressure_Bool_Exp>;
};


/** columns and relationships of "RoofArea" */
export type RoofAreaRoofAreaWindPressures_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAreaWindPressure_Order_By>>;
  where?: Maybe<RoofAreaWindPressure_Bool_Exp>;
};

export type RoofAreaBaseInfo = {
  BuildingName?: Maybe<Scalars['String']>;
  ClientContactId?: Maybe<Scalars['Int']>;
  ContractorContactId?: Maybe<Scalars['Int']>;
  Description?: Maybe<Scalars['String']>;
  DesignerContactId?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
};

export type RoofAreaBaseSaveResult = {
  __typename?: 'RoofAreaBaseSaveResult';
  RoofAreaId: Scalars['Int'];
};

/** columns and relationships of "RoofAreaWindPressure" */
export type RoofAreaWindPressure = {
  __typename?: 'RoofAreaWindPressure';
  /** An object relationship */
  C_PressureZone: C_PressureZone;
  CopingRating?: Maybe<Scalars['MeasurementValue']>;
  FasciaRating?: Maybe<Scalars['MeasurementValue']>;
  PressureZoneId: Scalars['Int'];
  /** An object relationship */
  RoofArea: RoofArea;
  RoofAreaId: Scalars['Int'];
  RoofAreaWindPressureId: Scalars['Int'];
  WindUplift: Scalars['MeasurementValue'];
};

/** aggregated selection of "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Aggregate = {
  __typename?: 'RoofAreaWindPressure_aggregate';
  aggregate?: Maybe<RoofAreaWindPressure_Aggregate_Fields>;
  nodes: Array<RoofAreaWindPressure>;
};

/** aggregate fields of "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Aggregate_Fields = {
  __typename?: 'RoofAreaWindPressure_aggregate_fields';
  avg?: Maybe<RoofAreaWindPressure_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RoofAreaWindPressure_Max_Fields>;
  min?: Maybe<RoofAreaWindPressure_Min_Fields>;
  stddev?: Maybe<RoofAreaWindPressure_Stddev_Fields>;
  stddev_pop?: Maybe<RoofAreaWindPressure_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RoofAreaWindPressure_Stddev_Samp_Fields>;
  sum?: Maybe<RoofAreaWindPressure_Sum_Fields>;
  var_pop?: Maybe<RoofAreaWindPressure_Var_Pop_Fields>;
  var_samp?: Maybe<RoofAreaWindPressure_Var_Samp_Fields>;
  variance?: Maybe<RoofAreaWindPressure_Variance_Fields>;
};


/** aggregate fields of "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Aggregate_FieldsCountArgs = {
  column?: Maybe<RoofAreaWindPressure_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Aggregate_Order_By = {
  avg?: Maybe<RoofAreaWindPressure_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<RoofAreaWindPressure_Max_Order_By>;
  min?: Maybe<RoofAreaWindPressure_Min_Order_By>;
  stddev?: Maybe<RoofAreaWindPressure_Stddev_Order_By>;
  stddev_pop?: Maybe<RoofAreaWindPressure_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<RoofAreaWindPressure_Stddev_Samp_Order_By>;
  sum?: Maybe<RoofAreaWindPressure_Sum_Order_By>;
  var_pop?: Maybe<RoofAreaWindPressure_Var_Pop_Order_By>;
  var_samp?: Maybe<RoofAreaWindPressure_Var_Samp_Order_By>;
  variance?: Maybe<RoofAreaWindPressure_Variance_Order_By>;
};

/** aggregate avg on columns */
export type RoofAreaWindPressure_Avg_Fields = {
  __typename?: 'RoofAreaWindPressure_avg_fields';
  PressureZoneId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
  RoofAreaWindPressureId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Avg_Order_By = {
  PressureZoneId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  RoofAreaWindPressureId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "RoofAreaWindPressure". All fields are combined with a logical 'AND'. */
export type RoofAreaWindPressure_Bool_Exp = {
  C_PressureZone?: Maybe<C_PressureZone_Bool_Exp>;
  CopingRating?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  FasciaRating?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  PressureZoneId?: Maybe<Int_Mssql_Comparison_Exp>;
  RoofArea?: Maybe<RoofArea_Bool_Exp>;
  RoofAreaId?: Maybe<Int_Mssql_Comparison_Exp>;
  RoofAreaWindPressureId?: Maybe<Int_Mssql_Comparison_Exp>;
  WindUplift?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<RoofAreaWindPressure_Bool_Exp>>;
  _not?: Maybe<RoofAreaWindPressure_Bool_Exp>;
  _or?: Maybe<Array<RoofAreaWindPressure_Bool_Exp>>;
};

/** upsert condition type for table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_If_Matched = {
  match_columns?: Array<RoofAreaWindPressure_Insert_Match_Column>;
  update_columns?: Array<RoofAreaWindPressure_Update_Column>;
  where?: Maybe<RoofAreaWindPressure_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Inc_Input = {
  PressureZoneId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Insert_Input = {
  CopingRating?: Maybe<Scalars['MeasurementValue']>;
  FasciaRating?: Maybe<Scalars['MeasurementValue']>;
  PressureZoneId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "RoofAreaWindPressure" */
export enum RoofAreaWindPressure_Insert_Match_Column {
  /** column name */
  CopingRating = 'CopingRating',
  /** column name */
  FasciaRating = 'FasciaRating',
  /** column name */
  PressureZoneId = 'PressureZoneId',
  /** column name */
  RoofAreaId = 'RoofAreaId',
  /** column name */
  RoofAreaWindPressureId = 'RoofAreaWindPressureId',
  /** column name */
  WindUplift = 'WindUplift'
}

/** aggregate max on columns */
export type RoofAreaWindPressure_Max_Fields = {
  __typename?: 'RoofAreaWindPressure_max_fields';
  CopingRating?: Maybe<Scalars['MeasurementValue']>;
  FasciaRating?: Maybe<Scalars['MeasurementValue']>;
  PressureZoneId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
  RoofAreaWindPressureId?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Max_Order_By = {
  CopingRating?: Maybe<Order_By>;
  FasciaRating?: Maybe<Order_By>;
  PressureZoneId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  RoofAreaWindPressureId?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type RoofAreaWindPressure_Min_Fields = {
  __typename?: 'RoofAreaWindPressure_min_fields';
  CopingRating?: Maybe<Scalars['MeasurementValue']>;
  FasciaRating?: Maybe<Scalars['MeasurementValue']>;
  PressureZoneId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
  RoofAreaWindPressureId?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Min_Order_By = {
  CopingRating?: Maybe<Order_By>;
  FasciaRating?: Maybe<Order_By>;
  PressureZoneId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  RoofAreaWindPressureId?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
};

/** response of any mutation on the table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Mutation_Response = {
  __typename?: 'RoofAreaWindPressure_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RoofAreaWindPressure>;
};

/** Ordering options when selecting data from "RoofAreaWindPressure". */
export type RoofAreaWindPressure_Order_By = {
  C_PressureZone?: Maybe<C_PressureZone_Order_By>;
  CopingRating?: Maybe<Order_By>;
  FasciaRating?: Maybe<Order_By>;
  PressureZoneId?: Maybe<Order_By>;
  RoofArea?: Maybe<RoofArea_Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  RoofAreaWindPressureId?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
};

/** primary key columns input for table: RoofAreaWindPressure */
export type RoofAreaWindPressure_Pk_Columns_Input = {
  RoofAreaWindPressureId: Scalars['Int'];
};

/** select columns of table "RoofAreaWindPressure" */
export enum RoofAreaWindPressure_Select_Column {
  /** column name */
  CopingRating = 'CopingRating',
  /** column name */
  FasciaRating = 'FasciaRating',
  /** column name */
  PressureZoneId = 'PressureZoneId',
  /** column name */
  RoofAreaId = 'RoofAreaId',
  /** column name */
  RoofAreaWindPressureId = 'RoofAreaWindPressureId',
  /** column name */
  WindUplift = 'WindUplift'
}

/** input type for updating data in table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Set_Input = {
  CopingRating?: Maybe<Scalars['MeasurementValue']>;
  FasciaRating?: Maybe<Scalars['MeasurementValue']>;
  PressureZoneId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type RoofAreaWindPressure_Stddev_Fields = {
  __typename?: 'RoofAreaWindPressure_stddev_fields';
  PressureZoneId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
  RoofAreaWindPressureId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Stddev_Order_By = {
  PressureZoneId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  RoofAreaWindPressureId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type RoofAreaWindPressure_Stddev_Pop_Fields = {
  __typename?: 'RoofAreaWindPressure_stddev_pop_fields';
  PressureZoneId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
  RoofAreaWindPressureId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Stddev_Pop_Order_By = {
  PressureZoneId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  RoofAreaWindPressureId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type RoofAreaWindPressure_Stddev_Samp_Fields = {
  __typename?: 'RoofAreaWindPressure_stddev_samp_fields';
  PressureZoneId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
  RoofAreaWindPressureId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Stddev_Samp_Order_By = {
  PressureZoneId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  RoofAreaWindPressureId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type RoofAreaWindPressure_Sum_Fields = {
  __typename?: 'RoofAreaWindPressure_sum_fields';
  PressureZoneId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
  RoofAreaWindPressureId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Sum_Order_By = {
  PressureZoneId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  RoofAreaWindPressureId?: Maybe<Order_By>;
};

/** update columns of table "RoofAreaWindPressure" */
export enum RoofAreaWindPressure_Update_Column {
  /** column name */
  CopingRating = 'CopingRating',
  /** column name */
  FasciaRating = 'FasciaRating',
  /** column name */
  PressureZoneId = 'PressureZoneId',
  /** column name */
  RoofAreaId = 'RoofAreaId',
  /** column name */
  WindUplift = 'WindUplift'
}

/** aggregate var_pop on columns */
export type RoofAreaWindPressure_Var_Pop_Fields = {
  __typename?: 'RoofAreaWindPressure_var_pop_fields';
  PressureZoneId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
  RoofAreaWindPressureId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Var_Pop_Order_By = {
  PressureZoneId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  RoofAreaWindPressureId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type RoofAreaWindPressure_Var_Samp_Fields = {
  __typename?: 'RoofAreaWindPressure_var_samp_fields';
  PressureZoneId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
  RoofAreaWindPressureId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Var_Samp_Order_By = {
  PressureZoneId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  RoofAreaWindPressureId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type RoofAreaWindPressure_Variance_Fields = {
  __typename?: 'RoofAreaWindPressure_variance_fields';
  PressureZoneId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
  RoofAreaWindPressureId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "RoofAreaWindPressure" */
export type RoofAreaWindPressure_Variance_Order_By = {
  PressureZoneId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  RoofAreaWindPressureId?: Maybe<Order_By>;
};

export type RoofAreaWithRatingSaveResult = {
  __typename?: 'RoofAreaWithRatingSaveResult';
  RoofAreaId: Scalars['Int'];
};

/** aggregated selection of "RoofArea" */
export type RoofArea_Aggregate = {
  __typename?: 'RoofArea_aggregate';
  aggregate?: Maybe<RoofArea_Aggregate_Fields>;
  nodes: Array<RoofArea>;
};

/** aggregate fields of "RoofArea" */
export type RoofArea_Aggregate_Fields = {
  __typename?: 'RoofArea_aggregate_fields';
  avg?: Maybe<RoofArea_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RoofArea_Max_Fields>;
  min?: Maybe<RoofArea_Min_Fields>;
  stddev?: Maybe<RoofArea_Stddev_Fields>;
  stddev_pop?: Maybe<RoofArea_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RoofArea_Stddev_Samp_Fields>;
  sum?: Maybe<RoofArea_Sum_Fields>;
  var_pop?: Maybe<RoofArea_Var_Pop_Fields>;
  var_samp?: Maybe<RoofArea_Var_Samp_Fields>;
  variance?: Maybe<RoofArea_Variance_Fields>;
};


/** aggregate fields of "RoofArea" */
export type RoofArea_Aggregate_FieldsCountArgs = {
  column?: Maybe<RoofArea_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "RoofArea" */
export type RoofArea_Aggregate_Order_By = {
  avg?: Maybe<RoofArea_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<RoofArea_Max_Order_By>;
  min?: Maybe<RoofArea_Min_Order_By>;
  stddev?: Maybe<RoofArea_Stddev_Order_By>;
  stddev_pop?: Maybe<RoofArea_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<RoofArea_Stddev_Samp_Order_By>;
  sum?: Maybe<RoofArea_Sum_Order_By>;
  var_pop?: Maybe<RoofArea_Var_Pop_Order_By>;
  var_samp?: Maybe<RoofArea_Var_Samp_Order_By>;
  variance?: Maybe<RoofArea_Variance_Order_By>;
};

/** aggregate avg on columns */
export type RoofArea_Avg_Fields = {
  __typename?: 'RoofArea_avg_fields';
  BuildingEnclosureId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
  WindBorneDebrisId?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "RoofArea" */
export type RoofArea_Avg_Order_By = {
  BuildingEnclosureId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  GroundRoughnessRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  WindBorneDebrisId?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "RoofArea". All fields are combined with a logical 'AND'. */
export type RoofArea_Bool_Exp = {
  BuildingEnclosureId?: Maybe<Int_Mssql_Comparison_Exp>;
  BuildingName?: Maybe<String_Mssql_Comparison_Exp>;
  C_BuildingEnclosure?: Maybe<C_BuildingEnclosure_Bool_Exp>;
  C_ExtFireRating?: Maybe<C_ExtFireRating_Bool_Exp>;
  C_GroundRoughnessRating?: Maybe<C_GroundRoughnessRating_Bool_Exp>;
  C_HailRating?: Maybe<C_HailRating_Bool_Exp>;
  C_IntFireRating?: Maybe<C_IntFireRating_Bool_Exp>;
  C_WindBorneDebri?: Maybe<C_WindBorneDebris_Bool_Exp>;
  CalculationLastUpdatedDate?: Maybe<Datetime_Mssql_Comparison_Exp>;
  CalculatorVersion?: Maybe<String_Mssql_Comparison_Exp>;
  ContinuousParapet?: Maybe<Boolean_Mssql_Comparison_Exp>;
  CornerWindUplift?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Description?: Maybe<String_Mssql_Comparison_Exp>;
  EnteredAsMetric?: Maybe<MetricEntryFlag_Mssql_Comparison_Exp>;
  ExtFireRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  GroundRoughnessRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  HailRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  Height?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  IntFireRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  Length?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Name?: Maybe<String_Mssql_Comparison_Exp>;
  PerimeterWindUplift?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ProjectId?: Maybe<Int_Mssql_Comparison_Exp>;
  R_RoofAreaContacts?: Maybe<R_RoofAreaContact_Bool_Exp>;
  R_RoofAreaNotes?: Maybe<R_RoofAreaNote_Bool_Exp>;
  R_RoofAreaScenarios?: Maybe<R_RoofAreaScenario_Bool_Exp>;
  RoofAreaId?: Maybe<Int_Mssql_Comparison_Exp>;
  RoofAreaWindPressures?: Maybe<RoofAreaWindPressure_Bool_Exp>;
  RoofProject?: Maybe<RoofProject_Bool_Exp>;
  Slope?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  TropicalCycloneRegion?: Maybe<Boolean_Mssql_Comparison_Exp>;
  Width?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  WindBorneDebrisId?: Maybe<Int_Mssql_Comparison_Exp>;
  WindSpeed?: Maybe<Int_Mssql_Comparison_Exp>;
  WindUplift?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<RoofArea_Bool_Exp>>;
  _not?: Maybe<RoofArea_Bool_Exp>;
  _or?: Maybe<Array<RoofArea_Bool_Exp>>;
};

/** upsert condition type for table "RoofArea" */
export type RoofArea_If_Matched = {
  match_columns?: Array<RoofArea_Insert_Match_Column>;
  update_columns?: Array<RoofArea_Update_Column>;
  where?: Maybe<RoofArea_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "RoofArea" */
export type RoofArea_Inc_Input = {
  BuildingEnclosureId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
  WindBorneDebrisId?: Maybe<Scalars['Int']>;
  WindSpeed?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "RoofArea" */
export type RoofArea_Insert_Input = {
  BuildingEnclosureId?: Maybe<Scalars['Int']>;
  BuildingName?: Maybe<Scalars['String']>;
  CalculationLastUpdatedDate?: Maybe<Scalars['datetime']>;
  CalculatorVersion?: Maybe<Scalars['String']>;
  ContinuousParapet?: Maybe<Scalars['Boolean']>;
  CornerWindUplift?: Maybe<Scalars['MeasurementValue']>;
  Description?: Maybe<Scalars['String']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  Height?: Maybe<Scalars['MeasurementValue']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  Name?: Maybe<Scalars['String']>;
  PerimeterWindUplift?: Maybe<Scalars['MeasurementValue']>;
  ProjectId?: Maybe<Scalars['Int']>;
  Slope?: Maybe<Scalars['MeasurementValue']>;
  TropicalCycloneRegion?: Maybe<Scalars['Boolean']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
  WindBorneDebrisId?: Maybe<Scalars['Int']>;
  WindSpeed?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "RoofArea" */
export enum RoofArea_Insert_Match_Column {
  /** column name */
  BuildingEnclosureId = 'BuildingEnclosureId',
  /** column name */
  BuildingName = 'BuildingName',
  /** column name */
  CalculationLastUpdatedDate = 'CalculationLastUpdatedDate',
  /** column name */
  CalculatorVersion = 'CalculatorVersion',
  /** column name */
  ContinuousParapet = 'ContinuousParapet',
  /** column name */
  CornerWindUplift = 'CornerWindUplift',
  /** column name */
  Description = 'Description',
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  ExtFireRatingId = 'ExtFireRatingId',
  /** column name */
  GroundRoughnessRatingId = 'GroundRoughnessRatingId',
  /** column name */
  HailRatingId = 'HailRatingId',
  /** column name */
  Height = 'Height',
  /** column name */
  IntFireRatingId = 'IntFireRatingId',
  /** column name */
  Length = 'Length',
  /** column name */
  Name = 'Name',
  /** column name */
  PerimeterWindUplift = 'PerimeterWindUplift',
  /** column name */
  ProjectId = 'ProjectId',
  /** column name */
  RoofAreaId = 'RoofAreaId',
  /** column name */
  Slope = 'Slope',
  /** column name */
  TropicalCycloneRegion = 'TropicalCycloneRegion',
  /** column name */
  Width = 'Width',
  /** column name */
  WindBorneDebrisId = 'WindBorneDebrisId',
  /** column name */
  WindSpeed = 'WindSpeed',
  /** column name */
  WindUplift = 'WindUplift'
}

/** aggregate max on columns */
export type RoofArea_Max_Fields = {
  __typename?: 'RoofArea_max_fields';
  BuildingEnclosureId?: Maybe<Scalars['Int']>;
  BuildingName?: Maybe<Scalars['String']>;
  CalculationLastUpdatedDate?: Maybe<Scalars['datetime']>;
  CalculatorVersion?: Maybe<Scalars['String']>;
  CornerWindUplift?: Maybe<Scalars['MeasurementValue']>;
  Description?: Maybe<Scalars['String']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  Height?: Maybe<Scalars['MeasurementValue']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  Name?: Maybe<Scalars['String']>;
  PerimeterWindUplift?: Maybe<Scalars['MeasurementValue']>;
  ProjectId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
  Slope?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
  WindBorneDebrisId?: Maybe<Scalars['Int']>;
  WindSpeed?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "RoofArea" */
export type RoofArea_Max_Order_By = {
  BuildingEnclosureId?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  CalculationLastUpdatedDate?: Maybe<Order_By>;
  CalculatorVersion?: Maybe<Order_By>;
  CornerWindUplift?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  EnteredAsMetric?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  GroundRoughnessRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  Height?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  Length?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PerimeterWindUplift?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  Slope?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WindBorneDebrisId?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type RoofArea_Min_Fields = {
  __typename?: 'RoofArea_min_fields';
  BuildingEnclosureId?: Maybe<Scalars['Int']>;
  BuildingName?: Maybe<Scalars['String']>;
  CalculationLastUpdatedDate?: Maybe<Scalars['datetime']>;
  CalculatorVersion?: Maybe<Scalars['String']>;
  CornerWindUplift?: Maybe<Scalars['MeasurementValue']>;
  Description?: Maybe<Scalars['String']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  Height?: Maybe<Scalars['MeasurementValue']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  Name?: Maybe<Scalars['String']>;
  PerimeterWindUplift?: Maybe<Scalars['MeasurementValue']>;
  ProjectId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
  Slope?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
  WindBorneDebrisId?: Maybe<Scalars['Int']>;
  WindSpeed?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "RoofArea" */
export type RoofArea_Min_Order_By = {
  BuildingEnclosureId?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  CalculationLastUpdatedDate?: Maybe<Order_By>;
  CalculatorVersion?: Maybe<Order_By>;
  CornerWindUplift?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  EnteredAsMetric?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  GroundRoughnessRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  Height?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  Length?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PerimeterWindUplift?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  Slope?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WindBorneDebrisId?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
};

/** response of any mutation on the table "RoofArea" */
export type RoofArea_Mutation_Response = {
  __typename?: 'RoofArea_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RoofArea>;
};

/** Ordering options when selecting data from "RoofArea". */
export type RoofArea_Order_By = {
  BuildingEnclosureId?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  C_BuildingEnclosure?: Maybe<C_BuildingEnclosure_Order_By>;
  C_ExtFireRating?: Maybe<C_ExtFireRating_Order_By>;
  C_GroundRoughnessRating?: Maybe<C_GroundRoughnessRating_Order_By>;
  C_HailRating?: Maybe<C_HailRating_Order_By>;
  C_IntFireRating?: Maybe<C_IntFireRating_Order_By>;
  C_WindBorneDebri?: Maybe<C_WindBorneDebris_Order_By>;
  CalculationLastUpdatedDate?: Maybe<Order_By>;
  CalculatorVersion?: Maybe<Order_By>;
  ContinuousParapet?: Maybe<Order_By>;
  CornerWindUplift?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  EnteredAsMetric?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  GroundRoughnessRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  Height?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  Length?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PerimeterWindUplift?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  R_RoofAreaContacts_aggregate?: Maybe<R_RoofAreaContact_Aggregate_Order_By>;
  R_RoofAreaNotes_aggregate?: Maybe<R_RoofAreaNote_Aggregate_Order_By>;
  R_RoofAreaScenarios_aggregate?: Maybe<R_RoofAreaScenario_Aggregate_Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  RoofAreaWindPressures_aggregate?: Maybe<RoofAreaWindPressure_Aggregate_Order_By>;
  RoofProject?: Maybe<RoofProject_Order_By>;
  Slope?: Maybe<Order_By>;
  TropicalCycloneRegion?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WindBorneDebrisId?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
};

/** primary key columns input for table: RoofArea */
export type RoofArea_Pk_Columns_Input = {
  RoofAreaId: Scalars['Int'];
};

/** select columns of table "RoofArea" */
export enum RoofArea_Select_Column {
  /** column name */
  BuildingEnclosureId = 'BuildingEnclosureId',
  /** column name */
  BuildingName = 'BuildingName',
  /** column name */
  CalculationLastUpdatedDate = 'CalculationLastUpdatedDate',
  /** column name */
  CalculatorVersion = 'CalculatorVersion',
  /** column name */
  ContinuousParapet = 'ContinuousParapet',
  /** column name */
  CornerWindUplift = 'CornerWindUplift',
  /** column name */
  Description = 'Description',
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  ExtFireRatingId = 'ExtFireRatingId',
  /** column name */
  GroundRoughnessRatingId = 'GroundRoughnessRatingId',
  /** column name */
  HailRatingId = 'HailRatingId',
  /** column name */
  Height = 'Height',
  /** column name */
  IntFireRatingId = 'IntFireRatingId',
  /** column name */
  Length = 'Length',
  /** column name */
  Name = 'Name',
  /** column name */
  PerimeterWindUplift = 'PerimeterWindUplift',
  /** column name */
  ProjectId = 'ProjectId',
  /** column name */
  RoofAreaId = 'RoofAreaId',
  /** column name */
  Slope = 'Slope',
  /** column name */
  TropicalCycloneRegion = 'TropicalCycloneRegion',
  /** column name */
  Width = 'Width',
  /** column name */
  WindBorneDebrisId = 'WindBorneDebrisId',
  /** column name */
  WindSpeed = 'WindSpeed',
  /** column name */
  WindUplift = 'WindUplift'
}

/** input type for updating data in table "RoofArea" */
export type RoofArea_Set_Input = {
  BuildingEnclosureId?: Maybe<Scalars['Int']>;
  BuildingName?: Maybe<Scalars['String']>;
  CalculationLastUpdatedDate?: Maybe<Scalars['datetime']>;
  CalculatorVersion?: Maybe<Scalars['String']>;
  ContinuousParapet?: Maybe<Scalars['Boolean']>;
  CornerWindUplift?: Maybe<Scalars['MeasurementValue']>;
  Description?: Maybe<Scalars['String']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  Height?: Maybe<Scalars['MeasurementValue']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  Name?: Maybe<Scalars['String']>;
  PerimeterWindUplift?: Maybe<Scalars['MeasurementValue']>;
  ProjectId?: Maybe<Scalars['Int']>;
  Slope?: Maybe<Scalars['MeasurementValue']>;
  TropicalCycloneRegion?: Maybe<Scalars['Boolean']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
  WindBorneDebrisId?: Maybe<Scalars['Int']>;
  WindSpeed?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type RoofArea_Stddev_Fields = {
  __typename?: 'RoofArea_stddev_fields';
  BuildingEnclosureId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
  WindBorneDebrisId?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "RoofArea" */
export type RoofArea_Stddev_Order_By = {
  BuildingEnclosureId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  GroundRoughnessRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  WindBorneDebrisId?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type RoofArea_Stddev_Pop_Fields = {
  __typename?: 'RoofArea_stddev_pop_fields';
  BuildingEnclosureId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
  WindBorneDebrisId?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "RoofArea" */
export type RoofArea_Stddev_Pop_Order_By = {
  BuildingEnclosureId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  GroundRoughnessRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  WindBorneDebrisId?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type RoofArea_Stddev_Samp_Fields = {
  __typename?: 'RoofArea_stddev_samp_fields';
  BuildingEnclosureId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
  WindBorneDebrisId?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "RoofArea" */
export type RoofArea_Stddev_Samp_Order_By = {
  BuildingEnclosureId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  GroundRoughnessRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  WindBorneDebrisId?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type RoofArea_Sum_Fields = {
  __typename?: 'RoofArea_sum_fields';
  BuildingEnclosureId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
  RoofAreaId?: Maybe<Scalars['Int']>;
  WindBorneDebrisId?: Maybe<Scalars['Int']>;
  WindSpeed?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "RoofArea" */
export type RoofArea_Sum_Order_By = {
  BuildingEnclosureId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  GroundRoughnessRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  WindBorneDebrisId?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** update columns of table "RoofArea" */
export enum RoofArea_Update_Column {
  /** column name */
  BuildingEnclosureId = 'BuildingEnclosureId',
  /** column name */
  BuildingName = 'BuildingName',
  /** column name */
  CalculationLastUpdatedDate = 'CalculationLastUpdatedDate',
  /** column name */
  CalculatorVersion = 'CalculatorVersion',
  /** column name */
  ContinuousParapet = 'ContinuousParapet',
  /** column name */
  CornerWindUplift = 'CornerWindUplift',
  /** column name */
  Description = 'Description',
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  ExtFireRatingId = 'ExtFireRatingId',
  /** column name */
  GroundRoughnessRatingId = 'GroundRoughnessRatingId',
  /** column name */
  HailRatingId = 'HailRatingId',
  /** column name */
  Height = 'Height',
  /** column name */
  IntFireRatingId = 'IntFireRatingId',
  /** column name */
  Length = 'Length',
  /** column name */
  Name = 'Name',
  /** column name */
  PerimeterWindUplift = 'PerimeterWindUplift',
  /** column name */
  ProjectId = 'ProjectId',
  /** column name */
  Slope = 'Slope',
  /** column name */
  TropicalCycloneRegion = 'TropicalCycloneRegion',
  /** column name */
  Width = 'Width',
  /** column name */
  WindBorneDebrisId = 'WindBorneDebrisId',
  /** column name */
  WindSpeed = 'WindSpeed',
  /** column name */
  WindUplift = 'WindUplift'
}

/** aggregate var_pop on columns */
export type RoofArea_Var_Pop_Fields = {
  __typename?: 'RoofArea_var_pop_fields';
  BuildingEnclosureId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
  WindBorneDebrisId?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "RoofArea" */
export type RoofArea_Var_Pop_Order_By = {
  BuildingEnclosureId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  GroundRoughnessRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  WindBorneDebrisId?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type RoofArea_Var_Samp_Fields = {
  __typename?: 'RoofArea_var_samp_fields';
  BuildingEnclosureId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
  WindBorneDebrisId?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "RoofArea" */
export type RoofArea_Var_Samp_Order_By = {
  BuildingEnclosureId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  GroundRoughnessRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  WindBorneDebrisId?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type RoofArea_Variance_Fields = {
  __typename?: 'RoofArea_variance_fields';
  BuildingEnclosureId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  GroundRoughnessRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  RoofAreaId?: Maybe<Scalars['Float']>;
  WindBorneDebrisId?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "RoofArea" */
export type RoofArea_Variance_Order_By = {
  BuildingEnclosureId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  GroundRoughnessRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  RoofAreaId?: Maybe<Order_By>;
  WindBorneDebrisId?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** columns and relationships of "RoofAssembly" */
export type RoofAssembly = {
  __typename?: 'RoofAssembly';
  AssemblyApplicationTypeId: Scalars['Int'];
  AssemblyId: Scalars['Int'];
  AssemblyNum: Scalars['String'];
  AssemblyTypeId: Scalars['Int'];
  /** An object relationship */
  C_AssemblyApplicationType: C_AssemblyApplicationType;
  /** An object relationship */
  C_AssemblyType: C_AssemblyType;
  /** An object relationship */
  C_CoverSecurementMethod: C_CoverSecurementMethod;
  /** An object relationship */
  C_DeckType: C_DeckType;
  /** An object relationship */
  C_ExtFireRating: C_ExtFireRating;
  /** An object relationship */
  C_HailRating: C_HailRating;
  /** An object relationship */
  C_IntFireRating: C_IntFireRating;
  /** An object relationship */
  C_LockStatus: C_LockStatus;
  /** An object relationship */
  C_PVApplicationMethod?: Maybe<C_PvApplicationMethod>;
  /** An object relationship */
  C_PVHailRating?: Maybe<C_PvHailRating>;
  /** An object relationship */
  C_Status: C_Status;
  Comments: Scalars['ItemComment'];
  CoverSecurementMethodId: Scalars['Int'];
  CreatedByUserAccountId: Scalars['Int'];
  DateCreated: Scalars['datetime'];
  DateEffective: Scalars['datetime'];
  DateInception: Scalars['datetime'];
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified: Scalars['datetime'];
  DeckTypeId: Scalars['Int'];
  ExtFireRatingId: Scalars['Int'];
  /** An object relationship */
  FMUserAccount: FmUserAccount;
  HailRatingId: Scalars['Int'];
  IncludesPV?: Maybe<Scalars['Boolean']>;
  IntFireRatingId: Scalars['Int'];
  IsUsedWithCombustibleWalls: Scalars['Boolean'];
  /** An object relationship */
  LayerConfig?: Maybe<LayerConfig>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  LockStatusId: Scalars['Int'];
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId: Scalars['Int'];
  /** An array relationship */
  NavAssemblies: Array<NavAssembly>;
  /** An aggregate relationship */
  NavAssemblies_aggregate: NavAssembly_Aggregate;
  /** An array relationship */
  NavLayers: Array<NavLayer>;
  /** An aggregate relationship */
  NavLayers_aggregate: NavLayer_Aggregate;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  PVWindUplift?: Maybe<Scalars['MeasurementValue']>;
  /** An array relationship */
  RoofAssemblyLayers: Array<RoofAssemblyLayers>;
  /** An aggregate relationship */
  RoofAssemblyLayers_aggregate: RoofAssemblyLayers_Aggregate;
  Slope: Scalars['MeasurementValue'];
  StatusId: Scalars['Int'];
  VersionMajor: Scalars['Int'];
  VersionMinor: Scalars['Int'];
  WindSpeed: Scalars['Int'];
  WindUplift: Scalars['MeasurementValue'];
  /** An object relationship */
  fMUserAccountByLockedbyuseraccountid?: Maybe<FmUserAccount>;
  /** An object relationship */
  fMUserAccountByModifiedbyuseraccountid: FmUserAccount;
};


/** columns and relationships of "RoofAssembly" */
export type RoofAssemblyNavAssembliesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssembly_Order_By>>;
  where?: Maybe<NavAssembly_Bool_Exp>;
};


/** columns and relationships of "RoofAssembly" */
export type RoofAssemblyNavAssemblies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssembly_Order_By>>;
  where?: Maybe<NavAssembly_Bool_Exp>;
};


/** columns and relationships of "RoofAssembly" */
export type RoofAssemblyNavLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavLayer_Order_By>>;
  where?: Maybe<NavLayer_Bool_Exp>;
};


/** columns and relationships of "RoofAssembly" */
export type RoofAssemblyNavLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavLayer_Order_By>>;
  where?: Maybe<NavLayer_Bool_Exp>;
};


/** columns and relationships of "RoofAssembly" */
export type RoofAssemblyRoofAssemblyLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssemblyLayers_Order_By>>;
  where?: Maybe<RoofAssemblyLayers_Bool_Exp>;
};


/** columns and relationships of "RoofAssembly" */
export type RoofAssemblyRoofAssemblyLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssemblyLayers_Order_By>>;
  where?: Maybe<RoofAssemblyLayers_Bool_Exp>;
};

/** columns and relationships of "RoofAssemblyDelistData" */
export type RoofAssemblyDelistData = {
  __typename?: 'RoofAssemblyDelistData';
  ApprovedUseId: Scalars['Int'];
  AssemblyId: Scalars['Int'];
  ComponentId: Scalars['Int'];
  LayerComponentCount: Scalars['Int'];
  LayerId: Scalars['Int'];
  LayerManufacturerCount: Scalars['Int'];
};

/** aggregated selection of "RoofAssemblyDelistData" */
export type RoofAssemblyDelistData_Aggregate = {
  __typename?: 'RoofAssemblyDelistData_aggregate';
  aggregate?: Maybe<RoofAssemblyDelistData_Aggregate_Fields>;
  nodes: Array<RoofAssemblyDelistData>;
};

/** aggregate fields of "RoofAssemblyDelistData" */
export type RoofAssemblyDelistData_Aggregate_Fields = {
  __typename?: 'RoofAssemblyDelistData_aggregate_fields';
  avg?: Maybe<RoofAssemblyDelistData_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RoofAssemblyDelistData_Max_Fields>;
  min?: Maybe<RoofAssemblyDelistData_Min_Fields>;
  stddev?: Maybe<RoofAssemblyDelistData_Stddev_Fields>;
  stddev_pop?: Maybe<RoofAssemblyDelistData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RoofAssemblyDelistData_Stddev_Samp_Fields>;
  sum?: Maybe<RoofAssemblyDelistData_Sum_Fields>;
  var_pop?: Maybe<RoofAssemblyDelistData_Var_Pop_Fields>;
  var_samp?: Maybe<RoofAssemblyDelistData_Var_Samp_Fields>;
  variance?: Maybe<RoofAssemblyDelistData_Variance_Fields>;
};


/** aggregate fields of "RoofAssemblyDelistData" */
export type RoofAssemblyDelistData_Aggregate_FieldsCountArgs = {
  column?: Maybe<RoofAssemblyDelistData_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type RoofAssemblyDelistData_Avg_Fields = {
  __typename?: 'RoofAssemblyDelistData_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerComponentCount?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerManufacturerCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "RoofAssemblyDelistData". All fields are combined with a logical 'AND'. */
export type RoofAssemblyDelistData_Bool_Exp = {
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  AssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  LayerComponentCount?: Maybe<Int_Mssql_Comparison_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  LayerManufacturerCount?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<RoofAssemblyDelistData_Bool_Exp>>;
  _not?: Maybe<RoofAssemblyDelistData_Bool_Exp>;
  _or?: Maybe<Array<RoofAssemblyDelistData_Bool_Exp>>;
};

/** upsert condition type for table "RoofAssemblyDelistData" */
export type RoofAssemblyDelistData_If_Matched = {
  match_columns?: Array<RoofAssemblyDelistData_Insert_Match_Column>;
  update_columns?: Array<RoofAssemblyDelistData_Update_Column>;
  where?: Maybe<RoofAssemblyDelistData_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "RoofAssemblyDelistData" */
export type RoofAssemblyDelistData_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerComponentCount?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerManufacturerCount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "RoofAssemblyDelistData" */
export type RoofAssemblyDelistData_Insert_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerComponentCount?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerManufacturerCount?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "RoofAssemblyDelistData" */
export enum RoofAssemblyDelistData_Insert_Match_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LayerComponentCount = 'LayerComponentCount',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerManufacturerCount = 'LayerManufacturerCount'
}

/** aggregate max on columns */
export type RoofAssemblyDelistData_Max_Fields = {
  __typename?: 'RoofAssemblyDelistData_max_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerComponentCount?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerManufacturerCount?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type RoofAssemblyDelistData_Min_Fields = {
  __typename?: 'RoofAssemblyDelistData_min_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerComponentCount?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerManufacturerCount?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "RoofAssemblyDelistData" */
export type RoofAssemblyDelistData_Mutation_Response = {
  __typename?: 'RoofAssemblyDelistData_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RoofAssemblyDelistData>;
};

/** Ordering options when selecting data from "RoofAssemblyDelistData". */
export type RoofAssemblyDelistData_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LayerComponentCount?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerManufacturerCount?: Maybe<Order_By>;
};

/** select columns of table "RoofAssemblyDelistData" */
export enum RoofAssemblyDelistData_Select_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LayerComponentCount = 'LayerComponentCount',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerManufacturerCount = 'LayerManufacturerCount'
}

/** input type for updating data in table "RoofAssemblyDelistData" */
export type RoofAssemblyDelistData_Set_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerComponentCount?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerManufacturerCount?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type RoofAssemblyDelistData_Stddev_Fields = {
  __typename?: 'RoofAssemblyDelistData_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerComponentCount?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerManufacturerCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RoofAssemblyDelistData_Stddev_Pop_Fields = {
  __typename?: 'RoofAssemblyDelistData_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerComponentCount?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerManufacturerCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RoofAssemblyDelistData_Stddev_Samp_Fields = {
  __typename?: 'RoofAssemblyDelistData_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerComponentCount?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerManufacturerCount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type RoofAssemblyDelistData_Sum_Fields = {
  __typename?: 'RoofAssemblyDelistData_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerComponentCount?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerManufacturerCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "RoofAssemblyDelistData" */
export enum RoofAssemblyDelistData_Update_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LayerComponentCount = 'LayerComponentCount',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerManufacturerCount = 'LayerManufacturerCount'
}

/** aggregate var_pop on columns */
export type RoofAssemblyDelistData_Var_Pop_Fields = {
  __typename?: 'RoofAssemblyDelistData_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerComponentCount?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerManufacturerCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RoofAssemblyDelistData_Var_Samp_Fields = {
  __typename?: 'RoofAssemblyDelistData_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerComponentCount?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerManufacturerCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RoofAssemblyDelistData_Variance_Fields = {
  __typename?: 'RoofAssemblyDelistData_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerComponentCount?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerManufacturerCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "RoofAssemblyLayers" */
export type RoofAssemblyLayers = {
  __typename?: 'RoofAssemblyLayers';
  AssemblyId: Scalars['Int'];
  /** An object relationship */
  Layer: Layer;
  LayerId: Scalars['Int'];
  /** An object relationship */
  RoofAssembly: RoofAssembly;
};

/** aggregated selection of "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Aggregate = {
  __typename?: 'RoofAssemblyLayers_aggregate';
  aggregate?: Maybe<RoofAssemblyLayers_Aggregate_Fields>;
  nodes: Array<RoofAssemblyLayers>;
};

/** aggregate fields of "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Aggregate_Fields = {
  __typename?: 'RoofAssemblyLayers_aggregate_fields';
  avg?: Maybe<RoofAssemblyLayers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RoofAssemblyLayers_Max_Fields>;
  min?: Maybe<RoofAssemblyLayers_Min_Fields>;
  stddev?: Maybe<RoofAssemblyLayers_Stddev_Fields>;
  stddev_pop?: Maybe<RoofAssemblyLayers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RoofAssemblyLayers_Stddev_Samp_Fields>;
  sum?: Maybe<RoofAssemblyLayers_Sum_Fields>;
  var_pop?: Maybe<RoofAssemblyLayers_Var_Pop_Fields>;
  var_samp?: Maybe<RoofAssemblyLayers_Var_Samp_Fields>;
  variance?: Maybe<RoofAssemblyLayers_Variance_Fields>;
};


/** aggregate fields of "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Aggregate_FieldsCountArgs = {
  column?: Maybe<RoofAssemblyLayers_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Aggregate_Order_By = {
  avg?: Maybe<RoofAssemblyLayers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<RoofAssemblyLayers_Max_Order_By>;
  min?: Maybe<RoofAssemblyLayers_Min_Order_By>;
  stddev?: Maybe<RoofAssemblyLayers_Stddev_Order_By>;
  stddev_pop?: Maybe<RoofAssemblyLayers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<RoofAssemblyLayers_Stddev_Samp_Order_By>;
  sum?: Maybe<RoofAssemblyLayers_Sum_Order_By>;
  var_pop?: Maybe<RoofAssemblyLayers_Var_Pop_Order_By>;
  var_samp?: Maybe<RoofAssemblyLayers_Var_Samp_Order_By>;
  variance?: Maybe<RoofAssemblyLayers_Variance_Order_By>;
};

/** aggregate avg on columns */
export type RoofAssemblyLayers_Avg_Fields = {
  __typename?: 'RoofAssemblyLayers_avg_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Avg_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "RoofAssemblyLayers". All fields are combined with a logical 'AND'. */
export type RoofAssemblyLayers_Bool_Exp = {
  AssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  Layer?: Maybe<Layer_Bool_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  RoofAssembly?: Maybe<RoofAssembly_Bool_Exp>;
  _and?: Maybe<Array<RoofAssemblyLayers_Bool_Exp>>;
  _not?: Maybe<RoofAssemblyLayers_Bool_Exp>;
  _or?: Maybe<Array<RoofAssemblyLayers_Bool_Exp>>;
};

/** upsert condition type for table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_If_Matched = {
  match_columns?: Array<RoofAssemblyLayers_Insert_Match_Column>;
  update_columns?: Array<RoofAssemblyLayers_Update_Column>;
  where?: Maybe<RoofAssemblyLayers_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Inc_Input = {
  AssemblyId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Insert_Input = {
  AssemblyId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "RoofAssemblyLayers" */
export enum RoofAssemblyLayers_Insert_Match_Column {
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  LayerId = 'LayerId'
}

/** aggregate max on columns */
export type RoofAssemblyLayers_Max_Fields = {
  __typename?: 'RoofAssemblyLayers_max_fields';
  AssemblyId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Max_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type RoofAssemblyLayers_Min_Fields = {
  __typename?: 'RoofAssemblyLayers_min_fields';
  AssemblyId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Min_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
};

/** response of any mutation on the table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Mutation_Response = {
  __typename?: 'RoofAssemblyLayers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RoofAssemblyLayers>;
};

/** Ordering options when selecting data from "RoofAssemblyLayers". */
export type RoofAssemblyLayers_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  Layer?: Maybe<Layer_Order_By>;
  LayerId?: Maybe<Order_By>;
  RoofAssembly?: Maybe<RoofAssembly_Order_By>;
};

/** primary key columns input for table: RoofAssemblyLayers */
export type RoofAssemblyLayers_Pk_Columns_Input = {
  AssemblyId: Scalars['Int'];
  LayerId: Scalars['Int'];
};

/** select columns of table "RoofAssemblyLayers" */
export enum RoofAssemblyLayers_Select_Column {
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  LayerId = 'LayerId'
}

/** input type for updating data in table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Set_Input = {
  AssemblyId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type RoofAssemblyLayers_Stddev_Fields = {
  __typename?: 'RoofAssemblyLayers_stddev_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Stddev_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type RoofAssemblyLayers_Stddev_Pop_Fields = {
  __typename?: 'RoofAssemblyLayers_stddev_pop_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Stddev_Pop_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type RoofAssemblyLayers_Stddev_Samp_Fields = {
  __typename?: 'RoofAssemblyLayers_stddev_samp_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Stddev_Samp_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type RoofAssemblyLayers_Sum_Fields = {
  __typename?: 'RoofAssemblyLayers_sum_fields';
  AssemblyId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Sum_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
};

/** update columns of table "RoofAssemblyLayers" */
export enum RoofAssemblyLayers_Update_Column {
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  LayerId = 'LayerId'
}

/** aggregate var_pop on columns */
export type RoofAssemblyLayers_Var_Pop_Fields = {
  __typename?: 'RoofAssemblyLayers_var_pop_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Var_Pop_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type RoofAssemblyLayers_Var_Samp_Fields = {
  __typename?: 'RoofAssemblyLayers_var_samp_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Var_Samp_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type RoofAssemblyLayers_Variance_Fields = {
  __typename?: 'RoofAssemblyLayers_variance_fields';
  AssemblyId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "RoofAssemblyLayers" */
export type RoofAssemblyLayers_Variance_Order_By = {
  AssemblyId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
};

/** aggregated selection of "RoofAssembly" */
export type RoofAssembly_Aggregate = {
  __typename?: 'RoofAssembly_aggregate';
  aggregate?: Maybe<RoofAssembly_Aggregate_Fields>;
  nodes: Array<RoofAssembly>;
};

/** aggregate fields of "RoofAssembly" */
export type RoofAssembly_Aggregate_Fields = {
  __typename?: 'RoofAssembly_aggregate_fields';
  avg?: Maybe<RoofAssembly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RoofAssembly_Max_Fields>;
  min?: Maybe<RoofAssembly_Min_Fields>;
  stddev?: Maybe<RoofAssembly_Stddev_Fields>;
  stddev_pop?: Maybe<RoofAssembly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RoofAssembly_Stddev_Samp_Fields>;
  sum?: Maybe<RoofAssembly_Sum_Fields>;
  var_pop?: Maybe<RoofAssembly_Var_Pop_Fields>;
  var_samp?: Maybe<RoofAssembly_Var_Samp_Fields>;
  variance?: Maybe<RoofAssembly_Variance_Fields>;
};


/** aggregate fields of "RoofAssembly" */
export type RoofAssembly_Aggregate_FieldsCountArgs = {
  column?: Maybe<RoofAssembly_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "RoofAssembly" */
export type RoofAssembly_Aggregate_Order_By = {
  avg?: Maybe<RoofAssembly_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<RoofAssembly_Max_Order_By>;
  min?: Maybe<RoofAssembly_Min_Order_By>;
  stddev?: Maybe<RoofAssembly_Stddev_Order_By>;
  stddev_pop?: Maybe<RoofAssembly_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<RoofAssembly_Stddev_Samp_Order_By>;
  sum?: Maybe<RoofAssembly_Sum_Order_By>;
  var_pop?: Maybe<RoofAssembly_Var_Pop_Order_By>;
  var_samp?: Maybe<RoofAssembly_Var_Samp_Order_By>;
  variance?: Maybe<RoofAssembly_Variance_Order_By>;
};

/** aggregate avg on columns */
export type RoofAssembly_Avg_Fields = {
  __typename?: 'RoofAssembly_avg_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  LayerConfigId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
  PVHailRatingId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "RoofAssembly" */
export type RoofAssembly_Avg_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "RoofAssembly". All fields are combined with a logical 'AND'. */
export type RoofAssembly_Bool_Exp = {
  AssemblyApplicationTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  AssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  AssemblyNum?: Maybe<String_Mssql_Comparison_Exp>;
  AssemblyTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_AssemblyApplicationType?: Maybe<C_AssemblyApplicationType_Bool_Exp>;
  C_AssemblyType?: Maybe<C_AssemblyType_Bool_Exp>;
  C_CoverSecurementMethod?: Maybe<C_CoverSecurementMethod_Bool_Exp>;
  C_DeckType?: Maybe<C_DeckType_Bool_Exp>;
  C_ExtFireRating?: Maybe<C_ExtFireRating_Bool_Exp>;
  C_HailRating?: Maybe<C_HailRating_Bool_Exp>;
  C_IntFireRating?: Maybe<C_IntFireRating_Bool_Exp>;
  C_LockStatus?: Maybe<C_LockStatus_Bool_Exp>;
  C_PVApplicationMethod?: Maybe<C_PvApplicationMethod_Bool_Exp>;
  C_PVHailRating?: Maybe<C_PvHailRating_Bool_Exp>;
  C_Status?: Maybe<C_Status_Bool_Exp>;
  Comments?: Maybe<ItemComment_Mssql_Comparison_Exp>;
  CoverSecurementMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  CreatedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateCreated?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateEffective?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateInception?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateLocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  DeckTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ExtFireRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  FMUserAccount?: Maybe<FmUserAccount_Bool_Exp>;
  HailRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  IncludesPV?: Maybe<Boolean_Mssql_Comparison_Exp>;
  IntFireRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  IsUsedWithCombustibleWalls?: Maybe<Boolean_Mssql_Comparison_Exp>;
  LayerConfig?: Maybe<LayerConfig_Bool_Exp>;
  LayerConfigId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblies?: Maybe<NavAssembly_Bool_Exp>;
  NavLayers?: Maybe<NavLayer_Bool_Exp>;
  PVApplicationMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  PVHailRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  PVWindUplift?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  RoofAssemblyLayers?: Maybe<RoofAssemblyLayers_Bool_Exp>;
  Slope?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  VersionMajor?: Maybe<Int_Mssql_Comparison_Exp>;
  VersionMinor?: Maybe<Int_Mssql_Comparison_Exp>;
  WindSpeed?: Maybe<Int_Mssql_Comparison_Exp>;
  WindUplift?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<RoofAssembly_Bool_Exp>>;
  _not?: Maybe<RoofAssembly_Bool_Exp>;
  _or?: Maybe<Array<RoofAssembly_Bool_Exp>>;
  fMUserAccountByLockedbyuseraccountid?: Maybe<FmUserAccount_Bool_Exp>;
  fMUserAccountByModifiedbyuseraccountid?: Maybe<FmUserAccount_Bool_Exp>;
};

/** upsert condition type for table "RoofAssembly" */
export type RoofAssembly_If_Matched = {
  match_columns?: Array<RoofAssembly_Insert_Match_Column>;
  update_columns?: Array<RoofAssembly_Update_Column>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "RoofAssembly" */
export type RoofAssembly_Inc_Input = {
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
  WindSpeed?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "RoofAssembly" */
export type RoofAssembly_Insert_Input = {
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyNum?: Maybe<Scalars['String']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateInception?: Maybe<Scalars['datetime']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IncludesPV?: Maybe<Scalars['Boolean']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  IsUsedWithCombustibleWalls?: Maybe<Scalars['Boolean']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  PVWindUplift?: Maybe<Scalars['MeasurementValue']>;
  Slope?: Maybe<Scalars['MeasurementValue']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
  WindSpeed?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "RoofAssembly" */
export enum RoofAssembly_Insert_Match_Column {
  /** column name */
  AssemblyApplicationTypeId = 'AssemblyApplicationTypeId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  AssemblyNum = 'AssemblyNum',
  /** column name */
  AssemblyTypeId = 'AssemblyTypeId',
  /** column name */
  Comments = 'Comments',
  /** column name */
  CoverSecurementMethodId = 'CoverSecurementMethodId',
  /** column name */
  CreatedByUserAccountId = 'CreatedByUserAccountId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  DateEffective = 'DateEffective',
  /** column name */
  DateInception = 'DateInception',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  DeckTypeId = 'DeckTypeId',
  /** column name */
  ExtFireRatingId = 'ExtFireRatingId',
  /** column name */
  HailRatingId = 'HailRatingId',
  /** column name */
  IncludesPv = 'IncludesPV',
  /** column name */
  IntFireRatingId = 'IntFireRatingId',
  /** column name */
  IsUsedWithCombustibleWalls = 'IsUsedWithCombustibleWalls',
  /** column name */
  LayerConfigId = 'LayerConfigId',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  PvApplicationMethodId = 'PVApplicationMethodId',
  /** column name */
  PvHailRatingId = 'PVHailRatingId',
  /** column name */
  PvWindUplift = 'PVWindUplift',
  /** column name */
  Slope = 'Slope',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  VersionMajor = 'VersionMajor',
  /** column name */
  VersionMinor = 'VersionMinor',
  /** column name */
  WindSpeed = 'WindSpeed',
  /** column name */
  WindUplift = 'WindUplift'
}

/** aggregate max on columns */
export type RoofAssembly_Max_Fields = {
  __typename?: 'RoofAssembly_max_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyNum?: Maybe<Scalars['String']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateInception?: Maybe<Scalars['datetime']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  PVWindUplift?: Maybe<Scalars['MeasurementValue']>;
  Slope?: Maybe<Scalars['MeasurementValue']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
  WindSpeed?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "RoofAssembly" */
export type RoofAssembly_Max_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyNum?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  Comments?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  DateEffective?: Maybe<Order_By>;
  DateInception?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  PVWindUplift?: Maybe<Order_By>;
  Slope?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type RoofAssembly_Min_Fields = {
  __typename?: 'RoofAssembly_min_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyNum?: Maybe<Scalars['String']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateInception?: Maybe<Scalars['datetime']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  PVWindUplift?: Maybe<Scalars['MeasurementValue']>;
  Slope?: Maybe<Scalars['MeasurementValue']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
  WindSpeed?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "RoofAssembly" */
export type RoofAssembly_Min_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyNum?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  Comments?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  DateEffective?: Maybe<Order_By>;
  DateInception?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  PVWindUplift?: Maybe<Order_By>;
  Slope?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
};

/** response of any mutation on the table "RoofAssembly" */
export type RoofAssembly_Mutation_Response = {
  __typename?: 'RoofAssembly_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RoofAssembly>;
};

/** Ordering options when selecting data from "RoofAssembly". */
export type RoofAssembly_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyNum?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  C_AssemblyApplicationType?: Maybe<C_AssemblyApplicationType_Order_By>;
  C_AssemblyType?: Maybe<C_AssemblyType_Order_By>;
  C_CoverSecurementMethod?: Maybe<C_CoverSecurementMethod_Order_By>;
  C_DeckType?: Maybe<C_DeckType_Order_By>;
  C_ExtFireRating?: Maybe<C_ExtFireRating_Order_By>;
  C_HailRating?: Maybe<C_HailRating_Order_By>;
  C_IntFireRating?: Maybe<C_IntFireRating_Order_By>;
  C_LockStatus?: Maybe<C_LockStatus_Order_By>;
  C_PVApplicationMethod?: Maybe<C_PvApplicationMethod_Order_By>;
  C_PVHailRating?: Maybe<C_PvHailRating_Order_By>;
  C_Status?: Maybe<C_Status_Order_By>;
  Comments?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  DateEffective?: Maybe<Order_By>;
  DateInception?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  FMUserAccount?: Maybe<FmUserAccount_Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IncludesPV?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  IsUsedWithCombustibleWalls?: Maybe<Order_By>;
  LayerConfig?: Maybe<LayerConfig_Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  NavAssemblies_aggregate?: Maybe<NavAssembly_Aggregate_Order_By>;
  NavLayers_aggregate?: Maybe<NavLayer_Aggregate_Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  PVWindUplift?: Maybe<Order_By>;
  RoofAssemblyLayers_aggregate?: Maybe<RoofAssemblyLayers_Aggregate_Order_By>;
  Slope?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
  fMUserAccountByLockedbyuseraccountid?: Maybe<FmUserAccount_Order_By>;
  fMUserAccountByModifiedbyuseraccountid?: Maybe<FmUserAccount_Order_By>;
};

/** primary key columns input for table: RoofAssembly */
export type RoofAssembly_Pk_Columns_Input = {
  AssemblyId: Scalars['Int'];
};

/** select columns of table "RoofAssembly" */
export enum RoofAssembly_Select_Column {
  /** column name */
  AssemblyApplicationTypeId = 'AssemblyApplicationTypeId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  AssemblyNum = 'AssemblyNum',
  /** column name */
  AssemblyTypeId = 'AssemblyTypeId',
  /** column name */
  Comments = 'Comments',
  /** column name */
  CoverSecurementMethodId = 'CoverSecurementMethodId',
  /** column name */
  CreatedByUserAccountId = 'CreatedByUserAccountId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  DateEffective = 'DateEffective',
  /** column name */
  DateInception = 'DateInception',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  DeckTypeId = 'DeckTypeId',
  /** column name */
  ExtFireRatingId = 'ExtFireRatingId',
  /** column name */
  HailRatingId = 'HailRatingId',
  /** column name */
  IncludesPv = 'IncludesPV',
  /** column name */
  IntFireRatingId = 'IntFireRatingId',
  /** column name */
  IsUsedWithCombustibleWalls = 'IsUsedWithCombustibleWalls',
  /** column name */
  LayerConfigId = 'LayerConfigId',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  PvApplicationMethodId = 'PVApplicationMethodId',
  /** column name */
  PvHailRatingId = 'PVHailRatingId',
  /** column name */
  PvWindUplift = 'PVWindUplift',
  /** column name */
  Slope = 'Slope',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  VersionMajor = 'VersionMajor',
  /** column name */
  VersionMinor = 'VersionMinor',
  /** column name */
  WindSpeed = 'WindSpeed',
  /** column name */
  WindUplift = 'WindUplift'
}

/** input type for updating data in table "RoofAssembly" */
export type RoofAssembly_Set_Input = {
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyNum?: Maybe<Scalars['String']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  DateEffective?: Maybe<Scalars['datetime']>;
  DateInception?: Maybe<Scalars['datetime']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  DateModified?: Maybe<Scalars['datetime']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IncludesPV?: Maybe<Scalars['Boolean']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  IsUsedWithCombustibleWalls?: Maybe<Scalars['Boolean']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  PVWindUplift?: Maybe<Scalars['MeasurementValue']>;
  Slope?: Maybe<Scalars['MeasurementValue']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
  WindSpeed?: Maybe<Scalars['Int']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type RoofAssembly_Stddev_Fields = {
  __typename?: 'RoofAssembly_stddev_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  LayerConfigId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
  PVHailRatingId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "RoofAssembly" */
export type RoofAssembly_Stddev_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type RoofAssembly_Stddev_Pop_Fields = {
  __typename?: 'RoofAssembly_stddev_pop_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  LayerConfigId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
  PVHailRatingId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "RoofAssembly" */
export type RoofAssembly_Stddev_Pop_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type RoofAssembly_Stddev_Samp_Fields = {
  __typename?: 'RoofAssembly_stddev_samp_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  LayerConfigId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
  PVHailRatingId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "RoofAssembly" */
export type RoofAssembly_Stddev_Samp_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type RoofAssembly_Sum_Fields = {
  __typename?: 'RoofAssembly_sum_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  CreatedByUserAccountId?: Maybe<Scalars['Int']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  PVApplicationMethodId?: Maybe<Scalars['Int']>;
  PVHailRatingId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  VersionMajor?: Maybe<Scalars['Int']>;
  VersionMinor?: Maybe<Scalars['Int']>;
  WindSpeed?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "RoofAssembly" */
export type RoofAssembly_Sum_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** update columns of table "RoofAssembly" */
export enum RoofAssembly_Update_Column {
  /** column name */
  AssemblyApplicationTypeId = 'AssemblyApplicationTypeId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  AssemblyNum = 'AssemblyNum',
  /** column name */
  AssemblyTypeId = 'AssemblyTypeId',
  /** column name */
  Comments = 'Comments',
  /** column name */
  CoverSecurementMethodId = 'CoverSecurementMethodId',
  /** column name */
  CreatedByUserAccountId = 'CreatedByUserAccountId',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  DateEffective = 'DateEffective',
  /** column name */
  DateInception = 'DateInception',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  DeckTypeId = 'DeckTypeId',
  /** column name */
  ExtFireRatingId = 'ExtFireRatingId',
  /** column name */
  HailRatingId = 'HailRatingId',
  /** column name */
  IncludesPv = 'IncludesPV',
  /** column name */
  IntFireRatingId = 'IntFireRatingId',
  /** column name */
  IsUsedWithCombustibleWalls = 'IsUsedWithCombustibleWalls',
  /** column name */
  LayerConfigId = 'LayerConfigId',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountId = 'LockedByUserAccountId',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  PvApplicationMethodId = 'PVApplicationMethodId',
  /** column name */
  PvHailRatingId = 'PVHailRatingId',
  /** column name */
  PvWindUplift = 'PVWindUplift',
  /** column name */
  Slope = 'Slope',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  VersionMajor = 'VersionMajor',
  /** column name */
  VersionMinor = 'VersionMinor',
  /** column name */
  WindSpeed = 'WindSpeed',
  /** column name */
  WindUplift = 'WindUplift'
}

/** aggregate var_pop on columns */
export type RoofAssembly_Var_Pop_Fields = {
  __typename?: 'RoofAssembly_var_pop_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  LayerConfigId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
  PVHailRatingId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "RoofAssembly" */
export type RoofAssembly_Var_Pop_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type RoofAssembly_Var_Samp_Fields = {
  __typename?: 'RoofAssembly_var_samp_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  LayerConfigId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
  PVHailRatingId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "RoofAssembly" */
export type RoofAssembly_Var_Samp_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type RoofAssembly_Variance_Fields = {
  __typename?: 'RoofAssembly_variance_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  CreatedByUserAccountId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  LayerConfigId?: Maybe<Scalars['Float']>;
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  PVApplicationMethodId?: Maybe<Scalars['Float']>;
  PVHailRatingId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  VersionMajor?: Maybe<Scalars['Float']>;
  VersionMinor?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "RoofAssembly" */
export type RoofAssembly_Variance_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  CreatedByUserAccountId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountId?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  PVApplicationMethodId?: Maybe<Order_By>;
  PVHailRatingId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  VersionMajor?: Maybe<Order_By>;
  VersionMinor?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** columns and relationships of "RoofProject" */
export type RoofProject = {
  __typename?: 'RoofProject';
  /** An object relationship */
  Address?: Maybe<Address>;
  AddressId?: Maybe<Scalars['Int']>;
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  IndexNumber?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Notes?: Maybe<Scalars['String']>;
  ProjectId: Scalars['Int'];
  /** An array relationship */
  R_ProjectContacts: Array<R_ProjectContact>;
  /** An aggregate relationship */
  R_ProjectContacts_aggregate: R_ProjectContact_Aggregate;
  /** An array relationship */
  R_ProjectNotes: Array<R_ProjectNote>;
  /** An aggregate relationship */
  R_ProjectNotes_aggregate: R_ProjectNote_Aggregate;
  /** An array relationship */
  RoofAreas: Array<RoofArea>;
  /** An aggregate relationship */
  RoofAreas_aggregate: RoofArea_Aggregate;
  UserAccountId: Scalars['Int'];
};


/** columns and relationships of "RoofProject" */
export type RoofProjectR_ProjectContactsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectContact_Order_By>>;
  where?: Maybe<R_ProjectContact_Bool_Exp>;
};


/** columns and relationships of "RoofProject" */
export type RoofProjectR_ProjectContacts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectContact_Order_By>>;
  where?: Maybe<R_ProjectContact_Bool_Exp>;
};


/** columns and relationships of "RoofProject" */
export type RoofProjectR_ProjectNotesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectNote_Order_By>>;
  where?: Maybe<R_ProjectNote_Bool_Exp>;
};


/** columns and relationships of "RoofProject" */
export type RoofProjectR_ProjectNotes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectNote_Order_By>>;
  where?: Maybe<R_ProjectNote_Bool_Exp>;
};


/** columns and relationships of "RoofProject" */
export type RoofProjectRoofAreasArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};


/** columns and relationships of "RoofProject" */
export type RoofProjectRoofAreas_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};

export type RoofProjectBaseInfo = {
  Address?: Maybe<AddressInfo>;
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IndexNumber?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Notes?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

export type RoofProjectBaseInfoSave = {
  Project?: Maybe<RoofProjectBaseInfo>;
  SaveOperationType: Scalars['Int'];
};

export type RoofProjectBaseInfoSaveResult = {
  __typename?: 'RoofProjectBaseInfoSaveResult';
  ProjectId: Scalars['Int'];
};

export type RoofProjectNoteSave = {
  ProjectId?: Maybe<Scalars['Int']>;
  ProjectNote?: Maybe<NoteInfo>;
  SaveOperationType: Scalars['Int'];
};

export type RoofProjectNoteSaveResult = {
  __typename?: 'RoofProjectNoteSaveResult';
  NoteId: Scalars['Int'];
};

/** aggregated selection of "RoofProject" */
export type RoofProject_Aggregate = {
  __typename?: 'RoofProject_aggregate';
  aggregate?: Maybe<RoofProject_Aggregate_Fields>;
  nodes: Array<RoofProject>;
};

/** aggregate fields of "RoofProject" */
export type RoofProject_Aggregate_Fields = {
  __typename?: 'RoofProject_aggregate_fields';
  avg?: Maybe<RoofProject_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RoofProject_Max_Fields>;
  min?: Maybe<RoofProject_Min_Fields>;
  stddev?: Maybe<RoofProject_Stddev_Fields>;
  stddev_pop?: Maybe<RoofProject_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RoofProject_Stddev_Samp_Fields>;
  sum?: Maybe<RoofProject_Sum_Fields>;
  var_pop?: Maybe<RoofProject_Var_Pop_Fields>;
  var_samp?: Maybe<RoofProject_Var_Samp_Fields>;
  variance?: Maybe<RoofProject_Variance_Fields>;
};


/** aggregate fields of "RoofProject" */
export type RoofProject_Aggregate_FieldsCountArgs = {
  column?: Maybe<RoofProject_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "RoofProject" */
export type RoofProject_Aggregate_Order_By = {
  avg?: Maybe<RoofProject_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<RoofProject_Max_Order_By>;
  min?: Maybe<RoofProject_Min_Order_By>;
  stddev?: Maybe<RoofProject_Stddev_Order_By>;
  stddev_pop?: Maybe<RoofProject_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<RoofProject_Stddev_Samp_Order_By>;
  sum?: Maybe<RoofProject_Sum_Order_By>;
  var_pop?: Maybe<RoofProject_Var_Pop_Order_By>;
  var_samp?: Maybe<RoofProject_Var_Samp_Order_By>;
  variance?: Maybe<RoofProject_Variance_Order_By>;
};

/** aggregate avg on columns */
export type RoofProject_Avg_Fields = {
  __typename?: 'RoofProject_avg_fields';
  AddressId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "RoofProject" */
export type RoofProject_Avg_Order_By = {
  AddressId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "RoofProject". All fields are combined with a logical 'AND'. */
export type RoofProject_Bool_Exp = {
  Address?: Maybe<Address_Bool_Exp>;
  AddressId?: Maybe<Int_Mssql_Comparison_Exp>;
  AzureB2CUserEmail?: Maybe<String_Mssql_Comparison_Exp>;
  AzureB2CUserGuid?: Maybe<String_Mssql_Comparison_Exp>;
  Description?: Maybe<String_Mssql_Comparison_Exp>;
  IndexNumber?: Maybe<String_Mssql_Comparison_Exp>;
  Name?: Maybe<String_Mssql_Comparison_Exp>;
  Notes?: Maybe<String_Mssql_Comparison_Exp>;
  ProjectId?: Maybe<Int_Mssql_Comparison_Exp>;
  R_ProjectContacts?: Maybe<R_ProjectContact_Bool_Exp>;
  R_ProjectNotes?: Maybe<R_ProjectNote_Bool_Exp>;
  RoofAreas?: Maybe<RoofArea_Bool_Exp>;
  UserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<RoofProject_Bool_Exp>>;
  _not?: Maybe<RoofProject_Bool_Exp>;
  _or?: Maybe<Array<RoofProject_Bool_Exp>>;
};

/** upsert condition type for table "RoofProject" */
export type RoofProject_If_Matched = {
  match_columns?: Array<RoofProject_Insert_Match_Column>;
  update_columns?: Array<RoofProject_Update_Column>;
  where?: Maybe<RoofProject_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "RoofProject" */
export type RoofProject_Inc_Input = {
  AddressId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "RoofProject" */
export type RoofProject_Insert_Input = {
  AddressId?: Maybe<Scalars['Int']>;
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IndexNumber?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Notes?: Maybe<Scalars['String']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "RoofProject" */
export enum RoofProject_Insert_Match_Column {
  /** column name */
  AddressId = 'AddressId',
  /** column name */
  AzureB2CUserEmail = 'AzureB2CUserEmail',
  /** column name */
  AzureB2CUserGuid = 'AzureB2CUserGuid',
  /** column name */
  Description = 'Description',
  /** column name */
  IndexNumber = 'IndexNumber',
  /** column name */
  Name = 'Name',
  /** column name */
  Notes = 'Notes',
  /** column name */
  ProjectId = 'ProjectId',
  /** column name */
  UserAccountId = 'UserAccountId'
}

/** aggregate max on columns */
export type RoofProject_Max_Fields = {
  __typename?: 'RoofProject_max_fields';
  AddressId?: Maybe<Scalars['Int']>;
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IndexNumber?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Notes?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "RoofProject" */
export type RoofProject_Max_Order_By = {
  AddressId?: Maybe<Order_By>;
  AzureB2CUserEmail?: Maybe<Order_By>;
  AzureB2CUserGuid?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  IndexNumber?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type RoofProject_Min_Fields = {
  __typename?: 'RoofProject_min_fields';
  AddressId?: Maybe<Scalars['Int']>;
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IndexNumber?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Notes?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "RoofProject" */
export type RoofProject_Min_Order_By = {
  AddressId?: Maybe<Order_By>;
  AzureB2CUserEmail?: Maybe<Order_By>;
  AzureB2CUserGuid?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  IndexNumber?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** response of any mutation on the table "RoofProject" */
export type RoofProject_Mutation_Response = {
  __typename?: 'RoofProject_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RoofProject>;
};

/** Ordering options when selecting data from "RoofProject". */
export type RoofProject_Order_By = {
  Address?: Maybe<Address_Order_By>;
  AddressId?: Maybe<Order_By>;
  AzureB2CUserEmail?: Maybe<Order_By>;
  AzureB2CUserGuid?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  IndexNumber?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  R_ProjectContacts_aggregate?: Maybe<R_ProjectContact_Aggregate_Order_By>;
  R_ProjectNotes_aggregate?: Maybe<R_ProjectNote_Aggregate_Order_By>;
  RoofAreas_aggregate?: Maybe<RoofArea_Aggregate_Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** primary key columns input for table: RoofProject */
export type RoofProject_Pk_Columns_Input = {
  ProjectId: Scalars['Int'];
};

/** select columns of table "RoofProject" */
export enum RoofProject_Select_Column {
  /** column name */
  AddressId = 'AddressId',
  /** column name */
  AzureB2CUserEmail = 'AzureB2CUserEmail',
  /** column name */
  AzureB2CUserGuid = 'AzureB2CUserGuid',
  /** column name */
  Description = 'Description',
  /** column name */
  IndexNumber = 'IndexNumber',
  /** column name */
  Name = 'Name',
  /** column name */
  Notes = 'Notes',
  /** column name */
  ProjectId = 'ProjectId',
  /** column name */
  UserAccountId = 'UserAccountId'
}

/** input type for updating data in table "RoofProject" */
export type RoofProject_Set_Input = {
  AddressId?: Maybe<Scalars['Int']>;
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IndexNumber?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Notes?: Maybe<Scalars['String']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type RoofProject_Stddev_Fields = {
  __typename?: 'RoofProject_stddev_fields';
  AddressId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "RoofProject" */
export type RoofProject_Stddev_Order_By = {
  AddressId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type RoofProject_Stddev_Pop_Fields = {
  __typename?: 'RoofProject_stddev_pop_fields';
  AddressId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "RoofProject" */
export type RoofProject_Stddev_Pop_Order_By = {
  AddressId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type RoofProject_Stddev_Samp_Fields = {
  __typename?: 'RoofProject_stddev_samp_fields';
  AddressId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "RoofProject" */
export type RoofProject_Stddev_Samp_Order_By = {
  AddressId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type RoofProject_Sum_Fields = {
  __typename?: 'RoofProject_sum_fields';
  AddressId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "RoofProject" */
export type RoofProject_Sum_Order_By = {
  AddressId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** update columns of table "RoofProject" */
export enum RoofProject_Update_Column {
  /** column name */
  AddressId = 'AddressId',
  /** column name */
  AzureB2CUserEmail = 'AzureB2CUserEmail',
  /** column name */
  AzureB2CUserGuid = 'AzureB2CUserGuid',
  /** column name */
  Description = 'Description',
  /** column name */
  IndexNumber = 'IndexNumber',
  /** column name */
  Name = 'Name',
  /** column name */
  Notes = 'Notes',
  /** column name */
  UserAccountId = 'UserAccountId'
}

/** aggregate var_pop on columns */
export type RoofProject_Var_Pop_Fields = {
  __typename?: 'RoofProject_var_pop_fields';
  AddressId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "RoofProject" */
export type RoofProject_Var_Pop_Order_By = {
  AddressId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type RoofProject_Var_Samp_Fields = {
  __typename?: 'RoofProject_var_samp_fields';
  AddressId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "RoofProject" */
export type RoofProject_Var_Samp_Order_By = {
  AddressId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type RoofProject_Variance_Fields = {
  __typename?: 'RoofProject_variance_fields';
  AddressId?: Maybe<Scalars['Float']>;
  ProjectId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "RoofProject" */
export type RoofProject_Variance_Order_By = {
  AddressId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

export type SaveContactInfo = {
  Contact?: Maybe<ContactDetail>;
  SaveOperationType: Scalars['Int'];
};

export type SaveRoofAreaBaseInfo = {
  RoofArea?: Maybe<RoofAreaBaseInfo>;
  SaveOperationType: Scalars['Int'];
};

export type SaveRoofAreaWithRating = {
  RoofAreaBase?: Maybe<RoofAreaBaseInfo>;
  RoofAreaRatingInput?: Maybe<IRoofAreaRatingInput>;
  RoofAreaRatingResult?: Maybe<Array<Maybe<IRoofAreaWindPressure>>>;
  SaveOperationType: Scalars['Int'];
};

/** columns and relationships of "Scenario" */
export type Scenario = {
  __typename?: 'Scenario';
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  /** An object relationship */
  C_ScenarioStatus: C_ScenarioStatus;
  /** An object relationship */
  C_ScenarioType: C_ScenarioType;
  Comments: Scalars['ItemComment'];
  DateCreated: Scalars['datetime'];
  Name: Scalars['String'];
  /** An object relationship */
  NavAssembly?: Maybe<NavAssembly>;
  NavAssemblyId: Scalars['Int'];
  NavAssemblyName: Scalars['String'];
  /** An array relationship */
  R_RoofAreaScenarios: Array<R_RoofAreaScenario>;
  /** An aggregate relationship */
  R_RoofAreaScenarios_aggregate: R_RoofAreaScenario_Aggregate;
  /** An array relationship */
  R_ScenarioNotes: Array<R_ScenarioNote>;
  /** An aggregate relationship */
  R_ScenarioNotes_aggregate: R_ScenarioNote_Aggregate;
  /** An array relationship */
  ScenarioAlerts: Array<ScenarioAlert>;
  /** An aggregate relationship */
  ScenarioAlerts_aggregate: ScenarioAlert_Aggregate;
  ScenarioId: Scalars['Int'];
  /** An array relationship */
  ScenarioLayers: Array<ScenarioLayer>;
  /** An aggregate relationship */
  ScenarioLayers_aggregate: ScenarioLayer_Aggregate;
  ScenarioStatusId: Scalars['Int'];
  ScenarioTypeId: Scalars['Int'];
  UserAccountId: Scalars['Int'];
};


/** columns and relationships of "Scenario" */
export type ScenarioR_RoofAreaScenariosArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaScenario_Order_By>>;
  where?: Maybe<R_RoofAreaScenario_Bool_Exp>;
};


/** columns and relationships of "Scenario" */
export type ScenarioR_RoofAreaScenarios_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaScenario_Order_By>>;
  where?: Maybe<R_RoofAreaScenario_Bool_Exp>;
};


/** columns and relationships of "Scenario" */
export type ScenarioR_ScenarioNotesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ScenarioNote_Order_By>>;
  where?: Maybe<R_ScenarioNote_Bool_Exp>;
};


/** columns and relationships of "Scenario" */
export type ScenarioR_ScenarioNotes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ScenarioNote_Order_By>>;
  where?: Maybe<R_ScenarioNote_Bool_Exp>;
};


/** columns and relationships of "Scenario" */
export type ScenarioScenarioAlertsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioAlert_Order_By>>;
  where?: Maybe<ScenarioAlert_Bool_Exp>;
};


/** columns and relationships of "Scenario" */
export type ScenarioScenarioAlerts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioAlert_Order_By>>;
  where?: Maybe<ScenarioAlert_Bool_Exp>;
};


/** columns and relationships of "Scenario" */
export type ScenarioScenarioLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioLayer_Order_By>>;
  where?: Maybe<ScenarioLayer_Bool_Exp>;
};


/** columns and relationships of "Scenario" */
export type ScenarioScenarioLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioLayer_Order_By>>;
  where?: Maybe<ScenarioLayer_Bool_Exp>;
};

/** columns and relationships of "ScenarioAlert" */
export type ScenarioAlert = {
  __typename?: 'ScenarioAlert';
  AlertTypeId: Scalars['Int'];
  Body: Scalars['String'];
  /** An object relationship */
  Scenario: Scenario;
  ScenarioAlertId: Scalars['Int'];
  ScenarioId: Scalars['Int'];
  Title: Scalars['String'];
};

/** aggregated selection of "ScenarioAlert" */
export type ScenarioAlert_Aggregate = {
  __typename?: 'ScenarioAlert_aggregate';
  aggregate?: Maybe<ScenarioAlert_Aggregate_Fields>;
  nodes: Array<ScenarioAlert>;
};

/** aggregate fields of "ScenarioAlert" */
export type ScenarioAlert_Aggregate_Fields = {
  __typename?: 'ScenarioAlert_aggregate_fields';
  avg?: Maybe<ScenarioAlert_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ScenarioAlert_Max_Fields>;
  min?: Maybe<ScenarioAlert_Min_Fields>;
  stddev?: Maybe<ScenarioAlert_Stddev_Fields>;
  stddev_pop?: Maybe<ScenarioAlert_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ScenarioAlert_Stddev_Samp_Fields>;
  sum?: Maybe<ScenarioAlert_Sum_Fields>;
  var_pop?: Maybe<ScenarioAlert_Var_Pop_Fields>;
  var_samp?: Maybe<ScenarioAlert_Var_Samp_Fields>;
  variance?: Maybe<ScenarioAlert_Variance_Fields>;
};


/** aggregate fields of "ScenarioAlert" */
export type ScenarioAlert_Aggregate_FieldsCountArgs = {
  column?: Maybe<ScenarioAlert_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ScenarioAlert" */
export type ScenarioAlert_Aggregate_Order_By = {
  avg?: Maybe<ScenarioAlert_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ScenarioAlert_Max_Order_By>;
  min?: Maybe<ScenarioAlert_Min_Order_By>;
  stddev?: Maybe<ScenarioAlert_Stddev_Order_By>;
  stddev_pop?: Maybe<ScenarioAlert_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ScenarioAlert_Stddev_Samp_Order_By>;
  sum?: Maybe<ScenarioAlert_Sum_Order_By>;
  var_pop?: Maybe<ScenarioAlert_Var_Pop_Order_By>;
  var_samp?: Maybe<ScenarioAlert_Var_Samp_Order_By>;
  variance?: Maybe<ScenarioAlert_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ScenarioAlert_Avg_Fields = {
  __typename?: 'ScenarioAlert_avg_fields';
  AlertTypeId?: Maybe<Scalars['Float']>;
  ScenarioAlertId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ScenarioAlert" */
export type ScenarioAlert_Avg_Order_By = {
  AlertTypeId?: Maybe<Order_By>;
  ScenarioAlertId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ScenarioAlert". All fields are combined with a logical 'AND'. */
export type ScenarioAlert_Bool_Exp = {
  AlertTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Body?: Maybe<String_Mssql_Comparison_Exp>;
  Scenario?: Maybe<Scenario_Bool_Exp>;
  ScenarioAlertId?: Maybe<Int_Mssql_Comparison_Exp>;
  ScenarioId?: Maybe<Int_Mssql_Comparison_Exp>;
  Title?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ScenarioAlert_Bool_Exp>>;
  _not?: Maybe<ScenarioAlert_Bool_Exp>;
  _or?: Maybe<Array<ScenarioAlert_Bool_Exp>>;
};

/** upsert condition type for table "ScenarioAlert" */
export type ScenarioAlert_If_Matched = {
  match_columns?: Array<ScenarioAlert_Insert_Match_Column>;
  update_columns?: Array<ScenarioAlert_Update_Column>;
  where?: Maybe<ScenarioAlert_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ScenarioAlert" */
export type ScenarioAlert_Inc_Input = {
  AlertTypeId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ScenarioAlert" */
export type ScenarioAlert_Insert_Input = {
  AlertTypeId?: Maybe<Scalars['Int']>;
  Body?: Maybe<Scalars['String']>;
  ScenarioId?: Maybe<Scalars['Int']>;
  Title?: Maybe<Scalars['String']>;
};

/** select match_columns of table "ScenarioAlert" */
export enum ScenarioAlert_Insert_Match_Column {
  /** column name */
  AlertTypeId = 'AlertTypeId',
  /** column name */
  Body = 'Body',
  /** column name */
  ScenarioAlertId = 'ScenarioAlertId',
  /** column name */
  ScenarioId = 'ScenarioId',
  /** column name */
  Title = 'Title'
}

/** aggregate max on columns */
export type ScenarioAlert_Max_Fields = {
  __typename?: 'ScenarioAlert_max_fields';
  AlertTypeId?: Maybe<Scalars['Int']>;
  Body?: Maybe<Scalars['String']>;
  ScenarioAlertId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
  Title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ScenarioAlert" */
export type ScenarioAlert_Max_Order_By = {
  AlertTypeId?: Maybe<Order_By>;
  Body?: Maybe<Order_By>;
  ScenarioAlertId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ScenarioAlert_Min_Fields = {
  __typename?: 'ScenarioAlert_min_fields';
  AlertTypeId?: Maybe<Scalars['Int']>;
  Body?: Maybe<Scalars['String']>;
  ScenarioAlertId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
  Title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ScenarioAlert" */
export type ScenarioAlert_Min_Order_By = {
  AlertTypeId?: Maybe<Order_By>;
  Body?: Maybe<Order_By>;
  ScenarioAlertId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
};

/** response of any mutation on the table "ScenarioAlert" */
export type ScenarioAlert_Mutation_Response = {
  __typename?: 'ScenarioAlert_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ScenarioAlert>;
};

/** Ordering options when selecting data from "ScenarioAlert". */
export type ScenarioAlert_Order_By = {
  AlertTypeId?: Maybe<Order_By>;
  Body?: Maybe<Order_By>;
  Scenario?: Maybe<Scenario_Order_By>;
  ScenarioAlertId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
};

/** primary key columns input for table: ScenarioAlert */
export type ScenarioAlert_Pk_Columns_Input = {
  ScenarioAlertId: Scalars['Int'];
};

/** select columns of table "ScenarioAlert" */
export enum ScenarioAlert_Select_Column {
  /** column name */
  AlertTypeId = 'AlertTypeId',
  /** column name */
  Body = 'Body',
  /** column name */
  ScenarioAlertId = 'ScenarioAlertId',
  /** column name */
  ScenarioId = 'ScenarioId',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "ScenarioAlert" */
export type ScenarioAlert_Set_Input = {
  AlertTypeId?: Maybe<Scalars['Int']>;
  Body?: Maybe<Scalars['String']>;
  ScenarioId?: Maybe<Scalars['Int']>;
  Title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ScenarioAlert_Stddev_Fields = {
  __typename?: 'ScenarioAlert_stddev_fields';
  AlertTypeId?: Maybe<Scalars['Float']>;
  ScenarioAlertId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ScenarioAlert" */
export type ScenarioAlert_Stddev_Order_By = {
  AlertTypeId?: Maybe<Order_By>;
  ScenarioAlertId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ScenarioAlert_Stddev_Pop_Fields = {
  __typename?: 'ScenarioAlert_stddev_pop_fields';
  AlertTypeId?: Maybe<Scalars['Float']>;
  ScenarioAlertId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ScenarioAlert" */
export type ScenarioAlert_Stddev_Pop_Order_By = {
  AlertTypeId?: Maybe<Order_By>;
  ScenarioAlertId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ScenarioAlert_Stddev_Samp_Fields = {
  __typename?: 'ScenarioAlert_stddev_samp_fields';
  AlertTypeId?: Maybe<Scalars['Float']>;
  ScenarioAlertId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ScenarioAlert" */
export type ScenarioAlert_Stddev_Samp_Order_By = {
  AlertTypeId?: Maybe<Order_By>;
  ScenarioAlertId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ScenarioAlert_Sum_Fields = {
  __typename?: 'ScenarioAlert_sum_fields';
  AlertTypeId?: Maybe<Scalars['Int']>;
  ScenarioAlertId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ScenarioAlert" */
export type ScenarioAlert_Sum_Order_By = {
  AlertTypeId?: Maybe<Order_By>;
  ScenarioAlertId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** update columns of table "ScenarioAlert" */
export enum ScenarioAlert_Update_Column {
  /** column name */
  AlertTypeId = 'AlertTypeId',
  /** column name */
  Body = 'Body',
  /** column name */
  ScenarioId = 'ScenarioId',
  /** column name */
  Title = 'Title'
}

/** aggregate var_pop on columns */
export type ScenarioAlert_Var_Pop_Fields = {
  __typename?: 'ScenarioAlert_var_pop_fields';
  AlertTypeId?: Maybe<Scalars['Float']>;
  ScenarioAlertId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ScenarioAlert" */
export type ScenarioAlert_Var_Pop_Order_By = {
  AlertTypeId?: Maybe<Order_By>;
  ScenarioAlertId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ScenarioAlert_Var_Samp_Fields = {
  __typename?: 'ScenarioAlert_var_samp_fields';
  AlertTypeId?: Maybe<Scalars['Float']>;
  ScenarioAlertId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ScenarioAlert" */
export type ScenarioAlert_Var_Samp_Order_By = {
  AlertTypeId?: Maybe<Order_By>;
  ScenarioAlertId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ScenarioAlert_Variance_Fields = {
  __typename?: 'ScenarioAlert_variance_fields';
  AlertTypeId?: Maybe<Scalars['Float']>;
  ScenarioAlertId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ScenarioAlert" */
export type ScenarioAlert_Variance_Order_By = {
  AlertTypeId?: Maybe<Order_By>;
  ScenarioAlertId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
};

export type ScenarioInfo = {
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  Comments?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyName?: Maybe<Scalars['String']>;
  ScenarioId?: Maybe<Scalars['Int']>;
  ScenarioLayers?: Maybe<Array<Maybe<ScenarioLayerInfo>>>;
  ScenarioStatusId?: Maybe<Scalars['Int']>;
  ScenarioTypeId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

export type ScenarioInfoSave = {
  SaveOperationType: Scalars['Int'];
  Scenario?: Maybe<ScenarioInfo>;
};

/** columns and relationships of "ScenarioLayer" */
export type ScenarioLayer = {
  __typename?: 'ScenarioLayer';
  IsOptional: Scalars['Boolean'];
  IsPermeable: Scalars['Boolean'];
  LayerNum: Scalars['Int'];
  LayerText?: Maybe<Scalars['String']>;
  LayerTypeID: Scalars['Int'];
  /** An object relationship */
  NavLayer?: Maybe<NavLayer>;
  NavLayerId: Scalars['Int'];
  /** An object relationship */
  Scenario: Scenario;
  ScenarioId: Scalars['Int'];
  ScenarioLayerId: Scalars['Int'];
  /** An array relationship */
  ScenarioUsages: Array<ScenarioUsage>;
  /** An aggregate relationship */
  ScenarioUsages_aggregate: ScenarioUsage_Aggregate;
};


/** columns and relationships of "ScenarioLayer" */
export type ScenarioLayerScenarioUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioUsage_Order_By>>;
  where?: Maybe<ScenarioUsage_Bool_Exp>;
};


/** columns and relationships of "ScenarioLayer" */
export type ScenarioLayerScenarioUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioUsage_Order_By>>;
  where?: Maybe<ScenarioUsage_Bool_Exp>;
};

export type ScenarioLayerInfo = {
  IsOptional?: Maybe<Scalars['Boolean']>;
  IsPermeable?: Maybe<Scalars['Boolean']>;
  LayerNum?: Maybe<Scalars['Int']>;
  LayerText?: Maybe<Scalars['String']>;
  LayerTypeID?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
  ScenarioLayerId?: Maybe<Scalars['Int']>;
  ScenarioUsages?: Maybe<Array<Maybe<ScenarioUsageInfo>>>;
};

/** aggregated selection of "ScenarioLayer" */
export type ScenarioLayer_Aggregate = {
  __typename?: 'ScenarioLayer_aggregate';
  aggregate?: Maybe<ScenarioLayer_Aggregate_Fields>;
  nodes: Array<ScenarioLayer>;
};

/** aggregate fields of "ScenarioLayer" */
export type ScenarioLayer_Aggregate_Fields = {
  __typename?: 'ScenarioLayer_aggregate_fields';
  avg?: Maybe<ScenarioLayer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ScenarioLayer_Max_Fields>;
  min?: Maybe<ScenarioLayer_Min_Fields>;
  stddev?: Maybe<ScenarioLayer_Stddev_Fields>;
  stddev_pop?: Maybe<ScenarioLayer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ScenarioLayer_Stddev_Samp_Fields>;
  sum?: Maybe<ScenarioLayer_Sum_Fields>;
  var_pop?: Maybe<ScenarioLayer_Var_Pop_Fields>;
  var_samp?: Maybe<ScenarioLayer_Var_Samp_Fields>;
  variance?: Maybe<ScenarioLayer_Variance_Fields>;
};


/** aggregate fields of "ScenarioLayer" */
export type ScenarioLayer_Aggregate_FieldsCountArgs = {
  column?: Maybe<ScenarioLayer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ScenarioLayer" */
export type ScenarioLayer_Aggregate_Order_By = {
  avg?: Maybe<ScenarioLayer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ScenarioLayer_Max_Order_By>;
  min?: Maybe<ScenarioLayer_Min_Order_By>;
  stddev?: Maybe<ScenarioLayer_Stddev_Order_By>;
  stddev_pop?: Maybe<ScenarioLayer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ScenarioLayer_Stddev_Samp_Order_By>;
  sum?: Maybe<ScenarioLayer_Sum_Order_By>;
  var_pop?: Maybe<ScenarioLayer_Var_Pop_Order_By>;
  var_samp?: Maybe<ScenarioLayer_Var_Samp_Order_By>;
  variance?: Maybe<ScenarioLayer_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ScenarioLayer_Avg_Fields = {
  __typename?: 'ScenarioLayer_avg_fields';
  LayerNum?: Maybe<Scalars['Float']>;
  LayerTypeID?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
  ScenarioLayerId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ScenarioLayer" */
export type ScenarioLayer_Avg_Order_By = {
  LayerNum?: Maybe<Order_By>;
  LayerTypeID?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ScenarioLayer". All fields are combined with a logical 'AND'. */
export type ScenarioLayer_Bool_Exp = {
  IsOptional?: Maybe<Boolean_Mssql_Comparison_Exp>;
  IsPermeable?: Maybe<Boolean_Mssql_Comparison_Exp>;
  LayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  LayerText?: Maybe<String_Mssql_Comparison_Exp>;
  LayerTypeID?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayer?: Maybe<NavLayer_Bool_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  Scenario?: Maybe<Scenario_Bool_Exp>;
  ScenarioId?: Maybe<Int_Mssql_Comparison_Exp>;
  ScenarioLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ScenarioUsages?: Maybe<ScenarioUsage_Bool_Exp>;
  _and?: Maybe<Array<ScenarioLayer_Bool_Exp>>;
  _not?: Maybe<ScenarioLayer_Bool_Exp>;
  _or?: Maybe<Array<ScenarioLayer_Bool_Exp>>;
};

/** upsert condition type for table "ScenarioLayer" */
export type ScenarioLayer_If_Matched = {
  match_columns?: Array<ScenarioLayer_Insert_Match_Column>;
  update_columns?: Array<ScenarioLayer_Update_Column>;
  where?: Maybe<ScenarioLayer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ScenarioLayer" */
export type ScenarioLayer_Inc_Input = {
  LayerNum?: Maybe<Scalars['Int']>;
  LayerTypeID?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ScenarioLayer" */
export type ScenarioLayer_Insert_Input = {
  IsOptional?: Maybe<Scalars['Boolean']>;
  IsPermeable?: Maybe<Scalars['Boolean']>;
  LayerNum?: Maybe<Scalars['Int']>;
  LayerText?: Maybe<Scalars['String']>;
  LayerTypeID?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ScenarioLayer" */
export enum ScenarioLayer_Insert_Match_Column {
  /** column name */
  IsOptional = 'IsOptional',
  /** column name */
  IsPermeable = 'IsPermeable',
  /** column name */
  LayerNum = 'LayerNum',
  /** column name */
  LayerText = 'LayerText',
  /** column name */
  LayerTypeId = 'LayerTypeID',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  ScenarioId = 'ScenarioId',
  /** column name */
  ScenarioLayerId = 'ScenarioLayerId'
}

/** aggregate max on columns */
export type ScenarioLayer_Max_Fields = {
  __typename?: 'ScenarioLayer_max_fields';
  LayerNum?: Maybe<Scalars['Int']>;
  LayerText?: Maybe<Scalars['String']>;
  LayerTypeID?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
  ScenarioLayerId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ScenarioLayer" */
export type ScenarioLayer_Max_Order_By = {
  LayerNum?: Maybe<Order_By>;
  LayerText?: Maybe<Order_By>;
  LayerTypeID?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ScenarioLayer_Min_Fields = {
  __typename?: 'ScenarioLayer_min_fields';
  LayerNum?: Maybe<Scalars['Int']>;
  LayerText?: Maybe<Scalars['String']>;
  LayerTypeID?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
  ScenarioLayerId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ScenarioLayer" */
export type ScenarioLayer_Min_Order_By = {
  LayerNum?: Maybe<Order_By>;
  LayerText?: Maybe<Order_By>;
  LayerTypeID?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ScenarioLayer" */
export type ScenarioLayer_Mutation_Response = {
  __typename?: 'ScenarioLayer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ScenarioLayer>;
};

/** Ordering options when selecting data from "ScenarioLayer". */
export type ScenarioLayer_Order_By = {
  IsOptional?: Maybe<Order_By>;
  IsPermeable?: Maybe<Order_By>;
  LayerNum?: Maybe<Order_By>;
  LayerText?: Maybe<Order_By>;
  LayerTypeID?: Maybe<Order_By>;
  NavLayer?: Maybe<NavLayer_Order_By>;
  NavLayerId?: Maybe<Order_By>;
  Scenario?: Maybe<Scenario_Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
  ScenarioUsages_aggregate?: Maybe<ScenarioUsage_Aggregate_Order_By>;
};

/** primary key columns input for table: ScenarioLayer */
export type ScenarioLayer_Pk_Columns_Input = {
  ScenarioLayerId: Scalars['Int'];
};

/** select columns of table "ScenarioLayer" */
export enum ScenarioLayer_Select_Column {
  /** column name */
  IsOptional = 'IsOptional',
  /** column name */
  IsPermeable = 'IsPermeable',
  /** column name */
  LayerNum = 'LayerNum',
  /** column name */
  LayerText = 'LayerText',
  /** column name */
  LayerTypeId = 'LayerTypeID',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  ScenarioId = 'ScenarioId',
  /** column name */
  ScenarioLayerId = 'ScenarioLayerId'
}

/** input type for updating data in table "ScenarioLayer" */
export type ScenarioLayer_Set_Input = {
  IsOptional?: Maybe<Scalars['Boolean']>;
  IsPermeable?: Maybe<Scalars['Boolean']>;
  LayerNum?: Maybe<Scalars['Int']>;
  LayerText?: Maybe<Scalars['String']>;
  LayerTypeID?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ScenarioLayer_Stddev_Fields = {
  __typename?: 'ScenarioLayer_stddev_fields';
  LayerNum?: Maybe<Scalars['Float']>;
  LayerTypeID?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
  ScenarioLayerId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ScenarioLayer" */
export type ScenarioLayer_Stddev_Order_By = {
  LayerNum?: Maybe<Order_By>;
  LayerTypeID?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ScenarioLayer_Stddev_Pop_Fields = {
  __typename?: 'ScenarioLayer_stddev_pop_fields';
  LayerNum?: Maybe<Scalars['Float']>;
  LayerTypeID?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
  ScenarioLayerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ScenarioLayer" */
export type ScenarioLayer_Stddev_Pop_Order_By = {
  LayerNum?: Maybe<Order_By>;
  LayerTypeID?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ScenarioLayer_Stddev_Samp_Fields = {
  __typename?: 'ScenarioLayer_stddev_samp_fields';
  LayerNum?: Maybe<Scalars['Float']>;
  LayerTypeID?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
  ScenarioLayerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ScenarioLayer" */
export type ScenarioLayer_Stddev_Samp_Order_By = {
  LayerNum?: Maybe<Order_By>;
  LayerTypeID?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ScenarioLayer_Sum_Fields = {
  __typename?: 'ScenarioLayer_sum_fields';
  LayerNum?: Maybe<Scalars['Int']>;
  LayerTypeID?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
  ScenarioLayerId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ScenarioLayer" */
export type ScenarioLayer_Sum_Order_By = {
  LayerNum?: Maybe<Order_By>;
  LayerTypeID?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
};

/** update columns of table "ScenarioLayer" */
export enum ScenarioLayer_Update_Column {
  /** column name */
  IsOptional = 'IsOptional',
  /** column name */
  IsPermeable = 'IsPermeable',
  /** column name */
  LayerNum = 'LayerNum',
  /** column name */
  LayerText = 'LayerText',
  /** column name */
  LayerTypeId = 'LayerTypeID',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  ScenarioId = 'ScenarioId'
}

/** aggregate var_pop on columns */
export type ScenarioLayer_Var_Pop_Fields = {
  __typename?: 'ScenarioLayer_var_pop_fields';
  LayerNum?: Maybe<Scalars['Float']>;
  LayerTypeID?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
  ScenarioLayerId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ScenarioLayer" */
export type ScenarioLayer_Var_Pop_Order_By = {
  LayerNum?: Maybe<Order_By>;
  LayerTypeID?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ScenarioLayer_Var_Samp_Fields = {
  __typename?: 'ScenarioLayer_var_samp_fields';
  LayerNum?: Maybe<Scalars['Float']>;
  LayerTypeID?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
  ScenarioLayerId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ScenarioLayer" */
export type ScenarioLayer_Var_Samp_Order_By = {
  LayerNum?: Maybe<Order_By>;
  LayerTypeID?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ScenarioLayer_Variance_Fields = {
  __typename?: 'ScenarioLayer_variance_fields';
  LayerNum?: Maybe<Scalars['Float']>;
  LayerTypeID?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
  ScenarioLayerId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ScenarioLayer" */
export type ScenarioLayer_Variance_Order_By = {
  LayerNum?: Maybe<Order_By>;
  LayerTypeID?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
};

export type ScenarioSaveResult = {
  __typename?: 'ScenarioSaveResult';
  ScenarioId: Scalars['Int'];
};

/** columns and relationships of "ScenarioUsage" */
export type ScenarioUsage = {
  __typename?: 'ScenarioUsage';
  LayerNum: Scalars['Int'];
  /** An object relationship */
  ScenarioLayer: ScenarioLayer;
  ScenarioLayerId: Scalars['Int'];
  ScenarioUsageId: Scalars['Int'];
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SelfSecurementText?: Maybe<Scalars['String']>;
  /** An object relationship */
  Usage?: Maybe<Usage>;
  UsageId: Scalars['Int'];
};

export type ScenarioUsageInfo = {
  LayerNum?: Maybe<Scalars['Int']>;
  ScenarioLayerId?: Maybe<Scalars['Int']>;
  ScenarioUsageId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SelfSecurementText?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "ScenarioUsage" */
export type ScenarioUsage_Aggregate = {
  __typename?: 'ScenarioUsage_aggregate';
  aggregate?: Maybe<ScenarioUsage_Aggregate_Fields>;
  nodes: Array<ScenarioUsage>;
};

/** aggregate fields of "ScenarioUsage" */
export type ScenarioUsage_Aggregate_Fields = {
  __typename?: 'ScenarioUsage_aggregate_fields';
  avg?: Maybe<ScenarioUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ScenarioUsage_Max_Fields>;
  min?: Maybe<ScenarioUsage_Min_Fields>;
  stddev?: Maybe<ScenarioUsage_Stddev_Fields>;
  stddev_pop?: Maybe<ScenarioUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ScenarioUsage_Stddev_Samp_Fields>;
  sum?: Maybe<ScenarioUsage_Sum_Fields>;
  var_pop?: Maybe<ScenarioUsage_Var_Pop_Fields>;
  var_samp?: Maybe<ScenarioUsage_Var_Samp_Fields>;
  variance?: Maybe<ScenarioUsage_Variance_Fields>;
};


/** aggregate fields of "ScenarioUsage" */
export type ScenarioUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<ScenarioUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ScenarioUsage" */
export type ScenarioUsage_Aggregate_Order_By = {
  avg?: Maybe<ScenarioUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ScenarioUsage_Max_Order_By>;
  min?: Maybe<ScenarioUsage_Min_Order_By>;
  stddev?: Maybe<ScenarioUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<ScenarioUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ScenarioUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<ScenarioUsage_Sum_Order_By>;
  var_pop?: Maybe<ScenarioUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<ScenarioUsage_Var_Samp_Order_By>;
  variance?: Maybe<ScenarioUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ScenarioUsage_Avg_Fields = {
  __typename?: 'ScenarioUsage_avg_fields';
  LayerNum?: Maybe<Scalars['Float']>;
  ScenarioLayerId?: Maybe<Scalars['Float']>;
  ScenarioUsageId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ScenarioUsage" */
export type ScenarioUsage_Avg_Order_By = {
  LayerNum?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
  ScenarioUsageId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ScenarioUsage". All fields are combined with a logical 'AND'. */
export type ScenarioUsage_Bool_Exp = {
  LayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ScenarioLayer?: Maybe<ScenarioLayer_Bool_Exp>;
  ScenarioLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ScenarioUsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  SelfSecurementLayerTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  SelfSecurementText?: Maybe<String_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ScenarioUsage_Bool_Exp>>;
  _not?: Maybe<ScenarioUsage_Bool_Exp>;
  _or?: Maybe<Array<ScenarioUsage_Bool_Exp>>;
};

/** upsert condition type for table "ScenarioUsage" */
export type ScenarioUsage_If_Matched = {
  match_columns?: Array<ScenarioUsage_Insert_Match_Column>;
  update_columns?: Array<ScenarioUsage_Update_Column>;
  where?: Maybe<ScenarioUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ScenarioUsage" */
export type ScenarioUsage_Inc_Input = {
  LayerNum?: Maybe<Scalars['Int']>;
  ScenarioLayerId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ScenarioUsage" */
export type ScenarioUsage_Insert_Input = {
  LayerNum?: Maybe<Scalars['Int']>;
  ScenarioLayerId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SelfSecurementText?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ScenarioUsage" */
export enum ScenarioUsage_Insert_Match_Column {
  /** column name */
  LayerNum = 'LayerNum',
  /** column name */
  ScenarioLayerId = 'ScenarioLayerId',
  /** column name */
  ScenarioUsageId = 'ScenarioUsageId',
  /** column name */
  SelfSecurementLayerTypeId = 'SelfSecurementLayerTypeId',
  /** column name */
  SelfSecurementText = 'SelfSecurementText',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type ScenarioUsage_Max_Fields = {
  __typename?: 'ScenarioUsage_max_fields';
  LayerNum?: Maybe<Scalars['Int']>;
  ScenarioLayerId?: Maybe<Scalars['Int']>;
  ScenarioUsageId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SelfSecurementText?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ScenarioUsage" */
export type ScenarioUsage_Max_Order_By = {
  LayerNum?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
  ScenarioUsageId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SelfSecurementText?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ScenarioUsage_Min_Fields = {
  __typename?: 'ScenarioUsage_min_fields';
  LayerNum?: Maybe<Scalars['Int']>;
  ScenarioLayerId?: Maybe<Scalars['Int']>;
  ScenarioUsageId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SelfSecurementText?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ScenarioUsage" */
export type ScenarioUsage_Min_Order_By = {
  LayerNum?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
  ScenarioUsageId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SelfSecurementText?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ScenarioUsage" */
export type ScenarioUsage_Mutation_Response = {
  __typename?: 'ScenarioUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ScenarioUsage>;
};

/** Ordering options when selecting data from "ScenarioUsage". */
export type ScenarioUsage_Order_By = {
  LayerNum?: Maybe<Order_By>;
  ScenarioLayer?: Maybe<ScenarioLayer_Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
  ScenarioUsageId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SelfSecurementText?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: ScenarioUsage */
export type ScenarioUsage_Pk_Columns_Input = {
  ScenarioUsageId: Scalars['Int'];
};

/** select columns of table "ScenarioUsage" */
export enum ScenarioUsage_Select_Column {
  /** column name */
  LayerNum = 'LayerNum',
  /** column name */
  ScenarioLayerId = 'ScenarioLayerId',
  /** column name */
  ScenarioUsageId = 'ScenarioUsageId',
  /** column name */
  SelfSecurementLayerTypeId = 'SelfSecurementLayerTypeId',
  /** column name */
  SelfSecurementText = 'SelfSecurementText',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "ScenarioUsage" */
export type ScenarioUsage_Set_Input = {
  LayerNum?: Maybe<Scalars['Int']>;
  ScenarioLayerId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SelfSecurementText?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ScenarioUsage_Stddev_Fields = {
  __typename?: 'ScenarioUsage_stddev_fields';
  LayerNum?: Maybe<Scalars['Float']>;
  ScenarioLayerId?: Maybe<Scalars['Float']>;
  ScenarioUsageId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ScenarioUsage" */
export type ScenarioUsage_Stddev_Order_By = {
  LayerNum?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
  ScenarioUsageId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ScenarioUsage_Stddev_Pop_Fields = {
  __typename?: 'ScenarioUsage_stddev_pop_fields';
  LayerNum?: Maybe<Scalars['Float']>;
  ScenarioLayerId?: Maybe<Scalars['Float']>;
  ScenarioUsageId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ScenarioUsage" */
export type ScenarioUsage_Stddev_Pop_Order_By = {
  LayerNum?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
  ScenarioUsageId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ScenarioUsage_Stddev_Samp_Fields = {
  __typename?: 'ScenarioUsage_stddev_samp_fields';
  LayerNum?: Maybe<Scalars['Float']>;
  ScenarioLayerId?: Maybe<Scalars['Float']>;
  ScenarioUsageId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ScenarioUsage" */
export type ScenarioUsage_Stddev_Samp_Order_By = {
  LayerNum?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
  ScenarioUsageId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ScenarioUsage_Sum_Fields = {
  __typename?: 'ScenarioUsage_sum_fields';
  LayerNum?: Maybe<Scalars['Int']>;
  ScenarioLayerId?: Maybe<Scalars['Int']>;
  ScenarioUsageId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ScenarioUsage" */
export type ScenarioUsage_Sum_Order_By = {
  LayerNum?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
  ScenarioUsageId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "ScenarioUsage" */
export enum ScenarioUsage_Update_Column {
  /** column name */
  LayerNum = 'LayerNum',
  /** column name */
  ScenarioLayerId = 'ScenarioLayerId',
  /** column name */
  SelfSecurementLayerTypeId = 'SelfSecurementLayerTypeId',
  /** column name */
  SelfSecurementText = 'SelfSecurementText',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type ScenarioUsage_Var_Pop_Fields = {
  __typename?: 'ScenarioUsage_var_pop_fields';
  LayerNum?: Maybe<Scalars['Float']>;
  ScenarioLayerId?: Maybe<Scalars['Float']>;
  ScenarioUsageId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ScenarioUsage" */
export type ScenarioUsage_Var_Pop_Order_By = {
  LayerNum?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
  ScenarioUsageId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ScenarioUsage_Var_Samp_Fields = {
  __typename?: 'ScenarioUsage_var_samp_fields';
  LayerNum?: Maybe<Scalars['Float']>;
  ScenarioLayerId?: Maybe<Scalars['Float']>;
  ScenarioUsageId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ScenarioUsage" */
export type ScenarioUsage_Var_Samp_Order_By = {
  LayerNum?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
  ScenarioUsageId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ScenarioUsage_Variance_Fields = {
  __typename?: 'ScenarioUsage_variance_fields';
  LayerNum?: Maybe<Scalars['Float']>;
  ScenarioLayerId?: Maybe<Scalars['Float']>;
  ScenarioUsageId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ScenarioUsage" */
export type ScenarioUsage_Variance_Order_By = {
  LayerNum?: Maybe<Order_By>;
  ScenarioLayerId?: Maybe<Order_By>;
  ScenarioUsageId?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregated selection of "Scenario" */
export type Scenario_Aggregate = {
  __typename?: 'Scenario_aggregate';
  aggregate?: Maybe<Scenario_Aggregate_Fields>;
  nodes: Array<Scenario>;
};

/** aggregate fields of "Scenario" */
export type Scenario_Aggregate_Fields = {
  __typename?: 'Scenario_aggregate_fields';
  avg?: Maybe<Scenario_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Scenario_Max_Fields>;
  min?: Maybe<Scenario_Min_Fields>;
  stddev?: Maybe<Scenario_Stddev_Fields>;
  stddev_pop?: Maybe<Scenario_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Scenario_Stddev_Samp_Fields>;
  sum?: Maybe<Scenario_Sum_Fields>;
  var_pop?: Maybe<Scenario_Var_Pop_Fields>;
  var_samp?: Maybe<Scenario_Var_Samp_Fields>;
  variance?: Maybe<Scenario_Variance_Fields>;
};


/** aggregate fields of "Scenario" */
export type Scenario_Aggregate_FieldsCountArgs = {
  column?: Maybe<Scenario_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Scenario" */
export type Scenario_Aggregate_Order_By = {
  avg?: Maybe<Scenario_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Scenario_Max_Order_By>;
  min?: Maybe<Scenario_Min_Order_By>;
  stddev?: Maybe<Scenario_Stddev_Order_By>;
  stddev_pop?: Maybe<Scenario_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Scenario_Stddev_Samp_Order_By>;
  sum?: Maybe<Scenario_Sum_Order_By>;
  var_pop?: Maybe<Scenario_Var_Pop_Order_By>;
  var_samp?: Maybe<Scenario_Var_Samp_Order_By>;
  variance?: Maybe<Scenario_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Scenario_Avg_Fields = {
  __typename?: 'Scenario_avg_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
  ScenarioStatusId?: Maybe<Scalars['Float']>;
  ScenarioTypeId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Scenario" */
export type Scenario_Avg_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioStatusId?: Maybe<Order_By>;
  ScenarioTypeId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Scenario". All fields are combined with a logical 'AND'. */
export type Scenario_Bool_Exp = {
  AzureB2CUserEmail?: Maybe<String_Mssql_Comparison_Exp>;
  AzureB2CUserGuid?: Maybe<String_Mssql_Comparison_Exp>;
  C_ScenarioStatus?: Maybe<C_ScenarioStatus_Bool_Exp>;
  C_ScenarioType?: Maybe<C_ScenarioType_Bool_Exp>;
  Comments?: Maybe<ItemComment_Mssql_Comparison_Exp>;
  DateCreated?: Maybe<Datetime_Mssql_Comparison_Exp>;
  Name?: Maybe<String_Mssql_Comparison_Exp>;
  NavAssembly?: Maybe<NavAssembly_Bool_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyName?: Maybe<String_Mssql_Comparison_Exp>;
  R_RoofAreaScenarios?: Maybe<R_RoofAreaScenario_Bool_Exp>;
  R_ScenarioNotes?: Maybe<R_ScenarioNote_Bool_Exp>;
  ScenarioAlerts?: Maybe<ScenarioAlert_Bool_Exp>;
  ScenarioId?: Maybe<Int_Mssql_Comparison_Exp>;
  ScenarioLayers?: Maybe<ScenarioLayer_Bool_Exp>;
  ScenarioStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  ScenarioTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  UserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Scenario_Bool_Exp>>;
  _not?: Maybe<Scenario_Bool_Exp>;
  _or?: Maybe<Array<Scenario_Bool_Exp>>;
};

/** upsert condition type for table "Scenario" */
export type Scenario_If_Matched = {
  match_columns?: Array<Scenario_Insert_Match_Column>;
  update_columns?: Array<Scenario_Update_Column>;
  where?: Maybe<Scenario_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Scenario" */
export type Scenario_Inc_Input = {
  NavAssemblyId?: Maybe<Scalars['Int']>;
  ScenarioStatusId?: Maybe<Scalars['Int']>;
  ScenarioTypeId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Scenario" */
export type Scenario_Insert_Input = {
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  Name?: Maybe<Scalars['String']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyName?: Maybe<Scalars['String']>;
  ScenarioStatusId?: Maybe<Scalars['Int']>;
  ScenarioTypeId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "Scenario" */
export enum Scenario_Insert_Match_Column {
  /** column name */
  AzureB2CUserEmail = 'AzureB2CUserEmail',
  /** column name */
  AzureB2CUserGuid = 'AzureB2CUserGuid',
  /** column name */
  Comments = 'Comments',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  Name = 'Name',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavAssemblyName = 'NavAssemblyName',
  /** column name */
  ScenarioId = 'ScenarioId',
  /** column name */
  ScenarioStatusId = 'ScenarioStatusId',
  /** column name */
  ScenarioTypeId = 'ScenarioTypeId',
  /** column name */
  UserAccountId = 'UserAccountId'
}

/** aggregate max on columns */
export type Scenario_Max_Fields = {
  __typename?: 'Scenario_max_fields';
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  Name?: Maybe<Scalars['String']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyName?: Maybe<Scalars['String']>;
  ScenarioId?: Maybe<Scalars['Int']>;
  ScenarioStatusId?: Maybe<Scalars['Int']>;
  ScenarioTypeId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Scenario" */
export type Scenario_Max_Order_By = {
  AzureB2CUserEmail?: Maybe<Order_By>;
  AzureB2CUserGuid?: Maybe<Order_By>;
  Comments?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyName?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioStatusId?: Maybe<Order_By>;
  ScenarioTypeId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Scenario_Min_Fields = {
  __typename?: 'Scenario_min_fields';
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  Name?: Maybe<Scalars['String']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyName?: Maybe<Scalars['String']>;
  ScenarioId?: Maybe<Scalars['Int']>;
  ScenarioStatusId?: Maybe<Scalars['Int']>;
  ScenarioTypeId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Scenario" */
export type Scenario_Min_Order_By = {
  AzureB2CUserEmail?: Maybe<Order_By>;
  AzureB2CUserGuid?: Maybe<Order_By>;
  Comments?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyName?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioStatusId?: Maybe<Order_By>;
  ScenarioTypeId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Scenario" */
export type Scenario_Mutation_Response = {
  __typename?: 'Scenario_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Scenario>;
};

/** Ordering options when selecting data from "Scenario". */
export type Scenario_Order_By = {
  AzureB2CUserEmail?: Maybe<Order_By>;
  AzureB2CUserGuid?: Maybe<Order_By>;
  C_ScenarioStatus?: Maybe<C_ScenarioStatus_Order_By>;
  C_ScenarioType?: Maybe<C_ScenarioType_Order_By>;
  Comments?: Maybe<Order_By>;
  DateCreated?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  NavAssembly?: Maybe<NavAssembly_Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyName?: Maybe<Order_By>;
  R_RoofAreaScenarios_aggregate?: Maybe<R_RoofAreaScenario_Aggregate_Order_By>;
  R_ScenarioNotes_aggregate?: Maybe<R_ScenarioNote_Aggregate_Order_By>;
  ScenarioAlerts_aggregate?: Maybe<ScenarioAlert_Aggregate_Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioLayers_aggregate?: Maybe<ScenarioLayer_Aggregate_Order_By>;
  ScenarioStatusId?: Maybe<Order_By>;
  ScenarioTypeId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** primary key columns input for table: Scenario */
export type Scenario_Pk_Columns_Input = {
  ScenarioId: Scalars['Int'];
};

/** select columns of table "Scenario" */
export enum Scenario_Select_Column {
  /** column name */
  AzureB2CUserEmail = 'AzureB2CUserEmail',
  /** column name */
  AzureB2CUserGuid = 'AzureB2CUserGuid',
  /** column name */
  Comments = 'Comments',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  Name = 'Name',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavAssemblyName = 'NavAssemblyName',
  /** column name */
  ScenarioId = 'ScenarioId',
  /** column name */
  ScenarioStatusId = 'ScenarioStatusId',
  /** column name */
  ScenarioTypeId = 'ScenarioTypeId',
  /** column name */
  UserAccountId = 'UserAccountId'
}

/** input type for updating data in table "Scenario" */
export type Scenario_Set_Input = {
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  AzureB2CUserGuid?: Maybe<Scalars['String']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  DateCreated?: Maybe<Scalars['datetime']>;
  Name?: Maybe<Scalars['String']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyName?: Maybe<Scalars['String']>;
  ScenarioStatusId?: Maybe<Scalars['Int']>;
  ScenarioTypeId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Scenario_Stddev_Fields = {
  __typename?: 'Scenario_stddev_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
  ScenarioStatusId?: Maybe<Scalars['Float']>;
  ScenarioTypeId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Scenario" */
export type Scenario_Stddev_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioStatusId?: Maybe<Order_By>;
  ScenarioTypeId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Scenario_Stddev_Pop_Fields = {
  __typename?: 'Scenario_stddev_pop_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
  ScenarioStatusId?: Maybe<Scalars['Float']>;
  ScenarioTypeId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Scenario" */
export type Scenario_Stddev_Pop_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioStatusId?: Maybe<Order_By>;
  ScenarioTypeId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Scenario_Stddev_Samp_Fields = {
  __typename?: 'Scenario_stddev_samp_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
  ScenarioStatusId?: Maybe<Scalars['Float']>;
  ScenarioTypeId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Scenario" */
export type Scenario_Stddev_Samp_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioStatusId?: Maybe<Order_By>;
  ScenarioTypeId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Scenario_Sum_Fields = {
  __typename?: 'Scenario_sum_fields';
  NavAssemblyId?: Maybe<Scalars['Int']>;
  ScenarioId?: Maybe<Scalars['Int']>;
  ScenarioStatusId?: Maybe<Scalars['Int']>;
  ScenarioTypeId?: Maybe<Scalars['Int']>;
  UserAccountId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Scenario" */
export type Scenario_Sum_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioStatusId?: Maybe<Order_By>;
  ScenarioTypeId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** update columns of table "Scenario" */
export enum Scenario_Update_Column {
  /** column name */
  AzureB2CUserEmail = 'AzureB2CUserEmail',
  /** column name */
  AzureB2CUserGuid = 'AzureB2CUserGuid',
  /** column name */
  Comments = 'Comments',
  /** column name */
  DateCreated = 'DateCreated',
  /** column name */
  Name = 'Name',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavAssemblyName = 'NavAssemblyName',
  /** column name */
  ScenarioStatusId = 'ScenarioStatusId',
  /** column name */
  ScenarioTypeId = 'ScenarioTypeId',
  /** column name */
  UserAccountId = 'UserAccountId'
}

/** aggregate var_pop on columns */
export type Scenario_Var_Pop_Fields = {
  __typename?: 'Scenario_var_pop_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
  ScenarioStatusId?: Maybe<Scalars['Float']>;
  ScenarioTypeId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Scenario" */
export type Scenario_Var_Pop_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioStatusId?: Maybe<Order_By>;
  ScenarioTypeId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Scenario_Var_Samp_Fields = {
  __typename?: 'Scenario_var_samp_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
  ScenarioStatusId?: Maybe<Scalars['Float']>;
  ScenarioTypeId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Scenario" */
export type Scenario_Var_Samp_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioStatusId?: Maybe<Order_By>;
  ScenarioTypeId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Scenario_Variance_Fields = {
  __typename?: 'Scenario_variance_fields';
  NavAssemblyId?: Maybe<Scalars['Float']>;
  ScenarioId?: Maybe<Scalars['Float']>;
  ScenarioStatusId?: Maybe<Scalars['Float']>;
  ScenarioTypeId?: Maybe<Scalars['Float']>;
  UserAccountId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Scenario" */
export type Scenario_Variance_Order_By = {
  NavAssemblyId?: Maybe<Order_By>;
  ScenarioId?: Maybe<Order_By>;
  ScenarioStatusId?: Maybe<Order_By>;
  ScenarioTypeId?: Maybe<Order_By>;
  UserAccountId?: Maybe<Order_By>;
};

/** columns and relationships of "SeamPrimer" */
export type SeamPrimer = {
  __typename?: 'SeamPrimer';
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
};

/** columns and relationships of "SeamPrimerUsage" */
export type SeamPrimerUsage = {
  __typename?: 'SeamPrimerUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "SeamPrimerUsage" */
export type SeamPrimerUsage_Aggregate = {
  __typename?: 'SeamPrimerUsage_aggregate';
  aggregate?: Maybe<SeamPrimerUsage_Aggregate_Fields>;
  nodes: Array<SeamPrimerUsage>;
};

/** aggregate fields of "SeamPrimerUsage" */
export type SeamPrimerUsage_Aggregate_Fields = {
  __typename?: 'SeamPrimerUsage_aggregate_fields';
  avg?: Maybe<SeamPrimerUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SeamPrimerUsage_Max_Fields>;
  min?: Maybe<SeamPrimerUsage_Min_Fields>;
  stddev?: Maybe<SeamPrimerUsage_Stddev_Fields>;
  stddev_pop?: Maybe<SeamPrimerUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SeamPrimerUsage_Stddev_Samp_Fields>;
  sum?: Maybe<SeamPrimerUsage_Sum_Fields>;
  var_pop?: Maybe<SeamPrimerUsage_Var_Pop_Fields>;
  var_samp?: Maybe<SeamPrimerUsage_Var_Samp_Fields>;
  variance?: Maybe<SeamPrimerUsage_Variance_Fields>;
};


/** aggregate fields of "SeamPrimerUsage" */
export type SeamPrimerUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<SeamPrimerUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SeamPrimerUsage_Avg_Fields = {
  __typename?: 'SeamPrimerUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SeamPrimerUsage". All fields are combined with a logical 'AND'. */
export type SeamPrimerUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SeamPrimerUsage_Bool_Exp>>;
  _not?: Maybe<SeamPrimerUsage_Bool_Exp>;
  _or?: Maybe<Array<SeamPrimerUsage_Bool_Exp>>;
};

/** upsert condition type for table "SeamPrimerUsage" */
export type SeamPrimerUsage_If_Matched = {
  match_columns?: Array<SeamPrimerUsage_Insert_Match_Column>;
  update_columns?: Array<SeamPrimerUsage_Update_Column>;
  where?: Maybe<SeamPrimerUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SeamPrimerUsage" */
export type SeamPrimerUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SeamPrimerUsage" */
export type SeamPrimerUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SeamPrimerUsage" */
export enum SeamPrimerUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type SeamPrimerUsage_Max_Fields = {
  __typename?: 'SeamPrimerUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SeamPrimerUsage_Min_Fields = {
  __typename?: 'SeamPrimerUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "SeamPrimerUsage" */
export type SeamPrimerUsage_Mutation_Response = {
  __typename?: 'SeamPrimerUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SeamPrimerUsage>;
};

/** Ordering options when selecting data from "SeamPrimerUsage". */
export type SeamPrimerUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: SeamPrimerUsage */
export type SeamPrimerUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "SeamPrimerUsage" */
export enum SeamPrimerUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "SeamPrimerUsage" */
export type SeamPrimerUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SeamPrimerUsage_Stddev_Fields = {
  __typename?: 'SeamPrimerUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SeamPrimerUsage_Stddev_Pop_Fields = {
  __typename?: 'SeamPrimerUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SeamPrimerUsage_Stddev_Samp_Fields = {
  __typename?: 'SeamPrimerUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SeamPrimerUsage_Sum_Fields = {
  __typename?: 'SeamPrimerUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "SeamPrimerUsage" */
export enum SeamPrimerUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type SeamPrimerUsage_Var_Pop_Fields = {
  __typename?: 'SeamPrimerUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SeamPrimerUsage_Var_Samp_Fields = {
  __typename?: 'SeamPrimerUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SeamPrimerUsage_Variance_Fields = {
  __typename?: 'SeamPrimerUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "SeamPrimer" */
export type SeamPrimer_Aggregate = {
  __typename?: 'SeamPrimer_aggregate';
  aggregate?: Maybe<SeamPrimer_Aggregate_Fields>;
  nodes: Array<SeamPrimer>;
};

/** aggregate fields of "SeamPrimer" */
export type SeamPrimer_Aggregate_Fields = {
  __typename?: 'SeamPrimer_aggregate_fields';
  avg?: Maybe<SeamPrimer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SeamPrimer_Max_Fields>;
  min?: Maybe<SeamPrimer_Min_Fields>;
  stddev?: Maybe<SeamPrimer_Stddev_Fields>;
  stddev_pop?: Maybe<SeamPrimer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SeamPrimer_Stddev_Samp_Fields>;
  sum?: Maybe<SeamPrimer_Sum_Fields>;
  var_pop?: Maybe<SeamPrimer_Var_Pop_Fields>;
  var_samp?: Maybe<SeamPrimer_Var_Samp_Fields>;
  variance?: Maybe<SeamPrimer_Variance_Fields>;
};


/** aggregate fields of "SeamPrimer" */
export type SeamPrimer_Aggregate_FieldsCountArgs = {
  column?: Maybe<SeamPrimer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SeamPrimer_Avg_Fields = {
  __typename?: 'SeamPrimer_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SeamPrimer". All fields are combined with a logical 'AND'. */
export type SeamPrimer_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  _and?: Maybe<Array<SeamPrimer_Bool_Exp>>;
  _not?: Maybe<SeamPrimer_Bool_Exp>;
  _or?: Maybe<Array<SeamPrimer_Bool_Exp>>;
};

/** upsert condition type for table "SeamPrimer" */
export type SeamPrimer_If_Matched = {
  match_columns?: Array<SeamPrimer_Insert_Match_Column>;
  update_columns?: Array<SeamPrimer_Update_Column>;
  where?: Maybe<SeamPrimer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SeamPrimer" */
export type SeamPrimer_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SeamPrimer" */
export type SeamPrimer_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SeamPrimer" */
export enum SeamPrimer_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId'
}

/** aggregate max on columns */
export type SeamPrimer_Max_Fields = {
  __typename?: 'SeamPrimer_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SeamPrimer_Min_Fields = {
  __typename?: 'SeamPrimer_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "SeamPrimer" */
export type SeamPrimer_Mutation_Response = {
  __typename?: 'SeamPrimer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SeamPrimer>;
};

/** Ordering options when selecting data from "SeamPrimer". */
export type SeamPrimer_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
};

/** primary key columns input for table: SeamPrimer */
export type SeamPrimer_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "SeamPrimer" */
export enum SeamPrimer_Select_Column {
  /** column name */
  ComponentId = 'ComponentId'
}

/** input type for updating data in table "SeamPrimer" */
export type SeamPrimer_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SeamPrimer_Stddev_Fields = {
  __typename?: 'SeamPrimer_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SeamPrimer_Stddev_Pop_Fields = {
  __typename?: 'SeamPrimer_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SeamPrimer_Stddev_Samp_Fields = {
  __typename?: 'SeamPrimer_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SeamPrimer_Sum_Fields = {
  __typename?: 'SeamPrimer_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
};

/** update columns of table "SeamPrimer" */
export enum SeamPrimer_Update_Column {
  /** column name */
  ComponentId = 'ComponentId'
}

/** aggregate var_pop on columns */
export type SeamPrimer_Var_Pop_Fields = {
  __typename?: 'SeamPrimer_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SeamPrimer_Var_Samp_Fields = {
  __typename?: 'SeamPrimer_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SeamPrimer_Variance_Fields = {
  __typename?: 'SeamPrimer_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "SeamTapeSystemUsage" */
export type SeamTapeSystemUsage = {
  __typename?: 'SeamTapeSystemUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "SeamTapeSystemUsage" */
export type SeamTapeSystemUsage_Aggregate = {
  __typename?: 'SeamTapeSystemUsage_aggregate';
  aggregate?: Maybe<SeamTapeSystemUsage_Aggregate_Fields>;
  nodes: Array<SeamTapeSystemUsage>;
};

/** aggregate fields of "SeamTapeSystemUsage" */
export type SeamTapeSystemUsage_Aggregate_Fields = {
  __typename?: 'SeamTapeSystemUsage_aggregate_fields';
  avg?: Maybe<SeamTapeSystemUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SeamTapeSystemUsage_Max_Fields>;
  min?: Maybe<SeamTapeSystemUsage_Min_Fields>;
  stddev?: Maybe<SeamTapeSystemUsage_Stddev_Fields>;
  stddev_pop?: Maybe<SeamTapeSystemUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SeamTapeSystemUsage_Stddev_Samp_Fields>;
  sum?: Maybe<SeamTapeSystemUsage_Sum_Fields>;
  var_pop?: Maybe<SeamTapeSystemUsage_Var_Pop_Fields>;
  var_samp?: Maybe<SeamTapeSystemUsage_Var_Samp_Fields>;
  variance?: Maybe<SeamTapeSystemUsage_Variance_Fields>;
};


/** aggregate fields of "SeamTapeSystemUsage" */
export type SeamTapeSystemUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<SeamTapeSystemUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SeamTapeSystemUsage_Avg_Fields = {
  __typename?: 'SeamTapeSystemUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SeamTapeSystemUsage". All fields are combined with a logical 'AND'. */
export type SeamTapeSystemUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SeamTapeSystemUsage_Bool_Exp>>;
  _not?: Maybe<SeamTapeSystemUsage_Bool_Exp>;
  _or?: Maybe<Array<SeamTapeSystemUsage_Bool_Exp>>;
};

/** upsert condition type for table "SeamTapeSystemUsage" */
export type SeamTapeSystemUsage_If_Matched = {
  match_columns?: Array<SeamTapeSystemUsage_Insert_Match_Column>;
  update_columns?: Array<SeamTapeSystemUsage_Update_Column>;
  where?: Maybe<SeamTapeSystemUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SeamTapeSystemUsage" */
export type SeamTapeSystemUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SeamTapeSystemUsage" */
export type SeamTapeSystemUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SeamTapeSystemUsage" */
export enum SeamTapeSystemUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type SeamTapeSystemUsage_Max_Fields = {
  __typename?: 'SeamTapeSystemUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SeamTapeSystemUsage_Min_Fields = {
  __typename?: 'SeamTapeSystemUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "SeamTapeSystemUsage" */
export type SeamTapeSystemUsage_Mutation_Response = {
  __typename?: 'SeamTapeSystemUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SeamTapeSystemUsage>;
};

/** Ordering options when selecting data from "SeamTapeSystemUsage". */
export type SeamTapeSystemUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: SeamTapeSystemUsage */
export type SeamTapeSystemUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "SeamTapeSystemUsage" */
export enum SeamTapeSystemUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "SeamTapeSystemUsage" */
export type SeamTapeSystemUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SeamTapeSystemUsage_Stddev_Fields = {
  __typename?: 'SeamTapeSystemUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SeamTapeSystemUsage_Stddev_Pop_Fields = {
  __typename?: 'SeamTapeSystemUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SeamTapeSystemUsage_Stddev_Samp_Fields = {
  __typename?: 'SeamTapeSystemUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SeamTapeSystemUsage_Sum_Fields = {
  __typename?: 'SeamTapeSystemUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "SeamTapeSystemUsage" */
export enum SeamTapeSystemUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type SeamTapeSystemUsage_Var_Pop_Fields = {
  __typename?: 'SeamTapeSystemUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SeamTapeSystemUsage_Var_Samp_Fields = {
  __typename?: 'SeamTapeSystemUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SeamTapeSystemUsage_Variance_Fields = {
  __typename?: 'SeamTapeSystemUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "SeamWash" */
export type SeamWash = {
  __typename?: 'SeamWash';
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
};

/** columns and relationships of "SeamWashUsage" */
export type SeamWashUsage = {
  __typename?: 'SeamWashUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "SeamWashUsage" */
export type SeamWashUsage_Aggregate = {
  __typename?: 'SeamWashUsage_aggregate';
  aggregate?: Maybe<SeamWashUsage_Aggregate_Fields>;
  nodes: Array<SeamWashUsage>;
};

/** aggregate fields of "SeamWashUsage" */
export type SeamWashUsage_Aggregate_Fields = {
  __typename?: 'SeamWashUsage_aggregate_fields';
  avg?: Maybe<SeamWashUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SeamWashUsage_Max_Fields>;
  min?: Maybe<SeamWashUsage_Min_Fields>;
  stddev?: Maybe<SeamWashUsage_Stddev_Fields>;
  stddev_pop?: Maybe<SeamWashUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SeamWashUsage_Stddev_Samp_Fields>;
  sum?: Maybe<SeamWashUsage_Sum_Fields>;
  var_pop?: Maybe<SeamWashUsage_Var_Pop_Fields>;
  var_samp?: Maybe<SeamWashUsage_Var_Samp_Fields>;
  variance?: Maybe<SeamWashUsage_Variance_Fields>;
};


/** aggregate fields of "SeamWashUsage" */
export type SeamWashUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<SeamWashUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SeamWashUsage_Avg_Fields = {
  __typename?: 'SeamWashUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SeamWashUsage". All fields are combined with a logical 'AND'. */
export type SeamWashUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SeamWashUsage_Bool_Exp>>;
  _not?: Maybe<SeamWashUsage_Bool_Exp>;
  _or?: Maybe<Array<SeamWashUsage_Bool_Exp>>;
};

/** upsert condition type for table "SeamWashUsage" */
export type SeamWashUsage_If_Matched = {
  match_columns?: Array<SeamWashUsage_Insert_Match_Column>;
  update_columns?: Array<SeamWashUsage_Update_Column>;
  where?: Maybe<SeamWashUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SeamWashUsage" */
export type SeamWashUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SeamWashUsage" */
export type SeamWashUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SeamWashUsage" */
export enum SeamWashUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type SeamWashUsage_Max_Fields = {
  __typename?: 'SeamWashUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SeamWashUsage_Min_Fields = {
  __typename?: 'SeamWashUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "SeamWashUsage" */
export type SeamWashUsage_Mutation_Response = {
  __typename?: 'SeamWashUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SeamWashUsage>;
};

/** Ordering options when selecting data from "SeamWashUsage". */
export type SeamWashUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: SeamWashUsage */
export type SeamWashUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "SeamWashUsage" */
export enum SeamWashUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "SeamWashUsage" */
export type SeamWashUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SeamWashUsage_Stddev_Fields = {
  __typename?: 'SeamWashUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SeamWashUsage_Stddev_Pop_Fields = {
  __typename?: 'SeamWashUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SeamWashUsage_Stddev_Samp_Fields = {
  __typename?: 'SeamWashUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SeamWashUsage_Sum_Fields = {
  __typename?: 'SeamWashUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "SeamWashUsage" */
export enum SeamWashUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type SeamWashUsage_Var_Pop_Fields = {
  __typename?: 'SeamWashUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SeamWashUsage_Var_Samp_Fields = {
  __typename?: 'SeamWashUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SeamWashUsage_Variance_Fields = {
  __typename?: 'SeamWashUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "SeamWash" */
export type SeamWash_Aggregate = {
  __typename?: 'SeamWash_aggregate';
  aggregate?: Maybe<SeamWash_Aggregate_Fields>;
  nodes: Array<SeamWash>;
};

/** aggregate fields of "SeamWash" */
export type SeamWash_Aggregate_Fields = {
  __typename?: 'SeamWash_aggregate_fields';
  avg?: Maybe<SeamWash_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SeamWash_Max_Fields>;
  min?: Maybe<SeamWash_Min_Fields>;
  stddev?: Maybe<SeamWash_Stddev_Fields>;
  stddev_pop?: Maybe<SeamWash_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SeamWash_Stddev_Samp_Fields>;
  sum?: Maybe<SeamWash_Sum_Fields>;
  var_pop?: Maybe<SeamWash_Var_Pop_Fields>;
  var_samp?: Maybe<SeamWash_Var_Samp_Fields>;
  variance?: Maybe<SeamWash_Variance_Fields>;
};


/** aggregate fields of "SeamWash" */
export type SeamWash_Aggregate_FieldsCountArgs = {
  column?: Maybe<SeamWash_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SeamWash_Avg_Fields = {
  __typename?: 'SeamWash_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SeamWash". All fields are combined with a logical 'AND'. */
export type SeamWash_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  _and?: Maybe<Array<SeamWash_Bool_Exp>>;
  _not?: Maybe<SeamWash_Bool_Exp>;
  _or?: Maybe<Array<SeamWash_Bool_Exp>>;
};

/** upsert condition type for table "SeamWash" */
export type SeamWash_If_Matched = {
  match_columns?: Array<SeamWash_Insert_Match_Column>;
  update_columns?: Array<SeamWash_Update_Column>;
  where?: Maybe<SeamWash_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SeamWash" */
export type SeamWash_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SeamWash" */
export type SeamWash_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SeamWash" */
export enum SeamWash_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId'
}

/** aggregate max on columns */
export type SeamWash_Max_Fields = {
  __typename?: 'SeamWash_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SeamWash_Min_Fields = {
  __typename?: 'SeamWash_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "SeamWash" */
export type SeamWash_Mutation_Response = {
  __typename?: 'SeamWash_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SeamWash>;
};

/** Ordering options when selecting data from "SeamWash". */
export type SeamWash_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
};

/** primary key columns input for table: SeamWash */
export type SeamWash_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "SeamWash" */
export enum SeamWash_Select_Column {
  /** column name */
  ComponentId = 'ComponentId'
}

/** input type for updating data in table "SeamWash" */
export type SeamWash_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SeamWash_Stddev_Fields = {
  __typename?: 'SeamWash_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SeamWash_Stddev_Pop_Fields = {
  __typename?: 'SeamWash_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SeamWash_Stddev_Samp_Fields = {
  __typename?: 'SeamWash_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SeamWash_Sum_Fields = {
  __typename?: 'SeamWash_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
};

/** update columns of table "SeamWash" */
export enum SeamWash_Update_Column {
  /** column name */
  ComponentId = 'ComponentId'
}

/** aggregate var_pop on columns */
export type SeamWash_Var_Pop_Fields = {
  __typename?: 'SeamWash_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SeamWash_Var_Samp_Fields = {
  __typename?: 'SeamWash_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SeamWash_Variance_Fields = {
  __typename?: 'SeamWash_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct = {
  __typename?: 'SearchNavAssembly_ManufacturerProduct';
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  ManufacturerId: Scalars['Int'];
  /** An object relationship */
  NavAssembly: NavAssembly;
  NavAssemblyId: Scalars['Int'];
};

/** aggregated selection of "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Aggregate = {
  __typename?: 'SearchNavAssembly_ManufacturerProduct_aggregate';
  aggregate?: Maybe<SearchNavAssembly_ManufacturerProduct_Aggregate_Fields>;
  nodes: Array<SearchNavAssembly_ManufacturerProduct>;
};

/** aggregate fields of "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Aggregate_Fields = {
  __typename?: 'SearchNavAssembly_ManufacturerProduct_aggregate_fields';
  avg?: Maybe<SearchNavAssembly_ManufacturerProduct_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SearchNavAssembly_ManufacturerProduct_Max_Fields>;
  min?: Maybe<SearchNavAssembly_ManufacturerProduct_Min_Fields>;
  stddev?: Maybe<SearchNavAssembly_ManufacturerProduct_Stddev_Fields>;
  stddev_pop?: Maybe<SearchNavAssembly_ManufacturerProduct_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SearchNavAssembly_ManufacturerProduct_Stddev_Samp_Fields>;
  sum?: Maybe<SearchNavAssembly_ManufacturerProduct_Sum_Fields>;
  var_pop?: Maybe<SearchNavAssembly_ManufacturerProduct_Var_Pop_Fields>;
  var_samp?: Maybe<SearchNavAssembly_ManufacturerProduct_Var_Samp_Fields>;
  variance?: Maybe<SearchNavAssembly_ManufacturerProduct_Variance_Fields>;
};


/** aggregate fields of "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Aggregate_FieldsCountArgs = {
  column?: Maybe<SearchNavAssembly_ManufacturerProduct_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Aggregate_Order_By = {
  avg?: Maybe<SearchNavAssembly_ManufacturerProduct_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SearchNavAssembly_ManufacturerProduct_Max_Order_By>;
  min?: Maybe<SearchNavAssembly_ManufacturerProduct_Min_Order_By>;
  stddev?: Maybe<SearchNavAssembly_ManufacturerProduct_Stddev_Order_By>;
  stddev_pop?: Maybe<SearchNavAssembly_ManufacturerProduct_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SearchNavAssembly_ManufacturerProduct_Stddev_Samp_Order_By>;
  sum?: Maybe<SearchNavAssembly_ManufacturerProduct_Sum_Order_By>;
  var_pop?: Maybe<SearchNavAssembly_ManufacturerProduct_Var_Pop_Order_By>;
  var_samp?: Maybe<SearchNavAssembly_ManufacturerProduct_Var_Samp_Order_By>;
  variance?: Maybe<SearchNavAssembly_ManufacturerProduct_Variance_Order_By>;
};

/** aggregate avg on columns */
export type SearchNavAssembly_ManufacturerProduct_Avg_Fields = {
  __typename?: 'SearchNavAssembly_ManufacturerProduct_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SearchNavAssembly_ManufacturerProduct". All fields are combined with a logical 'AND'. */
export type SearchNavAssembly_ManufacturerProduct_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssembly?: Maybe<NavAssembly_Bool_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SearchNavAssembly_ManufacturerProduct_Bool_Exp>>;
  _not?: Maybe<SearchNavAssembly_ManufacturerProduct_Bool_Exp>;
  _or?: Maybe<Array<SearchNavAssembly_ManufacturerProduct_Bool_Exp>>;
};

/** upsert condition type for table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_If_Matched = {
  match_columns?: Array<SearchNavAssembly_ManufacturerProduct_Insert_Match_Column>;
  update_columns?: Array<SearchNavAssembly_ManufacturerProduct_Update_Column>;
  where?: Maybe<SearchNavAssembly_ManufacturerProduct_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SearchNavAssembly_ManufacturerProduct" */
export enum SearchNavAssembly_ManufacturerProduct_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId'
}

/** aggregate max on columns */
export type SearchNavAssembly_ManufacturerProduct_Max_Fields = {
  __typename?: 'SearchNavAssembly_ManufacturerProduct_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SearchNavAssembly_ManufacturerProduct_Min_Fields = {
  __typename?: 'SearchNavAssembly_ManufacturerProduct_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
};

/** response of any mutation on the table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Mutation_Response = {
  __typename?: 'SearchNavAssembly_ManufacturerProduct_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SearchNavAssembly_ManufacturerProduct>;
};

/** Ordering options when selecting data from "SearchNavAssembly_ManufacturerProduct". */
export type SearchNavAssembly_ManufacturerProduct_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssembly?: Maybe<NavAssembly_Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
};

/** primary key columns input for table: SearchNavAssembly_ManufacturerProduct */
export type SearchNavAssembly_ManufacturerProduct_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
};

/** select columns of table "SearchNavAssembly_ManufacturerProduct" */
export enum SearchNavAssembly_ManufacturerProduct_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId'
}

/** input type for updating data in table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SearchNavAssembly_ManufacturerProduct_Stddev_Fields = {
  __typename?: 'SearchNavAssembly_ManufacturerProduct_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SearchNavAssembly_ManufacturerProduct_Stddev_Pop_Fields = {
  __typename?: 'SearchNavAssembly_ManufacturerProduct_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SearchNavAssembly_ManufacturerProduct_Stddev_Samp_Fields = {
  __typename?: 'SearchNavAssembly_ManufacturerProduct_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type SearchNavAssembly_ManufacturerProduct_Sum_Fields = {
  __typename?: 'SearchNavAssembly_ManufacturerProduct_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
};

/** update columns of table "SearchNavAssembly_ManufacturerProduct" */
export enum SearchNavAssembly_ManufacturerProduct_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId'
}

/** aggregate var_pop on columns */
export type SearchNavAssembly_ManufacturerProduct_Var_Pop_Fields = {
  __typename?: 'SearchNavAssembly_ManufacturerProduct_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SearchNavAssembly_ManufacturerProduct_Var_Samp_Fields = {
  __typename?: 'SearchNavAssembly_ManufacturerProduct_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SearchNavAssembly_ManufacturerProduct_Variance_Fields = {
  __typename?: 'SearchNavAssembly_ManufacturerProduct_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SearchNavAssembly_ManufacturerProduct" */
export type SearchNavAssembly_ManufacturerProduct_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
};

/** columns and relationships of "SecurementLayer" */
export type SecurementLayer = {
  __typename?: 'SecurementLayer';
  FromLayerId: Scalars['Int'];
  /** An object relationship */
  Layer: Layer;
  LayerId: Scalars['Int'];
  /** An array relationship */
  SecurementLayerUsages: Array<SecurementLayerUsage>;
  /** An aggregate relationship */
  SecurementLayerUsages_aggregate: SecurementLayerUsage_Aggregate;
  ToLayerId: Scalars['Int'];
  /** An object relationship */
  layerByFromlayerid: Layer;
  /** An object relationship */
  layerByTolayerid: Layer;
};


/** columns and relationships of "SecurementLayer" */
export type SecurementLayerSecurementLayerUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayerUsage_Order_By>>;
  where?: Maybe<SecurementLayerUsage_Bool_Exp>;
};


/** columns and relationships of "SecurementLayer" */
export type SecurementLayerSecurementLayerUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayerUsage_Order_By>>;
  where?: Maybe<SecurementLayerUsage_Bool_Exp>;
};

/** columns and relationships of "SecurementLayerUsage" */
export type SecurementLayerUsage = {
  __typename?: 'SecurementLayerUsage';
  LayerId: Scalars['Int'];
  /** An object relationship */
  SecurementLayer: SecurementLayer;
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "SecurementLayerUsage" */
export type SecurementLayerUsage_Aggregate = {
  __typename?: 'SecurementLayerUsage_aggregate';
  aggregate?: Maybe<SecurementLayerUsage_Aggregate_Fields>;
  nodes: Array<SecurementLayerUsage>;
};

/** aggregate fields of "SecurementLayerUsage" */
export type SecurementLayerUsage_Aggregate_Fields = {
  __typename?: 'SecurementLayerUsage_aggregate_fields';
  avg?: Maybe<SecurementLayerUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SecurementLayerUsage_Max_Fields>;
  min?: Maybe<SecurementLayerUsage_Min_Fields>;
  stddev?: Maybe<SecurementLayerUsage_Stddev_Fields>;
  stddev_pop?: Maybe<SecurementLayerUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SecurementLayerUsage_Stddev_Samp_Fields>;
  sum?: Maybe<SecurementLayerUsage_Sum_Fields>;
  var_pop?: Maybe<SecurementLayerUsage_Var_Pop_Fields>;
  var_samp?: Maybe<SecurementLayerUsage_Var_Samp_Fields>;
  variance?: Maybe<SecurementLayerUsage_Variance_Fields>;
};


/** aggregate fields of "SecurementLayerUsage" */
export type SecurementLayerUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<SecurementLayerUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SecurementLayerUsage" */
export type SecurementLayerUsage_Aggregate_Order_By = {
  avg?: Maybe<SecurementLayerUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SecurementLayerUsage_Max_Order_By>;
  min?: Maybe<SecurementLayerUsage_Min_Order_By>;
  stddev?: Maybe<SecurementLayerUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<SecurementLayerUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SecurementLayerUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<SecurementLayerUsage_Sum_Order_By>;
  var_pop?: Maybe<SecurementLayerUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<SecurementLayerUsage_Var_Samp_Order_By>;
  variance?: Maybe<SecurementLayerUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type SecurementLayerUsage_Avg_Fields = {
  __typename?: 'SecurementLayerUsage_avg_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SecurementLayerUsage" */
export type SecurementLayerUsage_Avg_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SecurementLayerUsage". All fields are combined with a logical 'AND'. */
export type SecurementLayerUsage_Bool_Exp = {
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  SecurementLayer?: Maybe<SecurementLayer_Bool_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SecurementLayerUsage_Bool_Exp>>;
  _not?: Maybe<SecurementLayerUsage_Bool_Exp>;
  _or?: Maybe<Array<SecurementLayerUsage_Bool_Exp>>;
};

/** upsert condition type for table "SecurementLayerUsage" */
export type SecurementLayerUsage_If_Matched = {
  match_columns?: Array<SecurementLayerUsage_Insert_Match_Column>;
  update_columns?: Array<SecurementLayerUsage_Update_Column>;
  where?: Maybe<SecurementLayerUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SecurementLayerUsage" */
export type SecurementLayerUsage_Inc_Input = {
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SecurementLayerUsage" */
export type SecurementLayerUsage_Insert_Input = {
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SecurementLayerUsage" */
export enum SecurementLayerUsage_Insert_Match_Column {
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type SecurementLayerUsage_Max_Fields = {
  __typename?: 'SecurementLayerUsage_max_fields';
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "SecurementLayerUsage" */
export type SecurementLayerUsage_Max_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SecurementLayerUsage_Min_Fields = {
  __typename?: 'SecurementLayerUsage_min_fields';
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "SecurementLayerUsage" */
export type SecurementLayerUsage_Min_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "SecurementLayerUsage" */
export type SecurementLayerUsage_Mutation_Response = {
  __typename?: 'SecurementLayerUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SecurementLayerUsage>;
};

/** Ordering options when selecting data from "SecurementLayerUsage". */
export type SecurementLayerUsage_Order_By = {
  LayerId?: Maybe<Order_By>;
  SecurementLayer?: Maybe<SecurementLayer_Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: SecurementLayerUsage */
export type SecurementLayerUsage_Pk_Columns_Input = {
  LayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};

/** select columns of table "SecurementLayerUsage" */
export enum SecurementLayerUsage_Select_Column {
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "SecurementLayerUsage" */
export type SecurementLayerUsage_Set_Input = {
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SecurementLayerUsage_Stddev_Fields = {
  __typename?: 'SecurementLayerUsage_stddev_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SecurementLayerUsage" */
export type SecurementLayerUsage_Stddev_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SecurementLayerUsage_Stddev_Pop_Fields = {
  __typename?: 'SecurementLayerUsage_stddev_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SecurementLayerUsage" */
export type SecurementLayerUsage_Stddev_Pop_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SecurementLayerUsage_Stddev_Samp_Fields = {
  __typename?: 'SecurementLayerUsage_stddev_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SecurementLayerUsage" */
export type SecurementLayerUsage_Stddev_Samp_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type SecurementLayerUsage_Sum_Fields = {
  __typename?: 'SecurementLayerUsage_sum_fields';
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "SecurementLayerUsage" */
export type SecurementLayerUsage_Sum_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "SecurementLayerUsage" */
export enum SecurementLayerUsage_Update_Column {
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type SecurementLayerUsage_Var_Pop_Fields = {
  __typename?: 'SecurementLayerUsage_var_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SecurementLayerUsage" */
export type SecurementLayerUsage_Var_Pop_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SecurementLayerUsage_Var_Samp_Fields = {
  __typename?: 'SecurementLayerUsage_var_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SecurementLayerUsage" */
export type SecurementLayerUsage_Var_Samp_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SecurementLayerUsage_Variance_Fields = {
  __typename?: 'SecurementLayerUsage_variance_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SecurementLayerUsage" */
export type SecurementLayerUsage_Variance_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregated selection of "SecurementLayer" */
export type SecurementLayer_Aggregate = {
  __typename?: 'SecurementLayer_aggregate';
  aggregate?: Maybe<SecurementLayer_Aggregate_Fields>;
  nodes: Array<SecurementLayer>;
};

/** aggregate fields of "SecurementLayer" */
export type SecurementLayer_Aggregate_Fields = {
  __typename?: 'SecurementLayer_aggregate_fields';
  avg?: Maybe<SecurementLayer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SecurementLayer_Max_Fields>;
  min?: Maybe<SecurementLayer_Min_Fields>;
  stddev?: Maybe<SecurementLayer_Stddev_Fields>;
  stddev_pop?: Maybe<SecurementLayer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SecurementLayer_Stddev_Samp_Fields>;
  sum?: Maybe<SecurementLayer_Sum_Fields>;
  var_pop?: Maybe<SecurementLayer_Var_Pop_Fields>;
  var_samp?: Maybe<SecurementLayer_Var_Samp_Fields>;
  variance?: Maybe<SecurementLayer_Variance_Fields>;
};


/** aggregate fields of "SecurementLayer" */
export type SecurementLayer_Aggregate_FieldsCountArgs = {
  column?: Maybe<SecurementLayer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SecurementLayer" */
export type SecurementLayer_Aggregate_Order_By = {
  avg?: Maybe<SecurementLayer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SecurementLayer_Max_Order_By>;
  min?: Maybe<SecurementLayer_Min_Order_By>;
  stddev?: Maybe<SecurementLayer_Stddev_Order_By>;
  stddev_pop?: Maybe<SecurementLayer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SecurementLayer_Stddev_Samp_Order_By>;
  sum?: Maybe<SecurementLayer_Sum_Order_By>;
  var_pop?: Maybe<SecurementLayer_Var_Pop_Order_By>;
  var_samp?: Maybe<SecurementLayer_Var_Samp_Order_By>;
  variance?: Maybe<SecurementLayer_Variance_Order_By>;
};

/** aggregate avg on columns */
export type SecurementLayer_Avg_Fields = {
  __typename?: 'SecurementLayer_avg_fields';
  FromLayerId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  ToLayerId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SecurementLayer" */
export type SecurementLayer_Avg_Order_By = {
  FromLayerId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  ToLayerId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SecurementLayer". All fields are combined with a logical 'AND'. */
export type SecurementLayer_Bool_Exp = {
  FromLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  Layer?: Maybe<Layer_Bool_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  SecurementLayerUsages?: Maybe<SecurementLayerUsage_Bool_Exp>;
  ToLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SecurementLayer_Bool_Exp>>;
  _not?: Maybe<SecurementLayer_Bool_Exp>;
  _or?: Maybe<Array<SecurementLayer_Bool_Exp>>;
  layerByFromlayerid?: Maybe<Layer_Bool_Exp>;
  layerByTolayerid?: Maybe<Layer_Bool_Exp>;
};

/** upsert condition type for table "SecurementLayer" */
export type SecurementLayer_If_Matched = {
  match_columns?: Array<SecurementLayer_Insert_Match_Column>;
  update_columns?: Array<SecurementLayer_Update_Column>;
  where?: Maybe<SecurementLayer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SecurementLayer" */
export type SecurementLayer_Inc_Input = {
  FromLayerId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  ToLayerId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SecurementLayer" */
export type SecurementLayer_Insert_Input = {
  FromLayerId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  ToLayerId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SecurementLayer" */
export enum SecurementLayer_Insert_Match_Column {
  /** column name */
  FromLayerId = 'FromLayerId',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  ToLayerId = 'ToLayerId'
}

/** aggregate max on columns */
export type SecurementLayer_Max_Fields = {
  __typename?: 'SecurementLayer_max_fields';
  FromLayerId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  ToLayerId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "SecurementLayer" */
export type SecurementLayer_Max_Order_By = {
  FromLayerId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  ToLayerId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SecurementLayer_Min_Fields = {
  __typename?: 'SecurementLayer_min_fields';
  FromLayerId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  ToLayerId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "SecurementLayer" */
export type SecurementLayer_Min_Order_By = {
  FromLayerId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  ToLayerId?: Maybe<Order_By>;
};

/** response of any mutation on the table "SecurementLayer" */
export type SecurementLayer_Mutation_Response = {
  __typename?: 'SecurementLayer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SecurementLayer>;
};

/** Ordering options when selecting data from "SecurementLayer". */
export type SecurementLayer_Order_By = {
  FromLayerId?: Maybe<Order_By>;
  Layer?: Maybe<Layer_Order_By>;
  LayerId?: Maybe<Order_By>;
  SecurementLayerUsages_aggregate?: Maybe<SecurementLayerUsage_Aggregate_Order_By>;
  ToLayerId?: Maybe<Order_By>;
  layerByFromlayerid?: Maybe<Layer_Order_By>;
  layerByTolayerid?: Maybe<Layer_Order_By>;
};

/** primary key columns input for table: SecurementLayer */
export type SecurementLayer_Pk_Columns_Input = {
  LayerId: Scalars['Int'];
};

/** select columns of table "SecurementLayer" */
export enum SecurementLayer_Select_Column {
  /** column name */
  FromLayerId = 'FromLayerId',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  ToLayerId = 'ToLayerId'
}

/** input type for updating data in table "SecurementLayer" */
export type SecurementLayer_Set_Input = {
  FromLayerId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  ToLayerId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SecurementLayer_Stddev_Fields = {
  __typename?: 'SecurementLayer_stddev_fields';
  FromLayerId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  ToLayerId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SecurementLayer" */
export type SecurementLayer_Stddev_Order_By = {
  FromLayerId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  ToLayerId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SecurementLayer_Stddev_Pop_Fields = {
  __typename?: 'SecurementLayer_stddev_pop_fields';
  FromLayerId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  ToLayerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SecurementLayer" */
export type SecurementLayer_Stddev_Pop_Order_By = {
  FromLayerId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  ToLayerId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SecurementLayer_Stddev_Samp_Fields = {
  __typename?: 'SecurementLayer_stddev_samp_fields';
  FromLayerId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  ToLayerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SecurementLayer" */
export type SecurementLayer_Stddev_Samp_Order_By = {
  FromLayerId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  ToLayerId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type SecurementLayer_Sum_Fields = {
  __typename?: 'SecurementLayer_sum_fields';
  FromLayerId?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  ToLayerId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "SecurementLayer" */
export type SecurementLayer_Sum_Order_By = {
  FromLayerId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  ToLayerId?: Maybe<Order_By>;
};

/** update columns of table "SecurementLayer" */
export enum SecurementLayer_Update_Column {
  /** column name */
  FromLayerId = 'FromLayerId',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  ToLayerId = 'ToLayerId'
}

/** aggregate var_pop on columns */
export type SecurementLayer_Var_Pop_Fields = {
  __typename?: 'SecurementLayer_var_pop_fields';
  FromLayerId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  ToLayerId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SecurementLayer" */
export type SecurementLayer_Var_Pop_Order_By = {
  FromLayerId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  ToLayerId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SecurementLayer_Var_Samp_Fields = {
  __typename?: 'SecurementLayer_var_samp_fields';
  FromLayerId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  ToLayerId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SecurementLayer" */
export type SecurementLayer_Var_Samp_Order_By = {
  FromLayerId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  ToLayerId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SecurementLayer_Variance_Fields = {
  __typename?: 'SecurementLayer_variance_fields';
  FromLayerId?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  ToLayerId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SecurementLayer" */
export type SecurementLayer_Variance_Order_By = {
  FromLayerId?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  ToLayerId?: Maybe<Order_By>;
};

export type SelfSecurementSearchCriteria = {
  approvedUseId: Scalars['Int'];
  attributes: Array<Maybe<AttributeSearchCriteria>>;
  componentIds: Array<Maybe<Scalars['Int']>>;
  layerNum: Scalars['Int'];
  manufacturerIds: Array<Maybe<Scalars['Int']>>;
  selfSecurementLayerTypeId: Scalars['Int'];
  subCategoryId: Scalars['Int'];
};

/** columns and relationships of "SelfSecurementUsage" */
export type SelfSecurementUsage = {
  __typename?: 'SelfSecurementUsage';
  /** An object relationship */
  ComponentLayer: ComponentLayer;
  LayerId: Scalars['Int'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "SelfSecurementUsage" */
export type SelfSecurementUsage_Aggregate = {
  __typename?: 'SelfSecurementUsage_aggregate';
  aggregate?: Maybe<SelfSecurementUsage_Aggregate_Fields>;
  nodes: Array<SelfSecurementUsage>;
};

/** aggregate fields of "SelfSecurementUsage" */
export type SelfSecurementUsage_Aggregate_Fields = {
  __typename?: 'SelfSecurementUsage_aggregate_fields';
  avg?: Maybe<SelfSecurementUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SelfSecurementUsage_Max_Fields>;
  min?: Maybe<SelfSecurementUsage_Min_Fields>;
  stddev?: Maybe<SelfSecurementUsage_Stddev_Fields>;
  stddev_pop?: Maybe<SelfSecurementUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SelfSecurementUsage_Stddev_Samp_Fields>;
  sum?: Maybe<SelfSecurementUsage_Sum_Fields>;
  var_pop?: Maybe<SelfSecurementUsage_Var_Pop_Fields>;
  var_samp?: Maybe<SelfSecurementUsage_Var_Samp_Fields>;
  variance?: Maybe<SelfSecurementUsage_Variance_Fields>;
};


/** aggregate fields of "SelfSecurementUsage" */
export type SelfSecurementUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<SelfSecurementUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SelfSecurementUsage" */
export type SelfSecurementUsage_Aggregate_Order_By = {
  avg?: Maybe<SelfSecurementUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SelfSecurementUsage_Max_Order_By>;
  min?: Maybe<SelfSecurementUsage_Min_Order_By>;
  stddev?: Maybe<SelfSecurementUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<SelfSecurementUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SelfSecurementUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<SelfSecurementUsage_Sum_Order_By>;
  var_pop?: Maybe<SelfSecurementUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<SelfSecurementUsage_Var_Samp_Order_By>;
  variance?: Maybe<SelfSecurementUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type SelfSecurementUsage_Avg_Fields = {
  __typename?: 'SelfSecurementUsage_avg_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SelfSecurementUsage" */
export type SelfSecurementUsage_Avg_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SelfSecurementUsage". All fields are combined with a logical 'AND'. */
export type SelfSecurementUsage_Bool_Exp = {
  ComponentLayer?: Maybe<ComponentLayer_Bool_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SelfSecurementUsage_Bool_Exp>>;
  _not?: Maybe<SelfSecurementUsage_Bool_Exp>;
  _or?: Maybe<Array<SelfSecurementUsage_Bool_Exp>>;
};

/** upsert condition type for table "SelfSecurementUsage" */
export type SelfSecurementUsage_If_Matched = {
  match_columns?: Array<SelfSecurementUsage_Insert_Match_Column>;
  update_columns?: Array<SelfSecurementUsage_Update_Column>;
  where?: Maybe<SelfSecurementUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SelfSecurementUsage" */
export type SelfSecurementUsage_Inc_Input = {
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SelfSecurementUsage" */
export type SelfSecurementUsage_Insert_Input = {
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SelfSecurementUsage" */
export enum SelfSecurementUsage_Insert_Match_Column {
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type SelfSecurementUsage_Max_Fields = {
  __typename?: 'SelfSecurementUsage_max_fields';
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "SelfSecurementUsage" */
export type SelfSecurementUsage_Max_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SelfSecurementUsage_Min_Fields = {
  __typename?: 'SelfSecurementUsage_min_fields';
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "SelfSecurementUsage" */
export type SelfSecurementUsage_Min_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "SelfSecurementUsage" */
export type SelfSecurementUsage_Mutation_Response = {
  __typename?: 'SelfSecurementUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SelfSecurementUsage>;
};

/** Ordering options when selecting data from "SelfSecurementUsage". */
export type SelfSecurementUsage_Order_By = {
  ComponentLayer?: Maybe<ComponentLayer_Order_By>;
  LayerId?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: SelfSecurementUsage */
export type SelfSecurementUsage_Pk_Columns_Input = {
  LayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};

/** select columns of table "SelfSecurementUsage" */
export enum SelfSecurementUsage_Select_Column {
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "SelfSecurementUsage" */
export type SelfSecurementUsage_Set_Input = {
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SelfSecurementUsage_Stddev_Fields = {
  __typename?: 'SelfSecurementUsage_stddev_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SelfSecurementUsage" */
export type SelfSecurementUsage_Stddev_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SelfSecurementUsage_Stddev_Pop_Fields = {
  __typename?: 'SelfSecurementUsage_stddev_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SelfSecurementUsage" */
export type SelfSecurementUsage_Stddev_Pop_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SelfSecurementUsage_Stddev_Samp_Fields = {
  __typename?: 'SelfSecurementUsage_stddev_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SelfSecurementUsage" */
export type SelfSecurementUsage_Stddev_Samp_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type SelfSecurementUsage_Sum_Fields = {
  __typename?: 'SelfSecurementUsage_sum_fields';
  LayerId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "SelfSecurementUsage" */
export type SelfSecurementUsage_Sum_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "SelfSecurementUsage" */
export enum SelfSecurementUsage_Update_Column {
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type SelfSecurementUsage_Var_Pop_Fields = {
  __typename?: 'SelfSecurementUsage_var_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SelfSecurementUsage" */
export type SelfSecurementUsage_Var_Pop_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SelfSecurementUsage_Var_Samp_Fields = {
  __typename?: 'SelfSecurementUsage_var_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SelfSecurementUsage" */
export type SelfSecurementUsage_Var_Samp_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SelfSecurementUsage_Variance_Fields = {
  __typename?: 'SelfSecurementUsage_variance_fields';
  LayerId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SelfSecurementUsage" */
export type SelfSecurementUsage_Variance_Order_By = {
  LayerId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "SeparatorSheet" */
export type SeparatorSheet = {
  __typename?: 'SeparatorSheet';
  /** An object relationship */
  C_RetarderMaterial: C_RetarderMaterial;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  RetarderMaterialId: Scalars['Int'];
};

/** columns and relationships of "SeparatorSheetUsage" */
export type SeparatorSheetUsage = {
  __typename?: 'SeparatorSheetUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "SeparatorSheetUsage" */
export type SeparatorSheetUsage_Aggregate = {
  __typename?: 'SeparatorSheetUsage_aggregate';
  aggregate?: Maybe<SeparatorSheetUsage_Aggregate_Fields>;
  nodes: Array<SeparatorSheetUsage>;
};

/** aggregate fields of "SeparatorSheetUsage" */
export type SeparatorSheetUsage_Aggregate_Fields = {
  __typename?: 'SeparatorSheetUsage_aggregate_fields';
  avg?: Maybe<SeparatorSheetUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SeparatorSheetUsage_Max_Fields>;
  min?: Maybe<SeparatorSheetUsage_Min_Fields>;
  stddev?: Maybe<SeparatorSheetUsage_Stddev_Fields>;
  stddev_pop?: Maybe<SeparatorSheetUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SeparatorSheetUsage_Stddev_Samp_Fields>;
  sum?: Maybe<SeparatorSheetUsage_Sum_Fields>;
  var_pop?: Maybe<SeparatorSheetUsage_Var_Pop_Fields>;
  var_samp?: Maybe<SeparatorSheetUsage_Var_Samp_Fields>;
  variance?: Maybe<SeparatorSheetUsage_Variance_Fields>;
};


/** aggregate fields of "SeparatorSheetUsage" */
export type SeparatorSheetUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<SeparatorSheetUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SeparatorSheetUsage_Avg_Fields = {
  __typename?: 'SeparatorSheetUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SeparatorSheetUsage". All fields are combined with a logical 'AND'. */
export type SeparatorSheetUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SeparatorSheetUsage_Bool_Exp>>;
  _not?: Maybe<SeparatorSheetUsage_Bool_Exp>;
  _or?: Maybe<Array<SeparatorSheetUsage_Bool_Exp>>;
};

/** upsert condition type for table "SeparatorSheetUsage" */
export type SeparatorSheetUsage_If_Matched = {
  match_columns?: Array<SeparatorSheetUsage_Insert_Match_Column>;
  update_columns?: Array<SeparatorSheetUsage_Update_Column>;
  where?: Maybe<SeparatorSheetUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SeparatorSheetUsage" */
export type SeparatorSheetUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SeparatorSheetUsage" */
export type SeparatorSheetUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SeparatorSheetUsage" */
export enum SeparatorSheetUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type SeparatorSheetUsage_Max_Fields = {
  __typename?: 'SeparatorSheetUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SeparatorSheetUsage_Min_Fields = {
  __typename?: 'SeparatorSheetUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "SeparatorSheetUsage" */
export type SeparatorSheetUsage_Mutation_Response = {
  __typename?: 'SeparatorSheetUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SeparatorSheetUsage>;
};

/** Ordering options when selecting data from "SeparatorSheetUsage". */
export type SeparatorSheetUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: SeparatorSheetUsage */
export type SeparatorSheetUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "SeparatorSheetUsage" */
export enum SeparatorSheetUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "SeparatorSheetUsage" */
export type SeparatorSheetUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SeparatorSheetUsage_Stddev_Fields = {
  __typename?: 'SeparatorSheetUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SeparatorSheetUsage_Stddev_Pop_Fields = {
  __typename?: 'SeparatorSheetUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SeparatorSheetUsage_Stddev_Samp_Fields = {
  __typename?: 'SeparatorSheetUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SeparatorSheetUsage_Sum_Fields = {
  __typename?: 'SeparatorSheetUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "SeparatorSheetUsage" */
export enum SeparatorSheetUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type SeparatorSheetUsage_Var_Pop_Fields = {
  __typename?: 'SeparatorSheetUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SeparatorSheetUsage_Var_Samp_Fields = {
  __typename?: 'SeparatorSheetUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SeparatorSheetUsage_Variance_Fields = {
  __typename?: 'SeparatorSheetUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "SeparatorSheet" */
export type SeparatorSheet_Aggregate = {
  __typename?: 'SeparatorSheet_aggregate';
  aggregate?: Maybe<SeparatorSheet_Aggregate_Fields>;
  nodes: Array<SeparatorSheet>;
};

/** aggregate fields of "SeparatorSheet" */
export type SeparatorSheet_Aggregate_Fields = {
  __typename?: 'SeparatorSheet_aggregate_fields';
  avg?: Maybe<SeparatorSheet_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SeparatorSheet_Max_Fields>;
  min?: Maybe<SeparatorSheet_Min_Fields>;
  stddev?: Maybe<SeparatorSheet_Stddev_Fields>;
  stddev_pop?: Maybe<SeparatorSheet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SeparatorSheet_Stddev_Samp_Fields>;
  sum?: Maybe<SeparatorSheet_Sum_Fields>;
  var_pop?: Maybe<SeparatorSheet_Var_Pop_Fields>;
  var_samp?: Maybe<SeparatorSheet_Var_Samp_Fields>;
  variance?: Maybe<SeparatorSheet_Variance_Fields>;
};


/** aggregate fields of "SeparatorSheet" */
export type SeparatorSheet_Aggregate_FieldsCountArgs = {
  column?: Maybe<SeparatorSheet_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SeparatorSheet" */
export type SeparatorSheet_Aggregate_Order_By = {
  avg?: Maybe<SeparatorSheet_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SeparatorSheet_Max_Order_By>;
  min?: Maybe<SeparatorSheet_Min_Order_By>;
  stddev?: Maybe<SeparatorSheet_Stddev_Order_By>;
  stddev_pop?: Maybe<SeparatorSheet_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SeparatorSheet_Stddev_Samp_Order_By>;
  sum?: Maybe<SeparatorSheet_Sum_Order_By>;
  var_pop?: Maybe<SeparatorSheet_Var_Pop_Order_By>;
  var_samp?: Maybe<SeparatorSheet_Var_Samp_Order_By>;
  variance?: Maybe<SeparatorSheet_Variance_Order_By>;
};

/** aggregate avg on columns */
export type SeparatorSheet_Avg_Fields = {
  __typename?: 'SeparatorSheet_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SeparatorSheet" */
export type SeparatorSheet_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SeparatorSheet". All fields are combined with a logical 'AND'. */
export type SeparatorSheet_Bool_Exp = {
  C_RetarderMaterial?: Maybe<C_RetarderMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  RetarderMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SeparatorSheet_Bool_Exp>>;
  _not?: Maybe<SeparatorSheet_Bool_Exp>;
  _or?: Maybe<Array<SeparatorSheet_Bool_Exp>>;
};

/** upsert condition type for table "SeparatorSheet" */
export type SeparatorSheet_If_Matched = {
  match_columns?: Array<SeparatorSheet_Insert_Match_Column>;
  update_columns?: Array<SeparatorSheet_Update_Column>;
  where?: Maybe<SeparatorSheet_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SeparatorSheet" */
export type SeparatorSheet_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SeparatorSheet" */
export type SeparatorSheet_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SeparatorSheet" */
export enum SeparatorSheet_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId'
}

/** aggregate max on columns */
export type SeparatorSheet_Max_Fields = {
  __typename?: 'SeparatorSheet_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "SeparatorSheet" */
export type SeparatorSheet_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SeparatorSheet_Min_Fields = {
  __typename?: 'SeparatorSheet_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "SeparatorSheet" */
export type SeparatorSheet_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** response of any mutation on the table "SeparatorSheet" */
export type SeparatorSheet_Mutation_Response = {
  __typename?: 'SeparatorSheet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SeparatorSheet>;
};

/** Ordering options when selecting data from "SeparatorSheet". */
export type SeparatorSheet_Order_By = {
  C_RetarderMaterial?: Maybe<C_RetarderMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** primary key columns input for table: SeparatorSheet */
export type SeparatorSheet_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "SeparatorSheet" */
export enum SeparatorSheet_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId'
}

/** input type for updating data in table "SeparatorSheet" */
export type SeparatorSheet_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SeparatorSheet_Stddev_Fields = {
  __typename?: 'SeparatorSheet_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SeparatorSheet" */
export type SeparatorSheet_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SeparatorSheet_Stddev_Pop_Fields = {
  __typename?: 'SeparatorSheet_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SeparatorSheet" */
export type SeparatorSheet_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SeparatorSheet_Stddev_Samp_Fields = {
  __typename?: 'SeparatorSheet_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SeparatorSheet" */
export type SeparatorSheet_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type SeparatorSheet_Sum_Fields = {
  __typename?: 'SeparatorSheet_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "SeparatorSheet" */
export type SeparatorSheet_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** update columns of table "SeparatorSheet" */
export enum SeparatorSheet_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId'
}

/** aggregate var_pop on columns */
export type SeparatorSheet_Var_Pop_Fields = {
  __typename?: 'SeparatorSheet_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SeparatorSheet" */
export type SeparatorSheet_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SeparatorSheet_Var_Samp_Fields = {
  __typename?: 'SeparatorSheet_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SeparatorSheet" */
export type SeparatorSheet_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SeparatorSheet_Variance_Fields = {
  __typename?: 'SeparatorSheet_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SeparatorSheet" */
export type SeparatorSheet_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "Shingles" */
export type Shingles = {
  __typename?: 'Shingles';
  /** An object relationship */
  C_ShinglesHail: C_ShinglesHail;
  /** An object relationship */
  C_ShinglesMaterial: C_ShinglesMaterial;
  /** An object relationship */
  C_ShinglesReinforcement: C_ShinglesReinforcement;
  /** An object relationship */
  C_ShinglesSurfacing: C_ShinglesSurfacing;
  /** An object relationship */
  C_ShinglesWindSpeed: C_ShinglesWindSpeed;
  ComponentId: Scalars['Int'];
  Length: Scalars['MeasurementValue'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  NominalWeight: Scalars['MeasurementValue'];
  ShinglesHailId: Scalars['Int'];
  ShinglesMaterialId: Scalars['Int'];
  ShinglesReinforcementId: Scalars['Int'];
  ShinglesSurfacingId: Scalars['Int'];
  ShinglesWindSpeedId: Scalars['Int'];
  Width: Scalars['MeasurementValue'];
  WindUplift: Scalars['MeasurementValue'];
};

/** aggregated selection of "Shingles" */
export type Shingles_Aggregate = {
  __typename?: 'Shingles_aggregate';
  aggregate?: Maybe<Shingles_Aggregate_Fields>;
  nodes: Array<Shingles>;
};

/** aggregate fields of "Shingles" */
export type Shingles_Aggregate_Fields = {
  __typename?: 'Shingles_aggregate_fields';
  avg?: Maybe<Shingles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Shingles_Max_Fields>;
  min?: Maybe<Shingles_Min_Fields>;
  stddev?: Maybe<Shingles_Stddev_Fields>;
  stddev_pop?: Maybe<Shingles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Shingles_Stddev_Samp_Fields>;
  sum?: Maybe<Shingles_Sum_Fields>;
  var_pop?: Maybe<Shingles_Var_Pop_Fields>;
  var_samp?: Maybe<Shingles_Var_Samp_Fields>;
  variance?: Maybe<Shingles_Variance_Fields>;
};


/** aggregate fields of "Shingles" */
export type Shingles_Aggregate_FieldsCountArgs = {
  column?: Maybe<Shingles_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Shingles" */
export type Shingles_Aggregate_Order_By = {
  avg?: Maybe<Shingles_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Shingles_Max_Order_By>;
  min?: Maybe<Shingles_Min_Order_By>;
  stddev?: Maybe<Shingles_Stddev_Order_By>;
  stddev_pop?: Maybe<Shingles_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Shingles_Stddev_Samp_Order_By>;
  sum?: Maybe<Shingles_Sum_Order_By>;
  var_pop?: Maybe<Shingles_Var_Pop_Order_By>;
  var_samp?: Maybe<Shingles_Var_Samp_Order_By>;
  variance?: Maybe<Shingles_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Shingles_Avg_Fields = {
  __typename?: 'Shingles_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ShinglesHailId?: Maybe<Scalars['Float']>;
  ShinglesMaterialId?: Maybe<Scalars['Float']>;
  ShinglesReinforcementId?: Maybe<Scalars['Float']>;
  ShinglesSurfacingId?: Maybe<Scalars['Float']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Shingles" */
export type Shingles_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ShinglesHailId?: Maybe<Order_By>;
  ShinglesMaterialId?: Maybe<Order_By>;
  ShinglesReinforcementId?: Maybe<Order_By>;
  ShinglesSurfacingId?: Maybe<Order_By>;
  ShinglesWindSpeedId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Shingles". All fields are combined with a logical 'AND'. */
export type Shingles_Bool_Exp = {
  C_ShinglesHail?: Maybe<C_ShinglesHail_Bool_Exp>;
  C_ShinglesMaterial?: Maybe<C_ShinglesMaterial_Bool_Exp>;
  C_ShinglesReinforcement?: Maybe<C_ShinglesReinforcement_Bool_Exp>;
  C_ShinglesSurfacing?: Maybe<C_ShinglesSurfacing_Bool_Exp>;
  C_ShinglesWindSpeed?: Maybe<C_ShinglesWindSpeed_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  Length?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  NominalWeight?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ShinglesHailId?: Maybe<Int_Mssql_Comparison_Exp>;
  ShinglesMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ShinglesReinforcementId?: Maybe<Int_Mssql_Comparison_Exp>;
  ShinglesSurfacingId?: Maybe<Int_Mssql_Comparison_Exp>;
  ShinglesWindSpeedId?: Maybe<Int_Mssql_Comparison_Exp>;
  Width?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  WindUplift?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Shingles_Bool_Exp>>;
  _not?: Maybe<Shingles_Bool_Exp>;
  _or?: Maybe<Array<Shingles_Bool_Exp>>;
};

/** upsert condition type for table "Shingles" */
export type Shingles_If_Matched = {
  match_columns?: Array<Shingles_Insert_Match_Column>;
  update_columns?: Array<Shingles_Update_Column>;
  where?: Maybe<Shingles_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Shingles" */
export type Shingles_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ShinglesHailId?: Maybe<Scalars['Int']>;
  ShinglesMaterialId?: Maybe<Scalars['Int']>;
  ShinglesReinforcementId?: Maybe<Scalars['Int']>;
  ShinglesSurfacingId?: Maybe<Scalars['Int']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Shingles" */
export type Shingles_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  NominalWeight?: Maybe<Scalars['MeasurementValue']>;
  ShinglesHailId?: Maybe<Scalars['Int']>;
  ShinglesMaterialId?: Maybe<Scalars['Int']>;
  ShinglesReinforcementId?: Maybe<Scalars['Int']>;
  ShinglesSurfacingId?: Maybe<Scalars['Int']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "Shingles" */
export enum Shingles_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  Length = 'Length',
  /** column name */
  NominalWeight = 'NominalWeight',
  /** column name */
  ShinglesHailId = 'ShinglesHailId',
  /** column name */
  ShinglesMaterialId = 'ShinglesMaterialId',
  /** column name */
  ShinglesReinforcementId = 'ShinglesReinforcementId',
  /** column name */
  ShinglesSurfacingId = 'ShinglesSurfacingId',
  /** column name */
  ShinglesWindSpeedId = 'ShinglesWindSpeedId',
  /** column name */
  Width = 'Width',
  /** column name */
  WindUplift = 'WindUplift'
}

/** aggregate max on columns */
export type Shingles_Max_Fields = {
  __typename?: 'Shingles_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  NominalWeight?: Maybe<Scalars['MeasurementValue']>;
  ShinglesHailId?: Maybe<Scalars['Int']>;
  ShinglesMaterialId?: Maybe<Scalars['Int']>;
  ShinglesReinforcementId?: Maybe<Scalars['Int']>;
  ShinglesSurfacingId?: Maybe<Scalars['Int']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "Shingles" */
export type Shingles_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Length?: Maybe<Order_By>;
  NominalWeight?: Maybe<Order_By>;
  ShinglesHailId?: Maybe<Order_By>;
  ShinglesMaterialId?: Maybe<Order_By>;
  ShinglesReinforcementId?: Maybe<Order_By>;
  ShinglesSurfacingId?: Maybe<Order_By>;
  ShinglesWindSpeedId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Shingles_Min_Fields = {
  __typename?: 'Shingles_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  NominalWeight?: Maybe<Scalars['MeasurementValue']>;
  ShinglesHailId?: Maybe<Scalars['Int']>;
  ShinglesMaterialId?: Maybe<Scalars['Int']>;
  ShinglesReinforcementId?: Maybe<Scalars['Int']>;
  ShinglesSurfacingId?: Maybe<Scalars['Int']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "Shingles" */
export type Shingles_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Length?: Maybe<Order_By>;
  NominalWeight?: Maybe<Order_By>;
  ShinglesHailId?: Maybe<Order_By>;
  ShinglesMaterialId?: Maybe<Order_By>;
  ShinglesReinforcementId?: Maybe<Order_By>;
  ShinglesSurfacingId?: Maybe<Order_By>;
  ShinglesWindSpeedId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
};

/** response of any mutation on the table "Shingles" */
export type Shingles_Mutation_Response = {
  __typename?: 'Shingles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Shingles>;
};

/** Ordering options when selecting data from "Shingles". */
export type Shingles_Order_By = {
  C_ShinglesHail?: Maybe<C_ShinglesHail_Order_By>;
  C_ShinglesMaterial?: Maybe<C_ShinglesMaterial_Order_By>;
  C_ShinglesReinforcement?: Maybe<C_ShinglesReinforcement_Order_By>;
  C_ShinglesSurfacing?: Maybe<C_ShinglesSurfacing_Order_By>;
  C_ShinglesWindSpeed?: Maybe<C_ShinglesWindSpeed_Order_By>;
  ComponentId?: Maybe<Order_By>;
  Length?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  NominalWeight?: Maybe<Order_By>;
  ShinglesHailId?: Maybe<Order_By>;
  ShinglesMaterialId?: Maybe<Order_By>;
  ShinglesReinforcementId?: Maybe<Order_By>;
  ShinglesSurfacingId?: Maybe<Order_By>;
  ShinglesWindSpeedId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
};

/** primary key columns input for table: Shingles */
export type Shingles_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "Shingles" */
export enum Shingles_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  Length = 'Length',
  /** column name */
  NominalWeight = 'NominalWeight',
  /** column name */
  ShinglesHailId = 'ShinglesHailId',
  /** column name */
  ShinglesMaterialId = 'ShinglesMaterialId',
  /** column name */
  ShinglesReinforcementId = 'ShinglesReinforcementId',
  /** column name */
  ShinglesSurfacingId = 'ShinglesSurfacingId',
  /** column name */
  ShinglesWindSpeedId = 'ShinglesWindSpeedId',
  /** column name */
  Width = 'Width',
  /** column name */
  WindUplift = 'WindUplift'
}

/** input type for updating data in table "Shingles" */
export type Shingles_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  NominalWeight?: Maybe<Scalars['MeasurementValue']>;
  ShinglesHailId?: Maybe<Scalars['Int']>;
  ShinglesMaterialId?: Maybe<Scalars['Int']>;
  ShinglesReinforcementId?: Maybe<Scalars['Int']>;
  ShinglesSurfacingId?: Maybe<Scalars['Int']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
  WindUplift?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type Shingles_Stddev_Fields = {
  __typename?: 'Shingles_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ShinglesHailId?: Maybe<Scalars['Float']>;
  ShinglesMaterialId?: Maybe<Scalars['Float']>;
  ShinglesReinforcementId?: Maybe<Scalars['Float']>;
  ShinglesSurfacingId?: Maybe<Scalars['Float']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Shingles" */
export type Shingles_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ShinglesHailId?: Maybe<Order_By>;
  ShinglesMaterialId?: Maybe<Order_By>;
  ShinglesReinforcementId?: Maybe<Order_By>;
  ShinglesSurfacingId?: Maybe<Order_By>;
  ShinglesWindSpeedId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Shingles_Stddev_Pop_Fields = {
  __typename?: 'Shingles_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ShinglesHailId?: Maybe<Scalars['Float']>;
  ShinglesMaterialId?: Maybe<Scalars['Float']>;
  ShinglesReinforcementId?: Maybe<Scalars['Float']>;
  ShinglesSurfacingId?: Maybe<Scalars['Float']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Shingles" */
export type Shingles_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ShinglesHailId?: Maybe<Order_By>;
  ShinglesMaterialId?: Maybe<Order_By>;
  ShinglesReinforcementId?: Maybe<Order_By>;
  ShinglesSurfacingId?: Maybe<Order_By>;
  ShinglesWindSpeedId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Shingles_Stddev_Samp_Fields = {
  __typename?: 'Shingles_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ShinglesHailId?: Maybe<Scalars['Float']>;
  ShinglesMaterialId?: Maybe<Scalars['Float']>;
  ShinglesReinforcementId?: Maybe<Scalars['Float']>;
  ShinglesSurfacingId?: Maybe<Scalars['Float']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Shingles" */
export type Shingles_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ShinglesHailId?: Maybe<Order_By>;
  ShinglesMaterialId?: Maybe<Order_By>;
  ShinglesReinforcementId?: Maybe<Order_By>;
  ShinglesSurfacingId?: Maybe<Order_By>;
  ShinglesWindSpeedId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Shingles_Sum_Fields = {
  __typename?: 'Shingles_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ShinglesHailId?: Maybe<Scalars['Int']>;
  ShinglesMaterialId?: Maybe<Scalars['Int']>;
  ShinglesReinforcementId?: Maybe<Scalars['Int']>;
  ShinglesSurfacingId?: Maybe<Scalars['Int']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Shingles" */
export type Shingles_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ShinglesHailId?: Maybe<Order_By>;
  ShinglesMaterialId?: Maybe<Order_By>;
  ShinglesReinforcementId?: Maybe<Order_By>;
  ShinglesSurfacingId?: Maybe<Order_By>;
  ShinglesWindSpeedId?: Maybe<Order_By>;
};

/** update columns of table "Shingles" */
export enum Shingles_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  Length = 'Length',
  /** column name */
  NominalWeight = 'NominalWeight',
  /** column name */
  ShinglesHailId = 'ShinglesHailId',
  /** column name */
  ShinglesMaterialId = 'ShinglesMaterialId',
  /** column name */
  ShinglesReinforcementId = 'ShinglesReinforcementId',
  /** column name */
  ShinglesSurfacingId = 'ShinglesSurfacingId',
  /** column name */
  ShinglesWindSpeedId = 'ShinglesWindSpeedId',
  /** column name */
  Width = 'Width',
  /** column name */
  WindUplift = 'WindUplift'
}

/** aggregate var_pop on columns */
export type Shingles_Var_Pop_Fields = {
  __typename?: 'Shingles_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ShinglesHailId?: Maybe<Scalars['Float']>;
  ShinglesMaterialId?: Maybe<Scalars['Float']>;
  ShinglesReinforcementId?: Maybe<Scalars['Float']>;
  ShinglesSurfacingId?: Maybe<Scalars['Float']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Shingles" */
export type Shingles_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ShinglesHailId?: Maybe<Order_By>;
  ShinglesMaterialId?: Maybe<Order_By>;
  ShinglesReinforcementId?: Maybe<Order_By>;
  ShinglesSurfacingId?: Maybe<Order_By>;
  ShinglesWindSpeedId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Shingles_Var_Samp_Fields = {
  __typename?: 'Shingles_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ShinglesHailId?: Maybe<Scalars['Float']>;
  ShinglesMaterialId?: Maybe<Scalars['Float']>;
  ShinglesReinforcementId?: Maybe<Scalars['Float']>;
  ShinglesSurfacingId?: Maybe<Scalars['Float']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Shingles" */
export type Shingles_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ShinglesHailId?: Maybe<Order_By>;
  ShinglesMaterialId?: Maybe<Order_By>;
  ShinglesReinforcementId?: Maybe<Order_By>;
  ShinglesSurfacingId?: Maybe<Order_By>;
  ShinglesWindSpeedId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Shingles_Variance_Fields = {
  __typename?: 'Shingles_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ShinglesHailId?: Maybe<Scalars['Float']>;
  ShinglesMaterialId?: Maybe<Scalars['Float']>;
  ShinglesReinforcementId?: Maybe<Scalars['Float']>;
  ShinglesSurfacingId?: Maybe<Scalars['Float']>;
  ShinglesWindSpeedId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Shingles" */
export type Shingles_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ShinglesHailId?: Maybe<Order_By>;
  ShinglesMaterialId?: Maybe<Order_By>;
  ShinglesReinforcementId?: Maybe<Order_By>;
  ShinglesSurfacingId?: Maybe<Order_By>;
  ShinglesWindSpeedId?: Maybe<Order_By>;
};

/** columns and relationships of "SinglePlyCover" */
export type SinglePlyCover = {
  __typename?: 'SinglePlyCover';
  BackingMaterialId: Scalars['Int'];
  /** An object relationship */
  C_BackingMaterial: C_BackingMaterial;
  /** An object relationship */
  C_CoverColor: C_CoverColor;
  /** An object relationship */
  C_Reinforcement: C_Reinforcement;
  /** An object relationship */
  C_SinglePlyMaterial: C_SinglePlyMaterial;
  ComponentId: Scalars['Int'];
  CoverColorId: Scalars['Int'];
  IsAppliedToAdheredAssemblies: Scalars['Boolean'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  /** An array relationship */
  R_SinglePly_BondingAdhesives: Array<R_SinglePly_BondingAdhesives>;
  /** An aggregate relationship */
  R_SinglePly_BondingAdhesives_aggregate: R_SinglePly_BondingAdhesives_Aggregate;
  /** An array relationship */
  R_SinglePly_LapAdhesives: Array<R_SinglePly_LapAdhesives>;
  /** An aggregate relationship */
  R_SinglePly_LapAdhesives_aggregate: R_SinglePly_LapAdhesives_Aggregate;
  ReinforcementId: Scalars['Int'];
  SinglePlyMaterialId: Scalars['Int'];
  ThicknessMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
};


/** columns and relationships of "SinglePlyCover" */
export type SinglePlyCoverR_SinglePly_BondingAdhesivesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_BondingAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_BondingAdhesives_Bool_Exp>;
};


/** columns and relationships of "SinglePlyCover" */
export type SinglePlyCoverR_SinglePly_BondingAdhesives_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_BondingAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_BondingAdhesives_Bool_Exp>;
};


/** columns and relationships of "SinglePlyCover" */
export type SinglePlyCoverR_SinglePly_LapAdhesivesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_LapAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_LapAdhesives_Bool_Exp>;
};


/** columns and relationships of "SinglePlyCover" */
export type SinglePlyCoverR_SinglePly_LapAdhesives_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_LapAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_LapAdhesives_Bool_Exp>;
};

/** aggregated selection of "SinglePlyCover" */
export type SinglePlyCover_Aggregate = {
  __typename?: 'SinglePlyCover_aggregate';
  aggregate?: Maybe<SinglePlyCover_Aggregate_Fields>;
  nodes: Array<SinglePlyCover>;
};

/** aggregate fields of "SinglePlyCover" */
export type SinglePlyCover_Aggregate_Fields = {
  __typename?: 'SinglePlyCover_aggregate_fields';
  avg?: Maybe<SinglePlyCover_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SinglePlyCover_Max_Fields>;
  min?: Maybe<SinglePlyCover_Min_Fields>;
  stddev?: Maybe<SinglePlyCover_Stddev_Fields>;
  stddev_pop?: Maybe<SinglePlyCover_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SinglePlyCover_Stddev_Samp_Fields>;
  sum?: Maybe<SinglePlyCover_Sum_Fields>;
  var_pop?: Maybe<SinglePlyCover_Var_Pop_Fields>;
  var_samp?: Maybe<SinglePlyCover_Var_Samp_Fields>;
  variance?: Maybe<SinglePlyCover_Variance_Fields>;
};


/** aggregate fields of "SinglePlyCover" */
export type SinglePlyCover_Aggregate_FieldsCountArgs = {
  column?: Maybe<SinglePlyCover_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SinglePlyCover" */
export type SinglePlyCover_Aggregate_Order_By = {
  avg?: Maybe<SinglePlyCover_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SinglePlyCover_Max_Order_By>;
  min?: Maybe<SinglePlyCover_Min_Order_By>;
  stddev?: Maybe<SinglePlyCover_Stddev_Order_By>;
  stddev_pop?: Maybe<SinglePlyCover_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SinglePlyCover_Stddev_Samp_Order_By>;
  sum?: Maybe<SinglePlyCover_Sum_Order_By>;
  var_pop?: Maybe<SinglePlyCover_Var_Pop_Order_By>;
  var_samp?: Maybe<SinglePlyCover_Var_Samp_Order_By>;
  variance?: Maybe<SinglePlyCover_Variance_Order_By>;
};

/** aggregate avg on columns */
export type SinglePlyCover_Avg_Fields = {
  __typename?: 'SinglePlyCover_avg_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SinglePlyCover" */
export type SinglePlyCover_Avg_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SinglePlyCover". All fields are combined with a logical 'AND'. */
export type SinglePlyCover_Bool_Exp = {
  BackingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_BackingMaterial?: Maybe<C_BackingMaterial_Bool_Exp>;
  C_CoverColor?: Maybe<C_CoverColor_Bool_Exp>;
  C_Reinforcement?: Maybe<C_Reinforcement_Bool_Exp>;
  C_SinglePlyMaterial?: Maybe<C_SinglePlyMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  CoverColorId?: Maybe<Int_Mssql_Comparison_Exp>;
  IsAppliedToAdheredAssemblies?: Maybe<Boolean_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  R_SinglePly_BondingAdhesives?: Maybe<R_SinglePly_BondingAdhesives_Bool_Exp>;
  R_SinglePly_LapAdhesives?: Maybe<R_SinglePly_LapAdhesives_Bool_Exp>;
  ReinforcementId?: Maybe<Int_Mssql_Comparison_Exp>;
  SinglePlyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SinglePlyCover_Bool_Exp>>;
  _not?: Maybe<SinglePlyCover_Bool_Exp>;
  _or?: Maybe<Array<SinglePlyCover_Bool_Exp>>;
};

/** upsert condition type for table "SinglePlyCover" */
export type SinglePlyCover_If_Matched = {
  match_columns?: Array<SinglePlyCover_Insert_Match_Column>;
  update_columns?: Array<SinglePlyCover_Update_Column>;
  where?: Maybe<SinglePlyCover_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SinglePlyCover" */
export type SinglePlyCover_Inc_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SinglePlyCover" */
export type SinglePlyCover_Insert_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  IsAppliedToAdheredAssemblies?: Maybe<Scalars['Boolean']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "SinglePlyCover" */
export enum SinglePlyCover_Insert_Match_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverColorId = 'CoverColorId',
  /** column name */
  IsAppliedToAdheredAssemblies = 'IsAppliedToAdheredAssemblies',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  SinglePlyMaterialId = 'SinglePlyMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate max on columns */
export type SinglePlyCover_Max_Fields = {
  __typename?: 'SinglePlyCover_max_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "SinglePlyCover" */
export type SinglePlyCover_Max_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SinglePlyCover_Min_Fields = {
  __typename?: 'SinglePlyCover_min_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "SinglePlyCover" */
export type SinglePlyCover_Min_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** response of any mutation on the table "SinglePlyCover" */
export type SinglePlyCover_Mutation_Response = {
  __typename?: 'SinglePlyCover_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SinglePlyCover>;
};

/** Ordering options when selecting data from "SinglePlyCover". */
export type SinglePlyCover_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  C_BackingMaterial?: Maybe<C_BackingMaterial_Order_By>;
  C_CoverColor?: Maybe<C_CoverColor_Order_By>;
  C_Reinforcement?: Maybe<C_Reinforcement_Order_By>;
  C_SinglePlyMaterial?: Maybe<C_SinglePlyMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  IsAppliedToAdheredAssemblies?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  R_SinglePly_BondingAdhesives_aggregate?: Maybe<R_SinglePly_BondingAdhesives_Aggregate_Order_By>;
  R_SinglePly_LapAdhesives_aggregate?: Maybe<R_SinglePly_LapAdhesives_Aggregate_Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** primary key columns input for table: SinglePlyCover */
export type SinglePlyCover_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "SinglePlyCover" */
export enum SinglePlyCover_Select_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverColorId = 'CoverColorId',
  /** column name */
  IsAppliedToAdheredAssemblies = 'IsAppliedToAdheredAssemblies',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  SinglePlyMaterialId = 'SinglePlyMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** input type for updating data in table "SinglePlyCover" */
export type SinglePlyCover_Set_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  IsAppliedToAdheredAssemblies?: Maybe<Scalars['Boolean']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type SinglePlyCover_Stddev_Fields = {
  __typename?: 'SinglePlyCover_stddev_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SinglePlyCover" */
export type SinglePlyCover_Stddev_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SinglePlyCover_Stddev_Pop_Fields = {
  __typename?: 'SinglePlyCover_stddev_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SinglePlyCover" */
export type SinglePlyCover_Stddev_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SinglePlyCover_Stddev_Samp_Fields = {
  __typename?: 'SinglePlyCover_stddev_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SinglePlyCover" */
export type SinglePlyCover_Stddev_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type SinglePlyCover_Sum_Fields = {
  __typename?: 'SinglePlyCover_sum_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "SinglePlyCover" */
export type SinglePlyCover_Sum_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** update columns of table "SinglePlyCover" */
export enum SinglePlyCover_Update_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverColorId = 'CoverColorId',
  /** column name */
  IsAppliedToAdheredAssemblies = 'IsAppliedToAdheredAssemblies',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  SinglePlyMaterialId = 'SinglePlyMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate var_pop on columns */
export type SinglePlyCover_Var_Pop_Fields = {
  __typename?: 'SinglePlyCover_var_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SinglePlyCover" */
export type SinglePlyCover_Var_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SinglePlyCover_Var_Samp_Fields = {
  __typename?: 'SinglePlyCover_var_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SinglePlyCover" */
export type SinglePlyCover_Var_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SinglePlyCover_Variance_Fields = {
  __typename?: 'SinglePlyCover_variance_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SinglePlyCover" */
export type SinglePlyCover_Variance_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "SinglePlyUsage" */
export type SinglePlyUsage = {
  __typename?: 'SinglePlyUsage';
  ThicknessMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
  WidthMax: Scalars['MeasurementValue'];
};

/** aggregated selection of "SinglePlyUsage" */
export type SinglePlyUsage_Aggregate = {
  __typename?: 'SinglePlyUsage_aggregate';
  aggregate?: Maybe<SinglePlyUsage_Aggregate_Fields>;
  nodes: Array<SinglePlyUsage>;
};

/** aggregate fields of "SinglePlyUsage" */
export type SinglePlyUsage_Aggregate_Fields = {
  __typename?: 'SinglePlyUsage_aggregate_fields';
  avg?: Maybe<SinglePlyUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SinglePlyUsage_Max_Fields>;
  min?: Maybe<SinglePlyUsage_Min_Fields>;
  stddev?: Maybe<SinglePlyUsage_Stddev_Fields>;
  stddev_pop?: Maybe<SinglePlyUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SinglePlyUsage_Stddev_Samp_Fields>;
  sum?: Maybe<SinglePlyUsage_Sum_Fields>;
  var_pop?: Maybe<SinglePlyUsage_Var_Pop_Fields>;
  var_samp?: Maybe<SinglePlyUsage_Var_Samp_Fields>;
  variance?: Maybe<SinglePlyUsage_Variance_Fields>;
};


/** aggregate fields of "SinglePlyUsage" */
export type SinglePlyUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<SinglePlyUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SinglePlyUsage_Avg_Fields = {
  __typename?: 'SinglePlyUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SinglePlyUsage". All fields are combined with a logical 'AND'. */
export type SinglePlyUsage_Bool_Exp = {
  ThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  WidthMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SinglePlyUsage_Bool_Exp>>;
  _not?: Maybe<SinglePlyUsage_Bool_Exp>;
  _or?: Maybe<Array<SinglePlyUsage_Bool_Exp>>;
};

/** upsert condition type for table "SinglePlyUsage" */
export type SinglePlyUsage_If_Matched = {
  match_columns?: Array<SinglePlyUsage_Insert_Match_Column>;
  update_columns?: Array<SinglePlyUsage_Update_Column>;
  where?: Maybe<SinglePlyUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SinglePlyUsage" */
export type SinglePlyUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SinglePlyUsage" */
export type SinglePlyUsage_Insert_Input = {
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "SinglePlyUsage" */
export enum SinglePlyUsage_Insert_Match_Column {
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax'
}

/** aggregate max on columns */
export type SinglePlyUsage_Max_Fields = {
  __typename?: 'SinglePlyUsage_max_fields';
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate min on columns */
export type SinglePlyUsage_Min_Fields = {
  __typename?: 'SinglePlyUsage_min_fields';
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** response of any mutation on the table "SinglePlyUsage" */
export type SinglePlyUsage_Mutation_Response = {
  __typename?: 'SinglePlyUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SinglePlyUsage>;
};

/** Ordering options when selecting data from "SinglePlyUsage". */
export type SinglePlyUsage_Order_By = {
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** primary key columns input for table: SinglePlyUsage */
export type SinglePlyUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "SinglePlyUsage" */
export enum SinglePlyUsage_Select_Column {
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax'
}

/** input type for updating data in table "SinglePlyUsage" */
export type SinglePlyUsage_Set_Input = {
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type SinglePlyUsage_Stddev_Fields = {
  __typename?: 'SinglePlyUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SinglePlyUsage_Stddev_Pop_Fields = {
  __typename?: 'SinglePlyUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SinglePlyUsage_Stddev_Samp_Fields = {
  __typename?: 'SinglePlyUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SinglePlyUsage_Sum_Fields = {
  __typename?: 'SinglePlyUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "SinglePlyUsage" */
export enum SinglePlyUsage_Update_Column {
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax'
}

/** aggregate var_pop on columns */
export type SinglePlyUsage_Var_Pop_Fields = {
  __typename?: 'SinglePlyUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SinglePlyUsage_Var_Samp_Fields = {
  __typename?: 'SinglePlyUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SinglePlyUsage_Variance_Fields = {
  __typename?: 'SinglePlyUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "SprayFoamInsulationUsage" */
export type SprayFoamInsulationUsage = {
  __typename?: 'SprayFoamInsulationUsage';
  ThicknessMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "SprayFoamInsulationUsage" */
export type SprayFoamInsulationUsage_Aggregate = {
  __typename?: 'SprayFoamInsulationUsage_aggregate';
  aggregate?: Maybe<SprayFoamInsulationUsage_Aggregate_Fields>;
  nodes: Array<SprayFoamInsulationUsage>;
};

/** aggregate fields of "SprayFoamInsulationUsage" */
export type SprayFoamInsulationUsage_Aggregate_Fields = {
  __typename?: 'SprayFoamInsulationUsage_aggregate_fields';
  avg?: Maybe<SprayFoamInsulationUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SprayFoamInsulationUsage_Max_Fields>;
  min?: Maybe<SprayFoamInsulationUsage_Min_Fields>;
  stddev?: Maybe<SprayFoamInsulationUsage_Stddev_Fields>;
  stddev_pop?: Maybe<SprayFoamInsulationUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SprayFoamInsulationUsage_Stddev_Samp_Fields>;
  sum?: Maybe<SprayFoamInsulationUsage_Sum_Fields>;
  var_pop?: Maybe<SprayFoamInsulationUsage_Var_Pop_Fields>;
  var_samp?: Maybe<SprayFoamInsulationUsage_Var_Samp_Fields>;
  variance?: Maybe<SprayFoamInsulationUsage_Variance_Fields>;
};


/** aggregate fields of "SprayFoamInsulationUsage" */
export type SprayFoamInsulationUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<SprayFoamInsulationUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SprayFoamInsulationUsage_Avg_Fields = {
  __typename?: 'SprayFoamInsulationUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SprayFoamInsulationUsage". All fields are combined with a logical 'AND'. */
export type SprayFoamInsulationUsage_Bool_Exp = {
  ThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SprayFoamInsulationUsage_Bool_Exp>>;
  _not?: Maybe<SprayFoamInsulationUsage_Bool_Exp>;
  _or?: Maybe<Array<SprayFoamInsulationUsage_Bool_Exp>>;
};

/** upsert condition type for table "SprayFoamInsulationUsage" */
export type SprayFoamInsulationUsage_If_Matched = {
  match_columns?: Array<SprayFoamInsulationUsage_Insert_Match_Column>;
  update_columns?: Array<SprayFoamInsulationUsage_Update_Column>;
  where?: Maybe<SprayFoamInsulationUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SprayFoamInsulationUsage" */
export type SprayFoamInsulationUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SprayFoamInsulationUsage" */
export type SprayFoamInsulationUsage_Insert_Input = {
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SprayFoamInsulationUsage" */
export enum SprayFoamInsulationUsage_Insert_Match_Column {
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type SprayFoamInsulationUsage_Max_Fields = {
  __typename?: 'SprayFoamInsulationUsage_max_fields';
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SprayFoamInsulationUsage_Min_Fields = {
  __typename?: 'SprayFoamInsulationUsage_min_fields';
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "SprayFoamInsulationUsage" */
export type SprayFoamInsulationUsage_Mutation_Response = {
  __typename?: 'SprayFoamInsulationUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SprayFoamInsulationUsage>;
};

/** Ordering options when selecting data from "SprayFoamInsulationUsage". */
export type SprayFoamInsulationUsage_Order_By = {
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: SprayFoamInsulationUsage */
export type SprayFoamInsulationUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "SprayFoamInsulationUsage" */
export enum SprayFoamInsulationUsage_Select_Column {
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "SprayFoamInsulationUsage" */
export type SprayFoamInsulationUsage_Set_Input = {
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SprayFoamInsulationUsage_Stddev_Fields = {
  __typename?: 'SprayFoamInsulationUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SprayFoamInsulationUsage_Stddev_Pop_Fields = {
  __typename?: 'SprayFoamInsulationUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SprayFoamInsulationUsage_Stddev_Samp_Fields = {
  __typename?: 'SprayFoamInsulationUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SprayFoamInsulationUsage_Sum_Fields = {
  __typename?: 'SprayFoamInsulationUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "SprayFoamInsulationUsage" */
export enum SprayFoamInsulationUsage_Update_Column {
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type SprayFoamInsulationUsage_Var_Pop_Fields = {
  __typename?: 'SprayFoamInsulationUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SprayFoamInsulationUsage_Var_Samp_Fields = {
  __typename?: 'SprayFoamInsulationUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SprayFoamInsulationUsage_Variance_Fields = {
  __typename?: 'SprayFoamInsulationUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "SprayedFoamInsulation" */
export type SprayedFoamInsulation = {
  __typename?: 'SprayedFoamInsulation';
  /** An object relationship */
  C_InsulationFoamType: C_InsulationFoamType;
  ComponentId: Scalars['Int'];
  DensityMax: Scalars['MeasurementValue'];
  DensityMin: Scalars['MeasurementValue'];
  InsulationFoamTypeId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
};

/** aggregated selection of "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Aggregate = {
  __typename?: 'SprayedFoamInsulation_aggregate';
  aggregate?: Maybe<SprayedFoamInsulation_Aggregate_Fields>;
  nodes: Array<SprayedFoamInsulation>;
};

/** aggregate fields of "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Aggregate_Fields = {
  __typename?: 'SprayedFoamInsulation_aggregate_fields';
  avg?: Maybe<SprayedFoamInsulation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SprayedFoamInsulation_Max_Fields>;
  min?: Maybe<SprayedFoamInsulation_Min_Fields>;
  stddev?: Maybe<SprayedFoamInsulation_Stddev_Fields>;
  stddev_pop?: Maybe<SprayedFoamInsulation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SprayedFoamInsulation_Stddev_Samp_Fields>;
  sum?: Maybe<SprayedFoamInsulation_Sum_Fields>;
  var_pop?: Maybe<SprayedFoamInsulation_Var_Pop_Fields>;
  var_samp?: Maybe<SprayedFoamInsulation_Var_Samp_Fields>;
  variance?: Maybe<SprayedFoamInsulation_Variance_Fields>;
};


/** aggregate fields of "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Aggregate_FieldsCountArgs = {
  column?: Maybe<SprayedFoamInsulation_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Aggregate_Order_By = {
  avg?: Maybe<SprayedFoamInsulation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SprayedFoamInsulation_Max_Order_By>;
  min?: Maybe<SprayedFoamInsulation_Min_Order_By>;
  stddev?: Maybe<SprayedFoamInsulation_Stddev_Order_By>;
  stddev_pop?: Maybe<SprayedFoamInsulation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SprayedFoamInsulation_Stddev_Samp_Order_By>;
  sum?: Maybe<SprayedFoamInsulation_Sum_Order_By>;
  var_pop?: Maybe<SprayedFoamInsulation_Var_Pop_Order_By>;
  var_samp?: Maybe<SprayedFoamInsulation_Var_Samp_Order_By>;
  variance?: Maybe<SprayedFoamInsulation_Variance_Order_By>;
};

/** aggregate avg on columns */
export type SprayedFoamInsulation_Avg_Fields = {
  __typename?: 'SprayedFoamInsulation_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SprayedFoamInsulation". All fields are combined with a logical 'AND'. */
export type SprayedFoamInsulation_Bool_Exp = {
  C_InsulationFoamType?: Maybe<C_InsulationFoamType_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  DensityMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  DensityMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  InsulationFoamTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  _and?: Maybe<Array<SprayedFoamInsulation_Bool_Exp>>;
  _not?: Maybe<SprayedFoamInsulation_Bool_Exp>;
  _or?: Maybe<Array<SprayedFoamInsulation_Bool_Exp>>;
};

/** upsert condition type for table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_If_Matched = {
  match_columns?: Array<SprayedFoamInsulation_Insert_Match_Column>;
  update_columns?: Array<SprayedFoamInsulation_Update_Column>;
  where?: Maybe<SprayedFoamInsulation_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMax?: Maybe<Scalars['MeasurementValue']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SprayedFoamInsulation" */
export enum SprayedFoamInsulation_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMax = 'DensityMax',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  InsulationFoamTypeId = 'InsulationFoamTypeId'
}

/** aggregate max on columns */
export type SprayedFoamInsulation_Max_Fields = {
  __typename?: 'SprayedFoamInsulation_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMax?: Maybe<Scalars['MeasurementValue']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DensityMax?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SprayedFoamInsulation_Min_Fields = {
  __typename?: 'SprayedFoamInsulation_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMax?: Maybe<Scalars['MeasurementValue']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DensityMax?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
};

/** response of any mutation on the table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Mutation_Response = {
  __typename?: 'SprayedFoamInsulation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SprayedFoamInsulation>;
};

/** Ordering options when selecting data from "SprayedFoamInsulation". */
export type SprayedFoamInsulation_Order_By = {
  C_InsulationFoamType?: Maybe<C_InsulationFoamType_Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMax?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
};

/** primary key columns input for table: SprayedFoamInsulation */
export type SprayedFoamInsulation_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "SprayedFoamInsulation" */
export enum SprayedFoamInsulation_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMax = 'DensityMax',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  InsulationFoamTypeId = 'InsulationFoamTypeId'
}

/** input type for updating data in table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMax?: Maybe<Scalars['MeasurementValue']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SprayedFoamInsulation_Stddev_Fields = {
  __typename?: 'SprayedFoamInsulation_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SprayedFoamInsulation_Stddev_Pop_Fields = {
  __typename?: 'SprayedFoamInsulation_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SprayedFoamInsulation_Stddev_Samp_Fields = {
  __typename?: 'SprayedFoamInsulation_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type SprayedFoamInsulation_Sum_Fields = {
  __typename?: 'SprayedFoamInsulation_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
};

/** update columns of table "SprayedFoamInsulation" */
export enum SprayedFoamInsulation_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMax = 'DensityMax',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  InsulationFoamTypeId = 'InsulationFoamTypeId'
}

/** aggregate var_pop on columns */
export type SprayedFoamInsulation_Var_Pop_Fields = {
  __typename?: 'SprayedFoamInsulation_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SprayedFoamInsulation_Var_Samp_Fields = {
  __typename?: 'SprayedFoamInsulation_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SprayedFoamInsulation_Variance_Fields = {
  __typename?: 'SprayedFoamInsulation_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SprayedFoamInsulation" */
export type SprayedFoamInsulation_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
};

/** columns and relationships of "StandingLapSeamCover" */
export type StandingLapSeamCover = {
  __typename?: 'StandingLapSeamCover';
  /** An object relationship */
  C_StandingLapSeamMaterial: C_StandingLapSeamMaterial;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  NominalWeight: Scalars['MeasurementValue'];
  StandingLapSeamMaterialId: Scalars['Int'];
  ThicknessMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  WidthMax: Scalars['MeasurementValue'];
  WidthMin: Scalars['MeasurementValue'];
};

/** aggregated selection of "StandingLapSeamCover" */
export type StandingLapSeamCover_Aggregate = {
  __typename?: 'StandingLapSeamCover_aggregate';
  aggregate?: Maybe<StandingLapSeamCover_Aggregate_Fields>;
  nodes: Array<StandingLapSeamCover>;
};

/** aggregate fields of "StandingLapSeamCover" */
export type StandingLapSeamCover_Aggregate_Fields = {
  __typename?: 'StandingLapSeamCover_aggregate_fields';
  avg?: Maybe<StandingLapSeamCover_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StandingLapSeamCover_Max_Fields>;
  min?: Maybe<StandingLapSeamCover_Min_Fields>;
  stddev?: Maybe<StandingLapSeamCover_Stddev_Fields>;
  stddev_pop?: Maybe<StandingLapSeamCover_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StandingLapSeamCover_Stddev_Samp_Fields>;
  sum?: Maybe<StandingLapSeamCover_Sum_Fields>;
  var_pop?: Maybe<StandingLapSeamCover_Var_Pop_Fields>;
  var_samp?: Maybe<StandingLapSeamCover_Var_Samp_Fields>;
  variance?: Maybe<StandingLapSeamCover_Variance_Fields>;
};


/** aggregate fields of "StandingLapSeamCover" */
export type StandingLapSeamCover_Aggregate_FieldsCountArgs = {
  column?: Maybe<StandingLapSeamCover_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "StandingLapSeamCover" */
export type StandingLapSeamCover_Aggregate_Order_By = {
  avg?: Maybe<StandingLapSeamCover_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<StandingLapSeamCover_Max_Order_By>;
  min?: Maybe<StandingLapSeamCover_Min_Order_By>;
  stddev?: Maybe<StandingLapSeamCover_Stddev_Order_By>;
  stddev_pop?: Maybe<StandingLapSeamCover_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<StandingLapSeamCover_Stddev_Samp_Order_By>;
  sum?: Maybe<StandingLapSeamCover_Sum_Order_By>;
  var_pop?: Maybe<StandingLapSeamCover_Var_Pop_Order_By>;
  var_samp?: Maybe<StandingLapSeamCover_Var_Samp_Order_By>;
  variance?: Maybe<StandingLapSeamCover_Variance_Order_By>;
};

/** aggregate avg on columns */
export type StandingLapSeamCover_Avg_Fields = {
  __typename?: 'StandingLapSeamCover_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "StandingLapSeamCover" */
export type StandingLapSeamCover_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "StandingLapSeamCover". All fields are combined with a logical 'AND'. */
export type StandingLapSeamCover_Bool_Exp = {
  C_StandingLapSeamMaterial?: Maybe<C_StandingLapSeamMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  NominalWeight?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  StandingLapSeamMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  WidthMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  WidthMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<StandingLapSeamCover_Bool_Exp>>;
  _not?: Maybe<StandingLapSeamCover_Bool_Exp>;
  _or?: Maybe<Array<StandingLapSeamCover_Bool_Exp>>;
};

/** upsert condition type for table "StandingLapSeamCover" */
export type StandingLapSeamCover_If_Matched = {
  match_columns?: Array<StandingLapSeamCover_Insert_Match_Column>;
  update_columns?: Array<StandingLapSeamCover_Update_Column>;
  where?: Maybe<StandingLapSeamCover_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "StandingLapSeamCover" */
export type StandingLapSeamCover_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "StandingLapSeamCover" */
export type StandingLapSeamCover_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  NominalWeight?: Maybe<Scalars['MeasurementValue']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
  WidthMin?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "StandingLapSeamCover" */
export enum StandingLapSeamCover_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  NominalWeight = 'NominalWeight',
  /** column name */
  StandingLapSeamMaterialId = 'StandingLapSeamMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** aggregate max on columns */
export type StandingLapSeamCover_Max_Fields = {
  __typename?: 'StandingLapSeamCover_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  NominalWeight?: Maybe<Scalars['MeasurementValue']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
  WidthMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "StandingLapSeamCover" */
export type StandingLapSeamCover_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  NominalWeight?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type StandingLapSeamCover_Min_Fields = {
  __typename?: 'StandingLapSeamCover_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  NominalWeight?: Maybe<Scalars['MeasurementValue']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
  WidthMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "StandingLapSeamCover" */
export type StandingLapSeamCover_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  NominalWeight?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** response of any mutation on the table "StandingLapSeamCover" */
export type StandingLapSeamCover_Mutation_Response = {
  __typename?: 'StandingLapSeamCover_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StandingLapSeamCover>;
};

/** Ordering options when selecting data from "StandingLapSeamCover". */
export type StandingLapSeamCover_Order_By = {
  C_StandingLapSeamMaterial?: Maybe<C_StandingLapSeamMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  NominalWeight?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** primary key columns input for table: StandingLapSeamCover */
export type StandingLapSeamCover_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "StandingLapSeamCover" */
export enum StandingLapSeamCover_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  NominalWeight = 'NominalWeight',
  /** column name */
  StandingLapSeamMaterialId = 'StandingLapSeamMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** input type for updating data in table "StandingLapSeamCover" */
export type StandingLapSeamCover_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  NominalWeight?: Maybe<Scalars['MeasurementValue']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
  WidthMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type StandingLapSeamCover_Stddev_Fields = {
  __typename?: 'StandingLapSeamCover_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "StandingLapSeamCover" */
export type StandingLapSeamCover_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type StandingLapSeamCover_Stddev_Pop_Fields = {
  __typename?: 'StandingLapSeamCover_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "StandingLapSeamCover" */
export type StandingLapSeamCover_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type StandingLapSeamCover_Stddev_Samp_Fields = {
  __typename?: 'StandingLapSeamCover_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "StandingLapSeamCover" */
export type StandingLapSeamCover_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type StandingLapSeamCover_Sum_Fields = {
  __typename?: 'StandingLapSeamCover_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "StandingLapSeamCover" */
export type StandingLapSeamCover_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
};

/** update columns of table "StandingLapSeamCover" */
export enum StandingLapSeamCover_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  NominalWeight = 'NominalWeight',
  /** column name */
  StandingLapSeamMaterialId = 'StandingLapSeamMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** aggregate var_pop on columns */
export type StandingLapSeamCover_Var_Pop_Fields = {
  __typename?: 'StandingLapSeamCover_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "StandingLapSeamCover" */
export type StandingLapSeamCover_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type StandingLapSeamCover_Var_Samp_Fields = {
  __typename?: 'StandingLapSeamCover_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "StandingLapSeamCover" */
export type StandingLapSeamCover_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type StandingLapSeamCover_Variance_Fields = {
  __typename?: 'StandingLapSeamCover_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "StandingLapSeamCover" */
export type StandingLapSeamCover_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "StandingLapSeamUsage" */
export type StandingLapSeamUsage = {
  __typename?: 'StandingLapSeamUsage';
  ThicknessMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
  WeightPerUnitAreaNominal: Scalars['MeasurementValue'];
  WidthMax: Scalars['MeasurementValue'];
};

/** aggregated selection of "StandingLapSeamUsage" */
export type StandingLapSeamUsage_Aggregate = {
  __typename?: 'StandingLapSeamUsage_aggregate';
  aggregate?: Maybe<StandingLapSeamUsage_Aggregate_Fields>;
  nodes: Array<StandingLapSeamUsage>;
};

/** aggregate fields of "StandingLapSeamUsage" */
export type StandingLapSeamUsage_Aggregate_Fields = {
  __typename?: 'StandingLapSeamUsage_aggregate_fields';
  avg?: Maybe<StandingLapSeamUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StandingLapSeamUsage_Max_Fields>;
  min?: Maybe<StandingLapSeamUsage_Min_Fields>;
  stddev?: Maybe<StandingLapSeamUsage_Stddev_Fields>;
  stddev_pop?: Maybe<StandingLapSeamUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StandingLapSeamUsage_Stddev_Samp_Fields>;
  sum?: Maybe<StandingLapSeamUsage_Sum_Fields>;
  var_pop?: Maybe<StandingLapSeamUsage_Var_Pop_Fields>;
  var_samp?: Maybe<StandingLapSeamUsage_Var_Samp_Fields>;
  variance?: Maybe<StandingLapSeamUsage_Variance_Fields>;
};


/** aggregate fields of "StandingLapSeamUsage" */
export type StandingLapSeamUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<StandingLapSeamUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type StandingLapSeamUsage_Avg_Fields = {
  __typename?: 'StandingLapSeamUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "StandingLapSeamUsage". All fields are combined with a logical 'AND'. */
export type StandingLapSeamUsage_Bool_Exp = {
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  WeightPerUnitAreaNominal?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  WidthMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<StandingLapSeamUsage_Bool_Exp>>;
  _not?: Maybe<StandingLapSeamUsage_Bool_Exp>;
  _or?: Maybe<Array<StandingLapSeamUsage_Bool_Exp>>;
};

/** upsert condition type for table "StandingLapSeamUsage" */
export type StandingLapSeamUsage_If_Matched = {
  match_columns?: Array<StandingLapSeamUsage_Insert_Match_Column>;
  update_columns?: Array<StandingLapSeamUsage_Update_Column>;
  where?: Maybe<StandingLapSeamUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "StandingLapSeamUsage" */
export type StandingLapSeamUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "StandingLapSeamUsage" */
export type StandingLapSeamUsage_Insert_Input = {
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['MeasurementValue']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "StandingLapSeamUsage" */
export enum StandingLapSeamUsage_Insert_Match_Column {
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeightPerUnitAreaNominal = 'WeightPerUnitAreaNominal',
  /** column name */
  WidthMax = 'WidthMax'
}

/** aggregate max on columns */
export type StandingLapSeamUsage_Max_Fields = {
  __typename?: 'StandingLapSeamUsage_max_fields';
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['MeasurementValue']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate min on columns */
export type StandingLapSeamUsage_Min_Fields = {
  __typename?: 'StandingLapSeamUsage_min_fields';
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['MeasurementValue']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** response of any mutation on the table "StandingLapSeamUsage" */
export type StandingLapSeamUsage_Mutation_Response = {
  __typename?: 'StandingLapSeamUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StandingLapSeamUsage>;
};

/** Ordering options when selecting data from "StandingLapSeamUsage". */
export type StandingLapSeamUsage_Order_By = {
  ThicknessMin?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
  WeightPerUnitAreaNominal?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** primary key columns input for table: StandingLapSeamUsage */
export type StandingLapSeamUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "StandingLapSeamUsage" */
export enum StandingLapSeamUsage_Select_Column {
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeightPerUnitAreaNominal = 'WeightPerUnitAreaNominal',
  /** column name */
  WidthMax = 'WidthMax'
}

/** input type for updating data in table "StandingLapSeamUsage" */
export type StandingLapSeamUsage_Set_Input = {
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['MeasurementValue']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type StandingLapSeamUsage_Stddev_Fields = {
  __typename?: 'StandingLapSeamUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type StandingLapSeamUsage_Stddev_Pop_Fields = {
  __typename?: 'StandingLapSeamUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type StandingLapSeamUsage_Stddev_Samp_Fields = {
  __typename?: 'StandingLapSeamUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type StandingLapSeamUsage_Sum_Fields = {
  __typename?: 'StandingLapSeamUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "StandingLapSeamUsage" */
export enum StandingLapSeamUsage_Update_Column {
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeightPerUnitAreaNominal = 'WeightPerUnitAreaNominal',
  /** column name */
  WidthMax = 'WidthMax'
}

/** aggregate var_pop on columns */
export type StandingLapSeamUsage_Var_Pop_Fields = {
  __typename?: 'StandingLapSeamUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type StandingLapSeamUsage_Var_Samp_Fields = {
  __typename?: 'StandingLapSeamUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type StandingLapSeamUsage_Variance_Fields = {
  __typename?: 'StandingLapSeamUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "SteelDeck" */
export type SteelDeck = {
  __typename?: 'SteelDeck';
  /** An object relationship */
  C_SteelDeckRibType: C_SteelDeckRibType;
  ComponentId: Scalars['Int'];
  DepthMax: Scalars['MeasurementValue'];
  DepthMin: Scalars['MeasurementValue'];
  DesignThickness: Scalars['MeasurementValue'];
  IsAcoustical: Scalars['Boolean'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  SpanMax: Scalars['MeasurementValue'];
  SteelDeckRibTypeId: Scalars['Int'];
  WidthMax: Scalars['MeasurementValue'];
  WidthMin: Scalars['MeasurementValue'];
};

/** columns and relationships of "SteelDeckLayer" */
export type SteelDeckLayer = {
  __typename?: 'SteelDeckLayer';
  DesignThickness: Scalars['MeasurementValue'];
  EnteredAsMetric: Scalars['MetricEntryFlag'];
  GradeMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Layer: Layer;
  LayerId: Scalars['Int'];
  SpanMax: Scalars['MeasurementValue'];
};

/** aggregated selection of "SteelDeckLayer" */
export type SteelDeckLayer_Aggregate = {
  __typename?: 'SteelDeckLayer_aggregate';
  aggregate?: Maybe<SteelDeckLayer_Aggregate_Fields>;
  nodes: Array<SteelDeckLayer>;
};

/** aggregate fields of "SteelDeckLayer" */
export type SteelDeckLayer_Aggregate_Fields = {
  __typename?: 'SteelDeckLayer_aggregate_fields';
  avg?: Maybe<SteelDeckLayer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SteelDeckLayer_Max_Fields>;
  min?: Maybe<SteelDeckLayer_Min_Fields>;
  stddev?: Maybe<SteelDeckLayer_Stddev_Fields>;
  stddev_pop?: Maybe<SteelDeckLayer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SteelDeckLayer_Stddev_Samp_Fields>;
  sum?: Maybe<SteelDeckLayer_Sum_Fields>;
  var_pop?: Maybe<SteelDeckLayer_Var_Pop_Fields>;
  var_samp?: Maybe<SteelDeckLayer_Var_Samp_Fields>;
  variance?: Maybe<SteelDeckLayer_Variance_Fields>;
};


/** aggregate fields of "SteelDeckLayer" */
export type SteelDeckLayer_Aggregate_FieldsCountArgs = {
  column?: Maybe<SteelDeckLayer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SteelDeckLayer_Avg_Fields = {
  __typename?: 'SteelDeckLayer_avg_fields';
  LayerId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SteelDeckLayer". All fields are combined with a logical 'AND'. */
export type SteelDeckLayer_Bool_Exp = {
  DesignThickness?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  EnteredAsMetric?: Maybe<MetricEntryFlag_Mssql_Comparison_Exp>;
  GradeMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Layer?: Maybe<Layer_Bool_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  SpanMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SteelDeckLayer_Bool_Exp>>;
  _not?: Maybe<SteelDeckLayer_Bool_Exp>;
  _or?: Maybe<Array<SteelDeckLayer_Bool_Exp>>;
};

/** upsert condition type for table "SteelDeckLayer" */
export type SteelDeckLayer_If_Matched = {
  match_columns?: Array<SteelDeckLayer_Insert_Match_Column>;
  update_columns?: Array<SteelDeckLayer_Update_Column>;
  where?: Maybe<SteelDeckLayer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SteelDeckLayer" */
export type SteelDeckLayer_Inc_Input = {
  LayerId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SteelDeckLayer" */
export type SteelDeckLayer_Insert_Input = {
  DesignThickness?: Maybe<Scalars['MeasurementValue']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  GradeMin?: Maybe<Scalars['MeasurementValue']>;
  LayerId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "SteelDeckLayer" */
export enum SteelDeckLayer_Insert_Match_Column {
  /** column name */
  DesignThickness = 'DesignThickness',
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  GradeMin = 'GradeMin',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SpanMax = 'SpanMax'
}

/** aggregate max on columns */
export type SteelDeckLayer_Max_Fields = {
  __typename?: 'SteelDeckLayer_max_fields';
  DesignThickness?: Maybe<Scalars['MeasurementValue']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  GradeMin?: Maybe<Scalars['MeasurementValue']>;
  LayerId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate min on columns */
export type SteelDeckLayer_Min_Fields = {
  __typename?: 'SteelDeckLayer_min_fields';
  DesignThickness?: Maybe<Scalars['MeasurementValue']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  GradeMin?: Maybe<Scalars['MeasurementValue']>;
  LayerId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
};

/** response of any mutation on the table "SteelDeckLayer" */
export type SteelDeckLayer_Mutation_Response = {
  __typename?: 'SteelDeckLayer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SteelDeckLayer>;
};

/** Ordering options when selecting data from "SteelDeckLayer". */
export type SteelDeckLayer_Order_By = {
  DesignThickness?: Maybe<Order_By>;
  EnteredAsMetric?: Maybe<Order_By>;
  GradeMin?: Maybe<Order_By>;
  Layer?: Maybe<Layer_Order_By>;
  LayerId?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
};

/** primary key columns input for table: SteelDeckLayer */
export type SteelDeckLayer_Pk_Columns_Input = {
  LayerId: Scalars['Int'];
};

/** select columns of table "SteelDeckLayer" */
export enum SteelDeckLayer_Select_Column {
  /** column name */
  DesignThickness = 'DesignThickness',
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  GradeMin = 'GradeMin',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SpanMax = 'SpanMax'
}

/** input type for updating data in table "SteelDeckLayer" */
export type SteelDeckLayer_Set_Input = {
  DesignThickness?: Maybe<Scalars['MeasurementValue']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  GradeMin?: Maybe<Scalars['MeasurementValue']>;
  LayerId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type SteelDeckLayer_Stddev_Fields = {
  __typename?: 'SteelDeckLayer_stddev_fields';
  LayerId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SteelDeckLayer_Stddev_Pop_Fields = {
  __typename?: 'SteelDeckLayer_stddev_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SteelDeckLayer_Stddev_Samp_Fields = {
  __typename?: 'SteelDeckLayer_stddev_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SteelDeckLayer_Sum_Fields = {
  __typename?: 'SteelDeckLayer_sum_fields';
  LayerId?: Maybe<Scalars['Int']>;
};

/** update columns of table "SteelDeckLayer" */
export enum SteelDeckLayer_Update_Column {
  /** column name */
  DesignThickness = 'DesignThickness',
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  GradeMin = 'GradeMin',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SpanMax = 'SpanMax'
}

/** aggregate var_pop on columns */
export type SteelDeckLayer_Var_Pop_Fields = {
  __typename?: 'SteelDeckLayer_var_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SteelDeckLayer_Var_Samp_Fields = {
  __typename?: 'SteelDeckLayer_var_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SteelDeckLayer_Variance_Fields = {
  __typename?: 'SteelDeckLayer_variance_fields';
  LayerId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "SteelDeckUsage" */
export type SteelDeckUsage = {
  __typename?: 'SteelDeckUsage';
  DesignThickness: Scalars['MeasurementValue'];
  GradeMin: Scalars['MeasurementValue'];
  SpanMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "SteelDeckUsage" */
export type SteelDeckUsage_Aggregate = {
  __typename?: 'SteelDeckUsage_aggregate';
  aggregate?: Maybe<SteelDeckUsage_Aggregate_Fields>;
  nodes: Array<SteelDeckUsage>;
};

/** aggregate fields of "SteelDeckUsage" */
export type SteelDeckUsage_Aggregate_Fields = {
  __typename?: 'SteelDeckUsage_aggregate_fields';
  avg?: Maybe<SteelDeckUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SteelDeckUsage_Max_Fields>;
  min?: Maybe<SteelDeckUsage_Min_Fields>;
  stddev?: Maybe<SteelDeckUsage_Stddev_Fields>;
  stddev_pop?: Maybe<SteelDeckUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SteelDeckUsage_Stddev_Samp_Fields>;
  sum?: Maybe<SteelDeckUsage_Sum_Fields>;
  var_pop?: Maybe<SteelDeckUsage_Var_Pop_Fields>;
  var_samp?: Maybe<SteelDeckUsage_Var_Samp_Fields>;
  variance?: Maybe<SteelDeckUsage_Variance_Fields>;
};


/** aggregate fields of "SteelDeckUsage" */
export type SteelDeckUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<SteelDeckUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SteelDeckUsage_Avg_Fields = {
  __typename?: 'SteelDeckUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SteelDeckUsage". All fields are combined with a logical 'AND'. */
export type SteelDeckUsage_Bool_Exp = {
  DesignThickness?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  GradeMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  SpanMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SteelDeckUsage_Bool_Exp>>;
  _not?: Maybe<SteelDeckUsage_Bool_Exp>;
  _or?: Maybe<Array<SteelDeckUsage_Bool_Exp>>;
};

/** upsert condition type for table "SteelDeckUsage" */
export type SteelDeckUsage_If_Matched = {
  match_columns?: Array<SteelDeckUsage_Insert_Match_Column>;
  update_columns?: Array<SteelDeckUsage_Update_Column>;
  where?: Maybe<SteelDeckUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SteelDeckUsage" */
export type SteelDeckUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SteelDeckUsage" */
export type SteelDeckUsage_Insert_Input = {
  DesignThickness?: Maybe<Scalars['MeasurementValue']>;
  GradeMin?: Maybe<Scalars['MeasurementValue']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SteelDeckUsage" */
export enum SteelDeckUsage_Insert_Match_Column {
  /** column name */
  DesignThickness = 'DesignThickness',
  /** column name */
  GradeMin = 'GradeMin',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type SteelDeckUsage_Max_Fields = {
  __typename?: 'SteelDeckUsage_max_fields';
  DesignThickness?: Maybe<Scalars['MeasurementValue']>;
  GradeMin?: Maybe<Scalars['MeasurementValue']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SteelDeckUsage_Min_Fields = {
  __typename?: 'SteelDeckUsage_min_fields';
  DesignThickness?: Maybe<Scalars['MeasurementValue']>;
  GradeMin?: Maybe<Scalars['MeasurementValue']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "SteelDeckUsage" */
export type SteelDeckUsage_Mutation_Response = {
  __typename?: 'SteelDeckUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SteelDeckUsage>;
};

/** Ordering options when selecting data from "SteelDeckUsage". */
export type SteelDeckUsage_Order_By = {
  DesignThickness?: Maybe<Order_By>;
  GradeMin?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: SteelDeckUsage */
export type SteelDeckUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "SteelDeckUsage" */
export enum SteelDeckUsage_Select_Column {
  /** column name */
  DesignThickness = 'DesignThickness',
  /** column name */
  GradeMin = 'GradeMin',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "SteelDeckUsage" */
export type SteelDeckUsage_Set_Input = {
  DesignThickness?: Maybe<Scalars['MeasurementValue']>;
  GradeMin?: Maybe<Scalars['MeasurementValue']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SteelDeckUsage_Stddev_Fields = {
  __typename?: 'SteelDeckUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SteelDeckUsage_Stddev_Pop_Fields = {
  __typename?: 'SteelDeckUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SteelDeckUsage_Stddev_Samp_Fields = {
  __typename?: 'SteelDeckUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SteelDeckUsage_Sum_Fields = {
  __typename?: 'SteelDeckUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "SteelDeckUsage" */
export enum SteelDeckUsage_Update_Column {
  /** column name */
  DesignThickness = 'DesignThickness',
  /** column name */
  GradeMin = 'GradeMin',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type SteelDeckUsage_Var_Pop_Fields = {
  __typename?: 'SteelDeckUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SteelDeckUsage_Var_Samp_Fields = {
  __typename?: 'SteelDeckUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SteelDeckUsage_Variance_Fields = {
  __typename?: 'SteelDeckUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "SteelDeck" */
export type SteelDeck_Aggregate = {
  __typename?: 'SteelDeck_aggregate';
  aggregate?: Maybe<SteelDeck_Aggregate_Fields>;
  nodes: Array<SteelDeck>;
};

/** aggregate fields of "SteelDeck" */
export type SteelDeck_Aggregate_Fields = {
  __typename?: 'SteelDeck_aggregate_fields';
  avg?: Maybe<SteelDeck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SteelDeck_Max_Fields>;
  min?: Maybe<SteelDeck_Min_Fields>;
  stddev?: Maybe<SteelDeck_Stddev_Fields>;
  stddev_pop?: Maybe<SteelDeck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SteelDeck_Stddev_Samp_Fields>;
  sum?: Maybe<SteelDeck_Sum_Fields>;
  var_pop?: Maybe<SteelDeck_Var_Pop_Fields>;
  var_samp?: Maybe<SteelDeck_Var_Samp_Fields>;
  variance?: Maybe<SteelDeck_Variance_Fields>;
};


/** aggregate fields of "SteelDeck" */
export type SteelDeck_Aggregate_FieldsCountArgs = {
  column?: Maybe<SteelDeck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SteelDeck" */
export type SteelDeck_Aggregate_Order_By = {
  avg?: Maybe<SteelDeck_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SteelDeck_Max_Order_By>;
  min?: Maybe<SteelDeck_Min_Order_By>;
  stddev?: Maybe<SteelDeck_Stddev_Order_By>;
  stddev_pop?: Maybe<SteelDeck_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SteelDeck_Stddev_Samp_Order_By>;
  sum?: Maybe<SteelDeck_Sum_Order_By>;
  var_pop?: Maybe<SteelDeck_Var_Pop_Order_By>;
  var_samp?: Maybe<SteelDeck_Var_Samp_Order_By>;
  variance?: Maybe<SteelDeck_Variance_Order_By>;
};

/** aggregate avg on columns */
export type SteelDeck_Avg_Fields = {
  __typename?: 'SteelDeck_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SteelDeck" */
export type SteelDeck_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SteelDeck". All fields are combined with a logical 'AND'. */
export type SteelDeck_Bool_Exp = {
  C_SteelDeckRibType?: Maybe<C_SteelDeckRibType_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  DepthMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  DepthMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  DesignThickness?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  IsAcoustical?: Maybe<Boolean_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  SpanMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  SteelDeckRibTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  WidthMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  WidthMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SteelDeck_Bool_Exp>>;
  _not?: Maybe<SteelDeck_Bool_Exp>;
  _or?: Maybe<Array<SteelDeck_Bool_Exp>>;
};

/** upsert condition type for table "SteelDeck" */
export type SteelDeck_If_Matched = {
  match_columns?: Array<SteelDeck_Insert_Match_Column>;
  update_columns?: Array<SteelDeck_Update_Column>;
  where?: Maybe<SteelDeck_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SteelDeck" */
export type SteelDeck_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SteelDeck" */
export type SteelDeck_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DepthMax?: Maybe<Scalars['MeasurementValue']>;
  DepthMin?: Maybe<Scalars['MeasurementValue']>;
  DesignThickness?: Maybe<Scalars['MeasurementValue']>;
  IsAcoustical?: Maybe<Scalars['Boolean']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
  WidthMin?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "SteelDeck" */
export enum SteelDeck_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DepthMax = 'DepthMax',
  /** column name */
  DepthMin = 'DepthMin',
  /** column name */
  DesignThickness = 'DesignThickness',
  /** column name */
  IsAcoustical = 'IsAcoustical',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  SteelDeckRibTypeId = 'SteelDeckRibTypeId',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** aggregate max on columns */
export type SteelDeck_Max_Fields = {
  __typename?: 'SteelDeck_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DepthMax?: Maybe<Scalars['MeasurementValue']>;
  DepthMin?: Maybe<Scalars['MeasurementValue']>;
  DesignThickness?: Maybe<Scalars['MeasurementValue']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
  WidthMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "SteelDeck" */
export type SteelDeck_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DepthMax?: Maybe<Order_By>;
  DepthMin?: Maybe<Order_By>;
  DesignThickness?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SteelDeck_Min_Fields = {
  __typename?: 'SteelDeck_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DepthMax?: Maybe<Scalars['MeasurementValue']>;
  DepthMin?: Maybe<Scalars['MeasurementValue']>;
  DesignThickness?: Maybe<Scalars['MeasurementValue']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
  WidthMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "SteelDeck" */
export type SteelDeck_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DepthMax?: Maybe<Order_By>;
  DepthMin?: Maybe<Order_By>;
  DesignThickness?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** response of any mutation on the table "SteelDeck" */
export type SteelDeck_Mutation_Response = {
  __typename?: 'SteelDeck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SteelDeck>;
};

/** Ordering options when selecting data from "SteelDeck". */
export type SteelDeck_Order_By = {
  C_SteelDeckRibType?: Maybe<C_SteelDeckRibType_Order_By>;
  ComponentId?: Maybe<Order_By>;
  DepthMax?: Maybe<Order_By>;
  DepthMin?: Maybe<Order_By>;
  DesignThickness?: Maybe<Order_By>;
  IsAcoustical?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  SpanMax?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** primary key columns input for table: SteelDeck */
export type SteelDeck_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "SteelDeck" */
export enum SteelDeck_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DepthMax = 'DepthMax',
  /** column name */
  DepthMin = 'DepthMin',
  /** column name */
  DesignThickness = 'DesignThickness',
  /** column name */
  IsAcoustical = 'IsAcoustical',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  SteelDeckRibTypeId = 'SteelDeckRibTypeId',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** input type for updating data in table "SteelDeck" */
export type SteelDeck_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DepthMax?: Maybe<Scalars['MeasurementValue']>;
  DepthMin?: Maybe<Scalars['MeasurementValue']>;
  DesignThickness?: Maybe<Scalars['MeasurementValue']>;
  IsAcoustical?: Maybe<Scalars['Boolean']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['MeasurementValue']>;
  WidthMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type SteelDeck_Stddev_Fields = {
  __typename?: 'SteelDeck_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SteelDeck" */
export type SteelDeck_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SteelDeck_Stddev_Pop_Fields = {
  __typename?: 'SteelDeck_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SteelDeck" */
export type SteelDeck_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SteelDeck_Stddev_Samp_Fields = {
  __typename?: 'SteelDeck_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SteelDeck" */
export type SteelDeck_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type SteelDeck_Sum_Fields = {
  __typename?: 'SteelDeck_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "SteelDeck" */
export type SteelDeck_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
};

/** update columns of table "SteelDeck" */
export enum SteelDeck_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DepthMax = 'DepthMax',
  /** column name */
  DepthMin = 'DepthMin',
  /** column name */
  DesignThickness = 'DesignThickness',
  /** column name */
  IsAcoustical = 'IsAcoustical',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  SteelDeckRibTypeId = 'SteelDeckRibTypeId',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** aggregate var_pop on columns */
export type SteelDeck_Var_Pop_Fields = {
  __typename?: 'SteelDeck_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SteelDeck" */
export type SteelDeck_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SteelDeck_Var_Samp_Fields = {
  __typename?: 'SteelDeck_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SteelDeck" */
export type SteelDeck_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SteelDeck_Variance_Fields = {
  __typename?: 'SteelDeck_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SteelDeck" */
export type SteelDeck_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
};

/** columns and relationships of "StressPlate" */
export type StressPlate = {
  __typename?: 'StressPlate';
  /** An object relationship */
  C_SecurementMaterial: C_SecurementMaterial;
  /** An object relationship */
  C_StressPlateShape: C_StressPlateShape;
  ComponentId: Scalars['Int'];
  Diameter: Scalars['MeasurementValue'];
  Length: Scalars['MeasurementValue'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  SecurementMaterialId: Scalars['Int'];
  StressPlateShapeId: Scalars['Int'];
  Thickness: Scalars['MeasurementValue'];
  Width: Scalars['MeasurementValue'];
};

/** columns and relationships of "StressPlateUsage" */
export type StressPlateUsage = {
  __typename?: 'StressPlateUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "StressPlateUsage" */
export type StressPlateUsage_Aggregate = {
  __typename?: 'StressPlateUsage_aggregate';
  aggregate?: Maybe<StressPlateUsage_Aggregate_Fields>;
  nodes: Array<StressPlateUsage>;
};

/** aggregate fields of "StressPlateUsage" */
export type StressPlateUsage_Aggregate_Fields = {
  __typename?: 'StressPlateUsage_aggregate_fields';
  avg?: Maybe<StressPlateUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StressPlateUsage_Max_Fields>;
  min?: Maybe<StressPlateUsage_Min_Fields>;
  stddev?: Maybe<StressPlateUsage_Stddev_Fields>;
  stddev_pop?: Maybe<StressPlateUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StressPlateUsage_Stddev_Samp_Fields>;
  sum?: Maybe<StressPlateUsage_Sum_Fields>;
  var_pop?: Maybe<StressPlateUsage_Var_Pop_Fields>;
  var_samp?: Maybe<StressPlateUsage_Var_Samp_Fields>;
  variance?: Maybe<StressPlateUsage_Variance_Fields>;
};


/** aggregate fields of "StressPlateUsage" */
export type StressPlateUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<StressPlateUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type StressPlateUsage_Avg_Fields = {
  __typename?: 'StressPlateUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "StressPlateUsage". All fields are combined with a logical 'AND'. */
export type StressPlateUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<StressPlateUsage_Bool_Exp>>;
  _not?: Maybe<StressPlateUsage_Bool_Exp>;
  _or?: Maybe<Array<StressPlateUsage_Bool_Exp>>;
};

/** upsert condition type for table "StressPlateUsage" */
export type StressPlateUsage_If_Matched = {
  match_columns?: Array<StressPlateUsage_Insert_Match_Column>;
  update_columns?: Array<StressPlateUsage_Update_Column>;
  where?: Maybe<StressPlateUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "StressPlateUsage" */
export type StressPlateUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "StressPlateUsage" */
export type StressPlateUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "StressPlateUsage" */
export enum StressPlateUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type StressPlateUsage_Max_Fields = {
  __typename?: 'StressPlateUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type StressPlateUsage_Min_Fields = {
  __typename?: 'StressPlateUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "StressPlateUsage" */
export type StressPlateUsage_Mutation_Response = {
  __typename?: 'StressPlateUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StressPlateUsage>;
};

/** Ordering options when selecting data from "StressPlateUsage". */
export type StressPlateUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: StressPlateUsage */
export type StressPlateUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "StressPlateUsage" */
export enum StressPlateUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "StressPlateUsage" */
export type StressPlateUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type StressPlateUsage_Stddev_Fields = {
  __typename?: 'StressPlateUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type StressPlateUsage_Stddev_Pop_Fields = {
  __typename?: 'StressPlateUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type StressPlateUsage_Stddev_Samp_Fields = {
  __typename?: 'StressPlateUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type StressPlateUsage_Sum_Fields = {
  __typename?: 'StressPlateUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "StressPlateUsage" */
export enum StressPlateUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type StressPlateUsage_Var_Pop_Fields = {
  __typename?: 'StressPlateUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type StressPlateUsage_Var_Samp_Fields = {
  __typename?: 'StressPlateUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type StressPlateUsage_Variance_Fields = {
  __typename?: 'StressPlateUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "StressPlate" */
export type StressPlate_Aggregate = {
  __typename?: 'StressPlate_aggregate';
  aggregate?: Maybe<StressPlate_Aggregate_Fields>;
  nodes: Array<StressPlate>;
};

/** aggregate fields of "StressPlate" */
export type StressPlate_Aggregate_Fields = {
  __typename?: 'StressPlate_aggregate_fields';
  avg?: Maybe<StressPlate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StressPlate_Max_Fields>;
  min?: Maybe<StressPlate_Min_Fields>;
  stddev?: Maybe<StressPlate_Stddev_Fields>;
  stddev_pop?: Maybe<StressPlate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StressPlate_Stddev_Samp_Fields>;
  sum?: Maybe<StressPlate_Sum_Fields>;
  var_pop?: Maybe<StressPlate_Var_Pop_Fields>;
  var_samp?: Maybe<StressPlate_Var_Samp_Fields>;
  variance?: Maybe<StressPlate_Variance_Fields>;
};


/** aggregate fields of "StressPlate" */
export type StressPlate_Aggregate_FieldsCountArgs = {
  column?: Maybe<StressPlate_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "StressPlate" */
export type StressPlate_Aggregate_Order_By = {
  avg?: Maybe<StressPlate_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<StressPlate_Max_Order_By>;
  min?: Maybe<StressPlate_Min_Order_By>;
  stddev?: Maybe<StressPlate_Stddev_Order_By>;
  stddev_pop?: Maybe<StressPlate_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<StressPlate_Stddev_Samp_Order_By>;
  sum?: Maybe<StressPlate_Sum_Order_By>;
  var_pop?: Maybe<StressPlate_Var_Pop_Order_By>;
  var_samp?: Maybe<StressPlate_Var_Samp_Order_By>;
  variance?: Maybe<StressPlate_Variance_Order_By>;
};

/** aggregate avg on columns */
export type StressPlate_Avg_Fields = {
  __typename?: 'StressPlate_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "StressPlate" */
export type StressPlate_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "StressPlate". All fields are combined with a logical 'AND'. */
export type StressPlate_Bool_Exp = {
  C_SecurementMaterial?: Maybe<C_SecurementMaterial_Bool_Exp>;
  C_StressPlateShape?: Maybe<C_StressPlateShape_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  Diameter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Length?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  SecurementMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  StressPlateShapeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Thickness?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Width?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<StressPlate_Bool_Exp>>;
  _not?: Maybe<StressPlate_Bool_Exp>;
  _or?: Maybe<Array<StressPlate_Bool_Exp>>;
};

/** upsert condition type for table "StressPlate" */
export type StressPlate_If_Matched = {
  match_columns?: Array<StressPlate_Insert_Match_Column>;
  update_columns?: Array<StressPlate_Update_Column>;
  where?: Maybe<StressPlate_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "StressPlate" */
export type StressPlate_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
  StressPlateShapeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "StressPlate" */
export type StressPlate_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
  StressPlateShapeId?: Maybe<Scalars['Int']>;
  Thickness?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "StressPlate" */
export enum StressPlate_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  Diameter = 'Diameter',
  /** column name */
  Length = 'Length',
  /** column name */
  SecurementMaterialId = 'SecurementMaterialId',
  /** column name */
  StressPlateShapeId = 'StressPlateShapeId',
  /** column name */
  Thickness = 'Thickness',
  /** column name */
  Width = 'Width'
}

/** aggregate max on columns */
export type StressPlate_Max_Fields = {
  __typename?: 'StressPlate_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
  StressPlateShapeId?: Maybe<Scalars['Int']>;
  Thickness?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "StressPlate" */
export type StressPlate_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Diameter?: Maybe<Order_By>;
  Length?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
  Thickness?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type StressPlate_Min_Fields = {
  __typename?: 'StressPlate_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
  StressPlateShapeId?: Maybe<Scalars['Int']>;
  Thickness?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "StressPlate" */
export type StressPlate_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Diameter?: Maybe<Order_By>;
  Length?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
  Thickness?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** response of any mutation on the table "StressPlate" */
export type StressPlate_Mutation_Response = {
  __typename?: 'StressPlate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StressPlate>;
};

/** Ordering options when selecting data from "StressPlate". */
export type StressPlate_Order_By = {
  C_SecurementMaterial?: Maybe<C_SecurementMaterial_Order_By>;
  C_StressPlateShape?: Maybe<C_StressPlateShape_Order_By>;
  ComponentId?: Maybe<Order_By>;
  Diameter?: Maybe<Order_By>;
  Length?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
  Thickness?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** primary key columns input for table: StressPlate */
export type StressPlate_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "StressPlate" */
export enum StressPlate_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  Diameter = 'Diameter',
  /** column name */
  Length = 'Length',
  /** column name */
  SecurementMaterialId = 'SecurementMaterialId',
  /** column name */
  StressPlateShapeId = 'StressPlateShapeId',
  /** column name */
  Thickness = 'Thickness',
  /** column name */
  Width = 'Width'
}

/** input type for updating data in table "StressPlate" */
export type StressPlate_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  Length?: Maybe<Scalars['MeasurementValue']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
  StressPlateShapeId?: Maybe<Scalars['Int']>;
  Thickness?: Maybe<Scalars['MeasurementValue']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type StressPlate_Stddev_Fields = {
  __typename?: 'StressPlate_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "StressPlate" */
export type StressPlate_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type StressPlate_Stddev_Pop_Fields = {
  __typename?: 'StressPlate_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "StressPlate" */
export type StressPlate_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type StressPlate_Stddev_Samp_Fields = {
  __typename?: 'StressPlate_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "StressPlate" */
export type StressPlate_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type StressPlate_Sum_Fields = {
  __typename?: 'StressPlate_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SecurementMaterialId?: Maybe<Scalars['Int']>;
  StressPlateShapeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "StressPlate" */
export type StressPlate_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** update columns of table "StressPlate" */
export enum StressPlate_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  Diameter = 'Diameter',
  /** column name */
  Length = 'Length',
  /** column name */
  SecurementMaterialId = 'SecurementMaterialId',
  /** column name */
  StressPlateShapeId = 'StressPlateShapeId',
  /** column name */
  Thickness = 'Thickness',
  /** column name */
  Width = 'Width'
}

/** aggregate var_pop on columns */
export type StressPlate_Var_Pop_Fields = {
  __typename?: 'StressPlate_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "StressPlate" */
export type StressPlate_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type StressPlate_Var_Samp_Fields = {
  __typename?: 'StressPlate_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "StressPlate" */
export type StressPlate_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type StressPlate_Variance_Fields = {
  __typename?: 'StressPlate_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SecurementMaterialId?: Maybe<Scalars['Float']>;
  StressPlateShapeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "StressPlate" */
export type StressPlate_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SecurementMaterialId?: Maybe<Order_By>;
  StressPlateShapeId?: Maybe<Order_By>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Mssql_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
};

/** columns and relationships of "StructuralConcreteLayer" */
export type StructuralConcreteLayer = {
  __typename?: 'StructuralConcreteLayer';
  /** An object relationship */
  Layer: Layer;
  LayerId: Scalars['Int'];
};

/** aggregated selection of "StructuralConcreteLayer" */
export type StructuralConcreteLayer_Aggregate = {
  __typename?: 'StructuralConcreteLayer_aggregate';
  aggregate?: Maybe<StructuralConcreteLayer_Aggregate_Fields>;
  nodes: Array<StructuralConcreteLayer>;
};

/** aggregate fields of "StructuralConcreteLayer" */
export type StructuralConcreteLayer_Aggregate_Fields = {
  __typename?: 'StructuralConcreteLayer_aggregate_fields';
  avg?: Maybe<StructuralConcreteLayer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StructuralConcreteLayer_Max_Fields>;
  min?: Maybe<StructuralConcreteLayer_Min_Fields>;
  stddev?: Maybe<StructuralConcreteLayer_Stddev_Fields>;
  stddev_pop?: Maybe<StructuralConcreteLayer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StructuralConcreteLayer_Stddev_Samp_Fields>;
  sum?: Maybe<StructuralConcreteLayer_Sum_Fields>;
  var_pop?: Maybe<StructuralConcreteLayer_Var_Pop_Fields>;
  var_samp?: Maybe<StructuralConcreteLayer_Var_Samp_Fields>;
  variance?: Maybe<StructuralConcreteLayer_Variance_Fields>;
};


/** aggregate fields of "StructuralConcreteLayer" */
export type StructuralConcreteLayer_Aggregate_FieldsCountArgs = {
  column?: Maybe<StructuralConcreteLayer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type StructuralConcreteLayer_Avg_Fields = {
  __typename?: 'StructuralConcreteLayer_avg_fields';
  LayerId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "StructuralConcreteLayer". All fields are combined with a logical 'AND'. */
export type StructuralConcreteLayer_Bool_Exp = {
  Layer?: Maybe<Layer_Bool_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<StructuralConcreteLayer_Bool_Exp>>;
  _not?: Maybe<StructuralConcreteLayer_Bool_Exp>;
  _or?: Maybe<Array<StructuralConcreteLayer_Bool_Exp>>;
};

/** upsert condition type for table "StructuralConcreteLayer" */
export type StructuralConcreteLayer_If_Matched = {
  match_columns?: Array<StructuralConcreteLayer_Insert_Match_Column>;
  update_columns?: Array<StructuralConcreteLayer_Update_Column>;
  where?: Maybe<StructuralConcreteLayer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "StructuralConcreteLayer" */
export type StructuralConcreteLayer_Inc_Input = {
  LayerId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "StructuralConcreteLayer" */
export type StructuralConcreteLayer_Insert_Input = {
  LayerId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "StructuralConcreteLayer" */
export enum StructuralConcreteLayer_Insert_Match_Column {
  /** column name */
  LayerId = 'LayerId'
}

/** aggregate max on columns */
export type StructuralConcreteLayer_Max_Fields = {
  __typename?: 'StructuralConcreteLayer_max_fields';
  LayerId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type StructuralConcreteLayer_Min_Fields = {
  __typename?: 'StructuralConcreteLayer_min_fields';
  LayerId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "StructuralConcreteLayer" */
export type StructuralConcreteLayer_Mutation_Response = {
  __typename?: 'StructuralConcreteLayer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StructuralConcreteLayer>;
};

/** Ordering options when selecting data from "StructuralConcreteLayer". */
export type StructuralConcreteLayer_Order_By = {
  Layer?: Maybe<Layer_Order_By>;
  LayerId?: Maybe<Order_By>;
};

/** primary key columns input for table: StructuralConcreteLayer */
export type StructuralConcreteLayer_Pk_Columns_Input = {
  LayerId: Scalars['Int'];
};

/** select columns of table "StructuralConcreteLayer" */
export enum StructuralConcreteLayer_Select_Column {
  /** column name */
  LayerId = 'LayerId'
}

/** input type for updating data in table "StructuralConcreteLayer" */
export type StructuralConcreteLayer_Set_Input = {
  LayerId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type StructuralConcreteLayer_Stddev_Fields = {
  __typename?: 'StructuralConcreteLayer_stddev_fields';
  LayerId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type StructuralConcreteLayer_Stddev_Pop_Fields = {
  __typename?: 'StructuralConcreteLayer_stddev_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type StructuralConcreteLayer_Stddev_Samp_Fields = {
  __typename?: 'StructuralConcreteLayer_stddev_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type StructuralConcreteLayer_Sum_Fields = {
  __typename?: 'StructuralConcreteLayer_sum_fields';
  LayerId?: Maybe<Scalars['Int']>;
};

/** update columns of table "StructuralConcreteLayer" */
export enum StructuralConcreteLayer_Update_Column {
  /** column name */
  LayerId = 'LayerId'
}

/** aggregate var_pop on columns */
export type StructuralConcreteLayer_Var_Pop_Fields = {
  __typename?: 'StructuralConcreteLayer_var_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type StructuralConcreteLayer_Var_Samp_Fields = {
  __typename?: 'StructuralConcreteLayer_var_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type StructuralConcreteLayer_Variance_Fields = {
  __typename?: 'StructuralConcreteLayer_variance_fields';
  LayerId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "StructureLayer" */
export type StructureLayer = {
  __typename?: 'StructureLayer';
  /** An object relationship */
  C_StructureType: C_StructureType;
  EnteredAsMetric: Scalars['MetricEntryFlag'];
  /** An object relationship */
  Layer: Layer;
  LayerId: Scalars['Int'];
  SpacingMax: Scalars['MeasurementValue'];
  StrengthMin: Scalars['MeasurementValue'];
  StructureTypeId: Scalars['Int'];
  Thickness: Scalars['MeasurementValue'];
};

/** aggregated selection of "StructureLayer" */
export type StructureLayer_Aggregate = {
  __typename?: 'StructureLayer_aggregate';
  aggregate?: Maybe<StructureLayer_Aggregate_Fields>;
  nodes: Array<StructureLayer>;
};

/** aggregate fields of "StructureLayer" */
export type StructureLayer_Aggregate_Fields = {
  __typename?: 'StructureLayer_aggregate_fields';
  avg?: Maybe<StructureLayer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StructureLayer_Max_Fields>;
  min?: Maybe<StructureLayer_Min_Fields>;
  stddev?: Maybe<StructureLayer_Stddev_Fields>;
  stddev_pop?: Maybe<StructureLayer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StructureLayer_Stddev_Samp_Fields>;
  sum?: Maybe<StructureLayer_Sum_Fields>;
  var_pop?: Maybe<StructureLayer_Var_Pop_Fields>;
  var_samp?: Maybe<StructureLayer_Var_Samp_Fields>;
  variance?: Maybe<StructureLayer_Variance_Fields>;
};


/** aggregate fields of "StructureLayer" */
export type StructureLayer_Aggregate_FieldsCountArgs = {
  column?: Maybe<StructureLayer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "StructureLayer" */
export type StructureLayer_Aggregate_Order_By = {
  avg?: Maybe<StructureLayer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<StructureLayer_Max_Order_By>;
  min?: Maybe<StructureLayer_Min_Order_By>;
  stddev?: Maybe<StructureLayer_Stddev_Order_By>;
  stddev_pop?: Maybe<StructureLayer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<StructureLayer_Stddev_Samp_Order_By>;
  sum?: Maybe<StructureLayer_Sum_Order_By>;
  var_pop?: Maybe<StructureLayer_Var_Pop_Order_By>;
  var_samp?: Maybe<StructureLayer_Var_Samp_Order_By>;
  variance?: Maybe<StructureLayer_Variance_Order_By>;
};

/** aggregate avg on columns */
export type StructureLayer_Avg_Fields = {
  __typename?: 'StructureLayer_avg_fields';
  LayerId?: Maybe<Scalars['Float']>;
  StructureTypeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "StructureLayer" */
export type StructureLayer_Avg_Order_By = {
  LayerId?: Maybe<Order_By>;
  StructureTypeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "StructureLayer". All fields are combined with a logical 'AND'. */
export type StructureLayer_Bool_Exp = {
  C_StructureType?: Maybe<C_StructureType_Bool_Exp>;
  EnteredAsMetric?: Maybe<MetricEntryFlag_Mssql_Comparison_Exp>;
  Layer?: Maybe<Layer_Bool_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  SpacingMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  StrengthMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  StructureTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  Thickness?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<StructureLayer_Bool_Exp>>;
  _not?: Maybe<StructureLayer_Bool_Exp>;
  _or?: Maybe<Array<StructureLayer_Bool_Exp>>;
};

/** upsert condition type for table "StructureLayer" */
export type StructureLayer_If_Matched = {
  match_columns?: Array<StructureLayer_Insert_Match_Column>;
  update_columns?: Array<StructureLayer_Update_Column>;
  where?: Maybe<StructureLayer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "StructureLayer" */
export type StructureLayer_Inc_Input = {
  LayerId?: Maybe<Scalars['Int']>;
  StructureTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "StructureLayer" */
export type StructureLayer_Insert_Input = {
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  LayerId?: Maybe<Scalars['Int']>;
  SpacingMax?: Maybe<Scalars['MeasurementValue']>;
  StrengthMin?: Maybe<Scalars['MeasurementValue']>;
  StructureTypeId?: Maybe<Scalars['Int']>;
  Thickness?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "StructureLayer" */
export enum StructureLayer_Insert_Match_Column {
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SpacingMax = 'SpacingMax',
  /** column name */
  StrengthMin = 'StrengthMin',
  /** column name */
  StructureTypeId = 'StructureTypeId',
  /** column name */
  Thickness = 'Thickness'
}

/** aggregate max on columns */
export type StructureLayer_Max_Fields = {
  __typename?: 'StructureLayer_max_fields';
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  LayerId?: Maybe<Scalars['Int']>;
  SpacingMax?: Maybe<Scalars['MeasurementValue']>;
  StrengthMin?: Maybe<Scalars['MeasurementValue']>;
  StructureTypeId?: Maybe<Scalars['Int']>;
  Thickness?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "StructureLayer" */
export type StructureLayer_Max_Order_By = {
  EnteredAsMetric?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  SpacingMax?: Maybe<Order_By>;
  StrengthMin?: Maybe<Order_By>;
  StructureTypeId?: Maybe<Order_By>;
  Thickness?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type StructureLayer_Min_Fields = {
  __typename?: 'StructureLayer_min_fields';
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  LayerId?: Maybe<Scalars['Int']>;
  SpacingMax?: Maybe<Scalars['MeasurementValue']>;
  StrengthMin?: Maybe<Scalars['MeasurementValue']>;
  StructureTypeId?: Maybe<Scalars['Int']>;
  Thickness?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "StructureLayer" */
export type StructureLayer_Min_Order_By = {
  EnteredAsMetric?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  SpacingMax?: Maybe<Order_By>;
  StrengthMin?: Maybe<Order_By>;
  StructureTypeId?: Maybe<Order_By>;
  Thickness?: Maybe<Order_By>;
};

/** response of any mutation on the table "StructureLayer" */
export type StructureLayer_Mutation_Response = {
  __typename?: 'StructureLayer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StructureLayer>;
};

/** Ordering options when selecting data from "StructureLayer". */
export type StructureLayer_Order_By = {
  C_StructureType?: Maybe<C_StructureType_Order_By>;
  EnteredAsMetric?: Maybe<Order_By>;
  Layer?: Maybe<Layer_Order_By>;
  LayerId?: Maybe<Order_By>;
  SpacingMax?: Maybe<Order_By>;
  StrengthMin?: Maybe<Order_By>;
  StructureTypeId?: Maybe<Order_By>;
  Thickness?: Maybe<Order_By>;
};

/** primary key columns input for table: StructureLayer */
export type StructureLayer_Pk_Columns_Input = {
  LayerId: Scalars['Int'];
};

/** select columns of table "StructureLayer" */
export enum StructureLayer_Select_Column {
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SpacingMax = 'SpacingMax',
  /** column name */
  StrengthMin = 'StrengthMin',
  /** column name */
  StructureTypeId = 'StructureTypeId',
  /** column name */
  Thickness = 'Thickness'
}

/** input type for updating data in table "StructureLayer" */
export type StructureLayer_Set_Input = {
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  LayerId?: Maybe<Scalars['Int']>;
  SpacingMax?: Maybe<Scalars['MeasurementValue']>;
  StrengthMin?: Maybe<Scalars['MeasurementValue']>;
  StructureTypeId?: Maybe<Scalars['Int']>;
  Thickness?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type StructureLayer_Stddev_Fields = {
  __typename?: 'StructureLayer_stddev_fields';
  LayerId?: Maybe<Scalars['Float']>;
  StructureTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "StructureLayer" */
export type StructureLayer_Stddev_Order_By = {
  LayerId?: Maybe<Order_By>;
  StructureTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type StructureLayer_Stddev_Pop_Fields = {
  __typename?: 'StructureLayer_stddev_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
  StructureTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "StructureLayer" */
export type StructureLayer_Stddev_Pop_Order_By = {
  LayerId?: Maybe<Order_By>;
  StructureTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type StructureLayer_Stddev_Samp_Fields = {
  __typename?: 'StructureLayer_stddev_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
  StructureTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "StructureLayer" */
export type StructureLayer_Stddev_Samp_Order_By = {
  LayerId?: Maybe<Order_By>;
  StructureTypeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type StructureLayer_Sum_Fields = {
  __typename?: 'StructureLayer_sum_fields';
  LayerId?: Maybe<Scalars['Int']>;
  StructureTypeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "StructureLayer" */
export type StructureLayer_Sum_Order_By = {
  LayerId?: Maybe<Order_By>;
  StructureTypeId?: Maybe<Order_By>;
};

/** update columns of table "StructureLayer" */
export enum StructureLayer_Update_Column {
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SpacingMax = 'SpacingMax',
  /** column name */
  StrengthMin = 'StrengthMin',
  /** column name */
  StructureTypeId = 'StructureTypeId',
  /** column name */
  Thickness = 'Thickness'
}

/** aggregate var_pop on columns */
export type StructureLayer_Var_Pop_Fields = {
  __typename?: 'StructureLayer_var_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
  StructureTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "StructureLayer" */
export type StructureLayer_Var_Pop_Order_By = {
  LayerId?: Maybe<Order_By>;
  StructureTypeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type StructureLayer_Var_Samp_Fields = {
  __typename?: 'StructureLayer_var_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
  StructureTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "StructureLayer" */
export type StructureLayer_Var_Samp_Order_By = {
  LayerId?: Maybe<Order_By>;
  StructureTypeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type StructureLayer_Variance_Fields = {
  __typename?: 'StructureLayer_variance_fields';
  LayerId?: Maybe<Scalars['Float']>;
  StructureTypeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "StructureLayer" */
export type StructureLayer_Variance_Order_By = {
  LayerId?: Maybe<Order_By>;
  StructureTypeId?: Maybe<Order_By>;
};

/** columns and relationships of "StructuredSystem" */
export type StructuredSystem = {
  __typename?: 'StructuredSystem';
  /** An object relationship */
  Component: Component;
  ComponentId: Scalars['Int'];
  /** An array relationship */
  StructuredSystemProducts: Array<StructuredSystemProducts>;
  /** An aggregate relationship */
  StructuredSystemProducts_aggregate: StructuredSystemProducts_Aggregate;
  SystemName: Scalars['String'];
};


/** columns and relationships of "StructuredSystem" */
export type StructuredSystemStructuredSystemProductsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuredSystemProducts_Order_By>>;
  where?: Maybe<StructuredSystemProducts_Bool_Exp>;
};


/** columns and relationships of "StructuredSystem" */
export type StructuredSystemStructuredSystemProducts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuredSystemProducts_Order_By>>;
  where?: Maybe<StructuredSystemProducts_Bool_Exp>;
};

/** columns and relationships of "StructuredSystemProducts" */
export type StructuredSystemProducts = {
  __typename?: 'StructuredSystemProducts';
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  OrderNum: Scalars['Int'];
  /** An object relationship */
  StructuredSystem: StructuredSystem;
  SystemId: Scalars['Int'];
};

/** aggregated selection of "StructuredSystemProducts" */
export type StructuredSystemProducts_Aggregate = {
  __typename?: 'StructuredSystemProducts_aggregate';
  aggregate?: Maybe<StructuredSystemProducts_Aggregate_Fields>;
  nodes: Array<StructuredSystemProducts>;
};

/** aggregate fields of "StructuredSystemProducts" */
export type StructuredSystemProducts_Aggregate_Fields = {
  __typename?: 'StructuredSystemProducts_aggregate_fields';
  avg?: Maybe<StructuredSystemProducts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StructuredSystemProducts_Max_Fields>;
  min?: Maybe<StructuredSystemProducts_Min_Fields>;
  stddev?: Maybe<StructuredSystemProducts_Stddev_Fields>;
  stddev_pop?: Maybe<StructuredSystemProducts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StructuredSystemProducts_Stddev_Samp_Fields>;
  sum?: Maybe<StructuredSystemProducts_Sum_Fields>;
  var_pop?: Maybe<StructuredSystemProducts_Var_Pop_Fields>;
  var_samp?: Maybe<StructuredSystemProducts_Var_Samp_Fields>;
  variance?: Maybe<StructuredSystemProducts_Variance_Fields>;
};


/** aggregate fields of "StructuredSystemProducts" */
export type StructuredSystemProducts_Aggregate_FieldsCountArgs = {
  column?: Maybe<StructuredSystemProducts_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "StructuredSystemProducts" */
export type StructuredSystemProducts_Aggregate_Order_By = {
  avg?: Maybe<StructuredSystemProducts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<StructuredSystemProducts_Max_Order_By>;
  min?: Maybe<StructuredSystemProducts_Min_Order_By>;
  stddev?: Maybe<StructuredSystemProducts_Stddev_Order_By>;
  stddev_pop?: Maybe<StructuredSystemProducts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<StructuredSystemProducts_Stddev_Samp_Order_By>;
  sum?: Maybe<StructuredSystemProducts_Sum_Order_By>;
  var_pop?: Maybe<StructuredSystemProducts_Var_Pop_Order_By>;
  var_samp?: Maybe<StructuredSystemProducts_Var_Samp_Order_By>;
  variance?: Maybe<StructuredSystemProducts_Variance_Order_By>;
};

/** aggregate avg on columns */
export type StructuredSystemProducts_Avg_Fields = {
  __typename?: 'StructuredSystemProducts_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
  SystemId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "StructuredSystemProducts" */
export type StructuredSystemProducts_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
  SystemId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "StructuredSystemProducts". All fields are combined with a logical 'AND'. */
export type StructuredSystemProducts_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  OrderNum?: Maybe<Int_Mssql_Comparison_Exp>;
  StructuredSystem?: Maybe<StructuredSystem_Bool_Exp>;
  SystemId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<StructuredSystemProducts_Bool_Exp>>;
  _not?: Maybe<StructuredSystemProducts_Bool_Exp>;
  _or?: Maybe<Array<StructuredSystemProducts_Bool_Exp>>;
};

/** upsert condition type for table "StructuredSystemProducts" */
export type StructuredSystemProducts_If_Matched = {
  match_columns?: Array<StructuredSystemProducts_Insert_Match_Column>;
  update_columns?: Array<StructuredSystemProducts_Update_Column>;
  where?: Maybe<StructuredSystemProducts_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "StructuredSystemProducts" */
export type StructuredSystemProducts_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
  SystemId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "StructuredSystemProducts" */
export type StructuredSystemProducts_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
  SystemId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "StructuredSystemProducts" */
export enum StructuredSystemProducts_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  OrderNum = 'OrderNum',
  /** column name */
  SystemId = 'SystemId'
}

/** aggregate max on columns */
export type StructuredSystemProducts_Max_Fields = {
  __typename?: 'StructuredSystemProducts_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
  SystemId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "StructuredSystemProducts" */
export type StructuredSystemProducts_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
  SystemId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type StructuredSystemProducts_Min_Fields = {
  __typename?: 'StructuredSystemProducts_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
  SystemId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "StructuredSystemProducts" */
export type StructuredSystemProducts_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
  SystemId?: Maybe<Order_By>;
};

/** response of any mutation on the table "StructuredSystemProducts" */
export type StructuredSystemProducts_Mutation_Response = {
  __typename?: 'StructuredSystemProducts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StructuredSystemProducts>;
};

/** Ordering options when selecting data from "StructuredSystemProducts". */
export type StructuredSystemProducts_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  OrderNum?: Maybe<Order_By>;
  StructuredSystem?: Maybe<StructuredSystem_Order_By>;
  SystemId?: Maybe<Order_By>;
};

/** primary key columns input for table: StructuredSystemProducts */
export type StructuredSystemProducts_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  SystemId: Scalars['Int'];
};

/** select columns of table "StructuredSystemProducts" */
export enum StructuredSystemProducts_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  OrderNum = 'OrderNum',
  /** column name */
  SystemId = 'SystemId'
}

/** input type for updating data in table "StructuredSystemProducts" */
export type StructuredSystemProducts_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
  SystemId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type StructuredSystemProducts_Stddev_Fields = {
  __typename?: 'StructuredSystemProducts_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
  SystemId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "StructuredSystemProducts" */
export type StructuredSystemProducts_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
  SystemId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type StructuredSystemProducts_Stddev_Pop_Fields = {
  __typename?: 'StructuredSystemProducts_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
  SystemId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "StructuredSystemProducts" */
export type StructuredSystemProducts_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
  SystemId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type StructuredSystemProducts_Stddev_Samp_Fields = {
  __typename?: 'StructuredSystemProducts_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
  SystemId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "StructuredSystemProducts" */
export type StructuredSystemProducts_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
  SystemId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type StructuredSystemProducts_Sum_Fields = {
  __typename?: 'StructuredSystemProducts_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  OrderNum?: Maybe<Scalars['Int']>;
  SystemId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "StructuredSystemProducts" */
export type StructuredSystemProducts_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
  SystemId?: Maybe<Order_By>;
};

/** update columns of table "StructuredSystemProducts" */
export enum StructuredSystemProducts_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  OrderNum = 'OrderNum',
  /** column name */
  SystemId = 'SystemId'
}

/** aggregate var_pop on columns */
export type StructuredSystemProducts_Var_Pop_Fields = {
  __typename?: 'StructuredSystemProducts_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
  SystemId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "StructuredSystemProducts" */
export type StructuredSystemProducts_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
  SystemId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type StructuredSystemProducts_Var_Samp_Fields = {
  __typename?: 'StructuredSystemProducts_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
  SystemId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "StructuredSystemProducts" */
export type StructuredSystemProducts_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
  SystemId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type StructuredSystemProducts_Variance_Fields = {
  __typename?: 'StructuredSystemProducts_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  OrderNum?: Maybe<Scalars['Float']>;
  SystemId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "StructuredSystemProducts" */
export type StructuredSystemProducts_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  OrderNum?: Maybe<Order_By>;
  SystemId?: Maybe<Order_By>;
};

/** aggregated selection of "StructuredSystem" */
export type StructuredSystem_Aggregate = {
  __typename?: 'StructuredSystem_aggregate';
  aggregate?: Maybe<StructuredSystem_Aggregate_Fields>;
  nodes: Array<StructuredSystem>;
};

/** aggregate fields of "StructuredSystem" */
export type StructuredSystem_Aggregate_Fields = {
  __typename?: 'StructuredSystem_aggregate_fields';
  avg?: Maybe<StructuredSystem_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<StructuredSystem_Max_Fields>;
  min?: Maybe<StructuredSystem_Min_Fields>;
  stddev?: Maybe<StructuredSystem_Stddev_Fields>;
  stddev_pop?: Maybe<StructuredSystem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StructuredSystem_Stddev_Samp_Fields>;
  sum?: Maybe<StructuredSystem_Sum_Fields>;
  var_pop?: Maybe<StructuredSystem_Var_Pop_Fields>;
  var_samp?: Maybe<StructuredSystem_Var_Samp_Fields>;
  variance?: Maybe<StructuredSystem_Variance_Fields>;
};


/** aggregate fields of "StructuredSystem" */
export type StructuredSystem_Aggregate_FieldsCountArgs = {
  column?: Maybe<StructuredSystem_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type StructuredSystem_Avg_Fields = {
  __typename?: 'StructuredSystem_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "StructuredSystem". All fields are combined with a logical 'AND'. */
export type StructuredSystem_Bool_Exp = {
  Component?: Maybe<Component_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  StructuredSystemProducts?: Maybe<StructuredSystemProducts_Bool_Exp>;
  SystemName?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<StructuredSystem_Bool_Exp>>;
  _not?: Maybe<StructuredSystem_Bool_Exp>;
  _or?: Maybe<Array<StructuredSystem_Bool_Exp>>;
};

/** upsert condition type for table "StructuredSystem" */
export type StructuredSystem_If_Matched = {
  match_columns?: Array<StructuredSystem_Insert_Match_Column>;
  update_columns?: Array<StructuredSystem_Update_Column>;
  where?: Maybe<StructuredSystem_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "StructuredSystem" */
export type StructuredSystem_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "StructuredSystem" */
export type StructuredSystem_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SystemName?: Maybe<Scalars['String']>;
};

/** select match_columns of table "StructuredSystem" */
export enum StructuredSystem_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SystemName = 'SystemName'
}

/** aggregate max on columns */
export type StructuredSystem_Max_Fields = {
  __typename?: 'StructuredSystem_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SystemName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type StructuredSystem_Min_Fields = {
  __typename?: 'StructuredSystem_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SystemName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "StructuredSystem" */
export type StructuredSystem_Mutation_Response = {
  __typename?: 'StructuredSystem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StructuredSystem>;
};

/** Ordering options when selecting data from "StructuredSystem". */
export type StructuredSystem_Order_By = {
  Component?: Maybe<Component_Order_By>;
  ComponentId?: Maybe<Order_By>;
  StructuredSystemProducts_aggregate?: Maybe<StructuredSystemProducts_Aggregate_Order_By>;
  SystemName?: Maybe<Order_By>;
};

/** primary key columns input for table: StructuredSystem */
export type StructuredSystem_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "StructuredSystem" */
export enum StructuredSystem_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SystemName = 'SystemName'
}

/** input type for updating data in table "StructuredSystem" */
export type StructuredSystem_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SystemName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type StructuredSystem_Stddev_Fields = {
  __typename?: 'StructuredSystem_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type StructuredSystem_Stddev_Pop_Fields = {
  __typename?: 'StructuredSystem_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type StructuredSystem_Stddev_Samp_Fields = {
  __typename?: 'StructuredSystem_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type StructuredSystem_Sum_Fields = {
  __typename?: 'StructuredSystem_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
};

/** update columns of table "StructuredSystem" */
export enum StructuredSystem_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SystemName = 'SystemName'
}

/** aggregate var_pop on columns */
export type StructuredSystem_Var_Pop_Fields = {
  __typename?: 'StructuredSystem_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type StructuredSystem_Var_Samp_Fields = {
  __typename?: 'StructuredSystem_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type StructuredSystem_Variance_Fields = {
  __typename?: 'StructuredSystem_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subassembly" */
export type Subassembly = {
  __typename?: 'Subassembly';
  AssemblyNum: Scalars['String'];
  /** An object relationship */
  C_LockStatus: C_LockStatus;
  /** An object relationship */
  C_SubassemblyType?: Maybe<C_SubassemblyType>;
  /** An object relationship */
  Component: Component;
  DateLocked?: Maybe<Scalars['datetime']>;
  /** An object relationship */
  FMUserAccount?: Maybe<FmUserAccount>;
  LockStatusId: Scalars['Int'];
  LockedByUserAccountid?: Maybe<Scalars['Int']>;
  /** An array relationship */
  R_Subassembly_MultiplyCoverConfigs: Array<R_Subassembly_MultiplyCoverConfig>;
  /** An aggregate relationship */
  R_Subassembly_MultiplyCoverConfigs_aggregate: R_Subassembly_MultiplyCoverConfig_Aggregate;
  SubassemblyComponentId: Scalars['Int'];
  /** An object relationship */
  SubassemblyE108Rating?: Maybe<SubassemblyE108Rating>;
  /** An array relationship */
  SubassemblyLayers: Array<SubassemblyLayers>;
  /** An aggregate relationship */
  SubassemblyLayers_aggregate: SubassemblyLayers_Aggregate;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "Subassembly" */
export type SubassemblyR_Subassembly_MultiplyCoverConfigsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Subassembly_MultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_Subassembly_MultiplyCoverConfig_Bool_Exp>;
};


/** columns and relationships of "Subassembly" */
export type SubassemblyR_Subassembly_MultiplyCoverConfigs_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Subassembly_MultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_Subassembly_MultiplyCoverConfig_Bool_Exp>;
};


/** columns and relationships of "Subassembly" */
export type SubassemblySubassemblyLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyLayers_Order_By>>;
  where?: Maybe<SubassemblyLayers_Bool_Exp>;
};


/** columns and relationships of "Subassembly" */
export type SubassemblySubassemblyLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyLayers_Order_By>>;
  where?: Maybe<SubassemblyLayers_Bool_Exp>;
};

/** columns and relationships of "SubassemblyDelistData" */
export type SubassemblyDelistData = {
  __typename?: 'SubassemblyDelistData';
  ApprovedUseId: Scalars['Int'];
  AssemblyId: Scalars['Int'];
  ComponentId: Scalars['Int'];
  LayerComponentCount: Scalars['Int'];
  LayerId: Scalars['Int'];
  LayerManufacturerCount: Scalars['Int'];
};

/** aggregated selection of "SubassemblyDelistData" */
export type SubassemblyDelistData_Aggregate = {
  __typename?: 'SubassemblyDelistData_aggregate';
  aggregate?: Maybe<SubassemblyDelistData_Aggregate_Fields>;
  nodes: Array<SubassemblyDelistData>;
};

/** aggregate fields of "SubassemblyDelistData" */
export type SubassemblyDelistData_Aggregate_Fields = {
  __typename?: 'SubassemblyDelistData_aggregate_fields';
  avg?: Maybe<SubassemblyDelistData_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SubassemblyDelistData_Max_Fields>;
  min?: Maybe<SubassemblyDelistData_Min_Fields>;
  stddev?: Maybe<SubassemblyDelistData_Stddev_Fields>;
  stddev_pop?: Maybe<SubassemblyDelistData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SubassemblyDelistData_Stddev_Samp_Fields>;
  sum?: Maybe<SubassemblyDelistData_Sum_Fields>;
  var_pop?: Maybe<SubassemblyDelistData_Var_Pop_Fields>;
  var_samp?: Maybe<SubassemblyDelistData_Var_Samp_Fields>;
  variance?: Maybe<SubassemblyDelistData_Variance_Fields>;
};


/** aggregate fields of "SubassemblyDelistData" */
export type SubassemblyDelistData_Aggregate_FieldsCountArgs = {
  column?: Maybe<SubassemblyDelistData_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SubassemblyDelistData_Avg_Fields = {
  __typename?: 'SubassemblyDelistData_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerComponentCount?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerManufacturerCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SubassemblyDelistData". All fields are combined with a logical 'AND'. */
export type SubassemblyDelistData_Bool_Exp = {
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  AssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  LayerComponentCount?: Maybe<Int_Mssql_Comparison_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  LayerManufacturerCount?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SubassemblyDelistData_Bool_Exp>>;
  _not?: Maybe<SubassemblyDelistData_Bool_Exp>;
  _or?: Maybe<Array<SubassemblyDelistData_Bool_Exp>>;
};

/** upsert condition type for table "SubassemblyDelistData" */
export type SubassemblyDelistData_If_Matched = {
  match_columns?: Array<SubassemblyDelistData_Insert_Match_Column>;
  update_columns?: Array<SubassemblyDelistData_Update_Column>;
  where?: Maybe<SubassemblyDelistData_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SubassemblyDelistData" */
export type SubassemblyDelistData_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerComponentCount?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerManufacturerCount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SubassemblyDelistData" */
export type SubassemblyDelistData_Insert_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerComponentCount?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerManufacturerCount?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SubassemblyDelistData" */
export enum SubassemblyDelistData_Insert_Match_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LayerComponentCount = 'LayerComponentCount',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerManufacturerCount = 'LayerManufacturerCount'
}

/** aggregate max on columns */
export type SubassemblyDelistData_Max_Fields = {
  __typename?: 'SubassemblyDelistData_max_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerComponentCount?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerManufacturerCount?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SubassemblyDelistData_Min_Fields = {
  __typename?: 'SubassemblyDelistData_min_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerComponentCount?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerManufacturerCount?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "SubassemblyDelistData" */
export type SubassemblyDelistData_Mutation_Response = {
  __typename?: 'SubassemblyDelistData_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SubassemblyDelistData>;
};

/** Ordering options when selecting data from "SubassemblyDelistData". */
export type SubassemblyDelistData_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LayerComponentCount?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerManufacturerCount?: Maybe<Order_By>;
};

/** select columns of table "SubassemblyDelistData" */
export enum SubassemblyDelistData_Select_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LayerComponentCount = 'LayerComponentCount',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerManufacturerCount = 'LayerManufacturerCount'
}

/** input type for updating data in table "SubassemblyDelistData" */
export type SubassemblyDelistData_Set_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerComponentCount?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerManufacturerCount?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SubassemblyDelistData_Stddev_Fields = {
  __typename?: 'SubassemblyDelistData_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerComponentCount?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerManufacturerCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SubassemblyDelistData_Stddev_Pop_Fields = {
  __typename?: 'SubassemblyDelistData_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerComponentCount?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerManufacturerCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SubassemblyDelistData_Stddev_Samp_Fields = {
  __typename?: 'SubassemblyDelistData_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerComponentCount?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerManufacturerCount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SubassemblyDelistData_Sum_Fields = {
  __typename?: 'SubassemblyDelistData_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LayerComponentCount?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerManufacturerCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "SubassemblyDelistData" */
export enum SubassemblyDelistData_Update_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LayerComponentCount = 'LayerComponentCount',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerManufacturerCount = 'LayerManufacturerCount'
}

/** aggregate var_pop on columns */
export type SubassemblyDelistData_Var_Pop_Fields = {
  __typename?: 'SubassemblyDelistData_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerComponentCount?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerManufacturerCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SubassemblyDelistData_Var_Samp_Fields = {
  __typename?: 'SubassemblyDelistData_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerComponentCount?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerManufacturerCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SubassemblyDelistData_Variance_Fields = {
  __typename?: 'SubassemblyDelistData_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LayerComponentCount?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerManufacturerCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "SubassemblyE108Rating" */
export type SubassemblyE108Rating = {
  __typename?: 'SubassemblyE108Rating';
  ASlopeRating: Scalars['MeasurementValue'];
  BSlopeRating: Scalars['MeasurementValue'];
  CSlopeRating: Scalars['MeasurementValue'];
  /** An object relationship */
  Subassembly: Subassembly;
  SubassemblyComponentId: Scalars['Int'];
};

/** aggregated selection of "SubassemblyE108Rating" */
export type SubassemblyE108Rating_Aggregate = {
  __typename?: 'SubassemblyE108Rating_aggregate';
  aggregate?: Maybe<SubassemblyE108Rating_Aggregate_Fields>;
  nodes: Array<SubassemblyE108Rating>;
};

/** aggregate fields of "SubassemblyE108Rating" */
export type SubassemblyE108Rating_Aggregate_Fields = {
  __typename?: 'SubassemblyE108Rating_aggregate_fields';
  avg?: Maybe<SubassemblyE108Rating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SubassemblyE108Rating_Max_Fields>;
  min?: Maybe<SubassemblyE108Rating_Min_Fields>;
  stddev?: Maybe<SubassemblyE108Rating_Stddev_Fields>;
  stddev_pop?: Maybe<SubassemblyE108Rating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SubassemblyE108Rating_Stddev_Samp_Fields>;
  sum?: Maybe<SubassemblyE108Rating_Sum_Fields>;
  var_pop?: Maybe<SubassemblyE108Rating_Var_Pop_Fields>;
  var_samp?: Maybe<SubassemblyE108Rating_Var_Samp_Fields>;
  variance?: Maybe<SubassemblyE108Rating_Variance_Fields>;
};


/** aggregate fields of "SubassemblyE108Rating" */
export type SubassemblyE108Rating_Aggregate_FieldsCountArgs = {
  column?: Maybe<SubassemblyE108Rating_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SubassemblyE108Rating_Avg_Fields = {
  __typename?: 'SubassemblyE108Rating_avg_fields';
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SubassemblyE108Rating". All fields are combined with a logical 'AND'. */
export type SubassemblyE108Rating_Bool_Exp = {
  ASlopeRating?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  BSlopeRating?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  CSlopeRating?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Subassembly?: Maybe<Subassembly_Bool_Exp>;
  SubassemblyComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SubassemblyE108Rating_Bool_Exp>>;
  _not?: Maybe<SubassemblyE108Rating_Bool_Exp>;
  _or?: Maybe<Array<SubassemblyE108Rating_Bool_Exp>>;
};

/** upsert condition type for table "SubassemblyE108Rating" */
export type SubassemblyE108Rating_If_Matched = {
  match_columns?: Array<SubassemblyE108Rating_Insert_Match_Column>;
  update_columns?: Array<SubassemblyE108Rating_Update_Column>;
  where?: Maybe<SubassemblyE108Rating_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SubassemblyE108Rating" */
export type SubassemblyE108Rating_Inc_Input = {
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SubassemblyE108Rating" */
export type SubassemblyE108Rating_Insert_Input = {
  ASlopeRating?: Maybe<Scalars['MeasurementValue']>;
  BSlopeRating?: Maybe<Scalars['MeasurementValue']>;
  CSlopeRating?: Maybe<Scalars['MeasurementValue']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SubassemblyE108Rating" */
export enum SubassemblyE108Rating_Insert_Match_Column {
  /** column name */
  ASlopeRating = 'ASlopeRating',
  /** column name */
  BSlopeRating = 'BSlopeRating',
  /** column name */
  CSlopeRating = 'CSlopeRating',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** aggregate max on columns */
export type SubassemblyE108Rating_Max_Fields = {
  __typename?: 'SubassemblyE108Rating_max_fields';
  ASlopeRating?: Maybe<Scalars['MeasurementValue']>;
  BSlopeRating?: Maybe<Scalars['MeasurementValue']>;
  CSlopeRating?: Maybe<Scalars['MeasurementValue']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SubassemblyE108Rating_Min_Fields = {
  __typename?: 'SubassemblyE108Rating_min_fields';
  ASlopeRating?: Maybe<Scalars['MeasurementValue']>;
  BSlopeRating?: Maybe<Scalars['MeasurementValue']>;
  CSlopeRating?: Maybe<Scalars['MeasurementValue']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "SubassemblyE108Rating" */
export type SubassemblyE108Rating_Mutation_Response = {
  __typename?: 'SubassemblyE108Rating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SubassemblyE108Rating>;
};

/** Ordering options when selecting data from "SubassemblyE108Rating". */
export type SubassemblyE108Rating_Order_By = {
  ASlopeRating?: Maybe<Order_By>;
  BSlopeRating?: Maybe<Order_By>;
  CSlopeRating?: Maybe<Order_By>;
  Subassembly?: Maybe<Subassembly_Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** primary key columns input for table: SubassemblyE108Rating */
export type SubassemblyE108Rating_Pk_Columns_Input = {
  SubassemblyComponentId: Scalars['Int'];
};

/** select columns of table "SubassemblyE108Rating" */
export enum SubassemblyE108Rating_Select_Column {
  /** column name */
  ASlopeRating = 'ASlopeRating',
  /** column name */
  BSlopeRating = 'BSlopeRating',
  /** column name */
  CSlopeRating = 'CSlopeRating',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** input type for updating data in table "SubassemblyE108Rating" */
export type SubassemblyE108Rating_Set_Input = {
  ASlopeRating?: Maybe<Scalars['MeasurementValue']>;
  BSlopeRating?: Maybe<Scalars['MeasurementValue']>;
  CSlopeRating?: Maybe<Scalars['MeasurementValue']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SubassemblyE108Rating_Stddev_Fields = {
  __typename?: 'SubassemblyE108Rating_stddev_fields';
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SubassemblyE108Rating_Stddev_Pop_Fields = {
  __typename?: 'SubassemblyE108Rating_stddev_pop_fields';
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SubassemblyE108Rating_Stddev_Samp_Fields = {
  __typename?: 'SubassemblyE108Rating_stddev_samp_fields';
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SubassemblyE108Rating_Sum_Fields = {
  __typename?: 'SubassemblyE108Rating_sum_fields';
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** update columns of table "SubassemblyE108Rating" */
export enum SubassemblyE108Rating_Update_Column {
  /** column name */
  ASlopeRating = 'ASlopeRating',
  /** column name */
  BSlopeRating = 'BSlopeRating',
  /** column name */
  CSlopeRating = 'CSlopeRating',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** aggregate var_pop on columns */
export type SubassemblyE108Rating_Var_Pop_Fields = {
  __typename?: 'SubassemblyE108Rating_var_pop_fields';
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SubassemblyE108Rating_Var_Samp_Fields = {
  __typename?: 'SubassemblyE108Rating_var_samp_fields';
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SubassemblyE108Rating_Variance_Fields = {
  __typename?: 'SubassemblyE108Rating_variance_fields';
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "SubassemblyLayers" */
export type SubassemblyLayers = {
  __typename?: 'SubassemblyLayers';
  /** An object relationship */
  Layer: Layer;
  LayerId: Scalars['Int'];
  /** An object relationship */
  Subassembly: Subassembly;
  SubassemblyComponentId: Scalars['Int'];
};

/** aggregated selection of "SubassemblyLayers" */
export type SubassemblyLayers_Aggregate = {
  __typename?: 'SubassemblyLayers_aggregate';
  aggregate?: Maybe<SubassemblyLayers_Aggregate_Fields>;
  nodes: Array<SubassemblyLayers>;
};

/** aggregate fields of "SubassemblyLayers" */
export type SubassemblyLayers_Aggregate_Fields = {
  __typename?: 'SubassemblyLayers_aggregate_fields';
  avg?: Maybe<SubassemblyLayers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SubassemblyLayers_Max_Fields>;
  min?: Maybe<SubassemblyLayers_Min_Fields>;
  stddev?: Maybe<SubassemblyLayers_Stddev_Fields>;
  stddev_pop?: Maybe<SubassemblyLayers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SubassemblyLayers_Stddev_Samp_Fields>;
  sum?: Maybe<SubassemblyLayers_Sum_Fields>;
  var_pop?: Maybe<SubassemblyLayers_Var_Pop_Fields>;
  var_samp?: Maybe<SubassemblyLayers_Var_Samp_Fields>;
  variance?: Maybe<SubassemblyLayers_Variance_Fields>;
};


/** aggregate fields of "SubassemblyLayers" */
export type SubassemblyLayers_Aggregate_FieldsCountArgs = {
  column?: Maybe<SubassemblyLayers_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SubassemblyLayers" */
export type SubassemblyLayers_Aggregate_Order_By = {
  avg?: Maybe<SubassemblyLayers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SubassemblyLayers_Max_Order_By>;
  min?: Maybe<SubassemblyLayers_Min_Order_By>;
  stddev?: Maybe<SubassemblyLayers_Stddev_Order_By>;
  stddev_pop?: Maybe<SubassemblyLayers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SubassemblyLayers_Stddev_Samp_Order_By>;
  sum?: Maybe<SubassemblyLayers_Sum_Order_By>;
  var_pop?: Maybe<SubassemblyLayers_Var_Pop_Order_By>;
  var_samp?: Maybe<SubassemblyLayers_Var_Samp_Order_By>;
  variance?: Maybe<SubassemblyLayers_Variance_Order_By>;
};

/** aggregate avg on columns */
export type SubassemblyLayers_Avg_Fields = {
  __typename?: 'SubassemblyLayers_avg_fields';
  LayerId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SubassemblyLayers" */
export type SubassemblyLayers_Avg_Order_By = {
  LayerId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SubassemblyLayers". All fields are combined with a logical 'AND'. */
export type SubassemblyLayers_Bool_Exp = {
  Layer?: Maybe<Layer_Bool_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  Subassembly?: Maybe<Subassembly_Bool_Exp>;
  SubassemblyComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SubassemblyLayers_Bool_Exp>>;
  _not?: Maybe<SubassemblyLayers_Bool_Exp>;
  _or?: Maybe<Array<SubassemblyLayers_Bool_Exp>>;
};

/** upsert condition type for table "SubassemblyLayers" */
export type SubassemblyLayers_If_Matched = {
  match_columns?: Array<SubassemblyLayers_Insert_Match_Column>;
  update_columns?: Array<SubassemblyLayers_Update_Column>;
  where?: Maybe<SubassemblyLayers_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SubassemblyLayers" */
export type SubassemblyLayers_Inc_Input = {
  LayerId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SubassemblyLayers" */
export type SubassemblyLayers_Insert_Input = {
  LayerId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SubassemblyLayers" */
export enum SubassemblyLayers_Insert_Match_Column {
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** aggregate max on columns */
export type SubassemblyLayers_Max_Fields = {
  __typename?: 'SubassemblyLayers_max_fields';
  LayerId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "SubassemblyLayers" */
export type SubassemblyLayers_Max_Order_By = {
  LayerId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SubassemblyLayers_Min_Fields = {
  __typename?: 'SubassemblyLayers_min_fields';
  LayerId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "SubassemblyLayers" */
export type SubassemblyLayers_Min_Order_By = {
  LayerId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "SubassemblyLayers" */
export type SubassemblyLayers_Mutation_Response = {
  __typename?: 'SubassemblyLayers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SubassemblyLayers>;
};

/** Ordering options when selecting data from "SubassemblyLayers". */
export type SubassemblyLayers_Order_By = {
  Layer?: Maybe<Layer_Order_By>;
  LayerId?: Maybe<Order_By>;
  Subassembly?: Maybe<Subassembly_Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** primary key columns input for table: SubassemblyLayers */
export type SubassemblyLayers_Pk_Columns_Input = {
  LayerId: Scalars['Int'];
  SubassemblyComponentId: Scalars['Int'];
};

/** select columns of table "SubassemblyLayers" */
export enum SubassemblyLayers_Select_Column {
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** input type for updating data in table "SubassemblyLayers" */
export type SubassemblyLayers_Set_Input = {
  LayerId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SubassemblyLayers_Stddev_Fields = {
  __typename?: 'SubassemblyLayers_stddev_fields';
  LayerId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SubassemblyLayers" */
export type SubassemblyLayers_Stddev_Order_By = {
  LayerId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SubassemblyLayers_Stddev_Pop_Fields = {
  __typename?: 'SubassemblyLayers_stddev_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SubassemblyLayers" */
export type SubassemblyLayers_Stddev_Pop_Order_By = {
  LayerId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SubassemblyLayers_Stddev_Samp_Fields = {
  __typename?: 'SubassemblyLayers_stddev_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SubassemblyLayers" */
export type SubassemblyLayers_Stddev_Samp_Order_By = {
  LayerId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type SubassemblyLayers_Sum_Fields = {
  __typename?: 'SubassemblyLayers_sum_fields';
  LayerId?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "SubassemblyLayers" */
export type SubassemblyLayers_Sum_Order_By = {
  LayerId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** update columns of table "SubassemblyLayers" */
export enum SubassemblyLayers_Update_Column {
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId'
}

/** aggregate var_pop on columns */
export type SubassemblyLayers_Var_Pop_Fields = {
  __typename?: 'SubassemblyLayers_var_pop_fields';
  LayerId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SubassemblyLayers" */
export type SubassemblyLayers_Var_Pop_Order_By = {
  LayerId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SubassemblyLayers_Var_Samp_Fields = {
  __typename?: 'SubassemblyLayers_var_samp_fields';
  LayerId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SubassemblyLayers" */
export type SubassemblyLayers_Var_Samp_Order_By = {
  LayerId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SubassemblyLayers_Variance_Fields = {
  __typename?: 'SubassemblyLayers_variance_fields';
  LayerId?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SubassemblyLayers" */
export type SubassemblyLayers_Variance_Order_By = {
  LayerId?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
};

/** columns and relationships of "SubassemblyMultiplyConfig" */
export type SubassemblyMultiplyConfig = {
  __typename?: 'SubassemblyMultiplyConfig';
  AssemblyNum: Scalars['Int'];
  ConfigString: Scalars['String'];
  NumPlies: Scalars['Int'];
  SubassemblyComponentId: Scalars['Int'];
  SubassemblyTypeId: Scalars['Int'];
};

/** aggregated selection of "SubassemblyMultiplyConfig" */
export type SubassemblyMultiplyConfig_Aggregate = {
  __typename?: 'SubassemblyMultiplyConfig_aggregate';
  aggregate?: Maybe<SubassemblyMultiplyConfig_Aggregate_Fields>;
  nodes: Array<SubassemblyMultiplyConfig>;
};

/** aggregate fields of "SubassemblyMultiplyConfig" */
export type SubassemblyMultiplyConfig_Aggregate_Fields = {
  __typename?: 'SubassemblyMultiplyConfig_aggregate_fields';
  avg?: Maybe<SubassemblyMultiplyConfig_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SubassemblyMultiplyConfig_Max_Fields>;
  min?: Maybe<SubassemblyMultiplyConfig_Min_Fields>;
  stddev?: Maybe<SubassemblyMultiplyConfig_Stddev_Fields>;
  stddev_pop?: Maybe<SubassemblyMultiplyConfig_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SubassemblyMultiplyConfig_Stddev_Samp_Fields>;
  sum?: Maybe<SubassemblyMultiplyConfig_Sum_Fields>;
  var_pop?: Maybe<SubassemblyMultiplyConfig_Var_Pop_Fields>;
  var_samp?: Maybe<SubassemblyMultiplyConfig_Var_Samp_Fields>;
  variance?: Maybe<SubassemblyMultiplyConfig_Variance_Fields>;
};


/** aggregate fields of "SubassemblyMultiplyConfig" */
export type SubassemblyMultiplyConfig_Aggregate_FieldsCountArgs = {
  column?: Maybe<SubassemblyMultiplyConfig_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SubassemblyMultiplyConfig_Avg_Fields = {
  __typename?: 'SubassemblyMultiplyConfig_avg_fields';
  AssemblyNum?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SubassemblyMultiplyConfig". All fields are combined with a logical 'AND'. */
export type SubassemblyMultiplyConfig_Bool_Exp = {
  AssemblyNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ConfigString?: Maybe<String_Mssql_Comparison_Exp>;
  NumPlies?: Maybe<Int_Mssql_Comparison_Exp>;
  SubassemblyComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubassemblyTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SubassemblyMultiplyConfig_Bool_Exp>>;
  _not?: Maybe<SubassemblyMultiplyConfig_Bool_Exp>;
  _or?: Maybe<Array<SubassemblyMultiplyConfig_Bool_Exp>>;
};

/** upsert condition type for table "SubassemblyMultiplyConfig" */
export type SubassemblyMultiplyConfig_If_Matched = {
  match_columns?: Array<SubassemblyMultiplyConfig_Insert_Match_Column>;
  update_columns?: Array<SubassemblyMultiplyConfig_Update_Column>;
  where?: Maybe<SubassemblyMultiplyConfig_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SubassemblyMultiplyConfig" */
export type SubassemblyMultiplyConfig_Inc_Input = {
  AssemblyNum?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SubassemblyMultiplyConfig" */
export type SubassemblyMultiplyConfig_Insert_Input = {
  AssemblyNum?: Maybe<Scalars['Int']>;
  ConfigString?: Maybe<Scalars['String']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SubassemblyMultiplyConfig" */
export enum SubassemblyMultiplyConfig_Insert_Match_Column {
  /** column name */
  AssemblyNum = 'AssemblyNum',
  /** column name */
  ConfigString = 'ConfigString',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId',
  /** column name */
  SubassemblyTypeId = 'SubassemblyTypeId'
}

/** aggregate max on columns */
export type SubassemblyMultiplyConfig_Max_Fields = {
  __typename?: 'SubassemblyMultiplyConfig_max_fields';
  AssemblyNum?: Maybe<Scalars['Int']>;
  ConfigString?: Maybe<Scalars['String']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SubassemblyMultiplyConfig_Min_Fields = {
  __typename?: 'SubassemblyMultiplyConfig_min_fields';
  AssemblyNum?: Maybe<Scalars['Int']>;
  ConfigString?: Maybe<Scalars['String']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "SubassemblyMultiplyConfig" */
export type SubassemblyMultiplyConfig_Mutation_Response = {
  __typename?: 'SubassemblyMultiplyConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SubassemblyMultiplyConfig>;
};

/** Ordering options when selecting data from "SubassemblyMultiplyConfig". */
export type SubassemblyMultiplyConfig_Order_By = {
  AssemblyNum?: Maybe<Order_By>;
  ConfigString?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: SubassemblyMultiplyConfig */
export type SubassemblyMultiplyConfig_Pk_Columns_Input = {
  SubassemblyComponentId: Scalars['Int'];
};

/** select columns of table "SubassemblyMultiplyConfig" */
export enum SubassemblyMultiplyConfig_Select_Column {
  /** column name */
  AssemblyNum = 'AssemblyNum',
  /** column name */
  ConfigString = 'ConfigString',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId',
  /** column name */
  SubassemblyTypeId = 'SubassemblyTypeId'
}

/** input type for updating data in table "SubassemblyMultiplyConfig" */
export type SubassemblyMultiplyConfig_Set_Input = {
  AssemblyNum?: Maybe<Scalars['Int']>;
  ConfigString?: Maybe<Scalars['String']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SubassemblyMultiplyConfig_Stddev_Fields = {
  __typename?: 'SubassemblyMultiplyConfig_stddev_fields';
  AssemblyNum?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SubassemblyMultiplyConfig_Stddev_Pop_Fields = {
  __typename?: 'SubassemblyMultiplyConfig_stddev_pop_fields';
  AssemblyNum?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SubassemblyMultiplyConfig_Stddev_Samp_Fields = {
  __typename?: 'SubassemblyMultiplyConfig_stddev_samp_fields';
  AssemblyNum?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SubassemblyMultiplyConfig_Sum_Fields = {
  __typename?: 'SubassemblyMultiplyConfig_sum_fields';
  AssemblyNum?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "SubassemblyMultiplyConfig" */
export enum SubassemblyMultiplyConfig_Update_Column {
  /** column name */
  AssemblyNum = 'AssemblyNum',
  /** column name */
  ConfigString = 'ConfigString',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId',
  /** column name */
  SubassemblyTypeId = 'SubassemblyTypeId'
}

/** aggregate var_pop on columns */
export type SubassemblyMultiplyConfig_Var_Pop_Fields = {
  __typename?: 'SubassemblyMultiplyConfig_var_pop_fields';
  AssemblyNum?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SubassemblyMultiplyConfig_Var_Samp_Fields = {
  __typename?: 'SubassemblyMultiplyConfig_var_samp_fields';
  AssemblyNum?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SubassemblyMultiplyConfig_Variance_Fields = {
  __typename?: 'SubassemblyMultiplyConfig_variance_fields';
  AssemblyNum?: Maybe<Scalars['Float']>;
  NumPlies?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Subassembly" */
export type Subassembly_Aggregate = {
  __typename?: 'Subassembly_aggregate';
  aggregate?: Maybe<Subassembly_Aggregate_Fields>;
  nodes: Array<Subassembly>;
};

/** aggregate fields of "Subassembly" */
export type Subassembly_Aggregate_Fields = {
  __typename?: 'Subassembly_aggregate_fields';
  avg?: Maybe<Subassembly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subassembly_Max_Fields>;
  min?: Maybe<Subassembly_Min_Fields>;
  stddev?: Maybe<Subassembly_Stddev_Fields>;
  stddev_pop?: Maybe<Subassembly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subassembly_Stddev_Samp_Fields>;
  sum?: Maybe<Subassembly_Sum_Fields>;
  var_pop?: Maybe<Subassembly_Var_Pop_Fields>;
  var_samp?: Maybe<Subassembly_Var_Samp_Fields>;
  variance?: Maybe<Subassembly_Variance_Fields>;
};


/** aggregate fields of "Subassembly" */
export type Subassembly_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subassembly_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Subassembly" */
export type Subassembly_Aggregate_Order_By = {
  avg?: Maybe<Subassembly_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Subassembly_Max_Order_By>;
  min?: Maybe<Subassembly_Min_Order_By>;
  stddev?: Maybe<Subassembly_Stddev_Order_By>;
  stddev_pop?: Maybe<Subassembly_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Subassembly_Stddev_Samp_Order_By>;
  sum?: Maybe<Subassembly_Sum_Order_By>;
  var_pop?: Maybe<Subassembly_Var_Pop_Order_By>;
  var_samp?: Maybe<Subassembly_Var_Samp_Order_By>;
  variance?: Maybe<Subassembly_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Subassembly_Avg_Fields = {
  __typename?: 'Subassembly_avg_fields';
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountid?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Subassembly" */
export type Subassembly_Avg_Order_By = {
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountid?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Subassembly". All fields are combined with a logical 'AND'. */
export type Subassembly_Bool_Exp = {
  AssemblyNum?: Maybe<String_Mssql_Comparison_Exp>;
  C_LockStatus?: Maybe<C_LockStatus_Bool_Exp>;
  C_SubassemblyType?: Maybe<C_SubassemblyType_Bool_Exp>;
  Component?: Maybe<Component_Bool_Exp>;
  DateLocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  FMUserAccount?: Maybe<FmUserAccount_Bool_Exp>;
  LockStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  LockedByUserAccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  R_Subassembly_MultiplyCoverConfigs?: Maybe<R_Subassembly_MultiplyCoverConfig_Bool_Exp>;
  SubassemblyComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubassemblyE108Rating?: Maybe<SubassemblyE108Rating_Bool_Exp>;
  SubassemblyLayers?: Maybe<SubassemblyLayers_Bool_Exp>;
  SubassemblyTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subassembly_Bool_Exp>>;
  _not?: Maybe<Subassembly_Bool_Exp>;
  _or?: Maybe<Array<Subassembly_Bool_Exp>>;
};

/** upsert condition type for table "Subassembly" */
export type Subassembly_If_Matched = {
  match_columns?: Array<Subassembly_Insert_Match_Column>;
  update_columns?: Array<Subassembly_Update_Column>;
  where?: Maybe<Subassembly_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subassembly" */
export type Subassembly_Inc_Input = {
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountid?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subassembly" */
export type Subassembly_Insert_Input = {
  AssemblyNum?: Maybe<Scalars['String']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountid?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "Subassembly" */
export enum Subassembly_Insert_Match_Column {
  /** column name */
  AssemblyNum = 'AssemblyNum',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountid = 'LockedByUserAccountid',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId',
  /** column name */
  SubassemblyTypeId = 'SubassemblyTypeId'
}

/** aggregate max on columns */
export type Subassembly_Max_Fields = {
  __typename?: 'Subassembly_max_fields';
  AssemblyNum?: Maybe<Scalars['String']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountid?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Subassembly" */
export type Subassembly_Max_Order_By = {
  AssemblyNum?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountid?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Subassembly_Min_Fields = {
  __typename?: 'Subassembly_min_fields';
  AssemblyNum?: Maybe<Scalars['String']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountid?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Subassembly" */
export type Subassembly_Min_Order_By = {
  AssemblyNum?: Maybe<Order_By>;
  DateLocked?: Maybe<Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountid?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Subassembly" */
export type Subassembly_Mutation_Response = {
  __typename?: 'Subassembly_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subassembly>;
};

/** Ordering options when selecting data from "Subassembly". */
export type Subassembly_Order_By = {
  AssemblyNum?: Maybe<Order_By>;
  C_LockStatus?: Maybe<C_LockStatus_Order_By>;
  C_SubassemblyType?: Maybe<C_SubassemblyType_Order_By>;
  Component?: Maybe<Component_Order_By>;
  DateLocked?: Maybe<Order_By>;
  FMUserAccount?: Maybe<FmUserAccount_Order_By>;
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountid?: Maybe<Order_By>;
  R_Subassembly_MultiplyCoverConfigs_aggregate?: Maybe<R_Subassembly_MultiplyCoverConfig_Aggregate_Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  SubassemblyE108Rating?: Maybe<SubassemblyE108Rating_Order_By>;
  SubassemblyLayers_aggregate?: Maybe<SubassemblyLayers_Aggregate_Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: Subassembly */
export type Subassembly_Pk_Columns_Input = {
  SubassemblyComponentId: Scalars['Int'];
};

/** select columns of table "Subassembly" */
export enum Subassembly_Select_Column {
  /** column name */
  AssemblyNum = 'AssemblyNum',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountid = 'LockedByUserAccountid',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId',
  /** column name */
  SubassemblyTypeId = 'SubassemblyTypeId'
}

/** input type for updating data in table "Subassembly" */
export type Subassembly_Set_Input = {
  AssemblyNum?: Maybe<Scalars['String']>;
  DateLocked?: Maybe<Scalars['datetime']>;
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountid?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Subassembly_Stddev_Fields = {
  __typename?: 'Subassembly_stddev_fields';
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountid?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Subassembly" */
export type Subassembly_Stddev_Order_By = {
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountid?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Subassembly_Stddev_Pop_Fields = {
  __typename?: 'Subassembly_stddev_pop_fields';
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountid?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Subassembly" */
export type Subassembly_Stddev_Pop_Order_By = {
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountid?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Subassembly_Stddev_Samp_Fields = {
  __typename?: 'Subassembly_stddev_samp_fields';
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountid?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Subassembly" */
export type Subassembly_Stddev_Samp_Order_By = {
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountid?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Subassembly_Sum_Fields = {
  __typename?: 'Subassembly_sum_fields';
  LockStatusId?: Maybe<Scalars['Int']>;
  LockedByUserAccountid?: Maybe<Scalars['Int']>;
  SubassemblyComponentId?: Maybe<Scalars['Int']>;
  SubassemblyTypeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Subassembly" */
export type Subassembly_Sum_Order_By = {
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountid?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** update columns of table "Subassembly" */
export enum Subassembly_Update_Column {
  /** column name */
  AssemblyNum = 'AssemblyNum',
  /** column name */
  DateLocked = 'DateLocked',
  /** column name */
  LockStatusId = 'LockStatusId',
  /** column name */
  LockedByUserAccountid = 'LockedByUserAccountid',
  /** column name */
  SubassemblyComponentId = 'SubassemblyComponentId',
  /** column name */
  SubassemblyTypeId = 'SubassemblyTypeId'
}

/** aggregate var_pop on columns */
export type Subassembly_Var_Pop_Fields = {
  __typename?: 'Subassembly_var_pop_fields';
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountid?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Subassembly" */
export type Subassembly_Var_Pop_Order_By = {
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountid?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Subassembly_Var_Samp_Fields = {
  __typename?: 'Subassembly_var_samp_fields';
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountid?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Subassembly" */
export type Subassembly_Var_Samp_Order_By = {
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountid?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Subassembly_Variance_Fields = {
  __typename?: 'Subassembly_variance_fields';
  LockStatusId?: Maybe<Scalars['Float']>;
  LockedByUserAccountid?: Maybe<Scalars['Float']>;
  SubassemblyComponentId?: Maybe<Scalars['Float']>;
  SubassemblyTypeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Subassembly" */
export type Subassembly_Variance_Order_By = {
  LockStatusId?: Maybe<Order_By>;
  LockedByUserAccountid?: Maybe<Order_By>;
  SubassemblyComponentId?: Maybe<Order_By>;
  SubassemblyTypeId?: Maybe<Order_By>;
};

/** columns and relationships of "Subcat_Adhesive" */
export type Subcat_Adhesive = {
  __typename?: 'Subcat_Adhesive';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_Adhesive" */
export type Subcat_Adhesive_Aggregate = {
  __typename?: 'Subcat_Adhesive_aggregate';
  aggregate?: Maybe<Subcat_Adhesive_Aggregate_Fields>;
  nodes: Array<Subcat_Adhesive>;
};

/** aggregate fields of "Subcat_Adhesive" */
export type Subcat_Adhesive_Aggregate_Fields = {
  __typename?: 'Subcat_Adhesive_aggregate_fields';
  avg?: Maybe<Subcat_Adhesive_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_Adhesive_Max_Fields>;
  min?: Maybe<Subcat_Adhesive_Min_Fields>;
  stddev?: Maybe<Subcat_Adhesive_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_Adhesive_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_Adhesive_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_Adhesive_Sum_Fields>;
  var_pop?: Maybe<Subcat_Adhesive_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_Adhesive_Var_Samp_Fields>;
  variance?: Maybe<Subcat_Adhesive_Variance_Fields>;
};


/** aggregate fields of "Subcat_Adhesive" */
export type Subcat_Adhesive_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_Adhesive_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_Adhesive_Avg_Fields = {
  __typename?: 'Subcat_Adhesive_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_Adhesive". All fields are combined with a logical 'AND'. */
export type Subcat_Adhesive_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_Adhesive_Bool_Exp>>;
  _not?: Maybe<Subcat_Adhesive_Bool_Exp>;
  _or?: Maybe<Array<Subcat_Adhesive_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_Adhesive" */
export type Subcat_Adhesive_If_Matched = {
  match_columns?: Array<Subcat_Adhesive_Insert_Match_Column>;
  update_columns?: Array<Subcat_Adhesive_Update_Column>;
  where?: Maybe<Subcat_Adhesive_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_Adhesive" */
export type Subcat_Adhesive_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_Adhesive" */
export type Subcat_Adhesive_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_Adhesive" */
export enum Subcat_Adhesive_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_Adhesive_Max_Fields = {
  __typename?: 'Subcat_Adhesive_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_Adhesive_Min_Fields = {
  __typename?: 'Subcat_Adhesive_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_Adhesive" */
export type Subcat_Adhesive_Mutation_Response = {
  __typename?: 'Subcat_Adhesive_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_Adhesive>;
};

/** Ordering options when selecting data from "Subcat_Adhesive". */
export type Subcat_Adhesive_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_Adhesive" */
export enum Subcat_Adhesive_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_Adhesive" */
export type Subcat_Adhesive_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_Adhesive_Stddev_Fields = {
  __typename?: 'Subcat_Adhesive_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_Adhesive_Stddev_Pop_Fields = {
  __typename?: 'Subcat_Adhesive_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_Adhesive_Stddev_Samp_Fields = {
  __typename?: 'Subcat_Adhesive_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_Adhesive_Sum_Fields = {
  __typename?: 'Subcat_Adhesive_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_Adhesive" */
export enum Subcat_Adhesive_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_Adhesive_Var_Pop_Fields = {
  __typename?: 'Subcat_Adhesive_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_Adhesive_Var_Samp_Fields = {
  __typename?: 'Subcat_Adhesive_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_Adhesive_Variance_Fields = {
  __typename?: 'Subcat_Adhesive_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_AirRetarder" */
export type Subcat_AirRetarder = {
  __typename?: 'Subcat_AirRetarder';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_AirRetarder" */
export type Subcat_AirRetarder_Aggregate = {
  __typename?: 'Subcat_AirRetarder_aggregate';
  aggregate?: Maybe<Subcat_AirRetarder_Aggregate_Fields>;
  nodes: Array<Subcat_AirRetarder>;
};

/** aggregate fields of "Subcat_AirRetarder" */
export type Subcat_AirRetarder_Aggregate_Fields = {
  __typename?: 'Subcat_AirRetarder_aggregate_fields';
  avg?: Maybe<Subcat_AirRetarder_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_AirRetarder_Max_Fields>;
  min?: Maybe<Subcat_AirRetarder_Min_Fields>;
  stddev?: Maybe<Subcat_AirRetarder_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_AirRetarder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_AirRetarder_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_AirRetarder_Sum_Fields>;
  var_pop?: Maybe<Subcat_AirRetarder_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_AirRetarder_Var_Samp_Fields>;
  variance?: Maybe<Subcat_AirRetarder_Variance_Fields>;
};


/** aggregate fields of "Subcat_AirRetarder" */
export type Subcat_AirRetarder_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_AirRetarder_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_AirRetarder_Avg_Fields = {
  __typename?: 'Subcat_AirRetarder_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_AirRetarder". All fields are combined with a logical 'AND'. */
export type Subcat_AirRetarder_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_AirRetarder_Bool_Exp>>;
  _not?: Maybe<Subcat_AirRetarder_Bool_Exp>;
  _or?: Maybe<Array<Subcat_AirRetarder_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_AirRetarder" */
export type Subcat_AirRetarder_If_Matched = {
  match_columns?: Array<Subcat_AirRetarder_Insert_Match_Column>;
  update_columns?: Array<Subcat_AirRetarder_Update_Column>;
  where?: Maybe<Subcat_AirRetarder_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_AirRetarder" */
export type Subcat_AirRetarder_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_AirRetarder" */
export type Subcat_AirRetarder_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_AirRetarder" */
export enum Subcat_AirRetarder_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_AirRetarder_Max_Fields = {
  __typename?: 'Subcat_AirRetarder_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_AirRetarder_Min_Fields = {
  __typename?: 'Subcat_AirRetarder_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_AirRetarder" */
export type Subcat_AirRetarder_Mutation_Response = {
  __typename?: 'Subcat_AirRetarder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_AirRetarder>;
};

/** Ordering options when selecting data from "Subcat_AirRetarder". */
export type Subcat_AirRetarder_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_AirRetarder" */
export enum Subcat_AirRetarder_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_AirRetarder" */
export type Subcat_AirRetarder_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_AirRetarder_Stddev_Fields = {
  __typename?: 'Subcat_AirRetarder_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_AirRetarder_Stddev_Pop_Fields = {
  __typename?: 'Subcat_AirRetarder_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_AirRetarder_Stddev_Samp_Fields = {
  __typename?: 'Subcat_AirRetarder_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_AirRetarder_Sum_Fields = {
  __typename?: 'Subcat_AirRetarder_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_AirRetarder" */
export enum Subcat_AirRetarder_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_AirRetarder_Var_Pop_Fields = {
  __typename?: 'Subcat_AirRetarder_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_AirRetarder_Var_Samp_Fields = {
  __typename?: 'Subcat_AirRetarder_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_AirRetarder_Variance_Fields = {
  __typename?: 'Subcat_AirRetarder_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_BaseSheet" */
export type Subcat_BaseSheet = {
  __typename?: 'Subcat_BaseSheet';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_BaseSheet" */
export type Subcat_BaseSheet_Aggregate = {
  __typename?: 'Subcat_BaseSheet_aggregate';
  aggregate?: Maybe<Subcat_BaseSheet_Aggregate_Fields>;
  nodes: Array<Subcat_BaseSheet>;
};

/** aggregate fields of "Subcat_BaseSheet" */
export type Subcat_BaseSheet_Aggregate_Fields = {
  __typename?: 'Subcat_BaseSheet_aggregate_fields';
  avg?: Maybe<Subcat_BaseSheet_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_BaseSheet_Max_Fields>;
  min?: Maybe<Subcat_BaseSheet_Min_Fields>;
  stddev?: Maybe<Subcat_BaseSheet_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_BaseSheet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_BaseSheet_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_BaseSheet_Sum_Fields>;
  var_pop?: Maybe<Subcat_BaseSheet_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_BaseSheet_Var_Samp_Fields>;
  variance?: Maybe<Subcat_BaseSheet_Variance_Fields>;
};


/** aggregate fields of "Subcat_BaseSheet" */
export type Subcat_BaseSheet_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_BaseSheet_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_BaseSheet_Avg_Fields = {
  __typename?: 'Subcat_BaseSheet_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_BaseSheet". All fields are combined with a logical 'AND'. */
export type Subcat_BaseSheet_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_BaseSheet_Bool_Exp>>;
  _not?: Maybe<Subcat_BaseSheet_Bool_Exp>;
  _or?: Maybe<Array<Subcat_BaseSheet_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_BaseSheet" */
export type Subcat_BaseSheet_If_Matched = {
  match_columns?: Array<Subcat_BaseSheet_Insert_Match_Column>;
  update_columns?: Array<Subcat_BaseSheet_Update_Column>;
  where?: Maybe<Subcat_BaseSheet_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_BaseSheet" */
export type Subcat_BaseSheet_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_BaseSheet" */
export type Subcat_BaseSheet_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_BaseSheet" */
export enum Subcat_BaseSheet_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_BaseSheet_Max_Fields = {
  __typename?: 'Subcat_BaseSheet_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_BaseSheet_Min_Fields = {
  __typename?: 'Subcat_BaseSheet_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_BaseSheet" */
export type Subcat_BaseSheet_Mutation_Response = {
  __typename?: 'Subcat_BaseSheet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_BaseSheet>;
};

/** Ordering options when selecting data from "Subcat_BaseSheet". */
export type Subcat_BaseSheet_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_BaseSheet" */
export enum Subcat_BaseSheet_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_BaseSheet" */
export type Subcat_BaseSheet_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_BaseSheet_Stddev_Fields = {
  __typename?: 'Subcat_BaseSheet_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_BaseSheet_Stddev_Pop_Fields = {
  __typename?: 'Subcat_BaseSheet_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_BaseSheet_Stddev_Samp_Fields = {
  __typename?: 'Subcat_BaseSheet_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_BaseSheet_Sum_Fields = {
  __typename?: 'Subcat_BaseSheet_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_BaseSheet" */
export enum Subcat_BaseSheet_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_BaseSheet_Var_Pop_Fields = {
  __typename?: 'Subcat_BaseSheet_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_BaseSheet_Var_Samp_Fields = {
  __typename?: 'Subcat_BaseSheet_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_BaseSheet_Variance_Fields = {
  __typename?: 'Subcat_BaseSheet_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_BattBlanket" */
export type Subcat_BattBlanket = {
  __typename?: 'Subcat_BattBlanket';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_BattBlanket" */
export type Subcat_BattBlanket_Aggregate = {
  __typename?: 'Subcat_BattBlanket_aggregate';
  aggregate?: Maybe<Subcat_BattBlanket_Aggregate_Fields>;
  nodes: Array<Subcat_BattBlanket>;
};

/** aggregate fields of "Subcat_BattBlanket" */
export type Subcat_BattBlanket_Aggregate_Fields = {
  __typename?: 'Subcat_BattBlanket_aggregate_fields';
  avg?: Maybe<Subcat_BattBlanket_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_BattBlanket_Max_Fields>;
  min?: Maybe<Subcat_BattBlanket_Min_Fields>;
  stddev?: Maybe<Subcat_BattBlanket_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_BattBlanket_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_BattBlanket_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_BattBlanket_Sum_Fields>;
  var_pop?: Maybe<Subcat_BattBlanket_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_BattBlanket_Var_Samp_Fields>;
  variance?: Maybe<Subcat_BattBlanket_Variance_Fields>;
};


/** aggregate fields of "Subcat_BattBlanket" */
export type Subcat_BattBlanket_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_BattBlanket_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_BattBlanket_Avg_Fields = {
  __typename?: 'Subcat_BattBlanket_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_BattBlanket". All fields are combined with a logical 'AND'. */
export type Subcat_BattBlanket_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_BattBlanket_Bool_Exp>>;
  _not?: Maybe<Subcat_BattBlanket_Bool_Exp>;
  _or?: Maybe<Array<Subcat_BattBlanket_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_BattBlanket" */
export type Subcat_BattBlanket_If_Matched = {
  match_columns?: Array<Subcat_BattBlanket_Insert_Match_Column>;
  update_columns?: Array<Subcat_BattBlanket_Update_Column>;
  where?: Maybe<Subcat_BattBlanket_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_BattBlanket" */
export type Subcat_BattBlanket_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_BattBlanket" */
export type Subcat_BattBlanket_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_BattBlanket" */
export enum Subcat_BattBlanket_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_BattBlanket_Max_Fields = {
  __typename?: 'Subcat_BattBlanket_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_BattBlanket_Min_Fields = {
  __typename?: 'Subcat_BattBlanket_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_BattBlanket" */
export type Subcat_BattBlanket_Mutation_Response = {
  __typename?: 'Subcat_BattBlanket_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_BattBlanket>;
};

/** Ordering options when selecting data from "Subcat_BattBlanket". */
export type Subcat_BattBlanket_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_BattBlanket" */
export enum Subcat_BattBlanket_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_BattBlanket" */
export type Subcat_BattBlanket_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_BattBlanket_Stddev_Fields = {
  __typename?: 'Subcat_BattBlanket_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_BattBlanket_Stddev_Pop_Fields = {
  __typename?: 'Subcat_BattBlanket_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_BattBlanket_Stddev_Samp_Fields = {
  __typename?: 'Subcat_BattBlanket_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_BattBlanket_Sum_Fields = {
  __typename?: 'Subcat_BattBlanket_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_BattBlanket" */
export enum Subcat_BattBlanket_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_BattBlanket_Var_Pop_Fields = {
  __typename?: 'Subcat_BattBlanket_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_BattBlanket_Var_Samp_Fields = {
  __typename?: 'Subcat_BattBlanket_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_BattBlanket_Variance_Fields = {
  __typename?: 'Subcat_BattBlanket_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_BoardStock" */
export type Subcat_BoardStock = {
  __typename?: 'Subcat_BoardStock';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_BoardStock" */
export type Subcat_BoardStock_Aggregate = {
  __typename?: 'Subcat_BoardStock_aggregate';
  aggregate?: Maybe<Subcat_BoardStock_Aggregate_Fields>;
  nodes: Array<Subcat_BoardStock>;
};

/** aggregate fields of "Subcat_BoardStock" */
export type Subcat_BoardStock_Aggregate_Fields = {
  __typename?: 'Subcat_BoardStock_aggregate_fields';
  avg?: Maybe<Subcat_BoardStock_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_BoardStock_Max_Fields>;
  min?: Maybe<Subcat_BoardStock_Min_Fields>;
  stddev?: Maybe<Subcat_BoardStock_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_BoardStock_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_BoardStock_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_BoardStock_Sum_Fields>;
  var_pop?: Maybe<Subcat_BoardStock_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_BoardStock_Var_Samp_Fields>;
  variance?: Maybe<Subcat_BoardStock_Variance_Fields>;
};


/** aggregate fields of "Subcat_BoardStock" */
export type Subcat_BoardStock_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_BoardStock_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_BoardStock_Avg_Fields = {
  __typename?: 'Subcat_BoardStock_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_BoardStock". All fields are combined with a logical 'AND'. */
export type Subcat_BoardStock_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_BoardStock_Bool_Exp>>;
  _not?: Maybe<Subcat_BoardStock_Bool_Exp>;
  _or?: Maybe<Array<Subcat_BoardStock_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_BoardStock" */
export type Subcat_BoardStock_If_Matched = {
  match_columns?: Array<Subcat_BoardStock_Insert_Match_Column>;
  update_columns?: Array<Subcat_BoardStock_Update_Column>;
  where?: Maybe<Subcat_BoardStock_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_BoardStock" */
export type Subcat_BoardStock_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_BoardStock" */
export type Subcat_BoardStock_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_BoardStock" */
export enum Subcat_BoardStock_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_BoardStock_Max_Fields = {
  __typename?: 'Subcat_BoardStock_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_BoardStock_Min_Fields = {
  __typename?: 'Subcat_BoardStock_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_BoardStock" */
export type Subcat_BoardStock_Mutation_Response = {
  __typename?: 'Subcat_BoardStock_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_BoardStock>;
};

/** Ordering options when selecting data from "Subcat_BoardStock". */
export type Subcat_BoardStock_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_BoardStock" */
export enum Subcat_BoardStock_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_BoardStock" */
export type Subcat_BoardStock_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_BoardStock_Stddev_Fields = {
  __typename?: 'Subcat_BoardStock_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_BoardStock_Stddev_Pop_Fields = {
  __typename?: 'Subcat_BoardStock_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_BoardStock_Stddev_Samp_Fields = {
  __typename?: 'Subcat_BoardStock_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_BoardStock_Sum_Fields = {
  __typename?: 'Subcat_BoardStock_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_BoardStock" */
export enum Subcat_BoardStock_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_BoardStock_Var_Pop_Fields = {
  __typename?: 'Subcat_BoardStock_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_BoardStock_Var_Samp_Fields = {
  __typename?: 'Subcat_BoardStock_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_BoardStock_Variance_Fields = {
  __typename?: 'Subcat_BoardStock_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_Coating" */
export type Subcat_Coating = {
  __typename?: 'Subcat_Coating';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_Coating" */
export type Subcat_Coating_Aggregate = {
  __typename?: 'Subcat_Coating_aggregate';
  aggregate?: Maybe<Subcat_Coating_Aggregate_Fields>;
  nodes: Array<Subcat_Coating>;
};

/** aggregate fields of "Subcat_Coating" */
export type Subcat_Coating_Aggregate_Fields = {
  __typename?: 'Subcat_Coating_aggregate_fields';
  avg?: Maybe<Subcat_Coating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_Coating_Max_Fields>;
  min?: Maybe<Subcat_Coating_Min_Fields>;
  stddev?: Maybe<Subcat_Coating_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_Coating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_Coating_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_Coating_Sum_Fields>;
  var_pop?: Maybe<Subcat_Coating_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_Coating_Var_Samp_Fields>;
  variance?: Maybe<Subcat_Coating_Variance_Fields>;
};


/** aggregate fields of "Subcat_Coating" */
export type Subcat_Coating_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_Coating_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_Coating_Avg_Fields = {
  __typename?: 'Subcat_Coating_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_Coating". All fields are combined with a logical 'AND'. */
export type Subcat_Coating_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_Coating_Bool_Exp>>;
  _not?: Maybe<Subcat_Coating_Bool_Exp>;
  _or?: Maybe<Array<Subcat_Coating_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_Coating" */
export type Subcat_Coating_If_Matched = {
  match_columns?: Array<Subcat_Coating_Insert_Match_Column>;
  update_columns?: Array<Subcat_Coating_Update_Column>;
  where?: Maybe<Subcat_Coating_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_Coating" */
export type Subcat_Coating_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_Coating" */
export type Subcat_Coating_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_Coating" */
export enum Subcat_Coating_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_Coating_Max_Fields = {
  __typename?: 'Subcat_Coating_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_Coating_Min_Fields = {
  __typename?: 'Subcat_Coating_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_Coating" */
export type Subcat_Coating_Mutation_Response = {
  __typename?: 'Subcat_Coating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_Coating>;
};

/** Ordering options when selecting data from "Subcat_Coating". */
export type Subcat_Coating_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_Coating" */
export enum Subcat_Coating_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_Coating" */
export type Subcat_Coating_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_Coating_Stddev_Fields = {
  __typename?: 'Subcat_Coating_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_Coating_Stddev_Pop_Fields = {
  __typename?: 'Subcat_Coating_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_Coating_Stddev_Samp_Fields = {
  __typename?: 'Subcat_Coating_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_Coating_Sum_Fields = {
  __typename?: 'Subcat_Coating_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_Coating" */
export enum Subcat_Coating_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_Coating_Var_Pop_Fields = {
  __typename?: 'Subcat_Coating_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_Coating_Var_Samp_Fields = {
  __typename?: 'Subcat_Coating_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_Coating_Variance_Fields = {
  __typename?: 'Subcat_Coating_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_CompositePanel" */
export type Subcat_CompositePanel = {
  __typename?: 'Subcat_CompositePanel';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_CompositePanel" */
export type Subcat_CompositePanel_Aggregate = {
  __typename?: 'Subcat_CompositePanel_aggregate';
  aggregate?: Maybe<Subcat_CompositePanel_Aggregate_Fields>;
  nodes: Array<Subcat_CompositePanel>;
};

/** aggregate fields of "Subcat_CompositePanel" */
export type Subcat_CompositePanel_Aggregate_Fields = {
  __typename?: 'Subcat_CompositePanel_aggregate_fields';
  avg?: Maybe<Subcat_CompositePanel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_CompositePanel_Max_Fields>;
  min?: Maybe<Subcat_CompositePanel_Min_Fields>;
  stddev?: Maybe<Subcat_CompositePanel_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_CompositePanel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_CompositePanel_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_CompositePanel_Sum_Fields>;
  var_pop?: Maybe<Subcat_CompositePanel_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_CompositePanel_Var_Samp_Fields>;
  variance?: Maybe<Subcat_CompositePanel_Variance_Fields>;
};


/** aggregate fields of "Subcat_CompositePanel" */
export type Subcat_CompositePanel_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_CompositePanel_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_CompositePanel_Avg_Fields = {
  __typename?: 'Subcat_CompositePanel_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_CompositePanel". All fields are combined with a logical 'AND'. */
export type Subcat_CompositePanel_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_CompositePanel_Bool_Exp>>;
  _not?: Maybe<Subcat_CompositePanel_Bool_Exp>;
  _or?: Maybe<Array<Subcat_CompositePanel_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_CompositePanel" */
export type Subcat_CompositePanel_If_Matched = {
  match_columns?: Array<Subcat_CompositePanel_Insert_Match_Column>;
  update_columns?: Array<Subcat_CompositePanel_Update_Column>;
  where?: Maybe<Subcat_CompositePanel_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_CompositePanel" */
export type Subcat_CompositePanel_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_CompositePanel" */
export type Subcat_CompositePanel_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_CompositePanel" */
export enum Subcat_CompositePanel_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_CompositePanel_Max_Fields = {
  __typename?: 'Subcat_CompositePanel_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_CompositePanel_Min_Fields = {
  __typename?: 'Subcat_CompositePanel_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_CompositePanel" */
export type Subcat_CompositePanel_Mutation_Response = {
  __typename?: 'Subcat_CompositePanel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_CompositePanel>;
};

/** Ordering options when selecting data from "Subcat_CompositePanel". */
export type Subcat_CompositePanel_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_CompositePanel" */
export enum Subcat_CompositePanel_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_CompositePanel" */
export type Subcat_CompositePanel_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_CompositePanel_Stddev_Fields = {
  __typename?: 'Subcat_CompositePanel_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_CompositePanel_Stddev_Pop_Fields = {
  __typename?: 'Subcat_CompositePanel_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_CompositePanel_Stddev_Samp_Fields = {
  __typename?: 'Subcat_CompositePanel_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_CompositePanel_Sum_Fields = {
  __typename?: 'Subcat_CompositePanel_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_CompositePanel" */
export enum Subcat_CompositePanel_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_CompositePanel_Var_Pop_Fields = {
  __typename?: 'Subcat_CompositePanel_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_CompositePanel_Var_Samp_Fields = {
  __typename?: 'Subcat_CompositePanel_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_CompositePanel_Variance_Fields = {
  __typename?: 'Subcat_CompositePanel_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_CoverStrip" */
export type Subcat_CoverStrip = {
  __typename?: 'Subcat_CoverStrip';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_CoverStrip" */
export type Subcat_CoverStrip_Aggregate = {
  __typename?: 'Subcat_CoverStrip_aggregate';
  aggregate?: Maybe<Subcat_CoverStrip_Aggregate_Fields>;
  nodes: Array<Subcat_CoverStrip>;
};

/** aggregate fields of "Subcat_CoverStrip" */
export type Subcat_CoverStrip_Aggregate_Fields = {
  __typename?: 'Subcat_CoverStrip_aggregate_fields';
  avg?: Maybe<Subcat_CoverStrip_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_CoverStrip_Max_Fields>;
  min?: Maybe<Subcat_CoverStrip_Min_Fields>;
  stddev?: Maybe<Subcat_CoverStrip_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_CoverStrip_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_CoverStrip_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_CoverStrip_Sum_Fields>;
  var_pop?: Maybe<Subcat_CoverStrip_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_CoverStrip_Var_Samp_Fields>;
  variance?: Maybe<Subcat_CoverStrip_Variance_Fields>;
};


/** aggregate fields of "Subcat_CoverStrip" */
export type Subcat_CoverStrip_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_CoverStrip_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_CoverStrip_Avg_Fields = {
  __typename?: 'Subcat_CoverStrip_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_CoverStrip". All fields are combined with a logical 'AND'. */
export type Subcat_CoverStrip_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_CoverStrip_Bool_Exp>>;
  _not?: Maybe<Subcat_CoverStrip_Bool_Exp>;
  _or?: Maybe<Array<Subcat_CoverStrip_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_CoverStrip" */
export type Subcat_CoverStrip_If_Matched = {
  match_columns?: Array<Subcat_CoverStrip_Insert_Match_Column>;
  update_columns?: Array<Subcat_CoverStrip_Update_Column>;
  where?: Maybe<Subcat_CoverStrip_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_CoverStrip" */
export type Subcat_CoverStrip_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_CoverStrip" */
export type Subcat_CoverStrip_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_CoverStrip" */
export enum Subcat_CoverStrip_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_CoverStrip_Max_Fields = {
  __typename?: 'Subcat_CoverStrip_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_CoverStrip_Min_Fields = {
  __typename?: 'Subcat_CoverStrip_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_CoverStrip" */
export type Subcat_CoverStrip_Mutation_Response = {
  __typename?: 'Subcat_CoverStrip_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_CoverStrip>;
};

/** Ordering options when selecting data from "Subcat_CoverStrip". */
export type Subcat_CoverStrip_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_CoverStrip" */
export enum Subcat_CoverStrip_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_CoverStrip" */
export type Subcat_CoverStrip_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_CoverStrip_Stddev_Fields = {
  __typename?: 'Subcat_CoverStrip_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_CoverStrip_Stddev_Pop_Fields = {
  __typename?: 'Subcat_CoverStrip_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_CoverStrip_Stddev_Samp_Fields = {
  __typename?: 'Subcat_CoverStrip_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_CoverStrip_Sum_Fields = {
  __typename?: 'Subcat_CoverStrip_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_CoverStrip" */
export enum Subcat_CoverStrip_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_CoverStrip_Var_Pop_Fields = {
  __typename?: 'Subcat_CoverStrip_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_CoverStrip_Var_Samp_Fields = {
  __typename?: 'Subcat_CoverStrip_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_CoverStrip_Variance_Fields = {
  __typename?: 'Subcat_CoverStrip_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_ExpJoint" */
export type Subcat_ExpJoint = {
  __typename?: 'Subcat_ExpJoint';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_ExpJoint" */
export type Subcat_ExpJoint_Aggregate = {
  __typename?: 'Subcat_ExpJoint_aggregate';
  aggregate?: Maybe<Subcat_ExpJoint_Aggregate_Fields>;
  nodes: Array<Subcat_ExpJoint>;
};

/** aggregate fields of "Subcat_ExpJoint" */
export type Subcat_ExpJoint_Aggregate_Fields = {
  __typename?: 'Subcat_ExpJoint_aggregate_fields';
  avg?: Maybe<Subcat_ExpJoint_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_ExpJoint_Max_Fields>;
  min?: Maybe<Subcat_ExpJoint_Min_Fields>;
  stddev?: Maybe<Subcat_ExpJoint_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_ExpJoint_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_ExpJoint_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_ExpJoint_Sum_Fields>;
  var_pop?: Maybe<Subcat_ExpJoint_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_ExpJoint_Var_Samp_Fields>;
  variance?: Maybe<Subcat_ExpJoint_Variance_Fields>;
};


/** aggregate fields of "Subcat_ExpJoint" */
export type Subcat_ExpJoint_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_ExpJoint_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_ExpJoint_Avg_Fields = {
  __typename?: 'Subcat_ExpJoint_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_ExpJoint". All fields are combined with a logical 'AND'. */
export type Subcat_ExpJoint_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_ExpJoint_Bool_Exp>>;
  _not?: Maybe<Subcat_ExpJoint_Bool_Exp>;
  _or?: Maybe<Array<Subcat_ExpJoint_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_ExpJoint" */
export type Subcat_ExpJoint_If_Matched = {
  match_columns?: Array<Subcat_ExpJoint_Insert_Match_Column>;
  update_columns?: Array<Subcat_ExpJoint_Update_Column>;
  where?: Maybe<Subcat_ExpJoint_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_ExpJoint" */
export type Subcat_ExpJoint_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_ExpJoint" */
export type Subcat_ExpJoint_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_ExpJoint" */
export enum Subcat_ExpJoint_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_ExpJoint_Max_Fields = {
  __typename?: 'Subcat_ExpJoint_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_ExpJoint_Min_Fields = {
  __typename?: 'Subcat_ExpJoint_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_ExpJoint" */
export type Subcat_ExpJoint_Mutation_Response = {
  __typename?: 'Subcat_ExpJoint_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_ExpJoint>;
};

/** Ordering options when selecting data from "Subcat_ExpJoint". */
export type Subcat_ExpJoint_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_ExpJoint" */
export enum Subcat_ExpJoint_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_ExpJoint" */
export type Subcat_ExpJoint_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_ExpJoint_Stddev_Fields = {
  __typename?: 'Subcat_ExpJoint_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_ExpJoint_Stddev_Pop_Fields = {
  __typename?: 'Subcat_ExpJoint_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_ExpJoint_Stddev_Samp_Fields = {
  __typename?: 'Subcat_ExpJoint_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_ExpJoint_Sum_Fields = {
  __typename?: 'Subcat_ExpJoint_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_ExpJoint" */
export enum Subcat_ExpJoint_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_ExpJoint_Var_Pop_Fields = {
  __typename?: 'Subcat_ExpJoint_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_ExpJoint_Var_Samp_Fields = {
  __typename?: 'Subcat_ExpJoint_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_ExpJoint_Variance_Fields = {
  __typename?: 'Subcat_ExpJoint_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_Fastening" */
export type Subcat_Fastening = {
  __typename?: 'Subcat_Fastening';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_Fastening" */
export type Subcat_Fastening_Aggregate = {
  __typename?: 'Subcat_Fastening_aggregate';
  aggregate?: Maybe<Subcat_Fastening_Aggregate_Fields>;
  nodes: Array<Subcat_Fastening>;
};

/** aggregate fields of "Subcat_Fastening" */
export type Subcat_Fastening_Aggregate_Fields = {
  __typename?: 'Subcat_Fastening_aggregate_fields';
  avg?: Maybe<Subcat_Fastening_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_Fastening_Max_Fields>;
  min?: Maybe<Subcat_Fastening_Min_Fields>;
  stddev?: Maybe<Subcat_Fastening_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_Fastening_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_Fastening_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_Fastening_Sum_Fields>;
  var_pop?: Maybe<Subcat_Fastening_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_Fastening_Var_Samp_Fields>;
  variance?: Maybe<Subcat_Fastening_Variance_Fields>;
};


/** aggregate fields of "Subcat_Fastening" */
export type Subcat_Fastening_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_Fastening_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_Fastening_Avg_Fields = {
  __typename?: 'Subcat_Fastening_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_Fastening". All fields are combined with a logical 'AND'. */
export type Subcat_Fastening_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_Fastening_Bool_Exp>>;
  _not?: Maybe<Subcat_Fastening_Bool_Exp>;
  _or?: Maybe<Array<Subcat_Fastening_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_Fastening" */
export type Subcat_Fastening_If_Matched = {
  match_columns?: Array<Subcat_Fastening_Insert_Match_Column>;
  update_columns?: Array<Subcat_Fastening_Update_Column>;
  where?: Maybe<Subcat_Fastening_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_Fastening" */
export type Subcat_Fastening_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_Fastening" */
export type Subcat_Fastening_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_Fastening" */
export enum Subcat_Fastening_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_Fastening_Max_Fields = {
  __typename?: 'Subcat_Fastening_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_Fastening_Min_Fields = {
  __typename?: 'Subcat_Fastening_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_Fastening" */
export type Subcat_Fastening_Mutation_Response = {
  __typename?: 'Subcat_Fastening_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_Fastening>;
};

/** Ordering options when selecting data from "Subcat_Fastening". */
export type Subcat_Fastening_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_Fastening" */
export enum Subcat_Fastening_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_Fastening" */
export type Subcat_Fastening_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_Fastening_Stddev_Fields = {
  __typename?: 'Subcat_Fastening_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_Fastening_Stddev_Pop_Fields = {
  __typename?: 'Subcat_Fastening_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_Fastening_Stddev_Samp_Fields = {
  __typename?: 'Subcat_Fastening_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_Fastening_Sum_Fields = {
  __typename?: 'Subcat_Fastening_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_Fastening" */
export enum Subcat_Fastening_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_Fastening_Var_Pop_Fields = {
  __typename?: 'Subcat_Fastening_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_Fastening_Var_Samp_Fields = {
  __typename?: 'Subcat_Fastening_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_Fastening_Variance_Fields = {
  __typename?: 'Subcat_Fastening_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_FormDeck" */
export type Subcat_FormDeck = {
  __typename?: 'Subcat_FormDeck';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_FormDeck" */
export type Subcat_FormDeck_Aggregate = {
  __typename?: 'Subcat_FormDeck_aggregate';
  aggregate?: Maybe<Subcat_FormDeck_Aggregate_Fields>;
  nodes: Array<Subcat_FormDeck>;
};

/** aggregate fields of "Subcat_FormDeck" */
export type Subcat_FormDeck_Aggregate_Fields = {
  __typename?: 'Subcat_FormDeck_aggregate_fields';
  avg?: Maybe<Subcat_FormDeck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_FormDeck_Max_Fields>;
  min?: Maybe<Subcat_FormDeck_Min_Fields>;
  stddev?: Maybe<Subcat_FormDeck_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_FormDeck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_FormDeck_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_FormDeck_Sum_Fields>;
  var_pop?: Maybe<Subcat_FormDeck_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_FormDeck_Var_Samp_Fields>;
  variance?: Maybe<Subcat_FormDeck_Variance_Fields>;
};


/** aggregate fields of "Subcat_FormDeck" */
export type Subcat_FormDeck_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_FormDeck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_FormDeck_Avg_Fields = {
  __typename?: 'Subcat_FormDeck_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_FormDeck". All fields are combined with a logical 'AND'. */
export type Subcat_FormDeck_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_FormDeck_Bool_Exp>>;
  _not?: Maybe<Subcat_FormDeck_Bool_Exp>;
  _or?: Maybe<Array<Subcat_FormDeck_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_FormDeck" */
export type Subcat_FormDeck_If_Matched = {
  match_columns?: Array<Subcat_FormDeck_Insert_Match_Column>;
  update_columns?: Array<Subcat_FormDeck_Update_Column>;
  where?: Maybe<Subcat_FormDeck_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_FormDeck" */
export type Subcat_FormDeck_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_FormDeck" */
export type Subcat_FormDeck_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_FormDeck" */
export enum Subcat_FormDeck_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_FormDeck_Max_Fields = {
  __typename?: 'Subcat_FormDeck_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_FormDeck_Min_Fields = {
  __typename?: 'Subcat_FormDeck_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_FormDeck" */
export type Subcat_FormDeck_Mutation_Response = {
  __typename?: 'Subcat_FormDeck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_FormDeck>;
};

/** Ordering options when selecting data from "Subcat_FormDeck". */
export type Subcat_FormDeck_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_FormDeck" */
export enum Subcat_FormDeck_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_FormDeck" */
export type Subcat_FormDeck_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_FormDeck_Stddev_Fields = {
  __typename?: 'Subcat_FormDeck_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_FormDeck_Stddev_Pop_Fields = {
  __typename?: 'Subcat_FormDeck_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_FormDeck_Stddev_Samp_Fields = {
  __typename?: 'Subcat_FormDeck_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_FormDeck_Sum_Fields = {
  __typename?: 'Subcat_FormDeck_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_FormDeck" */
export enum Subcat_FormDeck_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_FormDeck_Var_Pop_Fields = {
  __typename?: 'Subcat_FormDeck_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_FormDeck_Var_Samp_Fields = {
  __typename?: 'Subcat_FormDeck_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_FormDeck_Variance_Fields = {
  __typename?: 'Subcat_FormDeck_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_Gutter" */
export type Subcat_Gutter = {
  __typename?: 'Subcat_Gutter';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_Gutter" */
export type Subcat_Gutter_Aggregate = {
  __typename?: 'Subcat_Gutter_aggregate';
  aggregate?: Maybe<Subcat_Gutter_Aggregate_Fields>;
  nodes: Array<Subcat_Gutter>;
};

/** aggregate fields of "Subcat_Gutter" */
export type Subcat_Gutter_Aggregate_Fields = {
  __typename?: 'Subcat_Gutter_aggregate_fields';
  avg?: Maybe<Subcat_Gutter_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_Gutter_Max_Fields>;
  min?: Maybe<Subcat_Gutter_Min_Fields>;
  stddev?: Maybe<Subcat_Gutter_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_Gutter_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_Gutter_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_Gutter_Sum_Fields>;
  var_pop?: Maybe<Subcat_Gutter_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_Gutter_Var_Samp_Fields>;
  variance?: Maybe<Subcat_Gutter_Variance_Fields>;
};


/** aggregate fields of "Subcat_Gutter" */
export type Subcat_Gutter_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_Gutter_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_Gutter_Avg_Fields = {
  __typename?: 'Subcat_Gutter_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_Gutter". All fields are combined with a logical 'AND'. */
export type Subcat_Gutter_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_Gutter_Bool_Exp>>;
  _not?: Maybe<Subcat_Gutter_Bool_Exp>;
  _or?: Maybe<Array<Subcat_Gutter_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_Gutter" */
export type Subcat_Gutter_If_Matched = {
  match_columns?: Array<Subcat_Gutter_Insert_Match_Column>;
  update_columns?: Array<Subcat_Gutter_Update_Column>;
  where?: Maybe<Subcat_Gutter_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_Gutter" */
export type Subcat_Gutter_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_Gutter" */
export type Subcat_Gutter_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_Gutter" */
export enum Subcat_Gutter_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_Gutter_Max_Fields = {
  __typename?: 'Subcat_Gutter_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_Gutter_Min_Fields = {
  __typename?: 'Subcat_Gutter_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_Gutter" */
export type Subcat_Gutter_Mutation_Response = {
  __typename?: 'Subcat_Gutter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_Gutter>;
};

/** Ordering options when selecting data from "Subcat_Gutter". */
export type Subcat_Gutter_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_Gutter" */
export enum Subcat_Gutter_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_Gutter" */
export type Subcat_Gutter_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_Gutter_Stddev_Fields = {
  __typename?: 'Subcat_Gutter_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_Gutter_Stddev_Pop_Fields = {
  __typename?: 'Subcat_Gutter_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_Gutter_Stddev_Samp_Fields = {
  __typename?: 'Subcat_Gutter_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_Gutter_Sum_Fields = {
  __typename?: 'Subcat_Gutter_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_Gutter" */
export enum Subcat_Gutter_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_Gutter_Var_Pop_Fields = {
  __typename?: 'Subcat_Gutter_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_Gutter_Var_Samp_Fields = {
  __typename?: 'Subcat_Gutter_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_Gutter_Variance_Fields = {
  __typename?: 'Subcat_Gutter_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_Gypsum" */
export type Subcat_Gypsum = {
  __typename?: 'Subcat_Gypsum';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_Gypsum" */
export type Subcat_Gypsum_Aggregate = {
  __typename?: 'Subcat_Gypsum_aggregate';
  aggregate?: Maybe<Subcat_Gypsum_Aggregate_Fields>;
  nodes: Array<Subcat_Gypsum>;
};

/** aggregate fields of "Subcat_Gypsum" */
export type Subcat_Gypsum_Aggregate_Fields = {
  __typename?: 'Subcat_Gypsum_aggregate_fields';
  avg?: Maybe<Subcat_Gypsum_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_Gypsum_Max_Fields>;
  min?: Maybe<Subcat_Gypsum_Min_Fields>;
  stddev?: Maybe<Subcat_Gypsum_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_Gypsum_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_Gypsum_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_Gypsum_Sum_Fields>;
  var_pop?: Maybe<Subcat_Gypsum_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_Gypsum_Var_Samp_Fields>;
  variance?: Maybe<Subcat_Gypsum_Variance_Fields>;
};


/** aggregate fields of "Subcat_Gypsum" */
export type Subcat_Gypsum_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_Gypsum_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_Gypsum_Avg_Fields = {
  __typename?: 'Subcat_Gypsum_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_Gypsum". All fields are combined with a logical 'AND'. */
export type Subcat_Gypsum_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_Gypsum_Bool_Exp>>;
  _not?: Maybe<Subcat_Gypsum_Bool_Exp>;
  _or?: Maybe<Array<Subcat_Gypsum_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_Gypsum" */
export type Subcat_Gypsum_If_Matched = {
  match_columns?: Array<Subcat_Gypsum_Insert_Match_Column>;
  update_columns?: Array<Subcat_Gypsum_Update_Column>;
  where?: Maybe<Subcat_Gypsum_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_Gypsum" */
export type Subcat_Gypsum_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_Gypsum" */
export type Subcat_Gypsum_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_Gypsum" */
export enum Subcat_Gypsum_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_Gypsum_Max_Fields = {
  __typename?: 'Subcat_Gypsum_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_Gypsum_Min_Fields = {
  __typename?: 'Subcat_Gypsum_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_Gypsum" */
export type Subcat_Gypsum_Mutation_Response = {
  __typename?: 'Subcat_Gypsum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_Gypsum>;
};

/** Ordering options when selecting data from "Subcat_Gypsum". */
export type Subcat_Gypsum_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_Gypsum" */
export enum Subcat_Gypsum_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_Gypsum" */
export type Subcat_Gypsum_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_Gypsum_Stddev_Fields = {
  __typename?: 'Subcat_Gypsum_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_Gypsum_Stddev_Pop_Fields = {
  __typename?: 'Subcat_Gypsum_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_Gypsum_Stddev_Samp_Fields = {
  __typename?: 'Subcat_Gypsum_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_Gypsum_Sum_Fields = {
  __typename?: 'Subcat_Gypsum_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_Gypsum" */
export enum Subcat_Gypsum_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_Gypsum_Var_Pop_Fields = {
  __typename?: 'Subcat_Gypsum_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_Gypsum_Var_Samp_Fields = {
  __typename?: 'Subcat_Gypsum_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_Gypsum_Variance_Fields = {
  __typename?: 'Subcat_Gypsum_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_LWIC" */
export type Subcat_Lwic = {
  __typename?: 'Subcat_LWIC';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** columns and relationships of "Subcat_LWICDeck" */
export type Subcat_LwicDeck = {
  __typename?: 'Subcat_LWICDeck';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_LWICDeck" */
export type Subcat_LwicDeck_Aggregate = {
  __typename?: 'Subcat_LWICDeck_aggregate';
  aggregate?: Maybe<Subcat_LwicDeck_Aggregate_Fields>;
  nodes: Array<Subcat_LwicDeck>;
};

/** aggregate fields of "Subcat_LWICDeck" */
export type Subcat_LwicDeck_Aggregate_Fields = {
  __typename?: 'Subcat_LWICDeck_aggregate_fields';
  avg?: Maybe<Subcat_LwicDeck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_LwicDeck_Max_Fields>;
  min?: Maybe<Subcat_LwicDeck_Min_Fields>;
  stddev?: Maybe<Subcat_LwicDeck_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_LwicDeck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_LwicDeck_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_LwicDeck_Sum_Fields>;
  var_pop?: Maybe<Subcat_LwicDeck_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_LwicDeck_Var_Samp_Fields>;
  variance?: Maybe<Subcat_LwicDeck_Variance_Fields>;
};


/** aggregate fields of "Subcat_LWICDeck" */
export type Subcat_LwicDeck_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_LwicDeck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_LwicDeck_Avg_Fields = {
  __typename?: 'Subcat_LWICDeck_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_LWICDeck". All fields are combined with a logical 'AND'. */
export type Subcat_LwicDeck_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_LwicDeck_Bool_Exp>>;
  _not?: Maybe<Subcat_LwicDeck_Bool_Exp>;
  _or?: Maybe<Array<Subcat_LwicDeck_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_LWICDeck" */
export type Subcat_LwicDeck_If_Matched = {
  match_columns?: Array<Subcat_LwicDeck_Insert_Match_Column>;
  update_columns?: Array<Subcat_LwicDeck_Update_Column>;
  where?: Maybe<Subcat_LwicDeck_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_LWICDeck" */
export type Subcat_LwicDeck_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_LWICDeck" */
export type Subcat_LwicDeck_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_LWICDeck" */
export enum Subcat_LwicDeck_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_LwicDeck_Max_Fields = {
  __typename?: 'Subcat_LWICDeck_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_LwicDeck_Min_Fields = {
  __typename?: 'Subcat_LWICDeck_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_LWICDeck" */
export type Subcat_LwicDeck_Mutation_Response = {
  __typename?: 'Subcat_LWICDeck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_LwicDeck>;
};

/** Ordering options when selecting data from "Subcat_LWICDeck". */
export type Subcat_LwicDeck_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_LWICDeck" */
export enum Subcat_LwicDeck_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_LWICDeck" */
export type Subcat_LwicDeck_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_LwicDeck_Stddev_Fields = {
  __typename?: 'Subcat_LWICDeck_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_LwicDeck_Stddev_Pop_Fields = {
  __typename?: 'Subcat_LWICDeck_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_LwicDeck_Stddev_Samp_Fields = {
  __typename?: 'Subcat_LWICDeck_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_LwicDeck_Sum_Fields = {
  __typename?: 'Subcat_LWICDeck_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_LWICDeck" */
export enum Subcat_LwicDeck_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_LwicDeck_Var_Pop_Fields = {
  __typename?: 'Subcat_LWICDeck_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_LwicDeck_Var_Samp_Fields = {
  __typename?: 'Subcat_LWICDeck_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_LwicDeck_Variance_Fields = {
  __typename?: 'Subcat_LWICDeck_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Subcat_LWIC" */
export type Subcat_Lwic_Aggregate = {
  __typename?: 'Subcat_LWIC_aggregate';
  aggregate?: Maybe<Subcat_Lwic_Aggregate_Fields>;
  nodes: Array<Subcat_Lwic>;
};

/** aggregate fields of "Subcat_LWIC" */
export type Subcat_Lwic_Aggregate_Fields = {
  __typename?: 'Subcat_LWIC_aggregate_fields';
  avg?: Maybe<Subcat_Lwic_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_Lwic_Max_Fields>;
  min?: Maybe<Subcat_Lwic_Min_Fields>;
  stddev?: Maybe<Subcat_Lwic_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_Lwic_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_Lwic_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_Lwic_Sum_Fields>;
  var_pop?: Maybe<Subcat_Lwic_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_Lwic_Var_Samp_Fields>;
  variance?: Maybe<Subcat_Lwic_Variance_Fields>;
};


/** aggregate fields of "Subcat_LWIC" */
export type Subcat_Lwic_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_Lwic_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_Lwic_Avg_Fields = {
  __typename?: 'Subcat_LWIC_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_LWIC". All fields are combined with a logical 'AND'. */
export type Subcat_Lwic_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_Lwic_Bool_Exp>>;
  _not?: Maybe<Subcat_Lwic_Bool_Exp>;
  _or?: Maybe<Array<Subcat_Lwic_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_LWIC" */
export type Subcat_Lwic_If_Matched = {
  match_columns?: Array<Subcat_Lwic_Insert_Match_Column>;
  update_columns?: Array<Subcat_Lwic_Update_Column>;
  where?: Maybe<Subcat_Lwic_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_LWIC" */
export type Subcat_Lwic_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_LWIC" */
export type Subcat_Lwic_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_LWIC" */
export enum Subcat_Lwic_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_Lwic_Max_Fields = {
  __typename?: 'Subcat_LWIC_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_Lwic_Min_Fields = {
  __typename?: 'Subcat_LWIC_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_LWIC" */
export type Subcat_Lwic_Mutation_Response = {
  __typename?: 'Subcat_LWIC_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_Lwic>;
};

/** Ordering options when selecting data from "Subcat_LWIC". */
export type Subcat_Lwic_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_LWIC" */
export enum Subcat_Lwic_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_LWIC" */
export type Subcat_Lwic_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_Lwic_Stddev_Fields = {
  __typename?: 'Subcat_LWIC_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_Lwic_Stddev_Pop_Fields = {
  __typename?: 'Subcat_LWIC_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_Lwic_Stddev_Samp_Fields = {
  __typename?: 'Subcat_LWIC_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_Lwic_Sum_Fields = {
  __typename?: 'Subcat_LWIC_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_LWIC" */
export enum Subcat_Lwic_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_Lwic_Var_Pop_Fields = {
  __typename?: 'Subcat_LWIC_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_Lwic_Var_Samp_Fields = {
  __typename?: 'Subcat_LWIC_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_Lwic_Variance_Fields = {
  __typename?: 'Subcat_LWIC_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_LinerPanel" */
export type Subcat_LinerPanel = {
  __typename?: 'Subcat_LinerPanel';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_LinerPanel" */
export type Subcat_LinerPanel_Aggregate = {
  __typename?: 'Subcat_LinerPanel_aggregate';
  aggregate?: Maybe<Subcat_LinerPanel_Aggregate_Fields>;
  nodes: Array<Subcat_LinerPanel>;
};

/** aggregate fields of "Subcat_LinerPanel" */
export type Subcat_LinerPanel_Aggregate_Fields = {
  __typename?: 'Subcat_LinerPanel_aggregate_fields';
  avg?: Maybe<Subcat_LinerPanel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_LinerPanel_Max_Fields>;
  min?: Maybe<Subcat_LinerPanel_Min_Fields>;
  stddev?: Maybe<Subcat_LinerPanel_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_LinerPanel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_LinerPanel_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_LinerPanel_Sum_Fields>;
  var_pop?: Maybe<Subcat_LinerPanel_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_LinerPanel_Var_Samp_Fields>;
  variance?: Maybe<Subcat_LinerPanel_Variance_Fields>;
};


/** aggregate fields of "Subcat_LinerPanel" */
export type Subcat_LinerPanel_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_LinerPanel_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_LinerPanel_Avg_Fields = {
  __typename?: 'Subcat_LinerPanel_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_LinerPanel". All fields are combined with a logical 'AND'. */
export type Subcat_LinerPanel_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_LinerPanel_Bool_Exp>>;
  _not?: Maybe<Subcat_LinerPanel_Bool_Exp>;
  _or?: Maybe<Array<Subcat_LinerPanel_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_LinerPanel" */
export type Subcat_LinerPanel_If_Matched = {
  match_columns?: Array<Subcat_LinerPanel_Insert_Match_Column>;
  update_columns?: Array<Subcat_LinerPanel_Update_Column>;
  where?: Maybe<Subcat_LinerPanel_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_LinerPanel" */
export type Subcat_LinerPanel_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_LinerPanel" */
export type Subcat_LinerPanel_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_LinerPanel" */
export enum Subcat_LinerPanel_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_LinerPanel_Max_Fields = {
  __typename?: 'Subcat_LinerPanel_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_LinerPanel_Min_Fields = {
  __typename?: 'Subcat_LinerPanel_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_LinerPanel" */
export type Subcat_LinerPanel_Mutation_Response = {
  __typename?: 'Subcat_LinerPanel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_LinerPanel>;
};

/** Ordering options when selecting data from "Subcat_LinerPanel". */
export type Subcat_LinerPanel_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_LinerPanel" */
export enum Subcat_LinerPanel_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_LinerPanel" */
export type Subcat_LinerPanel_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_LinerPanel_Stddev_Fields = {
  __typename?: 'Subcat_LinerPanel_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_LinerPanel_Stddev_Pop_Fields = {
  __typename?: 'Subcat_LinerPanel_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_LinerPanel_Stddev_Samp_Fields = {
  __typename?: 'Subcat_LinerPanel_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_LinerPanel_Sum_Fields = {
  __typename?: 'Subcat_LinerPanel_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_LinerPanel" */
export enum Subcat_LinerPanel_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_LinerPanel_Var_Pop_Fields = {
  __typename?: 'Subcat_LinerPanel_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_LinerPanel_Var_Samp_Fields = {
  __typename?: 'Subcat_LinerPanel_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_LinerPanel_Variance_Fields = {
  __typename?: 'Subcat_LinerPanel_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_LiquidApplied" */
export type Subcat_LiquidApplied = {
  __typename?: 'Subcat_LiquidApplied';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_LiquidApplied" */
export type Subcat_LiquidApplied_Aggregate = {
  __typename?: 'Subcat_LiquidApplied_aggregate';
  aggregate?: Maybe<Subcat_LiquidApplied_Aggregate_Fields>;
  nodes: Array<Subcat_LiquidApplied>;
};

/** aggregate fields of "Subcat_LiquidApplied" */
export type Subcat_LiquidApplied_Aggregate_Fields = {
  __typename?: 'Subcat_LiquidApplied_aggregate_fields';
  avg?: Maybe<Subcat_LiquidApplied_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_LiquidApplied_Max_Fields>;
  min?: Maybe<Subcat_LiquidApplied_Min_Fields>;
  stddev?: Maybe<Subcat_LiquidApplied_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_LiquidApplied_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_LiquidApplied_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_LiquidApplied_Sum_Fields>;
  var_pop?: Maybe<Subcat_LiquidApplied_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_LiquidApplied_Var_Samp_Fields>;
  variance?: Maybe<Subcat_LiquidApplied_Variance_Fields>;
};


/** aggregate fields of "Subcat_LiquidApplied" */
export type Subcat_LiquidApplied_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_LiquidApplied_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_LiquidApplied_Avg_Fields = {
  __typename?: 'Subcat_LiquidApplied_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_LiquidApplied". All fields are combined with a logical 'AND'. */
export type Subcat_LiquidApplied_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_LiquidApplied_Bool_Exp>>;
  _not?: Maybe<Subcat_LiquidApplied_Bool_Exp>;
  _or?: Maybe<Array<Subcat_LiquidApplied_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_LiquidApplied" */
export type Subcat_LiquidApplied_If_Matched = {
  match_columns?: Array<Subcat_LiquidApplied_Insert_Match_Column>;
  update_columns?: Array<Subcat_LiquidApplied_Update_Column>;
  where?: Maybe<Subcat_LiquidApplied_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_LiquidApplied" */
export type Subcat_LiquidApplied_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_LiquidApplied" */
export type Subcat_LiquidApplied_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_LiquidApplied" */
export enum Subcat_LiquidApplied_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_LiquidApplied_Max_Fields = {
  __typename?: 'Subcat_LiquidApplied_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_LiquidApplied_Min_Fields = {
  __typename?: 'Subcat_LiquidApplied_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_LiquidApplied" */
export type Subcat_LiquidApplied_Mutation_Response = {
  __typename?: 'Subcat_LiquidApplied_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_LiquidApplied>;
};

/** Ordering options when selecting data from "Subcat_LiquidApplied". */
export type Subcat_LiquidApplied_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_LiquidApplied" */
export enum Subcat_LiquidApplied_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_LiquidApplied" */
export type Subcat_LiquidApplied_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_LiquidApplied_Stddev_Fields = {
  __typename?: 'Subcat_LiquidApplied_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_LiquidApplied_Stddev_Pop_Fields = {
  __typename?: 'Subcat_LiquidApplied_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_LiquidApplied_Stddev_Samp_Fields = {
  __typename?: 'Subcat_LiquidApplied_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_LiquidApplied_Sum_Fields = {
  __typename?: 'Subcat_LiquidApplied_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_LiquidApplied" */
export enum Subcat_LiquidApplied_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_LiquidApplied_Var_Pop_Fields = {
  __typename?: 'Subcat_LiquidApplied_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_LiquidApplied_Var_Samp_Fields = {
  __typename?: 'Subcat_LiquidApplied_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_LiquidApplied_Variance_Fields = {
  __typename?: 'Subcat_LiquidApplied_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_MaintCoating" */
export type Subcat_MaintCoating = {
  __typename?: 'Subcat_MaintCoating';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_MaintCoating" */
export type Subcat_MaintCoating_Aggregate = {
  __typename?: 'Subcat_MaintCoating_aggregate';
  aggregate?: Maybe<Subcat_MaintCoating_Aggregate_Fields>;
  nodes: Array<Subcat_MaintCoating>;
};

/** aggregate fields of "Subcat_MaintCoating" */
export type Subcat_MaintCoating_Aggregate_Fields = {
  __typename?: 'Subcat_MaintCoating_aggregate_fields';
  avg?: Maybe<Subcat_MaintCoating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_MaintCoating_Max_Fields>;
  min?: Maybe<Subcat_MaintCoating_Min_Fields>;
  stddev?: Maybe<Subcat_MaintCoating_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_MaintCoating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_MaintCoating_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_MaintCoating_Sum_Fields>;
  var_pop?: Maybe<Subcat_MaintCoating_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_MaintCoating_Var_Samp_Fields>;
  variance?: Maybe<Subcat_MaintCoating_Variance_Fields>;
};


/** aggregate fields of "Subcat_MaintCoating" */
export type Subcat_MaintCoating_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_MaintCoating_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_MaintCoating_Avg_Fields = {
  __typename?: 'Subcat_MaintCoating_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_MaintCoating". All fields are combined with a logical 'AND'. */
export type Subcat_MaintCoating_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_MaintCoating_Bool_Exp>>;
  _not?: Maybe<Subcat_MaintCoating_Bool_Exp>;
  _or?: Maybe<Array<Subcat_MaintCoating_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_MaintCoating" */
export type Subcat_MaintCoating_If_Matched = {
  match_columns?: Array<Subcat_MaintCoating_Insert_Match_Column>;
  update_columns?: Array<Subcat_MaintCoating_Update_Column>;
  where?: Maybe<Subcat_MaintCoating_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_MaintCoating" */
export type Subcat_MaintCoating_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_MaintCoating" */
export type Subcat_MaintCoating_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_MaintCoating" */
export enum Subcat_MaintCoating_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_MaintCoating_Max_Fields = {
  __typename?: 'Subcat_MaintCoating_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_MaintCoating_Min_Fields = {
  __typename?: 'Subcat_MaintCoating_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_MaintCoating" */
export type Subcat_MaintCoating_Mutation_Response = {
  __typename?: 'Subcat_MaintCoating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_MaintCoating>;
};

/** Ordering options when selecting data from "Subcat_MaintCoating". */
export type Subcat_MaintCoating_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_MaintCoating" */
export enum Subcat_MaintCoating_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_MaintCoating" */
export type Subcat_MaintCoating_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_MaintCoating_Stddev_Fields = {
  __typename?: 'Subcat_MaintCoating_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_MaintCoating_Stddev_Pop_Fields = {
  __typename?: 'Subcat_MaintCoating_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_MaintCoating_Stddev_Samp_Fields = {
  __typename?: 'Subcat_MaintCoating_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_MaintCoating_Sum_Fields = {
  __typename?: 'Subcat_MaintCoating_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_MaintCoating" */
export enum Subcat_MaintCoating_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_MaintCoating_Var_Pop_Fields = {
  __typename?: 'Subcat_MaintCoating_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_MaintCoating_Var_Samp_Fields = {
  __typename?: 'Subcat_MaintCoating_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_MaintCoating_Variance_Fields = {
  __typename?: 'Subcat_MaintCoating_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_Multiply" */
export type Subcat_Multiply = {
  __typename?: 'Subcat_Multiply';
  ComponentStatusId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** columns and relationships of "Subcat_MultiplyCover" */
export type Subcat_MultiplyCover = {
  __typename?: 'Subcat_MultiplyCover';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_MultiplyCover" */
export type Subcat_MultiplyCover_Aggregate = {
  __typename?: 'Subcat_MultiplyCover_aggregate';
  aggregate?: Maybe<Subcat_MultiplyCover_Aggregate_Fields>;
  nodes: Array<Subcat_MultiplyCover>;
};

/** aggregate fields of "Subcat_MultiplyCover" */
export type Subcat_MultiplyCover_Aggregate_Fields = {
  __typename?: 'Subcat_MultiplyCover_aggregate_fields';
  avg?: Maybe<Subcat_MultiplyCover_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_MultiplyCover_Max_Fields>;
  min?: Maybe<Subcat_MultiplyCover_Min_Fields>;
  stddev?: Maybe<Subcat_MultiplyCover_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_MultiplyCover_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_MultiplyCover_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_MultiplyCover_Sum_Fields>;
  var_pop?: Maybe<Subcat_MultiplyCover_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_MultiplyCover_Var_Samp_Fields>;
  variance?: Maybe<Subcat_MultiplyCover_Variance_Fields>;
};


/** aggregate fields of "Subcat_MultiplyCover" */
export type Subcat_MultiplyCover_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_MultiplyCover_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_MultiplyCover_Avg_Fields = {
  __typename?: 'Subcat_MultiplyCover_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_MultiplyCover". All fields are combined with a logical 'AND'. */
export type Subcat_MultiplyCover_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_MultiplyCover_Bool_Exp>>;
  _not?: Maybe<Subcat_MultiplyCover_Bool_Exp>;
  _or?: Maybe<Array<Subcat_MultiplyCover_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_MultiplyCover" */
export type Subcat_MultiplyCover_If_Matched = {
  match_columns?: Array<Subcat_MultiplyCover_Insert_Match_Column>;
  update_columns?: Array<Subcat_MultiplyCover_Update_Column>;
  where?: Maybe<Subcat_MultiplyCover_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_MultiplyCover" */
export type Subcat_MultiplyCover_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_MultiplyCover" */
export type Subcat_MultiplyCover_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_MultiplyCover" */
export enum Subcat_MultiplyCover_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_MultiplyCover_Max_Fields = {
  __typename?: 'Subcat_MultiplyCover_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_MultiplyCover_Min_Fields = {
  __typename?: 'Subcat_MultiplyCover_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_MultiplyCover" */
export type Subcat_MultiplyCover_Mutation_Response = {
  __typename?: 'Subcat_MultiplyCover_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_MultiplyCover>;
};

/** Ordering options when selecting data from "Subcat_MultiplyCover". */
export type Subcat_MultiplyCover_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_MultiplyCover" */
export enum Subcat_MultiplyCover_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_MultiplyCover" */
export type Subcat_MultiplyCover_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_MultiplyCover_Stddev_Fields = {
  __typename?: 'Subcat_MultiplyCover_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_MultiplyCover_Stddev_Pop_Fields = {
  __typename?: 'Subcat_MultiplyCover_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_MultiplyCover_Stddev_Samp_Fields = {
  __typename?: 'Subcat_MultiplyCover_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_MultiplyCover_Sum_Fields = {
  __typename?: 'Subcat_MultiplyCover_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_MultiplyCover" */
export enum Subcat_MultiplyCover_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_MultiplyCover_Var_Pop_Fields = {
  __typename?: 'Subcat_MultiplyCover_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_MultiplyCover_Var_Samp_Fields = {
  __typename?: 'Subcat_MultiplyCover_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_MultiplyCover_Variance_Fields = {
  __typename?: 'Subcat_MultiplyCover_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Subcat_Multiply" */
export type Subcat_Multiply_Aggregate = {
  __typename?: 'Subcat_Multiply_aggregate';
  aggregate?: Maybe<Subcat_Multiply_Aggregate_Fields>;
  nodes: Array<Subcat_Multiply>;
};

/** aggregate fields of "Subcat_Multiply" */
export type Subcat_Multiply_Aggregate_Fields = {
  __typename?: 'Subcat_Multiply_aggregate_fields';
  avg?: Maybe<Subcat_Multiply_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_Multiply_Max_Fields>;
  min?: Maybe<Subcat_Multiply_Min_Fields>;
  stddev?: Maybe<Subcat_Multiply_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_Multiply_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_Multiply_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_Multiply_Sum_Fields>;
  var_pop?: Maybe<Subcat_Multiply_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_Multiply_Var_Samp_Fields>;
  variance?: Maybe<Subcat_Multiply_Variance_Fields>;
};


/** aggregate fields of "Subcat_Multiply" */
export type Subcat_Multiply_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_Multiply_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_Multiply_Avg_Fields = {
  __typename?: 'Subcat_Multiply_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_Multiply". All fields are combined with a logical 'AND'. */
export type Subcat_Multiply_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_Multiply_Bool_Exp>>;
  _not?: Maybe<Subcat_Multiply_Bool_Exp>;
  _or?: Maybe<Array<Subcat_Multiply_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_Multiply" */
export type Subcat_Multiply_If_Matched = {
  match_columns?: Array<Subcat_Multiply_Insert_Match_Column>;
  update_columns?: Array<Subcat_Multiply_Update_Column>;
  where?: Maybe<Subcat_Multiply_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_Multiply" */
export type Subcat_Multiply_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_Multiply" */
export type Subcat_Multiply_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_Multiply" */
export enum Subcat_Multiply_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_Multiply_Max_Fields = {
  __typename?: 'Subcat_Multiply_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_Multiply_Min_Fields = {
  __typename?: 'Subcat_Multiply_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_Multiply" */
export type Subcat_Multiply_Mutation_Response = {
  __typename?: 'Subcat_Multiply_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_Multiply>;
};

/** Ordering options when selecting data from "Subcat_Multiply". */
export type Subcat_Multiply_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_Multiply" */
export enum Subcat_Multiply_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_Multiply" */
export type Subcat_Multiply_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_Multiply_Stddev_Fields = {
  __typename?: 'Subcat_Multiply_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_Multiply_Stddev_Pop_Fields = {
  __typename?: 'Subcat_Multiply_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_Multiply_Stddev_Samp_Fields = {
  __typename?: 'Subcat_Multiply_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_Multiply_Sum_Fields = {
  __typename?: 'Subcat_Multiply_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_Multiply" */
export enum Subcat_Multiply_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_Multiply_Var_Pop_Fields = {
  __typename?: 'Subcat_Multiply_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_Multiply_Var_Samp_Fields = {
  __typename?: 'Subcat_Multiply_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_Multiply_Variance_Fields = {
  __typename?: 'Subcat_Multiply_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_PerimeterFlashing" */
export type Subcat_PerimeterFlashing = {
  __typename?: 'Subcat_PerimeterFlashing';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_PerimeterFlashing" */
export type Subcat_PerimeterFlashing_Aggregate = {
  __typename?: 'Subcat_PerimeterFlashing_aggregate';
  aggregate?: Maybe<Subcat_PerimeterFlashing_Aggregate_Fields>;
  nodes: Array<Subcat_PerimeterFlashing>;
};

/** aggregate fields of "Subcat_PerimeterFlashing" */
export type Subcat_PerimeterFlashing_Aggregate_Fields = {
  __typename?: 'Subcat_PerimeterFlashing_aggregate_fields';
  avg?: Maybe<Subcat_PerimeterFlashing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_PerimeterFlashing_Max_Fields>;
  min?: Maybe<Subcat_PerimeterFlashing_Min_Fields>;
  stddev?: Maybe<Subcat_PerimeterFlashing_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_PerimeterFlashing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_PerimeterFlashing_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_PerimeterFlashing_Sum_Fields>;
  var_pop?: Maybe<Subcat_PerimeterFlashing_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_PerimeterFlashing_Var_Samp_Fields>;
  variance?: Maybe<Subcat_PerimeterFlashing_Variance_Fields>;
};


/** aggregate fields of "Subcat_PerimeterFlashing" */
export type Subcat_PerimeterFlashing_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_PerimeterFlashing_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_PerimeterFlashing_Avg_Fields = {
  __typename?: 'Subcat_PerimeterFlashing_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_PerimeterFlashing". All fields are combined with a logical 'AND'. */
export type Subcat_PerimeterFlashing_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_PerimeterFlashing_Bool_Exp>>;
  _not?: Maybe<Subcat_PerimeterFlashing_Bool_Exp>;
  _or?: Maybe<Array<Subcat_PerimeterFlashing_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_PerimeterFlashing" */
export type Subcat_PerimeterFlashing_If_Matched = {
  match_columns?: Array<Subcat_PerimeterFlashing_Insert_Match_Column>;
  update_columns?: Array<Subcat_PerimeterFlashing_Update_Column>;
  where?: Maybe<Subcat_PerimeterFlashing_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_PerimeterFlashing" */
export type Subcat_PerimeterFlashing_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_PerimeterFlashing" */
export type Subcat_PerimeterFlashing_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_PerimeterFlashing" */
export enum Subcat_PerimeterFlashing_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_PerimeterFlashing_Max_Fields = {
  __typename?: 'Subcat_PerimeterFlashing_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_PerimeterFlashing_Min_Fields = {
  __typename?: 'Subcat_PerimeterFlashing_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_PerimeterFlashing" */
export type Subcat_PerimeterFlashing_Mutation_Response = {
  __typename?: 'Subcat_PerimeterFlashing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_PerimeterFlashing>;
};

/** Ordering options when selecting data from "Subcat_PerimeterFlashing". */
export type Subcat_PerimeterFlashing_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_PerimeterFlashing" */
export enum Subcat_PerimeterFlashing_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_PerimeterFlashing" */
export type Subcat_PerimeterFlashing_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_PerimeterFlashing_Stddev_Fields = {
  __typename?: 'Subcat_PerimeterFlashing_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_PerimeterFlashing_Stddev_Pop_Fields = {
  __typename?: 'Subcat_PerimeterFlashing_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_PerimeterFlashing_Stddev_Samp_Fields = {
  __typename?: 'Subcat_PerimeterFlashing_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_PerimeterFlashing_Sum_Fields = {
  __typename?: 'Subcat_PerimeterFlashing_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_PerimeterFlashing" */
export enum Subcat_PerimeterFlashing_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_PerimeterFlashing_Var_Pop_Fields = {
  __typename?: 'Subcat_PerimeterFlashing_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_PerimeterFlashing_Var_Samp_Fields = {
  __typename?: 'Subcat_PerimeterFlashing_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_PerimeterFlashing_Variance_Fields = {
  __typename?: 'Subcat_PerimeterFlashing_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_PlasticDeck" */
export type Subcat_PlasticDeck = {
  __typename?: 'Subcat_PlasticDeck';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_PlasticDeck" */
export type Subcat_PlasticDeck_Aggregate = {
  __typename?: 'Subcat_PlasticDeck_aggregate';
  aggregate?: Maybe<Subcat_PlasticDeck_Aggregate_Fields>;
  nodes: Array<Subcat_PlasticDeck>;
};

/** aggregate fields of "Subcat_PlasticDeck" */
export type Subcat_PlasticDeck_Aggregate_Fields = {
  __typename?: 'Subcat_PlasticDeck_aggregate_fields';
  avg?: Maybe<Subcat_PlasticDeck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_PlasticDeck_Max_Fields>;
  min?: Maybe<Subcat_PlasticDeck_Min_Fields>;
  stddev?: Maybe<Subcat_PlasticDeck_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_PlasticDeck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_PlasticDeck_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_PlasticDeck_Sum_Fields>;
  var_pop?: Maybe<Subcat_PlasticDeck_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_PlasticDeck_Var_Samp_Fields>;
  variance?: Maybe<Subcat_PlasticDeck_Variance_Fields>;
};


/** aggregate fields of "Subcat_PlasticDeck" */
export type Subcat_PlasticDeck_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_PlasticDeck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_PlasticDeck_Avg_Fields = {
  __typename?: 'Subcat_PlasticDeck_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_PlasticDeck". All fields are combined with a logical 'AND'. */
export type Subcat_PlasticDeck_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_PlasticDeck_Bool_Exp>>;
  _not?: Maybe<Subcat_PlasticDeck_Bool_Exp>;
  _or?: Maybe<Array<Subcat_PlasticDeck_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_PlasticDeck" */
export type Subcat_PlasticDeck_If_Matched = {
  match_columns?: Array<Subcat_PlasticDeck_Insert_Match_Column>;
  update_columns?: Array<Subcat_PlasticDeck_Update_Column>;
  where?: Maybe<Subcat_PlasticDeck_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_PlasticDeck" */
export type Subcat_PlasticDeck_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_PlasticDeck" */
export type Subcat_PlasticDeck_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_PlasticDeck" */
export enum Subcat_PlasticDeck_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_PlasticDeck_Max_Fields = {
  __typename?: 'Subcat_PlasticDeck_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_PlasticDeck_Min_Fields = {
  __typename?: 'Subcat_PlasticDeck_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_PlasticDeck" */
export type Subcat_PlasticDeck_Mutation_Response = {
  __typename?: 'Subcat_PlasticDeck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_PlasticDeck>;
};

/** Ordering options when selecting data from "Subcat_PlasticDeck". */
export type Subcat_PlasticDeck_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_PlasticDeck" */
export enum Subcat_PlasticDeck_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_PlasticDeck" */
export type Subcat_PlasticDeck_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_PlasticDeck_Stddev_Fields = {
  __typename?: 'Subcat_PlasticDeck_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_PlasticDeck_Stddev_Pop_Fields = {
  __typename?: 'Subcat_PlasticDeck_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_PlasticDeck_Stddev_Samp_Fields = {
  __typename?: 'Subcat_PlasticDeck_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_PlasticDeck_Sum_Fields = {
  __typename?: 'Subcat_PlasticDeck_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_PlasticDeck" */
export enum Subcat_PlasticDeck_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_PlasticDeck_Var_Pop_Fields = {
  __typename?: 'Subcat_PlasticDeck_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_PlasticDeck_Var_Samp_Fields = {
  __typename?: 'Subcat_PlasticDeck_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_PlasticDeck_Variance_Fields = {
  __typename?: 'Subcat_PlasticDeck_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_Primer" */
export type Subcat_Primer = {
  __typename?: 'Subcat_Primer';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_Primer" */
export type Subcat_Primer_Aggregate = {
  __typename?: 'Subcat_Primer_aggregate';
  aggregate?: Maybe<Subcat_Primer_Aggregate_Fields>;
  nodes: Array<Subcat_Primer>;
};

/** aggregate fields of "Subcat_Primer" */
export type Subcat_Primer_Aggregate_Fields = {
  __typename?: 'Subcat_Primer_aggregate_fields';
  avg?: Maybe<Subcat_Primer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_Primer_Max_Fields>;
  min?: Maybe<Subcat_Primer_Min_Fields>;
  stddev?: Maybe<Subcat_Primer_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_Primer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_Primer_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_Primer_Sum_Fields>;
  var_pop?: Maybe<Subcat_Primer_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_Primer_Var_Samp_Fields>;
  variance?: Maybe<Subcat_Primer_Variance_Fields>;
};


/** aggregate fields of "Subcat_Primer" */
export type Subcat_Primer_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_Primer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_Primer_Avg_Fields = {
  __typename?: 'Subcat_Primer_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_Primer". All fields are combined with a logical 'AND'. */
export type Subcat_Primer_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_Primer_Bool_Exp>>;
  _not?: Maybe<Subcat_Primer_Bool_Exp>;
  _or?: Maybe<Array<Subcat_Primer_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_Primer" */
export type Subcat_Primer_If_Matched = {
  match_columns?: Array<Subcat_Primer_Insert_Match_Column>;
  update_columns?: Array<Subcat_Primer_Update_Column>;
  where?: Maybe<Subcat_Primer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_Primer" */
export type Subcat_Primer_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_Primer" */
export type Subcat_Primer_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_Primer" */
export enum Subcat_Primer_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_Primer_Max_Fields = {
  __typename?: 'Subcat_Primer_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_Primer_Min_Fields = {
  __typename?: 'Subcat_Primer_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_Primer" */
export type Subcat_Primer_Mutation_Response = {
  __typename?: 'Subcat_Primer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_Primer>;
};

/** Ordering options when selecting data from "Subcat_Primer". */
export type Subcat_Primer_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_Primer" */
export enum Subcat_Primer_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_Primer" */
export type Subcat_Primer_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_Primer_Stddev_Fields = {
  __typename?: 'Subcat_Primer_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_Primer_Stddev_Pop_Fields = {
  __typename?: 'Subcat_Primer_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_Primer_Stddev_Samp_Fields = {
  __typename?: 'Subcat_Primer_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_Primer_Sum_Fields = {
  __typename?: 'Subcat_Primer_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_Primer" */
export enum Subcat_Primer_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_Primer_Var_Pop_Fields = {
  __typename?: 'Subcat_Primer_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_Primer_Var_Samp_Fields = {
  __typename?: 'Subcat_Primer_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_Primer_Variance_Fields = {
  __typename?: 'Subcat_Primer_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_Seaming" */
export type Subcat_Seaming = {
  __typename?: 'Subcat_Seaming';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_Seaming" */
export type Subcat_Seaming_Aggregate = {
  __typename?: 'Subcat_Seaming_aggregate';
  aggregate?: Maybe<Subcat_Seaming_Aggregate_Fields>;
  nodes: Array<Subcat_Seaming>;
};

/** aggregate fields of "Subcat_Seaming" */
export type Subcat_Seaming_Aggregate_Fields = {
  __typename?: 'Subcat_Seaming_aggregate_fields';
  avg?: Maybe<Subcat_Seaming_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_Seaming_Max_Fields>;
  min?: Maybe<Subcat_Seaming_Min_Fields>;
  stddev?: Maybe<Subcat_Seaming_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_Seaming_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_Seaming_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_Seaming_Sum_Fields>;
  var_pop?: Maybe<Subcat_Seaming_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_Seaming_Var_Samp_Fields>;
  variance?: Maybe<Subcat_Seaming_Variance_Fields>;
};


/** aggregate fields of "Subcat_Seaming" */
export type Subcat_Seaming_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_Seaming_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_Seaming_Avg_Fields = {
  __typename?: 'Subcat_Seaming_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_Seaming". All fields are combined with a logical 'AND'. */
export type Subcat_Seaming_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_Seaming_Bool_Exp>>;
  _not?: Maybe<Subcat_Seaming_Bool_Exp>;
  _or?: Maybe<Array<Subcat_Seaming_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_Seaming" */
export type Subcat_Seaming_If_Matched = {
  match_columns?: Array<Subcat_Seaming_Insert_Match_Column>;
  update_columns?: Array<Subcat_Seaming_Update_Column>;
  where?: Maybe<Subcat_Seaming_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_Seaming" */
export type Subcat_Seaming_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_Seaming" */
export type Subcat_Seaming_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_Seaming" */
export enum Subcat_Seaming_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_Seaming_Max_Fields = {
  __typename?: 'Subcat_Seaming_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_Seaming_Min_Fields = {
  __typename?: 'Subcat_Seaming_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_Seaming" */
export type Subcat_Seaming_Mutation_Response = {
  __typename?: 'Subcat_Seaming_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_Seaming>;
};

/** Ordering options when selecting data from "Subcat_Seaming". */
export type Subcat_Seaming_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_Seaming" */
export enum Subcat_Seaming_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_Seaming" */
export type Subcat_Seaming_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_Seaming_Stddev_Fields = {
  __typename?: 'Subcat_Seaming_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_Seaming_Stddev_Pop_Fields = {
  __typename?: 'Subcat_Seaming_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_Seaming_Stddev_Samp_Fields = {
  __typename?: 'Subcat_Seaming_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_Seaming_Sum_Fields = {
  __typename?: 'Subcat_Seaming_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_Seaming" */
export enum Subcat_Seaming_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_Seaming_Var_Pop_Fields = {
  __typename?: 'Subcat_Seaming_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_Seaming_Var_Samp_Fields = {
  __typename?: 'Subcat_Seaming_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_Seaming_Variance_Fields = {
  __typename?: 'Subcat_Seaming_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_SeparatorSheet" */
export type Subcat_SeparatorSheet = {
  __typename?: 'Subcat_SeparatorSheet';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_SeparatorSheet" */
export type Subcat_SeparatorSheet_Aggregate = {
  __typename?: 'Subcat_SeparatorSheet_aggregate';
  aggregate?: Maybe<Subcat_SeparatorSheet_Aggregate_Fields>;
  nodes: Array<Subcat_SeparatorSheet>;
};

/** aggregate fields of "Subcat_SeparatorSheet" */
export type Subcat_SeparatorSheet_Aggregate_Fields = {
  __typename?: 'Subcat_SeparatorSheet_aggregate_fields';
  avg?: Maybe<Subcat_SeparatorSheet_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_SeparatorSheet_Max_Fields>;
  min?: Maybe<Subcat_SeparatorSheet_Min_Fields>;
  stddev?: Maybe<Subcat_SeparatorSheet_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_SeparatorSheet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_SeparatorSheet_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_SeparatorSheet_Sum_Fields>;
  var_pop?: Maybe<Subcat_SeparatorSheet_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_SeparatorSheet_Var_Samp_Fields>;
  variance?: Maybe<Subcat_SeparatorSheet_Variance_Fields>;
};


/** aggregate fields of "Subcat_SeparatorSheet" */
export type Subcat_SeparatorSheet_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_SeparatorSheet_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_SeparatorSheet_Avg_Fields = {
  __typename?: 'Subcat_SeparatorSheet_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_SeparatorSheet". All fields are combined with a logical 'AND'. */
export type Subcat_SeparatorSheet_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_SeparatorSheet_Bool_Exp>>;
  _not?: Maybe<Subcat_SeparatorSheet_Bool_Exp>;
  _or?: Maybe<Array<Subcat_SeparatorSheet_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_SeparatorSheet" */
export type Subcat_SeparatorSheet_If_Matched = {
  match_columns?: Array<Subcat_SeparatorSheet_Insert_Match_Column>;
  update_columns?: Array<Subcat_SeparatorSheet_Update_Column>;
  where?: Maybe<Subcat_SeparatorSheet_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_SeparatorSheet" */
export type Subcat_SeparatorSheet_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_SeparatorSheet" */
export type Subcat_SeparatorSheet_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_SeparatorSheet" */
export enum Subcat_SeparatorSheet_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_SeparatorSheet_Max_Fields = {
  __typename?: 'Subcat_SeparatorSheet_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_SeparatorSheet_Min_Fields = {
  __typename?: 'Subcat_SeparatorSheet_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_SeparatorSheet" */
export type Subcat_SeparatorSheet_Mutation_Response = {
  __typename?: 'Subcat_SeparatorSheet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_SeparatorSheet>;
};

/** Ordering options when selecting data from "Subcat_SeparatorSheet". */
export type Subcat_SeparatorSheet_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_SeparatorSheet" */
export enum Subcat_SeparatorSheet_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_SeparatorSheet" */
export type Subcat_SeparatorSheet_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_SeparatorSheet_Stddev_Fields = {
  __typename?: 'Subcat_SeparatorSheet_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_SeparatorSheet_Stddev_Pop_Fields = {
  __typename?: 'Subcat_SeparatorSheet_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_SeparatorSheet_Stddev_Samp_Fields = {
  __typename?: 'Subcat_SeparatorSheet_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_SeparatorSheet_Sum_Fields = {
  __typename?: 'Subcat_SeparatorSheet_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_SeparatorSheet" */
export enum Subcat_SeparatorSheet_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_SeparatorSheet_Var_Pop_Fields = {
  __typename?: 'Subcat_SeparatorSheet_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_SeparatorSheet_Var_Samp_Fields = {
  __typename?: 'Subcat_SeparatorSheet_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_SeparatorSheet_Variance_Fields = {
  __typename?: 'Subcat_SeparatorSheet_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_Shingles" */
export type Subcat_Shingles = {
  __typename?: 'Subcat_Shingles';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_Shingles" */
export type Subcat_Shingles_Aggregate = {
  __typename?: 'Subcat_Shingles_aggregate';
  aggregate?: Maybe<Subcat_Shingles_Aggregate_Fields>;
  nodes: Array<Subcat_Shingles>;
};

/** aggregate fields of "Subcat_Shingles" */
export type Subcat_Shingles_Aggregate_Fields = {
  __typename?: 'Subcat_Shingles_aggregate_fields';
  avg?: Maybe<Subcat_Shingles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_Shingles_Max_Fields>;
  min?: Maybe<Subcat_Shingles_Min_Fields>;
  stddev?: Maybe<Subcat_Shingles_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_Shingles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_Shingles_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_Shingles_Sum_Fields>;
  var_pop?: Maybe<Subcat_Shingles_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_Shingles_Var_Samp_Fields>;
  variance?: Maybe<Subcat_Shingles_Variance_Fields>;
};


/** aggregate fields of "Subcat_Shingles" */
export type Subcat_Shingles_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_Shingles_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_Shingles_Avg_Fields = {
  __typename?: 'Subcat_Shingles_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_Shingles". All fields are combined with a logical 'AND'. */
export type Subcat_Shingles_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_Shingles_Bool_Exp>>;
  _not?: Maybe<Subcat_Shingles_Bool_Exp>;
  _or?: Maybe<Array<Subcat_Shingles_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_Shingles" */
export type Subcat_Shingles_If_Matched = {
  match_columns?: Array<Subcat_Shingles_Insert_Match_Column>;
  update_columns?: Array<Subcat_Shingles_Update_Column>;
  where?: Maybe<Subcat_Shingles_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_Shingles" */
export type Subcat_Shingles_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_Shingles" */
export type Subcat_Shingles_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_Shingles" */
export enum Subcat_Shingles_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_Shingles_Max_Fields = {
  __typename?: 'Subcat_Shingles_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_Shingles_Min_Fields = {
  __typename?: 'Subcat_Shingles_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_Shingles" */
export type Subcat_Shingles_Mutation_Response = {
  __typename?: 'Subcat_Shingles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_Shingles>;
};

/** Ordering options when selecting data from "Subcat_Shingles". */
export type Subcat_Shingles_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_Shingles" */
export enum Subcat_Shingles_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_Shingles" */
export type Subcat_Shingles_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_Shingles_Stddev_Fields = {
  __typename?: 'Subcat_Shingles_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_Shingles_Stddev_Pop_Fields = {
  __typename?: 'Subcat_Shingles_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_Shingles_Stddev_Samp_Fields = {
  __typename?: 'Subcat_Shingles_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_Shingles_Sum_Fields = {
  __typename?: 'Subcat_Shingles_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_Shingles" */
export enum Subcat_Shingles_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_Shingles_Var_Pop_Fields = {
  __typename?: 'Subcat_Shingles_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_Shingles_Var_Samp_Fields = {
  __typename?: 'Subcat_Shingles_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_Shingles_Variance_Fields = {
  __typename?: 'Subcat_Shingles_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_SinglePly" */
export type Subcat_SinglePly = {
  __typename?: 'Subcat_SinglePly';
  ComponentStatusId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** columns and relationships of "Subcat_SinglePlyCover" */
export type Subcat_SinglePlyCover = {
  __typename?: 'Subcat_SinglePlyCover';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_SinglePlyCover" */
export type Subcat_SinglePlyCover_Aggregate = {
  __typename?: 'Subcat_SinglePlyCover_aggregate';
  aggregate?: Maybe<Subcat_SinglePlyCover_Aggregate_Fields>;
  nodes: Array<Subcat_SinglePlyCover>;
};

/** aggregate fields of "Subcat_SinglePlyCover" */
export type Subcat_SinglePlyCover_Aggregate_Fields = {
  __typename?: 'Subcat_SinglePlyCover_aggregate_fields';
  avg?: Maybe<Subcat_SinglePlyCover_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_SinglePlyCover_Max_Fields>;
  min?: Maybe<Subcat_SinglePlyCover_Min_Fields>;
  stddev?: Maybe<Subcat_SinglePlyCover_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_SinglePlyCover_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_SinglePlyCover_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_SinglePlyCover_Sum_Fields>;
  var_pop?: Maybe<Subcat_SinglePlyCover_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_SinglePlyCover_Var_Samp_Fields>;
  variance?: Maybe<Subcat_SinglePlyCover_Variance_Fields>;
};


/** aggregate fields of "Subcat_SinglePlyCover" */
export type Subcat_SinglePlyCover_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_SinglePlyCover_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_SinglePlyCover_Avg_Fields = {
  __typename?: 'Subcat_SinglePlyCover_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_SinglePlyCover". All fields are combined with a logical 'AND'. */
export type Subcat_SinglePlyCover_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_SinglePlyCover_Bool_Exp>>;
  _not?: Maybe<Subcat_SinglePlyCover_Bool_Exp>;
  _or?: Maybe<Array<Subcat_SinglePlyCover_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_SinglePlyCover" */
export type Subcat_SinglePlyCover_If_Matched = {
  match_columns?: Array<Subcat_SinglePlyCover_Insert_Match_Column>;
  update_columns?: Array<Subcat_SinglePlyCover_Update_Column>;
  where?: Maybe<Subcat_SinglePlyCover_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_SinglePlyCover" */
export type Subcat_SinglePlyCover_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_SinglePlyCover" */
export type Subcat_SinglePlyCover_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_SinglePlyCover" */
export enum Subcat_SinglePlyCover_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_SinglePlyCover_Max_Fields = {
  __typename?: 'Subcat_SinglePlyCover_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_SinglePlyCover_Min_Fields = {
  __typename?: 'Subcat_SinglePlyCover_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_SinglePlyCover" */
export type Subcat_SinglePlyCover_Mutation_Response = {
  __typename?: 'Subcat_SinglePlyCover_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_SinglePlyCover>;
};

/** Ordering options when selecting data from "Subcat_SinglePlyCover". */
export type Subcat_SinglePlyCover_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_SinglePlyCover" */
export enum Subcat_SinglePlyCover_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_SinglePlyCover" */
export type Subcat_SinglePlyCover_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_SinglePlyCover_Stddev_Fields = {
  __typename?: 'Subcat_SinglePlyCover_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_SinglePlyCover_Stddev_Pop_Fields = {
  __typename?: 'Subcat_SinglePlyCover_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_SinglePlyCover_Stddev_Samp_Fields = {
  __typename?: 'Subcat_SinglePlyCover_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_SinglePlyCover_Sum_Fields = {
  __typename?: 'Subcat_SinglePlyCover_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_SinglePlyCover" */
export enum Subcat_SinglePlyCover_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_SinglePlyCover_Var_Pop_Fields = {
  __typename?: 'Subcat_SinglePlyCover_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_SinglePlyCover_Var_Samp_Fields = {
  __typename?: 'Subcat_SinglePlyCover_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_SinglePlyCover_Variance_Fields = {
  __typename?: 'Subcat_SinglePlyCover_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Subcat_SinglePly" */
export type Subcat_SinglePly_Aggregate = {
  __typename?: 'Subcat_SinglePly_aggregate';
  aggregate?: Maybe<Subcat_SinglePly_Aggregate_Fields>;
  nodes: Array<Subcat_SinglePly>;
};

/** aggregate fields of "Subcat_SinglePly" */
export type Subcat_SinglePly_Aggregate_Fields = {
  __typename?: 'Subcat_SinglePly_aggregate_fields';
  avg?: Maybe<Subcat_SinglePly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_SinglePly_Max_Fields>;
  min?: Maybe<Subcat_SinglePly_Min_Fields>;
  stddev?: Maybe<Subcat_SinglePly_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_SinglePly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_SinglePly_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_SinglePly_Sum_Fields>;
  var_pop?: Maybe<Subcat_SinglePly_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_SinglePly_Var_Samp_Fields>;
  variance?: Maybe<Subcat_SinglePly_Variance_Fields>;
};


/** aggregate fields of "Subcat_SinglePly" */
export type Subcat_SinglePly_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_SinglePly_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_SinglePly_Avg_Fields = {
  __typename?: 'Subcat_SinglePly_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_SinglePly". All fields are combined with a logical 'AND'. */
export type Subcat_SinglePly_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_SinglePly_Bool_Exp>>;
  _not?: Maybe<Subcat_SinglePly_Bool_Exp>;
  _or?: Maybe<Array<Subcat_SinglePly_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_SinglePly" */
export type Subcat_SinglePly_If_Matched = {
  match_columns?: Array<Subcat_SinglePly_Insert_Match_Column>;
  update_columns?: Array<Subcat_SinglePly_Update_Column>;
  where?: Maybe<Subcat_SinglePly_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_SinglePly" */
export type Subcat_SinglePly_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_SinglePly" */
export type Subcat_SinglePly_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_SinglePly" */
export enum Subcat_SinglePly_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_SinglePly_Max_Fields = {
  __typename?: 'Subcat_SinglePly_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_SinglePly_Min_Fields = {
  __typename?: 'Subcat_SinglePly_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_SinglePly" */
export type Subcat_SinglePly_Mutation_Response = {
  __typename?: 'Subcat_SinglePly_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_SinglePly>;
};

/** Ordering options when selecting data from "Subcat_SinglePly". */
export type Subcat_SinglePly_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_SinglePly" */
export enum Subcat_SinglePly_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_SinglePly" */
export type Subcat_SinglePly_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_SinglePly_Stddev_Fields = {
  __typename?: 'Subcat_SinglePly_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_SinglePly_Stddev_Pop_Fields = {
  __typename?: 'Subcat_SinglePly_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_SinglePly_Stddev_Samp_Fields = {
  __typename?: 'Subcat_SinglePly_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_SinglePly_Sum_Fields = {
  __typename?: 'Subcat_SinglePly_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_SinglePly" */
export enum Subcat_SinglePly_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_SinglePly_Var_Pop_Fields = {
  __typename?: 'Subcat_SinglePly_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_SinglePly_Var_Samp_Fields = {
  __typename?: 'Subcat_SinglePly_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_SinglePly_Variance_Fields = {
  __typename?: 'Subcat_SinglePly_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_SprayFoam" */
export type Subcat_SprayFoam = {
  __typename?: 'Subcat_SprayFoam';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_SprayFoam" */
export type Subcat_SprayFoam_Aggregate = {
  __typename?: 'Subcat_SprayFoam_aggregate';
  aggregate?: Maybe<Subcat_SprayFoam_Aggregate_Fields>;
  nodes: Array<Subcat_SprayFoam>;
};

/** aggregate fields of "Subcat_SprayFoam" */
export type Subcat_SprayFoam_Aggregate_Fields = {
  __typename?: 'Subcat_SprayFoam_aggregate_fields';
  avg?: Maybe<Subcat_SprayFoam_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_SprayFoam_Max_Fields>;
  min?: Maybe<Subcat_SprayFoam_Min_Fields>;
  stddev?: Maybe<Subcat_SprayFoam_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_SprayFoam_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_SprayFoam_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_SprayFoam_Sum_Fields>;
  var_pop?: Maybe<Subcat_SprayFoam_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_SprayFoam_Var_Samp_Fields>;
  variance?: Maybe<Subcat_SprayFoam_Variance_Fields>;
};


/** aggregate fields of "Subcat_SprayFoam" */
export type Subcat_SprayFoam_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_SprayFoam_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_SprayFoam_Avg_Fields = {
  __typename?: 'Subcat_SprayFoam_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_SprayFoam". All fields are combined with a logical 'AND'. */
export type Subcat_SprayFoam_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_SprayFoam_Bool_Exp>>;
  _not?: Maybe<Subcat_SprayFoam_Bool_Exp>;
  _or?: Maybe<Array<Subcat_SprayFoam_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_SprayFoam" */
export type Subcat_SprayFoam_If_Matched = {
  match_columns?: Array<Subcat_SprayFoam_Insert_Match_Column>;
  update_columns?: Array<Subcat_SprayFoam_Update_Column>;
  where?: Maybe<Subcat_SprayFoam_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_SprayFoam" */
export type Subcat_SprayFoam_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_SprayFoam" */
export type Subcat_SprayFoam_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_SprayFoam" */
export enum Subcat_SprayFoam_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_SprayFoam_Max_Fields = {
  __typename?: 'Subcat_SprayFoam_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_SprayFoam_Min_Fields = {
  __typename?: 'Subcat_SprayFoam_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_SprayFoam" */
export type Subcat_SprayFoam_Mutation_Response = {
  __typename?: 'Subcat_SprayFoam_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_SprayFoam>;
};

/** Ordering options when selecting data from "Subcat_SprayFoam". */
export type Subcat_SprayFoam_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_SprayFoam" */
export enum Subcat_SprayFoam_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_SprayFoam" */
export type Subcat_SprayFoam_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_SprayFoam_Stddev_Fields = {
  __typename?: 'Subcat_SprayFoam_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_SprayFoam_Stddev_Pop_Fields = {
  __typename?: 'Subcat_SprayFoam_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_SprayFoam_Stddev_Samp_Fields = {
  __typename?: 'Subcat_SprayFoam_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_SprayFoam_Sum_Fields = {
  __typename?: 'Subcat_SprayFoam_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_SprayFoam" */
export enum Subcat_SprayFoam_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_SprayFoam_Var_Pop_Fields = {
  __typename?: 'Subcat_SprayFoam_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_SprayFoam_Var_Samp_Fields = {
  __typename?: 'Subcat_SprayFoam_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_SprayFoam_Variance_Fields = {
  __typename?: 'Subcat_SprayFoam_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_StandingLapSeam" */
export type Subcat_StandingLapSeam = {
  __typename?: 'Subcat_StandingLapSeam';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_StandingLapSeam" */
export type Subcat_StandingLapSeam_Aggregate = {
  __typename?: 'Subcat_StandingLapSeam_aggregate';
  aggregate?: Maybe<Subcat_StandingLapSeam_Aggregate_Fields>;
  nodes: Array<Subcat_StandingLapSeam>;
};

/** aggregate fields of "Subcat_StandingLapSeam" */
export type Subcat_StandingLapSeam_Aggregate_Fields = {
  __typename?: 'Subcat_StandingLapSeam_aggregate_fields';
  avg?: Maybe<Subcat_StandingLapSeam_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_StandingLapSeam_Max_Fields>;
  min?: Maybe<Subcat_StandingLapSeam_Min_Fields>;
  stddev?: Maybe<Subcat_StandingLapSeam_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_StandingLapSeam_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_StandingLapSeam_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_StandingLapSeam_Sum_Fields>;
  var_pop?: Maybe<Subcat_StandingLapSeam_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_StandingLapSeam_Var_Samp_Fields>;
  variance?: Maybe<Subcat_StandingLapSeam_Variance_Fields>;
};


/** aggregate fields of "Subcat_StandingLapSeam" */
export type Subcat_StandingLapSeam_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_StandingLapSeam_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_StandingLapSeam_Avg_Fields = {
  __typename?: 'Subcat_StandingLapSeam_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_StandingLapSeam". All fields are combined with a logical 'AND'. */
export type Subcat_StandingLapSeam_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_StandingLapSeam_Bool_Exp>>;
  _not?: Maybe<Subcat_StandingLapSeam_Bool_Exp>;
  _or?: Maybe<Array<Subcat_StandingLapSeam_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_StandingLapSeam" */
export type Subcat_StandingLapSeam_If_Matched = {
  match_columns?: Array<Subcat_StandingLapSeam_Insert_Match_Column>;
  update_columns?: Array<Subcat_StandingLapSeam_Update_Column>;
  where?: Maybe<Subcat_StandingLapSeam_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_StandingLapSeam" */
export type Subcat_StandingLapSeam_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_StandingLapSeam" */
export type Subcat_StandingLapSeam_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_StandingLapSeam" */
export enum Subcat_StandingLapSeam_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_StandingLapSeam_Max_Fields = {
  __typename?: 'Subcat_StandingLapSeam_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_StandingLapSeam_Min_Fields = {
  __typename?: 'Subcat_StandingLapSeam_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_StandingLapSeam" */
export type Subcat_StandingLapSeam_Mutation_Response = {
  __typename?: 'Subcat_StandingLapSeam_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_StandingLapSeam>;
};

/** Ordering options when selecting data from "Subcat_StandingLapSeam". */
export type Subcat_StandingLapSeam_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_StandingLapSeam" */
export enum Subcat_StandingLapSeam_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_StandingLapSeam" */
export type Subcat_StandingLapSeam_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_StandingLapSeam_Stddev_Fields = {
  __typename?: 'Subcat_StandingLapSeam_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_StandingLapSeam_Stddev_Pop_Fields = {
  __typename?: 'Subcat_StandingLapSeam_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_StandingLapSeam_Stddev_Samp_Fields = {
  __typename?: 'Subcat_StandingLapSeam_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_StandingLapSeam_Sum_Fields = {
  __typename?: 'Subcat_StandingLapSeam_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_StandingLapSeam" */
export enum Subcat_StandingLapSeam_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_StandingLapSeam_Var_Pop_Fields = {
  __typename?: 'Subcat_StandingLapSeam_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_StandingLapSeam_Var_Samp_Fields = {
  __typename?: 'Subcat_StandingLapSeam_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_StandingLapSeam_Variance_Fields = {
  __typename?: 'Subcat_StandingLapSeam_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_SteelDeck" */
export type Subcat_SteelDeck = {
  __typename?: 'Subcat_SteelDeck';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_SteelDeck" */
export type Subcat_SteelDeck_Aggregate = {
  __typename?: 'Subcat_SteelDeck_aggregate';
  aggregate?: Maybe<Subcat_SteelDeck_Aggregate_Fields>;
  nodes: Array<Subcat_SteelDeck>;
};

/** aggregate fields of "Subcat_SteelDeck" */
export type Subcat_SteelDeck_Aggregate_Fields = {
  __typename?: 'Subcat_SteelDeck_aggregate_fields';
  avg?: Maybe<Subcat_SteelDeck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_SteelDeck_Max_Fields>;
  min?: Maybe<Subcat_SteelDeck_Min_Fields>;
  stddev?: Maybe<Subcat_SteelDeck_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_SteelDeck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_SteelDeck_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_SteelDeck_Sum_Fields>;
  var_pop?: Maybe<Subcat_SteelDeck_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_SteelDeck_Var_Samp_Fields>;
  variance?: Maybe<Subcat_SteelDeck_Variance_Fields>;
};


/** aggregate fields of "Subcat_SteelDeck" */
export type Subcat_SteelDeck_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_SteelDeck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_SteelDeck_Avg_Fields = {
  __typename?: 'Subcat_SteelDeck_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_SteelDeck". All fields are combined with a logical 'AND'. */
export type Subcat_SteelDeck_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_SteelDeck_Bool_Exp>>;
  _not?: Maybe<Subcat_SteelDeck_Bool_Exp>;
  _or?: Maybe<Array<Subcat_SteelDeck_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_SteelDeck" */
export type Subcat_SteelDeck_If_Matched = {
  match_columns?: Array<Subcat_SteelDeck_Insert_Match_Column>;
  update_columns?: Array<Subcat_SteelDeck_Update_Column>;
  where?: Maybe<Subcat_SteelDeck_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_SteelDeck" */
export type Subcat_SteelDeck_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_SteelDeck" */
export type Subcat_SteelDeck_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_SteelDeck" */
export enum Subcat_SteelDeck_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_SteelDeck_Max_Fields = {
  __typename?: 'Subcat_SteelDeck_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_SteelDeck_Min_Fields = {
  __typename?: 'Subcat_SteelDeck_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_SteelDeck" */
export type Subcat_SteelDeck_Mutation_Response = {
  __typename?: 'Subcat_SteelDeck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_SteelDeck>;
};

/** Ordering options when selecting data from "Subcat_SteelDeck". */
export type Subcat_SteelDeck_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_SteelDeck" */
export enum Subcat_SteelDeck_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_SteelDeck" */
export type Subcat_SteelDeck_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_SteelDeck_Stddev_Fields = {
  __typename?: 'Subcat_SteelDeck_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_SteelDeck_Stddev_Pop_Fields = {
  __typename?: 'Subcat_SteelDeck_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_SteelDeck_Stddev_Samp_Fields = {
  __typename?: 'Subcat_SteelDeck_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_SteelDeck_Sum_Fields = {
  __typename?: 'Subcat_SteelDeck_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_SteelDeck" */
export enum Subcat_SteelDeck_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_SteelDeck_Var_Pop_Fields = {
  __typename?: 'Subcat_SteelDeck_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_SteelDeck_Var_Samp_Fields = {
  __typename?: 'Subcat_SteelDeck_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_SteelDeck_Variance_Fields = {
  __typename?: 'Subcat_SteelDeck_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_Surfacing" */
export type Subcat_Surfacing = {
  __typename?: 'Subcat_Surfacing';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_Surfacing" */
export type Subcat_Surfacing_Aggregate = {
  __typename?: 'Subcat_Surfacing_aggregate';
  aggregate?: Maybe<Subcat_Surfacing_Aggregate_Fields>;
  nodes: Array<Subcat_Surfacing>;
};

/** aggregate fields of "Subcat_Surfacing" */
export type Subcat_Surfacing_Aggregate_Fields = {
  __typename?: 'Subcat_Surfacing_aggregate_fields';
  avg?: Maybe<Subcat_Surfacing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_Surfacing_Max_Fields>;
  min?: Maybe<Subcat_Surfacing_Min_Fields>;
  stddev?: Maybe<Subcat_Surfacing_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_Surfacing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_Surfacing_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_Surfacing_Sum_Fields>;
  var_pop?: Maybe<Subcat_Surfacing_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_Surfacing_Var_Samp_Fields>;
  variance?: Maybe<Subcat_Surfacing_Variance_Fields>;
};


/** aggregate fields of "Subcat_Surfacing" */
export type Subcat_Surfacing_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_Surfacing_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_Surfacing_Avg_Fields = {
  __typename?: 'Subcat_Surfacing_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_Surfacing". All fields are combined with a logical 'AND'. */
export type Subcat_Surfacing_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_Surfacing_Bool_Exp>>;
  _not?: Maybe<Subcat_Surfacing_Bool_Exp>;
  _or?: Maybe<Array<Subcat_Surfacing_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_Surfacing" */
export type Subcat_Surfacing_If_Matched = {
  match_columns?: Array<Subcat_Surfacing_Insert_Match_Column>;
  update_columns?: Array<Subcat_Surfacing_Update_Column>;
  where?: Maybe<Subcat_Surfacing_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_Surfacing" */
export type Subcat_Surfacing_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_Surfacing" */
export type Subcat_Surfacing_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_Surfacing" */
export enum Subcat_Surfacing_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_Surfacing_Max_Fields = {
  __typename?: 'Subcat_Surfacing_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_Surfacing_Min_Fields = {
  __typename?: 'Subcat_Surfacing_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_Surfacing" */
export type Subcat_Surfacing_Mutation_Response = {
  __typename?: 'Subcat_Surfacing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_Surfacing>;
};

/** Ordering options when selecting data from "Subcat_Surfacing". */
export type Subcat_Surfacing_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_Surfacing" */
export enum Subcat_Surfacing_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_Surfacing" */
export type Subcat_Surfacing_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_Surfacing_Stddev_Fields = {
  __typename?: 'Subcat_Surfacing_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_Surfacing_Stddev_Pop_Fields = {
  __typename?: 'Subcat_Surfacing_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_Surfacing_Stddev_Samp_Fields = {
  __typename?: 'Subcat_Surfacing_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_Surfacing_Sum_Fields = {
  __typename?: 'Subcat_Surfacing_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_Surfacing" */
export enum Subcat_Surfacing_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_Surfacing_Var_Pop_Fields = {
  __typename?: 'Subcat_Surfacing_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_Surfacing_Var_Samp_Fields = {
  __typename?: 'Subcat_Surfacing_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_Surfacing_Variance_Fields = {
  __typename?: 'Subcat_Surfacing_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_VaporRetarder" */
export type Subcat_VaporRetarder = {
  __typename?: 'Subcat_VaporRetarder';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_VaporRetarder" */
export type Subcat_VaporRetarder_Aggregate = {
  __typename?: 'Subcat_VaporRetarder_aggregate';
  aggregate?: Maybe<Subcat_VaporRetarder_Aggregate_Fields>;
  nodes: Array<Subcat_VaporRetarder>;
};

/** aggregate fields of "Subcat_VaporRetarder" */
export type Subcat_VaporRetarder_Aggregate_Fields = {
  __typename?: 'Subcat_VaporRetarder_aggregate_fields';
  avg?: Maybe<Subcat_VaporRetarder_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_VaporRetarder_Max_Fields>;
  min?: Maybe<Subcat_VaporRetarder_Min_Fields>;
  stddev?: Maybe<Subcat_VaporRetarder_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_VaporRetarder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_VaporRetarder_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_VaporRetarder_Sum_Fields>;
  var_pop?: Maybe<Subcat_VaporRetarder_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_VaporRetarder_Var_Samp_Fields>;
  variance?: Maybe<Subcat_VaporRetarder_Variance_Fields>;
};


/** aggregate fields of "Subcat_VaporRetarder" */
export type Subcat_VaporRetarder_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_VaporRetarder_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_VaporRetarder_Avg_Fields = {
  __typename?: 'Subcat_VaporRetarder_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_VaporRetarder". All fields are combined with a logical 'AND'. */
export type Subcat_VaporRetarder_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_VaporRetarder_Bool_Exp>>;
  _not?: Maybe<Subcat_VaporRetarder_Bool_Exp>;
  _or?: Maybe<Array<Subcat_VaporRetarder_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_VaporRetarder" */
export type Subcat_VaporRetarder_If_Matched = {
  match_columns?: Array<Subcat_VaporRetarder_Insert_Match_Column>;
  update_columns?: Array<Subcat_VaporRetarder_Update_Column>;
  where?: Maybe<Subcat_VaporRetarder_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_VaporRetarder" */
export type Subcat_VaporRetarder_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_VaporRetarder" */
export type Subcat_VaporRetarder_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_VaporRetarder" */
export enum Subcat_VaporRetarder_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_VaporRetarder_Max_Fields = {
  __typename?: 'Subcat_VaporRetarder_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_VaporRetarder_Min_Fields = {
  __typename?: 'Subcat_VaporRetarder_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_VaporRetarder" */
export type Subcat_VaporRetarder_Mutation_Response = {
  __typename?: 'Subcat_VaporRetarder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_VaporRetarder>;
};

/** Ordering options when selecting data from "Subcat_VaporRetarder". */
export type Subcat_VaporRetarder_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_VaporRetarder" */
export enum Subcat_VaporRetarder_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_VaporRetarder" */
export type Subcat_VaporRetarder_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_VaporRetarder_Stddev_Fields = {
  __typename?: 'Subcat_VaporRetarder_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_VaporRetarder_Stddev_Pop_Fields = {
  __typename?: 'Subcat_VaporRetarder_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_VaporRetarder_Stddev_Samp_Fields = {
  __typename?: 'Subcat_VaporRetarder_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_VaporRetarder_Sum_Fields = {
  __typename?: 'Subcat_VaporRetarder_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_VaporRetarder" */
export enum Subcat_VaporRetarder_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_VaporRetarder_Var_Pop_Fields = {
  __typename?: 'Subcat_VaporRetarder_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_VaporRetarder_Var_Samp_Fields = {
  __typename?: 'Subcat_VaporRetarder_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_VaporRetarder_Variance_Fields = {
  __typename?: 'Subcat_VaporRetarder_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_Weld" */
export type Subcat_Weld = {
  __typename?: 'Subcat_Weld';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_Weld" */
export type Subcat_Weld_Aggregate = {
  __typename?: 'Subcat_Weld_aggregate';
  aggregate?: Maybe<Subcat_Weld_Aggregate_Fields>;
  nodes: Array<Subcat_Weld>;
};

/** aggregate fields of "Subcat_Weld" */
export type Subcat_Weld_Aggregate_Fields = {
  __typename?: 'Subcat_Weld_aggregate_fields';
  avg?: Maybe<Subcat_Weld_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_Weld_Max_Fields>;
  min?: Maybe<Subcat_Weld_Min_Fields>;
  stddev?: Maybe<Subcat_Weld_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_Weld_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_Weld_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_Weld_Sum_Fields>;
  var_pop?: Maybe<Subcat_Weld_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_Weld_Var_Samp_Fields>;
  variance?: Maybe<Subcat_Weld_Variance_Fields>;
};


/** aggregate fields of "Subcat_Weld" */
export type Subcat_Weld_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_Weld_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_Weld_Avg_Fields = {
  __typename?: 'Subcat_Weld_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_Weld". All fields are combined with a logical 'AND'. */
export type Subcat_Weld_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_Weld_Bool_Exp>>;
  _not?: Maybe<Subcat_Weld_Bool_Exp>;
  _or?: Maybe<Array<Subcat_Weld_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_Weld" */
export type Subcat_Weld_If_Matched = {
  match_columns?: Array<Subcat_Weld_Insert_Match_Column>;
  update_columns?: Array<Subcat_Weld_Update_Column>;
  where?: Maybe<Subcat_Weld_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_Weld" */
export type Subcat_Weld_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_Weld" */
export type Subcat_Weld_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_Weld" */
export enum Subcat_Weld_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_Weld_Max_Fields = {
  __typename?: 'Subcat_Weld_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_Weld_Min_Fields = {
  __typename?: 'Subcat_Weld_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_Weld" */
export type Subcat_Weld_Mutation_Response = {
  __typename?: 'Subcat_Weld_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_Weld>;
};

/** Ordering options when selecting data from "Subcat_Weld". */
export type Subcat_Weld_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_Weld" */
export enum Subcat_Weld_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_Weld" */
export type Subcat_Weld_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_Weld_Stddev_Fields = {
  __typename?: 'Subcat_Weld_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_Weld_Stddev_Pop_Fields = {
  __typename?: 'Subcat_Weld_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_Weld_Stddev_Samp_Fields = {
  __typename?: 'Subcat_Weld_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_Weld_Sum_Fields = {
  __typename?: 'Subcat_Weld_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_Weld" */
export enum Subcat_Weld_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_Weld_Var_Pop_Fields = {
  __typename?: 'Subcat_Weld_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_Weld_Var_Samp_Fields = {
  __typename?: 'Subcat_Weld_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_Weld_Variance_Fields = {
  __typename?: 'Subcat_Weld_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Subcat_Wood" */
export type Subcat_Wood = {
  __typename?: 'Subcat_Wood';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** columns and relationships of "Subcat_WoodFiberDeck" */
export type Subcat_WoodFiberDeck = {
  __typename?: 'Subcat_WoodFiberDeck';
  ComponentStatusId: Scalars['Int'];
  DateModified: Scalars['datetime'];
  ModifiedByUserAccountId: Scalars['Int'];
  approvedusecode: Scalars['CodeTableValue'];
  approveduseid: Scalars['Int'];
  approvedusetablename: Scalars['String'];
  categoryname: Scalars['String'];
  componentid: Scalars['Int'];
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid: Scalars['Int'];
  manufacturerid: Scalars['Int'];
  manufacturername: Scalars['String'];
  statusid: Scalars['Int'];
  subcategoryname: Scalars['String'];
  tradename?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Subcat_WoodFiberDeck" */
export type Subcat_WoodFiberDeck_Aggregate = {
  __typename?: 'Subcat_WoodFiberDeck_aggregate';
  aggregate?: Maybe<Subcat_WoodFiberDeck_Aggregate_Fields>;
  nodes: Array<Subcat_WoodFiberDeck>;
};

/** aggregate fields of "Subcat_WoodFiberDeck" */
export type Subcat_WoodFiberDeck_Aggregate_Fields = {
  __typename?: 'Subcat_WoodFiberDeck_aggregate_fields';
  avg?: Maybe<Subcat_WoodFiberDeck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_WoodFiberDeck_Max_Fields>;
  min?: Maybe<Subcat_WoodFiberDeck_Min_Fields>;
  stddev?: Maybe<Subcat_WoodFiberDeck_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_WoodFiberDeck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_WoodFiberDeck_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_WoodFiberDeck_Sum_Fields>;
  var_pop?: Maybe<Subcat_WoodFiberDeck_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_WoodFiberDeck_Var_Samp_Fields>;
  variance?: Maybe<Subcat_WoodFiberDeck_Variance_Fields>;
};


/** aggregate fields of "Subcat_WoodFiberDeck" */
export type Subcat_WoodFiberDeck_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_WoodFiberDeck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_WoodFiberDeck_Avg_Fields = {
  __typename?: 'Subcat_WoodFiberDeck_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_WoodFiberDeck". All fields are combined with a logical 'AND'. */
export type Subcat_WoodFiberDeck_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_WoodFiberDeck_Bool_Exp>>;
  _not?: Maybe<Subcat_WoodFiberDeck_Bool_Exp>;
  _or?: Maybe<Array<Subcat_WoodFiberDeck_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_WoodFiberDeck" */
export type Subcat_WoodFiberDeck_If_Matched = {
  match_columns?: Array<Subcat_WoodFiberDeck_Insert_Match_Column>;
  update_columns?: Array<Subcat_WoodFiberDeck_Update_Column>;
  where?: Maybe<Subcat_WoodFiberDeck_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_WoodFiberDeck" */
export type Subcat_WoodFiberDeck_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_WoodFiberDeck" */
export type Subcat_WoodFiberDeck_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_WoodFiberDeck" */
export enum Subcat_WoodFiberDeck_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_WoodFiberDeck_Max_Fields = {
  __typename?: 'Subcat_WoodFiberDeck_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_WoodFiberDeck_Min_Fields = {
  __typename?: 'Subcat_WoodFiberDeck_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_WoodFiberDeck" */
export type Subcat_WoodFiberDeck_Mutation_Response = {
  __typename?: 'Subcat_WoodFiberDeck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_WoodFiberDeck>;
};

/** Ordering options when selecting data from "Subcat_WoodFiberDeck". */
export type Subcat_WoodFiberDeck_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_WoodFiberDeck" */
export enum Subcat_WoodFiberDeck_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_WoodFiberDeck" */
export type Subcat_WoodFiberDeck_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_WoodFiberDeck_Stddev_Fields = {
  __typename?: 'Subcat_WoodFiberDeck_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_WoodFiberDeck_Stddev_Pop_Fields = {
  __typename?: 'Subcat_WoodFiberDeck_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_WoodFiberDeck_Stddev_Samp_Fields = {
  __typename?: 'Subcat_WoodFiberDeck_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_WoodFiberDeck_Sum_Fields = {
  __typename?: 'Subcat_WoodFiberDeck_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_WoodFiberDeck" */
export enum Subcat_WoodFiberDeck_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_WoodFiberDeck_Var_Pop_Fields = {
  __typename?: 'Subcat_WoodFiberDeck_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_WoodFiberDeck_Var_Samp_Fields = {
  __typename?: 'Subcat_WoodFiberDeck_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_WoodFiberDeck_Variance_Fields = {
  __typename?: 'Subcat_WoodFiberDeck_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Subcat_Wood" */
export type Subcat_Wood_Aggregate = {
  __typename?: 'Subcat_Wood_aggregate';
  aggregate?: Maybe<Subcat_Wood_Aggregate_Fields>;
  nodes: Array<Subcat_Wood>;
};

/** aggregate fields of "Subcat_Wood" */
export type Subcat_Wood_Aggregate_Fields = {
  __typename?: 'Subcat_Wood_aggregate_fields';
  avg?: Maybe<Subcat_Wood_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subcat_Wood_Max_Fields>;
  min?: Maybe<Subcat_Wood_Min_Fields>;
  stddev?: Maybe<Subcat_Wood_Stddev_Fields>;
  stddev_pop?: Maybe<Subcat_Wood_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subcat_Wood_Stddev_Samp_Fields>;
  sum?: Maybe<Subcat_Wood_Sum_Fields>;
  var_pop?: Maybe<Subcat_Wood_Var_Pop_Fields>;
  var_samp?: Maybe<Subcat_Wood_Var_Samp_Fields>;
  variance?: Maybe<Subcat_Wood_Variance_Fields>;
};


/** aggregate fields of "Subcat_Wood" */
export type Subcat_Wood_Aggregate_FieldsCountArgs = {
  column?: Maybe<Subcat_Wood_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subcat_Wood_Avg_Fields = {
  __typename?: 'Subcat_Wood_avg_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Subcat_Wood". All fields are combined with a logical 'AND'. */
export type Subcat_Wood_Bool_Exp = {
  ComponentStatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  DateModified?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ModifiedByUserAccountId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Subcat_Wood_Bool_Exp>>;
  _not?: Maybe<Subcat_Wood_Bool_Exp>;
  _or?: Maybe<Array<Subcat_Wood_Bool_Exp>>;
  approvedusecode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  approveduseid?: Maybe<Int_Mssql_Comparison_Exp>;
  approvedusetablename?: Maybe<String_Mssql_Comparison_Exp>;
  categoryname?: Maybe<String_Mssql_Comparison_Exp>;
  componentid?: Maybe<Int_Mssql_Comparison_Exp>;
  datelocked?: Maybe<Datetime_Mssql_Comparison_Exp>;
  lockedbyuseraccountid?: Maybe<Int_Mssql_Comparison_Exp>;
  lockstatusid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturerid?: Maybe<Int_Mssql_Comparison_Exp>;
  manufacturername?: Maybe<String_Mssql_Comparison_Exp>;
  statusid?: Maybe<Int_Mssql_Comparison_Exp>;
  subcategoryname?: Maybe<String_Mssql_Comparison_Exp>;
  tradename?: Maybe<String_Mssql_Comparison_Exp>;
};

/** upsert condition type for table "Subcat_Wood" */
export type Subcat_Wood_If_Matched = {
  match_columns?: Array<Subcat_Wood_Insert_Match_Column>;
  update_columns?: Array<Subcat_Wood_Update_Column>;
  where?: Maybe<Subcat_Wood_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Subcat_Wood" */
export type Subcat_Wood_Inc_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Subcat_Wood" */
export type Subcat_Wood_Insert_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** select match_columns of table "Subcat_Wood" */
export enum Subcat_Wood_Insert_Match_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate max on columns */
export type Subcat_Wood_Max_Fields = {
  __typename?: 'Subcat_Wood_max_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subcat_Wood_Min_Fields = {
  __typename?: 'Subcat_Wood_min_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Subcat_Wood" */
export type Subcat_Wood_Mutation_Response = {
  __typename?: 'Subcat_Wood_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subcat_Wood>;
};

/** Ordering options when selecting data from "Subcat_Wood". */
export type Subcat_Wood_Order_By = {
  ComponentStatusId?: Maybe<Order_By>;
  DateModified?: Maybe<Order_By>;
  ModifiedByUserAccountId?: Maybe<Order_By>;
  approvedusecode?: Maybe<Order_By>;
  approveduseid?: Maybe<Order_By>;
  approvedusetablename?: Maybe<Order_By>;
  categoryname?: Maybe<Order_By>;
  componentid?: Maybe<Order_By>;
  datelocked?: Maybe<Order_By>;
  lockedbyuseraccountid?: Maybe<Order_By>;
  lockstatusid?: Maybe<Order_By>;
  manufacturerid?: Maybe<Order_By>;
  manufacturername?: Maybe<Order_By>;
  statusid?: Maybe<Order_By>;
  subcategoryname?: Maybe<Order_By>;
  tradename?: Maybe<Order_By>;
};

/** select columns of table "Subcat_Wood" */
export enum Subcat_Wood_Select_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** input type for updating data in table "Subcat_Wood" */
export type Subcat_Wood_Set_Input = {
  ComponentStatusId?: Maybe<Scalars['Int']>;
  DateModified?: Maybe<Scalars['datetime']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approvedusecode?: Maybe<Scalars['CodeTableValue']>;
  approveduseid?: Maybe<Scalars['Int']>;
  approvedusetablename?: Maybe<Scalars['String']>;
  categoryname?: Maybe<Scalars['String']>;
  componentid?: Maybe<Scalars['Int']>;
  datelocked?: Maybe<Scalars['datetime']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  manufacturername?: Maybe<Scalars['String']>;
  statusid?: Maybe<Scalars['Int']>;
  subcategoryname?: Maybe<Scalars['String']>;
  tradename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Subcat_Wood_Stddev_Fields = {
  __typename?: 'Subcat_Wood_stddev_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subcat_Wood_Stddev_Pop_Fields = {
  __typename?: 'Subcat_Wood_stddev_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subcat_Wood_Stddev_Samp_Fields = {
  __typename?: 'Subcat_Wood_stddev_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Subcat_Wood_Sum_Fields = {
  __typename?: 'Subcat_Wood_sum_fields';
  ComponentStatusId?: Maybe<Scalars['Int']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Int']>;
  approveduseid?: Maybe<Scalars['Int']>;
  componentid?: Maybe<Scalars['Int']>;
  lockedbyuseraccountid?: Maybe<Scalars['Int']>;
  lockstatusid?: Maybe<Scalars['Int']>;
  manufacturerid?: Maybe<Scalars['Int']>;
  statusid?: Maybe<Scalars['Int']>;
};

/** update columns of table "Subcat_Wood" */
export enum Subcat_Wood_Update_Column {
  /** column name */
  ComponentStatusId = 'ComponentStatusId',
  /** column name */
  DateModified = 'DateModified',
  /** column name */
  ModifiedByUserAccountId = 'ModifiedByUserAccountId',
  /** column name */
  Approvedusecode = 'approvedusecode',
  /** column name */
  Approveduseid = 'approveduseid',
  /** column name */
  Approvedusetablename = 'approvedusetablename',
  /** column name */
  Categoryname = 'categoryname',
  /** column name */
  Componentid = 'componentid',
  /** column name */
  Datelocked = 'datelocked',
  /** column name */
  Lockedbyuseraccountid = 'lockedbyuseraccountid',
  /** column name */
  Lockstatusid = 'lockstatusid',
  /** column name */
  Manufacturerid = 'manufacturerid',
  /** column name */
  Manufacturername = 'manufacturername',
  /** column name */
  Statusid = 'statusid',
  /** column name */
  Subcategoryname = 'subcategoryname',
  /** column name */
  Tradename = 'tradename'
}

/** aggregate var_pop on columns */
export type Subcat_Wood_Var_Pop_Fields = {
  __typename?: 'Subcat_Wood_var_pop_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subcat_Wood_Var_Samp_Fields = {
  __typename?: 'Subcat_Wood_var_samp_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subcat_Wood_Variance_Fields = {
  __typename?: 'Subcat_Wood_variance_fields';
  ComponentStatusId?: Maybe<Scalars['Float']>;
  ModifiedByUserAccountId?: Maybe<Scalars['Float']>;
  approveduseid?: Maybe<Scalars['Float']>;
  componentid?: Maybe<Scalars['Float']>;
  lockedbyuseraccountid?: Maybe<Scalars['Float']>;
  lockstatusid?: Maybe<Scalars['Float']>;
  manufacturerid?: Maybe<Scalars['Float']>;
  statusid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "SubstratePrimer" */
export type SubstratePrimer = {
  __typename?: 'SubstratePrimer';
  /** An object relationship */
  C_SubstratePrimerMaterial: C_SubstratePrimerMaterial;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  SubstratePrimerMaterialId: Scalars['Int'];
};

/** columns and relationships of "SubstratePrimerUsage" */
export type SubstratePrimerUsage = {
  __typename?: 'SubstratePrimerUsage';
  ApplicationRateDry: Scalars['MeasurementValue'];
  ApplicationRateWet: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "SubstratePrimerUsage" */
export type SubstratePrimerUsage_Aggregate = {
  __typename?: 'SubstratePrimerUsage_aggregate';
  aggregate?: Maybe<SubstratePrimerUsage_Aggregate_Fields>;
  nodes: Array<SubstratePrimerUsage>;
};

/** aggregate fields of "SubstratePrimerUsage" */
export type SubstratePrimerUsage_Aggregate_Fields = {
  __typename?: 'SubstratePrimerUsage_aggregate_fields';
  avg?: Maybe<SubstratePrimerUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SubstratePrimerUsage_Max_Fields>;
  min?: Maybe<SubstratePrimerUsage_Min_Fields>;
  stddev?: Maybe<SubstratePrimerUsage_Stddev_Fields>;
  stddev_pop?: Maybe<SubstratePrimerUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SubstratePrimerUsage_Stddev_Samp_Fields>;
  sum?: Maybe<SubstratePrimerUsage_Sum_Fields>;
  var_pop?: Maybe<SubstratePrimerUsage_Var_Pop_Fields>;
  var_samp?: Maybe<SubstratePrimerUsage_Var_Samp_Fields>;
  variance?: Maybe<SubstratePrimerUsage_Variance_Fields>;
};


/** aggregate fields of "SubstratePrimerUsage" */
export type SubstratePrimerUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<SubstratePrimerUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SubstratePrimerUsage_Avg_Fields = {
  __typename?: 'SubstratePrimerUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SubstratePrimerUsage". All fields are combined with a logical 'AND'. */
export type SubstratePrimerUsage_Bool_Exp = {
  ApplicationRateDry?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ApplicationRateWet?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SubstratePrimerUsage_Bool_Exp>>;
  _not?: Maybe<SubstratePrimerUsage_Bool_Exp>;
  _or?: Maybe<Array<SubstratePrimerUsage_Bool_Exp>>;
};

/** upsert condition type for table "SubstratePrimerUsage" */
export type SubstratePrimerUsage_If_Matched = {
  match_columns?: Array<SubstratePrimerUsage_Insert_Match_Column>;
  update_columns?: Array<SubstratePrimerUsage_Update_Column>;
  where?: Maybe<SubstratePrimerUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SubstratePrimerUsage" */
export type SubstratePrimerUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SubstratePrimerUsage" */
export type SubstratePrimerUsage_Insert_Input = {
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SubstratePrimerUsage" */
export enum SubstratePrimerUsage_Insert_Match_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type SubstratePrimerUsage_Max_Fields = {
  __typename?: 'SubstratePrimerUsage_max_fields';
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SubstratePrimerUsage_Min_Fields = {
  __typename?: 'SubstratePrimerUsage_min_fields';
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "SubstratePrimerUsage" */
export type SubstratePrimerUsage_Mutation_Response = {
  __typename?: 'SubstratePrimerUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SubstratePrimerUsage>;
};

/** Ordering options when selecting data from "SubstratePrimerUsage". */
export type SubstratePrimerUsage_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: SubstratePrimerUsage */
export type SubstratePrimerUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "SubstratePrimerUsage" */
export enum SubstratePrimerUsage_Select_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "SubstratePrimerUsage" */
export type SubstratePrimerUsage_Set_Input = {
  ApplicationRateDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateWet?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SubstratePrimerUsage_Stddev_Fields = {
  __typename?: 'SubstratePrimerUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SubstratePrimerUsage_Stddev_Pop_Fields = {
  __typename?: 'SubstratePrimerUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SubstratePrimerUsage_Stddev_Samp_Fields = {
  __typename?: 'SubstratePrimerUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SubstratePrimerUsage_Sum_Fields = {
  __typename?: 'SubstratePrimerUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "SubstratePrimerUsage" */
export enum SubstratePrimerUsage_Update_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type SubstratePrimerUsage_Var_Pop_Fields = {
  __typename?: 'SubstratePrimerUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SubstratePrimerUsage_Var_Samp_Fields = {
  __typename?: 'SubstratePrimerUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SubstratePrimerUsage_Variance_Fields = {
  __typename?: 'SubstratePrimerUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "SubstratePrimer" */
export type SubstratePrimer_Aggregate = {
  __typename?: 'SubstratePrimer_aggregate';
  aggregate?: Maybe<SubstratePrimer_Aggregate_Fields>;
  nodes: Array<SubstratePrimer>;
};

/** aggregate fields of "SubstratePrimer" */
export type SubstratePrimer_Aggregate_Fields = {
  __typename?: 'SubstratePrimer_aggregate_fields';
  avg?: Maybe<SubstratePrimer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SubstratePrimer_Max_Fields>;
  min?: Maybe<SubstratePrimer_Min_Fields>;
  stddev?: Maybe<SubstratePrimer_Stddev_Fields>;
  stddev_pop?: Maybe<SubstratePrimer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SubstratePrimer_Stddev_Samp_Fields>;
  sum?: Maybe<SubstratePrimer_Sum_Fields>;
  var_pop?: Maybe<SubstratePrimer_Var_Pop_Fields>;
  var_samp?: Maybe<SubstratePrimer_Var_Samp_Fields>;
  variance?: Maybe<SubstratePrimer_Variance_Fields>;
};


/** aggregate fields of "SubstratePrimer" */
export type SubstratePrimer_Aggregate_FieldsCountArgs = {
  column?: Maybe<SubstratePrimer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SubstratePrimer" */
export type SubstratePrimer_Aggregate_Order_By = {
  avg?: Maybe<SubstratePrimer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SubstratePrimer_Max_Order_By>;
  min?: Maybe<SubstratePrimer_Min_Order_By>;
  stddev?: Maybe<SubstratePrimer_Stddev_Order_By>;
  stddev_pop?: Maybe<SubstratePrimer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SubstratePrimer_Stddev_Samp_Order_By>;
  sum?: Maybe<SubstratePrimer_Sum_Order_By>;
  var_pop?: Maybe<SubstratePrimer_Var_Pop_Order_By>;
  var_samp?: Maybe<SubstratePrimer_Var_Samp_Order_By>;
  variance?: Maybe<SubstratePrimer_Variance_Order_By>;
};

/** aggregate avg on columns */
export type SubstratePrimer_Avg_Fields = {
  __typename?: 'SubstratePrimer_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SubstratePrimer" */
export type SubstratePrimer_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SubstratePrimerMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SubstratePrimer". All fields are combined with a logical 'AND'. */
export type SubstratePrimer_Bool_Exp = {
  C_SubstratePrimerMaterial?: Maybe<C_SubstratePrimerMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  SubstratePrimerMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SubstratePrimer_Bool_Exp>>;
  _not?: Maybe<SubstratePrimer_Bool_Exp>;
  _or?: Maybe<Array<SubstratePrimer_Bool_Exp>>;
};

/** upsert condition type for table "SubstratePrimer" */
export type SubstratePrimer_If_Matched = {
  match_columns?: Array<SubstratePrimer_Insert_Match_Column>;
  update_columns?: Array<SubstratePrimer_Update_Column>;
  where?: Maybe<SubstratePrimer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SubstratePrimer" */
export type SubstratePrimer_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SubstratePrimer" */
export type SubstratePrimer_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SubstratePrimer" */
export enum SubstratePrimer_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SubstratePrimerMaterialId = 'SubstratePrimerMaterialId'
}

/** aggregate max on columns */
export type SubstratePrimer_Max_Fields = {
  __typename?: 'SubstratePrimer_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "SubstratePrimer" */
export type SubstratePrimer_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SubstratePrimerMaterialId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SubstratePrimer_Min_Fields = {
  __typename?: 'SubstratePrimer_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "SubstratePrimer" */
export type SubstratePrimer_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SubstratePrimerMaterialId?: Maybe<Order_By>;
};

/** response of any mutation on the table "SubstratePrimer" */
export type SubstratePrimer_Mutation_Response = {
  __typename?: 'SubstratePrimer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SubstratePrimer>;
};

/** Ordering options when selecting data from "SubstratePrimer". */
export type SubstratePrimer_Order_By = {
  C_SubstratePrimerMaterial?: Maybe<C_SubstratePrimerMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  SubstratePrimerMaterialId?: Maybe<Order_By>;
};

/** primary key columns input for table: SubstratePrimer */
export type SubstratePrimer_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "SubstratePrimer" */
export enum SubstratePrimer_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SubstratePrimerMaterialId = 'SubstratePrimerMaterialId'
}

/** input type for updating data in table "SubstratePrimer" */
export type SubstratePrimer_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SubstratePrimer_Stddev_Fields = {
  __typename?: 'SubstratePrimer_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SubstratePrimer" */
export type SubstratePrimer_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SubstratePrimerMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SubstratePrimer_Stddev_Pop_Fields = {
  __typename?: 'SubstratePrimer_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SubstratePrimer" */
export type SubstratePrimer_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SubstratePrimerMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SubstratePrimer_Stddev_Samp_Fields = {
  __typename?: 'SubstratePrimer_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SubstratePrimer" */
export type SubstratePrimer_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SubstratePrimerMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type SubstratePrimer_Sum_Fields = {
  __typename?: 'SubstratePrimer_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "SubstratePrimer" */
export type SubstratePrimer_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SubstratePrimerMaterialId?: Maybe<Order_By>;
};

/** update columns of table "SubstratePrimer" */
export enum SubstratePrimer_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SubstratePrimerMaterialId = 'SubstratePrimerMaterialId'
}

/** aggregate var_pop on columns */
export type SubstratePrimer_Var_Pop_Fields = {
  __typename?: 'SubstratePrimer_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SubstratePrimer" */
export type SubstratePrimer_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SubstratePrimerMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SubstratePrimer_Var_Samp_Fields = {
  __typename?: 'SubstratePrimer_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SubstratePrimer" */
export type SubstratePrimer_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SubstratePrimerMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SubstratePrimer_Variance_Fields = {
  __typename?: 'SubstratePrimer_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SubstratePrimerMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SubstratePrimer" */
export type SubstratePrimer_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SubstratePrimerMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "Surfacing" */
export type Surfacing = {
  __typename?: 'Surfacing';
  /** An object relationship */
  C_SurfacingMaterial: C_SurfacingMaterial;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  SurfacingMaterialId: Scalars['Int'];
};

/** columns and relationships of "SurfacingUsage" */
export type SurfacingUsage = {
  __typename?: 'SurfacingUsage';
  ApplicationRate: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "SurfacingUsage" */
export type SurfacingUsage_Aggregate = {
  __typename?: 'SurfacingUsage_aggregate';
  aggregate?: Maybe<SurfacingUsage_Aggregate_Fields>;
  nodes: Array<SurfacingUsage>;
};

/** aggregate fields of "SurfacingUsage" */
export type SurfacingUsage_Aggregate_Fields = {
  __typename?: 'SurfacingUsage_aggregate_fields';
  avg?: Maybe<SurfacingUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SurfacingUsage_Max_Fields>;
  min?: Maybe<SurfacingUsage_Min_Fields>;
  stddev?: Maybe<SurfacingUsage_Stddev_Fields>;
  stddev_pop?: Maybe<SurfacingUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SurfacingUsage_Stddev_Samp_Fields>;
  sum?: Maybe<SurfacingUsage_Sum_Fields>;
  var_pop?: Maybe<SurfacingUsage_Var_Pop_Fields>;
  var_samp?: Maybe<SurfacingUsage_Var_Samp_Fields>;
  variance?: Maybe<SurfacingUsage_Variance_Fields>;
};


/** aggregate fields of "SurfacingUsage" */
export type SurfacingUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<SurfacingUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SurfacingUsage_Avg_Fields = {
  __typename?: 'SurfacingUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "SurfacingUsage". All fields are combined with a logical 'AND'. */
export type SurfacingUsage_Bool_Exp = {
  ApplicationRate?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<SurfacingUsage_Bool_Exp>>;
  _not?: Maybe<SurfacingUsage_Bool_Exp>;
  _or?: Maybe<Array<SurfacingUsage_Bool_Exp>>;
};

/** upsert condition type for table "SurfacingUsage" */
export type SurfacingUsage_If_Matched = {
  match_columns?: Array<SurfacingUsage_Insert_Match_Column>;
  update_columns?: Array<SurfacingUsage_Update_Column>;
  where?: Maybe<SurfacingUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "SurfacingUsage" */
export type SurfacingUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SurfacingUsage" */
export type SurfacingUsage_Insert_Input = {
  ApplicationRate?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "SurfacingUsage" */
export enum SurfacingUsage_Insert_Match_Column {
  /** column name */
  ApplicationRate = 'ApplicationRate',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type SurfacingUsage_Max_Fields = {
  __typename?: 'SurfacingUsage_max_fields';
  ApplicationRate?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SurfacingUsage_Min_Fields = {
  __typename?: 'SurfacingUsage_min_fields';
  ApplicationRate?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "SurfacingUsage" */
export type SurfacingUsage_Mutation_Response = {
  __typename?: 'SurfacingUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SurfacingUsage>;
};

/** Ordering options when selecting data from "SurfacingUsage". */
export type SurfacingUsage_Order_By = {
  ApplicationRate?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: SurfacingUsage */
export type SurfacingUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "SurfacingUsage" */
export enum SurfacingUsage_Select_Column {
  /** column name */
  ApplicationRate = 'ApplicationRate',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "SurfacingUsage" */
export type SurfacingUsage_Set_Input = {
  ApplicationRate?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SurfacingUsage_Stddev_Fields = {
  __typename?: 'SurfacingUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SurfacingUsage_Stddev_Pop_Fields = {
  __typename?: 'SurfacingUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SurfacingUsage_Stddev_Samp_Fields = {
  __typename?: 'SurfacingUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SurfacingUsage_Sum_Fields = {
  __typename?: 'SurfacingUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "SurfacingUsage" */
export enum SurfacingUsage_Update_Column {
  /** column name */
  ApplicationRate = 'ApplicationRate',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type SurfacingUsage_Var_Pop_Fields = {
  __typename?: 'SurfacingUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SurfacingUsage_Var_Samp_Fields = {
  __typename?: 'SurfacingUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SurfacingUsage_Variance_Fields = {
  __typename?: 'SurfacingUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Surfacing" */
export type Surfacing_Aggregate = {
  __typename?: 'Surfacing_aggregate';
  aggregate?: Maybe<Surfacing_Aggregate_Fields>;
  nodes: Array<Surfacing>;
};

/** aggregate fields of "Surfacing" */
export type Surfacing_Aggregate_Fields = {
  __typename?: 'Surfacing_aggregate_fields';
  avg?: Maybe<Surfacing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Surfacing_Max_Fields>;
  min?: Maybe<Surfacing_Min_Fields>;
  stddev?: Maybe<Surfacing_Stddev_Fields>;
  stddev_pop?: Maybe<Surfacing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Surfacing_Stddev_Samp_Fields>;
  sum?: Maybe<Surfacing_Sum_Fields>;
  var_pop?: Maybe<Surfacing_Var_Pop_Fields>;
  var_samp?: Maybe<Surfacing_Var_Samp_Fields>;
  variance?: Maybe<Surfacing_Variance_Fields>;
};


/** aggregate fields of "Surfacing" */
export type Surfacing_Aggregate_FieldsCountArgs = {
  column?: Maybe<Surfacing_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Surfacing" */
export type Surfacing_Aggregate_Order_By = {
  avg?: Maybe<Surfacing_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Surfacing_Max_Order_By>;
  min?: Maybe<Surfacing_Min_Order_By>;
  stddev?: Maybe<Surfacing_Stddev_Order_By>;
  stddev_pop?: Maybe<Surfacing_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Surfacing_Stddev_Samp_Order_By>;
  sum?: Maybe<Surfacing_Sum_Order_By>;
  var_pop?: Maybe<Surfacing_Var_Pop_Order_By>;
  var_samp?: Maybe<Surfacing_Var_Samp_Order_By>;
  variance?: Maybe<Surfacing_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Surfacing_Avg_Fields = {
  __typename?: 'Surfacing_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Surfacing" */
export type Surfacing_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Surfacing". All fields are combined with a logical 'AND'. */
export type Surfacing_Bool_Exp = {
  C_SurfacingMaterial?: Maybe<C_SurfacingMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  SurfacingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<Surfacing_Bool_Exp>>;
  _not?: Maybe<Surfacing_Bool_Exp>;
  _or?: Maybe<Array<Surfacing_Bool_Exp>>;
};

/** upsert condition type for table "Surfacing" */
export type Surfacing_If_Matched = {
  match_columns?: Array<Surfacing_Insert_Match_Column>;
  update_columns?: Array<Surfacing_Update_Column>;
  where?: Maybe<Surfacing_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Surfacing" */
export type Surfacing_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Surfacing" */
export type Surfacing_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "Surfacing" */
export enum Surfacing_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SurfacingMaterialId = 'SurfacingMaterialId'
}

/** aggregate max on columns */
export type Surfacing_Max_Fields = {
  __typename?: 'Surfacing_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Surfacing" */
export type Surfacing_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Surfacing_Min_Fields = {
  __typename?: 'Surfacing_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Surfacing" */
export type Surfacing_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Surfacing" */
export type Surfacing_Mutation_Response = {
  __typename?: 'Surfacing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Surfacing>;
};

/** Ordering options when selecting data from "Surfacing". */
export type Surfacing_Order_By = {
  C_SurfacingMaterial?: Maybe<C_SurfacingMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
};

/** primary key columns input for table: Surfacing */
export type Surfacing_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "Surfacing" */
export enum Surfacing_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SurfacingMaterialId = 'SurfacingMaterialId'
}

/** input type for updating data in table "Surfacing" */
export type Surfacing_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Surfacing_Stddev_Fields = {
  __typename?: 'Surfacing_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Surfacing" */
export type Surfacing_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Surfacing_Stddev_Pop_Fields = {
  __typename?: 'Surfacing_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Surfacing" */
export type Surfacing_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Surfacing_Stddev_Samp_Fields = {
  __typename?: 'Surfacing_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Surfacing" */
export type Surfacing_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Surfacing_Sum_Fields = {
  __typename?: 'Surfacing_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Surfacing" */
export type Surfacing_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
};

/** update columns of table "Surfacing" */
export enum Surfacing_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SurfacingMaterialId = 'SurfacingMaterialId'
}

/** aggregate var_pop on columns */
export type Surfacing_Var_Pop_Fields = {
  __typename?: 'Surfacing_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Surfacing" */
export type Surfacing_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Surfacing_Var_Samp_Fields = {
  __typename?: 'Surfacing_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Surfacing" */
export type Surfacing_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Surfacing_Variance_Fields = {
  __typename?: 'Surfacing_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Surfacing" */
export type Surfacing_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "TapeWidth" */
export type TapeWidth = {
  __typename?: 'TapeWidth';
  EnteredAsMetric: Scalars['MetricEntryFlag'];
  /** An array relationship */
  R_AdhesiveTapeWidths: Array<R_AdhesiveTapeWidth>;
  /** An aggregate relationship */
  R_AdhesiveTapeWidths_aggregate: R_AdhesiveTapeWidth_Aggregate;
  TapeWidthId: Scalars['Int'];
  Width: Scalars['MeasurementValue'];
};


/** columns and relationships of "TapeWidth" */
export type TapeWidthR_AdhesiveTapeWidthsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_AdhesiveTapeWidth_Order_By>>;
  where?: Maybe<R_AdhesiveTapeWidth_Bool_Exp>;
};


/** columns and relationships of "TapeWidth" */
export type TapeWidthR_AdhesiveTapeWidths_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_AdhesiveTapeWidth_Order_By>>;
  where?: Maybe<R_AdhesiveTapeWidth_Bool_Exp>;
};

/** aggregated selection of "TapeWidth" */
export type TapeWidth_Aggregate = {
  __typename?: 'TapeWidth_aggregate';
  aggregate?: Maybe<TapeWidth_Aggregate_Fields>;
  nodes: Array<TapeWidth>;
};

/** aggregate fields of "TapeWidth" */
export type TapeWidth_Aggregate_Fields = {
  __typename?: 'TapeWidth_aggregate_fields';
  avg?: Maybe<TapeWidth_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TapeWidth_Max_Fields>;
  min?: Maybe<TapeWidth_Min_Fields>;
  stddev?: Maybe<TapeWidth_Stddev_Fields>;
  stddev_pop?: Maybe<TapeWidth_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TapeWidth_Stddev_Samp_Fields>;
  sum?: Maybe<TapeWidth_Sum_Fields>;
  var_pop?: Maybe<TapeWidth_Var_Pop_Fields>;
  var_samp?: Maybe<TapeWidth_Var_Samp_Fields>;
  variance?: Maybe<TapeWidth_Variance_Fields>;
};


/** aggregate fields of "TapeWidth" */
export type TapeWidth_Aggregate_FieldsCountArgs = {
  column?: Maybe<TapeWidth_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TapeWidth_Avg_Fields = {
  __typename?: 'TapeWidth_avg_fields';
  TapeWidthId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "TapeWidth". All fields are combined with a logical 'AND'. */
export type TapeWidth_Bool_Exp = {
  EnteredAsMetric?: Maybe<MetricEntryFlag_Mssql_Comparison_Exp>;
  R_AdhesiveTapeWidths?: Maybe<R_AdhesiveTapeWidth_Bool_Exp>;
  TapeWidthId?: Maybe<Int_Mssql_Comparison_Exp>;
  Width?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<TapeWidth_Bool_Exp>>;
  _not?: Maybe<TapeWidth_Bool_Exp>;
  _or?: Maybe<Array<TapeWidth_Bool_Exp>>;
};

/** upsert condition type for table "TapeWidth" */
export type TapeWidth_If_Matched = {
  match_columns?: Array<TapeWidth_Insert_Match_Column>;
  update_columns?: Array<TapeWidth_Update_Column>;
  where?: Maybe<TapeWidth_Bool_Exp>;
};

/** input type for inserting data into table "TapeWidth" */
export type TapeWidth_Insert_Input = {
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "TapeWidth" */
export enum TapeWidth_Insert_Match_Column {
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  TapeWidthId = 'TapeWidthId',
  /** column name */
  Width = 'Width'
}

/** aggregate max on columns */
export type TapeWidth_Max_Fields = {
  __typename?: 'TapeWidth_max_fields';
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  TapeWidthId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate min on columns */
export type TapeWidth_Min_Fields = {
  __typename?: 'TapeWidth_min_fields';
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  TapeWidthId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** response of any mutation on the table "TapeWidth" */
export type TapeWidth_Mutation_Response = {
  __typename?: 'TapeWidth_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TapeWidth>;
};

/** Ordering options when selecting data from "TapeWidth". */
export type TapeWidth_Order_By = {
  EnteredAsMetric?: Maybe<Order_By>;
  R_AdhesiveTapeWidths_aggregate?: Maybe<R_AdhesiveTapeWidth_Aggregate_Order_By>;
  TapeWidthId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** primary key columns input for table: TapeWidth */
export type TapeWidth_Pk_Columns_Input = {
  TapeWidthId: Scalars['Int'];
};

/** select columns of table "TapeWidth" */
export enum TapeWidth_Select_Column {
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  TapeWidthId = 'TapeWidthId',
  /** column name */
  Width = 'Width'
}

/** input type for updating data in table "TapeWidth" */
export type TapeWidth_Set_Input = {
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  Width?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type TapeWidth_Stddev_Fields = {
  __typename?: 'TapeWidth_stddev_fields';
  TapeWidthId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TapeWidth_Stddev_Pop_Fields = {
  __typename?: 'TapeWidth_stddev_pop_fields';
  TapeWidthId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TapeWidth_Stddev_Samp_Fields = {
  __typename?: 'TapeWidth_stddev_samp_fields';
  TapeWidthId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TapeWidth_Sum_Fields = {
  __typename?: 'TapeWidth_sum_fields';
  TapeWidthId?: Maybe<Scalars['Int']>;
};

/** update columns of table "TapeWidth" */
export enum TapeWidth_Update_Column {
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  Width = 'Width'
}

/** aggregate var_pop on columns */
export type TapeWidth_Var_Pop_Fields = {
  __typename?: 'TapeWidth_var_pop_fields';
  TapeWidthId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TapeWidth_Var_Samp_Fields = {
  __typename?: 'TapeWidth_var_samp_fields';
  TapeWidthId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TapeWidth_Variance_Fields = {
  __typename?: 'TapeWidth_variance_fields';
  TapeWidthId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ThermalBarrier" */
export type ThermalBarrier = {
  __typename?: 'ThermalBarrier';
  BoardProfileId: Scalars['Int'];
  /** An object relationship */
  C_BoardProfile: C_BoardProfile;
  /** An object relationship */
  C_ThermalBarrierMaterial: C_ThermalBarrierMaterial;
  ComponentId: Scalars['Int'];
  DensityMin: Scalars['MeasurementValue'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  /** An array relationship */
  ThermalBarrierBoardSizes: Array<ThermalBarrierBoardSizes>;
  /** An aggregate relationship */
  ThermalBarrierBoardSizes_aggregate: ThermalBarrierBoardSizes_Aggregate;
  /** An array relationship */
  ThermalBarrierFacerBottoms: Array<ThermalBarrierFacerBottom>;
  /** An aggregate relationship */
  ThermalBarrierFacerBottoms_aggregate: ThermalBarrierFacerBottom_Aggregate;
  /** An array relationship */
  ThermalBarrierFacerTops: Array<ThermalBarrierFacerTop>;
  /** An aggregate relationship */
  ThermalBarrierFacerTops_aggregate: ThermalBarrierFacerTop_Aggregate;
  ThermalBarrierMaterialId: Scalars['Int'];
  ThicknessMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
};


/** columns and relationships of "ThermalBarrier" */
export type ThermalBarrierThermalBarrierBoardSizesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierBoardSizes_Order_By>>;
  where?: Maybe<ThermalBarrierBoardSizes_Bool_Exp>;
};


/** columns and relationships of "ThermalBarrier" */
export type ThermalBarrierThermalBarrierBoardSizes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierBoardSizes_Order_By>>;
  where?: Maybe<ThermalBarrierBoardSizes_Bool_Exp>;
};


/** columns and relationships of "ThermalBarrier" */
export type ThermalBarrierThermalBarrierFacerBottomsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerBottom_Order_By>>;
  where?: Maybe<ThermalBarrierFacerBottom_Bool_Exp>;
};


/** columns and relationships of "ThermalBarrier" */
export type ThermalBarrierThermalBarrierFacerBottoms_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerBottom_Order_By>>;
  where?: Maybe<ThermalBarrierFacerBottom_Bool_Exp>;
};


/** columns and relationships of "ThermalBarrier" */
export type ThermalBarrierThermalBarrierFacerTopsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerTop_Order_By>>;
  where?: Maybe<ThermalBarrierFacerTop_Bool_Exp>;
};


/** columns and relationships of "ThermalBarrier" */
export type ThermalBarrierThermalBarrierFacerTops_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerTop_Order_By>>;
  where?: Maybe<ThermalBarrierFacerTop_Bool_Exp>;
};

/** columns and relationships of "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes = {
  __typename?: 'ThermalBarrierBoardSizes';
  ComponentId: Scalars['Int'];
  /** An object relationship */
  InsBoardSize: InsBoardSize;
  InsBoardSizeId: Scalars['Int'];
  /** An object relationship */
  ThermalBarrier: ThermalBarrier;
};

/** aggregated selection of "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Aggregate = {
  __typename?: 'ThermalBarrierBoardSizes_aggregate';
  aggregate?: Maybe<ThermalBarrierBoardSizes_Aggregate_Fields>;
  nodes: Array<ThermalBarrierBoardSizes>;
};

/** aggregate fields of "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Aggregate_Fields = {
  __typename?: 'ThermalBarrierBoardSizes_aggregate_fields';
  avg?: Maybe<ThermalBarrierBoardSizes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ThermalBarrierBoardSizes_Max_Fields>;
  min?: Maybe<ThermalBarrierBoardSizes_Min_Fields>;
  stddev?: Maybe<ThermalBarrierBoardSizes_Stddev_Fields>;
  stddev_pop?: Maybe<ThermalBarrierBoardSizes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ThermalBarrierBoardSizes_Stddev_Samp_Fields>;
  sum?: Maybe<ThermalBarrierBoardSizes_Sum_Fields>;
  var_pop?: Maybe<ThermalBarrierBoardSizes_Var_Pop_Fields>;
  var_samp?: Maybe<ThermalBarrierBoardSizes_Var_Samp_Fields>;
  variance?: Maybe<ThermalBarrierBoardSizes_Variance_Fields>;
};


/** aggregate fields of "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Aggregate_FieldsCountArgs = {
  column?: Maybe<ThermalBarrierBoardSizes_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Aggregate_Order_By = {
  avg?: Maybe<ThermalBarrierBoardSizes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ThermalBarrierBoardSizes_Max_Order_By>;
  min?: Maybe<ThermalBarrierBoardSizes_Min_Order_By>;
  stddev?: Maybe<ThermalBarrierBoardSizes_Stddev_Order_By>;
  stddev_pop?: Maybe<ThermalBarrierBoardSizes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ThermalBarrierBoardSizes_Stddev_Samp_Order_By>;
  sum?: Maybe<ThermalBarrierBoardSizes_Sum_Order_By>;
  var_pop?: Maybe<ThermalBarrierBoardSizes_Var_Pop_Order_By>;
  var_samp?: Maybe<ThermalBarrierBoardSizes_Var_Samp_Order_By>;
  variance?: Maybe<ThermalBarrierBoardSizes_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ThermalBarrierBoardSizes_Avg_Fields = {
  __typename?: 'ThermalBarrierBoardSizes_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ThermalBarrierBoardSizes". All fields are combined with a logical 'AND'. */
export type ThermalBarrierBoardSizes_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  InsBoardSize?: Maybe<InsBoardSize_Bool_Exp>;
  InsBoardSizeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThermalBarrier?: Maybe<ThermalBarrier_Bool_Exp>;
  _and?: Maybe<Array<ThermalBarrierBoardSizes_Bool_Exp>>;
  _not?: Maybe<ThermalBarrierBoardSizes_Bool_Exp>;
  _or?: Maybe<Array<ThermalBarrierBoardSizes_Bool_Exp>>;
};

/** upsert condition type for table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_If_Matched = {
  match_columns?: Array<ThermalBarrierBoardSizes_Insert_Match_Column>;
  update_columns?: Array<ThermalBarrierBoardSizes_Update_Column>;
  where?: Maybe<ThermalBarrierBoardSizes_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ThermalBarrierBoardSizes" */
export enum ThermalBarrierBoardSizes_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId'
}

/** aggregate max on columns */
export type ThermalBarrierBoardSizes_Max_Fields = {
  __typename?: 'ThermalBarrierBoardSizes_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ThermalBarrierBoardSizes_Min_Fields = {
  __typename?: 'ThermalBarrierBoardSizes_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Mutation_Response = {
  __typename?: 'ThermalBarrierBoardSizes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ThermalBarrierBoardSizes>;
};

/** Ordering options when selecting data from "ThermalBarrierBoardSizes". */
export type ThermalBarrierBoardSizes_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSize?: Maybe<InsBoardSize_Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
  ThermalBarrier?: Maybe<ThermalBarrier_Order_By>;
};

/** primary key columns input for table: ThermalBarrierBoardSizes */
export type ThermalBarrierBoardSizes_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  InsBoardSizeId: Scalars['Int'];
};

/** select columns of table "ThermalBarrierBoardSizes" */
export enum ThermalBarrierBoardSizes_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId'
}

/** input type for updating data in table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ThermalBarrierBoardSizes_Stddev_Fields = {
  __typename?: 'ThermalBarrierBoardSizes_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ThermalBarrierBoardSizes_Stddev_Pop_Fields = {
  __typename?: 'ThermalBarrierBoardSizes_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ThermalBarrierBoardSizes_Stddev_Samp_Fields = {
  __typename?: 'ThermalBarrierBoardSizes_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ThermalBarrierBoardSizes_Sum_Fields = {
  __typename?: 'ThermalBarrierBoardSizes_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  InsBoardSizeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** update columns of table "ThermalBarrierBoardSizes" */
export enum ThermalBarrierBoardSizes_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId'
}

/** aggregate var_pop on columns */
export type ThermalBarrierBoardSizes_Var_Pop_Fields = {
  __typename?: 'ThermalBarrierBoardSizes_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ThermalBarrierBoardSizes_Var_Samp_Fields = {
  __typename?: 'ThermalBarrierBoardSizes_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ThermalBarrierBoardSizes_Variance_Fields = {
  __typename?: 'ThermalBarrierBoardSizes_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  InsBoardSizeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ThermalBarrierBoardSizes" */
export type ThermalBarrierBoardSizes_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
};

/** columns and relationships of "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom = {
  __typename?: 'ThermalBarrierFacerBottom';
  /** An object relationship */
  C_Facer: C_Facer;
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
  /** An object relationship */
  ThermalBarrier: ThermalBarrier;
};

/** aggregated selection of "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Aggregate = {
  __typename?: 'ThermalBarrierFacerBottom_aggregate';
  aggregate?: Maybe<ThermalBarrierFacerBottom_Aggregate_Fields>;
  nodes: Array<ThermalBarrierFacerBottom>;
};

/** aggregate fields of "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Aggregate_Fields = {
  __typename?: 'ThermalBarrierFacerBottom_aggregate_fields';
  avg?: Maybe<ThermalBarrierFacerBottom_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ThermalBarrierFacerBottom_Max_Fields>;
  min?: Maybe<ThermalBarrierFacerBottom_Min_Fields>;
  stddev?: Maybe<ThermalBarrierFacerBottom_Stddev_Fields>;
  stddev_pop?: Maybe<ThermalBarrierFacerBottom_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ThermalBarrierFacerBottom_Stddev_Samp_Fields>;
  sum?: Maybe<ThermalBarrierFacerBottom_Sum_Fields>;
  var_pop?: Maybe<ThermalBarrierFacerBottom_Var_Pop_Fields>;
  var_samp?: Maybe<ThermalBarrierFacerBottom_Var_Samp_Fields>;
  variance?: Maybe<ThermalBarrierFacerBottom_Variance_Fields>;
};


/** aggregate fields of "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Aggregate_FieldsCountArgs = {
  column?: Maybe<ThermalBarrierFacerBottom_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Aggregate_Order_By = {
  avg?: Maybe<ThermalBarrierFacerBottom_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ThermalBarrierFacerBottom_Max_Order_By>;
  min?: Maybe<ThermalBarrierFacerBottom_Min_Order_By>;
  stddev?: Maybe<ThermalBarrierFacerBottom_Stddev_Order_By>;
  stddev_pop?: Maybe<ThermalBarrierFacerBottom_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ThermalBarrierFacerBottom_Stddev_Samp_Order_By>;
  sum?: Maybe<ThermalBarrierFacerBottom_Sum_Order_By>;
  var_pop?: Maybe<ThermalBarrierFacerBottom_Var_Pop_Order_By>;
  var_samp?: Maybe<ThermalBarrierFacerBottom_Var_Samp_Order_By>;
  variance?: Maybe<ThermalBarrierFacerBottom_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ThermalBarrierFacerBottom_Avg_Fields = {
  __typename?: 'ThermalBarrierFacerBottom_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ThermalBarrierFacerBottom". All fields are combined with a logical 'AND'. */
export type ThermalBarrierFacerBottom_Bool_Exp = {
  C_Facer?: Maybe<C_Facer_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  FacerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThermalBarrier?: Maybe<ThermalBarrier_Bool_Exp>;
  _and?: Maybe<Array<ThermalBarrierFacerBottom_Bool_Exp>>;
  _not?: Maybe<ThermalBarrierFacerBottom_Bool_Exp>;
  _or?: Maybe<Array<ThermalBarrierFacerBottom_Bool_Exp>>;
};

/** upsert condition type for table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_If_Matched = {
  match_columns?: Array<ThermalBarrierFacerBottom_Insert_Match_Column>;
  update_columns?: Array<ThermalBarrierFacerBottom_Update_Column>;
  where?: Maybe<ThermalBarrierFacerBottom_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ThermalBarrierFacerBottom" */
export enum ThermalBarrierFacerBottom_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** aggregate max on columns */
export type ThermalBarrierFacerBottom_Max_Fields = {
  __typename?: 'ThermalBarrierFacerBottom_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ThermalBarrierFacerBottom_Min_Fields = {
  __typename?: 'ThermalBarrierFacerBottom_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Mutation_Response = {
  __typename?: 'ThermalBarrierFacerBottom_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ThermalBarrierFacerBottom>;
};

/** Ordering options when selecting data from "ThermalBarrierFacerBottom". */
export type ThermalBarrierFacerBottom_Order_By = {
  C_Facer?: Maybe<C_Facer_Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
  ThermalBarrier?: Maybe<ThermalBarrier_Order_By>;
};

/** primary key columns input for table: ThermalBarrierFacerBottom */
export type ThermalBarrierFacerBottom_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};

/** select columns of table "ThermalBarrierFacerBottom" */
export enum ThermalBarrierFacerBottom_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** input type for updating data in table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ThermalBarrierFacerBottom_Stddev_Fields = {
  __typename?: 'ThermalBarrierFacerBottom_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ThermalBarrierFacerBottom_Stddev_Pop_Fields = {
  __typename?: 'ThermalBarrierFacerBottom_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ThermalBarrierFacerBottom_Stddev_Samp_Fields = {
  __typename?: 'ThermalBarrierFacerBottom_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ThermalBarrierFacerBottom_Sum_Fields = {
  __typename?: 'ThermalBarrierFacerBottom_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** update columns of table "ThermalBarrierFacerBottom" */
export enum ThermalBarrierFacerBottom_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** aggregate var_pop on columns */
export type ThermalBarrierFacerBottom_Var_Pop_Fields = {
  __typename?: 'ThermalBarrierFacerBottom_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ThermalBarrierFacerBottom_Var_Samp_Fields = {
  __typename?: 'ThermalBarrierFacerBottom_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ThermalBarrierFacerBottom_Variance_Fields = {
  __typename?: 'ThermalBarrierFacerBottom_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ThermalBarrierFacerBottom" */
export type ThermalBarrierFacerBottom_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** columns and relationships of "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop = {
  __typename?: 'ThermalBarrierFacerTop';
  /** An object relationship */
  C_Facer: C_Facer;
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
  /** An object relationship */
  ThermalBarrier: ThermalBarrier;
};

/** aggregated selection of "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Aggregate = {
  __typename?: 'ThermalBarrierFacerTop_aggregate';
  aggregate?: Maybe<ThermalBarrierFacerTop_Aggregate_Fields>;
  nodes: Array<ThermalBarrierFacerTop>;
};

/** aggregate fields of "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Aggregate_Fields = {
  __typename?: 'ThermalBarrierFacerTop_aggregate_fields';
  avg?: Maybe<ThermalBarrierFacerTop_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ThermalBarrierFacerTop_Max_Fields>;
  min?: Maybe<ThermalBarrierFacerTop_Min_Fields>;
  stddev?: Maybe<ThermalBarrierFacerTop_Stddev_Fields>;
  stddev_pop?: Maybe<ThermalBarrierFacerTop_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ThermalBarrierFacerTop_Stddev_Samp_Fields>;
  sum?: Maybe<ThermalBarrierFacerTop_Sum_Fields>;
  var_pop?: Maybe<ThermalBarrierFacerTop_Var_Pop_Fields>;
  var_samp?: Maybe<ThermalBarrierFacerTop_Var_Samp_Fields>;
  variance?: Maybe<ThermalBarrierFacerTop_Variance_Fields>;
};


/** aggregate fields of "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Aggregate_FieldsCountArgs = {
  column?: Maybe<ThermalBarrierFacerTop_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Aggregate_Order_By = {
  avg?: Maybe<ThermalBarrierFacerTop_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ThermalBarrierFacerTop_Max_Order_By>;
  min?: Maybe<ThermalBarrierFacerTop_Min_Order_By>;
  stddev?: Maybe<ThermalBarrierFacerTop_Stddev_Order_By>;
  stddev_pop?: Maybe<ThermalBarrierFacerTop_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ThermalBarrierFacerTop_Stddev_Samp_Order_By>;
  sum?: Maybe<ThermalBarrierFacerTop_Sum_Order_By>;
  var_pop?: Maybe<ThermalBarrierFacerTop_Var_Pop_Order_By>;
  var_samp?: Maybe<ThermalBarrierFacerTop_Var_Samp_Order_By>;
  variance?: Maybe<ThermalBarrierFacerTop_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ThermalBarrierFacerTop_Avg_Fields = {
  __typename?: 'ThermalBarrierFacerTop_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ThermalBarrierFacerTop". All fields are combined with a logical 'AND'. */
export type ThermalBarrierFacerTop_Bool_Exp = {
  C_Facer?: Maybe<C_Facer_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  FacerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThermalBarrier?: Maybe<ThermalBarrier_Bool_Exp>;
  _and?: Maybe<Array<ThermalBarrierFacerTop_Bool_Exp>>;
  _not?: Maybe<ThermalBarrierFacerTop_Bool_Exp>;
  _or?: Maybe<Array<ThermalBarrierFacerTop_Bool_Exp>>;
};

/** upsert condition type for table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_If_Matched = {
  match_columns?: Array<ThermalBarrierFacerTop_Insert_Match_Column>;
  update_columns?: Array<ThermalBarrierFacerTop_Update_Column>;
  where?: Maybe<ThermalBarrierFacerTop_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ThermalBarrierFacerTop" */
export enum ThermalBarrierFacerTop_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** aggregate max on columns */
export type ThermalBarrierFacerTop_Max_Fields = {
  __typename?: 'ThermalBarrierFacerTop_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ThermalBarrierFacerTop_Min_Fields = {
  __typename?: 'ThermalBarrierFacerTop_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Mutation_Response = {
  __typename?: 'ThermalBarrierFacerTop_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ThermalBarrierFacerTop>;
};

/** Ordering options when selecting data from "ThermalBarrierFacerTop". */
export type ThermalBarrierFacerTop_Order_By = {
  C_Facer?: Maybe<C_Facer_Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
  ThermalBarrier?: Maybe<ThermalBarrier_Order_By>;
};

/** primary key columns input for table: ThermalBarrierFacerTop */
export type ThermalBarrierFacerTop_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};

/** select columns of table "ThermalBarrierFacerTop" */
export enum ThermalBarrierFacerTop_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** input type for updating data in table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ThermalBarrierFacerTop_Stddev_Fields = {
  __typename?: 'ThermalBarrierFacerTop_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ThermalBarrierFacerTop_Stddev_Pop_Fields = {
  __typename?: 'ThermalBarrierFacerTop_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ThermalBarrierFacerTop_Stddev_Samp_Fields = {
  __typename?: 'ThermalBarrierFacerTop_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ThermalBarrierFacerTop_Sum_Fields = {
  __typename?: 'ThermalBarrierFacerTop_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  FacerId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** update columns of table "ThermalBarrierFacerTop" */
export enum ThermalBarrierFacerTop_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerId = 'FacerId'
}

/** aggregate var_pop on columns */
export type ThermalBarrierFacerTop_Var_Pop_Fields = {
  __typename?: 'ThermalBarrierFacerTop_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ThermalBarrierFacerTop_Var_Samp_Fields = {
  __typename?: 'ThermalBarrierFacerTop_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ThermalBarrierFacerTop_Variance_Fields = {
  __typename?: 'ThermalBarrierFacerTop_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  FacerId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ThermalBarrierFacerTop" */
export type ThermalBarrierFacerTop_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  FacerId?: Maybe<Order_By>;
};

/** columns and relationships of "ThermalBarrierUsage" */
export type ThermalBarrierUsage = {
  __typename?: 'ThermalBarrierUsage';
  /** An object relationship */
  InsBoardSize: InsBoardSize;
  InsBoardSizeId: Scalars['Int'];
  ThicknessMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Aggregate = {
  __typename?: 'ThermalBarrierUsage_aggregate';
  aggregate?: Maybe<ThermalBarrierUsage_Aggregate_Fields>;
  nodes: Array<ThermalBarrierUsage>;
};

/** aggregate fields of "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Aggregate_Fields = {
  __typename?: 'ThermalBarrierUsage_aggregate_fields';
  avg?: Maybe<ThermalBarrierUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ThermalBarrierUsage_Max_Fields>;
  min?: Maybe<ThermalBarrierUsage_Min_Fields>;
  stddev?: Maybe<ThermalBarrierUsage_Stddev_Fields>;
  stddev_pop?: Maybe<ThermalBarrierUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ThermalBarrierUsage_Stddev_Samp_Fields>;
  sum?: Maybe<ThermalBarrierUsage_Sum_Fields>;
  var_pop?: Maybe<ThermalBarrierUsage_Var_Pop_Fields>;
  var_samp?: Maybe<ThermalBarrierUsage_Var_Samp_Fields>;
  variance?: Maybe<ThermalBarrierUsage_Variance_Fields>;
};


/** aggregate fields of "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<ThermalBarrierUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Aggregate_Order_By = {
  avg?: Maybe<ThermalBarrierUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ThermalBarrierUsage_Max_Order_By>;
  min?: Maybe<ThermalBarrierUsage_Min_Order_By>;
  stddev?: Maybe<ThermalBarrierUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<ThermalBarrierUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ThermalBarrierUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<ThermalBarrierUsage_Sum_Order_By>;
  var_pop?: Maybe<ThermalBarrierUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<ThermalBarrierUsage_Var_Samp_Order_By>;
  variance?: Maybe<ThermalBarrierUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ThermalBarrierUsage_Avg_Fields = {
  __typename?: 'ThermalBarrierUsage_avg_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Avg_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ThermalBarrierUsage". All fields are combined with a logical 'AND'. */
export type ThermalBarrierUsage_Bool_Exp = {
  InsBoardSize?: Maybe<InsBoardSize_Bool_Exp>;
  InsBoardSizeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ThermalBarrierUsage_Bool_Exp>>;
  _not?: Maybe<ThermalBarrierUsage_Bool_Exp>;
  _or?: Maybe<Array<ThermalBarrierUsage_Bool_Exp>>;
};

/** upsert condition type for table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_If_Matched = {
  match_columns?: Array<ThermalBarrierUsage_Insert_Match_Column>;
  update_columns?: Array<ThermalBarrierUsage_Update_Column>;
  where?: Maybe<ThermalBarrierUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Inc_Input = {
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Insert_Input = {
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "ThermalBarrierUsage" */
export enum ThermalBarrierUsage_Insert_Match_Column {
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type ThermalBarrierUsage_Max_Fields = {
  __typename?: 'ThermalBarrierUsage_max_fields';
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Max_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ThermalBarrierUsage_Min_Fields = {
  __typename?: 'ThermalBarrierUsage_min_fields';
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Min_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Mutation_Response = {
  __typename?: 'ThermalBarrierUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ThermalBarrierUsage>;
};

/** Ordering options when selecting data from "ThermalBarrierUsage". */
export type ThermalBarrierUsage_Order_By = {
  InsBoardSize?: Maybe<InsBoardSize_Order_By>;
  InsBoardSizeId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: ThermalBarrierUsage */
export type ThermalBarrierUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "ThermalBarrierUsage" */
export enum ThermalBarrierUsage_Select_Column {
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Set_Input = {
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ThermalBarrierUsage_Stddev_Fields = {
  __typename?: 'ThermalBarrierUsage_stddev_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Stddev_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ThermalBarrierUsage_Stddev_Pop_Fields = {
  __typename?: 'ThermalBarrierUsage_stddev_pop_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Stddev_Pop_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ThermalBarrierUsage_Stddev_Samp_Fields = {
  __typename?: 'ThermalBarrierUsage_stddev_samp_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Stddev_Samp_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ThermalBarrierUsage_Sum_Fields = {
  __typename?: 'ThermalBarrierUsage_sum_fields';
  InsBoardSizeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Sum_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "ThermalBarrierUsage" */
export enum ThermalBarrierUsage_Update_Column {
  /** column name */
  InsBoardSizeId = 'InsBoardSizeId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type ThermalBarrierUsage_Var_Pop_Fields = {
  __typename?: 'ThermalBarrierUsage_var_pop_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Var_Pop_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ThermalBarrierUsage_Var_Samp_Fields = {
  __typename?: 'ThermalBarrierUsage_var_samp_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Var_Samp_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ThermalBarrierUsage_Variance_Fields = {
  __typename?: 'ThermalBarrierUsage_variance_fields';
  InsBoardSizeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ThermalBarrierUsage" */
export type ThermalBarrierUsage_Variance_Order_By = {
  InsBoardSizeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregated selection of "ThermalBarrier" */
export type ThermalBarrier_Aggregate = {
  __typename?: 'ThermalBarrier_aggregate';
  aggregate?: Maybe<ThermalBarrier_Aggregate_Fields>;
  nodes: Array<ThermalBarrier>;
};

/** aggregate fields of "ThermalBarrier" */
export type ThermalBarrier_Aggregate_Fields = {
  __typename?: 'ThermalBarrier_aggregate_fields';
  avg?: Maybe<ThermalBarrier_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ThermalBarrier_Max_Fields>;
  min?: Maybe<ThermalBarrier_Min_Fields>;
  stddev?: Maybe<ThermalBarrier_Stddev_Fields>;
  stddev_pop?: Maybe<ThermalBarrier_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ThermalBarrier_Stddev_Samp_Fields>;
  sum?: Maybe<ThermalBarrier_Sum_Fields>;
  var_pop?: Maybe<ThermalBarrier_Var_Pop_Fields>;
  var_samp?: Maybe<ThermalBarrier_Var_Samp_Fields>;
  variance?: Maybe<ThermalBarrier_Variance_Fields>;
};


/** aggregate fields of "ThermalBarrier" */
export type ThermalBarrier_Aggregate_FieldsCountArgs = {
  column?: Maybe<ThermalBarrier_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ThermalBarrier" */
export type ThermalBarrier_Aggregate_Order_By = {
  avg?: Maybe<ThermalBarrier_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ThermalBarrier_Max_Order_By>;
  min?: Maybe<ThermalBarrier_Min_Order_By>;
  stddev?: Maybe<ThermalBarrier_Stddev_Order_By>;
  stddev_pop?: Maybe<ThermalBarrier_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ThermalBarrier_Stddev_Samp_Order_By>;
  sum?: Maybe<ThermalBarrier_Sum_Order_By>;
  var_pop?: Maybe<ThermalBarrier_Var_Pop_Order_By>;
  var_samp?: Maybe<ThermalBarrier_Var_Samp_Order_By>;
  variance?: Maybe<ThermalBarrier_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ThermalBarrier_Avg_Fields = {
  __typename?: 'ThermalBarrier_avg_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ThermalBarrier" */
export type ThermalBarrier_Avg_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ThermalBarrier". All fields are combined with a logical 'AND'. */
export type ThermalBarrier_Bool_Exp = {
  BoardProfileId?: Maybe<Int_Mssql_Comparison_Exp>;
  C_BoardProfile?: Maybe<C_BoardProfile_Bool_Exp>;
  C_ThermalBarrierMaterial?: Maybe<C_ThermalBarrierMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  DensityMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  ThermalBarrierBoardSizes?: Maybe<ThermalBarrierBoardSizes_Bool_Exp>;
  ThermalBarrierFacerBottoms?: Maybe<ThermalBarrierFacerBottom_Bool_Exp>;
  ThermalBarrierFacerTops?: Maybe<ThermalBarrierFacerTop_Bool_Exp>;
  ThermalBarrierMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<ThermalBarrier_Bool_Exp>>;
  _not?: Maybe<ThermalBarrier_Bool_Exp>;
  _or?: Maybe<Array<ThermalBarrier_Bool_Exp>>;
};

/** upsert condition type for table "ThermalBarrier" */
export type ThermalBarrier_If_Matched = {
  match_columns?: Array<ThermalBarrier_Insert_Match_Column>;
  update_columns?: Array<ThermalBarrier_Update_Column>;
  where?: Maybe<ThermalBarrier_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "ThermalBarrier" */
export type ThermalBarrier_Inc_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ThermalBarrier" */
export type ThermalBarrier_Insert_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** select match_columns of table "ThermalBarrier" */
export enum ThermalBarrier_Insert_Match_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ThermalBarrierMaterialId = 'ThermalBarrierMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate max on columns */
export type ThermalBarrier_Max_Fields = {
  __typename?: 'ThermalBarrier_max_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by max() on columns of table "ThermalBarrier" */
export type ThermalBarrier_Max_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ThermalBarrier_Min_Fields = {
  __typename?: 'ThermalBarrier_min_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** order by min() on columns of table "ThermalBarrier" */
export type ThermalBarrier_Min_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** response of any mutation on the table "ThermalBarrier" */
export type ThermalBarrier_Mutation_Response = {
  __typename?: 'ThermalBarrier_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ThermalBarrier>;
};

/** Ordering options when selecting data from "ThermalBarrier". */
export type ThermalBarrier_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  C_BoardProfile?: Maybe<C_BoardProfile_Order_By>;
  C_ThermalBarrierMaterial?: Maybe<C_ThermalBarrierMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  ThermalBarrierBoardSizes_aggregate?: Maybe<ThermalBarrierBoardSizes_Aggregate_Order_By>;
  ThermalBarrierFacerBottoms_aggregate?: Maybe<ThermalBarrierFacerBottom_Aggregate_Order_By>;
  ThermalBarrierFacerTops_aggregate?: Maybe<ThermalBarrierFacerTop_Aggregate_Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
};

/** primary key columns input for table: ThermalBarrier */
export type ThermalBarrier_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "ThermalBarrier" */
export enum ThermalBarrier_Select_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ThermalBarrierMaterialId = 'ThermalBarrierMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** input type for updating data in table "ThermalBarrier" */
export type ThermalBarrier_Set_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['MeasurementValue']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
};

/** aggregate stddev on columns */
export type ThermalBarrier_Stddev_Fields = {
  __typename?: 'ThermalBarrier_stddev_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ThermalBarrier" */
export type ThermalBarrier_Stddev_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ThermalBarrier_Stddev_Pop_Fields = {
  __typename?: 'ThermalBarrier_stddev_pop_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ThermalBarrier" */
export type ThermalBarrier_Stddev_Pop_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ThermalBarrier_Stddev_Samp_Fields = {
  __typename?: 'ThermalBarrier_stddev_samp_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ThermalBarrier" */
export type ThermalBarrier_Stddev_Samp_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ThermalBarrier_Sum_Fields = {
  __typename?: 'ThermalBarrier_sum_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ThermalBarrier" */
export type ThermalBarrier_Sum_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
};

/** update columns of table "ThermalBarrier" */
export enum ThermalBarrier_Update_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ThermalBarrierMaterialId = 'ThermalBarrierMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin'
}

/** aggregate var_pop on columns */
export type ThermalBarrier_Var_Pop_Fields = {
  __typename?: 'ThermalBarrier_var_pop_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ThermalBarrier" */
export type ThermalBarrier_Var_Pop_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ThermalBarrier_Var_Samp_Fields = {
  __typename?: 'ThermalBarrier_var_samp_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ThermalBarrier" */
export type ThermalBarrier_Var_Samp_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ThermalBarrier_Variance_Fields = {
  __typename?: 'ThermalBarrier_variance_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ThermalBarrier" */
export type ThermalBarrier_Variance_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "TopCoat" */
export type TopCoat = {
  __typename?: 'TopCoat';
  /** An object relationship */
  C_CoverColor: C_CoverColor;
  /** An object relationship */
  C_LiquidApplication: C_LiquidApplication;
  /** An object relationship */
  C_LiquidAppliedMaterial: C_LiquidAppliedMaterial;
  ComponentId: Scalars['Int'];
  CoverColorId: Scalars['Int'];
  LiquidApplicationId: Scalars['Int'];
  LiquidAppliedMaterialId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
};

/** columns and relationships of "TopCoatUsage" */
export type TopCoatUsage = {
  __typename?: 'TopCoatUsage';
  ApplicationRateMinDry: Scalars['MeasurementValue'];
  ApplicationRateMinWet: Scalars['MeasurementValue'];
  NumCoats: Scalars['Int'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "TopCoatUsage" */
export type TopCoatUsage_Aggregate = {
  __typename?: 'TopCoatUsage_aggregate';
  aggregate?: Maybe<TopCoatUsage_Aggregate_Fields>;
  nodes: Array<TopCoatUsage>;
};

/** aggregate fields of "TopCoatUsage" */
export type TopCoatUsage_Aggregate_Fields = {
  __typename?: 'TopCoatUsage_aggregate_fields';
  avg?: Maybe<TopCoatUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TopCoatUsage_Max_Fields>;
  min?: Maybe<TopCoatUsage_Min_Fields>;
  stddev?: Maybe<TopCoatUsage_Stddev_Fields>;
  stddev_pop?: Maybe<TopCoatUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TopCoatUsage_Stddev_Samp_Fields>;
  sum?: Maybe<TopCoatUsage_Sum_Fields>;
  var_pop?: Maybe<TopCoatUsage_Var_Pop_Fields>;
  var_samp?: Maybe<TopCoatUsage_Var_Samp_Fields>;
  variance?: Maybe<TopCoatUsage_Variance_Fields>;
};


/** aggregate fields of "TopCoatUsage" */
export type TopCoatUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<TopCoatUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TopCoatUsage_Avg_Fields = {
  __typename?: 'TopCoatUsage_avg_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "TopCoatUsage". All fields are combined with a logical 'AND'. */
export type TopCoatUsage_Bool_Exp = {
  ApplicationRateMinDry?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ApplicationRateMinWet?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  NumCoats?: Maybe<Int_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<TopCoatUsage_Bool_Exp>>;
  _not?: Maybe<TopCoatUsage_Bool_Exp>;
  _or?: Maybe<Array<TopCoatUsage_Bool_Exp>>;
};

/** upsert condition type for table "TopCoatUsage" */
export type TopCoatUsage_If_Matched = {
  match_columns?: Array<TopCoatUsage_Insert_Match_Column>;
  update_columns?: Array<TopCoatUsage_Update_Column>;
  where?: Maybe<TopCoatUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "TopCoatUsage" */
export type TopCoatUsage_Inc_Input = {
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "TopCoatUsage" */
export type TopCoatUsage_Insert_Input = {
  ApplicationRateMinDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateMinWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "TopCoatUsage" */
export enum TopCoatUsage_Insert_Match_Column {
  /** column name */
  ApplicationRateMinDry = 'ApplicationRateMinDry',
  /** column name */
  ApplicationRateMinWet = 'ApplicationRateMinWet',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type TopCoatUsage_Max_Fields = {
  __typename?: 'TopCoatUsage_max_fields';
  ApplicationRateMinDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateMinWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type TopCoatUsage_Min_Fields = {
  __typename?: 'TopCoatUsage_min_fields';
  ApplicationRateMinDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateMinWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "TopCoatUsage" */
export type TopCoatUsage_Mutation_Response = {
  __typename?: 'TopCoatUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TopCoatUsage>;
};

/** Ordering options when selecting data from "TopCoatUsage". */
export type TopCoatUsage_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: TopCoatUsage */
export type TopCoatUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "TopCoatUsage" */
export enum TopCoatUsage_Select_Column {
  /** column name */
  ApplicationRateMinDry = 'ApplicationRateMinDry',
  /** column name */
  ApplicationRateMinWet = 'ApplicationRateMinWet',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "TopCoatUsage" */
export type TopCoatUsage_Set_Input = {
  ApplicationRateMinDry?: Maybe<Scalars['MeasurementValue']>;
  ApplicationRateMinWet?: Maybe<Scalars['MeasurementValue']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TopCoatUsage_Stddev_Fields = {
  __typename?: 'TopCoatUsage_stddev_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TopCoatUsage_Stddev_Pop_Fields = {
  __typename?: 'TopCoatUsage_stddev_pop_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TopCoatUsage_Stddev_Samp_Fields = {
  __typename?: 'TopCoatUsage_stddev_samp_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TopCoatUsage_Sum_Fields = {
  __typename?: 'TopCoatUsage_sum_fields';
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "TopCoatUsage" */
export enum TopCoatUsage_Update_Column {
  /** column name */
  ApplicationRateMinDry = 'ApplicationRateMinDry',
  /** column name */
  ApplicationRateMinWet = 'ApplicationRateMinWet',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type TopCoatUsage_Var_Pop_Fields = {
  __typename?: 'TopCoatUsage_var_pop_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TopCoatUsage_Var_Samp_Fields = {
  __typename?: 'TopCoatUsage_var_samp_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TopCoatUsage_Variance_Fields = {
  __typename?: 'TopCoatUsage_variance_fields';
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "TopCoat" */
export type TopCoat_Aggregate = {
  __typename?: 'TopCoat_aggregate';
  aggregate?: Maybe<TopCoat_Aggregate_Fields>;
  nodes: Array<TopCoat>;
};

/** aggregate fields of "TopCoat" */
export type TopCoat_Aggregate_Fields = {
  __typename?: 'TopCoat_aggregate_fields';
  avg?: Maybe<TopCoat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TopCoat_Max_Fields>;
  min?: Maybe<TopCoat_Min_Fields>;
  stddev?: Maybe<TopCoat_Stddev_Fields>;
  stddev_pop?: Maybe<TopCoat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TopCoat_Stddev_Samp_Fields>;
  sum?: Maybe<TopCoat_Sum_Fields>;
  var_pop?: Maybe<TopCoat_Var_Pop_Fields>;
  var_samp?: Maybe<TopCoat_Var_Samp_Fields>;
  variance?: Maybe<TopCoat_Variance_Fields>;
};


/** aggregate fields of "TopCoat" */
export type TopCoat_Aggregate_FieldsCountArgs = {
  column?: Maybe<TopCoat_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "TopCoat" */
export type TopCoat_Aggregate_Order_By = {
  avg?: Maybe<TopCoat_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<TopCoat_Max_Order_By>;
  min?: Maybe<TopCoat_Min_Order_By>;
  stddev?: Maybe<TopCoat_Stddev_Order_By>;
  stddev_pop?: Maybe<TopCoat_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<TopCoat_Stddev_Samp_Order_By>;
  sum?: Maybe<TopCoat_Sum_Order_By>;
  var_pop?: Maybe<TopCoat_Var_Pop_Order_By>;
  var_samp?: Maybe<TopCoat_Var_Samp_Order_By>;
  variance?: Maybe<TopCoat_Variance_Order_By>;
};

/** aggregate avg on columns */
export type TopCoat_Avg_Fields = {
  __typename?: 'TopCoat_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "TopCoat" */
export type TopCoat_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "TopCoat". All fields are combined with a logical 'AND'. */
export type TopCoat_Bool_Exp = {
  C_CoverColor?: Maybe<C_CoverColor_Bool_Exp>;
  C_LiquidApplication?: Maybe<C_LiquidApplication_Bool_Exp>;
  C_LiquidAppliedMaterial?: Maybe<C_LiquidAppliedMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  CoverColorId?: Maybe<Int_Mssql_Comparison_Exp>;
  LiquidApplicationId?: Maybe<Int_Mssql_Comparison_Exp>;
  LiquidAppliedMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  _and?: Maybe<Array<TopCoat_Bool_Exp>>;
  _not?: Maybe<TopCoat_Bool_Exp>;
  _or?: Maybe<Array<TopCoat_Bool_Exp>>;
};

/** upsert condition type for table "TopCoat" */
export type TopCoat_If_Matched = {
  match_columns?: Array<TopCoat_Insert_Match_Column>;
  update_columns?: Array<TopCoat_Update_Column>;
  where?: Maybe<TopCoat_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "TopCoat" */
export type TopCoat_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "TopCoat" */
export type TopCoat_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "TopCoat" */
export enum TopCoat_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverColorId = 'CoverColorId',
  /** column name */
  LiquidApplicationId = 'LiquidApplicationId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId'
}

/** aggregate max on columns */
export type TopCoat_Max_Fields = {
  __typename?: 'TopCoat_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "TopCoat" */
export type TopCoat_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type TopCoat_Min_Fields = {
  __typename?: 'TopCoat_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "TopCoat" */
export type TopCoat_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** response of any mutation on the table "TopCoat" */
export type TopCoat_Mutation_Response = {
  __typename?: 'TopCoat_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TopCoat>;
};

/** Ordering options when selecting data from "TopCoat". */
export type TopCoat_Order_By = {
  C_CoverColor?: Maybe<C_CoverColor_Order_By>;
  C_LiquidApplication?: Maybe<C_LiquidApplication_Order_By>;
  C_LiquidAppliedMaterial?: Maybe<C_LiquidAppliedMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
};

/** primary key columns input for table: TopCoat */
export type TopCoat_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "TopCoat" */
export enum TopCoat_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverColorId = 'CoverColorId',
  /** column name */
  LiquidApplicationId = 'LiquidApplicationId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId'
}

/** input type for updating data in table "TopCoat" */
export type TopCoat_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TopCoat_Stddev_Fields = {
  __typename?: 'TopCoat_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "TopCoat" */
export type TopCoat_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type TopCoat_Stddev_Pop_Fields = {
  __typename?: 'TopCoat_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "TopCoat" */
export type TopCoat_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type TopCoat_Stddev_Samp_Fields = {
  __typename?: 'TopCoat_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "TopCoat" */
export type TopCoat_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type TopCoat_Sum_Fields = {
  __typename?: 'TopCoat_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  CoverColorId?: Maybe<Scalars['Int']>;
  LiquidApplicationId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "TopCoat" */
export type TopCoat_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** update columns of table "TopCoat" */
export enum TopCoat_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverColorId = 'CoverColorId',
  /** column name */
  LiquidApplicationId = 'LiquidApplicationId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId'
}

/** aggregate var_pop on columns */
export type TopCoat_Var_Pop_Fields = {
  __typename?: 'TopCoat_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "TopCoat" */
export type TopCoat_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type TopCoat_Var_Samp_Fields = {
  __typename?: 'TopCoat_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "TopCoat" */
export type TopCoat_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type TopCoat_Variance_Fields = {
  __typename?: 'TopCoat_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  CoverColorId?: Maybe<Scalars['Float']>;
  LiquidApplicationId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "TopCoat" */
export type TopCoat_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  CoverColorId?: Maybe<Order_By>;
  LiquidApplicationId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "Usage" */
export type Usage = {
  __typename?: 'Usage';
  /** An object relationship */
  AdhesiveFullApplicationUsage?: Maybe<AdhesiveFullApplicationUsage>;
  /** An object relationship */
  AdhesiveRibbonUsage?: Maybe<AdhesiveRibbonUsage>;
  /** An object relationship */
  AdhesiveSpotsUsage?: Maybe<AdhesiveSpotsUsage>;
  /** An object relationship */
  AdhesiveSystemUsage?: Maybe<AdhesiveSystemUsage>;
  /** An object relationship */
  AirRetarderUsage?: Maybe<AirRetarderUsage>;
  ApprovedUseId: Scalars['Int'];
  /** An object relationship */
  BaseCoatUsage?: Maybe<BaseCoatUsage>;
  /** An object relationship */
  BasePlyUsage?: Maybe<BasePlyUsage>;
  /** An object relationship */
  BaseSheetUsage?: Maybe<BaseSheetUsage>;
  /** An object relationship */
  BattBlanketUsage?: Maybe<BattBlanketUsage>;
  /** An object relationship */
  BattenBarUsage?: Maybe<BattenBarUsage>;
  /** An object relationship */
  BoardStockUsage?: Maybe<BoardStockUsage>;
  /** An object relationship */
  BuiltupVaporRetarderSystemUsage?: Maybe<BuiltupVaporRetarderSystemUsage>;
  /** An object relationship */
  C_Status: C_Status;
  /** An object relationship */
  CapPlyUsage?: Maybe<CapPlyUsage>;
  /** An object relationship */
  CementitiousWoodFiberDeckUsage?: Maybe<CementitiousWoodFiberDeckUsage>;
  /** An object relationship */
  ClipUsage?: Maybe<ClipUsage>;
  /** An object relationship */
  CoatUsage?: Maybe<CoatUsage>;
  /** An object relationship */
  CoatingSystemUsage?: Maybe<CoatingSystemUsage>;
  /** An object relationship */
  CoatingUsage?: Maybe<CoatingUsage>;
  Comments: Scalars['ItemComment'];
  /** An object relationship */
  Component: Component;
  /** An object relationship */
  ComponentApprovedUse?: Maybe<ComponentApprovedUse>;
  ComponentId: Scalars['Int'];
  /** An array relationship */
  ComponentLayerUsages: Array<ComponentLayerUsage>;
  /** An aggregate relationship */
  ComponentLayerUsages_aggregate: ComponentLayerUsage_Aggregate;
  /** An object relationship */
  CompositePanelUsage?: Maybe<CompositePanelUsage>;
  /** An object relationship */
  CoverBoardUsage?: Maybe<CoverBoardUsage>;
  /** An object relationship */
  CoverStripDiskUsage?: Maybe<CoverStripDiskUsage>;
  EnteredAsMetric: Scalars['MetricEntryFlag'];
  /** An object relationship */
  ExpansionJointUsage?: Maybe<ExpansionJointUsage>;
  /** An object relationship */
  FastenerUsage?: Maybe<FastenerUsage>;
  /** An object relationship */
  FasteningSystemBattenBarUsage?: Maybe<FasteningSystemBattenBarUsage>;
  /** An object relationship */
  FasteningSystemClipUsage?: Maybe<FasteningSystemClipUsage>;
  /** An object relationship */
  FasteningSystemStressPlateUsage?: Maybe<FasteningSystemStressPlateUsage>;
  /** An object relationship */
  FiberReinforcedPlasticDeckUsage?: Maybe<FiberReinforcedPlasticDeckUsage>;
  /** An object relationship */
  FormDeckUsage?: Maybe<FormDeckUsage>;
  /** An object relationship */
  GypsumDeckUsage?: Maybe<GypsumDeckUsage>;
  /** An object relationship */
  LapSealantUsage?: Maybe<LapSealantUsage>;
  /** An object relationship */
  LapSecurementInfo?: Maybe<LapSecurementInfo>;
  /** An object relationship */
  LightweightInsulatingConcreteSubassemblyUsage?: Maybe<LightweightInsulatingConcreteSubassemblyUsage>;
  /** An object relationship */
  LightweightInsulatingConcreteUsage?: Maybe<LightweightInsulatingConcreteUsage>;
  /** An object relationship */
  LinerPanelUsage?: Maybe<LinerPanelUsage>;
  /** An object relationship */
  LiquidAppliedSubassemblyUsage?: Maybe<LiquidAppliedSubassemblyUsage>;
  /** An object relationship */
  LiquidAppliedUsage?: Maybe<LiquidAppliedUsage>;
  /** An object relationship */
  MaintenanceCoatingUsage?: Maybe<MaintenanceCoatingUsage>;
  /** An object relationship */
  MultiplyCoverSubassemblyUsage?: Maybe<MultiplyCoverSubassemblyUsage>;
  /** An array relationship */
  NavUsages: Array<NavUsage>;
  /** An aggregate relationship */
  NavUsages_aggregate: NavUsage_Aggregate;
  /** An object relationship */
  OtherPlyUsage?: Maybe<OtherPlyUsage>;
  /** An object relationship */
  PreassembledFasteningUsage?: Maybe<PreassembledFasteningUsage>;
  /** An object relationship */
  SeamPrimerUsage?: Maybe<SeamPrimerUsage>;
  /** An object relationship */
  SeamTapeSystemUsage?: Maybe<SeamTapeSystemUsage>;
  /** An object relationship */
  SeamWashUsage?: Maybe<SeamWashUsage>;
  /** An array relationship */
  SecurementLayerUsages: Array<SecurementLayerUsage>;
  /** An aggregate relationship */
  SecurementLayerUsages_aggregate: SecurementLayerUsage_Aggregate;
  /** An array relationship */
  SelfSecurementUsages: Array<SelfSecurementUsage>;
  /** An aggregate relationship */
  SelfSecurementUsages_aggregate: SelfSecurementUsage_Aggregate;
  /** An object relationship */
  SeparatorSheetUsage?: Maybe<SeparatorSheetUsage>;
  /** An object relationship */
  SinglePlyUsage?: Maybe<SinglePlyUsage>;
  /** An object relationship */
  SprayFoamInsulationUsage?: Maybe<SprayFoamInsulationUsage>;
  /** An object relationship */
  StandingLapSeamUsage?: Maybe<StandingLapSeamUsage>;
  StatusId: Scalars['Int'];
  /** An object relationship */
  SteelDeckUsage?: Maybe<SteelDeckUsage>;
  /** An object relationship */
  StressPlateUsage?: Maybe<StressPlateUsage>;
  /** An object relationship */
  SubstratePrimerUsage?: Maybe<SubstratePrimerUsage>;
  /** An object relationship */
  SurfacingUsage?: Maybe<SurfacingUsage>;
  /** An object relationship */
  ThermalBarrierUsage?: Maybe<ThermalBarrierUsage>;
  /** An object relationship */
  TopCoatUsage?: Maybe<TopCoatUsage>;
  UsageId: Scalars['Int'];
  /** An object relationship */
  VaporRetarderUsage?: Maybe<VaporRetarderUsage>;
  /** An object relationship */
  WeldArcUsage?: Maybe<WeldArcUsage>;
  /** An object relationship */
  WeldHeatUsage?: Maybe<WeldHeatUsage>;
  /** An object relationship */
  WeldSolventUsage?: Maybe<WeldSolventUsage>;
  /** An object relationship */
  WeldSystemUsage?: Maybe<WeldSystemUsage>;
  /** An object relationship */
  WeldTorchedUsage?: Maybe<WeldTorchedUsage>;
  /** An object relationship */
  WoodDeckUsage?: Maybe<WoodDeckUsage>;
};


/** columns and relationships of "Usage" */
export type UsageComponentLayerUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentLayerUsage_Order_By>>;
  where?: Maybe<ComponentLayerUsage_Bool_Exp>;
};


/** columns and relationships of "Usage" */
export type UsageComponentLayerUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentLayerUsage_Order_By>>;
  where?: Maybe<ComponentLayerUsage_Bool_Exp>;
};


/** columns and relationships of "Usage" */
export type UsageNavUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavUsage_Order_By>>;
  where?: Maybe<NavUsage_Bool_Exp>;
};


/** columns and relationships of "Usage" */
export type UsageNavUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavUsage_Order_By>>;
  where?: Maybe<NavUsage_Bool_Exp>;
};


/** columns and relationships of "Usage" */
export type UsageSecurementLayerUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayerUsage_Order_By>>;
  where?: Maybe<SecurementLayerUsage_Bool_Exp>;
};


/** columns and relationships of "Usage" */
export type UsageSecurementLayerUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayerUsage_Order_By>>;
  where?: Maybe<SecurementLayerUsage_Bool_Exp>;
};


/** columns and relationships of "Usage" */
export type UsageSelfSecurementUsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SelfSecurementUsage_Order_By>>;
  where?: Maybe<SelfSecurementUsage_Bool_Exp>;
};


/** columns and relationships of "Usage" */
export type UsageSelfSecurementUsages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SelfSecurementUsage_Order_By>>;
  where?: Maybe<SelfSecurementUsage_Bool_Exp>;
};

/** columns and relationships of "UsageTradeNameView" */
export type UsageTradeNameView = {
  __typename?: 'UsageTradeNameView';
  ComponentId: Scalars['Int'];
  ManufacturerId: Scalars['Int'];
  StatusId: Scalars['Int'];
  SubcategoryId: Scalars['Int'];
  TradeName?: Maybe<Scalars['String']>;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "UsageTradeNameView" */
export type UsageTradeNameView_Aggregate = {
  __typename?: 'UsageTradeNameView_aggregate';
  aggregate?: Maybe<UsageTradeNameView_Aggregate_Fields>;
  nodes: Array<UsageTradeNameView>;
};

/** aggregate fields of "UsageTradeNameView" */
export type UsageTradeNameView_Aggregate_Fields = {
  __typename?: 'UsageTradeNameView_aggregate_fields';
  avg?: Maybe<UsageTradeNameView_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<UsageTradeNameView_Max_Fields>;
  min?: Maybe<UsageTradeNameView_Min_Fields>;
  stddev?: Maybe<UsageTradeNameView_Stddev_Fields>;
  stddev_pop?: Maybe<UsageTradeNameView_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UsageTradeNameView_Stddev_Samp_Fields>;
  sum?: Maybe<UsageTradeNameView_Sum_Fields>;
  var_pop?: Maybe<UsageTradeNameView_Var_Pop_Fields>;
  var_samp?: Maybe<UsageTradeNameView_Var_Samp_Fields>;
  variance?: Maybe<UsageTradeNameView_Variance_Fields>;
};


/** aggregate fields of "UsageTradeNameView" */
export type UsageTradeNameView_Aggregate_FieldsCountArgs = {
  column?: Maybe<UsageTradeNameView_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type UsageTradeNameView_Avg_Fields = {
  __typename?: 'UsageTradeNameView_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "UsageTradeNameView". All fields are combined with a logical 'AND'. */
export type UsageTradeNameView_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  SubcategoryId?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<UsageTradeNameView_Bool_Exp>>;
  _not?: Maybe<UsageTradeNameView_Bool_Exp>;
  _or?: Maybe<Array<UsageTradeNameView_Bool_Exp>>;
};

/** upsert condition type for table "UsageTradeNameView" */
export type UsageTradeNameView_If_Matched = {
  match_columns?: Array<UsageTradeNameView_Insert_Match_Column>;
  update_columns?: Array<UsageTradeNameView_Update_Column>;
  where?: Maybe<UsageTradeNameView_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "UsageTradeNameView" */
export type UsageTradeNameView_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "UsageTradeNameView" */
export type UsageTradeNameView_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "UsageTradeNameView" */
export enum UsageTradeNameView_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryId = 'SubcategoryId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type UsageTradeNameView_Max_Fields = {
  __typename?: 'UsageTradeNameView_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type UsageTradeNameView_Min_Fields = {
  __typename?: 'UsageTradeNameView_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "UsageTradeNameView" */
export type UsageTradeNameView_Mutation_Response = {
  __typename?: 'UsageTradeNameView_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UsageTradeNameView>;
};

/** Ordering options when selecting data from "UsageTradeNameView". */
export type UsageTradeNameView_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubcategoryId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "UsageTradeNameView" */
export enum UsageTradeNameView_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryId = 'SubcategoryId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "UsageTradeNameView" */
export type UsageTradeNameView_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type UsageTradeNameView_Stddev_Fields = {
  __typename?: 'UsageTradeNameView_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type UsageTradeNameView_Stddev_Pop_Fields = {
  __typename?: 'UsageTradeNameView_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type UsageTradeNameView_Stddev_Samp_Fields = {
  __typename?: 'UsageTradeNameView_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type UsageTradeNameView_Sum_Fields = {
  __typename?: 'UsageTradeNameView_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  SubcategoryId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "UsageTradeNameView" */
export enum UsageTradeNameView_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  SubcategoryId = 'SubcategoryId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type UsageTradeNameView_Var_Pop_Fields = {
  __typename?: 'UsageTradeNameView_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type UsageTradeNameView_Var_Samp_Fields = {
  __typename?: 'UsageTradeNameView_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type UsageTradeNameView_Variance_Fields = {
  __typename?: 'UsageTradeNameView_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  SubcategoryId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Usage" */
export type Usage_Aggregate = {
  __typename?: 'Usage_aggregate';
  aggregate?: Maybe<Usage_Aggregate_Fields>;
  nodes: Array<Usage>;
};

/** aggregate fields of "Usage" */
export type Usage_Aggregate_Fields = {
  __typename?: 'Usage_aggregate_fields';
  avg?: Maybe<Usage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Usage_Max_Fields>;
  min?: Maybe<Usage_Min_Fields>;
  stddev?: Maybe<Usage_Stddev_Fields>;
  stddev_pop?: Maybe<Usage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Usage_Stddev_Samp_Fields>;
  sum?: Maybe<Usage_Sum_Fields>;
  var_pop?: Maybe<Usage_Var_Pop_Fields>;
  var_samp?: Maybe<Usage_Var_Samp_Fields>;
  variance?: Maybe<Usage_Variance_Fields>;
};


/** aggregate fields of "Usage" */
export type Usage_Aggregate_FieldsCountArgs = {
  column?: Maybe<Usage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Usage" */
export type Usage_Aggregate_Order_By = {
  avg?: Maybe<Usage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Usage_Max_Order_By>;
  min?: Maybe<Usage_Min_Order_By>;
  stddev?: Maybe<Usage_Stddev_Order_By>;
  stddev_pop?: Maybe<Usage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Usage_Stddev_Samp_Order_By>;
  sum?: Maybe<Usage_Sum_Order_By>;
  var_pop?: Maybe<Usage_Var_Pop_Order_By>;
  var_samp?: Maybe<Usage_Var_Samp_Order_By>;
  variance?: Maybe<Usage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Usage_Avg_Fields = {
  __typename?: 'Usage_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Usage" */
export type Usage_Avg_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Usage". All fields are combined with a logical 'AND'. */
export type Usage_Bool_Exp = {
  AdhesiveFullApplicationUsage?: Maybe<AdhesiveFullApplicationUsage_Bool_Exp>;
  AdhesiveRibbonUsage?: Maybe<AdhesiveRibbonUsage_Bool_Exp>;
  AdhesiveSpotsUsage?: Maybe<AdhesiveSpotsUsage_Bool_Exp>;
  AdhesiveSystemUsage?: Maybe<AdhesiveSystemUsage_Bool_Exp>;
  AirRetarderUsage?: Maybe<AirRetarderUsage_Bool_Exp>;
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  BaseCoatUsage?: Maybe<BaseCoatUsage_Bool_Exp>;
  BasePlyUsage?: Maybe<BasePlyUsage_Bool_Exp>;
  BaseSheetUsage?: Maybe<BaseSheetUsage_Bool_Exp>;
  BattBlanketUsage?: Maybe<BattBlanketUsage_Bool_Exp>;
  BattenBarUsage?: Maybe<BattenBarUsage_Bool_Exp>;
  BoardStockUsage?: Maybe<BoardStockUsage_Bool_Exp>;
  BuiltupVaporRetarderSystemUsage?: Maybe<BuiltupVaporRetarderSystemUsage_Bool_Exp>;
  C_Status?: Maybe<C_Status_Bool_Exp>;
  CapPlyUsage?: Maybe<CapPlyUsage_Bool_Exp>;
  CementitiousWoodFiberDeckUsage?: Maybe<CementitiousWoodFiberDeckUsage_Bool_Exp>;
  ClipUsage?: Maybe<ClipUsage_Bool_Exp>;
  CoatUsage?: Maybe<CoatUsage_Bool_Exp>;
  CoatingSystemUsage?: Maybe<CoatingSystemUsage_Bool_Exp>;
  CoatingUsage?: Maybe<CoatingUsage_Bool_Exp>;
  Comments?: Maybe<ItemComment_Mssql_Comparison_Exp>;
  Component?: Maybe<Component_Bool_Exp>;
  ComponentApprovedUse?: Maybe<ComponentApprovedUse_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentLayerUsages?: Maybe<ComponentLayerUsage_Bool_Exp>;
  CompositePanelUsage?: Maybe<CompositePanelUsage_Bool_Exp>;
  CoverBoardUsage?: Maybe<CoverBoardUsage_Bool_Exp>;
  CoverStripDiskUsage?: Maybe<CoverStripDiskUsage_Bool_Exp>;
  EnteredAsMetric?: Maybe<MetricEntryFlag_Mssql_Comparison_Exp>;
  ExpansionJointUsage?: Maybe<ExpansionJointUsage_Bool_Exp>;
  FastenerUsage?: Maybe<FastenerUsage_Bool_Exp>;
  FasteningSystemBattenBarUsage?: Maybe<FasteningSystemBattenBarUsage_Bool_Exp>;
  FasteningSystemClipUsage?: Maybe<FasteningSystemClipUsage_Bool_Exp>;
  FasteningSystemStressPlateUsage?: Maybe<FasteningSystemStressPlateUsage_Bool_Exp>;
  FiberReinforcedPlasticDeckUsage?: Maybe<FiberReinforcedPlasticDeckUsage_Bool_Exp>;
  FormDeckUsage?: Maybe<FormDeckUsage_Bool_Exp>;
  GypsumDeckUsage?: Maybe<GypsumDeckUsage_Bool_Exp>;
  LapSealantUsage?: Maybe<LapSealantUsage_Bool_Exp>;
  LapSecurementInfo?: Maybe<LapSecurementInfo_Bool_Exp>;
  LightweightInsulatingConcreteSubassemblyUsage?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Bool_Exp>;
  LightweightInsulatingConcreteUsage?: Maybe<LightweightInsulatingConcreteUsage_Bool_Exp>;
  LinerPanelUsage?: Maybe<LinerPanelUsage_Bool_Exp>;
  LiquidAppliedSubassemblyUsage?: Maybe<LiquidAppliedSubassemblyUsage_Bool_Exp>;
  LiquidAppliedUsage?: Maybe<LiquidAppliedUsage_Bool_Exp>;
  MaintenanceCoatingUsage?: Maybe<MaintenanceCoatingUsage_Bool_Exp>;
  MultiplyCoverSubassemblyUsage?: Maybe<MultiplyCoverSubassemblyUsage_Bool_Exp>;
  NavUsages?: Maybe<NavUsage_Bool_Exp>;
  OtherPlyUsage?: Maybe<OtherPlyUsage_Bool_Exp>;
  PreassembledFasteningUsage?: Maybe<PreassembledFasteningUsage_Bool_Exp>;
  SeamPrimerUsage?: Maybe<SeamPrimerUsage_Bool_Exp>;
  SeamTapeSystemUsage?: Maybe<SeamTapeSystemUsage_Bool_Exp>;
  SeamWashUsage?: Maybe<SeamWashUsage_Bool_Exp>;
  SecurementLayerUsages?: Maybe<SecurementLayerUsage_Bool_Exp>;
  SelfSecurementUsages?: Maybe<SelfSecurementUsage_Bool_Exp>;
  SeparatorSheetUsage?: Maybe<SeparatorSheetUsage_Bool_Exp>;
  SinglePlyUsage?: Maybe<SinglePlyUsage_Bool_Exp>;
  SprayFoamInsulationUsage?: Maybe<SprayFoamInsulationUsage_Bool_Exp>;
  StandingLapSeamUsage?: Maybe<StandingLapSeamUsage_Bool_Exp>;
  StatusId?: Maybe<Int_Mssql_Comparison_Exp>;
  SteelDeckUsage?: Maybe<SteelDeckUsage_Bool_Exp>;
  StressPlateUsage?: Maybe<StressPlateUsage_Bool_Exp>;
  SubstratePrimerUsage?: Maybe<SubstratePrimerUsage_Bool_Exp>;
  SurfacingUsage?: Maybe<SurfacingUsage_Bool_Exp>;
  ThermalBarrierUsage?: Maybe<ThermalBarrierUsage_Bool_Exp>;
  TopCoatUsage?: Maybe<TopCoatUsage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  VaporRetarderUsage?: Maybe<VaporRetarderUsage_Bool_Exp>;
  WeldArcUsage?: Maybe<WeldArcUsage_Bool_Exp>;
  WeldHeatUsage?: Maybe<WeldHeatUsage_Bool_Exp>;
  WeldSolventUsage?: Maybe<WeldSolventUsage_Bool_Exp>;
  WeldSystemUsage?: Maybe<WeldSystemUsage_Bool_Exp>;
  WeldTorchedUsage?: Maybe<WeldTorchedUsage_Bool_Exp>;
  WoodDeckUsage?: Maybe<WoodDeckUsage_Bool_Exp>;
  _and?: Maybe<Array<Usage_Bool_Exp>>;
  _not?: Maybe<Usage_Bool_Exp>;
  _or?: Maybe<Array<Usage_Bool_Exp>>;
};

/** upsert condition type for table "Usage" */
export type Usage_If_Matched = {
  match_columns?: Array<Usage_Insert_Match_Column>;
  update_columns?: Array<Usage_Update_Column>;
  where?: Maybe<Usage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "Usage" */
export type Usage_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Usage" */
export type Usage_Insert_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentId?: Maybe<Scalars['Int']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "Usage" */
export enum Usage_Insert_Match_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Comments = 'Comments',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type Usage_Max_Fields = {
  __typename?: 'Usage_max_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentId?: Maybe<Scalars['Int']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  StatusId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Usage" */
export type Usage_Max_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  Comments?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  EnteredAsMetric?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Usage_Min_Fields = {
  __typename?: 'Usage_min_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentId?: Maybe<Scalars['Int']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  StatusId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Usage" */
export type Usage_Min_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  Comments?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  EnteredAsMetric?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Usage" */
export type Usage_Mutation_Response = {
  __typename?: 'Usage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Usage>;
};

/** Ordering options when selecting data from "Usage". */
export type Usage_Order_By = {
  AdhesiveFullApplicationUsage?: Maybe<AdhesiveFullApplicationUsage_Order_By>;
  AdhesiveRibbonUsage?: Maybe<AdhesiveRibbonUsage_Order_By>;
  AdhesiveSpotsUsage?: Maybe<AdhesiveSpotsUsage_Order_By>;
  AdhesiveSystemUsage?: Maybe<AdhesiveSystemUsage_Order_By>;
  AirRetarderUsage?: Maybe<AirRetarderUsage_Order_By>;
  ApprovedUseId?: Maybe<Order_By>;
  BaseCoatUsage?: Maybe<BaseCoatUsage_Order_By>;
  BasePlyUsage?: Maybe<BasePlyUsage_Order_By>;
  BaseSheetUsage?: Maybe<BaseSheetUsage_Order_By>;
  BattBlanketUsage?: Maybe<BattBlanketUsage_Order_By>;
  BattenBarUsage?: Maybe<BattenBarUsage_Order_By>;
  BoardStockUsage?: Maybe<BoardStockUsage_Order_By>;
  BuiltupVaporRetarderSystemUsage?: Maybe<BuiltupVaporRetarderSystemUsage_Order_By>;
  C_Status?: Maybe<C_Status_Order_By>;
  CapPlyUsage?: Maybe<CapPlyUsage_Order_By>;
  CementitiousWoodFiberDeckUsage?: Maybe<CementitiousWoodFiberDeckUsage_Order_By>;
  ClipUsage?: Maybe<ClipUsage_Order_By>;
  CoatUsage?: Maybe<CoatUsage_Order_By>;
  CoatingSystemUsage?: Maybe<CoatingSystemUsage_Order_By>;
  CoatingUsage?: Maybe<CoatingUsage_Order_By>;
  Comments?: Maybe<Order_By>;
  Component?: Maybe<Component_Order_By>;
  ComponentApprovedUse?: Maybe<ComponentApprovedUse_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ComponentLayerUsages_aggregate?: Maybe<ComponentLayerUsage_Aggregate_Order_By>;
  CompositePanelUsage?: Maybe<CompositePanelUsage_Order_By>;
  CoverBoardUsage?: Maybe<CoverBoardUsage_Order_By>;
  CoverStripDiskUsage?: Maybe<CoverStripDiskUsage_Order_By>;
  EnteredAsMetric?: Maybe<Order_By>;
  ExpansionJointUsage?: Maybe<ExpansionJointUsage_Order_By>;
  FastenerUsage?: Maybe<FastenerUsage_Order_By>;
  FasteningSystemBattenBarUsage?: Maybe<FasteningSystemBattenBarUsage_Order_By>;
  FasteningSystemClipUsage?: Maybe<FasteningSystemClipUsage_Order_By>;
  FasteningSystemStressPlateUsage?: Maybe<FasteningSystemStressPlateUsage_Order_By>;
  FiberReinforcedPlasticDeckUsage?: Maybe<FiberReinforcedPlasticDeckUsage_Order_By>;
  FormDeckUsage?: Maybe<FormDeckUsage_Order_By>;
  GypsumDeckUsage?: Maybe<GypsumDeckUsage_Order_By>;
  LapSealantUsage?: Maybe<LapSealantUsage_Order_By>;
  LapSecurementInfo?: Maybe<LapSecurementInfo_Order_By>;
  LightweightInsulatingConcreteSubassemblyUsage?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Order_By>;
  LightweightInsulatingConcreteUsage?: Maybe<LightweightInsulatingConcreteUsage_Order_By>;
  LinerPanelUsage?: Maybe<LinerPanelUsage_Order_By>;
  LiquidAppliedSubassemblyUsage?: Maybe<LiquidAppliedSubassemblyUsage_Order_By>;
  LiquidAppliedUsage?: Maybe<LiquidAppliedUsage_Order_By>;
  MaintenanceCoatingUsage?: Maybe<MaintenanceCoatingUsage_Order_By>;
  MultiplyCoverSubassemblyUsage?: Maybe<MultiplyCoverSubassemblyUsage_Order_By>;
  NavUsages_aggregate?: Maybe<NavUsage_Aggregate_Order_By>;
  OtherPlyUsage?: Maybe<OtherPlyUsage_Order_By>;
  PreassembledFasteningUsage?: Maybe<PreassembledFasteningUsage_Order_By>;
  SeamPrimerUsage?: Maybe<SeamPrimerUsage_Order_By>;
  SeamTapeSystemUsage?: Maybe<SeamTapeSystemUsage_Order_By>;
  SeamWashUsage?: Maybe<SeamWashUsage_Order_By>;
  SecurementLayerUsages_aggregate?: Maybe<SecurementLayerUsage_Aggregate_Order_By>;
  SelfSecurementUsages_aggregate?: Maybe<SelfSecurementUsage_Aggregate_Order_By>;
  SeparatorSheetUsage?: Maybe<SeparatorSheetUsage_Order_By>;
  SinglePlyUsage?: Maybe<SinglePlyUsage_Order_By>;
  SprayFoamInsulationUsage?: Maybe<SprayFoamInsulationUsage_Order_By>;
  StandingLapSeamUsage?: Maybe<StandingLapSeamUsage_Order_By>;
  StatusId?: Maybe<Order_By>;
  SteelDeckUsage?: Maybe<SteelDeckUsage_Order_By>;
  StressPlateUsage?: Maybe<StressPlateUsage_Order_By>;
  SubstratePrimerUsage?: Maybe<SubstratePrimerUsage_Order_By>;
  SurfacingUsage?: Maybe<SurfacingUsage_Order_By>;
  ThermalBarrierUsage?: Maybe<ThermalBarrierUsage_Order_By>;
  TopCoatUsage?: Maybe<TopCoatUsage_Order_By>;
  UsageId?: Maybe<Order_By>;
  VaporRetarderUsage?: Maybe<VaporRetarderUsage_Order_By>;
  WeldArcUsage?: Maybe<WeldArcUsage_Order_By>;
  WeldHeatUsage?: Maybe<WeldHeatUsage_Order_By>;
  WeldSolventUsage?: Maybe<WeldSolventUsage_Order_By>;
  WeldSystemUsage?: Maybe<WeldSystemUsage_Order_By>;
  WeldTorchedUsage?: Maybe<WeldTorchedUsage_Order_By>;
  WoodDeckUsage?: Maybe<WoodDeckUsage_Order_By>;
};

/** primary key columns input for table: Usage */
export type Usage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "Usage" */
export enum Usage_Select_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Comments = 'Comments',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  StatusId = 'StatusId',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "Usage" */
export type Usage_Set_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  Comments?: Maybe<Scalars['ItemComment']>;
  ComponentId?: Maybe<Scalars['Int']>;
  EnteredAsMetric?: Maybe<Scalars['MetricEntryFlag']>;
  StatusId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Usage_Stddev_Fields = {
  __typename?: 'Usage_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Usage" */
export type Usage_Stddev_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Usage_Stddev_Pop_Fields = {
  __typename?: 'Usage_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Usage" */
export type Usage_Stddev_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Usage_Stddev_Samp_Fields = {
  __typename?: 'Usage_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Usage" */
export type Usage_Stddev_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Usage_Sum_Fields = {
  __typename?: 'Usage_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  StatusId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Usage" */
export type Usage_Sum_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "Usage" */
export enum Usage_Update_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  Comments = 'Comments',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  EnteredAsMetric = 'EnteredAsMetric',
  /** column name */
  StatusId = 'StatusId'
}

/** aggregate var_pop on columns */
export type Usage_Var_Pop_Fields = {
  __typename?: 'Usage_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Usage" */
export type Usage_Var_Pop_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Usage_Var_Samp_Fields = {
  __typename?: 'Usage_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Usage" */
export type Usage_Var_Samp_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Usage_Variance_Fields = {
  __typename?: 'Usage_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  StatusId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Usage" */
export type Usage_Variance_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "UserReportBug" */
export type UserReportBug = {
  __typename?: 'UserReportBug';
  CreationTime: Scalars['datetime'];
  ExtraNotes?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ReportBugGuid: Scalars['String'];
  ReportBugId: Scalars['Int'];
  SearchCriteriaData: Scalars['String'];
  UrlPath?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
};

/** aggregated selection of "UserReportBug" */
export type UserReportBug_Aggregate = {
  __typename?: 'UserReportBug_aggregate';
  aggregate?: Maybe<UserReportBug_Aggregate_Fields>;
  nodes: Array<UserReportBug>;
};

/** aggregate fields of "UserReportBug" */
export type UserReportBug_Aggregate_Fields = {
  __typename?: 'UserReportBug_aggregate_fields';
  avg?: Maybe<UserReportBug_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<UserReportBug_Max_Fields>;
  min?: Maybe<UserReportBug_Min_Fields>;
  stddev?: Maybe<UserReportBug_Stddev_Fields>;
  stddev_pop?: Maybe<UserReportBug_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserReportBug_Stddev_Samp_Fields>;
  sum?: Maybe<UserReportBug_Sum_Fields>;
  var_pop?: Maybe<UserReportBug_Var_Pop_Fields>;
  var_samp?: Maybe<UserReportBug_Var_Samp_Fields>;
  variance?: Maybe<UserReportBug_Variance_Fields>;
};


/** aggregate fields of "UserReportBug" */
export type UserReportBug_Aggregate_FieldsCountArgs = {
  column?: Maybe<UserReportBug_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type UserReportBug_Avg_Fields = {
  __typename?: 'UserReportBug_avg_fields';
  ReportBugId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "UserReportBug". All fields are combined with a logical 'AND'. */
export type UserReportBug_Bool_Exp = {
  CreationTime?: Maybe<Datetime_Mssql_Comparison_Exp>;
  ExtraNotes?: Maybe<String_Mssql_Comparison_Exp>;
  Reason?: Maybe<String_Mssql_Comparison_Exp>;
  ReportBugGuid?: Maybe<String_Mssql_Comparison_Exp>;
  ReportBugId?: Maybe<Int_Mssql_Comparison_Exp>;
  SearchCriteriaData?: Maybe<String_Mssql_Comparison_Exp>;
  UrlPath?: Maybe<String_Mssql_Comparison_Exp>;
  UserEmail?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<UserReportBug_Bool_Exp>>;
  _not?: Maybe<UserReportBug_Bool_Exp>;
  _or?: Maybe<Array<UserReportBug_Bool_Exp>>;
};

/** upsert condition type for table "UserReportBug" */
export type UserReportBug_If_Matched = {
  match_columns?: Array<UserReportBug_Insert_Match_Column>;
  update_columns?: Array<UserReportBug_Update_Column>;
  where?: Maybe<UserReportBug_Bool_Exp>;
};

/** input type for inserting data into table "UserReportBug" */
export type UserReportBug_Insert_Input = {
  CreationTime?: Maybe<Scalars['datetime']>;
  ExtraNotes?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ReportBugGuid?: Maybe<Scalars['String']>;
  SearchCriteriaData?: Maybe<Scalars['String']>;
  UrlPath?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
};

/** select match_columns of table "UserReportBug" */
export enum UserReportBug_Insert_Match_Column {
  /** column name */
  CreationTime = 'CreationTime',
  /** column name */
  ExtraNotes = 'ExtraNotes',
  /** column name */
  Reason = 'Reason',
  /** column name */
  ReportBugGuid = 'ReportBugGuid',
  /** column name */
  ReportBugId = 'ReportBugId',
  /** column name */
  SearchCriteriaData = 'SearchCriteriaData',
  /** column name */
  UrlPath = 'UrlPath',
  /** column name */
  UserEmail = 'UserEmail'
}

/** aggregate max on columns */
export type UserReportBug_Max_Fields = {
  __typename?: 'UserReportBug_max_fields';
  CreationTime?: Maybe<Scalars['datetime']>;
  ExtraNotes?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ReportBugGuid?: Maybe<Scalars['String']>;
  ReportBugId?: Maybe<Scalars['Int']>;
  SearchCriteriaData?: Maybe<Scalars['String']>;
  UrlPath?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type UserReportBug_Min_Fields = {
  __typename?: 'UserReportBug_min_fields';
  CreationTime?: Maybe<Scalars['datetime']>;
  ExtraNotes?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ReportBugGuid?: Maybe<Scalars['String']>;
  ReportBugId?: Maybe<Scalars['Int']>;
  SearchCriteriaData?: Maybe<Scalars['String']>;
  UrlPath?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "UserReportBug" */
export type UserReportBug_Mutation_Response = {
  __typename?: 'UserReportBug_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserReportBug>;
};

/** Ordering options when selecting data from "UserReportBug". */
export type UserReportBug_Order_By = {
  CreationTime?: Maybe<Order_By>;
  ExtraNotes?: Maybe<Order_By>;
  Reason?: Maybe<Order_By>;
  ReportBugGuid?: Maybe<Order_By>;
  ReportBugId?: Maybe<Order_By>;
  SearchCriteriaData?: Maybe<Order_By>;
  UrlPath?: Maybe<Order_By>;
  UserEmail?: Maybe<Order_By>;
};

/** primary key columns input for table: UserReportBug */
export type UserReportBug_Pk_Columns_Input = {
  ReportBugId: Scalars['Int'];
};

/** select columns of table "UserReportBug" */
export enum UserReportBug_Select_Column {
  /** column name */
  CreationTime = 'CreationTime',
  /** column name */
  ExtraNotes = 'ExtraNotes',
  /** column name */
  Reason = 'Reason',
  /** column name */
  ReportBugGuid = 'ReportBugGuid',
  /** column name */
  ReportBugId = 'ReportBugId',
  /** column name */
  SearchCriteriaData = 'SearchCriteriaData',
  /** column name */
  UrlPath = 'UrlPath',
  /** column name */
  UserEmail = 'UserEmail'
}

/** input type for updating data in table "UserReportBug" */
export type UserReportBug_Set_Input = {
  CreationTime?: Maybe<Scalars['datetime']>;
  ExtraNotes?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ReportBugGuid?: Maybe<Scalars['String']>;
  SearchCriteriaData?: Maybe<Scalars['String']>;
  UrlPath?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserReportBug_Stddev_Fields = {
  __typename?: 'UserReportBug_stddev_fields';
  ReportBugId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type UserReportBug_Stddev_Pop_Fields = {
  __typename?: 'UserReportBug_stddev_pop_fields';
  ReportBugId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type UserReportBug_Stddev_Samp_Fields = {
  __typename?: 'UserReportBug_stddev_samp_fields';
  ReportBugId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type UserReportBug_Sum_Fields = {
  __typename?: 'UserReportBug_sum_fields';
  ReportBugId?: Maybe<Scalars['Int']>;
};

/** update columns of table "UserReportBug" */
export enum UserReportBug_Update_Column {
  /** column name */
  CreationTime = 'CreationTime',
  /** column name */
  ExtraNotes = 'ExtraNotes',
  /** column name */
  Reason = 'Reason',
  /** column name */
  ReportBugGuid = 'ReportBugGuid',
  /** column name */
  SearchCriteriaData = 'SearchCriteriaData',
  /** column name */
  UrlPath = 'UrlPath',
  /** column name */
  UserEmail = 'UserEmail'
}

/** aggregate var_pop on columns */
export type UserReportBug_Var_Pop_Fields = {
  __typename?: 'UserReportBug_var_pop_fields';
  ReportBugId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type UserReportBug_Var_Samp_Fields = {
  __typename?: 'UserReportBug_var_samp_fields';
  ReportBugId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type UserReportBug_Variance_Fields = {
  __typename?: 'UserReportBug_variance_fields';
  ReportBugId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "UserShareLink" */
export type UserShareLink = {
  __typename?: 'UserShareLink';
  CreationTime: Scalars['datetime'];
  SearchCriteriaData: Scalars['String'];
  ShareLinkGuid: Scalars['String'];
  ShareLinkId: Scalars['Int'];
  UserGuid: Scalars['String'];
};

/** aggregated selection of "UserShareLink" */
export type UserShareLink_Aggregate = {
  __typename?: 'UserShareLink_aggregate';
  aggregate?: Maybe<UserShareLink_Aggregate_Fields>;
  nodes: Array<UserShareLink>;
};

/** aggregate fields of "UserShareLink" */
export type UserShareLink_Aggregate_Fields = {
  __typename?: 'UserShareLink_aggregate_fields';
  avg?: Maybe<UserShareLink_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<UserShareLink_Max_Fields>;
  min?: Maybe<UserShareLink_Min_Fields>;
  stddev?: Maybe<UserShareLink_Stddev_Fields>;
  stddev_pop?: Maybe<UserShareLink_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserShareLink_Stddev_Samp_Fields>;
  sum?: Maybe<UserShareLink_Sum_Fields>;
  var_pop?: Maybe<UserShareLink_Var_Pop_Fields>;
  var_samp?: Maybe<UserShareLink_Var_Samp_Fields>;
  variance?: Maybe<UserShareLink_Variance_Fields>;
};


/** aggregate fields of "UserShareLink" */
export type UserShareLink_Aggregate_FieldsCountArgs = {
  column?: Maybe<UserShareLink_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type UserShareLink_Avg_Fields = {
  __typename?: 'UserShareLink_avg_fields';
  ShareLinkId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "UserShareLink". All fields are combined with a logical 'AND'. */
export type UserShareLink_Bool_Exp = {
  CreationTime?: Maybe<Datetime_Mssql_Comparison_Exp>;
  SearchCriteriaData?: Maybe<String_Mssql_Comparison_Exp>;
  ShareLinkGuid?: Maybe<String_Mssql_Comparison_Exp>;
  ShareLinkId?: Maybe<Int_Mssql_Comparison_Exp>;
  UserGuid?: Maybe<String_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<UserShareLink_Bool_Exp>>;
  _not?: Maybe<UserShareLink_Bool_Exp>;
  _or?: Maybe<Array<UserShareLink_Bool_Exp>>;
};

/** upsert condition type for table "UserShareLink" */
export type UserShareLink_If_Matched = {
  match_columns?: Array<UserShareLink_Insert_Match_Column>;
  update_columns?: Array<UserShareLink_Update_Column>;
  where?: Maybe<UserShareLink_Bool_Exp>;
};

/** input type for inserting data into table "UserShareLink" */
export type UserShareLink_Insert_Input = {
  CreationTime?: Maybe<Scalars['datetime']>;
  SearchCriteriaData?: Maybe<Scalars['String']>;
  ShareLinkGuid?: Maybe<Scalars['String']>;
  UserGuid?: Maybe<Scalars['String']>;
};

/** select match_columns of table "UserShareLink" */
export enum UserShareLink_Insert_Match_Column {
  /** column name */
  CreationTime = 'CreationTime',
  /** column name */
  SearchCriteriaData = 'SearchCriteriaData',
  /** column name */
  ShareLinkGuid = 'ShareLinkGuid',
  /** column name */
  ShareLinkId = 'ShareLinkId',
  /** column name */
  UserGuid = 'UserGuid'
}

/** aggregate max on columns */
export type UserShareLink_Max_Fields = {
  __typename?: 'UserShareLink_max_fields';
  CreationTime?: Maybe<Scalars['datetime']>;
  SearchCriteriaData?: Maybe<Scalars['String']>;
  ShareLinkGuid?: Maybe<Scalars['String']>;
  ShareLinkId?: Maybe<Scalars['Int']>;
  UserGuid?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type UserShareLink_Min_Fields = {
  __typename?: 'UserShareLink_min_fields';
  CreationTime?: Maybe<Scalars['datetime']>;
  SearchCriteriaData?: Maybe<Scalars['String']>;
  ShareLinkGuid?: Maybe<Scalars['String']>;
  ShareLinkId?: Maybe<Scalars['Int']>;
  UserGuid?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "UserShareLink" */
export type UserShareLink_Mutation_Response = {
  __typename?: 'UserShareLink_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserShareLink>;
};

/** Ordering options when selecting data from "UserShareLink". */
export type UserShareLink_Order_By = {
  CreationTime?: Maybe<Order_By>;
  SearchCriteriaData?: Maybe<Order_By>;
  ShareLinkGuid?: Maybe<Order_By>;
  ShareLinkId?: Maybe<Order_By>;
  UserGuid?: Maybe<Order_By>;
};

/** primary key columns input for table: UserShareLink */
export type UserShareLink_Pk_Columns_Input = {
  ShareLinkId: Scalars['Int'];
};

/** select columns of table "UserShareLink" */
export enum UserShareLink_Select_Column {
  /** column name */
  CreationTime = 'CreationTime',
  /** column name */
  SearchCriteriaData = 'SearchCriteriaData',
  /** column name */
  ShareLinkGuid = 'ShareLinkGuid',
  /** column name */
  ShareLinkId = 'ShareLinkId',
  /** column name */
  UserGuid = 'UserGuid'
}

/** input type for updating data in table "UserShareLink" */
export type UserShareLink_Set_Input = {
  CreationTime?: Maybe<Scalars['datetime']>;
  SearchCriteriaData?: Maybe<Scalars['String']>;
  ShareLinkGuid?: Maybe<Scalars['String']>;
  UserGuid?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserShareLink_Stddev_Fields = {
  __typename?: 'UserShareLink_stddev_fields';
  ShareLinkId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type UserShareLink_Stddev_Pop_Fields = {
  __typename?: 'UserShareLink_stddev_pop_fields';
  ShareLinkId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type UserShareLink_Stddev_Samp_Fields = {
  __typename?: 'UserShareLink_stddev_samp_fields';
  ShareLinkId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type UserShareLink_Sum_Fields = {
  __typename?: 'UserShareLink_sum_fields';
  ShareLinkId?: Maybe<Scalars['Int']>;
};

/** update columns of table "UserShareLink" */
export enum UserShareLink_Update_Column {
  /** column name */
  CreationTime = 'CreationTime',
  /** column name */
  SearchCriteriaData = 'SearchCriteriaData',
  /** column name */
  ShareLinkGuid = 'ShareLinkGuid',
  /** column name */
  UserGuid = 'UserGuid'
}

/** aggregate var_pop on columns */
export type UserShareLink_Var_Pop_Fields = {
  __typename?: 'UserShareLink_var_pop_fields';
  ShareLinkId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type UserShareLink_Var_Samp_Fields = {
  __typename?: 'UserShareLink_var_samp_fields';
  ShareLinkId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type UserShareLink_Variance_Fields = {
  __typename?: 'UserShareLink_variance_fields';
  ShareLinkId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "VaporRetarder" */
export type VaporRetarder = {
  __typename?: 'VaporRetarder';
  /** An object relationship */
  C_RetarderMaterial: C_RetarderMaterial;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  RetarderMaterialId: Scalars['Int'];
};

/** columns and relationships of "VaporRetarderUsage" */
export type VaporRetarderUsage = {
  __typename?: 'VaporRetarderUsage';
  NumPlies: Scalars['Int'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "VaporRetarderUsage" */
export type VaporRetarderUsage_Aggregate = {
  __typename?: 'VaporRetarderUsage_aggregate';
  aggregate?: Maybe<VaporRetarderUsage_Aggregate_Fields>;
  nodes: Array<VaporRetarderUsage>;
};

/** aggregate fields of "VaporRetarderUsage" */
export type VaporRetarderUsage_Aggregate_Fields = {
  __typename?: 'VaporRetarderUsage_aggregate_fields';
  avg?: Maybe<VaporRetarderUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VaporRetarderUsage_Max_Fields>;
  min?: Maybe<VaporRetarderUsage_Min_Fields>;
  stddev?: Maybe<VaporRetarderUsage_Stddev_Fields>;
  stddev_pop?: Maybe<VaporRetarderUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VaporRetarderUsage_Stddev_Samp_Fields>;
  sum?: Maybe<VaporRetarderUsage_Sum_Fields>;
  var_pop?: Maybe<VaporRetarderUsage_Var_Pop_Fields>;
  var_samp?: Maybe<VaporRetarderUsage_Var_Samp_Fields>;
  variance?: Maybe<VaporRetarderUsage_Variance_Fields>;
};


/** aggregate fields of "VaporRetarderUsage" */
export type VaporRetarderUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<VaporRetarderUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type VaporRetarderUsage_Avg_Fields = {
  __typename?: 'VaporRetarderUsage_avg_fields';
  NumPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "VaporRetarderUsage". All fields are combined with a logical 'AND'. */
export type VaporRetarderUsage_Bool_Exp = {
  NumPlies?: Maybe<Int_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VaporRetarderUsage_Bool_Exp>>;
  _not?: Maybe<VaporRetarderUsage_Bool_Exp>;
  _or?: Maybe<Array<VaporRetarderUsage_Bool_Exp>>;
};

/** upsert condition type for table "VaporRetarderUsage" */
export type VaporRetarderUsage_If_Matched = {
  match_columns?: Array<VaporRetarderUsage_Insert_Match_Column>;
  update_columns?: Array<VaporRetarderUsage_Update_Column>;
  where?: Maybe<VaporRetarderUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "VaporRetarderUsage" */
export type VaporRetarderUsage_Inc_Input = {
  NumPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "VaporRetarderUsage" */
export type VaporRetarderUsage_Insert_Input = {
  NumPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "VaporRetarderUsage" */
export enum VaporRetarderUsage_Insert_Match_Column {
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VaporRetarderUsage_Max_Fields = {
  __typename?: 'VaporRetarderUsage_max_fields';
  NumPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type VaporRetarderUsage_Min_Fields = {
  __typename?: 'VaporRetarderUsage_min_fields';
  NumPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "VaporRetarderUsage" */
export type VaporRetarderUsage_Mutation_Response = {
  __typename?: 'VaporRetarderUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VaporRetarderUsage>;
};

/** Ordering options when selecting data from "VaporRetarderUsage". */
export type VaporRetarderUsage_Order_By = {
  NumPlies?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: VaporRetarderUsage */
export type VaporRetarderUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "VaporRetarderUsage" */
export enum VaporRetarderUsage_Select_Column {
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "VaporRetarderUsage" */
export type VaporRetarderUsage_Set_Input = {
  NumPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VaporRetarderUsage_Stddev_Fields = {
  __typename?: 'VaporRetarderUsage_stddev_fields';
  NumPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type VaporRetarderUsage_Stddev_Pop_Fields = {
  __typename?: 'VaporRetarderUsage_stddev_pop_fields';
  NumPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type VaporRetarderUsage_Stddev_Samp_Fields = {
  __typename?: 'VaporRetarderUsage_stddev_samp_fields';
  NumPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type VaporRetarderUsage_Sum_Fields = {
  __typename?: 'VaporRetarderUsage_sum_fields';
  NumPlies?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "VaporRetarderUsage" */
export enum VaporRetarderUsage_Update_Column {
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VaporRetarderUsage_Var_Pop_Fields = {
  __typename?: 'VaporRetarderUsage_var_pop_fields';
  NumPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type VaporRetarderUsage_Var_Samp_Fields = {
  __typename?: 'VaporRetarderUsage_var_samp_fields';
  NumPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type VaporRetarderUsage_Variance_Fields = {
  __typename?: 'VaporRetarderUsage_variance_fields';
  NumPlies?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "VaporRetarder" */
export type VaporRetarder_Aggregate = {
  __typename?: 'VaporRetarder_aggregate';
  aggregate?: Maybe<VaporRetarder_Aggregate_Fields>;
  nodes: Array<VaporRetarder>;
};

/** aggregate fields of "VaporRetarder" */
export type VaporRetarder_Aggregate_Fields = {
  __typename?: 'VaporRetarder_aggregate_fields';
  avg?: Maybe<VaporRetarder_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VaporRetarder_Max_Fields>;
  min?: Maybe<VaporRetarder_Min_Fields>;
  stddev?: Maybe<VaporRetarder_Stddev_Fields>;
  stddev_pop?: Maybe<VaporRetarder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VaporRetarder_Stddev_Samp_Fields>;
  sum?: Maybe<VaporRetarder_Sum_Fields>;
  var_pop?: Maybe<VaporRetarder_Var_Pop_Fields>;
  var_samp?: Maybe<VaporRetarder_Var_Samp_Fields>;
  variance?: Maybe<VaporRetarder_Variance_Fields>;
};


/** aggregate fields of "VaporRetarder" */
export type VaporRetarder_Aggregate_FieldsCountArgs = {
  column?: Maybe<VaporRetarder_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "VaporRetarder" */
export type VaporRetarder_Aggregate_Order_By = {
  avg?: Maybe<VaporRetarder_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VaporRetarder_Max_Order_By>;
  min?: Maybe<VaporRetarder_Min_Order_By>;
  stddev?: Maybe<VaporRetarder_Stddev_Order_By>;
  stddev_pop?: Maybe<VaporRetarder_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VaporRetarder_Stddev_Samp_Order_By>;
  sum?: Maybe<VaporRetarder_Sum_Order_By>;
  var_pop?: Maybe<VaporRetarder_Var_Pop_Order_By>;
  var_samp?: Maybe<VaporRetarder_Var_Samp_Order_By>;
  variance?: Maybe<VaporRetarder_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VaporRetarder_Avg_Fields = {
  __typename?: 'VaporRetarder_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "VaporRetarder" */
export type VaporRetarder_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "VaporRetarder". All fields are combined with a logical 'AND'. */
export type VaporRetarder_Bool_Exp = {
  C_RetarderMaterial?: Maybe<C_RetarderMaterial_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  RetarderMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VaporRetarder_Bool_Exp>>;
  _not?: Maybe<VaporRetarder_Bool_Exp>;
  _or?: Maybe<Array<VaporRetarder_Bool_Exp>>;
};

/** upsert condition type for table "VaporRetarder" */
export type VaporRetarder_If_Matched = {
  match_columns?: Array<VaporRetarder_Insert_Match_Column>;
  update_columns?: Array<VaporRetarder_Update_Column>;
  where?: Maybe<VaporRetarder_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "VaporRetarder" */
export type VaporRetarder_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "VaporRetarder" */
export type VaporRetarder_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "VaporRetarder" */
export enum VaporRetarder_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId'
}

/** aggregate max on columns */
export type VaporRetarder_Max_Fields = {
  __typename?: 'VaporRetarder_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "VaporRetarder" */
export type VaporRetarder_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VaporRetarder_Min_Fields = {
  __typename?: 'VaporRetarder_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "VaporRetarder" */
export type VaporRetarder_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** response of any mutation on the table "VaporRetarder" */
export type VaporRetarder_Mutation_Response = {
  __typename?: 'VaporRetarder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VaporRetarder>;
};

/** Ordering options when selecting data from "VaporRetarder". */
export type VaporRetarder_Order_By = {
  C_RetarderMaterial?: Maybe<C_RetarderMaterial_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** primary key columns input for table: VaporRetarder */
export type VaporRetarder_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "VaporRetarder" */
export enum VaporRetarder_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId'
}

/** input type for updating data in table "VaporRetarder" */
export type VaporRetarder_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VaporRetarder_Stddev_Fields = {
  __typename?: 'VaporRetarder_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "VaporRetarder" */
export type VaporRetarder_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VaporRetarder_Stddev_Pop_Fields = {
  __typename?: 'VaporRetarder_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "VaporRetarder" */
export type VaporRetarder_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VaporRetarder_Stddev_Samp_Fields = {
  __typename?: 'VaporRetarder_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "VaporRetarder" */
export type VaporRetarder_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VaporRetarder_Sum_Fields = {
  __typename?: 'VaporRetarder_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "VaporRetarder" */
export type VaporRetarder_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** update columns of table "VaporRetarder" */
export enum VaporRetarder_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId'
}

/** aggregate var_pop on columns */
export type VaporRetarder_Var_Pop_Fields = {
  __typename?: 'VaporRetarder_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "VaporRetarder" */
export type VaporRetarder_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VaporRetarder_Var_Samp_Fields = {
  __typename?: 'VaporRetarder_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "VaporRetarder" */
export type VaporRetarder_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VaporRetarder_Variance_Fields = {
  __typename?: 'VaporRetarder_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "VaporRetarder" */
export type VaporRetarder_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
};

/** columns and relationships of "WeldArcUsage" */
export type WeldArcUsage = {
  __typename?: 'WeldArcUsage';
  Diameter: Scalars['MeasurementValue'];
  OnCenter: Scalars['MeasurementValue'];
  RowSpacing: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
  WithWasher: Scalars['Boolean'];
};

/** aggregated selection of "WeldArcUsage" */
export type WeldArcUsage_Aggregate = {
  __typename?: 'WeldArcUsage_aggregate';
  aggregate?: Maybe<WeldArcUsage_Aggregate_Fields>;
  nodes: Array<WeldArcUsage>;
};

/** aggregate fields of "WeldArcUsage" */
export type WeldArcUsage_Aggregate_Fields = {
  __typename?: 'WeldArcUsage_aggregate_fields';
  avg?: Maybe<WeldArcUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<WeldArcUsage_Max_Fields>;
  min?: Maybe<WeldArcUsage_Min_Fields>;
  stddev?: Maybe<WeldArcUsage_Stddev_Fields>;
  stddev_pop?: Maybe<WeldArcUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<WeldArcUsage_Stddev_Samp_Fields>;
  sum?: Maybe<WeldArcUsage_Sum_Fields>;
  var_pop?: Maybe<WeldArcUsage_Var_Pop_Fields>;
  var_samp?: Maybe<WeldArcUsage_Var_Samp_Fields>;
  variance?: Maybe<WeldArcUsage_Variance_Fields>;
};


/** aggregate fields of "WeldArcUsage" */
export type WeldArcUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<WeldArcUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type WeldArcUsage_Avg_Fields = {
  __typename?: 'WeldArcUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WeldArcUsage". All fields are combined with a logical 'AND'. */
export type WeldArcUsage_Bool_Exp = {
  Diameter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  OnCenter?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  RowSpacing?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  WithWasher?: Maybe<Boolean_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<WeldArcUsage_Bool_Exp>>;
  _not?: Maybe<WeldArcUsage_Bool_Exp>;
  _or?: Maybe<Array<WeldArcUsage_Bool_Exp>>;
};

/** upsert condition type for table "WeldArcUsage" */
export type WeldArcUsage_If_Matched = {
  match_columns?: Array<WeldArcUsage_Insert_Match_Column>;
  update_columns?: Array<WeldArcUsage_Update_Column>;
  where?: Maybe<WeldArcUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "WeldArcUsage" */
export type WeldArcUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "WeldArcUsage" */
export type WeldArcUsage_Insert_Input = {
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WithWasher?: Maybe<Scalars['Boolean']>;
};

/** select match_columns of table "WeldArcUsage" */
export enum WeldArcUsage_Insert_Match_Column {
  /** column name */
  Diameter = 'Diameter',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WithWasher = 'WithWasher'
}

/** aggregate max on columns */
export type WeldArcUsage_Max_Fields = {
  __typename?: 'WeldArcUsage_max_fields';
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type WeldArcUsage_Min_Fields = {
  __typename?: 'WeldArcUsage_min_fields';
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "WeldArcUsage" */
export type WeldArcUsage_Mutation_Response = {
  __typename?: 'WeldArcUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WeldArcUsage>;
};

/** Ordering options when selecting data from "WeldArcUsage". */
export type WeldArcUsage_Order_By = {
  Diameter?: Maybe<Order_By>;
  OnCenter?: Maybe<Order_By>;
  RowSpacing?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
  WithWasher?: Maybe<Order_By>;
};

/** primary key columns input for table: WeldArcUsage */
export type WeldArcUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "WeldArcUsage" */
export enum WeldArcUsage_Select_Column {
  /** column name */
  Diameter = 'Diameter',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WithWasher = 'WithWasher'
}

/** input type for updating data in table "WeldArcUsage" */
export type WeldArcUsage_Set_Input = {
  Diameter?: Maybe<Scalars['MeasurementValue']>;
  OnCenter?: Maybe<Scalars['MeasurementValue']>;
  RowSpacing?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WithWasher?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type WeldArcUsage_Stddev_Fields = {
  __typename?: 'WeldArcUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type WeldArcUsage_Stddev_Pop_Fields = {
  __typename?: 'WeldArcUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type WeldArcUsage_Stddev_Samp_Fields = {
  __typename?: 'WeldArcUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type WeldArcUsage_Sum_Fields = {
  __typename?: 'WeldArcUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "WeldArcUsage" */
export enum WeldArcUsage_Update_Column {
  /** column name */
  Diameter = 'Diameter',
  /** column name */
  OnCenter = 'OnCenter',
  /** column name */
  RowSpacing = 'RowSpacing',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WithWasher = 'WithWasher'
}

/** aggregate var_pop on columns */
export type WeldArcUsage_Var_Pop_Fields = {
  __typename?: 'WeldArcUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type WeldArcUsage_Var_Samp_Fields = {
  __typename?: 'WeldArcUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type WeldArcUsage_Variance_Fields = {
  __typename?: 'WeldArcUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WeldHeatUsage" */
export type WeldHeatUsage = {
  __typename?: 'WeldHeatUsage';
  /** An object relationship */
  C_WeldConfiguration: C_WeldConfiguration;
  InsideWidth: Scalars['MeasurementValue'];
  OutsideWidth: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
  WeldConfigurationId: Scalars['Int'];
};

/** aggregated selection of "WeldHeatUsage" */
export type WeldHeatUsage_Aggregate = {
  __typename?: 'WeldHeatUsage_aggregate';
  aggregate?: Maybe<WeldHeatUsage_Aggregate_Fields>;
  nodes: Array<WeldHeatUsage>;
};

/** aggregate fields of "WeldHeatUsage" */
export type WeldHeatUsage_Aggregate_Fields = {
  __typename?: 'WeldHeatUsage_aggregate_fields';
  avg?: Maybe<WeldHeatUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<WeldHeatUsage_Max_Fields>;
  min?: Maybe<WeldHeatUsage_Min_Fields>;
  stddev?: Maybe<WeldHeatUsage_Stddev_Fields>;
  stddev_pop?: Maybe<WeldHeatUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<WeldHeatUsage_Stddev_Samp_Fields>;
  sum?: Maybe<WeldHeatUsage_Sum_Fields>;
  var_pop?: Maybe<WeldHeatUsage_Var_Pop_Fields>;
  var_samp?: Maybe<WeldHeatUsage_Var_Samp_Fields>;
  variance?: Maybe<WeldHeatUsage_Variance_Fields>;
};


/** aggregate fields of "WeldHeatUsage" */
export type WeldHeatUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<WeldHeatUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WeldHeatUsage" */
export type WeldHeatUsage_Aggregate_Order_By = {
  avg?: Maybe<WeldHeatUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<WeldHeatUsage_Max_Order_By>;
  min?: Maybe<WeldHeatUsage_Min_Order_By>;
  stddev?: Maybe<WeldHeatUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<WeldHeatUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<WeldHeatUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<WeldHeatUsage_Sum_Order_By>;
  var_pop?: Maybe<WeldHeatUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<WeldHeatUsage_Var_Samp_Order_By>;
  variance?: Maybe<WeldHeatUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type WeldHeatUsage_Avg_Fields = {
  __typename?: 'WeldHeatUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WeldHeatUsage" */
export type WeldHeatUsage_Avg_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WeldHeatUsage". All fields are combined with a logical 'AND'. */
export type WeldHeatUsage_Bool_Exp = {
  C_WeldConfiguration?: Maybe<C_WeldConfiguration_Bool_Exp>;
  InsideWidth?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  OutsideWidth?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  WeldConfigurationId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<WeldHeatUsage_Bool_Exp>>;
  _not?: Maybe<WeldHeatUsage_Bool_Exp>;
  _or?: Maybe<Array<WeldHeatUsage_Bool_Exp>>;
};

/** upsert condition type for table "WeldHeatUsage" */
export type WeldHeatUsage_If_Matched = {
  match_columns?: Array<WeldHeatUsage_Insert_Match_Column>;
  update_columns?: Array<WeldHeatUsage_Update_Column>;
  where?: Maybe<WeldHeatUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "WeldHeatUsage" */
export type WeldHeatUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "WeldHeatUsage" */
export type WeldHeatUsage_Insert_Input = {
  InsideWidth?: Maybe<Scalars['MeasurementValue']>;
  OutsideWidth?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "WeldHeatUsage" */
export enum WeldHeatUsage_Insert_Match_Column {
  /** column name */
  InsideWidth = 'InsideWidth',
  /** column name */
  OutsideWidth = 'OutsideWidth',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeldConfigurationId = 'WeldConfigurationId'
}

/** aggregate max on columns */
export type WeldHeatUsage_Max_Fields = {
  __typename?: 'WeldHeatUsage_max_fields';
  InsideWidth?: Maybe<Scalars['MeasurementValue']>;
  OutsideWidth?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "WeldHeatUsage" */
export type WeldHeatUsage_Max_Order_By = {
  InsideWidth?: Maybe<Order_By>;
  OutsideWidth?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type WeldHeatUsage_Min_Fields = {
  __typename?: 'WeldHeatUsage_min_fields';
  InsideWidth?: Maybe<Scalars['MeasurementValue']>;
  OutsideWidth?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "WeldHeatUsage" */
export type WeldHeatUsage_Min_Order_By = {
  InsideWidth?: Maybe<Order_By>;
  OutsideWidth?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** response of any mutation on the table "WeldHeatUsage" */
export type WeldHeatUsage_Mutation_Response = {
  __typename?: 'WeldHeatUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WeldHeatUsage>;
};

/** Ordering options when selecting data from "WeldHeatUsage". */
export type WeldHeatUsage_Order_By = {
  C_WeldConfiguration?: Maybe<C_WeldConfiguration_Order_By>;
  InsideWidth?: Maybe<Order_By>;
  OutsideWidth?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** primary key columns input for table: WeldHeatUsage */
export type WeldHeatUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "WeldHeatUsage" */
export enum WeldHeatUsage_Select_Column {
  /** column name */
  InsideWidth = 'InsideWidth',
  /** column name */
  OutsideWidth = 'OutsideWidth',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeldConfigurationId = 'WeldConfigurationId'
}

/** input type for updating data in table "WeldHeatUsage" */
export type WeldHeatUsage_Set_Input = {
  InsideWidth?: Maybe<Scalars['MeasurementValue']>;
  OutsideWidth?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type WeldHeatUsage_Stddev_Fields = {
  __typename?: 'WeldHeatUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WeldHeatUsage" */
export type WeldHeatUsage_Stddev_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type WeldHeatUsage_Stddev_Pop_Fields = {
  __typename?: 'WeldHeatUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WeldHeatUsage" */
export type WeldHeatUsage_Stddev_Pop_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type WeldHeatUsage_Stddev_Samp_Fields = {
  __typename?: 'WeldHeatUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WeldHeatUsage" */
export type WeldHeatUsage_Stddev_Samp_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type WeldHeatUsage_Sum_Fields = {
  __typename?: 'WeldHeatUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "WeldHeatUsage" */
export type WeldHeatUsage_Sum_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** update columns of table "WeldHeatUsage" */
export enum WeldHeatUsage_Update_Column {
  /** column name */
  InsideWidth = 'InsideWidth',
  /** column name */
  OutsideWidth = 'OutsideWidth',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeldConfigurationId = 'WeldConfigurationId'
}

/** aggregate var_pop on columns */
export type WeldHeatUsage_Var_Pop_Fields = {
  __typename?: 'WeldHeatUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WeldHeatUsage" */
export type WeldHeatUsage_Var_Pop_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type WeldHeatUsage_Var_Samp_Fields = {
  __typename?: 'WeldHeatUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WeldHeatUsage" */
export type WeldHeatUsage_Var_Samp_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type WeldHeatUsage_Variance_Fields = {
  __typename?: 'WeldHeatUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WeldHeatUsage" */
export type WeldHeatUsage_Variance_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** columns and relationships of "WeldSolventUsage" */
export type WeldSolventUsage = {
  __typename?: 'WeldSolventUsage';
  /** An object relationship */
  C_WeldConfiguration: C_WeldConfiguration;
  InsideWidth: Scalars['MeasurementValue'];
  OutsideWidth: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
  WeldConfigurationId: Scalars['Int'];
};

/** aggregated selection of "WeldSolventUsage" */
export type WeldSolventUsage_Aggregate = {
  __typename?: 'WeldSolventUsage_aggregate';
  aggregate?: Maybe<WeldSolventUsage_Aggregate_Fields>;
  nodes: Array<WeldSolventUsage>;
};

/** aggregate fields of "WeldSolventUsage" */
export type WeldSolventUsage_Aggregate_Fields = {
  __typename?: 'WeldSolventUsage_aggregate_fields';
  avg?: Maybe<WeldSolventUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<WeldSolventUsage_Max_Fields>;
  min?: Maybe<WeldSolventUsage_Min_Fields>;
  stddev?: Maybe<WeldSolventUsage_Stddev_Fields>;
  stddev_pop?: Maybe<WeldSolventUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<WeldSolventUsage_Stddev_Samp_Fields>;
  sum?: Maybe<WeldSolventUsage_Sum_Fields>;
  var_pop?: Maybe<WeldSolventUsage_Var_Pop_Fields>;
  var_samp?: Maybe<WeldSolventUsage_Var_Samp_Fields>;
  variance?: Maybe<WeldSolventUsage_Variance_Fields>;
};


/** aggregate fields of "WeldSolventUsage" */
export type WeldSolventUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<WeldSolventUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WeldSolventUsage" */
export type WeldSolventUsage_Aggregate_Order_By = {
  avg?: Maybe<WeldSolventUsage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<WeldSolventUsage_Max_Order_By>;
  min?: Maybe<WeldSolventUsage_Min_Order_By>;
  stddev?: Maybe<WeldSolventUsage_Stddev_Order_By>;
  stddev_pop?: Maybe<WeldSolventUsage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<WeldSolventUsage_Stddev_Samp_Order_By>;
  sum?: Maybe<WeldSolventUsage_Sum_Order_By>;
  var_pop?: Maybe<WeldSolventUsage_Var_Pop_Order_By>;
  var_samp?: Maybe<WeldSolventUsage_Var_Samp_Order_By>;
  variance?: Maybe<WeldSolventUsage_Variance_Order_By>;
};

/** aggregate avg on columns */
export type WeldSolventUsage_Avg_Fields = {
  __typename?: 'WeldSolventUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WeldSolventUsage" */
export type WeldSolventUsage_Avg_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WeldSolventUsage". All fields are combined with a logical 'AND'. */
export type WeldSolventUsage_Bool_Exp = {
  C_WeldConfiguration?: Maybe<C_WeldConfiguration_Bool_Exp>;
  InsideWidth?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  OutsideWidth?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  WeldConfigurationId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<WeldSolventUsage_Bool_Exp>>;
  _not?: Maybe<WeldSolventUsage_Bool_Exp>;
  _or?: Maybe<Array<WeldSolventUsage_Bool_Exp>>;
};

/** upsert condition type for table "WeldSolventUsage" */
export type WeldSolventUsage_If_Matched = {
  match_columns?: Array<WeldSolventUsage_Insert_Match_Column>;
  update_columns?: Array<WeldSolventUsage_Update_Column>;
  where?: Maybe<WeldSolventUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "WeldSolventUsage" */
export type WeldSolventUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "WeldSolventUsage" */
export type WeldSolventUsage_Insert_Input = {
  InsideWidth?: Maybe<Scalars['MeasurementValue']>;
  OutsideWidth?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "WeldSolventUsage" */
export enum WeldSolventUsage_Insert_Match_Column {
  /** column name */
  InsideWidth = 'InsideWidth',
  /** column name */
  OutsideWidth = 'OutsideWidth',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeldConfigurationId = 'WeldConfigurationId'
}

/** aggregate max on columns */
export type WeldSolventUsage_Max_Fields = {
  __typename?: 'WeldSolventUsage_max_fields';
  InsideWidth?: Maybe<Scalars['MeasurementValue']>;
  OutsideWidth?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "WeldSolventUsage" */
export type WeldSolventUsage_Max_Order_By = {
  InsideWidth?: Maybe<Order_By>;
  OutsideWidth?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type WeldSolventUsage_Min_Fields = {
  __typename?: 'WeldSolventUsage_min_fields';
  InsideWidth?: Maybe<Scalars['MeasurementValue']>;
  OutsideWidth?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "WeldSolventUsage" */
export type WeldSolventUsage_Min_Order_By = {
  InsideWidth?: Maybe<Order_By>;
  OutsideWidth?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** response of any mutation on the table "WeldSolventUsage" */
export type WeldSolventUsage_Mutation_Response = {
  __typename?: 'WeldSolventUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WeldSolventUsage>;
};

/** Ordering options when selecting data from "WeldSolventUsage". */
export type WeldSolventUsage_Order_By = {
  C_WeldConfiguration?: Maybe<C_WeldConfiguration_Order_By>;
  InsideWidth?: Maybe<Order_By>;
  OutsideWidth?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** primary key columns input for table: WeldSolventUsage */
export type WeldSolventUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "WeldSolventUsage" */
export enum WeldSolventUsage_Select_Column {
  /** column name */
  InsideWidth = 'InsideWidth',
  /** column name */
  OutsideWidth = 'OutsideWidth',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeldConfigurationId = 'WeldConfigurationId'
}

/** input type for updating data in table "WeldSolventUsage" */
export type WeldSolventUsage_Set_Input = {
  InsideWidth?: Maybe<Scalars['MeasurementValue']>;
  OutsideWidth?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type WeldSolventUsage_Stddev_Fields = {
  __typename?: 'WeldSolventUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WeldSolventUsage" */
export type WeldSolventUsage_Stddev_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type WeldSolventUsage_Stddev_Pop_Fields = {
  __typename?: 'WeldSolventUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WeldSolventUsage" */
export type WeldSolventUsage_Stddev_Pop_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type WeldSolventUsage_Stddev_Samp_Fields = {
  __typename?: 'WeldSolventUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WeldSolventUsage" */
export type WeldSolventUsage_Stddev_Samp_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type WeldSolventUsage_Sum_Fields = {
  __typename?: 'WeldSolventUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
  WeldConfigurationId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "WeldSolventUsage" */
export type WeldSolventUsage_Sum_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** update columns of table "WeldSolventUsage" */
export enum WeldSolventUsage_Update_Column {
  /** column name */
  InsideWidth = 'InsideWidth',
  /** column name */
  OutsideWidth = 'OutsideWidth',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeldConfigurationId = 'WeldConfigurationId'
}

/** aggregate var_pop on columns */
export type WeldSolventUsage_Var_Pop_Fields = {
  __typename?: 'WeldSolventUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WeldSolventUsage" */
export type WeldSolventUsage_Var_Pop_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type WeldSolventUsage_Var_Samp_Fields = {
  __typename?: 'WeldSolventUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WeldSolventUsage" */
export type WeldSolventUsage_Var_Samp_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type WeldSolventUsage_Variance_Fields = {
  __typename?: 'WeldSolventUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
  WeldConfigurationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WeldSolventUsage" */
export type WeldSolventUsage_Variance_Order_By = {
  UsageId?: Maybe<Order_By>;
  WeldConfigurationId?: Maybe<Order_By>;
};

/** columns and relationships of "WeldSystemUsage" */
export type WeldSystemUsage = {
  __typename?: 'WeldSystemUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "WeldSystemUsage" */
export type WeldSystemUsage_Aggregate = {
  __typename?: 'WeldSystemUsage_aggregate';
  aggregate?: Maybe<WeldSystemUsage_Aggregate_Fields>;
  nodes: Array<WeldSystemUsage>;
};

/** aggregate fields of "WeldSystemUsage" */
export type WeldSystemUsage_Aggregate_Fields = {
  __typename?: 'WeldSystemUsage_aggregate_fields';
  avg?: Maybe<WeldSystemUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<WeldSystemUsage_Max_Fields>;
  min?: Maybe<WeldSystemUsage_Min_Fields>;
  stddev?: Maybe<WeldSystemUsage_Stddev_Fields>;
  stddev_pop?: Maybe<WeldSystemUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<WeldSystemUsage_Stddev_Samp_Fields>;
  sum?: Maybe<WeldSystemUsage_Sum_Fields>;
  var_pop?: Maybe<WeldSystemUsage_Var_Pop_Fields>;
  var_samp?: Maybe<WeldSystemUsage_Var_Samp_Fields>;
  variance?: Maybe<WeldSystemUsage_Variance_Fields>;
};


/** aggregate fields of "WeldSystemUsage" */
export type WeldSystemUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<WeldSystemUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type WeldSystemUsage_Avg_Fields = {
  __typename?: 'WeldSystemUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WeldSystemUsage". All fields are combined with a logical 'AND'. */
export type WeldSystemUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<WeldSystemUsage_Bool_Exp>>;
  _not?: Maybe<WeldSystemUsage_Bool_Exp>;
  _or?: Maybe<Array<WeldSystemUsage_Bool_Exp>>;
};

/** upsert condition type for table "WeldSystemUsage" */
export type WeldSystemUsage_If_Matched = {
  match_columns?: Array<WeldSystemUsage_Insert_Match_Column>;
  update_columns?: Array<WeldSystemUsage_Update_Column>;
  where?: Maybe<WeldSystemUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "WeldSystemUsage" */
export type WeldSystemUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "WeldSystemUsage" */
export type WeldSystemUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "WeldSystemUsage" */
export enum WeldSystemUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type WeldSystemUsage_Max_Fields = {
  __typename?: 'WeldSystemUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type WeldSystemUsage_Min_Fields = {
  __typename?: 'WeldSystemUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "WeldSystemUsage" */
export type WeldSystemUsage_Mutation_Response = {
  __typename?: 'WeldSystemUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WeldSystemUsage>;
};

/** Ordering options when selecting data from "WeldSystemUsage". */
export type WeldSystemUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: WeldSystemUsage */
export type WeldSystemUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "WeldSystemUsage" */
export enum WeldSystemUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "WeldSystemUsage" */
export type WeldSystemUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type WeldSystemUsage_Stddev_Fields = {
  __typename?: 'WeldSystemUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type WeldSystemUsage_Stddev_Pop_Fields = {
  __typename?: 'WeldSystemUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type WeldSystemUsage_Stddev_Samp_Fields = {
  __typename?: 'WeldSystemUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type WeldSystemUsage_Sum_Fields = {
  __typename?: 'WeldSystemUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "WeldSystemUsage" */
export enum WeldSystemUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type WeldSystemUsage_Var_Pop_Fields = {
  __typename?: 'WeldSystemUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type WeldSystemUsage_Var_Samp_Fields = {
  __typename?: 'WeldSystemUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type WeldSystemUsage_Variance_Fields = {
  __typename?: 'WeldSystemUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WeldTorchedUsage" */
export type WeldTorchedUsage = {
  __typename?: 'WeldTorchedUsage';
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "WeldTorchedUsage" */
export type WeldTorchedUsage_Aggregate = {
  __typename?: 'WeldTorchedUsage_aggregate';
  aggregate?: Maybe<WeldTorchedUsage_Aggregate_Fields>;
  nodes: Array<WeldTorchedUsage>;
};

/** aggregate fields of "WeldTorchedUsage" */
export type WeldTorchedUsage_Aggregate_Fields = {
  __typename?: 'WeldTorchedUsage_aggregate_fields';
  avg?: Maybe<WeldTorchedUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<WeldTorchedUsage_Max_Fields>;
  min?: Maybe<WeldTorchedUsage_Min_Fields>;
  stddev?: Maybe<WeldTorchedUsage_Stddev_Fields>;
  stddev_pop?: Maybe<WeldTorchedUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<WeldTorchedUsage_Stddev_Samp_Fields>;
  sum?: Maybe<WeldTorchedUsage_Sum_Fields>;
  var_pop?: Maybe<WeldTorchedUsage_Var_Pop_Fields>;
  var_samp?: Maybe<WeldTorchedUsage_Var_Samp_Fields>;
  variance?: Maybe<WeldTorchedUsage_Variance_Fields>;
};


/** aggregate fields of "WeldTorchedUsage" */
export type WeldTorchedUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<WeldTorchedUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type WeldTorchedUsage_Avg_Fields = {
  __typename?: 'WeldTorchedUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WeldTorchedUsage". All fields are combined with a logical 'AND'. */
export type WeldTorchedUsage_Bool_Exp = {
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<WeldTorchedUsage_Bool_Exp>>;
  _not?: Maybe<WeldTorchedUsage_Bool_Exp>;
  _or?: Maybe<Array<WeldTorchedUsage_Bool_Exp>>;
};

/** upsert condition type for table "WeldTorchedUsage" */
export type WeldTorchedUsage_If_Matched = {
  match_columns?: Array<WeldTorchedUsage_Insert_Match_Column>;
  update_columns?: Array<WeldTorchedUsage_Update_Column>;
  where?: Maybe<WeldTorchedUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "WeldTorchedUsage" */
export type WeldTorchedUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "WeldTorchedUsage" */
export type WeldTorchedUsage_Insert_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "WeldTorchedUsage" */
export enum WeldTorchedUsage_Insert_Match_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type WeldTorchedUsage_Max_Fields = {
  __typename?: 'WeldTorchedUsage_max_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type WeldTorchedUsage_Min_Fields = {
  __typename?: 'WeldTorchedUsage_min_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "WeldTorchedUsage" */
export type WeldTorchedUsage_Mutation_Response = {
  __typename?: 'WeldTorchedUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WeldTorchedUsage>;
};

/** Ordering options when selecting data from "WeldTorchedUsage". */
export type WeldTorchedUsage_Order_By = {
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: WeldTorchedUsage */
export type WeldTorchedUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "WeldTorchedUsage" */
export enum WeldTorchedUsage_Select_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "WeldTorchedUsage" */
export type WeldTorchedUsage_Set_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type WeldTorchedUsage_Stddev_Fields = {
  __typename?: 'WeldTorchedUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type WeldTorchedUsage_Stddev_Pop_Fields = {
  __typename?: 'WeldTorchedUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type WeldTorchedUsage_Stddev_Samp_Fields = {
  __typename?: 'WeldTorchedUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type WeldTorchedUsage_Sum_Fields = {
  __typename?: 'WeldTorchedUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "WeldTorchedUsage" */
export enum WeldTorchedUsage_Update_Column {
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type WeldTorchedUsage_Var_Pop_Fields = {
  __typename?: 'WeldTorchedUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type WeldTorchedUsage_Var_Samp_Fields = {
  __typename?: 'WeldTorchedUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type WeldTorchedUsage_Variance_Fields = {
  __typename?: 'WeldTorchedUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "WoodDeck" */
export type WoodDeck = {
  __typename?: 'WoodDeck';
  /** An object relationship */
  C_WoodSpecy: C_WoodSpecies;
  /** An object relationship */
  C_WoodType: C_WoodType;
  ComponentId: Scalars['Int'];
  /** An object relationship */
  ManufacturedProduct: ManufacturedProduct;
  SpanMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  WoodSpeciesId: Scalars['Int'];
  WoodTypeId: Scalars['Int'];
};

/** columns and relationships of "WoodDeckUsage" */
export type WoodDeckUsage = {
  __typename?: 'WoodDeckUsage';
  SpanMax: Scalars['MeasurementValue'];
  ThicknessMin: Scalars['MeasurementValue'];
  /** An object relationship */
  Usage: Usage;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "WoodDeckUsage" */
export type WoodDeckUsage_Aggregate = {
  __typename?: 'WoodDeckUsage_aggregate';
  aggregate?: Maybe<WoodDeckUsage_Aggregate_Fields>;
  nodes: Array<WoodDeckUsage>;
};

/** aggregate fields of "WoodDeckUsage" */
export type WoodDeckUsage_Aggregate_Fields = {
  __typename?: 'WoodDeckUsage_aggregate_fields';
  avg?: Maybe<WoodDeckUsage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<WoodDeckUsage_Max_Fields>;
  min?: Maybe<WoodDeckUsage_Min_Fields>;
  stddev?: Maybe<WoodDeckUsage_Stddev_Fields>;
  stddev_pop?: Maybe<WoodDeckUsage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<WoodDeckUsage_Stddev_Samp_Fields>;
  sum?: Maybe<WoodDeckUsage_Sum_Fields>;
  var_pop?: Maybe<WoodDeckUsage_Var_Pop_Fields>;
  var_samp?: Maybe<WoodDeckUsage_Var_Samp_Fields>;
  variance?: Maybe<WoodDeckUsage_Variance_Fields>;
};


/** aggregate fields of "WoodDeckUsage" */
export type WoodDeckUsage_Aggregate_FieldsCountArgs = {
  column?: Maybe<WoodDeckUsage_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type WoodDeckUsage_Avg_Fields = {
  __typename?: 'WoodDeckUsage_avg_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "WoodDeckUsage". All fields are combined with a logical 'AND'. */
export type WoodDeckUsage_Bool_Exp = {
  SpanMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  Usage?: Maybe<Usage_Bool_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<WoodDeckUsage_Bool_Exp>>;
  _not?: Maybe<WoodDeckUsage_Bool_Exp>;
  _or?: Maybe<Array<WoodDeckUsage_Bool_Exp>>;
};

/** upsert condition type for table "WoodDeckUsage" */
export type WoodDeckUsage_If_Matched = {
  match_columns?: Array<WoodDeckUsage_Insert_Match_Column>;
  update_columns?: Array<WoodDeckUsage_Update_Column>;
  where?: Maybe<WoodDeckUsage_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "WoodDeckUsage" */
export type WoodDeckUsage_Inc_Input = {
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "WoodDeckUsage" */
export type WoodDeckUsage_Insert_Input = {
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "WoodDeckUsage" */
export enum WoodDeckUsage_Insert_Match_Column {
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type WoodDeckUsage_Max_Fields = {
  __typename?: 'WoodDeckUsage_max_fields';
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type WoodDeckUsage_Min_Fields = {
  __typename?: 'WoodDeckUsage_min_fields';
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "WoodDeckUsage" */
export type WoodDeckUsage_Mutation_Response = {
  __typename?: 'WoodDeckUsage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WoodDeckUsage>;
};

/** Ordering options when selecting data from "WoodDeckUsage". */
export type WoodDeckUsage_Order_By = {
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  Usage?: Maybe<Usage_Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** primary key columns input for table: WoodDeckUsage */
export type WoodDeckUsage_Pk_Columns_Input = {
  UsageId: Scalars['Int'];
};

/** select columns of table "WoodDeckUsage" */
export enum WoodDeckUsage_Select_Column {
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "WoodDeckUsage" */
export type WoodDeckUsage_Set_Input = {
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type WoodDeckUsage_Stddev_Fields = {
  __typename?: 'WoodDeckUsage_stddev_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type WoodDeckUsage_Stddev_Pop_Fields = {
  __typename?: 'WoodDeckUsage_stddev_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type WoodDeckUsage_Stddev_Samp_Fields = {
  __typename?: 'WoodDeckUsage_stddev_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type WoodDeckUsage_Sum_Fields = {
  __typename?: 'WoodDeckUsage_sum_fields';
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "WoodDeckUsage" */
export enum WoodDeckUsage_Update_Column {
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type WoodDeckUsage_Var_Pop_Fields = {
  __typename?: 'WoodDeckUsage_var_pop_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type WoodDeckUsage_Var_Samp_Fields = {
  __typename?: 'WoodDeckUsage_var_samp_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type WoodDeckUsage_Variance_Fields = {
  __typename?: 'WoodDeckUsage_variance_fields';
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "WoodDeck" */
export type WoodDeck_Aggregate = {
  __typename?: 'WoodDeck_aggregate';
  aggregate?: Maybe<WoodDeck_Aggregate_Fields>;
  nodes: Array<WoodDeck>;
};

/** aggregate fields of "WoodDeck" */
export type WoodDeck_Aggregate_Fields = {
  __typename?: 'WoodDeck_aggregate_fields';
  avg?: Maybe<WoodDeck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<WoodDeck_Max_Fields>;
  min?: Maybe<WoodDeck_Min_Fields>;
  stddev?: Maybe<WoodDeck_Stddev_Fields>;
  stddev_pop?: Maybe<WoodDeck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<WoodDeck_Stddev_Samp_Fields>;
  sum?: Maybe<WoodDeck_Sum_Fields>;
  var_pop?: Maybe<WoodDeck_Var_Pop_Fields>;
  var_samp?: Maybe<WoodDeck_Var_Samp_Fields>;
  variance?: Maybe<WoodDeck_Variance_Fields>;
};


/** aggregate fields of "WoodDeck" */
export type WoodDeck_Aggregate_FieldsCountArgs = {
  column?: Maybe<WoodDeck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "WoodDeck" */
export type WoodDeck_Aggregate_Order_By = {
  avg?: Maybe<WoodDeck_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<WoodDeck_Max_Order_By>;
  min?: Maybe<WoodDeck_Min_Order_By>;
  stddev?: Maybe<WoodDeck_Stddev_Order_By>;
  stddev_pop?: Maybe<WoodDeck_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<WoodDeck_Stddev_Samp_Order_By>;
  sum?: Maybe<WoodDeck_Sum_Order_By>;
  var_pop?: Maybe<WoodDeck_Var_Pop_Order_By>;
  var_samp?: Maybe<WoodDeck_Var_Samp_Order_By>;
  variance?: Maybe<WoodDeck_Variance_Order_By>;
};

/** aggregate avg on columns */
export type WoodDeck_Avg_Fields = {
  __typename?: 'WoodDeck_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  WoodSpeciesId?: Maybe<Scalars['Float']>;
  WoodTypeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "WoodDeck" */
export type WoodDeck_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  WoodSpeciesId?: Maybe<Order_By>;
  WoodTypeId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "WoodDeck". All fields are combined with a logical 'AND'. */
export type WoodDeck_Bool_Exp = {
  C_WoodSpecy?: Maybe<C_WoodSpecies_Bool_Exp>;
  C_WoodType?: Maybe<C_WoodType_Bool_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Bool_Exp>;
  SpanMax?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<MeasurementValue_Mssql_Comparison_Exp>;
  WoodSpeciesId?: Maybe<Int_Mssql_Comparison_Exp>;
  WoodTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<WoodDeck_Bool_Exp>>;
  _not?: Maybe<WoodDeck_Bool_Exp>;
  _or?: Maybe<Array<WoodDeck_Bool_Exp>>;
};

/** upsert condition type for table "WoodDeck" */
export type WoodDeck_If_Matched = {
  match_columns?: Array<WoodDeck_Insert_Match_Column>;
  update_columns?: Array<WoodDeck_Update_Column>;
  where?: Maybe<WoodDeck_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "WoodDeck" */
export type WoodDeck_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  WoodSpeciesId?: Maybe<Scalars['Int']>;
  WoodTypeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "WoodDeck" */
export type WoodDeck_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  WoodSpeciesId?: Maybe<Scalars['Int']>;
  WoodTypeId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "WoodDeck" */
export enum WoodDeck_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  WoodSpeciesId = 'WoodSpeciesId',
  /** column name */
  WoodTypeId = 'WoodTypeId'
}

/** aggregate max on columns */
export type WoodDeck_Max_Fields = {
  __typename?: 'WoodDeck_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  WoodSpeciesId?: Maybe<Scalars['Int']>;
  WoodTypeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "WoodDeck" */
export type WoodDeck_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  WoodSpeciesId?: Maybe<Order_By>;
  WoodTypeId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type WoodDeck_Min_Fields = {
  __typename?: 'WoodDeck_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  WoodSpeciesId?: Maybe<Scalars['Int']>;
  WoodTypeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "WoodDeck" */
export type WoodDeck_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  WoodSpeciesId?: Maybe<Order_By>;
  WoodTypeId?: Maybe<Order_By>;
};

/** response of any mutation on the table "WoodDeck" */
export type WoodDeck_Mutation_Response = {
  __typename?: 'WoodDeck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WoodDeck>;
};

/** Ordering options when selecting data from "WoodDeck". */
export type WoodDeck_Order_By = {
  C_WoodSpecy?: Maybe<C_WoodSpecies_Order_By>;
  C_WoodType?: Maybe<C_WoodType_Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturedProduct?: Maybe<ManufacturedProduct_Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  WoodSpeciesId?: Maybe<Order_By>;
  WoodTypeId?: Maybe<Order_By>;
};

/** primary key columns input for table: WoodDeck */
export type WoodDeck_Pk_Columns_Input = {
  ComponentId: Scalars['Int'];
};

/** select columns of table "WoodDeck" */
export enum WoodDeck_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  WoodSpeciesId = 'WoodSpeciesId',
  /** column name */
  WoodTypeId = 'WoodTypeId'
}

/** input type for updating data in table "WoodDeck" */
export type WoodDeck_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['MeasurementValue']>;
  ThicknessMin?: Maybe<Scalars['MeasurementValue']>;
  WoodSpeciesId?: Maybe<Scalars['Int']>;
  WoodTypeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type WoodDeck_Stddev_Fields = {
  __typename?: 'WoodDeck_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  WoodSpeciesId?: Maybe<Scalars['Float']>;
  WoodTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "WoodDeck" */
export type WoodDeck_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  WoodSpeciesId?: Maybe<Order_By>;
  WoodTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type WoodDeck_Stddev_Pop_Fields = {
  __typename?: 'WoodDeck_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  WoodSpeciesId?: Maybe<Scalars['Float']>;
  WoodTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "WoodDeck" */
export type WoodDeck_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  WoodSpeciesId?: Maybe<Order_By>;
  WoodTypeId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type WoodDeck_Stddev_Samp_Fields = {
  __typename?: 'WoodDeck_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  WoodSpeciesId?: Maybe<Scalars['Float']>;
  WoodTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "WoodDeck" */
export type WoodDeck_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  WoodSpeciesId?: Maybe<Order_By>;
  WoodTypeId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type WoodDeck_Sum_Fields = {
  __typename?: 'WoodDeck_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  WoodSpeciesId?: Maybe<Scalars['Int']>;
  WoodTypeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "WoodDeck" */
export type WoodDeck_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  WoodSpeciesId?: Maybe<Order_By>;
  WoodTypeId?: Maybe<Order_By>;
};

/** update columns of table "WoodDeck" */
export enum WoodDeck_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  WoodSpeciesId = 'WoodSpeciesId',
  /** column name */
  WoodTypeId = 'WoodTypeId'
}

/** aggregate var_pop on columns */
export type WoodDeck_Var_Pop_Fields = {
  __typename?: 'WoodDeck_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  WoodSpeciesId?: Maybe<Scalars['Float']>;
  WoodTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "WoodDeck" */
export type WoodDeck_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  WoodSpeciesId?: Maybe<Order_By>;
  WoodTypeId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type WoodDeck_Var_Samp_Fields = {
  __typename?: 'WoodDeck_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  WoodSpeciesId?: Maybe<Scalars['Float']>;
  WoodTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "WoodDeck" */
export type WoodDeck_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  WoodSpeciesId?: Maybe<Order_By>;
  WoodTypeId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type WoodDeck_Variance_Fields = {
  __typename?: 'WoodDeck_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  WoodSpeciesId?: Maybe<Scalars['Float']>;
  WoodTypeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "WoodDeck" */
export type WoodDeck_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  WoodSpeciesId?: Maybe<Order_By>;
  WoodTypeId?: Maybe<Order_By>;
};

export type AttributeSearchCriteria = {
  attributeName?: Maybe<Scalars['String']>;
  attributeType?: Maybe<Scalars['Int']>;
  attributeValue?: Maybe<Scalars['String']>;
  comparisonOperator?: Maybe<Scalars['Int']>;
};


/** Boolean expression to compare columns of type "datetime". All fields are combined with logical 'AND'. */
export type Datetime_Mssql_Comparison_Exp = {
  _eq?: Maybe<Scalars['datetime']>;
  _gt?: Maybe<Scalars['datetime']>;
  _gte?: Maybe<Scalars['datetime']>;
  _in?: Maybe<Array<Scalars['datetime']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['datetime']>;
  _lte?: Maybe<Scalars['datetime']>;
  _neq?: Maybe<Scalars['datetime']>;
  _nin?: Maybe<Array<Scalars['datetime']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "Address" */
  delete_Address?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "Address" */
  delete_Address_by_pk?: Maybe<Address>;
  /** delete data from the table: "AdheredSinglePlyOrphanReport" */
  delete_AdheredSinglePlyOrphanReport?: Maybe<AdheredSinglePlyOrphanReport_Mutation_Response>;
  /** delete single row from the table: "AdheredSinglePlyOrphanReport" */
  delete_AdheredSinglePlyOrphanReport_by_pk?: Maybe<AdheredSinglePlyOrphanReport>;
  /** delete data from the table: "AdhesiveFullApplication" */
  delete_AdhesiveFullApplication?: Maybe<AdhesiveFullApplication_Mutation_Response>;
  /** delete data from the table: "AdhesiveFullApplicationUsage" */
  delete_AdhesiveFullApplicationUsage?: Maybe<AdhesiveFullApplicationUsage_Mutation_Response>;
  /** delete single row from the table: "AdhesiveFullApplicationUsage" */
  delete_AdhesiveFullApplicationUsage_by_pk?: Maybe<AdhesiveFullApplicationUsage>;
  /** delete single row from the table: "AdhesiveFullApplication" */
  delete_AdhesiveFullApplication_by_pk?: Maybe<AdhesiveFullApplication>;
  /** delete data from the table: "AdhesiveRibbonUsage" */
  delete_AdhesiveRibbonUsage?: Maybe<AdhesiveRibbonUsage_Mutation_Response>;
  /** delete single row from the table: "AdhesiveRibbonUsage" */
  delete_AdhesiveRibbonUsage_by_pk?: Maybe<AdhesiveRibbonUsage>;
  /** delete data from the table: "AdhesiveRibbons" */
  delete_AdhesiveRibbons?: Maybe<AdhesiveRibbons_Mutation_Response>;
  /** delete single row from the table: "AdhesiveRibbons" */
  delete_AdhesiveRibbons_by_pk?: Maybe<AdhesiveRibbons>;
  /** delete data from the table: "AdhesiveSpots" */
  delete_AdhesiveSpots?: Maybe<AdhesiveSpots_Mutation_Response>;
  /** delete data from the table: "AdhesiveSpotsUsage" */
  delete_AdhesiveSpotsUsage?: Maybe<AdhesiveSpotsUsage_Mutation_Response>;
  /** delete single row from the table: "AdhesiveSpotsUsage" */
  delete_AdhesiveSpotsUsage_by_pk?: Maybe<AdhesiveSpotsUsage>;
  /** delete single row from the table: "AdhesiveSpots" */
  delete_AdhesiveSpots_by_pk?: Maybe<AdhesiveSpots>;
  /** delete data from the table: "AdhesiveSystemUsage" */
  delete_AdhesiveSystemUsage?: Maybe<AdhesiveSystemUsage_Mutation_Response>;
  /** delete single row from the table: "AdhesiveSystemUsage" */
  delete_AdhesiveSystemUsage_by_pk?: Maybe<AdhesiveSystemUsage>;
  /** delete data from the table: "AirRetarder" */
  delete_AirRetarder?: Maybe<AirRetarder_Mutation_Response>;
  /** delete data from the table: "AirRetarderUsage" */
  delete_AirRetarderUsage?: Maybe<AirRetarderUsage_Mutation_Response>;
  /** delete single row from the table: "AirRetarderUsage" */
  delete_AirRetarderUsage_by_pk?: Maybe<AirRetarderUsage>;
  /** delete single row from the table: "AirRetarder" */
  delete_AirRetarder_by_pk?: Maybe<AirRetarder>;
  /** delete data from the table: "AllManufacturedProducts" */
  delete_AllManufacturedProducts?: Maybe<AllManufacturedProducts_Mutation_Response>;
  /** delete data from the table: "Anchor" */
  delete_Anchor?: Maybe<Anchor_Mutation_Response>;
  /** delete single row from the table: "Anchor" */
  delete_Anchor_by_pk?: Maybe<Anchor>;
  /** delete data from the table: "ApprovalsDoc" */
  delete_ApprovalsDoc?: Maybe<ApprovalsDoc_Mutation_Response>;
  /** delete single row from the table: "ApprovalsDoc" */
  delete_ApprovalsDoc_by_pk?: Maybe<ApprovalsDoc>;
  /** delete data from the table: "ApprovedLayerUse" */
  delete_ApprovedLayerUse?: Maybe<ApprovedLayerUse_Mutation_Response>;
  /** delete single row from the table: "ApprovedLayerUse" */
  delete_ApprovedLayerUse_by_pk?: Maybe<ApprovedLayerUse>;
  /** delete data from the table: "AssemblyReconfigQueue" */
  delete_AssemblyReconfigQueue?: Maybe<AssemblyReconfigQueue_Mutation_Response>;
  /** delete single row from the table: "AssemblyReconfigQueue" */
  delete_AssemblyReconfigQueue_by_pk?: Maybe<AssemblyReconfigQueue>;
  /** delete data from the table: "BaseCoat" */
  delete_BaseCoat?: Maybe<BaseCoat_Mutation_Response>;
  /** delete data from the table: "BaseCoatUsage" */
  delete_BaseCoatUsage?: Maybe<BaseCoatUsage_Mutation_Response>;
  /** delete single row from the table: "BaseCoatUsage" */
  delete_BaseCoatUsage_by_pk?: Maybe<BaseCoatUsage>;
  /** delete single row from the table: "BaseCoat" */
  delete_BaseCoat_by_pk?: Maybe<BaseCoat>;
  /** delete data from the table: "BasePly" */
  delete_BasePly?: Maybe<BasePly_Mutation_Response>;
  /** delete data from the table: "BasePlyUsage" */
  delete_BasePlyUsage?: Maybe<BasePlyUsage_Mutation_Response>;
  /** delete single row from the table: "BasePlyUsage" */
  delete_BasePlyUsage_by_pk?: Maybe<BasePlyUsage>;
  /** delete single row from the table: "BasePly" */
  delete_BasePly_by_pk?: Maybe<BasePly>;
  /** delete data from the table: "BaseSheet" */
  delete_BaseSheet?: Maybe<BaseSheet_Mutation_Response>;
  /** delete data from the table: "BaseSheetUsage" */
  delete_BaseSheetUsage?: Maybe<BaseSheetUsage_Mutation_Response>;
  /** delete single row from the table: "BaseSheetUsage" */
  delete_BaseSheetUsage_by_pk?: Maybe<BaseSheetUsage>;
  /** delete single row from the table: "BaseSheet" */
  delete_BaseSheet_by_pk?: Maybe<BaseSheet>;
  /** delete data from the table: "BattBlanketInsulation" */
  delete_BattBlanketInsulation?: Maybe<BattBlanketInsulation_Mutation_Response>;
  /** delete single row from the table: "BattBlanketInsulation" */
  delete_BattBlanketInsulation_by_pk?: Maybe<BattBlanketInsulation>;
  /** delete data from the table: "BattBlanketUsage" */
  delete_BattBlanketUsage?: Maybe<BattBlanketUsage_Mutation_Response>;
  /** delete single row from the table: "BattBlanketUsage" */
  delete_BattBlanketUsage_by_pk?: Maybe<BattBlanketUsage>;
  /** delete data from the table: "BattenBar" */
  delete_BattenBar?: Maybe<BattenBar_Mutation_Response>;
  /** delete data from the table: "BattenBarUsage" */
  delete_BattenBarUsage?: Maybe<BattenBarUsage_Mutation_Response>;
  /** delete single row from the table: "BattenBarUsage" */
  delete_BattenBarUsage_by_pk?: Maybe<BattenBarUsage>;
  /** delete single row from the table: "BattenBar" */
  delete_BattenBar_by_pk?: Maybe<BattenBar>;
  /** delete data from the table: "BoardSizes" */
  delete_BoardSizes?: Maybe<BoardSizes_Mutation_Response>;
  /** delete data from the table: "BoardStock" */
  delete_BoardStock?: Maybe<BoardStock_Mutation_Response>;
  /** delete data from the table: "BoardStockBoardSizes" */
  delete_BoardStockBoardSizes?: Maybe<BoardStockBoardSizes_Mutation_Response>;
  /** delete single row from the table: "BoardStockBoardSizes" */
  delete_BoardStockBoardSizes_by_pk?: Maybe<BoardStockBoardSizes>;
  /** delete data from the table: "BoardStockFacerBottom" */
  delete_BoardStockFacerBottom?: Maybe<BoardStockFacerBottom_Mutation_Response>;
  /** delete single row from the table: "BoardStockFacerBottom" */
  delete_BoardStockFacerBottom_by_pk?: Maybe<BoardStockFacerBottom>;
  /** delete data from the table: "BoardStockFacerTop" */
  delete_BoardStockFacerTop?: Maybe<BoardStockFacerTop_Mutation_Response>;
  /** delete single row from the table: "BoardStockFacerTop" */
  delete_BoardStockFacerTop_by_pk?: Maybe<BoardStockFacerTop>;
  /** delete data from the table: "BoardStockUsage" */
  delete_BoardStockUsage?: Maybe<BoardStockUsage_Mutation_Response>;
  /** delete single row from the table: "BoardStockUsage" */
  delete_BoardStockUsage_by_pk?: Maybe<BoardStockUsage>;
  /** delete single row from the table: "BoardStock" */
  delete_BoardStock_by_pk?: Maybe<BoardStock>;
  /** delete data from the table: "BroadcastAlert" */
  delete_BroadcastAlert?: Maybe<BroadcastAlert_Mutation_Response>;
  /** delete single row from the table: "BroadcastAlert" */
  delete_BroadcastAlert_by_pk?: Maybe<BroadcastAlert>;
  /** delete data from the table: "BuiltupVaporRetarderSystemUsage" */
  delete_BuiltupVaporRetarderSystemUsage?: Maybe<BuiltupVaporRetarderSystemUsage_Mutation_Response>;
  /** delete single row from the table: "BuiltupVaporRetarderSystemUsage" */
  delete_BuiltupVaporRetarderSystemUsage_by_pk?: Maybe<BuiltupVaporRetarderSystemUsage>;
  /** delete data from the table: "C_Additive" */
  delete_C_Additive?: Maybe<C_Additive_Mutation_Response>;
  /** delete single row from the table: "C_Additive" */
  delete_C_Additive_by_pk?: Maybe<C_Additive>;
  /** delete data from the table: "C_AdhesiveApplicationMethod" */
  delete_C_AdhesiveApplicationMethod?: Maybe<C_AdhesiveApplicationMethod_Mutation_Response>;
  /** delete single row from the table: "C_AdhesiveApplicationMethod" */
  delete_C_AdhesiveApplicationMethod_by_pk?: Maybe<C_AdhesiveApplicationMethod>;
  /** delete data from the table: "C_AdhesiveMaterial" */
  delete_C_AdhesiveMaterial?: Maybe<C_AdhesiveMaterial_Mutation_Response>;
  /** delete single row from the table: "C_AdhesiveMaterial" */
  delete_C_AdhesiveMaterial_by_pk?: Maybe<C_AdhesiveMaterial>;
  /** delete data from the table: "C_AdhesiveType" */
  delete_C_AdhesiveType?: Maybe<C_AdhesiveType_Mutation_Response>;
  /** delete single row from the table: "C_AdhesiveType" */
  delete_C_AdhesiveType_by_pk?: Maybe<C_AdhesiveType>;
  /** delete data from the table: "C_Aggregate" */
  delete_C_Aggregate?: Maybe<C_Aggregate_Mutation_Response>;
  /** delete single row from the table: "C_Aggregate" */
  delete_C_Aggregate_by_pk?: Maybe<C_Aggregate>;
  /** delete data from the table: "C_ApprovalsDocCategory" */
  delete_C_ApprovalsDocCategory?: Maybe<C_ApprovalsDocCategory_Mutation_Response>;
  /** delete single row from the table: "C_ApprovalsDocCategory" */
  delete_C_ApprovalsDocCategory_by_pk?: Maybe<C_ApprovalsDocCategory>;
  /** delete data from the table: "C_ApprovedUse" */
  delete_C_ApprovedUse?: Maybe<C_ApprovedUse_Mutation_Response>;
  /** delete single row from the table: "C_ApprovedUse" */
  delete_C_ApprovedUse_by_pk?: Maybe<C_ApprovedUse>;
  /** delete data from the table: "C_AssemblyApplicationType" */
  delete_C_AssemblyApplicationType?: Maybe<C_AssemblyApplicationType_Mutation_Response>;
  /** delete single row from the table: "C_AssemblyApplicationType" */
  delete_C_AssemblyApplicationType_by_pk?: Maybe<C_AssemblyApplicationType>;
  /** delete data from the table: "C_AssemblyType" */
  delete_C_AssemblyType?: Maybe<C_AssemblyType_Mutation_Response>;
  /** delete single row from the table: "C_AssemblyType" */
  delete_C_AssemblyType_by_pk?: Maybe<C_AssemblyType>;
  /** delete data from the table: "C_BackingMaterial" */
  delete_C_BackingMaterial?: Maybe<C_BackingMaterial_Mutation_Response>;
  /** delete single row from the table: "C_BackingMaterial" */
  delete_C_BackingMaterial_by_pk?: Maybe<C_BackingMaterial>;
  /** delete data from the table: "C_BaseSheetApplicationMethod" */
  delete_C_BaseSheetApplicationMethod?: Maybe<C_BaseSheetApplicationMethod_Mutation_Response>;
  /** delete single row from the table: "C_BaseSheetApplicationMethod" */
  delete_C_BaseSheetApplicationMethod_by_pk?: Maybe<C_BaseSheetApplicationMethod>;
  /** delete data from the table: "C_BattBlanketFacerMaterial" */
  delete_C_BattBlanketFacerMaterial?: Maybe<C_BattBlanketFacerMaterial_Mutation_Response>;
  /** delete single row from the table: "C_BattBlanketFacerMaterial" */
  delete_C_BattBlanketFacerMaterial_by_pk?: Maybe<C_BattBlanketFacerMaterial>;
  /** delete data from the table: "C_BattBlanketMaterial" */
  delete_C_BattBlanketMaterial?: Maybe<C_BattBlanketMaterial_Mutation_Response>;
  /** delete single row from the table: "C_BattBlanketMaterial" */
  delete_C_BattBlanketMaterial_by_pk?: Maybe<C_BattBlanketMaterial>;
  /** delete data from the table: "C_BoardProfile" */
  delete_C_BoardProfile?: Maybe<C_BoardProfile_Mutation_Response>;
  /** delete single row from the table: "C_BoardProfile" */
  delete_C_BoardProfile_by_pk?: Maybe<C_BoardProfile>;
  /** delete data from the table: "C_BoardStockMaterial" */
  delete_C_BoardStockMaterial?: Maybe<C_BoardStockMaterial_Mutation_Response>;
  /** delete single row from the table: "C_BoardStockMaterial" */
  delete_C_BoardStockMaterial_by_pk?: Maybe<C_BoardStockMaterial>;
  /** delete data from the table: "C_BuildingEnclosure" */
  delete_C_BuildingEnclosure?: Maybe<C_BuildingEnclosure_Mutation_Response>;
  /** delete single row from the table: "C_BuildingEnclosure" */
  delete_C_BuildingEnclosure_by_pk?: Maybe<C_BuildingEnclosure>;
  /** delete data from the table: "C_CementAggregateRatio" */
  delete_C_CementAggregateRatio?: Maybe<C_CementAggregateRatio_Mutation_Response>;
  /** delete single row from the table: "C_CementAggregateRatio" */
  delete_C_CementAggregateRatio_by_pk?: Maybe<C_CementAggregateRatio>;
  /** delete data from the table: "C_CoatingMaterial" */
  delete_C_CoatingMaterial?: Maybe<C_CoatingMaterial_Mutation_Response>;
  /** delete single row from the table: "C_CoatingMaterial" */
  delete_C_CoatingMaterial_by_pk?: Maybe<C_CoatingMaterial>;
  /** delete data from the table: "C_ComponentType" */
  delete_C_ComponentType?: Maybe<C_ComponentType_Mutation_Response>;
  /** delete single row from the table: "C_ComponentType" */
  delete_C_ComponentType_by_pk?: Maybe<C_ComponentType>;
  /** delete data from the table: "C_ContactType" */
  delete_C_ContactType?: Maybe<C_ContactType_Mutation_Response>;
  /** delete single row from the table: "C_ContactType" */
  delete_C_ContactType_by_pk?: Maybe<C_ContactType>;
  /** delete data from the table: "C_Country" */
  delete_C_Country?: Maybe<C_Country_Mutation_Response>;
  /** delete single row from the table: "C_Country" */
  delete_C_Country_by_pk?: Maybe<C_Country>;
  /** delete data from the table: "C_CoverBoardMaterial" */
  delete_C_CoverBoardMaterial?: Maybe<C_CoverBoardMaterial_Mutation_Response>;
  /** delete single row from the table: "C_CoverBoardMaterial" */
  delete_C_CoverBoardMaterial_by_pk?: Maybe<C_CoverBoardMaterial>;
  /** delete data from the table: "C_CoverColor" */
  delete_C_CoverColor?: Maybe<C_CoverColor_Mutation_Response>;
  /** delete single row from the table: "C_CoverColor" */
  delete_C_CoverColor_by_pk?: Maybe<C_CoverColor>;
  /** delete data from the table: "C_CoverSecurementMethod" */
  delete_C_CoverSecurementMethod?: Maybe<C_CoverSecurementMethod_Mutation_Response>;
  /** delete single row from the table: "C_CoverSecurementMethod" */
  delete_C_CoverSecurementMethod_by_pk?: Maybe<C_CoverSecurementMethod>;
  /** delete data from the table: "C_DeckType" */
  delete_C_DeckType?: Maybe<C_DeckType_Mutation_Response>;
  /** delete single row from the table: "C_DeckType" */
  delete_C_DeckType_by_pk?: Maybe<C_DeckType>;
  /** delete data from the table: "C_ExistingRoofCover" */
  delete_C_ExistingRoofCover?: Maybe<C_ExistingRoofCover_Mutation_Response>;
  /** delete single row from the table: "C_ExistingRoofCover" */
  delete_C_ExistingRoofCover_by_pk?: Maybe<C_ExistingRoofCover>;
  /** delete data from the table: "C_ExistingRoofDeck" */
  delete_C_ExistingRoofDeck?: Maybe<C_ExistingRoofDeck_Mutation_Response>;
  /** delete single row from the table: "C_ExistingRoofDeck" */
  delete_C_ExistingRoofDeck_by_pk?: Maybe<C_ExistingRoofDeck>;
  /** delete data from the table: "C_ExistingRoofInsulationMaterial" */
  delete_C_ExistingRoofInsulationMaterial?: Maybe<C_ExistingRoofInsulationMaterial_Mutation_Response>;
  /** delete single row from the table: "C_ExistingRoofInsulationMaterial" */
  delete_C_ExistingRoofInsulationMaterial_by_pk?: Maybe<C_ExistingRoofInsulationMaterial>;
  /** delete data from the table: "C_ExistingRoofSinglePlyMaterial" */
  delete_C_ExistingRoofSinglePlyMaterial?: Maybe<C_ExistingRoofSinglePlyMaterial_Mutation_Response>;
  /** delete single row from the table: "C_ExistingRoofSinglePlyMaterial" */
  delete_C_ExistingRoofSinglePlyMaterial_by_pk?: Maybe<C_ExistingRoofSinglePlyMaterial>;
  /** delete data from the table: "C_ExistingRoofSurface" */
  delete_C_ExistingRoofSurface?: Maybe<C_ExistingRoofSurface_Mutation_Response>;
  /** delete single row from the table: "C_ExistingRoofSurface" */
  delete_C_ExistingRoofSurface_by_pk?: Maybe<C_ExistingRoofSurface>;
  /** delete data from the table: "C_ExtFireRating" */
  delete_C_ExtFireRating?: Maybe<C_ExtFireRating_Mutation_Response>;
  /** delete single row from the table: "C_ExtFireRating" */
  delete_C_ExtFireRating_by_pk?: Maybe<C_ExtFireRating>;
  /** delete data from the table: "C_Facer" */
  delete_C_Facer?: Maybe<C_Facer_Mutation_Response>;
  /** delete data from the table: "C_FacerMaterial" */
  delete_C_FacerMaterial?: Maybe<C_FacerMaterial_Mutation_Response>;
  /** delete single row from the table: "C_FacerMaterial" */
  delete_C_FacerMaterial_by_pk?: Maybe<C_FacerMaterial>;
  /** delete single row from the table: "C_Facer" */
  delete_C_Facer_by_pk?: Maybe<C_Facer>;
  /** delete data from the table: "C_FastenerCoating" */
  delete_C_FastenerCoating?: Maybe<C_FastenerCoating_Mutation_Response>;
  /** delete single row from the table: "C_FastenerCoating" */
  delete_C_FastenerCoating_by_pk?: Maybe<C_FastenerCoating>;
  /** delete data from the table: "C_FastenerDriveHead" */
  delete_C_FastenerDriveHead?: Maybe<C_FastenerDriveHead_Mutation_Response>;
  /** delete single row from the table: "C_FastenerDriveHead" */
  delete_C_FastenerDriveHead_by_pk?: Maybe<C_FastenerDriveHead>;
  /** delete data from the table: "C_FastenerType" */
  delete_C_FastenerType?: Maybe<C_FastenerType_Mutation_Response>;
  /** delete single row from the table: "C_FastenerType" */
  delete_C_FastenerType_by_pk?: Maybe<C_FastenerType>;
  /** delete data from the table: "C_FasteningApplicationMethod" */
  delete_C_FasteningApplicationMethod?: Maybe<C_FasteningApplicationMethod_Mutation_Response>;
  /** delete single row from the table: "C_FasteningApplicationMethod" */
  delete_C_FasteningApplicationMethod_by_pk?: Maybe<C_FasteningApplicationMethod>;
  /** delete data from the table: "C_FlashingMaterial" */
  delete_C_FlashingMaterial?: Maybe<C_FlashingMaterial_Mutation_Response>;
  /** delete single row from the table: "C_FlashingMaterial" */
  delete_C_FlashingMaterial_by_pk?: Maybe<C_FlashingMaterial>;
  /** delete data from the table: "C_FlashingType" */
  delete_C_FlashingType?: Maybe<C_FlashingType_Mutation_Response>;
  /** delete single row from the table: "C_FlashingType" */
  delete_C_FlashingType_by_pk?: Maybe<C_FlashingType>;
  /** delete data from the table: "C_FormDeckMaterial" */
  delete_C_FormDeckMaterial?: Maybe<C_FormDeckMaterial_Mutation_Response>;
  /** delete single row from the table: "C_FormDeckMaterial" */
  delete_C_FormDeckMaterial_by_pk?: Maybe<C_FormDeckMaterial>;
  /** delete data from the table: "C_GroundRoughnessRating" */
  delete_C_GroundRoughnessRating?: Maybe<C_GroundRoughnessRating_Mutation_Response>;
  /** delete single row from the table: "C_GroundRoughnessRating" */
  delete_C_GroundRoughnessRating_by_pk?: Maybe<C_GroundRoughnessRating>;
  /** delete data from the table: "C_GutterMaterial" */
  delete_C_GutterMaterial?: Maybe<C_GutterMaterial_Mutation_Response>;
  /** delete single row from the table: "C_GutterMaterial" */
  delete_C_GutterMaterial_by_pk?: Maybe<C_GutterMaterial>;
  /** delete data from the table: "C_HailRating" */
  delete_C_HailRating?: Maybe<C_HailRating_Mutation_Response>;
  /** delete single row from the table: "C_HailRating" */
  delete_C_HailRating_by_pk?: Maybe<C_HailRating>;
  /** delete data from the table: "C_IndustryType" */
  delete_C_IndustryType?: Maybe<C_IndustryType_Mutation_Response>;
  /** delete single row from the table: "C_IndustryType" */
  delete_C_IndustryType_by_pk?: Maybe<C_IndustryType>;
  /** delete data from the table: "C_InsulationCore" */
  delete_C_InsulationCore?: Maybe<C_InsulationCore_Mutation_Response>;
  /** delete single row from the table: "C_InsulationCore" */
  delete_C_InsulationCore_by_pk?: Maybe<C_InsulationCore>;
  /** delete data from the table: "C_InsulationFoamType" */
  delete_C_InsulationFoamType?: Maybe<C_InsulationFoamType_Mutation_Response>;
  /** delete single row from the table: "C_InsulationFoamType" */
  delete_C_InsulationFoamType_by_pk?: Maybe<C_InsulationFoamType>;
  /** delete data from the table: "C_IntFireRating" */
  delete_C_IntFireRating?: Maybe<C_IntFireRating_Mutation_Response>;
  /** delete single row from the table: "C_IntFireRating" */
  delete_C_IntFireRating_by_pk?: Maybe<C_IntFireRating>;
  /** delete data from the table: "C_LapSealantType" */
  delete_C_LapSealantType?: Maybe<C_LapSealantType_Mutation_Response>;
  /** delete single row from the table: "C_LapSealantType" */
  delete_C_LapSealantType_by_pk?: Maybe<C_LapSealantType>;
  /** delete data from the table: "C_LayerType" */
  delete_C_LayerType?: Maybe<C_LayerType_Mutation_Response>;
  /** delete single row from the table: "C_LayerType" */
  delete_C_LayerType_by_pk?: Maybe<C_LayerType>;
  /** delete data from the table: "C_LinerPanelMaterial" */
  delete_C_LinerPanelMaterial?: Maybe<C_LinerPanelMaterial_Mutation_Response>;
  /** delete single row from the table: "C_LinerPanelMaterial" */
  delete_C_LinerPanelMaterial_by_pk?: Maybe<C_LinerPanelMaterial>;
  /** delete data from the table: "C_LiquidApplication" */
  delete_C_LiquidApplication?: Maybe<C_LiquidApplication_Mutation_Response>;
  /** delete single row from the table: "C_LiquidApplication" */
  delete_C_LiquidApplication_by_pk?: Maybe<C_LiquidApplication>;
  /** delete data from the table: "C_LiquidAppliedMaterial" */
  delete_C_LiquidAppliedMaterial?: Maybe<C_LiquidAppliedMaterial_Mutation_Response>;
  /** delete single row from the table: "C_LiquidAppliedMaterial" */
  delete_C_LiquidAppliedMaterial_by_pk?: Maybe<C_LiquidAppliedMaterial>;
  /** delete data from the table: "C_LockStatus" */
  delete_C_LockStatus?: Maybe<C_LockStatus_Mutation_Response>;
  /** delete single row from the table: "C_LockStatus" */
  delete_C_LockStatus_by_pk?: Maybe<C_LockStatus>;
  /** delete data from the table: "C_MDAttributeType" */
  delete_C_MDAttributeType?: Maybe<C_MdAttributeType_Mutation_Response>;
  /** delete single row from the table: "C_MDAttributeType" */
  delete_C_MDAttributeType_by_pk?: Maybe<C_MdAttributeType>;
  /** delete data from the table: "C_MaintenanceCoatingMaterial" */
  delete_C_MaintenanceCoatingMaterial?: Maybe<C_MaintenanceCoatingMaterial_Mutation_Response>;
  /** delete single row from the table: "C_MaintenanceCoatingMaterial" */
  delete_C_MaintenanceCoatingMaterial_by_pk?: Maybe<C_MaintenanceCoatingMaterial>;
  /** delete data from the table: "C_MaintenanceCoatingSubstrate" */
  delete_C_MaintenanceCoatingSubstrate?: Maybe<C_MaintenanceCoatingSubstrate_Mutation_Response>;
  /** delete single row from the table: "C_MaintenanceCoatingSubstrate" */
  delete_C_MaintenanceCoatingSubstrate_by_pk?: Maybe<C_MaintenanceCoatingSubstrate>;
  /** delete data from the table: "C_MembraneTermination" */
  delete_C_MembraneTermination?: Maybe<C_MembraneTermination_Mutation_Response>;
  /** delete single row from the table: "C_MembraneTermination" */
  delete_C_MembraneTermination_by_pk?: Maybe<C_MembraneTermination>;
  /** delete data from the table: "C_MultiplyMaterial" */
  delete_C_MultiplyMaterial?: Maybe<C_MultiplyMaterial_Mutation_Response>;
  /** delete single row from the table: "C_MultiplyMaterial" */
  delete_C_MultiplyMaterial_by_pk?: Maybe<C_MultiplyMaterial>;
  /** delete data from the table: "C_PVApplicationMethod" */
  delete_C_PVApplicationMethod?: Maybe<C_PvApplicationMethod_Mutation_Response>;
  /** delete single row from the table: "C_PVApplicationMethod" */
  delete_C_PVApplicationMethod_by_pk?: Maybe<C_PvApplicationMethod>;
  /** delete data from the table: "C_PVHailRating" */
  delete_C_PVHailRating?: Maybe<C_PvHailRating_Mutation_Response>;
  /** delete single row from the table: "C_PVHailRating" */
  delete_C_PVHailRating_by_pk?: Maybe<C_PvHailRating>;
  /** delete data from the table: "C_PerimeterFlashingType" */
  delete_C_PerimeterFlashingType?: Maybe<C_PerimeterFlashingType_Mutation_Response>;
  /** delete single row from the table: "C_PerimeterFlashingType" */
  delete_C_PerimeterFlashingType_by_pk?: Maybe<C_PerimeterFlashingType>;
  /** delete data from the table: "C_PressureZone" */
  delete_C_PressureZone?: Maybe<C_PressureZone_Mutation_Response>;
  /** delete single row from the table: "C_PressureZone" */
  delete_C_PressureZone_by_pk?: Maybe<C_PressureZone>;
  /** delete data from the table: "C_ProductApprovedUse" */
  delete_C_ProductApprovedUse?: Maybe<C_ProductApprovedUse_Mutation_Response>;
  /** delete data from the table: "C_ProjectRole" */
  delete_C_ProjectRole?: Maybe<C_ProjectRole_Mutation_Response>;
  /** delete single row from the table: "C_ProjectRole" */
  delete_C_ProjectRole_by_pk?: Maybe<C_ProjectRole>;
  /** delete data from the table: "C_Reinforcement" */
  delete_C_Reinforcement?: Maybe<C_Reinforcement_Mutation_Response>;
  /** delete single row from the table: "C_Reinforcement" */
  delete_C_Reinforcement_by_pk?: Maybe<C_Reinforcement>;
  /** delete data from the table: "C_RetarderMaterial" */
  delete_C_RetarderMaterial?: Maybe<C_RetarderMaterial_Mutation_Response>;
  /** delete single row from the table: "C_RetarderMaterial" */
  delete_C_RetarderMaterial_by_pk?: Maybe<C_RetarderMaterial>;
  /** delete data from the table: "C_ScenarioStatus" */
  delete_C_ScenarioStatus?: Maybe<C_ScenarioStatus_Mutation_Response>;
  /** delete single row from the table: "C_ScenarioStatus" */
  delete_C_ScenarioStatus_by_pk?: Maybe<C_ScenarioStatus>;
  /** delete data from the table: "C_ScenarioType" */
  delete_C_ScenarioType?: Maybe<C_ScenarioType_Mutation_Response>;
  /** delete single row from the table: "C_ScenarioType" */
  delete_C_ScenarioType_by_pk?: Maybe<C_ScenarioType>;
  /** delete data from the table: "C_SeamPrimerType" */
  delete_C_SeamPrimerType?: Maybe<C_SeamPrimerType_Mutation_Response>;
  /** delete single row from the table: "C_SeamPrimerType" */
  delete_C_SeamPrimerType_by_pk?: Maybe<C_SeamPrimerType>;
  /** delete data from the table: "C_SeamWashType" */
  delete_C_SeamWashType?: Maybe<C_SeamWashType_Mutation_Response>;
  /** delete single row from the table: "C_SeamWashType" */
  delete_C_SeamWashType_by_pk?: Maybe<C_SeamWashType>;
  /** delete data from the table: "C_SecurementMaterial" */
  delete_C_SecurementMaterial?: Maybe<C_SecurementMaterial_Mutation_Response>;
  /** delete single row from the table: "C_SecurementMaterial" */
  delete_C_SecurementMaterial_by_pk?: Maybe<C_SecurementMaterial>;
  /** delete data from the table: "C_ShinglesHail" */
  delete_C_ShinglesHail?: Maybe<C_ShinglesHail_Mutation_Response>;
  /** delete single row from the table: "C_ShinglesHail" */
  delete_C_ShinglesHail_by_pk?: Maybe<C_ShinglesHail>;
  /** delete data from the table: "C_ShinglesMaterial" */
  delete_C_ShinglesMaterial?: Maybe<C_ShinglesMaterial_Mutation_Response>;
  /** delete single row from the table: "C_ShinglesMaterial" */
  delete_C_ShinglesMaterial_by_pk?: Maybe<C_ShinglesMaterial>;
  /** delete data from the table: "C_ShinglesReinforcement" */
  delete_C_ShinglesReinforcement?: Maybe<C_ShinglesReinforcement_Mutation_Response>;
  /** delete single row from the table: "C_ShinglesReinforcement" */
  delete_C_ShinglesReinforcement_by_pk?: Maybe<C_ShinglesReinforcement>;
  /** delete data from the table: "C_ShinglesSurfacing" */
  delete_C_ShinglesSurfacing?: Maybe<C_ShinglesSurfacing_Mutation_Response>;
  /** delete single row from the table: "C_ShinglesSurfacing" */
  delete_C_ShinglesSurfacing_by_pk?: Maybe<C_ShinglesSurfacing>;
  /** delete data from the table: "C_ShinglesWindSpeed" */
  delete_C_ShinglesWindSpeed?: Maybe<C_ShinglesWindSpeed_Mutation_Response>;
  /** delete single row from the table: "C_ShinglesWindSpeed" */
  delete_C_ShinglesWindSpeed_by_pk?: Maybe<C_ShinglesWindSpeed>;
  /** delete data from the table: "C_SinglePlyMaterial" */
  delete_C_SinglePlyMaterial?: Maybe<C_SinglePlyMaterial_Mutation_Response>;
  /** delete single row from the table: "C_SinglePlyMaterial" */
  delete_C_SinglePlyMaterial_by_pk?: Maybe<C_SinglePlyMaterial>;
  /** delete data from the table: "C_StandingLapSeamMaterial" */
  delete_C_StandingLapSeamMaterial?: Maybe<C_StandingLapSeamMaterial_Mutation_Response>;
  /** delete single row from the table: "C_StandingLapSeamMaterial" */
  delete_C_StandingLapSeamMaterial_by_pk?: Maybe<C_StandingLapSeamMaterial>;
  /** delete data from the table: "C_StateProvince" */
  delete_C_StateProvince?: Maybe<C_StateProvince_Mutation_Response>;
  /** delete single row from the table: "C_StateProvince" */
  delete_C_StateProvince_by_pk?: Maybe<C_StateProvince>;
  /** delete data from the table: "C_Status" */
  delete_C_Status?: Maybe<C_Status_Mutation_Response>;
  /** delete single row from the table: "C_Status" */
  delete_C_Status_by_pk?: Maybe<C_Status>;
  /** delete data from the table: "C_SteelDeckRibType" */
  delete_C_SteelDeckRibType?: Maybe<C_SteelDeckRibType_Mutation_Response>;
  /** delete single row from the table: "C_SteelDeckRibType" */
  delete_C_SteelDeckRibType_by_pk?: Maybe<C_SteelDeckRibType>;
  /** delete data from the table: "C_StressDistributorType" */
  delete_C_StressDistributorType?: Maybe<C_StressDistributorType_Mutation_Response>;
  /** delete single row from the table: "C_StressDistributorType" */
  delete_C_StressDistributorType_by_pk?: Maybe<C_StressDistributorType>;
  /** delete data from the table: "C_StressPlateShape" */
  delete_C_StressPlateShape?: Maybe<C_StressPlateShape_Mutation_Response>;
  /** delete single row from the table: "C_StressPlateShape" */
  delete_C_StressPlateShape_by_pk?: Maybe<C_StressPlateShape>;
  /** delete data from the table: "C_StructureType" */
  delete_C_StructureType?: Maybe<C_StructureType_Mutation_Response>;
  /** delete single row from the table: "C_StructureType" */
  delete_C_StructureType_by_pk?: Maybe<C_StructureType>;
  /** delete data from the table: "C_SubassemblyType" */
  delete_C_SubassemblyType?: Maybe<C_SubassemblyType_Mutation_Response>;
  /** delete single row from the table: "C_SubassemblyType" */
  delete_C_SubassemblyType_by_pk?: Maybe<C_SubassemblyType>;
  /** delete data from the table: "C_Substrate" */
  delete_C_Substrate?: Maybe<C_Substrate_Mutation_Response>;
  /** delete data from the table: "C_SubstratePrimerMaterial" */
  delete_C_SubstratePrimerMaterial?: Maybe<C_SubstratePrimerMaterial_Mutation_Response>;
  /** delete single row from the table: "C_SubstratePrimerMaterial" */
  delete_C_SubstratePrimerMaterial_by_pk?: Maybe<C_SubstratePrimerMaterial>;
  /** delete single row from the table: "C_Substrate" */
  delete_C_Substrate_by_pk?: Maybe<C_Substrate>;
  /** delete data from the table: "C_SurfaceType" */
  delete_C_SurfaceType?: Maybe<C_SurfaceType_Mutation_Response>;
  /** delete single row from the table: "C_SurfaceType" */
  delete_C_SurfaceType_by_pk?: Maybe<C_SurfaceType>;
  /** delete data from the table: "C_SurfacingMaterial" */
  delete_C_SurfacingMaterial?: Maybe<C_SurfacingMaterial_Mutation_Response>;
  /** delete single row from the table: "C_SurfacingMaterial" */
  delete_C_SurfacingMaterial_by_pk?: Maybe<C_SurfacingMaterial>;
  /** delete data from the table: "C_SystemApprovedUse" */
  delete_C_SystemApprovedUse?: Maybe<C_SystemApprovedUse_Mutation_Response>;
  /** delete data from the table: "C_ThermalBarrierMaterial" */
  delete_C_ThermalBarrierMaterial?: Maybe<C_ThermalBarrierMaterial_Mutation_Response>;
  /** delete single row from the table: "C_ThermalBarrierMaterial" */
  delete_C_ThermalBarrierMaterial_by_pk?: Maybe<C_ThermalBarrierMaterial>;
  /** delete data from the table: "C_WIPDelistReason" */
  delete_C_WIPDelistReason?: Maybe<C_WipDelistReason_Mutation_Response>;
  /** delete single row from the table: "C_WIPDelistReason" */
  delete_C_WIPDelistReason_by_pk?: Maybe<C_WipDelistReason>;
  /** delete data from the table: "C_WIPOnHoldReason" */
  delete_C_WIPOnHoldReason?: Maybe<C_WipOnHoldReason_Mutation_Response>;
  /** delete single row from the table: "C_WIPOnHoldReason" */
  delete_C_WIPOnHoldReason_by_pk?: Maybe<C_WipOnHoldReason>;
  /** delete data from the table: "C_WIPStatus" */
  delete_C_WIPStatus?: Maybe<C_WipStatus_Mutation_Response>;
  /** delete single row from the table: "C_WIPStatus" */
  delete_C_WIPStatus_by_pk?: Maybe<C_WipStatus>;
  /** delete data from the table: "C_WeldApplication" */
  delete_C_WeldApplication?: Maybe<C_WeldApplication_Mutation_Response>;
  /** delete single row from the table: "C_WeldApplication" */
  delete_C_WeldApplication_by_pk?: Maybe<C_WeldApplication>;
  /** delete data from the table: "C_WeldConfiguration" */
  delete_C_WeldConfiguration?: Maybe<C_WeldConfiguration_Mutation_Response>;
  /** delete single row from the table: "C_WeldConfiguration" */
  delete_C_WeldConfiguration_by_pk?: Maybe<C_WeldConfiguration>;
  /** delete data from the table: "C_WindBorneDebris" */
  delete_C_WindBorneDebris?: Maybe<C_WindBorneDebris_Mutation_Response>;
  /** delete single row from the table: "C_WindBorneDebris" */
  delete_C_WindBorneDebris_by_pk?: Maybe<C_WindBorneDebris>;
  /** delete data from the table: "C_WoodSpecies" */
  delete_C_WoodSpecies?: Maybe<C_WoodSpecies_Mutation_Response>;
  /** delete single row from the table: "C_WoodSpecies" */
  delete_C_WoodSpecies_by_pk?: Maybe<C_WoodSpecies>;
  /** delete data from the table: "C_WoodType" */
  delete_C_WoodType?: Maybe<C_WoodType_Mutation_Response>;
  /** delete single row from the table: "C_WoodType" */
  delete_C_WoodType_by_pk?: Maybe<C_WoodType>;
  /** delete data from the table: "C_WorkflowStatus" */
  delete_C_WorkflowStatus?: Maybe<C_WorkflowStatus_Mutation_Response>;
  /** delete single row from the table: "C_WorkflowStatus" */
  delete_C_WorkflowStatus_by_pk?: Maybe<C_WorkflowStatus>;
  /** delete data from the table: "CapPly" */
  delete_CapPly?: Maybe<CapPly_Mutation_Response>;
  /** delete data from the table: "CapPlyUsage" */
  delete_CapPlyUsage?: Maybe<CapPlyUsage_Mutation_Response>;
  /** delete single row from the table: "CapPlyUsage" */
  delete_CapPlyUsage_by_pk?: Maybe<CapPlyUsage>;
  /** delete single row from the table: "CapPly" */
  delete_CapPly_by_pk?: Maybe<CapPly>;
  /** delete data from the table: "Category" */
  delete_Category?: Maybe<Category_Mutation_Response>;
  /** delete data from the table: "CategoryLog" */
  delete_CategoryLog?: Maybe<CategoryLog_Mutation_Response>;
  /** delete single row from the table: "CategoryLog" */
  delete_CategoryLog_by_pk?: Maybe<CategoryLog>;
  /** delete data from the table: "Category_Coating" */
  delete_Category_Coating?: Maybe<Category_Coating_Mutation_Response>;
  /** delete data from the table: "Category_Cover" */
  delete_Category_Cover?: Maybe<Category_Cover_Mutation_Response>;
  /** delete data from the table: "Category_Deck" */
  delete_Category_Deck?: Maybe<Category_Deck_Mutation_Response>;
  /** delete data from the table: "Category_Flashing" */
  delete_Category_Flashing?: Maybe<Category_Flashing_Mutation_Response>;
  /** delete data from the table: "Category_Insulation" */
  delete_Category_Insulation?: Maybe<Category_Insulation_Mutation_Response>;
  /** delete data from the table: "Category_Other" */
  delete_Category_Other?: Maybe<Category_Other_Mutation_Response>;
  /** delete data from the table: "Category_Seaming" */
  delete_Category_Seaming?: Maybe<Category_Seaming_Mutation_Response>;
  /** delete data from the table: "Category_Securement" */
  delete_Category_Securement?: Maybe<Category_Securement_Mutation_Response>;
  /** delete data from the table: "Category_Sheet" */
  delete_Category_Sheet?: Maybe<Category_Sheet_Mutation_Response>;
  /** delete single row from the table: "Category" */
  delete_Category_by_pk?: Maybe<Category>;
  /** delete data from the table: "CementitiousWoodFiberDeck" */
  delete_CementitiousWoodFiberDeck?: Maybe<CementitiousWoodFiberDeck_Mutation_Response>;
  /** delete data from the table: "CementitiousWoodFiberDeckUsage" */
  delete_CementitiousWoodFiberDeckUsage?: Maybe<CementitiousWoodFiberDeckUsage_Mutation_Response>;
  /** delete single row from the table: "CementitiousWoodFiberDeckUsage" */
  delete_CementitiousWoodFiberDeckUsage_by_pk?: Maybe<CementitiousWoodFiberDeckUsage>;
  /** delete single row from the table: "CementitiousWoodFiberDeck" */
  delete_CementitiousWoodFiberDeck_by_pk?: Maybe<CementitiousWoodFiberDeck>;
  /** delete data from the table: "Clip" */
  delete_Clip?: Maybe<Clip_Mutation_Response>;
  /** delete data from the table: "ClipUsage" */
  delete_ClipUsage?: Maybe<ClipUsage_Mutation_Response>;
  /** delete single row from the table: "ClipUsage" */
  delete_ClipUsage_by_pk?: Maybe<ClipUsage>;
  /** delete single row from the table: "Clip" */
  delete_Clip_by_pk?: Maybe<Clip>;
  /** delete data from the table: "Coat" */
  delete_Coat?: Maybe<Coat_Mutation_Response>;
  /** delete data from the table: "CoatUsage" */
  delete_CoatUsage?: Maybe<CoatUsage_Mutation_Response>;
  /** delete single row from the table: "CoatUsage" */
  delete_CoatUsage_by_pk?: Maybe<CoatUsage>;
  /** delete single row from the table: "Coat" */
  delete_Coat_by_pk?: Maybe<Coat>;
  /** delete data from the table: "Coating" */
  delete_Coating?: Maybe<Coating_Mutation_Response>;
  /** delete data from the table: "CoatingSystemUsage" */
  delete_CoatingSystemUsage?: Maybe<CoatingSystemUsage_Mutation_Response>;
  /** delete single row from the table: "CoatingSystemUsage" */
  delete_CoatingSystemUsage_by_pk?: Maybe<CoatingSystemUsage>;
  /** delete data from the table: "CoatingUsage" */
  delete_CoatingUsage?: Maybe<CoatingUsage_Mutation_Response>;
  /** delete single row from the table: "CoatingUsage" */
  delete_CoatingUsage_by_pk?: Maybe<CoatingUsage>;
  /** delete single row from the table: "Coating" */
  delete_Coating_by_pk?: Maybe<Coating>;
  /** delete data from the table: "Component" */
  delete_Component?: Maybe<Component_Mutation_Response>;
  /** delete data from the table: "ComponentApprovedUse" */
  delete_ComponentApprovedUse?: Maybe<ComponentApprovedUse_Mutation_Response>;
  /** delete single row from the table: "ComponentApprovedUse" */
  delete_ComponentApprovedUse_by_pk?: Maybe<ComponentApprovedUse>;
  /** delete data from the table: "ComponentDoc" */
  delete_ComponentDoc?: Maybe<ComponentDoc_Mutation_Response>;
  /** delete single row from the table: "ComponentDoc" */
  delete_ComponentDoc_by_pk?: Maybe<ComponentDoc>;
  /** delete data from the table: "ComponentLayer" */
  delete_ComponentLayer?: Maybe<ComponentLayer_Mutation_Response>;
  /** delete data from the table: "ComponentLayerUsage" */
  delete_ComponentLayerUsage?: Maybe<ComponentLayerUsage_Mutation_Response>;
  /** delete single row from the table: "ComponentLayerUsage" */
  delete_ComponentLayerUsage_by_pk?: Maybe<ComponentLayerUsage>;
  /** delete single row from the table: "ComponentLayer" */
  delete_ComponentLayer_by_pk?: Maybe<ComponentLayer>;
  /** delete single row from the table: "Component" */
  delete_Component_by_pk?: Maybe<Component>;
  /** delete data from the table: "CompositePanelCover" */
  delete_CompositePanelCover?: Maybe<CompositePanelCover_Mutation_Response>;
  /** delete single row from the table: "CompositePanelCover" */
  delete_CompositePanelCover_by_pk?: Maybe<CompositePanelCover>;
  /** delete data from the table: "CompositePanelUsage" */
  delete_CompositePanelUsage?: Maybe<CompositePanelUsage_Mutation_Response>;
  /** delete single row from the table: "CompositePanelUsage" */
  delete_CompositePanelUsage_by_pk?: Maybe<CompositePanelUsage>;
  /** delete data from the table: "ContactInfo" */
  delete_ContactInfo?: Maybe<ContactInfo_Mutation_Response>;
  /** delete single row from the table: "ContactInfo" */
  delete_ContactInfo_by_pk?: Maybe<ContactInfo>;
  /** delete data from the table: "Coping" */
  delete_Coping?: Maybe<Coping_Mutation_Response>;
  /** delete single row from the table: "Coping" */
  delete_Coping_by_pk?: Maybe<Coping>;
  /** delete data from the table: "CoverBoard" */
  delete_CoverBoard?: Maybe<CoverBoard_Mutation_Response>;
  /** delete data from the table: "CoverBoardBoardSizes" */
  delete_CoverBoardBoardSizes?: Maybe<CoverBoardBoardSizes_Mutation_Response>;
  /** delete single row from the table: "CoverBoardBoardSizes" */
  delete_CoverBoardBoardSizes_by_pk?: Maybe<CoverBoardBoardSizes>;
  /** delete data from the table: "CoverBoardFacerBottom" */
  delete_CoverBoardFacerBottom?: Maybe<CoverBoardFacerBottom_Mutation_Response>;
  /** delete single row from the table: "CoverBoardFacerBottom" */
  delete_CoverBoardFacerBottom_by_pk?: Maybe<CoverBoardFacerBottom>;
  /** delete data from the table: "CoverBoardFacerTop" */
  delete_CoverBoardFacerTop?: Maybe<CoverBoardFacerTop_Mutation_Response>;
  /** delete single row from the table: "CoverBoardFacerTop" */
  delete_CoverBoardFacerTop_by_pk?: Maybe<CoverBoardFacerTop>;
  /** delete data from the table: "CoverBoardUsage" */
  delete_CoverBoardUsage?: Maybe<CoverBoardUsage_Mutation_Response>;
  /** delete single row from the table: "CoverBoardUsage" */
  delete_CoverBoardUsage_by_pk?: Maybe<CoverBoardUsage>;
  /** delete single row from the table: "CoverBoard" */
  delete_CoverBoard_by_pk?: Maybe<CoverBoard>;
  /** delete data from the table: "CoverStripDisk" */
  delete_CoverStripDisk?: Maybe<CoverStripDisk_Mutation_Response>;
  /** delete data from the table: "CoverStripDiskUsage" */
  delete_CoverStripDiskUsage?: Maybe<CoverStripDiskUsage_Mutation_Response>;
  /** delete single row from the table: "CoverStripDiskUsage" */
  delete_CoverStripDiskUsage_by_pk?: Maybe<CoverStripDiskUsage>;
  /** delete single row from the table: "CoverStripDisk" */
  delete_CoverStripDisk_by_pk?: Maybe<CoverStripDisk>;
  /** delete data from the table: "ExistingRoofLayer" */
  delete_ExistingRoofLayer?: Maybe<ExistingRoofLayer_Mutation_Response>;
  /** delete single row from the table: "ExistingRoofLayer" */
  delete_ExistingRoofLayer_by_pk?: Maybe<ExistingRoofLayer>;
  /** delete data from the table: "ExpansionJoint" */
  delete_ExpansionJoint?: Maybe<ExpansionJoint_Mutation_Response>;
  /** delete data from the table: "ExpansionJointUsage" */
  delete_ExpansionJointUsage?: Maybe<ExpansionJointUsage_Mutation_Response>;
  /** delete single row from the table: "ExpansionJointUsage" */
  delete_ExpansionJointUsage_by_pk?: Maybe<ExpansionJointUsage>;
  /** delete single row from the table: "ExpansionJoint" */
  delete_ExpansionJoint_by_pk?: Maybe<ExpansionJoint>;
  /** delete data from the table: "ExtendedLayerConfigSplit" */
  delete_ExtendedLayerConfigSplit?: Maybe<ExtendedLayerConfigSplit_Mutation_Response>;
  /** delete single row from the table: "ExtendedLayerConfigSplit" */
  delete_ExtendedLayerConfigSplit_by_pk?: Maybe<ExtendedLayerConfigSplit>;
  /** delete data from the table: "FMUserAccount" */
  delete_FMUserAccount?: Maybe<FmUserAccount_Mutation_Response>;
  /** delete single row from the table: "FMUserAccount" */
  delete_FMUserAccount_by_pk?: Maybe<FmUserAccount>;
  /** delete data from the table: "Fascia" */
  delete_Fascia?: Maybe<Fascia_Mutation_Response>;
  /** delete single row from the table: "Fascia" */
  delete_Fascia_by_pk?: Maybe<Fascia>;
  /** delete data from the table: "Fastener" */
  delete_Fastener?: Maybe<Fastener_Mutation_Response>;
  /** delete data from the table: "FastenerUsage" */
  delete_FastenerUsage?: Maybe<FastenerUsage_Mutation_Response>;
  /** delete single row from the table: "FastenerUsage" */
  delete_FastenerUsage_by_pk?: Maybe<FastenerUsage>;
  /** delete single row from the table: "Fastener" */
  delete_Fastener_by_pk?: Maybe<Fastener>;
  /** delete data from the table: "FasteningSystemBattenBarUsage" */
  delete_FasteningSystemBattenBarUsage?: Maybe<FasteningSystemBattenBarUsage_Mutation_Response>;
  /** delete single row from the table: "FasteningSystemBattenBarUsage" */
  delete_FasteningSystemBattenBarUsage_by_pk?: Maybe<FasteningSystemBattenBarUsage>;
  /** delete data from the table: "FasteningSystemClipUsage" */
  delete_FasteningSystemClipUsage?: Maybe<FasteningSystemClipUsage_Mutation_Response>;
  /** delete single row from the table: "FasteningSystemClipUsage" */
  delete_FasteningSystemClipUsage_by_pk?: Maybe<FasteningSystemClipUsage>;
  /** delete data from the table: "FasteningSystemStressPlateUsage" */
  delete_FasteningSystemStressPlateUsage?: Maybe<FasteningSystemStressPlateUsage_Mutation_Response>;
  /** delete single row from the table: "FasteningSystemStressPlateUsage" */
  delete_FasteningSystemStressPlateUsage_by_pk?: Maybe<FasteningSystemStressPlateUsage>;
  /** delete data from the table: "FiberReinforcedPlasticDeck" */
  delete_FiberReinforcedPlasticDeck?: Maybe<FiberReinforcedPlasticDeck_Mutation_Response>;
  /** delete data from the table: "FiberReinforcedPlasticDeckUsage" */
  delete_FiberReinforcedPlasticDeckUsage?: Maybe<FiberReinforcedPlasticDeckUsage_Mutation_Response>;
  /** delete single row from the table: "FiberReinforcedPlasticDeckUsage" */
  delete_FiberReinforcedPlasticDeckUsage_by_pk?: Maybe<FiberReinforcedPlasticDeckUsage>;
  /** delete single row from the table: "FiberReinforcedPlasticDeck" */
  delete_FiberReinforcedPlasticDeck_by_pk?: Maybe<FiberReinforcedPlasticDeck>;
  /** delete data from the table: "FormDeck" */
  delete_FormDeck?: Maybe<FormDeck_Mutation_Response>;
  /** delete data from the table: "FormDeckUsage" */
  delete_FormDeckUsage?: Maybe<FormDeckUsage_Mutation_Response>;
  /** delete single row from the table: "FormDeckUsage" */
  delete_FormDeckUsage_by_pk?: Maybe<FormDeckUsage>;
  /** delete single row from the table: "FormDeck" */
  delete_FormDeck_by_pk?: Maybe<FormDeck>;
  /** delete data from the table: "Gutter" */
  delete_Gutter?: Maybe<Gutter_Mutation_Response>;
  /** delete single row from the table: "Gutter" */
  delete_Gutter_by_pk?: Maybe<Gutter>;
  /** delete data from the table: "GypsumDeck" */
  delete_GypsumDeck?: Maybe<GypsumDeck_Mutation_Response>;
  /** delete data from the table: "GypsumDeckUsage" */
  delete_GypsumDeckUsage?: Maybe<GypsumDeckUsage_Mutation_Response>;
  /** delete single row from the table: "GypsumDeckUsage" */
  delete_GypsumDeckUsage_by_pk?: Maybe<GypsumDeckUsage>;
  /** delete single row from the table: "GypsumDeck" */
  delete_GypsumDeck_by_pk?: Maybe<GypsumDeck>;
  /** delete data from the table: "InsBoardSize" */
  delete_InsBoardSize?: Maybe<InsBoardSize_Mutation_Response>;
  /** delete single row from the table: "InsBoardSize" */
  delete_InsBoardSize_by_pk?: Maybe<InsBoardSize>;
  /** delete data from the table: "ItemWorkspace" */
  delete_ItemWorkspace?: Maybe<ItemWorkspace_Mutation_Response>;
  /** delete data from the table: "ItemWorkspaceData" */
  delete_ItemWorkspaceData?: Maybe<ItemWorkspaceData_Mutation_Response>;
  /** delete single row from the table: "ItemWorkspaceData" */
  delete_ItemWorkspaceData_by_pk?: Maybe<ItemWorkspaceData>;
  /** delete single row from the table: "ItemWorkspace" */
  delete_ItemWorkspace_by_pk?: Maybe<ItemWorkspace>;
  /** delete data from the table: "LapSealant" */
  delete_LapSealant?: Maybe<LapSealant_Mutation_Response>;
  /** delete data from the table: "LapSealantUsage" */
  delete_LapSealantUsage?: Maybe<LapSealantUsage_Mutation_Response>;
  /** delete single row from the table: "LapSealantUsage" */
  delete_LapSealantUsage_by_pk?: Maybe<LapSealantUsage>;
  /** delete single row from the table: "LapSealant" */
  delete_LapSealant_by_pk?: Maybe<LapSealant>;
  /** delete data from the table: "LapSecurementInfo" */
  delete_LapSecurementInfo?: Maybe<LapSecurementInfo_Mutation_Response>;
  /** delete single row from the table: "LapSecurementInfo" */
  delete_LapSecurementInfo_by_pk?: Maybe<LapSecurementInfo>;
  /** delete data from the table: "Layer" */
  delete_Layer?: Maybe<Layer_Mutation_Response>;
  /** delete data from the table: "LayerConfig" */
  delete_LayerConfig?: Maybe<LayerConfig_Mutation_Response>;
  /** delete single row from the table: "LayerConfig" */
  delete_LayerConfig_by_pk?: Maybe<LayerConfig>;
  /** delete single row from the table: "Layer" */
  delete_Layer_by_pk?: Maybe<Layer>;
  /** delete data from the table: "LightweightInsulatingConcrete" */
  delete_LightweightInsulatingConcrete?: Maybe<LightweightInsulatingConcrete_Mutation_Response>;
  /** delete data from the table: "LightweightInsulatingConcreteSubassemblyUsage" */
  delete_LightweightInsulatingConcreteSubassemblyUsage?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Mutation_Response>;
  /** delete single row from the table: "LightweightInsulatingConcreteSubassemblyUsage" */
  delete_LightweightInsulatingConcreteSubassemblyUsage_by_pk?: Maybe<LightweightInsulatingConcreteSubassemblyUsage>;
  /** delete data from the table: "LightweightInsulatingConcreteUsage" */
  delete_LightweightInsulatingConcreteUsage?: Maybe<LightweightInsulatingConcreteUsage_Mutation_Response>;
  /** delete single row from the table: "LightweightInsulatingConcreteUsage" */
  delete_LightweightInsulatingConcreteUsage_by_pk?: Maybe<LightweightInsulatingConcreteUsage>;
  /** delete single row from the table: "LightweightInsulatingConcrete" */
  delete_LightweightInsulatingConcrete_by_pk?: Maybe<LightweightInsulatingConcrete>;
  /** delete data from the table: "LinerPanel" */
  delete_LinerPanel?: Maybe<LinerPanel_Mutation_Response>;
  /** delete data from the table: "LinerPanelUsage" */
  delete_LinerPanelUsage?: Maybe<LinerPanelUsage_Mutation_Response>;
  /** delete single row from the table: "LinerPanelUsage" */
  delete_LinerPanelUsage_by_pk?: Maybe<LinerPanelUsage>;
  /** delete single row from the table: "LinerPanel" */
  delete_LinerPanel_by_pk?: Maybe<LinerPanel>;
  /** delete data from the table: "LiquidAppliedSubassemblyUsage" */
  delete_LiquidAppliedSubassemblyUsage?: Maybe<LiquidAppliedSubassemblyUsage_Mutation_Response>;
  /** delete single row from the table: "LiquidAppliedSubassemblyUsage" */
  delete_LiquidAppliedSubassemblyUsage_by_pk?: Maybe<LiquidAppliedSubassemblyUsage>;
  /** delete data from the table: "LiquidAppliedUsage" */
  delete_LiquidAppliedUsage?: Maybe<LiquidAppliedUsage_Mutation_Response>;
  /** delete single row from the table: "LiquidAppliedUsage" */
  delete_LiquidAppliedUsage_by_pk?: Maybe<LiquidAppliedUsage>;
  /** delete data from the table: "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
  delete_Load_SearchLayerUsage_DeckFiberReinforcedPlastic?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Mutation_Response>;
  /** delete data from the table: "MD_ApprovedUse" */
  delete_MD_ApprovedUse?: Maybe<Md_ApprovedUse_Mutation_Response>;
  /** delete data from the table: "MD_ApprovedUseAttributes" */
  delete_MD_ApprovedUseAttributes?: Maybe<Md_ApprovedUseAttributes_Mutation_Response>;
  /** delete single row from the table: "MD_ApprovedUseAttributes" */
  delete_MD_ApprovedUseAttributes_by_pk?: Maybe<Md_ApprovedUseAttributes>;
  /** delete single row from the table: "MD_ApprovedUse" */
  delete_MD_ApprovedUse_by_pk?: Maybe<Md_ApprovedUse>;
  /** delete data from the table: "MD_AttributeBase" */
  delete_MD_AttributeBase?: Maybe<Md_AttributeBase_Mutation_Response>;
  /** delete single row from the table: "MD_AttributeBase" */
  delete_MD_AttributeBase_by_pk?: Maybe<Md_AttributeBase>;
  /** delete data from the table: "MD_BooleanAttribute" */
  delete_MD_BooleanAttribute?: Maybe<Md_BooleanAttribute_Mutation_Response>;
  /** delete single row from the table: "MD_BooleanAttribute" */
  delete_MD_BooleanAttribute_by_pk?: Maybe<Md_BooleanAttribute>;
  /** delete data from the table: "MD_BooleanDisplayOption" */
  delete_MD_BooleanDisplayOption?: Maybe<Md_BooleanDisplayOption_Mutation_Response>;
  /** delete single row from the table: "MD_BooleanDisplayOption" */
  delete_MD_BooleanDisplayOption_by_pk?: Maybe<Md_BooleanDisplayOption>;
  /** delete data from the table: "MD_Catalog" */
  delete_MD_Catalog?: Maybe<Md_Catalog_Mutation_Response>;
  /** delete single row from the table: "MD_Catalog" */
  delete_MD_Catalog_by_pk?: Maybe<Md_Catalog>;
  /** delete data from the table: "MD_CodeTableAttribute" */
  delete_MD_CodeTableAttribute?: Maybe<Md_CodeTableAttribute_Mutation_Response>;
  /** delete single row from the table: "MD_CodeTableAttribute" */
  delete_MD_CodeTableAttribute_by_pk?: Maybe<Md_CodeTableAttribute>;
  /** delete data from the table: "MD_CodeTables" */
  delete_MD_CodeTables?: Maybe<Md_CodeTables_Mutation_Response>;
  /** delete single row from the table: "MD_CodeTables" */
  delete_MD_CodeTables_by_pk?: Maybe<Md_CodeTables>;
  /** delete data from the table: "MD_ComponentUsage" */
  delete_MD_ComponentUsage?: Maybe<Md_ComponentUsage_Mutation_Response>;
  /** delete data from the table: "MD_ComponentUsageAttributes" */
  delete_MD_ComponentUsageAttributes?: Maybe<Md_ComponentUsageAttributes_Mutation_Response>;
  /** delete single row from the table: "MD_ComponentUsageAttributes" */
  delete_MD_ComponentUsageAttributes_by_pk?: Maybe<Md_ComponentUsageAttributes>;
  /** delete single row from the table: "MD_ComponentUsage" */
  delete_MD_ComponentUsage_by_pk?: Maybe<Md_ComponentUsage>;
  /** delete data from the table: "MD_Measure" */
  delete_MD_Measure?: Maybe<Md_Measure_Mutation_Response>;
  /** delete single row from the table: "MD_Measure" */
  delete_MD_Measure_by_pk?: Maybe<Md_Measure>;
  /** delete data from the table: "MD_MeasurementAttribute" */
  delete_MD_MeasurementAttribute?: Maybe<Md_MeasurementAttribute_Mutation_Response>;
  /** delete single row from the table: "MD_MeasurementAttribute" */
  delete_MD_MeasurementAttribute_by_pk?: Maybe<Md_MeasurementAttribute>;
  /** delete data from the table: "MD_MeasurementDisplayOption" */
  delete_MD_MeasurementDisplayOption?: Maybe<Md_MeasurementDisplayOption_Mutation_Response>;
  /** delete single row from the table: "MD_MeasurementDisplayOption" */
  delete_MD_MeasurementDisplayOption_by_pk?: Maybe<Md_MeasurementDisplayOption>;
  /** delete data from the table: "MD_MultivalueAttribute" */
  delete_MD_MultivalueAttribute?: Maybe<Md_MultivalueAttribute_Mutation_Response>;
  /** delete single row from the table: "MD_MultivalueAttribute" */
  delete_MD_MultivalueAttribute_by_pk?: Maybe<Md_MultivalueAttribute>;
  /** delete data from the table: "MD_MultivalueDisplayOption" */
  delete_MD_MultivalueDisplayOption?: Maybe<Md_MultivalueDisplayOption_Mutation_Response>;
  /** delete single row from the table: "MD_MultivalueDisplayOption" */
  delete_MD_MultivalueDisplayOption_by_pk?: Maybe<Md_MultivalueDisplayOption>;
  /** delete data from the table: "MD_ProductCategory" */
  delete_MD_ProductCategory?: Maybe<Md_ProductCategory_Mutation_Response>;
  /** delete single row from the table: "MD_ProductCategory" */
  delete_MD_ProductCategory_by_pk?: Maybe<Md_ProductCategory>;
  /** delete data from the table: "MD_ProductSubcategory" */
  delete_MD_ProductSubcategory?: Maybe<Md_ProductSubcategory_Mutation_Response>;
  /** delete single row from the table: "MD_ProductSubcategory" */
  delete_MD_ProductSubcategory_by_pk?: Maybe<Md_ProductSubcategory>;
  /** delete data from the table: "MaintenanceCoating" */
  delete_MaintenanceCoating?: Maybe<MaintenanceCoating_Mutation_Response>;
  /** delete data from the table: "MaintenanceCoatingSubstrate" */
  delete_MaintenanceCoatingSubstrate?: Maybe<MaintenanceCoatingSubstrate_Mutation_Response>;
  /** delete single row from the table: "MaintenanceCoatingSubstrate" */
  delete_MaintenanceCoatingSubstrate_by_pk?: Maybe<MaintenanceCoatingSubstrate>;
  /** delete data from the table: "MaintenanceCoatingUsage" */
  delete_MaintenanceCoatingUsage?: Maybe<MaintenanceCoatingUsage_Mutation_Response>;
  /** delete single row from the table: "MaintenanceCoatingUsage" */
  delete_MaintenanceCoatingUsage_by_pk?: Maybe<MaintenanceCoatingUsage>;
  /** delete single row from the table: "MaintenanceCoating" */
  delete_MaintenanceCoating_by_pk?: Maybe<MaintenanceCoating>;
  /** delete data from the table: "ManufacturedProduct" */
  delete_ManufacturedProduct?: Maybe<ManufacturedProduct_Mutation_Response>;
  /** delete single row from the table: "ManufacturedProduct" */
  delete_ManufacturedProduct_by_pk?: Maybe<ManufacturedProduct>;
  /** delete data from the table: "Manufacturer" */
  delete_Manufacturer?: Maybe<Manufacturer_Mutation_Response>;
  /** delete data from the table: "ManufacturerChangeHistory" */
  delete_ManufacturerChangeHistory?: Maybe<ManufacturerChangeHistory_Mutation_Response>;
  /** delete single row from the table: "ManufacturerChangeHistory" */
  delete_ManufacturerChangeHistory_by_pk?: Maybe<ManufacturerChangeHistory>;
  /** delete data from the table: "ManufacturerList" */
  delete_ManufacturerList?: Maybe<ManufacturerList_Mutation_Response>;
  /** delete single row from the table: "Manufacturer" */
  delete_Manufacturer_by_pk?: Maybe<Manufacturer>;
  /** delete data from the table: "MultiplyCoverConfig" */
  delete_MultiplyCoverConfig?: Maybe<MultiplyCoverConfig_Mutation_Response>;
  /** delete single row from the table: "MultiplyCoverConfig" */
  delete_MultiplyCoverConfig_by_pk?: Maybe<MultiplyCoverConfig>;
  /** delete data from the table: "MultiplyCoverSubassemblyUsage" */
  delete_MultiplyCoverSubassemblyUsage?: Maybe<MultiplyCoverSubassemblyUsage_Mutation_Response>;
  /** delete single row from the table: "MultiplyCoverSubassemblyUsage" */
  delete_MultiplyCoverSubassemblyUsage_by_pk?: Maybe<MultiplyCoverSubassemblyUsage>;
  /** delete data from the table: "NavAssembly" */
  delete_NavAssembly?: Maybe<NavAssembly_Mutation_Response>;
  /** delete data from the table: "NavAssemblyMultiplyInfo" */
  delete_NavAssemblyMultiplyInfo?: Maybe<NavAssemblyMultiplyInfo_Mutation_Response>;
  /** delete single row from the table: "NavAssembly" */
  delete_NavAssembly_by_pk?: Maybe<NavAssembly>;
  /** delete data from the table: "NavLayer" */
  delete_NavLayer?: Maybe<NavLayer_Mutation_Response>;
  /** delete single row from the table: "NavLayer" */
  delete_NavLayer_by_pk?: Maybe<NavLayer>;
  /** delete data from the table: "NavMultiplyCoverConfig" */
  delete_NavMultiplyCoverConfig?: Maybe<NavMultiplyCoverConfig_Mutation_Response>;
  /** delete single row from the table: "NavMultiplyCoverConfig" */
  delete_NavMultiplyCoverConfig_by_pk?: Maybe<NavMultiplyCoverConfig>;
  /** delete data from the table: "NavSecurementLayer" */
  delete_NavSecurementLayer?: Maybe<NavSecurementLayer_Mutation_Response>;
  /** delete single row from the table: "NavSecurementLayer" */
  delete_NavSecurementLayer_by_pk?: Maybe<NavSecurementLayer>;
  /** delete data from the table: "NavSecurementUsage" */
  delete_NavSecurementUsage?: Maybe<NavSecurementUsage_Mutation_Response>;
  /** delete single row from the table: "NavSecurementUsage" */
  delete_NavSecurementUsage_by_pk?: Maybe<NavSecurementUsage>;
  /** delete data from the table: "NavUsage" */
  delete_NavUsage?: Maybe<NavUsage_Mutation_Response>;
  /** delete data from the table: "NavUsageSA" */
  delete_NavUsageSA?: Maybe<NavUsageSa_Mutation_Response>;
  /** delete single row from the table: "NavUsageSA" */
  delete_NavUsageSA_by_pk?: Maybe<NavUsageSa>;
  /** delete single row from the table: "NavUsage" */
  delete_NavUsage_by_pk?: Maybe<NavUsage>;
  /** delete data from the table: "Nav_SinglePlyBondingAdhesives" */
  delete_Nav_SinglePlyBondingAdhesives?: Maybe<Nav_SinglePlyBondingAdhesives_Mutation_Response>;
  /** delete data from the table: "Nav_SinglePlyLapAdhesives" */
  delete_Nav_SinglePlyLapAdhesives?: Maybe<Nav_SinglePlyLapAdhesives_Mutation_Response>;
  /** delete data from the table: "NextAssemblyNum" */
  delete_NextAssemblyNum?: Maybe<NextAssemblyNum_Mutation_Response>;
  /** delete single row from the table: "NextAssemblyNum" */
  delete_NextAssemblyNum_by_pk?: Maybe<NextAssemblyNum>;
  /** delete data from the table: "Note" */
  delete_Note?: Maybe<Note_Mutation_Response>;
  /** delete single row from the table: "Note" */
  delete_Note_by_pk?: Maybe<Note>;
  /** delete data from the table: "OtherPly" */
  delete_OtherPly?: Maybe<OtherPly_Mutation_Response>;
  /** delete data from the table: "OtherPlyUsage" */
  delete_OtherPlyUsage?: Maybe<OtherPlyUsage_Mutation_Response>;
  /** delete single row from the table: "OtherPlyUsage" */
  delete_OtherPlyUsage_by_pk?: Maybe<OtherPlyUsage>;
  /** delete single row from the table: "OtherPly" */
  delete_OtherPly_by_pk?: Maybe<OtherPly>;
  /** delete data from the table: "PreassembledFastening" */
  delete_PreassembledFastening?: Maybe<PreassembledFastening_Mutation_Response>;
  /** delete data from the table: "PreassembledFasteningUsage" */
  delete_PreassembledFasteningUsage?: Maybe<PreassembledFasteningUsage_Mutation_Response>;
  /** delete single row from the table: "PreassembledFasteningUsage" */
  delete_PreassembledFasteningUsage_by_pk?: Maybe<PreassembledFasteningUsage>;
  /** delete single row from the table: "PreassembledFastening" */
  delete_PreassembledFastening_by_pk?: Maybe<PreassembledFastening>;
  /** delete data from the table: "PrivateLabel" */
  delete_PrivateLabel?: Maybe<PrivateLabel_Mutation_Response>;
  /** delete single row from the table: "PrivateLabel" */
  delete_PrivateLabel_by_pk?: Maybe<PrivateLabel>;
  /** delete data from the table: "PrivateLabeler" */
  delete_PrivateLabeler?: Maybe<PrivateLabeler_Mutation_Response>;
  /** delete data from the table: "ProductDependencies" */
  delete_ProductDependencies?: Maybe<ProductDependencies_Mutation_Response>;
  /** delete single row from the table: "ProductDependencies" */
  delete_ProductDependencies_by_pk?: Maybe<ProductDependencies>;
  /** delete data from the table: "ProductsBySubcategory" */
  delete_ProductsBySubcategory?: Maybe<ProductsBySubcategory_Mutation_Response>;
  /** delete data from the table: "R_AdhesiveTapeWidth" */
  delete_R_AdhesiveTapeWidth?: Maybe<R_AdhesiveTapeWidth_Mutation_Response>;
  /** delete single row from the table: "R_AdhesiveTapeWidth" */
  delete_R_AdhesiveTapeWidth_by_pk?: Maybe<R_AdhesiveTapeWidth>;
  /** delete data from the table: "R_ApprovedUse_Subcategory" */
  delete_R_ApprovedUse_Subcategory?: Maybe<R_ApprovedUse_Subcategory_Mutation_Response>;
  /** delete single row from the table: "R_ApprovedUse_Subcategory" */
  delete_R_ApprovedUse_Subcategory_by_pk?: Maybe<R_ApprovedUse_Subcategory>;
  /** delete data from the table: "R_ComponentType_ApprovedUse" */
  delete_R_ComponentType_ApprovedUse?: Maybe<R_ComponentType_ApprovedUse_Mutation_Response>;
  /** delete single row from the table: "R_ComponentType_ApprovedUse" */
  delete_R_ComponentType_ApprovedUse_by_pk?: Maybe<R_ComponentType_ApprovedUse>;
  /** delete data from the table: "R_CopingFastener" */
  delete_R_CopingFastener?: Maybe<R_CopingFastener_Mutation_Response>;
  /** delete single row from the table: "R_CopingFastener" */
  delete_R_CopingFastener_by_pk?: Maybe<R_CopingFastener>;
  /** delete data from the table: "R_Country_StateProvince" */
  delete_R_Country_StateProvince?: Maybe<R_Country_StateProvince_Mutation_Response>;
  /** delete single row from the table: "R_Country_StateProvince" */
  delete_R_Country_StateProvince_by_pk?: Maybe<R_Country_StateProvince>;
  /** delete data from the table: "R_ExpansionJoinFastener" */
  delete_R_ExpansionJoinFastener?: Maybe<R_ExpansionJoinFastener_Mutation_Response>;
  /** delete single row from the table: "R_ExpansionJoinFastener" */
  delete_R_ExpansionJoinFastener_by_pk?: Maybe<R_ExpansionJoinFastener>;
  /** delete data from the table: "R_FasciaComponentFastenerComponent" */
  delete_R_FasciaComponentFastenerComponent?: Maybe<R_FasciaComponentFastenerComponent_Mutation_Response>;
  /** delete single row from the table: "R_FasciaComponentFastenerComponent" */
  delete_R_FasciaComponentFastenerComponent_by_pk?: Maybe<R_FasciaComponentFastenerComponent>;
  /** delete data from the table: "R_GutterFasteners" */
  delete_R_GutterFasteners?: Maybe<R_GutterFasteners_Mutation_Response>;
  /** delete single row from the table: "R_GutterFasteners" */
  delete_R_GutterFasteners_by_pk?: Maybe<R_GutterFasteners>;
  /** delete data from the table: "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
  delete_R_LinerPanelMaterialFiberReinforcedPlasticDeck?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Mutation_Response>;
  /** delete single row from the table: "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
  delete_R_LinerPanelMaterialFiberReinforcedPlasticDeck_by_pk?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck>;
  /** delete data from the table: "R_MaintenanceCoatingSubstrate" */
  delete_R_MaintenanceCoatingSubstrate?: Maybe<R_MaintenanceCoatingSubstrate_Mutation_Response>;
  /** delete single row from the table: "R_MaintenanceCoatingSubstrate" */
  delete_R_MaintenanceCoatingSubstrate_by_pk?: Maybe<R_MaintenanceCoatingSubstrate>;
  /** delete data from the table: "R_NavAssembly_NavMultiplyCoverConfig" */
  delete_R_NavAssembly_NavMultiplyCoverConfig?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Mutation_Response>;
  /** delete single row from the table: "R_NavAssembly_NavMultiplyCoverConfig" */
  delete_R_NavAssembly_NavMultiplyCoverConfig_by_pk?: Maybe<R_NavAssembly_NavMultiplyCoverConfig>;
  /** delete data from the table: "R_ProjectContact" */
  delete_R_ProjectContact?: Maybe<R_ProjectContact_Mutation_Response>;
  /** delete single row from the table: "R_ProjectContact" */
  delete_R_ProjectContact_by_pk?: Maybe<R_ProjectContact>;
  /** delete data from the table: "R_ProjectNote" */
  delete_R_ProjectNote?: Maybe<R_ProjectNote_Mutation_Response>;
  /** delete single row from the table: "R_ProjectNote" */
  delete_R_ProjectNote_by_pk?: Maybe<R_ProjectNote>;
  /** delete data from the table: "R_RegletFastener" */
  delete_R_RegletFastener?: Maybe<R_RegletFastener_Mutation_Response>;
  /** delete single row from the table: "R_RegletFastener" */
  delete_R_RegletFastener_by_pk?: Maybe<R_RegletFastener>;
  /** delete data from the table: "R_RoofAreaContact" */
  delete_R_RoofAreaContact?: Maybe<R_RoofAreaContact_Mutation_Response>;
  /** delete single row from the table: "R_RoofAreaContact" */
  delete_R_RoofAreaContact_by_pk?: Maybe<R_RoofAreaContact>;
  /** delete data from the table: "R_RoofAreaNote" */
  delete_R_RoofAreaNote?: Maybe<R_RoofAreaNote_Mutation_Response>;
  /** delete single row from the table: "R_RoofAreaNote" */
  delete_R_RoofAreaNote_by_pk?: Maybe<R_RoofAreaNote>;
  /** delete data from the table: "R_RoofAreaScenario" */
  delete_R_RoofAreaScenario?: Maybe<R_RoofAreaScenario_Mutation_Response>;
  /** delete single row from the table: "R_RoofAreaScenario" */
  delete_R_RoofAreaScenario_by_pk?: Maybe<R_RoofAreaScenario>;
  /** delete data from the table: "R_ScenarioNote" */
  delete_R_ScenarioNote?: Maybe<R_ScenarioNote_Mutation_Response>;
  /** delete single row from the table: "R_ScenarioNote" */
  delete_R_ScenarioNote_by_pk?: Maybe<R_ScenarioNote>;
  /** delete data from the table: "R_SinglePly_BondingAdhesives" */
  delete_R_SinglePly_BondingAdhesives?: Maybe<R_SinglePly_BondingAdhesives_Mutation_Response>;
  /** delete single row from the table: "R_SinglePly_BondingAdhesives" */
  delete_R_SinglePly_BondingAdhesives_by_pk?: Maybe<R_SinglePly_BondingAdhesives>;
  /** delete data from the table: "R_SinglePly_LapAdhesives" */
  delete_R_SinglePly_LapAdhesives?: Maybe<R_SinglePly_LapAdhesives_Mutation_Response>;
  /** delete single row from the table: "R_SinglePly_LapAdhesives" */
  delete_R_SinglePly_LapAdhesives_by_pk?: Maybe<R_SinglePly_LapAdhesives>;
  /** delete data from the table: "R_Subassembly_MultiplyCoverConfig" */
  delete_R_Subassembly_MultiplyCoverConfig?: Maybe<R_Subassembly_MultiplyCoverConfig_Mutation_Response>;
  /** delete single row from the table: "R_Subassembly_MultiplyCoverConfig" */
  delete_R_Subassembly_MultiplyCoverConfig_by_pk?: Maybe<R_Subassembly_MultiplyCoverConfig>;
  /** delete data from the table: "R_UserContact" */
  delete_R_UserContact?: Maybe<R_UserContact_Mutation_Response>;
  /** delete single row from the table: "R_UserContact" */
  delete_R_UserContact_by_pk?: Maybe<R_UserContact>;
  /** delete data from the table: "Reglet" */
  delete_Reglet?: Maybe<Reglet_Mutation_Response>;
  /** delete single row from the table: "Reglet" */
  delete_Reglet_by_pk?: Maybe<Reglet>;
  /** delete data from the table: "ReviewerWorkspace" */
  delete_ReviewerWorkspace?: Maybe<ReviewerWorkspace_Mutation_Response>;
  /** delete data from the table: "ReviewerWorkspaceData" */
  delete_ReviewerWorkspaceData?: Maybe<ReviewerWorkspaceData_Mutation_Response>;
  /** delete single row from the table: "ReviewerWorkspaceData" */
  delete_ReviewerWorkspaceData_by_pk?: Maybe<ReviewerWorkspaceData>;
  /** delete single row from the table: "ReviewerWorkspace" */
  delete_ReviewerWorkspace_by_pk?: Maybe<ReviewerWorkspace>;
  /** delete data from the table: "RoofArea" */
  delete_RoofArea?: Maybe<RoofArea_Mutation_Response>;
  /** delete data from the table: "RoofAreaWindPressure" */
  delete_RoofAreaWindPressure?: Maybe<RoofAreaWindPressure_Mutation_Response>;
  /** delete single row from the table: "RoofAreaWindPressure" */
  delete_RoofAreaWindPressure_by_pk?: Maybe<RoofAreaWindPressure>;
  /** delete single row from the table: "RoofArea" */
  delete_RoofArea_by_pk?: Maybe<RoofArea>;
  /** delete data from the table: "RoofAssembly" */
  delete_RoofAssembly?: Maybe<RoofAssembly_Mutation_Response>;
  /** delete data from the table: "RoofAssemblyDelistData" */
  delete_RoofAssemblyDelistData?: Maybe<RoofAssemblyDelistData_Mutation_Response>;
  /** delete data from the table: "RoofAssemblyLayers" */
  delete_RoofAssemblyLayers?: Maybe<RoofAssemblyLayers_Mutation_Response>;
  /** delete single row from the table: "RoofAssemblyLayers" */
  delete_RoofAssemblyLayers_by_pk?: Maybe<RoofAssemblyLayers>;
  /** delete single row from the table: "RoofAssembly" */
  delete_RoofAssembly_by_pk?: Maybe<RoofAssembly>;
  /** delete data from the table: "RoofProject" */
  delete_RoofProject?: Maybe<RoofProject_Mutation_Response>;
  /** delete single row from the table: "RoofProject" */
  delete_RoofProject_by_pk?: Maybe<RoofProject>;
  /** delete data from the table: "Scenario" */
  delete_Scenario?: Maybe<Scenario_Mutation_Response>;
  /** delete data from the table: "ScenarioAlert" */
  delete_ScenarioAlert?: Maybe<ScenarioAlert_Mutation_Response>;
  /** delete single row from the table: "ScenarioAlert" */
  delete_ScenarioAlert_by_pk?: Maybe<ScenarioAlert>;
  /** delete data from the table: "ScenarioLayer" */
  delete_ScenarioLayer?: Maybe<ScenarioLayer_Mutation_Response>;
  /** delete single row from the table: "ScenarioLayer" */
  delete_ScenarioLayer_by_pk?: Maybe<ScenarioLayer>;
  /** delete data from the table: "ScenarioUsage" */
  delete_ScenarioUsage?: Maybe<ScenarioUsage_Mutation_Response>;
  /** delete single row from the table: "ScenarioUsage" */
  delete_ScenarioUsage_by_pk?: Maybe<ScenarioUsage>;
  /** delete single row from the table: "Scenario" */
  delete_Scenario_by_pk?: Maybe<Scenario>;
  /** delete data from the table: "SeamPrimer" */
  delete_SeamPrimer?: Maybe<SeamPrimer_Mutation_Response>;
  /** delete data from the table: "SeamPrimerUsage" */
  delete_SeamPrimerUsage?: Maybe<SeamPrimerUsage_Mutation_Response>;
  /** delete single row from the table: "SeamPrimerUsage" */
  delete_SeamPrimerUsage_by_pk?: Maybe<SeamPrimerUsage>;
  /** delete single row from the table: "SeamPrimer" */
  delete_SeamPrimer_by_pk?: Maybe<SeamPrimer>;
  /** delete data from the table: "SeamTapeSystemUsage" */
  delete_SeamTapeSystemUsage?: Maybe<SeamTapeSystemUsage_Mutation_Response>;
  /** delete single row from the table: "SeamTapeSystemUsage" */
  delete_SeamTapeSystemUsage_by_pk?: Maybe<SeamTapeSystemUsage>;
  /** delete data from the table: "SeamWash" */
  delete_SeamWash?: Maybe<SeamWash_Mutation_Response>;
  /** delete data from the table: "SeamWashUsage" */
  delete_SeamWashUsage?: Maybe<SeamWashUsage_Mutation_Response>;
  /** delete single row from the table: "SeamWashUsage" */
  delete_SeamWashUsage_by_pk?: Maybe<SeamWashUsage>;
  /** delete single row from the table: "SeamWash" */
  delete_SeamWash_by_pk?: Maybe<SeamWash>;
  /** delete data from the table: "SearchNavAssembly_ManufacturerProduct" */
  delete_SearchNavAssembly_ManufacturerProduct?: Maybe<SearchNavAssembly_ManufacturerProduct_Mutation_Response>;
  /** delete single row from the table: "SearchNavAssembly_ManufacturerProduct" */
  delete_SearchNavAssembly_ManufacturerProduct_by_pk?: Maybe<SearchNavAssembly_ManufacturerProduct>;
  /** delete data from the table: "SecurementLayer" */
  delete_SecurementLayer?: Maybe<SecurementLayer_Mutation_Response>;
  /** delete data from the table: "SecurementLayerUsage" */
  delete_SecurementLayerUsage?: Maybe<SecurementLayerUsage_Mutation_Response>;
  /** delete single row from the table: "SecurementLayerUsage" */
  delete_SecurementLayerUsage_by_pk?: Maybe<SecurementLayerUsage>;
  /** delete single row from the table: "SecurementLayer" */
  delete_SecurementLayer_by_pk?: Maybe<SecurementLayer>;
  /** delete data from the table: "SelfSecurementUsage" */
  delete_SelfSecurementUsage?: Maybe<SelfSecurementUsage_Mutation_Response>;
  /** delete single row from the table: "SelfSecurementUsage" */
  delete_SelfSecurementUsage_by_pk?: Maybe<SelfSecurementUsage>;
  /** delete data from the table: "SeparatorSheet" */
  delete_SeparatorSheet?: Maybe<SeparatorSheet_Mutation_Response>;
  /** delete data from the table: "SeparatorSheetUsage" */
  delete_SeparatorSheetUsage?: Maybe<SeparatorSheetUsage_Mutation_Response>;
  /** delete single row from the table: "SeparatorSheetUsage" */
  delete_SeparatorSheetUsage_by_pk?: Maybe<SeparatorSheetUsage>;
  /** delete single row from the table: "SeparatorSheet" */
  delete_SeparatorSheet_by_pk?: Maybe<SeparatorSheet>;
  /** delete data from the table: "Shingles" */
  delete_Shingles?: Maybe<Shingles_Mutation_Response>;
  /** delete single row from the table: "Shingles" */
  delete_Shingles_by_pk?: Maybe<Shingles>;
  /** delete data from the table: "SinglePlyCover" */
  delete_SinglePlyCover?: Maybe<SinglePlyCover_Mutation_Response>;
  /** delete single row from the table: "SinglePlyCover" */
  delete_SinglePlyCover_by_pk?: Maybe<SinglePlyCover>;
  /** delete data from the table: "SinglePlyUsage" */
  delete_SinglePlyUsage?: Maybe<SinglePlyUsage_Mutation_Response>;
  /** delete single row from the table: "SinglePlyUsage" */
  delete_SinglePlyUsage_by_pk?: Maybe<SinglePlyUsage>;
  /** delete data from the table: "SprayFoamInsulationUsage" */
  delete_SprayFoamInsulationUsage?: Maybe<SprayFoamInsulationUsage_Mutation_Response>;
  /** delete single row from the table: "SprayFoamInsulationUsage" */
  delete_SprayFoamInsulationUsage_by_pk?: Maybe<SprayFoamInsulationUsage>;
  /** delete data from the table: "SprayedFoamInsulation" */
  delete_SprayedFoamInsulation?: Maybe<SprayedFoamInsulation_Mutation_Response>;
  /** delete single row from the table: "SprayedFoamInsulation" */
  delete_SprayedFoamInsulation_by_pk?: Maybe<SprayedFoamInsulation>;
  /** delete data from the table: "StandingLapSeamCover" */
  delete_StandingLapSeamCover?: Maybe<StandingLapSeamCover_Mutation_Response>;
  /** delete single row from the table: "StandingLapSeamCover" */
  delete_StandingLapSeamCover_by_pk?: Maybe<StandingLapSeamCover>;
  /** delete data from the table: "StandingLapSeamUsage" */
  delete_StandingLapSeamUsage?: Maybe<StandingLapSeamUsage_Mutation_Response>;
  /** delete single row from the table: "StandingLapSeamUsage" */
  delete_StandingLapSeamUsage_by_pk?: Maybe<StandingLapSeamUsage>;
  /** delete data from the table: "SteelDeck" */
  delete_SteelDeck?: Maybe<SteelDeck_Mutation_Response>;
  /** delete data from the table: "SteelDeckLayer" */
  delete_SteelDeckLayer?: Maybe<SteelDeckLayer_Mutation_Response>;
  /** delete single row from the table: "SteelDeckLayer" */
  delete_SteelDeckLayer_by_pk?: Maybe<SteelDeckLayer>;
  /** delete data from the table: "SteelDeckUsage" */
  delete_SteelDeckUsage?: Maybe<SteelDeckUsage_Mutation_Response>;
  /** delete single row from the table: "SteelDeckUsage" */
  delete_SteelDeckUsage_by_pk?: Maybe<SteelDeckUsage>;
  /** delete single row from the table: "SteelDeck" */
  delete_SteelDeck_by_pk?: Maybe<SteelDeck>;
  /** delete data from the table: "StressPlate" */
  delete_StressPlate?: Maybe<StressPlate_Mutation_Response>;
  /** delete data from the table: "StressPlateUsage" */
  delete_StressPlateUsage?: Maybe<StressPlateUsage_Mutation_Response>;
  /** delete single row from the table: "StressPlateUsage" */
  delete_StressPlateUsage_by_pk?: Maybe<StressPlateUsage>;
  /** delete single row from the table: "StressPlate" */
  delete_StressPlate_by_pk?: Maybe<StressPlate>;
  /** delete data from the table: "StructuralConcreteLayer" */
  delete_StructuralConcreteLayer?: Maybe<StructuralConcreteLayer_Mutation_Response>;
  /** delete single row from the table: "StructuralConcreteLayer" */
  delete_StructuralConcreteLayer_by_pk?: Maybe<StructuralConcreteLayer>;
  /** delete data from the table: "StructureLayer" */
  delete_StructureLayer?: Maybe<StructureLayer_Mutation_Response>;
  /** delete single row from the table: "StructureLayer" */
  delete_StructureLayer_by_pk?: Maybe<StructureLayer>;
  /** delete data from the table: "StructuredSystem" */
  delete_StructuredSystem?: Maybe<StructuredSystem_Mutation_Response>;
  /** delete data from the table: "StructuredSystemProducts" */
  delete_StructuredSystemProducts?: Maybe<StructuredSystemProducts_Mutation_Response>;
  /** delete single row from the table: "StructuredSystemProducts" */
  delete_StructuredSystemProducts_by_pk?: Maybe<StructuredSystemProducts>;
  /** delete single row from the table: "StructuredSystem" */
  delete_StructuredSystem_by_pk?: Maybe<StructuredSystem>;
  /** delete data from the table: "Subassembly" */
  delete_Subassembly?: Maybe<Subassembly_Mutation_Response>;
  /** delete data from the table: "SubassemblyDelistData" */
  delete_SubassemblyDelistData?: Maybe<SubassemblyDelistData_Mutation_Response>;
  /** delete data from the table: "SubassemblyE108Rating" */
  delete_SubassemblyE108Rating?: Maybe<SubassemblyE108Rating_Mutation_Response>;
  /** delete single row from the table: "SubassemblyE108Rating" */
  delete_SubassemblyE108Rating_by_pk?: Maybe<SubassemblyE108Rating>;
  /** delete data from the table: "SubassemblyLayers" */
  delete_SubassemblyLayers?: Maybe<SubassemblyLayers_Mutation_Response>;
  /** delete single row from the table: "SubassemblyLayers" */
  delete_SubassemblyLayers_by_pk?: Maybe<SubassemblyLayers>;
  /** delete data from the table: "SubassemblyMultiplyConfig" */
  delete_SubassemblyMultiplyConfig?: Maybe<SubassemblyMultiplyConfig_Mutation_Response>;
  /** delete single row from the table: "SubassemblyMultiplyConfig" */
  delete_SubassemblyMultiplyConfig_by_pk?: Maybe<SubassemblyMultiplyConfig>;
  /** delete single row from the table: "Subassembly" */
  delete_Subassembly_by_pk?: Maybe<Subassembly>;
  /** delete data from the table: "Subcat_Adhesive" */
  delete_Subcat_Adhesive?: Maybe<Subcat_Adhesive_Mutation_Response>;
  /** delete data from the table: "Subcat_AirRetarder" */
  delete_Subcat_AirRetarder?: Maybe<Subcat_AirRetarder_Mutation_Response>;
  /** delete data from the table: "Subcat_BaseSheet" */
  delete_Subcat_BaseSheet?: Maybe<Subcat_BaseSheet_Mutation_Response>;
  /** delete data from the table: "Subcat_BattBlanket" */
  delete_Subcat_BattBlanket?: Maybe<Subcat_BattBlanket_Mutation_Response>;
  /** delete data from the table: "Subcat_BoardStock" */
  delete_Subcat_BoardStock?: Maybe<Subcat_BoardStock_Mutation_Response>;
  /** delete data from the table: "Subcat_Coating" */
  delete_Subcat_Coating?: Maybe<Subcat_Coating_Mutation_Response>;
  /** delete data from the table: "Subcat_CompositePanel" */
  delete_Subcat_CompositePanel?: Maybe<Subcat_CompositePanel_Mutation_Response>;
  /** delete data from the table: "Subcat_CoverStrip" */
  delete_Subcat_CoverStrip?: Maybe<Subcat_CoverStrip_Mutation_Response>;
  /** delete data from the table: "Subcat_ExpJoint" */
  delete_Subcat_ExpJoint?: Maybe<Subcat_ExpJoint_Mutation_Response>;
  /** delete data from the table: "Subcat_Fastening" */
  delete_Subcat_Fastening?: Maybe<Subcat_Fastening_Mutation_Response>;
  /** delete data from the table: "Subcat_FormDeck" */
  delete_Subcat_FormDeck?: Maybe<Subcat_FormDeck_Mutation_Response>;
  /** delete data from the table: "Subcat_Gutter" */
  delete_Subcat_Gutter?: Maybe<Subcat_Gutter_Mutation_Response>;
  /** delete data from the table: "Subcat_Gypsum" */
  delete_Subcat_Gypsum?: Maybe<Subcat_Gypsum_Mutation_Response>;
  /** delete data from the table: "Subcat_LWIC" */
  delete_Subcat_LWIC?: Maybe<Subcat_Lwic_Mutation_Response>;
  /** delete data from the table: "Subcat_LWICDeck" */
  delete_Subcat_LWICDeck?: Maybe<Subcat_LwicDeck_Mutation_Response>;
  /** delete data from the table: "Subcat_LinerPanel" */
  delete_Subcat_LinerPanel?: Maybe<Subcat_LinerPanel_Mutation_Response>;
  /** delete data from the table: "Subcat_LiquidApplied" */
  delete_Subcat_LiquidApplied?: Maybe<Subcat_LiquidApplied_Mutation_Response>;
  /** delete data from the table: "Subcat_MaintCoating" */
  delete_Subcat_MaintCoating?: Maybe<Subcat_MaintCoating_Mutation_Response>;
  /** delete data from the table: "Subcat_Multiply" */
  delete_Subcat_Multiply?: Maybe<Subcat_Multiply_Mutation_Response>;
  /** delete data from the table: "Subcat_MultiplyCover" */
  delete_Subcat_MultiplyCover?: Maybe<Subcat_MultiplyCover_Mutation_Response>;
  /** delete data from the table: "Subcat_PerimeterFlashing" */
  delete_Subcat_PerimeterFlashing?: Maybe<Subcat_PerimeterFlashing_Mutation_Response>;
  /** delete data from the table: "Subcat_PlasticDeck" */
  delete_Subcat_PlasticDeck?: Maybe<Subcat_PlasticDeck_Mutation_Response>;
  /** delete data from the table: "Subcat_Primer" */
  delete_Subcat_Primer?: Maybe<Subcat_Primer_Mutation_Response>;
  /** delete data from the table: "Subcat_Seaming" */
  delete_Subcat_Seaming?: Maybe<Subcat_Seaming_Mutation_Response>;
  /** delete data from the table: "Subcat_SeparatorSheet" */
  delete_Subcat_SeparatorSheet?: Maybe<Subcat_SeparatorSheet_Mutation_Response>;
  /** delete data from the table: "Subcat_Shingles" */
  delete_Subcat_Shingles?: Maybe<Subcat_Shingles_Mutation_Response>;
  /** delete data from the table: "Subcat_SinglePly" */
  delete_Subcat_SinglePly?: Maybe<Subcat_SinglePly_Mutation_Response>;
  /** delete data from the table: "Subcat_SinglePlyCover" */
  delete_Subcat_SinglePlyCover?: Maybe<Subcat_SinglePlyCover_Mutation_Response>;
  /** delete data from the table: "Subcat_SprayFoam" */
  delete_Subcat_SprayFoam?: Maybe<Subcat_SprayFoam_Mutation_Response>;
  /** delete data from the table: "Subcat_StandingLapSeam" */
  delete_Subcat_StandingLapSeam?: Maybe<Subcat_StandingLapSeam_Mutation_Response>;
  /** delete data from the table: "Subcat_SteelDeck" */
  delete_Subcat_SteelDeck?: Maybe<Subcat_SteelDeck_Mutation_Response>;
  /** delete data from the table: "Subcat_Surfacing" */
  delete_Subcat_Surfacing?: Maybe<Subcat_Surfacing_Mutation_Response>;
  /** delete data from the table: "Subcat_VaporRetarder" */
  delete_Subcat_VaporRetarder?: Maybe<Subcat_VaporRetarder_Mutation_Response>;
  /** delete data from the table: "Subcat_Weld" */
  delete_Subcat_Weld?: Maybe<Subcat_Weld_Mutation_Response>;
  /** delete data from the table: "Subcat_Wood" */
  delete_Subcat_Wood?: Maybe<Subcat_Wood_Mutation_Response>;
  /** delete data from the table: "Subcat_WoodFiberDeck" */
  delete_Subcat_WoodFiberDeck?: Maybe<Subcat_WoodFiberDeck_Mutation_Response>;
  /** delete data from the table: "SubstratePrimer" */
  delete_SubstratePrimer?: Maybe<SubstratePrimer_Mutation_Response>;
  /** delete data from the table: "SubstratePrimerUsage" */
  delete_SubstratePrimerUsage?: Maybe<SubstratePrimerUsage_Mutation_Response>;
  /** delete single row from the table: "SubstratePrimerUsage" */
  delete_SubstratePrimerUsage_by_pk?: Maybe<SubstratePrimerUsage>;
  /** delete single row from the table: "SubstratePrimer" */
  delete_SubstratePrimer_by_pk?: Maybe<SubstratePrimer>;
  /** delete data from the table: "Surfacing" */
  delete_Surfacing?: Maybe<Surfacing_Mutation_Response>;
  /** delete data from the table: "SurfacingUsage" */
  delete_SurfacingUsage?: Maybe<SurfacingUsage_Mutation_Response>;
  /** delete single row from the table: "SurfacingUsage" */
  delete_SurfacingUsage_by_pk?: Maybe<SurfacingUsage>;
  /** delete single row from the table: "Surfacing" */
  delete_Surfacing_by_pk?: Maybe<Surfacing>;
  /** delete data from the table: "TapeWidth" */
  delete_TapeWidth?: Maybe<TapeWidth_Mutation_Response>;
  /** delete single row from the table: "TapeWidth" */
  delete_TapeWidth_by_pk?: Maybe<TapeWidth>;
  /** delete data from the table: "ThermalBarrier" */
  delete_ThermalBarrier?: Maybe<ThermalBarrier_Mutation_Response>;
  /** delete data from the table: "ThermalBarrierBoardSizes" */
  delete_ThermalBarrierBoardSizes?: Maybe<ThermalBarrierBoardSizes_Mutation_Response>;
  /** delete single row from the table: "ThermalBarrierBoardSizes" */
  delete_ThermalBarrierBoardSizes_by_pk?: Maybe<ThermalBarrierBoardSizes>;
  /** delete data from the table: "ThermalBarrierFacerBottom" */
  delete_ThermalBarrierFacerBottom?: Maybe<ThermalBarrierFacerBottom_Mutation_Response>;
  /** delete single row from the table: "ThermalBarrierFacerBottom" */
  delete_ThermalBarrierFacerBottom_by_pk?: Maybe<ThermalBarrierFacerBottom>;
  /** delete data from the table: "ThermalBarrierFacerTop" */
  delete_ThermalBarrierFacerTop?: Maybe<ThermalBarrierFacerTop_Mutation_Response>;
  /** delete single row from the table: "ThermalBarrierFacerTop" */
  delete_ThermalBarrierFacerTop_by_pk?: Maybe<ThermalBarrierFacerTop>;
  /** delete data from the table: "ThermalBarrierUsage" */
  delete_ThermalBarrierUsage?: Maybe<ThermalBarrierUsage_Mutation_Response>;
  /** delete single row from the table: "ThermalBarrierUsage" */
  delete_ThermalBarrierUsage_by_pk?: Maybe<ThermalBarrierUsage>;
  /** delete single row from the table: "ThermalBarrier" */
  delete_ThermalBarrier_by_pk?: Maybe<ThermalBarrier>;
  /** delete data from the table: "TopCoat" */
  delete_TopCoat?: Maybe<TopCoat_Mutation_Response>;
  /** delete data from the table: "TopCoatUsage" */
  delete_TopCoatUsage?: Maybe<TopCoatUsage_Mutation_Response>;
  /** delete single row from the table: "TopCoatUsage" */
  delete_TopCoatUsage_by_pk?: Maybe<TopCoatUsage>;
  /** delete single row from the table: "TopCoat" */
  delete_TopCoat_by_pk?: Maybe<TopCoat>;
  /** delete data from the table: "Usage" */
  delete_Usage?: Maybe<Usage_Mutation_Response>;
  /** delete data from the table: "UsageTradeNameView" */
  delete_UsageTradeNameView?: Maybe<UsageTradeNameView_Mutation_Response>;
  /** delete single row from the table: "Usage" */
  delete_Usage_by_pk?: Maybe<Usage>;
  /** delete data from the table: "UserReportBug" */
  delete_UserReportBug?: Maybe<UserReportBug_Mutation_Response>;
  /** delete single row from the table: "UserReportBug" */
  delete_UserReportBug_by_pk?: Maybe<UserReportBug>;
  /** delete data from the table: "UserShareLink" */
  delete_UserShareLink?: Maybe<UserShareLink_Mutation_Response>;
  /** delete single row from the table: "UserShareLink" */
  delete_UserShareLink_by_pk?: Maybe<UserShareLink>;
  /** delete data from the table: "VaporRetarder" */
  delete_VaporRetarder?: Maybe<VaporRetarder_Mutation_Response>;
  /** delete data from the table: "VaporRetarderUsage" */
  delete_VaporRetarderUsage?: Maybe<VaporRetarderUsage_Mutation_Response>;
  /** delete single row from the table: "VaporRetarderUsage" */
  delete_VaporRetarderUsage_by_pk?: Maybe<VaporRetarderUsage>;
  /** delete single row from the table: "VaporRetarder" */
  delete_VaporRetarder_by_pk?: Maybe<VaporRetarder>;
  /** delete data from the table: "WeldArcUsage" */
  delete_WeldArcUsage?: Maybe<WeldArcUsage_Mutation_Response>;
  /** delete single row from the table: "WeldArcUsage" */
  delete_WeldArcUsage_by_pk?: Maybe<WeldArcUsage>;
  /** delete data from the table: "WeldHeatUsage" */
  delete_WeldHeatUsage?: Maybe<WeldHeatUsage_Mutation_Response>;
  /** delete single row from the table: "WeldHeatUsage" */
  delete_WeldHeatUsage_by_pk?: Maybe<WeldHeatUsage>;
  /** delete data from the table: "WeldSolventUsage" */
  delete_WeldSolventUsage?: Maybe<WeldSolventUsage_Mutation_Response>;
  /** delete single row from the table: "WeldSolventUsage" */
  delete_WeldSolventUsage_by_pk?: Maybe<WeldSolventUsage>;
  /** delete data from the table: "WeldSystemUsage" */
  delete_WeldSystemUsage?: Maybe<WeldSystemUsage_Mutation_Response>;
  /** delete single row from the table: "WeldSystemUsage" */
  delete_WeldSystemUsage_by_pk?: Maybe<WeldSystemUsage>;
  /** delete data from the table: "WeldTorchedUsage" */
  delete_WeldTorchedUsage?: Maybe<WeldTorchedUsage_Mutation_Response>;
  /** delete single row from the table: "WeldTorchedUsage" */
  delete_WeldTorchedUsage_by_pk?: Maybe<WeldTorchedUsage>;
  /** delete data from the table: "WoodDeck" */
  delete_WoodDeck?: Maybe<WoodDeck_Mutation_Response>;
  /** delete data from the table: "WoodDeckUsage" */
  delete_WoodDeckUsage?: Maybe<WoodDeckUsage_Mutation_Response>;
  /** delete single row from the table: "WoodDeckUsage" */
  delete_WoodDeckUsage_by_pk?: Maybe<WoodDeckUsage>;
  /** delete single row from the table: "WoodDeck" */
  delete_WoodDeck_by_pk?: Maybe<WoodDeck>;
  /** delete data from the table: "vNavAssembly_Layers" */
  delete_vNavAssembly_Layers?: Maybe<VNavAssembly_Layers_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_ManufacturerOnlySearch" */
  delete_vNavAssembly_ManufacturerOnlySearch?: Maybe<VNavAssembly_ManufacturerOnlySearch_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_RoofTypeCount" */
  delete_vNavAssembly_RoofTypeCount?: Maybe<VNavAssembly_RoofTypeCount_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search" */
  delete_vNavAssembly_Search?: Maybe<VNavAssembly_Search_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_AirRetarder" */
  delete_vNavAssembly_Search_AirRetarder?: Maybe<VNavAssembly_Search_AirRetarder_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_BaseCoat" */
  delete_vNavAssembly_Search_BaseCoat?: Maybe<VNavAssembly_Search_BaseCoat_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_BasePly" */
  delete_vNavAssembly_Search_BasePly?: Maybe<VNavAssembly_Search_BasePly_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_BaseSheet" */
  delete_vNavAssembly_Search_BaseSheet?: Maybe<VNavAssembly_Search_BaseSheet_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_BoardStock" */
  delete_vNavAssembly_Search_BoardStock?: Maybe<VNavAssembly_Search_BoardStock_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_CapPly" */
  delete_vNavAssembly_Search_CapPly?: Maybe<VNavAssembly_Search_CapPly_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_Coat" */
  delete_vNavAssembly_Search_Coat?: Maybe<VNavAssembly_Search_Coat_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_Coating" */
  delete_vNavAssembly_Search_Coating?: Maybe<VNavAssembly_Search_Coating_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_CoverBoard" */
  delete_vNavAssembly_Search_CoverBoard?: Maybe<VNavAssembly_Search_CoverBoard_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_CoverCompositePanel" */
  delete_vNavAssembly_Search_CoverCompositePanel?: Maybe<VNavAssembly_Search_CoverCompositePanel_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_CoverSingleply" */
  delete_vNavAssembly_Search_CoverSingleply?: Maybe<VNavAssembly_Search_CoverSingleply_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_CoverStandingLapSeam" */
  delete_vNavAssembly_Search_CoverStandingLapSeam?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_CoverStripDisk" */
  delete_vNavAssembly_Search_CoverStripDisk?: Maybe<VNavAssembly_Search_CoverStripDisk_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_DeckCementitiousPanel" */
  delete_vNavAssembly_Search_DeckCementitiousPanel?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_DeckSteel" */
  delete_vNavAssembly_Search_DeckSteel?: Maybe<VNavAssembly_Search_DeckSteel_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_FormDeck" */
  delete_vNavAssembly_Search_FormDeck?: Maybe<VNavAssembly_Search_FormDeck_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_InsulationBattBlanket" */
  delete_vNavAssembly_Search_InsulationBattBlanket?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_InsulationSprayFoam" */
  delete_vNavAssembly_Search_InsulationSprayFoam?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_LightweightInsulatingConcrete" */
  delete_vNavAssembly_Search_LightweightInsulatingConcrete?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_LinerPanel" */
  delete_vNavAssembly_Search_LinerPanel?: Maybe<VNavAssembly_Search_LinerPanel_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_Ply" */
  delete_vNavAssembly_Search_Ply?: Maybe<VNavAssembly_Search_Ply_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_SeparatorSheet" */
  delete_vNavAssembly_Search_SeparatorSheet?: Maybe<VNavAssembly_Search_SeparatorSheet_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_SubstratePrimer" */
  delete_vNavAssembly_Search_SubstratePrimer?: Maybe<VNavAssembly_Search_SubstratePrimer_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_Surfacing" */
  delete_vNavAssembly_Search_Surfacing?: Maybe<VNavAssembly_Search_Surfacing_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_ThermalBarrier" */
  delete_vNavAssembly_Search_ThermalBarrier?: Maybe<VNavAssembly_Search_ThermalBarrier_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_TopCoat" */
  delete_vNavAssembly_Search_TopCoat?: Maybe<VNavAssembly_Search_TopCoat_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Search_VaporRetarder" */
  delete_vNavAssembly_Search_VaporRetarder?: Maybe<VNavAssembly_Search_VaporRetarder_Mutation_Response>;
  /** delete data from the table: "vNavAssembly_Usages" */
  delete_vNavAssembly_Usages?: Maybe<VNavAssembly_Usages_Mutation_Response>;
  /** insert data into the table: "Address" */
  insert_Address?: Maybe<Address_Mutation_Response>;
  /** insert a single row into the table: "Address" */
  insert_Address_one?: Maybe<Address>;
  /** insert data into the table: "AdheredSinglePlyOrphanReport" */
  insert_AdheredSinglePlyOrphanReport?: Maybe<AdheredSinglePlyOrphanReport_Mutation_Response>;
  /** insert a single row into the table: "AdheredSinglePlyOrphanReport" */
  insert_AdheredSinglePlyOrphanReport_one?: Maybe<AdheredSinglePlyOrphanReport>;
  /** insert data into the table: "AdhesiveFullApplication" */
  insert_AdhesiveFullApplication?: Maybe<AdhesiveFullApplication_Mutation_Response>;
  /** insert data into the table: "AdhesiveFullApplicationUsage" */
  insert_AdhesiveFullApplicationUsage?: Maybe<AdhesiveFullApplicationUsage_Mutation_Response>;
  /** insert a single row into the table: "AdhesiveFullApplicationUsage" */
  insert_AdhesiveFullApplicationUsage_one?: Maybe<AdhesiveFullApplicationUsage>;
  /** insert a single row into the table: "AdhesiveFullApplication" */
  insert_AdhesiveFullApplication_one?: Maybe<AdhesiveFullApplication>;
  /** insert data into the table: "AdhesiveRibbonUsage" */
  insert_AdhesiveRibbonUsage?: Maybe<AdhesiveRibbonUsage_Mutation_Response>;
  /** insert a single row into the table: "AdhesiveRibbonUsage" */
  insert_AdhesiveRibbonUsage_one?: Maybe<AdhesiveRibbonUsage>;
  /** insert data into the table: "AdhesiveRibbons" */
  insert_AdhesiveRibbons?: Maybe<AdhesiveRibbons_Mutation_Response>;
  /** insert a single row into the table: "AdhesiveRibbons" */
  insert_AdhesiveRibbons_one?: Maybe<AdhesiveRibbons>;
  /** insert data into the table: "AdhesiveSpots" */
  insert_AdhesiveSpots?: Maybe<AdhesiveSpots_Mutation_Response>;
  /** insert data into the table: "AdhesiveSpotsUsage" */
  insert_AdhesiveSpotsUsage?: Maybe<AdhesiveSpotsUsage_Mutation_Response>;
  /** insert a single row into the table: "AdhesiveSpotsUsage" */
  insert_AdhesiveSpotsUsage_one?: Maybe<AdhesiveSpotsUsage>;
  /** insert a single row into the table: "AdhesiveSpots" */
  insert_AdhesiveSpots_one?: Maybe<AdhesiveSpots>;
  /** insert data into the table: "AdhesiveSystemUsage" */
  insert_AdhesiveSystemUsage?: Maybe<AdhesiveSystemUsage_Mutation_Response>;
  /** insert a single row into the table: "AdhesiveSystemUsage" */
  insert_AdhesiveSystemUsage_one?: Maybe<AdhesiveSystemUsage>;
  /** insert data into the table: "AirRetarder" */
  insert_AirRetarder?: Maybe<AirRetarder_Mutation_Response>;
  /** insert data into the table: "AirRetarderUsage" */
  insert_AirRetarderUsage?: Maybe<AirRetarderUsage_Mutation_Response>;
  /** insert a single row into the table: "AirRetarderUsage" */
  insert_AirRetarderUsage_one?: Maybe<AirRetarderUsage>;
  /** insert a single row into the table: "AirRetarder" */
  insert_AirRetarder_one?: Maybe<AirRetarder>;
  /** insert data into the table: "AllManufacturedProducts" */
  insert_AllManufacturedProducts?: Maybe<AllManufacturedProducts_Mutation_Response>;
  /** insert a single row into the table: "AllManufacturedProducts" */
  insert_AllManufacturedProducts_one?: Maybe<AllManufacturedProducts>;
  /** insert data into the table: "Anchor" */
  insert_Anchor?: Maybe<Anchor_Mutation_Response>;
  /** insert a single row into the table: "Anchor" */
  insert_Anchor_one?: Maybe<Anchor>;
  /** insert data into the table: "ApprovalsDoc" */
  insert_ApprovalsDoc?: Maybe<ApprovalsDoc_Mutation_Response>;
  /** insert a single row into the table: "ApprovalsDoc" */
  insert_ApprovalsDoc_one?: Maybe<ApprovalsDoc>;
  /** insert data into the table: "ApprovedLayerUse" */
  insert_ApprovedLayerUse?: Maybe<ApprovedLayerUse_Mutation_Response>;
  /** insert a single row into the table: "ApprovedLayerUse" */
  insert_ApprovedLayerUse_one?: Maybe<ApprovedLayerUse>;
  /** insert data into the table: "AssemblyReconfigQueue" */
  insert_AssemblyReconfigQueue?: Maybe<AssemblyReconfigQueue_Mutation_Response>;
  /** insert a single row into the table: "AssemblyReconfigQueue" */
  insert_AssemblyReconfigQueue_one?: Maybe<AssemblyReconfigQueue>;
  /** insert data into the table: "BaseCoat" */
  insert_BaseCoat?: Maybe<BaseCoat_Mutation_Response>;
  /** insert data into the table: "BaseCoatUsage" */
  insert_BaseCoatUsage?: Maybe<BaseCoatUsage_Mutation_Response>;
  /** insert a single row into the table: "BaseCoatUsage" */
  insert_BaseCoatUsage_one?: Maybe<BaseCoatUsage>;
  /** insert a single row into the table: "BaseCoat" */
  insert_BaseCoat_one?: Maybe<BaseCoat>;
  /** insert data into the table: "BasePly" */
  insert_BasePly?: Maybe<BasePly_Mutation_Response>;
  /** insert data into the table: "BasePlyUsage" */
  insert_BasePlyUsage?: Maybe<BasePlyUsage_Mutation_Response>;
  /** insert a single row into the table: "BasePlyUsage" */
  insert_BasePlyUsage_one?: Maybe<BasePlyUsage>;
  /** insert a single row into the table: "BasePly" */
  insert_BasePly_one?: Maybe<BasePly>;
  /** insert data into the table: "BaseSheet" */
  insert_BaseSheet?: Maybe<BaseSheet_Mutation_Response>;
  /** insert data into the table: "BaseSheetUsage" */
  insert_BaseSheetUsage?: Maybe<BaseSheetUsage_Mutation_Response>;
  /** insert a single row into the table: "BaseSheetUsage" */
  insert_BaseSheetUsage_one?: Maybe<BaseSheetUsage>;
  /** insert a single row into the table: "BaseSheet" */
  insert_BaseSheet_one?: Maybe<BaseSheet>;
  /** insert data into the table: "BattBlanketInsulation" */
  insert_BattBlanketInsulation?: Maybe<BattBlanketInsulation_Mutation_Response>;
  /** insert a single row into the table: "BattBlanketInsulation" */
  insert_BattBlanketInsulation_one?: Maybe<BattBlanketInsulation>;
  /** insert data into the table: "BattBlanketUsage" */
  insert_BattBlanketUsage?: Maybe<BattBlanketUsage_Mutation_Response>;
  /** insert a single row into the table: "BattBlanketUsage" */
  insert_BattBlanketUsage_one?: Maybe<BattBlanketUsage>;
  /** insert data into the table: "BattenBar" */
  insert_BattenBar?: Maybe<BattenBar_Mutation_Response>;
  /** insert data into the table: "BattenBarUsage" */
  insert_BattenBarUsage?: Maybe<BattenBarUsage_Mutation_Response>;
  /** insert a single row into the table: "BattenBarUsage" */
  insert_BattenBarUsage_one?: Maybe<BattenBarUsage>;
  /** insert a single row into the table: "BattenBar" */
  insert_BattenBar_one?: Maybe<BattenBar>;
  /** insert data into the table: "BoardSizes" */
  insert_BoardSizes?: Maybe<BoardSizes_Mutation_Response>;
  /** insert a single row into the table: "BoardSizes" */
  insert_BoardSizes_one?: Maybe<BoardSizes>;
  /** insert data into the table: "BoardStock" */
  insert_BoardStock?: Maybe<BoardStock_Mutation_Response>;
  /** insert data into the table: "BoardStockBoardSizes" */
  insert_BoardStockBoardSizes?: Maybe<BoardStockBoardSizes_Mutation_Response>;
  /** insert a single row into the table: "BoardStockBoardSizes" */
  insert_BoardStockBoardSizes_one?: Maybe<BoardStockBoardSizes>;
  /** insert data into the table: "BoardStockFacerBottom" */
  insert_BoardStockFacerBottom?: Maybe<BoardStockFacerBottom_Mutation_Response>;
  /** insert a single row into the table: "BoardStockFacerBottom" */
  insert_BoardStockFacerBottom_one?: Maybe<BoardStockFacerBottom>;
  /** insert data into the table: "BoardStockFacerTop" */
  insert_BoardStockFacerTop?: Maybe<BoardStockFacerTop_Mutation_Response>;
  /** insert a single row into the table: "BoardStockFacerTop" */
  insert_BoardStockFacerTop_one?: Maybe<BoardStockFacerTop>;
  /** insert data into the table: "BoardStockUsage" */
  insert_BoardStockUsage?: Maybe<BoardStockUsage_Mutation_Response>;
  /** insert a single row into the table: "BoardStockUsage" */
  insert_BoardStockUsage_one?: Maybe<BoardStockUsage>;
  /** insert a single row into the table: "BoardStock" */
  insert_BoardStock_one?: Maybe<BoardStock>;
  /** insert data into the table: "BroadcastAlert" */
  insert_BroadcastAlert?: Maybe<BroadcastAlert_Mutation_Response>;
  /** insert a single row into the table: "BroadcastAlert" */
  insert_BroadcastAlert_one?: Maybe<BroadcastAlert>;
  /** insert data into the table: "BuiltupVaporRetarderSystemUsage" */
  insert_BuiltupVaporRetarderSystemUsage?: Maybe<BuiltupVaporRetarderSystemUsage_Mutation_Response>;
  /** insert a single row into the table: "BuiltupVaporRetarderSystemUsage" */
  insert_BuiltupVaporRetarderSystemUsage_one?: Maybe<BuiltupVaporRetarderSystemUsage>;
  /** insert data into the table: "C_Additive" */
  insert_C_Additive?: Maybe<C_Additive_Mutation_Response>;
  /** insert a single row into the table: "C_Additive" */
  insert_C_Additive_one?: Maybe<C_Additive>;
  /** insert data into the table: "C_AdhesiveApplicationMethod" */
  insert_C_AdhesiveApplicationMethod?: Maybe<C_AdhesiveApplicationMethod_Mutation_Response>;
  /** insert a single row into the table: "C_AdhesiveApplicationMethod" */
  insert_C_AdhesiveApplicationMethod_one?: Maybe<C_AdhesiveApplicationMethod>;
  /** insert data into the table: "C_AdhesiveMaterial" */
  insert_C_AdhesiveMaterial?: Maybe<C_AdhesiveMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_AdhesiveMaterial" */
  insert_C_AdhesiveMaterial_one?: Maybe<C_AdhesiveMaterial>;
  /** insert data into the table: "C_AdhesiveType" */
  insert_C_AdhesiveType?: Maybe<C_AdhesiveType_Mutation_Response>;
  /** insert a single row into the table: "C_AdhesiveType" */
  insert_C_AdhesiveType_one?: Maybe<C_AdhesiveType>;
  /** insert data into the table: "C_Aggregate" */
  insert_C_Aggregate?: Maybe<C_Aggregate_Mutation_Response>;
  /** insert a single row into the table: "C_Aggregate" */
  insert_C_Aggregate_one?: Maybe<C_Aggregate>;
  /** insert data into the table: "C_ApprovalsDocCategory" */
  insert_C_ApprovalsDocCategory?: Maybe<C_ApprovalsDocCategory_Mutation_Response>;
  /** insert a single row into the table: "C_ApprovalsDocCategory" */
  insert_C_ApprovalsDocCategory_one?: Maybe<C_ApprovalsDocCategory>;
  /** insert data into the table: "C_ApprovedUse" */
  insert_C_ApprovedUse?: Maybe<C_ApprovedUse_Mutation_Response>;
  /** insert a single row into the table: "C_ApprovedUse" */
  insert_C_ApprovedUse_one?: Maybe<C_ApprovedUse>;
  /** insert data into the table: "C_AssemblyApplicationType" */
  insert_C_AssemblyApplicationType?: Maybe<C_AssemblyApplicationType_Mutation_Response>;
  /** insert a single row into the table: "C_AssemblyApplicationType" */
  insert_C_AssemblyApplicationType_one?: Maybe<C_AssemblyApplicationType>;
  /** insert data into the table: "C_AssemblyType" */
  insert_C_AssemblyType?: Maybe<C_AssemblyType_Mutation_Response>;
  /** insert a single row into the table: "C_AssemblyType" */
  insert_C_AssemblyType_one?: Maybe<C_AssemblyType>;
  /** insert data into the table: "C_BackingMaterial" */
  insert_C_BackingMaterial?: Maybe<C_BackingMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_BackingMaterial" */
  insert_C_BackingMaterial_one?: Maybe<C_BackingMaterial>;
  /** insert data into the table: "C_BaseSheetApplicationMethod" */
  insert_C_BaseSheetApplicationMethod?: Maybe<C_BaseSheetApplicationMethod_Mutation_Response>;
  /** insert a single row into the table: "C_BaseSheetApplicationMethod" */
  insert_C_BaseSheetApplicationMethod_one?: Maybe<C_BaseSheetApplicationMethod>;
  /** insert data into the table: "C_BattBlanketFacerMaterial" */
  insert_C_BattBlanketFacerMaterial?: Maybe<C_BattBlanketFacerMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_BattBlanketFacerMaterial" */
  insert_C_BattBlanketFacerMaterial_one?: Maybe<C_BattBlanketFacerMaterial>;
  /** insert data into the table: "C_BattBlanketMaterial" */
  insert_C_BattBlanketMaterial?: Maybe<C_BattBlanketMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_BattBlanketMaterial" */
  insert_C_BattBlanketMaterial_one?: Maybe<C_BattBlanketMaterial>;
  /** insert data into the table: "C_BoardProfile" */
  insert_C_BoardProfile?: Maybe<C_BoardProfile_Mutation_Response>;
  /** insert a single row into the table: "C_BoardProfile" */
  insert_C_BoardProfile_one?: Maybe<C_BoardProfile>;
  /** insert data into the table: "C_BoardStockMaterial" */
  insert_C_BoardStockMaterial?: Maybe<C_BoardStockMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_BoardStockMaterial" */
  insert_C_BoardStockMaterial_one?: Maybe<C_BoardStockMaterial>;
  /** insert data into the table: "C_BuildingEnclosure" */
  insert_C_BuildingEnclosure?: Maybe<C_BuildingEnclosure_Mutation_Response>;
  /** insert a single row into the table: "C_BuildingEnclosure" */
  insert_C_BuildingEnclosure_one?: Maybe<C_BuildingEnclosure>;
  /** insert data into the table: "C_CementAggregateRatio" */
  insert_C_CementAggregateRatio?: Maybe<C_CementAggregateRatio_Mutation_Response>;
  /** insert a single row into the table: "C_CementAggregateRatio" */
  insert_C_CementAggregateRatio_one?: Maybe<C_CementAggregateRatio>;
  /** insert data into the table: "C_CoatingMaterial" */
  insert_C_CoatingMaterial?: Maybe<C_CoatingMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_CoatingMaterial" */
  insert_C_CoatingMaterial_one?: Maybe<C_CoatingMaterial>;
  /** insert data into the table: "C_ComponentType" */
  insert_C_ComponentType?: Maybe<C_ComponentType_Mutation_Response>;
  /** insert a single row into the table: "C_ComponentType" */
  insert_C_ComponentType_one?: Maybe<C_ComponentType>;
  /** insert data into the table: "C_ContactType" */
  insert_C_ContactType?: Maybe<C_ContactType_Mutation_Response>;
  /** insert a single row into the table: "C_ContactType" */
  insert_C_ContactType_one?: Maybe<C_ContactType>;
  /** insert data into the table: "C_Country" */
  insert_C_Country?: Maybe<C_Country_Mutation_Response>;
  /** insert a single row into the table: "C_Country" */
  insert_C_Country_one?: Maybe<C_Country>;
  /** insert data into the table: "C_CoverBoardMaterial" */
  insert_C_CoverBoardMaterial?: Maybe<C_CoverBoardMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_CoverBoardMaterial" */
  insert_C_CoverBoardMaterial_one?: Maybe<C_CoverBoardMaterial>;
  /** insert data into the table: "C_CoverColor" */
  insert_C_CoverColor?: Maybe<C_CoverColor_Mutation_Response>;
  /** insert a single row into the table: "C_CoverColor" */
  insert_C_CoverColor_one?: Maybe<C_CoverColor>;
  /** insert data into the table: "C_CoverSecurementMethod" */
  insert_C_CoverSecurementMethod?: Maybe<C_CoverSecurementMethod_Mutation_Response>;
  /** insert a single row into the table: "C_CoverSecurementMethod" */
  insert_C_CoverSecurementMethod_one?: Maybe<C_CoverSecurementMethod>;
  /** insert data into the table: "C_DeckType" */
  insert_C_DeckType?: Maybe<C_DeckType_Mutation_Response>;
  /** insert a single row into the table: "C_DeckType" */
  insert_C_DeckType_one?: Maybe<C_DeckType>;
  /** insert data into the table: "C_ExistingRoofCover" */
  insert_C_ExistingRoofCover?: Maybe<C_ExistingRoofCover_Mutation_Response>;
  /** insert a single row into the table: "C_ExistingRoofCover" */
  insert_C_ExistingRoofCover_one?: Maybe<C_ExistingRoofCover>;
  /** insert data into the table: "C_ExistingRoofDeck" */
  insert_C_ExistingRoofDeck?: Maybe<C_ExistingRoofDeck_Mutation_Response>;
  /** insert a single row into the table: "C_ExistingRoofDeck" */
  insert_C_ExistingRoofDeck_one?: Maybe<C_ExistingRoofDeck>;
  /** insert data into the table: "C_ExistingRoofInsulationMaterial" */
  insert_C_ExistingRoofInsulationMaterial?: Maybe<C_ExistingRoofInsulationMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_ExistingRoofInsulationMaterial" */
  insert_C_ExistingRoofInsulationMaterial_one?: Maybe<C_ExistingRoofInsulationMaterial>;
  /** insert data into the table: "C_ExistingRoofSinglePlyMaterial" */
  insert_C_ExistingRoofSinglePlyMaterial?: Maybe<C_ExistingRoofSinglePlyMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_ExistingRoofSinglePlyMaterial" */
  insert_C_ExistingRoofSinglePlyMaterial_one?: Maybe<C_ExistingRoofSinglePlyMaterial>;
  /** insert data into the table: "C_ExistingRoofSurface" */
  insert_C_ExistingRoofSurface?: Maybe<C_ExistingRoofSurface_Mutation_Response>;
  /** insert a single row into the table: "C_ExistingRoofSurface" */
  insert_C_ExistingRoofSurface_one?: Maybe<C_ExistingRoofSurface>;
  /** insert data into the table: "C_ExtFireRating" */
  insert_C_ExtFireRating?: Maybe<C_ExtFireRating_Mutation_Response>;
  /** insert a single row into the table: "C_ExtFireRating" */
  insert_C_ExtFireRating_one?: Maybe<C_ExtFireRating>;
  /** insert data into the table: "C_Facer" */
  insert_C_Facer?: Maybe<C_Facer_Mutation_Response>;
  /** insert data into the table: "C_FacerMaterial" */
  insert_C_FacerMaterial?: Maybe<C_FacerMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_FacerMaterial" */
  insert_C_FacerMaterial_one?: Maybe<C_FacerMaterial>;
  /** insert a single row into the table: "C_Facer" */
  insert_C_Facer_one?: Maybe<C_Facer>;
  /** insert data into the table: "C_FastenerCoating" */
  insert_C_FastenerCoating?: Maybe<C_FastenerCoating_Mutation_Response>;
  /** insert a single row into the table: "C_FastenerCoating" */
  insert_C_FastenerCoating_one?: Maybe<C_FastenerCoating>;
  /** insert data into the table: "C_FastenerDriveHead" */
  insert_C_FastenerDriveHead?: Maybe<C_FastenerDriveHead_Mutation_Response>;
  /** insert a single row into the table: "C_FastenerDriveHead" */
  insert_C_FastenerDriveHead_one?: Maybe<C_FastenerDriveHead>;
  /** insert data into the table: "C_FastenerType" */
  insert_C_FastenerType?: Maybe<C_FastenerType_Mutation_Response>;
  /** insert a single row into the table: "C_FastenerType" */
  insert_C_FastenerType_one?: Maybe<C_FastenerType>;
  /** insert data into the table: "C_FasteningApplicationMethod" */
  insert_C_FasteningApplicationMethod?: Maybe<C_FasteningApplicationMethod_Mutation_Response>;
  /** insert a single row into the table: "C_FasteningApplicationMethod" */
  insert_C_FasteningApplicationMethod_one?: Maybe<C_FasteningApplicationMethod>;
  /** insert data into the table: "C_FlashingMaterial" */
  insert_C_FlashingMaterial?: Maybe<C_FlashingMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_FlashingMaterial" */
  insert_C_FlashingMaterial_one?: Maybe<C_FlashingMaterial>;
  /** insert data into the table: "C_FlashingType" */
  insert_C_FlashingType?: Maybe<C_FlashingType_Mutation_Response>;
  /** insert a single row into the table: "C_FlashingType" */
  insert_C_FlashingType_one?: Maybe<C_FlashingType>;
  /** insert data into the table: "C_FormDeckMaterial" */
  insert_C_FormDeckMaterial?: Maybe<C_FormDeckMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_FormDeckMaterial" */
  insert_C_FormDeckMaterial_one?: Maybe<C_FormDeckMaterial>;
  /** insert data into the table: "C_GroundRoughnessRating" */
  insert_C_GroundRoughnessRating?: Maybe<C_GroundRoughnessRating_Mutation_Response>;
  /** insert a single row into the table: "C_GroundRoughnessRating" */
  insert_C_GroundRoughnessRating_one?: Maybe<C_GroundRoughnessRating>;
  /** insert data into the table: "C_GutterMaterial" */
  insert_C_GutterMaterial?: Maybe<C_GutterMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_GutterMaterial" */
  insert_C_GutterMaterial_one?: Maybe<C_GutterMaterial>;
  /** insert data into the table: "C_HailRating" */
  insert_C_HailRating?: Maybe<C_HailRating_Mutation_Response>;
  /** insert a single row into the table: "C_HailRating" */
  insert_C_HailRating_one?: Maybe<C_HailRating>;
  /** insert data into the table: "C_IndustryType" */
  insert_C_IndustryType?: Maybe<C_IndustryType_Mutation_Response>;
  /** insert a single row into the table: "C_IndustryType" */
  insert_C_IndustryType_one?: Maybe<C_IndustryType>;
  /** insert data into the table: "C_InsulationCore" */
  insert_C_InsulationCore?: Maybe<C_InsulationCore_Mutation_Response>;
  /** insert a single row into the table: "C_InsulationCore" */
  insert_C_InsulationCore_one?: Maybe<C_InsulationCore>;
  /** insert data into the table: "C_InsulationFoamType" */
  insert_C_InsulationFoamType?: Maybe<C_InsulationFoamType_Mutation_Response>;
  /** insert a single row into the table: "C_InsulationFoamType" */
  insert_C_InsulationFoamType_one?: Maybe<C_InsulationFoamType>;
  /** insert data into the table: "C_IntFireRating" */
  insert_C_IntFireRating?: Maybe<C_IntFireRating_Mutation_Response>;
  /** insert a single row into the table: "C_IntFireRating" */
  insert_C_IntFireRating_one?: Maybe<C_IntFireRating>;
  /** insert data into the table: "C_LapSealantType" */
  insert_C_LapSealantType?: Maybe<C_LapSealantType_Mutation_Response>;
  /** insert a single row into the table: "C_LapSealantType" */
  insert_C_LapSealantType_one?: Maybe<C_LapSealantType>;
  /** insert data into the table: "C_LayerType" */
  insert_C_LayerType?: Maybe<C_LayerType_Mutation_Response>;
  /** insert a single row into the table: "C_LayerType" */
  insert_C_LayerType_one?: Maybe<C_LayerType>;
  /** insert data into the table: "C_LinerPanelMaterial" */
  insert_C_LinerPanelMaterial?: Maybe<C_LinerPanelMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_LinerPanelMaterial" */
  insert_C_LinerPanelMaterial_one?: Maybe<C_LinerPanelMaterial>;
  /** insert data into the table: "C_LiquidApplication" */
  insert_C_LiquidApplication?: Maybe<C_LiquidApplication_Mutation_Response>;
  /** insert a single row into the table: "C_LiquidApplication" */
  insert_C_LiquidApplication_one?: Maybe<C_LiquidApplication>;
  /** insert data into the table: "C_LiquidAppliedMaterial" */
  insert_C_LiquidAppliedMaterial?: Maybe<C_LiquidAppliedMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_LiquidAppliedMaterial" */
  insert_C_LiquidAppliedMaterial_one?: Maybe<C_LiquidAppliedMaterial>;
  /** insert data into the table: "C_LockStatus" */
  insert_C_LockStatus?: Maybe<C_LockStatus_Mutation_Response>;
  /** insert a single row into the table: "C_LockStatus" */
  insert_C_LockStatus_one?: Maybe<C_LockStatus>;
  /** insert data into the table: "C_MDAttributeType" */
  insert_C_MDAttributeType?: Maybe<C_MdAttributeType_Mutation_Response>;
  /** insert a single row into the table: "C_MDAttributeType" */
  insert_C_MDAttributeType_one?: Maybe<C_MdAttributeType>;
  /** insert data into the table: "C_MaintenanceCoatingMaterial" */
  insert_C_MaintenanceCoatingMaterial?: Maybe<C_MaintenanceCoatingMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_MaintenanceCoatingMaterial" */
  insert_C_MaintenanceCoatingMaterial_one?: Maybe<C_MaintenanceCoatingMaterial>;
  /** insert data into the table: "C_MaintenanceCoatingSubstrate" */
  insert_C_MaintenanceCoatingSubstrate?: Maybe<C_MaintenanceCoatingSubstrate_Mutation_Response>;
  /** insert a single row into the table: "C_MaintenanceCoatingSubstrate" */
  insert_C_MaintenanceCoatingSubstrate_one?: Maybe<C_MaintenanceCoatingSubstrate>;
  /** insert data into the table: "C_MembraneTermination" */
  insert_C_MembraneTermination?: Maybe<C_MembraneTermination_Mutation_Response>;
  /** insert a single row into the table: "C_MembraneTermination" */
  insert_C_MembraneTermination_one?: Maybe<C_MembraneTermination>;
  /** insert data into the table: "C_MultiplyMaterial" */
  insert_C_MultiplyMaterial?: Maybe<C_MultiplyMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_MultiplyMaterial" */
  insert_C_MultiplyMaterial_one?: Maybe<C_MultiplyMaterial>;
  /** insert data into the table: "C_PVApplicationMethod" */
  insert_C_PVApplicationMethod?: Maybe<C_PvApplicationMethod_Mutation_Response>;
  /** insert a single row into the table: "C_PVApplicationMethod" */
  insert_C_PVApplicationMethod_one?: Maybe<C_PvApplicationMethod>;
  /** insert data into the table: "C_PVHailRating" */
  insert_C_PVHailRating?: Maybe<C_PvHailRating_Mutation_Response>;
  /** insert a single row into the table: "C_PVHailRating" */
  insert_C_PVHailRating_one?: Maybe<C_PvHailRating>;
  /** insert data into the table: "C_PerimeterFlashingType" */
  insert_C_PerimeterFlashingType?: Maybe<C_PerimeterFlashingType_Mutation_Response>;
  /** insert a single row into the table: "C_PerimeterFlashingType" */
  insert_C_PerimeterFlashingType_one?: Maybe<C_PerimeterFlashingType>;
  /** insert data into the table: "C_PressureZone" */
  insert_C_PressureZone?: Maybe<C_PressureZone_Mutation_Response>;
  /** insert a single row into the table: "C_PressureZone" */
  insert_C_PressureZone_one?: Maybe<C_PressureZone>;
  /** insert data into the table: "C_ProductApprovedUse" */
  insert_C_ProductApprovedUse?: Maybe<C_ProductApprovedUse_Mutation_Response>;
  /** insert a single row into the table: "C_ProductApprovedUse" */
  insert_C_ProductApprovedUse_one?: Maybe<C_ProductApprovedUse>;
  /** insert data into the table: "C_ProjectRole" */
  insert_C_ProjectRole?: Maybe<C_ProjectRole_Mutation_Response>;
  /** insert a single row into the table: "C_ProjectRole" */
  insert_C_ProjectRole_one?: Maybe<C_ProjectRole>;
  /** insert data into the table: "C_Reinforcement" */
  insert_C_Reinforcement?: Maybe<C_Reinforcement_Mutation_Response>;
  /** insert a single row into the table: "C_Reinforcement" */
  insert_C_Reinforcement_one?: Maybe<C_Reinforcement>;
  /** insert data into the table: "C_RetarderMaterial" */
  insert_C_RetarderMaterial?: Maybe<C_RetarderMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_RetarderMaterial" */
  insert_C_RetarderMaterial_one?: Maybe<C_RetarderMaterial>;
  /** insert data into the table: "C_ScenarioStatus" */
  insert_C_ScenarioStatus?: Maybe<C_ScenarioStatus_Mutation_Response>;
  /** insert a single row into the table: "C_ScenarioStatus" */
  insert_C_ScenarioStatus_one?: Maybe<C_ScenarioStatus>;
  /** insert data into the table: "C_ScenarioType" */
  insert_C_ScenarioType?: Maybe<C_ScenarioType_Mutation_Response>;
  /** insert a single row into the table: "C_ScenarioType" */
  insert_C_ScenarioType_one?: Maybe<C_ScenarioType>;
  /** insert data into the table: "C_SeamPrimerType" */
  insert_C_SeamPrimerType?: Maybe<C_SeamPrimerType_Mutation_Response>;
  /** insert a single row into the table: "C_SeamPrimerType" */
  insert_C_SeamPrimerType_one?: Maybe<C_SeamPrimerType>;
  /** insert data into the table: "C_SeamWashType" */
  insert_C_SeamWashType?: Maybe<C_SeamWashType_Mutation_Response>;
  /** insert a single row into the table: "C_SeamWashType" */
  insert_C_SeamWashType_one?: Maybe<C_SeamWashType>;
  /** insert data into the table: "C_SecurementMaterial" */
  insert_C_SecurementMaterial?: Maybe<C_SecurementMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_SecurementMaterial" */
  insert_C_SecurementMaterial_one?: Maybe<C_SecurementMaterial>;
  /** insert data into the table: "C_ShinglesHail" */
  insert_C_ShinglesHail?: Maybe<C_ShinglesHail_Mutation_Response>;
  /** insert a single row into the table: "C_ShinglesHail" */
  insert_C_ShinglesHail_one?: Maybe<C_ShinglesHail>;
  /** insert data into the table: "C_ShinglesMaterial" */
  insert_C_ShinglesMaterial?: Maybe<C_ShinglesMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_ShinglesMaterial" */
  insert_C_ShinglesMaterial_one?: Maybe<C_ShinglesMaterial>;
  /** insert data into the table: "C_ShinglesReinforcement" */
  insert_C_ShinglesReinforcement?: Maybe<C_ShinglesReinforcement_Mutation_Response>;
  /** insert a single row into the table: "C_ShinglesReinforcement" */
  insert_C_ShinglesReinforcement_one?: Maybe<C_ShinglesReinforcement>;
  /** insert data into the table: "C_ShinglesSurfacing" */
  insert_C_ShinglesSurfacing?: Maybe<C_ShinglesSurfacing_Mutation_Response>;
  /** insert a single row into the table: "C_ShinglesSurfacing" */
  insert_C_ShinglesSurfacing_one?: Maybe<C_ShinglesSurfacing>;
  /** insert data into the table: "C_ShinglesWindSpeed" */
  insert_C_ShinglesWindSpeed?: Maybe<C_ShinglesWindSpeed_Mutation_Response>;
  /** insert a single row into the table: "C_ShinglesWindSpeed" */
  insert_C_ShinglesWindSpeed_one?: Maybe<C_ShinglesWindSpeed>;
  /** insert data into the table: "C_SinglePlyMaterial" */
  insert_C_SinglePlyMaterial?: Maybe<C_SinglePlyMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_SinglePlyMaterial" */
  insert_C_SinglePlyMaterial_one?: Maybe<C_SinglePlyMaterial>;
  /** insert data into the table: "C_StandingLapSeamMaterial" */
  insert_C_StandingLapSeamMaterial?: Maybe<C_StandingLapSeamMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_StandingLapSeamMaterial" */
  insert_C_StandingLapSeamMaterial_one?: Maybe<C_StandingLapSeamMaterial>;
  /** insert data into the table: "C_StateProvince" */
  insert_C_StateProvince?: Maybe<C_StateProvince_Mutation_Response>;
  /** insert a single row into the table: "C_StateProvince" */
  insert_C_StateProvince_one?: Maybe<C_StateProvince>;
  /** insert data into the table: "C_Status" */
  insert_C_Status?: Maybe<C_Status_Mutation_Response>;
  /** insert a single row into the table: "C_Status" */
  insert_C_Status_one?: Maybe<C_Status>;
  /** insert data into the table: "C_SteelDeckRibType" */
  insert_C_SteelDeckRibType?: Maybe<C_SteelDeckRibType_Mutation_Response>;
  /** insert a single row into the table: "C_SteelDeckRibType" */
  insert_C_SteelDeckRibType_one?: Maybe<C_SteelDeckRibType>;
  /** insert data into the table: "C_StressDistributorType" */
  insert_C_StressDistributorType?: Maybe<C_StressDistributorType_Mutation_Response>;
  /** insert a single row into the table: "C_StressDistributorType" */
  insert_C_StressDistributorType_one?: Maybe<C_StressDistributorType>;
  /** insert data into the table: "C_StressPlateShape" */
  insert_C_StressPlateShape?: Maybe<C_StressPlateShape_Mutation_Response>;
  /** insert a single row into the table: "C_StressPlateShape" */
  insert_C_StressPlateShape_one?: Maybe<C_StressPlateShape>;
  /** insert data into the table: "C_StructureType" */
  insert_C_StructureType?: Maybe<C_StructureType_Mutation_Response>;
  /** insert a single row into the table: "C_StructureType" */
  insert_C_StructureType_one?: Maybe<C_StructureType>;
  /** insert data into the table: "C_SubassemblyType" */
  insert_C_SubassemblyType?: Maybe<C_SubassemblyType_Mutation_Response>;
  /** insert a single row into the table: "C_SubassemblyType" */
  insert_C_SubassemblyType_one?: Maybe<C_SubassemblyType>;
  /** insert data into the table: "C_Substrate" */
  insert_C_Substrate?: Maybe<C_Substrate_Mutation_Response>;
  /** insert data into the table: "C_SubstratePrimerMaterial" */
  insert_C_SubstratePrimerMaterial?: Maybe<C_SubstratePrimerMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_SubstratePrimerMaterial" */
  insert_C_SubstratePrimerMaterial_one?: Maybe<C_SubstratePrimerMaterial>;
  /** insert a single row into the table: "C_Substrate" */
  insert_C_Substrate_one?: Maybe<C_Substrate>;
  /** insert data into the table: "C_SurfaceType" */
  insert_C_SurfaceType?: Maybe<C_SurfaceType_Mutation_Response>;
  /** insert a single row into the table: "C_SurfaceType" */
  insert_C_SurfaceType_one?: Maybe<C_SurfaceType>;
  /** insert data into the table: "C_SurfacingMaterial" */
  insert_C_SurfacingMaterial?: Maybe<C_SurfacingMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_SurfacingMaterial" */
  insert_C_SurfacingMaterial_one?: Maybe<C_SurfacingMaterial>;
  /** insert data into the table: "C_SystemApprovedUse" */
  insert_C_SystemApprovedUse?: Maybe<C_SystemApprovedUse_Mutation_Response>;
  /** insert a single row into the table: "C_SystemApprovedUse" */
  insert_C_SystemApprovedUse_one?: Maybe<C_SystemApprovedUse>;
  /** insert data into the table: "C_ThermalBarrierMaterial" */
  insert_C_ThermalBarrierMaterial?: Maybe<C_ThermalBarrierMaterial_Mutation_Response>;
  /** insert a single row into the table: "C_ThermalBarrierMaterial" */
  insert_C_ThermalBarrierMaterial_one?: Maybe<C_ThermalBarrierMaterial>;
  /** insert data into the table: "C_WIPDelistReason" */
  insert_C_WIPDelistReason?: Maybe<C_WipDelistReason_Mutation_Response>;
  /** insert a single row into the table: "C_WIPDelistReason" */
  insert_C_WIPDelistReason_one?: Maybe<C_WipDelistReason>;
  /** insert data into the table: "C_WIPOnHoldReason" */
  insert_C_WIPOnHoldReason?: Maybe<C_WipOnHoldReason_Mutation_Response>;
  /** insert a single row into the table: "C_WIPOnHoldReason" */
  insert_C_WIPOnHoldReason_one?: Maybe<C_WipOnHoldReason>;
  /** insert data into the table: "C_WIPStatus" */
  insert_C_WIPStatus?: Maybe<C_WipStatus_Mutation_Response>;
  /** insert a single row into the table: "C_WIPStatus" */
  insert_C_WIPStatus_one?: Maybe<C_WipStatus>;
  /** insert data into the table: "C_WeldApplication" */
  insert_C_WeldApplication?: Maybe<C_WeldApplication_Mutation_Response>;
  /** insert a single row into the table: "C_WeldApplication" */
  insert_C_WeldApplication_one?: Maybe<C_WeldApplication>;
  /** insert data into the table: "C_WeldConfiguration" */
  insert_C_WeldConfiguration?: Maybe<C_WeldConfiguration_Mutation_Response>;
  /** insert a single row into the table: "C_WeldConfiguration" */
  insert_C_WeldConfiguration_one?: Maybe<C_WeldConfiguration>;
  /** insert data into the table: "C_WindBorneDebris" */
  insert_C_WindBorneDebris?: Maybe<C_WindBorneDebris_Mutation_Response>;
  /** insert a single row into the table: "C_WindBorneDebris" */
  insert_C_WindBorneDebris_one?: Maybe<C_WindBorneDebris>;
  /** insert data into the table: "C_WoodSpecies" */
  insert_C_WoodSpecies?: Maybe<C_WoodSpecies_Mutation_Response>;
  /** insert a single row into the table: "C_WoodSpecies" */
  insert_C_WoodSpecies_one?: Maybe<C_WoodSpecies>;
  /** insert data into the table: "C_WoodType" */
  insert_C_WoodType?: Maybe<C_WoodType_Mutation_Response>;
  /** insert a single row into the table: "C_WoodType" */
  insert_C_WoodType_one?: Maybe<C_WoodType>;
  /** insert data into the table: "C_WorkflowStatus" */
  insert_C_WorkflowStatus?: Maybe<C_WorkflowStatus_Mutation_Response>;
  /** insert a single row into the table: "C_WorkflowStatus" */
  insert_C_WorkflowStatus_one?: Maybe<C_WorkflowStatus>;
  /** insert data into the table: "CapPly" */
  insert_CapPly?: Maybe<CapPly_Mutation_Response>;
  /** insert data into the table: "CapPlyUsage" */
  insert_CapPlyUsage?: Maybe<CapPlyUsage_Mutation_Response>;
  /** insert a single row into the table: "CapPlyUsage" */
  insert_CapPlyUsage_one?: Maybe<CapPlyUsage>;
  /** insert a single row into the table: "CapPly" */
  insert_CapPly_one?: Maybe<CapPly>;
  /** insert data into the table: "Category" */
  insert_Category?: Maybe<Category_Mutation_Response>;
  /** insert data into the table: "CategoryLog" */
  insert_CategoryLog?: Maybe<CategoryLog_Mutation_Response>;
  /** insert a single row into the table: "CategoryLog" */
  insert_CategoryLog_one?: Maybe<CategoryLog>;
  /** insert data into the table: "Category_Coating" */
  insert_Category_Coating?: Maybe<Category_Coating_Mutation_Response>;
  /** insert a single row into the table: "Category_Coating" */
  insert_Category_Coating_one?: Maybe<Category_Coating>;
  /** insert data into the table: "Category_Cover" */
  insert_Category_Cover?: Maybe<Category_Cover_Mutation_Response>;
  /** insert a single row into the table: "Category_Cover" */
  insert_Category_Cover_one?: Maybe<Category_Cover>;
  /** insert data into the table: "Category_Deck" */
  insert_Category_Deck?: Maybe<Category_Deck_Mutation_Response>;
  /** insert a single row into the table: "Category_Deck" */
  insert_Category_Deck_one?: Maybe<Category_Deck>;
  /** insert data into the table: "Category_Flashing" */
  insert_Category_Flashing?: Maybe<Category_Flashing_Mutation_Response>;
  /** insert a single row into the table: "Category_Flashing" */
  insert_Category_Flashing_one?: Maybe<Category_Flashing>;
  /** insert data into the table: "Category_Insulation" */
  insert_Category_Insulation?: Maybe<Category_Insulation_Mutation_Response>;
  /** insert a single row into the table: "Category_Insulation" */
  insert_Category_Insulation_one?: Maybe<Category_Insulation>;
  /** insert data into the table: "Category_Other" */
  insert_Category_Other?: Maybe<Category_Other_Mutation_Response>;
  /** insert a single row into the table: "Category_Other" */
  insert_Category_Other_one?: Maybe<Category_Other>;
  /** insert data into the table: "Category_Seaming" */
  insert_Category_Seaming?: Maybe<Category_Seaming_Mutation_Response>;
  /** insert a single row into the table: "Category_Seaming" */
  insert_Category_Seaming_one?: Maybe<Category_Seaming>;
  /** insert data into the table: "Category_Securement" */
  insert_Category_Securement?: Maybe<Category_Securement_Mutation_Response>;
  /** insert a single row into the table: "Category_Securement" */
  insert_Category_Securement_one?: Maybe<Category_Securement>;
  /** insert data into the table: "Category_Sheet" */
  insert_Category_Sheet?: Maybe<Category_Sheet_Mutation_Response>;
  /** insert a single row into the table: "Category_Sheet" */
  insert_Category_Sheet_one?: Maybe<Category_Sheet>;
  /** insert a single row into the table: "Category" */
  insert_Category_one?: Maybe<Category>;
  /** insert data into the table: "CementitiousWoodFiberDeck" */
  insert_CementitiousWoodFiberDeck?: Maybe<CementitiousWoodFiberDeck_Mutation_Response>;
  /** insert data into the table: "CementitiousWoodFiberDeckUsage" */
  insert_CementitiousWoodFiberDeckUsage?: Maybe<CementitiousWoodFiberDeckUsage_Mutation_Response>;
  /** insert a single row into the table: "CementitiousWoodFiberDeckUsage" */
  insert_CementitiousWoodFiberDeckUsage_one?: Maybe<CementitiousWoodFiberDeckUsage>;
  /** insert a single row into the table: "CementitiousWoodFiberDeck" */
  insert_CementitiousWoodFiberDeck_one?: Maybe<CementitiousWoodFiberDeck>;
  /** insert data into the table: "Clip" */
  insert_Clip?: Maybe<Clip_Mutation_Response>;
  /** insert data into the table: "ClipUsage" */
  insert_ClipUsage?: Maybe<ClipUsage_Mutation_Response>;
  /** insert a single row into the table: "ClipUsage" */
  insert_ClipUsage_one?: Maybe<ClipUsage>;
  /** insert a single row into the table: "Clip" */
  insert_Clip_one?: Maybe<Clip>;
  /** insert data into the table: "Coat" */
  insert_Coat?: Maybe<Coat_Mutation_Response>;
  /** insert data into the table: "CoatUsage" */
  insert_CoatUsage?: Maybe<CoatUsage_Mutation_Response>;
  /** insert a single row into the table: "CoatUsage" */
  insert_CoatUsage_one?: Maybe<CoatUsage>;
  /** insert a single row into the table: "Coat" */
  insert_Coat_one?: Maybe<Coat>;
  /** insert data into the table: "Coating" */
  insert_Coating?: Maybe<Coating_Mutation_Response>;
  /** insert data into the table: "CoatingSystemUsage" */
  insert_CoatingSystemUsage?: Maybe<CoatingSystemUsage_Mutation_Response>;
  /** insert a single row into the table: "CoatingSystemUsage" */
  insert_CoatingSystemUsage_one?: Maybe<CoatingSystemUsage>;
  /** insert data into the table: "CoatingUsage" */
  insert_CoatingUsage?: Maybe<CoatingUsage_Mutation_Response>;
  /** insert a single row into the table: "CoatingUsage" */
  insert_CoatingUsage_one?: Maybe<CoatingUsage>;
  /** insert a single row into the table: "Coating" */
  insert_Coating_one?: Maybe<Coating>;
  /** insert data into the table: "Component" */
  insert_Component?: Maybe<Component_Mutation_Response>;
  /** insert data into the table: "ComponentApprovedUse" */
  insert_ComponentApprovedUse?: Maybe<ComponentApprovedUse_Mutation_Response>;
  /** insert a single row into the table: "ComponentApprovedUse" */
  insert_ComponentApprovedUse_one?: Maybe<ComponentApprovedUse>;
  /** insert data into the table: "ComponentDoc" */
  insert_ComponentDoc?: Maybe<ComponentDoc_Mutation_Response>;
  /** insert a single row into the table: "ComponentDoc" */
  insert_ComponentDoc_one?: Maybe<ComponentDoc>;
  /** insert data into the table: "ComponentLayer" */
  insert_ComponentLayer?: Maybe<ComponentLayer_Mutation_Response>;
  /** insert data into the table: "ComponentLayerUsage" */
  insert_ComponentLayerUsage?: Maybe<ComponentLayerUsage_Mutation_Response>;
  /** insert a single row into the table: "ComponentLayerUsage" */
  insert_ComponentLayerUsage_one?: Maybe<ComponentLayerUsage>;
  /** insert a single row into the table: "ComponentLayer" */
  insert_ComponentLayer_one?: Maybe<ComponentLayer>;
  /** insert a single row into the table: "Component" */
  insert_Component_one?: Maybe<Component>;
  /** insert data into the table: "CompositePanelCover" */
  insert_CompositePanelCover?: Maybe<CompositePanelCover_Mutation_Response>;
  /** insert a single row into the table: "CompositePanelCover" */
  insert_CompositePanelCover_one?: Maybe<CompositePanelCover>;
  /** insert data into the table: "CompositePanelUsage" */
  insert_CompositePanelUsage?: Maybe<CompositePanelUsage_Mutation_Response>;
  /** insert a single row into the table: "CompositePanelUsage" */
  insert_CompositePanelUsage_one?: Maybe<CompositePanelUsage>;
  /** insert data into the table: "ContactInfo" */
  insert_ContactInfo?: Maybe<ContactInfo_Mutation_Response>;
  /** insert a single row into the table: "ContactInfo" */
  insert_ContactInfo_one?: Maybe<ContactInfo>;
  /** insert data into the table: "Coping" */
  insert_Coping?: Maybe<Coping_Mutation_Response>;
  /** insert a single row into the table: "Coping" */
  insert_Coping_one?: Maybe<Coping>;
  /** insert data into the table: "CoverBoard" */
  insert_CoverBoard?: Maybe<CoverBoard_Mutation_Response>;
  /** insert data into the table: "CoverBoardBoardSizes" */
  insert_CoverBoardBoardSizes?: Maybe<CoverBoardBoardSizes_Mutation_Response>;
  /** insert a single row into the table: "CoverBoardBoardSizes" */
  insert_CoverBoardBoardSizes_one?: Maybe<CoverBoardBoardSizes>;
  /** insert data into the table: "CoverBoardFacerBottom" */
  insert_CoverBoardFacerBottom?: Maybe<CoverBoardFacerBottom_Mutation_Response>;
  /** insert a single row into the table: "CoverBoardFacerBottom" */
  insert_CoverBoardFacerBottom_one?: Maybe<CoverBoardFacerBottom>;
  /** insert data into the table: "CoverBoardFacerTop" */
  insert_CoverBoardFacerTop?: Maybe<CoverBoardFacerTop_Mutation_Response>;
  /** insert a single row into the table: "CoverBoardFacerTop" */
  insert_CoverBoardFacerTop_one?: Maybe<CoverBoardFacerTop>;
  /** insert data into the table: "CoverBoardUsage" */
  insert_CoverBoardUsage?: Maybe<CoverBoardUsage_Mutation_Response>;
  /** insert a single row into the table: "CoverBoardUsage" */
  insert_CoverBoardUsage_one?: Maybe<CoverBoardUsage>;
  /** insert a single row into the table: "CoverBoard" */
  insert_CoverBoard_one?: Maybe<CoverBoard>;
  /** insert data into the table: "CoverStripDisk" */
  insert_CoverStripDisk?: Maybe<CoverStripDisk_Mutation_Response>;
  /** insert data into the table: "CoverStripDiskUsage" */
  insert_CoverStripDiskUsage?: Maybe<CoverStripDiskUsage_Mutation_Response>;
  /** insert a single row into the table: "CoverStripDiskUsage" */
  insert_CoverStripDiskUsage_one?: Maybe<CoverStripDiskUsage>;
  /** insert a single row into the table: "CoverStripDisk" */
  insert_CoverStripDisk_one?: Maybe<CoverStripDisk>;
  /** insert data into the table: "ExistingRoofLayer" */
  insert_ExistingRoofLayer?: Maybe<ExistingRoofLayer_Mutation_Response>;
  /** insert a single row into the table: "ExistingRoofLayer" */
  insert_ExistingRoofLayer_one?: Maybe<ExistingRoofLayer>;
  /** insert data into the table: "ExpansionJoint" */
  insert_ExpansionJoint?: Maybe<ExpansionJoint_Mutation_Response>;
  /** insert data into the table: "ExpansionJointUsage" */
  insert_ExpansionJointUsage?: Maybe<ExpansionJointUsage_Mutation_Response>;
  /** insert a single row into the table: "ExpansionJointUsage" */
  insert_ExpansionJointUsage_one?: Maybe<ExpansionJointUsage>;
  /** insert a single row into the table: "ExpansionJoint" */
  insert_ExpansionJoint_one?: Maybe<ExpansionJoint>;
  /** insert data into the table: "ExtendedLayerConfigSplit" */
  insert_ExtendedLayerConfigSplit?: Maybe<ExtendedLayerConfigSplit_Mutation_Response>;
  /** insert a single row into the table: "ExtendedLayerConfigSplit" */
  insert_ExtendedLayerConfigSplit_one?: Maybe<ExtendedLayerConfigSplit>;
  /** insert data into the table: "FMUserAccount" */
  insert_FMUserAccount?: Maybe<FmUserAccount_Mutation_Response>;
  /** insert a single row into the table: "FMUserAccount" */
  insert_FMUserAccount_one?: Maybe<FmUserAccount>;
  /** insert data into the table: "Fascia" */
  insert_Fascia?: Maybe<Fascia_Mutation_Response>;
  /** insert a single row into the table: "Fascia" */
  insert_Fascia_one?: Maybe<Fascia>;
  /** insert data into the table: "Fastener" */
  insert_Fastener?: Maybe<Fastener_Mutation_Response>;
  /** insert data into the table: "FastenerUsage" */
  insert_FastenerUsage?: Maybe<FastenerUsage_Mutation_Response>;
  /** insert a single row into the table: "FastenerUsage" */
  insert_FastenerUsage_one?: Maybe<FastenerUsage>;
  /** insert a single row into the table: "Fastener" */
  insert_Fastener_one?: Maybe<Fastener>;
  /** insert data into the table: "FasteningSystemBattenBarUsage" */
  insert_FasteningSystemBattenBarUsage?: Maybe<FasteningSystemBattenBarUsage_Mutation_Response>;
  /** insert a single row into the table: "FasteningSystemBattenBarUsage" */
  insert_FasteningSystemBattenBarUsage_one?: Maybe<FasteningSystemBattenBarUsage>;
  /** insert data into the table: "FasteningSystemClipUsage" */
  insert_FasteningSystemClipUsage?: Maybe<FasteningSystemClipUsage_Mutation_Response>;
  /** insert a single row into the table: "FasteningSystemClipUsage" */
  insert_FasteningSystemClipUsage_one?: Maybe<FasteningSystemClipUsage>;
  /** insert data into the table: "FasteningSystemStressPlateUsage" */
  insert_FasteningSystemStressPlateUsage?: Maybe<FasteningSystemStressPlateUsage_Mutation_Response>;
  /** insert a single row into the table: "FasteningSystemStressPlateUsage" */
  insert_FasteningSystemStressPlateUsage_one?: Maybe<FasteningSystemStressPlateUsage>;
  /** insert data into the table: "FiberReinforcedPlasticDeck" */
  insert_FiberReinforcedPlasticDeck?: Maybe<FiberReinforcedPlasticDeck_Mutation_Response>;
  /** insert data into the table: "FiberReinforcedPlasticDeckUsage" */
  insert_FiberReinforcedPlasticDeckUsage?: Maybe<FiberReinforcedPlasticDeckUsage_Mutation_Response>;
  /** insert a single row into the table: "FiberReinforcedPlasticDeckUsage" */
  insert_FiberReinforcedPlasticDeckUsage_one?: Maybe<FiberReinforcedPlasticDeckUsage>;
  /** insert a single row into the table: "FiberReinforcedPlasticDeck" */
  insert_FiberReinforcedPlasticDeck_one?: Maybe<FiberReinforcedPlasticDeck>;
  /** insert data into the table: "FormDeck" */
  insert_FormDeck?: Maybe<FormDeck_Mutation_Response>;
  /** insert data into the table: "FormDeckUsage" */
  insert_FormDeckUsage?: Maybe<FormDeckUsage_Mutation_Response>;
  /** insert a single row into the table: "FormDeckUsage" */
  insert_FormDeckUsage_one?: Maybe<FormDeckUsage>;
  /** insert a single row into the table: "FormDeck" */
  insert_FormDeck_one?: Maybe<FormDeck>;
  /** insert data into the table: "Gutter" */
  insert_Gutter?: Maybe<Gutter_Mutation_Response>;
  /** insert a single row into the table: "Gutter" */
  insert_Gutter_one?: Maybe<Gutter>;
  /** insert data into the table: "GypsumDeck" */
  insert_GypsumDeck?: Maybe<GypsumDeck_Mutation_Response>;
  /** insert data into the table: "GypsumDeckUsage" */
  insert_GypsumDeckUsage?: Maybe<GypsumDeckUsage_Mutation_Response>;
  /** insert a single row into the table: "GypsumDeckUsage" */
  insert_GypsumDeckUsage_one?: Maybe<GypsumDeckUsage>;
  /** insert a single row into the table: "GypsumDeck" */
  insert_GypsumDeck_one?: Maybe<GypsumDeck>;
  /** insert data into the table: "InsBoardSize" */
  insert_InsBoardSize?: Maybe<InsBoardSize_Mutation_Response>;
  /** insert a single row into the table: "InsBoardSize" */
  insert_InsBoardSize_one?: Maybe<InsBoardSize>;
  /** insert data into the table: "ItemWorkspace" */
  insert_ItemWorkspace?: Maybe<ItemWorkspace_Mutation_Response>;
  /** insert data into the table: "ItemWorkspaceData" */
  insert_ItemWorkspaceData?: Maybe<ItemWorkspaceData_Mutation_Response>;
  /** insert a single row into the table: "ItemWorkspaceData" */
  insert_ItemWorkspaceData_one?: Maybe<ItemWorkspaceData>;
  /** insert a single row into the table: "ItemWorkspace" */
  insert_ItemWorkspace_one?: Maybe<ItemWorkspace>;
  /** insert data into the table: "LapSealant" */
  insert_LapSealant?: Maybe<LapSealant_Mutation_Response>;
  /** insert data into the table: "LapSealantUsage" */
  insert_LapSealantUsage?: Maybe<LapSealantUsage_Mutation_Response>;
  /** insert a single row into the table: "LapSealantUsage" */
  insert_LapSealantUsage_one?: Maybe<LapSealantUsage>;
  /** insert a single row into the table: "LapSealant" */
  insert_LapSealant_one?: Maybe<LapSealant>;
  /** insert data into the table: "LapSecurementInfo" */
  insert_LapSecurementInfo?: Maybe<LapSecurementInfo_Mutation_Response>;
  /** insert a single row into the table: "LapSecurementInfo" */
  insert_LapSecurementInfo_one?: Maybe<LapSecurementInfo>;
  /** insert data into the table: "Layer" */
  insert_Layer?: Maybe<Layer_Mutation_Response>;
  /** insert data into the table: "LayerConfig" */
  insert_LayerConfig?: Maybe<LayerConfig_Mutation_Response>;
  /** insert a single row into the table: "LayerConfig" */
  insert_LayerConfig_one?: Maybe<LayerConfig>;
  /** insert a single row into the table: "Layer" */
  insert_Layer_one?: Maybe<Layer>;
  /** insert data into the table: "LightweightInsulatingConcrete" */
  insert_LightweightInsulatingConcrete?: Maybe<LightweightInsulatingConcrete_Mutation_Response>;
  /** insert data into the table: "LightweightInsulatingConcreteSubassemblyUsage" */
  insert_LightweightInsulatingConcreteSubassemblyUsage?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Mutation_Response>;
  /** insert a single row into the table: "LightweightInsulatingConcreteSubassemblyUsage" */
  insert_LightweightInsulatingConcreteSubassemblyUsage_one?: Maybe<LightweightInsulatingConcreteSubassemblyUsage>;
  /** insert data into the table: "LightweightInsulatingConcreteUsage" */
  insert_LightweightInsulatingConcreteUsage?: Maybe<LightweightInsulatingConcreteUsage_Mutation_Response>;
  /** insert a single row into the table: "LightweightInsulatingConcreteUsage" */
  insert_LightweightInsulatingConcreteUsage_one?: Maybe<LightweightInsulatingConcreteUsage>;
  /** insert a single row into the table: "LightweightInsulatingConcrete" */
  insert_LightweightInsulatingConcrete_one?: Maybe<LightweightInsulatingConcrete>;
  /** insert data into the table: "LinerPanel" */
  insert_LinerPanel?: Maybe<LinerPanel_Mutation_Response>;
  /** insert data into the table: "LinerPanelUsage" */
  insert_LinerPanelUsage?: Maybe<LinerPanelUsage_Mutation_Response>;
  /** insert a single row into the table: "LinerPanelUsage" */
  insert_LinerPanelUsage_one?: Maybe<LinerPanelUsage>;
  /** insert a single row into the table: "LinerPanel" */
  insert_LinerPanel_one?: Maybe<LinerPanel>;
  /** insert data into the table: "LiquidAppliedSubassemblyUsage" */
  insert_LiquidAppliedSubassemblyUsage?: Maybe<LiquidAppliedSubassemblyUsage_Mutation_Response>;
  /** insert a single row into the table: "LiquidAppliedSubassemblyUsage" */
  insert_LiquidAppliedSubassemblyUsage_one?: Maybe<LiquidAppliedSubassemblyUsage>;
  /** insert data into the table: "LiquidAppliedUsage" */
  insert_LiquidAppliedUsage?: Maybe<LiquidAppliedUsage_Mutation_Response>;
  /** insert a single row into the table: "LiquidAppliedUsage" */
  insert_LiquidAppliedUsage_one?: Maybe<LiquidAppliedUsage>;
  /** insert data into the table: "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
  insert_Load_SearchLayerUsage_DeckFiberReinforcedPlastic?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Mutation_Response>;
  /** insert a single row into the table: "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
  insert_Load_SearchLayerUsage_DeckFiberReinforcedPlastic_one?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic>;
  /** insert data into the table: "MD_ApprovedUse" */
  insert_MD_ApprovedUse?: Maybe<Md_ApprovedUse_Mutation_Response>;
  /** insert data into the table: "MD_ApprovedUseAttributes" */
  insert_MD_ApprovedUseAttributes?: Maybe<Md_ApprovedUseAttributes_Mutation_Response>;
  /** insert a single row into the table: "MD_ApprovedUseAttributes" */
  insert_MD_ApprovedUseAttributes_one?: Maybe<Md_ApprovedUseAttributes>;
  /** insert a single row into the table: "MD_ApprovedUse" */
  insert_MD_ApprovedUse_one?: Maybe<Md_ApprovedUse>;
  /** insert data into the table: "MD_AttributeBase" */
  insert_MD_AttributeBase?: Maybe<Md_AttributeBase_Mutation_Response>;
  /** insert a single row into the table: "MD_AttributeBase" */
  insert_MD_AttributeBase_one?: Maybe<Md_AttributeBase>;
  /** insert data into the table: "MD_BooleanAttribute" */
  insert_MD_BooleanAttribute?: Maybe<Md_BooleanAttribute_Mutation_Response>;
  /** insert a single row into the table: "MD_BooleanAttribute" */
  insert_MD_BooleanAttribute_one?: Maybe<Md_BooleanAttribute>;
  /** insert data into the table: "MD_BooleanDisplayOption" */
  insert_MD_BooleanDisplayOption?: Maybe<Md_BooleanDisplayOption_Mutation_Response>;
  /** insert a single row into the table: "MD_BooleanDisplayOption" */
  insert_MD_BooleanDisplayOption_one?: Maybe<Md_BooleanDisplayOption>;
  /** insert data into the table: "MD_Catalog" */
  insert_MD_Catalog?: Maybe<Md_Catalog_Mutation_Response>;
  /** insert a single row into the table: "MD_Catalog" */
  insert_MD_Catalog_one?: Maybe<Md_Catalog>;
  /** insert data into the table: "MD_CodeTableAttribute" */
  insert_MD_CodeTableAttribute?: Maybe<Md_CodeTableAttribute_Mutation_Response>;
  /** insert a single row into the table: "MD_CodeTableAttribute" */
  insert_MD_CodeTableAttribute_one?: Maybe<Md_CodeTableAttribute>;
  /** insert data into the table: "MD_CodeTables" */
  insert_MD_CodeTables?: Maybe<Md_CodeTables_Mutation_Response>;
  /** insert a single row into the table: "MD_CodeTables" */
  insert_MD_CodeTables_one?: Maybe<Md_CodeTables>;
  /** insert data into the table: "MD_ComponentUsage" */
  insert_MD_ComponentUsage?: Maybe<Md_ComponentUsage_Mutation_Response>;
  /** insert data into the table: "MD_ComponentUsageAttributes" */
  insert_MD_ComponentUsageAttributes?: Maybe<Md_ComponentUsageAttributes_Mutation_Response>;
  /** insert a single row into the table: "MD_ComponentUsageAttributes" */
  insert_MD_ComponentUsageAttributes_one?: Maybe<Md_ComponentUsageAttributes>;
  /** insert a single row into the table: "MD_ComponentUsage" */
  insert_MD_ComponentUsage_one?: Maybe<Md_ComponentUsage>;
  /** insert data into the table: "MD_Measure" */
  insert_MD_Measure?: Maybe<Md_Measure_Mutation_Response>;
  /** insert a single row into the table: "MD_Measure" */
  insert_MD_Measure_one?: Maybe<Md_Measure>;
  /** insert data into the table: "MD_MeasurementAttribute" */
  insert_MD_MeasurementAttribute?: Maybe<Md_MeasurementAttribute_Mutation_Response>;
  /** insert a single row into the table: "MD_MeasurementAttribute" */
  insert_MD_MeasurementAttribute_one?: Maybe<Md_MeasurementAttribute>;
  /** insert data into the table: "MD_MeasurementDisplayOption" */
  insert_MD_MeasurementDisplayOption?: Maybe<Md_MeasurementDisplayOption_Mutation_Response>;
  /** insert a single row into the table: "MD_MeasurementDisplayOption" */
  insert_MD_MeasurementDisplayOption_one?: Maybe<Md_MeasurementDisplayOption>;
  /** insert data into the table: "MD_MultivalueAttribute" */
  insert_MD_MultivalueAttribute?: Maybe<Md_MultivalueAttribute_Mutation_Response>;
  /** insert a single row into the table: "MD_MultivalueAttribute" */
  insert_MD_MultivalueAttribute_one?: Maybe<Md_MultivalueAttribute>;
  /** insert data into the table: "MD_MultivalueDisplayOption" */
  insert_MD_MultivalueDisplayOption?: Maybe<Md_MultivalueDisplayOption_Mutation_Response>;
  /** insert a single row into the table: "MD_MultivalueDisplayOption" */
  insert_MD_MultivalueDisplayOption_one?: Maybe<Md_MultivalueDisplayOption>;
  /** insert data into the table: "MD_ProductCategory" */
  insert_MD_ProductCategory?: Maybe<Md_ProductCategory_Mutation_Response>;
  /** insert a single row into the table: "MD_ProductCategory" */
  insert_MD_ProductCategory_one?: Maybe<Md_ProductCategory>;
  /** insert data into the table: "MD_ProductSubcategory" */
  insert_MD_ProductSubcategory?: Maybe<Md_ProductSubcategory_Mutation_Response>;
  /** insert a single row into the table: "MD_ProductSubcategory" */
  insert_MD_ProductSubcategory_one?: Maybe<Md_ProductSubcategory>;
  /** insert data into the table: "MaintenanceCoating" */
  insert_MaintenanceCoating?: Maybe<MaintenanceCoating_Mutation_Response>;
  /** insert data into the table: "MaintenanceCoatingSubstrate" */
  insert_MaintenanceCoatingSubstrate?: Maybe<MaintenanceCoatingSubstrate_Mutation_Response>;
  /** insert a single row into the table: "MaintenanceCoatingSubstrate" */
  insert_MaintenanceCoatingSubstrate_one?: Maybe<MaintenanceCoatingSubstrate>;
  /** insert data into the table: "MaintenanceCoatingUsage" */
  insert_MaintenanceCoatingUsage?: Maybe<MaintenanceCoatingUsage_Mutation_Response>;
  /** insert a single row into the table: "MaintenanceCoatingUsage" */
  insert_MaintenanceCoatingUsage_one?: Maybe<MaintenanceCoatingUsage>;
  /** insert a single row into the table: "MaintenanceCoating" */
  insert_MaintenanceCoating_one?: Maybe<MaintenanceCoating>;
  /** insert data into the table: "ManufacturedProduct" */
  insert_ManufacturedProduct?: Maybe<ManufacturedProduct_Mutation_Response>;
  /** insert a single row into the table: "ManufacturedProduct" */
  insert_ManufacturedProduct_one?: Maybe<ManufacturedProduct>;
  /** insert data into the table: "Manufacturer" */
  insert_Manufacturer?: Maybe<Manufacturer_Mutation_Response>;
  /** insert data into the table: "ManufacturerChangeHistory" */
  insert_ManufacturerChangeHistory?: Maybe<ManufacturerChangeHistory_Mutation_Response>;
  /** insert a single row into the table: "ManufacturerChangeHistory" */
  insert_ManufacturerChangeHistory_one?: Maybe<ManufacturerChangeHistory>;
  /** insert data into the table: "ManufacturerList" */
  insert_ManufacturerList?: Maybe<ManufacturerList_Mutation_Response>;
  /** insert a single row into the table: "ManufacturerList" */
  insert_ManufacturerList_one?: Maybe<ManufacturerList>;
  /** insert a single row into the table: "Manufacturer" */
  insert_Manufacturer_one?: Maybe<Manufacturer>;
  /** insert data into the table: "MultiplyCoverConfig" */
  insert_MultiplyCoverConfig?: Maybe<MultiplyCoverConfig_Mutation_Response>;
  /** insert a single row into the table: "MultiplyCoverConfig" */
  insert_MultiplyCoverConfig_one?: Maybe<MultiplyCoverConfig>;
  /** insert data into the table: "MultiplyCoverSubassemblyUsage" */
  insert_MultiplyCoverSubassemblyUsage?: Maybe<MultiplyCoverSubassemblyUsage_Mutation_Response>;
  /** insert a single row into the table: "MultiplyCoverSubassemblyUsage" */
  insert_MultiplyCoverSubassemblyUsage_one?: Maybe<MultiplyCoverSubassemblyUsage>;
  /** insert data into the table: "NavAssembly" */
  insert_NavAssembly?: Maybe<NavAssembly_Mutation_Response>;
  /** insert data into the table: "NavAssemblyMultiplyInfo" */
  insert_NavAssemblyMultiplyInfo?: Maybe<NavAssemblyMultiplyInfo_Mutation_Response>;
  /** insert a single row into the table: "NavAssemblyMultiplyInfo" */
  insert_NavAssemblyMultiplyInfo_one?: Maybe<NavAssemblyMultiplyInfo>;
  /** insert a single row into the table: "NavAssembly" */
  insert_NavAssembly_one?: Maybe<NavAssembly>;
  /** insert data into the table: "NavLayer" */
  insert_NavLayer?: Maybe<NavLayer_Mutation_Response>;
  /** insert a single row into the table: "NavLayer" */
  insert_NavLayer_one?: Maybe<NavLayer>;
  /** insert data into the table: "NavMultiplyCoverConfig" */
  insert_NavMultiplyCoverConfig?: Maybe<NavMultiplyCoverConfig_Mutation_Response>;
  /** insert a single row into the table: "NavMultiplyCoverConfig" */
  insert_NavMultiplyCoverConfig_one?: Maybe<NavMultiplyCoverConfig>;
  /** insert data into the table: "NavSecurementLayer" */
  insert_NavSecurementLayer?: Maybe<NavSecurementLayer_Mutation_Response>;
  /** insert a single row into the table: "NavSecurementLayer" */
  insert_NavSecurementLayer_one?: Maybe<NavSecurementLayer>;
  /** insert data into the table: "NavSecurementUsage" */
  insert_NavSecurementUsage?: Maybe<NavSecurementUsage_Mutation_Response>;
  /** insert a single row into the table: "NavSecurementUsage" */
  insert_NavSecurementUsage_one?: Maybe<NavSecurementUsage>;
  /** insert data into the table: "NavUsage" */
  insert_NavUsage?: Maybe<NavUsage_Mutation_Response>;
  /** insert data into the table: "NavUsageSA" */
  insert_NavUsageSA?: Maybe<NavUsageSa_Mutation_Response>;
  /** insert a single row into the table: "NavUsageSA" */
  insert_NavUsageSA_one?: Maybe<NavUsageSa>;
  /** insert a single row into the table: "NavUsage" */
  insert_NavUsage_one?: Maybe<NavUsage>;
  /** insert data into the table: "Nav_SinglePlyBondingAdhesives" */
  insert_Nav_SinglePlyBondingAdhesives?: Maybe<Nav_SinglePlyBondingAdhesives_Mutation_Response>;
  /** insert a single row into the table: "Nav_SinglePlyBondingAdhesives" */
  insert_Nav_SinglePlyBondingAdhesives_one?: Maybe<Nav_SinglePlyBondingAdhesives>;
  /** insert data into the table: "Nav_SinglePlyLapAdhesives" */
  insert_Nav_SinglePlyLapAdhesives?: Maybe<Nav_SinglePlyLapAdhesives_Mutation_Response>;
  /** insert a single row into the table: "Nav_SinglePlyLapAdhesives" */
  insert_Nav_SinglePlyLapAdhesives_one?: Maybe<Nav_SinglePlyLapAdhesives>;
  /** insert data into the table: "NextAssemblyNum" */
  insert_NextAssemblyNum?: Maybe<NextAssemblyNum_Mutation_Response>;
  /** insert a single row into the table: "NextAssemblyNum" */
  insert_NextAssemblyNum_one?: Maybe<NextAssemblyNum>;
  /** insert data into the table: "Note" */
  insert_Note?: Maybe<Note_Mutation_Response>;
  /** insert a single row into the table: "Note" */
  insert_Note_one?: Maybe<Note>;
  /** insert data into the table: "OtherPly" */
  insert_OtherPly?: Maybe<OtherPly_Mutation_Response>;
  /** insert data into the table: "OtherPlyUsage" */
  insert_OtherPlyUsage?: Maybe<OtherPlyUsage_Mutation_Response>;
  /** insert a single row into the table: "OtherPlyUsage" */
  insert_OtherPlyUsage_one?: Maybe<OtherPlyUsage>;
  /** insert a single row into the table: "OtherPly" */
  insert_OtherPly_one?: Maybe<OtherPly>;
  /** insert data into the table: "PreassembledFastening" */
  insert_PreassembledFastening?: Maybe<PreassembledFastening_Mutation_Response>;
  /** insert data into the table: "PreassembledFasteningUsage" */
  insert_PreassembledFasteningUsage?: Maybe<PreassembledFasteningUsage_Mutation_Response>;
  /** insert a single row into the table: "PreassembledFasteningUsage" */
  insert_PreassembledFasteningUsage_one?: Maybe<PreassembledFasteningUsage>;
  /** insert a single row into the table: "PreassembledFastening" */
  insert_PreassembledFastening_one?: Maybe<PreassembledFastening>;
  /** insert data into the table: "PrivateLabel" */
  insert_PrivateLabel?: Maybe<PrivateLabel_Mutation_Response>;
  /** insert a single row into the table: "PrivateLabel" */
  insert_PrivateLabel_one?: Maybe<PrivateLabel>;
  /** insert data into the table: "PrivateLabeler" */
  insert_PrivateLabeler?: Maybe<PrivateLabeler_Mutation_Response>;
  /** insert a single row into the table: "PrivateLabeler" */
  insert_PrivateLabeler_one?: Maybe<PrivateLabeler>;
  /** insert data into the table: "ProductDependencies" */
  insert_ProductDependencies?: Maybe<ProductDependencies_Mutation_Response>;
  /** insert a single row into the table: "ProductDependencies" */
  insert_ProductDependencies_one?: Maybe<ProductDependencies>;
  /** insert data into the table: "ProductsBySubcategory" */
  insert_ProductsBySubcategory?: Maybe<ProductsBySubcategory_Mutation_Response>;
  /** insert a single row into the table: "ProductsBySubcategory" */
  insert_ProductsBySubcategory_one?: Maybe<ProductsBySubcategory>;
  /** insert data into the table: "R_AdhesiveTapeWidth" */
  insert_R_AdhesiveTapeWidth?: Maybe<R_AdhesiveTapeWidth_Mutation_Response>;
  /** insert a single row into the table: "R_AdhesiveTapeWidth" */
  insert_R_AdhesiveTapeWidth_one?: Maybe<R_AdhesiveTapeWidth>;
  /** insert data into the table: "R_ApprovedUse_Subcategory" */
  insert_R_ApprovedUse_Subcategory?: Maybe<R_ApprovedUse_Subcategory_Mutation_Response>;
  /** insert a single row into the table: "R_ApprovedUse_Subcategory" */
  insert_R_ApprovedUse_Subcategory_one?: Maybe<R_ApprovedUse_Subcategory>;
  /** insert data into the table: "R_ComponentType_ApprovedUse" */
  insert_R_ComponentType_ApprovedUse?: Maybe<R_ComponentType_ApprovedUse_Mutation_Response>;
  /** insert a single row into the table: "R_ComponentType_ApprovedUse" */
  insert_R_ComponentType_ApprovedUse_one?: Maybe<R_ComponentType_ApprovedUse>;
  /** insert data into the table: "R_CopingFastener" */
  insert_R_CopingFastener?: Maybe<R_CopingFastener_Mutation_Response>;
  /** insert a single row into the table: "R_CopingFastener" */
  insert_R_CopingFastener_one?: Maybe<R_CopingFastener>;
  /** insert data into the table: "R_Country_StateProvince" */
  insert_R_Country_StateProvince?: Maybe<R_Country_StateProvince_Mutation_Response>;
  /** insert a single row into the table: "R_Country_StateProvince" */
  insert_R_Country_StateProvince_one?: Maybe<R_Country_StateProvince>;
  /** insert data into the table: "R_ExpansionJoinFastener" */
  insert_R_ExpansionJoinFastener?: Maybe<R_ExpansionJoinFastener_Mutation_Response>;
  /** insert a single row into the table: "R_ExpansionJoinFastener" */
  insert_R_ExpansionJoinFastener_one?: Maybe<R_ExpansionJoinFastener>;
  /** insert data into the table: "R_FasciaComponentFastenerComponent" */
  insert_R_FasciaComponentFastenerComponent?: Maybe<R_FasciaComponentFastenerComponent_Mutation_Response>;
  /** insert a single row into the table: "R_FasciaComponentFastenerComponent" */
  insert_R_FasciaComponentFastenerComponent_one?: Maybe<R_FasciaComponentFastenerComponent>;
  /** insert data into the table: "R_GutterFasteners" */
  insert_R_GutterFasteners?: Maybe<R_GutterFasteners_Mutation_Response>;
  /** insert a single row into the table: "R_GutterFasteners" */
  insert_R_GutterFasteners_one?: Maybe<R_GutterFasteners>;
  /** insert data into the table: "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
  insert_R_LinerPanelMaterialFiberReinforcedPlasticDeck?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Mutation_Response>;
  /** insert a single row into the table: "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
  insert_R_LinerPanelMaterialFiberReinforcedPlasticDeck_one?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck>;
  /** insert data into the table: "R_MaintenanceCoatingSubstrate" */
  insert_R_MaintenanceCoatingSubstrate?: Maybe<R_MaintenanceCoatingSubstrate_Mutation_Response>;
  /** insert a single row into the table: "R_MaintenanceCoatingSubstrate" */
  insert_R_MaintenanceCoatingSubstrate_one?: Maybe<R_MaintenanceCoatingSubstrate>;
  /** insert data into the table: "R_NavAssembly_NavMultiplyCoverConfig" */
  insert_R_NavAssembly_NavMultiplyCoverConfig?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Mutation_Response>;
  /** insert a single row into the table: "R_NavAssembly_NavMultiplyCoverConfig" */
  insert_R_NavAssembly_NavMultiplyCoverConfig_one?: Maybe<R_NavAssembly_NavMultiplyCoverConfig>;
  /** insert data into the table: "R_ProjectContact" */
  insert_R_ProjectContact?: Maybe<R_ProjectContact_Mutation_Response>;
  /** insert a single row into the table: "R_ProjectContact" */
  insert_R_ProjectContact_one?: Maybe<R_ProjectContact>;
  /** insert data into the table: "R_ProjectNote" */
  insert_R_ProjectNote?: Maybe<R_ProjectNote_Mutation_Response>;
  /** insert a single row into the table: "R_ProjectNote" */
  insert_R_ProjectNote_one?: Maybe<R_ProjectNote>;
  /** insert data into the table: "R_RegletFastener" */
  insert_R_RegletFastener?: Maybe<R_RegletFastener_Mutation_Response>;
  /** insert a single row into the table: "R_RegletFastener" */
  insert_R_RegletFastener_one?: Maybe<R_RegletFastener>;
  /** insert data into the table: "R_RoofAreaContact" */
  insert_R_RoofAreaContact?: Maybe<R_RoofAreaContact_Mutation_Response>;
  /** insert a single row into the table: "R_RoofAreaContact" */
  insert_R_RoofAreaContact_one?: Maybe<R_RoofAreaContact>;
  /** insert data into the table: "R_RoofAreaNote" */
  insert_R_RoofAreaNote?: Maybe<R_RoofAreaNote_Mutation_Response>;
  /** insert a single row into the table: "R_RoofAreaNote" */
  insert_R_RoofAreaNote_one?: Maybe<R_RoofAreaNote>;
  /** insert data into the table: "R_RoofAreaScenario" */
  insert_R_RoofAreaScenario?: Maybe<R_RoofAreaScenario_Mutation_Response>;
  /** insert a single row into the table: "R_RoofAreaScenario" */
  insert_R_RoofAreaScenario_one?: Maybe<R_RoofAreaScenario>;
  /** insert data into the table: "R_ScenarioNote" */
  insert_R_ScenarioNote?: Maybe<R_ScenarioNote_Mutation_Response>;
  /** insert a single row into the table: "R_ScenarioNote" */
  insert_R_ScenarioNote_one?: Maybe<R_ScenarioNote>;
  /** insert data into the table: "R_SinglePly_BondingAdhesives" */
  insert_R_SinglePly_BondingAdhesives?: Maybe<R_SinglePly_BondingAdhesives_Mutation_Response>;
  /** insert a single row into the table: "R_SinglePly_BondingAdhesives" */
  insert_R_SinglePly_BondingAdhesives_one?: Maybe<R_SinglePly_BondingAdhesives>;
  /** insert data into the table: "R_SinglePly_LapAdhesives" */
  insert_R_SinglePly_LapAdhesives?: Maybe<R_SinglePly_LapAdhesives_Mutation_Response>;
  /** insert a single row into the table: "R_SinglePly_LapAdhesives" */
  insert_R_SinglePly_LapAdhesives_one?: Maybe<R_SinglePly_LapAdhesives>;
  /** insert data into the table: "R_Subassembly_MultiplyCoverConfig" */
  insert_R_Subassembly_MultiplyCoverConfig?: Maybe<R_Subassembly_MultiplyCoverConfig_Mutation_Response>;
  /** insert a single row into the table: "R_Subassembly_MultiplyCoverConfig" */
  insert_R_Subassembly_MultiplyCoverConfig_one?: Maybe<R_Subassembly_MultiplyCoverConfig>;
  /** insert data into the table: "R_UserContact" */
  insert_R_UserContact?: Maybe<R_UserContact_Mutation_Response>;
  /** insert a single row into the table: "R_UserContact" */
  insert_R_UserContact_one?: Maybe<R_UserContact>;
  /** insert data into the table: "Reglet" */
  insert_Reglet?: Maybe<Reglet_Mutation_Response>;
  /** insert a single row into the table: "Reglet" */
  insert_Reglet_one?: Maybe<Reglet>;
  /** insert data into the table: "ReviewerWorkspace" */
  insert_ReviewerWorkspace?: Maybe<ReviewerWorkspace_Mutation_Response>;
  /** insert data into the table: "ReviewerWorkspaceData" */
  insert_ReviewerWorkspaceData?: Maybe<ReviewerWorkspaceData_Mutation_Response>;
  /** insert a single row into the table: "ReviewerWorkspaceData" */
  insert_ReviewerWorkspaceData_one?: Maybe<ReviewerWorkspaceData>;
  /** insert a single row into the table: "ReviewerWorkspace" */
  insert_ReviewerWorkspace_one?: Maybe<ReviewerWorkspace>;
  /** insert data into the table: "RoofArea" */
  insert_RoofArea?: Maybe<RoofArea_Mutation_Response>;
  /** insert data into the table: "RoofAreaWindPressure" */
  insert_RoofAreaWindPressure?: Maybe<RoofAreaWindPressure_Mutation_Response>;
  /** insert a single row into the table: "RoofAreaWindPressure" */
  insert_RoofAreaWindPressure_one?: Maybe<RoofAreaWindPressure>;
  /** insert a single row into the table: "RoofArea" */
  insert_RoofArea_one?: Maybe<RoofArea>;
  /** insert data into the table: "RoofAssembly" */
  insert_RoofAssembly?: Maybe<RoofAssembly_Mutation_Response>;
  /** insert data into the table: "RoofAssemblyDelistData" */
  insert_RoofAssemblyDelistData?: Maybe<RoofAssemblyDelistData_Mutation_Response>;
  /** insert a single row into the table: "RoofAssemblyDelistData" */
  insert_RoofAssemblyDelistData_one?: Maybe<RoofAssemblyDelistData>;
  /** insert data into the table: "RoofAssemblyLayers" */
  insert_RoofAssemblyLayers?: Maybe<RoofAssemblyLayers_Mutation_Response>;
  /** insert a single row into the table: "RoofAssemblyLayers" */
  insert_RoofAssemblyLayers_one?: Maybe<RoofAssemblyLayers>;
  /** insert a single row into the table: "RoofAssembly" */
  insert_RoofAssembly_one?: Maybe<RoofAssembly>;
  /** insert data into the table: "RoofProject" */
  insert_RoofProject?: Maybe<RoofProject_Mutation_Response>;
  /** insert a single row into the table: "RoofProject" */
  insert_RoofProject_one?: Maybe<RoofProject>;
  /** insert data into the table: "Scenario" */
  insert_Scenario?: Maybe<Scenario_Mutation_Response>;
  /** insert data into the table: "ScenarioAlert" */
  insert_ScenarioAlert?: Maybe<ScenarioAlert_Mutation_Response>;
  /** insert a single row into the table: "ScenarioAlert" */
  insert_ScenarioAlert_one?: Maybe<ScenarioAlert>;
  /** insert data into the table: "ScenarioLayer" */
  insert_ScenarioLayer?: Maybe<ScenarioLayer_Mutation_Response>;
  /** insert a single row into the table: "ScenarioLayer" */
  insert_ScenarioLayer_one?: Maybe<ScenarioLayer>;
  /** insert data into the table: "ScenarioUsage" */
  insert_ScenarioUsage?: Maybe<ScenarioUsage_Mutation_Response>;
  /** insert a single row into the table: "ScenarioUsage" */
  insert_ScenarioUsage_one?: Maybe<ScenarioUsage>;
  /** insert a single row into the table: "Scenario" */
  insert_Scenario_one?: Maybe<Scenario>;
  /** insert data into the table: "SeamPrimer" */
  insert_SeamPrimer?: Maybe<SeamPrimer_Mutation_Response>;
  /** insert data into the table: "SeamPrimerUsage" */
  insert_SeamPrimerUsage?: Maybe<SeamPrimerUsage_Mutation_Response>;
  /** insert a single row into the table: "SeamPrimerUsage" */
  insert_SeamPrimerUsage_one?: Maybe<SeamPrimerUsage>;
  /** insert a single row into the table: "SeamPrimer" */
  insert_SeamPrimer_one?: Maybe<SeamPrimer>;
  /** insert data into the table: "SeamTapeSystemUsage" */
  insert_SeamTapeSystemUsage?: Maybe<SeamTapeSystemUsage_Mutation_Response>;
  /** insert a single row into the table: "SeamTapeSystemUsage" */
  insert_SeamTapeSystemUsage_one?: Maybe<SeamTapeSystemUsage>;
  /** insert data into the table: "SeamWash" */
  insert_SeamWash?: Maybe<SeamWash_Mutation_Response>;
  /** insert data into the table: "SeamWashUsage" */
  insert_SeamWashUsage?: Maybe<SeamWashUsage_Mutation_Response>;
  /** insert a single row into the table: "SeamWashUsage" */
  insert_SeamWashUsage_one?: Maybe<SeamWashUsage>;
  /** insert a single row into the table: "SeamWash" */
  insert_SeamWash_one?: Maybe<SeamWash>;
  /** insert data into the table: "SearchNavAssembly_ManufacturerProduct" */
  insert_SearchNavAssembly_ManufacturerProduct?: Maybe<SearchNavAssembly_ManufacturerProduct_Mutation_Response>;
  /** insert a single row into the table: "SearchNavAssembly_ManufacturerProduct" */
  insert_SearchNavAssembly_ManufacturerProduct_one?: Maybe<SearchNavAssembly_ManufacturerProduct>;
  /** insert data into the table: "SecurementLayer" */
  insert_SecurementLayer?: Maybe<SecurementLayer_Mutation_Response>;
  /** insert data into the table: "SecurementLayerUsage" */
  insert_SecurementLayerUsage?: Maybe<SecurementLayerUsage_Mutation_Response>;
  /** insert a single row into the table: "SecurementLayerUsage" */
  insert_SecurementLayerUsage_one?: Maybe<SecurementLayerUsage>;
  /** insert a single row into the table: "SecurementLayer" */
  insert_SecurementLayer_one?: Maybe<SecurementLayer>;
  /** insert data into the table: "SelfSecurementUsage" */
  insert_SelfSecurementUsage?: Maybe<SelfSecurementUsage_Mutation_Response>;
  /** insert a single row into the table: "SelfSecurementUsage" */
  insert_SelfSecurementUsage_one?: Maybe<SelfSecurementUsage>;
  /** insert data into the table: "SeparatorSheet" */
  insert_SeparatorSheet?: Maybe<SeparatorSheet_Mutation_Response>;
  /** insert data into the table: "SeparatorSheetUsage" */
  insert_SeparatorSheetUsage?: Maybe<SeparatorSheetUsage_Mutation_Response>;
  /** insert a single row into the table: "SeparatorSheetUsage" */
  insert_SeparatorSheetUsage_one?: Maybe<SeparatorSheetUsage>;
  /** insert a single row into the table: "SeparatorSheet" */
  insert_SeparatorSheet_one?: Maybe<SeparatorSheet>;
  /** insert data into the table: "Shingles" */
  insert_Shingles?: Maybe<Shingles_Mutation_Response>;
  /** insert a single row into the table: "Shingles" */
  insert_Shingles_one?: Maybe<Shingles>;
  /** insert data into the table: "SinglePlyCover" */
  insert_SinglePlyCover?: Maybe<SinglePlyCover_Mutation_Response>;
  /** insert a single row into the table: "SinglePlyCover" */
  insert_SinglePlyCover_one?: Maybe<SinglePlyCover>;
  /** insert data into the table: "SinglePlyUsage" */
  insert_SinglePlyUsage?: Maybe<SinglePlyUsage_Mutation_Response>;
  /** insert a single row into the table: "SinglePlyUsage" */
  insert_SinglePlyUsage_one?: Maybe<SinglePlyUsage>;
  /** insert data into the table: "SprayFoamInsulationUsage" */
  insert_SprayFoamInsulationUsage?: Maybe<SprayFoamInsulationUsage_Mutation_Response>;
  /** insert a single row into the table: "SprayFoamInsulationUsage" */
  insert_SprayFoamInsulationUsage_one?: Maybe<SprayFoamInsulationUsage>;
  /** insert data into the table: "SprayedFoamInsulation" */
  insert_SprayedFoamInsulation?: Maybe<SprayedFoamInsulation_Mutation_Response>;
  /** insert a single row into the table: "SprayedFoamInsulation" */
  insert_SprayedFoamInsulation_one?: Maybe<SprayedFoamInsulation>;
  /** insert data into the table: "StandingLapSeamCover" */
  insert_StandingLapSeamCover?: Maybe<StandingLapSeamCover_Mutation_Response>;
  /** insert a single row into the table: "StandingLapSeamCover" */
  insert_StandingLapSeamCover_one?: Maybe<StandingLapSeamCover>;
  /** insert data into the table: "StandingLapSeamUsage" */
  insert_StandingLapSeamUsage?: Maybe<StandingLapSeamUsage_Mutation_Response>;
  /** insert a single row into the table: "StandingLapSeamUsage" */
  insert_StandingLapSeamUsage_one?: Maybe<StandingLapSeamUsage>;
  /** insert data into the table: "SteelDeck" */
  insert_SteelDeck?: Maybe<SteelDeck_Mutation_Response>;
  /** insert data into the table: "SteelDeckLayer" */
  insert_SteelDeckLayer?: Maybe<SteelDeckLayer_Mutation_Response>;
  /** insert a single row into the table: "SteelDeckLayer" */
  insert_SteelDeckLayer_one?: Maybe<SteelDeckLayer>;
  /** insert data into the table: "SteelDeckUsage" */
  insert_SteelDeckUsage?: Maybe<SteelDeckUsage_Mutation_Response>;
  /** insert a single row into the table: "SteelDeckUsage" */
  insert_SteelDeckUsage_one?: Maybe<SteelDeckUsage>;
  /** insert a single row into the table: "SteelDeck" */
  insert_SteelDeck_one?: Maybe<SteelDeck>;
  /** insert data into the table: "StressPlate" */
  insert_StressPlate?: Maybe<StressPlate_Mutation_Response>;
  /** insert data into the table: "StressPlateUsage" */
  insert_StressPlateUsage?: Maybe<StressPlateUsage_Mutation_Response>;
  /** insert a single row into the table: "StressPlateUsage" */
  insert_StressPlateUsage_one?: Maybe<StressPlateUsage>;
  /** insert a single row into the table: "StressPlate" */
  insert_StressPlate_one?: Maybe<StressPlate>;
  /** insert data into the table: "StructuralConcreteLayer" */
  insert_StructuralConcreteLayer?: Maybe<StructuralConcreteLayer_Mutation_Response>;
  /** insert a single row into the table: "StructuralConcreteLayer" */
  insert_StructuralConcreteLayer_one?: Maybe<StructuralConcreteLayer>;
  /** insert data into the table: "StructureLayer" */
  insert_StructureLayer?: Maybe<StructureLayer_Mutation_Response>;
  /** insert a single row into the table: "StructureLayer" */
  insert_StructureLayer_one?: Maybe<StructureLayer>;
  /** insert data into the table: "StructuredSystem" */
  insert_StructuredSystem?: Maybe<StructuredSystem_Mutation_Response>;
  /** insert data into the table: "StructuredSystemProducts" */
  insert_StructuredSystemProducts?: Maybe<StructuredSystemProducts_Mutation_Response>;
  /** insert a single row into the table: "StructuredSystemProducts" */
  insert_StructuredSystemProducts_one?: Maybe<StructuredSystemProducts>;
  /** insert a single row into the table: "StructuredSystem" */
  insert_StructuredSystem_one?: Maybe<StructuredSystem>;
  /** insert data into the table: "Subassembly" */
  insert_Subassembly?: Maybe<Subassembly_Mutation_Response>;
  /** insert data into the table: "SubassemblyDelistData" */
  insert_SubassemblyDelistData?: Maybe<SubassemblyDelistData_Mutation_Response>;
  /** insert a single row into the table: "SubassemblyDelistData" */
  insert_SubassemblyDelistData_one?: Maybe<SubassemblyDelistData>;
  /** insert data into the table: "SubassemblyE108Rating" */
  insert_SubassemblyE108Rating?: Maybe<SubassemblyE108Rating_Mutation_Response>;
  /** insert a single row into the table: "SubassemblyE108Rating" */
  insert_SubassemblyE108Rating_one?: Maybe<SubassemblyE108Rating>;
  /** insert data into the table: "SubassemblyLayers" */
  insert_SubassemblyLayers?: Maybe<SubassemblyLayers_Mutation_Response>;
  /** insert a single row into the table: "SubassemblyLayers" */
  insert_SubassemblyLayers_one?: Maybe<SubassemblyLayers>;
  /** insert data into the table: "SubassemblyMultiplyConfig" */
  insert_SubassemblyMultiplyConfig?: Maybe<SubassemblyMultiplyConfig_Mutation_Response>;
  /** insert a single row into the table: "SubassemblyMultiplyConfig" */
  insert_SubassemblyMultiplyConfig_one?: Maybe<SubassemblyMultiplyConfig>;
  /** insert a single row into the table: "Subassembly" */
  insert_Subassembly_one?: Maybe<Subassembly>;
  /** insert data into the table: "Subcat_Adhesive" */
  insert_Subcat_Adhesive?: Maybe<Subcat_Adhesive_Mutation_Response>;
  /** insert a single row into the table: "Subcat_Adhesive" */
  insert_Subcat_Adhesive_one?: Maybe<Subcat_Adhesive>;
  /** insert data into the table: "Subcat_AirRetarder" */
  insert_Subcat_AirRetarder?: Maybe<Subcat_AirRetarder_Mutation_Response>;
  /** insert a single row into the table: "Subcat_AirRetarder" */
  insert_Subcat_AirRetarder_one?: Maybe<Subcat_AirRetarder>;
  /** insert data into the table: "Subcat_BaseSheet" */
  insert_Subcat_BaseSheet?: Maybe<Subcat_BaseSheet_Mutation_Response>;
  /** insert a single row into the table: "Subcat_BaseSheet" */
  insert_Subcat_BaseSheet_one?: Maybe<Subcat_BaseSheet>;
  /** insert data into the table: "Subcat_BattBlanket" */
  insert_Subcat_BattBlanket?: Maybe<Subcat_BattBlanket_Mutation_Response>;
  /** insert a single row into the table: "Subcat_BattBlanket" */
  insert_Subcat_BattBlanket_one?: Maybe<Subcat_BattBlanket>;
  /** insert data into the table: "Subcat_BoardStock" */
  insert_Subcat_BoardStock?: Maybe<Subcat_BoardStock_Mutation_Response>;
  /** insert a single row into the table: "Subcat_BoardStock" */
  insert_Subcat_BoardStock_one?: Maybe<Subcat_BoardStock>;
  /** insert data into the table: "Subcat_Coating" */
  insert_Subcat_Coating?: Maybe<Subcat_Coating_Mutation_Response>;
  /** insert a single row into the table: "Subcat_Coating" */
  insert_Subcat_Coating_one?: Maybe<Subcat_Coating>;
  /** insert data into the table: "Subcat_CompositePanel" */
  insert_Subcat_CompositePanel?: Maybe<Subcat_CompositePanel_Mutation_Response>;
  /** insert a single row into the table: "Subcat_CompositePanel" */
  insert_Subcat_CompositePanel_one?: Maybe<Subcat_CompositePanel>;
  /** insert data into the table: "Subcat_CoverStrip" */
  insert_Subcat_CoverStrip?: Maybe<Subcat_CoverStrip_Mutation_Response>;
  /** insert a single row into the table: "Subcat_CoverStrip" */
  insert_Subcat_CoverStrip_one?: Maybe<Subcat_CoverStrip>;
  /** insert data into the table: "Subcat_ExpJoint" */
  insert_Subcat_ExpJoint?: Maybe<Subcat_ExpJoint_Mutation_Response>;
  /** insert a single row into the table: "Subcat_ExpJoint" */
  insert_Subcat_ExpJoint_one?: Maybe<Subcat_ExpJoint>;
  /** insert data into the table: "Subcat_Fastening" */
  insert_Subcat_Fastening?: Maybe<Subcat_Fastening_Mutation_Response>;
  /** insert a single row into the table: "Subcat_Fastening" */
  insert_Subcat_Fastening_one?: Maybe<Subcat_Fastening>;
  /** insert data into the table: "Subcat_FormDeck" */
  insert_Subcat_FormDeck?: Maybe<Subcat_FormDeck_Mutation_Response>;
  /** insert a single row into the table: "Subcat_FormDeck" */
  insert_Subcat_FormDeck_one?: Maybe<Subcat_FormDeck>;
  /** insert data into the table: "Subcat_Gutter" */
  insert_Subcat_Gutter?: Maybe<Subcat_Gutter_Mutation_Response>;
  /** insert a single row into the table: "Subcat_Gutter" */
  insert_Subcat_Gutter_one?: Maybe<Subcat_Gutter>;
  /** insert data into the table: "Subcat_Gypsum" */
  insert_Subcat_Gypsum?: Maybe<Subcat_Gypsum_Mutation_Response>;
  /** insert a single row into the table: "Subcat_Gypsum" */
  insert_Subcat_Gypsum_one?: Maybe<Subcat_Gypsum>;
  /** insert data into the table: "Subcat_LWIC" */
  insert_Subcat_LWIC?: Maybe<Subcat_Lwic_Mutation_Response>;
  /** insert data into the table: "Subcat_LWICDeck" */
  insert_Subcat_LWICDeck?: Maybe<Subcat_LwicDeck_Mutation_Response>;
  /** insert a single row into the table: "Subcat_LWICDeck" */
  insert_Subcat_LWICDeck_one?: Maybe<Subcat_LwicDeck>;
  /** insert a single row into the table: "Subcat_LWIC" */
  insert_Subcat_LWIC_one?: Maybe<Subcat_Lwic>;
  /** insert data into the table: "Subcat_LinerPanel" */
  insert_Subcat_LinerPanel?: Maybe<Subcat_LinerPanel_Mutation_Response>;
  /** insert a single row into the table: "Subcat_LinerPanel" */
  insert_Subcat_LinerPanel_one?: Maybe<Subcat_LinerPanel>;
  /** insert data into the table: "Subcat_LiquidApplied" */
  insert_Subcat_LiquidApplied?: Maybe<Subcat_LiquidApplied_Mutation_Response>;
  /** insert a single row into the table: "Subcat_LiquidApplied" */
  insert_Subcat_LiquidApplied_one?: Maybe<Subcat_LiquidApplied>;
  /** insert data into the table: "Subcat_MaintCoating" */
  insert_Subcat_MaintCoating?: Maybe<Subcat_MaintCoating_Mutation_Response>;
  /** insert a single row into the table: "Subcat_MaintCoating" */
  insert_Subcat_MaintCoating_one?: Maybe<Subcat_MaintCoating>;
  /** insert data into the table: "Subcat_Multiply" */
  insert_Subcat_Multiply?: Maybe<Subcat_Multiply_Mutation_Response>;
  /** insert data into the table: "Subcat_MultiplyCover" */
  insert_Subcat_MultiplyCover?: Maybe<Subcat_MultiplyCover_Mutation_Response>;
  /** insert a single row into the table: "Subcat_MultiplyCover" */
  insert_Subcat_MultiplyCover_one?: Maybe<Subcat_MultiplyCover>;
  /** insert a single row into the table: "Subcat_Multiply" */
  insert_Subcat_Multiply_one?: Maybe<Subcat_Multiply>;
  /** insert data into the table: "Subcat_PerimeterFlashing" */
  insert_Subcat_PerimeterFlashing?: Maybe<Subcat_PerimeterFlashing_Mutation_Response>;
  /** insert a single row into the table: "Subcat_PerimeterFlashing" */
  insert_Subcat_PerimeterFlashing_one?: Maybe<Subcat_PerimeterFlashing>;
  /** insert data into the table: "Subcat_PlasticDeck" */
  insert_Subcat_PlasticDeck?: Maybe<Subcat_PlasticDeck_Mutation_Response>;
  /** insert a single row into the table: "Subcat_PlasticDeck" */
  insert_Subcat_PlasticDeck_one?: Maybe<Subcat_PlasticDeck>;
  /** insert data into the table: "Subcat_Primer" */
  insert_Subcat_Primer?: Maybe<Subcat_Primer_Mutation_Response>;
  /** insert a single row into the table: "Subcat_Primer" */
  insert_Subcat_Primer_one?: Maybe<Subcat_Primer>;
  /** insert data into the table: "Subcat_Seaming" */
  insert_Subcat_Seaming?: Maybe<Subcat_Seaming_Mutation_Response>;
  /** insert a single row into the table: "Subcat_Seaming" */
  insert_Subcat_Seaming_one?: Maybe<Subcat_Seaming>;
  /** insert data into the table: "Subcat_SeparatorSheet" */
  insert_Subcat_SeparatorSheet?: Maybe<Subcat_SeparatorSheet_Mutation_Response>;
  /** insert a single row into the table: "Subcat_SeparatorSheet" */
  insert_Subcat_SeparatorSheet_one?: Maybe<Subcat_SeparatorSheet>;
  /** insert data into the table: "Subcat_Shingles" */
  insert_Subcat_Shingles?: Maybe<Subcat_Shingles_Mutation_Response>;
  /** insert a single row into the table: "Subcat_Shingles" */
  insert_Subcat_Shingles_one?: Maybe<Subcat_Shingles>;
  /** insert data into the table: "Subcat_SinglePly" */
  insert_Subcat_SinglePly?: Maybe<Subcat_SinglePly_Mutation_Response>;
  /** insert data into the table: "Subcat_SinglePlyCover" */
  insert_Subcat_SinglePlyCover?: Maybe<Subcat_SinglePlyCover_Mutation_Response>;
  /** insert a single row into the table: "Subcat_SinglePlyCover" */
  insert_Subcat_SinglePlyCover_one?: Maybe<Subcat_SinglePlyCover>;
  /** insert a single row into the table: "Subcat_SinglePly" */
  insert_Subcat_SinglePly_one?: Maybe<Subcat_SinglePly>;
  /** insert data into the table: "Subcat_SprayFoam" */
  insert_Subcat_SprayFoam?: Maybe<Subcat_SprayFoam_Mutation_Response>;
  /** insert a single row into the table: "Subcat_SprayFoam" */
  insert_Subcat_SprayFoam_one?: Maybe<Subcat_SprayFoam>;
  /** insert data into the table: "Subcat_StandingLapSeam" */
  insert_Subcat_StandingLapSeam?: Maybe<Subcat_StandingLapSeam_Mutation_Response>;
  /** insert a single row into the table: "Subcat_StandingLapSeam" */
  insert_Subcat_StandingLapSeam_one?: Maybe<Subcat_StandingLapSeam>;
  /** insert data into the table: "Subcat_SteelDeck" */
  insert_Subcat_SteelDeck?: Maybe<Subcat_SteelDeck_Mutation_Response>;
  /** insert a single row into the table: "Subcat_SteelDeck" */
  insert_Subcat_SteelDeck_one?: Maybe<Subcat_SteelDeck>;
  /** insert data into the table: "Subcat_Surfacing" */
  insert_Subcat_Surfacing?: Maybe<Subcat_Surfacing_Mutation_Response>;
  /** insert a single row into the table: "Subcat_Surfacing" */
  insert_Subcat_Surfacing_one?: Maybe<Subcat_Surfacing>;
  /** insert data into the table: "Subcat_VaporRetarder" */
  insert_Subcat_VaporRetarder?: Maybe<Subcat_VaporRetarder_Mutation_Response>;
  /** insert a single row into the table: "Subcat_VaporRetarder" */
  insert_Subcat_VaporRetarder_one?: Maybe<Subcat_VaporRetarder>;
  /** insert data into the table: "Subcat_Weld" */
  insert_Subcat_Weld?: Maybe<Subcat_Weld_Mutation_Response>;
  /** insert a single row into the table: "Subcat_Weld" */
  insert_Subcat_Weld_one?: Maybe<Subcat_Weld>;
  /** insert data into the table: "Subcat_Wood" */
  insert_Subcat_Wood?: Maybe<Subcat_Wood_Mutation_Response>;
  /** insert data into the table: "Subcat_WoodFiberDeck" */
  insert_Subcat_WoodFiberDeck?: Maybe<Subcat_WoodFiberDeck_Mutation_Response>;
  /** insert a single row into the table: "Subcat_WoodFiberDeck" */
  insert_Subcat_WoodFiberDeck_one?: Maybe<Subcat_WoodFiberDeck>;
  /** insert a single row into the table: "Subcat_Wood" */
  insert_Subcat_Wood_one?: Maybe<Subcat_Wood>;
  /** insert data into the table: "SubstratePrimer" */
  insert_SubstratePrimer?: Maybe<SubstratePrimer_Mutation_Response>;
  /** insert data into the table: "SubstratePrimerUsage" */
  insert_SubstratePrimerUsage?: Maybe<SubstratePrimerUsage_Mutation_Response>;
  /** insert a single row into the table: "SubstratePrimerUsage" */
  insert_SubstratePrimerUsage_one?: Maybe<SubstratePrimerUsage>;
  /** insert a single row into the table: "SubstratePrimer" */
  insert_SubstratePrimer_one?: Maybe<SubstratePrimer>;
  /** insert data into the table: "Surfacing" */
  insert_Surfacing?: Maybe<Surfacing_Mutation_Response>;
  /** insert data into the table: "SurfacingUsage" */
  insert_SurfacingUsage?: Maybe<SurfacingUsage_Mutation_Response>;
  /** insert a single row into the table: "SurfacingUsage" */
  insert_SurfacingUsage_one?: Maybe<SurfacingUsage>;
  /** insert a single row into the table: "Surfacing" */
  insert_Surfacing_one?: Maybe<Surfacing>;
  /** insert data into the table: "TapeWidth" */
  insert_TapeWidth?: Maybe<TapeWidth_Mutation_Response>;
  /** insert a single row into the table: "TapeWidth" */
  insert_TapeWidth_one?: Maybe<TapeWidth>;
  /** insert data into the table: "ThermalBarrier" */
  insert_ThermalBarrier?: Maybe<ThermalBarrier_Mutation_Response>;
  /** insert data into the table: "ThermalBarrierBoardSizes" */
  insert_ThermalBarrierBoardSizes?: Maybe<ThermalBarrierBoardSizes_Mutation_Response>;
  /** insert a single row into the table: "ThermalBarrierBoardSizes" */
  insert_ThermalBarrierBoardSizes_one?: Maybe<ThermalBarrierBoardSizes>;
  /** insert data into the table: "ThermalBarrierFacerBottom" */
  insert_ThermalBarrierFacerBottom?: Maybe<ThermalBarrierFacerBottom_Mutation_Response>;
  /** insert a single row into the table: "ThermalBarrierFacerBottom" */
  insert_ThermalBarrierFacerBottom_one?: Maybe<ThermalBarrierFacerBottom>;
  /** insert data into the table: "ThermalBarrierFacerTop" */
  insert_ThermalBarrierFacerTop?: Maybe<ThermalBarrierFacerTop_Mutation_Response>;
  /** insert a single row into the table: "ThermalBarrierFacerTop" */
  insert_ThermalBarrierFacerTop_one?: Maybe<ThermalBarrierFacerTop>;
  /** insert data into the table: "ThermalBarrierUsage" */
  insert_ThermalBarrierUsage?: Maybe<ThermalBarrierUsage_Mutation_Response>;
  /** insert a single row into the table: "ThermalBarrierUsage" */
  insert_ThermalBarrierUsage_one?: Maybe<ThermalBarrierUsage>;
  /** insert a single row into the table: "ThermalBarrier" */
  insert_ThermalBarrier_one?: Maybe<ThermalBarrier>;
  /** insert data into the table: "TopCoat" */
  insert_TopCoat?: Maybe<TopCoat_Mutation_Response>;
  /** insert data into the table: "TopCoatUsage" */
  insert_TopCoatUsage?: Maybe<TopCoatUsage_Mutation_Response>;
  /** insert a single row into the table: "TopCoatUsage" */
  insert_TopCoatUsage_one?: Maybe<TopCoatUsage>;
  /** insert a single row into the table: "TopCoat" */
  insert_TopCoat_one?: Maybe<TopCoat>;
  /** insert data into the table: "Usage" */
  insert_Usage?: Maybe<Usage_Mutation_Response>;
  /** insert data into the table: "UsageTradeNameView" */
  insert_UsageTradeNameView?: Maybe<UsageTradeNameView_Mutation_Response>;
  /** insert a single row into the table: "UsageTradeNameView" */
  insert_UsageTradeNameView_one?: Maybe<UsageTradeNameView>;
  /** insert a single row into the table: "Usage" */
  insert_Usage_one?: Maybe<Usage>;
  /** insert data into the table: "UserReportBug" */
  insert_UserReportBug?: Maybe<UserReportBug_Mutation_Response>;
  /** insert a single row into the table: "UserReportBug" */
  insert_UserReportBug_one?: Maybe<UserReportBug>;
  /** insert data into the table: "UserShareLink" */
  insert_UserShareLink?: Maybe<UserShareLink_Mutation_Response>;
  /** insert a single row into the table: "UserShareLink" */
  insert_UserShareLink_one?: Maybe<UserShareLink>;
  /** insert data into the table: "VaporRetarder" */
  insert_VaporRetarder?: Maybe<VaporRetarder_Mutation_Response>;
  /** insert data into the table: "VaporRetarderUsage" */
  insert_VaporRetarderUsage?: Maybe<VaporRetarderUsage_Mutation_Response>;
  /** insert a single row into the table: "VaporRetarderUsage" */
  insert_VaporRetarderUsage_one?: Maybe<VaporRetarderUsage>;
  /** insert a single row into the table: "VaporRetarder" */
  insert_VaporRetarder_one?: Maybe<VaporRetarder>;
  /** insert data into the table: "WeldArcUsage" */
  insert_WeldArcUsage?: Maybe<WeldArcUsage_Mutation_Response>;
  /** insert a single row into the table: "WeldArcUsage" */
  insert_WeldArcUsage_one?: Maybe<WeldArcUsage>;
  /** insert data into the table: "WeldHeatUsage" */
  insert_WeldHeatUsage?: Maybe<WeldHeatUsage_Mutation_Response>;
  /** insert a single row into the table: "WeldHeatUsage" */
  insert_WeldHeatUsage_one?: Maybe<WeldHeatUsage>;
  /** insert data into the table: "WeldSolventUsage" */
  insert_WeldSolventUsage?: Maybe<WeldSolventUsage_Mutation_Response>;
  /** insert a single row into the table: "WeldSolventUsage" */
  insert_WeldSolventUsage_one?: Maybe<WeldSolventUsage>;
  /** insert data into the table: "WeldSystemUsage" */
  insert_WeldSystemUsage?: Maybe<WeldSystemUsage_Mutation_Response>;
  /** insert a single row into the table: "WeldSystemUsage" */
  insert_WeldSystemUsage_one?: Maybe<WeldSystemUsage>;
  /** insert data into the table: "WeldTorchedUsage" */
  insert_WeldTorchedUsage?: Maybe<WeldTorchedUsage_Mutation_Response>;
  /** insert a single row into the table: "WeldTorchedUsage" */
  insert_WeldTorchedUsage_one?: Maybe<WeldTorchedUsage>;
  /** insert data into the table: "WoodDeck" */
  insert_WoodDeck?: Maybe<WoodDeck_Mutation_Response>;
  /** insert data into the table: "WoodDeckUsage" */
  insert_WoodDeckUsage?: Maybe<WoodDeckUsage_Mutation_Response>;
  /** insert a single row into the table: "WoodDeckUsage" */
  insert_WoodDeckUsage_one?: Maybe<WoodDeckUsage>;
  /** insert a single row into the table: "WoodDeck" */
  insert_WoodDeck_one?: Maybe<WoodDeck>;
  /** insert data into the table: "vNavAssembly_Layers" */
  insert_vNavAssembly_Layers?: Maybe<VNavAssembly_Layers_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Layers" */
  insert_vNavAssembly_Layers_one?: Maybe<VNavAssembly_Layers>;
  /** insert data into the table: "vNavAssembly_ManufacturerOnlySearch" */
  insert_vNavAssembly_ManufacturerOnlySearch?: Maybe<VNavAssembly_ManufacturerOnlySearch_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_ManufacturerOnlySearch" */
  insert_vNavAssembly_ManufacturerOnlySearch_one?: Maybe<VNavAssembly_ManufacturerOnlySearch>;
  /** insert data into the table: "vNavAssembly_RoofTypeCount" */
  insert_vNavAssembly_RoofTypeCount?: Maybe<VNavAssembly_RoofTypeCount_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_RoofTypeCount" */
  insert_vNavAssembly_RoofTypeCount_one?: Maybe<VNavAssembly_RoofTypeCount>;
  /** insert data into the table: "vNavAssembly_Search" */
  insert_vNavAssembly_Search?: Maybe<VNavAssembly_Search_Mutation_Response>;
  /** insert data into the table: "vNavAssembly_Search_AirRetarder" */
  insert_vNavAssembly_Search_AirRetarder?: Maybe<VNavAssembly_Search_AirRetarder_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_AirRetarder" */
  insert_vNavAssembly_Search_AirRetarder_one?: Maybe<VNavAssembly_Search_AirRetarder>;
  /** insert data into the table: "vNavAssembly_Search_BaseCoat" */
  insert_vNavAssembly_Search_BaseCoat?: Maybe<VNavAssembly_Search_BaseCoat_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_BaseCoat" */
  insert_vNavAssembly_Search_BaseCoat_one?: Maybe<VNavAssembly_Search_BaseCoat>;
  /** insert data into the table: "vNavAssembly_Search_BasePly" */
  insert_vNavAssembly_Search_BasePly?: Maybe<VNavAssembly_Search_BasePly_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_BasePly" */
  insert_vNavAssembly_Search_BasePly_one?: Maybe<VNavAssembly_Search_BasePly>;
  /** insert data into the table: "vNavAssembly_Search_BaseSheet" */
  insert_vNavAssembly_Search_BaseSheet?: Maybe<VNavAssembly_Search_BaseSheet_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_BaseSheet" */
  insert_vNavAssembly_Search_BaseSheet_one?: Maybe<VNavAssembly_Search_BaseSheet>;
  /** insert data into the table: "vNavAssembly_Search_BoardStock" */
  insert_vNavAssembly_Search_BoardStock?: Maybe<VNavAssembly_Search_BoardStock_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_BoardStock" */
  insert_vNavAssembly_Search_BoardStock_one?: Maybe<VNavAssembly_Search_BoardStock>;
  /** insert data into the table: "vNavAssembly_Search_CapPly" */
  insert_vNavAssembly_Search_CapPly?: Maybe<VNavAssembly_Search_CapPly_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_CapPly" */
  insert_vNavAssembly_Search_CapPly_one?: Maybe<VNavAssembly_Search_CapPly>;
  /** insert data into the table: "vNavAssembly_Search_Coat" */
  insert_vNavAssembly_Search_Coat?: Maybe<VNavAssembly_Search_Coat_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_Coat" */
  insert_vNavAssembly_Search_Coat_one?: Maybe<VNavAssembly_Search_Coat>;
  /** insert data into the table: "vNavAssembly_Search_Coating" */
  insert_vNavAssembly_Search_Coating?: Maybe<VNavAssembly_Search_Coating_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_Coating" */
  insert_vNavAssembly_Search_Coating_one?: Maybe<VNavAssembly_Search_Coating>;
  /** insert data into the table: "vNavAssembly_Search_CoverBoard" */
  insert_vNavAssembly_Search_CoverBoard?: Maybe<VNavAssembly_Search_CoverBoard_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_CoverBoard" */
  insert_vNavAssembly_Search_CoverBoard_one?: Maybe<VNavAssembly_Search_CoverBoard>;
  /** insert data into the table: "vNavAssembly_Search_CoverCompositePanel" */
  insert_vNavAssembly_Search_CoverCompositePanel?: Maybe<VNavAssembly_Search_CoverCompositePanel_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_CoverCompositePanel" */
  insert_vNavAssembly_Search_CoverCompositePanel_one?: Maybe<VNavAssembly_Search_CoverCompositePanel>;
  /** insert data into the table: "vNavAssembly_Search_CoverSingleply" */
  insert_vNavAssembly_Search_CoverSingleply?: Maybe<VNavAssembly_Search_CoverSingleply_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_CoverSingleply" */
  insert_vNavAssembly_Search_CoverSingleply_one?: Maybe<VNavAssembly_Search_CoverSingleply>;
  /** insert data into the table: "vNavAssembly_Search_CoverStandingLapSeam" */
  insert_vNavAssembly_Search_CoverStandingLapSeam?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_CoverStandingLapSeam" */
  insert_vNavAssembly_Search_CoverStandingLapSeam_one?: Maybe<VNavAssembly_Search_CoverStandingLapSeam>;
  /** insert data into the table: "vNavAssembly_Search_CoverStripDisk" */
  insert_vNavAssembly_Search_CoverStripDisk?: Maybe<VNavAssembly_Search_CoverStripDisk_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_CoverStripDisk" */
  insert_vNavAssembly_Search_CoverStripDisk_one?: Maybe<VNavAssembly_Search_CoverStripDisk>;
  /** insert data into the table: "vNavAssembly_Search_DeckCementitiousPanel" */
  insert_vNavAssembly_Search_DeckCementitiousPanel?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_DeckCementitiousPanel" */
  insert_vNavAssembly_Search_DeckCementitiousPanel_one?: Maybe<VNavAssembly_Search_DeckCementitiousPanel>;
  /** insert data into the table: "vNavAssembly_Search_DeckSteel" */
  insert_vNavAssembly_Search_DeckSteel?: Maybe<VNavAssembly_Search_DeckSteel_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_DeckSteel" */
  insert_vNavAssembly_Search_DeckSteel_one?: Maybe<VNavAssembly_Search_DeckSteel>;
  /** insert data into the table: "vNavAssembly_Search_FormDeck" */
  insert_vNavAssembly_Search_FormDeck?: Maybe<VNavAssembly_Search_FormDeck_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_FormDeck" */
  insert_vNavAssembly_Search_FormDeck_one?: Maybe<VNavAssembly_Search_FormDeck>;
  /** insert data into the table: "vNavAssembly_Search_InsulationBattBlanket" */
  insert_vNavAssembly_Search_InsulationBattBlanket?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_InsulationBattBlanket" */
  insert_vNavAssembly_Search_InsulationBattBlanket_one?: Maybe<VNavAssembly_Search_InsulationBattBlanket>;
  /** insert data into the table: "vNavAssembly_Search_InsulationSprayFoam" */
  insert_vNavAssembly_Search_InsulationSprayFoam?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_InsulationSprayFoam" */
  insert_vNavAssembly_Search_InsulationSprayFoam_one?: Maybe<VNavAssembly_Search_InsulationSprayFoam>;
  /** insert data into the table: "vNavAssembly_Search_LightweightInsulatingConcrete" */
  insert_vNavAssembly_Search_LightweightInsulatingConcrete?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_LightweightInsulatingConcrete" */
  insert_vNavAssembly_Search_LightweightInsulatingConcrete_one?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete>;
  /** insert data into the table: "vNavAssembly_Search_LinerPanel" */
  insert_vNavAssembly_Search_LinerPanel?: Maybe<VNavAssembly_Search_LinerPanel_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_LinerPanel" */
  insert_vNavAssembly_Search_LinerPanel_one?: Maybe<VNavAssembly_Search_LinerPanel>;
  /** insert data into the table: "vNavAssembly_Search_Ply" */
  insert_vNavAssembly_Search_Ply?: Maybe<VNavAssembly_Search_Ply_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_Ply" */
  insert_vNavAssembly_Search_Ply_one?: Maybe<VNavAssembly_Search_Ply>;
  /** insert data into the table: "vNavAssembly_Search_SeparatorSheet" */
  insert_vNavAssembly_Search_SeparatorSheet?: Maybe<VNavAssembly_Search_SeparatorSheet_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_SeparatorSheet" */
  insert_vNavAssembly_Search_SeparatorSheet_one?: Maybe<VNavAssembly_Search_SeparatorSheet>;
  /** insert data into the table: "vNavAssembly_Search_SubstratePrimer" */
  insert_vNavAssembly_Search_SubstratePrimer?: Maybe<VNavAssembly_Search_SubstratePrimer_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_SubstratePrimer" */
  insert_vNavAssembly_Search_SubstratePrimer_one?: Maybe<VNavAssembly_Search_SubstratePrimer>;
  /** insert data into the table: "vNavAssembly_Search_Surfacing" */
  insert_vNavAssembly_Search_Surfacing?: Maybe<VNavAssembly_Search_Surfacing_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_Surfacing" */
  insert_vNavAssembly_Search_Surfacing_one?: Maybe<VNavAssembly_Search_Surfacing>;
  /** insert data into the table: "vNavAssembly_Search_ThermalBarrier" */
  insert_vNavAssembly_Search_ThermalBarrier?: Maybe<VNavAssembly_Search_ThermalBarrier_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_ThermalBarrier" */
  insert_vNavAssembly_Search_ThermalBarrier_one?: Maybe<VNavAssembly_Search_ThermalBarrier>;
  /** insert data into the table: "vNavAssembly_Search_TopCoat" */
  insert_vNavAssembly_Search_TopCoat?: Maybe<VNavAssembly_Search_TopCoat_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_TopCoat" */
  insert_vNavAssembly_Search_TopCoat_one?: Maybe<VNavAssembly_Search_TopCoat>;
  /** insert data into the table: "vNavAssembly_Search_VaporRetarder" */
  insert_vNavAssembly_Search_VaporRetarder?: Maybe<VNavAssembly_Search_VaporRetarder_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Search_VaporRetarder" */
  insert_vNavAssembly_Search_VaporRetarder_one?: Maybe<VNavAssembly_Search_VaporRetarder>;
  /** insert a single row into the table: "vNavAssembly_Search" */
  insert_vNavAssembly_Search_one?: Maybe<VNavAssembly_Search>;
  /** insert data into the table: "vNavAssembly_Usages" */
  insert_vNavAssembly_Usages?: Maybe<VNavAssembly_Usages_Mutation_Response>;
  /** insert a single row into the table: "vNavAssembly_Usages" */
  insert_vNavAssembly_Usages_one?: Maybe<VNavAssembly_Usages>;
  /** update data of the table: "Address" */
  update_Address?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "Address" */
  update_Address_by_pk?: Maybe<Address>;
  /** update data of the table: "AdheredSinglePlyOrphanReport" */
  update_AdheredSinglePlyOrphanReport?: Maybe<AdheredSinglePlyOrphanReport_Mutation_Response>;
  /** update single row of the table: "AdheredSinglePlyOrphanReport" */
  update_AdheredSinglePlyOrphanReport_by_pk?: Maybe<AdheredSinglePlyOrphanReport>;
  /** update data of the table: "AdhesiveFullApplication" */
  update_AdhesiveFullApplication?: Maybe<AdhesiveFullApplication_Mutation_Response>;
  /** update data of the table: "AdhesiveFullApplicationUsage" */
  update_AdhesiveFullApplicationUsage?: Maybe<AdhesiveFullApplicationUsage_Mutation_Response>;
  /** update single row of the table: "AdhesiveFullApplicationUsage" */
  update_AdhesiveFullApplicationUsage_by_pk?: Maybe<AdhesiveFullApplicationUsage>;
  /** update single row of the table: "AdhesiveFullApplication" */
  update_AdhesiveFullApplication_by_pk?: Maybe<AdhesiveFullApplication>;
  /** update data of the table: "AdhesiveRibbonUsage" */
  update_AdhesiveRibbonUsage?: Maybe<AdhesiveRibbonUsage_Mutation_Response>;
  /** update single row of the table: "AdhesiveRibbonUsage" */
  update_AdhesiveRibbonUsage_by_pk?: Maybe<AdhesiveRibbonUsage>;
  /** update data of the table: "AdhesiveRibbons" */
  update_AdhesiveRibbons?: Maybe<AdhesiveRibbons_Mutation_Response>;
  /** update single row of the table: "AdhesiveRibbons" */
  update_AdhesiveRibbons_by_pk?: Maybe<AdhesiveRibbons>;
  /** update data of the table: "AdhesiveSpots" */
  update_AdhesiveSpots?: Maybe<AdhesiveSpots_Mutation_Response>;
  /** update data of the table: "AdhesiveSpotsUsage" */
  update_AdhesiveSpotsUsage?: Maybe<AdhesiveSpotsUsage_Mutation_Response>;
  /** update single row of the table: "AdhesiveSpotsUsage" */
  update_AdhesiveSpotsUsage_by_pk?: Maybe<AdhesiveSpotsUsage>;
  /** update single row of the table: "AdhesiveSpots" */
  update_AdhesiveSpots_by_pk?: Maybe<AdhesiveSpots>;
  /** update data of the table: "AdhesiveSystemUsage" */
  update_AdhesiveSystemUsage?: Maybe<AdhesiveSystemUsage_Mutation_Response>;
  /** update single row of the table: "AdhesiveSystemUsage" */
  update_AdhesiveSystemUsage_by_pk?: Maybe<AdhesiveSystemUsage>;
  /** update data of the table: "AirRetarder" */
  update_AirRetarder?: Maybe<AirRetarder_Mutation_Response>;
  /** update data of the table: "AirRetarderUsage" */
  update_AirRetarderUsage?: Maybe<AirRetarderUsage_Mutation_Response>;
  /** update single row of the table: "AirRetarderUsage" */
  update_AirRetarderUsage_by_pk?: Maybe<AirRetarderUsage>;
  /** update single row of the table: "AirRetarder" */
  update_AirRetarder_by_pk?: Maybe<AirRetarder>;
  /** update data of the table: "AllManufacturedProducts" */
  update_AllManufacturedProducts?: Maybe<AllManufacturedProducts_Mutation_Response>;
  /** update data of the table: "Anchor" */
  update_Anchor?: Maybe<Anchor_Mutation_Response>;
  /** update single row of the table: "Anchor" */
  update_Anchor_by_pk?: Maybe<Anchor>;
  /** update data of the table: "ApprovalsDoc" */
  update_ApprovalsDoc?: Maybe<ApprovalsDoc_Mutation_Response>;
  /** update single row of the table: "ApprovalsDoc" */
  update_ApprovalsDoc_by_pk?: Maybe<ApprovalsDoc>;
  /** update data of the table: "ApprovedLayerUse" */
  update_ApprovedLayerUse?: Maybe<ApprovedLayerUse_Mutation_Response>;
  /** update single row of the table: "ApprovedLayerUse" */
  update_ApprovedLayerUse_by_pk?: Maybe<ApprovedLayerUse>;
  /** update data of the table: "AssemblyReconfigQueue" */
  update_AssemblyReconfigQueue?: Maybe<AssemblyReconfigQueue_Mutation_Response>;
  /** update single row of the table: "AssemblyReconfigQueue" */
  update_AssemblyReconfigQueue_by_pk?: Maybe<AssemblyReconfigQueue>;
  /** update data of the table: "BaseCoat" */
  update_BaseCoat?: Maybe<BaseCoat_Mutation_Response>;
  /** update data of the table: "BaseCoatUsage" */
  update_BaseCoatUsage?: Maybe<BaseCoatUsage_Mutation_Response>;
  /** update single row of the table: "BaseCoatUsage" */
  update_BaseCoatUsage_by_pk?: Maybe<BaseCoatUsage>;
  /** update single row of the table: "BaseCoat" */
  update_BaseCoat_by_pk?: Maybe<BaseCoat>;
  /** update data of the table: "BasePly" */
  update_BasePly?: Maybe<BasePly_Mutation_Response>;
  /** update data of the table: "BasePlyUsage" */
  update_BasePlyUsage?: Maybe<BasePlyUsage_Mutation_Response>;
  /** update single row of the table: "BasePlyUsage" */
  update_BasePlyUsage_by_pk?: Maybe<BasePlyUsage>;
  /** update single row of the table: "BasePly" */
  update_BasePly_by_pk?: Maybe<BasePly>;
  /** update data of the table: "BaseSheet" */
  update_BaseSheet?: Maybe<BaseSheet_Mutation_Response>;
  /** update data of the table: "BaseSheetUsage" */
  update_BaseSheetUsage?: Maybe<BaseSheetUsage_Mutation_Response>;
  /** update single row of the table: "BaseSheetUsage" */
  update_BaseSheetUsage_by_pk?: Maybe<BaseSheetUsage>;
  /** update single row of the table: "BaseSheet" */
  update_BaseSheet_by_pk?: Maybe<BaseSheet>;
  /** update data of the table: "BattBlanketInsulation" */
  update_BattBlanketInsulation?: Maybe<BattBlanketInsulation_Mutation_Response>;
  /** update single row of the table: "BattBlanketInsulation" */
  update_BattBlanketInsulation_by_pk?: Maybe<BattBlanketInsulation>;
  /** update data of the table: "BattBlanketUsage" */
  update_BattBlanketUsage?: Maybe<BattBlanketUsage_Mutation_Response>;
  /** update single row of the table: "BattBlanketUsage" */
  update_BattBlanketUsage_by_pk?: Maybe<BattBlanketUsage>;
  /** update data of the table: "BattenBar" */
  update_BattenBar?: Maybe<BattenBar_Mutation_Response>;
  /** update data of the table: "BattenBarUsage" */
  update_BattenBarUsage?: Maybe<BattenBarUsage_Mutation_Response>;
  /** update single row of the table: "BattenBarUsage" */
  update_BattenBarUsage_by_pk?: Maybe<BattenBarUsage>;
  /** update single row of the table: "BattenBar" */
  update_BattenBar_by_pk?: Maybe<BattenBar>;
  /** update data of the table: "BoardSizes" */
  update_BoardSizes?: Maybe<BoardSizes_Mutation_Response>;
  /** update data of the table: "BoardStock" */
  update_BoardStock?: Maybe<BoardStock_Mutation_Response>;
  /** update data of the table: "BoardStockBoardSizes" */
  update_BoardStockBoardSizes?: Maybe<BoardStockBoardSizes_Mutation_Response>;
  /** update single row of the table: "BoardStockBoardSizes" */
  update_BoardStockBoardSizes_by_pk?: Maybe<BoardStockBoardSizes>;
  /** update data of the table: "BoardStockFacerBottom" */
  update_BoardStockFacerBottom?: Maybe<BoardStockFacerBottom_Mutation_Response>;
  /** update single row of the table: "BoardStockFacerBottom" */
  update_BoardStockFacerBottom_by_pk?: Maybe<BoardStockFacerBottom>;
  /** update data of the table: "BoardStockFacerTop" */
  update_BoardStockFacerTop?: Maybe<BoardStockFacerTop_Mutation_Response>;
  /** update single row of the table: "BoardStockFacerTop" */
  update_BoardStockFacerTop_by_pk?: Maybe<BoardStockFacerTop>;
  /** update data of the table: "BoardStockUsage" */
  update_BoardStockUsage?: Maybe<BoardStockUsage_Mutation_Response>;
  /** update single row of the table: "BoardStockUsage" */
  update_BoardStockUsage_by_pk?: Maybe<BoardStockUsage>;
  /** update single row of the table: "BoardStock" */
  update_BoardStock_by_pk?: Maybe<BoardStock>;
  /** update data of the table: "BroadcastAlert" */
  update_BroadcastAlert?: Maybe<BroadcastAlert_Mutation_Response>;
  /** update single row of the table: "BroadcastAlert" */
  update_BroadcastAlert_by_pk?: Maybe<BroadcastAlert>;
  /** update data of the table: "BuiltupVaporRetarderSystemUsage" */
  update_BuiltupVaporRetarderSystemUsage?: Maybe<BuiltupVaporRetarderSystemUsage_Mutation_Response>;
  /** update single row of the table: "BuiltupVaporRetarderSystemUsage" */
  update_BuiltupVaporRetarderSystemUsage_by_pk?: Maybe<BuiltupVaporRetarderSystemUsage>;
  /** update data of the table: "C_Additive" */
  update_C_Additive?: Maybe<C_Additive_Mutation_Response>;
  /** update single row of the table: "C_Additive" */
  update_C_Additive_by_pk?: Maybe<C_Additive>;
  /** update data of the table: "C_AdhesiveApplicationMethod" */
  update_C_AdhesiveApplicationMethod?: Maybe<C_AdhesiveApplicationMethod_Mutation_Response>;
  /** update single row of the table: "C_AdhesiveApplicationMethod" */
  update_C_AdhesiveApplicationMethod_by_pk?: Maybe<C_AdhesiveApplicationMethod>;
  /** update data of the table: "C_AdhesiveMaterial" */
  update_C_AdhesiveMaterial?: Maybe<C_AdhesiveMaterial_Mutation_Response>;
  /** update single row of the table: "C_AdhesiveMaterial" */
  update_C_AdhesiveMaterial_by_pk?: Maybe<C_AdhesiveMaterial>;
  /** update data of the table: "C_AdhesiveType" */
  update_C_AdhesiveType?: Maybe<C_AdhesiveType_Mutation_Response>;
  /** update single row of the table: "C_AdhesiveType" */
  update_C_AdhesiveType_by_pk?: Maybe<C_AdhesiveType>;
  /** update data of the table: "C_Aggregate" */
  update_C_Aggregate?: Maybe<C_Aggregate_Mutation_Response>;
  /** update single row of the table: "C_Aggregate" */
  update_C_Aggregate_by_pk?: Maybe<C_Aggregate>;
  /** update data of the table: "C_ApprovalsDocCategory" */
  update_C_ApprovalsDocCategory?: Maybe<C_ApprovalsDocCategory_Mutation_Response>;
  /** update single row of the table: "C_ApprovalsDocCategory" */
  update_C_ApprovalsDocCategory_by_pk?: Maybe<C_ApprovalsDocCategory>;
  /** update data of the table: "C_ApprovedUse" */
  update_C_ApprovedUse?: Maybe<C_ApprovedUse_Mutation_Response>;
  /** update single row of the table: "C_ApprovedUse" */
  update_C_ApprovedUse_by_pk?: Maybe<C_ApprovedUse>;
  /** update data of the table: "C_AssemblyApplicationType" */
  update_C_AssemblyApplicationType?: Maybe<C_AssemblyApplicationType_Mutation_Response>;
  /** update single row of the table: "C_AssemblyApplicationType" */
  update_C_AssemblyApplicationType_by_pk?: Maybe<C_AssemblyApplicationType>;
  /** update data of the table: "C_AssemblyType" */
  update_C_AssemblyType?: Maybe<C_AssemblyType_Mutation_Response>;
  /** update single row of the table: "C_AssemblyType" */
  update_C_AssemblyType_by_pk?: Maybe<C_AssemblyType>;
  /** update data of the table: "C_BackingMaterial" */
  update_C_BackingMaterial?: Maybe<C_BackingMaterial_Mutation_Response>;
  /** update single row of the table: "C_BackingMaterial" */
  update_C_BackingMaterial_by_pk?: Maybe<C_BackingMaterial>;
  /** update data of the table: "C_BaseSheetApplicationMethod" */
  update_C_BaseSheetApplicationMethod?: Maybe<C_BaseSheetApplicationMethod_Mutation_Response>;
  /** update single row of the table: "C_BaseSheetApplicationMethod" */
  update_C_BaseSheetApplicationMethod_by_pk?: Maybe<C_BaseSheetApplicationMethod>;
  /** update data of the table: "C_BattBlanketFacerMaterial" */
  update_C_BattBlanketFacerMaterial?: Maybe<C_BattBlanketFacerMaterial_Mutation_Response>;
  /** update single row of the table: "C_BattBlanketFacerMaterial" */
  update_C_BattBlanketFacerMaterial_by_pk?: Maybe<C_BattBlanketFacerMaterial>;
  /** update data of the table: "C_BattBlanketMaterial" */
  update_C_BattBlanketMaterial?: Maybe<C_BattBlanketMaterial_Mutation_Response>;
  /** update single row of the table: "C_BattBlanketMaterial" */
  update_C_BattBlanketMaterial_by_pk?: Maybe<C_BattBlanketMaterial>;
  /** update data of the table: "C_BoardProfile" */
  update_C_BoardProfile?: Maybe<C_BoardProfile_Mutation_Response>;
  /** update single row of the table: "C_BoardProfile" */
  update_C_BoardProfile_by_pk?: Maybe<C_BoardProfile>;
  /** update data of the table: "C_BoardStockMaterial" */
  update_C_BoardStockMaterial?: Maybe<C_BoardStockMaterial_Mutation_Response>;
  /** update single row of the table: "C_BoardStockMaterial" */
  update_C_BoardStockMaterial_by_pk?: Maybe<C_BoardStockMaterial>;
  /** update data of the table: "C_BuildingEnclosure" */
  update_C_BuildingEnclosure?: Maybe<C_BuildingEnclosure_Mutation_Response>;
  /** update single row of the table: "C_BuildingEnclosure" */
  update_C_BuildingEnclosure_by_pk?: Maybe<C_BuildingEnclosure>;
  /** update data of the table: "C_CementAggregateRatio" */
  update_C_CementAggregateRatio?: Maybe<C_CementAggregateRatio_Mutation_Response>;
  /** update single row of the table: "C_CementAggregateRatio" */
  update_C_CementAggregateRatio_by_pk?: Maybe<C_CementAggregateRatio>;
  /** update data of the table: "C_CoatingMaterial" */
  update_C_CoatingMaterial?: Maybe<C_CoatingMaterial_Mutation_Response>;
  /** update single row of the table: "C_CoatingMaterial" */
  update_C_CoatingMaterial_by_pk?: Maybe<C_CoatingMaterial>;
  /** update data of the table: "C_ComponentType" */
  update_C_ComponentType?: Maybe<C_ComponentType_Mutation_Response>;
  /** update single row of the table: "C_ComponentType" */
  update_C_ComponentType_by_pk?: Maybe<C_ComponentType>;
  /** update data of the table: "C_ContactType" */
  update_C_ContactType?: Maybe<C_ContactType_Mutation_Response>;
  /** update single row of the table: "C_ContactType" */
  update_C_ContactType_by_pk?: Maybe<C_ContactType>;
  /** update data of the table: "C_Country" */
  update_C_Country?: Maybe<C_Country_Mutation_Response>;
  /** update single row of the table: "C_Country" */
  update_C_Country_by_pk?: Maybe<C_Country>;
  /** update data of the table: "C_CoverBoardMaterial" */
  update_C_CoverBoardMaterial?: Maybe<C_CoverBoardMaterial_Mutation_Response>;
  /** update single row of the table: "C_CoverBoardMaterial" */
  update_C_CoverBoardMaterial_by_pk?: Maybe<C_CoverBoardMaterial>;
  /** update data of the table: "C_CoverColor" */
  update_C_CoverColor?: Maybe<C_CoverColor_Mutation_Response>;
  /** update single row of the table: "C_CoverColor" */
  update_C_CoverColor_by_pk?: Maybe<C_CoverColor>;
  /** update data of the table: "C_CoverSecurementMethod" */
  update_C_CoverSecurementMethod?: Maybe<C_CoverSecurementMethod_Mutation_Response>;
  /** update single row of the table: "C_CoverSecurementMethod" */
  update_C_CoverSecurementMethod_by_pk?: Maybe<C_CoverSecurementMethod>;
  /** update data of the table: "C_DeckType" */
  update_C_DeckType?: Maybe<C_DeckType_Mutation_Response>;
  /** update single row of the table: "C_DeckType" */
  update_C_DeckType_by_pk?: Maybe<C_DeckType>;
  /** update data of the table: "C_ExistingRoofCover" */
  update_C_ExistingRoofCover?: Maybe<C_ExistingRoofCover_Mutation_Response>;
  /** update single row of the table: "C_ExistingRoofCover" */
  update_C_ExistingRoofCover_by_pk?: Maybe<C_ExistingRoofCover>;
  /** update data of the table: "C_ExistingRoofDeck" */
  update_C_ExistingRoofDeck?: Maybe<C_ExistingRoofDeck_Mutation_Response>;
  /** update single row of the table: "C_ExistingRoofDeck" */
  update_C_ExistingRoofDeck_by_pk?: Maybe<C_ExistingRoofDeck>;
  /** update data of the table: "C_ExistingRoofInsulationMaterial" */
  update_C_ExistingRoofInsulationMaterial?: Maybe<C_ExistingRoofInsulationMaterial_Mutation_Response>;
  /** update single row of the table: "C_ExistingRoofInsulationMaterial" */
  update_C_ExistingRoofInsulationMaterial_by_pk?: Maybe<C_ExistingRoofInsulationMaterial>;
  /** update data of the table: "C_ExistingRoofSinglePlyMaterial" */
  update_C_ExistingRoofSinglePlyMaterial?: Maybe<C_ExistingRoofSinglePlyMaterial_Mutation_Response>;
  /** update single row of the table: "C_ExistingRoofSinglePlyMaterial" */
  update_C_ExistingRoofSinglePlyMaterial_by_pk?: Maybe<C_ExistingRoofSinglePlyMaterial>;
  /** update data of the table: "C_ExistingRoofSurface" */
  update_C_ExistingRoofSurface?: Maybe<C_ExistingRoofSurface_Mutation_Response>;
  /** update single row of the table: "C_ExistingRoofSurface" */
  update_C_ExistingRoofSurface_by_pk?: Maybe<C_ExistingRoofSurface>;
  /** update data of the table: "C_ExtFireRating" */
  update_C_ExtFireRating?: Maybe<C_ExtFireRating_Mutation_Response>;
  /** update single row of the table: "C_ExtFireRating" */
  update_C_ExtFireRating_by_pk?: Maybe<C_ExtFireRating>;
  /** update data of the table: "C_Facer" */
  update_C_Facer?: Maybe<C_Facer_Mutation_Response>;
  /** update data of the table: "C_FacerMaterial" */
  update_C_FacerMaterial?: Maybe<C_FacerMaterial_Mutation_Response>;
  /** update single row of the table: "C_FacerMaterial" */
  update_C_FacerMaterial_by_pk?: Maybe<C_FacerMaterial>;
  /** update single row of the table: "C_Facer" */
  update_C_Facer_by_pk?: Maybe<C_Facer>;
  /** update data of the table: "C_FastenerCoating" */
  update_C_FastenerCoating?: Maybe<C_FastenerCoating_Mutation_Response>;
  /** update single row of the table: "C_FastenerCoating" */
  update_C_FastenerCoating_by_pk?: Maybe<C_FastenerCoating>;
  /** update data of the table: "C_FastenerDriveHead" */
  update_C_FastenerDriveHead?: Maybe<C_FastenerDriveHead_Mutation_Response>;
  /** update single row of the table: "C_FastenerDriveHead" */
  update_C_FastenerDriveHead_by_pk?: Maybe<C_FastenerDriveHead>;
  /** update data of the table: "C_FastenerType" */
  update_C_FastenerType?: Maybe<C_FastenerType_Mutation_Response>;
  /** update single row of the table: "C_FastenerType" */
  update_C_FastenerType_by_pk?: Maybe<C_FastenerType>;
  /** update data of the table: "C_FasteningApplicationMethod" */
  update_C_FasteningApplicationMethod?: Maybe<C_FasteningApplicationMethod_Mutation_Response>;
  /** update single row of the table: "C_FasteningApplicationMethod" */
  update_C_FasteningApplicationMethod_by_pk?: Maybe<C_FasteningApplicationMethod>;
  /** update data of the table: "C_FlashingMaterial" */
  update_C_FlashingMaterial?: Maybe<C_FlashingMaterial_Mutation_Response>;
  /** update single row of the table: "C_FlashingMaterial" */
  update_C_FlashingMaterial_by_pk?: Maybe<C_FlashingMaterial>;
  /** update data of the table: "C_FlashingType" */
  update_C_FlashingType?: Maybe<C_FlashingType_Mutation_Response>;
  /** update single row of the table: "C_FlashingType" */
  update_C_FlashingType_by_pk?: Maybe<C_FlashingType>;
  /** update data of the table: "C_FormDeckMaterial" */
  update_C_FormDeckMaterial?: Maybe<C_FormDeckMaterial_Mutation_Response>;
  /** update single row of the table: "C_FormDeckMaterial" */
  update_C_FormDeckMaterial_by_pk?: Maybe<C_FormDeckMaterial>;
  /** update data of the table: "C_GroundRoughnessRating" */
  update_C_GroundRoughnessRating?: Maybe<C_GroundRoughnessRating_Mutation_Response>;
  /** update single row of the table: "C_GroundRoughnessRating" */
  update_C_GroundRoughnessRating_by_pk?: Maybe<C_GroundRoughnessRating>;
  /** update data of the table: "C_GutterMaterial" */
  update_C_GutterMaterial?: Maybe<C_GutterMaterial_Mutation_Response>;
  /** update single row of the table: "C_GutterMaterial" */
  update_C_GutterMaterial_by_pk?: Maybe<C_GutterMaterial>;
  /** update data of the table: "C_HailRating" */
  update_C_HailRating?: Maybe<C_HailRating_Mutation_Response>;
  /** update single row of the table: "C_HailRating" */
  update_C_HailRating_by_pk?: Maybe<C_HailRating>;
  /** update data of the table: "C_IndustryType" */
  update_C_IndustryType?: Maybe<C_IndustryType_Mutation_Response>;
  /** update single row of the table: "C_IndustryType" */
  update_C_IndustryType_by_pk?: Maybe<C_IndustryType>;
  /** update data of the table: "C_InsulationCore" */
  update_C_InsulationCore?: Maybe<C_InsulationCore_Mutation_Response>;
  /** update single row of the table: "C_InsulationCore" */
  update_C_InsulationCore_by_pk?: Maybe<C_InsulationCore>;
  /** update data of the table: "C_InsulationFoamType" */
  update_C_InsulationFoamType?: Maybe<C_InsulationFoamType_Mutation_Response>;
  /** update single row of the table: "C_InsulationFoamType" */
  update_C_InsulationFoamType_by_pk?: Maybe<C_InsulationFoamType>;
  /** update data of the table: "C_IntFireRating" */
  update_C_IntFireRating?: Maybe<C_IntFireRating_Mutation_Response>;
  /** update single row of the table: "C_IntFireRating" */
  update_C_IntFireRating_by_pk?: Maybe<C_IntFireRating>;
  /** update data of the table: "C_LapSealantType" */
  update_C_LapSealantType?: Maybe<C_LapSealantType_Mutation_Response>;
  /** update single row of the table: "C_LapSealantType" */
  update_C_LapSealantType_by_pk?: Maybe<C_LapSealantType>;
  /** update data of the table: "C_LayerType" */
  update_C_LayerType?: Maybe<C_LayerType_Mutation_Response>;
  /** update single row of the table: "C_LayerType" */
  update_C_LayerType_by_pk?: Maybe<C_LayerType>;
  /** update data of the table: "C_LinerPanelMaterial" */
  update_C_LinerPanelMaterial?: Maybe<C_LinerPanelMaterial_Mutation_Response>;
  /** update single row of the table: "C_LinerPanelMaterial" */
  update_C_LinerPanelMaterial_by_pk?: Maybe<C_LinerPanelMaterial>;
  /** update data of the table: "C_LiquidApplication" */
  update_C_LiquidApplication?: Maybe<C_LiquidApplication_Mutation_Response>;
  /** update single row of the table: "C_LiquidApplication" */
  update_C_LiquidApplication_by_pk?: Maybe<C_LiquidApplication>;
  /** update data of the table: "C_LiquidAppliedMaterial" */
  update_C_LiquidAppliedMaterial?: Maybe<C_LiquidAppliedMaterial_Mutation_Response>;
  /** update single row of the table: "C_LiquidAppliedMaterial" */
  update_C_LiquidAppliedMaterial_by_pk?: Maybe<C_LiquidAppliedMaterial>;
  /** update data of the table: "C_LockStatus" */
  update_C_LockStatus?: Maybe<C_LockStatus_Mutation_Response>;
  /** update single row of the table: "C_LockStatus" */
  update_C_LockStatus_by_pk?: Maybe<C_LockStatus>;
  /** update data of the table: "C_MDAttributeType" */
  update_C_MDAttributeType?: Maybe<C_MdAttributeType_Mutation_Response>;
  /** update single row of the table: "C_MDAttributeType" */
  update_C_MDAttributeType_by_pk?: Maybe<C_MdAttributeType>;
  /** update data of the table: "C_MaintenanceCoatingMaterial" */
  update_C_MaintenanceCoatingMaterial?: Maybe<C_MaintenanceCoatingMaterial_Mutation_Response>;
  /** update single row of the table: "C_MaintenanceCoatingMaterial" */
  update_C_MaintenanceCoatingMaterial_by_pk?: Maybe<C_MaintenanceCoatingMaterial>;
  /** update data of the table: "C_MaintenanceCoatingSubstrate" */
  update_C_MaintenanceCoatingSubstrate?: Maybe<C_MaintenanceCoatingSubstrate_Mutation_Response>;
  /** update single row of the table: "C_MaintenanceCoatingSubstrate" */
  update_C_MaintenanceCoatingSubstrate_by_pk?: Maybe<C_MaintenanceCoatingSubstrate>;
  /** update data of the table: "C_MembraneTermination" */
  update_C_MembraneTermination?: Maybe<C_MembraneTermination_Mutation_Response>;
  /** update single row of the table: "C_MembraneTermination" */
  update_C_MembraneTermination_by_pk?: Maybe<C_MembraneTermination>;
  /** update data of the table: "C_MultiplyMaterial" */
  update_C_MultiplyMaterial?: Maybe<C_MultiplyMaterial_Mutation_Response>;
  /** update single row of the table: "C_MultiplyMaterial" */
  update_C_MultiplyMaterial_by_pk?: Maybe<C_MultiplyMaterial>;
  /** update data of the table: "C_PVApplicationMethod" */
  update_C_PVApplicationMethod?: Maybe<C_PvApplicationMethod_Mutation_Response>;
  /** update single row of the table: "C_PVApplicationMethod" */
  update_C_PVApplicationMethod_by_pk?: Maybe<C_PvApplicationMethod>;
  /** update data of the table: "C_PVHailRating" */
  update_C_PVHailRating?: Maybe<C_PvHailRating_Mutation_Response>;
  /** update single row of the table: "C_PVHailRating" */
  update_C_PVHailRating_by_pk?: Maybe<C_PvHailRating>;
  /** update data of the table: "C_PerimeterFlashingType" */
  update_C_PerimeterFlashingType?: Maybe<C_PerimeterFlashingType_Mutation_Response>;
  /** update single row of the table: "C_PerimeterFlashingType" */
  update_C_PerimeterFlashingType_by_pk?: Maybe<C_PerimeterFlashingType>;
  /** update data of the table: "C_PressureZone" */
  update_C_PressureZone?: Maybe<C_PressureZone_Mutation_Response>;
  /** update single row of the table: "C_PressureZone" */
  update_C_PressureZone_by_pk?: Maybe<C_PressureZone>;
  /** update data of the table: "C_ProductApprovedUse" */
  update_C_ProductApprovedUse?: Maybe<C_ProductApprovedUse_Mutation_Response>;
  /** update data of the table: "C_ProjectRole" */
  update_C_ProjectRole?: Maybe<C_ProjectRole_Mutation_Response>;
  /** update single row of the table: "C_ProjectRole" */
  update_C_ProjectRole_by_pk?: Maybe<C_ProjectRole>;
  /** update data of the table: "C_Reinforcement" */
  update_C_Reinforcement?: Maybe<C_Reinforcement_Mutation_Response>;
  /** update single row of the table: "C_Reinforcement" */
  update_C_Reinforcement_by_pk?: Maybe<C_Reinforcement>;
  /** update data of the table: "C_RetarderMaterial" */
  update_C_RetarderMaterial?: Maybe<C_RetarderMaterial_Mutation_Response>;
  /** update single row of the table: "C_RetarderMaterial" */
  update_C_RetarderMaterial_by_pk?: Maybe<C_RetarderMaterial>;
  /** update data of the table: "C_ScenarioStatus" */
  update_C_ScenarioStatus?: Maybe<C_ScenarioStatus_Mutation_Response>;
  /** update single row of the table: "C_ScenarioStatus" */
  update_C_ScenarioStatus_by_pk?: Maybe<C_ScenarioStatus>;
  /** update data of the table: "C_ScenarioType" */
  update_C_ScenarioType?: Maybe<C_ScenarioType_Mutation_Response>;
  /** update single row of the table: "C_ScenarioType" */
  update_C_ScenarioType_by_pk?: Maybe<C_ScenarioType>;
  /** update data of the table: "C_SeamPrimerType" */
  update_C_SeamPrimerType?: Maybe<C_SeamPrimerType_Mutation_Response>;
  /** update single row of the table: "C_SeamPrimerType" */
  update_C_SeamPrimerType_by_pk?: Maybe<C_SeamPrimerType>;
  /** update data of the table: "C_SeamWashType" */
  update_C_SeamWashType?: Maybe<C_SeamWashType_Mutation_Response>;
  /** update single row of the table: "C_SeamWashType" */
  update_C_SeamWashType_by_pk?: Maybe<C_SeamWashType>;
  /** update data of the table: "C_SecurementMaterial" */
  update_C_SecurementMaterial?: Maybe<C_SecurementMaterial_Mutation_Response>;
  /** update single row of the table: "C_SecurementMaterial" */
  update_C_SecurementMaterial_by_pk?: Maybe<C_SecurementMaterial>;
  /** update data of the table: "C_ShinglesHail" */
  update_C_ShinglesHail?: Maybe<C_ShinglesHail_Mutation_Response>;
  /** update single row of the table: "C_ShinglesHail" */
  update_C_ShinglesHail_by_pk?: Maybe<C_ShinglesHail>;
  /** update data of the table: "C_ShinglesMaterial" */
  update_C_ShinglesMaterial?: Maybe<C_ShinglesMaterial_Mutation_Response>;
  /** update single row of the table: "C_ShinglesMaterial" */
  update_C_ShinglesMaterial_by_pk?: Maybe<C_ShinglesMaterial>;
  /** update data of the table: "C_ShinglesReinforcement" */
  update_C_ShinglesReinforcement?: Maybe<C_ShinglesReinforcement_Mutation_Response>;
  /** update single row of the table: "C_ShinglesReinforcement" */
  update_C_ShinglesReinforcement_by_pk?: Maybe<C_ShinglesReinforcement>;
  /** update data of the table: "C_ShinglesSurfacing" */
  update_C_ShinglesSurfacing?: Maybe<C_ShinglesSurfacing_Mutation_Response>;
  /** update single row of the table: "C_ShinglesSurfacing" */
  update_C_ShinglesSurfacing_by_pk?: Maybe<C_ShinglesSurfacing>;
  /** update data of the table: "C_ShinglesWindSpeed" */
  update_C_ShinglesWindSpeed?: Maybe<C_ShinglesWindSpeed_Mutation_Response>;
  /** update single row of the table: "C_ShinglesWindSpeed" */
  update_C_ShinglesWindSpeed_by_pk?: Maybe<C_ShinglesWindSpeed>;
  /** update data of the table: "C_SinglePlyMaterial" */
  update_C_SinglePlyMaterial?: Maybe<C_SinglePlyMaterial_Mutation_Response>;
  /** update single row of the table: "C_SinglePlyMaterial" */
  update_C_SinglePlyMaterial_by_pk?: Maybe<C_SinglePlyMaterial>;
  /** update data of the table: "C_StandingLapSeamMaterial" */
  update_C_StandingLapSeamMaterial?: Maybe<C_StandingLapSeamMaterial_Mutation_Response>;
  /** update single row of the table: "C_StandingLapSeamMaterial" */
  update_C_StandingLapSeamMaterial_by_pk?: Maybe<C_StandingLapSeamMaterial>;
  /** update data of the table: "C_StateProvince" */
  update_C_StateProvince?: Maybe<C_StateProvince_Mutation_Response>;
  /** update single row of the table: "C_StateProvince" */
  update_C_StateProvince_by_pk?: Maybe<C_StateProvince>;
  /** update data of the table: "C_Status" */
  update_C_Status?: Maybe<C_Status_Mutation_Response>;
  /** update single row of the table: "C_Status" */
  update_C_Status_by_pk?: Maybe<C_Status>;
  /** update data of the table: "C_SteelDeckRibType" */
  update_C_SteelDeckRibType?: Maybe<C_SteelDeckRibType_Mutation_Response>;
  /** update single row of the table: "C_SteelDeckRibType" */
  update_C_SteelDeckRibType_by_pk?: Maybe<C_SteelDeckRibType>;
  /** update data of the table: "C_StressDistributorType" */
  update_C_StressDistributorType?: Maybe<C_StressDistributorType_Mutation_Response>;
  /** update single row of the table: "C_StressDistributorType" */
  update_C_StressDistributorType_by_pk?: Maybe<C_StressDistributorType>;
  /** update data of the table: "C_StressPlateShape" */
  update_C_StressPlateShape?: Maybe<C_StressPlateShape_Mutation_Response>;
  /** update single row of the table: "C_StressPlateShape" */
  update_C_StressPlateShape_by_pk?: Maybe<C_StressPlateShape>;
  /** update data of the table: "C_StructureType" */
  update_C_StructureType?: Maybe<C_StructureType_Mutation_Response>;
  /** update single row of the table: "C_StructureType" */
  update_C_StructureType_by_pk?: Maybe<C_StructureType>;
  /** update data of the table: "C_SubassemblyType" */
  update_C_SubassemblyType?: Maybe<C_SubassemblyType_Mutation_Response>;
  /** update single row of the table: "C_SubassemblyType" */
  update_C_SubassemblyType_by_pk?: Maybe<C_SubassemblyType>;
  /** update data of the table: "C_Substrate" */
  update_C_Substrate?: Maybe<C_Substrate_Mutation_Response>;
  /** update data of the table: "C_SubstratePrimerMaterial" */
  update_C_SubstratePrimerMaterial?: Maybe<C_SubstratePrimerMaterial_Mutation_Response>;
  /** update single row of the table: "C_SubstratePrimerMaterial" */
  update_C_SubstratePrimerMaterial_by_pk?: Maybe<C_SubstratePrimerMaterial>;
  /** update single row of the table: "C_Substrate" */
  update_C_Substrate_by_pk?: Maybe<C_Substrate>;
  /** update data of the table: "C_SurfaceType" */
  update_C_SurfaceType?: Maybe<C_SurfaceType_Mutation_Response>;
  /** update single row of the table: "C_SurfaceType" */
  update_C_SurfaceType_by_pk?: Maybe<C_SurfaceType>;
  /** update data of the table: "C_SurfacingMaterial" */
  update_C_SurfacingMaterial?: Maybe<C_SurfacingMaterial_Mutation_Response>;
  /** update single row of the table: "C_SurfacingMaterial" */
  update_C_SurfacingMaterial_by_pk?: Maybe<C_SurfacingMaterial>;
  /** update data of the table: "C_SystemApprovedUse" */
  update_C_SystemApprovedUse?: Maybe<C_SystemApprovedUse_Mutation_Response>;
  /** update data of the table: "C_ThermalBarrierMaterial" */
  update_C_ThermalBarrierMaterial?: Maybe<C_ThermalBarrierMaterial_Mutation_Response>;
  /** update single row of the table: "C_ThermalBarrierMaterial" */
  update_C_ThermalBarrierMaterial_by_pk?: Maybe<C_ThermalBarrierMaterial>;
  /** update data of the table: "C_WIPDelistReason" */
  update_C_WIPDelistReason?: Maybe<C_WipDelistReason_Mutation_Response>;
  /** update single row of the table: "C_WIPDelistReason" */
  update_C_WIPDelistReason_by_pk?: Maybe<C_WipDelistReason>;
  /** update data of the table: "C_WIPOnHoldReason" */
  update_C_WIPOnHoldReason?: Maybe<C_WipOnHoldReason_Mutation_Response>;
  /** update single row of the table: "C_WIPOnHoldReason" */
  update_C_WIPOnHoldReason_by_pk?: Maybe<C_WipOnHoldReason>;
  /** update data of the table: "C_WIPStatus" */
  update_C_WIPStatus?: Maybe<C_WipStatus_Mutation_Response>;
  /** update single row of the table: "C_WIPStatus" */
  update_C_WIPStatus_by_pk?: Maybe<C_WipStatus>;
  /** update data of the table: "C_WeldApplication" */
  update_C_WeldApplication?: Maybe<C_WeldApplication_Mutation_Response>;
  /** update single row of the table: "C_WeldApplication" */
  update_C_WeldApplication_by_pk?: Maybe<C_WeldApplication>;
  /** update data of the table: "C_WeldConfiguration" */
  update_C_WeldConfiguration?: Maybe<C_WeldConfiguration_Mutation_Response>;
  /** update single row of the table: "C_WeldConfiguration" */
  update_C_WeldConfiguration_by_pk?: Maybe<C_WeldConfiguration>;
  /** update data of the table: "C_WindBorneDebris" */
  update_C_WindBorneDebris?: Maybe<C_WindBorneDebris_Mutation_Response>;
  /** update single row of the table: "C_WindBorneDebris" */
  update_C_WindBorneDebris_by_pk?: Maybe<C_WindBorneDebris>;
  /** update data of the table: "C_WoodSpecies" */
  update_C_WoodSpecies?: Maybe<C_WoodSpecies_Mutation_Response>;
  /** update single row of the table: "C_WoodSpecies" */
  update_C_WoodSpecies_by_pk?: Maybe<C_WoodSpecies>;
  /** update data of the table: "C_WoodType" */
  update_C_WoodType?: Maybe<C_WoodType_Mutation_Response>;
  /** update single row of the table: "C_WoodType" */
  update_C_WoodType_by_pk?: Maybe<C_WoodType>;
  /** update data of the table: "C_WorkflowStatus" */
  update_C_WorkflowStatus?: Maybe<C_WorkflowStatus_Mutation_Response>;
  /** update single row of the table: "C_WorkflowStatus" */
  update_C_WorkflowStatus_by_pk?: Maybe<C_WorkflowStatus>;
  /** update data of the table: "CapPly" */
  update_CapPly?: Maybe<CapPly_Mutation_Response>;
  /** update data of the table: "CapPlyUsage" */
  update_CapPlyUsage?: Maybe<CapPlyUsage_Mutation_Response>;
  /** update single row of the table: "CapPlyUsage" */
  update_CapPlyUsage_by_pk?: Maybe<CapPlyUsage>;
  /** update single row of the table: "CapPly" */
  update_CapPly_by_pk?: Maybe<CapPly>;
  /** update data of the table: "Category" */
  update_Category?: Maybe<Category_Mutation_Response>;
  /** update data of the table: "CategoryLog" */
  update_CategoryLog?: Maybe<CategoryLog_Mutation_Response>;
  /** update single row of the table: "CategoryLog" */
  update_CategoryLog_by_pk?: Maybe<CategoryLog>;
  /** update data of the table: "Category_Coating" */
  update_Category_Coating?: Maybe<Category_Coating_Mutation_Response>;
  /** update data of the table: "Category_Cover" */
  update_Category_Cover?: Maybe<Category_Cover_Mutation_Response>;
  /** update data of the table: "Category_Deck" */
  update_Category_Deck?: Maybe<Category_Deck_Mutation_Response>;
  /** update data of the table: "Category_Flashing" */
  update_Category_Flashing?: Maybe<Category_Flashing_Mutation_Response>;
  /** update data of the table: "Category_Insulation" */
  update_Category_Insulation?: Maybe<Category_Insulation_Mutation_Response>;
  /** update data of the table: "Category_Other" */
  update_Category_Other?: Maybe<Category_Other_Mutation_Response>;
  /** update data of the table: "Category_Seaming" */
  update_Category_Seaming?: Maybe<Category_Seaming_Mutation_Response>;
  /** update data of the table: "Category_Securement" */
  update_Category_Securement?: Maybe<Category_Securement_Mutation_Response>;
  /** update data of the table: "Category_Sheet" */
  update_Category_Sheet?: Maybe<Category_Sheet_Mutation_Response>;
  /** update single row of the table: "Category" */
  update_Category_by_pk?: Maybe<Category>;
  /** update data of the table: "CementitiousWoodFiberDeck" */
  update_CementitiousWoodFiberDeck?: Maybe<CementitiousWoodFiberDeck_Mutation_Response>;
  /** update data of the table: "CementitiousWoodFiberDeckUsage" */
  update_CementitiousWoodFiberDeckUsage?: Maybe<CementitiousWoodFiberDeckUsage_Mutation_Response>;
  /** update single row of the table: "CementitiousWoodFiberDeckUsage" */
  update_CementitiousWoodFiberDeckUsage_by_pk?: Maybe<CementitiousWoodFiberDeckUsage>;
  /** update single row of the table: "CementitiousWoodFiberDeck" */
  update_CementitiousWoodFiberDeck_by_pk?: Maybe<CementitiousWoodFiberDeck>;
  /** update data of the table: "Clip" */
  update_Clip?: Maybe<Clip_Mutation_Response>;
  /** update data of the table: "ClipUsage" */
  update_ClipUsage?: Maybe<ClipUsage_Mutation_Response>;
  /** update single row of the table: "ClipUsage" */
  update_ClipUsage_by_pk?: Maybe<ClipUsage>;
  /** update single row of the table: "Clip" */
  update_Clip_by_pk?: Maybe<Clip>;
  /** update data of the table: "Coat" */
  update_Coat?: Maybe<Coat_Mutation_Response>;
  /** update data of the table: "CoatUsage" */
  update_CoatUsage?: Maybe<CoatUsage_Mutation_Response>;
  /** update single row of the table: "CoatUsage" */
  update_CoatUsage_by_pk?: Maybe<CoatUsage>;
  /** update single row of the table: "Coat" */
  update_Coat_by_pk?: Maybe<Coat>;
  /** update data of the table: "Coating" */
  update_Coating?: Maybe<Coating_Mutation_Response>;
  /** update data of the table: "CoatingSystemUsage" */
  update_CoatingSystemUsage?: Maybe<CoatingSystemUsage_Mutation_Response>;
  /** update single row of the table: "CoatingSystemUsage" */
  update_CoatingSystemUsage_by_pk?: Maybe<CoatingSystemUsage>;
  /** update data of the table: "CoatingUsage" */
  update_CoatingUsage?: Maybe<CoatingUsage_Mutation_Response>;
  /** update single row of the table: "CoatingUsage" */
  update_CoatingUsage_by_pk?: Maybe<CoatingUsage>;
  /** update single row of the table: "Coating" */
  update_Coating_by_pk?: Maybe<Coating>;
  /** update data of the table: "Component" */
  update_Component?: Maybe<Component_Mutation_Response>;
  /** update data of the table: "ComponentApprovedUse" */
  update_ComponentApprovedUse?: Maybe<ComponentApprovedUse_Mutation_Response>;
  /** update single row of the table: "ComponentApprovedUse" */
  update_ComponentApprovedUse_by_pk?: Maybe<ComponentApprovedUse>;
  /** update data of the table: "ComponentDoc" */
  update_ComponentDoc?: Maybe<ComponentDoc_Mutation_Response>;
  /** update single row of the table: "ComponentDoc" */
  update_ComponentDoc_by_pk?: Maybe<ComponentDoc>;
  /** update data of the table: "ComponentLayer" */
  update_ComponentLayer?: Maybe<ComponentLayer_Mutation_Response>;
  /** update data of the table: "ComponentLayerUsage" */
  update_ComponentLayerUsage?: Maybe<ComponentLayerUsage_Mutation_Response>;
  /** update single row of the table: "ComponentLayerUsage" */
  update_ComponentLayerUsage_by_pk?: Maybe<ComponentLayerUsage>;
  /** update single row of the table: "ComponentLayer" */
  update_ComponentLayer_by_pk?: Maybe<ComponentLayer>;
  /** update single row of the table: "Component" */
  update_Component_by_pk?: Maybe<Component>;
  /** update data of the table: "CompositePanelCover" */
  update_CompositePanelCover?: Maybe<CompositePanelCover_Mutation_Response>;
  /** update single row of the table: "CompositePanelCover" */
  update_CompositePanelCover_by_pk?: Maybe<CompositePanelCover>;
  /** update data of the table: "CompositePanelUsage" */
  update_CompositePanelUsage?: Maybe<CompositePanelUsage_Mutation_Response>;
  /** update single row of the table: "CompositePanelUsage" */
  update_CompositePanelUsage_by_pk?: Maybe<CompositePanelUsage>;
  /** update data of the table: "ContactInfo" */
  update_ContactInfo?: Maybe<ContactInfo_Mutation_Response>;
  /** update single row of the table: "ContactInfo" */
  update_ContactInfo_by_pk?: Maybe<ContactInfo>;
  /** update data of the table: "Coping" */
  update_Coping?: Maybe<Coping_Mutation_Response>;
  /** update single row of the table: "Coping" */
  update_Coping_by_pk?: Maybe<Coping>;
  /** update data of the table: "CoverBoard" */
  update_CoverBoard?: Maybe<CoverBoard_Mutation_Response>;
  /** update data of the table: "CoverBoardBoardSizes" */
  update_CoverBoardBoardSizes?: Maybe<CoverBoardBoardSizes_Mutation_Response>;
  /** update single row of the table: "CoverBoardBoardSizes" */
  update_CoverBoardBoardSizes_by_pk?: Maybe<CoverBoardBoardSizes>;
  /** update data of the table: "CoverBoardFacerBottom" */
  update_CoverBoardFacerBottom?: Maybe<CoverBoardFacerBottom_Mutation_Response>;
  /** update single row of the table: "CoverBoardFacerBottom" */
  update_CoverBoardFacerBottom_by_pk?: Maybe<CoverBoardFacerBottom>;
  /** update data of the table: "CoverBoardFacerTop" */
  update_CoverBoardFacerTop?: Maybe<CoverBoardFacerTop_Mutation_Response>;
  /** update single row of the table: "CoverBoardFacerTop" */
  update_CoverBoardFacerTop_by_pk?: Maybe<CoverBoardFacerTop>;
  /** update data of the table: "CoverBoardUsage" */
  update_CoverBoardUsage?: Maybe<CoverBoardUsage_Mutation_Response>;
  /** update single row of the table: "CoverBoardUsage" */
  update_CoverBoardUsage_by_pk?: Maybe<CoverBoardUsage>;
  /** update single row of the table: "CoverBoard" */
  update_CoverBoard_by_pk?: Maybe<CoverBoard>;
  /** update data of the table: "CoverStripDisk" */
  update_CoverStripDisk?: Maybe<CoverStripDisk_Mutation_Response>;
  /** update data of the table: "CoverStripDiskUsage" */
  update_CoverStripDiskUsage?: Maybe<CoverStripDiskUsage_Mutation_Response>;
  /** update single row of the table: "CoverStripDiskUsage" */
  update_CoverStripDiskUsage_by_pk?: Maybe<CoverStripDiskUsage>;
  /** update single row of the table: "CoverStripDisk" */
  update_CoverStripDisk_by_pk?: Maybe<CoverStripDisk>;
  /** update data of the table: "ExistingRoofLayer" */
  update_ExistingRoofLayer?: Maybe<ExistingRoofLayer_Mutation_Response>;
  /** update single row of the table: "ExistingRoofLayer" */
  update_ExistingRoofLayer_by_pk?: Maybe<ExistingRoofLayer>;
  /** update data of the table: "ExpansionJoint" */
  update_ExpansionJoint?: Maybe<ExpansionJoint_Mutation_Response>;
  /** update data of the table: "ExpansionJointUsage" */
  update_ExpansionJointUsage?: Maybe<ExpansionJointUsage_Mutation_Response>;
  /** update single row of the table: "ExpansionJointUsage" */
  update_ExpansionJointUsage_by_pk?: Maybe<ExpansionJointUsage>;
  /** update single row of the table: "ExpansionJoint" */
  update_ExpansionJoint_by_pk?: Maybe<ExpansionJoint>;
  /** update data of the table: "ExtendedLayerConfigSplit" */
  update_ExtendedLayerConfigSplit?: Maybe<ExtendedLayerConfigSplit_Mutation_Response>;
  /** update single row of the table: "ExtendedLayerConfigSplit" */
  update_ExtendedLayerConfigSplit_by_pk?: Maybe<ExtendedLayerConfigSplit>;
  /** update data of the table: "FMUserAccount" */
  update_FMUserAccount?: Maybe<FmUserAccount_Mutation_Response>;
  /** update single row of the table: "FMUserAccount" */
  update_FMUserAccount_by_pk?: Maybe<FmUserAccount>;
  /** update data of the table: "Fascia" */
  update_Fascia?: Maybe<Fascia_Mutation_Response>;
  /** update single row of the table: "Fascia" */
  update_Fascia_by_pk?: Maybe<Fascia>;
  /** update data of the table: "Fastener" */
  update_Fastener?: Maybe<Fastener_Mutation_Response>;
  /** update data of the table: "FastenerUsage" */
  update_FastenerUsage?: Maybe<FastenerUsage_Mutation_Response>;
  /** update single row of the table: "FastenerUsage" */
  update_FastenerUsage_by_pk?: Maybe<FastenerUsage>;
  /** update single row of the table: "Fastener" */
  update_Fastener_by_pk?: Maybe<Fastener>;
  /** update data of the table: "FasteningSystemBattenBarUsage" */
  update_FasteningSystemBattenBarUsage?: Maybe<FasteningSystemBattenBarUsage_Mutation_Response>;
  /** update single row of the table: "FasteningSystemBattenBarUsage" */
  update_FasteningSystemBattenBarUsage_by_pk?: Maybe<FasteningSystemBattenBarUsage>;
  /** update data of the table: "FasteningSystemClipUsage" */
  update_FasteningSystemClipUsage?: Maybe<FasteningSystemClipUsage_Mutation_Response>;
  /** update single row of the table: "FasteningSystemClipUsage" */
  update_FasteningSystemClipUsage_by_pk?: Maybe<FasteningSystemClipUsage>;
  /** update data of the table: "FasteningSystemStressPlateUsage" */
  update_FasteningSystemStressPlateUsage?: Maybe<FasteningSystemStressPlateUsage_Mutation_Response>;
  /** update single row of the table: "FasteningSystemStressPlateUsage" */
  update_FasteningSystemStressPlateUsage_by_pk?: Maybe<FasteningSystemStressPlateUsage>;
  /** update data of the table: "FiberReinforcedPlasticDeck" */
  update_FiberReinforcedPlasticDeck?: Maybe<FiberReinforcedPlasticDeck_Mutation_Response>;
  /** update data of the table: "FiberReinforcedPlasticDeckUsage" */
  update_FiberReinforcedPlasticDeckUsage?: Maybe<FiberReinforcedPlasticDeckUsage_Mutation_Response>;
  /** update single row of the table: "FiberReinforcedPlasticDeckUsage" */
  update_FiberReinforcedPlasticDeckUsage_by_pk?: Maybe<FiberReinforcedPlasticDeckUsage>;
  /** update single row of the table: "FiberReinforcedPlasticDeck" */
  update_FiberReinforcedPlasticDeck_by_pk?: Maybe<FiberReinforcedPlasticDeck>;
  /** update data of the table: "FormDeck" */
  update_FormDeck?: Maybe<FormDeck_Mutation_Response>;
  /** update data of the table: "FormDeckUsage" */
  update_FormDeckUsage?: Maybe<FormDeckUsage_Mutation_Response>;
  /** update single row of the table: "FormDeckUsage" */
  update_FormDeckUsage_by_pk?: Maybe<FormDeckUsage>;
  /** update single row of the table: "FormDeck" */
  update_FormDeck_by_pk?: Maybe<FormDeck>;
  /** update data of the table: "Gutter" */
  update_Gutter?: Maybe<Gutter_Mutation_Response>;
  /** update single row of the table: "Gutter" */
  update_Gutter_by_pk?: Maybe<Gutter>;
  /** update data of the table: "GypsumDeck" */
  update_GypsumDeck?: Maybe<GypsumDeck_Mutation_Response>;
  /** update data of the table: "GypsumDeckUsage" */
  update_GypsumDeckUsage?: Maybe<GypsumDeckUsage_Mutation_Response>;
  /** update single row of the table: "GypsumDeckUsage" */
  update_GypsumDeckUsage_by_pk?: Maybe<GypsumDeckUsage>;
  /** update single row of the table: "GypsumDeck" */
  update_GypsumDeck_by_pk?: Maybe<GypsumDeck>;
  /** update data of the table: "InsBoardSize" */
  update_InsBoardSize?: Maybe<InsBoardSize_Mutation_Response>;
  /** update single row of the table: "InsBoardSize" */
  update_InsBoardSize_by_pk?: Maybe<InsBoardSize>;
  /** update data of the table: "ItemWorkspace" */
  update_ItemWorkspace?: Maybe<ItemWorkspace_Mutation_Response>;
  /** update data of the table: "ItemWorkspaceData" */
  update_ItemWorkspaceData?: Maybe<ItemWorkspaceData_Mutation_Response>;
  /** update single row of the table: "ItemWorkspaceData" */
  update_ItemWorkspaceData_by_pk?: Maybe<ItemWorkspaceData>;
  /** update single row of the table: "ItemWorkspace" */
  update_ItemWorkspace_by_pk?: Maybe<ItemWorkspace>;
  /** update data of the table: "LapSealant" */
  update_LapSealant?: Maybe<LapSealant_Mutation_Response>;
  /** update data of the table: "LapSealantUsage" */
  update_LapSealantUsage?: Maybe<LapSealantUsage_Mutation_Response>;
  /** update single row of the table: "LapSealantUsage" */
  update_LapSealantUsage_by_pk?: Maybe<LapSealantUsage>;
  /** update single row of the table: "LapSealant" */
  update_LapSealant_by_pk?: Maybe<LapSealant>;
  /** update data of the table: "LapSecurementInfo" */
  update_LapSecurementInfo?: Maybe<LapSecurementInfo_Mutation_Response>;
  /** update single row of the table: "LapSecurementInfo" */
  update_LapSecurementInfo_by_pk?: Maybe<LapSecurementInfo>;
  /** update data of the table: "Layer" */
  update_Layer?: Maybe<Layer_Mutation_Response>;
  /** update data of the table: "LayerConfig" */
  update_LayerConfig?: Maybe<LayerConfig_Mutation_Response>;
  /** update single row of the table: "LayerConfig" */
  update_LayerConfig_by_pk?: Maybe<LayerConfig>;
  /** update single row of the table: "Layer" */
  update_Layer_by_pk?: Maybe<Layer>;
  /** update data of the table: "LightweightInsulatingConcrete" */
  update_LightweightInsulatingConcrete?: Maybe<LightweightInsulatingConcrete_Mutation_Response>;
  /** update data of the table: "LightweightInsulatingConcreteSubassemblyUsage" */
  update_LightweightInsulatingConcreteSubassemblyUsage?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Mutation_Response>;
  /** update single row of the table: "LightweightInsulatingConcreteSubassemblyUsage" */
  update_LightweightInsulatingConcreteSubassemblyUsage_by_pk?: Maybe<LightweightInsulatingConcreteSubassemblyUsage>;
  /** update data of the table: "LightweightInsulatingConcreteUsage" */
  update_LightweightInsulatingConcreteUsage?: Maybe<LightweightInsulatingConcreteUsage_Mutation_Response>;
  /** update single row of the table: "LightweightInsulatingConcreteUsage" */
  update_LightweightInsulatingConcreteUsage_by_pk?: Maybe<LightweightInsulatingConcreteUsage>;
  /** update single row of the table: "LightweightInsulatingConcrete" */
  update_LightweightInsulatingConcrete_by_pk?: Maybe<LightweightInsulatingConcrete>;
  /** update data of the table: "LinerPanel" */
  update_LinerPanel?: Maybe<LinerPanel_Mutation_Response>;
  /** update data of the table: "LinerPanelUsage" */
  update_LinerPanelUsage?: Maybe<LinerPanelUsage_Mutation_Response>;
  /** update single row of the table: "LinerPanelUsage" */
  update_LinerPanelUsage_by_pk?: Maybe<LinerPanelUsage>;
  /** update single row of the table: "LinerPanel" */
  update_LinerPanel_by_pk?: Maybe<LinerPanel>;
  /** update data of the table: "LiquidAppliedSubassemblyUsage" */
  update_LiquidAppliedSubassemblyUsage?: Maybe<LiquidAppliedSubassemblyUsage_Mutation_Response>;
  /** update single row of the table: "LiquidAppliedSubassemblyUsage" */
  update_LiquidAppliedSubassemblyUsage_by_pk?: Maybe<LiquidAppliedSubassemblyUsage>;
  /** update data of the table: "LiquidAppliedUsage" */
  update_LiquidAppliedUsage?: Maybe<LiquidAppliedUsage_Mutation_Response>;
  /** update single row of the table: "LiquidAppliedUsage" */
  update_LiquidAppliedUsage_by_pk?: Maybe<LiquidAppliedUsage>;
  /** update data of the table: "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
  update_Load_SearchLayerUsage_DeckFiberReinforcedPlastic?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Mutation_Response>;
  /** update data of the table: "MD_ApprovedUse" */
  update_MD_ApprovedUse?: Maybe<Md_ApprovedUse_Mutation_Response>;
  /** update data of the table: "MD_ApprovedUseAttributes" */
  update_MD_ApprovedUseAttributes?: Maybe<Md_ApprovedUseAttributes_Mutation_Response>;
  /** update single row of the table: "MD_ApprovedUseAttributes" */
  update_MD_ApprovedUseAttributes_by_pk?: Maybe<Md_ApprovedUseAttributes>;
  /** update single row of the table: "MD_ApprovedUse" */
  update_MD_ApprovedUse_by_pk?: Maybe<Md_ApprovedUse>;
  /** update data of the table: "MD_AttributeBase" */
  update_MD_AttributeBase?: Maybe<Md_AttributeBase_Mutation_Response>;
  /** update single row of the table: "MD_AttributeBase" */
  update_MD_AttributeBase_by_pk?: Maybe<Md_AttributeBase>;
  /** update data of the table: "MD_BooleanAttribute" */
  update_MD_BooleanAttribute?: Maybe<Md_BooleanAttribute_Mutation_Response>;
  /** update single row of the table: "MD_BooleanAttribute" */
  update_MD_BooleanAttribute_by_pk?: Maybe<Md_BooleanAttribute>;
  /** update data of the table: "MD_BooleanDisplayOption" */
  update_MD_BooleanDisplayOption?: Maybe<Md_BooleanDisplayOption_Mutation_Response>;
  /** update single row of the table: "MD_BooleanDisplayOption" */
  update_MD_BooleanDisplayOption_by_pk?: Maybe<Md_BooleanDisplayOption>;
  /** update data of the table: "MD_Catalog" */
  update_MD_Catalog?: Maybe<Md_Catalog_Mutation_Response>;
  /** update single row of the table: "MD_Catalog" */
  update_MD_Catalog_by_pk?: Maybe<Md_Catalog>;
  /** update data of the table: "MD_CodeTableAttribute" */
  update_MD_CodeTableAttribute?: Maybe<Md_CodeTableAttribute_Mutation_Response>;
  /** update single row of the table: "MD_CodeTableAttribute" */
  update_MD_CodeTableAttribute_by_pk?: Maybe<Md_CodeTableAttribute>;
  /** update data of the table: "MD_CodeTables" */
  update_MD_CodeTables?: Maybe<Md_CodeTables_Mutation_Response>;
  /** update single row of the table: "MD_CodeTables" */
  update_MD_CodeTables_by_pk?: Maybe<Md_CodeTables>;
  /** update data of the table: "MD_ComponentUsage" */
  update_MD_ComponentUsage?: Maybe<Md_ComponentUsage_Mutation_Response>;
  /** update data of the table: "MD_ComponentUsageAttributes" */
  update_MD_ComponentUsageAttributes?: Maybe<Md_ComponentUsageAttributes_Mutation_Response>;
  /** update single row of the table: "MD_ComponentUsageAttributes" */
  update_MD_ComponentUsageAttributes_by_pk?: Maybe<Md_ComponentUsageAttributes>;
  /** update single row of the table: "MD_ComponentUsage" */
  update_MD_ComponentUsage_by_pk?: Maybe<Md_ComponentUsage>;
  /** update data of the table: "MD_Measure" */
  update_MD_Measure?: Maybe<Md_Measure_Mutation_Response>;
  /** update single row of the table: "MD_Measure" */
  update_MD_Measure_by_pk?: Maybe<Md_Measure>;
  /** update data of the table: "MD_MeasurementAttribute" */
  update_MD_MeasurementAttribute?: Maybe<Md_MeasurementAttribute_Mutation_Response>;
  /** update single row of the table: "MD_MeasurementAttribute" */
  update_MD_MeasurementAttribute_by_pk?: Maybe<Md_MeasurementAttribute>;
  /** update data of the table: "MD_MeasurementDisplayOption" */
  update_MD_MeasurementDisplayOption?: Maybe<Md_MeasurementDisplayOption_Mutation_Response>;
  /** update single row of the table: "MD_MeasurementDisplayOption" */
  update_MD_MeasurementDisplayOption_by_pk?: Maybe<Md_MeasurementDisplayOption>;
  /** update data of the table: "MD_MultivalueAttribute" */
  update_MD_MultivalueAttribute?: Maybe<Md_MultivalueAttribute_Mutation_Response>;
  /** update single row of the table: "MD_MultivalueAttribute" */
  update_MD_MultivalueAttribute_by_pk?: Maybe<Md_MultivalueAttribute>;
  /** update data of the table: "MD_MultivalueDisplayOption" */
  update_MD_MultivalueDisplayOption?: Maybe<Md_MultivalueDisplayOption_Mutation_Response>;
  /** update single row of the table: "MD_MultivalueDisplayOption" */
  update_MD_MultivalueDisplayOption_by_pk?: Maybe<Md_MultivalueDisplayOption>;
  /** update data of the table: "MD_ProductCategory" */
  update_MD_ProductCategory?: Maybe<Md_ProductCategory_Mutation_Response>;
  /** update single row of the table: "MD_ProductCategory" */
  update_MD_ProductCategory_by_pk?: Maybe<Md_ProductCategory>;
  /** update data of the table: "MD_ProductSubcategory" */
  update_MD_ProductSubcategory?: Maybe<Md_ProductSubcategory_Mutation_Response>;
  /** update single row of the table: "MD_ProductSubcategory" */
  update_MD_ProductSubcategory_by_pk?: Maybe<Md_ProductSubcategory>;
  /** update data of the table: "MaintenanceCoating" */
  update_MaintenanceCoating?: Maybe<MaintenanceCoating_Mutation_Response>;
  /** update data of the table: "MaintenanceCoatingSubstrate" */
  update_MaintenanceCoatingSubstrate?: Maybe<MaintenanceCoatingSubstrate_Mutation_Response>;
  /** update single row of the table: "MaintenanceCoatingSubstrate" */
  update_MaintenanceCoatingSubstrate_by_pk?: Maybe<MaintenanceCoatingSubstrate>;
  /** update data of the table: "MaintenanceCoatingUsage" */
  update_MaintenanceCoatingUsage?: Maybe<MaintenanceCoatingUsage_Mutation_Response>;
  /** update single row of the table: "MaintenanceCoatingUsage" */
  update_MaintenanceCoatingUsage_by_pk?: Maybe<MaintenanceCoatingUsage>;
  /** update single row of the table: "MaintenanceCoating" */
  update_MaintenanceCoating_by_pk?: Maybe<MaintenanceCoating>;
  /** update data of the table: "ManufacturedProduct" */
  update_ManufacturedProduct?: Maybe<ManufacturedProduct_Mutation_Response>;
  /** update single row of the table: "ManufacturedProduct" */
  update_ManufacturedProduct_by_pk?: Maybe<ManufacturedProduct>;
  /** update data of the table: "Manufacturer" */
  update_Manufacturer?: Maybe<Manufacturer_Mutation_Response>;
  /** update data of the table: "ManufacturerChangeHistory" */
  update_ManufacturerChangeHistory?: Maybe<ManufacturerChangeHistory_Mutation_Response>;
  /** update single row of the table: "ManufacturerChangeHistory" */
  update_ManufacturerChangeHistory_by_pk?: Maybe<ManufacturerChangeHistory>;
  /** update data of the table: "ManufacturerList" */
  update_ManufacturerList?: Maybe<ManufacturerList_Mutation_Response>;
  /** update single row of the table: "Manufacturer" */
  update_Manufacturer_by_pk?: Maybe<Manufacturer>;
  /** update data of the table: "MultiplyCoverConfig" */
  update_MultiplyCoverConfig?: Maybe<MultiplyCoverConfig_Mutation_Response>;
  /** update single row of the table: "MultiplyCoverConfig" */
  update_MultiplyCoverConfig_by_pk?: Maybe<MultiplyCoverConfig>;
  /** update data of the table: "MultiplyCoverSubassemblyUsage" */
  update_MultiplyCoverSubassemblyUsage?: Maybe<MultiplyCoverSubassemblyUsage_Mutation_Response>;
  /** update single row of the table: "MultiplyCoverSubassemblyUsage" */
  update_MultiplyCoverSubassemblyUsage_by_pk?: Maybe<MultiplyCoverSubassemblyUsage>;
  /** update data of the table: "NavAssembly" */
  update_NavAssembly?: Maybe<NavAssembly_Mutation_Response>;
  /** update data of the table: "NavAssemblyMultiplyInfo" */
  update_NavAssemblyMultiplyInfo?: Maybe<NavAssemblyMultiplyInfo_Mutation_Response>;
  /** update single row of the table: "NavAssembly" */
  update_NavAssembly_by_pk?: Maybe<NavAssembly>;
  /** update data of the table: "NavLayer" */
  update_NavLayer?: Maybe<NavLayer_Mutation_Response>;
  /** update single row of the table: "NavLayer" */
  update_NavLayer_by_pk?: Maybe<NavLayer>;
  /** update data of the table: "NavMultiplyCoverConfig" */
  update_NavMultiplyCoverConfig?: Maybe<NavMultiplyCoverConfig_Mutation_Response>;
  /** update single row of the table: "NavMultiplyCoverConfig" */
  update_NavMultiplyCoverConfig_by_pk?: Maybe<NavMultiplyCoverConfig>;
  /** update data of the table: "NavSecurementLayer" */
  update_NavSecurementLayer?: Maybe<NavSecurementLayer_Mutation_Response>;
  /** update single row of the table: "NavSecurementLayer" */
  update_NavSecurementLayer_by_pk?: Maybe<NavSecurementLayer>;
  /** update data of the table: "NavSecurementUsage" */
  update_NavSecurementUsage?: Maybe<NavSecurementUsage_Mutation_Response>;
  /** update single row of the table: "NavSecurementUsage" */
  update_NavSecurementUsage_by_pk?: Maybe<NavSecurementUsage>;
  /** update data of the table: "NavUsage" */
  update_NavUsage?: Maybe<NavUsage_Mutation_Response>;
  /** update data of the table: "NavUsageSA" */
  update_NavUsageSA?: Maybe<NavUsageSa_Mutation_Response>;
  /** update single row of the table: "NavUsageSA" */
  update_NavUsageSA_by_pk?: Maybe<NavUsageSa>;
  /** update single row of the table: "NavUsage" */
  update_NavUsage_by_pk?: Maybe<NavUsage>;
  /** update data of the table: "Nav_SinglePlyBondingAdhesives" */
  update_Nav_SinglePlyBondingAdhesives?: Maybe<Nav_SinglePlyBondingAdhesives_Mutation_Response>;
  /** update data of the table: "Nav_SinglePlyLapAdhesives" */
  update_Nav_SinglePlyLapAdhesives?: Maybe<Nav_SinglePlyLapAdhesives_Mutation_Response>;
  /** update data of the table: "NextAssemblyNum" */
  update_NextAssemblyNum?: Maybe<NextAssemblyNum_Mutation_Response>;
  /** update single row of the table: "NextAssemblyNum" */
  update_NextAssemblyNum_by_pk?: Maybe<NextAssemblyNum>;
  /** update data of the table: "Note" */
  update_Note?: Maybe<Note_Mutation_Response>;
  /** update single row of the table: "Note" */
  update_Note_by_pk?: Maybe<Note>;
  /** update data of the table: "OtherPly" */
  update_OtherPly?: Maybe<OtherPly_Mutation_Response>;
  /** update data of the table: "OtherPlyUsage" */
  update_OtherPlyUsage?: Maybe<OtherPlyUsage_Mutation_Response>;
  /** update single row of the table: "OtherPlyUsage" */
  update_OtherPlyUsage_by_pk?: Maybe<OtherPlyUsage>;
  /** update single row of the table: "OtherPly" */
  update_OtherPly_by_pk?: Maybe<OtherPly>;
  /** update data of the table: "PreassembledFastening" */
  update_PreassembledFastening?: Maybe<PreassembledFastening_Mutation_Response>;
  /** update data of the table: "PreassembledFasteningUsage" */
  update_PreassembledFasteningUsage?: Maybe<PreassembledFasteningUsage_Mutation_Response>;
  /** update single row of the table: "PreassembledFasteningUsage" */
  update_PreassembledFasteningUsage_by_pk?: Maybe<PreassembledFasteningUsage>;
  /** update single row of the table: "PreassembledFastening" */
  update_PreassembledFastening_by_pk?: Maybe<PreassembledFastening>;
  /** update data of the table: "PrivateLabel" */
  update_PrivateLabel?: Maybe<PrivateLabel_Mutation_Response>;
  /** update single row of the table: "PrivateLabel" */
  update_PrivateLabel_by_pk?: Maybe<PrivateLabel>;
  /** update data of the table: "PrivateLabeler" */
  update_PrivateLabeler?: Maybe<PrivateLabeler_Mutation_Response>;
  /** update data of the table: "ProductDependencies" */
  update_ProductDependencies?: Maybe<ProductDependencies_Mutation_Response>;
  /** update single row of the table: "ProductDependencies" */
  update_ProductDependencies_by_pk?: Maybe<ProductDependencies>;
  /** update data of the table: "ProductsBySubcategory" */
  update_ProductsBySubcategory?: Maybe<ProductsBySubcategory_Mutation_Response>;
  /** update data of the table: "R_AdhesiveTapeWidth" */
  update_R_AdhesiveTapeWidth?: Maybe<R_AdhesiveTapeWidth_Mutation_Response>;
  /** update single row of the table: "R_AdhesiveTapeWidth" */
  update_R_AdhesiveTapeWidth_by_pk?: Maybe<R_AdhesiveTapeWidth>;
  /** update data of the table: "R_ApprovedUse_Subcategory" */
  update_R_ApprovedUse_Subcategory?: Maybe<R_ApprovedUse_Subcategory_Mutation_Response>;
  /** update single row of the table: "R_ApprovedUse_Subcategory" */
  update_R_ApprovedUse_Subcategory_by_pk?: Maybe<R_ApprovedUse_Subcategory>;
  /** update data of the table: "R_ComponentType_ApprovedUse" */
  update_R_ComponentType_ApprovedUse?: Maybe<R_ComponentType_ApprovedUse_Mutation_Response>;
  /** update single row of the table: "R_ComponentType_ApprovedUse" */
  update_R_ComponentType_ApprovedUse_by_pk?: Maybe<R_ComponentType_ApprovedUse>;
  /** update data of the table: "R_CopingFastener" */
  update_R_CopingFastener?: Maybe<R_CopingFastener_Mutation_Response>;
  /** update single row of the table: "R_CopingFastener" */
  update_R_CopingFastener_by_pk?: Maybe<R_CopingFastener>;
  /** update data of the table: "R_Country_StateProvince" */
  update_R_Country_StateProvince?: Maybe<R_Country_StateProvince_Mutation_Response>;
  /** update single row of the table: "R_Country_StateProvince" */
  update_R_Country_StateProvince_by_pk?: Maybe<R_Country_StateProvince>;
  /** update data of the table: "R_ExpansionJoinFastener" */
  update_R_ExpansionJoinFastener?: Maybe<R_ExpansionJoinFastener_Mutation_Response>;
  /** update single row of the table: "R_ExpansionJoinFastener" */
  update_R_ExpansionJoinFastener_by_pk?: Maybe<R_ExpansionJoinFastener>;
  /** update data of the table: "R_FasciaComponentFastenerComponent" */
  update_R_FasciaComponentFastenerComponent?: Maybe<R_FasciaComponentFastenerComponent_Mutation_Response>;
  /** update single row of the table: "R_FasciaComponentFastenerComponent" */
  update_R_FasciaComponentFastenerComponent_by_pk?: Maybe<R_FasciaComponentFastenerComponent>;
  /** update data of the table: "R_GutterFasteners" */
  update_R_GutterFasteners?: Maybe<R_GutterFasteners_Mutation_Response>;
  /** update single row of the table: "R_GutterFasteners" */
  update_R_GutterFasteners_by_pk?: Maybe<R_GutterFasteners>;
  /** update data of the table: "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
  update_R_LinerPanelMaterialFiberReinforcedPlasticDeck?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Mutation_Response>;
  /** update single row of the table: "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
  update_R_LinerPanelMaterialFiberReinforcedPlasticDeck_by_pk?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck>;
  /** update data of the table: "R_MaintenanceCoatingSubstrate" */
  update_R_MaintenanceCoatingSubstrate?: Maybe<R_MaintenanceCoatingSubstrate_Mutation_Response>;
  /** update single row of the table: "R_MaintenanceCoatingSubstrate" */
  update_R_MaintenanceCoatingSubstrate_by_pk?: Maybe<R_MaintenanceCoatingSubstrate>;
  /** update data of the table: "R_NavAssembly_NavMultiplyCoverConfig" */
  update_R_NavAssembly_NavMultiplyCoverConfig?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Mutation_Response>;
  /** update single row of the table: "R_NavAssembly_NavMultiplyCoverConfig" */
  update_R_NavAssembly_NavMultiplyCoverConfig_by_pk?: Maybe<R_NavAssembly_NavMultiplyCoverConfig>;
  /** update data of the table: "R_ProjectContact" */
  update_R_ProjectContact?: Maybe<R_ProjectContact_Mutation_Response>;
  /** update single row of the table: "R_ProjectContact" */
  update_R_ProjectContact_by_pk?: Maybe<R_ProjectContact>;
  /** update data of the table: "R_ProjectNote" */
  update_R_ProjectNote?: Maybe<R_ProjectNote_Mutation_Response>;
  /** update single row of the table: "R_ProjectNote" */
  update_R_ProjectNote_by_pk?: Maybe<R_ProjectNote>;
  /** update data of the table: "R_RegletFastener" */
  update_R_RegletFastener?: Maybe<R_RegletFastener_Mutation_Response>;
  /** update single row of the table: "R_RegletFastener" */
  update_R_RegletFastener_by_pk?: Maybe<R_RegletFastener>;
  /** update data of the table: "R_RoofAreaContact" */
  update_R_RoofAreaContact?: Maybe<R_RoofAreaContact_Mutation_Response>;
  /** update single row of the table: "R_RoofAreaContact" */
  update_R_RoofAreaContact_by_pk?: Maybe<R_RoofAreaContact>;
  /** update data of the table: "R_RoofAreaNote" */
  update_R_RoofAreaNote?: Maybe<R_RoofAreaNote_Mutation_Response>;
  /** update single row of the table: "R_RoofAreaNote" */
  update_R_RoofAreaNote_by_pk?: Maybe<R_RoofAreaNote>;
  /** update data of the table: "R_RoofAreaScenario" */
  update_R_RoofAreaScenario?: Maybe<R_RoofAreaScenario_Mutation_Response>;
  /** update single row of the table: "R_RoofAreaScenario" */
  update_R_RoofAreaScenario_by_pk?: Maybe<R_RoofAreaScenario>;
  /** update data of the table: "R_ScenarioNote" */
  update_R_ScenarioNote?: Maybe<R_ScenarioNote_Mutation_Response>;
  /** update single row of the table: "R_ScenarioNote" */
  update_R_ScenarioNote_by_pk?: Maybe<R_ScenarioNote>;
  /** update data of the table: "R_SinglePly_BondingAdhesives" */
  update_R_SinglePly_BondingAdhesives?: Maybe<R_SinglePly_BondingAdhesives_Mutation_Response>;
  /** update single row of the table: "R_SinglePly_BondingAdhesives" */
  update_R_SinglePly_BondingAdhesives_by_pk?: Maybe<R_SinglePly_BondingAdhesives>;
  /** update data of the table: "R_SinglePly_LapAdhesives" */
  update_R_SinglePly_LapAdhesives?: Maybe<R_SinglePly_LapAdhesives_Mutation_Response>;
  /** update single row of the table: "R_SinglePly_LapAdhesives" */
  update_R_SinglePly_LapAdhesives_by_pk?: Maybe<R_SinglePly_LapAdhesives>;
  /** update data of the table: "R_Subassembly_MultiplyCoverConfig" */
  update_R_Subassembly_MultiplyCoverConfig?: Maybe<R_Subassembly_MultiplyCoverConfig_Mutation_Response>;
  /** update single row of the table: "R_Subassembly_MultiplyCoverConfig" */
  update_R_Subassembly_MultiplyCoverConfig_by_pk?: Maybe<R_Subassembly_MultiplyCoverConfig>;
  /** update data of the table: "R_UserContact" */
  update_R_UserContact?: Maybe<R_UserContact_Mutation_Response>;
  /** update single row of the table: "R_UserContact" */
  update_R_UserContact_by_pk?: Maybe<R_UserContact>;
  /** update data of the table: "Reglet" */
  update_Reglet?: Maybe<Reglet_Mutation_Response>;
  /** update single row of the table: "Reglet" */
  update_Reglet_by_pk?: Maybe<Reglet>;
  /** update data of the table: "ReviewerWorkspace" */
  update_ReviewerWorkspace?: Maybe<ReviewerWorkspace_Mutation_Response>;
  /** update data of the table: "ReviewerWorkspaceData" */
  update_ReviewerWorkspaceData?: Maybe<ReviewerWorkspaceData_Mutation_Response>;
  /** update single row of the table: "ReviewerWorkspaceData" */
  update_ReviewerWorkspaceData_by_pk?: Maybe<ReviewerWorkspaceData>;
  /** update single row of the table: "ReviewerWorkspace" */
  update_ReviewerWorkspace_by_pk?: Maybe<ReviewerWorkspace>;
  /** update data of the table: "RoofArea" */
  update_RoofArea?: Maybe<RoofArea_Mutation_Response>;
  /** update data of the table: "RoofAreaWindPressure" */
  update_RoofAreaWindPressure?: Maybe<RoofAreaWindPressure_Mutation_Response>;
  /** update single row of the table: "RoofAreaWindPressure" */
  update_RoofAreaWindPressure_by_pk?: Maybe<RoofAreaWindPressure>;
  /** update single row of the table: "RoofArea" */
  update_RoofArea_by_pk?: Maybe<RoofArea>;
  /** update data of the table: "RoofAssembly" */
  update_RoofAssembly?: Maybe<RoofAssembly_Mutation_Response>;
  /** update data of the table: "RoofAssemblyDelistData" */
  update_RoofAssemblyDelistData?: Maybe<RoofAssemblyDelistData_Mutation_Response>;
  /** update data of the table: "RoofAssemblyLayers" */
  update_RoofAssemblyLayers?: Maybe<RoofAssemblyLayers_Mutation_Response>;
  /** update single row of the table: "RoofAssemblyLayers" */
  update_RoofAssemblyLayers_by_pk?: Maybe<RoofAssemblyLayers>;
  /** update single row of the table: "RoofAssembly" */
  update_RoofAssembly_by_pk?: Maybe<RoofAssembly>;
  /** update data of the table: "RoofProject" */
  update_RoofProject?: Maybe<RoofProject_Mutation_Response>;
  /** update single row of the table: "RoofProject" */
  update_RoofProject_by_pk?: Maybe<RoofProject>;
  /** update data of the table: "Scenario" */
  update_Scenario?: Maybe<Scenario_Mutation_Response>;
  /** update data of the table: "ScenarioAlert" */
  update_ScenarioAlert?: Maybe<ScenarioAlert_Mutation_Response>;
  /** update single row of the table: "ScenarioAlert" */
  update_ScenarioAlert_by_pk?: Maybe<ScenarioAlert>;
  /** update data of the table: "ScenarioLayer" */
  update_ScenarioLayer?: Maybe<ScenarioLayer_Mutation_Response>;
  /** update single row of the table: "ScenarioLayer" */
  update_ScenarioLayer_by_pk?: Maybe<ScenarioLayer>;
  /** update data of the table: "ScenarioUsage" */
  update_ScenarioUsage?: Maybe<ScenarioUsage_Mutation_Response>;
  /** update single row of the table: "ScenarioUsage" */
  update_ScenarioUsage_by_pk?: Maybe<ScenarioUsage>;
  /** update single row of the table: "Scenario" */
  update_Scenario_by_pk?: Maybe<Scenario>;
  /** update data of the table: "SeamPrimer" */
  update_SeamPrimer?: Maybe<SeamPrimer_Mutation_Response>;
  /** update data of the table: "SeamPrimerUsage" */
  update_SeamPrimerUsage?: Maybe<SeamPrimerUsage_Mutation_Response>;
  /** update single row of the table: "SeamPrimerUsage" */
  update_SeamPrimerUsage_by_pk?: Maybe<SeamPrimerUsage>;
  /** update single row of the table: "SeamPrimer" */
  update_SeamPrimer_by_pk?: Maybe<SeamPrimer>;
  /** update data of the table: "SeamTapeSystemUsage" */
  update_SeamTapeSystemUsage?: Maybe<SeamTapeSystemUsage_Mutation_Response>;
  /** update single row of the table: "SeamTapeSystemUsage" */
  update_SeamTapeSystemUsage_by_pk?: Maybe<SeamTapeSystemUsage>;
  /** update data of the table: "SeamWash" */
  update_SeamWash?: Maybe<SeamWash_Mutation_Response>;
  /** update data of the table: "SeamWashUsage" */
  update_SeamWashUsage?: Maybe<SeamWashUsage_Mutation_Response>;
  /** update single row of the table: "SeamWashUsage" */
  update_SeamWashUsage_by_pk?: Maybe<SeamWashUsage>;
  /** update single row of the table: "SeamWash" */
  update_SeamWash_by_pk?: Maybe<SeamWash>;
  /** update data of the table: "SearchNavAssembly_ManufacturerProduct" */
  update_SearchNavAssembly_ManufacturerProduct?: Maybe<SearchNavAssembly_ManufacturerProduct_Mutation_Response>;
  /** update single row of the table: "SearchNavAssembly_ManufacturerProduct" */
  update_SearchNavAssembly_ManufacturerProduct_by_pk?: Maybe<SearchNavAssembly_ManufacturerProduct>;
  /** update data of the table: "SecurementLayer" */
  update_SecurementLayer?: Maybe<SecurementLayer_Mutation_Response>;
  /** update data of the table: "SecurementLayerUsage" */
  update_SecurementLayerUsage?: Maybe<SecurementLayerUsage_Mutation_Response>;
  /** update single row of the table: "SecurementLayerUsage" */
  update_SecurementLayerUsage_by_pk?: Maybe<SecurementLayerUsage>;
  /** update single row of the table: "SecurementLayer" */
  update_SecurementLayer_by_pk?: Maybe<SecurementLayer>;
  /** update data of the table: "SelfSecurementUsage" */
  update_SelfSecurementUsage?: Maybe<SelfSecurementUsage_Mutation_Response>;
  /** update single row of the table: "SelfSecurementUsage" */
  update_SelfSecurementUsage_by_pk?: Maybe<SelfSecurementUsage>;
  /** update data of the table: "SeparatorSheet" */
  update_SeparatorSheet?: Maybe<SeparatorSheet_Mutation_Response>;
  /** update data of the table: "SeparatorSheetUsage" */
  update_SeparatorSheetUsage?: Maybe<SeparatorSheetUsage_Mutation_Response>;
  /** update single row of the table: "SeparatorSheetUsage" */
  update_SeparatorSheetUsage_by_pk?: Maybe<SeparatorSheetUsage>;
  /** update single row of the table: "SeparatorSheet" */
  update_SeparatorSheet_by_pk?: Maybe<SeparatorSheet>;
  /** update data of the table: "Shingles" */
  update_Shingles?: Maybe<Shingles_Mutation_Response>;
  /** update single row of the table: "Shingles" */
  update_Shingles_by_pk?: Maybe<Shingles>;
  /** update data of the table: "SinglePlyCover" */
  update_SinglePlyCover?: Maybe<SinglePlyCover_Mutation_Response>;
  /** update single row of the table: "SinglePlyCover" */
  update_SinglePlyCover_by_pk?: Maybe<SinglePlyCover>;
  /** update data of the table: "SinglePlyUsage" */
  update_SinglePlyUsage?: Maybe<SinglePlyUsage_Mutation_Response>;
  /** update single row of the table: "SinglePlyUsage" */
  update_SinglePlyUsage_by_pk?: Maybe<SinglePlyUsage>;
  /** update data of the table: "SprayFoamInsulationUsage" */
  update_SprayFoamInsulationUsage?: Maybe<SprayFoamInsulationUsage_Mutation_Response>;
  /** update single row of the table: "SprayFoamInsulationUsage" */
  update_SprayFoamInsulationUsage_by_pk?: Maybe<SprayFoamInsulationUsage>;
  /** update data of the table: "SprayedFoamInsulation" */
  update_SprayedFoamInsulation?: Maybe<SprayedFoamInsulation_Mutation_Response>;
  /** update single row of the table: "SprayedFoamInsulation" */
  update_SprayedFoamInsulation_by_pk?: Maybe<SprayedFoamInsulation>;
  /** update data of the table: "StandingLapSeamCover" */
  update_StandingLapSeamCover?: Maybe<StandingLapSeamCover_Mutation_Response>;
  /** update single row of the table: "StandingLapSeamCover" */
  update_StandingLapSeamCover_by_pk?: Maybe<StandingLapSeamCover>;
  /** update data of the table: "StandingLapSeamUsage" */
  update_StandingLapSeamUsage?: Maybe<StandingLapSeamUsage_Mutation_Response>;
  /** update single row of the table: "StandingLapSeamUsage" */
  update_StandingLapSeamUsage_by_pk?: Maybe<StandingLapSeamUsage>;
  /** update data of the table: "SteelDeck" */
  update_SteelDeck?: Maybe<SteelDeck_Mutation_Response>;
  /** update data of the table: "SteelDeckLayer" */
  update_SteelDeckLayer?: Maybe<SteelDeckLayer_Mutation_Response>;
  /** update single row of the table: "SteelDeckLayer" */
  update_SteelDeckLayer_by_pk?: Maybe<SteelDeckLayer>;
  /** update data of the table: "SteelDeckUsage" */
  update_SteelDeckUsage?: Maybe<SteelDeckUsage_Mutation_Response>;
  /** update single row of the table: "SteelDeckUsage" */
  update_SteelDeckUsage_by_pk?: Maybe<SteelDeckUsage>;
  /** update single row of the table: "SteelDeck" */
  update_SteelDeck_by_pk?: Maybe<SteelDeck>;
  /** update data of the table: "StressPlate" */
  update_StressPlate?: Maybe<StressPlate_Mutation_Response>;
  /** update data of the table: "StressPlateUsage" */
  update_StressPlateUsage?: Maybe<StressPlateUsage_Mutation_Response>;
  /** update single row of the table: "StressPlateUsage" */
  update_StressPlateUsage_by_pk?: Maybe<StressPlateUsage>;
  /** update single row of the table: "StressPlate" */
  update_StressPlate_by_pk?: Maybe<StressPlate>;
  /** update data of the table: "StructuralConcreteLayer" */
  update_StructuralConcreteLayer?: Maybe<StructuralConcreteLayer_Mutation_Response>;
  /** update single row of the table: "StructuralConcreteLayer" */
  update_StructuralConcreteLayer_by_pk?: Maybe<StructuralConcreteLayer>;
  /** update data of the table: "StructureLayer" */
  update_StructureLayer?: Maybe<StructureLayer_Mutation_Response>;
  /** update single row of the table: "StructureLayer" */
  update_StructureLayer_by_pk?: Maybe<StructureLayer>;
  /** update data of the table: "StructuredSystem" */
  update_StructuredSystem?: Maybe<StructuredSystem_Mutation_Response>;
  /** update data of the table: "StructuredSystemProducts" */
  update_StructuredSystemProducts?: Maybe<StructuredSystemProducts_Mutation_Response>;
  /** update single row of the table: "StructuredSystemProducts" */
  update_StructuredSystemProducts_by_pk?: Maybe<StructuredSystemProducts>;
  /** update single row of the table: "StructuredSystem" */
  update_StructuredSystem_by_pk?: Maybe<StructuredSystem>;
  /** update data of the table: "Subassembly" */
  update_Subassembly?: Maybe<Subassembly_Mutation_Response>;
  /** update data of the table: "SubassemblyDelistData" */
  update_SubassemblyDelistData?: Maybe<SubassemblyDelistData_Mutation_Response>;
  /** update data of the table: "SubassemblyE108Rating" */
  update_SubassemblyE108Rating?: Maybe<SubassemblyE108Rating_Mutation_Response>;
  /** update single row of the table: "SubassemblyE108Rating" */
  update_SubassemblyE108Rating_by_pk?: Maybe<SubassemblyE108Rating>;
  /** update data of the table: "SubassemblyLayers" */
  update_SubassemblyLayers?: Maybe<SubassemblyLayers_Mutation_Response>;
  /** update single row of the table: "SubassemblyLayers" */
  update_SubassemblyLayers_by_pk?: Maybe<SubassemblyLayers>;
  /** update data of the table: "SubassemblyMultiplyConfig" */
  update_SubassemblyMultiplyConfig?: Maybe<SubassemblyMultiplyConfig_Mutation_Response>;
  /** update single row of the table: "SubassemblyMultiplyConfig" */
  update_SubassemblyMultiplyConfig_by_pk?: Maybe<SubassemblyMultiplyConfig>;
  /** update single row of the table: "Subassembly" */
  update_Subassembly_by_pk?: Maybe<Subassembly>;
  /** update data of the table: "Subcat_Adhesive" */
  update_Subcat_Adhesive?: Maybe<Subcat_Adhesive_Mutation_Response>;
  /** update data of the table: "Subcat_AirRetarder" */
  update_Subcat_AirRetarder?: Maybe<Subcat_AirRetarder_Mutation_Response>;
  /** update data of the table: "Subcat_BaseSheet" */
  update_Subcat_BaseSheet?: Maybe<Subcat_BaseSheet_Mutation_Response>;
  /** update data of the table: "Subcat_BattBlanket" */
  update_Subcat_BattBlanket?: Maybe<Subcat_BattBlanket_Mutation_Response>;
  /** update data of the table: "Subcat_BoardStock" */
  update_Subcat_BoardStock?: Maybe<Subcat_BoardStock_Mutation_Response>;
  /** update data of the table: "Subcat_Coating" */
  update_Subcat_Coating?: Maybe<Subcat_Coating_Mutation_Response>;
  /** update data of the table: "Subcat_CompositePanel" */
  update_Subcat_CompositePanel?: Maybe<Subcat_CompositePanel_Mutation_Response>;
  /** update data of the table: "Subcat_CoverStrip" */
  update_Subcat_CoverStrip?: Maybe<Subcat_CoverStrip_Mutation_Response>;
  /** update data of the table: "Subcat_ExpJoint" */
  update_Subcat_ExpJoint?: Maybe<Subcat_ExpJoint_Mutation_Response>;
  /** update data of the table: "Subcat_Fastening" */
  update_Subcat_Fastening?: Maybe<Subcat_Fastening_Mutation_Response>;
  /** update data of the table: "Subcat_FormDeck" */
  update_Subcat_FormDeck?: Maybe<Subcat_FormDeck_Mutation_Response>;
  /** update data of the table: "Subcat_Gutter" */
  update_Subcat_Gutter?: Maybe<Subcat_Gutter_Mutation_Response>;
  /** update data of the table: "Subcat_Gypsum" */
  update_Subcat_Gypsum?: Maybe<Subcat_Gypsum_Mutation_Response>;
  /** update data of the table: "Subcat_LWIC" */
  update_Subcat_LWIC?: Maybe<Subcat_Lwic_Mutation_Response>;
  /** update data of the table: "Subcat_LWICDeck" */
  update_Subcat_LWICDeck?: Maybe<Subcat_LwicDeck_Mutation_Response>;
  /** update data of the table: "Subcat_LinerPanel" */
  update_Subcat_LinerPanel?: Maybe<Subcat_LinerPanel_Mutation_Response>;
  /** update data of the table: "Subcat_LiquidApplied" */
  update_Subcat_LiquidApplied?: Maybe<Subcat_LiquidApplied_Mutation_Response>;
  /** update data of the table: "Subcat_MaintCoating" */
  update_Subcat_MaintCoating?: Maybe<Subcat_MaintCoating_Mutation_Response>;
  /** update data of the table: "Subcat_Multiply" */
  update_Subcat_Multiply?: Maybe<Subcat_Multiply_Mutation_Response>;
  /** update data of the table: "Subcat_MultiplyCover" */
  update_Subcat_MultiplyCover?: Maybe<Subcat_MultiplyCover_Mutation_Response>;
  /** update data of the table: "Subcat_PerimeterFlashing" */
  update_Subcat_PerimeterFlashing?: Maybe<Subcat_PerimeterFlashing_Mutation_Response>;
  /** update data of the table: "Subcat_PlasticDeck" */
  update_Subcat_PlasticDeck?: Maybe<Subcat_PlasticDeck_Mutation_Response>;
  /** update data of the table: "Subcat_Primer" */
  update_Subcat_Primer?: Maybe<Subcat_Primer_Mutation_Response>;
  /** update data of the table: "Subcat_Seaming" */
  update_Subcat_Seaming?: Maybe<Subcat_Seaming_Mutation_Response>;
  /** update data of the table: "Subcat_SeparatorSheet" */
  update_Subcat_SeparatorSheet?: Maybe<Subcat_SeparatorSheet_Mutation_Response>;
  /** update data of the table: "Subcat_Shingles" */
  update_Subcat_Shingles?: Maybe<Subcat_Shingles_Mutation_Response>;
  /** update data of the table: "Subcat_SinglePly" */
  update_Subcat_SinglePly?: Maybe<Subcat_SinglePly_Mutation_Response>;
  /** update data of the table: "Subcat_SinglePlyCover" */
  update_Subcat_SinglePlyCover?: Maybe<Subcat_SinglePlyCover_Mutation_Response>;
  /** update data of the table: "Subcat_SprayFoam" */
  update_Subcat_SprayFoam?: Maybe<Subcat_SprayFoam_Mutation_Response>;
  /** update data of the table: "Subcat_StandingLapSeam" */
  update_Subcat_StandingLapSeam?: Maybe<Subcat_StandingLapSeam_Mutation_Response>;
  /** update data of the table: "Subcat_SteelDeck" */
  update_Subcat_SteelDeck?: Maybe<Subcat_SteelDeck_Mutation_Response>;
  /** update data of the table: "Subcat_Surfacing" */
  update_Subcat_Surfacing?: Maybe<Subcat_Surfacing_Mutation_Response>;
  /** update data of the table: "Subcat_VaporRetarder" */
  update_Subcat_VaporRetarder?: Maybe<Subcat_VaporRetarder_Mutation_Response>;
  /** update data of the table: "Subcat_Weld" */
  update_Subcat_Weld?: Maybe<Subcat_Weld_Mutation_Response>;
  /** update data of the table: "Subcat_Wood" */
  update_Subcat_Wood?: Maybe<Subcat_Wood_Mutation_Response>;
  /** update data of the table: "Subcat_WoodFiberDeck" */
  update_Subcat_WoodFiberDeck?: Maybe<Subcat_WoodFiberDeck_Mutation_Response>;
  /** update data of the table: "SubstratePrimer" */
  update_SubstratePrimer?: Maybe<SubstratePrimer_Mutation_Response>;
  /** update data of the table: "SubstratePrimerUsage" */
  update_SubstratePrimerUsage?: Maybe<SubstratePrimerUsage_Mutation_Response>;
  /** update single row of the table: "SubstratePrimerUsage" */
  update_SubstratePrimerUsage_by_pk?: Maybe<SubstratePrimerUsage>;
  /** update single row of the table: "SubstratePrimer" */
  update_SubstratePrimer_by_pk?: Maybe<SubstratePrimer>;
  /** update data of the table: "Surfacing" */
  update_Surfacing?: Maybe<Surfacing_Mutation_Response>;
  /** update data of the table: "SurfacingUsage" */
  update_SurfacingUsage?: Maybe<SurfacingUsage_Mutation_Response>;
  /** update single row of the table: "SurfacingUsage" */
  update_SurfacingUsage_by_pk?: Maybe<SurfacingUsage>;
  /** update single row of the table: "Surfacing" */
  update_Surfacing_by_pk?: Maybe<Surfacing>;
  /** update data of the table: "TapeWidth" */
  update_TapeWidth?: Maybe<TapeWidth_Mutation_Response>;
  /** update single row of the table: "TapeWidth" */
  update_TapeWidth_by_pk?: Maybe<TapeWidth>;
  /** update data of the table: "ThermalBarrier" */
  update_ThermalBarrier?: Maybe<ThermalBarrier_Mutation_Response>;
  /** update data of the table: "ThermalBarrierBoardSizes" */
  update_ThermalBarrierBoardSizes?: Maybe<ThermalBarrierBoardSizes_Mutation_Response>;
  /** update single row of the table: "ThermalBarrierBoardSizes" */
  update_ThermalBarrierBoardSizes_by_pk?: Maybe<ThermalBarrierBoardSizes>;
  /** update data of the table: "ThermalBarrierFacerBottom" */
  update_ThermalBarrierFacerBottom?: Maybe<ThermalBarrierFacerBottom_Mutation_Response>;
  /** update single row of the table: "ThermalBarrierFacerBottom" */
  update_ThermalBarrierFacerBottom_by_pk?: Maybe<ThermalBarrierFacerBottom>;
  /** update data of the table: "ThermalBarrierFacerTop" */
  update_ThermalBarrierFacerTop?: Maybe<ThermalBarrierFacerTop_Mutation_Response>;
  /** update single row of the table: "ThermalBarrierFacerTop" */
  update_ThermalBarrierFacerTop_by_pk?: Maybe<ThermalBarrierFacerTop>;
  /** update data of the table: "ThermalBarrierUsage" */
  update_ThermalBarrierUsage?: Maybe<ThermalBarrierUsage_Mutation_Response>;
  /** update single row of the table: "ThermalBarrierUsage" */
  update_ThermalBarrierUsage_by_pk?: Maybe<ThermalBarrierUsage>;
  /** update single row of the table: "ThermalBarrier" */
  update_ThermalBarrier_by_pk?: Maybe<ThermalBarrier>;
  /** update data of the table: "TopCoat" */
  update_TopCoat?: Maybe<TopCoat_Mutation_Response>;
  /** update data of the table: "TopCoatUsage" */
  update_TopCoatUsage?: Maybe<TopCoatUsage_Mutation_Response>;
  /** update single row of the table: "TopCoatUsage" */
  update_TopCoatUsage_by_pk?: Maybe<TopCoatUsage>;
  /** update single row of the table: "TopCoat" */
  update_TopCoat_by_pk?: Maybe<TopCoat>;
  /** update data of the table: "Usage" */
  update_Usage?: Maybe<Usage_Mutation_Response>;
  /** update data of the table: "UsageTradeNameView" */
  update_UsageTradeNameView?: Maybe<UsageTradeNameView_Mutation_Response>;
  /** update single row of the table: "Usage" */
  update_Usage_by_pk?: Maybe<Usage>;
  /** update data of the table: "UserReportBug" */
  update_UserReportBug?: Maybe<UserReportBug_Mutation_Response>;
  /** update single row of the table: "UserReportBug" */
  update_UserReportBug_by_pk?: Maybe<UserReportBug>;
  /** update data of the table: "UserShareLink" */
  update_UserShareLink?: Maybe<UserShareLink_Mutation_Response>;
  /** update single row of the table: "UserShareLink" */
  update_UserShareLink_by_pk?: Maybe<UserShareLink>;
  /** update data of the table: "VaporRetarder" */
  update_VaporRetarder?: Maybe<VaporRetarder_Mutation_Response>;
  /** update data of the table: "VaporRetarderUsage" */
  update_VaporRetarderUsage?: Maybe<VaporRetarderUsage_Mutation_Response>;
  /** update single row of the table: "VaporRetarderUsage" */
  update_VaporRetarderUsage_by_pk?: Maybe<VaporRetarderUsage>;
  /** update single row of the table: "VaporRetarder" */
  update_VaporRetarder_by_pk?: Maybe<VaporRetarder>;
  /** update data of the table: "WeldArcUsage" */
  update_WeldArcUsage?: Maybe<WeldArcUsage_Mutation_Response>;
  /** update single row of the table: "WeldArcUsage" */
  update_WeldArcUsage_by_pk?: Maybe<WeldArcUsage>;
  /** update data of the table: "WeldHeatUsage" */
  update_WeldHeatUsage?: Maybe<WeldHeatUsage_Mutation_Response>;
  /** update single row of the table: "WeldHeatUsage" */
  update_WeldHeatUsage_by_pk?: Maybe<WeldHeatUsage>;
  /** update data of the table: "WeldSolventUsage" */
  update_WeldSolventUsage?: Maybe<WeldSolventUsage_Mutation_Response>;
  /** update single row of the table: "WeldSolventUsage" */
  update_WeldSolventUsage_by_pk?: Maybe<WeldSolventUsage>;
  /** update data of the table: "WeldSystemUsage" */
  update_WeldSystemUsage?: Maybe<WeldSystemUsage_Mutation_Response>;
  /** update single row of the table: "WeldSystemUsage" */
  update_WeldSystemUsage_by_pk?: Maybe<WeldSystemUsage>;
  /** update data of the table: "WeldTorchedUsage" */
  update_WeldTorchedUsage?: Maybe<WeldTorchedUsage_Mutation_Response>;
  /** update single row of the table: "WeldTorchedUsage" */
  update_WeldTorchedUsage_by_pk?: Maybe<WeldTorchedUsage>;
  /** update data of the table: "WoodDeck" */
  update_WoodDeck?: Maybe<WoodDeck_Mutation_Response>;
  /** update data of the table: "WoodDeckUsage" */
  update_WoodDeckUsage?: Maybe<WoodDeckUsage_Mutation_Response>;
  /** update single row of the table: "WoodDeckUsage" */
  update_WoodDeckUsage_by_pk?: Maybe<WoodDeckUsage>;
  /** update single row of the table: "WoodDeck" */
  update_WoodDeck_by_pk?: Maybe<WoodDeck>;
  /** update data of the table: "vNavAssembly_Layers" */
  update_vNavAssembly_Layers?: Maybe<VNavAssembly_Layers_Mutation_Response>;
  /** update data of the table: "vNavAssembly_ManufacturerOnlySearch" */
  update_vNavAssembly_ManufacturerOnlySearch?: Maybe<VNavAssembly_ManufacturerOnlySearch_Mutation_Response>;
  /** update data of the table: "vNavAssembly_RoofTypeCount" */
  update_vNavAssembly_RoofTypeCount?: Maybe<VNavAssembly_RoofTypeCount_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search" */
  update_vNavAssembly_Search?: Maybe<VNavAssembly_Search_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_AirRetarder" */
  update_vNavAssembly_Search_AirRetarder?: Maybe<VNavAssembly_Search_AirRetarder_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_BaseCoat" */
  update_vNavAssembly_Search_BaseCoat?: Maybe<VNavAssembly_Search_BaseCoat_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_BasePly" */
  update_vNavAssembly_Search_BasePly?: Maybe<VNavAssembly_Search_BasePly_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_BaseSheet" */
  update_vNavAssembly_Search_BaseSheet?: Maybe<VNavAssembly_Search_BaseSheet_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_BoardStock" */
  update_vNavAssembly_Search_BoardStock?: Maybe<VNavAssembly_Search_BoardStock_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_CapPly" */
  update_vNavAssembly_Search_CapPly?: Maybe<VNavAssembly_Search_CapPly_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_Coat" */
  update_vNavAssembly_Search_Coat?: Maybe<VNavAssembly_Search_Coat_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_Coating" */
  update_vNavAssembly_Search_Coating?: Maybe<VNavAssembly_Search_Coating_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_CoverBoard" */
  update_vNavAssembly_Search_CoverBoard?: Maybe<VNavAssembly_Search_CoverBoard_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_CoverCompositePanel" */
  update_vNavAssembly_Search_CoverCompositePanel?: Maybe<VNavAssembly_Search_CoverCompositePanel_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_CoverSingleply" */
  update_vNavAssembly_Search_CoverSingleply?: Maybe<VNavAssembly_Search_CoverSingleply_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_CoverStandingLapSeam" */
  update_vNavAssembly_Search_CoverStandingLapSeam?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_CoverStripDisk" */
  update_vNavAssembly_Search_CoverStripDisk?: Maybe<VNavAssembly_Search_CoverStripDisk_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_DeckCementitiousPanel" */
  update_vNavAssembly_Search_DeckCementitiousPanel?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_DeckSteel" */
  update_vNavAssembly_Search_DeckSteel?: Maybe<VNavAssembly_Search_DeckSteel_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_FormDeck" */
  update_vNavAssembly_Search_FormDeck?: Maybe<VNavAssembly_Search_FormDeck_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_InsulationBattBlanket" */
  update_vNavAssembly_Search_InsulationBattBlanket?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_InsulationSprayFoam" */
  update_vNavAssembly_Search_InsulationSprayFoam?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_LightweightInsulatingConcrete" */
  update_vNavAssembly_Search_LightweightInsulatingConcrete?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_LinerPanel" */
  update_vNavAssembly_Search_LinerPanel?: Maybe<VNavAssembly_Search_LinerPanel_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_Ply" */
  update_vNavAssembly_Search_Ply?: Maybe<VNavAssembly_Search_Ply_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_SeparatorSheet" */
  update_vNavAssembly_Search_SeparatorSheet?: Maybe<VNavAssembly_Search_SeparatorSheet_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_SubstratePrimer" */
  update_vNavAssembly_Search_SubstratePrimer?: Maybe<VNavAssembly_Search_SubstratePrimer_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_Surfacing" */
  update_vNavAssembly_Search_Surfacing?: Maybe<VNavAssembly_Search_Surfacing_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_ThermalBarrier" */
  update_vNavAssembly_Search_ThermalBarrier?: Maybe<VNavAssembly_Search_ThermalBarrier_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_TopCoat" */
  update_vNavAssembly_Search_TopCoat?: Maybe<VNavAssembly_Search_TopCoat_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Search_VaporRetarder" */
  update_vNavAssembly_Search_VaporRetarder?: Maybe<VNavAssembly_Search_VaporRetarder_Mutation_Response>;
  /** update data of the table: "vNavAssembly_Usages" */
  update_vNavAssembly_Usages?: Maybe<VNavAssembly_Usages_Mutation_Response>;
};


/** mutation root */
export type Mutation_RootDelete_AddressArgs = {
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_By_PkArgs = {
  AddressId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AdheredSinglePlyOrphanReportArgs = {
  where: AdheredSinglePlyOrphanReport_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AdheredSinglePlyOrphanReport_By_PkArgs = {
  AdheredSinglePlyOrphansId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AdhesiveFullApplicationArgs = {
  where: AdhesiveFullApplication_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AdhesiveFullApplicationUsageArgs = {
  where: AdhesiveFullApplicationUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AdhesiveFullApplicationUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AdhesiveFullApplication_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AdhesiveRibbonUsageArgs = {
  where: AdhesiveRibbonUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AdhesiveRibbonUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AdhesiveRibbonsArgs = {
  where: AdhesiveRibbons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AdhesiveRibbons_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AdhesiveSpotsArgs = {
  where: AdhesiveSpots_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AdhesiveSpotsUsageArgs = {
  where: AdhesiveSpotsUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AdhesiveSpotsUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AdhesiveSpots_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AdhesiveSystemUsageArgs = {
  where: AdhesiveSystemUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AdhesiveSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AirRetarderArgs = {
  where: AirRetarder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AirRetarderUsageArgs = {
  where: AirRetarderUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AirRetarderUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AirRetarder_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AllManufacturedProductsArgs = {
  where: AllManufacturedProducts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AnchorArgs = {
  where: Anchor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Anchor_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ApprovalsDocArgs = {
  where: ApprovalsDoc_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ApprovalsDoc_By_PkArgs = {
  ApprovalsDocId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ApprovedLayerUseArgs = {
  where: ApprovedLayerUse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ApprovedLayerUse_By_PkArgs = {
  ApprovedLayerUseId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AssemblyReconfigQueueArgs = {
  where: AssemblyReconfigQueue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AssemblyReconfigQueue_By_PkArgs = {
  AssemblyReconfigQueueId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BaseCoatArgs = {
  where: BaseCoat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BaseCoatUsageArgs = {
  where: BaseCoatUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BaseCoatUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BaseCoat_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BasePlyArgs = {
  where: BasePly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BasePlyUsageArgs = {
  where: BasePlyUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BasePlyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BasePly_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BaseSheetArgs = {
  where: BaseSheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BaseSheetUsageArgs = {
  where: BaseSheetUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BaseSheetUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BaseSheet_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BattBlanketInsulationArgs = {
  where: BattBlanketInsulation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BattBlanketInsulation_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BattBlanketUsageArgs = {
  where: BattBlanketUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BattBlanketUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BattenBarArgs = {
  where: BattenBar_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BattenBarUsageArgs = {
  where: BattenBarUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BattenBarUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BattenBar_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BoardSizesArgs = {
  where: BoardSizes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BoardStockArgs = {
  where: BoardStock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BoardStockBoardSizesArgs = {
  where: BoardStockBoardSizes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BoardStockBoardSizes_By_PkArgs = {
  ComponentId: Scalars['Int'];
  InsBoardSizeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BoardStockFacerBottomArgs = {
  where: BoardStockFacerBottom_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BoardStockFacerBottom_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BoardStockFacerTopArgs = {
  where: BoardStockFacerTop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BoardStockFacerTop_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BoardStockUsageArgs = {
  where: BoardStockUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BoardStockUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BoardStock_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BroadcastAlertArgs = {
  where: BroadcastAlert_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BroadcastAlert_By_PkArgs = {
  BroadcastAlertId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BuiltupVaporRetarderSystemUsageArgs = {
  where: BuiltupVaporRetarderSystemUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_BuiltupVaporRetarderSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_AdditiveArgs = {
  where: C_Additive_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_Additive_By_PkArgs = {
  AdditiveId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_AdhesiveApplicationMethodArgs = {
  where: C_AdhesiveApplicationMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_AdhesiveApplicationMethod_By_PkArgs = {
  AdhesiveApplicationMethodId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_AdhesiveMaterialArgs = {
  where: C_AdhesiveMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_AdhesiveMaterial_By_PkArgs = {
  AdhesiveMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_AdhesiveTypeArgs = {
  where: C_AdhesiveType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_AdhesiveType_By_PkArgs = {
  AdhesiveTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_AggregateArgs = {
  where: C_Aggregate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_Aggregate_By_PkArgs = {
  AggregateId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ApprovalsDocCategoryArgs = {
  where: C_ApprovalsDocCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ApprovalsDocCategory_By_PkArgs = {
  ApprovalsDocCategoryId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ApprovedUseArgs = {
  where: C_ApprovedUse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ApprovedUse_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_AssemblyApplicationTypeArgs = {
  where: C_AssemblyApplicationType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_AssemblyApplicationType_By_PkArgs = {
  AssemblyApplicationTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_AssemblyTypeArgs = {
  where: C_AssemblyType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_AssemblyType_By_PkArgs = {
  AssemblyTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_BackingMaterialArgs = {
  where: C_BackingMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_BackingMaterial_By_PkArgs = {
  BackingMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_BaseSheetApplicationMethodArgs = {
  where: C_BaseSheetApplicationMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_BaseSheetApplicationMethod_By_PkArgs = {
  BaseSheetApplicationMethodId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_BattBlanketFacerMaterialArgs = {
  where: C_BattBlanketFacerMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_BattBlanketFacerMaterial_By_PkArgs = {
  BattBlanketFacerMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_BattBlanketMaterialArgs = {
  where: C_BattBlanketMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_BattBlanketMaterial_By_PkArgs = {
  BattBlanketMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_BoardProfileArgs = {
  where: C_BoardProfile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_BoardProfile_By_PkArgs = {
  BoardProfileId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_BoardStockMaterialArgs = {
  where: C_BoardStockMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_BoardStockMaterial_By_PkArgs = {
  BoardStockMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_BuildingEnclosureArgs = {
  where: C_BuildingEnclosure_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_BuildingEnclosure_By_PkArgs = {
  BuildingEnclosureId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_CementAggregateRatioArgs = {
  where: C_CementAggregateRatio_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_CementAggregateRatio_By_PkArgs = {
  CementAggregateRatioId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_CoatingMaterialArgs = {
  where: C_CoatingMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_CoatingMaterial_By_PkArgs = {
  CoatingMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ComponentTypeArgs = {
  where: C_ComponentType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ComponentType_By_PkArgs = {
  ComponentTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ContactTypeArgs = {
  where: C_ContactType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ContactType_By_PkArgs = {
  ContactTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_CountryArgs = {
  where: C_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_Country_By_PkArgs = {
  CountryId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_CoverBoardMaterialArgs = {
  where: C_CoverBoardMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_CoverBoardMaterial_By_PkArgs = {
  CoverBoardMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_CoverColorArgs = {
  where: C_CoverColor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_CoverColor_By_PkArgs = {
  CoverColorId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_CoverSecurementMethodArgs = {
  where: C_CoverSecurementMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_CoverSecurementMethod_By_PkArgs = {
  CoverSecurementMethodId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_DeckTypeArgs = {
  where: C_DeckType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_DeckType_By_PkArgs = {
  DeckTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ExistingRoofCoverArgs = {
  where: C_ExistingRoofCover_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ExistingRoofCover_By_PkArgs = {
  ExistingRoofCoverId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ExistingRoofDeckArgs = {
  where: C_ExistingRoofDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ExistingRoofDeck_By_PkArgs = {
  ExistingRoofDeckId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ExistingRoofInsulationMaterialArgs = {
  where: C_ExistingRoofInsulationMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ExistingRoofInsulationMaterial_By_PkArgs = {
  ExistingRoofInsulationMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ExistingRoofSinglePlyMaterialArgs = {
  where: C_ExistingRoofSinglePlyMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ExistingRoofSinglePlyMaterial_By_PkArgs = {
  ExistingRoofSinglePlyMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ExistingRoofSurfaceArgs = {
  where: C_ExistingRoofSurface_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ExistingRoofSurface_By_PkArgs = {
  ExistingRoofSurfaceId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ExtFireRatingArgs = {
  where: C_ExtFireRating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ExtFireRating_By_PkArgs = {
  ExtFireRatingId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_FacerArgs = {
  where: C_Facer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_FacerMaterialArgs = {
  where: C_FacerMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_FacerMaterial_By_PkArgs = {
  FacerMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_Facer_By_PkArgs = {
  FacerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_FastenerCoatingArgs = {
  where: C_FastenerCoating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_FastenerCoating_By_PkArgs = {
  FastenerCoatingId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_FastenerDriveHeadArgs = {
  where: C_FastenerDriveHead_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_FastenerDriveHead_By_PkArgs = {
  FastenerDriveHeadId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_FastenerTypeArgs = {
  where: C_FastenerType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_FastenerType_By_PkArgs = {
  FastenerTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_FasteningApplicationMethodArgs = {
  where: C_FasteningApplicationMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_FasteningApplicationMethod_By_PkArgs = {
  FasteningApplicationMethodId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_FlashingMaterialArgs = {
  where: C_FlashingMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_FlashingMaterial_By_PkArgs = {
  FlashingMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_FlashingTypeArgs = {
  where: C_FlashingType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_FlashingType_By_PkArgs = {
  FlashingTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_FormDeckMaterialArgs = {
  where: C_FormDeckMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_FormDeckMaterial_By_PkArgs = {
  FormDeckMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_GroundRoughnessRatingArgs = {
  where: C_GroundRoughnessRating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_GroundRoughnessRating_By_PkArgs = {
  GroundRoughnessRatingId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_GutterMaterialArgs = {
  where: C_GutterMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_GutterMaterial_By_PkArgs = {
  GutterMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_HailRatingArgs = {
  where: C_HailRating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_HailRating_By_PkArgs = {
  HailRatingId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_IndustryTypeArgs = {
  where: C_IndustryType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_IndustryType_By_PkArgs = {
  IndustryTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_InsulationCoreArgs = {
  where: C_InsulationCore_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_InsulationCore_By_PkArgs = {
  InsulationCoreId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_InsulationFoamTypeArgs = {
  where: C_InsulationFoamType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_InsulationFoamType_By_PkArgs = {
  InsulationFoamTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_IntFireRatingArgs = {
  where: C_IntFireRating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_IntFireRating_By_PkArgs = {
  IntFireRatingId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_LapSealantTypeArgs = {
  where: C_LapSealantType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_LapSealantType_By_PkArgs = {
  LapSealantTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_LayerTypeArgs = {
  where: C_LayerType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_LayerType_By_PkArgs = {
  LayerTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_LinerPanelMaterialArgs = {
  where: C_LinerPanelMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_LinerPanelMaterial_By_PkArgs = {
  LinerPanelMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_LiquidApplicationArgs = {
  where: C_LiquidApplication_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_LiquidApplication_By_PkArgs = {
  LiquidApplicationId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_LiquidAppliedMaterialArgs = {
  where: C_LiquidAppliedMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_LiquidAppliedMaterial_By_PkArgs = {
  LiquidAppliedMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_LockStatusArgs = {
  where: C_LockStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_LockStatus_By_PkArgs = {
  LockStatusId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_MdAttributeTypeArgs = {
  where: C_MdAttributeType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_MdAttributeType_By_PkArgs = {
  MDAttributeTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_MaintenanceCoatingMaterialArgs = {
  where: C_MaintenanceCoatingMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_MaintenanceCoatingMaterial_By_PkArgs = {
  MaintenanceCoatingMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_MaintenanceCoatingSubstrateArgs = {
  where: C_MaintenanceCoatingSubstrate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_MaintenanceCoatingSubstrate_By_PkArgs = {
  MaintenanceCoatingSubstrateId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_MembraneTerminationArgs = {
  where: C_MembraneTermination_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_MembraneTermination_By_PkArgs = {
  MembraneTerminationId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_MultiplyMaterialArgs = {
  where: C_MultiplyMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_MultiplyMaterial_By_PkArgs = {
  MultiplyMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_PvApplicationMethodArgs = {
  where: C_PvApplicationMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_PvApplicationMethod_By_PkArgs = {
  PVApplicationMethodId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_PvHailRatingArgs = {
  where: C_PvHailRating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_PvHailRating_By_PkArgs = {
  PVHailRatingId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_PerimeterFlashingTypeArgs = {
  where: C_PerimeterFlashingType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_PerimeterFlashingType_By_PkArgs = {
  PerimeterFlashingTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_PressureZoneArgs = {
  where: C_PressureZone_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_PressureZone_By_PkArgs = {
  PressureZoneId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ProductApprovedUseArgs = {
  where: C_ProductApprovedUse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ProjectRoleArgs = {
  where: C_ProjectRole_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ProjectRole_By_PkArgs = {
  ProjectRoleId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ReinforcementArgs = {
  where: C_Reinforcement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_Reinforcement_By_PkArgs = {
  ReinforcementId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_RetarderMaterialArgs = {
  where: C_RetarderMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_RetarderMaterial_By_PkArgs = {
  RetarderMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ScenarioStatusArgs = {
  where: C_ScenarioStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ScenarioStatus_By_PkArgs = {
  ScenarioStatusId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ScenarioTypeArgs = {
  where: C_ScenarioType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ScenarioType_By_PkArgs = {
  ScenarioTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_SeamPrimerTypeArgs = {
  where: C_SeamPrimerType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_SeamPrimerType_By_PkArgs = {
  SeamPrimerTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_SeamWashTypeArgs = {
  where: C_SeamWashType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_SeamWashType_By_PkArgs = {
  SeamWashTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_SecurementMaterialArgs = {
  where: C_SecurementMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_SecurementMaterial_By_PkArgs = {
  SecurementMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ShinglesHailArgs = {
  where: C_ShinglesHail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ShinglesHail_By_PkArgs = {
  ShinglesHailId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ShinglesMaterialArgs = {
  where: C_ShinglesMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ShinglesMaterial_By_PkArgs = {
  ShinglesMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ShinglesReinforcementArgs = {
  where: C_ShinglesReinforcement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ShinglesReinforcement_By_PkArgs = {
  ShinglesReinforcementId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ShinglesSurfacingArgs = {
  where: C_ShinglesSurfacing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ShinglesSurfacing_By_PkArgs = {
  ShinglesSurfacingId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_ShinglesWindSpeedArgs = {
  where: C_ShinglesWindSpeed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ShinglesWindSpeed_By_PkArgs = {
  ShinglesWindSpeedId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_SinglePlyMaterialArgs = {
  where: C_SinglePlyMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_SinglePlyMaterial_By_PkArgs = {
  SinglePlyMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_StandingLapSeamMaterialArgs = {
  where: C_StandingLapSeamMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_StandingLapSeamMaterial_By_PkArgs = {
  StandingLapSeamMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_StateProvinceArgs = {
  where: C_StateProvince_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_StateProvince_By_PkArgs = {
  StateProvinceId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_StatusArgs = {
  where: C_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_Status_By_PkArgs = {
  StatusId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_SteelDeckRibTypeArgs = {
  where: C_SteelDeckRibType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_SteelDeckRibType_By_PkArgs = {
  SteelDeckRibTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_StressDistributorTypeArgs = {
  where: C_StressDistributorType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_StressDistributorType_By_PkArgs = {
  StressDistributorTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_StressPlateShapeArgs = {
  where: C_StressPlateShape_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_StressPlateShape_By_PkArgs = {
  StressPlateShapeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_StructureTypeArgs = {
  where: C_StructureType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_StructureType_By_PkArgs = {
  StructureTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_SubassemblyTypeArgs = {
  where: C_SubassemblyType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_SubassemblyType_By_PkArgs = {
  SubassemblyTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_SubstrateArgs = {
  where: C_Substrate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_SubstratePrimerMaterialArgs = {
  where: C_SubstratePrimerMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_SubstratePrimerMaterial_By_PkArgs = {
  SubstratePrimerMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_Substrate_By_PkArgs = {
  SubstrateId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_SurfaceTypeArgs = {
  where: C_SurfaceType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_SurfaceType_By_PkArgs = {
  SurfaceTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_SurfacingMaterialArgs = {
  where: C_SurfacingMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_SurfacingMaterial_By_PkArgs = {
  SurfacingMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_SystemApprovedUseArgs = {
  where: C_SystemApprovedUse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ThermalBarrierMaterialArgs = {
  where: C_ThermalBarrierMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_ThermalBarrierMaterial_By_PkArgs = {
  ThermalBarrierMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_WipDelistReasonArgs = {
  where: C_WipDelistReason_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_WipDelistReason_By_PkArgs = {
  WIPDelistReasonId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_WipOnHoldReasonArgs = {
  where: C_WipOnHoldReason_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_WipOnHoldReason_By_PkArgs = {
  WIPOnHoldReasonId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_WipStatusArgs = {
  where: C_WipStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_WipStatus_By_PkArgs = {
  WIPStatusId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_WeldApplicationArgs = {
  where: C_WeldApplication_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_WeldApplication_By_PkArgs = {
  WeldApplicationId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_WeldConfigurationArgs = {
  where: C_WeldConfiguration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_WeldConfiguration_By_PkArgs = {
  WeldConfigurationId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_WindBorneDebrisArgs = {
  where: C_WindBorneDebris_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_WindBorneDebris_By_PkArgs = {
  WindBorneDebrisId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_WoodSpeciesArgs = {
  where: C_WoodSpecies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_WoodSpecies_By_PkArgs = {
  WoodSpeciesId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_WoodTypeArgs = {
  where: C_WoodType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_WoodType_By_PkArgs = {
  WoodTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_C_WorkflowStatusArgs = {
  where: C_WorkflowStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_C_WorkflowStatus_By_PkArgs = {
  WorkflowStatusId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CapPlyArgs = {
  where: CapPly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CapPlyUsageArgs = {
  where: CapPlyUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CapPlyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CapPly_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CategoryArgs = {
  where: Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CategoryLogArgs = {
  where: CategoryLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CategoryLog_By_PkArgs = {
  CategoryLogID: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Category_CoatingArgs = {
  where: Category_Coating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_CoverArgs = {
  where: Category_Cover_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_DeckArgs = {
  where: Category_Deck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_FlashingArgs = {
  where: Category_Flashing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_InsulationArgs = {
  where: Category_Insulation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_OtherArgs = {
  where: Category_Other_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_SeamingArgs = {
  where: Category_Seaming_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_SecurementArgs = {
  where: Category_Securement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_SheetArgs = {
  where: Category_Sheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_By_PkArgs = {
  CategoryID: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CementitiousWoodFiberDeckArgs = {
  where: CementitiousWoodFiberDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CementitiousWoodFiberDeckUsageArgs = {
  where: CementitiousWoodFiberDeckUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CementitiousWoodFiberDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CementitiousWoodFiberDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ClipArgs = {
  where: Clip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ClipUsageArgs = {
  where: ClipUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ClipUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Clip_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CoatArgs = {
  where: Coat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CoatUsageArgs = {
  where: CoatUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CoatUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Coat_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CoatingArgs = {
  where: Coating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CoatingSystemUsageArgs = {
  where: CoatingSystemUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CoatingSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CoatingUsageArgs = {
  where: CoatingUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CoatingUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Coating_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ComponentArgs = {
  where: Component_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ComponentApprovedUseArgs = {
  where: ComponentApprovedUse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ComponentApprovedUse_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ComponentDocArgs = {
  where: ComponentDoc_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ComponentDoc_By_PkArgs = {
  ComponentDocId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ComponentLayerArgs = {
  where: ComponentLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ComponentLayerUsageArgs = {
  where: ComponentLayerUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ComponentLayerUsage_By_PkArgs = {
  LayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ComponentLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Component_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CompositePanelCoverArgs = {
  where: CompositePanelCover_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompositePanelCover_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CompositePanelUsageArgs = {
  where: CompositePanelUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CompositePanelUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ContactInfoArgs = {
  where: ContactInfo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContactInfo_By_PkArgs = {
  ContactInfoId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CopingArgs = {
  where: Coping_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Coping_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CoverBoardArgs = {
  where: CoverBoard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CoverBoardBoardSizesArgs = {
  where: CoverBoardBoardSizes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CoverBoardBoardSizes_By_PkArgs = {
  ComponentId: Scalars['Int'];
  InsBoardSizeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CoverBoardFacerBottomArgs = {
  where: CoverBoardFacerBottom_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CoverBoardFacerBottom_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CoverBoardFacerTopArgs = {
  where: CoverBoardFacerTop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CoverBoardFacerTop_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CoverBoardUsageArgs = {
  where: CoverBoardUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CoverBoardUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CoverBoard_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CoverStripDiskArgs = {
  where: CoverStripDisk_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CoverStripDiskUsageArgs = {
  where: CoverStripDiskUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CoverStripDiskUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CoverStripDisk_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ExistingRoofLayerArgs = {
  where: ExistingRoofLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExistingRoofLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ExpansionJointArgs = {
  where: ExpansionJoint_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExpansionJointUsageArgs = {
  where: ExpansionJointUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExpansionJointUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ExpansionJoint_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ExtendedLayerConfigSplitArgs = {
  where: ExtendedLayerConfigSplit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ExtendedLayerConfigSplit_By_PkArgs = {
  ExtendedLayerConfigId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FmUserAccountArgs = {
  where: FmUserAccount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FmUserAccount_By_PkArgs = {
  UserAccountId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FasciaArgs = {
  where: Fascia_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fascia_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FastenerArgs = {
  where: Fastener_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FastenerUsageArgs = {
  where: FastenerUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FastenerUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Fastener_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FasteningSystemBattenBarUsageArgs = {
  where: FasteningSystemBattenBarUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FasteningSystemBattenBarUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FasteningSystemClipUsageArgs = {
  where: FasteningSystemClipUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FasteningSystemClipUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FasteningSystemStressPlateUsageArgs = {
  where: FasteningSystemStressPlateUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FasteningSystemStressPlateUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FiberReinforcedPlasticDeckArgs = {
  where: FiberReinforcedPlasticDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FiberReinforcedPlasticDeckUsageArgs = {
  where: FiberReinforcedPlasticDeckUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FiberReinforcedPlasticDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FiberReinforcedPlasticDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FormDeckArgs = {
  where: FormDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FormDeckUsageArgs = {
  where: FormDeckUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FormDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FormDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_GutterArgs = {
  where: Gutter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gutter_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_GypsumDeckArgs = {
  where: GypsumDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GypsumDeckUsageArgs = {
  where: GypsumDeckUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GypsumDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_GypsumDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_InsBoardSizeArgs = {
  where: InsBoardSize_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InsBoardSize_By_PkArgs = {
  InsBoardSizeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ItemWorkspaceArgs = {
  where: ItemWorkspace_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ItemWorkspaceDataArgs = {
  where: ItemWorkspaceData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ItemWorkspaceData_By_PkArgs = {
  ItemWorkspaceDataId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ItemWorkspace_By_PkArgs = {
  ItemWorkspaceId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LapSealantArgs = {
  where: LapSealant_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LapSealantUsageArgs = {
  where: LapSealantUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LapSealantUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LapSealant_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LapSecurementInfoArgs = {
  where: LapSecurementInfo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LapSecurementInfo_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LayerArgs = {
  where: Layer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LayerConfigArgs = {
  where: LayerConfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LayerConfig_By_PkArgs = {
  LayerConfigId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Layer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LightweightInsulatingConcreteArgs = {
  where: LightweightInsulatingConcrete_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LightweightInsulatingConcreteSubassemblyUsageArgs = {
  where: LightweightInsulatingConcreteSubassemblyUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LightweightInsulatingConcreteSubassemblyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LightweightInsulatingConcreteUsageArgs = {
  where: LightweightInsulatingConcreteUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LightweightInsulatingConcreteUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LightweightInsulatingConcrete_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LinerPanelArgs = {
  where: LinerPanel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LinerPanelUsageArgs = {
  where: LinerPanelUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LinerPanelUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LinerPanel_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LiquidAppliedSubassemblyUsageArgs = {
  where: LiquidAppliedSubassemblyUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LiquidAppliedSubassemblyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LiquidAppliedUsageArgs = {
  where: LiquidAppliedUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LiquidAppliedUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Load_SearchLayerUsage_DeckFiberReinforcedPlasticArgs = {
  where: Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_ApprovedUseArgs = {
  where: Md_ApprovedUse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_ApprovedUseAttributesArgs = {
  where: Md_ApprovedUseAttributes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_ApprovedUseAttributes_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  AttributeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_ApprovedUse_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_AttributeBaseArgs = {
  where: Md_AttributeBase_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_AttributeBase_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_BooleanAttributeArgs = {
  where: Md_BooleanAttribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_BooleanAttribute_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_BooleanDisplayOptionArgs = {
  where: Md_BooleanDisplayOption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_BooleanDisplayOption_By_PkArgs = {
  BooleanDisplayOptionId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_CatalogArgs = {
  where: Md_Catalog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_Catalog_By_PkArgs = {
  MetadataCatalogId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_CodeTableAttributeArgs = {
  where: Md_CodeTableAttribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_CodeTableAttribute_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_CodeTablesArgs = {
  where: Md_CodeTables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_CodeTables_By_PkArgs = {
  CodeTableId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_ComponentUsageArgs = {
  where: Md_ComponentUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_ComponentUsageAttributesArgs = {
  where: Md_ComponentUsageAttributes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_ComponentUsageAttributes_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  AttributeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_ComponentUsage_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_MeasureArgs = {
  where: Md_Measure_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_Measure_By_PkArgs = {
  MeasureId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_MeasurementAttributeArgs = {
  where: Md_MeasurementAttribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_MeasurementAttribute_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_MeasurementDisplayOptionArgs = {
  where: Md_MeasurementDisplayOption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_MeasurementDisplayOption_By_PkArgs = {
  MeasurementDisplayOptionId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_MultivalueAttributeArgs = {
  where: Md_MultivalueAttribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_MultivalueAttribute_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_MultivalueDisplayOptionArgs = {
  where: Md_MultivalueDisplayOption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_MultivalueDisplayOption_By_PkArgs = {
  MultivalueDisplayOptionId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_ProductCategoryArgs = {
  where: Md_ProductCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_ProductCategory_By_PkArgs = {
  CategoryId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Md_ProductSubcategoryArgs = {
  where: Md_ProductSubcategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Md_ProductSubcategory_By_PkArgs = {
  SubcategoryId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MaintenanceCoatingArgs = {
  where: MaintenanceCoating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MaintenanceCoatingSubstrateArgs = {
  where: MaintenanceCoatingSubstrate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MaintenanceCoatingSubstrate_By_PkArgs = {
  ComponentId: Scalars['Int'];
  MaintenanceCoatingSubstrateId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MaintenanceCoatingUsageArgs = {
  where: MaintenanceCoatingUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MaintenanceCoatingUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MaintenanceCoating_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ManufacturedProductArgs = {
  where: ManufacturedProduct_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ManufacturedProduct_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ManufacturerArgs = {
  where: Manufacturer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ManufacturerChangeHistoryArgs = {
  where: ManufacturerChangeHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ManufacturerChangeHistory_By_PkArgs = {
  ChangeHistoryId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ManufacturerListArgs = {
  where: ManufacturerList_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Manufacturer_By_PkArgs = {
  ManufacturerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MultiplyCoverConfigArgs = {
  where: MultiplyCoverConfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MultiplyCoverConfig_By_PkArgs = {
  MultiplyCoverConfigId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MultiplyCoverSubassemblyUsageArgs = {
  where: MultiplyCoverSubassemblyUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MultiplyCoverSubassemblyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_NavAssemblyArgs = {
  where: NavAssembly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_NavAssemblyMultiplyInfoArgs = {
  where: NavAssemblyMultiplyInfo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_NavAssembly_By_PkArgs = {
  NavAssemblyId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_NavLayerArgs = {
  where: NavLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_NavLayer_By_PkArgs = {
  NavLayerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_NavMultiplyCoverConfigArgs = {
  where: NavMultiplyCoverConfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_NavMultiplyCoverConfig_By_PkArgs = {
  NavMultiplyCoverConfigId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_NavSecurementLayerArgs = {
  where: NavSecurementLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_NavSecurementLayer_By_PkArgs = {
  NavLayerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_NavSecurementUsageArgs = {
  where: NavSecurementUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_NavSecurementUsage_By_PkArgs = {
  NavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_NavUsageArgs = {
  where: NavUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_NavUsageSaArgs = {
  where: NavUsageSa_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_NavUsageSa_By_PkArgs = {
  NavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_NavUsage_By_PkArgs = {
  NavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Nav_SinglePlyBondingAdhesivesArgs = {
  where: Nav_SinglePlyBondingAdhesives_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nav_SinglePlyLapAdhesivesArgs = {
  where: Nav_SinglePlyLapAdhesives_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_NextAssemblyNumArgs = {
  where: NextAssemblyNum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_NextAssemblyNum_By_PkArgs = {
  AssemblyNum: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_NoteArgs = {
  where: Note_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Note_By_PkArgs = {
  NoteId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_OtherPlyArgs = {
  where: OtherPly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OtherPlyUsageArgs = {
  where: OtherPlyUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OtherPlyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_OtherPly_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PreassembledFasteningArgs = {
  where: PreassembledFastening_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PreassembledFasteningUsageArgs = {
  where: PreassembledFasteningUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PreassembledFasteningUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PreassembledFastening_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PrivateLabelArgs = {
  where: PrivateLabel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PrivateLabel_By_PkArgs = {
  ChildComponentId: Scalars['Int'];
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PrivateLabelerArgs = {
  where: PrivateLabeler_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductDependenciesArgs = {
  where: ProductDependencies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductDependencies_By_PkArgs = {
  Component1Id: Scalars['Int'];
  Component2Id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProductsBySubcategoryArgs = {
  where: ProductsBySubcategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_AdhesiveTapeWidthArgs = {
  where: R_AdhesiveTapeWidth_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_AdhesiveTapeWidth_By_PkArgs = {
  ComponentId: Scalars['Int'];
  TapeWidthId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_ApprovedUse_SubcategoryArgs = {
  where: R_ApprovedUse_Subcategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_ApprovedUse_Subcategory_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  SubcategoryId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_ComponentType_ApprovedUseArgs = {
  where: R_ComponentType_ApprovedUse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_ComponentType_ApprovedUse_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  ComponentTypeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_CopingFastenerArgs = {
  where: R_CopingFastener_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_CopingFastener_By_PkArgs = {
  CopingComponentId: Scalars['Int'];
  FastenerComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_Country_StateProvinceArgs = {
  where: R_Country_StateProvince_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_Country_StateProvince_By_PkArgs = {
  CountryId: Scalars['Int'];
  StateProvinceId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_ExpansionJoinFastenerArgs = {
  where: R_ExpansionJoinFastener_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_ExpansionJoinFastener_By_PkArgs = {
  ExpansionJointComponentId: Scalars['Int'];
  FastenerComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_FasciaComponentFastenerComponentArgs = {
  where: R_FasciaComponentFastenerComponent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_FasciaComponentFastenerComponent_By_PkArgs = {
  FasciaComponentId: Scalars['Int'];
  FastenerComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_GutterFastenersArgs = {
  where: R_GutterFasteners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_GutterFasteners_By_PkArgs = {
  FastenerId: Scalars['Int'];
  GutterId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_LinerPanelMaterialFiberReinforcedPlasticDeckArgs = {
  where: R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_LinerPanelMaterialFiberReinforcedPlasticDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
  LinerPanelMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_MaintenanceCoatingSubstrateArgs = {
  where: R_MaintenanceCoatingSubstrate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_MaintenanceCoatingSubstrate_By_PkArgs = {
  ComponentId: Scalars['Int'];
  MaintenanceCoatingSubstrateId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_NavAssembly_NavMultiplyCoverConfigArgs = {
  where: R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_NavAssembly_NavMultiplyCoverConfig_By_PkArgs = {
  NavAssemblyId: Scalars['Int'];
  NavMultiplyCoverConfigId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_ProjectContactArgs = {
  where: R_ProjectContact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_ProjectContact_By_PkArgs = {
  ContactInfoId: Scalars['Int'];
  ProjectId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_ProjectNoteArgs = {
  where: R_ProjectNote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_ProjectNote_By_PkArgs = {
  NoteId: Scalars['Int'];
  ProjectId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_RegletFastenerArgs = {
  where: R_RegletFastener_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_RegletFastener_By_PkArgs = {
  FastenerComponentId: Scalars['Int'];
  RegletComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_RoofAreaContactArgs = {
  where: R_RoofAreaContact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_RoofAreaContact_By_PkArgs = {
  ContactInfoId: Scalars['Int'];
  ProjectRoleId: Scalars['Int'];
  RoofAreaId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_RoofAreaNoteArgs = {
  where: R_RoofAreaNote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_RoofAreaNote_By_PkArgs = {
  NoteId: Scalars['Int'];
  RoofAreaId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_RoofAreaScenarioArgs = {
  where: R_RoofAreaScenario_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_RoofAreaScenario_By_PkArgs = {
  RoofAreaId: Scalars['Int'];
  ScenarioId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_ScenarioNoteArgs = {
  where: R_ScenarioNote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_ScenarioNote_By_PkArgs = {
  NoteId: Scalars['Int'];
  ScenarioId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_SinglePly_BondingAdhesivesArgs = {
  where: R_SinglePly_BondingAdhesives_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_SinglePly_BondingAdhesives_By_PkArgs = {
  AdhesiveComponentId: Scalars['Int'];
  SinglePlyComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_SinglePly_LapAdhesivesArgs = {
  where: R_SinglePly_LapAdhesives_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_SinglePly_LapAdhesives_By_PkArgs = {
  AdhesiveComponentId: Scalars['Int'];
  SinglePlyComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_Subassembly_MultiplyCoverConfigArgs = {
  where: R_Subassembly_MultiplyCoverConfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_Subassembly_MultiplyCoverConfig_By_PkArgs = {
  MultiplyCoverConfigId: Scalars['Int'];
  SubassemblyComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_R_UserContactArgs = {
  where: R_UserContact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_R_UserContact_By_PkArgs = {
  ContactInfoId: Scalars['Int'];
  UserAccountId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RegletArgs = {
  where: Reglet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reglet_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewerWorkspaceArgs = {
  where: ReviewerWorkspace_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReviewerWorkspaceDataArgs = {
  where: ReviewerWorkspaceData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReviewerWorkspaceData_By_PkArgs = {
  ReviewerWorkspaceDataId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewerWorkspace_By_PkArgs = {
  ReviewerWorkspaceId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RoofAreaArgs = {
  where: RoofArea_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RoofAreaWindPressureArgs = {
  where: RoofAreaWindPressure_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RoofAreaWindPressure_By_PkArgs = {
  RoofAreaWindPressureId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RoofArea_By_PkArgs = {
  RoofAreaId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RoofAssemblyArgs = {
  where: RoofAssembly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RoofAssemblyDelistDataArgs = {
  where: RoofAssemblyDelistData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RoofAssemblyLayersArgs = {
  where: RoofAssemblyLayers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RoofAssemblyLayers_By_PkArgs = {
  AssemblyId: Scalars['Int'];
  LayerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RoofAssembly_By_PkArgs = {
  AssemblyId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RoofProjectArgs = {
  where: RoofProject_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RoofProject_By_PkArgs = {
  ProjectId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ScenarioArgs = {
  where: Scenario_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ScenarioAlertArgs = {
  where: ScenarioAlert_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ScenarioAlert_By_PkArgs = {
  ScenarioAlertId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ScenarioLayerArgs = {
  where: ScenarioLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ScenarioLayer_By_PkArgs = {
  ScenarioLayerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ScenarioUsageArgs = {
  where: ScenarioUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ScenarioUsage_By_PkArgs = {
  ScenarioUsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Scenario_By_PkArgs = {
  ScenarioId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SeamPrimerArgs = {
  where: SeamPrimer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SeamPrimerUsageArgs = {
  where: SeamPrimerUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SeamPrimerUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SeamPrimer_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SeamTapeSystemUsageArgs = {
  where: SeamTapeSystemUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SeamTapeSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SeamWashArgs = {
  where: SeamWash_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SeamWashUsageArgs = {
  where: SeamWashUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SeamWashUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SeamWash_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SearchNavAssembly_ManufacturerProductArgs = {
  where: SearchNavAssembly_ManufacturerProduct_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SearchNavAssembly_ManufacturerProduct_By_PkArgs = {
  ComponentId: Scalars['Int'];
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SecurementLayerArgs = {
  where: SecurementLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SecurementLayerUsageArgs = {
  where: SecurementLayerUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SecurementLayerUsage_By_PkArgs = {
  LayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SecurementLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SelfSecurementUsageArgs = {
  where: SelfSecurementUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SelfSecurementUsage_By_PkArgs = {
  LayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SeparatorSheetArgs = {
  where: SeparatorSheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SeparatorSheetUsageArgs = {
  where: SeparatorSheetUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SeparatorSheetUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SeparatorSheet_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ShinglesArgs = {
  where: Shingles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Shingles_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SinglePlyCoverArgs = {
  where: SinglePlyCover_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SinglePlyCover_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SinglePlyUsageArgs = {
  where: SinglePlyUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SinglePlyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SprayFoamInsulationUsageArgs = {
  where: SprayFoamInsulationUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SprayFoamInsulationUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SprayedFoamInsulationArgs = {
  where: SprayedFoamInsulation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SprayedFoamInsulation_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StandingLapSeamCoverArgs = {
  where: StandingLapSeamCover_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StandingLapSeamCover_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StandingLapSeamUsageArgs = {
  where: StandingLapSeamUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StandingLapSeamUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SteelDeckArgs = {
  where: SteelDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SteelDeckLayerArgs = {
  where: SteelDeckLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SteelDeckLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SteelDeckUsageArgs = {
  where: SteelDeckUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SteelDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SteelDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StressPlateArgs = {
  where: StressPlate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StressPlateUsageArgs = {
  where: StressPlateUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StressPlateUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StressPlate_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StructuralConcreteLayerArgs = {
  where: StructuralConcreteLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StructuralConcreteLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StructureLayerArgs = {
  where: StructureLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StructureLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StructuredSystemArgs = {
  where: StructuredSystem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StructuredSystemProductsArgs = {
  where: StructuredSystemProducts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_StructuredSystemProducts_By_PkArgs = {
  ComponentId: Scalars['Int'];
  SystemId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StructuredSystem_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SubassemblyArgs = {
  where: Subassembly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SubassemblyDelistDataArgs = {
  where: SubassemblyDelistData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SubassemblyE108RatingArgs = {
  where: SubassemblyE108Rating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SubassemblyE108Rating_By_PkArgs = {
  SubassemblyComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SubassemblyLayersArgs = {
  where: SubassemblyLayers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SubassemblyLayers_By_PkArgs = {
  LayerId: Scalars['Int'];
  SubassemblyComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SubassemblyMultiplyConfigArgs = {
  where: SubassemblyMultiplyConfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SubassemblyMultiplyConfig_By_PkArgs = {
  SubassemblyComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Subassembly_By_PkArgs = {
  SubassemblyComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Subcat_AdhesiveArgs = {
  where: Subcat_Adhesive_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_AirRetarderArgs = {
  where: Subcat_AirRetarder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_BaseSheetArgs = {
  where: Subcat_BaseSheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_BattBlanketArgs = {
  where: Subcat_BattBlanket_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_BoardStockArgs = {
  where: Subcat_BoardStock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_CoatingArgs = {
  where: Subcat_Coating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_CompositePanelArgs = {
  where: Subcat_CompositePanel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_CoverStripArgs = {
  where: Subcat_CoverStrip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_ExpJointArgs = {
  where: Subcat_ExpJoint_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_FasteningArgs = {
  where: Subcat_Fastening_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_FormDeckArgs = {
  where: Subcat_FormDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_GutterArgs = {
  where: Subcat_Gutter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_GypsumArgs = {
  where: Subcat_Gypsum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_LwicArgs = {
  where: Subcat_Lwic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_LwicDeckArgs = {
  where: Subcat_LwicDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_LinerPanelArgs = {
  where: Subcat_LinerPanel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_LiquidAppliedArgs = {
  where: Subcat_LiquidApplied_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_MaintCoatingArgs = {
  where: Subcat_MaintCoating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_MultiplyArgs = {
  where: Subcat_Multiply_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_MultiplyCoverArgs = {
  where: Subcat_MultiplyCover_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_PerimeterFlashingArgs = {
  where: Subcat_PerimeterFlashing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_PlasticDeckArgs = {
  where: Subcat_PlasticDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_PrimerArgs = {
  where: Subcat_Primer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_SeamingArgs = {
  where: Subcat_Seaming_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_SeparatorSheetArgs = {
  where: Subcat_SeparatorSheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_ShinglesArgs = {
  where: Subcat_Shingles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_SinglePlyArgs = {
  where: Subcat_SinglePly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_SinglePlyCoverArgs = {
  where: Subcat_SinglePlyCover_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_SprayFoamArgs = {
  where: Subcat_SprayFoam_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_StandingLapSeamArgs = {
  where: Subcat_StandingLapSeam_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_SteelDeckArgs = {
  where: Subcat_SteelDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_SurfacingArgs = {
  where: Subcat_Surfacing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_VaporRetarderArgs = {
  where: Subcat_VaporRetarder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_WeldArgs = {
  where: Subcat_Weld_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_WoodArgs = {
  where: Subcat_Wood_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subcat_WoodFiberDeckArgs = {
  where: Subcat_WoodFiberDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SubstratePrimerArgs = {
  where: SubstratePrimer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SubstratePrimerUsageArgs = {
  where: SubstratePrimerUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SubstratePrimerUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SubstratePrimer_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SurfacingArgs = {
  where: Surfacing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SurfacingUsageArgs = {
  where: SurfacingUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SurfacingUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Surfacing_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TapeWidthArgs = {
  where: TapeWidth_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TapeWidth_By_PkArgs = {
  TapeWidthId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ThermalBarrierArgs = {
  where: ThermalBarrier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ThermalBarrierBoardSizesArgs = {
  where: ThermalBarrierBoardSizes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ThermalBarrierBoardSizes_By_PkArgs = {
  ComponentId: Scalars['Int'];
  InsBoardSizeId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ThermalBarrierFacerBottomArgs = {
  where: ThermalBarrierFacerBottom_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ThermalBarrierFacerBottom_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ThermalBarrierFacerTopArgs = {
  where: ThermalBarrierFacerTop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ThermalBarrierFacerTop_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ThermalBarrierUsageArgs = {
  where: ThermalBarrierUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ThermalBarrierUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ThermalBarrier_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TopCoatArgs = {
  where: TopCoat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TopCoatUsageArgs = {
  where: TopCoatUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TopCoatUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TopCoat_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UsageArgs = {
  where: Usage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UsageTradeNameViewArgs = {
  where: UsageTradeNameView_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Usage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UserReportBugArgs = {
  where: UserReportBug_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserReportBug_By_PkArgs = {
  ReportBugId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UserShareLinkArgs = {
  where: UserShareLink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserShareLink_By_PkArgs = {
  ShareLinkId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_VaporRetarderArgs = {
  where: VaporRetarder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VaporRetarderUsageArgs = {
  where: VaporRetarderUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VaporRetarderUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_VaporRetarder_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_WeldArcUsageArgs = {
  where: WeldArcUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WeldArcUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_WeldHeatUsageArgs = {
  where: WeldHeatUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WeldHeatUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_WeldSolventUsageArgs = {
  where: WeldSolventUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WeldSolventUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_WeldSystemUsageArgs = {
  where: WeldSystemUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WeldSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_WeldTorchedUsageArgs = {
  where: WeldTorchedUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WeldTorchedUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_WoodDeckArgs = {
  where: WoodDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WoodDeckUsageArgs = {
  where: WoodDeckUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WoodDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_WoodDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_LayersArgs = {
  where: VNavAssembly_Layers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_ManufacturerOnlySearchArgs = {
  where: VNavAssembly_ManufacturerOnlySearch_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_RoofTypeCountArgs = {
  where: VNavAssembly_RoofTypeCount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_SearchArgs = {
  where: VNavAssembly_Search_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_AirRetarderArgs = {
  where: VNavAssembly_Search_AirRetarder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_BaseCoatArgs = {
  where: VNavAssembly_Search_BaseCoat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_BasePlyArgs = {
  where: VNavAssembly_Search_BasePly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_BaseSheetArgs = {
  where: VNavAssembly_Search_BaseSheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_BoardStockArgs = {
  where: VNavAssembly_Search_BoardStock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_CapPlyArgs = {
  where: VNavAssembly_Search_CapPly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_CoatArgs = {
  where: VNavAssembly_Search_Coat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_CoatingArgs = {
  where: VNavAssembly_Search_Coating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_CoverBoardArgs = {
  where: VNavAssembly_Search_CoverBoard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_CoverCompositePanelArgs = {
  where: VNavAssembly_Search_CoverCompositePanel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_CoverSingleplyArgs = {
  where: VNavAssembly_Search_CoverSingleply_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_CoverStandingLapSeamArgs = {
  where: VNavAssembly_Search_CoverStandingLapSeam_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_CoverStripDiskArgs = {
  where: VNavAssembly_Search_CoverStripDisk_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_DeckCementitiousPanelArgs = {
  where: VNavAssembly_Search_DeckCementitiousPanel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_DeckSteelArgs = {
  where: VNavAssembly_Search_DeckSteel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_FormDeckArgs = {
  where: VNavAssembly_Search_FormDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_InsulationBattBlanketArgs = {
  where: VNavAssembly_Search_InsulationBattBlanket_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_InsulationSprayFoamArgs = {
  where: VNavAssembly_Search_InsulationSprayFoam_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_LightweightInsulatingConcreteArgs = {
  where: VNavAssembly_Search_LightweightInsulatingConcrete_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_LinerPanelArgs = {
  where: VNavAssembly_Search_LinerPanel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_PlyArgs = {
  where: VNavAssembly_Search_Ply_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_SeparatorSheetArgs = {
  where: VNavAssembly_Search_SeparatorSheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_SubstratePrimerArgs = {
  where: VNavAssembly_Search_SubstratePrimer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_SurfacingArgs = {
  where: VNavAssembly_Search_Surfacing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_ThermalBarrierArgs = {
  where: VNavAssembly_Search_ThermalBarrier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_TopCoatArgs = {
  where: VNavAssembly_Search_TopCoat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_Search_VaporRetarderArgs = {
  where: VNavAssembly_Search_VaporRetarder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VNavAssembly_UsagesArgs = {
  where: VNavAssembly_Usages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootInsert_AddressArgs = {
  if_matched?: Maybe<Address_If_Matched>;
  objects: Array<Address_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Address_OneArgs = {
  if_matched?: Maybe<Address_If_Matched>;
  object: Address_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_AdheredSinglePlyOrphanReportArgs = {
  if_matched?: Maybe<AdheredSinglePlyOrphanReport_If_Matched>;
  objects: Array<AdheredSinglePlyOrphanReport_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_AdheredSinglePlyOrphanReport_OneArgs = {
  if_matched?: Maybe<AdheredSinglePlyOrphanReport_If_Matched>;
  object: AdheredSinglePlyOrphanReport_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_AdhesiveFullApplicationArgs = {
  if_matched?: Maybe<AdhesiveFullApplication_If_Matched>;
  objects: Array<AdhesiveFullApplication_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_AdhesiveFullApplicationUsageArgs = {
  if_matched?: Maybe<AdhesiveFullApplicationUsage_If_Matched>;
  objects: Array<AdhesiveFullApplicationUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_AdhesiveFullApplicationUsage_OneArgs = {
  if_matched?: Maybe<AdhesiveFullApplicationUsage_If_Matched>;
  object: AdhesiveFullApplicationUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_AdhesiveFullApplication_OneArgs = {
  if_matched?: Maybe<AdhesiveFullApplication_If_Matched>;
  object: AdhesiveFullApplication_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_AdhesiveRibbonUsageArgs = {
  if_matched?: Maybe<AdhesiveRibbonUsage_If_Matched>;
  objects: Array<AdhesiveRibbonUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_AdhesiveRibbonUsage_OneArgs = {
  if_matched?: Maybe<AdhesiveRibbonUsage_If_Matched>;
  object: AdhesiveRibbonUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_AdhesiveRibbonsArgs = {
  if_matched?: Maybe<AdhesiveRibbons_If_Matched>;
  objects: Array<AdhesiveRibbons_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_AdhesiveRibbons_OneArgs = {
  if_matched?: Maybe<AdhesiveRibbons_If_Matched>;
  object: AdhesiveRibbons_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_AdhesiveSpotsArgs = {
  if_matched?: Maybe<AdhesiveSpots_If_Matched>;
  objects: Array<AdhesiveSpots_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_AdhesiveSpotsUsageArgs = {
  if_matched?: Maybe<AdhesiveSpotsUsage_If_Matched>;
  objects: Array<AdhesiveSpotsUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_AdhesiveSpotsUsage_OneArgs = {
  if_matched?: Maybe<AdhesiveSpotsUsage_If_Matched>;
  object: AdhesiveSpotsUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_AdhesiveSpots_OneArgs = {
  if_matched?: Maybe<AdhesiveSpots_If_Matched>;
  object: AdhesiveSpots_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_AdhesiveSystemUsageArgs = {
  if_matched?: Maybe<AdhesiveSystemUsage_If_Matched>;
  objects: Array<AdhesiveSystemUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_AdhesiveSystemUsage_OneArgs = {
  if_matched?: Maybe<AdhesiveSystemUsage_If_Matched>;
  object: AdhesiveSystemUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_AirRetarderArgs = {
  if_matched?: Maybe<AirRetarder_If_Matched>;
  objects: Array<AirRetarder_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_AirRetarderUsageArgs = {
  if_matched?: Maybe<AirRetarderUsage_If_Matched>;
  objects: Array<AirRetarderUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_AirRetarderUsage_OneArgs = {
  if_matched?: Maybe<AirRetarderUsage_If_Matched>;
  object: AirRetarderUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_AirRetarder_OneArgs = {
  if_matched?: Maybe<AirRetarder_If_Matched>;
  object: AirRetarder_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_AllManufacturedProductsArgs = {
  if_matched?: Maybe<AllManufacturedProducts_If_Matched>;
  objects: Array<AllManufacturedProducts_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_AllManufacturedProducts_OneArgs = {
  if_matched?: Maybe<AllManufacturedProducts_If_Matched>;
  object: AllManufacturedProducts_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_AnchorArgs = {
  if_matched?: Maybe<Anchor_If_Matched>;
  objects: Array<Anchor_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Anchor_OneArgs = {
  if_matched?: Maybe<Anchor_If_Matched>;
  object: Anchor_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ApprovalsDocArgs = {
  if_matched?: Maybe<ApprovalsDoc_If_Matched>;
  objects: Array<ApprovalsDoc_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ApprovalsDoc_OneArgs = {
  if_matched?: Maybe<ApprovalsDoc_If_Matched>;
  object: ApprovalsDoc_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ApprovedLayerUseArgs = {
  if_matched?: Maybe<ApprovedLayerUse_If_Matched>;
  objects: Array<ApprovedLayerUse_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ApprovedLayerUse_OneArgs = {
  if_matched?: Maybe<ApprovedLayerUse_If_Matched>;
  object: ApprovedLayerUse_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_AssemblyReconfigQueueArgs = {
  if_matched?: Maybe<AssemblyReconfigQueue_If_Matched>;
  objects: Array<AssemblyReconfigQueue_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_AssemblyReconfigQueue_OneArgs = {
  if_matched?: Maybe<AssemblyReconfigQueue_If_Matched>;
  object: AssemblyReconfigQueue_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BaseCoatArgs = {
  if_matched?: Maybe<BaseCoat_If_Matched>;
  objects: Array<BaseCoat_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BaseCoatUsageArgs = {
  if_matched?: Maybe<BaseCoatUsage_If_Matched>;
  objects: Array<BaseCoatUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BaseCoatUsage_OneArgs = {
  if_matched?: Maybe<BaseCoatUsage_If_Matched>;
  object: BaseCoatUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BaseCoat_OneArgs = {
  if_matched?: Maybe<BaseCoat_If_Matched>;
  object: BaseCoat_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BasePlyArgs = {
  if_matched?: Maybe<BasePly_If_Matched>;
  objects: Array<BasePly_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BasePlyUsageArgs = {
  if_matched?: Maybe<BasePlyUsage_If_Matched>;
  objects: Array<BasePlyUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BasePlyUsage_OneArgs = {
  if_matched?: Maybe<BasePlyUsage_If_Matched>;
  object: BasePlyUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BasePly_OneArgs = {
  if_matched?: Maybe<BasePly_If_Matched>;
  object: BasePly_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BaseSheetArgs = {
  if_matched?: Maybe<BaseSheet_If_Matched>;
  objects: Array<BaseSheet_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BaseSheetUsageArgs = {
  if_matched?: Maybe<BaseSheetUsage_If_Matched>;
  objects: Array<BaseSheetUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BaseSheetUsage_OneArgs = {
  if_matched?: Maybe<BaseSheetUsage_If_Matched>;
  object: BaseSheetUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BaseSheet_OneArgs = {
  if_matched?: Maybe<BaseSheet_If_Matched>;
  object: BaseSheet_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BattBlanketInsulationArgs = {
  if_matched?: Maybe<BattBlanketInsulation_If_Matched>;
  objects: Array<BattBlanketInsulation_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BattBlanketInsulation_OneArgs = {
  if_matched?: Maybe<BattBlanketInsulation_If_Matched>;
  object: BattBlanketInsulation_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BattBlanketUsageArgs = {
  if_matched?: Maybe<BattBlanketUsage_If_Matched>;
  objects: Array<BattBlanketUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BattBlanketUsage_OneArgs = {
  if_matched?: Maybe<BattBlanketUsage_If_Matched>;
  object: BattBlanketUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BattenBarArgs = {
  if_matched?: Maybe<BattenBar_If_Matched>;
  objects: Array<BattenBar_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BattenBarUsageArgs = {
  if_matched?: Maybe<BattenBarUsage_If_Matched>;
  objects: Array<BattenBarUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BattenBarUsage_OneArgs = {
  if_matched?: Maybe<BattenBarUsage_If_Matched>;
  object: BattenBarUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BattenBar_OneArgs = {
  if_matched?: Maybe<BattenBar_If_Matched>;
  object: BattenBar_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BoardSizesArgs = {
  if_matched?: Maybe<BoardSizes_If_Matched>;
  objects: Array<BoardSizes_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BoardSizes_OneArgs = {
  if_matched?: Maybe<BoardSizes_If_Matched>;
  object: BoardSizes_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BoardStockArgs = {
  if_matched?: Maybe<BoardStock_If_Matched>;
  objects: Array<BoardStock_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BoardStockBoardSizesArgs = {
  if_matched?: Maybe<BoardStockBoardSizes_If_Matched>;
  objects: Array<BoardStockBoardSizes_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BoardStockBoardSizes_OneArgs = {
  if_matched?: Maybe<BoardStockBoardSizes_If_Matched>;
  object: BoardStockBoardSizes_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BoardStockFacerBottomArgs = {
  if_matched?: Maybe<BoardStockFacerBottom_If_Matched>;
  objects: Array<BoardStockFacerBottom_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BoardStockFacerBottom_OneArgs = {
  if_matched?: Maybe<BoardStockFacerBottom_If_Matched>;
  object: BoardStockFacerBottom_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BoardStockFacerTopArgs = {
  if_matched?: Maybe<BoardStockFacerTop_If_Matched>;
  objects: Array<BoardStockFacerTop_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BoardStockFacerTop_OneArgs = {
  if_matched?: Maybe<BoardStockFacerTop_If_Matched>;
  object: BoardStockFacerTop_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BoardStockUsageArgs = {
  if_matched?: Maybe<BoardStockUsage_If_Matched>;
  objects: Array<BoardStockUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BoardStockUsage_OneArgs = {
  if_matched?: Maybe<BoardStockUsage_If_Matched>;
  object: BoardStockUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BoardStock_OneArgs = {
  if_matched?: Maybe<BoardStock_If_Matched>;
  object: BoardStock_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BroadcastAlertArgs = {
  if_matched?: Maybe<BroadcastAlert_If_Matched>;
  objects: Array<BroadcastAlert_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BroadcastAlert_OneArgs = {
  if_matched?: Maybe<BroadcastAlert_If_Matched>;
  object: BroadcastAlert_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_BuiltupVaporRetarderSystemUsageArgs = {
  if_matched?: Maybe<BuiltupVaporRetarderSystemUsage_If_Matched>;
  objects: Array<BuiltupVaporRetarderSystemUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_BuiltupVaporRetarderSystemUsage_OneArgs = {
  if_matched?: Maybe<BuiltupVaporRetarderSystemUsage_If_Matched>;
  object: BuiltupVaporRetarderSystemUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_AdditiveArgs = {
  if_matched?: Maybe<C_Additive_If_Matched>;
  objects: Array<C_Additive_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_Additive_OneArgs = {
  if_matched?: Maybe<C_Additive_If_Matched>;
  object: C_Additive_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_AdhesiveApplicationMethodArgs = {
  if_matched?: Maybe<C_AdhesiveApplicationMethod_If_Matched>;
  objects: Array<C_AdhesiveApplicationMethod_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_AdhesiveApplicationMethod_OneArgs = {
  if_matched?: Maybe<C_AdhesiveApplicationMethod_If_Matched>;
  object: C_AdhesiveApplicationMethod_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_AdhesiveMaterialArgs = {
  if_matched?: Maybe<C_AdhesiveMaterial_If_Matched>;
  objects: Array<C_AdhesiveMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_AdhesiveMaterial_OneArgs = {
  if_matched?: Maybe<C_AdhesiveMaterial_If_Matched>;
  object: C_AdhesiveMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_AdhesiveTypeArgs = {
  if_matched?: Maybe<C_AdhesiveType_If_Matched>;
  objects: Array<C_AdhesiveType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_AdhesiveType_OneArgs = {
  if_matched?: Maybe<C_AdhesiveType_If_Matched>;
  object: C_AdhesiveType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_AggregateArgs = {
  if_matched?: Maybe<C_Aggregate_If_Matched>;
  objects: Array<C_Aggregate_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_Aggregate_OneArgs = {
  if_matched?: Maybe<C_Aggregate_If_Matched>;
  object: C_Aggregate_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ApprovalsDocCategoryArgs = {
  if_matched?: Maybe<C_ApprovalsDocCategory_If_Matched>;
  objects: Array<C_ApprovalsDocCategory_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ApprovalsDocCategory_OneArgs = {
  if_matched?: Maybe<C_ApprovalsDocCategory_If_Matched>;
  object: C_ApprovalsDocCategory_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ApprovedUseArgs = {
  if_matched?: Maybe<C_ApprovedUse_If_Matched>;
  objects: Array<C_ApprovedUse_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ApprovedUse_OneArgs = {
  if_matched?: Maybe<C_ApprovedUse_If_Matched>;
  object: C_ApprovedUse_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_AssemblyApplicationTypeArgs = {
  if_matched?: Maybe<C_AssemblyApplicationType_If_Matched>;
  objects: Array<C_AssemblyApplicationType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_AssemblyApplicationType_OneArgs = {
  if_matched?: Maybe<C_AssemblyApplicationType_If_Matched>;
  object: C_AssemblyApplicationType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_AssemblyTypeArgs = {
  if_matched?: Maybe<C_AssemblyType_If_Matched>;
  objects: Array<C_AssemblyType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_AssemblyType_OneArgs = {
  if_matched?: Maybe<C_AssemblyType_If_Matched>;
  object: C_AssemblyType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_BackingMaterialArgs = {
  if_matched?: Maybe<C_BackingMaterial_If_Matched>;
  objects: Array<C_BackingMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_BackingMaterial_OneArgs = {
  if_matched?: Maybe<C_BackingMaterial_If_Matched>;
  object: C_BackingMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_BaseSheetApplicationMethodArgs = {
  if_matched?: Maybe<C_BaseSheetApplicationMethod_If_Matched>;
  objects: Array<C_BaseSheetApplicationMethod_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_BaseSheetApplicationMethod_OneArgs = {
  if_matched?: Maybe<C_BaseSheetApplicationMethod_If_Matched>;
  object: C_BaseSheetApplicationMethod_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_BattBlanketFacerMaterialArgs = {
  if_matched?: Maybe<C_BattBlanketFacerMaterial_If_Matched>;
  objects: Array<C_BattBlanketFacerMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_BattBlanketFacerMaterial_OneArgs = {
  if_matched?: Maybe<C_BattBlanketFacerMaterial_If_Matched>;
  object: C_BattBlanketFacerMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_BattBlanketMaterialArgs = {
  if_matched?: Maybe<C_BattBlanketMaterial_If_Matched>;
  objects: Array<C_BattBlanketMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_BattBlanketMaterial_OneArgs = {
  if_matched?: Maybe<C_BattBlanketMaterial_If_Matched>;
  object: C_BattBlanketMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_BoardProfileArgs = {
  if_matched?: Maybe<C_BoardProfile_If_Matched>;
  objects: Array<C_BoardProfile_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_BoardProfile_OneArgs = {
  if_matched?: Maybe<C_BoardProfile_If_Matched>;
  object: C_BoardProfile_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_BoardStockMaterialArgs = {
  if_matched?: Maybe<C_BoardStockMaterial_If_Matched>;
  objects: Array<C_BoardStockMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_BoardStockMaterial_OneArgs = {
  if_matched?: Maybe<C_BoardStockMaterial_If_Matched>;
  object: C_BoardStockMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_BuildingEnclosureArgs = {
  if_matched?: Maybe<C_BuildingEnclosure_If_Matched>;
  objects: Array<C_BuildingEnclosure_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_BuildingEnclosure_OneArgs = {
  if_matched?: Maybe<C_BuildingEnclosure_If_Matched>;
  object: C_BuildingEnclosure_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_CementAggregateRatioArgs = {
  if_matched?: Maybe<C_CementAggregateRatio_If_Matched>;
  objects: Array<C_CementAggregateRatio_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_CementAggregateRatio_OneArgs = {
  if_matched?: Maybe<C_CementAggregateRatio_If_Matched>;
  object: C_CementAggregateRatio_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_CoatingMaterialArgs = {
  if_matched?: Maybe<C_CoatingMaterial_If_Matched>;
  objects: Array<C_CoatingMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_CoatingMaterial_OneArgs = {
  if_matched?: Maybe<C_CoatingMaterial_If_Matched>;
  object: C_CoatingMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ComponentTypeArgs = {
  if_matched?: Maybe<C_ComponentType_If_Matched>;
  objects: Array<C_ComponentType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ComponentType_OneArgs = {
  if_matched?: Maybe<C_ComponentType_If_Matched>;
  object: C_ComponentType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ContactTypeArgs = {
  if_matched?: Maybe<C_ContactType_If_Matched>;
  objects: Array<C_ContactType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ContactType_OneArgs = {
  if_matched?: Maybe<C_ContactType_If_Matched>;
  object: C_ContactType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_CountryArgs = {
  if_matched?: Maybe<C_Country_If_Matched>;
  objects: Array<C_Country_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_Country_OneArgs = {
  if_matched?: Maybe<C_Country_If_Matched>;
  object: C_Country_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_CoverBoardMaterialArgs = {
  if_matched?: Maybe<C_CoverBoardMaterial_If_Matched>;
  objects: Array<C_CoverBoardMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_CoverBoardMaterial_OneArgs = {
  if_matched?: Maybe<C_CoverBoardMaterial_If_Matched>;
  object: C_CoverBoardMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_CoverColorArgs = {
  if_matched?: Maybe<C_CoverColor_If_Matched>;
  objects: Array<C_CoverColor_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_CoverColor_OneArgs = {
  if_matched?: Maybe<C_CoverColor_If_Matched>;
  object: C_CoverColor_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_CoverSecurementMethodArgs = {
  if_matched?: Maybe<C_CoverSecurementMethod_If_Matched>;
  objects: Array<C_CoverSecurementMethod_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_CoverSecurementMethod_OneArgs = {
  if_matched?: Maybe<C_CoverSecurementMethod_If_Matched>;
  object: C_CoverSecurementMethod_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_DeckTypeArgs = {
  if_matched?: Maybe<C_DeckType_If_Matched>;
  objects: Array<C_DeckType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_DeckType_OneArgs = {
  if_matched?: Maybe<C_DeckType_If_Matched>;
  object: C_DeckType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ExistingRoofCoverArgs = {
  if_matched?: Maybe<C_ExistingRoofCover_If_Matched>;
  objects: Array<C_ExistingRoofCover_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ExistingRoofCover_OneArgs = {
  if_matched?: Maybe<C_ExistingRoofCover_If_Matched>;
  object: C_ExistingRoofCover_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ExistingRoofDeckArgs = {
  if_matched?: Maybe<C_ExistingRoofDeck_If_Matched>;
  objects: Array<C_ExistingRoofDeck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ExistingRoofDeck_OneArgs = {
  if_matched?: Maybe<C_ExistingRoofDeck_If_Matched>;
  object: C_ExistingRoofDeck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ExistingRoofInsulationMaterialArgs = {
  if_matched?: Maybe<C_ExistingRoofInsulationMaterial_If_Matched>;
  objects: Array<C_ExistingRoofInsulationMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ExistingRoofInsulationMaterial_OneArgs = {
  if_matched?: Maybe<C_ExistingRoofInsulationMaterial_If_Matched>;
  object: C_ExistingRoofInsulationMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ExistingRoofSinglePlyMaterialArgs = {
  if_matched?: Maybe<C_ExistingRoofSinglePlyMaterial_If_Matched>;
  objects: Array<C_ExistingRoofSinglePlyMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ExistingRoofSinglePlyMaterial_OneArgs = {
  if_matched?: Maybe<C_ExistingRoofSinglePlyMaterial_If_Matched>;
  object: C_ExistingRoofSinglePlyMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ExistingRoofSurfaceArgs = {
  if_matched?: Maybe<C_ExistingRoofSurface_If_Matched>;
  objects: Array<C_ExistingRoofSurface_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ExistingRoofSurface_OneArgs = {
  if_matched?: Maybe<C_ExistingRoofSurface_If_Matched>;
  object: C_ExistingRoofSurface_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ExtFireRatingArgs = {
  if_matched?: Maybe<C_ExtFireRating_If_Matched>;
  objects: Array<C_ExtFireRating_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ExtFireRating_OneArgs = {
  if_matched?: Maybe<C_ExtFireRating_If_Matched>;
  object: C_ExtFireRating_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_FacerArgs = {
  if_matched?: Maybe<C_Facer_If_Matched>;
  objects: Array<C_Facer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_FacerMaterialArgs = {
  if_matched?: Maybe<C_FacerMaterial_If_Matched>;
  objects: Array<C_FacerMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_FacerMaterial_OneArgs = {
  if_matched?: Maybe<C_FacerMaterial_If_Matched>;
  object: C_FacerMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_Facer_OneArgs = {
  if_matched?: Maybe<C_Facer_If_Matched>;
  object: C_Facer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_FastenerCoatingArgs = {
  if_matched?: Maybe<C_FastenerCoating_If_Matched>;
  objects: Array<C_FastenerCoating_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_FastenerCoating_OneArgs = {
  if_matched?: Maybe<C_FastenerCoating_If_Matched>;
  object: C_FastenerCoating_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_FastenerDriveHeadArgs = {
  if_matched?: Maybe<C_FastenerDriveHead_If_Matched>;
  objects: Array<C_FastenerDriveHead_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_FastenerDriveHead_OneArgs = {
  if_matched?: Maybe<C_FastenerDriveHead_If_Matched>;
  object: C_FastenerDriveHead_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_FastenerTypeArgs = {
  if_matched?: Maybe<C_FastenerType_If_Matched>;
  objects: Array<C_FastenerType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_FastenerType_OneArgs = {
  if_matched?: Maybe<C_FastenerType_If_Matched>;
  object: C_FastenerType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_FasteningApplicationMethodArgs = {
  if_matched?: Maybe<C_FasteningApplicationMethod_If_Matched>;
  objects: Array<C_FasteningApplicationMethod_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_FasteningApplicationMethod_OneArgs = {
  if_matched?: Maybe<C_FasteningApplicationMethod_If_Matched>;
  object: C_FasteningApplicationMethod_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_FlashingMaterialArgs = {
  if_matched?: Maybe<C_FlashingMaterial_If_Matched>;
  objects: Array<C_FlashingMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_FlashingMaterial_OneArgs = {
  if_matched?: Maybe<C_FlashingMaterial_If_Matched>;
  object: C_FlashingMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_FlashingTypeArgs = {
  if_matched?: Maybe<C_FlashingType_If_Matched>;
  objects: Array<C_FlashingType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_FlashingType_OneArgs = {
  if_matched?: Maybe<C_FlashingType_If_Matched>;
  object: C_FlashingType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_FormDeckMaterialArgs = {
  if_matched?: Maybe<C_FormDeckMaterial_If_Matched>;
  objects: Array<C_FormDeckMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_FormDeckMaterial_OneArgs = {
  if_matched?: Maybe<C_FormDeckMaterial_If_Matched>;
  object: C_FormDeckMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_GroundRoughnessRatingArgs = {
  if_matched?: Maybe<C_GroundRoughnessRating_If_Matched>;
  objects: Array<C_GroundRoughnessRating_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_GroundRoughnessRating_OneArgs = {
  if_matched?: Maybe<C_GroundRoughnessRating_If_Matched>;
  object: C_GroundRoughnessRating_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_GutterMaterialArgs = {
  if_matched?: Maybe<C_GutterMaterial_If_Matched>;
  objects: Array<C_GutterMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_GutterMaterial_OneArgs = {
  if_matched?: Maybe<C_GutterMaterial_If_Matched>;
  object: C_GutterMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_HailRatingArgs = {
  if_matched?: Maybe<C_HailRating_If_Matched>;
  objects: Array<C_HailRating_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_HailRating_OneArgs = {
  if_matched?: Maybe<C_HailRating_If_Matched>;
  object: C_HailRating_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_IndustryTypeArgs = {
  if_matched?: Maybe<C_IndustryType_If_Matched>;
  objects: Array<C_IndustryType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_IndustryType_OneArgs = {
  if_matched?: Maybe<C_IndustryType_If_Matched>;
  object: C_IndustryType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_InsulationCoreArgs = {
  if_matched?: Maybe<C_InsulationCore_If_Matched>;
  objects: Array<C_InsulationCore_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_InsulationCore_OneArgs = {
  if_matched?: Maybe<C_InsulationCore_If_Matched>;
  object: C_InsulationCore_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_InsulationFoamTypeArgs = {
  if_matched?: Maybe<C_InsulationFoamType_If_Matched>;
  objects: Array<C_InsulationFoamType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_InsulationFoamType_OneArgs = {
  if_matched?: Maybe<C_InsulationFoamType_If_Matched>;
  object: C_InsulationFoamType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_IntFireRatingArgs = {
  if_matched?: Maybe<C_IntFireRating_If_Matched>;
  objects: Array<C_IntFireRating_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_IntFireRating_OneArgs = {
  if_matched?: Maybe<C_IntFireRating_If_Matched>;
  object: C_IntFireRating_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_LapSealantTypeArgs = {
  if_matched?: Maybe<C_LapSealantType_If_Matched>;
  objects: Array<C_LapSealantType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_LapSealantType_OneArgs = {
  if_matched?: Maybe<C_LapSealantType_If_Matched>;
  object: C_LapSealantType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_LayerTypeArgs = {
  if_matched?: Maybe<C_LayerType_If_Matched>;
  objects: Array<C_LayerType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_LayerType_OneArgs = {
  if_matched?: Maybe<C_LayerType_If_Matched>;
  object: C_LayerType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_LinerPanelMaterialArgs = {
  if_matched?: Maybe<C_LinerPanelMaterial_If_Matched>;
  objects: Array<C_LinerPanelMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_LinerPanelMaterial_OneArgs = {
  if_matched?: Maybe<C_LinerPanelMaterial_If_Matched>;
  object: C_LinerPanelMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_LiquidApplicationArgs = {
  if_matched?: Maybe<C_LiquidApplication_If_Matched>;
  objects: Array<C_LiquidApplication_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_LiquidApplication_OneArgs = {
  if_matched?: Maybe<C_LiquidApplication_If_Matched>;
  object: C_LiquidApplication_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_LiquidAppliedMaterialArgs = {
  if_matched?: Maybe<C_LiquidAppliedMaterial_If_Matched>;
  objects: Array<C_LiquidAppliedMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_LiquidAppliedMaterial_OneArgs = {
  if_matched?: Maybe<C_LiquidAppliedMaterial_If_Matched>;
  object: C_LiquidAppliedMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_LockStatusArgs = {
  if_matched?: Maybe<C_LockStatus_If_Matched>;
  objects: Array<C_LockStatus_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_LockStatus_OneArgs = {
  if_matched?: Maybe<C_LockStatus_If_Matched>;
  object: C_LockStatus_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_MdAttributeTypeArgs = {
  if_matched?: Maybe<C_MdAttributeType_If_Matched>;
  objects: Array<C_MdAttributeType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_MdAttributeType_OneArgs = {
  if_matched?: Maybe<C_MdAttributeType_If_Matched>;
  object: C_MdAttributeType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_MaintenanceCoatingMaterialArgs = {
  if_matched?: Maybe<C_MaintenanceCoatingMaterial_If_Matched>;
  objects: Array<C_MaintenanceCoatingMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_MaintenanceCoatingMaterial_OneArgs = {
  if_matched?: Maybe<C_MaintenanceCoatingMaterial_If_Matched>;
  object: C_MaintenanceCoatingMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_MaintenanceCoatingSubstrateArgs = {
  if_matched?: Maybe<C_MaintenanceCoatingSubstrate_If_Matched>;
  objects: Array<C_MaintenanceCoatingSubstrate_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_MaintenanceCoatingSubstrate_OneArgs = {
  if_matched?: Maybe<C_MaintenanceCoatingSubstrate_If_Matched>;
  object: C_MaintenanceCoatingSubstrate_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_MembraneTerminationArgs = {
  if_matched?: Maybe<C_MembraneTermination_If_Matched>;
  objects: Array<C_MembraneTermination_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_MembraneTermination_OneArgs = {
  if_matched?: Maybe<C_MembraneTermination_If_Matched>;
  object: C_MembraneTermination_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_MultiplyMaterialArgs = {
  if_matched?: Maybe<C_MultiplyMaterial_If_Matched>;
  objects: Array<C_MultiplyMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_MultiplyMaterial_OneArgs = {
  if_matched?: Maybe<C_MultiplyMaterial_If_Matched>;
  object: C_MultiplyMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_PvApplicationMethodArgs = {
  if_matched?: Maybe<C_PvApplicationMethod_If_Matched>;
  objects: Array<C_PvApplicationMethod_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_PvApplicationMethod_OneArgs = {
  if_matched?: Maybe<C_PvApplicationMethod_If_Matched>;
  object: C_PvApplicationMethod_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_PvHailRatingArgs = {
  if_matched?: Maybe<C_PvHailRating_If_Matched>;
  objects: Array<C_PvHailRating_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_PvHailRating_OneArgs = {
  if_matched?: Maybe<C_PvHailRating_If_Matched>;
  object: C_PvHailRating_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_PerimeterFlashingTypeArgs = {
  if_matched?: Maybe<C_PerimeterFlashingType_If_Matched>;
  objects: Array<C_PerimeterFlashingType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_PerimeterFlashingType_OneArgs = {
  if_matched?: Maybe<C_PerimeterFlashingType_If_Matched>;
  object: C_PerimeterFlashingType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_PressureZoneArgs = {
  if_matched?: Maybe<C_PressureZone_If_Matched>;
  objects: Array<C_PressureZone_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_PressureZone_OneArgs = {
  if_matched?: Maybe<C_PressureZone_If_Matched>;
  object: C_PressureZone_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ProductApprovedUseArgs = {
  if_matched?: Maybe<C_ProductApprovedUse_If_Matched>;
  objects: Array<C_ProductApprovedUse_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ProductApprovedUse_OneArgs = {
  if_matched?: Maybe<C_ProductApprovedUse_If_Matched>;
  object: C_ProductApprovedUse_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ProjectRoleArgs = {
  if_matched?: Maybe<C_ProjectRole_If_Matched>;
  objects: Array<C_ProjectRole_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ProjectRole_OneArgs = {
  if_matched?: Maybe<C_ProjectRole_If_Matched>;
  object: C_ProjectRole_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ReinforcementArgs = {
  if_matched?: Maybe<C_Reinforcement_If_Matched>;
  objects: Array<C_Reinforcement_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_Reinforcement_OneArgs = {
  if_matched?: Maybe<C_Reinforcement_If_Matched>;
  object: C_Reinforcement_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_RetarderMaterialArgs = {
  if_matched?: Maybe<C_RetarderMaterial_If_Matched>;
  objects: Array<C_RetarderMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_RetarderMaterial_OneArgs = {
  if_matched?: Maybe<C_RetarderMaterial_If_Matched>;
  object: C_RetarderMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ScenarioStatusArgs = {
  if_matched?: Maybe<C_ScenarioStatus_If_Matched>;
  objects: Array<C_ScenarioStatus_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ScenarioStatus_OneArgs = {
  if_matched?: Maybe<C_ScenarioStatus_If_Matched>;
  object: C_ScenarioStatus_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ScenarioTypeArgs = {
  if_matched?: Maybe<C_ScenarioType_If_Matched>;
  objects: Array<C_ScenarioType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ScenarioType_OneArgs = {
  if_matched?: Maybe<C_ScenarioType_If_Matched>;
  object: C_ScenarioType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_SeamPrimerTypeArgs = {
  if_matched?: Maybe<C_SeamPrimerType_If_Matched>;
  objects: Array<C_SeamPrimerType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_SeamPrimerType_OneArgs = {
  if_matched?: Maybe<C_SeamPrimerType_If_Matched>;
  object: C_SeamPrimerType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_SeamWashTypeArgs = {
  if_matched?: Maybe<C_SeamWashType_If_Matched>;
  objects: Array<C_SeamWashType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_SeamWashType_OneArgs = {
  if_matched?: Maybe<C_SeamWashType_If_Matched>;
  object: C_SeamWashType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_SecurementMaterialArgs = {
  if_matched?: Maybe<C_SecurementMaterial_If_Matched>;
  objects: Array<C_SecurementMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_SecurementMaterial_OneArgs = {
  if_matched?: Maybe<C_SecurementMaterial_If_Matched>;
  object: C_SecurementMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ShinglesHailArgs = {
  if_matched?: Maybe<C_ShinglesHail_If_Matched>;
  objects: Array<C_ShinglesHail_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ShinglesHail_OneArgs = {
  if_matched?: Maybe<C_ShinglesHail_If_Matched>;
  object: C_ShinglesHail_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ShinglesMaterialArgs = {
  if_matched?: Maybe<C_ShinglesMaterial_If_Matched>;
  objects: Array<C_ShinglesMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ShinglesMaterial_OneArgs = {
  if_matched?: Maybe<C_ShinglesMaterial_If_Matched>;
  object: C_ShinglesMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ShinglesReinforcementArgs = {
  if_matched?: Maybe<C_ShinglesReinforcement_If_Matched>;
  objects: Array<C_ShinglesReinforcement_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ShinglesReinforcement_OneArgs = {
  if_matched?: Maybe<C_ShinglesReinforcement_If_Matched>;
  object: C_ShinglesReinforcement_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ShinglesSurfacingArgs = {
  if_matched?: Maybe<C_ShinglesSurfacing_If_Matched>;
  objects: Array<C_ShinglesSurfacing_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ShinglesSurfacing_OneArgs = {
  if_matched?: Maybe<C_ShinglesSurfacing_If_Matched>;
  object: C_ShinglesSurfacing_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ShinglesWindSpeedArgs = {
  if_matched?: Maybe<C_ShinglesWindSpeed_If_Matched>;
  objects: Array<C_ShinglesWindSpeed_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ShinglesWindSpeed_OneArgs = {
  if_matched?: Maybe<C_ShinglesWindSpeed_If_Matched>;
  object: C_ShinglesWindSpeed_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_SinglePlyMaterialArgs = {
  if_matched?: Maybe<C_SinglePlyMaterial_If_Matched>;
  objects: Array<C_SinglePlyMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_SinglePlyMaterial_OneArgs = {
  if_matched?: Maybe<C_SinglePlyMaterial_If_Matched>;
  object: C_SinglePlyMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_StandingLapSeamMaterialArgs = {
  if_matched?: Maybe<C_StandingLapSeamMaterial_If_Matched>;
  objects: Array<C_StandingLapSeamMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_StandingLapSeamMaterial_OneArgs = {
  if_matched?: Maybe<C_StandingLapSeamMaterial_If_Matched>;
  object: C_StandingLapSeamMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_StateProvinceArgs = {
  if_matched?: Maybe<C_StateProvince_If_Matched>;
  objects: Array<C_StateProvince_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_StateProvince_OneArgs = {
  if_matched?: Maybe<C_StateProvince_If_Matched>;
  object: C_StateProvince_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_StatusArgs = {
  if_matched?: Maybe<C_Status_If_Matched>;
  objects: Array<C_Status_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_Status_OneArgs = {
  if_matched?: Maybe<C_Status_If_Matched>;
  object: C_Status_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_SteelDeckRibTypeArgs = {
  if_matched?: Maybe<C_SteelDeckRibType_If_Matched>;
  objects: Array<C_SteelDeckRibType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_SteelDeckRibType_OneArgs = {
  if_matched?: Maybe<C_SteelDeckRibType_If_Matched>;
  object: C_SteelDeckRibType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_StressDistributorTypeArgs = {
  if_matched?: Maybe<C_StressDistributorType_If_Matched>;
  objects: Array<C_StressDistributorType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_StressDistributorType_OneArgs = {
  if_matched?: Maybe<C_StressDistributorType_If_Matched>;
  object: C_StressDistributorType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_StressPlateShapeArgs = {
  if_matched?: Maybe<C_StressPlateShape_If_Matched>;
  objects: Array<C_StressPlateShape_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_StressPlateShape_OneArgs = {
  if_matched?: Maybe<C_StressPlateShape_If_Matched>;
  object: C_StressPlateShape_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_StructureTypeArgs = {
  if_matched?: Maybe<C_StructureType_If_Matched>;
  objects: Array<C_StructureType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_StructureType_OneArgs = {
  if_matched?: Maybe<C_StructureType_If_Matched>;
  object: C_StructureType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_SubassemblyTypeArgs = {
  if_matched?: Maybe<C_SubassemblyType_If_Matched>;
  objects: Array<C_SubassemblyType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_SubassemblyType_OneArgs = {
  if_matched?: Maybe<C_SubassemblyType_If_Matched>;
  object: C_SubassemblyType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_SubstrateArgs = {
  if_matched?: Maybe<C_Substrate_If_Matched>;
  objects: Array<C_Substrate_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_SubstratePrimerMaterialArgs = {
  if_matched?: Maybe<C_SubstratePrimerMaterial_If_Matched>;
  objects: Array<C_SubstratePrimerMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_SubstratePrimerMaterial_OneArgs = {
  if_matched?: Maybe<C_SubstratePrimerMaterial_If_Matched>;
  object: C_SubstratePrimerMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_Substrate_OneArgs = {
  if_matched?: Maybe<C_Substrate_If_Matched>;
  object: C_Substrate_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_SurfaceTypeArgs = {
  if_matched?: Maybe<C_SurfaceType_If_Matched>;
  objects: Array<C_SurfaceType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_SurfaceType_OneArgs = {
  if_matched?: Maybe<C_SurfaceType_If_Matched>;
  object: C_SurfaceType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_SurfacingMaterialArgs = {
  if_matched?: Maybe<C_SurfacingMaterial_If_Matched>;
  objects: Array<C_SurfacingMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_SurfacingMaterial_OneArgs = {
  if_matched?: Maybe<C_SurfacingMaterial_If_Matched>;
  object: C_SurfacingMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_SystemApprovedUseArgs = {
  if_matched?: Maybe<C_SystemApprovedUse_If_Matched>;
  objects: Array<C_SystemApprovedUse_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_SystemApprovedUse_OneArgs = {
  if_matched?: Maybe<C_SystemApprovedUse_If_Matched>;
  object: C_SystemApprovedUse_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_ThermalBarrierMaterialArgs = {
  if_matched?: Maybe<C_ThermalBarrierMaterial_If_Matched>;
  objects: Array<C_ThermalBarrierMaterial_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_ThermalBarrierMaterial_OneArgs = {
  if_matched?: Maybe<C_ThermalBarrierMaterial_If_Matched>;
  object: C_ThermalBarrierMaterial_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_WipDelistReasonArgs = {
  if_matched?: Maybe<C_WipDelistReason_If_Matched>;
  objects: Array<C_WipDelistReason_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_WipDelistReason_OneArgs = {
  if_matched?: Maybe<C_WipDelistReason_If_Matched>;
  object: C_WipDelistReason_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_WipOnHoldReasonArgs = {
  if_matched?: Maybe<C_WipOnHoldReason_If_Matched>;
  objects: Array<C_WipOnHoldReason_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_WipOnHoldReason_OneArgs = {
  if_matched?: Maybe<C_WipOnHoldReason_If_Matched>;
  object: C_WipOnHoldReason_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_WipStatusArgs = {
  if_matched?: Maybe<C_WipStatus_If_Matched>;
  objects: Array<C_WipStatus_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_WipStatus_OneArgs = {
  if_matched?: Maybe<C_WipStatus_If_Matched>;
  object: C_WipStatus_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_WeldApplicationArgs = {
  if_matched?: Maybe<C_WeldApplication_If_Matched>;
  objects: Array<C_WeldApplication_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_WeldApplication_OneArgs = {
  if_matched?: Maybe<C_WeldApplication_If_Matched>;
  object: C_WeldApplication_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_WeldConfigurationArgs = {
  if_matched?: Maybe<C_WeldConfiguration_If_Matched>;
  objects: Array<C_WeldConfiguration_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_WeldConfiguration_OneArgs = {
  if_matched?: Maybe<C_WeldConfiguration_If_Matched>;
  object: C_WeldConfiguration_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_WindBorneDebrisArgs = {
  if_matched?: Maybe<C_WindBorneDebris_If_Matched>;
  objects: Array<C_WindBorneDebris_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_WindBorneDebris_OneArgs = {
  if_matched?: Maybe<C_WindBorneDebris_If_Matched>;
  object: C_WindBorneDebris_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_WoodSpeciesArgs = {
  if_matched?: Maybe<C_WoodSpecies_If_Matched>;
  objects: Array<C_WoodSpecies_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_WoodSpecies_OneArgs = {
  if_matched?: Maybe<C_WoodSpecies_If_Matched>;
  object: C_WoodSpecies_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_WoodTypeArgs = {
  if_matched?: Maybe<C_WoodType_If_Matched>;
  objects: Array<C_WoodType_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_WoodType_OneArgs = {
  if_matched?: Maybe<C_WoodType_If_Matched>;
  object: C_WoodType_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_C_WorkflowStatusArgs = {
  if_matched?: Maybe<C_WorkflowStatus_If_Matched>;
  objects: Array<C_WorkflowStatus_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_C_WorkflowStatus_OneArgs = {
  if_matched?: Maybe<C_WorkflowStatus_If_Matched>;
  object: C_WorkflowStatus_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CapPlyArgs = {
  if_matched?: Maybe<CapPly_If_Matched>;
  objects: Array<CapPly_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CapPlyUsageArgs = {
  if_matched?: Maybe<CapPlyUsage_If_Matched>;
  objects: Array<CapPlyUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CapPlyUsage_OneArgs = {
  if_matched?: Maybe<CapPlyUsage_If_Matched>;
  object: CapPlyUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CapPly_OneArgs = {
  if_matched?: Maybe<CapPly_If_Matched>;
  object: CapPly_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CategoryArgs = {
  if_matched?: Maybe<Category_If_Matched>;
  objects: Array<Category_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CategoryLogArgs = {
  if_matched?: Maybe<CategoryLog_If_Matched>;
  objects: Array<CategoryLog_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CategoryLog_OneArgs = {
  if_matched?: Maybe<CategoryLog_If_Matched>;
  object: CategoryLog_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Category_CoatingArgs = {
  if_matched?: Maybe<Category_Coating_If_Matched>;
  objects: Array<Category_Coating_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Coating_OneArgs = {
  if_matched?: Maybe<Category_Coating_If_Matched>;
  object: Category_Coating_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Category_CoverArgs = {
  if_matched?: Maybe<Category_Cover_If_Matched>;
  objects: Array<Category_Cover_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Cover_OneArgs = {
  if_matched?: Maybe<Category_Cover_If_Matched>;
  object: Category_Cover_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Category_DeckArgs = {
  if_matched?: Maybe<Category_Deck_If_Matched>;
  objects: Array<Category_Deck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Deck_OneArgs = {
  if_matched?: Maybe<Category_Deck_If_Matched>;
  object: Category_Deck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Category_FlashingArgs = {
  if_matched?: Maybe<Category_Flashing_If_Matched>;
  objects: Array<Category_Flashing_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Flashing_OneArgs = {
  if_matched?: Maybe<Category_Flashing_If_Matched>;
  object: Category_Flashing_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Category_InsulationArgs = {
  if_matched?: Maybe<Category_Insulation_If_Matched>;
  objects: Array<Category_Insulation_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Insulation_OneArgs = {
  if_matched?: Maybe<Category_Insulation_If_Matched>;
  object: Category_Insulation_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Category_OtherArgs = {
  if_matched?: Maybe<Category_Other_If_Matched>;
  objects: Array<Category_Other_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Other_OneArgs = {
  if_matched?: Maybe<Category_Other_If_Matched>;
  object: Category_Other_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Category_SeamingArgs = {
  if_matched?: Maybe<Category_Seaming_If_Matched>;
  objects: Array<Category_Seaming_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Seaming_OneArgs = {
  if_matched?: Maybe<Category_Seaming_If_Matched>;
  object: Category_Seaming_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Category_SecurementArgs = {
  if_matched?: Maybe<Category_Securement_If_Matched>;
  objects: Array<Category_Securement_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Securement_OneArgs = {
  if_matched?: Maybe<Category_Securement_If_Matched>;
  object: Category_Securement_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Category_SheetArgs = {
  if_matched?: Maybe<Category_Sheet_If_Matched>;
  objects: Array<Category_Sheet_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Sheet_OneArgs = {
  if_matched?: Maybe<Category_Sheet_If_Matched>;
  object: Category_Sheet_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Category_OneArgs = {
  if_matched?: Maybe<Category_If_Matched>;
  object: Category_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CementitiousWoodFiberDeckArgs = {
  if_matched?: Maybe<CementitiousWoodFiberDeck_If_Matched>;
  objects: Array<CementitiousWoodFiberDeck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CementitiousWoodFiberDeckUsageArgs = {
  if_matched?: Maybe<CementitiousWoodFiberDeckUsage_If_Matched>;
  objects: Array<CementitiousWoodFiberDeckUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CementitiousWoodFiberDeckUsage_OneArgs = {
  if_matched?: Maybe<CementitiousWoodFiberDeckUsage_If_Matched>;
  object: CementitiousWoodFiberDeckUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CementitiousWoodFiberDeck_OneArgs = {
  if_matched?: Maybe<CementitiousWoodFiberDeck_If_Matched>;
  object: CementitiousWoodFiberDeck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ClipArgs = {
  if_matched?: Maybe<Clip_If_Matched>;
  objects: Array<Clip_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ClipUsageArgs = {
  if_matched?: Maybe<ClipUsage_If_Matched>;
  objects: Array<ClipUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ClipUsage_OneArgs = {
  if_matched?: Maybe<ClipUsage_If_Matched>;
  object: ClipUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Clip_OneArgs = {
  if_matched?: Maybe<Clip_If_Matched>;
  object: Clip_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CoatArgs = {
  if_matched?: Maybe<Coat_If_Matched>;
  objects: Array<Coat_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CoatUsageArgs = {
  if_matched?: Maybe<CoatUsage_If_Matched>;
  objects: Array<CoatUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CoatUsage_OneArgs = {
  if_matched?: Maybe<CoatUsage_If_Matched>;
  object: CoatUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Coat_OneArgs = {
  if_matched?: Maybe<Coat_If_Matched>;
  object: Coat_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CoatingArgs = {
  if_matched?: Maybe<Coating_If_Matched>;
  objects: Array<Coating_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CoatingSystemUsageArgs = {
  if_matched?: Maybe<CoatingSystemUsage_If_Matched>;
  objects: Array<CoatingSystemUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CoatingSystemUsage_OneArgs = {
  if_matched?: Maybe<CoatingSystemUsage_If_Matched>;
  object: CoatingSystemUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CoatingUsageArgs = {
  if_matched?: Maybe<CoatingUsage_If_Matched>;
  objects: Array<CoatingUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CoatingUsage_OneArgs = {
  if_matched?: Maybe<CoatingUsage_If_Matched>;
  object: CoatingUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Coating_OneArgs = {
  if_matched?: Maybe<Coating_If_Matched>;
  object: Coating_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ComponentArgs = {
  if_matched?: Maybe<Component_If_Matched>;
  objects: Array<Component_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ComponentApprovedUseArgs = {
  if_matched?: Maybe<ComponentApprovedUse_If_Matched>;
  objects: Array<ComponentApprovedUse_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ComponentApprovedUse_OneArgs = {
  if_matched?: Maybe<ComponentApprovedUse_If_Matched>;
  object: ComponentApprovedUse_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ComponentDocArgs = {
  if_matched?: Maybe<ComponentDoc_If_Matched>;
  objects: Array<ComponentDoc_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ComponentDoc_OneArgs = {
  if_matched?: Maybe<ComponentDoc_If_Matched>;
  object: ComponentDoc_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ComponentLayerArgs = {
  if_matched?: Maybe<ComponentLayer_If_Matched>;
  objects: Array<ComponentLayer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ComponentLayerUsageArgs = {
  if_matched?: Maybe<ComponentLayerUsage_If_Matched>;
  objects: Array<ComponentLayerUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ComponentLayerUsage_OneArgs = {
  if_matched?: Maybe<ComponentLayerUsage_If_Matched>;
  object: ComponentLayerUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ComponentLayer_OneArgs = {
  if_matched?: Maybe<ComponentLayer_If_Matched>;
  object: ComponentLayer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Component_OneArgs = {
  if_matched?: Maybe<Component_If_Matched>;
  object: Component_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CompositePanelCoverArgs = {
  if_matched?: Maybe<CompositePanelCover_If_Matched>;
  objects: Array<CompositePanelCover_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CompositePanelCover_OneArgs = {
  if_matched?: Maybe<CompositePanelCover_If_Matched>;
  object: CompositePanelCover_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CompositePanelUsageArgs = {
  if_matched?: Maybe<CompositePanelUsage_If_Matched>;
  objects: Array<CompositePanelUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CompositePanelUsage_OneArgs = {
  if_matched?: Maybe<CompositePanelUsage_If_Matched>;
  object: CompositePanelUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ContactInfoArgs = {
  if_matched?: Maybe<ContactInfo_If_Matched>;
  objects: Array<ContactInfo_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ContactInfo_OneArgs = {
  if_matched?: Maybe<ContactInfo_If_Matched>;
  object: ContactInfo_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CopingArgs = {
  if_matched?: Maybe<Coping_If_Matched>;
  objects: Array<Coping_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Coping_OneArgs = {
  if_matched?: Maybe<Coping_If_Matched>;
  object: Coping_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CoverBoardArgs = {
  if_matched?: Maybe<CoverBoard_If_Matched>;
  objects: Array<CoverBoard_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CoverBoardBoardSizesArgs = {
  if_matched?: Maybe<CoverBoardBoardSizes_If_Matched>;
  objects: Array<CoverBoardBoardSizes_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CoverBoardBoardSizes_OneArgs = {
  if_matched?: Maybe<CoverBoardBoardSizes_If_Matched>;
  object: CoverBoardBoardSizes_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CoverBoardFacerBottomArgs = {
  if_matched?: Maybe<CoverBoardFacerBottom_If_Matched>;
  objects: Array<CoverBoardFacerBottom_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CoverBoardFacerBottom_OneArgs = {
  if_matched?: Maybe<CoverBoardFacerBottom_If_Matched>;
  object: CoverBoardFacerBottom_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CoverBoardFacerTopArgs = {
  if_matched?: Maybe<CoverBoardFacerTop_If_Matched>;
  objects: Array<CoverBoardFacerTop_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CoverBoardFacerTop_OneArgs = {
  if_matched?: Maybe<CoverBoardFacerTop_If_Matched>;
  object: CoverBoardFacerTop_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CoverBoardUsageArgs = {
  if_matched?: Maybe<CoverBoardUsage_If_Matched>;
  objects: Array<CoverBoardUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CoverBoardUsage_OneArgs = {
  if_matched?: Maybe<CoverBoardUsage_If_Matched>;
  object: CoverBoardUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CoverBoard_OneArgs = {
  if_matched?: Maybe<CoverBoard_If_Matched>;
  object: CoverBoard_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CoverStripDiskArgs = {
  if_matched?: Maybe<CoverStripDisk_If_Matched>;
  objects: Array<CoverStripDisk_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CoverStripDiskUsageArgs = {
  if_matched?: Maybe<CoverStripDiskUsage_If_Matched>;
  objects: Array<CoverStripDiskUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_CoverStripDiskUsage_OneArgs = {
  if_matched?: Maybe<CoverStripDiskUsage_If_Matched>;
  object: CoverStripDiskUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CoverStripDisk_OneArgs = {
  if_matched?: Maybe<CoverStripDisk_If_Matched>;
  object: CoverStripDisk_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ExistingRoofLayerArgs = {
  if_matched?: Maybe<ExistingRoofLayer_If_Matched>;
  objects: Array<ExistingRoofLayer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ExistingRoofLayer_OneArgs = {
  if_matched?: Maybe<ExistingRoofLayer_If_Matched>;
  object: ExistingRoofLayer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ExpansionJointArgs = {
  if_matched?: Maybe<ExpansionJoint_If_Matched>;
  objects: Array<ExpansionJoint_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ExpansionJointUsageArgs = {
  if_matched?: Maybe<ExpansionJointUsage_If_Matched>;
  objects: Array<ExpansionJointUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ExpansionJointUsage_OneArgs = {
  if_matched?: Maybe<ExpansionJointUsage_If_Matched>;
  object: ExpansionJointUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ExpansionJoint_OneArgs = {
  if_matched?: Maybe<ExpansionJoint_If_Matched>;
  object: ExpansionJoint_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ExtendedLayerConfigSplitArgs = {
  if_matched?: Maybe<ExtendedLayerConfigSplit_If_Matched>;
  objects: Array<ExtendedLayerConfigSplit_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ExtendedLayerConfigSplit_OneArgs = {
  if_matched?: Maybe<ExtendedLayerConfigSplit_If_Matched>;
  object: ExtendedLayerConfigSplit_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_FmUserAccountArgs = {
  if_matched?: Maybe<FmUserAccount_If_Matched>;
  objects: Array<FmUserAccount_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_FmUserAccount_OneArgs = {
  if_matched?: Maybe<FmUserAccount_If_Matched>;
  object: FmUserAccount_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_FasciaArgs = {
  if_matched?: Maybe<Fascia_If_Matched>;
  objects: Array<Fascia_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Fascia_OneArgs = {
  if_matched?: Maybe<Fascia_If_Matched>;
  object: Fascia_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_FastenerArgs = {
  if_matched?: Maybe<Fastener_If_Matched>;
  objects: Array<Fastener_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_FastenerUsageArgs = {
  if_matched?: Maybe<FastenerUsage_If_Matched>;
  objects: Array<FastenerUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_FastenerUsage_OneArgs = {
  if_matched?: Maybe<FastenerUsage_If_Matched>;
  object: FastenerUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Fastener_OneArgs = {
  if_matched?: Maybe<Fastener_If_Matched>;
  object: Fastener_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_FasteningSystemBattenBarUsageArgs = {
  if_matched?: Maybe<FasteningSystemBattenBarUsage_If_Matched>;
  objects: Array<FasteningSystemBattenBarUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_FasteningSystemBattenBarUsage_OneArgs = {
  if_matched?: Maybe<FasteningSystemBattenBarUsage_If_Matched>;
  object: FasteningSystemBattenBarUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_FasteningSystemClipUsageArgs = {
  if_matched?: Maybe<FasteningSystemClipUsage_If_Matched>;
  objects: Array<FasteningSystemClipUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_FasteningSystemClipUsage_OneArgs = {
  if_matched?: Maybe<FasteningSystemClipUsage_If_Matched>;
  object: FasteningSystemClipUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_FasteningSystemStressPlateUsageArgs = {
  if_matched?: Maybe<FasteningSystemStressPlateUsage_If_Matched>;
  objects: Array<FasteningSystemStressPlateUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_FasteningSystemStressPlateUsage_OneArgs = {
  if_matched?: Maybe<FasteningSystemStressPlateUsage_If_Matched>;
  object: FasteningSystemStressPlateUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_FiberReinforcedPlasticDeckArgs = {
  if_matched?: Maybe<FiberReinforcedPlasticDeck_If_Matched>;
  objects: Array<FiberReinforcedPlasticDeck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_FiberReinforcedPlasticDeckUsageArgs = {
  if_matched?: Maybe<FiberReinforcedPlasticDeckUsage_If_Matched>;
  objects: Array<FiberReinforcedPlasticDeckUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_FiberReinforcedPlasticDeckUsage_OneArgs = {
  if_matched?: Maybe<FiberReinforcedPlasticDeckUsage_If_Matched>;
  object: FiberReinforcedPlasticDeckUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_FiberReinforcedPlasticDeck_OneArgs = {
  if_matched?: Maybe<FiberReinforcedPlasticDeck_If_Matched>;
  object: FiberReinforcedPlasticDeck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_FormDeckArgs = {
  if_matched?: Maybe<FormDeck_If_Matched>;
  objects: Array<FormDeck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_FormDeckUsageArgs = {
  if_matched?: Maybe<FormDeckUsage_If_Matched>;
  objects: Array<FormDeckUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_FormDeckUsage_OneArgs = {
  if_matched?: Maybe<FormDeckUsage_If_Matched>;
  object: FormDeckUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_FormDeck_OneArgs = {
  if_matched?: Maybe<FormDeck_If_Matched>;
  object: FormDeck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_GutterArgs = {
  if_matched?: Maybe<Gutter_If_Matched>;
  objects: Array<Gutter_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Gutter_OneArgs = {
  if_matched?: Maybe<Gutter_If_Matched>;
  object: Gutter_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_GypsumDeckArgs = {
  if_matched?: Maybe<GypsumDeck_If_Matched>;
  objects: Array<GypsumDeck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_GypsumDeckUsageArgs = {
  if_matched?: Maybe<GypsumDeckUsage_If_Matched>;
  objects: Array<GypsumDeckUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_GypsumDeckUsage_OneArgs = {
  if_matched?: Maybe<GypsumDeckUsage_If_Matched>;
  object: GypsumDeckUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_GypsumDeck_OneArgs = {
  if_matched?: Maybe<GypsumDeck_If_Matched>;
  object: GypsumDeck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_InsBoardSizeArgs = {
  if_matched?: Maybe<InsBoardSize_If_Matched>;
  objects: Array<InsBoardSize_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_InsBoardSize_OneArgs = {
  if_matched?: Maybe<InsBoardSize_If_Matched>;
  object: InsBoardSize_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ItemWorkspaceArgs = {
  if_matched?: Maybe<ItemWorkspace_If_Matched>;
  objects: Array<ItemWorkspace_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ItemWorkspaceDataArgs = {
  if_matched?: Maybe<ItemWorkspaceData_If_Matched>;
  objects: Array<ItemWorkspaceData_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ItemWorkspaceData_OneArgs = {
  if_matched?: Maybe<ItemWorkspaceData_If_Matched>;
  object: ItemWorkspaceData_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ItemWorkspace_OneArgs = {
  if_matched?: Maybe<ItemWorkspace_If_Matched>;
  object: ItemWorkspace_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_LapSealantArgs = {
  if_matched?: Maybe<LapSealant_If_Matched>;
  objects: Array<LapSealant_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_LapSealantUsageArgs = {
  if_matched?: Maybe<LapSealantUsage_If_Matched>;
  objects: Array<LapSealantUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_LapSealantUsage_OneArgs = {
  if_matched?: Maybe<LapSealantUsage_If_Matched>;
  object: LapSealantUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_LapSealant_OneArgs = {
  if_matched?: Maybe<LapSealant_If_Matched>;
  object: LapSealant_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_LapSecurementInfoArgs = {
  if_matched?: Maybe<LapSecurementInfo_If_Matched>;
  objects: Array<LapSecurementInfo_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_LapSecurementInfo_OneArgs = {
  if_matched?: Maybe<LapSecurementInfo_If_Matched>;
  object: LapSecurementInfo_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_LayerArgs = {
  if_matched?: Maybe<Layer_If_Matched>;
  objects: Array<Layer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_LayerConfigArgs = {
  if_matched?: Maybe<LayerConfig_If_Matched>;
  objects: Array<LayerConfig_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_LayerConfig_OneArgs = {
  if_matched?: Maybe<LayerConfig_If_Matched>;
  object: LayerConfig_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Layer_OneArgs = {
  if_matched?: Maybe<Layer_If_Matched>;
  object: Layer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_LightweightInsulatingConcreteArgs = {
  if_matched?: Maybe<LightweightInsulatingConcrete_If_Matched>;
  objects: Array<LightweightInsulatingConcrete_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_LightweightInsulatingConcreteSubassemblyUsageArgs = {
  if_matched?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_If_Matched>;
  objects: Array<LightweightInsulatingConcreteSubassemblyUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_LightweightInsulatingConcreteSubassemblyUsage_OneArgs = {
  if_matched?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_If_Matched>;
  object: LightweightInsulatingConcreteSubassemblyUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_LightweightInsulatingConcreteUsageArgs = {
  if_matched?: Maybe<LightweightInsulatingConcreteUsage_If_Matched>;
  objects: Array<LightweightInsulatingConcreteUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_LightweightInsulatingConcreteUsage_OneArgs = {
  if_matched?: Maybe<LightweightInsulatingConcreteUsage_If_Matched>;
  object: LightweightInsulatingConcreteUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_LightweightInsulatingConcrete_OneArgs = {
  if_matched?: Maybe<LightweightInsulatingConcrete_If_Matched>;
  object: LightweightInsulatingConcrete_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_LinerPanelArgs = {
  if_matched?: Maybe<LinerPanel_If_Matched>;
  objects: Array<LinerPanel_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_LinerPanelUsageArgs = {
  if_matched?: Maybe<LinerPanelUsage_If_Matched>;
  objects: Array<LinerPanelUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_LinerPanelUsage_OneArgs = {
  if_matched?: Maybe<LinerPanelUsage_If_Matched>;
  object: LinerPanelUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_LinerPanel_OneArgs = {
  if_matched?: Maybe<LinerPanel_If_Matched>;
  object: LinerPanel_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_LiquidAppliedSubassemblyUsageArgs = {
  if_matched?: Maybe<LiquidAppliedSubassemblyUsage_If_Matched>;
  objects: Array<LiquidAppliedSubassemblyUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_LiquidAppliedSubassemblyUsage_OneArgs = {
  if_matched?: Maybe<LiquidAppliedSubassemblyUsage_If_Matched>;
  object: LiquidAppliedSubassemblyUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_LiquidAppliedUsageArgs = {
  if_matched?: Maybe<LiquidAppliedUsage_If_Matched>;
  objects: Array<LiquidAppliedUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_LiquidAppliedUsage_OneArgs = {
  if_matched?: Maybe<LiquidAppliedUsage_If_Matched>;
  object: LiquidAppliedUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Load_SearchLayerUsage_DeckFiberReinforcedPlasticArgs = {
  if_matched?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_If_Matched>;
  objects: Array<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Load_SearchLayerUsage_DeckFiberReinforcedPlastic_OneArgs = {
  if_matched?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_If_Matched>;
  object: Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_ApprovedUseArgs = {
  if_matched?: Maybe<Md_ApprovedUse_If_Matched>;
  objects: Array<Md_ApprovedUse_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_ApprovedUseAttributesArgs = {
  if_matched?: Maybe<Md_ApprovedUseAttributes_If_Matched>;
  objects: Array<Md_ApprovedUseAttributes_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_ApprovedUseAttributes_OneArgs = {
  if_matched?: Maybe<Md_ApprovedUseAttributes_If_Matched>;
  object: Md_ApprovedUseAttributes_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_ApprovedUse_OneArgs = {
  if_matched?: Maybe<Md_ApprovedUse_If_Matched>;
  object: Md_ApprovedUse_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_AttributeBaseArgs = {
  if_matched?: Maybe<Md_AttributeBase_If_Matched>;
  objects: Array<Md_AttributeBase_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_AttributeBase_OneArgs = {
  if_matched?: Maybe<Md_AttributeBase_If_Matched>;
  object: Md_AttributeBase_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_BooleanAttributeArgs = {
  if_matched?: Maybe<Md_BooleanAttribute_If_Matched>;
  objects: Array<Md_BooleanAttribute_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_BooleanAttribute_OneArgs = {
  if_matched?: Maybe<Md_BooleanAttribute_If_Matched>;
  object: Md_BooleanAttribute_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_BooleanDisplayOptionArgs = {
  if_matched?: Maybe<Md_BooleanDisplayOption_If_Matched>;
  objects: Array<Md_BooleanDisplayOption_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_BooleanDisplayOption_OneArgs = {
  if_matched?: Maybe<Md_BooleanDisplayOption_If_Matched>;
  object: Md_BooleanDisplayOption_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_CatalogArgs = {
  if_matched?: Maybe<Md_Catalog_If_Matched>;
  objects: Array<Md_Catalog_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_Catalog_OneArgs = {
  if_matched?: Maybe<Md_Catalog_If_Matched>;
  object: Md_Catalog_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_CodeTableAttributeArgs = {
  if_matched?: Maybe<Md_CodeTableAttribute_If_Matched>;
  objects: Array<Md_CodeTableAttribute_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_CodeTableAttribute_OneArgs = {
  if_matched?: Maybe<Md_CodeTableAttribute_If_Matched>;
  object: Md_CodeTableAttribute_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_CodeTablesArgs = {
  if_matched?: Maybe<Md_CodeTables_If_Matched>;
  objects: Array<Md_CodeTables_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_CodeTables_OneArgs = {
  if_matched?: Maybe<Md_CodeTables_If_Matched>;
  object: Md_CodeTables_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_ComponentUsageArgs = {
  if_matched?: Maybe<Md_ComponentUsage_If_Matched>;
  objects: Array<Md_ComponentUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_ComponentUsageAttributesArgs = {
  if_matched?: Maybe<Md_ComponentUsageAttributes_If_Matched>;
  objects: Array<Md_ComponentUsageAttributes_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_ComponentUsageAttributes_OneArgs = {
  if_matched?: Maybe<Md_ComponentUsageAttributes_If_Matched>;
  object: Md_ComponentUsageAttributes_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_ComponentUsage_OneArgs = {
  if_matched?: Maybe<Md_ComponentUsage_If_Matched>;
  object: Md_ComponentUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_MeasureArgs = {
  if_matched?: Maybe<Md_Measure_If_Matched>;
  objects: Array<Md_Measure_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_Measure_OneArgs = {
  if_matched?: Maybe<Md_Measure_If_Matched>;
  object: Md_Measure_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_MeasurementAttributeArgs = {
  if_matched?: Maybe<Md_MeasurementAttribute_If_Matched>;
  objects: Array<Md_MeasurementAttribute_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_MeasurementAttribute_OneArgs = {
  if_matched?: Maybe<Md_MeasurementAttribute_If_Matched>;
  object: Md_MeasurementAttribute_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_MeasurementDisplayOptionArgs = {
  if_matched?: Maybe<Md_MeasurementDisplayOption_If_Matched>;
  objects: Array<Md_MeasurementDisplayOption_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_MeasurementDisplayOption_OneArgs = {
  if_matched?: Maybe<Md_MeasurementDisplayOption_If_Matched>;
  object: Md_MeasurementDisplayOption_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_MultivalueAttributeArgs = {
  if_matched?: Maybe<Md_MultivalueAttribute_If_Matched>;
  objects: Array<Md_MultivalueAttribute_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_MultivalueAttribute_OneArgs = {
  if_matched?: Maybe<Md_MultivalueAttribute_If_Matched>;
  object: Md_MultivalueAttribute_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_MultivalueDisplayOptionArgs = {
  if_matched?: Maybe<Md_MultivalueDisplayOption_If_Matched>;
  objects: Array<Md_MultivalueDisplayOption_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_MultivalueDisplayOption_OneArgs = {
  if_matched?: Maybe<Md_MultivalueDisplayOption_If_Matched>;
  object: Md_MultivalueDisplayOption_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_ProductCategoryArgs = {
  if_matched?: Maybe<Md_ProductCategory_If_Matched>;
  objects: Array<Md_ProductCategory_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_ProductCategory_OneArgs = {
  if_matched?: Maybe<Md_ProductCategory_If_Matched>;
  object: Md_ProductCategory_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Md_ProductSubcategoryArgs = {
  if_matched?: Maybe<Md_ProductSubcategory_If_Matched>;
  objects: Array<Md_ProductSubcategory_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Md_ProductSubcategory_OneArgs = {
  if_matched?: Maybe<Md_ProductSubcategory_If_Matched>;
  object: Md_ProductSubcategory_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_MaintenanceCoatingArgs = {
  if_matched?: Maybe<MaintenanceCoating_If_Matched>;
  objects: Array<MaintenanceCoating_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_MaintenanceCoatingSubstrateArgs = {
  if_matched?: Maybe<MaintenanceCoatingSubstrate_If_Matched>;
  objects: Array<MaintenanceCoatingSubstrate_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_MaintenanceCoatingSubstrate_OneArgs = {
  if_matched?: Maybe<MaintenanceCoatingSubstrate_If_Matched>;
  object: MaintenanceCoatingSubstrate_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_MaintenanceCoatingUsageArgs = {
  if_matched?: Maybe<MaintenanceCoatingUsage_If_Matched>;
  objects: Array<MaintenanceCoatingUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_MaintenanceCoatingUsage_OneArgs = {
  if_matched?: Maybe<MaintenanceCoatingUsage_If_Matched>;
  object: MaintenanceCoatingUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_MaintenanceCoating_OneArgs = {
  if_matched?: Maybe<MaintenanceCoating_If_Matched>;
  object: MaintenanceCoating_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ManufacturedProductArgs = {
  if_matched?: Maybe<ManufacturedProduct_If_Matched>;
  objects: Array<ManufacturedProduct_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ManufacturedProduct_OneArgs = {
  if_matched?: Maybe<ManufacturedProduct_If_Matched>;
  object: ManufacturedProduct_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ManufacturerArgs = {
  if_matched?: Maybe<Manufacturer_If_Matched>;
  objects: Array<Manufacturer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ManufacturerChangeHistoryArgs = {
  if_matched?: Maybe<ManufacturerChangeHistory_If_Matched>;
  objects: Array<ManufacturerChangeHistory_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ManufacturerChangeHistory_OneArgs = {
  if_matched?: Maybe<ManufacturerChangeHistory_If_Matched>;
  object: ManufacturerChangeHistory_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ManufacturerListArgs = {
  if_matched?: Maybe<ManufacturerList_If_Matched>;
  objects: Array<ManufacturerList_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ManufacturerList_OneArgs = {
  if_matched?: Maybe<ManufacturerList_If_Matched>;
  object: ManufacturerList_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Manufacturer_OneArgs = {
  if_matched?: Maybe<Manufacturer_If_Matched>;
  object: Manufacturer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_MultiplyCoverConfigArgs = {
  if_matched?: Maybe<MultiplyCoverConfig_If_Matched>;
  objects: Array<MultiplyCoverConfig_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_MultiplyCoverConfig_OneArgs = {
  if_matched?: Maybe<MultiplyCoverConfig_If_Matched>;
  object: MultiplyCoverConfig_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_MultiplyCoverSubassemblyUsageArgs = {
  if_matched?: Maybe<MultiplyCoverSubassemblyUsage_If_Matched>;
  objects: Array<MultiplyCoverSubassemblyUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_MultiplyCoverSubassemblyUsage_OneArgs = {
  if_matched?: Maybe<MultiplyCoverSubassemblyUsage_If_Matched>;
  object: MultiplyCoverSubassemblyUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_NavAssemblyArgs = {
  if_matched?: Maybe<NavAssembly_If_Matched>;
  objects: Array<NavAssembly_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_NavAssemblyMultiplyInfoArgs = {
  if_matched?: Maybe<NavAssemblyMultiplyInfo_If_Matched>;
  objects: Array<NavAssemblyMultiplyInfo_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_NavAssemblyMultiplyInfo_OneArgs = {
  if_matched?: Maybe<NavAssemblyMultiplyInfo_If_Matched>;
  object: NavAssemblyMultiplyInfo_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_NavAssembly_OneArgs = {
  if_matched?: Maybe<NavAssembly_If_Matched>;
  object: NavAssembly_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_NavLayerArgs = {
  if_matched?: Maybe<NavLayer_If_Matched>;
  objects: Array<NavLayer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_NavLayer_OneArgs = {
  if_matched?: Maybe<NavLayer_If_Matched>;
  object: NavLayer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_NavMultiplyCoverConfigArgs = {
  if_matched?: Maybe<NavMultiplyCoverConfig_If_Matched>;
  objects: Array<NavMultiplyCoverConfig_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_NavMultiplyCoverConfig_OneArgs = {
  if_matched?: Maybe<NavMultiplyCoverConfig_If_Matched>;
  object: NavMultiplyCoverConfig_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_NavSecurementLayerArgs = {
  if_matched?: Maybe<NavSecurementLayer_If_Matched>;
  objects: Array<NavSecurementLayer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_NavSecurementLayer_OneArgs = {
  if_matched?: Maybe<NavSecurementLayer_If_Matched>;
  object: NavSecurementLayer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_NavSecurementUsageArgs = {
  if_matched?: Maybe<NavSecurementUsage_If_Matched>;
  objects: Array<NavSecurementUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_NavSecurementUsage_OneArgs = {
  if_matched?: Maybe<NavSecurementUsage_If_Matched>;
  object: NavSecurementUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_NavUsageArgs = {
  if_matched?: Maybe<NavUsage_If_Matched>;
  objects: Array<NavUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_NavUsageSaArgs = {
  if_matched?: Maybe<NavUsageSa_If_Matched>;
  objects: Array<NavUsageSa_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_NavUsageSa_OneArgs = {
  if_matched?: Maybe<NavUsageSa_If_Matched>;
  object: NavUsageSa_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_NavUsage_OneArgs = {
  if_matched?: Maybe<NavUsage_If_Matched>;
  object: NavUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Nav_SinglePlyBondingAdhesivesArgs = {
  if_matched?: Maybe<Nav_SinglePlyBondingAdhesives_If_Matched>;
  objects: Array<Nav_SinglePlyBondingAdhesives_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Nav_SinglePlyBondingAdhesives_OneArgs = {
  if_matched?: Maybe<Nav_SinglePlyBondingAdhesives_If_Matched>;
  object: Nav_SinglePlyBondingAdhesives_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Nav_SinglePlyLapAdhesivesArgs = {
  if_matched?: Maybe<Nav_SinglePlyLapAdhesives_If_Matched>;
  objects: Array<Nav_SinglePlyLapAdhesives_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Nav_SinglePlyLapAdhesives_OneArgs = {
  if_matched?: Maybe<Nav_SinglePlyLapAdhesives_If_Matched>;
  object: Nav_SinglePlyLapAdhesives_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_NextAssemblyNumArgs = {
  if_matched?: Maybe<NextAssemblyNum_If_Matched>;
  objects: Array<NextAssemblyNum_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_NextAssemblyNum_OneArgs = {
  if_matched?: Maybe<NextAssemblyNum_If_Matched>;
  object: NextAssemblyNum_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_NoteArgs = {
  if_matched?: Maybe<Note_If_Matched>;
  objects: Array<Note_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Note_OneArgs = {
  if_matched?: Maybe<Note_If_Matched>;
  object: Note_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_OtherPlyArgs = {
  if_matched?: Maybe<OtherPly_If_Matched>;
  objects: Array<OtherPly_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_OtherPlyUsageArgs = {
  if_matched?: Maybe<OtherPlyUsage_If_Matched>;
  objects: Array<OtherPlyUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_OtherPlyUsage_OneArgs = {
  if_matched?: Maybe<OtherPlyUsage_If_Matched>;
  object: OtherPlyUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_OtherPly_OneArgs = {
  if_matched?: Maybe<OtherPly_If_Matched>;
  object: OtherPly_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_PreassembledFasteningArgs = {
  if_matched?: Maybe<PreassembledFastening_If_Matched>;
  objects: Array<PreassembledFastening_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_PreassembledFasteningUsageArgs = {
  if_matched?: Maybe<PreassembledFasteningUsage_If_Matched>;
  objects: Array<PreassembledFasteningUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_PreassembledFasteningUsage_OneArgs = {
  if_matched?: Maybe<PreassembledFasteningUsage_If_Matched>;
  object: PreassembledFasteningUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_PreassembledFastening_OneArgs = {
  if_matched?: Maybe<PreassembledFastening_If_Matched>;
  object: PreassembledFastening_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_PrivateLabelArgs = {
  if_matched?: Maybe<PrivateLabel_If_Matched>;
  objects: Array<PrivateLabel_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_PrivateLabel_OneArgs = {
  if_matched?: Maybe<PrivateLabel_If_Matched>;
  object: PrivateLabel_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_PrivateLabelerArgs = {
  if_matched?: Maybe<PrivateLabeler_If_Matched>;
  objects: Array<PrivateLabeler_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_PrivateLabeler_OneArgs = {
  if_matched?: Maybe<PrivateLabeler_If_Matched>;
  object: PrivateLabeler_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ProductDependenciesArgs = {
  if_matched?: Maybe<ProductDependencies_If_Matched>;
  objects: Array<ProductDependencies_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ProductDependencies_OneArgs = {
  if_matched?: Maybe<ProductDependencies_If_Matched>;
  object: ProductDependencies_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ProductsBySubcategoryArgs = {
  if_matched?: Maybe<ProductsBySubcategory_If_Matched>;
  objects: Array<ProductsBySubcategory_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ProductsBySubcategory_OneArgs = {
  if_matched?: Maybe<ProductsBySubcategory_If_Matched>;
  object: ProductsBySubcategory_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_AdhesiveTapeWidthArgs = {
  if_matched?: Maybe<R_AdhesiveTapeWidth_If_Matched>;
  objects: Array<R_AdhesiveTapeWidth_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_AdhesiveTapeWidth_OneArgs = {
  if_matched?: Maybe<R_AdhesiveTapeWidth_If_Matched>;
  object: R_AdhesiveTapeWidth_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_ApprovedUse_SubcategoryArgs = {
  if_matched?: Maybe<R_ApprovedUse_Subcategory_If_Matched>;
  objects: Array<R_ApprovedUse_Subcategory_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_ApprovedUse_Subcategory_OneArgs = {
  if_matched?: Maybe<R_ApprovedUse_Subcategory_If_Matched>;
  object: R_ApprovedUse_Subcategory_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_ComponentType_ApprovedUseArgs = {
  if_matched?: Maybe<R_ComponentType_ApprovedUse_If_Matched>;
  objects: Array<R_ComponentType_ApprovedUse_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_ComponentType_ApprovedUse_OneArgs = {
  if_matched?: Maybe<R_ComponentType_ApprovedUse_If_Matched>;
  object: R_ComponentType_ApprovedUse_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_CopingFastenerArgs = {
  if_matched?: Maybe<R_CopingFastener_If_Matched>;
  objects: Array<R_CopingFastener_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_CopingFastener_OneArgs = {
  if_matched?: Maybe<R_CopingFastener_If_Matched>;
  object: R_CopingFastener_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_Country_StateProvinceArgs = {
  if_matched?: Maybe<R_Country_StateProvince_If_Matched>;
  objects: Array<R_Country_StateProvince_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_Country_StateProvince_OneArgs = {
  if_matched?: Maybe<R_Country_StateProvince_If_Matched>;
  object: R_Country_StateProvince_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_ExpansionJoinFastenerArgs = {
  if_matched?: Maybe<R_ExpansionJoinFastener_If_Matched>;
  objects: Array<R_ExpansionJoinFastener_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_ExpansionJoinFastener_OneArgs = {
  if_matched?: Maybe<R_ExpansionJoinFastener_If_Matched>;
  object: R_ExpansionJoinFastener_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_FasciaComponentFastenerComponentArgs = {
  if_matched?: Maybe<R_FasciaComponentFastenerComponent_If_Matched>;
  objects: Array<R_FasciaComponentFastenerComponent_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_FasciaComponentFastenerComponent_OneArgs = {
  if_matched?: Maybe<R_FasciaComponentFastenerComponent_If_Matched>;
  object: R_FasciaComponentFastenerComponent_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_GutterFastenersArgs = {
  if_matched?: Maybe<R_GutterFasteners_If_Matched>;
  objects: Array<R_GutterFasteners_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_GutterFasteners_OneArgs = {
  if_matched?: Maybe<R_GutterFasteners_If_Matched>;
  object: R_GutterFasteners_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_LinerPanelMaterialFiberReinforcedPlasticDeckArgs = {
  if_matched?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_If_Matched>;
  objects: Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_LinerPanelMaterialFiberReinforcedPlasticDeck_OneArgs = {
  if_matched?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_If_Matched>;
  object: R_LinerPanelMaterialFiberReinforcedPlasticDeck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_MaintenanceCoatingSubstrateArgs = {
  if_matched?: Maybe<R_MaintenanceCoatingSubstrate_If_Matched>;
  objects: Array<R_MaintenanceCoatingSubstrate_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_MaintenanceCoatingSubstrate_OneArgs = {
  if_matched?: Maybe<R_MaintenanceCoatingSubstrate_If_Matched>;
  object: R_MaintenanceCoatingSubstrate_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_NavAssembly_NavMultiplyCoverConfigArgs = {
  if_matched?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_If_Matched>;
  objects: Array<R_NavAssembly_NavMultiplyCoverConfig_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_NavAssembly_NavMultiplyCoverConfig_OneArgs = {
  if_matched?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_If_Matched>;
  object: R_NavAssembly_NavMultiplyCoverConfig_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_ProjectContactArgs = {
  if_matched?: Maybe<R_ProjectContact_If_Matched>;
  objects: Array<R_ProjectContact_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_ProjectContact_OneArgs = {
  if_matched?: Maybe<R_ProjectContact_If_Matched>;
  object: R_ProjectContact_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_ProjectNoteArgs = {
  if_matched?: Maybe<R_ProjectNote_If_Matched>;
  objects: Array<R_ProjectNote_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_ProjectNote_OneArgs = {
  if_matched?: Maybe<R_ProjectNote_If_Matched>;
  object: R_ProjectNote_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_RegletFastenerArgs = {
  if_matched?: Maybe<R_RegletFastener_If_Matched>;
  objects: Array<R_RegletFastener_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_RegletFastener_OneArgs = {
  if_matched?: Maybe<R_RegletFastener_If_Matched>;
  object: R_RegletFastener_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_RoofAreaContactArgs = {
  if_matched?: Maybe<R_RoofAreaContact_If_Matched>;
  objects: Array<R_RoofAreaContact_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_RoofAreaContact_OneArgs = {
  if_matched?: Maybe<R_RoofAreaContact_If_Matched>;
  object: R_RoofAreaContact_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_RoofAreaNoteArgs = {
  if_matched?: Maybe<R_RoofAreaNote_If_Matched>;
  objects: Array<R_RoofAreaNote_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_RoofAreaNote_OneArgs = {
  if_matched?: Maybe<R_RoofAreaNote_If_Matched>;
  object: R_RoofAreaNote_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_RoofAreaScenarioArgs = {
  if_matched?: Maybe<R_RoofAreaScenario_If_Matched>;
  objects: Array<R_RoofAreaScenario_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_RoofAreaScenario_OneArgs = {
  if_matched?: Maybe<R_RoofAreaScenario_If_Matched>;
  object: R_RoofAreaScenario_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_ScenarioNoteArgs = {
  if_matched?: Maybe<R_ScenarioNote_If_Matched>;
  objects: Array<R_ScenarioNote_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_ScenarioNote_OneArgs = {
  if_matched?: Maybe<R_ScenarioNote_If_Matched>;
  object: R_ScenarioNote_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_SinglePly_BondingAdhesivesArgs = {
  if_matched?: Maybe<R_SinglePly_BondingAdhesives_If_Matched>;
  objects: Array<R_SinglePly_BondingAdhesives_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_SinglePly_BondingAdhesives_OneArgs = {
  if_matched?: Maybe<R_SinglePly_BondingAdhesives_If_Matched>;
  object: R_SinglePly_BondingAdhesives_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_SinglePly_LapAdhesivesArgs = {
  if_matched?: Maybe<R_SinglePly_LapAdhesives_If_Matched>;
  objects: Array<R_SinglePly_LapAdhesives_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_SinglePly_LapAdhesives_OneArgs = {
  if_matched?: Maybe<R_SinglePly_LapAdhesives_If_Matched>;
  object: R_SinglePly_LapAdhesives_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_Subassembly_MultiplyCoverConfigArgs = {
  if_matched?: Maybe<R_Subassembly_MultiplyCoverConfig_If_Matched>;
  objects: Array<R_Subassembly_MultiplyCoverConfig_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_Subassembly_MultiplyCoverConfig_OneArgs = {
  if_matched?: Maybe<R_Subassembly_MultiplyCoverConfig_If_Matched>;
  object: R_Subassembly_MultiplyCoverConfig_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_R_UserContactArgs = {
  if_matched?: Maybe<R_UserContact_If_Matched>;
  objects: Array<R_UserContact_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_R_UserContact_OneArgs = {
  if_matched?: Maybe<R_UserContact_If_Matched>;
  object: R_UserContact_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_RegletArgs = {
  if_matched?: Maybe<Reglet_If_Matched>;
  objects: Array<Reglet_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Reglet_OneArgs = {
  if_matched?: Maybe<Reglet_If_Matched>;
  object: Reglet_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ReviewerWorkspaceArgs = {
  if_matched?: Maybe<ReviewerWorkspace_If_Matched>;
  objects: Array<ReviewerWorkspace_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewerWorkspaceDataArgs = {
  if_matched?: Maybe<ReviewerWorkspaceData_If_Matched>;
  objects: Array<ReviewerWorkspaceData_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewerWorkspaceData_OneArgs = {
  if_matched?: Maybe<ReviewerWorkspaceData_If_Matched>;
  object: ReviewerWorkspaceData_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ReviewerWorkspace_OneArgs = {
  if_matched?: Maybe<ReviewerWorkspace_If_Matched>;
  object: ReviewerWorkspace_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_RoofAreaArgs = {
  if_matched?: Maybe<RoofArea_If_Matched>;
  objects: Array<RoofArea_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_RoofAreaWindPressureArgs = {
  if_matched?: Maybe<RoofAreaWindPressure_If_Matched>;
  objects: Array<RoofAreaWindPressure_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_RoofAreaWindPressure_OneArgs = {
  if_matched?: Maybe<RoofAreaWindPressure_If_Matched>;
  object: RoofAreaWindPressure_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_RoofArea_OneArgs = {
  if_matched?: Maybe<RoofArea_If_Matched>;
  object: RoofArea_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_RoofAssemblyArgs = {
  if_matched?: Maybe<RoofAssembly_If_Matched>;
  objects: Array<RoofAssembly_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_RoofAssemblyDelistDataArgs = {
  if_matched?: Maybe<RoofAssemblyDelistData_If_Matched>;
  objects: Array<RoofAssemblyDelistData_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_RoofAssemblyDelistData_OneArgs = {
  if_matched?: Maybe<RoofAssemblyDelistData_If_Matched>;
  object: RoofAssemblyDelistData_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_RoofAssemblyLayersArgs = {
  if_matched?: Maybe<RoofAssemblyLayers_If_Matched>;
  objects: Array<RoofAssemblyLayers_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_RoofAssemblyLayers_OneArgs = {
  if_matched?: Maybe<RoofAssemblyLayers_If_Matched>;
  object: RoofAssemblyLayers_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_RoofAssembly_OneArgs = {
  if_matched?: Maybe<RoofAssembly_If_Matched>;
  object: RoofAssembly_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_RoofProjectArgs = {
  if_matched?: Maybe<RoofProject_If_Matched>;
  objects: Array<RoofProject_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_RoofProject_OneArgs = {
  if_matched?: Maybe<RoofProject_If_Matched>;
  object: RoofProject_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ScenarioArgs = {
  if_matched?: Maybe<Scenario_If_Matched>;
  objects: Array<Scenario_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ScenarioAlertArgs = {
  if_matched?: Maybe<ScenarioAlert_If_Matched>;
  objects: Array<ScenarioAlert_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ScenarioAlert_OneArgs = {
  if_matched?: Maybe<ScenarioAlert_If_Matched>;
  object: ScenarioAlert_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ScenarioLayerArgs = {
  if_matched?: Maybe<ScenarioLayer_If_Matched>;
  objects: Array<ScenarioLayer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ScenarioLayer_OneArgs = {
  if_matched?: Maybe<ScenarioLayer_If_Matched>;
  object: ScenarioLayer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ScenarioUsageArgs = {
  if_matched?: Maybe<ScenarioUsage_If_Matched>;
  objects: Array<ScenarioUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ScenarioUsage_OneArgs = {
  if_matched?: Maybe<ScenarioUsage_If_Matched>;
  object: ScenarioUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Scenario_OneArgs = {
  if_matched?: Maybe<Scenario_If_Matched>;
  object: Scenario_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SeamPrimerArgs = {
  if_matched?: Maybe<SeamPrimer_If_Matched>;
  objects: Array<SeamPrimer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SeamPrimerUsageArgs = {
  if_matched?: Maybe<SeamPrimerUsage_If_Matched>;
  objects: Array<SeamPrimerUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SeamPrimerUsage_OneArgs = {
  if_matched?: Maybe<SeamPrimerUsage_If_Matched>;
  object: SeamPrimerUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SeamPrimer_OneArgs = {
  if_matched?: Maybe<SeamPrimer_If_Matched>;
  object: SeamPrimer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SeamTapeSystemUsageArgs = {
  if_matched?: Maybe<SeamTapeSystemUsage_If_Matched>;
  objects: Array<SeamTapeSystemUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SeamTapeSystemUsage_OneArgs = {
  if_matched?: Maybe<SeamTapeSystemUsage_If_Matched>;
  object: SeamTapeSystemUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SeamWashArgs = {
  if_matched?: Maybe<SeamWash_If_Matched>;
  objects: Array<SeamWash_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SeamWashUsageArgs = {
  if_matched?: Maybe<SeamWashUsage_If_Matched>;
  objects: Array<SeamWashUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SeamWashUsage_OneArgs = {
  if_matched?: Maybe<SeamWashUsage_If_Matched>;
  object: SeamWashUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SeamWash_OneArgs = {
  if_matched?: Maybe<SeamWash_If_Matched>;
  object: SeamWash_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SearchNavAssembly_ManufacturerProductArgs = {
  if_matched?: Maybe<SearchNavAssembly_ManufacturerProduct_If_Matched>;
  objects: Array<SearchNavAssembly_ManufacturerProduct_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SearchNavAssembly_ManufacturerProduct_OneArgs = {
  if_matched?: Maybe<SearchNavAssembly_ManufacturerProduct_If_Matched>;
  object: SearchNavAssembly_ManufacturerProduct_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SecurementLayerArgs = {
  if_matched?: Maybe<SecurementLayer_If_Matched>;
  objects: Array<SecurementLayer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SecurementLayerUsageArgs = {
  if_matched?: Maybe<SecurementLayerUsage_If_Matched>;
  objects: Array<SecurementLayerUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SecurementLayerUsage_OneArgs = {
  if_matched?: Maybe<SecurementLayerUsage_If_Matched>;
  object: SecurementLayerUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SecurementLayer_OneArgs = {
  if_matched?: Maybe<SecurementLayer_If_Matched>;
  object: SecurementLayer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SelfSecurementUsageArgs = {
  if_matched?: Maybe<SelfSecurementUsage_If_Matched>;
  objects: Array<SelfSecurementUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SelfSecurementUsage_OneArgs = {
  if_matched?: Maybe<SelfSecurementUsage_If_Matched>;
  object: SelfSecurementUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SeparatorSheetArgs = {
  if_matched?: Maybe<SeparatorSheet_If_Matched>;
  objects: Array<SeparatorSheet_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SeparatorSheetUsageArgs = {
  if_matched?: Maybe<SeparatorSheetUsage_If_Matched>;
  objects: Array<SeparatorSheetUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SeparatorSheetUsage_OneArgs = {
  if_matched?: Maybe<SeparatorSheetUsage_If_Matched>;
  object: SeparatorSheetUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SeparatorSheet_OneArgs = {
  if_matched?: Maybe<SeparatorSheet_If_Matched>;
  object: SeparatorSheet_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ShinglesArgs = {
  if_matched?: Maybe<Shingles_If_Matched>;
  objects: Array<Shingles_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Shingles_OneArgs = {
  if_matched?: Maybe<Shingles_If_Matched>;
  object: Shingles_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SinglePlyCoverArgs = {
  if_matched?: Maybe<SinglePlyCover_If_Matched>;
  objects: Array<SinglePlyCover_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SinglePlyCover_OneArgs = {
  if_matched?: Maybe<SinglePlyCover_If_Matched>;
  object: SinglePlyCover_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SinglePlyUsageArgs = {
  if_matched?: Maybe<SinglePlyUsage_If_Matched>;
  objects: Array<SinglePlyUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SinglePlyUsage_OneArgs = {
  if_matched?: Maybe<SinglePlyUsage_If_Matched>;
  object: SinglePlyUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SprayFoamInsulationUsageArgs = {
  if_matched?: Maybe<SprayFoamInsulationUsage_If_Matched>;
  objects: Array<SprayFoamInsulationUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SprayFoamInsulationUsage_OneArgs = {
  if_matched?: Maybe<SprayFoamInsulationUsage_If_Matched>;
  object: SprayFoamInsulationUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SprayedFoamInsulationArgs = {
  if_matched?: Maybe<SprayedFoamInsulation_If_Matched>;
  objects: Array<SprayedFoamInsulation_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SprayedFoamInsulation_OneArgs = {
  if_matched?: Maybe<SprayedFoamInsulation_If_Matched>;
  object: SprayedFoamInsulation_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_StandingLapSeamCoverArgs = {
  if_matched?: Maybe<StandingLapSeamCover_If_Matched>;
  objects: Array<StandingLapSeamCover_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_StandingLapSeamCover_OneArgs = {
  if_matched?: Maybe<StandingLapSeamCover_If_Matched>;
  object: StandingLapSeamCover_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_StandingLapSeamUsageArgs = {
  if_matched?: Maybe<StandingLapSeamUsage_If_Matched>;
  objects: Array<StandingLapSeamUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_StandingLapSeamUsage_OneArgs = {
  if_matched?: Maybe<StandingLapSeamUsage_If_Matched>;
  object: StandingLapSeamUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SteelDeckArgs = {
  if_matched?: Maybe<SteelDeck_If_Matched>;
  objects: Array<SteelDeck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SteelDeckLayerArgs = {
  if_matched?: Maybe<SteelDeckLayer_If_Matched>;
  objects: Array<SteelDeckLayer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SteelDeckLayer_OneArgs = {
  if_matched?: Maybe<SteelDeckLayer_If_Matched>;
  object: SteelDeckLayer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SteelDeckUsageArgs = {
  if_matched?: Maybe<SteelDeckUsage_If_Matched>;
  objects: Array<SteelDeckUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SteelDeckUsage_OneArgs = {
  if_matched?: Maybe<SteelDeckUsage_If_Matched>;
  object: SteelDeckUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SteelDeck_OneArgs = {
  if_matched?: Maybe<SteelDeck_If_Matched>;
  object: SteelDeck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_StressPlateArgs = {
  if_matched?: Maybe<StressPlate_If_Matched>;
  objects: Array<StressPlate_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_StressPlateUsageArgs = {
  if_matched?: Maybe<StressPlateUsage_If_Matched>;
  objects: Array<StressPlateUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_StressPlateUsage_OneArgs = {
  if_matched?: Maybe<StressPlateUsage_If_Matched>;
  object: StressPlateUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_StressPlate_OneArgs = {
  if_matched?: Maybe<StressPlate_If_Matched>;
  object: StressPlate_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_StructuralConcreteLayerArgs = {
  if_matched?: Maybe<StructuralConcreteLayer_If_Matched>;
  objects: Array<StructuralConcreteLayer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_StructuralConcreteLayer_OneArgs = {
  if_matched?: Maybe<StructuralConcreteLayer_If_Matched>;
  object: StructuralConcreteLayer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_StructureLayerArgs = {
  if_matched?: Maybe<StructureLayer_If_Matched>;
  objects: Array<StructureLayer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_StructureLayer_OneArgs = {
  if_matched?: Maybe<StructureLayer_If_Matched>;
  object: StructureLayer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_StructuredSystemArgs = {
  if_matched?: Maybe<StructuredSystem_If_Matched>;
  objects: Array<StructuredSystem_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_StructuredSystemProductsArgs = {
  if_matched?: Maybe<StructuredSystemProducts_If_Matched>;
  objects: Array<StructuredSystemProducts_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_StructuredSystemProducts_OneArgs = {
  if_matched?: Maybe<StructuredSystemProducts_If_Matched>;
  object: StructuredSystemProducts_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_StructuredSystem_OneArgs = {
  if_matched?: Maybe<StructuredSystem_If_Matched>;
  object: StructuredSystem_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SubassemblyArgs = {
  if_matched?: Maybe<Subassembly_If_Matched>;
  objects: Array<Subassembly_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SubassemblyDelistDataArgs = {
  if_matched?: Maybe<SubassemblyDelistData_If_Matched>;
  objects: Array<SubassemblyDelistData_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SubassemblyDelistData_OneArgs = {
  if_matched?: Maybe<SubassemblyDelistData_If_Matched>;
  object: SubassemblyDelistData_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SubassemblyE108RatingArgs = {
  if_matched?: Maybe<SubassemblyE108Rating_If_Matched>;
  objects: Array<SubassemblyE108Rating_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SubassemblyE108Rating_OneArgs = {
  if_matched?: Maybe<SubassemblyE108Rating_If_Matched>;
  object: SubassemblyE108Rating_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SubassemblyLayersArgs = {
  if_matched?: Maybe<SubassemblyLayers_If_Matched>;
  objects: Array<SubassemblyLayers_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SubassemblyLayers_OneArgs = {
  if_matched?: Maybe<SubassemblyLayers_If_Matched>;
  object: SubassemblyLayers_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SubassemblyMultiplyConfigArgs = {
  if_matched?: Maybe<SubassemblyMultiplyConfig_If_Matched>;
  objects: Array<SubassemblyMultiplyConfig_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SubassemblyMultiplyConfig_OneArgs = {
  if_matched?: Maybe<SubassemblyMultiplyConfig_If_Matched>;
  object: SubassemblyMultiplyConfig_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subassembly_OneArgs = {
  if_matched?: Maybe<Subassembly_If_Matched>;
  object: Subassembly_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_AdhesiveArgs = {
  if_matched?: Maybe<Subcat_Adhesive_If_Matched>;
  objects: Array<Subcat_Adhesive_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_Adhesive_OneArgs = {
  if_matched?: Maybe<Subcat_Adhesive_If_Matched>;
  object: Subcat_Adhesive_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_AirRetarderArgs = {
  if_matched?: Maybe<Subcat_AirRetarder_If_Matched>;
  objects: Array<Subcat_AirRetarder_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_AirRetarder_OneArgs = {
  if_matched?: Maybe<Subcat_AirRetarder_If_Matched>;
  object: Subcat_AirRetarder_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_BaseSheetArgs = {
  if_matched?: Maybe<Subcat_BaseSheet_If_Matched>;
  objects: Array<Subcat_BaseSheet_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_BaseSheet_OneArgs = {
  if_matched?: Maybe<Subcat_BaseSheet_If_Matched>;
  object: Subcat_BaseSheet_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_BattBlanketArgs = {
  if_matched?: Maybe<Subcat_BattBlanket_If_Matched>;
  objects: Array<Subcat_BattBlanket_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_BattBlanket_OneArgs = {
  if_matched?: Maybe<Subcat_BattBlanket_If_Matched>;
  object: Subcat_BattBlanket_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_BoardStockArgs = {
  if_matched?: Maybe<Subcat_BoardStock_If_Matched>;
  objects: Array<Subcat_BoardStock_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_BoardStock_OneArgs = {
  if_matched?: Maybe<Subcat_BoardStock_If_Matched>;
  object: Subcat_BoardStock_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_CoatingArgs = {
  if_matched?: Maybe<Subcat_Coating_If_Matched>;
  objects: Array<Subcat_Coating_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_Coating_OneArgs = {
  if_matched?: Maybe<Subcat_Coating_If_Matched>;
  object: Subcat_Coating_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_CompositePanelArgs = {
  if_matched?: Maybe<Subcat_CompositePanel_If_Matched>;
  objects: Array<Subcat_CompositePanel_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_CompositePanel_OneArgs = {
  if_matched?: Maybe<Subcat_CompositePanel_If_Matched>;
  object: Subcat_CompositePanel_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_CoverStripArgs = {
  if_matched?: Maybe<Subcat_CoverStrip_If_Matched>;
  objects: Array<Subcat_CoverStrip_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_CoverStrip_OneArgs = {
  if_matched?: Maybe<Subcat_CoverStrip_If_Matched>;
  object: Subcat_CoverStrip_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_ExpJointArgs = {
  if_matched?: Maybe<Subcat_ExpJoint_If_Matched>;
  objects: Array<Subcat_ExpJoint_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_ExpJoint_OneArgs = {
  if_matched?: Maybe<Subcat_ExpJoint_If_Matched>;
  object: Subcat_ExpJoint_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_FasteningArgs = {
  if_matched?: Maybe<Subcat_Fastening_If_Matched>;
  objects: Array<Subcat_Fastening_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_Fastening_OneArgs = {
  if_matched?: Maybe<Subcat_Fastening_If_Matched>;
  object: Subcat_Fastening_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_FormDeckArgs = {
  if_matched?: Maybe<Subcat_FormDeck_If_Matched>;
  objects: Array<Subcat_FormDeck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_FormDeck_OneArgs = {
  if_matched?: Maybe<Subcat_FormDeck_If_Matched>;
  object: Subcat_FormDeck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_GutterArgs = {
  if_matched?: Maybe<Subcat_Gutter_If_Matched>;
  objects: Array<Subcat_Gutter_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_Gutter_OneArgs = {
  if_matched?: Maybe<Subcat_Gutter_If_Matched>;
  object: Subcat_Gutter_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_GypsumArgs = {
  if_matched?: Maybe<Subcat_Gypsum_If_Matched>;
  objects: Array<Subcat_Gypsum_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_Gypsum_OneArgs = {
  if_matched?: Maybe<Subcat_Gypsum_If_Matched>;
  object: Subcat_Gypsum_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_LwicArgs = {
  if_matched?: Maybe<Subcat_Lwic_If_Matched>;
  objects: Array<Subcat_Lwic_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_LwicDeckArgs = {
  if_matched?: Maybe<Subcat_LwicDeck_If_Matched>;
  objects: Array<Subcat_LwicDeck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_LwicDeck_OneArgs = {
  if_matched?: Maybe<Subcat_LwicDeck_If_Matched>;
  object: Subcat_LwicDeck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_Lwic_OneArgs = {
  if_matched?: Maybe<Subcat_Lwic_If_Matched>;
  object: Subcat_Lwic_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_LinerPanelArgs = {
  if_matched?: Maybe<Subcat_LinerPanel_If_Matched>;
  objects: Array<Subcat_LinerPanel_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_LinerPanel_OneArgs = {
  if_matched?: Maybe<Subcat_LinerPanel_If_Matched>;
  object: Subcat_LinerPanel_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_LiquidAppliedArgs = {
  if_matched?: Maybe<Subcat_LiquidApplied_If_Matched>;
  objects: Array<Subcat_LiquidApplied_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_LiquidApplied_OneArgs = {
  if_matched?: Maybe<Subcat_LiquidApplied_If_Matched>;
  object: Subcat_LiquidApplied_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_MaintCoatingArgs = {
  if_matched?: Maybe<Subcat_MaintCoating_If_Matched>;
  objects: Array<Subcat_MaintCoating_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_MaintCoating_OneArgs = {
  if_matched?: Maybe<Subcat_MaintCoating_If_Matched>;
  object: Subcat_MaintCoating_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_MultiplyArgs = {
  if_matched?: Maybe<Subcat_Multiply_If_Matched>;
  objects: Array<Subcat_Multiply_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_MultiplyCoverArgs = {
  if_matched?: Maybe<Subcat_MultiplyCover_If_Matched>;
  objects: Array<Subcat_MultiplyCover_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_MultiplyCover_OneArgs = {
  if_matched?: Maybe<Subcat_MultiplyCover_If_Matched>;
  object: Subcat_MultiplyCover_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_Multiply_OneArgs = {
  if_matched?: Maybe<Subcat_Multiply_If_Matched>;
  object: Subcat_Multiply_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_PerimeterFlashingArgs = {
  if_matched?: Maybe<Subcat_PerimeterFlashing_If_Matched>;
  objects: Array<Subcat_PerimeterFlashing_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_PerimeterFlashing_OneArgs = {
  if_matched?: Maybe<Subcat_PerimeterFlashing_If_Matched>;
  object: Subcat_PerimeterFlashing_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_PlasticDeckArgs = {
  if_matched?: Maybe<Subcat_PlasticDeck_If_Matched>;
  objects: Array<Subcat_PlasticDeck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_PlasticDeck_OneArgs = {
  if_matched?: Maybe<Subcat_PlasticDeck_If_Matched>;
  object: Subcat_PlasticDeck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_PrimerArgs = {
  if_matched?: Maybe<Subcat_Primer_If_Matched>;
  objects: Array<Subcat_Primer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_Primer_OneArgs = {
  if_matched?: Maybe<Subcat_Primer_If_Matched>;
  object: Subcat_Primer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_SeamingArgs = {
  if_matched?: Maybe<Subcat_Seaming_If_Matched>;
  objects: Array<Subcat_Seaming_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_Seaming_OneArgs = {
  if_matched?: Maybe<Subcat_Seaming_If_Matched>;
  object: Subcat_Seaming_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_SeparatorSheetArgs = {
  if_matched?: Maybe<Subcat_SeparatorSheet_If_Matched>;
  objects: Array<Subcat_SeparatorSheet_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_SeparatorSheet_OneArgs = {
  if_matched?: Maybe<Subcat_SeparatorSheet_If_Matched>;
  object: Subcat_SeparatorSheet_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_ShinglesArgs = {
  if_matched?: Maybe<Subcat_Shingles_If_Matched>;
  objects: Array<Subcat_Shingles_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_Shingles_OneArgs = {
  if_matched?: Maybe<Subcat_Shingles_If_Matched>;
  object: Subcat_Shingles_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_SinglePlyArgs = {
  if_matched?: Maybe<Subcat_SinglePly_If_Matched>;
  objects: Array<Subcat_SinglePly_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_SinglePlyCoverArgs = {
  if_matched?: Maybe<Subcat_SinglePlyCover_If_Matched>;
  objects: Array<Subcat_SinglePlyCover_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_SinglePlyCover_OneArgs = {
  if_matched?: Maybe<Subcat_SinglePlyCover_If_Matched>;
  object: Subcat_SinglePlyCover_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_SinglePly_OneArgs = {
  if_matched?: Maybe<Subcat_SinglePly_If_Matched>;
  object: Subcat_SinglePly_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_SprayFoamArgs = {
  if_matched?: Maybe<Subcat_SprayFoam_If_Matched>;
  objects: Array<Subcat_SprayFoam_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_SprayFoam_OneArgs = {
  if_matched?: Maybe<Subcat_SprayFoam_If_Matched>;
  object: Subcat_SprayFoam_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_StandingLapSeamArgs = {
  if_matched?: Maybe<Subcat_StandingLapSeam_If_Matched>;
  objects: Array<Subcat_StandingLapSeam_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_StandingLapSeam_OneArgs = {
  if_matched?: Maybe<Subcat_StandingLapSeam_If_Matched>;
  object: Subcat_StandingLapSeam_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_SteelDeckArgs = {
  if_matched?: Maybe<Subcat_SteelDeck_If_Matched>;
  objects: Array<Subcat_SteelDeck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_SteelDeck_OneArgs = {
  if_matched?: Maybe<Subcat_SteelDeck_If_Matched>;
  object: Subcat_SteelDeck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_SurfacingArgs = {
  if_matched?: Maybe<Subcat_Surfacing_If_Matched>;
  objects: Array<Subcat_Surfacing_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_Surfacing_OneArgs = {
  if_matched?: Maybe<Subcat_Surfacing_If_Matched>;
  object: Subcat_Surfacing_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_VaporRetarderArgs = {
  if_matched?: Maybe<Subcat_VaporRetarder_If_Matched>;
  objects: Array<Subcat_VaporRetarder_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_VaporRetarder_OneArgs = {
  if_matched?: Maybe<Subcat_VaporRetarder_If_Matched>;
  object: Subcat_VaporRetarder_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_WeldArgs = {
  if_matched?: Maybe<Subcat_Weld_If_Matched>;
  objects: Array<Subcat_Weld_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_Weld_OneArgs = {
  if_matched?: Maybe<Subcat_Weld_If_Matched>;
  object: Subcat_Weld_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_WoodArgs = {
  if_matched?: Maybe<Subcat_Wood_If_Matched>;
  objects: Array<Subcat_Wood_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_WoodFiberDeckArgs = {
  if_matched?: Maybe<Subcat_WoodFiberDeck_If_Matched>;
  objects: Array<Subcat_WoodFiberDeck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_WoodFiberDeck_OneArgs = {
  if_matched?: Maybe<Subcat_WoodFiberDeck_If_Matched>;
  object: Subcat_WoodFiberDeck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Subcat_Wood_OneArgs = {
  if_matched?: Maybe<Subcat_Wood_If_Matched>;
  object: Subcat_Wood_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SubstratePrimerArgs = {
  if_matched?: Maybe<SubstratePrimer_If_Matched>;
  objects: Array<SubstratePrimer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SubstratePrimerUsageArgs = {
  if_matched?: Maybe<SubstratePrimerUsage_If_Matched>;
  objects: Array<SubstratePrimerUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SubstratePrimerUsage_OneArgs = {
  if_matched?: Maybe<SubstratePrimerUsage_If_Matched>;
  object: SubstratePrimerUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SubstratePrimer_OneArgs = {
  if_matched?: Maybe<SubstratePrimer_If_Matched>;
  object: SubstratePrimer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_SurfacingArgs = {
  if_matched?: Maybe<Surfacing_If_Matched>;
  objects: Array<Surfacing_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SurfacingUsageArgs = {
  if_matched?: Maybe<SurfacingUsage_If_Matched>;
  objects: Array<SurfacingUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_SurfacingUsage_OneArgs = {
  if_matched?: Maybe<SurfacingUsage_If_Matched>;
  object: SurfacingUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Surfacing_OneArgs = {
  if_matched?: Maybe<Surfacing_If_Matched>;
  object: Surfacing_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_TapeWidthArgs = {
  if_matched?: Maybe<TapeWidth_If_Matched>;
  objects: Array<TapeWidth_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_TapeWidth_OneArgs = {
  if_matched?: Maybe<TapeWidth_If_Matched>;
  object: TapeWidth_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ThermalBarrierArgs = {
  if_matched?: Maybe<ThermalBarrier_If_Matched>;
  objects: Array<ThermalBarrier_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ThermalBarrierBoardSizesArgs = {
  if_matched?: Maybe<ThermalBarrierBoardSizes_If_Matched>;
  objects: Array<ThermalBarrierBoardSizes_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ThermalBarrierBoardSizes_OneArgs = {
  if_matched?: Maybe<ThermalBarrierBoardSizes_If_Matched>;
  object: ThermalBarrierBoardSizes_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ThermalBarrierFacerBottomArgs = {
  if_matched?: Maybe<ThermalBarrierFacerBottom_If_Matched>;
  objects: Array<ThermalBarrierFacerBottom_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ThermalBarrierFacerBottom_OneArgs = {
  if_matched?: Maybe<ThermalBarrierFacerBottom_If_Matched>;
  object: ThermalBarrierFacerBottom_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ThermalBarrierFacerTopArgs = {
  if_matched?: Maybe<ThermalBarrierFacerTop_If_Matched>;
  objects: Array<ThermalBarrierFacerTop_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ThermalBarrierFacerTop_OneArgs = {
  if_matched?: Maybe<ThermalBarrierFacerTop_If_Matched>;
  object: ThermalBarrierFacerTop_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ThermalBarrierUsageArgs = {
  if_matched?: Maybe<ThermalBarrierUsage_If_Matched>;
  objects: Array<ThermalBarrierUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_ThermalBarrierUsage_OneArgs = {
  if_matched?: Maybe<ThermalBarrierUsage_If_Matched>;
  object: ThermalBarrierUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ThermalBarrier_OneArgs = {
  if_matched?: Maybe<ThermalBarrier_If_Matched>;
  object: ThermalBarrier_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_TopCoatArgs = {
  if_matched?: Maybe<TopCoat_If_Matched>;
  objects: Array<TopCoat_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_TopCoatUsageArgs = {
  if_matched?: Maybe<TopCoatUsage_If_Matched>;
  objects: Array<TopCoatUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_TopCoatUsage_OneArgs = {
  if_matched?: Maybe<TopCoatUsage_If_Matched>;
  object: TopCoatUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_TopCoat_OneArgs = {
  if_matched?: Maybe<TopCoat_If_Matched>;
  object: TopCoat_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_UsageArgs = {
  if_matched?: Maybe<Usage_If_Matched>;
  objects: Array<Usage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_UsageTradeNameViewArgs = {
  if_matched?: Maybe<UsageTradeNameView_If_Matched>;
  objects: Array<UsageTradeNameView_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_UsageTradeNameView_OneArgs = {
  if_matched?: Maybe<UsageTradeNameView_If_Matched>;
  object: UsageTradeNameView_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Usage_OneArgs = {
  if_matched?: Maybe<Usage_If_Matched>;
  object: Usage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_UserReportBugArgs = {
  if_matched?: Maybe<UserReportBug_If_Matched>;
  objects: Array<UserReportBug_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_UserReportBug_OneArgs = {
  if_matched?: Maybe<UserReportBug_If_Matched>;
  object: UserReportBug_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_UserShareLinkArgs = {
  if_matched?: Maybe<UserShareLink_If_Matched>;
  objects: Array<UserShareLink_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_UserShareLink_OneArgs = {
  if_matched?: Maybe<UserShareLink_If_Matched>;
  object: UserShareLink_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VaporRetarderArgs = {
  if_matched?: Maybe<VaporRetarder_If_Matched>;
  objects: Array<VaporRetarder_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VaporRetarderUsageArgs = {
  if_matched?: Maybe<VaporRetarderUsage_If_Matched>;
  objects: Array<VaporRetarderUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VaporRetarderUsage_OneArgs = {
  if_matched?: Maybe<VaporRetarderUsage_If_Matched>;
  object: VaporRetarderUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VaporRetarder_OneArgs = {
  if_matched?: Maybe<VaporRetarder_If_Matched>;
  object: VaporRetarder_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_WeldArcUsageArgs = {
  if_matched?: Maybe<WeldArcUsage_If_Matched>;
  objects: Array<WeldArcUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_WeldArcUsage_OneArgs = {
  if_matched?: Maybe<WeldArcUsage_If_Matched>;
  object: WeldArcUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_WeldHeatUsageArgs = {
  if_matched?: Maybe<WeldHeatUsage_If_Matched>;
  objects: Array<WeldHeatUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_WeldHeatUsage_OneArgs = {
  if_matched?: Maybe<WeldHeatUsage_If_Matched>;
  object: WeldHeatUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_WeldSolventUsageArgs = {
  if_matched?: Maybe<WeldSolventUsage_If_Matched>;
  objects: Array<WeldSolventUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_WeldSolventUsage_OneArgs = {
  if_matched?: Maybe<WeldSolventUsage_If_Matched>;
  object: WeldSolventUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_WeldSystemUsageArgs = {
  if_matched?: Maybe<WeldSystemUsage_If_Matched>;
  objects: Array<WeldSystemUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_WeldSystemUsage_OneArgs = {
  if_matched?: Maybe<WeldSystemUsage_If_Matched>;
  object: WeldSystemUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_WeldTorchedUsageArgs = {
  if_matched?: Maybe<WeldTorchedUsage_If_Matched>;
  objects: Array<WeldTorchedUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_WeldTorchedUsage_OneArgs = {
  if_matched?: Maybe<WeldTorchedUsage_If_Matched>;
  object: WeldTorchedUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_WoodDeckArgs = {
  if_matched?: Maybe<WoodDeck_If_Matched>;
  objects: Array<WoodDeck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_WoodDeckUsageArgs = {
  if_matched?: Maybe<WoodDeckUsage_If_Matched>;
  objects: Array<WoodDeckUsage_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_WoodDeckUsage_OneArgs = {
  if_matched?: Maybe<WoodDeckUsage_If_Matched>;
  object: WoodDeckUsage_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_WoodDeck_OneArgs = {
  if_matched?: Maybe<WoodDeck_If_Matched>;
  object: WoodDeck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_LayersArgs = {
  if_matched?: Maybe<VNavAssembly_Layers_If_Matched>;
  objects: Array<VNavAssembly_Layers_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Layers_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Layers_If_Matched>;
  object: VNavAssembly_Layers_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_ManufacturerOnlySearchArgs = {
  if_matched?: Maybe<VNavAssembly_ManufacturerOnlySearch_If_Matched>;
  objects: Array<VNavAssembly_ManufacturerOnlySearch_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_ManufacturerOnlySearch_OneArgs = {
  if_matched?: Maybe<VNavAssembly_ManufacturerOnlySearch_If_Matched>;
  object: VNavAssembly_ManufacturerOnlySearch_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_RoofTypeCountArgs = {
  if_matched?: Maybe<VNavAssembly_RoofTypeCount_If_Matched>;
  objects: Array<VNavAssembly_RoofTypeCount_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_RoofTypeCount_OneArgs = {
  if_matched?: Maybe<VNavAssembly_RoofTypeCount_If_Matched>;
  object: VNavAssembly_RoofTypeCount_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_SearchArgs = {
  if_matched?: Maybe<VNavAssembly_Search_If_Matched>;
  objects: Array<VNavAssembly_Search_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_AirRetarderArgs = {
  if_matched?: Maybe<VNavAssembly_Search_AirRetarder_If_Matched>;
  objects: Array<VNavAssembly_Search_AirRetarder_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_AirRetarder_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_AirRetarder_If_Matched>;
  object: VNavAssembly_Search_AirRetarder_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_BaseCoatArgs = {
  if_matched?: Maybe<VNavAssembly_Search_BaseCoat_If_Matched>;
  objects: Array<VNavAssembly_Search_BaseCoat_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_BaseCoat_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_BaseCoat_If_Matched>;
  object: VNavAssembly_Search_BaseCoat_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_BasePlyArgs = {
  if_matched?: Maybe<VNavAssembly_Search_BasePly_If_Matched>;
  objects: Array<VNavAssembly_Search_BasePly_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_BasePly_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_BasePly_If_Matched>;
  object: VNavAssembly_Search_BasePly_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_BaseSheetArgs = {
  if_matched?: Maybe<VNavAssembly_Search_BaseSheet_If_Matched>;
  objects: Array<VNavAssembly_Search_BaseSheet_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_BaseSheet_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_BaseSheet_If_Matched>;
  object: VNavAssembly_Search_BaseSheet_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_BoardStockArgs = {
  if_matched?: Maybe<VNavAssembly_Search_BoardStock_If_Matched>;
  objects: Array<VNavAssembly_Search_BoardStock_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_BoardStock_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_BoardStock_If_Matched>;
  object: VNavAssembly_Search_BoardStock_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_CapPlyArgs = {
  if_matched?: Maybe<VNavAssembly_Search_CapPly_If_Matched>;
  objects: Array<VNavAssembly_Search_CapPly_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_CapPly_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_CapPly_If_Matched>;
  object: VNavAssembly_Search_CapPly_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_CoatArgs = {
  if_matched?: Maybe<VNavAssembly_Search_Coat_If_Matched>;
  objects: Array<VNavAssembly_Search_Coat_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_Coat_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_Coat_If_Matched>;
  object: VNavAssembly_Search_Coat_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_CoatingArgs = {
  if_matched?: Maybe<VNavAssembly_Search_Coating_If_Matched>;
  objects: Array<VNavAssembly_Search_Coating_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_Coating_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_Coating_If_Matched>;
  object: VNavAssembly_Search_Coating_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_CoverBoardArgs = {
  if_matched?: Maybe<VNavAssembly_Search_CoverBoard_If_Matched>;
  objects: Array<VNavAssembly_Search_CoverBoard_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_CoverBoard_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_CoverBoard_If_Matched>;
  object: VNavAssembly_Search_CoverBoard_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_CoverCompositePanelArgs = {
  if_matched?: Maybe<VNavAssembly_Search_CoverCompositePanel_If_Matched>;
  objects: Array<VNavAssembly_Search_CoverCompositePanel_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_CoverCompositePanel_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_CoverCompositePanel_If_Matched>;
  object: VNavAssembly_Search_CoverCompositePanel_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_CoverSingleplyArgs = {
  if_matched?: Maybe<VNavAssembly_Search_CoverSingleply_If_Matched>;
  objects: Array<VNavAssembly_Search_CoverSingleply_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_CoverSingleply_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_CoverSingleply_If_Matched>;
  object: VNavAssembly_Search_CoverSingleply_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_CoverStandingLapSeamArgs = {
  if_matched?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_If_Matched>;
  objects: Array<VNavAssembly_Search_CoverStandingLapSeam_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_CoverStandingLapSeam_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_If_Matched>;
  object: VNavAssembly_Search_CoverStandingLapSeam_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_CoverStripDiskArgs = {
  if_matched?: Maybe<VNavAssembly_Search_CoverStripDisk_If_Matched>;
  objects: Array<VNavAssembly_Search_CoverStripDisk_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_CoverStripDisk_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_CoverStripDisk_If_Matched>;
  object: VNavAssembly_Search_CoverStripDisk_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_DeckCementitiousPanelArgs = {
  if_matched?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_If_Matched>;
  objects: Array<VNavAssembly_Search_DeckCementitiousPanel_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_DeckCementitiousPanel_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_If_Matched>;
  object: VNavAssembly_Search_DeckCementitiousPanel_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_DeckSteelArgs = {
  if_matched?: Maybe<VNavAssembly_Search_DeckSteel_If_Matched>;
  objects: Array<VNavAssembly_Search_DeckSteel_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_DeckSteel_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_DeckSteel_If_Matched>;
  object: VNavAssembly_Search_DeckSteel_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_FormDeckArgs = {
  if_matched?: Maybe<VNavAssembly_Search_FormDeck_If_Matched>;
  objects: Array<VNavAssembly_Search_FormDeck_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_FormDeck_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_FormDeck_If_Matched>;
  object: VNavAssembly_Search_FormDeck_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_InsulationBattBlanketArgs = {
  if_matched?: Maybe<VNavAssembly_Search_InsulationBattBlanket_If_Matched>;
  objects: Array<VNavAssembly_Search_InsulationBattBlanket_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_InsulationBattBlanket_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_InsulationBattBlanket_If_Matched>;
  object: VNavAssembly_Search_InsulationBattBlanket_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_InsulationSprayFoamArgs = {
  if_matched?: Maybe<VNavAssembly_Search_InsulationSprayFoam_If_Matched>;
  objects: Array<VNavAssembly_Search_InsulationSprayFoam_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_InsulationSprayFoam_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_InsulationSprayFoam_If_Matched>;
  object: VNavAssembly_Search_InsulationSprayFoam_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_LightweightInsulatingConcreteArgs = {
  if_matched?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_If_Matched>;
  objects: Array<VNavAssembly_Search_LightweightInsulatingConcrete_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_LightweightInsulatingConcrete_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_If_Matched>;
  object: VNavAssembly_Search_LightweightInsulatingConcrete_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_LinerPanelArgs = {
  if_matched?: Maybe<VNavAssembly_Search_LinerPanel_If_Matched>;
  objects: Array<VNavAssembly_Search_LinerPanel_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_LinerPanel_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_LinerPanel_If_Matched>;
  object: VNavAssembly_Search_LinerPanel_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_PlyArgs = {
  if_matched?: Maybe<VNavAssembly_Search_Ply_If_Matched>;
  objects: Array<VNavAssembly_Search_Ply_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_Ply_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_Ply_If_Matched>;
  object: VNavAssembly_Search_Ply_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_SeparatorSheetArgs = {
  if_matched?: Maybe<VNavAssembly_Search_SeparatorSheet_If_Matched>;
  objects: Array<VNavAssembly_Search_SeparatorSheet_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_SeparatorSheet_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_SeparatorSheet_If_Matched>;
  object: VNavAssembly_Search_SeparatorSheet_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_SubstratePrimerArgs = {
  if_matched?: Maybe<VNavAssembly_Search_SubstratePrimer_If_Matched>;
  objects: Array<VNavAssembly_Search_SubstratePrimer_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_SubstratePrimer_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_SubstratePrimer_If_Matched>;
  object: VNavAssembly_Search_SubstratePrimer_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_SurfacingArgs = {
  if_matched?: Maybe<VNavAssembly_Search_Surfacing_If_Matched>;
  objects: Array<VNavAssembly_Search_Surfacing_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_Surfacing_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_Surfacing_If_Matched>;
  object: VNavAssembly_Search_Surfacing_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_ThermalBarrierArgs = {
  if_matched?: Maybe<VNavAssembly_Search_ThermalBarrier_If_Matched>;
  objects: Array<VNavAssembly_Search_ThermalBarrier_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_ThermalBarrier_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_ThermalBarrier_If_Matched>;
  object: VNavAssembly_Search_ThermalBarrier_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_TopCoatArgs = {
  if_matched?: Maybe<VNavAssembly_Search_TopCoat_If_Matched>;
  objects: Array<VNavAssembly_Search_TopCoat_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_TopCoat_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_TopCoat_If_Matched>;
  object: VNavAssembly_Search_TopCoat_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_VaporRetarderArgs = {
  if_matched?: Maybe<VNavAssembly_Search_VaporRetarder_If_Matched>;
  objects: Array<VNavAssembly_Search_VaporRetarder_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_VaporRetarder_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_VaporRetarder_If_Matched>;
  object: VNavAssembly_Search_VaporRetarder_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Search_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Search_If_Matched>;
  object: VNavAssembly_Search_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_UsagesArgs = {
  if_matched?: Maybe<VNavAssembly_Usages_If_Matched>;
  objects: Array<VNavAssembly_Usages_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_VNavAssembly_Usages_OneArgs = {
  if_matched?: Maybe<VNavAssembly_Usages_If_Matched>;
  object: VNavAssembly_Usages_Insert_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AddressArgs = {
  _inc?: Maybe<Address_Inc_Input>;
  _set?: Maybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_By_PkArgs = {
  _inc?: Maybe<Address_Inc_Input>;
  _set?: Maybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AdheredSinglePlyOrphanReportArgs = {
  _inc?: Maybe<AdheredSinglePlyOrphanReport_Inc_Input>;
  _set?: Maybe<AdheredSinglePlyOrphanReport_Set_Input>;
  where: AdheredSinglePlyOrphanReport_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AdheredSinglePlyOrphanReport_By_PkArgs = {
  _inc?: Maybe<AdheredSinglePlyOrphanReport_Inc_Input>;
  _set?: Maybe<AdheredSinglePlyOrphanReport_Set_Input>;
  pk_columns: AdheredSinglePlyOrphanReport_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AdhesiveFullApplicationArgs = {
  _inc?: Maybe<AdhesiveFullApplication_Inc_Input>;
  _set?: Maybe<AdhesiveFullApplication_Set_Input>;
  where: AdhesiveFullApplication_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AdhesiveFullApplicationUsageArgs = {
  _inc?: Maybe<AdhesiveFullApplicationUsage_Inc_Input>;
  _set?: Maybe<AdhesiveFullApplicationUsage_Set_Input>;
  where: AdhesiveFullApplicationUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AdhesiveFullApplicationUsage_By_PkArgs = {
  _inc?: Maybe<AdhesiveFullApplicationUsage_Inc_Input>;
  _set?: Maybe<AdhesiveFullApplicationUsage_Set_Input>;
  pk_columns: AdhesiveFullApplicationUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AdhesiveFullApplication_By_PkArgs = {
  _inc?: Maybe<AdhesiveFullApplication_Inc_Input>;
  _set?: Maybe<AdhesiveFullApplication_Set_Input>;
  pk_columns: AdhesiveFullApplication_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AdhesiveRibbonUsageArgs = {
  _inc?: Maybe<AdhesiveRibbonUsage_Inc_Input>;
  _set?: Maybe<AdhesiveRibbonUsage_Set_Input>;
  where: AdhesiveRibbonUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AdhesiveRibbonUsage_By_PkArgs = {
  _inc?: Maybe<AdhesiveRibbonUsage_Inc_Input>;
  _set?: Maybe<AdhesiveRibbonUsage_Set_Input>;
  pk_columns: AdhesiveRibbonUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AdhesiveRibbonsArgs = {
  _inc?: Maybe<AdhesiveRibbons_Inc_Input>;
  _set?: Maybe<AdhesiveRibbons_Set_Input>;
  where: AdhesiveRibbons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AdhesiveRibbons_By_PkArgs = {
  _inc?: Maybe<AdhesiveRibbons_Inc_Input>;
  _set?: Maybe<AdhesiveRibbons_Set_Input>;
  pk_columns: AdhesiveRibbons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AdhesiveSpotsArgs = {
  _inc?: Maybe<AdhesiveSpots_Inc_Input>;
  _set?: Maybe<AdhesiveSpots_Set_Input>;
  where: AdhesiveSpots_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AdhesiveSpotsUsageArgs = {
  _inc?: Maybe<AdhesiveSpotsUsage_Inc_Input>;
  _set?: Maybe<AdhesiveSpotsUsage_Set_Input>;
  where: AdhesiveSpotsUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AdhesiveSpotsUsage_By_PkArgs = {
  _inc?: Maybe<AdhesiveSpotsUsage_Inc_Input>;
  _set?: Maybe<AdhesiveSpotsUsage_Set_Input>;
  pk_columns: AdhesiveSpotsUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AdhesiveSpots_By_PkArgs = {
  _inc?: Maybe<AdhesiveSpots_Inc_Input>;
  _set?: Maybe<AdhesiveSpots_Set_Input>;
  pk_columns: AdhesiveSpots_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AdhesiveSystemUsageArgs = {
  _inc?: Maybe<AdhesiveSystemUsage_Inc_Input>;
  _set?: Maybe<AdhesiveSystemUsage_Set_Input>;
  where: AdhesiveSystemUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AdhesiveSystemUsage_By_PkArgs = {
  _inc?: Maybe<AdhesiveSystemUsage_Inc_Input>;
  _set?: Maybe<AdhesiveSystemUsage_Set_Input>;
  pk_columns: AdhesiveSystemUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AirRetarderArgs = {
  _inc?: Maybe<AirRetarder_Inc_Input>;
  _set?: Maybe<AirRetarder_Set_Input>;
  where: AirRetarder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AirRetarderUsageArgs = {
  _inc?: Maybe<AirRetarderUsage_Inc_Input>;
  _set?: Maybe<AirRetarderUsage_Set_Input>;
  where: AirRetarderUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AirRetarderUsage_By_PkArgs = {
  _inc?: Maybe<AirRetarderUsage_Inc_Input>;
  _set?: Maybe<AirRetarderUsage_Set_Input>;
  pk_columns: AirRetarderUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AirRetarder_By_PkArgs = {
  _inc?: Maybe<AirRetarder_Inc_Input>;
  _set?: Maybe<AirRetarder_Set_Input>;
  pk_columns: AirRetarder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AllManufacturedProductsArgs = {
  _inc?: Maybe<AllManufacturedProducts_Inc_Input>;
  _set?: Maybe<AllManufacturedProducts_Set_Input>;
  where: AllManufacturedProducts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AnchorArgs = {
  _inc?: Maybe<Anchor_Inc_Input>;
  _set?: Maybe<Anchor_Set_Input>;
  where: Anchor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Anchor_By_PkArgs = {
  _inc?: Maybe<Anchor_Inc_Input>;
  _set?: Maybe<Anchor_Set_Input>;
  pk_columns: Anchor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ApprovalsDocArgs = {
  _inc?: Maybe<ApprovalsDoc_Inc_Input>;
  _set?: Maybe<ApprovalsDoc_Set_Input>;
  where: ApprovalsDoc_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ApprovalsDoc_By_PkArgs = {
  _inc?: Maybe<ApprovalsDoc_Inc_Input>;
  _set?: Maybe<ApprovalsDoc_Set_Input>;
  pk_columns: ApprovalsDoc_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ApprovedLayerUseArgs = {
  _inc?: Maybe<ApprovedLayerUse_Inc_Input>;
  _set?: Maybe<ApprovedLayerUse_Set_Input>;
  where: ApprovedLayerUse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ApprovedLayerUse_By_PkArgs = {
  _inc?: Maybe<ApprovedLayerUse_Inc_Input>;
  _set?: Maybe<ApprovedLayerUse_Set_Input>;
  pk_columns: ApprovedLayerUse_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AssemblyReconfigQueueArgs = {
  _inc?: Maybe<AssemblyReconfigQueue_Inc_Input>;
  _set?: Maybe<AssemblyReconfigQueue_Set_Input>;
  where: AssemblyReconfigQueue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AssemblyReconfigQueue_By_PkArgs = {
  _inc?: Maybe<AssemblyReconfigQueue_Inc_Input>;
  _set?: Maybe<AssemblyReconfigQueue_Set_Input>;
  pk_columns: AssemblyReconfigQueue_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BaseCoatArgs = {
  _inc?: Maybe<BaseCoat_Inc_Input>;
  _set?: Maybe<BaseCoat_Set_Input>;
  where: BaseCoat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BaseCoatUsageArgs = {
  _inc?: Maybe<BaseCoatUsage_Inc_Input>;
  _set?: Maybe<BaseCoatUsage_Set_Input>;
  where: BaseCoatUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BaseCoatUsage_By_PkArgs = {
  _inc?: Maybe<BaseCoatUsage_Inc_Input>;
  _set?: Maybe<BaseCoatUsage_Set_Input>;
  pk_columns: BaseCoatUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BaseCoat_By_PkArgs = {
  _inc?: Maybe<BaseCoat_Inc_Input>;
  _set?: Maybe<BaseCoat_Set_Input>;
  pk_columns: BaseCoat_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BasePlyArgs = {
  _inc?: Maybe<BasePly_Inc_Input>;
  _set?: Maybe<BasePly_Set_Input>;
  where: BasePly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BasePlyUsageArgs = {
  _inc?: Maybe<BasePlyUsage_Inc_Input>;
  _set?: Maybe<BasePlyUsage_Set_Input>;
  where: BasePlyUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BasePlyUsage_By_PkArgs = {
  _inc?: Maybe<BasePlyUsage_Inc_Input>;
  _set?: Maybe<BasePlyUsage_Set_Input>;
  pk_columns: BasePlyUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BasePly_By_PkArgs = {
  _inc?: Maybe<BasePly_Inc_Input>;
  _set?: Maybe<BasePly_Set_Input>;
  pk_columns: BasePly_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BaseSheetArgs = {
  _inc?: Maybe<BaseSheet_Inc_Input>;
  _set?: Maybe<BaseSheet_Set_Input>;
  where: BaseSheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BaseSheetUsageArgs = {
  _inc?: Maybe<BaseSheetUsage_Inc_Input>;
  _set?: Maybe<BaseSheetUsage_Set_Input>;
  where: BaseSheetUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BaseSheetUsage_By_PkArgs = {
  _inc?: Maybe<BaseSheetUsage_Inc_Input>;
  _set?: Maybe<BaseSheetUsage_Set_Input>;
  pk_columns: BaseSheetUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BaseSheet_By_PkArgs = {
  _inc?: Maybe<BaseSheet_Inc_Input>;
  _set?: Maybe<BaseSheet_Set_Input>;
  pk_columns: BaseSheet_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BattBlanketInsulationArgs = {
  _inc?: Maybe<BattBlanketInsulation_Inc_Input>;
  _set?: Maybe<BattBlanketInsulation_Set_Input>;
  where: BattBlanketInsulation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BattBlanketInsulation_By_PkArgs = {
  _inc?: Maybe<BattBlanketInsulation_Inc_Input>;
  _set?: Maybe<BattBlanketInsulation_Set_Input>;
  pk_columns: BattBlanketInsulation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BattBlanketUsageArgs = {
  _inc?: Maybe<BattBlanketUsage_Inc_Input>;
  _set?: Maybe<BattBlanketUsage_Set_Input>;
  where: BattBlanketUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BattBlanketUsage_By_PkArgs = {
  _inc?: Maybe<BattBlanketUsage_Inc_Input>;
  _set?: Maybe<BattBlanketUsage_Set_Input>;
  pk_columns: BattBlanketUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BattenBarArgs = {
  _inc?: Maybe<BattenBar_Inc_Input>;
  _set?: Maybe<BattenBar_Set_Input>;
  where: BattenBar_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BattenBarUsageArgs = {
  _inc?: Maybe<BattenBarUsage_Inc_Input>;
  _set?: Maybe<BattenBarUsage_Set_Input>;
  where: BattenBarUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BattenBarUsage_By_PkArgs = {
  _inc?: Maybe<BattenBarUsage_Inc_Input>;
  _set?: Maybe<BattenBarUsage_Set_Input>;
  pk_columns: BattenBarUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BattenBar_By_PkArgs = {
  _inc?: Maybe<BattenBar_Inc_Input>;
  _set?: Maybe<BattenBar_Set_Input>;
  pk_columns: BattenBar_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BoardSizesArgs = {
  _set?: Maybe<BoardSizes_Set_Input>;
  where: BoardSizes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BoardStockArgs = {
  _inc?: Maybe<BoardStock_Inc_Input>;
  _set?: Maybe<BoardStock_Set_Input>;
  where: BoardStock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BoardStockBoardSizesArgs = {
  _inc?: Maybe<BoardStockBoardSizes_Inc_Input>;
  _set?: Maybe<BoardStockBoardSizes_Set_Input>;
  where: BoardStockBoardSizes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BoardStockBoardSizes_By_PkArgs = {
  _inc?: Maybe<BoardStockBoardSizes_Inc_Input>;
  _set?: Maybe<BoardStockBoardSizes_Set_Input>;
  pk_columns: BoardStockBoardSizes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BoardStockFacerBottomArgs = {
  _inc?: Maybe<BoardStockFacerBottom_Inc_Input>;
  _set?: Maybe<BoardStockFacerBottom_Set_Input>;
  where: BoardStockFacerBottom_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BoardStockFacerBottom_By_PkArgs = {
  _inc?: Maybe<BoardStockFacerBottom_Inc_Input>;
  _set?: Maybe<BoardStockFacerBottom_Set_Input>;
  pk_columns: BoardStockFacerBottom_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BoardStockFacerTopArgs = {
  _inc?: Maybe<BoardStockFacerTop_Inc_Input>;
  _set?: Maybe<BoardStockFacerTop_Set_Input>;
  where: BoardStockFacerTop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BoardStockFacerTop_By_PkArgs = {
  _inc?: Maybe<BoardStockFacerTop_Inc_Input>;
  _set?: Maybe<BoardStockFacerTop_Set_Input>;
  pk_columns: BoardStockFacerTop_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BoardStockUsageArgs = {
  _inc?: Maybe<BoardStockUsage_Inc_Input>;
  _set?: Maybe<BoardStockUsage_Set_Input>;
  where: BoardStockUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BoardStockUsage_By_PkArgs = {
  _inc?: Maybe<BoardStockUsage_Inc_Input>;
  _set?: Maybe<BoardStockUsage_Set_Input>;
  pk_columns: BoardStockUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BoardStock_By_PkArgs = {
  _inc?: Maybe<BoardStock_Inc_Input>;
  _set?: Maybe<BoardStock_Set_Input>;
  pk_columns: BoardStock_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BroadcastAlertArgs = {
  _inc?: Maybe<BroadcastAlert_Inc_Input>;
  _set?: Maybe<BroadcastAlert_Set_Input>;
  where: BroadcastAlert_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BroadcastAlert_By_PkArgs = {
  _inc?: Maybe<BroadcastAlert_Inc_Input>;
  _set?: Maybe<BroadcastAlert_Set_Input>;
  pk_columns: BroadcastAlert_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BuiltupVaporRetarderSystemUsageArgs = {
  _inc?: Maybe<BuiltupVaporRetarderSystemUsage_Inc_Input>;
  _set?: Maybe<BuiltupVaporRetarderSystemUsage_Set_Input>;
  where: BuiltupVaporRetarderSystemUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_BuiltupVaporRetarderSystemUsage_By_PkArgs = {
  _inc?: Maybe<BuiltupVaporRetarderSystemUsage_Inc_Input>;
  _set?: Maybe<BuiltupVaporRetarderSystemUsage_Set_Input>;
  pk_columns: BuiltupVaporRetarderSystemUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_AdditiveArgs = {
  _set?: Maybe<C_Additive_Set_Input>;
  where: C_Additive_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_Additive_By_PkArgs = {
  _set?: Maybe<C_Additive_Set_Input>;
  pk_columns: C_Additive_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_AdhesiveApplicationMethodArgs = {
  _set?: Maybe<C_AdhesiveApplicationMethod_Set_Input>;
  where: C_AdhesiveApplicationMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_AdhesiveApplicationMethod_By_PkArgs = {
  _set?: Maybe<C_AdhesiveApplicationMethod_Set_Input>;
  pk_columns: C_AdhesiveApplicationMethod_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_AdhesiveMaterialArgs = {
  _set?: Maybe<C_AdhesiveMaterial_Set_Input>;
  where: C_AdhesiveMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_AdhesiveMaterial_By_PkArgs = {
  _set?: Maybe<C_AdhesiveMaterial_Set_Input>;
  pk_columns: C_AdhesiveMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_AdhesiveTypeArgs = {
  _set?: Maybe<C_AdhesiveType_Set_Input>;
  where: C_AdhesiveType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_AdhesiveType_By_PkArgs = {
  _set?: Maybe<C_AdhesiveType_Set_Input>;
  pk_columns: C_AdhesiveType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_AggregateArgs = {
  _set?: Maybe<C_Aggregate_Set_Input>;
  where: C_Aggregate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_Aggregate_By_PkArgs = {
  _set?: Maybe<C_Aggregate_Set_Input>;
  pk_columns: C_Aggregate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ApprovalsDocCategoryArgs = {
  _set?: Maybe<C_ApprovalsDocCategory_Set_Input>;
  where: C_ApprovalsDocCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ApprovalsDocCategory_By_PkArgs = {
  _set?: Maybe<C_ApprovalsDocCategory_Set_Input>;
  pk_columns: C_ApprovalsDocCategory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ApprovedUseArgs = {
  _inc?: Maybe<C_ApprovedUse_Inc_Input>;
  _set?: Maybe<C_ApprovedUse_Set_Input>;
  where: C_ApprovedUse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ApprovedUse_By_PkArgs = {
  _inc?: Maybe<C_ApprovedUse_Inc_Input>;
  _set?: Maybe<C_ApprovedUse_Set_Input>;
  pk_columns: C_ApprovedUse_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_AssemblyApplicationTypeArgs = {
  _set?: Maybe<C_AssemblyApplicationType_Set_Input>;
  where: C_AssemblyApplicationType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_AssemblyApplicationType_By_PkArgs = {
  _set?: Maybe<C_AssemblyApplicationType_Set_Input>;
  pk_columns: C_AssemblyApplicationType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_AssemblyTypeArgs = {
  _set?: Maybe<C_AssemblyType_Set_Input>;
  where: C_AssemblyType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_AssemblyType_By_PkArgs = {
  _set?: Maybe<C_AssemblyType_Set_Input>;
  pk_columns: C_AssemblyType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_BackingMaterialArgs = {
  _set?: Maybe<C_BackingMaterial_Set_Input>;
  where: C_BackingMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_BackingMaterial_By_PkArgs = {
  _set?: Maybe<C_BackingMaterial_Set_Input>;
  pk_columns: C_BackingMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_BaseSheetApplicationMethodArgs = {
  _set?: Maybe<C_BaseSheetApplicationMethod_Set_Input>;
  where: C_BaseSheetApplicationMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_BaseSheetApplicationMethod_By_PkArgs = {
  _set?: Maybe<C_BaseSheetApplicationMethod_Set_Input>;
  pk_columns: C_BaseSheetApplicationMethod_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_BattBlanketFacerMaterialArgs = {
  _set?: Maybe<C_BattBlanketFacerMaterial_Set_Input>;
  where: C_BattBlanketFacerMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_BattBlanketFacerMaterial_By_PkArgs = {
  _set?: Maybe<C_BattBlanketFacerMaterial_Set_Input>;
  pk_columns: C_BattBlanketFacerMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_BattBlanketMaterialArgs = {
  _set?: Maybe<C_BattBlanketMaterial_Set_Input>;
  where: C_BattBlanketMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_BattBlanketMaterial_By_PkArgs = {
  _set?: Maybe<C_BattBlanketMaterial_Set_Input>;
  pk_columns: C_BattBlanketMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_BoardProfileArgs = {
  _set?: Maybe<C_BoardProfile_Set_Input>;
  where: C_BoardProfile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_BoardProfile_By_PkArgs = {
  _set?: Maybe<C_BoardProfile_Set_Input>;
  pk_columns: C_BoardProfile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_BoardStockMaterialArgs = {
  _set?: Maybe<C_BoardStockMaterial_Set_Input>;
  where: C_BoardStockMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_BoardStockMaterial_By_PkArgs = {
  _set?: Maybe<C_BoardStockMaterial_Set_Input>;
  pk_columns: C_BoardStockMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_BuildingEnclosureArgs = {
  _set?: Maybe<C_BuildingEnclosure_Set_Input>;
  where: C_BuildingEnclosure_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_BuildingEnclosure_By_PkArgs = {
  _set?: Maybe<C_BuildingEnclosure_Set_Input>;
  pk_columns: C_BuildingEnclosure_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_CementAggregateRatioArgs = {
  _set?: Maybe<C_CementAggregateRatio_Set_Input>;
  where: C_CementAggregateRatio_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_CementAggregateRatio_By_PkArgs = {
  _set?: Maybe<C_CementAggregateRatio_Set_Input>;
  pk_columns: C_CementAggregateRatio_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_CoatingMaterialArgs = {
  _set?: Maybe<C_CoatingMaterial_Set_Input>;
  where: C_CoatingMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_CoatingMaterial_By_PkArgs = {
  _set?: Maybe<C_CoatingMaterial_Set_Input>;
  pk_columns: C_CoatingMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ComponentTypeArgs = {
  _set?: Maybe<C_ComponentType_Set_Input>;
  where: C_ComponentType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ComponentType_By_PkArgs = {
  _set?: Maybe<C_ComponentType_Set_Input>;
  pk_columns: C_ComponentType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ContactTypeArgs = {
  _set?: Maybe<C_ContactType_Set_Input>;
  where: C_ContactType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ContactType_By_PkArgs = {
  _set?: Maybe<C_ContactType_Set_Input>;
  pk_columns: C_ContactType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_CountryArgs = {
  _set?: Maybe<C_Country_Set_Input>;
  where: C_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_Country_By_PkArgs = {
  _set?: Maybe<C_Country_Set_Input>;
  pk_columns: C_Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_CoverBoardMaterialArgs = {
  _set?: Maybe<C_CoverBoardMaterial_Set_Input>;
  where: C_CoverBoardMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_CoverBoardMaterial_By_PkArgs = {
  _set?: Maybe<C_CoverBoardMaterial_Set_Input>;
  pk_columns: C_CoverBoardMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_CoverColorArgs = {
  _set?: Maybe<C_CoverColor_Set_Input>;
  where: C_CoverColor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_CoverColor_By_PkArgs = {
  _set?: Maybe<C_CoverColor_Set_Input>;
  pk_columns: C_CoverColor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_CoverSecurementMethodArgs = {
  _set?: Maybe<C_CoverSecurementMethod_Set_Input>;
  where: C_CoverSecurementMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_CoverSecurementMethod_By_PkArgs = {
  _set?: Maybe<C_CoverSecurementMethod_Set_Input>;
  pk_columns: C_CoverSecurementMethod_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_DeckTypeArgs = {
  _set?: Maybe<C_DeckType_Set_Input>;
  where: C_DeckType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_DeckType_By_PkArgs = {
  _set?: Maybe<C_DeckType_Set_Input>;
  pk_columns: C_DeckType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ExistingRoofCoverArgs = {
  _set?: Maybe<C_ExistingRoofCover_Set_Input>;
  where: C_ExistingRoofCover_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ExistingRoofCover_By_PkArgs = {
  _set?: Maybe<C_ExistingRoofCover_Set_Input>;
  pk_columns: C_ExistingRoofCover_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ExistingRoofDeckArgs = {
  _set?: Maybe<C_ExistingRoofDeck_Set_Input>;
  where: C_ExistingRoofDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ExistingRoofDeck_By_PkArgs = {
  _set?: Maybe<C_ExistingRoofDeck_Set_Input>;
  pk_columns: C_ExistingRoofDeck_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ExistingRoofInsulationMaterialArgs = {
  _set?: Maybe<C_ExistingRoofInsulationMaterial_Set_Input>;
  where: C_ExistingRoofInsulationMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ExistingRoofInsulationMaterial_By_PkArgs = {
  _set?: Maybe<C_ExistingRoofInsulationMaterial_Set_Input>;
  pk_columns: C_ExistingRoofInsulationMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ExistingRoofSinglePlyMaterialArgs = {
  _set?: Maybe<C_ExistingRoofSinglePlyMaterial_Set_Input>;
  where: C_ExistingRoofSinglePlyMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ExistingRoofSinglePlyMaterial_By_PkArgs = {
  _set?: Maybe<C_ExistingRoofSinglePlyMaterial_Set_Input>;
  pk_columns: C_ExistingRoofSinglePlyMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ExistingRoofSurfaceArgs = {
  _set?: Maybe<C_ExistingRoofSurface_Set_Input>;
  where: C_ExistingRoofSurface_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ExistingRoofSurface_By_PkArgs = {
  _set?: Maybe<C_ExistingRoofSurface_Set_Input>;
  pk_columns: C_ExistingRoofSurface_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ExtFireRatingArgs = {
  _set?: Maybe<C_ExtFireRating_Set_Input>;
  where: C_ExtFireRating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ExtFireRating_By_PkArgs = {
  _set?: Maybe<C_ExtFireRating_Set_Input>;
  pk_columns: C_ExtFireRating_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_FacerArgs = {
  _set?: Maybe<C_Facer_Set_Input>;
  where: C_Facer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_FacerMaterialArgs = {
  _set?: Maybe<C_FacerMaterial_Set_Input>;
  where: C_FacerMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_FacerMaterial_By_PkArgs = {
  _set?: Maybe<C_FacerMaterial_Set_Input>;
  pk_columns: C_FacerMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_Facer_By_PkArgs = {
  _set?: Maybe<C_Facer_Set_Input>;
  pk_columns: C_Facer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_FastenerCoatingArgs = {
  _set?: Maybe<C_FastenerCoating_Set_Input>;
  where: C_FastenerCoating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_FastenerCoating_By_PkArgs = {
  _set?: Maybe<C_FastenerCoating_Set_Input>;
  pk_columns: C_FastenerCoating_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_FastenerDriveHeadArgs = {
  _set?: Maybe<C_FastenerDriveHead_Set_Input>;
  where: C_FastenerDriveHead_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_FastenerDriveHead_By_PkArgs = {
  _set?: Maybe<C_FastenerDriveHead_Set_Input>;
  pk_columns: C_FastenerDriveHead_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_FastenerTypeArgs = {
  _set?: Maybe<C_FastenerType_Set_Input>;
  where: C_FastenerType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_FastenerType_By_PkArgs = {
  _set?: Maybe<C_FastenerType_Set_Input>;
  pk_columns: C_FastenerType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_FasteningApplicationMethodArgs = {
  _set?: Maybe<C_FasteningApplicationMethod_Set_Input>;
  where: C_FasteningApplicationMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_FasteningApplicationMethod_By_PkArgs = {
  _set?: Maybe<C_FasteningApplicationMethod_Set_Input>;
  pk_columns: C_FasteningApplicationMethod_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_FlashingMaterialArgs = {
  _set?: Maybe<C_FlashingMaterial_Set_Input>;
  where: C_FlashingMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_FlashingMaterial_By_PkArgs = {
  _set?: Maybe<C_FlashingMaterial_Set_Input>;
  pk_columns: C_FlashingMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_FlashingTypeArgs = {
  _set?: Maybe<C_FlashingType_Set_Input>;
  where: C_FlashingType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_FlashingType_By_PkArgs = {
  _set?: Maybe<C_FlashingType_Set_Input>;
  pk_columns: C_FlashingType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_FormDeckMaterialArgs = {
  _set?: Maybe<C_FormDeckMaterial_Set_Input>;
  where: C_FormDeckMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_FormDeckMaterial_By_PkArgs = {
  _set?: Maybe<C_FormDeckMaterial_Set_Input>;
  pk_columns: C_FormDeckMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_GroundRoughnessRatingArgs = {
  _set?: Maybe<C_GroundRoughnessRating_Set_Input>;
  where: C_GroundRoughnessRating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_GroundRoughnessRating_By_PkArgs = {
  _set?: Maybe<C_GroundRoughnessRating_Set_Input>;
  pk_columns: C_GroundRoughnessRating_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_GutterMaterialArgs = {
  _set?: Maybe<C_GutterMaterial_Set_Input>;
  where: C_GutterMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_GutterMaterial_By_PkArgs = {
  _set?: Maybe<C_GutterMaterial_Set_Input>;
  pk_columns: C_GutterMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_HailRatingArgs = {
  _set?: Maybe<C_HailRating_Set_Input>;
  where: C_HailRating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_HailRating_By_PkArgs = {
  _set?: Maybe<C_HailRating_Set_Input>;
  pk_columns: C_HailRating_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_IndustryTypeArgs = {
  _set?: Maybe<C_IndustryType_Set_Input>;
  where: C_IndustryType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_IndustryType_By_PkArgs = {
  _set?: Maybe<C_IndustryType_Set_Input>;
  pk_columns: C_IndustryType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_InsulationCoreArgs = {
  _set?: Maybe<C_InsulationCore_Set_Input>;
  where: C_InsulationCore_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_InsulationCore_By_PkArgs = {
  _set?: Maybe<C_InsulationCore_Set_Input>;
  pk_columns: C_InsulationCore_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_InsulationFoamTypeArgs = {
  _set?: Maybe<C_InsulationFoamType_Set_Input>;
  where: C_InsulationFoamType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_InsulationFoamType_By_PkArgs = {
  _set?: Maybe<C_InsulationFoamType_Set_Input>;
  pk_columns: C_InsulationFoamType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_IntFireRatingArgs = {
  _set?: Maybe<C_IntFireRating_Set_Input>;
  where: C_IntFireRating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_IntFireRating_By_PkArgs = {
  _set?: Maybe<C_IntFireRating_Set_Input>;
  pk_columns: C_IntFireRating_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_LapSealantTypeArgs = {
  _set?: Maybe<C_LapSealantType_Set_Input>;
  where: C_LapSealantType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_LapSealantType_By_PkArgs = {
  _set?: Maybe<C_LapSealantType_Set_Input>;
  pk_columns: C_LapSealantType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_LayerTypeArgs = {
  _inc?: Maybe<C_LayerType_Inc_Input>;
  _set?: Maybe<C_LayerType_Set_Input>;
  where: C_LayerType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_LayerType_By_PkArgs = {
  _inc?: Maybe<C_LayerType_Inc_Input>;
  _set?: Maybe<C_LayerType_Set_Input>;
  pk_columns: C_LayerType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_LinerPanelMaterialArgs = {
  _set?: Maybe<C_LinerPanelMaterial_Set_Input>;
  where: C_LinerPanelMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_LinerPanelMaterial_By_PkArgs = {
  _set?: Maybe<C_LinerPanelMaterial_Set_Input>;
  pk_columns: C_LinerPanelMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_LiquidApplicationArgs = {
  _set?: Maybe<C_LiquidApplication_Set_Input>;
  where: C_LiquidApplication_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_LiquidApplication_By_PkArgs = {
  _set?: Maybe<C_LiquidApplication_Set_Input>;
  pk_columns: C_LiquidApplication_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_LiquidAppliedMaterialArgs = {
  _set?: Maybe<C_LiquidAppliedMaterial_Set_Input>;
  where: C_LiquidAppliedMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_LiquidAppliedMaterial_By_PkArgs = {
  _set?: Maybe<C_LiquidAppliedMaterial_Set_Input>;
  pk_columns: C_LiquidAppliedMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_LockStatusArgs = {
  _set?: Maybe<C_LockStatus_Set_Input>;
  where: C_LockStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_LockStatus_By_PkArgs = {
  _set?: Maybe<C_LockStatus_Set_Input>;
  pk_columns: C_LockStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_MdAttributeTypeArgs = {
  _inc?: Maybe<C_MdAttributeType_Inc_Input>;
  _set?: Maybe<C_MdAttributeType_Set_Input>;
  where: C_MdAttributeType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_MdAttributeType_By_PkArgs = {
  _inc?: Maybe<C_MdAttributeType_Inc_Input>;
  _set?: Maybe<C_MdAttributeType_Set_Input>;
  pk_columns: C_MdAttributeType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_MaintenanceCoatingMaterialArgs = {
  _set?: Maybe<C_MaintenanceCoatingMaterial_Set_Input>;
  where: C_MaintenanceCoatingMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_MaintenanceCoatingMaterial_By_PkArgs = {
  _set?: Maybe<C_MaintenanceCoatingMaterial_Set_Input>;
  pk_columns: C_MaintenanceCoatingMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_MaintenanceCoatingSubstrateArgs = {
  _set?: Maybe<C_MaintenanceCoatingSubstrate_Set_Input>;
  where: C_MaintenanceCoatingSubstrate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_MaintenanceCoatingSubstrate_By_PkArgs = {
  _set?: Maybe<C_MaintenanceCoatingSubstrate_Set_Input>;
  pk_columns: C_MaintenanceCoatingSubstrate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_MembraneTerminationArgs = {
  _set?: Maybe<C_MembraneTermination_Set_Input>;
  where: C_MembraneTermination_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_MembraneTermination_By_PkArgs = {
  _set?: Maybe<C_MembraneTermination_Set_Input>;
  pk_columns: C_MembraneTermination_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_MultiplyMaterialArgs = {
  _set?: Maybe<C_MultiplyMaterial_Set_Input>;
  where: C_MultiplyMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_MultiplyMaterial_By_PkArgs = {
  _set?: Maybe<C_MultiplyMaterial_Set_Input>;
  pk_columns: C_MultiplyMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_PvApplicationMethodArgs = {
  _set?: Maybe<C_PvApplicationMethod_Set_Input>;
  where: C_PvApplicationMethod_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_PvApplicationMethod_By_PkArgs = {
  _set?: Maybe<C_PvApplicationMethod_Set_Input>;
  pk_columns: C_PvApplicationMethod_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_PvHailRatingArgs = {
  _set?: Maybe<C_PvHailRating_Set_Input>;
  where: C_PvHailRating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_PvHailRating_By_PkArgs = {
  _set?: Maybe<C_PvHailRating_Set_Input>;
  pk_columns: C_PvHailRating_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_PerimeterFlashingTypeArgs = {
  _set?: Maybe<C_PerimeterFlashingType_Set_Input>;
  where: C_PerimeterFlashingType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_PerimeterFlashingType_By_PkArgs = {
  _set?: Maybe<C_PerimeterFlashingType_Set_Input>;
  pk_columns: C_PerimeterFlashingType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_PressureZoneArgs = {
  _inc?: Maybe<C_PressureZone_Inc_Input>;
  _set?: Maybe<C_PressureZone_Set_Input>;
  where: C_PressureZone_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_PressureZone_By_PkArgs = {
  _inc?: Maybe<C_PressureZone_Inc_Input>;
  _set?: Maybe<C_PressureZone_Set_Input>;
  pk_columns: C_PressureZone_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ProductApprovedUseArgs = {
  _inc?: Maybe<C_ProductApprovedUse_Inc_Input>;
  _set?: Maybe<C_ProductApprovedUse_Set_Input>;
  where: C_ProductApprovedUse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ProjectRoleArgs = {
  _set?: Maybe<C_ProjectRole_Set_Input>;
  where: C_ProjectRole_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ProjectRole_By_PkArgs = {
  _set?: Maybe<C_ProjectRole_Set_Input>;
  pk_columns: C_ProjectRole_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ReinforcementArgs = {
  _set?: Maybe<C_Reinforcement_Set_Input>;
  where: C_Reinforcement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_Reinforcement_By_PkArgs = {
  _set?: Maybe<C_Reinforcement_Set_Input>;
  pk_columns: C_Reinforcement_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_RetarderMaterialArgs = {
  _set?: Maybe<C_RetarderMaterial_Set_Input>;
  where: C_RetarderMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_RetarderMaterial_By_PkArgs = {
  _set?: Maybe<C_RetarderMaterial_Set_Input>;
  pk_columns: C_RetarderMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ScenarioStatusArgs = {
  _set?: Maybe<C_ScenarioStatus_Set_Input>;
  where: C_ScenarioStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ScenarioStatus_By_PkArgs = {
  _set?: Maybe<C_ScenarioStatus_Set_Input>;
  pk_columns: C_ScenarioStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ScenarioTypeArgs = {
  _set?: Maybe<C_ScenarioType_Set_Input>;
  where: C_ScenarioType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ScenarioType_By_PkArgs = {
  _set?: Maybe<C_ScenarioType_Set_Input>;
  pk_columns: C_ScenarioType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_SeamPrimerTypeArgs = {
  _set?: Maybe<C_SeamPrimerType_Set_Input>;
  where: C_SeamPrimerType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_SeamPrimerType_By_PkArgs = {
  _set?: Maybe<C_SeamPrimerType_Set_Input>;
  pk_columns: C_SeamPrimerType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_SeamWashTypeArgs = {
  _set?: Maybe<C_SeamWashType_Set_Input>;
  where: C_SeamWashType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_SeamWashType_By_PkArgs = {
  _set?: Maybe<C_SeamWashType_Set_Input>;
  pk_columns: C_SeamWashType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_SecurementMaterialArgs = {
  _set?: Maybe<C_SecurementMaterial_Set_Input>;
  where: C_SecurementMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_SecurementMaterial_By_PkArgs = {
  _set?: Maybe<C_SecurementMaterial_Set_Input>;
  pk_columns: C_SecurementMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ShinglesHailArgs = {
  _set?: Maybe<C_ShinglesHail_Set_Input>;
  where: C_ShinglesHail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ShinglesHail_By_PkArgs = {
  _set?: Maybe<C_ShinglesHail_Set_Input>;
  pk_columns: C_ShinglesHail_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ShinglesMaterialArgs = {
  _set?: Maybe<C_ShinglesMaterial_Set_Input>;
  where: C_ShinglesMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ShinglesMaterial_By_PkArgs = {
  _set?: Maybe<C_ShinglesMaterial_Set_Input>;
  pk_columns: C_ShinglesMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ShinglesReinforcementArgs = {
  _set?: Maybe<C_ShinglesReinforcement_Set_Input>;
  where: C_ShinglesReinforcement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ShinglesReinforcement_By_PkArgs = {
  _set?: Maybe<C_ShinglesReinforcement_Set_Input>;
  pk_columns: C_ShinglesReinforcement_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ShinglesSurfacingArgs = {
  _set?: Maybe<C_ShinglesSurfacing_Set_Input>;
  where: C_ShinglesSurfacing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ShinglesSurfacing_By_PkArgs = {
  _set?: Maybe<C_ShinglesSurfacing_Set_Input>;
  pk_columns: C_ShinglesSurfacing_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_ShinglesWindSpeedArgs = {
  _set?: Maybe<C_ShinglesWindSpeed_Set_Input>;
  where: C_ShinglesWindSpeed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ShinglesWindSpeed_By_PkArgs = {
  _set?: Maybe<C_ShinglesWindSpeed_Set_Input>;
  pk_columns: C_ShinglesWindSpeed_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_SinglePlyMaterialArgs = {
  _set?: Maybe<C_SinglePlyMaterial_Set_Input>;
  where: C_SinglePlyMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_SinglePlyMaterial_By_PkArgs = {
  _set?: Maybe<C_SinglePlyMaterial_Set_Input>;
  pk_columns: C_SinglePlyMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_StandingLapSeamMaterialArgs = {
  _set?: Maybe<C_StandingLapSeamMaterial_Set_Input>;
  where: C_StandingLapSeamMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_StandingLapSeamMaterial_By_PkArgs = {
  _set?: Maybe<C_StandingLapSeamMaterial_Set_Input>;
  pk_columns: C_StandingLapSeamMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_StateProvinceArgs = {
  _set?: Maybe<C_StateProvince_Set_Input>;
  where: C_StateProvince_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_StateProvince_By_PkArgs = {
  _set?: Maybe<C_StateProvince_Set_Input>;
  pk_columns: C_StateProvince_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_StatusArgs = {
  _set?: Maybe<C_Status_Set_Input>;
  where: C_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_Status_By_PkArgs = {
  _set?: Maybe<C_Status_Set_Input>;
  pk_columns: C_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_SteelDeckRibTypeArgs = {
  _set?: Maybe<C_SteelDeckRibType_Set_Input>;
  where: C_SteelDeckRibType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_SteelDeckRibType_By_PkArgs = {
  _set?: Maybe<C_SteelDeckRibType_Set_Input>;
  pk_columns: C_SteelDeckRibType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_StressDistributorTypeArgs = {
  _inc?: Maybe<C_StressDistributorType_Inc_Input>;
  _set?: Maybe<C_StressDistributorType_Set_Input>;
  where: C_StressDistributorType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_StressDistributorType_By_PkArgs = {
  _inc?: Maybe<C_StressDistributorType_Inc_Input>;
  _set?: Maybe<C_StressDistributorType_Set_Input>;
  pk_columns: C_StressDistributorType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_StressPlateShapeArgs = {
  _set?: Maybe<C_StressPlateShape_Set_Input>;
  where: C_StressPlateShape_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_StressPlateShape_By_PkArgs = {
  _set?: Maybe<C_StressPlateShape_Set_Input>;
  pk_columns: C_StressPlateShape_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_StructureTypeArgs = {
  _set?: Maybe<C_StructureType_Set_Input>;
  where: C_StructureType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_StructureType_By_PkArgs = {
  _set?: Maybe<C_StructureType_Set_Input>;
  pk_columns: C_StructureType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_SubassemblyTypeArgs = {
  _set?: Maybe<C_SubassemblyType_Set_Input>;
  where: C_SubassemblyType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_SubassemblyType_By_PkArgs = {
  _set?: Maybe<C_SubassemblyType_Set_Input>;
  pk_columns: C_SubassemblyType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_SubstrateArgs = {
  _set?: Maybe<C_Substrate_Set_Input>;
  where: C_Substrate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_SubstratePrimerMaterialArgs = {
  _set?: Maybe<C_SubstratePrimerMaterial_Set_Input>;
  where: C_SubstratePrimerMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_SubstratePrimerMaterial_By_PkArgs = {
  _set?: Maybe<C_SubstratePrimerMaterial_Set_Input>;
  pk_columns: C_SubstratePrimerMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_Substrate_By_PkArgs = {
  _set?: Maybe<C_Substrate_Set_Input>;
  pk_columns: C_Substrate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_SurfaceTypeArgs = {
  _set?: Maybe<C_SurfaceType_Set_Input>;
  where: C_SurfaceType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_SurfaceType_By_PkArgs = {
  _set?: Maybe<C_SurfaceType_Set_Input>;
  pk_columns: C_SurfaceType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_SurfacingMaterialArgs = {
  _set?: Maybe<C_SurfacingMaterial_Set_Input>;
  where: C_SurfacingMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_SurfacingMaterial_By_PkArgs = {
  _set?: Maybe<C_SurfacingMaterial_Set_Input>;
  pk_columns: C_SurfacingMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_SystemApprovedUseArgs = {
  _inc?: Maybe<C_SystemApprovedUse_Inc_Input>;
  _set?: Maybe<C_SystemApprovedUse_Set_Input>;
  where: C_SystemApprovedUse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ThermalBarrierMaterialArgs = {
  _set?: Maybe<C_ThermalBarrierMaterial_Set_Input>;
  where: C_ThermalBarrierMaterial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_ThermalBarrierMaterial_By_PkArgs = {
  _set?: Maybe<C_ThermalBarrierMaterial_Set_Input>;
  pk_columns: C_ThermalBarrierMaterial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_WipDelistReasonArgs = {
  _set?: Maybe<C_WipDelistReason_Set_Input>;
  where: C_WipDelistReason_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_WipDelistReason_By_PkArgs = {
  _set?: Maybe<C_WipDelistReason_Set_Input>;
  pk_columns: C_WipDelistReason_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_WipOnHoldReasonArgs = {
  _set?: Maybe<C_WipOnHoldReason_Set_Input>;
  where: C_WipOnHoldReason_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_WipOnHoldReason_By_PkArgs = {
  _set?: Maybe<C_WipOnHoldReason_Set_Input>;
  pk_columns: C_WipOnHoldReason_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_WipStatusArgs = {
  _set?: Maybe<C_WipStatus_Set_Input>;
  where: C_WipStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_WipStatus_By_PkArgs = {
  _set?: Maybe<C_WipStatus_Set_Input>;
  pk_columns: C_WipStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_WeldApplicationArgs = {
  _set?: Maybe<C_WeldApplication_Set_Input>;
  where: C_WeldApplication_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_WeldApplication_By_PkArgs = {
  _set?: Maybe<C_WeldApplication_Set_Input>;
  pk_columns: C_WeldApplication_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_WeldConfigurationArgs = {
  _set?: Maybe<C_WeldConfiguration_Set_Input>;
  where: C_WeldConfiguration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_WeldConfiguration_By_PkArgs = {
  _set?: Maybe<C_WeldConfiguration_Set_Input>;
  pk_columns: C_WeldConfiguration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_WindBorneDebrisArgs = {
  _set?: Maybe<C_WindBorneDebris_Set_Input>;
  where: C_WindBorneDebris_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_WindBorneDebris_By_PkArgs = {
  _set?: Maybe<C_WindBorneDebris_Set_Input>;
  pk_columns: C_WindBorneDebris_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_WoodSpeciesArgs = {
  _set?: Maybe<C_WoodSpecies_Set_Input>;
  where: C_WoodSpecies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_WoodSpecies_By_PkArgs = {
  _set?: Maybe<C_WoodSpecies_Set_Input>;
  pk_columns: C_WoodSpecies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_WoodTypeArgs = {
  _set?: Maybe<C_WoodType_Set_Input>;
  where: C_WoodType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_WoodType_By_PkArgs = {
  _set?: Maybe<C_WoodType_Set_Input>;
  pk_columns: C_WoodType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_C_WorkflowStatusArgs = {
  _set?: Maybe<C_WorkflowStatus_Set_Input>;
  where: C_WorkflowStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_C_WorkflowStatus_By_PkArgs = {
  _set?: Maybe<C_WorkflowStatus_Set_Input>;
  pk_columns: C_WorkflowStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CapPlyArgs = {
  _inc?: Maybe<CapPly_Inc_Input>;
  _set?: Maybe<CapPly_Set_Input>;
  where: CapPly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CapPlyUsageArgs = {
  _inc?: Maybe<CapPlyUsage_Inc_Input>;
  _set?: Maybe<CapPlyUsage_Set_Input>;
  where: CapPlyUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CapPlyUsage_By_PkArgs = {
  _inc?: Maybe<CapPlyUsage_Inc_Input>;
  _set?: Maybe<CapPlyUsage_Set_Input>;
  pk_columns: CapPlyUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CapPly_By_PkArgs = {
  _inc?: Maybe<CapPly_Inc_Input>;
  _set?: Maybe<CapPly_Set_Input>;
  pk_columns: CapPly_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CategoryArgs = {
  _set?: Maybe<Category_Set_Input>;
  where: Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CategoryLogArgs = {
  _inc?: Maybe<CategoryLog_Inc_Input>;
  _set?: Maybe<CategoryLog_Set_Input>;
  where: CategoryLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CategoryLog_By_PkArgs = {
  _inc?: Maybe<CategoryLog_Inc_Input>;
  _set?: Maybe<CategoryLog_Set_Input>;
  pk_columns: CategoryLog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Category_CoatingArgs = {
  _inc?: Maybe<Category_Coating_Inc_Input>;
  _set?: Maybe<Category_Coating_Set_Input>;
  where: Category_Coating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_CoverArgs = {
  _inc?: Maybe<Category_Cover_Inc_Input>;
  _set?: Maybe<Category_Cover_Set_Input>;
  where: Category_Cover_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_DeckArgs = {
  _inc?: Maybe<Category_Deck_Inc_Input>;
  _set?: Maybe<Category_Deck_Set_Input>;
  where: Category_Deck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_FlashingArgs = {
  _inc?: Maybe<Category_Flashing_Inc_Input>;
  _set?: Maybe<Category_Flashing_Set_Input>;
  where: Category_Flashing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_InsulationArgs = {
  _inc?: Maybe<Category_Insulation_Inc_Input>;
  _set?: Maybe<Category_Insulation_Set_Input>;
  where: Category_Insulation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_OtherArgs = {
  _inc?: Maybe<Category_Other_Inc_Input>;
  _set?: Maybe<Category_Other_Set_Input>;
  where: Category_Other_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_SeamingArgs = {
  _inc?: Maybe<Category_Seaming_Inc_Input>;
  _set?: Maybe<Category_Seaming_Set_Input>;
  where: Category_Seaming_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_SecurementArgs = {
  _inc?: Maybe<Category_Securement_Inc_Input>;
  _set?: Maybe<Category_Securement_Set_Input>;
  where: Category_Securement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_SheetArgs = {
  _inc?: Maybe<Category_Sheet_Inc_Input>;
  _set?: Maybe<Category_Sheet_Set_Input>;
  where: Category_Sheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_By_PkArgs = {
  _set?: Maybe<Category_Set_Input>;
  pk_columns: Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CementitiousWoodFiberDeckArgs = {
  _inc?: Maybe<CementitiousWoodFiberDeck_Inc_Input>;
  _set?: Maybe<CementitiousWoodFiberDeck_Set_Input>;
  where: CementitiousWoodFiberDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CementitiousWoodFiberDeckUsageArgs = {
  _inc?: Maybe<CementitiousWoodFiberDeckUsage_Inc_Input>;
  _set?: Maybe<CementitiousWoodFiberDeckUsage_Set_Input>;
  where: CementitiousWoodFiberDeckUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CementitiousWoodFiberDeckUsage_By_PkArgs = {
  _inc?: Maybe<CementitiousWoodFiberDeckUsage_Inc_Input>;
  _set?: Maybe<CementitiousWoodFiberDeckUsage_Set_Input>;
  pk_columns: CementitiousWoodFiberDeckUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CementitiousWoodFiberDeck_By_PkArgs = {
  _inc?: Maybe<CementitiousWoodFiberDeck_Inc_Input>;
  _set?: Maybe<CementitiousWoodFiberDeck_Set_Input>;
  pk_columns: CementitiousWoodFiberDeck_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ClipArgs = {
  _inc?: Maybe<Clip_Inc_Input>;
  _set?: Maybe<Clip_Set_Input>;
  where: Clip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ClipUsageArgs = {
  _inc?: Maybe<ClipUsage_Inc_Input>;
  _set?: Maybe<ClipUsage_Set_Input>;
  where: ClipUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ClipUsage_By_PkArgs = {
  _inc?: Maybe<ClipUsage_Inc_Input>;
  _set?: Maybe<ClipUsage_Set_Input>;
  pk_columns: ClipUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Clip_By_PkArgs = {
  _inc?: Maybe<Clip_Inc_Input>;
  _set?: Maybe<Clip_Set_Input>;
  pk_columns: Clip_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CoatArgs = {
  _inc?: Maybe<Coat_Inc_Input>;
  _set?: Maybe<Coat_Set_Input>;
  where: Coat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CoatUsageArgs = {
  _inc?: Maybe<CoatUsage_Inc_Input>;
  _set?: Maybe<CoatUsage_Set_Input>;
  where: CoatUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CoatUsage_By_PkArgs = {
  _inc?: Maybe<CoatUsage_Inc_Input>;
  _set?: Maybe<CoatUsage_Set_Input>;
  pk_columns: CoatUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Coat_By_PkArgs = {
  _inc?: Maybe<Coat_Inc_Input>;
  _set?: Maybe<Coat_Set_Input>;
  pk_columns: Coat_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CoatingArgs = {
  _inc?: Maybe<Coating_Inc_Input>;
  _set?: Maybe<Coating_Set_Input>;
  where: Coating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CoatingSystemUsageArgs = {
  _inc?: Maybe<CoatingSystemUsage_Inc_Input>;
  _set?: Maybe<CoatingSystemUsage_Set_Input>;
  where: CoatingSystemUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CoatingSystemUsage_By_PkArgs = {
  _inc?: Maybe<CoatingSystemUsage_Inc_Input>;
  _set?: Maybe<CoatingSystemUsage_Set_Input>;
  pk_columns: CoatingSystemUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CoatingUsageArgs = {
  _inc?: Maybe<CoatingUsage_Inc_Input>;
  _set?: Maybe<CoatingUsage_Set_Input>;
  where: CoatingUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CoatingUsage_By_PkArgs = {
  _inc?: Maybe<CoatingUsage_Inc_Input>;
  _set?: Maybe<CoatingUsage_Set_Input>;
  pk_columns: CoatingUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Coating_By_PkArgs = {
  _inc?: Maybe<Coating_Inc_Input>;
  _set?: Maybe<Coating_Set_Input>;
  pk_columns: Coating_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ComponentArgs = {
  _inc?: Maybe<Component_Inc_Input>;
  _set?: Maybe<Component_Set_Input>;
  where: Component_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ComponentApprovedUseArgs = {
  _inc?: Maybe<ComponentApprovedUse_Inc_Input>;
  _set?: Maybe<ComponentApprovedUse_Set_Input>;
  where: ComponentApprovedUse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ComponentApprovedUse_By_PkArgs = {
  _inc?: Maybe<ComponentApprovedUse_Inc_Input>;
  _set?: Maybe<ComponentApprovedUse_Set_Input>;
  pk_columns: ComponentApprovedUse_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ComponentDocArgs = {
  _inc?: Maybe<ComponentDoc_Inc_Input>;
  _set?: Maybe<ComponentDoc_Set_Input>;
  where: ComponentDoc_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ComponentDoc_By_PkArgs = {
  _inc?: Maybe<ComponentDoc_Inc_Input>;
  _set?: Maybe<ComponentDoc_Set_Input>;
  pk_columns: ComponentDoc_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ComponentLayerArgs = {
  _inc?: Maybe<ComponentLayer_Inc_Input>;
  _set?: Maybe<ComponentLayer_Set_Input>;
  where: ComponentLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ComponentLayerUsageArgs = {
  _inc?: Maybe<ComponentLayerUsage_Inc_Input>;
  _set?: Maybe<ComponentLayerUsage_Set_Input>;
  where: ComponentLayerUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ComponentLayerUsage_By_PkArgs = {
  _inc?: Maybe<ComponentLayerUsage_Inc_Input>;
  _set?: Maybe<ComponentLayerUsage_Set_Input>;
  pk_columns: ComponentLayerUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ComponentLayer_By_PkArgs = {
  _inc?: Maybe<ComponentLayer_Inc_Input>;
  _set?: Maybe<ComponentLayer_Set_Input>;
  pk_columns: ComponentLayer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Component_By_PkArgs = {
  _inc?: Maybe<Component_Inc_Input>;
  _set?: Maybe<Component_Set_Input>;
  pk_columns: Component_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompositePanelCoverArgs = {
  _inc?: Maybe<CompositePanelCover_Inc_Input>;
  _set?: Maybe<CompositePanelCover_Set_Input>;
  where: CompositePanelCover_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompositePanelCover_By_PkArgs = {
  _inc?: Maybe<CompositePanelCover_Inc_Input>;
  _set?: Maybe<CompositePanelCover_Set_Input>;
  pk_columns: CompositePanelCover_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompositePanelUsageArgs = {
  _inc?: Maybe<CompositePanelUsage_Inc_Input>;
  _set?: Maybe<CompositePanelUsage_Set_Input>;
  where: CompositePanelUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompositePanelUsage_By_PkArgs = {
  _inc?: Maybe<CompositePanelUsage_Inc_Input>;
  _set?: Maybe<CompositePanelUsage_Set_Input>;
  pk_columns: CompositePanelUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContactInfoArgs = {
  _inc?: Maybe<ContactInfo_Inc_Input>;
  _set?: Maybe<ContactInfo_Set_Input>;
  where: ContactInfo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContactInfo_By_PkArgs = {
  _inc?: Maybe<ContactInfo_Inc_Input>;
  _set?: Maybe<ContactInfo_Set_Input>;
  pk_columns: ContactInfo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CopingArgs = {
  _inc?: Maybe<Coping_Inc_Input>;
  _set?: Maybe<Coping_Set_Input>;
  where: Coping_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Coping_By_PkArgs = {
  _inc?: Maybe<Coping_Inc_Input>;
  _set?: Maybe<Coping_Set_Input>;
  pk_columns: Coping_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CoverBoardArgs = {
  _inc?: Maybe<CoverBoard_Inc_Input>;
  _set?: Maybe<CoverBoard_Set_Input>;
  where: CoverBoard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CoverBoardBoardSizesArgs = {
  _inc?: Maybe<CoverBoardBoardSizes_Inc_Input>;
  _set?: Maybe<CoverBoardBoardSizes_Set_Input>;
  where: CoverBoardBoardSizes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CoverBoardBoardSizes_By_PkArgs = {
  _inc?: Maybe<CoverBoardBoardSizes_Inc_Input>;
  _set?: Maybe<CoverBoardBoardSizes_Set_Input>;
  pk_columns: CoverBoardBoardSizes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CoverBoardFacerBottomArgs = {
  _inc?: Maybe<CoverBoardFacerBottom_Inc_Input>;
  _set?: Maybe<CoverBoardFacerBottom_Set_Input>;
  where: CoverBoardFacerBottom_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CoverBoardFacerBottom_By_PkArgs = {
  _inc?: Maybe<CoverBoardFacerBottom_Inc_Input>;
  _set?: Maybe<CoverBoardFacerBottom_Set_Input>;
  pk_columns: CoverBoardFacerBottom_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CoverBoardFacerTopArgs = {
  _inc?: Maybe<CoverBoardFacerTop_Inc_Input>;
  _set?: Maybe<CoverBoardFacerTop_Set_Input>;
  where: CoverBoardFacerTop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CoverBoardFacerTop_By_PkArgs = {
  _inc?: Maybe<CoverBoardFacerTop_Inc_Input>;
  _set?: Maybe<CoverBoardFacerTop_Set_Input>;
  pk_columns: CoverBoardFacerTop_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CoverBoardUsageArgs = {
  _inc?: Maybe<CoverBoardUsage_Inc_Input>;
  _set?: Maybe<CoverBoardUsage_Set_Input>;
  where: CoverBoardUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CoverBoardUsage_By_PkArgs = {
  _inc?: Maybe<CoverBoardUsage_Inc_Input>;
  _set?: Maybe<CoverBoardUsage_Set_Input>;
  pk_columns: CoverBoardUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CoverBoard_By_PkArgs = {
  _inc?: Maybe<CoverBoard_Inc_Input>;
  _set?: Maybe<CoverBoard_Set_Input>;
  pk_columns: CoverBoard_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CoverStripDiskArgs = {
  _inc?: Maybe<CoverStripDisk_Inc_Input>;
  _set?: Maybe<CoverStripDisk_Set_Input>;
  where: CoverStripDisk_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CoverStripDiskUsageArgs = {
  _inc?: Maybe<CoverStripDiskUsage_Inc_Input>;
  _set?: Maybe<CoverStripDiskUsage_Set_Input>;
  where: CoverStripDiskUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CoverStripDiskUsage_By_PkArgs = {
  _inc?: Maybe<CoverStripDiskUsage_Inc_Input>;
  _set?: Maybe<CoverStripDiskUsage_Set_Input>;
  pk_columns: CoverStripDiskUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CoverStripDisk_By_PkArgs = {
  _inc?: Maybe<CoverStripDisk_Inc_Input>;
  _set?: Maybe<CoverStripDisk_Set_Input>;
  pk_columns: CoverStripDisk_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExistingRoofLayerArgs = {
  _inc?: Maybe<ExistingRoofLayer_Inc_Input>;
  _set?: Maybe<ExistingRoofLayer_Set_Input>;
  where: ExistingRoofLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExistingRoofLayer_By_PkArgs = {
  _inc?: Maybe<ExistingRoofLayer_Inc_Input>;
  _set?: Maybe<ExistingRoofLayer_Set_Input>;
  pk_columns: ExistingRoofLayer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExpansionJointArgs = {
  _inc?: Maybe<ExpansionJoint_Inc_Input>;
  _set?: Maybe<ExpansionJoint_Set_Input>;
  where: ExpansionJoint_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExpansionJointUsageArgs = {
  _inc?: Maybe<ExpansionJointUsage_Inc_Input>;
  _set?: Maybe<ExpansionJointUsage_Set_Input>;
  where: ExpansionJointUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExpansionJointUsage_By_PkArgs = {
  _inc?: Maybe<ExpansionJointUsage_Inc_Input>;
  _set?: Maybe<ExpansionJointUsage_Set_Input>;
  pk_columns: ExpansionJointUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExpansionJoint_By_PkArgs = {
  _inc?: Maybe<ExpansionJoint_Inc_Input>;
  _set?: Maybe<ExpansionJoint_Set_Input>;
  pk_columns: ExpansionJoint_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ExtendedLayerConfigSplitArgs = {
  _set?: Maybe<ExtendedLayerConfigSplit_Set_Input>;
  where: ExtendedLayerConfigSplit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ExtendedLayerConfigSplit_By_PkArgs = {
  _set?: Maybe<ExtendedLayerConfigSplit_Set_Input>;
  pk_columns: ExtendedLayerConfigSplit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FmUserAccountArgs = {
  _inc?: Maybe<FmUserAccount_Inc_Input>;
  _set?: Maybe<FmUserAccount_Set_Input>;
  where: FmUserAccount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FmUserAccount_By_PkArgs = {
  _inc?: Maybe<FmUserAccount_Inc_Input>;
  _set?: Maybe<FmUserAccount_Set_Input>;
  pk_columns: FmUserAccount_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FasciaArgs = {
  _inc?: Maybe<Fascia_Inc_Input>;
  _set?: Maybe<Fascia_Set_Input>;
  where: Fascia_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fascia_By_PkArgs = {
  _inc?: Maybe<Fascia_Inc_Input>;
  _set?: Maybe<Fascia_Set_Input>;
  pk_columns: Fascia_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FastenerArgs = {
  _inc?: Maybe<Fastener_Inc_Input>;
  _set?: Maybe<Fastener_Set_Input>;
  where: Fastener_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FastenerUsageArgs = {
  _inc?: Maybe<FastenerUsage_Inc_Input>;
  _set?: Maybe<FastenerUsage_Set_Input>;
  where: FastenerUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FastenerUsage_By_PkArgs = {
  _inc?: Maybe<FastenerUsage_Inc_Input>;
  _set?: Maybe<FastenerUsage_Set_Input>;
  pk_columns: FastenerUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fastener_By_PkArgs = {
  _inc?: Maybe<Fastener_Inc_Input>;
  _set?: Maybe<Fastener_Set_Input>;
  pk_columns: Fastener_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FasteningSystemBattenBarUsageArgs = {
  _inc?: Maybe<FasteningSystemBattenBarUsage_Inc_Input>;
  _set?: Maybe<FasteningSystemBattenBarUsage_Set_Input>;
  where: FasteningSystemBattenBarUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FasteningSystemBattenBarUsage_By_PkArgs = {
  _inc?: Maybe<FasteningSystemBattenBarUsage_Inc_Input>;
  _set?: Maybe<FasteningSystemBattenBarUsage_Set_Input>;
  pk_columns: FasteningSystemBattenBarUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FasteningSystemClipUsageArgs = {
  _inc?: Maybe<FasteningSystemClipUsage_Inc_Input>;
  _set?: Maybe<FasteningSystemClipUsage_Set_Input>;
  where: FasteningSystemClipUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FasteningSystemClipUsage_By_PkArgs = {
  _inc?: Maybe<FasteningSystemClipUsage_Inc_Input>;
  _set?: Maybe<FasteningSystemClipUsage_Set_Input>;
  pk_columns: FasteningSystemClipUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FasteningSystemStressPlateUsageArgs = {
  _inc?: Maybe<FasteningSystemStressPlateUsage_Inc_Input>;
  _set?: Maybe<FasteningSystemStressPlateUsage_Set_Input>;
  where: FasteningSystemStressPlateUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FasteningSystemStressPlateUsage_By_PkArgs = {
  _inc?: Maybe<FasteningSystemStressPlateUsage_Inc_Input>;
  _set?: Maybe<FasteningSystemStressPlateUsage_Set_Input>;
  pk_columns: FasteningSystemStressPlateUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FiberReinforcedPlasticDeckArgs = {
  _inc?: Maybe<FiberReinforcedPlasticDeck_Inc_Input>;
  _set?: Maybe<FiberReinforcedPlasticDeck_Set_Input>;
  where: FiberReinforcedPlasticDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FiberReinforcedPlasticDeckUsageArgs = {
  _inc?: Maybe<FiberReinforcedPlasticDeckUsage_Inc_Input>;
  _set?: Maybe<FiberReinforcedPlasticDeckUsage_Set_Input>;
  where: FiberReinforcedPlasticDeckUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FiberReinforcedPlasticDeckUsage_By_PkArgs = {
  _inc?: Maybe<FiberReinforcedPlasticDeckUsage_Inc_Input>;
  _set?: Maybe<FiberReinforcedPlasticDeckUsage_Set_Input>;
  pk_columns: FiberReinforcedPlasticDeckUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FiberReinforcedPlasticDeck_By_PkArgs = {
  _inc?: Maybe<FiberReinforcedPlasticDeck_Inc_Input>;
  _set?: Maybe<FiberReinforcedPlasticDeck_Set_Input>;
  pk_columns: FiberReinforcedPlasticDeck_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FormDeckArgs = {
  _inc?: Maybe<FormDeck_Inc_Input>;
  _set?: Maybe<FormDeck_Set_Input>;
  where: FormDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FormDeckUsageArgs = {
  _inc?: Maybe<FormDeckUsage_Inc_Input>;
  _set?: Maybe<FormDeckUsage_Set_Input>;
  where: FormDeckUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FormDeckUsage_By_PkArgs = {
  _inc?: Maybe<FormDeckUsage_Inc_Input>;
  _set?: Maybe<FormDeckUsage_Set_Input>;
  pk_columns: FormDeckUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FormDeck_By_PkArgs = {
  _inc?: Maybe<FormDeck_Inc_Input>;
  _set?: Maybe<FormDeck_Set_Input>;
  pk_columns: FormDeck_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GutterArgs = {
  _inc?: Maybe<Gutter_Inc_Input>;
  _set?: Maybe<Gutter_Set_Input>;
  where: Gutter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gutter_By_PkArgs = {
  _inc?: Maybe<Gutter_Inc_Input>;
  _set?: Maybe<Gutter_Set_Input>;
  pk_columns: Gutter_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GypsumDeckArgs = {
  _inc?: Maybe<GypsumDeck_Inc_Input>;
  _set?: Maybe<GypsumDeck_Set_Input>;
  where: GypsumDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GypsumDeckUsageArgs = {
  _inc?: Maybe<GypsumDeckUsage_Inc_Input>;
  _set?: Maybe<GypsumDeckUsage_Set_Input>;
  where: GypsumDeckUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GypsumDeckUsage_By_PkArgs = {
  _inc?: Maybe<GypsumDeckUsage_Inc_Input>;
  _set?: Maybe<GypsumDeckUsage_Set_Input>;
  pk_columns: GypsumDeckUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GypsumDeck_By_PkArgs = {
  _inc?: Maybe<GypsumDeck_Inc_Input>;
  _set?: Maybe<GypsumDeck_Set_Input>;
  pk_columns: GypsumDeck_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InsBoardSizeArgs = {
  _set?: Maybe<InsBoardSize_Set_Input>;
  where: InsBoardSize_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InsBoardSize_By_PkArgs = {
  _set?: Maybe<InsBoardSize_Set_Input>;
  pk_columns: InsBoardSize_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ItemWorkspaceArgs = {
  _inc?: Maybe<ItemWorkspace_Inc_Input>;
  _set?: Maybe<ItemWorkspace_Set_Input>;
  where: ItemWorkspace_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ItemWorkspaceDataArgs = {
  _inc?: Maybe<ItemWorkspaceData_Inc_Input>;
  _set?: Maybe<ItemWorkspaceData_Set_Input>;
  where: ItemWorkspaceData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ItemWorkspaceData_By_PkArgs = {
  _inc?: Maybe<ItemWorkspaceData_Inc_Input>;
  _set?: Maybe<ItemWorkspaceData_Set_Input>;
  pk_columns: ItemWorkspaceData_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ItemWorkspace_By_PkArgs = {
  _inc?: Maybe<ItemWorkspace_Inc_Input>;
  _set?: Maybe<ItemWorkspace_Set_Input>;
  pk_columns: ItemWorkspace_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LapSealantArgs = {
  _inc?: Maybe<LapSealant_Inc_Input>;
  _set?: Maybe<LapSealant_Set_Input>;
  where: LapSealant_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LapSealantUsageArgs = {
  _inc?: Maybe<LapSealantUsage_Inc_Input>;
  _set?: Maybe<LapSealantUsage_Set_Input>;
  where: LapSealantUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LapSealantUsage_By_PkArgs = {
  _inc?: Maybe<LapSealantUsage_Inc_Input>;
  _set?: Maybe<LapSealantUsage_Set_Input>;
  pk_columns: LapSealantUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LapSealant_By_PkArgs = {
  _inc?: Maybe<LapSealant_Inc_Input>;
  _set?: Maybe<LapSealant_Set_Input>;
  pk_columns: LapSealant_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LapSecurementInfoArgs = {
  _inc?: Maybe<LapSecurementInfo_Inc_Input>;
  _set?: Maybe<LapSecurementInfo_Set_Input>;
  where: LapSecurementInfo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LapSecurementInfo_By_PkArgs = {
  _inc?: Maybe<LapSecurementInfo_Inc_Input>;
  _set?: Maybe<LapSecurementInfo_Set_Input>;
  pk_columns: LapSecurementInfo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LayerArgs = {
  _inc?: Maybe<Layer_Inc_Input>;
  _set?: Maybe<Layer_Set_Input>;
  where: Layer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LayerConfigArgs = {
  _set?: Maybe<LayerConfig_Set_Input>;
  where: LayerConfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LayerConfig_By_PkArgs = {
  _set?: Maybe<LayerConfig_Set_Input>;
  pk_columns: LayerConfig_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Layer_By_PkArgs = {
  _inc?: Maybe<Layer_Inc_Input>;
  _set?: Maybe<Layer_Set_Input>;
  pk_columns: Layer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LightweightInsulatingConcreteArgs = {
  _inc?: Maybe<LightweightInsulatingConcrete_Inc_Input>;
  _set?: Maybe<LightweightInsulatingConcrete_Set_Input>;
  where: LightweightInsulatingConcrete_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LightweightInsulatingConcreteSubassemblyUsageArgs = {
  _inc?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Inc_Input>;
  _set?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Set_Input>;
  where: LightweightInsulatingConcreteSubassemblyUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LightweightInsulatingConcreteSubassemblyUsage_By_PkArgs = {
  _inc?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Inc_Input>;
  _set?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Set_Input>;
  pk_columns: LightweightInsulatingConcreteSubassemblyUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LightweightInsulatingConcreteUsageArgs = {
  _inc?: Maybe<LightweightInsulatingConcreteUsage_Inc_Input>;
  _set?: Maybe<LightweightInsulatingConcreteUsage_Set_Input>;
  where: LightweightInsulatingConcreteUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LightweightInsulatingConcreteUsage_By_PkArgs = {
  _inc?: Maybe<LightweightInsulatingConcreteUsage_Inc_Input>;
  _set?: Maybe<LightweightInsulatingConcreteUsage_Set_Input>;
  pk_columns: LightweightInsulatingConcreteUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LightweightInsulatingConcrete_By_PkArgs = {
  _inc?: Maybe<LightweightInsulatingConcrete_Inc_Input>;
  _set?: Maybe<LightweightInsulatingConcrete_Set_Input>;
  pk_columns: LightweightInsulatingConcrete_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LinerPanelArgs = {
  _inc?: Maybe<LinerPanel_Inc_Input>;
  _set?: Maybe<LinerPanel_Set_Input>;
  where: LinerPanel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LinerPanelUsageArgs = {
  _inc?: Maybe<LinerPanelUsage_Inc_Input>;
  _set?: Maybe<LinerPanelUsage_Set_Input>;
  where: LinerPanelUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LinerPanelUsage_By_PkArgs = {
  _inc?: Maybe<LinerPanelUsage_Inc_Input>;
  _set?: Maybe<LinerPanelUsage_Set_Input>;
  pk_columns: LinerPanelUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LinerPanel_By_PkArgs = {
  _inc?: Maybe<LinerPanel_Inc_Input>;
  _set?: Maybe<LinerPanel_Set_Input>;
  pk_columns: LinerPanel_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LiquidAppliedSubassemblyUsageArgs = {
  _inc?: Maybe<LiquidAppliedSubassemblyUsage_Inc_Input>;
  _set?: Maybe<LiquidAppliedSubassemblyUsage_Set_Input>;
  where: LiquidAppliedSubassemblyUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LiquidAppliedSubassemblyUsage_By_PkArgs = {
  _inc?: Maybe<LiquidAppliedSubassemblyUsage_Inc_Input>;
  _set?: Maybe<LiquidAppliedSubassemblyUsage_Set_Input>;
  pk_columns: LiquidAppliedSubassemblyUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LiquidAppliedUsageArgs = {
  _inc?: Maybe<LiquidAppliedUsage_Inc_Input>;
  _set?: Maybe<LiquidAppliedUsage_Set_Input>;
  where: LiquidAppliedUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LiquidAppliedUsage_By_PkArgs = {
  _inc?: Maybe<LiquidAppliedUsage_Inc_Input>;
  _set?: Maybe<LiquidAppliedUsage_Set_Input>;
  pk_columns: LiquidAppliedUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Load_SearchLayerUsage_DeckFiberReinforcedPlasticArgs = {
  _inc?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Inc_Input>;
  _set?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Set_Input>;
  where: Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_ApprovedUseArgs = {
  _inc?: Maybe<Md_ApprovedUse_Inc_Input>;
  _set?: Maybe<Md_ApprovedUse_Set_Input>;
  where: Md_ApprovedUse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_ApprovedUseAttributesArgs = {
  _inc?: Maybe<Md_ApprovedUseAttributes_Inc_Input>;
  _set?: Maybe<Md_ApprovedUseAttributes_Set_Input>;
  where: Md_ApprovedUseAttributes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_ApprovedUseAttributes_By_PkArgs = {
  _inc?: Maybe<Md_ApprovedUseAttributes_Inc_Input>;
  _set?: Maybe<Md_ApprovedUseAttributes_Set_Input>;
  pk_columns: Md_ApprovedUseAttributes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_ApprovedUse_By_PkArgs = {
  _inc?: Maybe<Md_ApprovedUse_Inc_Input>;
  _set?: Maybe<Md_ApprovedUse_Set_Input>;
  pk_columns: Md_ApprovedUse_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_AttributeBaseArgs = {
  _inc?: Maybe<Md_AttributeBase_Inc_Input>;
  _set?: Maybe<Md_AttributeBase_Set_Input>;
  where: Md_AttributeBase_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_AttributeBase_By_PkArgs = {
  _inc?: Maybe<Md_AttributeBase_Inc_Input>;
  _set?: Maybe<Md_AttributeBase_Set_Input>;
  pk_columns: Md_AttributeBase_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_BooleanAttributeArgs = {
  _inc?: Maybe<Md_BooleanAttribute_Inc_Input>;
  _set?: Maybe<Md_BooleanAttribute_Set_Input>;
  where: Md_BooleanAttribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_BooleanAttribute_By_PkArgs = {
  _inc?: Maybe<Md_BooleanAttribute_Inc_Input>;
  _set?: Maybe<Md_BooleanAttribute_Set_Input>;
  pk_columns: Md_BooleanAttribute_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_BooleanDisplayOptionArgs = {
  _inc?: Maybe<Md_BooleanDisplayOption_Inc_Input>;
  _set?: Maybe<Md_BooleanDisplayOption_Set_Input>;
  where: Md_BooleanDisplayOption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_BooleanDisplayOption_By_PkArgs = {
  _inc?: Maybe<Md_BooleanDisplayOption_Inc_Input>;
  _set?: Maybe<Md_BooleanDisplayOption_Set_Input>;
  pk_columns: Md_BooleanDisplayOption_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_CatalogArgs = {
  _inc?: Maybe<Md_Catalog_Inc_Input>;
  _set?: Maybe<Md_Catalog_Set_Input>;
  where: Md_Catalog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_Catalog_By_PkArgs = {
  _inc?: Maybe<Md_Catalog_Inc_Input>;
  _set?: Maybe<Md_Catalog_Set_Input>;
  pk_columns: Md_Catalog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_CodeTableAttributeArgs = {
  _inc?: Maybe<Md_CodeTableAttribute_Inc_Input>;
  _set?: Maybe<Md_CodeTableAttribute_Set_Input>;
  where: Md_CodeTableAttribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_CodeTableAttribute_By_PkArgs = {
  _inc?: Maybe<Md_CodeTableAttribute_Inc_Input>;
  _set?: Maybe<Md_CodeTableAttribute_Set_Input>;
  pk_columns: Md_CodeTableAttribute_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_CodeTablesArgs = {
  _inc?: Maybe<Md_CodeTables_Inc_Input>;
  _set?: Maybe<Md_CodeTables_Set_Input>;
  where: Md_CodeTables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_CodeTables_By_PkArgs = {
  _inc?: Maybe<Md_CodeTables_Inc_Input>;
  _set?: Maybe<Md_CodeTables_Set_Input>;
  pk_columns: Md_CodeTables_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_ComponentUsageArgs = {
  _inc?: Maybe<Md_ComponentUsage_Inc_Input>;
  _set?: Maybe<Md_ComponentUsage_Set_Input>;
  where: Md_ComponentUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_ComponentUsageAttributesArgs = {
  _inc?: Maybe<Md_ComponentUsageAttributes_Inc_Input>;
  _set?: Maybe<Md_ComponentUsageAttributes_Set_Input>;
  where: Md_ComponentUsageAttributes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_ComponentUsageAttributes_By_PkArgs = {
  _inc?: Maybe<Md_ComponentUsageAttributes_Inc_Input>;
  _set?: Maybe<Md_ComponentUsageAttributes_Set_Input>;
  pk_columns: Md_ComponentUsageAttributes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_ComponentUsage_By_PkArgs = {
  _inc?: Maybe<Md_ComponentUsage_Inc_Input>;
  _set?: Maybe<Md_ComponentUsage_Set_Input>;
  pk_columns: Md_ComponentUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_MeasureArgs = {
  _inc?: Maybe<Md_Measure_Inc_Input>;
  _set?: Maybe<Md_Measure_Set_Input>;
  where: Md_Measure_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_Measure_By_PkArgs = {
  _inc?: Maybe<Md_Measure_Inc_Input>;
  _set?: Maybe<Md_Measure_Set_Input>;
  pk_columns: Md_Measure_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_MeasurementAttributeArgs = {
  _inc?: Maybe<Md_MeasurementAttribute_Inc_Input>;
  _set?: Maybe<Md_MeasurementAttribute_Set_Input>;
  where: Md_MeasurementAttribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_MeasurementAttribute_By_PkArgs = {
  _inc?: Maybe<Md_MeasurementAttribute_Inc_Input>;
  _set?: Maybe<Md_MeasurementAttribute_Set_Input>;
  pk_columns: Md_MeasurementAttribute_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_MeasurementDisplayOptionArgs = {
  _inc?: Maybe<Md_MeasurementDisplayOption_Inc_Input>;
  _set?: Maybe<Md_MeasurementDisplayOption_Set_Input>;
  where: Md_MeasurementDisplayOption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_MeasurementDisplayOption_By_PkArgs = {
  _inc?: Maybe<Md_MeasurementDisplayOption_Inc_Input>;
  _set?: Maybe<Md_MeasurementDisplayOption_Set_Input>;
  pk_columns: Md_MeasurementDisplayOption_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_MultivalueAttributeArgs = {
  _inc?: Maybe<Md_MultivalueAttribute_Inc_Input>;
  _set?: Maybe<Md_MultivalueAttribute_Set_Input>;
  where: Md_MultivalueAttribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_MultivalueAttribute_By_PkArgs = {
  _inc?: Maybe<Md_MultivalueAttribute_Inc_Input>;
  _set?: Maybe<Md_MultivalueAttribute_Set_Input>;
  pk_columns: Md_MultivalueAttribute_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_MultivalueDisplayOptionArgs = {
  _inc?: Maybe<Md_MultivalueDisplayOption_Inc_Input>;
  _set?: Maybe<Md_MultivalueDisplayOption_Set_Input>;
  where: Md_MultivalueDisplayOption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_MultivalueDisplayOption_By_PkArgs = {
  _inc?: Maybe<Md_MultivalueDisplayOption_Inc_Input>;
  _set?: Maybe<Md_MultivalueDisplayOption_Set_Input>;
  pk_columns: Md_MultivalueDisplayOption_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_ProductCategoryArgs = {
  _inc?: Maybe<Md_ProductCategory_Inc_Input>;
  _set?: Maybe<Md_ProductCategory_Set_Input>;
  where: Md_ProductCategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_ProductCategory_By_PkArgs = {
  _inc?: Maybe<Md_ProductCategory_Inc_Input>;
  _set?: Maybe<Md_ProductCategory_Set_Input>;
  pk_columns: Md_ProductCategory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Md_ProductSubcategoryArgs = {
  _inc?: Maybe<Md_ProductSubcategory_Inc_Input>;
  _set?: Maybe<Md_ProductSubcategory_Set_Input>;
  where: Md_ProductSubcategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Md_ProductSubcategory_By_PkArgs = {
  _inc?: Maybe<Md_ProductSubcategory_Inc_Input>;
  _set?: Maybe<Md_ProductSubcategory_Set_Input>;
  pk_columns: Md_ProductSubcategory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MaintenanceCoatingArgs = {
  _inc?: Maybe<MaintenanceCoating_Inc_Input>;
  _set?: Maybe<MaintenanceCoating_Set_Input>;
  where: MaintenanceCoating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MaintenanceCoatingSubstrateArgs = {
  _inc?: Maybe<MaintenanceCoatingSubstrate_Inc_Input>;
  _set?: Maybe<MaintenanceCoatingSubstrate_Set_Input>;
  where: MaintenanceCoatingSubstrate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MaintenanceCoatingSubstrate_By_PkArgs = {
  _inc?: Maybe<MaintenanceCoatingSubstrate_Inc_Input>;
  _set?: Maybe<MaintenanceCoatingSubstrate_Set_Input>;
  pk_columns: MaintenanceCoatingSubstrate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MaintenanceCoatingUsageArgs = {
  _inc?: Maybe<MaintenanceCoatingUsage_Inc_Input>;
  _set?: Maybe<MaintenanceCoatingUsage_Set_Input>;
  where: MaintenanceCoatingUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MaintenanceCoatingUsage_By_PkArgs = {
  _inc?: Maybe<MaintenanceCoatingUsage_Inc_Input>;
  _set?: Maybe<MaintenanceCoatingUsage_Set_Input>;
  pk_columns: MaintenanceCoatingUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MaintenanceCoating_By_PkArgs = {
  _inc?: Maybe<MaintenanceCoating_Inc_Input>;
  _set?: Maybe<MaintenanceCoating_Set_Input>;
  pk_columns: MaintenanceCoating_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ManufacturedProductArgs = {
  _inc?: Maybe<ManufacturedProduct_Inc_Input>;
  _set?: Maybe<ManufacturedProduct_Set_Input>;
  where: ManufacturedProduct_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ManufacturedProduct_By_PkArgs = {
  _inc?: Maybe<ManufacturedProduct_Inc_Input>;
  _set?: Maybe<ManufacturedProduct_Set_Input>;
  pk_columns: ManufacturedProduct_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ManufacturerArgs = {
  _inc?: Maybe<Manufacturer_Inc_Input>;
  _set?: Maybe<Manufacturer_Set_Input>;
  where: Manufacturer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ManufacturerChangeHistoryArgs = {
  _inc?: Maybe<ManufacturerChangeHistory_Inc_Input>;
  _set?: Maybe<ManufacturerChangeHistory_Set_Input>;
  where: ManufacturerChangeHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ManufacturerChangeHistory_By_PkArgs = {
  _inc?: Maybe<ManufacturerChangeHistory_Inc_Input>;
  _set?: Maybe<ManufacturerChangeHistory_Set_Input>;
  pk_columns: ManufacturerChangeHistory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ManufacturerListArgs = {
  _inc?: Maybe<ManufacturerList_Inc_Input>;
  _set?: Maybe<ManufacturerList_Set_Input>;
  where: ManufacturerList_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Manufacturer_By_PkArgs = {
  _inc?: Maybe<Manufacturer_Inc_Input>;
  _set?: Maybe<Manufacturer_Set_Input>;
  pk_columns: Manufacturer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MultiplyCoverConfigArgs = {
  _inc?: Maybe<MultiplyCoverConfig_Inc_Input>;
  _set?: Maybe<MultiplyCoverConfig_Set_Input>;
  where: MultiplyCoverConfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MultiplyCoverConfig_By_PkArgs = {
  _inc?: Maybe<MultiplyCoverConfig_Inc_Input>;
  _set?: Maybe<MultiplyCoverConfig_Set_Input>;
  pk_columns: MultiplyCoverConfig_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MultiplyCoverSubassemblyUsageArgs = {
  _inc?: Maybe<MultiplyCoverSubassemblyUsage_Inc_Input>;
  _set?: Maybe<MultiplyCoverSubassemblyUsage_Set_Input>;
  where: MultiplyCoverSubassemblyUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MultiplyCoverSubassemblyUsage_By_PkArgs = {
  _inc?: Maybe<MultiplyCoverSubassemblyUsage_Inc_Input>;
  _set?: Maybe<MultiplyCoverSubassemblyUsage_Set_Input>;
  pk_columns: MultiplyCoverSubassemblyUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NavAssemblyArgs = {
  _inc?: Maybe<NavAssembly_Inc_Input>;
  _set?: Maybe<NavAssembly_Set_Input>;
  where: NavAssembly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_NavAssemblyMultiplyInfoArgs = {
  _inc?: Maybe<NavAssemblyMultiplyInfo_Inc_Input>;
  _set?: Maybe<NavAssemblyMultiplyInfo_Set_Input>;
  where: NavAssemblyMultiplyInfo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_NavAssembly_By_PkArgs = {
  _inc?: Maybe<NavAssembly_Inc_Input>;
  _set?: Maybe<NavAssembly_Set_Input>;
  pk_columns: NavAssembly_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NavLayerArgs = {
  _inc?: Maybe<NavLayer_Inc_Input>;
  _set?: Maybe<NavLayer_Set_Input>;
  where: NavLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_NavLayer_By_PkArgs = {
  _inc?: Maybe<NavLayer_Inc_Input>;
  _set?: Maybe<NavLayer_Set_Input>;
  pk_columns: NavLayer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NavMultiplyCoverConfigArgs = {
  _inc?: Maybe<NavMultiplyCoverConfig_Inc_Input>;
  _set?: Maybe<NavMultiplyCoverConfig_Set_Input>;
  where: NavMultiplyCoverConfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_NavMultiplyCoverConfig_By_PkArgs = {
  _inc?: Maybe<NavMultiplyCoverConfig_Inc_Input>;
  _set?: Maybe<NavMultiplyCoverConfig_Set_Input>;
  pk_columns: NavMultiplyCoverConfig_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NavSecurementLayerArgs = {
  _inc?: Maybe<NavSecurementLayer_Inc_Input>;
  _set?: Maybe<NavSecurementLayer_Set_Input>;
  where: NavSecurementLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_NavSecurementLayer_By_PkArgs = {
  _inc?: Maybe<NavSecurementLayer_Inc_Input>;
  _set?: Maybe<NavSecurementLayer_Set_Input>;
  pk_columns: NavSecurementLayer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NavSecurementUsageArgs = {
  _inc?: Maybe<NavSecurementUsage_Inc_Input>;
  _set?: Maybe<NavSecurementUsage_Set_Input>;
  where: NavSecurementUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_NavSecurementUsage_By_PkArgs = {
  _inc?: Maybe<NavSecurementUsage_Inc_Input>;
  _set?: Maybe<NavSecurementUsage_Set_Input>;
  pk_columns: NavSecurementUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NavUsageArgs = {
  _inc?: Maybe<NavUsage_Inc_Input>;
  _set?: Maybe<NavUsage_Set_Input>;
  where: NavUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_NavUsageSaArgs = {
  _inc?: Maybe<NavUsageSa_Inc_Input>;
  _set?: Maybe<NavUsageSa_Set_Input>;
  where: NavUsageSa_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_NavUsageSa_By_PkArgs = {
  _inc?: Maybe<NavUsageSa_Inc_Input>;
  _set?: Maybe<NavUsageSa_Set_Input>;
  pk_columns: NavUsageSa_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NavUsage_By_PkArgs = {
  _inc?: Maybe<NavUsage_Inc_Input>;
  _set?: Maybe<NavUsage_Set_Input>;
  pk_columns: NavUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nav_SinglePlyBondingAdhesivesArgs = {
  _inc?: Maybe<Nav_SinglePlyBondingAdhesives_Inc_Input>;
  _set?: Maybe<Nav_SinglePlyBondingAdhesives_Set_Input>;
  where: Nav_SinglePlyBondingAdhesives_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nav_SinglePlyLapAdhesivesArgs = {
  _inc?: Maybe<Nav_SinglePlyLapAdhesives_Inc_Input>;
  _set?: Maybe<Nav_SinglePlyLapAdhesives_Set_Input>;
  where: Nav_SinglePlyLapAdhesives_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_NextAssemblyNumArgs = {
  _inc?: Maybe<NextAssemblyNum_Inc_Input>;
  _set?: Maybe<NextAssemblyNum_Set_Input>;
  where: NextAssemblyNum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_NextAssemblyNum_By_PkArgs = {
  _inc?: Maybe<NextAssemblyNum_Inc_Input>;
  _set?: Maybe<NextAssemblyNum_Set_Input>;
  pk_columns: NextAssemblyNum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NoteArgs = {
  _set?: Maybe<Note_Set_Input>;
  where: Note_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Note_By_PkArgs = {
  _set?: Maybe<Note_Set_Input>;
  pk_columns: Note_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OtherPlyArgs = {
  _inc?: Maybe<OtherPly_Inc_Input>;
  _set?: Maybe<OtherPly_Set_Input>;
  where: OtherPly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OtherPlyUsageArgs = {
  _inc?: Maybe<OtherPlyUsage_Inc_Input>;
  _set?: Maybe<OtherPlyUsage_Set_Input>;
  where: OtherPlyUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OtherPlyUsage_By_PkArgs = {
  _inc?: Maybe<OtherPlyUsage_Inc_Input>;
  _set?: Maybe<OtherPlyUsage_Set_Input>;
  pk_columns: OtherPlyUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OtherPly_By_PkArgs = {
  _inc?: Maybe<OtherPly_Inc_Input>;
  _set?: Maybe<OtherPly_Set_Input>;
  pk_columns: OtherPly_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PreassembledFasteningArgs = {
  _inc?: Maybe<PreassembledFastening_Inc_Input>;
  _set?: Maybe<PreassembledFastening_Set_Input>;
  where: PreassembledFastening_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PreassembledFasteningUsageArgs = {
  _inc?: Maybe<PreassembledFasteningUsage_Inc_Input>;
  _set?: Maybe<PreassembledFasteningUsage_Set_Input>;
  where: PreassembledFasteningUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PreassembledFasteningUsage_By_PkArgs = {
  _inc?: Maybe<PreassembledFasteningUsage_Inc_Input>;
  _set?: Maybe<PreassembledFasteningUsage_Set_Input>;
  pk_columns: PreassembledFasteningUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PreassembledFastening_By_PkArgs = {
  _inc?: Maybe<PreassembledFastening_Inc_Input>;
  _set?: Maybe<PreassembledFastening_Set_Input>;
  pk_columns: PreassembledFastening_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PrivateLabelArgs = {
  _inc?: Maybe<PrivateLabel_Inc_Input>;
  _set?: Maybe<PrivateLabel_Set_Input>;
  where: PrivateLabel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PrivateLabel_By_PkArgs = {
  _inc?: Maybe<PrivateLabel_Inc_Input>;
  _set?: Maybe<PrivateLabel_Set_Input>;
  pk_columns: PrivateLabel_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PrivateLabelerArgs = {
  _set?: Maybe<PrivateLabeler_Set_Input>;
  where: PrivateLabeler_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductDependenciesArgs = {
  _inc?: Maybe<ProductDependencies_Inc_Input>;
  _set?: Maybe<ProductDependencies_Set_Input>;
  where: ProductDependencies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductDependencies_By_PkArgs = {
  _inc?: Maybe<ProductDependencies_Inc_Input>;
  _set?: Maybe<ProductDependencies_Set_Input>;
  pk_columns: ProductDependencies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductsBySubcategoryArgs = {
  _inc?: Maybe<ProductsBySubcategory_Inc_Input>;
  _set?: Maybe<ProductsBySubcategory_Set_Input>;
  where: ProductsBySubcategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_AdhesiveTapeWidthArgs = {
  _inc?: Maybe<R_AdhesiveTapeWidth_Inc_Input>;
  _set?: Maybe<R_AdhesiveTapeWidth_Set_Input>;
  where: R_AdhesiveTapeWidth_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_AdhesiveTapeWidth_By_PkArgs = {
  _inc?: Maybe<R_AdhesiveTapeWidth_Inc_Input>;
  _set?: Maybe<R_AdhesiveTapeWidth_Set_Input>;
  pk_columns: R_AdhesiveTapeWidth_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_ApprovedUse_SubcategoryArgs = {
  _inc?: Maybe<R_ApprovedUse_Subcategory_Inc_Input>;
  _set?: Maybe<R_ApprovedUse_Subcategory_Set_Input>;
  where: R_ApprovedUse_Subcategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_ApprovedUse_Subcategory_By_PkArgs = {
  _inc?: Maybe<R_ApprovedUse_Subcategory_Inc_Input>;
  _set?: Maybe<R_ApprovedUse_Subcategory_Set_Input>;
  pk_columns: R_ApprovedUse_Subcategory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_ComponentType_ApprovedUseArgs = {
  _inc?: Maybe<R_ComponentType_ApprovedUse_Inc_Input>;
  _set?: Maybe<R_ComponentType_ApprovedUse_Set_Input>;
  where: R_ComponentType_ApprovedUse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_ComponentType_ApprovedUse_By_PkArgs = {
  _inc?: Maybe<R_ComponentType_ApprovedUse_Inc_Input>;
  _set?: Maybe<R_ComponentType_ApprovedUse_Set_Input>;
  pk_columns: R_ComponentType_ApprovedUse_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_CopingFastenerArgs = {
  _inc?: Maybe<R_CopingFastener_Inc_Input>;
  _set?: Maybe<R_CopingFastener_Set_Input>;
  where: R_CopingFastener_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_CopingFastener_By_PkArgs = {
  _inc?: Maybe<R_CopingFastener_Inc_Input>;
  _set?: Maybe<R_CopingFastener_Set_Input>;
  pk_columns: R_CopingFastener_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_Country_StateProvinceArgs = {
  _inc?: Maybe<R_Country_StateProvince_Inc_Input>;
  _set?: Maybe<R_Country_StateProvince_Set_Input>;
  where: R_Country_StateProvince_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_Country_StateProvince_By_PkArgs = {
  _inc?: Maybe<R_Country_StateProvince_Inc_Input>;
  _set?: Maybe<R_Country_StateProvince_Set_Input>;
  pk_columns: R_Country_StateProvince_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_ExpansionJoinFastenerArgs = {
  _inc?: Maybe<R_ExpansionJoinFastener_Inc_Input>;
  _set?: Maybe<R_ExpansionJoinFastener_Set_Input>;
  where: R_ExpansionJoinFastener_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_ExpansionJoinFastener_By_PkArgs = {
  _inc?: Maybe<R_ExpansionJoinFastener_Inc_Input>;
  _set?: Maybe<R_ExpansionJoinFastener_Set_Input>;
  pk_columns: R_ExpansionJoinFastener_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_FasciaComponentFastenerComponentArgs = {
  _inc?: Maybe<R_FasciaComponentFastenerComponent_Inc_Input>;
  _set?: Maybe<R_FasciaComponentFastenerComponent_Set_Input>;
  where: R_FasciaComponentFastenerComponent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_FasciaComponentFastenerComponent_By_PkArgs = {
  _inc?: Maybe<R_FasciaComponentFastenerComponent_Inc_Input>;
  _set?: Maybe<R_FasciaComponentFastenerComponent_Set_Input>;
  pk_columns: R_FasciaComponentFastenerComponent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_GutterFastenersArgs = {
  _inc?: Maybe<R_GutterFasteners_Inc_Input>;
  _set?: Maybe<R_GutterFasteners_Set_Input>;
  where: R_GutterFasteners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_GutterFasteners_By_PkArgs = {
  _inc?: Maybe<R_GutterFasteners_Inc_Input>;
  _set?: Maybe<R_GutterFasteners_Set_Input>;
  pk_columns: R_GutterFasteners_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_LinerPanelMaterialFiberReinforcedPlasticDeckArgs = {
  _inc?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Inc_Input>;
  _set?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Set_Input>;
  where: R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_LinerPanelMaterialFiberReinforcedPlasticDeck_By_PkArgs = {
  _inc?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Inc_Input>;
  _set?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Set_Input>;
  pk_columns: R_LinerPanelMaterialFiberReinforcedPlasticDeck_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_MaintenanceCoatingSubstrateArgs = {
  _inc?: Maybe<R_MaintenanceCoatingSubstrate_Inc_Input>;
  _set?: Maybe<R_MaintenanceCoatingSubstrate_Set_Input>;
  where: R_MaintenanceCoatingSubstrate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_MaintenanceCoatingSubstrate_By_PkArgs = {
  _inc?: Maybe<R_MaintenanceCoatingSubstrate_Inc_Input>;
  _set?: Maybe<R_MaintenanceCoatingSubstrate_Set_Input>;
  pk_columns: R_MaintenanceCoatingSubstrate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_NavAssembly_NavMultiplyCoverConfigArgs = {
  _inc?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Inc_Input>;
  _set?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Set_Input>;
  where: R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_NavAssembly_NavMultiplyCoverConfig_By_PkArgs = {
  _inc?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Inc_Input>;
  _set?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Set_Input>;
  pk_columns: R_NavAssembly_NavMultiplyCoverConfig_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_ProjectContactArgs = {
  _inc?: Maybe<R_ProjectContact_Inc_Input>;
  _set?: Maybe<R_ProjectContact_Set_Input>;
  where: R_ProjectContact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_ProjectContact_By_PkArgs = {
  _inc?: Maybe<R_ProjectContact_Inc_Input>;
  _set?: Maybe<R_ProjectContact_Set_Input>;
  pk_columns: R_ProjectContact_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_ProjectNoteArgs = {
  _inc?: Maybe<R_ProjectNote_Inc_Input>;
  _set?: Maybe<R_ProjectNote_Set_Input>;
  where: R_ProjectNote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_ProjectNote_By_PkArgs = {
  _inc?: Maybe<R_ProjectNote_Inc_Input>;
  _set?: Maybe<R_ProjectNote_Set_Input>;
  pk_columns: R_ProjectNote_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_RegletFastenerArgs = {
  _inc?: Maybe<R_RegletFastener_Inc_Input>;
  _set?: Maybe<R_RegletFastener_Set_Input>;
  where: R_RegletFastener_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_RegletFastener_By_PkArgs = {
  _inc?: Maybe<R_RegletFastener_Inc_Input>;
  _set?: Maybe<R_RegletFastener_Set_Input>;
  pk_columns: R_RegletFastener_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_RoofAreaContactArgs = {
  _inc?: Maybe<R_RoofAreaContact_Inc_Input>;
  _set?: Maybe<R_RoofAreaContact_Set_Input>;
  where: R_RoofAreaContact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_RoofAreaContact_By_PkArgs = {
  _inc?: Maybe<R_RoofAreaContact_Inc_Input>;
  _set?: Maybe<R_RoofAreaContact_Set_Input>;
  pk_columns: R_RoofAreaContact_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_RoofAreaNoteArgs = {
  _inc?: Maybe<R_RoofAreaNote_Inc_Input>;
  _set?: Maybe<R_RoofAreaNote_Set_Input>;
  where: R_RoofAreaNote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_RoofAreaNote_By_PkArgs = {
  _inc?: Maybe<R_RoofAreaNote_Inc_Input>;
  _set?: Maybe<R_RoofAreaNote_Set_Input>;
  pk_columns: R_RoofAreaNote_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_RoofAreaScenarioArgs = {
  _inc?: Maybe<R_RoofAreaScenario_Inc_Input>;
  _set?: Maybe<R_RoofAreaScenario_Set_Input>;
  where: R_RoofAreaScenario_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_RoofAreaScenario_By_PkArgs = {
  _inc?: Maybe<R_RoofAreaScenario_Inc_Input>;
  _set?: Maybe<R_RoofAreaScenario_Set_Input>;
  pk_columns: R_RoofAreaScenario_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_ScenarioNoteArgs = {
  _inc?: Maybe<R_ScenarioNote_Inc_Input>;
  _set?: Maybe<R_ScenarioNote_Set_Input>;
  where: R_ScenarioNote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_ScenarioNote_By_PkArgs = {
  _inc?: Maybe<R_ScenarioNote_Inc_Input>;
  _set?: Maybe<R_ScenarioNote_Set_Input>;
  pk_columns: R_ScenarioNote_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_SinglePly_BondingAdhesivesArgs = {
  _inc?: Maybe<R_SinglePly_BondingAdhesives_Inc_Input>;
  _set?: Maybe<R_SinglePly_BondingAdhesives_Set_Input>;
  where: R_SinglePly_BondingAdhesives_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_SinglePly_BondingAdhesives_By_PkArgs = {
  _inc?: Maybe<R_SinglePly_BondingAdhesives_Inc_Input>;
  _set?: Maybe<R_SinglePly_BondingAdhesives_Set_Input>;
  pk_columns: R_SinglePly_BondingAdhesives_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_SinglePly_LapAdhesivesArgs = {
  _inc?: Maybe<R_SinglePly_LapAdhesives_Inc_Input>;
  _set?: Maybe<R_SinglePly_LapAdhesives_Set_Input>;
  where: R_SinglePly_LapAdhesives_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_SinglePly_LapAdhesives_By_PkArgs = {
  _inc?: Maybe<R_SinglePly_LapAdhesives_Inc_Input>;
  _set?: Maybe<R_SinglePly_LapAdhesives_Set_Input>;
  pk_columns: R_SinglePly_LapAdhesives_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_Subassembly_MultiplyCoverConfigArgs = {
  _inc?: Maybe<R_Subassembly_MultiplyCoverConfig_Inc_Input>;
  _set?: Maybe<R_Subassembly_MultiplyCoverConfig_Set_Input>;
  where: R_Subassembly_MultiplyCoverConfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_Subassembly_MultiplyCoverConfig_By_PkArgs = {
  _inc?: Maybe<R_Subassembly_MultiplyCoverConfig_Inc_Input>;
  _set?: Maybe<R_Subassembly_MultiplyCoverConfig_Set_Input>;
  pk_columns: R_Subassembly_MultiplyCoverConfig_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_R_UserContactArgs = {
  _inc?: Maybe<R_UserContact_Inc_Input>;
  _set?: Maybe<R_UserContact_Set_Input>;
  where: R_UserContact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_R_UserContact_By_PkArgs = {
  _inc?: Maybe<R_UserContact_Inc_Input>;
  _set?: Maybe<R_UserContact_Set_Input>;
  pk_columns: R_UserContact_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RegletArgs = {
  _inc?: Maybe<Reglet_Inc_Input>;
  _set?: Maybe<Reglet_Set_Input>;
  where: Reglet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reglet_By_PkArgs = {
  _inc?: Maybe<Reglet_Inc_Input>;
  _set?: Maybe<Reglet_Set_Input>;
  pk_columns: Reglet_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewerWorkspaceArgs = {
  _inc?: Maybe<ReviewerWorkspace_Inc_Input>;
  _set?: Maybe<ReviewerWorkspace_Set_Input>;
  where: ReviewerWorkspace_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewerWorkspaceDataArgs = {
  _inc?: Maybe<ReviewerWorkspaceData_Inc_Input>;
  _set?: Maybe<ReviewerWorkspaceData_Set_Input>;
  where: ReviewerWorkspaceData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewerWorkspaceData_By_PkArgs = {
  _inc?: Maybe<ReviewerWorkspaceData_Inc_Input>;
  _set?: Maybe<ReviewerWorkspaceData_Set_Input>;
  pk_columns: ReviewerWorkspaceData_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewerWorkspace_By_PkArgs = {
  _inc?: Maybe<ReviewerWorkspace_Inc_Input>;
  _set?: Maybe<ReviewerWorkspace_Set_Input>;
  pk_columns: ReviewerWorkspace_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RoofAreaArgs = {
  _inc?: Maybe<RoofArea_Inc_Input>;
  _set?: Maybe<RoofArea_Set_Input>;
  where: RoofArea_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RoofAreaWindPressureArgs = {
  _inc?: Maybe<RoofAreaWindPressure_Inc_Input>;
  _set?: Maybe<RoofAreaWindPressure_Set_Input>;
  where: RoofAreaWindPressure_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RoofAreaWindPressure_By_PkArgs = {
  _inc?: Maybe<RoofAreaWindPressure_Inc_Input>;
  _set?: Maybe<RoofAreaWindPressure_Set_Input>;
  pk_columns: RoofAreaWindPressure_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RoofArea_By_PkArgs = {
  _inc?: Maybe<RoofArea_Inc_Input>;
  _set?: Maybe<RoofArea_Set_Input>;
  pk_columns: RoofArea_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RoofAssemblyArgs = {
  _inc?: Maybe<RoofAssembly_Inc_Input>;
  _set?: Maybe<RoofAssembly_Set_Input>;
  where: RoofAssembly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RoofAssemblyDelistDataArgs = {
  _inc?: Maybe<RoofAssemblyDelistData_Inc_Input>;
  _set?: Maybe<RoofAssemblyDelistData_Set_Input>;
  where: RoofAssemblyDelistData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RoofAssemblyLayersArgs = {
  _inc?: Maybe<RoofAssemblyLayers_Inc_Input>;
  _set?: Maybe<RoofAssemblyLayers_Set_Input>;
  where: RoofAssemblyLayers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RoofAssemblyLayers_By_PkArgs = {
  _inc?: Maybe<RoofAssemblyLayers_Inc_Input>;
  _set?: Maybe<RoofAssemblyLayers_Set_Input>;
  pk_columns: RoofAssemblyLayers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RoofAssembly_By_PkArgs = {
  _inc?: Maybe<RoofAssembly_Inc_Input>;
  _set?: Maybe<RoofAssembly_Set_Input>;
  pk_columns: RoofAssembly_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RoofProjectArgs = {
  _inc?: Maybe<RoofProject_Inc_Input>;
  _set?: Maybe<RoofProject_Set_Input>;
  where: RoofProject_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RoofProject_By_PkArgs = {
  _inc?: Maybe<RoofProject_Inc_Input>;
  _set?: Maybe<RoofProject_Set_Input>;
  pk_columns: RoofProject_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ScenarioArgs = {
  _inc?: Maybe<Scenario_Inc_Input>;
  _set?: Maybe<Scenario_Set_Input>;
  where: Scenario_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ScenarioAlertArgs = {
  _inc?: Maybe<ScenarioAlert_Inc_Input>;
  _set?: Maybe<ScenarioAlert_Set_Input>;
  where: ScenarioAlert_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ScenarioAlert_By_PkArgs = {
  _inc?: Maybe<ScenarioAlert_Inc_Input>;
  _set?: Maybe<ScenarioAlert_Set_Input>;
  pk_columns: ScenarioAlert_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ScenarioLayerArgs = {
  _inc?: Maybe<ScenarioLayer_Inc_Input>;
  _set?: Maybe<ScenarioLayer_Set_Input>;
  where: ScenarioLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ScenarioLayer_By_PkArgs = {
  _inc?: Maybe<ScenarioLayer_Inc_Input>;
  _set?: Maybe<ScenarioLayer_Set_Input>;
  pk_columns: ScenarioLayer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ScenarioUsageArgs = {
  _inc?: Maybe<ScenarioUsage_Inc_Input>;
  _set?: Maybe<ScenarioUsage_Set_Input>;
  where: ScenarioUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ScenarioUsage_By_PkArgs = {
  _inc?: Maybe<ScenarioUsage_Inc_Input>;
  _set?: Maybe<ScenarioUsage_Set_Input>;
  pk_columns: ScenarioUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Scenario_By_PkArgs = {
  _inc?: Maybe<Scenario_Inc_Input>;
  _set?: Maybe<Scenario_Set_Input>;
  pk_columns: Scenario_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SeamPrimerArgs = {
  _inc?: Maybe<SeamPrimer_Inc_Input>;
  _set?: Maybe<SeamPrimer_Set_Input>;
  where: SeamPrimer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SeamPrimerUsageArgs = {
  _inc?: Maybe<SeamPrimerUsage_Inc_Input>;
  _set?: Maybe<SeamPrimerUsage_Set_Input>;
  where: SeamPrimerUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SeamPrimerUsage_By_PkArgs = {
  _inc?: Maybe<SeamPrimerUsage_Inc_Input>;
  _set?: Maybe<SeamPrimerUsage_Set_Input>;
  pk_columns: SeamPrimerUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SeamPrimer_By_PkArgs = {
  _inc?: Maybe<SeamPrimer_Inc_Input>;
  _set?: Maybe<SeamPrimer_Set_Input>;
  pk_columns: SeamPrimer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SeamTapeSystemUsageArgs = {
  _inc?: Maybe<SeamTapeSystemUsage_Inc_Input>;
  _set?: Maybe<SeamTapeSystemUsage_Set_Input>;
  where: SeamTapeSystemUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SeamTapeSystemUsage_By_PkArgs = {
  _inc?: Maybe<SeamTapeSystemUsage_Inc_Input>;
  _set?: Maybe<SeamTapeSystemUsage_Set_Input>;
  pk_columns: SeamTapeSystemUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SeamWashArgs = {
  _inc?: Maybe<SeamWash_Inc_Input>;
  _set?: Maybe<SeamWash_Set_Input>;
  where: SeamWash_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SeamWashUsageArgs = {
  _inc?: Maybe<SeamWashUsage_Inc_Input>;
  _set?: Maybe<SeamWashUsage_Set_Input>;
  where: SeamWashUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SeamWashUsage_By_PkArgs = {
  _inc?: Maybe<SeamWashUsage_Inc_Input>;
  _set?: Maybe<SeamWashUsage_Set_Input>;
  pk_columns: SeamWashUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SeamWash_By_PkArgs = {
  _inc?: Maybe<SeamWash_Inc_Input>;
  _set?: Maybe<SeamWash_Set_Input>;
  pk_columns: SeamWash_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SearchNavAssembly_ManufacturerProductArgs = {
  _inc?: Maybe<SearchNavAssembly_ManufacturerProduct_Inc_Input>;
  _set?: Maybe<SearchNavAssembly_ManufacturerProduct_Set_Input>;
  where: SearchNavAssembly_ManufacturerProduct_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SearchNavAssembly_ManufacturerProduct_By_PkArgs = {
  _inc?: Maybe<SearchNavAssembly_ManufacturerProduct_Inc_Input>;
  _set?: Maybe<SearchNavAssembly_ManufacturerProduct_Set_Input>;
  pk_columns: SearchNavAssembly_ManufacturerProduct_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SecurementLayerArgs = {
  _inc?: Maybe<SecurementLayer_Inc_Input>;
  _set?: Maybe<SecurementLayer_Set_Input>;
  where: SecurementLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SecurementLayerUsageArgs = {
  _inc?: Maybe<SecurementLayerUsage_Inc_Input>;
  _set?: Maybe<SecurementLayerUsage_Set_Input>;
  where: SecurementLayerUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SecurementLayerUsage_By_PkArgs = {
  _inc?: Maybe<SecurementLayerUsage_Inc_Input>;
  _set?: Maybe<SecurementLayerUsage_Set_Input>;
  pk_columns: SecurementLayerUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SecurementLayer_By_PkArgs = {
  _inc?: Maybe<SecurementLayer_Inc_Input>;
  _set?: Maybe<SecurementLayer_Set_Input>;
  pk_columns: SecurementLayer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SelfSecurementUsageArgs = {
  _inc?: Maybe<SelfSecurementUsage_Inc_Input>;
  _set?: Maybe<SelfSecurementUsage_Set_Input>;
  where: SelfSecurementUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SelfSecurementUsage_By_PkArgs = {
  _inc?: Maybe<SelfSecurementUsage_Inc_Input>;
  _set?: Maybe<SelfSecurementUsage_Set_Input>;
  pk_columns: SelfSecurementUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SeparatorSheetArgs = {
  _inc?: Maybe<SeparatorSheet_Inc_Input>;
  _set?: Maybe<SeparatorSheet_Set_Input>;
  where: SeparatorSheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SeparatorSheetUsageArgs = {
  _inc?: Maybe<SeparatorSheetUsage_Inc_Input>;
  _set?: Maybe<SeparatorSheetUsage_Set_Input>;
  where: SeparatorSheetUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SeparatorSheetUsage_By_PkArgs = {
  _inc?: Maybe<SeparatorSheetUsage_Inc_Input>;
  _set?: Maybe<SeparatorSheetUsage_Set_Input>;
  pk_columns: SeparatorSheetUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SeparatorSheet_By_PkArgs = {
  _inc?: Maybe<SeparatorSheet_Inc_Input>;
  _set?: Maybe<SeparatorSheet_Set_Input>;
  pk_columns: SeparatorSheet_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ShinglesArgs = {
  _inc?: Maybe<Shingles_Inc_Input>;
  _set?: Maybe<Shingles_Set_Input>;
  where: Shingles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Shingles_By_PkArgs = {
  _inc?: Maybe<Shingles_Inc_Input>;
  _set?: Maybe<Shingles_Set_Input>;
  pk_columns: Shingles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SinglePlyCoverArgs = {
  _inc?: Maybe<SinglePlyCover_Inc_Input>;
  _set?: Maybe<SinglePlyCover_Set_Input>;
  where: SinglePlyCover_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SinglePlyCover_By_PkArgs = {
  _inc?: Maybe<SinglePlyCover_Inc_Input>;
  _set?: Maybe<SinglePlyCover_Set_Input>;
  pk_columns: SinglePlyCover_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SinglePlyUsageArgs = {
  _inc?: Maybe<SinglePlyUsage_Inc_Input>;
  _set?: Maybe<SinglePlyUsage_Set_Input>;
  where: SinglePlyUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SinglePlyUsage_By_PkArgs = {
  _inc?: Maybe<SinglePlyUsage_Inc_Input>;
  _set?: Maybe<SinglePlyUsage_Set_Input>;
  pk_columns: SinglePlyUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SprayFoamInsulationUsageArgs = {
  _inc?: Maybe<SprayFoamInsulationUsage_Inc_Input>;
  _set?: Maybe<SprayFoamInsulationUsage_Set_Input>;
  where: SprayFoamInsulationUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SprayFoamInsulationUsage_By_PkArgs = {
  _inc?: Maybe<SprayFoamInsulationUsage_Inc_Input>;
  _set?: Maybe<SprayFoamInsulationUsage_Set_Input>;
  pk_columns: SprayFoamInsulationUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SprayedFoamInsulationArgs = {
  _inc?: Maybe<SprayedFoamInsulation_Inc_Input>;
  _set?: Maybe<SprayedFoamInsulation_Set_Input>;
  where: SprayedFoamInsulation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SprayedFoamInsulation_By_PkArgs = {
  _inc?: Maybe<SprayedFoamInsulation_Inc_Input>;
  _set?: Maybe<SprayedFoamInsulation_Set_Input>;
  pk_columns: SprayedFoamInsulation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StandingLapSeamCoverArgs = {
  _inc?: Maybe<StandingLapSeamCover_Inc_Input>;
  _set?: Maybe<StandingLapSeamCover_Set_Input>;
  where: StandingLapSeamCover_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StandingLapSeamCover_By_PkArgs = {
  _inc?: Maybe<StandingLapSeamCover_Inc_Input>;
  _set?: Maybe<StandingLapSeamCover_Set_Input>;
  pk_columns: StandingLapSeamCover_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StandingLapSeamUsageArgs = {
  _inc?: Maybe<StandingLapSeamUsage_Inc_Input>;
  _set?: Maybe<StandingLapSeamUsage_Set_Input>;
  where: StandingLapSeamUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StandingLapSeamUsage_By_PkArgs = {
  _inc?: Maybe<StandingLapSeamUsage_Inc_Input>;
  _set?: Maybe<StandingLapSeamUsage_Set_Input>;
  pk_columns: StandingLapSeamUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SteelDeckArgs = {
  _inc?: Maybe<SteelDeck_Inc_Input>;
  _set?: Maybe<SteelDeck_Set_Input>;
  where: SteelDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SteelDeckLayerArgs = {
  _inc?: Maybe<SteelDeckLayer_Inc_Input>;
  _set?: Maybe<SteelDeckLayer_Set_Input>;
  where: SteelDeckLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SteelDeckLayer_By_PkArgs = {
  _inc?: Maybe<SteelDeckLayer_Inc_Input>;
  _set?: Maybe<SteelDeckLayer_Set_Input>;
  pk_columns: SteelDeckLayer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SteelDeckUsageArgs = {
  _inc?: Maybe<SteelDeckUsage_Inc_Input>;
  _set?: Maybe<SteelDeckUsage_Set_Input>;
  where: SteelDeckUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SteelDeckUsage_By_PkArgs = {
  _inc?: Maybe<SteelDeckUsage_Inc_Input>;
  _set?: Maybe<SteelDeckUsage_Set_Input>;
  pk_columns: SteelDeckUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SteelDeck_By_PkArgs = {
  _inc?: Maybe<SteelDeck_Inc_Input>;
  _set?: Maybe<SteelDeck_Set_Input>;
  pk_columns: SteelDeck_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StressPlateArgs = {
  _inc?: Maybe<StressPlate_Inc_Input>;
  _set?: Maybe<StressPlate_Set_Input>;
  where: StressPlate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StressPlateUsageArgs = {
  _inc?: Maybe<StressPlateUsage_Inc_Input>;
  _set?: Maybe<StressPlateUsage_Set_Input>;
  where: StressPlateUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StressPlateUsage_By_PkArgs = {
  _inc?: Maybe<StressPlateUsage_Inc_Input>;
  _set?: Maybe<StressPlateUsage_Set_Input>;
  pk_columns: StressPlateUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StressPlate_By_PkArgs = {
  _inc?: Maybe<StressPlate_Inc_Input>;
  _set?: Maybe<StressPlate_Set_Input>;
  pk_columns: StressPlate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StructuralConcreteLayerArgs = {
  _inc?: Maybe<StructuralConcreteLayer_Inc_Input>;
  _set?: Maybe<StructuralConcreteLayer_Set_Input>;
  where: StructuralConcreteLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StructuralConcreteLayer_By_PkArgs = {
  _inc?: Maybe<StructuralConcreteLayer_Inc_Input>;
  _set?: Maybe<StructuralConcreteLayer_Set_Input>;
  pk_columns: StructuralConcreteLayer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StructureLayerArgs = {
  _inc?: Maybe<StructureLayer_Inc_Input>;
  _set?: Maybe<StructureLayer_Set_Input>;
  where: StructureLayer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StructureLayer_By_PkArgs = {
  _inc?: Maybe<StructureLayer_Inc_Input>;
  _set?: Maybe<StructureLayer_Set_Input>;
  pk_columns: StructureLayer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StructuredSystemArgs = {
  _inc?: Maybe<StructuredSystem_Inc_Input>;
  _set?: Maybe<StructuredSystem_Set_Input>;
  where: StructuredSystem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StructuredSystemProductsArgs = {
  _inc?: Maybe<StructuredSystemProducts_Inc_Input>;
  _set?: Maybe<StructuredSystemProducts_Set_Input>;
  where: StructuredSystemProducts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_StructuredSystemProducts_By_PkArgs = {
  _inc?: Maybe<StructuredSystemProducts_Inc_Input>;
  _set?: Maybe<StructuredSystemProducts_Set_Input>;
  pk_columns: StructuredSystemProducts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StructuredSystem_By_PkArgs = {
  _inc?: Maybe<StructuredSystem_Inc_Input>;
  _set?: Maybe<StructuredSystem_Set_Input>;
  pk_columns: StructuredSystem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SubassemblyArgs = {
  _inc?: Maybe<Subassembly_Inc_Input>;
  _set?: Maybe<Subassembly_Set_Input>;
  where: Subassembly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SubassemblyDelistDataArgs = {
  _inc?: Maybe<SubassemblyDelistData_Inc_Input>;
  _set?: Maybe<SubassemblyDelistData_Set_Input>;
  where: SubassemblyDelistData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SubassemblyE108RatingArgs = {
  _inc?: Maybe<SubassemblyE108Rating_Inc_Input>;
  _set?: Maybe<SubassemblyE108Rating_Set_Input>;
  where: SubassemblyE108Rating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SubassemblyE108Rating_By_PkArgs = {
  _inc?: Maybe<SubassemblyE108Rating_Inc_Input>;
  _set?: Maybe<SubassemblyE108Rating_Set_Input>;
  pk_columns: SubassemblyE108Rating_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SubassemblyLayersArgs = {
  _inc?: Maybe<SubassemblyLayers_Inc_Input>;
  _set?: Maybe<SubassemblyLayers_Set_Input>;
  where: SubassemblyLayers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SubassemblyLayers_By_PkArgs = {
  _inc?: Maybe<SubassemblyLayers_Inc_Input>;
  _set?: Maybe<SubassemblyLayers_Set_Input>;
  pk_columns: SubassemblyLayers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SubassemblyMultiplyConfigArgs = {
  _inc?: Maybe<SubassemblyMultiplyConfig_Inc_Input>;
  _set?: Maybe<SubassemblyMultiplyConfig_Set_Input>;
  where: SubassemblyMultiplyConfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SubassemblyMultiplyConfig_By_PkArgs = {
  _inc?: Maybe<SubassemblyMultiplyConfig_Inc_Input>;
  _set?: Maybe<SubassemblyMultiplyConfig_Set_Input>;
  pk_columns: SubassemblyMultiplyConfig_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subassembly_By_PkArgs = {
  _inc?: Maybe<Subassembly_Inc_Input>;
  _set?: Maybe<Subassembly_Set_Input>;
  pk_columns: Subassembly_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_AdhesiveArgs = {
  _inc?: Maybe<Subcat_Adhesive_Inc_Input>;
  _set?: Maybe<Subcat_Adhesive_Set_Input>;
  where: Subcat_Adhesive_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_AirRetarderArgs = {
  _inc?: Maybe<Subcat_AirRetarder_Inc_Input>;
  _set?: Maybe<Subcat_AirRetarder_Set_Input>;
  where: Subcat_AirRetarder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_BaseSheetArgs = {
  _inc?: Maybe<Subcat_BaseSheet_Inc_Input>;
  _set?: Maybe<Subcat_BaseSheet_Set_Input>;
  where: Subcat_BaseSheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_BattBlanketArgs = {
  _inc?: Maybe<Subcat_BattBlanket_Inc_Input>;
  _set?: Maybe<Subcat_BattBlanket_Set_Input>;
  where: Subcat_BattBlanket_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_BoardStockArgs = {
  _inc?: Maybe<Subcat_BoardStock_Inc_Input>;
  _set?: Maybe<Subcat_BoardStock_Set_Input>;
  where: Subcat_BoardStock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_CoatingArgs = {
  _inc?: Maybe<Subcat_Coating_Inc_Input>;
  _set?: Maybe<Subcat_Coating_Set_Input>;
  where: Subcat_Coating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_CompositePanelArgs = {
  _inc?: Maybe<Subcat_CompositePanel_Inc_Input>;
  _set?: Maybe<Subcat_CompositePanel_Set_Input>;
  where: Subcat_CompositePanel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_CoverStripArgs = {
  _inc?: Maybe<Subcat_CoverStrip_Inc_Input>;
  _set?: Maybe<Subcat_CoverStrip_Set_Input>;
  where: Subcat_CoverStrip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_ExpJointArgs = {
  _inc?: Maybe<Subcat_ExpJoint_Inc_Input>;
  _set?: Maybe<Subcat_ExpJoint_Set_Input>;
  where: Subcat_ExpJoint_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_FasteningArgs = {
  _inc?: Maybe<Subcat_Fastening_Inc_Input>;
  _set?: Maybe<Subcat_Fastening_Set_Input>;
  where: Subcat_Fastening_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_FormDeckArgs = {
  _inc?: Maybe<Subcat_FormDeck_Inc_Input>;
  _set?: Maybe<Subcat_FormDeck_Set_Input>;
  where: Subcat_FormDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_GutterArgs = {
  _inc?: Maybe<Subcat_Gutter_Inc_Input>;
  _set?: Maybe<Subcat_Gutter_Set_Input>;
  where: Subcat_Gutter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_GypsumArgs = {
  _inc?: Maybe<Subcat_Gypsum_Inc_Input>;
  _set?: Maybe<Subcat_Gypsum_Set_Input>;
  where: Subcat_Gypsum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_LwicArgs = {
  _inc?: Maybe<Subcat_Lwic_Inc_Input>;
  _set?: Maybe<Subcat_Lwic_Set_Input>;
  where: Subcat_Lwic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_LwicDeckArgs = {
  _inc?: Maybe<Subcat_LwicDeck_Inc_Input>;
  _set?: Maybe<Subcat_LwicDeck_Set_Input>;
  where: Subcat_LwicDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_LinerPanelArgs = {
  _inc?: Maybe<Subcat_LinerPanel_Inc_Input>;
  _set?: Maybe<Subcat_LinerPanel_Set_Input>;
  where: Subcat_LinerPanel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_LiquidAppliedArgs = {
  _inc?: Maybe<Subcat_LiquidApplied_Inc_Input>;
  _set?: Maybe<Subcat_LiquidApplied_Set_Input>;
  where: Subcat_LiquidApplied_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_MaintCoatingArgs = {
  _inc?: Maybe<Subcat_MaintCoating_Inc_Input>;
  _set?: Maybe<Subcat_MaintCoating_Set_Input>;
  where: Subcat_MaintCoating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_MultiplyArgs = {
  _inc?: Maybe<Subcat_Multiply_Inc_Input>;
  _set?: Maybe<Subcat_Multiply_Set_Input>;
  where: Subcat_Multiply_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_MultiplyCoverArgs = {
  _inc?: Maybe<Subcat_MultiplyCover_Inc_Input>;
  _set?: Maybe<Subcat_MultiplyCover_Set_Input>;
  where: Subcat_MultiplyCover_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_PerimeterFlashingArgs = {
  _inc?: Maybe<Subcat_PerimeterFlashing_Inc_Input>;
  _set?: Maybe<Subcat_PerimeterFlashing_Set_Input>;
  where: Subcat_PerimeterFlashing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_PlasticDeckArgs = {
  _inc?: Maybe<Subcat_PlasticDeck_Inc_Input>;
  _set?: Maybe<Subcat_PlasticDeck_Set_Input>;
  where: Subcat_PlasticDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_PrimerArgs = {
  _inc?: Maybe<Subcat_Primer_Inc_Input>;
  _set?: Maybe<Subcat_Primer_Set_Input>;
  where: Subcat_Primer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_SeamingArgs = {
  _inc?: Maybe<Subcat_Seaming_Inc_Input>;
  _set?: Maybe<Subcat_Seaming_Set_Input>;
  where: Subcat_Seaming_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_SeparatorSheetArgs = {
  _inc?: Maybe<Subcat_SeparatorSheet_Inc_Input>;
  _set?: Maybe<Subcat_SeparatorSheet_Set_Input>;
  where: Subcat_SeparatorSheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_ShinglesArgs = {
  _inc?: Maybe<Subcat_Shingles_Inc_Input>;
  _set?: Maybe<Subcat_Shingles_Set_Input>;
  where: Subcat_Shingles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_SinglePlyArgs = {
  _inc?: Maybe<Subcat_SinglePly_Inc_Input>;
  _set?: Maybe<Subcat_SinglePly_Set_Input>;
  where: Subcat_SinglePly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_SinglePlyCoverArgs = {
  _inc?: Maybe<Subcat_SinglePlyCover_Inc_Input>;
  _set?: Maybe<Subcat_SinglePlyCover_Set_Input>;
  where: Subcat_SinglePlyCover_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_SprayFoamArgs = {
  _inc?: Maybe<Subcat_SprayFoam_Inc_Input>;
  _set?: Maybe<Subcat_SprayFoam_Set_Input>;
  where: Subcat_SprayFoam_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_StandingLapSeamArgs = {
  _inc?: Maybe<Subcat_StandingLapSeam_Inc_Input>;
  _set?: Maybe<Subcat_StandingLapSeam_Set_Input>;
  where: Subcat_StandingLapSeam_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_SteelDeckArgs = {
  _inc?: Maybe<Subcat_SteelDeck_Inc_Input>;
  _set?: Maybe<Subcat_SteelDeck_Set_Input>;
  where: Subcat_SteelDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_SurfacingArgs = {
  _inc?: Maybe<Subcat_Surfacing_Inc_Input>;
  _set?: Maybe<Subcat_Surfacing_Set_Input>;
  where: Subcat_Surfacing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_VaporRetarderArgs = {
  _inc?: Maybe<Subcat_VaporRetarder_Inc_Input>;
  _set?: Maybe<Subcat_VaporRetarder_Set_Input>;
  where: Subcat_VaporRetarder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_WeldArgs = {
  _inc?: Maybe<Subcat_Weld_Inc_Input>;
  _set?: Maybe<Subcat_Weld_Set_Input>;
  where: Subcat_Weld_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_WoodArgs = {
  _inc?: Maybe<Subcat_Wood_Inc_Input>;
  _set?: Maybe<Subcat_Wood_Set_Input>;
  where: Subcat_Wood_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subcat_WoodFiberDeckArgs = {
  _inc?: Maybe<Subcat_WoodFiberDeck_Inc_Input>;
  _set?: Maybe<Subcat_WoodFiberDeck_Set_Input>;
  where: Subcat_WoodFiberDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SubstratePrimerArgs = {
  _inc?: Maybe<SubstratePrimer_Inc_Input>;
  _set?: Maybe<SubstratePrimer_Set_Input>;
  where: SubstratePrimer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SubstratePrimerUsageArgs = {
  _inc?: Maybe<SubstratePrimerUsage_Inc_Input>;
  _set?: Maybe<SubstratePrimerUsage_Set_Input>;
  where: SubstratePrimerUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SubstratePrimerUsage_By_PkArgs = {
  _inc?: Maybe<SubstratePrimerUsage_Inc_Input>;
  _set?: Maybe<SubstratePrimerUsage_Set_Input>;
  pk_columns: SubstratePrimerUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SubstratePrimer_By_PkArgs = {
  _inc?: Maybe<SubstratePrimer_Inc_Input>;
  _set?: Maybe<SubstratePrimer_Set_Input>;
  pk_columns: SubstratePrimer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SurfacingArgs = {
  _inc?: Maybe<Surfacing_Inc_Input>;
  _set?: Maybe<Surfacing_Set_Input>;
  where: Surfacing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SurfacingUsageArgs = {
  _inc?: Maybe<SurfacingUsage_Inc_Input>;
  _set?: Maybe<SurfacingUsage_Set_Input>;
  where: SurfacingUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SurfacingUsage_By_PkArgs = {
  _inc?: Maybe<SurfacingUsage_Inc_Input>;
  _set?: Maybe<SurfacingUsage_Set_Input>;
  pk_columns: SurfacingUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Surfacing_By_PkArgs = {
  _inc?: Maybe<Surfacing_Inc_Input>;
  _set?: Maybe<Surfacing_Set_Input>;
  pk_columns: Surfacing_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TapeWidthArgs = {
  _set?: Maybe<TapeWidth_Set_Input>;
  where: TapeWidth_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TapeWidth_By_PkArgs = {
  _set?: Maybe<TapeWidth_Set_Input>;
  pk_columns: TapeWidth_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ThermalBarrierArgs = {
  _inc?: Maybe<ThermalBarrier_Inc_Input>;
  _set?: Maybe<ThermalBarrier_Set_Input>;
  where: ThermalBarrier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ThermalBarrierBoardSizesArgs = {
  _inc?: Maybe<ThermalBarrierBoardSizes_Inc_Input>;
  _set?: Maybe<ThermalBarrierBoardSizes_Set_Input>;
  where: ThermalBarrierBoardSizes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ThermalBarrierBoardSizes_By_PkArgs = {
  _inc?: Maybe<ThermalBarrierBoardSizes_Inc_Input>;
  _set?: Maybe<ThermalBarrierBoardSizes_Set_Input>;
  pk_columns: ThermalBarrierBoardSizes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ThermalBarrierFacerBottomArgs = {
  _inc?: Maybe<ThermalBarrierFacerBottom_Inc_Input>;
  _set?: Maybe<ThermalBarrierFacerBottom_Set_Input>;
  where: ThermalBarrierFacerBottom_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ThermalBarrierFacerBottom_By_PkArgs = {
  _inc?: Maybe<ThermalBarrierFacerBottom_Inc_Input>;
  _set?: Maybe<ThermalBarrierFacerBottom_Set_Input>;
  pk_columns: ThermalBarrierFacerBottom_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ThermalBarrierFacerTopArgs = {
  _inc?: Maybe<ThermalBarrierFacerTop_Inc_Input>;
  _set?: Maybe<ThermalBarrierFacerTop_Set_Input>;
  where: ThermalBarrierFacerTop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ThermalBarrierFacerTop_By_PkArgs = {
  _inc?: Maybe<ThermalBarrierFacerTop_Inc_Input>;
  _set?: Maybe<ThermalBarrierFacerTop_Set_Input>;
  pk_columns: ThermalBarrierFacerTop_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ThermalBarrierUsageArgs = {
  _inc?: Maybe<ThermalBarrierUsage_Inc_Input>;
  _set?: Maybe<ThermalBarrierUsage_Set_Input>;
  where: ThermalBarrierUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ThermalBarrierUsage_By_PkArgs = {
  _inc?: Maybe<ThermalBarrierUsage_Inc_Input>;
  _set?: Maybe<ThermalBarrierUsage_Set_Input>;
  pk_columns: ThermalBarrierUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ThermalBarrier_By_PkArgs = {
  _inc?: Maybe<ThermalBarrier_Inc_Input>;
  _set?: Maybe<ThermalBarrier_Set_Input>;
  pk_columns: ThermalBarrier_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TopCoatArgs = {
  _inc?: Maybe<TopCoat_Inc_Input>;
  _set?: Maybe<TopCoat_Set_Input>;
  where: TopCoat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TopCoatUsageArgs = {
  _inc?: Maybe<TopCoatUsage_Inc_Input>;
  _set?: Maybe<TopCoatUsage_Set_Input>;
  where: TopCoatUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TopCoatUsage_By_PkArgs = {
  _inc?: Maybe<TopCoatUsage_Inc_Input>;
  _set?: Maybe<TopCoatUsage_Set_Input>;
  pk_columns: TopCoatUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TopCoat_By_PkArgs = {
  _inc?: Maybe<TopCoat_Inc_Input>;
  _set?: Maybe<TopCoat_Set_Input>;
  pk_columns: TopCoat_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsageArgs = {
  _inc?: Maybe<Usage_Inc_Input>;
  _set?: Maybe<Usage_Set_Input>;
  where: Usage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UsageTradeNameViewArgs = {
  _inc?: Maybe<UsageTradeNameView_Inc_Input>;
  _set?: Maybe<UsageTradeNameView_Set_Input>;
  where: UsageTradeNameView_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Usage_By_PkArgs = {
  _inc?: Maybe<Usage_Inc_Input>;
  _set?: Maybe<Usage_Set_Input>;
  pk_columns: Usage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserReportBugArgs = {
  _set?: Maybe<UserReportBug_Set_Input>;
  where: UserReportBug_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserReportBug_By_PkArgs = {
  _set?: Maybe<UserReportBug_Set_Input>;
  pk_columns: UserReportBug_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserShareLinkArgs = {
  _set?: Maybe<UserShareLink_Set_Input>;
  where: UserShareLink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserShareLink_By_PkArgs = {
  _set?: Maybe<UserShareLink_Set_Input>;
  pk_columns: UserShareLink_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VaporRetarderArgs = {
  _inc?: Maybe<VaporRetarder_Inc_Input>;
  _set?: Maybe<VaporRetarder_Set_Input>;
  where: VaporRetarder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VaporRetarderUsageArgs = {
  _inc?: Maybe<VaporRetarderUsage_Inc_Input>;
  _set?: Maybe<VaporRetarderUsage_Set_Input>;
  where: VaporRetarderUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VaporRetarderUsage_By_PkArgs = {
  _inc?: Maybe<VaporRetarderUsage_Inc_Input>;
  _set?: Maybe<VaporRetarderUsage_Set_Input>;
  pk_columns: VaporRetarderUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VaporRetarder_By_PkArgs = {
  _inc?: Maybe<VaporRetarder_Inc_Input>;
  _set?: Maybe<VaporRetarder_Set_Input>;
  pk_columns: VaporRetarder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WeldArcUsageArgs = {
  _inc?: Maybe<WeldArcUsage_Inc_Input>;
  _set?: Maybe<WeldArcUsage_Set_Input>;
  where: WeldArcUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WeldArcUsage_By_PkArgs = {
  _inc?: Maybe<WeldArcUsage_Inc_Input>;
  _set?: Maybe<WeldArcUsage_Set_Input>;
  pk_columns: WeldArcUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WeldHeatUsageArgs = {
  _inc?: Maybe<WeldHeatUsage_Inc_Input>;
  _set?: Maybe<WeldHeatUsage_Set_Input>;
  where: WeldHeatUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WeldHeatUsage_By_PkArgs = {
  _inc?: Maybe<WeldHeatUsage_Inc_Input>;
  _set?: Maybe<WeldHeatUsage_Set_Input>;
  pk_columns: WeldHeatUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WeldSolventUsageArgs = {
  _inc?: Maybe<WeldSolventUsage_Inc_Input>;
  _set?: Maybe<WeldSolventUsage_Set_Input>;
  where: WeldSolventUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WeldSolventUsage_By_PkArgs = {
  _inc?: Maybe<WeldSolventUsage_Inc_Input>;
  _set?: Maybe<WeldSolventUsage_Set_Input>;
  pk_columns: WeldSolventUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WeldSystemUsageArgs = {
  _inc?: Maybe<WeldSystemUsage_Inc_Input>;
  _set?: Maybe<WeldSystemUsage_Set_Input>;
  where: WeldSystemUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WeldSystemUsage_By_PkArgs = {
  _inc?: Maybe<WeldSystemUsage_Inc_Input>;
  _set?: Maybe<WeldSystemUsage_Set_Input>;
  pk_columns: WeldSystemUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WeldTorchedUsageArgs = {
  _inc?: Maybe<WeldTorchedUsage_Inc_Input>;
  _set?: Maybe<WeldTorchedUsage_Set_Input>;
  where: WeldTorchedUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WeldTorchedUsage_By_PkArgs = {
  _inc?: Maybe<WeldTorchedUsage_Inc_Input>;
  _set?: Maybe<WeldTorchedUsage_Set_Input>;
  pk_columns: WeldTorchedUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WoodDeckArgs = {
  _inc?: Maybe<WoodDeck_Inc_Input>;
  _set?: Maybe<WoodDeck_Set_Input>;
  where: WoodDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WoodDeckUsageArgs = {
  _inc?: Maybe<WoodDeckUsage_Inc_Input>;
  _set?: Maybe<WoodDeckUsage_Set_Input>;
  where: WoodDeckUsage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WoodDeckUsage_By_PkArgs = {
  _inc?: Maybe<WoodDeckUsage_Inc_Input>;
  _set?: Maybe<WoodDeckUsage_Set_Input>;
  pk_columns: WoodDeckUsage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WoodDeck_By_PkArgs = {
  _inc?: Maybe<WoodDeck_Inc_Input>;
  _set?: Maybe<WoodDeck_Set_Input>;
  pk_columns: WoodDeck_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_LayersArgs = {
  _inc?: Maybe<VNavAssembly_Layers_Inc_Input>;
  _set?: Maybe<VNavAssembly_Layers_Set_Input>;
  where: VNavAssembly_Layers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_ManufacturerOnlySearchArgs = {
  _inc?: Maybe<VNavAssembly_ManufacturerOnlySearch_Inc_Input>;
  _set?: Maybe<VNavAssembly_ManufacturerOnlySearch_Set_Input>;
  where: VNavAssembly_ManufacturerOnlySearch_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_RoofTypeCountArgs = {
  _inc?: Maybe<VNavAssembly_RoofTypeCount_Inc_Input>;
  _set?: Maybe<VNavAssembly_RoofTypeCount_Set_Input>;
  where: VNavAssembly_RoofTypeCount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_SearchArgs = {
  _inc?: Maybe<VNavAssembly_Search_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_Set_Input>;
  where: VNavAssembly_Search_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_AirRetarderArgs = {
  _inc?: Maybe<VNavAssembly_Search_AirRetarder_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_AirRetarder_Set_Input>;
  where: VNavAssembly_Search_AirRetarder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_BaseCoatArgs = {
  _inc?: Maybe<VNavAssembly_Search_BaseCoat_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_BaseCoat_Set_Input>;
  where: VNavAssembly_Search_BaseCoat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_BasePlyArgs = {
  _inc?: Maybe<VNavAssembly_Search_BasePly_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_BasePly_Set_Input>;
  where: VNavAssembly_Search_BasePly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_BaseSheetArgs = {
  _inc?: Maybe<VNavAssembly_Search_BaseSheet_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_BaseSheet_Set_Input>;
  where: VNavAssembly_Search_BaseSheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_BoardStockArgs = {
  _inc?: Maybe<VNavAssembly_Search_BoardStock_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_BoardStock_Set_Input>;
  where: VNavAssembly_Search_BoardStock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_CapPlyArgs = {
  _inc?: Maybe<VNavAssembly_Search_CapPly_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_CapPly_Set_Input>;
  where: VNavAssembly_Search_CapPly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_CoatArgs = {
  _inc?: Maybe<VNavAssembly_Search_Coat_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_Coat_Set_Input>;
  where: VNavAssembly_Search_Coat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_CoatingArgs = {
  _inc?: Maybe<VNavAssembly_Search_Coating_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_Coating_Set_Input>;
  where: VNavAssembly_Search_Coating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_CoverBoardArgs = {
  _inc?: Maybe<VNavAssembly_Search_CoverBoard_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_CoverBoard_Set_Input>;
  where: VNavAssembly_Search_CoverBoard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_CoverCompositePanelArgs = {
  _inc?: Maybe<VNavAssembly_Search_CoverCompositePanel_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_CoverCompositePanel_Set_Input>;
  where: VNavAssembly_Search_CoverCompositePanel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_CoverSingleplyArgs = {
  _inc?: Maybe<VNavAssembly_Search_CoverSingleply_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_CoverSingleply_Set_Input>;
  where: VNavAssembly_Search_CoverSingleply_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_CoverStandingLapSeamArgs = {
  _inc?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Set_Input>;
  where: VNavAssembly_Search_CoverStandingLapSeam_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_CoverStripDiskArgs = {
  _inc?: Maybe<VNavAssembly_Search_CoverStripDisk_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_CoverStripDisk_Set_Input>;
  where: VNavAssembly_Search_CoverStripDisk_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_DeckCementitiousPanelArgs = {
  _inc?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Set_Input>;
  where: VNavAssembly_Search_DeckCementitiousPanel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_DeckSteelArgs = {
  _inc?: Maybe<VNavAssembly_Search_DeckSteel_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_DeckSteel_Set_Input>;
  where: VNavAssembly_Search_DeckSteel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_FormDeckArgs = {
  _inc?: Maybe<VNavAssembly_Search_FormDeck_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_FormDeck_Set_Input>;
  where: VNavAssembly_Search_FormDeck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_InsulationBattBlanketArgs = {
  _inc?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Set_Input>;
  where: VNavAssembly_Search_InsulationBattBlanket_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_InsulationSprayFoamArgs = {
  _inc?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Set_Input>;
  where: VNavAssembly_Search_InsulationSprayFoam_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_LightweightInsulatingConcreteArgs = {
  _inc?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Set_Input>;
  where: VNavAssembly_Search_LightweightInsulatingConcrete_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_LinerPanelArgs = {
  _inc?: Maybe<VNavAssembly_Search_LinerPanel_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_LinerPanel_Set_Input>;
  where: VNavAssembly_Search_LinerPanel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_PlyArgs = {
  _inc?: Maybe<VNavAssembly_Search_Ply_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_Ply_Set_Input>;
  where: VNavAssembly_Search_Ply_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_SeparatorSheetArgs = {
  _inc?: Maybe<VNavAssembly_Search_SeparatorSheet_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_SeparatorSheet_Set_Input>;
  where: VNavAssembly_Search_SeparatorSheet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_SubstratePrimerArgs = {
  _inc?: Maybe<VNavAssembly_Search_SubstratePrimer_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_SubstratePrimer_Set_Input>;
  where: VNavAssembly_Search_SubstratePrimer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_SurfacingArgs = {
  _inc?: Maybe<VNavAssembly_Search_Surfacing_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_Surfacing_Set_Input>;
  where: VNavAssembly_Search_Surfacing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_ThermalBarrierArgs = {
  _inc?: Maybe<VNavAssembly_Search_ThermalBarrier_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_ThermalBarrier_Set_Input>;
  where: VNavAssembly_Search_ThermalBarrier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_TopCoatArgs = {
  _inc?: Maybe<VNavAssembly_Search_TopCoat_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_TopCoat_Set_Input>;
  where: VNavAssembly_Search_TopCoat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_Search_VaporRetarderArgs = {
  _inc?: Maybe<VNavAssembly_Search_VaporRetarder_Inc_Input>;
  _set?: Maybe<VNavAssembly_Search_VaporRetarder_Set_Input>;
  where: VNavAssembly_Search_VaporRetarder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VNavAssembly_UsagesArgs = {
  _inc?: Maybe<VNavAssembly_Usages_Inc_Input>;
  _set?: Maybe<VNavAssembly_Usages_Set_Input>;
  where: VNavAssembly_Usages_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls first */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls last */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "Address" */
  Address: Array<Address>;
  /** fetch aggregated fields from the table: "Address" */
  Address_aggregate: Address_Aggregate;
  /** fetch data from the table: "Address" using primary key columns */
  Address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "AdheredSinglePlyOrphanReport" */
  AdheredSinglePlyOrphanReport: Array<AdheredSinglePlyOrphanReport>;
  /** fetch aggregated fields from the table: "AdheredSinglePlyOrphanReport" */
  AdheredSinglePlyOrphanReport_aggregate: AdheredSinglePlyOrphanReport_Aggregate;
  /** fetch data from the table: "AdheredSinglePlyOrphanReport" using primary key columns */
  AdheredSinglePlyOrphanReport_by_pk?: Maybe<AdheredSinglePlyOrphanReport>;
  /** fetch data from the table: "AdhesiveFullApplication" */
  AdhesiveFullApplication: Array<AdhesiveFullApplication>;
  /** fetch data from the table: "AdhesiveFullApplicationUsage" */
  AdhesiveFullApplicationUsage: Array<AdhesiveFullApplicationUsage>;
  /** fetch aggregated fields from the table: "AdhesiveFullApplicationUsage" */
  AdhesiveFullApplicationUsage_aggregate: AdhesiveFullApplicationUsage_Aggregate;
  /** fetch data from the table: "AdhesiveFullApplicationUsage" using primary key columns */
  AdhesiveFullApplicationUsage_by_pk?: Maybe<AdhesiveFullApplicationUsage>;
  /** fetch aggregated fields from the table: "AdhesiveFullApplication" */
  AdhesiveFullApplication_aggregate: AdhesiveFullApplication_Aggregate;
  /** fetch data from the table: "AdhesiveFullApplication" using primary key columns */
  AdhesiveFullApplication_by_pk?: Maybe<AdhesiveFullApplication>;
  /** fetch data from the table: "AdhesiveRibbonUsage" */
  AdhesiveRibbonUsage: Array<AdhesiveRibbonUsage>;
  /** fetch aggregated fields from the table: "AdhesiveRibbonUsage" */
  AdhesiveRibbonUsage_aggregate: AdhesiveRibbonUsage_Aggregate;
  /** fetch data from the table: "AdhesiveRibbonUsage" using primary key columns */
  AdhesiveRibbonUsage_by_pk?: Maybe<AdhesiveRibbonUsage>;
  /** An array relationship */
  AdhesiveRibbons: Array<AdhesiveRibbons>;
  /** An aggregate relationship */
  AdhesiveRibbons_aggregate: AdhesiveRibbons_Aggregate;
  /** fetch data from the table: "AdhesiveRibbons" using primary key columns */
  AdhesiveRibbons_by_pk?: Maybe<AdhesiveRibbons>;
  /** An array relationship */
  AdhesiveSpots: Array<AdhesiveSpots>;
  /** fetch data from the table: "AdhesiveSpotsUsage" */
  AdhesiveSpotsUsage: Array<AdhesiveSpotsUsage>;
  /** fetch aggregated fields from the table: "AdhesiveSpotsUsage" */
  AdhesiveSpotsUsage_aggregate: AdhesiveSpotsUsage_Aggregate;
  /** fetch data from the table: "AdhesiveSpotsUsage" using primary key columns */
  AdhesiveSpotsUsage_by_pk?: Maybe<AdhesiveSpotsUsage>;
  /** An aggregate relationship */
  AdhesiveSpots_aggregate: AdhesiveSpots_Aggregate;
  /** fetch data from the table: "AdhesiveSpots" using primary key columns */
  AdhesiveSpots_by_pk?: Maybe<AdhesiveSpots>;
  /** fetch data from the table: "AdhesiveSystemUsage" */
  AdhesiveSystemUsage: Array<AdhesiveSystemUsage>;
  /** fetch aggregated fields from the table: "AdhesiveSystemUsage" */
  AdhesiveSystemUsage_aggregate: AdhesiveSystemUsage_Aggregate;
  /** fetch data from the table: "AdhesiveSystemUsage" using primary key columns */
  AdhesiveSystemUsage_by_pk?: Maybe<AdhesiveSystemUsage>;
  /** fetch data from the table: "AirRetarder" */
  AirRetarder: Array<AirRetarder>;
  /** fetch data from the table: "AirRetarderUsage" */
  AirRetarderUsage: Array<AirRetarderUsage>;
  /** fetch aggregated fields from the table: "AirRetarderUsage" */
  AirRetarderUsage_aggregate: AirRetarderUsage_Aggregate;
  /** fetch data from the table: "AirRetarderUsage" using primary key columns */
  AirRetarderUsage_by_pk?: Maybe<AirRetarderUsage>;
  /** fetch aggregated fields from the table: "AirRetarder" */
  AirRetarder_aggregate: AirRetarder_Aggregate;
  /** fetch data from the table: "AirRetarder" using primary key columns */
  AirRetarder_by_pk?: Maybe<AirRetarder>;
  /** fetch data from the table: "AllManufacturedProducts" */
  AllManufacturedProducts: Array<AllManufacturedProducts>;
  /** fetch aggregated fields from the table: "AllManufacturedProducts" */
  AllManufacturedProducts_aggregate: AllManufacturedProducts_Aggregate;
  /** fetch data from the table: "Anchor" */
  Anchor: Array<Anchor>;
  /** fetch aggregated fields from the table: "Anchor" */
  Anchor_aggregate: Anchor_Aggregate;
  /** fetch data from the table: "Anchor" using primary key columns */
  Anchor_by_pk?: Maybe<Anchor>;
  /** fetch data from the table: "ApprovalsDoc" */
  ApprovalsDoc: Array<ApprovalsDoc>;
  /** fetch aggregated fields from the table: "ApprovalsDoc" */
  ApprovalsDoc_aggregate: ApprovalsDoc_Aggregate;
  /** fetch data from the table: "ApprovalsDoc" using primary key columns */
  ApprovalsDoc_by_pk?: Maybe<ApprovalsDoc>;
  /** fetch data from the table: "ApprovedLayerUse" */
  ApprovedLayerUse: Array<ApprovedLayerUse>;
  /** fetch aggregated fields from the table: "ApprovedLayerUse" */
  ApprovedLayerUse_aggregate: ApprovedLayerUse_Aggregate;
  /** fetch data from the table: "ApprovedLayerUse" using primary key columns */
  ApprovedLayerUse_by_pk?: Maybe<ApprovedLayerUse>;
  /** fetch data from the table: "AssemblyReconfigQueue" */
  AssemblyReconfigQueue: Array<AssemblyReconfigQueue>;
  /** fetch aggregated fields from the table: "AssemblyReconfigQueue" */
  AssemblyReconfigQueue_aggregate: AssemblyReconfigQueue_Aggregate;
  /** fetch data from the table: "AssemblyReconfigQueue" using primary key columns */
  AssemblyReconfigQueue_by_pk?: Maybe<AssemblyReconfigQueue>;
  /** fetch data from the table: "BaseCoat" */
  BaseCoat: Array<BaseCoat>;
  /** fetch data from the table: "BaseCoatUsage" */
  BaseCoatUsage: Array<BaseCoatUsage>;
  /** fetch aggregated fields from the table: "BaseCoatUsage" */
  BaseCoatUsage_aggregate: BaseCoatUsage_Aggregate;
  /** fetch data from the table: "BaseCoatUsage" using primary key columns */
  BaseCoatUsage_by_pk?: Maybe<BaseCoatUsage>;
  /** fetch aggregated fields from the table: "BaseCoat" */
  BaseCoat_aggregate: BaseCoat_Aggregate;
  /** fetch data from the table: "BaseCoat" using primary key columns */
  BaseCoat_by_pk?: Maybe<BaseCoat>;
  /** fetch data from the table: "BasePly" */
  BasePly: Array<BasePly>;
  /** fetch data from the table: "BasePlyUsage" */
  BasePlyUsage: Array<BasePlyUsage>;
  /** fetch aggregated fields from the table: "BasePlyUsage" */
  BasePlyUsage_aggregate: BasePlyUsage_Aggregate;
  /** fetch data from the table: "BasePlyUsage" using primary key columns */
  BasePlyUsage_by_pk?: Maybe<BasePlyUsage>;
  /** fetch aggregated fields from the table: "BasePly" */
  BasePly_aggregate: BasePly_Aggregate;
  /** fetch data from the table: "BasePly" using primary key columns */
  BasePly_by_pk?: Maybe<BasePly>;
  /** fetch data from the table: "BaseSheet" */
  BaseSheet: Array<BaseSheet>;
  /** fetch data from the table: "BaseSheetUsage" */
  BaseSheetUsage: Array<BaseSheetUsage>;
  /** fetch aggregated fields from the table: "BaseSheetUsage" */
  BaseSheetUsage_aggregate: BaseSheetUsage_Aggregate;
  /** fetch data from the table: "BaseSheetUsage" using primary key columns */
  BaseSheetUsage_by_pk?: Maybe<BaseSheetUsage>;
  /** fetch aggregated fields from the table: "BaseSheet" */
  BaseSheet_aggregate: BaseSheet_Aggregate;
  /** fetch data from the table: "BaseSheet" using primary key columns */
  BaseSheet_by_pk?: Maybe<BaseSheet>;
  /** fetch data from the table: "BattBlanketInsulation" */
  BattBlanketInsulation: Array<BattBlanketInsulation>;
  /** fetch aggregated fields from the table: "BattBlanketInsulation" */
  BattBlanketInsulation_aggregate: BattBlanketInsulation_Aggregate;
  /** fetch data from the table: "BattBlanketInsulation" using primary key columns */
  BattBlanketInsulation_by_pk?: Maybe<BattBlanketInsulation>;
  /** fetch data from the table: "BattBlanketUsage" */
  BattBlanketUsage: Array<BattBlanketUsage>;
  /** fetch aggregated fields from the table: "BattBlanketUsage" */
  BattBlanketUsage_aggregate: BattBlanketUsage_Aggregate;
  /** fetch data from the table: "BattBlanketUsage" using primary key columns */
  BattBlanketUsage_by_pk?: Maybe<BattBlanketUsage>;
  /** fetch data from the table: "BattenBar" */
  BattenBar: Array<BattenBar>;
  /** fetch data from the table: "BattenBarUsage" */
  BattenBarUsage: Array<BattenBarUsage>;
  /** fetch aggregated fields from the table: "BattenBarUsage" */
  BattenBarUsage_aggregate: BattenBarUsage_Aggregate;
  /** fetch data from the table: "BattenBarUsage" using primary key columns */
  BattenBarUsage_by_pk?: Maybe<BattenBarUsage>;
  /** fetch aggregated fields from the table: "BattenBar" */
  BattenBar_aggregate: BattenBar_Aggregate;
  /** fetch data from the table: "BattenBar" using primary key columns */
  BattenBar_by_pk?: Maybe<BattenBar>;
  /** fetch data from the table: "BoardSizes" */
  BoardSizes: Array<BoardSizes>;
  /** fetch aggregated fields from the table: "BoardSizes" */
  BoardSizes_aggregate: BoardSizes_Aggregate;
  /** fetch data from the table: "BoardStock" */
  BoardStock: Array<BoardStock>;
  /** An array relationship */
  BoardStockBoardSizes: Array<BoardStockBoardSizes>;
  /** An aggregate relationship */
  BoardStockBoardSizes_aggregate: BoardStockBoardSizes_Aggregate;
  /** fetch data from the table: "BoardStockBoardSizes" using primary key columns */
  BoardStockBoardSizes_by_pk?: Maybe<BoardStockBoardSizes>;
  /** fetch data from the table: "BoardStockFacerBottom" */
  BoardStockFacerBottom: Array<BoardStockFacerBottom>;
  /** fetch aggregated fields from the table: "BoardStockFacerBottom" */
  BoardStockFacerBottom_aggregate: BoardStockFacerBottom_Aggregate;
  /** fetch data from the table: "BoardStockFacerBottom" using primary key columns */
  BoardStockFacerBottom_by_pk?: Maybe<BoardStockFacerBottom>;
  /** fetch data from the table: "BoardStockFacerTop" */
  BoardStockFacerTop: Array<BoardStockFacerTop>;
  /** fetch aggregated fields from the table: "BoardStockFacerTop" */
  BoardStockFacerTop_aggregate: BoardStockFacerTop_Aggregate;
  /** fetch data from the table: "BoardStockFacerTop" using primary key columns */
  BoardStockFacerTop_by_pk?: Maybe<BoardStockFacerTop>;
  /** fetch data from the table: "BoardStockUsage" */
  BoardStockUsage: Array<BoardStockUsage>;
  /** fetch aggregated fields from the table: "BoardStockUsage" */
  BoardStockUsage_aggregate: BoardStockUsage_Aggregate;
  /** fetch data from the table: "BoardStockUsage" using primary key columns */
  BoardStockUsage_by_pk?: Maybe<BoardStockUsage>;
  /** fetch aggregated fields from the table: "BoardStock" */
  BoardStock_aggregate: BoardStock_Aggregate;
  /** fetch data from the table: "BoardStock" using primary key columns */
  BoardStock_by_pk?: Maybe<BoardStock>;
  /** fetch data from the table: "BroadcastAlert" */
  BroadcastAlert: Array<BroadcastAlert>;
  /** fetch aggregated fields from the table: "BroadcastAlert" */
  BroadcastAlert_aggregate: BroadcastAlert_Aggregate;
  /** fetch data from the table: "BroadcastAlert" using primary key columns */
  BroadcastAlert_by_pk?: Maybe<BroadcastAlert>;
  /** fetch data from the table: "BuiltupVaporRetarderSystemUsage" */
  BuiltupVaporRetarderSystemUsage: Array<BuiltupVaporRetarderSystemUsage>;
  /** fetch aggregated fields from the table: "BuiltupVaporRetarderSystemUsage" */
  BuiltupVaporRetarderSystemUsage_aggregate: BuiltupVaporRetarderSystemUsage_Aggregate;
  /** fetch data from the table: "BuiltupVaporRetarderSystemUsage" using primary key columns */
  BuiltupVaporRetarderSystemUsage_by_pk?: Maybe<BuiltupVaporRetarderSystemUsage>;
  /** fetch data from the table: "C_Additive" */
  C_Additive: Array<C_Additive>;
  /** fetch aggregated fields from the table: "C_Additive" */
  C_Additive_aggregate: C_Additive_Aggregate;
  /** fetch data from the table: "C_Additive" using primary key columns */
  C_Additive_by_pk?: Maybe<C_Additive>;
  /** fetch data from the table: "C_AdhesiveApplicationMethod" */
  C_AdhesiveApplicationMethod: Array<C_AdhesiveApplicationMethod>;
  /** fetch aggregated fields from the table: "C_AdhesiveApplicationMethod" */
  C_AdhesiveApplicationMethod_aggregate: C_AdhesiveApplicationMethod_Aggregate;
  /** fetch data from the table: "C_AdhesiveApplicationMethod" using primary key columns */
  C_AdhesiveApplicationMethod_by_pk?: Maybe<C_AdhesiveApplicationMethod>;
  /** fetch data from the table: "C_AdhesiveMaterial" */
  C_AdhesiveMaterial: Array<C_AdhesiveMaterial>;
  /** fetch aggregated fields from the table: "C_AdhesiveMaterial" */
  C_AdhesiveMaterial_aggregate: C_AdhesiveMaterial_Aggregate;
  /** fetch data from the table: "C_AdhesiveMaterial" using primary key columns */
  C_AdhesiveMaterial_by_pk?: Maybe<C_AdhesiveMaterial>;
  /** fetch data from the table: "C_AdhesiveType" */
  C_AdhesiveType: Array<C_AdhesiveType>;
  /** fetch aggregated fields from the table: "C_AdhesiveType" */
  C_AdhesiveType_aggregate: C_AdhesiveType_Aggregate;
  /** fetch data from the table: "C_AdhesiveType" using primary key columns */
  C_AdhesiveType_by_pk?: Maybe<C_AdhesiveType>;
  /** fetch data from the table: "C_Aggregate" */
  C_Aggregate: Array<C_Aggregate>;
  /** fetch aggregated fields from the table: "C_Aggregate" */
  C_Aggregate_aggregate: C_Aggregate_Aggregate;
  /** fetch data from the table: "C_Aggregate" using primary key columns */
  C_Aggregate_by_pk?: Maybe<C_Aggregate>;
  /** fetch data from the table: "C_ApprovalsDocCategory" */
  C_ApprovalsDocCategory: Array<C_ApprovalsDocCategory>;
  /** fetch aggregated fields from the table: "C_ApprovalsDocCategory" */
  C_ApprovalsDocCategory_aggregate: C_ApprovalsDocCategory_Aggregate;
  /** fetch data from the table: "C_ApprovalsDocCategory" using primary key columns */
  C_ApprovalsDocCategory_by_pk?: Maybe<C_ApprovalsDocCategory>;
  /** fetch data from the table: "C_ApprovedUse" */
  C_ApprovedUse: Array<C_ApprovedUse>;
  /** fetch aggregated fields from the table: "C_ApprovedUse" */
  C_ApprovedUse_aggregate: C_ApprovedUse_Aggregate;
  /** fetch data from the table: "C_ApprovedUse" using primary key columns */
  C_ApprovedUse_by_pk?: Maybe<C_ApprovedUse>;
  /** fetch data from the table: "C_AssemblyApplicationType" */
  C_AssemblyApplicationType: Array<C_AssemblyApplicationType>;
  /** fetch aggregated fields from the table: "C_AssemblyApplicationType" */
  C_AssemblyApplicationType_aggregate: C_AssemblyApplicationType_Aggregate;
  /** fetch data from the table: "C_AssemblyApplicationType" using primary key columns */
  C_AssemblyApplicationType_by_pk?: Maybe<C_AssemblyApplicationType>;
  /** fetch data from the table: "C_AssemblyType" */
  C_AssemblyType: Array<C_AssemblyType>;
  /** fetch aggregated fields from the table: "C_AssemblyType" */
  C_AssemblyType_aggregate: C_AssemblyType_Aggregate;
  /** fetch data from the table: "C_AssemblyType" using primary key columns */
  C_AssemblyType_by_pk?: Maybe<C_AssemblyType>;
  /** fetch data from the table: "C_BackingMaterial" */
  C_BackingMaterial: Array<C_BackingMaterial>;
  /** fetch aggregated fields from the table: "C_BackingMaterial" */
  C_BackingMaterial_aggregate: C_BackingMaterial_Aggregate;
  /** fetch data from the table: "C_BackingMaterial" using primary key columns */
  C_BackingMaterial_by_pk?: Maybe<C_BackingMaterial>;
  /** fetch data from the table: "C_BaseSheetApplicationMethod" */
  C_BaseSheetApplicationMethod: Array<C_BaseSheetApplicationMethod>;
  /** fetch aggregated fields from the table: "C_BaseSheetApplicationMethod" */
  C_BaseSheetApplicationMethod_aggregate: C_BaseSheetApplicationMethod_Aggregate;
  /** fetch data from the table: "C_BaseSheetApplicationMethod" using primary key columns */
  C_BaseSheetApplicationMethod_by_pk?: Maybe<C_BaseSheetApplicationMethod>;
  /** fetch data from the table: "C_BattBlanketFacerMaterial" */
  C_BattBlanketFacerMaterial: Array<C_BattBlanketFacerMaterial>;
  /** fetch aggregated fields from the table: "C_BattBlanketFacerMaterial" */
  C_BattBlanketFacerMaterial_aggregate: C_BattBlanketFacerMaterial_Aggregate;
  /** fetch data from the table: "C_BattBlanketFacerMaterial" using primary key columns */
  C_BattBlanketFacerMaterial_by_pk?: Maybe<C_BattBlanketFacerMaterial>;
  /** fetch data from the table: "C_BattBlanketMaterial" */
  C_BattBlanketMaterial: Array<C_BattBlanketMaterial>;
  /** fetch aggregated fields from the table: "C_BattBlanketMaterial" */
  C_BattBlanketMaterial_aggregate: C_BattBlanketMaterial_Aggregate;
  /** fetch data from the table: "C_BattBlanketMaterial" using primary key columns */
  C_BattBlanketMaterial_by_pk?: Maybe<C_BattBlanketMaterial>;
  /** fetch data from the table: "C_BoardProfile" */
  C_BoardProfile: Array<C_BoardProfile>;
  /** fetch aggregated fields from the table: "C_BoardProfile" */
  C_BoardProfile_aggregate: C_BoardProfile_Aggregate;
  /** fetch data from the table: "C_BoardProfile" using primary key columns */
  C_BoardProfile_by_pk?: Maybe<C_BoardProfile>;
  /** fetch data from the table: "C_BoardStockMaterial" */
  C_BoardStockMaterial: Array<C_BoardStockMaterial>;
  /** fetch aggregated fields from the table: "C_BoardStockMaterial" */
  C_BoardStockMaterial_aggregate: C_BoardStockMaterial_Aggregate;
  /** fetch data from the table: "C_BoardStockMaterial" using primary key columns */
  C_BoardStockMaterial_by_pk?: Maybe<C_BoardStockMaterial>;
  /** fetch data from the table: "C_BuildingEnclosure" */
  C_BuildingEnclosure: Array<C_BuildingEnclosure>;
  /** fetch aggregated fields from the table: "C_BuildingEnclosure" */
  C_BuildingEnclosure_aggregate: C_BuildingEnclosure_Aggregate;
  /** fetch data from the table: "C_BuildingEnclosure" using primary key columns */
  C_BuildingEnclosure_by_pk?: Maybe<C_BuildingEnclosure>;
  /** fetch data from the table: "C_CementAggregateRatio" */
  C_CementAggregateRatio: Array<C_CementAggregateRatio>;
  /** fetch aggregated fields from the table: "C_CementAggregateRatio" */
  C_CementAggregateRatio_aggregate: C_CementAggregateRatio_Aggregate;
  /** fetch data from the table: "C_CementAggregateRatio" using primary key columns */
  C_CementAggregateRatio_by_pk?: Maybe<C_CementAggregateRatio>;
  /** fetch data from the table: "C_CoatingMaterial" */
  C_CoatingMaterial: Array<C_CoatingMaterial>;
  /** fetch aggregated fields from the table: "C_CoatingMaterial" */
  C_CoatingMaterial_aggregate: C_CoatingMaterial_Aggregate;
  /** fetch data from the table: "C_CoatingMaterial" using primary key columns */
  C_CoatingMaterial_by_pk?: Maybe<C_CoatingMaterial>;
  /** fetch data from the table: "C_ComponentType" */
  C_ComponentType: Array<C_ComponentType>;
  /** fetch aggregated fields from the table: "C_ComponentType" */
  C_ComponentType_aggregate: C_ComponentType_Aggregate;
  /** fetch data from the table: "C_ComponentType" using primary key columns */
  C_ComponentType_by_pk?: Maybe<C_ComponentType>;
  /** fetch data from the table: "C_ContactType" */
  C_ContactType: Array<C_ContactType>;
  /** fetch aggregated fields from the table: "C_ContactType" */
  C_ContactType_aggregate: C_ContactType_Aggregate;
  /** fetch data from the table: "C_ContactType" using primary key columns */
  C_ContactType_by_pk?: Maybe<C_ContactType>;
  /** fetch data from the table: "C_Country" */
  C_Country: Array<C_Country>;
  /** fetch aggregated fields from the table: "C_Country" */
  C_Country_aggregate: C_Country_Aggregate;
  /** fetch data from the table: "C_Country" using primary key columns */
  C_Country_by_pk?: Maybe<C_Country>;
  /** fetch data from the table: "C_CoverBoardMaterial" */
  C_CoverBoardMaterial: Array<C_CoverBoardMaterial>;
  /** fetch aggregated fields from the table: "C_CoverBoardMaterial" */
  C_CoverBoardMaterial_aggregate: C_CoverBoardMaterial_Aggregate;
  /** fetch data from the table: "C_CoverBoardMaterial" using primary key columns */
  C_CoverBoardMaterial_by_pk?: Maybe<C_CoverBoardMaterial>;
  /** fetch data from the table: "C_CoverColor" */
  C_CoverColor: Array<C_CoverColor>;
  /** fetch aggregated fields from the table: "C_CoverColor" */
  C_CoverColor_aggregate: C_CoverColor_Aggregate;
  /** fetch data from the table: "C_CoverColor" using primary key columns */
  C_CoverColor_by_pk?: Maybe<C_CoverColor>;
  /** fetch data from the table: "C_CoverSecurementMethod" */
  C_CoverSecurementMethod: Array<C_CoverSecurementMethod>;
  /** fetch aggregated fields from the table: "C_CoverSecurementMethod" */
  C_CoverSecurementMethod_aggregate: C_CoverSecurementMethod_Aggregate;
  /** fetch data from the table: "C_CoverSecurementMethod" using primary key columns */
  C_CoverSecurementMethod_by_pk?: Maybe<C_CoverSecurementMethod>;
  /** fetch data from the table: "C_DeckType" */
  C_DeckType: Array<C_DeckType>;
  /** fetch aggregated fields from the table: "C_DeckType" */
  C_DeckType_aggregate: C_DeckType_Aggregate;
  /** fetch data from the table: "C_DeckType" using primary key columns */
  C_DeckType_by_pk?: Maybe<C_DeckType>;
  /** fetch data from the table: "C_ExistingRoofCover" */
  C_ExistingRoofCover: Array<C_ExistingRoofCover>;
  /** fetch aggregated fields from the table: "C_ExistingRoofCover" */
  C_ExistingRoofCover_aggregate: C_ExistingRoofCover_Aggregate;
  /** fetch data from the table: "C_ExistingRoofCover" using primary key columns */
  C_ExistingRoofCover_by_pk?: Maybe<C_ExistingRoofCover>;
  /** fetch data from the table: "C_ExistingRoofDeck" */
  C_ExistingRoofDeck: Array<C_ExistingRoofDeck>;
  /** fetch aggregated fields from the table: "C_ExistingRoofDeck" */
  C_ExistingRoofDeck_aggregate: C_ExistingRoofDeck_Aggregate;
  /** fetch data from the table: "C_ExistingRoofDeck" using primary key columns */
  C_ExistingRoofDeck_by_pk?: Maybe<C_ExistingRoofDeck>;
  /** fetch data from the table: "C_ExistingRoofInsulationMaterial" */
  C_ExistingRoofInsulationMaterial: Array<C_ExistingRoofInsulationMaterial>;
  /** fetch aggregated fields from the table: "C_ExistingRoofInsulationMaterial" */
  C_ExistingRoofInsulationMaterial_aggregate: C_ExistingRoofInsulationMaterial_Aggregate;
  /** fetch data from the table: "C_ExistingRoofInsulationMaterial" using primary key columns */
  C_ExistingRoofInsulationMaterial_by_pk?: Maybe<C_ExistingRoofInsulationMaterial>;
  /** fetch data from the table: "C_ExistingRoofSinglePlyMaterial" */
  C_ExistingRoofSinglePlyMaterial: Array<C_ExistingRoofSinglePlyMaterial>;
  /** fetch aggregated fields from the table: "C_ExistingRoofSinglePlyMaterial" */
  C_ExistingRoofSinglePlyMaterial_aggregate: C_ExistingRoofSinglePlyMaterial_Aggregate;
  /** fetch data from the table: "C_ExistingRoofSinglePlyMaterial" using primary key columns */
  C_ExistingRoofSinglePlyMaterial_by_pk?: Maybe<C_ExistingRoofSinglePlyMaterial>;
  /** fetch data from the table: "C_ExistingRoofSurface" */
  C_ExistingRoofSurface: Array<C_ExistingRoofSurface>;
  /** fetch aggregated fields from the table: "C_ExistingRoofSurface" */
  C_ExistingRoofSurface_aggregate: C_ExistingRoofSurface_Aggregate;
  /** fetch data from the table: "C_ExistingRoofSurface" using primary key columns */
  C_ExistingRoofSurface_by_pk?: Maybe<C_ExistingRoofSurface>;
  /** fetch data from the table: "C_ExtFireRating" */
  C_ExtFireRating: Array<C_ExtFireRating>;
  /** fetch aggregated fields from the table: "C_ExtFireRating" */
  C_ExtFireRating_aggregate: C_ExtFireRating_Aggregate;
  /** fetch data from the table: "C_ExtFireRating" using primary key columns */
  C_ExtFireRating_by_pk?: Maybe<C_ExtFireRating>;
  /** fetch data from the table: "C_Facer" */
  C_Facer: Array<C_Facer>;
  /** fetch data from the table: "C_FacerMaterial" */
  C_FacerMaterial: Array<C_FacerMaterial>;
  /** fetch aggregated fields from the table: "C_FacerMaterial" */
  C_FacerMaterial_aggregate: C_FacerMaterial_Aggregate;
  /** fetch data from the table: "C_FacerMaterial" using primary key columns */
  C_FacerMaterial_by_pk?: Maybe<C_FacerMaterial>;
  /** fetch aggregated fields from the table: "C_Facer" */
  C_Facer_aggregate: C_Facer_Aggregate;
  /** fetch data from the table: "C_Facer" using primary key columns */
  C_Facer_by_pk?: Maybe<C_Facer>;
  /** fetch data from the table: "C_FastenerCoating" */
  C_FastenerCoating: Array<C_FastenerCoating>;
  /** fetch aggregated fields from the table: "C_FastenerCoating" */
  C_FastenerCoating_aggregate: C_FastenerCoating_Aggregate;
  /** fetch data from the table: "C_FastenerCoating" using primary key columns */
  C_FastenerCoating_by_pk?: Maybe<C_FastenerCoating>;
  /** fetch data from the table: "C_FastenerDriveHead" */
  C_FastenerDriveHead: Array<C_FastenerDriveHead>;
  /** fetch aggregated fields from the table: "C_FastenerDriveHead" */
  C_FastenerDriveHead_aggregate: C_FastenerDriveHead_Aggregate;
  /** fetch data from the table: "C_FastenerDriveHead" using primary key columns */
  C_FastenerDriveHead_by_pk?: Maybe<C_FastenerDriveHead>;
  /** fetch data from the table: "C_FastenerType" */
  C_FastenerType: Array<C_FastenerType>;
  /** fetch aggregated fields from the table: "C_FastenerType" */
  C_FastenerType_aggregate: C_FastenerType_Aggregate;
  /** fetch data from the table: "C_FastenerType" using primary key columns */
  C_FastenerType_by_pk?: Maybe<C_FastenerType>;
  /** fetch data from the table: "C_FasteningApplicationMethod" */
  C_FasteningApplicationMethod: Array<C_FasteningApplicationMethod>;
  /** fetch aggregated fields from the table: "C_FasteningApplicationMethod" */
  C_FasteningApplicationMethod_aggregate: C_FasteningApplicationMethod_Aggregate;
  /** fetch data from the table: "C_FasteningApplicationMethod" using primary key columns */
  C_FasteningApplicationMethod_by_pk?: Maybe<C_FasteningApplicationMethod>;
  /** fetch data from the table: "C_FlashingMaterial" */
  C_FlashingMaterial: Array<C_FlashingMaterial>;
  /** fetch aggregated fields from the table: "C_FlashingMaterial" */
  C_FlashingMaterial_aggregate: C_FlashingMaterial_Aggregate;
  /** fetch data from the table: "C_FlashingMaterial" using primary key columns */
  C_FlashingMaterial_by_pk?: Maybe<C_FlashingMaterial>;
  /** fetch data from the table: "C_FlashingType" */
  C_FlashingType: Array<C_FlashingType>;
  /** fetch aggregated fields from the table: "C_FlashingType" */
  C_FlashingType_aggregate: C_FlashingType_Aggregate;
  /** fetch data from the table: "C_FlashingType" using primary key columns */
  C_FlashingType_by_pk?: Maybe<C_FlashingType>;
  /** fetch data from the table: "C_FormDeckMaterial" */
  C_FormDeckMaterial: Array<C_FormDeckMaterial>;
  /** fetch aggregated fields from the table: "C_FormDeckMaterial" */
  C_FormDeckMaterial_aggregate: C_FormDeckMaterial_Aggregate;
  /** fetch data from the table: "C_FormDeckMaterial" using primary key columns */
  C_FormDeckMaterial_by_pk?: Maybe<C_FormDeckMaterial>;
  /** fetch data from the table: "C_GroundRoughnessRating" */
  C_GroundRoughnessRating: Array<C_GroundRoughnessRating>;
  /** fetch aggregated fields from the table: "C_GroundRoughnessRating" */
  C_GroundRoughnessRating_aggregate: C_GroundRoughnessRating_Aggregate;
  /** fetch data from the table: "C_GroundRoughnessRating" using primary key columns */
  C_GroundRoughnessRating_by_pk?: Maybe<C_GroundRoughnessRating>;
  /** fetch data from the table: "C_GutterMaterial" */
  C_GutterMaterial: Array<C_GutterMaterial>;
  /** fetch aggregated fields from the table: "C_GutterMaterial" */
  C_GutterMaterial_aggregate: C_GutterMaterial_Aggregate;
  /** fetch data from the table: "C_GutterMaterial" using primary key columns */
  C_GutterMaterial_by_pk?: Maybe<C_GutterMaterial>;
  /** fetch data from the table: "C_HailRating" */
  C_HailRating: Array<C_HailRating>;
  /** fetch aggregated fields from the table: "C_HailRating" */
  C_HailRating_aggregate: C_HailRating_Aggregate;
  /** fetch data from the table: "C_HailRating" using primary key columns */
  C_HailRating_by_pk?: Maybe<C_HailRating>;
  /** fetch data from the table: "C_IndustryType" */
  C_IndustryType: Array<C_IndustryType>;
  /** fetch aggregated fields from the table: "C_IndustryType" */
  C_IndustryType_aggregate: C_IndustryType_Aggregate;
  /** fetch data from the table: "C_IndustryType" using primary key columns */
  C_IndustryType_by_pk?: Maybe<C_IndustryType>;
  /** fetch data from the table: "C_InsulationCore" */
  C_InsulationCore: Array<C_InsulationCore>;
  /** fetch aggregated fields from the table: "C_InsulationCore" */
  C_InsulationCore_aggregate: C_InsulationCore_Aggregate;
  /** fetch data from the table: "C_InsulationCore" using primary key columns */
  C_InsulationCore_by_pk?: Maybe<C_InsulationCore>;
  /** fetch data from the table: "C_InsulationFoamType" */
  C_InsulationFoamType: Array<C_InsulationFoamType>;
  /** fetch aggregated fields from the table: "C_InsulationFoamType" */
  C_InsulationFoamType_aggregate: C_InsulationFoamType_Aggregate;
  /** fetch data from the table: "C_InsulationFoamType" using primary key columns */
  C_InsulationFoamType_by_pk?: Maybe<C_InsulationFoamType>;
  /** fetch data from the table: "C_IntFireRating" */
  C_IntFireRating: Array<C_IntFireRating>;
  /** fetch aggregated fields from the table: "C_IntFireRating" */
  C_IntFireRating_aggregate: C_IntFireRating_Aggregate;
  /** fetch data from the table: "C_IntFireRating" using primary key columns */
  C_IntFireRating_by_pk?: Maybe<C_IntFireRating>;
  /** fetch data from the table: "C_LapSealantType" */
  C_LapSealantType: Array<C_LapSealantType>;
  /** fetch aggregated fields from the table: "C_LapSealantType" */
  C_LapSealantType_aggregate: C_LapSealantType_Aggregate;
  /** fetch data from the table: "C_LapSealantType" using primary key columns */
  C_LapSealantType_by_pk?: Maybe<C_LapSealantType>;
  /** fetch data from the table: "C_LayerType" */
  C_LayerType: Array<C_LayerType>;
  /** fetch aggregated fields from the table: "C_LayerType" */
  C_LayerType_aggregate: C_LayerType_Aggregate;
  /** fetch data from the table: "C_LayerType" using primary key columns */
  C_LayerType_by_pk?: Maybe<C_LayerType>;
  /** fetch data from the table: "C_LinerPanelMaterial" */
  C_LinerPanelMaterial: Array<C_LinerPanelMaterial>;
  /** fetch aggregated fields from the table: "C_LinerPanelMaterial" */
  C_LinerPanelMaterial_aggregate: C_LinerPanelMaterial_Aggregate;
  /** fetch data from the table: "C_LinerPanelMaterial" using primary key columns */
  C_LinerPanelMaterial_by_pk?: Maybe<C_LinerPanelMaterial>;
  /** fetch data from the table: "C_LiquidApplication" */
  C_LiquidApplication: Array<C_LiquidApplication>;
  /** fetch aggregated fields from the table: "C_LiquidApplication" */
  C_LiquidApplication_aggregate: C_LiquidApplication_Aggregate;
  /** fetch data from the table: "C_LiquidApplication" using primary key columns */
  C_LiquidApplication_by_pk?: Maybe<C_LiquidApplication>;
  /** fetch data from the table: "C_LiquidAppliedMaterial" */
  C_LiquidAppliedMaterial: Array<C_LiquidAppliedMaterial>;
  /** fetch aggregated fields from the table: "C_LiquidAppliedMaterial" */
  C_LiquidAppliedMaterial_aggregate: C_LiquidAppliedMaterial_Aggregate;
  /** fetch data from the table: "C_LiquidAppliedMaterial" using primary key columns */
  C_LiquidAppliedMaterial_by_pk?: Maybe<C_LiquidAppliedMaterial>;
  /** fetch data from the table: "C_LockStatus" */
  C_LockStatus: Array<C_LockStatus>;
  /** fetch aggregated fields from the table: "C_LockStatus" */
  C_LockStatus_aggregate: C_LockStatus_Aggregate;
  /** fetch data from the table: "C_LockStatus" using primary key columns */
  C_LockStatus_by_pk?: Maybe<C_LockStatus>;
  /** fetch data from the table: "C_MDAttributeType" */
  C_MDAttributeType: Array<C_MdAttributeType>;
  /** fetch aggregated fields from the table: "C_MDAttributeType" */
  C_MDAttributeType_aggregate: C_MdAttributeType_Aggregate;
  /** fetch data from the table: "C_MDAttributeType" using primary key columns */
  C_MDAttributeType_by_pk?: Maybe<C_MdAttributeType>;
  /** fetch data from the table: "C_MaintenanceCoatingMaterial" */
  C_MaintenanceCoatingMaterial: Array<C_MaintenanceCoatingMaterial>;
  /** fetch aggregated fields from the table: "C_MaintenanceCoatingMaterial" */
  C_MaintenanceCoatingMaterial_aggregate: C_MaintenanceCoatingMaterial_Aggregate;
  /** fetch data from the table: "C_MaintenanceCoatingMaterial" using primary key columns */
  C_MaintenanceCoatingMaterial_by_pk?: Maybe<C_MaintenanceCoatingMaterial>;
  /** fetch data from the table: "C_MaintenanceCoatingSubstrate" */
  C_MaintenanceCoatingSubstrate: Array<C_MaintenanceCoatingSubstrate>;
  /** fetch aggregated fields from the table: "C_MaintenanceCoatingSubstrate" */
  C_MaintenanceCoatingSubstrate_aggregate: C_MaintenanceCoatingSubstrate_Aggregate;
  /** fetch data from the table: "C_MaintenanceCoatingSubstrate" using primary key columns */
  C_MaintenanceCoatingSubstrate_by_pk?: Maybe<C_MaintenanceCoatingSubstrate>;
  /** fetch data from the table: "C_MembraneTermination" */
  C_MembraneTermination: Array<C_MembraneTermination>;
  /** fetch aggregated fields from the table: "C_MembraneTermination" */
  C_MembraneTermination_aggregate: C_MembraneTermination_Aggregate;
  /** fetch data from the table: "C_MembraneTermination" using primary key columns */
  C_MembraneTermination_by_pk?: Maybe<C_MembraneTermination>;
  /** fetch data from the table: "C_MultiplyMaterial" */
  C_MultiplyMaterial: Array<C_MultiplyMaterial>;
  /** fetch aggregated fields from the table: "C_MultiplyMaterial" */
  C_MultiplyMaterial_aggregate: C_MultiplyMaterial_Aggregate;
  /** fetch data from the table: "C_MultiplyMaterial" using primary key columns */
  C_MultiplyMaterial_by_pk?: Maybe<C_MultiplyMaterial>;
  /** fetch data from the table: "C_PVApplicationMethod" */
  C_PVApplicationMethod: Array<C_PvApplicationMethod>;
  /** fetch aggregated fields from the table: "C_PVApplicationMethod" */
  C_PVApplicationMethod_aggregate: C_PvApplicationMethod_Aggregate;
  /** fetch data from the table: "C_PVApplicationMethod" using primary key columns */
  C_PVApplicationMethod_by_pk?: Maybe<C_PvApplicationMethod>;
  /** fetch data from the table: "C_PVHailRating" */
  C_PVHailRating: Array<C_PvHailRating>;
  /** fetch aggregated fields from the table: "C_PVHailRating" */
  C_PVHailRating_aggregate: C_PvHailRating_Aggregate;
  /** fetch data from the table: "C_PVHailRating" using primary key columns */
  C_PVHailRating_by_pk?: Maybe<C_PvHailRating>;
  /** fetch data from the table: "C_PerimeterFlashingType" */
  C_PerimeterFlashingType: Array<C_PerimeterFlashingType>;
  /** fetch aggregated fields from the table: "C_PerimeterFlashingType" */
  C_PerimeterFlashingType_aggregate: C_PerimeterFlashingType_Aggregate;
  /** fetch data from the table: "C_PerimeterFlashingType" using primary key columns */
  C_PerimeterFlashingType_by_pk?: Maybe<C_PerimeterFlashingType>;
  /** fetch data from the table: "C_PressureZone" */
  C_PressureZone: Array<C_PressureZone>;
  /** fetch aggregated fields from the table: "C_PressureZone" */
  C_PressureZone_aggregate: C_PressureZone_Aggregate;
  /** fetch data from the table: "C_PressureZone" using primary key columns */
  C_PressureZone_by_pk?: Maybe<C_PressureZone>;
  /** fetch data from the table: "C_ProductApprovedUse" */
  C_ProductApprovedUse: Array<C_ProductApprovedUse>;
  /** fetch aggregated fields from the table: "C_ProductApprovedUse" */
  C_ProductApprovedUse_aggregate: C_ProductApprovedUse_Aggregate;
  /** fetch data from the table: "C_ProjectRole" */
  C_ProjectRole: Array<C_ProjectRole>;
  /** fetch aggregated fields from the table: "C_ProjectRole" */
  C_ProjectRole_aggregate: C_ProjectRole_Aggregate;
  /** fetch data from the table: "C_ProjectRole" using primary key columns */
  C_ProjectRole_by_pk?: Maybe<C_ProjectRole>;
  /** fetch data from the table: "C_Reinforcement" */
  C_Reinforcement: Array<C_Reinforcement>;
  /** fetch aggregated fields from the table: "C_Reinforcement" */
  C_Reinforcement_aggregate: C_Reinforcement_Aggregate;
  /** fetch data from the table: "C_Reinforcement" using primary key columns */
  C_Reinforcement_by_pk?: Maybe<C_Reinforcement>;
  /** fetch data from the table: "C_RetarderMaterial" */
  C_RetarderMaterial: Array<C_RetarderMaterial>;
  /** fetch aggregated fields from the table: "C_RetarderMaterial" */
  C_RetarderMaterial_aggregate: C_RetarderMaterial_Aggregate;
  /** fetch data from the table: "C_RetarderMaterial" using primary key columns */
  C_RetarderMaterial_by_pk?: Maybe<C_RetarderMaterial>;
  /** fetch data from the table: "C_ScenarioStatus" */
  C_ScenarioStatus: Array<C_ScenarioStatus>;
  /** fetch aggregated fields from the table: "C_ScenarioStatus" */
  C_ScenarioStatus_aggregate: C_ScenarioStatus_Aggregate;
  /** fetch data from the table: "C_ScenarioStatus" using primary key columns */
  C_ScenarioStatus_by_pk?: Maybe<C_ScenarioStatus>;
  /** fetch data from the table: "C_ScenarioType" */
  C_ScenarioType: Array<C_ScenarioType>;
  /** fetch aggregated fields from the table: "C_ScenarioType" */
  C_ScenarioType_aggregate: C_ScenarioType_Aggregate;
  /** fetch data from the table: "C_ScenarioType" using primary key columns */
  C_ScenarioType_by_pk?: Maybe<C_ScenarioType>;
  /** fetch data from the table: "C_SeamPrimerType" */
  C_SeamPrimerType: Array<C_SeamPrimerType>;
  /** fetch aggregated fields from the table: "C_SeamPrimerType" */
  C_SeamPrimerType_aggregate: C_SeamPrimerType_Aggregate;
  /** fetch data from the table: "C_SeamPrimerType" using primary key columns */
  C_SeamPrimerType_by_pk?: Maybe<C_SeamPrimerType>;
  /** fetch data from the table: "C_SeamWashType" */
  C_SeamWashType: Array<C_SeamWashType>;
  /** fetch aggregated fields from the table: "C_SeamWashType" */
  C_SeamWashType_aggregate: C_SeamWashType_Aggregate;
  /** fetch data from the table: "C_SeamWashType" using primary key columns */
  C_SeamWashType_by_pk?: Maybe<C_SeamWashType>;
  /** fetch data from the table: "C_SecurementMaterial" */
  C_SecurementMaterial: Array<C_SecurementMaterial>;
  /** fetch aggregated fields from the table: "C_SecurementMaterial" */
  C_SecurementMaterial_aggregate: C_SecurementMaterial_Aggregate;
  /** fetch data from the table: "C_SecurementMaterial" using primary key columns */
  C_SecurementMaterial_by_pk?: Maybe<C_SecurementMaterial>;
  /** fetch data from the table: "C_ShinglesHail" */
  C_ShinglesHail: Array<C_ShinglesHail>;
  /** fetch aggregated fields from the table: "C_ShinglesHail" */
  C_ShinglesHail_aggregate: C_ShinglesHail_Aggregate;
  /** fetch data from the table: "C_ShinglesHail" using primary key columns */
  C_ShinglesHail_by_pk?: Maybe<C_ShinglesHail>;
  /** fetch data from the table: "C_ShinglesMaterial" */
  C_ShinglesMaterial: Array<C_ShinglesMaterial>;
  /** fetch aggregated fields from the table: "C_ShinglesMaterial" */
  C_ShinglesMaterial_aggregate: C_ShinglesMaterial_Aggregate;
  /** fetch data from the table: "C_ShinglesMaterial" using primary key columns */
  C_ShinglesMaterial_by_pk?: Maybe<C_ShinglesMaterial>;
  /** fetch data from the table: "C_ShinglesReinforcement" */
  C_ShinglesReinforcement: Array<C_ShinglesReinforcement>;
  /** fetch aggregated fields from the table: "C_ShinglesReinforcement" */
  C_ShinglesReinforcement_aggregate: C_ShinglesReinforcement_Aggregate;
  /** fetch data from the table: "C_ShinglesReinforcement" using primary key columns */
  C_ShinglesReinforcement_by_pk?: Maybe<C_ShinglesReinforcement>;
  /** fetch data from the table: "C_ShinglesSurfacing" */
  C_ShinglesSurfacing: Array<C_ShinglesSurfacing>;
  /** fetch aggregated fields from the table: "C_ShinglesSurfacing" */
  C_ShinglesSurfacing_aggregate: C_ShinglesSurfacing_Aggregate;
  /** fetch data from the table: "C_ShinglesSurfacing" using primary key columns */
  C_ShinglesSurfacing_by_pk?: Maybe<C_ShinglesSurfacing>;
  /** fetch data from the table: "C_ShinglesWindSpeed" */
  C_ShinglesWindSpeed: Array<C_ShinglesWindSpeed>;
  /** fetch aggregated fields from the table: "C_ShinglesWindSpeed" */
  C_ShinglesWindSpeed_aggregate: C_ShinglesWindSpeed_Aggregate;
  /** fetch data from the table: "C_ShinglesWindSpeed" using primary key columns */
  C_ShinglesWindSpeed_by_pk?: Maybe<C_ShinglesWindSpeed>;
  /** fetch data from the table: "C_SinglePlyMaterial" */
  C_SinglePlyMaterial: Array<C_SinglePlyMaterial>;
  /** fetch aggregated fields from the table: "C_SinglePlyMaterial" */
  C_SinglePlyMaterial_aggregate: C_SinglePlyMaterial_Aggregate;
  /** fetch data from the table: "C_SinglePlyMaterial" using primary key columns */
  C_SinglePlyMaterial_by_pk?: Maybe<C_SinglePlyMaterial>;
  /** fetch data from the table: "C_StandingLapSeamMaterial" */
  C_StandingLapSeamMaterial: Array<C_StandingLapSeamMaterial>;
  /** fetch aggregated fields from the table: "C_StandingLapSeamMaterial" */
  C_StandingLapSeamMaterial_aggregate: C_StandingLapSeamMaterial_Aggregate;
  /** fetch data from the table: "C_StandingLapSeamMaterial" using primary key columns */
  C_StandingLapSeamMaterial_by_pk?: Maybe<C_StandingLapSeamMaterial>;
  /** fetch data from the table: "C_StateProvince" */
  C_StateProvince: Array<C_StateProvince>;
  /** fetch aggregated fields from the table: "C_StateProvince" */
  C_StateProvince_aggregate: C_StateProvince_Aggregate;
  /** fetch data from the table: "C_StateProvince" using primary key columns */
  C_StateProvince_by_pk?: Maybe<C_StateProvince>;
  /** fetch data from the table: "C_Status" */
  C_Status: Array<C_Status>;
  /** fetch aggregated fields from the table: "C_Status" */
  C_Status_aggregate: C_Status_Aggregate;
  /** fetch data from the table: "C_Status" using primary key columns */
  C_Status_by_pk?: Maybe<C_Status>;
  /** fetch data from the table: "C_SteelDeckRibType" */
  C_SteelDeckRibType: Array<C_SteelDeckRibType>;
  /** fetch aggregated fields from the table: "C_SteelDeckRibType" */
  C_SteelDeckRibType_aggregate: C_SteelDeckRibType_Aggregate;
  /** fetch data from the table: "C_SteelDeckRibType" using primary key columns */
  C_SteelDeckRibType_by_pk?: Maybe<C_SteelDeckRibType>;
  /** fetch data from the table: "C_StressDistributorType" */
  C_StressDistributorType: Array<C_StressDistributorType>;
  /** fetch aggregated fields from the table: "C_StressDistributorType" */
  C_StressDistributorType_aggregate: C_StressDistributorType_Aggregate;
  /** fetch data from the table: "C_StressDistributorType" using primary key columns */
  C_StressDistributorType_by_pk?: Maybe<C_StressDistributorType>;
  /** fetch data from the table: "C_StressPlateShape" */
  C_StressPlateShape: Array<C_StressPlateShape>;
  /** fetch aggregated fields from the table: "C_StressPlateShape" */
  C_StressPlateShape_aggregate: C_StressPlateShape_Aggregate;
  /** fetch data from the table: "C_StressPlateShape" using primary key columns */
  C_StressPlateShape_by_pk?: Maybe<C_StressPlateShape>;
  /** fetch data from the table: "C_StructureType" */
  C_StructureType: Array<C_StructureType>;
  /** fetch aggregated fields from the table: "C_StructureType" */
  C_StructureType_aggregate: C_StructureType_Aggregate;
  /** fetch data from the table: "C_StructureType" using primary key columns */
  C_StructureType_by_pk?: Maybe<C_StructureType>;
  /** fetch data from the table: "C_SubassemblyType" */
  C_SubassemblyType: Array<C_SubassemblyType>;
  /** fetch aggregated fields from the table: "C_SubassemblyType" */
  C_SubassemblyType_aggregate: C_SubassemblyType_Aggregate;
  /** fetch data from the table: "C_SubassemblyType" using primary key columns */
  C_SubassemblyType_by_pk?: Maybe<C_SubassemblyType>;
  /** fetch data from the table: "C_Substrate" */
  C_Substrate: Array<C_Substrate>;
  /** fetch data from the table: "C_SubstratePrimerMaterial" */
  C_SubstratePrimerMaterial: Array<C_SubstratePrimerMaterial>;
  /** fetch aggregated fields from the table: "C_SubstratePrimerMaterial" */
  C_SubstratePrimerMaterial_aggregate: C_SubstratePrimerMaterial_Aggregate;
  /** fetch data from the table: "C_SubstratePrimerMaterial" using primary key columns */
  C_SubstratePrimerMaterial_by_pk?: Maybe<C_SubstratePrimerMaterial>;
  /** fetch aggregated fields from the table: "C_Substrate" */
  C_Substrate_aggregate: C_Substrate_Aggregate;
  /** fetch data from the table: "C_Substrate" using primary key columns */
  C_Substrate_by_pk?: Maybe<C_Substrate>;
  /** fetch data from the table: "C_SurfaceType" */
  C_SurfaceType: Array<C_SurfaceType>;
  /** fetch aggregated fields from the table: "C_SurfaceType" */
  C_SurfaceType_aggregate: C_SurfaceType_Aggregate;
  /** fetch data from the table: "C_SurfaceType" using primary key columns */
  C_SurfaceType_by_pk?: Maybe<C_SurfaceType>;
  /** fetch data from the table: "C_SurfacingMaterial" */
  C_SurfacingMaterial: Array<C_SurfacingMaterial>;
  /** fetch aggregated fields from the table: "C_SurfacingMaterial" */
  C_SurfacingMaterial_aggregate: C_SurfacingMaterial_Aggregate;
  /** fetch data from the table: "C_SurfacingMaterial" using primary key columns */
  C_SurfacingMaterial_by_pk?: Maybe<C_SurfacingMaterial>;
  /** fetch data from the table: "C_SystemApprovedUse" */
  C_SystemApprovedUse: Array<C_SystemApprovedUse>;
  /** fetch aggregated fields from the table: "C_SystemApprovedUse" */
  C_SystemApprovedUse_aggregate: C_SystemApprovedUse_Aggregate;
  /** fetch data from the table: "C_ThermalBarrierMaterial" */
  C_ThermalBarrierMaterial: Array<C_ThermalBarrierMaterial>;
  /** fetch aggregated fields from the table: "C_ThermalBarrierMaterial" */
  C_ThermalBarrierMaterial_aggregate: C_ThermalBarrierMaterial_Aggregate;
  /** fetch data from the table: "C_ThermalBarrierMaterial" using primary key columns */
  C_ThermalBarrierMaterial_by_pk?: Maybe<C_ThermalBarrierMaterial>;
  /** fetch data from the table: "C_WIPDelistReason" */
  C_WIPDelistReason: Array<C_WipDelistReason>;
  /** fetch aggregated fields from the table: "C_WIPDelistReason" */
  C_WIPDelistReason_aggregate: C_WipDelistReason_Aggregate;
  /** fetch data from the table: "C_WIPDelistReason" using primary key columns */
  C_WIPDelistReason_by_pk?: Maybe<C_WipDelistReason>;
  /** fetch data from the table: "C_WIPOnHoldReason" */
  C_WIPOnHoldReason: Array<C_WipOnHoldReason>;
  /** fetch aggregated fields from the table: "C_WIPOnHoldReason" */
  C_WIPOnHoldReason_aggregate: C_WipOnHoldReason_Aggregate;
  /** fetch data from the table: "C_WIPOnHoldReason" using primary key columns */
  C_WIPOnHoldReason_by_pk?: Maybe<C_WipOnHoldReason>;
  /** fetch data from the table: "C_WIPStatus" */
  C_WIPStatus: Array<C_WipStatus>;
  /** fetch aggregated fields from the table: "C_WIPStatus" */
  C_WIPStatus_aggregate: C_WipStatus_Aggregate;
  /** fetch data from the table: "C_WIPStatus" using primary key columns */
  C_WIPStatus_by_pk?: Maybe<C_WipStatus>;
  /** fetch data from the table: "C_WeldApplication" */
  C_WeldApplication: Array<C_WeldApplication>;
  /** fetch aggregated fields from the table: "C_WeldApplication" */
  C_WeldApplication_aggregate: C_WeldApplication_Aggregate;
  /** fetch data from the table: "C_WeldApplication" using primary key columns */
  C_WeldApplication_by_pk?: Maybe<C_WeldApplication>;
  /** fetch data from the table: "C_WeldConfiguration" */
  C_WeldConfiguration: Array<C_WeldConfiguration>;
  /** fetch aggregated fields from the table: "C_WeldConfiguration" */
  C_WeldConfiguration_aggregate: C_WeldConfiguration_Aggregate;
  /** fetch data from the table: "C_WeldConfiguration" using primary key columns */
  C_WeldConfiguration_by_pk?: Maybe<C_WeldConfiguration>;
  /** fetch data from the table: "C_WindBorneDebris" */
  C_WindBorneDebris: Array<C_WindBorneDebris>;
  /** fetch aggregated fields from the table: "C_WindBorneDebris" */
  C_WindBorneDebris_aggregate: C_WindBorneDebris_Aggregate;
  /** fetch data from the table: "C_WindBorneDebris" using primary key columns */
  C_WindBorneDebris_by_pk?: Maybe<C_WindBorneDebris>;
  /** fetch data from the table: "C_WoodSpecies" */
  C_WoodSpecies: Array<C_WoodSpecies>;
  /** fetch aggregated fields from the table: "C_WoodSpecies" */
  C_WoodSpecies_aggregate: C_WoodSpecies_Aggregate;
  /** fetch data from the table: "C_WoodSpecies" using primary key columns */
  C_WoodSpecies_by_pk?: Maybe<C_WoodSpecies>;
  /** fetch data from the table: "C_WoodType" */
  C_WoodType: Array<C_WoodType>;
  /** fetch aggregated fields from the table: "C_WoodType" */
  C_WoodType_aggregate: C_WoodType_Aggregate;
  /** fetch data from the table: "C_WoodType" using primary key columns */
  C_WoodType_by_pk?: Maybe<C_WoodType>;
  /** fetch data from the table: "C_WorkflowStatus" */
  C_WorkflowStatus: Array<C_WorkflowStatus>;
  /** fetch aggregated fields from the table: "C_WorkflowStatus" */
  C_WorkflowStatus_aggregate: C_WorkflowStatus_Aggregate;
  /** fetch data from the table: "C_WorkflowStatus" using primary key columns */
  C_WorkflowStatus_by_pk?: Maybe<C_WorkflowStatus>;
  /** fetch data from the table: "CapPly" */
  CapPly: Array<CapPly>;
  /** fetch data from the table: "CapPlyUsage" */
  CapPlyUsage: Array<CapPlyUsage>;
  /** fetch aggregated fields from the table: "CapPlyUsage" */
  CapPlyUsage_aggregate: CapPlyUsage_Aggregate;
  /** fetch data from the table: "CapPlyUsage" using primary key columns */
  CapPlyUsage_by_pk?: Maybe<CapPlyUsage>;
  /** fetch aggregated fields from the table: "CapPly" */
  CapPly_aggregate: CapPly_Aggregate;
  /** fetch data from the table: "CapPly" using primary key columns */
  CapPly_by_pk?: Maybe<CapPly>;
  /** fetch data from the table: "Category" */
  Category: Array<Category>;
  /** fetch data from the table: "CategoryLog" */
  CategoryLog: Array<CategoryLog>;
  /** fetch aggregated fields from the table: "CategoryLog" */
  CategoryLog_aggregate: CategoryLog_Aggregate;
  /** fetch data from the table: "CategoryLog" using primary key columns */
  CategoryLog_by_pk?: Maybe<CategoryLog>;
  /** fetch data from the table: "Category_Coating" */
  Category_Coating: Array<Category_Coating>;
  /** fetch aggregated fields from the table: "Category_Coating" */
  Category_Coating_aggregate: Category_Coating_Aggregate;
  /** fetch data from the table: "Category_Cover" */
  Category_Cover: Array<Category_Cover>;
  /** fetch aggregated fields from the table: "Category_Cover" */
  Category_Cover_aggregate: Category_Cover_Aggregate;
  /** fetch data from the table: "Category_Deck" */
  Category_Deck: Array<Category_Deck>;
  /** fetch aggregated fields from the table: "Category_Deck" */
  Category_Deck_aggregate: Category_Deck_Aggregate;
  /** fetch data from the table: "Category_Flashing" */
  Category_Flashing: Array<Category_Flashing>;
  /** fetch aggregated fields from the table: "Category_Flashing" */
  Category_Flashing_aggregate: Category_Flashing_Aggregate;
  /** fetch data from the table: "Category_Insulation" */
  Category_Insulation: Array<Category_Insulation>;
  /** fetch aggregated fields from the table: "Category_Insulation" */
  Category_Insulation_aggregate: Category_Insulation_Aggregate;
  /** fetch data from the table: "Category_Other" */
  Category_Other: Array<Category_Other>;
  /** fetch aggregated fields from the table: "Category_Other" */
  Category_Other_aggregate: Category_Other_Aggregate;
  /** fetch data from the table: "Category_Seaming" */
  Category_Seaming: Array<Category_Seaming>;
  /** fetch aggregated fields from the table: "Category_Seaming" */
  Category_Seaming_aggregate: Category_Seaming_Aggregate;
  /** fetch data from the table: "Category_Securement" */
  Category_Securement: Array<Category_Securement>;
  /** fetch aggregated fields from the table: "Category_Securement" */
  Category_Securement_aggregate: Category_Securement_Aggregate;
  /** fetch data from the table: "Category_Sheet" */
  Category_Sheet: Array<Category_Sheet>;
  /** fetch aggregated fields from the table: "Category_Sheet" */
  Category_Sheet_aggregate: Category_Sheet_Aggregate;
  /** fetch aggregated fields from the table: "Category" */
  Category_aggregate: Category_Aggregate;
  /** fetch data from the table: "Category" using primary key columns */
  Category_by_pk?: Maybe<Category>;
  /** fetch data from the table: "CementitiousWoodFiberDeck" */
  CementitiousWoodFiberDeck: Array<CementitiousWoodFiberDeck>;
  /** fetch data from the table: "CementitiousWoodFiberDeckUsage" */
  CementitiousWoodFiberDeckUsage: Array<CementitiousWoodFiberDeckUsage>;
  /** fetch aggregated fields from the table: "CementitiousWoodFiberDeckUsage" */
  CementitiousWoodFiberDeckUsage_aggregate: CementitiousWoodFiberDeckUsage_Aggregate;
  /** fetch data from the table: "CementitiousWoodFiberDeckUsage" using primary key columns */
  CementitiousWoodFiberDeckUsage_by_pk?: Maybe<CementitiousWoodFiberDeckUsage>;
  /** fetch aggregated fields from the table: "CementitiousWoodFiberDeck" */
  CementitiousWoodFiberDeck_aggregate: CementitiousWoodFiberDeck_Aggregate;
  /** fetch data from the table: "CementitiousWoodFiberDeck" using primary key columns */
  CementitiousWoodFiberDeck_by_pk?: Maybe<CementitiousWoodFiberDeck>;
  /** fetch data from the table: "Clip" */
  Clip: Array<Clip>;
  /** fetch data from the table: "ClipUsage" */
  ClipUsage: Array<ClipUsage>;
  /** fetch aggregated fields from the table: "ClipUsage" */
  ClipUsage_aggregate: ClipUsage_Aggregate;
  /** fetch data from the table: "ClipUsage" using primary key columns */
  ClipUsage_by_pk?: Maybe<ClipUsage>;
  /** fetch aggregated fields from the table: "Clip" */
  Clip_aggregate: Clip_Aggregate;
  /** fetch data from the table: "Clip" using primary key columns */
  Clip_by_pk?: Maybe<Clip>;
  /** fetch data from the table: "Coat" */
  Coat: Array<Coat>;
  /** fetch data from the table: "CoatUsage" */
  CoatUsage: Array<CoatUsage>;
  /** fetch aggregated fields from the table: "CoatUsage" */
  CoatUsage_aggregate: CoatUsage_Aggregate;
  /** fetch data from the table: "CoatUsage" using primary key columns */
  CoatUsage_by_pk?: Maybe<CoatUsage>;
  /** fetch aggregated fields from the table: "Coat" */
  Coat_aggregate: Coat_Aggregate;
  /** fetch data from the table: "Coat" using primary key columns */
  Coat_by_pk?: Maybe<Coat>;
  /** fetch data from the table: "Coating" */
  Coating: Array<Coating>;
  /** fetch data from the table: "CoatingSystemUsage" */
  CoatingSystemUsage: Array<CoatingSystemUsage>;
  /** fetch aggregated fields from the table: "CoatingSystemUsage" */
  CoatingSystemUsage_aggregate: CoatingSystemUsage_Aggregate;
  /** fetch data from the table: "CoatingSystemUsage" using primary key columns */
  CoatingSystemUsage_by_pk?: Maybe<CoatingSystemUsage>;
  /** fetch data from the table: "CoatingUsage" */
  CoatingUsage: Array<CoatingUsage>;
  /** fetch aggregated fields from the table: "CoatingUsage" */
  CoatingUsage_aggregate: CoatingUsage_Aggregate;
  /** fetch data from the table: "CoatingUsage" using primary key columns */
  CoatingUsage_by_pk?: Maybe<CoatingUsage>;
  /** fetch aggregated fields from the table: "Coating" */
  Coating_aggregate: Coating_Aggregate;
  /** fetch data from the table: "Coating" using primary key columns */
  Coating_by_pk?: Maybe<Coating>;
  /** fetch data from the table: "Component" */
  Component: Array<Component>;
  /** fetch data from the table: "ComponentApprovedUse" */
  ComponentApprovedUse: Array<ComponentApprovedUse>;
  /** fetch aggregated fields from the table: "ComponentApprovedUse" */
  ComponentApprovedUse_aggregate: ComponentApprovedUse_Aggregate;
  /** fetch data from the table: "ComponentApprovedUse" using primary key columns */
  ComponentApprovedUse_by_pk?: Maybe<ComponentApprovedUse>;
  /** fetch data from the table: "ComponentDoc" */
  ComponentDoc: Array<ComponentDoc>;
  /** fetch aggregated fields from the table: "ComponentDoc" */
  ComponentDoc_aggregate: ComponentDoc_Aggregate;
  /** fetch data from the table: "ComponentDoc" using primary key columns */
  ComponentDoc_by_pk?: Maybe<ComponentDoc>;
  /** fetch data from the table: "ComponentLayer" */
  ComponentLayer: Array<ComponentLayer>;
  /** fetch data from the table: "ComponentLayerUsage" */
  ComponentLayerUsage: Array<ComponentLayerUsage>;
  /** fetch aggregated fields from the table: "ComponentLayerUsage" */
  ComponentLayerUsage_aggregate: ComponentLayerUsage_Aggregate;
  /** fetch data from the table: "ComponentLayerUsage" using primary key columns */
  ComponentLayerUsage_by_pk?: Maybe<ComponentLayerUsage>;
  /** fetch aggregated fields from the table: "ComponentLayer" */
  ComponentLayer_aggregate: ComponentLayer_Aggregate;
  /** fetch data from the table: "ComponentLayer" using primary key columns */
  ComponentLayer_by_pk?: Maybe<ComponentLayer>;
  /** fetch aggregated fields from the table: "Component" */
  Component_aggregate: Component_Aggregate;
  /** fetch data from the table: "Component" using primary key columns */
  Component_by_pk?: Maybe<Component>;
  /** fetch data from the table: "CompositePanelCover" */
  CompositePanelCover: Array<CompositePanelCover>;
  /** fetch aggregated fields from the table: "CompositePanelCover" */
  CompositePanelCover_aggregate: CompositePanelCover_Aggregate;
  /** fetch data from the table: "CompositePanelCover" using primary key columns */
  CompositePanelCover_by_pk?: Maybe<CompositePanelCover>;
  /** fetch data from the table: "CompositePanelUsage" */
  CompositePanelUsage: Array<CompositePanelUsage>;
  /** fetch aggregated fields from the table: "CompositePanelUsage" */
  CompositePanelUsage_aggregate: CompositePanelUsage_Aggregate;
  /** fetch data from the table: "CompositePanelUsage" using primary key columns */
  CompositePanelUsage_by_pk?: Maybe<CompositePanelUsage>;
  /** fetch data from the table: "ContactInfo" */
  ContactInfo: Array<ContactInfo>;
  /** fetch aggregated fields from the table: "ContactInfo" */
  ContactInfo_aggregate: ContactInfo_Aggregate;
  /** fetch data from the table: "ContactInfo" using primary key columns */
  ContactInfo_by_pk?: Maybe<ContactInfo>;
  /** fetch data from the table: "Coping" */
  Coping: Array<Coping>;
  /** fetch aggregated fields from the table: "Coping" */
  Coping_aggregate: Coping_Aggregate;
  /** fetch data from the table: "Coping" using primary key columns */
  Coping_by_pk?: Maybe<Coping>;
  /** fetch data from the table: "CoverBoard" */
  CoverBoard: Array<CoverBoard>;
  /** An array relationship */
  CoverBoardBoardSizes: Array<CoverBoardBoardSizes>;
  /** An aggregate relationship */
  CoverBoardBoardSizes_aggregate: CoverBoardBoardSizes_Aggregate;
  /** fetch data from the table: "CoverBoardBoardSizes" using primary key columns */
  CoverBoardBoardSizes_by_pk?: Maybe<CoverBoardBoardSizes>;
  /** fetch data from the table: "CoverBoardFacerBottom" */
  CoverBoardFacerBottom: Array<CoverBoardFacerBottom>;
  /** fetch aggregated fields from the table: "CoverBoardFacerBottom" */
  CoverBoardFacerBottom_aggregate: CoverBoardFacerBottom_Aggregate;
  /** fetch data from the table: "CoverBoardFacerBottom" using primary key columns */
  CoverBoardFacerBottom_by_pk?: Maybe<CoverBoardFacerBottom>;
  /** fetch data from the table: "CoverBoardFacerTop" */
  CoverBoardFacerTop: Array<CoverBoardFacerTop>;
  /** fetch aggregated fields from the table: "CoverBoardFacerTop" */
  CoverBoardFacerTop_aggregate: CoverBoardFacerTop_Aggregate;
  /** fetch data from the table: "CoverBoardFacerTop" using primary key columns */
  CoverBoardFacerTop_by_pk?: Maybe<CoverBoardFacerTop>;
  /** fetch data from the table: "CoverBoardUsage" */
  CoverBoardUsage: Array<CoverBoardUsage>;
  /** fetch aggregated fields from the table: "CoverBoardUsage" */
  CoverBoardUsage_aggregate: CoverBoardUsage_Aggregate;
  /** fetch data from the table: "CoverBoardUsage" using primary key columns */
  CoverBoardUsage_by_pk?: Maybe<CoverBoardUsage>;
  /** fetch aggregated fields from the table: "CoverBoard" */
  CoverBoard_aggregate: CoverBoard_Aggregate;
  /** fetch data from the table: "CoverBoard" using primary key columns */
  CoverBoard_by_pk?: Maybe<CoverBoard>;
  /** fetch data from the table: "CoverStripDisk" */
  CoverStripDisk: Array<CoverStripDisk>;
  /** fetch data from the table: "CoverStripDiskUsage" */
  CoverStripDiskUsage: Array<CoverStripDiskUsage>;
  /** fetch aggregated fields from the table: "CoverStripDiskUsage" */
  CoverStripDiskUsage_aggregate: CoverStripDiskUsage_Aggregate;
  /** fetch data from the table: "CoverStripDiskUsage" using primary key columns */
  CoverStripDiskUsage_by_pk?: Maybe<CoverStripDiskUsage>;
  /** fetch aggregated fields from the table: "CoverStripDisk" */
  CoverStripDisk_aggregate: CoverStripDisk_Aggregate;
  /** fetch data from the table: "CoverStripDisk" using primary key columns */
  CoverStripDisk_by_pk?: Maybe<CoverStripDisk>;
  /** fetch data from the table: "ExistingRoofLayer" */
  ExistingRoofLayer: Array<ExistingRoofLayer>;
  /** fetch aggregated fields from the table: "ExistingRoofLayer" */
  ExistingRoofLayer_aggregate: ExistingRoofLayer_Aggregate;
  /** fetch data from the table: "ExistingRoofLayer" using primary key columns */
  ExistingRoofLayer_by_pk?: Maybe<ExistingRoofLayer>;
  /** fetch data from the table: "ExpansionJoint" */
  ExpansionJoint: Array<ExpansionJoint>;
  /** fetch data from the table: "ExpansionJointUsage" */
  ExpansionJointUsage: Array<ExpansionJointUsage>;
  /** fetch aggregated fields from the table: "ExpansionJointUsage" */
  ExpansionJointUsage_aggregate: ExpansionJointUsage_Aggregate;
  /** fetch data from the table: "ExpansionJointUsage" using primary key columns */
  ExpansionJointUsage_by_pk?: Maybe<ExpansionJointUsage>;
  /** fetch aggregated fields from the table: "ExpansionJoint" */
  ExpansionJoint_aggregate: ExpansionJoint_Aggregate;
  /** fetch data from the table: "ExpansionJoint" using primary key columns */
  ExpansionJoint_by_pk?: Maybe<ExpansionJoint>;
  /** fetch data from the table: "ExtendedLayerConfigSplit" */
  ExtendedLayerConfigSplit: Array<ExtendedLayerConfigSplit>;
  /** fetch aggregated fields from the table: "ExtendedLayerConfigSplit" */
  ExtendedLayerConfigSplit_aggregate: ExtendedLayerConfigSplit_Aggregate;
  /** fetch data from the table: "ExtendedLayerConfigSplit" using primary key columns */
  ExtendedLayerConfigSplit_by_pk?: Maybe<ExtendedLayerConfigSplit>;
  /** fetch data from the table: "FMUserAccount" */
  FMUserAccount: Array<FmUserAccount>;
  /** fetch aggregated fields from the table: "FMUserAccount" */
  FMUserAccount_aggregate: FmUserAccount_Aggregate;
  /** fetch data from the table: "FMUserAccount" using primary key columns */
  FMUserAccount_by_pk?: Maybe<FmUserAccount>;
  /** An array relationship */
  Fascia: Array<Fascia>;
  /** An aggregate relationship */
  Fascia_aggregate: Fascia_Aggregate;
  /** fetch data from the table: "Fascia" using primary key columns */
  Fascia_by_pk?: Maybe<Fascia>;
  /** fetch data from the table: "Fastener" */
  Fastener: Array<Fastener>;
  /** fetch data from the table: "FastenerUsage" */
  FastenerUsage: Array<FastenerUsage>;
  /** fetch aggregated fields from the table: "FastenerUsage" */
  FastenerUsage_aggregate: FastenerUsage_Aggregate;
  /** fetch data from the table: "FastenerUsage" using primary key columns */
  FastenerUsage_by_pk?: Maybe<FastenerUsage>;
  /** fetch aggregated fields from the table: "Fastener" */
  Fastener_aggregate: Fastener_Aggregate;
  /** fetch data from the table: "Fastener" using primary key columns */
  Fastener_by_pk?: Maybe<Fastener>;
  /** fetch data from the table: "FasteningSystemBattenBarUsage" */
  FasteningSystemBattenBarUsage: Array<FasteningSystemBattenBarUsage>;
  /** fetch aggregated fields from the table: "FasteningSystemBattenBarUsage" */
  FasteningSystemBattenBarUsage_aggregate: FasteningSystemBattenBarUsage_Aggregate;
  /** fetch data from the table: "FasteningSystemBattenBarUsage" using primary key columns */
  FasteningSystemBattenBarUsage_by_pk?: Maybe<FasteningSystemBattenBarUsage>;
  /** fetch data from the table: "FasteningSystemClipUsage" */
  FasteningSystemClipUsage: Array<FasteningSystemClipUsage>;
  /** fetch aggregated fields from the table: "FasteningSystemClipUsage" */
  FasteningSystemClipUsage_aggregate: FasteningSystemClipUsage_Aggregate;
  /** fetch data from the table: "FasteningSystemClipUsage" using primary key columns */
  FasteningSystemClipUsage_by_pk?: Maybe<FasteningSystemClipUsage>;
  /** fetch data from the table: "FasteningSystemStressPlateUsage" */
  FasteningSystemStressPlateUsage: Array<FasteningSystemStressPlateUsage>;
  /** fetch aggregated fields from the table: "FasteningSystemStressPlateUsage" */
  FasteningSystemStressPlateUsage_aggregate: FasteningSystemStressPlateUsage_Aggregate;
  /** fetch data from the table: "FasteningSystemStressPlateUsage" using primary key columns */
  FasteningSystemStressPlateUsage_by_pk?: Maybe<FasteningSystemStressPlateUsage>;
  /** fetch data from the table: "FiberReinforcedPlasticDeck" */
  FiberReinforcedPlasticDeck: Array<FiberReinforcedPlasticDeck>;
  /** fetch data from the table: "FiberReinforcedPlasticDeckUsage" */
  FiberReinforcedPlasticDeckUsage: Array<FiberReinforcedPlasticDeckUsage>;
  /** fetch aggregated fields from the table: "FiberReinforcedPlasticDeckUsage" */
  FiberReinforcedPlasticDeckUsage_aggregate: FiberReinforcedPlasticDeckUsage_Aggregate;
  /** fetch data from the table: "FiberReinforcedPlasticDeckUsage" using primary key columns */
  FiberReinforcedPlasticDeckUsage_by_pk?: Maybe<FiberReinforcedPlasticDeckUsage>;
  /** fetch aggregated fields from the table: "FiberReinforcedPlasticDeck" */
  FiberReinforcedPlasticDeck_aggregate: FiberReinforcedPlasticDeck_Aggregate;
  /** fetch data from the table: "FiberReinforcedPlasticDeck" using primary key columns */
  FiberReinforcedPlasticDeck_by_pk?: Maybe<FiberReinforcedPlasticDeck>;
  /** fetch data from the table: "FormDeck" */
  FormDeck: Array<FormDeck>;
  /** fetch data from the table: "FormDeckUsage" */
  FormDeckUsage: Array<FormDeckUsage>;
  /** fetch aggregated fields from the table: "FormDeckUsage" */
  FormDeckUsage_aggregate: FormDeckUsage_Aggregate;
  /** fetch data from the table: "FormDeckUsage" using primary key columns */
  FormDeckUsage_by_pk?: Maybe<FormDeckUsage>;
  /** fetch aggregated fields from the table: "FormDeck" */
  FormDeck_aggregate: FormDeck_Aggregate;
  /** fetch data from the table: "FormDeck" using primary key columns */
  FormDeck_by_pk?: Maybe<FormDeck>;
  /** fetch data from the table: "Gutter" */
  Gutter: Array<Gutter>;
  /** fetch aggregated fields from the table: "Gutter" */
  Gutter_aggregate: Gutter_Aggregate;
  /** fetch data from the table: "Gutter" using primary key columns */
  Gutter_by_pk?: Maybe<Gutter>;
  /** fetch data from the table: "GypsumDeck" */
  GypsumDeck: Array<GypsumDeck>;
  /** fetch data from the table: "GypsumDeckUsage" */
  GypsumDeckUsage: Array<GypsumDeckUsage>;
  /** fetch aggregated fields from the table: "GypsumDeckUsage" */
  GypsumDeckUsage_aggregate: GypsumDeckUsage_Aggregate;
  /** fetch data from the table: "GypsumDeckUsage" using primary key columns */
  GypsumDeckUsage_by_pk?: Maybe<GypsumDeckUsage>;
  /** fetch aggregated fields from the table: "GypsumDeck" */
  GypsumDeck_aggregate: GypsumDeck_Aggregate;
  /** fetch data from the table: "GypsumDeck" using primary key columns */
  GypsumDeck_by_pk?: Maybe<GypsumDeck>;
  /** fetch data from the table: "InsBoardSize" */
  InsBoardSize: Array<InsBoardSize>;
  /** fetch aggregated fields from the table: "InsBoardSize" */
  InsBoardSize_aggregate: InsBoardSize_Aggregate;
  /** fetch data from the table: "InsBoardSize" using primary key columns */
  InsBoardSize_by_pk?: Maybe<InsBoardSize>;
  /** fetch data from the table: "ItemWorkspace" */
  ItemWorkspace: Array<ItemWorkspace>;
  /** An array relationship */
  ItemWorkspaceData: Array<ItemWorkspaceData>;
  /** An aggregate relationship */
  ItemWorkspaceData_aggregate: ItemWorkspaceData_Aggregate;
  /** fetch data from the table: "ItemWorkspaceData" using primary key columns */
  ItemWorkspaceData_by_pk?: Maybe<ItemWorkspaceData>;
  /** fetch aggregated fields from the table: "ItemWorkspace" */
  ItemWorkspace_aggregate: ItemWorkspace_Aggregate;
  /** fetch data from the table: "ItemWorkspace" using primary key columns */
  ItemWorkspace_by_pk?: Maybe<ItemWorkspace>;
  /** fetch data from the table: "LapSealant" */
  LapSealant: Array<LapSealant>;
  /** fetch data from the table: "LapSealantUsage" */
  LapSealantUsage: Array<LapSealantUsage>;
  /** fetch aggregated fields from the table: "LapSealantUsage" */
  LapSealantUsage_aggregate: LapSealantUsage_Aggregate;
  /** fetch data from the table: "LapSealantUsage" using primary key columns */
  LapSealantUsage_by_pk?: Maybe<LapSealantUsage>;
  /** fetch aggregated fields from the table: "LapSealant" */
  LapSealant_aggregate: LapSealant_Aggregate;
  /** fetch data from the table: "LapSealant" using primary key columns */
  LapSealant_by_pk?: Maybe<LapSealant>;
  /** fetch data from the table: "LapSecurementInfo" */
  LapSecurementInfo: Array<LapSecurementInfo>;
  /** fetch aggregated fields from the table: "LapSecurementInfo" */
  LapSecurementInfo_aggregate: LapSecurementInfo_Aggregate;
  /** fetch data from the table: "LapSecurementInfo" using primary key columns */
  LapSecurementInfo_by_pk?: Maybe<LapSecurementInfo>;
  /** fetch data from the table: "Layer" */
  Layer: Array<Layer>;
  /** fetch data from the table: "LayerConfig" */
  LayerConfig: Array<LayerConfig>;
  /** fetch aggregated fields from the table: "LayerConfig" */
  LayerConfig_aggregate: LayerConfig_Aggregate;
  /** fetch data from the table: "LayerConfig" using primary key columns */
  LayerConfig_by_pk?: Maybe<LayerConfig>;
  /** fetch aggregated fields from the table: "Layer" */
  Layer_aggregate: Layer_Aggregate;
  /** fetch data from the table: "Layer" using primary key columns */
  Layer_by_pk?: Maybe<Layer>;
  /** fetch data from the table: "LightweightInsulatingConcrete" */
  LightweightInsulatingConcrete: Array<LightweightInsulatingConcrete>;
  /** fetch data from the table: "LightweightInsulatingConcreteSubassemblyUsage" */
  LightweightInsulatingConcreteSubassemblyUsage: Array<LightweightInsulatingConcreteSubassemblyUsage>;
  /** fetch aggregated fields from the table: "LightweightInsulatingConcreteSubassemblyUsage" */
  LightweightInsulatingConcreteSubassemblyUsage_aggregate: LightweightInsulatingConcreteSubassemblyUsage_Aggregate;
  /** fetch data from the table: "LightweightInsulatingConcreteSubassemblyUsage" using primary key columns */
  LightweightInsulatingConcreteSubassemblyUsage_by_pk?: Maybe<LightweightInsulatingConcreteSubassemblyUsage>;
  /** fetch data from the table: "LightweightInsulatingConcreteUsage" */
  LightweightInsulatingConcreteUsage: Array<LightweightInsulatingConcreteUsage>;
  /** fetch aggregated fields from the table: "LightweightInsulatingConcreteUsage" */
  LightweightInsulatingConcreteUsage_aggregate: LightweightInsulatingConcreteUsage_Aggregate;
  /** fetch data from the table: "LightweightInsulatingConcreteUsage" using primary key columns */
  LightweightInsulatingConcreteUsage_by_pk?: Maybe<LightweightInsulatingConcreteUsage>;
  /** fetch aggregated fields from the table: "LightweightInsulatingConcrete" */
  LightweightInsulatingConcrete_aggregate: LightweightInsulatingConcrete_Aggregate;
  /** fetch data from the table: "LightweightInsulatingConcrete" using primary key columns */
  LightweightInsulatingConcrete_by_pk?: Maybe<LightweightInsulatingConcrete>;
  /** fetch data from the table: "LinerPanel" */
  LinerPanel: Array<LinerPanel>;
  /** fetch data from the table: "LinerPanelUsage" */
  LinerPanelUsage: Array<LinerPanelUsage>;
  /** fetch aggregated fields from the table: "LinerPanelUsage" */
  LinerPanelUsage_aggregate: LinerPanelUsage_Aggregate;
  /** fetch data from the table: "LinerPanelUsage" using primary key columns */
  LinerPanelUsage_by_pk?: Maybe<LinerPanelUsage>;
  /** fetch aggregated fields from the table: "LinerPanel" */
  LinerPanel_aggregate: LinerPanel_Aggregate;
  /** fetch data from the table: "LinerPanel" using primary key columns */
  LinerPanel_by_pk?: Maybe<LinerPanel>;
  /** fetch data from the table: "LiquidAppliedSubassemblyUsage" */
  LiquidAppliedSubassemblyUsage: Array<LiquidAppliedSubassemblyUsage>;
  /** fetch aggregated fields from the table: "LiquidAppliedSubassemblyUsage" */
  LiquidAppliedSubassemblyUsage_aggregate: LiquidAppliedSubassemblyUsage_Aggregate;
  /** fetch data from the table: "LiquidAppliedSubassemblyUsage" using primary key columns */
  LiquidAppliedSubassemblyUsage_by_pk?: Maybe<LiquidAppliedSubassemblyUsage>;
  /** fetch data from the table: "LiquidAppliedUsage" */
  LiquidAppliedUsage: Array<LiquidAppliedUsage>;
  /** fetch aggregated fields from the table: "LiquidAppliedUsage" */
  LiquidAppliedUsage_aggregate: LiquidAppliedUsage_Aggregate;
  /** fetch data from the table: "LiquidAppliedUsage" using primary key columns */
  LiquidAppliedUsage_by_pk?: Maybe<LiquidAppliedUsage>;
  /** fetch data from the table: "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
  Load_SearchLayerUsage_DeckFiberReinforcedPlastic: Array<Load_SearchLayerUsage_DeckFiberReinforcedPlastic>;
  /** fetch aggregated fields from the table: "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
  Load_SearchLayerUsage_DeckFiberReinforcedPlastic_aggregate: Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Aggregate;
  /** fetch data from the table: "MD_ApprovedUse" */
  MD_ApprovedUse: Array<Md_ApprovedUse>;
  /** An array relationship */
  MD_ApprovedUseAttributes: Array<Md_ApprovedUseAttributes>;
  /** An aggregate relationship */
  MD_ApprovedUseAttributes_aggregate: Md_ApprovedUseAttributes_Aggregate;
  /** fetch data from the table: "MD_ApprovedUseAttributes" using primary key columns */
  MD_ApprovedUseAttributes_by_pk?: Maybe<Md_ApprovedUseAttributes>;
  /** fetch aggregated fields from the table: "MD_ApprovedUse" */
  MD_ApprovedUse_aggregate: Md_ApprovedUse_Aggregate;
  /** fetch data from the table: "MD_ApprovedUse" using primary key columns */
  MD_ApprovedUse_by_pk?: Maybe<Md_ApprovedUse>;
  /** fetch data from the table: "MD_AttributeBase" */
  MD_AttributeBase: Array<Md_AttributeBase>;
  /** fetch aggregated fields from the table: "MD_AttributeBase" */
  MD_AttributeBase_aggregate: Md_AttributeBase_Aggregate;
  /** fetch data from the table: "MD_AttributeBase" using primary key columns */
  MD_AttributeBase_by_pk?: Maybe<Md_AttributeBase>;
  /** fetch data from the table: "MD_BooleanAttribute" */
  MD_BooleanAttribute: Array<Md_BooleanAttribute>;
  /** fetch aggregated fields from the table: "MD_BooleanAttribute" */
  MD_BooleanAttribute_aggregate: Md_BooleanAttribute_Aggregate;
  /** fetch data from the table: "MD_BooleanAttribute" using primary key columns */
  MD_BooleanAttribute_by_pk?: Maybe<Md_BooleanAttribute>;
  /** fetch data from the table: "MD_BooleanDisplayOption" */
  MD_BooleanDisplayOption: Array<Md_BooleanDisplayOption>;
  /** fetch aggregated fields from the table: "MD_BooleanDisplayOption" */
  MD_BooleanDisplayOption_aggregate: Md_BooleanDisplayOption_Aggregate;
  /** fetch data from the table: "MD_BooleanDisplayOption" using primary key columns */
  MD_BooleanDisplayOption_by_pk?: Maybe<Md_BooleanDisplayOption>;
  /** fetch data from the table: "MD_Catalog" */
  MD_Catalog: Array<Md_Catalog>;
  /** fetch aggregated fields from the table: "MD_Catalog" */
  MD_Catalog_aggregate: Md_Catalog_Aggregate;
  /** fetch data from the table: "MD_Catalog" using primary key columns */
  MD_Catalog_by_pk?: Maybe<Md_Catalog>;
  /** fetch data from the table: "MD_CodeTableAttribute" */
  MD_CodeTableAttribute: Array<Md_CodeTableAttribute>;
  /** fetch aggregated fields from the table: "MD_CodeTableAttribute" */
  MD_CodeTableAttribute_aggregate: Md_CodeTableAttribute_Aggregate;
  /** fetch data from the table: "MD_CodeTableAttribute" using primary key columns */
  MD_CodeTableAttribute_by_pk?: Maybe<Md_CodeTableAttribute>;
  /** fetch data from the table: "MD_CodeTables" */
  MD_CodeTables: Array<Md_CodeTables>;
  /** fetch aggregated fields from the table: "MD_CodeTables" */
  MD_CodeTables_aggregate: Md_CodeTables_Aggregate;
  /** fetch data from the table: "MD_CodeTables" using primary key columns */
  MD_CodeTables_by_pk?: Maybe<Md_CodeTables>;
  /** fetch data from the table: "MD_ComponentUsage" */
  MD_ComponentUsage: Array<Md_ComponentUsage>;
  /** An array relationship */
  MD_ComponentUsageAttributes: Array<Md_ComponentUsageAttributes>;
  /** An aggregate relationship */
  MD_ComponentUsageAttributes_aggregate: Md_ComponentUsageAttributes_Aggregate;
  /** fetch data from the table: "MD_ComponentUsageAttributes" using primary key columns */
  MD_ComponentUsageAttributes_by_pk?: Maybe<Md_ComponentUsageAttributes>;
  /** fetch aggregated fields from the table: "MD_ComponentUsage" */
  MD_ComponentUsage_aggregate: Md_ComponentUsage_Aggregate;
  /** fetch data from the table: "MD_ComponentUsage" using primary key columns */
  MD_ComponentUsage_by_pk?: Maybe<Md_ComponentUsage>;
  /** fetch data from the table: "MD_Measure" */
  MD_Measure: Array<Md_Measure>;
  /** fetch aggregated fields from the table: "MD_Measure" */
  MD_Measure_aggregate: Md_Measure_Aggregate;
  /** fetch data from the table: "MD_Measure" using primary key columns */
  MD_Measure_by_pk?: Maybe<Md_Measure>;
  /** fetch data from the table: "MD_MeasurementAttribute" */
  MD_MeasurementAttribute: Array<Md_MeasurementAttribute>;
  /** fetch aggregated fields from the table: "MD_MeasurementAttribute" */
  MD_MeasurementAttribute_aggregate: Md_MeasurementAttribute_Aggregate;
  /** fetch data from the table: "MD_MeasurementAttribute" using primary key columns */
  MD_MeasurementAttribute_by_pk?: Maybe<Md_MeasurementAttribute>;
  /** fetch data from the table: "MD_MeasurementDisplayOption" */
  MD_MeasurementDisplayOption: Array<Md_MeasurementDisplayOption>;
  /** fetch aggregated fields from the table: "MD_MeasurementDisplayOption" */
  MD_MeasurementDisplayOption_aggregate: Md_MeasurementDisplayOption_Aggregate;
  /** fetch data from the table: "MD_MeasurementDisplayOption" using primary key columns */
  MD_MeasurementDisplayOption_by_pk?: Maybe<Md_MeasurementDisplayOption>;
  /** fetch data from the table: "MD_MultivalueAttribute" */
  MD_MultivalueAttribute: Array<Md_MultivalueAttribute>;
  /** fetch aggregated fields from the table: "MD_MultivalueAttribute" */
  MD_MultivalueAttribute_aggregate: Md_MultivalueAttribute_Aggregate;
  /** fetch data from the table: "MD_MultivalueAttribute" using primary key columns */
  MD_MultivalueAttribute_by_pk?: Maybe<Md_MultivalueAttribute>;
  /** fetch data from the table: "MD_MultivalueDisplayOption" */
  MD_MultivalueDisplayOption: Array<Md_MultivalueDisplayOption>;
  /** fetch aggregated fields from the table: "MD_MultivalueDisplayOption" */
  MD_MultivalueDisplayOption_aggregate: Md_MultivalueDisplayOption_Aggregate;
  /** fetch data from the table: "MD_MultivalueDisplayOption" using primary key columns */
  MD_MultivalueDisplayOption_by_pk?: Maybe<Md_MultivalueDisplayOption>;
  /** fetch data from the table: "MD_ProductCategory" */
  MD_ProductCategory: Array<Md_ProductCategory>;
  /** fetch aggregated fields from the table: "MD_ProductCategory" */
  MD_ProductCategory_aggregate: Md_ProductCategory_Aggregate;
  /** fetch data from the table: "MD_ProductCategory" using primary key columns */
  MD_ProductCategory_by_pk?: Maybe<Md_ProductCategory>;
  /** fetch data from the table: "MD_ProductSubcategory" */
  MD_ProductSubcategory: Array<Md_ProductSubcategory>;
  /** fetch aggregated fields from the table: "MD_ProductSubcategory" */
  MD_ProductSubcategory_aggregate: Md_ProductSubcategory_Aggregate;
  /** fetch data from the table: "MD_ProductSubcategory" using primary key columns */
  MD_ProductSubcategory_by_pk?: Maybe<Md_ProductSubcategory>;
  /** fetch data from the table: "MaintenanceCoating" */
  MaintenanceCoating: Array<MaintenanceCoating>;
  /** fetch data from the table: "MaintenanceCoatingSubstrate" */
  MaintenanceCoatingSubstrate: Array<MaintenanceCoatingSubstrate>;
  /** fetch aggregated fields from the table: "MaintenanceCoatingSubstrate" */
  MaintenanceCoatingSubstrate_aggregate: MaintenanceCoatingSubstrate_Aggregate;
  /** fetch data from the table: "MaintenanceCoatingSubstrate" using primary key columns */
  MaintenanceCoatingSubstrate_by_pk?: Maybe<MaintenanceCoatingSubstrate>;
  /** fetch data from the table: "MaintenanceCoatingUsage" */
  MaintenanceCoatingUsage: Array<MaintenanceCoatingUsage>;
  /** fetch aggregated fields from the table: "MaintenanceCoatingUsage" */
  MaintenanceCoatingUsage_aggregate: MaintenanceCoatingUsage_Aggregate;
  /** fetch data from the table: "MaintenanceCoatingUsage" using primary key columns */
  MaintenanceCoatingUsage_by_pk?: Maybe<MaintenanceCoatingUsage>;
  /** fetch aggregated fields from the table: "MaintenanceCoating" */
  MaintenanceCoating_aggregate: MaintenanceCoating_Aggregate;
  /** fetch data from the table: "MaintenanceCoating" using primary key columns */
  MaintenanceCoating_by_pk?: Maybe<MaintenanceCoating>;
  /** fetch data from the table: "ManufacturedProduct" */
  ManufacturedProduct: Array<ManufacturedProduct>;
  /** fetch aggregated fields from the table: "ManufacturedProduct" */
  ManufacturedProduct_aggregate: ManufacturedProduct_Aggregate;
  /** fetch data from the table: "ManufacturedProduct" using primary key columns */
  ManufacturedProduct_by_pk?: Maybe<ManufacturedProduct>;
  /** fetch data from the table: "Manufacturer" */
  Manufacturer: Array<Manufacturer>;
  /** fetch data from the table: "ManufacturerChangeHistory" */
  ManufacturerChangeHistory: Array<ManufacturerChangeHistory>;
  /** fetch aggregated fields from the table: "ManufacturerChangeHistory" */
  ManufacturerChangeHistory_aggregate: ManufacturerChangeHistory_Aggregate;
  /** fetch data from the table: "ManufacturerChangeHistory" using primary key columns */
  ManufacturerChangeHistory_by_pk?: Maybe<ManufacturerChangeHistory>;
  /** fetch data from the table: "ManufacturerList" */
  ManufacturerList: Array<ManufacturerList>;
  /** fetch aggregated fields from the table: "ManufacturerList" */
  ManufacturerList_aggregate: ManufacturerList_Aggregate;
  /** fetch aggregated fields from the table: "Manufacturer" */
  Manufacturer_aggregate: Manufacturer_Aggregate;
  /** fetch data from the table: "Manufacturer" using primary key columns */
  Manufacturer_by_pk?: Maybe<Manufacturer>;
  /** fetch data from the table: "MultiplyCoverConfig" */
  MultiplyCoverConfig: Array<MultiplyCoverConfig>;
  /** fetch aggregated fields from the table: "MultiplyCoverConfig" */
  MultiplyCoverConfig_aggregate: MultiplyCoverConfig_Aggregate;
  /** fetch data from the table: "MultiplyCoverConfig" using primary key columns */
  MultiplyCoverConfig_by_pk?: Maybe<MultiplyCoverConfig>;
  /** fetch data from the table: "MultiplyCoverSubassemblyUsage" */
  MultiplyCoverSubassemblyUsage: Array<MultiplyCoverSubassemblyUsage>;
  /** fetch aggregated fields from the table: "MultiplyCoverSubassemblyUsage" */
  MultiplyCoverSubassemblyUsage_aggregate: MultiplyCoverSubassemblyUsage_Aggregate;
  /** fetch data from the table: "MultiplyCoverSubassemblyUsage" using primary key columns */
  MultiplyCoverSubassemblyUsage_by_pk?: Maybe<MultiplyCoverSubassemblyUsage>;
  /** fetch data from the table: "NavAssembly" */
  NavAssembly: Array<NavAssembly>;
  /** fetch data from the table: "NavAssemblyMultiplyInfo" */
  NavAssemblyMultiplyInfo: Array<NavAssemblyMultiplyInfo>;
  /** fetch aggregated fields from the table: "NavAssemblyMultiplyInfo" */
  NavAssemblyMultiplyInfo_aggregate: NavAssemblyMultiplyInfo_Aggregate;
  /** fetch aggregated fields from the table: "NavAssembly" */
  NavAssembly_aggregate: NavAssembly_Aggregate;
  /** fetch data from the table: "NavAssembly" using primary key columns */
  NavAssembly_by_pk?: Maybe<NavAssembly>;
  /** fetch data from the table: "NavLayer" */
  NavLayer: Array<NavLayer>;
  /** fetch aggregated fields from the table: "NavLayer" */
  NavLayer_aggregate: NavLayer_Aggregate;
  /** fetch data from the table: "NavLayer" using primary key columns */
  NavLayer_by_pk?: Maybe<NavLayer>;
  /** fetch data from the table: "NavMultiplyCoverConfig" */
  NavMultiplyCoverConfig: Array<NavMultiplyCoverConfig>;
  /** fetch aggregated fields from the table: "NavMultiplyCoverConfig" */
  NavMultiplyCoverConfig_aggregate: NavMultiplyCoverConfig_Aggregate;
  /** fetch data from the table: "NavMultiplyCoverConfig" using primary key columns */
  NavMultiplyCoverConfig_by_pk?: Maybe<NavMultiplyCoverConfig>;
  /** fetch data from the table: "NavSecurementLayer" */
  NavSecurementLayer: Array<NavSecurementLayer>;
  /** fetch aggregated fields from the table: "NavSecurementLayer" */
  NavSecurementLayer_aggregate: NavSecurementLayer_Aggregate;
  /** fetch data from the table: "NavSecurementLayer" using primary key columns */
  NavSecurementLayer_by_pk?: Maybe<NavSecurementLayer>;
  /** fetch data from the table: "NavSecurementUsage" */
  NavSecurementUsage: Array<NavSecurementUsage>;
  /** fetch aggregated fields from the table: "NavSecurementUsage" */
  NavSecurementUsage_aggregate: NavSecurementUsage_Aggregate;
  /** fetch data from the table: "NavSecurementUsage" using primary key columns */
  NavSecurementUsage_by_pk?: Maybe<NavSecurementUsage>;
  /** fetch data from the table: "NavUsage" */
  NavUsage: Array<NavUsage>;
  /** fetch data from the table: "NavUsageSA" */
  NavUsageSA: Array<NavUsageSa>;
  /** fetch aggregated fields from the table: "NavUsageSA" */
  NavUsageSA_aggregate: NavUsageSa_Aggregate;
  /** fetch data from the table: "NavUsageSA" using primary key columns */
  NavUsageSA_by_pk?: Maybe<NavUsageSa>;
  /** fetch aggregated fields from the table: "NavUsage" */
  NavUsage_aggregate: NavUsage_Aggregate;
  /** fetch data from the table: "NavUsage" using primary key columns */
  NavUsage_by_pk?: Maybe<NavUsage>;
  /** An array relationship */
  Nav_SinglePlyBondingAdhesives: Array<Nav_SinglePlyBondingAdhesives>;
  /** An aggregate relationship */
  Nav_SinglePlyBondingAdhesives_aggregate: Nav_SinglePlyBondingAdhesives_Aggregate;
  /** An array relationship */
  Nav_SinglePlyLapAdhesives: Array<Nav_SinglePlyLapAdhesives>;
  /** An aggregate relationship */
  Nav_SinglePlyLapAdhesives_aggregate: Nav_SinglePlyLapAdhesives_Aggregate;
  /** fetch data from the table: "NextAssemblyNum" */
  NextAssemblyNum: Array<NextAssemblyNum>;
  /** fetch aggregated fields from the table: "NextAssemblyNum" */
  NextAssemblyNum_aggregate: NextAssemblyNum_Aggregate;
  /** fetch data from the table: "NextAssemblyNum" using primary key columns */
  NextAssemblyNum_by_pk?: Maybe<NextAssemblyNum>;
  /** fetch data from the table: "Note" */
  Note: Array<Note>;
  /** fetch aggregated fields from the table: "Note" */
  Note_aggregate: Note_Aggregate;
  /** fetch data from the table: "Note" using primary key columns */
  Note_by_pk?: Maybe<Note>;
  /** fetch data from the table: "OtherPly" */
  OtherPly: Array<OtherPly>;
  /** fetch data from the table: "OtherPlyUsage" */
  OtherPlyUsage: Array<OtherPlyUsage>;
  /** fetch aggregated fields from the table: "OtherPlyUsage" */
  OtherPlyUsage_aggregate: OtherPlyUsage_Aggregate;
  /** fetch data from the table: "OtherPlyUsage" using primary key columns */
  OtherPlyUsage_by_pk?: Maybe<OtherPlyUsage>;
  /** fetch aggregated fields from the table: "OtherPly" */
  OtherPly_aggregate: OtherPly_Aggregate;
  /** fetch data from the table: "OtherPly" using primary key columns */
  OtherPly_by_pk?: Maybe<OtherPly>;
  /** fetch data from the table: "PreassembledFastening" */
  PreassembledFastening: Array<PreassembledFastening>;
  /** fetch data from the table: "PreassembledFasteningUsage" */
  PreassembledFasteningUsage: Array<PreassembledFasteningUsage>;
  /** fetch aggregated fields from the table: "PreassembledFasteningUsage" */
  PreassembledFasteningUsage_aggregate: PreassembledFasteningUsage_Aggregate;
  /** fetch data from the table: "PreassembledFasteningUsage" using primary key columns */
  PreassembledFasteningUsage_by_pk?: Maybe<PreassembledFasteningUsage>;
  /** fetch aggregated fields from the table: "PreassembledFastening" */
  PreassembledFastening_aggregate: PreassembledFastening_Aggregate;
  /** fetch data from the table: "PreassembledFastening" using primary key columns */
  PreassembledFastening_by_pk?: Maybe<PreassembledFastening>;
  /** fetch data from the table: "PrivateLabel" */
  PrivateLabel: Array<PrivateLabel>;
  /** fetch aggregated fields from the table: "PrivateLabel" */
  PrivateLabel_aggregate: PrivateLabel_Aggregate;
  /** fetch data from the table: "PrivateLabel" using primary key columns */
  PrivateLabel_by_pk?: Maybe<PrivateLabel>;
  /** fetch data from the table: "PrivateLabeler" */
  PrivateLabeler: Array<PrivateLabeler>;
  /** fetch aggregated fields from the table: "PrivateLabeler" */
  PrivateLabeler_aggregate: PrivateLabeler_Aggregate;
  /** An array relationship */
  ProductDependencies: Array<ProductDependencies>;
  /** An aggregate relationship */
  ProductDependencies_aggregate: ProductDependencies_Aggregate;
  /** fetch data from the table: "ProductDependencies" using primary key columns */
  ProductDependencies_by_pk?: Maybe<ProductDependencies>;
  /** fetch data from the table: "ProductsBySubcategory" */
  ProductsBySubcategory: Array<ProductsBySubcategory>;
  /** fetch aggregated fields from the table: "ProductsBySubcategory" */
  ProductsBySubcategory_aggregate: ProductsBySubcategory_Aggregate;
  /** fetch data from the table: "R_AdhesiveTapeWidth" */
  R_AdhesiveTapeWidth: Array<R_AdhesiveTapeWidth>;
  /** fetch aggregated fields from the table: "R_AdhesiveTapeWidth" */
  R_AdhesiveTapeWidth_aggregate: R_AdhesiveTapeWidth_Aggregate;
  /** fetch data from the table: "R_AdhesiveTapeWidth" using primary key columns */
  R_AdhesiveTapeWidth_by_pk?: Maybe<R_AdhesiveTapeWidth>;
  /** fetch data from the table: "R_ApprovedUse_Subcategory" */
  R_ApprovedUse_Subcategory: Array<R_ApprovedUse_Subcategory>;
  /** fetch aggregated fields from the table: "R_ApprovedUse_Subcategory" */
  R_ApprovedUse_Subcategory_aggregate: R_ApprovedUse_Subcategory_Aggregate;
  /** fetch data from the table: "R_ApprovedUse_Subcategory" using primary key columns */
  R_ApprovedUse_Subcategory_by_pk?: Maybe<R_ApprovedUse_Subcategory>;
  /** fetch data from the table: "R_ComponentType_ApprovedUse" */
  R_ComponentType_ApprovedUse: Array<R_ComponentType_ApprovedUse>;
  /** fetch aggregated fields from the table: "R_ComponentType_ApprovedUse" */
  R_ComponentType_ApprovedUse_aggregate: R_ComponentType_ApprovedUse_Aggregate;
  /** fetch data from the table: "R_ComponentType_ApprovedUse" using primary key columns */
  R_ComponentType_ApprovedUse_by_pk?: Maybe<R_ComponentType_ApprovedUse>;
  /** fetch data from the table: "R_CopingFastener" */
  R_CopingFastener: Array<R_CopingFastener>;
  /** fetch aggregated fields from the table: "R_CopingFastener" */
  R_CopingFastener_aggregate: R_CopingFastener_Aggregate;
  /** fetch data from the table: "R_CopingFastener" using primary key columns */
  R_CopingFastener_by_pk?: Maybe<R_CopingFastener>;
  /** fetch data from the table: "R_Country_StateProvince" */
  R_Country_StateProvince: Array<R_Country_StateProvince>;
  /** fetch aggregated fields from the table: "R_Country_StateProvince" */
  R_Country_StateProvince_aggregate: R_Country_StateProvince_Aggregate;
  /** fetch data from the table: "R_Country_StateProvince" using primary key columns */
  R_Country_StateProvince_by_pk?: Maybe<R_Country_StateProvince>;
  /** fetch data from the table: "R_ExpansionJoinFastener" */
  R_ExpansionJoinFastener: Array<R_ExpansionJoinFastener>;
  /** fetch aggregated fields from the table: "R_ExpansionJoinFastener" */
  R_ExpansionJoinFastener_aggregate: R_ExpansionJoinFastener_Aggregate;
  /** fetch data from the table: "R_ExpansionJoinFastener" using primary key columns */
  R_ExpansionJoinFastener_by_pk?: Maybe<R_ExpansionJoinFastener>;
  /** fetch data from the table: "R_FasciaComponentFastenerComponent" */
  R_FasciaComponentFastenerComponent: Array<R_FasciaComponentFastenerComponent>;
  /** fetch aggregated fields from the table: "R_FasciaComponentFastenerComponent" */
  R_FasciaComponentFastenerComponent_aggregate: R_FasciaComponentFastenerComponent_Aggregate;
  /** fetch data from the table: "R_FasciaComponentFastenerComponent" using primary key columns */
  R_FasciaComponentFastenerComponent_by_pk?: Maybe<R_FasciaComponentFastenerComponent>;
  /** An array relationship */
  R_GutterFasteners: Array<R_GutterFasteners>;
  /** An aggregate relationship */
  R_GutterFasteners_aggregate: R_GutterFasteners_Aggregate;
  /** fetch data from the table: "R_GutterFasteners" using primary key columns */
  R_GutterFasteners_by_pk?: Maybe<R_GutterFasteners>;
  /** fetch data from the table: "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
  R_LinerPanelMaterialFiberReinforcedPlasticDeck: Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck>;
  /** fetch aggregated fields from the table: "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
  R_LinerPanelMaterialFiberReinforcedPlasticDeck_aggregate: R_LinerPanelMaterialFiberReinforcedPlasticDeck_Aggregate;
  /** fetch data from the table: "R_LinerPanelMaterialFiberReinforcedPlasticDeck" using primary key columns */
  R_LinerPanelMaterialFiberReinforcedPlasticDeck_by_pk?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck>;
  /** fetch data from the table: "R_MaintenanceCoatingSubstrate" */
  R_MaintenanceCoatingSubstrate: Array<R_MaintenanceCoatingSubstrate>;
  /** fetch aggregated fields from the table: "R_MaintenanceCoatingSubstrate" */
  R_MaintenanceCoatingSubstrate_aggregate: R_MaintenanceCoatingSubstrate_Aggregate;
  /** fetch data from the table: "R_MaintenanceCoatingSubstrate" using primary key columns */
  R_MaintenanceCoatingSubstrate_by_pk?: Maybe<R_MaintenanceCoatingSubstrate>;
  /** fetch data from the table: "R_NavAssembly_NavMultiplyCoverConfig" */
  R_NavAssembly_NavMultiplyCoverConfig: Array<R_NavAssembly_NavMultiplyCoverConfig>;
  /** fetch aggregated fields from the table: "R_NavAssembly_NavMultiplyCoverConfig" */
  R_NavAssembly_NavMultiplyCoverConfig_aggregate: R_NavAssembly_NavMultiplyCoverConfig_Aggregate;
  /** fetch data from the table: "R_NavAssembly_NavMultiplyCoverConfig" using primary key columns */
  R_NavAssembly_NavMultiplyCoverConfig_by_pk?: Maybe<R_NavAssembly_NavMultiplyCoverConfig>;
  /** fetch data from the table: "R_ProjectContact" */
  R_ProjectContact: Array<R_ProjectContact>;
  /** fetch aggregated fields from the table: "R_ProjectContact" */
  R_ProjectContact_aggregate: R_ProjectContact_Aggregate;
  /** fetch data from the table: "R_ProjectContact" using primary key columns */
  R_ProjectContact_by_pk?: Maybe<R_ProjectContact>;
  /** fetch data from the table: "R_ProjectNote" */
  R_ProjectNote: Array<R_ProjectNote>;
  /** fetch aggregated fields from the table: "R_ProjectNote" */
  R_ProjectNote_aggregate: R_ProjectNote_Aggregate;
  /** fetch data from the table: "R_ProjectNote" using primary key columns */
  R_ProjectNote_by_pk?: Maybe<R_ProjectNote>;
  /** fetch data from the table: "R_RegletFastener" */
  R_RegletFastener: Array<R_RegletFastener>;
  /** fetch aggregated fields from the table: "R_RegletFastener" */
  R_RegletFastener_aggregate: R_RegletFastener_Aggregate;
  /** fetch data from the table: "R_RegletFastener" using primary key columns */
  R_RegletFastener_by_pk?: Maybe<R_RegletFastener>;
  /** fetch data from the table: "R_RoofAreaContact" */
  R_RoofAreaContact: Array<R_RoofAreaContact>;
  /** fetch aggregated fields from the table: "R_RoofAreaContact" */
  R_RoofAreaContact_aggregate: R_RoofAreaContact_Aggregate;
  /** fetch data from the table: "R_RoofAreaContact" using primary key columns */
  R_RoofAreaContact_by_pk?: Maybe<R_RoofAreaContact>;
  /** fetch data from the table: "R_RoofAreaNote" */
  R_RoofAreaNote: Array<R_RoofAreaNote>;
  /** fetch aggregated fields from the table: "R_RoofAreaNote" */
  R_RoofAreaNote_aggregate: R_RoofAreaNote_Aggregate;
  /** fetch data from the table: "R_RoofAreaNote" using primary key columns */
  R_RoofAreaNote_by_pk?: Maybe<R_RoofAreaNote>;
  /** fetch data from the table: "R_RoofAreaScenario" */
  R_RoofAreaScenario: Array<R_RoofAreaScenario>;
  /** fetch aggregated fields from the table: "R_RoofAreaScenario" */
  R_RoofAreaScenario_aggregate: R_RoofAreaScenario_Aggregate;
  /** fetch data from the table: "R_RoofAreaScenario" using primary key columns */
  R_RoofAreaScenario_by_pk?: Maybe<R_RoofAreaScenario>;
  /** fetch data from the table: "R_ScenarioNote" */
  R_ScenarioNote: Array<R_ScenarioNote>;
  /** fetch aggregated fields from the table: "R_ScenarioNote" */
  R_ScenarioNote_aggregate: R_ScenarioNote_Aggregate;
  /** fetch data from the table: "R_ScenarioNote" using primary key columns */
  R_ScenarioNote_by_pk?: Maybe<R_ScenarioNote>;
  /** An array relationship */
  R_SinglePly_BondingAdhesives: Array<R_SinglePly_BondingAdhesives>;
  /** An aggregate relationship */
  R_SinglePly_BondingAdhesives_aggregate: R_SinglePly_BondingAdhesives_Aggregate;
  /** fetch data from the table: "R_SinglePly_BondingAdhesives" using primary key columns */
  R_SinglePly_BondingAdhesives_by_pk?: Maybe<R_SinglePly_BondingAdhesives>;
  /** An array relationship */
  R_SinglePly_LapAdhesives: Array<R_SinglePly_LapAdhesives>;
  /** An aggregate relationship */
  R_SinglePly_LapAdhesives_aggregate: R_SinglePly_LapAdhesives_Aggregate;
  /** fetch data from the table: "R_SinglePly_LapAdhesives" using primary key columns */
  R_SinglePly_LapAdhesives_by_pk?: Maybe<R_SinglePly_LapAdhesives>;
  /** fetch data from the table: "R_Subassembly_MultiplyCoverConfig" */
  R_Subassembly_MultiplyCoverConfig: Array<R_Subassembly_MultiplyCoverConfig>;
  /** fetch aggregated fields from the table: "R_Subassembly_MultiplyCoverConfig" */
  R_Subassembly_MultiplyCoverConfig_aggregate: R_Subassembly_MultiplyCoverConfig_Aggregate;
  /** fetch data from the table: "R_Subassembly_MultiplyCoverConfig" using primary key columns */
  R_Subassembly_MultiplyCoverConfig_by_pk?: Maybe<R_Subassembly_MultiplyCoverConfig>;
  /** fetch data from the table: "R_UserContact" */
  R_UserContact: Array<R_UserContact>;
  /** fetch aggregated fields from the table: "R_UserContact" */
  R_UserContact_aggregate: R_UserContact_Aggregate;
  /** fetch data from the table: "R_UserContact" using primary key columns */
  R_UserContact_by_pk?: Maybe<R_UserContact>;
  /** fetch data from the table: "Reglet" */
  Reglet: Array<Reglet>;
  /** fetch aggregated fields from the table: "Reglet" */
  Reglet_aggregate: Reglet_Aggregate;
  /** fetch data from the table: "Reglet" using primary key columns */
  Reglet_by_pk?: Maybe<Reglet>;
  /** fetch data from the table: "ReviewerWorkspace" */
  ReviewerWorkspace: Array<ReviewerWorkspace>;
  /** An array relationship */
  ReviewerWorkspaceData: Array<ReviewerWorkspaceData>;
  /** An aggregate relationship */
  ReviewerWorkspaceData_aggregate: ReviewerWorkspaceData_Aggregate;
  /** fetch data from the table: "ReviewerWorkspaceData" using primary key columns */
  ReviewerWorkspaceData_by_pk?: Maybe<ReviewerWorkspaceData>;
  /** fetch aggregated fields from the table: "ReviewerWorkspace" */
  ReviewerWorkspace_aggregate: ReviewerWorkspace_Aggregate;
  /** fetch data from the table: "ReviewerWorkspace" using primary key columns */
  ReviewerWorkspace_by_pk?: Maybe<ReviewerWorkspace>;
  /** fetch data from the table: "RoofArea" */
  RoofArea: Array<RoofArea>;
  /** fetch data from the table: "RoofAreaWindPressure" */
  RoofAreaWindPressure: Array<RoofAreaWindPressure>;
  /** fetch aggregated fields from the table: "RoofAreaWindPressure" */
  RoofAreaWindPressure_aggregate: RoofAreaWindPressure_Aggregate;
  /** fetch data from the table: "RoofAreaWindPressure" using primary key columns */
  RoofAreaWindPressure_by_pk?: Maybe<RoofAreaWindPressure>;
  /** fetch aggregated fields from the table: "RoofArea" */
  RoofArea_aggregate: RoofArea_Aggregate;
  /** fetch data from the table: "RoofArea" using primary key columns */
  RoofArea_by_pk?: Maybe<RoofArea>;
  /** fetch data from the table: "RoofAssembly" */
  RoofAssembly: Array<RoofAssembly>;
  /** fetch data from the table: "RoofAssemblyDelistData" */
  RoofAssemblyDelistData: Array<RoofAssemblyDelistData>;
  /** fetch aggregated fields from the table: "RoofAssemblyDelistData" */
  RoofAssemblyDelistData_aggregate: RoofAssemblyDelistData_Aggregate;
  /** An array relationship */
  RoofAssemblyLayers: Array<RoofAssemblyLayers>;
  /** An aggregate relationship */
  RoofAssemblyLayers_aggregate: RoofAssemblyLayers_Aggregate;
  /** fetch data from the table: "RoofAssemblyLayers" using primary key columns */
  RoofAssemblyLayers_by_pk?: Maybe<RoofAssemblyLayers>;
  /** fetch aggregated fields from the table: "RoofAssembly" */
  RoofAssembly_aggregate: RoofAssembly_Aggregate;
  /** fetch data from the table: "RoofAssembly" using primary key columns */
  RoofAssembly_by_pk?: Maybe<RoofAssembly>;
  /** fetch data from the table: "RoofProject" */
  RoofProject: Array<RoofProject>;
  /** fetch aggregated fields from the table: "RoofProject" */
  RoofProject_aggregate: RoofProject_Aggregate;
  /** fetch data from the table: "RoofProject" using primary key columns */
  RoofProject_by_pk?: Maybe<RoofProject>;
  /** fetch data from the table: "Scenario" */
  Scenario: Array<Scenario>;
  /** fetch data from the table: "ScenarioAlert" */
  ScenarioAlert: Array<ScenarioAlert>;
  /** fetch aggregated fields from the table: "ScenarioAlert" */
  ScenarioAlert_aggregate: ScenarioAlert_Aggregate;
  /** fetch data from the table: "ScenarioAlert" using primary key columns */
  ScenarioAlert_by_pk?: Maybe<ScenarioAlert>;
  /** fetch data from the table: "ScenarioLayer" */
  ScenarioLayer: Array<ScenarioLayer>;
  /** fetch aggregated fields from the table: "ScenarioLayer" */
  ScenarioLayer_aggregate: ScenarioLayer_Aggregate;
  /** fetch data from the table: "ScenarioLayer" using primary key columns */
  ScenarioLayer_by_pk?: Maybe<ScenarioLayer>;
  /** fetch data from the table: "ScenarioUsage" */
  ScenarioUsage: Array<ScenarioUsage>;
  /** fetch aggregated fields from the table: "ScenarioUsage" */
  ScenarioUsage_aggregate: ScenarioUsage_Aggregate;
  /** fetch data from the table: "ScenarioUsage" using primary key columns */
  ScenarioUsage_by_pk?: Maybe<ScenarioUsage>;
  /** fetch aggregated fields from the table: "Scenario" */
  Scenario_aggregate: Scenario_Aggregate;
  /** fetch data from the table: "Scenario" using primary key columns */
  Scenario_by_pk?: Maybe<Scenario>;
  /** fetch data from the table: "SeamPrimer" */
  SeamPrimer: Array<SeamPrimer>;
  /** fetch data from the table: "SeamPrimerUsage" */
  SeamPrimerUsage: Array<SeamPrimerUsage>;
  /** fetch aggregated fields from the table: "SeamPrimerUsage" */
  SeamPrimerUsage_aggregate: SeamPrimerUsage_Aggregate;
  /** fetch data from the table: "SeamPrimerUsage" using primary key columns */
  SeamPrimerUsage_by_pk?: Maybe<SeamPrimerUsage>;
  /** fetch aggregated fields from the table: "SeamPrimer" */
  SeamPrimer_aggregate: SeamPrimer_Aggregate;
  /** fetch data from the table: "SeamPrimer" using primary key columns */
  SeamPrimer_by_pk?: Maybe<SeamPrimer>;
  /** fetch data from the table: "SeamTapeSystemUsage" */
  SeamTapeSystemUsage: Array<SeamTapeSystemUsage>;
  /** fetch aggregated fields from the table: "SeamTapeSystemUsage" */
  SeamTapeSystemUsage_aggregate: SeamTapeSystemUsage_Aggregate;
  /** fetch data from the table: "SeamTapeSystemUsage" using primary key columns */
  SeamTapeSystemUsage_by_pk?: Maybe<SeamTapeSystemUsage>;
  /** fetch data from the table: "SeamWash" */
  SeamWash: Array<SeamWash>;
  /** fetch data from the table: "SeamWashUsage" */
  SeamWashUsage: Array<SeamWashUsage>;
  /** fetch aggregated fields from the table: "SeamWashUsage" */
  SeamWashUsage_aggregate: SeamWashUsage_Aggregate;
  /** fetch data from the table: "SeamWashUsage" using primary key columns */
  SeamWashUsage_by_pk?: Maybe<SeamWashUsage>;
  /** fetch aggregated fields from the table: "SeamWash" */
  SeamWash_aggregate: SeamWash_Aggregate;
  /** fetch data from the table: "SeamWash" using primary key columns */
  SeamWash_by_pk?: Maybe<SeamWash>;
  /** fetch data from the table: "SearchNavAssembly_ManufacturerProduct" */
  SearchNavAssembly_ManufacturerProduct: Array<SearchNavAssembly_ManufacturerProduct>;
  /** fetch aggregated fields from the table: "SearchNavAssembly_ManufacturerProduct" */
  SearchNavAssembly_ManufacturerProduct_aggregate: SearchNavAssembly_ManufacturerProduct_Aggregate;
  /** fetch data from the table: "SearchNavAssembly_ManufacturerProduct" using primary key columns */
  SearchNavAssembly_ManufacturerProduct_by_pk?: Maybe<SearchNavAssembly_ManufacturerProduct>;
  /** fetch data from the table: "SecurementLayer" */
  SecurementLayer: Array<SecurementLayer>;
  /** fetch data from the table: "SecurementLayerUsage" */
  SecurementLayerUsage: Array<SecurementLayerUsage>;
  /** fetch aggregated fields from the table: "SecurementLayerUsage" */
  SecurementLayerUsage_aggregate: SecurementLayerUsage_Aggregate;
  /** fetch data from the table: "SecurementLayerUsage" using primary key columns */
  SecurementLayerUsage_by_pk?: Maybe<SecurementLayerUsage>;
  /** fetch aggregated fields from the table: "SecurementLayer" */
  SecurementLayer_aggregate: SecurementLayer_Aggregate;
  /** fetch data from the table: "SecurementLayer" using primary key columns */
  SecurementLayer_by_pk?: Maybe<SecurementLayer>;
  /** fetch data from the table: "SelfSecurementUsage" */
  SelfSecurementUsage: Array<SelfSecurementUsage>;
  /** fetch aggregated fields from the table: "SelfSecurementUsage" */
  SelfSecurementUsage_aggregate: SelfSecurementUsage_Aggregate;
  /** fetch data from the table: "SelfSecurementUsage" using primary key columns */
  SelfSecurementUsage_by_pk?: Maybe<SelfSecurementUsage>;
  /** fetch data from the table: "SeparatorSheet" */
  SeparatorSheet: Array<SeparatorSheet>;
  /** fetch data from the table: "SeparatorSheetUsage" */
  SeparatorSheetUsage: Array<SeparatorSheetUsage>;
  /** fetch aggregated fields from the table: "SeparatorSheetUsage" */
  SeparatorSheetUsage_aggregate: SeparatorSheetUsage_Aggregate;
  /** fetch data from the table: "SeparatorSheetUsage" using primary key columns */
  SeparatorSheetUsage_by_pk?: Maybe<SeparatorSheetUsage>;
  /** fetch aggregated fields from the table: "SeparatorSheet" */
  SeparatorSheet_aggregate: SeparatorSheet_Aggregate;
  /** fetch data from the table: "SeparatorSheet" using primary key columns */
  SeparatorSheet_by_pk?: Maybe<SeparatorSheet>;
  /** An array relationship */
  Shingles: Array<Shingles>;
  /** An aggregate relationship */
  Shingles_aggregate: Shingles_Aggregate;
  /** fetch data from the table: "Shingles" using primary key columns */
  Shingles_by_pk?: Maybe<Shingles>;
  /** fetch data from the table: "SinglePlyCover" */
  SinglePlyCover: Array<SinglePlyCover>;
  /** fetch aggregated fields from the table: "SinglePlyCover" */
  SinglePlyCover_aggregate: SinglePlyCover_Aggregate;
  /** fetch data from the table: "SinglePlyCover" using primary key columns */
  SinglePlyCover_by_pk?: Maybe<SinglePlyCover>;
  /** fetch data from the table: "SinglePlyUsage" */
  SinglePlyUsage: Array<SinglePlyUsage>;
  /** fetch aggregated fields from the table: "SinglePlyUsage" */
  SinglePlyUsage_aggregate: SinglePlyUsage_Aggregate;
  /** fetch data from the table: "SinglePlyUsage" using primary key columns */
  SinglePlyUsage_by_pk?: Maybe<SinglePlyUsage>;
  /** fetch data from the table: "SprayFoamInsulationUsage" */
  SprayFoamInsulationUsage: Array<SprayFoamInsulationUsage>;
  /** fetch aggregated fields from the table: "SprayFoamInsulationUsage" */
  SprayFoamInsulationUsage_aggregate: SprayFoamInsulationUsage_Aggregate;
  /** fetch data from the table: "SprayFoamInsulationUsage" using primary key columns */
  SprayFoamInsulationUsage_by_pk?: Maybe<SprayFoamInsulationUsage>;
  /** fetch data from the table: "SprayedFoamInsulation" */
  SprayedFoamInsulation: Array<SprayedFoamInsulation>;
  /** fetch aggregated fields from the table: "SprayedFoamInsulation" */
  SprayedFoamInsulation_aggregate: SprayedFoamInsulation_Aggregate;
  /** fetch data from the table: "SprayedFoamInsulation" using primary key columns */
  SprayedFoamInsulation_by_pk?: Maybe<SprayedFoamInsulation>;
  /** fetch data from the table: "StandingLapSeamCover" */
  StandingLapSeamCover: Array<StandingLapSeamCover>;
  /** fetch aggregated fields from the table: "StandingLapSeamCover" */
  StandingLapSeamCover_aggregate: StandingLapSeamCover_Aggregate;
  /** fetch data from the table: "StandingLapSeamCover" using primary key columns */
  StandingLapSeamCover_by_pk?: Maybe<StandingLapSeamCover>;
  /** fetch data from the table: "StandingLapSeamUsage" */
  StandingLapSeamUsage: Array<StandingLapSeamUsage>;
  /** fetch aggregated fields from the table: "StandingLapSeamUsage" */
  StandingLapSeamUsage_aggregate: StandingLapSeamUsage_Aggregate;
  /** fetch data from the table: "StandingLapSeamUsage" using primary key columns */
  StandingLapSeamUsage_by_pk?: Maybe<StandingLapSeamUsage>;
  /** fetch data from the table: "SteelDeck" */
  SteelDeck: Array<SteelDeck>;
  /** fetch data from the table: "SteelDeckLayer" */
  SteelDeckLayer: Array<SteelDeckLayer>;
  /** fetch aggregated fields from the table: "SteelDeckLayer" */
  SteelDeckLayer_aggregate: SteelDeckLayer_Aggregate;
  /** fetch data from the table: "SteelDeckLayer" using primary key columns */
  SteelDeckLayer_by_pk?: Maybe<SteelDeckLayer>;
  /** fetch data from the table: "SteelDeckUsage" */
  SteelDeckUsage: Array<SteelDeckUsage>;
  /** fetch aggregated fields from the table: "SteelDeckUsage" */
  SteelDeckUsage_aggregate: SteelDeckUsage_Aggregate;
  /** fetch data from the table: "SteelDeckUsage" using primary key columns */
  SteelDeckUsage_by_pk?: Maybe<SteelDeckUsage>;
  /** fetch aggregated fields from the table: "SteelDeck" */
  SteelDeck_aggregate: SteelDeck_Aggregate;
  /** fetch data from the table: "SteelDeck" using primary key columns */
  SteelDeck_by_pk?: Maybe<SteelDeck>;
  /** fetch data from the table: "StressPlate" */
  StressPlate: Array<StressPlate>;
  /** fetch data from the table: "StressPlateUsage" */
  StressPlateUsage: Array<StressPlateUsage>;
  /** fetch aggregated fields from the table: "StressPlateUsage" */
  StressPlateUsage_aggregate: StressPlateUsage_Aggregate;
  /** fetch data from the table: "StressPlateUsage" using primary key columns */
  StressPlateUsage_by_pk?: Maybe<StressPlateUsage>;
  /** fetch aggregated fields from the table: "StressPlate" */
  StressPlate_aggregate: StressPlate_Aggregate;
  /** fetch data from the table: "StressPlate" using primary key columns */
  StressPlate_by_pk?: Maybe<StressPlate>;
  /** fetch data from the table: "StructuralConcreteLayer" */
  StructuralConcreteLayer: Array<StructuralConcreteLayer>;
  /** fetch aggregated fields from the table: "StructuralConcreteLayer" */
  StructuralConcreteLayer_aggregate: StructuralConcreteLayer_Aggregate;
  /** fetch data from the table: "StructuralConcreteLayer" using primary key columns */
  StructuralConcreteLayer_by_pk?: Maybe<StructuralConcreteLayer>;
  /** fetch data from the table: "StructureLayer" */
  StructureLayer: Array<StructureLayer>;
  /** fetch aggregated fields from the table: "StructureLayer" */
  StructureLayer_aggregate: StructureLayer_Aggregate;
  /** fetch data from the table: "StructureLayer" using primary key columns */
  StructureLayer_by_pk?: Maybe<StructureLayer>;
  /** fetch data from the table: "StructuredSystem" */
  StructuredSystem: Array<StructuredSystem>;
  /** An array relationship */
  StructuredSystemProducts: Array<StructuredSystemProducts>;
  /** An aggregate relationship */
  StructuredSystemProducts_aggregate: StructuredSystemProducts_Aggregate;
  /** fetch data from the table: "StructuredSystemProducts" using primary key columns */
  StructuredSystemProducts_by_pk?: Maybe<StructuredSystemProducts>;
  /** fetch aggregated fields from the table: "StructuredSystem" */
  StructuredSystem_aggregate: StructuredSystem_Aggregate;
  /** fetch data from the table: "StructuredSystem" using primary key columns */
  StructuredSystem_by_pk?: Maybe<StructuredSystem>;
  /** fetch data from the table: "Subassembly" */
  Subassembly: Array<Subassembly>;
  /** fetch data from the table: "SubassemblyDelistData" */
  SubassemblyDelistData: Array<SubassemblyDelistData>;
  /** fetch aggregated fields from the table: "SubassemblyDelistData" */
  SubassemblyDelistData_aggregate: SubassemblyDelistData_Aggregate;
  /** fetch data from the table: "SubassemblyE108Rating" */
  SubassemblyE108Rating: Array<SubassemblyE108Rating>;
  /** fetch aggregated fields from the table: "SubassemblyE108Rating" */
  SubassemblyE108Rating_aggregate: SubassemblyE108Rating_Aggregate;
  /** fetch data from the table: "SubassemblyE108Rating" using primary key columns */
  SubassemblyE108Rating_by_pk?: Maybe<SubassemblyE108Rating>;
  /** An array relationship */
  SubassemblyLayers: Array<SubassemblyLayers>;
  /** An aggregate relationship */
  SubassemblyLayers_aggregate: SubassemblyLayers_Aggregate;
  /** fetch data from the table: "SubassemblyLayers" using primary key columns */
  SubassemblyLayers_by_pk?: Maybe<SubassemblyLayers>;
  /** fetch data from the table: "SubassemblyMultiplyConfig" */
  SubassemblyMultiplyConfig: Array<SubassemblyMultiplyConfig>;
  /** fetch aggregated fields from the table: "SubassemblyMultiplyConfig" */
  SubassemblyMultiplyConfig_aggregate: SubassemblyMultiplyConfig_Aggregate;
  /** fetch data from the table: "SubassemblyMultiplyConfig" using primary key columns */
  SubassemblyMultiplyConfig_by_pk?: Maybe<SubassemblyMultiplyConfig>;
  /** fetch aggregated fields from the table: "Subassembly" */
  Subassembly_aggregate: Subassembly_Aggregate;
  /** fetch data from the table: "Subassembly" using primary key columns */
  Subassembly_by_pk?: Maybe<Subassembly>;
  /** fetch data from the table: "Subcat_Adhesive" */
  Subcat_Adhesive: Array<Subcat_Adhesive>;
  /** fetch aggregated fields from the table: "Subcat_Adhesive" */
  Subcat_Adhesive_aggregate: Subcat_Adhesive_Aggregate;
  /** fetch data from the table: "Subcat_AirRetarder" */
  Subcat_AirRetarder: Array<Subcat_AirRetarder>;
  /** fetch aggregated fields from the table: "Subcat_AirRetarder" */
  Subcat_AirRetarder_aggregate: Subcat_AirRetarder_Aggregate;
  /** fetch data from the table: "Subcat_BaseSheet" */
  Subcat_BaseSheet: Array<Subcat_BaseSheet>;
  /** fetch aggregated fields from the table: "Subcat_BaseSheet" */
  Subcat_BaseSheet_aggregate: Subcat_BaseSheet_Aggregate;
  /** fetch data from the table: "Subcat_BattBlanket" */
  Subcat_BattBlanket: Array<Subcat_BattBlanket>;
  /** fetch aggregated fields from the table: "Subcat_BattBlanket" */
  Subcat_BattBlanket_aggregate: Subcat_BattBlanket_Aggregate;
  /** fetch data from the table: "Subcat_BoardStock" */
  Subcat_BoardStock: Array<Subcat_BoardStock>;
  /** fetch aggregated fields from the table: "Subcat_BoardStock" */
  Subcat_BoardStock_aggregate: Subcat_BoardStock_Aggregate;
  /** fetch data from the table: "Subcat_Coating" */
  Subcat_Coating: Array<Subcat_Coating>;
  /** fetch aggregated fields from the table: "Subcat_Coating" */
  Subcat_Coating_aggregate: Subcat_Coating_Aggregate;
  /** fetch data from the table: "Subcat_CompositePanel" */
  Subcat_CompositePanel: Array<Subcat_CompositePanel>;
  /** fetch aggregated fields from the table: "Subcat_CompositePanel" */
  Subcat_CompositePanel_aggregate: Subcat_CompositePanel_Aggregate;
  /** fetch data from the table: "Subcat_CoverStrip" */
  Subcat_CoverStrip: Array<Subcat_CoverStrip>;
  /** fetch aggregated fields from the table: "Subcat_CoverStrip" */
  Subcat_CoverStrip_aggregate: Subcat_CoverStrip_Aggregate;
  /** fetch data from the table: "Subcat_ExpJoint" */
  Subcat_ExpJoint: Array<Subcat_ExpJoint>;
  /** fetch aggregated fields from the table: "Subcat_ExpJoint" */
  Subcat_ExpJoint_aggregate: Subcat_ExpJoint_Aggregate;
  /** fetch data from the table: "Subcat_Fastening" */
  Subcat_Fastening: Array<Subcat_Fastening>;
  /** fetch aggregated fields from the table: "Subcat_Fastening" */
  Subcat_Fastening_aggregate: Subcat_Fastening_Aggregate;
  /** fetch data from the table: "Subcat_FormDeck" */
  Subcat_FormDeck: Array<Subcat_FormDeck>;
  /** fetch aggregated fields from the table: "Subcat_FormDeck" */
  Subcat_FormDeck_aggregate: Subcat_FormDeck_Aggregate;
  /** fetch data from the table: "Subcat_Gutter" */
  Subcat_Gutter: Array<Subcat_Gutter>;
  /** fetch aggregated fields from the table: "Subcat_Gutter" */
  Subcat_Gutter_aggregate: Subcat_Gutter_Aggregate;
  /** fetch data from the table: "Subcat_Gypsum" */
  Subcat_Gypsum: Array<Subcat_Gypsum>;
  /** fetch aggregated fields from the table: "Subcat_Gypsum" */
  Subcat_Gypsum_aggregate: Subcat_Gypsum_Aggregate;
  /** fetch data from the table: "Subcat_LWIC" */
  Subcat_LWIC: Array<Subcat_Lwic>;
  /** fetch data from the table: "Subcat_LWICDeck" */
  Subcat_LWICDeck: Array<Subcat_LwicDeck>;
  /** fetch aggregated fields from the table: "Subcat_LWICDeck" */
  Subcat_LWICDeck_aggregate: Subcat_LwicDeck_Aggregate;
  /** fetch aggregated fields from the table: "Subcat_LWIC" */
  Subcat_LWIC_aggregate: Subcat_Lwic_Aggregate;
  /** fetch data from the table: "Subcat_LinerPanel" */
  Subcat_LinerPanel: Array<Subcat_LinerPanel>;
  /** fetch aggregated fields from the table: "Subcat_LinerPanel" */
  Subcat_LinerPanel_aggregate: Subcat_LinerPanel_Aggregate;
  /** fetch data from the table: "Subcat_LiquidApplied" */
  Subcat_LiquidApplied: Array<Subcat_LiquidApplied>;
  /** fetch aggregated fields from the table: "Subcat_LiquidApplied" */
  Subcat_LiquidApplied_aggregate: Subcat_LiquidApplied_Aggregate;
  /** fetch data from the table: "Subcat_MaintCoating" */
  Subcat_MaintCoating: Array<Subcat_MaintCoating>;
  /** fetch aggregated fields from the table: "Subcat_MaintCoating" */
  Subcat_MaintCoating_aggregate: Subcat_MaintCoating_Aggregate;
  /** fetch data from the table: "Subcat_Multiply" */
  Subcat_Multiply: Array<Subcat_Multiply>;
  /** fetch data from the table: "Subcat_MultiplyCover" */
  Subcat_MultiplyCover: Array<Subcat_MultiplyCover>;
  /** fetch aggregated fields from the table: "Subcat_MultiplyCover" */
  Subcat_MultiplyCover_aggregate: Subcat_MultiplyCover_Aggregate;
  /** fetch aggregated fields from the table: "Subcat_Multiply" */
  Subcat_Multiply_aggregate: Subcat_Multiply_Aggregate;
  /** fetch data from the table: "Subcat_PerimeterFlashing" */
  Subcat_PerimeterFlashing: Array<Subcat_PerimeterFlashing>;
  /** fetch aggregated fields from the table: "Subcat_PerimeterFlashing" */
  Subcat_PerimeterFlashing_aggregate: Subcat_PerimeterFlashing_Aggregate;
  /** fetch data from the table: "Subcat_PlasticDeck" */
  Subcat_PlasticDeck: Array<Subcat_PlasticDeck>;
  /** fetch aggregated fields from the table: "Subcat_PlasticDeck" */
  Subcat_PlasticDeck_aggregate: Subcat_PlasticDeck_Aggregate;
  /** fetch data from the table: "Subcat_Primer" */
  Subcat_Primer: Array<Subcat_Primer>;
  /** fetch aggregated fields from the table: "Subcat_Primer" */
  Subcat_Primer_aggregate: Subcat_Primer_Aggregate;
  /** fetch data from the table: "Subcat_Seaming" */
  Subcat_Seaming: Array<Subcat_Seaming>;
  /** fetch aggregated fields from the table: "Subcat_Seaming" */
  Subcat_Seaming_aggregate: Subcat_Seaming_Aggregate;
  /** fetch data from the table: "Subcat_SeparatorSheet" */
  Subcat_SeparatorSheet: Array<Subcat_SeparatorSheet>;
  /** fetch aggregated fields from the table: "Subcat_SeparatorSheet" */
  Subcat_SeparatorSheet_aggregate: Subcat_SeparatorSheet_Aggregate;
  /** fetch data from the table: "Subcat_Shingles" */
  Subcat_Shingles: Array<Subcat_Shingles>;
  /** fetch aggregated fields from the table: "Subcat_Shingles" */
  Subcat_Shingles_aggregate: Subcat_Shingles_Aggregate;
  /** fetch data from the table: "Subcat_SinglePly" */
  Subcat_SinglePly: Array<Subcat_SinglePly>;
  /** fetch data from the table: "Subcat_SinglePlyCover" */
  Subcat_SinglePlyCover: Array<Subcat_SinglePlyCover>;
  /** fetch aggregated fields from the table: "Subcat_SinglePlyCover" */
  Subcat_SinglePlyCover_aggregate: Subcat_SinglePlyCover_Aggregate;
  /** fetch aggregated fields from the table: "Subcat_SinglePly" */
  Subcat_SinglePly_aggregate: Subcat_SinglePly_Aggregate;
  /** fetch data from the table: "Subcat_SprayFoam" */
  Subcat_SprayFoam: Array<Subcat_SprayFoam>;
  /** fetch aggregated fields from the table: "Subcat_SprayFoam" */
  Subcat_SprayFoam_aggregate: Subcat_SprayFoam_Aggregate;
  /** fetch data from the table: "Subcat_StandingLapSeam" */
  Subcat_StandingLapSeam: Array<Subcat_StandingLapSeam>;
  /** fetch aggregated fields from the table: "Subcat_StandingLapSeam" */
  Subcat_StandingLapSeam_aggregate: Subcat_StandingLapSeam_Aggregate;
  /** fetch data from the table: "Subcat_SteelDeck" */
  Subcat_SteelDeck: Array<Subcat_SteelDeck>;
  /** fetch aggregated fields from the table: "Subcat_SteelDeck" */
  Subcat_SteelDeck_aggregate: Subcat_SteelDeck_Aggregate;
  /** fetch data from the table: "Subcat_Surfacing" */
  Subcat_Surfacing: Array<Subcat_Surfacing>;
  /** fetch aggregated fields from the table: "Subcat_Surfacing" */
  Subcat_Surfacing_aggregate: Subcat_Surfacing_Aggregate;
  /** fetch data from the table: "Subcat_VaporRetarder" */
  Subcat_VaporRetarder: Array<Subcat_VaporRetarder>;
  /** fetch aggregated fields from the table: "Subcat_VaporRetarder" */
  Subcat_VaporRetarder_aggregate: Subcat_VaporRetarder_Aggregate;
  /** fetch data from the table: "Subcat_Weld" */
  Subcat_Weld: Array<Subcat_Weld>;
  /** fetch aggregated fields from the table: "Subcat_Weld" */
  Subcat_Weld_aggregate: Subcat_Weld_Aggregate;
  /** fetch data from the table: "Subcat_Wood" */
  Subcat_Wood: Array<Subcat_Wood>;
  /** fetch data from the table: "Subcat_WoodFiberDeck" */
  Subcat_WoodFiberDeck: Array<Subcat_WoodFiberDeck>;
  /** fetch aggregated fields from the table: "Subcat_WoodFiberDeck" */
  Subcat_WoodFiberDeck_aggregate: Subcat_WoodFiberDeck_Aggregate;
  /** fetch aggregated fields from the table: "Subcat_Wood" */
  Subcat_Wood_aggregate: Subcat_Wood_Aggregate;
  /** fetch data from the table: "SubstratePrimer" */
  SubstratePrimer: Array<SubstratePrimer>;
  /** fetch data from the table: "SubstratePrimerUsage" */
  SubstratePrimerUsage: Array<SubstratePrimerUsage>;
  /** fetch aggregated fields from the table: "SubstratePrimerUsage" */
  SubstratePrimerUsage_aggregate: SubstratePrimerUsage_Aggregate;
  /** fetch data from the table: "SubstratePrimerUsage" using primary key columns */
  SubstratePrimerUsage_by_pk?: Maybe<SubstratePrimerUsage>;
  /** fetch aggregated fields from the table: "SubstratePrimer" */
  SubstratePrimer_aggregate: SubstratePrimer_Aggregate;
  /** fetch data from the table: "SubstratePrimer" using primary key columns */
  SubstratePrimer_by_pk?: Maybe<SubstratePrimer>;
  /** fetch data from the table: "Surfacing" */
  Surfacing: Array<Surfacing>;
  /** fetch data from the table: "SurfacingUsage" */
  SurfacingUsage: Array<SurfacingUsage>;
  /** fetch aggregated fields from the table: "SurfacingUsage" */
  SurfacingUsage_aggregate: SurfacingUsage_Aggregate;
  /** fetch data from the table: "SurfacingUsage" using primary key columns */
  SurfacingUsage_by_pk?: Maybe<SurfacingUsage>;
  /** fetch aggregated fields from the table: "Surfacing" */
  Surfacing_aggregate: Surfacing_Aggregate;
  /** fetch data from the table: "Surfacing" using primary key columns */
  Surfacing_by_pk?: Maybe<Surfacing>;
  /** fetch data from the table: "TapeWidth" */
  TapeWidth: Array<TapeWidth>;
  /** fetch aggregated fields from the table: "TapeWidth" */
  TapeWidth_aggregate: TapeWidth_Aggregate;
  /** fetch data from the table: "TapeWidth" using primary key columns */
  TapeWidth_by_pk?: Maybe<TapeWidth>;
  /** fetch data from the table: "ThermalBarrier" */
  ThermalBarrier: Array<ThermalBarrier>;
  /** An array relationship */
  ThermalBarrierBoardSizes: Array<ThermalBarrierBoardSizes>;
  /** An aggregate relationship */
  ThermalBarrierBoardSizes_aggregate: ThermalBarrierBoardSizes_Aggregate;
  /** fetch data from the table: "ThermalBarrierBoardSizes" using primary key columns */
  ThermalBarrierBoardSizes_by_pk?: Maybe<ThermalBarrierBoardSizes>;
  /** fetch data from the table: "ThermalBarrierFacerBottom" */
  ThermalBarrierFacerBottom: Array<ThermalBarrierFacerBottom>;
  /** fetch aggregated fields from the table: "ThermalBarrierFacerBottom" */
  ThermalBarrierFacerBottom_aggregate: ThermalBarrierFacerBottom_Aggregate;
  /** fetch data from the table: "ThermalBarrierFacerBottom" using primary key columns */
  ThermalBarrierFacerBottom_by_pk?: Maybe<ThermalBarrierFacerBottom>;
  /** fetch data from the table: "ThermalBarrierFacerTop" */
  ThermalBarrierFacerTop: Array<ThermalBarrierFacerTop>;
  /** fetch aggregated fields from the table: "ThermalBarrierFacerTop" */
  ThermalBarrierFacerTop_aggregate: ThermalBarrierFacerTop_Aggregate;
  /** fetch data from the table: "ThermalBarrierFacerTop" using primary key columns */
  ThermalBarrierFacerTop_by_pk?: Maybe<ThermalBarrierFacerTop>;
  /** fetch data from the table: "ThermalBarrierUsage" */
  ThermalBarrierUsage: Array<ThermalBarrierUsage>;
  /** fetch aggregated fields from the table: "ThermalBarrierUsage" */
  ThermalBarrierUsage_aggregate: ThermalBarrierUsage_Aggregate;
  /** fetch data from the table: "ThermalBarrierUsage" using primary key columns */
  ThermalBarrierUsage_by_pk?: Maybe<ThermalBarrierUsage>;
  /** fetch aggregated fields from the table: "ThermalBarrier" */
  ThermalBarrier_aggregate: ThermalBarrier_Aggregate;
  /** fetch data from the table: "ThermalBarrier" using primary key columns */
  ThermalBarrier_by_pk?: Maybe<ThermalBarrier>;
  /** fetch data from the table: "TopCoat" */
  TopCoat: Array<TopCoat>;
  /** fetch data from the table: "TopCoatUsage" */
  TopCoatUsage: Array<TopCoatUsage>;
  /** fetch aggregated fields from the table: "TopCoatUsage" */
  TopCoatUsage_aggregate: TopCoatUsage_Aggregate;
  /** fetch data from the table: "TopCoatUsage" using primary key columns */
  TopCoatUsage_by_pk?: Maybe<TopCoatUsage>;
  /** fetch aggregated fields from the table: "TopCoat" */
  TopCoat_aggregate: TopCoat_Aggregate;
  /** fetch data from the table: "TopCoat" using primary key columns */
  TopCoat_by_pk?: Maybe<TopCoat>;
  /** fetch data from the table: "Usage" */
  Usage: Array<Usage>;
  /** fetch data from the table: "UsageTradeNameView" */
  UsageTradeNameView: Array<UsageTradeNameView>;
  /** fetch aggregated fields from the table: "UsageTradeNameView" */
  UsageTradeNameView_aggregate: UsageTradeNameView_Aggregate;
  /** fetch aggregated fields from the table: "Usage" */
  Usage_aggregate: Usage_Aggregate;
  /** fetch data from the table: "Usage" using primary key columns */
  Usage_by_pk?: Maybe<Usage>;
  /** fetch data from the table: "UserReportBug" */
  UserReportBug: Array<UserReportBug>;
  /** fetch aggregated fields from the table: "UserReportBug" */
  UserReportBug_aggregate: UserReportBug_Aggregate;
  /** fetch data from the table: "UserReportBug" using primary key columns */
  UserReportBug_by_pk?: Maybe<UserReportBug>;
  /** fetch data from the table: "UserShareLink" */
  UserShareLink: Array<UserShareLink>;
  /** fetch aggregated fields from the table: "UserShareLink" */
  UserShareLink_aggregate: UserShareLink_Aggregate;
  /** fetch data from the table: "UserShareLink" using primary key columns */
  UserShareLink_by_pk?: Maybe<UserShareLink>;
  /** fetch data from the table: "VaporRetarder" */
  VaporRetarder: Array<VaporRetarder>;
  /** fetch data from the table: "VaporRetarderUsage" */
  VaporRetarderUsage: Array<VaporRetarderUsage>;
  /** fetch aggregated fields from the table: "VaporRetarderUsage" */
  VaporRetarderUsage_aggregate: VaporRetarderUsage_Aggregate;
  /** fetch data from the table: "VaporRetarderUsage" using primary key columns */
  VaporRetarderUsage_by_pk?: Maybe<VaporRetarderUsage>;
  /** fetch aggregated fields from the table: "VaporRetarder" */
  VaporRetarder_aggregate: VaporRetarder_Aggregate;
  /** fetch data from the table: "VaporRetarder" using primary key columns */
  VaporRetarder_by_pk?: Maybe<VaporRetarder>;
  /** fetch data from the table: "WeldArcUsage" */
  WeldArcUsage: Array<WeldArcUsage>;
  /** fetch aggregated fields from the table: "WeldArcUsage" */
  WeldArcUsage_aggregate: WeldArcUsage_Aggregate;
  /** fetch data from the table: "WeldArcUsage" using primary key columns */
  WeldArcUsage_by_pk?: Maybe<WeldArcUsage>;
  /** fetch data from the table: "WeldHeatUsage" */
  WeldHeatUsage: Array<WeldHeatUsage>;
  /** fetch aggregated fields from the table: "WeldHeatUsage" */
  WeldHeatUsage_aggregate: WeldHeatUsage_Aggregate;
  /** fetch data from the table: "WeldHeatUsage" using primary key columns */
  WeldHeatUsage_by_pk?: Maybe<WeldHeatUsage>;
  /** fetch data from the table: "WeldSolventUsage" */
  WeldSolventUsage: Array<WeldSolventUsage>;
  /** fetch aggregated fields from the table: "WeldSolventUsage" */
  WeldSolventUsage_aggregate: WeldSolventUsage_Aggregate;
  /** fetch data from the table: "WeldSolventUsage" using primary key columns */
  WeldSolventUsage_by_pk?: Maybe<WeldSolventUsage>;
  /** fetch data from the table: "WeldSystemUsage" */
  WeldSystemUsage: Array<WeldSystemUsage>;
  /** fetch aggregated fields from the table: "WeldSystemUsage" */
  WeldSystemUsage_aggregate: WeldSystemUsage_Aggregate;
  /** fetch data from the table: "WeldSystemUsage" using primary key columns */
  WeldSystemUsage_by_pk?: Maybe<WeldSystemUsage>;
  /** fetch data from the table: "WeldTorchedUsage" */
  WeldTorchedUsage: Array<WeldTorchedUsage>;
  /** fetch aggregated fields from the table: "WeldTorchedUsage" */
  WeldTorchedUsage_aggregate: WeldTorchedUsage_Aggregate;
  /** fetch data from the table: "WeldTorchedUsage" using primary key columns */
  WeldTorchedUsage_by_pk?: Maybe<WeldTorchedUsage>;
  /** fetch data from the table: "WoodDeck" */
  WoodDeck: Array<WoodDeck>;
  /** fetch data from the table: "WoodDeckUsage" */
  WoodDeckUsage: Array<WoodDeckUsage>;
  /** fetch aggregated fields from the table: "WoodDeckUsage" */
  WoodDeckUsage_aggregate: WoodDeckUsage_Aggregate;
  /** fetch data from the table: "WoodDeckUsage" using primary key columns */
  WoodDeckUsage_by_pk?: Maybe<WoodDeckUsage>;
  /** fetch aggregated fields from the table: "WoodDeck" */
  WoodDeck_aggregate: WoodDeck_Aggregate;
  /** fetch data from the table: "WoodDeck" using primary key columns */
  WoodDeck_by_pk?: Maybe<WoodDeck>;
  contact_api_savecontact?: Maybe<Array<Maybe<ContactSaveResult>>>;
  navassembly_api_countsearchresult?: Maybe<Array<Maybe<AssemblySearchCountItem>>>;
  navassembly_api_rooftypesearch?: Maybe<Array<Maybe<AssemblySearchRoofTypeItem>>>;
  navassembly_api_search?: Maybe<Array<Maybe<AssemblySearchResultItem>>>;
  product_api_listing?: Maybe<Array<Maybe<ProductListItem>>>;
  product_api_matchedassemblycount?: Maybe<Array<Maybe<ProductMatchedAssemblyCounting>>>;
  product_api_matchedassemblylist?: Maybe<Array<Maybe<AssemblySearchResultItem>>>;
  product_api_search?: Maybe<Array<Maybe<ProductSearchResultCounting>>>;
  roofarea_api_saveroofareabase?: Maybe<Array<Maybe<RoofAreaBaseSaveResult>>>;
  roofarea_api_saveroofareawithrating?: Maybe<Array<Maybe<RoofAreaWithRatingSaveResult>>>;
  roofproject_api_savebaseinfo?: Maybe<Array<Maybe<RoofProjectBaseInfoSaveResult>>>;
  roofproject_api_saveprojectnote?: Maybe<Array<Maybe<RoofProjectNoteSaveResult>>>;
  scenario_api_savescenario?: Maybe<Array<Maybe<ScenarioSaveResult>>>;
  /** fetch data from the table: "vNavAssembly_Layers" */
  vNavAssembly_Layers: Array<VNavAssembly_Layers>;
  /** fetch aggregated fields from the table: "vNavAssembly_Layers" */
  vNavAssembly_Layers_aggregate: VNavAssembly_Layers_Aggregate;
  /** fetch data from the table: "vNavAssembly_ManufacturerOnlySearch" */
  vNavAssembly_ManufacturerOnlySearch: Array<VNavAssembly_ManufacturerOnlySearch>;
  /** fetch aggregated fields from the table: "vNavAssembly_ManufacturerOnlySearch" */
  vNavAssembly_ManufacturerOnlySearch_aggregate: VNavAssembly_ManufacturerOnlySearch_Aggregate;
  /** fetch data from the table: "vNavAssembly_RoofTypeCount" */
  vNavAssembly_RoofTypeCount: Array<VNavAssembly_RoofTypeCount>;
  /** fetch aggregated fields from the table: "vNavAssembly_RoofTypeCount" */
  vNavAssembly_RoofTypeCount_aggregate: VNavAssembly_RoofTypeCount_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search" */
  vNavAssembly_Search: Array<VNavAssembly_Search>;
  /** fetch data from the table: "vNavAssembly_Search_AirRetarder" */
  vNavAssembly_Search_AirRetarder: Array<VNavAssembly_Search_AirRetarder>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_AirRetarder" */
  vNavAssembly_Search_AirRetarder_aggregate: VNavAssembly_Search_AirRetarder_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_BaseCoat" */
  vNavAssembly_Search_BaseCoat: Array<VNavAssembly_Search_BaseCoat>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_BaseCoat" */
  vNavAssembly_Search_BaseCoat_aggregate: VNavAssembly_Search_BaseCoat_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_BasePly" */
  vNavAssembly_Search_BasePly: Array<VNavAssembly_Search_BasePly>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_BasePly" */
  vNavAssembly_Search_BasePly_aggregate: VNavAssembly_Search_BasePly_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_BaseSheet" */
  vNavAssembly_Search_BaseSheet: Array<VNavAssembly_Search_BaseSheet>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_BaseSheet" */
  vNavAssembly_Search_BaseSheet_aggregate: VNavAssembly_Search_BaseSheet_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_BoardStock" */
  vNavAssembly_Search_BoardStock: Array<VNavAssembly_Search_BoardStock>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_BoardStock" */
  vNavAssembly_Search_BoardStock_aggregate: VNavAssembly_Search_BoardStock_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_CapPly" */
  vNavAssembly_Search_CapPly: Array<VNavAssembly_Search_CapPly>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_CapPly" */
  vNavAssembly_Search_CapPly_aggregate: VNavAssembly_Search_CapPly_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_Coat" */
  vNavAssembly_Search_Coat: Array<VNavAssembly_Search_Coat>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_Coat" */
  vNavAssembly_Search_Coat_aggregate: VNavAssembly_Search_Coat_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_Coating" */
  vNavAssembly_Search_Coating: Array<VNavAssembly_Search_Coating>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_Coating" */
  vNavAssembly_Search_Coating_aggregate: VNavAssembly_Search_Coating_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_CoverBoard" */
  vNavAssembly_Search_CoverBoard: Array<VNavAssembly_Search_CoverBoard>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_CoverBoard" */
  vNavAssembly_Search_CoverBoard_aggregate: VNavAssembly_Search_CoverBoard_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_CoverCompositePanel" */
  vNavAssembly_Search_CoverCompositePanel: Array<VNavAssembly_Search_CoverCompositePanel>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_CoverCompositePanel" */
  vNavAssembly_Search_CoverCompositePanel_aggregate: VNavAssembly_Search_CoverCompositePanel_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_CoverSingleply" */
  vNavAssembly_Search_CoverSingleply: Array<VNavAssembly_Search_CoverSingleply>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_CoverSingleply" */
  vNavAssembly_Search_CoverSingleply_aggregate: VNavAssembly_Search_CoverSingleply_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_CoverStandingLapSeam" */
  vNavAssembly_Search_CoverStandingLapSeam: Array<VNavAssembly_Search_CoverStandingLapSeam>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_CoverStandingLapSeam" */
  vNavAssembly_Search_CoverStandingLapSeam_aggregate: VNavAssembly_Search_CoverStandingLapSeam_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_CoverStripDisk" */
  vNavAssembly_Search_CoverStripDisk: Array<VNavAssembly_Search_CoverStripDisk>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_CoverStripDisk" */
  vNavAssembly_Search_CoverStripDisk_aggregate: VNavAssembly_Search_CoverStripDisk_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_DeckCementitiousPanel" */
  vNavAssembly_Search_DeckCementitiousPanel: Array<VNavAssembly_Search_DeckCementitiousPanel>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_DeckCementitiousPanel" */
  vNavAssembly_Search_DeckCementitiousPanel_aggregate: VNavAssembly_Search_DeckCementitiousPanel_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_DeckSteel" */
  vNavAssembly_Search_DeckSteel: Array<VNavAssembly_Search_DeckSteel>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_DeckSteel" */
  vNavAssembly_Search_DeckSteel_aggregate: VNavAssembly_Search_DeckSteel_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_FormDeck" */
  vNavAssembly_Search_FormDeck: Array<VNavAssembly_Search_FormDeck>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_FormDeck" */
  vNavAssembly_Search_FormDeck_aggregate: VNavAssembly_Search_FormDeck_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_InsulationBattBlanket" */
  vNavAssembly_Search_InsulationBattBlanket: Array<VNavAssembly_Search_InsulationBattBlanket>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_InsulationBattBlanket" */
  vNavAssembly_Search_InsulationBattBlanket_aggregate: VNavAssembly_Search_InsulationBattBlanket_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_InsulationSprayFoam" */
  vNavAssembly_Search_InsulationSprayFoam: Array<VNavAssembly_Search_InsulationSprayFoam>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_InsulationSprayFoam" */
  vNavAssembly_Search_InsulationSprayFoam_aggregate: VNavAssembly_Search_InsulationSprayFoam_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_LightweightInsulatingConcrete" */
  vNavAssembly_Search_LightweightInsulatingConcrete: Array<VNavAssembly_Search_LightweightInsulatingConcrete>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_LightweightInsulatingConcrete" */
  vNavAssembly_Search_LightweightInsulatingConcrete_aggregate: VNavAssembly_Search_LightweightInsulatingConcrete_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_LinerPanel" */
  vNavAssembly_Search_LinerPanel: Array<VNavAssembly_Search_LinerPanel>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_LinerPanel" */
  vNavAssembly_Search_LinerPanel_aggregate: VNavAssembly_Search_LinerPanel_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_Ply" */
  vNavAssembly_Search_Ply: Array<VNavAssembly_Search_Ply>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_Ply" */
  vNavAssembly_Search_Ply_aggregate: VNavAssembly_Search_Ply_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_SeparatorSheet" */
  vNavAssembly_Search_SeparatorSheet: Array<VNavAssembly_Search_SeparatorSheet>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_SeparatorSheet" */
  vNavAssembly_Search_SeparatorSheet_aggregate: VNavAssembly_Search_SeparatorSheet_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_SubstratePrimer" */
  vNavAssembly_Search_SubstratePrimer: Array<VNavAssembly_Search_SubstratePrimer>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_SubstratePrimer" */
  vNavAssembly_Search_SubstratePrimer_aggregate: VNavAssembly_Search_SubstratePrimer_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_Surfacing" */
  vNavAssembly_Search_Surfacing: Array<VNavAssembly_Search_Surfacing>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_Surfacing" */
  vNavAssembly_Search_Surfacing_aggregate: VNavAssembly_Search_Surfacing_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_ThermalBarrier" */
  vNavAssembly_Search_ThermalBarrier: Array<VNavAssembly_Search_ThermalBarrier>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_ThermalBarrier" */
  vNavAssembly_Search_ThermalBarrier_aggregate: VNavAssembly_Search_ThermalBarrier_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_TopCoat" */
  vNavAssembly_Search_TopCoat: Array<VNavAssembly_Search_TopCoat>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_TopCoat" */
  vNavAssembly_Search_TopCoat_aggregate: VNavAssembly_Search_TopCoat_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_VaporRetarder" */
  vNavAssembly_Search_VaporRetarder: Array<VNavAssembly_Search_VaporRetarder>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_VaporRetarder" */
  vNavAssembly_Search_VaporRetarder_aggregate: VNavAssembly_Search_VaporRetarder_Aggregate;
  /** fetch aggregated fields from the table: "vNavAssembly_Search" */
  vNavAssembly_Search_aggregate: VNavAssembly_Search_Aggregate;
  /** fetch data from the table: "vNavAssembly_Usages" */
  vNavAssembly_Usages: Array<VNavAssembly_Usages>;
  /** fetch aggregated fields from the table: "vNavAssembly_Usages" */
  vNavAssembly_Usages_aggregate: VNavAssembly_Usages_Aggregate;
};


export type Query_RootAddressArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Query_RootAddress_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Query_RootAddress_By_PkArgs = {
  AddressId: Scalars['Int'];
};


export type Query_RootAdheredSinglePlyOrphanReportArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdheredSinglePlyOrphanReport_Order_By>>;
  where?: Maybe<AdheredSinglePlyOrphanReport_Bool_Exp>;
};


export type Query_RootAdheredSinglePlyOrphanReport_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdheredSinglePlyOrphanReport_Order_By>>;
  where?: Maybe<AdheredSinglePlyOrphanReport_Bool_Exp>;
};


export type Query_RootAdheredSinglePlyOrphanReport_By_PkArgs = {
  AdheredSinglePlyOrphansId: Scalars['Int'];
};


export type Query_RootAdhesiveFullApplicationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveFullApplication_Order_By>>;
  where?: Maybe<AdhesiveFullApplication_Bool_Exp>;
};


export type Query_RootAdhesiveFullApplicationUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveFullApplicationUsage_Order_By>>;
  where?: Maybe<AdhesiveFullApplicationUsage_Bool_Exp>;
};


export type Query_RootAdhesiveFullApplicationUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveFullApplicationUsage_Order_By>>;
  where?: Maybe<AdhesiveFullApplicationUsage_Bool_Exp>;
};


export type Query_RootAdhesiveFullApplicationUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootAdhesiveFullApplication_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveFullApplication_Order_By>>;
  where?: Maybe<AdhesiveFullApplication_Bool_Exp>;
};


export type Query_RootAdhesiveFullApplication_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootAdhesiveRibbonUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveRibbonUsage_Order_By>>;
  where?: Maybe<AdhesiveRibbonUsage_Bool_Exp>;
};


export type Query_RootAdhesiveRibbonUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveRibbonUsage_Order_By>>;
  where?: Maybe<AdhesiveRibbonUsage_Bool_Exp>;
};


export type Query_RootAdhesiveRibbonUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootAdhesiveRibbonsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveRibbons_Order_By>>;
  where?: Maybe<AdhesiveRibbons_Bool_Exp>;
};


export type Query_RootAdhesiveRibbons_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveRibbons_Order_By>>;
  where?: Maybe<AdhesiveRibbons_Bool_Exp>;
};


export type Query_RootAdhesiveRibbons_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootAdhesiveSpotsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSpots_Order_By>>;
  where?: Maybe<AdhesiveSpots_Bool_Exp>;
};


export type Query_RootAdhesiveSpotsUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSpotsUsage_Order_By>>;
  where?: Maybe<AdhesiveSpotsUsage_Bool_Exp>;
};


export type Query_RootAdhesiveSpotsUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSpotsUsage_Order_By>>;
  where?: Maybe<AdhesiveSpotsUsage_Bool_Exp>;
};


export type Query_RootAdhesiveSpotsUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootAdhesiveSpots_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSpots_Order_By>>;
  where?: Maybe<AdhesiveSpots_Bool_Exp>;
};


export type Query_RootAdhesiveSpots_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootAdhesiveSystemUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSystemUsage_Order_By>>;
  where?: Maybe<AdhesiveSystemUsage_Bool_Exp>;
};


export type Query_RootAdhesiveSystemUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSystemUsage_Order_By>>;
  where?: Maybe<AdhesiveSystemUsage_Bool_Exp>;
};


export type Query_RootAdhesiveSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootAirRetarderArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AirRetarder_Order_By>>;
  where?: Maybe<AirRetarder_Bool_Exp>;
};


export type Query_RootAirRetarderUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AirRetarderUsage_Order_By>>;
  where?: Maybe<AirRetarderUsage_Bool_Exp>;
};


export type Query_RootAirRetarderUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AirRetarderUsage_Order_By>>;
  where?: Maybe<AirRetarderUsage_Bool_Exp>;
};


export type Query_RootAirRetarderUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootAirRetarder_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AirRetarder_Order_By>>;
  where?: Maybe<AirRetarder_Bool_Exp>;
};


export type Query_RootAirRetarder_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootAllManufacturedProductsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AllManufacturedProducts_Order_By>>;
  where?: Maybe<AllManufacturedProducts_Bool_Exp>;
};


export type Query_RootAllManufacturedProducts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AllManufacturedProducts_Order_By>>;
  where?: Maybe<AllManufacturedProducts_Bool_Exp>;
};


export type Query_RootAnchorArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Anchor_Order_By>>;
  where?: Maybe<Anchor_Bool_Exp>;
};


export type Query_RootAnchor_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Anchor_Order_By>>;
  where?: Maybe<Anchor_Bool_Exp>;
};


export type Query_RootAnchor_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootApprovalsDocArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ApprovalsDoc_Order_By>>;
  where?: Maybe<ApprovalsDoc_Bool_Exp>;
};


export type Query_RootApprovalsDoc_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ApprovalsDoc_Order_By>>;
  where?: Maybe<ApprovalsDoc_Bool_Exp>;
};


export type Query_RootApprovalsDoc_By_PkArgs = {
  ApprovalsDocId: Scalars['Int'];
};


export type Query_RootApprovedLayerUseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ApprovedLayerUse_Order_By>>;
  where?: Maybe<ApprovedLayerUse_Bool_Exp>;
};


export type Query_RootApprovedLayerUse_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ApprovedLayerUse_Order_By>>;
  where?: Maybe<ApprovedLayerUse_Bool_Exp>;
};


export type Query_RootApprovedLayerUse_By_PkArgs = {
  ApprovedLayerUseId: Scalars['Int'];
};


export type Query_RootAssemblyReconfigQueueArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AssemblyReconfigQueue_Order_By>>;
  where?: Maybe<AssemblyReconfigQueue_Bool_Exp>;
};


export type Query_RootAssemblyReconfigQueue_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AssemblyReconfigQueue_Order_By>>;
  where?: Maybe<AssemblyReconfigQueue_Bool_Exp>;
};


export type Query_RootAssemblyReconfigQueue_By_PkArgs = {
  AssemblyReconfigQueueId: Scalars['Int'];
};


export type Query_RootBaseCoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseCoat_Order_By>>;
  where?: Maybe<BaseCoat_Bool_Exp>;
};


export type Query_RootBaseCoatUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseCoatUsage_Order_By>>;
  where?: Maybe<BaseCoatUsage_Bool_Exp>;
};


export type Query_RootBaseCoatUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseCoatUsage_Order_By>>;
  where?: Maybe<BaseCoatUsage_Bool_Exp>;
};


export type Query_RootBaseCoatUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootBaseCoat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseCoat_Order_By>>;
  where?: Maybe<BaseCoat_Bool_Exp>;
};


export type Query_RootBaseCoat_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootBasePlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BasePly_Order_By>>;
  where?: Maybe<BasePly_Bool_Exp>;
};


export type Query_RootBasePlyUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BasePlyUsage_Order_By>>;
  where?: Maybe<BasePlyUsage_Bool_Exp>;
};


export type Query_RootBasePlyUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BasePlyUsage_Order_By>>;
  where?: Maybe<BasePlyUsage_Bool_Exp>;
};


export type Query_RootBasePlyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootBasePly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BasePly_Order_By>>;
  where?: Maybe<BasePly_Bool_Exp>;
};


export type Query_RootBasePly_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootBaseSheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseSheet_Order_By>>;
  where?: Maybe<BaseSheet_Bool_Exp>;
};


export type Query_RootBaseSheetUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseSheetUsage_Order_By>>;
  where?: Maybe<BaseSheetUsage_Bool_Exp>;
};


export type Query_RootBaseSheetUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseSheetUsage_Order_By>>;
  where?: Maybe<BaseSheetUsage_Bool_Exp>;
};


export type Query_RootBaseSheetUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootBaseSheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseSheet_Order_By>>;
  where?: Maybe<BaseSheet_Bool_Exp>;
};


export type Query_RootBaseSheet_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootBattBlanketInsulationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattBlanketInsulation_Order_By>>;
  where?: Maybe<BattBlanketInsulation_Bool_Exp>;
};


export type Query_RootBattBlanketInsulation_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattBlanketInsulation_Order_By>>;
  where?: Maybe<BattBlanketInsulation_Bool_Exp>;
};


export type Query_RootBattBlanketInsulation_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootBattBlanketUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattBlanketUsage_Order_By>>;
  where?: Maybe<BattBlanketUsage_Bool_Exp>;
};


export type Query_RootBattBlanketUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattBlanketUsage_Order_By>>;
  where?: Maybe<BattBlanketUsage_Bool_Exp>;
};


export type Query_RootBattBlanketUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootBattenBarArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattenBar_Order_By>>;
  where?: Maybe<BattenBar_Bool_Exp>;
};


export type Query_RootBattenBarUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattenBarUsage_Order_By>>;
  where?: Maybe<BattenBarUsage_Bool_Exp>;
};


export type Query_RootBattenBarUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattenBarUsage_Order_By>>;
  where?: Maybe<BattenBarUsage_Bool_Exp>;
};


export type Query_RootBattenBarUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootBattenBar_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattenBar_Order_By>>;
  where?: Maybe<BattenBar_Bool_Exp>;
};


export type Query_RootBattenBar_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootBoardSizesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardSizes_Order_By>>;
  where?: Maybe<BoardSizes_Bool_Exp>;
};


export type Query_RootBoardSizes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardSizes_Order_By>>;
  where?: Maybe<BoardSizes_Bool_Exp>;
};


export type Query_RootBoardStockArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStock_Order_By>>;
  where?: Maybe<BoardStock_Bool_Exp>;
};


export type Query_RootBoardStockBoardSizesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockBoardSizes_Order_By>>;
  where?: Maybe<BoardStockBoardSizes_Bool_Exp>;
};


export type Query_RootBoardStockBoardSizes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockBoardSizes_Order_By>>;
  where?: Maybe<BoardStockBoardSizes_Bool_Exp>;
};


export type Query_RootBoardStockBoardSizes_By_PkArgs = {
  ComponentId: Scalars['Int'];
  InsBoardSizeId: Scalars['Int'];
};


export type Query_RootBoardStockFacerBottomArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerBottom_Order_By>>;
  where?: Maybe<BoardStockFacerBottom_Bool_Exp>;
};


export type Query_RootBoardStockFacerBottom_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerBottom_Order_By>>;
  where?: Maybe<BoardStockFacerBottom_Bool_Exp>;
};


export type Query_RootBoardStockFacerBottom_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


export type Query_RootBoardStockFacerTopArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerTop_Order_By>>;
  where?: Maybe<BoardStockFacerTop_Bool_Exp>;
};


export type Query_RootBoardStockFacerTop_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerTop_Order_By>>;
  where?: Maybe<BoardStockFacerTop_Bool_Exp>;
};


export type Query_RootBoardStockFacerTop_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


export type Query_RootBoardStockUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockUsage_Order_By>>;
  where?: Maybe<BoardStockUsage_Bool_Exp>;
};


export type Query_RootBoardStockUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockUsage_Order_By>>;
  where?: Maybe<BoardStockUsage_Bool_Exp>;
};


export type Query_RootBoardStockUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootBoardStock_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStock_Order_By>>;
  where?: Maybe<BoardStock_Bool_Exp>;
};


export type Query_RootBoardStock_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootBroadcastAlertArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BroadcastAlert_Order_By>>;
  where?: Maybe<BroadcastAlert_Bool_Exp>;
};


export type Query_RootBroadcastAlert_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BroadcastAlert_Order_By>>;
  where?: Maybe<BroadcastAlert_Bool_Exp>;
};


export type Query_RootBroadcastAlert_By_PkArgs = {
  BroadcastAlertId: Scalars['Int'];
};


export type Query_RootBuiltupVaporRetarderSystemUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BuiltupVaporRetarderSystemUsage_Order_By>>;
  where?: Maybe<BuiltupVaporRetarderSystemUsage_Bool_Exp>;
};


export type Query_RootBuiltupVaporRetarderSystemUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BuiltupVaporRetarderSystemUsage_Order_By>>;
  where?: Maybe<BuiltupVaporRetarderSystemUsage_Bool_Exp>;
};


export type Query_RootBuiltupVaporRetarderSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootC_AdditiveArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Additive_Order_By>>;
  where?: Maybe<C_Additive_Bool_Exp>;
};


export type Query_RootC_Additive_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Additive_Order_By>>;
  where?: Maybe<C_Additive_Bool_Exp>;
};


export type Query_RootC_Additive_By_PkArgs = {
  AdditiveId: Scalars['Int'];
};


export type Query_RootC_AdhesiveApplicationMethodArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AdhesiveApplicationMethod_Order_By>>;
  where?: Maybe<C_AdhesiveApplicationMethod_Bool_Exp>;
};


export type Query_RootC_AdhesiveApplicationMethod_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AdhesiveApplicationMethod_Order_By>>;
  where?: Maybe<C_AdhesiveApplicationMethod_Bool_Exp>;
};


export type Query_RootC_AdhesiveApplicationMethod_By_PkArgs = {
  AdhesiveApplicationMethodId: Scalars['Int'];
};


export type Query_RootC_AdhesiveMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AdhesiveMaterial_Order_By>>;
  where?: Maybe<C_AdhesiveMaterial_Bool_Exp>;
};


export type Query_RootC_AdhesiveMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AdhesiveMaterial_Order_By>>;
  where?: Maybe<C_AdhesiveMaterial_Bool_Exp>;
};


export type Query_RootC_AdhesiveMaterial_By_PkArgs = {
  AdhesiveMaterialId: Scalars['Int'];
};


export type Query_RootC_AdhesiveTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AdhesiveType_Order_By>>;
  where?: Maybe<C_AdhesiveType_Bool_Exp>;
};


export type Query_RootC_AdhesiveType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AdhesiveType_Order_By>>;
  where?: Maybe<C_AdhesiveType_Bool_Exp>;
};


export type Query_RootC_AdhesiveType_By_PkArgs = {
  AdhesiveTypeId: Scalars['Int'];
};


export type Query_RootC_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Aggregate_Order_By>>;
  where?: Maybe<C_Aggregate_Bool_Exp>;
};


export type Query_RootC_Aggregate_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Aggregate_Order_By>>;
  where?: Maybe<C_Aggregate_Bool_Exp>;
};


export type Query_RootC_Aggregate_By_PkArgs = {
  AggregateId: Scalars['Int'];
};


export type Query_RootC_ApprovalsDocCategoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ApprovalsDocCategory_Order_By>>;
  where?: Maybe<C_ApprovalsDocCategory_Bool_Exp>;
};


export type Query_RootC_ApprovalsDocCategory_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ApprovalsDocCategory_Order_By>>;
  where?: Maybe<C_ApprovalsDocCategory_Bool_Exp>;
};


export type Query_RootC_ApprovalsDocCategory_By_PkArgs = {
  ApprovalsDocCategoryId: Scalars['Int'];
};


export type Query_RootC_ApprovedUseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ApprovedUse_Order_By>>;
  where?: Maybe<C_ApprovedUse_Bool_Exp>;
};


export type Query_RootC_ApprovedUse_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ApprovedUse_Order_By>>;
  where?: Maybe<C_ApprovedUse_Bool_Exp>;
};


export type Query_RootC_ApprovedUse_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
};


export type Query_RootC_AssemblyApplicationTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AssemblyApplicationType_Order_By>>;
  where?: Maybe<C_AssemblyApplicationType_Bool_Exp>;
};


export type Query_RootC_AssemblyApplicationType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AssemblyApplicationType_Order_By>>;
  where?: Maybe<C_AssemblyApplicationType_Bool_Exp>;
};


export type Query_RootC_AssemblyApplicationType_By_PkArgs = {
  AssemblyApplicationTypeId: Scalars['Int'];
};


export type Query_RootC_AssemblyTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AssemblyType_Order_By>>;
  where?: Maybe<C_AssemblyType_Bool_Exp>;
};


export type Query_RootC_AssemblyType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AssemblyType_Order_By>>;
  where?: Maybe<C_AssemblyType_Bool_Exp>;
};


export type Query_RootC_AssemblyType_By_PkArgs = {
  AssemblyTypeId: Scalars['Int'];
};


export type Query_RootC_BackingMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BackingMaterial_Order_By>>;
  where?: Maybe<C_BackingMaterial_Bool_Exp>;
};


export type Query_RootC_BackingMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BackingMaterial_Order_By>>;
  where?: Maybe<C_BackingMaterial_Bool_Exp>;
};


export type Query_RootC_BackingMaterial_By_PkArgs = {
  BackingMaterialId: Scalars['Int'];
};


export type Query_RootC_BaseSheetApplicationMethodArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BaseSheetApplicationMethod_Order_By>>;
  where?: Maybe<C_BaseSheetApplicationMethod_Bool_Exp>;
};


export type Query_RootC_BaseSheetApplicationMethod_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BaseSheetApplicationMethod_Order_By>>;
  where?: Maybe<C_BaseSheetApplicationMethod_Bool_Exp>;
};


export type Query_RootC_BaseSheetApplicationMethod_By_PkArgs = {
  BaseSheetApplicationMethodId: Scalars['Int'];
};


export type Query_RootC_BattBlanketFacerMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BattBlanketFacerMaterial_Order_By>>;
  where?: Maybe<C_BattBlanketFacerMaterial_Bool_Exp>;
};


export type Query_RootC_BattBlanketFacerMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BattBlanketFacerMaterial_Order_By>>;
  where?: Maybe<C_BattBlanketFacerMaterial_Bool_Exp>;
};


export type Query_RootC_BattBlanketFacerMaterial_By_PkArgs = {
  BattBlanketFacerMaterialId: Scalars['Int'];
};


export type Query_RootC_BattBlanketMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BattBlanketMaterial_Order_By>>;
  where?: Maybe<C_BattBlanketMaterial_Bool_Exp>;
};


export type Query_RootC_BattBlanketMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BattBlanketMaterial_Order_By>>;
  where?: Maybe<C_BattBlanketMaterial_Bool_Exp>;
};


export type Query_RootC_BattBlanketMaterial_By_PkArgs = {
  BattBlanketMaterialId: Scalars['Int'];
};


export type Query_RootC_BoardProfileArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BoardProfile_Order_By>>;
  where?: Maybe<C_BoardProfile_Bool_Exp>;
};


export type Query_RootC_BoardProfile_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BoardProfile_Order_By>>;
  where?: Maybe<C_BoardProfile_Bool_Exp>;
};


export type Query_RootC_BoardProfile_By_PkArgs = {
  BoardProfileId: Scalars['Int'];
};


export type Query_RootC_BoardStockMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BoardStockMaterial_Order_By>>;
  where?: Maybe<C_BoardStockMaterial_Bool_Exp>;
};


export type Query_RootC_BoardStockMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BoardStockMaterial_Order_By>>;
  where?: Maybe<C_BoardStockMaterial_Bool_Exp>;
};


export type Query_RootC_BoardStockMaterial_By_PkArgs = {
  BoardStockMaterialId: Scalars['Int'];
};


export type Query_RootC_BuildingEnclosureArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BuildingEnclosure_Order_By>>;
  where?: Maybe<C_BuildingEnclosure_Bool_Exp>;
};


export type Query_RootC_BuildingEnclosure_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BuildingEnclosure_Order_By>>;
  where?: Maybe<C_BuildingEnclosure_Bool_Exp>;
};


export type Query_RootC_BuildingEnclosure_By_PkArgs = {
  BuildingEnclosureId: Scalars['Int'];
};


export type Query_RootC_CementAggregateRatioArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CementAggregateRatio_Order_By>>;
  where?: Maybe<C_CementAggregateRatio_Bool_Exp>;
};


export type Query_RootC_CementAggregateRatio_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CementAggregateRatio_Order_By>>;
  where?: Maybe<C_CementAggregateRatio_Bool_Exp>;
};


export type Query_RootC_CementAggregateRatio_By_PkArgs = {
  CementAggregateRatioId: Scalars['Int'];
};


export type Query_RootC_CoatingMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoatingMaterial_Order_By>>;
  where?: Maybe<C_CoatingMaterial_Bool_Exp>;
};


export type Query_RootC_CoatingMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoatingMaterial_Order_By>>;
  where?: Maybe<C_CoatingMaterial_Bool_Exp>;
};


export type Query_RootC_CoatingMaterial_By_PkArgs = {
  CoatingMaterialId: Scalars['Int'];
};


export type Query_RootC_ComponentTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ComponentType_Order_By>>;
  where?: Maybe<C_ComponentType_Bool_Exp>;
};


export type Query_RootC_ComponentType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ComponentType_Order_By>>;
  where?: Maybe<C_ComponentType_Bool_Exp>;
};


export type Query_RootC_ComponentType_By_PkArgs = {
  ComponentTypeId: Scalars['Int'];
};


export type Query_RootC_ContactTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ContactType_Order_By>>;
  where?: Maybe<C_ContactType_Bool_Exp>;
};


export type Query_RootC_ContactType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ContactType_Order_By>>;
  where?: Maybe<C_ContactType_Bool_Exp>;
};


export type Query_RootC_ContactType_By_PkArgs = {
  ContactTypeId: Scalars['Int'];
};


export type Query_RootC_CountryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Country_Order_By>>;
  where?: Maybe<C_Country_Bool_Exp>;
};


export type Query_RootC_Country_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Country_Order_By>>;
  where?: Maybe<C_Country_Bool_Exp>;
};


export type Query_RootC_Country_By_PkArgs = {
  CountryId: Scalars['Int'];
};


export type Query_RootC_CoverBoardMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoverBoardMaterial_Order_By>>;
  where?: Maybe<C_CoverBoardMaterial_Bool_Exp>;
};


export type Query_RootC_CoverBoardMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoverBoardMaterial_Order_By>>;
  where?: Maybe<C_CoverBoardMaterial_Bool_Exp>;
};


export type Query_RootC_CoverBoardMaterial_By_PkArgs = {
  CoverBoardMaterialId: Scalars['Int'];
};


export type Query_RootC_CoverColorArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoverColor_Order_By>>;
  where?: Maybe<C_CoverColor_Bool_Exp>;
};


export type Query_RootC_CoverColor_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoverColor_Order_By>>;
  where?: Maybe<C_CoverColor_Bool_Exp>;
};


export type Query_RootC_CoverColor_By_PkArgs = {
  CoverColorId: Scalars['Int'];
};


export type Query_RootC_CoverSecurementMethodArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoverSecurementMethod_Order_By>>;
  where?: Maybe<C_CoverSecurementMethod_Bool_Exp>;
};


export type Query_RootC_CoverSecurementMethod_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoverSecurementMethod_Order_By>>;
  where?: Maybe<C_CoverSecurementMethod_Bool_Exp>;
};


export type Query_RootC_CoverSecurementMethod_By_PkArgs = {
  CoverSecurementMethodId: Scalars['Int'];
};


export type Query_RootC_DeckTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_DeckType_Order_By>>;
  where?: Maybe<C_DeckType_Bool_Exp>;
};


export type Query_RootC_DeckType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_DeckType_Order_By>>;
  where?: Maybe<C_DeckType_Bool_Exp>;
};


export type Query_RootC_DeckType_By_PkArgs = {
  DeckTypeId: Scalars['Int'];
};


export type Query_RootC_ExistingRoofCoverArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofCover_Order_By>>;
  where?: Maybe<C_ExistingRoofCover_Bool_Exp>;
};


export type Query_RootC_ExistingRoofCover_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofCover_Order_By>>;
  where?: Maybe<C_ExistingRoofCover_Bool_Exp>;
};


export type Query_RootC_ExistingRoofCover_By_PkArgs = {
  ExistingRoofCoverId: Scalars['Int'];
};


export type Query_RootC_ExistingRoofDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofDeck_Order_By>>;
  where?: Maybe<C_ExistingRoofDeck_Bool_Exp>;
};


export type Query_RootC_ExistingRoofDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofDeck_Order_By>>;
  where?: Maybe<C_ExistingRoofDeck_Bool_Exp>;
};


export type Query_RootC_ExistingRoofDeck_By_PkArgs = {
  ExistingRoofDeckId: Scalars['Int'];
};


export type Query_RootC_ExistingRoofInsulationMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofInsulationMaterial_Order_By>>;
  where?: Maybe<C_ExistingRoofInsulationMaterial_Bool_Exp>;
};


export type Query_RootC_ExistingRoofInsulationMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofInsulationMaterial_Order_By>>;
  where?: Maybe<C_ExistingRoofInsulationMaterial_Bool_Exp>;
};


export type Query_RootC_ExistingRoofInsulationMaterial_By_PkArgs = {
  ExistingRoofInsulationMaterialId: Scalars['Int'];
};


export type Query_RootC_ExistingRoofSinglePlyMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofSinglePlyMaterial_Order_By>>;
  where?: Maybe<C_ExistingRoofSinglePlyMaterial_Bool_Exp>;
};


export type Query_RootC_ExistingRoofSinglePlyMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofSinglePlyMaterial_Order_By>>;
  where?: Maybe<C_ExistingRoofSinglePlyMaterial_Bool_Exp>;
};


export type Query_RootC_ExistingRoofSinglePlyMaterial_By_PkArgs = {
  ExistingRoofSinglePlyMaterialId: Scalars['Int'];
};


export type Query_RootC_ExistingRoofSurfaceArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofSurface_Order_By>>;
  where?: Maybe<C_ExistingRoofSurface_Bool_Exp>;
};


export type Query_RootC_ExistingRoofSurface_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofSurface_Order_By>>;
  where?: Maybe<C_ExistingRoofSurface_Bool_Exp>;
};


export type Query_RootC_ExistingRoofSurface_By_PkArgs = {
  ExistingRoofSurfaceId: Scalars['Int'];
};


export type Query_RootC_ExtFireRatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExtFireRating_Order_By>>;
  where?: Maybe<C_ExtFireRating_Bool_Exp>;
};


export type Query_RootC_ExtFireRating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExtFireRating_Order_By>>;
  where?: Maybe<C_ExtFireRating_Bool_Exp>;
};


export type Query_RootC_ExtFireRating_By_PkArgs = {
  ExtFireRatingId: Scalars['Int'];
};


export type Query_RootC_FacerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Facer_Order_By>>;
  where?: Maybe<C_Facer_Bool_Exp>;
};


export type Query_RootC_FacerMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FacerMaterial_Order_By>>;
  where?: Maybe<C_FacerMaterial_Bool_Exp>;
};


export type Query_RootC_FacerMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FacerMaterial_Order_By>>;
  where?: Maybe<C_FacerMaterial_Bool_Exp>;
};


export type Query_RootC_FacerMaterial_By_PkArgs = {
  FacerMaterialId: Scalars['Int'];
};


export type Query_RootC_Facer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Facer_Order_By>>;
  where?: Maybe<C_Facer_Bool_Exp>;
};


export type Query_RootC_Facer_By_PkArgs = {
  FacerId: Scalars['Int'];
};


export type Query_RootC_FastenerCoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FastenerCoating_Order_By>>;
  where?: Maybe<C_FastenerCoating_Bool_Exp>;
};


export type Query_RootC_FastenerCoating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FastenerCoating_Order_By>>;
  where?: Maybe<C_FastenerCoating_Bool_Exp>;
};


export type Query_RootC_FastenerCoating_By_PkArgs = {
  FastenerCoatingId: Scalars['Int'];
};


export type Query_RootC_FastenerDriveHeadArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FastenerDriveHead_Order_By>>;
  where?: Maybe<C_FastenerDriveHead_Bool_Exp>;
};


export type Query_RootC_FastenerDriveHead_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FastenerDriveHead_Order_By>>;
  where?: Maybe<C_FastenerDriveHead_Bool_Exp>;
};


export type Query_RootC_FastenerDriveHead_By_PkArgs = {
  FastenerDriveHeadId: Scalars['Int'];
};


export type Query_RootC_FastenerTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FastenerType_Order_By>>;
  where?: Maybe<C_FastenerType_Bool_Exp>;
};


export type Query_RootC_FastenerType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FastenerType_Order_By>>;
  where?: Maybe<C_FastenerType_Bool_Exp>;
};


export type Query_RootC_FastenerType_By_PkArgs = {
  FastenerTypeId: Scalars['Int'];
};


export type Query_RootC_FasteningApplicationMethodArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FasteningApplicationMethod_Order_By>>;
  where?: Maybe<C_FasteningApplicationMethod_Bool_Exp>;
};


export type Query_RootC_FasteningApplicationMethod_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FasteningApplicationMethod_Order_By>>;
  where?: Maybe<C_FasteningApplicationMethod_Bool_Exp>;
};


export type Query_RootC_FasteningApplicationMethod_By_PkArgs = {
  FasteningApplicationMethodId: Scalars['Int'];
};


export type Query_RootC_FlashingMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FlashingMaterial_Order_By>>;
  where?: Maybe<C_FlashingMaterial_Bool_Exp>;
};


export type Query_RootC_FlashingMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FlashingMaterial_Order_By>>;
  where?: Maybe<C_FlashingMaterial_Bool_Exp>;
};


export type Query_RootC_FlashingMaterial_By_PkArgs = {
  FlashingMaterialId: Scalars['Int'];
};


export type Query_RootC_FlashingTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FlashingType_Order_By>>;
  where?: Maybe<C_FlashingType_Bool_Exp>;
};


export type Query_RootC_FlashingType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FlashingType_Order_By>>;
  where?: Maybe<C_FlashingType_Bool_Exp>;
};


export type Query_RootC_FlashingType_By_PkArgs = {
  FlashingTypeId: Scalars['Int'];
};


export type Query_RootC_FormDeckMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FormDeckMaterial_Order_By>>;
  where?: Maybe<C_FormDeckMaterial_Bool_Exp>;
};


export type Query_RootC_FormDeckMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FormDeckMaterial_Order_By>>;
  where?: Maybe<C_FormDeckMaterial_Bool_Exp>;
};


export type Query_RootC_FormDeckMaterial_By_PkArgs = {
  FormDeckMaterialId: Scalars['Int'];
};


export type Query_RootC_GroundRoughnessRatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_GroundRoughnessRating_Order_By>>;
  where?: Maybe<C_GroundRoughnessRating_Bool_Exp>;
};


export type Query_RootC_GroundRoughnessRating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_GroundRoughnessRating_Order_By>>;
  where?: Maybe<C_GroundRoughnessRating_Bool_Exp>;
};


export type Query_RootC_GroundRoughnessRating_By_PkArgs = {
  GroundRoughnessRatingId: Scalars['Int'];
};


export type Query_RootC_GutterMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_GutterMaterial_Order_By>>;
  where?: Maybe<C_GutterMaterial_Bool_Exp>;
};


export type Query_RootC_GutterMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_GutterMaterial_Order_By>>;
  where?: Maybe<C_GutterMaterial_Bool_Exp>;
};


export type Query_RootC_GutterMaterial_By_PkArgs = {
  GutterMaterialId: Scalars['Int'];
};


export type Query_RootC_HailRatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_HailRating_Order_By>>;
  where?: Maybe<C_HailRating_Bool_Exp>;
};


export type Query_RootC_HailRating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_HailRating_Order_By>>;
  where?: Maybe<C_HailRating_Bool_Exp>;
};


export type Query_RootC_HailRating_By_PkArgs = {
  HailRatingId: Scalars['Int'];
};


export type Query_RootC_IndustryTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_IndustryType_Order_By>>;
  where?: Maybe<C_IndustryType_Bool_Exp>;
};


export type Query_RootC_IndustryType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_IndustryType_Order_By>>;
  where?: Maybe<C_IndustryType_Bool_Exp>;
};


export type Query_RootC_IndustryType_By_PkArgs = {
  IndustryTypeId: Scalars['Int'];
};


export type Query_RootC_InsulationCoreArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_InsulationCore_Order_By>>;
  where?: Maybe<C_InsulationCore_Bool_Exp>;
};


export type Query_RootC_InsulationCore_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_InsulationCore_Order_By>>;
  where?: Maybe<C_InsulationCore_Bool_Exp>;
};


export type Query_RootC_InsulationCore_By_PkArgs = {
  InsulationCoreId: Scalars['Int'];
};


export type Query_RootC_InsulationFoamTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_InsulationFoamType_Order_By>>;
  where?: Maybe<C_InsulationFoamType_Bool_Exp>;
};


export type Query_RootC_InsulationFoamType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_InsulationFoamType_Order_By>>;
  where?: Maybe<C_InsulationFoamType_Bool_Exp>;
};


export type Query_RootC_InsulationFoamType_By_PkArgs = {
  InsulationFoamTypeId: Scalars['Int'];
};


export type Query_RootC_IntFireRatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_IntFireRating_Order_By>>;
  where?: Maybe<C_IntFireRating_Bool_Exp>;
};


export type Query_RootC_IntFireRating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_IntFireRating_Order_By>>;
  where?: Maybe<C_IntFireRating_Bool_Exp>;
};


export type Query_RootC_IntFireRating_By_PkArgs = {
  IntFireRatingId: Scalars['Int'];
};


export type Query_RootC_LapSealantTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LapSealantType_Order_By>>;
  where?: Maybe<C_LapSealantType_Bool_Exp>;
};


export type Query_RootC_LapSealantType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LapSealantType_Order_By>>;
  where?: Maybe<C_LapSealantType_Bool_Exp>;
};


export type Query_RootC_LapSealantType_By_PkArgs = {
  LapSealantTypeId: Scalars['Int'];
};


export type Query_RootC_LayerTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LayerType_Order_By>>;
  where?: Maybe<C_LayerType_Bool_Exp>;
};


export type Query_RootC_LayerType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LayerType_Order_By>>;
  where?: Maybe<C_LayerType_Bool_Exp>;
};


export type Query_RootC_LayerType_By_PkArgs = {
  LayerTypeId: Scalars['Int'];
};


export type Query_RootC_LinerPanelMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LinerPanelMaterial_Order_By>>;
  where?: Maybe<C_LinerPanelMaterial_Bool_Exp>;
};


export type Query_RootC_LinerPanelMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LinerPanelMaterial_Order_By>>;
  where?: Maybe<C_LinerPanelMaterial_Bool_Exp>;
};


export type Query_RootC_LinerPanelMaterial_By_PkArgs = {
  LinerPanelMaterialId: Scalars['Int'];
};


export type Query_RootC_LiquidApplicationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LiquidApplication_Order_By>>;
  where?: Maybe<C_LiquidApplication_Bool_Exp>;
};


export type Query_RootC_LiquidApplication_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LiquidApplication_Order_By>>;
  where?: Maybe<C_LiquidApplication_Bool_Exp>;
};


export type Query_RootC_LiquidApplication_By_PkArgs = {
  LiquidApplicationId: Scalars['Int'];
};


export type Query_RootC_LiquidAppliedMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LiquidAppliedMaterial_Order_By>>;
  where?: Maybe<C_LiquidAppliedMaterial_Bool_Exp>;
};


export type Query_RootC_LiquidAppliedMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LiquidAppliedMaterial_Order_By>>;
  where?: Maybe<C_LiquidAppliedMaterial_Bool_Exp>;
};


export type Query_RootC_LiquidAppliedMaterial_By_PkArgs = {
  LiquidAppliedMaterialId: Scalars['Int'];
};


export type Query_RootC_LockStatusArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LockStatus_Order_By>>;
  where?: Maybe<C_LockStatus_Bool_Exp>;
};


export type Query_RootC_LockStatus_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LockStatus_Order_By>>;
  where?: Maybe<C_LockStatus_Bool_Exp>;
};


export type Query_RootC_LockStatus_By_PkArgs = {
  LockStatusId: Scalars['Int'];
};


export type Query_RootC_MdAttributeTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MdAttributeType_Order_By>>;
  where?: Maybe<C_MdAttributeType_Bool_Exp>;
};


export type Query_RootC_MdAttributeType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MdAttributeType_Order_By>>;
  where?: Maybe<C_MdAttributeType_Bool_Exp>;
};


export type Query_RootC_MdAttributeType_By_PkArgs = {
  MDAttributeTypeId: Scalars['Int'];
};


export type Query_RootC_MaintenanceCoatingMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MaintenanceCoatingMaterial_Order_By>>;
  where?: Maybe<C_MaintenanceCoatingMaterial_Bool_Exp>;
};


export type Query_RootC_MaintenanceCoatingMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MaintenanceCoatingMaterial_Order_By>>;
  where?: Maybe<C_MaintenanceCoatingMaterial_Bool_Exp>;
};


export type Query_RootC_MaintenanceCoatingMaterial_By_PkArgs = {
  MaintenanceCoatingMaterialId: Scalars['Int'];
};


export type Query_RootC_MaintenanceCoatingSubstrateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<C_MaintenanceCoatingSubstrate_Bool_Exp>;
};


export type Query_RootC_MaintenanceCoatingSubstrate_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<C_MaintenanceCoatingSubstrate_Bool_Exp>;
};


export type Query_RootC_MaintenanceCoatingSubstrate_By_PkArgs = {
  MaintenanceCoatingSubstrateId: Scalars['Int'];
};


export type Query_RootC_MembraneTerminationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MembraneTermination_Order_By>>;
  where?: Maybe<C_MembraneTermination_Bool_Exp>;
};


export type Query_RootC_MembraneTermination_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MembraneTermination_Order_By>>;
  where?: Maybe<C_MembraneTermination_Bool_Exp>;
};


export type Query_RootC_MembraneTermination_By_PkArgs = {
  MembraneTerminationId: Scalars['Int'];
};


export type Query_RootC_MultiplyMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MultiplyMaterial_Order_By>>;
  where?: Maybe<C_MultiplyMaterial_Bool_Exp>;
};


export type Query_RootC_MultiplyMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MultiplyMaterial_Order_By>>;
  where?: Maybe<C_MultiplyMaterial_Bool_Exp>;
};


export type Query_RootC_MultiplyMaterial_By_PkArgs = {
  MultiplyMaterialId: Scalars['Int'];
};


export type Query_RootC_PvApplicationMethodArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PvApplicationMethod_Order_By>>;
  where?: Maybe<C_PvApplicationMethod_Bool_Exp>;
};


export type Query_RootC_PvApplicationMethod_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PvApplicationMethod_Order_By>>;
  where?: Maybe<C_PvApplicationMethod_Bool_Exp>;
};


export type Query_RootC_PvApplicationMethod_By_PkArgs = {
  PVApplicationMethodId: Scalars['Int'];
};


export type Query_RootC_PvHailRatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PvHailRating_Order_By>>;
  where?: Maybe<C_PvHailRating_Bool_Exp>;
};


export type Query_RootC_PvHailRating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PvHailRating_Order_By>>;
  where?: Maybe<C_PvHailRating_Bool_Exp>;
};


export type Query_RootC_PvHailRating_By_PkArgs = {
  PVHailRatingId: Scalars['Int'];
};


export type Query_RootC_PerimeterFlashingTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PerimeterFlashingType_Order_By>>;
  where?: Maybe<C_PerimeterFlashingType_Bool_Exp>;
};


export type Query_RootC_PerimeterFlashingType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PerimeterFlashingType_Order_By>>;
  where?: Maybe<C_PerimeterFlashingType_Bool_Exp>;
};


export type Query_RootC_PerimeterFlashingType_By_PkArgs = {
  PerimeterFlashingTypeId: Scalars['Int'];
};


export type Query_RootC_PressureZoneArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PressureZone_Order_By>>;
  where?: Maybe<C_PressureZone_Bool_Exp>;
};


export type Query_RootC_PressureZone_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PressureZone_Order_By>>;
  where?: Maybe<C_PressureZone_Bool_Exp>;
};


export type Query_RootC_PressureZone_By_PkArgs = {
  PressureZoneId: Scalars['Int'];
};


export type Query_RootC_ProductApprovedUseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ProductApprovedUse_Order_By>>;
  where?: Maybe<C_ProductApprovedUse_Bool_Exp>;
};


export type Query_RootC_ProductApprovedUse_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ProductApprovedUse_Order_By>>;
  where?: Maybe<C_ProductApprovedUse_Bool_Exp>;
};


export type Query_RootC_ProjectRoleArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ProjectRole_Order_By>>;
  where?: Maybe<C_ProjectRole_Bool_Exp>;
};


export type Query_RootC_ProjectRole_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ProjectRole_Order_By>>;
  where?: Maybe<C_ProjectRole_Bool_Exp>;
};


export type Query_RootC_ProjectRole_By_PkArgs = {
  ProjectRoleId: Scalars['Int'];
};


export type Query_RootC_ReinforcementArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Reinforcement_Order_By>>;
  where?: Maybe<C_Reinforcement_Bool_Exp>;
};


export type Query_RootC_Reinforcement_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Reinforcement_Order_By>>;
  where?: Maybe<C_Reinforcement_Bool_Exp>;
};


export type Query_RootC_Reinforcement_By_PkArgs = {
  ReinforcementId: Scalars['Int'];
};


export type Query_RootC_RetarderMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_RetarderMaterial_Order_By>>;
  where?: Maybe<C_RetarderMaterial_Bool_Exp>;
};


export type Query_RootC_RetarderMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_RetarderMaterial_Order_By>>;
  where?: Maybe<C_RetarderMaterial_Bool_Exp>;
};


export type Query_RootC_RetarderMaterial_By_PkArgs = {
  RetarderMaterialId: Scalars['Int'];
};


export type Query_RootC_ScenarioStatusArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ScenarioStatus_Order_By>>;
  where?: Maybe<C_ScenarioStatus_Bool_Exp>;
};


export type Query_RootC_ScenarioStatus_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ScenarioStatus_Order_By>>;
  where?: Maybe<C_ScenarioStatus_Bool_Exp>;
};


export type Query_RootC_ScenarioStatus_By_PkArgs = {
  ScenarioStatusId: Scalars['Int'];
};


export type Query_RootC_ScenarioTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ScenarioType_Order_By>>;
  where?: Maybe<C_ScenarioType_Bool_Exp>;
};


export type Query_RootC_ScenarioType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ScenarioType_Order_By>>;
  where?: Maybe<C_ScenarioType_Bool_Exp>;
};


export type Query_RootC_ScenarioType_By_PkArgs = {
  ScenarioTypeId: Scalars['Int'];
};


export type Query_RootC_SeamPrimerTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SeamPrimerType_Order_By>>;
  where?: Maybe<C_SeamPrimerType_Bool_Exp>;
};


export type Query_RootC_SeamPrimerType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SeamPrimerType_Order_By>>;
  where?: Maybe<C_SeamPrimerType_Bool_Exp>;
};


export type Query_RootC_SeamPrimerType_By_PkArgs = {
  SeamPrimerTypeId: Scalars['Int'];
};


export type Query_RootC_SeamWashTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SeamWashType_Order_By>>;
  where?: Maybe<C_SeamWashType_Bool_Exp>;
};


export type Query_RootC_SeamWashType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SeamWashType_Order_By>>;
  where?: Maybe<C_SeamWashType_Bool_Exp>;
};


export type Query_RootC_SeamWashType_By_PkArgs = {
  SeamWashTypeId: Scalars['Int'];
};


export type Query_RootC_SecurementMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SecurementMaterial_Order_By>>;
  where?: Maybe<C_SecurementMaterial_Bool_Exp>;
};


export type Query_RootC_SecurementMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SecurementMaterial_Order_By>>;
  where?: Maybe<C_SecurementMaterial_Bool_Exp>;
};


export type Query_RootC_SecurementMaterial_By_PkArgs = {
  SecurementMaterialId: Scalars['Int'];
};


export type Query_RootC_ShinglesHailArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesHail_Order_By>>;
  where?: Maybe<C_ShinglesHail_Bool_Exp>;
};


export type Query_RootC_ShinglesHail_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesHail_Order_By>>;
  where?: Maybe<C_ShinglesHail_Bool_Exp>;
};


export type Query_RootC_ShinglesHail_By_PkArgs = {
  ShinglesHailId: Scalars['Int'];
};


export type Query_RootC_ShinglesMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesMaterial_Order_By>>;
  where?: Maybe<C_ShinglesMaterial_Bool_Exp>;
};


export type Query_RootC_ShinglesMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesMaterial_Order_By>>;
  where?: Maybe<C_ShinglesMaterial_Bool_Exp>;
};


export type Query_RootC_ShinglesMaterial_By_PkArgs = {
  ShinglesMaterialId: Scalars['Int'];
};


export type Query_RootC_ShinglesReinforcementArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesReinforcement_Order_By>>;
  where?: Maybe<C_ShinglesReinforcement_Bool_Exp>;
};


export type Query_RootC_ShinglesReinforcement_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesReinforcement_Order_By>>;
  where?: Maybe<C_ShinglesReinforcement_Bool_Exp>;
};


export type Query_RootC_ShinglesReinforcement_By_PkArgs = {
  ShinglesReinforcementId: Scalars['Int'];
};


export type Query_RootC_ShinglesSurfacingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesSurfacing_Order_By>>;
  where?: Maybe<C_ShinglesSurfacing_Bool_Exp>;
};


export type Query_RootC_ShinglesSurfacing_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesSurfacing_Order_By>>;
  where?: Maybe<C_ShinglesSurfacing_Bool_Exp>;
};


export type Query_RootC_ShinglesSurfacing_By_PkArgs = {
  ShinglesSurfacingId: Scalars['Int'];
};


export type Query_RootC_ShinglesWindSpeedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesWindSpeed_Order_By>>;
  where?: Maybe<C_ShinglesWindSpeed_Bool_Exp>;
};


export type Query_RootC_ShinglesWindSpeed_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesWindSpeed_Order_By>>;
  where?: Maybe<C_ShinglesWindSpeed_Bool_Exp>;
};


export type Query_RootC_ShinglesWindSpeed_By_PkArgs = {
  ShinglesWindSpeedId: Scalars['Int'];
};


export type Query_RootC_SinglePlyMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SinglePlyMaterial_Order_By>>;
  where?: Maybe<C_SinglePlyMaterial_Bool_Exp>;
};


export type Query_RootC_SinglePlyMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SinglePlyMaterial_Order_By>>;
  where?: Maybe<C_SinglePlyMaterial_Bool_Exp>;
};


export type Query_RootC_SinglePlyMaterial_By_PkArgs = {
  SinglePlyMaterialId: Scalars['Int'];
};


export type Query_RootC_StandingLapSeamMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StandingLapSeamMaterial_Order_By>>;
  where?: Maybe<C_StandingLapSeamMaterial_Bool_Exp>;
};


export type Query_RootC_StandingLapSeamMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StandingLapSeamMaterial_Order_By>>;
  where?: Maybe<C_StandingLapSeamMaterial_Bool_Exp>;
};


export type Query_RootC_StandingLapSeamMaterial_By_PkArgs = {
  StandingLapSeamMaterialId: Scalars['Int'];
};


export type Query_RootC_StateProvinceArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StateProvince_Order_By>>;
  where?: Maybe<C_StateProvince_Bool_Exp>;
};


export type Query_RootC_StateProvince_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StateProvince_Order_By>>;
  where?: Maybe<C_StateProvince_Bool_Exp>;
};


export type Query_RootC_StateProvince_By_PkArgs = {
  StateProvinceId: Scalars['Int'];
};


export type Query_RootC_StatusArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Status_Order_By>>;
  where?: Maybe<C_Status_Bool_Exp>;
};


export type Query_RootC_Status_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Status_Order_By>>;
  where?: Maybe<C_Status_Bool_Exp>;
};


export type Query_RootC_Status_By_PkArgs = {
  StatusId: Scalars['Int'];
};


export type Query_RootC_SteelDeckRibTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SteelDeckRibType_Order_By>>;
  where?: Maybe<C_SteelDeckRibType_Bool_Exp>;
};


export type Query_RootC_SteelDeckRibType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SteelDeckRibType_Order_By>>;
  where?: Maybe<C_SteelDeckRibType_Bool_Exp>;
};


export type Query_RootC_SteelDeckRibType_By_PkArgs = {
  SteelDeckRibTypeId: Scalars['Int'];
};


export type Query_RootC_StressDistributorTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StressDistributorType_Order_By>>;
  where?: Maybe<C_StressDistributorType_Bool_Exp>;
};


export type Query_RootC_StressDistributorType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StressDistributorType_Order_By>>;
  where?: Maybe<C_StressDistributorType_Bool_Exp>;
};


export type Query_RootC_StressDistributorType_By_PkArgs = {
  StressDistributorTypeId: Scalars['Int'];
};


export type Query_RootC_StressPlateShapeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StressPlateShape_Order_By>>;
  where?: Maybe<C_StressPlateShape_Bool_Exp>;
};


export type Query_RootC_StressPlateShape_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StressPlateShape_Order_By>>;
  where?: Maybe<C_StressPlateShape_Bool_Exp>;
};


export type Query_RootC_StressPlateShape_By_PkArgs = {
  StressPlateShapeId: Scalars['Int'];
};


export type Query_RootC_StructureTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StructureType_Order_By>>;
  where?: Maybe<C_StructureType_Bool_Exp>;
};


export type Query_RootC_StructureType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StructureType_Order_By>>;
  where?: Maybe<C_StructureType_Bool_Exp>;
};


export type Query_RootC_StructureType_By_PkArgs = {
  StructureTypeId: Scalars['Int'];
};


export type Query_RootC_SubassemblyTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SubassemblyType_Order_By>>;
  where?: Maybe<C_SubassemblyType_Bool_Exp>;
};


export type Query_RootC_SubassemblyType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SubassemblyType_Order_By>>;
  where?: Maybe<C_SubassemblyType_Bool_Exp>;
};


export type Query_RootC_SubassemblyType_By_PkArgs = {
  SubassemblyTypeId: Scalars['Int'];
};


export type Query_RootC_SubstrateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Substrate_Order_By>>;
  where?: Maybe<C_Substrate_Bool_Exp>;
};


export type Query_RootC_SubstratePrimerMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SubstratePrimerMaterial_Order_By>>;
  where?: Maybe<C_SubstratePrimerMaterial_Bool_Exp>;
};


export type Query_RootC_SubstratePrimerMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SubstratePrimerMaterial_Order_By>>;
  where?: Maybe<C_SubstratePrimerMaterial_Bool_Exp>;
};


export type Query_RootC_SubstratePrimerMaterial_By_PkArgs = {
  SubstratePrimerMaterialId: Scalars['Int'];
};


export type Query_RootC_Substrate_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Substrate_Order_By>>;
  where?: Maybe<C_Substrate_Bool_Exp>;
};


export type Query_RootC_Substrate_By_PkArgs = {
  SubstrateId: Scalars['Int'];
};


export type Query_RootC_SurfaceTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SurfaceType_Order_By>>;
  where?: Maybe<C_SurfaceType_Bool_Exp>;
};


export type Query_RootC_SurfaceType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SurfaceType_Order_By>>;
  where?: Maybe<C_SurfaceType_Bool_Exp>;
};


export type Query_RootC_SurfaceType_By_PkArgs = {
  SurfaceTypeId: Scalars['Int'];
};


export type Query_RootC_SurfacingMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SurfacingMaterial_Order_By>>;
  where?: Maybe<C_SurfacingMaterial_Bool_Exp>;
};


export type Query_RootC_SurfacingMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SurfacingMaterial_Order_By>>;
  where?: Maybe<C_SurfacingMaterial_Bool_Exp>;
};


export type Query_RootC_SurfacingMaterial_By_PkArgs = {
  SurfacingMaterialId: Scalars['Int'];
};


export type Query_RootC_SystemApprovedUseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SystemApprovedUse_Order_By>>;
  where?: Maybe<C_SystemApprovedUse_Bool_Exp>;
};


export type Query_RootC_SystemApprovedUse_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SystemApprovedUse_Order_By>>;
  where?: Maybe<C_SystemApprovedUse_Bool_Exp>;
};


export type Query_RootC_ThermalBarrierMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ThermalBarrierMaterial_Order_By>>;
  where?: Maybe<C_ThermalBarrierMaterial_Bool_Exp>;
};


export type Query_RootC_ThermalBarrierMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ThermalBarrierMaterial_Order_By>>;
  where?: Maybe<C_ThermalBarrierMaterial_Bool_Exp>;
};


export type Query_RootC_ThermalBarrierMaterial_By_PkArgs = {
  ThermalBarrierMaterialId: Scalars['Int'];
};


export type Query_RootC_WipDelistReasonArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WipDelistReason_Order_By>>;
  where?: Maybe<C_WipDelistReason_Bool_Exp>;
};


export type Query_RootC_WipDelistReason_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WipDelistReason_Order_By>>;
  where?: Maybe<C_WipDelistReason_Bool_Exp>;
};


export type Query_RootC_WipDelistReason_By_PkArgs = {
  WIPDelistReasonId: Scalars['Int'];
};


export type Query_RootC_WipOnHoldReasonArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WipOnHoldReason_Order_By>>;
  where?: Maybe<C_WipOnHoldReason_Bool_Exp>;
};


export type Query_RootC_WipOnHoldReason_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WipOnHoldReason_Order_By>>;
  where?: Maybe<C_WipOnHoldReason_Bool_Exp>;
};


export type Query_RootC_WipOnHoldReason_By_PkArgs = {
  WIPOnHoldReasonId: Scalars['Int'];
};


export type Query_RootC_WipStatusArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WipStatus_Order_By>>;
  where?: Maybe<C_WipStatus_Bool_Exp>;
};


export type Query_RootC_WipStatus_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WipStatus_Order_By>>;
  where?: Maybe<C_WipStatus_Bool_Exp>;
};


export type Query_RootC_WipStatus_By_PkArgs = {
  WIPStatusId: Scalars['Int'];
};


export type Query_RootC_WeldApplicationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WeldApplication_Order_By>>;
  where?: Maybe<C_WeldApplication_Bool_Exp>;
};


export type Query_RootC_WeldApplication_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WeldApplication_Order_By>>;
  where?: Maybe<C_WeldApplication_Bool_Exp>;
};


export type Query_RootC_WeldApplication_By_PkArgs = {
  WeldApplicationId: Scalars['Int'];
};


export type Query_RootC_WeldConfigurationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WeldConfiguration_Order_By>>;
  where?: Maybe<C_WeldConfiguration_Bool_Exp>;
};


export type Query_RootC_WeldConfiguration_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WeldConfiguration_Order_By>>;
  where?: Maybe<C_WeldConfiguration_Bool_Exp>;
};


export type Query_RootC_WeldConfiguration_By_PkArgs = {
  WeldConfigurationId: Scalars['Int'];
};


export type Query_RootC_WindBorneDebrisArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WindBorneDebris_Order_By>>;
  where?: Maybe<C_WindBorneDebris_Bool_Exp>;
};


export type Query_RootC_WindBorneDebris_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WindBorneDebris_Order_By>>;
  where?: Maybe<C_WindBorneDebris_Bool_Exp>;
};


export type Query_RootC_WindBorneDebris_By_PkArgs = {
  WindBorneDebrisId: Scalars['Int'];
};


export type Query_RootC_WoodSpeciesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WoodSpecies_Order_By>>;
  where?: Maybe<C_WoodSpecies_Bool_Exp>;
};


export type Query_RootC_WoodSpecies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WoodSpecies_Order_By>>;
  where?: Maybe<C_WoodSpecies_Bool_Exp>;
};


export type Query_RootC_WoodSpecies_By_PkArgs = {
  WoodSpeciesId: Scalars['Int'];
};


export type Query_RootC_WoodTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WoodType_Order_By>>;
  where?: Maybe<C_WoodType_Bool_Exp>;
};


export type Query_RootC_WoodType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WoodType_Order_By>>;
  where?: Maybe<C_WoodType_Bool_Exp>;
};


export type Query_RootC_WoodType_By_PkArgs = {
  WoodTypeId: Scalars['Int'];
};


export type Query_RootC_WorkflowStatusArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WorkflowStatus_Order_By>>;
  where?: Maybe<C_WorkflowStatus_Bool_Exp>;
};


export type Query_RootC_WorkflowStatus_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WorkflowStatus_Order_By>>;
  where?: Maybe<C_WorkflowStatus_Bool_Exp>;
};


export type Query_RootC_WorkflowStatus_By_PkArgs = {
  WorkflowStatusId: Scalars['Int'];
};


export type Query_RootCapPlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPly_Order_By>>;
  where?: Maybe<CapPly_Bool_Exp>;
};


export type Query_RootCapPlyUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPlyUsage_Order_By>>;
  where?: Maybe<CapPlyUsage_Bool_Exp>;
};


export type Query_RootCapPlyUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPlyUsage_Order_By>>;
  where?: Maybe<CapPlyUsage_Bool_Exp>;
};


export type Query_RootCapPlyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootCapPly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPly_Order_By>>;
  where?: Maybe<CapPly_Bool_Exp>;
};


export type Query_RootCapPly_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootCategoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Order_By>>;
  where?: Maybe<Category_Bool_Exp>;
};


export type Query_RootCategoryLogArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CategoryLog_Order_By>>;
  where?: Maybe<CategoryLog_Bool_Exp>;
};


export type Query_RootCategoryLog_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CategoryLog_Order_By>>;
  where?: Maybe<CategoryLog_Bool_Exp>;
};


export type Query_RootCategoryLog_By_PkArgs = {
  CategoryLogID: Scalars['Int'];
};


export type Query_RootCategory_CoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Coating_Order_By>>;
  where?: Maybe<Category_Coating_Bool_Exp>;
};


export type Query_RootCategory_Coating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Coating_Order_By>>;
  where?: Maybe<Category_Coating_Bool_Exp>;
};


export type Query_RootCategory_CoverArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Cover_Order_By>>;
  where?: Maybe<Category_Cover_Bool_Exp>;
};


export type Query_RootCategory_Cover_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Cover_Order_By>>;
  where?: Maybe<Category_Cover_Bool_Exp>;
};


export type Query_RootCategory_DeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Deck_Order_By>>;
  where?: Maybe<Category_Deck_Bool_Exp>;
};


export type Query_RootCategory_Deck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Deck_Order_By>>;
  where?: Maybe<Category_Deck_Bool_Exp>;
};


export type Query_RootCategory_FlashingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Flashing_Order_By>>;
  where?: Maybe<Category_Flashing_Bool_Exp>;
};


export type Query_RootCategory_Flashing_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Flashing_Order_By>>;
  where?: Maybe<Category_Flashing_Bool_Exp>;
};


export type Query_RootCategory_InsulationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Insulation_Order_By>>;
  where?: Maybe<Category_Insulation_Bool_Exp>;
};


export type Query_RootCategory_Insulation_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Insulation_Order_By>>;
  where?: Maybe<Category_Insulation_Bool_Exp>;
};


export type Query_RootCategory_OtherArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Other_Order_By>>;
  where?: Maybe<Category_Other_Bool_Exp>;
};


export type Query_RootCategory_Other_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Other_Order_By>>;
  where?: Maybe<Category_Other_Bool_Exp>;
};


export type Query_RootCategory_SeamingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Seaming_Order_By>>;
  where?: Maybe<Category_Seaming_Bool_Exp>;
};


export type Query_RootCategory_Seaming_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Seaming_Order_By>>;
  where?: Maybe<Category_Seaming_Bool_Exp>;
};


export type Query_RootCategory_SecurementArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Securement_Order_By>>;
  where?: Maybe<Category_Securement_Bool_Exp>;
};


export type Query_RootCategory_Securement_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Securement_Order_By>>;
  where?: Maybe<Category_Securement_Bool_Exp>;
};


export type Query_RootCategory_SheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Sheet_Order_By>>;
  where?: Maybe<Category_Sheet_Bool_Exp>;
};


export type Query_RootCategory_Sheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Sheet_Order_By>>;
  where?: Maybe<Category_Sheet_Bool_Exp>;
};


export type Query_RootCategory_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Order_By>>;
  where?: Maybe<Category_Bool_Exp>;
};


export type Query_RootCategory_By_PkArgs = {
  CategoryID: Scalars['Int'];
};


export type Query_RootCementitiousWoodFiberDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CementitiousWoodFiberDeck_Order_By>>;
  where?: Maybe<CementitiousWoodFiberDeck_Bool_Exp>;
};


export type Query_RootCementitiousWoodFiberDeckUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CementitiousWoodFiberDeckUsage_Order_By>>;
  where?: Maybe<CementitiousWoodFiberDeckUsage_Bool_Exp>;
};


export type Query_RootCementitiousWoodFiberDeckUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CementitiousWoodFiberDeckUsage_Order_By>>;
  where?: Maybe<CementitiousWoodFiberDeckUsage_Bool_Exp>;
};


export type Query_RootCementitiousWoodFiberDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootCementitiousWoodFiberDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CementitiousWoodFiberDeck_Order_By>>;
  where?: Maybe<CementitiousWoodFiberDeck_Bool_Exp>;
};


export type Query_RootCementitiousWoodFiberDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootClipArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clip_Order_By>>;
  where?: Maybe<Clip_Bool_Exp>;
};


export type Query_RootClipUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClipUsage_Order_By>>;
  where?: Maybe<ClipUsage_Bool_Exp>;
};


export type Query_RootClipUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClipUsage_Order_By>>;
  where?: Maybe<ClipUsage_Bool_Exp>;
};


export type Query_RootClipUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootClip_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clip_Order_By>>;
  where?: Maybe<Clip_Bool_Exp>;
};


export type Query_RootClip_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootCoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coat_Order_By>>;
  where?: Maybe<Coat_Bool_Exp>;
};


export type Query_RootCoatUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoatUsage_Order_By>>;
  where?: Maybe<CoatUsage_Bool_Exp>;
};


export type Query_RootCoatUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoatUsage_Order_By>>;
  where?: Maybe<CoatUsage_Bool_Exp>;
};


export type Query_RootCoatUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootCoat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coat_Order_By>>;
  where?: Maybe<Coat_Bool_Exp>;
};


export type Query_RootCoat_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootCoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coating_Order_By>>;
  where?: Maybe<Coating_Bool_Exp>;
};


export type Query_RootCoatingSystemUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoatingSystemUsage_Order_By>>;
  where?: Maybe<CoatingSystemUsage_Bool_Exp>;
};


export type Query_RootCoatingSystemUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoatingSystemUsage_Order_By>>;
  where?: Maybe<CoatingSystemUsage_Bool_Exp>;
};


export type Query_RootCoatingSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootCoatingUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoatingUsage_Order_By>>;
  where?: Maybe<CoatingUsage_Bool_Exp>;
};


export type Query_RootCoatingUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoatingUsage_Order_By>>;
  where?: Maybe<CoatingUsage_Bool_Exp>;
};


export type Query_RootCoatingUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootCoating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coating_Order_By>>;
  where?: Maybe<Coating_Bool_Exp>;
};


export type Query_RootCoating_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootComponentArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Order_By>>;
  where?: Maybe<Component_Bool_Exp>;
};


export type Query_RootComponentApprovedUseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentApprovedUse_Order_By>>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};


export type Query_RootComponentApprovedUse_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentApprovedUse_Order_By>>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};


export type Query_RootComponentApprovedUse_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  ComponentId: Scalars['Int'];
};


export type Query_RootComponentDocArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentDoc_Order_By>>;
  where?: Maybe<ComponentDoc_Bool_Exp>;
};


export type Query_RootComponentDoc_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentDoc_Order_By>>;
  where?: Maybe<ComponentDoc_Bool_Exp>;
};


export type Query_RootComponentDoc_By_PkArgs = {
  ComponentDocId: Scalars['Int'];
};


export type Query_RootComponentLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentLayer_Order_By>>;
  where?: Maybe<ComponentLayer_Bool_Exp>;
};


export type Query_RootComponentLayerUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentLayerUsage_Order_By>>;
  where?: Maybe<ComponentLayerUsage_Bool_Exp>;
};


export type Query_RootComponentLayerUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentLayerUsage_Order_By>>;
  where?: Maybe<ComponentLayerUsage_Bool_Exp>;
};


export type Query_RootComponentLayerUsage_By_PkArgs = {
  LayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


export type Query_RootComponentLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentLayer_Order_By>>;
  where?: Maybe<ComponentLayer_Bool_Exp>;
};


export type Query_RootComponentLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


export type Query_RootComponent_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Order_By>>;
  where?: Maybe<Component_Bool_Exp>;
};


export type Query_RootComponent_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootCompositePanelCoverArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompositePanelCover_Order_By>>;
  where?: Maybe<CompositePanelCover_Bool_Exp>;
};


export type Query_RootCompositePanelCover_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompositePanelCover_Order_By>>;
  where?: Maybe<CompositePanelCover_Bool_Exp>;
};


export type Query_RootCompositePanelCover_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootCompositePanelUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompositePanelUsage_Order_By>>;
  where?: Maybe<CompositePanelUsage_Bool_Exp>;
};


export type Query_RootCompositePanelUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompositePanelUsage_Order_By>>;
  where?: Maybe<CompositePanelUsage_Bool_Exp>;
};


export type Query_RootCompositePanelUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootContactInfoArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactInfo_Order_By>>;
  where?: Maybe<ContactInfo_Bool_Exp>;
};


export type Query_RootContactInfo_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactInfo_Order_By>>;
  where?: Maybe<ContactInfo_Bool_Exp>;
};


export type Query_RootContactInfo_By_PkArgs = {
  ContactInfoId: Scalars['Int'];
};


export type Query_RootCopingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coping_Order_By>>;
  where?: Maybe<Coping_Bool_Exp>;
};


export type Query_RootCoping_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coping_Order_By>>;
  where?: Maybe<Coping_Bool_Exp>;
};


export type Query_RootCoping_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootCoverBoardArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoard_Order_By>>;
  where?: Maybe<CoverBoard_Bool_Exp>;
};


export type Query_RootCoverBoardBoardSizesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardBoardSizes_Order_By>>;
  where?: Maybe<CoverBoardBoardSizes_Bool_Exp>;
};


export type Query_RootCoverBoardBoardSizes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardBoardSizes_Order_By>>;
  where?: Maybe<CoverBoardBoardSizes_Bool_Exp>;
};


export type Query_RootCoverBoardBoardSizes_By_PkArgs = {
  ComponentId: Scalars['Int'];
  InsBoardSizeId: Scalars['Int'];
};


export type Query_RootCoverBoardFacerBottomArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerBottom_Order_By>>;
  where?: Maybe<CoverBoardFacerBottom_Bool_Exp>;
};


export type Query_RootCoverBoardFacerBottom_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerBottom_Order_By>>;
  where?: Maybe<CoverBoardFacerBottom_Bool_Exp>;
};


export type Query_RootCoverBoardFacerBottom_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


export type Query_RootCoverBoardFacerTopArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerTop_Order_By>>;
  where?: Maybe<CoverBoardFacerTop_Bool_Exp>;
};


export type Query_RootCoverBoardFacerTop_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerTop_Order_By>>;
  where?: Maybe<CoverBoardFacerTop_Bool_Exp>;
};


export type Query_RootCoverBoardFacerTop_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


export type Query_RootCoverBoardUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardUsage_Order_By>>;
  where?: Maybe<CoverBoardUsage_Bool_Exp>;
};


export type Query_RootCoverBoardUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardUsage_Order_By>>;
  where?: Maybe<CoverBoardUsage_Bool_Exp>;
};


export type Query_RootCoverBoardUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootCoverBoard_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoard_Order_By>>;
  where?: Maybe<CoverBoard_Bool_Exp>;
};


export type Query_RootCoverBoard_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootCoverStripDiskArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverStripDisk_Order_By>>;
  where?: Maybe<CoverStripDisk_Bool_Exp>;
};


export type Query_RootCoverStripDiskUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverStripDiskUsage_Order_By>>;
  where?: Maybe<CoverStripDiskUsage_Bool_Exp>;
};


export type Query_RootCoverStripDiskUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverStripDiskUsage_Order_By>>;
  where?: Maybe<CoverStripDiskUsage_Bool_Exp>;
};


export type Query_RootCoverStripDiskUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootCoverStripDisk_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverStripDisk_Order_By>>;
  where?: Maybe<CoverStripDisk_Bool_Exp>;
};


export type Query_RootCoverStripDisk_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootExistingRoofLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExistingRoofLayer_Order_By>>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};


export type Query_RootExistingRoofLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExistingRoofLayer_Order_By>>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};


export type Query_RootExistingRoofLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


export type Query_RootExpansionJointArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExpansionJoint_Order_By>>;
  where?: Maybe<ExpansionJoint_Bool_Exp>;
};


export type Query_RootExpansionJointUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExpansionJointUsage_Order_By>>;
  where?: Maybe<ExpansionJointUsage_Bool_Exp>;
};


export type Query_RootExpansionJointUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExpansionJointUsage_Order_By>>;
  where?: Maybe<ExpansionJointUsage_Bool_Exp>;
};


export type Query_RootExpansionJointUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootExpansionJoint_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExpansionJoint_Order_By>>;
  where?: Maybe<ExpansionJoint_Bool_Exp>;
};


export type Query_RootExpansionJoint_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootExtendedLayerConfigSplitArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtendedLayerConfigSplit_Order_By>>;
  where?: Maybe<ExtendedLayerConfigSplit_Bool_Exp>;
};


export type Query_RootExtendedLayerConfigSplit_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtendedLayerConfigSplit_Order_By>>;
  where?: Maybe<ExtendedLayerConfigSplit_Bool_Exp>;
};


export type Query_RootExtendedLayerConfigSplit_By_PkArgs = {
  ExtendedLayerConfigId: Scalars['Int'];
};


export type Query_RootFmUserAccountArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FmUserAccount_Order_By>>;
  where?: Maybe<FmUserAccount_Bool_Exp>;
};


export type Query_RootFmUserAccount_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FmUserAccount_Order_By>>;
  where?: Maybe<FmUserAccount_Bool_Exp>;
};


export type Query_RootFmUserAccount_By_PkArgs = {
  UserAccountId: Scalars['Int'];
};


export type Query_RootFasciaArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fascia_Order_By>>;
  where?: Maybe<Fascia_Bool_Exp>;
};


export type Query_RootFascia_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fascia_Order_By>>;
  where?: Maybe<Fascia_Bool_Exp>;
};


export type Query_RootFascia_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootFastenerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fastener_Order_By>>;
  where?: Maybe<Fastener_Bool_Exp>;
};


export type Query_RootFastenerUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FastenerUsage_Order_By>>;
  where?: Maybe<FastenerUsage_Bool_Exp>;
};


export type Query_RootFastenerUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FastenerUsage_Order_By>>;
  where?: Maybe<FastenerUsage_Bool_Exp>;
};


export type Query_RootFastenerUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootFastener_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fastener_Order_By>>;
  where?: Maybe<Fastener_Bool_Exp>;
};


export type Query_RootFastener_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootFasteningSystemBattenBarUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemBattenBarUsage_Order_By>>;
  where?: Maybe<FasteningSystemBattenBarUsage_Bool_Exp>;
};


export type Query_RootFasteningSystemBattenBarUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemBattenBarUsage_Order_By>>;
  where?: Maybe<FasteningSystemBattenBarUsage_Bool_Exp>;
};


export type Query_RootFasteningSystemBattenBarUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootFasteningSystemClipUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemClipUsage_Order_By>>;
  where?: Maybe<FasteningSystemClipUsage_Bool_Exp>;
};


export type Query_RootFasteningSystemClipUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemClipUsage_Order_By>>;
  where?: Maybe<FasteningSystemClipUsage_Bool_Exp>;
};


export type Query_RootFasteningSystemClipUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootFasteningSystemStressPlateUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemStressPlateUsage_Order_By>>;
  where?: Maybe<FasteningSystemStressPlateUsage_Bool_Exp>;
};


export type Query_RootFasteningSystemStressPlateUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemStressPlateUsage_Order_By>>;
  where?: Maybe<FasteningSystemStressPlateUsage_Bool_Exp>;
};


export type Query_RootFasteningSystemStressPlateUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootFiberReinforcedPlasticDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FiberReinforcedPlasticDeck_Order_By>>;
  where?: Maybe<FiberReinforcedPlasticDeck_Bool_Exp>;
};


export type Query_RootFiberReinforcedPlasticDeckUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FiberReinforcedPlasticDeckUsage_Order_By>>;
  where?: Maybe<FiberReinforcedPlasticDeckUsage_Bool_Exp>;
};


export type Query_RootFiberReinforcedPlasticDeckUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FiberReinforcedPlasticDeckUsage_Order_By>>;
  where?: Maybe<FiberReinforcedPlasticDeckUsage_Bool_Exp>;
};


export type Query_RootFiberReinforcedPlasticDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootFiberReinforcedPlasticDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FiberReinforcedPlasticDeck_Order_By>>;
  where?: Maybe<FiberReinforcedPlasticDeck_Bool_Exp>;
};


export type Query_RootFiberReinforcedPlasticDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootFormDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FormDeck_Order_By>>;
  where?: Maybe<FormDeck_Bool_Exp>;
};


export type Query_RootFormDeckUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FormDeckUsage_Order_By>>;
  where?: Maybe<FormDeckUsage_Bool_Exp>;
};


export type Query_RootFormDeckUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FormDeckUsage_Order_By>>;
  where?: Maybe<FormDeckUsage_Bool_Exp>;
};


export type Query_RootFormDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootFormDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FormDeck_Order_By>>;
  where?: Maybe<FormDeck_Bool_Exp>;
};


export type Query_RootFormDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootGutterArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gutter_Order_By>>;
  where?: Maybe<Gutter_Bool_Exp>;
};


export type Query_RootGutter_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gutter_Order_By>>;
  where?: Maybe<Gutter_Bool_Exp>;
};


export type Query_RootGutter_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootGypsumDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GypsumDeck_Order_By>>;
  where?: Maybe<GypsumDeck_Bool_Exp>;
};


export type Query_RootGypsumDeckUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GypsumDeckUsage_Order_By>>;
  where?: Maybe<GypsumDeckUsage_Bool_Exp>;
};


export type Query_RootGypsumDeckUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GypsumDeckUsage_Order_By>>;
  where?: Maybe<GypsumDeckUsage_Bool_Exp>;
};


export type Query_RootGypsumDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootGypsumDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GypsumDeck_Order_By>>;
  where?: Maybe<GypsumDeck_Bool_Exp>;
};


export type Query_RootGypsumDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootInsBoardSizeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<InsBoardSize_Order_By>>;
  where?: Maybe<InsBoardSize_Bool_Exp>;
};


export type Query_RootInsBoardSize_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<InsBoardSize_Order_By>>;
  where?: Maybe<InsBoardSize_Bool_Exp>;
};


export type Query_RootInsBoardSize_By_PkArgs = {
  InsBoardSizeId: Scalars['Int'];
};


export type Query_RootItemWorkspaceArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspace_Order_By>>;
  where?: Maybe<ItemWorkspace_Bool_Exp>;
};


export type Query_RootItemWorkspaceDataArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspaceData_Order_By>>;
  where?: Maybe<ItemWorkspaceData_Bool_Exp>;
};


export type Query_RootItemWorkspaceData_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspaceData_Order_By>>;
  where?: Maybe<ItemWorkspaceData_Bool_Exp>;
};


export type Query_RootItemWorkspaceData_By_PkArgs = {
  ItemWorkspaceDataId: Scalars['Int'];
};


export type Query_RootItemWorkspace_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspace_Order_By>>;
  where?: Maybe<ItemWorkspace_Bool_Exp>;
};


export type Query_RootItemWorkspace_By_PkArgs = {
  ItemWorkspaceId: Scalars['Int'];
};


export type Query_RootLapSealantArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSealant_Order_By>>;
  where?: Maybe<LapSealant_Bool_Exp>;
};


export type Query_RootLapSealantUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSealantUsage_Order_By>>;
  where?: Maybe<LapSealantUsage_Bool_Exp>;
};


export type Query_RootLapSealantUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSealantUsage_Order_By>>;
  where?: Maybe<LapSealantUsage_Bool_Exp>;
};


export type Query_RootLapSealantUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootLapSealant_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSealant_Order_By>>;
  where?: Maybe<LapSealant_Bool_Exp>;
};


export type Query_RootLapSealant_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootLapSecurementInfoArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSecurementInfo_Order_By>>;
  where?: Maybe<LapSecurementInfo_Bool_Exp>;
};


export type Query_RootLapSecurementInfo_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSecurementInfo_Order_By>>;
  where?: Maybe<LapSecurementInfo_Bool_Exp>;
};


export type Query_RootLapSecurementInfo_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Layer_Order_By>>;
  where?: Maybe<Layer_Bool_Exp>;
};


export type Query_RootLayerConfigArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LayerConfig_Order_By>>;
  where?: Maybe<LayerConfig_Bool_Exp>;
};


export type Query_RootLayerConfig_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LayerConfig_Order_By>>;
  where?: Maybe<LayerConfig_Bool_Exp>;
};


export type Query_RootLayerConfig_By_PkArgs = {
  LayerConfigId: Scalars['Int'];
};


export type Query_RootLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Layer_Order_By>>;
  where?: Maybe<Layer_Bool_Exp>;
};


export type Query_RootLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


export type Query_RootLightweightInsulatingConcreteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
};


export type Query_RootLightweightInsulatingConcreteSubassemblyUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcreteSubassemblyUsage_Order_By>>;
  where?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Bool_Exp>;
};


export type Query_RootLightweightInsulatingConcreteSubassemblyUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcreteSubassemblyUsage_Order_By>>;
  where?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Bool_Exp>;
};


export type Query_RootLightweightInsulatingConcreteSubassemblyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootLightweightInsulatingConcreteUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcreteUsage_Order_By>>;
  where?: Maybe<LightweightInsulatingConcreteUsage_Bool_Exp>;
};


export type Query_RootLightweightInsulatingConcreteUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcreteUsage_Order_By>>;
  where?: Maybe<LightweightInsulatingConcreteUsage_Bool_Exp>;
};


export type Query_RootLightweightInsulatingConcreteUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootLightweightInsulatingConcrete_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
};


export type Query_RootLightweightInsulatingConcrete_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootLinerPanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LinerPanel_Order_By>>;
  where?: Maybe<LinerPanel_Bool_Exp>;
};


export type Query_RootLinerPanelUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LinerPanelUsage_Order_By>>;
  where?: Maybe<LinerPanelUsage_Bool_Exp>;
};


export type Query_RootLinerPanelUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LinerPanelUsage_Order_By>>;
  where?: Maybe<LinerPanelUsage_Bool_Exp>;
};


export type Query_RootLinerPanelUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootLinerPanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LinerPanel_Order_By>>;
  where?: Maybe<LinerPanel_Bool_Exp>;
};


export type Query_RootLinerPanel_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootLiquidAppliedSubassemblyUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LiquidAppliedSubassemblyUsage_Order_By>>;
  where?: Maybe<LiquidAppliedSubassemblyUsage_Bool_Exp>;
};


export type Query_RootLiquidAppliedSubassemblyUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LiquidAppliedSubassemblyUsage_Order_By>>;
  where?: Maybe<LiquidAppliedSubassemblyUsage_Bool_Exp>;
};


export type Query_RootLiquidAppliedSubassemblyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootLiquidAppliedUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LiquidAppliedUsage_Order_By>>;
  where?: Maybe<LiquidAppliedUsage_Bool_Exp>;
};


export type Query_RootLiquidAppliedUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LiquidAppliedUsage_Order_By>>;
  where?: Maybe<LiquidAppliedUsage_Bool_Exp>;
};


export type Query_RootLiquidAppliedUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootLoad_SearchLayerUsage_DeckFiberReinforcedPlasticArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Order_By>>;
  where?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Bool_Exp>;
};


export type Query_RootLoad_SearchLayerUsage_DeckFiberReinforcedPlastic_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Order_By>>;
  where?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Bool_Exp>;
};


export type Query_RootMd_ApprovedUseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ApprovedUse_Order_By>>;
  where?: Maybe<Md_ApprovedUse_Bool_Exp>;
};


export type Query_RootMd_ApprovedUseAttributesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ApprovedUseAttributes_Order_By>>;
  where?: Maybe<Md_ApprovedUseAttributes_Bool_Exp>;
};


export type Query_RootMd_ApprovedUseAttributes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ApprovedUseAttributes_Order_By>>;
  where?: Maybe<Md_ApprovedUseAttributes_Bool_Exp>;
};


export type Query_RootMd_ApprovedUseAttributes_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  AttributeId: Scalars['Int'];
};


export type Query_RootMd_ApprovedUse_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ApprovedUse_Order_By>>;
  where?: Maybe<Md_ApprovedUse_Bool_Exp>;
};


export type Query_RootMd_ApprovedUse_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
};


export type Query_RootMd_AttributeBaseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_AttributeBase_Order_By>>;
  where?: Maybe<Md_AttributeBase_Bool_Exp>;
};


export type Query_RootMd_AttributeBase_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_AttributeBase_Order_By>>;
  where?: Maybe<Md_AttributeBase_Bool_Exp>;
};


export type Query_RootMd_AttributeBase_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


export type Query_RootMd_BooleanAttributeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_BooleanAttribute_Order_By>>;
  where?: Maybe<Md_BooleanAttribute_Bool_Exp>;
};


export type Query_RootMd_BooleanAttribute_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_BooleanAttribute_Order_By>>;
  where?: Maybe<Md_BooleanAttribute_Bool_Exp>;
};


export type Query_RootMd_BooleanAttribute_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


export type Query_RootMd_BooleanDisplayOptionArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_BooleanDisplayOption_Order_By>>;
  where?: Maybe<Md_BooleanDisplayOption_Bool_Exp>;
};


export type Query_RootMd_BooleanDisplayOption_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_BooleanDisplayOption_Order_By>>;
  where?: Maybe<Md_BooleanDisplayOption_Bool_Exp>;
};


export type Query_RootMd_BooleanDisplayOption_By_PkArgs = {
  BooleanDisplayOptionId: Scalars['Int'];
};


export type Query_RootMd_CatalogArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_Catalog_Order_By>>;
  where?: Maybe<Md_Catalog_Bool_Exp>;
};


export type Query_RootMd_Catalog_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_Catalog_Order_By>>;
  where?: Maybe<Md_Catalog_Bool_Exp>;
};


export type Query_RootMd_Catalog_By_PkArgs = {
  MetadataCatalogId: Scalars['Int'];
};


export type Query_RootMd_CodeTableAttributeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_CodeTableAttribute_Order_By>>;
  where?: Maybe<Md_CodeTableAttribute_Bool_Exp>;
};


export type Query_RootMd_CodeTableAttribute_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_CodeTableAttribute_Order_By>>;
  where?: Maybe<Md_CodeTableAttribute_Bool_Exp>;
};


export type Query_RootMd_CodeTableAttribute_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


export type Query_RootMd_CodeTablesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_CodeTables_Order_By>>;
  where?: Maybe<Md_CodeTables_Bool_Exp>;
};


export type Query_RootMd_CodeTables_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_CodeTables_Order_By>>;
  where?: Maybe<Md_CodeTables_Bool_Exp>;
};


export type Query_RootMd_CodeTables_By_PkArgs = {
  CodeTableId: Scalars['Int'];
};


export type Query_RootMd_ComponentUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ComponentUsage_Order_By>>;
  where?: Maybe<Md_ComponentUsage_Bool_Exp>;
};


export type Query_RootMd_ComponentUsageAttributesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ComponentUsageAttributes_Order_By>>;
  where?: Maybe<Md_ComponentUsageAttributes_Bool_Exp>;
};


export type Query_RootMd_ComponentUsageAttributes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ComponentUsageAttributes_Order_By>>;
  where?: Maybe<Md_ComponentUsageAttributes_Bool_Exp>;
};


export type Query_RootMd_ComponentUsageAttributes_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  AttributeId: Scalars['Int'];
};


export type Query_RootMd_ComponentUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ComponentUsage_Order_By>>;
  where?: Maybe<Md_ComponentUsage_Bool_Exp>;
};


export type Query_RootMd_ComponentUsage_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
};


export type Query_RootMd_MeasureArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_Measure_Order_By>>;
  where?: Maybe<Md_Measure_Bool_Exp>;
};


export type Query_RootMd_Measure_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_Measure_Order_By>>;
  where?: Maybe<Md_Measure_Bool_Exp>;
};


export type Query_RootMd_Measure_By_PkArgs = {
  MeasureId: Scalars['Int'];
};


export type Query_RootMd_MeasurementAttributeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MeasurementAttribute_Order_By>>;
  where?: Maybe<Md_MeasurementAttribute_Bool_Exp>;
};


export type Query_RootMd_MeasurementAttribute_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MeasurementAttribute_Order_By>>;
  where?: Maybe<Md_MeasurementAttribute_Bool_Exp>;
};


export type Query_RootMd_MeasurementAttribute_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


export type Query_RootMd_MeasurementDisplayOptionArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MeasurementDisplayOption_Order_By>>;
  where?: Maybe<Md_MeasurementDisplayOption_Bool_Exp>;
};


export type Query_RootMd_MeasurementDisplayOption_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MeasurementDisplayOption_Order_By>>;
  where?: Maybe<Md_MeasurementDisplayOption_Bool_Exp>;
};


export type Query_RootMd_MeasurementDisplayOption_By_PkArgs = {
  MeasurementDisplayOptionId: Scalars['Int'];
};


export type Query_RootMd_MultivalueAttributeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MultivalueAttribute_Order_By>>;
  where?: Maybe<Md_MultivalueAttribute_Bool_Exp>;
};


export type Query_RootMd_MultivalueAttribute_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MultivalueAttribute_Order_By>>;
  where?: Maybe<Md_MultivalueAttribute_Bool_Exp>;
};


export type Query_RootMd_MultivalueAttribute_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


export type Query_RootMd_MultivalueDisplayOptionArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MultivalueDisplayOption_Order_By>>;
  where?: Maybe<Md_MultivalueDisplayOption_Bool_Exp>;
};


export type Query_RootMd_MultivalueDisplayOption_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MultivalueDisplayOption_Order_By>>;
  where?: Maybe<Md_MultivalueDisplayOption_Bool_Exp>;
};


export type Query_RootMd_MultivalueDisplayOption_By_PkArgs = {
  MultivalueDisplayOptionId: Scalars['Int'];
};


export type Query_RootMd_ProductCategoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ProductCategory_Order_By>>;
  where?: Maybe<Md_ProductCategory_Bool_Exp>;
};


export type Query_RootMd_ProductCategory_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ProductCategory_Order_By>>;
  where?: Maybe<Md_ProductCategory_Bool_Exp>;
};


export type Query_RootMd_ProductCategory_By_PkArgs = {
  CategoryId: Scalars['Int'];
};


export type Query_RootMd_ProductSubcategoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ProductSubcategory_Order_By>>;
  where?: Maybe<Md_ProductSubcategory_Bool_Exp>;
};


export type Query_RootMd_ProductSubcategory_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ProductSubcategory_Order_By>>;
  where?: Maybe<Md_ProductSubcategory_Bool_Exp>;
};


export type Query_RootMd_ProductSubcategory_By_PkArgs = {
  SubcategoryId: Scalars['Int'];
};


export type Query_RootMaintenanceCoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoating_Order_By>>;
  where?: Maybe<MaintenanceCoating_Bool_Exp>;
};


export type Query_RootMaintenanceCoatingSubstrateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<MaintenanceCoatingSubstrate_Bool_Exp>;
};


export type Query_RootMaintenanceCoatingSubstrate_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<MaintenanceCoatingSubstrate_Bool_Exp>;
};


export type Query_RootMaintenanceCoatingSubstrate_By_PkArgs = {
  ComponentId: Scalars['Int'];
  MaintenanceCoatingSubstrateId: Scalars['Int'];
};


export type Query_RootMaintenanceCoatingUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoatingUsage_Order_By>>;
  where?: Maybe<MaintenanceCoatingUsage_Bool_Exp>;
};


export type Query_RootMaintenanceCoatingUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoatingUsage_Order_By>>;
  where?: Maybe<MaintenanceCoatingUsage_Bool_Exp>;
};


export type Query_RootMaintenanceCoatingUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootMaintenanceCoating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoating_Order_By>>;
  where?: Maybe<MaintenanceCoating_Bool_Exp>;
};


export type Query_RootMaintenanceCoating_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootManufacturedProductArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturedProduct_Order_By>>;
  where?: Maybe<ManufacturedProduct_Bool_Exp>;
};


export type Query_RootManufacturedProduct_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturedProduct_Order_By>>;
  where?: Maybe<ManufacturedProduct_Bool_Exp>;
};


export type Query_RootManufacturedProduct_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootManufacturerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manufacturer_Order_By>>;
  where?: Maybe<Manufacturer_Bool_Exp>;
};


export type Query_RootManufacturerChangeHistoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturerChangeHistory_Order_By>>;
  where?: Maybe<ManufacturerChangeHistory_Bool_Exp>;
};


export type Query_RootManufacturerChangeHistory_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturerChangeHistory_Order_By>>;
  where?: Maybe<ManufacturerChangeHistory_Bool_Exp>;
};


export type Query_RootManufacturerChangeHistory_By_PkArgs = {
  ChangeHistoryId: Scalars['Int'];
};


export type Query_RootManufacturerListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturerList_Order_By>>;
  where?: Maybe<ManufacturerList_Bool_Exp>;
};


export type Query_RootManufacturerList_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturerList_Order_By>>;
  where?: Maybe<ManufacturerList_Bool_Exp>;
};


export type Query_RootManufacturer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manufacturer_Order_By>>;
  where?: Maybe<Manufacturer_Bool_Exp>;
};


export type Query_RootManufacturer_By_PkArgs = {
  ManufacturerId: Scalars['Int'];
};


export type Query_RootMultiplyCoverConfigArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MultiplyCoverConfig_Order_By>>;
  where?: Maybe<MultiplyCoverConfig_Bool_Exp>;
};


export type Query_RootMultiplyCoverConfig_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MultiplyCoverConfig_Order_By>>;
  where?: Maybe<MultiplyCoverConfig_Bool_Exp>;
};


export type Query_RootMultiplyCoverConfig_By_PkArgs = {
  MultiplyCoverConfigId: Scalars['Int'];
};


export type Query_RootMultiplyCoverSubassemblyUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MultiplyCoverSubassemblyUsage_Order_By>>;
  where?: Maybe<MultiplyCoverSubassemblyUsage_Bool_Exp>;
};


export type Query_RootMultiplyCoverSubassemblyUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MultiplyCoverSubassemblyUsage_Order_By>>;
  where?: Maybe<MultiplyCoverSubassemblyUsage_Bool_Exp>;
};


export type Query_RootMultiplyCoverSubassemblyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootNavAssemblyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssembly_Order_By>>;
  where?: Maybe<NavAssembly_Bool_Exp>;
};


export type Query_RootNavAssemblyMultiplyInfoArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssemblyMultiplyInfo_Order_By>>;
  where?: Maybe<NavAssemblyMultiplyInfo_Bool_Exp>;
};


export type Query_RootNavAssemblyMultiplyInfo_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssemblyMultiplyInfo_Order_By>>;
  where?: Maybe<NavAssemblyMultiplyInfo_Bool_Exp>;
};


export type Query_RootNavAssembly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssembly_Order_By>>;
  where?: Maybe<NavAssembly_Bool_Exp>;
};


export type Query_RootNavAssembly_By_PkArgs = {
  NavAssemblyId: Scalars['Int'];
};


export type Query_RootNavLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavLayer_Order_By>>;
  where?: Maybe<NavLayer_Bool_Exp>;
};


export type Query_RootNavLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavLayer_Order_By>>;
  where?: Maybe<NavLayer_Bool_Exp>;
};


export type Query_RootNavLayer_By_PkArgs = {
  NavLayerId: Scalars['Int'];
};


export type Query_RootNavMultiplyCoverConfigArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavMultiplyCoverConfig_Order_By>>;
  where?: Maybe<NavMultiplyCoverConfig_Bool_Exp>;
};


export type Query_RootNavMultiplyCoverConfig_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavMultiplyCoverConfig_Order_By>>;
  where?: Maybe<NavMultiplyCoverConfig_Bool_Exp>;
};


export type Query_RootNavMultiplyCoverConfig_By_PkArgs = {
  NavMultiplyCoverConfigId: Scalars['Int'];
};


export type Query_RootNavSecurementLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavSecurementLayer_Order_By>>;
  where?: Maybe<NavSecurementLayer_Bool_Exp>;
};


export type Query_RootNavSecurementLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavSecurementLayer_Order_By>>;
  where?: Maybe<NavSecurementLayer_Bool_Exp>;
};


export type Query_RootNavSecurementLayer_By_PkArgs = {
  NavLayerId: Scalars['Int'];
};


export type Query_RootNavSecurementUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavSecurementUsage_Order_By>>;
  where?: Maybe<NavSecurementUsage_Bool_Exp>;
};


export type Query_RootNavSecurementUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavSecurementUsage_Order_By>>;
  where?: Maybe<NavSecurementUsage_Bool_Exp>;
};


export type Query_RootNavSecurementUsage_By_PkArgs = {
  NavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


export type Query_RootNavUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavUsage_Order_By>>;
  where?: Maybe<NavUsage_Bool_Exp>;
};


export type Query_RootNavUsageSaArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavUsageSa_Order_By>>;
  where?: Maybe<NavUsageSa_Bool_Exp>;
};


export type Query_RootNavUsageSa_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavUsageSa_Order_By>>;
  where?: Maybe<NavUsageSa_Bool_Exp>;
};


export type Query_RootNavUsageSa_By_PkArgs = {
  NavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


export type Query_RootNavUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavUsage_Order_By>>;
  where?: Maybe<NavUsage_Bool_Exp>;
};


export type Query_RootNavUsage_By_PkArgs = {
  NavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


export type Query_RootNav_SinglePlyBondingAdhesivesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nav_SinglePlyBondingAdhesives_Order_By>>;
  where?: Maybe<Nav_SinglePlyBondingAdhesives_Bool_Exp>;
};


export type Query_RootNav_SinglePlyBondingAdhesives_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nav_SinglePlyBondingAdhesives_Order_By>>;
  where?: Maybe<Nav_SinglePlyBondingAdhesives_Bool_Exp>;
};


export type Query_RootNav_SinglePlyLapAdhesivesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nav_SinglePlyLapAdhesives_Order_By>>;
  where?: Maybe<Nav_SinglePlyLapAdhesives_Bool_Exp>;
};


export type Query_RootNav_SinglePlyLapAdhesives_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nav_SinglePlyLapAdhesives_Order_By>>;
  where?: Maybe<Nav_SinglePlyLapAdhesives_Bool_Exp>;
};


export type Query_RootNextAssemblyNumArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NextAssemblyNum_Order_By>>;
  where?: Maybe<NextAssemblyNum_Bool_Exp>;
};


export type Query_RootNextAssemblyNum_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NextAssemblyNum_Order_By>>;
  where?: Maybe<NextAssemblyNum_Bool_Exp>;
};


export type Query_RootNextAssemblyNum_By_PkArgs = {
  AssemblyNum: Scalars['Int'];
};


export type Query_RootNoteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Order_By>>;
  where?: Maybe<Note_Bool_Exp>;
};


export type Query_RootNote_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Order_By>>;
  where?: Maybe<Note_Bool_Exp>;
};


export type Query_RootNote_By_PkArgs = {
  NoteId: Scalars['Int'];
};


export type Query_RootOtherPlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OtherPly_Order_By>>;
  where?: Maybe<OtherPly_Bool_Exp>;
};


export type Query_RootOtherPlyUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OtherPlyUsage_Order_By>>;
  where?: Maybe<OtherPlyUsage_Bool_Exp>;
};


export type Query_RootOtherPlyUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OtherPlyUsage_Order_By>>;
  where?: Maybe<OtherPlyUsage_Bool_Exp>;
};


export type Query_RootOtherPlyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootOtherPly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OtherPly_Order_By>>;
  where?: Maybe<OtherPly_Bool_Exp>;
};


export type Query_RootOtherPly_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootPreassembledFasteningArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};


export type Query_RootPreassembledFasteningUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFasteningUsage_Order_By>>;
  where?: Maybe<PreassembledFasteningUsage_Bool_Exp>;
};


export type Query_RootPreassembledFasteningUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFasteningUsage_Order_By>>;
  where?: Maybe<PreassembledFasteningUsage_Bool_Exp>;
};


export type Query_RootPreassembledFasteningUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootPreassembledFastening_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};


export type Query_RootPreassembledFastening_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootPrivateLabelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PrivateLabel_Order_By>>;
  where?: Maybe<PrivateLabel_Bool_Exp>;
};


export type Query_RootPrivateLabel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PrivateLabel_Order_By>>;
  where?: Maybe<PrivateLabel_Bool_Exp>;
};


export type Query_RootPrivateLabel_By_PkArgs = {
  ChildComponentId: Scalars['Int'];
  ComponentId: Scalars['Int'];
};


export type Query_RootPrivateLabelerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PrivateLabeler_Order_By>>;
  where?: Maybe<PrivateLabeler_Bool_Exp>;
};


export type Query_RootPrivateLabeler_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PrivateLabeler_Order_By>>;
  where?: Maybe<PrivateLabeler_Bool_Exp>;
};


export type Query_RootProductDependenciesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductDependencies_Order_By>>;
  where?: Maybe<ProductDependencies_Bool_Exp>;
};


export type Query_RootProductDependencies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductDependencies_Order_By>>;
  where?: Maybe<ProductDependencies_Bool_Exp>;
};


export type Query_RootProductDependencies_By_PkArgs = {
  Component1Id: Scalars['Int'];
  Component2Id: Scalars['Int'];
};


export type Query_RootProductsBySubcategoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsBySubcategory_Order_By>>;
  where?: Maybe<ProductsBySubcategory_Bool_Exp>;
};


export type Query_RootProductsBySubcategory_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsBySubcategory_Order_By>>;
  where?: Maybe<ProductsBySubcategory_Bool_Exp>;
};


export type Query_RootR_AdhesiveTapeWidthArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_AdhesiveTapeWidth_Order_By>>;
  where?: Maybe<R_AdhesiveTapeWidth_Bool_Exp>;
};


export type Query_RootR_AdhesiveTapeWidth_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_AdhesiveTapeWidth_Order_By>>;
  where?: Maybe<R_AdhesiveTapeWidth_Bool_Exp>;
};


export type Query_RootR_AdhesiveTapeWidth_By_PkArgs = {
  ComponentId: Scalars['Int'];
  TapeWidthId: Scalars['Int'];
};


export type Query_RootR_ApprovedUse_SubcategoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ApprovedUse_Subcategory_Order_By>>;
  where?: Maybe<R_ApprovedUse_Subcategory_Bool_Exp>;
};


export type Query_RootR_ApprovedUse_Subcategory_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ApprovedUse_Subcategory_Order_By>>;
  where?: Maybe<R_ApprovedUse_Subcategory_Bool_Exp>;
};


export type Query_RootR_ApprovedUse_Subcategory_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  SubcategoryId: Scalars['Int'];
};


export type Query_RootR_ComponentType_ApprovedUseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ComponentType_ApprovedUse_Order_By>>;
  where?: Maybe<R_ComponentType_ApprovedUse_Bool_Exp>;
};


export type Query_RootR_ComponentType_ApprovedUse_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ComponentType_ApprovedUse_Order_By>>;
  where?: Maybe<R_ComponentType_ApprovedUse_Bool_Exp>;
};


export type Query_RootR_ComponentType_ApprovedUse_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  ComponentTypeId: Scalars['Int'];
};


export type Query_RootR_CopingFastenerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_CopingFastener_Order_By>>;
  where?: Maybe<R_CopingFastener_Bool_Exp>;
};


export type Query_RootR_CopingFastener_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_CopingFastener_Order_By>>;
  where?: Maybe<R_CopingFastener_Bool_Exp>;
};


export type Query_RootR_CopingFastener_By_PkArgs = {
  CopingComponentId: Scalars['Int'];
  FastenerComponentId: Scalars['Int'];
};


export type Query_RootR_Country_StateProvinceArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Country_StateProvince_Order_By>>;
  where?: Maybe<R_Country_StateProvince_Bool_Exp>;
};


export type Query_RootR_Country_StateProvince_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Country_StateProvince_Order_By>>;
  where?: Maybe<R_Country_StateProvince_Bool_Exp>;
};


export type Query_RootR_Country_StateProvince_By_PkArgs = {
  CountryId: Scalars['Int'];
  StateProvinceId: Scalars['Int'];
};


export type Query_RootR_ExpansionJoinFastenerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ExpansionJoinFastener_Order_By>>;
  where?: Maybe<R_ExpansionJoinFastener_Bool_Exp>;
};


export type Query_RootR_ExpansionJoinFastener_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ExpansionJoinFastener_Order_By>>;
  where?: Maybe<R_ExpansionJoinFastener_Bool_Exp>;
};


export type Query_RootR_ExpansionJoinFastener_By_PkArgs = {
  ExpansionJointComponentId: Scalars['Int'];
  FastenerComponentId: Scalars['Int'];
};


export type Query_RootR_FasciaComponentFastenerComponentArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_FasciaComponentFastenerComponent_Order_By>>;
  where?: Maybe<R_FasciaComponentFastenerComponent_Bool_Exp>;
};


export type Query_RootR_FasciaComponentFastenerComponent_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_FasciaComponentFastenerComponent_Order_By>>;
  where?: Maybe<R_FasciaComponentFastenerComponent_Bool_Exp>;
};


export type Query_RootR_FasciaComponentFastenerComponent_By_PkArgs = {
  FasciaComponentId: Scalars['Int'];
  FastenerComponentId: Scalars['Int'];
};


export type Query_RootR_GutterFastenersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_GutterFasteners_Order_By>>;
  where?: Maybe<R_GutterFasteners_Bool_Exp>;
};


export type Query_RootR_GutterFasteners_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_GutterFasteners_Order_By>>;
  where?: Maybe<R_GutterFasteners_Bool_Exp>;
};


export type Query_RootR_GutterFasteners_By_PkArgs = {
  FastenerId: Scalars['Int'];
  GutterId: Scalars['Int'];
};


export type Query_RootR_LinerPanelMaterialFiberReinforcedPlasticDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Order_By>>;
  where?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp>;
};


export type Query_RootR_LinerPanelMaterialFiberReinforcedPlasticDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Order_By>>;
  where?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp>;
};


export type Query_RootR_LinerPanelMaterialFiberReinforcedPlasticDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
  LinerPanelMaterialId: Scalars['Int'];
};


export type Query_RootR_MaintenanceCoatingSubstrateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<R_MaintenanceCoatingSubstrate_Bool_Exp>;
};


export type Query_RootR_MaintenanceCoatingSubstrate_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<R_MaintenanceCoatingSubstrate_Bool_Exp>;
};


export type Query_RootR_MaintenanceCoatingSubstrate_By_PkArgs = {
  ComponentId: Scalars['Int'];
  MaintenanceCoatingSubstrateId: Scalars['Int'];
};


export type Query_RootR_NavAssembly_NavMultiplyCoverConfigArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_NavAssembly_NavMultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp>;
};


export type Query_RootR_NavAssembly_NavMultiplyCoverConfig_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_NavAssembly_NavMultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp>;
};


export type Query_RootR_NavAssembly_NavMultiplyCoverConfig_By_PkArgs = {
  NavAssemblyId: Scalars['Int'];
  NavMultiplyCoverConfigId: Scalars['Int'];
};


export type Query_RootR_ProjectContactArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectContact_Order_By>>;
  where?: Maybe<R_ProjectContact_Bool_Exp>;
};


export type Query_RootR_ProjectContact_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectContact_Order_By>>;
  where?: Maybe<R_ProjectContact_Bool_Exp>;
};


export type Query_RootR_ProjectContact_By_PkArgs = {
  ContactInfoId: Scalars['Int'];
  ProjectId: Scalars['Int'];
};


export type Query_RootR_ProjectNoteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectNote_Order_By>>;
  where?: Maybe<R_ProjectNote_Bool_Exp>;
};


export type Query_RootR_ProjectNote_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectNote_Order_By>>;
  where?: Maybe<R_ProjectNote_Bool_Exp>;
};


export type Query_RootR_ProjectNote_By_PkArgs = {
  NoteId: Scalars['Int'];
  ProjectId: Scalars['Int'];
};


export type Query_RootR_RegletFastenerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RegletFastener_Order_By>>;
  where?: Maybe<R_RegletFastener_Bool_Exp>;
};


export type Query_RootR_RegletFastener_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RegletFastener_Order_By>>;
  where?: Maybe<R_RegletFastener_Bool_Exp>;
};


export type Query_RootR_RegletFastener_By_PkArgs = {
  FastenerComponentId: Scalars['Int'];
  RegletComponentId: Scalars['Int'];
};


export type Query_RootR_RoofAreaContactArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaContact_Order_By>>;
  where?: Maybe<R_RoofAreaContact_Bool_Exp>;
};


export type Query_RootR_RoofAreaContact_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaContact_Order_By>>;
  where?: Maybe<R_RoofAreaContact_Bool_Exp>;
};


export type Query_RootR_RoofAreaContact_By_PkArgs = {
  ContactInfoId: Scalars['Int'];
  ProjectRoleId: Scalars['Int'];
  RoofAreaId: Scalars['Int'];
};


export type Query_RootR_RoofAreaNoteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaNote_Order_By>>;
  where?: Maybe<R_RoofAreaNote_Bool_Exp>;
};


export type Query_RootR_RoofAreaNote_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaNote_Order_By>>;
  where?: Maybe<R_RoofAreaNote_Bool_Exp>;
};


export type Query_RootR_RoofAreaNote_By_PkArgs = {
  NoteId: Scalars['Int'];
  RoofAreaId: Scalars['Int'];
};


export type Query_RootR_RoofAreaScenarioArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaScenario_Order_By>>;
  where?: Maybe<R_RoofAreaScenario_Bool_Exp>;
};


export type Query_RootR_RoofAreaScenario_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaScenario_Order_By>>;
  where?: Maybe<R_RoofAreaScenario_Bool_Exp>;
};


export type Query_RootR_RoofAreaScenario_By_PkArgs = {
  RoofAreaId: Scalars['Int'];
  ScenarioId: Scalars['Int'];
};


export type Query_RootR_ScenarioNoteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ScenarioNote_Order_By>>;
  where?: Maybe<R_ScenarioNote_Bool_Exp>;
};


export type Query_RootR_ScenarioNote_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ScenarioNote_Order_By>>;
  where?: Maybe<R_ScenarioNote_Bool_Exp>;
};


export type Query_RootR_ScenarioNote_By_PkArgs = {
  NoteId: Scalars['Int'];
  ScenarioId: Scalars['Int'];
};


export type Query_RootR_SinglePly_BondingAdhesivesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_BondingAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_BondingAdhesives_Bool_Exp>;
};


export type Query_RootR_SinglePly_BondingAdhesives_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_BondingAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_BondingAdhesives_Bool_Exp>;
};


export type Query_RootR_SinglePly_BondingAdhesives_By_PkArgs = {
  AdhesiveComponentId: Scalars['Int'];
  SinglePlyComponentId: Scalars['Int'];
};


export type Query_RootR_SinglePly_LapAdhesivesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_LapAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_LapAdhesives_Bool_Exp>;
};


export type Query_RootR_SinglePly_LapAdhesives_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_LapAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_LapAdhesives_Bool_Exp>;
};


export type Query_RootR_SinglePly_LapAdhesives_By_PkArgs = {
  AdhesiveComponentId: Scalars['Int'];
  SinglePlyComponentId: Scalars['Int'];
};


export type Query_RootR_Subassembly_MultiplyCoverConfigArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Subassembly_MultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_Subassembly_MultiplyCoverConfig_Bool_Exp>;
};


export type Query_RootR_Subassembly_MultiplyCoverConfig_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Subassembly_MultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_Subassembly_MultiplyCoverConfig_Bool_Exp>;
};


export type Query_RootR_Subassembly_MultiplyCoverConfig_By_PkArgs = {
  MultiplyCoverConfigId: Scalars['Int'];
  SubassemblyComponentId: Scalars['Int'];
};


export type Query_RootR_UserContactArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_UserContact_Order_By>>;
  where?: Maybe<R_UserContact_Bool_Exp>;
};


export type Query_RootR_UserContact_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_UserContact_Order_By>>;
  where?: Maybe<R_UserContact_Bool_Exp>;
};


export type Query_RootR_UserContact_By_PkArgs = {
  ContactInfoId: Scalars['Int'];
  UserAccountId: Scalars['Int'];
};


export type Query_RootRegletArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reglet_Order_By>>;
  where?: Maybe<Reglet_Bool_Exp>;
};


export type Query_RootReglet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reglet_Order_By>>;
  where?: Maybe<Reglet_Bool_Exp>;
};


export type Query_RootReglet_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootReviewerWorkspaceArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspace_Order_By>>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};


export type Query_RootReviewerWorkspaceDataArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspaceData_Order_By>>;
  where?: Maybe<ReviewerWorkspaceData_Bool_Exp>;
};


export type Query_RootReviewerWorkspaceData_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspaceData_Order_By>>;
  where?: Maybe<ReviewerWorkspaceData_Bool_Exp>;
};


export type Query_RootReviewerWorkspaceData_By_PkArgs = {
  ReviewerWorkspaceDataId: Scalars['Int'];
};


export type Query_RootReviewerWorkspace_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspace_Order_By>>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};


export type Query_RootReviewerWorkspace_By_PkArgs = {
  ReviewerWorkspaceId: Scalars['Int'];
};


export type Query_RootRoofAreaArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};


export type Query_RootRoofAreaWindPressureArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAreaWindPressure_Order_By>>;
  where?: Maybe<RoofAreaWindPressure_Bool_Exp>;
};


export type Query_RootRoofAreaWindPressure_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAreaWindPressure_Order_By>>;
  where?: Maybe<RoofAreaWindPressure_Bool_Exp>;
};


export type Query_RootRoofAreaWindPressure_By_PkArgs = {
  RoofAreaWindPressureId: Scalars['Int'];
};


export type Query_RootRoofArea_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};


export type Query_RootRoofArea_By_PkArgs = {
  RoofAreaId: Scalars['Int'];
};


export type Query_RootRoofAssemblyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


export type Query_RootRoofAssemblyDelistDataArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssemblyDelistData_Order_By>>;
  where?: Maybe<RoofAssemblyDelistData_Bool_Exp>;
};


export type Query_RootRoofAssemblyDelistData_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssemblyDelistData_Order_By>>;
  where?: Maybe<RoofAssemblyDelistData_Bool_Exp>;
};


export type Query_RootRoofAssemblyLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssemblyLayers_Order_By>>;
  where?: Maybe<RoofAssemblyLayers_Bool_Exp>;
};


export type Query_RootRoofAssemblyLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssemblyLayers_Order_By>>;
  where?: Maybe<RoofAssemblyLayers_Bool_Exp>;
};


export type Query_RootRoofAssemblyLayers_By_PkArgs = {
  AssemblyId: Scalars['Int'];
  LayerId: Scalars['Int'];
};


export type Query_RootRoofAssembly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


export type Query_RootRoofAssembly_By_PkArgs = {
  AssemblyId: Scalars['Int'];
};


export type Query_RootRoofProjectArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofProject_Order_By>>;
  where?: Maybe<RoofProject_Bool_Exp>;
};


export type Query_RootRoofProject_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofProject_Order_By>>;
  where?: Maybe<RoofProject_Bool_Exp>;
};


export type Query_RootRoofProject_By_PkArgs = {
  ProjectId: Scalars['Int'];
};


export type Query_RootScenarioArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scenario_Order_By>>;
  where?: Maybe<Scenario_Bool_Exp>;
};


export type Query_RootScenarioAlertArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioAlert_Order_By>>;
  where?: Maybe<ScenarioAlert_Bool_Exp>;
};


export type Query_RootScenarioAlert_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioAlert_Order_By>>;
  where?: Maybe<ScenarioAlert_Bool_Exp>;
};


export type Query_RootScenarioAlert_By_PkArgs = {
  ScenarioAlertId: Scalars['Int'];
};


export type Query_RootScenarioLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioLayer_Order_By>>;
  where?: Maybe<ScenarioLayer_Bool_Exp>;
};


export type Query_RootScenarioLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioLayer_Order_By>>;
  where?: Maybe<ScenarioLayer_Bool_Exp>;
};


export type Query_RootScenarioLayer_By_PkArgs = {
  ScenarioLayerId: Scalars['Int'];
};


export type Query_RootScenarioUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioUsage_Order_By>>;
  where?: Maybe<ScenarioUsage_Bool_Exp>;
};


export type Query_RootScenarioUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioUsage_Order_By>>;
  where?: Maybe<ScenarioUsage_Bool_Exp>;
};


export type Query_RootScenarioUsage_By_PkArgs = {
  ScenarioUsageId: Scalars['Int'];
};


export type Query_RootScenario_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scenario_Order_By>>;
  where?: Maybe<Scenario_Bool_Exp>;
};


export type Query_RootScenario_By_PkArgs = {
  ScenarioId: Scalars['Int'];
};


export type Query_RootSeamPrimerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamPrimer_Order_By>>;
  where?: Maybe<SeamPrimer_Bool_Exp>;
};


export type Query_RootSeamPrimerUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamPrimerUsage_Order_By>>;
  where?: Maybe<SeamPrimerUsage_Bool_Exp>;
};


export type Query_RootSeamPrimerUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamPrimerUsage_Order_By>>;
  where?: Maybe<SeamPrimerUsage_Bool_Exp>;
};


export type Query_RootSeamPrimerUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootSeamPrimer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamPrimer_Order_By>>;
  where?: Maybe<SeamPrimer_Bool_Exp>;
};


export type Query_RootSeamPrimer_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootSeamTapeSystemUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamTapeSystemUsage_Order_By>>;
  where?: Maybe<SeamTapeSystemUsage_Bool_Exp>;
};


export type Query_RootSeamTapeSystemUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamTapeSystemUsage_Order_By>>;
  where?: Maybe<SeamTapeSystemUsage_Bool_Exp>;
};


export type Query_RootSeamTapeSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootSeamWashArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamWash_Order_By>>;
  where?: Maybe<SeamWash_Bool_Exp>;
};


export type Query_RootSeamWashUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamWashUsage_Order_By>>;
  where?: Maybe<SeamWashUsage_Bool_Exp>;
};


export type Query_RootSeamWashUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamWashUsage_Order_By>>;
  where?: Maybe<SeamWashUsage_Bool_Exp>;
};


export type Query_RootSeamWashUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootSeamWash_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamWash_Order_By>>;
  where?: Maybe<SeamWash_Bool_Exp>;
};


export type Query_RootSeamWash_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootSearchNavAssembly_ManufacturerProductArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SearchNavAssembly_ManufacturerProduct_Order_By>>;
  where?: Maybe<SearchNavAssembly_ManufacturerProduct_Bool_Exp>;
};


export type Query_RootSearchNavAssembly_ManufacturerProduct_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SearchNavAssembly_ManufacturerProduct_Order_By>>;
  where?: Maybe<SearchNavAssembly_ManufacturerProduct_Bool_Exp>;
};


export type Query_RootSearchNavAssembly_ManufacturerProduct_By_PkArgs = {
  ComponentId: Scalars['Int'];
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
};


export type Query_RootSecurementLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayer_Order_By>>;
  where?: Maybe<SecurementLayer_Bool_Exp>;
};


export type Query_RootSecurementLayerUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayerUsage_Order_By>>;
  where?: Maybe<SecurementLayerUsage_Bool_Exp>;
};


export type Query_RootSecurementLayerUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayerUsage_Order_By>>;
  where?: Maybe<SecurementLayerUsage_Bool_Exp>;
};


export type Query_RootSecurementLayerUsage_By_PkArgs = {
  LayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


export type Query_RootSecurementLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayer_Order_By>>;
  where?: Maybe<SecurementLayer_Bool_Exp>;
};


export type Query_RootSecurementLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


export type Query_RootSelfSecurementUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SelfSecurementUsage_Order_By>>;
  where?: Maybe<SelfSecurementUsage_Bool_Exp>;
};


export type Query_RootSelfSecurementUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SelfSecurementUsage_Order_By>>;
  where?: Maybe<SelfSecurementUsage_Bool_Exp>;
};


export type Query_RootSelfSecurementUsage_By_PkArgs = {
  LayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


export type Query_RootSeparatorSheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeparatorSheet_Order_By>>;
  where?: Maybe<SeparatorSheet_Bool_Exp>;
};


export type Query_RootSeparatorSheetUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeparatorSheetUsage_Order_By>>;
  where?: Maybe<SeparatorSheetUsage_Bool_Exp>;
};


export type Query_RootSeparatorSheetUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeparatorSheetUsage_Order_By>>;
  where?: Maybe<SeparatorSheetUsage_Bool_Exp>;
};


export type Query_RootSeparatorSheetUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootSeparatorSheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeparatorSheet_Order_By>>;
  where?: Maybe<SeparatorSheet_Bool_Exp>;
};


export type Query_RootSeparatorSheet_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootShinglesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shingles_Order_By>>;
  where?: Maybe<Shingles_Bool_Exp>;
};


export type Query_RootShingles_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shingles_Order_By>>;
  where?: Maybe<Shingles_Bool_Exp>;
};


export type Query_RootShingles_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootSinglePlyCoverArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyCover_Order_By>>;
  where?: Maybe<SinglePlyCover_Bool_Exp>;
};


export type Query_RootSinglePlyCover_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyCover_Order_By>>;
  where?: Maybe<SinglePlyCover_Bool_Exp>;
};


export type Query_RootSinglePlyCover_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootSinglePlyUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyUsage_Order_By>>;
  where?: Maybe<SinglePlyUsage_Bool_Exp>;
};


export type Query_RootSinglePlyUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyUsage_Order_By>>;
  where?: Maybe<SinglePlyUsage_Bool_Exp>;
};


export type Query_RootSinglePlyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootSprayFoamInsulationUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SprayFoamInsulationUsage_Order_By>>;
  where?: Maybe<SprayFoamInsulationUsage_Bool_Exp>;
};


export type Query_RootSprayFoamInsulationUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SprayFoamInsulationUsage_Order_By>>;
  where?: Maybe<SprayFoamInsulationUsage_Bool_Exp>;
};


export type Query_RootSprayFoamInsulationUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootSprayedFoamInsulationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SprayedFoamInsulation_Order_By>>;
  where?: Maybe<SprayedFoamInsulation_Bool_Exp>;
};


export type Query_RootSprayedFoamInsulation_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SprayedFoamInsulation_Order_By>>;
  where?: Maybe<SprayedFoamInsulation_Bool_Exp>;
};


export type Query_RootSprayedFoamInsulation_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootStandingLapSeamCoverArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StandingLapSeamCover_Order_By>>;
  where?: Maybe<StandingLapSeamCover_Bool_Exp>;
};


export type Query_RootStandingLapSeamCover_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StandingLapSeamCover_Order_By>>;
  where?: Maybe<StandingLapSeamCover_Bool_Exp>;
};


export type Query_RootStandingLapSeamCover_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootStandingLapSeamUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StandingLapSeamUsage_Order_By>>;
  where?: Maybe<StandingLapSeamUsage_Bool_Exp>;
};


export type Query_RootStandingLapSeamUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StandingLapSeamUsage_Order_By>>;
  where?: Maybe<StandingLapSeamUsage_Bool_Exp>;
};


export type Query_RootStandingLapSeamUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootSteelDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SteelDeck_Order_By>>;
  where?: Maybe<SteelDeck_Bool_Exp>;
};


export type Query_RootSteelDeckLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SteelDeckLayer_Order_By>>;
  where?: Maybe<SteelDeckLayer_Bool_Exp>;
};


export type Query_RootSteelDeckLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SteelDeckLayer_Order_By>>;
  where?: Maybe<SteelDeckLayer_Bool_Exp>;
};


export type Query_RootSteelDeckLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


export type Query_RootSteelDeckUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SteelDeckUsage_Order_By>>;
  where?: Maybe<SteelDeckUsage_Bool_Exp>;
};


export type Query_RootSteelDeckUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SteelDeckUsage_Order_By>>;
  where?: Maybe<SteelDeckUsage_Bool_Exp>;
};


export type Query_RootSteelDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootSteelDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SteelDeck_Order_By>>;
  where?: Maybe<SteelDeck_Bool_Exp>;
};


export type Query_RootSteelDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootStressPlateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StressPlate_Order_By>>;
  where?: Maybe<StressPlate_Bool_Exp>;
};


export type Query_RootStressPlateUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StressPlateUsage_Order_By>>;
  where?: Maybe<StressPlateUsage_Bool_Exp>;
};


export type Query_RootStressPlateUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StressPlateUsage_Order_By>>;
  where?: Maybe<StressPlateUsage_Bool_Exp>;
};


export type Query_RootStressPlateUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootStressPlate_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StressPlate_Order_By>>;
  where?: Maybe<StressPlate_Bool_Exp>;
};


export type Query_RootStressPlate_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootStructuralConcreteLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuralConcreteLayer_Order_By>>;
  where?: Maybe<StructuralConcreteLayer_Bool_Exp>;
};


export type Query_RootStructuralConcreteLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuralConcreteLayer_Order_By>>;
  where?: Maybe<StructuralConcreteLayer_Bool_Exp>;
};


export type Query_RootStructuralConcreteLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


export type Query_RootStructureLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructureLayer_Order_By>>;
  where?: Maybe<StructureLayer_Bool_Exp>;
};


export type Query_RootStructureLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructureLayer_Order_By>>;
  where?: Maybe<StructureLayer_Bool_Exp>;
};


export type Query_RootStructureLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


export type Query_RootStructuredSystemArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuredSystem_Order_By>>;
  where?: Maybe<StructuredSystem_Bool_Exp>;
};


export type Query_RootStructuredSystemProductsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuredSystemProducts_Order_By>>;
  where?: Maybe<StructuredSystemProducts_Bool_Exp>;
};


export type Query_RootStructuredSystemProducts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuredSystemProducts_Order_By>>;
  where?: Maybe<StructuredSystemProducts_Bool_Exp>;
};


export type Query_RootStructuredSystemProducts_By_PkArgs = {
  ComponentId: Scalars['Int'];
  SystemId: Scalars['Int'];
};


export type Query_RootStructuredSystem_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuredSystem_Order_By>>;
  where?: Maybe<StructuredSystem_Bool_Exp>;
};


export type Query_RootStructuredSystem_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootSubassemblyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subassembly_Order_By>>;
  where?: Maybe<Subassembly_Bool_Exp>;
};


export type Query_RootSubassemblyDelistDataArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyDelistData_Order_By>>;
  where?: Maybe<SubassemblyDelistData_Bool_Exp>;
};


export type Query_RootSubassemblyDelistData_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyDelistData_Order_By>>;
  where?: Maybe<SubassemblyDelistData_Bool_Exp>;
};


export type Query_RootSubassemblyE108RatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyE108Rating_Order_By>>;
  where?: Maybe<SubassemblyE108Rating_Bool_Exp>;
};


export type Query_RootSubassemblyE108Rating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyE108Rating_Order_By>>;
  where?: Maybe<SubassemblyE108Rating_Bool_Exp>;
};


export type Query_RootSubassemblyE108Rating_By_PkArgs = {
  SubassemblyComponentId: Scalars['Int'];
};


export type Query_RootSubassemblyLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyLayers_Order_By>>;
  where?: Maybe<SubassemblyLayers_Bool_Exp>;
};


export type Query_RootSubassemblyLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyLayers_Order_By>>;
  where?: Maybe<SubassemblyLayers_Bool_Exp>;
};


export type Query_RootSubassemblyLayers_By_PkArgs = {
  LayerId: Scalars['Int'];
  SubassemblyComponentId: Scalars['Int'];
};


export type Query_RootSubassemblyMultiplyConfigArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyMultiplyConfig_Order_By>>;
  where?: Maybe<SubassemblyMultiplyConfig_Bool_Exp>;
};


export type Query_RootSubassemblyMultiplyConfig_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyMultiplyConfig_Order_By>>;
  where?: Maybe<SubassemblyMultiplyConfig_Bool_Exp>;
};


export type Query_RootSubassemblyMultiplyConfig_By_PkArgs = {
  SubassemblyComponentId: Scalars['Int'];
};


export type Query_RootSubassembly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subassembly_Order_By>>;
  where?: Maybe<Subassembly_Bool_Exp>;
};


export type Query_RootSubassembly_By_PkArgs = {
  SubassemblyComponentId: Scalars['Int'];
};


export type Query_RootSubcat_AdhesiveArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Adhesive_Order_By>>;
  where?: Maybe<Subcat_Adhesive_Bool_Exp>;
};


export type Query_RootSubcat_Adhesive_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Adhesive_Order_By>>;
  where?: Maybe<Subcat_Adhesive_Bool_Exp>;
};


export type Query_RootSubcat_AirRetarderArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_AirRetarder_Order_By>>;
  where?: Maybe<Subcat_AirRetarder_Bool_Exp>;
};


export type Query_RootSubcat_AirRetarder_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_AirRetarder_Order_By>>;
  where?: Maybe<Subcat_AirRetarder_Bool_Exp>;
};


export type Query_RootSubcat_BaseSheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_BaseSheet_Order_By>>;
  where?: Maybe<Subcat_BaseSheet_Bool_Exp>;
};


export type Query_RootSubcat_BaseSheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_BaseSheet_Order_By>>;
  where?: Maybe<Subcat_BaseSheet_Bool_Exp>;
};


export type Query_RootSubcat_BattBlanketArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_BattBlanket_Order_By>>;
  where?: Maybe<Subcat_BattBlanket_Bool_Exp>;
};


export type Query_RootSubcat_BattBlanket_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_BattBlanket_Order_By>>;
  where?: Maybe<Subcat_BattBlanket_Bool_Exp>;
};


export type Query_RootSubcat_BoardStockArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_BoardStock_Order_By>>;
  where?: Maybe<Subcat_BoardStock_Bool_Exp>;
};


export type Query_RootSubcat_BoardStock_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_BoardStock_Order_By>>;
  where?: Maybe<Subcat_BoardStock_Bool_Exp>;
};


export type Query_RootSubcat_CoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Coating_Order_By>>;
  where?: Maybe<Subcat_Coating_Bool_Exp>;
};


export type Query_RootSubcat_Coating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Coating_Order_By>>;
  where?: Maybe<Subcat_Coating_Bool_Exp>;
};


export type Query_RootSubcat_CompositePanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_CompositePanel_Order_By>>;
  where?: Maybe<Subcat_CompositePanel_Bool_Exp>;
};


export type Query_RootSubcat_CompositePanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_CompositePanel_Order_By>>;
  where?: Maybe<Subcat_CompositePanel_Bool_Exp>;
};


export type Query_RootSubcat_CoverStripArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_CoverStrip_Order_By>>;
  where?: Maybe<Subcat_CoverStrip_Bool_Exp>;
};


export type Query_RootSubcat_CoverStrip_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_CoverStrip_Order_By>>;
  where?: Maybe<Subcat_CoverStrip_Bool_Exp>;
};


export type Query_RootSubcat_ExpJointArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_ExpJoint_Order_By>>;
  where?: Maybe<Subcat_ExpJoint_Bool_Exp>;
};


export type Query_RootSubcat_ExpJoint_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_ExpJoint_Order_By>>;
  where?: Maybe<Subcat_ExpJoint_Bool_Exp>;
};


export type Query_RootSubcat_FasteningArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Fastening_Order_By>>;
  where?: Maybe<Subcat_Fastening_Bool_Exp>;
};


export type Query_RootSubcat_Fastening_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Fastening_Order_By>>;
  where?: Maybe<Subcat_Fastening_Bool_Exp>;
};


export type Query_RootSubcat_FormDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_FormDeck_Order_By>>;
  where?: Maybe<Subcat_FormDeck_Bool_Exp>;
};


export type Query_RootSubcat_FormDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_FormDeck_Order_By>>;
  where?: Maybe<Subcat_FormDeck_Bool_Exp>;
};


export type Query_RootSubcat_GutterArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Gutter_Order_By>>;
  where?: Maybe<Subcat_Gutter_Bool_Exp>;
};


export type Query_RootSubcat_Gutter_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Gutter_Order_By>>;
  where?: Maybe<Subcat_Gutter_Bool_Exp>;
};


export type Query_RootSubcat_GypsumArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Gypsum_Order_By>>;
  where?: Maybe<Subcat_Gypsum_Bool_Exp>;
};


export type Query_RootSubcat_Gypsum_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Gypsum_Order_By>>;
  where?: Maybe<Subcat_Gypsum_Bool_Exp>;
};


export type Query_RootSubcat_LwicArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Lwic_Order_By>>;
  where?: Maybe<Subcat_Lwic_Bool_Exp>;
};


export type Query_RootSubcat_LwicDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_LwicDeck_Order_By>>;
  where?: Maybe<Subcat_LwicDeck_Bool_Exp>;
};


export type Query_RootSubcat_LwicDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_LwicDeck_Order_By>>;
  where?: Maybe<Subcat_LwicDeck_Bool_Exp>;
};


export type Query_RootSubcat_Lwic_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Lwic_Order_By>>;
  where?: Maybe<Subcat_Lwic_Bool_Exp>;
};


export type Query_RootSubcat_LinerPanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_LinerPanel_Order_By>>;
  where?: Maybe<Subcat_LinerPanel_Bool_Exp>;
};


export type Query_RootSubcat_LinerPanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_LinerPanel_Order_By>>;
  where?: Maybe<Subcat_LinerPanel_Bool_Exp>;
};


export type Query_RootSubcat_LiquidAppliedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_LiquidApplied_Order_By>>;
  where?: Maybe<Subcat_LiquidApplied_Bool_Exp>;
};


export type Query_RootSubcat_LiquidApplied_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_LiquidApplied_Order_By>>;
  where?: Maybe<Subcat_LiquidApplied_Bool_Exp>;
};


export type Query_RootSubcat_MaintCoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_MaintCoating_Order_By>>;
  where?: Maybe<Subcat_MaintCoating_Bool_Exp>;
};


export type Query_RootSubcat_MaintCoating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_MaintCoating_Order_By>>;
  where?: Maybe<Subcat_MaintCoating_Bool_Exp>;
};


export type Query_RootSubcat_MultiplyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Multiply_Order_By>>;
  where?: Maybe<Subcat_Multiply_Bool_Exp>;
};


export type Query_RootSubcat_MultiplyCoverArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_MultiplyCover_Order_By>>;
  where?: Maybe<Subcat_MultiplyCover_Bool_Exp>;
};


export type Query_RootSubcat_MultiplyCover_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_MultiplyCover_Order_By>>;
  where?: Maybe<Subcat_MultiplyCover_Bool_Exp>;
};


export type Query_RootSubcat_Multiply_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Multiply_Order_By>>;
  where?: Maybe<Subcat_Multiply_Bool_Exp>;
};


export type Query_RootSubcat_PerimeterFlashingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_PerimeterFlashing_Order_By>>;
  where?: Maybe<Subcat_PerimeterFlashing_Bool_Exp>;
};


export type Query_RootSubcat_PerimeterFlashing_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_PerimeterFlashing_Order_By>>;
  where?: Maybe<Subcat_PerimeterFlashing_Bool_Exp>;
};


export type Query_RootSubcat_PlasticDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_PlasticDeck_Order_By>>;
  where?: Maybe<Subcat_PlasticDeck_Bool_Exp>;
};


export type Query_RootSubcat_PlasticDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_PlasticDeck_Order_By>>;
  where?: Maybe<Subcat_PlasticDeck_Bool_Exp>;
};


export type Query_RootSubcat_PrimerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Primer_Order_By>>;
  where?: Maybe<Subcat_Primer_Bool_Exp>;
};


export type Query_RootSubcat_Primer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Primer_Order_By>>;
  where?: Maybe<Subcat_Primer_Bool_Exp>;
};


export type Query_RootSubcat_SeamingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Seaming_Order_By>>;
  where?: Maybe<Subcat_Seaming_Bool_Exp>;
};


export type Query_RootSubcat_Seaming_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Seaming_Order_By>>;
  where?: Maybe<Subcat_Seaming_Bool_Exp>;
};


export type Query_RootSubcat_SeparatorSheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SeparatorSheet_Order_By>>;
  where?: Maybe<Subcat_SeparatorSheet_Bool_Exp>;
};


export type Query_RootSubcat_SeparatorSheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SeparatorSheet_Order_By>>;
  where?: Maybe<Subcat_SeparatorSheet_Bool_Exp>;
};


export type Query_RootSubcat_ShinglesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Shingles_Order_By>>;
  where?: Maybe<Subcat_Shingles_Bool_Exp>;
};


export type Query_RootSubcat_Shingles_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Shingles_Order_By>>;
  where?: Maybe<Subcat_Shingles_Bool_Exp>;
};


export type Query_RootSubcat_SinglePlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SinglePly_Order_By>>;
  where?: Maybe<Subcat_SinglePly_Bool_Exp>;
};


export type Query_RootSubcat_SinglePlyCoverArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SinglePlyCover_Order_By>>;
  where?: Maybe<Subcat_SinglePlyCover_Bool_Exp>;
};


export type Query_RootSubcat_SinglePlyCover_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SinglePlyCover_Order_By>>;
  where?: Maybe<Subcat_SinglePlyCover_Bool_Exp>;
};


export type Query_RootSubcat_SinglePly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SinglePly_Order_By>>;
  where?: Maybe<Subcat_SinglePly_Bool_Exp>;
};


export type Query_RootSubcat_SprayFoamArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SprayFoam_Order_By>>;
  where?: Maybe<Subcat_SprayFoam_Bool_Exp>;
};


export type Query_RootSubcat_SprayFoam_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SprayFoam_Order_By>>;
  where?: Maybe<Subcat_SprayFoam_Bool_Exp>;
};


export type Query_RootSubcat_StandingLapSeamArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_StandingLapSeam_Order_By>>;
  where?: Maybe<Subcat_StandingLapSeam_Bool_Exp>;
};


export type Query_RootSubcat_StandingLapSeam_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_StandingLapSeam_Order_By>>;
  where?: Maybe<Subcat_StandingLapSeam_Bool_Exp>;
};


export type Query_RootSubcat_SteelDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SteelDeck_Order_By>>;
  where?: Maybe<Subcat_SteelDeck_Bool_Exp>;
};


export type Query_RootSubcat_SteelDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SteelDeck_Order_By>>;
  where?: Maybe<Subcat_SteelDeck_Bool_Exp>;
};


export type Query_RootSubcat_SurfacingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Surfacing_Order_By>>;
  where?: Maybe<Subcat_Surfacing_Bool_Exp>;
};


export type Query_RootSubcat_Surfacing_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Surfacing_Order_By>>;
  where?: Maybe<Subcat_Surfacing_Bool_Exp>;
};


export type Query_RootSubcat_VaporRetarderArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_VaporRetarder_Order_By>>;
  where?: Maybe<Subcat_VaporRetarder_Bool_Exp>;
};


export type Query_RootSubcat_VaporRetarder_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_VaporRetarder_Order_By>>;
  where?: Maybe<Subcat_VaporRetarder_Bool_Exp>;
};


export type Query_RootSubcat_WeldArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Weld_Order_By>>;
  where?: Maybe<Subcat_Weld_Bool_Exp>;
};


export type Query_RootSubcat_Weld_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Weld_Order_By>>;
  where?: Maybe<Subcat_Weld_Bool_Exp>;
};


export type Query_RootSubcat_WoodArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Wood_Order_By>>;
  where?: Maybe<Subcat_Wood_Bool_Exp>;
};


export type Query_RootSubcat_WoodFiberDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_WoodFiberDeck_Order_By>>;
  where?: Maybe<Subcat_WoodFiberDeck_Bool_Exp>;
};


export type Query_RootSubcat_WoodFiberDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_WoodFiberDeck_Order_By>>;
  where?: Maybe<Subcat_WoodFiberDeck_Bool_Exp>;
};


export type Query_RootSubcat_Wood_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Wood_Order_By>>;
  where?: Maybe<Subcat_Wood_Bool_Exp>;
};


export type Query_RootSubstratePrimerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubstratePrimer_Order_By>>;
  where?: Maybe<SubstratePrimer_Bool_Exp>;
};


export type Query_RootSubstratePrimerUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubstratePrimerUsage_Order_By>>;
  where?: Maybe<SubstratePrimerUsage_Bool_Exp>;
};


export type Query_RootSubstratePrimerUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubstratePrimerUsage_Order_By>>;
  where?: Maybe<SubstratePrimerUsage_Bool_Exp>;
};


export type Query_RootSubstratePrimerUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootSubstratePrimer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubstratePrimer_Order_By>>;
  where?: Maybe<SubstratePrimer_Bool_Exp>;
};


export type Query_RootSubstratePrimer_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootSurfacingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Surfacing_Order_By>>;
  where?: Maybe<Surfacing_Bool_Exp>;
};


export type Query_RootSurfacingUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SurfacingUsage_Order_By>>;
  where?: Maybe<SurfacingUsage_Bool_Exp>;
};


export type Query_RootSurfacingUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SurfacingUsage_Order_By>>;
  where?: Maybe<SurfacingUsage_Bool_Exp>;
};


export type Query_RootSurfacingUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootSurfacing_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Surfacing_Order_By>>;
  where?: Maybe<Surfacing_Bool_Exp>;
};


export type Query_RootSurfacing_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootTapeWidthArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TapeWidth_Order_By>>;
  where?: Maybe<TapeWidth_Bool_Exp>;
};


export type Query_RootTapeWidth_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TapeWidth_Order_By>>;
  where?: Maybe<TapeWidth_Bool_Exp>;
};


export type Query_RootTapeWidth_By_PkArgs = {
  TapeWidthId: Scalars['Int'];
};


export type Query_RootThermalBarrierArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrier_Order_By>>;
  where?: Maybe<ThermalBarrier_Bool_Exp>;
};


export type Query_RootThermalBarrierBoardSizesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierBoardSizes_Order_By>>;
  where?: Maybe<ThermalBarrierBoardSizes_Bool_Exp>;
};


export type Query_RootThermalBarrierBoardSizes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierBoardSizes_Order_By>>;
  where?: Maybe<ThermalBarrierBoardSizes_Bool_Exp>;
};


export type Query_RootThermalBarrierBoardSizes_By_PkArgs = {
  ComponentId: Scalars['Int'];
  InsBoardSizeId: Scalars['Int'];
};


export type Query_RootThermalBarrierFacerBottomArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerBottom_Order_By>>;
  where?: Maybe<ThermalBarrierFacerBottom_Bool_Exp>;
};


export type Query_RootThermalBarrierFacerBottom_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerBottom_Order_By>>;
  where?: Maybe<ThermalBarrierFacerBottom_Bool_Exp>;
};


export type Query_RootThermalBarrierFacerBottom_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


export type Query_RootThermalBarrierFacerTopArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerTop_Order_By>>;
  where?: Maybe<ThermalBarrierFacerTop_Bool_Exp>;
};


export type Query_RootThermalBarrierFacerTop_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerTop_Order_By>>;
  where?: Maybe<ThermalBarrierFacerTop_Bool_Exp>;
};


export type Query_RootThermalBarrierFacerTop_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


export type Query_RootThermalBarrierUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierUsage_Order_By>>;
  where?: Maybe<ThermalBarrierUsage_Bool_Exp>;
};


export type Query_RootThermalBarrierUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierUsage_Order_By>>;
  where?: Maybe<ThermalBarrierUsage_Bool_Exp>;
};


export type Query_RootThermalBarrierUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootThermalBarrier_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrier_Order_By>>;
  where?: Maybe<ThermalBarrier_Bool_Exp>;
};


export type Query_RootThermalBarrier_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootTopCoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TopCoat_Order_By>>;
  where?: Maybe<TopCoat_Bool_Exp>;
};


export type Query_RootTopCoatUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TopCoatUsage_Order_By>>;
  where?: Maybe<TopCoatUsage_Bool_Exp>;
};


export type Query_RootTopCoatUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TopCoatUsage_Order_By>>;
  where?: Maybe<TopCoatUsage_Bool_Exp>;
};


export type Query_RootTopCoatUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootTopCoat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TopCoat_Order_By>>;
  where?: Maybe<TopCoat_Bool_Exp>;
};


export type Query_RootTopCoat_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Usage_Order_By>>;
  where?: Maybe<Usage_Bool_Exp>;
};


export type Query_RootUsageTradeNameViewArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsageTradeNameView_Order_By>>;
  where?: Maybe<UsageTradeNameView_Bool_Exp>;
};


export type Query_RootUsageTradeNameView_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsageTradeNameView_Order_By>>;
  where?: Maybe<UsageTradeNameView_Bool_Exp>;
};


export type Query_RootUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Usage_Order_By>>;
  where?: Maybe<Usage_Bool_Exp>;
};


export type Query_RootUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootUserReportBugArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserReportBug_Order_By>>;
  where?: Maybe<UserReportBug_Bool_Exp>;
};


export type Query_RootUserReportBug_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserReportBug_Order_By>>;
  where?: Maybe<UserReportBug_Bool_Exp>;
};


export type Query_RootUserReportBug_By_PkArgs = {
  ReportBugId: Scalars['Int'];
};


export type Query_RootUserShareLinkArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserShareLink_Order_By>>;
  where?: Maybe<UserShareLink_Bool_Exp>;
};


export type Query_RootUserShareLink_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserShareLink_Order_By>>;
  where?: Maybe<UserShareLink_Bool_Exp>;
};


export type Query_RootUserShareLink_By_PkArgs = {
  ShareLinkId: Scalars['Int'];
};


export type Query_RootVaporRetarderArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaporRetarder_Order_By>>;
  where?: Maybe<VaporRetarder_Bool_Exp>;
};


export type Query_RootVaporRetarderUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaporRetarderUsage_Order_By>>;
  where?: Maybe<VaporRetarderUsage_Bool_Exp>;
};


export type Query_RootVaporRetarderUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaporRetarderUsage_Order_By>>;
  where?: Maybe<VaporRetarderUsage_Bool_Exp>;
};


export type Query_RootVaporRetarderUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootVaporRetarder_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaporRetarder_Order_By>>;
  where?: Maybe<VaporRetarder_Bool_Exp>;
};


export type Query_RootVaporRetarder_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootWeldArcUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldArcUsage_Order_By>>;
  where?: Maybe<WeldArcUsage_Bool_Exp>;
};


export type Query_RootWeldArcUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldArcUsage_Order_By>>;
  where?: Maybe<WeldArcUsage_Bool_Exp>;
};


export type Query_RootWeldArcUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootWeldHeatUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldHeatUsage_Order_By>>;
  where?: Maybe<WeldHeatUsage_Bool_Exp>;
};


export type Query_RootWeldHeatUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldHeatUsage_Order_By>>;
  where?: Maybe<WeldHeatUsage_Bool_Exp>;
};


export type Query_RootWeldHeatUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootWeldSolventUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldSolventUsage_Order_By>>;
  where?: Maybe<WeldSolventUsage_Bool_Exp>;
};


export type Query_RootWeldSolventUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldSolventUsage_Order_By>>;
  where?: Maybe<WeldSolventUsage_Bool_Exp>;
};


export type Query_RootWeldSolventUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootWeldSystemUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldSystemUsage_Order_By>>;
  where?: Maybe<WeldSystemUsage_Bool_Exp>;
};


export type Query_RootWeldSystemUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldSystemUsage_Order_By>>;
  where?: Maybe<WeldSystemUsage_Bool_Exp>;
};


export type Query_RootWeldSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootWeldTorchedUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldTorchedUsage_Order_By>>;
  where?: Maybe<WeldTorchedUsage_Bool_Exp>;
};


export type Query_RootWeldTorchedUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldTorchedUsage_Order_By>>;
  where?: Maybe<WeldTorchedUsage_Bool_Exp>;
};


export type Query_RootWeldTorchedUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootWoodDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WoodDeck_Order_By>>;
  where?: Maybe<WoodDeck_Bool_Exp>;
};


export type Query_RootWoodDeckUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WoodDeckUsage_Order_By>>;
  where?: Maybe<WoodDeckUsage_Bool_Exp>;
};


export type Query_RootWoodDeckUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WoodDeckUsage_Order_By>>;
  where?: Maybe<WoodDeckUsage_Bool_Exp>;
};


export type Query_RootWoodDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Query_RootWoodDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WoodDeck_Order_By>>;
  where?: Maybe<WoodDeck_Bool_Exp>;
};


export type Query_RootWoodDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Query_RootContact_Api_SavecontactArgs = {
  saveContactInfo: SaveContactInfo;
};


export type Query_RootNavassembly_Api_CountsearchresultArgs = {
  assemblySearchCriteria: AssemblySearchCriteria;
};


export type Query_RootNavassembly_Api_RooftypesearchArgs = {
  assemblySearchCriteria: AssemblySearchCriteria;
};


export type Query_RootNavassembly_Api_SearchArgs = {
  assemblySearchCriteria: AssemblySearchCriteria;
};


export type Query_RootProduct_Api_ListingArgs = {
  productSearchCriteriaWithTarget: ProductSearchCriteriaWithTarget;
};


export type Query_RootProduct_Api_MatchedassemblycountArgs = {
  producMatchAssemblyCountCriteria: ProducMatchAssemblyCountCriteria;
};


export type Query_RootProduct_Api_MatchedassemblylistArgs = {
  productMatchAssemblyListCriteria: ProductMatchAssemblyListCriteria;
};


export type Query_RootProduct_Api_SearchArgs = {
  productSearchCriteria: ProductSearchCriteria;
};


export type Query_RootRoofarea_Api_SaveroofareabaseArgs = {
  saveRoofAreaBaseInfo: SaveRoofAreaBaseInfo;
};


export type Query_RootRoofarea_Api_SaveroofareawithratingArgs = {
  saveRoofAreaWithRating: SaveRoofAreaWithRating;
};


export type Query_RootRoofproject_Api_SavebaseinfoArgs = {
  saveProjectBaseInfo: RoofProjectBaseInfoSave;
};


export type Query_RootRoofproject_Api_SaveprojectnoteArgs = {
  saveProjectNote: RoofProjectNoteSave;
};


export type Query_RootScenario_Api_SavescenarioArgs = {
  saveScenarioInfo: ScenarioInfoSave;
};


export type Query_RootVNavAssembly_LayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Layers_Order_By>>;
  where?: Maybe<VNavAssembly_Layers_Bool_Exp>;
};


export type Query_RootVNavAssembly_Layers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Layers_Order_By>>;
  where?: Maybe<VNavAssembly_Layers_Bool_Exp>;
};


export type Query_RootVNavAssembly_ManufacturerOnlySearchArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_ManufacturerOnlySearch_Order_By>>;
  where?: Maybe<VNavAssembly_ManufacturerOnlySearch_Bool_Exp>;
};


export type Query_RootVNavAssembly_ManufacturerOnlySearch_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_ManufacturerOnlySearch_Order_By>>;
  where?: Maybe<VNavAssembly_ManufacturerOnlySearch_Bool_Exp>;
};


export type Query_RootVNavAssembly_RoofTypeCountArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_RoofTypeCount_Order_By>>;
  where?: Maybe<VNavAssembly_RoofTypeCount_Bool_Exp>;
};


export type Query_RootVNavAssembly_RoofTypeCount_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_RoofTypeCount_Order_By>>;
  where?: Maybe<VNavAssembly_RoofTypeCount_Bool_Exp>;
};


export type Query_RootVNavAssembly_SearchArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_AirRetarderArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_AirRetarder_Order_By>>;
  where?: Maybe<VNavAssembly_Search_AirRetarder_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_AirRetarder_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_AirRetarder_Order_By>>;
  where?: Maybe<VNavAssembly_Search_AirRetarder_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_BaseCoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BaseCoat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BaseCoat_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_BaseCoat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BaseCoat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BaseCoat_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_BasePlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BasePly_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BasePly_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_BasePly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BasePly_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BasePly_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_BaseSheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BaseSheet_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BaseSheet_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_BaseSheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BaseSheet_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BaseSheet_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_BoardStockArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BoardStock_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BoardStock_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_BoardStock_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BoardStock_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BoardStock_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_CapPlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CapPly_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CapPly_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_CapPly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CapPly_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CapPly_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_CoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Coat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Coat_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_Coat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Coat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Coat_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_CoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Coating_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Coating_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_Coating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Coating_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Coating_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_CoverBoardArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverBoard_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverBoard_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_CoverBoard_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverBoard_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverBoard_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_CoverCompositePanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverCompositePanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverCompositePanel_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_CoverCompositePanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverCompositePanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverCompositePanel_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_CoverSingleplyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverSingleply_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverSingleply_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_CoverSingleply_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverSingleply_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverSingleply_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_CoverStandingLapSeamArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverStandingLapSeam_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_CoverStandingLapSeam_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverStandingLapSeam_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_CoverStripDiskArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverStripDisk_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverStripDisk_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_CoverStripDisk_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverStripDisk_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverStripDisk_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_DeckCementitiousPanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_DeckCementitiousPanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_DeckCementitiousPanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_DeckCementitiousPanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_DeckSteelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_DeckSteel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_DeckSteel_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_DeckSteel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_DeckSteel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_DeckSteel_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_FormDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_FormDeck_Order_By>>;
  where?: Maybe<VNavAssembly_Search_FormDeck_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_FormDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_FormDeck_Order_By>>;
  where?: Maybe<VNavAssembly_Search_FormDeck_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_InsulationBattBlanketArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_InsulationBattBlanket_Order_By>>;
  where?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_InsulationBattBlanket_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_InsulationBattBlanket_Order_By>>;
  where?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_InsulationSprayFoamArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_InsulationSprayFoam_Order_By>>;
  where?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_InsulationSprayFoam_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_InsulationSprayFoam_Order_By>>;
  where?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_LightweightInsulatingConcreteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_LightweightInsulatingConcrete_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_LinerPanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_LinerPanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_LinerPanel_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_LinerPanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_LinerPanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_LinerPanel_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_PlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Ply_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Ply_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_Ply_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Ply_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Ply_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_SeparatorSheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_SeparatorSheet_Order_By>>;
  where?: Maybe<VNavAssembly_Search_SeparatorSheet_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_SeparatorSheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_SeparatorSheet_Order_By>>;
  where?: Maybe<VNavAssembly_Search_SeparatorSheet_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_SubstratePrimerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_SubstratePrimer_Order_By>>;
  where?: Maybe<VNavAssembly_Search_SubstratePrimer_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_SubstratePrimer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_SubstratePrimer_Order_By>>;
  where?: Maybe<VNavAssembly_Search_SubstratePrimer_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_SurfacingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Surfacing_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Surfacing_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_Surfacing_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Surfacing_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Surfacing_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_ThermalBarrierArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_ThermalBarrier_Order_By>>;
  where?: Maybe<VNavAssembly_Search_ThermalBarrier_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_ThermalBarrier_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_ThermalBarrier_Order_By>>;
  where?: Maybe<VNavAssembly_Search_ThermalBarrier_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_TopCoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_TopCoat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_TopCoat_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_TopCoat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_TopCoat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_TopCoat_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_VaporRetarderArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_VaporRetarder_Order_By>>;
  where?: Maybe<VNavAssembly_Search_VaporRetarder_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_VaporRetarder_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_VaporRetarder_Order_By>>;
  where?: Maybe<VNavAssembly_Search_VaporRetarder_Bool_Exp>;
};


export type Query_RootVNavAssembly_Search_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Bool_Exp>;
};


export type Query_RootVNavAssembly_UsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Usages_Order_By>>;
  where?: Maybe<VNavAssembly_Usages_Bool_Exp>;
};


export type Query_RootVNavAssembly_Usages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Usages_Order_By>>;
  where?: Maybe<VNavAssembly_Usages_Bool_Exp>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "Address" */
  Address: Array<Address>;
  /** fetch aggregated fields from the table: "Address" */
  Address_aggregate: Address_Aggregate;
  /** fetch data from the table: "Address" using primary key columns */
  Address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "AdheredSinglePlyOrphanReport" */
  AdheredSinglePlyOrphanReport: Array<AdheredSinglePlyOrphanReport>;
  /** fetch aggregated fields from the table: "AdheredSinglePlyOrphanReport" */
  AdheredSinglePlyOrphanReport_aggregate: AdheredSinglePlyOrphanReport_Aggregate;
  /** fetch data from the table: "AdheredSinglePlyOrphanReport" using primary key columns */
  AdheredSinglePlyOrphanReport_by_pk?: Maybe<AdheredSinglePlyOrphanReport>;
  /** fetch data from the table: "AdhesiveFullApplication" */
  AdhesiveFullApplication: Array<AdhesiveFullApplication>;
  /** fetch data from the table: "AdhesiveFullApplicationUsage" */
  AdhesiveFullApplicationUsage: Array<AdhesiveFullApplicationUsage>;
  /** fetch aggregated fields from the table: "AdhesiveFullApplicationUsage" */
  AdhesiveFullApplicationUsage_aggregate: AdhesiveFullApplicationUsage_Aggregate;
  /** fetch data from the table: "AdhesiveFullApplicationUsage" using primary key columns */
  AdhesiveFullApplicationUsage_by_pk?: Maybe<AdhesiveFullApplicationUsage>;
  /** fetch aggregated fields from the table: "AdhesiveFullApplication" */
  AdhesiveFullApplication_aggregate: AdhesiveFullApplication_Aggregate;
  /** fetch data from the table: "AdhesiveFullApplication" using primary key columns */
  AdhesiveFullApplication_by_pk?: Maybe<AdhesiveFullApplication>;
  /** fetch data from the table: "AdhesiveRibbonUsage" */
  AdhesiveRibbonUsage: Array<AdhesiveRibbonUsage>;
  /** fetch aggregated fields from the table: "AdhesiveRibbonUsage" */
  AdhesiveRibbonUsage_aggregate: AdhesiveRibbonUsage_Aggregate;
  /** fetch data from the table: "AdhesiveRibbonUsage" using primary key columns */
  AdhesiveRibbonUsage_by_pk?: Maybe<AdhesiveRibbonUsage>;
  /** An array relationship */
  AdhesiveRibbons: Array<AdhesiveRibbons>;
  /** An aggregate relationship */
  AdhesiveRibbons_aggregate: AdhesiveRibbons_Aggregate;
  /** fetch data from the table: "AdhesiveRibbons" using primary key columns */
  AdhesiveRibbons_by_pk?: Maybe<AdhesiveRibbons>;
  /** An array relationship */
  AdhesiveSpots: Array<AdhesiveSpots>;
  /** fetch data from the table: "AdhesiveSpotsUsage" */
  AdhesiveSpotsUsage: Array<AdhesiveSpotsUsage>;
  /** fetch aggregated fields from the table: "AdhesiveSpotsUsage" */
  AdhesiveSpotsUsage_aggregate: AdhesiveSpotsUsage_Aggregate;
  /** fetch data from the table: "AdhesiveSpotsUsage" using primary key columns */
  AdhesiveSpotsUsage_by_pk?: Maybe<AdhesiveSpotsUsage>;
  /** An aggregate relationship */
  AdhesiveSpots_aggregate: AdhesiveSpots_Aggregate;
  /** fetch data from the table: "AdhesiveSpots" using primary key columns */
  AdhesiveSpots_by_pk?: Maybe<AdhesiveSpots>;
  /** fetch data from the table: "AdhesiveSystemUsage" */
  AdhesiveSystemUsage: Array<AdhesiveSystemUsage>;
  /** fetch aggregated fields from the table: "AdhesiveSystemUsage" */
  AdhesiveSystemUsage_aggregate: AdhesiveSystemUsage_Aggregate;
  /** fetch data from the table: "AdhesiveSystemUsage" using primary key columns */
  AdhesiveSystemUsage_by_pk?: Maybe<AdhesiveSystemUsage>;
  /** fetch data from the table: "AirRetarder" */
  AirRetarder: Array<AirRetarder>;
  /** fetch data from the table: "AirRetarderUsage" */
  AirRetarderUsage: Array<AirRetarderUsage>;
  /** fetch aggregated fields from the table: "AirRetarderUsage" */
  AirRetarderUsage_aggregate: AirRetarderUsage_Aggregate;
  /** fetch data from the table: "AirRetarderUsage" using primary key columns */
  AirRetarderUsage_by_pk?: Maybe<AirRetarderUsage>;
  /** fetch aggregated fields from the table: "AirRetarder" */
  AirRetarder_aggregate: AirRetarder_Aggregate;
  /** fetch data from the table: "AirRetarder" using primary key columns */
  AirRetarder_by_pk?: Maybe<AirRetarder>;
  /** fetch data from the table: "AllManufacturedProducts" */
  AllManufacturedProducts: Array<AllManufacturedProducts>;
  /** fetch aggregated fields from the table: "AllManufacturedProducts" */
  AllManufacturedProducts_aggregate: AllManufacturedProducts_Aggregate;
  /** fetch data from the table: "Anchor" */
  Anchor: Array<Anchor>;
  /** fetch aggregated fields from the table: "Anchor" */
  Anchor_aggregate: Anchor_Aggregate;
  /** fetch data from the table: "Anchor" using primary key columns */
  Anchor_by_pk?: Maybe<Anchor>;
  /** fetch data from the table: "ApprovalsDoc" */
  ApprovalsDoc: Array<ApprovalsDoc>;
  /** fetch aggregated fields from the table: "ApprovalsDoc" */
  ApprovalsDoc_aggregate: ApprovalsDoc_Aggregate;
  /** fetch data from the table: "ApprovalsDoc" using primary key columns */
  ApprovalsDoc_by_pk?: Maybe<ApprovalsDoc>;
  /** fetch data from the table: "ApprovedLayerUse" */
  ApprovedLayerUse: Array<ApprovedLayerUse>;
  /** fetch aggregated fields from the table: "ApprovedLayerUse" */
  ApprovedLayerUse_aggregate: ApprovedLayerUse_Aggregate;
  /** fetch data from the table: "ApprovedLayerUse" using primary key columns */
  ApprovedLayerUse_by_pk?: Maybe<ApprovedLayerUse>;
  /** fetch data from the table: "AssemblyReconfigQueue" */
  AssemblyReconfigQueue: Array<AssemblyReconfigQueue>;
  /** fetch aggregated fields from the table: "AssemblyReconfigQueue" */
  AssemblyReconfigQueue_aggregate: AssemblyReconfigQueue_Aggregate;
  /** fetch data from the table: "AssemblyReconfigQueue" using primary key columns */
  AssemblyReconfigQueue_by_pk?: Maybe<AssemblyReconfigQueue>;
  /** fetch data from the table: "BaseCoat" */
  BaseCoat: Array<BaseCoat>;
  /** fetch data from the table: "BaseCoatUsage" */
  BaseCoatUsage: Array<BaseCoatUsage>;
  /** fetch aggregated fields from the table: "BaseCoatUsage" */
  BaseCoatUsage_aggregate: BaseCoatUsage_Aggregate;
  /** fetch data from the table: "BaseCoatUsage" using primary key columns */
  BaseCoatUsage_by_pk?: Maybe<BaseCoatUsage>;
  /** fetch aggregated fields from the table: "BaseCoat" */
  BaseCoat_aggregate: BaseCoat_Aggregate;
  /** fetch data from the table: "BaseCoat" using primary key columns */
  BaseCoat_by_pk?: Maybe<BaseCoat>;
  /** fetch data from the table: "BasePly" */
  BasePly: Array<BasePly>;
  /** fetch data from the table: "BasePlyUsage" */
  BasePlyUsage: Array<BasePlyUsage>;
  /** fetch aggregated fields from the table: "BasePlyUsage" */
  BasePlyUsage_aggregate: BasePlyUsage_Aggregate;
  /** fetch data from the table: "BasePlyUsage" using primary key columns */
  BasePlyUsage_by_pk?: Maybe<BasePlyUsage>;
  /** fetch aggregated fields from the table: "BasePly" */
  BasePly_aggregate: BasePly_Aggregate;
  /** fetch data from the table: "BasePly" using primary key columns */
  BasePly_by_pk?: Maybe<BasePly>;
  /** fetch data from the table: "BaseSheet" */
  BaseSheet: Array<BaseSheet>;
  /** fetch data from the table: "BaseSheetUsage" */
  BaseSheetUsage: Array<BaseSheetUsage>;
  /** fetch aggregated fields from the table: "BaseSheetUsage" */
  BaseSheetUsage_aggregate: BaseSheetUsage_Aggregate;
  /** fetch data from the table: "BaseSheetUsage" using primary key columns */
  BaseSheetUsage_by_pk?: Maybe<BaseSheetUsage>;
  /** fetch aggregated fields from the table: "BaseSheet" */
  BaseSheet_aggregate: BaseSheet_Aggregate;
  /** fetch data from the table: "BaseSheet" using primary key columns */
  BaseSheet_by_pk?: Maybe<BaseSheet>;
  /** fetch data from the table: "BattBlanketInsulation" */
  BattBlanketInsulation: Array<BattBlanketInsulation>;
  /** fetch aggregated fields from the table: "BattBlanketInsulation" */
  BattBlanketInsulation_aggregate: BattBlanketInsulation_Aggregate;
  /** fetch data from the table: "BattBlanketInsulation" using primary key columns */
  BattBlanketInsulation_by_pk?: Maybe<BattBlanketInsulation>;
  /** fetch data from the table: "BattBlanketUsage" */
  BattBlanketUsage: Array<BattBlanketUsage>;
  /** fetch aggregated fields from the table: "BattBlanketUsage" */
  BattBlanketUsage_aggregate: BattBlanketUsage_Aggregate;
  /** fetch data from the table: "BattBlanketUsage" using primary key columns */
  BattBlanketUsage_by_pk?: Maybe<BattBlanketUsage>;
  /** fetch data from the table: "BattenBar" */
  BattenBar: Array<BattenBar>;
  /** fetch data from the table: "BattenBarUsage" */
  BattenBarUsage: Array<BattenBarUsage>;
  /** fetch aggregated fields from the table: "BattenBarUsage" */
  BattenBarUsage_aggregate: BattenBarUsage_Aggregate;
  /** fetch data from the table: "BattenBarUsage" using primary key columns */
  BattenBarUsage_by_pk?: Maybe<BattenBarUsage>;
  /** fetch aggregated fields from the table: "BattenBar" */
  BattenBar_aggregate: BattenBar_Aggregate;
  /** fetch data from the table: "BattenBar" using primary key columns */
  BattenBar_by_pk?: Maybe<BattenBar>;
  /** fetch data from the table: "BoardSizes" */
  BoardSizes: Array<BoardSizes>;
  /** fetch aggregated fields from the table: "BoardSizes" */
  BoardSizes_aggregate: BoardSizes_Aggregate;
  /** fetch data from the table: "BoardStock" */
  BoardStock: Array<BoardStock>;
  /** An array relationship */
  BoardStockBoardSizes: Array<BoardStockBoardSizes>;
  /** An aggregate relationship */
  BoardStockBoardSizes_aggregate: BoardStockBoardSizes_Aggregate;
  /** fetch data from the table: "BoardStockBoardSizes" using primary key columns */
  BoardStockBoardSizes_by_pk?: Maybe<BoardStockBoardSizes>;
  /** fetch data from the table: "BoardStockFacerBottom" */
  BoardStockFacerBottom: Array<BoardStockFacerBottom>;
  /** fetch aggregated fields from the table: "BoardStockFacerBottom" */
  BoardStockFacerBottom_aggregate: BoardStockFacerBottom_Aggregate;
  /** fetch data from the table: "BoardStockFacerBottom" using primary key columns */
  BoardStockFacerBottom_by_pk?: Maybe<BoardStockFacerBottom>;
  /** fetch data from the table: "BoardStockFacerTop" */
  BoardStockFacerTop: Array<BoardStockFacerTop>;
  /** fetch aggregated fields from the table: "BoardStockFacerTop" */
  BoardStockFacerTop_aggregate: BoardStockFacerTop_Aggregate;
  /** fetch data from the table: "BoardStockFacerTop" using primary key columns */
  BoardStockFacerTop_by_pk?: Maybe<BoardStockFacerTop>;
  /** fetch data from the table: "BoardStockUsage" */
  BoardStockUsage: Array<BoardStockUsage>;
  /** fetch aggregated fields from the table: "BoardStockUsage" */
  BoardStockUsage_aggregate: BoardStockUsage_Aggregate;
  /** fetch data from the table: "BoardStockUsage" using primary key columns */
  BoardStockUsage_by_pk?: Maybe<BoardStockUsage>;
  /** fetch aggregated fields from the table: "BoardStock" */
  BoardStock_aggregate: BoardStock_Aggregate;
  /** fetch data from the table: "BoardStock" using primary key columns */
  BoardStock_by_pk?: Maybe<BoardStock>;
  /** fetch data from the table: "BroadcastAlert" */
  BroadcastAlert: Array<BroadcastAlert>;
  /** fetch aggregated fields from the table: "BroadcastAlert" */
  BroadcastAlert_aggregate: BroadcastAlert_Aggregate;
  /** fetch data from the table: "BroadcastAlert" using primary key columns */
  BroadcastAlert_by_pk?: Maybe<BroadcastAlert>;
  /** fetch data from the table: "BuiltupVaporRetarderSystemUsage" */
  BuiltupVaporRetarderSystemUsage: Array<BuiltupVaporRetarderSystemUsage>;
  /** fetch aggregated fields from the table: "BuiltupVaporRetarderSystemUsage" */
  BuiltupVaporRetarderSystemUsage_aggregate: BuiltupVaporRetarderSystemUsage_Aggregate;
  /** fetch data from the table: "BuiltupVaporRetarderSystemUsage" using primary key columns */
  BuiltupVaporRetarderSystemUsage_by_pk?: Maybe<BuiltupVaporRetarderSystemUsage>;
  /** fetch data from the table: "C_Additive" */
  C_Additive: Array<C_Additive>;
  /** fetch aggregated fields from the table: "C_Additive" */
  C_Additive_aggregate: C_Additive_Aggregate;
  /** fetch data from the table: "C_Additive" using primary key columns */
  C_Additive_by_pk?: Maybe<C_Additive>;
  /** fetch data from the table: "C_AdhesiveApplicationMethod" */
  C_AdhesiveApplicationMethod: Array<C_AdhesiveApplicationMethod>;
  /** fetch aggregated fields from the table: "C_AdhesiveApplicationMethod" */
  C_AdhesiveApplicationMethod_aggregate: C_AdhesiveApplicationMethod_Aggregate;
  /** fetch data from the table: "C_AdhesiveApplicationMethod" using primary key columns */
  C_AdhesiveApplicationMethod_by_pk?: Maybe<C_AdhesiveApplicationMethod>;
  /** fetch data from the table: "C_AdhesiveMaterial" */
  C_AdhesiveMaterial: Array<C_AdhesiveMaterial>;
  /** fetch aggregated fields from the table: "C_AdhesiveMaterial" */
  C_AdhesiveMaterial_aggregate: C_AdhesiveMaterial_Aggregate;
  /** fetch data from the table: "C_AdhesiveMaterial" using primary key columns */
  C_AdhesiveMaterial_by_pk?: Maybe<C_AdhesiveMaterial>;
  /** fetch data from the table: "C_AdhesiveType" */
  C_AdhesiveType: Array<C_AdhesiveType>;
  /** fetch aggregated fields from the table: "C_AdhesiveType" */
  C_AdhesiveType_aggregate: C_AdhesiveType_Aggregate;
  /** fetch data from the table: "C_AdhesiveType" using primary key columns */
  C_AdhesiveType_by_pk?: Maybe<C_AdhesiveType>;
  /** fetch data from the table: "C_Aggregate" */
  C_Aggregate: Array<C_Aggregate>;
  /** fetch aggregated fields from the table: "C_Aggregate" */
  C_Aggregate_aggregate: C_Aggregate_Aggregate;
  /** fetch data from the table: "C_Aggregate" using primary key columns */
  C_Aggregate_by_pk?: Maybe<C_Aggregate>;
  /** fetch data from the table: "C_ApprovalsDocCategory" */
  C_ApprovalsDocCategory: Array<C_ApprovalsDocCategory>;
  /** fetch aggregated fields from the table: "C_ApprovalsDocCategory" */
  C_ApprovalsDocCategory_aggregate: C_ApprovalsDocCategory_Aggregate;
  /** fetch data from the table: "C_ApprovalsDocCategory" using primary key columns */
  C_ApprovalsDocCategory_by_pk?: Maybe<C_ApprovalsDocCategory>;
  /** fetch data from the table: "C_ApprovedUse" */
  C_ApprovedUse: Array<C_ApprovedUse>;
  /** fetch aggregated fields from the table: "C_ApprovedUse" */
  C_ApprovedUse_aggregate: C_ApprovedUse_Aggregate;
  /** fetch data from the table: "C_ApprovedUse" using primary key columns */
  C_ApprovedUse_by_pk?: Maybe<C_ApprovedUse>;
  /** fetch data from the table: "C_AssemblyApplicationType" */
  C_AssemblyApplicationType: Array<C_AssemblyApplicationType>;
  /** fetch aggregated fields from the table: "C_AssemblyApplicationType" */
  C_AssemblyApplicationType_aggregate: C_AssemblyApplicationType_Aggregate;
  /** fetch data from the table: "C_AssemblyApplicationType" using primary key columns */
  C_AssemblyApplicationType_by_pk?: Maybe<C_AssemblyApplicationType>;
  /** fetch data from the table: "C_AssemblyType" */
  C_AssemblyType: Array<C_AssemblyType>;
  /** fetch aggregated fields from the table: "C_AssemblyType" */
  C_AssemblyType_aggregate: C_AssemblyType_Aggregate;
  /** fetch data from the table: "C_AssemblyType" using primary key columns */
  C_AssemblyType_by_pk?: Maybe<C_AssemblyType>;
  /** fetch data from the table: "C_BackingMaterial" */
  C_BackingMaterial: Array<C_BackingMaterial>;
  /** fetch aggregated fields from the table: "C_BackingMaterial" */
  C_BackingMaterial_aggregate: C_BackingMaterial_Aggregate;
  /** fetch data from the table: "C_BackingMaterial" using primary key columns */
  C_BackingMaterial_by_pk?: Maybe<C_BackingMaterial>;
  /** fetch data from the table: "C_BaseSheetApplicationMethod" */
  C_BaseSheetApplicationMethod: Array<C_BaseSheetApplicationMethod>;
  /** fetch aggregated fields from the table: "C_BaseSheetApplicationMethod" */
  C_BaseSheetApplicationMethod_aggregate: C_BaseSheetApplicationMethod_Aggregate;
  /** fetch data from the table: "C_BaseSheetApplicationMethod" using primary key columns */
  C_BaseSheetApplicationMethod_by_pk?: Maybe<C_BaseSheetApplicationMethod>;
  /** fetch data from the table: "C_BattBlanketFacerMaterial" */
  C_BattBlanketFacerMaterial: Array<C_BattBlanketFacerMaterial>;
  /** fetch aggregated fields from the table: "C_BattBlanketFacerMaterial" */
  C_BattBlanketFacerMaterial_aggregate: C_BattBlanketFacerMaterial_Aggregate;
  /** fetch data from the table: "C_BattBlanketFacerMaterial" using primary key columns */
  C_BattBlanketFacerMaterial_by_pk?: Maybe<C_BattBlanketFacerMaterial>;
  /** fetch data from the table: "C_BattBlanketMaterial" */
  C_BattBlanketMaterial: Array<C_BattBlanketMaterial>;
  /** fetch aggregated fields from the table: "C_BattBlanketMaterial" */
  C_BattBlanketMaterial_aggregate: C_BattBlanketMaterial_Aggregate;
  /** fetch data from the table: "C_BattBlanketMaterial" using primary key columns */
  C_BattBlanketMaterial_by_pk?: Maybe<C_BattBlanketMaterial>;
  /** fetch data from the table: "C_BoardProfile" */
  C_BoardProfile: Array<C_BoardProfile>;
  /** fetch aggregated fields from the table: "C_BoardProfile" */
  C_BoardProfile_aggregate: C_BoardProfile_Aggregate;
  /** fetch data from the table: "C_BoardProfile" using primary key columns */
  C_BoardProfile_by_pk?: Maybe<C_BoardProfile>;
  /** fetch data from the table: "C_BoardStockMaterial" */
  C_BoardStockMaterial: Array<C_BoardStockMaterial>;
  /** fetch aggregated fields from the table: "C_BoardStockMaterial" */
  C_BoardStockMaterial_aggregate: C_BoardStockMaterial_Aggregate;
  /** fetch data from the table: "C_BoardStockMaterial" using primary key columns */
  C_BoardStockMaterial_by_pk?: Maybe<C_BoardStockMaterial>;
  /** fetch data from the table: "C_BuildingEnclosure" */
  C_BuildingEnclosure: Array<C_BuildingEnclosure>;
  /** fetch aggregated fields from the table: "C_BuildingEnclosure" */
  C_BuildingEnclosure_aggregate: C_BuildingEnclosure_Aggregate;
  /** fetch data from the table: "C_BuildingEnclosure" using primary key columns */
  C_BuildingEnclosure_by_pk?: Maybe<C_BuildingEnclosure>;
  /** fetch data from the table: "C_CementAggregateRatio" */
  C_CementAggregateRatio: Array<C_CementAggregateRatio>;
  /** fetch aggregated fields from the table: "C_CementAggregateRatio" */
  C_CementAggregateRatio_aggregate: C_CementAggregateRatio_Aggregate;
  /** fetch data from the table: "C_CementAggregateRatio" using primary key columns */
  C_CementAggregateRatio_by_pk?: Maybe<C_CementAggregateRatio>;
  /** fetch data from the table: "C_CoatingMaterial" */
  C_CoatingMaterial: Array<C_CoatingMaterial>;
  /** fetch aggregated fields from the table: "C_CoatingMaterial" */
  C_CoatingMaterial_aggregate: C_CoatingMaterial_Aggregate;
  /** fetch data from the table: "C_CoatingMaterial" using primary key columns */
  C_CoatingMaterial_by_pk?: Maybe<C_CoatingMaterial>;
  /** fetch data from the table: "C_ComponentType" */
  C_ComponentType: Array<C_ComponentType>;
  /** fetch aggregated fields from the table: "C_ComponentType" */
  C_ComponentType_aggregate: C_ComponentType_Aggregate;
  /** fetch data from the table: "C_ComponentType" using primary key columns */
  C_ComponentType_by_pk?: Maybe<C_ComponentType>;
  /** fetch data from the table: "C_ContactType" */
  C_ContactType: Array<C_ContactType>;
  /** fetch aggregated fields from the table: "C_ContactType" */
  C_ContactType_aggregate: C_ContactType_Aggregate;
  /** fetch data from the table: "C_ContactType" using primary key columns */
  C_ContactType_by_pk?: Maybe<C_ContactType>;
  /** fetch data from the table: "C_Country" */
  C_Country: Array<C_Country>;
  /** fetch aggregated fields from the table: "C_Country" */
  C_Country_aggregate: C_Country_Aggregate;
  /** fetch data from the table: "C_Country" using primary key columns */
  C_Country_by_pk?: Maybe<C_Country>;
  /** fetch data from the table: "C_CoverBoardMaterial" */
  C_CoverBoardMaterial: Array<C_CoverBoardMaterial>;
  /** fetch aggregated fields from the table: "C_CoverBoardMaterial" */
  C_CoverBoardMaterial_aggregate: C_CoverBoardMaterial_Aggregate;
  /** fetch data from the table: "C_CoverBoardMaterial" using primary key columns */
  C_CoverBoardMaterial_by_pk?: Maybe<C_CoverBoardMaterial>;
  /** fetch data from the table: "C_CoverColor" */
  C_CoverColor: Array<C_CoverColor>;
  /** fetch aggregated fields from the table: "C_CoverColor" */
  C_CoverColor_aggregate: C_CoverColor_Aggregate;
  /** fetch data from the table: "C_CoverColor" using primary key columns */
  C_CoverColor_by_pk?: Maybe<C_CoverColor>;
  /** fetch data from the table: "C_CoverSecurementMethod" */
  C_CoverSecurementMethod: Array<C_CoverSecurementMethod>;
  /** fetch aggregated fields from the table: "C_CoverSecurementMethod" */
  C_CoverSecurementMethod_aggregate: C_CoverSecurementMethod_Aggregate;
  /** fetch data from the table: "C_CoverSecurementMethod" using primary key columns */
  C_CoverSecurementMethod_by_pk?: Maybe<C_CoverSecurementMethod>;
  /** fetch data from the table: "C_DeckType" */
  C_DeckType: Array<C_DeckType>;
  /** fetch aggregated fields from the table: "C_DeckType" */
  C_DeckType_aggregate: C_DeckType_Aggregate;
  /** fetch data from the table: "C_DeckType" using primary key columns */
  C_DeckType_by_pk?: Maybe<C_DeckType>;
  /** fetch data from the table: "C_ExistingRoofCover" */
  C_ExistingRoofCover: Array<C_ExistingRoofCover>;
  /** fetch aggregated fields from the table: "C_ExistingRoofCover" */
  C_ExistingRoofCover_aggregate: C_ExistingRoofCover_Aggregate;
  /** fetch data from the table: "C_ExistingRoofCover" using primary key columns */
  C_ExistingRoofCover_by_pk?: Maybe<C_ExistingRoofCover>;
  /** fetch data from the table: "C_ExistingRoofDeck" */
  C_ExistingRoofDeck: Array<C_ExistingRoofDeck>;
  /** fetch aggregated fields from the table: "C_ExistingRoofDeck" */
  C_ExistingRoofDeck_aggregate: C_ExistingRoofDeck_Aggregate;
  /** fetch data from the table: "C_ExistingRoofDeck" using primary key columns */
  C_ExistingRoofDeck_by_pk?: Maybe<C_ExistingRoofDeck>;
  /** fetch data from the table: "C_ExistingRoofInsulationMaterial" */
  C_ExistingRoofInsulationMaterial: Array<C_ExistingRoofInsulationMaterial>;
  /** fetch aggregated fields from the table: "C_ExistingRoofInsulationMaterial" */
  C_ExistingRoofInsulationMaterial_aggregate: C_ExistingRoofInsulationMaterial_Aggregate;
  /** fetch data from the table: "C_ExistingRoofInsulationMaterial" using primary key columns */
  C_ExistingRoofInsulationMaterial_by_pk?: Maybe<C_ExistingRoofInsulationMaterial>;
  /** fetch data from the table: "C_ExistingRoofSinglePlyMaterial" */
  C_ExistingRoofSinglePlyMaterial: Array<C_ExistingRoofSinglePlyMaterial>;
  /** fetch aggregated fields from the table: "C_ExistingRoofSinglePlyMaterial" */
  C_ExistingRoofSinglePlyMaterial_aggregate: C_ExistingRoofSinglePlyMaterial_Aggregate;
  /** fetch data from the table: "C_ExistingRoofSinglePlyMaterial" using primary key columns */
  C_ExistingRoofSinglePlyMaterial_by_pk?: Maybe<C_ExistingRoofSinglePlyMaterial>;
  /** fetch data from the table: "C_ExistingRoofSurface" */
  C_ExistingRoofSurface: Array<C_ExistingRoofSurface>;
  /** fetch aggregated fields from the table: "C_ExistingRoofSurface" */
  C_ExistingRoofSurface_aggregate: C_ExistingRoofSurface_Aggregate;
  /** fetch data from the table: "C_ExistingRoofSurface" using primary key columns */
  C_ExistingRoofSurface_by_pk?: Maybe<C_ExistingRoofSurface>;
  /** fetch data from the table: "C_ExtFireRating" */
  C_ExtFireRating: Array<C_ExtFireRating>;
  /** fetch aggregated fields from the table: "C_ExtFireRating" */
  C_ExtFireRating_aggregate: C_ExtFireRating_Aggregate;
  /** fetch data from the table: "C_ExtFireRating" using primary key columns */
  C_ExtFireRating_by_pk?: Maybe<C_ExtFireRating>;
  /** fetch data from the table: "C_Facer" */
  C_Facer: Array<C_Facer>;
  /** fetch data from the table: "C_FacerMaterial" */
  C_FacerMaterial: Array<C_FacerMaterial>;
  /** fetch aggregated fields from the table: "C_FacerMaterial" */
  C_FacerMaterial_aggregate: C_FacerMaterial_Aggregate;
  /** fetch data from the table: "C_FacerMaterial" using primary key columns */
  C_FacerMaterial_by_pk?: Maybe<C_FacerMaterial>;
  /** fetch aggregated fields from the table: "C_Facer" */
  C_Facer_aggregate: C_Facer_Aggregate;
  /** fetch data from the table: "C_Facer" using primary key columns */
  C_Facer_by_pk?: Maybe<C_Facer>;
  /** fetch data from the table: "C_FastenerCoating" */
  C_FastenerCoating: Array<C_FastenerCoating>;
  /** fetch aggregated fields from the table: "C_FastenerCoating" */
  C_FastenerCoating_aggregate: C_FastenerCoating_Aggregate;
  /** fetch data from the table: "C_FastenerCoating" using primary key columns */
  C_FastenerCoating_by_pk?: Maybe<C_FastenerCoating>;
  /** fetch data from the table: "C_FastenerDriveHead" */
  C_FastenerDriveHead: Array<C_FastenerDriveHead>;
  /** fetch aggregated fields from the table: "C_FastenerDriveHead" */
  C_FastenerDriveHead_aggregate: C_FastenerDriveHead_Aggregate;
  /** fetch data from the table: "C_FastenerDriveHead" using primary key columns */
  C_FastenerDriveHead_by_pk?: Maybe<C_FastenerDriveHead>;
  /** fetch data from the table: "C_FastenerType" */
  C_FastenerType: Array<C_FastenerType>;
  /** fetch aggregated fields from the table: "C_FastenerType" */
  C_FastenerType_aggregate: C_FastenerType_Aggregate;
  /** fetch data from the table: "C_FastenerType" using primary key columns */
  C_FastenerType_by_pk?: Maybe<C_FastenerType>;
  /** fetch data from the table: "C_FasteningApplicationMethod" */
  C_FasteningApplicationMethod: Array<C_FasteningApplicationMethod>;
  /** fetch aggregated fields from the table: "C_FasteningApplicationMethod" */
  C_FasteningApplicationMethod_aggregate: C_FasteningApplicationMethod_Aggregate;
  /** fetch data from the table: "C_FasteningApplicationMethod" using primary key columns */
  C_FasteningApplicationMethod_by_pk?: Maybe<C_FasteningApplicationMethod>;
  /** fetch data from the table: "C_FlashingMaterial" */
  C_FlashingMaterial: Array<C_FlashingMaterial>;
  /** fetch aggregated fields from the table: "C_FlashingMaterial" */
  C_FlashingMaterial_aggregate: C_FlashingMaterial_Aggregate;
  /** fetch data from the table: "C_FlashingMaterial" using primary key columns */
  C_FlashingMaterial_by_pk?: Maybe<C_FlashingMaterial>;
  /** fetch data from the table: "C_FlashingType" */
  C_FlashingType: Array<C_FlashingType>;
  /** fetch aggregated fields from the table: "C_FlashingType" */
  C_FlashingType_aggregate: C_FlashingType_Aggregate;
  /** fetch data from the table: "C_FlashingType" using primary key columns */
  C_FlashingType_by_pk?: Maybe<C_FlashingType>;
  /** fetch data from the table: "C_FormDeckMaterial" */
  C_FormDeckMaterial: Array<C_FormDeckMaterial>;
  /** fetch aggregated fields from the table: "C_FormDeckMaterial" */
  C_FormDeckMaterial_aggregate: C_FormDeckMaterial_Aggregate;
  /** fetch data from the table: "C_FormDeckMaterial" using primary key columns */
  C_FormDeckMaterial_by_pk?: Maybe<C_FormDeckMaterial>;
  /** fetch data from the table: "C_GroundRoughnessRating" */
  C_GroundRoughnessRating: Array<C_GroundRoughnessRating>;
  /** fetch aggregated fields from the table: "C_GroundRoughnessRating" */
  C_GroundRoughnessRating_aggregate: C_GroundRoughnessRating_Aggregate;
  /** fetch data from the table: "C_GroundRoughnessRating" using primary key columns */
  C_GroundRoughnessRating_by_pk?: Maybe<C_GroundRoughnessRating>;
  /** fetch data from the table: "C_GutterMaterial" */
  C_GutterMaterial: Array<C_GutterMaterial>;
  /** fetch aggregated fields from the table: "C_GutterMaterial" */
  C_GutterMaterial_aggregate: C_GutterMaterial_Aggregate;
  /** fetch data from the table: "C_GutterMaterial" using primary key columns */
  C_GutterMaterial_by_pk?: Maybe<C_GutterMaterial>;
  /** fetch data from the table: "C_HailRating" */
  C_HailRating: Array<C_HailRating>;
  /** fetch aggregated fields from the table: "C_HailRating" */
  C_HailRating_aggregate: C_HailRating_Aggregate;
  /** fetch data from the table: "C_HailRating" using primary key columns */
  C_HailRating_by_pk?: Maybe<C_HailRating>;
  /** fetch data from the table: "C_IndustryType" */
  C_IndustryType: Array<C_IndustryType>;
  /** fetch aggregated fields from the table: "C_IndustryType" */
  C_IndustryType_aggregate: C_IndustryType_Aggregate;
  /** fetch data from the table: "C_IndustryType" using primary key columns */
  C_IndustryType_by_pk?: Maybe<C_IndustryType>;
  /** fetch data from the table: "C_InsulationCore" */
  C_InsulationCore: Array<C_InsulationCore>;
  /** fetch aggregated fields from the table: "C_InsulationCore" */
  C_InsulationCore_aggregate: C_InsulationCore_Aggregate;
  /** fetch data from the table: "C_InsulationCore" using primary key columns */
  C_InsulationCore_by_pk?: Maybe<C_InsulationCore>;
  /** fetch data from the table: "C_InsulationFoamType" */
  C_InsulationFoamType: Array<C_InsulationFoamType>;
  /** fetch aggregated fields from the table: "C_InsulationFoamType" */
  C_InsulationFoamType_aggregate: C_InsulationFoamType_Aggregate;
  /** fetch data from the table: "C_InsulationFoamType" using primary key columns */
  C_InsulationFoamType_by_pk?: Maybe<C_InsulationFoamType>;
  /** fetch data from the table: "C_IntFireRating" */
  C_IntFireRating: Array<C_IntFireRating>;
  /** fetch aggregated fields from the table: "C_IntFireRating" */
  C_IntFireRating_aggregate: C_IntFireRating_Aggregate;
  /** fetch data from the table: "C_IntFireRating" using primary key columns */
  C_IntFireRating_by_pk?: Maybe<C_IntFireRating>;
  /** fetch data from the table: "C_LapSealantType" */
  C_LapSealantType: Array<C_LapSealantType>;
  /** fetch aggregated fields from the table: "C_LapSealantType" */
  C_LapSealantType_aggregate: C_LapSealantType_Aggregate;
  /** fetch data from the table: "C_LapSealantType" using primary key columns */
  C_LapSealantType_by_pk?: Maybe<C_LapSealantType>;
  /** fetch data from the table: "C_LayerType" */
  C_LayerType: Array<C_LayerType>;
  /** fetch aggregated fields from the table: "C_LayerType" */
  C_LayerType_aggregate: C_LayerType_Aggregate;
  /** fetch data from the table: "C_LayerType" using primary key columns */
  C_LayerType_by_pk?: Maybe<C_LayerType>;
  /** fetch data from the table: "C_LinerPanelMaterial" */
  C_LinerPanelMaterial: Array<C_LinerPanelMaterial>;
  /** fetch aggregated fields from the table: "C_LinerPanelMaterial" */
  C_LinerPanelMaterial_aggregate: C_LinerPanelMaterial_Aggregate;
  /** fetch data from the table: "C_LinerPanelMaterial" using primary key columns */
  C_LinerPanelMaterial_by_pk?: Maybe<C_LinerPanelMaterial>;
  /** fetch data from the table: "C_LiquidApplication" */
  C_LiquidApplication: Array<C_LiquidApplication>;
  /** fetch aggregated fields from the table: "C_LiquidApplication" */
  C_LiquidApplication_aggregate: C_LiquidApplication_Aggregate;
  /** fetch data from the table: "C_LiquidApplication" using primary key columns */
  C_LiquidApplication_by_pk?: Maybe<C_LiquidApplication>;
  /** fetch data from the table: "C_LiquidAppliedMaterial" */
  C_LiquidAppliedMaterial: Array<C_LiquidAppliedMaterial>;
  /** fetch aggregated fields from the table: "C_LiquidAppliedMaterial" */
  C_LiquidAppliedMaterial_aggregate: C_LiquidAppliedMaterial_Aggregate;
  /** fetch data from the table: "C_LiquidAppliedMaterial" using primary key columns */
  C_LiquidAppliedMaterial_by_pk?: Maybe<C_LiquidAppliedMaterial>;
  /** fetch data from the table: "C_LockStatus" */
  C_LockStatus: Array<C_LockStatus>;
  /** fetch aggregated fields from the table: "C_LockStatus" */
  C_LockStatus_aggregate: C_LockStatus_Aggregate;
  /** fetch data from the table: "C_LockStatus" using primary key columns */
  C_LockStatus_by_pk?: Maybe<C_LockStatus>;
  /** fetch data from the table: "C_MDAttributeType" */
  C_MDAttributeType: Array<C_MdAttributeType>;
  /** fetch aggregated fields from the table: "C_MDAttributeType" */
  C_MDAttributeType_aggregate: C_MdAttributeType_Aggregate;
  /** fetch data from the table: "C_MDAttributeType" using primary key columns */
  C_MDAttributeType_by_pk?: Maybe<C_MdAttributeType>;
  /** fetch data from the table: "C_MaintenanceCoatingMaterial" */
  C_MaintenanceCoatingMaterial: Array<C_MaintenanceCoatingMaterial>;
  /** fetch aggregated fields from the table: "C_MaintenanceCoatingMaterial" */
  C_MaintenanceCoatingMaterial_aggregate: C_MaintenanceCoatingMaterial_Aggregate;
  /** fetch data from the table: "C_MaintenanceCoatingMaterial" using primary key columns */
  C_MaintenanceCoatingMaterial_by_pk?: Maybe<C_MaintenanceCoatingMaterial>;
  /** fetch data from the table: "C_MaintenanceCoatingSubstrate" */
  C_MaintenanceCoatingSubstrate: Array<C_MaintenanceCoatingSubstrate>;
  /** fetch aggregated fields from the table: "C_MaintenanceCoatingSubstrate" */
  C_MaintenanceCoatingSubstrate_aggregate: C_MaintenanceCoatingSubstrate_Aggregate;
  /** fetch data from the table: "C_MaintenanceCoatingSubstrate" using primary key columns */
  C_MaintenanceCoatingSubstrate_by_pk?: Maybe<C_MaintenanceCoatingSubstrate>;
  /** fetch data from the table: "C_MembraneTermination" */
  C_MembraneTermination: Array<C_MembraneTermination>;
  /** fetch aggregated fields from the table: "C_MembraneTermination" */
  C_MembraneTermination_aggregate: C_MembraneTermination_Aggregate;
  /** fetch data from the table: "C_MembraneTermination" using primary key columns */
  C_MembraneTermination_by_pk?: Maybe<C_MembraneTermination>;
  /** fetch data from the table: "C_MultiplyMaterial" */
  C_MultiplyMaterial: Array<C_MultiplyMaterial>;
  /** fetch aggregated fields from the table: "C_MultiplyMaterial" */
  C_MultiplyMaterial_aggregate: C_MultiplyMaterial_Aggregate;
  /** fetch data from the table: "C_MultiplyMaterial" using primary key columns */
  C_MultiplyMaterial_by_pk?: Maybe<C_MultiplyMaterial>;
  /** fetch data from the table: "C_PVApplicationMethod" */
  C_PVApplicationMethod: Array<C_PvApplicationMethod>;
  /** fetch aggregated fields from the table: "C_PVApplicationMethod" */
  C_PVApplicationMethod_aggregate: C_PvApplicationMethod_Aggregate;
  /** fetch data from the table: "C_PVApplicationMethod" using primary key columns */
  C_PVApplicationMethod_by_pk?: Maybe<C_PvApplicationMethod>;
  /** fetch data from the table: "C_PVHailRating" */
  C_PVHailRating: Array<C_PvHailRating>;
  /** fetch aggregated fields from the table: "C_PVHailRating" */
  C_PVHailRating_aggregate: C_PvHailRating_Aggregate;
  /** fetch data from the table: "C_PVHailRating" using primary key columns */
  C_PVHailRating_by_pk?: Maybe<C_PvHailRating>;
  /** fetch data from the table: "C_PerimeterFlashingType" */
  C_PerimeterFlashingType: Array<C_PerimeterFlashingType>;
  /** fetch aggregated fields from the table: "C_PerimeterFlashingType" */
  C_PerimeterFlashingType_aggregate: C_PerimeterFlashingType_Aggregate;
  /** fetch data from the table: "C_PerimeterFlashingType" using primary key columns */
  C_PerimeterFlashingType_by_pk?: Maybe<C_PerimeterFlashingType>;
  /** fetch data from the table: "C_PressureZone" */
  C_PressureZone: Array<C_PressureZone>;
  /** fetch aggregated fields from the table: "C_PressureZone" */
  C_PressureZone_aggregate: C_PressureZone_Aggregate;
  /** fetch data from the table: "C_PressureZone" using primary key columns */
  C_PressureZone_by_pk?: Maybe<C_PressureZone>;
  /** fetch data from the table: "C_ProductApprovedUse" */
  C_ProductApprovedUse: Array<C_ProductApprovedUse>;
  /** fetch aggregated fields from the table: "C_ProductApprovedUse" */
  C_ProductApprovedUse_aggregate: C_ProductApprovedUse_Aggregate;
  /** fetch data from the table: "C_ProjectRole" */
  C_ProjectRole: Array<C_ProjectRole>;
  /** fetch aggregated fields from the table: "C_ProjectRole" */
  C_ProjectRole_aggregate: C_ProjectRole_Aggregate;
  /** fetch data from the table: "C_ProjectRole" using primary key columns */
  C_ProjectRole_by_pk?: Maybe<C_ProjectRole>;
  /** fetch data from the table: "C_Reinforcement" */
  C_Reinforcement: Array<C_Reinforcement>;
  /** fetch aggregated fields from the table: "C_Reinforcement" */
  C_Reinforcement_aggregate: C_Reinforcement_Aggregate;
  /** fetch data from the table: "C_Reinforcement" using primary key columns */
  C_Reinforcement_by_pk?: Maybe<C_Reinforcement>;
  /** fetch data from the table: "C_RetarderMaterial" */
  C_RetarderMaterial: Array<C_RetarderMaterial>;
  /** fetch aggregated fields from the table: "C_RetarderMaterial" */
  C_RetarderMaterial_aggregate: C_RetarderMaterial_Aggregate;
  /** fetch data from the table: "C_RetarderMaterial" using primary key columns */
  C_RetarderMaterial_by_pk?: Maybe<C_RetarderMaterial>;
  /** fetch data from the table: "C_ScenarioStatus" */
  C_ScenarioStatus: Array<C_ScenarioStatus>;
  /** fetch aggregated fields from the table: "C_ScenarioStatus" */
  C_ScenarioStatus_aggregate: C_ScenarioStatus_Aggregate;
  /** fetch data from the table: "C_ScenarioStatus" using primary key columns */
  C_ScenarioStatus_by_pk?: Maybe<C_ScenarioStatus>;
  /** fetch data from the table: "C_ScenarioType" */
  C_ScenarioType: Array<C_ScenarioType>;
  /** fetch aggregated fields from the table: "C_ScenarioType" */
  C_ScenarioType_aggregate: C_ScenarioType_Aggregate;
  /** fetch data from the table: "C_ScenarioType" using primary key columns */
  C_ScenarioType_by_pk?: Maybe<C_ScenarioType>;
  /** fetch data from the table: "C_SeamPrimerType" */
  C_SeamPrimerType: Array<C_SeamPrimerType>;
  /** fetch aggregated fields from the table: "C_SeamPrimerType" */
  C_SeamPrimerType_aggregate: C_SeamPrimerType_Aggregate;
  /** fetch data from the table: "C_SeamPrimerType" using primary key columns */
  C_SeamPrimerType_by_pk?: Maybe<C_SeamPrimerType>;
  /** fetch data from the table: "C_SeamWashType" */
  C_SeamWashType: Array<C_SeamWashType>;
  /** fetch aggregated fields from the table: "C_SeamWashType" */
  C_SeamWashType_aggregate: C_SeamWashType_Aggregate;
  /** fetch data from the table: "C_SeamWashType" using primary key columns */
  C_SeamWashType_by_pk?: Maybe<C_SeamWashType>;
  /** fetch data from the table: "C_SecurementMaterial" */
  C_SecurementMaterial: Array<C_SecurementMaterial>;
  /** fetch aggregated fields from the table: "C_SecurementMaterial" */
  C_SecurementMaterial_aggregate: C_SecurementMaterial_Aggregate;
  /** fetch data from the table: "C_SecurementMaterial" using primary key columns */
  C_SecurementMaterial_by_pk?: Maybe<C_SecurementMaterial>;
  /** fetch data from the table: "C_ShinglesHail" */
  C_ShinglesHail: Array<C_ShinglesHail>;
  /** fetch aggregated fields from the table: "C_ShinglesHail" */
  C_ShinglesHail_aggregate: C_ShinglesHail_Aggregate;
  /** fetch data from the table: "C_ShinglesHail" using primary key columns */
  C_ShinglesHail_by_pk?: Maybe<C_ShinglesHail>;
  /** fetch data from the table: "C_ShinglesMaterial" */
  C_ShinglesMaterial: Array<C_ShinglesMaterial>;
  /** fetch aggregated fields from the table: "C_ShinglesMaterial" */
  C_ShinglesMaterial_aggregate: C_ShinglesMaterial_Aggregate;
  /** fetch data from the table: "C_ShinglesMaterial" using primary key columns */
  C_ShinglesMaterial_by_pk?: Maybe<C_ShinglesMaterial>;
  /** fetch data from the table: "C_ShinglesReinforcement" */
  C_ShinglesReinforcement: Array<C_ShinglesReinforcement>;
  /** fetch aggregated fields from the table: "C_ShinglesReinforcement" */
  C_ShinglesReinforcement_aggregate: C_ShinglesReinforcement_Aggregate;
  /** fetch data from the table: "C_ShinglesReinforcement" using primary key columns */
  C_ShinglesReinforcement_by_pk?: Maybe<C_ShinglesReinforcement>;
  /** fetch data from the table: "C_ShinglesSurfacing" */
  C_ShinglesSurfacing: Array<C_ShinglesSurfacing>;
  /** fetch aggregated fields from the table: "C_ShinglesSurfacing" */
  C_ShinglesSurfacing_aggregate: C_ShinglesSurfacing_Aggregate;
  /** fetch data from the table: "C_ShinglesSurfacing" using primary key columns */
  C_ShinglesSurfacing_by_pk?: Maybe<C_ShinglesSurfacing>;
  /** fetch data from the table: "C_ShinglesWindSpeed" */
  C_ShinglesWindSpeed: Array<C_ShinglesWindSpeed>;
  /** fetch aggregated fields from the table: "C_ShinglesWindSpeed" */
  C_ShinglesWindSpeed_aggregate: C_ShinglesWindSpeed_Aggregate;
  /** fetch data from the table: "C_ShinglesWindSpeed" using primary key columns */
  C_ShinglesWindSpeed_by_pk?: Maybe<C_ShinglesWindSpeed>;
  /** fetch data from the table: "C_SinglePlyMaterial" */
  C_SinglePlyMaterial: Array<C_SinglePlyMaterial>;
  /** fetch aggregated fields from the table: "C_SinglePlyMaterial" */
  C_SinglePlyMaterial_aggregate: C_SinglePlyMaterial_Aggregate;
  /** fetch data from the table: "C_SinglePlyMaterial" using primary key columns */
  C_SinglePlyMaterial_by_pk?: Maybe<C_SinglePlyMaterial>;
  /** fetch data from the table: "C_StandingLapSeamMaterial" */
  C_StandingLapSeamMaterial: Array<C_StandingLapSeamMaterial>;
  /** fetch aggregated fields from the table: "C_StandingLapSeamMaterial" */
  C_StandingLapSeamMaterial_aggregate: C_StandingLapSeamMaterial_Aggregate;
  /** fetch data from the table: "C_StandingLapSeamMaterial" using primary key columns */
  C_StandingLapSeamMaterial_by_pk?: Maybe<C_StandingLapSeamMaterial>;
  /** fetch data from the table: "C_StateProvince" */
  C_StateProvince: Array<C_StateProvince>;
  /** fetch aggregated fields from the table: "C_StateProvince" */
  C_StateProvince_aggregate: C_StateProvince_Aggregate;
  /** fetch data from the table: "C_StateProvince" using primary key columns */
  C_StateProvince_by_pk?: Maybe<C_StateProvince>;
  /** fetch data from the table: "C_Status" */
  C_Status: Array<C_Status>;
  /** fetch aggregated fields from the table: "C_Status" */
  C_Status_aggregate: C_Status_Aggregate;
  /** fetch data from the table: "C_Status" using primary key columns */
  C_Status_by_pk?: Maybe<C_Status>;
  /** fetch data from the table: "C_SteelDeckRibType" */
  C_SteelDeckRibType: Array<C_SteelDeckRibType>;
  /** fetch aggregated fields from the table: "C_SteelDeckRibType" */
  C_SteelDeckRibType_aggregate: C_SteelDeckRibType_Aggregate;
  /** fetch data from the table: "C_SteelDeckRibType" using primary key columns */
  C_SteelDeckRibType_by_pk?: Maybe<C_SteelDeckRibType>;
  /** fetch data from the table: "C_StressDistributorType" */
  C_StressDistributorType: Array<C_StressDistributorType>;
  /** fetch aggregated fields from the table: "C_StressDistributorType" */
  C_StressDistributorType_aggregate: C_StressDistributorType_Aggregate;
  /** fetch data from the table: "C_StressDistributorType" using primary key columns */
  C_StressDistributorType_by_pk?: Maybe<C_StressDistributorType>;
  /** fetch data from the table: "C_StressPlateShape" */
  C_StressPlateShape: Array<C_StressPlateShape>;
  /** fetch aggregated fields from the table: "C_StressPlateShape" */
  C_StressPlateShape_aggregate: C_StressPlateShape_Aggregate;
  /** fetch data from the table: "C_StressPlateShape" using primary key columns */
  C_StressPlateShape_by_pk?: Maybe<C_StressPlateShape>;
  /** fetch data from the table: "C_StructureType" */
  C_StructureType: Array<C_StructureType>;
  /** fetch aggregated fields from the table: "C_StructureType" */
  C_StructureType_aggregate: C_StructureType_Aggregate;
  /** fetch data from the table: "C_StructureType" using primary key columns */
  C_StructureType_by_pk?: Maybe<C_StructureType>;
  /** fetch data from the table: "C_SubassemblyType" */
  C_SubassemblyType: Array<C_SubassemblyType>;
  /** fetch aggregated fields from the table: "C_SubassemblyType" */
  C_SubassemblyType_aggregate: C_SubassemblyType_Aggregate;
  /** fetch data from the table: "C_SubassemblyType" using primary key columns */
  C_SubassemblyType_by_pk?: Maybe<C_SubassemblyType>;
  /** fetch data from the table: "C_Substrate" */
  C_Substrate: Array<C_Substrate>;
  /** fetch data from the table: "C_SubstratePrimerMaterial" */
  C_SubstratePrimerMaterial: Array<C_SubstratePrimerMaterial>;
  /** fetch aggregated fields from the table: "C_SubstratePrimerMaterial" */
  C_SubstratePrimerMaterial_aggregate: C_SubstratePrimerMaterial_Aggregate;
  /** fetch data from the table: "C_SubstratePrimerMaterial" using primary key columns */
  C_SubstratePrimerMaterial_by_pk?: Maybe<C_SubstratePrimerMaterial>;
  /** fetch aggregated fields from the table: "C_Substrate" */
  C_Substrate_aggregate: C_Substrate_Aggregate;
  /** fetch data from the table: "C_Substrate" using primary key columns */
  C_Substrate_by_pk?: Maybe<C_Substrate>;
  /** fetch data from the table: "C_SurfaceType" */
  C_SurfaceType: Array<C_SurfaceType>;
  /** fetch aggregated fields from the table: "C_SurfaceType" */
  C_SurfaceType_aggregate: C_SurfaceType_Aggregate;
  /** fetch data from the table: "C_SurfaceType" using primary key columns */
  C_SurfaceType_by_pk?: Maybe<C_SurfaceType>;
  /** fetch data from the table: "C_SurfacingMaterial" */
  C_SurfacingMaterial: Array<C_SurfacingMaterial>;
  /** fetch aggregated fields from the table: "C_SurfacingMaterial" */
  C_SurfacingMaterial_aggregate: C_SurfacingMaterial_Aggregate;
  /** fetch data from the table: "C_SurfacingMaterial" using primary key columns */
  C_SurfacingMaterial_by_pk?: Maybe<C_SurfacingMaterial>;
  /** fetch data from the table: "C_SystemApprovedUse" */
  C_SystemApprovedUse: Array<C_SystemApprovedUse>;
  /** fetch aggregated fields from the table: "C_SystemApprovedUse" */
  C_SystemApprovedUse_aggregate: C_SystemApprovedUse_Aggregate;
  /** fetch data from the table: "C_ThermalBarrierMaterial" */
  C_ThermalBarrierMaterial: Array<C_ThermalBarrierMaterial>;
  /** fetch aggregated fields from the table: "C_ThermalBarrierMaterial" */
  C_ThermalBarrierMaterial_aggregate: C_ThermalBarrierMaterial_Aggregate;
  /** fetch data from the table: "C_ThermalBarrierMaterial" using primary key columns */
  C_ThermalBarrierMaterial_by_pk?: Maybe<C_ThermalBarrierMaterial>;
  /** fetch data from the table: "C_WIPDelistReason" */
  C_WIPDelistReason: Array<C_WipDelistReason>;
  /** fetch aggregated fields from the table: "C_WIPDelistReason" */
  C_WIPDelistReason_aggregate: C_WipDelistReason_Aggregate;
  /** fetch data from the table: "C_WIPDelistReason" using primary key columns */
  C_WIPDelistReason_by_pk?: Maybe<C_WipDelistReason>;
  /** fetch data from the table: "C_WIPOnHoldReason" */
  C_WIPOnHoldReason: Array<C_WipOnHoldReason>;
  /** fetch aggregated fields from the table: "C_WIPOnHoldReason" */
  C_WIPOnHoldReason_aggregate: C_WipOnHoldReason_Aggregate;
  /** fetch data from the table: "C_WIPOnHoldReason" using primary key columns */
  C_WIPOnHoldReason_by_pk?: Maybe<C_WipOnHoldReason>;
  /** fetch data from the table: "C_WIPStatus" */
  C_WIPStatus: Array<C_WipStatus>;
  /** fetch aggregated fields from the table: "C_WIPStatus" */
  C_WIPStatus_aggregate: C_WipStatus_Aggregate;
  /** fetch data from the table: "C_WIPStatus" using primary key columns */
  C_WIPStatus_by_pk?: Maybe<C_WipStatus>;
  /** fetch data from the table: "C_WeldApplication" */
  C_WeldApplication: Array<C_WeldApplication>;
  /** fetch aggregated fields from the table: "C_WeldApplication" */
  C_WeldApplication_aggregate: C_WeldApplication_Aggregate;
  /** fetch data from the table: "C_WeldApplication" using primary key columns */
  C_WeldApplication_by_pk?: Maybe<C_WeldApplication>;
  /** fetch data from the table: "C_WeldConfiguration" */
  C_WeldConfiguration: Array<C_WeldConfiguration>;
  /** fetch aggregated fields from the table: "C_WeldConfiguration" */
  C_WeldConfiguration_aggregate: C_WeldConfiguration_Aggregate;
  /** fetch data from the table: "C_WeldConfiguration" using primary key columns */
  C_WeldConfiguration_by_pk?: Maybe<C_WeldConfiguration>;
  /** fetch data from the table: "C_WindBorneDebris" */
  C_WindBorneDebris: Array<C_WindBorneDebris>;
  /** fetch aggregated fields from the table: "C_WindBorneDebris" */
  C_WindBorneDebris_aggregate: C_WindBorneDebris_Aggregate;
  /** fetch data from the table: "C_WindBorneDebris" using primary key columns */
  C_WindBorneDebris_by_pk?: Maybe<C_WindBorneDebris>;
  /** fetch data from the table: "C_WoodSpecies" */
  C_WoodSpecies: Array<C_WoodSpecies>;
  /** fetch aggregated fields from the table: "C_WoodSpecies" */
  C_WoodSpecies_aggregate: C_WoodSpecies_Aggregate;
  /** fetch data from the table: "C_WoodSpecies" using primary key columns */
  C_WoodSpecies_by_pk?: Maybe<C_WoodSpecies>;
  /** fetch data from the table: "C_WoodType" */
  C_WoodType: Array<C_WoodType>;
  /** fetch aggregated fields from the table: "C_WoodType" */
  C_WoodType_aggregate: C_WoodType_Aggregate;
  /** fetch data from the table: "C_WoodType" using primary key columns */
  C_WoodType_by_pk?: Maybe<C_WoodType>;
  /** fetch data from the table: "C_WorkflowStatus" */
  C_WorkflowStatus: Array<C_WorkflowStatus>;
  /** fetch aggregated fields from the table: "C_WorkflowStatus" */
  C_WorkflowStatus_aggregate: C_WorkflowStatus_Aggregate;
  /** fetch data from the table: "C_WorkflowStatus" using primary key columns */
  C_WorkflowStatus_by_pk?: Maybe<C_WorkflowStatus>;
  /** fetch data from the table: "CapPly" */
  CapPly: Array<CapPly>;
  /** fetch data from the table: "CapPlyUsage" */
  CapPlyUsage: Array<CapPlyUsage>;
  /** fetch aggregated fields from the table: "CapPlyUsage" */
  CapPlyUsage_aggregate: CapPlyUsage_Aggregate;
  /** fetch data from the table: "CapPlyUsage" using primary key columns */
  CapPlyUsage_by_pk?: Maybe<CapPlyUsage>;
  /** fetch aggregated fields from the table: "CapPly" */
  CapPly_aggregate: CapPly_Aggregate;
  /** fetch data from the table: "CapPly" using primary key columns */
  CapPly_by_pk?: Maybe<CapPly>;
  /** fetch data from the table: "Category" */
  Category: Array<Category>;
  /** fetch data from the table: "CategoryLog" */
  CategoryLog: Array<CategoryLog>;
  /** fetch aggregated fields from the table: "CategoryLog" */
  CategoryLog_aggregate: CategoryLog_Aggregate;
  /** fetch data from the table: "CategoryLog" using primary key columns */
  CategoryLog_by_pk?: Maybe<CategoryLog>;
  /** fetch data from the table: "Category_Coating" */
  Category_Coating: Array<Category_Coating>;
  /** fetch aggregated fields from the table: "Category_Coating" */
  Category_Coating_aggregate: Category_Coating_Aggregate;
  /** fetch data from the table: "Category_Cover" */
  Category_Cover: Array<Category_Cover>;
  /** fetch aggregated fields from the table: "Category_Cover" */
  Category_Cover_aggregate: Category_Cover_Aggregate;
  /** fetch data from the table: "Category_Deck" */
  Category_Deck: Array<Category_Deck>;
  /** fetch aggregated fields from the table: "Category_Deck" */
  Category_Deck_aggregate: Category_Deck_Aggregate;
  /** fetch data from the table: "Category_Flashing" */
  Category_Flashing: Array<Category_Flashing>;
  /** fetch aggregated fields from the table: "Category_Flashing" */
  Category_Flashing_aggregate: Category_Flashing_Aggregate;
  /** fetch data from the table: "Category_Insulation" */
  Category_Insulation: Array<Category_Insulation>;
  /** fetch aggregated fields from the table: "Category_Insulation" */
  Category_Insulation_aggregate: Category_Insulation_Aggregate;
  /** fetch data from the table: "Category_Other" */
  Category_Other: Array<Category_Other>;
  /** fetch aggregated fields from the table: "Category_Other" */
  Category_Other_aggregate: Category_Other_Aggregate;
  /** fetch data from the table: "Category_Seaming" */
  Category_Seaming: Array<Category_Seaming>;
  /** fetch aggregated fields from the table: "Category_Seaming" */
  Category_Seaming_aggregate: Category_Seaming_Aggregate;
  /** fetch data from the table: "Category_Securement" */
  Category_Securement: Array<Category_Securement>;
  /** fetch aggregated fields from the table: "Category_Securement" */
  Category_Securement_aggregate: Category_Securement_Aggregate;
  /** fetch data from the table: "Category_Sheet" */
  Category_Sheet: Array<Category_Sheet>;
  /** fetch aggregated fields from the table: "Category_Sheet" */
  Category_Sheet_aggregate: Category_Sheet_Aggregate;
  /** fetch aggregated fields from the table: "Category" */
  Category_aggregate: Category_Aggregate;
  /** fetch data from the table: "Category" using primary key columns */
  Category_by_pk?: Maybe<Category>;
  /** fetch data from the table: "CementitiousWoodFiberDeck" */
  CementitiousWoodFiberDeck: Array<CementitiousWoodFiberDeck>;
  /** fetch data from the table: "CementitiousWoodFiberDeckUsage" */
  CementitiousWoodFiberDeckUsage: Array<CementitiousWoodFiberDeckUsage>;
  /** fetch aggregated fields from the table: "CementitiousWoodFiberDeckUsage" */
  CementitiousWoodFiberDeckUsage_aggregate: CementitiousWoodFiberDeckUsage_Aggregate;
  /** fetch data from the table: "CementitiousWoodFiberDeckUsage" using primary key columns */
  CementitiousWoodFiberDeckUsage_by_pk?: Maybe<CementitiousWoodFiberDeckUsage>;
  /** fetch aggregated fields from the table: "CementitiousWoodFiberDeck" */
  CementitiousWoodFiberDeck_aggregate: CementitiousWoodFiberDeck_Aggregate;
  /** fetch data from the table: "CementitiousWoodFiberDeck" using primary key columns */
  CementitiousWoodFiberDeck_by_pk?: Maybe<CementitiousWoodFiberDeck>;
  /** fetch data from the table: "Clip" */
  Clip: Array<Clip>;
  /** fetch data from the table: "ClipUsage" */
  ClipUsage: Array<ClipUsage>;
  /** fetch aggregated fields from the table: "ClipUsage" */
  ClipUsage_aggregate: ClipUsage_Aggregate;
  /** fetch data from the table: "ClipUsage" using primary key columns */
  ClipUsage_by_pk?: Maybe<ClipUsage>;
  /** fetch aggregated fields from the table: "Clip" */
  Clip_aggregate: Clip_Aggregate;
  /** fetch data from the table: "Clip" using primary key columns */
  Clip_by_pk?: Maybe<Clip>;
  /** fetch data from the table: "Coat" */
  Coat: Array<Coat>;
  /** fetch data from the table: "CoatUsage" */
  CoatUsage: Array<CoatUsage>;
  /** fetch aggregated fields from the table: "CoatUsage" */
  CoatUsage_aggregate: CoatUsage_Aggregate;
  /** fetch data from the table: "CoatUsage" using primary key columns */
  CoatUsage_by_pk?: Maybe<CoatUsage>;
  /** fetch aggregated fields from the table: "Coat" */
  Coat_aggregate: Coat_Aggregate;
  /** fetch data from the table: "Coat" using primary key columns */
  Coat_by_pk?: Maybe<Coat>;
  /** fetch data from the table: "Coating" */
  Coating: Array<Coating>;
  /** fetch data from the table: "CoatingSystemUsage" */
  CoatingSystemUsage: Array<CoatingSystemUsage>;
  /** fetch aggregated fields from the table: "CoatingSystemUsage" */
  CoatingSystemUsage_aggregate: CoatingSystemUsage_Aggregate;
  /** fetch data from the table: "CoatingSystemUsage" using primary key columns */
  CoatingSystemUsage_by_pk?: Maybe<CoatingSystemUsage>;
  /** fetch data from the table: "CoatingUsage" */
  CoatingUsage: Array<CoatingUsage>;
  /** fetch aggregated fields from the table: "CoatingUsage" */
  CoatingUsage_aggregate: CoatingUsage_Aggregate;
  /** fetch data from the table: "CoatingUsage" using primary key columns */
  CoatingUsage_by_pk?: Maybe<CoatingUsage>;
  /** fetch aggregated fields from the table: "Coating" */
  Coating_aggregate: Coating_Aggregate;
  /** fetch data from the table: "Coating" using primary key columns */
  Coating_by_pk?: Maybe<Coating>;
  /** fetch data from the table: "Component" */
  Component: Array<Component>;
  /** fetch data from the table: "ComponentApprovedUse" */
  ComponentApprovedUse: Array<ComponentApprovedUse>;
  /** fetch aggregated fields from the table: "ComponentApprovedUse" */
  ComponentApprovedUse_aggregate: ComponentApprovedUse_Aggregate;
  /** fetch data from the table: "ComponentApprovedUse" using primary key columns */
  ComponentApprovedUse_by_pk?: Maybe<ComponentApprovedUse>;
  /** fetch data from the table: "ComponentDoc" */
  ComponentDoc: Array<ComponentDoc>;
  /** fetch aggregated fields from the table: "ComponentDoc" */
  ComponentDoc_aggregate: ComponentDoc_Aggregate;
  /** fetch data from the table: "ComponentDoc" using primary key columns */
  ComponentDoc_by_pk?: Maybe<ComponentDoc>;
  /** fetch data from the table: "ComponentLayer" */
  ComponentLayer: Array<ComponentLayer>;
  /** fetch data from the table: "ComponentLayerUsage" */
  ComponentLayerUsage: Array<ComponentLayerUsage>;
  /** fetch aggregated fields from the table: "ComponentLayerUsage" */
  ComponentLayerUsage_aggregate: ComponentLayerUsage_Aggregate;
  /** fetch data from the table: "ComponentLayerUsage" using primary key columns */
  ComponentLayerUsage_by_pk?: Maybe<ComponentLayerUsage>;
  /** fetch aggregated fields from the table: "ComponentLayer" */
  ComponentLayer_aggregate: ComponentLayer_Aggregate;
  /** fetch data from the table: "ComponentLayer" using primary key columns */
  ComponentLayer_by_pk?: Maybe<ComponentLayer>;
  /** fetch aggregated fields from the table: "Component" */
  Component_aggregate: Component_Aggregate;
  /** fetch data from the table: "Component" using primary key columns */
  Component_by_pk?: Maybe<Component>;
  /** fetch data from the table: "CompositePanelCover" */
  CompositePanelCover: Array<CompositePanelCover>;
  /** fetch aggregated fields from the table: "CompositePanelCover" */
  CompositePanelCover_aggregate: CompositePanelCover_Aggregate;
  /** fetch data from the table: "CompositePanelCover" using primary key columns */
  CompositePanelCover_by_pk?: Maybe<CompositePanelCover>;
  /** fetch data from the table: "CompositePanelUsage" */
  CompositePanelUsage: Array<CompositePanelUsage>;
  /** fetch aggregated fields from the table: "CompositePanelUsage" */
  CompositePanelUsage_aggregate: CompositePanelUsage_Aggregate;
  /** fetch data from the table: "CompositePanelUsage" using primary key columns */
  CompositePanelUsage_by_pk?: Maybe<CompositePanelUsage>;
  /** fetch data from the table: "ContactInfo" */
  ContactInfo: Array<ContactInfo>;
  /** fetch aggregated fields from the table: "ContactInfo" */
  ContactInfo_aggregate: ContactInfo_Aggregate;
  /** fetch data from the table: "ContactInfo" using primary key columns */
  ContactInfo_by_pk?: Maybe<ContactInfo>;
  /** fetch data from the table: "Coping" */
  Coping: Array<Coping>;
  /** fetch aggregated fields from the table: "Coping" */
  Coping_aggregate: Coping_Aggregate;
  /** fetch data from the table: "Coping" using primary key columns */
  Coping_by_pk?: Maybe<Coping>;
  /** fetch data from the table: "CoverBoard" */
  CoverBoard: Array<CoverBoard>;
  /** An array relationship */
  CoverBoardBoardSizes: Array<CoverBoardBoardSizes>;
  /** An aggregate relationship */
  CoverBoardBoardSizes_aggregate: CoverBoardBoardSizes_Aggregate;
  /** fetch data from the table: "CoverBoardBoardSizes" using primary key columns */
  CoverBoardBoardSizes_by_pk?: Maybe<CoverBoardBoardSizes>;
  /** fetch data from the table: "CoverBoardFacerBottom" */
  CoverBoardFacerBottom: Array<CoverBoardFacerBottom>;
  /** fetch aggregated fields from the table: "CoverBoardFacerBottom" */
  CoverBoardFacerBottom_aggregate: CoverBoardFacerBottom_Aggregate;
  /** fetch data from the table: "CoverBoardFacerBottom" using primary key columns */
  CoverBoardFacerBottom_by_pk?: Maybe<CoverBoardFacerBottom>;
  /** fetch data from the table: "CoverBoardFacerTop" */
  CoverBoardFacerTop: Array<CoverBoardFacerTop>;
  /** fetch aggregated fields from the table: "CoverBoardFacerTop" */
  CoverBoardFacerTop_aggregate: CoverBoardFacerTop_Aggregate;
  /** fetch data from the table: "CoverBoardFacerTop" using primary key columns */
  CoverBoardFacerTop_by_pk?: Maybe<CoverBoardFacerTop>;
  /** fetch data from the table: "CoverBoardUsage" */
  CoverBoardUsage: Array<CoverBoardUsage>;
  /** fetch aggregated fields from the table: "CoverBoardUsage" */
  CoverBoardUsage_aggregate: CoverBoardUsage_Aggregate;
  /** fetch data from the table: "CoverBoardUsage" using primary key columns */
  CoverBoardUsage_by_pk?: Maybe<CoverBoardUsage>;
  /** fetch aggregated fields from the table: "CoverBoard" */
  CoverBoard_aggregate: CoverBoard_Aggregate;
  /** fetch data from the table: "CoverBoard" using primary key columns */
  CoverBoard_by_pk?: Maybe<CoverBoard>;
  /** fetch data from the table: "CoverStripDisk" */
  CoverStripDisk: Array<CoverStripDisk>;
  /** fetch data from the table: "CoverStripDiskUsage" */
  CoverStripDiskUsage: Array<CoverStripDiskUsage>;
  /** fetch aggregated fields from the table: "CoverStripDiskUsage" */
  CoverStripDiskUsage_aggregate: CoverStripDiskUsage_Aggregate;
  /** fetch data from the table: "CoverStripDiskUsage" using primary key columns */
  CoverStripDiskUsage_by_pk?: Maybe<CoverStripDiskUsage>;
  /** fetch aggregated fields from the table: "CoverStripDisk" */
  CoverStripDisk_aggregate: CoverStripDisk_Aggregate;
  /** fetch data from the table: "CoverStripDisk" using primary key columns */
  CoverStripDisk_by_pk?: Maybe<CoverStripDisk>;
  /** fetch data from the table: "ExistingRoofLayer" */
  ExistingRoofLayer: Array<ExistingRoofLayer>;
  /** fetch aggregated fields from the table: "ExistingRoofLayer" */
  ExistingRoofLayer_aggregate: ExistingRoofLayer_Aggregate;
  /** fetch data from the table: "ExistingRoofLayer" using primary key columns */
  ExistingRoofLayer_by_pk?: Maybe<ExistingRoofLayer>;
  /** fetch data from the table: "ExpansionJoint" */
  ExpansionJoint: Array<ExpansionJoint>;
  /** fetch data from the table: "ExpansionJointUsage" */
  ExpansionJointUsage: Array<ExpansionJointUsage>;
  /** fetch aggregated fields from the table: "ExpansionJointUsage" */
  ExpansionJointUsage_aggregate: ExpansionJointUsage_Aggregate;
  /** fetch data from the table: "ExpansionJointUsage" using primary key columns */
  ExpansionJointUsage_by_pk?: Maybe<ExpansionJointUsage>;
  /** fetch aggregated fields from the table: "ExpansionJoint" */
  ExpansionJoint_aggregate: ExpansionJoint_Aggregate;
  /** fetch data from the table: "ExpansionJoint" using primary key columns */
  ExpansionJoint_by_pk?: Maybe<ExpansionJoint>;
  /** fetch data from the table: "ExtendedLayerConfigSplit" */
  ExtendedLayerConfigSplit: Array<ExtendedLayerConfigSplit>;
  /** fetch aggregated fields from the table: "ExtendedLayerConfigSplit" */
  ExtendedLayerConfigSplit_aggregate: ExtendedLayerConfigSplit_Aggregate;
  /** fetch data from the table: "ExtendedLayerConfigSplit" using primary key columns */
  ExtendedLayerConfigSplit_by_pk?: Maybe<ExtendedLayerConfigSplit>;
  /** fetch data from the table: "FMUserAccount" */
  FMUserAccount: Array<FmUserAccount>;
  /** fetch aggregated fields from the table: "FMUserAccount" */
  FMUserAccount_aggregate: FmUserAccount_Aggregate;
  /** fetch data from the table: "FMUserAccount" using primary key columns */
  FMUserAccount_by_pk?: Maybe<FmUserAccount>;
  /** An array relationship */
  Fascia: Array<Fascia>;
  /** An aggregate relationship */
  Fascia_aggregate: Fascia_Aggregate;
  /** fetch data from the table: "Fascia" using primary key columns */
  Fascia_by_pk?: Maybe<Fascia>;
  /** fetch data from the table: "Fastener" */
  Fastener: Array<Fastener>;
  /** fetch data from the table: "FastenerUsage" */
  FastenerUsage: Array<FastenerUsage>;
  /** fetch aggregated fields from the table: "FastenerUsage" */
  FastenerUsage_aggregate: FastenerUsage_Aggregate;
  /** fetch data from the table: "FastenerUsage" using primary key columns */
  FastenerUsage_by_pk?: Maybe<FastenerUsage>;
  /** fetch aggregated fields from the table: "Fastener" */
  Fastener_aggregate: Fastener_Aggregate;
  /** fetch data from the table: "Fastener" using primary key columns */
  Fastener_by_pk?: Maybe<Fastener>;
  /** fetch data from the table: "FasteningSystemBattenBarUsage" */
  FasteningSystemBattenBarUsage: Array<FasteningSystemBattenBarUsage>;
  /** fetch aggregated fields from the table: "FasteningSystemBattenBarUsage" */
  FasteningSystemBattenBarUsage_aggregate: FasteningSystemBattenBarUsage_Aggregate;
  /** fetch data from the table: "FasteningSystemBattenBarUsage" using primary key columns */
  FasteningSystemBattenBarUsage_by_pk?: Maybe<FasteningSystemBattenBarUsage>;
  /** fetch data from the table: "FasteningSystemClipUsage" */
  FasteningSystemClipUsage: Array<FasteningSystemClipUsage>;
  /** fetch aggregated fields from the table: "FasteningSystemClipUsage" */
  FasteningSystemClipUsage_aggregate: FasteningSystemClipUsage_Aggregate;
  /** fetch data from the table: "FasteningSystemClipUsage" using primary key columns */
  FasteningSystemClipUsage_by_pk?: Maybe<FasteningSystemClipUsage>;
  /** fetch data from the table: "FasteningSystemStressPlateUsage" */
  FasteningSystemStressPlateUsage: Array<FasteningSystemStressPlateUsage>;
  /** fetch aggregated fields from the table: "FasteningSystemStressPlateUsage" */
  FasteningSystemStressPlateUsage_aggregate: FasteningSystemStressPlateUsage_Aggregate;
  /** fetch data from the table: "FasteningSystemStressPlateUsage" using primary key columns */
  FasteningSystemStressPlateUsage_by_pk?: Maybe<FasteningSystemStressPlateUsage>;
  /** fetch data from the table: "FiberReinforcedPlasticDeck" */
  FiberReinforcedPlasticDeck: Array<FiberReinforcedPlasticDeck>;
  /** fetch data from the table: "FiberReinforcedPlasticDeckUsage" */
  FiberReinforcedPlasticDeckUsage: Array<FiberReinforcedPlasticDeckUsage>;
  /** fetch aggregated fields from the table: "FiberReinforcedPlasticDeckUsage" */
  FiberReinforcedPlasticDeckUsage_aggregate: FiberReinforcedPlasticDeckUsage_Aggregate;
  /** fetch data from the table: "FiberReinforcedPlasticDeckUsage" using primary key columns */
  FiberReinforcedPlasticDeckUsage_by_pk?: Maybe<FiberReinforcedPlasticDeckUsage>;
  /** fetch aggregated fields from the table: "FiberReinforcedPlasticDeck" */
  FiberReinforcedPlasticDeck_aggregate: FiberReinforcedPlasticDeck_Aggregate;
  /** fetch data from the table: "FiberReinforcedPlasticDeck" using primary key columns */
  FiberReinforcedPlasticDeck_by_pk?: Maybe<FiberReinforcedPlasticDeck>;
  /** fetch data from the table: "FormDeck" */
  FormDeck: Array<FormDeck>;
  /** fetch data from the table: "FormDeckUsage" */
  FormDeckUsage: Array<FormDeckUsage>;
  /** fetch aggregated fields from the table: "FormDeckUsage" */
  FormDeckUsage_aggregate: FormDeckUsage_Aggregate;
  /** fetch data from the table: "FormDeckUsage" using primary key columns */
  FormDeckUsage_by_pk?: Maybe<FormDeckUsage>;
  /** fetch aggregated fields from the table: "FormDeck" */
  FormDeck_aggregate: FormDeck_Aggregate;
  /** fetch data from the table: "FormDeck" using primary key columns */
  FormDeck_by_pk?: Maybe<FormDeck>;
  /** fetch data from the table: "Gutter" */
  Gutter: Array<Gutter>;
  /** fetch aggregated fields from the table: "Gutter" */
  Gutter_aggregate: Gutter_Aggregate;
  /** fetch data from the table: "Gutter" using primary key columns */
  Gutter_by_pk?: Maybe<Gutter>;
  /** fetch data from the table: "GypsumDeck" */
  GypsumDeck: Array<GypsumDeck>;
  /** fetch data from the table: "GypsumDeckUsage" */
  GypsumDeckUsage: Array<GypsumDeckUsage>;
  /** fetch aggregated fields from the table: "GypsumDeckUsage" */
  GypsumDeckUsage_aggregate: GypsumDeckUsage_Aggregate;
  /** fetch data from the table: "GypsumDeckUsage" using primary key columns */
  GypsumDeckUsage_by_pk?: Maybe<GypsumDeckUsage>;
  /** fetch aggregated fields from the table: "GypsumDeck" */
  GypsumDeck_aggregate: GypsumDeck_Aggregate;
  /** fetch data from the table: "GypsumDeck" using primary key columns */
  GypsumDeck_by_pk?: Maybe<GypsumDeck>;
  /** fetch data from the table: "InsBoardSize" */
  InsBoardSize: Array<InsBoardSize>;
  /** fetch aggregated fields from the table: "InsBoardSize" */
  InsBoardSize_aggregate: InsBoardSize_Aggregate;
  /** fetch data from the table: "InsBoardSize" using primary key columns */
  InsBoardSize_by_pk?: Maybe<InsBoardSize>;
  /** fetch data from the table: "ItemWorkspace" */
  ItemWorkspace: Array<ItemWorkspace>;
  /** An array relationship */
  ItemWorkspaceData: Array<ItemWorkspaceData>;
  /** An aggregate relationship */
  ItemWorkspaceData_aggregate: ItemWorkspaceData_Aggregate;
  /** fetch data from the table: "ItemWorkspaceData" using primary key columns */
  ItemWorkspaceData_by_pk?: Maybe<ItemWorkspaceData>;
  /** fetch aggregated fields from the table: "ItemWorkspace" */
  ItemWorkspace_aggregate: ItemWorkspace_Aggregate;
  /** fetch data from the table: "ItemWorkspace" using primary key columns */
  ItemWorkspace_by_pk?: Maybe<ItemWorkspace>;
  /** fetch data from the table: "LapSealant" */
  LapSealant: Array<LapSealant>;
  /** fetch data from the table: "LapSealantUsage" */
  LapSealantUsage: Array<LapSealantUsage>;
  /** fetch aggregated fields from the table: "LapSealantUsage" */
  LapSealantUsage_aggregate: LapSealantUsage_Aggregate;
  /** fetch data from the table: "LapSealantUsage" using primary key columns */
  LapSealantUsage_by_pk?: Maybe<LapSealantUsage>;
  /** fetch aggregated fields from the table: "LapSealant" */
  LapSealant_aggregate: LapSealant_Aggregate;
  /** fetch data from the table: "LapSealant" using primary key columns */
  LapSealant_by_pk?: Maybe<LapSealant>;
  /** fetch data from the table: "LapSecurementInfo" */
  LapSecurementInfo: Array<LapSecurementInfo>;
  /** fetch aggregated fields from the table: "LapSecurementInfo" */
  LapSecurementInfo_aggregate: LapSecurementInfo_Aggregate;
  /** fetch data from the table: "LapSecurementInfo" using primary key columns */
  LapSecurementInfo_by_pk?: Maybe<LapSecurementInfo>;
  /** fetch data from the table: "Layer" */
  Layer: Array<Layer>;
  /** fetch data from the table: "LayerConfig" */
  LayerConfig: Array<LayerConfig>;
  /** fetch aggregated fields from the table: "LayerConfig" */
  LayerConfig_aggregate: LayerConfig_Aggregate;
  /** fetch data from the table: "LayerConfig" using primary key columns */
  LayerConfig_by_pk?: Maybe<LayerConfig>;
  /** fetch aggregated fields from the table: "Layer" */
  Layer_aggregate: Layer_Aggregate;
  /** fetch data from the table: "Layer" using primary key columns */
  Layer_by_pk?: Maybe<Layer>;
  /** fetch data from the table: "LightweightInsulatingConcrete" */
  LightweightInsulatingConcrete: Array<LightweightInsulatingConcrete>;
  /** fetch data from the table: "LightweightInsulatingConcreteSubassemblyUsage" */
  LightweightInsulatingConcreteSubassemblyUsage: Array<LightweightInsulatingConcreteSubassemblyUsage>;
  /** fetch aggregated fields from the table: "LightweightInsulatingConcreteSubassemblyUsage" */
  LightweightInsulatingConcreteSubassemblyUsage_aggregate: LightweightInsulatingConcreteSubassemblyUsage_Aggregate;
  /** fetch data from the table: "LightweightInsulatingConcreteSubassemblyUsage" using primary key columns */
  LightweightInsulatingConcreteSubassemblyUsage_by_pk?: Maybe<LightweightInsulatingConcreteSubassemblyUsage>;
  /** fetch data from the table: "LightweightInsulatingConcreteUsage" */
  LightweightInsulatingConcreteUsage: Array<LightweightInsulatingConcreteUsage>;
  /** fetch aggregated fields from the table: "LightweightInsulatingConcreteUsage" */
  LightweightInsulatingConcreteUsage_aggregate: LightweightInsulatingConcreteUsage_Aggregate;
  /** fetch data from the table: "LightweightInsulatingConcreteUsage" using primary key columns */
  LightweightInsulatingConcreteUsage_by_pk?: Maybe<LightweightInsulatingConcreteUsage>;
  /** fetch aggregated fields from the table: "LightweightInsulatingConcrete" */
  LightweightInsulatingConcrete_aggregate: LightweightInsulatingConcrete_Aggregate;
  /** fetch data from the table: "LightweightInsulatingConcrete" using primary key columns */
  LightweightInsulatingConcrete_by_pk?: Maybe<LightweightInsulatingConcrete>;
  /** fetch data from the table: "LinerPanel" */
  LinerPanel: Array<LinerPanel>;
  /** fetch data from the table: "LinerPanelUsage" */
  LinerPanelUsage: Array<LinerPanelUsage>;
  /** fetch aggregated fields from the table: "LinerPanelUsage" */
  LinerPanelUsage_aggregate: LinerPanelUsage_Aggregate;
  /** fetch data from the table: "LinerPanelUsage" using primary key columns */
  LinerPanelUsage_by_pk?: Maybe<LinerPanelUsage>;
  /** fetch aggregated fields from the table: "LinerPanel" */
  LinerPanel_aggregate: LinerPanel_Aggregate;
  /** fetch data from the table: "LinerPanel" using primary key columns */
  LinerPanel_by_pk?: Maybe<LinerPanel>;
  /** fetch data from the table: "LiquidAppliedSubassemblyUsage" */
  LiquidAppliedSubassemblyUsage: Array<LiquidAppliedSubassemblyUsage>;
  /** fetch aggregated fields from the table: "LiquidAppliedSubassemblyUsage" */
  LiquidAppliedSubassemblyUsage_aggregate: LiquidAppliedSubassemblyUsage_Aggregate;
  /** fetch data from the table: "LiquidAppliedSubassemblyUsage" using primary key columns */
  LiquidAppliedSubassemblyUsage_by_pk?: Maybe<LiquidAppliedSubassemblyUsage>;
  /** fetch data from the table: "LiquidAppliedUsage" */
  LiquidAppliedUsage: Array<LiquidAppliedUsage>;
  /** fetch aggregated fields from the table: "LiquidAppliedUsage" */
  LiquidAppliedUsage_aggregate: LiquidAppliedUsage_Aggregate;
  /** fetch data from the table: "LiquidAppliedUsage" using primary key columns */
  LiquidAppliedUsage_by_pk?: Maybe<LiquidAppliedUsage>;
  /** fetch data from the table: "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
  Load_SearchLayerUsage_DeckFiberReinforcedPlastic: Array<Load_SearchLayerUsage_DeckFiberReinforcedPlastic>;
  /** fetch aggregated fields from the table: "Load_SearchLayerUsage_DeckFiberReinforcedPlastic" */
  Load_SearchLayerUsage_DeckFiberReinforcedPlastic_aggregate: Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Aggregate;
  /** fetch data from the table: "MD_ApprovedUse" */
  MD_ApprovedUse: Array<Md_ApprovedUse>;
  /** An array relationship */
  MD_ApprovedUseAttributes: Array<Md_ApprovedUseAttributes>;
  /** An aggregate relationship */
  MD_ApprovedUseAttributes_aggregate: Md_ApprovedUseAttributes_Aggregate;
  /** fetch data from the table: "MD_ApprovedUseAttributes" using primary key columns */
  MD_ApprovedUseAttributes_by_pk?: Maybe<Md_ApprovedUseAttributes>;
  /** fetch aggregated fields from the table: "MD_ApprovedUse" */
  MD_ApprovedUse_aggregate: Md_ApprovedUse_Aggregate;
  /** fetch data from the table: "MD_ApprovedUse" using primary key columns */
  MD_ApprovedUse_by_pk?: Maybe<Md_ApprovedUse>;
  /** fetch data from the table: "MD_AttributeBase" */
  MD_AttributeBase: Array<Md_AttributeBase>;
  /** fetch aggregated fields from the table: "MD_AttributeBase" */
  MD_AttributeBase_aggregate: Md_AttributeBase_Aggregate;
  /** fetch data from the table: "MD_AttributeBase" using primary key columns */
  MD_AttributeBase_by_pk?: Maybe<Md_AttributeBase>;
  /** fetch data from the table: "MD_BooleanAttribute" */
  MD_BooleanAttribute: Array<Md_BooleanAttribute>;
  /** fetch aggregated fields from the table: "MD_BooleanAttribute" */
  MD_BooleanAttribute_aggregate: Md_BooleanAttribute_Aggregate;
  /** fetch data from the table: "MD_BooleanAttribute" using primary key columns */
  MD_BooleanAttribute_by_pk?: Maybe<Md_BooleanAttribute>;
  /** fetch data from the table: "MD_BooleanDisplayOption" */
  MD_BooleanDisplayOption: Array<Md_BooleanDisplayOption>;
  /** fetch aggregated fields from the table: "MD_BooleanDisplayOption" */
  MD_BooleanDisplayOption_aggregate: Md_BooleanDisplayOption_Aggregate;
  /** fetch data from the table: "MD_BooleanDisplayOption" using primary key columns */
  MD_BooleanDisplayOption_by_pk?: Maybe<Md_BooleanDisplayOption>;
  /** fetch data from the table: "MD_Catalog" */
  MD_Catalog: Array<Md_Catalog>;
  /** fetch aggregated fields from the table: "MD_Catalog" */
  MD_Catalog_aggregate: Md_Catalog_Aggregate;
  /** fetch data from the table: "MD_Catalog" using primary key columns */
  MD_Catalog_by_pk?: Maybe<Md_Catalog>;
  /** fetch data from the table: "MD_CodeTableAttribute" */
  MD_CodeTableAttribute: Array<Md_CodeTableAttribute>;
  /** fetch aggregated fields from the table: "MD_CodeTableAttribute" */
  MD_CodeTableAttribute_aggregate: Md_CodeTableAttribute_Aggregate;
  /** fetch data from the table: "MD_CodeTableAttribute" using primary key columns */
  MD_CodeTableAttribute_by_pk?: Maybe<Md_CodeTableAttribute>;
  /** fetch data from the table: "MD_CodeTables" */
  MD_CodeTables: Array<Md_CodeTables>;
  /** fetch aggregated fields from the table: "MD_CodeTables" */
  MD_CodeTables_aggregate: Md_CodeTables_Aggregate;
  /** fetch data from the table: "MD_CodeTables" using primary key columns */
  MD_CodeTables_by_pk?: Maybe<Md_CodeTables>;
  /** fetch data from the table: "MD_ComponentUsage" */
  MD_ComponentUsage: Array<Md_ComponentUsage>;
  /** An array relationship */
  MD_ComponentUsageAttributes: Array<Md_ComponentUsageAttributes>;
  /** An aggregate relationship */
  MD_ComponentUsageAttributes_aggregate: Md_ComponentUsageAttributes_Aggregate;
  /** fetch data from the table: "MD_ComponentUsageAttributes" using primary key columns */
  MD_ComponentUsageAttributes_by_pk?: Maybe<Md_ComponentUsageAttributes>;
  /** fetch aggregated fields from the table: "MD_ComponentUsage" */
  MD_ComponentUsage_aggregate: Md_ComponentUsage_Aggregate;
  /** fetch data from the table: "MD_ComponentUsage" using primary key columns */
  MD_ComponentUsage_by_pk?: Maybe<Md_ComponentUsage>;
  /** fetch data from the table: "MD_Measure" */
  MD_Measure: Array<Md_Measure>;
  /** fetch aggregated fields from the table: "MD_Measure" */
  MD_Measure_aggregate: Md_Measure_Aggregate;
  /** fetch data from the table: "MD_Measure" using primary key columns */
  MD_Measure_by_pk?: Maybe<Md_Measure>;
  /** fetch data from the table: "MD_MeasurementAttribute" */
  MD_MeasurementAttribute: Array<Md_MeasurementAttribute>;
  /** fetch aggregated fields from the table: "MD_MeasurementAttribute" */
  MD_MeasurementAttribute_aggregate: Md_MeasurementAttribute_Aggregate;
  /** fetch data from the table: "MD_MeasurementAttribute" using primary key columns */
  MD_MeasurementAttribute_by_pk?: Maybe<Md_MeasurementAttribute>;
  /** fetch data from the table: "MD_MeasurementDisplayOption" */
  MD_MeasurementDisplayOption: Array<Md_MeasurementDisplayOption>;
  /** fetch aggregated fields from the table: "MD_MeasurementDisplayOption" */
  MD_MeasurementDisplayOption_aggregate: Md_MeasurementDisplayOption_Aggregate;
  /** fetch data from the table: "MD_MeasurementDisplayOption" using primary key columns */
  MD_MeasurementDisplayOption_by_pk?: Maybe<Md_MeasurementDisplayOption>;
  /** fetch data from the table: "MD_MultivalueAttribute" */
  MD_MultivalueAttribute: Array<Md_MultivalueAttribute>;
  /** fetch aggregated fields from the table: "MD_MultivalueAttribute" */
  MD_MultivalueAttribute_aggregate: Md_MultivalueAttribute_Aggregate;
  /** fetch data from the table: "MD_MultivalueAttribute" using primary key columns */
  MD_MultivalueAttribute_by_pk?: Maybe<Md_MultivalueAttribute>;
  /** fetch data from the table: "MD_MultivalueDisplayOption" */
  MD_MultivalueDisplayOption: Array<Md_MultivalueDisplayOption>;
  /** fetch aggregated fields from the table: "MD_MultivalueDisplayOption" */
  MD_MultivalueDisplayOption_aggregate: Md_MultivalueDisplayOption_Aggregate;
  /** fetch data from the table: "MD_MultivalueDisplayOption" using primary key columns */
  MD_MultivalueDisplayOption_by_pk?: Maybe<Md_MultivalueDisplayOption>;
  /** fetch data from the table: "MD_ProductCategory" */
  MD_ProductCategory: Array<Md_ProductCategory>;
  /** fetch aggregated fields from the table: "MD_ProductCategory" */
  MD_ProductCategory_aggregate: Md_ProductCategory_Aggregate;
  /** fetch data from the table: "MD_ProductCategory" using primary key columns */
  MD_ProductCategory_by_pk?: Maybe<Md_ProductCategory>;
  /** fetch data from the table: "MD_ProductSubcategory" */
  MD_ProductSubcategory: Array<Md_ProductSubcategory>;
  /** fetch aggregated fields from the table: "MD_ProductSubcategory" */
  MD_ProductSubcategory_aggregate: Md_ProductSubcategory_Aggregate;
  /** fetch data from the table: "MD_ProductSubcategory" using primary key columns */
  MD_ProductSubcategory_by_pk?: Maybe<Md_ProductSubcategory>;
  /** fetch data from the table: "MaintenanceCoating" */
  MaintenanceCoating: Array<MaintenanceCoating>;
  /** fetch data from the table: "MaintenanceCoatingSubstrate" */
  MaintenanceCoatingSubstrate: Array<MaintenanceCoatingSubstrate>;
  /** fetch aggregated fields from the table: "MaintenanceCoatingSubstrate" */
  MaintenanceCoatingSubstrate_aggregate: MaintenanceCoatingSubstrate_Aggregate;
  /** fetch data from the table: "MaintenanceCoatingSubstrate" using primary key columns */
  MaintenanceCoatingSubstrate_by_pk?: Maybe<MaintenanceCoatingSubstrate>;
  /** fetch data from the table: "MaintenanceCoatingUsage" */
  MaintenanceCoatingUsage: Array<MaintenanceCoatingUsage>;
  /** fetch aggregated fields from the table: "MaintenanceCoatingUsage" */
  MaintenanceCoatingUsage_aggregate: MaintenanceCoatingUsage_Aggregate;
  /** fetch data from the table: "MaintenanceCoatingUsage" using primary key columns */
  MaintenanceCoatingUsage_by_pk?: Maybe<MaintenanceCoatingUsage>;
  /** fetch aggregated fields from the table: "MaintenanceCoating" */
  MaintenanceCoating_aggregate: MaintenanceCoating_Aggregate;
  /** fetch data from the table: "MaintenanceCoating" using primary key columns */
  MaintenanceCoating_by_pk?: Maybe<MaintenanceCoating>;
  /** fetch data from the table: "ManufacturedProduct" */
  ManufacturedProduct: Array<ManufacturedProduct>;
  /** fetch aggregated fields from the table: "ManufacturedProduct" */
  ManufacturedProduct_aggregate: ManufacturedProduct_Aggregate;
  /** fetch data from the table: "ManufacturedProduct" using primary key columns */
  ManufacturedProduct_by_pk?: Maybe<ManufacturedProduct>;
  /** fetch data from the table: "Manufacturer" */
  Manufacturer: Array<Manufacturer>;
  /** fetch data from the table: "ManufacturerChangeHistory" */
  ManufacturerChangeHistory: Array<ManufacturerChangeHistory>;
  /** fetch aggregated fields from the table: "ManufacturerChangeHistory" */
  ManufacturerChangeHistory_aggregate: ManufacturerChangeHistory_Aggregate;
  /** fetch data from the table: "ManufacturerChangeHistory" using primary key columns */
  ManufacturerChangeHistory_by_pk?: Maybe<ManufacturerChangeHistory>;
  /** fetch data from the table: "ManufacturerList" */
  ManufacturerList: Array<ManufacturerList>;
  /** fetch aggregated fields from the table: "ManufacturerList" */
  ManufacturerList_aggregate: ManufacturerList_Aggregate;
  /** fetch aggregated fields from the table: "Manufacturer" */
  Manufacturer_aggregate: Manufacturer_Aggregate;
  /** fetch data from the table: "Manufacturer" using primary key columns */
  Manufacturer_by_pk?: Maybe<Manufacturer>;
  /** fetch data from the table: "MultiplyCoverConfig" */
  MultiplyCoverConfig: Array<MultiplyCoverConfig>;
  /** fetch aggregated fields from the table: "MultiplyCoverConfig" */
  MultiplyCoverConfig_aggregate: MultiplyCoverConfig_Aggregate;
  /** fetch data from the table: "MultiplyCoverConfig" using primary key columns */
  MultiplyCoverConfig_by_pk?: Maybe<MultiplyCoverConfig>;
  /** fetch data from the table: "MultiplyCoverSubassemblyUsage" */
  MultiplyCoverSubassemblyUsage: Array<MultiplyCoverSubassemblyUsage>;
  /** fetch aggregated fields from the table: "MultiplyCoverSubassemblyUsage" */
  MultiplyCoverSubassemblyUsage_aggregate: MultiplyCoverSubassemblyUsage_Aggregate;
  /** fetch data from the table: "MultiplyCoverSubassemblyUsage" using primary key columns */
  MultiplyCoverSubassemblyUsage_by_pk?: Maybe<MultiplyCoverSubassemblyUsage>;
  /** fetch data from the table: "NavAssembly" */
  NavAssembly: Array<NavAssembly>;
  /** fetch data from the table: "NavAssemblyMultiplyInfo" */
  NavAssemblyMultiplyInfo: Array<NavAssemblyMultiplyInfo>;
  /** fetch aggregated fields from the table: "NavAssemblyMultiplyInfo" */
  NavAssemblyMultiplyInfo_aggregate: NavAssemblyMultiplyInfo_Aggregate;
  /** fetch aggregated fields from the table: "NavAssembly" */
  NavAssembly_aggregate: NavAssembly_Aggregate;
  /** fetch data from the table: "NavAssembly" using primary key columns */
  NavAssembly_by_pk?: Maybe<NavAssembly>;
  /** fetch data from the table: "NavLayer" */
  NavLayer: Array<NavLayer>;
  /** fetch aggregated fields from the table: "NavLayer" */
  NavLayer_aggregate: NavLayer_Aggregate;
  /** fetch data from the table: "NavLayer" using primary key columns */
  NavLayer_by_pk?: Maybe<NavLayer>;
  /** fetch data from the table: "NavMultiplyCoverConfig" */
  NavMultiplyCoverConfig: Array<NavMultiplyCoverConfig>;
  /** fetch aggregated fields from the table: "NavMultiplyCoverConfig" */
  NavMultiplyCoverConfig_aggregate: NavMultiplyCoverConfig_Aggregate;
  /** fetch data from the table: "NavMultiplyCoverConfig" using primary key columns */
  NavMultiplyCoverConfig_by_pk?: Maybe<NavMultiplyCoverConfig>;
  /** fetch data from the table: "NavSecurementLayer" */
  NavSecurementLayer: Array<NavSecurementLayer>;
  /** fetch aggregated fields from the table: "NavSecurementLayer" */
  NavSecurementLayer_aggregate: NavSecurementLayer_Aggregate;
  /** fetch data from the table: "NavSecurementLayer" using primary key columns */
  NavSecurementLayer_by_pk?: Maybe<NavSecurementLayer>;
  /** fetch data from the table: "NavSecurementUsage" */
  NavSecurementUsage: Array<NavSecurementUsage>;
  /** fetch aggregated fields from the table: "NavSecurementUsage" */
  NavSecurementUsage_aggregate: NavSecurementUsage_Aggregate;
  /** fetch data from the table: "NavSecurementUsage" using primary key columns */
  NavSecurementUsage_by_pk?: Maybe<NavSecurementUsage>;
  /** fetch data from the table: "NavUsage" */
  NavUsage: Array<NavUsage>;
  /** fetch data from the table: "NavUsageSA" */
  NavUsageSA: Array<NavUsageSa>;
  /** fetch aggregated fields from the table: "NavUsageSA" */
  NavUsageSA_aggregate: NavUsageSa_Aggregate;
  /** fetch data from the table: "NavUsageSA" using primary key columns */
  NavUsageSA_by_pk?: Maybe<NavUsageSa>;
  /** fetch aggregated fields from the table: "NavUsage" */
  NavUsage_aggregate: NavUsage_Aggregate;
  /** fetch data from the table: "NavUsage" using primary key columns */
  NavUsage_by_pk?: Maybe<NavUsage>;
  /** An array relationship */
  Nav_SinglePlyBondingAdhesives: Array<Nav_SinglePlyBondingAdhesives>;
  /** An aggregate relationship */
  Nav_SinglePlyBondingAdhesives_aggregate: Nav_SinglePlyBondingAdhesives_Aggregate;
  /** An array relationship */
  Nav_SinglePlyLapAdhesives: Array<Nav_SinglePlyLapAdhesives>;
  /** An aggregate relationship */
  Nav_SinglePlyLapAdhesives_aggregate: Nav_SinglePlyLapAdhesives_Aggregate;
  /** fetch data from the table: "NextAssemblyNum" */
  NextAssemblyNum: Array<NextAssemblyNum>;
  /** fetch aggregated fields from the table: "NextAssemblyNum" */
  NextAssemblyNum_aggregate: NextAssemblyNum_Aggregate;
  /** fetch data from the table: "NextAssemblyNum" using primary key columns */
  NextAssemblyNum_by_pk?: Maybe<NextAssemblyNum>;
  /** fetch data from the table: "Note" */
  Note: Array<Note>;
  /** fetch aggregated fields from the table: "Note" */
  Note_aggregate: Note_Aggregate;
  /** fetch data from the table: "Note" using primary key columns */
  Note_by_pk?: Maybe<Note>;
  /** fetch data from the table: "OtherPly" */
  OtherPly: Array<OtherPly>;
  /** fetch data from the table: "OtherPlyUsage" */
  OtherPlyUsage: Array<OtherPlyUsage>;
  /** fetch aggregated fields from the table: "OtherPlyUsage" */
  OtherPlyUsage_aggregate: OtherPlyUsage_Aggregate;
  /** fetch data from the table: "OtherPlyUsage" using primary key columns */
  OtherPlyUsage_by_pk?: Maybe<OtherPlyUsage>;
  /** fetch aggregated fields from the table: "OtherPly" */
  OtherPly_aggregate: OtherPly_Aggregate;
  /** fetch data from the table: "OtherPly" using primary key columns */
  OtherPly_by_pk?: Maybe<OtherPly>;
  /** fetch data from the table: "PreassembledFastening" */
  PreassembledFastening: Array<PreassembledFastening>;
  /** fetch data from the table: "PreassembledFasteningUsage" */
  PreassembledFasteningUsage: Array<PreassembledFasteningUsage>;
  /** fetch aggregated fields from the table: "PreassembledFasteningUsage" */
  PreassembledFasteningUsage_aggregate: PreassembledFasteningUsage_Aggregate;
  /** fetch data from the table: "PreassembledFasteningUsage" using primary key columns */
  PreassembledFasteningUsage_by_pk?: Maybe<PreassembledFasteningUsage>;
  /** fetch aggregated fields from the table: "PreassembledFastening" */
  PreassembledFastening_aggregate: PreassembledFastening_Aggregate;
  /** fetch data from the table: "PreassembledFastening" using primary key columns */
  PreassembledFastening_by_pk?: Maybe<PreassembledFastening>;
  /** fetch data from the table: "PrivateLabel" */
  PrivateLabel: Array<PrivateLabel>;
  /** fetch aggregated fields from the table: "PrivateLabel" */
  PrivateLabel_aggregate: PrivateLabel_Aggregate;
  /** fetch data from the table: "PrivateLabel" using primary key columns */
  PrivateLabel_by_pk?: Maybe<PrivateLabel>;
  /** fetch data from the table: "PrivateLabeler" */
  PrivateLabeler: Array<PrivateLabeler>;
  /** fetch aggregated fields from the table: "PrivateLabeler" */
  PrivateLabeler_aggregate: PrivateLabeler_Aggregate;
  /** An array relationship */
  ProductDependencies: Array<ProductDependencies>;
  /** An aggregate relationship */
  ProductDependencies_aggregate: ProductDependencies_Aggregate;
  /** fetch data from the table: "ProductDependencies" using primary key columns */
  ProductDependencies_by_pk?: Maybe<ProductDependencies>;
  /** fetch data from the table: "ProductsBySubcategory" */
  ProductsBySubcategory: Array<ProductsBySubcategory>;
  /** fetch aggregated fields from the table: "ProductsBySubcategory" */
  ProductsBySubcategory_aggregate: ProductsBySubcategory_Aggregate;
  /** fetch data from the table: "R_AdhesiveTapeWidth" */
  R_AdhesiveTapeWidth: Array<R_AdhesiveTapeWidth>;
  /** fetch aggregated fields from the table: "R_AdhesiveTapeWidth" */
  R_AdhesiveTapeWidth_aggregate: R_AdhesiveTapeWidth_Aggregate;
  /** fetch data from the table: "R_AdhesiveTapeWidth" using primary key columns */
  R_AdhesiveTapeWidth_by_pk?: Maybe<R_AdhesiveTapeWidth>;
  /** fetch data from the table: "R_ApprovedUse_Subcategory" */
  R_ApprovedUse_Subcategory: Array<R_ApprovedUse_Subcategory>;
  /** fetch aggregated fields from the table: "R_ApprovedUse_Subcategory" */
  R_ApprovedUse_Subcategory_aggregate: R_ApprovedUse_Subcategory_Aggregate;
  /** fetch data from the table: "R_ApprovedUse_Subcategory" using primary key columns */
  R_ApprovedUse_Subcategory_by_pk?: Maybe<R_ApprovedUse_Subcategory>;
  /** fetch data from the table: "R_ComponentType_ApprovedUse" */
  R_ComponentType_ApprovedUse: Array<R_ComponentType_ApprovedUse>;
  /** fetch aggregated fields from the table: "R_ComponentType_ApprovedUse" */
  R_ComponentType_ApprovedUse_aggregate: R_ComponentType_ApprovedUse_Aggregate;
  /** fetch data from the table: "R_ComponentType_ApprovedUse" using primary key columns */
  R_ComponentType_ApprovedUse_by_pk?: Maybe<R_ComponentType_ApprovedUse>;
  /** fetch data from the table: "R_CopingFastener" */
  R_CopingFastener: Array<R_CopingFastener>;
  /** fetch aggregated fields from the table: "R_CopingFastener" */
  R_CopingFastener_aggregate: R_CopingFastener_Aggregate;
  /** fetch data from the table: "R_CopingFastener" using primary key columns */
  R_CopingFastener_by_pk?: Maybe<R_CopingFastener>;
  /** fetch data from the table: "R_Country_StateProvince" */
  R_Country_StateProvince: Array<R_Country_StateProvince>;
  /** fetch aggregated fields from the table: "R_Country_StateProvince" */
  R_Country_StateProvince_aggregate: R_Country_StateProvince_Aggregate;
  /** fetch data from the table: "R_Country_StateProvince" using primary key columns */
  R_Country_StateProvince_by_pk?: Maybe<R_Country_StateProvince>;
  /** fetch data from the table: "R_ExpansionJoinFastener" */
  R_ExpansionJoinFastener: Array<R_ExpansionJoinFastener>;
  /** fetch aggregated fields from the table: "R_ExpansionJoinFastener" */
  R_ExpansionJoinFastener_aggregate: R_ExpansionJoinFastener_Aggregate;
  /** fetch data from the table: "R_ExpansionJoinFastener" using primary key columns */
  R_ExpansionJoinFastener_by_pk?: Maybe<R_ExpansionJoinFastener>;
  /** fetch data from the table: "R_FasciaComponentFastenerComponent" */
  R_FasciaComponentFastenerComponent: Array<R_FasciaComponentFastenerComponent>;
  /** fetch aggregated fields from the table: "R_FasciaComponentFastenerComponent" */
  R_FasciaComponentFastenerComponent_aggregate: R_FasciaComponentFastenerComponent_Aggregate;
  /** fetch data from the table: "R_FasciaComponentFastenerComponent" using primary key columns */
  R_FasciaComponentFastenerComponent_by_pk?: Maybe<R_FasciaComponentFastenerComponent>;
  /** An array relationship */
  R_GutterFasteners: Array<R_GutterFasteners>;
  /** An aggregate relationship */
  R_GutterFasteners_aggregate: R_GutterFasteners_Aggregate;
  /** fetch data from the table: "R_GutterFasteners" using primary key columns */
  R_GutterFasteners_by_pk?: Maybe<R_GutterFasteners>;
  /** fetch data from the table: "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
  R_LinerPanelMaterialFiberReinforcedPlasticDeck: Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck>;
  /** fetch aggregated fields from the table: "R_LinerPanelMaterialFiberReinforcedPlasticDeck" */
  R_LinerPanelMaterialFiberReinforcedPlasticDeck_aggregate: R_LinerPanelMaterialFiberReinforcedPlasticDeck_Aggregate;
  /** fetch data from the table: "R_LinerPanelMaterialFiberReinforcedPlasticDeck" using primary key columns */
  R_LinerPanelMaterialFiberReinforcedPlasticDeck_by_pk?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck>;
  /** fetch data from the table: "R_MaintenanceCoatingSubstrate" */
  R_MaintenanceCoatingSubstrate: Array<R_MaintenanceCoatingSubstrate>;
  /** fetch aggregated fields from the table: "R_MaintenanceCoatingSubstrate" */
  R_MaintenanceCoatingSubstrate_aggregate: R_MaintenanceCoatingSubstrate_Aggregate;
  /** fetch data from the table: "R_MaintenanceCoatingSubstrate" using primary key columns */
  R_MaintenanceCoatingSubstrate_by_pk?: Maybe<R_MaintenanceCoatingSubstrate>;
  /** fetch data from the table: "R_NavAssembly_NavMultiplyCoverConfig" */
  R_NavAssembly_NavMultiplyCoverConfig: Array<R_NavAssembly_NavMultiplyCoverConfig>;
  /** fetch aggregated fields from the table: "R_NavAssembly_NavMultiplyCoverConfig" */
  R_NavAssembly_NavMultiplyCoverConfig_aggregate: R_NavAssembly_NavMultiplyCoverConfig_Aggregate;
  /** fetch data from the table: "R_NavAssembly_NavMultiplyCoverConfig" using primary key columns */
  R_NavAssembly_NavMultiplyCoverConfig_by_pk?: Maybe<R_NavAssembly_NavMultiplyCoverConfig>;
  /** fetch data from the table: "R_ProjectContact" */
  R_ProjectContact: Array<R_ProjectContact>;
  /** fetch aggregated fields from the table: "R_ProjectContact" */
  R_ProjectContact_aggregate: R_ProjectContact_Aggregate;
  /** fetch data from the table: "R_ProjectContact" using primary key columns */
  R_ProjectContact_by_pk?: Maybe<R_ProjectContact>;
  /** fetch data from the table: "R_ProjectNote" */
  R_ProjectNote: Array<R_ProjectNote>;
  /** fetch aggregated fields from the table: "R_ProjectNote" */
  R_ProjectNote_aggregate: R_ProjectNote_Aggregate;
  /** fetch data from the table: "R_ProjectNote" using primary key columns */
  R_ProjectNote_by_pk?: Maybe<R_ProjectNote>;
  /** fetch data from the table: "R_RegletFastener" */
  R_RegletFastener: Array<R_RegletFastener>;
  /** fetch aggregated fields from the table: "R_RegletFastener" */
  R_RegletFastener_aggregate: R_RegletFastener_Aggregate;
  /** fetch data from the table: "R_RegletFastener" using primary key columns */
  R_RegletFastener_by_pk?: Maybe<R_RegletFastener>;
  /** fetch data from the table: "R_RoofAreaContact" */
  R_RoofAreaContact: Array<R_RoofAreaContact>;
  /** fetch aggregated fields from the table: "R_RoofAreaContact" */
  R_RoofAreaContact_aggregate: R_RoofAreaContact_Aggregate;
  /** fetch data from the table: "R_RoofAreaContact" using primary key columns */
  R_RoofAreaContact_by_pk?: Maybe<R_RoofAreaContact>;
  /** fetch data from the table: "R_RoofAreaNote" */
  R_RoofAreaNote: Array<R_RoofAreaNote>;
  /** fetch aggregated fields from the table: "R_RoofAreaNote" */
  R_RoofAreaNote_aggregate: R_RoofAreaNote_Aggregate;
  /** fetch data from the table: "R_RoofAreaNote" using primary key columns */
  R_RoofAreaNote_by_pk?: Maybe<R_RoofAreaNote>;
  /** fetch data from the table: "R_RoofAreaScenario" */
  R_RoofAreaScenario: Array<R_RoofAreaScenario>;
  /** fetch aggregated fields from the table: "R_RoofAreaScenario" */
  R_RoofAreaScenario_aggregate: R_RoofAreaScenario_Aggregate;
  /** fetch data from the table: "R_RoofAreaScenario" using primary key columns */
  R_RoofAreaScenario_by_pk?: Maybe<R_RoofAreaScenario>;
  /** fetch data from the table: "R_ScenarioNote" */
  R_ScenarioNote: Array<R_ScenarioNote>;
  /** fetch aggregated fields from the table: "R_ScenarioNote" */
  R_ScenarioNote_aggregate: R_ScenarioNote_Aggregate;
  /** fetch data from the table: "R_ScenarioNote" using primary key columns */
  R_ScenarioNote_by_pk?: Maybe<R_ScenarioNote>;
  /** An array relationship */
  R_SinglePly_BondingAdhesives: Array<R_SinglePly_BondingAdhesives>;
  /** An aggregate relationship */
  R_SinglePly_BondingAdhesives_aggregate: R_SinglePly_BondingAdhesives_Aggregate;
  /** fetch data from the table: "R_SinglePly_BondingAdhesives" using primary key columns */
  R_SinglePly_BondingAdhesives_by_pk?: Maybe<R_SinglePly_BondingAdhesives>;
  /** An array relationship */
  R_SinglePly_LapAdhesives: Array<R_SinglePly_LapAdhesives>;
  /** An aggregate relationship */
  R_SinglePly_LapAdhesives_aggregate: R_SinglePly_LapAdhesives_Aggregate;
  /** fetch data from the table: "R_SinglePly_LapAdhesives" using primary key columns */
  R_SinglePly_LapAdhesives_by_pk?: Maybe<R_SinglePly_LapAdhesives>;
  /** fetch data from the table: "R_Subassembly_MultiplyCoverConfig" */
  R_Subassembly_MultiplyCoverConfig: Array<R_Subassembly_MultiplyCoverConfig>;
  /** fetch aggregated fields from the table: "R_Subassembly_MultiplyCoverConfig" */
  R_Subassembly_MultiplyCoverConfig_aggregate: R_Subassembly_MultiplyCoverConfig_Aggregate;
  /** fetch data from the table: "R_Subassembly_MultiplyCoverConfig" using primary key columns */
  R_Subassembly_MultiplyCoverConfig_by_pk?: Maybe<R_Subassembly_MultiplyCoverConfig>;
  /** fetch data from the table: "R_UserContact" */
  R_UserContact: Array<R_UserContact>;
  /** fetch aggregated fields from the table: "R_UserContact" */
  R_UserContact_aggregate: R_UserContact_Aggregate;
  /** fetch data from the table: "R_UserContact" using primary key columns */
  R_UserContact_by_pk?: Maybe<R_UserContact>;
  /** fetch data from the table: "Reglet" */
  Reglet: Array<Reglet>;
  /** fetch aggregated fields from the table: "Reglet" */
  Reglet_aggregate: Reglet_Aggregate;
  /** fetch data from the table: "Reglet" using primary key columns */
  Reglet_by_pk?: Maybe<Reglet>;
  /** fetch data from the table: "ReviewerWorkspace" */
  ReviewerWorkspace: Array<ReviewerWorkspace>;
  /** An array relationship */
  ReviewerWorkspaceData: Array<ReviewerWorkspaceData>;
  /** An aggregate relationship */
  ReviewerWorkspaceData_aggregate: ReviewerWorkspaceData_Aggregate;
  /** fetch data from the table: "ReviewerWorkspaceData" using primary key columns */
  ReviewerWorkspaceData_by_pk?: Maybe<ReviewerWorkspaceData>;
  /** fetch aggregated fields from the table: "ReviewerWorkspace" */
  ReviewerWorkspace_aggregate: ReviewerWorkspace_Aggregate;
  /** fetch data from the table: "ReviewerWorkspace" using primary key columns */
  ReviewerWorkspace_by_pk?: Maybe<ReviewerWorkspace>;
  /** fetch data from the table: "RoofArea" */
  RoofArea: Array<RoofArea>;
  /** fetch data from the table: "RoofAreaWindPressure" */
  RoofAreaWindPressure: Array<RoofAreaWindPressure>;
  /** fetch aggregated fields from the table: "RoofAreaWindPressure" */
  RoofAreaWindPressure_aggregate: RoofAreaWindPressure_Aggregate;
  /** fetch data from the table: "RoofAreaWindPressure" using primary key columns */
  RoofAreaWindPressure_by_pk?: Maybe<RoofAreaWindPressure>;
  /** fetch aggregated fields from the table: "RoofArea" */
  RoofArea_aggregate: RoofArea_Aggregate;
  /** fetch data from the table: "RoofArea" using primary key columns */
  RoofArea_by_pk?: Maybe<RoofArea>;
  /** fetch data from the table: "RoofAssembly" */
  RoofAssembly: Array<RoofAssembly>;
  /** fetch data from the table: "RoofAssemblyDelistData" */
  RoofAssemblyDelistData: Array<RoofAssemblyDelistData>;
  /** fetch aggregated fields from the table: "RoofAssemblyDelistData" */
  RoofAssemblyDelistData_aggregate: RoofAssemblyDelistData_Aggregate;
  /** An array relationship */
  RoofAssemblyLayers: Array<RoofAssemblyLayers>;
  /** An aggregate relationship */
  RoofAssemblyLayers_aggregate: RoofAssemblyLayers_Aggregate;
  /** fetch data from the table: "RoofAssemblyLayers" using primary key columns */
  RoofAssemblyLayers_by_pk?: Maybe<RoofAssemblyLayers>;
  /** fetch aggregated fields from the table: "RoofAssembly" */
  RoofAssembly_aggregate: RoofAssembly_Aggregate;
  /** fetch data from the table: "RoofAssembly" using primary key columns */
  RoofAssembly_by_pk?: Maybe<RoofAssembly>;
  /** fetch data from the table: "RoofProject" */
  RoofProject: Array<RoofProject>;
  /** fetch aggregated fields from the table: "RoofProject" */
  RoofProject_aggregate: RoofProject_Aggregate;
  /** fetch data from the table: "RoofProject" using primary key columns */
  RoofProject_by_pk?: Maybe<RoofProject>;
  /** fetch data from the table: "Scenario" */
  Scenario: Array<Scenario>;
  /** fetch data from the table: "ScenarioAlert" */
  ScenarioAlert: Array<ScenarioAlert>;
  /** fetch aggregated fields from the table: "ScenarioAlert" */
  ScenarioAlert_aggregate: ScenarioAlert_Aggregate;
  /** fetch data from the table: "ScenarioAlert" using primary key columns */
  ScenarioAlert_by_pk?: Maybe<ScenarioAlert>;
  /** fetch data from the table: "ScenarioLayer" */
  ScenarioLayer: Array<ScenarioLayer>;
  /** fetch aggregated fields from the table: "ScenarioLayer" */
  ScenarioLayer_aggregate: ScenarioLayer_Aggregate;
  /** fetch data from the table: "ScenarioLayer" using primary key columns */
  ScenarioLayer_by_pk?: Maybe<ScenarioLayer>;
  /** fetch data from the table: "ScenarioUsage" */
  ScenarioUsage: Array<ScenarioUsage>;
  /** fetch aggregated fields from the table: "ScenarioUsage" */
  ScenarioUsage_aggregate: ScenarioUsage_Aggregate;
  /** fetch data from the table: "ScenarioUsage" using primary key columns */
  ScenarioUsage_by_pk?: Maybe<ScenarioUsage>;
  /** fetch aggregated fields from the table: "Scenario" */
  Scenario_aggregate: Scenario_Aggregate;
  /** fetch data from the table: "Scenario" using primary key columns */
  Scenario_by_pk?: Maybe<Scenario>;
  /** fetch data from the table: "SeamPrimer" */
  SeamPrimer: Array<SeamPrimer>;
  /** fetch data from the table: "SeamPrimerUsage" */
  SeamPrimerUsage: Array<SeamPrimerUsage>;
  /** fetch aggregated fields from the table: "SeamPrimerUsage" */
  SeamPrimerUsage_aggregate: SeamPrimerUsage_Aggregate;
  /** fetch data from the table: "SeamPrimerUsage" using primary key columns */
  SeamPrimerUsage_by_pk?: Maybe<SeamPrimerUsage>;
  /** fetch aggregated fields from the table: "SeamPrimer" */
  SeamPrimer_aggregate: SeamPrimer_Aggregate;
  /** fetch data from the table: "SeamPrimer" using primary key columns */
  SeamPrimer_by_pk?: Maybe<SeamPrimer>;
  /** fetch data from the table: "SeamTapeSystemUsage" */
  SeamTapeSystemUsage: Array<SeamTapeSystemUsage>;
  /** fetch aggregated fields from the table: "SeamTapeSystemUsage" */
  SeamTapeSystemUsage_aggregate: SeamTapeSystemUsage_Aggregate;
  /** fetch data from the table: "SeamTapeSystemUsage" using primary key columns */
  SeamTapeSystemUsage_by_pk?: Maybe<SeamTapeSystemUsage>;
  /** fetch data from the table: "SeamWash" */
  SeamWash: Array<SeamWash>;
  /** fetch data from the table: "SeamWashUsage" */
  SeamWashUsage: Array<SeamWashUsage>;
  /** fetch aggregated fields from the table: "SeamWashUsage" */
  SeamWashUsage_aggregate: SeamWashUsage_Aggregate;
  /** fetch data from the table: "SeamWashUsage" using primary key columns */
  SeamWashUsage_by_pk?: Maybe<SeamWashUsage>;
  /** fetch aggregated fields from the table: "SeamWash" */
  SeamWash_aggregate: SeamWash_Aggregate;
  /** fetch data from the table: "SeamWash" using primary key columns */
  SeamWash_by_pk?: Maybe<SeamWash>;
  /** fetch data from the table: "SearchNavAssembly_ManufacturerProduct" */
  SearchNavAssembly_ManufacturerProduct: Array<SearchNavAssembly_ManufacturerProduct>;
  /** fetch aggregated fields from the table: "SearchNavAssembly_ManufacturerProduct" */
  SearchNavAssembly_ManufacturerProduct_aggregate: SearchNavAssembly_ManufacturerProduct_Aggregate;
  /** fetch data from the table: "SearchNavAssembly_ManufacturerProduct" using primary key columns */
  SearchNavAssembly_ManufacturerProduct_by_pk?: Maybe<SearchNavAssembly_ManufacturerProduct>;
  /** fetch data from the table: "SecurementLayer" */
  SecurementLayer: Array<SecurementLayer>;
  /** fetch data from the table: "SecurementLayerUsage" */
  SecurementLayerUsage: Array<SecurementLayerUsage>;
  /** fetch aggregated fields from the table: "SecurementLayerUsage" */
  SecurementLayerUsage_aggregate: SecurementLayerUsage_Aggregate;
  /** fetch data from the table: "SecurementLayerUsage" using primary key columns */
  SecurementLayerUsage_by_pk?: Maybe<SecurementLayerUsage>;
  /** fetch aggregated fields from the table: "SecurementLayer" */
  SecurementLayer_aggregate: SecurementLayer_Aggregate;
  /** fetch data from the table: "SecurementLayer" using primary key columns */
  SecurementLayer_by_pk?: Maybe<SecurementLayer>;
  /** fetch data from the table: "SelfSecurementUsage" */
  SelfSecurementUsage: Array<SelfSecurementUsage>;
  /** fetch aggregated fields from the table: "SelfSecurementUsage" */
  SelfSecurementUsage_aggregate: SelfSecurementUsage_Aggregate;
  /** fetch data from the table: "SelfSecurementUsage" using primary key columns */
  SelfSecurementUsage_by_pk?: Maybe<SelfSecurementUsage>;
  /** fetch data from the table: "SeparatorSheet" */
  SeparatorSheet: Array<SeparatorSheet>;
  /** fetch data from the table: "SeparatorSheetUsage" */
  SeparatorSheetUsage: Array<SeparatorSheetUsage>;
  /** fetch aggregated fields from the table: "SeparatorSheetUsage" */
  SeparatorSheetUsage_aggregate: SeparatorSheetUsage_Aggregate;
  /** fetch data from the table: "SeparatorSheetUsage" using primary key columns */
  SeparatorSheetUsage_by_pk?: Maybe<SeparatorSheetUsage>;
  /** fetch aggregated fields from the table: "SeparatorSheet" */
  SeparatorSheet_aggregate: SeparatorSheet_Aggregate;
  /** fetch data from the table: "SeparatorSheet" using primary key columns */
  SeparatorSheet_by_pk?: Maybe<SeparatorSheet>;
  /** An array relationship */
  Shingles: Array<Shingles>;
  /** An aggregate relationship */
  Shingles_aggregate: Shingles_Aggregate;
  /** fetch data from the table: "Shingles" using primary key columns */
  Shingles_by_pk?: Maybe<Shingles>;
  /** fetch data from the table: "SinglePlyCover" */
  SinglePlyCover: Array<SinglePlyCover>;
  /** fetch aggregated fields from the table: "SinglePlyCover" */
  SinglePlyCover_aggregate: SinglePlyCover_Aggregate;
  /** fetch data from the table: "SinglePlyCover" using primary key columns */
  SinglePlyCover_by_pk?: Maybe<SinglePlyCover>;
  /** fetch data from the table: "SinglePlyUsage" */
  SinglePlyUsage: Array<SinglePlyUsage>;
  /** fetch aggregated fields from the table: "SinglePlyUsage" */
  SinglePlyUsage_aggregate: SinglePlyUsage_Aggregate;
  /** fetch data from the table: "SinglePlyUsage" using primary key columns */
  SinglePlyUsage_by_pk?: Maybe<SinglePlyUsage>;
  /** fetch data from the table: "SprayFoamInsulationUsage" */
  SprayFoamInsulationUsage: Array<SprayFoamInsulationUsage>;
  /** fetch aggregated fields from the table: "SprayFoamInsulationUsage" */
  SprayFoamInsulationUsage_aggregate: SprayFoamInsulationUsage_Aggregate;
  /** fetch data from the table: "SprayFoamInsulationUsage" using primary key columns */
  SprayFoamInsulationUsage_by_pk?: Maybe<SprayFoamInsulationUsage>;
  /** fetch data from the table: "SprayedFoamInsulation" */
  SprayedFoamInsulation: Array<SprayedFoamInsulation>;
  /** fetch aggregated fields from the table: "SprayedFoamInsulation" */
  SprayedFoamInsulation_aggregate: SprayedFoamInsulation_Aggregate;
  /** fetch data from the table: "SprayedFoamInsulation" using primary key columns */
  SprayedFoamInsulation_by_pk?: Maybe<SprayedFoamInsulation>;
  /** fetch data from the table: "StandingLapSeamCover" */
  StandingLapSeamCover: Array<StandingLapSeamCover>;
  /** fetch aggregated fields from the table: "StandingLapSeamCover" */
  StandingLapSeamCover_aggregate: StandingLapSeamCover_Aggregate;
  /** fetch data from the table: "StandingLapSeamCover" using primary key columns */
  StandingLapSeamCover_by_pk?: Maybe<StandingLapSeamCover>;
  /** fetch data from the table: "StandingLapSeamUsage" */
  StandingLapSeamUsage: Array<StandingLapSeamUsage>;
  /** fetch aggregated fields from the table: "StandingLapSeamUsage" */
  StandingLapSeamUsage_aggregate: StandingLapSeamUsage_Aggregate;
  /** fetch data from the table: "StandingLapSeamUsage" using primary key columns */
  StandingLapSeamUsage_by_pk?: Maybe<StandingLapSeamUsage>;
  /** fetch data from the table: "SteelDeck" */
  SteelDeck: Array<SteelDeck>;
  /** fetch data from the table: "SteelDeckLayer" */
  SteelDeckLayer: Array<SteelDeckLayer>;
  /** fetch aggregated fields from the table: "SteelDeckLayer" */
  SteelDeckLayer_aggregate: SteelDeckLayer_Aggregate;
  /** fetch data from the table: "SteelDeckLayer" using primary key columns */
  SteelDeckLayer_by_pk?: Maybe<SteelDeckLayer>;
  /** fetch data from the table: "SteelDeckUsage" */
  SteelDeckUsage: Array<SteelDeckUsage>;
  /** fetch aggregated fields from the table: "SteelDeckUsage" */
  SteelDeckUsage_aggregate: SteelDeckUsage_Aggregate;
  /** fetch data from the table: "SteelDeckUsage" using primary key columns */
  SteelDeckUsage_by_pk?: Maybe<SteelDeckUsage>;
  /** fetch aggregated fields from the table: "SteelDeck" */
  SteelDeck_aggregate: SteelDeck_Aggregate;
  /** fetch data from the table: "SteelDeck" using primary key columns */
  SteelDeck_by_pk?: Maybe<SteelDeck>;
  /** fetch data from the table: "StressPlate" */
  StressPlate: Array<StressPlate>;
  /** fetch data from the table: "StressPlateUsage" */
  StressPlateUsage: Array<StressPlateUsage>;
  /** fetch aggregated fields from the table: "StressPlateUsage" */
  StressPlateUsage_aggregate: StressPlateUsage_Aggregate;
  /** fetch data from the table: "StressPlateUsage" using primary key columns */
  StressPlateUsage_by_pk?: Maybe<StressPlateUsage>;
  /** fetch aggregated fields from the table: "StressPlate" */
  StressPlate_aggregate: StressPlate_Aggregate;
  /** fetch data from the table: "StressPlate" using primary key columns */
  StressPlate_by_pk?: Maybe<StressPlate>;
  /** fetch data from the table: "StructuralConcreteLayer" */
  StructuralConcreteLayer: Array<StructuralConcreteLayer>;
  /** fetch aggregated fields from the table: "StructuralConcreteLayer" */
  StructuralConcreteLayer_aggregate: StructuralConcreteLayer_Aggregate;
  /** fetch data from the table: "StructuralConcreteLayer" using primary key columns */
  StructuralConcreteLayer_by_pk?: Maybe<StructuralConcreteLayer>;
  /** fetch data from the table: "StructureLayer" */
  StructureLayer: Array<StructureLayer>;
  /** fetch aggregated fields from the table: "StructureLayer" */
  StructureLayer_aggregate: StructureLayer_Aggregate;
  /** fetch data from the table: "StructureLayer" using primary key columns */
  StructureLayer_by_pk?: Maybe<StructureLayer>;
  /** fetch data from the table: "StructuredSystem" */
  StructuredSystem: Array<StructuredSystem>;
  /** An array relationship */
  StructuredSystemProducts: Array<StructuredSystemProducts>;
  /** An aggregate relationship */
  StructuredSystemProducts_aggregate: StructuredSystemProducts_Aggregate;
  /** fetch data from the table: "StructuredSystemProducts" using primary key columns */
  StructuredSystemProducts_by_pk?: Maybe<StructuredSystemProducts>;
  /** fetch aggregated fields from the table: "StructuredSystem" */
  StructuredSystem_aggregate: StructuredSystem_Aggregate;
  /** fetch data from the table: "StructuredSystem" using primary key columns */
  StructuredSystem_by_pk?: Maybe<StructuredSystem>;
  /** fetch data from the table: "Subassembly" */
  Subassembly: Array<Subassembly>;
  /** fetch data from the table: "SubassemblyDelistData" */
  SubassemblyDelistData: Array<SubassemblyDelistData>;
  /** fetch aggregated fields from the table: "SubassemblyDelistData" */
  SubassemblyDelistData_aggregate: SubassemblyDelistData_Aggregate;
  /** fetch data from the table: "SubassemblyE108Rating" */
  SubassemblyE108Rating: Array<SubassemblyE108Rating>;
  /** fetch aggregated fields from the table: "SubassemblyE108Rating" */
  SubassemblyE108Rating_aggregate: SubassemblyE108Rating_Aggregate;
  /** fetch data from the table: "SubassemblyE108Rating" using primary key columns */
  SubassemblyE108Rating_by_pk?: Maybe<SubassemblyE108Rating>;
  /** An array relationship */
  SubassemblyLayers: Array<SubassemblyLayers>;
  /** An aggregate relationship */
  SubassemblyLayers_aggregate: SubassemblyLayers_Aggregate;
  /** fetch data from the table: "SubassemblyLayers" using primary key columns */
  SubassemblyLayers_by_pk?: Maybe<SubassemblyLayers>;
  /** fetch data from the table: "SubassemblyMultiplyConfig" */
  SubassemblyMultiplyConfig: Array<SubassemblyMultiplyConfig>;
  /** fetch aggregated fields from the table: "SubassemblyMultiplyConfig" */
  SubassemblyMultiplyConfig_aggregate: SubassemblyMultiplyConfig_Aggregate;
  /** fetch data from the table: "SubassemblyMultiplyConfig" using primary key columns */
  SubassemblyMultiplyConfig_by_pk?: Maybe<SubassemblyMultiplyConfig>;
  /** fetch aggregated fields from the table: "Subassembly" */
  Subassembly_aggregate: Subassembly_Aggregate;
  /** fetch data from the table: "Subassembly" using primary key columns */
  Subassembly_by_pk?: Maybe<Subassembly>;
  /** fetch data from the table: "Subcat_Adhesive" */
  Subcat_Adhesive: Array<Subcat_Adhesive>;
  /** fetch aggregated fields from the table: "Subcat_Adhesive" */
  Subcat_Adhesive_aggregate: Subcat_Adhesive_Aggregate;
  /** fetch data from the table: "Subcat_AirRetarder" */
  Subcat_AirRetarder: Array<Subcat_AirRetarder>;
  /** fetch aggregated fields from the table: "Subcat_AirRetarder" */
  Subcat_AirRetarder_aggregate: Subcat_AirRetarder_Aggregate;
  /** fetch data from the table: "Subcat_BaseSheet" */
  Subcat_BaseSheet: Array<Subcat_BaseSheet>;
  /** fetch aggregated fields from the table: "Subcat_BaseSheet" */
  Subcat_BaseSheet_aggregate: Subcat_BaseSheet_Aggregate;
  /** fetch data from the table: "Subcat_BattBlanket" */
  Subcat_BattBlanket: Array<Subcat_BattBlanket>;
  /** fetch aggregated fields from the table: "Subcat_BattBlanket" */
  Subcat_BattBlanket_aggregate: Subcat_BattBlanket_Aggregate;
  /** fetch data from the table: "Subcat_BoardStock" */
  Subcat_BoardStock: Array<Subcat_BoardStock>;
  /** fetch aggregated fields from the table: "Subcat_BoardStock" */
  Subcat_BoardStock_aggregate: Subcat_BoardStock_Aggregate;
  /** fetch data from the table: "Subcat_Coating" */
  Subcat_Coating: Array<Subcat_Coating>;
  /** fetch aggregated fields from the table: "Subcat_Coating" */
  Subcat_Coating_aggregate: Subcat_Coating_Aggregate;
  /** fetch data from the table: "Subcat_CompositePanel" */
  Subcat_CompositePanel: Array<Subcat_CompositePanel>;
  /** fetch aggregated fields from the table: "Subcat_CompositePanel" */
  Subcat_CompositePanel_aggregate: Subcat_CompositePanel_Aggregate;
  /** fetch data from the table: "Subcat_CoverStrip" */
  Subcat_CoverStrip: Array<Subcat_CoverStrip>;
  /** fetch aggregated fields from the table: "Subcat_CoverStrip" */
  Subcat_CoverStrip_aggregate: Subcat_CoverStrip_Aggregate;
  /** fetch data from the table: "Subcat_ExpJoint" */
  Subcat_ExpJoint: Array<Subcat_ExpJoint>;
  /** fetch aggregated fields from the table: "Subcat_ExpJoint" */
  Subcat_ExpJoint_aggregate: Subcat_ExpJoint_Aggregate;
  /** fetch data from the table: "Subcat_Fastening" */
  Subcat_Fastening: Array<Subcat_Fastening>;
  /** fetch aggregated fields from the table: "Subcat_Fastening" */
  Subcat_Fastening_aggregate: Subcat_Fastening_Aggregate;
  /** fetch data from the table: "Subcat_FormDeck" */
  Subcat_FormDeck: Array<Subcat_FormDeck>;
  /** fetch aggregated fields from the table: "Subcat_FormDeck" */
  Subcat_FormDeck_aggregate: Subcat_FormDeck_Aggregate;
  /** fetch data from the table: "Subcat_Gutter" */
  Subcat_Gutter: Array<Subcat_Gutter>;
  /** fetch aggregated fields from the table: "Subcat_Gutter" */
  Subcat_Gutter_aggregate: Subcat_Gutter_Aggregate;
  /** fetch data from the table: "Subcat_Gypsum" */
  Subcat_Gypsum: Array<Subcat_Gypsum>;
  /** fetch aggregated fields from the table: "Subcat_Gypsum" */
  Subcat_Gypsum_aggregate: Subcat_Gypsum_Aggregate;
  /** fetch data from the table: "Subcat_LWIC" */
  Subcat_LWIC: Array<Subcat_Lwic>;
  /** fetch data from the table: "Subcat_LWICDeck" */
  Subcat_LWICDeck: Array<Subcat_LwicDeck>;
  /** fetch aggregated fields from the table: "Subcat_LWICDeck" */
  Subcat_LWICDeck_aggregate: Subcat_LwicDeck_Aggregate;
  /** fetch aggregated fields from the table: "Subcat_LWIC" */
  Subcat_LWIC_aggregate: Subcat_Lwic_Aggregate;
  /** fetch data from the table: "Subcat_LinerPanel" */
  Subcat_LinerPanel: Array<Subcat_LinerPanel>;
  /** fetch aggregated fields from the table: "Subcat_LinerPanel" */
  Subcat_LinerPanel_aggregate: Subcat_LinerPanel_Aggregate;
  /** fetch data from the table: "Subcat_LiquidApplied" */
  Subcat_LiquidApplied: Array<Subcat_LiquidApplied>;
  /** fetch aggregated fields from the table: "Subcat_LiquidApplied" */
  Subcat_LiquidApplied_aggregate: Subcat_LiquidApplied_Aggregate;
  /** fetch data from the table: "Subcat_MaintCoating" */
  Subcat_MaintCoating: Array<Subcat_MaintCoating>;
  /** fetch aggregated fields from the table: "Subcat_MaintCoating" */
  Subcat_MaintCoating_aggregate: Subcat_MaintCoating_Aggregate;
  /** fetch data from the table: "Subcat_Multiply" */
  Subcat_Multiply: Array<Subcat_Multiply>;
  /** fetch data from the table: "Subcat_MultiplyCover" */
  Subcat_MultiplyCover: Array<Subcat_MultiplyCover>;
  /** fetch aggregated fields from the table: "Subcat_MultiplyCover" */
  Subcat_MultiplyCover_aggregate: Subcat_MultiplyCover_Aggregate;
  /** fetch aggregated fields from the table: "Subcat_Multiply" */
  Subcat_Multiply_aggregate: Subcat_Multiply_Aggregate;
  /** fetch data from the table: "Subcat_PerimeterFlashing" */
  Subcat_PerimeterFlashing: Array<Subcat_PerimeterFlashing>;
  /** fetch aggregated fields from the table: "Subcat_PerimeterFlashing" */
  Subcat_PerimeterFlashing_aggregate: Subcat_PerimeterFlashing_Aggregate;
  /** fetch data from the table: "Subcat_PlasticDeck" */
  Subcat_PlasticDeck: Array<Subcat_PlasticDeck>;
  /** fetch aggregated fields from the table: "Subcat_PlasticDeck" */
  Subcat_PlasticDeck_aggregate: Subcat_PlasticDeck_Aggregate;
  /** fetch data from the table: "Subcat_Primer" */
  Subcat_Primer: Array<Subcat_Primer>;
  /** fetch aggregated fields from the table: "Subcat_Primer" */
  Subcat_Primer_aggregate: Subcat_Primer_Aggregate;
  /** fetch data from the table: "Subcat_Seaming" */
  Subcat_Seaming: Array<Subcat_Seaming>;
  /** fetch aggregated fields from the table: "Subcat_Seaming" */
  Subcat_Seaming_aggregate: Subcat_Seaming_Aggregate;
  /** fetch data from the table: "Subcat_SeparatorSheet" */
  Subcat_SeparatorSheet: Array<Subcat_SeparatorSheet>;
  /** fetch aggregated fields from the table: "Subcat_SeparatorSheet" */
  Subcat_SeparatorSheet_aggregate: Subcat_SeparatorSheet_Aggregate;
  /** fetch data from the table: "Subcat_Shingles" */
  Subcat_Shingles: Array<Subcat_Shingles>;
  /** fetch aggregated fields from the table: "Subcat_Shingles" */
  Subcat_Shingles_aggregate: Subcat_Shingles_Aggregate;
  /** fetch data from the table: "Subcat_SinglePly" */
  Subcat_SinglePly: Array<Subcat_SinglePly>;
  /** fetch data from the table: "Subcat_SinglePlyCover" */
  Subcat_SinglePlyCover: Array<Subcat_SinglePlyCover>;
  /** fetch aggregated fields from the table: "Subcat_SinglePlyCover" */
  Subcat_SinglePlyCover_aggregate: Subcat_SinglePlyCover_Aggregate;
  /** fetch aggregated fields from the table: "Subcat_SinglePly" */
  Subcat_SinglePly_aggregate: Subcat_SinglePly_Aggregate;
  /** fetch data from the table: "Subcat_SprayFoam" */
  Subcat_SprayFoam: Array<Subcat_SprayFoam>;
  /** fetch aggregated fields from the table: "Subcat_SprayFoam" */
  Subcat_SprayFoam_aggregate: Subcat_SprayFoam_Aggregate;
  /** fetch data from the table: "Subcat_StandingLapSeam" */
  Subcat_StandingLapSeam: Array<Subcat_StandingLapSeam>;
  /** fetch aggregated fields from the table: "Subcat_StandingLapSeam" */
  Subcat_StandingLapSeam_aggregate: Subcat_StandingLapSeam_Aggregate;
  /** fetch data from the table: "Subcat_SteelDeck" */
  Subcat_SteelDeck: Array<Subcat_SteelDeck>;
  /** fetch aggregated fields from the table: "Subcat_SteelDeck" */
  Subcat_SteelDeck_aggregate: Subcat_SteelDeck_Aggregate;
  /** fetch data from the table: "Subcat_Surfacing" */
  Subcat_Surfacing: Array<Subcat_Surfacing>;
  /** fetch aggregated fields from the table: "Subcat_Surfacing" */
  Subcat_Surfacing_aggregate: Subcat_Surfacing_Aggregate;
  /** fetch data from the table: "Subcat_VaporRetarder" */
  Subcat_VaporRetarder: Array<Subcat_VaporRetarder>;
  /** fetch aggregated fields from the table: "Subcat_VaporRetarder" */
  Subcat_VaporRetarder_aggregate: Subcat_VaporRetarder_Aggregate;
  /** fetch data from the table: "Subcat_Weld" */
  Subcat_Weld: Array<Subcat_Weld>;
  /** fetch aggregated fields from the table: "Subcat_Weld" */
  Subcat_Weld_aggregate: Subcat_Weld_Aggregate;
  /** fetch data from the table: "Subcat_Wood" */
  Subcat_Wood: Array<Subcat_Wood>;
  /** fetch data from the table: "Subcat_WoodFiberDeck" */
  Subcat_WoodFiberDeck: Array<Subcat_WoodFiberDeck>;
  /** fetch aggregated fields from the table: "Subcat_WoodFiberDeck" */
  Subcat_WoodFiberDeck_aggregate: Subcat_WoodFiberDeck_Aggregate;
  /** fetch aggregated fields from the table: "Subcat_Wood" */
  Subcat_Wood_aggregate: Subcat_Wood_Aggregate;
  /** fetch data from the table: "SubstratePrimer" */
  SubstratePrimer: Array<SubstratePrimer>;
  /** fetch data from the table: "SubstratePrimerUsage" */
  SubstratePrimerUsage: Array<SubstratePrimerUsage>;
  /** fetch aggregated fields from the table: "SubstratePrimerUsage" */
  SubstratePrimerUsage_aggregate: SubstratePrimerUsage_Aggregate;
  /** fetch data from the table: "SubstratePrimerUsage" using primary key columns */
  SubstratePrimerUsage_by_pk?: Maybe<SubstratePrimerUsage>;
  /** fetch aggregated fields from the table: "SubstratePrimer" */
  SubstratePrimer_aggregate: SubstratePrimer_Aggregate;
  /** fetch data from the table: "SubstratePrimer" using primary key columns */
  SubstratePrimer_by_pk?: Maybe<SubstratePrimer>;
  /** fetch data from the table: "Surfacing" */
  Surfacing: Array<Surfacing>;
  /** fetch data from the table: "SurfacingUsage" */
  SurfacingUsage: Array<SurfacingUsage>;
  /** fetch aggregated fields from the table: "SurfacingUsage" */
  SurfacingUsage_aggregate: SurfacingUsage_Aggregate;
  /** fetch data from the table: "SurfacingUsage" using primary key columns */
  SurfacingUsage_by_pk?: Maybe<SurfacingUsage>;
  /** fetch aggregated fields from the table: "Surfacing" */
  Surfacing_aggregate: Surfacing_Aggregate;
  /** fetch data from the table: "Surfacing" using primary key columns */
  Surfacing_by_pk?: Maybe<Surfacing>;
  /** fetch data from the table: "TapeWidth" */
  TapeWidth: Array<TapeWidth>;
  /** fetch aggregated fields from the table: "TapeWidth" */
  TapeWidth_aggregate: TapeWidth_Aggregate;
  /** fetch data from the table: "TapeWidth" using primary key columns */
  TapeWidth_by_pk?: Maybe<TapeWidth>;
  /** fetch data from the table: "ThermalBarrier" */
  ThermalBarrier: Array<ThermalBarrier>;
  /** An array relationship */
  ThermalBarrierBoardSizes: Array<ThermalBarrierBoardSizes>;
  /** An aggregate relationship */
  ThermalBarrierBoardSizes_aggregate: ThermalBarrierBoardSizes_Aggregate;
  /** fetch data from the table: "ThermalBarrierBoardSizes" using primary key columns */
  ThermalBarrierBoardSizes_by_pk?: Maybe<ThermalBarrierBoardSizes>;
  /** fetch data from the table: "ThermalBarrierFacerBottom" */
  ThermalBarrierFacerBottom: Array<ThermalBarrierFacerBottom>;
  /** fetch aggregated fields from the table: "ThermalBarrierFacerBottom" */
  ThermalBarrierFacerBottom_aggregate: ThermalBarrierFacerBottom_Aggregate;
  /** fetch data from the table: "ThermalBarrierFacerBottom" using primary key columns */
  ThermalBarrierFacerBottom_by_pk?: Maybe<ThermalBarrierFacerBottom>;
  /** fetch data from the table: "ThermalBarrierFacerTop" */
  ThermalBarrierFacerTop: Array<ThermalBarrierFacerTop>;
  /** fetch aggregated fields from the table: "ThermalBarrierFacerTop" */
  ThermalBarrierFacerTop_aggregate: ThermalBarrierFacerTop_Aggregate;
  /** fetch data from the table: "ThermalBarrierFacerTop" using primary key columns */
  ThermalBarrierFacerTop_by_pk?: Maybe<ThermalBarrierFacerTop>;
  /** fetch data from the table: "ThermalBarrierUsage" */
  ThermalBarrierUsage: Array<ThermalBarrierUsage>;
  /** fetch aggregated fields from the table: "ThermalBarrierUsage" */
  ThermalBarrierUsage_aggregate: ThermalBarrierUsage_Aggregate;
  /** fetch data from the table: "ThermalBarrierUsage" using primary key columns */
  ThermalBarrierUsage_by_pk?: Maybe<ThermalBarrierUsage>;
  /** fetch aggregated fields from the table: "ThermalBarrier" */
  ThermalBarrier_aggregate: ThermalBarrier_Aggregate;
  /** fetch data from the table: "ThermalBarrier" using primary key columns */
  ThermalBarrier_by_pk?: Maybe<ThermalBarrier>;
  /** fetch data from the table: "TopCoat" */
  TopCoat: Array<TopCoat>;
  /** fetch data from the table: "TopCoatUsage" */
  TopCoatUsage: Array<TopCoatUsage>;
  /** fetch aggregated fields from the table: "TopCoatUsage" */
  TopCoatUsage_aggregate: TopCoatUsage_Aggregate;
  /** fetch data from the table: "TopCoatUsage" using primary key columns */
  TopCoatUsage_by_pk?: Maybe<TopCoatUsage>;
  /** fetch aggregated fields from the table: "TopCoat" */
  TopCoat_aggregate: TopCoat_Aggregate;
  /** fetch data from the table: "TopCoat" using primary key columns */
  TopCoat_by_pk?: Maybe<TopCoat>;
  /** fetch data from the table: "Usage" */
  Usage: Array<Usage>;
  /** fetch data from the table: "UsageTradeNameView" */
  UsageTradeNameView: Array<UsageTradeNameView>;
  /** fetch aggregated fields from the table: "UsageTradeNameView" */
  UsageTradeNameView_aggregate: UsageTradeNameView_Aggregate;
  /** fetch aggregated fields from the table: "Usage" */
  Usage_aggregate: Usage_Aggregate;
  /** fetch data from the table: "Usage" using primary key columns */
  Usage_by_pk?: Maybe<Usage>;
  /** fetch data from the table: "UserReportBug" */
  UserReportBug: Array<UserReportBug>;
  /** fetch aggregated fields from the table: "UserReportBug" */
  UserReportBug_aggregate: UserReportBug_Aggregate;
  /** fetch data from the table: "UserReportBug" using primary key columns */
  UserReportBug_by_pk?: Maybe<UserReportBug>;
  /** fetch data from the table: "UserShareLink" */
  UserShareLink: Array<UserShareLink>;
  /** fetch aggregated fields from the table: "UserShareLink" */
  UserShareLink_aggregate: UserShareLink_Aggregate;
  /** fetch data from the table: "UserShareLink" using primary key columns */
  UserShareLink_by_pk?: Maybe<UserShareLink>;
  /** fetch data from the table: "VaporRetarder" */
  VaporRetarder: Array<VaporRetarder>;
  /** fetch data from the table: "VaporRetarderUsage" */
  VaporRetarderUsage: Array<VaporRetarderUsage>;
  /** fetch aggregated fields from the table: "VaporRetarderUsage" */
  VaporRetarderUsage_aggregate: VaporRetarderUsage_Aggregate;
  /** fetch data from the table: "VaporRetarderUsage" using primary key columns */
  VaporRetarderUsage_by_pk?: Maybe<VaporRetarderUsage>;
  /** fetch aggregated fields from the table: "VaporRetarder" */
  VaporRetarder_aggregate: VaporRetarder_Aggregate;
  /** fetch data from the table: "VaporRetarder" using primary key columns */
  VaporRetarder_by_pk?: Maybe<VaporRetarder>;
  /** fetch data from the table: "WeldArcUsage" */
  WeldArcUsage: Array<WeldArcUsage>;
  /** fetch aggregated fields from the table: "WeldArcUsage" */
  WeldArcUsage_aggregate: WeldArcUsage_Aggregate;
  /** fetch data from the table: "WeldArcUsage" using primary key columns */
  WeldArcUsage_by_pk?: Maybe<WeldArcUsage>;
  /** fetch data from the table: "WeldHeatUsage" */
  WeldHeatUsage: Array<WeldHeatUsage>;
  /** fetch aggregated fields from the table: "WeldHeatUsage" */
  WeldHeatUsage_aggregate: WeldHeatUsage_Aggregate;
  /** fetch data from the table: "WeldHeatUsage" using primary key columns */
  WeldHeatUsage_by_pk?: Maybe<WeldHeatUsage>;
  /** fetch data from the table: "WeldSolventUsage" */
  WeldSolventUsage: Array<WeldSolventUsage>;
  /** fetch aggregated fields from the table: "WeldSolventUsage" */
  WeldSolventUsage_aggregate: WeldSolventUsage_Aggregate;
  /** fetch data from the table: "WeldSolventUsage" using primary key columns */
  WeldSolventUsage_by_pk?: Maybe<WeldSolventUsage>;
  /** fetch data from the table: "WeldSystemUsage" */
  WeldSystemUsage: Array<WeldSystemUsage>;
  /** fetch aggregated fields from the table: "WeldSystemUsage" */
  WeldSystemUsage_aggregate: WeldSystemUsage_Aggregate;
  /** fetch data from the table: "WeldSystemUsage" using primary key columns */
  WeldSystemUsage_by_pk?: Maybe<WeldSystemUsage>;
  /** fetch data from the table: "WeldTorchedUsage" */
  WeldTorchedUsage: Array<WeldTorchedUsage>;
  /** fetch aggregated fields from the table: "WeldTorchedUsage" */
  WeldTorchedUsage_aggregate: WeldTorchedUsage_Aggregate;
  /** fetch data from the table: "WeldTorchedUsage" using primary key columns */
  WeldTorchedUsage_by_pk?: Maybe<WeldTorchedUsage>;
  /** fetch data from the table: "WoodDeck" */
  WoodDeck: Array<WoodDeck>;
  /** fetch data from the table: "WoodDeckUsage" */
  WoodDeckUsage: Array<WoodDeckUsage>;
  /** fetch aggregated fields from the table: "WoodDeckUsage" */
  WoodDeckUsage_aggregate: WoodDeckUsage_Aggregate;
  /** fetch data from the table: "WoodDeckUsage" using primary key columns */
  WoodDeckUsage_by_pk?: Maybe<WoodDeckUsage>;
  /** fetch aggregated fields from the table: "WoodDeck" */
  WoodDeck_aggregate: WoodDeck_Aggregate;
  /** fetch data from the table: "WoodDeck" using primary key columns */
  WoodDeck_by_pk?: Maybe<WoodDeck>;
  /** fetch data from the table: "vNavAssembly_Layers" */
  vNavAssembly_Layers: Array<VNavAssembly_Layers>;
  /** fetch aggregated fields from the table: "vNavAssembly_Layers" */
  vNavAssembly_Layers_aggregate: VNavAssembly_Layers_Aggregate;
  /** fetch data from the table: "vNavAssembly_ManufacturerOnlySearch" */
  vNavAssembly_ManufacturerOnlySearch: Array<VNavAssembly_ManufacturerOnlySearch>;
  /** fetch aggregated fields from the table: "vNavAssembly_ManufacturerOnlySearch" */
  vNavAssembly_ManufacturerOnlySearch_aggregate: VNavAssembly_ManufacturerOnlySearch_Aggregate;
  /** fetch data from the table: "vNavAssembly_RoofTypeCount" */
  vNavAssembly_RoofTypeCount: Array<VNavAssembly_RoofTypeCount>;
  /** fetch aggregated fields from the table: "vNavAssembly_RoofTypeCount" */
  vNavAssembly_RoofTypeCount_aggregate: VNavAssembly_RoofTypeCount_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search" */
  vNavAssembly_Search: Array<VNavAssembly_Search>;
  /** fetch data from the table: "vNavAssembly_Search_AirRetarder" */
  vNavAssembly_Search_AirRetarder: Array<VNavAssembly_Search_AirRetarder>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_AirRetarder" */
  vNavAssembly_Search_AirRetarder_aggregate: VNavAssembly_Search_AirRetarder_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_BaseCoat" */
  vNavAssembly_Search_BaseCoat: Array<VNavAssembly_Search_BaseCoat>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_BaseCoat" */
  vNavAssembly_Search_BaseCoat_aggregate: VNavAssembly_Search_BaseCoat_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_BasePly" */
  vNavAssembly_Search_BasePly: Array<VNavAssembly_Search_BasePly>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_BasePly" */
  vNavAssembly_Search_BasePly_aggregate: VNavAssembly_Search_BasePly_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_BaseSheet" */
  vNavAssembly_Search_BaseSheet: Array<VNavAssembly_Search_BaseSheet>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_BaseSheet" */
  vNavAssembly_Search_BaseSheet_aggregate: VNavAssembly_Search_BaseSheet_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_BoardStock" */
  vNavAssembly_Search_BoardStock: Array<VNavAssembly_Search_BoardStock>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_BoardStock" */
  vNavAssembly_Search_BoardStock_aggregate: VNavAssembly_Search_BoardStock_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_CapPly" */
  vNavAssembly_Search_CapPly: Array<VNavAssembly_Search_CapPly>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_CapPly" */
  vNavAssembly_Search_CapPly_aggregate: VNavAssembly_Search_CapPly_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_Coat" */
  vNavAssembly_Search_Coat: Array<VNavAssembly_Search_Coat>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_Coat" */
  vNavAssembly_Search_Coat_aggregate: VNavAssembly_Search_Coat_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_Coating" */
  vNavAssembly_Search_Coating: Array<VNavAssembly_Search_Coating>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_Coating" */
  vNavAssembly_Search_Coating_aggregate: VNavAssembly_Search_Coating_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_CoverBoard" */
  vNavAssembly_Search_CoverBoard: Array<VNavAssembly_Search_CoverBoard>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_CoverBoard" */
  vNavAssembly_Search_CoverBoard_aggregate: VNavAssembly_Search_CoverBoard_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_CoverCompositePanel" */
  vNavAssembly_Search_CoverCompositePanel: Array<VNavAssembly_Search_CoverCompositePanel>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_CoverCompositePanel" */
  vNavAssembly_Search_CoverCompositePanel_aggregate: VNavAssembly_Search_CoverCompositePanel_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_CoverSingleply" */
  vNavAssembly_Search_CoverSingleply: Array<VNavAssembly_Search_CoverSingleply>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_CoverSingleply" */
  vNavAssembly_Search_CoverSingleply_aggregate: VNavAssembly_Search_CoverSingleply_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_CoverStandingLapSeam" */
  vNavAssembly_Search_CoverStandingLapSeam: Array<VNavAssembly_Search_CoverStandingLapSeam>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_CoverStandingLapSeam" */
  vNavAssembly_Search_CoverStandingLapSeam_aggregate: VNavAssembly_Search_CoverStandingLapSeam_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_CoverStripDisk" */
  vNavAssembly_Search_CoverStripDisk: Array<VNavAssembly_Search_CoverStripDisk>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_CoverStripDisk" */
  vNavAssembly_Search_CoverStripDisk_aggregate: VNavAssembly_Search_CoverStripDisk_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_DeckCementitiousPanel" */
  vNavAssembly_Search_DeckCementitiousPanel: Array<VNavAssembly_Search_DeckCementitiousPanel>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_DeckCementitiousPanel" */
  vNavAssembly_Search_DeckCementitiousPanel_aggregate: VNavAssembly_Search_DeckCementitiousPanel_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_DeckSteel" */
  vNavAssembly_Search_DeckSteel: Array<VNavAssembly_Search_DeckSteel>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_DeckSteel" */
  vNavAssembly_Search_DeckSteel_aggregate: VNavAssembly_Search_DeckSteel_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_FormDeck" */
  vNavAssembly_Search_FormDeck: Array<VNavAssembly_Search_FormDeck>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_FormDeck" */
  vNavAssembly_Search_FormDeck_aggregate: VNavAssembly_Search_FormDeck_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_InsulationBattBlanket" */
  vNavAssembly_Search_InsulationBattBlanket: Array<VNavAssembly_Search_InsulationBattBlanket>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_InsulationBattBlanket" */
  vNavAssembly_Search_InsulationBattBlanket_aggregate: VNavAssembly_Search_InsulationBattBlanket_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_InsulationSprayFoam" */
  vNavAssembly_Search_InsulationSprayFoam: Array<VNavAssembly_Search_InsulationSprayFoam>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_InsulationSprayFoam" */
  vNavAssembly_Search_InsulationSprayFoam_aggregate: VNavAssembly_Search_InsulationSprayFoam_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_LightweightInsulatingConcrete" */
  vNavAssembly_Search_LightweightInsulatingConcrete: Array<VNavAssembly_Search_LightweightInsulatingConcrete>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_LightweightInsulatingConcrete" */
  vNavAssembly_Search_LightweightInsulatingConcrete_aggregate: VNavAssembly_Search_LightweightInsulatingConcrete_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_LinerPanel" */
  vNavAssembly_Search_LinerPanel: Array<VNavAssembly_Search_LinerPanel>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_LinerPanel" */
  vNavAssembly_Search_LinerPanel_aggregate: VNavAssembly_Search_LinerPanel_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_Ply" */
  vNavAssembly_Search_Ply: Array<VNavAssembly_Search_Ply>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_Ply" */
  vNavAssembly_Search_Ply_aggregate: VNavAssembly_Search_Ply_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_SeparatorSheet" */
  vNavAssembly_Search_SeparatorSheet: Array<VNavAssembly_Search_SeparatorSheet>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_SeparatorSheet" */
  vNavAssembly_Search_SeparatorSheet_aggregate: VNavAssembly_Search_SeparatorSheet_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_SubstratePrimer" */
  vNavAssembly_Search_SubstratePrimer: Array<VNavAssembly_Search_SubstratePrimer>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_SubstratePrimer" */
  vNavAssembly_Search_SubstratePrimer_aggregate: VNavAssembly_Search_SubstratePrimer_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_Surfacing" */
  vNavAssembly_Search_Surfacing: Array<VNavAssembly_Search_Surfacing>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_Surfacing" */
  vNavAssembly_Search_Surfacing_aggregate: VNavAssembly_Search_Surfacing_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_ThermalBarrier" */
  vNavAssembly_Search_ThermalBarrier: Array<VNavAssembly_Search_ThermalBarrier>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_ThermalBarrier" */
  vNavAssembly_Search_ThermalBarrier_aggregate: VNavAssembly_Search_ThermalBarrier_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_TopCoat" */
  vNavAssembly_Search_TopCoat: Array<VNavAssembly_Search_TopCoat>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_TopCoat" */
  vNavAssembly_Search_TopCoat_aggregate: VNavAssembly_Search_TopCoat_Aggregate;
  /** fetch data from the table: "vNavAssembly_Search_VaporRetarder" */
  vNavAssembly_Search_VaporRetarder: Array<VNavAssembly_Search_VaporRetarder>;
  /** fetch aggregated fields from the table: "vNavAssembly_Search_VaporRetarder" */
  vNavAssembly_Search_VaporRetarder_aggregate: VNavAssembly_Search_VaporRetarder_Aggregate;
  /** fetch aggregated fields from the table: "vNavAssembly_Search" */
  vNavAssembly_Search_aggregate: VNavAssembly_Search_Aggregate;
  /** fetch data from the table: "vNavAssembly_Usages" */
  vNavAssembly_Usages: Array<VNavAssembly_Usages>;
  /** fetch aggregated fields from the table: "vNavAssembly_Usages" */
  vNavAssembly_Usages_aggregate: VNavAssembly_Usages_Aggregate;
};


export type Subscription_RootAddressArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_By_PkArgs = {
  AddressId: Scalars['Int'];
};


export type Subscription_RootAdheredSinglePlyOrphanReportArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdheredSinglePlyOrphanReport_Order_By>>;
  where?: Maybe<AdheredSinglePlyOrphanReport_Bool_Exp>;
};


export type Subscription_RootAdheredSinglePlyOrphanReport_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdheredSinglePlyOrphanReport_Order_By>>;
  where?: Maybe<AdheredSinglePlyOrphanReport_Bool_Exp>;
};


export type Subscription_RootAdheredSinglePlyOrphanReport_By_PkArgs = {
  AdheredSinglePlyOrphansId: Scalars['Int'];
};


export type Subscription_RootAdhesiveFullApplicationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveFullApplication_Order_By>>;
  where?: Maybe<AdhesiveFullApplication_Bool_Exp>;
};


export type Subscription_RootAdhesiveFullApplicationUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveFullApplicationUsage_Order_By>>;
  where?: Maybe<AdhesiveFullApplicationUsage_Bool_Exp>;
};


export type Subscription_RootAdhesiveFullApplicationUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveFullApplicationUsage_Order_By>>;
  where?: Maybe<AdhesiveFullApplicationUsage_Bool_Exp>;
};


export type Subscription_RootAdhesiveFullApplicationUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootAdhesiveFullApplication_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveFullApplication_Order_By>>;
  where?: Maybe<AdhesiveFullApplication_Bool_Exp>;
};


export type Subscription_RootAdhesiveFullApplication_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootAdhesiveRibbonUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveRibbonUsage_Order_By>>;
  where?: Maybe<AdhesiveRibbonUsage_Bool_Exp>;
};


export type Subscription_RootAdhesiveRibbonUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveRibbonUsage_Order_By>>;
  where?: Maybe<AdhesiveRibbonUsage_Bool_Exp>;
};


export type Subscription_RootAdhesiveRibbonUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootAdhesiveRibbonsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveRibbons_Order_By>>;
  where?: Maybe<AdhesiveRibbons_Bool_Exp>;
};


export type Subscription_RootAdhesiveRibbons_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveRibbons_Order_By>>;
  where?: Maybe<AdhesiveRibbons_Bool_Exp>;
};


export type Subscription_RootAdhesiveRibbons_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootAdhesiveSpotsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSpots_Order_By>>;
  where?: Maybe<AdhesiveSpots_Bool_Exp>;
};


export type Subscription_RootAdhesiveSpotsUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSpotsUsage_Order_By>>;
  where?: Maybe<AdhesiveSpotsUsage_Bool_Exp>;
};


export type Subscription_RootAdhesiveSpotsUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSpotsUsage_Order_By>>;
  where?: Maybe<AdhesiveSpotsUsage_Bool_Exp>;
};


export type Subscription_RootAdhesiveSpotsUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootAdhesiveSpots_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSpots_Order_By>>;
  where?: Maybe<AdhesiveSpots_Bool_Exp>;
};


export type Subscription_RootAdhesiveSpots_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootAdhesiveSystemUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSystemUsage_Order_By>>;
  where?: Maybe<AdhesiveSystemUsage_Bool_Exp>;
};


export type Subscription_RootAdhesiveSystemUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AdhesiveSystemUsage_Order_By>>;
  where?: Maybe<AdhesiveSystemUsage_Bool_Exp>;
};


export type Subscription_RootAdhesiveSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootAirRetarderArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AirRetarder_Order_By>>;
  where?: Maybe<AirRetarder_Bool_Exp>;
};


export type Subscription_RootAirRetarderUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AirRetarderUsage_Order_By>>;
  where?: Maybe<AirRetarderUsage_Bool_Exp>;
};


export type Subscription_RootAirRetarderUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AirRetarderUsage_Order_By>>;
  where?: Maybe<AirRetarderUsage_Bool_Exp>;
};


export type Subscription_RootAirRetarderUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootAirRetarder_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AirRetarder_Order_By>>;
  where?: Maybe<AirRetarder_Bool_Exp>;
};


export type Subscription_RootAirRetarder_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootAllManufacturedProductsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AllManufacturedProducts_Order_By>>;
  where?: Maybe<AllManufacturedProducts_Bool_Exp>;
};


export type Subscription_RootAllManufacturedProducts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AllManufacturedProducts_Order_By>>;
  where?: Maybe<AllManufacturedProducts_Bool_Exp>;
};


export type Subscription_RootAnchorArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Anchor_Order_By>>;
  where?: Maybe<Anchor_Bool_Exp>;
};


export type Subscription_RootAnchor_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Anchor_Order_By>>;
  where?: Maybe<Anchor_Bool_Exp>;
};


export type Subscription_RootAnchor_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootApprovalsDocArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ApprovalsDoc_Order_By>>;
  where?: Maybe<ApprovalsDoc_Bool_Exp>;
};


export type Subscription_RootApprovalsDoc_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ApprovalsDoc_Order_By>>;
  where?: Maybe<ApprovalsDoc_Bool_Exp>;
};


export type Subscription_RootApprovalsDoc_By_PkArgs = {
  ApprovalsDocId: Scalars['Int'];
};


export type Subscription_RootApprovedLayerUseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ApprovedLayerUse_Order_By>>;
  where?: Maybe<ApprovedLayerUse_Bool_Exp>;
};


export type Subscription_RootApprovedLayerUse_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ApprovedLayerUse_Order_By>>;
  where?: Maybe<ApprovedLayerUse_Bool_Exp>;
};


export type Subscription_RootApprovedLayerUse_By_PkArgs = {
  ApprovedLayerUseId: Scalars['Int'];
};


export type Subscription_RootAssemblyReconfigQueueArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AssemblyReconfigQueue_Order_By>>;
  where?: Maybe<AssemblyReconfigQueue_Bool_Exp>;
};


export type Subscription_RootAssemblyReconfigQueue_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AssemblyReconfigQueue_Order_By>>;
  where?: Maybe<AssemblyReconfigQueue_Bool_Exp>;
};


export type Subscription_RootAssemblyReconfigQueue_By_PkArgs = {
  AssemblyReconfigQueueId: Scalars['Int'];
};


export type Subscription_RootBaseCoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseCoat_Order_By>>;
  where?: Maybe<BaseCoat_Bool_Exp>;
};


export type Subscription_RootBaseCoatUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseCoatUsage_Order_By>>;
  where?: Maybe<BaseCoatUsage_Bool_Exp>;
};


export type Subscription_RootBaseCoatUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseCoatUsage_Order_By>>;
  where?: Maybe<BaseCoatUsage_Bool_Exp>;
};


export type Subscription_RootBaseCoatUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootBaseCoat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseCoat_Order_By>>;
  where?: Maybe<BaseCoat_Bool_Exp>;
};


export type Subscription_RootBaseCoat_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootBasePlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BasePly_Order_By>>;
  where?: Maybe<BasePly_Bool_Exp>;
};


export type Subscription_RootBasePlyUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BasePlyUsage_Order_By>>;
  where?: Maybe<BasePlyUsage_Bool_Exp>;
};


export type Subscription_RootBasePlyUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BasePlyUsage_Order_By>>;
  where?: Maybe<BasePlyUsage_Bool_Exp>;
};


export type Subscription_RootBasePlyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootBasePly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BasePly_Order_By>>;
  where?: Maybe<BasePly_Bool_Exp>;
};


export type Subscription_RootBasePly_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootBaseSheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseSheet_Order_By>>;
  where?: Maybe<BaseSheet_Bool_Exp>;
};


export type Subscription_RootBaseSheetUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseSheetUsage_Order_By>>;
  where?: Maybe<BaseSheetUsage_Bool_Exp>;
};


export type Subscription_RootBaseSheetUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseSheetUsage_Order_By>>;
  where?: Maybe<BaseSheetUsage_Bool_Exp>;
};


export type Subscription_RootBaseSheetUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootBaseSheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BaseSheet_Order_By>>;
  where?: Maybe<BaseSheet_Bool_Exp>;
};


export type Subscription_RootBaseSheet_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootBattBlanketInsulationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattBlanketInsulation_Order_By>>;
  where?: Maybe<BattBlanketInsulation_Bool_Exp>;
};


export type Subscription_RootBattBlanketInsulation_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattBlanketInsulation_Order_By>>;
  where?: Maybe<BattBlanketInsulation_Bool_Exp>;
};


export type Subscription_RootBattBlanketInsulation_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootBattBlanketUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattBlanketUsage_Order_By>>;
  where?: Maybe<BattBlanketUsage_Bool_Exp>;
};


export type Subscription_RootBattBlanketUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattBlanketUsage_Order_By>>;
  where?: Maybe<BattBlanketUsage_Bool_Exp>;
};


export type Subscription_RootBattBlanketUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootBattenBarArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattenBar_Order_By>>;
  where?: Maybe<BattenBar_Bool_Exp>;
};


export type Subscription_RootBattenBarUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattenBarUsage_Order_By>>;
  where?: Maybe<BattenBarUsage_Bool_Exp>;
};


export type Subscription_RootBattenBarUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattenBarUsage_Order_By>>;
  where?: Maybe<BattenBarUsage_Bool_Exp>;
};


export type Subscription_RootBattenBarUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootBattenBar_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BattenBar_Order_By>>;
  where?: Maybe<BattenBar_Bool_Exp>;
};


export type Subscription_RootBattenBar_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootBoardSizesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardSizes_Order_By>>;
  where?: Maybe<BoardSizes_Bool_Exp>;
};


export type Subscription_RootBoardSizes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardSizes_Order_By>>;
  where?: Maybe<BoardSizes_Bool_Exp>;
};


export type Subscription_RootBoardStockArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStock_Order_By>>;
  where?: Maybe<BoardStock_Bool_Exp>;
};


export type Subscription_RootBoardStockBoardSizesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockBoardSizes_Order_By>>;
  where?: Maybe<BoardStockBoardSizes_Bool_Exp>;
};


export type Subscription_RootBoardStockBoardSizes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockBoardSizes_Order_By>>;
  where?: Maybe<BoardStockBoardSizes_Bool_Exp>;
};


export type Subscription_RootBoardStockBoardSizes_By_PkArgs = {
  ComponentId: Scalars['Int'];
  InsBoardSizeId: Scalars['Int'];
};


export type Subscription_RootBoardStockFacerBottomArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerBottom_Order_By>>;
  where?: Maybe<BoardStockFacerBottom_Bool_Exp>;
};


export type Subscription_RootBoardStockFacerBottom_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerBottom_Order_By>>;
  where?: Maybe<BoardStockFacerBottom_Bool_Exp>;
};


export type Subscription_RootBoardStockFacerBottom_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


export type Subscription_RootBoardStockFacerTopArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerTop_Order_By>>;
  where?: Maybe<BoardStockFacerTop_Bool_Exp>;
};


export type Subscription_RootBoardStockFacerTop_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockFacerTop_Order_By>>;
  where?: Maybe<BoardStockFacerTop_Bool_Exp>;
};


export type Subscription_RootBoardStockFacerTop_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


export type Subscription_RootBoardStockUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockUsage_Order_By>>;
  where?: Maybe<BoardStockUsage_Bool_Exp>;
};


export type Subscription_RootBoardStockUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStockUsage_Order_By>>;
  where?: Maybe<BoardStockUsage_Bool_Exp>;
};


export type Subscription_RootBoardStockUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootBoardStock_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BoardStock_Order_By>>;
  where?: Maybe<BoardStock_Bool_Exp>;
};


export type Subscription_RootBoardStock_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootBroadcastAlertArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BroadcastAlert_Order_By>>;
  where?: Maybe<BroadcastAlert_Bool_Exp>;
};


export type Subscription_RootBroadcastAlert_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BroadcastAlert_Order_By>>;
  where?: Maybe<BroadcastAlert_Bool_Exp>;
};


export type Subscription_RootBroadcastAlert_By_PkArgs = {
  BroadcastAlertId: Scalars['Int'];
};


export type Subscription_RootBuiltupVaporRetarderSystemUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BuiltupVaporRetarderSystemUsage_Order_By>>;
  where?: Maybe<BuiltupVaporRetarderSystemUsage_Bool_Exp>;
};


export type Subscription_RootBuiltupVaporRetarderSystemUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BuiltupVaporRetarderSystemUsage_Order_By>>;
  where?: Maybe<BuiltupVaporRetarderSystemUsage_Bool_Exp>;
};


export type Subscription_RootBuiltupVaporRetarderSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootC_AdditiveArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Additive_Order_By>>;
  where?: Maybe<C_Additive_Bool_Exp>;
};


export type Subscription_RootC_Additive_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Additive_Order_By>>;
  where?: Maybe<C_Additive_Bool_Exp>;
};


export type Subscription_RootC_Additive_By_PkArgs = {
  AdditiveId: Scalars['Int'];
};


export type Subscription_RootC_AdhesiveApplicationMethodArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AdhesiveApplicationMethod_Order_By>>;
  where?: Maybe<C_AdhesiveApplicationMethod_Bool_Exp>;
};


export type Subscription_RootC_AdhesiveApplicationMethod_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AdhesiveApplicationMethod_Order_By>>;
  where?: Maybe<C_AdhesiveApplicationMethod_Bool_Exp>;
};


export type Subscription_RootC_AdhesiveApplicationMethod_By_PkArgs = {
  AdhesiveApplicationMethodId: Scalars['Int'];
};


export type Subscription_RootC_AdhesiveMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AdhesiveMaterial_Order_By>>;
  where?: Maybe<C_AdhesiveMaterial_Bool_Exp>;
};


export type Subscription_RootC_AdhesiveMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AdhesiveMaterial_Order_By>>;
  where?: Maybe<C_AdhesiveMaterial_Bool_Exp>;
};


export type Subscription_RootC_AdhesiveMaterial_By_PkArgs = {
  AdhesiveMaterialId: Scalars['Int'];
};


export type Subscription_RootC_AdhesiveTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AdhesiveType_Order_By>>;
  where?: Maybe<C_AdhesiveType_Bool_Exp>;
};


export type Subscription_RootC_AdhesiveType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AdhesiveType_Order_By>>;
  where?: Maybe<C_AdhesiveType_Bool_Exp>;
};


export type Subscription_RootC_AdhesiveType_By_PkArgs = {
  AdhesiveTypeId: Scalars['Int'];
};


export type Subscription_RootC_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Aggregate_Order_By>>;
  where?: Maybe<C_Aggregate_Bool_Exp>;
};


export type Subscription_RootC_Aggregate_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Aggregate_Order_By>>;
  where?: Maybe<C_Aggregate_Bool_Exp>;
};


export type Subscription_RootC_Aggregate_By_PkArgs = {
  AggregateId: Scalars['Int'];
};


export type Subscription_RootC_ApprovalsDocCategoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ApprovalsDocCategory_Order_By>>;
  where?: Maybe<C_ApprovalsDocCategory_Bool_Exp>;
};


export type Subscription_RootC_ApprovalsDocCategory_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ApprovalsDocCategory_Order_By>>;
  where?: Maybe<C_ApprovalsDocCategory_Bool_Exp>;
};


export type Subscription_RootC_ApprovalsDocCategory_By_PkArgs = {
  ApprovalsDocCategoryId: Scalars['Int'];
};


export type Subscription_RootC_ApprovedUseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ApprovedUse_Order_By>>;
  where?: Maybe<C_ApprovedUse_Bool_Exp>;
};


export type Subscription_RootC_ApprovedUse_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ApprovedUse_Order_By>>;
  where?: Maybe<C_ApprovedUse_Bool_Exp>;
};


export type Subscription_RootC_ApprovedUse_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
};


export type Subscription_RootC_AssemblyApplicationTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AssemblyApplicationType_Order_By>>;
  where?: Maybe<C_AssemblyApplicationType_Bool_Exp>;
};


export type Subscription_RootC_AssemblyApplicationType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AssemblyApplicationType_Order_By>>;
  where?: Maybe<C_AssemblyApplicationType_Bool_Exp>;
};


export type Subscription_RootC_AssemblyApplicationType_By_PkArgs = {
  AssemblyApplicationTypeId: Scalars['Int'];
};


export type Subscription_RootC_AssemblyTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AssemblyType_Order_By>>;
  where?: Maybe<C_AssemblyType_Bool_Exp>;
};


export type Subscription_RootC_AssemblyType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_AssemblyType_Order_By>>;
  where?: Maybe<C_AssemblyType_Bool_Exp>;
};


export type Subscription_RootC_AssemblyType_By_PkArgs = {
  AssemblyTypeId: Scalars['Int'];
};


export type Subscription_RootC_BackingMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BackingMaterial_Order_By>>;
  where?: Maybe<C_BackingMaterial_Bool_Exp>;
};


export type Subscription_RootC_BackingMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BackingMaterial_Order_By>>;
  where?: Maybe<C_BackingMaterial_Bool_Exp>;
};


export type Subscription_RootC_BackingMaterial_By_PkArgs = {
  BackingMaterialId: Scalars['Int'];
};


export type Subscription_RootC_BaseSheetApplicationMethodArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BaseSheetApplicationMethod_Order_By>>;
  where?: Maybe<C_BaseSheetApplicationMethod_Bool_Exp>;
};


export type Subscription_RootC_BaseSheetApplicationMethod_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BaseSheetApplicationMethod_Order_By>>;
  where?: Maybe<C_BaseSheetApplicationMethod_Bool_Exp>;
};


export type Subscription_RootC_BaseSheetApplicationMethod_By_PkArgs = {
  BaseSheetApplicationMethodId: Scalars['Int'];
};


export type Subscription_RootC_BattBlanketFacerMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BattBlanketFacerMaterial_Order_By>>;
  where?: Maybe<C_BattBlanketFacerMaterial_Bool_Exp>;
};


export type Subscription_RootC_BattBlanketFacerMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BattBlanketFacerMaterial_Order_By>>;
  where?: Maybe<C_BattBlanketFacerMaterial_Bool_Exp>;
};


export type Subscription_RootC_BattBlanketFacerMaterial_By_PkArgs = {
  BattBlanketFacerMaterialId: Scalars['Int'];
};


export type Subscription_RootC_BattBlanketMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BattBlanketMaterial_Order_By>>;
  where?: Maybe<C_BattBlanketMaterial_Bool_Exp>;
};


export type Subscription_RootC_BattBlanketMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BattBlanketMaterial_Order_By>>;
  where?: Maybe<C_BattBlanketMaterial_Bool_Exp>;
};


export type Subscription_RootC_BattBlanketMaterial_By_PkArgs = {
  BattBlanketMaterialId: Scalars['Int'];
};


export type Subscription_RootC_BoardProfileArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BoardProfile_Order_By>>;
  where?: Maybe<C_BoardProfile_Bool_Exp>;
};


export type Subscription_RootC_BoardProfile_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BoardProfile_Order_By>>;
  where?: Maybe<C_BoardProfile_Bool_Exp>;
};


export type Subscription_RootC_BoardProfile_By_PkArgs = {
  BoardProfileId: Scalars['Int'];
};


export type Subscription_RootC_BoardStockMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BoardStockMaterial_Order_By>>;
  where?: Maybe<C_BoardStockMaterial_Bool_Exp>;
};


export type Subscription_RootC_BoardStockMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BoardStockMaterial_Order_By>>;
  where?: Maybe<C_BoardStockMaterial_Bool_Exp>;
};


export type Subscription_RootC_BoardStockMaterial_By_PkArgs = {
  BoardStockMaterialId: Scalars['Int'];
};


export type Subscription_RootC_BuildingEnclosureArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BuildingEnclosure_Order_By>>;
  where?: Maybe<C_BuildingEnclosure_Bool_Exp>;
};


export type Subscription_RootC_BuildingEnclosure_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_BuildingEnclosure_Order_By>>;
  where?: Maybe<C_BuildingEnclosure_Bool_Exp>;
};


export type Subscription_RootC_BuildingEnclosure_By_PkArgs = {
  BuildingEnclosureId: Scalars['Int'];
};


export type Subscription_RootC_CementAggregateRatioArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CementAggregateRatio_Order_By>>;
  where?: Maybe<C_CementAggregateRatio_Bool_Exp>;
};


export type Subscription_RootC_CementAggregateRatio_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CementAggregateRatio_Order_By>>;
  where?: Maybe<C_CementAggregateRatio_Bool_Exp>;
};


export type Subscription_RootC_CementAggregateRatio_By_PkArgs = {
  CementAggregateRatioId: Scalars['Int'];
};


export type Subscription_RootC_CoatingMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoatingMaterial_Order_By>>;
  where?: Maybe<C_CoatingMaterial_Bool_Exp>;
};


export type Subscription_RootC_CoatingMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoatingMaterial_Order_By>>;
  where?: Maybe<C_CoatingMaterial_Bool_Exp>;
};


export type Subscription_RootC_CoatingMaterial_By_PkArgs = {
  CoatingMaterialId: Scalars['Int'];
};


export type Subscription_RootC_ComponentTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ComponentType_Order_By>>;
  where?: Maybe<C_ComponentType_Bool_Exp>;
};


export type Subscription_RootC_ComponentType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ComponentType_Order_By>>;
  where?: Maybe<C_ComponentType_Bool_Exp>;
};


export type Subscription_RootC_ComponentType_By_PkArgs = {
  ComponentTypeId: Scalars['Int'];
};


export type Subscription_RootC_ContactTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ContactType_Order_By>>;
  where?: Maybe<C_ContactType_Bool_Exp>;
};


export type Subscription_RootC_ContactType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ContactType_Order_By>>;
  where?: Maybe<C_ContactType_Bool_Exp>;
};


export type Subscription_RootC_ContactType_By_PkArgs = {
  ContactTypeId: Scalars['Int'];
};


export type Subscription_RootC_CountryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Country_Order_By>>;
  where?: Maybe<C_Country_Bool_Exp>;
};


export type Subscription_RootC_Country_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Country_Order_By>>;
  where?: Maybe<C_Country_Bool_Exp>;
};


export type Subscription_RootC_Country_By_PkArgs = {
  CountryId: Scalars['Int'];
};


export type Subscription_RootC_CoverBoardMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoverBoardMaterial_Order_By>>;
  where?: Maybe<C_CoverBoardMaterial_Bool_Exp>;
};


export type Subscription_RootC_CoverBoardMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoverBoardMaterial_Order_By>>;
  where?: Maybe<C_CoverBoardMaterial_Bool_Exp>;
};


export type Subscription_RootC_CoverBoardMaterial_By_PkArgs = {
  CoverBoardMaterialId: Scalars['Int'];
};


export type Subscription_RootC_CoverColorArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoverColor_Order_By>>;
  where?: Maybe<C_CoverColor_Bool_Exp>;
};


export type Subscription_RootC_CoverColor_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoverColor_Order_By>>;
  where?: Maybe<C_CoverColor_Bool_Exp>;
};


export type Subscription_RootC_CoverColor_By_PkArgs = {
  CoverColorId: Scalars['Int'];
};


export type Subscription_RootC_CoverSecurementMethodArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoverSecurementMethod_Order_By>>;
  where?: Maybe<C_CoverSecurementMethod_Bool_Exp>;
};


export type Subscription_RootC_CoverSecurementMethod_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_CoverSecurementMethod_Order_By>>;
  where?: Maybe<C_CoverSecurementMethod_Bool_Exp>;
};


export type Subscription_RootC_CoverSecurementMethod_By_PkArgs = {
  CoverSecurementMethodId: Scalars['Int'];
};


export type Subscription_RootC_DeckTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_DeckType_Order_By>>;
  where?: Maybe<C_DeckType_Bool_Exp>;
};


export type Subscription_RootC_DeckType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_DeckType_Order_By>>;
  where?: Maybe<C_DeckType_Bool_Exp>;
};


export type Subscription_RootC_DeckType_By_PkArgs = {
  DeckTypeId: Scalars['Int'];
};


export type Subscription_RootC_ExistingRoofCoverArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofCover_Order_By>>;
  where?: Maybe<C_ExistingRoofCover_Bool_Exp>;
};


export type Subscription_RootC_ExistingRoofCover_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofCover_Order_By>>;
  where?: Maybe<C_ExistingRoofCover_Bool_Exp>;
};


export type Subscription_RootC_ExistingRoofCover_By_PkArgs = {
  ExistingRoofCoverId: Scalars['Int'];
};


export type Subscription_RootC_ExistingRoofDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofDeck_Order_By>>;
  where?: Maybe<C_ExistingRoofDeck_Bool_Exp>;
};


export type Subscription_RootC_ExistingRoofDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofDeck_Order_By>>;
  where?: Maybe<C_ExistingRoofDeck_Bool_Exp>;
};


export type Subscription_RootC_ExistingRoofDeck_By_PkArgs = {
  ExistingRoofDeckId: Scalars['Int'];
};


export type Subscription_RootC_ExistingRoofInsulationMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofInsulationMaterial_Order_By>>;
  where?: Maybe<C_ExistingRoofInsulationMaterial_Bool_Exp>;
};


export type Subscription_RootC_ExistingRoofInsulationMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofInsulationMaterial_Order_By>>;
  where?: Maybe<C_ExistingRoofInsulationMaterial_Bool_Exp>;
};


export type Subscription_RootC_ExistingRoofInsulationMaterial_By_PkArgs = {
  ExistingRoofInsulationMaterialId: Scalars['Int'];
};


export type Subscription_RootC_ExistingRoofSinglePlyMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofSinglePlyMaterial_Order_By>>;
  where?: Maybe<C_ExistingRoofSinglePlyMaterial_Bool_Exp>;
};


export type Subscription_RootC_ExistingRoofSinglePlyMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofSinglePlyMaterial_Order_By>>;
  where?: Maybe<C_ExistingRoofSinglePlyMaterial_Bool_Exp>;
};


export type Subscription_RootC_ExistingRoofSinglePlyMaterial_By_PkArgs = {
  ExistingRoofSinglePlyMaterialId: Scalars['Int'];
};


export type Subscription_RootC_ExistingRoofSurfaceArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofSurface_Order_By>>;
  where?: Maybe<C_ExistingRoofSurface_Bool_Exp>;
};


export type Subscription_RootC_ExistingRoofSurface_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExistingRoofSurface_Order_By>>;
  where?: Maybe<C_ExistingRoofSurface_Bool_Exp>;
};


export type Subscription_RootC_ExistingRoofSurface_By_PkArgs = {
  ExistingRoofSurfaceId: Scalars['Int'];
};


export type Subscription_RootC_ExtFireRatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExtFireRating_Order_By>>;
  where?: Maybe<C_ExtFireRating_Bool_Exp>;
};


export type Subscription_RootC_ExtFireRating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ExtFireRating_Order_By>>;
  where?: Maybe<C_ExtFireRating_Bool_Exp>;
};


export type Subscription_RootC_ExtFireRating_By_PkArgs = {
  ExtFireRatingId: Scalars['Int'];
};


export type Subscription_RootC_FacerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Facer_Order_By>>;
  where?: Maybe<C_Facer_Bool_Exp>;
};


export type Subscription_RootC_FacerMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FacerMaterial_Order_By>>;
  where?: Maybe<C_FacerMaterial_Bool_Exp>;
};


export type Subscription_RootC_FacerMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FacerMaterial_Order_By>>;
  where?: Maybe<C_FacerMaterial_Bool_Exp>;
};


export type Subscription_RootC_FacerMaterial_By_PkArgs = {
  FacerMaterialId: Scalars['Int'];
};


export type Subscription_RootC_Facer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Facer_Order_By>>;
  where?: Maybe<C_Facer_Bool_Exp>;
};


export type Subscription_RootC_Facer_By_PkArgs = {
  FacerId: Scalars['Int'];
};


export type Subscription_RootC_FastenerCoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FastenerCoating_Order_By>>;
  where?: Maybe<C_FastenerCoating_Bool_Exp>;
};


export type Subscription_RootC_FastenerCoating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FastenerCoating_Order_By>>;
  where?: Maybe<C_FastenerCoating_Bool_Exp>;
};


export type Subscription_RootC_FastenerCoating_By_PkArgs = {
  FastenerCoatingId: Scalars['Int'];
};


export type Subscription_RootC_FastenerDriveHeadArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FastenerDriveHead_Order_By>>;
  where?: Maybe<C_FastenerDriveHead_Bool_Exp>;
};


export type Subscription_RootC_FastenerDriveHead_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FastenerDriveHead_Order_By>>;
  where?: Maybe<C_FastenerDriveHead_Bool_Exp>;
};


export type Subscription_RootC_FastenerDriveHead_By_PkArgs = {
  FastenerDriveHeadId: Scalars['Int'];
};


export type Subscription_RootC_FastenerTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FastenerType_Order_By>>;
  where?: Maybe<C_FastenerType_Bool_Exp>;
};


export type Subscription_RootC_FastenerType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FastenerType_Order_By>>;
  where?: Maybe<C_FastenerType_Bool_Exp>;
};


export type Subscription_RootC_FastenerType_By_PkArgs = {
  FastenerTypeId: Scalars['Int'];
};


export type Subscription_RootC_FasteningApplicationMethodArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FasteningApplicationMethod_Order_By>>;
  where?: Maybe<C_FasteningApplicationMethod_Bool_Exp>;
};


export type Subscription_RootC_FasteningApplicationMethod_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FasteningApplicationMethod_Order_By>>;
  where?: Maybe<C_FasteningApplicationMethod_Bool_Exp>;
};


export type Subscription_RootC_FasteningApplicationMethod_By_PkArgs = {
  FasteningApplicationMethodId: Scalars['Int'];
};


export type Subscription_RootC_FlashingMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FlashingMaterial_Order_By>>;
  where?: Maybe<C_FlashingMaterial_Bool_Exp>;
};


export type Subscription_RootC_FlashingMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FlashingMaterial_Order_By>>;
  where?: Maybe<C_FlashingMaterial_Bool_Exp>;
};


export type Subscription_RootC_FlashingMaterial_By_PkArgs = {
  FlashingMaterialId: Scalars['Int'];
};


export type Subscription_RootC_FlashingTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FlashingType_Order_By>>;
  where?: Maybe<C_FlashingType_Bool_Exp>;
};


export type Subscription_RootC_FlashingType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FlashingType_Order_By>>;
  where?: Maybe<C_FlashingType_Bool_Exp>;
};


export type Subscription_RootC_FlashingType_By_PkArgs = {
  FlashingTypeId: Scalars['Int'];
};


export type Subscription_RootC_FormDeckMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FormDeckMaterial_Order_By>>;
  where?: Maybe<C_FormDeckMaterial_Bool_Exp>;
};


export type Subscription_RootC_FormDeckMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_FormDeckMaterial_Order_By>>;
  where?: Maybe<C_FormDeckMaterial_Bool_Exp>;
};


export type Subscription_RootC_FormDeckMaterial_By_PkArgs = {
  FormDeckMaterialId: Scalars['Int'];
};


export type Subscription_RootC_GroundRoughnessRatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_GroundRoughnessRating_Order_By>>;
  where?: Maybe<C_GroundRoughnessRating_Bool_Exp>;
};


export type Subscription_RootC_GroundRoughnessRating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_GroundRoughnessRating_Order_By>>;
  where?: Maybe<C_GroundRoughnessRating_Bool_Exp>;
};


export type Subscription_RootC_GroundRoughnessRating_By_PkArgs = {
  GroundRoughnessRatingId: Scalars['Int'];
};


export type Subscription_RootC_GutterMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_GutterMaterial_Order_By>>;
  where?: Maybe<C_GutterMaterial_Bool_Exp>;
};


export type Subscription_RootC_GutterMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_GutterMaterial_Order_By>>;
  where?: Maybe<C_GutterMaterial_Bool_Exp>;
};


export type Subscription_RootC_GutterMaterial_By_PkArgs = {
  GutterMaterialId: Scalars['Int'];
};


export type Subscription_RootC_HailRatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_HailRating_Order_By>>;
  where?: Maybe<C_HailRating_Bool_Exp>;
};


export type Subscription_RootC_HailRating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_HailRating_Order_By>>;
  where?: Maybe<C_HailRating_Bool_Exp>;
};


export type Subscription_RootC_HailRating_By_PkArgs = {
  HailRatingId: Scalars['Int'];
};


export type Subscription_RootC_IndustryTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_IndustryType_Order_By>>;
  where?: Maybe<C_IndustryType_Bool_Exp>;
};


export type Subscription_RootC_IndustryType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_IndustryType_Order_By>>;
  where?: Maybe<C_IndustryType_Bool_Exp>;
};


export type Subscription_RootC_IndustryType_By_PkArgs = {
  IndustryTypeId: Scalars['Int'];
};


export type Subscription_RootC_InsulationCoreArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_InsulationCore_Order_By>>;
  where?: Maybe<C_InsulationCore_Bool_Exp>;
};


export type Subscription_RootC_InsulationCore_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_InsulationCore_Order_By>>;
  where?: Maybe<C_InsulationCore_Bool_Exp>;
};


export type Subscription_RootC_InsulationCore_By_PkArgs = {
  InsulationCoreId: Scalars['Int'];
};


export type Subscription_RootC_InsulationFoamTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_InsulationFoamType_Order_By>>;
  where?: Maybe<C_InsulationFoamType_Bool_Exp>;
};


export type Subscription_RootC_InsulationFoamType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_InsulationFoamType_Order_By>>;
  where?: Maybe<C_InsulationFoamType_Bool_Exp>;
};


export type Subscription_RootC_InsulationFoamType_By_PkArgs = {
  InsulationFoamTypeId: Scalars['Int'];
};


export type Subscription_RootC_IntFireRatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_IntFireRating_Order_By>>;
  where?: Maybe<C_IntFireRating_Bool_Exp>;
};


export type Subscription_RootC_IntFireRating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_IntFireRating_Order_By>>;
  where?: Maybe<C_IntFireRating_Bool_Exp>;
};


export type Subscription_RootC_IntFireRating_By_PkArgs = {
  IntFireRatingId: Scalars['Int'];
};


export type Subscription_RootC_LapSealantTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LapSealantType_Order_By>>;
  where?: Maybe<C_LapSealantType_Bool_Exp>;
};


export type Subscription_RootC_LapSealantType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LapSealantType_Order_By>>;
  where?: Maybe<C_LapSealantType_Bool_Exp>;
};


export type Subscription_RootC_LapSealantType_By_PkArgs = {
  LapSealantTypeId: Scalars['Int'];
};


export type Subscription_RootC_LayerTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LayerType_Order_By>>;
  where?: Maybe<C_LayerType_Bool_Exp>;
};


export type Subscription_RootC_LayerType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LayerType_Order_By>>;
  where?: Maybe<C_LayerType_Bool_Exp>;
};


export type Subscription_RootC_LayerType_By_PkArgs = {
  LayerTypeId: Scalars['Int'];
};


export type Subscription_RootC_LinerPanelMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LinerPanelMaterial_Order_By>>;
  where?: Maybe<C_LinerPanelMaterial_Bool_Exp>;
};


export type Subscription_RootC_LinerPanelMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LinerPanelMaterial_Order_By>>;
  where?: Maybe<C_LinerPanelMaterial_Bool_Exp>;
};


export type Subscription_RootC_LinerPanelMaterial_By_PkArgs = {
  LinerPanelMaterialId: Scalars['Int'];
};


export type Subscription_RootC_LiquidApplicationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LiquidApplication_Order_By>>;
  where?: Maybe<C_LiquidApplication_Bool_Exp>;
};


export type Subscription_RootC_LiquidApplication_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LiquidApplication_Order_By>>;
  where?: Maybe<C_LiquidApplication_Bool_Exp>;
};


export type Subscription_RootC_LiquidApplication_By_PkArgs = {
  LiquidApplicationId: Scalars['Int'];
};


export type Subscription_RootC_LiquidAppliedMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LiquidAppliedMaterial_Order_By>>;
  where?: Maybe<C_LiquidAppliedMaterial_Bool_Exp>;
};


export type Subscription_RootC_LiquidAppliedMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LiquidAppliedMaterial_Order_By>>;
  where?: Maybe<C_LiquidAppliedMaterial_Bool_Exp>;
};


export type Subscription_RootC_LiquidAppliedMaterial_By_PkArgs = {
  LiquidAppliedMaterialId: Scalars['Int'];
};


export type Subscription_RootC_LockStatusArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LockStatus_Order_By>>;
  where?: Maybe<C_LockStatus_Bool_Exp>;
};


export type Subscription_RootC_LockStatus_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_LockStatus_Order_By>>;
  where?: Maybe<C_LockStatus_Bool_Exp>;
};


export type Subscription_RootC_LockStatus_By_PkArgs = {
  LockStatusId: Scalars['Int'];
};


export type Subscription_RootC_MdAttributeTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MdAttributeType_Order_By>>;
  where?: Maybe<C_MdAttributeType_Bool_Exp>;
};


export type Subscription_RootC_MdAttributeType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MdAttributeType_Order_By>>;
  where?: Maybe<C_MdAttributeType_Bool_Exp>;
};


export type Subscription_RootC_MdAttributeType_By_PkArgs = {
  MDAttributeTypeId: Scalars['Int'];
};


export type Subscription_RootC_MaintenanceCoatingMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MaintenanceCoatingMaterial_Order_By>>;
  where?: Maybe<C_MaintenanceCoatingMaterial_Bool_Exp>;
};


export type Subscription_RootC_MaintenanceCoatingMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MaintenanceCoatingMaterial_Order_By>>;
  where?: Maybe<C_MaintenanceCoatingMaterial_Bool_Exp>;
};


export type Subscription_RootC_MaintenanceCoatingMaterial_By_PkArgs = {
  MaintenanceCoatingMaterialId: Scalars['Int'];
};


export type Subscription_RootC_MaintenanceCoatingSubstrateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<C_MaintenanceCoatingSubstrate_Bool_Exp>;
};


export type Subscription_RootC_MaintenanceCoatingSubstrate_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<C_MaintenanceCoatingSubstrate_Bool_Exp>;
};


export type Subscription_RootC_MaintenanceCoatingSubstrate_By_PkArgs = {
  MaintenanceCoatingSubstrateId: Scalars['Int'];
};


export type Subscription_RootC_MembraneTerminationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MembraneTermination_Order_By>>;
  where?: Maybe<C_MembraneTermination_Bool_Exp>;
};


export type Subscription_RootC_MembraneTermination_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MembraneTermination_Order_By>>;
  where?: Maybe<C_MembraneTermination_Bool_Exp>;
};


export type Subscription_RootC_MembraneTermination_By_PkArgs = {
  MembraneTerminationId: Scalars['Int'];
};


export type Subscription_RootC_MultiplyMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MultiplyMaterial_Order_By>>;
  where?: Maybe<C_MultiplyMaterial_Bool_Exp>;
};


export type Subscription_RootC_MultiplyMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_MultiplyMaterial_Order_By>>;
  where?: Maybe<C_MultiplyMaterial_Bool_Exp>;
};


export type Subscription_RootC_MultiplyMaterial_By_PkArgs = {
  MultiplyMaterialId: Scalars['Int'];
};


export type Subscription_RootC_PvApplicationMethodArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PvApplicationMethod_Order_By>>;
  where?: Maybe<C_PvApplicationMethod_Bool_Exp>;
};


export type Subscription_RootC_PvApplicationMethod_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PvApplicationMethod_Order_By>>;
  where?: Maybe<C_PvApplicationMethod_Bool_Exp>;
};


export type Subscription_RootC_PvApplicationMethod_By_PkArgs = {
  PVApplicationMethodId: Scalars['Int'];
};


export type Subscription_RootC_PvHailRatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PvHailRating_Order_By>>;
  where?: Maybe<C_PvHailRating_Bool_Exp>;
};


export type Subscription_RootC_PvHailRating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PvHailRating_Order_By>>;
  where?: Maybe<C_PvHailRating_Bool_Exp>;
};


export type Subscription_RootC_PvHailRating_By_PkArgs = {
  PVHailRatingId: Scalars['Int'];
};


export type Subscription_RootC_PerimeterFlashingTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PerimeterFlashingType_Order_By>>;
  where?: Maybe<C_PerimeterFlashingType_Bool_Exp>;
};


export type Subscription_RootC_PerimeterFlashingType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PerimeterFlashingType_Order_By>>;
  where?: Maybe<C_PerimeterFlashingType_Bool_Exp>;
};


export type Subscription_RootC_PerimeterFlashingType_By_PkArgs = {
  PerimeterFlashingTypeId: Scalars['Int'];
};


export type Subscription_RootC_PressureZoneArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PressureZone_Order_By>>;
  where?: Maybe<C_PressureZone_Bool_Exp>;
};


export type Subscription_RootC_PressureZone_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_PressureZone_Order_By>>;
  where?: Maybe<C_PressureZone_Bool_Exp>;
};


export type Subscription_RootC_PressureZone_By_PkArgs = {
  PressureZoneId: Scalars['Int'];
};


export type Subscription_RootC_ProductApprovedUseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ProductApprovedUse_Order_By>>;
  where?: Maybe<C_ProductApprovedUse_Bool_Exp>;
};


export type Subscription_RootC_ProductApprovedUse_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ProductApprovedUse_Order_By>>;
  where?: Maybe<C_ProductApprovedUse_Bool_Exp>;
};


export type Subscription_RootC_ProjectRoleArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ProjectRole_Order_By>>;
  where?: Maybe<C_ProjectRole_Bool_Exp>;
};


export type Subscription_RootC_ProjectRole_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ProjectRole_Order_By>>;
  where?: Maybe<C_ProjectRole_Bool_Exp>;
};


export type Subscription_RootC_ProjectRole_By_PkArgs = {
  ProjectRoleId: Scalars['Int'];
};


export type Subscription_RootC_ReinforcementArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Reinforcement_Order_By>>;
  where?: Maybe<C_Reinforcement_Bool_Exp>;
};


export type Subscription_RootC_Reinforcement_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Reinforcement_Order_By>>;
  where?: Maybe<C_Reinforcement_Bool_Exp>;
};


export type Subscription_RootC_Reinforcement_By_PkArgs = {
  ReinforcementId: Scalars['Int'];
};


export type Subscription_RootC_RetarderMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_RetarderMaterial_Order_By>>;
  where?: Maybe<C_RetarderMaterial_Bool_Exp>;
};


export type Subscription_RootC_RetarderMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_RetarderMaterial_Order_By>>;
  where?: Maybe<C_RetarderMaterial_Bool_Exp>;
};


export type Subscription_RootC_RetarderMaterial_By_PkArgs = {
  RetarderMaterialId: Scalars['Int'];
};


export type Subscription_RootC_ScenarioStatusArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ScenarioStatus_Order_By>>;
  where?: Maybe<C_ScenarioStatus_Bool_Exp>;
};


export type Subscription_RootC_ScenarioStatus_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ScenarioStatus_Order_By>>;
  where?: Maybe<C_ScenarioStatus_Bool_Exp>;
};


export type Subscription_RootC_ScenarioStatus_By_PkArgs = {
  ScenarioStatusId: Scalars['Int'];
};


export type Subscription_RootC_ScenarioTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ScenarioType_Order_By>>;
  where?: Maybe<C_ScenarioType_Bool_Exp>;
};


export type Subscription_RootC_ScenarioType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ScenarioType_Order_By>>;
  where?: Maybe<C_ScenarioType_Bool_Exp>;
};


export type Subscription_RootC_ScenarioType_By_PkArgs = {
  ScenarioTypeId: Scalars['Int'];
};


export type Subscription_RootC_SeamPrimerTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SeamPrimerType_Order_By>>;
  where?: Maybe<C_SeamPrimerType_Bool_Exp>;
};


export type Subscription_RootC_SeamPrimerType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SeamPrimerType_Order_By>>;
  where?: Maybe<C_SeamPrimerType_Bool_Exp>;
};


export type Subscription_RootC_SeamPrimerType_By_PkArgs = {
  SeamPrimerTypeId: Scalars['Int'];
};


export type Subscription_RootC_SeamWashTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SeamWashType_Order_By>>;
  where?: Maybe<C_SeamWashType_Bool_Exp>;
};


export type Subscription_RootC_SeamWashType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SeamWashType_Order_By>>;
  where?: Maybe<C_SeamWashType_Bool_Exp>;
};


export type Subscription_RootC_SeamWashType_By_PkArgs = {
  SeamWashTypeId: Scalars['Int'];
};


export type Subscription_RootC_SecurementMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SecurementMaterial_Order_By>>;
  where?: Maybe<C_SecurementMaterial_Bool_Exp>;
};


export type Subscription_RootC_SecurementMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SecurementMaterial_Order_By>>;
  where?: Maybe<C_SecurementMaterial_Bool_Exp>;
};


export type Subscription_RootC_SecurementMaterial_By_PkArgs = {
  SecurementMaterialId: Scalars['Int'];
};


export type Subscription_RootC_ShinglesHailArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesHail_Order_By>>;
  where?: Maybe<C_ShinglesHail_Bool_Exp>;
};


export type Subscription_RootC_ShinglesHail_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesHail_Order_By>>;
  where?: Maybe<C_ShinglesHail_Bool_Exp>;
};


export type Subscription_RootC_ShinglesHail_By_PkArgs = {
  ShinglesHailId: Scalars['Int'];
};


export type Subscription_RootC_ShinglesMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesMaterial_Order_By>>;
  where?: Maybe<C_ShinglesMaterial_Bool_Exp>;
};


export type Subscription_RootC_ShinglesMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesMaterial_Order_By>>;
  where?: Maybe<C_ShinglesMaterial_Bool_Exp>;
};


export type Subscription_RootC_ShinglesMaterial_By_PkArgs = {
  ShinglesMaterialId: Scalars['Int'];
};


export type Subscription_RootC_ShinglesReinforcementArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesReinforcement_Order_By>>;
  where?: Maybe<C_ShinglesReinforcement_Bool_Exp>;
};


export type Subscription_RootC_ShinglesReinforcement_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesReinforcement_Order_By>>;
  where?: Maybe<C_ShinglesReinforcement_Bool_Exp>;
};


export type Subscription_RootC_ShinglesReinforcement_By_PkArgs = {
  ShinglesReinforcementId: Scalars['Int'];
};


export type Subscription_RootC_ShinglesSurfacingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesSurfacing_Order_By>>;
  where?: Maybe<C_ShinglesSurfacing_Bool_Exp>;
};


export type Subscription_RootC_ShinglesSurfacing_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesSurfacing_Order_By>>;
  where?: Maybe<C_ShinglesSurfacing_Bool_Exp>;
};


export type Subscription_RootC_ShinglesSurfacing_By_PkArgs = {
  ShinglesSurfacingId: Scalars['Int'];
};


export type Subscription_RootC_ShinglesWindSpeedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesWindSpeed_Order_By>>;
  where?: Maybe<C_ShinglesWindSpeed_Bool_Exp>;
};


export type Subscription_RootC_ShinglesWindSpeed_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ShinglesWindSpeed_Order_By>>;
  where?: Maybe<C_ShinglesWindSpeed_Bool_Exp>;
};


export type Subscription_RootC_ShinglesWindSpeed_By_PkArgs = {
  ShinglesWindSpeedId: Scalars['Int'];
};


export type Subscription_RootC_SinglePlyMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SinglePlyMaterial_Order_By>>;
  where?: Maybe<C_SinglePlyMaterial_Bool_Exp>;
};


export type Subscription_RootC_SinglePlyMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SinglePlyMaterial_Order_By>>;
  where?: Maybe<C_SinglePlyMaterial_Bool_Exp>;
};


export type Subscription_RootC_SinglePlyMaterial_By_PkArgs = {
  SinglePlyMaterialId: Scalars['Int'];
};


export type Subscription_RootC_StandingLapSeamMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StandingLapSeamMaterial_Order_By>>;
  where?: Maybe<C_StandingLapSeamMaterial_Bool_Exp>;
};


export type Subscription_RootC_StandingLapSeamMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StandingLapSeamMaterial_Order_By>>;
  where?: Maybe<C_StandingLapSeamMaterial_Bool_Exp>;
};


export type Subscription_RootC_StandingLapSeamMaterial_By_PkArgs = {
  StandingLapSeamMaterialId: Scalars['Int'];
};


export type Subscription_RootC_StateProvinceArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StateProvince_Order_By>>;
  where?: Maybe<C_StateProvince_Bool_Exp>;
};


export type Subscription_RootC_StateProvince_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StateProvince_Order_By>>;
  where?: Maybe<C_StateProvince_Bool_Exp>;
};


export type Subscription_RootC_StateProvince_By_PkArgs = {
  StateProvinceId: Scalars['Int'];
};


export type Subscription_RootC_StatusArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Status_Order_By>>;
  where?: Maybe<C_Status_Bool_Exp>;
};


export type Subscription_RootC_Status_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Status_Order_By>>;
  where?: Maybe<C_Status_Bool_Exp>;
};


export type Subscription_RootC_Status_By_PkArgs = {
  StatusId: Scalars['Int'];
};


export type Subscription_RootC_SteelDeckRibTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SteelDeckRibType_Order_By>>;
  where?: Maybe<C_SteelDeckRibType_Bool_Exp>;
};


export type Subscription_RootC_SteelDeckRibType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SteelDeckRibType_Order_By>>;
  where?: Maybe<C_SteelDeckRibType_Bool_Exp>;
};


export type Subscription_RootC_SteelDeckRibType_By_PkArgs = {
  SteelDeckRibTypeId: Scalars['Int'];
};


export type Subscription_RootC_StressDistributorTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StressDistributorType_Order_By>>;
  where?: Maybe<C_StressDistributorType_Bool_Exp>;
};


export type Subscription_RootC_StressDistributorType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StressDistributorType_Order_By>>;
  where?: Maybe<C_StressDistributorType_Bool_Exp>;
};


export type Subscription_RootC_StressDistributorType_By_PkArgs = {
  StressDistributorTypeId: Scalars['Int'];
};


export type Subscription_RootC_StressPlateShapeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StressPlateShape_Order_By>>;
  where?: Maybe<C_StressPlateShape_Bool_Exp>;
};


export type Subscription_RootC_StressPlateShape_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StressPlateShape_Order_By>>;
  where?: Maybe<C_StressPlateShape_Bool_Exp>;
};


export type Subscription_RootC_StressPlateShape_By_PkArgs = {
  StressPlateShapeId: Scalars['Int'];
};


export type Subscription_RootC_StructureTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StructureType_Order_By>>;
  where?: Maybe<C_StructureType_Bool_Exp>;
};


export type Subscription_RootC_StructureType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_StructureType_Order_By>>;
  where?: Maybe<C_StructureType_Bool_Exp>;
};


export type Subscription_RootC_StructureType_By_PkArgs = {
  StructureTypeId: Scalars['Int'];
};


export type Subscription_RootC_SubassemblyTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SubassemblyType_Order_By>>;
  where?: Maybe<C_SubassemblyType_Bool_Exp>;
};


export type Subscription_RootC_SubassemblyType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SubassemblyType_Order_By>>;
  where?: Maybe<C_SubassemblyType_Bool_Exp>;
};


export type Subscription_RootC_SubassemblyType_By_PkArgs = {
  SubassemblyTypeId: Scalars['Int'];
};


export type Subscription_RootC_SubstrateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Substrate_Order_By>>;
  where?: Maybe<C_Substrate_Bool_Exp>;
};


export type Subscription_RootC_SubstratePrimerMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SubstratePrimerMaterial_Order_By>>;
  where?: Maybe<C_SubstratePrimerMaterial_Bool_Exp>;
};


export type Subscription_RootC_SubstratePrimerMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SubstratePrimerMaterial_Order_By>>;
  where?: Maybe<C_SubstratePrimerMaterial_Bool_Exp>;
};


export type Subscription_RootC_SubstratePrimerMaterial_By_PkArgs = {
  SubstratePrimerMaterialId: Scalars['Int'];
};


export type Subscription_RootC_Substrate_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_Substrate_Order_By>>;
  where?: Maybe<C_Substrate_Bool_Exp>;
};


export type Subscription_RootC_Substrate_By_PkArgs = {
  SubstrateId: Scalars['Int'];
};


export type Subscription_RootC_SurfaceTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SurfaceType_Order_By>>;
  where?: Maybe<C_SurfaceType_Bool_Exp>;
};


export type Subscription_RootC_SurfaceType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SurfaceType_Order_By>>;
  where?: Maybe<C_SurfaceType_Bool_Exp>;
};


export type Subscription_RootC_SurfaceType_By_PkArgs = {
  SurfaceTypeId: Scalars['Int'];
};


export type Subscription_RootC_SurfacingMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SurfacingMaterial_Order_By>>;
  where?: Maybe<C_SurfacingMaterial_Bool_Exp>;
};


export type Subscription_RootC_SurfacingMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SurfacingMaterial_Order_By>>;
  where?: Maybe<C_SurfacingMaterial_Bool_Exp>;
};


export type Subscription_RootC_SurfacingMaterial_By_PkArgs = {
  SurfacingMaterialId: Scalars['Int'];
};


export type Subscription_RootC_SystemApprovedUseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SystemApprovedUse_Order_By>>;
  where?: Maybe<C_SystemApprovedUse_Bool_Exp>;
};


export type Subscription_RootC_SystemApprovedUse_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_SystemApprovedUse_Order_By>>;
  where?: Maybe<C_SystemApprovedUse_Bool_Exp>;
};


export type Subscription_RootC_ThermalBarrierMaterialArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ThermalBarrierMaterial_Order_By>>;
  where?: Maybe<C_ThermalBarrierMaterial_Bool_Exp>;
};


export type Subscription_RootC_ThermalBarrierMaterial_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_ThermalBarrierMaterial_Order_By>>;
  where?: Maybe<C_ThermalBarrierMaterial_Bool_Exp>;
};


export type Subscription_RootC_ThermalBarrierMaterial_By_PkArgs = {
  ThermalBarrierMaterialId: Scalars['Int'];
};


export type Subscription_RootC_WipDelistReasonArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WipDelistReason_Order_By>>;
  where?: Maybe<C_WipDelistReason_Bool_Exp>;
};


export type Subscription_RootC_WipDelistReason_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WipDelistReason_Order_By>>;
  where?: Maybe<C_WipDelistReason_Bool_Exp>;
};


export type Subscription_RootC_WipDelistReason_By_PkArgs = {
  WIPDelistReasonId: Scalars['Int'];
};


export type Subscription_RootC_WipOnHoldReasonArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WipOnHoldReason_Order_By>>;
  where?: Maybe<C_WipOnHoldReason_Bool_Exp>;
};


export type Subscription_RootC_WipOnHoldReason_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WipOnHoldReason_Order_By>>;
  where?: Maybe<C_WipOnHoldReason_Bool_Exp>;
};


export type Subscription_RootC_WipOnHoldReason_By_PkArgs = {
  WIPOnHoldReasonId: Scalars['Int'];
};


export type Subscription_RootC_WipStatusArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WipStatus_Order_By>>;
  where?: Maybe<C_WipStatus_Bool_Exp>;
};


export type Subscription_RootC_WipStatus_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WipStatus_Order_By>>;
  where?: Maybe<C_WipStatus_Bool_Exp>;
};


export type Subscription_RootC_WipStatus_By_PkArgs = {
  WIPStatusId: Scalars['Int'];
};


export type Subscription_RootC_WeldApplicationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WeldApplication_Order_By>>;
  where?: Maybe<C_WeldApplication_Bool_Exp>;
};


export type Subscription_RootC_WeldApplication_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WeldApplication_Order_By>>;
  where?: Maybe<C_WeldApplication_Bool_Exp>;
};


export type Subscription_RootC_WeldApplication_By_PkArgs = {
  WeldApplicationId: Scalars['Int'];
};


export type Subscription_RootC_WeldConfigurationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WeldConfiguration_Order_By>>;
  where?: Maybe<C_WeldConfiguration_Bool_Exp>;
};


export type Subscription_RootC_WeldConfiguration_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WeldConfiguration_Order_By>>;
  where?: Maybe<C_WeldConfiguration_Bool_Exp>;
};


export type Subscription_RootC_WeldConfiguration_By_PkArgs = {
  WeldConfigurationId: Scalars['Int'];
};


export type Subscription_RootC_WindBorneDebrisArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WindBorneDebris_Order_By>>;
  where?: Maybe<C_WindBorneDebris_Bool_Exp>;
};


export type Subscription_RootC_WindBorneDebris_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WindBorneDebris_Order_By>>;
  where?: Maybe<C_WindBorneDebris_Bool_Exp>;
};


export type Subscription_RootC_WindBorneDebris_By_PkArgs = {
  WindBorneDebrisId: Scalars['Int'];
};


export type Subscription_RootC_WoodSpeciesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WoodSpecies_Order_By>>;
  where?: Maybe<C_WoodSpecies_Bool_Exp>;
};


export type Subscription_RootC_WoodSpecies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WoodSpecies_Order_By>>;
  where?: Maybe<C_WoodSpecies_Bool_Exp>;
};


export type Subscription_RootC_WoodSpecies_By_PkArgs = {
  WoodSpeciesId: Scalars['Int'];
};


export type Subscription_RootC_WoodTypeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WoodType_Order_By>>;
  where?: Maybe<C_WoodType_Bool_Exp>;
};


export type Subscription_RootC_WoodType_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WoodType_Order_By>>;
  where?: Maybe<C_WoodType_Bool_Exp>;
};


export type Subscription_RootC_WoodType_By_PkArgs = {
  WoodTypeId: Scalars['Int'];
};


export type Subscription_RootC_WorkflowStatusArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WorkflowStatus_Order_By>>;
  where?: Maybe<C_WorkflowStatus_Bool_Exp>;
};


export type Subscription_RootC_WorkflowStatus_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<C_WorkflowStatus_Order_By>>;
  where?: Maybe<C_WorkflowStatus_Bool_Exp>;
};


export type Subscription_RootC_WorkflowStatus_By_PkArgs = {
  WorkflowStatusId: Scalars['Int'];
};


export type Subscription_RootCapPlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPly_Order_By>>;
  where?: Maybe<CapPly_Bool_Exp>;
};


export type Subscription_RootCapPlyUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPlyUsage_Order_By>>;
  where?: Maybe<CapPlyUsage_Bool_Exp>;
};


export type Subscription_RootCapPlyUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPlyUsage_Order_By>>;
  where?: Maybe<CapPlyUsage_Bool_Exp>;
};


export type Subscription_RootCapPlyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootCapPly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CapPly_Order_By>>;
  where?: Maybe<CapPly_Bool_Exp>;
};


export type Subscription_RootCapPly_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootCategoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Order_By>>;
  where?: Maybe<Category_Bool_Exp>;
};


export type Subscription_RootCategoryLogArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CategoryLog_Order_By>>;
  where?: Maybe<CategoryLog_Bool_Exp>;
};


export type Subscription_RootCategoryLog_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CategoryLog_Order_By>>;
  where?: Maybe<CategoryLog_Bool_Exp>;
};


export type Subscription_RootCategoryLog_By_PkArgs = {
  CategoryLogID: Scalars['Int'];
};


export type Subscription_RootCategory_CoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Coating_Order_By>>;
  where?: Maybe<Category_Coating_Bool_Exp>;
};


export type Subscription_RootCategory_Coating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Coating_Order_By>>;
  where?: Maybe<Category_Coating_Bool_Exp>;
};


export type Subscription_RootCategory_CoverArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Cover_Order_By>>;
  where?: Maybe<Category_Cover_Bool_Exp>;
};


export type Subscription_RootCategory_Cover_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Cover_Order_By>>;
  where?: Maybe<Category_Cover_Bool_Exp>;
};


export type Subscription_RootCategory_DeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Deck_Order_By>>;
  where?: Maybe<Category_Deck_Bool_Exp>;
};


export type Subscription_RootCategory_Deck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Deck_Order_By>>;
  where?: Maybe<Category_Deck_Bool_Exp>;
};


export type Subscription_RootCategory_FlashingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Flashing_Order_By>>;
  where?: Maybe<Category_Flashing_Bool_Exp>;
};


export type Subscription_RootCategory_Flashing_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Flashing_Order_By>>;
  where?: Maybe<Category_Flashing_Bool_Exp>;
};


export type Subscription_RootCategory_InsulationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Insulation_Order_By>>;
  where?: Maybe<Category_Insulation_Bool_Exp>;
};


export type Subscription_RootCategory_Insulation_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Insulation_Order_By>>;
  where?: Maybe<Category_Insulation_Bool_Exp>;
};


export type Subscription_RootCategory_OtherArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Other_Order_By>>;
  where?: Maybe<Category_Other_Bool_Exp>;
};


export type Subscription_RootCategory_Other_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Other_Order_By>>;
  where?: Maybe<Category_Other_Bool_Exp>;
};


export type Subscription_RootCategory_SeamingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Seaming_Order_By>>;
  where?: Maybe<Category_Seaming_Bool_Exp>;
};


export type Subscription_RootCategory_Seaming_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Seaming_Order_By>>;
  where?: Maybe<Category_Seaming_Bool_Exp>;
};


export type Subscription_RootCategory_SecurementArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Securement_Order_By>>;
  where?: Maybe<Category_Securement_Bool_Exp>;
};


export type Subscription_RootCategory_Securement_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Securement_Order_By>>;
  where?: Maybe<Category_Securement_Bool_Exp>;
};


export type Subscription_RootCategory_SheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Sheet_Order_By>>;
  where?: Maybe<Category_Sheet_Bool_Exp>;
};


export type Subscription_RootCategory_Sheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Sheet_Order_By>>;
  where?: Maybe<Category_Sheet_Bool_Exp>;
};


export type Subscription_RootCategory_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Order_By>>;
  where?: Maybe<Category_Bool_Exp>;
};


export type Subscription_RootCategory_By_PkArgs = {
  CategoryID: Scalars['Int'];
};


export type Subscription_RootCementitiousWoodFiberDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CementitiousWoodFiberDeck_Order_By>>;
  where?: Maybe<CementitiousWoodFiberDeck_Bool_Exp>;
};


export type Subscription_RootCementitiousWoodFiberDeckUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CementitiousWoodFiberDeckUsage_Order_By>>;
  where?: Maybe<CementitiousWoodFiberDeckUsage_Bool_Exp>;
};


export type Subscription_RootCementitiousWoodFiberDeckUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CementitiousWoodFiberDeckUsage_Order_By>>;
  where?: Maybe<CementitiousWoodFiberDeckUsage_Bool_Exp>;
};


export type Subscription_RootCementitiousWoodFiberDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootCementitiousWoodFiberDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CementitiousWoodFiberDeck_Order_By>>;
  where?: Maybe<CementitiousWoodFiberDeck_Bool_Exp>;
};


export type Subscription_RootCementitiousWoodFiberDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootClipArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clip_Order_By>>;
  where?: Maybe<Clip_Bool_Exp>;
};


export type Subscription_RootClipUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClipUsage_Order_By>>;
  where?: Maybe<ClipUsage_Bool_Exp>;
};


export type Subscription_RootClipUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClipUsage_Order_By>>;
  where?: Maybe<ClipUsage_Bool_Exp>;
};


export type Subscription_RootClipUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootClip_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clip_Order_By>>;
  where?: Maybe<Clip_Bool_Exp>;
};


export type Subscription_RootClip_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootCoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coat_Order_By>>;
  where?: Maybe<Coat_Bool_Exp>;
};


export type Subscription_RootCoatUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoatUsage_Order_By>>;
  where?: Maybe<CoatUsage_Bool_Exp>;
};


export type Subscription_RootCoatUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoatUsage_Order_By>>;
  where?: Maybe<CoatUsage_Bool_Exp>;
};


export type Subscription_RootCoatUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootCoat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coat_Order_By>>;
  where?: Maybe<Coat_Bool_Exp>;
};


export type Subscription_RootCoat_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootCoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coating_Order_By>>;
  where?: Maybe<Coating_Bool_Exp>;
};


export type Subscription_RootCoatingSystemUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoatingSystemUsage_Order_By>>;
  where?: Maybe<CoatingSystemUsage_Bool_Exp>;
};


export type Subscription_RootCoatingSystemUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoatingSystemUsage_Order_By>>;
  where?: Maybe<CoatingSystemUsage_Bool_Exp>;
};


export type Subscription_RootCoatingSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootCoatingUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoatingUsage_Order_By>>;
  where?: Maybe<CoatingUsage_Bool_Exp>;
};


export type Subscription_RootCoatingUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoatingUsage_Order_By>>;
  where?: Maybe<CoatingUsage_Bool_Exp>;
};


export type Subscription_RootCoatingUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootCoating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coating_Order_By>>;
  where?: Maybe<Coating_Bool_Exp>;
};


export type Subscription_RootCoating_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootComponentArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Order_By>>;
  where?: Maybe<Component_Bool_Exp>;
};


export type Subscription_RootComponentApprovedUseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentApprovedUse_Order_By>>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};


export type Subscription_RootComponentApprovedUse_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentApprovedUse_Order_By>>;
  where?: Maybe<ComponentApprovedUse_Bool_Exp>;
};


export type Subscription_RootComponentApprovedUse_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  ComponentId: Scalars['Int'];
};


export type Subscription_RootComponentDocArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentDoc_Order_By>>;
  where?: Maybe<ComponentDoc_Bool_Exp>;
};


export type Subscription_RootComponentDoc_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentDoc_Order_By>>;
  where?: Maybe<ComponentDoc_Bool_Exp>;
};


export type Subscription_RootComponentDoc_By_PkArgs = {
  ComponentDocId: Scalars['Int'];
};


export type Subscription_RootComponentLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentLayer_Order_By>>;
  where?: Maybe<ComponentLayer_Bool_Exp>;
};


export type Subscription_RootComponentLayerUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentLayerUsage_Order_By>>;
  where?: Maybe<ComponentLayerUsage_Bool_Exp>;
};


export type Subscription_RootComponentLayerUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentLayerUsage_Order_By>>;
  where?: Maybe<ComponentLayerUsage_Bool_Exp>;
};


export type Subscription_RootComponentLayerUsage_By_PkArgs = {
  LayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


export type Subscription_RootComponentLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ComponentLayer_Order_By>>;
  where?: Maybe<ComponentLayer_Bool_Exp>;
};


export type Subscription_RootComponentLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


export type Subscription_RootComponent_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Order_By>>;
  where?: Maybe<Component_Bool_Exp>;
};


export type Subscription_RootComponent_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootCompositePanelCoverArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompositePanelCover_Order_By>>;
  where?: Maybe<CompositePanelCover_Bool_Exp>;
};


export type Subscription_RootCompositePanelCover_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompositePanelCover_Order_By>>;
  where?: Maybe<CompositePanelCover_Bool_Exp>;
};


export type Subscription_RootCompositePanelCover_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootCompositePanelUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompositePanelUsage_Order_By>>;
  where?: Maybe<CompositePanelUsage_Bool_Exp>;
};


export type Subscription_RootCompositePanelUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompositePanelUsage_Order_By>>;
  where?: Maybe<CompositePanelUsage_Bool_Exp>;
};


export type Subscription_RootCompositePanelUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootContactInfoArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactInfo_Order_By>>;
  where?: Maybe<ContactInfo_Bool_Exp>;
};


export type Subscription_RootContactInfo_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactInfo_Order_By>>;
  where?: Maybe<ContactInfo_Bool_Exp>;
};


export type Subscription_RootContactInfo_By_PkArgs = {
  ContactInfoId: Scalars['Int'];
};


export type Subscription_RootCopingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coping_Order_By>>;
  where?: Maybe<Coping_Bool_Exp>;
};


export type Subscription_RootCoping_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coping_Order_By>>;
  where?: Maybe<Coping_Bool_Exp>;
};


export type Subscription_RootCoping_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootCoverBoardArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoard_Order_By>>;
  where?: Maybe<CoverBoard_Bool_Exp>;
};


export type Subscription_RootCoverBoardBoardSizesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardBoardSizes_Order_By>>;
  where?: Maybe<CoverBoardBoardSizes_Bool_Exp>;
};


export type Subscription_RootCoverBoardBoardSizes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardBoardSizes_Order_By>>;
  where?: Maybe<CoverBoardBoardSizes_Bool_Exp>;
};


export type Subscription_RootCoverBoardBoardSizes_By_PkArgs = {
  ComponentId: Scalars['Int'];
  InsBoardSizeId: Scalars['Int'];
};


export type Subscription_RootCoverBoardFacerBottomArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerBottom_Order_By>>;
  where?: Maybe<CoverBoardFacerBottom_Bool_Exp>;
};


export type Subscription_RootCoverBoardFacerBottom_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerBottom_Order_By>>;
  where?: Maybe<CoverBoardFacerBottom_Bool_Exp>;
};


export type Subscription_RootCoverBoardFacerBottom_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


export type Subscription_RootCoverBoardFacerTopArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerTop_Order_By>>;
  where?: Maybe<CoverBoardFacerTop_Bool_Exp>;
};


export type Subscription_RootCoverBoardFacerTop_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardFacerTop_Order_By>>;
  where?: Maybe<CoverBoardFacerTop_Bool_Exp>;
};


export type Subscription_RootCoverBoardFacerTop_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


export type Subscription_RootCoverBoardUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardUsage_Order_By>>;
  where?: Maybe<CoverBoardUsage_Bool_Exp>;
};


export type Subscription_RootCoverBoardUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoardUsage_Order_By>>;
  where?: Maybe<CoverBoardUsage_Bool_Exp>;
};


export type Subscription_RootCoverBoardUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootCoverBoard_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverBoard_Order_By>>;
  where?: Maybe<CoverBoard_Bool_Exp>;
};


export type Subscription_RootCoverBoard_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootCoverStripDiskArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverStripDisk_Order_By>>;
  where?: Maybe<CoverStripDisk_Bool_Exp>;
};


export type Subscription_RootCoverStripDiskUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverStripDiskUsage_Order_By>>;
  where?: Maybe<CoverStripDiskUsage_Bool_Exp>;
};


export type Subscription_RootCoverStripDiskUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverStripDiskUsage_Order_By>>;
  where?: Maybe<CoverStripDiskUsage_Bool_Exp>;
};


export type Subscription_RootCoverStripDiskUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootCoverStripDisk_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CoverStripDisk_Order_By>>;
  where?: Maybe<CoverStripDisk_Bool_Exp>;
};


export type Subscription_RootCoverStripDisk_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootExistingRoofLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExistingRoofLayer_Order_By>>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};


export type Subscription_RootExistingRoofLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExistingRoofLayer_Order_By>>;
  where?: Maybe<ExistingRoofLayer_Bool_Exp>;
};


export type Subscription_RootExistingRoofLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


export type Subscription_RootExpansionJointArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExpansionJoint_Order_By>>;
  where?: Maybe<ExpansionJoint_Bool_Exp>;
};


export type Subscription_RootExpansionJointUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExpansionJointUsage_Order_By>>;
  where?: Maybe<ExpansionJointUsage_Bool_Exp>;
};


export type Subscription_RootExpansionJointUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExpansionJointUsage_Order_By>>;
  where?: Maybe<ExpansionJointUsage_Bool_Exp>;
};


export type Subscription_RootExpansionJointUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootExpansionJoint_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExpansionJoint_Order_By>>;
  where?: Maybe<ExpansionJoint_Bool_Exp>;
};


export type Subscription_RootExpansionJoint_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootExtendedLayerConfigSplitArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtendedLayerConfigSplit_Order_By>>;
  where?: Maybe<ExtendedLayerConfigSplit_Bool_Exp>;
};


export type Subscription_RootExtendedLayerConfigSplit_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ExtendedLayerConfigSplit_Order_By>>;
  where?: Maybe<ExtendedLayerConfigSplit_Bool_Exp>;
};


export type Subscription_RootExtendedLayerConfigSplit_By_PkArgs = {
  ExtendedLayerConfigId: Scalars['Int'];
};


export type Subscription_RootFmUserAccountArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FmUserAccount_Order_By>>;
  where?: Maybe<FmUserAccount_Bool_Exp>;
};


export type Subscription_RootFmUserAccount_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FmUserAccount_Order_By>>;
  where?: Maybe<FmUserAccount_Bool_Exp>;
};


export type Subscription_RootFmUserAccount_By_PkArgs = {
  UserAccountId: Scalars['Int'];
};


export type Subscription_RootFasciaArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fascia_Order_By>>;
  where?: Maybe<Fascia_Bool_Exp>;
};


export type Subscription_RootFascia_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fascia_Order_By>>;
  where?: Maybe<Fascia_Bool_Exp>;
};


export type Subscription_RootFascia_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootFastenerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fastener_Order_By>>;
  where?: Maybe<Fastener_Bool_Exp>;
};


export type Subscription_RootFastenerUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FastenerUsage_Order_By>>;
  where?: Maybe<FastenerUsage_Bool_Exp>;
};


export type Subscription_RootFastenerUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FastenerUsage_Order_By>>;
  where?: Maybe<FastenerUsage_Bool_Exp>;
};


export type Subscription_RootFastenerUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootFastener_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fastener_Order_By>>;
  where?: Maybe<Fastener_Bool_Exp>;
};


export type Subscription_RootFastener_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootFasteningSystemBattenBarUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemBattenBarUsage_Order_By>>;
  where?: Maybe<FasteningSystemBattenBarUsage_Bool_Exp>;
};


export type Subscription_RootFasteningSystemBattenBarUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemBattenBarUsage_Order_By>>;
  where?: Maybe<FasteningSystemBattenBarUsage_Bool_Exp>;
};


export type Subscription_RootFasteningSystemBattenBarUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootFasteningSystemClipUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemClipUsage_Order_By>>;
  where?: Maybe<FasteningSystemClipUsage_Bool_Exp>;
};


export type Subscription_RootFasteningSystemClipUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemClipUsage_Order_By>>;
  where?: Maybe<FasteningSystemClipUsage_Bool_Exp>;
};


export type Subscription_RootFasteningSystemClipUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootFasteningSystemStressPlateUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemStressPlateUsage_Order_By>>;
  where?: Maybe<FasteningSystemStressPlateUsage_Bool_Exp>;
};


export type Subscription_RootFasteningSystemStressPlateUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FasteningSystemStressPlateUsage_Order_By>>;
  where?: Maybe<FasteningSystemStressPlateUsage_Bool_Exp>;
};


export type Subscription_RootFasteningSystemStressPlateUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootFiberReinforcedPlasticDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FiberReinforcedPlasticDeck_Order_By>>;
  where?: Maybe<FiberReinforcedPlasticDeck_Bool_Exp>;
};


export type Subscription_RootFiberReinforcedPlasticDeckUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FiberReinforcedPlasticDeckUsage_Order_By>>;
  where?: Maybe<FiberReinforcedPlasticDeckUsage_Bool_Exp>;
};


export type Subscription_RootFiberReinforcedPlasticDeckUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FiberReinforcedPlasticDeckUsage_Order_By>>;
  where?: Maybe<FiberReinforcedPlasticDeckUsage_Bool_Exp>;
};


export type Subscription_RootFiberReinforcedPlasticDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootFiberReinforcedPlasticDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FiberReinforcedPlasticDeck_Order_By>>;
  where?: Maybe<FiberReinforcedPlasticDeck_Bool_Exp>;
};


export type Subscription_RootFiberReinforcedPlasticDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootFormDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FormDeck_Order_By>>;
  where?: Maybe<FormDeck_Bool_Exp>;
};


export type Subscription_RootFormDeckUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FormDeckUsage_Order_By>>;
  where?: Maybe<FormDeckUsage_Bool_Exp>;
};


export type Subscription_RootFormDeckUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FormDeckUsage_Order_By>>;
  where?: Maybe<FormDeckUsage_Bool_Exp>;
};


export type Subscription_RootFormDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootFormDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FormDeck_Order_By>>;
  where?: Maybe<FormDeck_Bool_Exp>;
};


export type Subscription_RootFormDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootGutterArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gutter_Order_By>>;
  where?: Maybe<Gutter_Bool_Exp>;
};


export type Subscription_RootGutter_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gutter_Order_By>>;
  where?: Maybe<Gutter_Bool_Exp>;
};


export type Subscription_RootGutter_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootGypsumDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GypsumDeck_Order_By>>;
  where?: Maybe<GypsumDeck_Bool_Exp>;
};


export type Subscription_RootGypsumDeckUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GypsumDeckUsage_Order_By>>;
  where?: Maybe<GypsumDeckUsage_Bool_Exp>;
};


export type Subscription_RootGypsumDeckUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GypsumDeckUsage_Order_By>>;
  where?: Maybe<GypsumDeckUsage_Bool_Exp>;
};


export type Subscription_RootGypsumDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootGypsumDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GypsumDeck_Order_By>>;
  where?: Maybe<GypsumDeck_Bool_Exp>;
};


export type Subscription_RootGypsumDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootInsBoardSizeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<InsBoardSize_Order_By>>;
  where?: Maybe<InsBoardSize_Bool_Exp>;
};


export type Subscription_RootInsBoardSize_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<InsBoardSize_Order_By>>;
  where?: Maybe<InsBoardSize_Bool_Exp>;
};


export type Subscription_RootInsBoardSize_By_PkArgs = {
  InsBoardSizeId: Scalars['Int'];
};


export type Subscription_RootItemWorkspaceArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspace_Order_By>>;
  where?: Maybe<ItemWorkspace_Bool_Exp>;
};


export type Subscription_RootItemWorkspaceDataArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspaceData_Order_By>>;
  where?: Maybe<ItemWorkspaceData_Bool_Exp>;
};


export type Subscription_RootItemWorkspaceData_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspaceData_Order_By>>;
  where?: Maybe<ItemWorkspaceData_Bool_Exp>;
};


export type Subscription_RootItemWorkspaceData_By_PkArgs = {
  ItemWorkspaceDataId: Scalars['Int'];
};


export type Subscription_RootItemWorkspace_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ItemWorkspace_Order_By>>;
  where?: Maybe<ItemWorkspace_Bool_Exp>;
};


export type Subscription_RootItemWorkspace_By_PkArgs = {
  ItemWorkspaceId: Scalars['Int'];
};


export type Subscription_RootLapSealantArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSealant_Order_By>>;
  where?: Maybe<LapSealant_Bool_Exp>;
};


export type Subscription_RootLapSealantUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSealantUsage_Order_By>>;
  where?: Maybe<LapSealantUsage_Bool_Exp>;
};


export type Subscription_RootLapSealantUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSealantUsage_Order_By>>;
  where?: Maybe<LapSealantUsage_Bool_Exp>;
};


export type Subscription_RootLapSealantUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootLapSealant_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSealant_Order_By>>;
  where?: Maybe<LapSealant_Bool_Exp>;
};


export type Subscription_RootLapSealant_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootLapSecurementInfoArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSecurementInfo_Order_By>>;
  where?: Maybe<LapSecurementInfo_Bool_Exp>;
};


export type Subscription_RootLapSecurementInfo_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LapSecurementInfo_Order_By>>;
  where?: Maybe<LapSecurementInfo_Bool_Exp>;
};


export type Subscription_RootLapSecurementInfo_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Layer_Order_By>>;
  where?: Maybe<Layer_Bool_Exp>;
};


export type Subscription_RootLayerConfigArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LayerConfig_Order_By>>;
  where?: Maybe<LayerConfig_Bool_Exp>;
};


export type Subscription_RootLayerConfig_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LayerConfig_Order_By>>;
  where?: Maybe<LayerConfig_Bool_Exp>;
};


export type Subscription_RootLayerConfig_By_PkArgs = {
  LayerConfigId: Scalars['Int'];
};


export type Subscription_RootLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Layer_Order_By>>;
  where?: Maybe<Layer_Bool_Exp>;
};


export type Subscription_RootLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


export type Subscription_RootLightweightInsulatingConcreteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
};


export type Subscription_RootLightweightInsulatingConcreteSubassemblyUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcreteSubassemblyUsage_Order_By>>;
  where?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Bool_Exp>;
};


export type Subscription_RootLightweightInsulatingConcreteSubassemblyUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcreteSubassemblyUsage_Order_By>>;
  where?: Maybe<LightweightInsulatingConcreteSubassemblyUsage_Bool_Exp>;
};


export type Subscription_RootLightweightInsulatingConcreteSubassemblyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootLightweightInsulatingConcreteUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcreteUsage_Order_By>>;
  where?: Maybe<LightweightInsulatingConcreteUsage_Bool_Exp>;
};


export type Subscription_RootLightweightInsulatingConcreteUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcreteUsage_Order_By>>;
  where?: Maybe<LightweightInsulatingConcreteUsage_Bool_Exp>;
};


export type Subscription_RootLightweightInsulatingConcreteUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootLightweightInsulatingConcrete_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<LightweightInsulatingConcrete_Bool_Exp>;
};


export type Subscription_RootLightweightInsulatingConcrete_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootLinerPanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LinerPanel_Order_By>>;
  where?: Maybe<LinerPanel_Bool_Exp>;
};


export type Subscription_RootLinerPanelUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LinerPanelUsage_Order_By>>;
  where?: Maybe<LinerPanelUsage_Bool_Exp>;
};


export type Subscription_RootLinerPanelUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LinerPanelUsage_Order_By>>;
  where?: Maybe<LinerPanelUsage_Bool_Exp>;
};


export type Subscription_RootLinerPanelUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootLinerPanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LinerPanel_Order_By>>;
  where?: Maybe<LinerPanel_Bool_Exp>;
};


export type Subscription_RootLinerPanel_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootLiquidAppliedSubassemblyUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LiquidAppliedSubassemblyUsage_Order_By>>;
  where?: Maybe<LiquidAppliedSubassemblyUsage_Bool_Exp>;
};


export type Subscription_RootLiquidAppliedSubassemblyUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LiquidAppliedSubassemblyUsage_Order_By>>;
  where?: Maybe<LiquidAppliedSubassemblyUsage_Bool_Exp>;
};


export type Subscription_RootLiquidAppliedSubassemblyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootLiquidAppliedUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LiquidAppliedUsage_Order_By>>;
  where?: Maybe<LiquidAppliedUsage_Bool_Exp>;
};


export type Subscription_RootLiquidAppliedUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LiquidAppliedUsage_Order_By>>;
  where?: Maybe<LiquidAppliedUsage_Bool_Exp>;
};


export type Subscription_RootLiquidAppliedUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootLoad_SearchLayerUsage_DeckFiberReinforcedPlasticArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Order_By>>;
  where?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Bool_Exp>;
};


export type Subscription_RootLoad_SearchLayerUsage_DeckFiberReinforcedPlastic_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Order_By>>;
  where?: Maybe<Load_SearchLayerUsage_DeckFiberReinforcedPlastic_Bool_Exp>;
};


export type Subscription_RootMd_ApprovedUseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ApprovedUse_Order_By>>;
  where?: Maybe<Md_ApprovedUse_Bool_Exp>;
};


export type Subscription_RootMd_ApprovedUseAttributesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ApprovedUseAttributes_Order_By>>;
  where?: Maybe<Md_ApprovedUseAttributes_Bool_Exp>;
};


export type Subscription_RootMd_ApprovedUseAttributes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ApprovedUseAttributes_Order_By>>;
  where?: Maybe<Md_ApprovedUseAttributes_Bool_Exp>;
};


export type Subscription_RootMd_ApprovedUseAttributes_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  AttributeId: Scalars['Int'];
};


export type Subscription_RootMd_ApprovedUse_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ApprovedUse_Order_By>>;
  where?: Maybe<Md_ApprovedUse_Bool_Exp>;
};


export type Subscription_RootMd_ApprovedUse_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
};


export type Subscription_RootMd_AttributeBaseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_AttributeBase_Order_By>>;
  where?: Maybe<Md_AttributeBase_Bool_Exp>;
};


export type Subscription_RootMd_AttributeBase_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_AttributeBase_Order_By>>;
  where?: Maybe<Md_AttributeBase_Bool_Exp>;
};


export type Subscription_RootMd_AttributeBase_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


export type Subscription_RootMd_BooleanAttributeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_BooleanAttribute_Order_By>>;
  where?: Maybe<Md_BooleanAttribute_Bool_Exp>;
};


export type Subscription_RootMd_BooleanAttribute_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_BooleanAttribute_Order_By>>;
  where?: Maybe<Md_BooleanAttribute_Bool_Exp>;
};


export type Subscription_RootMd_BooleanAttribute_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


export type Subscription_RootMd_BooleanDisplayOptionArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_BooleanDisplayOption_Order_By>>;
  where?: Maybe<Md_BooleanDisplayOption_Bool_Exp>;
};


export type Subscription_RootMd_BooleanDisplayOption_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_BooleanDisplayOption_Order_By>>;
  where?: Maybe<Md_BooleanDisplayOption_Bool_Exp>;
};


export type Subscription_RootMd_BooleanDisplayOption_By_PkArgs = {
  BooleanDisplayOptionId: Scalars['Int'];
};


export type Subscription_RootMd_CatalogArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_Catalog_Order_By>>;
  where?: Maybe<Md_Catalog_Bool_Exp>;
};


export type Subscription_RootMd_Catalog_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_Catalog_Order_By>>;
  where?: Maybe<Md_Catalog_Bool_Exp>;
};


export type Subscription_RootMd_Catalog_By_PkArgs = {
  MetadataCatalogId: Scalars['Int'];
};


export type Subscription_RootMd_CodeTableAttributeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_CodeTableAttribute_Order_By>>;
  where?: Maybe<Md_CodeTableAttribute_Bool_Exp>;
};


export type Subscription_RootMd_CodeTableAttribute_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_CodeTableAttribute_Order_By>>;
  where?: Maybe<Md_CodeTableAttribute_Bool_Exp>;
};


export type Subscription_RootMd_CodeTableAttribute_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


export type Subscription_RootMd_CodeTablesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_CodeTables_Order_By>>;
  where?: Maybe<Md_CodeTables_Bool_Exp>;
};


export type Subscription_RootMd_CodeTables_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_CodeTables_Order_By>>;
  where?: Maybe<Md_CodeTables_Bool_Exp>;
};


export type Subscription_RootMd_CodeTables_By_PkArgs = {
  CodeTableId: Scalars['Int'];
};


export type Subscription_RootMd_ComponentUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ComponentUsage_Order_By>>;
  where?: Maybe<Md_ComponentUsage_Bool_Exp>;
};


export type Subscription_RootMd_ComponentUsageAttributesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ComponentUsageAttributes_Order_By>>;
  where?: Maybe<Md_ComponentUsageAttributes_Bool_Exp>;
};


export type Subscription_RootMd_ComponentUsageAttributes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ComponentUsageAttributes_Order_By>>;
  where?: Maybe<Md_ComponentUsageAttributes_Bool_Exp>;
};


export type Subscription_RootMd_ComponentUsageAttributes_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  AttributeId: Scalars['Int'];
};


export type Subscription_RootMd_ComponentUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ComponentUsage_Order_By>>;
  where?: Maybe<Md_ComponentUsage_Bool_Exp>;
};


export type Subscription_RootMd_ComponentUsage_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
};


export type Subscription_RootMd_MeasureArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_Measure_Order_By>>;
  where?: Maybe<Md_Measure_Bool_Exp>;
};


export type Subscription_RootMd_Measure_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_Measure_Order_By>>;
  where?: Maybe<Md_Measure_Bool_Exp>;
};


export type Subscription_RootMd_Measure_By_PkArgs = {
  MeasureId: Scalars['Int'];
};


export type Subscription_RootMd_MeasurementAttributeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MeasurementAttribute_Order_By>>;
  where?: Maybe<Md_MeasurementAttribute_Bool_Exp>;
};


export type Subscription_RootMd_MeasurementAttribute_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MeasurementAttribute_Order_By>>;
  where?: Maybe<Md_MeasurementAttribute_Bool_Exp>;
};


export type Subscription_RootMd_MeasurementAttribute_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


export type Subscription_RootMd_MeasurementDisplayOptionArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MeasurementDisplayOption_Order_By>>;
  where?: Maybe<Md_MeasurementDisplayOption_Bool_Exp>;
};


export type Subscription_RootMd_MeasurementDisplayOption_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MeasurementDisplayOption_Order_By>>;
  where?: Maybe<Md_MeasurementDisplayOption_Bool_Exp>;
};


export type Subscription_RootMd_MeasurementDisplayOption_By_PkArgs = {
  MeasurementDisplayOptionId: Scalars['Int'];
};


export type Subscription_RootMd_MultivalueAttributeArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MultivalueAttribute_Order_By>>;
  where?: Maybe<Md_MultivalueAttribute_Bool_Exp>;
};


export type Subscription_RootMd_MultivalueAttribute_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MultivalueAttribute_Order_By>>;
  where?: Maybe<Md_MultivalueAttribute_Bool_Exp>;
};


export type Subscription_RootMd_MultivalueAttribute_By_PkArgs = {
  AttributeId: Scalars['Int'];
};


export type Subscription_RootMd_MultivalueDisplayOptionArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MultivalueDisplayOption_Order_By>>;
  where?: Maybe<Md_MultivalueDisplayOption_Bool_Exp>;
};


export type Subscription_RootMd_MultivalueDisplayOption_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_MultivalueDisplayOption_Order_By>>;
  where?: Maybe<Md_MultivalueDisplayOption_Bool_Exp>;
};


export type Subscription_RootMd_MultivalueDisplayOption_By_PkArgs = {
  MultivalueDisplayOptionId: Scalars['Int'];
};


export type Subscription_RootMd_ProductCategoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ProductCategory_Order_By>>;
  where?: Maybe<Md_ProductCategory_Bool_Exp>;
};


export type Subscription_RootMd_ProductCategory_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ProductCategory_Order_By>>;
  where?: Maybe<Md_ProductCategory_Bool_Exp>;
};


export type Subscription_RootMd_ProductCategory_By_PkArgs = {
  CategoryId: Scalars['Int'];
};


export type Subscription_RootMd_ProductSubcategoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ProductSubcategory_Order_By>>;
  where?: Maybe<Md_ProductSubcategory_Bool_Exp>;
};


export type Subscription_RootMd_ProductSubcategory_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Md_ProductSubcategory_Order_By>>;
  where?: Maybe<Md_ProductSubcategory_Bool_Exp>;
};


export type Subscription_RootMd_ProductSubcategory_By_PkArgs = {
  SubcategoryId: Scalars['Int'];
};


export type Subscription_RootMaintenanceCoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoating_Order_By>>;
  where?: Maybe<MaintenanceCoating_Bool_Exp>;
};


export type Subscription_RootMaintenanceCoatingSubstrateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<MaintenanceCoatingSubstrate_Bool_Exp>;
};


export type Subscription_RootMaintenanceCoatingSubstrate_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<MaintenanceCoatingSubstrate_Bool_Exp>;
};


export type Subscription_RootMaintenanceCoatingSubstrate_By_PkArgs = {
  ComponentId: Scalars['Int'];
  MaintenanceCoatingSubstrateId: Scalars['Int'];
};


export type Subscription_RootMaintenanceCoatingUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoatingUsage_Order_By>>;
  where?: Maybe<MaintenanceCoatingUsage_Bool_Exp>;
};


export type Subscription_RootMaintenanceCoatingUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoatingUsage_Order_By>>;
  where?: Maybe<MaintenanceCoatingUsage_Bool_Exp>;
};


export type Subscription_RootMaintenanceCoatingUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootMaintenanceCoating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MaintenanceCoating_Order_By>>;
  where?: Maybe<MaintenanceCoating_Bool_Exp>;
};


export type Subscription_RootMaintenanceCoating_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootManufacturedProductArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturedProduct_Order_By>>;
  where?: Maybe<ManufacturedProduct_Bool_Exp>;
};


export type Subscription_RootManufacturedProduct_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturedProduct_Order_By>>;
  where?: Maybe<ManufacturedProduct_Bool_Exp>;
};


export type Subscription_RootManufacturedProduct_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootManufacturerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manufacturer_Order_By>>;
  where?: Maybe<Manufacturer_Bool_Exp>;
};


export type Subscription_RootManufacturerChangeHistoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturerChangeHistory_Order_By>>;
  where?: Maybe<ManufacturerChangeHistory_Bool_Exp>;
};


export type Subscription_RootManufacturerChangeHistory_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturerChangeHistory_Order_By>>;
  where?: Maybe<ManufacturerChangeHistory_Bool_Exp>;
};


export type Subscription_RootManufacturerChangeHistory_By_PkArgs = {
  ChangeHistoryId: Scalars['Int'];
};


export type Subscription_RootManufacturerListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturerList_Order_By>>;
  where?: Maybe<ManufacturerList_Bool_Exp>;
};


export type Subscription_RootManufacturerList_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ManufacturerList_Order_By>>;
  where?: Maybe<ManufacturerList_Bool_Exp>;
};


export type Subscription_RootManufacturer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manufacturer_Order_By>>;
  where?: Maybe<Manufacturer_Bool_Exp>;
};


export type Subscription_RootManufacturer_By_PkArgs = {
  ManufacturerId: Scalars['Int'];
};


export type Subscription_RootMultiplyCoverConfigArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MultiplyCoverConfig_Order_By>>;
  where?: Maybe<MultiplyCoverConfig_Bool_Exp>;
};


export type Subscription_RootMultiplyCoverConfig_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MultiplyCoverConfig_Order_By>>;
  where?: Maybe<MultiplyCoverConfig_Bool_Exp>;
};


export type Subscription_RootMultiplyCoverConfig_By_PkArgs = {
  MultiplyCoverConfigId: Scalars['Int'];
};


export type Subscription_RootMultiplyCoverSubassemblyUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MultiplyCoverSubassemblyUsage_Order_By>>;
  where?: Maybe<MultiplyCoverSubassemblyUsage_Bool_Exp>;
};


export type Subscription_RootMultiplyCoverSubassemblyUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MultiplyCoverSubassemblyUsage_Order_By>>;
  where?: Maybe<MultiplyCoverSubassemblyUsage_Bool_Exp>;
};


export type Subscription_RootMultiplyCoverSubassemblyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootNavAssemblyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssembly_Order_By>>;
  where?: Maybe<NavAssembly_Bool_Exp>;
};


export type Subscription_RootNavAssemblyMultiplyInfoArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssemblyMultiplyInfo_Order_By>>;
  where?: Maybe<NavAssemblyMultiplyInfo_Bool_Exp>;
};


export type Subscription_RootNavAssemblyMultiplyInfo_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssemblyMultiplyInfo_Order_By>>;
  where?: Maybe<NavAssemblyMultiplyInfo_Bool_Exp>;
};


export type Subscription_RootNavAssembly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssembly_Order_By>>;
  where?: Maybe<NavAssembly_Bool_Exp>;
};


export type Subscription_RootNavAssembly_By_PkArgs = {
  NavAssemblyId: Scalars['Int'];
};


export type Subscription_RootNavLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavLayer_Order_By>>;
  where?: Maybe<NavLayer_Bool_Exp>;
};


export type Subscription_RootNavLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavLayer_Order_By>>;
  where?: Maybe<NavLayer_Bool_Exp>;
};


export type Subscription_RootNavLayer_By_PkArgs = {
  NavLayerId: Scalars['Int'];
};


export type Subscription_RootNavMultiplyCoverConfigArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavMultiplyCoverConfig_Order_By>>;
  where?: Maybe<NavMultiplyCoverConfig_Bool_Exp>;
};


export type Subscription_RootNavMultiplyCoverConfig_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavMultiplyCoverConfig_Order_By>>;
  where?: Maybe<NavMultiplyCoverConfig_Bool_Exp>;
};


export type Subscription_RootNavMultiplyCoverConfig_By_PkArgs = {
  NavMultiplyCoverConfigId: Scalars['Int'];
};


export type Subscription_RootNavSecurementLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavSecurementLayer_Order_By>>;
  where?: Maybe<NavSecurementLayer_Bool_Exp>;
};


export type Subscription_RootNavSecurementLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavSecurementLayer_Order_By>>;
  where?: Maybe<NavSecurementLayer_Bool_Exp>;
};


export type Subscription_RootNavSecurementLayer_By_PkArgs = {
  NavLayerId: Scalars['Int'];
};


export type Subscription_RootNavSecurementUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavSecurementUsage_Order_By>>;
  where?: Maybe<NavSecurementUsage_Bool_Exp>;
};


export type Subscription_RootNavSecurementUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavSecurementUsage_Order_By>>;
  where?: Maybe<NavSecurementUsage_Bool_Exp>;
};


export type Subscription_RootNavSecurementUsage_By_PkArgs = {
  NavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


export type Subscription_RootNavUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavUsage_Order_By>>;
  where?: Maybe<NavUsage_Bool_Exp>;
};


export type Subscription_RootNavUsageSaArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavUsageSa_Order_By>>;
  where?: Maybe<NavUsageSa_Bool_Exp>;
};


export type Subscription_RootNavUsageSa_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavUsageSa_Order_By>>;
  where?: Maybe<NavUsageSa_Bool_Exp>;
};


export type Subscription_RootNavUsageSa_By_PkArgs = {
  NavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


export type Subscription_RootNavUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavUsage_Order_By>>;
  where?: Maybe<NavUsage_Bool_Exp>;
};


export type Subscription_RootNavUsage_By_PkArgs = {
  NavLayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


export type Subscription_RootNav_SinglePlyBondingAdhesivesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nav_SinglePlyBondingAdhesives_Order_By>>;
  where?: Maybe<Nav_SinglePlyBondingAdhesives_Bool_Exp>;
};


export type Subscription_RootNav_SinglePlyBondingAdhesives_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nav_SinglePlyBondingAdhesives_Order_By>>;
  where?: Maybe<Nav_SinglePlyBondingAdhesives_Bool_Exp>;
};


export type Subscription_RootNav_SinglePlyLapAdhesivesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nav_SinglePlyLapAdhesives_Order_By>>;
  where?: Maybe<Nav_SinglePlyLapAdhesives_Bool_Exp>;
};


export type Subscription_RootNav_SinglePlyLapAdhesives_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nav_SinglePlyLapAdhesives_Order_By>>;
  where?: Maybe<Nav_SinglePlyLapAdhesives_Bool_Exp>;
};


export type Subscription_RootNextAssemblyNumArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NextAssemblyNum_Order_By>>;
  where?: Maybe<NextAssemblyNum_Bool_Exp>;
};


export type Subscription_RootNextAssemblyNum_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NextAssemblyNum_Order_By>>;
  where?: Maybe<NextAssemblyNum_Bool_Exp>;
};


export type Subscription_RootNextAssemblyNum_By_PkArgs = {
  AssemblyNum: Scalars['Int'];
};


export type Subscription_RootNoteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Order_By>>;
  where?: Maybe<Note_Bool_Exp>;
};


export type Subscription_RootNote_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Order_By>>;
  where?: Maybe<Note_Bool_Exp>;
};


export type Subscription_RootNote_By_PkArgs = {
  NoteId: Scalars['Int'];
};


export type Subscription_RootOtherPlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OtherPly_Order_By>>;
  where?: Maybe<OtherPly_Bool_Exp>;
};


export type Subscription_RootOtherPlyUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OtherPlyUsage_Order_By>>;
  where?: Maybe<OtherPlyUsage_Bool_Exp>;
};


export type Subscription_RootOtherPlyUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OtherPlyUsage_Order_By>>;
  where?: Maybe<OtherPlyUsage_Bool_Exp>;
};


export type Subscription_RootOtherPlyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootOtherPly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OtherPly_Order_By>>;
  where?: Maybe<OtherPly_Bool_Exp>;
};


export type Subscription_RootOtherPly_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootPreassembledFasteningArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};


export type Subscription_RootPreassembledFasteningUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFasteningUsage_Order_By>>;
  where?: Maybe<PreassembledFasteningUsage_Bool_Exp>;
};


export type Subscription_RootPreassembledFasteningUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFasteningUsage_Order_By>>;
  where?: Maybe<PreassembledFasteningUsage_Bool_Exp>;
};


export type Subscription_RootPreassembledFasteningUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootPreassembledFastening_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PreassembledFastening_Order_By>>;
  where?: Maybe<PreassembledFastening_Bool_Exp>;
};


export type Subscription_RootPreassembledFastening_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootPrivateLabelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PrivateLabel_Order_By>>;
  where?: Maybe<PrivateLabel_Bool_Exp>;
};


export type Subscription_RootPrivateLabel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PrivateLabel_Order_By>>;
  where?: Maybe<PrivateLabel_Bool_Exp>;
};


export type Subscription_RootPrivateLabel_By_PkArgs = {
  ChildComponentId: Scalars['Int'];
  ComponentId: Scalars['Int'];
};


export type Subscription_RootPrivateLabelerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PrivateLabeler_Order_By>>;
  where?: Maybe<PrivateLabeler_Bool_Exp>;
};


export type Subscription_RootPrivateLabeler_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PrivateLabeler_Order_By>>;
  where?: Maybe<PrivateLabeler_Bool_Exp>;
};


export type Subscription_RootProductDependenciesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductDependencies_Order_By>>;
  where?: Maybe<ProductDependencies_Bool_Exp>;
};


export type Subscription_RootProductDependencies_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductDependencies_Order_By>>;
  where?: Maybe<ProductDependencies_Bool_Exp>;
};


export type Subscription_RootProductDependencies_By_PkArgs = {
  Component1Id: Scalars['Int'];
  Component2Id: Scalars['Int'];
};


export type Subscription_RootProductsBySubcategoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsBySubcategory_Order_By>>;
  where?: Maybe<ProductsBySubcategory_Bool_Exp>;
};


export type Subscription_RootProductsBySubcategory_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsBySubcategory_Order_By>>;
  where?: Maybe<ProductsBySubcategory_Bool_Exp>;
};


export type Subscription_RootR_AdhesiveTapeWidthArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_AdhesiveTapeWidth_Order_By>>;
  where?: Maybe<R_AdhesiveTapeWidth_Bool_Exp>;
};


export type Subscription_RootR_AdhesiveTapeWidth_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_AdhesiveTapeWidth_Order_By>>;
  where?: Maybe<R_AdhesiveTapeWidth_Bool_Exp>;
};


export type Subscription_RootR_AdhesiveTapeWidth_By_PkArgs = {
  ComponentId: Scalars['Int'];
  TapeWidthId: Scalars['Int'];
};


export type Subscription_RootR_ApprovedUse_SubcategoryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ApprovedUse_Subcategory_Order_By>>;
  where?: Maybe<R_ApprovedUse_Subcategory_Bool_Exp>;
};


export type Subscription_RootR_ApprovedUse_Subcategory_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ApprovedUse_Subcategory_Order_By>>;
  where?: Maybe<R_ApprovedUse_Subcategory_Bool_Exp>;
};


export type Subscription_RootR_ApprovedUse_Subcategory_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  SubcategoryId: Scalars['Int'];
};


export type Subscription_RootR_ComponentType_ApprovedUseArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ComponentType_ApprovedUse_Order_By>>;
  where?: Maybe<R_ComponentType_ApprovedUse_Bool_Exp>;
};


export type Subscription_RootR_ComponentType_ApprovedUse_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ComponentType_ApprovedUse_Order_By>>;
  where?: Maybe<R_ComponentType_ApprovedUse_Bool_Exp>;
};


export type Subscription_RootR_ComponentType_ApprovedUse_By_PkArgs = {
  ApprovedUseId: Scalars['Int'];
  ComponentTypeId: Scalars['Int'];
};


export type Subscription_RootR_CopingFastenerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_CopingFastener_Order_By>>;
  where?: Maybe<R_CopingFastener_Bool_Exp>;
};


export type Subscription_RootR_CopingFastener_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_CopingFastener_Order_By>>;
  where?: Maybe<R_CopingFastener_Bool_Exp>;
};


export type Subscription_RootR_CopingFastener_By_PkArgs = {
  CopingComponentId: Scalars['Int'];
  FastenerComponentId: Scalars['Int'];
};


export type Subscription_RootR_Country_StateProvinceArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Country_StateProvince_Order_By>>;
  where?: Maybe<R_Country_StateProvince_Bool_Exp>;
};


export type Subscription_RootR_Country_StateProvince_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Country_StateProvince_Order_By>>;
  where?: Maybe<R_Country_StateProvince_Bool_Exp>;
};


export type Subscription_RootR_Country_StateProvince_By_PkArgs = {
  CountryId: Scalars['Int'];
  StateProvinceId: Scalars['Int'];
};


export type Subscription_RootR_ExpansionJoinFastenerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ExpansionJoinFastener_Order_By>>;
  where?: Maybe<R_ExpansionJoinFastener_Bool_Exp>;
};


export type Subscription_RootR_ExpansionJoinFastener_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ExpansionJoinFastener_Order_By>>;
  where?: Maybe<R_ExpansionJoinFastener_Bool_Exp>;
};


export type Subscription_RootR_ExpansionJoinFastener_By_PkArgs = {
  ExpansionJointComponentId: Scalars['Int'];
  FastenerComponentId: Scalars['Int'];
};


export type Subscription_RootR_FasciaComponentFastenerComponentArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_FasciaComponentFastenerComponent_Order_By>>;
  where?: Maybe<R_FasciaComponentFastenerComponent_Bool_Exp>;
};


export type Subscription_RootR_FasciaComponentFastenerComponent_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_FasciaComponentFastenerComponent_Order_By>>;
  where?: Maybe<R_FasciaComponentFastenerComponent_Bool_Exp>;
};


export type Subscription_RootR_FasciaComponentFastenerComponent_By_PkArgs = {
  FasciaComponentId: Scalars['Int'];
  FastenerComponentId: Scalars['Int'];
};


export type Subscription_RootR_GutterFastenersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_GutterFasteners_Order_By>>;
  where?: Maybe<R_GutterFasteners_Bool_Exp>;
};


export type Subscription_RootR_GutterFasteners_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_GutterFasteners_Order_By>>;
  where?: Maybe<R_GutterFasteners_Bool_Exp>;
};


export type Subscription_RootR_GutterFasteners_By_PkArgs = {
  FastenerId: Scalars['Int'];
  GutterId: Scalars['Int'];
};


export type Subscription_RootR_LinerPanelMaterialFiberReinforcedPlasticDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Order_By>>;
  where?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp>;
};


export type Subscription_RootR_LinerPanelMaterialFiberReinforcedPlasticDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Order_By>>;
  where?: Maybe<R_LinerPanelMaterialFiberReinforcedPlasticDeck_Bool_Exp>;
};


export type Subscription_RootR_LinerPanelMaterialFiberReinforcedPlasticDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
  LinerPanelMaterialId: Scalars['Int'];
};


export type Subscription_RootR_MaintenanceCoatingSubstrateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<R_MaintenanceCoatingSubstrate_Bool_Exp>;
};


export type Subscription_RootR_MaintenanceCoatingSubstrate_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_MaintenanceCoatingSubstrate_Order_By>>;
  where?: Maybe<R_MaintenanceCoatingSubstrate_Bool_Exp>;
};


export type Subscription_RootR_MaintenanceCoatingSubstrate_By_PkArgs = {
  ComponentId: Scalars['Int'];
  MaintenanceCoatingSubstrateId: Scalars['Int'];
};


export type Subscription_RootR_NavAssembly_NavMultiplyCoverConfigArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_NavAssembly_NavMultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp>;
};


export type Subscription_RootR_NavAssembly_NavMultiplyCoverConfig_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_NavAssembly_NavMultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_NavAssembly_NavMultiplyCoverConfig_Bool_Exp>;
};


export type Subscription_RootR_NavAssembly_NavMultiplyCoverConfig_By_PkArgs = {
  NavAssemblyId: Scalars['Int'];
  NavMultiplyCoverConfigId: Scalars['Int'];
};


export type Subscription_RootR_ProjectContactArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectContact_Order_By>>;
  where?: Maybe<R_ProjectContact_Bool_Exp>;
};


export type Subscription_RootR_ProjectContact_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectContact_Order_By>>;
  where?: Maybe<R_ProjectContact_Bool_Exp>;
};


export type Subscription_RootR_ProjectContact_By_PkArgs = {
  ContactInfoId: Scalars['Int'];
  ProjectId: Scalars['Int'];
};


export type Subscription_RootR_ProjectNoteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectNote_Order_By>>;
  where?: Maybe<R_ProjectNote_Bool_Exp>;
};


export type Subscription_RootR_ProjectNote_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ProjectNote_Order_By>>;
  where?: Maybe<R_ProjectNote_Bool_Exp>;
};


export type Subscription_RootR_ProjectNote_By_PkArgs = {
  NoteId: Scalars['Int'];
  ProjectId: Scalars['Int'];
};


export type Subscription_RootR_RegletFastenerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RegletFastener_Order_By>>;
  where?: Maybe<R_RegletFastener_Bool_Exp>;
};


export type Subscription_RootR_RegletFastener_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RegletFastener_Order_By>>;
  where?: Maybe<R_RegletFastener_Bool_Exp>;
};


export type Subscription_RootR_RegletFastener_By_PkArgs = {
  FastenerComponentId: Scalars['Int'];
  RegletComponentId: Scalars['Int'];
};


export type Subscription_RootR_RoofAreaContactArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaContact_Order_By>>;
  where?: Maybe<R_RoofAreaContact_Bool_Exp>;
};


export type Subscription_RootR_RoofAreaContact_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaContact_Order_By>>;
  where?: Maybe<R_RoofAreaContact_Bool_Exp>;
};


export type Subscription_RootR_RoofAreaContact_By_PkArgs = {
  ContactInfoId: Scalars['Int'];
  ProjectRoleId: Scalars['Int'];
  RoofAreaId: Scalars['Int'];
};


export type Subscription_RootR_RoofAreaNoteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaNote_Order_By>>;
  where?: Maybe<R_RoofAreaNote_Bool_Exp>;
};


export type Subscription_RootR_RoofAreaNote_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaNote_Order_By>>;
  where?: Maybe<R_RoofAreaNote_Bool_Exp>;
};


export type Subscription_RootR_RoofAreaNote_By_PkArgs = {
  NoteId: Scalars['Int'];
  RoofAreaId: Scalars['Int'];
};


export type Subscription_RootR_RoofAreaScenarioArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaScenario_Order_By>>;
  where?: Maybe<R_RoofAreaScenario_Bool_Exp>;
};


export type Subscription_RootR_RoofAreaScenario_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_RoofAreaScenario_Order_By>>;
  where?: Maybe<R_RoofAreaScenario_Bool_Exp>;
};


export type Subscription_RootR_RoofAreaScenario_By_PkArgs = {
  RoofAreaId: Scalars['Int'];
  ScenarioId: Scalars['Int'];
};


export type Subscription_RootR_ScenarioNoteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ScenarioNote_Order_By>>;
  where?: Maybe<R_ScenarioNote_Bool_Exp>;
};


export type Subscription_RootR_ScenarioNote_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_ScenarioNote_Order_By>>;
  where?: Maybe<R_ScenarioNote_Bool_Exp>;
};


export type Subscription_RootR_ScenarioNote_By_PkArgs = {
  NoteId: Scalars['Int'];
  ScenarioId: Scalars['Int'];
};


export type Subscription_RootR_SinglePly_BondingAdhesivesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_BondingAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_BondingAdhesives_Bool_Exp>;
};


export type Subscription_RootR_SinglePly_BondingAdhesives_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_BondingAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_BondingAdhesives_Bool_Exp>;
};


export type Subscription_RootR_SinglePly_BondingAdhesives_By_PkArgs = {
  AdhesiveComponentId: Scalars['Int'];
  SinglePlyComponentId: Scalars['Int'];
};


export type Subscription_RootR_SinglePly_LapAdhesivesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_LapAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_LapAdhesives_Bool_Exp>;
};


export type Subscription_RootR_SinglePly_LapAdhesives_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_SinglePly_LapAdhesives_Order_By>>;
  where?: Maybe<R_SinglePly_LapAdhesives_Bool_Exp>;
};


export type Subscription_RootR_SinglePly_LapAdhesives_By_PkArgs = {
  AdhesiveComponentId: Scalars['Int'];
  SinglePlyComponentId: Scalars['Int'];
};


export type Subscription_RootR_Subassembly_MultiplyCoverConfigArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Subassembly_MultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_Subassembly_MultiplyCoverConfig_Bool_Exp>;
};


export type Subscription_RootR_Subassembly_MultiplyCoverConfig_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_Subassembly_MultiplyCoverConfig_Order_By>>;
  where?: Maybe<R_Subassembly_MultiplyCoverConfig_Bool_Exp>;
};


export type Subscription_RootR_Subassembly_MultiplyCoverConfig_By_PkArgs = {
  MultiplyCoverConfigId: Scalars['Int'];
  SubassemblyComponentId: Scalars['Int'];
};


export type Subscription_RootR_UserContactArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_UserContact_Order_By>>;
  where?: Maybe<R_UserContact_Bool_Exp>;
};


export type Subscription_RootR_UserContact_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<R_UserContact_Order_By>>;
  where?: Maybe<R_UserContact_Bool_Exp>;
};


export type Subscription_RootR_UserContact_By_PkArgs = {
  ContactInfoId: Scalars['Int'];
  UserAccountId: Scalars['Int'];
};


export type Subscription_RootRegletArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reglet_Order_By>>;
  where?: Maybe<Reglet_Bool_Exp>;
};


export type Subscription_RootReglet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reglet_Order_By>>;
  where?: Maybe<Reglet_Bool_Exp>;
};


export type Subscription_RootReglet_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootReviewerWorkspaceArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspace_Order_By>>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};


export type Subscription_RootReviewerWorkspaceDataArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspaceData_Order_By>>;
  where?: Maybe<ReviewerWorkspaceData_Bool_Exp>;
};


export type Subscription_RootReviewerWorkspaceData_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspaceData_Order_By>>;
  where?: Maybe<ReviewerWorkspaceData_Bool_Exp>;
};


export type Subscription_RootReviewerWorkspaceData_By_PkArgs = {
  ReviewerWorkspaceDataId: Scalars['Int'];
};


export type Subscription_RootReviewerWorkspace_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReviewerWorkspace_Order_By>>;
  where?: Maybe<ReviewerWorkspace_Bool_Exp>;
};


export type Subscription_RootReviewerWorkspace_By_PkArgs = {
  ReviewerWorkspaceId: Scalars['Int'];
};


export type Subscription_RootRoofAreaArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};


export type Subscription_RootRoofAreaWindPressureArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAreaWindPressure_Order_By>>;
  where?: Maybe<RoofAreaWindPressure_Bool_Exp>;
};


export type Subscription_RootRoofAreaWindPressure_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAreaWindPressure_Order_By>>;
  where?: Maybe<RoofAreaWindPressure_Bool_Exp>;
};


export type Subscription_RootRoofAreaWindPressure_By_PkArgs = {
  RoofAreaWindPressureId: Scalars['Int'];
};


export type Subscription_RootRoofArea_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofArea_Order_By>>;
  where?: Maybe<RoofArea_Bool_Exp>;
};


export type Subscription_RootRoofArea_By_PkArgs = {
  RoofAreaId: Scalars['Int'];
};


export type Subscription_RootRoofAssemblyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


export type Subscription_RootRoofAssemblyDelistDataArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssemblyDelistData_Order_By>>;
  where?: Maybe<RoofAssemblyDelistData_Bool_Exp>;
};


export type Subscription_RootRoofAssemblyDelistData_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssemblyDelistData_Order_By>>;
  where?: Maybe<RoofAssemblyDelistData_Bool_Exp>;
};


export type Subscription_RootRoofAssemblyLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssemblyLayers_Order_By>>;
  where?: Maybe<RoofAssemblyLayers_Bool_Exp>;
};


export type Subscription_RootRoofAssemblyLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssemblyLayers_Order_By>>;
  where?: Maybe<RoofAssemblyLayers_Bool_Exp>;
};


export type Subscription_RootRoofAssemblyLayers_By_PkArgs = {
  AssemblyId: Scalars['Int'];
  LayerId: Scalars['Int'];
};


export type Subscription_RootRoofAssembly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofAssembly_Order_By>>;
  where?: Maybe<RoofAssembly_Bool_Exp>;
};


export type Subscription_RootRoofAssembly_By_PkArgs = {
  AssemblyId: Scalars['Int'];
};


export type Subscription_RootRoofProjectArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofProject_Order_By>>;
  where?: Maybe<RoofProject_Bool_Exp>;
};


export type Subscription_RootRoofProject_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RoofProject_Order_By>>;
  where?: Maybe<RoofProject_Bool_Exp>;
};


export type Subscription_RootRoofProject_By_PkArgs = {
  ProjectId: Scalars['Int'];
};


export type Subscription_RootScenarioArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scenario_Order_By>>;
  where?: Maybe<Scenario_Bool_Exp>;
};


export type Subscription_RootScenarioAlertArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioAlert_Order_By>>;
  where?: Maybe<ScenarioAlert_Bool_Exp>;
};


export type Subscription_RootScenarioAlert_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioAlert_Order_By>>;
  where?: Maybe<ScenarioAlert_Bool_Exp>;
};


export type Subscription_RootScenarioAlert_By_PkArgs = {
  ScenarioAlertId: Scalars['Int'];
};


export type Subscription_RootScenarioLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioLayer_Order_By>>;
  where?: Maybe<ScenarioLayer_Bool_Exp>;
};


export type Subscription_RootScenarioLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioLayer_Order_By>>;
  where?: Maybe<ScenarioLayer_Bool_Exp>;
};


export type Subscription_RootScenarioLayer_By_PkArgs = {
  ScenarioLayerId: Scalars['Int'];
};


export type Subscription_RootScenarioUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioUsage_Order_By>>;
  where?: Maybe<ScenarioUsage_Bool_Exp>;
};


export type Subscription_RootScenarioUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ScenarioUsage_Order_By>>;
  where?: Maybe<ScenarioUsage_Bool_Exp>;
};


export type Subscription_RootScenarioUsage_By_PkArgs = {
  ScenarioUsageId: Scalars['Int'];
};


export type Subscription_RootScenario_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scenario_Order_By>>;
  where?: Maybe<Scenario_Bool_Exp>;
};


export type Subscription_RootScenario_By_PkArgs = {
  ScenarioId: Scalars['Int'];
};


export type Subscription_RootSeamPrimerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamPrimer_Order_By>>;
  where?: Maybe<SeamPrimer_Bool_Exp>;
};


export type Subscription_RootSeamPrimerUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamPrimerUsage_Order_By>>;
  where?: Maybe<SeamPrimerUsage_Bool_Exp>;
};


export type Subscription_RootSeamPrimerUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamPrimerUsage_Order_By>>;
  where?: Maybe<SeamPrimerUsage_Bool_Exp>;
};


export type Subscription_RootSeamPrimerUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootSeamPrimer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamPrimer_Order_By>>;
  where?: Maybe<SeamPrimer_Bool_Exp>;
};


export type Subscription_RootSeamPrimer_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootSeamTapeSystemUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamTapeSystemUsage_Order_By>>;
  where?: Maybe<SeamTapeSystemUsage_Bool_Exp>;
};


export type Subscription_RootSeamTapeSystemUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamTapeSystemUsage_Order_By>>;
  where?: Maybe<SeamTapeSystemUsage_Bool_Exp>;
};


export type Subscription_RootSeamTapeSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootSeamWashArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamWash_Order_By>>;
  where?: Maybe<SeamWash_Bool_Exp>;
};


export type Subscription_RootSeamWashUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamWashUsage_Order_By>>;
  where?: Maybe<SeamWashUsage_Bool_Exp>;
};


export type Subscription_RootSeamWashUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamWashUsage_Order_By>>;
  where?: Maybe<SeamWashUsage_Bool_Exp>;
};


export type Subscription_RootSeamWashUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootSeamWash_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeamWash_Order_By>>;
  where?: Maybe<SeamWash_Bool_Exp>;
};


export type Subscription_RootSeamWash_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootSearchNavAssembly_ManufacturerProductArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SearchNavAssembly_ManufacturerProduct_Order_By>>;
  where?: Maybe<SearchNavAssembly_ManufacturerProduct_Bool_Exp>;
};


export type Subscription_RootSearchNavAssembly_ManufacturerProduct_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SearchNavAssembly_ManufacturerProduct_Order_By>>;
  where?: Maybe<SearchNavAssembly_ManufacturerProduct_Bool_Exp>;
};


export type Subscription_RootSearchNavAssembly_ManufacturerProduct_By_PkArgs = {
  ComponentId: Scalars['Int'];
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
};


export type Subscription_RootSecurementLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayer_Order_By>>;
  where?: Maybe<SecurementLayer_Bool_Exp>;
};


export type Subscription_RootSecurementLayerUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayerUsage_Order_By>>;
  where?: Maybe<SecurementLayerUsage_Bool_Exp>;
};


export type Subscription_RootSecurementLayerUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayerUsage_Order_By>>;
  where?: Maybe<SecurementLayerUsage_Bool_Exp>;
};


export type Subscription_RootSecurementLayerUsage_By_PkArgs = {
  LayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


export type Subscription_RootSecurementLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SecurementLayer_Order_By>>;
  where?: Maybe<SecurementLayer_Bool_Exp>;
};


export type Subscription_RootSecurementLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


export type Subscription_RootSelfSecurementUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SelfSecurementUsage_Order_By>>;
  where?: Maybe<SelfSecurementUsage_Bool_Exp>;
};


export type Subscription_RootSelfSecurementUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SelfSecurementUsage_Order_By>>;
  where?: Maybe<SelfSecurementUsage_Bool_Exp>;
};


export type Subscription_RootSelfSecurementUsage_By_PkArgs = {
  LayerId: Scalars['Int'];
  UsageId: Scalars['Int'];
};


export type Subscription_RootSeparatorSheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeparatorSheet_Order_By>>;
  where?: Maybe<SeparatorSheet_Bool_Exp>;
};


export type Subscription_RootSeparatorSheetUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeparatorSheetUsage_Order_By>>;
  where?: Maybe<SeparatorSheetUsage_Bool_Exp>;
};


export type Subscription_RootSeparatorSheetUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeparatorSheetUsage_Order_By>>;
  where?: Maybe<SeparatorSheetUsage_Bool_Exp>;
};


export type Subscription_RootSeparatorSheetUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootSeparatorSheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SeparatorSheet_Order_By>>;
  where?: Maybe<SeparatorSheet_Bool_Exp>;
};


export type Subscription_RootSeparatorSheet_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootShinglesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shingles_Order_By>>;
  where?: Maybe<Shingles_Bool_Exp>;
};


export type Subscription_RootShingles_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shingles_Order_By>>;
  where?: Maybe<Shingles_Bool_Exp>;
};


export type Subscription_RootShingles_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootSinglePlyCoverArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyCover_Order_By>>;
  where?: Maybe<SinglePlyCover_Bool_Exp>;
};


export type Subscription_RootSinglePlyCover_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyCover_Order_By>>;
  where?: Maybe<SinglePlyCover_Bool_Exp>;
};


export type Subscription_RootSinglePlyCover_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootSinglePlyUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyUsage_Order_By>>;
  where?: Maybe<SinglePlyUsage_Bool_Exp>;
};


export type Subscription_RootSinglePlyUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SinglePlyUsage_Order_By>>;
  where?: Maybe<SinglePlyUsage_Bool_Exp>;
};


export type Subscription_RootSinglePlyUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootSprayFoamInsulationUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SprayFoamInsulationUsage_Order_By>>;
  where?: Maybe<SprayFoamInsulationUsage_Bool_Exp>;
};


export type Subscription_RootSprayFoamInsulationUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SprayFoamInsulationUsage_Order_By>>;
  where?: Maybe<SprayFoamInsulationUsage_Bool_Exp>;
};


export type Subscription_RootSprayFoamInsulationUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootSprayedFoamInsulationArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SprayedFoamInsulation_Order_By>>;
  where?: Maybe<SprayedFoamInsulation_Bool_Exp>;
};


export type Subscription_RootSprayedFoamInsulation_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SprayedFoamInsulation_Order_By>>;
  where?: Maybe<SprayedFoamInsulation_Bool_Exp>;
};


export type Subscription_RootSprayedFoamInsulation_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootStandingLapSeamCoverArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StandingLapSeamCover_Order_By>>;
  where?: Maybe<StandingLapSeamCover_Bool_Exp>;
};


export type Subscription_RootStandingLapSeamCover_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StandingLapSeamCover_Order_By>>;
  where?: Maybe<StandingLapSeamCover_Bool_Exp>;
};


export type Subscription_RootStandingLapSeamCover_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootStandingLapSeamUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StandingLapSeamUsage_Order_By>>;
  where?: Maybe<StandingLapSeamUsage_Bool_Exp>;
};


export type Subscription_RootStandingLapSeamUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StandingLapSeamUsage_Order_By>>;
  where?: Maybe<StandingLapSeamUsage_Bool_Exp>;
};


export type Subscription_RootStandingLapSeamUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootSteelDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SteelDeck_Order_By>>;
  where?: Maybe<SteelDeck_Bool_Exp>;
};


export type Subscription_RootSteelDeckLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SteelDeckLayer_Order_By>>;
  where?: Maybe<SteelDeckLayer_Bool_Exp>;
};


export type Subscription_RootSteelDeckLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SteelDeckLayer_Order_By>>;
  where?: Maybe<SteelDeckLayer_Bool_Exp>;
};


export type Subscription_RootSteelDeckLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


export type Subscription_RootSteelDeckUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SteelDeckUsage_Order_By>>;
  where?: Maybe<SteelDeckUsage_Bool_Exp>;
};


export type Subscription_RootSteelDeckUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SteelDeckUsage_Order_By>>;
  where?: Maybe<SteelDeckUsage_Bool_Exp>;
};


export type Subscription_RootSteelDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootSteelDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SteelDeck_Order_By>>;
  where?: Maybe<SteelDeck_Bool_Exp>;
};


export type Subscription_RootSteelDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootStressPlateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StressPlate_Order_By>>;
  where?: Maybe<StressPlate_Bool_Exp>;
};


export type Subscription_RootStressPlateUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StressPlateUsage_Order_By>>;
  where?: Maybe<StressPlateUsage_Bool_Exp>;
};


export type Subscription_RootStressPlateUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StressPlateUsage_Order_By>>;
  where?: Maybe<StressPlateUsage_Bool_Exp>;
};


export type Subscription_RootStressPlateUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootStressPlate_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StressPlate_Order_By>>;
  where?: Maybe<StressPlate_Bool_Exp>;
};


export type Subscription_RootStressPlate_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootStructuralConcreteLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuralConcreteLayer_Order_By>>;
  where?: Maybe<StructuralConcreteLayer_Bool_Exp>;
};


export type Subscription_RootStructuralConcreteLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuralConcreteLayer_Order_By>>;
  where?: Maybe<StructuralConcreteLayer_Bool_Exp>;
};


export type Subscription_RootStructuralConcreteLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


export type Subscription_RootStructureLayerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructureLayer_Order_By>>;
  where?: Maybe<StructureLayer_Bool_Exp>;
};


export type Subscription_RootStructureLayer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructureLayer_Order_By>>;
  where?: Maybe<StructureLayer_Bool_Exp>;
};


export type Subscription_RootStructureLayer_By_PkArgs = {
  LayerId: Scalars['Int'];
};


export type Subscription_RootStructuredSystemArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuredSystem_Order_By>>;
  where?: Maybe<StructuredSystem_Bool_Exp>;
};


export type Subscription_RootStructuredSystemProductsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuredSystemProducts_Order_By>>;
  where?: Maybe<StructuredSystemProducts_Bool_Exp>;
};


export type Subscription_RootStructuredSystemProducts_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuredSystemProducts_Order_By>>;
  where?: Maybe<StructuredSystemProducts_Bool_Exp>;
};


export type Subscription_RootStructuredSystemProducts_By_PkArgs = {
  ComponentId: Scalars['Int'];
  SystemId: Scalars['Int'];
};


export type Subscription_RootStructuredSystem_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StructuredSystem_Order_By>>;
  where?: Maybe<StructuredSystem_Bool_Exp>;
};


export type Subscription_RootStructuredSystem_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootSubassemblyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subassembly_Order_By>>;
  where?: Maybe<Subassembly_Bool_Exp>;
};


export type Subscription_RootSubassemblyDelistDataArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyDelistData_Order_By>>;
  where?: Maybe<SubassemblyDelistData_Bool_Exp>;
};


export type Subscription_RootSubassemblyDelistData_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyDelistData_Order_By>>;
  where?: Maybe<SubassemblyDelistData_Bool_Exp>;
};


export type Subscription_RootSubassemblyE108RatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyE108Rating_Order_By>>;
  where?: Maybe<SubassemblyE108Rating_Bool_Exp>;
};


export type Subscription_RootSubassemblyE108Rating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyE108Rating_Order_By>>;
  where?: Maybe<SubassemblyE108Rating_Bool_Exp>;
};


export type Subscription_RootSubassemblyE108Rating_By_PkArgs = {
  SubassemblyComponentId: Scalars['Int'];
};


export type Subscription_RootSubassemblyLayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyLayers_Order_By>>;
  where?: Maybe<SubassemblyLayers_Bool_Exp>;
};


export type Subscription_RootSubassemblyLayers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyLayers_Order_By>>;
  where?: Maybe<SubassemblyLayers_Bool_Exp>;
};


export type Subscription_RootSubassemblyLayers_By_PkArgs = {
  LayerId: Scalars['Int'];
  SubassemblyComponentId: Scalars['Int'];
};


export type Subscription_RootSubassemblyMultiplyConfigArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyMultiplyConfig_Order_By>>;
  where?: Maybe<SubassemblyMultiplyConfig_Bool_Exp>;
};


export type Subscription_RootSubassemblyMultiplyConfig_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubassemblyMultiplyConfig_Order_By>>;
  where?: Maybe<SubassemblyMultiplyConfig_Bool_Exp>;
};


export type Subscription_RootSubassemblyMultiplyConfig_By_PkArgs = {
  SubassemblyComponentId: Scalars['Int'];
};


export type Subscription_RootSubassembly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subassembly_Order_By>>;
  where?: Maybe<Subassembly_Bool_Exp>;
};


export type Subscription_RootSubassembly_By_PkArgs = {
  SubassemblyComponentId: Scalars['Int'];
};


export type Subscription_RootSubcat_AdhesiveArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Adhesive_Order_By>>;
  where?: Maybe<Subcat_Adhesive_Bool_Exp>;
};


export type Subscription_RootSubcat_Adhesive_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Adhesive_Order_By>>;
  where?: Maybe<Subcat_Adhesive_Bool_Exp>;
};


export type Subscription_RootSubcat_AirRetarderArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_AirRetarder_Order_By>>;
  where?: Maybe<Subcat_AirRetarder_Bool_Exp>;
};


export type Subscription_RootSubcat_AirRetarder_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_AirRetarder_Order_By>>;
  where?: Maybe<Subcat_AirRetarder_Bool_Exp>;
};


export type Subscription_RootSubcat_BaseSheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_BaseSheet_Order_By>>;
  where?: Maybe<Subcat_BaseSheet_Bool_Exp>;
};


export type Subscription_RootSubcat_BaseSheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_BaseSheet_Order_By>>;
  where?: Maybe<Subcat_BaseSheet_Bool_Exp>;
};


export type Subscription_RootSubcat_BattBlanketArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_BattBlanket_Order_By>>;
  where?: Maybe<Subcat_BattBlanket_Bool_Exp>;
};


export type Subscription_RootSubcat_BattBlanket_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_BattBlanket_Order_By>>;
  where?: Maybe<Subcat_BattBlanket_Bool_Exp>;
};


export type Subscription_RootSubcat_BoardStockArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_BoardStock_Order_By>>;
  where?: Maybe<Subcat_BoardStock_Bool_Exp>;
};


export type Subscription_RootSubcat_BoardStock_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_BoardStock_Order_By>>;
  where?: Maybe<Subcat_BoardStock_Bool_Exp>;
};


export type Subscription_RootSubcat_CoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Coating_Order_By>>;
  where?: Maybe<Subcat_Coating_Bool_Exp>;
};


export type Subscription_RootSubcat_Coating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Coating_Order_By>>;
  where?: Maybe<Subcat_Coating_Bool_Exp>;
};


export type Subscription_RootSubcat_CompositePanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_CompositePanel_Order_By>>;
  where?: Maybe<Subcat_CompositePanel_Bool_Exp>;
};


export type Subscription_RootSubcat_CompositePanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_CompositePanel_Order_By>>;
  where?: Maybe<Subcat_CompositePanel_Bool_Exp>;
};


export type Subscription_RootSubcat_CoverStripArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_CoverStrip_Order_By>>;
  where?: Maybe<Subcat_CoverStrip_Bool_Exp>;
};


export type Subscription_RootSubcat_CoverStrip_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_CoverStrip_Order_By>>;
  where?: Maybe<Subcat_CoverStrip_Bool_Exp>;
};


export type Subscription_RootSubcat_ExpJointArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_ExpJoint_Order_By>>;
  where?: Maybe<Subcat_ExpJoint_Bool_Exp>;
};


export type Subscription_RootSubcat_ExpJoint_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_ExpJoint_Order_By>>;
  where?: Maybe<Subcat_ExpJoint_Bool_Exp>;
};


export type Subscription_RootSubcat_FasteningArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Fastening_Order_By>>;
  where?: Maybe<Subcat_Fastening_Bool_Exp>;
};


export type Subscription_RootSubcat_Fastening_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Fastening_Order_By>>;
  where?: Maybe<Subcat_Fastening_Bool_Exp>;
};


export type Subscription_RootSubcat_FormDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_FormDeck_Order_By>>;
  where?: Maybe<Subcat_FormDeck_Bool_Exp>;
};


export type Subscription_RootSubcat_FormDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_FormDeck_Order_By>>;
  where?: Maybe<Subcat_FormDeck_Bool_Exp>;
};


export type Subscription_RootSubcat_GutterArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Gutter_Order_By>>;
  where?: Maybe<Subcat_Gutter_Bool_Exp>;
};


export type Subscription_RootSubcat_Gutter_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Gutter_Order_By>>;
  where?: Maybe<Subcat_Gutter_Bool_Exp>;
};


export type Subscription_RootSubcat_GypsumArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Gypsum_Order_By>>;
  where?: Maybe<Subcat_Gypsum_Bool_Exp>;
};


export type Subscription_RootSubcat_Gypsum_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Gypsum_Order_By>>;
  where?: Maybe<Subcat_Gypsum_Bool_Exp>;
};


export type Subscription_RootSubcat_LwicArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Lwic_Order_By>>;
  where?: Maybe<Subcat_Lwic_Bool_Exp>;
};


export type Subscription_RootSubcat_LwicDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_LwicDeck_Order_By>>;
  where?: Maybe<Subcat_LwicDeck_Bool_Exp>;
};


export type Subscription_RootSubcat_LwicDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_LwicDeck_Order_By>>;
  where?: Maybe<Subcat_LwicDeck_Bool_Exp>;
};


export type Subscription_RootSubcat_Lwic_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Lwic_Order_By>>;
  where?: Maybe<Subcat_Lwic_Bool_Exp>;
};


export type Subscription_RootSubcat_LinerPanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_LinerPanel_Order_By>>;
  where?: Maybe<Subcat_LinerPanel_Bool_Exp>;
};


export type Subscription_RootSubcat_LinerPanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_LinerPanel_Order_By>>;
  where?: Maybe<Subcat_LinerPanel_Bool_Exp>;
};


export type Subscription_RootSubcat_LiquidAppliedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_LiquidApplied_Order_By>>;
  where?: Maybe<Subcat_LiquidApplied_Bool_Exp>;
};


export type Subscription_RootSubcat_LiquidApplied_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_LiquidApplied_Order_By>>;
  where?: Maybe<Subcat_LiquidApplied_Bool_Exp>;
};


export type Subscription_RootSubcat_MaintCoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_MaintCoating_Order_By>>;
  where?: Maybe<Subcat_MaintCoating_Bool_Exp>;
};


export type Subscription_RootSubcat_MaintCoating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_MaintCoating_Order_By>>;
  where?: Maybe<Subcat_MaintCoating_Bool_Exp>;
};


export type Subscription_RootSubcat_MultiplyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Multiply_Order_By>>;
  where?: Maybe<Subcat_Multiply_Bool_Exp>;
};


export type Subscription_RootSubcat_MultiplyCoverArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_MultiplyCover_Order_By>>;
  where?: Maybe<Subcat_MultiplyCover_Bool_Exp>;
};


export type Subscription_RootSubcat_MultiplyCover_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_MultiplyCover_Order_By>>;
  where?: Maybe<Subcat_MultiplyCover_Bool_Exp>;
};


export type Subscription_RootSubcat_Multiply_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Multiply_Order_By>>;
  where?: Maybe<Subcat_Multiply_Bool_Exp>;
};


export type Subscription_RootSubcat_PerimeterFlashingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_PerimeterFlashing_Order_By>>;
  where?: Maybe<Subcat_PerimeterFlashing_Bool_Exp>;
};


export type Subscription_RootSubcat_PerimeterFlashing_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_PerimeterFlashing_Order_By>>;
  where?: Maybe<Subcat_PerimeterFlashing_Bool_Exp>;
};


export type Subscription_RootSubcat_PlasticDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_PlasticDeck_Order_By>>;
  where?: Maybe<Subcat_PlasticDeck_Bool_Exp>;
};


export type Subscription_RootSubcat_PlasticDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_PlasticDeck_Order_By>>;
  where?: Maybe<Subcat_PlasticDeck_Bool_Exp>;
};


export type Subscription_RootSubcat_PrimerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Primer_Order_By>>;
  where?: Maybe<Subcat_Primer_Bool_Exp>;
};


export type Subscription_RootSubcat_Primer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Primer_Order_By>>;
  where?: Maybe<Subcat_Primer_Bool_Exp>;
};


export type Subscription_RootSubcat_SeamingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Seaming_Order_By>>;
  where?: Maybe<Subcat_Seaming_Bool_Exp>;
};


export type Subscription_RootSubcat_Seaming_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Seaming_Order_By>>;
  where?: Maybe<Subcat_Seaming_Bool_Exp>;
};


export type Subscription_RootSubcat_SeparatorSheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SeparatorSheet_Order_By>>;
  where?: Maybe<Subcat_SeparatorSheet_Bool_Exp>;
};


export type Subscription_RootSubcat_SeparatorSheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SeparatorSheet_Order_By>>;
  where?: Maybe<Subcat_SeparatorSheet_Bool_Exp>;
};


export type Subscription_RootSubcat_ShinglesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Shingles_Order_By>>;
  where?: Maybe<Subcat_Shingles_Bool_Exp>;
};


export type Subscription_RootSubcat_Shingles_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Shingles_Order_By>>;
  where?: Maybe<Subcat_Shingles_Bool_Exp>;
};


export type Subscription_RootSubcat_SinglePlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SinglePly_Order_By>>;
  where?: Maybe<Subcat_SinglePly_Bool_Exp>;
};


export type Subscription_RootSubcat_SinglePlyCoverArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SinglePlyCover_Order_By>>;
  where?: Maybe<Subcat_SinglePlyCover_Bool_Exp>;
};


export type Subscription_RootSubcat_SinglePlyCover_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SinglePlyCover_Order_By>>;
  where?: Maybe<Subcat_SinglePlyCover_Bool_Exp>;
};


export type Subscription_RootSubcat_SinglePly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SinglePly_Order_By>>;
  where?: Maybe<Subcat_SinglePly_Bool_Exp>;
};


export type Subscription_RootSubcat_SprayFoamArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SprayFoam_Order_By>>;
  where?: Maybe<Subcat_SprayFoam_Bool_Exp>;
};


export type Subscription_RootSubcat_SprayFoam_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SprayFoam_Order_By>>;
  where?: Maybe<Subcat_SprayFoam_Bool_Exp>;
};


export type Subscription_RootSubcat_StandingLapSeamArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_StandingLapSeam_Order_By>>;
  where?: Maybe<Subcat_StandingLapSeam_Bool_Exp>;
};


export type Subscription_RootSubcat_StandingLapSeam_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_StandingLapSeam_Order_By>>;
  where?: Maybe<Subcat_StandingLapSeam_Bool_Exp>;
};


export type Subscription_RootSubcat_SteelDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SteelDeck_Order_By>>;
  where?: Maybe<Subcat_SteelDeck_Bool_Exp>;
};


export type Subscription_RootSubcat_SteelDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_SteelDeck_Order_By>>;
  where?: Maybe<Subcat_SteelDeck_Bool_Exp>;
};


export type Subscription_RootSubcat_SurfacingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Surfacing_Order_By>>;
  where?: Maybe<Subcat_Surfacing_Bool_Exp>;
};


export type Subscription_RootSubcat_Surfacing_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Surfacing_Order_By>>;
  where?: Maybe<Subcat_Surfacing_Bool_Exp>;
};


export type Subscription_RootSubcat_VaporRetarderArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_VaporRetarder_Order_By>>;
  where?: Maybe<Subcat_VaporRetarder_Bool_Exp>;
};


export type Subscription_RootSubcat_VaporRetarder_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_VaporRetarder_Order_By>>;
  where?: Maybe<Subcat_VaporRetarder_Bool_Exp>;
};


export type Subscription_RootSubcat_WeldArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Weld_Order_By>>;
  where?: Maybe<Subcat_Weld_Bool_Exp>;
};


export type Subscription_RootSubcat_Weld_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Weld_Order_By>>;
  where?: Maybe<Subcat_Weld_Bool_Exp>;
};


export type Subscription_RootSubcat_WoodArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Wood_Order_By>>;
  where?: Maybe<Subcat_Wood_Bool_Exp>;
};


export type Subscription_RootSubcat_WoodFiberDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_WoodFiberDeck_Order_By>>;
  where?: Maybe<Subcat_WoodFiberDeck_Bool_Exp>;
};


export type Subscription_RootSubcat_WoodFiberDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_WoodFiberDeck_Order_By>>;
  where?: Maybe<Subcat_WoodFiberDeck_Bool_Exp>;
};


export type Subscription_RootSubcat_Wood_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Subcat_Wood_Order_By>>;
  where?: Maybe<Subcat_Wood_Bool_Exp>;
};


export type Subscription_RootSubstratePrimerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubstratePrimer_Order_By>>;
  where?: Maybe<SubstratePrimer_Bool_Exp>;
};


export type Subscription_RootSubstratePrimerUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubstratePrimerUsage_Order_By>>;
  where?: Maybe<SubstratePrimerUsage_Bool_Exp>;
};


export type Subscription_RootSubstratePrimerUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubstratePrimerUsage_Order_By>>;
  where?: Maybe<SubstratePrimerUsage_Bool_Exp>;
};


export type Subscription_RootSubstratePrimerUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootSubstratePrimer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SubstratePrimer_Order_By>>;
  where?: Maybe<SubstratePrimer_Bool_Exp>;
};


export type Subscription_RootSubstratePrimer_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootSurfacingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Surfacing_Order_By>>;
  where?: Maybe<Surfacing_Bool_Exp>;
};


export type Subscription_RootSurfacingUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SurfacingUsage_Order_By>>;
  where?: Maybe<SurfacingUsage_Bool_Exp>;
};


export type Subscription_RootSurfacingUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SurfacingUsage_Order_By>>;
  where?: Maybe<SurfacingUsage_Bool_Exp>;
};


export type Subscription_RootSurfacingUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootSurfacing_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Surfacing_Order_By>>;
  where?: Maybe<Surfacing_Bool_Exp>;
};


export type Subscription_RootSurfacing_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootTapeWidthArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TapeWidth_Order_By>>;
  where?: Maybe<TapeWidth_Bool_Exp>;
};


export type Subscription_RootTapeWidth_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TapeWidth_Order_By>>;
  where?: Maybe<TapeWidth_Bool_Exp>;
};


export type Subscription_RootTapeWidth_By_PkArgs = {
  TapeWidthId: Scalars['Int'];
};


export type Subscription_RootThermalBarrierArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrier_Order_By>>;
  where?: Maybe<ThermalBarrier_Bool_Exp>;
};


export type Subscription_RootThermalBarrierBoardSizesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierBoardSizes_Order_By>>;
  where?: Maybe<ThermalBarrierBoardSizes_Bool_Exp>;
};


export type Subscription_RootThermalBarrierBoardSizes_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierBoardSizes_Order_By>>;
  where?: Maybe<ThermalBarrierBoardSizes_Bool_Exp>;
};


export type Subscription_RootThermalBarrierBoardSizes_By_PkArgs = {
  ComponentId: Scalars['Int'];
  InsBoardSizeId: Scalars['Int'];
};


export type Subscription_RootThermalBarrierFacerBottomArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerBottom_Order_By>>;
  where?: Maybe<ThermalBarrierFacerBottom_Bool_Exp>;
};


export type Subscription_RootThermalBarrierFacerBottom_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerBottom_Order_By>>;
  where?: Maybe<ThermalBarrierFacerBottom_Bool_Exp>;
};


export type Subscription_RootThermalBarrierFacerBottom_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


export type Subscription_RootThermalBarrierFacerTopArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerTop_Order_By>>;
  where?: Maybe<ThermalBarrierFacerTop_Bool_Exp>;
};


export type Subscription_RootThermalBarrierFacerTop_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierFacerTop_Order_By>>;
  where?: Maybe<ThermalBarrierFacerTop_Bool_Exp>;
};


export type Subscription_RootThermalBarrierFacerTop_By_PkArgs = {
  ComponentId: Scalars['Int'];
  FacerId: Scalars['Int'];
};


export type Subscription_RootThermalBarrierUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierUsage_Order_By>>;
  where?: Maybe<ThermalBarrierUsage_Bool_Exp>;
};


export type Subscription_RootThermalBarrierUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrierUsage_Order_By>>;
  where?: Maybe<ThermalBarrierUsage_Bool_Exp>;
};


export type Subscription_RootThermalBarrierUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootThermalBarrier_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThermalBarrier_Order_By>>;
  where?: Maybe<ThermalBarrier_Bool_Exp>;
};


export type Subscription_RootThermalBarrier_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootTopCoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TopCoat_Order_By>>;
  where?: Maybe<TopCoat_Bool_Exp>;
};


export type Subscription_RootTopCoatUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TopCoatUsage_Order_By>>;
  where?: Maybe<TopCoatUsage_Bool_Exp>;
};


export type Subscription_RootTopCoatUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TopCoatUsage_Order_By>>;
  where?: Maybe<TopCoatUsage_Bool_Exp>;
};


export type Subscription_RootTopCoatUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootTopCoat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TopCoat_Order_By>>;
  where?: Maybe<TopCoat_Bool_Exp>;
};


export type Subscription_RootTopCoat_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Usage_Order_By>>;
  where?: Maybe<Usage_Bool_Exp>;
};


export type Subscription_RootUsageTradeNameViewArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsageTradeNameView_Order_By>>;
  where?: Maybe<UsageTradeNameView_Bool_Exp>;
};


export type Subscription_RootUsageTradeNameView_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsageTradeNameView_Order_By>>;
  where?: Maybe<UsageTradeNameView_Bool_Exp>;
};


export type Subscription_RootUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Usage_Order_By>>;
  where?: Maybe<Usage_Bool_Exp>;
};


export type Subscription_RootUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootUserReportBugArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserReportBug_Order_By>>;
  where?: Maybe<UserReportBug_Bool_Exp>;
};


export type Subscription_RootUserReportBug_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserReportBug_Order_By>>;
  where?: Maybe<UserReportBug_Bool_Exp>;
};


export type Subscription_RootUserReportBug_By_PkArgs = {
  ReportBugId: Scalars['Int'];
};


export type Subscription_RootUserShareLinkArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserShareLink_Order_By>>;
  where?: Maybe<UserShareLink_Bool_Exp>;
};


export type Subscription_RootUserShareLink_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserShareLink_Order_By>>;
  where?: Maybe<UserShareLink_Bool_Exp>;
};


export type Subscription_RootUserShareLink_By_PkArgs = {
  ShareLinkId: Scalars['Int'];
};


export type Subscription_RootVaporRetarderArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaporRetarder_Order_By>>;
  where?: Maybe<VaporRetarder_Bool_Exp>;
};


export type Subscription_RootVaporRetarderUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaporRetarderUsage_Order_By>>;
  where?: Maybe<VaporRetarderUsage_Bool_Exp>;
};


export type Subscription_RootVaporRetarderUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaporRetarderUsage_Order_By>>;
  where?: Maybe<VaporRetarderUsage_Bool_Exp>;
};


export type Subscription_RootVaporRetarderUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootVaporRetarder_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VaporRetarder_Order_By>>;
  where?: Maybe<VaporRetarder_Bool_Exp>;
};


export type Subscription_RootVaporRetarder_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootWeldArcUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldArcUsage_Order_By>>;
  where?: Maybe<WeldArcUsage_Bool_Exp>;
};


export type Subscription_RootWeldArcUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldArcUsage_Order_By>>;
  where?: Maybe<WeldArcUsage_Bool_Exp>;
};


export type Subscription_RootWeldArcUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootWeldHeatUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldHeatUsage_Order_By>>;
  where?: Maybe<WeldHeatUsage_Bool_Exp>;
};


export type Subscription_RootWeldHeatUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldHeatUsage_Order_By>>;
  where?: Maybe<WeldHeatUsage_Bool_Exp>;
};


export type Subscription_RootWeldHeatUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootWeldSolventUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldSolventUsage_Order_By>>;
  where?: Maybe<WeldSolventUsage_Bool_Exp>;
};


export type Subscription_RootWeldSolventUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldSolventUsage_Order_By>>;
  where?: Maybe<WeldSolventUsage_Bool_Exp>;
};


export type Subscription_RootWeldSolventUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootWeldSystemUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldSystemUsage_Order_By>>;
  where?: Maybe<WeldSystemUsage_Bool_Exp>;
};


export type Subscription_RootWeldSystemUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldSystemUsage_Order_By>>;
  where?: Maybe<WeldSystemUsage_Bool_Exp>;
};


export type Subscription_RootWeldSystemUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootWeldTorchedUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldTorchedUsage_Order_By>>;
  where?: Maybe<WeldTorchedUsage_Bool_Exp>;
};


export type Subscription_RootWeldTorchedUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WeldTorchedUsage_Order_By>>;
  where?: Maybe<WeldTorchedUsage_Bool_Exp>;
};


export type Subscription_RootWeldTorchedUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootWoodDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WoodDeck_Order_By>>;
  where?: Maybe<WoodDeck_Bool_Exp>;
};


export type Subscription_RootWoodDeckUsageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WoodDeckUsage_Order_By>>;
  where?: Maybe<WoodDeckUsage_Bool_Exp>;
};


export type Subscription_RootWoodDeckUsage_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WoodDeckUsage_Order_By>>;
  where?: Maybe<WoodDeckUsage_Bool_Exp>;
};


export type Subscription_RootWoodDeckUsage_By_PkArgs = {
  UsageId: Scalars['Int'];
};


export type Subscription_RootWoodDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WoodDeck_Order_By>>;
  where?: Maybe<WoodDeck_Bool_Exp>;
};


export type Subscription_RootWoodDeck_By_PkArgs = {
  ComponentId: Scalars['Int'];
};


export type Subscription_RootVNavAssembly_LayersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Layers_Order_By>>;
  where?: Maybe<VNavAssembly_Layers_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Layers_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Layers_Order_By>>;
  where?: Maybe<VNavAssembly_Layers_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_ManufacturerOnlySearchArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_ManufacturerOnlySearch_Order_By>>;
  where?: Maybe<VNavAssembly_ManufacturerOnlySearch_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_ManufacturerOnlySearch_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_ManufacturerOnlySearch_Order_By>>;
  where?: Maybe<VNavAssembly_ManufacturerOnlySearch_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_RoofTypeCountArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_RoofTypeCount_Order_By>>;
  where?: Maybe<VNavAssembly_RoofTypeCount_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_RoofTypeCount_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_RoofTypeCount_Order_By>>;
  where?: Maybe<VNavAssembly_RoofTypeCount_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_SearchArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_AirRetarderArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_AirRetarder_Order_By>>;
  where?: Maybe<VNavAssembly_Search_AirRetarder_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_AirRetarder_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_AirRetarder_Order_By>>;
  where?: Maybe<VNavAssembly_Search_AirRetarder_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_BaseCoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BaseCoat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BaseCoat_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_BaseCoat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BaseCoat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BaseCoat_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_BasePlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BasePly_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BasePly_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_BasePly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BasePly_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BasePly_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_BaseSheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BaseSheet_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BaseSheet_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_BaseSheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BaseSheet_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BaseSheet_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_BoardStockArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BoardStock_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BoardStock_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_BoardStock_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_BoardStock_Order_By>>;
  where?: Maybe<VNavAssembly_Search_BoardStock_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_CapPlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CapPly_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CapPly_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_CapPly_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CapPly_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CapPly_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_CoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Coat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Coat_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_Coat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Coat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Coat_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_CoatingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Coating_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Coating_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_Coating_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Coating_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Coating_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_CoverBoardArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverBoard_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverBoard_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_CoverBoard_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverBoard_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverBoard_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_CoverCompositePanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverCompositePanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverCompositePanel_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_CoverCompositePanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverCompositePanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverCompositePanel_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_CoverSingleplyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverSingleply_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverSingleply_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_CoverSingleply_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverSingleply_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverSingleply_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_CoverStandingLapSeamArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverStandingLapSeam_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_CoverStandingLapSeam_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverStandingLapSeam_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_CoverStripDiskArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverStripDisk_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverStripDisk_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_CoverStripDisk_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_CoverStripDisk_Order_By>>;
  where?: Maybe<VNavAssembly_Search_CoverStripDisk_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_DeckCementitiousPanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_DeckCementitiousPanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_DeckCementitiousPanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_DeckCementitiousPanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_DeckSteelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_DeckSteel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_DeckSteel_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_DeckSteel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_DeckSteel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_DeckSteel_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_FormDeckArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_FormDeck_Order_By>>;
  where?: Maybe<VNavAssembly_Search_FormDeck_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_FormDeck_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_FormDeck_Order_By>>;
  where?: Maybe<VNavAssembly_Search_FormDeck_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_InsulationBattBlanketArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_InsulationBattBlanket_Order_By>>;
  where?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_InsulationBattBlanket_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_InsulationBattBlanket_Order_By>>;
  where?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_InsulationSprayFoamArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_InsulationSprayFoam_Order_By>>;
  where?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_InsulationSprayFoam_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_InsulationSprayFoam_Order_By>>;
  where?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_LightweightInsulatingConcreteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_LightweightInsulatingConcrete_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_LightweightInsulatingConcrete_Order_By>>;
  where?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_LinerPanelArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_LinerPanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_LinerPanel_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_LinerPanel_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_LinerPanel_Order_By>>;
  where?: Maybe<VNavAssembly_Search_LinerPanel_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_PlyArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Ply_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Ply_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_Ply_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Ply_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Ply_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_SeparatorSheetArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_SeparatorSheet_Order_By>>;
  where?: Maybe<VNavAssembly_Search_SeparatorSheet_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_SeparatorSheet_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_SeparatorSheet_Order_By>>;
  where?: Maybe<VNavAssembly_Search_SeparatorSheet_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_SubstratePrimerArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_SubstratePrimer_Order_By>>;
  where?: Maybe<VNavAssembly_Search_SubstratePrimer_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_SubstratePrimer_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_SubstratePrimer_Order_By>>;
  where?: Maybe<VNavAssembly_Search_SubstratePrimer_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_SurfacingArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Surfacing_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Surfacing_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_Surfacing_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Surfacing_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Surfacing_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_ThermalBarrierArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_ThermalBarrier_Order_By>>;
  where?: Maybe<VNavAssembly_Search_ThermalBarrier_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_ThermalBarrier_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_ThermalBarrier_Order_By>>;
  where?: Maybe<VNavAssembly_Search_ThermalBarrier_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_TopCoatArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_TopCoat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_TopCoat_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_TopCoat_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_TopCoat_Order_By>>;
  where?: Maybe<VNavAssembly_Search_TopCoat_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_VaporRetarderArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_VaporRetarder_Order_By>>;
  where?: Maybe<VNavAssembly_Search_VaporRetarder_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_VaporRetarder_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_VaporRetarder_Order_By>>;
  where?: Maybe<VNavAssembly_Search_VaporRetarder_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Search_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Search_Order_By>>;
  where?: Maybe<VNavAssembly_Search_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_UsagesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Usages_Order_By>>;
  where?: Maybe<VNavAssembly_Usages_Bool_Exp>;
};


export type Subscription_RootVNavAssembly_Usages_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VNavAssembly_Usages_Order_By>>;
  where?: Maybe<VNavAssembly_Usages_Bool_Exp>;
};

/** columns and relationships of "vNavAssembly_Layers" */
export type VNavAssembly_Layers = {
  __typename?: 'vNavAssembly_Layers';
  Comments: Scalars['ItemComment'];
  FromNavLayerId?: Maybe<Scalars['Int']>;
  FromNavLayerNum?: Maybe<Scalars['Int']>;
  IsOptional: Scalars['Boolean'];
  IsPermeable?: Maybe<Scalars['Boolean']>;
  LayerId: Scalars['Int'];
  LayerTypeCode?: Maybe<Scalars['CodeTableValue']>;
  LayerTypeId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  ToNavLayerId?: Maybe<Scalars['Int']>;
  ToNavLayerNum?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "vNavAssembly_Layers" */
export type VNavAssembly_Layers_Aggregate = {
  __typename?: 'vNavAssembly_Layers_aggregate';
  aggregate?: Maybe<VNavAssembly_Layers_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Layers>;
};

/** aggregate fields of "vNavAssembly_Layers" */
export type VNavAssembly_Layers_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Layers_aggregate_fields';
  avg?: Maybe<VNavAssembly_Layers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Layers_Max_Fields>;
  min?: Maybe<VNavAssembly_Layers_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Layers_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Layers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Layers_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Layers_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Layers_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Layers_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Layers_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Layers" */
export type VNavAssembly_Layers_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Layers_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type VNavAssembly_Layers_Avg_Fields = {
  __typename?: 'vNavAssembly_Layers_avg_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  FromNavLayerNum?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
  ToNavLayerNum?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Layers". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Layers_Bool_Exp = {
  Comments?: Maybe<ItemComment_Mssql_Comparison_Exp>;
  FromNavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  FromNavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  IsOptional?: Maybe<Boolean_Mssql_Comparison_Exp>;
  IsPermeable?: Maybe<Boolean_Mssql_Comparison_Exp>;
  LayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  LayerTypeCode?: Maybe<CodeTableValue_Mssql_Comparison_Exp>;
  LayerTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ToNavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  ToNavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Layers_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Layers_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Layers_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Layers" */
export type VNavAssembly_Layers_If_Matched = {
  match_columns?: Array<VNavAssembly_Layers_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Layers_Update_Column>;
  where?: Maybe<VNavAssembly_Layers_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Layers" */
export type VNavAssembly_Layers_Inc_Input = {
  FromNavLayerId?: Maybe<Scalars['Int']>;
  FromNavLayerNum?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
  ToNavLayerNum?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Layers" */
export type VNavAssembly_Layers_Insert_Input = {
  Comments?: Maybe<Scalars['ItemComment']>;
  FromNavLayerId?: Maybe<Scalars['Int']>;
  FromNavLayerNum?: Maybe<Scalars['Int']>;
  IsOptional?: Maybe<Scalars['Boolean']>;
  IsPermeable?: Maybe<Scalars['Boolean']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeCode?: Maybe<Scalars['CodeTableValue']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
  ToNavLayerNum?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Layers" */
export enum VNavAssembly_Layers_Insert_Match_Column {
  /** column name */
  Comments = 'Comments',
  /** column name */
  FromNavLayerId = 'FromNavLayerId',
  /** column name */
  FromNavLayerNum = 'FromNavLayerNum',
  /** column name */
  IsOptional = 'IsOptional',
  /** column name */
  IsPermeable = 'IsPermeable',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerTypeCode = 'LayerTypeCode',
  /** column name */
  LayerTypeId = 'LayerTypeId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ToNavLayerId = 'ToNavLayerId',
  /** column name */
  ToNavLayerNum = 'ToNavLayerNum'
}

/** aggregate max on columns */
export type VNavAssembly_Layers_Max_Fields = {
  __typename?: 'vNavAssembly_Layers_max_fields';
  Comments?: Maybe<Scalars['ItemComment']>;
  FromNavLayerId?: Maybe<Scalars['Int']>;
  FromNavLayerNum?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeCode?: Maybe<Scalars['CodeTableValue']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
  ToNavLayerNum?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type VNavAssembly_Layers_Min_Fields = {
  __typename?: 'vNavAssembly_Layers_min_fields';
  Comments?: Maybe<Scalars['ItemComment']>;
  FromNavLayerId?: Maybe<Scalars['Int']>;
  FromNavLayerNum?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeCode?: Maybe<Scalars['CodeTableValue']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
  ToNavLayerNum?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "vNavAssembly_Layers" */
export type VNavAssembly_Layers_Mutation_Response = {
  __typename?: 'vNavAssembly_Layers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Layers>;
};

/** Ordering options when selecting data from "vNavAssembly_Layers". */
export type VNavAssembly_Layers_Order_By = {
  Comments?: Maybe<Order_By>;
  FromNavLayerId?: Maybe<Order_By>;
  FromNavLayerNum?: Maybe<Order_By>;
  IsOptional?: Maybe<Order_By>;
  IsPermeable?: Maybe<Order_By>;
  LayerId?: Maybe<Order_By>;
  LayerTypeCode?: Maybe<Order_By>;
  LayerTypeId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ToNavLayerId?: Maybe<Order_By>;
  ToNavLayerNum?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Layers" */
export enum VNavAssembly_Layers_Select_Column {
  /** column name */
  Comments = 'Comments',
  /** column name */
  FromNavLayerId = 'FromNavLayerId',
  /** column name */
  FromNavLayerNum = 'FromNavLayerNum',
  /** column name */
  IsOptional = 'IsOptional',
  /** column name */
  IsPermeable = 'IsPermeable',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerTypeCode = 'LayerTypeCode',
  /** column name */
  LayerTypeId = 'LayerTypeId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ToNavLayerId = 'ToNavLayerId',
  /** column name */
  ToNavLayerNum = 'ToNavLayerNum'
}

/** input type for updating data in table "vNavAssembly_Layers" */
export type VNavAssembly_Layers_Set_Input = {
  Comments?: Maybe<Scalars['ItemComment']>;
  FromNavLayerId?: Maybe<Scalars['Int']>;
  FromNavLayerNum?: Maybe<Scalars['Int']>;
  IsOptional?: Maybe<Scalars['Boolean']>;
  IsPermeable?: Maybe<Scalars['Boolean']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeCode?: Maybe<Scalars['CodeTableValue']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
  ToNavLayerNum?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Layers_Stddev_Fields = {
  __typename?: 'vNavAssembly_Layers_stddev_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  FromNavLayerNum?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
  ToNavLayerNum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Layers_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Layers_stddev_pop_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  FromNavLayerNum?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
  ToNavLayerNum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Layers_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Layers_stddev_samp_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  FromNavLayerNum?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
  ToNavLayerNum?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type VNavAssembly_Layers_Sum_Fields = {
  __typename?: 'vNavAssembly_Layers_sum_fields';
  FromNavLayerId?: Maybe<Scalars['Int']>;
  FromNavLayerNum?: Maybe<Scalars['Int']>;
  LayerId?: Maybe<Scalars['Int']>;
  LayerTypeId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ToNavLayerId?: Maybe<Scalars['Int']>;
  ToNavLayerNum?: Maybe<Scalars['Int']>;
};

/** update columns of table "vNavAssembly_Layers" */
export enum VNavAssembly_Layers_Update_Column {
  /** column name */
  Comments = 'Comments',
  /** column name */
  FromNavLayerId = 'FromNavLayerId',
  /** column name */
  FromNavLayerNum = 'FromNavLayerNum',
  /** column name */
  IsOptional = 'IsOptional',
  /** column name */
  IsPermeable = 'IsPermeable',
  /** column name */
  LayerId = 'LayerId',
  /** column name */
  LayerTypeCode = 'LayerTypeCode',
  /** column name */
  LayerTypeId = 'LayerTypeId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ToNavLayerId = 'ToNavLayerId',
  /** column name */
  ToNavLayerNum = 'ToNavLayerNum'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Layers_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Layers_var_pop_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  FromNavLayerNum?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
  ToNavLayerNum?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Layers_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Layers_var_samp_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  FromNavLayerNum?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
  ToNavLayerNum?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type VNavAssembly_Layers_Variance_Fields = {
  __typename?: 'vNavAssembly_Layers_variance_fields';
  FromNavLayerId?: Maybe<Scalars['Float']>;
  FromNavLayerNum?: Maybe<Scalars['Float']>;
  LayerId?: Maybe<Scalars['Float']>;
  LayerTypeId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ToNavLayerId?: Maybe<Scalars['Float']>;
  ToNavLayerNum?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "vNavAssembly_ManufacturerOnlySearch" */
export type VNavAssembly_ManufacturerOnlySearch = {
  __typename?: 'vNavAssembly_ManufacturerOnlySearch';
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  /** An object relationship */
  NavAssembly_ForManufacturerSearch?: Maybe<NavAssembly>;
};

/** aggregated selection of "vNavAssembly_ManufacturerOnlySearch" */
export type VNavAssembly_ManufacturerOnlySearch_Aggregate = {
  __typename?: 'vNavAssembly_ManufacturerOnlySearch_aggregate';
  aggregate?: Maybe<VNavAssembly_ManufacturerOnlySearch_Aggregate_Fields>;
  nodes: Array<VNavAssembly_ManufacturerOnlySearch>;
};

/** aggregate fields of "vNavAssembly_ManufacturerOnlySearch" */
export type VNavAssembly_ManufacturerOnlySearch_Aggregate_Fields = {
  __typename?: 'vNavAssembly_ManufacturerOnlySearch_aggregate_fields';
  avg?: Maybe<VNavAssembly_ManufacturerOnlySearch_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_ManufacturerOnlySearch_Max_Fields>;
  min?: Maybe<VNavAssembly_ManufacturerOnlySearch_Min_Fields>;
  stddev?: Maybe<VNavAssembly_ManufacturerOnlySearch_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_ManufacturerOnlySearch_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_ManufacturerOnlySearch_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_ManufacturerOnlySearch_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_ManufacturerOnlySearch_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_ManufacturerOnlySearch_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_ManufacturerOnlySearch_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_ManufacturerOnlySearch" */
export type VNavAssembly_ManufacturerOnlySearch_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_ManufacturerOnlySearch_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type VNavAssembly_ManufacturerOnlySearch_Avg_Fields = {
  __typename?: 'vNavAssembly_ManufacturerOnlySearch_avg_fields';
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_ManufacturerOnlySearch". All fields are combined with a logical 'AND'. */
export type VNavAssembly_ManufacturerOnlySearch_Bool_Exp = {
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssembly_ForManufacturerSearch?: Maybe<NavAssembly_Bool_Exp>;
  _and?: Maybe<Array<VNavAssembly_ManufacturerOnlySearch_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_ManufacturerOnlySearch_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_ManufacturerOnlySearch_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_ManufacturerOnlySearch" */
export type VNavAssembly_ManufacturerOnlySearch_If_Matched = {
  match_columns?: Array<VNavAssembly_ManufacturerOnlySearch_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_ManufacturerOnlySearch_Update_Column>;
  where?: Maybe<VNavAssembly_ManufacturerOnlySearch_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_ManufacturerOnlySearch" */
export type VNavAssembly_ManufacturerOnlySearch_Inc_Input = {
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_ManufacturerOnlySearch" */
export type VNavAssembly_ManufacturerOnlySearch_Insert_Input = {
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_ManufacturerOnlySearch" */
export enum VNavAssembly_ManufacturerOnlySearch_Insert_Match_Column {
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId'
}

/** aggregate max on columns */
export type VNavAssembly_ManufacturerOnlySearch_Max_Fields = {
  __typename?: 'vNavAssembly_ManufacturerOnlySearch_max_fields';
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type VNavAssembly_ManufacturerOnlySearch_Min_Fields = {
  __typename?: 'vNavAssembly_ManufacturerOnlySearch_min_fields';
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "vNavAssembly_ManufacturerOnlySearch" */
export type VNavAssembly_ManufacturerOnlySearch_Mutation_Response = {
  __typename?: 'vNavAssembly_ManufacturerOnlySearch_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_ManufacturerOnlySearch>;
};

/** Ordering options when selecting data from "vNavAssembly_ManufacturerOnlySearch". */
export type VNavAssembly_ManufacturerOnlySearch_Order_By = {
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssembly_ForManufacturerSearch?: Maybe<NavAssembly_Order_By>;
};

/** select columns of table "vNavAssembly_ManufacturerOnlySearch" */
export enum VNavAssembly_ManufacturerOnlySearch_Select_Column {
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId'
}

/** input type for updating data in table "vNavAssembly_ManufacturerOnlySearch" */
export type VNavAssembly_ManufacturerOnlySearch_Set_Input = {
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_ManufacturerOnlySearch_Stddev_Fields = {
  __typename?: 'vNavAssembly_ManufacturerOnlySearch_stddev_fields';
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_ManufacturerOnlySearch_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_ManufacturerOnlySearch_stddev_pop_fields';
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_ManufacturerOnlySearch_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_ManufacturerOnlySearch_stddev_samp_fields';
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type VNavAssembly_ManufacturerOnlySearch_Sum_Fields = {
  __typename?: 'vNavAssembly_ManufacturerOnlySearch_sum_fields';
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
};

/** update columns of table "vNavAssembly_ManufacturerOnlySearch" */
export enum VNavAssembly_ManufacturerOnlySearch_Update_Column {
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_ManufacturerOnlySearch_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_ManufacturerOnlySearch_var_pop_fields';
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_ManufacturerOnlySearch_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_ManufacturerOnlySearch_var_samp_fields';
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type VNavAssembly_ManufacturerOnlySearch_Variance_Fields = {
  __typename?: 'vNavAssembly_ManufacturerOnlySearch_variance_fields';
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "vNavAssembly_RoofTypeCount" */
export type VNavAssembly_RoofTypeCount = {
  __typename?: 'vNavAssembly_RoofTypeCount';
  ConfigString?: Maybe<Scalars['String']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  NavAssemblies_ForRoofTypeCount: Array<NavAssembly>;
  /** An aggregate relationship */
  NavAssemblies_ForRoofTypeCount_aggregate: NavAssembly_Aggregate;
  NumOfAssembly?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "vNavAssembly_RoofTypeCount" */
export type VNavAssembly_RoofTypeCountNavAssemblies_ForRoofTypeCountArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssembly_Order_By>>;
  where?: Maybe<NavAssembly_Bool_Exp>;
};


/** columns and relationships of "vNavAssembly_RoofTypeCount" */
export type VNavAssembly_RoofTypeCountNavAssemblies_ForRoofTypeCount_AggregateArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NavAssembly_Order_By>>;
  where?: Maybe<NavAssembly_Bool_Exp>;
};

/** aggregated selection of "vNavAssembly_RoofTypeCount" */
export type VNavAssembly_RoofTypeCount_Aggregate = {
  __typename?: 'vNavAssembly_RoofTypeCount_aggregate';
  aggregate?: Maybe<VNavAssembly_RoofTypeCount_Aggregate_Fields>;
  nodes: Array<VNavAssembly_RoofTypeCount>;
};

/** aggregate fields of "vNavAssembly_RoofTypeCount" */
export type VNavAssembly_RoofTypeCount_Aggregate_Fields = {
  __typename?: 'vNavAssembly_RoofTypeCount_aggregate_fields';
  avg?: Maybe<VNavAssembly_RoofTypeCount_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_RoofTypeCount_Max_Fields>;
  min?: Maybe<VNavAssembly_RoofTypeCount_Min_Fields>;
  stddev?: Maybe<VNavAssembly_RoofTypeCount_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_RoofTypeCount_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_RoofTypeCount_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_RoofTypeCount_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_RoofTypeCount_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_RoofTypeCount_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_RoofTypeCount_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_RoofTypeCount" */
export type VNavAssembly_RoofTypeCount_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_RoofTypeCount_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type VNavAssembly_RoofTypeCount_Avg_Fields = {
  __typename?: 'vNavAssembly_RoofTypeCount_avg_fields';
  LayerConfigId?: Maybe<Scalars['Float']>;
  NumOfAssembly?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_RoofTypeCount". All fields are combined with a logical 'AND'. */
export type VNavAssembly_RoofTypeCount_Bool_Exp = {
  ConfigString?: Maybe<String_Mssql_Comparison_Exp>;
  LayerConfigId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblies_ForRoofTypeCount?: Maybe<NavAssembly_Bool_Exp>;
  NumOfAssembly?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_RoofTypeCount_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_RoofTypeCount_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_RoofTypeCount_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_RoofTypeCount" */
export type VNavAssembly_RoofTypeCount_If_Matched = {
  match_columns?: Array<VNavAssembly_RoofTypeCount_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_RoofTypeCount_Update_Column>;
  where?: Maybe<VNavAssembly_RoofTypeCount_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_RoofTypeCount" */
export type VNavAssembly_RoofTypeCount_Inc_Input = {
  LayerConfigId?: Maybe<Scalars['Int']>;
  NumOfAssembly?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_RoofTypeCount" */
export type VNavAssembly_RoofTypeCount_Insert_Input = {
  ConfigString?: Maybe<Scalars['String']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  NumOfAssembly?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_RoofTypeCount" */
export enum VNavAssembly_RoofTypeCount_Insert_Match_Column {
  /** column name */
  ConfigString = 'ConfigString',
  /** column name */
  LayerConfigId = 'LayerConfigId',
  /** column name */
  NumOfAssembly = 'NumOfAssembly'
}

/** aggregate max on columns */
export type VNavAssembly_RoofTypeCount_Max_Fields = {
  __typename?: 'vNavAssembly_RoofTypeCount_max_fields';
  ConfigString?: Maybe<Scalars['String']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  NumOfAssembly?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type VNavAssembly_RoofTypeCount_Min_Fields = {
  __typename?: 'vNavAssembly_RoofTypeCount_min_fields';
  ConfigString?: Maybe<Scalars['String']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  NumOfAssembly?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "vNavAssembly_RoofTypeCount" */
export type VNavAssembly_RoofTypeCount_Mutation_Response = {
  __typename?: 'vNavAssembly_RoofTypeCount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_RoofTypeCount>;
};

/** Ordering options when selecting data from "vNavAssembly_RoofTypeCount". */
export type VNavAssembly_RoofTypeCount_Order_By = {
  ConfigString?: Maybe<Order_By>;
  LayerConfigId?: Maybe<Order_By>;
  NavAssemblies_ForRoofTypeCount_aggregate?: Maybe<NavAssembly_Aggregate_Order_By>;
  NumOfAssembly?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_RoofTypeCount" */
export enum VNavAssembly_RoofTypeCount_Select_Column {
  /** column name */
  ConfigString = 'ConfigString',
  /** column name */
  LayerConfigId = 'LayerConfigId',
  /** column name */
  NumOfAssembly = 'NumOfAssembly'
}

/** input type for updating data in table "vNavAssembly_RoofTypeCount" */
export type VNavAssembly_RoofTypeCount_Set_Input = {
  ConfigString?: Maybe<Scalars['String']>;
  LayerConfigId?: Maybe<Scalars['Int']>;
  NumOfAssembly?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_RoofTypeCount_Stddev_Fields = {
  __typename?: 'vNavAssembly_RoofTypeCount_stddev_fields';
  LayerConfigId?: Maybe<Scalars['Float']>;
  NumOfAssembly?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_RoofTypeCount_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_RoofTypeCount_stddev_pop_fields';
  LayerConfigId?: Maybe<Scalars['Float']>;
  NumOfAssembly?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_RoofTypeCount_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_RoofTypeCount_stddev_samp_fields';
  LayerConfigId?: Maybe<Scalars['Float']>;
  NumOfAssembly?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type VNavAssembly_RoofTypeCount_Sum_Fields = {
  __typename?: 'vNavAssembly_RoofTypeCount_sum_fields';
  LayerConfigId?: Maybe<Scalars['Int']>;
  NumOfAssembly?: Maybe<Scalars['Int']>;
};

/** update columns of table "vNavAssembly_RoofTypeCount" */
export enum VNavAssembly_RoofTypeCount_Update_Column {
  /** column name */
  ConfigString = 'ConfigString',
  /** column name */
  LayerConfigId = 'LayerConfigId',
  /** column name */
  NumOfAssembly = 'NumOfAssembly'
}

/** aggregate var_pop on columns */
export type VNavAssembly_RoofTypeCount_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_RoofTypeCount_var_pop_fields';
  LayerConfigId?: Maybe<Scalars['Float']>;
  NumOfAssembly?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_RoofTypeCount_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_RoofTypeCount_var_samp_fields';
  LayerConfigId?: Maybe<Scalars['Float']>;
  NumOfAssembly?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type VNavAssembly_RoofTypeCount_Variance_Fields = {
  __typename?: 'vNavAssembly_RoofTypeCount_variance_fields';
  LayerConfigId?: Maybe<Scalars['Float']>;
  NumOfAssembly?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "vNavAssembly_Search" */
export type VNavAssembly_Search = {
  __typename?: 'vNavAssembly_Search';
  AssemblyApplicationTypeId: Scalars['Int'];
  AssemblyId: Scalars['Int'];
  AssemblyTypeId: Scalars['Int'];
  ComponentId: Scalars['Int'];
  CoverSecurementMethodId: Scalars['Int'];
  DeckTypeId: Scalars['Int'];
  ExtFireRatingId: Scalars['Int'];
  HailRatingId: Scalars['Int'];
  IntFireRatingId: Scalars['Int'];
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavAssemblyName: Scalars['String'];
  Slope: Scalars['Float'];
  WindUplift: Scalars['Float'];
};

/** columns and relationships of "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder = {
  __typename?: 'vNavAssembly_Search_AirRetarder';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  RetarderMaterialId: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Aggregate = {
  __typename?: 'vNavAssembly_Search_AirRetarder_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_AirRetarder_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_AirRetarder>;
};

/** aggregate fields of "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_AirRetarder_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_AirRetarder_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_AirRetarder_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_AirRetarder_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_AirRetarder_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_AirRetarder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_AirRetarder_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_AirRetarder_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_AirRetarder_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_AirRetarder_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_AirRetarder_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_AirRetarder_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_AirRetarder_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_AirRetarder_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_AirRetarder_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_AirRetarder_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_AirRetarder_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_AirRetarder_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_AirRetarder_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_AirRetarder_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_AirRetarder_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_AirRetarder_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_AirRetarder_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_AirRetarder_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_AirRetarder". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_AirRetarder_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  RetarderMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_AirRetarder_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_AirRetarder_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_AirRetarder_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_AirRetarder_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_AirRetarder_Update_Column>;
  where?: Maybe<VNavAssembly_Search_AirRetarder_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_AirRetarder" */
export enum VNavAssembly_Search_AirRetarder_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_AirRetarder_Max_Fields = {
  __typename?: 'vNavAssembly_Search_AirRetarder_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_AirRetarder_Min_Fields = {
  __typename?: 'vNavAssembly_Search_AirRetarder_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_AirRetarder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_AirRetarder>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_AirRetarder". */
export type VNavAssembly_Search_AirRetarder_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_AirRetarder" */
export enum VNavAssembly_Search_AirRetarder_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_AirRetarder_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_AirRetarder_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_AirRetarder_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_AirRetarder_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_AirRetarder_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_AirRetarder_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_AirRetarder_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_AirRetarder_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_AirRetarder" */
export enum VNavAssembly_Search_AirRetarder_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_AirRetarder_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_AirRetarder_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_AirRetarder_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_AirRetarder_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_AirRetarder_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_AirRetarder_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_AirRetarder" */
export type VNavAssembly_Search_AirRetarder_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat = {
  __typename?: 'vNavAssembly_Search_BaseCoat';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId: Scalars['Int'];
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  NumCoats: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Aggregate = {
  __typename?: 'vNavAssembly_Search_BaseCoat_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_BaseCoat_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_BaseCoat>;
};

/** aggregate fields of "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_BaseCoat_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_BaseCoat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_BaseCoat_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_BaseCoat_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_BaseCoat_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_BaseCoat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_BaseCoat_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_BaseCoat_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_BaseCoat_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_BaseCoat_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_BaseCoat_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_BaseCoat_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_BaseCoat_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_BaseCoat_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_BaseCoat_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_BaseCoat_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_BaseCoat_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_BaseCoat_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_BaseCoat_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_BaseCoat_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_BaseCoat_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_BaseCoat_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_BaseCoat_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_BaseCoat_avg_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Avg_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_BaseCoat". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_BaseCoat_Bool_Exp = {
  ApplicationRateMinDry?: Maybe<Float_Mssql_Comparison_Exp>;
  ApplicationRateMinWet?: Maybe<Float_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  LiquidAppliedMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  NumCoats?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_BaseCoat_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_BaseCoat_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_BaseCoat_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_BaseCoat_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_BaseCoat_Update_Column>;
  where?: Maybe<VNavAssembly_Search_BaseCoat_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Inc_Input = {
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Insert_Input = {
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_BaseCoat" */
export enum VNavAssembly_Search_BaseCoat_Insert_Match_Column {
  /** column name */
  ApplicationRateMinDry = 'ApplicationRateMinDry',
  /** column name */
  ApplicationRateMinWet = 'ApplicationRateMinWet',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_BaseCoat_Max_Fields = {
  __typename?: 'vNavAssembly_Search_BaseCoat_max_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Max_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_BaseCoat_Min_Fields = {
  __typename?: 'vNavAssembly_Search_BaseCoat_min_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Min_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_BaseCoat_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_BaseCoat>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_BaseCoat". */
export type VNavAssembly_Search_BaseCoat_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_BaseCoat" */
export enum VNavAssembly_Search_BaseCoat_Select_Column {
  /** column name */
  ApplicationRateMinDry = 'ApplicationRateMinDry',
  /** column name */
  ApplicationRateMinWet = 'ApplicationRateMinWet',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Set_Input = {
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_BaseCoat_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_BaseCoat_stddev_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Stddev_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_BaseCoat_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_BaseCoat_stddev_pop_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Stddev_Pop_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_BaseCoat_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_BaseCoat_stddev_samp_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Stddev_Samp_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_BaseCoat_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_BaseCoat_sum_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Sum_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_BaseCoat" */
export enum VNavAssembly_Search_BaseCoat_Update_Column {
  /** column name */
  ApplicationRateMinDry = 'ApplicationRateMinDry',
  /** column name */
  ApplicationRateMinWet = 'ApplicationRateMinWet',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_BaseCoat_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_BaseCoat_var_pop_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Var_Pop_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_BaseCoat_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_BaseCoat_var_samp_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Var_Samp_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_BaseCoat_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_BaseCoat_variance_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_BaseCoat" */
export type VNavAssembly_Search_BaseCoat_Variance_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly = {
  __typename?: 'vNavAssembly_Search_BasePly';
  BackingMaterialId: Scalars['Int'];
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  MultiplyMaterialId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  ReinforcementId: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
  Width?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Aggregate = {
  __typename?: 'vNavAssembly_Search_BasePly_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_BasePly_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_BasePly>;
};

/** aggregate fields of "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_BasePly_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_BasePly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_BasePly_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_BasePly_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_BasePly_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_BasePly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_BasePly_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_BasePly_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_BasePly_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_BasePly_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_BasePly_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_BasePly_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_BasePly_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_BasePly_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_BasePly_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_BasePly_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_BasePly_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_BasePly_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_BasePly_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_BasePly_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_BasePly_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_BasePly_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_BasePly_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_BasePly_avg_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Avg_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_BasePly". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_BasePly_Bool_Exp = {
  BackingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  MultiplyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ReinforcementId?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  Width?: Maybe<Float_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_BasePly_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_BasePly_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_BasePly_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_BasePly_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_BasePly_Update_Column>;
  where?: Maybe<VNavAssembly_Search_BasePly_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Inc_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Insert_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "vNavAssembly_Search_BasePly" */
export enum VNavAssembly_Search_BasePly_Insert_Match_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  Width = 'Width'
}

/** aggregate max on columns */
export type VNavAssembly_Search_BasePly_Max_Fields = {
  __typename?: 'vNavAssembly_Search_BasePly_max_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Max_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_BasePly_Min_Fields = {
  __typename?: 'vNavAssembly_Search_BasePly_min_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Min_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_BasePly_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_BasePly>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_BasePly". */
export type VNavAssembly_Search_BasePly_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_BasePly" */
export enum VNavAssembly_Search_BasePly_Select_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  Width = 'Width'
}

/** input type for updating data in table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Set_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_BasePly_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_BasePly_stddev_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Stddev_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_BasePly_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_BasePly_stddev_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Stddev_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_BasePly_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_BasePly_stddev_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Stddev_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_BasePly_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_BasePly_sum_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Sum_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_BasePly" */
export enum VNavAssembly_Search_BasePly_Update_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  Width = 'Width'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_BasePly_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_BasePly_var_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Var_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_BasePly_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_BasePly_var_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Var_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_BasePly_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_BasePly_variance_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_BasePly" */
export type VNavAssembly_Search_BasePly_Variance_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet = {
  __typename?: 'vNavAssembly_Search_BaseSheet';
  BackingMaterialId: Scalars['Int'];
  ComponentId?: Maybe<Scalars['Int']>;
  IsPermeable: Scalars['Boolean'];
  LapWidth?: Maybe<Scalars['Float']>;
  ManufacturerId: Scalars['Int'];
  MultiplyMaterialId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  ReinforcementId: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
  Width?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Aggregate = {
  __typename?: 'vNavAssembly_Search_BaseSheet_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_BaseSheet_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_BaseSheet>;
};

/** aggregate fields of "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_BaseSheet_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_BaseSheet_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_BaseSheet_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_BaseSheet_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_BaseSheet_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_BaseSheet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_BaseSheet_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_BaseSheet_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_BaseSheet_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_BaseSheet_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_BaseSheet_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_BaseSheet_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_BaseSheet_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_BaseSheet_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_BaseSheet_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_BaseSheet_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_BaseSheet_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_BaseSheet_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_BaseSheet_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_BaseSheet_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_BaseSheet_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_BaseSheet_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_BaseSheet_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_BaseSheet_avg_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LapWidth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Avg_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LapWidth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_BaseSheet". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_BaseSheet_Bool_Exp = {
  BackingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  IsPermeable?: Maybe<Boolean_Mssql_Comparison_Exp>;
  LapWidth?: Maybe<Float_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  MultiplyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ReinforcementId?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  Width?: Maybe<Float_Mssql_Comparison_Exp>;
  WidthMax?: Maybe<Float_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_BaseSheet_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_BaseSheet_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_BaseSheet_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_BaseSheet_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_BaseSheet_Update_Column>;
  where?: Maybe<VNavAssembly_Search_BaseSheet_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Inc_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LapWidth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Insert_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  IsPermeable?: Maybe<Scalars['Boolean']>;
  LapWidth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "vNavAssembly_Search_BaseSheet" */
export enum VNavAssembly_Search_BaseSheet_Insert_Match_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  IsPermeable = 'IsPermeable',
  /** column name */
  LapWidth = 'LapWidth',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  Width = 'Width',
  /** column name */
  WidthMax = 'WidthMax'
}

/** aggregate max on columns */
export type VNavAssembly_Search_BaseSheet_Max_Fields = {
  __typename?: 'vNavAssembly_Search_BaseSheet_max_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LapWidth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Max_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LapWidth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_BaseSheet_Min_Fields = {
  __typename?: 'vNavAssembly_Search_BaseSheet_min_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LapWidth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Min_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LapWidth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_BaseSheet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_BaseSheet>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_BaseSheet". */
export type VNavAssembly_Search_BaseSheet_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  IsPermeable?: Maybe<Order_By>;
  LapWidth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_BaseSheet" */
export enum VNavAssembly_Search_BaseSheet_Select_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  IsPermeable = 'IsPermeable',
  /** column name */
  LapWidth = 'LapWidth',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  Width = 'Width',
  /** column name */
  WidthMax = 'WidthMax'
}

/** input type for updating data in table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Set_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  IsPermeable?: Maybe<Scalars['Boolean']>;
  LapWidth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_BaseSheet_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_BaseSheet_stddev_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LapWidth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Stddev_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LapWidth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_BaseSheet_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_BaseSheet_stddev_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LapWidth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Stddev_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LapWidth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_BaseSheet_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_BaseSheet_stddev_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LapWidth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Stddev_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LapWidth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_BaseSheet_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_BaseSheet_sum_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LapWidth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Sum_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LapWidth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_BaseSheet" */
export enum VNavAssembly_Search_BaseSheet_Update_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  IsPermeable = 'IsPermeable',
  /** column name */
  LapWidth = 'LapWidth',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  Width = 'Width',
  /** column name */
  WidthMax = 'WidthMax'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_BaseSheet_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_BaseSheet_var_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LapWidth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Var_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LapWidth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_BaseSheet_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_BaseSheet_var_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LapWidth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Var_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LapWidth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_BaseSheet_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_BaseSheet_variance_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LapWidth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_BaseSheet" */
export type VNavAssembly_Search_BaseSheet_Variance_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LapWidth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock = {
  __typename?: 'vNavAssembly_Search_BoardStock';
  BoardProfileId: Scalars['Int'];
  BoardStockBoardSizes: Scalars['Int'];
  BoardStockMaterialId: Scalars['Int'];
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Aggregate = {
  __typename?: 'vNavAssembly_Search_BoardStock_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_BoardStock_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_BoardStock>;
};

/** aggregate fields of "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_BoardStock_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_BoardStock_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_BoardStock_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_BoardStock_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_BoardStock_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_BoardStock_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_BoardStock_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_BoardStock_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_BoardStock_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_BoardStock_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_BoardStock_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_BoardStock_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_BoardStock_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_BoardStock_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_BoardStock_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_BoardStock_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_BoardStock_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_BoardStock_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_BoardStock_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_BoardStock_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_BoardStock_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_BoardStock_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_BoardStock_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_BoardStock_avg_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  BoardStockBoardSizes?: Maybe<Scalars['Float']>;
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Avg_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockBoardSizes?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_BoardStock". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_BoardStock_Bool_Exp = {
  BoardProfileId?: Maybe<Int_Mssql_Comparison_Exp>;
  BoardStockBoardSizes?: Maybe<Int_Mssql_Comparison_Exp>;
  BoardStockMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  DensityMin?: Maybe<Float_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<Float_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<Float_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_BoardStock_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_BoardStock_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_BoardStock_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_BoardStock_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_BoardStock_Update_Column>;
  where?: Maybe<VNavAssembly_Search_BoardStock_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Inc_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  BoardStockBoardSizes?: Maybe<Scalars['Int']>;
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Insert_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  BoardStockBoardSizes?: Maybe<Scalars['Int']>;
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_BoardStock" */
export enum VNavAssembly_Search_BoardStock_Insert_Match_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  BoardStockBoardSizes = 'BoardStockBoardSizes',
  /** column name */
  BoardStockMaterialId = 'BoardStockMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_BoardStock_Max_Fields = {
  __typename?: 'vNavAssembly_Search_BoardStock_max_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  BoardStockBoardSizes?: Maybe<Scalars['Int']>;
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Max_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockBoardSizes?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_BoardStock_Min_Fields = {
  __typename?: 'vNavAssembly_Search_BoardStock_min_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  BoardStockBoardSizes?: Maybe<Scalars['Int']>;
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Min_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockBoardSizes?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_BoardStock_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_BoardStock>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_BoardStock". */
export type VNavAssembly_Search_BoardStock_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockBoardSizes?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_BoardStock" */
export enum VNavAssembly_Search_BoardStock_Select_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  BoardStockBoardSizes = 'BoardStockBoardSizes',
  /** column name */
  BoardStockMaterialId = 'BoardStockMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Set_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  BoardStockBoardSizes?: Maybe<Scalars['Int']>;
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_BoardStock_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_BoardStock_stddev_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  BoardStockBoardSizes?: Maybe<Scalars['Float']>;
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Stddev_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockBoardSizes?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_BoardStock_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_BoardStock_stddev_pop_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  BoardStockBoardSizes?: Maybe<Scalars['Float']>;
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Stddev_Pop_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockBoardSizes?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_BoardStock_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_BoardStock_stddev_samp_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  BoardStockBoardSizes?: Maybe<Scalars['Float']>;
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Stddev_Samp_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockBoardSizes?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_BoardStock_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_BoardStock_sum_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  BoardStockBoardSizes?: Maybe<Scalars['Int']>;
  BoardStockMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Sum_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockBoardSizes?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_BoardStock" */
export enum VNavAssembly_Search_BoardStock_Update_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  BoardStockBoardSizes = 'BoardStockBoardSizes',
  /** column name */
  BoardStockMaterialId = 'BoardStockMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_BoardStock_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_BoardStock_var_pop_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  BoardStockBoardSizes?: Maybe<Scalars['Float']>;
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Var_Pop_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockBoardSizes?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_BoardStock_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_BoardStock_var_samp_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  BoardStockBoardSizes?: Maybe<Scalars['Float']>;
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Var_Samp_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockBoardSizes?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_BoardStock_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_BoardStock_variance_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  BoardStockBoardSizes?: Maybe<Scalars['Float']>;
  BoardStockMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_BoardStock" */
export type VNavAssembly_Search_BoardStock_Variance_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  BoardStockBoardSizes?: Maybe<Order_By>;
  BoardStockMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly = {
  __typename?: 'vNavAssembly_Search_CapPly';
  BackingMaterialId: Scalars['Int'];
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  MultiplyMaterialId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  ReinforcementId: Scalars['Int'];
  SurfaceTypeId: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
  Width?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Aggregate = {
  __typename?: 'vNavAssembly_Search_CapPly_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_CapPly_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_CapPly>;
};

/** aggregate fields of "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_CapPly_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_CapPly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_CapPly_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_CapPly_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_CapPly_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_CapPly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_CapPly_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_CapPly_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_CapPly_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_CapPly_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_CapPly_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_CapPly_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_CapPly_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_CapPly_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_CapPly_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_CapPly_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_CapPly_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_CapPly_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_CapPly_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_CapPly_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_CapPly_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_CapPly_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_CapPly_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_CapPly_avg_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SurfaceTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Avg_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_CapPly". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_CapPly_Bool_Exp = {
  BackingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  MultiplyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ReinforcementId?: Maybe<Int_Mssql_Comparison_Exp>;
  SurfaceTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  Width?: Maybe<Float_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_CapPly_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_CapPly_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_CapPly_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_CapPly_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_CapPly_Update_Column>;
  where?: Maybe<VNavAssembly_Search_CapPly_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Inc_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SurfaceTypeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Insert_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SurfaceTypeId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "vNavAssembly_Search_CapPly" */
export enum VNavAssembly_Search_CapPly_Insert_Match_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  SurfaceTypeId = 'SurfaceTypeId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  Width = 'Width'
}

/** aggregate max on columns */
export type VNavAssembly_Search_CapPly_Max_Fields = {
  __typename?: 'vNavAssembly_Search_CapPly_max_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SurfaceTypeId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Max_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_CapPly_Min_Fields = {
  __typename?: 'vNavAssembly_Search_CapPly_min_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SurfaceTypeId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Min_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_CapPly_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_CapPly>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_CapPly". */
export type VNavAssembly_Search_CapPly_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_CapPly" */
export enum VNavAssembly_Search_CapPly_Select_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  SurfaceTypeId = 'SurfaceTypeId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  Width = 'Width'
}

/** input type for updating data in table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Set_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SurfaceTypeId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_CapPly_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_CapPly_stddev_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SurfaceTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Stddev_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_CapPly_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_CapPly_stddev_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SurfaceTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Stddev_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_CapPly_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_CapPly_stddev_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SurfaceTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Stddev_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_CapPly_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_CapPly_sum_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SurfaceTypeId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Sum_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_CapPly" */
export enum VNavAssembly_Search_CapPly_Update_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  SurfaceTypeId = 'SurfaceTypeId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  Width = 'Width'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_CapPly_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_CapPly_var_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SurfaceTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Var_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_CapPly_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_CapPly_var_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SurfaceTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Var_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_CapPly_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_CapPly_variance_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SurfaceTypeId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_CapPly" */
export type VNavAssembly_Search_CapPly_Variance_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SurfaceTypeId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat = {
  __typename?: 'vNavAssembly_Search_Coat';
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId: Scalars['Int'];
  ManufacturerId: Scalars['Int'];
  MinAppRatePerCoatDry?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['Float']>;
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  NumCoats: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Aggregate = {
  __typename?: 'vNavAssembly_Search_Coat_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_Coat_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_Coat>;
};

/** aggregate fields of "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_Coat_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_Coat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_Coat_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_Coat_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_Coat_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_Coat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_Coat_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_Coat_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_Coat_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_Coat_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_Coat_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_Coat_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_Coat_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_Coat_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_Coat_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_Coat_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_Coat_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_Coat_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_Coat_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_Coat_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_Coat_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_Coat_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_Coat_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_Coat_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatDry?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinAppRatePerCoatDry?: Maybe<Order_By>;
  MinAppRatePerCoatWet?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_Coat". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_Coat_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  LiquidAppliedMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  MinAppRatePerCoatDry?: Maybe<Float_Mssql_Comparison_Exp>;
  MinAppRatePerCoatWet?: Maybe<Float_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  NumCoats?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_Coat_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_Coat_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_Coat_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_Coat_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_Coat_Update_Column>;
  where?: Maybe<VNavAssembly_Search_Coat_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MinAppRatePerCoatDry?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MinAppRatePerCoatDry?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_Coat" */
export enum VNavAssembly_Search_Coat_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MinAppRatePerCoatDry = 'MinAppRatePerCoatDry',
  /** column name */
  MinAppRatePerCoatWet = 'MinAppRatePerCoatWet',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_Coat_Max_Fields = {
  __typename?: 'vNavAssembly_Search_Coat_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MinAppRatePerCoatDry?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinAppRatePerCoatDry?: Maybe<Order_By>;
  MinAppRatePerCoatWet?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_Coat_Min_Fields = {
  __typename?: 'vNavAssembly_Search_Coat_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MinAppRatePerCoatDry?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinAppRatePerCoatDry?: Maybe<Order_By>;
  MinAppRatePerCoatWet?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_Coat_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_Coat>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_Coat". */
export type VNavAssembly_Search_Coat_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinAppRatePerCoatDry?: Maybe<Order_By>;
  MinAppRatePerCoatWet?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_Coat" */
export enum VNavAssembly_Search_Coat_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MinAppRatePerCoatDry = 'MinAppRatePerCoatDry',
  /** column name */
  MinAppRatePerCoatWet = 'MinAppRatePerCoatWet',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MinAppRatePerCoatDry?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_Coat_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_Coat_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatDry?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinAppRatePerCoatDry?: Maybe<Order_By>;
  MinAppRatePerCoatWet?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_Coat_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_Coat_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatDry?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinAppRatePerCoatDry?: Maybe<Order_By>;
  MinAppRatePerCoatWet?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_Coat_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_Coat_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatDry?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinAppRatePerCoatDry?: Maybe<Order_By>;
  MinAppRatePerCoatWet?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_Coat_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_Coat_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MinAppRatePerCoatDry?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinAppRatePerCoatDry?: Maybe<Order_By>;
  MinAppRatePerCoatWet?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_Coat" */
export enum VNavAssembly_Search_Coat_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MinAppRatePerCoatDry = 'MinAppRatePerCoatDry',
  /** column name */
  MinAppRatePerCoatWet = 'MinAppRatePerCoatWet',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_Coat_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_Coat_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatDry?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinAppRatePerCoatDry?: Maybe<Order_By>;
  MinAppRatePerCoatWet?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_Coat_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_Coat_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatDry?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinAppRatePerCoatDry?: Maybe<Order_By>;
  MinAppRatePerCoatWet?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_Coat_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_Coat_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatDry?: Maybe<Scalars['Float']>;
  MinAppRatePerCoatWet?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_Coat" */
export type VNavAssembly_Search_Coat_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinAppRatePerCoatDry?: Maybe<Order_By>;
  MinAppRatePerCoatWet?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating = {
  __typename?: 'vNavAssembly_Search_Coating';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  CoatingMaterialId: Scalars['Int'];
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  NumCoats: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Aggregate = {
  __typename?: 'vNavAssembly_Search_Coating_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_Coating_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_Coating>;
};

/** aggregate fields of "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_Coating_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_Coating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_Coating_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_Coating_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_Coating_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_Coating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_Coating_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_Coating_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_Coating_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_Coating_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_Coating_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_Coating_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_Coating_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_Coating_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_Coating_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_Coating_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_Coating_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_Coating_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_Coating_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_Coating_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_Coating_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_Coating_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_Coating_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_Coating_avg_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  CoatingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Avg_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_Coating". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_Coating_Bool_Exp = {
  ApplicationRateDry?: Maybe<Float_Mssql_Comparison_Exp>;
  ApplicationRateWet?: Maybe<Float_Mssql_Comparison_Exp>;
  CoatingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  NumCoats?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_Coating_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_Coating_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_Coating_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_Coating_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_Coating_Update_Column>;
  where?: Maybe<VNavAssembly_Search_Coating_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Inc_Input = {
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  CoatingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Insert_Input = {
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  CoatingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_Coating" */
export enum VNavAssembly_Search_Coating_Insert_Match_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  CoatingMaterialId = 'CoatingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_Coating_Max_Fields = {
  __typename?: 'vNavAssembly_Search_Coating_max_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  CoatingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Max_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_Coating_Min_Fields = {
  __typename?: 'vNavAssembly_Search_Coating_min_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  CoatingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Min_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_Coating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_Coating>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_Coating". */
export type VNavAssembly_Search_Coating_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_Coating" */
export enum VNavAssembly_Search_Coating_Select_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  CoatingMaterialId = 'CoatingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Set_Input = {
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  CoatingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_Coating_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_Coating_stddev_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  CoatingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Stddev_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_Coating_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_Coating_stddev_pop_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  CoatingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Stddev_Pop_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_Coating_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_Coating_stddev_samp_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  CoatingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Stddev_Samp_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_Coating_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_Coating_sum_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  CoatingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Sum_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_Coating" */
export enum VNavAssembly_Search_Coating_Update_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  CoatingMaterialId = 'CoatingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_Coating_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_Coating_var_pop_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  CoatingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Var_Pop_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_Coating_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_Coating_var_samp_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  CoatingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Var_Samp_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_Coating_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_Coating_variance_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  CoatingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_Coating" */
export type VNavAssembly_Search_Coating_Variance_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  CoatingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard = {
  __typename?: 'vNavAssembly_Search_CoverBoard';
  BoardProfileId: Scalars['Int'];
  ComponentId?: Maybe<Scalars['Int']>;
  CoverBoardBoardSizes?: Maybe<Scalars['Int']>;
  CoverBoardMaterialId: Scalars['Int'];
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Aggregate = {
  __typename?: 'vNavAssembly_Search_CoverBoard_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_CoverBoard_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_CoverBoard>;
};

/** aggregate fields of "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_CoverBoard_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_CoverBoard_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_CoverBoard_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_CoverBoard_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_CoverBoard_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_CoverBoard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_CoverBoard_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_CoverBoard_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_CoverBoard_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_CoverBoard_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_CoverBoard_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_CoverBoard_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_CoverBoard_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_CoverBoard_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_CoverBoard_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_CoverBoard_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_CoverBoard_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_CoverBoard_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_CoverBoard_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_CoverBoard_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_CoverBoard_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_CoverBoard_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_CoverBoard_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_CoverBoard_avg_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverBoardBoardSizes?: Maybe<Scalars['Float']>;
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Avg_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardBoardSizes?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_CoverBoard". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_CoverBoard_Bool_Exp = {
  BoardProfileId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  CoverBoardBoardSizes?: Maybe<Int_Mssql_Comparison_Exp>;
  CoverBoardMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  DensityMin?: Maybe<Float_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<Float_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<Float_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_CoverBoard_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_CoverBoard_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_CoverBoard_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_CoverBoard_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_CoverBoard_Update_Column>;
  where?: Maybe<VNavAssembly_Search_CoverBoard_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Inc_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverBoardBoardSizes?: Maybe<Scalars['Int']>;
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Insert_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverBoardBoardSizes?: Maybe<Scalars['Int']>;
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_CoverBoard" */
export enum VNavAssembly_Search_CoverBoard_Insert_Match_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverBoardBoardSizes = 'CoverBoardBoardSizes',
  /** column name */
  CoverBoardMaterialId = 'CoverBoardMaterialId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_CoverBoard_Max_Fields = {
  __typename?: 'vNavAssembly_Search_CoverBoard_max_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverBoardBoardSizes?: Maybe<Scalars['Int']>;
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Max_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardBoardSizes?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_CoverBoard_Min_Fields = {
  __typename?: 'vNavAssembly_Search_CoverBoard_min_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverBoardBoardSizes?: Maybe<Scalars['Int']>;
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Min_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardBoardSizes?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_CoverBoard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_CoverBoard>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_CoverBoard". */
export type VNavAssembly_Search_CoverBoard_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardBoardSizes?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_CoverBoard" */
export enum VNavAssembly_Search_CoverBoard_Select_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverBoardBoardSizes = 'CoverBoardBoardSizes',
  /** column name */
  CoverBoardMaterialId = 'CoverBoardMaterialId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Set_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverBoardBoardSizes?: Maybe<Scalars['Int']>;
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_CoverBoard_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_CoverBoard_stddev_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverBoardBoardSizes?: Maybe<Scalars['Float']>;
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Stddev_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardBoardSizes?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_CoverBoard_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_CoverBoard_stddev_pop_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverBoardBoardSizes?: Maybe<Scalars['Float']>;
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Stddev_Pop_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardBoardSizes?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_CoverBoard_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_CoverBoard_stddev_samp_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverBoardBoardSizes?: Maybe<Scalars['Float']>;
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Stddev_Samp_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardBoardSizes?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_CoverBoard_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_CoverBoard_sum_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverBoardBoardSizes?: Maybe<Scalars['Int']>;
  CoverBoardMaterialId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Sum_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardBoardSizes?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_CoverBoard" */
export enum VNavAssembly_Search_CoverBoard_Update_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverBoardBoardSizes = 'CoverBoardBoardSizes',
  /** column name */
  CoverBoardMaterialId = 'CoverBoardMaterialId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_CoverBoard_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_CoverBoard_var_pop_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverBoardBoardSizes?: Maybe<Scalars['Float']>;
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Var_Pop_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardBoardSizes?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_CoverBoard_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_CoverBoard_var_samp_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverBoardBoardSizes?: Maybe<Scalars['Float']>;
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Var_Samp_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardBoardSizes?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_CoverBoard_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_CoverBoard_variance_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverBoardBoardSizes?: Maybe<Scalars['Float']>;
  CoverBoardMaterialId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_CoverBoard" */
export type VNavAssembly_Search_CoverBoard_Variance_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverBoardBoardSizes?: Maybe<Order_By>;
  CoverBoardMaterialId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel = {
  __typename?: 'vNavAssembly_Search_CoverCompositePanel';
  BottomFacerMaterialId: Scalars['Int'];
  ComponentId?: Maybe<Scalars['Int']>;
  FacerThicknessBottom?: Maybe<Scalars['Float']>;
  FacerThicknessTop?: Maybe<Scalars['Float']>;
  InsulationCoreId: Scalars['Int'];
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TopFacerMaterialId: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Aggregate = {
  __typename?: 'vNavAssembly_Search_CoverCompositePanel_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_CoverCompositePanel_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_CoverCompositePanel>;
};

/** aggregate fields of "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_CoverCompositePanel_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_CoverCompositePanel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_CoverCompositePanel_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_CoverCompositePanel_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_CoverCompositePanel_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_CoverCompositePanel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_CoverCompositePanel_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_CoverCompositePanel_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_CoverCompositePanel_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_CoverCompositePanel_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_CoverCompositePanel_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_CoverCompositePanel_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_CoverCompositePanel_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_CoverCompositePanel_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_CoverCompositePanel_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_CoverCompositePanel_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_CoverCompositePanel_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_CoverCompositePanel_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_CoverCompositePanel_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_CoverCompositePanel_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_CoverCompositePanel_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_CoverCompositePanel_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_CoverCompositePanel_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_CoverCompositePanel_avg_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  FacerThicknessBottom?: Maybe<Scalars['Float']>;
  FacerThicknessTop?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Avg_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerThicknessBottom?: Maybe<Order_By>;
  FacerThicknessTop?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_CoverCompositePanel". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_CoverCompositePanel_Bool_Exp = {
  BottomFacerMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  FacerThicknessBottom?: Maybe<Float_Mssql_Comparison_Exp>;
  FacerThicknessTop?: Maybe<Float_Mssql_Comparison_Exp>;
  InsulationCoreId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<Float_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<Float_Mssql_Comparison_Exp>;
  TopFacerMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  WidthMax?: Maybe<Float_Mssql_Comparison_Exp>;
  WidthMin?: Maybe<Float_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_CoverCompositePanel_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_CoverCompositePanel_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_CoverCompositePanel_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_CoverCompositePanel_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_CoverCompositePanel_Update_Column>;
  where?: Maybe<VNavAssembly_Search_CoverCompositePanel_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Inc_Input = {
  BottomFacerMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  FacerThicknessBottom?: Maybe<Scalars['Float']>;
  FacerThicknessTop?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Insert_Input = {
  BottomFacerMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  FacerThicknessBottom?: Maybe<Scalars['Float']>;
  FacerThicknessTop?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "vNavAssembly_Search_CoverCompositePanel" */
export enum VNavAssembly_Search_CoverCompositePanel_Insert_Match_Column {
  /** column name */
  BottomFacerMaterialId = 'BottomFacerMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerThicknessBottom = 'FacerThicknessBottom',
  /** column name */
  FacerThicknessTop = 'FacerThicknessTop',
  /** column name */
  InsulationCoreId = 'InsulationCoreId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TopFacerMaterialId = 'TopFacerMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** aggregate max on columns */
export type VNavAssembly_Search_CoverCompositePanel_Max_Fields = {
  __typename?: 'vNavAssembly_Search_CoverCompositePanel_max_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  FacerThicknessBottom?: Maybe<Scalars['Float']>;
  FacerThicknessTop?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Max_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerThicknessBottom?: Maybe<Order_By>;
  FacerThicknessTop?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_CoverCompositePanel_Min_Fields = {
  __typename?: 'vNavAssembly_Search_CoverCompositePanel_min_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  FacerThicknessBottom?: Maybe<Scalars['Float']>;
  FacerThicknessTop?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Min_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerThicknessBottom?: Maybe<Order_By>;
  FacerThicknessTop?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_CoverCompositePanel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_CoverCompositePanel>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_CoverCompositePanel". */
export type VNavAssembly_Search_CoverCompositePanel_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerThicknessBottom?: Maybe<Order_By>;
  FacerThicknessTop?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_CoverCompositePanel" */
export enum VNavAssembly_Search_CoverCompositePanel_Select_Column {
  /** column name */
  BottomFacerMaterialId = 'BottomFacerMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerThicknessBottom = 'FacerThicknessBottom',
  /** column name */
  FacerThicknessTop = 'FacerThicknessTop',
  /** column name */
  InsulationCoreId = 'InsulationCoreId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TopFacerMaterialId = 'TopFacerMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** input type for updating data in table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Set_Input = {
  BottomFacerMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  FacerThicknessBottom?: Maybe<Scalars['Float']>;
  FacerThicknessTop?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_CoverCompositePanel_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_CoverCompositePanel_stddev_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  FacerThicknessBottom?: Maybe<Scalars['Float']>;
  FacerThicknessTop?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Stddev_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerThicknessBottom?: Maybe<Order_By>;
  FacerThicknessTop?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_CoverCompositePanel_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_CoverCompositePanel_stddev_pop_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  FacerThicknessBottom?: Maybe<Scalars['Float']>;
  FacerThicknessTop?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Stddev_Pop_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerThicknessBottom?: Maybe<Order_By>;
  FacerThicknessTop?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_CoverCompositePanel_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_CoverCompositePanel_stddev_samp_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  FacerThicknessBottom?: Maybe<Scalars['Float']>;
  FacerThicknessTop?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Stddev_Samp_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerThicknessBottom?: Maybe<Order_By>;
  FacerThicknessTop?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_CoverCompositePanel_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_CoverCompositePanel_sum_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  FacerThicknessBottom?: Maybe<Scalars['Float']>;
  FacerThicknessTop?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Sum_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerThicknessBottom?: Maybe<Order_By>;
  FacerThicknessTop?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_CoverCompositePanel" */
export enum VNavAssembly_Search_CoverCompositePanel_Update_Column {
  /** column name */
  BottomFacerMaterialId = 'BottomFacerMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  FacerThicknessBottom = 'FacerThicknessBottom',
  /** column name */
  FacerThicknessTop = 'FacerThicknessTop',
  /** column name */
  InsulationCoreId = 'InsulationCoreId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TopFacerMaterialId = 'TopFacerMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_CoverCompositePanel_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_CoverCompositePanel_var_pop_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  FacerThicknessBottom?: Maybe<Scalars['Float']>;
  FacerThicknessTop?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Var_Pop_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerThicknessBottom?: Maybe<Order_By>;
  FacerThicknessTop?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_CoverCompositePanel_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_CoverCompositePanel_var_samp_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  FacerThicknessBottom?: Maybe<Scalars['Float']>;
  FacerThicknessTop?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Var_Samp_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerThicknessBottom?: Maybe<Order_By>;
  FacerThicknessTop?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_CoverCompositePanel_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_CoverCompositePanel_variance_fields';
  BottomFacerMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  FacerThicknessBottom?: Maybe<Scalars['Float']>;
  FacerThicknessTop?: Maybe<Scalars['Float']>;
  InsulationCoreId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TopFacerMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_CoverCompositePanel" */
export type VNavAssembly_Search_CoverCompositePanel_Variance_Order_By = {
  BottomFacerMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  FacerThicknessBottom?: Maybe<Order_By>;
  FacerThicknessTop?: Maybe<Order_By>;
  InsulationCoreId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TopFacerMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply = {
  __typename?: 'vNavAssembly_Search_CoverSingleply';
  BackingMaterialId: Scalars['Int'];
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  ReinforcementId: Scalars['Int'];
  SinglePlyMaterialId: Scalars['Int'];
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
  WidthMax?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Aggregate = {
  __typename?: 'vNavAssembly_Search_CoverSingleply_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_CoverSingleply_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_CoverSingleply>;
};

/** aggregate fields of "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_CoverSingleply_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_CoverSingleply_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_CoverSingleply_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_CoverSingleply_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_CoverSingleply_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_CoverSingleply_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_CoverSingleply_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_CoverSingleply_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_CoverSingleply_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_CoverSingleply_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_CoverSingleply_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_CoverSingleply_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_CoverSingleply_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_CoverSingleply_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_CoverSingleply_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_CoverSingleply_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_CoverSingleply_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_CoverSingleply_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_CoverSingleply_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_CoverSingleply_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_CoverSingleply_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_CoverSingleply_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_CoverSingleply_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_CoverSingleply_avg_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Avg_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_CoverSingleply". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_CoverSingleply_Bool_Exp = {
  BackingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ReinforcementId?: Maybe<Int_Mssql_Comparison_Exp>;
  SinglePlyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<Float_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<Float_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  WidthMax?: Maybe<Float_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_CoverSingleply_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_CoverSingleply_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_CoverSingleply_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_CoverSingleply_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_CoverSingleply_Update_Column>;
  where?: Maybe<VNavAssembly_Search_CoverSingleply_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Inc_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Insert_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "vNavAssembly_Search_CoverSingleply" */
export enum VNavAssembly_Search_CoverSingleply_Insert_Match_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  SinglePlyMaterialId = 'SinglePlyMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax'
}

/** aggregate max on columns */
export type VNavAssembly_Search_CoverSingleply_Max_Fields = {
  __typename?: 'vNavAssembly_Search_CoverSingleply_max_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Max_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_CoverSingleply_Min_Fields = {
  __typename?: 'vNavAssembly_Search_CoverSingleply_min_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Min_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_CoverSingleply_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_CoverSingleply>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_CoverSingleply". */
export type VNavAssembly_Search_CoverSingleply_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_CoverSingleply" */
export enum VNavAssembly_Search_CoverSingleply_Select_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  SinglePlyMaterialId = 'SinglePlyMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax'
}

/** input type for updating data in table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Set_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_CoverSingleply_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_CoverSingleply_stddev_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Stddev_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_CoverSingleply_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_CoverSingleply_stddev_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Stddev_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_CoverSingleply_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_CoverSingleply_stddev_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Stddev_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_CoverSingleply_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_CoverSingleply_sum_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Sum_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_CoverSingleply" */
export enum VNavAssembly_Search_CoverSingleply_Update_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  SinglePlyMaterialId = 'SinglePlyMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_CoverSingleply_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_CoverSingleply_var_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Var_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_CoverSingleply_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_CoverSingleply_var_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Var_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_CoverSingleply_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_CoverSingleply_variance_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_CoverSingleply" */
export type VNavAssembly_Search_CoverSingleply_Variance_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam = {
  __typename?: 'vNavAssembly_Search_CoverStandingLapSeam';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  StandingLapSeamMaterialId: Scalars['Int'];
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
  WeightPerUnitAreaNominal?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Aggregate = {
  __typename?: 'vNavAssembly_Search_CoverStandingLapSeam_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_CoverStandingLapSeam>;
};

/** aggregate fields of "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStandingLapSeam_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_CoverStandingLapSeam_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStandingLapSeam_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeightPerUnitAreaNominal?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_CoverStandingLapSeam". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_CoverStandingLapSeam_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  StandingLapSeamMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<Float_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<Float_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  WeightPerUnitAreaNominal?: Maybe<Float_Mssql_Comparison_Exp>;
  WidthMax?: Maybe<Float_Mssql_Comparison_Exp>;
  WidthMin?: Maybe<Float_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_CoverStandingLapSeam_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_CoverStandingLapSeam_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_CoverStandingLapSeam_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_CoverStandingLapSeam_Update_Column>;
  where?: Maybe<VNavAssembly_Search_CoverStandingLapSeam_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "vNavAssembly_Search_CoverStandingLapSeam" */
export enum VNavAssembly_Search_CoverStandingLapSeam_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  StandingLapSeamMaterialId = 'StandingLapSeamMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeightPerUnitAreaNominal = 'WeightPerUnitAreaNominal',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** aggregate max on columns */
export type VNavAssembly_Search_CoverStandingLapSeam_Max_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStandingLapSeam_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeightPerUnitAreaNominal?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_CoverStandingLapSeam_Min_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStandingLapSeam_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeightPerUnitAreaNominal?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_CoverStandingLapSeam_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_CoverStandingLapSeam>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_CoverStandingLapSeam". */
export type VNavAssembly_Search_CoverStandingLapSeam_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeightPerUnitAreaNominal?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_CoverStandingLapSeam" */
export enum VNavAssembly_Search_CoverStandingLapSeam_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  StandingLapSeamMaterialId = 'StandingLapSeamMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeightPerUnitAreaNominal = 'WeightPerUnitAreaNominal',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** input type for updating data in table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_CoverStandingLapSeam_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStandingLapSeam_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeightPerUnitAreaNominal?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_CoverStandingLapSeam_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStandingLapSeam_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeightPerUnitAreaNominal?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_CoverStandingLapSeam_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStandingLapSeam_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeightPerUnitAreaNominal?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_CoverStandingLapSeam_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStandingLapSeam_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeightPerUnitAreaNominal?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_CoverStandingLapSeam" */
export enum VNavAssembly_Search_CoverStandingLapSeam_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  StandingLapSeamMaterialId = 'StandingLapSeamMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WeightPerUnitAreaNominal = 'WeightPerUnitAreaNominal',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_CoverStandingLapSeam_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStandingLapSeam_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeightPerUnitAreaNominal?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_CoverStandingLapSeam_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStandingLapSeam_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeightPerUnitAreaNominal?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_CoverStandingLapSeam_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStandingLapSeam_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  StandingLapSeamMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WeightPerUnitAreaNominal?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_CoverStandingLapSeam" */
export type VNavAssembly_Search_CoverStandingLapSeam_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  StandingLapSeamMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WeightPerUnitAreaNominal?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk = {
  __typename?: 'vNavAssembly_Search_CoverStripDisk';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  SinglePlyMaterialId: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Aggregate = {
  __typename?: 'vNavAssembly_Search_CoverStripDisk_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_CoverStripDisk_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_CoverStripDisk>;
};

/** aggregate fields of "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStripDisk_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_CoverStripDisk_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_CoverStripDisk_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_CoverStripDisk_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_CoverStripDisk_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_CoverStripDisk_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_CoverStripDisk_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_CoverStripDisk_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_CoverStripDisk_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_CoverStripDisk_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_CoverStripDisk_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_CoverStripDisk_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_CoverStripDisk_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_CoverStripDisk_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_CoverStripDisk_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_CoverStripDisk_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_CoverStripDisk_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_CoverStripDisk_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_CoverStripDisk_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_CoverStripDisk_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_CoverStripDisk_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_CoverStripDisk_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_CoverStripDisk_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStripDisk_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_CoverStripDisk". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_CoverStripDisk_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  SinglePlyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_CoverStripDisk_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_CoverStripDisk_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_CoverStripDisk_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_CoverStripDisk_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_CoverStripDisk_Update_Column>;
  where?: Maybe<VNavAssembly_Search_CoverStripDisk_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_CoverStripDisk" */
export enum VNavAssembly_Search_CoverStripDisk_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SinglePlyMaterialId = 'SinglePlyMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_CoverStripDisk_Max_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStripDisk_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_CoverStripDisk_Min_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStripDisk_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_CoverStripDisk_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_CoverStripDisk>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_CoverStripDisk". */
export type VNavAssembly_Search_CoverStripDisk_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_CoverStripDisk" */
export enum VNavAssembly_Search_CoverStripDisk_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SinglePlyMaterialId = 'SinglePlyMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_CoverStripDisk_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStripDisk_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_CoverStripDisk_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStripDisk_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_CoverStripDisk_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStripDisk_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_CoverStripDisk_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStripDisk_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SinglePlyMaterialId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_CoverStripDisk" */
export enum VNavAssembly_Search_CoverStripDisk_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SinglePlyMaterialId = 'SinglePlyMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_CoverStripDisk_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStripDisk_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_CoverStripDisk_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStripDisk_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_CoverStripDisk_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_CoverStripDisk_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SinglePlyMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_CoverStripDisk" */
export type VNavAssembly_Search_CoverStripDisk_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SinglePlyMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel = {
  __typename?: 'vNavAssembly_Search_DeckCementitiousPanel';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Aggregate = {
  __typename?: 'vNavAssembly_Search_DeckCementitiousPanel_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_DeckCementitiousPanel>;
};

/** aggregate fields of "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_DeckCementitiousPanel_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_DeckCementitiousPanel_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_DeckCementitiousPanel_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_DeckCementitiousPanel". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_DeckCementitiousPanel_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  SpanMax?: Maybe<Float_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<Float_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<Float_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_DeckCementitiousPanel_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_DeckCementitiousPanel_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_DeckCementitiousPanel_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_DeckCementitiousPanel_Update_Column>;
  where?: Maybe<VNavAssembly_Search_DeckCementitiousPanel_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_DeckCementitiousPanel" */
export enum VNavAssembly_Search_DeckCementitiousPanel_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_DeckCementitiousPanel_Max_Fields = {
  __typename?: 'vNavAssembly_Search_DeckCementitiousPanel_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_DeckCementitiousPanel_Min_Fields = {
  __typename?: 'vNavAssembly_Search_DeckCementitiousPanel_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_DeckCementitiousPanel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_DeckCementitiousPanel>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_DeckCementitiousPanel". */
export type VNavAssembly_Search_DeckCementitiousPanel_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_DeckCementitiousPanel" */
export enum VNavAssembly_Search_DeckCementitiousPanel_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_DeckCementitiousPanel_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_DeckCementitiousPanel_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_DeckCementitiousPanel_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_DeckCementitiousPanel_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_DeckCementitiousPanel_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_DeckCementitiousPanel_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_DeckCementitiousPanel_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_DeckCementitiousPanel_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_DeckCementitiousPanel" */
export enum VNavAssembly_Search_DeckCementitiousPanel_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_DeckCementitiousPanel_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_DeckCementitiousPanel_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_DeckCementitiousPanel_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_DeckCementitiousPanel_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_DeckCementitiousPanel_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_DeckCementitiousPanel_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_DeckCementitiousPanel" */
export type VNavAssembly_Search_DeckCementitiousPanel_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel = {
  __typename?: 'vNavAssembly_Search_DeckSteel';
  ComponentId?: Maybe<Scalars['Int']>;
  DepthMax?: Maybe<Scalars['Float']>;
  DepthMin?: Maybe<Scalars['Float']>;
  DesignThickness?: Maybe<Scalars['Float']>;
  GradeMin?: Maybe<Scalars['Float']>;
  IsAcoustical: Scalars['Boolean'];
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  SpanMax?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId: Scalars['Int'];
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Aggregate = {
  __typename?: 'vNavAssembly_Search_DeckSteel_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_DeckSteel_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_DeckSteel>;
};

/** aggregate fields of "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_DeckSteel_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_DeckSteel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_DeckSteel_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_DeckSteel_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_DeckSteel_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_DeckSteel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_DeckSteel_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_DeckSteel_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_DeckSteel_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_DeckSteel_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_DeckSteel_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_DeckSteel_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_DeckSteel_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_DeckSteel_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_DeckSteel_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_DeckSteel_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_DeckSteel_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_DeckSteel_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_DeckSteel_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_DeckSteel_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_DeckSteel_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_DeckSteel_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_DeckSteel_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_DeckSteel_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  DepthMax?: Maybe<Scalars['Float']>;
  DepthMin?: Maybe<Scalars['Float']>;
  DesignThickness?: Maybe<Scalars['Float']>;
  GradeMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DepthMax?: Maybe<Order_By>;
  DepthMin?: Maybe<Order_By>;
  DesignThickness?: Maybe<Order_By>;
  GradeMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_DeckSteel". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_DeckSteel_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  DepthMax?: Maybe<Float_Mssql_Comparison_Exp>;
  DepthMin?: Maybe<Float_Mssql_Comparison_Exp>;
  DesignThickness?: Maybe<Float_Mssql_Comparison_Exp>;
  GradeMin?: Maybe<Float_Mssql_Comparison_Exp>;
  IsAcoustical?: Maybe<Boolean_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  SpanMax?: Maybe<Float_Mssql_Comparison_Exp>;
  SteelDeckRibTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<Float_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  WidthMax?: Maybe<Float_Mssql_Comparison_Exp>;
  WidthMin?: Maybe<Float_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_DeckSteel_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_DeckSteel_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_DeckSteel_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_DeckSteel_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_DeckSteel_Update_Column>;
  where?: Maybe<VNavAssembly_Search_DeckSteel_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DepthMax?: Maybe<Scalars['Float']>;
  DepthMin?: Maybe<Scalars['Float']>;
  DesignThickness?: Maybe<Scalars['Float']>;
  GradeMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DepthMax?: Maybe<Scalars['Float']>;
  DepthMin?: Maybe<Scalars['Float']>;
  DesignThickness?: Maybe<Scalars['Float']>;
  GradeMin?: Maybe<Scalars['Float']>;
  IsAcoustical?: Maybe<Scalars['Boolean']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "vNavAssembly_Search_DeckSteel" */
export enum VNavAssembly_Search_DeckSteel_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DepthMax = 'DepthMax',
  /** column name */
  DepthMin = 'DepthMin',
  /** column name */
  DesignThickness = 'DesignThickness',
  /** column name */
  GradeMin = 'GradeMin',
  /** column name */
  IsAcoustical = 'IsAcoustical',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  SteelDeckRibTypeId = 'SteelDeckRibTypeId',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** aggregate max on columns */
export type VNavAssembly_Search_DeckSteel_Max_Fields = {
  __typename?: 'vNavAssembly_Search_DeckSteel_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DepthMax?: Maybe<Scalars['Float']>;
  DepthMin?: Maybe<Scalars['Float']>;
  DesignThickness?: Maybe<Scalars['Float']>;
  GradeMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DepthMax?: Maybe<Order_By>;
  DepthMin?: Maybe<Order_By>;
  DesignThickness?: Maybe<Order_By>;
  GradeMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_DeckSteel_Min_Fields = {
  __typename?: 'vNavAssembly_Search_DeckSteel_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DepthMax?: Maybe<Scalars['Float']>;
  DepthMin?: Maybe<Scalars['Float']>;
  DesignThickness?: Maybe<Scalars['Float']>;
  GradeMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DepthMax?: Maybe<Order_By>;
  DepthMin?: Maybe<Order_By>;
  DesignThickness?: Maybe<Order_By>;
  GradeMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_DeckSteel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_DeckSteel>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_DeckSteel". */
export type VNavAssembly_Search_DeckSteel_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DepthMax?: Maybe<Order_By>;
  DepthMin?: Maybe<Order_By>;
  DesignThickness?: Maybe<Order_By>;
  GradeMin?: Maybe<Order_By>;
  IsAcoustical?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_DeckSteel" */
export enum VNavAssembly_Search_DeckSteel_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DepthMax = 'DepthMax',
  /** column name */
  DepthMin = 'DepthMin',
  /** column name */
  DesignThickness = 'DesignThickness',
  /** column name */
  GradeMin = 'GradeMin',
  /** column name */
  IsAcoustical = 'IsAcoustical',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  SteelDeckRibTypeId = 'SteelDeckRibTypeId',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** input type for updating data in table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DepthMax?: Maybe<Scalars['Float']>;
  DepthMin?: Maybe<Scalars['Float']>;
  DesignThickness?: Maybe<Scalars['Float']>;
  GradeMin?: Maybe<Scalars['Float']>;
  IsAcoustical?: Maybe<Scalars['Boolean']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_DeckSteel_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_DeckSteel_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  DepthMax?: Maybe<Scalars['Float']>;
  DepthMin?: Maybe<Scalars['Float']>;
  DesignThickness?: Maybe<Scalars['Float']>;
  GradeMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DepthMax?: Maybe<Order_By>;
  DepthMin?: Maybe<Order_By>;
  DesignThickness?: Maybe<Order_By>;
  GradeMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_DeckSteel_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_DeckSteel_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  DepthMax?: Maybe<Scalars['Float']>;
  DepthMin?: Maybe<Scalars['Float']>;
  DesignThickness?: Maybe<Scalars['Float']>;
  GradeMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DepthMax?: Maybe<Order_By>;
  DepthMin?: Maybe<Order_By>;
  DesignThickness?: Maybe<Order_By>;
  GradeMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_DeckSteel_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_DeckSteel_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  DepthMax?: Maybe<Scalars['Float']>;
  DepthMin?: Maybe<Scalars['Float']>;
  DesignThickness?: Maybe<Scalars['Float']>;
  GradeMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DepthMax?: Maybe<Order_By>;
  DepthMin?: Maybe<Order_By>;
  DesignThickness?: Maybe<Order_By>;
  GradeMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_DeckSteel_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_DeckSteel_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DepthMax?: Maybe<Scalars['Float']>;
  DepthMin?: Maybe<Scalars['Float']>;
  DesignThickness?: Maybe<Scalars['Float']>;
  GradeMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DepthMax?: Maybe<Order_By>;
  DepthMin?: Maybe<Order_By>;
  DesignThickness?: Maybe<Order_By>;
  GradeMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_DeckSteel" */
export enum VNavAssembly_Search_DeckSteel_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DepthMax = 'DepthMax',
  /** column name */
  DepthMin = 'DepthMin',
  /** column name */
  DesignThickness = 'DesignThickness',
  /** column name */
  GradeMin = 'GradeMin',
  /** column name */
  IsAcoustical = 'IsAcoustical',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  SteelDeckRibTypeId = 'SteelDeckRibTypeId',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  WidthMax = 'WidthMax',
  /** column name */
  WidthMin = 'WidthMin'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_DeckSteel_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_DeckSteel_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  DepthMax?: Maybe<Scalars['Float']>;
  DepthMin?: Maybe<Scalars['Float']>;
  DesignThickness?: Maybe<Scalars['Float']>;
  GradeMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DepthMax?: Maybe<Order_By>;
  DepthMin?: Maybe<Order_By>;
  DesignThickness?: Maybe<Order_By>;
  GradeMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_DeckSteel_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_DeckSteel_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  DepthMax?: Maybe<Scalars['Float']>;
  DepthMin?: Maybe<Scalars['Float']>;
  DesignThickness?: Maybe<Scalars['Float']>;
  GradeMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DepthMax?: Maybe<Order_By>;
  DepthMin?: Maybe<Order_By>;
  DesignThickness?: Maybe<Order_By>;
  GradeMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_DeckSteel_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_DeckSteel_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  DepthMax?: Maybe<Scalars['Float']>;
  DepthMin?: Maybe<Scalars['Float']>;
  DesignThickness?: Maybe<Scalars['Float']>;
  GradeMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  SteelDeckRibTypeId?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  WidthMax?: Maybe<Scalars['Float']>;
  WidthMin?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_DeckSteel" */
export type VNavAssembly_Search_DeckSteel_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DepthMax?: Maybe<Order_By>;
  DepthMin?: Maybe<Order_By>;
  DesignThickness?: Maybe<Order_By>;
  GradeMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  SteelDeckRibTypeId?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  WidthMax?: Maybe<Order_By>;
  WidthMin?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck = {
  __typename?: 'vNavAssembly_Search_FormDeck';
  ComponentId?: Maybe<Scalars['Int']>;
  Depth?: Maybe<Scalars['Float']>;
  IsVented: Scalars['Boolean'];
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Aggregate = {
  __typename?: 'vNavAssembly_Search_FormDeck_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_FormDeck_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_FormDeck>;
};

/** aggregate fields of "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_FormDeck_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_FormDeck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_FormDeck_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_FormDeck_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_FormDeck_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_FormDeck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_FormDeck_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_FormDeck_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_FormDeck_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_FormDeck_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_FormDeck_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_FormDeck_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_FormDeck_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_FormDeck_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_FormDeck_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_FormDeck_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_FormDeck_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_FormDeck_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_FormDeck_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_FormDeck_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_FormDeck_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_FormDeck_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_FormDeck_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_FormDeck_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  Depth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Depth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_FormDeck". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_FormDeck_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  Depth?: Maybe<Float_Mssql_Comparison_Exp>;
  IsVented?: Maybe<Boolean_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  SpanMax?: Maybe<Float_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<Float_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_FormDeck_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_FormDeck_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_FormDeck_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_FormDeck_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_FormDeck_Update_Column>;
  where?: Maybe<VNavAssembly_Search_FormDeck_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  Depth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  Depth?: Maybe<Scalars['Float']>;
  IsVented?: Maybe<Scalars['Boolean']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_FormDeck" */
export enum VNavAssembly_Search_FormDeck_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  Depth = 'Depth',
  /** column name */
  IsVented = 'IsVented',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_FormDeck_Max_Fields = {
  __typename?: 'vNavAssembly_Search_FormDeck_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  Depth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Depth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_FormDeck_Min_Fields = {
  __typename?: 'vNavAssembly_Search_FormDeck_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  Depth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Depth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_FormDeck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_FormDeck>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_FormDeck". */
export type VNavAssembly_Search_FormDeck_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Depth?: Maybe<Order_By>;
  IsVented?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_FormDeck" */
export enum VNavAssembly_Search_FormDeck_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  Depth = 'Depth',
  /** column name */
  IsVented = 'IsVented',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  Depth?: Maybe<Scalars['Float']>;
  IsVented?: Maybe<Scalars['Boolean']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_FormDeck_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_FormDeck_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  Depth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Depth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_FormDeck_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_FormDeck_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  Depth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Depth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_FormDeck_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_FormDeck_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  Depth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Depth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_FormDeck_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_FormDeck_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  Depth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Depth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_FormDeck" */
export enum VNavAssembly_Search_FormDeck_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  Depth = 'Depth',
  /** column name */
  IsVented = 'IsVented',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SpanMax = 'SpanMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_FormDeck_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_FormDeck_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  Depth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Depth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_FormDeck_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_FormDeck_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  Depth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Depth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_FormDeck_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_FormDeck_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  Depth?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SpanMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_FormDeck" */
export type VNavAssembly_Search_FormDeck_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  Depth?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SpanMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket = {
  __typename?: 'vNavAssembly_Search_InsulationBattBlanket';
  BattBlanketFacerMaterialId: Scalars['Int'];
  BattBlanketMaterialId: Scalars['Int'];
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Aggregate = {
  __typename?: 'vNavAssembly_Search_InsulationBattBlanket_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_InsulationBattBlanket>;
};

/** aggregate fields of "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationBattBlanket_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_InsulationBattBlanket_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationBattBlanket_avg_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Avg_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_InsulationBattBlanket". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_InsulationBattBlanket_Bool_Exp = {
  BattBlanketFacerMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  BattBlanketMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<Float_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<Float_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_InsulationBattBlanket_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_InsulationBattBlanket_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_InsulationBattBlanket_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_InsulationBattBlanket_Update_Column>;
  where?: Maybe<VNavAssembly_Search_InsulationBattBlanket_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Inc_Input = {
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Insert_Input = {
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_InsulationBattBlanket" */
export enum VNavAssembly_Search_InsulationBattBlanket_Insert_Match_Column {
  /** column name */
  BattBlanketFacerMaterialId = 'BattBlanketFacerMaterialId',
  /** column name */
  BattBlanketMaterialId = 'BattBlanketMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_InsulationBattBlanket_Max_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationBattBlanket_max_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Max_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_InsulationBattBlanket_Min_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationBattBlanket_min_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Min_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_InsulationBattBlanket_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_InsulationBattBlanket>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_InsulationBattBlanket". */
export type VNavAssembly_Search_InsulationBattBlanket_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_InsulationBattBlanket" */
export enum VNavAssembly_Search_InsulationBattBlanket_Select_Column {
  /** column name */
  BattBlanketFacerMaterialId = 'BattBlanketFacerMaterialId',
  /** column name */
  BattBlanketMaterialId = 'BattBlanketMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Set_Input = {
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_InsulationBattBlanket_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationBattBlanket_stddev_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Stddev_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_InsulationBattBlanket_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationBattBlanket_stddev_pop_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Stddev_Pop_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_InsulationBattBlanket_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationBattBlanket_stddev_samp_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Stddev_Samp_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_InsulationBattBlanket_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationBattBlanket_sum_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Int']>;
  BattBlanketMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Sum_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_InsulationBattBlanket" */
export enum VNavAssembly_Search_InsulationBattBlanket_Update_Column {
  /** column name */
  BattBlanketFacerMaterialId = 'BattBlanketFacerMaterialId',
  /** column name */
  BattBlanketMaterialId = 'BattBlanketMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_InsulationBattBlanket_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationBattBlanket_var_pop_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Var_Pop_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_InsulationBattBlanket_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationBattBlanket_var_samp_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Var_Samp_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_InsulationBattBlanket_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationBattBlanket_variance_fields';
  BattBlanketFacerMaterialId?: Maybe<Scalars['Float']>;
  BattBlanketMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_InsulationBattBlanket" */
export type VNavAssembly_Search_InsulationBattBlanket_Variance_Order_By = {
  BattBlanketFacerMaterialId?: Maybe<Order_By>;
  BattBlanketMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam = {
  __typename?: 'vNavAssembly_Search_InsulationSprayFoam';
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMax?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId: Scalars['Int'];
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Aggregate = {
  __typename?: 'vNavAssembly_Search_InsulationSprayFoam_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_InsulationSprayFoam>;
};

/** aggregate fields of "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationSprayFoam_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_InsulationSprayFoam_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationSprayFoam_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMax?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DensityMax?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_InsulationSprayFoam". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_InsulationSprayFoam_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  DensityMax?: Maybe<Float_Mssql_Comparison_Exp>;
  DensityMin?: Maybe<Float_Mssql_Comparison_Exp>;
  InsulationFoamTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<Float_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<Float_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_InsulationSprayFoam_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_InsulationSprayFoam_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_InsulationSprayFoam_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_InsulationSprayFoam_Update_Column>;
  where?: Maybe<VNavAssembly_Search_InsulationSprayFoam_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMax?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMax?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_InsulationSprayFoam" */
export enum VNavAssembly_Search_InsulationSprayFoam_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMax = 'DensityMax',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  InsulationFoamTypeId = 'InsulationFoamTypeId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_InsulationSprayFoam_Max_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationSprayFoam_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMax?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DensityMax?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_InsulationSprayFoam_Min_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationSprayFoam_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMax?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DensityMax?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_InsulationSprayFoam_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_InsulationSprayFoam>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_InsulationSprayFoam". */
export type VNavAssembly_Search_InsulationSprayFoam_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DensityMax?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_InsulationSprayFoam" */
export enum VNavAssembly_Search_InsulationSprayFoam_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMax = 'DensityMax',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  InsulationFoamTypeId = 'InsulationFoamTypeId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMax?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_InsulationSprayFoam_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationSprayFoam_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMax?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DensityMax?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_InsulationSprayFoam_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationSprayFoam_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMax?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DensityMax?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_InsulationSprayFoam_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationSprayFoam_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMax?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DensityMax?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_InsulationSprayFoam_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationSprayFoam_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMax?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DensityMax?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_InsulationSprayFoam" */
export enum VNavAssembly_Search_InsulationSprayFoam_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMax = 'DensityMax',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  InsulationFoamTypeId = 'InsulationFoamTypeId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_InsulationSprayFoam_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationSprayFoam_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMax?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DensityMax?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_InsulationSprayFoam_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationSprayFoam_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMax?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DensityMax?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_InsulationSprayFoam_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_InsulationSprayFoam_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMax?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  InsulationFoamTypeId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_InsulationSprayFoam" */
export type VNavAssembly_Search_InsulationSprayFoam_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  DensityMax?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  InsulationFoamTypeId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete = {
  __typename?: 'vNavAssembly_Search_LightweightInsulatingConcrete';
  AggregateId: Scalars['Int'];
  CastDensityMin?: Maybe<Scalars['Float']>;
  CementAggregateRatioId: Scalars['Int'];
  ComponentId?: Maybe<Scalars['Int']>;
  DryDensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId: Scalars['Int'];
  MinCompressiveStrength?: Maybe<Scalars['Float']>;
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Aggregate = {
  __typename?: 'vNavAssembly_Search_LightweightInsulatingConcrete_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_LightweightInsulatingConcrete>;
};

/** aggregate fields of "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_LightweightInsulatingConcrete_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_LightweightInsulatingConcrete_avg_fields';
  AggregateId?: Maybe<Scalars['Float']>;
  CastDensityMin?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DryDensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MinCompressiveStrength?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Avg_Order_By = {
  AggregateId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinCompressiveStrength?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_LightweightInsulatingConcrete". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Bool_Exp = {
  AggregateId?: Maybe<Int_Mssql_Comparison_Exp>;
  CastDensityMin?: Maybe<Float_Mssql_Comparison_Exp>;
  CementAggregateRatioId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  DryDensityMin?: Maybe<Float_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  MinCompressiveStrength?: Maybe<Float_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<Float_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_LightweightInsulatingConcrete_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_LightweightInsulatingConcrete_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_LightweightInsulatingConcrete_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_LightweightInsulatingConcrete_Update_Column>;
  where?: Maybe<VNavAssembly_Search_LightweightInsulatingConcrete_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Inc_Input = {
  AggregateId?: Maybe<Scalars['Int']>;
  CastDensityMin?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DryDensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MinCompressiveStrength?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Insert_Input = {
  AggregateId?: Maybe<Scalars['Int']>;
  CastDensityMin?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DryDensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MinCompressiveStrength?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export enum VNavAssembly_Search_LightweightInsulatingConcrete_Insert_Match_Column {
  /** column name */
  AggregateId = 'AggregateId',
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  CementAggregateRatioId = 'CementAggregateRatioId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MinCompressiveStrength = 'MinCompressiveStrength',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Max_Fields = {
  __typename?: 'vNavAssembly_Search_LightweightInsulatingConcrete_max_fields';
  AggregateId?: Maybe<Scalars['Int']>;
  CastDensityMin?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DryDensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MinCompressiveStrength?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Max_Order_By = {
  AggregateId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinCompressiveStrength?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Min_Fields = {
  __typename?: 'vNavAssembly_Search_LightweightInsulatingConcrete_min_fields';
  AggregateId?: Maybe<Scalars['Int']>;
  CastDensityMin?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DryDensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MinCompressiveStrength?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Min_Order_By = {
  AggregateId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinCompressiveStrength?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_LightweightInsulatingConcrete_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_LightweightInsulatingConcrete>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_LightweightInsulatingConcrete". */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Order_By = {
  AggregateId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinCompressiveStrength?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export enum VNavAssembly_Search_LightweightInsulatingConcrete_Select_Column {
  /** column name */
  AggregateId = 'AggregateId',
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  CementAggregateRatioId = 'CementAggregateRatioId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MinCompressiveStrength = 'MinCompressiveStrength',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Set_Input = {
  AggregateId?: Maybe<Scalars['Int']>;
  CastDensityMin?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DryDensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MinCompressiveStrength?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_LightweightInsulatingConcrete_stddev_fields';
  AggregateId?: Maybe<Scalars['Float']>;
  CastDensityMin?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DryDensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MinCompressiveStrength?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Stddev_Order_By = {
  AggregateId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinCompressiveStrength?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_LightweightInsulatingConcrete_stddev_pop_fields';
  AggregateId?: Maybe<Scalars['Float']>;
  CastDensityMin?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DryDensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MinCompressiveStrength?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Stddev_Pop_Order_By = {
  AggregateId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinCompressiveStrength?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_LightweightInsulatingConcrete_stddev_samp_fields';
  AggregateId?: Maybe<Scalars['Float']>;
  CastDensityMin?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DryDensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MinCompressiveStrength?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Stddev_Samp_Order_By = {
  AggregateId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinCompressiveStrength?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_LightweightInsulatingConcrete_sum_fields';
  AggregateId?: Maybe<Scalars['Int']>;
  CastDensityMin?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DryDensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MinCompressiveStrength?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Sum_Order_By = {
  AggregateId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinCompressiveStrength?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export enum VNavAssembly_Search_LightweightInsulatingConcrete_Update_Column {
  /** column name */
  AggregateId = 'AggregateId',
  /** column name */
  CastDensityMin = 'CastDensityMin',
  /** column name */
  CementAggregateRatioId = 'CementAggregateRatioId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DryDensityMin = 'DryDensityMin',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MinCompressiveStrength = 'MinCompressiveStrength',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_LightweightInsulatingConcrete_var_pop_fields';
  AggregateId?: Maybe<Scalars['Float']>;
  CastDensityMin?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DryDensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MinCompressiveStrength?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Var_Pop_Order_By = {
  AggregateId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinCompressiveStrength?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_LightweightInsulatingConcrete_var_samp_fields';
  AggregateId?: Maybe<Scalars['Float']>;
  CastDensityMin?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DryDensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MinCompressiveStrength?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Var_Samp_Order_By = {
  AggregateId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinCompressiveStrength?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_LightweightInsulatingConcrete_variance_fields';
  AggregateId?: Maybe<Scalars['Float']>;
  CastDensityMin?: Maybe<Scalars['Float']>;
  CementAggregateRatioId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DryDensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MinCompressiveStrength?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_LightweightInsulatingConcrete" */
export type VNavAssembly_Search_LightweightInsulatingConcrete_Variance_Order_By = {
  AggregateId?: Maybe<Order_By>;
  CastDensityMin?: Maybe<Order_By>;
  CementAggregateRatioId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DryDensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MinCompressiveStrength?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel = {
  __typename?: 'vNavAssembly_Search_LinerPanel';
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId: Scalars['Int'];
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Aggregate = {
  __typename?: 'vNavAssembly_Search_LinerPanel_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_LinerPanel_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_LinerPanel>;
};

/** aggregate fields of "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_LinerPanel_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_LinerPanel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_LinerPanel_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_LinerPanel_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_LinerPanel_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_LinerPanel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_LinerPanel_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_LinerPanel_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_LinerPanel_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_LinerPanel_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_LinerPanel_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_LinerPanel_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_LinerPanel_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_LinerPanel_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_LinerPanel_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_LinerPanel_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_LinerPanel_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_LinerPanel_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_LinerPanel_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_LinerPanel_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_LinerPanel_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_LinerPanel_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_LinerPanel_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_LinerPanel_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_LinerPanel". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_LinerPanel_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  LinerPanelMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_LinerPanel_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_LinerPanel_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_LinerPanel_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_LinerPanel_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_LinerPanel_Update_Column>;
  where?: Maybe<VNavAssembly_Search_LinerPanel_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_LinerPanel" */
export enum VNavAssembly_Search_LinerPanel_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LinerPanelMaterialId = 'LinerPanelMaterialId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_LinerPanel_Max_Fields = {
  __typename?: 'vNavAssembly_Search_LinerPanel_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_LinerPanel_Min_Fields = {
  __typename?: 'vNavAssembly_Search_LinerPanel_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_LinerPanel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_LinerPanel>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_LinerPanel". */
export type VNavAssembly_Search_LinerPanel_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_LinerPanel" */
export enum VNavAssembly_Search_LinerPanel_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LinerPanelMaterialId = 'LinerPanelMaterialId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_LinerPanel_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_LinerPanel_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_LinerPanel_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_LinerPanel_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_LinerPanel_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_LinerPanel_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_LinerPanel_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_LinerPanel_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  LinerPanelMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_LinerPanel" */
export enum VNavAssembly_Search_LinerPanel_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LinerPanelMaterialId = 'LinerPanelMaterialId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_LinerPanel_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_LinerPanel_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_LinerPanel_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_LinerPanel_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_LinerPanel_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_LinerPanel_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  LinerPanelMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_LinerPanel" */
export type VNavAssembly_Search_LinerPanel_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  LinerPanelMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply = {
  __typename?: 'vNavAssembly_Search_Ply';
  BackingMaterialId: Scalars['Int'];
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  MultiplyMaterialId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  NumPlies?: Maybe<Scalars['String']>;
  ReinforcementId: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
  Width?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Aggregate = {
  __typename?: 'vNavAssembly_Search_Ply_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_Ply_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_Ply>;
};

/** aggregate fields of "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_Ply_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_Ply_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_Ply_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_Ply_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_Ply_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_Ply_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_Ply_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_Ply_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_Ply_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_Ply_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_Ply_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_Ply_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_Ply_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_Ply_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_Ply_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_Ply_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_Ply_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_Ply_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_Ply_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_Ply_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_Ply_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_Ply_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_Ply_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_Ply_avg_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Avg_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_Ply". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_Ply_Bool_Exp = {
  BackingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  MultiplyMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  NumPlies?: Maybe<String_Mssql_Comparison_Exp>;
  ReinforcementId?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  Width?: Maybe<Float_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_Ply_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_Ply_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_Ply_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_Ply_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_Ply_Update_Column>;
  where?: Maybe<VNavAssembly_Search_Ply_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Inc_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Insert_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['String']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "vNavAssembly_Search_Ply" */
export enum VNavAssembly_Search_Ply_Insert_Match_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  Width = 'Width'
}

/** aggregate max on columns */
export type VNavAssembly_Search_Ply_Max_Fields = {
  __typename?: 'vNavAssembly_Search_Ply_max_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['String']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Max_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_Ply_Min_Fields = {
  __typename?: 'vNavAssembly_Search_Ply_min_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['String']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Min_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_Ply_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_Ply>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_Ply". */
export type VNavAssembly_Search_Ply_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_Ply" */
export enum VNavAssembly_Search_Ply_Select_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  Width = 'Width'
}

/** input type for updating data in table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Set_Input = {
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['String']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_Ply_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_Ply_stddev_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Stddev_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_Ply_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_Ply_stddev_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Stddev_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_Ply_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_Ply_stddev_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Stddev_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_Ply_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_Ply_sum_fields';
  BackingMaterialId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  MultiplyMaterialId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ReinforcementId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Sum_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_Ply" */
export enum VNavAssembly_Search_Ply_Update_Column {
  /** column name */
  BackingMaterialId = 'BackingMaterialId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  MultiplyMaterialId = 'MultiplyMaterialId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  ReinforcementId = 'ReinforcementId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId',
  /** column name */
  Width = 'Width'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_Ply_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_Ply_var_pop_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Var_Pop_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_Ply_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_Ply_var_samp_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Var_Samp_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_Ply_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_Ply_variance_fields';
  BackingMaterialId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  MultiplyMaterialId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ReinforcementId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_Ply" */
export type VNavAssembly_Search_Ply_Variance_Order_By = {
  BackingMaterialId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  MultiplyMaterialId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ReinforcementId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
  Width?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet = {
  __typename?: 'vNavAssembly_Search_SeparatorSheet';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  RetarderMaterialId: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Aggregate = {
  __typename?: 'vNavAssembly_Search_SeparatorSheet_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_SeparatorSheet_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_SeparatorSheet>;
};

/** aggregate fields of "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_SeparatorSheet_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_SeparatorSheet_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_SeparatorSheet_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_SeparatorSheet_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_SeparatorSheet_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_SeparatorSheet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_SeparatorSheet_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_SeparatorSheet_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_SeparatorSheet_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_SeparatorSheet_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_SeparatorSheet_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_SeparatorSheet_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_SeparatorSheet_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_SeparatorSheet_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_SeparatorSheet_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_SeparatorSheet_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_SeparatorSheet_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_SeparatorSheet_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_SeparatorSheet_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_SeparatorSheet_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_SeparatorSheet_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_SeparatorSheet_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_SeparatorSheet_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_SeparatorSheet_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_SeparatorSheet". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_SeparatorSheet_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  RetarderMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_SeparatorSheet_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_SeparatorSheet_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_SeparatorSheet_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_SeparatorSheet_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_SeparatorSheet_Update_Column>;
  where?: Maybe<VNavAssembly_Search_SeparatorSheet_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_SeparatorSheet" */
export enum VNavAssembly_Search_SeparatorSheet_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_SeparatorSheet_Max_Fields = {
  __typename?: 'vNavAssembly_Search_SeparatorSheet_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_SeparatorSheet_Min_Fields = {
  __typename?: 'vNavAssembly_Search_SeparatorSheet_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_SeparatorSheet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_SeparatorSheet>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_SeparatorSheet". */
export type VNavAssembly_Search_SeparatorSheet_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_SeparatorSheet" */
export enum VNavAssembly_Search_SeparatorSheet_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_SeparatorSheet_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_SeparatorSheet_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_SeparatorSheet_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_SeparatorSheet_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_SeparatorSheet_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_SeparatorSheet_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_SeparatorSheet_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_SeparatorSheet_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_SeparatorSheet" */
export enum VNavAssembly_Search_SeparatorSheet_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_SeparatorSheet_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_SeparatorSheet_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_SeparatorSheet_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_SeparatorSheet_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_SeparatorSheet_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_SeparatorSheet_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_SeparatorSheet" */
export type VNavAssembly_Search_SeparatorSheet_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer = {
  __typename?: 'vNavAssembly_Search_SubstratePrimer';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Aggregate = {
  __typename?: 'vNavAssembly_Search_SubstratePrimer_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_SubstratePrimer_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_SubstratePrimer>;
};

/** aggregate fields of "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_SubstratePrimer_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_SubstratePrimer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_SubstratePrimer_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_SubstratePrimer_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_SubstratePrimer_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_SubstratePrimer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_SubstratePrimer_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_SubstratePrimer_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_SubstratePrimer_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_SubstratePrimer_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_SubstratePrimer_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_SubstratePrimer_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_SubstratePrimer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_SubstratePrimer_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_SubstratePrimer_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_SubstratePrimer_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_SubstratePrimer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_SubstratePrimer_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_SubstratePrimer_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_SubstratePrimer_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_SubstratePrimer_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_SubstratePrimer_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_SubstratePrimer_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_SubstratePrimer_avg_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Avg_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_SubstratePrimer". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_SubstratePrimer_Bool_Exp = {
  ApplicationRateDry?: Maybe<Float_Mssql_Comparison_Exp>;
  ApplicationRateWet?: Maybe<Float_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_SubstratePrimer_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_SubstratePrimer_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_SubstratePrimer_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_SubstratePrimer_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_SubstratePrimer_Update_Column>;
  where?: Maybe<VNavAssembly_Search_SubstratePrimer_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Inc_Input = {
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Insert_Input = {
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_SubstratePrimer" */
export enum VNavAssembly_Search_SubstratePrimer_Insert_Match_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_SubstratePrimer_Max_Fields = {
  __typename?: 'vNavAssembly_Search_SubstratePrimer_max_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Max_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_SubstratePrimer_Min_Fields = {
  __typename?: 'vNavAssembly_Search_SubstratePrimer_min_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Min_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_SubstratePrimer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_SubstratePrimer>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_SubstratePrimer". */
export type VNavAssembly_Search_SubstratePrimer_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_SubstratePrimer" */
export enum VNavAssembly_Search_SubstratePrimer_Select_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Set_Input = {
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_SubstratePrimer_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_SubstratePrimer_stddev_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Stddev_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_SubstratePrimer_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_SubstratePrimer_stddev_pop_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Stddev_Pop_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_SubstratePrimer_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_SubstratePrimer_stddev_samp_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Stddev_Samp_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_SubstratePrimer_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_SubstratePrimer_sum_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Sum_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_SubstratePrimer" */
export enum VNavAssembly_Search_SubstratePrimer_Update_Column {
  /** column name */
  ApplicationRateDry = 'ApplicationRateDry',
  /** column name */
  ApplicationRateWet = 'ApplicationRateWet',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_SubstratePrimer_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_SubstratePrimer_var_pop_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Var_Pop_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_SubstratePrimer_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_SubstratePrimer_var_samp_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Var_Samp_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_SubstratePrimer_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_SubstratePrimer_variance_fields';
  ApplicationRateDry?: Maybe<Scalars['Float']>;
  ApplicationRateWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_SubstratePrimer" */
export type VNavAssembly_Search_SubstratePrimer_Variance_Order_By = {
  ApplicationRateDry?: Maybe<Order_By>;
  ApplicationRateWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing = {
  __typename?: 'vNavAssembly_Search_Surfacing';
  ApplicationRate?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  SurfacingMaterialId: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Aggregate = {
  __typename?: 'vNavAssembly_Search_Surfacing_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_Surfacing_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_Surfacing>;
};

/** aggregate fields of "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_Surfacing_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_Surfacing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_Surfacing_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_Surfacing_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_Surfacing_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_Surfacing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_Surfacing_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_Surfacing_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_Surfacing_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_Surfacing_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_Surfacing_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_Surfacing_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_Surfacing_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_Surfacing_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_Surfacing_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_Surfacing_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_Surfacing_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_Surfacing_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_Surfacing_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_Surfacing_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_Surfacing_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_Surfacing_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_Surfacing_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_Surfacing_avg_fields';
  ApplicationRate?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Avg_Order_By = {
  ApplicationRate?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_Surfacing". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_Surfacing_Bool_Exp = {
  ApplicationRate?: Maybe<Float_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  SurfacingMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_Surfacing_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_Surfacing_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_Surfacing_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_Surfacing_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_Surfacing_Update_Column>;
  where?: Maybe<VNavAssembly_Search_Surfacing_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Inc_Input = {
  ApplicationRate?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Insert_Input = {
  ApplicationRate?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_Surfacing" */
export enum VNavAssembly_Search_Surfacing_Insert_Match_Column {
  /** column name */
  ApplicationRate = 'ApplicationRate',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SurfacingMaterialId = 'SurfacingMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_Surfacing_Max_Fields = {
  __typename?: 'vNavAssembly_Search_Surfacing_max_fields';
  ApplicationRate?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Max_Order_By = {
  ApplicationRate?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_Surfacing_Min_Fields = {
  __typename?: 'vNavAssembly_Search_Surfacing_min_fields';
  ApplicationRate?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Min_Order_By = {
  ApplicationRate?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_Surfacing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_Surfacing>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_Surfacing". */
export type VNavAssembly_Search_Surfacing_Order_By = {
  ApplicationRate?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_Surfacing" */
export enum VNavAssembly_Search_Surfacing_Select_Column {
  /** column name */
  ApplicationRate = 'ApplicationRate',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SurfacingMaterialId = 'SurfacingMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Set_Input = {
  ApplicationRate?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_Surfacing_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_Surfacing_stddev_fields';
  ApplicationRate?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Stddev_Order_By = {
  ApplicationRate?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_Surfacing_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_Surfacing_stddev_pop_fields';
  ApplicationRate?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Stddev_Pop_Order_By = {
  ApplicationRate?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_Surfacing_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_Surfacing_stddev_samp_fields';
  ApplicationRate?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Stddev_Samp_Order_By = {
  ApplicationRate?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_Surfacing_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_Surfacing_sum_fields';
  ApplicationRate?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  SurfacingMaterialId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Sum_Order_By = {
  ApplicationRate?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_Surfacing" */
export enum VNavAssembly_Search_Surfacing_Update_Column {
  /** column name */
  ApplicationRate = 'ApplicationRate',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  SurfacingMaterialId = 'SurfacingMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_Surfacing_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_Surfacing_var_pop_fields';
  ApplicationRate?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Var_Pop_Order_By = {
  ApplicationRate?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_Surfacing_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_Surfacing_var_samp_fields';
  ApplicationRate?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Var_Samp_Order_By = {
  ApplicationRate?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_Surfacing_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_Surfacing_variance_fields';
  ApplicationRate?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  SurfacingMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_Surfacing" */
export type VNavAssembly_Search_Surfacing_Variance_Order_By = {
  ApplicationRate?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  SurfacingMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier = {
  __typename?: 'vNavAssembly_Search_ThermalBarrier';
  BoardProfileId: Scalars['Int'];
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  ThermalBarrierBoardSizes?: Maybe<Scalars['Int']>;
  ThermalBarrierMaterialId: Scalars['Int'];
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Aggregate = {
  __typename?: 'vNavAssembly_Search_ThermalBarrier_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_ThermalBarrier_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_ThermalBarrier>;
};

/** aggregate fields of "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_ThermalBarrier_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_ThermalBarrier_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_ThermalBarrier_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_ThermalBarrier_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_ThermalBarrier_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_ThermalBarrier_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_ThermalBarrier_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_ThermalBarrier_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_ThermalBarrier_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_ThermalBarrier_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_ThermalBarrier_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_ThermalBarrier_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_ThermalBarrier_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_ThermalBarrier_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_ThermalBarrier_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_ThermalBarrier_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_ThermalBarrier_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_ThermalBarrier_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_ThermalBarrier_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_ThermalBarrier_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_ThermalBarrier_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_ThermalBarrier_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_ThermalBarrier_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_ThermalBarrier_avg_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThermalBarrierBoardSizes?: Maybe<Scalars['Float']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Avg_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThermalBarrierBoardSizes?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_ThermalBarrier". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_ThermalBarrier_Bool_Exp = {
  BoardProfileId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  DensityMin?: Maybe<Float_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ThermalBarrierBoardSizes?: Maybe<Int_Mssql_Comparison_Exp>;
  ThermalBarrierMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ThicknessMax?: Maybe<Float_Mssql_Comparison_Exp>;
  ThicknessMin?: Maybe<Float_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_ThermalBarrier_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_ThermalBarrier_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_ThermalBarrier_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_ThermalBarrier_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_ThermalBarrier_Update_Column>;
  where?: Maybe<VNavAssembly_Search_ThermalBarrier_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Inc_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThermalBarrierBoardSizes?: Maybe<Scalars['Int']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Insert_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThermalBarrierBoardSizes?: Maybe<Scalars['Int']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_ThermalBarrier" */
export enum VNavAssembly_Search_ThermalBarrier_Insert_Match_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThermalBarrierBoardSizes = 'ThermalBarrierBoardSizes',
  /** column name */
  ThermalBarrierMaterialId = 'ThermalBarrierMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_ThermalBarrier_Max_Fields = {
  __typename?: 'vNavAssembly_Search_ThermalBarrier_max_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThermalBarrierBoardSizes?: Maybe<Scalars['Int']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Max_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThermalBarrierBoardSizes?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_ThermalBarrier_Min_Fields = {
  __typename?: 'vNavAssembly_Search_ThermalBarrier_min_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThermalBarrierBoardSizes?: Maybe<Scalars['Int']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Min_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThermalBarrierBoardSizes?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_ThermalBarrier_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_ThermalBarrier>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_ThermalBarrier". */
export type VNavAssembly_Search_ThermalBarrier_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThermalBarrierBoardSizes?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_ThermalBarrier" */
export enum VNavAssembly_Search_ThermalBarrier_Select_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThermalBarrierBoardSizes = 'ThermalBarrierBoardSizes',
  /** column name */
  ThermalBarrierMaterialId = 'ThermalBarrierMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Set_Input = {
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThermalBarrierBoardSizes?: Maybe<Scalars['Int']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_ThermalBarrier_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_ThermalBarrier_stddev_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThermalBarrierBoardSizes?: Maybe<Scalars['Float']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Stddev_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThermalBarrierBoardSizes?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_ThermalBarrier_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_ThermalBarrier_stddev_pop_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThermalBarrierBoardSizes?: Maybe<Scalars['Float']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Stddev_Pop_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThermalBarrierBoardSizes?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_ThermalBarrier_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_ThermalBarrier_stddev_samp_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThermalBarrierBoardSizes?: Maybe<Scalars['Float']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Stddev_Samp_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThermalBarrierBoardSizes?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_ThermalBarrier_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_ThermalBarrier_sum_fields';
  BoardProfileId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ThermalBarrierBoardSizes?: Maybe<Scalars['Int']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Int']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Sum_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThermalBarrierBoardSizes?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_ThermalBarrier" */
export enum VNavAssembly_Search_ThermalBarrier_Update_Column {
  /** column name */
  BoardProfileId = 'BoardProfileId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  DensityMin = 'DensityMin',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ThermalBarrierBoardSizes = 'ThermalBarrierBoardSizes',
  /** column name */
  ThermalBarrierMaterialId = 'ThermalBarrierMaterialId',
  /** column name */
  ThicknessMax = 'ThicknessMax',
  /** column name */
  ThicknessMin = 'ThicknessMin',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_ThermalBarrier_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_ThermalBarrier_var_pop_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThermalBarrierBoardSizes?: Maybe<Scalars['Float']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Var_Pop_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThermalBarrierBoardSizes?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_ThermalBarrier_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_ThermalBarrier_var_samp_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThermalBarrierBoardSizes?: Maybe<Scalars['Float']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Var_Samp_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThermalBarrierBoardSizes?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_ThermalBarrier_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_ThermalBarrier_variance_fields';
  BoardProfileId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  DensityMin?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ThermalBarrierBoardSizes?: Maybe<Scalars['Float']>;
  ThermalBarrierMaterialId?: Maybe<Scalars['Float']>;
  ThicknessMax?: Maybe<Scalars['Float']>;
  ThicknessMin?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_ThermalBarrier" */
export type VNavAssembly_Search_ThermalBarrier_Variance_Order_By = {
  BoardProfileId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  DensityMin?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ThermalBarrierBoardSizes?: Maybe<Order_By>;
  ThermalBarrierMaterialId?: Maybe<Order_By>;
  ThicknessMax?: Maybe<Order_By>;
  ThicknessMin?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat = {
  __typename?: 'vNavAssembly_Search_TopCoat';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId: Scalars['Int'];
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  NumCoats: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Aggregate = {
  __typename?: 'vNavAssembly_Search_TopCoat_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_TopCoat_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_TopCoat>;
};

/** aggregate fields of "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_TopCoat_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_TopCoat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_TopCoat_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_TopCoat_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_TopCoat_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_TopCoat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_TopCoat_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_TopCoat_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_TopCoat_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_TopCoat_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_TopCoat_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_TopCoat_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_TopCoat_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_TopCoat_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_TopCoat_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_TopCoat_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_TopCoat_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_TopCoat_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_TopCoat_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_TopCoat_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_TopCoat_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_TopCoat_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_TopCoat_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_TopCoat_avg_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Avg_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_TopCoat". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_TopCoat_Bool_Exp = {
  ApplicationRateMinDry?: Maybe<Float_Mssql_Comparison_Exp>;
  ApplicationRateMinWet?: Maybe<Float_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  LiquidAppliedMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  NumCoats?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_TopCoat_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_TopCoat_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_TopCoat_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_TopCoat_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_TopCoat_Update_Column>;
  where?: Maybe<VNavAssembly_Search_TopCoat_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Inc_Input = {
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Insert_Input = {
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_TopCoat" */
export enum VNavAssembly_Search_TopCoat_Insert_Match_Column {
  /** column name */
  ApplicationRateMinDry = 'ApplicationRateMinDry',
  /** column name */
  ApplicationRateMinWet = 'ApplicationRateMinWet',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_TopCoat_Max_Fields = {
  __typename?: 'vNavAssembly_Search_TopCoat_max_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Max_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_TopCoat_Min_Fields = {
  __typename?: 'vNavAssembly_Search_TopCoat_min_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Min_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_TopCoat_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_TopCoat>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_TopCoat". */
export type VNavAssembly_Search_TopCoat_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_TopCoat" */
export enum VNavAssembly_Search_TopCoat_Select_Column {
  /** column name */
  ApplicationRateMinDry = 'ApplicationRateMinDry',
  /** column name */
  ApplicationRateMinWet = 'ApplicationRateMinWet',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Set_Input = {
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_TopCoat_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_TopCoat_stddev_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Stddev_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_TopCoat_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_TopCoat_stddev_pop_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Stddev_Pop_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_TopCoat_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_TopCoat_stddev_samp_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Stddev_Samp_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_TopCoat_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_TopCoat_sum_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Int']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumCoats?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Sum_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_TopCoat" */
export enum VNavAssembly_Search_TopCoat_Update_Column {
  /** column name */
  ApplicationRateMinDry = 'ApplicationRateMinDry',
  /** column name */
  ApplicationRateMinWet = 'ApplicationRateMinWet',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  LiquidAppliedMaterialId = 'LiquidAppliedMaterialId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumCoats = 'NumCoats',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_TopCoat_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_TopCoat_var_pop_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Var_Pop_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_TopCoat_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_TopCoat_var_samp_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Var_Samp_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_TopCoat_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_TopCoat_variance_fields';
  ApplicationRateMinDry?: Maybe<Scalars['Float']>;
  ApplicationRateMinWet?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  LiquidAppliedMaterialId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  NumCoats?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_TopCoat" */
export type VNavAssembly_Search_TopCoat_Variance_Order_By = {
  ApplicationRateMinDry?: Maybe<Order_By>;
  ApplicationRateMinWet?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  LiquidAppliedMaterialId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumCoats?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** columns and relationships of "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder = {
  __typename?: 'vNavAssembly_Search_VaporRetarder';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  NumPlies?: Maybe<Scalars['String']>;
  RetarderMaterialId: Scalars['Int'];
  TradeName: Scalars['String'];
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Aggregate = {
  __typename?: 'vNavAssembly_Search_VaporRetarder_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_VaporRetarder_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search_VaporRetarder>;
};

/** aggregate fields of "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_VaporRetarder_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_VaporRetarder_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_VaporRetarder_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_VaporRetarder_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_VaporRetarder_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_VaporRetarder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_VaporRetarder_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_VaporRetarder_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_VaporRetarder_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_VaporRetarder_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_VaporRetarder_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_VaporRetarder_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Aggregate_Order_By = {
  avg?: Maybe<VNavAssembly_Search_VaporRetarder_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VNavAssembly_Search_VaporRetarder_Max_Order_By>;
  min?: Maybe<VNavAssembly_Search_VaporRetarder_Min_Order_By>;
  stddev?: Maybe<VNavAssembly_Search_VaporRetarder_Stddev_Order_By>;
  stddev_pop?: Maybe<VNavAssembly_Search_VaporRetarder_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VNavAssembly_Search_VaporRetarder_Stddev_Samp_Order_By>;
  sum?: Maybe<VNavAssembly_Search_VaporRetarder_Sum_Order_By>;
  var_pop?: Maybe<VNavAssembly_Search_VaporRetarder_Var_Pop_Order_By>;
  var_samp?: Maybe<VNavAssembly_Search_VaporRetarder_Var_Samp_Order_By>;
  variance?: Maybe<VNavAssembly_Search_VaporRetarder_Variance_Order_By>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_VaporRetarder_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_VaporRetarder_avg_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Avg_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search_VaporRetarder". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_VaporRetarder_Bool_Exp = {
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  NumPlies?: Maybe<String_Mssql_Comparison_Exp>;
  RetarderMaterialId?: Maybe<Int_Mssql_Comparison_Exp>;
  TradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_VaporRetarder_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_VaporRetarder_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_VaporRetarder_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_VaporRetarder_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_VaporRetarder_Update_Column>;
  where?: Maybe<VNavAssembly_Search_VaporRetarder_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Inc_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Insert_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['String']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Search_VaporRetarder" */
export enum VNavAssembly_Search_VaporRetarder_Insert_Match_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Search_VaporRetarder_Max_Fields = {
  __typename?: 'vNavAssembly_Search_VaporRetarder_max_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['String']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Max_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_VaporRetarder_Min_Fields = {
  __typename?: 'vNavAssembly_Search_VaporRetarder_min_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['String']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Min_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_VaporRetarder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search_VaporRetarder>;
};

/** Ordering options when selecting data from "vNavAssembly_Search_VaporRetarder". */
export type VNavAssembly_Search_VaporRetarder_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  NumPlies?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  TradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search_VaporRetarder" */
export enum VNavAssembly_Search_VaporRetarder_Select_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Set_Input = {
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  NumPlies?: Maybe<Scalars['String']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  TradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_VaporRetarder_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_VaporRetarder_stddev_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Stddev_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_VaporRetarder_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_VaporRetarder_stddev_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Stddev_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_VaporRetarder_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_VaporRetarder_stddev_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Stddev_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_VaporRetarder_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_VaporRetarder_sum_fields';
  ComponentId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  RetarderMaterialId?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Sum_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** update columns of table "vNavAssembly_Search_VaporRetarder" */
export enum VNavAssembly_Search_VaporRetarder_Update_Column {
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  NumPlies = 'NumPlies',
  /** column name */
  RetarderMaterialId = 'RetarderMaterialId',
  /** column name */
  TradeName = 'TradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_VaporRetarder_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_VaporRetarder_var_pop_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Var_Pop_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_VaporRetarder_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_VaporRetarder_var_samp_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Var_Samp_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_VaporRetarder_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_VaporRetarder_variance_fields';
  ComponentId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  RetarderMaterialId?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vNavAssembly_Search_VaporRetarder" */
export type VNavAssembly_Search_VaporRetarder_Variance_Order_By = {
  ComponentId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  RetarderMaterialId?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** aggregated selection of "vNavAssembly_Search" */
export type VNavAssembly_Search_Aggregate = {
  __typename?: 'vNavAssembly_Search_aggregate';
  aggregate?: Maybe<VNavAssembly_Search_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Search>;
};

/** aggregate fields of "vNavAssembly_Search" */
export type VNavAssembly_Search_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Search_aggregate_fields';
  avg?: Maybe<VNavAssembly_Search_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Search_Max_Fields>;
  min?: Maybe<VNavAssembly_Search_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Search_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Search_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Search_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Search_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Search_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Search_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Search_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Search" */
export type VNavAssembly_Search_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Search_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type VNavAssembly_Search_Avg_Fields = {
  __typename?: 'vNavAssembly_Search_avg_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  Slope?: Maybe<Scalars['Float']>;
  WindUplift?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Search". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Search_Bool_Exp = {
  AssemblyApplicationTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  AssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  AssemblyTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  CoverSecurementMethodId?: Maybe<Int_Mssql_Comparison_Exp>;
  DeckTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  ExtFireRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  HailRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  IntFireRatingId?: Maybe<Int_Mssql_Comparison_Exp>;
  ManufacturerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyName?: Maybe<String_Mssql_Comparison_Exp>;
  Slope?: Maybe<Float_Mssql_Comparison_Exp>;
  WindUplift?: Maybe<Float_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Search_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Search_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Search_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Search" */
export type VNavAssembly_Search_If_Matched = {
  match_columns?: Array<VNavAssembly_Search_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Search_Update_Column>;
  where?: Maybe<VNavAssembly_Search_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Search" */
export type VNavAssembly_Search_Inc_Input = {
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  Slope?: Maybe<Scalars['Float']>;
  WindUplift?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "vNavAssembly_Search" */
export type VNavAssembly_Search_Insert_Input = {
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyName?: Maybe<Scalars['String']>;
  Slope?: Maybe<Scalars['Float']>;
  WindUplift?: Maybe<Scalars['Float']>;
};

/** select match_columns of table "vNavAssembly_Search" */
export enum VNavAssembly_Search_Insert_Match_Column {
  /** column name */
  AssemblyApplicationTypeId = 'AssemblyApplicationTypeId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  AssemblyTypeId = 'AssemblyTypeId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverSecurementMethodId = 'CoverSecurementMethodId',
  /** column name */
  DeckTypeId = 'DeckTypeId',
  /** column name */
  ExtFireRatingId = 'ExtFireRatingId',
  /** column name */
  HailRatingId = 'HailRatingId',
  /** column name */
  IntFireRatingId = 'IntFireRatingId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavAssemblyName = 'NavAssemblyName',
  /** column name */
  Slope = 'Slope',
  /** column name */
  WindUplift = 'WindUplift'
}

/** aggregate max on columns */
export type VNavAssembly_Search_Max_Fields = {
  __typename?: 'vNavAssembly_Search_max_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyName?: Maybe<Scalars['String']>;
  Slope?: Maybe<Scalars['Float']>;
  WindUplift?: Maybe<Scalars['Float']>;
};

/** aggregate min on columns */
export type VNavAssembly_Search_Min_Fields = {
  __typename?: 'vNavAssembly_Search_min_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyName?: Maybe<Scalars['String']>;
  Slope?: Maybe<Scalars['Float']>;
  WindUplift?: Maybe<Scalars['Float']>;
};

/** response of any mutation on the table "vNavAssembly_Search" */
export type VNavAssembly_Search_Mutation_Response = {
  __typename?: 'vNavAssembly_Search_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Search>;
};

/** Ordering options when selecting data from "vNavAssembly_Search". */
export type VNavAssembly_Search_Order_By = {
  AssemblyApplicationTypeId?: Maybe<Order_By>;
  AssemblyId?: Maybe<Order_By>;
  AssemblyTypeId?: Maybe<Order_By>;
  ComponentId?: Maybe<Order_By>;
  CoverSecurementMethodId?: Maybe<Order_By>;
  DeckTypeId?: Maybe<Order_By>;
  ExtFireRatingId?: Maybe<Order_By>;
  HailRatingId?: Maybe<Order_By>;
  IntFireRatingId?: Maybe<Order_By>;
  ManufacturerId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavAssemblyName?: Maybe<Order_By>;
  Slope?: Maybe<Order_By>;
  WindUplift?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Search" */
export enum VNavAssembly_Search_Select_Column {
  /** column name */
  AssemblyApplicationTypeId = 'AssemblyApplicationTypeId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  AssemblyTypeId = 'AssemblyTypeId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverSecurementMethodId = 'CoverSecurementMethodId',
  /** column name */
  DeckTypeId = 'DeckTypeId',
  /** column name */
  ExtFireRatingId = 'ExtFireRatingId',
  /** column name */
  HailRatingId = 'HailRatingId',
  /** column name */
  IntFireRatingId = 'IntFireRatingId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavAssemblyName = 'NavAssemblyName',
  /** column name */
  Slope = 'Slope',
  /** column name */
  WindUplift = 'WindUplift'
}

/** input type for updating data in table "vNavAssembly_Search" */
export type VNavAssembly_Search_Set_Input = {
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavAssemblyName?: Maybe<Scalars['String']>;
  Slope?: Maybe<Scalars['Float']>;
  WindUplift?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Search_Stddev_Fields = {
  __typename?: 'vNavAssembly_Search_stddev_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  Slope?: Maybe<Scalars['Float']>;
  WindUplift?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Search_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_stddev_pop_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  Slope?: Maybe<Scalars['Float']>;
  WindUplift?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Search_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_stddev_samp_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  Slope?: Maybe<Scalars['Float']>;
  WindUplift?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type VNavAssembly_Search_Sum_Fields = {
  __typename?: 'vNavAssembly_Search_sum_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Int']>;
  AssemblyId?: Maybe<Scalars['Int']>;
  AssemblyTypeId?: Maybe<Scalars['Int']>;
  ComponentId?: Maybe<Scalars['Int']>;
  CoverSecurementMethodId?: Maybe<Scalars['Int']>;
  DeckTypeId?: Maybe<Scalars['Int']>;
  ExtFireRatingId?: Maybe<Scalars['Int']>;
  HailRatingId?: Maybe<Scalars['Int']>;
  IntFireRatingId?: Maybe<Scalars['Int']>;
  ManufacturerId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  Slope?: Maybe<Scalars['Float']>;
  WindUplift?: Maybe<Scalars['Float']>;
};

/** update columns of table "vNavAssembly_Search" */
export enum VNavAssembly_Search_Update_Column {
  /** column name */
  AssemblyApplicationTypeId = 'AssemblyApplicationTypeId',
  /** column name */
  AssemblyId = 'AssemblyId',
  /** column name */
  AssemblyTypeId = 'AssemblyTypeId',
  /** column name */
  ComponentId = 'ComponentId',
  /** column name */
  CoverSecurementMethodId = 'CoverSecurementMethodId',
  /** column name */
  DeckTypeId = 'DeckTypeId',
  /** column name */
  ExtFireRatingId = 'ExtFireRatingId',
  /** column name */
  HailRatingId = 'HailRatingId',
  /** column name */
  IntFireRatingId = 'IntFireRatingId',
  /** column name */
  ManufacturerId = 'ManufacturerId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavAssemblyName = 'NavAssemblyName',
  /** column name */
  Slope = 'Slope',
  /** column name */
  WindUplift = 'WindUplift'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Search_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Search_var_pop_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  Slope?: Maybe<Scalars['Float']>;
  WindUplift?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Search_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Search_var_samp_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  Slope?: Maybe<Scalars['Float']>;
  WindUplift?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type VNavAssembly_Search_Variance_Fields = {
  __typename?: 'vNavAssembly_Search_variance_fields';
  AssemblyApplicationTypeId?: Maybe<Scalars['Float']>;
  AssemblyId?: Maybe<Scalars['Float']>;
  AssemblyTypeId?: Maybe<Scalars['Float']>;
  ComponentId?: Maybe<Scalars['Float']>;
  CoverSecurementMethodId?: Maybe<Scalars['Float']>;
  DeckTypeId?: Maybe<Scalars['Float']>;
  ExtFireRatingId?: Maybe<Scalars['Float']>;
  HailRatingId?: Maybe<Scalars['Float']>;
  IntFireRatingId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  Slope?: Maybe<Scalars['Float']>;
  WindUplift?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "vNavAssembly_Usages" */
export type VNavAssembly_Usages = {
  __typename?: 'vNavAssembly_Usages';
  ApprovedUseId: Scalars['Int'];
  NavAssemblyId: Scalars['Int'];
  NavLayerId: Scalars['Int'];
  NavLayerNum: Scalars['Int'];
  ProductComponentId?: Maybe<Scalars['Int']>;
  ProductManufacturer?: Maybe<Scalars['String']>;
  ProductTradeName?: Maybe<Scalars['String']>;
  SelfSecurementComments?: Maybe<Scalars['String']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SystemComponentId?: Maybe<Scalars['Int']>;
  SystemName?: Maybe<Scalars['String']>;
  SystemProductComponentId?: Maybe<Scalars['Int']>;
  SystemProductManufacturer?: Maybe<Scalars['String']>;
  SystemProductOrder?: Maybe<Scalars['Int']>;
  SystemProductTradeName?: Maybe<Scalars['String']>;
  UsageId: Scalars['Int'];
};

/** aggregated selection of "vNavAssembly_Usages" */
export type VNavAssembly_Usages_Aggregate = {
  __typename?: 'vNavAssembly_Usages_aggregate';
  aggregate?: Maybe<VNavAssembly_Usages_Aggregate_Fields>;
  nodes: Array<VNavAssembly_Usages>;
};

/** aggregate fields of "vNavAssembly_Usages" */
export type VNavAssembly_Usages_Aggregate_Fields = {
  __typename?: 'vNavAssembly_Usages_aggregate_fields';
  avg?: Maybe<VNavAssembly_Usages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VNavAssembly_Usages_Max_Fields>;
  min?: Maybe<VNavAssembly_Usages_Min_Fields>;
  stddev?: Maybe<VNavAssembly_Usages_Stddev_Fields>;
  stddev_pop?: Maybe<VNavAssembly_Usages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VNavAssembly_Usages_Stddev_Samp_Fields>;
  sum?: Maybe<VNavAssembly_Usages_Sum_Fields>;
  var_pop?: Maybe<VNavAssembly_Usages_Var_Pop_Fields>;
  var_samp?: Maybe<VNavAssembly_Usages_Var_Samp_Fields>;
  variance?: Maybe<VNavAssembly_Usages_Variance_Fields>;
};


/** aggregate fields of "vNavAssembly_Usages" */
export type VNavAssembly_Usages_Aggregate_FieldsCountArgs = {
  column?: Maybe<VNavAssembly_Usages_Select_Column>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type VNavAssembly_Usages_Avg_Fields = {
  __typename?: 'vNavAssembly_Usages_avg_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ProductComponentId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  SystemComponentId?: Maybe<Scalars['Float']>;
  SystemProductComponentId?: Maybe<Scalars['Float']>;
  SystemProductOrder?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "vNavAssembly_Usages". All fields are combined with a logical 'AND'. */
export type VNavAssembly_Usages_Bool_Exp = {
  ApprovedUseId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavAssemblyId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerId?: Maybe<Int_Mssql_Comparison_Exp>;
  NavLayerNum?: Maybe<Int_Mssql_Comparison_Exp>;
  ProductComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  ProductManufacturer?: Maybe<String_Mssql_Comparison_Exp>;
  ProductTradeName?: Maybe<String_Mssql_Comparison_Exp>;
  SelfSecurementComments?: Maybe<String_Mssql_Comparison_Exp>;
  SelfSecurementLayerTypeId?: Maybe<Int_Mssql_Comparison_Exp>;
  SystemComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  SystemName?: Maybe<String_Mssql_Comparison_Exp>;
  SystemProductComponentId?: Maybe<Int_Mssql_Comparison_Exp>;
  SystemProductManufacturer?: Maybe<String_Mssql_Comparison_Exp>;
  SystemProductOrder?: Maybe<Int_Mssql_Comparison_Exp>;
  SystemProductTradeName?: Maybe<String_Mssql_Comparison_Exp>;
  UsageId?: Maybe<Int_Mssql_Comparison_Exp>;
  _and?: Maybe<Array<VNavAssembly_Usages_Bool_Exp>>;
  _not?: Maybe<VNavAssembly_Usages_Bool_Exp>;
  _or?: Maybe<Array<VNavAssembly_Usages_Bool_Exp>>;
};

/** upsert condition type for table "vNavAssembly_Usages" */
export type VNavAssembly_Usages_If_Matched = {
  match_columns?: Array<VNavAssembly_Usages_Insert_Match_Column>;
  update_columns?: Array<VNavAssembly_Usages_Update_Column>;
  where?: Maybe<VNavAssembly_Usages_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "vNavAssembly_Usages" */
export type VNavAssembly_Usages_Inc_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ProductComponentId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SystemComponentId?: Maybe<Scalars['Int']>;
  SystemProductComponentId?: Maybe<Scalars['Int']>;
  SystemProductOrder?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "vNavAssembly_Usages" */
export type VNavAssembly_Usages_Insert_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ProductComponentId?: Maybe<Scalars['Int']>;
  ProductManufacturer?: Maybe<Scalars['String']>;
  ProductTradeName?: Maybe<Scalars['String']>;
  SelfSecurementComments?: Maybe<Scalars['String']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SystemComponentId?: Maybe<Scalars['Int']>;
  SystemName?: Maybe<Scalars['String']>;
  SystemProductComponentId?: Maybe<Scalars['Int']>;
  SystemProductManufacturer?: Maybe<Scalars['String']>;
  SystemProductOrder?: Maybe<Scalars['Int']>;
  SystemProductTradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** select match_columns of table "vNavAssembly_Usages" */
export enum VNavAssembly_Usages_Insert_Match_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ProductComponentId = 'ProductComponentId',
  /** column name */
  ProductManufacturer = 'ProductManufacturer',
  /** column name */
  ProductTradeName = 'ProductTradeName',
  /** column name */
  SelfSecurementComments = 'SelfSecurementComments',
  /** column name */
  SelfSecurementLayerTypeId = 'SelfSecurementLayerTypeId',
  /** column name */
  SystemComponentId = 'SystemComponentId',
  /** column name */
  SystemName = 'SystemName',
  /** column name */
  SystemProductComponentId = 'SystemProductComponentId',
  /** column name */
  SystemProductManufacturer = 'SystemProductManufacturer',
  /** column name */
  SystemProductOrder = 'SystemProductOrder',
  /** column name */
  SystemProductTradeName = 'SystemProductTradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate max on columns */
export type VNavAssembly_Usages_Max_Fields = {
  __typename?: 'vNavAssembly_Usages_max_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ProductComponentId?: Maybe<Scalars['Int']>;
  ProductManufacturer?: Maybe<Scalars['String']>;
  ProductTradeName?: Maybe<Scalars['String']>;
  SelfSecurementComments?: Maybe<Scalars['String']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SystemComponentId?: Maybe<Scalars['Int']>;
  SystemName?: Maybe<Scalars['String']>;
  SystemProductComponentId?: Maybe<Scalars['Int']>;
  SystemProductManufacturer?: Maybe<Scalars['String']>;
  SystemProductOrder?: Maybe<Scalars['Int']>;
  SystemProductTradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type VNavAssembly_Usages_Min_Fields = {
  __typename?: 'vNavAssembly_Usages_min_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ProductComponentId?: Maybe<Scalars['Int']>;
  ProductManufacturer?: Maybe<Scalars['String']>;
  ProductTradeName?: Maybe<Scalars['String']>;
  SelfSecurementComments?: Maybe<Scalars['String']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SystemComponentId?: Maybe<Scalars['Int']>;
  SystemName?: Maybe<Scalars['String']>;
  SystemProductComponentId?: Maybe<Scalars['Int']>;
  SystemProductManufacturer?: Maybe<Scalars['String']>;
  SystemProductOrder?: Maybe<Scalars['Int']>;
  SystemProductTradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "vNavAssembly_Usages" */
export type VNavAssembly_Usages_Mutation_Response = {
  __typename?: 'vNavAssembly_Usages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VNavAssembly_Usages>;
};

/** Ordering options when selecting data from "vNavAssembly_Usages". */
export type VNavAssembly_Usages_Order_By = {
  ApprovedUseId?: Maybe<Order_By>;
  NavAssemblyId?: Maybe<Order_By>;
  NavLayerId?: Maybe<Order_By>;
  NavLayerNum?: Maybe<Order_By>;
  ProductComponentId?: Maybe<Order_By>;
  ProductManufacturer?: Maybe<Order_By>;
  ProductTradeName?: Maybe<Order_By>;
  SelfSecurementComments?: Maybe<Order_By>;
  SelfSecurementLayerTypeId?: Maybe<Order_By>;
  SystemComponentId?: Maybe<Order_By>;
  SystemName?: Maybe<Order_By>;
  SystemProductComponentId?: Maybe<Order_By>;
  SystemProductManufacturer?: Maybe<Order_By>;
  SystemProductOrder?: Maybe<Order_By>;
  SystemProductTradeName?: Maybe<Order_By>;
  UsageId?: Maybe<Order_By>;
};

/** select columns of table "vNavAssembly_Usages" */
export enum VNavAssembly_Usages_Select_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ProductComponentId = 'ProductComponentId',
  /** column name */
  ProductManufacturer = 'ProductManufacturer',
  /** column name */
  ProductTradeName = 'ProductTradeName',
  /** column name */
  SelfSecurementComments = 'SelfSecurementComments',
  /** column name */
  SelfSecurementLayerTypeId = 'SelfSecurementLayerTypeId',
  /** column name */
  SystemComponentId = 'SystemComponentId',
  /** column name */
  SystemName = 'SystemName',
  /** column name */
  SystemProductComponentId = 'SystemProductComponentId',
  /** column name */
  SystemProductManufacturer = 'SystemProductManufacturer',
  /** column name */
  SystemProductOrder = 'SystemProductOrder',
  /** column name */
  SystemProductTradeName = 'SystemProductTradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** input type for updating data in table "vNavAssembly_Usages" */
export type VNavAssembly_Usages_Set_Input = {
  ApprovedUseId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ProductComponentId?: Maybe<Scalars['Int']>;
  ProductManufacturer?: Maybe<Scalars['String']>;
  ProductTradeName?: Maybe<Scalars['String']>;
  SelfSecurementComments?: Maybe<Scalars['String']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SystemComponentId?: Maybe<Scalars['Int']>;
  SystemName?: Maybe<Scalars['String']>;
  SystemProductComponentId?: Maybe<Scalars['Int']>;
  SystemProductManufacturer?: Maybe<Scalars['String']>;
  SystemProductOrder?: Maybe<Scalars['Int']>;
  SystemProductTradeName?: Maybe<Scalars['String']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VNavAssembly_Usages_Stddev_Fields = {
  __typename?: 'vNavAssembly_Usages_stddev_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ProductComponentId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  SystemComponentId?: Maybe<Scalars['Float']>;
  SystemProductComponentId?: Maybe<Scalars['Float']>;
  SystemProductOrder?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type VNavAssembly_Usages_Stddev_Pop_Fields = {
  __typename?: 'vNavAssembly_Usages_stddev_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ProductComponentId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  SystemComponentId?: Maybe<Scalars['Float']>;
  SystemProductComponentId?: Maybe<Scalars['Float']>;
  SystemProductOrder?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type VNavAssembly_Usages_Stddev_Samp_Fields = {
  __typename?: 'vNavAssembly_Usages_stddev_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ProductComponentId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  SystemComponentId?: Maybe<Scalars['Float']>;
  SystemProductComponentId?: Maybe<Scalars['Float']>;
  SystemProductOrder?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type VNavAssembly_Usages_Sum_Fields = {
  __typename?: 'vNavAssembly_Usages_sum_fields';
  ApprovedUseId?: Maybe<Scalars['Int']>;
  NavAssemblyId?: Maybe<Scalars['Int']>;
  NavLayerId?: Maybe<Scalars['Int']>;
  NavLayerNum?: Maybe<Scalars['Int']>;
  ProductComponentId?: Maybe<Scalars['Int']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Int']>;
  SystemComponentId?: Maybe<Scalars['Int']>;
  SystemProductComponentId?: Maybe<Scalars['Int']>;
  SystemProductOrder?: Maybe<Scalars['Int']>;
  UsageId?: Maybe<Scalars['Int']>;
};

/** update columns of table "vNavAssembly_Usages" */
export enum VNavAssembly_Usages_Update_Column {
  /** column name */
  ApprovedUseId = 'ApprovedUseId',
  /** column name */
  NavAssemblyId = 'NavAssemblyId',
  /** column name */
  NavLayerId = 'NavLayerId',
  /** column name */
  NavLayerNum = 'NavLayerNum',
  /** column name */
  ProductComponentId = 'ProductComponentId',
  /** column name */
  ProductManufacturer = 'ProductManufacturer',
  /** column name */
  ProductTradeName = 'ProductTradeName',
  /** column name */
  SelfSecurementComments = 'SelfSecurementComments',
  /** column name */
  SelfSecurementLayerTypeId = 'SelfSecurementLayerTypeId',
  /** column name */
  SystemComponentId = 'SystemComponentId',
  /** column name */
  SystemName = 'SystemName',
  /** column name */
  SystemProductComponentId = 'SystemProductComponentId',
  /** column name */
  SystemProductManufacturer = 'SystemProductManufacturer',
  /** column name */
  SystemProductOrder = 'SystemProductOrder',
  /** column name */
  SystemProductTradeName = 'SystemProductTradeName',
  /** column name */
  UsageId = 'UsageId'
}

/** aggregate var_pop on columns */
export type VNavAssembly_Usages_Var_Pop_Fields = {
  __typename?: 'vNavAssembly_Usages_var_pop_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ProductComponentId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  SystemComponentId?: Maybe<Scalars['Float']>;
  SystemProductComponentId?: Maybe<Scalars['Float']>;
  SystemProductOrder?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type VNavAssembly_Usages_Var_Samp_Fields = {
  __typename?: 'vNavAssembly_Usages_var_samp_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ProductComponentId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  SystemComponentId?: Maybe<Scalars['Float']>;
  SystemProductComponentId?: Maybe<Scalars['Float']>;
  SystemProductOrder?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type VNavAssembly_Usages_Variance_Fields = {
  __typename?: 'vNavAssembly_Usages_variance_fields';
  ApprovedUseId?: Maybe<Scalars['Float']>;
  NavAssemblyId?: Maybe<Scalars['Float']>;
  NavLayerId?: Maybe<Scalars['Float']>;
  NavLayerNum?: Maybe<Scalars['Float']>;
  ProductComponentId?: Maybe<Scalars['Float']>;
  SelfSecurementLayerTypeId?: Maybe<Scalars['Float']>;
  SystemComponentId?: Maybe<Scalars['Float']>;
  SystemProductComponentId?: Maybe<Scalars['Float']>;
  SystemProductOrder?: Maybe<Scalars['Float']>;
  UsageId?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "varbinary". All fields are combined with logical 'AND'. */
export type Varbinary_Mssql_Comparison_Exp = {
  _eq?: Maybe<Scalars['varbinary']>;
  _gt?: Maybe<Scalars['varbinary']>;
  _gte?: Maybe<Scalars['varbinary']>;
  _in?: Maybe<Array<Scalars['varbinary']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['varbinary']>;
  _lte?: Maybe<Scalars['varbinary']>;
  _neq?: Maybe<Scalars['varbinary']>;
  _nin?: Maybe<Array<Scalars['varbinary']>>;
};

export type GetApprovalsDocByIdQueryVariables = Exact<{
  ApprovalsDocId?: Maybe<Scalars['Int']>;
}>;


export type GetApprovalsDocByIdQuery = (
  { __typename?: 'query_root' }
  & { ApprovalsDoc: Array<(
    { __typename?: 'ApprovalsDoc' }
    & Pick<ApprovalsDoc, 'ApprovalsDocId' | 'DocName' | 'DocData'>
  )> }
);

export type GetApprovalsDocByCategoryIdQueryVariables = Exact<{
  ApprovalsDocCategoryId?: Maybe<Scalars['Int']>;
}>;


export type GetApprovalsDocByCategoryIdQuery = (
  { __typename?: 'query_root' }
  & { ApprovalsDoc: Array<(
    { __typename?: 'ApprovalsDoc' }
    & Pick<ApprovalsDoc, 'ApprovalsDocId' | 'DocName' | 'Dsc' | 'DateIssued'>
  )> }
);

export type GetComponentDocByIdQueryVariables = Exact<{
  ComponentDocId?: Maybe<Scalars['Int']>;
}>;


export type GetComponentDocByIdQuery = (
  { __typename?: 'query_root' }
  & { ComponentDoc: Array<(
    { __typename?: 'ComponentDoc' }
    & Pick<ComponentDoc, 'ComponentDocId' | 'ComponentId' | 'DocData'>
  )> }
);

export type AssemblyApiSearchQueryVariables = Exact<{
  assemblySearchCriteria: AssemblySearchCriteria;
}>;


export type AssemblyApiSearchQuery = (
  { __typename?: 'query_root' }
  & { navassembly_api_search?: Maybe<Array<Maybe<(
    { __typename?: 'AssemblySearchResultItem' }
    & Pick<AssemblySearchResultItem, 'NavAssemblyId' | 'NavAssemblyName' | 'AssemblyApplicationTypeCode' | 'CoverSecurementMethodCode' | 'AssemblyTypeCode' | 'DeckTypeCode' | 'Slope' | 'WindUplift' | 'IntFireRatingCode' | 'ExtFireRatingCode' | 'HailRatingCode'>
  )>>> }
);

export type AssemblyApiRoofTypeSearchQueryVariables = Exact<{
  assemblySearchCriteria: AssemblySearchCriteria;
}>;


export type AssemblyApiRoofTypeSearchQuery = (
  { __typename?: 'query_root' }
  & { navassembly_api_rooftypesearch?: Maybe<Array<Maybe<(
    { __typename?: 'AssemblySearchRoofTypeItem' }
    & Pick<AssemblySearchRoofTypeItem, 'LayerConfigId' | 'ConfigString' | 'NumOfAssembly'>
  )>>> }
);

export type AssemblyApiSearchResultCountQueryVariables = Exact<{
  assemblySearchCriteria: AssemblySearchCriteria;
}>;


export type AssemblyApiSearchResultCountQuery = (
  { __typename?: 'query_root' }
  & { navassembly_api_countsearchresult?: Maybe<Array<Maybe<(
    { __typename?: 'AssemblySearchCountItem' }
    & Pick<AssemblySearchCountItem, 'AssemblySearchResultCount'>
  )>>> }
);

export type GetNavAssemblyCompleteDetailQueryVariables = Exact<{
  NavAssemblyId?: Maybe<Scalars['Int']>;
}>;


export type GetNavAssemblyCompleteDetailQuery = (
  { __typename?: 'query_root' }
  & { NavAssembly: Array<(
    { __typename?: 'NavAssembly' }
    & Pick<NavAssembly, 'NavAssemblyId' | 'NavAssemblyName' | 'AssemblyId'>
    & { RoofAssembly: (
      { __typename?: 'RoofAssembly' }
      & Pick<RoofAssembly, 'AssemblyTypeId' | 'IntFireRatingId' | 'ExtFireRatingId' | 'AssemblyApplicationTypeId' | 'HailRatingId' | 'CoverSecurementMethodId' | 'DeckTypeId' | 'PVApplicationMethodId' | 'PVHailRatingId' | 'Slope' | 'WindUplift' | 'IsUsedWithCombustibleWalls' | 'IncludesPV' | 'PVWindUplift' | 'LockStatusId'>
      & { C_AssemblyType: (
        { __typename?: 'C_AssemblyType' }
        & Pick<C_AssemblyType, 'AssemblyTypeCode'>
      ), C_IntFireRating: (
        { __typename?: 'C_IntFireRating' }
        & Pick<C_IntFireRating, 'IntFireRatingCode'>
      ), C_ExtFireRating: (
        { __typename?: 'C_ExtFireRating' }
        & Pick<C_ExtFireRating, 'ExtFireRatingCode'>
      ), C_AssemblyApplicationType: (
        { __typename?: 'C_AssemblyApplicationType' }
        & Pick<C_AssemblyApplicationType, 'AssemblyApplicationTypeCode'>
      ), C_HailRating: (
        { __typename?: 'C_HailRating' }
        & Pick<C_HailRating, 'HailRatingCode'>
      ), C_CoverSecurementMethod: (
        { __typename?: 'C_CoverSecurementMethod' }
        & Pick<C_CoverSecurementMethod, 'CoverSecurementMethodCode'>
      ), C_DeckType: (
        { __typename?: 'C_DeckType' }
        & Pick<C_DeckType, 'DeckTypeCode'>
      ), C_PVApplicationMethod?: Maybe<(
        { __typename?: 'C_PVApplicationMethod' }
        & Pick<C_PvApplicationMethod, 'PVApplicationMethodCode'>
      )>, C_PVHailRating?: Maybe<(
        { __typename?: 'C_PVHailRating' }
        & Pick<C_PvHailRating, 'PVHailRatingCode'>
      )> }
    ), R_NavAssembly_NavMultiplyCoverConfigs: Array<(
      { __typename?: 'R_NavAssembly_NavMultiplyCoverConfig' }
      & Pick<R_NavAssembly_NavMultiplyCoverConfig, 'NavMultiplyCoverConfigId'>
      & { NavMultiplyCoverConfig: (
        { __typename?: 'NavMultiplyCoverConfig' }
        & Pick<NavMultiplyCoverConfig, 'CoverConfiguration' | 'NumPlies' | 'SubassemblyTypeId'>
        & { C_SubassemblyType?: Maybe<(
          { __typename?: 'C_SubassemblyType' }
          & Pick<C_SubassemblyType, 'SubassemblyTypeCode'>
        )> }
      ) }
    )> }
  )>, vNavAssembly_Layers: Array<(
    { __typename?: 'vNavAssembly_Layers' }
    & Pick<VNavAssembly_Layers, 'NavLayerId' | 'NavLayerNum' | 'LayerTypeId' | 'LayerTypeCode' | 'FromNavLayerId' | 'FromNavLayerNum' | 'ToNavLayerId' | 'ToNavLayerNum' | 'IsOptional' | 'IsPermeable' | 'Comments' | 'LayerId'>
  )>, vNavAssembly_Usages: Array<(
    { __typename?: 'vNavAssembly_Usages' }
    & Pick<VNavAssembly_Usages, 'NavLayerId' | 'NavLayerNum' | 'UsageId' | 'ApprovedUseId' | 'SystemComponentId' | 'SystemName' | 'SystemProductComponentId' | 'SystemProductTradeName' | 'SystemProductManufacturer' | 'SystemProductOrder' | 'ProductComponentId' | 'ProductTradeName' | 'ProductManufacturer' | 'SelfSecurementLayerTypeId' | 'SelfSecurementComments'>
  )> }
);

export type GetSinglePlyAdhesiveRelatedUsagesQueryVariables = Exact<{
  NavAssemblyId?: Maybe<Scalars['Int']>;
}>;


export type GetSinglePlyAdhesiveRelatedUsagesQuery = (
  { __typename?: 'query_root' }
  & { NavAssembly: Array<(
    { __typename?: 'NavAssembly' }
    & { Nav_SinglePlyBondingAdhesives: Array<(
      { __typename?: 'Nav_SinglePlyBondingAdhesives' }
      & Pick<Nav_SinglePlyBondingAdhesives, 'SinglePlyComponentId' | 'SinglePlyUsageId' | 'AdhesiveComponentId' | 'AdhesiveUsageId'>
    )>, Nav_SinglePlyLapAdhesives: Array<(
      { __typename?: 'Nav_SinglePlyLapAdhesives' }
      & Pick<Nav_SinglePlyLapAdhesives, 'SinglePlyComponentId' | 'SinglePlyUsageId' | 'AdhesiveComponentId' | 'AdhesiveUsageId'>
    )> }
  )> }
);

export type GetAssemblyCountByFiltersQueryVariables = Exact<{
  filters?: Maybe<NavAssembly_Bool_Exp>;
}>;


export type GetAssemblyCountByFiltersQuery = (
  { __typename?: 'query_root' }
  & { NavAssembly_aggregate: (
    { __typename?: 'NavAssembly_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'NavAssembly_aggregate_fields' }
      & Pick<NavAssembly_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetNavAssemblyIdByFiltersQueryVariables = Exact<{
  filters?: Maybe<NavAssembly_Bool_Exp>;
}>;


export type GetNavAssemblyIdByFiltersQuery = (
  { __typename?: 'query_root' }
  & { NavAssembly: Array<(
    { __typename?: 'NavAssembly' }
    & Pick<NavAssembly, 'NavAssemblyId'>
  )> }
);

export type GetAssembliesByFiltersQueryVariables = Exact<{
  filters?: Maybe<NavAssembly_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by: NavAssembly_Order_By;
}>;


export type GetAssembliesByFiltersQuery = (
  { __typename?: 'query_root' }
  & { NavAssembly: Array<(
    { __typename?: 'NavAssembly' }
    & Pick<NavAssembly, 'NavAssemblyId' | 'NavAssemblyName' | 'AssemblyId' | 'AssemblyTypeId' | 'IntFireRatingId' | 'ExtFireRatingId' | 'AssemblyApplicationTypeId' | 'HailRatingId' | 'CoverSecurementMethodId' | 'DeckTypeId' | 'Slope' | 'WindUplift'>
    & { RoofAssembly: (
      { __typename?: 'RoofAssembly' }
      & { C_AssemblyType: (
        { __typename?: 'C_AssemblyType' }
        & Pick<C_AssemblyType, 'AssemblyTypeCode'>
      ), C_IntFireRating: (
        { __typename?: 'C_IntFireRating' }
        & Pick<C_IntFireRating, 'IntFireRatingCode'>
      ), C_ExtFireRating: (
        { __typename?: 'C_ExtFireRating' }
        & Pick<C_ExtFireRating, 'ExtFireRatingCode'>
      ), C_AssemblyApplicationType: (
        { __typename?: 'C_AssemblyApplicationType' }
        & Pick<C_AssemblyApplicationType, 'AssemblyApplicationTypeCode'>
      ), C_HailRating: (
        { __typename?: 'C_HailRating' }
        & Pick<C_HailRating, 'HailRatingCode'>
      ), C_CoverSecurementMethod: (
        { __typename?: 'C_CoverSecurementMethod' }
        & Pick<C_CoverSecurementMethod, 'CoverSecurementMethodCode'>
      ), C_DeckType: (
        { __typename?: 'C_DeckType' }
        & Pick<C_DeckType, 'DeckTypeCode'>
      ) }
    ) }
  )> }
);

export type GetAssemblyIdsByFiltersQueryVariables = Exact<{
  filters?: Maybe<NavAssembly_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by: NavAssembly_Order_By;
}>;


export type GetAssemblyIdsByFiltersQuery = (
  { __typename?: 'query_root' }
  & { NavAssembly: Array<(
    { __typename?: 'NavAssembly' }
    & Pick<NavAssembly, 'NavAssemblyId'>
  )> }
);

export type GetAssembliesByIdsQueryVariables = Exact<{
  ids?: Maybe<NavAssembly_Bool_Exp>;
  order_by: NavAssembly_Order_By;
}>;


export type GetAssembliesByIdsQuery = (
  { __typename?: 'query_root' }
  & { NavAssembly: Array<(
    { __typename?: 'NavAssembly' }
    & Pick<NavAssembly, 'NavAssemblyId' | 'NavAssemblyName' | 'AssemblyId' | 'AssemblyTypeId' | 'IntFireRatingId' | 'ExtFireRatingId' | 'AssemblyApplicationTypeId' | 'HailRatingId' | 'CoverSecurementMethodId' | 'DeckTypeId' | 'Slope' | 'WindUplift' | 'AssemblyTypeCode' | 'IntFireRatingCode' | 'ExtFireRatingCode' | 'AssemblyApplicationTypeCode' | 'HailRatingCode' | 'CoverSecurementMethodCode' | 'DeckTypeCode'>
  )> }
);

export type SearchNavAssembliesQueryVariables = Exact<{
  filters?: Maybe<NavAssembly_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by: NavAssembly_Order_By;
}>;


export type SearchNavAssembliesQuery = (
  { __typename?: 'query_root' }
  & { NavAssembly: Array<(
    { __typename?: 'NavAssembly' }
    & Pick<NavAssembly, 'NavAssemblyId' | 'NavAssemblyName' | 'AssemblyId' | 'AssemblyTypeId' | 'IntFireRatingId' | 'ExtFireRatingId' | 'AssemblyApplicationTypeId' | 'HailRatingId' | 'CoverSecurementMethodId' | 'DeckTypeId' | 'Slope' | 'WindUplift' | 'AssemblyTypeCode' | 'IntFireRatingCode' | 'ExtFireRatingCode' | 'AssemblyApplicationTypeCode' | 'HailRatingCode' | 'CoverSecurementMethodCode' | 'DeckTypeCode'>
  )> }
);

export type SearchNavAssembliesByManufacturerQueryVariables = Exact<{
  filters?: Maybe<VNavAssembly_ManufacturerOnlySearch_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by: VNavAssembly_ManufacturerOnlySearch_Order_By;
}>;


export type SearchNavAssembliesByManufacturerQuery = (
  { __typename?: 'query_root' }
  & { vNavAssembly_ManufacturerOnlySearch: Array<(
    { __typename?: 'vNavAssembly_ManufacturerOnlySearch' }
    & Pick<VNavAssembly_ManufacturerOnlySearch, 'NavAssemblyId'>
  )> }
);

export type GetNavAssembliesByBaseSheetQueryVariables = Exact<{
  filters?: Maybe<VNavAssembly_Search_BaseSheet_Bool_Exp>;
}>;


export type GetNavAssembliesByBaseSheetQuery = (
  { __typename?: 'query_root' }
  & { vNavAssembly_Search_BaseSheet: Array<(
    { __typename?: 'vNavAssembly_Search_BaseSheet' }
    & Pick<VNavAssembly_Search_BaseSheet, 'NavAssemblyId' | 'NavLayerNum'>
  )> }
);

export type GetNavAssembliesBySubstratePrimerQueryVariables = Exact<{
  filters?: Maybe<VNavAssembly_Search_SubstratePrimer_Bool_Exp>;
}>;


export type GetNavAssembliesBySubstratePrimerQuery = (
  { __typename?: 'query_root' }
  & { vNavAssembly_Search_SubstratePrimer: Array<(
    { __typename?: 'vNavAssembly_Search_SubstratePrimer' }
    & Pick<VNavAssembly_Search_SubstratePrimer, 'NavAssemblyId' | 'NavLayerNum'>
  )> }
);

export type SaveContactQueryVariables = Exact<{
  saveContactInfo: SaveContactInfo;
}>;


export type SaveContactQuery = (
  { __typename?: 'query_root' }
  & { contact_api_savecontact?: Maybe<Array<Maybe<(
    { __typename?: 'ContactSaveResult' }
    & Pick<ContactSaveResult, 'ContactInfoId'>
  )>>> }
);

export type GetContactsByUserEmailQueryVariables = Exact<{
  UserEmail?: Maybe<Scalars['String']>;
  RandomId?: Maybe<Scalars['String']>;
}>;


export type GetContactsByUserEmailQuery = (
  { __typename?: 'query_root' }
  & { R_UserContact: Array<(
    { __typename?: 'R_UserContact' }
    & { ContactInfo: (
      { __typename?: 'ContactInfo' }
      & Pick<ContactInfo, 'ContactInfoId' | 'FirstName' | 'LastName' | 'CompanyName' | 'Phone'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'AddressId'>
        & { C_StateProvince?: Maybe<(
          { __typename?: 'C_StateProvince' }
          & Pick<C_StateProvince, 'StateProvinceCode'>
        )>, C_Country?: Maybe<(
          { __typename?: 'C_Country' }
          & Pick<C_Country, 'CountryCode'>
        )> }
      )> }
    ) }
  )> }
);

export type GetContactDetailQueryVariables = Exact<{
  ContactInfoId?: Maybe<Scalars['Int']>;
}>;


export type GetContactDetailQuery = (
  { __typename?: 'query_root' }
  & { ContactInfo: Array<(
    { __typename?: 'ContactInfo' }
    & Pick<ContactInfo, 'FirstName' | 'LastName' | 'Initial' | 'CompanyName' | 'Phone' | 'Fax' | 'Email'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'AddressId' | 'Line1' | 'Line2' | 'Line3' | 'City' | 'PostalCode'>
      & { C_StateProvince?: Maybe<(
        { __typename?: 'C_StateProvince' }
        & Pick<C_StateProvince, 'StateProvinceCode'>
      )>, C_Country?: Maybe<(
        { __typename?: 'C_Country' }
        & Pick<C_Country, 'CountryCode'>
      )> }
    )> }
  )> }
);

export type GetExtendedLayerConfigsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExtendedLayerConfigsQuery = (
  { __typename?: 'query_root' }
  & { ExtendedLayerConfigSplit: Array<(
    { __typename?: 'ExtendedLayerConfigSplit' }
    & Pick<ExtendedLayerConfigSplit, 'ExtendedLayerConfigId' | 'FullConfigString'>
  )> }
);

export type Get_C_AssemblyTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_C_AssemblyTypeQuery = (
  { __typename?: 'query_root' }
  & { C_AssemblyType: Array<(
    { __typename?: 'C_AssemblyType' }
    & Pick<C_AssemblyType, 'AssemblyTypeCode' | 'AssemblyTypeId'>
  )> }
);

export type Get_C_AssemblyApplicationTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_C_AssemblyApplicationTypeQuery = (
  { __typename?: 'query_root' }
  & { C_AssemblyApplicationType: Array<(
    { __typename?: 'C_AssemblyApplicationType' }
    & Pick<C_AssemblyApplicationType, 'AssemblyApplicationTypeCode' | 'AssemblyApplicationTypeId'>
  )> }
);

export type Get_C_CoverSecurementMethodQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_C_CoverSecurementMethodQuery = (
  { __typename?: 'query_root' }
  & { C_CoverSecurementMethod: Array<(
    { __typename?: 'C_CoverSecurementMethod' }
    & Pick<C_CoverSecurementMethod, 'CoverSecurementMethodCode' | 'CoverSecurementMethodId'>
  )> }
);

export type Get_C_DeckTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_C_DeckTypeQuery = (
  { __typename?: 'query_root' }
  & { C_DeckType: Array<(
    { __typename?: 'C_DeckType' }
    & Pick<C_DeckType, 'DeckTypeCode' | 'DeckTypeId'>
  )> }
);

export type Get_C_IntFireRatingQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_C_IntFireRatingQuery = (
  { __typename?: 'query_root' }
  & { C_IntFireRating: Array<(
    { __typename?: 'C_IntFireRating' }
    & Pick<C_IntFireRating, 'IntFireRatingCode' | 'IntFireRatingId'>
  )> }
);

export type Get_C_ExtFireRatingQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_C_ExtFireRatingQuery = (
  { __typename?: 'query_root' }
  & { C_ExtFireRating: Array<(
    { __typename?: 'C_ExtFireRating' }
    & Pick<C_ExtFireRating, 'ExtFireRatingCode' | 'ExtFireRatingId'>
  )> }
);

export type Get_C_HailRatingQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_C_HailRatingQuery = (
  { __typename?: 'query_root' }
  & { C_HailRating: Array<(
    { __typename?: 'C_HailRating' }
    & Pick<C_HailRating, 'HailRatingCode' | 'HailRatingId'>
  )> }
);

export type GetExtendedLayerConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExtendedLayerConfigQuery = (
  { __typename?: 'query_root' }
  & { ExtendedLayerConfigSplit: Array<(
    { __typename?: 'ExtendedLayerConfigSplit' }
    & Pick<ExtendedLayerConfigSplit, 'ExtendedLayerConfigId' | 'CompleteConfigString' | 'ConfigStringGenericSecurementDuplicateId'>
  )> }
);

export type ProductApiSearchQueryVariables = Exact<{
  productSearchCriteria: ProductSearchCriteria;
}>;


export type ProductApiSearchQuery = (
  { __typename?: 'query_root' }
  & { product_api_search?: Maybe<Array<Maybe<(
    { __typename?: 'ProductSearchResultCounting' }
    & Pick<ProductSearchResultCounting, 'ApprovedUseId' | 'ApprovedUseCode' | 'SubcategoryId' | 'SubcategoryName' | 'CategoryId' | 'CategoryName' | 'NumOfProducts'>
  )>>> }
);

export type ProductApiListingQueryVariables = Exact<{
  productSearchCriteriaWithTarget: ProductSearchCriteriaWithTarget;
}>;


export type ProductApiListingQuery = (
  { __typename?: 'query_root' }
  & { product_api_listing?: Maybe<Array<Maybe<(
    { __typename?: 'ProductListItem' }
    & Pick<ProductListItem, 'ComponentId' | 'TradeName' | 'ManufacturerId' | 'ManufacturerName' | 'ApprovedUseId' | 'ApprovedUseCode' | 'CountryId' | 'CountryCode'>
  )>>> }
);

export type ProductApiMatchedAssemblyCountQueryVariables = Exact<{
  producMatchAssemblyCountCriteria: ProducMatchAssemblyCountCriteria;
}>;


export type ProductApiMatchedAssemblyCountQuery = (
  { __typename?: 'query_root' }
  & { product_api_matchedassemblycount?: Maybe<Array<Maybe<(
    { __typename?: 'ProductMatchedAssemblyCounting' }
    & Pick<ProductMatchedAssemblyCounting, 'ApprovedUseId' | 'NumMatchedAssembly'>
  )>>> }
);

export type ProductApiMatchedAssemblyListQueryVariables = Exact<{
  productMatchAssemblyListCriteria: ProductMatchAssemblyListCriteria;
}>;


export type ProductApiMatchedAssemblyListQuery = (
  { __typename?: 'query_root' }
  & { product_api_matchedassemblylist?: Maybe<Array<Maybe<(
    { __typename?: 'AssemblySearchResultItem' }
    & Pick<AssemblySearchResultItem, 'NavAssemblyId' | 'NavAssemblyName' | 'AssemblyApplicationTypeCode' | 'CoverSecurementMethodCode' | 'AssemblyTypeCode' | 'DeckTypeCode' | 'Slope' | 'WindUplift' | 'IntFireRatingCode' | 'ExtFireRatingCode' | 'HailRatingCode'>
  )>>> }
);

export type GetProductDetailAllInfoQueryVariables = Exact<{
  ComponentId?: Maybe<Scalars['Int']>;
}>;


export type GetProductDetailAllInfoQuery = (
  { __typename?: 'query_root' }
  & { ManufacturedProduct: Array<(
    { __typename?: 'ManufacturedProduct' }
    & Pick<ManufacturedProduct, 'ComponentId' | 'TradeName' | 'EnteredAsMetric'>
    & { Manufacturer: (
      { __typename?: 'Manufacturer' }
      & Pick<Manufacturer, 'ManufacturerId' | 'Name'>
      & { Address: (
        { __typename?: 'Address' }
        & { C_Country?: Maybe<(
          { __typename?: 'C_Country' }
          & Pick<C_Country, 'CountryId' | 'CountryCode'>
        )> }
      ) }
    ), Component: (
      { __typename?: 'Component' }
      & Pick<Component, 'Comments'>
      & { ComponentApprovedUses: Array<(
        { __typename?: 'ComponentApprovedUse' }
        & Pick<ComponentApprovedUse, 'Comments'>
        & { C_ApprovedUse: (
          { __typename?: 'C_ApprovedUse' }
          & Pick<C_ApprovedUse, 'ApprovedUseId' | 'ApprovedUseCode'>
        ) }
      )>, ComponentDocs: Array<(
        { __typename?: 'ComponentDoc' }
        & Pick<ComponentDoc, 'ComponentDocId'>
      )> }
    ), AdhesiveFullApplication?: Maybe<(
      { __typename?: 'AdhesiveFullApplication' }
      & Pick<AdhesiveFullApplication, 'AdhesiveMaterialId' | 'AdhesiveTypeId'>
      & { C_AdhesiveMaterial: (
        { __typename?: 'C_AdhesiveMaterial' }
        & Pick<C_AdhesiveMaterial, 'AdhesiveMaterialCode'>
      ), C_AdhesiveType: (
        { __typename?: 'C_AdhesiveType' }
        & Pick<C_AdhesiveType, 'AdhesiveTypeCode'>
      ) }
    )>, AdhesiveRibbon?: Maybe<(
      { __typename?: 'AdhesiveRibbons' }
      & Pick<AdhesiveRibbons, 'AdhesiveMaterialId' | 'AdhesiveTypeId' | 'TapeWidth'>
      & { C_AdhesiveMaterial: (
        { __typename?: 'C_AdhesiveMaterial' }
        & Pick<C_AdhesiveMaterial, 'AdhesiveMaterialCode'>
      ), C_AdhesiveType: (
        { __typename?: 'C_AdhesiveType' }
        & Pick<C_AdhesiveType, 'AdhesiveTypeCode'>
      ) }
    )>, AdhesiveSpot?: Maybe<(
      { __typename?: 'AdhesiveSpots' }
      & Pick<AdhesiveSpots, 'AdhesiveMaterialId' | 'AdhesiveTypeId'>
      & { C_AdhesiveMaterial: (
        { __typename?: 'C_AdhesiveMaterial' }
        & Pick<C_AdhesiveMaterial, 'AdhesiveMaterialCode'>
      ), C_AdhesiveType: (
        { __typename?: 'C_AdhesiveType' }
        & Pick<C_AdhesiveType, 'AdhesiveTypeCode'>
      ) }
    )>, AirRetarder?: Maybe<(
      { __typename?: 'AirRetarder' }
      & Pick<AirRetarder, 'RetarderMaterialId'>
      & { C_RetarderMaterial: (
        { __typename?: 'C_RetarderMaterial' }
        & Pick<C_RetarderMaterial, 'RetarderMaterialCode'>
      ) }
    )>, Anchor?: Maybe<(
      { __typename?: 'Anchor' }
      & Pick<Anchor, 'VerticalLoadMax' | 'ShearLoadMax'>
    )>, BaseSheet?: Maybe<(
      { __typename?: 'BaseSheet' }
      & Pick<BaseSheet, 'IsPermeable' | 'BackingMaterialId' | 'Width' | 'MultiplyMaterialId' | 'ReinforcementId'>
      & { C_BackingMaterial: (
        { __typename?: 'C_BackingMaterial' }
        & Pick<C_BackingMaterial, 'BackingMaterialCode'>
      ), C_MultiplyMaterial: (
        { __typename?: 'C_MultiplyMaterial' }
        & Pick<C_MultiplyMaterial, 'MultiplyMaterialCode'>
      ), C_Reinforcement: (
        { __typename?: 'C_Reinforcement' }
        & Pick<C_Reinforcement, 'ReinforcementCode'>
      ) }
    )>, Coating?: Maybe<(
      { __typename?: 'Coating' }
      & Pick<Coating, 'CoatingMaterialId'>
      & { C_CoatingMaterial: (
        { __typename?: 'C_CoatingMaterial' }
        & Pick<C_CoatingMaterial, 'CoatingMaterialCode'>
      ) }
    )>, Coping?: Maybe<(
      { __typename?: 'Coping' }
      & Pick<Coping, 'FlashingMaterialId' | 'MaxWindRating'>
      & { C_FlashingMaterial: (
        { __typename?: 'C_FlashingMaterial' }
        & Pick<C_FlashingMaterial, 'FlashingMaterialCode'>
      ) }
    )>, CompositePanelCover?: Maybe<(
      { __typename?: 'CompositePanelCover' }
      & Pick<CompositePanelCover, 'InsulationCoreId' | 'WidthMin' | 'WidthMax' | 'ThicknessMin' | 'ThicknessMax' | 'TopFacerMaterialId' | 'FacerThicknessTop' | 'BottomFacerMaterialId' | 'FacerThicknessBottom'>
      & { C_InsulationCore: (
        { __typename?: 'C_InsulationCore' }
        & Pick<C_InsulationCore, 'InsulationCoreCode'>
      ), C_FacerMaterial: (
        { __typename?: 'C_FacerMaterial' }
        & Pick<C_FacerMaterial, 'FacerMaterialCode'>
      ), cFacermaterialByBottomfacermaterialid: (
        { __typename?: 'C_FacerMaterial' }
        & Pick<C_FacerMaterial, 'FacerMaterialCode'>
      ) }
    )>, SinglePlyCover?: Maybe<(
      { __typename?: 'SinglePlyCover' }
      & Pick<SinglePlyCover, 'IsAppliedToAdheredAssemblies' | 'SinglePlyMaterialId' | 'BackingMaterialId' | 'ThicknessMin' | 'ThicknessMax' | 'ReinforcementId' | 'CoverColorId'>
      & { C_SinglePlyMaterial: (
        { __typename?: 'C_SinglePlyMaterial' }
        & Pick<C_SinglePlyMaterial, 'SinglePlyMaterialCode'>
      ), C_BackingMaterial: (
        { __typename?: 'C_BackingMaterial' }
        & Pick<C_BackingMaterial, 'BackingMaterialCode'>
      ), C_Reinforcement: (
        { __typename?: 'C_Reinforcement' }
        & Pick<C_Reinforcement, 'ReinforcementCode'>
      ), C_CoverColor: (
        { __typename?: 'C_CoverColor' }
        & Pick<C_CoverColor, 'CoverColorCode'>
      ) }
    )>, StandingLapSeamCover?: Maybe<(
      { __typename?: 'StandingLapSeamCover' }
      & Pick<StandingLapSeamCover, 'WidthMin' | 'WidthMax' | 'StandingLapSeamMaterialId' | 'NominalWeight' | 'ThicknessMin' | 'ThicknessMax'>
      & { C_StandingLapSeamMaterial: (
        { __typename?: 'C_StandingLapSeamMaterial' }
        & Pick<C_StandingLapSeamMaterial, 'StandingLapSeamMaterialCode'>
      ) }
    )>, CoverBoard?: Maybe<(
      { __typename?: 'CoverBoard' }
      & Pick<CoverBoard, 'CoverBoardMaterialId' | 'BoardProfileId' | 'DensityMin' | 'ThicknessMin' | 'ThicknessMax'>
      & { C_CoverBoardMaterial: (
        { __typename?: 'C_CoverBoardMaterial' }
        & Pick<C_CoverBoardMaterial, 'CoverBoardMaterialCode'>
      ), C_BoardProfile: (
        { __typename?: 'C_BoardProfile' }
        & Pick<C_BoardProfile, 'BoardProfileCode'>
      ), CoverBoardBoardSizes: Array<(
        { __typename?: 'CoverBoardBoardSizes' }
        & Pick<CoverBoardBoardSizes, 'InsBoardSizeId'>
        & { InsBoardSize: (
          { __typename?: 'InsBoardSize' }
          & Pick<InsBoardSize, 'Width' | 'Length' | 'IsMetric'>
        ) }
      )>, CoverBoardFacerTops: Array<(
        { __typename?: 'CoverBoardFacerTop' }
        & Pick<CoverBoardFacerTop, 'FacerId'>
        & { C_Facer: (
          { __typename?: 'C_Facer' }
          & Pick<C_Facer, 'FacerCode'>
        ) }
      )>, CoverBoardFacerBottoms: Array<(
        { __typename?: 'CoverBoardFacerBottom' }
        & Pick<CoverBoardFacerBottom, 'FacerId'>
        & { C_Facer: (
          { __typename?: 'C_Facer' }
          & Pick<C_Facer, 'FacerCode'>
        ) }
      )> }
    )>, CoverStripDisk?: Maybe<(
      { __typename?: 'CoverStripDisk' }
      & Pick<CoverStripDisk, 'SinglePlyMaterialId'>
      & { C_SinglePlyMaterial: (
        { __typename?: 'C_SinglePlyMaterial' }
        & Pick<C_SinglePlyMaterial, 'SinglePlyMaterialCode'>
      ) }
    )>, BasePly?: Maybe<(
      { __typename?: 'BasePly' }
      & Pick<BasePly, 'BackingMaterialId' | 'Width' | 'MultiplyMaterialId' | 'ReinforcementId'>
      & { C_BackingMaterial: (
        { __typename?: 'C_BackingMaterial' }
        & Pick<C_BackingMaterial, 'BackingMaterialCode'>
      ), C_MultiplyMaterial: (
        { __typename?: 'C_MultiplyMaterial' }
        & Pick<C_MultiplyMaterial, 'MultiplyMaterialCode'>
      ), C_Reinforcement: (
        { __typename?: 'C_Reinforcement' }
        & Pick<C_Reinforcement, 'ReinforcementCode'>
      ) }
    )>, CapPly?: Maybe<(
      { __typename?: 'CapPly' }
      & Pick<CapPly, 'BackingMaterialId' | 'Width' | 'MultiplyMaterialId' | 'ReinforcementId' | 'SurfaceTypeId'>
      & { C_BackingMaterial: (
        { __typename?: 'C_BackingMaterial' }
        & Pick<C_BackingMaterial, 'BackingMaterialCode'>
      ), C_MultiplyMaterial: (
        { __typename?: 'C_MultiplyMaterial' }
        & Pick<C_MultiplyMaterial, 'MultiplyMaterialCode'>
      ), C_Reinforcement: (
        { __typename?: 'C_Reinforcement' }
        & Pick<C_Reinforcement, 'ReinforcementCode'>
      ), C_SurfaceType: (
        { __typename?: 'C_SurfaceType' }
        & Pick<C_SurfaceType, 'SurfaceTypeCode'>
      ) }
    )>, OtherPly?: Maybe<(
      { __typename?: 'OtherPly' }
      & Pick<OtherPly, 'BackingMaterialId' | 'MultiplyMaterialId' | 'ReinforcementId' | 'Width'>
      & { C_BackingMaterial: (
        { __typename?: 'C_BackingMaterial' }
        & Pick<C_BackingMaterial, 'BackingMaterialCode'>
      ), C_MultiplyMaterial: (
        { __typename?: 'C_MultiplyMaterial' }
        & Pick<C_MultiplyMaterial, 'MultiplyMaterialCode'>
      ), C_Reinforcement: (
        { __typename?: 'C_Reinforcement' }
        & Pick<C_Reinforcement, 'ReinforcementCode'>
      ) }
    )>, CementitiousWoodFiberDeck?: Maybe<(
      { __typename?: 'CementitiousWoodFiberDeck' }
      & Pick<CementitiousWoodFiberDeck, 'ThicknessMin' | 'ThicknessMax' | 'SpanMax'>
    )>, LightweightInsulatingConcrete?: Maybe<(
      { __typename?: 'LightweightInsulatingConcrete' }
      & Pick<LightweightInsulatingConcrete, 'AggregateId' | 'ThicknessMin' | 'CompressiveStrengthMin' | 'DryDensityMin' | 'CastDensityMin' | 'AdditiveId'>
      & { C_Aggregate: (
        { __typename?: 'C_Aggregate' }
        & Pick<C_Aggregate, 'AggregateCode'>
      ), C_Additive: (
        { __typename?: 'C_Additive' }
        & Pick<C_Additive, 'AdditiveCode'>
      ) }
    )>, SteelDeck?: Maybe<(
      { __typename?: 'SteelDeck' }
      & Pick<SteelDeck, 'IsAcoustical' | 'SteelDeckRibTypeId' | 'DepthMin' | 'DepthMax' | 'WidthMin' | 'WidthMax' | 'DesignThickness' | 'SpanMax'>
      & { C_SteelDeckRibType: (
        { __typename?: 'C_SteelDeckRibType' }
        & Pick<C_SteelDeckRibType, 'SteelDeckRibTypeCode'>
      ) }
    )>, Fascium?: Maybe<(
      { __typename?: 'Fascia' }
      & Pick<Fascia, 'FlashingMaterialId' | 'MaxWindRating'>
      & { C_FlashingMaterial: (
        { __typename?: 'C_FlashingMaterial' }
        & Pick<C_FlashingMaterial, 'FlashingMaterialCode'>
      ) }
    )>, BattenBar?: Maybe<(
      { __typename?: 'BattenBar' }
      & Pick<BattenBar, 'SecurementMaterialId' | 'ThicknessMin' | 'HoleSpacing'>
      & { C_SecurementMaterial: (
        { __typename?: 'C_SecurementMaterial' }
        & Pick<C_SecurementMaterial, 'SecurementMaterialCode'>
      ) }
    )>, Clip?: Maybe<(
      { __typename?: 'Clip' }
      & Pick<Clip, 'SecurementMaterialId'>
      & { C_SecurementMaterial: (
        { __typename?: 'C_SecurementMaterial' }
        & Pick<C_SecurementMaterial, 'SecurementMaterialCode'>
      ) }
    )>, Fastener?: Maybe<(
      { __typename?: 'Fastener' }
      & Pick<Fastener, 'SecurementMaterialId' | 'FastenerTypeId' | 'FastenerCoatingId' | 'FastenerDriveHeadId' | 'Diameter' | 'LengthMin' | 'LengthMax' | 'IsSteelDeckFastener' | 'StructureThicknessMin' | 'StructureThicknessMax' | 'DeckDesignThicknessMax'>
      & { C_SecurementMaterial: (
        { __typename?: 'C_SecurementMaterial' }
        & Pick<C_SecurementMaterial, 'SecurementMaterialCode'>
      ), C_FastenerType: (
        { __typename?: 'C_FastenerType' }
        & Pick<C_FastenerType, 'FastenerTypeCode'>
      ), C_FastenerCoating: (
        { __typename?: 'C_FastenerCoating' }
        & Pick<C_FastenerCoating, 'FastenerCoatingCode'>
      ), C_FastenerDriveHead: (
        { __typename?: 'C_FastenerDriveHead' }
        & Pick<C_FastenerDriveHead, 'FastenerDriveHeadCode'>
      ) }
    )>, PreassembledFastening?: Maybe<(
      { __typename?: 'PreassembledFastening' }
      & Pick<PreassembledFastening, 'FastenerMaterialId' | 'FastenerTypeId' | 'FastenerCoatingId' | 'FastenerDriveHeadId' | 'FastenerDiameter' | 'LengthMin' | 'LengthMax' | 'IsSteelDeckFastener' | 'StructureThicknessMin' | 'StructureThicknessMax' | 'DeckDesignThicknessMax' | 'StressDistributorTypeId' | 'StressDistributorMaterialId' | 'Thickness' | 'StressPlateShapeId' | 'Diameter' | 'Width' | 'Length' | 'ThicknessMin' | 'HoleSpacing'>
      & { cSecurementmaterialByFastenermaterialid: (
        { __typename?: 'C_SecurementMaterial' }
        & Pick<C_SecurementMaterial, 'SecurementMaterialCode'>
      ), C_FastenerType: (
        { __typename?: 'C_FastenerType' }
        & Pick<C_FastenerType, 'FastenerTypeCode'>
      ), C_FastenerCoating: (
        { __typename?: 'C_FastenerCoating' }
        & Pick<C_FastenerCoating, 'FastenerCoatingCode'>
      ), C_FastenerDriveHead: (
        { __typename?: 'C_FastenerDriveHead' }
        & Pick<C_FastenerDriveHead, 'FastenerDriveHeadCode'>
      ), C_StressDistributorType: (
        { __typename?: 'C_StressDistributorType' }
        & Pick<C_StressDistributorType, 'StressDistributorTypeCode'>
      ), C_SecurementMaterial: (
        { __typename?: 'C_SecurementMaterial' }
        & Pick<C_SecurementMaterial, 'SecurementMaterialCode'>
      ), C_StressPlateShape: (
        { __typename?: 'C_StressPlateShape' }
        & Pick<C_StressPlateShape, 'StressPlateShapeCode'>
      ) }
    )>, StressPlate?: Maybe<(
      { __typename?: 'StressPlate' }
      & Pick<StressPlate, 'SecurementMaterialId' | 'Thickness' | 'StressPlateShapeId' | 'Diameter' | 'Width' | 'Length'>
      & { C_SecurementMaterial: (
        { __typename?: 'C_SecurementMaterial' }
        & Pick<C_SecurementMaterial, 'SecurementMaterialCode'>
      ), C_StressPlateShape: (
        { __typename?: 'C_StressPlateShape' }
        & Pick<C_StressPlateShape, 'StressPlateShapeCode'>
      ) }
    )>, FormDeck?: Maybe<(
      { __typename?: 'FormDeck' }
      & Pick<FormDeck, 'ThicknessMin' | 'Depth' | 'IsVented' | 'SpanMax' | 'PercentVented'>
    )>, Gutter?: Maybe<(
      { __typename?: 'Gutter' }
      & Pick<Gutter, 'GutterMaterialId' | 'MaxWindRating'>
      & { C_GutterMaterial: (
        { __typename?: 'C_GutterMaterial' }
        & Pick<C_GutterMaterial, 'GutterMaterialCode'>
      ) }
    )>, BattBlanketInsulation?: Maybe<(
      { __typename?: 'BattBlanketInsulation' }
      & Pick<BattBlanketInsulation, 'BattBlanketMaterialId' | 'BattBlanketFacerMaterialId' | 'ThicknessMin' | 'ThicknessMax'>
      & { C_BattBlanketMaterial: (
        { __typename?: 'C_BattBlanketMaterial' }
        & Pick<C_BattBlanketMaterial, 'BattBlanketMaterialCode'>
      ), C_BattBlanketFacerMaterial: (
        { __typename?: 'C_BattBlanketFacerMaterial' }
        & Pick<C_BattBlanketFacerMaterial, 'BattBlanketFacerMaterialCode'>
      ) }
    )>, BoardStock?: Maybe<(
      { __typename?: 'BoardStock' }
      & Pick<BoardStock, 'BoardStockMaterialId' | 'BoardProfileId' | 'DensityMin' | 'ThicknessMin' | 'ThicknessMax'>
      & { C_BoardStockMaterial: (
        { __typename?: 'C_BoardStockMaterial' }
        & Pick<C_BoardStockMaterial, 'BoardStockMaterialCode'>
      ), C_BoardProfile: (
        { __typename?: 'C_BoardProfile' }
        & Pick<C_BoardProfile, 'BoardProfileCode'>
      ), BoardStockBoardSizes: Array<(
        { __typename?: 'BoardStockBoardSizes' }
        & Pick<BoardStockBoardSizes, 'InsBoardSizeId'>
        & { InsBoardSize: (
          { __typename?: 'InsBoardSize' }
          & Pick<InsBoardSize, 'Width' | 'Length' | 'IsMetric'>
        ) }
      )>, BoardStockFacerTops: Array<(
        { __typename?: 'BoardStockFacerTop' }
        & Pick<BoardStockFacerTop, 'FacerId'>
        & { C_Facer: (
          { __typename?: 'C_Facer' }
          & Pick<C_Facer, 'FacerCode'>
        ) }
      )>, BoardStockFacerBottoms: Array<(
        { __typename?: 'BoardStockFacerBottom' }
        & Pick<BoardStockFacerBottom, 'FacerId'>
        & { C_Facer: (
          { __typename?: 'C_Facer' }
          & Pick<C_Facer, 'FacerCode'>
        ) }
      )> }
    )>, SprayedFoamInsulation?: Maybe<(
      { __typename?: 'SprayedFoamInsulation' }
      & Pick<SprayedFoamInsulation, 'InsulationFoamTypeId' | 'DensityMin' | 'DensityMax'>
      & { C_InsulationFoamType: (
        { __typename?: 'C_InsulationFoamType' }
        & Pick<C_InsulationFoamType, 'InsulationFoamTypeCode'>
      ) }
    )>, LinerPanel?: Maybe<(
      { __typename?: 'LinerPanel' }
      & Pick<LinerPanel, 'LinerPanelMaterialId'>
      & { C_LinerPanelMaterial: (
        { __typename?: 'C_LinerPanelMaterial' }
        & Pick<C_LinerPanelMaterial, 'LinerPanelMaterialCode'>
      ) }
    )>, MaintenanceCoating?: Maybe<(
      { __typename?: 'MaintenanceCoating' }
      & Pick<MaintenanceCoating, 'MaintenanceCoatingMaterialId'>
      & { C_MaintenanceCoatingMaterial: (
        { __typename?: 'C_MaintenanceCoatingMaterial' }
        & Pick<C_MaintenanceCoatingMaterial, 'MaintenanceCoatingMaterialCode'>
      ), MaintenanceCoatingSubstrates: Array<(
        { __typename?: 'MaintenanceCoatingSubstrate' }
        & Pick<MaintenanceCoatingSubstrate, 'MaintenanceCoatingSubstrateId'>
        & { C_MaintenanceCoatingSubstrate: (
          { __typename?: 'C_MaintenanceCoatingSubstrate' }
          & Pick<C_MaintenanceCoatingSubstrate, 'MaintenanceCoatingSubstrateCode'>
        ) }
      )> }
    )>, SeparatorSheet?: Maybe<(
      { __typename?: 'SeparatorSheet' }
      & Pick<SeparatorSheet, 'RetarderMaterialId'>
      & { C_RetarderMaterial: (
        { __typename?: 'C_RetarderMaterial' }
        & Pick<C_RetarderMaterial, 'RetarderMaterialCode'>
      ) }
    )>, SubstratePrimer?: Maybe<(
      { __typename?: 'SubstratePrimer' }
      & Pick<SubstratePrimer, 'SubstratePrimerMaterialId'>
      & { C_SubstratePrimerMaterial: (
        { __typename?: 'C_SubstratePrimerMaterial' }
        & Pick<C_SubstratePrimerMaterial, 'SubstratePrimerMaterialCode'>
      ) }
    )>, Surfacing?: Maybe<(
      { __typename?: 'Surfacing' }
      & Pick<Surfacing, 'SurfacingMaterialId'>
      & { C_SurfacingMaterial: (
        { __typename?: 'C_SurfacingMaterial' }
        & Pick<C_SurfacingMaterial, 'SurfacingMaterialCode'>
      ) }
    )>, ThermalBarrier?: Maybe<(
      { __typename?: 'ThermalBarrier' }
      & Pick<ThermalBarrier, 'ThermalBarrierMaterialId' | 'BoardProfileId' | 'DensityMin' | 'ThicknessMin' | 'ThicknessMax'>
      & { C_ThermalBarrierMaterial: (
        { __typename?: 'C_ThermalBarrierMaterial' }
        & Pick<C_ThermalBarrierMaterial, 'ThermalBarrierMaterialCode'>
      ), C_BoardProfile: (
        { __typename?: 'C_BoardProfile' }
        & Pick<C_BoardProfile, 'BoardProfileCode'>
      ), ThermalBarrierBoardSizes: Array<(
        { __typename?: 'ThermalBarrierBoardSizes' }
        & Pick<ThermalBarrierBoardSizes, 'InsBoardSizeId'>
        & { InsBoardSize: (
          { __typename?: 'InsBoardSize' }
          & Pick<InsBoardSize, 'Width' | 'Length' | 'IsMetric'>
        ) }
      )>, ThermalBarrierFacerTops: Array<(
        { __typename?: 'ThermalBarrierFacerTop' }
        & Pick<ThermalBarrierFacerTop, 'FacerId'>
        & { C_Facer: (
          { __typename?: 'C_Facer' }
          & Pick<C_Facer, 'FacerCode'>
        ) }
      )>, ThermalBarrierFacerBottoms: Array<(
        { __typename?: 'ThermalBarrierFacerBottom' }
        & Pick<ThermalBarrierFacerBottom, 'FacerId'>
        & { C_Facer: (
          { __typename?: 'C_Facer' }
          & Pick<C_Facer, 'FacerCode'>
        ) }
      )> }
    )>, VaporRetarder?: Maybe<(
      { __typename?: 'VaporRetarder' }
      & Pick<VaporRetarder, 'RetarderMaterialId'>
      & { C_RetarderMaterial: (
        { __typename?: 'C_RetarderMaterial' }
        & Pick<C_RetarderMaterial, 'RetarderMaterialCode'>
      ) }
    )>, Coat?: Maybe<(
      { __typename?: 'Coat' }
      & Pick<Coat, 'LiquidApplicationId' | 'LiquidAppliedMaterialId' | 'CoverColorId'>
      & { C_LiquidApplication: (
        { __typename?: 'C_LiquidApplication' }
        & Pick<C_LiquidApplication, 'LiquidApplicationCode'>
      ), C_LiquidAppliedMaterial: (
        { __typename?: 'C_LiquidAppliedMaterial' }
        & Pick<C_LiquidAppliedMaterial, 'LiquidAppliedMaterialCode'>
      ), C_CoverColor: (
        { __typename?: 'C_CoverColor' }
        & Pick<C_CoverColor, 'CoverColorCode'>
      ) }
    )>, TopCoat?: Maybe<(
      { __typename?: 'TopCoat' }
      & Pick<TopCoat, 'LiquidApplicationId' | 'LiquidAppliedMaterialId' | 'CoverColorId'>
      & { C_LiquidApplication: (
        { __typename?: 'C_LiquidApplication' }
        & Pick<C_LiquidApplication, 'LiquidApplicationCode'>
      ), C_LiquidAppliedMaterial: (
        { __typename?: 'C_LiquidAppliedMaterial' }
        & Pick<C_LiquidAppliedMaterial, 'LiquidAppliedMaterialCode'>
      ), C_CoverColor: (
        { __typename?: 'C_CoverColor' }
        & Pick<C_CoverColor, 'CoverColorCode'>
      ) }
    )>, BaseCoat?: Maybe<(
      { __typename?: 'BaseCoat' }
      & Pick<BaseCoat, 'LiquidApplicationId' | 'LiquidAppliedMaterialId' | 'CoverColorId'>
      & { C_LiquidApplication: (
        { __typename?: 'C_LiquidApplication' }
        & Pick<C_LiquidApplication, 'LiquidApplicationCode'>
      ), C_LiquidAppliedMaterial: (
        { __typename?: 'C_LiquidAppliedMaterial' }
        & Pick<C_LiquidAppliedMaterial, 'LiquidAppliedMaterialCode'>
      ), C_CoverColor: (
        { __typename?: 'C_CoverColor' }
        & Pick<C_CoverColor, 'CoverColorCode'>
      ) }
    )>, Shingle?: Maybe<(
      { __typename?: 'Shingles' }
      & Pick<Shingles, 'Length' | 'Width' | 'NominalWeight' | 'ShinglesMaterialId' | 'ShinglesReinforcementId' | 'ShinglesSurfacingId' | 'ShinglesHailId' | 'ShinglesWindSpeedId' | 'WindUplift'>
      & { C_ShinglesMaterial: (
        { __typename?: 'C_ShinglesMaterial' }
        & Pick<C_ShinglesMaterial, 'ShinglesMaterialCode'>
      ), C_ShinglesReinforcement: (
        { __typename?: 'C_ShinglesReinforcement' }
        & Pick<C_ShinglesReinforcement, 'ShinglesReinforcementCode'>
      ), C_ShinglesSurfacing: (
        { __typename?: 'C_ShinglesSurfacing' }
        & Pick<C_ShinglesSurfacing, 'ShinglesSurfacingCode'>
      ), C_ShinglesHail: (
        { __typename?: 'C_ShinglesHail' }
        & Pick<C_ShinglesHail, 'ShinglesHailCode'>
      ), C_ShinglesWindSpeed: (
        { __typename?: 'C_ShinglesWindSpeed' }
        & Pick<C_ShinglesWindSpeed, 'ShinglesWindSpeedCode'>
      ) }
    )> }
  )> }
);

export type GetComponentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetComponentsQuery = (
  { __typename?: 'query_root' }
  & { Component: Array<(
    { __typename?: 'Component' }
    & Pick<Component, 'ComponentId'>
    & { C_ComponentType: (
      { __typename?: 'C_ComponentType' }
      & Pick<C_ComponentType, 'ComponentTypeCode'>
    ), C_Status: (
      { __typename?: 'C_Status' }
      & Pick<C_Status, 'StatusCode'>
      & { Manufacturers: Array<(
        { __typename?: 'Manufacturer' }
        & Pick<Manufacturer, 'Name'>
      )> }
    ) }
  )> }
);

export type GetAssemblyNumberInfoQueryVariables = Exact<{
  navAssemblyName?: Maybe<Scalars['String']>;
}>;


export type GetAssemblyNumberInfoQuery = (
  { __typename?: 'query_root' }
  & { NavAssembly: Array<(
    { __typename?: 'NavAssembly' }
    & Pick<NavAssembly, 'NavAssemblyId' | 'NavAssemblyName' | 'AssemblyId'>
    & { RoofAssembly: (
      { __typename?: 'RoofAssembly' }
      & Pick<RoofAssembly, 'AssemblyTypeId' | 'IntFireRatingId' | 'ExtFireRatingId' | 'AssemblyApplicationTypeId' | 'HailRatingId' | 'CoverSecurementMethodId' | 'DeckTypeId' | 'Slope' | 'WindUplift'>
      & { C_AssemblyType: (
        { __typename?: 'C_AssemblyType' }
        & Pick<C_AssemblyType, 'AssemblyTypeCode'>
      ), C_IntFireRating: (
        { __typename?: 'C_IntFireRating' }
        & Pick<C_IntFireRating, 'IntFireRatingCode'>
      ), C_ExtFireRating: (
        { __typename?: 'C_ExtFireRating' }
        & Pick<C_ExtFireRating, 'ExtFireRatingCode'>
      ), C_AssemblyApplicationType: (
        { __typename?: 'C_AssemblyApplicationType' }
        & Pick<C_AssemblyApplicationType, 'AssemblyApplicationTypeCode'>
      ), C_HailRating: (
        { __typename?: 'C_HailRating' }
        & Pick<C_HailRating, 'HailRatingCode'>
      ), C_CoverSecurementMethod: (
        { __typename?: 'C_CoverSecurementMethod' }
        & Pick<C_CoverSecurementMethod, 'CoverSecurementMethodCode'>
      ), C_DeckType: (
        { __typename?: 'C_DeckType' }
        & Pick<C_DeckType, 'DeckTypeCode'>
      ) }
    ) }
  )> }
);

export type GetNavAssemblyDetailByIdQueryVariables = Exact<{
  NavAssemblyId?: Maybe<Scalars['Int']>;
}>;


export type GetNavAssemblyDetailByIdQuery = (
  { __typename?: 'query_root' }
  & { NavAssembly: Array<(
    { __typename?: 'NavAssembly' }
    & Pick<NavAssembly, 'NavAssemblyId' | 'NavAssemblyName' | 'AssemblyId'>
    & { RoofAssembly: (
      { __typename?: 'RoofAssembly' }
      & Pick<RoofAssembly, 'AssemblyTypeId' | 'IntFireRatingId' | 'ExtFireRatingId' | 'AssemblyApplicationTypeId' | 'HailRatingId' | 'CoverSecurementMethodId' | 'DeckTypeId' | 'Slope' | 'WindUplift' | 'IsUsedWithCombustibleWalls'>
      & { C_AssemblyType: (
        { __typename?: 'C_AssemblyType' }
        & Pick<C_AssemblyType, 'AssemblyTypeCode'>
      ), C_IntFireRating: (
        { __typename?: 'C_IntFireRating' }
        & Pick<C_IntFireRating, 'IntFireRatingCode'>
      ), C_ExtFireRating: (
        { __typename?: 'C_ExtFireRating' }
        & Pick<C_ExtFireRating, 'ExtFireRatingCode'>
      ), C_AssemblyApplicationType: (
        { __typename?: 'C_AssemblyApplicationType' }
        & Pick<C_AssemblyApplicationType, 'AssemblyApplicationTypeCode'>
      ), C_HailRating: (
        { __typename?: 'C_HailRating' }
        & Pick<C_HailRating, 'HailRatingCode'>
      ), C_CoverSecurementMethod: (
        { __typename?: 'C_CoverSecurementMethod' }
        & Pick<C_CoverSecurementMethod, 'CoverSecurementMethodCode'>
      ), C_DeckType: (
        { __typename?: 'C_DeckType' }
        & Pick<C_DeckType, 'DeckTypeCode'>
      ) }
    ), NavLayers: Array<(
      { __typename?: 'NavLayer' }
      & Pick<NavLayer, 'NavLayerId' | 'NavLayerNum' | 'LayerId' | 'LayerTypeId'>
      & { C_LayerType: (
        { __typename?: 'C_LayerType' }
        & Pick<C_LayerType, 'LayerTypeCode'>
      ) }
    )> }
  )> }
);

export type SaveRoofAreaBaseOnlyQueryVariables = Exact<{
  saveRoofAreaBaseInfo: SaveRoofAreaBaseInfo;
}>;


export type SaveRoofAreaBaseOnlyQuery = (
  { __typename?: 'query_root' }
  & { roofarea_api_saveroofareabase?: Maybe<Array<Maybe<(
    { __typename?: 'RoofAreaBaseSaveResult' }
    & Pick<RoofAreaBaseSaveResult, 'RoofAreaId'>
  )>>> }
);

export type SaveRoofAreaIncludeRatingQueryVariables = Exact<{
  saveRoofAreaWithRating: SaveRoofAreaWithRating;
}>;


export type SaveRoofAreaIncludeRatingQuery = (
  { __typename?: 'query_root' }
  & { roofarea_api_saveroofareawithrating?: Maybe<Array<Maybe<(
    { __typename?: 'RoofAreaWithRatingSaveResult' }
    & Pick<RoofAreaWithRatingSaveResult, 'RoofAreaId'>
  )>>> }
);

export type GetRoofAreaInfoByIdQueryVariables = Exact<{
  RoofAreaId?: Maybe<Scalars['Int']>;
}>;


export type GetRoofAreaInfoByIdQuery = (
  { __typename?: 'query_root' }
  & { RoofArea: Array<(
    { __typename?: 'RoofArea' }
    & Pick<RoofArea, 'RoofAreaId' | 'IntFireRatingId' | 'HailRatingId' | 'GroundRoughnessRatingId' | 'ExtFireRatingId' | 'Name' | 'Description' | 'Width' | 'Length' | 'Height' | 'BuildingName' | 'BuildingEnclosureId' | 'WindBorneDebrisId' | 'Slope' | 'WindSpeed' | 'ContinuousParapet' | 'CalculatorVersion' | 'CalculationLastUpdatedDate' | 'TropicalCycloneRegion'>
    & { RoofAreaWindPressures: Array<(
      { __typename?: 'RoofAreaWindPressure' }
      & Pick<RoofAreaWindPressure, 'RoofAreaWindPressureId' | 'PressureZoneId' | 'WindUplift' | 'CopingRating' | 'FasciaRating'>
    )>, RoofProject: (
      { __typename?: 'RoofProject' }
      & Pick<RoofProject, 'ProjectId' | 'Name' | 'Description' | 'Notes'>
    ) }
  )> }
);

export type GetRoofAreasByProjectIdQueryVariables = Exact<{
  ProjectId?: Maybe<Scalars['Int']>;
  RandomId?: Maybe<Scalars['String']>;
}>;


export type GetRoofAreasByProjectIdQuery = (
  { __typename?: 'query_root' }
  & { RoofArea: Array<(
    { __typename?: 'RoofArea' }
    & Pick<RoofArea, 'RoofAreaId' | 'Name'>
  )> }
);

export type GetRoofAreaReportInfoByIdQueryVariables = Exact<{
  RoofAreaId?: Maybe<Scalars['Int']>;
}>;


export type GetRoofAreaReportInfoByIdQuery = (
  { __typename?: 'query_root' }
  & { RoofArea: Array<(
    { __typename?: 'RoofArea' }
    & Pick<RoofArea, 'RoofAreaId' | 'IntFireRatingId' | 'HailRatingId' | 'GroundRoughnessRatingId' | 'ExtFireRatingId' | 'Name' | 'Description' | 'Width' | 'Length' | 'Height' | 'BuildingName' | 'BuildingEnclosureId' | 'WindBorneDebrisId' | 'Slope' | 'WindSpeed' | 'ContinuousParapet' | 'CalculatorVersion' | 'CalculationLastUpdatedDate' | 'TropicalCycloneRegion'>
    & { RoofAreaWindPressures: Array<(
      { __typename?: 'RoofAreaWindPressure' }
      & Pick<RoofAreaWindPressure, 'RoofAreaWindPressureId' | 'PressureZoneId' | 'WindUplift' | 'CopingRating' | 'FasciaRating'>
    )>, RoofProject: (
      { __typename?: 'RoofProject' }
      & Pick<RoofProject, 'ProjectId' | 'Name' | 'Description' | 'Notes'>
    ), R_RoofAreaContacts: Array<(
      { __typename?: 'R_RoofAreaContact' }
      & Pick<R_RoofAreaContact, 'ProjectRoleId'>
      & { ContactInfo: (
        { __typename?: 'ContactInfo' }
        & Pick<ContactInfo, 'ContactInfoId' | 'FirstName' | 'LastName' | 'Initial' | 'CompanyName' | 'Phone' | 'Fax' | 'Email'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'Line1' | 'Line2' | 'City' | 'PostalCode'>
          & { C_StateProvince?: Maybe<(
            { __typename?: 'C_StateProvince' }
            & Pick<C_StateProvince, 'StateProvinceCode'>
          )>, C_Country?: Maybe<(
            { __typename?: 'C_Country' }
            & Pick<C_Country, 'CountryCode'>
          )> }
        )> }
      ) }
    )> }
  )> }
);

export type GetRoofProjectBaseInfoByIdQueryVariables = Exact<{
  ProjectId?: Maybe<Scalars['Int']>;
  RandomId?: Maybe<Scalars['String']>;
}>;


export type GetRoofProjectBaseInfoByIdQuery = (
  { __typename?: 'query_root' }
  & { RoofProject: Array<(
    { __typename?: 'RoofProject' }
    & Pick<RoofProject, 'ProjectId' | 'UserAccountId' | 'Name' | 'Description' | 'IndexNumber' | 'Notes'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'AddressId' | 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'StateId' | 'CountryId'>
      & { C_StateProvince?: Maybe<(
        { __typename?: 'C_StateProvince' }
        & Pick<C_StateProvince, 'StateProvinceCode'>
      )>, C_Country?: Maybe<(
        { __typename?: 'C_Country' }
        & Pick<C_Country, 'CountryCode'>
      )> }
    )> }
  )> }
);

export type GetRoofProjectCompleteInfoByIdQueryVariables = Exact<{
  ProjectId?: Maybe<Scalars['Int']>;
}>;


export type GetRoofProjectCompleteInfoByIdQuery = (
  { __typename?: 'query_root' }
  & { RoofProject: Array<(
    { __typename?: 'RoofProject' }
    & Pick<RoofProject, 'ProjectId' | 'UserAccountId' | 'Name' | 'Description' | 'IndexNumber' | 'Notes'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'AddressId' | 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'StateId' | 'CountryId'>
      & { C_StateProvince?: Maybe<(
        { __typename?: 'C_StateProvince' }
        & Pick<C_StateProvince, 'StateProvinceCode'>
      )>, C_Country?: Maybe<(
        { __typename?: 'C_Country' }
        & Pick<C_Country, 'CountryCode'>
      )> }
    )>, R_ProjectNotes: Array<(
      { __typename?: 'R_ProjectNote' }
      & { Note: (
        { __typename?: 'Note' }
        & Pick<Note, 'NoteId' | 'Title'>
      ) }
    )>, RoofAreas: Array<(
      { __typename?: 'RoofArea' }
      & Pick<RoofArea, 'RoofAreaId' | 'Name' | 'Description'>
      & { R_RoofAreaNotes: Array<(
        { __typename?: 'R_RoofAreaNote' }
        & { Note: (
          { __typename?: 'Note' }
          & Pick<Note, 'NoteId' | 'Title'>
        ) }
      )>, R_RoofAreaScenarios: Array<(
        { __typename?: 'R_RoofAreaScenario' }
        & { Scenario: (
          { __typename?: 'Scenario' }
          & Pick<Scenario, 'ScenarioId' | 'Name' | 'ScenarioStatusId'>
          & { R_ScenarioNotes: Array<(
            { __typename?: 'R_ScenarioNote' }
            & { Note: (
              { __typename?: 'Note' }
              & Pick<Note, 'NoteId' | 'Title'>
            ) }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type GetRoofProjectsByUserIdQueryVariables = Exact<{
  UserAccountId?: Maybe<Scalars['Int']>;
}>;


export type GetRoofProjectsByUserIdQuery = (
  { __typename?: 'query_root' }
  & { RoofProject: Array<(
    { __typename?: 'RoofProject' }
    & Pick<RoofProject, 'ProjectId' | 'UserAccountId' | 'Name' | 'Description' | 'IndexNumber' | 'Notes'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'AddressId' | 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'StateId' | 'CountryId'>
      & { C_StateProvince?: Maybe<(
        { __typename?: 'C_StateProvince' }
        & Pick<C_StateProvince, 'StateProvinceCode'>
      )>, C_Country?: Maybe<(
        { __typename?: 'C_Country' }
        & Pick<C_Country, 'CountryCode'>
      )> }
    )> }
  )> }
);

export type GetRoofProjectsByEmailQueryVariables = Exact<{
  UserEmail?: Maybe<Scalars['String']>;
  RandomId?: Maybe<Scalars['String']>;
}>;


export type GetRoofProjectsByEmailQuery = (
  { __typename?: 'query_root' }
  & { RoofProject: Array<(
    { __typename?: 'RoofProject' }
    & Pick<RoofProject, 'ProjectId' | 'UserAccountId' | 'Name' | 'Description' | 'IndexNumber' | 'Notes'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'AddressId' | 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'StateId' | 'CountryId'>
      & { C_StateProvince?: Maybe<(
        { __typename?: 'C_StateProvince' }
        & Pick<C_StateProvince, 'StateProvinceCode'>
      )>, C_Country?: Maybe<(
        { __typename?: 'C_Country' }
        & Pick<C_Country, 'CountryCode'>
      )> }
    )> }
  )> }
);

export type AddNewAddressMutationVariables = Exact<{
  CountryId?: Maybe<Scalars['Int']>;
  StateId?: Maybe<Scalars['Int']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  Line3?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
}>;


export type AddNewAddressMutation = (
  { __typename?: 'mutation_root' }
  & { insert_Address?: Maybe<(
    { __typename?: 'Address_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Address' }
      & Pick<Address, 'AddressId'>
    )> }
  )> }
);

export type AddNewRoofProjectReturnProjectIdMutationVariables = Exact<{
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IndexNumber?: Maybe<Scalars['String']>;
  UserAccountId?: Maybe<Scalars['Int']>;
  AddressId?: Maybe<Scalars['Int']>;
}>;


export type AddNewRoofProjectReturnProjectIdMutation = (
  { __typename?: 'mutation_root' }
  & { insert_RoofProject?: Maybe<(
    { __typename?: 'RoofProject_mutation_response' }
    & { returning: Array<(
      { __typename?: 'RoofProject' }
      & Pick<RoofProject, 'ProjectId'>
    )> }
  )> }
);

export type AddNewRoofProjectReturnProjectInfoMutationVariables = Exact<{
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IndexNumber?: Maybe<Scalars['String']>;
  UserAccountId?: Maybe<Scalars['Int']>;
  AddressId?: Maybe<Scalars['Int']>;
}>;


export type AddNewRoofProjectReturnProjectInfoMutation = (
  { __typename?: 'mutation_root' }
  & { insert_RoofProject?: Maybe<(
    { __typename?: 'RoofProject_mutation_response' }
    & { returning: Array<(
      { __typename?: 'RoofProject' }
      & Pick<RoofProject, 'ProjectId'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'AddressId' | 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'StateId' | 'CountryId'>
        & { C_StateProvince?: Maybe<(
          { __typename?: 'C_StateProvince' }
          & Pick<C_StateProvince, 'StateProvinceCode'>
        )>, C_Country?: Maybe<(
          { __typename?: 'C_Country' }
          & Pick<C_Country, 'CountryCode'>
        )> }
      )> }
    )> }
  )> }
);

export type AddNewNoteMutationVariables = Exact<{
  Title?: Maybe<Scalars['String']>;
  Body?: Maybe<Scalars['String']>;
}>;


export type AddNewNoteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_Note?: Maybe<(
    { __typename?: 'Note_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Note' }
      & Pick<Note, 'NoteId'>
    )> }
  )> }
);

export type AddProjectNoteRelationMutationVariables = Exact<{
  NoteId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
}>;


export type AddProjectNoteRelationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_R_ProjectNote?: Maybe<(
    { __typename?: 'R_ProjectNote_mutation_response' }
    & { returning: Array<(
      { __typename?: 'R_ProjectNote' }
      & Pick<R_ProjectNote, 'NoteId' | 'ProjectId'>
    )> }
  )> }
);

export type DeleteProjectNoteMutationVariables = Exact<{
  NoteId?: Maybe<Scalars['Int']>;
  ProjectId?: Maybe<Scalars['Int']>;
}>;


export type DeleteProjectNoteMutation = (
  { __typename?: 'mutation_root' }
  & { delete_R_ProjectNote?: Maybe<(
    { __typename?: 'R_ProjectNote_mutation_response' }
    & Pick<R_ProjectNote_Mutation_Response, 'affected_rows'>
  )>, delete_Note?: Maybe<(
    { __typename?: 'Note_mutation_response' }
    & Pick<Note_Mutation_Response, 'affected_rows'>
  )> }
);

export type SaveUserProjectQueryVariables = Exact<{
  saveProjectBaseInfo: RoofProjectBaseInfoSave;
}>;


export type SaveUserProjectQuery = (
  { __typename?: 'query_root' }
  & { roofproject_api_savebaseinfo?: Maybe<Array<Maybe<(
    { __typename?: 'RoofProjectBaseInfoSaveResult' }
    & Pick<RoofProjectBaseInfoSaveResult, 'ProjectId'>
  )>>> }
);

export type GetCountryListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountryListQuery = (
  { __typename?: 'query_root' }
  & { C_Country: Array<(
    { __typename?: 'C_Country' }
    & Pick<C_Country, 'CountryId' | 'CountryCode' | 'Dsc'>
  )> }
);

export type GetStateProvinceListByCountryQueryVariables = Exact<{
  CountryId?: Maybe<Scalars['Int']>;
}>;


export type GetStateProvinceListByCountryQuery = (
  { __typename?: 'query_root' }
  & { R_Country_StateProvince: Array<(
    { __typename?: 'R_Country_StateProvince' }
    & Pick<R_Country_StateProvince, 'StateProvinceId'>
    & { C_StateProvince: (
      { __typename?: 'C_StateProvince' }
      & Pick<C_StateProvince, 'StateProvinceCode' | 'Dsc'>
    ) }
  )> }
);

export type GetProjectsWithChildRoofAreaScenarioByEmailQueryVariables = Exact<{
  UserEmail?: Maybe<Scalars['String']>;
  RandomId?: Maybe<Scalars['String']>;
}>;


export type GetProjectsWithChildRoofAreaScenarioByEmailQuery = (
  { __typename?: 'query_root' }
  & { RoofProject: Array<(
    { __typename?: 'RoofProject' }
    & Pick<RoofProject, 'ProjectId' | 'UserAccountId' | 'Name' | 'Description' | 'IndexNumber' | 'Notes'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'AddressId' | 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'StateId' | 'CountryId'>
      & { C_StateProvince?: Maybe<(
        { __typename?: 'C_StateProvince' }
        & Pick<C_StateProvince, 'StateProvinceCode'>
      )>, C_Country?: Maybe<(
        { __typename?: 'C_Country' }
        & Pick<C_Country, 'CountryCode'>
      )> }
    )>, RoofAreas: Array<(
      { __typename?: 'RoofArea' }
      & Pick<RoofArea, 'RoofAreaId' | 'Name'>
      & { R_RoofAreaScenarios: Array<(
        { __typename?: 'R_RoofAreaScenario' }
        & { Scenario: (
          { __typename?: 'Scenario' }
          & Pick<Scenario, 'ScenarioId' | 'Name'>
        ) }
      )> }
    )> }
  )> }
);

export type AssemblySearchRoofTypeCountQueryVariables = Exact<{
  filters?: Maybe<VNavAssembly_RoofTypeCount_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by: VNavAssembly_RoofTypeCount_Order_By;
}>;


export type AssemblySearchRoofTypeCountQuery = (
  { __typename?: 'query_root' }
  & { vNavAssembly_RoofTypeCount: Array<(
    { __typename?: 'vNavAssembly_RoofTypeCount' }
    & Pick<VNavAssembly_RoofTypeCount, 'LayerConfigId' | 'ConfigString' | 'NumOfAssembly'>
  )> }
);

export type GetNavAssembliesByLayerConfigIdQueryVariables = Exact<{
  LayerConfigId?: Maybe<Scalars['Int']>;
}>;


export type GetNavAssembliesByLayerConfigIdQuery = (
  { __typename?: 'query_root' }
  & { NavAssembly: Array<(
    { __typename?: 'NavAssembly' }
    & Pick<NavAssembly, 'NavAssemblyId' | 'Slope' | 'WindUplift' | 'AssemblyTypeCode' | 'IntFireRatingCode' | 'ExtFireRatingCode' | 'AssemblyApplicationTypeCode' | 'HailRatingCode' | 'CoverSecurementMethodCode' | 'DeckTypeCode'>
  )> }
);

export type GetNavAssemblyLayersQueryVariables = Exact<{
  NavAssemblyId?: Maybe<Scalars['Int']>;
}>;


export type GetNavAssemblyLayersQuery = (
  { __typename?: 'query_root' }
  & { vNavAssembly_Layers: Array<(
    { __typename?: 'vNavAssembly_Layers' }
    & Pick<VNavAssembly_Layers, 'NavLayerId' | 'NavLayerNum' | 'LayerTypeId' | 'LayerTypeCode' | 'FromNavLayerId' | 'FromNavLayerNum' | 'ToNavLayerId' | 'ToNavLayerNum'>
  )> }
);

export type GetRoofTypeCountByFiltersQueryVariables = Exact<{
  filters?: Maybe<VNavAssembly_RoofTypeCount_Bool_Exp>;
}>;


export type GetRoofTypeCountByFiltersQuery = (
  { __typename?: 'query_root' }
  & { vNavAssembly_RoofTypeCount_aggregate: (
    { __typename?: 'vNavAssembly_RoofTypeCount_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'vNavAssembly_RoofTypeCount_aggregate_fields' }
      & Pick<VNavAssembly_RoofTypeCount_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type AddUserReportBugMutationVariables = Exact<{
  UserReportBug: UserReportBug_Insert_Input;
}>;


export type AddUserReportBugMutation = (
  { __typename?: 'mutation_root' }
  & { insert_UserReportBug?: Maybe<(
    { __typename?: 'UserReportBug_mutation_response' }
    & { returning: Array<(
      { __typename?: 'UserReportBug' }
      & Pick<UserReportBug, 'ReportBugId' | 'ReportBugGuid'>
    )> }
  )> }
);

export type GetUserReportBugSearchCriteriaQueryVariables = Exact<{
  ReportBugGuid?: Maybe<Scalars['String']>;
}>;


export type GetUserReportBugSearchCriteriaQuery = (
  { __typename?: 'query_root' }
  & { UserReportBug: Array<(
    { __typename?: 'UserReportBug' }
    & Pick<UserReportBug, 'SearchCriteriaData'>
  )> }
);

export type AddUserShareLinkMutationVariables = Exact<{
  UserShareLink: UserShareLink_Insert_Input;
}>;


export type AddUserShareLinkMutation = (
  { __typename?: 'mutation_root' }
  & { insert_UserShareLink?: Maybe<(
    { __typename?: 'UserShareLink_mutation_response' }
    & { returning: Array<(
      { __typename?: 'UserShareLink' }
      & Pick<UserShareLink, 'ShareLinkId' | 'ShareLinkGuid'>
    )> }
  )> }
);

export type GetUserShareLinkSearchCriteriaQueryVariables = Exact<{
  ShareLinkGuid?: Maybe<Scalars['String']>;
}>;


export type GetUserShareLinkSearchCriteriaQuery = (
  { __typename?: 'query_root' }
  & { UserShareLink: Array<(
    { __typename?: 'UserShareLink' }
    & Pick<UserShareLink, 'SearchCriteriaData'>
  )> }
);

export type SaveScenarioQueryVariables = Exact<{
  scenarioInfoSave: ScenarioInfoSave;
}>;


export type SaveScenarioQuery = (
  { __typename?: 'query_root' }
  & { scenario_api_savescenario?: Maybe<Array<Maybe<(
    { __typename?: 'ScenarioSaveResult' }
    & Pick<ScenarioSaveResult, 'ScenarioId'>
  )>>> }
);

export type GetScenarioDetailQueryVariables = Exact<{
  ScenarioId?: Maybe<Scalars['Int']>;
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  RandomId?: Maybe<Scalars['String']>;
}>;


export type GetScenarioDetailQuery = (
  { __typename?: 'query_root' }
  & { Scenario: Array<(
    { __typename?: 'Scenario' }
    & Pick<Scenario, 'ScenarioId' | 'ScenarioStatusId' | 'Name' | 'NavAssemblyId' | 'NavAssemblyName' | 'DateCreated' | 'ScenarioTypeId' | 'Comments'>
    & { ScenarioLayers: Array<(
      { __typename?: 'ScenarioLayer' }
      & Pick<ScenarioLayer, 'ScenarioLayerId' | 'NavLayerId' | 'LayerNum' | 'LayerText' | 'LayerTypeID' | 'IsPermeable' | 'IsOptional'>
      & { ScenarioUsages: Array<(
        { __typename?: 'ScenarioUsage' }
        & Pick<ScenarioUsage, 'ScenarioUsageId' | 'UsageId' | 'LayerNum' | 'SelfSecurementText' | 'SelfSecurementLayerTypeId'>
      )> }
    )> }
  )> }
);

export type GetListScenarioDetailByEmailQueryVariables = Exact<{
  UserEmail?: Maybe<Scalars['String']>;
  RandomId?: Maybe<Scalars['String']>;
}>;


export type GetListScenarioDetailByEmailQuery = (
  { __typename?: 'query_root' }
  & { Scenario: Array<(
    { __typename?: 'Scenario' }
    & Pick<Scenario, 'ScenarioId' | 'ScenarioStatusId' | 'Name' | 'NavAssemblyId' | 'NavAssemblyName' | 'DateCreated' | 'ScenarioTypeId' | 'Comments'>
  )> }
);

export type GetListScenarioDetailByUserGuidQueryVariables = Exact<{
  UserGuid?: Maybe<Scalars['String']>;
}>;


export type GetListScenarioDetailByUserGuidQuery = (
  { __typename?: 'query_root' }
  & { Scenario: Array<(
    { __typename?: 'Scenario' }
    & Pick<Scenario, 'ScenarioId' | 'ScenarioStatusId' | 'Name' | 'NavAssemblyId' | 'NavAssemblyName' | 'DateCreated' | 'ScenarioTypeId' | 'Comments'>
  )> }
);

export type GetScenarioFullInfoQueryVariables = Exact<{
  ScenarioId?: Maybe<Scalars['Int']>;
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  RandomId?: Maybe<Scalars['String']>;
}>;


export type GetScenarioFullInfoQuery = (
  { __typename?: 'query_root' }
  & { Scenario: Array<(
    { __typename?: 'Scenario' }
    & Pick<Scenario, 'ScenarioId' | 'ScenarioStatusId' | 'Name' | 'NavAssemblyId' | 'NavAssemblyName' | 'DateCreated' | 'ScenarioTypeId' | 'Comments'>
    & { NavAssembly?: Maybe<(
      { __typename?: 'NavAssembly' }
      & Pick<NavAssembly, 'AssemblyTypeCode' | 'AssemblyApplicationTypeCode' | 'CoverSecurementMethodCode' | 'DeckTypeCode' | 'ExtFireRatingCode' | 'HailRatingCode' | 'IntFireRatingCode' | 'Slope' | 'WindUplift'>
      & { RoofAssembly: (
        { __typename?: 'RoofAssembly' }
        & Pick<RoofAssembly, 'IncludesPV' | 'PVApplicationMethodId' | 'PVWindUplift' | 'PVHailRatingId' | 'IsUsedWithCombustibleWalls'>
        & { C_PVApplicationMethod?: Maybe<(
          { __typename?: 'C_PVApplicationMethod' }
          & Pick<C_PvApplicationMethod, 'PVApplicationMethodCode'>
        )>, C_PVHailRating?: Maybe<(
          { __typename?: 'C_PVHailRating' }
          & Pick<C_PvHailRating, 'PVHailRatingCode'>
        )> }
      ) }
    )>, ScenarioLayers: Array<(
      { __typename?: 'ScenarioLayer' }
      & Pick<ScenarioLayer, 'ScenarioLayerId' | 'NavLayerId' | 'LayerNum' | 'LayerText' | 'LayerTypeID' | 'IsPermeable' | 'IsOptional'>
      & { NavLayer?: Maybe<(
        { __typename?: 'NavLayer' }
        & Pick<NavLayer, 'LayerTypeId' | 'NavLayerNum' | 'LayerId'>
        & { Layer?: Maybe<(
          { __typename?: 'Layer' }
          & Pick<Layer, 'Comments'>
        )> }
      )>, ScenarioUsages: Array<(
        { __typename?: 'ScenarioUsage' }
        & Pick<ScenarioUsage, 'ScenarioUsageId' | 'UsageId' | 'LayerNum' | 'SelfSecurementText' | 'SelfSecurementLayerTypeId'>
        & { Usage?: Maybe<(
          { __typename?: 'Usage' }
          & Pick<Usage, 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
          & { Component: (
            { __typename?: 'Component' }
            & Pick<Component, 'Comments'>
            & { ManufacturedProduct?: Maybe<(
              { __typename?: 'ManufacturedProduct' }
              & Pick<ManufacturedProduct, 'ComponentId' | 'TradeName'>
              & { Manufacturer: (
                { __typename?: 'Manufacturer' }
                & Pick<Manufacturer, 'ManufacturerId' | 'Name'>
              ) }
            )>, StructuredSystem?: Maybe<(
              { __typename?: 'StructuredSystem' }
              & Pick<StructuredSystem, 'ComponentId' | 'SystemName'>
              & { StructuredSystemProducts: Array<(
                { __typename?: 'StructuredSystemProducts' }
                & Pick<StructuredSystemProducts, 'ComponentId' | 'OrderNum'>
                & { ManufacturedProduct: (
                  { __typename?: 'ManufacturedProduct' }
                  & Pick<ManufacturedProduct, 'ComponentId' | 'TradeName'>
                  & { Manufacturer: (
                    { __typename?: 'Manufacturer' }
                    & Pick<Manufacturer, 'ManufacturerId' | 'Name'>
                  ) }
                ) }
              )> }
            )> }
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type GetScenarioContractorPackageInfoQueryVariables = Exact<{
  ScenarioId?: Maybe<Scalars['Int']>;
  AzureB2CUserEmail?: Maybe<Scalars['String']>;
  RandomId?: Maybe<Scalars['String']>;
}>;


export type GetScenarioContractorPackageInfoQuery = (
  { __typename?: 'query_root' }
  & { Scenario: Array<(
    { __typename?: 'Scenario' }
    & Pick<Scenario, 'ScenarioId' | 'ScenarioStatusId' | 'Name' | 'NavAssemblyId' | 'NavAssemblyName' | 'DateCreated' | 'ScenarioTypeId' | 'Comments'>
    & { NavAssembly?: Maybe<(
      { __typename?: 'NavAssembly' }
      & Pick<NavAssembly, 'AssemblyTypeCode' | 'AssemblyApplicationTypeCode' | 'CoverSecurementMethodCode' | 'DeckTypeCode' | 'ExtFireRatingCode' | 'HailRatingCode' | 'IntFireRatingCode' | 'Slope' | 'WindUplift'>
      & { RoofAssembly: (
        { __typename?: 'RoofAssembly' }
        & Pick<RoofAssembly, 'IncludesPV' | 'PVApplicationMethodId' | 'PVWindUplift' | 'PVHailRatingId' | 'IsUsedWithCombustibleWalls'>
        & { C_PVApplicationMethod?: Maybe<(
          { __typename?: 'C_PVApplicationMethod' }
          & Pick<C_PvApplicationMethod, 'PVApplicationMethodCode'>
        )>, C_PVHailRating?: Maybe<(
          { __typename?: 'C_PVHailRating' }
          & Pick<C_PvHailRating, 'PVHailRatingCode'>
        )> }
      ) }
    )>, ScenarioLayers: Array<(
      { __typename?: 'ScenarioLayer' }
      & Pick<ScenarioLayer, 'ScenarioLayerId' | 'NavLayerId' | 'LayerNum' | 'LayerText' | 'LayerTypeID' | 'IsPermeable' | 'IsOptional'>
      & { NavLayer?: Maybe<(
        { __typename?: 'NavLayer' }
        & Pick<NavLayer, 'LayerTypeId' | 'NavLayerNum' | 'LayerId'>
        & { Layer?: Maybe<(
          { __typename?: 'Layer' }
          & Pick<Layer, 'Comments'>
        )> }
      )>, ScenarioUsages: Array<(
        { __typename?: 'ScenarioUsage' }
        & Pick<ScenarioUsage, 'ScenarioUsageId' | 'UsageId' | 'LayerNum' | 'SelfSecurementText' | 'SelfSecurementLayerTypeId'>
        & { Usage?: Maybe<(
          { __typename?: 'Usage' }
          & Pick<Usage, 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
          & { Component: (
            { __typename?: 'Component' }
            & Pick<Component, 'Comments'>
            & { ManufacturedProduct?: Maybe<(
              { __typename?: 'ManufacturedProduct' }
              & Pick<ManufacturedProduct, 'ComponentId' | 'TradeName'>
              & { Manufacturer: (
                { __typename?: 'Manufacturer' }
                & Pick<Manufacturer, 'ManufacturerId' | 'Name'>
              ) }
            )>, StructuredSystem?: Maybe<(
              { __typename?: 'StructuredSystem' }
              & Pick<StructuredSystem, 'ComponentId' | 'SystemName'>
              & { StructuredSystemProducts: Array<(
                { __typename?: 'StructuredSystemProducts' }
                & Pick<StructuredSystemProducts, 'ComponentId' | 'OrderNum'>
                & { ManufacturedProduct: (
                  { __typename?: 'ManufacturedProduct' }
                  & Pick<ManufacturedProduct, 'ComponentId' | 'TradeName'>
                  & { Manufacturer: (
                    { __typename?: 'Manufacturer' }
                    & Pick<Manufacturer, 'ManufacturerId' | 'Name'>
                  ) }
                ) }
              )> }
            )> }
          ) }
        )> }
      )> }
    )>, R_RoofAreaScenarios: Array<(
      { __typename?: 'R_RoofAreaScenario' }
      & { RoofArea: (
        { __typename?: 'RoofArea' }
        & Pick<RoofArea, 'RoofAreaId' | 'IntFireRatingId' | 'HailRatingId' | 'GroundRoughnessRatingId' | 'ExtFireRatingId' | 'Name' | 'Description' | 'Width' | 'Length' | 'Height' | 'BuildingName' | 'BuildingEnclosureId' | 'WindBorneDebrisId' | 'Slope' | 'WindSpeed' | 'ContinuousParapet' | 'CalculatorVersion' | 'CalculationLastUpdatedDate' | 'TropicalCycloneRegion'>
        & { RoofAreaWindPressures: Array<(
          { __typename?: 'RoofAreaWindPressure' }
          & Pick<RoofAreaWindPressure, 'RoofAreaWindPressureId' | 'PressureZoneId' | 'WindUplift' | 'CopingRating' | 'FasciaRating'>
        )>, RoofProject: (
          { __typename?: 'RoofProject' }
          & Pick<RoofProject, 'ProjectId' | 'Name' | 'Description' | 'Notes' | 'IndexNumber'>
        ), R_RoofAreaContacts: Array<(
          { __typename?: 'R_RoofAreaContact' }
          & Pick<R_RoofAreaContact, 'ProjectRoleId'>
          & { ContactInfo: (
            { __typename?: 'ContactInfo' }
            & Pick<ContactInfo, 'ContactInfoId' | 'FirstName' | 'LastName' | 'Initial' | 'CompanyName' | 'Phone' | 'Fax' | 'Email'>
            & { Address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'Line1' | 'Line2' | 'City' | 'PostalCode'>
              & { C_StateProvince?: Maybe<(
                { __typename?: 'C_StateProvince' }
                & Pick<C_StateProvince, 'StateProvinceCode'>
              )>, C_Country?: Maybe<(
                { __typename?: 'C_Country' }
                & Pick<C_Country, 'CountryCode'>
              )> }
            )> }
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type GetExistingRoofDetailQueryVariables = Exact<{
  LayerId?: Maybe<Scalars['Int']>;
}>;


export type GetExistingRoofDetailQuery = (
  { __typename?: 'query_root' }
  & { ExistingRoofLayer: Array<(
    { __typename?: 'ExistingRoofLayer' }
    & Pick<ExistingRoofLayer, 'ExistingRoofInsulationMaterialId' | 'ExistingRoofSinglePlyMaterialId' | 'ExistingRoofDeckId' | 'ExistingRoofCoverId' | 'ExistingRoofSurfaceId' | 'SteelStrength' | 'SteelThicknessMin' | 'SteelThicknessMax' | 'InsulationThicknessMin' | 'InsulationThicknessMax' | 'EnteredAsMetric'>
    & { C_ExistingRoofInsulationMaterial: (
      { __typename?: 'C_ExistingRoofInsulationMaterial' }
      & Pick<C_ExistingRoofInsulationMaterial, 'ExistingRoofInsulationMaterialCode'>
    ), C_ExistingRoofSinglePlyMaterial: (
      { __typename?: 'C_ExistingRoofSinglePlyMaterial' }
      & Pick<C_ExistingRoofSinglePlyMaterial, 'ExistingRoofSinglePlyMaterialCode'>
    ), C_ExistingRoofDeck: (
      { __typename?: 'C_ExistingRoofDeck' }
      & Pick<C_ExistingRoofDeck, 'ExistingRoofDeckCode'>
    ), C_ExistingRoofCover: (
      { __typename?: 'C_ExistingRoofCover' }
      & Pick<C_ExistingRoofCover, 'ExistingRoofCoverCode'>
    ), C_ExistingRoofSurface: (
      { __typename?: 'C_ExistingRoofSurface' }
      & Pick<C_ExistingRoofSurface, 'ExistingRoofSurfaceCode'>
    ), Layer: (
      { __typename?: 'Layer' }
      & Pick<Layer, 'Comments'>
    ) }
  )> }
);

export type GetStructureDetailQueryVariables = Exact<{
  LayerId?: Maybe<Scalars['Int']>;
}>;


export type GetStructureDetailQuery = (
  { __typename?: 'query_root' }
  & { StructureLayer: Array<(
    { __typename?: 'StructureLayer' }
    & Pick<StructureLayer, 'StructureTypeId' | 'SpacingMax' | 'Thickness' | 'StrengthMin' | 'EnteredAsMetric'>
    & { C_StructureType: (
      { __typename?: 'C_StructureType' }
      & Pick<C_StructureType, 'StructureTypeCode'>
    ), Layer: (
      { __typename?: 'Layer' }
      & Pick<Layer, 'Comments'>
    ) }
  )> }
);

export type GetDeckStructuralConcreteDetailQueryVariables = Exact<{
  LayerId?: Maybe<Scalars['Int']>;
}>;


export type GetDeckStructuralConcreteDetailQuery = (
  { __typename?: 'query_root' }
  & { StructuralConcreteLayer: Array<(
    { __typename?: 'StructuralConcreteLayer' }
    & { Layer: (
      { __typename?: 'Layer' }
      & Pick<Layer, 'Comments'>
    ) }
  )> }
);

export type GetCoatingUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetCoatingUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), Coating?: Maybe<(
          { __typename?: 'Coating' }
          & Pick<Coating, 'CoatingMaterialId'>
          & { C_CoatingMaterial: (
            { __typename?: 'C_CoatingMaterial' }
            & Pick<C_CoatingMaterial, 'CoatingMaterialCode'>
          ) }
        )> }
      )> }
    ), CoatingUsage?: Maybe<(
      { __typename?: 'CoatingUsage' }
      & Pick<CoatingUsage, 'NumCoats' | 'ApplicationRateWet' | 'ApplicationRateDry'>
    )> }
  )> }
);

export type GetPreassembledFasteningUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetPreassembledFasteningUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), PreassembledFastening?: Maybe<(
          { __typename?: 'PreassembledFastening' }
          & Pick<PreassembledFastening, 'FastenerTypeId' | 'FastenerMaterialId' | 'FastenerCoatingId' | 'FastenerDriveHeadId' | 'FastenerDiameter' | 'LengthMin' | 'LengthMax' | 'IsSteelDeckFastener' | 'StructureThicknessMin' | 'StructureThicknessMax' | 'DeckDesignThicknessMax' | 'StressDistributorTypeId' | 'StressDistributorMaterialId' | 'Thickness' | 'StressPlateShapeId' | 'Diameter' | 'Width' | 'Length' | 'HoleSpacing' | 'ThicknessMin' | 'IsSecuredToConcreteDeck'>
          & { C_FastenerType: (
            { __typename?: 'C_FastenerType' }
            & Pick<C_FastenerType, 'FastenerTypeCode'>
          ), cSecurementmaterialByFastenermaterialid: (
            { __typename?: 'C_SecurementMaterial' }
            & Pick<C_SecurementMaterial, 'SecurementMaterialCode'>
          ), C_FastenerCoating: (
            { __typename?: 'C_FastenerCoating' }
            & Pick<C_FastenerCoating, 'FastenerCoatingCode'>
          ), C_FastenerDriveHead: (
            { __typename?: 'C_FastenerDriveHead' }
            & Pick<C_FastenerDriveHead, 'FastenerDriveHeadCode'>
          ), C_StressDistributorType: (
            { __typename?: 'C_StressDistributorType' }
            & Pick<C_StressDistributorType, 'StressDistributorTypeCode'>
          ), C_SecurementMaterial: (
            { __typename?: 'C_SecurementMaterial' }
            & Pick<C_SecurementMaterial, 'SecurementMaterialCode'>
          ), C_StressPlateShape: (
            { __typename?: 'C_StressPlateShape' }
            & Pick<C_StressPlateShape, 'StressPlateShapeCode'>
          ) }
        )> }
      )> }
    ), PreassembledFasteningUsage?: Maybe<(
      { __typename?: 'PreassembledFasteningUsage' }
      & Pick<PreassembledFasteningUsage, 'RowSpacing' | 'OnCenter' | 'IsFieldRows' | 'FieldRowsOnCenter' | 'NumFieldRows' | 'FasteningApplicationMethodId' | 'Embedment' | 'StressDistributorTypeId' | 'FastenersPerPlateOrClip' | 'ContributoryArea'>
      & { C_FasteningApplicationMethod: (
        { __typename?: 'C_FasteningApplicationMethod' }
        & Pick<C_FasteningApplicationMethod, 'FasteningApplicationMethodCode'>
      ), C_StressDistributorType: (
        { __typename?: 'C_StressDistributorType' }
        & Pick<C_StressDistributorType, 'StressDistributorTypeCode'>
      ) }
    )> }
  )> }
);

export type GetFasteningSystemStressPlateUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetFasteningSystemStressPlateUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & Pick<Component, 'Comments'>
      & { StructuredSystem?: Maybe<(
        { __typename?: 'StructuredSystem' }
        & Pick<StructuredSystem, 'SystemName'>
        & { StructuredSystemProducts: Array<(
          { __typename?: 'StructuredSystemProducts' }
          & { ManufacturedProduct: (
            { __typename?: 'ManufacturedProduct' }
            & Pick<ManufacturedProduct, 'ComponentId' | 'TradeName' | 'ManufacturerId'>
            & { Manufacturer: (
              { __typename?: 'Manufacturer' }
              & Pick<Manufacturer, 'Name'>
            ) }
          ) }
        )> }
      )> }
    ), FasteningSystemStressPlateUsage?: Maybe<(
      { __typename?: 'FasteningSystemStressPlateUsage' }
      & Pick<FasteningSystemStressPlateUsage, 'OnCenter' | 'RowSpacing' | 'FastenersPerPlateOrClip' | 'IsFieldRows' | 'FieldRowsOnCenter' | 'NumFieldRows' | 'ContributoryArea' | 'FasteningApplicationMethodId' | 'Embedment'>
      & { C_FasteningApplicationMethod: (
        { __typename?: 'C_FasteningApplicationMethod' }
        & Pick<C_FasteningApplicationMethod, 'FasteningApplicationMethodCode'>
      ) }
    )> }
  )> }
);

export type GetAdhesiveFullApplicationUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetAdhesiveFullApplicationUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), AdhesiveFullApplication?: Maybe<(
          { __typename?: 'AdhesiveFullApplication' }
          & Pick<AdhesiveFullApplication, 'AdhesiveMaterialId' | 'AdhesiveTypeId'>
          & { C_AdhesiveMaterial: (
            { __typename?: 'C_AdhesiveMaterial' }
            & Pick<C_AdhesiveMaterial, 'AdhesiveMaterialCode'>
          ), C_AdhesiveType: (
            { __typename?: 'C_AdhesiveType' }
            & Pick<C_AdhesiveType, 'AdhesiveTypeCode'>
          ) }
        )> }
      )> }
    ), AdhesiveFullApplicationUsage?: Maybe<(
      { __typename?: 'AdhesiveFullApplicationUsage' }
      & Pick<AdhesiveFullApplicationUsage, 'SubstratePrimerRateWet' | 'OverSubstrateRateWet' | 'UndersideRateWet' | 'SubstratePrimerRateDry' | 'OverSubstrateRateDry' | 'UndersideRateDry'>
    )> }
  )> }
);

export type GetOtherPlyUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetOtherPlyUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), OtherPly?: Maybe<(
          { __typename?: 'OtherPly' }
          & Pick<OtherPly, 'BackingMaterialId' | 'ReinforcementId' | 'MultiplyMaterialId' | 'Width'>
          & { C_BackingMaterial: (
            { __typename?: 'C_BackingMaterial' }
            & Pick<C_BackingMaterial, 'BackingMaterialCode'>
          ), C_Reinforcement: (
            { __typename?: 'C_Reinforcement' }
            & Pick<C_Reinforcement, 'ReinforcementCode'>
          ), C_MultiplyMaterial: (
            { __typename?: 'C_MultiplyMaterial' }
            & Pick<C_MultiplyMaterial, 'MultiplyMaterialCode'>
          ) }
        )> }
      )> }
    ), OtherPlyUsage?: Maybe<(
      { __typename?: 'OtherPlyUsage' }
      & Pick<OtherPlyUsage, 'NumPlies'>
    )> }
  )> }
);

export type GetBaseSheetUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetBaseSheetUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), BaseSheet?: Maybe<(
          { __typename?: 'BaseSheet' }
          & Pick<BaseSheet, 'IsPermeable' | 'Width' | 'BackingMaterialId' | 'MultiplyMaterialId' | 'ReinforcementId'>
          & { C_BackingMaterial: (
            { __typename?: 'C_BackingMaterial' }
            & Pick<C_BackingMaterial, 'BackingMaterialCode'>
          ), C_MultiplyMaterial: (
            { __typename?: 'C_MultiplyMaterial' }
            & Pick<C_MultiplyMaterial, 'MultiplyMaterialCode'>
          ), C_Reinforcement: (
            { __typename?: 'C_Reinforcement' }
            & Pick<C_Reinforcement, 'ReinforcementCode'>
          ) }
        )> }
      )> }
    ), BaseSheetUsage?: Maybe<(
      { __typename?: 'BaseSheetUsage' }
      & Pick<BaseSheetUsage, 'LapWidth' | 'WidthMax'>
    )> }
  )> }
);

export type GetSinglePlyCoverUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetSinglePlyCoverUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), SinglePlyCover?: Maybe<(
          { __typename?: 'SinglePlyCover' }
          & Pick<SinglePlyCover, 'SinglePlyMaterialId' | 'BackingMaterialId' | 'ThicknessMin' | 'ThicknessMax' | 'ReinforcementId'>
          & { C_SinglePlyMaterial: (
            { __typename?: 'C_SinglePlyMaterial' }
            & Pick<C_SinglePlyMaterial, 'SinglePlyMaterialCode'>
          ), C_BackingMaterial: (
            { __typename?: 'C_BackingMaterial' }
            & Pick<C_BackingMaterial, 'BackingMaterialCode'>
          ), C_Reinforcement: (
            { __typename?: 'C_Reinforcement' }
            & Pick<C_Reinforcement, 'ReinforcementCode'>
          ) }
        )> }
      )> }
    ), SinglePlyUsage?: Maybe<(
      { __typename?: 'SinglePlyUsage' }
      & Pick<SinglePlyUsage, 'WidthMax' | 'ThicknessMin' | 'ThicknessMax'>
    )> }
  )> }
);

export type GetBoardStockUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetBoardStockUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), BoardStock?: Maybe<(
          { __typename?: 'BoardStock' }
          & Pick<BoardStock, 'BoardStockMaterialId' | 'BoardProfileId' | 'DensityMin' | 'ThicknessMin' | 'ThicknessMax'>
          & { C_BoardStockMaterial: (
            { __typename?: 'C_BoardStockMaterial' }
            & Pick<C_BoardStockMaterial, 'BoardStockMaterialCode'>
          ), C_BoardProfile: (
            { __typename?: 'C_BoardProfile' }
            & Pick<C_BoardProfile, 'BoardProfileCode'>
          ) }
        )> }
      )> }
    ), BoardStockUsage?: Maybe<(
      { __typename?: 'BoardStockUsage' }
      & Pick<BoardStockUsage, 'ThicknessMin' | 'ThicknessMax' | 'InsBoardSizeId'>
      & { InsBoardSize: (
        { __typename?: 'InsBoardSize' }
        & Pick<InsBoardSize, 'Width' | 'Length' | 'IsMetric'>
      ) }
    )> }
  )> }
);

export type GetLightweightInsulatingConcreteUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetLightweightInsulatingConcreteUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), LightweightInsulatingConcrete?: Maybe<(
          { __typename?: 'LightweightInsulatingConcrete' }
          & Pick<LightweightInsulatingConcrete, 'AggregateId' | 'CementAggregateRatioId' | 'ThicknessMin' | 'CompressiveStrengthMin' | 'DryDensityMin' | 'CastDensityMin'>
          & { C_Aggregate: (
            { __typename?: 'C_Aggregate' }
            & Pick<C_Aggregate, 'AggregateCode'>
          ), C_CementAggregateRatio: (
            { __typename?: 'C_CementAggregateRatio' }
            & Pick<C_CementAggregateRatio, 'CementAggregateRatioCode'>
          ) }
        )> }
      )> }
    ), LightweightInsulatingConcreteUsage?: Maybe<(
      { __typename?: 'LightweightInsulatingConcreteUsage' }
      & Pick<LightweightInsulatingConcreteUsage, 'ThicknessMin' | 'MinCompressiveStrength' | 'DryDensityMin' | 'CastDensityMin'>
    )> }
  )> }
);

export type GetSteelDeckUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetSteelDeckUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), SteelDeck?: Maybe<(
          { __typename?: 'SteelDeck' }
          & Pick<SteelDeck, 'IsAcoustical' | 'SteelDeckRibTypeId' | 'DepthMin' | 'DepthMax' | 'WidthMin' | 'WidthMax' | 'DesignThickness' | 'SpanMax'>
          & { C_SteelDeckRibType: (
            { __typename?: 'C_SteelDeckRibType' }
            & Pick<C_SteelDeckRibType, 'SteelDeckRibTypeCode'>
          ) }
        )> }
      )> }
    ), SteelDeckUsage?: Maybe<(
      { __typename?: 'SteelDeckUsage' }
      & Pick<SteelDeckUsage, 'DesignThickness' | 'SpanMax' | 'GradeMin' | 'ThicknessMin'>
    )> }
  )> }
);

export type GetVaporRetarderUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetVaporRetarderUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), VaporRetarder?: Maybe<(
          { __typename?: 'VaporRetarder' }
          & Pick<VaporRetarder, 'RetarderMaterialId'>
          & { C_RetarderMaterial: (
            { __typename?: 'C_RetarderMaterial' }
            & Pick<C_RetarderMaterial, 'RetarderMaterialCode'>
          ) }
        )> }
      )> }
    ), VaporRetarderUsage?: Maybe<(
      { __typename?: 'VaporRetarderUsage' }
      & Pick<VaporRetarderUsage, 'NumPlies'>
    )> }
  )> }
);

export type GetVaporRetarderSystemUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetVaporRetarderSystemUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & Pick<Component, 'Comments'>
      & { StructuredSystem?: Maybe<(
        { __typename?: 'StructuredSystem' }
        & Pick<StructuredSystem, 'SystemName'>
        & { StructuredSystemProducts: Array<(
          { __typename?: 'StructuredSystemProducts' }
          & { ManufacturedProduct: (
            { __typename?: 'ManufacturedProduct' }
            & Pick<ManufacturedProduct, 'ComponentId' | 'TradeName' | 'ManufacturerId'>
            & { Manufacturer: (
              { __typename?: 'Manufacturer' }
              & Pick<Manufacturer, 'Name'>
            ) }
          ) }
        )> }
      )> }
    ), BuiltupVaporRetarderSystemUsage?: Maybe<(
      { __typename?: 'BuiltupVaporRetarderSystemUsage' }
      & Pick<BuiltupVaporRetarderSystemUsage, 'NumberPlies' | 'ApplicationRateWet' | 'ApplicationRateDry'>
    )> }
  )> }
);

export type GetCoverBoardUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetCoverBoardUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), CoverBoard?: Maybe<(
          { __typename?: 'CoverBoard' }
          & Pick<CoverBoard, 'CoverBoardMaterialId' | 'BoardProfileId' | 'DensityMin' | 'ThicknessMin' | 'ThicknessMax'>
          & { C_CoverBoardMaterial: (
            { __typename?: 'C_CoverBoardMaterial' }
            & Pick<C_CoverBoardMaterial, 'CoverBoardMaterialCode'>
          ), C_BoardProfile: (
            { __typename?: 'C_BoardProfile' }
            & Pick<C_BoardProfile, 'BoardProfileCode'>
          ) }
        )> }
      )> }
    ), CoverBoardUsage?: Maybe<(
      { __typename?: 'CoverBoardUsage' }
      & Pick<CoverBoardUsage, 'ThicknessMin' | 'ThicknessMax' | 'InsBoardSizeId'>
      & { InsBoardSize: (
        { __typename?: 'InsBoardSize' }
        & Pick<InsBoardSize, 'Width' | 'Length' | 'IsMetric'>
      ) }
    )> }
  )> }
);

export type GetCapPlyUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetCapPlyUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), CapPly?: Maybe<(
          { __typename?: 'CapPly' }
          & Pick<CapPly, 'BackingMaterialId' | 'Width' | 'MultiplyMaterialId' | 'ReinforcementId' | 'SurfaceTypeId'>
          & { C_BackingMaterial: (
            { __typename?: 'C_BackingMaterial' }
            & Pick<C_BackingMaterial, 'BackingMaterialCode'>
          ), C_MultiplyMaterial: (
            { __typename?: 'C_MultiplyMaterial' }
            & Pick<C_MultiplyMaterial, 'MultiplyMaterialCode'>
          ), C_Reinforcement: (
            { __typename?: 'C_Reinforcement' }
            & Pick<C_Reinforcement, 'ReinforcementCode'>
          ), C_SurfaceType: (
            { __typename?: 'C_SurfaceType' }
            & Pick<C_SurfaceType, 'SurfaceTypeCode'>
          ) }
        )> }
      )> }
    ) }
  )> }
);

export type GetWeldTorchedUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetWeldTorchedUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ) }
      )> }
    ) }
  )> }
);

export type GetThermalBarrierUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetThermalBarrierUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), ThermalBarrier?: Maybe<(
          { __typename?: 'ThermalBarrier' }
          & Pick<ThermalBarrier, 'ThermalBarrierMaterialId' | 'BoardProfileId' | 'DensityMin' | 'ThicknessMin' | 'ThicknessMax'>
          & { C_ThermalBarrierMaterial: (
            { __typename?: 'C_ThermalBarrierMaterial' }
            & Pick<C_ThermalBarrierMaterial, 'ThermalBarrierMaterialCode'>
          ), C_BoardProfile: (
            { __typename?: 'C_BoardProfile' }
            & Pick<C_BoardProfile, 'BoardProfileCode'>
          ) }
        )> }
      )> }
    ), ThermalBarrierUsage?: Maybe<(
      { __typename?: 'ThermalBarrierUsage' }
      & Pick<ThermalBarrierUsage, 'ThicknessMin' | 'ThicknessMax' | 'InsBoardSizeId'>
      & { InsBoardSize: (
        { __typename?: 'InsBoardSize' }
        & Pick<InsBoardSize, 'Width' | 'Length' | 'IsMetric'>
      ) }
    )> }
  )> }
);

export type GetBasePlyUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetBasePlyUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), BasePly?: Maybe<(
          { __typename?: 'BasePly' }
          & Pick<BasePly, 'BackingMaterialId' | 'Width' | 'MultiplyMaterialId' | 'ReinforcementId'>
          & { C_BackingMaterial: (
            { __typename?: 'C_BackingMaterial' }
            & Pick<C_BackingMaterial, 'BackingMaterialCode'>
          ), C_MultiplyMaterial: (
            { __typename?: 'C_MultiplyMaterial' }
            & Pick<C_MultiplyMaterial, 'MultiplyMaterialCode'>
          ), C_Reinforcement: (
            { __typename?: 'C_Reinforcement' }
            & Pick<C_Reinforcement, 'ReinforcementCode'>
          ) }
        )> }
      )> }
    ) }
  )> }
);

export type GetSubstratePrimerUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetSubstratePrimerUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ) }
      )> }
    ), SubstratePrimerUsage?: Maybe<(
      { __typename?: 'SubstratePrimerUsage' }
      & Pick<SubstratePrimerUsage, 'ApplicationRateWet' | 'ApplicationRateDry'>
    )> }
  )> }
);

export type GetCompositePanelCoverUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetCompositePanelCoverUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), CompositePanelCover?: Maybe<(
          { __typename?: 'CompositePanelCover' }
          & Pick<CompositePanelCover, 'InsulationCoreId' | 'WidthMin' | 'WidthMax' | 'ThicknessMin' | 'ThicknessMax' | 'TopFacerMaterialId' | 'FacerThicknessTop' | 'BottomFacerMaterialId' | 'FacerThicknessBottom'>
          & { C_InsulationCore: (
            { __typename?: 'C_InsulationCore' }
            & Pick<C_InsulationCore, 'InsulationCoreCode'>
          ), C_FacerMaterial: (
            { __typename?: 'C_FacerMaterial' }
            & Pick<C_FacerMaterial, 'FacerMaterialCode'>
          ), cFacermaterialByBottomfacermaterialid: (
            { __typename?: 'C_FacerMaterial' }
            & Pick<C_FacerMaterial, 'FacerMaterialCode'>
          ) }
        )> }
      )> }
    ), CompositePanelUsage?: Maybe<(
      { __typename?: 'CompositePanelUsage' }
      & Pick<CompositePanelUsage, 'WidthMax' | 'ThicknessMin'>
    )> }
  )> }
);

export type GetFastenerUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetFastenerUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), Fastener?: Maybe<(
          { __typename?: 'Fastener' }
          & Pick<Fastener, 'SecurementMaterialId' | 'Diameter' | 'LengthMin' | 'LengthMax' | 'IsSecuredToConcreteDeck' | 'IsSteelDeckFastener'>
          & { C_SecurementMaterial: (
            { __typename?: 'C_SecurementMaterial' }
            & Pick<C_SecurementMaterial, 'SecurementMaterialCode'>
          ) }
        )> }
      )> }
    ), FastenerUsage?: Maybe<(
      { __typename?: 'FastenerUsage' }
      & Pick<FastenerUsage, 'RowSpacing' | 'OnCenter' | 'IsFieldRows' | 'FieldRowsOnCenter' | 'NumFieldRows' | 'FasteningApplicationMethodId' | 'Embedment' | 'ContributoryArea'>
      & { C_FasteningApplicationMethod: (
        { __typename?: 'C_FasteningApplicationMethod' }
        & Pick<C_FasteningApplicationMethod, 'FasteningApplicationMethodCode'>
      ) }
    )> }
  )> }
);

export type GetTopCoatUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetTopCoatUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), TopCoat?: Maybe<(
          { __typename?: 'TopCoat' }
          & Pick<TopCoat, 'LiquidAppliedMaterialId'>
          & { C_LiquidAppliedMaterial: (
            { __typename?: 'C_LiquidAppliedMaterial' }
            & Pick<C_LiquidAppliedMaterial, 'LiquidAppliedMaterialCode'>
          ) }
        )> }
      )> }
    ), TopCoatUsage?: Maybe<(
      { __typename?: 'TopCoatUsage' }
      & Pick<TopCoatUsage, 'NumCoats' | 'ApplicationRateMinWet' | 'ApplicationRateMinDry'>
    )> }
  )> }
);

export type GetCoatUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetCoatUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), Coat?: Maybe<(
          { __typename?: 'Coat' }
          & Pick<Coat, 'LiquidAppliedMaterialId'>
          & { C_LiquidAppliedMaterial: (
            { __typename?: 'C_LiquidAppliedMaterial' }
            & Pick<C_LiquidAppliedMaterial, 'LiquidAppliedMaterialCode'>
          ) }
        )> }
      )> }
    ), CoatUsage?: Maybe<(
      { __typename?: 'CoatUsage' }
      & Pick<CoatUsage, 'NumCoats' | 'MinAppRatePerCoatWet' | 'MinAppRatePerCoatDry'>
    )> }
  )> }
);

export type GetBaseCoatUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetBaseCoatUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), BaseCoat?: Maybe<(
          { __typename?: 'BaseCoat' }
          & Pick<BaseCoat, 'LiquidAppliedMaterialId'>
          & { C_LiquidAppliedMaterial: (
            { __typename?: 'C_LiquidAppliedMaterial' }
            & Pick<C_LiquidAppliedMaterial, 'LiquidAppliedMaterialCode'>
          ) }
        )> }
      )> }
    ), BaseCoatUsage?: Maybe<(
      { __typename?: 'BaseCoatUsage' }
      & Pick<BaseCoatUsage, 'NumCoats' | 'ApplicationRateMinWet' | 'ApplicationRateMinDry'>
    )> }
  )> }
);

export type GetSprayedFoamInsulationUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetSprayedFoamInsulationUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), SprayedFoamInsulation?: Maybe<(
          { __typename?: 'SprayedFoamInsulation' }
          & Pick<SprayedFoamInsulation, 'InsulationFoamTypeId' | 'DensityMin' | 'DensityMax'>
          & { C_InsulationFoamType: (
            { __typename?: 'C_InsulationFoamType' }
            & Pick<C_InsulationFoamType, 'InsulationFoamTypeCode'>
          ) }
        )> }
      )> }
    ), SprayFoamInsulationUsage?: Maybe<(
      { __typename?: 'SprayFoamInsulationUsage' }
      & Pick<SprayFoamInsulationUsage, 'ThicknessMin' | 'ThicknessMax'>
    )> }
  )> }
);

export type GetBattBlanketInsulationUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetBattBlanketInsulationUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), BattBlanketInsulation?: Maybe<(
          { __typename?: 'BattBlanketInsulation' }
          & Pick<BattBlanketInsulation, 'BattBlanketMaterialId' | 'BattBlanketFacerMaterialId' | 'ThicknessMin' | 'ThicknessMax'>
          & { C_BattBlanketMaterial: (
            { __typename?: 'C_BattBlanketMaterial' }
            & Pick<C_BattBlanketMaterial, 'BattBlanketMaterialCode'>
          ), C_BattBlanketFacerMaterial: (
            { __typename?: 'C_BattBlanketFacerMaterial' }
            & Pick<C_BattBlanketFacerMaterial, 'BattBlanketFacerMaterialCode'>
          ) }
        )> }
      )> }
    ), BattBlanketUsage?: Maybe<(
      { __typename?: 'BattBlanketUsage' }
      & Pick<BattBlanketUsage, 'ThicknessMin' | 'ThicknessMax'>
    )> }
  )> }
);

export type GetStandingLapSeamCoverUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetStandingLapSeamCoverUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), StandingLapSeamCover?: Maybe<(
          { __typename?: 'StandingLapSeamCover' }
          & Pick<StandingLapSeamCover, 'WidthMin' | 'WidthMax' | 'StandingLapSeamMaterialId' | 'NominalWeight' | 'ThicknessMin' | 'ThicknessMax'>
          & { C_StandingLapSeamMaterial: (
            { __typename?: 'C_StandingLapSeamMaterial' }
            & Pick<C_StandingLapSeamMaterial, 'StandingLapSeamMaterialCode'>
          ) }
        )> }
      )> }
    ), StandingLapSeamUsage?: Maybe<(
      { __typename?: 'StandingLapSeamUsage' }
      & Pick<StandingLapSeamUsage, 'WidthMax' | 'ThicknessMin' | 'WeightPerUnitAreaNominal'>
    )> }
  )> }
);

export type GetAirRetarderUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetAirRetarderUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), AirRetarder?: Maybe<(
          { __typename?: 'AirRetarder' }
          & Pick<AirRetarder, 'RetarderMaterialId'>
          & { C_RetarderMaterial: (
            { __typename?: 'C_RetarderMaterial' }
            & Pick<C_RetarderMaterial, 'RetarderMaterialCode'>
          ) }
        )> }
      )> }
    ) }
  )> }
);

export type GetSeamTapeSystemUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetSeamTapeSystemUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & Pick<Component, 'Comments'>
      & { StructuredSystem?: Maybe<(
        { __typename?: 'StructuredSystem' }
        & Pick<StructuredSystem, 'SystemName'>
        & { StructuredSystemProducts: Array<(
          { __typename?: 'StructuredSystemProducts' }
          & { ManufacturedProduct: (
            { __typename?: 'ManufacturedProduct' }
            & Pick<ManufacturedProduct, 'ComponentId' | 'TradeName' | 'ManufacturerId'>
            & { Manufacturer: (
              { __typename?: 'Manufacturer' }
              & Pick<Manufacturer, 'Name'>
            ) }
          ) }
        )> }
      )> }
    ) }
  )> }
);

export type GetAdhesiveRibbonsUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetAdhesiveRibbonsUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), AdhesiveRibbon?: Maybe<(
          { __typename?: 'AdhesiveRibbons' }
          & Pick<AdhesiveRibbons, 'AdhesiveMaterialId' | 'AdhesiveTypeId' | 'TapeWidth'>
          & { C_AdhesiveMaterial: (
            { __typename?: 'C_AdhesiveMaterial' }
            & Pick<C_AdhesiveMaterial, 'AdhesiveMaterialCode'>
          ), C_AdhesiveType: (
            { __typename?: 'C_AdhesiveType' }
            & Pick<C_AdhesiveType, 'AdhesiveTypeCode'>
          ) }
        )> }
      )> }
    ), AdhesiveRibbonUsage?: Maybe<(
      { __typename?: 'AdhesiveRibbonUsage' }
      & Pick<AdhesiveRibbonUsage, 'RowSpacing' | 'RibbonWidth' | 'AdhesiveApplicationMethodId'>
      & { C_AdhesiveApplicationMethod: (
        { __typename?: 'C_AdhesiveApplicationMethod' }
        & Pick<C_AdhesiveApplicationMethod, 'AdhesiveApplicationMethodCode'>
      ) }
    )> }
  )> }
);

export type GetCementitiousWoodFiberDeckUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetCementitiousWoodFiberDeckUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), CementitiousWoodFiberDeck?: Maybe<(
          { __typename?: 'CementitiousWoodFiberDeck' }
          & Pick<CementitiousWoodFiberDeck, 'ThicknessMin' | 'ThicknessMax' | 'SpanMax'>
        )> }
      )> }
    ), CementitiousWoodFiberDeckUsage?: Maybe<(
      { __typename?: 'CementitiousWoodFiberDeckUsage' }
      & Pick<CementitiousWoodFiberDeckUsage, 'ThicknessMin' | 'SpanMax'>
    )> }
  )> }
);

export type GetWeldHeatUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetWeldHeatUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ) }
      )> }
    ), WeldHeatUsage?: Maybe<(
      { __typename?: 'WeldHeatUsage' }
      & Pick<WeldHeatUsage, 'WeldConfigurationId' | 'OutsideWidth' | 'InsideWidth'>
      & { C_WeldConfiguration: (
        { __typename?: 'C_WeldConfiguration' }
        & Pick<C_WeldConfiguration, 'WeldConfigurationCode'>
      ) }
    )> }
  )> }
);

export type GetSeparatorSheetUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetSeparatorSheetUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), SeparatorSheet?: Maybe<(
          { __typename?: 'SeparatorSheet' }
          & Pick<SeparatorSheet, 'RetarderMaterialId'>
          & { C_RetarderMaterial: (
            { __typename?: 'C_RetarderMaterial' }
            & Pick<C_RetarderMaterial, 'RetarderMaterialCode'>
          ) }
        )> }
      )> }
    ) }
  )> }
);

export type GetCoverStripDiskUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetCoverStripDiskUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), CoverStripDisk?: Maybe<(
          { __typename?: 'CoverStripDisk' }
          & Pick<CoverStripDisk, 'SinglePlyMaterialId'>
          & { C_SinglePlyMaterial: (
            { __typename?: 'C_SinglePlyMaterial' }
            & Pick<C_SinglePlyMaterial, 'SinglePlyMaterialCode'>
          ) }
        )> }
      )> }
    ) }
  )> }
);

export type GetLinerPanelUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetLinerPanelUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), LinerPanel?: Maybe<(
          { __typename?: 'LinerPanel' }
          & Pick<LinerPanel, 'LinerPanelMaterialId'>
          & { C_LinerPanelMaterial: (
            { __typename?: 'C_LinerPanelMaterial' }
            & Pick<C_LinerPanelMaterial, 'LinerPanelMaterialCode'>
          ) }
        )> }
      )> }
    ) }
  )> }
);

export type GetClipSystemUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetClipSystemUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & Pick<Component, 'Comments'>
      & { StructuredSystem?: Maybe<(
        { __typename?: 'StructuredSystem' }
        & Pick<StructuredSystem, 'SystemName'>
        & { StructuredSystemProducts: Array<(
          { __typename?: 'StructuredSystemProducts' }
          & { ManufacturedProduct: (
            { __typename?: 'ManufacturedProduct' }
            & Pick<ManufacturedProduct, 'ComponentId' | 'TradeName' | 'ManufacturerId'>
            & { Manufacturer: (
              { __typename?: 'Manufacturer' }
              & Pick<Manufacturer, 'Name'>
            ) }
          ) }
        )> }
      )> }
    ), FasteningSystemClipUsage?: Maybe<(
      { __typename?: 'FasteningSystemClipUsage' }
      & Pick<FasteningSystemClipUsage, 'RowSpacing' | 'OnCenter' | 'FastenersPerPlateOrClip'>
    )> }
  )> }
);

export type GetCoatingSystemUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetCoatingSystemUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & Pick<Component, 'Comments'>
      & { StructuredSystem?: Maybe<(
        { __typename?: 'StructuredSystem' }
        & Pick<StructuredSystem, 'SystemName'>
        & { StructuredSystemProducts: Array<(
          { __typename?: 'StructuredSystemProducts' }
          & { ManufacturedProduct: (
            { __typename?: 'ManufacturedProduct' }
            & Pick<ManufacturedProduct, 'ComponentId' | 'TradeName' | 'ManufacturerId'>
            & { Manufacturer: (
              { __typename?: 'Manufacturer' }
              & Pick<Manufacturer, 'Name'>
            ) }
          ) }
        )> }
      )> }
    ), CoatingSystemUsage?: Maybe<(
      { __typename?: 'CoatingSystemUsage' }
      & Pick<CoatingSystemUsage, 'ApplicationRateWet' | 'ApplicationRateDry'>
    )> }
  )> }
);

export type GetBattenBarSystemUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetBattenBarSystemUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & Pick<Component, 'Comments'>
      & { StructuredSystem?: Maybe<(
        { __typename?: 'StructuredSystem' }
        & Pick<StructuredSystem, 'SystemName'>
        & { StructuredSystemProducts: Array<(
          { __typename?: 'StructuredSystemProducts' }
          & { ManufacturedProduct: (
            { __typename?: 'ManufacturedProduct' }
            & Pick<ManufacturedProduct, 'ComponentId' | 'TradeName' | 'ManufacturerId'>
            & { Manufacturer: (
              { __typename?: 'Manufacturer' }
              & Pick<Manufacturer, 'Name'>
            ) }
          ) }
        )> }
      )> }
    ), FasteningSystemBattenBarUsage?: Maybe<(
      { __typename?: 'FasteningSystemBattenBarUsage' }
      & Pick<FasteningSystemBattenBarUsage, 'RowSpacing' | 'OnCenter' | 'IsFieldRows' | 'FieldRowsOnCenter' | 'NumFieldRows' | 'FasteningApplicationMethodId'>
      & { C_FasteningApplicationMethod: (
        { __typename?: 'C_FasteningApplicationMethod' }
        & Pick<C_FasteningApplicationMethod, 'FasteningApplicationMethodCode'>
      ) }
    )> }
  )> }
);

export type GetWeldSolventUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetWeldSolventUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ) }
      )> }
    ), WeldSolventUsage?: Maybe<(
      { __typename?: 'WeldSolventUsage' }
      & Pick<WeldSolventUsage, 'WeldConfigurationId' | 'OutsideWidth' | 'InsideWidth'>
      & { C_WeldConfiguration: (
        { __typename?: 'C_WeldConfiguration' }
        & Pick<C_WeldConfiguration, 'WeldConfigurationCode'>
      ) }
    )> }
  )> }
);

export type GetFormDeckUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetFormDeckUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), FormDeck?: Maybe<(
          { __typename?: 'FormDeck' }
          & Pick<FormDeck, 'ThicknessMin' | 'Depth' | 'IsVented' | 'SpanMax'>
        )> }
      )> }
    ), FormDeckUsage?: Maybe<(
      { __typename?: 'FormDeckUsage' }
      & Pick<FormDeckUsage, 'SpanMax'>
    )> }
  )> }
);

export type GetAdhesiveSpotsUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetAdhesiveSpotsUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), AdhesiveSpot?: Maybe<(
          { __typename?: 'AdhesiveSpots' }
          & Pick<AdhesiveSpots, 'AdhesiveMaterialId' | 'AdhesiveTypeId'>
          & { C_AdhesiveMaterial: (
            { __typename?: 'C_AdhesiveMaterial' }
            & Pick<C_AdhesiveMaterial, 'AdhesiveMaterialCode'>
          ), C_AdhesiveType: (
            { __typename?: 'C_AdhesiveType' }
            & Pick<C_AdhesiveType, 'AdhesiveTypeCode'>
          ) }
        )> }
      )> }
    ), AdhesiveSpotsUsage?: Maybe<(
      { __typename?: 'AdhesiveSpotsUsage' }
      & Pick<AdhesiveSpotsUsage, 'RowSpacing' | 'OnCenter' | 'SpotDiameter'>
    )> }
  )> }
);

export type GetWeldArcUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetWeldArcUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ) }
      )> }
    ), WeldArcUsage?: Maybe<(
      { __typename?: 'WeldArcUsage' }
      & Pick<WeldArcUsage, 'RowSpacing' | 'OnCenter' | 'Diameter' | 'WithWasher'>
    )> }
  )> }
);

export type GetSurfacingUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetSurfacingUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & { ManufacturedProduct?: Maybe<(
        { __typename?: 'ManufacturedProduct' }
        & Pick<ManufacturedProduct, 'TradeName' | 'ManufacturerId'>
        & { Manufacturer: (
          { __typename?: 'Manufacturer' }
          & Pick<Manufacturer, 'Name'>
        ), Surfacing?: Maybe<(
          { __typename?: 'Surfacing' }
          & Pick<Surfacing, 'SurfacingMaterialId'>
          & { C_SurfacingMaterial: (
            { __typename?: 'C_SurfacingMaterial' }
            & Pick<C_SurfacingMaterial, 'SurfacingMaterialCode'>
          ) }
        )> }
      )> }
    ), SurfacingUsage?: Maybe<(
      { __typename?: 'SurfacingUsage' }
      & Pick<SurfacingUsage, 'ApplicationRate'>
    )> }
  )> }
);

export type GetAdhesiveSystemUsageDetailQueryVariables = Exact<{
  UsageId?: Maybe<Scalars['Int']>;
}>;


export type GetAdhesiveSystemUsageDetailQuery = (
  { __typename?: 'query_root' }
  & { Usage: Array<(
    { __typename?: 'Usage' }
    & Pick<Usage, 'UsageId' | 'ComponentId' | 'ApprovedUseId' | 'EnteredAsMetric' | 'Comments'>
    & { Component: (
      { __typename?: 'Component' }
      & Pick<Component, 'Comments'>
      & { StructuredSystem?: Maybe<(
        { __typename?: 'StructuredSystem' }
        & Pick<StructuredSystem, 'SystemName'>
        & { StructuredSystemProducts: Array<(
          { __typename?: 'StructuredSystemProducts' }
          & { ManufacturedProduct: (
            { __typename?: 'ManufacturedProduct' }
            & Pick<ManufacturedProduct, 'ComponentId' | 'TradeName' | 'ManufacturerId'>
            & { Manufacturer: (
              { __typename?: 'Manufacturer' }
              & Pick<Manufacturer, 'Name'>
            ) }
          ) }
        )> }
      )> }
    ), AdhesiveSystemUsage?: Maybe<(
      { __typename?: 'AdhesiveSystemUsage' }
      & Pick<AdhesiveSystemUsage, 'SubstratePrimerRateWet' | 'OverSubstrateRateWet' | 'UndersideRateWet' | 'SubstratePrimerRateDry' | 'OverSubstrateRateDry' | 'UndersideRateDry'>
    )> }
  )> }
);


export const GetApprovalsDocByIdDocument = gql`
    query GetApprovalsDocById($ApprovalsDocId: Int) {
  ApprovalsDoc(where: {ApprovalsDocId: {_eq: $ApprovalsDocId}}) {
    ApprovalsDocId
    DocName
    DocData
  }
}
    `;

/**
 * __useGetApprovalsDocByIdQuery__
 *
 * To run a query within a React component, call `useGetApprovalsDocByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalsDocByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalsDocByIdQuery({
 *   variables: {
 *      ApprovalsDocId: // value for 'ApprovalsDocId'
 *   },
 * });
 */
export function useGetApprovalsDocByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetApprovalsDocByIdQuery, GetApprovalsDocByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalsDocByIdQuery, GetApprovalsDocByIdQueryVariables>(GetApprovalsDocByIdDocument, options);
      }
export function useGetApprovalsDocByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalsDocByIdQuery, GetApprovalsDocByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalsDocByIdQuery, GetApprovalsDocByIdQueryVariables>(GetApprovalsDocByIdDocument, options);
        }
export type GetApprovalsDocByIdQueryHookResult = ReturnType<typeof useGetApprovalsDocByIdQuery>;
export type GetApprovalsDocByIdLazyQueryHookResult = ReturnType<typeof useGetApprovalsDocByIdLazyQuery>;
export type GetApprovalsDocByIdQueryResult = Apollo.QueryResult<GetApprovalsDocByIdQuery, GetApprovalsDocByIdQueryVariables>;
export const GetApprovalsDocByCategoryIdDocument = gql`
    query GetApprovalsDocByCategoryId($ApprovalsDocCategoryId: Int) {
  ApprovalsDoc(where: {ApprovalsDocCategoryId: {_eq: $ApprovalsDocCategoryId}}) {
    ApprovalsDocId
    DocName
    Dsc
    DateIssued
  }
}
    `;

/**
 * __useGetApprovalsDocByCategoryIdQuery__
 *
 * To run a query within a React component, call `useGetApprovalsDocByCategoryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalsDocByCategoryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalsDocByCategoryIdQuery({
 *   variables: {
 *      ApprovalsDocCategoryId: // value for 'ApprovalsDocCategoryId'
 *   },
 * });
 */
export function useGetApprovalsDocByCategoryIdQuery(baseOptions?: Apollo.QueryHookOptions<GetApprovalsDocByCategoryIdQuery, GetApprovalsDocByCategoryIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalsDocByCategoryIdQuery, GetApprovalsDocByCategoryIdQueryVariables>(GetApprovalsDocByCategoryIdDocument, options);
      }
export function useGetApprovalsDocByCategoryIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalsDocByCategoryIdQuery, GetApprovalsDocByCategoryIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalsDocByCategoryIdQuery, GetApprovalsDocByCategoryIdQueryVariables>(GetApprovalsDocByCategoryIdDocument, options);
        }
export type GetApprovalsDocByCategoryIdQueryHookResult = ReturnType<typeof useGetApprovalsDocByCategoryIdQuery>;
export type GetApprovalsDocByCategoryIdLazyQueryHookResult = ReturnType<typeof useGetApprovalsDocByCategoryIdLazyQuery>;
export type GetApprovalsDocByCategoryIdQueryResult = Apollo.QueryResult<GetApprovalsDocByCategoryIdQuery, GetApprovalsDocByCategoryIdQueryVariables>;
export const GetComponentDocByIdDocument = gql`
    query GetComponentDocById($ComponentDocId: Int) {
  ComponentDoc(where: {ComponentDocId: {_eq: $ComponentDocId}}) {
    ComponentDocId
    ComponentId
    DocData
  }
}
    `;

/**
 * __useGetComponentDocByIdQuery__
 *
 * To run a query within a React component, call `useGetComponentDocByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComponentDocByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComponentDocByIdQuery({
 *   variables: {
 *      ComponentDocId: // value for 'ComponentDocId'
 *   },
 * });
 */
export function useGetComponentDocByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetComponentDocByIdQuery, GetComponentDocByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComponentDocByIdQuery, GetComponentDocByIdQueryVariables>(GetComponentDocByIdDocument, options);
      }
export function useGetComponentDocByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComponentDocByIdQuery, GetComponentDocByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComponentDocByIdQuery, GetComponentDocByIdQueryVariables>(GetComponentDocByIdDocument, options);
        }
export type GetComponentDocByIdQueryHookResult = ReturnType<typeof useGetComponentDocByIdQuery>;
export type GetComponentDocByIdLazyQueryHookResult = ReturnType<typeof useGetComponentDocByIdLazyQuery>;
export type GetComponentDocByIdQueryResult = Apollo.QueryResult<GetComponentDocByIdQuery, GetComponentDocByIdQueryVariables>;
export const AssemblyApiSearchDocument = gql`
    query AssemblyAPISearch($assemblySearchCriteria: AssemblySearchCriteria!) {
  navassembly_api_search(assemblySearchCriteria: $assemblySearchCriteria) {
    NavAssemblyId
    NavAssemblyName
    AssemblyApplicationTypeCode
    CoverSecurementMethodCode
    AssemblyTypeCode
    DeckTypeCode
    Slope
    WindUplift
    IntFireRatingCode
    ExtFireRatingCode
    HailRatingCode
  }
}
    `;

/**
 * __useAssemblyApiSearchQuery__
 *
 * To run a query within a React component, call `useAssemblyApiSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssemblyApiSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssemblyApiSearchQuery({
 *   variables: {
 *      assemblySearchCriteria: // value for 'assemblySearchCriteria'
 *   },
 * });
 */
export function useAssemblyApiSearchQuery(baseOptions: Apollo.QueryHookOptions<AssemblyApiSearchQuery, AssemblyApiSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssemblyApiSearchQuery, AssemblyApiSearchQueryVariables>(AssemblyApiSearchDocument, options);
      }
export function useAssemblyApiSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssemblyApiSearchQuery, AssemblyApiSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssemblyApiSearchQuery, AssemblyApiSearchQueryVariables>(AssemblyApiSearchDocument, options);
        }
export type AssemblyApiSearchQueryHookResult = ReturnType<typeof useAssemblyApiSearchQuery>;
export type AssemblyApiSearchLazyQueryHookResult = ReturnType<typeof useAssemblyApiSearchLazyQuery>;
export type AssemblyApiSearchQueryResult = Apollo.QueryResult<AssemblyApiSearchQuery, AssemblyApiSearchQueryVariables>;
export const AssemblyApiRoofTypeSearchDocument = gql`
    query AssemblyAPIRoofTypeSearch($assemblySearchCriteria: AssemblySearchCriteria!) {
  navassembly_api_rooftypesearch(assemblySearchCriteria: $assemblySearchCriteria) {
    LayerConfigId
    ConfigString
    NumOfAssembly
  }
}
    `;

/**
 * __useAssemblyApiRoofTypeSearchQuery__
 *
 * To run a query within a React component, call `useAssemblyApiRoofTypeSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssemblyApiRoofTypeSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssemblyApiRoofTypeSearchQuery({
 *   variables: {
 *      assemblySearchCriteria: // value for 'assemblySearchCriteria'
 *   },
 * });
 */
export function useAssemblyApiRoofTypeSearchQuery(baseOptions: Apollo.QueryHookOptions<AssemblyApiRoofTypeSearchQuery, AssemblyApiRoofTypeSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssemblyApiRoofTypeSearchQuery, AssemblyApiRoofTypeSearchQueryVariables>(AssemblyApiRoofTypeSearchDocument, options);
      }
export function useAssemblyApiRoofTypeSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssemblyApiRoofTypeSearchQuery, AssemblyApiRoofTypeSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssemblyApiRoofTypeSearchQuery, AssemblyApiRoofTypeSearchQueryVariables>(AssemblyApiRoofTypeSearchDocument, options);
        }
export type AssemblyApiRoofTypeSearchQueryHookResult = ReturnType<typeof useAssemblyApiRoofTypeSearchQuery>;
export type AssemblyApiRoofTypeSearchLazyQueryHookResult = ReturnType<typeof useAssemblyApiRoofTypeSearchLazyQuery>;
export type AssemblyApiRoofTypeSearchQueryResult = Apollo.QueryResult<AssemblyApiRoofTypeSearchQuery, AssemblyApiRoofTypeSearchQueryVariables>;
export const AssemblyApiSearchResultCountDocument = gql`
    query AssemblyAPISearchResultCount($assemblySearchCriteria: AssemblySearchCriteria!) {
  navassembly_api_countsearchresult(
    assemblySearchCriteria: $assemblySearchCriteria
  ) {
    AssemblySearchResultCount
  }
}
    `;

/**
 * __useAssemblyApiSearchResultCountQuery__
 *
 * To run a query within a React component, call `useAssemblyApiSearchResultCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssemblyApiSearchResultCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssemblyApiSearchResultCountQuery({
 *   variables: {
 *      assemblySearchCriteria: // value for 'assemblySearchCriteria'
 *   },
 * });
 */
export function useAssemblyApiSearchResultCountQuery(baseOptions: Apollo.QueryHookOptions<AssemblyApiSearchResultCountQuery, AssemblyApiSearchResultCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssemblyApiSearchResultCountQuery, AssemblyApiSearchResultCountQueryVariables>(AssemblyApiSearchResultCountDocument, options);
      }
export function useAssemblyApiSearchResultCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssemblyApiSearchResultCountQuery, AssemblyApiSearchResultCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssemblyApiSearchResultCountQuery, AssemblyApiSearchResultCountQueryVariables>(AssemblyApiSearchResultCountDocument, options);
        }
export type AssemblyApiSearchResultCountQueryHookResult = ReturnType<typeof useAssemblyApiSearchResultCountQuery>;
export type AssemblyApiSearchResultCountLazyQueryHookResult = ReturnType<typeof useAssemblyApiSearchResultCountLazyQuery>;
export type AssemblyApiSearchResultCountQueryResult = Apollo.QueryResult<AssemblyApiSearchResultCountQuery, AssemblyApiSearchResultCountQueryVariables>;
export const GetNavAssemblyCompleteDetailDocument = gql`
    query GetNavAssemblyCompleteDetail($NavAssemblyId: Int) {
  NavAssembly(where: {NavAssemblyId: {_eq: $NavAssemblyId}}) {
    NavAssemblyId
    NavAssemblyName
    AssemblyId
    RoofAssembly {
      AssemblyTypeId
      C_AssemblyType {
        AssemblyTypeCode
      }
      IntFireRatingId
      C_IntFireRating {
        IntFireRatingCode
      }
      ExtFireRatingId
      C_ExtFireRating {
        ExtFireRatingCode
      }
      AssemblyApplicationTypeId
      C_AssemblyApplicationType {
        AssemblyApplicationTypeCode
      }
      HailRatingId
      C_HailRating {
        HailRatingCode
      }
      CoverSecurementMethodId
      C_CoverSecurementMethod {
        CoverSecurementMethodCode
      }
      DeckTypeId
      C_DeckType {
        DeckTypeCode
      }
      PVApplicationMethodId
      C_PVApplicationMethod {
        PVApplicationMethodCode
      }
      PVHailRatingId
      C_PVHailRating {
        PVHailRatingCode
      }
      Slope
      WindUplift
      IsUsedWithCombustibleWalls
      IncludesPV
      PVWindUplift
      LockStatusId
    }
    R_NavAssembly_NavMultiplyCoverConfigs {
      NavMultiplyCoverConfigId
      NavMultiplyCoverConfig {
        CoverConfiguration
        NumPlies
        SubassemblyTypeId
        C_SubassemblyType {
          SubassemblyTypeCode
        }
      }
    }
  }
  vNavAssembly_Layers(
    where: {NavAssemblyId: {_eq: $NavAssemblyId}}
    order_by: {NavLayerNum: asc}
  ) {
    NavLayerId
    NavLayerNum
    LayerTypeId
    LayerTypeCode
    FromNavLayerId
    FromNavLayerNum
    ToNavLayerId
    ToNavLayerNum
    IsOptional
    IsPermeable
    Comments
    LayerId
  }
  vNavAssembly_Usages(
    where: {NavAssemblyId: {_eq: $NavAssemblyId}}
    order_by: [{NavLayerNum: asc}, {SelfSecurementLayerTypeId: asc}, {SystemName: asc}, {SystemProductOrder: asc}, {ProductManufacturer: asc}, {ProductTradeName: asc}]
  ) {
    NavLayerId
    NavLayerNum
    UsageId
    ApprovedUseId
    SystemComponentId
    SystemName
    SystemProductComponentId
    SystemProductTradeName
    SystemProductManufacturer
    SystemProductOrder
    ProductComponentId
    ProductTradeName
    ProductManufacturer
    SelfSecurementLayerTypeId
    SelfSecurementComments
  }
}
    `;

/**
 * __useGetNavAssemblyCompleteDetailQuery__
 *
 * To run a query within a React component, call `useGetNavAssemblyCompleteDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavAssemblyCompleteDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavAssemblyCompleteDetailQuery({
 *   variables: {
 *      NavAssemblyId: // value for 'NavAssemblyId'
 *   },
 * });
 */
export function useGetNavAssemblyCompleteDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetNavAssemblyCompleteDetailQuery, GetNavAssemblyCompleteDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNavAssemblyCompleteDetailQuery, GetNavAssemblyCompleteDetailQueryVariables>(GetNavAssemblyCompleteDetailDocument, options);
      }
export function useGetNavAssemblyCompleteDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNavAssemblyCompleteDetailQuery, GetNavAssemblyCompleteDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNavAssemblyCompleteDetailQuery, GetNavAssemblyCompleteDetailQueryVariables>(GetNavAssemblyCompleteDetailDocument, options);
        }
export type GetNavAssemblyCompleteDetailQueryHookResult = ReturnType<typeof useGetNavAssemblyCompleteDetailQuery>;
export type GetNavAssemblyCompleteDetailLazyQueryHookResult = ReturnType<typeof useGetNavAssemblyCompleteDetailLazyQuery>;
export type GetNavAssemblyCompleteDetailQueryResult = Apollo.QueryResult<GetNavAssemblyCompleteDetailQuery, GetNavAssemblyCompleteDetailQueryVariables>;
export const GetSinglePlyAdhesiveRelatedUsagesDocument = gql`
    query GetSinglePlyAdhesiveRelatedUsages($NavAssemblyId: Int) {
  NavAssembly(where: {NavAssemblyId: {_eq: $NavAssemblyId}}) {
    Nav_SinglePlyBondingAdhesives {
      SinglePlyComponentId
      SinglePlyUsageId
      AdhesiveComponentId
      AdhesiveUsageId
    }
    Nav_SinglePlyLapAdhesives {
      SinglePlyComponentId
      SinglePlyUsageId
      AdhesiveComponentId
      AdhesiveUsageId
    }
  }
}
    `;

/**
 * __useGetSinglePlyAdhesiveRelatedUsagesQuery__
 *
 * To run a query within a React component, call `useGetSinglePlyAdhesiveRelatedUsagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSinglePlyAdhesiveRelatedUsagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSinglePlyAdhesiveRelatedUsagesQuery({
 *   variables: {
 *      NavAssemblyId: // value for 'NavAssemblyId'
 *   },
 * });
 */
export function useGetSinglePlyAdhesiveRelatedUsagesQuery(baseOptions?: Apollo.QueryHookOptions<GetSinglePlyAdhesiveRelatedUsagesQuery, GetSinglePlyAdhesiveRelatedUsagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSinglePlyAdhesiveRelatedUsagesQuery, GetSinglePlyAdhesiveRelatedUsagesQueryVariables>(GetSinglePlyAdhesiveRelatedUsagesDocument, options);
      }
export function useGetSinglePlyAdhesiveRelatedUsagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSinglePlyAdhesiveRelatedUsagesQuery, GetSinglePlyAdhesiveRelatedUsagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSinglePlyAdhesiveRelatedUsagesQuery, GetSinglePlyAdhesiveRelatedUsagesQueryVariables>(GetSinglePlyAdhesiveRelatedUsagesDocument, options);
        }
export type GetSinglePlyAdhesiveRelatedUsagesQueryHookResult = ReturnType<typeof useGetSinglePlyAdhesiveRelatedUsagesQuery>;
export type GetSinglePlyAdhesiveRelatedUsagesLazyQueryHookResult = ReturnType<typeof useGetSinglePlyAdhesiveRelatedUsagesLazyQuery>;
export type GetSinglePlyAdhesiveRelatedUsagesQueryResult = Apollo.QueryResult<GetSinglePlyAdhesiveRelatedUsagesQuery, GetSinglePlyAdhesiveRelatedUsagesQueryVariables>;
export const GetAssemblyCountByFiltersDocument = gql`
    query GetAssemblyCountByFilters($filters: NavAssembly_bool_exp) {
  NavAssembly_aggregate(where: $filters) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAssemblyCountByFiltersQuery__
 *
 * To run a query within a React component, call `useGetAssemblyCountByFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssemblyCountByFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssemblyCountByFiltersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAssemblyCountByFiltersQuery(baseOptions?: Apollo.QueryHookOptions<GetAssemblyCountByFiltersQuery, GetAssemblyCountByFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssemblyCountByFiltersQuery, GetAssemblyCountByFiltersQueryVariables>(GetAssemblyCountByFiltersDocument, options);
      }
export function useGetAssemblyCountByFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssemblyCountByFiltersQuery, GetAssemblyCountByFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssemblyCountByFiltersQuery, GetAssemblyCountByFiltersQueryVariables>(GetAssemblyCountByFiltersDocument, options);
        }
export type GetAssemblyCountByFiltersQueryHookResult = ReturnType<typeof useGetAssemblyCountByFiltersQuery>;
export type GetAssemblyCountByFiltersLazyQueryHookResult = ReturnType<typeof useGetAssemblyCountByFiltersLazyQuery>;
export type GetAssemblyCountByFiltersQueryResult = Apollo.QueryResult<GetAssemblyCountByFiltersQuery, GetAssemblyCountByFiltersQueryVariables>;
export const GetNavAssemblyIdByFiltersDocument = gql`
    query GetNavAssemblyIdByFilters($filters: NavAssembly_bool_exp) {
  NavAssembly(where: $filters) {
    NavAssemblyId
  }
}
    `;

/**
 * __useGetNavAssemblyIdByFiltersQuery__
 *
 * To run a query within a React component, call `useGetNavAssemblyIdByFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavAssemblyIdByFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavAssemblyIdByFiltersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetNavAssemblyIdByFiltersQuery(baseOptions?: Apollo.QueryHookOptions<GetNavAssemblyIdByFiltersQuery, GetNavAssemblyIdByFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNavAssemblyIdByFiltersQuery, GetNavAssemblyIdByFiltersQueryVariables>(GetNavAssemblyIdByFiltersDocument, options);
      }
export function useGetNavAssemblyIdByFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNavAssemblyIdByFiltersQuery, GetNavAssemblyIdByFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNavAssemblyIdByFiltersQuery, GetNavAssemblyIdByFiltersQueryVariables>(GetNavAssemblyIdByFiltersDocument, options);
        }
export type GetNavAssemblyIdByFiltersQueryHookResult = ReturnType<typeof useGetNavAssemblyIdByFiltersQuery>;
export type GetNavAssemblyIdByFiltersLazyQueryHookResult = ReturnType<typeof useGetNavAssemblyIdByFiltersLazyQuery>;
export type GetNavAssemblyIdByFiltersQueryResult = Apollo.QueryResult<GetNavAssemblyIdByFiltersQuery, GetNavAssemblyIdByFiltersQueryVariables>;
export const GetAssembliesByFiltersDocument = gql`
    query GetAssembliesByFilters($filters: NavAssembly_bool_exp, $limit: Int, $offset: Int, $order_by: NavAssembly_order_by!) {
  NavAssembly(
    where: $filters
    limit: $limit
    offset: $offset
    order_by: [$order_by]
  ) {
    NavAssemblyId
    NavAssemblyName
    AssemblyId
    AssemblyTypeId
    IntFireRatingId
    ExtFireRatingId
    AssemblyApplicationTypeId
    HailRatingId
    CoverSecurementMethodId
    DeckTypeId
    Slope
    WindUplift
    RoofAssembly {
      C_AssemblyType {
        AssemblyTypeCode
      }
      C_IntFireRating {
        IntFireRatingCode
      }
      C_ExtFireRating {
        ExtFireRatingCode
      }
      C_AssemblyApplicationType {
        AssemblyApplicationTypeCode
      }
      C_HailRating {
        HailRatingCode
      }
      C_CoverSecurementMethod {
        CoverSecurementMethodCode
      }
      C_DeckType {
        DeckTypeCode
      }
    }
  }
}
    `;

/**
 * __useGetAssembliesByFiltersQuery__
 *
 * To run a query within a React component, call `useGetAssembliesByFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssembliesByFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssembliesByFiltersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetAssembliesByFiltersQuery(baseOptions: Apollo.QueryHookOptions<GetAssembliesByFiltersQuery, GetAssembliesByFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssembliesByFiltersQuery, GetAssembliesByFiltersQueryVariables>(GetAssembliesByFiltersDocument, options);
      }
export function useGetAssembliesByFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssembliesByFiltersQuery, GetAssembliesByFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssembliesByFiltersQuery, GetAssembliesByFiltersQueryVariables>(GetAssembliesByFiltersDocument, options);
        }
export type GetAssembliesByFiltersQueryHookResult = ReturnType<typeof useGetAssembliesByFiltersQuery>;
export type GetAssembliesByFiltersLazyQueryHookResult = ReturnType<typeof useGetAssembliesByFiltersLazyQuery>;
export type GetAssembliesByFiltersQueryResult = Apollo.QueryResult<GetAssembliesByFiltersQuery, GetAssembliesByFiltersQueryVariables>;
export const GetAssemblyIdsByFiltersDocument = gql`
    query GetAssemblyIdsByFilters($filters: NavAssembly_bool_exp, $limit: Int, $offset: Int, $order_by: NavAssembly_order_by!) {
  NavAssembly(
    where: $filters
    limit: $limit
    offset: $offset
    order_by: [$order_by]
  ) {
    NavAssemblyId
  }
}
    `;

/**
 * __useGetAssemblyIdsByFiltersQuery__
 *
 * To run a query within a React component, call `useGetAssemblyIdsByFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssemblyIdsByFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssemblyIdsByFiltersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetAssemblyIdsByFiltersQuery(baseOptions: Apollo.QueryHookOptions<GetAssemblyIdsByFiltersQuery, GetAssemblyIdsByFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssemblyIdsByFiltersQuery, GetAssemblyIdsByFiltersQueryVariables>(GetAssemblyIdsByFiltersDocument, options);
      }
export function useGetAssemblyIdsByFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssemblyIdsByFiltersQuery, GetAssemblyIdsByFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssemblyIdsByFiltersQuery, GetAssemblyIdsByFiltersQueryVariables>(GetAssemblyIdsByFiltersDocument, options);
        }
export type GetAssemblyIdsByFiltersQueryHookResult = ReturnType<typeof useGetAssemblyIdsByFiltersQuery>;
export type GetAssemblyIdsByFiltersLazyQueryHookResult = ReturnType<typeof useGetAssemblyIdsByFiltersLazyQuery>;
export type GetAssemblyIdsByFiltersQueryResult = Apollo.QueryResult<GetAssemblyIdsByFiltersQuery, GetAssemblyIdsByFiltersQueryVariables>;
export const GetAssembliesByIdsDocument = gql`
    query GetAssembliesByIds($ids: NavAssembly_bool_exp, $order_by: NavAssembly_order_by!) {
  NavAssembly(where: $ids, order_by: [$order_by]) {
    NavAssemblyId
    NavAssemblyName
    AssemblyId
    AssemblyTypeId
    IntFireRatingId
    ExtFireRatingId
    AssemblyApplicationTypeId
    HailRatingId
    CoverSecurementMethodId
    DeckTypeId
    Slope
    WindUplift
    AssemblyTypeCode
    IntFireRatingCode
    ExtFireRatingCode
    AssemblyApplicationTypeCode
    HailRatingCode
    CoverSecurementMethodCode
    DeckTypeCode
  }
}
    `;

/**
 * __useGetAssembliesByIdsQuery__
 *
 * To run a query within a React component, call `useGetAssembliesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssembliesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssembliesByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetAssembliesByIdsQuery(baseOptions: Apollo.QueryHookOptions<GetAssembliesByIdsQuery, GetAssembliesByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssembliesByIdsQuery, GetAssembliesByIdsQueryVariables>(GetAssembliesByIdsDocument, options);
      }
export function useGetAssembliesByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssembliesByIdsQuery, GetAssembliesByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssembliesByIdsQuery, GetAssembliesByIdsQueryVariables>(GetAssembliesByIdsDocument, options);
        }
export type GetAssembliesByIdsQueryHookResult = ReturnType<typeof useGetAssembliesByIdsQuery>;
export type GetAssembliesByIdsLazyQueryHookResult = ReturnType<typeof useGetAssembliesByIdsLazyQuery>;
export type GetAssembliesByIdsQueryResult = Apollo.QueryResult<GetAssembliesByIdsQuery, GetAssembliesByIdsQueryVariables>;
export const SearchNavAssembliesDocument = gql`
    query SearchNavAssemblies($filters: NavAssembly_bool_exp, $limit: Int, $offset: Int, $order_by: NavAssembly_order_by!) {
  NavAssembly(
    where: $filters
    limit: $limit
    offset: $offset
    order_by: [$order_by]
  ) {
    NavAssemblyId
    NavAssemblyName
    AssemblyId
    AssemblyTypeId
    IntFireRatingId
    ExtFireRatingId
    AssemblyApplicationTypeId
    HailRatingId
    CoverSecurementMethodId
    DeckTypeId
    Slope
    WindUplift
    AssemblyTypeCode
    IntFireRatingCode
    ExtFireRatingCode
    AssemblyApplicationTypeCode
    HailRatingCode
    CoverSecurementMethodCode
    DeckTypeCode
  }
}
    `;

/**
 * __useSearchNavAssembliesQuery__
 *
 * To run a query within a React component, call `useSearchNavAssembliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNavAssembliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNavAssembliesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSearchNavAssembliesQuery(baseOptions: Apollo.QueryHookOptions<SearchNavAssembliesQuery, SearchNavAssembliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchNavAssembliesQuery, SearchNavAssembliesQueryVariables>(SearchNavAssembliesDocument, options);
      }
export function useSearchNavAssembliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchNavAssembliesQuery, SearchNavAssembliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchNavAssembliesQuery, SearchNavAssembliesQueryVariables>(SearchNavAssembliesDocument, options);
        }
export type SearchNavAssembliesQueryHookResult = ReturnType<typeof useSearchNavAssembliesQuery>;
export type SearchNavAssembliesLazyQueryHookResult = ReturnType<typeof useSearchNavAssembliesLazyQuery>;
export type SearchNavAssembliesQueryResult = Apollo.QueryResult<SearchNavAssembliesQuery, SearchNavAssembliesQueryVariables>;
export const SearchNavAssembliesByManufacturerDocument = gql`
    query SearchNavAssembliesByManufacturer($filters: vNavAssembly_ManufacturerOnlySearch_bool_exp, $limit: Int, $offset: Int, $order_by: vNavAssembly_ManufacturerOnlySearch_order_by!) {
  vNavAssembly_ManufacturerOnlySearch(
    where: $filters
    limit: $limit
    offset: $offset
    order_by: [$order_by]
  ) {
    NavAssemblyId
  }
}
    `;

/**
 * __useSearchNavAssembliesByManufacturerQuery__
 *
 * To run a query within a React component, call `useSearchNavAssembliesByManufacturerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNavAssembliesByManufacturerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNavAssembliesByManufacturerQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSearchNavAssembliesByManufacturerQuery(baseOptions: Apollo.QueryHookOptions<SearchNavAssembliesByManufacturerQuery, SearchNavAssembliesByManufacturerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchNavAssembliesByManufacturerQuery, SearchNavAssembliesByManufacturerQueryVariables>(SearchNavAssembliesByManufacturerDocument, options);
      }
export function useSearchNavAssembliesByManufacturerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchNavAssembliesByManufacturerQuery, SearchNavAssembliesByManufacturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchNavAssembliesByManufacturerQuery, SearchNavAssembliesByManufacturerQueryVariables>(SearchNavAssembliesByManufacturerDocument, options);
        }
export type SearchNavAssembliesByManufacturerQueryHookResult = ReturnType<typeof useSearchNavAssembliesByManufacturerQuery>;
export type SearchNavAssembliesByManufacturerLazyQueryHookResult = ReturnType<typeof useSearchNavAssembliesByManufacturerLazyQuery>;
export type SearchNavAssembliesByManufacturerQueryResult = Apollo.QueryResult<SearchNavAssembliesByManufacturerQuery, SearchNavAssembliesByManufacturerQueryVariables>;
export const GetNavAssembliesByBaseSheetDocument = gql`
    query GetNavAssembliesByBaseSheet($filters: vNavAssembly_Search_BaseSheet_bool_exp) {
  vNavAssembly_Search_BaseSheet(
    where: $filters
    order_by: {NavAssemblyId: asc, NavLayerNum: asc}
  ) {
    NavAssemblyId
    NavLayerNum
  }
}
    `;

/**
 * __useGetNavAssembliesByBaseSheetQuery__
 *
 * To run a query within a React component, call `useGetNavAssembliesByBaseSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavAssembliesByBaseSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavAssembliesByBaseSheetQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetNavAssembliesByBaseSheetQuery(baseOptions?: Apollo.QueryHookOptions<GetNavAssembliesByBaseSheetQuery, GetNavAssembliesByBaseSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNavAssembliesByBaseSheetQuery, GetNavAssembliesByBaseSheetQueryVariables>(GetNavAssembliesByBaseSheetDocument, options);
      }
export function useGetNavAssembliesByBaseSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNavAssembliesByBaseSheetQuery, GetNavAssembliesByBaseSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNavAssembliesByBaseSheetQuery, GetNavAssembliesByBaseSheetQueryVariables>(GetNavAssembliesByBaseSheetDocument, options);
        }
export type GetNavAssembliesByBaseSheetQueryHookResult = ReturnType<typeof useGetNavAssembliesByBaseSheetQuery>;
export type GetNavAssembliesByBaseSheetLazyQueryHookResult = ReturnType<typeof useGetNavAssembliesByBaseSheetLazyQuery>;
export type GetNavAssembliesByBaseSheetQueryResult = Apollo.QueryResult<GetNavAssembliesByBaseSheetQuery, GetNavAssembliesByBaseSheetQueryVariables>;
export const GetNavAssembliesBySubstratePrimerDocument = gql`
    query GetNavAssembliesBySubstratePrimer($filters: vNavAssembly_Search_SubstratePrimer_bool_exp) {
  vNavAssembly_Search_SubstratePrimer(
    where: $filters
    order_by: {NavAssemblyId: asc, NavLayerNum: asc}
  ) {
    NavAssemblyId
    NavLayerNum
  }
}
    `;

/**
 * __useGetNavAssembliesBySubstratePrimerQuery__
 *
 * To run a query within a React component, call `useGetNavAssembliesBySubstratePrimerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavAssembliesBySubstratePrimerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavAssembliesBySubstratePrimerQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetNavAssembliesBySubstratePrimerQuery(baseOptions?: Apollo.QueryHookOptions<GetNavAssembliesBySubstratePrimerQuery, GetNavAssembliesBySubstratePrimerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNavAssembliesBySubstratePrimerQuery, GetNavAssembliesBySubstratePrimerQueryVariables>(GetNavAssembliesBySubstratePrimerDocument, options);
      }
export function useGetNavAssembliesBySubstratePrimerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNavAssembliesBySubstratePrimerQuery, GetNavAssembliesBySubstratePrimerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNavAssembliesBySubstratePrimerQuery, GetNavAssembliesBySubstratePrimerQueryVariables>(GetNavAssembliesBySubstratePrimerDocument, options);
        }
export type GetNavAssembliesBySubstratePrimerQueryHookResult = ReturnType<typeof useGetNavAssembliesBySubstratePrimerQuery>;
export type GetNavAssembliesBySubstratePrimerLazyQueryHookResult = ReturnType<typeof useGetNavAssembliesBySubstratePrimerLazyQuery>;
export type GetNavAssembliesBySubstratePrimerQueryResult = Apollo.QueryResult<GetNavAssembliesBySubstratePrimerQuery, GetNavAssembliesBySubstratePrimerQueryVariables>;
export const SaveContactDocument = gql`
    query SaveContact($saveContactInfo: SaveContactInfo!) {
  contact_api_savecontact(saveContactInfo: $saveContactInfo) {
    ContactInfoId
  }
}
    `;

/**
 * __useSaveContactQuery__
 *
 * To run a query within a React component, call `useSaveContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaveContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaveContactQuery({
 *   variables: {
 *      saveContactInfo: // value for 'saveContactInfo'
 *   },
 * });
 */
export function useSaveContactQuery(baseOptions: Apollo.QueryHookOptions<SaveContactQuery, SaveContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SaveContactQuery, SaveContactQueryVariables>(SaveContactDocument, options);
      }
export function useSaveContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SaveContactQuery, SaveContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SaveContactQuery, SaveContactQueryVariables>(SaveContactDocument, options);
        }
export type SaveContactQueryHookResult = ReturnType<typeof useSaveContactQuery>;
export type SaveContactLazyQueryHookResult = ReturnType<typeof useSaveContactLazyQuery>;
export type SaveContactQueryResult = Apollo.QueryResult<SaveContactQuery, SaveContactQueryVariables>;
export const GetContactsByUserEmailDocument = gql`
    query GetContactsByUserEmail($UserEmail: String, $RandomId: String) {
  R_UserContact(
    where: {_and: [{AzureB2CUserEmail: {_eq: $UserEmail}}, {AzureB2CUserGuid: {_neq: $RandomId}}]}
  ) {
    ContactInfo {
      ContactInfoId
      FirstName
      LastName
      CompanyName
      Phone
      Address {
        AddressId
        C_StateProvince {
          StateProvinceCode
        }
        C_Country {
          CountryCode
        }
      }
    }
  }
}
    `;

/**
 * __useGetContactsByUserEmailQuery__
 *
 * To run a query within a React component, call `useGetContactsByUserEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsByUserEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsByUserEmailQuery({
 *   variables: {
 *      UserEmail: // value for 'UserEmail'
 *      RandomId: // value for 'RandomId'
 *   },
 * });
 */
export function useGetContactsByUserEmailQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsByUserEmailQuery, GetContactsByUserEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactsByUserEmailQuery, GetContactsByUserEmailQueryVariables>(GetContactsByUserEmailDocument, options);
      }
export function useGetContactsByUserEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsByUserEmailQuery, GetContactsByUserEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactsByUserEmailQuery, GetContactsByUserEmailQueryVariables>(GetContactsByUserEmailDocument, options);
        }
export type GetContactsByUserEmailQueryHookResult = ReturnType<typeof useGetContactsByUserEmailQuery>;
export type GetContactsByUserEmailLazyQueryHookResult = ReturnType<typeof useGetContactsByUserEmailLazyQuery>;
export type GetContactsByUserEmailQueryResult = Apollo.QueryResult<GetContactsByUserEmailQuery, GetContactsByUserEmailQueryVariables>;
export const GetContactDetailDocument = gql`
    query GetContactDetail($ContactInfoId: Int) {
  ContactInfo(where: {ContactInfoId: {_eq: $ContactInfoId}}) {
    FirstName
    LastName
    Initial
    CompanyName
    Phone
    Fax
    Email
    Address {
      AddressId
      Line1
      Line2
      Line3
      City
      PostalCode
      C_StateProvince {
        StateProvinceCode
      }
      C_Country {
        CountryCode
      }
    }
  }
}
    `;

/**
 * __useGetContactDetailQuery__
 *
 * To run a query within a React component, call `useGetContactDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactDetailQuery({
 *   variables: {
 *      ContactInfoId: // value for 'ContactInfoId'
 *   },
 * });
 */
export function useGetContactDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetContactDetailQuery, GetContactDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactDetailQuery, GetContactDetailQueryVariables>(GetContactDetailDocument, options);
      }
export function useGetContactDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactDetailQuery, GetContactDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactDetailQuery, GetContactDetailQueryVariables>(GetContactDetailDocument, options);
        }
export type GetContactDetailQueryHookResult = ReturnType<typeof useGetContactDetailQuery>;
export type GetContactDetailLazyQueryHookResult = ReturnType<typeof useGetContactDetailLazyQuery>;
export type GetContactDetailQueryResult = Apollo.QueryResult<GetContactDetailQuery, GetContactDetailQueryVariables>;
export const GetExtendedLayerConfigsDocument = gql`
    query getExtendedLayerConfigs {
  ExtendedLayerConfigSplit {
    ExtendedLayerConfigId
    FullConfigString
  }
}
    `;

/**
 * __useGetExtendedLayerConfigsQuery__
 *
 * To run a query within a React component, call `useGetExtendedLayerConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtendedLayerConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtendedLayerConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExtendedLayerConfigsQuery(baseOptions?: Apollo.QueryHookOptions<GetExtendedLayerConfigsQuery, GetExtendedLayerConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExtendedLayerConfigsQuery, GetExtendedLayerConfigsQueryVariables>(GetExtendedLayerConfigsDocument, options);
      }
export function useGetExtendedLayerConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExtendedLayerConfigsQuery, GetExtendedLayerConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExtendedLayerConfigsQuery, GetExtendedLayerConfigsQueryVariables>(GetExtendedLayerConfigsDocument, options);
        }
export type GetExtendedLayerConfigsQueryHookResult = ReturnType<typeof useGetExtendedLayerConfigsQuery>;
export type GetExtendedLayerConfigsLazyQueryHookResult = ReturnType<typeof useGetExtendedLayerConfigsLazyQuery>;
export type GetExtendedLayerConfigsQueryResult = Apollo.QueryResult<GetExtendedLayerConfigsQuery, GetExtendedLayerConfigsQueryVariables>;
export const Get_C_AssemblyTypeDocument = gql`
    query Get_C_AssemblyType {
  C_AssemblyType {
    AssemblyTypeCode
    AssemblyTypeId
  }
}
    `;

/**
 * __useGet_C_AssemblyTypeQuery__
 *
 * To run a query within a React component, call `useGet_C_AssemblyTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_C_AssemblyTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_C_AssemblyTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_C_AssemblyTypeQuery(baseOptions?: Apollo.QueryHookOptions<Get_C_AssemblyTypeQuery, Get_C_AssemblyTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_C_AssemblyTypeQuery, Get_C_AssemblyTypeQueryVariables>(Get_C_AssemblyTypeDocument, options);
      }
export function useGet_C_AssemblyTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_C_AssemblyTypeQuery, Get_C_AssemblyTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_C_AssemblyTypeQuery, Get_C_AssemblyTypeQueryVariables>(Get_C_AssemblyTypeDocument, options);
        }
export type Get_C_AssemblyTypeQueryHookResult = ReturnType<typeof useGet_C_AssemblyTypeQuery>;
export type Get_C_AssemblyTypeLazyQueryHookResult = ReturnType<typeof useGet_C_AssemblyTypeLazyQuery>;
export type Get_C_AssemblyTypeQueryResult = Apollo.QueryResult<Get_C_AssemblyTypeQuery, Get_C_AssemblyTypeQueryVariables>;
export const Get_C_AssemblyApplicationTypeDocument = gql`
    query Get_C_AssemblyApplicationType {
  C_AssemblyApplicationType {
    AssemblyApplicationTypeCode
    AssemblyApplicationTypeId
  }
}
    `;

/**
 * __useGet_C_AssemblyApplicationTypeQuery__
 *
 * To run a query within a React component, call `useGet_C_AssemblyApplicationTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_C_AssemblyApplicationTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_C_AssemblyApplicationTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_C_AssemblyApplicationTypeQuery(baseOptions?: Apollo.QueryHookOptions<Get_C_AssemblyApplicationTypeQuery, Get_C_AssemblyApplicationTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_C_AssemblyApplicationTypeQuery, Get_C_AssemblyApplicationTypeQueryVariables>(Get_C_AssemblyApplicationTypeDocument, options);
      }
export function useGet_C_AssemblyApplicationTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_C_AssemblyApplicationTypeQuery, Get_C_AssemblyApplicationTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_C_AssemblyApplicationTypeQuery, Get_C_AssemblyApplicationTypeQueryVariables>(Get_C_AssemblyApplicationTypeDocument, options);
        }
export type Get_C_AssemblyApplicationTypeQueryHookResult = ReturnType<typeof useGet_C_AssemblyApplicationTypeQuery>;
export type Get_C_AssemblyApplicationTypeLazyQueryHookResult = ReturnType<typeof useGet_C_AssemblyApplicationTypeLazyQuery>;
export type Get_C_AssemblyApplicationTypeQueryResult = Apollo.QueryResult<Get_C_AssemblyApplicationTypeQuery, Get_C_AssemblyApplicationTypeQueryVariables>;
export const Get_C_CoverSecurementMethodDocument = gql`
    query Get_C_CoverSecurementMethod {
  C_CoverSecurementMethod {
    CoverSecurementMethodCode
    CoverSecurementMethodId
  }
}
    `;

/**
 * __useGet_C_CoverSecurementMethodQuery__
 *
 * To run a query within a React component, call `useGet_C_CoverSecurementMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_C_CoverSecurementMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_C_CoverSecurementMethodQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_C_CoverSecurementMethodQuery(baseOptions?: Apollo.QueryHookOptions<Get_C_CoverSecurementMethodQuery, Get_C_CoverSecurementMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_C_CoverSecurementMethodQuery, Get_C_CoverSecurementMethodQueryVariables>(Get_C_CoverSecurementMethodDocument, options);
      }
export function useGet_C_CoverSecurementMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_C_CoverSecurementMethodQuery, Get_C_CoverSecurementMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_C_CoverSecurementMethodQuery, Get_C_CoverSecurementMethodQueryVariables>(Get_C_CoverSecurementMethodDocument, options);
        }
export type Get_C_CoverSecurementMethodQueryHookResult = ReturnType<typeof useGet_C_CoverSecurementMethodQuery>;
export type Get_C_CoverSecurementMethodLazyQueryHookResult = ReturnType<typeof useGet_C_CoverSecurementMethodLazyQuery>;
export type Get_C_CoverSecurementMethodQueryResult = Apollo.QueryResult<Get_C_CoverSecurementMethodQuery, Get_C_CoverSecurementMethodQueryVariables>;
export const Get_C_DeckTypeDocument = gql`
    query Get_C_DeckType {
  C_DeckType {
    DeckTypeCode
    DeckTypeId
  }
}
    `;

/**
 * __useGet_C_DeckTypeQuery__
 *
 * To run a query within a React component, call `useGet_C_DeckTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_C_DeckTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_C_DeckTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_C_DeckTypeQuery(baseOptions?: Apollo.QueryHookOptions<Get_C_DeckTypeQuery, Get_C_DeckTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_C_DeckTypeQuery, Get_C_DeckTypeQueryVariables>(Get_C_DeckTypeDocument, options);
      }
export function useGet_C_DeckTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_C_DeckTypeQuery, Get_C_DeckTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_C_DeckTypeQuery, Get_C_DeckTypeQueryVariables>(Get_C_DeckTypeDocument, options);
        }
export type Get_C_DeckTypeQueryHookResult = ReturnType<typeof useGet_C_DeckTypeQuery>;
export type Get_C_DeckTypeLazyQueryHookResult = ReturnType<typeof useGet_C_DeckTypeLazyQuery>;
export type Get_C_DeckTypeQueryResult = Apollo.QueryResult<Get_C_DeckTypeQuery, Get_C_DeckTypeQueryVariables>;
export const Get_C_IntFireRatingDocument = gql`
    query Get_C_IntFireRating {
  C_IntFireRating {
    IntFireRatingCode
    IntFireRatingId
  }
}
    `;

/**
 * __useGet_C_IntFireRatingQuery__
 *
 * To run a query within a React component, call `useGet_C_IntFireRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_C_IntFireRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_C_IntFireRatingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_C_IntFireRatingQuery(baseOptions?: Apollo.QueryHookOptions<Get_C_IntFireRatingQuery, Get_C_IntFireRatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_C_IntFireRatingQuery, Get_C_IntFireRatingQueryVariables>(Get_C_IntFireRatingDocument, options);
      }
export function useGet_C_IntFireRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_C_IntFireRatingQuery, Get_C_IntFireRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_C_IntFireRatingQuery, Get_C_IntFireRatingQueryVariables>(Get_C_IntFireRatingDocument, options);
        }
export type Get_C_IntFireRatingQueryHookResult = ReturnType<typeof useGet_C_IntFireRatingQuery>;
export type Get_C_IntFireRatingLazyQueryHookResult = ReturnType<typeof useGet_C_IntFireRatingLazyQuery>;
export type Get_C_IntFireRatingQueryResult = Apollo.QueryResult<Get_C_IntFireRatingQuery, Get_C_IntFireRatingQueryVariables>;
export const Get_C_ExtFireRatingDocument = gql`
    query Get_C_ExtFireRating {
  C_ExtFireRating {
    ExtFireRatingCode
    ExtFireRatingId
  }
}
    `;

/**
 * __useGet_C_ExtFireRatingQuery__
 *
 * To run a query within a React component, call `useGet_C_ExtFireRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_C_ExtFireRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_C_ExtFireRatingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_C_ExtFireRatingQuery(baseOptions?: Apollo.QueryHookOptions<Get_C_ExtFireRatingQuery, Get_C_ExtFireRatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_C_ExtFireRatingQuery, Get_C_ExtFireRatingQueryVariables>(Get_C_ExtFireRatingDocument, options);
      }
export function useGet_C_ExtFireRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_C_ExtFireRatingQuery, Get_C_ExtFireRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_C_ExtFireRatingQuery, Get_C_ExtFireRatingQueryVariables>(Get_C_ExtFireRatingDocument, options);
        }
export type Get_C_ExtFireRatingQueryHookResult = ReturnType<typeof useGet_C_ExtFireRatingQuery>;
export type Get_C_ExtFireRatingLazyQueryHookResult = ReturnType<typeof useGet_C_ExtFireRatingLazyQuery>;
export type Get_C_ExtFireRatingQueryResult = Apollo.QueryResult<Get_C_ExtFireRatingQuery, Get_C_ExtFireRatingQueryVariables>;
export const Get_C_HailRatingDocument = gql`
    query Get_C_HailRating {
  C_HailRating {
    HailRatingCode
    HailRatingId
  }
}
    `;

/**
 * __useGet_C_HailRatingQuery__
 *
 * To run a query within a React component, call `useGet_C_HailRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_C_HailRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_C_HailRatingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_C_HailRatingQuery(baseOptions?: Apollo.QueryHookOptions<Get_C_HailRatingQuery, Get_C_HailRatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_C_HailRatingQuery, Get_C_HailRatingQueryVariables>(Get_C_HailRatingDocument, options);
      }
export function useGet_C_HailRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_C_HailRatingQuery, Get_C_HailRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_C_HailRatingQuery, Get_C_HailRatingQueryVariables>(Get_C_HailRatingDocument, options);
        }
export type Get_C_HailRatingQueryHookResult = ReturnType<typeof useGet_C_HailRatingQuery>;
export type Get_C_HailRatingLazyQueryHookResult = ReturnType<typeof useGet_C_HailRatingLazyQuery>;
export type Get_C_HailRatingQueryResult = Apollo.QueryResult<Get_C_HailRatingQuery, Get_C_HailRatingQueryVariables>;
export const GetExtendedLayerConfigDocument = gql`
    query GetExtendedLayerConfig {
  ExtendedLayerConfigSplit {
    ExtendedLayerConfigId
    CompleteConfigString
    ConfigStringGenericSecurementDuplicateId
  }
}
    `;

/**
 * __useGetExtendedLayerConfigQuery__
 *
 * To run a query within a React component, call `useGetExtendedLayerConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtendedLayerConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtendedLayerConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExtendedLayerConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetExtendedLayerConfigQuery, GetExtendedLayerConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExtendedLayerConfigQuery, GetExtendedLayerConfigQueryVariables>(GetExtendedLayerConfigDocument, options);
      }
export function useGetExtendedLayerConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExtendedLayerConfigQuery, GetExtendedLayerConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExtendedLayerConfigQuery, GetExtendedLayerConfigQueryVariables>(GetExtendedLayerConfigDocument, options);
        }
export type GetExtendedLayerConfigQueryHookResult = ReturnType<typeof useGetExtendedLayerConfigQuery>;
export type GetExtendedLayerConfigLazyQueryHookResult = ReturnType<typeof useGetExtendedLayerConfigLazyQuery>;
export type GetExtendedLayerConfigQueryResult = Apollo.QueryResult<GetExtendedLayerConfigQuery, GetExtendedLayerConfigQueryVariables>;
export const ProductApiSearchDocument = gql`
    query ProductAPISearch($productSearchCriteria: ProductSearchCriteria!) {
  product_api_search(productSearchCriteria: $productSearchCriteria) {
    ApprovedUseId
    ApprovedUseCode
    SubcategoryId
    SubcategoryName
    CategoryId
    CategoryName
    NumOfProducts
  }
}
    `;

/**
 * __useProductApiSearchQuery__
 *
 * To run a query within a React component, call `useProductApiSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductApiSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductApiSearchQuery({
 *   variables: {
 *      productSearchCriteria: // value for 'productSearchCriteria'
 *   },
 * });
 */
export function useProductApiSearchQuery(baseOptions: Apollo.QueryHookOptions<ProductApiSearchQuery, ProductApiSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductApiSearchQuery, ProductApiSearchQueryVariables>(ProductApiSearchDocument, options);
      }
export function useProductApiSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductApiSearchQuery, ProductApiSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductApiSearchQuery, ProductApiSearchQueryVariables>(ProductApiSearchDocument, options);
        }
export type ProductApiSearchQueryHookResult = ReturnType<typeof useProductApiSearchQuery>;
export type ProductApiSearchLazyQueryHookResult = ReturnType<typeof useProductApiSearchLazyQuery>;
export type ProductApiSearchQueryResult = Apollo.QueryResult<ProductApiSearchQuery, ProductApiSearchQueryVariables>;
export const ProductApiListingDocument = gql`
    query ProductAPIListing($productSearchCriteriaWithTarget: ProductSearchCriteriaWithTarget!) {
  product_api_listing(
    productSearchCriteriaWithTarget: $productSearchCriteriaWithTarget
  ) {
    ComponentId
    TradeName
    ManufacturerId
    ManufacturerName
    ApprovedUseId
    ApprovedUseCode
    CountryId
    CountryCode
  }
}
    `;

/**
 * __useProductApiListingQuery__
 *
 * To run a query within a React component, call `useProductApiListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductApiListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductApiListingQuery({
 *   variables: {
 *      productSearchCriteriaWithTarget: // value for 'productSearchCriteriaWithTarget'
 *   },
 * });
 */
export function useProductApiListingQuery(baseOptions: Apollo.QueryHookOptions<ProductApiListingQuery, ProductApiListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductApiListingQuery, ProductApiListingQueryVariables>(ProductApiListingDocument, options);
      }
export function useProductApiListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductApiListingQuery, ProductApiListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductApiListingQuery, ProductApiListingQueryVariables>(ProductApiListingDocument, options);
        }
export type ProductApiListingQueryHookResult = ReturnType<typeof useProductApiListingQuery>;
export type ProductApiListingLazyQueryHookResult = ReturnType<typeof useProductApiListingLazyQuery>;
export type ProductApiListingQueryResult = Apollo.QueryResult<ProductApiListingQuery, ProductApiListingQueryVariables>;
export const ProductApiMatchedAssemblyCountDocument = gql`
    query ProductAPIMatchedAssemblyCount($producMatchAssemblyCountCriteria: ProducMatchAssemblyCountCriteria!) {
  product_api_matchedassemblycount(
    producMatchAssemblyCountCriteria: $producMatchAssemblyCountCriteria
  ) {
    ApprovedUseId
    NumMatchedAssembly
  }
}
    `;

/**
 * __useProductApiMatchedAssemblyCountQuery__
 *
 * To run a query within a React component, call `useProductApiMatchedAssemblyCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductApiMatchedAssemblyCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductApiMatchedAssemblyCountQuery({
 *   variables: {
 *      producMatchAssemblyCountCriteria: // value for 'producMatchAssemblyCountCriteria'
 *   },
 * });
 */
export function useProductApiMatchedAssemblyCountQuery(baseOptions: Apollo.QueryHookOptions<ProductApiMatchedAssemblyCountQuery, ProductApiMatchedAssemblyCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductApiMatchedAssemblyCountQuery, ProductApiMatchedAssemblyCountQueryVariables>(ProductApiMatchedAssemblyCountDocument, options);
      }
export function useProductApiMatchedAssemblyCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductApiMatchedAssemblyCountQuery, ProductApiMatchedAssemblyCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductApiMatchedAssemblyCountQuery, ProductApiMatchedAssemblyCountQueryVariables>(ProductApiMatchedAssemblyCountDocument, options);
        }
export type ProductApiMatchedAssemblyCountQueryHookResult = ReturnType<typeof useProductApiMatchedAssemblyCountQuery>;
export type ProductApiMatchedAssemblyCountLazyQueryHookResult = ReturnType<typeof useProductApiMatchedAssemblyCountLazyQuery>;
export type ProductApiMatchedAssemblyCountQueryResult = Apollo.QueryResult<ProductApiMatchedAssemblyCountQuery, ProductApiMatchedAssemblyCountQueryVariables>;
export const ProductApiMatchedAssemblyListDocument = gql`
    query ProductAPIMatchedAssemblyList($productMatchAssemblyListCriteria: ProductMatchAssemblyListCriteria!) {
  product_api_matchedassemblylist(
    productMatchAssemblyListCriteria: $productMatchAssemblyListCriteria
  ) {
    NavAssemblyId
    NavAssemblyName
    AssemblyApplicationTypeCode
    CoverSecurementMethodCode
    AssemblyTypeCode
    DeckTypeCode
    Slope
    WindUplift
    IntFireRatingCode
    ExtFireRatingCode
    HailRatingCode
  }
}
    `;

/**
 * __useProductApiMatchedAssemblyListQuery__
 *
 * To run a query within a React component, call `useProductApiMatchedAssemblyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductApiMatchedAssemblyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductApiMatchedAssemblyListQuery({
 *   variables: {
 *      productMatchAssemblyListCriteria: // value for 'productMatchAssemblyListCriteria'
 *   },
 * });
 */
export function useProductApiMatchedAssemblyListQuery(baseOptions: Apollo.QueryHookOptions<ProductApiMatchedAssemblyListQuery, ProductApiMatchedAssemblyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductApiMatchedAssemblyListQuery, ProductApiMatchedAssemblyListQueryVariables>(ProductApiMatchedAssemblyListDocument, options);
      }
export function useProductApiMatchedAssemblyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductApiMatchedAssemblyListQuery, ProductApiMatchedAssemblyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductApiMatchedAssemblyListQuery, ProductApiMatchedAssemblyListQueryVariables>(ProductApiMatchedAssemblyListDocument, options);
        }
export type ProductApiMatchedAssemblyListQueryHookResult = ReturnType<typeof useProductApiMatchedAssemblyListQuery>;
export type ProductApiMatchedAssemblyListLazyQueryHookResult = ReturnType<typeof useProductApiMatchedAssemblyListLazyQuery>;
export type ProductApiMatchedAssemblyListQueryResult = Apollo.QueryResult<ProductApiMatchedAssemblyListQuery, ProductApiMatchedAssemblyListQueryVariables>;
export const GetProductDetailAllInfoDocument = gql`
    query GetProductDetailAllInfo($ComponentId: Int) {
  ManufacturedProduct(where: {ComponentId: {_eq: $ComponentId}}) {
    ComponentId
    TradeName
    EnteredAsMetric
    Manufacturer {
      ManufacturerId
      Name
      Address {
        C_Country {
          CountryId
          CountryCode
        }
      }
    }
    Component {
      ComponentApprovedUses {
        C_ApprovedUse {
          ApprovedUseId
          ApprovedUseCode
        }
        Comments
      }
      Comments
      ComponentDocs {
        ComponentDocId
      }
    }
    AdhesiveFullApplication {
      AdhesiveMaterialId
      C_AdhesiveMaterial {
        AdhesiveMaterialCode
      }
      AdhesiveTypeId
      C_AdhesiveType {
        AdhesiveTypeCode
      }
    }
    AdhesiveRibbon {
      AdhesiveMaterialId
      C_AdhesiveMaterial {
        AdhesiveMaterialCode
      }
      AdhesiveTypeId
      C_AdhesiveType {
        AdhesiveTypeCode
      }
      TapeWidth
    }
    AdhesiveSpot {
      AdhesiveMaterialId
      C_AdhesiveMaterial {
        AdhesiveMaterialCode
      }
      AdhesiveTypeId
      C_AdhesiveType {
        AdhesiveTypeCode
      }
    }
    AirRetarder {
      RetarderMaterialId
      C_RetarderMaterial {
        RetarderMaterialCode
      }
    }
    Anchor {
      VerticalLoadMax
      ShearLoadMax
    }
    BaseSheet {
      IsPermeable
      BackingMaterialId
      C_BackingMaterial {
        BackingMaterialCode
      }
      Width
      MultiplyMaterialId
      C_MultiplyMaterial {
        MultiplyMaterialCode
      }
      ReinforcementId
      C_Reinforcement {
        ReinforcementCode
      }
    }
    Coating {
      CoatingMaterialId
      C_CoatingMaterial {
        CoatingMaterialCode
      }
    }
    Coping {
      FlashingMaterialId
      C_FlashingMaterial {
        FlashingMaterialCode
      }
      MaxWindRating
    }
    CompositePanelCover {
      InsulationCoreId
      C_InsulationCore {
        InsulationCoreCode
      }
      WidthMin
      WidthMax
      ThicknessMin
      ThicknessMax
      TopFacerMaterialId
      C_FacerMaterial {
        FacerMaterialCode
      }
      FacerThicknessTop
      BottomFacerMaterialId
      cFacermaterialByBottomfacermaterialid {
        FacerMaterialCode
      }
      FacerThicknessBottom
    }
    SinglePlyCover {
      IsAppliedToAdheredAssemblies
      SinglePlyMaterialId
      C_SinglePlyMaterial {
        SinglePlyMaterialCode
      }
      BackingMaterialId
      C_BackingMaterial {
        BackingMaterialCode
      }
      ThicknessMin
      ThicknessMax
      ReinforcementId
      C_Reinforcement {
        ReinforcementCode
      }
      CoverColorId
      C_CoverColor {
        CoverColorCode
      }
    }
    StandingLapSeamCover {
      WidthMin
      WidthMax
      StandingLapSeamMaterialId
      C_StandingLapSeamMaterial {
        StandingLapSeamMaterialCode
      }
      NominalWeight
      ThicknessMin
      ThicknessMax
    }
    CoverBoard {
      CoverBoardMaterialId
      C_CoverBoardMaterial {
        CoverBoardMaterialCode
      }
      BoardProfileId
      C_BoardProfile {
        BoardProfileCode
      }
      CoverBoardBoardSizes {
        InsBoardSizeId
        InsBoardSize {
          Width
          Length
          IsMetric
        }
      }
      CoverBoardFacerTops {
        FacerId
        C_Facer {
          FacerCode
        }
      }
      CoverBoardFacerBottoms {
        FacerId
        C_Facer {
          FacerCode
        }
      }
      DensityMin
      ThicknessMin
      ThicknessMax
    }
    CoverStripDisk {
      SinglePlyMaterialId
      C_SinglePlyMaterial {
        SinglePlyMaterialCode
      }
    }
    BasePly {
      BackingMaterialId
      C_BackingMaterial {
        BackingMaterialCode
      }
      Width
      MultiplyMaterialId
      C_MultiplyMaterial {
        MultiplyMaterialCode
      }
      ReinforcementId
      C_Reinforcement {
        ReinforcementCode
      }
    }
    CapPly {
      BackingMaterialId
      C_BackingMaterial {
        BackingMaterialCode
      }
      Width
      MultiplyMaterialId
      C_MultiplyMaterial {
        MultiplyMaterialCode
      }
      ReinforcementId
      C_Reinforcement {
        ReinforcementCode
      }
      SurfaceTypeId
      C_SurfaceType {
        SurfaceTypeCode
      }
    }
    OtherPly {
      BackingMaterialId
      C_BackingMaterial {
        BackingMaterialCode
      }
      MultiplyMaterialId
      C_MultiplyMaterial {
        MultiplyMaterialCode
      }
      ReinforcementId
      C_Reinforcement {
        ReinforcementCode
      }
      Width
    }
    CementitiousWoodFiberDeck {
      ThicknessMin
      ThicknessMax
      SpanMax
    }
    LightweightInsulatingConcrete {
      AggregateId
      C_Aggregate {
        AggregateCode
      }
      ThicknessMin
      CompressiveStrengthMin
      DryDensityMin
      CastDensityMin
      AdditiveId
      C_Additive {
        AdditiveCode
      }
    }
    SteelDeck {
      IsAcoustical
      SteelDeckRibTypeId
      C_SteelDeckRibType {
        SteelDeckRibTypeCode
      }
      DepthMin
      DepthMax
      WidthMin
      WidthMax
      DesignThickness
      SpanMax
    }
    Fascium {
      FlashingMaterialId
      C_FlashingMaterial {
        FlashingMaterialCode
      }
      MaxWindRating
    }
    BattenBar {
      SecurementMaterialId
      C_SecurementMaterial {
        SecurementMaterialCode
      }
      ThicknessMin
      HoleSpacing
    }
    Clip {
      SecurementMaterialId
      C_SecurementMaterial {
        SecurementMaterialCode
      }
    }
    Fastener {
      SecurementMaterialId
      C_SecurementMaterial {
        SecurementMaterialCode
      }
      FastenerTypeId
      C_FastenerType {
        FastenerTypeCode
      }
      FastenerCoatingId
      C_FastenerCoating {
        FastenerCoatingCode
      }
      FastenerDriveHeadId
      C_FastenerDriveHead {
        FastenerDriveHeadCode
      }
      Diameter
      LengthMin
      LengthMax
      IsSteelDeckFastener
      StructureThicknessMin
      StructureThicknessMax
      DeckDesignThicknessMax
    }
    PreassembledFastening {
      FastenerMaterialId
      cSecurementmaterialByFastenermaterialid {
        SecurementMaterialCode
      }
      FastenerTypeId
      C_FastenerType {
        FastenerTypeCode
      }
      FastenerCoatingId
      C_FastenerCoating {
        FastenerCoatingCode
      }
      FastenerDriveHeadId
      C_FastenerDriveHead {
        FastenerDriveHeadCode
      }
      FastenerDiameter
      LengthMin
      LengthMax
      IsSteelDeckFastener
      StructureThicknessMin
      StructureThicknessMax
      DeckDesignThicknessMax
      StressDistributorTypeId
      C_StressDistributorType {
        StressDistributorTypeCode
      }
      StressDistributorMaterialId
      C_SecurementMaterial {
        SecurementMaterialCode
      }
      Thickness
      StressPlateShapeId
      C_StressPlateShape {
        StressPlateShapeCode
      }
      Diameter
      Width
      Length
      ThicknessMin
      HoleSpacing
    }
    StressPlate {
      SecurementMaterialId
      C_SecurementMaterial {
        SecurementMaterialCode
      }
      Thickness
      StressPlateShapeId
      C_StressPlateShape {
        StressPlateShapeCode
      }
      Diameter
      Width
      Length
    }
    FormDeck {
      ThicknessMin
      Depth
      IsVented
      SpanMax
      PercentVented
    }
    Gutter {
      GutterMaterialId
      C_GutterMaterial {
        GutterMaterialCode
      }
      MaxWindRating
    }
    BattBlanketInsulation {
      BattBlanketMaterialId
      C_BattBlanketMaterial {
        BattBlanketMaterialCode
      }
      BattBlanketFacerMaterialId
      C_BattBlanketFacerMaterial {
        BattBlanketFacerMaterialCode
      }
      ThicknessMin
      ThicknessMax
    }
    BoardStock {
      BoardStockMaterialId
      C_BoardStockMaterial {
        BoardStockMaterialCode
      }
      BoardProfileId
      C_BoardProfile {
        BoardProfileCode
      }
      BoardStockBoardSizes {
        InsBoardSizeId
        InsBoardSize {
          Width
          Length
          IsMetric
        }
      }
      BoardStockFacerTops {
        FacerId
        C_Facer {
          FacerCode
        }
      }
      BoardStockFacerBottoms {
        FacerId
        C_Facer {
          FacerCode
        }
      }
      DensityMin
      ThicknessMin
      ThicknessMax
    }
    SprayedFoamInsulation {
      InsulationFoamTypeId
      C_InsulationFoamType {
        InsulationFoamTypeCode
      }
      DensityMin
      DensityMax
    }
    LinerPanel {
      LinerPanelMaterialId
      C_LinerPanelMaterial {
        LinerPanelMaterialCode
      }
    }
    MaintenanceCoating {
      MaintenanceCoatingMaterialId
      C_MaintenanceCoatingMaterial {
        MaintenanceCoatingMaterialCode
      }
      MaintenanceCoatingSubstrates {
        MaintenanceCoatingSubstrateId
        C_MaintenanceCoatingSubstrate {
          MaintenanceCoatingSubstrateCode
        }
      }
    }
    SeparatorSheet {
      RetarderMaterialId
      C_RetarderMaterial {
        RetarderMaterialCode
      }
    }
    SubstratePrimer {
      SubstratePrimerMaterialId
      C_SubstratePrimerMaterial {
        SubstratePrimerMaterialCode
      }
    }
    Surfacing {
      SurfacingMaterialId
      C_SurfacingMaterial {
        SurfacingMaterialCode
      }
    }
    ThermalBarrier {
      ThermalBarrierMaterialId
      C_ThermalBarrierMaterial {
        ThermalBarrierMaterialCode
      }
      BoardProfileId
      C_BoardProfile {
        BoardProfileCode
      }
      ThermalBarrierBoardSizes {
        InsBoardSizeId
        InsBoardSize {
          Width
          Length
          IsMetric
        }
      }
      ThermalBarrierFacerTops {
        FacerId
        C_Facer {
          FacerCode
        }
      }
      ThermalBarrierFacerBottoms {
        FacerId
        C_Facer {
          FacerCode
        }
      }
      DensityMin
      ThicknessMin
      ThicknessMax
    }
    VaporRetarder {
      RetarderMaterialId
      C_RetarderMaterial {
        RetarderMaterialCode
      }
    }
    Coat {
      LiquidApplicationId
      C_LiquidApplication {
        LiquidApplicationCode
      }
      LiquidAppliedMaterialId
      C_LiquidAppliedMaterial {
        LiquidAppliedMaterialCode
      }
      CoverColorId
      C_CoverColor {
        CoverColorCode
      }
    }
    TopCoat {
      LiquidApplicationId
      C_LiquidApplication {
        LiquidApplicationCode
      }
      LiquidAppliedMaterialId
      C_LiquidAppliedMaterial {
        LiquidAppliedMaterialCode
      }
      CoverColorId
      C_CoverColor {
        CoverColorCode
      }
    }
    BaseCoat {
      LiquidApplicationId
      C_LiquidApplication {
        LiquidApplicationCode
      }
      LiquidAppliedMaterialId
      C_LiquidAppliedMaterial {
        LiquidAppliedMaterialCode
      }
      CoverColorId
      C_CoverColor {
        CoverColorCode
      }
    }
    Shingle {
      Length
      Width
      NominalWeight
      ShinglesMaterialId
      C_ShinglesMaterial {
        ShinglesMaterialCode
      }
      ShinglesReinforcementId
      C_ShinglesReinforcement {
        ShinglesReinforcementCode
      }
      ShinglesSurfacingId
      C_ShinglesSurfacing {
        ShinglesSurfacingCode
      }
      ShinglesHailId
      C_ShinglesHail {
        ShinglesHailCode
      }
      ShinglesWindSpeedId
      C_ShinglesWindSpeed {
        ShinglesWindSpeedCode
      }
      WindUplift
    }
  }
}
    `;

/**
 * __useGetProductDetailAllInfoQuery__
 *
 * To run a query within a React component, call `useGetProductDetailAllInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductDetailAllInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductDetailAllInfoQuery({
 *   variables: {
 *      ComponentId: // value for 'ComponentId'
 *   },
 * });
 */
export function useGetProductDetailAllInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetProductDetailAllInfoQuery, GetProductDetailAllInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductDetailAllInfoQuery, GetProductDetailAllInfoQueryVariables>(GetProductDetailAllInfoDocument, options);
      }
export function useGetProductDetailAllInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductDetailAllInfoQuery, GetProductDetailAllInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductDetailAllInfoQuery, GetProductDetailAllInfoQueryVariables>(GetProductDetailAllInfoDocument, options);
        }
export type GetProductDetailAllInfoQueryHookResult = ReturnType<typeof useGetProductDetailAllInfoQuery>;
export type GetProductDetailAllInfoLazyQueryHookResult = ReturnType<typeof useGetProductDetailAllInfoLazyQuery>;
export type GetProductDetailAllInfoQueryResult = Apollo.QueryResult<GetProductDetailAllInfoQuery, GetProductDetailAllInfoQueryVariables>;
export const GetComponentsDocument = gql`
    query GetComponents {
  Component(limit: 3) {
    ComponentId
    C_ComponentType {
      ComponentTypeCode
    }
    C_Status {
      StatusCode
      Manufacturers(limit: 2) {
        Name
      }
    }
  }
}
    `;

/**
 * __useGetComponentsQuery__
 *
 * To run a query within a React component, call `useGetComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComponentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetComponentsQuery(baseOptions?: Apollo.QueryHookOptions<GetComponentsQuery, GetComponentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComponentsQuery, GetComponentsQueryVariables>(GetComponentsDocument, options);
      }
export function useGetComponentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComponentsQuery, GetComponentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComponentsQuery, GetComponentsQueryVariables>(GetComponentsDocument, options);
        }
export type GetComponentsQueryHookResult = ReturnType<typeof useGetComponentsQuery>;
export type GetComponentsLazyQueryHookResult = ReturnType<typeof useGetComponentsLazyQuery>;
export type GetComponentsQueryResult = Apollo.QueryResult<GetComponentsQuery, GetComponentsQueryVariables>;
export const GetAssemblyNumberInfoDocument = gql`
    query GetAssemblyNumberInfo($navAssemblyName: String) {
  NavAssembly(where: {NavAssemblyName: {_like: $navAssemblyName}}, limit: 10) {
    NavAssemblyId
    NavAssemblyName
    AssemblyId
    RoofAssembly {
      AssemblyTypeId
      C_AssemblyType {
        AssemblyTypeCode
      }
      IntFireRatingId
      C_IntFireRating {
        IntFireRatingCode
      }
      ExtFireRatingId
      C_ExtFireRating {
        ExtFireRatingCode
      }
      AssemblyApplicationTypeId
      C_AssemblyApplicationType {
        AssemblyApplicationTypeCode
      }
      HailRatingId
      C_HailRating {
        HailRatingCode
      }
      CoverSecurementMethodId
      C_CoverSecurementMethod {
        CoverSecurementMethodCode
      }
      DeckTypeId
      C_DeckType {
        DeckTypeCode
      }
      Slope
      WindUplift
    }
  }
}
    `;

/**
 * __useGetAssemblyNumberInfoQuery__
 *
 * To run a query within a React component, call `useGetAssemblyNumberInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssemblyNumberInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssemblyNumberInfoQuery({
 *   variables: {
 *      navAssemblyName: // value for 'navAssemblyName'
 *   },
 * });
 */
export function useGetAssemblyNumberInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetAssemblyNumberInfoQuery, GetAssemblyNumberInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssemblyNumberInfoQuery, GetAssemblyNumberInfoQueryVariables>(GetAssemblyNumberInfoDocument, options);
      }
export function useGetAssemblyNumberInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssemblyNumberInfoQuery, GetAssemblyNumberInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssemblyNumberInfoQuery, GetAssemblyNumberInfoQueryVariables>(GetAssemblyNumberInfoDocument, options);
        }
export type GetAssemblyNumberInfoQueryHookResult = ReturnType<typeof useGetAssemblyNumberInfoQuery>;
export type GetAssemblyNumberInfoLazyQueryHookResult = ReturnType<typeof useGetAssemblyNumberInfoLazyQuery>;
export type GetAssemblyNumberInfoQueryResult = Apollo.QueryResult<GetAssemblyNumberInfoQuery, GetAssemblyNumberInfoQueryVariables>;
export const GetNavAssemblyDetailByIdDocument = gql`
    query GetNavAssemblyDetailById($NavAssemblyId: Int) {
  NavAssembly(where: {NavAssemblyId: {_eq: $NavAssemblyId}}) {
    NavAssemblyId
    NavAssemblyName
    AssemblyId
    RoofAssembly {
      AssemblyTypeId
      C_AssemblyType {
        AssemblyTypeCode
      }
      IntFireRatingId
      C_IntFireRating {
        IntFireRatingCode
      }
      ExtFireRatingId
      C_ExtFireRating {
        ExtFireRatingCode
      }
      AssemblyApplicationTypeId
      C_AssemblyApplicationType {
        AssemblyApplicationTypeCode
      }
      HailRatingId
      C_HailRating {
        HailRatingCode
      }
      CoverSecurementMethodId
      C_CoverSecurementMethod {
        CoverSecurementMethodCode
      }
      DeckTypeId
      C_DeckType {
        DeckTypeCode
      }
      Slope
      WindUplift
      IsUsedWithCombustibleWalls
    }
    NavLayers(order_by: {NavLayerNum: asc}) {
      NavLayerId
      NavLayerNum
      LayerId
      LayerTypeId
      C_LayerType {
        LayerTypeCode
      }
    }
  }
}
    `;

/**
 * __useGetNavAssemblyDetailByIdQuery__
 *
 * To run a query within a React component, call `useGetNavAssemblyDetailByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavAssemblyDetailByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavAssemblyDetailByIdQuery({
 *   variables: {
 *      NavAssemblyId: // value for 'NavAssemblyId'
 *   },
 * });
 */
export function useGetNavAssemblyDetailByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetNavAssemblyDetailByIdQuery, GetNavAssemblyDetailByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNavAssemblyDetailByIdQuery, GetNavAssemblyDetailByIdQueryVariables>(GetNavAssemblyDetailByIdDocument, options);
      }
export function useGetNavAssemblyDetailByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNavAssemblyDetailByIdQuery, GetNavAssemblyDetailByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNavAssemblyDetailByIdQuery, GetNavAssemblyDetailByIdQueryVariables>(GetNavAssemblyDetailByIdDocument, options);
        }
export type GetNavAssemblyDetailByIdQueryHookResult = ReturnType<typeof useGetNavAssemblyDetailByIdQuery>;
export type GetNavAssemblyDetailByIdLazyQueryHookResult = ReturnType<typeof useGetNavAssemblyDetailByIdLazyQuery>;
export type GetNavAssemblyDetailByIdQueryResult = Apollo.QueryResult<GetNavAssemblyDetailByIdQuery, GetNavAssemblyDetailByIdQueryVariables>;
export const SaveRoofAreaBaseOnlyDocument = gql`
    query SaveRoofAreaBaseOnly($saveRoofAreaBaseInfo: SaveRoofAreaBaseInfo!) {
  roofarea_api_saveroofareabase(saveRoofAreaBaseInfo: $saveRoofAreaBaseInfo) {
    RoofAreaId
  }
}
    `;

/**
 * __useSaveRoofAreaBaseOnlyQuery__
 *
 * To run a query within a React component, call `useSaveRoofAreaBaseOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaveRoofAreaBaseOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaveRoofAreaBaseOnlyQuery({
 *   variables: {
 *      saveRoofAreaBaseInfo: // value for 'saveRoofAreaBaseInfo'
 *   },
 * });
 */
export function useSaveRoofAreaBaseOnlyQuery(baseOptions: Apollo.QueryHookOptions<SaveRoofAreaBaseOnlyQuery, SaveRoofAreaBaseOnlyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SaveRoofAreaBaseOnlyQuery, SaveRoofAreaBaseOnlyQueryVariables>(SaveRoofAreaBaseOnlyDocument, options);
      }
export function useSaveRoofAreaBaseOnlyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SaveRoofAreaBaseOnlyQuery, SaveRoofAreaBaseOnlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SaveRoofAreaBaseOnlyQuery, SaveRoofAreaBaseOnlyQueryVariables>(SaveRoofAreaBaseOnlyDocument, options);
        }
export type SaveRoofAreaBaseOnlyQueryHookResult = ReturnType<typeof useSaveRoofAreaBaseOnlyQuery>;
export type SaveRoofAreaBaseOnlyLazyQueryHookResult = ReturnType<typeof useSaveRoofAreaBaseOnlyLazyQuery>;
export type SaveRoofAreaBaseOnlyQueryResult = Apollo.QueryResult<SaveRoofAreaBaseOnlyQuery, SaveRoofAreaBaseOnlyQueryVariables>;
export const SaveRoofAreaIncludeRatingDocument = gql`
    query SaveRoofAreaIncludeRating($saveRoofAreaWithRating: SaveRoofAreaWithRating!) {
  roofarea_api_saveroofareawithrating(
    saveRoofAreaWithRating: $saveRoofAreaWithRating
  ) {
    RoofAreaId
  }
}
    `;

/**
 * __useSaveRoofAreaIncludeRatingQuery__
 *
 * To run a query within a React component, call `useSaveRoofAreaIncludeRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaveRoofAreaIncludeRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaveRoofAreaIncludeRatingQuery({
 *   variables: {
 *      saveRoofAreaWithRating: // value for 'saveRoofAreaWithRating'
 *   },
 * });
 */
export function useSaveRoofAreaIncludeRatingQuery(baseOptions: Apollo.QueryHookOptions<SaveRoofAreaIncludeRatingQuery, SaveRoofAreaIncludeRatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SaveRoofAreaIncludeRatingQuery, SaveRoofAreaIncludeRatingQueryVariables>(SaveRoofAreaIncludeRatingDocument, options);
      }
export function useSaveRoofAreaIncludeRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SaveRoofAreaIncludeRatingQuery, SaveRoofAreaIncludeRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SaveRoofAreaIncludeRatingQuery, SaveRoofAreaIncludeRatingQueryVariables>(SaveRoofAreaIncludeRatingDocument, options);
        }
export type SaveRoofAreaIncludeRatingQueryHookResult = ReturnType<typeof useSaveRoofAreaIncludeRatingQuery>;
export type SaveRoofAreaIncludeRatingLazyQueryHookResult = ReturnType<typeof useSaveRoofAreaIncludeRatingLazyQuery>;
export type SaveRoofAreaIncludeRatingQueryResult = Apollo.QueryResult<SaveRoofAreaIncludeRatingQuery, SaveRoofAreaIncludeRatingQueryVariables>;
export const GetRoofAreaInfoByIdDocument = gql`
    query GetRoofAreaInfoById($RoofAreaId: Int) {
  RoofArea(where: {RoofAreaId: {_eq: $RoofAreaId}}) {
    RoofAreaId
    IntFireRatingId
    HailRatingId
    GroundRoughnessRatingId
    ExtFireRatingId
    Name
    Description
    Width
    Length
    Height
    BuildingName
    BuildingEnclosureId
    WindBorneDebrisId
    Slope
    WindSpeed
    ContinuousParapet
    CalculatorVersion
    CalculationLastUpdatedDate
    TropicalCycloneRegion
    RoofAreaWindPressures {
      RoofAreaWindPressureId
      PressureZoneId
      WindUplift
      CopingRating
      FasciaRating
    }
    RoofProject {
      ProjectId
      Name
      Description
      Notes
    }
  }
}
    `;

/**
 * __useGetRoofAreaInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetRoofAreaInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoofAreaInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoofAreaInfoByIdQuery({
 *   variables: {
 *      RoofAreaId: // value for 'RoofAreaId'
 *   },
 * });
 */
export function useGetRoofAreaInfoByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetRoofAreaInfoByIdQuery, GetRoofAreaInfoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoofAreaInfoByIdQuery, GetRoofAreaInfoByIdQueryVariables>(GetRoofAreaInfoByIdDocument, options);
      }
export function useGetRoofAreaInfoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoofAreaInfoByIdQuery, GetRoofAreaInfoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoofAreaInfoByIdQuery, GetRoofAreaInfoByIdQueryVariables>(GetRoofAreaInfoByIdDocument, options);
        }
export type GetRoofAreaInfoByIdQueryHookResult = ReturnType<typeof useGetRoofAreaInfoByIdQuery>;
export type GetRoofAreaInfoByIdLazyQueryHookResult = ReturnType<typeof useGetRoofAreaInfoByIdLazyQuery>;
export type GetRoofAreaInfoByIdQueryResult = Apollo.QueryResult<GetRoofAreaInfoByIdQuery, GetRoofAreaInfoByIdQueryVariables>;
export const GetRoofAreasByProjectIdDocument = gql`
    query GetRoofAreasByProjectId($ProjectId: Int, $RandomId: String) {
  RoofArea(
    where: {_and: [{ProjectId: {_eq: $ProjectId}}, {Name: {_neq: $RandomId}}]}
  ) {
    RoofAreaId
    Name
  }
}
    `;

/**
 * __useGetRoofAreasByProjectIdQuery__
 *
 * To run a query within a React component, call `useGetRoofAreasByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoofAreasByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoofAreasByProjectIdQuery({
 *   variables: {
 *      ProjectId: // value for 'ProjectId'
 *      RandomId: // value for 'RandomId'
 *   },
 * });
 */
export function useGetRoofAreasByProjectIdQuery(baseOptions?: Apollo.QueryHookOptions<GetRoofAreasByProjectIdQuery, GetRoofAreasByProjectIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoofAreasByProjectIdQuery, GetRoofAreasByProjectIdQueryVariables>(GetRoofAreasByProjectIdDocument, options);
      }
export function useGetRoofAreasByProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoofAreasByProjectIdQuery, GetRoofAreasByProjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoofAreasByProjectIdQuery, GetRoofAreasByProjectIdQueryVariables>(GetRoofAreasByProjectIdDocument, options);
        }
export type GetRoofAreasByProjectIdQueryHookResult = ReturnType<typeof useGetRoofAreasByProjectIdQuery>;
export type GetRoofAreasByProjectIdLazyQueryHookResult = ReturnType<typeof useGetRoofAreasByProjectIdLazyQuery>;
export type GetRoofAreasByProjectIdQueryResult = Apollo.QueryResult<GetRoofAreasByProjectIdQuery, GetRoofAreasByProjectIdQueryVariables>;
export const GetRoofAreaReportInfoByIdDocument = gql`
    query GetRoofAreaReportInfoById($RoofAreaId: Int) {
  RoofArea(where: {RoofAreaId: {_eq: $RoofAreaId}}) {
    RoofAreaId
    IntFireRatingId
    HailRatingId
    GroundRoughnessRatingId
    ExtFireRatingId
    Name
    Description
    Width
    Length
    Height
    BuildingName
    BuildingEnclosureId
    WindBorneDebrisId
    Slope
    WindSpeed
    ContinuousParapet
    CalculatorVersion
    CalculationLastUpdatedDate
    TropicalCycloneRegion
    RoofAreaWindPressures {
      RoofAreaWindPressureId
      PressureZoneId
      WindUplift
      CopingRating
      FasciaRating
    }
    RoofProject {
      ProjectId
      Name
      Description
      Notes
    }
    R_RoofAreaContacts {
      ProjectRoleId
      ContactInfo {
        ContactInfoId
        FirstName
        LastName
        Initial
        CompanyName
        Phone
        Fax
        Email
        Address {
          Line1
          Line2
          City
          PostalCode
          C_StateProvince {
            StateProvinceCode
          }
          C_Country {
            CountryCode
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetRoofAreaReportInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetRoofAreaReportInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoofAreaReportInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoofAreaReportInfoByIdQuery({
 *   variables: {
 *      RoofAreaId: // value for 'RoofAreaId'
 *   },
 * });
 */
export function useGetRoofAreaReportInfoByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetRoofAreaReportInfoByIdQuery, GetRoofAreaReportInfoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoofAreaReportInfoByIdQuery, GetRoofAreaReportInfoByIdQueryVariables>(GetRoofAreaReportInfoByIdDocument, options);
      }
export function useGetRoofAreaReportInfoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoofAreaReportInfoByIdQuery, GetRoofAreaReportInfoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoofAreaReportInfoByIdQuery, GetRoofAreaReportInfoByIdQueryVariables>(GetRoofAreaReportInfoByIdDocument, options);
        }
export type GetRoofAreaReportInfoByIdQueryHookResult = ReturnType<typeof useGetRoofAreaReportInfoByIdQuery>;
export type GetRoofAreaReportInfoByIdLazyQueryHookResult = ReturnType<typeof useGetRoofAreaReportInfoByIdLazyQuery>;
export type GetRoofAreaReportInfoByIdQueryResult = Apollo.QueryResult<GetRoofAreaReportInfoByIdQuery, GetRoofAreaReportInfoByIdQueryVariables>;
export const GetRoofProjectBaseInfoByIdDocument = gql`
    query GetRoofProjectBaseInfoById($ProjectId: Int, $RandomId: String) {
  RoofProject(
    where: {_and: [{ProjectId: {_eq: $ProjectId}}, {AzureB2CUserGuid: {_neq: $RandomId}}]}
  ) {
    ProjectId
    UserAccountId
    Name
    Description
    IndexNumber
    Notes
    Address {
      AddressId
      Line1
      Line2
      City
      PostalCode
      StateId
      C_StateProvince {
        StateProvinceCode
      }
      CountryId
      C_Country {
        CountryCode
      }
    }
  }
}
    `;

/**
 * __useGetRoofProjectBaseInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetRoofProjectBaseInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoofProjectBaseInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoofProjectBaseInfoByIdQuery({
 *   variables: {
 *      ProjectId: // value for 'ProjectId'
 *      RandomId: // value for 'RandomId'
 *   },
 * });
 */
export function useGetRoofProjectBaseInfoByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetRoofProjectBaseInfoByIdQuery, GetRoofProjectBaseInfoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoofProjectBaseInfoByIdQuery, GetRoofProjectBaseInfoByIdQueryVariables>(GetRoofProjectBaseInfoByIdDocument, options);
      }
export function useGetRoofProjectBaseInfoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoofProjectBaseInfoByIdQuery, GetRoofProjectBaseInfoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoofProjectBaseInfoByIdQuery, GetRoofProjectBaseInfoByIdQueryVariables>(GetRoofProjectBaseInfoByIdDocument, options);
        }
export type GetRoofProjectBaseInfoByIdQueryHookResult = ReturnType<typeof useGetRoofProjectBaseInfoByIdQuery>;
export type GetRoofProjectBaseInfoByIdLazyQueryHookResult = ReturnType<typeof useGetRoofProjectBaseInfoByIdLazyQuery>;
export type GetRoofProjectBaseInfoByIdQueryResult = Apollo.QueryResult<GetRoofProjectBaseInfoByIdQuery, GetRoofProjectBaseInfoByIdQueryVariables>;
export const GetRoofProjectCompleteInfoByIdDocument = gql`
    query GetRoofProjectCompleteInfoById($ProjectId: Int) {
  RoofProject(where: {ProjectId: {_eq: $ProjectId}}) {
    ProjectId
    UserAccountId
    Name
    Description
    IndexNumber
    Notes
    Address {
      AddressId
      Line1
      Line2
      City
      PostalCode
      StateId
      C_StateProvince {
        StateProvinceCode
      }
      CountryId
      C_Country {
        CountryCode
      }
    }
    R_ProjectNotes {
      Note {
        NoteId
        Title
      }
    }
    RoofAreas {
      RoofAreaId
      Name
      Description
      R_RoofAreaNotes {
        Note {
          NoteId
          Title
        }
      }
      R_RoofAreaScenarios {
        Scenario {
          ScenarioId
          Name
          ScenarioStatusId
          R_ScenarioNotes {
            Note {
              NoteId
              Title
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetRoofProjectCompleteInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetRoofProjectCompleteInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoofProjectCompleteInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoofProjectCompleteInfoByIdQuery({
 *   variables: {
 *      ProjectId: // value for 'ProjectId'
 *   },
 * });
 */
export function useGetRoofProjectCompleteInfoByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetRoofProjectCompleteInfoByIdQuery, GetRoofProjectCompleteInfoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoofProjectCompleteInfoByIdQuery, GetRoofProjectCompleteInfoByIdQueryVariables>(GetRoofProjectCompleteInfoByIdDocument, options);
      }
export function useGetRoofProjectCompleteInfoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoofProjectCompleteInfoByIdQuery, GetRoofProjectCompleteInfoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoofProjectCompleteInfoByIdQuery, GetRoofProjectCompleteInfoByIdQueryVariables>(GetRoofProjectCompleteInfoByIdDocument, options);
        }
export type GetRoofProjectCompleteInfoByIdQueryHookResult = ReturnType<typeof useGetRoofProjectCompleteInfoByIdQuery>;
export type GetRoofProjectCompleteInfoByIdLazyQueryHookResult = ReturnType<typeof useGetRoofProjectCompleteInfoByIdLazyQuery>;
export type GetRoofProjectCompleteInfoByIdQueryResult = Apollo.QueryResult<GetRoofProjectCompleteInfoByIdQuery, GetRoofProjectCompleteInfoByIdQueryVariables>;
export const GetRoofProjectsByUserIdDocument = gql`
    query GetRoofProjectsByUserId($UserAccountId: Int) {
  RoofProject(where: {UserAccountId: {_eq: $UserAccountId}}) {
    ProjectId
    UserAccountId
    Name
    Description
    IndexNumber
    Notes
    Address {
      AddressId
      Line1
      Line2
      City
      PostalCode
      StateId
      C_StateProvince {
        StateProvinceCode
      }
      CountryId
      C_Country {
        CountryCode
      }
    }
  }
}
    `;

/**
 * __useGetRoofProjectsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetRoofProjectsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoofProjectsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoofProjectsByUserIdQuery({
 *   variables: {
 *      UserAccountId: // value for 'UserAccountId'
 *   },
 * });
 */
export function useGetRoofProjectsByUserIdQuery(baseOptions?: Apollo.QueryHookOptions<GetRoofProjectsByUserIdQuery, GetRoofProjectsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoofProjectsByUserIdQuery, GetRoofProjectsByUserIdQueryVariables>(GetRoofProjectsByUserIdDocument, options);
      }
export function useGetRoofProjectsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoofProjectsByUserIdQuery, GetRoofProjectsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoofProjectsByUserIdQuery, GetRoofProjectsByUserIdQueryVariables>(GetRoofProjectsByUserIdDocument, options);
        }
export type GetRoofProjectsByUserIdQueryHookResult = ReturnType<typeof useGetRoofProjectsByUserIdQuery>;
export type GetRoofProjectsByUserIdLazyQueryHookResult = ReturnType<typeof useGetRoofProjectsByUserIdLazyQuery>;
export type GetRoofProjectsByUserIdQueryResult = Apollo.QueryResult<GetRoofProjectsByUserIdQuery, GetRoofProjectsByUserIdQueryVariables>;
export const GetRoofProjectsByEmailDocument = gql`
    query GetRoofProjectsByEmail($UserEmail: String, $RandomId: String) {
  RoofProject(
    where: {_and: [{AzureB2CUserEmail: {_eq: $UserEmail}}, {AzureB2CUserGuid: {_neq: $RandomId}}]}
  ) {
    ProjectId
    UserAccountId
    Name
    Description
    IndexNumber
    Notes
    Address {
      AddressId
      Line1
      Line2
      City
      PostalCode
      StateId
      C_StateProvince {
        StateProvinceCode
      }
      CountryId
      C_Country {
        CountryCode
      }
    }
  }
}
    `;

/**
 * __useGetRoofProjectsByEmailQuery__
 *
 * To run a query within a React component, call `useGetRoofProjectsByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoofProjectsByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoofProjectsByEmailQuery({
 *   variables: {
 *      UserEmail: // value for 'UserEmail'
 *      RandomId: // value for 'RandomId'
 *   },
 * });
 */
export function useGetRoofProjectsByEmailQuery(baseOptions?: Apollo.QueryHookOptions<GetRoofProjectsByEmailQuery, GetRoofProjectsByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoofProjectsByEmailQuery, GetRoofProjectsByEmailQueryVariables>(GetRoofProjectsByEmailDocument, options);
      }
export function useGetRoofProjectsByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoofProjectsByEmailQuery, GetRoofProjectsByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoofProjectsByEmailQuery, GetRoofProjectsByEmailQueryVariables>(GetRoofProjectsByEmailDocument, options);
        }
export type GetRoofProjectsByEmailQueryHookResult = ReturnType<typeof useGetRoofProjectsByEmailQuery>;
export type GetRoofProjectsByEmailLazyQueryHookResult = ReturnType<typeof useGetRoofProjectsByEmailLazyQuery>;
export type GetRoofProjectsByEmailQueryResult = Apollo.QueryResult<GetRoofProjectsByEmailQuery, GetRoofProjectsByEmailQueryVariables>;
export const AddNewAddressDocument = gql`
    mutation AddNewAddress($CountryId: Int, $StateId: Int, $Line1: String, $Line2: String, $Line3: String, $City: String, $PostalCode: String) {
  insert_Address(
    objects: {CountryId: $CountryId, StateId: $StateId, Line1: $Line1, Line2: $Line2, Line3: $Line3, City: $City, PostalCode: $PostalCode}
  ) {
    returning {
      AddressId
    }
  }
}
    `;
export type AddNewAddressMutationFn = Apollo.MutationFunction<AddNewAddressMutation, AddNewAddressMutationVariables>;

/**
 * __useAddNewAddressMutation__
 *
 * To run a mutation, you first call `useAddNewAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewAddressMutation, { data, loading, error }] = useAddNewAddressMutation({
 *   variables: {
 *      CountryId: // value for 'CountryId'
 *      StateId: // value for 'StateId'
 *      Line1: // value for 'Line1'
 *      Line2: // value for 'Line2'
 *      Line3: // value for 'Line3'
 *      City: // value for 'City'
 *      PostalCode: // value for 'PostalCode'
 *   },
 * });
 */
export function useAddNewAddressMutation(baseOptions?: Apollo.MutationHookOptions<AddNewAddressMutation, AddNewAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewAddressMutation, AddNewAddressMutationVariables>(AddNewAddressDocument, options);
      }
export type AddNewAddressMutationHookResult = ReturnType<typeof useAddNewAddressMutation>;
export type AddNewAddressMutationResult = Apollo.MutationResult<AddNewAddressMutation>;
export type AddNewAddressMutationOptions = Apollo.BaseMutationOptions<AddNewAddressMutation, AddNewAddressMutationVariables>;
export const AddNewRoofProjectReturnProjectIdDocument = gql`
    mutation AddNewRoofProjectReturnProjectId($Name: String, $Description: String, $IndexNumber: String, $UserAccountId: Int, $AddressId: Int) {
  insert_RoofProject(
    objects: {Name: $Name, Description: $Description, IndexNumber: $IndexNumber, UserAccountId: $UserAccountId, AddressId: $AddressId}
  ) {
    returning {
      ProjectId
    }
  }
}
    `;
export type AddNewRoofProjectReturnProjectIdMutationFn = Apollo.MutationFunction<AddNewRoofProjectReturnProjectIdMutation, AddNewRoofProjectReturnProjectIdMutationVariables>;

/**
 * __useAddNewRoofProjectReturnProjectIdMutation__
 *
 * To run a mutation, you first call `useAddNewRoofProjectReturnProjectIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewRoofProjectReturnProjectIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewRoofProjectReturnProjectIdMutation, { data, loading, error }] = useAddNewRoofProjectReturnProjectIdMutation({
 *   variables: {
 *      Name: // value for 'Name'
 *      Description: // value for 'Description'
 *      IndexNumber: // value for 'IndexNumber'
 *      UserAccountId: // value for 'UserAccountId'
 *      AddressId: // value for 'AddressId'
 *   },
 * });
 */
export function useAddNewRoofProjectReturnProjectIdMutation(baseOptions?: Apollo.MutationHookOptions<AddNewRoofProjectReturnProjectIdMutation, AddNewRoofProjectReturnProjectIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewRoofProjectReturnProjectIdMutation, AddNewRoofProjectReturnProjectIdMutationVariables>(AddNewRoofProjectReturnProjectIdDocument, options);
      }
export type AddNewRoofProjectReturnProjectIdMutationHookResult = ReturnType<typeof useAddNewRoofProjectReturnProjectIdMutation>;
export type AddNewRoofProjectReturnProjectIdMutationResult = Apollo.MutationResult<AddNewRoofProjectReturnProjectIdMutation>;
export type AddNewRoofProjectReturnProjectIdMutationOptions = Apollo.BaseMutationOptions<AddNewRoofProjectReturnProjectIdMutation, AddNewRoofProjectReturnProjectIdMutationVariables>;
export const AddNewRoofProjectReturnProjectInfoDocument = gql`
    mutation AddNewRoofProjectReturnProjectInfo($Name: String, $Description: String, $IndexNumber: String, $UserAccountId: Int, $AddressId: Int) {
  insert_RoofProject(
    objects: {Name: $Name, Description: $Description, IndexNumber: $IndexNumber, UserAccountId: $UserAccountId, AddressId: $AddressId}
  ) {
    returning {
      ProjectId
      Address {
        AddressId
        Line1
        Line2
        City
        PostalCode
        StateId
        C_StateProvince {
          StateProvinceCode
        }
        CountryId
        C_Country {
          CountryCode
        }
      }
    }
  }
}
    `;
export type AddNewRoofProjectReturnProjectInfoMutationFn = Apollo.MutationFunction<AddNewRoofProjectReturnProjectInfoMutation, AddNewRoofProjectReturnProjectInfoMutationVariables>;

/**
 * __useAddNewRoofProjectReturnProjectInfoMutation__
 *
 * To run a mutation, you first call `useAddNewRoofProjectReturnProjectInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewRoofProjectReturnProjectInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewRoofProjectReturnProjectInfoMutation, { data, loading, error }] = useAddNewRoofProjectReturnProjectInfoMutation({
 *   variables: {
 *      Name: // value for 'Name'
 *      Description: // value for 'Description'
 *      IndexNumber: // value for 'IndexNumber'
 *      UserAccountId: // value for 'UserAccountId'
 *      AddressId: // value for 'AddressId'
 *   },
 * });
 */
export function useAddNewRoofProjectReturnProjectInfoMutation(baseOptions?: Apollo.MutationHookOptions<AddNewRoofProjectReturnProjectInfoMutation, AddNewRoofProjectReturnProjectInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewRoofProjectReturnProjectInfoMutation, AddNewRoofProjectReturnProjectInfoMutationVariables>(AddNewRoofProjectReturnProjectInfoDocument, options);
      }
export type AddNewRoofProjectReturnProjectInfoMutationHookResult = ReturnType<typeof useAddNewRoofProjectReturnProjectInfoMutation>;
export type AddNewRoofProjectReturnProjectInfoMutationResult = Apollo.MutationResult<AddNewRoofProjectReturnProjectInfoMutation>;
export type AddNewRoofProjectReturnProjectInfoMutationOptions = Apollo.BaseMutationOptions<AddNewRoofProjectReturnProjectInfoMutation, AddNewRoofProjectReturnProjectInfoMutationVariables>;
export const AddNewNoteDocument = gql`
    mutation AddNewNote($Title: String, $Body: String) {
  insert_Note(objects: {Title: $Title, Body: $Body}) {
    returning {
      NoteId
    }
  }
}
    `;
export type AddNewNoteMutationFn = Apollo.MutationFunction<AddNewNoteMutation, AddNewNoteMutationVariables>;

/**
 * __useAddNewNoteMutation__
 *
 * To run a mutation, you first call `useAddNewNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewNoteMutation, { data, loading, error }] = useAddNewNoteMutation({
 *   variables: {
 *      Title: // value for 'Title'
 *      Body: // value for 'Body'
 *   },
 * });
 */
export function useAddNewNoteMutation(baseOptions?: Apollo.MutationHookOptions<AddNewNoteMutation, AddNewNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewNoteMutation, AddNewNoteMutationVariables>(AddNewNoteDocument, options);
      }
export type AddNewNoteMutationHookResult = ReturnType<typeof useAddNewNoteMutation>;
export type AddNewNoteMutationResult = Apollo.MutationResult<AddNewNoteMutation>;
export type AddNewNoteMutationOptions = Apollo.BaseMutationOptions<AddNewNoteMutation, AddNewNoteMutationVariables>;
export const AddProjectNoteRelationDocument = gql`
    mutation AddProjectNoteRelation($NoteId: Int, $ProjectId: Int) {
  insert_R_ProjectNote(objects: {NoteId: $NoteId, ProjectId: $ProjectId}) {
    returning {
      NoteId
      ProjectId
    }
  }
}
    `;
export type AddProjectNoteRelationMutationFn = Apollo.MutationFunction<AddProjectNoteRelationMutation, AddProjectNoteRelationMutationVariables>;

/**
 * __useAddProjectNoteRelationMutation__
 *
 * To run a mutation, you first call `useAddProjectNoteRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectNoteRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectNoteRelationMutation, { data, loading, error }] = useAddProjectNoteRelationMutation({
 *   variables: {
 *      NoteId: // value for 'NoteId'
 *      ProjectId: // value for 'ProjectId'
 *   },
 * });
 */
export function useAddProjectNoteRelationMutation(baseOptions?: Apollo.MutationHookOptions<AddProjectNoteRelationMutation, AddProjectNoteRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProjectNoteRelationMutation, AddProjectNoteRelationMutationVariables>(AddProjectNoteRelationDocument, options);
      }
export type AddProjectNoteRelationMutationHookResult = ReturnType<typeof useAddProjectNoteRelationMutation>;
export type AddProjectNoteRelationMutationResult = Apollo.MutationResult<AddProjectNoteRelationMutation>;
export type AddProjectNoteRelationMutationOptions = Apollo.BaseMutationOptions<AddProjectNoteRelationMutation, AddProjectNoteRelationMutationVariables>;
export const DeleteProjectNoteDocument = gql`
    mutation DeleteProjectNote($NoteId: Int, $ProjectId: Int) {
  delete_R_ProjectNote(
    where: {NoteId: {_eq: $NoteId}, ProjectId: {_eq: $ProjectId}}
  ) {
    affected_rows
  }
  delete_Note(where: {NoteId: {_eq: $NoteId}}) {
    affected_rows
  }
}
    `;
export type DeleteProjectNoteMutationFn = Apollo.MutationFunction<DeleteProjectNoteMutation, DeleteProjectNoteMutationVariables>;

/**
 * __useDeleteProjectNoteMutation__
 *
 * To run a mutation, you first call `useDeleteProjectNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectNoteMutation, { data, loading, error }] = useDeleteProjectNoteMutation({
 *   variables: {
 *      NoteId: // value for 'NoteId'
 *      ProjectId: // value for 'ProjectId'
 *   },
 * });
 */
export function useDeleteProjectNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectNoteMutation, DeleteProjectNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectNoteMutation, DeleteProjectNoteMutationVariables>(DeleteProjectNoteDocument, options);
      }
export type DeleteProjectNoteMutationHookResult = ReturnType<typeof useDeleteProjectNoteMutation>;
export type DeleteProjectNoteMutationResult = Apollo.MutationResult<DeleteProjectNoteMutation>;
export type DeleteProjectNoteMutationOptions = Apollo.BaseMutationOptions<DeleteProjectNoteMutation, DeleteProjectNoteMutationVariables>;
export const SaveUserProjectDocument = gql`
    query SaveUserProject($saveProjectBaseInfo: RoofProjectBaseInfoSave!) {
  roofproject_api_savebaseinfo(saveProjectBaseInfo: $saveProjectBaseInfo) {
    ProjectId
  }
}
    `;

/**
 * __useSaveUserProjectQuery__
 *
 * To run a query within a React component, call `useSaveUserProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaveUserProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaveUserProjectQuery({
 *   variables: {
 *      saveProjectBaseInfo: // value for 'saveProjectBaseInfo'
 *   },
 * });
 */
export function useSaveUserProjectQuery(baseOptions: Apollo.QueryHookOptions<SaveUserProjectQuery, SaveUserProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SaveUserProjectQuery, SaveUserProjectQueryVariables>(SaveUserProjectDocument, options);
      }
export function useSaveUserProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SaveUserProjectQuery, SaveUserProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SaveUserProjectQuery, SaveUserProjectQueryVariables>(SaveUserProjectDocument, options);
        }
export type SaveUserProjectQueryHookResult = ReturnType<typeof useSaveUserProjectQuery>;
export type SaveUserProjectLazyQueryHookResult = ReturnType<typeof useSaveUserProjectLazyQuery>;
export type SaveUserProjectQueryResult = Apollo.QueryResult<SaveUserProjectQuery, SaveUserProjectQueryVariables>;
export const GetCountryListDocument = gql`
    query GetCountryList {
  C_Country {
    CountryId
    CountryCode
    Dsc
  }
}
    `;

/**
 * __useGetCountryListQuery__
 *
 * To run a query within a React component, call `useGetCountryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountryListQuery(baseOptions?: Apollo.QueryHookOptions<GetCountryListQuery, GetCountryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountryListQuery, GetCountryListQueryVariables>(GetCountryListDocument, options);
      }
export function useGetCountryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountryListQuery, GetCountryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountryListQuery, GetCountryListQueryVariables>(GetCountryListDocument, options);
        }
export type GetCountryListQueryHookResult = ReturnType<typeof useGetCountryListQuery>;
export type GetCountryListLazyQueryHookResult = ReturnType<typeof useGetCountryListLazyQuery>;
export type GetCountryListQueryResult = Apollo.QueryResult<GetCountryListQuery, GetCountryListQueryVariables>;
export const GetStateProvinceListByCountryDocument = gql`
    query GetStateProvinceListByCountry($CountryId: Int) {
  R_Country_StateProvince(where: {CountryId: {_eq: $CountryId}}) {
    StateProvinceId
    C_StateProvince {
      StateProvinceCode
      Dsc
    }
  }
}
    `;

/**
 * __useGetStateProvinceListByCountryQuery__
 *
 * To run a query within a React component, call `useGetStateProvinceListByCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStateProvinceListByCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStateProvinceListByCountryQuery({
 *   variables: {
 *      CountryId: // value for 'CountryId'
 *   },
 * });
 */
export function useGetStateProvinceListByCountryQuery(baseOptions?: Apollo.QueryHookOptions<GetStateProvinceListByCountryQuery, GetStateProvinceListByCountryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStateProvinceListByCountryQuery, GetStateProvinceListByCountryQueryVariables>(GetStateProvinceListByCountryDocument, options);
      }
export function useGetStateProvinceListByCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStateProvinceListByCountryQuery, GetStateProvinceListByCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStateProvinceListByCountryQuery, GetStateProvinceListByCountryQueryVariables>(GetStateProvinceListByCountryDocument, options);
        }
export type GetStateProvinceListByCountryQueryHookResult = ReturnType<typeof useGetStateProvinceListByCountryQuery>;
export type GetStateProvinceListByCountryLazyQueryHookResult = ReturnType<typeof useGetStateProvinceListByCountryLazyQuery>;
export type GetStateProvinceListByCountryQueryResult = Apollo.QueryResult<GetStateProvinceListByCountryQuery, GetStateProvinceListByCountryQueryVariables>;
export const GetProjectsWithChildRoofAreaScenarioByEmailDocument = gql`
    query GetProjectsWithChildRoofAreaScenarioByEmail($UserEmail: String, $RandomId: String) {
  RoofProject(
    where: {_and: [{AzureB2CUserEmail: {_eq: $UserEmail}}, {AzureB2CUserGuid: {_neq: $RandomId}}]}
  ) {
    ProjectId
    UserAccountId
    Name
    Description
    IndexNumber
    Notes
    Address {
      AddressId
      Line1
      Line2
      City
      PostalCode
      StateId
      C_StateProvince {
        StateProvinceCode
      }
      CountryId
      C_Country {
        CountryCode
      }
    }
    RoofAreas {
      RoofAreaId
      Name
      R_RoofAreaScenarios {
        Scenario {
          ScenarioId
          Name
        }
      }
    }
  }
}
    `;

/**
 * __useGetProjectsWithChildRoofAreaScenarioByEmailQuery__
 *
 * To run a query within a React component, call `useGetProjectsWithChildRoofAreaScenarioByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsWithChildRoofAreaScenarioByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsWithChildRoofAreaScenarioByEmailQuery({
 *   variables: {
 *      UserEmail: // value for 'UserEmail'
 *      RandomId: // value for 'RandomId'
 *   },
 * });
 */
export function useGetProjectsWithChildRoofAreaScenarioByEmailQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectsWithChildRoofAreaScenarioByEmailQuery, GetProjectsWithChildRoofAreaScenarioByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsWithChildRoofAreaScenarioByEmailQuery, GetProjectsWithChildRoofAreaScenarioByEmailQueryVariables>(GetProjectsWithChildRoofAreaScenarioByEmailDocument, options);
      }
export function useGetProjectsWithChildRoofAreaScenarioByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsWithChildRoofAreaScenarioByEmailQuery, GetProjectsWithChildRoofAreaScenarioByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsWithChildRoofAreaScenarioByEmailQuery, GetProjectsWithChildRoofAreaScenarioByEmailQueryVariables>(GetProjectsWithChildRoofAreaScenarioByEmailDocument, options);
        }
export type GetProjectsWithChildRoofAreaScenarioByEmailQueryHookResult = ReturnType<typeof useGetProjectsWithChildRoofAreaScenarioByEmailQuery>;
export type GetProjectsWithChildRoofAreaScenarioByEmailLazyQueryHookResult = ReturnType<typeof useGetProjectsWithChildRoofAreaScenarioByEmailLazyQuery>;
export type GetProjectsWithChildRoofAreaScenarioByEmailQueryResult = Apollo.QueryResult<GetProjectsWithChildRoofAreaScenarioByEmailQuery, GetProjectsWithChildRoofAreaScenarioByEmailQueryVariables>;
export const AssemblySearchRoofTypeCountDocument = gql`
    query AssemblySearchRoofTypeCount($filters: vNavAssembly_RoofTypeCount_bool_exp, $limit: Int, $offset: Int, $order_by: vNavAssembly_RoofTypeCount_order_by!) {
  vNavAssembly_RoofTypeCount(where: $filters, order_by: [$order_by]) {
    LayerConfigId
    ConfigString
    NumOfAssembly
  }
}
    `;

/**
 * __useAssemblySearchRoofTypeCountQuery__
 *
 * To run a query within a React component, call `useAssemblySearchRoofTypeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssemblySearchRoofTypeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssemblySearchRoofTypeCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAssemblySearchRoofTypeCountQuery(baseOptions: Apollo.QueryHookOptions<AssemblySearchRoofTypeCountQuery, AssemblySearchRoofTypeCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssemblySearchRoofTypeCountQuery, AssemblySearchRoofTypeCountQueryVariables>(AssemblySearchRoofTypeCountDocument, options);
      }
export function useAssemblySearchRoofTypeCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssemblySearchRoofTypeCountQuery, AssemblySearchRoofTypeCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssemblySearchRoofTypeCountQuery, AssemblySearchRoofTypeCountQueryVariables>(AssemblySearchRoofTypeCountDocument, options);
        }
export type AssemblySearchRoofTypeCountQueryHookResult = ReturnType<typeof useAssemblySearchRoofTypeCountQuery>;
export type AssemblySearchRoofTypeCountLazyQueryHookResult = ReturnType<typeof useAssemblySearchRoofTypeCountLazyQuery>;
export type AssemblySearchRoofTypeCountQueryResult = Apollo.QueryResult<AssemblySearchRoofTypeCountQuery, AssemblySearchRoofTypeCountQueryVariables>;
export const GetNavAssembliesByLayerConfigIdDocument = gql`
    query GetNavAssembliesByLayerConfigId($LayerConfigId: Int) {
  NavAssembly(where: {LayerConfigId: {_eq: $LayerConfigId}}) {
    NavAssemblyId
    Slope
    WindUplift
    AssemblyTypeCode
    IntFireRatingCode
    ExtFireRatingCode
    AssemblyApplicationTypeCode
    HailRatingCode
    CoverSecurementMethodCode
    DeckTypeCode
  }
}
    `;

/**
 * __useGetNavAssembliesByLayerConfigIdQuery__
 *
 * To run a query within a React component, call `useGetNavAssembliesByLayerConfigIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavAssembliesByLayerConfigIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavAssembliesByLayerConfigIdQuery({
 *   variables: {
 *      LayerConfigId: // value for 'LayerConfigId'
 *   },
 * });
 */
export function useGetNavAssembliesByLayerConfigIdQuery(baseOptions?: Apollo.QueryHookOptions<GetNavAssembliesByLayerConfigIdQuery, GetNavAssembliesByLayerConfigIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNavAssembliesByLayerConfigIdQuery, GetNavAssembliesByLayerConfigIdQueryVariables>(GetNavAssembliesByLayerConfigIdDocument, options);
      }
export function useGetNavAssembliesByLayerConfigIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNavAssembliesByLayerConfigIdQuery, GetNavAssembliesByLayerConfigIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNavAssembliesByLayerConfigIdQuery, GetNavAssembliesByLayerConfigIdQueryVariables>(GetNavAssembliesByLayerConfigIdDocument, options);
        }
export type GetNavAssembliesByLayerConfigIdQueryHookResult = ReturnType<typeof useGetNavAssembliesByLayerConfigIdQuery>;
export type GetNavAssembliesByLayerConfigIdLazyQueryHookResult = ReturnType<typeof useGetNavAssembliesByLayerConfigIdLazyQuery>;
export type GetNavAssembliesByLayerConfigIdQueryResult = Apollo.QueryResult<GetNavAssembliesByLayerConfigIdQuery, GetNavAssembliesByLayerConfigIdQueryVariables>;
export const GetNavAssemblyLayersDocument = gql`
    query GetNavAssemblyLayers($NavAssemblyId: Int) {
  vNavAssembly_Layers(
    where: {NavAssemblyId: {_eq: $NavAssemblyId}}
    order_by: {NavLayerNum: asc}
  ) {
    NavLayerId
    NavLayerNum
    LayerTypeId
    LayerTypeCode
    FromNavLayerId
    FromNavLayerNum
    ToNavLayerId
    ToNavLayerNum
  }
}
    `;

/**
 * __useGetNavAssemblyLayersQuery__
 *
 * To run a query within a React component, call `useGetNavAssemblyLayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavAssemblyLayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavAssemblyLayersQuery({
 *   variables: {
 *      NavAssemblyId: // value for 'NavAssemblyId'
 *   },
 * });
 */
export function useGetNavAssemblyLayersQuery(baseOptions?: Apollo.QueryHookOptions<GetNavAssemblyLayersQuery, GetNavAssemblyLayersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNavAssemblyLayersQuery, GetNavAssemblyLayersQueryVariables>(GetNavAssemblyLayersDocument, options);
      }
export function useGetNavAssemblyLayersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNavAssemblyLayersQuery, GetNavAssemblyLayersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNavAssemblyLayersQuery, GetNavAssemblyLayersQueryVariables>(GetNavAssemblyLayersDocument, options);
        }
export type GetNavAssemblyLayersQueryHookResult = ReturnType<typeof useGetNavAssemblyLayersQuery>;
export type GetNavAssemblyLayersLazyQueryHookResult = ReturnType<typeof useGetNavAssemblyLayersLazyQuery>;
export type GetNavAssemblyLayersQueryResult = Apollo.QueryResult<GetNavAssemblyLayersQuery, GetNavAssemblyLayersQueryVariables>;
export const GetRoofTypeCountByFiltersDocument = gql`
    query GetRoofTypeCountByFilters($filters: vNavAssembly_RoofTypeCount_bool_exp) {
  vNavAssembly_RoofTypeCount_aggregate(where: $filters) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetRoofTypeCountByFiltersQuery__
 *
 * To run a query within a React component, call `useGetRoofTypeCountByFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoofTypeCountByFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoofTypeCountByFiltersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetRoofTypeCountByFiltersQuery(baseOptions?: Apollo.QueryHookOptions<GetRoofTypeCountByFiltersQuery, GetRoofTypeCountByFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoofTypeCountByFiltersQuery, GetRoofTypeCountByFiltersQueryVariables>(GetRoofTypeCountByFiltersDocument, options);
      }
export function useGetRoofTypeCountByFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoofTypeCountByFiltersQuery, GetRoofTypeCountByFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoofTypeCountByFiltersQuery, GetRoofTypeCountByFiltersQueryVariables>(GetRoofTypeCountByFiltersDocument, options);
        }
export type GetRoofTypeCountByFiltersQueryHookResult = ReturnType<typeof useGetRoofTypeCountByFiltersQuery>;
export type GetRoofTypeCountByFiltersLazyQueryHookResult = ReturnType<typeof useGetRoofTypeCountByFiltersLazyQuery>;
export type GetRoofTypeCountByFiltersQueryResult = Apollo.QueryResult<GetRoofTypeCountByFiltersQuery, GetRoofTypeCountByFiltersQueryVariables>;
export const AddUserReportBugDocument = gql`
    mutation AddUserReportBug($UserReportBug: UserReportBug_insert_input!) {
  insert_UserReportBug(objects: [$UserReportBug]) {
    returning {
      ReportBugId
      ReportBugGuid
    }
  }
}
    `;
export type AddUserReportBugMutationFn = Apollo.MutationFunction<AddUserReportBugMutation, AddUserReportBugMutationVariables>;

/**
 * __useAddUserReportBugMutation__
 *
 * To run a mutation, you first call `useAddUserReportBugMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserReportBugMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserReportBugMutation, { data, loading, error }] = useAddUserReportBugMutation({
 *   variables: {
 *      UserReportBug: // value for 'UserReportBug'
 *   },
 * });
 */
export function useAddUserReportBugMutation(baseOptions?: Apollo.MutationHookOptions<AddUserReportBugMutation, AddUserReportBugMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserReportBugMutation, AddUserReportBugMutationVariables>(AddUserReportBugDocument, options);
      }
export type AddUserReportBugMutationHookResult = ReturnType<typeof useAddUserReportBugMutation>;
export type AddUserReportBugMutationResult = Apollo.MutationResult<AddUserReportBugMutation>;
export type AddUserReportBugMutationOptions = Apollo.BaseMutationOptions<AddUserReportBugMutation, AddUserReportBugMutationVariables>;
export const GetUserReportBugSearchCriteriaDocument = gql`
    query GetUserReportBugSearchCriteria($ReportBugGuid: String) {
  UserReportBug(where: {ReportBugGuid: {_eq: $ReportBugGuid}}) {
    SearchCriteriaData
  }
}
    `;

/**
 * __useGetUserReportBugSearchCriteriaQuery__
 *
 * To run a query within a React component, call `useGetUserReportBugSearchCriteriaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserReportBugSearchCriteriaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserReportBugSearchCriteriaQuery({
 *   variables: {
 *      ReportBugGuid: // value for 'ReportBugGuid'
 *   },
 * });
 */
export function useGetUserReportBugSearchCriteriaQuery(baseOptions?: Apollo.QueryHookOptions<GetUserReportBugSearchCriteriaQuery, GetUserReportBugSearchCriteriaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserReportBugSearchCriteriaQuery, GetUserReportBugSearchCriteriaQueryVariables>(GetUserReportBugSearchCriteriaDocument, options);
      }
export function useGetUserReportBugSearchCriteriaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserReportBugSearchCriteriaQuery, GetUserReportBugSearchCriteriaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserReportBugSearchCriteriaQuery, GetUserReportBugSearchCriteriaQueryVariables>(GetUserReportBugSearchCriteriaDocument, options);
        }
export type GetUserReportBugSearchCriteriaQueryHookResult = ReturnType<typeof useGetUserReportBugSearchCriteriaQuery>;
export type GetUserReportBugSearchCriteriaLazyQueryHookResult = ReturnType<typeof useGetUserReportBugSearchCriteriaLazyQuery>;
export type GetUserReportBugSearchCriteriaQueryResult = Apollo.QueryResult<GetUserReportBugSearchCriteriaQuery, GetUserReportBugSearchCriteriaQueryVariables>;
export const AddUserShareLinkDocument = gql`
    mutation AddUserShareLink($UserShareLink: UserShareLink_insert_input!) {
  insert_UserShareLink(objects: [$UserShareLink]) {
    returning {
      ShareLinkId
      ShareLinkGuid
    }
  }
}
    `;
export type AddUserShareLinkMutationFn = Apollo.MutationFunction<AddUserShareLinkMutation, AddUserShareLinkMutationVariables>;

/**
 * __useAddUserShareLinkMutation__
 *
 * To run a mutation, you first call `useAddUserShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserShareLinkMutation, { data, loading, error }] = useAddUserShareLinkMutation({
 *   variables: {
 *      UserShareLink: // value for 'UserShareLink'
 *   },
 * });
 */
export function useAddUserShareLinkMutation(baseOptions?: Apollo.MutationHookOptions<AddUserShareLinkMutation, AddUserShareLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserShareLinkMutation, AddUserShareLinkMutationVariables>(AddUserShareLinkDocument, options);
      }
export type AddUserShareLinkMutationHookResult = ReturnType<typeof useAddUserShareLinkMutation>;
export type AddUserShareLinkMutationResult = Apollo.MutationResult<AddUserShareLinkMutation>;
export type AddUserShareLinkMutationOptions = Apollo.BaseMutationOptions<AddUserShareLinkMutation, AddUserShareLinkMutationVariables>;
export const GetUserShareLinkSearchCriteriaDocument = gql`
    query GetUserShareLinkSearchCriteria($ShareLinkGuid: String) {
  UserShareLink(where: {ShareLinkGuid: {_eq: $ShareLinkGuid}}) {
    SearchCriteriaData
  }
}
    `;

/**
 * __useGetUserShareLinkSearchCriteriaQuery__
 *
 * To run a query within a React component, call `useGetUserShareLinkSearchCriteriaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserShareLinkSearchCriteriaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserShareLinkSearchCriteriaQuery({
 *   variables: {
 *      ShareLinkGuid: // value for 'ShareLinkGuid'
 *   },
 * });
 */
export function useGetUserShareLinkSearchCriteriaQuery(baseOptions?: Apollo.QueryHookOptions<GetUserShareLinkSearchCriteriaQuery, GetUserShareLinkSearchCriteriaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserShareLinkSearchCriteriaQuery, GetUserShareLinkSearchCriteriaQueryVariables>(GetUserShareLinkSearchCriteriaDocument, options);
      }
export function useGetUserShareLinkSearchCriteriaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserShareLinkSearchCriteriaQuery, GetUserShareLinkSearchCriteriaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserShareLinkSearchCriteriaQuery, GetUserShareLinkSearchCriteriaQueryVariables>(GetUserShareLinkSearchCriteriaDocument, options);
        }
export type GetUserShareLinkSearchCriteriaQueryHookResult = ReturnType<typeof useGetUserShareLinkSearchCriteriaQuery>;
export type GetUserShareLinkSearchCriteriaLazyQueryHookResult = ReturnType<typeof useGetUserShareLinkSearchCriteriaLazyQuery>;
export type GetUserShareLinkSearchCriteriaQueryResult = Apollo.QueryResult<GetUserShareLinkSearchCriteriaQuery, GetUserShareLinkSearchCriteriaQueryVariables>;
export const SaveScenarioDocument = gql`
    query SaveScenario($scenarioInfoSave: ScenarioInfoSave!) {
  scenario_api_savescenario(saveScenarioInfo: $scenarioInfoSave) {
    ScenarioId
  }
}
    `;

/**
 * __useSaveScenarioQuery__
 *
 * To run a query within a React component, call `useSaveScenarioQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaveScenarioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaveScenarioQuery({
 *   variables: {
 *      scenarioInfoSave: // value for 'scenarioInfoSave'
 *   },
 * });
 */
export function useSaveScenarioQuery(baseOptions: Apollo.QueryHookOptions<SaveScenarioQuery, SaveScenarioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SaveScenarioQuery, SaveScenarioQueryVariables>(SaveScenarioDocument, options);
      }
export function useSaveScenarioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SaveScenarioQuery, SaveScenarioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SaveScenarioQuery, SaveScenarioQueryVariables>(SaveScenarioDocument, options);
        }
export type SaveScenarioQueryHookResult = ReturnType<typeof useSaveScenarioQuery>;
export type SaveScenarioLazyQueryHookResult = ReturnType<typeof useSaveScenarioLazyQuery>;
export type SaveScenarioQueryResult = Apollo.QueryResult<SaveScenarioQuery, SaveScenarioQueryVariables>;
export const GetScenarioDetailDocument = gql`
    query GetScenarioDetail($ScenarioId: Int, $AzureB2CUserEmail: String, $RandomId: String) {
  Scenario(
    where: {_and: [{ScenarioId: {_eq: $ScenarioId}}, {AzureB2CUserEmail: {_eq: $AzureB2CUserEmail}}, {AzureB2CUserGuid: {_neq: $RandomId}}]}
  ) {
    ScenarioId
    ScenarioStatusId
    Name
    NavAssemblyId
    NavAssemblyName
    DateCreated
    ScenarioTypeId
    Comments
    ScenarioLayers(order_by: {LayerNum: asc}) {
      ScenarioLayerId
      NavLayerId
      LayerNum
      LayerText
      LayerTypeID
      IsPermeable
      IsOptional
      ScenarioUsages(order_by: {SelfSecurementLayerTypeId: asc}) {
        ScenarioUsageId
        UsageId
        LayerNum
        SelfSecurementText
        SelfSecurementLayerTypeId
      }
    }
  }
}
    `;

/**
 * __useGetScenarioDetailQuery__
 *
 * To run a query within a React component, call `useGetScenarioDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioDetailQuery({
 *   variables: {
 *      ScenarioId: // value for 'ScenarioId'
 *      AzureB2CUserEmail: // value for 'AzureB2CUserEmail'
 *      RandomId: // value for 'RandomId'
 *   },
 * });
 */
export function useGetScenarioDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetScenarioDetailQuery, GetScenarioDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScenarioDetailQuery, GetScenarioDetailQueryVariables>(GetScenarioDetailDocument, options);
      }
export function useGetScenarioDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScenarioDetailQuery, GetScenarioDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScenarioDetailQuery, GetScenarioDetailQueryVariables>(GetScenarioDetailDocument, options);
        }
export type GetScenarioDetailQueryHookResult = ReturnType<typeof useGetScenarioDetailQuery>;
export type GetScenarioDetailLazyQueryHookResult = ReturnType<typeof useGetScenarioDetailLazyQuery>;
export type GetScenarioDetailQueryResult = Apollo.QueryResult<GetScenarioDetailQuery, GetScenarioDetailQueryVariables>;
export const GetListScenarioDetailByEmailDocument = gql`
    query GetListScenarioDetailByEmail($UserEmail: String, $RandomId: String) {
  Scenario(
    where: {_and: [{AzureB2CUserEmail: {_eq: $UserEmail}}, {AzureB2CUserGuid: {_neq: $RandomId}}]}
  ) {
    ScenarioId
    ScenarioStatusId
    Name
    NavAssemblyId
    NavAssemblyName
    DateCreated
    ScenarioTypeId
    Comments
  }
}
    `;

/**
 * __useGetListScenarioDetailByEmailQuery__
 *
 * To run a query within a React component, call `useGetListScenarioDetailByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListScenarioDetailByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListScenarioDetailByEmailQuery({
 *   variables: {
 *      UserEmail: // value for 'UserEmail'
 *      RandomId: // value for 'RandomId'
 *   },
 * });
 */
export function useGetListScenarioDetailByEmailQuery(baseOptions?: Apollo.QueryHookOptions<GetListScenarioDetailByEmailQuery, GetListScenarioDetailByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListScenarioDetailByEmailQuery, GetListScenarioDetailByEmailQueryVariables>(GetListScenarioDetailByEmailDocument, options);
      }
export function useGetListScenarioDetailByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListScenarioDetailByEmailQuery, GetListScenarioDetailByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListScenarioDetailByEmailQuery, GetListScenarioDetailByEmailQueryVariables>(GetListScenarioDetailByEmailDocument, options);
        }
export type GetListScenarioDetailByEmailQueryHookResult = ReturnType<typeof useGetListScenarioDetailByEmailQuery>;
export type GetListScenarioDetailByEmailLazyQueryHookResult = ReturnType<typeof useGetListScenarioDetailByEmailLazyQuery>;
export type GetListScenarioDetailByEmailQueryResult = Apollo.QueryResult<GetListScenarioDetailByEmailQuery, GetListScenarioDetailByEmailQueryVariables>;
export const GetListScenarioDetailByUserGuidDocument = gql`
    query GetListScenarioDetailByUserGuid($UserGuid: String) {
  Scenario(where: {AzureB2CUserGuid: {_eq: $UserGuid}}) {
    ScenarioId
    ScenarioStatusId
    Name
    NavAssemblyId
    NavAssemblyName
    DateCreated
    ScenarioTypeId
    Comments
  }
}
    `;

/**
 * __useGetListScenarioDetailByUserGuidQuery__
 *
 * To run a query within a React component, call `useGetListScenarioDetailByUserGuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListScenarioDetailByUserGuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListScenarioDetailByUserGuidQuery({
 *   variables: {
 *      UserGuid: // value for 'UserGuid'
 *   },
 * });
 */
export function useGetListScenarioDetailByUserGuidQuery(baseOptions?: Apollo.QueryHookOptions<GetListScenarioDetailByUserGuidQuery, GetListScenarioDetailByUserGuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListScenarioDetailByUserGuidQuery, GetListScenarioDetailByUserGuidQueryVariables>(GetListScenarioDetailByUserGuidDocument, options);
      }
export function useGetListScenarioDetailByUserGuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListScenarioDetailByUserGuidQuery, GetListScenarioDetailByUserGuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListScenarioDetailByUserGuidQuery, GetListScenarioDetailByUserGuidQueryVariables>(GetListScenarioDetailByUserGuidDocument, options);
        }
export type GetListScenarioDetailByUserGuidQueryHookResult = ReturnType<typeof useGetListScenarioDetailByUserGuidQuery>;
export type GetListScenarioDetailByUserGuidLazyQueryHookResult = ReturnType<typeof useGetListScenarioDetailByUserGuidLazyQuery>;
export type GetListScenarioDetailByUserGuidQueryResult = Apollo.QueryResult<GetListScenarioDetailByUserGuidQuery, GetListScenarioDetailByUserGuidQueryVariables>;
export const GetScenarioFullInfoDocument = gql`
    query GetScenarioFullInfo($ScenarioId: Int, $AzureB2CUserEmail: String, $RandomId: String) {
  Scenario(
    where: {_and: [{ScenarioId: {_eq: $ScenarioId}}, {AzureB2CUserEmail: {_eq: $AzureB2CUserEmail}}, {AzureB2CUserGuid: {_neq: $RandomId}}]}
  ) {
    ScenarioId
    ScenarioStatusId
    Name
    NavAssemblyId
    NavAssemblyName
    DateCreated
    ScenarioTypeId
    Comments
    NavAssembly {
      AssemblyTypeCode
      AssemblyApplicationTypeCode
      CoverSecurementMethodCode
      DeckTypeCode
      ExtFireRatingCode
      HailRatingCode
      IntFireRatingCode
      Slope
      WindUplift
      RoofAssembly {
        IncludesPV
        PVApplicationMethodId
        C_PVApplicationMethod {
          PVApplicationMethodCode
        }
        PVWindUplift
        PVHailRatingId
        C_PVHailRating {
          PVHailRatingCode
        }
        IsUsedWithCombustibleWalls
      }
    }
    ScenarioLayers(order_by: {LayerNum: asc}) {
      ScenarioLayerId
      NavLayerId
      LayerNum
      LayerText
      LayerTypeID
      IsPermeable
      IsOptional
      NavLayer {
        LayerTypeId
        NavLayerNum
        LayerId
        Layer {
          Comments
        }
      }
      ScenarioUsages(order_by: {SelfSecurementLayerTypeId: asc}) {
        ScenarioUsageId
        UsageId
        LayerNum
        SelfSecurementText
        SelfSecurementLayerTypeId
        Usage {
          ComponentId
          ApprovedUseId
          EnteredAsMetric
          Comments
          Component {
            Comments
            ManufacturedProduct {
              ComponentId
              TradeName
              Manufacturer {
                ManufacturerId
                Name
              }
            }
            StructuredSystem {
              ComponentId
              SystemName
              StructuredSystemProducts(order_by: {OrderNum: asc}) {
                ComponentId
                OrderNum
                ManufacturedProduct {
                  ComponentId
                  TradeName
                  Manufacturer {
                    ManufacturerId
                    Name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetScenarioFullInfoQuery__
 *
 * To run a query within a React component, call `useGetScenarioFullInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioFullInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioFullInfoQuery({
 *   variables: {
 *      ScenarioId: // value for 'ScenarioId'
 *      AzureB2CUserEmail: // value for 'AzureB2CUserEmail'
 *      RandomId: // value for 'RandomId'
 *   },
 * });
 */
export function useGetScenarioFullInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetScenarioFullInfoQuery, GetScenarioFullInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScenarioFullInfoQuery, GetScenarioFullInfoQueryVariables>(GetScenarioFullInfoDocument, options);
      }
export function useGetScenarioFullInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScenarioFullInfoQuery, GetScenarioFullInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScenarioFullInfoQuery, GetScenarioFullInfoQueryVariables>(GetScenarioFullInfoDocument, options);
        }
export type GetScenarioFullInfoQueryHookResult = ReturnType<typeof useGetScenarioFullInfoQuery>;
export type GetScenarioFullInfoLazyQueryHookResult = ReturnType<typeof useGetScenarioFullInfoLazyQuery>;
export type GetScenarioFullInfoQueryResult = Apollo.QueryResult<GetScenarioFullInfoQuery, GetScenarioFullInfoQueryVariables>;
export const GetScenarioContractorPackageInfoDocument = gql`
    query GetScenarioContractorPackageInfo($ScenarioId: Int, $AzureB2CUserEmail: String, $RandomId: String) {
  Scenario(
    where: {_and: [{ScenarioId: {_eq: $ScenarioId}}, {AzureB2CUserEmail: {_eq: $AzureB2CUserEmail}}, {AzureB2CUserGuid: {_neq: $RandomId}}]}
  ) {
    ScenarioId
    ScenarioStatusId
    Name
    NavAssemblyId
    NavAssemblyName
    DateCreated
    ScenarioTypeId
    Comments
    NavAssembly {
      AssemblyTypeCode
      AssemblyApplicationTypeCode
      CoverSecurementMethodCode
      DeckTypeCode
      ExtFireRatingCode
      HailRatingCode
      IntFireRatingCode
      Slope
      WindUplift
      RoofAssembly {
        IncludesPV
        PVApplicationMethodId
        C_PVApplicationMethod {
          PVApplicationMethodCode
        }
        PVWindUplift
        PVHailRatingId
        C_PVHailRating {
          PVHailRatingCode
        }
        IsUsedWithCombustibleWalls
      }
    }
    ScenarioLayers(order_by: {LayerNum: asc}) {
      ScenarioLayerId
      NavLayerId
      LayerNum
      LayerText
      LayerTypeID
      IsPermeable
      IsOptional
      NavLayer {
        LayerTypeId
        NavLayerNum
        LayerId
        Layer {
          Comments
        }
      }
      ScenarioUsages(order_by: {SelfSecurementLayerTypeId: asc}) {
        ScenarioUsageId
        UsageId
        LayerNum
        SelfSecurementText
        SelfSecurementLayerTypeId
        Usage {
          ComponentId
          ApprovedUseId
          EnteredAsMetric
          Comments
          Component {
            Comments
            ManufacturedProduct {
              ComponentId
              TradeName
              Manufacturer {
                ManufacturerId
                Name
              }
            }
            StructuredSystem {
              ComponentId
              SystemName
              StructuredSystemProducts(order_by: {OrderNum: asc}) {
                ComponentId
                OrderNum
                ManufacturedProduct {
                  ComponentId
                  TradeName
                  Manufacturer {
                    ManufacturerId
                    Name
                  }
                }
              }
            }
          }
        }
      }
    }
    R_RoofAreaScenarios {
      RoofArea {
        RoofAreaId
        IntFireRatingId
        HailRatingId
        GroundRoughnessRatingId
        ExtFireRatingId
        Name
        Description
        Width
        Length
        Height
        BuildingName
        BuildingEnclosureId
        WindBorneDebrisId
        Slope
        WindSpeed
        ContinuousParapet
        CalculatorVersion
        CalculationLastUpdatedDate
        TropicalCycloneRegion
        RoofAreaWindPressures {
          RoofAreaWindPressureId
          PressureZoneId
          WindUplift
          CopingRating
          FasciaRating
        }
        RoofProject {
          ProjectId
          Name
          Description
          Notes
          IndexNumber
        }
        R_RoofAreaContacts {
          ProjectRoleId
          ContactInfo {
            ContactInfoId
            FirstName
            LastName
            Initial
            CompanyName
            Phone
            Fax
            Email
            Address {
              Line1
              Line2
              City
              PostalCode
              C_StateProvince {
                StateProvinceCode
              }
              C_Country {
                CountryCode
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetScenarioContractorPackageInfoQuery__
 *
 * To run a query within a React component, call `useGetScenarioContractorPackageInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioContractorPackageInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioContractorPackageInfoQuery({
 *   variables: {
 *      ScenarioId: // value for 'ScenarioId'
 *      AzureB2CUserEmail: // value for 'AzureB2CUserEmail'
 *      RandomId: // value for 'RandomId'
 *   },
 * });
 */
export function useGetScenarioContractorPackageInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetScenarioContractorPackageInfoQuery, GetScenarioContractorPackageInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScenarioContractorPackageInfoQuery, GetScenarioContractorPackageInfoQueryVariables>(GetScenarioContractorPackageInfoDocument, options);
      }
export function useGetScenarioContractorPackageInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScenarioContractorPackageInfoQuery, GetScenarioContractorPackageInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScenarioContractorPackageInfoQuery, GetScenarioContractorPackageInfoQueryVariables>(GetScenarioContractorPackageInfoDocument, options);
        }
export type GetScenarioContractorPackageInfoQueryHookResult = ReturnType<typeof useGetScenarioContractorPackageInfoQuery>;
export type GetScenarioContractorPackageInfoLazyQueryHookResult = ReturnType<typeof useGetScenarioContractorPackageInfoLazyQuery>;
export type GetScenarioContractorPackageInfoQueryResult = Apollo.QueryResult<GetScenarioContractorPackageInfoQuery, GetScenarioContractorPackageInfoQueryVariables>;
export const GetExistingRoofDetailDocument = gql`
    query GetExistingRoofDetail($LayerId: Int) {
  ExistingRoofLayer(where: {LayerId: {_eq: $LayerId}}) {
    ExistingRoofInsulationMaterialId
    C_ExistingRoofInsulationMaterial {
      ExistingRoofInsulationMaterialCode
    }
    ExistingRoofSinglePlyMaterialId
    C_ExistingRoofSinglePlyMaterial {
      ExistingRoofSinglePlyMaterialCode
    }
    ExistingRoofDeckId
    C_ExistingRoofDeck {
      ExistingRoofDeckCode
    }
    ExistingRoofCoverId
    C_ExistingRoofCover {
      ExistingRoofCoverCode
    }
    ExistingRoofSurfaceId
    C_ExistingRoofSurface {
      ExistingRoofSurfaceCode
    }
    SteelStrength
    SteelThicknessMin
    SteelThicknessMax
    InsulationThicknessMin
    InsulationThicknessMax
    EnteredAsMetric
    Layer {
      Comments
    }
  }
}
    `;

/**
 * __useGetExistingRoofDetailQuery__
 *
 * To run a query within a React component, call `useGetExistingRoofDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingRoofDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingRoofDetailQuery({
 *   variables: {
 *      LayerId: // value for 'LayerId'
 *   },
 * });
 */
export function useGetExistingRoofDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetExistingRoofDetailQuery, GetExistingRoofDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExistingRoofDetailQuery, GetExistingRoofDetailQueryVariables>(GetExistingRoofDetailDocument, options);
      }
export function useGetExistingRoofDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExistingRoofDetailQuery, GetExistingRoofDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExistingRoofDetailQuery, GetExistingRoofDetailQueryVariables>(GetExistingRoofDetailDocument, options);
        }
export type GetExistingRoofDetailQueryHookResult = ReturnType<typeof useGetExistingRoofDetailQuery>;
export type GetExistingRoofDetailLazyQueryHookResult = ReturnType<typeof useGetExistingRoofDetailLazyQuery>;
export type GetExistingRoofDetailQueryResult = Apollo.QueryResult<GetExistingRoofDetailQuery, GetExistingRoofDetailQueryVariables>;
export const GetStructureDetailDocument = gql`
    query GetStructureDetail($LayerId: Int) {
  StructureLayer(where: {LayerId: {_eq: $LayerId}}) {
    StructureTypeId
    C_StructureType {
      StructureTypeCode
    }
    SpacingMax
    Thickness
    StrengthMin
    EnteredAsMetric
    Layer {
      Comments
    }
  }
}
    `;

/**
 * __useGetStructureDetailQuery__
 *
 * To run a query within a React component, call `useGetStructureDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStructureDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStructureDetailQuery({
 *   variables: {
 *      LayerId: // value for 'LayerId'
 *   },
 * });
 */
export function useGetStructureDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetStructureDetailQuery, GetStructureDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStructureDetailQuery, GetStructureDetailQueryVariables>(GetStructureDetailDocument, options);
      }
export function useGetStructureDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStructureDetailQuery, GetStructureDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStructureDetailQuery, GetStructureDetailQueryVariables>(GetStructureDetailDocument, options);
        }
export type GetStructureDetailQueryHookResult = ReturnType<typeof useGetStructureDetailQuery>;
export type GetStructureDetailLazyQueryHookResult = ReturnType<typeof useGetStructureDetailLazyQuery>;
export type GetStructureDetailQueryResult = Apollo.QueryResult<GetStructureDetailQuery, GetStructureDetailQueryVariables>;
export const GetDeckStructuralConcreteDetailDocument = gql`
    query GetDeckStructuralConcreteDetail($LayerId: Int) {
  StructuralConcreteLayer(where: {LayerId: {_eq: $LayerId}}) {
    Layer {
      Comments
    }
  }
}
    `;

/**
 * __useGetDeckStructuralConcreteDetailQuery__
 *
 * To run a query within a React component, call `useGetDeckStructuralConcreteDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeckStructuralConcreteDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeckStructuralConcreteDetailQuery({
 *   variables: {
 *      LayerId: // value for 'LayerId'
 *   },
 * });
 */
export function useGetDeckStructuralConcreteDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetDeckStructuralConcreteDetailQuery, GetDeckStructuralConcreteDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeckStructuralConcreteDetailQuery, GetDeckStructuralConcreteDetailQueryVariables>(GetDeckStructuralConcreteDetailDocument, options);
      }
export function useGetDeckStructuralConcreteDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeckStructuralConcreteDetailQuery, GetDeckStructuralConcreteDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeckStructuralConcreteDetailQuery, GetDeckStructuralConcreteDetailQueryVariables>(GetDeckStructuralConcreteDetailDocument, options);
        }
export type GetDeckStructuralConcreteDetailQueryHookResult = ReturnType<typeof useGetDeckStructuralConcreteDetailQuery>;
export type GetDeckStructuralConcreteDetailLazyQueryHookResult = ReturnType<typeof useGetDeckStructuralConcreteDetailLazyQuery>;
export type GetDeckStructuralConcreteDetailQueryResult = Apollo.QueryResult<GetDeckStructuralConcreteDetailQuery, GetDeckStructuralConcreteDetailQueryVariables>;
export const GetCoatingUsageDetailDocument = gql`
    query GetCoatingUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        Coating {
          CoatingMaterialId
          C_CoatingMaterial {
            CoatingMaterialCode
          }
        }
      }
    }
    CoatingUsage {
      NumCoats
      ApplicationRateWet
      ApplicationRateDry
    }
  }
}
    `;

/**
 * __useGetCoatingUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetCoatingUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoatingUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoatingUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetCoatingUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetCoatingUsageDetailQuery, GetCoatingUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoatingUsageDetailQuery, GetCoatingUsageDetailQueryVariables>(GetCoatingUsageDetailDocument, options);
      }
export function useGetCoatingUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoatingUsageDetailQuery, GetCoatingUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoatingUsageDetailQuery, GetCoatingUsageDetailQueryVariables>(GetCoatingUsageDetailDocument, options);
        }
export type GetCoatingUsageDetailQueryHookResult = ReturnType<typeof useGetCoatingUsageDetailQuery>;
export type GetCoatingUsageDetailLazyQueryHookResult = ReturnType<typeof useGetCoatingUsageDetailLazyQuery>;
export type GetCoatingUsageDetailQueryResult = Apollo.QueryResult<GetCoatingUsageDetailQuery, GetCoatingUsageDetailQueryVariables>;
export const GetPreassembledFasteningUsageDetailDocument = gql`
    query GetPreassembledFasteningUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        PreassembledFastening {
          FastenerTypeId
          C_FastenerType {
            FastenerTypeCode
          }
          FastenerMaterialId
          cSecurementmaterialByFastenermaterialid {
            SecurementMaterialCode
          }
          FastenerCoatingId
          C_FastenerCoating {
            FastenerCoatingCode
          }
          FastenerDriveHeadId
          C_FastenerDriveHead {
            FastenerDriveHeadCode
          }
          FastenerDiameter
          LengthMin
          LengthMax
          IsSteelDeckFastener
          StructureThicknessMin
          StructureThicknessMax
          DeckDesignThicknessMax
          StressDistributorTypeId
          C_StressDistributorType {
            StressDistributorTypeCode
          }
          StressDistributorMaterialId
          C_SecurementMaterial {
            SecurementMaterialCode
          }
          Thickness
          StressPlateShapeId
          C_StressPlateShape {
            StressPlateShapeCode
          }
          Diameter
          Width
          Length
          HoleSpacing
          ThicknessMin
          IsSecuredToConcreteDeck
        }
      }
    }
    PreassembledFasteningUsage {
      RowSpacing
      OnCenter
      IsFieldRows
      FieldRowsOnCenter
      NumFieldRows
      FasteningApplicationMethodId
      C_FasteningApplicationMethod {
        FasteningApplicationMethodCode
      }
      Embedment
      StressDistributorTypeId
      C_StressDistributorType {
        StressDistributorTypeCode
      }
      FastenersPerPlateOrClip
      ContributoryArea
    }
  }
}
    `;

/**
 * __useGetPreassembledFasteningUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetPreassembledFasteningUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreassembledFasteningUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreassembledFasteningUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetPreassembledFasteningUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetPreassembledFasteningUsageDetailQuery, GetPreassembledFasteningUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPreassembledFasteningUsageDetailQuery, GetPreassembledFasteningUsageDetailQueryVariables>(GetPreassembledFasteningUsageDetailDocument, options);
      }
export function useGetPreassembledFasteningUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPreassembledFasteningUsageDetailQuery, GetPreassembledFasteningUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPreassembledFasteningUsageDetailQuery, GetPreassembledFasteningUsageDetailQueryVariables>(GetPreassembledFasteningUsageDetailDocument, options);
        }
export type GetPreassembledFasteningUsageDetailQueryHookResult = ReturnType<typeof useGetPreassembledFasteningUsageDetailQuery>;
export type GetPreassembledFasteningUsageDetailLazyQueryHookResult = ReturnType<typeof useGetPreassembledFasteningUsageDetailLazyQuery>;
export type GetPreassembledFasteningUsageDetailQueryResult = Apollo.QueryResult<GetPreassembledFasteningUsageDetailQuery, GetPreassembledFasteningUsageDetailQueryVariables>;
export const GetFasteningSystemStressPlateUsageDetailDocument = gql`
    query GetFasteningSystemStressPlateUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      StructuredSystem {
        SystemName
        StructuredSystemProducts(order_by: {OrderNum: asc}) {
          ManufacturedProduct {
            ComponentId
            TradeName
            ManufacturerId
            Manufacturer {
              Name
            }
          }
        }
      }
      Comments
    }
    FasteningSystemStressPlateUsage {
      OnCenter
      RowSpacing
      FastenersPerPlateOrClip
      IsFieldRows
      FieldRowsOnCenter
      NumFieldRows
      ContributoryArea
      FasteningApplicationMethodId
      C_FasteningApplicationMethod {
        FasteningApplicationMethodCode
      }
      Embedment
    }
  }
}
    `;

/**
 * __useGetFasteningSystemStressPlateUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetFasteningSystemStressPlateUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFasteningSystemStressPlateUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFasteningSystemStressPlateUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetFasteningSystemStressPlateUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetFasteningSystemStressPlateUsageDetailQuery, GetFasteningSystemStressPlateUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFasteningSystemStressPlateUsageDetailQuery, GetFasteningSystemStressPlateUsageDetailQueryVariables>(GetFasteningSystemStressPlateUsageDetailDocument, options);
      }
export function useGetFasteningSystemStressPlateUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFasteningSystemStressPlateUsageDetailQuery, GetFasteningSystemStressPlateUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFasteningSystemStressPlateUsageDetailQuery, GetFasteningSystemStressPlateUsageDetailQueryVariables>(GetFasteningSystemStressPlateUsageDetailDocument, options);
        }
export type GetFasteningSystemStressPlateUsageDetailQueryHookResult = ReturnType<typeof useGetFasteningSystemStressPlateUsageDetailQuery>;
export type GetFasteningSystemStressPlateUsageDetailLazyQueryHookResult = ReturnType<typeof useGetFasteningSystemStressPlateUsageDetailLazyQuery>;
export type GetFasteningSystemStressPlateUsageDetailQueryResult = Apollo.QueryResult<GetFasteningSystemStressPlateUsageDetailQuery, GetFasteningSystemStressPlateUsageDetailQueryVariables>;
export const GetAdhesiveFullApplicationUsageDetailDocument = gql`
    query GetAdhesiveFullApplicationUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        AdhesiveFullApplication {
          AdhesiveMaterialId
          C_AdhesiveMaterial {
            AdhesiveMaterialCode
          }
          AdhesiveTypeId
          C_AdhesiveType {
            AdhesiveTypeCode
          }
        }
      }
    }
    AdhesiveFullApplicationUsage {
      SubstratePrimerRateWet
      OverSubstrateRateWet
      UndersideRateWet
      SubstratePrimerRateDry
      OverSubstrateRateDry
      UndersideRateDry
    }
  }
}
    `;

/**
 * __useGetAdhesiveFullApplicationUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetAdhesiveFullApplicationUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdhesiveFullApplicationUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdhesiveFullApplicationUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetAdhesiveFullApplicationUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetAdhesiveFullApplicationUsageDetailQuery, GetAdhesiveFullApplicationUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdhesiveFullApplicationUsageDetailQuery, GetAdhesiveFullApplicationUsageDetailQueryVariables>(GetAdhesiveFullApplicationUsageDetailDocument, options);
      }
export function useGetAdhesiveFullApplicationUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdhesiveFullApplicationUsageDetailQuery, GetAdhesiveFullApplicationUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdhesiveFullApplicationUsageDetailQuery, GetAdhesiveFullApplicationUsageDetailQueryVariables>(GetAdhesiveFullApplicationUsageDetailDocument, options);
        }
export type GetAdhesiveFullApplicationUsageDetailQueryHookResult = ReturnType<typeof useGetAdhesiveFullApplicationUsageDetailQuery>;
export type GetAdhesiveFullApplicationUsageDetailLazyQueryHookResult = ReturnType<typeof useGetAdhesiveFullApplicationUsageDetailLazyQuery>;
export type GetAdhesiveFullApplicationUsageDetailQueryResult = Apollo.QueryResult<GetAdhesiveFullApplicationUsageDetailQuery, GetAdhesiveFullApplicationUsageDetailQueryVariables>;
export const GetOtherPlyUsageDetailDocument = gql`
    query GetOtherPlyUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        OtherPly {
          BackingMaterialId
          C_BackingMaterial {
            BackingMaterialCode
          }
          ReinforcementId
          C_Reinforcement {
            ReinforcementCode
          }
          MultiplyMaterialId
          C_MultiplyMaterial {
            MultiplyMaterialCode
          }
          Width
        }
      }
    }
    OtherPlyUsage {
      NumPlies
    }
  }
}
    `;

/**
 * __useGetOtherPlyUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetOtherPlyUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtherPlyUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtherPlyUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetOtherPlyUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetOtherPlyUsageDetailQuery, GetOtherPlyUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOtherPlyUsageDetailQuery, GetOtherPlyUsageDetailQueryVariables>(GetOtherPlyUsageDetailDocument, options);
      }
export function useGetOtherPlyUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOtherPlyUsageDetailQuery, GetOtherPlyUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOtherPlyUsageDetailQuery, GetOtherPlyUsageDetailQueryVariables>(GetOtherPlyUsageDetailDocument, options);
        }
export type GetOtherPlyUsageDetailQueryHookResult = ReturnType<typeof useGetOtherPlyUsageDetailQuery>;
export type GetOtherPlyUsageDetailLazyQueryHookResult = ReturnType<typeof useGetOtherPlyUsageDetailLazyQuery>;
export type GetOtherPlyUsageDetailQueryResult = Apollo.QueryResult<GetOtherPlyUsageDetailQuery, GetOtherPlyUsageDetailQueryVariables>;
export const GetBaseSheetUsageDetailDocument = gql`
    query GetBaseSheetUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        BaseSheet {
          IsPermeable
          Width
          BackingMaterialId
          C_BackingMaterial {
            BackingMaterialCode
          }
          MultiplyMaterialId
          C_MultiplyMaterial {
            MultiplyMaterialCode
          }
          ReinforcementId
          C_Reinforcement {
            ReinforcementCode
          }
        }
      }
    }
    BaseSheetUsage {
      LapWidth
      WidthMax
    }
  }
}
    `;

/**
 * __useGetBaseSheetUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetBaseSheetUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseSheetUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseSheetUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetBaseSheetUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetBaseSheetUsageDetailQuery, GetBaseSheetUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaseSheetUsageDetailQuery, GetBaseSheetUsageDetailQueryVariables>(GetBaseSheetUsageDetailDocument, options);
      }
export function useGetBaseSheetUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseSheetUsageDetailQuery, GetBaseSheetUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaseSheetUsageDetailQuery, GetBaseSheetUsageDetailQueryVariables>(GetBaseSheetUsageDetailDocument, options);
        }
export type GetBaseSheetUsageDetailQueryHookResult = ReturnType<typeof useGetBaseSheetUsageDetailQuery>;
export type GetBaseSheetUsageDetailLazyQueryHookResult = ReturnType<typeof useGetBaseSheetUsageDetailLazyQuery>;
export type GetBaseSheetUsageDetailQueryResult = Apollo.QueryResult<GetBaseSheetUsageDetailQuery, GetBaseSheetUsageDetailQueryVariables>;
export const GetSinglePlyCoverUsageDetailDocument = gql`
    query GetSinglePlyCoverUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        SinglePlyCover {
          SinglePlyMaterialId
          C_SinglePlyMaterial {
            SinglePlyMaterialCode
          }
          BackingMaterialId
          C_BackingMaterial {
            BackingMaterialCode
          }
          ThicknessMin
          ThicknessMax
          ReinforcementId
          C_Reinforcement {
            ReinforcementCode
          }
        }
      }
    }
    SinglePlyUsage {
      WidthMax
      ThicknessMin
      ThicknessMax
    }
  }
}
    `;

/**
 * __useGetSinglePlyCoverUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetSinglePlyCoverUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSinglePlyCoverUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSinglePlyCoverUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetSinglePlyCoverUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetSinglePlyCoverUsageDetailQuery, GetSinglePlyCoverUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSinglePlyCoverUsageDetailQuery, GetSinglePlyCoverUsageDetailQueryVariables>(GetSinglePlyCoverUsageDetailDocument, options);
      }
export function useGetSinglePlyCoverUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSinglePlyCoverUsageDetailQuery, GetSinglePlyCoverUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSinglePlyCoverUsageDetailQuery, GetSinglePlyCoverUsageDetailQueryVariables>(GetSinglePlyCoverUsageDetailDocument, options);
        }
export type GetSinglePlyCoverUsageDetailQueryHookResult = ReturnType<typeof useGetSinglePlyCoverUsageDetailQuery>;
export type GetSinglePlyCoverUsageDetailLazyQueryHookResult = ReturnType<typeof useGetSinglePlyCoverUsageDetailLazyQuery>;
export type GetSinglePlyCoverUsageDetailQueryResult = Apollo.QueryResult<GetSinglePlyCoverUsageDetailQuery, GetSinglePlyCoverUsageDetailQueryVariables>;
export const GetBoardStockUsageDetailDocument = gql`
    query GetBoardStockUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        BoardStock {
          BoardStockMaterialId
          C_BoardStockMaterial {
            BoardStockMaterialCode
          }
          BoardProfileId
          C_BoardProfile {
            BoardProfileCode
          }
          DensityMin
          ThicknessMin
          ThicknessMax
        }
      }
    }
    BoardStockUsage {
      ThicknessMin
      ThicknessMax
      InsBoardSizeId
      InsBoardSize {
        Width
        Length
        IsMetric
      }
    }
  }
}
    `;

/**
 * __useGetBoardStockUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetBoardStockUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoardStockUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoardStockUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetBoardStockUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetBoardStockUsageDetailQuery, GetBoardStockUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBoardStockUsageDetailQuery, GetBoardStockUsageDetailQueryVariables>(GetBoardStockUsageDetailDocument, options);
      }
export function useGetBoardStockUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBoardStockUsageDetailQuery, GetBoardStockUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBoardStockUsageDetailQuery, GetBoardStockUsageDetailQueryVariables>(GetBoardStockUsageDetailDocument, options);
        }
export type GetBoardStockUsageDetailQueryHookResult = ReturnType<typeof useGetBoardStockUsageDetailQuery>;
export type GetBoardStockUsageDetailLazyQueryHookResult = ReturnType<typeof useGetBoardStockUsageDetailLazyQuery>;
export type GetBoardStockUsageDetailQueryResult = Apollo.QueryResult<GetBoardStockUsageDetailQuery, GetBoardStockUsageDetailQueryVariables>;
export const GetLightweightInsulatingConcreteUsageDetailDocument = gql`
    query GetLightweightInsulatingConcreteUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        LightweightInsulatingConcrete {
          AggregateId
          C_Aggregate {
            AggregateCode
          }
          CementAggregateRatioId
          C_CementAggregateRatio {
            CementAggregateRatioCode
          }
          ThicknessMin
          CompressiveStrengthMin
          DryDensityMin
          CastDensityMin
        }
      }
    }
    LightweightInsulatingConcreteUsage {
      ThicknessMin
      MinCompressiveStrength
      DryDensityMin
      CastDensityMin
    }
  }
}
    `;

/**
 * __useGetLightweightInsulatingConcreteUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetLightweightInsulatingConcreteUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightweightInsulatingConcreteUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightweightInsulatingConcreteUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetLightweightInsulatingConcreteUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetLightweightInsulatingConcreteUsageDetailQuery, GetLightweightInsulatingConcreteUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLightweightInsulatingConcreteUsageDetailQuery, GetLightweightInsulatingConcreteUsageDetailQueryVariables>(GetLightweightInsulatingConcreteUsageDetailDocument, options);
      }
export function useGetLightweightInsulatingConcreteUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLightweightInsulatingConcreteUsageDetailQuery, GetLightweightInsulatingConcreteUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLightweightInsulatingConcreteUsageDetailQuery, GetLightweightInsulatingConcreteUsageDetailQueryVariables>(GetLightweightInsulatingConcreteUsageDetailDocument, options);
        }
export type GetLightweightInsulatingConcreteUsageDetailQueryHookResult = ReturnType<typeof useGetLightweightInsulatingConcreteUsageDetailQuery>;
export type GetLightweightInsulatingConcreteUsageDetailLazyQueryHookResult = ReturnType<typeof useGetLightweightInsulatingConcreteUsageDetailLazyQuery>;
export type GetLightweightInsulatingConcreteUsageDetailQueryResult = Apollo.QueryResult<GetLightweightInsulatingConcreteUsageDetailQuery, GetLightweightInsulatingConcreteUsageDetailQueryVariables>;
export const GetSteelDeckUsageDetailDocument = gql`
    query GetSteelDeckUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        SteelDeck {
          IsAcoustical
          SteelDeckRibTypeId
          C_SteelDeckRibType {
            SteelDeckRibTypeCode
          }
          DepthMin
          DepthMax
          WidthMin
          WidthMax
          DesignThickness
          SpanMax
        }
      }
    }
    SteelDeckUsage {
      DesignThickness
      SpanMax
      GradeMin
      ThicknessMin
    }
  }
}
    `;

/**
 * __useGetSteelDeckUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetSteelDeckUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSteelDeckUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSteelDeckUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetSteelDeckUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetSteelDeckUsageDetailQuery, GetSteelDeckUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSteelDeckUsageDetailQuery, GetSteelDeckUsageDetailQueryVariables>(GetSteelDeckUsageDetailDocument, options);
      }
export function useGetSteelDeckUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSteelDeckUsageDetailQuery, GetSteelDeckUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSteelDeckUsageDetailQuery, GetSteelDeckUsageDetailQueryVariables>(GetSteelDeckUsageDetailDocument, options);
        }
export type GetSteelDeckUsageDetailQueryHookResult = ReturnType<typeof useGetSteelDeckUsageDetailQuery>;
export type GetSteelDeckUsageDetailLazyQueryHookResult = ReturnType<typeof useGetSteelDeckUsageDetailLazyQuery>;
export type GetSteelDeckUsageDetailQueryResult = Apollo.QueryResult<GetSteelDeckUsageDetailQuery, GetSteelDeckUsageDetailQueryVariables>;
export const GetVaporRetarderUsageDetailDocument = gql`
    query GetVaporRetarderUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        VaporRetarder {
          RetarderMaterialId
          C_RetarderMaterial {
            RetarderMaterialCode
          }
        }
      }
    }
    VaporRetarderUsage {
      NumPlies
    }
  }
}
    `;

/**
 * __useGetVaporRetarderUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetVaporRetarderUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVaporRetarderUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVaporRetarderUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetVaporRetarderUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetVaporRetarderUsageDetailQuery, GetVaporRetarderUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVaporRetarderUsageDetailQuery, GetVaporRetarderUsageDetailQueryVariables>(GetVaporRetarderUsageDetailDocument, options);
      }
export function useGetVaporRetarderUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVaporRetarderUsageDetailQuery, GetVaporRetarderUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVaporRetarderUsageDetailQuery, GetVaporRetarderUsageDetailQueryVariables>(GetVaporRetarderUsageDetailDocument, options);
        }
export type GetVaporRetarderUsageDetailQueryHookResult = ReturnType<typeof useGetVaporRetarderUsageDetailQuery>;
export type GetVaporRetarderUsageDetailLazyQueryHookResult = ReturnType<typeof useGetVaporRetarderUsageDetailLazyQuery>;
export type GetVaporRetarderUsageDetailQueryResult = Apollo.QueryResult<GetVaporRetarderUsageDetailQuery, GetVaporRetarderUsageDetailQueryVariables>;
export const GetVaporRetarderSystemUsageDetailDocument = gql`
    query GetVaporRetarderSystemUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      StructuredSystem {
        SystemName
        StructuredSystemProducts(order_by: {OrderNum: asc}) {
          ManufacturedProduct {
            ComponentId
            TradeName
            ManufacturerId
            Manufacturer {
              Name
            }
          }
        }
      }
      Comments
    }
    BuiltupVaporRetarderSystemUsage {
      NumberPlies
      ApplicationRateWet
      ApplicationRateDry
    }
  }
}
    `;

/**
 * __useGetVaporRetarderSystemUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetVaporRetarderSystemUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVaporRetarderSystemUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVaporRetarderSystemUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetVaporRetarderSystemUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetVaporRetarderSystemUsageDetailQuery, GetVaporRetarderSystemUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVaporRetarderSystemUsageDetailQuery, GetVaporRetarderSystemUsageDetailQueryVariables>(GetVaporRetarderSystemUsageDetailDocument, options);
      }
export function useGetVaporRetarderSystemUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVaporRetarderSystemUsageDetailQuery, GetVaporRetarderSystemUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVaporRetarderSystemUsageDetailQuery, GetVaporRetarderSystemUsageDetailQueryVariables>(GetVaporRetarderSystemUsageDetailDocument, options);
        }
export type GetVaporRetarderSystemUsageDetailQueryHookResult = ReturnType<typeof useGetVaporRetarderSystemUsageDetailQuery>;
export type GetVaporRetarderSystemUsageDetailLazyQueryHookResult = ReturnType<typeof useGetVaporRetarderSystemUsageDetailLazyQuery>;
export type GetVaporRetarderSystemUsageDetailQueryResult = Apollo.QueryResult<GetVaporRetarderSystemUsageDetailQuery, GetVaporRetarderSystemUsageDetailQueryVariables>;
export const GetCoverBoardUsageDetailDocument = gql`
    query GetCoverBoardUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        CoverBoard {
          CoverBoardMaterialId
          C_CoverBoardMaterial {
            CoverBoardMaterialCode
          }
          BoardProfileId
          C_BoardProfile {
            BoardProfileCode
          }
          DensityMin
          ThicknessMin
          ThicknessMax
        }
      }
    }
    CoverBoardUsage {
      ThicknessMin
      ThicknessMax
      InsBoardSizeId
      InsBoardSize {
        Width
        Length
        IsMetric
      }
    }
  }
}
    `;

/**
 * __useGetCoverBoardUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetCoverBoardUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoverBoardUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoverBoardUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetCoverBoardUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetCoverBoardUsageDetailQuery, GetCoverBoardUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoverBoardUsageDetailQuery, GetCoverBoardUsageDetailQueryVariables>(GetCoverBoardUsageDetailDocument, options);
      }
export function useGetCoverBoardUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoverBoardUsageDetailQuery, GetCoverBoardUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoverBoardUsageDetailQuery, GetCoverBoardUsageDetailQueryVariables>(GetCoverBoardUsageDetailDocument, options);
        }
export type GetCoverBoardUsageDetailQueryHookResult = ReturnType<typeof useGetCoverBoardUsageDetailQuery>;
export type GetCoverBoardUsageDetailLazyQueryHookResult = ReturnType<typeof useGetCoverBoardUsageDetailLazyQuery>;
export type GetCoverBoardUsageDetailQueryResult = Apollo.QueryResult<GetCoverBoardUsageDetailQuery, GetCoverBoardUsageDetailQueryVariables>;
export const GetCapPlyUsageDetailDocument = gql`
    query GetCapPlyUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        CapPly {
          BackingMaterialId
          C_BackingMaterial {
            BackingMaterialCode
          }
          Width
          MultiplyMaterialId
          C_MultiplyMaterial {
            MultiplyMaterialCode
          }
          ReinforcementId
          C_Reinforcement {
            ReinforcementCode
          }
          SurfaceTypeId
          C_SurfaceType {
            SurfaceTypeCode
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCapPlyUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetCapPlyUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCapPlyUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCapPlyUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetCapPlyUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetCapPlyUsageDetailQuery, GetCapPlyUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCapPlyUsageDetailQuery, GetCapPlyUsageDetailQueryVariables>(GetCapPlyUsageDetailDocument, options);
      }
export function useGetCapPlyUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCapPlyUsageDetailQuery, GetCapPlyUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCapPlyUsageDetailQuery, GetCapPlyUsageDetailQueryVariables>(GetCapPlyUsageDetailDocument, options);
        }
export type GetCapPlyUsageDetailQueryHookResult = ReturnType<typeof useGetCapPlyUsageDetailQuery>;
export type GetCapPlyUsageDetailLazyQueryHookResult = ReturnType<typeof useGetCapPlyUsageDetailLazyQuery>;
export type GetCapPlyUsageDetailQueryResult = Apollo.QueryResult<GetCapPlyUsageDetailQuery, GetCapPlyUsageDetailQueryVariables>;
export const GetWeldTorchedUsageDetailDocument = gql`
    query GetWeldTorchedUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
      }
    }
  }
}
    `;

/**
 * __useGetWeldTorchedUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetWeldTorchedUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeldTorchedUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeldTorchedUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetWeldTorchedUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetWeldTorchedUsageDetailQuery, GetWeldTorchedUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWeldTorchedUsageDetailQuery, GetWeldTorchedUsageDetailQueryVariables>(GetWeldTorchedUsageDetailDocument, options);
      }
export function useGetWeldTorchedUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWeldTorchedUsageDetailQuery, GetWeldTorchedUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWeldTorchedUsageDetailQuery, GetWeldTorchedUsageDetailQueryVariables>(GetWeldTorchedUsageDetailDocument, options);
        }
export type GetWeldTorchedUsageDetailQueryHookResult = ReturnType<typeof useGetWeldTorchedUsageDetailQuery>;
export type GetWeldTorchedUsageDetailLazyQueryHookResult = ReturnType<typeof useGetWeldTorchedUsageDetailLazyQuery>;
export type GetWeldTorchedUsageDetailQueryResult = Apollo.QueryResult<GetWeldTorchedUsageDetailQuery, GetWeldTorchedUsageDetailQueryVariables>;
export const GetThermalBarrierUsageDetailDocument = gql`
    query GetThermalBarrierUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        ThermalBarrier {
          ThermalBarrierMaterialId
          C_ThermalBarrierMaterial {
            ThermalBarrierMaterialCode
          }
          BoardProfileId
          C_BoardProfile {
            BoardProfileCode
          }
          DensityMin
          ThicknessMin
          ThicknessMax
        }
      }
    }
    ThermalBarrierUsage {
      ThicknessMin
      ThicknessMax
      InsBoardSizeId
      InsBoardSize {
        Width
        Length
        IsMetric
      }
    }
  }
}
    `;

/**
 * __useGetThermalBarrierUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetThermalBarrierUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThermalBarrierUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThermalBarrierUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetThermalBarrierUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetThermalBarrierUsageDetailQuery, GetThermalBarrierUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetThermalBarrierUsageDetailQuery, GetThermalBarrierUsageDetailQueryVariables>(GetThermalBarrierUsageDetailDocument, options);
      }
export function useGetThermalBarrierUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetThermalBarrierUsageDetailQuery, GetThermalBarrierUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetThermalBarrierUsageDetailQuery, GetThermalBarrierUsageDetailQueryVariables>(GetThermalBarrierUsageDetailDocument, options);
        }
export type GetThermalBarrierUsageDetailQueryHookResult = ReturnType<typeof useGetThermalBarrierUsageDetailQuery>;
export type GetThermalBarrierUsageDetailLazyQueryHookResult = ReturnType<typeof useGetThermalBarrierUsageDetailLazyQuery>;
export type GetThermalBarrierUsageDetailQueryResult = Apollo.QueryResult<GetThermalBarrierUsageDetailQuery, GetThermalBarrierUsageDetailQueryVariables>;
export const GetBasePlyUsageDetailDocument = gql`
    query GetBasePlyUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        BasePly {
          BackingMaterialId
          C_BackingMaterial {
            BackingMaterialCode
          }
          Width
          MultiplyMaterialId
          C_MultiplyMaterial {
            MultiplyMaterialCode
          }
          ReinforcementId
          C_Reinforcement {
            ReinforcementCode
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetBasePlyUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetBasePlyUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasePlyUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasePlyUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetBasePlyUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetBasePlyUsageDetailQuery, GetBasePlyUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBasePlyUsageDetailQuery, GetBasePlyUsageDetailQueryVariables>(GetBasePlyUsageDetailDocument, options);
      }
export function useGetBasePlyUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBasePlyUsageDetailQuery, GetBasePlyUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBasePlyUsageDetailQuery, GetBasePlyUsageDetailQueryVariables>(GetBasePlyUsageDetailDocument, options);
        }
export type GetBasePlyUsageDetailQueryHookResult = ReturnType<typeof useGetBasePlyUsageDetailQuery>;
export type GetBasePlyUsageDetailLazyQueryHookResult = ReturnType<typeof useGetBasePlyUsageDetailLazyQuery>;
export type GetBasePlyUsageDetailQueryResult = Apollo.QueryResult<GetBasePlyUsageDetailQuery, GetBasePlyUsageDetailQueryVariables>;
export const GetSubstratePrimerUsageDetailDocument = gql`
    query GetSubstratePrimerUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
      }
    }
    SubstratePrimerUsage {
      ApplicationRateWet
      ApplicationRateDry
    }
  }
}
    `;

/**
 * __useGetSubstratePrimerUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetSubstratePrimerUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubstratePrimerUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubstratePrimerUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetSubstratePrimerUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetSubstratePrimerUsageDetailQuery, GetSubstratePrimerUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubstratePrimerUsageDetailQuery, GetSubstratePrimerUsageDetailQueryVariables>(GetSubstratePrimerUsageDetailDocument, options);
      }
export function useGetSubstratePrimerUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubstratePrimerUsageDetailQuery, GetSubstratePrimerUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubstratePrimerUsageDetailQuery, GetSubstratePrimerUsageDetailQueryVariables>(GetSubstratePrimerUsageDetailDocument, options);
        }
export type GetSubstratePrimerUsageDetailQueryHookResult = ReturnType<typeof useGetSubstratePrimerUsageDetailQuery>;
export type GetSubstratePrimerUsageDetailLazyQueryHookResult = ReturnType<typeof useGetSubstratePrimerUsageDetailLazyQuery>;
export type GetSubstratePrimerUsageDetailQueryResult = Apollo.QueryResult<GetSubstratePrimerUsageDetailQuery, GetSubstratePrimerUsageDetailQueryVariables>;
export const GetCompositePanelCoverUsageDetailDocument = gql`
    query GetCompositePanelCoverUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        CompositePanelCover {
          InsulationCoreId
          C_InsulationCore {
            InsulationCoreCode
          }
          WidthMin
          WidthMax
          ThicknessMin
          ThicknessMax
          TopFacerMaterialId
          C_FacerMaterial {
            FacerMaterialCode
          }
          FacerThicknessTop
          BottomFacerMaterialId
          cFacermaterialByBottomfacermaterialid {
            FacerMaterialCode
          }
          FacerThicknessBottom
        }
      }
    }
    CompositePanelUsage {
      WidthMax
      ThicknessMin
    }
  }
}
    `;

/**
 * __useGetCompositePanelCoverUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetCompositePanelCoverUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompositePanelCoverUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompositePanelCoverUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetCompositePanelCoverUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetCompositePanelCoverUsageDetailQuery, GetCompositePanelCoverUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompositePanelCoverUsageDetailQuery, GetCompositePanelCoverUsageDetailQueryVariables>(GetCompositePanelCoverUsageDetailDocument, options);
      }
export function useGetCompositePanelCoverUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompositePanelCoverUsageDetailQuery, GetCompositePanelCoverUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompositePanelCoverUsageDetailQuery, GetCompositePanelCoverUsageDetailQueryVariables>(GetCompositePanelCoverUsageDetailDocument, options);
        }
export type GetCompositePanelCoverUsageDetailQueryHookResult = ReturnType<typeof useGetCompositePanelCoverUsageDetailQuery>;
export type GetCompositePanelCoverUsageDetailLazyQueryHookResult = ReturnType<typeof useGetCompositePanelCoverUsageDetailLazyQuery>;
export type GetCompositePanelCoverUsageDetailQueryResult = Apollo.QueryResult<GetCompositePanelCoverUsageDetailQuery, GetCompositePanelCoverUsageDetailQueryVariables>;
export const GetFastenerUsageDetailDocument = gql`
    query GetFastenerUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        Fastener {
          SecurementMaterialId
          C_SecurementMaterial {
            SecurementMaterialCode
          }
          Diameter
          LengthMin
          LengthMax
          IsSecuredToConcreteDeck
          IsSteelDeckFastener
        }
      }
    }
    FastenerUsage {
      RowSpacing
      OnCenter
      IsFieldRows
      FieldRowsOnCenter
      NumFieldRows
      FasteningApplicationMethodId
      C_FasteningApplicationMethod {
        FasteningApplicationMethodCode
      }
      Embedment
      ContributoryArea
    }
  }
}
    `;

/**
 * __useGetFastenerUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetFastenerUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFastenerUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFastenerUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetFastenerUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetFastenerUsageDetailQuery, GetFastenerUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFastenerUsageDetailQuery, GetFastenerUsageDetailQueryVariables>(GetFastenerUsageDetailDocument, options);
      }
export function useGetFastenerUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFastenerUsageDetailQuery, GetFastenerUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFastenerUsageDetailQuery, GetFastenerUsageDetailQueryVariables>(GetFastenerUsageDetailDocument, options);
        }
export type GetFastenerUsageDetailQueryHookResult = ReturnType<typeof useGetFastenerUsageDetailQuery>;
export type GetFastenerUsageDetailLazyQueryHookResult = ReturnType<typeof useGetFastenerUsageDetailLazyQuery>;
export type GetFastenerUsageDetailQueryResult = Apollo.QueryResult<GetFastenerUsageDetailQuery, GetFastenerUsageDetailQueryVariables>;
export const GetTopCoatUsageDetailDocument = gql`
    query GetTopCoatUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        TopCoat {
          LiquidAppliedMaterialId
          C_LiquidAppliedMaterial {
            LiquidAppliedMaterialCode
          }
        }
      }
    }
    TopCoatUsage {
      NumCoats
      ApplicationRateMinWet
      ApplicationRateMinDry
    }
  }
}
    `;

/**
 * __useGetTopCoatUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetTopCoatUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopCoatUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopCoatUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetTopCoatUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetTopCoatUsageDetailQuery, GetTopCoatUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopCoatUsageDetailQuery, GetTopCoatUsageDetailQueryVariables>(GetTopCoatUsageDetailDocument, options);
      }
export function useGetTopCoatUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopCoatUsageDetailQuery, GetTopCoatUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopCoatUsageDetailQuery, GetTopCoatUsageDetailQueryVariables>(GetTopCoatUsageDetailDocument, options);
        }
export type GetTopCoatUsageDetailQueryHookResult = ReturnType<typeof useGetTopCoatUsageDetailQuery>;
export type GetTopCoatUsageDetailLazyQueryHookResult = ReturnType<typeof useGetTopCoatUsageDetailLazyQuery>;
export type GetTopCoatUsageDetailQueryResult = Apollo.QueryResult<GetTopCoatUsageDetailQuery, GetTopCoatUsageDetailQueryVariables>;
export const GetCoatUsageDetailDocument = gql`
    query GetCoatUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        Coat {
          LiquidAppliedMaterialId
          C_LiquidAppliedMaterial {
            LiquidAppliedMaterialCode
          }
        }
      }
    }
    CoatUsage {
      NumCoats
      MinAppRatePerCoatWet
      MinAppRatePerCoatDry
    }
  }
}
    `;

/**
 * __useGetCoatUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetCoatUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoatUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoatUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetCoatUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetCoatUsageDetailQuery, GetCoatUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoatUsageDetailQuery, GetCoatUsageDetailQueryVariables>(GetCoatUsageDetailDocument, options);
      }
export function useGetCoatUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoatUsageDetailQuery, GetCoatUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoatUsageDetailQuery, GetCoatUsageDetailQueryVariables>(GetCoatUsageDetailDocument, options);
        }
export type GetCoatUsageDetailQueryHookResult = ReturnType<typeof useGetCoatUsageDetailQuery>;
export type GetCoatUsageDetailLazyQueryHookResult = ReturnType<typeof useGetCoatUsageDetailLazyQuery>;
export type GetCoatUsageDetailQueryResult = Apollo.QueryResult<GetCoatUsageDetailQuery, GetCoatUsageDetailQueryVariables>;
export const GetBaseCoatUsageDetailDocument = gql`
    query GetBaseCoatUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        BaseCoat {
          LiquidAppliedMaterialId
          C_LiquidAppliedMaterial {
            LiquidAppliedMaterialCode
          }
        }
      }
    }
    BaseCoatUsage {
      NumCoats
      ApplicationRateMinWet
      ApplicationRateMinDry
    }
  }
}
    `;

/**
 * __useGetBaseCoatUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetBaseCoatUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseCoatUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseCoatUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetBaseCoatUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetBaseCoatUsageDetailQuery, GetBaseCoatUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaseCoatUsageDetailQuery, GetBaseCoatUsageDetailQueryVariables>(GetBaseCoatUsageDetailDocument, options);
      }
export function useGetBaseCoatUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseCoatUsageDetailQuery, GetBaseCoatUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaseCoatUsageDetailQuery, GetBaseCoatUsageDetailQueryVariables>(GetBaseCoatUsageDetailDocument, options);
        }
export type GetBaseCoatUsageDetailQueryHookResult = ReturnType<typeof useGetBaseCoatUsageDetailQuery>;
export type GetBaseCoatUsageDetailLazyQueryHookResult = ReturnType<typeof useGetBaseCoatUsageDetailLazyQuery>;
export type GetBaseCoatUsageDetailQueryResult = Apollo.QueryResult<GetBaseCoatUsageDetailQuery, GetBaseCoatUsageDetailQueryVariables>;
export const GetSprayedFoamInsulationUsageDetailDocument = gql`
    query GetSprayedFoamInsulationUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        SprayedFoamInsulation {
          InsulationFoamTypeId
          C_InsulationFoamType {
            InsulationFoamTypeCode
          }
          DensityMin
          DensityMax
        }
      }
    }
    SprayFoamInsulationUsage {
      ThicknessMin
      ThicknessMax
    }
  }
}
    `;

/**
 * __useGetSprayedFoamInsulationUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetSprayedFoamInsulationUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSprayedFoamInsulationUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSprayedFoamInsulationUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetSprayedFoamInsulationUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetSprayedFoamInsulationUsageDetailQuery, GetSprayedFoamInsulationUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSprayedFoamInsulationUsageDetailQuery, GetSprayedFoamInsulationUsageDetailQueryVariables>(GetSprayedFoamInsulationUsageDetailDocument, options);
      }
export function useGetSprayedFoamInsulationUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSprayedFoamInsulationUsageDetailQuery, GetSprayedFoamInsulationUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSprayedFoamInsulationUsageDetailQuery, GetSprayedFoamInsulationUsageDetailQueryVariables>(GetSprayedFoamInsulationUsageDetailDocument, options);
        }
export type GetSprayedFoamInsulationUsageDetailQueryHookResult = ReturnType<typeof useGetSprayedFoamInsulationUsageDetailQuery>;
export type GetSprayedFoamInsulationUsageDetailLazyQueryHookResult = ReturnType<typeof useGetSprayedFoamInsulationUsageDetailLazyQuery>;
export type GetSprayedFoamInsulationUsageDetailQueryResult = Apollo.QueryResult<GetSprayedFoamInsulationUsageDetailQuery, GetSprayedFoamInsulationUsageDetailQueryVariables>;
export const GetBattBlanketInsulationUsageDetailDocument = gql`
    query GetBattBlanketInsulationUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        BattBlanketInsulation {
          BattBlanketMaterialId
          C_BattBlanketMaterial {
            BattBlanketMaterialCode
          }
          BattBlanketFacerMaterialId
          C_BattBlanketFacerMaterial {
            BattBlanketFacerMaterialCode
          }
          ThicknessMin
          ThicknessMax
        }
      }
    }
    BattBlanketUsage {
      ThicknessMin
      ThicknessMax
    }
  }
}
    `;

/**
 * __useGetBattBlanketInsulationUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetBattBlanketInsulationUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBattBlanketInsulationUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBattBlanketInsulationUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetBattBlanketInsulationUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetBattBlanketInsulationUsageDetailQuery, GetBattBlanketInsulationUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBattBlanketInsulationUsageDetailQuery, GetBattBlanketInsulationUsageDetailQueryVariables>(GetBattBlanketInsulationUsageDetailDocument, options);
      }
export function useGetBattBlanketInsulationUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBattBlanketInsulationUsageDetailQuery, GetBattBlanketInsulationUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBattBlanketInsulationUsageDetailQuery, GetBattBlanketInsulationUsageDetailQueryVariables>(GetBattBlanketInsulationUsageDetailDocument, options);
        }
export type GetBattBlanketInsulationUsageDetailQueryHookResult = ReturnType<typeof useGetBattBlanketInsulationUsageDetailQuery>;
export type GetBattBlanketInsulationUsageDetailLazyQueryHookResult = ReturnType<typeof useGetBattBlanketInsulationUsageDetailLazyQuery>;
export type GetBattBlanketInsulationUsageDetailQueryResult = Apollo.QueryResult<GetBattBlanketInsulationUsageDetailQuery, GetBattBlanketInsulationUsageDetailQueryVariables>;
export const GetStandingLapSeamCoverUsageDetailDocument = gql`
    query GetStandingLapSeamCoverUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        StandingLapSeamCover {
          WidthMin
          WidthMax
          StandingLapSeamMaterialId
          C_StandingLapSeamMaterial {
            StandingLapSeamMaterialCode
          }
          NominalWeight
          ThicknessMin
          ThicknessMax
        }
      }
    }
    StandingLapSeamUsage {
      WidthMax
      ThicknessMin
      WeightPerUnitAreaNominal
    }
  }
}
    `;

/**
 * __useGetStandingLapSeamCoverUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetStandingLapSeamCoverUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandingLapSeamCoverUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandingLapSeamCoverUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetStandingLapSeamCoverUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetStandingLapSeamCoverUsageDetailQuery, GetStandingLapSeamCoverUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStandingLapSeamCoverUsageDetailQuery, GetStandingLapSeamCoverUsageDetailQueryVariables>(GetStandingLapSeamCoverUsageDetailDocument, options);
      }
export function useGetStandingLapSeamCoverUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStandingLapSeamCoverUsageDetailQuery, GetStandingLapSeamCoverUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStandingLapSeamCoverUsageDetailQuery, GetStandingLapSeamCoverUsageDetailQueryVariables>(GetStandingLapSeamCoverUsageDetailDocument, options);
        }
export type GetStandingLapSeamCoverUsageDetailQueryHookResult = ReturnType<typeof useGetStandingLapSeamCoverUsageDetailQuery>;
export type GetStandingLapSeamCoverUsageDetailLazyQueryHookResult = ReturnType<typeof useGetStandingLapSeamCoverUsageDetailLazyQuery>;
export type GetStandingLapSeamCoverUsageDetailQueryResult = Apollo.QueryResult<GetStandingLapSeamCoverUsageDetailQuery, GetStandingLapSeamCoverUsageDetailQueryVariables>;
export const GetAirRetarderUsageDetailDocument = gql`
    query GetAirRetarderUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        AirRetarder {
          RetarderMaterialId
          C_RetarderMaterial {
            RetarderMaterialCode
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAirRetarderUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetAirRetarderUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAirRetarderUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAirRetarderUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetAirRetarderUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetAirRetarderUsageDetailQuery, GetAirRetarderUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAirRetarderUsageDetailQuery, GetAirRetarderUsageDetailQueryVariables>(GetAirRetarderUsageDetailDocument, options);
      }
export function useGetAirRetarderUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAirRetarderUsageDetailQuery, GetAirRetarderUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAirRetarderUsageDetailQuery, GetAirRetarderUsageDetailQueryVariables>(GetAirRetarderUsageDetailDocument, options);
        }
export type GetAirRetarderUsageDetailQueryHookResult = ReturnType<typeof useGetAirRetarderUsageDetailQuery>;
export type GetAirRetarderUsageDetailLazyQueryHookResult = ReturnType<typeof useGetAirRetarderUsageDetailLazyQuery>;
export type GetAirRetarderUsageDetailQueryResult = Apollo.QueryResult<GetAirRetarderUsageDetailQuery, GetAirRetarderUsageDetailQueryVariables>;
export const GetSeamTapeSystemUsageDetailDocument = gql`
    query GetSeamTapeSystemUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      StructuredSystem {
        SystemName
        StructuredSystemProducts(order_by: {OrderNum: asc}) {
          ManufacturedProduct {
            ComponentId
            TradeName
            ManufacturerId
            Manufacturer {
              Name
            }
          }
        }
      }
      Comments
    }
  }
}
    `;

/**
 * __useGetSeamTapeSystemUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetSeamTapeSystemUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeamTapeSystemUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeamTapeSystemUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetSeamTapeSystemUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetSeamTapeSystemUsageDetailQuery, GetSeamTapeSystemUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeamTapeSystemUsageDetailQuery, GetSeamTapeSystemUsageDetailQueryVariables>(GetSeamTapeSystemUsageDetailDocument, options);
      }
export function useGetSeamTapeSystemUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeamTapeSystemUsageDetailQuery, GetSeamTapeSystemUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeamTapeSystemUsageDetailQuery, GetSeamTapeSystemUsageDetailQueryVariables>(GetSeamTapeSystemUsageDetailDocument, options);
        }
export type GetSeamTapeSystemUsageDetailQueryHookResult = ReturnType<typeof useGetSeamTapeSystemUsageDetailQuery>;
export type GetSeamTapeSystemUsageDetailLazyQueryHookResult = ReturnType<typeof useGetSeamTapeSystemUsageDetailLazyQuery>;
export type GetSeamTapeSystemUsageDetailQueryResult = Apollo.QueryResult<GetSeamTapeSystemUsageDetailQuery, GetSeamTapeSystemUsageDetailQueryVariables>;
export const GetAdhesiveRibbonsUsageDetailDocument = gql`
    query GetAdhesiveRibbonsUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        AdhesiveRibbon {
          AdhesiveMaterialId
          C_AdhesiveMaterial {
            AdhesiveMaterialCode
          }
          AdhesiveTypeId
          C_AdhesiveType {
            AdhesiveTypeCode
          }
          TapeWidth
        }
      }
    }
    AdhesiveRibbonUsage {
      RowSpacing
      RibbonWidth
      AdhesiveApplicationMethodId
      C_AdhesiveApplicationMethod {
        AdhesiveApplicationMethodCode
      }
    }
  }
}
    `;

/**
 * __useGetAdhesiveRibbonsUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetAdhesiveRibbonsUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdhesiveRibbonsUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdhesiveRibbonsUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetAdhesiveRibbonsUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetAdhesiveRibbonsUsageDetailQuery, GetAdhesiveRibbonsUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdhesiveRibbonsUsageDetailQuery, GetAdhesiveRibbonsUsageDetailQueryVariables>(GetAdhesiveRibbonsUsageDetailDocument, options);
      }
export function useGetAdhesiveRibbonsUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdhesiveRibbonsUsageDetailQuery, GetAdhesiveRibbonsUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdhesiveRibbonsUsageDetailQuery, GetAdhesiveRibbonsUsageDetailQueryVariables>(GetAdhesiveRibbonsUsageDetailDocument, options);
        }
export type GetAdhesiveRibbonsUsageDetailQueryHookResult = ReturnType<typeof useGetAdhesiveRibbonsUsageDetailQuery>;
export type GetAdhesiveRibbonsUsageDetailLazyQueryHookResult = ReturnType<typeof useGetAdhesiveRibbonsUsageDetailLazyQuery>;
export type GetAdhesiveRibbonsUsageDetailQueryResult = Apollo.QueryResult<GetAdhesiveRibbonsUsageDetailQuery, GetAdhesiveRibbonsUsageDetailQueryVariables>;
export const GetCementitiousWoodFiberDeckUsageDetailDocument = gql`
    query GetCementitiousWoodFiberDeckUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        CementitiousWoodFiberDeck {
          ThicknessMin
          ThicknessMax
          SpanMax
        }
      }
    }
    CementitiousWoodFiberDeckUsage {
      ThicknessMin
      SpanMax
    }
  }
}
    `;

/**
 * __useGetCementitiousWoodFiberDeckUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetCementitiousWoodFiberDeckUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCementitiousWoodFiberDeckUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCementitiousWoodFiberDeckUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetCementitiousWoodFiberDeckUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetCementitiousWoodFiberDeckUsageDetailQuery, GetCementitiousWoodFiberDeckUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCementitiousWoodFiberDeckUsageDetailQuery, GetCementitiousWoodFiberDeckUsageDetailQueryVariables>(GetCementitiousWoodFiberDeckUsageDetailDocument, options);
      }
export function useGetCementitiousWoodFiberDeckUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCementitiousWoodFiberDeckUsageDetailQuery, GetCementitiousWoodFiberDeckUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCementitiousWoodFiberDeckUsageDetailQuery, GetCementitiousWoodFiberDeckUsageDetailQueryVariables>(GetCementitiousWoodFiberDeckUsageDetailDocument, options);
        }
export type GetCementitiousWoodFiberDeckUsageDetailQueryHookResult = ReturnType<typeof useGetCementitiousWoodFiberDeckUsageDetailQuery>;
export type GetCementitiousWoodFiberDeckUsageDetailLazyQueryHookResult = ReturnType<typeof useGetCementitiousWoodFiberDeckUsageDetailLazyQuery>;
export type GetCementitiousWoodFiberDeckUsageDetailQueryResult = Apollo.QueryResult<GetCementitiousWoodFiberDeckUsageDetailQuery, GetCementitiousWoodFiberDeckUsageDetailQueryVariables>;
export const GetWeldHeatUsageDetailDocument = gql`
    query GetWeldHeatUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
      }
    }
    WeldHeatUsage {
      WeldConfigurationId
      C_WeldConfiguration {
        WeldConfigurationCode
      }
      OutsideWidth
      InsideWidth
    }
  }
}
    `;

/**
 * __useGetWeldHeatUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetWeldHeatUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeldHeatUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeldHeatUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetWeldHeatUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetWeldHeatUsageDetailQuery, GetWeldHeatUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWeldHeatUsageDetailQuery, GetWeldHeatUsageDetailQueryVariables>(GetWeldHeatUsageDetailDocument, options);
      }
export function useGetWeldHeatUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWeldHeatUsageDetailQuery, GetWeldHeatUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWeldHeatUsageDetailQuery, GetWeldHeatUsageDetailQueryVariables>(GetWeldHeatUsageDetailDocument, options);
        }
export type GetWeldHeatUsageDetailQueryHookResult = ReturnType<typeof useGetWeldHeatUsageDetailQuery>;
export type GetWeldHeatUsageDetailLazyQueryHookResult = ReturnType<typeof useGetWeldHeatUsageDetailLazyQuery>;
export type GetWeldHeatUsageDetailQueryResult = Apollo.QueryResult<GetWeldHeatUsageDetailQuery, GetWeldHeatUsageDetailQueryVariables>;
export const GetSeparatorSheetUsageDetailDocument = gql`
    query GetSeparatorSheetUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        SeparatorSheet {
          RetarderMaterialId
          C_RetarderMaterial {
            RetarderMaterialCode
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetSeparatorSheetUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetSeparatorSheetUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeparatorSheetUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeparatorSheetUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetSeparatorSheetUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetSeparatorSheetUsageDetailQuery, GetSeparatorSheetUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeparatorSheetUsageDetailQuery, GetSeparatorSheetUsageDetailQueryVariables>(GetSeparatorSheetUsageDetailDocument, options);
      }
export function useGetSeparatorSheetUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeparatorSheetUsageDetailQuery, GetSeparatorSheetUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeparatorSheetUsageDetailQuery, GetSeparatorSheetUsageDetailQueryVariables>(GetSeparatorSheetUsageDetailDocument, options);
        }
export type GetSeparatorSheetUsageDetailQueryHookResult = ReturnType<typeof useGetSeparatorSheetUsageDetailQuery>;
export type GetSeparatorSheetUsageDetailLazyQueryHookResult = ReturnType<typeof useGetSeparatorSheetUsageDetailLazyQuery>;
export type GetSeparatorSheetUsageDetailQueryResult = Apollo.QueryResult<GetSeparatorSheetUsageDetailQuery, GetSeparatorSheetUsageDetailQueryVariables>;
export const GetCoverStripDiskUsageDetailDocument = gql`
    query GetCoverStripDiskUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        CoverStripDisk {
          SinglePlyMaterialId
          C_SinglePlyMaterial {
            SinglePlyMaterialCode
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCoverStripDiskUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetCoverStripDiskUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoverStripDiskUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoverStripDiskUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetCoverStripDiskUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetCoverStripDiskUsageDetailQuery, GetCoverStripDiskUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoverStripDiskUsageDetailQuery, GetCoverStripDiskUsageDetailQueryVariables>(GetCoverStripDiskUsageDetailDocument, options);
      }
export function useGetCoverStripDiskUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoverStripDiskUsageDetailQuery, GetCoverStripDiskUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoverStripDiskUsageDetailQuery, GetCoverStripDiskUsageDetailQueryVariables>(GetCoverStripDiskUsageDetailDocument, options);
        }
export type GetCoverStripDiskUsageDetailQueryHookResult = ReturnType<typeof useGetCoverStripDiskUsageDetailQuery>;
export type GetCoverStripDiskUsageDetailLazyQueryHookResult = ReturnType<typeof useGetCoverStripDiskUsageDetailLazyQuery>;
export type GetCoverStripDiskUsageDetailQueryResult = Apollo.QueryResult<GetCoverStripDiskUsageDetailQuery, GetCoverStripDiskUsageDetailQueryVariables>;
export const GetLinerPanelUsageDetailDocument = gql`
    query GetLinerPanelUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        LinerPanel {
          LinerPanelMaterialId
          C_LinerPanelMaterial {
            LinerPanelMaterialCode
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetLinerPanelUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetLinerPanelUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinerPanelUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinerPanelUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetLinerPanelUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetLinerPanelUsageDetailQuery, GetLinerPanelUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLinerPanelUsageDetailQuery, GetLinerPanelUsageDetailQueryVariables>(GetLinerPanelUsageDetailDocument, options);
      }
export function useGetLinerPanelUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLinerPanelUsageDetailQuery, GetLinerPanelUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLinerPanelUsageDetailQuery, GetLinerPanelUsageDetailQueryVariables>(GetLinerPanelUsageDetailDocument, options);
        }
export type GetLinerPanelUsageDetailQueryHookResult = ReturnType<typeof useGetLinerPanelUsageDetailQuery>;
export type GetLinerPanelUsageDetailLazyQueryHookResult = ReturnType<typeof useGetLinerPanelUsageDetailLazyQuery>;
export type GetLinerPanelUsageDetailQueryResult = Apollo.QueryResult<GetLinerPanelUsageDetailQuery, GetLinerPanelUsageDetailQueryVariables>;
export const GetClipSystemUsageDetailDocument = gql`
    query GetClipSystemUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      StructuredSystem {
        SystemName
        StructuredSystemProducts(order_by: {OrderNum: asc}) {
          ManufacturedProduct {
            ComponentId
            TradeName
            ManufacturerId
            Manufacturer {
              Name
            }
          }
        }
      }
      Comments
    }
    FasteningSystemClipUsage {
      RowSpacing
      OnCenter
      FastenersPerPlateOrClip
    }
  }
}
    `;

/**
 * __useGetClipSystemUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetClipSystemUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClipSystemUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClipSystemUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetClipSystemUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetClipSystemUsageDetailQuery, GetClipSystemUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClipSystemUsageDetailQuery, GetClipSystemUsageDetailQueryVariables>(GetClipSystemUsageDetailDocument, options);
      }
export function useGetClipSystemUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClipSystemUsageDetailQuery, GetClipSystemUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClipSystemUsageDetailQuery, GetClipSystemUsageDetailQueryVariables>(GetClipSystemUsageDetailDocument, options);
        }
export type GetClipSystemUsageDetailQueryHookResult = ReturnType<typeof useGetClipSystemUsageDetailQuery>;
export type GetClipSystemUsageDetailLazyQueryHookResult = ReturnType<typeof useGetClipSystemUsageDetailLazyQuery>;
export type GetClipSystemUsageDetailQueryResult = Apollo.QueryResult<GetClipSystemUsageDetailQuery, GetClipSystemUsageDetailQueryVariables>;
export const GetCoatingSystemUsageDetailDocument = gql`
    query GetCoatingSystemUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      StructuredSystem {
        SystemName
        StructuredSystemProducts(order_by: {OrderNum: asc}) {
          ManufacturedProduct {
            ComponentId
            TradeName
            ManufacturerId
            Manufacturer {
              Name
            }
          }
        }
      }
      Comments
    }
    CoatingSystemUsage {
      ApplicationRateWet
      ApplicationRateDry
    }
  }
}
    `;

/**
 * __useGetCoatingSystemUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetCoatingSystemUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoatingSystemUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoatingSystemUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetCoatingSystemUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetCoatingSystemUsageDetailQuery, GetCoatingSystemUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoatingSystemUsageDetailQuery, GetCoatingSystemUsageDetailQueryVariables>(GetCoatingSystemUsageDetailDocument, options);
      }
export function useGetCoatingSystemUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoatingSystemUsageDetailQuery, GetCoatingSystemUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoatingSystemUsageDetailQuery, GetCoatingSystemUsageDetailQueryVariables>(GetCoatingSystemUsageDetailDocument, options);
        }
export type GetCoatingSystemUsageDetailQueryHookResult = ReturnType<typeof useGetCoatingSystemUsageDetailQuery>;
export type GetCoatingSystemUsageDetailLazyQueryHookResult = ReturnType<typeof useGetCoatingSystemUsageDetailLazyQuery>;
export type GetCoatingSystemUsageDetailQueryResult = Apollo.QueryResult<GetCoatingSystemUsageDetailQuery, GetCoatingSystemUsageDetailQueryVariables>;
export const GetBattenBarSystemUsageDetailDocument = gql`
    query GetBattenBarSystemUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      StructuredSystem {
        SystemName
        StructuredSystemProducts(order_by: {OrderNum: asc}) {
          ManufacturedProduct {
            ComponentId
            TradeName
            ManufacturerId
            Manufacturer {
              Name
            }
          }
        }
      }
      Comments
    }
    FasteningSystemBattenBarUsage {
      RowSpacing
      OnCenter
      IsFieldRows
      FieldRowsOnCenter
      NumFieldRows
      FasteningApplicationMethodId
      C_FasteningApplicationMethod {
        FasteningApplicationMethodCode
      }
    }
  }
}
    `;

/**
 * __useGetBattenBarSystemUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetBattenBarSystemUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBattenBarSystemUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBattenBarSystemUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetBattenBarSystemUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetBattenBarSystemUsageDetailQuery, GetBattenBarSystemUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBattenBarSystemUsageDetailQuery, GetBattenBarSystemUsageDetailQueryVariables>(GetBattenBarSystemUsageDetailDocument, options);
      }
export function useGetBattenBarSystemUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBattenBarSystemUsageDetailQuery, GetBattenBarSystemUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBattenBarSystemUsageDetailQuery, GetBattenBarSystemUsageDetailQueryVariables>(GetBattenBarSystemUsageDetailDocument, options);
        }
export type GetBattenBarSystemUsageDetailQueryHookResult = ReturnType<typeof useGetBattenBarSystemUsageDetailQuery>;
export type GetBattenBarSystemUsageDetailLazyQueryHookResult = ReturnType<typeof useGetBattenBarSystemUsageDetailLazyQuery>;
export type GetBattenBarSystemUsageDetailQueryResult = Apollo.QueryResult<GetBattenBarSystemUsageDetailQuery, GetBattenBarSystemUsageDetailQueryVariables>;
export const GetWeldSolventUsageDetailDocument = gql`
    query GetWeldSolventUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
      }
    }
    WeldSolventUsage {
      WeldConfigurationId
      C_WeldConfiguration {
        WeldConfigurationCode
      }
      OutsideWidth
      InsideWidth
    }
  }
}
    `;

/**
 * __useGetWeldSolventUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetWeldSolventUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeldSolventUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeldSolventUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetWeldSolventUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetWeldSolventUsageDetailQuery, GetWeldSolventUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWeldSolventUsageDetailQuery, GetWeldSolventUsageDetailQueryVariables>(GetWeldSolventUsageDetailDocument, options);
      }
export function useGetWeldSolventUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWeldSolventUsageDetailQuery, GetWeldSolventUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWeldSolventUsageDetailQuery, GetWeldSolventUsageDetailQueryVariables>(GetWeldSolventUsageDetailDocument, options);
        }
export type GetWeldSolventUsageDetailQueryHookResult = ReturnType<typeof useGetWeldSolventUsageDetailQuery>;
export type GetWeldSolventUsageDetailLazyQueryHookResult = ReturnType<typeof useGetWeldSolventUsageDetailLazyQuery>;
export type GetWeldSolventUsageDetailQueryResult = Apollo.QueryResult<GetWeldSolventUsageDetailQuery, GetWeldSolventUsageDetailQueryVariables>;
export const GetFormDeckUsageDetailDocument = gql`
    query GetFormDeckUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        FormDeck {
          ThicknessMin
          Depth
          IsVented
          SpanMax
        }
      }
    }
    FormDeckUsage {
      SpanMax
    }
  }
}
    `;

/**
 * __useGetFormDeckUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetFormDeckUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormDeckUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormDeckUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetFormDeckUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetFormDeckUsageDetailQuery, GetFormDeckUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormDeckUsageDetailQuery, GetFormDeckUsageDetailQueryVariables>(GetFormDeckUsageDetailDocument, options);
      }
export function useGetFormDeckUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormDeckUsageDetailQuery, GetFormDeckUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormDeckUsageDetailQuery, GetFormDeckUsageDetailQueryVariables>(GetFormDeckUsageDetailDocument, options);
        }
export type GetFormDeckUsageDetailQueryHookResult = ReturnType<typeof useGetFormDeckUsageDetailQuery>;
export type GetFormDeckUsageDetailLazyQueryHookResult = ReturnType<typeof useGetFormDeckUsageDetailLazyQuery>;
export type GetFormDeckUsageDetailQueryResult = Apollo.QueryResult<GetFormDeckUsageDetailQuery, GetFormDeckUsageDetailQueryVariables>;
export const GetAdhesiveSpotsUsageDetailDocument = gql`
    query GetAdhesiveSpotsUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        AdhesiveSpot {
          AdhesiveMaterialId
          C_AdhesiveMaterial {
            AdhesiveMaterialCode
          }
          AdhesiveTypeId
          C_AdhesiveType {
            AdhesiveTypeCode
          }
        }
      }
    }
    AdhesiveSpotsUsage {
      RowSpacing
      OnCenter
      SpotDiameter
    }
  }
}
    `;

/**
 * __useGetAdhesiveSpotsUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetAdhesiveSpotsUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdhesiveSpotsUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdhesiveSpotsUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetAdhesiveSpotsUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetAdhesiveSpotsUsageDetailQuery, GetAdhesiveSpotsUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdhesiveSpotsUsageDetailQuery, GetAdhesiveSpotsUsageDetailQueryVariables>(GetAdhesiveSpotsUsageDetailDocument, options);
      }
export function useGetAdhesiveSpotsUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdhesiveSpotsUsageDetailQuery, GetAdhesiveSpotsUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdhesiveSpotsUsageDetailQuery, GetAdhesiveSpotsUsageDetailQueryVariables>(GetAdhesiveSpotsUsageDetailDocument, options);
        }
export type GetAdhesiveSpotsUsageDetailQueryHookResult = ReturnType<typeof useGetAdhesiveSpotsUsageDetailQuery>;
export type GetAdhesiveSpotsUsageDetailLazyQueryHookResult = ReturnType<typeof useGetAdhesiveSpotsUsageDetailLazyQuery>;
export type GetAdhesiveSpotsUsageDetailQueryResult = Apollo.QueryResult<GetAdhesiveSpotsUsageDetailQuery, GetAdhesiveSpotsUsageDetailQueryVariables>;
export const GetWeldArcUsageDetailDocument = gql`
    query GetWeldArcUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
      }
    }
    WeldArcUsage {
      RowSpacing
      OnCenter
      Diameter
      WithWasher
    }
  }
}
    `;

/**
 * __useGetWeldArcUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetWeldArcUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeldArcUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeldArcUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetWeldArcUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetWeldArcUsageDetailQuery, GetWeldArcUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWeldArcUsageDetailQuery, GetWeldArcUsageDetailQueryVariables>(GetWeldArcUsageDetailDocument, options);
      }
export function useGetWeldArcUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWeldArcUsageDetailQuery, GetWeldArcUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWeldArcUsageDetailQuery, GetWeldArcUsageDetailQueryVariables>(GetWeldArcUsageDetailDocument, options);
        }
export type GetWeldArcUsageDetailQueryHookResult = ReturnType<typeof useGetWeldArcUsageDetailQuery>;
export type GetWeldArcUsageDetailLazyQueryHookResult = ReturnType<typeof useGetWeldArcUsageDetailLazyQuery>;
export type GetWeldArcUsageDetailQueryResult = Apollo.QueryResult<GetWeldArcUsageDetailQuery, GetWeldArcUsageDetailQueryVariables>;
export const GetSurfacingUsageDetailDocument = gql`
    query GetSurfacingUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      ManufacturedProduct {
        TradeName
        ManufacturerId
        Manufacturer {
          Name
        }
        Surfacing {
          SurfacingMaterialId
          C_SurfacingMaterial {
            SurfacingMaterialCode
          }
        }
      }
    }
    SurfacingUsage {
      ApplicationRate
    }
  }
}
    `;

/**
 * __useGetSurfacingUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetSurfacingUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurfacingUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurfacingUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetSurfacingUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetSurfacingUsageDetailQuery, GetSurfacingUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurfacingUsageDetailQuery, GetSurfacingUsageDetailQueryVariables>(GetSurfacingUsageDetailDocument, options);
      }
export function useGetSurfacingUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurfacingUsageDetailQuery, GetSurfacingUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurfacingUsageDetailQuery, GetSurfacingUsageDetailQueryVariables>(GetSurfacingUsageDetailDocument, options);
        }
export type GetSurfacingUsageDetailQueryHookResult = ReturnType<typeof useGetSurfacingUsageDetailQuery>;
export type GetSurfacingUsageDetailLazyQueryHookResult = ReturnType<typeof useGetSurfacingUsageDetailLazyQuery>;
export type GetSurfacingUsageDetailQueryResult = Apollo.QueryResult<GetSurfacingUsageDetailQuery, GetSurfacingUsageDetailQueryVariables>;
export const GetAdhesiveSystemUsageDetailDocument = gql`
    query GetAdhesiveSystemUsageDetail($UsageId: Int) {
  Usage(where: {UsageId: {_eq: $UsageId}}) {
    UsageId
    ComponentId
    ApprovedUseId
    EnteredAsMetric
    Comments
    Component {
      StructuredSystem {
        SystemName
        StructuredSystemProducts(order_by: {OrderNum: asc}) {
          ManufacturedProduct {
            ComponentId
            TradeName
            ManufacturerId
            Manufacturer {
              Name
            }
          }
        }
      }
      Comments
    }
    AdhesiveSystemUsage {
      SubstratePrimerRateWet
      OverSubstrateRateWet
      UndersideRateWet
      SubstratePrimerRateDry
      OverSubstrateRateDry
      UndersideRateDry
    }
  }
}
    `;

/**
 * __useGetAdhesiveSystemUsageDetailQuery__
 *
 * To run a query within a React component, call `useGetAdhesiveSystemUsageDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdhesiveSystemUsageDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdhesiveSystemUsageDetailQuery({
 *   variables: {
 *      UsageId: // value for 'UsageId'
 *   },
 * });
 */
export function useGetAdhesiveSystemUsageDetailQuery(baseOptions?: Apollo.QueryHookOptions<GetAdhesiveSystemUsageDetailQuery, GetAdhesiveSystemUsageDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdhesiveSystemUsageDetailQuery, GetAdhesiveSystemUsageDetailQueryVariables>(GetAdhesiveSystemUsageDetailDocument, options);
      }
export function useGetAdhesiveSystemUsageDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdhesiveSystemUsageDetailQuery, GetAdhesiveSystemUsageDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdhesiveSystemUsageDetailQuery, GetAdhesiveSystemUsageDetailQueryVariables>(GetAdhesiveSystemUsageDetailDocument, options);
        }
export type GetAdhesiveSystemUsageDetailQueryHookResult = ReturnType<typeof useGetAdhesiveSystemUsageDetailQuery>;
export type GetAdhesiveSystemUsageDetailLazyQueryHookResult = ReturnType<typeof useGetAdhesiveSystemUsageDetailLazyQuery>;
export type GetAdhesiveSystemUsageDetailQueryResult = Apollo.QueryResult<GetAdhesiveSystemUsageDetailQuery, GetAdhesiveSystemUsageDetailQueryVariables>;