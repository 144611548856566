import React from 'react';
import { useGetCapPlyUsageDetailQuery } from '../../models/GQLGeneratedModels';
import { Length } from '../../utils/Measurement/Length';
import './UsageDetails.scss';


export const CapPlyUsageDetail = (props: any) => {
  const { data, loading, error } = useGetCapPlyUsageDetailQuery({
    variables: {
      UsageId: parseInt(props.usageId),
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const usage = data?.Usage[0];

  return (
    <div className={'usage-detail p-3'}>
      <h4>Cover, Multi-ply (Cap Ply)</h4>
      <table>
        <tr>
          <td>Backing:</td>
          <td>{usage?.Component?.ManufacturedProduct?.CapPly?.C_BackingMaterial.BackingMaterialCode}</td>
        </tr>
        <tr>
          <td>Width:</td>
          <td>{Length.display(usage?.Component?.ManufacturedProduct?.CapPly?.Width, usage?.EnteredAsMetric)}</td>
        </tr>
        <tr>
          <td>Material:</td>
          <td>{usage?.Component.ManufacturedProduct?.CapPly?.C_MultiplyMaterial.MultiplyMaterialCode}</td>
        </tr>
        <tr>
          <td>Reinforcement:</td>
          <td>{usage?.Component?.ManufacturedProduct?.CapPly?.C_Reinforcement.ReinforcementCode}</td>
        </tr>
        <tr>
          <td>Surface Type:</td>
          <td>{usage?.Component?.ManufacturedProduct?.CapPly?.C_SurfaceType.SurfaceTypeCode}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{usage?.Comments}</td>
        </tr>
      </table>
    </div>
  );
};
