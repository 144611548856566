import React from 'react';
import { Outlet } from 'react-router-dom';

const Minimal = () => {
  return (
    <div>
      <main slot="app-main">
        <Outlet />
      </main>
    </div>
  );
};

export default Minimal;
