import React from 'react';
import { ISelectValue, TextField } from '.';
import { ConfigurationValue } from "../../../types/configurator";
import { ConfigitAutocomplete } from '../ConfigitAutocomplete/ConfigitAutocomplete';

export interface IConfigitTextFieldProps {
  id?: string;
  label?: string;
  disabled?: boolean;
  options: ConfigurationValue[];
  select: (variableId: string, value: string) => void;
  unSelect: (variableId: string) => void;
}

export const ConfigitTextFieldProduct: React.FC<IConfigitTextFieldProps> = ({
  id,
  label,
  disabled,
  options,
  select,
  unSelect
}: IConfigitTextFieldProps) => {
  let preSelected = options.find(option => option.assigned) as ISelectValue;
  if (!preSelected) preSelected = {};

  const handleSelect = (value: string) => {
    if (id) select(id, value);
  };

  const handleUnSelect = () => {
    if (id) unSelect(id);
  };

  return (
    <ConfigitAutocomplete
      id={id}
      label={label}
      disabled={disabled}
      options={options}
      preSelectedOption={preSelected}
      handleSelect={handleSelect}
      handleUnSelect={handleUnSelect}
    />
  );
};